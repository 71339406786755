import { PermissionConstants } from 'utils/AppConstants';
import { EnvelopeType } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import PageMap from '../PageMap';

// For new pages add them to the PageMap and use the 'pageMapKey' field
const masterMenu = {
  en_US: {
    crm: [
      // Company Settings
      {
        companyButton: {
          title: 'Company Settings',
          isOpen: true,
          caslKey: [PermissionConstants.MENU_SETTINGS, PermissionConstants.MENU_PAYROLLSETTING],
          caslAction: 'allow',
          scope: 'tenant',
          sections: ['settings'],
          menus: [
            {
              menu: {
                pageMapKey: 'company',
                type: 'menu',
                scope: 'tenant',
                caslKey: PermissionConstants.MENU_SETTINGS,
                caslAction: 'allow'
              }
            },
            {
              menu: {
                pageMapKey: 'people',
                type: 'menu',
                scope: 'tenant',
                caslKey: PermissionConstants.MENU_SETTINGS,
                caslAction: 'allow'
              }
            },
            {
              menu: {
                pageMapKey: 'customFields',
                type: 'menu',
                scope: 'tenant',
                caslKey: 'customFields',
                caslAction: 'manage'
              }
            },
            {
              menu: {
                pageMapKey: 'forms',
                type: 'menu',
                scope: 'tenant',
                caslKey: 'forms',
                caslAction: 'allow',
                featureGate: 'dynamicForms',
                featureGateValue: 'true'
              }
            },
            {
              menu: {
                pageMapKey: 'invoiceSettings',
                type: 'menu',
                scope: 'tenant',
                caslKey: PermissionConstants.MENU_ACCOUNTING,
                caslAction: 'allow',
                featureGate: 'envelopeType',
                featureGateValue: EnvelopeType.SINGLE_LARGE_LOGO
              }
            },
            {
              menu: {
                pageMapKey: 'accountingSettings',
                type: 'menu',
                scope: 'tenant',
                caslKey: PermissionConstants.MENU_ACCOUNTING,
                caslAction: 'allow'
              }
            },
            {
              ldFlag: FeatureFlags.SERVICE_CHANNEL_INTEGRATION,
              menu: {
                pageMapKey: 'integrations',
                type: 'menu',
                scope: 'tenant',
                caslKey: PermissionConstants.MENU_INTEGRATIONS,
                caslAction: 'allow'
              }
            },
            {
              menu: {
                pageMapKey: 'pricebooks',
                type: 'menu',
                scope: 'tenant',
                caslKey: PermissionConstants.MENU_PRICEBOOKS,
                caslAction: 'allow',
                featureGate: 'pricebook',
                featureGateValue: 'true'
              }
            },
            {
              menu: {
                pageMapKey: 'inventorySettings',
                type: 'menu',
                caslKey: PermissionConstants.MENU_INVENTORY,
                caslAction: 'allow',
                scope: 'tenant',
                featureGate: 'pricebook',
                featureGateValue: 'true'
              }
            },
            {
              menu: {
                pageMapKey: 'vendors',
                type: 'menu',
                caslKey: PermissionConstants.MENU_ACCOUNTING,
                caslAction: 'allow',
                scope: 'tenant',
                featureGate: 'pricebook',
                featureGateValue: 'true'
              }
            },
            {
              menu: {
                pageMapKey: 'payrollSetting',
                type: 'menu',
                caslKey: PermissionConstants.OBJECT_TIMETRACKINGSETTING,
                caslAction: 'read',
                scope: 'tenant'
              }
            },
            {
              menu: {
                pageMapKey: 'projectSetting',
                type: 'menu',
                caslKey: PermissionConstants.ONLY_ADMIN,
                caslAction: 'read',
                scope: 'tenant'
              }
            },
            {
              ldFlag: FeatureFlags.QUOTESV2,
              menu: {
                pageMapKey: 'quoteSettings',
                type: 'menu',
                caslKey: PermissionConstants.ONLY_ADMIN,
                caslAction: 'read',
                scope: 'tenant'
              }
            },
            {
              menu: {
                pageMapKey: 'mobileSettings',
                type: 'menu',
                caslKey: [PermissionConstants.MENU_WORKFLOWS],
                caslAction: 'allow',
                scope: 'tenant',
                featureGate: 'workflowsEnabled',
                featureGateValue: 'true'
              }
            },
            {
              menu: {
                pageMapKey: 'dispatchSettings',
                type: 'menu',
                caslKey: [PermissionConstants.MENU_SETTINGS],
                caslAction: 'allow',
                scope: 'tenant'
              }
            },
            {
              menu: {
                pageMapKey: 'logs',
                type: 'menu',
                caslKey: [PermissionConstants.MENU_SETTINGS],
                caslAction: 'allow',
                scope: 'tenant',
                featureGate: 'logs',
                featureGateValue: 'true'
              }
            },
            {
              ldFlag: FeatureFlags.SERVICE_AGREEMENTS,
              menu: {
                pageMapKey: 'serviceAgreementSettings',
                type: 'menu',
                caslKey: PermissionConstants.MENU_SETTINGS,
                caslAction: 'allow',
                scope: 'tenant',
                featureGate: 'serviceAgreements',
                featureGateValue: 'true'
              }
            }
          ]
        }
      },
      // Reporting
      {
        menu: {
          ldFlag: FeatureFlags.METABASE,
          title: 'Reports',
          type: 'menu',
          url: 'reports',
          caslKey: PermissionConstants.MENU_REPORTS_AND_DASHBOARDS,
          caslAction: 'allow',
          scope: 'tenant'
        }
      },
      {
        menu: {
          ldFlag: FeatureFlags.SIGMA,
          title: 'Dashboard v2',
          type: 'menu',
          url: 'reportsV2',
          caslKey: PermissionConstants.MENU_REPORTS_AND_DASHBOARDS,
          caslAction: 'allow',
          scope: 'tenant'
        }
      },
      // Operations
      {
        section: {
          title: 'Operations',
          isOpen: false,
          caslKey: PermissionConstants.MENU_LISTS,
          caslAction: 'allow',
          scope: 'tenant',
          url: 'operations',
          menus: [
            {
              menu: {
                pageMapKey: 'jobs',
                type: 'menu',
                scope: 'tenant',
                caslKey: PermissionConstants.OBJECT_JOB,
                caslAction: 'view'
              }
            },
            {
              ldFlag: FeatureFlags.SERVICE_AGREEMENTS,
              menu: {
                pageMapKey: 'maintenance',
                type: 'menu',
                caslKey: PermissionConstants.MENU_SERVICE_AGREEMENTS,
                caslAction: 'allow',
                scope: 'tenant',
                featureGate: 'serviceAgreements',
                featureGateValue: 'true'
              }
            },
            {
              menu: {
                pageMapKey: 'technicianReport',
                type: 'menu',
                scope: 'tenant',
                caslKey: PermissionConstants.MENU_LISTS,
                caslAction: 'allow'
              }
            },
            {
              menu: {
                pageMapKey: 'reviewReport',
                type: 'menu',
                scope: 'tenant',
                caslKey: PermissionConstants.MENU_LISTS,
                caslAction: 'allow'
              }
            },
            {
              ldFlag: FeatureFlags.QUOTESV2,
              notLdFlag: FeatureFlags.RECOMMENDED_TASKS_WORKFLOW,
              menu: {
                pageMapKey: 'quotes',
                type: 'menu',
                caslKey: PermissionConstants.OBJECT_QUOTES,
                caslAction: 'read',
                scope: 'tenant',
                featureGate: 'quotes',
                featureGateValue: 'true'
              }
            },
            {
              ldFlag: FeatureFlags.RECOMMENDED_TASKS_WORKFLOW,
              subSection: {
                pageMapKey: 'sales',
                title: 'Sales',
                scope: 'tenant',
                caslKey: PermissionConstants.MENU_LISTS,
                caslAction: 'allow',
                sections: ['quotes, recommendations'],
                icon: 'quotesIcon',
                menus: [
                  {
                    menu: {
                      pageMapKey: 'quotes',
                      type: 'menu',
                      caslKey: PermissionConstants.OBJECT_QUOTES,
                      caslAction: 'read',
                      scope: 'tenant',
                      featureGate: 'quotes',
                      featureGateValue: 'true'
                    }
                  },
                  {
                    menu: {
                      pageMapKey: 'recommendations',
                      type: 'menu',
                      scope: 'tenant'
                    }
                  }
                ]
              }
            },
            {
              ldFlag: FeatureFlags.TRUCK_STOCK_INVENTORY,
              menu: {
                pageMapKey: 'inventory',
                type: 'menu',
                scope: 'tenant',
                caslKey: PermissionConstants.MENU_INVENTORY,
                caslAction: 'allow'
              }
            },
            {
              ldFlag: FeatureFlags.PROCUREMENT,
              subSection: {
                pageMapKey: 'procurement',
                title: 'Procurement',
                scope: 'tenant',
                caslKey: PermissionConstants.MENU_LISTS,
                caslAction: 'allow',
                sections: ['purchase orders, receipts & bills'],
                icon: 'procurementIcon',
                menus: [
                  {
                    menu: {
                      pageMapKey: 'procurementPOs',
                      type: 'menu',
                      caslKey: PermissionConstants.MENU_PROCUREMENT,
                      caslAction: 'allow',
                      scope: 'tenant'
                    }
                  },
                  {
                    menu: {
                      pageMapKey: 'procurementRBs',
                      type: 'menu',
                      caslKey: PermissionConstants.MENU_PROCUREMENT,
                      caslAction: 'allow',
                      scope: 'tenant'
                    }
                  }
                ]
              }
            },
            {
              menu: {
                pageMapKey: 'followUps',
                type: 'menu',
                caslKey: PermissionConstants.MENU_FOLLOW_UPS,
                caslAction: 'allow',
                scope: 'tenant',
                featureGate: 'followUps',
                featureGateValue: 'true'
              }
            },
            {
              ldFlag: FeatureFlags.SERVICE_AGREEMENTS,
              menu: {
                pageMapKey: 'serviceAgreements',
                type: 'menu',
                caslKey: PermissionConstants.MENU_SERVICE_AGREEMENTS,
                caslAction: 'allow',
                scope: 'tenant',
                featureGate: 'serviceAgreements',
                featureGateValue: 'true'
              }
            }
          ]
        }
      },
      // Dispatch
      {
        menu: {
          notLdFlag: FeatureFlags.JOB_CLOSEOUT,
          title: PageMap.dispatch.title.en,
          type: 'menu',
          url: 'dispatch',
          caslKey: PermissionConstants.MENU_DISPATCH,
          caslAction: 'allow',
          scope: 'tenant'
        }
      },
      {
        section: {
          ldFlag: FeatureFlags.JOB_CLOSEOUT,
          title: 'Dispatch',
          isOpen: false,
          caslKey: PermissionConstants.MENU_LISTS,
          caslAction: 'allow',
          scope: 'tenant',
          url: '',
          menus: [
            {
              menu: {
                pageMapKey: 'dispatchBoard',
                type: 'menu',
                url: 'dispatch',
                caslKey: PermissionConstants.MENU_DISPATCH,
                caslAction: 'allow',
                scope: 'tenant'
              }
            },
            {
              menu: {
                pageMapKey: 'visits',
                type: 'menu',
                url: 'visit/list',
                caslKey: PermissionConstants.OBJECT_VISIT,
                caslAction: 'read',
                scope: 'tenant'
              }
            }
          ]
        }
      },
      // Accounting
      {
        section: {
          title: 'Accounting',
          isOpen: false,
          caslKey: PermissionConstants.MENU_LISTS,
          caslAction: 'allow',
          scope: 'tenant',
          sections: ['timesheets', 'invoice', 'payments', 'adjustments', 'payroll'],
          menus: [
            {
              subSection: {
                pageMapKey: 'invoices',
                title: 'Billing',
                scope: 'tenant',
                caslKey: PermissionConstants.MENU_LISTS,
                caslAction: 'allow',
                sections: ['invoice', 'recurring maintenance billing'],
                icon: 'invoicesIcon',
                menus: [
                  {
                    menu: {
                      pageMapKey: 'invoices',
                      type: 'menu',
                      scope: 'tenant',
                      caslKey: PermissionConstants.OBJECT_INVOICE,
                      caslAction: 'read'
                    }
                  },
                  {
                    menu: {
                      pageMapKey: 'recurringMaintenanceBilling',
                      type: 'menu',
                      scope: 'tenant',
                      caslKey: PermissionConstants.MENU_SERVICE_AGREEMENTS,
                      caslAction: 'read',
                      featureGate: 'recurringMaintenanceBilling',
                      featureGateValue: 'true'
                    }
                  }
                ]
              }
            },
            {
              menu: {
                pageMapKey: 'payments',
                type: 'menu',
                scope: 'tenant',
                caslKey: PermissionConstants.OBJECT_INVOICE,
                caslAction: 'read'
              }
            },
            {
              ldFlag: 'adjustments',
              menu: {
                pageMapKey: 'adjustments',
                type: 'menu',
                scope: 'tenant',
                caslKey: PermissionConstants.OBJECT_INVOICE,
                caslAction: 'read'
              }
            },
            {
              menu: {
                pageMapKey: 'timesheets',
                type: 'menu',
                caslKey: PermissionConstants.MENU_TIMESHEETS,
                caslAction: 'allow',
                scope: 'tenant',
                featureGate: 'timesheets',
                featureGateValue: 'true'
              }
            }
          ]
        }
      },
      // Directory
      {
        section: {
          title: 'Directory',
          isOpen: false,
          caslKey: PermissionConstants.MENU_LISTS,
          caslAction: 'allow',
          scope: 'tenant',
          sections: ['customer', 'property', 'serviceChannel'],
          testingid: 'navigationdirectory',
          menus: [
            {
              menu: {
                pageMapKey: 'customers',
                type: 'menu',
                scope: 'tenant',
                caslKey: PermissionConstants.OBJECT_CUSTOMER,
                caslAction: 'read',
                testingid: 'navigationselectcustomer'
              }
            },
            {
              menu: {
                pageMapKey: 'properties',
                type: 'menu',
                scope: 'tenant',
                caslKey: PermissionConstants.OBJECT_PROPERTY,
                caslAction: 'read'
              }
            },
            {
              ldFlag: FeatureFlags.SERVICE_CHANNEL_INTEGRATION,
              menu: {
                pageMapKey: 'serviceChannel',
                type: 'menu',
                scope: 'tenant',
                caslKey: PermissionConstants.MENU_SERVICE_CHANNEL,
                caslAction: 'allow'
              }
            },
            {
              ldFlag: FeatureFlags.SERVICE_AGREEMENTS_ADVANCED_SCHEDULING,
              menu: {
                pageMapKey: 'checklistLibrary',
                type: 'menu',
                scope: 'tenant',
                caslKey: PermissionConstants.MENU_LISTS,
                caslAction: 'allow'
              }
            }
          ]
        }
      },
      // Project Management
      {
        section: {
          ldFlag: FeatureFlags.PROJECT_MANAGEMENT,
          title: 'Projects',
          isOpen: false,
          caslAction: 'allow',
          caslKey: PermissionConstants.MENU_PROJECT_MANAGEMENT,
          scope: 'tenant',
          sections: ['project management', 'wip reports'],
          menus: [
            {
              menu: {
                pageMapKey: 'projectManagement',
                type: 'menu',
                scope: 'tenant',
                caslAction: 'read'
              }
            },
            {
              menu: {
                pageMapKey: 'wipReports',
                type: 'menu',
                scope: 'tenant',
                caslAction: 'read'
              }
            }
          ]
        }
      },
      // System Admin
      {
        section: {
          title: 'Admin',
          icon: 'settingsIcon',
          isOpen: true,
          tenantId: 'all',
          scope: 'global',
          caslKey: 'tenants',
          caslAction: 'manage',
          sections: ['settings'],
          menus: [
            {
              menu: {
                title: 'Tenants',
                type: 'menu',
                scope: 'global',
                url: 'admin/tenant',
                tenantId: 'all',
                caslKey: 'tenants',
                caslAction: 'manage'
              }
            }
          ]
        }
      }
    ]
  }
};
export default masterMenu;
