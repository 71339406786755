import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { debounce } from 'lodash';
import { func } from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CustomSearchBar from '@pm/components/CustomSearchBar';
import ProjectItemCard from '@pm/components/ProjectItemCard';
import { sortProjects } from '@pm/components/utils';
import Spinner from 'components/Spinners/CircularIndeterminate';
import Labels from 'meta/labels';
import { getProjectsByUser } from 'services/API/project';

import { useStyles } from './ProjectsGallery.styles';

const ProjectsGallery = ({ onAddProject }) => {
  const user = useSelector(store => store.user) || {};
  const styles = useStyles();
  const [projectListData, setProjectList] = useState([]);
  const [projectListOriginalData, setProjectListOriginal] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(true);

  const updateProjects = () => {
    setHasLoaded(false);
    getProjectsByUser(user.employeeId).then(projects => {
      const sortedProjects = sortProjects(projects);
      setProjectListOriginal(sortedProjects);
      setProjectList(sortedProjects);
      setHasLoaded(true);
    });
  };

  useEffect(() => {
    updateProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelayedUpdate = debounce(keyword => {
    if (!keyword) return;

    const searchResult = projectListData.filter(project =>
      project.name.toLowerCase().includes(keyword)
    );
    setProjectList(searchResult);
  }, 500);

  const handleProjectSearchCancel = () => {
    handleDelayedUpdate.cancel();
    setProjectList(projectListOriginalData);
  };

  if (!hasLoaded) {
    return <Spinner />;
  }

  return (
    <>
      <Box css={styles.searchContainer}>
        <CustomSearchBar
          key="projectManagementSearch"
          placeholder={Labels.auditFilterSearch[user.locale]}
          onCancelSearch={handleProjectSearchCancel}
          onChange={keyword => handleDelayedUpdate(keyword.toLowerCase())}
          onRequestSearch={keyword => handleDelayedUpdate(keyword.toLowerCase())}
        />
      </Box>
      <Grid container spacing={2}>
        {projectListData.map(project => {
          return (
            <Grid item key={project.id} lg={2} md={4} sm={6} xs={12}>
              <Link css={styles.projectLink} to={`/project/view/${project.id}/dashboard`}>
                <ProjectItemCard projectData={project} user={user} />
              </Link>
            </Grid>
          );
        })}
        <Grid item lg={2} md={4} sm={6} xs={12}>
          <Box css={styles.addProjectBtnContainer}>
            <Button
              css={styles.addProjectBtn}
              data-testid="project-list-add-button"
              startIcon={<AddCircleOutlineIcon />}
              variant="outlined"
              onClick={onAddProject}
            >
              {Labels.addProject[user.locale]}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

ProjectsGallery.propTypes = {
  onAddProject: func.isRequired
};

export default ProjectsGallery;
