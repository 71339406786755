import React from 'react';

import { useQuery } from '@apollo/client';
import { ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { Box, useTheme } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { LinkButton, ResponsiveTable } from 'components';
import { logErrorWithCallback } from 'utils';
import { EnumType } from 'utils/constants';

import { getRenewalHistory } from './gql';

const historyTableMeta = [
  {
    // id: 'serviceAgreement.agreementNumber', // not use
    label: 'Agreement Number',
    type: 'StyledLinkButton',
    isCustom: true
  },
  {
    id: 'serviceAgreement.status',
    label: 'Status',
    enumType: EnumType.SERVICE_AGREEMENT_STATUS,
    type: 'enum'
  },
  {
    id: 'renewalDate',
    label: 'Renewal Date',
    type: 'dateOnly'
  },
  {
    id: 'serviceAgreement.renewalNote',
    label: 'Renewal Notes',
    headerStyle: {
      width: '55%',
      minWidth: 100
    }
  }
];

export const RenewalHistory = ({ agreementInfo, showSnackbar }) => {
  const { palette, spacing } = useTheme();
  const { data: rawData, loading, error: queryError } = useQuery(getRenewalHistory, {
    variables: { id: agreementInfo.id },
    // when renewed the SA and going back to original SA, the history is refreshed.
    // As this tab is assumed to be used rarely, instead of writing cache going for network only queries
    fetchPolicy: 'network-only',
    skip: !agreementInfo?.id
  });
  const historyData = rawData?.serviceAgreement?.renewalGroup || [];

  if (queryError) {
    logErrorWithCallback(queryError, showSnackbar, 'Unable to query renewal history');
  }

  const StyledLinkButton = ({ record }) => (
    <>
      <LinkButton
        label={record?.serviceAgreement?.agreementNumber}
        path={`/serviceAgreement/view/${record?.serviceAgreement?.id}`}
      />
      {record?.serviceAgreement?.id === agreementInfo.id && (
        <VisibilityIcon
          fontSize="small"
          style={{ marginLeft: spacing(0.25), color: palette.secondary.main }}
        />
      )}
    </>
  );

  return (
    <>
      <ThemeProvider>
        <Box paddingY={2}>
          <Typography variant={TV.L} weight={TW.BOLD}>
            Renewal History
          </Typography>
        </Box>
      </ThemeProvider>
      {historyData?.length === 0 ? (
        <ThemeProvider>
          <Box
            alignItems="center"
            border={`dashed 2px ${palette.grayscale(60)}`}
            display="flex"
            justifyContent="center"
            minHeight="300px"
            paddingY={2}
          >
            <Box display="flex" flexDirection="column" m="0 auto" textAlign="center" width="245">
              <Typography variant={TV.XL}>No Renewal History</Typography>
              <Typography color={palette.grayscale(60)} variant={TV.BASE} weight={TW.REGULAR}>
                Previous agreements will appear once
              </Typography>
              <Typography color={palette.grayscale(60)} variant={TV.BASE} weight={TW.REGULAR}>
                this agreement has been renewed.
              </Typography>
            </Box>
          </Box>
        </ThemeProvider>
      ) : (
        <ResponsiveTable
          customCellComponents={{ StyledLinkButton }}
          data={historyData}
          defaults={{ sortBy: 'renewalDate', sortOrder: 'desc' }}
          disableFilter
          isLoading={loading}
          rowMetadata={historyTableMeta}
        />
      )}
    </>
  );
};
