import React from 'react';

import { calculateUnitPriceWithMarkup, convertForMathLib } from '@BuildHero/math';
import { jsx } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';

import AlgoliaSearch from 'components/AlgoliaSearch';
import { useLazyFetchPricebookEntry } from 'components/Tasks/components/useLazyFetchPricebookEntry';
import ErrorBoundaries from 'scenes/Error';

import isAssetEnabled from 'scenes/Quotes/components/QuoteSettings/defaultQuoteSettings';
import TableView from 'scenes/Quotes/components/Tasks/TaskAccordian/SelectAndTable/TableView/TableView';
import { Mode } from 'utils/constants';

import { useDeleteTaskEntryById } from '../../mutations';
import { usePricebookById } from '../../queries';

const style = {
  container: {
    '& div[id*="tableTitle"]': {
      fontSize: 16,
      paddingLeft: 0
    }
  },
  inputLabel: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'normal',
    letterSpacing: '0.01px',
    lineHeight: '14px'
  },
  searchbarGrid: {
    '& .MuiInputBase-root': {
      fontSize: 14,
      padding: '0 11px'
    }
  }
};

const CustomPartsAndMaterials = props => {
  const fetchPricebookEntry = useLazyFetchPricebookEntry();
  const [deleteTaskEntryById] = useDeleteTaskEntryById();
  const user = useSelector(state => state.user);

  const { form, props: sgtProps } = props;
  const { partsData } = form.values;
  const {
    isReadOnly,
    mode,
    searchParams: [searchParams],
    scopedPricebook
  } = sgtProps;
  const { data: pricebookData } = usePricebookById(scopedPricebook);

  const config = {
    showPricing: true,
    ...isAssetEnabled()
  };

  const handleSearchChange = async newItem => {
    if (!newItem?.id || partsData.some(existingPart => existingPart.productId === newItem.id)) {
      return;
    }

    const pEntry = await fetchPricebookEntry({
      pricebookId: scopedPricebook,
      productSortKey: newItem.sortKey
    });

    const markupValue = pEntry?.markupValue ?? pricebookData.markupValue ?? 0;
    const unitPrice = convertForMathLib(
      calculateUnitPriceWithMarkup,
      newItem.unitCost ?? 0,
      markupValue
    );

    const formattedTaskEntry = {
      name: newItem.name,
      description: newItem.description,
      unitPrice,
      unitCost: newItem.unitCost ?? 0,
      markupType: pEntry?.markupType || pricebookData.markupType || 'Percentage',
      markupValue,
      productId: newItem.id,
      quantity: newItem.quantity ?? 0,
      taxable: newItem.taxable,
      sortOrder: partsData.at(-1)?.sortOrder + 1 || 0,
      id: newItem.id
    };
    form.setFieldValue('partsData', [...partsData, formattedTaskEntry]);
  };

  const handleDeletePart = (entry, onCompleteCallback) => {
    const filteredParts = partsData.filter(item => item.productId !== entry.productId);
    form.setFieldValue('partsData', filteredParts);
    if (mode === Mode.EDIT) {
      deleteTaskEntryById({ id: entry.id, tenantId: user.tenantId });
    }
    onCompleteCallback();
  };

  const handleChangePart = (value, field, newItem) => {
    const newSelectedParts = partsData.map(item =>
      item.productId !== newItem.productId ? item : newItem
    );

    form.setFieldValue('partsData', newSelectedParts);
  };

  return (
    <ErrorBoundaries>
      <Grid css={style.container} item lg={12} md={12} sm={12} xl={12} xs={12}>
        <TableView
          config={config}
          data={partsData}
          entityName="Item"
          handleChange={handleChangePart}
          handleDelete={handleDeletePart}
          isReadOnly={isReadOnly}
          labels={{
            name: 'ITEM NAME',
            title: 'Parts & Materials',
            total: 'PARTS TOTAL'
          }}
          skipMutation
        />
      </Grid>
      <Grid
        css={style.searchbarGrid}
        item
        lg={12}
        md={12}
        sm={12}
        styles={{ padding: 0 }}
        xl={12}
        xs={12}
      >
        {searchParams && (
          <AlgoliaSearch
            delimiter=" - "
            displayText={['name', 'code']}
            filters={[
              {
                attribute: 'entityType',
                valueArray: ['Product']
              }
            ]}
            greyBackground
            handleChange={handleSearchChange}
            label=""
            name="selectProduct"
            placeholder="Search and Select"
            searchIndex={searchParams.searchIndex}
            value=""
          />
        )}
      </Grid>
    </ErrorBoundaries>
  );
};

export default CustomPartsAndMaterials;
