import React from 'react';

import { Link } from 'react-router-dom';

import JobNumber from '@dispatch/components/JobNumber';
import { JobTypes } from '@dispatch/Dispatch.constants';
import { JobPropTypes } from '@dispatch/Dispatch.props';

import { Job, Maintenance } from '@dispatch/icons';
import Routes from 'scenes/Routes';

import { useStyles } from './JobLink.styles';

const JobLink = ({ job }) => {
  const isMaintenance = job?.jobTypeInternal === JobTypes.MAINTENANCE.value.serverValue;
  const route = isMaintenance ? Routes.maintenance : Routes.job;
  const styles = useStyles();

  return (
    <Link to={route({ mode: 'view', id: encodeURIComponent(job?.jobNumber) })}>
      <img alt="job-type" css={styles.icon} src={isMaintenance ? Maintenance : Job} />
      <JobNumber job={job} />
    </Link>
  );
};

JobLink.propTypes = {
  job: JobPropTypes.isRequired
};

export default JobLink;
