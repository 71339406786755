import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { array, func, object, string } from 'prop-types';

import EventBadge from '@dispatch/components/Drawer/components/EventBadge';
import { EVENT_TYPES } from '@dispatch/Dispatch.constants';
import { isNVEDeleteDisabled } from '@dispatch/Dispatch.utils';

import { useFormService } from '@dispatch/hooks';
import { getState } from 'redux/store';

import BillableEventForm from '../BillableEventForm';
import DrawerLayout from '../DrawerLayout';
import NonVisitStatusHeader from '../NonVisitStatusHeader';
import VisitDetailsHeader from '../VisitDetailsHeader';

const EditBillableEvent = ({
  billableEventId,
  closeDrawer,
  billableNonVisitEventDetailsResponse,
  deleteBillableNonVisitTuple,
  updateBillableNonVisitTuple
}) => {
  const flags = useFlags();
  const { tenantId } = getState().user;
  const { isDirty, onCreateService, onDirtyChange, resetForm, submitForm } = useFormService();
  const { data, loading: fetching } = billableNonVisitEventDetailsResponse;
  const [triggerUpdate, updateResponse] = updateBillableNonVisitTuple;
  const { loading: updating } = updateResponse;
  const [triggerDelete, deleteResponse] = deleteBillableNonVisitTuple;
  const { loading: deleting } = deleteResponse;

  const loading = fetching || deleting || updating;

  const handleDelete = () => {
    triggerDelete({
      eventId: billableEventId,
      tenantId
    });
    closeDrawer();
  };

  const updateBillableNonVisitEvent = eventData => {
    triggerUpdate({
      eventId: billableEventId,
      tenantId,
      originalEvent: data,
      data: {
        ...eventData,
        version: data.version
      }
    });
    closeDrawer();
  };

  const renderHeader = () => {
    return (
      <>
        <EventBadge eventType={EVENT_TYPES.BILLABLE_EVENT} />
        <NonVisitStatusHeader event={data} />

        {!!data?.visit && (
          <VisitDetailsHeader job={data?.visit?.job} loading={fetching} visit={data?.visit} />
        )}
      </>
    );
  };

  const renderBody = () => (
    <BillableEventForm
      disabled={loading}
      eventData={data}
      visit={data?.visit}
      visitDataLoading={fetching}
      onComplete={updateBillableNonVisitEvent}
      onCreateService={onCreateService}
      onDirtyChange={onDirtyChange}
    />
  );

  return (
    <DrawerLayout
      disabledClose={loading}
      disabledDelete={isNVEDeleteDisabled(data, flags)}
      isDirty={isDirty}
      loading={loading}
      renderBody={renderBody}
      renderHeader={renderHeader}
      onDelete={handleDelete}
      onResetForm={resetForm}
      onSubmitForm={submitForm}
    />
  );
};

EditBillableEvent.propTypes = {
  billableEventId: string.isRequired,
  billableNonVisitEventDetailsResponse: object.isRequired,
  closeDrawer: func.isRequired,
  deleteBillableNonVisitTuple: array.isRequired,
  updateBillableNonVisitTuple: array.isRequired
};

export default EditBillableEvent;
