import React, { useMemo, useState } from 'react';

import { Button, ButtonType, Modal, ThemeProvider } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { XGrid } from 'components';
import { useConfirmModal } from 'customHooks/ConfirmModalContext';

import CreateRecommendationModal from 'scenes/RecommendedTasks/CreateModal';
import recommendationsColumns from 'scenes/RecommendedTasks/ListView/meta';
import {
  useAddTaskEntryToTask,
  useDeleteAttachmentById,
  useDeleteFormById,
  useDeleteTaskById,
  useUpdateTask,
  useUpdateTaskEntries
} from 'scenes/RecommendedTasks/mutations';
import GET_RECOMMENDED_TASK_LIST, {
  useRecommendedTaskList
} from 'scenes/RecommendedTasks/queries/useTaskList';
import { BuildHeroThemeProvider } from 'themes/BuildHeroTheme';
import { TaskStatus } from 'utils/AppConstants';

import { handleAddToQuote, handleCancel } from './index.handlers';

const RecommendedTasksModal = ({
  addQuoteLoading,
  addTaskService,
  fetchTasksOnCustomerProperty,
  isModalOpen,
  propertyId,
  propertyName,
  quoteInfo,
  setAddQuoteLoading,
  setIsModalOpen,
  snackbarOn
}) => {
  const user = useSelector(s => s.user);

  const [modalConfig, setModalConfig] = useState({});
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [refetchTrigger, setRefetchTrigger] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [addTaskEntryToTask, { loading: addingTaskEntry }] = useAddTaskEntryToTask();
  const [deleteAttachmentById, { loading: deletingAttachment }] = useDeleteAttachmentById();
  const [deleteFormById, { loading: deletingForm }] = useDeleteFormById();
  const [deleteTaskById, { loading: deletingTask }] = useDeleteTaskById();
  const [updateTask, { loading: updatingTask }] = useUpdateTask();
  const [updateTaskEntries, { loading: updatingTaskEntries }] = useUpdateTaskEntries();
  const { refetch: refetchTaskList, data: recommendedTaskResponse } = useRecommendedTaskList(
    user.tenantId
  );
  const confirmContext = useConfirmModal();

  const isLoading =
    addingTaskEntry ||
    deletingAttachment ||
    deletingForm ||
    deletingTask ||
    updatingTask ||
    updatingTaskEntries;

  const silentFilter = [
    { columnField: 'customerPropertyId', operatorValue: 'equals', value: propertyId },
    { columnField: 'status', operatorValue: 'equals', value: TaskStatus.OPEN }
  ];
  const columns = useMemo(() => {
    refetchTaskList();
    return recommendationsColumns({
      addTaskEntryToTask,
      confirmContext,
      deleteAttachmentById,
      deleteFormById,
      deleteTaskById,
      isLoading,
      refetchTrigger,
      refetchTaskList,
      hideCreatedDate: true,
      hideProperty: true,
      selectedTab: 0,
      setIsCreateModalOpen,
      setModalConfig,
      setRefetchTrigger,
      setSelectionModel,
      tenantId: user.tenantId,
      updateTask,
      updateTaskEntries
    });
  }, [
    addTaskEntryToTask,
    confirmContext,
    deleteAttachmentById,
    deleteFormById,
    deleteTaskById,
    isLoading,
    refetchTrigger,
    refetchTaskList,
    setRefetchTrigger,
    setSelectionModel,
    updateTask,
    updateTaskEntries,
    user.tenantId
  ]);

  const memoizedXGrid = useMemo(() => {
    return (
      <div css={{ position: 'relative', height: '100%' }}>
        <BuildHeroThemeProvider>
          <XGrid
            checkboxSelection
            columns={columns}
            entityTypeName="RecommendedTask"
            query={GET_RECOMMENDED_TASK_LIST}
            refetchTrigger={refetchTrigger}
            rowHeight={38}
            selectionModel={selectionModel}
            silentFilter={silentFilter}
            tableName="RecommendedTaskXGrid"
            onSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel)}
          />
        </BuildHeroThemeProvider>
      </div>
    );
  }, [columns, refetchTrigger, selectionModel, silentFilter]);

  return (
    <ThemeProvider>
      <Modal
        actions={[
          <Button
            fullWidth
            type={ButtonType.TERTIARY}
            onClick={() => handleCancel({ setIsModalOpen, setSelectionModel })}
          >
            Cancel
          </Button>,
          <Button
            fullWidth
            loading={addQuoteLoading}
            onClick={() =>
              handleAddToQuote({
                addTaskService,
                fetchTasksOnCustomerProperty,
                propertyId,
                quoteInfo,
                recommendedTaskResponse,
                selectionModel,
                setAddQuoteLoading,
                setIsModalOpen,
                snackbarOn,
                tenantId: user.tenantId
              })
            }
          >
            Add to Quote
          </Button>
        ]}
        open={isModalOpen}
        PaperProps={{ style: { minWidth: '80%', height: '100%' } }}
        title={`Recommendations for ${propertyName}`}
        onClose={() => setIsModalOpen(false)}
      >
        {memoizedXGrid}
      </Modal>
      <CreateRecommendationModal
        isCreateModalOpen={isCreateModalOpen}
        modalConfig={modalConfig}
        refetchTaskList={refetchTaskList}
        refetchTrigger={refetchTrigger}
        setIsCreateModalOpen={setIsCreateModalOpen}
        setRefetchTrigger={setRefetchTrigger}
        updateTask={updateTask}
      />
    </ThemeProvider>
  );
};

export default RecommendedTasksModal;

RecommendedTasksModal.propTypes = {
  addQuoteLoading: PropTypes.bool,
  addTaskService: PropTypes.func.isRequired,
  fetchTasksOnCustomerProperty: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  propertyId: PropTypes.string,
  propertyName: PropTypes.string,
  quoteInfo: PropTypes.object,
  setAddQuoteLoading: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  snackbarOn: PropTypes.func.isRequired
};

RecommendedTasksModal.defaultProps = {
  addQuoteLoading: false,
  isModalOpen: false,
  propertyId: '',
  propertyName: '',
  quoteInfo: {}
};
