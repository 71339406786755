import React from 'react';

import { Button, ThemeProvider } from '@BuildHero/sergeant';
import { ButtonGroup } from '@material-ui/core';
import { bool, func } from 'prop-types';

const FlaggedVisitSelector = ({ flaggedVisits, setFlaggedVisits }) => (
  <ThemeProvider>
    <ButtonGroup>
      <Button
        type={!flaggedVisits ? 'secondary' : 'tertiary'}
        onClick={() => setFlaggedVisits(false)}
      >
        All
      </Button>
      <Button
        type={flaggedVisits ? 'secondary' : 'tertiary'}
        onClick={() => setFlaggedVisits(true)}
      >
        Flagged
      </Button>
    </ButtonGroup>
  </ThemeProvider>
);

FlaggedVisitSelector.propTypes = {
  flaggedVisits: bool,
  setFlaggedVisits: func.isRequired
};

FlaggedVisitSelector.defaultProps = {
  flaggedVisits: false
};

export default FlaggedVisitSelector;
