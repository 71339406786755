import React from 'react';

import { MUIFormComponents } from '@BuildHero/sergeant';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Moment from 'moment';
import PropTypes from 'prop-types';
import SignaturePad from 'react-signature-canvas';

import { getCustomerSignatureSrc } from 'utils';
import AppConstants from 'utils/AppConstants';

const { AlignmentWrapper, LabelControl, ErrorMessage } = MUIFormComponents;

export class RawSignatureScreen extends React.Component {
  constructor(props) {
    super(props);
    const { field, options } = this.props;
    const { value } = field;
    const { readOnly } = options;
    let initialValues = { name: '', date: Moment().unix(), signature: null };
    let isEdit = true;
    if (value.length) {
      try {
        initialValues = JSON.parse(value);
        isEdit = false;
      } catch (error) {
        console.log(`Signature Values:${error}`);
      }
    }

    const { name, date, signature } = initialValues;

    this.state = {
      name,
      date,
      signature,
      readOnly,
      isEdit
    };
  }

  clear = () => {
    this.sigPad.clear();
  };

  edit = () => {
    this.setState({ name: '', date: Moment().unix(), signature: null, isEdit: true });
  };

  updateSignature = () => {
    const sig = this.sigPad.getTrimmedCanvas().toDataURL();
    this.updateField('signature', sig);
  };

  updateField = (fieldName, value) => {
    const { form, field, options } = this.props;
    const { isRequired } = options;
    const { name, date, signature } = this.state;
    const sigObj = { name, date, signature };
    sigObj[fieldName] = value;

    this.setState(sigObj);
    if (isRequired) {
      // Check for Name and Signature
      if (sigObj.name && sigObj.signature) {
        form.setFieldValue(field.name, JSON.stringify(sigObj));
      }
    } else {
      form.setFieldValue(field.name, JSON.stringify(sigObj));
    }
  };

  renderSignatureImage() {
    const { classes } = this.props;
    const { name, date, signature, readOnly } = this.state;
    const formatDate = Moment.unix(date).format(AppConstants.DATE_FORMAT);

    const signatureSource = getCustomerSignatureSrc({ signatureImageUrl: signature });

    return (
      <div className={classes.container}>
        {!readOnly && (
          <Button className={classes.rightBtn} onClick={this.edit}>
            Edit
          </Button>
        )}
        {signatureSource && (
          <img alt="Signature" className={classes.sigImage} src={signatureSource} />
        )}
        <Typography gutterBottom variant="h6">{`${name} - ${formatDate}`}</Typography>
      </div>
    );
  }

  renderSignatureView(error) {
    const { classes, options } = this.props;
    const { name, date } = this.state;
    const { isRequired } = options;
    const formatDate = Moment.unix(date).format(AppConstants.DATE_FORMAT);

    return (
      <div className={classes.container}>
        <div className={classes.inputRow}>
          <div className={classes.inputWrapper}>
            <LabelControl required={isRequired} value="Print Name" />
            <TextField
              error={error && error !== ''}
              name="Print Name"
              value={name}
              variant="outlined"
              onChange={e => this.updateField('name', e.target.value)}
            />
            <ErrorMessage value={error} />
          </div>
          <div className={classes.inputWrapper}>
            <LabelControl value="Today's Date" />
            <Typography variant="body1">{formatDate}</Typography>
          </div>
        </div>
        <div className={classes.sigWrapper}>
          <Button className={classes.rightBtn} onClick={this.clear}>
            Clear
          </Button>
          <div className={classes.sigContainer}>
            <SignaturePad
              canvasProps={{ className: classes.sigPad }}
              ref={ref => {
                this.sigPad = ref;
              }}
              onEnd={this.updateSignature}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { form, field, options } = this.props;
    const { label, isRequired, alignment } = options;
    const { isEdit } = this.state;
    const error = form.errors && form.errors[field.name];

    return (
      <AlignmentWrapper alignment={alignment}>
        <div>
          <LabelControl required={isRequired} value={label} />
          {isEdit ? this.renderSignatureView(error) : this.renderSignatureImage()}
          <ErrorMessage value={error} />
        </div>
      </AlignmentWrapper>
    );
  }
}

const styles = theme => ({
  container: {
    width: '100%',
    maxWidth: 400,
    padding: '6px 0',
    position: 'relative'
  },
  sigWrapper: {
    width: '100%',
    margin: '0 auto',
    border: '2px solid black',
    backgroundColor: '#f5f5f5'
  },
  sigContainer: {
    clear: 'both',
    backgroundColor: '#fff'
  },
  sigPad: {
    width: '100%',
    minHeight: 120,
    height: '100%'
  },
  leftBtn: {
    float: 'left'
  },
  rightBtn: {
    float: 'right'
  },
  sigImage: {
    backgroundColor: 'white',
    width: '100%',
    height: 'auto',
    maxHeight: 150,
    border: '2px solid black'
  },
  inputRow: {
    display: 'flex',
    flex: 1,
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'start'
  },
  inputWrapper: {
    marginBottom: 12,
    marginRight: 12
  }
});

const styled = withStyles(styles)(RawSignatureScreen);
export { styled as SignatureScreen };

RawSignatureScreen.propTypes = {
  options: PropTypes.shape({
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    readOnly: PropTypes.bool
  }).isRequired
};
