import React, { useRef, useState } from 'react';

import { Grid } from '@material-ui/core';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CorruptData, PageHeader } from 'components';
import DefaultButton from 'components/Buttons/DefaultButton';
import SplitButton from 'components/Buttons/SplitButton';
import LeavingPageModal from 'components/Modal/LeavingPageModal';
import Spinner from 'components/Spinners/CircularIndeterminate';
import { useTrigger } from 'customHooks/useTrigger';
import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { sentryMessage } from 'services/Logger';
import { checkPermission } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';

import { reportTypes } from './constants';
import DaySummary from './DaySummary';
import EmployeeSelector from './EmployeeSelector';
import {
  addShiftEndToEntry,
  compareDayWithWeekEnd,
  generateWeekDate,
  getTimesheetPeriodSummary
} from './helpers';
import HourSummary from './HourSummary';
import {
  approveTimesheetPeriod,
  getPayrollHourTypes,
  getPayrollSettings,
  getTimesheetPeriodById,
  getTimesheetPeriods,
  handleTimesheetDownload
} from './services';
import useStyles from './styles';
import TimesheetPeriodSelector from './TimesheetPeriodSelector';

const TimeTrackingReport = ({ user, ...props }) => {
  const history = useHistory();
  const classes = useStyles();
  const [timesheetPeriods, setTimesheetPeriods] = useState([]);
  const [leaveConfirmation, setLeaveConfirmation] = useState(false);
  const [payrollSetting, setPayrollSetting] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTimesheetPeriod, setSelectedTimesheetPeriod] = useState({});
  const [fullTimesheetPeriod, setFullTimesheetPeriod] = useState({});
  const [hourTypes, setHourTypes] = useState([]);
  const [weekDates, setWeekDates] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [isExportLoading, setIsExportLoading] = useState(false);

  const [reset, trigger] = useTrigger();

  const overAllHourSummaryRef = useRef({});
  const dayApprovedStatusRef = useRef({});

  const hasEditPermission = checkPermission(
    'allow',
    PermissionConstants.FUNCTIONS_TIMETRACKING_REPORT,
    user
  );

  React.useEffect(() => {
    getPayrollHourTypes({ user, snackbarOn: props.snackbarOn, successCallback: setHourTypes });
    getPayrollSettings({ user, snackbarOn: props.snackbarOn, successCallback: setPayrollSetting });
  }, [user, props.snackbarOn]);

  const handleApprovalState = status => {
    dayApprovedStatusRef.current = { ...dayApprovedStatusRef.current, ...status };
    trigger();
  };

  const handleEditTimesheetEntries = (newEntries, dayStartUTC) => {
    const weekDatesData = weekDates.map(day => {
      if (day.dayStartUTC !== dayStartUTC) return day;
      return {
        ...day,
        timesheetEntries: newEntries
      };
    });
    setWeekDates(weekDatesData);
  };

  const fetchTimeSheetPeriods = async ({
    employee = selectedEmployee,
    updateDateApproval = false
  }) => {
    const successCallback = data => {
      setTimesheetPeriods(data);
    };
    await getTimesheetPeriods({
      employee,
      setLoader: setIsLoading,
      snackbarOn: props.snackbarOn,
      successCallback
    });
    if (updateDateApproval) {
      const updatedSelectedTimesheetPeriod = { ...selectedTimesheetPeriod, approved: false };
      setSelectedTimesheetPeriod(updatedSelectedTimesheetPeriod);
    }
  };

  const handleEmployeeSelection = employee => {
    setSelectedEmployee(employee);
    setWeekDates([]);
    setSelectedTimesheetPeriod({});

    overAllHourSummaryRef.current = {};

    fetchTimeSheetPeriods({ employee });
  };

  const updateOverAllHourSummary = data => {
    overAllHourSummaryRef.current = { ...overAllHourSummaryRef.current, ...data };
    trigger();
  };

  const handleTimesheetPeriodSelection = async timesheetPeriod => {
    setWeekDates([]);
    overAllHourSummaryRef.current = {};

    const period = timesheetPeriod || selectedTimesheetPeriod;
    setSelectedTimesheetPeriod(period);

    const successCallback = data => {
      setFullTimesheetPeriod(data);

      let allRelevantEntries = [];

      // @TODO - need to discuss how to handle entries with a null actualEndTimeUTC

      const entriesMissingAStartDate = data.timesheetEntriesView.items.filter(
        e => !e.actualStartTimeUTC
      );

      if (entriesMissingAStartDate.length) {
        sentryMessage('Automatic Timesheet Entries - missing start time', {
          entriesMissingAStartDate
        });
      }

      const entriesWithAStartDate = data.timesheetEntriesView.items.filter(
        e => e.actualStartTimeUTC
      );

      const weekDatesData = generateWeekDate(data, payrollSetting.timeZone).map(day => {
        const relevantEntries = entriesWithAStartDate.filter(
          entry =>
            // entry.actualEndTimeUTC <= day.dayEndUTC && entry.actualEndTimeUTC >= day.dayStartUTC
            entry.actualStartTimeUTC <= day.dayEndUTC && entry.actualStartTimeUTC >= day.dayStartUTC
        );
        allRelevantEntries = [...allRelevantEntries, ...relevantEntries];
        return {
          ...day,
          timesheetEntries: addShiftEndToEntry(relevantEntries)
        };
      });

      const corruptEntries = entriesWithAStartDate.filter(
        item => !allRelevantEntries.find(entry => entry.id === item.id)
      );

      if (corruptEntries.length) {
        sentryMessage('Automatic Timesheet Entries - entries attached to wrong timeperiod', {
          corruptEntries,
          period: data
        });
      }

      setWeekDates(weekDatesData);
    };

    getTimesheetPeriodById({
      employee: selectedEmployee,
      id: period.id,
      setLoader: setIsLoading,
      snackbarOn: props.snackbarOn,
      successCallback
    });
    dayApprovedStatusRef.current = {};
  };

  const handleExportClick = async summaryType => {
    setIsExportLoading(true);
    await handleTimesheetDownload({
      snackbarOn: props.snackbarOn,
      selectedTimesheetPeriod,
      summaryType
    });
    setIsExportLoading(false);
  };

  const isDayPassedToWeekend = compareDayWithWeekEnd(
    selectedTimesheetPeriod.dateEndUTC,
    payrollSetting.timeZone
  );

  const approvedStatus = Object.values(dayApprovedStatusRef.current);

  const isApproved = [
    isDayPassedToWeekend,
    !isEmpty(approvedStatus),
    !approvedStatus.includes(false),
    weekDates.length === approvedStatus.length,
    !selectedTimesheetPeriod?.approved
  ].every(Boolean);

  const onApprovedTimesheetPeriod = ({ data }) => {
    const index = timesheetPeriods.findIndex(e => e.id === data.approveTimesheetPeriod.id);

    const updatetimesheetPeriods = [...timesheetPeriods];

    updatetimesheetPeriods[index] = {
      ...updatetimesheetPeriods[index],
      approved: true
    };

    const updatedSelectedDate = { ...selectedTimesheetPeriod, approved: true };

    setSelectedTimesheetPeriod(updatedSelectedDate);
    setTimesheetPeriods(updatetimesheetPeriods);
    dayApprovedStatusRef.current = {};
  };

  return (
    <ErrorBoundaries>
      <Grid className={classes.header}>
        <PageHeader
          breadcrumbsArray={[
            { title: Labels.accountingLabel[user.locale], link: '' },
            { title: Labels.payroll[user.locale], link: '/accounting/payroll/report/time-tracking' }
          ]}
          overrideHeaderButtons={
            hasEditPermission
              ? [
                  <DefaultButton
                    color="primary"
                    disabled={!isApproved}
                    handle={() => {
                      approveTimesheetPeriod({
                        timesheetPeriodId: selectedTimesheetPeriod.id,
                        partitionKey: user.partitionKey,
                        snackbarOn: props.snackbarOn,
                        successCallback: onApprovedTimesheetPeriod
                      });
                    }}
                    label="Approve"
                    style={{ marginRight: 8 }}
                  />,
                  <SplitButton
                    buttonProps={{
                      startIcon: <OpenInNewIcon />
                    }}
                    className={classes.exportButton}
                    disabled={isEmpty(selectedTimesheetPeriod) || isExportLoading}
                    label="Export"
                    options={[
                      {
                        label: 'Weekly Report ',
                        onClick: () => handleExportClick(reportTypes.WEEKLY)
                      },
                      {
                        label: 'Summary Report ',
                        onClick: () => handleExportClick(reportTypes.SUMMARY)
                      }
                    ]}
                    showSpinner={isExportLoading}
                  />
                ]
              : []
          }
          pageMapKey="timesheets"
          userLocale={user.locale}
        />
      </Grid>
      <Grid
        alignItems="flex-start"
        container
        justify="space-between"
        style={{ minHeight: '500px', paddingTop: '24px' }}
      >
        <Grid className={classes.filterSection} item>
          <EmployeeSelector
            selectedEmployee={selectedEmployee}
            snackbarOn={props.snackbarOn}
            user={user}
            onSelectEmployee={handleEmployeeSelection}
          />
          <TimesheetPeriodSelector
            selected={selectedTimesheetPeriod}
            timesheetPeriods={timesheetPeriods}
            onChange={handleTimesheetPeriodSelection}
          />
          {isLoading && <Spinner size={24} />}
        </Grid>
        <HourSummary
          className={classes.hourSummary}
          data={getTimesheetPeriodSummary({
            hourTypes,
            overAllHourSummary: overAllHourSummaryRef.current
          })}
          isVisible={!isEmpty(overAllHourSummaryRef.current)}
          user={user}
        />
        {weekDates.map(date => (
          <DaySummary
            dailyOverrides={fullTimesheetPeriod?.timesheetDailyOverrides?.items || []}
            dateRange={date}
            employee={selectedEmployee}
            fetchTimeSheetPeriods={fetchTimeSheetPeriods}
            handleEditTimesheetEntries={handleEditTimesheetEntries}
            hourTypes={hourTypes}
            key={date.dayStartUTC}
            refetch={() => handleTimesheetPeriodSelection()}
            setDayApprovedStatus={handleApprovalState}
            setLeaveConfirmation={setLeaveConfirmation}
            snackbarOn={props.snackbarOn}
            timesheetEntries={date.timesheetEntries}
            timesheetPeriodId={fullTimesheetPeriod.id}
            updateOverAllHourSummary={updateOverAllHourSummary}
            user={user}
          />
        ))}
      </Grid>
      <LeavingPageModal navigate={history} when={leaveConfirmation} />
    </ErrorBoundaries>
  );
};

const mapStateToProps = state => ({
  user: state.user
});
const mapDispatcherToProps = { snackbarOn };

const connectedJobs = connect(mapStateToProps, mapDispatcherToProps)(TimeTrackingReport);

export default connectedJobs;
