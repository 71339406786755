import { useSelector } from 'react-redux';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { getServiceAgreementById } from 'customHooks/useServiceAgreement';
import { addPropertyAssetsToCustomerProperty } from 'scenes/Customer/Assets/gql';

const useAddPropertyAssetToCustomerProperty = serviceAgreementId => {
  const user = useSelector(state => state.user);
  return useExtendedMutation(addPropertyAssetsToCustomerProperty, {
    serializer: ({ propertyToAddTo, payload }) => ({
      variables: {
        data: {
          customerPropertyId: propertyToAddTo,
          propertyAssets: [{ ...payload }]
        }
      }
    }),
    update: (cache, { data }, { propertyToAddTo }) => {
      const query = {
        query: getServiceAgreementById,
        variables: {
          id: serviceAgreementId,
          partitionKey: user.tenantId
        }
      };

      const cachedData = cache.readQuery(query);

      cache.writeQuery({
        ...query,
        data: {
          serviceAgreement: {
            ...(cachedData?.serviceAgreement || {}),
            customerProperties: {
              items: [
                ...cachedData?.serviceAgreement?.customerProperties?.items?.map(property => {
                  if (property?.id === propertyToAddTo) {
                    return {
                      ...property,
                      propertyAssets: {
                        items: [
                          ...property?.propertyAssets?.items,
                          ...data?.addPropertyAssetsToCustomerProperty
                        ]
                      }
                    };
                  }
                  return property;
                })
              ]
            }
          }
        }
      });
    },
    onSuccess: (data, snackbar) => {
      snackbar('success', 'Property Asset added');
    },
    onError: (error, snackbar) => {
      snackbar('error', 'Unable to add Property Asset');
    }
  });
};

export default useAddPropertyAssetToCustomerProperty;
