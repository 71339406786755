import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function QuotesProfitability(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M18.6069 2.1175C17.4206 2.1175 17.4206 0.280273 18.6069 0.280273H23.0784C23.6259 0.280273 23.991 0.73958 23.991 1.19889V5.7001C23.991 6.89429 22.1659 6.89429 22.1659 5.7001V3.40356L14.8655 10.7525C14.5005 11.1199 13.9529 11.1199 13.5879 10.7525L9.75523 6.98616L1.5423 15.2537C0.721005 16.0804 -0.556561 14.7944 0.264732 13.9676L9.11644 4.9652C9.48146 4.59776 10.1202 4.68962 10.4853 5.05707L14.2267 8.82338L20.8883 2.1175H18.6069Z"
        fill="#2DCD88"
      />
    </SvgIcon>
  );
}
