import gql from 'graphql-tag';

const getTasksByCustomerPropertyById = gql`
  query getCustomerPropertyById(
    $id: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getCustomerPropertyById(id: $id) {
      id
      tasks: tasksView(
        entityConnection: "Task"
        pagination: { limit: $limit, offset: $offset }
        filter: $filter
        sorting: $sort
      ) {
        nextToken
        items {
          id
          sortKey
          version
          name
          createdBy
          createdDateTime
          lastUpdatedBy
          lastUpdatedDateTime
          nextDueDate
          description
          departmentId
          departmentValue
          taskTypeId
          taskTypeValue
          taskTypeInternal
          taskNumber
          status
          isRecommended
          jobTypeId
          jobTypeValue
          assetId
          assetValue
          productBundleId
          productBundleValue
          isActive
          total: aggregatedField(
            input: {
              entityConnection: "TaskEntry"
              isDirectChildren: true
              aggregations: [
                {
                  aggregationType: SUM
                  aggregationField: "TaskEntry.unitPrice * TaskEntry.quantity"
                }
              ]
            }
          ) {
            items {
              total: aggregation1
            }
          }
          products: taskEntriesView(entityConnection: "TaskEntry") {
            nextToken
            items {
              id
              sortKey
              version
              isActive
              entityType
              name
              description
              markupType
              markupValue
              unitPrice
              unitCost
              quantity
              taxable
              productId
              product {
                id
                name
                description
                taxable
                costCodeId
                revenueTypeId
                jobCostTypeId
              }
            }
          }
          auditLogEntries(entityConnection: "AuditLogEntry") {
            items {
              auditedEntityType
              auditedEntityDisplayName
              executionType
              executedBy
              executedDateTime
              auditedEntityId
              auditedEntitySortKey
              auditedEntityParentId
              auditedEntityParentSortKey
              auditedEntityParentEntityType
              auditedEntityParentDisplayName
              changeLog
            }
          }
          jobs {
            id
            customIdentifier
            jobNumber
            jobTypeInternal
          }
          quote {
            id
            quoteNumber
            customIdentifier
          }
        }
      }
    }
  }
`;
export default getTasksByCustomerPropertyById;
