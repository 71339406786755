import React from 'react';

import 'react-loading-skeleton/dist/skeleton.css';
import { theme as sgtTheme } from '@BuildHero/sergeant';
import createCache from '@emotion/cache';
import { CacheProvider, ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { LoadScript } from '@react-google-maps/api';
import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { create } from 'jss';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// false positive
// eslint-disable-next-line import/no-unresolved

import App from './App';
import DnDProvider from './components/DnDProvider';
import { tenantCompanyClient } from './configs/aws-clients';
import ENV from './configs/env';
import store from './redux/store';
import CatchErrors from './scenes/Error';
import AmplifyService from './services/AmplifyService';
import theme from './themes/BuildHeroTheme';

import { GOOGLE_MAPS_API_KEY } from './utils/AppConstants';

// For dependencies without access to ENV
if (!window.BuildOps) window.BuildOps = {};
window.BuildOps.ENV = ENV;

AmplifyService.configure();
const { auth } = AmplifyService.startServices(store);

// TODO, use process.env.npm_package_version for release when we
// tie it into our build process.

// TODO, move to @sentry/react
Sentry.init({
  dsn: 'https://9fb6cc8b8ce34586a4491c292bad05cb@sentry.io/1447728',
  environment: ENV,
  release: `webapp@${process.env.REACT_APP_RELEASE || process.env.npm_package_version}`,
  integrations: [new TracingIntegrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // eslint-disable-next-line no-unused-vars
  tracesSampler: samplingContext => {
    if (ENV === 'prod') {
      return 1.0; // we will keep it at 1.0 until we have a full capture, then start lowering.
    }
    return 0.1;
  }
});

const emotionCache = createCache({
  key: 'webapp-emotion',
  container: document.getElementById('webapp-emotion-insertion-point')
});

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('webapp-mui-insertion-point')
});

ReactDOM.render(
  <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
    <Provider store={store}>
      <DnDProvider>
        <CatchErrors>
          <ApolloProvider client={tenantCompanyClient}>
            <ThemeProvider theme={theme}>
              <StylesProvider jss={jss}>
                <CacheProvider value={emotionCache}>
                  <EmotionThemeProvider theme={sgtTheme}>
                    <App auth={auth} />
                  </EmotionThemeProvider>
                </CacheProvider>
              </StylesProvider>
            </ThemeProvider>
          </ApolloProvider>
        </CatchErrors>
      </DnDProvider>
    </Provider>
  </LoadScript>,
  document.getElementById('root')
);
