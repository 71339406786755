import React from 'react';

import { Image as PDFImage, View } from '@react-pdf/renderer';

const ImageComp = React.memo(
  props => {
    const { displayValue, options } = props;
    const { width, height, alignImage, padding, margin, debug, maxHeight } = options;

    let newWidth = width || '100%';
    if (typeof width === 'number') {
      newWidth = `${width * 0.15}mm`;
    }

    let newHeight = height || '36mm';
    if (typeof height === 'number') {
      newHeight = `${height * 0.15}mm`;
    }

    if (displayValue) {
      return (
        <View debug={debug} style={{ alignItems: alignImage, padding, margin }}>
          <PDFImage
            src={displayValue}
            style={{
              maxHeight,
              width: newWidth,
              height: newHeight,
              objectFit: 'contain'
            }}
          />
        </View>
      );
    }

    return null;
  },
  (prevProps, nextProps) => prevProps.displayValue === nextProps.displayValue
);

export default ImageComp;
