import React, { memo } from 'react';

import PropTypes from 'prop-types';

import { TECHS_RESPONSE_PROP } from '@dispatch/queries';

import DailyView from './components/DailyView';
import MapView from './components/MapView';
import WeekView from './components/WeekView';

const DispatchBoard = ({ weekView, mapView, techsResponse, lazyDispatchEventsTuple }) => {
  if (mapView) {
    return <MapView techsResponse={techsResponse} />;
  }

  if (weekView) {
    return (
      <WeekView lazyDispatchEventsTuple={lazyDispatchEventsTuple} techsResponse={techsResponse} />
    );
  }

  return (
    <DailyView lazyDispatchEventsTuple={lazyDispatchEventsTuple} techsResponse={techsResponse} />
  );
};

DispatchBoard.propTypes = {
  mapView: PropTypes.bool.isRequired,
  weekView: PropTypes.bool.isRequired,
  techsResponse: TECHS_RESPONSE_PROP.isRequired
};

export default memo(DispatchBoard);
