import React from 'react';

import { array, func, object } from 'prop-types';

import { EventEntities } from '@dispatch/Dispatch.constants';
import { EventDataPropTypes } from '@dispatch/Dispatch.props';
import { prepareNonVisitData } from '@dispatch/Dispatch.utils';
import { useFormService } from '@dispatch/hooks';
import { useSnackbar } from 'customHooks/useSnackbar';
import { getState } from 'redux/store';

import BillableEventForm from '../BillableEventForm';
import DrawerLayout from '../DrawerLayout';
import EventBadge from '../EventBadge';
import VisitDetailsHeader from '../VisitDetailsHeader';

import VisitSearchHeader from './components/VisitSearchHeader';

const CreateBillableEvent = ({
  eventData,
  closeDrawer,
  addNonVisitTuple,
  addRecurringBillableEventsTuple,
  jobDetailsResponse,
  visit,
  setVisit
}) => {
  const { tenantId, tenantCompanyId } = getState().user;
  const nonVisitEventData = prepareNonVisitData(eventData);
  const { isDirty, onCreateService, onDirtyChange, resetForm, submitForm } = useFormService();
  const snackbar = useSnackbar();
  const { data: job, loading: fetchingJobDetails } = jobDetailsResponse;
  const [triggerMutation, mutationResponse] = addNonVisitTuple;
  const [triggerAddRecurringEventsMutation] = addRecurringBillableEventsTuple;
  const { loading: submittingBillableEvent } = mutationResponse;
  const disabled = !visit || fetchingJobDetails || submittingBillableEvent;
  const loading = fetchingJobDetails || submittingBillableEvent;

  const handleFormReset = () => {
    setVisit(null);
    resetForm();
  };

  const createBillableNonVisitEvent = (data, shouldCreateRecurringEvents) => {
    closeDrawer();
    if (shouldCreateRecurringEvents) {
      (async () => {
        snackbar('success', 'Creating your billable events. This may take a while.');
        const response = await triggerAddRecurringEventsMutation({
          ...data,
          assignedEntityId: visit?.visitId,
          employeeId: eventData.primaryTechId
        });
        if (response) {
          snackbar('success', 'Recurring billable events created successfully.');
        }
      })();
    } else {
      triggerMutation({
        tenantId,
        tenantCompanyId,
        data,
        visit,
        techId: eventData.primaryTechId
      });
    }
    handleFormReset();
  };

  const handleVisitSelect = selectedVisit => {
    setVisit(selectedVisit);
  };

  const renderHeader = () => {
    return (
      <>
        <EventBadge eventType={EventEntities.BILLABLE_EVENT.value.clientValue} />
        {visit ? (
          <VisitDetailsHeader job={job} loading={loading} visit={visit} />
        ) : (
          <VisitSearchHeader onVisitSelected={handleVisitSelect} />
        )}
      </>
    );
  };

  const renderBody = () => (
    <BillableEventForm
      allowMultipleEventsCreation
      disabled={disabled}
      eventData={nonVisitEventData}
      job={job}
      visit={visit}
      visitDataLoading={fetchingJobDetails}
      onComplete={createBillableNonVisitEvent}
      onCreateService={onCreateService}
      onDirtyChange={onDirtyChange}
    />
  );

  return (
    <DrawerLayout
      disabledClose={loading}
      disabledSave={disabled}
      isDirty={!!visit || isDirty}
      loading={loading}
      renderBody={renderBody}
      renderHeader={renderHeader}
      onResetForm={handleFormReset}
      onSubmitForm={submitForm}
    />
  );
};

CreateBillableEvent.propTypes = {
  eventData: EventDataPropTypes.isRequired,
  closeDrawer: func.isRequired,
  addNonVisitTuple: array.isRequired,
  addRecurringBillableEventsTuple: array.isRequired,
  jobDetailsResponse: object.isRequired,
  visit: object,
  setVisit: func.isRequired
};

CreateBillableEvent.defaultProps = {
  visit: null
};

export default CreateBillableEvent;
