import AmplifyService from 'services/AmplifyService';

import addTruckToCompany from '../../graphql/inventory/mutations/addTruck';
import addTruckInventoriesMutation from '../../graphql/inventory/mutations/addTruckInventories';
import bulkDeleteTruckInventoriesMutation from '../../graphql/inventory/mutations/bulkDeleteTruckInventories';
import deleteTruck from '../../graphql/inventory/mutations/deleteTruck';
import deleteTruckInventoryMutation from '../../graphql/inventory/mutations/deleteTruckInventory';
import updateTruckAndRelated from '../../graphql/inventory/mutations/updateTruck';
import updateTruckInventoriesMutation from '../../graphql/inventory/mutations/updateTruckInventories';
import getTruckById from '../../graphql/inventory/queries/getTruckById';
import getTrucksList from '../../graphql/inventory/queries/getTrucksList';

export default class InventoryService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
  }

  getAllActiveTrucks = async (partitionKey, sortKey, filter, limit, offset, sortBy, sortOrder) => {
    const { booleanFilters, ...rest } = filter || {};
    let customizedFilter = {
      booleanFilters: []
    };

    let combinedBooleanFilterArr = [
      {
        fieldName: 'Truck.isActive',
        filterInput: {
          eq: true
        }
      }
    ];

    if (booleanFilters) {
      combinedBooleanFilterArr = combinedBooleanFilterArr.concat(booleanFilters);
    }
    customizedFilter.booleanFilters = combinedBooleanFilterArr;

    if (rest) {
      customizedFilter = { ...customizedFilter, ...rest };
    }

    const params = {
      partitionKey,
      sortKey,
      filter: customizedFilter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    let result;
    const response = await this.api.query(getTrucksList, params);
    const formattedResult = { items: [] };
    if (response && response.data) {
      result = (response.data.getCompany || {}).trucks || {};
      if (result.items && result.items.length) {
        result.items.forEach(item => {
          let employeeNames;

          const truckEmployees = (item.truckEmployees || {}).items || [];
          if (truckEmployees.length > 0) {
            const employeeNamesList = truckEmployees.map(truckEmployee => {
              if (truckEmployee.employee) {
                return (
                  truckEmployee.employee.name ||
                  `${truckEmployee.employee.firstName || ''} ${truckEmployee.employee.lastName ||
                    ''}`
                );
              }
            });
            employeeNames = (employeeNamesList || []).join(', ');
          }

          formattedResult.items.push({
            id: item.id,
            version: item.version,
            name: item.name,
            productBundleId: item.productBundleId,
            productBundle: item.productBundle,
            productBundleName: (item.productBundle || {}).name || '',
            employeeName: employeeNames || '',
            truckEmployees
          });
        });
      }

      if (result.nextToken) {
        formattedResult.nextToken = result.nextToken;
      }
    }
    return formattedResult;
  };

  addTruck = async (partitionKey, values) => {
    const payload = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(addTruckToCompany, payload);
    return response;
  };

  updateTruck = async (partitionKey, values) => {
    const payload = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(updateTruckAndRelated, payload);
    return response;
  };

  deleteTruck = async id => {
    const payload = {
      id
    };

    const response = await this.api.mutate(deleteTruck, payload);
    return response;
  };

  getTruckDetails = async id => {
    const params = {
      id
    };

    const response = await this.api.query(getTruckById, params);
    if (response && response.data && response.data.getTruckById) {
      return response.data.getTruckById;
    }
    return response;
  };

  addTruckInventories = async values => {
    const payload = {
      data: values
    };

    const response = await this.api.mutate(addTruckInventoriesMutation, payload);
    return response;
  };

  updateTruckInventories = async values => {
    const payload = {
      data: values
    };

    const response = await this.api.mutate(updateTruckInventoriesMutation, payload);
    return response;
  };

  deleteTruckInventories = async id => {
    const payload = {
      id
    };

    const response = await this.api.mutate(deleteTruckInventoryMutation, payload);
    return response;
  };

  bulkDeleteTruckInventories = async data => {
    const payload = {
      data
    };

    const response = await this.api.mutate(bulkDeleteTruckInventoriesMutation, payload);
    return response;
  };
}
