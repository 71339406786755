import React from 'react';

import { ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';

const ConfigHeader = () => {
  return (
    <ThemeProvider>
      <Typography style={{ letterSpacing: -1 }} variant={TV.BASE} weight={TW.BOLD}>
        Pricebook Configuration
      </Typography>
    </ThemeProvider>
  );
};

export default ConfigHeader;
