import React, { useCallback, useMemo, useState } from 'react';

import { Divider } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';

import { connect } from 'react-redux';

import CustomTabs from '@pm/components/CustomTabs';
import { PageHeader, XGrid } from 'components';
import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import { snackbarOn } from 'redux/actions/globalActions';

import ErrorBoundaries from 'scenes/Error';
import { PermissionConstants } from 'utils/AppConstants';

import CreateRecommendationModal from '../CreateModal';
import {
  useAddTaskEntryToTask,
  useBatchMutatePropertyTasks,
  useBulkDeleteTasks,
  useDeleteAttachmentById,
  useDeleteFormById,
  useDeleteTaskById,
  useUpdateTask,
  useUpdateTaskEntries
} from '../mutations';
import GET_RECOMMENDED_TASK_LIST, { useRecommendedTaskList } from '../queries/useTaskList';

import { composeSilentFilters, composeTabMenu } from './index.helper';
import recommendationsColumns from './meta';
import PageHeaderButtons from './PageHeaderButtons';
import SecondaryHeaderButtons from './SecondaryHeaderButtons';

const TABS = { RECOMMENDED: 0, IN_QUOTE: 1, IN_JOB: 2, ALL: 3 };

const ListView = ({ user }) => {
  const [modalConfig, setModalConfig] = useState({});
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [refetchTrigger, setRefetchTrigger] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [addTaskEntryToTask, { loading: addingTaskEntry }] = useAddTaskEntryToTask();
  const [batchMutatePropertyTasks] = useBatchMutatePropertyTasks();
  const [deleteAttachmentById, { loading: deletingAttachment }] = useDeleteAttachmentById();
  const [deleteFormById, { loading: deletingForm }] = useDeleteFormById();
  const [deleteTaskById, { loading: deletingTask }] = useDeleteTaskById();
  const [bulkDeleteTasks] = useBulkDeleteTasks();
  const [updateTask, { loading: updatingTask }] = useUpdateTask();
  const [updateTaskEntries, { loading: updatingTaskEntries }] = useUpdateTaskEntries();
  const { refetch: refetchTaskList, data: recommendedTaskResponse } = useRecommendedTaskList(
    user.tenantId
  );
  const tabMenus = composeTabMenu(recommendedTaskResponse);
  const confirmContext = useConfirmModal();

  const isLoading =
    addingTaskEntry ||
    deletingAttachment ||
    deletingForm ||
    deletingTask ||
    updatingTask ||
    updatingTaskEntries;

  const isRowSelectableCB = useCallback(
    (params, data) => {
      if (selectionModel.length > 0) {
        const row = data.find(d => d.id === selectionModel[0]);
        return params.row.customerPropertyId === row.customerPropertyId;
      }
      return true;
    },
    [selectionModel]
  );

  const handleTabChange = (_event, newValue) => {
    setSelectionModel([]);
    setSelectedTab(newValue);
  };

  const columns = useMemo(() => {
    return recommendationsColumns({
      addTaskEntryToTask,
      confirmContext,
      deleteAttachmentById,
      deleteFormById,
      deleteTaskById,
      isLoading,
      refetchTrigger,
      refetchTaskList,
      selectedTab,
      setIsCreateModalOpen,
      setModalConfig,
      setRefetchTrigger,
      setSelectionModel,
      tenantId: user.tenantId,
      updateTask,
      updateTaskEntries
    });
  }, [
    addTaskEntryToTask,
    confirmContext,
    deleteAttachmentById,
    deleteFormById,
    deleteTaskById,
    isLoading,
    refetchTrigger,
    selectedTab,
    refetchTaskList,
    setRefetchTrigger,
    setSelectionModel,
    updateTask,
    updateTaskEntries,
    user.tenantId
  ]);

  const memoizedXGrid = useMemo(() => {
    const [silentFilter, silentFilterOperator] = composeSilentFilters(selectedTab);
    refetchTaskList();
    return (
      <div css={{ position: 'relative', height: '100%' }}>
        {selectedTab === 0 && (
          <SecondaryHeaderButtons
            batchMutatePropertyTasks={batchMutatePropertyTasks}
            bulkDeleteTasks={bulkDeleteTasks}
            confirmContext={confirmContext}
            recommendedTaskResponse={recommendedTaskResponse}
            refetchTaskList={refetchTaskList}
            refetchTrigger={refetchTrigger}
            selectionModel={selectionModel}
            setRefetchTrigger={setRefetchTrigger}
            setSelectionModel={setSelectionModel}
            snackbarOn={snackbarOn}
            tenantId={user.tenantId}
          />
        )}
        <XGrid
          checkboxSelection={selectedTab === 0}
          columns={columns}
          entityTypeName="RecommendedTask"
          isRowSelectable={isRowSelectableCB}
          query={GET_RECOMMENDED_TASK_LIST}
          refetchTrigger={refetchTrigger}
          rowHeight={38}
          selectionModel={selectionModel}
          silentFilter={silentFilter}
          silentFilterOperator={silentFilterOperator}
          tableName="RecommendedTaskXGrid"
          onSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel)}
        />
      </div>
    );
  }, [columns, isRowSelectableCB, refetchTrigger, selectionModel, selectedTab]);

  return (
    <ErrorBoundaries>
      <PageHeader
        overrideHeaderButtons={
          <PageHeaderButtons
            refetchTrigger={refetchTrigger}
            setIsCreateModalOpen={setIsCreateModalOpen}
            setModalConfig={setModalConfig}
            setRefetchTrigger={setRefetchTrigger}
          />
        }
        pageMapKey="recommendations"
        userLocale={user.locale}
      />
      <Divider marginBottom={28} marginTop={32} />
      <CustomTabs tabMenus={tabMenus} value={selectedTab} onChange={handleTabChange} />
      <div css={{ paddingLeft: 17, height: '100%' }}>
        {selectedTab === TABS.RECOMMENDED && memoizedXGrid}
        {selectedTab === TABS.IN_QUOTE && memoizedXGrid}
        {selectedTab === TABS.IN_JOB && memoizedXGrid}
        {selectedTab === TABS.ALL && memoizedXGrid}
      </div>
      <CreateRecommendationModal
        confirmContext={confirmContext}
        isCreateModalOpen={isCreateModalOpen}
        modalConfig={modalConfig}
        refetchTaskList={refetchTaskList}
        refetchTrigger={refetchTrigger}
        setIsCreateModalOpen={setIsCreateModalOpen}
        setRefetchTrigger={setRefetchTrigger}
        updateTask={updateTask}
      />
    </ErrorBoundaries>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const connectedListView = connect(mapStateToProps, mapDispatchToProps)(ListView);

export default connectedListView;
