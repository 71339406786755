import { useMemo } from 'react';

import { column, ColumnType, valueGetters } from 'components/XGrid/columnTypes';
import { MultiSelectTypes } from 'utils/AppConstants';

export const useInvoiceListColumns = ({ isAdjustmentEnabled }) =>
  useMemo(
    () => [
      {
        field: 'invoiceNumber',
        headerName: 'Invoice',
        width: 250,
        valueGetter: valueGetters.invoiceLink,
        ...column[ColumnType.LINK]
      },
      {
        field: 'status',
        headerName: 'Invoice Status',
        width: 250,
        enumType: MultiSelectTypes.INVOICE_STATUS,
        ...column[ColumnType.ENUM]
      },
      {
        field: 'dueDate',
        headerName: 'Due Date',
        width: 250,
        ...column[ColumnType.DATE]
      },
      {
        field: 'createdDate',
        headerName: 'Date Created',
        width: 250,
        ...column[ColumnType.DATE]
      },
      {
        field: 'totalAmount',
        headerName: 'Invoice Amount',
        width: 250,
        ...column[ColumnType.CURRENCY]
      },
      {
        field: 'outstandingBalance',
        headerName: 'AR Balance',
        width: 250,
        isBalance: true,
        ...column[ColumnType.CURRENCY]
      },
      isAdjustmentEnabled && {
        field: 'adjustedBalance',
        headerName: 'Adjusted AR Balance',
        width: 250,
        isBalance: true,
        ...column[ColumnType.CURRENCY]
      }
    ],
    [isAdjustmentEnabled]
  );
