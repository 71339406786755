import React, { Component } from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import classNames from 'classnames';

const styles = {
  root: {
    // maxWidth: 1123,
    position: 'relative',
    overflow: 'hidden'
  },
  buttonWrapper: {
    position: 'absolute',
    backgroundColor: 'transparent',
    top: 'calc(50% - 12px)',
    '&:hover': {
      '& $button': {
        filter: 'brightness(120%)',
        opacity: 0.6
      }
    }
  },
  button: {
    position: 'relative',
    color: '#333333',
    fontSize: '20px',
    transition: '200ms',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.6 !important'
    }
  },
  fullHeightHoverButton: {
    padding: 0,
    top: 'calc(50% - 12px) !important'
  },
  next: {
    right: 0
  },
  prev: {
    left: 0
  },
  carouseItem: {
    padding: '0px 24px'
  }
};

function CarouselItem(props) {
  return props.display ? (
    <Box className={props.classes.carouseItem}>
      <Slide
        direction={
          // eslint-disable-next-line no-nested-ternary
          props.active ? (props.isNext ? 'left' : 'right') : props.isNext ? 'right' : 'left'
        }
        in={props.active}
        timeout={props.timeout}
      >
        <Box>{props.child}</Box>
      </Slide>
    </Box>
  ) : null;
}

const getFormattedData = data => {
  return {
    children: data?.children || [],
    index: data?.index || 0,
    timeout: data?.timeout || 200,
    onChange: data?.onChange || null,
    next: data?.next || null,
    prev: data?.prev || null
  };
};

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      prevActive: 0,
      displayed: 0
    };
  }

  componentDidMount() {
    const { index } = getFormattedData(this.props);
    this.setActive(index);
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line no-param-reassign
    prevProps = getFormattedData(prevProps);
    const { children, index } = getFormattedData(this.props);

    if (children.length !== prevProps.children.length) {
      this.setActive(index);
    }

    if (prevProps.index !== index) {
      this.setActive(index);
    }
  }

  setActive(index) {
    const { timeout, children } = getFormattedData(this.props);
    let currentIndex = index;

    if (Array.isArray(children)) {
      if (index > children.length - 1) currentIndex = children.length - 1;
      if (index < 0) currentIndex = 0;
    } else {
      currentIndex = 0;
    }

    // eslint-disable-next-line react/no-access-state-in-setstate
    const prevActive = this.state.active;

    this.setState({
      active: currentIndex,
      prevActive,
      displayed: prevActive
    });

    setTimeout(() => {
      this.setState({
        displayed: currentIndex
      });
    }, timeout);
  }

  next(event) {
    const { children } = getFormattedData(this.props);
    const nextActive = this.state.active + 1 > children.length - 1 ? 0 : this.state.active + 1;
    this.setActive(nextActive);
    if (event) event.stopPropagation();
  }

  prev(event) {
    const { children } = getFormattedData(this.props);
    const nextActive = this.state.active - 1 < 0 ? children.length - 1 : this.state.active - 1;
    this.setActive(nextActive);
    if (event) event.stopPropagation();
  }

  compareActiveDisplayed(data) {
    if (this.state.active === 0 && this.state.prevActive === data.length - 1) {
      return false;
    }
    if (this.state.active === data.length - 1 && this.state.prevActive === 0) {
      return true;
    }
    if (this.state.active > this.state.prevActive) {
      return true;
    }
    return false;
  }

  render() {
    const { classes } = this.props;
    const { children, timeout } = getFormattedData(this.props);

    return (
      <Box className={classes.root}>
        {Array.isArray(children) ? (
          children.map((child, index) => {
            return (
              <CarouselItem
                // eslint-disable-next-line react/no-array-index-key
                active={index === this.state.active}
                child={child}
                classes={classes}
                display={index === this.state.displayed}
                isNext={this.compareActiveDisplayed(children)}
                key={`carousel-item${index}`}
                next={() => this.next()}
                prev={() => this.prev()}
                timeout={timeout}
              />
            );
          })
        ) : (
          <CarouselItem
            active
            child={children}
            classes={classes}
            display
            key="carousel-item0"
            timeout={timeout}
          />
        )}

        <Box className={classNames(classes.buttonWrapper, classes.next)}>
          <IconButton
            className={classNames(classes.button, classes.fullHeightHoverButton, classes.next)}
            onClick={() => this.next()}
          >
            <NavigateNextIcon />
          </IconButton>
        </Box>

        <Box className={classNames(classes.buttonWrapper, classes.prev)}>
          <IconButton
            className={classNames(classes.button, classes.fullHeightHoverButton, classes.prev)}
            onClick={() => this.prev()}
          >
            <NavigateBeforeIcon />
          </IconButton>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(Carousel);
