import React, { useEffect, useMemo, useState } from 'react';

import { Button, Input, MultiSelect } from '@BuildHero/sergeant';
import { Grid } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import CheckIcon from '@material-ui/icons/Check';
import uuidV4 from 'uuid/v4';

import { SgtAlgoliaMultiSelect } from 'components';
import { bundleIndex } from 'constants/algoliaIndex';

import FormLineItem from './FormLineItem';
import PartsAndMaterialsLineItem from './PartsAndMaterialsLineItem';

const style = {
  secondaryInputs: {
    padding: 5,
    border: '1px solid black',
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    marginTop: -1,
    width: '100%'
  },
  divider: {
    marginTop: 8,
    marginBottom: 8,
    width: '100%'
  },
  button: {
    width: 200,
    height: 15
  },
  createTaskButtonCheckmark: {
    height: 15,
    width: 15,
    marginRight: 5,
    marginLeft: -5
  }
};

const BulkAddTaskCard = ({ assetOptions, formOptions, onCreateTasks, assetMap, onCancel }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [selectedPartsAndMaterials, setSelectedPartsAndMaterials] = useState([]);

  const groupedFormOptions = useMemo(() => {
    return (
      formOptions
        .map(o => {
          const assetOption = o.value.asset && selectedAssets.find(so => so.id === o.value.asset);

          return {
            ...o,
            group: assetOption?.label
          };
        })
        // option may appear in multiple groups, this is desired behaviour (i.e. if a form is associated with multiple assets that are selected).
        // option may appear multiple times in the "Other" group, all but one of these should be filtered out (i.e. if a form is associated with multiple assets that are not selected).
        // option may appear in both the defined groups and "Other group, the options in the "Other" group should be filtered out (i.e. a form is associated with multiple assets, some selected, others not selected)
        .sort((a, b) => {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;
          if (a.group && b.group) return 0;
          if (!a.group && !b.group) return 0;
          if (!b.group) return -1;
          if (!a.group) return 1;
          return 0;
        })
        .filter((f, index, fs) => index === 0 || f.group || f.id !== fs[index - 1].id)
    );
  }, [formOptions, selectedAssets]);

  const associatedParts = useMemo(() => {
    return assetOptions.reduce((acc, asset) => {
      const assetParts =
        asset.value.maintenanceTaskRequiredParts?.items.map(part => ({
          id: part.id,
          label: part.product?.name,
          value: {
            productId: part.product?.id,
            name: part.product?.name,
            code: part.product?.code,
            description: part.product?.description,
            quantity: part.quantity
          },
          group: asset.value.assetName
        })) ?? [];

      return [...acc, ...assetParts];
    }, []);
  }, [assetOptions]);

  useEffect(() => {
    const remainingSelectedAssets = selectedAssets.filter(sa =>
      assetOptions.find(ao => ao.id === sa.id)
    );
    if (remainingSelectedAssets.length !== selectedAssets.length) {
      setSelectedAssets(remainingSelectedAssets);
    }
  }, [assetOptions.length]);

  return (
    <Grid container>
      <Input
        endAdornment={
          <>
            <Button css={style.button} type="tertiary" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              css={style.button}
              disabled={selectedAssets.length === 0 || !name}
              type="secondary"
              onClick={() => {
                const assetTasks = selectedAssets.reduce((acc, asset) => {
                  return {
                    ...acc,
                    [asset.id]: [
                      {
                        id: uuidV4(),
                        name,
                        description,
                        forms: selectedForms,
                        partsAndMaterials: selectedPartsAndMaterials,
                        assetId: asset.id,
                        assetTypeId: assetMap[asset.id].assetTypeId
                      }
                    ]
                  };
                }, {});

                onCreateTasks(assetTasks);
              }}
            >
              <CheckIcon css={style.createTaskButtonCheckmark} /> Create Tasks
            </Button>
          </>
        }
        placeholder="Enter Task Name..."
        style={{ width: '100%' }}
        onBlur={e => setName(e.target.value)}
      />
      <div css={style.secondaryInputs}>
        <MultiSelect
          grouped
          label="Assets"
          options={assetOptions}
          placeholder="Select Assets"
          selectedOptions={selectedAssets}
          showChips
          onChange={setSelectedAssets}
        />
        <Divider css={style.divider} />
        <Input
          label="Task Description"
          multiline
          placeholder="Enter Task Description..."
          style={{ width: '100%' }}
          onBlur={e => setDescription(e.target.value)}
        />
        <Divider css={style.divider} />
        <MultiSelect
          grouped
          label="Forms"
          lineItemComponent={(option, selectedOptions) => (
            <FormLineItem
              formsChange={setSelectedForms}
              option={option}
              selectedOptions={selectedOptions}
            />
          )}
          options={groupedFormOptions}
          placeholder="Search & Select Forms"
          selectedOptions={selectedForms}
          showChips
          showSearchIcon
          onChange={setSelectedForms}
        />
        <Divider css={style.divider} />
        <SgtAlgoliaMultiSelect
          options={{
            grouped: true,
            onChange: setSelectedPartsAndMaterials,
            preSearchOptions: associatedParts,
            placeholder: 'Search & Select Parts & Materials',
            showChips: true,
            showSearchIcon: true,
            selectedOptions: selectedPartsAndMaterials,
            topLevelSelectAllLabel: 'Select All Parts & Materials',
            label: 'Parts & Materials',
            restructureAlgoliaHitToOptions: hit => {
              const partAssociationsForThisHit = associatedParts.filter(p => p.id === hit.id);

              if (partAssociationsForThisHit.length) {
                return partAssociationsForThisHit.map(p => ({
                  id: hit.id,
                  label: hit.name,
                  value: {
                    productId: hit.id,
                    productSortKey: hit.sortKey,
                    name: hit.name,
                    code: hit.code,
                    description: hit.description,
                    quantity: 1
                  },
                  group: p.group
                }));
              }

              return [
                {
                  id: hit.id,
                  label: hit.name,
                  value: {
                    productId: hit.id,
                    productSortKey: hit.sortKey,
                    name: hit.name,
                    code: hit.code,
                    description: hit.description,
                    quantity: 1
                  }
                }
              ];
            },
            algoliaFilter: `entityType:Product`,
            algoliaIndex: bundleIndex,
            lineItemComponent: (option, selectedOptions) => (
              <PartsAndMaterialsLineItem
                option={option}
                partsAndMaterialsChange={setSelectedPartsAndMaterials}
                selectedOptions={selectedOptions}
              />
            )
          }}
        />
      </div>
      {/*  */}
    </Grid>
  );
};

export default BulkAddTaskCard;
