import React, { Fragment, useMemo, useState } from 'react';

import { Button, ButtonType, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';

import { Box, Tooltip, useTheme } from '@material-ui/core';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { sortBy } from 'lodash';
import { useSelector } from 'react-redux';

import { SergeantModal } from 'components';
import SwitchComponent from 'components/BuildHeroFormComponents/Switch';
import Placeholder from 'components/Placeholder';
import ErrorBoundaries from 'scenes/Error';
import { Logger } from 'services/Logger';
import { isTenantSettingEnabled } from 'utils';
import { Mode } from 'utils/constants';
import { constructSelectOptions } from 'utils/constructSelectOptions';

import Checklist from './checklist';
import { checklistFormLayout } from './checklistFormLayout';

import { ChecklistPlaceholder } from './components/checklistPlaceholder';

import { getCheckListPayload } from './helper';

import { useChecklistLibrary } from './hooks/useChecklistLibrary';
import { useCreateTaskTemplateChecklist } from './hooks/useCreateTaskTemplateChecklist';
import { useDeleteTaskTemplateChecklist } from './hooks/useDeleteTaskTemplateChecklist';
import { useUpdateTaskTemplateChecklist } from './hooks/useUpdateTaskTemplateChecklist';

import { PROPERTY_CHECKLISTS } from './index';

export const TooltipInfo = ({ options: { message } }) => {
  const theme = useTheme();
  return (
    <Tooltip title={message}>
      <InfoOutlinedIcon css={{ marginLeft: theme.spacing(0.5), cursor: 'pointer', fontSize: 14 }} />
    </Tooltip>
  );
};

const ChecklistListing = ({ currentAssetType }) => {
  const theme = useTheme();
  const assetTypes = useSelector(state => state.company?.assetTypes?.items);
  const sortedAssetTypes = sortBy(assetTypes, 'sortOrder');
  const assetTypeOptions = constructSelectOptions(
    [...sortedAssetTypes, PROPERTY_CHECKLISTS],
    'tagName'
  );

  const assetTypeId = currentAssetType?.id;
  const [checklistModal, setChecklistModal] = useState({ open: false, mode: Mode.VOID });
  const isAssetEnabled = isTenantSettingEnabled('assetTrackingAgainstTask');

  const { data: checklistArray, loading: checklistLoading, refetch } = useChecklistLibrary(
    { assetTypeId },
    { skip: !currentAssetType }
  );

  const [createChecklist] = useCreateTaskTemplateChecklist();
  const [updateChecklist, { loading: updatingChecklist }] = useUpdateTaskTemplateChecklist();
  const [deleteChecklist] = useDeleteTaskTemplateChecklist({
    assetTypeId: checklistModal?.data?.assetTypeId
  });

  const checklistLayout = useMemo(
    () => checklistFormLayout({ assetTypeOptions, isAssetEnabled, currentAssetType }),
    [assetTypeOptions, isAssetEnabled, currentAssetType]
  );

  const handleChecklist = async (values, stopLoading) => {
    const payload = getCheckListPayload(values);
    try {
      switch (checklistModal.mode) {
        case Mode.ADD: {
          await createChecklist(payload);
          break;
        }
        case Mode.EDIT: {
          await updateChecklist(payload);
          break;
        }
        case Mode.DELETE: {
          await deleteChecklist(payload);
          break;
        }
        default:
          break;
      }
    } catch (e) {
      Logger.error(e);
    } finally {
      stopLoading();
      setChecklistModal({ open: false });
    }
  };

  return (
    <ErrorBoundaries>
      <ThemeProvider>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          key="AddChecklist"
          paddingBottom={1}
        >
          <Typography variant={TV.L} weight={TW.BOLD}>
            {currentAssetType?.tagName}
          </Typography>
          <Button
            type={ButtonType.SECONDARY}
            onClick={() =>
              setChecklistModal({
                open: true,
                data: { assetTypeId: currentAssetType.id, isDefaultChecklist: false },
                mode: Mode.ADD
              })
            }
          >
            Add Checklist
          </Button>
        </Box>
        {checklistArray?.length === 0 && (
          <ChecklistPlaceholder
            currentAssetType={currentAssetType}
            key="ChecklistPlaceholder"
            setChecklistModal={setChecklistModal}
            theme={theme}
          />
        )}
        {checklistLoading && <Placeholder repeatCount={3} variant="card" />}

        {checklistArray?.length > 0 &&
          checklistArray.map(cList => {
            return (
              <Fragment key={`Checklist-${cList.id}`}>
                <Checklist
                  assetTypeId={assetTypeId}
                  cList={cList}
                  setChecklistModal={setChecklistModal}
                  updateChecklist={updateChecklist}
                  updatingChecklist={updatingChecklist}
                />
              </Fragment>
            );
          })}
      </ThemeProvider>
      <SergeantModal
        customComponents={{
          Switch: SwitchComponent,
          TooltipInfo
        }}
        customPrimaryButtonLabel={
          checklistModal.mode === Mode.EDIT ? 'Save Changes' : `${checklistModal.mode} Checklist`
        }
        data={checklistModal.data}
        dataType="Checklist"
        formVersion={checklistModal.mode}
        fullWidth
        handleClose={() => setChecklistModal({ open: false })}
        handlePrimaryAction={handleChecklist}
        layout={checklistLayout}
        maxWidth={480}
        mode={checklistModal.mode}
        open={checklistModal.open}
      />
    </ErrorBoundaries>
  );
};

export default ChecklistListing;
