import React, { useEffect, useReducer, useRef, useState } from 'react';

import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { generateDefaultValidationSchema } from '@pm/components/formattingUtils';
import FormWithAccordion from '@pm/components/FormWithAccordion';
import MUIFormListView from '@pm/components/MUIFormListView';
import { getLastLineNumber } from 'scenes/Procurement/component/utils';

import { subcontractorFields, subcontractorLayout } from './layout';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 24
  },
  accordionFooter: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '21px 28px'
  },
  hideElement: {
    display: 'none'
  },
  addButton: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '14px',
    textDecoration: 'none'
  },
  footerForm: {
    width: 702
  },
  footerTotal: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 21
  },
  deleteButton: {
    background: theme?.palette?.error?.light
  }
}));

const formDataReducer = (state, { type, payload }) => {
  switch (type) {
    case 'update': {
      const stateCopy = [...state];
      const itemIndex = stateCopy.findIndex(item => item.index === payload.index);
      if (itemIndex >= 0) stateCopy[itemIndex] = payload;
      return [...stateCopy];
    }
    case 'add': {
      return [...state, payload];
    }
    case 'delete': {
      return [...state.filter(value => payload.index !== value.index)];
    }
    case 'set': {
      return [...payload];
    }
    default:
      return state;
  }
};

const SubcontractorFieldForm = props => {
  const {
    initialData,
    mode,
    onCreateService,
    onComplete,
    onDeleteService,
    formatFuncForSave,
    loading
  } = props;
  const classes = useStyles();
  const [panelExpanded, setPanelExpanded] = useState(false);
  const [formData, dispatchStateItems] = useReducer(formDataReducer, []);
  const loadingRef = useRef();
  loadingRef.current = loading;

  const getFormattedData = (data, index) => {
    return {
      index,
      serviceName: `subcontractors-${index}`,
      id: data?.id || null,
      comments: data?.comments || '',
      name: data?.name || '',
      workersOnsite: data?.workersOnsite || ''
    };
  };

  const addNewFormData = () => {
    const indexNumber = formData.length ? getLastLineNumber(formData, 'index') + 1 : 0;
    const newFormData = getFormattedData(null, indexNumber);
    dispatchStateItems({ type: 'add', payload: newFormData });
  };

  useEffect(() => {
    if (initialData && Array.isArray(initialData) && initialData?.length > 0) {
      dispatchStateItems({
        type: 'set',
        payload: initialData?.map((value, index) => ({
          ...value,
          index,
          serviceName: `subcontractors-${index}`
        }))
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initialData)]);

  const handlePanelExpand = (event, newExpanded) => {
    setPanelExpanded(newExpanded);
  };

  const handleFormChange = value => {
    dispatchStateItems({
      type: 'update',
      payload: getFormattedData(value, value.index)
    });
  };

  const handleDeleteButton = async item => {
    if (item.serviceName) onDeleteService(item.serviceName);
    dispatchStateItems({ type: 'delete', payload: item });
  };

  const deleteButton = ({ field }) => {
    return (
      <Button
        className={classes.deleteButton}
        color="secondary"
        disabled={loadingRef.current}
        variant="contained"
        onClick={() => handleDeleteButton(field?.value || {})}
      >
        Delete
      </Button>
    );
  };

  const muiForms = () => (
    <MUIFormListView
      configuration={subcontractorLayout}
      customComponents={{ deleteButton }}
      formatFuncForSave={formatFuncForSave}
      formData={formData}
      key="subcontractors"
      keyName="subcontractors"
      layout={mode}
      mode="default"
      validationSchema={generateDefaultValidationSchema(subcontractorFields)}
      onComplete={onComplete}
      onCreateService={onCreateService}
      onFormChange={handleFormChange}
    />
  );

  const addFormBtn = (
    <Button
      className={classes.addButton}
      startIcon={<AddCircleOutlineIcon />}
      onClick={addNewFormData}
    >
      <Typography>Add Subcontractor</Typography>
    </Button>
  );

  return (
    <div className={classes.root}>
      <FormWithAccordion
        buttonComponent={addFormBtn}
        expanded={panelExpanded}
        formComponent={muiForms}
        handlePanelExpand={handlePanelExpand}
        loading={loading}
        sectionName="subcontractor"
      />
      <Divider />
    </div>
  );
};

SubcontractorFieldForm.propTypes = {
  initialData: PropTypes.array.isRequired,
  onCreateService: PropTypes.func.isRequired,
  mode: PropTypes.string,
  onComplete: PropTypes.func.isRequired,
  onDeleteService: PropTypes.func.isRequired,
  formatFuncForSave: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

SubcontractorFieldForm.defaultProps = {
  mode: 'default',
  loading: false
};

const mapStateToProps = state => ({ user: state.user });
const ReduxConnectedSubcontractorFieldForm = connect(mapStateToProps)(SubcontractorFieldForm);

export default ReduxConnectedSubcontractorFieldForm;
