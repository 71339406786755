import React from 'react';

import { Typography } from '@material-ui/core';
import CX from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import AlgoliaSearch from 'components/InputFilter/SearchBox';

import useStyles from './AssignmentInput.styles';
import { algoliaProps } from './constants';
import { getAssignmentName } from './helpers';

const renderText = (label, value, classes) => (
  <span>
    {label}
    <b className={classes.boldText}>{value}</b>
  </span>
);

const AssignmentInput = ({ record, onChange, hasEditPermission }) => {
  const classes = useStyles();
  const [isFocus, setIsFocus] = React.useState(false);

  const { assignmentDetail, userActionType, timekeepingLedger } = record;
  const displayDetail = assignmentDetail || getAssignmentName(record);
  const { jobNumber, visitNumber, assignmentName } = displayDetail;
  const eventType = timekeepingLedger?.eventType?.name;
  const eventTitle = timekeepingLedger?.eventEntity?.name;

  const handleSelect = (value = {}) => {
    if (!isEmpty(value)) {
      const { visitId: id, entityType: type } = value;
      onChange({ id, type, assignmentDetail: value });
    }
    setIsFocus(false);
  };

  let label = (
    <>
      {jobNumber && renderText('Job', jobNumber, classes)}
      {visitNumber && renderText('Visit', visitNumber, classes)}
      {eventType === 'BillableNonVisit' && renderText('-', eventTitle, classes)}
      {assignmentName && <span>{assignmentName}</span>}
    </>
  );

  if ((userActionType || '').toLowerCase() === 'shift') {
    label = 'Shift - Idle';
  }

  // when no job is assigned we need the title
  // then its assiged to job, it show the job related info
  if (eventType === 'NonBillable' && !jobNumber) {
    label = eventTitle || '-';
  }

  if (!isFocus || !hasEditPermission)
    return (
      <Typography
        className={CX(classes.labelContainer, { [classes.disabled]: !hasEditPermission })}
        variant="h6"
        onClick={() => hasEditPermission && setIsFocus(true)}
      >
        {label}
      </Typography>
    );
  return (
    <AlgoliaSearch
      {...algoliaProps}
      customHitFormatter={hit => {
        const { visitNumber, jobNumber, customIdentifier } = hit;
        const job = jobNumber ? `Job #${customIdentifier || jobNumber}` : '';
        const visit = visitNumber ? ` - Visit #${visitNumber}` : '';
        return `${job}${visit}`;
      }}
      customInputProps={{ className: classes.search, autoFocus: isFocus, disableUnderline: true }}
      displayDetail={displayDetail}
      handleChange={handleSelect}
      iconClassNames={classes.searchIcon}
      inputClassNames={classes.searchContainer}
    />
  );
};

export default AssignmentInput;

AssignmentInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  hasEditPermission: PropTypes.bool,
  record: PropTypes.object.isRequired
};

AssignmentInput.defaultProps = {
  hasEditPermission: true
};
