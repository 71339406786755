/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

import { sentryException } from '../Logger/index';

export async function getJcPhasesByJob(jobId) {
  return await instance
    .get(`jobjcphasejccosttype/byjob/${jobId}`)
    .then(({ data = [] }) => {
      const jcPhaseData = data.map(item => item.JcPhase[0]);
      return jcPhaseData;
    })
    .catch(err => {
      sentryException(err, 'Unable to get jcPhases');
      return [];
    });
}

export async function getJcCostTypesByJobJcPhase(jobId, phaseId) {
  return await instance
    .get(`jobjcphasejccosttype/byjob/${jobId}/${phaseId}`)
    .then(({ data = [] }) => {
      const jcCostTypeData = data.map(item => item.JcCostType[0]);
      return jcCostTypeData;
    })
    .catch(err => {
      sentryException(err, 'Unable to get jcCostTypes');
      return [];
    });
}
