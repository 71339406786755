import React from 'react';

import { roundFive } from '@BuildHero/math';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  customCurrency: {
    fontSize: '0.9em',
    fontWeight: '700',
    textAlign: 'right',
    alignSelf: 'right'
  }
}));

const CustomCurrency = valueProps => {
  const classes = useStyles();
  const { record, meta } = valueProps;
  return (
    <Typography className={classes.customCurrency}>
      ${roundFive(String(record[meta.id]))}
    </Typography>
  );
};

export default CustomCurrency;
