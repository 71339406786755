import React from 'react';

import { Button, ButtonType, MUIForm, ThemeProvider } from '@BuildHero/sergeant';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { FieldLabel } from 'components/BuildHeroFormComponents/Texts';

import StyledCheckbox from '../BuildHeroFormComponents/CheckBox';
import Dropdown from '../BuildHeroFormComponents/Dropdown';
import TextArea from '../BuildHeroFormComponents/TextArea';
import { TextInput } from '../BuildHeroFormComponents/TextInput';
import DropdownCreatable from '../DropdownFlyCreation';

import defaultRepresentativeForm from './defaultRepresentativeForm';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.grayscale(94),
    padding: '0 8px'
  },
  cancelBtn: {
    backgroundColor: theme.palette.grayscale(60),
    '&:hover': {
      backgroundColor: theme.palette.grayscale(40)
    }
  },
  saveBtn: {
    backgroundColor: theme.palette.brand.green,
    '&:hover': {
      backgroundColor: theme.palette.brand.logoGreen
    }
  },
  btn: {
    color: theme.palette.grayscale(100)
  },
  footer: {
    backgroundColor: theme.palette.grayscale(90),
    padding: '18px 8px'
  }
}));

export default function Representative(props) {
  const { data, btn, setExpanded, expanded, name } = props;
  const classes = useStyles();
  const [service, setService] = React.useState('');

  const save = async (finalizeData, data) => {
    if (service) {
      service.submit();
      if (finalizeData) {
        let values = {
          id: finalizeData.id,
          firstName: finalizeData.firstName,
          lastName: finalizeData.lastName || null,
          contactRole: finalizeData.role || null,
          email: finalizeData.mail || null,
          cellPhone: finalizeData.mobile || null,
          landlinePhone: finalizeData.landline || null,
          bestContact:
            finalizeData.mailPreferred === 'true'
              ? 'Email'
              : finalizeData.mobilePreferred === 'true'
              ? 'Cell phone'
              : finalizeData.landlinePreferred === 'true'
              ? 'Landline'
              : null,
          isSmsOptOut: finalizeData.smsNotication || false,
          note: finalizeData.note || null
        };
        values = finalizeData.id ? { ...values, version: finalizeData.version } : { ...values };
        let latestOrderNote = {};
        if (data?.notes?.items && !_.isEmpty(data.notes.items)) {
          latestOrderNote = _.orderBy(data?.notes?.items, ['lastUpdatedDateTime'], ['desc'])[0];
        }
        btn.saveAction(values, name, latestOrderNote);
        setExpanded && setExpanded(false);
      }
    }
  };

  const cancel = async () => {
    if (btn && btn.cancelAction) {
      btn.cancelAction();
    }

    if (setExpanded) setExpanded(!expanded);
  };

  const getFormattedData = data => {
    if (data) {
      let note = '';
      if (data?.notes?.items && !_.isEmpty(data.notes.items)) {
        const latestOrderNote = _.orderBy(data?.notes?.items, ['lastUpdatedDateTime'], ['desc']);
        note = latestOrderNote[0].note;
      }
      return {
        id: data.id || '',
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        role: data.contactRole || '',
        mail: data.email || '',
        mobile: data.cellPhone || '',
        landline: data.landlinePhone || '',
        mailPreferred: data.bestContact === 'Email' || false,
        mobilePreferred: data.bestContact === 'Cell phone' || false,
        landlinePreferred: data.bestContact === 'Landline' || false,
        smsNotication: !!data.isSmsOptOut,
        note: note || '',
        version: data.version || ''
      };
    }
    return {};
  };
  const formattedData = getFormattedData(data);
  return (
    <>
      <Box className={classes.container}>
        <MUIForm
          configuration={defaultRepresentativeForm()}
          customComponents={{
            DropdownCreation: DropdownCreatable,
            FieldLabel,
            Dropdown,
            TextInput,
            Checkbox: StyledCheckbox,
            TextArea
          }}
          data={formattedData}
          onComplete={async completed => {
            save(completed, data);
          }}
          onCreateService={formService => setService(formService)}
        />
      </Box>
      <ThemeProvider>
        <Grid className={classes.footer} container>
          <Grid item lg={6} md={6} sm={6} xl={6} xs={6}>
            <Button type={ButtonType.TERTIARY} onClick={cancel}>
              {btn.cancelLabel}
            </Button>
          </Grid>
          <Grid container justify="flex-end" lg={6} md={6} sm={6} xl={6} xs={6}>
            <Button type={ButtonType.PRIMARY} onClick={save}>
              {!formattedData.id ? 'SAVE' : 'UPDATE'}
            </Button>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}
