import React, { memo, useState } from 'react';

import { ButtonType, MoreButton, SplitButton, ThemeProvider } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';
import DeleteIcon from '@material-ui/icons/Delete';
import PostAddIcon from '@material-ui/icons/PostAdd';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import useEmployees from 'customHooks/useEmployees';

import AddTasksToQuoteModal from 'scenes/Customer/PropertyDetail/Tasks/AddTaskToQuote';

import { bulkDeleteConfirmContent } from '../index.helper';

import {
  handleAddToExistingQuote,
  handleAddToNewQuote,
  handleBulkDeleteRecommendations,
  handleBulkMoreInfoNeeded
} from './index.handlers';

const WrappedComponent = props => {
  const [addQuoteLoading, setAddQuoteLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addTasksToQuoteModalOpen, setAddTasksToQuoteModalOpen] = useState(false);
  const [custPropertyId, setCustPropertyId] = useState('');
  const [tasksToAddToQuote, setTasksToAddToQuote] = useState([]);
  const history = useHistory();
  const [companyEmployees] = useEmployees();

  const splitBtnOptions = [
    {
      label: 'Add to New Quote',
      onClick: () =>
        handleAddToNewQuote({
          companyEmployees,
          history,
          recommendedTaskResponse: props.recommendedTaskResponse,
          selectionModel: props.selectionModel,
          setAddQuoteLoading,
          snackbarOn: props.snackbarOn,
          tenantId: props.tenantId
        })
    },
    {
      label: 'Add to Existing Quote',
      onClick: () =>
        handleAddToExistingQuote({
          recommendedTaskResponse: props.recommendedTaskResponse,
          selectionModel: props.selectionModel,
          setAddTasksToQuoteModalOpen,
          setCustPropertyId,
          setTasksToAddToQuote,
          snackbarOn: props.snackbarOn
        })
    }
  ];

  const moreBtnOptions = [
    {
      label: 'More Info Needed',
      icon: PostAddIcon,
      onClick: () => handleBulkMoreInfoNeeded({ ...props, setIsLoading })
    },
    {
      label: 'Delete Recommendations',
      icon: DeleteIcon,
      onClick: async () => {
        if (
          await props.confirmContext?.confirm(
            bulkDeleteConfirmContent(props?.selectionModel?.length ?? 0)
          )
        ) {
          handleBulkDeleteRecommendations({ ...props, setIsLoading });
        }
      }
    }
  ];

  return (
    <div css={{ position: 'absolute', right: 0, top: 16, zIndex: 1 }}>
      <ThemeProvider>
        <SplitButton
          disabled={!props?.selectionModel?.length}
          loading={addQuoteLoading}
          options={splitBtnOptions}
          type={ButtonType.TERTIARY}
        >
          Add to Quote
        </SplitButton>
        <MoreButton loading={isLoading} options={moreBtnOptions} />
        <AddTasksToQuoteModal
          customerPropertyId={custPropertyId}
          open={addTasksToQuoteModalOpen}
          refetchTasks={quote => {
            if (quote?.value) {
              history.push(`/quote/${quote.value}`);
            }
          }}
          selectedTasks={tasksToAddToQuote}
          title="Add Recommendations To Quote"
          onClose={() => setAddTasksToQuoteModalOpen(false)} // to reset the form
        />
      </ThemeProvider>
    </div>
  );
};

const SecondaryHeaderButtons = memo(WrappedComponent);

export default SecondaryHeaderButtons;

WrappedComponent.propTypes = {
  confirmContext: PropTypes.object.isRequired,
  recommendedTaskResponse: PropTypes.object.isRequired,
  selectionModel: PropTypes.array.isRequired,
  snackbarOn: PropTypes.func.isRequired,
  tenantId: PropTypes.string
};

WrappedComponent.defaultProps = {
  tenantId: ''
};
