import React, { Fragment } from 'react';

import { Button, IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { styles, styles2 } from './styles';

const variantIcon = {
  success: CheckCircleIcon,
  longSuccess: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  failure: ErrorIcon,
  info: InfoIcon
};

/**
 * Custom error message that popus at top center of the page
 *
 * TODO: to align with vicky on positioning of the box
 */
function MySnackbarContent(props) {
  const { classes, className, message, errorLog, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      aria-describedby="client-snackbar"
      className={classNames(classes[variant], className)}
      message={
        <>
          <span className={classes.message} id="client-snackbar">
            <Icon className={classNames(classes.icon, classes.iconVariant)} />

            {message &&
              message.split('\n').map((line, index) => (
                <Fragment key={`fragement${index}`}>
                  {line}
                  <br key={`line${index}`} />
                </Fragment>
              ))}

            <IconButton
              aria-label="Close"
              className={classes.close}
              color="inherit"
              key="close"
              onClick={onClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          </span>
          {errorLog && (
            <Button
              style={{
                color: 'gray',
                float: 'right'
              }}
              onClick={e => {
                const textField = document.createElement('textarea');
                textField.innerText = JSON.stringify(
                  errorLog,
                  Object.getOwnPropertyNames(errorLog)
                );
                document.body.appendChild(textField);
                textField.select();
                document.execCommand('copy');
                textField.remove();
                e.preventDefault();
                e.target.innerHTML = 'Copied';
                e.target.disabled = true;
              }}
            >
              Copy Error
            </Button>
          )}
        </>
      }
      // action={[<></>]}
      {...other}
    />
  );
}
const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

class CustomizedSnackbars extends React.Component {
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.props.closeAction();
  };

  render() {
    let mode = this.props.renderProps[0];
    if (mode === 'off') return null;
    const message = this.props.renderProps[1];
    const errorLog = this.props.renderProps[2] || '';
    let autoHideDuration = 2000;
    if (mode === 'error') {
      autoHideDuration = 4000;
    } else if (mode === 'longSuccess') {
      autoHideDuration = 30000;
      mode = 'success';
    }

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          autoHideDuration={autoHideDuration}
          open
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            errorLog={errorLog}
            message={message}
            variant={mode}
            onClose={this.handleClose}
          />
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  renderProps: state.pageNotification
});
const connectedSnackbar = connect(mapStateToProps)(CustomizedSnackbars);
const StylizedSnackBar = withStyles(styles2)(connectedSnackbar);

export default StylizedSnackBar;
