/* eslint-disable import/no-cycle */
import React, { useMemo, useState } from 'react';

import { InlineAlert, InlineAlertTypes, TV, TW, Typography } from '@BuildHero/sergeant';
import Divider from '@material-ui/core/Divider';
import WarningIcon from '@material-ui/icons/Warning';
import { noop } from 'lodash';
import moment from 'moment-timezone';

import AttachmentModal from 'components/AttachmentSection/AddAttachment';
import AppConstants from 'utils/AppConstants';
import { Mode } from 'utils/constants';

import { useUpsertTimesheetNotes } from '../../customHooks';
import { upsertTimesheetNote } from '../../services';

import DayCardFields from './DayCardFields';
import DismissedBinders from './DismissedBinders';
import HourlyTotals from './HourlyTotals';
import TimesheetCards from './TimesheetCards';
import WorkEventDetails from './WorkEventDetails';

const useStyles = () => ({
  daySummaryLine: {
    paddingBottom: 24,
    display: 'flex',
    justifyContent: 'space-between'
  },
  dayContainer: {
    boxShadow: '0 3px 11px 0 #f3f3f3ff',
    padding: 24,
    border: '2px solid #999999',
    borderRadius: '4px'
  },
  dayTotals: {
    justifyContent: 'flex-end'
  },
  timesheetDate: {
    marginTop: 10
  }
});

export const DayCard = ({
  day,
  payrollHourTypes,
  updateTimesheetEntry,
  updateTimesheetEntryBinder,
  isEditable,
  payrollSetting,
  newAuditLogs = [],
  children,
  tenantId,
  refetchTimesheetPeriod,
  employeeId,
  tab,
  setDismissedBinderMap = noop,
  onUpdateDayCard = noop,
  canDismiss = false,
  areBindersEnabled = false
}) => {
  const styles = useStyles();
  const [notesModalData, setNotesModalData] = useState();
  const {
    dayStartUTC,
    workEvents,
    dismissedBinders = [],
    dailyTotals,
    validHourTypes,
    dailyUnsubmittedEvents
  } = day;

  const [upsertTimesheetNotes] = useUpsertTimesheetNotes();
  const startDayCompanyTZ = useMemo(
    () => moment.tz(moment.unix(dayStartUTC).format(), payrollSetting.timeZone),
    [dayStartUTC, payrollSetting]
  );

  if (workEvents.length === 0 && dismissedBinders.length === 0) return null;

  return (
    <>
      <div css={styles.dayContainer}>
        <div css={styles.daySummaryLine}>
          <Typography css={styles.timesheetDate} variant={TV.L} weight={TW.BOLD}>
            {startDayCompanyTZ.format(AppConstants.TIMESHEET_DATE_FORMAT)}
          </Typography>
          <div css={styles.dayTotals}>
            <HourlyTotals
              hourlyTotals={dailyTotals}
              payrollHourTypes={payrollHourTypes}
              validHourTypes={validHourTypes}
            />
          </div>
          {children}
        </div>

        <Divider />

        <DayCardFields
          employeeId={employeeId}
          startDayCompanyTZ={startDayCompanyTZ.unix()}
          timezone={payrollSetting.timeZone}
        />

        {/* TODO: remove once WIT FF enabled everywhere */}
        {dailyUnsubmittedEvents?.map(workEvent => (
          <WorkEventDetails
            payrollSetting={payrollSetting}
            setDismissedBinderMap={setDismissedBinderMap}
            timeZone={payrollSetting.timeZone}
            workEvent={workEvent}
          >
            <InlineAlert
              Icon={WarningIcon}
              style={{ marginBottom: 40 }}
              type={InlineAlertTypes.YELLOW}
            >
              Incomplete timesheet. Technician needs to submit hours
            </InlineAlert>
            <Divider />
          </WorkEventDetails>
        ))}

        <TimesheetCards
          areBindersEnabled={areBindersEnabled}
          canDismiss={canDismiss}
          employeeId={employeeId}
          isEditable={isEditable}
          isIncomplete={dailyUnsubmittedEvents?.length > 0}
          newAuditLogs={newAuditLogs}
          payrollHourTypes={payrollHourTypes}
          payrollSetting={payrollSetting}
          setDismissedBinderMap={setDismissedBinderMap}
          setNotesModalData={setNotesModalData}
          tab={tab}
          tenantId={tenantId}
          updateTimesheetEntry={updateTimesheetEntry}
          updateTimesheetEntryBinder={updateTimesheetEntryBinder}
          workEvents={workEvents}
          onUpdateDayCard={onUpdateDayCard}
        />

        {areBindersEnabled && (
          <DismissedBinders
            canDismiss={canDismiss}
            dismissedBinders={dismissedBinders}
            employeeId={employeeId}
            newAuditLogs={newAuditLogs}
            payrollHourTypes={payrollHourTypes}
            payrollSetting={payrollSetting}
            setDismissedBinderMap={setDismissedBinderMap}
            setNotesModalData={setNotesModalData}
            tenantId={tenantId}
            onUpdateDayCard={onUpdateDayCard}
          />
        )}
      </div>
      <AttachmentModal
        attachmentRequired={false}
        data={notesModalData?.data}
        descriptionLabel="Content"
        descriptionPlaceholder="Enter Note Content..."
        handleClose={success => {
          if (success) refetchTimesheetPeriod();
          setNotesModalData();
        }}
        mode={notesModalData?.mode}
        mutationService={areBindersEnabled ? upsertTimesheetNotes : upsertTimesheetNote}
        open={notesModalData}
        parent={notesModalData?.parent}
        subjectLabel="Subject"
        subjectPlaceholder="Enter Note Subject..."
        subjectRequired
        title={`${notesModalData?.mode === Mode.EDIT ? 'Edit' : 'New'} Timesheet Note`}
      />
    </>
  );
};
