import React from 'react';

import { Image as PDFImage, StyleSheet, Text, View } from '@react-pdf/renderer';
import moment from 'moment';

import StorageService from 'services/StorageService';
import { grayscalePdf } from 'themes/BuildHeroTheme';

const style = StyleSheet.create({
  label: {
    fontFamily: 'Helvetica',
    fontSize: '10pt',
    color: grayscalePdf(60),
    marginVertical: '4.2pt'
  },
  field: {
    fontFamily: 'Helvetica',
    fontSize: '12pt'
  },
  image: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    border: `0.1pt solid ${grayscalePdf(60)}`,
    marginBottom: 6,
    objectFit: 'cover'
  }
});

export const SignatureScreen = ({ field, options }) => {
  const { value } = field;

  let initialValues = { name: '', date: '', signature: null };
  if (value.length) {
    try {
      initialValues = JSON.parse(value);
    } catch (error) {
      console.log(`Signature Values: ${error}`);
    }
  }

  const { name, date, signature } = initialValues;
  const { label } = options;
  const signatureUrl = new StorageService().getFile(signature);

  const formatDate = moment.unix(date).format('l');

  if (signature?.includes('data:image/png;base64')) {
    return (
      <View style={{ maxWidth: 300, maxHeight: 150, marginVertical: 12 }}>
        <Text style={style.label}>{label}</Text>
        <PDFImage alt={label} src={signature} style={style.image} />
        <Text style={style.field}>{`${name} - ${formatDate}`}</Text>
      </View>
    );
  }

  return (
    <View style={{ maxWidth: 300, maxHeight: 150, marginVertical: 12 }}>
      <Text style={style.label}>{label}</Text>
      {signature && <PDFImage alt={label} src={signatureUrl} style={style.image} />}
      {signature && <Text style={style.field}>{`${name} - ${formatDate}`}</Text>}
    </View>
  );
};
