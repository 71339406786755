import React from 'react';

import { MUIFormComponents } from '@BuildHero/sergeant';

import Select from 'components/Select';
import useEmployees from 'customHooks/useEmployees';

import EmployeeMenuItem from './EmployeeMenuItem';

const Option = ({ data, innerRef, isFocused, innerProps }) => {
  return (
    <div style={{ display: 'flex' }}>
      <EmployeeMenuItem
        id={data.value}
        innerProps={innerProps}
        innerRef={innerRef}
        isFocused={isFocused}
        name={data.label}
        profilePictureUrl={data.profilePictureUrl}
      />
    </div>
  );
};

/*
 * CUSTOM OPTIONS
 * options: {
 *   filterOptions: fn(
 *      form, - only if you need to access form values for filtering
 *   )
 * }
 */
const ForemanSelector = ({ form, options, field }) => {
  const [employees] = useEmployees({ includeDepartments: true });

  const getEmployeeFromId = id => employees.find(employee => employee.id === id);

  // filter employees with custom filter
  let employeeOptions = employees;
  if (options.filterOptions) {
    employeeOptions = options.filterOptions(employees, form);
  }

  const listOptions = employeeOptions.map(employee => ({
    label: employee.name,
    value: employee.id,
    profilePictureUrl: employee.profilePictureUrl
  }));

  listOptions.unshift({
    label: 'None',
    value: null
  });

  const handleOnChange = option => form.setFieldValue(field.name, option.value);

  const foremanEmployee = getEmployeeFromId(form.values?.foremanId);
  const selectValue = foremanEmployee
    ? {
        label: foremanEmployee.name,
        value: foremanEmployee.id,
        profilePictureUrl: foremanEmployee.profilePictureUrl
      }
    : null;

  return (
    <>
      <MUIFormComponents.LabelControl value="Foreman" />
      <Select
        components={{
          Option
        }}
        isDisabled={options.disabled}
        options={listOptions}
        value={selectValue}
        onChange={option => handleOnChange(option)}
      />
    </>
  );
};

export default ForemanSelector;
