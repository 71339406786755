import gql from 'graphql-tag';

const updatePurchaseOrderLine = gql`
  mutation updatePurchaseOrderLine($partitionKey: String, $data: UpdatePurchaseOrderLineInput!) {
    updatePurchaseOrderLine(partitionKey: $partitionKey, data: $data)
      @rest(
        type: "PurchaseOrderLine"
        path: "purchaseorderlines/{args.data.id}"
        endpoint: "pmapi"
        method: "PUT"
        bodyKey: "data"
      ) {
      id
      sortKey
      itemName
      vendorName
      description
      departmentName
      accountingRefIdOfClass
      priceBookEntryId
      quantity
      unitCost
      unitPrice
      markup
      amount
      createdBy
      includeInInvoice
      taxable
      version
      parentId
      costCodeId
      jobCostTypeId
      revenueTypeId
      billingStatus
    }
  }
`;

export default updatePurchaseOrderLine;
