import { row } from 'utils/layout';

export const addFilesLayout = ({ onSelectFolder }) => ({
  fields: {},
  layouts: {
    default: {
      options: {
        minHeight: 400,
        minWidth: 900
      },
      contents: [
        row([
          {
            component: 'FilesManagerComponent',
            options: {
              onSelectFolder
            }
          }
        ])
      ]
    }
  }
});
