export const INVENTORY_TABLE_COLUMNS = {
  QUANTITY: 'quantity',
  DESCRIPTION: 'description',
  UNIT_COST: 'unitCost',
  UNIT_PRICE: 'unitPrice',
  MARKUP: 'markup',
  UNIT_OF_MEASURE: 'unitOfMeasure'
};

export const SCOPE_AND_PRICING_TAB = '?tab=pricing';

export const LABEL_SCHEMA = {
  addIncurredCostsHeader: {
    en: 'Add Incurred Costs to Quote',
    es: 'Agregar costos incurridos a la cotización'
  },
  skipIncurredCosts: {
    en: 'Skip',
    es: 'Saltar'
  },
  addCostsToQuote: {
    en: 'Add Costs to Quote',
    es: 'Agregar costos a la cotización'
  },
  addTasks: {
    en: 'Add tasks',
    es: 'Agregar tareas'
  },
  createTaskHeader: {
    en: 'Create a task or multiple tasks and select the incurred costs to include in the quote.',
    es:
      'Cree una tarea o varias tareas y seleccione los costos incurridos para incluirlos en la cotización.'
  },
  addTaskPlaceholder: {
    en: 'Add Task',
    es: 'Agregar tarea'
  },
  taskDescriptionPlaceholder: {
    en: 'Task description',
    es: 'Descripción de la tarea'
  },
  partsAndMaterials: {
    en: 'Parts & Materials',
    es: 'Piezas y materiales'
  },
  labor: {
    en: 'Labor',
    es: 'Mano de obra'
  },
  noPartsAndMaterials: {
    en: 'No parts & material data',
    es: 'Sin datos de piezas y materiales'
  },
  noLabor: {
    en: 'No labor data',
    es: 'Sin datos laborales'
  },
  addTask: {
    en: '+ Add task',
    es: '+ Agregar tarea'
  }
};
