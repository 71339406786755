import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';

import { Button as SgtButton, ThemeProvider } from '@BuildHero/sergeant';
import { Button, IconButton, Popover } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import classnames from 'classnames';
import { object, oneOf, shape, string } from 'prop-types';

import { useStyles } from './PopOverButton.styles';

export const PopOverButtonTypes = {
  button: 'button',
  select: 'select',
  icon: 'icon'
};

const PopOverButton = forwardRef((props, ref) => {
  const { classes: propClasses, buttonText, type, buttonProps, children } = props;
  const classes = useStyles();
  const [openPopover, setOpenPopover] = useState(false);
  const buttonRef = useRef();

  const handlePopOverToggle = useCallback(() => setOpenPopover(!openPopover), [openPopover]);

  useImperativeHandle(ref, () => ({
    togglePopOverToggle() {
      handlePopOverToggle();
    }
  }));

  return (
    <div className={propClasses.root}>
      {type === PopOverButtonTypes.select && (
        <Button
          className={classnames(classes.button, propClasses.button)}
          endIcon={<ArrowDropDownIcon />}
          ref={buttonRef}
          onClick={handlePopOverToggle}
          {...buttonProps}
        >
          {buttonText}
        </Button>
      )}
      {type === PopOverButtonTypes.button && (
        <ThemeProvider>
          <SgtButton
            className={propClasses.button}
            ref={buttonRef}
            onClick={handlePopOverToggle}
            {...buttonProps}
          >
            {buttonText}
          </SgtButton>
        </ThemeProvider>
      )}
      {type === PopOverButtonTypes.icon && (
        <IconButton
          className={propClasses.button}
          ref={buttonRef}
          onClick={handlePopOverToggle}
          {...buttonProps}
        >
          {buttonText}
        </IconButton>
      )}
      <Popover
        anchorEl={() => buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        keepMounted
        open={openPopover}
        onClose={handlePopOverToggle}
      >
        <div className={classnames(classes.popOver, propClasses.popOver)}>{children}</div>
      </Popover>
    </div>
  );
});

PopOverButton.propTypes = {
  classes: shape({
    root: string,
    button: string,
    popOver: string
  }),
  buttonProps: object,
  buttonText: string,
  type: oneOf(Object.values(PopOverButtonTypes))
};

PopOverButton.defaultProps = {
  buttonProps: {},
  buttonText: 'Press',
  classes: {},
  type: PopOverButtonTypes.select
};

export default PopOverButton;
