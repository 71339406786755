import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';

export const styles = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  failure: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing()
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  close: {
    margin: 4
  }
});

export const styles2 = theme => ({
  margin: {
    margin: theme.spacing()
  }
});
