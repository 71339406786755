import React, { useRef, useState } from 'react';

import { Box, Chip, IconButton, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment-timezone';

import { PopperMenu, ResponsiveTable } from 'components';
import { maintenanceRowsMeta } from 'meta/ServiceAgreements/tables/maintenance';
import { AppConstants } from 'utils/AppConstants';

import { getIntervalValue, getOverAllSummary } from './helpers';
import useStyles from './Item.styles';
import { ViewPartsAndMaterials } from './ViewPartsAndMaterials';

const PartsAndMaterials = ({ record: { maintenanceTaskRequiredParts } }) => {
  const [viewParts, setViewParts] = React.useState(false);
  const { items = [] } = maintenanceTaskRequiredParts;
  return (
    <>
      <Typography style={{ cursor: 'pointer' }} variant="body2" onClick={() => setViewParts(true)}>
        <u>{`${items.length} Parts and Materials Required`}</u>
      </Typography>
      <ViewPartsAndMaterials
        data={items}
        disableQtyInput
        isVisible={viewParts}
        onModalClose={() => setViewParts(false)}
      />
    </>
  );
};

const Item = ({
  data,
  handleEditTemplate,
  handleDeleteTemplate,
  isAssetEnabled,
  isReadOnly,
  companyTimeZone
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const anchorRef = useRef();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const { label = '-' } = getIntervalValue(data);

  const ExpandIcon = isExpanded ? ArrowDropUpIcon : ArrowDropDownIcon;

  const renderTags = item => (
    <Typography className={classes.tags} variant="body2">
      {item.tagName}
    </Typography>
  );

  let tableMaintenanceMeta = maintenanceRowsMeta;
  if (!isAssetEnabled) {
    tableMaintenanceMeta = maintenanceRowsMeta.filter(item => item.id !== 'asset.assetName');
  }
  return (
    <Box className={classes.container} display="flex" flex={1} p={1.5} pr={2}>
      <ExpandIcon className={classes.expandIcon} onClick={() => setIsExpanded(!isExpanded)} />
      <Box alignItems="flex-start" display="flex" flex={1} flexDirection="column">
        <Box alignItems="center" display="flex" pb={0.5} width="100%">
          <Typography variant="body1">
            <b>{data?.customerProperty?.companyName}</b>
          </Typography>
          {data?.maintenanceTypeName && (
            <Typography
              style={{ color: theme.palette.grayscale(50), marginLeft: 5 }}
              variant="body2"
            >
              {`- ${data.maintenanceTypeName}`}
            </Typography>
          )}
          <Chip
            avatar={<CalendarTodayIcon className={classes.scheduleIcon} />}
            className={classes.titleChip}
            label={label}
            size="small"
          />
          <Box display="flex" flex={1} justifyContent="space-between">
            <Box display="flex">{data.tags.map(renderTags)}</Box>
            <Box display="flex">
              <Typography css={{ marginRight: 8 }} variant="body2">
                {`First Due Date: `}
                <b>
                  {data.firstDueDate
                    ? moment
                        .tz(moment.unix(data.firstDueDate), companyTimeZone)
                        .format(AppConstants.DATE_FORMAT)
                    : '-'}
                </b>
              </Typography>
              <Typography variant="body2">
                {`End Date: `}
                <b>
                  {data.endDate
                    ? moment
                        .tz(moment.unix(data.endDate), companyTimeZone)
                        .format(AppConstants.DATE_FORMAT)
                    : '-'}
                </b>
              </Typography>
              {!isReadOnly && (
                <>
                  <IconButton
                    aria-label="Vert Icon"
                    className={classes.vertIconButton}
                    disableRipple
                    ref={anchorRef}
                    onClick={() => setIsPopperOpen(true)}
                  >
                    <MoreVertIcon style={{ fontSize: 24, color: theme.palette.grayscale(20) }} />
                  </IconButton>
                  <PopperMenu
                    anchorEl={anchorRef.current}
                    itemList={[
                      {
                        label: 'Edit',
                        icon: 'Edit',
                        onClick: () => handleEditTemplate(data)
                      },
                      {
                        label: 'Delete',
                        icon: 'Delete',
                        onClick: () => handleDeleteTemplate(data)
                      }
                    ]}
                    open={isPopperOpen}
                    onClose={() => setIsPopperOpen(false)}
                  />
                </>
              )}
            </Box>
          </Box>
        </Box>
        <Typography className={classes.overAllCount} variant="body2">
          {getOverAllSummary(data)}
        </Typography>
        {isExpanded && (
          <Box display="flex" flexDirection="column" width="100%">
            <Typography variant="body2">
              <b>Notes:</b> {data.serviceDescription}.
            </Typography>
            <Box display="flex" flex={1} mt={1}>
              <ResponsiveTable
                customCellComponents={{ PartsAndMaterials }}
                data={data.maintenanceTaskTemplates || []}
                disableFilter
                noDataMsg="No tasks"
                rowMetadata={tableMaintenanceMeta}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Item;
