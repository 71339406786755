/* eslint-disable no-template-curly-in-string */
import { object, string } from 'yup';

import { toLengthTest } from 'meta/validations';

export default isVistaIntegrated =>
  object({
    parentName: string()
      .nullable()
      .required('Selection is required.'),
    department: isVistaIntegrated
      ? object({ value: string() })
          .nullable()
          .required('Department is required.')
      : undefined,
    billTo: string()
      .nullable()
      .test(toLengthTest())
      .required('Billing information is required.'),
    priceBook: object({ value: string() })
      .nullable()
      .required('Price book is required.')
  });
