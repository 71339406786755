import React, { useState } from 'react';

import { SgtForm, TH, TV, TW, Typography } from '@BuildHero/sergeant';
import { Box, ButtonBase, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { DndTags } from './DndTags';

const configuration = (fieldName, fieldKey, displayName, onDelete, setChanged) => ({
  layouts: {
    default: {
      contents: [
        {
          source: fieldName,
          component: 'DndTags',
          props: {
            fieldName,
            fieldKey,
            displayName,
            onDelete,
            setChanged
          }
        }
      ]
    }
  }
});

export const CustomFieldsCard = props => {
  const { data, displayName, fieldName, setFormService, onDelete, setChanged } = props;
  const [expand, setExpand] = useState(false);

  return (
    <>
      <Box alignItems="center" display="flex" marginBottom={1}>
        <ButtonBase style={{ paddingRight: 10 }} onClick={() => setExpand(!expand)}>
          {expand ? <ExpandMore /> : <ExpandLess />}
          <Typography height={TH.COMPACT} variant={TV.L} weight={TW.BOLD}>
            {displayName}
          </Typography>
        </ButtonBase>
      </Box>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <SgtForm
          configuration={configuration(fieldName, 'tagName', displayName, onDelete, setChanged)}
          customComponents={{ DndTags }}
          formikProps={{ enableReinitialize: true }}
          initialValues={{ [fieldName]: data }}
          onCreateService={service => setFormService(fieldName, service)}
        />
      </Collapse>
    </>
  );
};

CustomFieldsCard.propTypes = {
  data: PropTypes.any.isRequired, // data provided to the SgtForm
  displayName: PropTypes.string.isRequired, // the name of form to display at header (e.g. Customer Tags)
  fieldName: PropTypes.string.isRequired, // the name of the custom field (e.g. customerTags)
  setFormService: PropTypes.func.isRequired, // function that used to save form service into states
  onDelete: PropTypes.func.isRequired, // the function that should be called to delete a tag
  setChanged: PropTypes.func.isRequired // called upon tag creation and modification
};
