import React from 'react';

import Divider from '@material-ui/core/Divider';
import { bool, string } from 'prop-types';

import {
  CheckCircleOutline,
  VisitCloneIcon,
  VisitContinueAsNewIcon,
  VisitDeleteIcon,
  VisitReleaseIcon
} from '@dispatch/icons';

import { useStyles } from './MenuItems.styles';

export const MenuItem = ({ text, icon, showDivider }) => {
  const classes = useStyles();

  return (
    <div className={classes.itemContainer}>
      {showDivider && <Divider className={classes.divider} />}
      {icon && (
        <div className={classes.imgContainer}>
          <img alt={text} className={classes.img} src={icon} />
        </div>
      )}
      <div className={classes.text}>{text}</div>
    </div>
  );
};

MenuItem.propTypes = {
  text: string.isRequired,
  // eslint-disable-next-line react/require-default-props
  icon: string,
  // eslint-disable-next-line react/require-default-props
  showDivider: bool
};

export const CloneVisitMenuItem = <MenuItem icon={VisitCloneIcon} text="Clone Visit" />;

export const ContinueVisitAsNewMenuItem = (
  <MenuItem icon={VisitContinueAsNewIcon} text="Continue in a New Visit" />
);

export const DeleteMenuItem = <MenuItem icon={VisitDeleteIcon} text="Delete" />;

export const DeleteAllMenuItem = <MenuItem icon={VisitDeleteIcon} text="Delete All" />;

export const ReleaseToTechMenuItem = <MenuItem icon={VisitReleaseIcon} text="Release to Tech" />;

export const CreatePurchaseOrderMenuItem = <MenuItem text="Create Purchase Order" />;

export const CreateFieldOrderMenuItem = <MenuItem text="Create Field Order" />;

export const CompleteProjectVisit = (
  <MenuItem icon={CheckCircleOutline} showDivider text="Mark Visit as Complete" />
);
