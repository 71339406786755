import { mappingRequired } from '../../graphql/common/schemas/baseEntity';
import visitSchema, { visitSchemaMappings } from '../../graphql/scheduling/visit/schemas/visit';

const mapEntity = (obj, rootSchema, mapSchemaObj = null) => {
  let retObj = {};
  let mapSchema = null;
  if (mapSchemaObj) {
    mapSchema = mapSchemaObj.map(e => e.key);
  }

  const validateOutput = obj => {
    const valid = obj;
    mappingRequired.forEach(key => {
      if (!obj[key]) {
        if (key === 'tenantCompanyId' && obj.sortKey) {
          valid.tenantCompanyId = obj.sortKey.split('_')[1];
        }
        if (key === 'tenantId' && obj.partitionKey) {
          valid.tenantId = obj.partitionKey;
        }
      }
    });
    return valid;
  };

  Object.keys(obj).forEach(key => {
    if (rootSchema.includes(key)) {
      retObj[key] = obj[key];
    }

    if (mapSchema && mapSchema.includes(key)) {
      const map = mapSchemaObj.filter(e => e.key === key);
      if (map && map.length && map[0].mapType === '1M' && map[0].type !== 'parent') {
        const items = obj[key];
        retObj[key] = { items: [] };
        items.forEach(item => {
          retObj[key].items.push(mapEntity(item, map[0].connection));
        });
      }
      if (map && map.length && map[0].mapType === 'MM') {
        const mapItems = obj[key];
        retObj[key] = { items: [] };
        mapItems.forEach(item => {
          retObj[key].items.push(mapEntity(item, map[0].connection));
        });
      }
    }
  });
  retObj = validateOutput(retObj);
  return retObj;
};

const visitSchemaDataset = visit => {
  const data = {};
  const parentMap = visitSchemaMappings.find(e => e.type === 'parent');
  if (visit[parentMap.key]) {
    const parentEntity = visit[parentMap.key];
    data.entityType = parentEntity.entityType;
    data.tenantId = parentEntity.tenantId;
    data.sortKey = parentEntity.sortKey;
    data.id = parentEntity.id;
    data.hierarchy = parentEntity.hierarchy;
  }
  const mappings = mapEntity(visit, visitSchema, visitSchemaMappings);
  const ret = [{ ...data, visits: { items: [mappings] } }];
  return { data: JSON.stringify(ret) };
};

export default visitSchemaDataset;
