import { InvoiceItemType } from 'utils/constants';

export const Action = {
  SAVE: 'save',
  POST: 'post',
  APPLY_PAYMENT: 'apply_payment',
  NEW_PAYMENT: 'new_payment',
  PREVIEW: 'preview',
  EMAIL: 'send_email',
  DOWNLOAD: 'download',
  VOID: 'void',
  SAVE_AND_BYPASS: 'save_bypass'
};

export const dataTypeToTitle = {
  [InvoiceItemType.LABOR_LINE_ITEM]: 'Labor',
  [InvoiceItemType.INVENTORY_PART]: 'Parts & Materials',
  [InvoiceItemType.PUCHASE_ORDER_LINE]: 'Parts & Materials',
  [InvoiceItemType.DISCOUNT]: 'Discounts & Fees',
  [InvoiceItemType.FEE]: 'Discounts & Fees'
};

export const Message = {
  POST_ERROR: 'Please address the errors before posting',
  EMPTY_INVOICE_ERROR: 'The invoice can not be empty'
};
