import React from 'react';

import { Checkbox } from '@BuildHero/sergeant';

export const checkboxColumn = ({
  disabled,
  onRowToggle,
  onHeaderToggle,
  isRowChecked,
  isHeaderChecked
}) => ({
  field: 'action',
  flex: 1,
  width: 41,
  maxWidth: 41,
  noPadding: true,
  align: 'center',
  justify: 'center',
  noHeaderPadding: true,
  headerAlign: 'center',
  headerJustify: 'center',
  renderHeader: () => (
    <div style={{ align: 'center' }}>
      <Checkbox
        checked={isHeaderChecked()}
        css={{ marginTop: 0 }}
        disabled={disabled}
        onChange={onHeaderToggle}
      />
    </div>
  ),
  // eslint-disable-next-line react/prop-types
  renderCell: ({ row }) => (
    <Checkbox
      checked={isRowChecked(row)}
      // eslint-disable-next-line react/prop-types
      css={{ marginTop: 0 }}
      disabled={disabled}
      onChange={onRowToggle(row)}
    />
  )
});
