import { removeNestedJson } from '../../utils';
import InstructionHelper from '../InstructionHelper';

// import { Logger } from "services/core";

const companySchema = props => {
  // const companyId = uuidV4();

  const fieldsToBeRemoved = [
    'shippingAddress',
    'billingAddress',
    'billingAddressIsSame',
    'appRoles',
    'departments',
    'employees',
    'skills',
    'parent',
    '__typename'
  ];

  const billingAddressTemplate = {
    billingAddressLine1: 'addressLine1',
    billingAddressLine2: 'addressLine2',
    billingCity: 'city',
    billingState: 'state',
    billingZipcode: 'zipcode',
    billingLatitude: 'latitude',
    billingLongitude: 'longitude',
    billingId: 'id',
    billingSortkey: 'sortKey',
    billingHierarchy: 'hierarchy'
  };
  const shippingAddressTemplate = {
    shippingAddressLine1: 'addressLine1',
    shippingAddressLine2: 'addressLine2',
    shippingCity: 'city',
    shippingState: 'state',
    shippingZipcode: 'zipcode',
    shippingLatitude: 'latitude',
    shippingLongitude: 'longitude',
    shippingId: 'id',
    shippingSortkey: 'sortKey',
    shippingHierarchy: 'hierarchy'
  };

  // Forming different address type JSONs
  const billingAddressJson = {};
  const shippingAddressJson = {};
  const otherFields = {};

  // Flags
  let hasBillingAddressField = false;
  let hasShippingAddressField = false;

  // Iterating JSON keys of filled formik form
  Object.keys(props).map(key => {
    // checks if the key is billing address template
    if (billingAddressTemplate[key] && (props[key] || props[key] === '')) {
      hasBillingAddressField = true;
      const templateKey = billingAddressTemplate[key];
      billingAddressJson[templateKey] = props[key];
    }
    // checks if the key is shipping address template
    if (shippingAddressTemplate[key] && (props[key] || props[key] === '')) {
      hasShippingAddressField = true;
      const templateKey = shippingAddressTemplate[key];
      shippingAddressJson[templateKey] = props[key];
    }

    // non address fileds to be pushed seperately
    if ((props[key] || props[key] === '') && !fieldsToBeRemoved.includes(key)) {
      if (key === 'status') {
        otherFields[key] = 'complete';
      }
      const value = typeof props[key] === 'string' ? props[key].trim() : props[key];
      otherFields[key] = value;
    }

    // empty values to be skipped
    return null;
  });

  const companyAddresses = [];
  // Iterating props and filling approp
  if (hasBillingAddressField) {
    billingAddressJson.partitionKey = props.tenantId;
    billingAddressJson.tenantId = props.tenantId;
    billingAddressJson.tenantCompanyId = props.tenantCompanyId;
    billingAddressJson.entityType = 'CompanyAddress';
    billingAddressJson.addressType = 'billingAddress';
    companyAddresses.push(billingAddressJson);
  }
  if (hasShippingAddressField) {
    shippingAddressJson.tenantId = props.tenantId;
    shippingAddressJson.tenantCompanyId = props.tenantCompanyId;
    shippingAddressJson.partitionKey = props.tenantId;
    shippingAddressJson.entityType = 'CompanyAddress';
    shippingAddressJson.addressType = 'shippingAddress';
    companyAddresses.push(shippingAddressJson);
  }

  otherFields.status = 'complete';

  const data = [
    { ...props.parent },
    { ...removeNestedJson(otherFields) },
    { ...billingAddressJson },
    { ...shippingAddressJson }
  ];
  const ins = new InstructionHelper();
  const { instructionSet } = ins
    .oneToMany(0, 1)
    .oneToMany(1, 2)
    .oneToMany(1, 3);

  return { data, instructionSet };
};

export default companySchema;
