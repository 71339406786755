import React from 'react';

import { Button, ButtonType, ThemeProvider } from '@BuildHero/sergeant';

import { useStyles } from './GenerateTimesheetsButton.styles';

const GenerateTimesheetsButton = props => {
  const styles = useStyles();
  return (
    <ThemeProvider>
      <Button css={styles.button} type={ButtonType.SECONDARY} {...props}>
        Generate Individual Timesheet Entries
      </Button>
    </ThemeProvider>
  );
};

export default GenerateTimesheetsButton;
