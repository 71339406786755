import React, { useMemo } from 'react';

import { Button, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { Box, Grid, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import uuid from 'uuid';

import { reorder } from 'utils';

import LaborRateModifierCard from './LaborRateModifierCard';

const LaborRateModifiersTable = ({
  employees,
  departments,
  loading,
  laborRateModifiers,
  setLaborRateModifiers,
  resetSortOrder
}) => {
  const theme = useTheme();

  const creatingNewLaborRateModifier =
    laborRateModifiers[laborRateModifiers.length - 1]?.name === '';

  const departmentOptions = useMemo(
    () => departments?.map(({ id, name }) => ({ id, value: id, label: name })) || [],
    [departments]
  );

  const employeeOptions = useMemo(
    () => employees?.map(({ id, name }) => ({ id, value: id, label: name })) || [],
    [employees]
  );

  const onModifierChange = ({ field, value, index }) => {
    setLaborRateModifiers(existingModifiers => {
      return existingModifiers.map((g, i) => {
        if (i === index) return { ...g, [field]: value };
        return g;
      });
    });
  };

  const onRemoveLaborRateModifier = ({ index }) => {
    setLaborRateModifiers(existingModifiers =>
      resetSortOrder(existingModifiers.filter((t, i) => i !== index))
    );
  };

  const moveModifier = ({ source, destination }) => {
    if (!Number.isInteger(source?.index) || !Number.isInteger(destination?.index)) return; // invalid drag
    setLaborRateModifiers(existingModifiers =>
      resetSortOrder(
        reorder({
          arr: existingModifiers,
          source: source.index,
          destination: destination.index
        })
      )
    );
  };

  const onAddLaborRateModifier = () => {
    setLaborRateModifiers(existingModifiers => [
      ...existingModifiers,
      {
        id: uuid.v4(),
        name: '',
        appliesToCategory: '',
        appliesTo: [],
        modifierValue: 0.0,
        modifierType: 'percentage',
        unsaved: true,
        sortOrder: existingModifiers.length
      }
    ]);
  };

  return (
    <ThemeProvider>
      <Grid item style={{ padding: 0, width: '100%' }}>
        <Box component="div">
          <DragDropContext onDragEnd={moveModifier}>
            <Droppable droppableId="droppable" isDropDisabled={loading}>
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {laborRateModifiers.map((item, index) => (
                    <LaborRateModifierCard
                      departmentOptions={departmentOptions}
                      employeeOptions={employeeOptions}
                      index={index}
                      isDragDisabled={loading}
                      item={item}
                      laborRateModifiers={laborRateModifiers}
                      onAddModifier={onAddLaborRateModifier}
                      onModifierChange={onModifierChange}
                      onRemoveModifier={onRemoveLaborRateModifier}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {!creatingNewLaborRateModifier && (
              <Button type="leading" onClick={onAddLaborRateModifier}>
                <AddIcon css={{ height: theme.spacing(3) }} />
                <Typography variant={TV.BASE} weight={TW.MEDIUM}>
                  Add Labor Rate Modifier
                </Typography>
              </Button>
            )}
          </DragDropContext>
        </Box>
      </Grid>
    </ThemeProvider>
  );
};

LaborRateModifiersTable.propTypes = {
  employees: PropTypes.array,
  departments: PropTypes.array,
  loading: PropTypes.bool,
  laborRateModifiers: PropTypes.array,
  setLaborRateModifiers: PropTypes.func,
  resetSortOrder: PropTypes.func
};

LaborRateModifiersTable.defaultProps = {
  employees: [],
  departments: [],
  loading: false,
  laborRateModifiers: [],
  setLaborRateModifiers: () => {},
  resetSortOrder: () => {}
};

export default LaborRateModifiersTable;
