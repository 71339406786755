import React from 'react';

import { Typography } from '@BuildHero/sergeant';
import { bool } from 'prop-types';
import { Link } from 'react-router-dom';

import { VISIT_DETAILS_PROP } from '@dispatch/queries';
import Routes from 'scenes/Routes';

import { useStyles } from '../../VisitDetailsForm.styles';

const CustomerName = ({ visit, loading }) => {
  const classes = useStyles();

  return (
    <Typography classes={{ root: classes.oneLineText }} loading={loading} variant="body2">
      <Link to={Routes.customerId({ mode: 'view', id: visit?.customerId })}>
        {visit?.customerName}
      </Link>
    </Typography>
  );
};

CustomerName.propTypes = {
  visit: VISIT_DETAILS_PROP.isRequired,
  loading: bool.isRequired
};

export default CustomerName;
