import React from 'react';

import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import labels from 'meta/labels';
import { getNumberValue } from 'utils';

import Tooltip from '../../../ToolTip';

import FormikField from './Field';
import componentStrategy from './strategy';

/**
 * Renders Grid rows for the form. uses grid settings defined in the layout meta
 * @param {row} props
 */

const fieldRows = props => {
  const rowItem = props.row;
  const { values, ...rest } = props;
  let lgValue = 2;
  let mdValue = 4;
  let smValue = 12;

  const gridItems = rowItem.cols.map((colItem, index) => {
    if (colItem.type === 'rowHeading') {
      const { dependentField, hideFieldOnCondition } = colItem.specialBehaviour || {};

      if (
        hideFieldOnCondition &&
        dependentField &&
        (props[dependentField] === '' || props[dependentField] === 'false')
      ) {
        return <></>;
      }

      smValue = getNumberValue(colItem.sm) || 12;
      mdValue = getNumberValue(colItem.md) || 12;
      lgValue = getNumberValue(colItem.lg) || 12;

      // if no behaviour for view/edit/new, dont show the field
      if (!colItem.behavior[props.mode]) {
        return null;
      }
      // to show only field only on edit
      if (colItem.showOnEditOnly && props.mode === 'new') {
        return null;
      }

      // Dirty fix for OR field in the form
      return (
        <Grid
          item
          key={`grid${index}`}
          lg={lgValue}
          md={mdValue}
          sm={smValue}
          style={colItem.value === 'or' ? { padding: 35 } : {}}
          xl={lgValue}
          xs={smValue}
        >
          <Typography style={{ color: '#3f3f3f' }} variant="body2">
            {(labels[colItem.value] && labels[colItem.value][props.user.locale]) || colItem.value}
            {colItem.infoToolTip ? <Tooltip aria-label="Add" helpText={colItem.infoToolTip} /> : ''}
          </Typography>
        </Grid>
      );
    }

    if (colItem.type === 'component') {
      smValue = getNumberValue(colItem.sm) || 12;
      mdValue = getNumberValue(colItem.md) || 12;
      lgValue = getNumberValue(colItem.lg) || 12;

      return (
        <Grid
          item
          key={`grid${index}`}
          lg={lgValue}
          md={mdValue}
          sm={smValue}
          style={colItem.value === 'or' ? { padding: 35 } : {}}
          xl={lgValue}
          xs={smValue}
        >
          {componentStrategy(colItem.value, props)}
        </Grid>
      );
    }
    let showField = true;
    if (colItem.showField) {
      // TODO: evaluate if not null condition is enough
      showField = !!props[colItem.showField];
    }

    if (colItem.hideField && props[colItem.hideField]) {
      // TODO: evaluate if not null condition is enough
      showField = false;
    }

    return (
      showField && (
        <FormikField
          behaviour={colItem.behavior}
          cols={colItem}
          index={index}
          {...rest}
          key={`formikField${index}`}
        />
      )
    );
  });
  return gridItems;
};

export default fieldRows;
