import React, { useCallback, useEffect } from 'react';

import { calculateMarginFromMarkup, calculateMarkupFromMargin } from '@BuildHero/math';
import { PercentageInput, ThemeProvider } from '@BuildHero/sergeant';
import PropTypes from 'prop-types';

import { PricingStrategy } from 'utils/constants';
import { convertForMathLib } from 'utils/mathLibrary';

const DefaultRate = ({ form, field, options }) => {
  const { showConfigSection, marginEnabled } = options;
  let label;

  useEffect(() => {
    if (field.value) {
      const value =
        form?.values?.pricebookConfig === PricingStrategy.MARGIN
          ? form?.values?.baseMargin
          : form?.values?.baseMarkup;

      form.setFieldValue(field.name, value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.pricebookConfig]);

  if (showConfigSection) {
    label =
      form?.values?.pricebookConfig === PricingStrategy.MARGIN ? 'Base Margin' : 'Base Markup';
  } else {
    label = marginEnabled ? 'Base Margin' : 'Base Markup';
  }

  const handleChange = useCallback(
    evt => {
      if (form.values.pricebookConfig === PricingStrategy.MARGIN) {
        form.setFieldValue('baseMarkup', convertForMathLib(calculateMarkupFromMargin, evt));
        form.setFieldValue('baseMargin', evt);
      } else {
        form.setFieldValue('baseMarkup', evt);
        form.setFieldValue('baseMargin', convertForMathLib(calculateMarginFromMarkup, evt));
      }

      form.setFieldValue(field.name, evt);
    },
    [field.name, form]
  );

  return (
    <ThemeProvider>
      <PercentageInput
        error={form?.errors?.defaultMarkup}
        label={label}
        required
        subtext={form?.errors?.defaultMarkup}
        value={field.value}
        onChange={handleChange}
      />
    </ThemeProvider>
  );
};

export default DefaultRate;

DefaultRate.propTypes = {
  options: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired
};
