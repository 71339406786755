import React from 'react';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { compose } from 'redux';

import { AuditLogs } from 'components';
import ErrorBoundaries from 'scenes/Error';
import { JobService } from 'services/core';

import styles from '../styles';

const ActivityList = props => {
  const { classes, maintenanceNumber } = props;

  return (
    <div className={classes.activityContainer}>
      <ErrorBoundaries>
        <Typography className={classes.activityTitle} variant="h4">
          Activity
        </Typography>
        <AuditLogs
          dataService={() => new JobService().getAllAuditLogByJobNumber(maintenanceNumber)}
          variant="singleEntity"
        />
      </ErrorBoundaries>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, null)
)(ActivityList);
