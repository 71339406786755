import React, { useMemo } from 'react';

import { ButtonType, MultiSelect } from '@BuildHero/sergeant';
import { isEqual } from 'lodash';

import { useConfirmModal } from 'customHooks/ConfirmModalContext';

const changeChecklistConfirm = {
  body:
    'By adding or removing a checklist, all maintenance information (from step 4) for properties will be erased.',
  title: 'Add or Remove Checklist',
  buttonLabel: 'Continue',
  buttonType: ButtonType.ERROR
};

// @TODO write tests around the selection logic.
const AssetTypeChecklists = ({
  cell,
  assetTypeChecklistMap,
  selectedAssetChecklistMap,
  assetMap,
  onSelectionModelChange,
  deleteAllTemplateGroupsOnServiceAgreement,
  hasMetadata,
  refreshServiceAgreementPropertyMetadata
}) => {
  const { assetTypeId } = cell.row.__groupData[0];

  const confirmContext = useConfirmModal();

  const checklistOptions = useMemo(
    () =>
      assetTypeChecklistMap[assetTypeId].map(l => ({
        id: l.id,
        label: l.name,
        value: l
      })),
    [assetTypeChecklistMap, assetTypeId]
  );

  const selectedAssetsOfThisAssetType = useMemo(
    () =>
      Object.entries(selectedAssetChecklistMap)
        .map(([assetId, checklistIds]) => ({ ...assetMap[assetId], checklistIds }))
        .filter(a => a.assetTypeId === assetTypeId),
    [selectedAssetChecklistMap, assetMap, assetTypeId]
  );

  return (
    <MultiSelect
      disabled={
        // disabled if no assets of this type selected
        // or the selected assets have mismatching checklists
        !selectedAssetsOfThisAssetType.length ||
        !selectedAssetsOfThisAssetType.every(
          (a, i, arr) => i === 0 || isEqual(arr[i].checklistIds, arr[i - 1].checklistIds)
        )
      }
      options={checklistOptions}
      // TODO - this might be an expensive operation if there are many asset type checklist options
      placeholder="Select checklists"
      selectedOptions={assetTypeChecklistMap[assetTypeId]
        .filter(l =>
          Object.entries(selectedAssetChecklistMap).every(
            ([assetId, lIds]) =>
              assetMap[assetId].assetTypeId !== assetTypeId || lIds.includes(l.id)
          )
        )
        .map(l => ({ id: l.id, label: l.name, value: l }))}
      showChips
      onChange={async newSelectedOptions => {
        if (hasMetadata) {
          if (!(await confirmContext.confirm(changeChecklistConfirm))) {
            onSelectionModelChange({
              ...selectedAssetChecklistMap
            });
            return;
          }

          await deleteAllTemplateGroupsOnServiceAgreement();
          await refreshServiceAgreementPropertyMetadata();
        }

        if (!newSelectedOptions.length) {
          // deselect this asset type and all its assets
          const newSelectedAssetChecklistMap = Object.entries(selectedAssetChecklistMap).reduce(
            (acc, [aId, lIds]) => {
              if (assetMap[aId].assetTypeId === assetTypeId) return acc;
              return {
                ...acc,
                [aId]: lIds
              };
            },
            {}
          );
          onSelectionModelChange(newSelectedAssetChecklistMap);
        } else {
          // select the newlySelectedOptions for all assets of this assetType
          const newSelectedAssetChecklistMapForAssetType = Object.keys(
            selectedAssetChecklistMap
          ).reduce((acc, aId) => {
            const asset = assetMap[aId];
            if (asset.assetTypeId !== assetTypeId) return acc;

            return {
              ...acc,
              [asset.id]: newSelectedOptions.map(o => o.id)
            };
          }, {});
          onSelectionModelChange({
            ...selectedAssetChecklistMap,
            ...newSelectedAssetChecklistMapForAssetType
          });
        }
      }}
      onClickCreateOption={() => {}}
    />
  );
};

export default AssetTypeChecklists;
