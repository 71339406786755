import React from 'react';

import { ThemeProvider, Typography } from '@BuildHero/sergeant';

import { useStyles } from './InfoField.styles';

const InfoField = ({ label, value, component: Component, loading, weight }) => {
  const styles = useStyles();

  return (
    <ThemeProvider>
      <div css={styles.root}>
        <Typography css={styles.label}>{label}</Typography>
        {Component ? (
          <Component label={label} loading={loading} value={value} />
        ) : (
          <Typography loading={loading} weight={weight}>
            {value || '-'}
          </Typography>
        )}
      </div>
    </ThemeProvider>
  );
};

export default InfoField;
