import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';

import Labels from 'meta/labels';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

const AssociatedProjectQuoteTitle = ({ locale, quote }) => {
  const flags = useFlags();
  return (
    <>
      {flags[FeatureFlags.PROJECT_MANAGEMENT] && quote && (
        <p style={{ margin: 0 }}>
          <b>{`${Labels.projectAssociatedQuote[locale]}: `}</b>
          <a href={`/quote/${quote.id}`}>
            {Labels.quote[locale]} {quote.quoteNumber} {quote.name ? ` - ${quote.name}` : ''}
          </a>
        </p>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  quote: state.pm.project?.Quote
});

const AssociatedProjectQuote = connect(mapStateToProps)(AssociatedProjectQuoteTitle);
export default AssociatedProjectQuote;
