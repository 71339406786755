import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { StatusChip } from 'components';
import { OpenTaskStatus } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';

const useStyles = makeStyles(theme => ({
  chipLabel: {
    textTransform: 'capitalize'
  },
  chip: {
    margin: theme.spacing(0.5),
    height: 28
  }
}));

const StatusFilter = props => {
  const classes = useStyles();

  const { onClickStatusFilter } = props;

  // to default the open task status as per requirement
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onClickStatusFilter(OpenTaskStatus.OPEN), []);

  const handleClickFilter = clickedStatus => {
    // if you click an already active filter, it deactivates it
    onClickStatusFilter(clickedStatus);
  };

  return (
    <>
      {[OpenTaskStatus.OPEN, OpenTaskStatus.ADDED_TO_WORK].map(status => (
        <StatusChip
          classes={{
            root: classes.chip,
            label: classes.chipLabel
          }}
          enumType={EnumType.OPEN_TASK_STATUS}
          enumValue={status}
          label={status}
          onClick={() => handleClickFilter(status)}
        />
      ))}
    </>
  );
};

StatusFilter.propTypes = {
  /**
   * @func - cb called with the maintenance item status chip that was clicked
   * @param status - one of the MaintenanceStatus values, or null if
   *  unselected
   */
  onClickStatusFilter: PropTypes.func.isRequired
};

export default StatusFilter;
