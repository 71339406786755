import React, { useEffect, useRef, useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import { MUIForm } from '@BuildHero/sergeant';
import omit from 'lodash/omit';
import { bool, func, object } from 'prop-types';

import { VISIT_DETAILS_PROP } from '@dispatch/queries';

import { extraTechsVar, primaryTechIdVar } from '../EditVisit/EditVisit.vars';

import * as customComponents from './components';
import { useVisitFormConfig } from './VisitDetailsForm.config';
import { VisitFormFields } from './VisitDetailsForm.constants';
import { useVisitFormData } from './VisitDetailsForm.data';
import { useTechOptions } from './VisitDetailsForm.hooks';

const VisitDetailsForm = ({
  allowMultipleVisitsCreation,
  collapseDetails,
  createMultiVisitStart,
  crewMembersAdded,
  crewTimeTracking,
  departmentsResponse,
  disabled,
  job,
  multipleVisitsCreateInProcess,
  showTechStatuses,
  snackbarOn,
  techsResponse,
  visit,
  visitLoading,
  onComplete,
  onCreateService,
  onDirtyChange
}) => {
  const [multipleVisitsCreationEnabled, setMultipleVisitsCreationEnabled] = useState(false);
  const primaryTechId = useReactiveVar(primaryTechIdVar);
  const extraTechs = useReactiveVar(extraTechsVar);

  const { primaryTechOptions, extraTechOptions, techsLoading } = useTechOptions({
    primaryTechId,
    extraTechIds: extraTechs,
    techsResponse
  });

  const onCreateMultipleVisitsChange = () => {
    setMultipleVisitsCreationEnabled(!multipleVisitsCreationEnabled);
  };

  const visitFormConfig = useVisitFormConfig({
    disabled,
    visitLoading,
    techs: techsResponse.data || [],
    techsLoading,
    primaryTechOptions,
    extraTechOptions,
    allowMultipleVisitsCreation,
    multipleVisitsCreationEnabled,
    onCreateMultipleVisitsChange,
    departmentsResponse,
    visit,
    collapseDetails,
    schedules: visit.schedules,
    showTechStatuses,
    multipleVisitsCreateInProcess,
    job,
    crewTimeTracking,
    crewMembersAdded
  });

  const visitFormData = useVisitFormData(visit, job, crewTimeTracking);

  const handleFormNotification = (type, message) => {
    if (type === 'success') return;
    snackbarOn('error', message);
  };

  const techsLoadingRef = useRef(null);
  techsLoadingRef.current = techsResponse.loading;

  const handleFieldChange = (field, value) => {
    switch (field) {
      case 'primaryTechId': {
        primaryTechIdVar(value);
        break;
      }
      case 'extraTechs': {
        if (!techsLoadingRef.current) {
          extraTechsVar(value);
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  useEffect(() => {
    if (visit.primaryTechId) {
      handleFieldChange('primaryTechId', visit.primaryTechId);
    }
  }, [visit]);

  const handleComplete = visitData => {
    if (multipleVisitsCreationEnabled) {
      createMultiVisitStart();
      snackbarOn('success', 'Creating your visits. This may take a while.');
      onComplete(visitData, multipleVisitsCreationEnabled);
    } else {
      onComplete(
        omit(visitData, [VisitFormFields.excludeWeekend.key, VisitFormFields.endDate.key])
      );
    }
  };

  return (
    <MUIForm
      configuration={visitFormConfig}
      customComponents={customComponents}
      data={visitFormData}
      onComplete={handleComplete}
      onCreateService={onCreateService}
      onDirtyChange={onDirtyChange}
      onFieldChange={handleFieldChange}
      onFormNotification={handleFormNotification}
    />
  );
};

VisitDetailsForm.propTypes = {
  allowMultipleVisitsCreation: bool,
  collapseDetails: bool,
  createMultiVisitStart: func.isRequired,
  crewMembersAdded: bool,
  crewTimeTracking: bool,
  departmentsResponse: object.isRequired,
  disabled: bool.isRequired,
  job: object.isRequired,
  multipleVisitsCreateInProcess: bool.isRequired,
  showTechStatuses: bool,
  snackbarOn: func.isRequired,
  techsResponse: object.isRequired,
  visit: VISIT_DETAILS_PROP.isRequired,
  visitLoading: bool.isRequired,
  onComplete: func.isRequired,
  onCreateService: func.isRequired,
  onDirtyChange: func.isRequired
};

VisitDetailsForm.defaultProps = {
  allowMultipleVisitsCreation: false,
  collapseDetails: false,
  crewMembersAdded: false,
  crewTimeTracking: false,
  showTechStatuses: false
};

export default VisitDetailsForm;
