import React, { useMemo } from 'react';

import { ButtonType, MultiSelect, TV, TW, Typography } from '@BuildHero/sergeant';
import { Box, useTheme } from '@material-ui/core';

import WrapTable from 'components/WrapTable';

import { useConfirmModal } from 'customHooks/ConfirmModalContext';

import NoProperties from './NoProperties';

const staticColumns = [
  {
    field: 'name',
    headerName: 'Property',
    minWidth: 180
  },
  {
    field: 'address',
    headerName: 'Property Address',
    minWidth: 120
  }
];

const changeChecklistConfirm = {
  body:
    'By adding or removing a checklist, all maintenance information (from step 4) for properties will be erased.',
  title: 'Add or Remove Checklist',
  buttonLabel: 'Continue',
  buttonType: ButtonType.ERROR
};

const PropertyChecklists = ({
  checklists: allGeneralChecklists,
  selectedPropertyChecklistMap,
  setSelectedPropertyChecklistMap,
  deleteAllTemplateGroupsOnServiceAgreement,
  hasMetadata,
  refreshServiceAgreementPropertyMetadata
}) => {
  const theme = useTheme();

  const confirmContext = useConfirmModal();

  const generalChecklistOptions = useMemo(
    () =>
      allGeneralChecklists.map(l => ({
        id: l.id,
        label: l.name,
        value: l,
        isDefault: l.isDefault
      })),
    [allGeneralChecklists]
  );

  const columns = useMemo(() => {
    return [
      ...staticColumns,
      {
        field: 'checklists',
        headerName: 'Property Checklists',
        minWidth: 250,
        renderCell: cell => {
          return (
            <MultiSelect
              options={generalChecklistOptions}
              placeholder="Select checklists"
              selectedOptions={generalChecklistOptions?.filter(l =>
                selectedPropertyChecklistMap[cell.row.id]?.checklists?.includes(l.id)
              )}
              showChips
              onChange={async newSelectedOptions => {
                if (hasMetadata) {
                  if (!(await confirmContext.confirm(changeChecklistConfirm))) {
                    setSelectedPropertyChecklistMap({
                      ...selectedPropertyChecklistMap
                    });
                    return;
                  }

                  await deleteAllTemplateGroupsOnServiceAgreement();
                  await refreshServiceAgreementPropertyMetadata();
                }

                const propertyId = cell.row.id;
                setSelectedPropertyChecklistMap(prevChecklistMap => ({
                  ...prevChecklistMap,
                  [propertyId]: {
                    ...prevChecklistMap[propertyId],
                    checklists: newSelectedOptions.map(o => o.id)
                  }
                }));
              }}
              onClickCreateOption={() => {}}
            />
          );
        }
      }
    ];
  }, [
    confirmContext,
    deleteAllTemplateGroupsOnServiceAgreement,
    generalChecklistOptions,
    hasMetadata,
    refreshServiceAgreementPropertyMetadata,
    selectedPropertyChecklistMap,
    setSelectedPropertyChecklistMap
  ]);

  const rows = Object.entries(selectedPropertyChecklistMap)
    .map(([pId, { name, address, checklists }]) => ({
      id: pId,
      name,
      address,
      checklists
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: theme.spacing(8)
      }}
    >
      <Typography component="h2" variant={TV.L} weight={TW.BOLD}>
        Property Checklists
      </Typography>
      <Typography
        style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(2) }}
        variant={TV.BASE}
        weight={TW.REGULAR}
      >
        Add checklists that don't specifically apply to assets.
      </Typography>
      {rows.length ? (
        <WrapTable
          autoHeight
          columns={columns}
          hideFooter
          rows={rows}
          setSelectedPropertyChecklistMap={setSelectedPropertyChecklistMap}
        />
      ) : (
        <NoProperties />
      )}
    </Box>
  );
};

export default PropertyChecklists;
