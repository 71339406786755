import gql from 'graphql-tag';
import orderBy from 'lodash/orderBy';

import useExtendedQuery from 'customHooks/useExtendedQuery';

import { getState } from 'redux/store';
import { logErrorWithCallback } from 'utils';

const GET_FORMS = gql`
  query getFormsAvailableToEntity($partitionKey: String!, $sortKey: String!, $formType: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      forms(entityConnection: "Form", filter: { associatedEntityType: { eq: $formType } }) {
        items {
          id
          name
          description
          viewType
          sortKey
          latestPublishedFormDefinitionSortKey
          latestPublishedFormDefinition(entityConnection: "latestPublishedFormDefinition") {
            id
            createdBy
            formDefinitionJson
            lastUpdatedDateTime
          }
          associatedEntityType
          version
        }
      }
    }
  }
`;

const transformForms = data => {
  const forms = (data?.getCompany?.forms?.items || []).filter(
    form => form.latestPublishedFormDefinition
  );

  return orderBy(forms, form => form.name.toLowerCase());
};

const useCompanyForms = ({ formType }) => {
  const state = getState();
  const { tenantId, tenantCompanyId } = state.user;
  const sortKey = `${tenantId}_Company_${tenantCompanyId}`;

  const { data, loading } = useExtendedQuery(GET_FORMS, {
    variables: { partitionKey: tenantId, sortKey, formType },
    defaultData: [],
    transform: transformForms,
    onError: (err, snackbarOn) => {
      logErrorWithCallback(err, snackbarOn, 'Unable to get forms');
    }
  });

  return [data, loading];
};

export default useCompanyForms;
