import { MUIFormComponentNames } from '@BuildHero/sergeant';

import { sortBy } from 'lodash';

import { generateDefaultFieldsObject } from '@pm/components/formattingUtils';
import { col, row } from 'utils/layout';
import { findById } from 'utils/ramda';

const margin = '0px 15px 21px 0px';

// Productivity Information
const productivityFields = {
  index: { required: false },
  phaseId: { required: true },
  departmentId: { required: true },
  costCodeId: { required: true },
  installedUnits: { required: false },
  unproductiveHours: { required: false }
};

const productivityContents = ({ phaseIds, departments, projectPhases, index }) => {
  const inputOptions = {
    marginRight: 16,
    height: 'auto'
  };

  const phaseDepartments = findById(phaseIds[index])(projectPhases)?.ProjectPhaseDepartment || [];
  const costCodes =
    findById(departments[index])(phaseDepartments)?.ProjectPhaseDepartmentCostCode || [];

  const phaseOptions =
    sortBy(
      projectPhases?.map(phase => ({ value: phase?.id, label: phase?.name })),
      ['label']
    ) || [];

  const departmentOptions =
    sortBy(
      phaseDepartments?.map(department => {
        return {
          value: department?.id,
          label: `${department?.tagName}`
        };
      }),
      ['label']
    ) || [];

  const costCodeOptions =
    sortBy(
      costCodes?.map(costCode => ({
        value: costCode?.id,
        label: costCode?.description
          ? `${costCode?.name} - ${costCode?.description}`
          : costCode?.name
      })),
      ['label']
    ) || [];

  return [
    {
      options: {
        direction: 'row'
      },
      contents: [
        {
          direction: 'column',
          contents: [
            row(
              [
                col(
                  [
                    {
                      options: {
                        label: 'Phase',
                        valueSet: phaseOptions,
                        isRequired: true,
                        placeholder: 'Select Phase'
                      },
                      component: MUIFormComponentNames.SelectInput,
                      source: 'phaseId'
                    }
                  ],
                  { ...inputOptions }
                ),
                col(
                  [
                    {
                      options: {
                        label: 'Department',
                        valueSet: departmentOptions,
                        isRequired: true,
                        disabled: !phaseIds[index],
                        placeholder: 'Select Department'
                      },
                      component: MUIFormComponentNames.SelectInput,
                      source: 'departmentId'
                    }
                  ],
                  inputOptions
                ),
                col(
                  [
                    {
                      options: {
                        label: 'Cost Code',
                        valueSet: costCodeOptions,
                        isRequired: true,
                        disabled: !departments[index],
                        placeholder: 'Select Cost Code'
                      },
                      component: MUIFormComponentNames.SelectInput,
                      source: 'costCodeId'
                    }
                  ],
                  inputOptions
                ),
                col(
                  [
                    {
                      options: {
                        label: 'Installed Units',
                        variant: 'standard',
                        color: 'secondary',
                        type: 'numeric',
                        min: 0
                      },
                      component: MUIFormComponentNames.TextInput,
                      source: 'installedUnits'
                    }
                  ],
                  { ...inputOptions }
                ),
                col(
                  [
                    {
                      options: {
                        label: 'Unproductive Hours',
                        variant: 'standard',
                        color: 'secondary',
                        type: 'numeric',
                        min: 0
                      },
                      component: MUIFormComponentNames.TextInput,
                      source: 'unproductiveHours'
                    }
                  ],
                  inputOptions
                ),
                col(
                  [
                    {
                      component: 'deleteButton'
                    }
                  ],
                  {
                    ...inputOptions,
                    width: 'auto',
                    marginRight: 0,
                    marginTop: 17.5,
                    grow: 0,
                    direction: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start'
                  }
                )
              ],
              { marginBottom: 16 }
            )
          ]
        }
      ]
    }
  ];
};

const productivityLayout = ({ phaseIds, departments, projectPhases, index }) => {
  return {
    fields: generateDefaultFieldsObject(productivityFields),
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          ...productivityContents({
            phaseIds,
            departments,
            projectPhases,
            index
          })
        ]
      },
      edit: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          ...productivityContents({
            phaseIds,
            departments,
            projectPhases,
            index
          })
        ]
      }
    }
  };
};

export { productivityFields, productivityLayout };
