/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import { calculateMarginFromMarkup } from '@BuildHero/math';
import { Button, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { Grid } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { isEmpty } from 'lodash';
import Moment from 'moment';
import { useSelector } from 'react-redux';

import { AddRecordButton } from 'components';
import ImageThumbnail from 'components/ImageThumbnail';
import invoiceLabels from 'meta/Jobs/Invoice/labels';
import PurchasedItemLayout from 'meta/Jobs/Invoice/PurchasedItemForm';
import { billItemsRows } from 'meta/Jobs/Invoice/review-report-tables';
import ErrorBoundaries from 'scenes/Error';
import { getPurchaseOrderTags } from 'services/API/purchaseOrderTag';
import { ReviewReportService } from 'services/core';
import { Logger } from 'services/Logger';
import { roundCurrency } from 'utils';
import { AppConstants, BillLineItemStatus } from 'utils/AppConstants';

import { PricingStrategy } from 'utils/constants';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import { convertForMathLib } from 'utils/mathLibrary';

import ProcurementPurchaseOrderModal from '../../../Procurement/PurchaseOrders/CreatePurchaseOrder';

import BillItemLine from './BillItemLine';
import BillModal from './BillModal';

// Format data to group bill items under bill id in case review report has multiple bill items from the same bill
export function getGroupedBills(context) {
  return Object.values(
    context?.reviewReport?.reviewReportBillItems?.items
      ?.filter(item => item && item.billLine)
      .reduce((acc, b) => {
        const formattedBillLineItems = {
          id: b.id,
          billLineId: b.billLineId,
          markup: b.markup,
          marginValue: convertForMathLib(calculateMarginFromMarkup, b.markup),
          quantity: b.quantity,
          unitCost: b.unitCost,
          unitPrice: b.unitPrice,
          description: b.description,
          itemName: b.billLine.product?.name,
          jobId: b.billLine.jobId,
          invoicedStatus: b.billLine.invoicedStatus,
          taxable: b.taxable,
          remainingQuantity: b.billLine.remainingQuantity,
          costCodeId: b?.costCodeId || '',
          jobCostTypeId: b?.jobCostTypeId || '',
          revenueTypeId: b?.revenueTypeId || ''
        };

        if (b.billLine.billId in acc) {
          acc[b.billLine.billId].billLineItems = [
            ...acc[b.billLine.billId].billLineItems,
            formattedBillLineItems
          ];
          return acc;
        }
        acc[b.billLine.billId] = {
          reviewReportBillItemId: b.id,
          id: b.billLine.billId,
          billNumber: b.billLine.bill.billNumber,
          purchaseOrderReceipt: b.billLine.bill.purchaseOrderReceipt,
          purchaseOrder: b.billLine.bill.purchaseOrder,
          billLineItems: [formattedBillLineItems]
        };
        return acc;
      }, {}) || []
  )
    .sort((a, b) => parseFloat(a.billNumber.split('-')[1]) - parseFloat(b.billNumber.split('-')[1]))
    .sort(
      (a, b) => parseFloat(a.billNumber.split('-')[0]) - parseFloat(b.billNumber.split('-')[0])
    );
}

function addAmountsToBills(billsWithUninvoicedItems) {
  return billsWithUninvoicedItems.map(bill => {
    const editedBills = bill.billLineItems.map(lineItem => {
      const currentBillLineItem = lineItem;
      if (currentBillLineItem && currentBillLineItem.quantity && currentBillLineItem.unitPrice) {
        currentBillLineItem.amount = roundCurrency(
          currentBillLineItem.quantity * currentBillLineItem.unitPrice
        );
      }
      return currentBillLineItem;
    });

    return { ...bill, billLineItems: editedBills };
  });
}

const Bills = ({ current, send, service, user, classes, history, isReviewReport }) => {
  const { context, value } = current;
  const [billsForModal, setBillsForModal] = useState([]);
  const [billsForTable, setBillsForTable] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const marginEnabled = useSelector(s => s.settings.pricingStrategy === PricingStrategy.MARGIN);

  useEffect(() => {
    // Bills already on the reviewReport and that should be displayed in the TABLE
    const groupedBillItems = getGroupedBills(context);
    const billsOnJob = groupedBillItems.filter(
      item =>
        item.id && item.billLineItems.some(lineItem => lineItem.jobId === context?.jobInfoData?.id)
    );
    /* Amount Calculation for Bill Line Items */
    const billsWithAmounts = addAmountsToBills(billsOnJob);
    setBillsForTable(billsWithAmounts);
    async function fetchBillItemsOnJob() {
      try {
        const reviewReportService = new ReviewReportService();
        const reviewResponse = await reviewReportService.getBillLineListForReviewReport(
          'jobId',
          '=',
          context?.jobInfoData?.id,
          context?.visit?.id
        );

        if (reviewResponse) {
          const uninvoicedBillLineItems = reviewResponse.data.getBillLineListForReviewReport.items
            .filter(
              item =>
                item.invoicedStatus === BillLineItemStatus.PARTIALLY_INVOICED ||
                item.invoicedStatus === BillLineItemStatus.NOT_INVOICED
            )
            .map(item => ({
              ...item,
              quantity: Math.round(item.quantity),
              remainingQuantity: Math.round(item.remainingQuantity),
              usedQuantityOnVisit: Math.round(item.usedQuantityOnVisit)
            }));

          setBillsForModal(uninvoicedBillLineItems);
        }
      } catch (error) {
        Logger.error(error);
      }
    }

    getPurchaseOrderTags().then(purchaseOrderTags => {
      setTagOptions(constructSelectOptions(purchaseOrderTags, 'tagName'));
    });

    fetchBillItemsOnJob();
  }, [context?.reviewReport]);

  const PurchasedLayoutMeta = PurchasedItemLayout.entity.layouts.web;
  PurchasedLayoutMeta.buttons.cancel.action = () => send('CLOSE');

  const isEditable = isReviewReport && !context.freezeEdit;

  const billItemsTableMeta = [...billItemsRows(marginEnabled)];
  if (isEditable) {
    billItemsTableMeta.push({
      id: 'actions',
      isCustom: true,
      type: 'actions',
      label: ''
    });
  }

  const open = value?.bill_new || value?.bill_edited || false;
  const title = value.bill_new ? 'Add Bill Item(s)' : 'Edit Bill';

  const startPO = () => {
    const kickoffPO = () => {
      // open the create purchase order modal
      send('ADD_PO_ITEM_PROCUREMENT');
    };
    kickoffPO();
  };

  const openProcurementPurchaseOrderModal = !!value?.po_item_new_procurement;

  const handleCreatePO = po => {
    if (po.id) history.push(`/procurement/purchaseorders/view/${po.id}`);
    send('CLOSE');
  };

  return (
    <>
      <Grid container justify="flex-start" style={{ marginTop: 20 }} />
      {isEditable && <AddRecordButton handle={startPO} label="+ Add purchase order" />}
      <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
        <ThemeProvider>
          <Typography className={classes.sectionDescription}>
            {invoiceLabels.bills[user.locale]}
          </Typography>

          {isEditable && (
            <Button
              startIcon={<AddCircleOutlineIcon />}
              type="leading"
              onClick={() => send('ADD_BILL')}
            >
              Add bill items
            </Button>
          )}
        </ThemeProvider>
        <ErrorBoundaries>
          {billsForTable.map(billItem => (
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              style={{ borderTop: '1px solid #e0e0e4' }}
              xl={12}
              xs={12}
            >
              <ThemeProvider>
                <Grid alignItems="center" container direction="row" justify="center">
                  <Grid item lg={10} md={10} sm={10} xl={11} xs={10}>
                    <Grid
                      container
                      justify="space-around"
                      spacing={4}
                      style={{ marginTop: 20, marginBottom: 10 }}
                    >
                      <Grid item lg={1} md={1} sm={2} xl={1} xs={2}>
                        {billItem.purchaseOrderReceipt?.imageUrl && (
                          <ImageThumbnail
                            image={{
                              fileUrl: billItem.purchaseOrderReceipt?.imageUrl
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item>
                        <Typography style={{ padding: '5px 0' }} variant={TV.S1} weight={TW.BOLD}>
                          Added by
                        </Typography>
                        <Typography variant={TV.BASE}>
                          {billItem.purchaseOrder?.addedBy || billItem.purchaseOrder?.createdBy}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ padding: '5px 0' }} variant={TV.S1} weight={TW.BOLD}>
                          Vendor
                        </Typography>
                        <Typography variant={TV.BASE}>
                          {billItem.purchaseOrder?.vendorName}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ padding: '5px 0' }} variant={TV.S1} weight={TW.BOLD}>
                          Bill No
                        </Typography>
                        <Typography variant={TV.BASE}>{billItem.billNumber}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ padding: '5px 0' }} variant={TV.S1} weight={TW.BOLD}>
                          Receipt No
                        </Typography>
                        <Typography variant={TV.BASE}>
                          {billItem.purchaseOrderReceipt?.receiptNumber}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ padding: '5px 0' }} variant={TV.S1} weight={TW.BOLD}>
                          P.O No
                        </Typography>
                        <Typography variant={TV.BASE}>
                          {billItem.purchaseOrder?.poNumber}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography style={{ padding: '5px 0' }} variant={TV.S1} weight={TW.BOLD}>
                          Date
                        </Typography>
                        <Typography variant={TV.BASE}>
                          {isNaN(billItem.purchaseOrder?.dateOfPurchase)
                            ? Moment(billItem.purchaseOrder?.dateOfPurchase).format(
                                AppConstants.DATE_FORMAT
                              )
                            : Moment.unix(
                                parseInt(billItem.purchaseOrder?.dateOfPurchase, 10)
                              ).format(AppConstants.DATE_FORMAT)}
                        </Typography>
                      </Grid>
                      <Grid item style={{ textAlign: 'center' }}>
                        <Typography style={{ padding: '5px 0' }} variant={TV.S1} weight={TW.BOLD}>
                          Total
                        </Typography>
                        <Typography variant={TV.BASE}>
                          $
                          {billItem.billLineItems
                            .reduce((acc, lineItem) => {
                              return acc + (lineItem?.amount ? Number(lineItem?.amount) : 0);
                            }, 0)
                            .toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ThemeProvider>

              <BillItemLine
                billItem={billItem}
                current={current}
                isReviewReport={isReviewReport}
                marginEnabled={marginEnabled}
                send={send}
                user={user}
              />
            </Grid>
          ))}
        </ErrorBoundaries>
      </Grid>
      {open && (
        <BillModal
          billOptions={billsForModal}
          billsForTable={billsForTable}
          data={context.modalRecord}
          mode="new"
          open={open}
          reviewReportId={context.reportId}
          send={send}
          service={service}
          title={title}
          user={user}
        />
      )}
      {!isEmpty(context.modalRecord) && openProcurementPurchaseOrderModal && (
        <ProcurementPurchaseOrderModal
          handleClose={handleCreatePO}
          initialData={context.modalRecord}
          open={openProcurementPurchaseOrderModal}
          tagOptions={tagOptions}
          user={user}
        />
      )}
    </>
  );
};

export default Bills;
