/* eslint-disable react/no-array-index-key */
import React, { memo } from 'react';

import { areEqual as propsAreEqual } from 'react-window';

import TechCell from '../../../TechCell';

import DayCell from './components/DayCell';
import { useStyles } from './WeekSwimLane.styles';

const WeekSwimLane = ({ data, index, style }) => {
  const { tech = {}, techLoading, eventsLoading, techEvents = [] } = data[index] || {};

  const styles = useStyles();

  return (
    <div css={styles.swimLane} style={style}>
      <TechCell tech={tech} techLoading={techLoading} />
      <div css={styles.daysContainer}>
        {techEvents.map((dayEvents, dayIndex) => (
          <DayCell
            dayEvents={dayEvents}
            dayIndex={dayIndex}
            eventsLoading={techLoading || eventsLoading}
            key={dayIndex}
            laneIndex={index}
            tech={tech}
          />
        ))}
      </div>
    </div>
  );
};

const SwimLaneWrapper = props => {
  // index 0 is virtualized list sticky header offset
  if (props.index === 0) return null;
  return <WeekSwimLane {...props} />;
};

SwimLaneWrapper.propTypes = WeekSwimLane.propTypes;

export default memo(SwimLaneWrapper, propsAreEqual);
