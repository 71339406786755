import React, { useState } from 'react';

import { Box, FormControl, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import AccessTime from '@material-ui/icons/AccessTime';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import AutoComplete from 'components/AutoCompleteDropdown';
import Labels from 'meta/labels';

import styles from './styles';

const TimeStep = props => {
  const { onClickNextStep, userLocale, classes, pageTitle, stepData } = props;
  const [selectedTime, setTime] = useState('');

  const getTimePeriods = () => {
    const times = [];
    let id = 4;
    for (let i = 6; i <= 12; i += 1, id += 1) {
      times.push({
        id,
        name: i === 12 ? '12:00 pm' : `${i}:00 am`
      });
    }

    for (let i = 1; i <= 12; i += 1, id += 1) {
      times.push({
        id,
        name: i === 12 ? '12:00 am' : `${i}:00 pm`
      });
    }
    for (let i = 1; i < 6; i += 1, id += 1) {
      times.push({
        id,
        name: `${i}:00 am`
      });
    }

    const timeList = [];
    times.map(item => timeList.push({ label: item.name, value: item.name }));

    return timeList;
  };

  const timeValue = selectedTime || stepData?.selectedTime;

  return (
    <Box display="flex" flexDirection="column" margin="auto" maxWidth="500px" pr={4}>
      <Typography className={classes.title} variant="h4">
        {pageTitle}
      </Typography>
      <Typography className={classes.inputLabel} variant="body2">
        {Labels.startingTime[userLocale]}
      </Typography>
      <Box alignItems="center" display="flex" flexDirection="row" pr={6}>
        <FormControl className={classes.formControlTS} variant="filled">
          <AutoComplete
            DropdownIndicator={() => <AccessTime className={classes.timeIcon} />}
            handleOnChange={setTime}
            label={Labels.enterTime[userLocale]}
            menuPlacement="bottom"
            name="timeSelection"
            options={getTimePeriods()}
            value={timeValue}
          />
        </FormControl>
        <Button
          className={classes.nextButton}
          variant="containedSecondary"
          onClick={() => onClickNextStep({ selectedTime: timeValue })}
        >
          {Labels.nextStep[userLocale]}
        </Button>
      </Box>
    </Box>
  );
};

TimeStep.propTypes = {
  onClickNextStep: PropTypes.func.isRequired,
  userLocale: PropTypes.string.isRequired
};

export default withStyles(styles)(TimeStep);
