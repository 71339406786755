import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect, useSelector } from 'react-redux';

import { Context, DefaultButton, PageHeader, Tab, Tabs, UserPermission } from 'components';
import CreateEntryButton from 'components/Buttons/CreateEntryButton';
import ResponsiveTable from 'components/ResponsiveTable';
import Labels from 'meta/labels';
import { getPriceBookRows } from 'meta/ListViews';
import { setOpenQuickAddModal, snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import AmplifyService from 'services/AmplifyService';
import { getPriceBooksByCompany } from 'services/API/priceBook';
import { Logger } from 'services/Logger';
import { checkPermission, isTenantSettingEnabled, logErrorWithCallback } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';
import { PricingStrategy, QuickAddModal } from 'utils/constants';

import { getPricebooks, updateCompany } from './gql';

import PricingStrategyOptions from './PricingStrategyOptions';
import styles from './styles';

function PricebookList({
  settings,
  user,
  snackbarOn: snackbar,
  setOpenQuickAddModal: openQuickAddModal
}) {
  const { client } = AmplifyService.appSyncClient();

  const pricebookVersion = useSelector(s => s.company.pricebookVersion);
  const [currentCompany, setCurrentCompany] = useState({});
  const [isLoadingDefault, setIsLoadingDefault] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(() => {
    document.title = 'BuildOps - Pricebooks';
  }, []);

  const queryPricebooksV1 = async (filter, limit, offset, sortField, sortDirection) => {
    const noResponse = { items: [], nextToken: '0' };
    if (!user?.tenantId) return noResponse;
    try {
      const { data } = await client.query({
        query: getPricebooks,
        variables: {
          partitionKey: user.tenantId,
          sortKey: `${user.tenantId}_Company_${user.tenantCompanyId}`,
          filter,
          limit,
          offset,
          sort: { sortField: sortField || 'createdDate', sortDirection: sortDirection || 'desc' }
        }
      });
      const { priceBooksView, __typename, ...company } = data.getCompany;
      setCurrentCompany(company);
      return priceBooksView;
    } catch (error) {
      logErrorWithCallback(error, snackbar, 'Failed to fetch pricebook information');
    }
    return noResponse;
  };

  const queryPricebooks = async (filter, limit, offset, sortField, sortDirection) => {
    const noResponse = { items: [], nextToken: '0' };
    if (!user?.tenantId) return noResponse;

    if (pricebookVersion >= 2) {
      const response = await getPriceBooksByCompany(offset, limit);
      const { priceBooks, totalCount } = response?.data || { priceBooks: [], totalCount: 0 };
      return { items: priceBooks, nextToken: totalCount.toString() };
    }
    return queryPricebooksV1(filter, limit, offset, sortField, sortDirection);
  };

  const closeModal = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const setDefaultPricebook = async id => {
    setIsLoadingDefault(true);
    setCurrentCompany({ ...currentCompany, defaultPriceBookId: id });

    try {
      const { data } = await client.mutate({
        mutation: updateCompany,
        variables: {
          partitionKey: user.tenantId,
          data: {
            ...currentCompany,
            defaultPriceBookId: id
          }
        }
      });
      const { __typename, ...company } = data.updateCompany;
      setCurrentCompany(company);
      Context.setCompanyContext(
        user.tenantId,
        Context.generateCompanyContextSortKey(user),
        () => Logger.debug('Context is refreshed'),
        true
      );
    } catch (error) {
      logErrorWithCallback(
        error,
        snackbar,
        'Unable to set default pricebook, please try again later.'
      );
    } finally {
      setIsLoadingDefault(false);
    }
  };

  const setDefaultButton = ({ record }) => {
    const isAdmin = checkPermission('update', PermissionConstants.MENU_PRICEBOOKS, user);
    return record.id === currentCompany.defaultPriceBookId ? (
      <Typography>Default pricebook</Typography>
    ) : (
      <DefaultButton
        disabled={isLoadingDefault || !isAdmin}
        label="Set as Default"
        variant="outlined"
        onClick={() => setDefaultPricebook(record.id)}
      />
    );
  };

  const renderAddPricebook = () => {
    if (!isTenantSettingEnabled('multiplePricebooks')) return null;
    return (
      <CreateEntryButton
        caslKey={PermissionConstants.MENU_PRICEBOOKS}
        handleAdd={() => openQuickAddModal(QuickAddModal.CREATE_PRICEBOOK, {}, closeModal)}
        key="createPricebook" // only for Tenant Admins, it will return true
        label="Add Pricebook"
      />
    );
  };

  const showMarkupAndMargin = settings.pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN;
  const showMarkup = settings.pricingStrategy === PricingStrategy.MARKUP || showMarkupAndMargin;
  const showMargin = settings.pricingStrategy === PricingStrategy.MARGIN || showMarkupAndMargin;
  return (
    <ErrorBoundaries>
      <UserPermission action={PermissionConstants.MENU_PRICEBOOKS} I="allow">
        <PageHeader pageMapKey="pricebooks" userLocale={user.locale}>
          {renderAddPricebook()}
        </PageHeader>
        <Tabs disableBottomPadding>
          <Tab label={Labels.pricebooks[user.locale]}>
            <Box>
              <ErrorBoundaries>
                <UserPermission I="read">
                  <ResponsiveTable
                    customCellComponents={{ setDefaultButton }}
                    fullScreen
                    key={refreshCounter}
                    noDataMsg="No Pricebooks"
                    refreshData={refreshCounter}
                    rowMetadata={getPriceBookRows(showMargin, showMarkup)}
                    service={queryPricebooks}
                    showToolbars
                    tableName="Pricebooks"
                  />
                </UserPermission>
              </ErrorBoundaries>
            </Box>
          </Tab>
          <Tab label={Labels.settings[user.locale]}>
            <Box>
              <PricingStrategyOptions />
            </Box>
          </Tab>
        </Tabs>
      </UserPermission>
    </ErrorBoundaries>
  );
}

const styledPricebooks = withStyles(styles, { withTheme: true })(PricebookList);

const mapStateToProps = state => ({
  user: state.user,
  settings: state.settings
});

const reduxConnectedPricebooks = connect(mapStateToProps, { snackbarOn, setOpenQuickAddModal })(
  styledPricebooks
);

export default reduxConnectedPricebooks;
