import React from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { selectEndDay } from '@dispatch/Dispatch.selectors';
import { withQueryStringStore } from '@dispatch/Dispatch.store';

import DispatchBoard from './DispatchBoard.component';
import { useFilteredDispatchEventsTuple, useFilteredTechnicians } from './DispatchBoard.hooks';
import { useDispatchEventsByTechIds } from './queries/useDispatchEventsByTechIds';

const mapQueryStringToProps = ({ state }) => {
  const { mapView, weekView, crews, departments, technicians, day, flaggedVisits } = state;

  return {
    mapView: Boolean(mapView),
    weekView: Boolean(weekView),
    flaggedVisits: Boolean(flaggedVisits),
    technicians,
    departments,
    crews,
    endDay: selectEndDay(state.endDay, state.weekView, state.day),
    day
  };
};

const DataProvider = props => {
  const { flaggedVisits } = props;
  const techsResponse = useFilteredTechnicians(props);
  const lazyDispatchEventsTuple = useDispatchEventsByTechIds({});
  const filteredTuple = useFilteredDispatchEventsTuple({ lazyDispatchEventsTuple, flaggedVisits });

  return (
    <DispatchBoard
      {...props}
      lazyDispatchEventsTuple={filteredTuple}
      techsResponse={techsResponse}
    />
  );
};

DataProvider.propTypes = {
  crews: PropTypes.array,
  departments: PropTypes.array,
  technicians: PropTypes.array,
  flaggedVisits: PropTypes.bool
};

DataProvider.defaultProps = {
  crews: [],
  departments: [],
  technicians: [],
  flaggedVisits: false
};

export default compose(withQueryStringStore(mapQueryStringToProps))(DataProvider);
