import React, { Component } from 'react';

import { Dialog, Grid, IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import BackArrow from '@material-ui/icons/ArrowBackIos';
import FrontArrow from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';

import ErrorBoundaries from 'scenes/Error';

import ImageComponent from '../ImageComponent';

/**
 * Renders images in a Fullscreen view. Allows users to skim through using arrow keys & mouse clicks
 * labels are fetched from application level
 * locale of the user is referred from user context
 */

class CustomFullScreen extends Component {
  state = {
    currentIndex: 0 || this.props.index
  };

  incrementIndex = () => {
    const { currentIndex } = this.state;
    this.setState({ currentIndex: currentIndex + 1 }, () => {});
  };

  decrementIndex = () => {
    const { currentIndex } = this.state;
    this.setState({ currentIndex: currentIndex - 1 }, () => {});
  };

  handleKeyPress = event => {
    if (event.key === 'ArrowLeft' && this.state.currentIndex > 0) {
      this.decrementIndex();
    } else if (
      event.key === 'ArrowRight' &&
      this.state.currentIndex < this.props.images.length - 1
    ) {
      this.incrementIndex();
    } else if (event.key === 'Escape') {
      this.props.toggle();
    }
  };

  render() {
    const { currentIndex } = this.state;
    const { images } = this.props;

    return (
      <ErrorBoundaries>
        <Dialog
          fullScreen
          index={currentIndex}
          open={currentIndex > -1}
          PaperProps={{
            style: {
              backgroundColor: 'black',
              boxShadow: 'none'
            }
          }}
          onKeyDown={event => {
            this.handleKeyPress(event);
          }}
        >
          <IconButton
            aria-label="Close"
            style={{ width: 30, right: 0, marginLeft: 10 }}
            onClick={this.props.toggle}
          >
            <CloseIcon style={{ color: 'white', right: 0 }} />
          </IconButton>

          <Grid alignItems="center" container direction="column" justify="center">
            {currentIndex < images.length && currentIndex >= 0 ? (
              <Grid item lg={10} xl={10}>
                <Card>
                  <CardActionArea>
                    <ImageComponent image={images[currentIndex]} />
                  </CardActionArea>
                </Card>
              </Grid>
            ) : null}
          </Grid>
          {currentIndex > 0 ? (
            <IconButton
              style={{ width: 30, top: '50%', left: '5%', position: 'fixed' }}
              onClick={() => {
                this.decrementIndex();
              }}
            >
              <BackArrow style={{ color: 'white' }} />
            </IconButton>
          ) : null}

          {currentIndex < images.length - 1 ? (
            <IconButton
              style={{ width: 30, top: '50%', right: '5%', position: 'fixed' }}
              onClick={() => {
                this.incrementIndex();
              }}
            >
              <FrontArrow style={{ color: 'white' }} />
            </IconButton>
          ) : null}
        </Dialog>
      </ErrorBoundaries>
    );
  }
}

export default CustomFullScreen;
