import { useMemo } from 'react';

import { DeleteOutlined, EditOutlined } from '@material-ui/icons';
import every from 'lodash/every';

import Context from 'components/Context';
import { actionButtonColumn, checkboxColumn, tableCurrencyFormatter } from 'components/WrapTable';

export const useFeesTableColumns = ({
  showEditModal,
  handleDelete,
  tenantId,
  updateLoading: loading,
  rows,
  handleIncludeInInvoiceChange,
  handleIncludeInInvoiceAllChange,
  disabled
}) => {
  return useMemo(
    () => [
      checkboxColumn({
        disabled: disabled || loading,
        onRowToggle: handleIncludeInInvoiceChange,
        onHeaderToggle: handleIncludeInInvoiceAllChange,
        isRowChecked: row => row?.includeInInvoice,
        isHeaderChecked: () => every(rows, row => row?.includeInInvoice)
      }),
      {
        field: 'id',
        hide: true
      },
      {
        field: 'itemName',
        flex: 2,
        headerName: 'Name'
      },
      {
        field: 'reasonForFee',
        flex: 10,
        headerName: 'Reason For Fee'
      },
      {
        field: 'amount',
        flex: 1,
        align: 'right',
        headerName: 'Amount',
        valueFormatter: tableCurrencyFormatter
      },
      actionButtonColumn({
        actions: [
          {
            icon: EditOutlined,
            label: 'Edit',
            onClick: fee => {
              showEditModal(fee);
            },
            disabled
          },
          {
            icon: DeleteOutlined,
            label: 'Delete',
            onClick: fee => {
              handleDelete({ tenantId, fee });
            },
            disabled
          }
        ]
      })
    ],
    [
      showEditModal,
      handleDelete,
      tenantId,
      loading,
      rows,
      handleIncludeInInvoiceChange,
      handleIncludeInInvoiceAllChange,
      disabled
    ]
  );
};

export const useGetProductFilter = ({ job }) =>
  useMemo(() => {
    const productFilter = {
      entityType: ['Product']
    };
    const filters = Object.keys(productFilter || {}).map(key => ({
      attribute: key,
      valueArray: [...productFilter[key]]
    }));
    return filters;
  }, [job]);
