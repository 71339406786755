import React from 'react';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

/**
 * Renders Readonly inputs as per the build hero designs
 */
const LabelWithoutValue = ({ field, fieldProps, form, classes, ...rest }) => {
  // Arrayfiled will not have values in field props
  const fieldName = fieldProps && fieldProps.name ? fieldProps.name : field.name;
  let referenceFieldName;
  if (rest.specialbehaviour && rest.specialbehaviour.fieldName) {
    referenceFieldName = rest.specialbehaviour.fieldName;
  }
  const fieldType = typeof form.values[fieldName];
  let fieldLabel = rest.label;
  if (fieldLabel && fieldLabel.charAt(0) === '*') {
    fieldLabel = fieldLabel.slice(1);
  }

  return (
    <>
      {fieldType && fieldType !== 'object' && (
        <>
          <Typography className={classes.label} variant="caption">
            {fieldLabel}
          </Typography>
        </>
      )}

      {fieldType &&
        fieldType === 'object' &&
        form.values[fieldProps.name].map((data, index) => (
          <Grid
            item
            key={`Grid${data.id || index}`}
            style={referenceFieldName ? { paddingBottom: 4, paddingTop: 0 } : {}}
            xs={12}
          >
            <Typography className={classes.content} key={`data${data.id || index}`}>
              {referenceFieldName ? data[referenceFieldName] : data}
            </Typography>
          </Grid>
        ))}
      {fieldType && fieldType === 'object' && form.values[fieldProps.name].length === 0 && (
        <Grid
          item
          key="Gridempty"
          style={referenceFieldName ? { paddingBottom: 4, paddingTop: 0 } : {}}
          xs={12}
        >
          <Typography key="emptydata">-</Typography>
        </Grid>
      )}
    </>
  );
};
// export default MuiTextInput;
export default withStyles(styles)(LabelWithoutValue);
