export const checklistFormLayout = ({ assetTypeOptions, isAssetEnabled, currentAssetType }) => ({
  fields: {
    checklistName: {
      default: 'checklistName'
    },
    assetTypeId: {
      default: 'assetTypeId'
    },
    isDefaultChecklist: {
      default: 'isDefaultChecklist'
    }
  },
  layouts: {
    default: {
      options: {
        grow: 0
      },
      contents: [
        {
          options: {
            size: 1,
            direction: 'column',
            grow: 1
          },
          contents: [
            {
              options: {
                grow: 1,
                padding: 8
              },
              contents: [
                {
                  component: 'TextInput',
                  source: 'checklistName',
                  options: {
                    label: 'Checklist Name',
                    isRequired: true
                  }
                }
              ]
            },
            isAssetEnabled && {
              options: {
                grow: 1,
                padding: 8
              },
              contents: [
                {
                  source: 'assetTypeId',
                  options: {
                    label: 'Asset Type',
                    fullWidth: true,
                    inputOptions: assetTypeOptions,
                    alignment: 'left',
                    enableSort: false,
                    isRequired: true,
                    disabled: currentAssetType.id === 'null'
                  },
                  component: 'SelectInput'
                }
              ]
            },
            {
              options: {
                grow: 1,
                padding: 8,
                direction: 'row',
                justifyItems: 'space-between'
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    alignItems: 'center'
                  },
                  contents: [
                    {
                      component: 'BodyText',
                      source: 'Set as Default Checklist'
                    },
                    {
                      component: 'TooltipInfo',
                      options: {
                        message:
                          'Default checklists are automatically added to service agreements containing this asset type'
                      }
                    }
                  ]
                },
                {
                  options: {
                    name: 'isDefaultChecklist'
                  },
                  component: 'Switch',
                  source: 'isDefaultChecklist'
                }
              ]
            }
          ].filter(Boolean)
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      checklistName: {
        type: 'string'
      },
      assetTypeId: {
        type: 'string',
        nullable: true
      }
    },
    required: isAssetEnabled ? ['checklistName', 'assetTypeId'] : ['checklistName']
  },
  validationErrors: {
    checklistName: {
      required: 'Field is required.'
    },
    assetTypeId: {
      required: 'Field is required.'
    }
  }
});

export default checklistFormLayout;
