import React, { useCallback, useRef, useState } from 'react';

import { Button, ButtonType, Input } from '@BuildHero/sergeant';
import { ClickAwayListener, InputAdornment } from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';

export default function AutoComplete({
  testingid,
  hits, // autoComplete
  currentRefinement, // autoComplete
  refine, // autoComplete
  defaultRefinement, // autoComplete
  createURL, // autoComplete
  name,
  value,
  formatHitLabel,
  formatHit,
  onClickAway,
  onChange,
  handleRenderOption,
  CustomInfiniteHits,
  disabled,
  required,
  disableClear = false,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const inputRef = useRef();

  const handleClickAway = useCallback(() => {
    setOpen(false);
    refine(value);
    if (onClickAway) onClickAway();
  }, [onClickAway, refine, value]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Input
          autoComplete="off"
          endAdornment={
            currentRefinement &&
            !disabled &&
            !disableClear && (
              <InputAdornment position="end">
                <Button
                  name={name}
                  style={{ padding: 0, backgroundColor: 'transparent', color: 'inherit' }}
                  type={ButtonType.LEADING}
                  onClick={() => {
                    refine();
                    inputRef.current.focus();
                    if (!required && onChange) onChange({});
                  }}
                >
                  <Clear fontSize="small" />
                </Button>
              </InputAdornment>
            )
          }
          name={name}
          placeholder="Search..."
          startAdornment={
            <InputAdornment position="start">
              <Search fontSize="small" />
            </InputAdornment>
          }
          {...props}
          disabled={disabled}
          innerRef={ref}
          inputProps={{
            testingid
          }}
          inputRef={inputRef}
          required={required}
          value={currentRefinement}
          onChange={e => refine(e.currentTarget.value)}
          onFocus={() => !open && setOpen(true)}
        />
        <CustomInfiniteHits
          anchorRef={ref}
          formatHit={formatHit}
          formatHitLabel={formatHitLabel}
          handleRenderOption={handleRenderOption}
          open={open}
          parentRefine={refine}
          setOpen={setOpen}
          onChange={onChange}
        />
      </div>
    </ClickAwayListener>
  );
}
