import { withDispatchStore, withQueryStringStore } from '@dispatch/Dispatch.store';
import compose from 'utils/compose';

import WeekView from './WeekView.component';

const mapQueryStringToProps = store => ({
  startDay: store.state.day,
  filterBy: store.filterBy
});

const mapStoreToProps = store => ({
  clearVisitRowHover: store.clearVisitRowHover
});

export default compose(
  withQueryStringStore(mapQueryStringToProps),
  withDispatchStore(mapStoreToProps)
)(WeekView);
