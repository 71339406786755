import { removeNestedJson } from '../../utils';
import InstructionHelper from '../InstructionHelper';

const roleSchema = (permission, company) => {
  const data = [{ ...removeNestedJson(company) }, { ...permission }];
  const ins = new InstructionHelper();
  const { instructionSet } = ins.oneToMany(0, 1);
  return { data, instructionSet };
};

export default roleSchema;
