import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ButtonType, MultiSelect, TV, TW, Typography } from '@BuildHero/sergeant';
import { Box, useTheme } from '@material-ui/core';
import { isEmpty } from 'lodash';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import uuidV4 from 'uuid/v4';

import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import useServiceAgreement from 'customHooks/useServiceAgreement';
import { useSnackbar } from 'customHooks/useSnackbar';

import { useChecklistLibrary } from 'scenes/ChecklistLibrary/hooks/useChecklistLibrary';
import AssetModal from 'scenes/Customer/PropertyDetail/Assets/AssetModal';
import { assetPayload } from 'scenes/Customer/PropertyDetail/helpers';
import useAdvancedSchedulingServiceAgreementCustomerPropertiesMetadata from 'scenes/ServiceAgreements/DetailView/ServiceAgreementsTabs/hooks';
import { logErrorWithCallback, processAddressArrayAsJson } from 'utils';

import useAddCustomerPropertiesToServiceAgreement from './hooks/useAddCustomerPropertiesToServiceAgreement';
import useAddCustomerPropertyChecklistsToServiceAgreement from './hooks/useAddCustomerPropertyChecklistsToServiceAgreement';
import useAddPropertyAssetsAndChecklistsToServiceAgreement from './hooks/useAddPropertyAssetsAndChecklistsToServiceAgreement';
import useAddPropertyAssetToCustomerProperty from './hooks/useAddPropertyAssetToCustomerProperty';
import useDeleteAllTemplateGroupsOnServiceAgreement from './hooks/useDeleteAllTemplateGroupsOnServiceAgreement';
import useRemoveCustomerPropertiesFromServiceAgreement from './hooks/useRemoveCustomerPropertiesFromServiceAgreement';
import useRemoveCustomerPropertyChecklistsFromServiceAgreement from './hooks/useRemoveCustomerPropertyChecklistsFromServiceAgreement';
import useRemovePropertyAssetsAndChecklistsFromServiceAgreement from './hooks/useRemovePropertyAssetsAndChecklistsFromServiceAgreement';
import useUpdatePropertyAsset from './hooks/useUpdatePropertyAsset';

import NoAssets from './NoAssets';
import PropertyAssets from './PropertyAssets';
import PropertyChecklists from './PropertyChecklists';

const changePropertyConfirm = {
  body:
    'By removing a property, all maintenance information (from step 4) for properties will be erased.',
  title: 'Remove Property',
  buttonLabel: 'Continue',
  buttonType: ButtonType.ERROR
};

const PropertiesAndAssets = ({
  serviceAgreementId,
  triggerSave,
  setSaving,
  setCurrentStepComplete
}) => {
  const theme = useTheme();
  const snackbar = useSnackbar();
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [selectedPropertyAssetChecklistMap, setSelectedPropertyAssetChecklistMap] = useState({});
  const [selectedPropertyChecklistMap, setSelectedPropertyChecklistMap] = useState({});
  const confirmContext = useConfirmModal();

  const allPropertiesHaveAtleastOneChecklist = useMemo(() => {
    if (isEmpty(selectedPropertyChecklistMap) && isEmpty(selectedPropertyAssetChecklistMap))
      return false;

    return selectedProperties.every(p => {
      return (
        selectedPropertyChecklistMap[p.id]?.checklists?.length ||
        !isEmpty(selectedPropertyAssetChecklistMap[p.id])
      );
    });
  }, [selectedProperties, selectedPropertyChecklistMap, selectedPropertyAssetChecklistMap]);

  const [addAssetModal, setAddAssetModal] = useState({ open: false });
  const [editAssetModal, setEditAssetModal] = useState({
    open: false
  });

  const [addPropertyAssetToCustomerProperty] = useAddPropertyAssetToCustomerProperty(
    serviceAgreementId
  );
  const [updateAsset] = useUpdatePropertyAsset();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const user = useSelector(state => state.user);

  const [
    {
      customer: {
        // property options
        customerProperties: { items: customerProperties = [] } = {},
        customerName = ''
      } = {},
      // selectedProperties (with assetType and asset options inside)
      customerProperties: { items: serviceAgreementProperties = [] } = {},
      // selected assets
      propertyAssets: { items: serviceAgreementAssets = [] } = {}
    } = {},
    isLoadingServiceAgreement
  ] = useServiceAgreement({
    serviceAgreementId
  });

  const { data: checklists = [], loading: loadingChecklists } = useChecklistLibrary();

  const checklistsWithTasks = useMemo(() => checklists.filter(c => c.taskTemplates.length), [
    checklists
  ]);

  const assetChecklists = useMemo(
    () => (loadingChecklists ? [] : checklistsWithTasks.filter(l => l.assetTypeId)),
    [checklistsWithTasks, loadingChecklists]
  );

  const propertyChecklists = useMemo(
    () => (loadingChecklists ? [] : checklistsWithTasks.filter(l => !l.assetTypeId)),
    [checklistsWithTasks, loadingChecklists]
  );

  const [deleteAllTemplateGroupsOnServiceAgreement] = useDeleteAllTemplateGroupsOnServiceAgreement({
    serviceAgreementId
  });

  const [
    serviceAgreementPropertyMetadata,
    ,
    refreshServiceAgreementPropertyMetadata
  ] = useAdvancedSchedulingServiceAgreementCustomerPropertiesMetadata({
    serviceAgreementId
  });

  const propertyIdsWithMetadata = useMemo(
    () =>
      serviceAgreementPropertyMetadata?.customerPropertyServiceAgreementsMetadata.map(
        x => x.customerProperty.id
      ) || [],
    [serviceAgreementPropertyMetadata]
  );

  const [
    addCustomerPropertiesToServiceAgreement,
    { loading: addingCustomerPropertiesToServiceAgreement }
  ] = useAddCustomerPropertiesToServiceAgreement({ user, serviceAgreementId });

  const [
    addCustomerPropertyChecklistsToServiceAgreement,
    { loading: addingCustomerPropertyChecklistToServiceAgreement }
  ] = useAddCustomerPropertyChecklistsToServiceAgreement({ user, serviceAgreementId });

  const [
    removeCustomerPropertyChecklistsFromServiceAgreement,
    { loading: removingCustomerPropertyChecklistsFromServiceAgreement }
  ] = useRemoveCustomerPropertyChecklistsFromServiceAgreement({ user, serviceAgreementId });

  const [
    removeCustomerPropertiesFromServiceAgreement,
    { loading: removingCustomerPropertiesFromServiceAgreement }
  ] = useRemoveCustomerPropertiesFromServiceAgreement({ user, serviceAgreementId });

  const [
    addPropertyAssetsAndChecklistsToServiceAgreement,
    { loading: addingPropertyAssetsAndChecklistsToServiceAgreement }
  ] = useAddPropertyAssetsAndChecklistsToServiceAgreement({ user, serviceAgreementId });

  const [
    removePropertyAssetsAndChecklistsFromServiceAgreement,
    { loading: removingPropertyAssetsAndChecklistsFromServiceAgreement }
  ] = useRemovePropertyAssetsAndChecklistsFromServiceAgreement({ user, serviceAgreementId });

  const mapCustomerPropertiesToMultiSelectOptions = useCallback(
    ({ id: customerPropertyId, companyName, address, assets, mergedTaskTemplateChecklists }) => ({
      id: customerPropertyId,
      label: companyName,
      rightLabel: address,
      value: customerPropertyId,
      assets,
      mergedTaskTemplateChecklists,
      onSelectionModelChange: newSelectedAssetChecklistMap => {
        setSelectedPropertyAssetChecklistMap(previousSelectedPropertyAssetChecklistMap => ({
          ...previousSelectedPropertyAssetChecklistMap,
          [customerPropertyId]: newSelectedAssetChecklistMap
        }));
      }
    }),
    []
  );

  const propertyOptions = useMemo(
    () =>
      customerProperties
        ? customerProperties
            .filter(({ isActive }) => isActive)
            .map(
              ({
                id,
                companyName,
                companyAddresses: { items: addressArray },
                propertyAssets: { items: assets }
              }) => ({
                id,
                companyName,
                address: processAddressArrayAsJson(addressArray).propertyAddress || '',
                assets: assets
                  .filter(({ isActive }) => isActive)
                  .map(({ assetType, ...asset }) => {
                    return {
                      assetTypeName: assetType?.tagName || 'Undefined',
                      assetTypeId: assetType?.id,
                      ...asset
                    };
                  })
                  .sort((a, b) => a.assetTypeName.localeCompare(b.assetTypeName)),
                mergedTaskTemplateChecklists: []
              })
            )
            .sort((a, b) => a.companyName.localeCompare(b.companyName))
            .map(mapCustomerPropertiesToMultiSelectOptions)
        : [],
    [customerProperties]
  );

  const assetTypeChecklistMap = useMemo(
    () =>
      checklistsWithTasks.reduce((acc, l) => {
        return {
          ...acc,
          [l.assetTypeId]: [...(acc[l.assetTypeId] ?? []), l]
        };
      }, {}),
    [checklistsWithTasks]
  );

  // prevent user from going forward if they have not selected a property and atleast 1 checklist
  useEffect(() => {
    setCurrentStepComplete(allPropertiesHaveAtleastOneChecklist);
  }, [allPropertiesHaveAtleastOneChecklist]);

  // sets the saving prop
  useEffect(() => {
    setSaving(
      [
        addingCustomerPropertiesToServiceAgreement,
        addingCustomerPropertyChecklistToServiceAgreement,
        addingPropertyAssetsAndChecklistsToServiceAgreement,
        isLoadingServiceAgreement,
        loadingChecklists,
        removingCustomerPropertiesFromServiceAgreement,
        removingCustomerPropertyChecklistsFromServiceAgreement,
        removingPropertyAssetsAndChecklistsFromServiceAgreement
      ].some(x => x)
    );
  }, [
    addingCustomerPropertiesToServiceAgreement,
    addingCustomerPropertyChecklistToServiceAgreement,
    addingPropertyAssetsAndChecklistsToServiceAgreement,
    isLoadingServiceAgreement,
    loadingChecklists,
    removingCustomerPropertiesFromServiceAgreement,
    removingCustomerPropertyChecklistsFromServiceAgreement,
    removingPropertyAssetsAndChecklistsFromServiceAgreement
  ]);

  useEffect(() => {
    if (!isLoadingServiceAgreement) {
      if (serviceAgreementProperties) {
        // initializes the selected properties
        setSelectedProperties(
          serviceAgreementProperties
            .filter(({ isActive }) => isActive)
            .map(
              ({
                id,
                companyName,
                companyAddresses: { items: addressArray },
                propertyAssets: { items: assets },
                mergedTaskTemplateChecklists
              }) => ({
                id,
                companyName,
                address: processAddressArrayAsJson(addressArray).propertyAddress || '',
                assets: assets
                  .filter(({ isActive }) => isActive)
                  .map(({ assetType, ...asset }) => {
                    return {
                      assetTypeName: assetType?.tagName || 'Undefined',
                      assetTypeId: assetType?.id,
                      ...asset
                    };
                  })
                  .sort((a, b) => a.assetTypeName.localeCompare(b.assetTypeName)),
                mergedTaskTemplateChecklists
              })
            )
            .sort((a, b) => a.companyName.localeCompare(b.companyName))
            .map(mapCustomerPropertiesToMultiSelectOptions)
        );
      }
      // initializes the selectedPropertyAssetChecklistMap
      setSelectedPropertyAssetChecklistMap(oldMap => {
        if (!isEmpty(oldMap)) return oldMap;

        return serviceAgreementAssets.reduce((acc, a) => {
          if (!a.mergedTaskTemplateChecklists.length) {
            // this probably should not happen but it does when editing a selected checklist with assets
            // and changing the asset type to one that does not have checklists.
            // TODO figure out how to not have this happen

            return acc;
          }

          if (acc[a.parentId]) {
            return {
              ...acc,
              [a.parentId]: {
                ...acc[a.parentId],
                [a.id]: a.mergedTaskTemplateChecklists.map(l => l.id)
              }
            };
          }

          return {
            ...acc,
            [a.parentId]: { [a.id]: a.mergedTaskTemplateChecklists.map(l => l.id) }
          };
        }, {});
      });
      // initializes the selectedPropertyChecklistMap
      setSelectedPropertyChecklistMap(oldMap => {
        return serviceAgreementProperties.reduce((acc, p) => {
          // don't overwrite existing property checklists
          if (oldMap[p.id]) return { ...acc, [p.id]: oldMap[p.id] };

          const address =
            processAddressArrayAsJson(p.companyAddresses?.items || []).propertyAddress || '';

          return {
            ...acc,
            [p.id]: {
              name: p.companyName,
              address,
              checklists: p.mergedTaskTemplateChecklists.map(l => l.id)
            }
          };
        }, {});
      });
    }
  }, [
    mapCustomerPropertiesToMultiSelectOptions,
    isLoadingServiceAgreement,
    serviceAgreementProperties,
    serviceAgreementAssets
  ]);

  const saveSA = useCallback(
    newSelectedPropertyAssetChecklistMap => {
      const relevantSelectedPropertyAssetChecklistMap =
        newSelectedPropertyAssetChecklistMap || selectedPropertyAssetChecklistMap;

      const serviceAgreementPropertyIds = serviceAgreementProperties.map(({ id }) => id);
      const selectedPropertyIds = selectedProperties.map(({ id }) => id);

      const propertyIdsToAdd = selectedPropertyIds.filter(
        id => !serviceAgreementPropertyIds.includes(id)
      );
      const propertyIdsToRemove = serviceAgreementPropertyIds.filter(
        id => !selectedPropertyIds.includes(id)
      );

      const propertyIdsRelevantToAddingAssetChecklists = [
        ...propertyIdsToAdd,
        ...serviceAgreementPropertyIds.filter(sId => !propertyIdsToRemove.includes(sId))
      ];

      const propertyIdsRelevantToAddingGeneralChecklists = [
        ...propertyIdsToAdd,
        ...serviceAgreementPropertyIds.filter(sId => !propertyIdsToRemove.includes(sId)),
        ...Object.keys(selectedPropertyChecklistMap).filter(
          cId => !selectedPropertyIds.includes(cId)
        )
      ];

      const generalChecklistsToAdd = propertyIdsRelevantToAddingGeneralChecklists
        .reduce((acc, pId) => {
          if (selectedPropertyChecklistMap[pId]) {
            const savedProperty = serviceAgreementProperties?.find(p => p.id === pId);
            if (!savedProperty) {
              return [
                ...acc,
                {
                  customerPropertyId: pId,
                  taskTemplateChecklistIds: selectedPropertyChecklistMap[pId]?.checklists
                }
              ];
            }
            const relevantChecklists = selectedPropertyChecklistMap[pId]?.checklists.filter(
              c => !savedProperty?.mergedTaskTemplateChecklists.map(({ id }) => id).includes(c)
            );
            return [
              ...acc,
              {
                customerPropertyId: pId,
                taskTemplateChecklistIds: relevantChecklists
              }
            ];
          }
          return acc;
        }, [])
        .filter(({ taskTemplateChecklistIds }) => taskTemplateChecklistIds.length > 0);

      const generalChecklistsToRemove = serviceAgreementProperties
        .reduce((acc, { id, mergedTaskTemplateChecklists }) => {
          if (selectedPropertyChecklistMap[id]) {
            const relevantChecklists = mergedTaskTemplateChecklists?.reduce((acc2, { id: cId }) => {
              if (!selectedPropertyChecklistMap[id]?.checklists?.find(clist => clist === cId)) {
                return [...acc2, cId];
              }
              return acc2;
            }, []);
            return [
              ...acc,
              {
                customerPropertyId: id,
                taskTemplateChecklistIds: relevantChecklists
              }
            ];
          }
          return [
            ...acc,
            {
              customerPropertyId: id,
              taskTemplateChecklistIds: mergedTaskTemplateChecklists.map(l => l.id)
            }
          ];
        }, [])
        .filter(({ taskTemplateChecklistIds }) => taskTemplateChecklistIds?.length > 0);

      const assetChecklistsToAdd = propertyIdsRelevantToAddingAssetChecklists.reduce((acc, pId) => {
        if (relevantSelectedPropertyAssetChecklistMap[pId]) {
          const relevantPropertyChecklists = Object.entries(
            relevantSelectedPropertyAssetChecklistMap[pId]
          ).reduce((acc2, [aId, lIds]) => {
            const savedAssetSA = serviceAgreementAssets.find(saA => saA.id === aId);
            if (!savedAssetSA) {
              // asset not yet associated to SA, add every selected checklist for this asset
              return [...acc2, { propertyAssetId: aId, taskTemplateChecklistIds: lIds }];
            }
            // asset already associated to SA, only add selected checklists that don't already appear on the SA
            const missingChecklists = lIds.filter(
              lId => !savedAssetSA.mergedTaskTemplateChecklists.map(c => c.id).includes(lId)
            );
            if (missingChecklists.length) {
              return [
                ...acc2,
                { propertyAssetId: aId, taskTemplateChecklistIds: missingChecklists }
              ];
            }
            return acc2;
          }, []);
          return [...acc, ...relevantPropertyChecklists];
        }

        // property is selected but no assets + checklist combinations have been selected
        return acc;
      }, []);

      const assetChecklistsToRemove = serviceAgreementAssets.reduce((acc, saA) => {
        const selectedAssetLists =
          relevantSelectedPropertyAssetChecklistMap[saA.parentId]?.[saA.id];
        if (!selectedAssetLists) {
          return [
            ...acc,
            {
              propertyAssetId: saA.id,
              taskTemplateChecklistIds: saA.mergedTaskTemplateChecklists.map(l => l.id)
            }
          ];
        }

        const toRemove = saA.mergedTaskTemplateChecklists
          .map(l => l.id)
          .filter(
            l => !relevantSelectedPropertyAssetChecklistMap[saA.parentId][saA.id].includes(l)
          );

        if (toRemove.length) {
          return [
            ...acc,
            {
              propertyAssetId: saA.id,
              taskTemplateChecklistIds: toRemove
            }
          ];
        }
        return acc;
      }, []);

      const nothingToSave =
        !propertyIdsToAdd.length &&
        !propertyIdsToRemove.length &&
        !assetChecklistsToAdd.length &&
        !assetChecklistsToRemove.length &&
        !generalChecklistsToAdd.length &&
        !generalChecklistsToRemove.length;

      if (nothingToSave) {
        snackbar('success', 'Service Agreement Saved');
        setSaving(false);
      } else {
        if (assetChecklistsToAdd.length) {
          addPropertyAssetsAndChecklistsToServiceAgreement({
            assetChecklistsToAdd
          });
        }
        if (assetChecklistsToRemove.length) {
          removePropertyAssetsAndChecklistsFromServiceAgreement({
            assetChecklistsToRemove
          });
        }
        if (propertyIdsToAdd.length) {
          addCustomerPropertiesToServiceAgreement({
            customerPropertyIds: propertyIdsToAdd
          });
        }
        if (propertyIdsToRemove.length) {
          removeCustomerPropertiesFromServiceAgreement({
            customerPropertyIds: propertyIdsToRemove
          });
        }
        if (generalChecklistsToAdd.length) {
          addCustomerPropertyChecklistsToServiceAgreement({
            generalChecklistsToAdd
          });
        }
        if (generalChecklistsToRemove.length) {
          removeCustomerPropertyChecklistsFromServiceAgreement({
            generalChecklistsToRemove
          });
        }
      }
    },
    [
      selectedProperties,
      selectedPropertyAssetChecklistMap,
      serviceAgreementAssets,
      serviceAgreementProperties,
      selectedPropertyChecklistMap
    ]
  );

  const handleAddAsset = async (newData, modalCallback) => {
    const payload = assetPayload({ ...newData, id: newData?.id ?? uuidV4() });
    try {
      // necessary to saveSA if you try add an asset to a property that was not selected on page load.
      // otherwise adding the asset will cause a cache reset that will clear all of the users unsaved work (i.e. newly selected properties and assets)
      // the alternative is to only fire the initialization useEffect on page load, but then we'd have to manually add the
      // newly created asset into the selectedProperties so that the downstream assetMap recalculates correctly
      // and that approach seems more cumbersome and defeats the whole point of using a cache.
      const propertyToAddTo = newData.propertyId;

      await saveSA();
      await addPropertyAssetToCustomerProperty({
        propertyToAddTo,
        payload
      });

      // select the newly created asset if the asset type has checklists
      setSelectedPropertyAssetChecklistMap(oldMap => {
        const fallback = assetTypeChecklistMap[payload.assetTypeId]?.[0]?.id;

        if (!fallback) return oldMap; // assetType with no checklists

        // // need to add this asset to the selected property so that it updates the assetMap downstream
        setSelectedProperties(oldProperties =>
          oldProperties.map(p => {
            if (p.id !== propertyToAddTo) return p;

            return {
              ...p,
              assets: [
                ...p.assets,
                {
                  ...payload,
                  assetTypeName: payload.assetType?.tagName || 'Undefined',
                  assetTypeId: payload.assetType?.id
                }
              ].sort((a, b) => a.assetTypeName.localeCompare(b.assetTypeName))
            };
          })
        );

        const defaultChecklists = assetTypeChecklistMap[payload.assetTypeId]
          .filter(l => l.isDefault)
          .map(l => l.id);

        return {
          ...oldMap,
          [propertyToAddTo]: {
            ...oldMap[propertyToAddTo],
            [payload.id]: defaultChecklists?.length ? defaultChecklists : [fallback]
          }
        };
      });
      setAddAssetModal({ open: false });
    } catch (error) {
      logErrorWithCallback(error, snackbar, `Unable to add Asset, please try again later`);
    } finally {
      modalCallback();
    }
  };

  const handleEditAsset = async (newData, modalCallback) => {
    const payload = assetPayload({ ...newData, id: newData?.id });

    const changedAssetTypeId = newData.assetTypeId !== newData.originalAssetTypeId;
    try {
      // select the newly edited asset if the asset type has checklists
      let newSelectedPropertyAssetChecklistMap;

      if (!selectedPropertyChecklistMap[newData.parentId]?.[newData.id]) {
        setSelectedPropertyAssetChecklistMap(oldMap => {
          const fallback = assetTypeChecklistMap[newData.assetTypeId]?.[0]?.id;
          if (!fallback && !changedAssetTypeId) return oldMap; // assetType with no checklists, do nothing
          if (!fallback && changedAssetTypeId) {
            // new assetType has no checklists, deselect this asset.
            const result = Object.entries(oldMap).reduce((acc, [pId, as]) => {
              if (!as[newData.id]) {
                return {
                  ...acc,
                  [pId]: as
                };
              }
              const newPropertyAssets = Object.entries(as).reduce((acc2, [aId, lIds]) => {
                if (aId === newData.id) return acc2;
                return { ...acc2, [aId]: lIds };
              }, {});

              if (isEmpty(newPropertyAssets)) return acc;

              return {
                ...acc,
                [pId]: newPropertyAssets
              };
            }, {});

            newSelectedPropertyAssetChecklistMap = result;
            return result;
          }

          const defaultChecklists = assetTypeChecklistMap[newData.assetTypeId]
            .filter(l => l.isDefault)
            .map(l => l.id);

          const result = {
            ...oldMap,
            [newData.parentId]: {
              ...oldMap[newData.parentId],
              [newData.id]: defaultChecklists?.length ? defaultChecklists : [fallback]
            }
          };
          newSelectedPropertyAssetChecklistMap = result;

          return result;
        });
      }
      await updateAsset({
        payload
      });
      if (changedAssetTypeId) {
        // need to resave in order to clear junk checklists that are not relevant to the new assetType
        // need to pass in newSelectedPropertyAssetChecklistMap since the one in this instance of saveSA
        //  is stale and won't have the changes done in setSelectedPropertyAssetChecklistMap above
        await saveSA(newSelectedPropertyAssetChecklistMap);
      }

      setEditAssetModal({ open: false });
    } catch (error) {
      logErrorWithCallback(error, snackbar, `Unable to edit Asset, please try again later`);
    } finally {
      modalCallback();
    }
  };

  // saving
  useEffect(() => {
    if (!isFirstLoad) {
      saveSA();
    } else {
      setIsFirstLoad(false);
    }
  }, [triggerSave]);

  if (isLoadingServiceAgreement || loadingChecklists) {
    // @TODO show a loading skeleton while loading.
    return null;
  }

  const propertyChange = newProperties => {
    setSelectedProperties(oldProperties => {
      const missingPropertyIds = oldProperties
        .filter(({ id }) => !newProperties.find(p => p.id === id))
        .map(({ id }) => id);
      if (missingPropertyIds.length > 0) {
        // deselect all of the assets for the deselected property
        setSelectedPropertyAssetChecklistMap(oldMap =>
          Object.entries(oldMap).reduce(
            (acc, [propertyId, assetChecklistMap]) =>
              missingPropertyIds.includes(propertyId)
                ? acc
                : { ...acc, [propertyId]: assetChecklistMap },
            {}
          )
        );
      }
      return newProperties;
    });
    setSelectedPropertyChecklistMap(oldMap => {
      return newProperties.reduce((acc, p) => {
        if (oldMap[p.id]) return { ...acc, [p.id]: oldMap[p.id] };
        const defaultChecklists = propertyChecklists.reduce((acc2, cl) => {
          if (cl.isDefault) {
            return [...acc2, cl.id];
          }
          return acc2;
        }, []);
        return {
          ...acc,
          [p.id]: {
            name: p.label,
            address: p.rightLabel,
            checklists: defaultChecklists
          }
        };
      }, {});
    });
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography component="h2" variant={TV.L} weight={TW.BOLD}>
        Add Properties
      </Typography>
      <Typography
        component="p"
        style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(2) }}
        variant={TV.BASE}
        weight={TW.REGULAR}
      >
        Select which properties will be covered by this service agreement.
      </Typography>
      <MultiSelect
        options={propertyOptions}
        placeholder="Select Properties"
        selectedOptions={selectedProperties}
        showChips
        showSearchIcon
        suggestedOptions={propertyOptions}
        suggestedOptionsLabel="All Properties"
        topLevelSelectAllLabel="Select All Properties"
        onChange={async newProperties => {
          const missingPropertyIds = selectedProperties
            .filter(({ id }) => !newProperties.find(p => p.id === id))
            .map(({ id }) => id);

          if (R.intersection(missingPropertyIds, propertyIdsWithMetadata).length) {
            if (await confirmContext.confirm(changePropertyConfirm)) {
              deleteAllTemplateGroupsOnServiceAgreement();
              propertyChange(newProperties);
            } else {
              setSelectedProperties([...selectedProperties]);
            }

            return;
          }

          propertyChange(newProperties);
        }}
        onClickCreateOption={() => {}}
      />
      <Box style={{ marginTop: theme.spacing(8) }}>
        <Typography component="h2" variant={TV.L} weight={TW.BOLD}>
          Select Assets
        </Typography>
        <Typography
          component="p"
          style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(2) }}
          variant={TV.BASE}
          weight={TW.REGULAR}
        >
          Select the assets covered by this agreement and their corresponding maintenance
          checklists.
        </Typography>
        {selectedProperties.length ? (
          selectedProperties.map(({ id, label, rightLabel, assets, onSelectionModelChange }) => (
            <React.Fragment key={id}>
              <PropertyAssets
                assets={assets}
                assetTypeChecklistMap={assetTypeChecklistMap}
                checklists={assetChecklists}
                deleteAllTemplateGroupsOnServiceAgreement={
                  deleteAllTemplateGroupsOnServiceAgreement
                }
                hasMetadata={propertyIdsWithMetadata.includes(id)}
                id={id}
                label={label}
                refreshServiceAgreementPropertyMetadata={refreshServiceAgreementPropertyMetadata}
                rightLabel={rightLabel}
                selectedAssetChecklistMap={selectedPropertyAssetChecklistMap[id] || []}
                setAddAssetModal={setAddAssetModal}
                setEditAssetModal={setEditAssetModal}
                onSelectionModelChange={onSelectionModelChange}
              />
            </React.Fragment>
          ))
        ) : (
          <NoAssets />
        )}
      </Box>
      <PropertyChecklists
        checklists={propertyChecklists}
        deleteAllTemplateGroupsOnServiceAgreement={deleteAllTemplateGroupsOnServiceAgreement}
        hasMetadata={propertyIdsWithMetadata.length}
        refreshServiceAgreementPropertyMetadata={refreshServiceAgreementPropertyMetadata}
        selectedPropertyChecklistMap={selectedPropertyChecklistMap}
        setSelectedPropertyChecklistMap={setSelectedPropertyChecklistMap}
      />
      <AssetModal
        data={{
          ...addAssetModal
        }}
        handleClose={() => setAddAssetModal({ open: false })}
        handlePrimaryAction={handleAddAsset}
        mode={addAssetModal.mode}
        open={addAssetModal.open}
      />
      <AssetModal
        data={{
          ...editAssetModal.data,
          property: {
            companyName: editAssetModal?.data?.propertyName,
            customer: { customerName }
          },
          originalAssetTypeId: editAssetModal.data?.assetTypeId
        }}
        handleClose={() => setEditAssetModal({ open: false })}
        handlePrimaryAction={handleEditAsset}
        hideServiceAgreements
        mode={editAssetModal.mode}
        open={editAssetModal.open}
      />
    </Box>
  );
};

export default PropertiesAndAssets;
