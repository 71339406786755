/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';

import {
  getCloudinaryImageUrl,
  getCombinedAddressFromProjectData,
  getFinalBillingCustomerAddress
} from 'scenes/ProjectManagement/components/utils';
import { getCustomerRepById } from 'services/API/customerRep';
import { getCombinedAddress } from 'utils';
import { AppConstants } from 'utils/AppConstants';

const getCompanyBusinessAddress = companyInfo =>
  companyInfo.companyAddresses?.items?.find(addr => addr?.addressType === 'shippingAddress');

const formatMultiLineCompanyAddress = address => {
  if (!address) {
    return;
  }
  const { addressLine1, addressLine2, city, state, zipcode } = address;
  const stateAndZipcode = state && zipcode ? `${state} ${zipcode}` : state || zipcode;
  const addressLine3 =
    city && stateAndZipcode ? `${city}, ${stateAndZipcode}` : city || stateAndZipcode;
  return [addressLine1, addressLine2, addressLine3]
    .map(item => item?.trim())
    .filter(item => !!item)
    .join('\n');
};

const formatSinglelineCompanyAddress = address => {
  if (!address) {
    return;
  }
  const { addressLine1, addressLine2, city, state, zipcode } = address;
  return [addressLine1, addressLine2, city, state, zipcode]
    .map(item => item?.trim())
    .filter(item => !!item)
    .join(', ');
};

const getFormattedMultilineCompanyAddress = companyInfo =>
  formatMultiLineCompanyAddress(getCompanyBusinessAddress(companyInfo));

const getFormattedSinglelineCompanyAddress = companyInfo =>
  formatSinglelineCompanyAddress(getCompanyBusinessAddress(companyInfo));

export const useFormatDataForPdf = (changeOrderData, projectData) => {
  const [pdfData, setPdfData] = React.useState();
  const [customerRep, setCustomerRep] = React.useState({});
  const [billingCustomerAddress, setBillingCustomerAddress] = React.useState(null);
  const [readyToCalculate, setReadyToCalculate] = React.useState(false);
  const companyInfo = useSelector(state => state?.company || {});

  React.useEffect(() => {
    getCustomerRepById(changeOrderData?.sendToEmployee?.id).then(customer => {
      setCustomerRep(customer);
      setReadyToCalculate(true);
    });
    setBillingCustomerAddress(getFinalBillingCustomerAddress(projectData?.billingCustomerAddress));
  }, []);

  const types = {
    labor: 'Labor',
    material: 'Parts & Materials',
    other: 'Other',
    overhead: 'Overhead',
    subcontractor: 'Subcontractor',
    equipment: 'Equipment'
  };

  // Calculations:
  // subtotal = quantity * sellPrice
  // taxAmount = (taxPercent (0 if line not taxable) * subtotal) + (subtotal * overheadPercent) + (subtotal * profitPercent)
  // total = subtotal + taxAmount
  const formatLineItemForPdf = line => {
    const formattedLine = {
      profitPercent: line.profitPercent,
      overheadPercent: line.overheadPercent,
      description: line.description,
      quantity: Number(line.quantity),
      sellPrice: Number(line.sellPrice),
      subtotal: Number(line.quantity) * Number(line.sellPrice),
      taxPercent: line.taxPercent ? Number(line.taxPercent) : 0,
      taxable: line.taxable
    };
    const taxAmount =
      line.taxable && formattedLine.taxPercent
        ? Number(formattedLine.taxPercent / 100) * formattedLine.subtotal
        : 0;

    const overheadAmount = formattedLine.subtotal * formattedLine.overheadPercent * 0.01;
    const profitAmount = formattedLine.subtotal * formattedLine.profitPercent * 0.01;
    return {
      ...formattedLine,
      taxAmount: taxAmount + overheadAmount + profitAmount
    };
  };

  const calculate = () => {
    const typesWithFormattedLines = {};
    changeOrderData?.ChangeOrderLineItem?.forEach(line => {
      if (types[line.type]) {
        const formattedLine = formatLineItemForPdf(line);
        const lineTotal = formattedLine.subtotal + formattedLine.taxAmount;
        if (!typesWithFormattedLines[line.type]) {
          typesWithFormattedLines[line.type] = {
            label: types[line.type],
            items: [formattedLine],
            total: lineTotal
          };
        } else {
          typesWithFormattedLines[line.type].items.push(formattedLine);
          typesWithFormattedLines[line.type].total += lineTotal;
        }
      }
    });
    const total = Object.keys(types).reduce((runningTotal, type) => {
      try {
        return (
          runningTotal +
          typesWithFormattedLines[type].items.reduce((typeTotal, line) => {
            return typeTotal + line.subtotal + line.taxAmount;
          }, 0)
        );
      } catch {
        return runningTotal + 0;
      }
    }, 0);

    setPdfData({
      // general info
      senderLogoUrl: getCloudinaryImageUrl(companyInfo.logoUrl || null),
      senderAddress: getFormattedMultilineCompanyAddress(companyInfo) || null,
      senderPhoneNumber: companyInfo.phonePrimary || null,
      packageNumber: changeOrderData?.number
        ? `${projectData.number} - ${changeOrderData?.number}`
        : null,
      date: moment().format(AppConstants.DATE_FORMAT),

      // project info
      projectName: projectData.name || '-',
      projectNumber: projectData.number || '-',
      projectAddress: getCombinedAddressFromProjectData(projectData) || '-',

      // address info
      toName: changeOrderData?.sentTo || '-',
      toEmail: customerRep?.email || changeOrderData?.sendToEmployee?.email || '-',
      toCompany: projectData?.billingCustomer?.customerName || '-',
      toCompanyAddress: getCombinedAddress(billingCustomerAddress) || '-',
      fromName: changeOrderData?.returnTo || changeOrderData?.returnToEmployee?.name || '-',
      fromEmail: changeOrderData?.returnToEmployee?.email || '-',
      fromCompany: companyInfo.companyName || '-',
      fromCompanyAddress: getFormattedSinglelineCompanyAddress(companyInfo) || '-',

      // change order info
      subject: changeOrderData?.subject || 'No subject',
      daysValid: `${changeOrderData?.daysValid} Days` || '0 Days',
      scheduleExtensionRequested: `${changeOrderData?.scheduleExtensionRequested} Days` || '0 Days',
      scopeOfWork: changeOrderData?.scopeOfWork || 'No description',
      typesWithFormattedLines: Object.values(typesWithFormattedLines),
      total
    });
  };

  React.useEffect(() => {
    if (readyToCalculate && changeOrderData) calculate();
  }, [readyToCalculate, changeOrderData]);

  return [pdfData];
};
