import { Timezone } from 'scenes/Procurement/constants';
import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';
import { getLabelFromValues } from 'scenes/ProjectManagement/components/utils';
import { paymentTermSearch } from 'services/API/paymentTerm';
import { VendorApprovalStatus } from 'utils/AppConstants';

const defaultFlexStyle = '0 0 219px';
const marginEdit = '0px 16px 0px 0px';

const generateReceiptModalFormFields = ({ isVendorInvoiceOn }) => ({
  vendorDocumentNumber: { required: false },
  issuedBy: { required: true, nullable: false },
  paymentTerm: { required: true },
  ...(isVendorInvoiceOn && {
    approvalStatus: { required: true, nullable: false }
  })
});

const generateReceiptContents = ({ isVendorInvoiceOn }) => [
  // the 1st row
  {
    options: {
      direction: 'row',
      grow: 0,
      flexWrap: 'nowrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin: marginEdit
        },
        contents: [
          {
            component: {
              edit: 'TextInput'
            },
            source: 'vendorDocumentNumber',
            options: {
              label: 'Vendor Document Number',
              placeholder: 'Enter Vendor Document Number',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin: marginEdit
        },
        contents: [
          {
            component: {
              edit: 'DateInput'
            },
            source: 'issuedBy',
            options: {
              label: 'Issued By',
              placeholder: 'Enter Date',
              fullWidth: false,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true,
              timezone: Timezone.GMT
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin: marginEdit
        },
        contents: [
          {
            component: {
              edit: 'SearchBar'
            },
            source: 'paymentTerm',
            options: {
              resultFormatFunction: paymentTerm => `${paymentTerm.name}`,
              searchFunction: paymentTermSearch,
              searchColumns: ['name'],
              label: 'Payment Terms',
              placeholder: 'Search Payment Terms',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true
            }
          }
        ]
      },
      isVendorInvoiceOn && {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin: marginEdit
        },
        contents: [
          {
            component: {
              edit: 'CurrencyInput'
            },
            source: 'invoiceAmount',
            options: {
              label: 'Invoice Amount',
              placeholder: 'Enter Invoice Amount',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      isVendorInvoiceOn && {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin: marginEdit
        },
        contents: [
          {
            component: {
              edit: 'SelectInput'
            },
            source: 'approvalStatus',
            options: {
              label: 'Approval Status',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              valueSet: getLabelFromValues(VendorApprovalStatus),
              isRequired: true
            }
          }
        ]
      }
    ].filter(Boolean)
  }
];

const generateReceiptModalFormLayout = ({ isVendorInvoiceOn }) => ({
  fields: generateDefaultFieldsObject(generateReceiptModalFormFields({ isVendorInvoiceOn })),
  layouts: {
    edit: {
      options: {
        pageSize: 'LETTER'
      },
      contents: generateReceiptContents({ isVendorInvoiceOn })
    }
  }
});

export { generateReceiptModalFormFields, generateReceiptModalFormLayout };
