import { pick } from 'ramda';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import updatePurchaseOrderReceipt from 'services/core/graphql/review-report/mutations/UpdatePurchaseOrderReceipt';

export const useEditReviewReportPurchaseOrderReceipt = () =>
  useExtendedMutation(updatePurchaseOrderReceipt, {
    serializer: ({ tenantId, purchaseOrderReceipt }) => {
      const data = pick(['id', 'imageUrl', 'fileName', 'version'], purchaseOrderReceipt);
      return {
        variables: {
          partitionKey: tenantId,
          data
        }
      };
    }
  });
