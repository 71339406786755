import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { get } from 'lodash';

import { AccountingApp, EntityType } from 'utils/constants';

const GET_PAYMENTS = gql`
  query getCustomerById($id: String!) {
    getCustomerById(id: $id) {
      transactions: paymentsBilling(
        entityConnection: "Payment"
        includeComputedColumns: true
        filter: {
          computedColumnFilters: { fieldName: "balanceAmountComputed", filterInput: { gt: 0 } }
          booleanFilters: { fieldName: "isActive", filterInput: { ne: false } }
        }
      ) {
        nextToken
        items {
          id
          paymentNumber
          paymentStatus
          exportStatus
          paymentInvoices(entityConnection: "PaymentInvoice") {
            items {
              invoice(entityConnection: "Invoice") {
                id
                invoiceNumber
              }
            }
          }
          paymentType(entityConnection: "PaymentType") {
            name
          }
          paymentAmount
          unappliedAmount: balanceAmountComputed
          adjustedBalance
        }
      }
    }
  }
`;

const GET_INVOICES = gql`
  query getCustomerById($id: String!, $filter: GenericFilterInput) {
    getCustomerById(id: $id) {
      transactions: invoicesBilling(
        entityConnection: "Invoice"
        includeComputedColumns: true
        filter: $filter
      ) {
        nextToken
        items {
          id
          invoiceNumber
          jobNumber
          job(entityConnection: "Job") {
            jobNumber
          }
          customerProperty(entityConnection: "CustomerProperty") {
            id
            companyName
          }
          issuedDate
          dueDate
          totalAmount
          balance: balanceAmountComputed
          adjustedBalance
        }
      }
    }
  }
`;

const defaultInvoiceFilter = {
  stringFilters: [
    { fieldName: 'status', filterInput: { ne: 'void' } },
    { fieldName: 'customerPropertySortKey', filterInput: { is: null } }
  ]
};

const accountingInvoiceFilters = {
  [AccountingApp.INTACCT]: {
    stringFilters: [{ fieldName: 'status', filterInput: { ne: 'void' } }]
  }
};

export const useTransactionQuery = (
  customerId,
  transactionEntity,
  applicationMapRef,
  propertyId,
  accountingApp
) => {
  const [transactions, setTransactions] = useState();
  const [getTransactions, { loading, error, data }] = useLazyQuery(
    transactionEntity === EntityType.PAYMENT ? GET_PAYMENTS : GET_INVOICES,
    {
      variables: {
        id: customerId,
        filter: propertyId
          ? {
              stringFilters: [
                { fieldName: 'status', filterInput: { ne: 'void' } },
                { fieldName: 'customerPropertySortKey', filterInput: { endsWith: propertyId } }
              ]
            }
          : accountingInvoiceFilters[accountingApp] || defaultInvoiceFilter
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  );
  useEffect(() => {
    setTransactions(undefined);
  }, [customerId, propertyId, transactionEntity]);

  useEffect(() => {
    // put the transactions with applications to the top for UX
    const items = get(data, 'getCustomerById.transactions.items');
    if (applicationMapRef.current && items) {
      setTransactions(
        items?.slice()?.sort((a, b) => {
          const aApplied = applicationMapRef.current[a.id];
          const bApplied = applicationMapRef.current[b.id];
          if (aApplied && !bApplied) return -1;
          if (!aApplied && bApplied) return 1;
          return 0;
        })
      );
    }
  }, [data, applicationMapRef]);

  return {
    getTransactions,
    loading,
    error,
    transactions,
    setTransactions
  };
};
