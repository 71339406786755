import { removeNestedJson } from '../../utils';
import InstructionHelper from '../InstructionHelper';

const inactivateEmployeeSchema = employeeDetails => {
  const { parent } = employeeDetails;

  // adding Login record
  const loginDetails = {
    userName: employeeDetails.userName || employeeDetails.email,
    email: employeeDetails.email,
    firstName: employeeDetails.firstName,
    lastName: employeeDetails.lastName,
    parentId: employeeDetails.id,
    tenantId: employeeDetails.tenantId,
    tenantCompanyId: employeeDetails.tenantCompanyId,
    isAdmin: false,
    entityType: 'Login',
    actionType: 'delete',
    isActive: false
  };

  const companyInfo = removeNestedJson(parent);
  const strippedEmployee = removeNestedJson(employeeDetails);
  const data = [{ ...companyInfo }, { ...strippedEmployee }, { ...loginDetails }];
  const ins = new InstructionHelper();
  let temp = ins.oneToMany(0, 1);
  temp = temp.oneToMany(1, 2);
  const { instructionSet } = temp;
  return { data, instructionSet };
};

export default inactivateEmployeeSchema;
