import React from 'react';

import { Divider, Typography } from '@BuildHero/sergeant';
import { number } from 'prop-types';

import { convertToCurrencyString } from 'utils';

import { useStyles } from './InvoiceAmountFooter.styles';

const InvoiceAmountFooter = ({ invoiceAmount }) => {
  const styles = useStyles();
  const amount = convertToCurrencyString(invoiceAmount);
  return (
    <div css={styles.container}>
      <Divider />
      <div css={styles.labelContainer}>
        <Typography />
        <Typography css={styles.label}>Invoice Amount</Typography>
      </div>
      <div css={styles.container}>
        <Typography />
        <Typography>{amount}</Typography>
      </div>
    </div>
  );
};

InvoiceAmountFooter.propTypes = {
  invoiceAmount: number.isRequired
};

export default InvoiceAmountFooter;
