import React, { useEffect, useMemo, useReducer, useState } from 'react';

import {
  calculateMarginFromMarkup,
  calculateMarkup,
  calculateMarkupFromMargin,
  calculateUnitPriceWithMargin,
  calculateUnitPriceWithMarkup,
  roundCurrency
} from '@BuildHero/math';
import {
  CurrencyInput,
  Divider,
  Field,
  FieldType,
  Input,
  NumberInput,
  PercentageInput,
  ThemeProvider,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';
import { Box, Checkbox, Grid, IconButton } from '@material-ui/core';

import { useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { debounce, isEmpty, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import { PopperMenu, SergeantModal } from 'components';
import CloudIcon from 'components/CloudIcon';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';

import { ItemType, SettingConstants } from 'scenes/Quotes/constants';
import { convertStringToFloat } from 'utils';
import { PricingStrategy } from 'utils/constants';
import { convertForMathLib } from 'utils/mathLibrary';

import styles from './TableView.styles';

const ACTION_TYPES = {
  ITEMS: 'STORE_ITEMS',
  CONFIRMATIONMODAL: 'CONFIRMATION_MODAL'
};

const FIELD_TYPES = {
  description: 'description',
  markupValue: 'markupValue',
  marginValue: 'marginValue',
  quantity: 'quantity',
  unitPrice: 'unitPrice',
  unitCost: 'unitCost',
  subtotal: 'subtotal'
};

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grayscale(60),
    fontWeight: 500,
    fontSize: 10,
    paddingBottom: 0,
    paddingLeft: 8,
    border: 'none'
  },
  body: {
    fontSize: 14,
    borderTop: 'none',
    borderBottom: 'none',
    padding: 8
  }
}))(TableCell);

// to give a better user experience - since its wireup with backend mutation, it takes a while to reflect
const CustomCheckBox = ({ onChange, checked = false, isReadOnly, ...props }) => {
  const [value, setValue] = React.useState(checked);
  const handleOnChange = e => {
    if (e.target.checked && !value) {
      setValue(true);
      onChange(e);
    } else {
      setValue(false);
      onChange(e);
    }
  };
  return (
    <Checkbox
      checked={value}
      color="primary"
      disabled={isReadOnly}
      onChange={handleOnChange}
      {...props}
    />
  );
};

const initialState = {
  items: [],
  confirmationModal: {
    isOpen: false,
    message: '',
    productId: ''
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.ITEMS:
      return { ...state, items: action.payload };
    case ACTION_TYPES.CONFIRMATIONMODAL:
      return { ...state, confirmationModal: action.payload };
    default:
      return state;
  }
};

function LineItemTable(props) {
  const {
    data,
    entityName,
    handleDelete,
    handleChange,
    config,
    labels,
    status,
    openEditLaborModal,
    isReadOnly,
    skipMutation
  } = props;
  const classes = styles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const iconStyling = { fontSize: 24, color: theme.palette.grayscale(20) };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [inputCurrentlyUpdating, setInputCurrentlyUpdating] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const pricingStrategy = useSelector(s => s.settings.pricingStrategy);
  const isMarginEnabled =
    pricingStrategy === PricingStrategy.MARGIN ||
    pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN;
  const isMarkupEnabled =
    pricingStrategy === PricingStrategy.MARKUP ||
    pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN;

  // overflow menu handlers
  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setCurrentItem(item);
    setIsOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setCurrentItem(null);
    setIsOpen(false);
  };

  const handleActions = {
    openConfirmationModal: item =>
      dispatch({
        type: ACTION_TYPES.CONFIRMATIONMODAL,
        payload: {
          isOpen: true,
          message: `${item.name} item`,
          productId: item.productId,
          toDelete: item
        }
      }),
    closeConfimationModal: () =>
      dispatch({
        type: ACTION_TYPES.CONFIRMATIONMODAL,
        payload: { isOpen: false, message: ``, productId: '' }
      })
  };

  const rowActionButtons = [
    {
      label: 'Edit',
      icon: 'Edit',
      onClick: () => openEditLaborModal(currentItem)
    },
    {
      label: 'Delete',
      icon: 'Delete',
      onClick: () => handleActions.openConfirmationModal(currentItem)
    }
  ];

  useEffect(() => {
    if (data) {
      dispatch({ type: ACTION_TYPES.ITEMS, payload: [...data] });
    }
  }, [data]);

  const productTotal = item => (item.quantity ? item.quantity * item.unitPrice : 0);
  const materialTotal = state.items.reduce(
    (currentValue, product) => currentValue + productTotal(product),
    0
  );

  const getNewItem = item => {
    const newItem = { ...item };
    [
      FIELD_TYPES.unitCost,
      FIELD_TYPES.unitPrice,
      FIELD_TYPES.markupValue,
      FIELD_TYPES.marginValue,
      FIELD_TYPES.description,
      FIELD_TYPES.quantity
    ].forEach(type => {
      if (`${item.id}-${type}` in inputCurrentlyUpdating) {
        newItem[type] = inputCurrentlyUpdating[`${item.id}-${type}`];
      }
    });
    return newItem;
  };

  const handleNonPriceChange = (value, item, index, field) => {
    if (isEmpty(inputCurrentlyUpdating) && field !== 'isTaxable' && field !== 'taxable') return;
    if (!value && value !== 0 && field === FIELD_TYPES.quantity) return;
    const newItem = getNewItem(item);
    newItem[field] = value;

    const isInfinityMarkup =
      item.markupValue === null && item.unitCost === 0 && typeof item.unitPrice === 'number';
    if (isInfinityMarkup) {
      newItem.marginValue = 100;
      newItem.markupValue = 'Infinity';
    }
    const newSubtotal = productTotal(newItem);
    setInputCurrentlyUpdating(prev => ({
      ...prev,
      [`${item.id}-${FIELD_TYPES.description}`]: newItem.description,
      [`${item.id}-${FIELD_TYPES.quantity}`]: newItem.quantity,
      [`${item.id}-${FIELD_TYPES.subtotal}`]: newSubtotal
    }));
    handleChange(newItem[field], field, newItem, index);
  };

  const handleSavePriceChange = useMemo(
    () =>
      debounce((newItem, field, index) => {
        const includedField = field === FIELD_TYPES.marginValue ? null : field;
        handleChange(newItem[field] || 0, includedField, newItem, index);
      }, 750),
    []
  );

  const handlePriceChange = (value, item, index, field) => {
    const newItem = getNewItem(item);

    if (field === FIELD_TYPES.unitCost) {
      newItem[field] = convertForMathLib(roundCurrency, value);

      if (value === 0) {
        newItem.unitPrice = 0;
      } else {
        const newUnitPrice =
          convertForMathLib(calculateUnitPriceWithMarkup, newItem.unitCost, newItem.markupValue) ||
          0;
        newItem.unitPrice = convertForMathLib(roundCurrency, newUnitPrice);
        newItem.marginValue = calculateMarginFromMarkup(String(item.markupValue));
      }
    }

    if (field === FIELD_TYPES.unitPrice) {
      newItem[field] = convertForMathLib(roundCurrency, value);
      const newMarkup = convertForMathLib(calculateMarkup, newItem.unitCost, newItem.unitPrice);
      newItem.markupValue = newMarkup;
      newItem.marginValue = calculateMarginFromMarkup(String(newMarkup));
    }

    if (field === FIELD_TYPES.marginValue) {
      newItem[field] = value;
      const newMarkup = convertForMathLib(calculateMarkupFromMargin, value);
      newItem.markupValue = newMarkup;
      newItem.unitPrice = convertForMathLib(calculateUnitPriceWithMargin, newItem.unitCost, value);
    }

    if (field === FIELD_TYPES.markupValue) {
      newItem[field] = value;
      newItem.marginValue = calculateMarginFromMarkup(String(value));
      const newUnitPrice =
        convertForMathLib(calculateUnitPriceWithMarkup, newItem.unitCost, newItem.markupValue) || 0;
      newItem.unitPrice = convertForMathLib(roundCurrency, newUnitPrice);
    }
    // Update inputs on state change to avoid lag with BE
    const newSubtotal = productTotal(newItem);
    setInputCurrentlyUpdating(prev => ({
      ...prev,
      [`${item.id}-${FIELD_TYPES.markupValue}`]: newItem.markupValue,
      [`${item.id}-${FIELD_TYPES.marginValue}`]: newItem.marginValue,
      [`${item.id}-${FIELD_TYPES.unitPrice}`]: newItem.unitPrice,
      [`${item.id}-${FIELD_TYPES.unitCost}`]: newItem.unitCost,
      [`${item.id}-${FIELD_TYPES.subtotal}`]: newSubtotal
    }));

    if (skipMutation) {
      handleChange(newItem[field], field, newItem, index);
    } else {
      handleSavePriceChange(newItem, field, index);
    }
  };

  const handleInputChange = (value, field) => {
    setInputCurrentlyUpdating(prev => ({
      ...prev,
      [field]: value
    }));
  };
  const itemsExist = state.items.length > 0;

  const isShowItemStriped =
    (labels.title === ItemType.LABOR &&
      !config?.Tasks?.Time[SettingConstants.SHOW_ITEMIZED_LABOR]) ||
    (labels.title === ItemType.INVENTORY_PART &&
      !config?.Tasks?.Material[SettingConstants.SHOW_ITEMIZED_MATERIAL_ITEMS]);

  const isShowLaborPricingStriped = !config?.Tasks?.Time[
    SettingConstants.SHOW_ITEMIZED_LABOR_PRICING
  ];

  const isShowLaborQuantityStriped = !config?.Tasks?.Time[
    SettingConstants.SHOW_ITEMIZED_LABOR_QUANTITY
  ];

  const isShowMaterialPricingStriped = !config?.Tasks?.Material[
    SettingConstants.SHOW_ITEMIZED_MATERIAL_ITEM_PRICING
  ];

  const isShowMaterialQuantityStriped = !config?.Tasks?.Material[
    SettingConstants.SHOW_ITEMIZED_MATERIAL_QUANTITY
  ];

  const isShowTotalStriped =
    (labels.title === ItemType.LABOR && !config?.Tasks?.Time[SettingConstants.SHOW_LABOR_TOTAL]) ||
    (labels.title === ItemType.INVENTORY_PART &&
      !config?.Tasks?.Material[SettingConstants.SHOW_MATERIAL_TOTAL]);

  const getMarkupValue = item => {
    if (`${item.id}-${FIELD_TYPES.markupValue}` in inputCurrentlyUpdating) {
      return inputCurrentlyUpdating[`${item.id}-${FIELD_TYPES.markupValue}`];
    }
    const markupAmt = item.markupValue ?? item.markup;
    if (markupAmt === null) {
      return 'Infinity';
    }
    return markupAmt;
  };

  const getMarginValue = item => {
    if (`${item.id}-${FIELD_TYPES.marginValue}` in inputCurrentlyUpdating) {
      return inputCurrentlyUpdating[`${item.id}-${FIELD_TYPES.marginValue}`];
    }
    if (item.markupValue === null) {
      return 100;
    }
    return convertForMathLib(calculateMarginFromMarkup, item.markupValue);
  };

  return (
    <>
      <ErrorBoundaries>
        <Divider margin={16} />
        <Grid alignItems="center" container>
          <Typography
            className={itemsExist ? classes.title : classes.titleWPadding}
            component="div"
            id="tableTitle"
            variant={TV.BASE}
            weight={TW.BOLD}
          >
            {labels.title}
          </Typography>
          <Grid alignItems="center" className={classes.cloudIcon} container>
            {itemsExist && status && <CloudIcon status={status} />}
          </Grid>
        </Grid>
        {itemsExist && (
          <Table aria-label="product table" className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <StyledTableCell align="left">{labels.name}</StyledTableCell>
                <StyledTableCell align="left">DESCRIPTION</StyledTableCell>
                {config.showPricing && (
                  <>
                    <StyledTableCell align="left">UNIT COST</StyledTableCell>
                    {isMarkupEnabled && <StyledTableCell align="left">MARKUP</StyledTableCell>}
                    {isMarginEnabled && <StyledTableCell align="left">MARGIN</StyledTableCell>}
                    <StyledTableCell align="left">UNIT PRICE</StyledTableCell>
                    <StyledTableCell align="left">TAXABLE</StyledTableCell>
                  </>
                )}
                <StyledTableCell align="left">QUANTITY</StyledTableCell>
                {config.showPricing && <StyledTableCell align="left">SUBTOTAL</StyledTableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortBy(state.items, 'sortOrder').map((item, index) => {
                // Remove this after BE merged - only use id
                const subtotal = productTotal(item);
                return (
                  <TableRow
                    className={classes.tableRow}
                    key={item.id}
                    style={{ boxSizing: 'content-box' }}
                  >
                    <StyledTableCell align="left" width="297px">
                      <Field
                        striped={isShowItemStriped}
                        value={item.name || 'N/A'}
                        variant={TV.S1}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left" width="297px">
                      <Input
                        className={classes.descriptionInput}
                        id={`${item.id}-${FIELD_TYPES.description}`}
                        striped={isShowItemStriped}
                        value={
                          `${item.id}-${FIELD_TYPES.description}` in inputCurrentlyUpdating
                            ? inputCurrentlyUpdating[`${item.id}-${FIELD_TYPES.description}`]
                            : item.description || 'N/A'
                        }
                        onBlur={e =>
                          handleNonPriceChange(e.target.value, item, index, FIELD_TYPES.description)
                        }
                        onChange={e =>
                          handleInputChange(e.target.value, `${item.id}-${FIELD_TYPES.description}`)
                        }
                      />
                    </StyledTableCell>
                    {config.showPricing && (
                      <>
                        <StyledTableCell width="96px">
                          {labels.title === ItemType.LABOR ? (
                            <Field
                              striped={isShowLaborPricingStriped}
                              type={FieldType.CURRENCY}
                              value={item.unitCost}
                              variant={TV.S1}
                            />
                          ) : (
                            <CurrencyInput
                              id={`${item.id}-${FIELD_TYPES.unitCost}`}
                              inputProps={{ style: { fontSize: 14 } }}
                              striped={isShowMaterialPricingStriped}
                              value={
                                `${item.id}-${FIELD_TYPES.unitCost}` in inputCurrentlyUpdating
                                  ? inputCurrentlyUpdating[`${item.id}-${FIELD_TYPES.unitCost}`]
                                  : item.unitCost || 0
                              }
                              onChange={value => {
                                handlePriceChange(value, item, index, FIELD_TYPES.unitCost);
                              }}
                            />
                          )}
                        </StyledTableCell>
                        {isMarkupEnabled && (
                          <StyledTableCell width="96px">
                            {labels.title === ItemType.LABOR ? (
                              <Field
                                striped={isShowLaborPricingStriped}
                                type={item.markup === null ? FieldType.TEXT : FieldType.PERCENTAGE}
                                value={getMarkupValue(item)}
                                variant={TV.S1}
                              />
                            ) : (
                              <PercentageInput
                                id={`${item.id}-${FIELD_TYPES.markupValue}`}
                                inputProps={{ style: { fontSize: 14 } }}
                                striped={isShowMaterialPricingStriped}
                                value={getMarkupValue(item)}
                                onChange={value =>
                                  handlePriceChange(value, item, index, FIELD_TYPES.markupValue)
                                }
                              />
                            )}
                          </StyledTableCell>
                        )}
                        {isMarginEnabled && (
                          <StyledTableCell width="96px">
                            {labels.title === ItemType.LABOR ? (
                              <Field
                                striped={isShowLaborPricingStriped}
                                type={item.markup === null ? FieldType.TEXT : FieldType.PERCENTAGE}
                                value={getMarginValue(item)}
                                variant={TV.S1}
                              />
                            ) : (
                              <PercentageInput
                                id={`${item.id}-${FIELD_TYPES.marginValue}`}
                                inputProps={{ style: { fontSize: 14 } }}
                                striped={isShowMaterialPricingStriped}
                                value={getMarginValue(item)}
                                onChange={value =>
                                  handlePriceChange(value, item, index, FIELD_TYPES.marginValue)
                                }
                              />
                            )}
                          </StyledTableCell>
                        )}
                        <StyledTableCell width="96px">
                          {labels.title === ItemType.LABOR ? (
                            <Field
                              striped={isShowLaborPricingStriped}
                              type={FieldType.CURRENCY}
                              value={item.unitPrice}
                              variant={TV.S1}
                            />
                          ) : (
                            <CurrencyInput
                              id={`${item.id}-${FIELD_TYPES.unitPrice}`}
                              inputProps={{ style: { fontSize: 14 } }}
                              striped={isShowMaterialPricingStriped}
                              value={
                                `${item.id}-${FIELD_TYPES.unitPrice}` in inputCurrentlyUpdating
                                  ? inputCurrentlyUpdating[`${item.id}-${FIELD_TYPES.unitPrice}`]
                                  : item.unitPrice || 0
                              }
                              onChange={value =>
                                handlePriceChange(value, item, index, FIELD_TYPES.unitPrice)
                              }
                            />
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center" width="44px">
                          <CustomCheckBox
                            checked={item?.taxable || item?.isTaxable || false}
                            isReadOnly={isReadOnly}
                            onChange={e =>
                              handleNonPriceChange(
                                e.target.checked,
                                item,
                                index,
                                labels.title === ItemType.LABOR ? 'isTaxable' : 'taxable'
                              )
                            }
                          />
                        </StyledTableCell>
                      </>
                    )}
                    <StyledTableCell width="96px">
                      {labels.title === ItemType.LABOR ? (
                        <Field
                          striped={isShowLaborQuantityStriped}
                          type={FieldType.NUMBER}
                          value={item.quantity}
                          variant={TV.S1}
                        />
                      ) : (
                        <NumberInput
                          id={`${item.id}-${FIELD_TYPES.quantity}`}
                          inputProps={{ style: { fontSize: 14 } }}
                          striped={isShowMaterialQuantityStriped}
                          value={
                            `${item.id}-${FIELD_TYPES.quantity}` in inputCurrentlyUpdating
                              ? inputCurrentlyUpdating[`${item.id}-${FIELD_TYPES.quantity}`]
                              : item.quantity || 0
                          }
                          onBlur={value =>
                            handleNonPriceChange(value, item, index, FIELD_TYPES.quantity)
                          }
                          onChange={value =>
                            handleInputChange(
                              convertStringToFloat(value),
                              `${item.id}-${FIELD_TYPES.quantity}`
                            )
                          }
                        />
                      )}
                    </StyledTableCell>
                    {config.showPricing && (
                      <StyledTableCell key={subtotal} width="96px">
                        {labels.title === ItemType.LABOR ? (
                          <Field
                            striped={isShowLaborPricingStriped}
                            type={FieldType.CURRENCY}
                            value={item.subtotal}
                            variant={TV.S1}
                          />
                        ) : (
                          <CurrencyInput
                            id={`${item.id}-${FIELD_TYPES.subtotal}`}
                            inputProps={{ style: { fontSize: 14 } }}
                            readOnly
                            striped={isShowMaterialPricingStriped}
                            value={
                              `${item.id}-${FIELD_TYPES.subtotal}` in inputCurrentlyUpdating
                                ? inputCurrentlyUpdating[`${item.id}-${FIELD_TYPES.subtotal}`]
                                : subtotal
                            }
                          />
                        )}
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="left" width="24px">
                      {labels.title === ItemType.LABOR ? (
                        <Box component="div" justify="flex-start">
                          <IconButton
                            disabled={isReadOnly}
                            disableRipple
                            ref={anchorEl}
                            style={{ padding: 0, marginTop: 9, marginLeft: 5, marginRight: 10 }}
                            onClick={event => handleClick(event, item)}
                          >
                            <MoreVertIcon style={iconStyling} />
                          </IconButton>
                          <PopperMenu
                            anchorEl={anchorEl}
                            itemList={rowActionButtons}
                            open={isOpen}
                            onClose={handleClose}
                          />
                        </Box>
                      ) : (
                        <IconButton
                          aria-label="Close"
                          disabled={isReadOnly}
                          style={{ padding: 0 }}
                          onClick={() => handleActions.openConfirmationModal(item)}
                        >
                          <CloseIcon color="primary" style={{ padding: 0 }} />
                        </IconButton>
                      )}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
              {config.showPricing && (
                <ThemeProvider>
                  <TableRow className={classes.tableRow}>
                    <StyledTableCell width="297px" />
                    <StyledTableCell width="297px" />
                    <StyledTableCell width="96px" />
                    {isMarkupEnabled && <StyledTableCell width="96px" />}
                    {isMarginEnabled && <StyledTableCell width="96px" />}
                    <StyledTableCell width="96px" />
                    <StyledTableCell width="44px" />
                    <StyledTableCell width="96px" />
                    <StyledTableCell
                      align="left"
                      key={materialTotal}
                      style={{ padding: '16px 8px' }}
                      width="96px"
                    >
                      <CurrencyInput
                        id="taskTotal"
                        label={labels.total}
                        readOnly
                        striped={isShowTotalStriped}
                        value={materialTotal}
                      />
                    </StyledTableCell>
                  </TableRow>
                </ThemeProvider>
              )}
            </TableBody>
          </Table>
        )}
        <SergeantModal
          dataType={entityName}
          handleClose={handleActions.closeConfimationModal}
          handlePrimaryAction={async (_, onCompleteCallback) => {
            await handleDelete(state.confirmationModal.toDelete, onCompleteCallback);
            handleActions.closeConfimationModal();
          }}
          mode="delete"
          open={state.confirmationModal.isOpen}
        />
      </ErrorBoundaries>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const ReduxConnectedLineItemTable = connect(mapStateToProps, mapDispatcherToProps)(LineItemTable);

export default ReduxConnectedLineItemTable;

LineItemTable.propTypes = {
  data: PropTypes.array,
  entityName: PropTypes.string,
  handleDelete: PropTypes.func,
  handleChange: PropTypes.func,
  config: PropTypes.object,
  labels: PropTypes.object,
  status: PropTypes.string,
  openEditLaborModal: PropTypes.func
};

LineItemTable.defaultProps = {
  data: [],
  entityName: '',
  handleDelete: () => {},
  handleChange: () => {},
  config: {},
  labels: {},
  status: '',
  openEditLaborModal: () => {}
};
