import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Labels from 'meta/labels';

import styles from './styles';

/**
 * Materail UI text input with BuildHero styles
 */
const MutiTextArea = ({ field, form, classes, user, specialbehaviour, theme, ...rest }) => {
  const { skipLabel, specialPlaceholder } = specialbehaviour;

  if (skipLabel) {
    // eslint-disable-next-line no-param-reassign
    delete rest.label;
  }
  if (specialPlaceholder) {
    // eslint-disable-next-line no-param-reassign
    rest.placeholder = Labels[specialPlaceholder][user.locale];
  }

  if (
    rest.specialbehaviour &&
    rest.specialbehaviour.dependentField &&
    form.values[rest.specialbehaviour.dependentField] === 'true'
  ) {
    const dependentValue = form.values[rest.specialbehaviour.dependentValue];
    if (field.value !== dependentValue) {
      form.setFieldValue(field.name, form.values[rest.specialbehaviour.dependentValue], false);
    }
  }
  const helperText =
    form.errors && form.errors[field.name] ? form.errors && form.errors[field.name] : ' ';

  return (
    <TextField
      error={form.errors && form.errors[field.name] && form.errors[field.name] !== ''}
      fullWidth
      helperText={helperText}
      InputProps={{
        classes: {
          underline: classes.cssUnderline,
          root: classes.textField
        }
      }}
      multiline
      rows={
        rest.specialbehaviour && rest.specialbehaviour.numberOfLines
          ? rest.specialbehaviour.numberOfLines
          : '3'
      }
      rowsMax="9"
      variant="filled"
      {...field}
      {...rest}
    />
  );
};
// export default MutiTextArea;
export default withStyles(styles)(MutiTextArea);
