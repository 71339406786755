import React from 'react';

import { TV, TW, Typography } from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import WrapTable from 'components/WrapTable';

const assetColumns = [
  {
    field: 'assetTypeName',
    headerName: 'Asset Type',
    minWidth: 180,
    renderCell: ({ isGroupRow, formattedValue, row }) => {
      if (isGroupRow) return `${formattedValue} (${row?.__groupData?.length})`;
    }
  },
  {
    field: 'assetName',
    headerName: 'Asset Name',
    minWidth: 120
  },
  {
    field: 'make',
    headerName: 'Make',
    minWidth: 110
  },
  {
    field: 'modelNumber',
    headerName: 'Model',
    minWidth: 110
  },
  {
    field: 'serialNo',
    headerName: 'Serial',
    minWidth: 110
  }
];

const PropertyAssets = ({ label, rightLabel, name, address, assets }) => {
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box
        style={{
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'space-between',
          marginTop: 40,
          marginBottom: 4
        }}
      >
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            color="textPrimary"
            component="h3"
            style={{ marginRight: 8 }}
            variant={TV.BASE}
            weight={TW.BOLD}
          >
            {label}
          </Typography>
          <Typography color="textPrimary" component="h3" variant={TV.BASE} weight={TW.REGULAR}>
            {rightLabel}
          </Typography>
        </Box>
      </Box>
      <Box style={{ display: 'flex', paddingBottom: 10 }}>
        <Typography style={{ marginRight: 8 }} variant={TV.BASE} weight={TW.BOLD}>
          {name}
        </Typography>
        <Typography variant={TV.BASE} weight={TW.REGULAR}>
          {address}
        </Typography>
      </Box>
      <WrapTable
        autoHeight
        checkboxSelection
        columns={assetColumns}
        disableSelectionOnClick
        hideFooter
        rowGroupingModel={['assetTypeName']}
        rows={assets}
      />
    </Box>
  );
};

PropertyAssets.propTypes = {
  label: PropTypes.string.isRequired,
  rightLabel: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  assets: PropTypes.array.isRequired
};

PropertyAssets.defaultProps = {};

export default PropertyAssets;
