import React, { useEffect, useMemo, useState } from 'react';

import CustomTabs from '@pm/components/CustomTabs';
import { useCompanyTimezone } from '@pm/components/hooks';

import { UserPermission } from 'components';
import Placeholder from 'components/Placeholder';
import useJob from 'customHooks/useJob';
import JobCloseoutHeader from 'scenes/JobCloseout/JobCloseoutHeader';
import { PermissionConstants, VISIT_REVIEW_STATUS } from 'utils/AppConstants';

import { JobCloseoutTypes, RelevantVisitStatuses } from '../constants';
import OverviewTab from '../OverviewTab';
import { getJobCloseoutType } from '../utils';
import VisitsTab from '../VisitsTab';

const TABS = { OVERVIEW: 0, VISITS: 1 };

const JobCloseoutDetail = props => {
  const { jobid, jobCloseoutType: jobCloseoutTypeFromUrl, id: initialVisitId } =
    props.computedMatch?.params || {};
  const [jobData, jobLoading, jobError, refetchJob] = useJob({ id: jobid });
  const [selectedTab, setSelectedTab] = useState(initialVisitId ? TABS.VISITS : TABS.OVERVIEW);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [specificVisits, setSpecificVisits] = useState([]);
  const [{ data: companyTimezone }] = useCompanyTimezone();

  const title = jobLoading
    ? 'Job Report'
    : `${jobData.jobTypeInternal} Report ${jobData.customIdentifier}`;
  document.title = `BuildOps - ${title}`;

  /*
   When the status is updated, useJob hook is called with the same jobId and will return the same value,
    unless we explicitly call refetch
    
    references: 
      https://www.apollographql.com/docs/react/data/queries/#caching-query-results
      https://www.apollographql.com/docs/react/data/queries/#refetching
  */
  useEffect(() => {
    refetchJob();
  }, []);

  const totalNumVisits = useMemo(
    () => jobData?.visits?.items?.filter(v => RelevantVisitStatuses.includes(v.status)).length,
    [jobData]
  );

  const visits = useMemo(() => {
    if (specificVisits?.length) return specificVisits;

    if (isFirstLoad && initialVisitId) {
      const initialVisit = jobData?.visits?.items?.find(v => v.id === initialVisitId);
      if (initialVisit) {
        setIsFirstLoad(false);
        setSpecificVisits(initialVisit ? [initialVisit] : []);
        return [initialVisit];
      }
    }

    return jobData?.visits?.items?.filter(v => RelevantVisitStatuses.includes(v.status)) || [];
  }, [jobData, selectedTab, specificVisits]);

  useEffect(() => {
    // Required to update the content of memoized visits if a mutation changes
    // the content of a cached visit from inside a child component
    // TODO: specificVisits should just be ids and maybe versions that can get rehydrated
    // every time the parent job data updates
    setSpecificVisits(previousSpecificVisits => {
      if (!previousSpecificVisits.length) return previousSpecificVisits;

      return previousSpecificVisits.map(previousSpecificVisit => {
        const updatedVisit = jobData?.visits?.items.find(
          visit => visit.id === previousSpecificVisit.id
        );
        return updatedVisit ?? previousSpecificVisit;
      });
    });
  }, [jobData?.visits?.items]);

  const allRelevantVisits = useMemo(() => {
    return jobData?.visits?.items?.filter(v => RelevantVisitStatuses.includes(v.status)) || [];
  }, [jobData]);

  const numReviewedVisits = useMemo(() => {
    return allRelevantVisits.filter(v => v.reviewStatus === VISIT_REVIEW_STATUS.REVIEWED).length;
  }, [allRelevantVisits]);

  const jobCloseoutType = useMemo(() => getJobCloseoutType(jobData, jobCloseoutTypeFromUrl), [
    jobData
  ]);

  const visitsLabel = jobLoading
    ? 'Visits'
    : `Visits (${numReviewedVisits} of ${totalNumVisits} Reviewed)`;

  const tabMenus = [
    { tabLabel: 'Overview' },
    {
      tabLabel: visitsLabel,
      disabled: visits.length === 0
    }
  ];

  const handleTabChange = (_event, newValue) => {
    setSelectedTab(newValue);
  };

  if (jobError) return '...Error';

  return (
    <UserPermission action={PermissionConstants.OBJECT_JOB} I="read">
      <JobCloseoutHeader
        jobCloseoutTypeFromUrl={jobCloseoutTypeFromUrl}
        jobData={jobData}
        refetchJob={refetchJob}
        visits={visits}
      />
      {jobLoading && !jobCloseoutType ? (
        <Placeholder />
      ) : (
        <>
          <CustomTabs tabMenus={tabMenus} value={selectedTab} onChange={handleTabChange} />
          <div css={{ paddingLeft: 17 }}>
            {selectedTab === TABS.OVERVIEW && (
              <OverviewTab
                companyTimezone={companyTimezone}
                isLoading={jobLoading}
                jobCloseoutType={jobCloseoutType}
                jobData={jobData}
                refetchJob={refetchJob}
              />
            )}
            {selectedTab === TABS.VISITS && (
              <VisitsTab
                allVisits={allRelevantVisits}
                companyTimezone={companyTimezone}
                isLoading={jobLoading}
                JobCloseoutType={jobCloseoutType}
                jobData={jobData}
                refetchJob={refetchJob}
                setSpecificVisits={setSpecificVisits}
                visits={visits}
              />
            )}
          </div>
        </>
      )}
    </UserPermission>
  );
};

export default JobCloseoutDetail;
