import { useRef } from 'react';

import gql from 'graphql-tag';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ENV from 'configs/env';
import useExtendedMutation from 'customHooks/useExtendedMutation';
import useExtendedQuery from 'customHooks/useExtendedQuery';

const authUrl = ['qa', 'staging', 'prod'].includes(ENV)
  ? 'https://login.servicechannel.com/oauth/authorize'
  : 'https://sb2login.servicechannel.com/oauth/authorize';

const getBOUrl = env => {
  switch (env) {
    case 'local':
      return 'http://localhost:3000';
    case 'dev':
    case 'qa':
    case 'staging':
      return `https://${env}.buildops.com`;
    case 'prod':
      return 'https://live.buildops.com';
    default:
      throw new Error('Invalid environment');
  }
};

const GET_SETTING = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      companySettings(filter: { type: { eq: "SERVICE_CHANNEL" } }) {
        items {
          id
          settings
          version
        }
      }
    }
  }
`;

const ADD_SETTING = gql`
  mutation AddCompanySetting($partitionKey: String!, $data: AddCompanySettingsToCompanyInput!) {
    addCompanySettingsToCompany(partitionKey: $partitionKey, data: $data) {
      id
      type
      version
      isActive
      settings
    }
  }
`;

const UPDATE_SETTING = gql`
  mutation UpdateCompanySetting($partitionKey: String!, $data: UpdateCompanySettingInput!) {
    updateCompanySetting(partitionKey: $partitionKey, data: $data) {
      id
      version
      settings
    }
  }
`;

export const useServiceChannelSetting = () => {
  const company = useSelector(state => state.company);
  const settingEntityData = useRef();
  const { data = { clientId: '', clientSecret: '' }, loading } = useExtendedQuery(GET_SETTING, {
    transform: result => {
      const setting = result.getCompany?.companySettings.items?.[0];
      if (setting) {
        settingEntityData.current = setting;
        return JSON.parse(setting.settings);
      }

      return undefined;
    },
    variables: { partitionKey: company.tenantId, sortKey: company.sortKey },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const localData = useRef();
  const [upsert, { loading: mutationLoading }] = useExtendedMutation(
    settingEntityData.current ? UPDATE_SETTING : ADD_SETTING,
    {
      serializer: settings => {
        localData.current = settings;
        return {
          variables: {
            partitionKey: company.tenantId,
            data: settingEntityData.current
              ? {
                  id: settingEntityData.current.id,
                  version: settingEntityData.current.version,
                  settings: JSON.stringify(settings)
                }
              : {
                  companyId: company.id,
                  companySettings: [{ type: 'SERVICE_CHANNEL', settings: JSON.stringify(settings) }]
                }
          }
        };
      },
      onCompleted: () => {
        // on disconnect, clientId will be undefined
        if (!localData.current?.clientId) return;

        // redirect user
        window.location = `${authUrl}?response_type=code&client_id=${
          localData.current.clientId
        }&redirect_uri=${getBOUrl(ENV)}/serviceChannel`;
      }
    }
  );

  return { data, loading, mutationLoading, upsert };
};

const USE_CODE = gql`
  mutation register($tenantId: String!, $authCode: String!) {
    configureServiceChannel(tenantId: $tenantId, authCode: $authCode)
  }
`;

export const useAuthWithCode = () => {
  const user = useSelector(state => state.user);
  const history = useHistory();

  const [authenticate] = useExtendedMutation(USE_CODE, {
    serializer: authCode => ({
      variables: {
        tenantId: user.tenantId,
        authCode
      }
    }),
    onCompleted: () => {
      history.push('/settings/integrations');
    }
  });
  return authenticate;
};
