import React from 'react';

import { Box, TextField, Typography } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { isEmpty } from 'lodash';

import AutoCompleteDropdown from 'components/AutoCompleteDropdown';

import { getCostTypeLabel, modifyListItem, replaceItem } from './helpers';

import ItemActions from './ItemActions';
import useStyles from './styles';

const CostTypes = ({ items, types, onUpdateCostTypeState, isAllowedToEdit, onUpdateCostType }) => {
  const classes = useStyles();
  const [costTypes, setCostTypes] = React.useState([]);
  React.useEffect(() => {
    setCostTypes(items);
  }, [items]);

  const handleItemEdit = (value, key, index) => {
    const newItems = modifyListItem(costTypes, value, key, index);
    setCostTypes(newItems);
  };

  const handleSave = index => {
    const successCallback = response => {
      const newItems = replaceItem(costTypes, index, response);
      onUpdateCostTypeState(newItems);
    };
    const costCode = costTypes[index];
    onUpdateCostType(costCode, successCallback);
  };

  const handleCancel = index => {
    const existingItem = items[index];
    const newItems = replaceItem(costTypes, index, existingItem);
    setCostTypes(newItems);
  };

  return (
    <Box display="flex" flex={1} flexDirection="column" py={2}>
      {isEmpty(items) && <Typography variant="body2">No Cost Types entered</Typography>}
      {costTypes.map((item, i) => (
        <Box display="flex" key={i}>
          <Box className={classes.itemBorder} display="flex" width={350}>
            {isAllowedToEdit && (
              <TextField
                InputProps={{
                  className: classes.textInput,
                  disableUnderline: true
                }}
                style={{ flex: 2 }}
                value={item.name}
                onChange={e => handleItemEdit(e.target.value, 'name', i)}
              />
            )}
            {!isAllowedToEdit && (
              <Typography
                className={classes.notEditableValue}
                style={{ fontWeight: 'bold' }}
                variant="body1"
              >
                {item.name}
              </Typography>
            )}
            <Box display="flex" flex={1}>
              <Box width="100%">
                {!isAllowedToEdit && (
                  <Typography className={classes.notEditableValue} variant="body1">
                    {getCostTypeLabel(types, item.type)}
                  </Typography>
                )}
                {isAllowedToEdit && (
                  <AutoCompleteDropdown
                    DropdownIndicator={() => <ExpandMore />}
                    handleOnChange={option => handleItemEdit(option, 'type', i)}
                    inputProps={{
                      textFieldClassName: classes.selectionPlaceholder,
                      inputClassNames: classes.selectionInput,
                      className: classes.selectionWrapper,
                      disableUnderline: true
                    }}
                    options={types}
                    value={item.type}
                  />
                )}
              </Box>
            </Box>
          </Box>
          <ItemActions
            handleCancel={handleCancel}
            handleSave={handleSave}
            index={i}
            isVisible={item.isEdited}
          />
        </Box>
      ))}
    </Box>
  );
};

export default CostTypes;
