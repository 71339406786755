/* eslint-disable camelcase */
import React from 'react';

import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { AddRecordButton, Modal, ResponsiveTable } from 'components';
import { attachmentsRows } from 'meta/Jobs/Invoice/review-report-tables';

import { validations } from 'services/core';
import { capitalizeFirstLetter } from 'utils';

import { useSetStopLoading } from '../ReviewReport/ReviewReport.hook';

import AttachmentModal from './AddAttachment';
import RowActions from './ReviewReportRowActions';

const PhotosAndVideos = ({ classes, current, send, service, isReviewReport }) => {
  const { context, value } = current;
  const setStopLoading = useSetStopLoading(service);
  const attachments = (context.attachments || []).map(attachment => {
    const localAttachment = attachment;
    if (localAttachment.type) {
      localAttachment.displayType = capitalizeFirstLetter(localAttachment.type);
    }
    if (!attachment.addedBy) {
      localAttachment.addedBy = attachment.createdBy;
    }
    return localAttachment;
  });

  const attachmentMeta = [...attachmentsRows];

  if (isReviewReport) {
    attachmentMeta.push({
      id: 'actions',
      isCustom: true,
      type: 'actions',
      label: ''
    });
  }

  const isEditable = isReviewReport && !context.freezeEdit;
  const AttachmentActions = ({ record }) => {
    return (
      <RowActions
        checkboxAction={event =>
          send('INCLUDE_ATTACHMENT_INVOICE', {
            data: record,
            checked: event.target.checked
          })
        }
        deleteAction={() => send('DELETE_ATTACHMENT', { data: record })}
        editAction={() => send('EDIT_ATTACHMENT', { data: record })}
        isEditable={isEditable}
        record={record}
      />
    );
  };

  const open = value?.attachment_new || value?.attachment_edited || false;

  return (
    <>
      <Typography className={classes.sectionTitle}>Photos and Videos</Typography>
      <Divider classes={{ root: classes.blueDivider }} variant="fullWidth" />
      {isEditable && (
        <AddRecordButton handle={() => send('ADD_ATTACHMENT')} label="+ Add photo or video" />
      )}
      <ResponsiveTable
        customCellComponents={{ actions: AttachmentActions }}
        data={attachments}
        disableFilter
        disablePagination
        maxHeight="auto"
        noDataMsg="No attachment"
        noMaxHeight
        rowMetadata={attachmentMeta}
      />
      {open && (
        <AttachmentModal
          data={value.attachment_new ? null : context.modalRecord}
          handleClose={() => send('CLOSE')}
          mode={value.attachment_new ? 'new' : 'edit'}
          open={open}
          parent={context.parentData}
          setStopLoading={setStopLoading}
          source={isReviewReport ? 'ReviewReport' : ''}
          title={value.attachment_new ? 'Add Photo or Video' : 'Edit Photo or Video'}
          validationSchema={validations.reviewReportAttachementSchema}
          onComplete={values => send('SAVE', { saveData: values })}
        />
      )}
    </>
  );
};

export default PhotosAndVideos;
