/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import PermissionSwitch from '@pm/ProjectDetails/ProjectSettings/Users/PermissionSwitch';
import ProfileCard from '@pm/ProjectDetails/ProjectSettings/Users/ProfileCard';

const getTableColumn = ({ handleSwitchToggle }) => {
  return [
    {
      field: 'profileData',
      headerName: 'Name',
      width: 400,
      renderCell: ({ value }) => <ProfileCard record={value ?? {}} />
    },
    {
      field: 'switchData',
      headerName: 'Permission',
      width: 150,
      align: 'center',
      renderCell: ({ value }) => (
        <PermissionSwitch record={value ?? {}} onSwitchToggle={handleSwitchToggle} />
      )
    }
  ];
};

export default getTableColumn;
