import React from 'react';

import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';

import { AlgoliaSearch } from 'components';

import { bundleIndex as defaultProductIndex } from 'constants/algoliaIndex';

export default function SearchBundle({ updateBundle, bundle }) {
  const theme = useTheme();
  const handleSearchChange = value => updateBundle(value);

  return (
    <Grid container style={{ paddingTop: '1rem' }}>
      <AlgoliaSearch
        delimiter=" - "
        displayText={['name', 'description']}
        filters={[
          {
            attribute: 'entityType',
            valueArray: ['ProductBundle']
          }
        ]}
        handleChange={handleSearchChange}
        locatedInPopUp
        name="selectBundle"
        overrideVariant="outlined"
        placeholder="Search Bundle"
        searchIndex={defaultProductIndex}
        style={{
          color: theme.palette.grayscale(90)
        }}
        value={bundle.name || ''}
      />
    </Grid>
  );
}

SearchBundle.propTypes = {
  updateBundle: PropTypes.func.isRequired,
  bundle: PropTypes.object
};

SearchBundle.defaultProps = {
  bundle: {}
};
