import { useSubscription } from '@apollo/client';
import gql from 'graphql-tag';

import { visitDetailsFragment } from '@dispatch/fragments';
import { getState } from 'redux/store';

export const VISIT_BULK_SUBSCRIPTION = gql`
    subscription visitBulkUpdateNotification($partitionKey: String!) {
        visitBulkUpdateNotification(partitionKey: $partitionKey) {
            items {
                ${visitDetailsFragment}
            }
        }
    }
`;

export const useVisitBulkUpdateSubscription = onSubscriptionData => {
  const { tenantId } = getState().user;

  return useSubscription(VISIT_BULK_SUBSCRIPTION, {
    variables: { partitionKey: tenantId },
    onSubscriptionData: ({ subscriptionData }) =>
      onSubscriptionData(subscriptionData.data.visitBulkUpdateNotification.items)
  });
};
