import React, { useMemo } from 'react';

import { Box } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';

import { XGrid } from 'components';
import { GET_INVOICES, invoiceColumns } from 'scenes/Invoices/InvoiceList';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

export const InvoiceTableForCustomer = ({ customerData }) => {
  const flags = useFlags();
  const [xgridPageSize, setXgridPageSize] = React.useState(5);
  const tenantSettings = useSelector(s => s.settings);
  const { accountingApp } = tenantSettings;
  const accountingIntegrationEnabled = !!accountingApp;
  const isAdjustmentEnabled = flags[FeatureFlags.ADJUSTMENTS];

  // as project is not needed in customer table disabling it
  const columns = useMemo(() => invoiceColumns(accountingIntegrationEnabled, isAdjustmentEnabled), [
    accountingIntegrationEnabled,
    isAdjustmentEnabled
  ]);

  const silentFilter = useMemo(
    () => [
      {
        columnField: 'billingCustomerId',
        operatorValue: '=',
        value: customerData?.id
      }
    ],
    [customerData?.id]
  );

  return (
    <Box height={300}>
      <XGrid
        columns={columns}
        disableToolbar
        entityTypeName="Invoices"
        key={`${isAdjustmentEnabled}`}
        pageSize={xgridPageSize}
        pagination
        query={GET_INVOICES}
        rowHeight={38}
        rowsPerPageOptions={[5, 10, 20]}
        silentFilter={silentFilter}
        onPageSizeChange={newPageSize => setXgridPageSize(newPageSize)}
      />
    </Box>
  );
};

export default InvoiceTableForCustomer;
