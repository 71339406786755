import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { checkPermission } from 'utils';

export const UserPermission = props => {
  const { scope, featureGate, I, action, children, featureFlag } = props;
  const user = useSelector(s => s.user);
  const launchDarklyFlags = useFlags();

  const permission = checkPermission(
    I,
    action,
    user,
    scope,
    featureGate,
    featureFlag,
    launchDarklyFlags
  );
  if (permission) {
    return <>{children}</>;
  }
  return null;
};

UserPermission.propTypes = {
  // I - goes along with casl library - to more like english <AppPermission I=read action=departments />
  I: PropTypes.oneOf([
    'read',
    'view',
    'new',
    'add',
    'create',
    'update',
    'edit',
    'delete',
    'allow',
    'manage'
  ]).isRequired,
  // action - it has to permission name as defiend in backend. All the permission names are maintained in PermissionConstants
  // action can be object as used in menus to include the tenantIds for restricting system admin menus
  action: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  // CASL library allows to give all permissions for select conditions
  // for e.g.: tenantAdmin can access all but scope is limited to the particular tenant
  scope: PropTypes.string,
  // @deprecated - Tenant settings key should be passed. All new tenant settings should go to metabase
  featureGate: PropTypes.string,
  // Flag name in the metabase
  featureFlag: PropTypes.string
  // inject from redux. Flags retrieved in app.js and dispatched to redux
};

UserPermission.defaultProps = {
  scope: null,
  featureGate: null,
  featureFlag: null
};

export default UserPermission;
