import { useMemo } from 'react';

import { MUIFormComponentNames } from '@BuildHero/sergeant';
import { useTheme } from '@material-ui/core';

import { Modes } from '@dispatch/components/Drawer/components/NonBillableEventForm/NonBillableEventForm.constants';

import { row } from 'utils/layout';

import { getFormValidation, getFormValidationErrors } from '../../helpers/NonVisitEvents.helpers';
import {
  getCreateMultipleEventsSwitch,
  getDateTimeField,
  getDurationField,
  getEndDateField,
  getExcludeWeekendsField,
  getStartDateTimeField
} from '../../helpers/NonVisitEventsFormFields';

export const useNonBillableEventFormConfig = ({
  allowMultipleEventsCreation,
  disabled,
  disablePayrollHourType,
  departments = [],
  departmentsLoading,
  enableDepartment,
  payrollHourTypesLoading,
  payrollHourTypes = [],
  mode,
  multipleEventsCreationEnabled,
  onCreateMultipleEventsChange
}) => {
  const theme = useTheme();
  return useMemo(() => {
    const validation = getFormValidation(multipleEventsCreationEnabled);
    const validationErrors = getFormValidationErrors();

    const rowOptions = {
      marginBottom: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column'
    };

    const EVENT_NAME_FIELD = [
      {
        component: MUIFormComponentNames.TextInput,
        source: 'eventName',
        options: {
          isRequired: true,
          disabled,
          label: 'EVENT NAME'
        }
      }
    ];

    const DESCRIPTION_FIELD = [
      {
        component: MUIFormComponentNames.TextInput,
        source: 'description',
        options: {
          disabled,
          label: 'DESCRIPTION',
          lines: 4,
          maxTextLen: 80
        }
      }
    ];

    const DETAILS_SECTION_CONTENT = [
      row(EVENT_NAME_FIELD, rowOptions),
      row(DESCRIPTION_FIELD, rowOptions)
    ];

    if (enableDepartment) {
      const departmentField = 'departmentId';
      const DEPARTMENT_FIELD = [
        {
          component: MUIFormComponentNames.SelectInput,
          source: departmentField,
          options: {
            isRequired: true,
            disabled: disabled || departmentsLoading,
            label: 'DEPARTMENT',
            placeholder: '-',
            valueSet: departments.map(({ id, name }) => ({ value: id, label: name })),
            isSearchable: true,
            enableSort: false
          }
        }
      ];

      DETAILS_SECTION_CONTENT.push(row(DEPARTMENT_FIELD, rowOptions));

      validation.properties[departmentField] = {
        type: 'string',
        nullable: false
      };
      validation.required.push(departmentField);
      validationErrors[departmentField] = {
        required: 'Department is required',
        type: 'Value must be a string'
      };
    }

    const PAYROLL_TYPES_FIELD = [
      {
        component: MUIFormComponentNames.SelectInput,
        source: 'payrollHourTypeId',
        options: {
          disabled:
            disabled || payrollHourTypesLoading || (mode === Modes.EDIT && disablePayrollHourType),
          label: 'PAYROLL HOUR TYPE',
          placeholder: '-',
          valueSet: payrollHourTypes.map(({ id, hourType }) => ({ value: id, label: hourType })),
          isSearchable: true,
          enableSort: false
        }
      }
    ];

    DETAILS_SECTION_CONTENT.push(row(PAYROLL_TYPES_FIELD, rowOptions));

    const durationField = getDurationField({ disabled });
    const excludeWeekendsField = getExcludeWeekendsField();
    const createMultipleEventsSwitch = getCreateMultipleEventsSwitch({
      allowMultipleEventsCreation,
      disabled,
      multipleEventsCreationEnabled,
      onCreateMultipleEventsChange,
      label: 'Create Multiple Non-Billable Events'
    });
    const startDateTimeField = getStartDateTimeField({ disabled });
    const dateTimeField = getDateTimeField({ disabled });
    const endDateField = getEndDateField({ disabled });

    const SCHEDULING_SECTION_CONTENT = multipleEventsCreationEnabled
      ? [
          row(createMultipleEventsSwitch, {
            ...rowOptions,
            marginBottom: theme.spacing(3)
          }),
          row(startDateTimeField, rowOptions),
          row(endDateField, rowOptions),
          row(excludeWeekendsField, rowOptions),
          row(durationField, rowOptions)
        ]
      : [
          row(createMultipleEventsSwitch, {
            ...rowOptions,
            marginBottom: theme.spacing(3)
          }),
          row(dateTimeField, rowOptions),
          row(durationField, rowOptions)
        ];

    return {
      fields: {},
      layouts: {
        default: {
          contents: [
            {
              component: 'AccordionSection',
              options: {
                heading: 'Details',
                expanded: true
              },
              contents: DETAILS_SECTION_CONTENT
            },
            {
              component: 'AccordionSection',
              options: {
                heading: 'Scheduling',
                expanded: true
              },
              contents: SCHEDULING_SECTION_CONTENT
            }
          ]
        }
      },
      validation,
      validationErrors
    };
  }, [
    allowMultipleEventsCreation,
    disabled,
    disablePayrollHourType,
    enableDepartment,
    departments,
    departmentsLoading,
    payrollHourTypes,
    payrollHourTypesLoading,
    theme,
    mode,
    multipleEventsCreationEnabled,
    onCreateMultipleEventsChange
  ]);
};
