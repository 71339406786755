import { TW } from '@BuildHero/sergeant';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  addImageButton: {
    marginTop: theme.spacing(1.5)
  },
  muiIcon: {
    color: theme.palette.common.white
  },
  fileName: {
    weight: TW.MEDIUM,
    color: theme.palette.common.white
  },
  navContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  leftNavContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(7.5),
    alignItems: 'center'
  },
  rightNavContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: theme.spacing(7.5)
  },
  paperProps: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden'
  },
  paperPropsPDF: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
    height: '100%',
    width: '100%'
  },
  imagePreviewStyle: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '2px',
    cursor: 'auto'
  }
}));

export default useStyles;
