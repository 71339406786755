import React, { memo, useRef } from 'react';

import useCompleteNonVisitEvent from '@dispatch/mutations/useCompleteNonVisitEvent';

import NonVisitStatusHeader from './NonVisitStatusHeader.component';

const DataProvider = props => {
  const Memoized = useRef(memo(NonVisitStatusHeader)).current;
  // eslint-disable-next-line react/prop-types
  const completeBillableNonVisitTuple = useCompleteNonVisitEvent();

  return <Memoized {...props} completeBillableNonVisitTuple={completeBillableNonVisitTuple} />;
};

export default DataProvider;
