import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { getServiceAgreementById } from 'customHooks/useServiceAgreement';
import mergeDeep from 'utils/mergeDeep';

const removeCustomerPropertyChecklistsFromServiceAgreement = gql`
  mutation removeCustomerPropertyChecklistsFromServiceAgreement(
    $serviceAgreementId: String!
    $input: [CustomerPropertyTaskTemplateChecklistsInput!]!
  ) {
    removeCustomerPropertyChecklistsFromServiceAgreement(
      serviceAgreementId: $serviceAgreementId
      input: $input
    ) {
      id
      relatedIds
    }
  }
`;

const useRemoveCustomerPropertyChecklistsToServiceAgreement = ({ user, serviceAgreementId }) => {
  return useExtendedMutation(removeCustomerPropertyChecklistsFromServiceAgreement, {
    serializer: ({ generalChecklistsToRemove }) => ({
      variables: {
        serviceAgreementId,
        input: generalChecklistsToRemove
      }
    }),
    onSuccess: (data, snackbar) => {
      snackbar('success', 'Service Agreement Saved');
    },
    onError: (error, snackbar) => {
      snackbar('error', 'Unable to Save Service Agreement');
    },
    skip: !serviceAgreementId,
    update: (
      cache,
      { data: { removeCustomerPropertyChecklistsFromServiceAgreement: removedPropertyChecklists } }
    ) => {
      const query = {
        query: getServiceAgreementById,
        variables: {
          partitionKey: user.tenantId,
          id: serviceAgreementId
        }
      };
      const cachedData = cache.readQuery(query);
      const newItems = cachedData.serviceAgreement.customerProperties.items.map(p => {
        const relevantProperty = removedPropertyChecklists?.find(({ id }) => id === p.id);
        if (!relevantProperty) return p;

        return {
          ...p,
          mergedTaskTemplateChecklists: p.mergedTaskTemplateChecklists.filter(
            l => !relevantProperty.relatedIds.includes(l.id)
          )
        };
      });

      const update = { serviceAgreement: { customerProperties: { items: newItems } } };

      const result = mergeDeep(cachedData, update);

      cache.writeQuery({ ...query, data: result });
    }
  });
};

export default useRemoveCustomerPropertyChecklistsToServiceAgreement;
