import React from 'react';

import ProductSearch from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';

export const ItemSearch = props => {
  const { options } = props;
  const onChange = item => {
    if (item?.id) {
      options.addItem({
        productId: item.id,
        quantity: null,
        name: item.name,
        description: item.description
      });
    }
  };

  return <ProductSearch {...props} options={{ ...options, onChange }} />;
};
