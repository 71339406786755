import { withQueryStringStore } from '@dispatch/Dispatch.store';

import FlaggedVisitSelector from './FlaggedVisitSelector.component';

const mapQueryStringToProps = store => ({
  setFlaggedVisits: store.setFlaggedVisits,
  flaggedVisits: store.state.flaggedVisits
});

export default withQueryStringStore(mapQueryStringToProps)(FlaggedVisitSelector);
