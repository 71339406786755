import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

import { UserPermission } from 'components';
import ResponsiveTable from 'components/ResponsiveTable';
import { getPhaseDeptsByPhase } from 'services/API/projectPhaseDepartment';
import { getPhasesByProject } from 'services/API/projectPhases';
import { PermissionConstants } from 'utils/AppConstants';

import SubmenuTitle from '../../components/SubmenuTitle';

import { budgetRowMeta } from './layout';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 17
  }
}));

const ProjectShow = () => {
  const { projectId } = useParams();
  const classes = useStyles();
  const [allPhases, setAllPhases] = useState([]);

  const getPhaseData = async () => {
    const newPhases = await getPhasesByProject(projectId);
    for (const phase of newPhases) {
      phase.ProjectPhaseDepartment = await getPhaseDeptsByPhase(phase.id);
    }
    setAllPhases(newPhases);
  };

  useEffect(() => {
    getPhaseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <UserPermission action={PermissionConstants.OBJECT_PM_DASHBOARD} I="read">
      <SubmenuTitle>Dashboard</SubmenuTitle>

      <div className={classes.root}>
        <ResponsiveTable
          data={allPhases}
          defaults={{
            sortBy: 'number',
            sortOrder: 'asc'
          }}
          disableFilters
          noDataMsg="No Phase found"
          rowMetadata={budgetRowMeta}
          showToolbars
        />
      </div>
    </UserPermission>
  );
};

export default ProjectShow;
