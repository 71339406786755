import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  Button,
  CurrencyInput,
  Input,
  MoreButton,
  NumberInput,
  ThemeProvider,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';
import { css } from '@emotion/react';
import { useTheme } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { debounce, isEmpty, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import InvoicesIcon from 'assets/Icons/Invoices';
import { ConfirmLeave } from 'components';
import ConfirmModal from 'components/Modal/ConfirmDialog';
import WrapTable, {
  tableCurrencyFormatter,
  tableEmptyValueFormatter,
  tablePercentageFormatter
} from 'components/WrapTable';
import usePricebookEntries from 'customHooks/usePricebookEntries';
import { getTrucksFromEmployeeList } from 'scenes/Jobs/JobDetail/componentsV2/ReviewReport/components/ReviewReportPartsAndMaterialsSection/components/ReviewReportInventoryItems/ReviewReportInventoryItems.hooks';
import Routes from 'scenes/Routes';
import addInventoryPartsToJob from 'services/core/graphql/jobs/mutations/addInventoryPartsToJob';
import deleteInventoryPart from 'services/core/graphql/jobs/mutations/deleteInventoryPart';
import { convertToCurrencyString, roundCurrency } from 'utils';
import { LineItemBillingStatus } from 'utils/AppConstants';
import { InventoryPartSourceType, InvoiceStatus } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import { getMarkupValue, getUnitPrice } from 'utils/onCalcChange';

import { InventoryItemFormModal } from '../../Jobs/JobDetail/components/PartsAndMaterials';

import {
  EditableInputWrapper,
  InvoiceItemsEditedField,
  LineItemWithAsterisk,
  SectionHeader
} from '../Components';
import { InventoryPartSelectionSource } from '../constants';
import {
  useAddInventoryPartsToVisit,
  useDeleteInventoryPartsFromVisit,
  useUpdateInventoryPart
} from '../mutations';
import {
  selectInventoryInput,
  selectInventoryItemFormData,
  selectInventoryItemUpdateInput
} from '../selectors';
import { getVisitDisplayText } from '../utils';

const InventoryModalModes = {
  ADD: 'ADD',
  EDIT: 'EDIT'
};

const trimFormatter = ({ value }) => value?.trim();

const emptyCell = () => (
  <div style={{ width: '100%', height: '100%', backgroundColor: '#E6E6E6' }} />
);

const getMarkup = (part, entry) => {
  if (entry) {
    // Override pricebook entry markup if markup is blank on InventoryPart, but unitPrice is not
    if (typeof part.unitPrice === 'number') {
      const derivedMarkup = getMarkupValue(part.unitCost, part.unitPrice);
      if (Math.abs(entry.markupValue - derivedMarkup) > 0.01) {
        return derivedMarkup;
      }
    }
    return entry.markupValue;
  }

  // Fallback
  return getMarkupValue(part.unitCost, part.unitPrice);
};

const getColumns = (
  updateTableField,
  handleEditClick,
  handleInvoiceStatusClick,
  handleDeleteClick,
  setExternalDescription,
  externalDescription,
  setRowsEditingUnitPrice,
  rowIsLoading,
  setRowIsLoading,
  displayPricebookMarkup
) =>
  [
    {
      field: 'displaySource',
      headerName: 'Source',
      width: 175,
      align: 'center',
      renderCell: ({ row }) => {
        if (row.source === InventoryPartSourceType.INVOICE) {
          return (
            <Typography variant={TV.BASE} weight={TW.REGULAR}>
              <Link to={Routes.invoice({ mode: 'view', id: row.invoiceId })}>
                {row.displaySource}
              </Link>
            </Typography>
          );
        }

        if (row.sourceAsteriskNeeded) {
          return <LineItemWithAsterisk content={row.displaySource} />;
        }

        return (
          <Typography variant={TV.BASE} weight={TW.REGULAR}>
            {row.displaySource}
          </Typography>
        );
      }
    },
    {
      field: 'itemName',
      headerName: 'Item Name',
      align: 'center',
      renderCell: ({ row }) => (
        <div css={{ textAlign: 'left', width: '100%' }}>
          {tableEmptyValueFormatter({ value: trimFormatter({ value: row.itemName }) })}
        </div>
      )
    },
    {
      field: 'description',
      headerName: 'Description',
      align: 'left',
      renderCell: ({ row }) => {
        if (row.readOnly) {
          return (
            <div css={{ width: '100%', margin: 'auto' }}>
              {tableEmptyValueFormatter({ value: trimFormatter({ value: row.description }) })}
            </div>
          );
        }
        return (
          <Input
            type="text"
            value={row.id === externalDescription.id ? externalDescription.value : row.description}
            onBlur={e => {
              const { value } = e.target;
              if (!value || value === row.description) return;

              const { id, version } = row;
              updateTableField({ id, version, description: value });
            }}
            onChange={e => {
              const { value } = e.target;
              if (!value || value === externalDescription.value) return;

              setExternalDescription({ id: row.id, value });
            }}
          />
        );
      }
    },
    {
      field: 'quantity',
      headerName: 'Qty',
      width: 75,
      align: 'left',
      justify: 'left',
      renderCell: ({ row }) => {
        if (row.readOnly) {
          return (
            <div css={{ textAlign: 'right', width: '100%', margin: 'auto' }}>
              {tableEmptyValueFormatter({ value: row.quantity })}
            </div>
          );
        }
        return (
          <NumberInput
            value={row.quantity}
            onBlur={value => {
              if (value === row.unitCost) {
                setRowIsLoading(prev => {
                  if (prev.length > 0) {
                    const prevCopy = [...prev];
                    prevCopy.shift();
                    return prevCopy;
                  }
                  return prev;
                });
              }

              const { id, version } = row;
              updateTableField({ id, version, quantity: value });
            }}
          />
        );
      }
    },
    {
      field: 'unitOfMeasure',
      headerName: 'UOM',
      width: 100,
      align: 'center',
      valueFormatter: trimFormatter
    },
    {
      field: 'unitCost',
      headerName: 'Unit Cost',
      align: 'left',
      width: 120,
      renderCell: ({ row }) => {
        if (row.readOnly || row.doNotInvoice) {
          return (
            <div css={{ textAlign: 'right', width: '100%', margin: 'auto' }}>
              {tableCurrencyFormatter({ value: row.unitCost })}
            </div>
          );
        }
        return (
          <EditableInputWrapper
            row={row}
            rowIsLoading={rowIsLoading}
            setRowIsLoading={setRowIsLoading}
            setRowsEditingUnitPrice={setRowsEditingUnitPrice}
          >
            <CurrencyInput
              css={css`
              input.Mui-disabled {
                color: black;
                background-color: #ffffff;
            `}
              value={roundCurrency(row.unitCost)}
              onBlur={value => {
                if (value === row.unitCost) {
                  setRowIsLoading(prev => {
                    if (prev.length > 0) {
                      const prevCopy = [...prev];
                      prevCopy.shift();
                      return prevCopy;
                    }
                    return prev;
                  });
                }
                const newUnitPrice = getUnitPrice(value, row.markup);
                const { id, version } = row;
                updateTableField({ id, version, unitCost: value, unitPrice: newUnitPrice });
              }}
            />
          </EditableInputWrapper>
        );
      }
    },
    displayPricebookMarkup && {
      field: 'pricebookMarkup',
      headerName: 'Pricebook Markup (%)',
      align: 'left',
      width: 100,
      renderCell: ({ row }) => {
        if (row.doNotInvoice) {
          return emptyCell();
        }

        return (
          <div css={{ textAlign: 'right', width: '100%', margin: 'auto' }}>
            {tablePercentageFormatter({ value: row.pricebookMarkup })}
          </div>
        );
      }
    },
    {
      field: 'markup',
      headerName: 'Markup (%)',
      align: 'left',
      width: 100,
      renderCell: ({ row }) => {
        if (row.readOnly) {
          return (
            <div css={{ textAlign: 'right', width: '100%', margin: 'auto' }}>
              {tablePercentageFormatter({ value: row.markup })}
            </div>
          );
        }
        if (row.doNotInvoice) {
          return emptyCell();
        }
        return (
          <EditableInputWrapper
            isMarkup
            row={row}
            rowIsLoading={rowIsLoading}
            setRowIsLoading={setRowIsLoading}
            setRowsEditingUnitPrice={setRowsEditingUnitPrice}
          >
            <NumberInput
              css={css`
              input.Mui-disabled {
                color: black;
                background-color: #ffffff;
            `}
              value={row.markup}
              onBlur={value => {
                if (value === row.unitCost) {
                  setRowIsLoading(prev => {
                    if (prev.length > 0) {
                      const prevCopy = [...prev];
                      prevCopy.shift();
                      return prevCopy;
                    }
                    return prev;
                  });
                }
                const { id, version } = row;
                // Note: Markup taken from the pricbook entry updates markup field
                // on the inventory item
                // We also want to update the unitPrice here.
                // TODO: Really should happen on the BE
                updateTableField({
                  id,
                  version,
                  markup: value,
                  unitPrice: getUnitPrice(row.unitCost, value)
                });
              }}
            />
          </EditableInputWrapper>
        );
      }
    },
    {
      field: 'unitPrice',
      headerName: 'Unit Price',
      align: 'left',
      width: 120,
      renderCell: ({ row }) => {
        if (row.readOnly) {
          return (
            <div css={{ textAlign: 'right', width: '100%', margin: 'auto' }}>
              {tableCurrencyFormatter({ value: row.unitPrice })}
            </div>
          );
        }
        if (row.doNotInvoice) {
          return emptyCell();
        }
        return (
          <EditableInputWrapper
            isUnitPrice
            row={row}
            rowIsLoading={rowIsLoading}
            setRowIsLoading={setRowIsLoading}
            setRowsEditingUnitPrice={setRowsEditingUnitPrice}
          >
            <CurrencyInput
              css={css`
              input.Mui-disabled {
                color: black;
                background-color: #ffffff;
            `}
              value={roundCurrency(row.unitPrice)}
              onBlur={value => {
                if (value === row.unitCost) {
                  setRowIsLoading(prev => {
                    if (prev.length > 0) {
                      const prevCopy = [...prev];
                      prevCopy.shift();
                      return prevCopy;
                    }
                    return prev;
                  });
                }

                const newMarkup = getMarkupValue(row.unitCost, value);
                const { id, version } = row;

                updateTableField({ id, version, unitPrice: value, markup: newMarkup });
              }}
            />
          </EditableInputWrapper>
        );
      }
    },
    {
      field: 'invoiceQuantity',
      headerName: 'Invoice Qty',
      width: 100,
      align: 'center',
      renderCell: ({ row }) => {
        if (row.doNotInvoice) {
          return emptyCell();
        }
        return <div css={{ width: '100%', textAlign: 'right' }}>{row.invoiceQuantity}</div>;
        /*
       * TODO: Re-enable when its possible to upate invoice item quantity
      return (
        <NumberInput
          value={row.invoiceQuantity}
          onBlur={value => {
            if (value === row.invoiceQuantity) return;

            const { id, version } = row;
            updateTableField({ id, version, quantity: value });
          }}
        />
      );
      */
      }
    },
    {
      field: 'subtotal',
      headerName: 'Subtotal',
      width: 100,
      align: 'center',
      valueFormatter: tableCurrencyFormatter,
      renderCell: ({ row, formattedValue }) => {
        if (row.doNotInvoice) {
          return emptyCell();
        }
        return (
          <Typography css={{ width: '100%', textAlign: 'right' }} numeric variant={TV.BASE}>
            {formattedValue}
          </Typography>
        );
      },
      totalGetter: ({ rows }) =>
        rows.filter(row => !row.doNotInvoice).reduce((acc, row) => acc + row.subtotal, 0),
      renderTotal: ({ formattedValue }) => (
        <Typography
          css={{ width: '100%', textAlign: 'right' }}
          numeric
          variant={TV.BASE}
          weight={TW.BOLD}
        >
          {convertToCurrencyString(formattedValue ?? 0)}
        </Typography>
      )
    },
    {
      field: 'actions',
      flex: 1,
      width: 38,
      headerName: '',
      noPadding: true,
      align: 'center',
      renderCell: ({ row }) => {
        if (row.billingStatus === LineItemBillingStatus.BILLED) return <></>;

        return (
          <MoreButton
            disabled={row.readOnly}
            options={[
              { label: 'Edit', icon: EditIcon, onClick: () => handleEditClick(row.id) },
              {
                label: row.doNotInvoice ? 'Include In Invoice' : 'Do Not Invoice',
                icon: row.doNotInvoice ? InvoicesIcon : BlockIcon,
                onClick: () => {
                  handleInvoiceStatusClick({
                    id: row.id,
                    version: row.version,
                    billingStatus: row.doNotInvoice
                      ? LineItemBillingStatus.NOT_INVOICED
                      : LineItemBillingStatus.DO_NOT_INVOICE
                  });
                }
              },
              {
                label: 'Delete',
                icon: DeleteIcon,
                onClick: () => handleDeleteClick({ inventoryPartId: row.id, visitId: row.visitId })
              }
            ]}
          />
        );
      }
    }
  ].filter(Boolean);

const isAsteriskNeeded = (invoices, part) => {
  const invoiceItem = invoices
    .flatMap(invoice => invoice.invoiceItems?.items || [])
    .find(({ id }) => id === part?.invoiceItemId);

  return invoiceItem
    ? invoiceItem?.quantity !== part?.quantity ||
        invoiceItem?.unitCost !== part?.unitCost ||
        invoiceItem?.unitPrice !== part?.unitPrice
    : false;
};

const shouldUseInvoiceQuantity = part => {
  return part?.invoiceItem?.invoice?.status !== InvoiceStatus.VOID
    ? part?.invoiceItem?.quantity ?? part.quantity
    : part.quantity;
};

const mapInventoryPartsToRows = (
  visits,
  inventoryParts,
  invoices,
  pricebookEntries,
  rowsEditingUnitPrice,
  hideInvoiced,
  companyTimezone
) => {
  const rows = [];
  visits.forEach(visit => {
    const visitInventoryParts = visit.inventoryParts?.items || [];
    const visitTag = getVisitDisplayText(visit, companyTimezone);

    visitInventoryParts.forEach(part => {
      const entry = pricebookEntries.find(
        pricebookEntry => pricebookEntry.productSortKey === part.product?.sortKey
      );

      rows.push({
        ...part,
        displaySource: visitTag,
        sourceAsteriskNeeded: isAsteriskNeeded(invoices, part),
        subtotal: part.unitPrice * shouldUseInvoiceQuantity(part),
        quantity:
          part.source === InventoryPartSourceType.INVOICE
            ? part?.invoiceItem?.quantity
            : part.quantity,
        invoiceQuantity: shouldUseInvoiceQuantity(part),
        markup: getMarkup(part, entry),
        pricebookMarkup: entry?.markupValue,
        unitOfMeasure: part.unitOfMeasure || '-',
        readOnly: part.billingStatus === LineItemBillingStatus.BILLED,
        doNotInvoice: part.billingStatus === LineItemBillingStatus.DO_NOT_INVOICE,
        visitNumber: visit.visitNumber,
        visitId: visit.id,
        unitPriceFocus: rowsEditingUnitPrice.includes(part.id),
        rowsEditingUnitPrice
      });
    });
  });

  inventoryParts.forEach(part => {
    const entry = pricebookEntries.find(
      pricebookEntry => pricebookEntry.productSortKey === part.product.sortKey
    );

    rows.push({
      ...part,
      displaySource:
        part.source === InventoryPartSourceType.INVOICE
          ? `Invoice ${part?.invoiceItem?.invoice?.invoiceNumber}`
          : InventoryPartSelectionSource[part.source],
      sourceAsteriskNeeded: isAsteriskNeeded(invoices, part),
      subtotal: part.unitPrice * shouldUseInvoiceQuantity(part),
      quantity:
        part.source === InventoryPartSourceType.INVOICE
          ? part?.invoiceItem?.quantity
          : part.quantity,
      invoiceQuantity: shouldUseInvoiceQuantity(part),
      markup: getMarkup(part, entry),
      pricebookMarkup: entry?.markupValue,
      unitOfMeasure: part.unitOfMeasure || '-',
      readOnly: part.billingStatus === LineItemBillingStatus.BILLED,
      doNotInvoice: part.billingStatus === LineItemBillingStatus.DO_NOT_INVOICE,
      visitNumber: null,
      visitId: null,
      invoiceId:
        part.source === InventoryPartSourceType.INVOICE ? part.invoiceItem?.invoice?.id : null,
      unitPriceFocus: rowsEditingUnitPrice.includes(part.id),
      rowsEditingUnitPrice
    });
  });

  const filteredRows = rows.filter(row => {
    if (hideInvoiced && row.billingStatus === LineItemBillingStatus.BILLED) {
      return false;
    }
    return true;
  });

  return orderBy(filteredRows, ['source', 'visitNumber', 'itemName'], ['desc', 'asc', 'asc']);
};

const InventoryPartsCostsTable = ({
  jobData,
  visits,
  invoices,
  refetchJob,
  hideInvoiced,
  companyTimezone,
  isLoading
}) => {
  const flags = useFlags();
  const displayPricebookMarkup = flags[FeatureFlags.DISPLAY_PRICEBOOK_MARKUP];
  const theme = useTheme();
  const [externalDescription, setExternalDescription] = useState('');

  const { defaultPriceBookId, tenantId, departments } = useSelector(state => state?.company);
  const [addInventoryPartsToJobMutation, { loading: addPartLoading }] = useMutation(
    addInventoryPartsToJob
  );

  const [addInventoryPartsToVisitMutation] = useAddInventoryPartsToVisit();
  const [deleteInventoryPartFromVisitMutation] = useDeleteInventoryPartsFromVisit();
  const [updateInventoryMap, setUpdateInventoryMap] = useState({});
  const [
    updateInventoryPartMutation,
    { loading: updateInventoryPartLoading }
  ] = useUpdateInventoryPart();

  const availableTrucks = useMemo(
    () =>
      visits
        ?.flatMap(({ primaryTechs, extraTechs }) => [
          ...getTrucksFromEmployeeList(primaryTechs?.items || []),
          ...getTrucksFromEmployeeList(extraTechs?.items || [])
        ])
        .filter((truck, index, currArr) => currArr.indexOf(truck) === index),
    [visits]
  );

  const updateInventoryParts = useCallback(
    debounce(async ({ map, setMap }) => {
      setMap(oldMap => {
        const newMap = {};
        Object.keys(oldMap).forEach(key => {
          const { version, ...rest } = oldMap[key];
          if (!isEmpty(rest)) {
            newMap[key] = { version: version + 1 };
          }
        });
        return newMap;
      });
      const promises = [];
      Object.keys(map).forEach(key => {
        promises.push(updateInventoryPartMutation({ tenantId, inventoryPart: { ...map[key] } }));
      });
      await Promise.all(promises);
    }, 1000),
    [tenantId]
  );

  const [deleteInventoryPartMutation] = useMutation(deleteInventoryPart);

  const [confirmDelete, setConfirmDelete] = useState({});
  const [inventoryItemModalOpen, setInventoryItemModalOpen] = useState(false);

  const modalMode = useRef(null);
  const modalInitialValues = useRef({});

  const departmentOptions =
    departments?.items.map(d => ({
      label: d.tagName,
      value: d.id
    })) || [];

  const [jobReportDepartment, setJobReportDepartment] = useState(null);

  useEffect(() => {
    const firstJobDepartment = jobData?.departments?.items[0];
    if (firstJobDepartment) {
      setJobReportDepartment({
        label: firstJobDepartment.mappedEntity?.tagName,
        value: firstJobDepartment.mappedEntity?.id
      });
    } else {
      setJobReportDepartment(null);
    }
  }, [jobData]);

  const visitDepartments = useMemo(
    () =>
      visits?.map(({ id, departmentId }) => ({
        id,
        departmentId
      })),
    [visits]
  );

  const [rowsEditingUnitPrice, setRowsEditingUnitPrice] = useState([]);
  const [rowIsLoading, setRowIsLoading] = useState(() => []);

  useEffect(() => {
    if (!isEmpty(updateInventoryMap)) {
      const relevantMap = {};
      Object.keys(updateInventoryMap).forEach(key => {
        const { version, ...rest } = updateInventoryMap[key];
        if (!isEmpty(rest)) {
          relevantMap[key] = { ...updateInventoryMap[key] };
        }
      });

      if (!isEmpty(relevantMap)) {
        updateInventoryParts({ map: relevantMap, setMap: setUpdateInventoryMap });
      }
    }
  }, [updateInventoryMap]);

  useEffect(() => {
    if (!updateInventoryPartLoading) {
      setRowIsLoading(prev => {
        if (prev.length > 0) {
          const prevCopy = [...prev];
          prevCopy.shift();
          return prevCopy;
        }
        return prev;
      });
    }
  }, [updateInventoryPartLoading]);

  const handleCancelConfirmation = () =>
    setConfirmDelete({ confirmMessage: '', confirmAction: '', confirmDialog: '' });

  const deleteAction = useCallback(
    async ({ inventoryPartId, visitId }) => {
      if (visitId) {
        await deleteInventoryPartFromVisitMutation({
          tenantId,
          visitId,
          inventoryPartId,
          jobId: jobData.id
        });
      } else {
        await deleteInventoryPartMutation({
          variables: {
            id: inventoryPartId,
            partitionKey: tenantId,
            jobId: jobData.id
          }
        });
        await refetchJob();
      }
      setConfirmDelete({});
    },
    [deleteInventoryPartMutation, refetchJob, tenantId, jobData.id]
  );

  const handleDeleteClick = useCallback(
    args => {
      setConfirmDelete({
        confirmAction: async () => deleteAction(args),
        confirmMessage: 'inventory item',
        confirmDialog: true
      });
    },
    [deleteAction]
  );

  const handleAddInventory = async inventoryItem => {
    if (inventoryItem.selectedSource.value === InventoryPartSelectionSource.JOB_REPORT) {
      await addInventoryPartsToJobMutation({
        variables: {
          data: {
            jobId: jobData.id,
            inventoryParts: [selectInventoryInput(inventoryItem)]
          },
          partitionKey: tenantId
        }
      });
      refetchJob();
    } else {
      await addInventoryPartsToVisitMutation({
        tenantId,
        visitId: inventoryItem.selectedSource.value,
        inventoryParts: [selectInventoryInput(inventoryItem)]
      });
    }
  };

  const handleEditClick = useCallback(
    inventoryItemId => {
      modalMode.current = InventoryModalModes.EDIT;
      const allInventoryParts = [
        ...visits?.map(v => {
          const departmentId = departmentOptions.find(d => v.departmentId === d.value)?.value;
          return v.inventoryParts?.items.map(part => ({ ...part, departmentId }));
        }),
        ...jobData.inventoryParts?.items
      ].flat();
      modalInitialValues.current = selectInventoryItemFormData({
        inventoryItem: allInventoryParts.find(p => p.id === inventoryItemId),
        departmentOptions
      });
      setInventoryItemModalOpen(true);
    },
    [jobData, visits]
  );

  const handleAddClick = () => {
    modalMode.current = InventoryModalModes.ADD;
    modalInitialValues.current = {
      department: jobReportDepartment,
      selectedSource: {
        label: InventoryPartSelectionSource.JOB_REPORT,
        value: InventoryPartSelectionSource.JOB_REPORT
      }
    };
    setInventoryItemModalOpen(true);
  };

  const handleInvoiceStatusClick = useCallback(
    ({ id, version, billingStatus }) => {
      updateInventoryPartMutation({
        tenantId,
        inventoryPart: {
          id,
          version,
          billingStatus
        }
      });
    },
    [tenantId]
  );

  const [pricebookEntries] = usePricebookEntries({
    pricebookId: jobData.priceBookId,
    productSortKeys: isLoading
      ? []
      : [
          ...jobData.inventoryParts?.items.map(inventoryPart => inventoryPart?.product?.sortKey),
          ...visits?.reduce(
            (acc, v) => [
              ...acc,
              ...v.inventoryParts.items?.map(p => p.product?.sortKey).filter(k => k)
            ],
            []
          )
        ]
  });

  // Filter out visits and parts with void invoices
  const inventoryParts = useMemo(() => {
    if (isLoading) return [];
    return jobData.inventoryParts?.items?.filter?.(part => {
      if (part.source === InventoryPartSourceType.JOB_REPORT) {
        return true;
      }
      if (part.source === InventoryPartSourceType.INVOICE) {
        return part.invoiceItem?.invoice?.status !== InvoiceStatus.VOID;
      }
      return false;
    });
  }, [jobData?.inventoryParts?.items]);

  const rows = useMemo(
    () =>
      isLoading
        ? []
        : mapInventoryPartsToRows(
            visits || [],
            inventoryParts || [],
            invoices || [],
            pricebookEntries,
            rowsEditingUnitPrice,
            hideInvoiced,
            companyTimezone
          ),
    [visits, inventoryParts, pricebookEntries, rowsEditingUnitPrice, hideInvoiced]
  );

  const updateTableField = useCallback(async newData => {
    setUpdateInventoryMap(oldMap => ({
      ...oldMap,
      [newData.id]: {
        ...(oldMap[newData.id] ?? {}),
        ...newData,
        version: oldMap[newData.id]?.version ?? newData.version
      }
    }));
  }, []);

  const handleInventoryPartSubmit = inventoryItem => {
    if (modalMode.current === InventoryModalModes.ADD) {
      handleAddInventory(inventoryItem);
    } else {
      updateTableField(selectInventoryItemUpdateInput(inventoryItem));
    }
    setInventoryItemModalOpen(false);
  };

  const sourceOptions = useMemo(() => {
    return [
      {
        label: InventoryPartSelectionSource.JOB_REPORT,
        value: InventoryPartSelectionSource.JOB_REPORT
      },
      ...visits?.map?.(visit => {
        return {
          label: `Visit ${visit.visitNumber}`,
          value: visit.id
        };
      })
    ];
  }, [jobData.visits]);

  const columns = useMemo(
    () =>
      getColumns(
        updateTableField,
        handleEditClick,
        handleInvoiceStatusClick,
        handleDeleteClick,
        setExternalDescription,
        externalDescription,
        setRowsEditingUnitPrice,
        rowIsLoading,
        setRowIsLoading,
        displayPricebookMarkup
      ),
    [
      updateTableField,
      handleEditClick,
      handleInvoiceStatusClick,
      handleDeleteClick,
      externalDescription,
      setRowsEditingUnitPrice,
      rowIsLoading,
      setRowIsLoading,
      displayPricebookMarkup
    ]
  );

  return (
    <ThemeProvider>
      <SectionHeader title="Inventory Items">
        <Button
          loading={isLoading || addPartLoading}
          size="small"
          type="secondary"
          onClick={handleAddClick}
        >
          Add Items
        </Button>
      </SectionHeader>
      <WrapTable
        columns={columns}
        enableTotalsRow
        hideFooter={rows.length <= 10}
        loading={isLoading}
        loadingRows={3}
        noDataMessage="No inventory parts"
        rows={rows}
      />
      {rows?.some(({ sourceAsteriskNeeded }) => sourceAsteriskNeeded === true) && (
        <InvoiceItemsEditedField />
      )}
      <InventoryItemFormModal
        availableTrucks={availableTrucks}
        departmentOptions={departmentOptions}
        initialValues={modalInitialValues.current}
        jobReportDepartment={jobReportDepartment}
        loading={false}
        open={inventoryItemModalOpen}
        priceBookId={jobData.priceBookId || defaultPriceBookId}
        setJobReportDepartment={setJobReportDepartment}
        sourceOptions={modalMode.current === InventoryModalModes.ADD ? sourceOptions : null}
        title={`${modalMode.current === InventoryModalModes.ADD ? 'Add' : 'Edit'} Inventory Item`}
        visitDepartments={visitDepartments}
        onClose={() => setInventoryItemModalOpen(false)}
        onSubmit={handleInventoryPartSubmit}
      />
      <ConfirmModal
        cancel={handleCancelConfirmation}
        confirm={confirmDelete.confirmAction}
        message={confirmDelete.confirmMessage}
        open={confirmDelete.confirmDialog}
      />
      <ConfirmLeave when={updateInventoryPartLoading} />
    </ThemeProvider>
  );
};

InventoryPartsCostsTable.defaultProps = {
  isLoading: false
};

export default InventoryPartsCostsTable;
