import { useMemo } from 'react';

import { MUIFormComponentNames } from '@BuildHero/sergeant';

import { col, row } from 'utils/layout';

export const useLaborProductivityFormConfig = ({ employees }) => {
  return useMemo(() => {
    const margin = { marginRight: 16, marginBottom: 16 };
    const width = { maxWidth: 200 };
    const timeFieldOptions = { ...margin, ...width };
    const timeValidationFieldOptions = { ...margin, grow: 2 };

    const employeeOptions =
      employees
        ?.filter(employee => employee.isActive === true)
        .map(employee => ({ value: employee.id, label: employee.name })) || [];

    const WORKERS_FIELD = {
      component: MUIFormComponentNames.SelectInput,
      source: 'workers',
      options: {
        label: 'Workers',
        placeholder: 'Search Workers',
        valueSet: employeeOptions,
        isMultipleSelection: true,
        isSearchable: true,
        isRequired: true
      }
    };

    const START_TIME_FIELD = {
      component: 'LaborTimeField',
      source: 'startTime',
      options: {
        label: 'Start Time',
        variant: 'outlined',
        isRequired: true,
        errorText: 'Field is required'
      }
    };

    const END_TIME_FIELD = {
      component: 'LaborTimeField',
      source: 'endTime',
      options: {
        label: 'End Time',
        variant: 'outlined',
        isRequired: true,
        errorText: 'Field is required'
      }
    };

    const TIME_VALIDATION_FIELD = {
      options: { label: 'Payroll Hours to Distribute' },
      component: 'LaborTimeRemainingField'
    };

    const LUNCH_IN_TIME_FIELD = {
      component: 'LaborTimeField',
      source: 'lunchInTime',
      options: {
        label: 'Lunch In',
        variant: 'outlined'
      }
    };

    const LUNCH_OUT_TIME_FIELD = {
      component: 'LaborTimeField',
      source: 'lunchOutTime',
      options: {
        label: 'Lunch Out',
        variant: 'outlined'
      }
    };

    const LUNCH_TIME_VALIDATION_FIELD = {
      component: 'LunchTimeValidationField'
    };

    return {
      fields: {},
      layouts: {
        default: {
          contents: [
            row([WORKERS_FIELD], margin),
            row(
              [
                col([START_TIME_FIELD], timeFieldOptions),
                col([END_TIME_FIELD], timeFieldOptions),
                col([TIME_VALIDATION_FIELD], timeValidationFieldOptions)
              ],
              margin
            ),
            row(
              [
                col([LUNCH_IN_TIME_FIELD], timeFieldOptions),
                col([LUNCH_OUT_TIME_FIELD], timeFieldOptions),
                col([LUNCH_TIME_VALIDATION_FIELD], timeValidationFieldOptions)
              ],
              margin
            )
          ]
        }
      }
    };
  }, [employees]);
};
