import React from 'react';

import { StepProgressIndicator, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { Box, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Tooltip } from 'components';

const bouncedEmailsHeadingText = bouncedCount =>
  bouncedCount > 1 ? `${bouncedCount} Emails Bounced` : '1 Email Bounced';

const TooltipText = ({ emails }) => {
  return (
    <>
      Email bounced to:
      <br />
      {emails.map(email => (
        <>
          <br />
          {email}
        </>
      ))}
    </>
  );
};

function EmailStatus({ status, sendCount, openCount, bouncedEmails }) {
  const sentLabel = sendCount ? `Sent (${sendCount})` : 'Sent';
  const readLabel = openCount ? `Read (${openCount})` : 'Read';
  const bouncedCount = bouncedEmails?.length;

  const { spacing } = useTheme();

  return (
    <ThemeProvider>
      <Box display="flex" flexDirection="column">
        <Typography style={{ marginBottom: spacing(1) }} variant={TV.BASE} weight={TW.BOLD}>
          Email Status
        </Typography>

        <StepProgressIndicator
          activeStep={status}
          steps={[
            { label: 'Unsent', value: 'unsent' },
            { label: sentLabel, value: 'sent' },
            { label: 'Delivered', value: 'delivered' },
            { label: readLabel, value: 'opened' }
          ]}
        />

        {bouncedCount > 0 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: spacing(1)
            }}
          >
            <Typography variant={TV.S1} weight={TW.REGULAR}>
              {bouncedEmailsHeadingText(bouncedCount)}
            </Typography>
            <Tooltip helpText={<TooltipText emails={bouncedEmails} />} />
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
}

EmailStatus.propTypes = {
  status: PropTypes.string,
  sendCount: PropTypes.number,
  openCount: PropTypes.number,
  bouncedEmails: PropTypes.arrayOf(PropTypes.string)
};

EmailStatus.defaultProps = {
  status: 'unsent',
  sendCount: undefined,
  openCount: undefined,
  bouncedEmails: []
};

export default EmailStatus;
