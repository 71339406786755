import React, { useCallback, useState } from 'react';

import { DeleteOutlined, EditOutlined } from '@material-ui/icons';
import every from 'lodash/every';
import { any, bool, object } from 'prop-types';
import { useSelector } from 'react-redux';

import { actionButtonColumn, checkboxColumn } from 'components/WrapTable';
import withLazyMount from 'utils/withLazyMount';

import { summaryNotesColumns } from '../../../../meta';
import {
  useAddReviewReportSummaryNote,
  useDeleteReviewReportSummaryNote,
  useEditReviewReportSummaryNote
} from '../../../../mutations';
import { selectReviewReportSummaryNotes } from '../../../../selectors';
import ReportTable from '../../../ReportTable';

import { useReviewReportDisabled } from '../../ReviewReport.contexts';
import TableRowModal from '../TableRowModal';

import SummarySectionHeader from './components/SummarySectionHeader';
import { summaryLayout } from './SummaryModal.layout';

const ReviewReportSummaryNotesTable = ({ reviewReport, loading, error }) => {
  const user = useSelector(state => state.user);
  const { disabled } = useReviewReportDisabled();
  const rows = useCallback(selectReviewReportSummaryNotes(reviewReport), [reviewReport]);
  const [editSummaryNoteMutation, { loading: updateLoading }] = useEditReviewReportSummaryNote();
  const [summaryToEdit, setSummaryToEdit] = useState(null);
  const showEditModal = row => setSummaryToEdit(row);
  const closeEditModal = () => setSummaryToEdit(null);
  const handleEdit = async data => {
    await editSummaryNoteMutation({
      tenantId: user.tenantId,
      summaryId: summaryToEdit?.id,
      version: summaryToEdit?.version,
      summary: data?.summary
    });
    closeEditModal();
  };

  const [addSummaryNoteMutation] = useAddReviewReportSummaryNote(reviewReport?.id);
  const [summaryToAdd, setSummaryToAdd] = useState(null);
  const showAddModal = row => setSummaryToAdd(row);
  const closeAddModal = () => setSummaryToAdd(null);
  const handleAdd = async summary => {
    await addSummaryNoteMutation({
      tenantId: user.tenantId,
      reviewReportId: reviewReport?.id,
      summary: {
        ...summary
      }
    });
    closeAddModal();
  };

  const [deleteSummaryNoteMutation] = useDeleteReviewReportSummaryNote(reviewReport?.id);
  const handleDelete = summaryToDelete =>
    deleteSummaryNoteMutation({
      tenantId: user.tenantId,
      summaryId: summaryToDelete?.id
    });

  const handleIncludeInInvoiceChange = row => event =>
    editSummaryNoteMutation({
      tenantId: user.tenantId,
      summaryId: row?.id,
      version: row?.version,
      includeInInvoice: event.target.checked
    });

  const handleIncludeInInvoiceAllChange = event => {
    const { checked } = event.target;
    return Promise.all(
      rows
        .filter(row => (checked ? !row.includeInInvoice : row.includeInInvoice))
        .map(row =>
          editSummaryNoteMutation({
            tenantId: user.tenantId,
            summaryId: row?.id,
            version: row?.version,
            includeInInvoice: checked
          })
        )
    );
  };

  const actions = [
    { icon: EditOutlined, label: 'Edit', onClick: showEditModal, disabled },
    {
      icon: DeleteOutlined,
      label: 'Delete',
      onClick: handleDelete,
      disabled
    }
  ];

  const columns = [
    checkboxColumn({
      disabled: disabled || updateLoading,
      onRowToggle: handleIncludeInInvoiceChange,
      onHeaderToggle: handleIncludeInInvoiceAllChange,
      isRowChecked: row => row?.includeInInvoice,
      isHeaderChecked: () => every(rows, summary => summary?.includeInInvoice)
    }),
    ...summaryNotesColumns,
    actionButtonColumn({ actions })
  ];

  const renderTitle = () => <SummarySectionHeader onAddSummaryNote={() => showAddModal({})} />;

  return (
    <>
      <ReportTable
        columns={columns}
        error={error}
        loading={loading}
        noDataMessage="No Summary Note"
        renderTitle={renderTitle}
        rows={rows}
      />

      <TableRowModal
        data={summaryToEdit}
        layout={summaryLayout}
        open={Boolean(summaryToEdit)}
        title="Edit Summary Note"
        onAction={handleEdit}
        onClose={closeEditModal}
      />

      <TableRowModal
        data={summaryToAdd}
        layout={summaryLayout}
        open={Boolean(summaryToAdd)}
        title="Add Summary Note"
        onAction={handleAdd}
        onClose={closeAddModal}
      />
    </>
  );
};

ReviewReportSummaryNotesTable.propTypes = {
  reviewReport: object.isRequired,
  loading: bool.isRequired,
  error: any
};
ReviewReportSummaryNotesTable.defaultProps = {
  error: undefined
};

export default withLazyMount(ReviewReportSummaryNotesTable);
