import React, { useEffect, useRef, useState } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';

import DefaultButton from 'components/Buttons/DefaultButton';
import FullScreenModal from 'components/FullScreenModal';
import Labels from 'meta/labels';
import {
  addSubmitatalItemlayout,
  addSubmittalItemFields
} from 'meta/ProjectManagement/Submittals/addSubmittalItemForm';
import { snackbarOn } from 'redux/actions/globalActions';
import AttachmentsV1 from 'scenes/ProjectManagement/components/Attachments';
import AttachmentsV2 from 'scenes/ProjectManagement/components/AttachmentsV2';
import CustomFieldWithLabel from 'scenes/ProjectManagement/components/CustomFieldWithLabel';
import { generateDefaultValidationSchema } from 'scenes/ProjectManagement/components/formattingUtils';
import {
  getNextNumber,
  submittalItemChange,
  submittalItemCreate
} from 'services/API/submittalItem';
import { ProjectManagementSubmittalStatus } from 'utils/AppConstants';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import styles from './style';

const sanitizeData = (data, projectId, attachments) => {
  return {
    ...data,
    projectId,
    specSection: data?.specSection ? data.specSection : null,
    subSection: data?.subSection ? data.subSection : null,
    notes: data?.notes ? data?.notes : null,
    submitBy: data?.submitBy,
    submittalItemAttachments:
      attachments?.map(file => ({
        id: file?.id || null,
        customFileName: file.customFileName || null,
        fileName: file.fileName || null,
        fileType: file.fileType || null,
        fileUrl: file.fileUrl || null
      })) || []
  };
};

const CustomFieldWithLabelStyled = ({ field, options }) => {
  return (
    <CustomFieldWithLabel
      field={field}
      options={options}
      style={{ background: '#f0f0f0', color: '#999999', padding: '1px 8px' }}
    />
  );
};

const AddSubmittalItem = props => {
  const { classes, initialData, editMode, open, projectId, user, handleClose, snackbar } = props;
  const fullscreenModalRef = useRef(null);
  const [formService, setFormService] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nextNumber, setNextNumber] = useState('');
  const [attachmentLists, setAttachmentLists] = useState([]);

  useEffect(() => {
    const setMaxWidth = () => {
      if (fullscreenModalRef?.current?.parentNode?.style.maxWidth < 1440) {
        fullscreenModalRef.current.parentNode.style.maxWidth = '1440px';
        fullscreenModalRef.current.parentNode.style.marginTop = '0px';
        fullscreenModalRef.current.parentNode.style.display = 'flex';
        fullscreenModalRef.current.parentNode.style.justifyContent = 'center';
      }
    };
    setMaxWidth();
  });

  useEffect(() => {
    getNextNumber(projectId).then(number => setNextNumber(number));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAttachmentLists(initialData?.itemData?.submittalItemAttachments || []);
  }, [initialData]);

  const handleUploadFileList = (newAttachments, selectedImages, selectedAttachments) => {
    const selectedAttachmentUrls = [
      ...R.map(R.prop('url'))(selectedImages || []),
      ...R.map(R.prop('fileUrl'))(selectedAttachments || [])
    ];
    const existingAttachments = R.filter(
      attachment =>
        Boolean(attachment?.fileUrl) && R.includes(attachment.fileUrl)(selectedAttachmentUrls)
    )(attachmentLists);
    setAttachmentLists([...existingAttachments, ...newAttachments]);
  };

  const handleSubmit = async data => {
    setIsSubmitting(true);
    const payload = sanitizeData(data, projectId, attachmentLists);

    if (editMode) {
      await submittalItemChange(initialData.itemData?.id, payload);
      snackbar('success', 'Updated successfully');
    } else {
      await submittalItemCreate(payload);
      snackbar('success', 'Posted successfully');
    }
    setAttachmentLists([]);
    setIsSubmitting(false);
    handleClose();
  };

  const getFormattedData = data => {
    if (!data) return {};

    return {
      version: editMode ? `${parseInt(data?.version, 10) + 1}` : data?.version || '1',
      specSection: data?.specSection || null,
      subSection: data?.subSection || null,
      description: data?.description,
      notes: data?.notes,
      type: data?.type || null,
      status: data?.status || ProjectManagementSubmittalStatus.TO_BE_SUBMITTED,
      number: data?.number || nextNumber,
      submitBy: data?.submitBy || null
    };
  };

  const formattedData = getFormattedData(initialData.itemData);

  const Attachments = useFlags()[FeatureFlags.PM_FILE_SYSTEM] ? AttachmentsV2 : AttachmentsV1;

  return (
    <FullScreenModal
      handleClose={handleClose}
      modalHeaderButtons={[
        <DefaultButton
          disabled={isSubmitting}
          key="save"
          label="Save Submittal Item"
          showSpinner={isSubmitting}
          variant="containedPrimary"
          onClick={() => formService.submit()}
        />
      ]}
      open={open}
      title={
        initialData.type === 'default'
          ? Labels.addSubmittalItem[user.locale]
          : Labels.editSubmittalItem[user.locale]
      }
    >
      <Box className={classes.contentContainer} ref={fullscreenModalRef}>
        <Box className={classes.formContainer}>
          <MUIForm
            configuration={addSubmitatalItemlayout}
            customComponents={{ CustomFieldWithLabelStyled }}
            data={formattedData}
            layout={editMode ? 'edit' : 'default'}
            validationSchema={generateDefaultValidationSchema(addSubmittalItemFields)}
            onComplete={finalizeData => handleSubmit(finalizeData)}
            onCreateService={service => setFormService(service)}
          />
        </Box>
        <Box>
          <Attachments
            attachedFileCount={
              editMode ? initialData.itemData?.submittalItemAttachments?.length : 0
            }
            attachedFiles={editMode ? initialData.itemData?.submittalItemAttachments : []}
            downloadable={editMode}
            smallType
            uploadFileList={handleUploadFileList}
          />
        </Box>
      </Box>
    </FullScreenModal>
  );
};

CustomFieldWithLabelStyled.propTypes = {
  field: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

AddSubmittalItem.propTypes = {
  classes: PropTypes.object.isRequired,
  initialData: PropTypes.object,
  editMode: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  snackbar: PropTypes.func.isRequired
};

AddSubmittalItem.defaultProps = {
  initialData: {}
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  snackbar: (mode, message) => dispatch(snackbarOn(mode, message))
});
const ReduxConnectedAddSubmittalItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSubmittalItem);
export default withStyles(styles)(ReduxConnectedAddSubmittalItem);
