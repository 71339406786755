import React, { useMemo } from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { getMultiSelectOptions } from 'meta/ListViews';

import { toTitleCase } from 'utils';

const getValue = val => {
  if (!val) return [];
  return val.includes('[') ? JSON.parse(val) : [val];
};

const optionStyle = { whiteSpace: 'pre-wrap' };

export default function EnumFilterInput({
  item,
  apiRef,
  applyValue,
  selectOptions,
  isMultiple = false,
  customApplyValue
}) {
  const { noLabelFormat, enumType } = apiRef.current.getColumn(item.columnField);
  // selectOptions can be passed as InputComponentProps in filterOperators
  const options = useMemo(() => selectOptions || getMultiSelectOptions(enumType), [
    selectOptions,
    enumType
  ]);
  // In Multiselect, Stringifying as both data grid api and graphql has the type as string
  const parsedValue = isMultiple && getValue(item.value);

  const changeValue = event => {
    const { value } = event.target;
    if (customApplyValue) {
      return customApplyValue(applyValue, item, value);
    }
    if (!isMultiple) return applyValue({ ...item, value });
    const modifiedValue = parsedValue.includes(value)
      ? parsedValue.filter(v => v !== value)
      : parsedValue.concat(value);

    applyValue({
      ...item,
      value: modifiedValue.length ? JSON.stringify(modifiedValue) : ''
    });
  };

  return (
    <>
      <InputLabel id="columns-operators-select-label" shrink>
        Value
      </InputLabel>
      <Select
        id={`columns-filter-value-select-${item.columnField}`}
        inputProps={isMultiple && { size: 3 }}
        labelId={`columns-filter-value-select-${item.columnField}`}
        multiple={isMultiple}
        native
        value={isMultiple ? parsedValue : item.Value}
        onChange={changeValue}
      >
        <option disabled hidden selected value>
          {' '}
        </option>
        {options?.map(option =>
          typeof option === 'string' ? (
            <option css={optionStyle} key={option} value={option}>
              {noLabelFormat ? option : toTitleCase(option)}
            </option>
          ) : (
            <option css={optionStyle} key={option.label} value={option.label || option.value}>
              {option.label}
            </option>
          )
        )}
      </Select>
    </>
  );
}

export const EnumMultiSelectFilterInput = props => <EnumFilterInput isMultiple {...props} />;
