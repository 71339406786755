import React from 'react';

import { Typography } from '@BuildHero/sergeant';
import { bool } from 'prop-types';

import { VISIT_DETAILS_PROP } from '@dispatch/queries';

import { useStyles } from '../../VisitDetailsForm.styles';

const PropertyRep = ({ visit: { customerRep }, loading }) => {
  const classes = useStyles();

  return (
    <>
      <Typography classes={{ root: classes.oneLineText }} loading={loading} variant="body2">
        {customerRep?.name || 'none'}
      </Typography>
      <Typography classes={{ root: classes.oneLineText }} loading={loading} variant="body2">
        {customerRep?.email}
      </Typography>
      <Typography classes={{ root: classes.oneLineText }} loading={loading} variant="body2">
        {customerRep?.cellPhone}
      </Typography>
    </>
  );
};

PropertyRep.propTypes = {
  visit: VISIT_DETAILS_PROP.isRequired,
  loading: bool.isRequired
};

export default PropertyRep;
