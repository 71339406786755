import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { getServiceAgreementById } from 'customHooks/useServiceAgreement';

const addCustomerPropertiesToServiceAgreement = gql`
  mutation addCustomerPropertiesToServiceAgreement(
    $serviceAgreementId: String!
    $input: [String!]!
  ) {
    addCustomerPropertiesToServiceAgreement(
      serviceAgreementId: $serviceAgreementId
      input: $input
    ) {
      items {
        id
        companyName
        entityType
        isActive
        status
      }
    }
  }
`;

const useAddCustomerPropertiesToServiceAgreement = ({ user, serviceAgreementId }) => {
  return useExtendedMutation(addCustomerPropertiesToServiceAgreement, {
    serializer: ({ customerPropertyIds }) => ({
      variables: {
        serviceAgreementId,
        input: customerPropertyIds
      }
    }),
    skip: !serviceAgreementId,
    onSuccess: (data, snackbar) => {
      snackbar('success', 'Service Agreement Saved');
    },
    onError: (error, snackbar) => {
      snackbar('error', 'Unable to Save Service Agreement');
    },
    refetchQueries: [
      {
        query: getServiceAgreementById,
        variables: {
          partitionKey: user.tenantId,
          id: serviceAgreementId
        }
      }
    ]
  });
};

export default useAddCustomerPropertiesToServiceAgreement;
