import React from 'react';

import { Button, CircularProgress } from '@material-ui/core';

const SubmitButton = ({ submitting, handleSubmit }) => (
  <Button color="primary" disabled={submitting} variant="contained" onClick={handleSubmit}>
    {submitting && <CircularProgress size={20} />}
    Save
  </Button>
);

export default SubmitButton;
