/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef } from 'react';

import { useQuery } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

import { handleError } from '@dispatch/Dispatch.utils';
import { snackbarOn as snackbarOnAction } from 'redux/actions/globalActions';
import { dispatch } from 'redux/store';

const snackbarOn = (mode, message, errorLog) => dispatch(snackbarOnAction(mode, message, errorLog));

/**
 * Wrapper around @apollo/client useQuery that adds options for transforming and resolving data.
 *
 * @param {any} query - The graphQl query string.
 * @param {Object?} options - An extended [useQuery options object](https://www.apollographql.com/docs/react/api/react/hooks/#options-1).
 *
 * @param {function} options.transform - a function that transforms the resolved data from a successful mutation.
 * @param {Object} options.subscribeToMore - an object to be passed to the query's subscribeToMore function. [see docs](https://www.apollographql.com/docs/react/data/subscriptions/#subscribing-to-updates-for-a-query)
 * @param {any} options.loadingData - will be returned as data when the query is loading
 * @param {any} options.errorData - will be returned as data when there is an error
 * @param {any} options.defaultData - will be returned if data is falsy. errorData and loadingData take priority
 * @param {boolean} options.hideError - suppress notification on error
 * @param {function} options.onRequest - (variables, snackbarOn) => void
 * @param {function} options.onCompleted - (data, snackbarOn) => void
 * @param {function} options.onSuccess - (transformedData, snackbarOn) => void
 * @param {function} options.onError - (err, snackbarOn) => void
 * @param {function} options.onResponse - ({ err, data }, snackbarOn) => void | fires on success or error
 *
 * @returns [queryResponse](https://www.apollographql.com/docs/react/api/react/hooks/#result-1) { data: any, loading: boolean, error: Error, called: boolean, client: ApolloClient }
 */
const useExtendedQuery = (
  query,
  { defaultData, errorData, hideError, loadingData, subscribeToMore, transform, ...options } = {}
) => {
  const skip = Boolean(options.manual || options.skip);

  const res = useQuery(query, {
    ...options,
    skip,
    onCompleted: data => {
      const transformedData = transform?.(data, options.variables) || data;
      options.onCompleted?.(data, snackbarOn);
      options.onSuccess?.(transformedData, snackbarOn);
      options.onResponse?.({ data: transformedData }, snackbarOn);
    },
    onError: err => {
      options.onError?.(err, snackbarOn);
      options.onResponse?.({ err }, snackbarOn);
      handleError({ err, errContext: getMainDefinition(query)?.name?.value });
    }
  });

  const wasLoading = useRef(false);

  if (res.loading && !wasLoading.current) {
    options.onRequest?.(options.variables, snackbarOn);
    wasLoading.current = true;
  }

  if (!res.loading && wasLoading.current) wasLoading.current = false;

  useEffect(() => {
    const unsubscribe = subscribeToMore ? res?.subscribeToMore?.(subscribeToMore) : null;

    return () => unsubscribe?.();
  }, []);

  const transformedData = useMemo(() => {
    return res.data && transform ? transform(res.data, options.variables) : res.data;
  }, [res.data, transform]);

  const data = (() => {
    if (res.loading && loadingData) return loadingData;
    if (res.error && errorData) return errorData;

    return transformedData || defaultData;
  })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ({ ...res, data, rawData: res.data }), [res.data, res.loading, res.error]);
};

export default useExtendedQuery;
