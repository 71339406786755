import React from 'react';

import { withTheme } from '@material-ui/styles';

const Error = props => (
  <div
    style={{
      color: props.theme.palette.other.scarletRed,
      marginTop: 40,
      textAlign: 'center',
      fontSize: 24
    }}
  >
    {props.message || 'The requested page is not available'}
  </div>
);

export default withTheme(Error);
