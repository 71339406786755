import AmplifyService from 'services/AmplifyService';

import { visitTransitionMutation } from '../../../visitTransitionMutation';

const mutationUpdateVisit = ({ action, payload }) => {
  const { client } = AmplifyService.appSyncClient();
  return client.mutate({
    mutation: visitTransitionMutation,
    variables: {
      action,
      input: payload
    }
  });
};

export default mutationUpdateVisit;
