import React from 'react';

import { TV, TW, Typography } from '@BuildHero/sergeant';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

export const CustomRadioButton = props => {
  const { disabled, onChange, value, label, description } = props;
  const useStyles = makeStyles(theme => ({
    colorSecondary: {
      color: theme.palette.grayscale(20),
      '&$checked': {
        color: '#00854B'
      }
    },
    checked: {}
  }));
  const radioButtonClasses = useStyles();
  return (
    <div css={{ marginTop: 4, marginBottom: 4 }}>
      <Grid alignItems="center" container spacing={0}>
        <Grid item>
          <Radio
            classes={radioButtonClasses}
            disabled={disabled}
            size="small"
            value={value}
            onChange={onChange}
          />
        </Grid>
        <Grid item style={{ maxWidth: 250 }}>
          <Typography variant={TV.BASE} weight={TW.BOLD}>
            {label}
          </Typography>
          <Typography variant={TV.S1}>{description}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export const CustomRadioGroup = ({ handleChange, value, options = [] }) => {
  return (
    <RadioGroup row value={value} onChange={handleChange}>
      {options.map(option => {
        const { label, description } = option;
        return (
          <CustomRadioButton
            description={description}
            key={option.value}
            label={label}
            value={option.value}
          />
        );
      })}
    </RadioGroup>
  );
};
