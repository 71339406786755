import moment from 'moment-timezone';
import { object, string } from 'prop-types';

import { AppConstants } from 'utils/AppConstants';

const DateInTimezone = ({ timezone, record }) =>
  record?.date && timezone
    ? moment
        .unix(record?.date)
        .tz(timezone)
        .format(AppConstants.DATE_FORMAT)
    : '-';

DateInTimezone.propTypes = {
  timezone: string.isRequired,
  record: object.isRequired
};

export default DateInTimezone;
