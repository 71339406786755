import { useRef } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDragLayer, useDrop } from 'react-dnd';

import { selectDropTech } from '@dispatch/components/DispatchBoard/DispatchBoard.selectors';
import { canDropVisit, trackDrop } from '@dispatch/components/DispatchBoard/DispatchBoard.utils';
import { TIME_FORMAT } from '@dispatch/Dispatch.constants';
import { DragScenarios, ItemTypes, selectDragScenario } from '@dispatch/dnd';

import { selectSnappedMin, selectTimeClosestToClient } from './AvailableTimeCard.selectors';
import { serializeDropData } from './AvailableTimeCard.serializer';

export const useVisitDrop = ({
  availableTime,
  tech,
  timePosition,
  triggerVisitTransition,
  triggerUpdateNonVisit,
  triggerUpdateManDay
}) => {
  const flags = useFlags();

  const [collected, dropRef] = useDrop({
    accept: [
      ItemTypes.TABLE_VISIT,
      ItemTypes.BOARD_VISIT,
      ItemTypes.NON_VISIT_EVENT,
      ItemTypes.MAN_DAY
    ],
    canDrop: () =>
      canDropVisit({ ...collected, techId: tech.id, weekView: true, availableTime, flags }),
    collect: monitor => {
      const item = monitor.getItem();
      const dragScenario = selectDragScenario(item);
      const isOver = monitor.isOver({ shallow: true });

      const canDrop = (() => {
        try {
          return dragScenario === DragScenarios.FULLY_ASSIGNED_MULTI_TECH
            ? true
            : monitor.canDrop();
        } catch (err) {
          // We must handle the "no drop target" error for assigned visits
          // that are not currently rendered in the virtualized list
          return false;
        }
      })();

      return {
        isOver,
        canDrop,
        dragScenario,
        dropTargetActive: isOver && canDrop,
        primaryTech: selectDropTech(item),
        item
      };
    },
    drop: item => {
      const dragScenario = collected?.dragScenario;
      const itemType = collected?.item?.type;
      trackDrop({ dragScenario, itemType });
      switch (collected?.item?.type) {
        case ItemTypes.TABLE_VISIT:
        case ItemTypes.BOARD_VISIT: {
          triggerVisitTransition(serializeDropData({ item, timePosition, tech, ...collected }));
          break;
        }
        case ItemTypes.NON_VISIT_EVENT: {
          triggerUpdateNonVisit(serializeDropData({ item, timePosition, tech, ...collected }));
          break;
        }
        case ItemTypes.MAN_DAY: {
          triggerUpdateManDay(serializeDropData({ item, timePosition, tech, ...collected }));
          break;
        }
        default: {
          break;
        }
      }
    }
  });

  return [collected, dropRef];
};

export const usePositionUpdate = ({
  rootRef,
  isOver,
  setTimePosition,
  range,
  timePosition,
  canDrop
}) => {
  const canDropRef = useRef(false);
  canDropRef.current = canDrop;

  const positionRef = useRef(false);
  positionRef.current = timePosition.position;

  const isOverRef = useRef(false);
  isOverRef.current = isOver;

  useDragLayer(monitor => {
    if (isOverRef.current && canDropRef.current) {
      const rootY = rootRef?.current?.getBoundingClientRect?.()?.y || 0;
      const clientY = monitor?.getClientOffset?.()?.y || 0;
      const relativeY = clientY - rootY;
      const relativeYPercent = relativeY / rootRef.current.offsetHeight;
      const clientTime = range.start.clone().add(range.diff() * relativeYPercent);
      const snappedTime = selectSnappedMin(15, clientTime);
      // support snapping to start and end even if they are not on a 15 min time
      const timeClosestToClient = selectTimeClosestToClient({ range, snappedTime, clientTime });
      const relativeTimePercent = range.start.diff(timeClosestToClient) / range.diff();
      const snappedPosition = relativeTimePercent * rootRef.current.offsetHeight * -1;

      if (snappedPosition !== positionRef.current) {
        setTimePosition({
          position: snappedPosition,
          time: timeClosestToClient.format(TIME_FORMAT),
          unixTime: timeClosestToClient.unix()
        });
      }
    }
  });
};
