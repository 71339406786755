import React, { useEffect, useMemo, useState } from 'react';

import { Button, ButtonType, ThemeProvider } from '@BuildHero/sergeant';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'components';
import useCustomer from 'customHooks/useCustomer';
import usePricebooks from 'customHooks/usePricebooks';
import { useSnackbar } from 'customHooks/useSnackbar';

import Labels from 'meta/labels';
import { setOpenQuickAddModal } from 'redux/actions/globalActions';
import { QuickAddModal } from 'utils/constants';
import { constructSelectOptions } from 'utils/constructSelectOptions';

const RateCard = ({ data, saveData }) => {
  const dispatch = useDispatch();
  const user = useSelector(s => s.user);
  const snackbar = useSnackbar();
  const [pricebooks, priceBooksLoading] = usePricebooks(
    user.tenantId,
    user.tenantCompanyId,
    snackbar
  );

  const [customer, loadingCustomer] = useCustomer({ customerId: data.customerId });

  const [selectedPricebook, setSelectedPricebook] = useState();

  const pricebookOptions = useMemo(() => constructSelectOptions(pricebooks, 'name'), [pricebooks]);

  const defaultPricebookId = useSelector(s => s.company?.defaultPriceBookId);

  const closeModal = newPricebook => {
    setSelectedPricebook({ label: newPricebook.name, value: newPricebook.id });
    saveData(oldData => ({
      ...oldData,
      pricebookId: newPricebook.id
    }));
  };

  useEffect(() => {
    if (!loadingCustomer && !data?.pricebookId) {
      if (customer?.priceBookId) {
        saveData(oldData => ({
          ...oldData,
          pricebookId: customer.priceBookId
        }));
      } else if (defaultPricebookId) {
        saveData(oldData => ({
          ...oldData,
          pricebookId: defaultPricebookId
        }));
      }
    }
  }, [customer]);

  return (
    <div>
      <Select
        label={Labels.selectPricebook[user.locale]}
        loading={priceBooksLoading || loadingCustomer}
        menuHeight={100}
        name="name"
        options={pricebookOptions}
        value={
          selectedPricebook ||
          (data.pricebookId && pricebookOptions.find(o => o.value === data.pricebookId))
        }
        onChange={({ label, value }) => {
          setSelectedPricebook({ label, value });
          saveData(oldData => ({
            ...oldData,
            rateCardJSON: JSON.stringify({
              labourPricebookId: value,
              materialMarkupPricebookId: value
            }),
            pricebookId: value
          }));
        }}
      />
      <ThemeProvider>
        <Button
          component="a"
          css={{
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '16px'
          }}
          type={ButtonType.LEADING}
          onClick={() => {
            dispatch(setOpenQuickAddModal(QuickAddModal.CREATE_PRICEBOOK, {}, closeModal));
          }}
        >
          + Create New Pricebook
        </Button>
      </ThemeProvider>
    </div>
  );
};

RateCard.propTypes = {
  data: PropTypes.object.isRequired,
  saveData: PropTypes.func.isRequired
};

RateCard.defaultProps = {};

export default RateCard;
