import React, { useMemo, useState } from 'react';

import { Divider, Button as SergeantButton, ThemeProvider } from '@BuildHero/sergeant';
import { flatten, isEmpty } from 'lodash';

import { Placeholder } from 'components';
import { TimeCardStatusTypes } from 'utils/AppConstants';

import { useReopenTimesheet, useTimesheetPeriodsOfStatus } from '../customHooks';

import { DayCard } from './components/DayCard';
import { generateWeekDataFromBinder, showDivider } from './helpers';

const useStyles = () => ({
  reopenButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

const PendingRevision = ({
  selectedEmployee,
  payrollHourTypes,
  payrollSetting,
  onUpdateDayCard
}) => {
  const styles = useStyles();

  const [dismissedBinderMap, setDismissedBinderMap] = useState({ dismissed: [], undismissed: [] });
  const [currentIndex, setCurrentIndex] = useState();

  const [periods, isLoading] = useTimesheetPeriodsOfStatus({
    employeeId: selectedEmployee?.id,
    manualStatus: TimeCardStatusTypes.DISPUTED
  });

  const [reopenTimesheet, { loading: reopenLoading }] = useReopenTimesheet();

  const weekDates = useMemo(() => {
    if (isEmpty(periods)) return [];

    return flatten(
      periods.map(period => {
        const {
          timesheetEntryBinders: { items: timesheetEntryBinders }
        } = period;

        return generateWeekDataFromBinder(
          timesheetEntryBinders,
          period,
          payrollSetting.timeZone,
          payrollHourTypes,
          [TimeCardStatusTypes.DISPUTED],
          [],
          dismissedBinderMap
        );
      })
    ).filter(d => d.workEvents.length || d.dismissedBinders.length);
  }, [periods, payrollSetting.timeZone, payrollHourTypes, dismissedBinderMap]);

  const handleReopen = startDate => {
    reopenTimesheet({
      employeeId: selectedEmployee.id,
      startDate
    }).then(() => {
      onUpdateDayCard();
    });
  };

  return (
    <div>
      {isLoading && <Placeholder variant="table" />}
      {!isLoading &&
        weekDates.map((day, i) => (
          <ThemeProvider>
            <DayCard
              areBindersEnabled
              day={day}
              key={day.dayStartUTC}
              payrollHourTypes={payrollHourTypes}
              payrollSetting={payrollSetting}
              setDismissedBinderMap={setDismissedBinderMap}
              tab="pendingRevision"
              onUpdateDayCard={onUpdateDayCard}
            >
              <div css={styles.reopenButton}>
                <SergeantButton
                  loading={reopenLoading && i === currentIndex}
                  type="tertiary"
                  onClick={() => {
                    setCurrentIndex(i);
                    handleReopen(day.dayStartUTC);
                  }}
                >
                  Reopen
                </SergeantButton>
              </div>
            </DayCard>
            {showDivider(i, day, weekDates) ? <Divider /> : null}
          </ThemeProvider>
        ))}
      {weekDates.length === 0 && !isLoading && <div style={{ height: 300 }} />}
    </div>
  );
};

export default PendingRevision;
