import isNumber from 'lodash/isNumber';

import { findAddressByAddressType, getCombinedAddress } from 'utils';
import { DisplayName, PathName } from 'utils/constants';

export const selectBillingCustomerId = job => job?.billingCustomer?.id;

export const selectBillingCustomerName = job => job?.billingCustomer?.customerName;

export const selectCustomerId = job => job?.customer?.id;

export const selectCustomerName = job => job?.customer?.customerName;

export const selectPropertyId = job => job?.customerProperty?.id;

export const selectPropertyName = job => job?.customerProperty?.companyName;

export const selectCustomerPropertyAddress = job =>
  getCombinedAddress(
    findAddressByAddressType(job?.customerProperty?.companyAddresses?.items, 'propertyAddress')
  );

// use slice.reverse to take the last address in the list - just like v1
const getCustomerBillingAddress = job =>
  findAddressByAddressType(
    job?.billingCustomer?.companyAddresses?.items?.slice().reverse(),
    'billingAddress'
  );

export const selectCustomerBillingAddress = job =>
  getCombinedAddress(getCustomerBillingAddress(job));

export const selectBillTo = job => job?.customerProperty?.billTo;

export const selectProjectManager = job => job?.owner?.name;

export const selectJobType = job => job?.jobTypeName;

export const selectTotalBudgetedHours = job => job?.totalBudgetedHours;

export const selectTotalUsedHours = job =>
  isNumber(job?.totalUsedTime) ? (job.totalUsedTime / 3600).toFixed(2) : null;

export const selectJobDescription = job => job?.issueDescription;

export const selectJobTitle = job => {
  if (job) {
    return `${
      job?.jobTypeInternal === 'Maintenance' ? DisplayName.MAINTENANCE : DisplayName.JOB
    } ${job?.customIdentifier || job?.jobNumber || ''}`;
  }
};

export const selectJobPath = job => {
  if (job) {
    return job.jobTypeInternal === 'Maintenance'
      ? `/${PathName.MAINTENANCE}/view/${encodeURIComponent(job.jobNumber || '')}`
      : `/${PathName.JOB}/view/${encodeURIComponent(job.jobNumber || '')}`;
  }
};
export const selectJobPriceBookId = job => job?.priceBookId;
