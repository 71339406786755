import React from 'react';

import { func } from 'prop-types';
import { useSelector } from 'react-redux';

import { AlgoliaSearch } from 'components';
import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';

const VisitSearchHeader = ({ onVisitSelected }) => {
  const isCustomJobNumberEnabled = useSelector(s => s.settings?.job_customJobNumber);

  return (
    <AlgoliaSearch
      customHitFormatter={hit =>
        `Job ${isCustomJobNumberEnabled ? hit?.customIdentifier : hit?.jobNumber} - Visit ${
          hit?.visitNumber
        }`
      }
      delimiter=" - "
      displayText={['customIdentifier', 'jobNumber', 'visitNumber']}
      filters={[
        {
          attribute: 'entityType',
          valueArray: ['Visit']
        }
      ]}
      handleChange={onVisitSelected}
      isRequired
      locatedInPopUp
      name="selectVisit"
      placeholder="Search Visits"
      searchIndex={defaultSearchIndex}
      value=""
    />
  );
};

VisitSearchHeader.propTypes = {
  onVisitSelected: func.isRequired
};

export default VisitSearchHeader;
