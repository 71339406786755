import React from 'react';

import Placeholder from './Placeholder';

const NoProperties = () => (
  <Placeholder
    height={200}
    subtitle="A property must be selected before adding Property Checklists."
    subtitleWidth={236}
    title="No Properties"
  />
);

export default NoProperties;
