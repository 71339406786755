import React, { Component } from 'react';

import MomentUtils from '@date-io/moment';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';

import ErrorBoundaries from 'scenes/Error';

import AppConstants from '../../utils/AppConstants';

import styles from './styles';

/**
 * Renders Material UI datepicker with BuildHero styles
 */
const DatePickerField = ({ value, handleChange, name, errors, handleError, ...other }) => {
  const handleDateChange = date => {
    const formattedDate = date && date.isValid() && date.format(AppConstants.DATE_FORMAT);
    handleChange(formattedDate);
  };
  let fieldValue;
  if (value && moment(value).isValid()) {
    fieldValue = moment(value);
  } else if (value) {
    fieldValue = moment(new Date(value));
  } else {
    fieldValue = null;
  }

  return (
    <ErrorBoundaries>
      <KeyboardDatePicker
        allowKeyboardControl
        autoOk
        clearable="true"
        disableToolbar
        error={!!errors}
        format={AppConstants.DATE_FORMAT}
        fullWidth
        helperText={errors || ''}
        inputVariant="filled"
        invalidLabel=""
        name={name}
        value={fieldValue}
        variant="inline"
        onChange={handleDateChange}
        onError={error => handleError(name, error)}
        //  mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
        {...other}
      />
    </ErrorBoundaries>
  );
};

class DatePickers extends Component {
  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePickerField {...this.props} className="dateField" />
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(DatePickers);
