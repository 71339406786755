import React, { useCallback, useMemo, useState } from 'react';

import { Button, ButtonType, SgtForm, ThemeProvider } from '@BuildHero/sergeant';
import { useParams } from 'react-router-dom';

import { Spinner } from 'components';

import ErrorBoundary from 'scenes/Error';

import { TenantTabs } from './components';
import { getConfiguration, Layouts } from './Tenant.config';
import { formatDataForServer, useTenantMutation, useTenantQuery } from './Tenant.gql';

const Tenant = ({ auth }) => {
  document.title = 'BuildOps - Tenant';
  const [layout, setLayout] = useState(Layouts.VIEW);
  const isEditing = layout === Layouts.EDIT;
  const [formService, setFormService] = useState(null);

  const { id } = useParams();
  const { data, setData, loading } = useTenantQuery(id);
  const [mutate, { loading: mutationLoading }] = useTenantMutation(updatedTenant => {
    setData(updatedTenant);
    formService.formikContext.setValues(updatedTenant);
    setLayout(Layouts.VIEW);
  });

  const configuration = useMemo(() => getConfiguration(auth), [auth]);

  const handleSave = useCallback(
    async newData => {
      mutate(formatDataForServer(newData));
    },
    [mutate]
  );

  if (loading) return <Spinner />;

  return (
    <ErrorBoundary>
      <ThemeProvider>
        <div css={{ position: 'fixed', right: 24, zIndex: 10 }}>
          {isEditing && (
            <Button
              css={{ marginRight: 8 }}
              disabled={mutationLoading || loading}
              type={ButtonType.TERTIARY}
              onClick={() => {
                formService.formikContext.setValues(data);
                setLayout(Layouts.VIEW);
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            css={{ minWidth: 100 }}
            loading={mutationLoading || loading}
            type={isEditing ? ButtonType.PRIMARY : ButtonType.TERTIARY}
            onClick={() => {
              if (isEditing) {
                // save
                formService.formikContext.handleSubmit();
              } else {
                setLayout(Layouts.EDIT);
              }
            }}
          >
            {isEditing ? 'Save' : 'Edit'}
          </Button>
        </div>
        <SgtForm
          configuration={configuration}
          customComponents={{ TenantTabs }}
          formikProps={{
            validateOnChange: false,
            validateOnBlur: true
          }}
          initialValues={data}
          layout={layout}
          onCreateService={setFormService}
          onSubmit={handleSave}
        />
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default Tenant;
