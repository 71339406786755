import React, { Component } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import styles from './styles';
/**
 * Materail UI text input with BuildHero styles
 */
class PriceTagMuiTextInput extends Component {
  handleOnChange = event => {
    const currentValue = event.target.value;
    const { field, form, specialbehaviour } = this.props;
    const { dependentField, dependentValue } = specialbehaviour;
    if (specialbehaviour && dependentField && form.values[dependentField] === 'true') {
      const newValues = {
        ...form.values,
        [field.name]: currentValue,
        [dependentValue]: currentValue
      };
      form.setValues(newValues);
    } else {
      form.setFieldValue(field.name, currentValue, false);
    }
  };

  render() {
    const { field, form, classes, user, ...rest } = this.props;
    const { specialbehaviour } = rest;
    const {
      queryResult,
      queryPath,
      dependentFieldName,
      dependentQueryPath,
      dependentReferenceKey,
      disableFieldWhen,
      disableFieldValue
    } = specialbehaviour;

    // if (
    //   specialbehaviour &&
    //   specialbehaviour.dependentField &&
    //   form.values[specialbehaviour.dependentField] === 'true'
    // ) {
    //   const dependentValue = form.values[specialbehaviour.dependentValue];
    //   if (field.value !== dependentValue) {
    //     form.setFieldValue(field.name, form.values[specialbehaviour.dependentValue], false);
    //   }
    // }

    // for getting values from a dependent field selection and based on selecetion populate value from queryResult
    if (
      dependentQueryPath &&
      dependentFieldName &&
      dependentReferenceKey &&
      form.values[dependentFieldName] &&
      form.values[dependentFieldName] !== ''
    ) {
      let localResult = queryResult;
      let displayDataValue = null;
      let dependentArray = null;
      const localDependentQueryPathSplit = dependentQueryPath.split('.');

      localDependentQueryPathSplit.forEach(element => {
        if (dependentArray === null) dependentArray = queryResult[element];
        else dependentArray = dependentArray[element];
      });
      localResult =
        dependentArray &&
        dependentArray.filter(
          item => item[dependentReferenceKey] === form.values[dependentFieldName]
        );

      // Choosing only the first element in the array after filtering
      if (localResult) {
        [localResult] = localResult;
      }

      if (localResult) {
        const fieldPath = queryPath.split('.');
        fieldPath.forEach(element => {
          if (displayDataValue === null) displayDataValue = localResult[element];
          else displayDataValue = displayDataValue[element];
        });

        if (
          field.value !== displayDataValue &&
          (field.value === '' || !specialbehaviour.allowEdit)
        ) {
          form.setFieldValue(field.name, displayDataValue, false);
        }
      }
    }

    const helperText =
      form.errors && form.errors[field.name] ? form.errors && form.errors[field.name] : ' ';

    let isReadOnly = false;
    if (
      disableFieldWhen &&
      disableFieldValue &&
      disableFieldValue.includes(form.values[disableFieldWhen])
    ) {
      isReadOnly = true;
    }

    return (
      <TextField
        className={classes.textField}
        disabled={isReadOnly}
        error={form.errors && form.errors[field.name] && form.errors[field.name] !== ''}
        fullWidth
        helperText={helperText}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography className={classes.adormentRoot}>$</Typography>
            </InputAdornment>
          )
        }}
        name={field.name}
        value={field.value}
        variant="filled"
        onChange={this.handleOnChange}
        {...rest}
      />
    );
  }
}

export default withStyles(styles)(PriceTagMuiTextInput);
