/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect, useMemo, useState } from 'react';

import { InlineAlert, InlineAlertTypes, Select, Switch, ThemeProvider } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';

import * as R from 'ramda';
import { useParams } from 'react-router-dom';

import { useDepartments } from '@dispatch/queries';
import WrapTable from 'components/WrapTable';
import { useQueryString } from 'utils/queryString';

import toFixedNumber from '../../../../../utils/toFixedNumber';
import { useScheduleOfValueLinesByProject } from '../queries/useScheduleOfValueLinesByProject';

import { useProjectFinanceTableColumns } from './ProjectFinanceTable.columns';
import {
  GROUP_BY_COST_CODE,
  GROUP_BY_COST_TYPE,
  GROUP_BY_COST_TYPE_AND_PHASE,
  GROUP_BY_COST_TYPE_ONLY,
  GROUP_BY_PHASE,
  GROUP_BY_PHASE_COST_TYPE,
  ProjectFinanceTableView
} from './ProjectFinanceTable.constants';

import { getOriginalContractTotal } from './ProjectFinanceTable.getters';
import { useDepartmentsValueSet, useFilteredData } from './ProjectFinanceTable.hooks';

import { useProjectFinanceData } from './ProjectFinanceTable.queries';
import { useStyles } from './ProjectFinanceTable.styles';

const multiCheck = (prop, data) =>
  !!(R.pipe(R.pluck(prop), R.reject(R.isNil), R.uniq, R.length)(data) > 1);
const isMultiPhase = data => multiCheck('projectPhaseId', data);
const isMultiCostCode = data => multiCheck('projectPhaseDepartmentCostCodeId', data);

const getDefaultGrouping = (view, data) => {
  if (view === ProjectFinanceTableView.REVENUE_BUDGET) {
    if (isMultiPhase(data)) {
      return GROUP_BY_PHASE_COST_TYPE;
    }
    if (isMultiCostCode(data)) {
      return GROUP_BY_COST_TYPE;
    }
    return GROUP_BY_COST_TYPE_ONLY;
  }

  if (isMultiPhase(data)) {
    return GROUP_BY_PHASE;
  }

  // If one phase, multiple cost codes - Group by CC on Budget/Labor Report, Group by Cost Type on Revenue
  if (isMultiCostCode(data)) {
    return GROUP_BY_COST_CODE;
  }

  // If one phase, one cost code - Group by Cost Type on Budget, Group by Phase on Labor Report
  return view === ProjectFinanceTableView.LABOR_REPORT ? GROUP_BY_PHASE : GROUP_BY_COST_TYPE;
};

const sovTotal = R.pipe(R.pluck('contractValue'), R.sum);

const ProjectFinanceTable = ({ view }) => {
  const {
    data: { debug }
  } = useQueryString();

  const { projectId } = useParams();
  const styles = useStyles();
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const projectFinanceDataResponse = useProjectFinanceData(projectId, {
    fetchPolicy: 'cache-and-network'
  });
  const departmentsResponse = useDepartments();
  const departmentsValueSet = useDepartmentsValueSet(
    departmentsResponse.data,
    projectFinanceDataResponse.data
  );
  const filteredData = useFilteredData(projectFinanceDataResponse.data, selectedDepartments, view);
  const DEFAULT_GROUPING = useMemo(
    () => getDefaultGrouping(view, projectFinanceDataResponse.data),
    [view, projectFinanceDataResponse.data]
  );

  const [rowGroupingModel, setRowGroupingModel] = useState(null);
  const [isGroupedByCostCode, setIsGroupedByCostCode] = useState(false);
  const [isGroupedByCostType, setIsGroupedByCostType] = useState(false);
  const [revWarning, setRevWarning] = useState(false);
  const projectFinanceColumns = useProjectFinanceTableColumns({ debug, view });
  const sovResponse = useScheduleOfValueLinesByProject(projectId);
  useEffect(() => {
    if (
      !sovResponse.loading &&
      sovResponse.data?.length &&
      projectFinanceDataResponse.data?.length
    ) {
      const totalSov = sovTotal(sovResponse.data);
      const totalContract = getOriginalContractTotal({ rows: projectFinanceDataResponse.data });
      setRevWarning(toFixedNumber(totalSov) !== toFixedNumber(totalContract));
    }
  }, [sovResponse, projectFinanceDataResponse.data]);

  useEffect(() => {
    if (projectFinanceDataResponse.data?.length) {
      setRowGroupingModel(DEFAULT_GROUPING);

      if (!isMultiPhase(projectFinanceDataResponse.data)) {
        setIsGroupedByCostCode(DEFAULT_GROUPING === GROUP_BY_COST_CODE);
        setIsGroupedByCostType(
          DEFAULT_GROUPING === GROUP_BY_COST_TYPE ||
            DEFAULT_GROUPING === GROUP_BY_COST_TYPE_AND_PHASE ||
            DEFAULT_GROUPING === GROUP_BY_COST_TYPE_ONLY
        );
      }
    }
  }, [DEFAULT_GROUPING, projectFinanceDataResponse.data]);

  useEffect(() => {
    if (isGroupedByCostCode) {
      setIsGroupedByCostType(false);
      setRowGroupingModel(GROUP_BY_COST_CODE);
    } else if (!isGroupedByCostType) {
      setRowGroupingModel(DEFAULT_GROUPING);
    }
  }, [DEFAULT_GROUPING, isGroupedByCostCode, isGroupedByCostType]);

  useEffect(() => {
    if (isGroupedByCostType) {
      setIsGroupedByCostCode(false);
      if (view === ProjectFinanceTableView.REVENUE_BUDGET) {
        setRowGroupingModel(GROUP_BY_COST_TYPE_AND_PHASE);
      } else {
        setRowGroupingModel(GROUP_BY_COST_TYPE);
      }
    } else if (!isGroupedByCostCode) {
      setRowGroupingModel(DEFAULT_GROUPING);
    }
  }, [DEFAULT_GROUPING, isGroupedByCostCode, isGroupedByCostType, view]);

  return (
    <ThemeProvider>
      <div css={styles.root}>
        {revWarning && view === ProjectFinanceTableView.REVENUE_BUDGET && (
          <div css={styles.warn}>
            <InlineAlert
              message="Revenue Estimates do not match the Schedule of Values. For the most accurate Revenue calculations, please go to Project Settings / Budget / Edit Phase to update."
              type={InlineAlertTypes.YELLOW}
            />
          </div>
        )}
        <div css={styles.tableControls}>
          <Select
            css={styles.departmentSelect}
            multi
            options={departmentsValueSet}
            placeholder="Filter by Departments"
            value={selectedDepartments}
            onChange={setSelectedDepartments}
          />
          <div css={styles.switchContainer}>
            {view !== ProjectFinanceTableView.LABOR_REPORT && (
              <div css={styles.switch}>
                <Switch
                  checked={isGroupedByCostType}
                  label="Group By Cost Type"
                  onClick={() => setIsGroupedByCostType(!isGroupedByCostType)}
                />
              </div>
            )}
            {view !== ProjectFinanceTableView.REVENUE_BUDGET && (
              <div css={styles.switch}>
                <Switch
                  checked={isGroupedByCostCode}
                  label="Group By Cost Code"
                  onClick={() => setIsGroupedByCostCode(!isGroupedByCostCode)}
                />
              </div>
            )}
          </div>
        </div>
        <WrapTable
          columns={projectFinanceColumns}
          enableTotalsRow
          error={projectFinanceDataResponse.error}
          loading={projectFinanceDataResponse.loading}
          noWrap
          rowGroupingModel={rowGroupingModel}
          rows={filteredData}
        />
      </div>
    </ThemeProvider>
  );
};

ProjectFinanceTable.propTypes = {
  view: PropTypes.string.isRequired
};

export default ProjectFinanceTable;
