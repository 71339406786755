import React from 'react';

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import { AttachmentOptions } from '../../AddAttachmentStep.constants';

import { useStyles } from './AttachmentOptionsRadioGroup.styles';

const AttachmentOptionsRadioGroup = props => {
  const { value, onChangeAttachmentOption } = props;
  const styles = useStyles();

  return (
    <RadioGroup css={styles.radioGroup} row value={value} onChange={onChangeAttachmentOption}>
      <FormControlLabel
        control={<Radio color="primary" size="small" />}
        css={styles.radioButton}
        label="Combine into one PDF"
        value={AttachmentOptions.COMBINE}
      />
      <FormControlLabel
        control={<Radio color="primary" size="small" />}
        css={styles.radioButton}
        label="Attach as individual files"
        value={AttachmentOptions.SEPARATE}
      />
    </RadioGroup>
  );
};

export default AttachmentOptionsRadioGroup;
