import React from 'react';

import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import styles from './styles';

/* considered as one off component while developing */

function PreferredContact(props) {
  const { form, classes } = props;
  const { values } = form;

  const handleChange = (fieldName, value) => {
    const newValue = { ...values, [fieldName]: value };
    form.setValues(newValue);
  };

  const handlePrimaryContact = event => {
    const newValue = { ...values, bestContact: event.target.value };
    form.setValues(newValue);
  };
  return (
    <>
      <FormControl>
        <RadioGroup
          aria-label="primaryContact"
          name="primaryContact"
          value={values.bestContact}
          onChange={handlePrimaryContact}
        >
          <Grid className={classes.gridContainer} container>
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
              <Typography className={classes.label} variant="caption">
                Please add your contact information and indicate your preferred method of contact
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.gridContainer} container>
            <Grid item>
              <TextField
                className={classes.textField}
                error={form.errors && form.errors.email && form.errors.email !== ''}
                fullWidth
                helperText={form.errors.email}
                label="Email address"
                name="email"
                value={values.email}
                variant="filled"
                onChange={event => handleChange('email', event.target.value)}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                className={classes.radioboxLabel}
                control={<Radio />}
                label="Primary contact method"
                value="Email"
              />
            </Grid>
          </Grid>
          <Grid className={classes.gridContainer} container>
            <Grid item>
              <TextField
                className={classes.textField}
                error={form.errors && form.errors.landlinePhone && form.errors.landlinePhone !== ''}
                fullWidth
                helperText={form.errors.landlinePhone}
                label="Landline"
                name="landlinePhone"
                value={values.landlinePhone}
                variant="filled"
                onChange={event => handleChange('landlinePhone', event.target.value)}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                className={classes.radioboxLabel}
                control={<Radio />}
                label="Primary contact method"
                value="Landline"
              />
            </Grid>
          </Grid>
          <Grid className={classes.gridContainer} container>
            <Grid item>
              <TextField
                className={classes.textField}
                error={form.errors && form.errors.cellPhone && form.errors.cellPhone !== ''}
                fullWidth
                helperText={form.errors.cellPhone}
                label="Cell phone"
                name="cellPhone"
                value={values.cellPhone}
                variant="filled"
                onChange={event => handleChange('cellPhone', event.target.value)}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                className={classes.radioboxLabel}
                control={<Radio />}
                label="Primary contact method"
                value="Cell phone"
              />
            </Grid>
          </Grid>
          <Grid item>
            <FormControlLabel
              className={classes.checkboxLabel}
              control={
                <Checkbox
                  checked={values.isSmsOptOut}
                  onChange={event => handleChange('isSmsOptOut', event.target.checked)}
                />
              }
              label="SMS notifications?"
              labelPlacement="start"
            />
          </Grid>
        </RadioGroup>
      </FormControl>
    </>
  );
}

const styledPreferredContact = withStyles(styles)(PreferredContact);
export default styledPreferredContact;
