import React from 'react';

import { Switch, ThemeProvider } from '@BuildHero/sergeant';

const CreateMultipleEventsSwitch = record => {
  return (
    <ThemeProvider>
      <Switch
        checked={record.options?.checked}
        disabled={record.options?.disabled}
        horizontalLabel
        label={record.options?.label}
        onChange={record.options?.onChange}
      />
    </ThemeProvider>
  );
};

export default CreateMultipleEventsSwitch;
