import React, { useMemo } from 'react';

import { Button, ThemeProvider } from '@BuildHero/sergeant';
import { Grid } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import { ConfirmLeave } from 'components';
import useAutosaveManager from 'customHooks/useAutosaveManager';
import { snackbarOn } from 'redux/actions/globalActions';

import ErrorBoundaries from 'scenes/Error';
import { logErrorWithCallback } from 'utils';

import { Mode, NetworkStatuses, PricingStrategy } from 'utils/constants';

import { updateTasksAndGroups } from '../TaskGroups/TaskGroup.utils';

import {
  formatLaborLineItemBeforeSaving,
  formatLaborLineItemsForDisplay
} from './LaborModal/LaborModal.utils';
import TableView from './SelectAndTable/TableView/TableView';

function LaborSelect(props) {
  const {
    deleteLaborLineItem,
    laborRates,
    task,
    setIsLaborModalOpen,
    updateLaborLineItems,
    options,
    quoteInfo,
    openEditLaborModal
  } = props;
  const {
    isReadOnly,
    taskGroupAndSet: [taskGroupAndSet],
    config: [config]
  } = options;
  const isMarginEnabled = useSelector(s => s.settings.pricingStrategy === PricingStrategy.MARGIN);
  const { taskGroups, setTaskGroups, groupIndex, taskIndex } = taskGroupAndSet;
  const laborItems = formatLaborLineItemsForDisplay(
    taskGroups[groupIndex].tasks[taskIndex]?.quoteLineLabors.items,
    laborRates,
    isMarginEnabled
  );

  const { autosave, status, setHasTempData, confirmLeave } = useAutosaveManager({
    update: updateLaborLineItems,
    initialVersion: quoteInfo.version,
    entityName: 'labor line item',
    destructureUpdateResult: result => result?.quoteTransition,
    snackbarOn: props.snackbarOn
  });

  const blockInteractions = useMemo(() => status === NetworkStatuses.RETRYING, [status]);

  const handleDelete = async (laborLineItem, onCompleteCallback) => {
    try {
      const response = await deleteLaborLineItem(laborLineItem.id);
      if (response) {
        updateTasksAndGroups({
          groups: response.quoteTransition.quoteTaskGroups.items,
          tasks: response.quoteTransition.quoteLineTasks.items,
          setTaskGroups
        });
      }
    } catch (error) {
      logErrorWithCallback(
        error,
        props.snackbarOn,
        'Unable to delete labor item, please try again later'
      );
    } finally {
      onCompleteCallback(); // to stop spinner
    }
  };

  const handleFieldChange = async (value, field, item) => {
    setHasTempData(true);
    try {
      const laborLineItem = formatLaborLineItemBeforeSaving(
        { ...item, [field]: value },
        isMarginEnabled
      );
      if (blockInteractions) return null;
      const response = await autosave({
        changes: { laborLineItemArr: [laborLineItem], taskId: task.id }
      });
      if (response) {
        updateTasksAndGroups({
          groups: response.quoteTaskGroups.items,
          tasks: response.quoteLineTasks.items,
          setTaskGroups
        });
      }
    } catch (error) {
      logErrorWithCallback(
        error,
        props.snackbarOn,
        'Unable to update labor item, please try again later'
      );
    }
  };

  return (
    <ErrorBoundaries>
      <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
        <ConfirmLeave when={confirmLeave} />
        <TableView
          config={config}
          data={laborItems}
          entityName="Labor Line Item"
          handleChange={handleFieldChange}
          handleDelete={handleDelete}
          isReadOnly={isReadOnly}
          labels={{
            title: 'Labor',
            name: 'LABOR TYPE',
            total: 'LABOR TOTAL'
          }}
          openEditLaborModal={openEditLaborModal}
          status={status}
        />
        <ThemeProvider>
          <Button
            disabled={isReadOnly}
            startIcon={<AddCircleOutlineIcon />}
            type="leading"
            onClick={() => {
              setIsLaborModalOpen({
                isOpen: true,
                type: Mode.ADD
              });
            }}
          >
            Add Labor to Task
          </Button>
        </ThemeProvider>
      </Grid>
    </ErrorBoundaries>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedLaborSelect = connect(
  mapStateToProps,
  mapDispatcherToProps
)(withTheme(LaborSelect));

export default reduxConnectedLaborSelect;

LaborSelect.propTypes = {
  deleteLaborLineItem: PropTypes.func,
  isReadOnly: PropTypes.bool,
  laborRates: PropTypes.array,
  options: PropTypes.object,
  setIsLaborModalOpen: PropTypes.func,
  task: PropTypes.object,
  quoteInfo: PropTypes.object,
  updateLaborLineItems: PropTypes.func,
  openEditLaborModal: PropTypes.func
};

LaborSelect.defaultProps = {
  deleteLaborLineItem: () => {},
  isReadOnly: false,
  laborRates: [],
  options: {},
  setIsLaborModalOpen: () => {},
  task: {},
  quoteInfo: {},
  updateLaborLineItems: [],
  openEditLaborModal: () => {}
};
