import React, { useEffect, useState } from 'react';

import {
  Button,
  ButtonSize,
  ButtonType,
  MUIForm,
  ThemeProvider,
  ThumbnailSize
} from '@BuildHero/sergeant';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import FullScreenModal from 'components/FullScreenModal';
import ResponsiveTable from 'components/ResponsiveTable';
import Labels from 'meta/labels';
import {
  generateReceiptModalFormFields,
  generateReceiptModalFormLayout
} from 'meta/Procurement/PurchaseOrders/generateReceiptModalForm';
import poGenerateReceiptListTableMeta from 'meta/Procurement/PurchaseOrders/poGenerateReceiptListTableMeta';
import { snackbarOn } from 'redux/actions/globalActions';
import EntityImagesCarousel from 'scenes/Procurement/component/EntityImagesCarousel';

import {
  AttachmentType,
  OrderQtyStatus,
  ReceiptAttachmentType
} from 'scenes/Procurement/constants';
import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';
import buildHeroMuiFormOverrides from 'scenes/ProjectManagement/components/buildHeroMuiFormOverrides';
import CustomFieldWithLabel from 'scenes/ProjectManagement/components/CustomFieldWithLabel';
import { generateDefaultValidationSchema } from 'scenes/ProjectManagement/components/formattingUtils';
import { getCloudinaryImageUrl } from 'scenes/ProjectManagement/components/utils';
import { getLinesByPurchaseOrder } from 'services/API/purchaseOrderLine';
import { parseFloatAndRound, roundCurrency } from 'utils';
import { VendorApprovalStatus } from 'utils/AppConstants';
import { PaymentTermType } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

const useStyles = makeStyles(theme => ({
  contentContainer: {
    position: 'absolute',
    display: 'flex',
    left: '0px',
    right: '0px',
    top: '60px',
    padding: '40px 24px',
    flexWrap: 'nowrap'
  },
  customFormContainer: {
    margin: '4px 0px',
    paddingLeft: 4,
    background: '#F0F0F0',
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
      marginTop: 0
    },
    '& .MuiTypography-colorTextSecondary': {
      color: '#333333',
      fontSize: 14
    },
    '& .MuiInputBase-inputMarginDense': {
      fontSize: 14,
      color: '#333333',
      lineHeight: '16px',
      paddingTop: 8,
      paddingBottom: 8
    }
  },
  label: {
    fontSize: 10,
    letterSpacing: 0.01,
    fontWeight: 'normal',
    textTransform: 'uppercase',
    lineHeight: '14px',
    marginBottom: '0.35em',
    display: 'block'
  },
  topMenuContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 16
  },
  formContainer: buildHeroMuiFormOverrides(theme),
  imageCarousel: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '16px',
    maxWidth: '219px'
  },
  rightContainer: {
    overflow: 'auto'
  }
}));

const GenerateReceiptModal = props => {
  const classes = useStyles();
  const flags = useFlags();
  const isVendorInvoiceOn = flags[FeatureFlags.VENDOR_INVOICE_APPROVAL];
  const { receiptNumber, open, user, onSubmit, handleClose, poId } = props;
  const company = useSelector(state => state.company);
  const defaultPaymentTermId =
    company.defaultPaymentTerm?.type === PaymentTermType.BOTH ||
    company.defaultPaymentTerm?.type === PaymentTermType.PURCHASING
      ? company.defaultPaymentTermId
      : null;

  const isVistaEnabled = useSelector(state => state.settings.isVistaEnabled);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [canSave, setCanSave] = useState(false);
  const [generateReceiptData, setGenerateReceiptData] = useState({
    vendorDocumentNumber: null,
    issuedBy: moment.utc(moment().format('L')).unix(),
    paymentTermId: defaultPaymentTermId,
    invoiceAmount: null,
    approvalStatus: VendorApprovalStatus.UNREVIEWED
  });
  const [receiptImages, setReceiptImages] = useState([]);

  const getCalulatedOutstanding = (quantity, received) => {
    let outstanding = 0;

    if (quantity >= 0) {
      outstanding = Math.max(0, parseFloatAndRound(quantity - received, 2));
    } else {
      outstanding = Math.min(0, parseFloatAndRound(quantity - received, 2));
    }

    return outstanding;
  };

  useEffect(() => {
    const selectedIds = selectedItems.map(item => item.id);
    setCanSave(
      isRowSelected &&
        orderItems.some(
          item => selectedIds.includes(item.id) && Number(item.quantityFulfilled) !== 0
        ) &&
        generateReceiptData.issuedBy &&
        generateReceiptData.paymentTermId
    );
  }, [
    isRowSelected,
    orderItems,
    selectedItems,
    generateReceiptData.issuedBy,
    generateReceiptData.paymentTermId
  ]);

  useEffect(() => {
    if (!poId) return;

    setIsSubmitting(false);
    getLinesByPurchaseOrder(poId).then(data => {
      const mappedData = data.map(item => ({
        ...item,
        originalQtyFulfilled: item.quantityFulfilled || 0,
        quantityFulfilled: 0,
        quantityOutstanding: getCalulatedOutstanding(
          Number(item.quantity || 0),
          Number(item.quantityFulfilled || 0)
        )
      }));
      setOrderItems(mappedData.sort((a, b) => a.lineNumber - b.lineNumber));
    });
  }, [poId, open]);

  const getFormattedData = data => {
    return data.map((item, index) => {
      const safeQty = Number(item.quantityFulfilled);
      const safePOQty = Number(item.originalQtyFulfilled);

      const formattedItem = {
        ...item,
        id: item?.id || index,
        lineNumber: item?.lineNumber || '',
        itemName: item?.itemName || '',
        description: item?.description || '-',
        departmentId: item?.departmentId || '',
        productId: item?.productId || '',
        departmentName: item?.departmentName || '-',
        unitOfMeasure: item?.unitOfMeasure || '-',
        unitCost: item?.unitCost || 0,
        quantity: Number(item?.quantity || 0),
        totalCost:
          roundCurrency(Number(item?.unitCost ?? 0) * Number(item?.quantityFulfilled ?? 0)) || 0,
        quantityFulfilled: Number(item?.quantityFulfilled || 0),
        quantityOutstanding:
          item?.quantityOutstanding === undefined
            ? Number(item.quantity ?? 0) - (safeQty + safePOQty)
            : Number(item?.quantityOutstanding)
      };

      // eslint-disable-next-line no-use-before-define
      const newStatus = getUpdatedStatus(
        formattedItem?.quantity,
        formattedItem?.quantityOutstanding
      );
      if (newStatus !== item.status) {
        setOrderItems(
          orderItems.map(i =>
            i.lineNumber === item.lineNumber ? { ...item, status: newStatus } : i
          )
        );
      }

      return {
        ...formattedItem,
        status: newStatus
      };
    });
  };

  const getUpdatedStatus = (total, outstanding) => {
    // the logic should be:
    // 1) if outstanding qty is equal to 0, then qty status is fulfilled
    // 2) if outstanding qty is equal to total qty, status is unfulfilled
    // 3) if total qty is negative and oustanding qty bigger than total, status is partially fulfilled
    // 4) if total qty is positive and outstanding qty is less than total qty, status is partially fulfilled
    // 5) if total qty is positive and outstanding qty is more than total qty, status is fulfilled
    // 6) if total qty is negative and outstanding qty is less than total qty, status is fulfilled
    if (outstanding !== 0) {
      if (outstanding === total) return OrderQtyStatus.UNFULFILLED;
      if ((total < 0 && outstanding > total) || (total > 0 && outstanding < total)) {
        return OrderQtyStatus.PARTIALLY_FULFILLED;
      }
      return OrderQtyStatus.UNFULFILLED;
    }
    return OrderQtyStatus.FULFILLED;
  };

  const updateTableData = (rowRecord, value) => {
    let newQty;
    if (Number.isNaN(value)) {
      newQty = 0;
    } else {
      newQty = value;
    }

    const updatedData = { ...rowRecord };
    updatedData.quantityFulfilled = newQty;
    const safeQty = Number(newQty);
    const safePOQty = Number(updatedData.originalQtyFulfilled);
    updatedData.quantityOutstanding = getCalulatedOutstanding(
      Number(rowRecord.quantity),
      safeQty + safePOQty
    );
    updatedData.status = getUpdatedStatus(
      rowRecord.quantity - safePOQty,
      updatedData.quantityOutstanding
    );
    setOrderItems([...orderItems.map(item => (item.id === updatedData.id ? updatedData : item))]);
  };

  const handleReceiveAll = () => {
    setOrderItems([
      ...orderItems.map(item => {
        const selectedItem = selectedItems.find(value => value.id === item.id);
        if (selectedItem) {
          const safePOQty = Number(selectedItem.originalQtyFulfilled);
          return {
            ...item,
            quantityFulfilled: Number(item.quantity || 0) - safePOQty,
            quantityOutstanding: 0,
            status: OrderQtyStatus.FULFILLED
          };
        }
        return item;
      })
    ]);
  };

  const handleSelectedItem = (mode, items) => {
    if (mode === 'select') {
      setSelectedItems(items);
      if (items.length) {
        setIsRowSelected(true);
      } else {
        setIsRowSelected(false);
      }
    }
  };

  const handleGenerateReceipt = async () => {
    setIsSubmitting(true);
    const selectedIds = selectedItems.map(item => item.id);
    await onSubmit({
      vendorDocumentNumber: generateReceiptData.vendorDocumentNumber,
      issuedBy: generateReceiptData.issuedBy,
      paymentTermId: generateReceiptData.paymentTermId,
      lines: orderItems
        .filter(item => selectedIds.includes(item.id) && item.quantityFulfilled !== 0)
        .map(line => ({ ...line, originalQtyFulfilled: undefined })),
      receiptImages,
      approvalStatus: generateReceiptData.approvalStatus,
      invoiceAmount: generateReceiptData.invoiceAmount
    });
    setIsSubmitting(false);
  };

  const handleGenerateReceiptModalFormChange = data => {
    setGenerateReceiptData({
      ...data,
      paymentTermId: data?.paymentTerm?.id
    });
  };

  const CustomInputComponent = valueProps => {
    const { record, meta } = valueProps;
    const [receivedQty, setReceivedQty] = useState(record.quantityFulfilled || 0);

    return (
      <FormControl className={classes.customFormContainer} fullWidth>
        <InputBase
          disabled={!selectedItems.find(item => item.id === record.id)}
          inputProps={{
            min: -5000000,
            max: 5000000
          }}
          name={meta.id}
          type="number"
          value={receivedQty}
          onBlur={event => {
            const value = Number(event.target.value);
            updateTableData(record, value);
          }}
          onChange={event => {
            const value = Number(event.target.value);
            record.totalCost = value * record.unitCost;
            setReceivedQty(value);
          }}
          onKeyUp={event => {
            if (event.key === 'Enter') {
              const value = Number(event.target.value);
              updateTableData(record, value);
            }
          }}
        />
      </FormControl>
    );
  };

  const handleSetReceiptData = record => {
    if (
      record?.receiptAttachmentType === ReceiptAttachmentType.VENDOR_INVOICE ||
      record?.type === AttachmentType.vendorInvoice
    ) {
      setGenerateReceiptData({
        ...generateReceiptData,
        invoiceAmount: record?.invoiceAmount,
        vendorDocumentNumber: record?.vendorDocumentNumber,
        approvalStatus: record?.approvalStatus
      });
    }
  };

  const handleSaveReceiptImage = (_, record) => {
    setReceiptImages([...receiptImages, record]);
    handleSetReceiptData(record);
  };

  const handleEditReceiptImage = (_, record, index) => {
    receiptImages[index] = record;
    setReceiptImages([...receiptImages]);
    handleSetReceiptData(record);
  };

  const handleDeleteReceiptImage = (_, index) => {
    setReceiptImages(receiptImages.filter((val, idx) => idx !== index));
  };

  const formatReceiptImages = attachments => {
    return attachments?.map(attachment => {
      return {
        ...attachment,
        url: getCloudinaryImageUrl(attachment.fileUrl),
        ...(attachment.type === AttachmentType.vendorInvoice && {
          invoiceAmount: generateReceiptData.invoiceAmount,
          vendorDocumentNumber: generateReceiptData.vendorDocumentNumber,
          approvalStatus: generateReceiptData.approvalStatus
        })
      };
    });
  };

  return (
    <FullScreenModal
      handleClose={handleClose}
      modalHeaderButtons={[
        <ThemeProvider>
          <Button
            disabled={!canSave || isSubmitting}
            loading={isSubmitting}
            type={ButtonType.PRIMARY}
            onClick={handleGenerateReceipt}
          >
            {Labels.generateReceipt[user.locale]}
          </Button>
        </ThemeProvider>
      ]}
      open={open}
      title={`${Labels.generateReceipt[user.locale]} ${receiptNumber}`}
    >
      <div className={classes.contentContainer}>
        <div className={classes.imageCarousel}>
          <EntityImagesCarousel
            disableVendorInvoiceOption={receiptImages?.some(
              image => image.type === AttachmentType.vendorInvoice
            )}
            images={formatReceiptImages(receiptImages)}
            parentData={{
              vendorDocumentNumber: generateReceiptData.vendorDocumentNumber,
              approvalStatus: generateReceiptData.approvalStatus,
              invoiceAmount: generateReceiptData.invoiceAmount
            }}
            thumbnailSize={ThumbnailSize.MEDIUM}
            user={user}
            onDelete={handleDeleteReceiptImage}
            onEdit={handleEditReceiptImage}
            onSave={handleSaveReceiptImage}
          />
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.topMenuContainer}>
            <div className={classes.formContainer}>
              <MUIForm
                configuration={generateReceiptModalFormLayout({
                  isVendorInvoiceOn
                })}
                customComponents={{ CustomFieldWithLabel, SearchBar }}
                data={{
                  vendorDocumentNumber: generateReceiptData.vendorDocumentNumber,
                  issuedBy: generateReceiptData.issuedBy,
                  paymentTerm:
                    generateReceiptData.paymentTerm ||
                    (defaultPaymentTermId && company.defaultPaymentTerm),
                  approvalStatus: generateReceiptData.approvalStatus,
                  invoiceAmount: generateReceiptData.invoiceAmount
                }}
                layout="edit"
                validationSchema={generateDefaultValidationSchema(generateReceiptModalFormFields)}
                onComplete={() => {}}
                onCreateService={() => {}}
                onFormChange={handleGenerateReceiptModalFormChange}
              />
            </div>
            <ThemeProvider>
              <Button
                disabled={!isRowSelected}
                size={ButtonSize.SMALL}
                style={{ minWidth: 92, height: 30, padding: 8, margin: 0 }}
                type={ButtonType.SECONDARY}
                onClick={handleReceiveAll}
              >
                {Labels.receiveAll[user.locale]}
              </Button>
            </ThemeProvider>
          </div>
          <ResponsiveTable
            customCellComponents={{ customInput: CustomInputComponent }}
            data={getFormattedData(orderItems)}
            defaults={{
              sortBy: 'lineNumber',
              sortOrder: 'asc'
            }}
            disableFilter
            disablePagination
            noDataMsg="No Order Items"
            rowActionButtons={{ select: true, alternativeSelect: true }}
            rowActions={handleSelectedItem}
            rowMetadata={poGenerateReceiptListTableMeta(isVistaEnabled)}
          />
        </div>
      </div>
    </FullScreenModal>
  );
};

GenerateReceiptModal.propTypes = {
  receiptNumber: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  poId: PropTypes.string.isRequired
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatcherToProps = { snackbarOn };
export default connect(mapStateToProps, mapDispatcherToProps)(GenerateReceiptModal);
