import React, { useEffect } from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import ErrorBoundaries from 'scenes/Error';

import DropdownCreatable from '../DropdownFlyCreation';
import Representative from '../Representative';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: '15px'
  },
  paper: {
    width: '100%',
    paddingTop: '8px',
    border: `${theme.palette.grayscale(94)} 1px solid`
  },
  nameDropDown: {
    width: 206,
    height: 74,
    fontSize: 22
  },
  expandText: {
    textAlign: 'center',
    color: theme.palette.grayscale(60),
    fontSize: 8,
    fontFamily: theme.typography.fontFamily,
    fontWeight: '500',
    letterSpacing: 0,
    textTransform: 'uppercase'
  },
  expandSection: {
    backgroundColor: theme.palette.grayscale(94),
    height: 19
  },
  title: {
    height: 18,
    fontSize: 10,
    width: 'auto',
    backgroundColor: theme.palette.grayscale(94),
    textTransform: 'uppercase'
  },
  infoContainer: {
    margin: '0 8px 0 8px'
  },
  infoPara: {
    paddingLeft: '3px',
    fontWeight: 'normal',
    color: theme.palette.grayscale(20)
  },
  objectiveContainer: {
    marginTop: '5px',
    minHeight: '44px',
    paddingBottom: '12px'
  },
  elementContainer: {
    paddingRight: '8px'
  },
  changeBtn: {
    backgroundColor: theme.palette.grayscale(60),
    border: 'none',
    color: theme.palette.grayscale(100),
    '&:hover': {
      border: 'none',
      color: theme.palette.grayscale(100),
      backgroundColor: theme.palette.grayscale(60)
    }
  },
  labelComp: {
    fontSize: 20
  },
  arrowIcons: {
    height: 10,
    width: 10
  }
}));

export default function ExpansionCard(props) {
  const {
    requireElement,
    reqElementProps,
    info,
    title,
    expandComponent,
    btnAction,
    btnLabel,
    name,
    enableExpand
  } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [propsData, setPropsData] = React.useState({});
  const [actionRequired, setActionRequired] = React.useState(true);

  const OBJECTIVE_ELEMENT = {
    label: label => <Typography className={classes.labelComp}>{label || `loading...`}</Typography>,
    dropdown: () => 'dropdown',
    dropdownFlyCreation: (props = {}) => <DropdownCreatable {...props} />,
    representative: (props = {}) => {
      props.data = propsData;
      return (
        <Representative
          {...props}
          expanded={expanded}
          name={reqElementProps.name}
          setExpanded={setExpanded}
        />
      );
    }
  };

  requireElement === 'dropdownFlyCreation' &&
    (reqElementProps.createActionFromOption = newOpt => {
      setActionRequired(false);
      setPropsData({ firstName: newOpt.value });
      setExpanded(true);
    });

  const handleExpand = () => {
    setActionRequired(false);
    if (requireElement === 'dropdownFlyCreation') {
      const currentProps = reqElementProps.options.valueSet.find(
        opt => opt.value === reqElementProps[reqElementProps.name]
      );
      setPropsData(currentProps);
    }
    enableExpand ? setExpanded(!expanded) : setExpanded(false);
  };

  const renderExpandInfo = () => {
    if (expandComponent && expandComponent.type === 'component' && expandComponent.key) {
      return OBJECTIVE_ELEMENT[expandComponent.key]
        ? OBJECTIVE_ELEMENT[expandComponent.key](expandComponent.props)
        : '';
    }
    return expandComponent;
  };

  useEffect(() => {
    async function getExpandedData() {
      (await (expandComponent && expandComponent.data && actionRequired)) &&
        setPropsData(expandComponent.data || {});
    }
    getExpandedData();
  });

  return (
    <ErrorBoundaries>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Grid className={classes.infoContainer} container>
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
              <Chip className={classes.title} label={title} />
            </Grid>
            <Grid className={classes.objectiveContainer} container item>
              <Grid className={classes.elementContainer} item lg={5} md={5} sm={12} xl={5} xs={12}>
                {OBJECTIVE_ELEMENT[requireElement]
                  ? OBJECTIVE_ELEMENT[requireElement](reqElementProps)
                  : OBJECTIVE_ELEMENT.label(name)}
              </Grid>
              <Grid
                item
                lg={btnAction ? 4 : 7}
                md={btnAction ? 4 : 7}
                sm={12}
                xl={btnAction ? 4 : 7}
                xs={12}
              >
                <Typography className={classes.infoPara} component="div" variant="body2">
                  {info}
                </Typography>
              </Grid>
              {btnAction && (
                <Grid container justify="center" lg={3} md={3} sm={12} spacing={2} xl={3} xs={12}>
                  <Grid item>
                    <Button
                      className={classes.changeBtn}
                      variant="outlined"
                      onClick={() => btnAction()}
                    >
                      {btnLabel || 'CHANGE'}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {expandComponent && (
            <>
              <CardActions className={classes.expandSection} disableSpacing>
                <Grid alignItems="center" container direction="row" justify="center" spacing={2}>
                  <ButtonBase onClick={handleExpand}>
                    {!expanded ? (
                      <Typography className={classes.expandText}>
                        {enableExpand && (
                          <>
                            <ArrowDownwardIcon
                              style={{ fontSize: '10px', marginRight: '4px', cursor: 'default' }}
                            />{' '}
                            Show Details{' '}
                            <ArrowDownwardIcon style={{ fontSize: '10px', marginLeft: '4px' }} />
                          </>
                        )}
                      </Typography>
                    ) : (
                      <Typography className={classes.expandText}>
                        {enableExpand && (
                          <>
                            <ArrowUpwardIcon
                              style={{ fontSize: '10px', marginRight: '4px', cursor: 'default' }}
                            />{' '}
                            Hide Details{' '}
                            <ArrowUpwardIcon style={{ fontSize: '10px', marginLeft: '4px' }} />
                          </>
                        )}
                      </Typography>
                    )}
                  </ButtonBase>
                </Grid>
              </CardActions>
              <Collapse
                className={classes.expandSection}
                in={expanded}
                timeout="auto"
                unmountOnExit
              >
                <Box>{renderExpandInfo()}</Box>
              </Collapse>
            </>
          )}
        </Paper>
      </div>
    </ErrorBoundaries>
  );
}
