import React from 'react';

import { shape, string } from 'prop-types';

import ImageThumbnail from 'components/ImageThumbnail';

const ThumbnailField = ({ row }) => <ImageThumbnail image={{ fileUrl: row.fileUrl }} />;

ThumbnailField.propTypes = {
  row: shape({ fileUrl: string }).isRequired
};

export default ThumbnailField;
