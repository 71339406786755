import React from 'react';

import { Button, ThemeProvider } from '@BuildHero/sergeant';
import { object, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { transformCreatedReviewReportData, useCreateReviewReport } from '../../../../mutations';
import { selectCanBeReviewed, selectReviewReportId } from '../../../../selectors';

const getReviewReportPath = id => `/reviewreport/view/${id}`;

const TechnicianReportActionButton = ({ reportId, visit, buttonSize }) => {
  const history = useHistory();
  const user = useSelector(state => state.user);
  const [
    createReviewReportMutation,
    { loading, data: createdReviewReport }
  ] = useCreateReviewReport(reportId);

  const submitReviewReport = async () => {
    const response = await createReviewReportMutation({
      tenantId: user.tenantId,
      sortKey: visit.sortKey
    });
    const id = transformCreatedReviewReportData(response?.data)?.id;
    if (id) {
      history.push(getReviewReportPath(id));
    }
  };

  const reviewReportId = selectReviewReportId(visit) || createdReviewReport?.id;
  const canBeReviewed = selectCanBeReviewed(visit);

  return (
    <ThemeProvider>
      {reviewReportId ? (
        <Button size={buttonSize} type="secondary">
          <Link to={getReviewReportPath(reviewReportId)}>View Review Report</Link>
        </Button>
      ) : (
        <Button
          disabled={!canBeReviewed}
          loading={loading}
          size={buttonSize}
          type="primary"
          onClick={submitReviewReport}
        >
          Review
        </Button>
      )}
    </ThemeProvider>
  );
};

TechnicianReportActionButton.propTypes = {
  reportId: string.isRequired,
  visit: object.isRequired,
  buttonSize: string
};

TechnicianReportActionButton.defaultProps = {
  buttonSize: 'medium'
};

export default TechnicianReportActionButton;
