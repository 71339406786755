import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentLate from '@material-ui/icons/AssignmentLate';
import AttachmentIcon from '@material-ui/icons/Attachment';
import BuildIcon from '@material-ui/icons/Build';
import ContactIcon from '@material-ui/icons/Contacts';
import DescriptionIcon from '@material-ui/icons/Description';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import EventIcon from '@material-ui/icons/Event';
import FormatListIcon from '@material-ui/icons/FormatListBulleted';
import History from '@material-ui/icons/History';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import ListIcon from '@material-ui/icons/List';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Pause from '@material-ui/icons/Pause';
import CustomerIcon from '@material-ui/icons/People';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PersonIcon from '@material-ui/icons/Person';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import SettingsIcon from '@material-ui/icons/Settings';
import StoreIcon from '@material-ui/icons/Store';
import ApplicationIcon from '@material-ui/icons/Tab';
import LineItemsIcon from '@material-ui/icons/Toys';

const IconMap = props => {
  const { icon } = props;
  const theme = useTheme();

  const style = {
    fontSize: 24,
    marginLeft: 4,
    color: theme.palette.grayscale(20)
  };
  const infoStyle = {
    fontSize: 16,
    marginRight: 4,
    color: theme.palette.grayscale(20)
  };

  const iconArray = {
    contactIcon: <ContactIcon style={style} />,
    applicationIcon: <ApplicationIcon style={style} />,
    customerIcon: <CustomerIcon style={style} />,
    localShippingIcon: <LocalShippingIcon style={style} />,
    settingsIcon: <SettingsIcon style={style} />,
    deviceHubIcon: <DeviceHubIcon style={style} />,
    permIdentityIcon: <PermIdentityIcon style={style} />,
    personIcon: <PersonIcon style={style} />,
    storeIcon: <StoreIcon style={style} />,
    buildIcon: <BuildIcon style={style} />,
    formatListIcon: <FormatListIcon style={style} />,
    attachmentIcon: <AttachmentIcon style={style} />,
    unassigned: <AssignmentLate style={style} />,
    onHold: <Pause style={style} />,
    eventIcon: <EventIcon style={style} />,
    lineItemsIcon: <LineItemsIcon style={style} />,
    history: <History style={style} />,
    info: <InfoOutlined style={infoStyle} />,
    assignmentIcon: <AssignmentIcon style={style} />,
    descriptionIcon: <DescriptionIcon style={style} />,
    photoCameraIcon: <PhotoCameraIcon style={style} />,
    listIcon: <ListIcon style={style} />
  };
  return iconArray[icon];
};

export default IconMap;
