import React, { useEffect, useMemo, useState } from 'react';

import {
  Button,
  ButtonType,
  Divider,
  MoreButton,
  theme,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';
import { IconButton } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import RefreshIcon from '@material-ui/icons/Refresh';

import { Placeholder } from 'components';
import { StepButtonStateConstants } from 'scenes/Invoices/InvoiceDetail/InvoiceEmailModal/InvoiceEmailModal.constants';
import { availableJobReportPDFs } from 'scenes/Jobs/DetailView/JobTabs/InvoiceAndReport/exportJobReportService';

import CheckboxLineItem from '../CheckboxLineItem';

import { useGenerateJobReportPdf } from './JobReportAttachments.hooks';
import { useStyles } from './JobReportAttachments.styles';
import { processReportsData } from './JobReportAttachments.utils';

const JobReportAttachment = props => {
  const { snackbar, dispatchFilesData, filesData, jobData, dispatchButtonState } = props;
  const { customIdentifier, id: jobId, jobNumber, visits } = jobData;
  const [jobReportsData, setJobReportsData] = useState();
  const [refetchToken, setRefetchToken] = useState(1);
  const isEmptyState = useMemo(() => !jobReportsData?.length > 0, [jobReportsData]);

  const styles = useStyles({ isEmptyState });

  useEffect(() => {
    const initJobReports = async () => {
      dispatchButtonState(StepButtonStateConstants.LOADING);
      const jobReportPdfs = await availableJobReportPDFs(jobId, snackbar);
      const formattedReportsData = await processReportsData(jobReportPdfs, customIdentifier);
      dispatchButtonState(StepButtonStateConstants.ACTIVE);
      setJobReportsData(formattedReportsData);
    };
    if (refetchToken && jobId) {
      initJobReports();
    }
  }, [refetchToken, dispatchButtonState, setJobReportsData, customIdentifier, jobId, snackbar]);

  const generateJobReportPdf = useGenerateJobReportPdf(jobData, snackbar);

  const options = useMemo(
    () => [
      {
        label: 'Generate custom job report on Jobs page',
        icon: LaunchIcon,
        onClick: () => window.open(`/job/view/${jobNumber}`, '_blank')
      }
    ],
    [jobNumber]
  );

  if (jobReportsData === undefined)
    return (
      <Placeholder
        paddingBottom={0}
        paddingLeft={0}
        paddingRight={0}
        paddingTop={0}
        variant="table"
      />
    );

  return (
    <div>
      <div css={styles.jobReportsHeader}>
        <div>
          <Typography variant={TV.L} weight={TW.BOLD}>
            Job Reports
          </Typography>
        </div>
        <div>
          {/* Hide the button per ticket BUOP-19369 */}
          {/* <Button
            css={{ marginRight: 8 }}
            disabled={!visits?.items?.length > 0} // Cannot generate job report pdf without job visits
            size="small"
            type={ButtonType.TERTIARY}
            onClick={generateJobReportPdf}
          >
            Generate Job Report
          </Button> */}
          <IconButton
            css={{ marginRight: 8 }}
            size="small"
            onClick={() => setRefetchToken(prev => prev + 1)}
          >
            <RefreshIcon />
          </IconButton>
          <MoreButton css={styles.moreButton} options={options} />
        </div>
      </div>

      {isEmptyState ? (
        <div css={styles.jobReportEmptyStateWrapper}>
          <Typography css={{ marginBottom: 10 }} weight={TW.BOLD}>
            No Job Reports
          </Typography>
          <Typography color={theme.palette.grayscale(50)}>
            Visit the Jobs page to generate a custom job report.
          </Typography>
        </div>
      ) : (
        <>
          <Divider margin={12} />
          {jobReportsData.map(reportData => (
            <CheckboxLineItem
              dispatchFilesData={dispatchFilesData}
              filesData={filesData}
              reportData={reportData}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default React.memo(JobReportAttachment);
