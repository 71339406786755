import * as R from 'ramda';

import { removeEmptyValues, removeNestedJson, removeNullValues } from '../utils';

import InstructionHelper from './InstructionHelper';

export default class InstructionSetGenerator {
  constructor(currentObject, schema, schemaMappings, previousObject = null) {
    this.currentObject = currentObject;
    this.previousObject = previousObject;
    this.schema = schema;
    this.schemaMappings = schemaMappings;
  }

  addMapping = (instructionSet, parentMapping, parentIndex, childIndex) => {
    let temp = null;
    if (parentMapping.mapType === '1M') {
      temp = instructionSet.oneToMany(parentIndex, childIndex);
    } else if (parentMapping.mapType === 'MM') {
      const {
        directEntityField,
        invertedEntityField,
        directEntity,
        invertedEntity
      } = parentMapping.mapping;
      if (directEntityField && invertedEntityField) {
        if (!directEntity || !invertedEntity) {
          temp = instructionSet.manyToMany(
            parentIndex,
            childIndex,
            directEntityField,
            invertedEntityField
          );
        } else {
          temp = instructionSet.manyToMany(
            parentIndex,
            childIndex,
            directEntityField,
            invertedEntityField,
            directEntity,
            invertedEntity
          );
        }
      } else {
        temp = instructionSet.manyToMany(parentIndex, childIndex);
      }
    }
    return temp;
  };

  associationsToRemove = () => {
    const schemaMappingKeys = this.schemaMappings.map(el => el.key);
    const associations = [];
    if (this.previousObject) {
      schemaMappingKeys.forEach(key => {
        if (
          key !== 'parentEntity' &&
          Object.keys(this.previousObject).includes(key) &&
          Object.keys(this.currentObject).includes(key)
        ) {
          const prevItems = this.previousObject[key].map(e => e.id);
          const currentItems = this.currentObject[key].map(e => e.id);
          const itemsToRemove = prevItems.filter(id => !currentItems.includes(id));
          itemsToRemove.forEach(id => {
            const item = this.previousObject[key].find(e => e.id === id);
            associations.push({ ...item, tenantId: this.previousObject.partitionKey });
          });
        }
      });
    }
    return associations;
  };

  createDataset = () => {
    const localProps = removeEmptyValues(removeNullValues(this.currentObject));
    const schemaMappingKeys = this.schemaMappings.map(el => el.key);
    const fieldsToBeRemoved = R.uniq(schemaMappingKeys.concat(['__typename', 'parent']));
    const removeFieldsMaps = ['directSortKey', 'invertedSortKey'];

    const dataArrays = {};

    schemaMappingKeys.forEach(key => {
      dataArrays[key] = [];
    });
    const visitFields = {};

    Object.keys(localProps).forEach((key, index) => {
      if (schemaMappingKeys.includes(key) && localProps[key].length > 0) {
        const localObj = localProps[key];
        dataArrays[key] = localObj.map(obj => removeNestedJson(obj));
      }

      if (localProps[key] && localProps[key] !== '' && !fieldsToBeRemoved.includes(key)) {
        if (this.schema.includes(key)) {
          visitFields[key] = localProps[key];
        }
      }
    });

    const parentMapping = this.schemaMappings.find(el => el.type === 'parent');
    const parent = removeNestedJson(localProps[parentMapping.key]);
    const parentRequiredMapping = parentMapping.required;
    const parentKeys = Object.keys(parent);
    parentRequiredMapping.forEach(key => {
      if (!parentKeys.includes(key)) {
        throw new Error(`Parent does not include required fields (missing '${key}')!`);
      }
    });
    const data = [{ ...parent }, { ...visitFields }];
    const ins = new InstructionHelper();
    let temp = this.addMapping(ins, parentMapping, 0, 1);
    schemaMappingKeys.forEach(key => {
      const objMapping = this.schemaMappings.find(el => el.key === key);
      const localArray = dataArrays[key];
      localArray.forEach(item => {
        const localItem = {};
        Object.keys(item).forEach(k => {
          if (
            item[k] &&
            item[k] !== '' &&
            !removeFieldsMaps.includes(k) &&
            objMapping.connection.includes(k)
          ) {
            localItem[k] = item[k];
          }
        });
        data.push(localItem);
        temp = this.addMapping(temp, objMapping, 1, data.length - 1);
      });
    });

    const { instructionSet } = temp;
    const ret = { data: JSON.stringify(data), instructionSet: JSON.stringify(instructionSet) };
    return ret;
  };
}
