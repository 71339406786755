import gql from 'graphql-tag';

import { Context } from 'components';
import useExtendedMutation from 'customHooks/useExtendedMutation';
import CacheService from 'services/CacheService';

const UPDATE_COMPANY = gql`
  mutation updateCompany($partitionKey: String, $data: UpdateCompanyInput) {
    updateCompany(partitionKey: $partitionKey, data: $data) {
      id
      version
      invoiceLogoUrl
      sortKey
    }
  }
`;

export const useCompanyMutation = () => {
  const [mutate] = useExtendedMutation(UPDATE_COMPANY, {
    serializer: ({ id, version, invoiceLogoUrl }) => ({
      variables: {
        data: {
          id,
          version,
          invoiceLogoUrl
        }
      }
    }),
    onCompleted: data => {
      const updatedCompany = data.updateCompany;
      const ctx = CacheService.getCachedItem(`CTX_${updatedCompany.sortKey}`);
      CacheService.cacheItem({ ...ctx, getCompany: { ...ctx.getCompany, ...updatedCompany } });

      // updated Context object used in BuildOps which will also update
      // redux stores
      Context.resetCompanyContext();
      Context.setCompanyContext(ctx.getCompany.tenantId, updatedCompany.sortKey);
    }
  });

  return mutate;
};
