import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const DELETE_TASK_ENTRY_BY_ID = gql`
  mutation deleteTaskEntry($partitionKey: String!, $id: String!) {
    deleteTaskEntry(partitionKey: $partitionKey, id: $id) {
      id
      parentId
    }
  }
`;

const serializer = ({ tenantId, id }) => ({
  variables: {
    partitionKey: tenantId,
    id
  }
});

export const useDeleteTaskEntryById = () => {
  return useExtendedMutation(DELETE_TASK_ENTRY_BY_ID, {
    serializer
  });
};
