import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import LinkButton from './LinkButton';

const useStyles = makeStyles(theme => ({
  linkListLink: {
    marginRight: 5
  }
}));

const LinkList = ({ meta, record, classes, testingid }) => {
  const additionalClasses = useStyles();
  const attributeKey = meta?.id;
  const maxAmountOfLinks = meta.maxAmountOfLinks ?? 3;
  const itemList = record[attributeKey];
  const { items } = itemList;
  const links = [];
  if (items.length > 0) {
    const {
      itemLabel,
      linkReference,
      itemLabelReference,
      linkPath,
      moreLinkReference,
      moreLinkPath
    } = meta;
    classes.root =
      classes.root?.concat(` ${additionalClasses.linkListLink}`) || additionalClasses.linkListLink;
    // Loop through items and structure links from the provided meta
    for (let i = 0; i < items.length; i += 1) {
      const item = items[i];
      const key = `link-${record.id}-${i}`;
      if (links.length < maxAmountOfLinks && item) {
        const reference = _.get(item, linkReference);
        const label = `${itemLabel}${_.get(item, itemLabelReference)}`;
        const path = `${linkPath}/${reference}`;
        links.push(
          <LinkButton
            classes={classes}
            key={key}
            label={label}
            path={path}
            testingid={`${testingid}-${i}`}
          />
        );
      } else if (moreLinkReference && moreLinkPath) {
        const moreReference = _.get(record, moreLinkReference);
        const morePath = `${moreLinkPath}/${moreReference}`;
        links.push(
          <LinkButton
            classes={classes}
            key={key}
            label="more"
            path={morePath}
            testingid={`${testingid}-more`}
          />
        );
        break;
      }
    }
  } else {
    links.push(<p key={record.id}>-</p>);
  }
  return links;
};

export default LinkList;
