import algoliaClient from 'algoliasearch/lite';

import configForEnvironment from 'configs/aws-exports';
import ENV from 'configs/env';

import { dateRanges } from './constants';

export const getDateRangeFilter = () => {
  const range = dateRanges.map(({ label, numberOfDays }) => {
    const currentDate = new Date();

    return {
      label,
      range: () => ({
        startDate: currentDate,
        endDate: new Date().setDate(currentDate.getDate() + numberOfDays)
      })
    };
  });
  return range;
};

export const getAlgoliaClient = key => {
  const { algoliaAppKey } = configForEnvironment(ENV);
  const client = algoliaClient(algoliaAppKey, key);
  const clientWithIndex = client.initIndex('bh-productIndex');
  return clientWithIndex;
};
