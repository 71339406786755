import React from 'react';

import { Box, Typography } from '@material-ui/core';

import useStyles from './TinyComponents.styles';

export const ItemWrapper = ({ children, ...props }) => {
  return (
    <Box {...props} display="flex" minWidth={120} p={0.5} pl={0}>
      {children}
    </Box>
  );
};

export const RenderTitle = ({ title }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.subtitle} variant="subtitle2">
      {title}
    </Typography>
  );
};

export const RenderHeader = ({ items, displayItemKey = 'label', wrapperProps = {} }) => (
  <Box display="flex" pt={1}>
    {items.map((item, i) => (
      <ItemWrapper key={i} {...wrapperProps}>
        <RenderTitle title={item[displayItemKey]} />
      </ItemWrapper>
    ))}
  </Box>
);
