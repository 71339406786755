import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const ADD_TASK_ENTRY_TO_TASK = gql`
  mutation addTaskEntriesToTask($partitionKey: String!, $data: AddTaskEntriesToTaskInput!) {
    addTaskEntriesToTask(partitionKey: $partitionKey, data: $data) {
      id
      description
      markupValue
      parentId
      quantity
      unitCost
      unitPrice
    }
  }
`;

const serializer = ({ tenantId, taskId, taskEntries }) => ({
  variables: {
    partitionKey: tenantId,
    data: {
      taskId,
      taskEntries
    }
  }
});

export const useAddTaskEntryToTask = () => {
  return useExtendedMutation(ADD_TASK_ENTRY_TO_TASK, {
    serializer
  });
};
