import React from 'react';

import { shape, string } from 'prop-types';

import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';

const downloadFile = async ({ fileUrl, fileName }) => {
  const storageService = new StorageService();
  try {
    const url = await storageService.getFile(fileUrl, false);
    if (url) {
      const aLink = document.createElement('a');
      aLink.target = '_blank';
      aLink.download = fileName;
      aLink.href = url;
      aLink.click();
    }
  } catch (error) {
    Logger.info(`Error uploading image ${error}`);
  }
};

const FileNameField = ({ formattedValue, row }) => {
  const fileUrl = row?.fileUrl;
  const fileName = formattedValue;

  const handleClick = () => downloadFile({ fileUrl, fileName });

  return (
    <div
      aria-label={`Download file ${formattedValue}`}
      css={{ cursor: 'pointer', textDecoration: 'underline' }}
      role="button"
      tabIndex="0"
      onClick={handleClick}
      onKeyPress={handleClick}
    >
      {formattedValue}
    </div>
  );
};

FileNameField.propTypes = {
  formattedValue: string.isRequired,
  row: shape({ fileUrl: string }).isRequired
};

export default FileNameField;
