import React from 'react';

import { Typography } from '@BuildHero/sergeant';

import { useStyles } from './QuotesListHeader.styles';

const QuotesListHeader = () => {
  const styles = useStyles();
  return (
    <div css={styles.container}>
      <Typography css={styles.label}>Associated Quotes</Typography>
      <Typography css={styles.label}>Quote Amount</Typography>
    </div>
  );
};

export default QuotesListHeader;
