import React, { useMemo, useState } from 'react';

import { Divider, Button as SergeantButton, ThemeProvider, Typography } from '@BuildHero/sergeant';
import { findLastIndex, isEmpty, noop } from 'lodash';

import { SergeantModal } from 'components';
import Placeholder from 'components/Placeholder';
import { TimeCardStatusTypes } from 'utils/AppConstants';

import { timesheetViews } from '../constants';
import { useReopenTimesheet, useTimesheetPeriod } from '../customHooks';

import DateNavigation from './components/DateNavigation';
import { DayCard } from './components/DayCard';
import TabFooter from './components/TabFooter';
import { generateWeekDataFromBinder, showDivider, startDayToday } from './helpers';
import requestReasonMetaLayout from './RequestReasonMeta';

const useStyles = () => ({
  outerContainer: {
    minHeight: 500
  },
  visitsSection: {
    marginTop: 36
  },
  flexButtons: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

const Approved = ({
  selectedEmployee,
  payrollHourTypes,
  timesheetPeriods = [],
  payrollSetting,
  snackbarOn,
  setExportSelectedDate,
  onUpdateDayCard = noop,
  setIsExportDisabled = noop,
  selectedDate = null,
  setSelectedDate = noop,
  selectedPeriod,
  setSelectedPeriod = noop,
  dayOrWeek = timesheetViews.DAY,
  setDayOrWeek = noop
}) => {
  const styles = useStyles();

  const [isReopenModalOpen, setIsReopenModalOpen] = useState(false);
  const [dismissedBinderMap, setDismissedBinderMap] = useState({ dismissed: [], undismissed: [] });
  const [currentDayIndex, setCurrentDayIndex] = useState();

  const [period, isLoading, , refetch] = useTimesheetPeriod({ id: selectedPeriod?.id });
  const [reopenTimesheet, { loading: reopenLoading }] = useReopenTimesheet();

  const availableStatuses = [TimeCardStatusTypes.APPROVED];

  const weekDates = useMemo(() => {
    if (isEmpty(period)) return [];

    const {
      timesheetEntryBinders: { items: timesheetEntryBinders }
    } = period;

    return generateWeekDataFromBinder(
      timesheetEntryBinders,
      period,
      payrollSetting.timeZone,
      payrollHourTypes,
      availableStatuses,
      [],
      dismissedBinderMap
    );
  }, [period, payrollSetting.timeZone, payrollHourTypes, availableStatuses, dismissedBinderMap]);

  const weekDate = useMemo(
    () =>
      weekDates.find(
        ({ dayStartUTC, dayEndUTC }) => dayStartUTC <= selectedDate && selectedDate <= dayEndUTC
      ) || {},
    [selectedDate, weekDates]
  );

  const handleReopen = ({ reason }) => {
    setIsReopenModalOpen(false);
    const updateDay = dayOrWeek === timesheetViews.DAY ? weekDate : weekDates[currentDayIndex];

    reopenTimesheet({
      employeeId: selectedEmployee.id,
      startDate: updateDay.dayStartUTC,
      reason
    }).then(() => {
      onUpdateDayCard();
    });
  };

  const noTimesheetsMessage = 'No approved timesheets';

  const dayCardProps = {
    isEditable: false,
    payrollHourTypes,
    payrollSetting,
    tenantId: selectedEmployee.tenantId,
    refetchTimesheetPeriod: refetch,
    employeeId: selectedEmployee.id,
    tab: 'approved',
    setDismissedBinderMap,
    areBindersEnabled: true
  };

  if (isLoading) return <Placeholder variant="table" />;

  return (
    <div css={styles.outerContainer}>
      <DateNavigation
        availableStatuses={availableStatuses}
        dayOrWeek={dayOrWeek}
        payrollSetting={payrollSetting}
        selectedDate={selectedDate}
        selectedEmployee={selectedEmployee}
        selectedPeriod={selectedPeriod}
        setDayOrWeek={setDayOrWeek}
        setExportSelectedDate={setExportSelectedDate}
        setIsExportDisabled={setIsExportDisabled}
        setSelectedDate={setSelectedDate}
        setSelectedPeriod={setSelectedPeriod}
        snackbarOn={snackbarOn}
        timesheetPeriods={timesheetPeriods}
        weekDate={weekDate}
        weekDates={weekDates}
      />

      <ThemeProvider>
        {dayOrWeek === timesheetViews.DAY && (
          <div css={styles.visitsSection}>
            {!isEmpty(weekDate) &&
            (weekDate.workEvents.length || weekDate.dismissedBinders.length) ? (
              <DayCard day={weekDate} {...dayCardProps} />
            ) : (
              <>{selectedDate && <Typography>{noTimesheetsMessage}</Typography>}</>
            )}
          </div>
        )}
        {dayOrWeek === timesheetViews.WEEK && (
          <div css={styles.visitsSection}>
            {weekDates.map((day, i) => {
              // do not show future timesheets
              if (day.dayStartUTC > startDayToday(payrollSetting.timeZone)) return null;
              return (
                <>
                  <DayCard day={day} key={day.dayStartUTC} {...dayCardProps}>
                    <div css={styles.flexButtons}>
                      <SergeantButton
                        loading={!isReopenModalOpen && reopenLoading && i === currentDayIndex}
                        type="tertiary"
                        onClick={() => {
                          setCurrentDayIndex(i);
                          setIsReopenModalOpen(true);
                        }}
                      >
                        Reopen
                      </SergeantButton>
                    </div>
                  </DayCard>
                  {showDivider(i, day, weekDates) ? <Divider /> : null}
                </>
              );
            })}
            {selectedPeriod.id &&
              weekDates.every(w => !w.workEvents.length) &&
              weekDates.every(w => !w.dismissedBinders.length) && (
                <Typography>{noTimesheetsMessage}</Typography>
              )}
          </div>
        )}
        {((!isEmpty(weekDate) && dayOrWeek === timesheetViews.DAY) ||
          (dayOrWeek === timesheetViews.WEEK && weekDates?.some(w => w.workEvents.length > 0))) && (
          <TabFooter
            dayOrWeek={dayOrWeek}
            payrollHourTypes={payrollHourTypes}
            weekDate={weekDate}
            weekDates={weekDates}
          >
            <div css={styles.flexButtons}>
              {dayOrWeek === timesheetViews.DAY && (
                <SergeantButton
                  disabled={!weekDate.workEvents.length}
                  loading={!isReopenModalOpen && reopenLoading}
                  type="tertiary"
                  onClick={() => {
                    setIsReopenModalOpen(true);
                  }}
                >
                  Reopen
                </SergeantButton>
              )}
            </div>
          </TabFooter>
        )}
      </ThemeProvider>

      <SergeantModal
        disablePrimaryButton={false}
        handleClose={() => setIsReopenModalOpen(false)}
        handlePrimaryAction={handleReopen}
        layout={requestReasonMetaLayout}
        open={isReopenModalOpen}
        title="Reopen Timesheet"
      />
    </div>
  );
};

export default Approved;
