import React from 'react';

import { Checkbox, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ViewIcon from '@material-ui/icons/OpenInNew';
import PublishIcon from '@material-ui/icons/Publish';
import { Cache } from 'aws-amplify';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import UserPermission from 'components/AppPermissions';
import ConfirmModal from 'components/Modal/ConfirmDialog';
import Spinner from 'components/Spinners/CircularIndeterminate';
import ErrorBoundaries from 'scenes/Error';
import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';
import AppConstants from 'utils/AppConstants';

import styles from './styles';
import EnhancedTableHead from './TableHead';
import EnhancedTableToolbar from './TableToolbar';

let counter = 0;

function insertIdColumn(jsonRecord) {
  counter += 1;
  return { ...jsonRecord, rowId: counter };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const RouterLink = React.forwardRef((props, ref) => <Link {...props} ref={ref} />);

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.state = {
      order: this.props.sortOrder || 'desc',
      orderBy: this.props.defaultSort || this.props.captionAttributes[0].id || 'id',
      selected: [],
      page: 0,
      rowsPerPage: Cache.getItem(this.props.tableId) || 5,
      nextToken: null,
      data: null,
      totalPages: '',
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: ''
    };
  }

  componentDidMount = async () => {
    if (!this.state.data) {
      const data = await this.performQuery(this.state.nextToken, this.state.rowsPerPage);
      if (this.mounted && data) {
        const totalPages = data.items ? Math.ceil(data.items.length / this.state.rowsPerPage) : 0;
        this.setState(prevState => ({
          ...prevState,
          data: data.items || [],
          nextToken: data.nextToken || '',
          totalPages
        }));
      }
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  performQuery = async (nextToken, limit) => {
    const data = await this.props.service(nextToken, (limit || 5) * 2);
    return data;
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.rowId) }));
      return;
    }
    this.setState({ selected: [] });
  };

  downloadFile = async fileName => {
    if (!fileName) {
      return;
    }
    try {
      const storageService = new StorageService();
      const url = await storageService.getFile(fileName);
      window.open(url);
    } catch (error) {
      // avoid sending to Sentry as not all images will have thumbnails
      Logger.info(`Error getting image ${error}`);
    }
  };

  handleChangePage = async (event, page) => {
    if (this.state.nextToken) {
      const initialData = this.state.data;
      const addOnData = await this.performQuery(this.state.nextToken, this.state.rowsPerPage);
      const data = initialData.concat(addOnData.items);
      if (this.mounted && data) {
        const totalPages = Math.ceil(data.length / this.state.rowsPerPage);
        this.setState(prevState => ({
          ...prevState,
          data,
          nextToken: addOnData.nextToken,
          totalPages
        }));
      }
    }
    this.setState({ page });
  };

  handleChangeRowsPerPage = async event => {
    const selectedValue = event.target.value || 5;
    const rowsPerPage = parseInt(selectedValue, 10);
    if (this.props.tableId) {
      const timestamp = moment()
        .add(1, 'year')
        .valueOf();
      Cache.setItem(this.props.tableId, event.target.value, { expires: timestamp });
    }
    const data = await this.performQuery(null, parseInt(rowsPerPage, 10));
    if (this.mounted && data) {
      const totalPages = Math.ceil(data.length / this.state.rowsPerPage);
      this.setState(prevState => ({
        ...prevState,
        data: data.items,
        nextToken: data.nextToken,
        totalPages,
        rowsPerPage,
        page: 0
      }));
    }
  };

  isSelected = rowId => this.state.selected.indexOf(rowId) !== -1;

  handleValueRendering = (value, fieldType, other) => {
    if (value === 'undefined') {
      return '-';
    }

    if (fieldType === 'text') {
      return value;
    }

    if (fieldType === 'date') {
      if (!value) {
        return '';
      }
      return moment.unix(value).format(AppConstants.DATE_FORMAT);
    }

    if (fieldType === 'dateOnly') {
      if (!value) {
        return '';
      }
      return moment.unix(value / 1000).format(AppConstants.DATE_FORMAT);
    }

    if (fieldType === 'boolean') {
      return value === true ? 'Yes' : 'No';
    }

    if (fieldType === 'attachment' && other) {
      return (
        <Button
          classes={{
            outlinedSecondary: this.props.classes.buttonOutlinedSecondary,
            label: this.props.classes.buttonLabel
          }}
          onClick={() => this.downloadFile(other)}
        >
          {value}
        </Button>
      );
    }

    return value;
  };

  handleCancelConfirmation = () => {
    this.setState({ confirmDialog: false, confirmAction: '', confirmMessage: '' });
  };

  handleSelectAll = event => {
    const { data, order, orderBy, page, rowsPerPage } = this.state;
    const records = event.target.checked
      ? stableSort(data, getSorting(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : [];
    this.props.handleSelectAll(records);
  };

  render() {
    const {
      classes,
      captionAttributes,
      title,
      showRowButtons,
      showTableHeader,
      caslKey,
      customLinkText,
      dynamicallyShowPagination
    } = this.props;

    const data = [];

    if (this.state.data === null) {
      return <Spinner />;
    }

    if (this.state.data) {
      this.state.data.forEach(dataElement => {
        data.push(insertIdColumn(dataElement));
      });
    }

    const highlightFirstColumn = !!this.props.highlightFirstColumn;
    // const data = this.state.displayData;

    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const showPagination =
      dynamicallyShowPagination === undefined ||
      (dynamicallyShowPagination === true && data.length > rowsPerPage);

    return (
      <Paper className={classes.root}>
        <ErrorBoundaries>
          {showTableHeader && <EnhancedTableToolbar numSelected={selected.length} title={title} />}
          <div className={classes.tableWrapper}>
            <ErrorBoundaries>
              <Table aria-labelledby="tableTitle" className={classes.table} size="small">
                <ErrorBoundaries>
                  <EnhancedTableHead
                    classes={classes}
                    handleSelectAll={this.handleSelectAll}
                    highlightFirstColumn={highlightFirstColumn}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    rowCount={data.length}
                    rows={captionAttributes}
                    onRequestSort={this.handleRequestSort}
                    onSelectAllClick={this.handleSelectAllClick}
                  />
                </ErrorBoundaries>
                <TableBody>
                  {data &&
                    stableSort(data, getSorting(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map(record => {
                        const isSelected = this.isSelected(record.rowId);
                        return (
                          <ErrorBoundaries>
                            <TableRow
                              aria-checked={isSelected}
                              hover
                              key={record.rowId}
                              role="checkbox"
                              selected={isSelected}
                              tabIndex={-1}
                            >
                              {captionAttributes.map((a, index) => (
                                <TableCell
                                  align={a.numeric ? 'right' : 'left'}
                                  className={
                                    index === 0 && highlightFirstColumn
                                      ? classes.highlightFirstColumn
                                      : classes.column
                                  }
                                  key={record.rowId + a.id}
                                >
                                  <ErrorBoundaries>
                                    {a.id === 'checkBox' && (
                                      <Checkbox
                                        checked={this.props.isSelected(record)}
                                        onChange={() => this.props.handleSelectAction(record)}
                                      />
                                    )}
                                    {a.showLink && a.linkPath && (
                                      <Button
                                        classes={{
                                          outlinedSecondary: this.props.classes
                                            .buttonOutlinedSecondary,
                                          label: this.props.classes.buttonLabel
                                        }}
                                        component={RouterLink}
                                        to={
                                          a.linkStateKey && a.linkStateValue
                                            ? {
                                                pathname: `${a.linkPath}/${
                                                  record[a.linkReference]
                                                }`,
                                                state: {
                                                  [a.linkStateKey]: record[a.linkStateValue]
                                                }
                                              }
                                            : {
                                                pathname: `${a.linkPath}/${record[a.linkReference]}`
                                              }
                                        }
                                        // onClick={() => this.props.rowActions(a.showLink, n)}
                                      >
                                        {customLinkText &&
                                          a.linkText &&
                                          this.handleValueRendering(
                                            record[a.linkText] || record[a.id],
                                            a.type || 'text',
                                            a.other ? record[a.other] : null
                                          )}
                                        {!customLinkText &&
                                          record[a.id] &&
                                          this.handleValueRendering(
                                            record[a.id],
                                            a.type || 'text',
                                            a.other ? record[a.other] : null
                                          )}
                                      </Button>
                                    )}
                                    {a.showLink && !a.linkPath && (
                                      <Button
                                        classes={{
                                          outlinedSecondary: this.props.classes
                                            .buttonOutlinedSecondary,
                                          label: this.props.classes.buttonLabel
                                        }}
                                        onClick={() => this.props.rowActions(a.showLink, record)}
                                      >
                                        {record[a.id] &&
                                          this.handleValueRendering(
                                            record[a.id],
                                            a.type || 'text',
                                            a.other ? record[a.other] : null
                                          )}
                                      </Button>
                                    )}
                                    {!a.showLink &&
                                      record[a.id] &&
                                      this.handleValueRendering(
                                        record[a.id],
                                        a.type || 'text',
                                        a.other ? record[a.other] : null
                                      )}
                                  </ErrorBoundaries>
                                </TableCell>
                              ))}

                              {showRowButtons && (
                                <TableCell align="right">
                                  <ErrorBoundaries>
                                    {showRowButtons.includes('publish') && (
                                      <UserPermission action={caslKey} I="delete">
                                        <Tooltip title="Publish">
                                          <IconButton
                                            aria-label="Publish"
                                            className={classes.buttonHover}
                                            onClick={() => {
                                              this.setState({
                                                confirmDialog: true,
                                                confirmAction: async () => {
                                                  const opertationStatus = await this.props.rowActions(
                                                    'publish',
                                                    record,
                                                    this.props.refetch
                                                  );
                                                  let localData = data;
                                                  if (opertationStatus) {
                                                    localData = data.filter(
                                                      item => item.id !== record.id
                                                    );
                                                  }

                                                  this.setState({
                                                    confirmDialog: false,
                                                    confirmAction: '',
                                                    confirmMessage: '',
                                                    confirmOverride: false,
                                                    data: localData
                                                  });
                                                },
                                                confirmMessage: `Are you sure you would like to publish this ${this.props.uiEntityName}?`,
                                                confirmOverride: true
                                              });
                                            }}
                                          >
                                            <PublishIcon className={classes.iconColor} />
                                          </IconButton>
                                        </Tooltip>
                                      </UserPermission>
                                    )}
                                    {showRowButtons.includes('view') && (
                                      <UserPermission action={caslKey} I="view">
                                        <Tooltip title="View details ">
                                          <IconButton
                                            aria-label="View details"
                                            className={classes.buttonHover}
                                            onClick={() =>
                                              this.props.rowActions(
                                                'view',
                                                record,
                                                this.props.refetch
                                              )
                                            }
                                          >
                                            <ViewIcon className={classes.iconColor} />
                                          </IconButton>
                                        </Tooltip>
                                      </UserPermission>
                                    )}
                                    {showRowButtons.includes('edit') && (
                                      <UserPermission action={caslKey} I="edit">
                                        <Tooltip title="Edit">
                                          <IconButton
                                            aria-label="Edit"
                                            className={classes.buttonHover}
                                            onClick={() =>
                                              this.props.rowActions(
                                                'edit',
                                                record,
                                                this.props.refetch
                                              )
                                            }
                                          >
                                            <EditIcon className={classes.iconColor} />
                                          </IconButton>
                                        </Tooltip>
                                      </UserPermission>
                                    )}
                                    {showRowButtons.includes('copy') && (
                                      <UserPermission action={caslKey} I="copy">
                                        <Tooltip title="Copy">
                                          <IconButton
                                            aria-label="copy"
                                            className={classes.buttonHover}
                                            onClick={() =>
                                              this.props.rowActions(
                                                'copy',
                                                record,
                                                this.props.refetch
                                              )
                                            }
                                          >
                                            <FileCopyIcon className={classes.iconColor} />
                                          </IconButton>
                                        </Tooltip>
                                      </UserPermission>
                                    )}
                                    {showRowButtons.includes('delete') && (
                                      <UserPermission action={caslKey} I="delete">
                                        <Tooltip title="Delete">
                                          <IconButton
                                            aria-label="Delete"
                                            className={classes.buttonHover}
                                            onClick={() => {
                                              this.setState({
                                                confirmDialog: true,
                                                confirmAction: async () => {
                                                  const opertationStatus = await this.props.rowActions(
                                                    'delete',
                                                    record,
                                                    this.props.refetch
                                                  );
                                                  let localData = data;
                                                  // after successfull deletion
                                                  if (opertationStatus) {
                                                    localData = data.filter(
                                                      item => item.id !== record.id
                                                    );
                                                  }

                                                  this.setState({
                                                    confirmDialog: false,
                                                    confirmAction: '',
                                                    confirmMessage: '',
                                                    data: localData
                                                  });
                                                },
                                                confirmMessage: this.props.uiEntityName
                                              });
                                            }}
                                          >
                                            <DeleteIcon className={classes.iconColor} />
                                          </IconButton>
                                        </Tooltip>
                                      </UserPermission>
                                    )}
                                  </ErrorBoundaries>
                                </TableCell>
                              )}
                            </TableRow>
                          </ErrorBoundaries>
                        );
                      })}
                  {data.length !== 0 && emptyRows > 0 && (
                    <TableRow style={{ height: 49 }}>{/* <TableCell colSpan={6} /> */}</TableRow>
                  )}
                </TableBody>
              </Table>
              {data.length === 0 && (
                <Typography className={classes.noData}>{this.props.noDataMsg}</Typography>
              )}
            </ErrorBoundaries>
          </div>
          {showPagination && (
            <TablePagination
              backIconButtonProps={{
                'aria-label': 'Previous Page'
              }}
              classes={{ toolbar: classes.tableFooterToolbar }}
              component="div"
              count={data.length}
              nextIconButtonProps={{
                'aria-label': 'Next Page'
              }}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          )}
          <ConfirmModal
            cancel={this.handleCancelConfirmation}
            confirm={this.state.confirmAction}
            message={this.state.confirmMessage}
            open={this.state.confirmDialog}
            override={this.state.confirmOverride}
          />
        </ErrorBoundaries>
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  captionAttributes: PropTypes.array.isRequired
};

export default withStyles(styles)(EnhancedTable);
