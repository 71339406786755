import React from 'react';

import Box from '@material-ui/core/Box';
import {
  DetailsView,
  FileManagerComponent,
  Inject,
  Toolbar
} from '@syncfusion/ej2-react-filemanager';
import { func, string } from 'prop-types';

import ErrorBoundaries from 'scenes/Error';

import {
  beforeDownload,
  beforeImageLoad,
  beforeSend,
  getFilesApiUrl,
  useJwtToken
} from '../FileManagement.hooks';

import { useStyles } from './SelectFolderFileManager.styles';

const SelectFolderFileManager = ({ projectId, onSelectFolder }) => {
  const styles = useStyles();
  const jwtToken = useJwtToken();
  const fsApiUrl = getFilesApiUrl(projectId);

  function handleFileOpen(event) {
    const fileManager = this;
    const { fileDetails } = event;
    if (!fileDetails.isFile) {
      onSelectFolder({ id: fileDetails.id, path: fileManager.path });
    }
  }

  return (
    <ErrorBoundaries>
      <Box css={styles.root}>
        <div className="control-section">
          {jwtToken && (
            <FileManagerComponent
              ajaxSettings={{
                url: fsApiUrl
              }}
              allowMultiSelection={false}
              beforeDownload={beforeDownload(jwtToken)}
              beforeImageLoad={beforeImageLoad}
              beforeSend={beforeSend(jwtToken)}
              contextMenuSettings={{
                folder: ['Open', 'Rename', 'Delete'],
                file: [],
                layout: ['SortBy', 'View', 'Refresh', '|', 'NewFolder']
              }}
              fileOpen={handleFileOpen}
              id="file_storage"
              toolbarSettings={{
                items: ['NewFolder', 'SortBy', 'Refresh', 'Selection', 'View']
              }}
            >
              <Inject services={[DetailsView, Toolbar]} />
            </FileManagerComponent>
          )}
        </div>
      </Box>
    </ErrorBoundaries>
  );
};

SelectFolderFileManager.propTypes = {
  projectId: string.isRequired,
  onSelectFolder: func.isRequired
};

export default SelectFolderFileManager;
