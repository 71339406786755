import { makeStyles } from '@material-ui/core';

import { DispatchTheme, ElementSizes, ElementZIndex } from '@dispatch/Dispatch.styles';

// these styles shared by DailyView and WeekView
export const useStyles = makeStyles(theme => ({
  scrollContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'scroll',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  board: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.default
  },
  boardFilterContainer: {
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    background: theme.palette.background.default,
    zIndex: ElementZIndex.boardFilterContainer,
    height: ElementSizes.stickyHeaderHeight,
    maxHeight: ElementSizes.stickyHeaderHeight,
    width: ElementSizes.techCellWidth,
    marginBottom: (ElementSizes.stickyHeaderHeight + 1) * -1,
    borderBottom: DispatchTheme.mixins.mainBorder,
    borderRight: DispatchTheme.mixins.mainBorder,
    position: 'sticky',
    left: -1,
    top: 0
  }
}));
