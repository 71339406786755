import React, { useMemo } from 'react';

import { AddCircleOutlineOutlined } from '@material-ui/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { column, ColumnType, valueGetters } from 'components/XGrid/columnTypes';
import { noNewLineValueSetter } from 'components/XGrid/columnUtils';
import JobBillingStatusChip from 'scenes/JobCloseout/JobCloseoutHeader/JobBillingStatusChip';
import { featureFlagFilter } from 'scenes/Jobs/utils';
import { getServiceChannelExternalLink } from 'scenes/ServiceChannel/utils';
import { JOB_CLOSEOUT_STATUS, MultiSelectTypes } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

const formatReviewStatusText = reviewStatus => {
  return reviewStatus?.replaceAll(' ', '_')?.toUpperCase();
};

const jobColumns = [
  {
    field: 'customIdentifier',
    headerName: 'Job',
    width: 100,
    valueGetter: valueGetters.jobLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'visitCount',
    headerName: 'Visits',
    width: 100,
    ...column[ColumnType.NUMBER]
  },
  {
    field: 'outstandingBalance',
    headerName: 'Outstanding Balance',
    width: 200,
    isBalance: true,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'overdueBalance',
    headerName: 'Overdue Balance',
    width: 200,
    isBalance: true,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'totalBilledJobComputed',
    headerName: 'Total Billed for Job',
    width: 200,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'departments',
    headerName: 'Departments',
    width: 150,
    enumType: MultiSelectTypes.DEPARTMENTS,
    ...column[ColumnType.TAGS]
  },
  {
    field: 'jobTypeName',
    headerName: 'Job Type',
    width: 150,
    enumType: MultiSelectTypes.JOB_TYPES,
    ...column[ColumnType.TAG]
  },
  {
    ldFlag: FeatureFlags.JOB_CLOSEOUT,
    field: 'billingType',
    headerName: 'Billing Type',
    width: 200,
    enumType: EnumType.JOB_BILLING_TYPE,
    ...column[ColumnType.ENUM],
    valueGetter: ({ value, row }) => (row?.closeoutReport && value ? value.toLowerCase() : '')
  },
  {
    field: 'jobTags',
    headerName: 'Tags',
    width: 100,
    enumType: MultiSelectTypes.JOB_TAGS,
    ...column[ColumnType.TAGS]
  },
  {
    field: 'customerName',
    headerName: 'Customer',
    width: 150,
    valueGetter: valueGetters.customerLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'billingCustomerName',
    headerName: 'Billing Customer',
    width: 150,
    valueGetter: valueGetters.billingCustomerLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'propertyName',
    headerName: 'Property',
    width: 150,
    valueGetter: valueGetters.propertyLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'createdDate',
    headerName: 'Created On',
    width: 150,
    ...column[ColumnType.DATE]
  },
  {
    field: 'completedDate',
    headerName: 'Job Completion Date',
    width: 200,
    ...column[ColumnType.DATE]
  },
  {
    field: 'amountQuoted',
    headerName: 'Amount Quoted',
    width: 175,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'status',
    headerName: 'Completion Status',
    width: 150,
    enumType: EnumType.JOB_STATUS,
    showIcon: true,
    ...column[ColumnType.ENUM]
  },
  {
    ldFlag: FeatureFlags.JOB_PROCUREMENT_STATUS,
    field: 'computedProcurementStatus',
    headerName: 'Procurement Status',
    width: 200,
    enumType: EnumType.JOB_PROCUREMENT_STATUS,
    showIcon: true,
    noLabelFormat: true,
    ...column[ColumnType.ENUM]
  },
  {
    ldFlag: FeatureFlags.JOB_CLOSEOUT,
    field: 'reviewStatus',
    headerName: 'Review Status',
    width: 200,
    enumType: EnumType.JOB_CLOSEOUT_STATUS,
    showIcon: true,
    valueGetter: ({ value, row }) =>
      row?.closeoutReport ? JOB_CLOSEOUT_STATUS[formatReviewStatusText(value)] : null,
    ...column[ColumnType.ENUM]
  },
  {
    ldFlag: FeatureFlags.JOB_QUOTE_STATUS,
    field: 'computedQuoteStatus',
    headerName: 'Quote Status',
    width: 200,
    enumType: EnumType.JOB_QUOTE_STATUS,
    showIcon: true,
    ...column[ColumnType.ENUM]
  },
  {
    ldFlag: FeatureFlags.JOB_CLOSEOUT,
    field: 'billingStatus',
    headerName: 'Invoicing Status',
    width: 200,
    enumType: EnumType.INVOICE_BILLING_STATUS,
    showIcon: true,
    ...column[ColumnType.ENUM],
    valueGetter: ({ value, row }) => (row?.closeoutReport ? value : ''),
    renderCell: ({ value }) =>
      value ? <JobBillingStatusChip job={{ billingStatus: value }} /> : ''
  },
  {
    field: 'projectManagerName',
    headerName: 'Project Manager',
    width: 175,
    type: 'string'
  },
  {
    field: 'accountManagerValue',
    headerName: 'Account Manager',
    width: 150,
    type: 'string'
  },
  {
    field: 'soldByName',
    headerName: 'Sold By',
    width: 175,
    type: 'string'
  },
  {
    field: 'propertyAddress',
    headerName: 'Address',
    width: 200,
    ...column[ColumnType.ADDRESS]
  },
  {
    field: 'amountNotToExceed',
    headerName: 'Amount Not To Exceed',
    width: 200,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'authorizedByName',
    headerName: 'Authorized By',
    width: 150,
    type: 'string'
  },
  {
    field: 'bestContact',
    headerName: 'Best Contact Method',
    width: 200,
    type: 'string'
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 150,
    type: 'string'
  },
  {
    field: 'invoices',
    headerName: 'Invoices',
    width: 150,
    ...column[ColumnType.LINK],
    valueFormatter: ({ value }) =>
      value
        ?.map(i => i.label)
        ?.filter(Boolean)
        ?.join(', '),
    valueGetter: ({ value }) =>
      value?.map?.(i => ({
        label: i.invoiceNumber,
        to: `/invoice/view/${i.id}`
      }))
  },
  {
    field: 'notesCount',
    headerName: 'Job Notes',
    width: 200,
    headerAlign: 'left',
    align: 'left',
    ...column[ColumnType.NUMBER],
    valueFormatter: ({ value }) => (value ? `${value} note${value > 1 ? 's' : ''}` : '-'),
    renderCell: ({ value = 0 }) => (
      <div className="LinkButton">
        <AddCircleOutlineOutlined fontSize="small" />
        {`Add or View Notes (${value || 0})`}
      </div>
    )
  },
  {
    field: 'priority',
    headerName: 'Priority',
    width: 150,
    enumType: MultiSelectTypes.PRIORITY_STATUS,
    ...column[ColumnType.TAG]
  },
  {
    field: 'customerRepName',
    headerName: 'Property Representative',
    width: 200,
    type: 'string'
  },
  {
    field: 'customerProvidedPONumber',
    headerName: 'Customer Purchase Order #',
    width: 175,
    type: 'string'
  },
  {
    field: 'customerProvidedWONumber',
    headerName: 'Customer Work Order #',
    width: 150,
    type: 'string'
  },
  {
    field: 'serviceAgreementNumber',
    headerName: 'Service Agreement #',
    width: 200,
    valueGetter: valueGetters.serviceAgreementLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'serviceAgreementName',
    headerName: 'Service Agreement Name',
    width: 230,
    valueGetter: valueGetters.serviceAgreementLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'serviceAgreementStatus',
    headerName: 'Service Agreement Status',
    width: 230,
    enumType: EnumType.SERVICE_AGREEMENT_STATUS,
    ...column[ColumnType.ENUM]
  },
  {
    field: 'issueDescription',
    headerName: 'Issue Description',
    width: 300,
    ...noNewLineValueSetter,
    type: 'string'
  },
  {
    // TODO: revisit this setting after adding launch darkly feature flags property to xGrid
    ldFlag: FeatureFlags.SERVICE_CHANNEL_INTEGRATION,
    field: 'serviceChannelWorkOrderWOId',
    headerName: 'Service Channel',
    width: 175,
    type: 'string',
    renderCell: ({ value }) => (
      <a href={getServiceChannelExternalLink(value)} rel="noreferrer" target="_blank">
        {value}
      </a>
    )
  }
];

export const useJobColumns = () => {
  const flags = useFlags();
  return useMemo(() => featureFlagFilter(flags)(jobColumns), [flags]);
};
