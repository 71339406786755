import React from 'react';

import NeedsReview from 'assets/Icons/NeedsReview.svg';
import Reviewed from 'assets/Icons/Reviewed.svg';
import { PermissionConstants, VISIT_REVIEW_STATUS } from 'utils/AppConstants';

export const invoiceRows = [
  {
    id: 'invoiceNumber',
    numeric: false,
    disablePadding: true,
    label: 'Invoice #',
    showLink: 'view',
    linkPath: '/invoice/view',
    linkReference: 'id'
  },
  {
    id: 'includesVisit',
    numeric: false,
    disablePadding: true,
    label: 'Visits Included'
  },
  {
    id: 'totalAmount',
    numeric: false,
    disablePadding: true,
    caslKey: PermissionConstants.DATA_VIEW_PRICE_DATA,
    caslAction: 'allow',
    type: 'currency',
    label: 'Amount'
  },
  {
    id: 'createdBy',
    numeric: false,
    disablePadding: true,
    label: 'Created by'
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status'
  },
  {
    id: 'issuedDate',
    numeric: false,
    disablePadding: true,
    type: 'date',
    label: 'Issue date'
  },
  {
    id: 'dueDate',
    numeric: false,
    type: 'date',
    disablePadding: true,
    label: 'Due date'
  }
];

export const reportRows = ({ closeoutReport }) =>
  [
    {
      id: 'action',
      numeric: false,
      disablePadding: true,
      label: '',
      isCustom: true,
      type: 'action'
    },
    {
      id: 'visitNumber',
      numeric: false,
      disablePadding: true,
      label: 'Visit'
    },
    {
      id: 'description',
      align: 'center',
      disablePadding: true,
      label: 'Work to be performed'
    },
    {
      id: 'status',
      align: 'center',
      disablePadding: true,
      label: 'Status'
    },
    closeoutReport
      ? {
          // TODO fill out this field with visit status->pdf status->pdf link as part of BUOP-14583
          id: 'techReport',
          align: 'center',
          disablePadding: 'true',
          label: 'Technician Report',
          isCustom: true,
          type: 'TechReport',
          caslKey: PermissionConstants.OBJECT_VISIT
        }
      : {
          id: 'technicianReport',
          align: 'center',
          disablePadding: true,
          label: 'Technician Report',
          showLink: 'view',
          linkPath: `/technicianreport/view`,
          caslKey: PermissionConstants.OBJECT_VISIT,
          linkReference: 'id',
          linkStateKey: 'recordSortKey',
          maxTextLen: 60
        },
    closeoutReport
      ? {
          id: 'visitNumber',
          align: 'center',
          disablePadding: 'true',
          label: 'Visit Report',
          showLink: true,
          baseLinkPath: '/jobreport',
          linkConnector: 'visit',
          caslKey: PermissionConstants.OBJECT_VISIT,
          baseLinkReference: 'parentId',
          detailLinkReference: 'id',
          linkParams: ['jobCloseoutType'],
          valueFormatter: visitNumber => `Visit ${visitNumber}`
        }
      : {
          id: 'reviewedReport',
          align: 'center',
          disablePadding: true,
          label: 'Reviewed report',
          showLink: 'view',
          linkPath: `/reviewreport/view`,
          caslKey: PermissionConstants.OBJECT_REVIEWREPORT,
          linkReference: 'reviewReportId',
          linkStateKey: 'recordSortKey',
          maxTextLen: 60
        },
    closeoutReport
      ? {
          id: 'reviewStatus',
          align: 'center',
          disablePadding: 'true',
          label: 'Review Status',
          type: 'iconenum',
          labelFormatter: reviewStatus => {
            switch (reviewStatus) {
              case VISIT_REVIEW_STATUS.UNREVIEWED: {
                return 'Review Needed';
              }
              case VISIT_REVIEW_STATUS.REVIEWED: {
                return 'Reviewed';
              }
              default: {
                return '-';
              }
            }
          },
          getBackgroundColor: ({ data, theme }) => {
            switch (data) {
              case VISIT_REVIEW_STATUS.UNREVIEWED: {
                return theme.palette.support.yellow.dark;
              }
              case VISIT_REVIEW_STATUS.REVIEWED: {
                return theme.palette.support.green.dark;
              }
              default: {
                return theme.palette.support.grey.dark;
              }
            }
          },
          getTextColor: ({ theme, data }) => {
            switch (data) {
              case VISIT_REVIEW_STATUS.UNREVIEWED: {
                return 'white';
              }
              case VISIT_REVIEW_STATUS.REVIEWED: {
                return 'white';
              }
              default: {
                return 'white';
              }
            }
          },
          getIcon: ({ theme, data }) => {
            switch (data) {
              case VISIT_REVIEW_STATUS.UNREVIEWED: {
                return (
                  <img
                    alt="reviewStatus"
                    css={{
                      height: 20,
                      filter:
                        // see https://codepen.io/sosuke/pen/Pjoqqp for calculating colors for svgs that start as black
                        'invert(100%) sepia(100%) saturate(2%) hue-rotate(68deg) brightness(103%) contrast(101%)'
                    }}
                    src={NeedsReview}
                  />
                );
              }
              case VISIT_REVIEW_STATUS.REVIEWED: {
                return (
                  <img
                    alt="reviewStatus"
                    css={{
                      height: 20,
                      filter:
                        // see https://codepen.io/sosuke/pen/Pjoqqp for calculating colors for svgs that start as black
                        'invert(100%) sepia(100%) saturate(2%) hue-rotate(68deg) brightness(103%) contrast(101%)'
                    }}
                    src={Reviewed}
                  />
                );
              }
              default: {
                return <></>;
              }
            }
          }
        }
      : null
  ].filter(Boolean);

export const partProcurementsRows = [
  {
    id: 'visitNumber',
    numeric: false,
    label: 'Visit'
  },
  {
    id: 'origin',
    label: 'Origin',
    numeric: false
  },
  {
    id: 'vendorName',
    numeric: false,
    label: 'Vendor'
  },
  {
    id: 'description',
    numeric: false,
    label: 'description'
  },
  {
    id: 'quantity',
    numeric: false,
    label: 'Quantity'
  },
  {
    id: 'unitCost',
    numeric: false,
    type: 'currency',
    caslKey: PermissionConstants.DATA_VIEW_COST_DATA,
    caslAction: 'allow',
    label: 'Unit Cost'
  },
  {
    id: 'markup',
    type: 'percentage',
    numeric: true,
    label: 'Markup'
  },
  {
    id: 'amount',
    type: 'currency',
    label: 'Amount'
  },
  {
    id: 'source',
    numeric: false,
    label: 'Source',
    isCustom: true,
    type: 'LinkButton'
  }
];

export default invoiceRows;
