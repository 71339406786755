import React, { memo, useRef } from 'react';

import { connect } from 'react-redux';

import { useDispatchTechs } from '@dispatch/queries';
import { snackbarOn } from 'redux/actions/globalActions';

import ManDayEditForm from './ManDayEditForm.component';

const mapDispatchToProps = {
  snackbarOn
};

const DataProvider = props => {
  const Memoized = useRef(memo(ManDayEditForm)).current;

  const techsResponse = useDispatchTechs();

  return <Memoized {...props} techsResponse={techsResponse} />;
};

export default connect(null, mapDispatchToProps)(DataProvider);
