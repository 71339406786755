import React from 'react';

import { theme, TV } from '@BuildHero/sergeant';
import FlagIcon from '@material-ui/icons/Flag';

export const CornerBannerTypes = {
  ToDo: 'ToDo',
  Flagged: 'Flagged'
};

export const CornerBannerConfig = {
  [CornerBannerTypes.ToDo]: {
    icon: null,
    text: 'To-Do',
    TV: TV.S1,
    textColor: 'white',
    hasCheckbox: true,
    background: theme.palette.grayscale(20)
  },
  [CornerBannerTypes.Flagged]: {
    icon: <FlagIcon css={{ fontSize: '10px' }} />,
    text: 'Flagged',
    TV: TV.S2,
    textColor: 'white',
    hasCheckbox: false,
    background: theme.palette.support.red.dark
  }
};
