import React, { useCallback, useState } from 'react';

import { useTheme } from '@material-ui/core/styles';

import { getParents, getTransparentBackgroundCss } from 'utils';

// This HOC expects a wrapped component that forwards a ref to the component whose
// computed background color is of interest. The wrapper passes the ascertained
// computed background color to the wrapped component via a prop `selfBackgroundColor`.

// Rationale: In some cases we want to find the background color to set certain
// styles on child elements accordingly. However, naively trying to introspect on
// the element itself (for example, by using `window.getComputedStyle()`) will
// not work for getting the true background color, since it will return transparent
// (e.g. `rgba(0, 0, 0, 0)` in Chrome). Instead we need to find the first parent whose
// background-color style is anything other than transparent.

// NOTE: This works in most but not all cases (e.g. if the wrapped component is absolutely positioned).
function withBackgroundColor(WrappedComponent) {
  return function WithBackgroundColor(props) {
    const theme = useTheme();
    const [backgroundColor, setBackgroundColor] = useState(theme.palette.background.default);
    const computedBackgroundRef = useCallback(
      content => {
        if (!content) return;
        const parents = getParents(content);
        const transparentBackground = getTransparentBackgroundCss();
        // Find **closest** parent with non-transparent background
        const backgroundElement = parents.find(
          parent => window.getComputedStyle(parent).backgroundColor !== transparentBackground
        );
        const newBackgroundColor = backgroundElement
          ? window.getComputedStyle(backgroundElement).backgroundColor
          : theme.palette.background.default;
        setBackgroundColor(newBackgroundColor);
      },
      [theme.palette.background.default]
    );
    return (
      <WrappedComponent
        ref={computedBackgroundRef}
        selfBackgroundColor={backgroundColor}
        {...props}
      />
    );
  };
}

export default withBackgroundColor;
