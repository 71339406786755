import gql from 'graphql-tag';

import { updateWatchedManDayVisits } from '@dispatch/Dispatch.utils';
import { manDayFragment } from '@dispatch/fragments/manDayFragment';
import useExtendedMutation from 'customHooks/useExtendedMutation';

const ADD_MAN_DAY_MUTATION = gql`
  mutation addManDays($data: AddManDayInput!) {
    addManDays(data: $data) {
      ${manDayFragment}
    }
  }
`;

const serializer = data => {
  const {
    projectId,
    startDateTime,
    endDateTime,
    technicianIds,
    excludeWeekends,
    description
  } = data;
  return {
    variables: {
      data: {
        projectId,
        startDateTime,
        endDateTime,
        technicianIds,
        excludeWeekends,
        description
      }
    }
  };
};

const useAddManDay = () => {
  return useExtendedMutation(ADD_MAN_DAY_MUTATION, {
    serializer,
    update: (cache, { data }) => {
      updateWatchedManDayVisits(cache, data.addManDays);
    }
  });
};

export default useAddManDay;
