import React from 'react';

import { Box, IconButton } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const ItemActions = ({ isVisible, handleSave, index, handleCancel }) => {
  if (!isVisible) return null;
  const iconStyle = { padding: '6px 9px', marginLeft: 8 };

  return (
    <Box display="flex">
      <IconButton style={iconStyle} onClick={() => handleSave(index)}>
        <CheckIcon color="secondary" />
      </IconButton>
      <IconButton style={iconStyle} onClick={() => handleCancel(index)}>
        <ClearIcon color="error" />
      </IconButton>
    </Box>
  );
};

export default ItemActions;
