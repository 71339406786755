import gql from 'graphql-tag';

export const taskEntriesFragment = `
  taskEntries {
    nextToken
    items {
      id
      sortKey
      sortOrder
      version
      isActive
      entityType
      productId
      name
      description
      markupType
      markupValue
      unitPrice
      unitCost
      quantity
      taxable
      product {
          id
          name
          description
          taxable
          costCodeId
          revenueTypeId
          jobCostTypeId
          code
        }
    }
  }
`;

export const formDataFragment = `
  formData {
    items {
      id
      isRequired
      sortOrder
      formDataJson
      form {
        id
        name
        description
        latestPublishedFormDefinition {
          id
        }
        latestPublishedFormDefinitionSortKey
        sortKey
      }
      formDefinition {
        formDefinitionJson
      }
    }
  }
`;

export const getTasksByCustomerProperty = gql`
  query getTasksByPropertyAndListType(
    $customerPropertyId: String!
    $listType: TaskListType!
    $includeAuditLogs: Boolean!
    $includeJob: Boolean!
    $includeQuote: Boolean!
    $filter: TableFilterInput
    $pagination: PaginationInput
    $sorting: [TableSortingInput]
  ) {
    data: getTasksByPropertyAndListType(
      customerPropertyId: $customerPropertyId
      listType: $listType
      pagination: $pagination
      filter: $filter
      sorting: $sorting
    ) {
      rowCount
      items {
        parentId
        parentEntity {
          id
        }
        id
        version
        name
        createdBy
        createdDateTime
        lastUpdatedBy
        lastUpdatedDateTime
        nextDueDate
        description
        departmentId
        departmentValue
        taskTypeId
        taskTypeValue
        taskTypeInternal
        taskNumber
        status
        jobTypeId
        jobTypeValue
        assetId
        asset {
          assetName
        }
        visit {
          id
          parentEntity {
            ... on Job {
              id
              jobNumber
            }
          }
        }
        productBundleId
        productBundleValue
        isActive
        total: aggregatedField(
          input: {
            entityConnection: "TaskEntry"
            isDirectChildren: true
            aggregations: [
              { aggregationType: SUM, aggregationField: "TaskEntry.unitPrice * TaskEntry.quantity" }
            ]
          }
        ) {
          items {
            total: aggregation1
          }
        }
        ${taskEntriesFragment}
        ${formDataFragment}
        auditLogEntries(entityConnection: "AuditLogEntry") @include(if: $includeAuditLogs) {
          items {
            auditedEntityType
            auditedEntityDisplayName
            executionType
            executedBy
            executedDateTime
            auditedEntityId
            auditedEntitySortKey
            auditedEntityParentId
            auditedEntityParentSortKey
            auditedEntityParentEntityType
            auditedEntityParentDisplayName
            changeLog
          }
        }
        jobs @include(if: $includeJob) {
          id
          customIdentifier
          jobNumber
          jobTypeInternal
        }
        quote @include(if: $includeQuote) {
          id
          quoteNumber
          customIdentifier
        }
      }
    }
  }
`;

export const getCustomerPropertyTasksCountByType = gql`
  query getTasksByPropertyAndListType($customerPropertyId: String!, $listType: TaskListType!) {
    getTasksByPropertyAndListType(customerPropertyId: $customerPropertyId, listType: $listType) {
      rowCount
    }
  }
`;
