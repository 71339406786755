import React from 'react';

import { Typography, withStyles } from '@material-ui/core';
import LocationOn from '@material-ui/icons/LocationOn';

import { convertDistance, getDistance } from 'geolib';
import _ from 'lodash';
import { connect } from 'react-redux';

import { ResponsiveTable } from 'components';

import invoiceLabels from 'meta/Jobs/Invoice/labels';
import { manualTimeTrackingRows } from 'meta/Jobs/Invoice/review-report-tables';
import ErrorBoundaries from 'scenes/Error';
import { backendDateToMoment } from 'utils';
import { getDateFormat, ManualTimesheetWorkTypes, PermissionConstants } from 'utils/AppConstants';

import { useTimekeepingLedger } from '../queries/useTimekeepingLedger';

import styles from './TimeTrackingTable/styles';

const visitActionTypeMapping = {
  Working: 'Work',
  Traveling: 'Travel',
  OnABreak: 'Break',
  Paused: 'Pause'
};

function ManualTimeTrackingTable(props) {
  const DateWithLocation = ({ meta, record }) => {
    const momentObj = record[meta.id] ? backendDateToMoment(record[meta.id]) : null;
    const dateText = momentObj && momentObj.format(getDateFormat('datetime'));
    if (record?.startLocation?.latitude || record?.endLocation?.latitude) {
      const coordinates =
        meta.label === 'Start'
          ? {
              latitude: record?.startLocation?.latitude,
              longitude: record?.startLocation?.longitude
            }
          : {
              latitude: record?.endLocation?.latitude,
              longitude: record?.endLocation?.longitude
            };
      const propertyCoordinates = record.propertyLocation;

      if (
        coordinates?.longitude &&
        coordinates?.latitude &&
        propertyCoordinates?.longitude &&
        propertyCoordinates?.latitude
      ) {
        const distance = getDistance(coordinates, propertyCoordinates, 100);
        const url = `https://www.google.com/maps/dir/${coordinates.latitude},${coordinates.longitude}/${propertyCoordinates.latitude},${propertyCoordinates.longitude}`;
        return (
          <div className={props.classes.textStyle}>
            {dateText}
            <br />
            <LocationOn className={props.classes.locationStyle} />
            <a
              className={props.classes.locationText}
              href={url}
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              {`${convertDistance(distance, 'mi').toFixed(2)} mi from job`}
            </a>
          </div>
        );
      }
    }
    return <div className={props.classes.textStyle}>{dateText}</div>;
  };

  const { classes, user, isReviewReport, context } = props;

  if (context.isAutomatedTimeTrackingEnabled) return null;

  const { jobInfoData } = context;

  const { data: locationData } = useTimekeepingLedger(context?.visit?.id || context?.reportId);
  const timeSheetsFromVisits = context.timeSheets.filter(
    sheet => sheet.labourType && sheet.labourType !== ManualTimesheetWorkTypes.COMPLETE
  );

  const timeSheetsFromNonvisitEvents = context.nonVisitEvents.reduce((acc, nve) => {
    const nveSheets =
      nve?.timekeepingLedgersView?.items.map(item => ({
        createdBy: item.employeeName,
        clockInTime: item.actualStartTimeUTC,
        clockOutTime: item.actualEndTimeUTC,
        labourType: ManualTimesheetWorkTypes.NONVISIT
      })) || [];

    return [...acc, ...nveSheets];
  }, []);

  const timeSheets = [...timeSheetsFromVisits, ...timeSheetsFromNonvisitEvents];

  const sortedTimesheets = _.sortBy(timeSheets, 'clockInTime');

  const formatDifferenceInSecsAsText = seconds => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    return `${hrs || 0}h ${mins || 0}m`;
  };

  const getTimeDifference = (startTime, endTime) => {
    if (!startTime || !endTime) {
      return { timeDifferenceInSeconds: 0, timeDifferenceInText: '-' };
    }

    const timeDifferenceInSeconds = endTime - startTime;

    return {
      timeDifferenceInSeconds,
      timeDifferenceInText: formatDifferenceInSecsAsText(timeDifferenceInSeconds)
    };
  };

  const totalsByType = (total, val, actionType) => {
    let localSum = total;
    if (val[actionType]) {
      localSum = Math.round(total) + Math.round(val.timeDifferenceInSeconds);
    }
    return localSum;
  };

  const propertyAddress = jobInfoData?.parentEntity?.companyAddresses?.items?.find(
    addr => addr.addressType === 'propertyAddress'
  );

  const processedTimesheets = sortedTimesheets.map(entry => {
    const localEntry = entry;
    localEntry.startTime = localEntry?.clockInTime;
    localEntry.endTime = localEntry?.clockOutTime;
    const actionType =
      entry.labourType === ManualTimesheetWorkTypes.WORKDONE
        ? ManualTimesheetWorkTypes.WORKING
        : entry.labourType;
    const eventType = visitActionTypeMapping[actionType];

    // Find matched technician location data from TimekeepingLedger
    const technicianLocation = locationData?.find(
      ledger =>
        ledger?.actualStartTimeUTC === localEntry?.clockInTime &&
        ledger?.actualEndTimeUTC === localEntry?.clockOutTime &&
        ledger?.userActionType === eventType
    );

    if (technicianLocation) {
      const { startLatitude, startLongitude, endLatitude, endLongitude } = technicianLocation;
      localEntry.startLocation = {
        latitude: startLatitude,
        longitude: startLongitude
      };
      localEntry.endLocation = {
        latitude: endLatitude,
        longitude: endLongitude
      };
    }

    const timeDifference = getTimeDifference(localEntry?.clockInTime, localEntry?.clockOutTime);
    localEntry.timeDifference = timeDifference;
    localEntry[actionType] = timeDifference?.timeDifferenceInText;
    localEntry.timeDifferenceInSeconds = timeDifference?.timeDifferenceInSeconds;
    if (propertyAddress) {
      localEntry.propertyLocation = {
        latitude: propertyAddress?.latitude,
        longitude: propertyAddress?.longitude
      };
    }
    return localEntry;
  });

  // Generate totals by work action type
  const totalsEntry = { createdBy: 'Totals' };
  Object.values(ManualTimesheetWorkTypes).forEach(laborType => {
    totalsEntry[laborType] = formatDifferenceInSecsAsText(
      timeSheets.reduce((total, val) => totalsByType(total, val, laborType), 0)
    );
  });

  return (
    <ErrorBoundaries>
      <Typography className={classes.greySectionTitle}>
        {invoiceLabels.timeTracking[user.locale]}
      </Typography>
      <ResponsiveTable
        caslKey={
          isReviewReport
            ? PermissionConstants.OBJECT_REVIEWREPORT
            : PermissionConstants.OBJECT_VISIT
        }
        customCellComponents={{ DateWithLocation }}
        data={[...processedTimesheets, totalsEntry]}
        disableFilter
        disablePagination
        noDataMsg="No items"
        noMaxHeight
        rowMetadata={manualTimeTrackingRows}
      />
    </ErrorBoundaries>
  );
}

const styledManualTimeTrackingTable = withStyles(styles)(ManualTimeTrackingTable);

const mapStateToProps = state => ({
  user: state.user
});

const reduxConnectedManualTimeTrackingTable = connect(
  mapStateToProps,
  null
)(styledManualTimeTrackingTable);

export default reduxConnectedManualTimeTrackingTable;
