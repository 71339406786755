import React from 'react';

import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  root: {
    padding: '23px 0px 12px 17px',
    height: 63
  },
  titleText: {
    color: '#333333',
    fontSize: 24,
    fontWeight: 'bold',
    letterSpacing: '-0.05em',
    lineHeight: '28px'
  },
  divider: {
    marginTop: 12
  }
}));

export default function SubmenuTitle({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.titleText}>{children}</Typography>
      <Divider className={classes.divider} />
    </div>
  );
}
