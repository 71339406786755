import { TW } from '@BuildHero/sergeant';

export const useStyles = () => {
  return {
    radioGroup: {
      margin: '-10px 0 18px 0'
    },
    radioButton: theme => ({
      '& [class^="MuiTypography-root"]': {
        ...theme.typography.body1,
        fontWeight: TW.MEDIUM
      }
    })
  };
};
