export const budgetRowMeta = [
  {
    id: 'number',
    label: 'Number',
    type: 'number'
  },
  {
    id: 'name',
    label: 'Phase',
    showLink: 'view',
    linkPath: 'projectsettings/phase',
    linkReference: 'phase',
    type: 'string'
  },
  {
    id: 'startDate',
    label: 'Start Date',
    type: 'date'
  },
  {
    id: 'endDate',
    label: 'End Date',
    type: 'date'
  },
  {
    id: 'percentComplete',
    label: 'Progress',
    type: 'percentage'
  }
];
