import React, { useEffect, useState } from 'react';

import { Input } from '@BuildHero/sergeant';
import algoliasearch from 'algoliasearch/lite';
import PropTypes from 'prop-types';
import {
  Configure,
  connectAutoComplete,
  connectInfiniteHits,
  InstantSearch
} from 'react-instantsearch-dom';

import configForEnvironment from 'configs/aws-exports';
import ENV from 'configs/env';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import KEY_QUERY from 'services/core/graphql/common/queries/getAlgoliaSecuredKey';

import AutoComplete from './AutoComplete';
import InfiniteHits from './InfiniteSearch';

const CustomAutoComplete = connectAutoComplete(AutoComplete);
const CustomInfiniteHits = connectInfiniteHits(InfiniteHits);

const useAlgoliaSecuredKey = () =>
  useExtendedQuery(KEY_QUERY, {
    transform: data => data.getAlgoliaSecuredKey
  });

export default function AlgoliaSearch({
  testingid,
  name,
  value,
  indexName,
  filters,
  style,
  configureProps,
  ...inputProps
}) {
  const [client, setClient] = useState();
  const { data: key } = useAlgoliaSecuredKey();

  useEffect(() => {
    const configure = async () => {
      setClient(algoliasearch(configForEnvironment(ENV).algoliaAppKey, key));
    };
    if (key) configure();
  }, [key]);

  return !key || !client ? (
    <Input
      disabled
      label={inputProps.label}
      name={name}
      placeholder="Loading..."
      required={inputProps.required}
      style={style}
    />
  ) : (
    <InstantSearch
      indexName={indexName}
      root={{
        Root: 'section',
        props: {
          style
        }
      }}
      searchClient={client}
    >
      <CustomAutoComplete
        CustomInfiniteHits={CustomInfiniteHits}
        defaultRefinement={value}
        name={name}
        testingid={testingid}
        value={value}
        {...inputProps}
      />
      <Configure filters={filters} hitsPerPage={10} {...configureProps} />
    </InstantSearch>
  );
}

AlgoliaSearch.propTypes = {
  testingid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  indexName: PropTypes.string.isRequired,
  filters: PropTypes.string.isRequired,
  style: PropTypes.object,
  configureProps: PropTypes.object,
  formatHitLabel: PropTypes.func.isRequired,
  formatHit: PropTypes.func,
  onClickAway: PropTypes.func,
  onChange: PropTypes.func,
  handleRenderOption: PropTypes.func
};

AlgoliaSearch.defaultProps = {
  testingid: undefined,
  value: undefined,
  style: undefined,
  configureProps: undefined,
  formatHit: undefined,
  onClickAway: undefined,
  onChange: undefined,
  handleRenderOption: undefined
};
