import React, { useCallback } from 'react';

import XGrid from 'components/XGrid';
import useEagerMultiNotes from 'customHooks/useEagerMultiNotes/useEagerMultiNotes';
import { useTrigger } from 'customHooks/useTrigger';

import { useJobColumns } from './JobsTable.columns';
import { GET_JOBS } from './JobsTable.query';

const JobsTable = xGridProps => {
  const columns = useJobColumns();
  const [subscribe, trigger] = useTrigger();
  const { MultiNoteModal, openMultiNoteModal } = useEagerMultiNotes(trigger, true);

  const handleCellClick = useCallback(
    ({ row, field, value }) => {
      const { jobNumber, customIdentifier, id, version } = row;
      if (field === 'notesCount')
        openMultiNoteModal({
          name: customIdentifier || jobNumber,
          id,
          entityType: 'Job',
          notesCount: value,
          version
        });
    },
    [openMultiNoteModal]
  );

  return (
    <>
      <XGrid
        columns={columns}
        enableExport
        entityTypeName="Jobs"
        query={GET_JOBS}
        refetchTrigger={subscribe}
        onCellClick={handleCellClick}
        {...xGridProps}
      />
      {MultiNoteModal}
    </>
  );
};

JobsTable.defaultProps = {
  tableName: 'JobsXGrid'
};

export default JobsTable;
