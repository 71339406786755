import React from 'react';

import { Modal } from '@BuildHero/sergeant';
import PropTypes from 'prop-types';

import { AlgoliaSearch } from 'components';

const CustomerPropertyModal = ({
  defaultSearchIndex,
  handlePropertyChange,
  openPropertyModal,
  setOpenPropertyModal
}) => {
  return (
    <Modal
      open={openPropertyModal}
      title="Select a customer property to create quote: "
      onClose={() => setOpenPropertyModal(false)}
    >
      <div style={{ marginTop: 20, marginBottom: 200 }}>
        <AlgoliaSearch
          delimiter=" "
          displayText={['customerPropertyName', 'address']}
          filters={[
            {
              attribute: 'entityType',
              valueArray: ['CompanyAddress']
            },
            {
              attribute: 'parentEntityType',
              valueArray: ['CustomerProperty']
            },
            {
              attribute: 'addressType',
              valueArray: ['propertyAddress']
            },
            {
              attribute: 'parentStatus',
              valueArray: ['active']
            }
          ]}
          handleChange={customerProperty =>
            customerProperty && handlePropertyChange(customerProperty)
          }
          label="Search by customer property name or its address"
          locatedInPopUp
          name="selectProperty"
          searchIndex={defaultSearchIndex}
          suggestionMarginTop={156}
          value=""
        />
      </div>
    </Modal>
  );
};

CustomerPropertyModal.propTypes = {
  defaultSearchIndex: PropTypes.string,
  handlePropertyChange: PropTypes.func,
  openPropertyModal: PropTypes.bool,
  setOpenPropertyModal: PropTypes.func
};

CustomerPropertyModal.defaultProps = {
  defaultSearchIndex: '',
  handlePropertyChange: () => {},
  openPropertyModal: false,
  setOpenPropertyModal: () => {}
};

export default CustomerPropertyModal;
