import React, { useRef } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import { Paper } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { array, bool, func, object, string } from 'prop-types';

import { findById } from 'utils/ramda';

import {
  getDefaultIdFromMapping,
  getPhaseDeptCostCodesMapping
} from '../../LaborFieldForm.helpers';

import TimesheetEntriesField from './components/TimesheetEntriesField';
import { useTimesheetCardConfiguration } from './TimesheetCard.config';
import { useStyles } from './TimesheetCard.styles';

const TimesheetCard = ({
  employeeTimesheet,
  projectPhases,
  payrollHourTypes,
  onChange,
  variant,
  isDisabled
}) => {
  const defaultValueMapping = getPhaseDeptCostCodesMapping(projectPhases);
  const styles = useStyles();
  const projectPhasesRef = useRef();
  const defaultValueMappingRef = useRef();
  projectPhasesRef.current = projectPhases;
  defaultValueMappingRef.current = defaultValueMapping;

  const configuration = useTimesheetCardConfiguration({
    phaseId: employeeTimesheet?.projectPhaseId,
    departmentId: employeeTimesheet?.projectPhaseDepartmentId,
    projectPhases,
    payrollHourTypes,
    variant,
    isDisabled: employeeTimesheet?.isDisabled || isDisabled
  });

  const handleChange = timesheet => {
    const { projectPhaseId, projectPhaseDepartmentId, projectPhaseDepartmentCostCodeId } =
      timesheet || {};

    const phase = findById(projectPhaseId)(projectPhasesRef.current || []);
    const department = findById(projectPhaseDepartmentId)(phase?.ProjectPhaseDepartment);
    const costCode = findById(projectPhaseDepartmentCostCodeId)(
      department?.ProjectPhaseDepartmentCostCode
    );

    const defaultDepartmentId =
      getDefaultIdFromMapping(defaultValueMappingRef.current?.[projectPhaseId]) ||
      projectPhaseDepartmentId;
    const defaultCostCodeId = getDefaultIdFromMapping(
      defaultValueMappingRef.current?.[projectPhaseId]?.[defaultDepartmentId]
    );

    const isPhasesInitialized = projectPhasesRef.current?.length;
    onChange({
      ...timesheet,
      ...(isPhasesInitialized
        ? {
            projectPhaseDepartmentId: department ? department.id : defaultDepartmentId,
            projectPhaseDepartmentCostCodeId: costCode ? costCode.id : defaultCostCodeId
          }
        : {})
    });
  };

  return (
    <div css={styles.root}>
      <MUIForm
        configuration={configuration}
        customComponents={{ TimesheetEntriesField }}
        data={employeeTimesheet}
        layout="default"
        onFormChange={handleChange}
      />
      {employeeTimesheet?.isDuplicated && (
        <Paper css={styles.warning}>
          <div>
            <ErrorIcon />
          </div>
          <div>A timesheet with the same phase, department, and cost code already exists.</div>
          <div>Please zero hour values in order to save this report.</div>
        </Paper>
      )}
    </div>
  );
};

TimesheetCard.propTypes = {
  employeeTimesheet: object.isRequired,
  projectPhases: array.isRequired,
  payrollHourTypes: array.isRequired,
  onChange: func.isRequired,
  variant: string.isRequired,
  isDisabled: bool
};

TimesheetCard.defaultProps = {
  isDisabled: false
};

export default TimesheetCard;
