import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getCloudinaryImageUrl, getProfileInitials } from '@pm/components/utils';

const useStyles = makeStyles(theme => ({
  userProfileContainer: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '35px'
  },
  profilePic: {
    width: '35px',
    height: '35px',
    overflow: 'hidden',
    borderRadius: '50%',
    bottom: '0px',
    fontSize: 14,
    marginRight: 10
  },
  self: {
    color: theme.palette.grayscale(60)
  }
}));

const ProfileCard = ({ record, user }) => {
  const classes = useStyles();
  return record.name ? (
    <Box className={classes.userProfileContainer}>
      {record.profilePictureUrl ? (
        <Avatar
          alt={`${record.name ?? ''}`}
          className={classes.profilePic}
          src={getCloudinaryImageUrl(record.profilePictureUrl)}
        />
      ) : (
        <Avatar className={classes.profilePic}>
          {getProfileInitials(record.firstName || '', record.lastName || '')}
        </Avatar>
      )}
      <Box
        className={classNames(user.employeeId === record.employeeId && classes.self)}
      >{`${record.name ?? ''}`}</Box>
    </Box>
  ) : null;
};

ProfileCard.propTypes = {
  record: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({ user: state.user });
const ReduxConnectedProfileCard = connect(mapStateToProps)(ProfileCard);

export default ReduxConnectedProfileCard;
