import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Button, ButtonType, Field, FieldType, TV, TW, Typography } from '@BuildHero/sergeant';

import { makeStyles, MuiThemeProvider } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';

import { ResponsiveTable } from 'components';
import { snackbarOn } from 'redux/actions/globalActions';
import theme from 'themes/BuildHeroTheme';
import { roundCurrency } from 'utils';
import { EntityType, TransactionType } from 'utils/constants';
import { patchArray } from 'utils/patchArray';

import { InvoiceColumns, PaymentColumns } from './AdjustmentApplication.columns';
import { useTransactionQuery } from './AdjustmentApplication.gql';
import ApplicationCell from './ApplicationCell';

const Header = (
  <>
    <Typography style={{ marginBottom: 4 }} variant={TV.L} weight={TW.BOLD}>
      Apply Adjustment
    </Typography>
    <Typography style={{ maxWidth: 880 }}>
      An adjustment may be applied to either invoices or payments depending on the type of
      transaction. If the transaction type is an overpayment, the adjustment may be applied to
      payments. If the transaction type is a write-off or a refund, the adjustment may be applied to
      invoices.
    </Typography>
  </>
);

const getProps = ({ transactionType }) => {
  switch (transactionType) {
    case TransactionType.OVERPAYMENT: {
      return {
        loadButtonLabel: 'View Payments',
        columns: PaymentColumns,
        transactionEntity: EntityType.PAYMENT
      };
    }
    case TransactionType.REFUND:
    case TransactionType.WRITE_OFF: {
      return {
        loadButtonLabel: 'View Invoices',
        columns: InvoiceColumns,
        transactionEntity: EntityType.INVOICE
      };
    }
    default: {
      return {
        loadButtonLabel: 'View Payments/Invoices',
        loadButtonProps: {
          disabled: true,
          tooltip: 'You must select an adjustment type and billing customer'
        }
      };
    }
  }
};

const useStyle = makeStyles(({ spacing }) => ({
  loadButton: { marginTop: spacing(3) },
  customerLink: { marginTop: spacing(3), marginBottom: spacing(2), display: 'flex' }
}));

function AdjustmentApplication({ form: { values, setFieldValue }, snackbar }) {
  const classes = useStyle();

  const accountingApp = useSelector(state => state.settings.accountingApp);

  const {
    transactionType,
    billingCustomer,
    property,
    availableAmount,
    appliedAmount,
    transactions: givenApplications = []
  } = values;
  const [props, setProps] = useState(getProps({ transactionType }));
  const applicationMapRef = useRef(
    givenApplications.reduce((acc, val) => {
      acc[val.transactionId] = {
        id: val.id,
        transactionId: val.transactionId,
        appliedAmount: val.appliedAmount
      };
      return acc;
    }, {})
  );
  useEffect(() => {
    setFieldValue('applicationMapRef', applicationMapRef);
  }, [applicationMapRef, setFieldValue]);

  const { getTransactions, transactions, setTransactions, loading, error } = useTransactionQuery(
    billingCustomer?.id,
    props.transactionEntity,
    applicationMapRef,
    property?.id,
    accountingApp
  );
  const loaded = !!transactions;

  useEffect(() => {
    if (error) snackbar('error', 'Failed fetching transactions. Please try again.');
  }, [error, snackbar]);

  useEffect(() => {
    setProps(getProps({ transactionType }));
  }, [transactionType]);

  const LoadButton = useMemo(() => {
    const tooltip =
      (!billingCustomer?.customerName && 'You must select a billing customer') ||
      (loaded && 'Already loaded');
    return (
      <Button
        className={classes.loadButton}
        disabled={!!tooltip}
        loading={loading}
        testingid={`apply-adjustment-${transactionType}`}
        tooltip={tooltip}
        type={ButtonType.TERTIARY}
        {...props.loadButtonProps}
        onClick={() => getTransactions()}
      >
        {props.loadButtonLabel}
      </Button>
    );
  }, [
    loading,
    loaded,
    getTransactions,
    billingCustomer,
    props.loadButtonLabel,
    props.loadButtonProps,
    classes.loadButton
  ]);

  const availableAmountRef = useRef(availableAmount);
  useEffect(() => {
    availableAmountRef.current = availableAmount;
  }, [availableAmount]);

  const CustomerLink = useMemo(
    () =>
      loaded && (
        <Typography className={classes.customerLink} weight={TW.BOLD}>
          Available transactions for{' '}
          <Field
            type={FieldType.LINK}
            value={{
              label: billingCustomer.customerName,
              to: `/customer/view/${billingCustomer.id}`
            }}
            weight={TW.BOLD}
          />
        </Typography>
      ),
    [loaded, billingCustomer, classes.customerLink]
  );

  const ApplicationTable = useMemo(
    () =>
      transactions && (
        <MuiThemeProvider theme={theme}>
          <ResponsiveTable
            customCellComponents={{
              ApplicationCell: ({ record }) => (
                <ApplicationCell
                  applicationMapRef={applicationMapRef}
                  availableAmountRef={availableAmountRef}
                  record={record}
                  transactionType={transactionType}
                  onChange={delta => {
                    setFieldValue('appliedAmount', roundCurrency(appliedAmount - delta));
                    // refunds don't affect AR/balance
                    if (transactionType !== TransactionType.REFUND) {
                      setTransactions(prev =>
                        patchArray(prev, {
                          ...record,
                          adjustedBalance: record.adjustedBalance + delta
                        })
                      );
                    }
                  }}
                />
              )
            }}
            data={transactions}
            defaults={{ rowsPerPage: 10 }}
            disableFilter
            rowMetadata={props.columns}
            showToolbars
          />
        </MuiThemeProvider>
      ),
    [transactions, props.columns, appliedAmount, setFieldValue, setTransactions, transactionType]
  );

  return (
    <div>
      {Header}
      {LoadButton}
      {CustomerLink}
      {ApplicationTable}
    </div>
  );
}

export default connect(state => state, { snackbar: snackbarOn })(AdjustmentApplication);
