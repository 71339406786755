/* eslint-disable camelcase */
import React, { useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import moment from 'moment';

import { Link } from 'react-router-dom';

import { SergeantModal, Spinner, UserPermission } from 'components';
import { useMutationSubscription } from 'customHooks/useMutationSubscription';
import { InvoiceService, JobService } from 'services/core';
import { Logger } from 'services/Logger';
import { logErrorWithCallback } from 'utils';

import {
  AppConstants,
  PermissionConstants,
  ReviewReportStatus,
  VisitStatus
} from 'utils/AppConstants';
import { EntityType } from 'utils/constants';

import invoiceWarningLayout from '../../DetailView/JobTabs/InvoiceAndReport/layout';

const ReviewReportButtons = ({
  current,
  send,
  classes,
  spinnerOn,
  spinnerOff,
  history,
  billingAddressObject,
  setOpenMultipleVisits,
  setMultipleVisits
}) => {
  const { context } = current;
  const { user } = context;
  const {
    jobInfoData,
    buttonClicked,
    visit,
    reduxActions,
    primaryTechs,
    techReportLastUpdated,
    submittedBy,
    submittedDate
  } = context || {};
  const { snackbarOn } = reduxActions;
  // will be null when no mutation occurs
  const newUpdates = useMutationSubscription(user.tenantId, context.id, EntityType.REVIEW_REPORT, [
    'status'
  ]);
  const currentStatus = newUpdates?.status || context.status;
  const jobService = new JobService();
  const [openWarningPopup, setOpenWarningPopup] = useState(false);
  let hasExistingInvoice = false;
  let currentInvoiceId = '';
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  // Find invoices which is not in void status for this visit
  // As review report is not associated with invoices, parent (Visit) will have many to many releation with invoices
  const visitsWithInvoices = [];
  if (jobInfoData?.invoices?.items) {
    jobInfoData.invoices.items.forEach(invoice => {
      if (invoice.invoiceVisits) {
        invoice.invoiceVisits.items.forEach(v => {
          if (invoice?.status?.toLowerCase() !== 'void') {
            visitsWithInvoices.push(v.visit.visitNumber);
            // To get the invoice id by matching visit numbers
            if (v.visit.visitNumber === context.visit.visitNumber) {
              currentInvoiceId = invoice.id;
            }
          }
        });
      }
    });
  }

  if (context?.visit?.visitNumber) {
    hasExistingInvoice = visitsWithInvoices?.includes(context.visit.visitNumber) || false;
  }

  const createInvoice = async () => {
    const invoiceService = new InvoiceService();
    spinnerOn();
    window.scrollTo(0, 0);

    try {
      const { billTo, addressLine1, addressLine2, city, state, zipcode } =
        billingAddressObject || {};
      const parsedBillingAddress = billingAddressObject
        ? {
            billTo: billTo || '',
            addressLine1: addressLine1 || '',
            addressLine2: addressLine2 || '',
            city: city || '',
            state: state || '',
            zipcode: zipcode || ''
          }
        : undefined;

      const { data } = await invoiceService.createInvoiceFromVisits(jobInfoData.tenantId, {
        tenantCompanyId: user.tenantCompanyId,
        jobId: jobInfoData.id,
        visitIds: [visit.id],
        billingAddress: parsedBillingAddress
      });
      if (data && data.createInvoiceFromVisits) {
        history.push(`/invoice/view/${data.createInvoiceFromVisits.id}`, {
          recordSortKey: data.createInvoiceFromVisits.sortKey
        });
      }
      spinnerOff();
    } catch (error) {
      Logger.error(error);
      logErrorWithCallback(error, snackbarOn, 'Unable to create invoice, please try again later');
      spinnerOff();
    }
  };

  const createInvoiceMode = async () => {
    setIsBtnLoading(true);
    const { data } = await jobService.getVisitsByJobNumber(`${jobInfoData.jobNumber}`);
    const visitsWithSubmittedReviewReports = data.getJobByJobNumber.visits.items.filter(visit => {
      return (
        visit.reviewReports.items.length > 0 &&
        visit.reviewReports.items[0].status === 'submitted' &&
        [
          VisitStatus.COMPLETE,
          VisitStatus.CONVERTED,
          VisitStatus.ONHOLD,
          VisitStatus.CLOSED
        ].includes(visit.status)
      );
    });

    if (visitsWithSubmittedReviewReports.length > 1) {
      setOpenMultipleVisits(true);
      setMultipleVisits(visitsWithSubmittedReviewReports);
    } else {
      await createInvoice();
    }
    setIsBtnLoading(false);
  };

  const editReportAction = () => {
    if (hasExistingInvoice) {
      return snackbarOn(
        'error',
        'Invoice already exists \nKindly delete the invoice if you wish edit the report'
      );
    }
    return send('EDIT_REPORT', { status: ReviewReportStatus.DRAFT });
  };

  const buttonsForStatusConfig = {
    [ReviewReportStatus.DRAFT]: [
      {
        permissionKey: PermissionConstants.OBJECT_REVIEWREPORT,
        permissionAction: 'edit',
        variant: 'contained',
        color: 'secondary',
        className: classes.pageActionButton,
        action: () => send('SAVE_REPORT', { status: ReviewReportStatus.DRAFT }),
        btnName: 'save',
        label: 'Save'
      },
      {
        permissionKey: PermissionConstants.OBJECT_REVIEWREPORT,
        permissionAction: 'edit',
        variant: 'contained',
        color: 'primary',
        className: classes.pageActionButton,
        action: () => send('SUBMIT_REPORT', { status: ReviewReportStatus.SUBMITTED }),
        btnName: 'submit',
        label: 'Submit'
      }
    ],
    [ReviewReportStatus.DISMISSED]: [
      {
        permissionKey: PermissionConstants.OBJECT_REVIEWREPORT,
        permissionAction: 'edit',
        variant: 'contained',
        color: 'secondary',
        className: classes.pageActionButton,
        action: () => send('SUBMIT_REPORT', { status: ReviewReportStatus.SUBMITTED }),
        btnName: 'submit',
        label: 'Submit'
      }
    ],
    [ReviewReportStatus.SUBMITTED]: [
      {
        permissionKey: PermissionConstants.OBJECT_REVIEWREPORT,
        permissionAction: 'edit',
        variant: 'contained',
        color: 'secondary',
        className: classes.pageActionButton,
        action: editReportAction,
        btnName: 'edit',
        label: 'Edit'
      },
      {
        permissionKey: PermissionConstants.OBJECT_INVOICE,
        permissionAction: 'new',
        variant: 'contained',
        color: 'primary',
        className: classes.pageActionButton,
        action: () => {
          if (jobInfoData) {
            if (jobInfoData.jobTypeInternal === 'Maintenance') {
              setOpenWarningPopup(true);
            } else {
              createInvoiceMode();
            }
          }
        },
        btnName: 'submit',
        label: 'Create Invoice'
      },
      {
        permissionKey: PermissionConstants.OBJECT_REVIEWREPORT,
        permissionAction: 'edit',
        variant: 'contained',
        color: 'secondary',
        className: classes.pageActionButton,
        action: () => send('DISMISS_REPORT', { status: ReviewReportStatus.DISMISSED }),
        btnName: 'dismiss',
        label: 'Dismiss'
      }
    ],
    [ReviewReportStatus.INVOICED]: []
  };

  const buttonsForDisplay = buttonsForStatusConfig[currentStatus.toLowerCase()] || [];
  return (
    <>
      <Grid container direction="column">
        {buttonsForDisplay.map(btn => {
          const disabled = isBtnLoading || buttonClicked === btn.btnName;
          const showSpinner =
            (isBtnLoading && btn.btnName === 'submit') || buttonClicked === btn.btnName;

          return (
            <UserPermission
              action={btn.permissionKey}
              I={btn.permissionAction}
              key={`perm${btn.label}`}
            >
              <Grid item key={`grid${btn.label}`}>
                <Button
                  className={btn.className}
                  color={btn.color}
                  disabled={disabled}
                  key={`btn${btn.label}`}
                  variant={btn.variant}
                  onClick={() => btn.action()}
                >
                  {btn.label}
                  {showSpinner && (
                    <Spinner
                      className={classes.buttonProgress}
                      key={`spinner${btn.label}`}
                      size={24}
                    />
                  )}
                </Button>
              </Grid>
            </UserPermission>
          );
        })}
        <UserPermission action={PermissionConstants.OBJECT_VISIT} I="view">
          <Grid item>
            {context && context.visit && (
              <Typography className={classes.linkPtag}>
                <Link
                  className={classes.linkText}
                  key="reportLink"
                  to={{
                    pathname: `/technicianreport/view/${context.visit.id}`
                  }}
                >
                  Technician Report
                </Link>
              </Typography>
            )}
          </Grid>
        </UserPermission>
        <Grid item>
          {currentInvoiceId && (
            <Typography className={classes.linkPtag}>
              <Link
                className={classes.linkText}
                key="invoiceLink"
                to={{
                  pathname: `/invoice/view/${currentInvoiceId}`
                }}
              >
                Invoice
              </Link>
            </Typography>
          )}
        </Grid>
        <Grid item>
          {currentStatus?.toLowerCase() === ReviewReportStatus.DISMISSED && (
            <>
              <Typography className={classes.auditText}>Review report dismissed by:</Typography>
              <Typography className={classes.auditTextValue}>
                {context.reviewReport &&
                  ` ${context.reviewReport?.lastUpdatedBy || ''}, ${context.reviewReport &&
                    moment
                      .unix(parseInt(context.reviewReport.lastUpdatedDateTime, 10) / 1000)
                      .format(AppConstants.DATETIME_FORMAT)}
          `}
              </Typography>
            </>
          )}
          {currentStatus?.toLowerCase() === ReviewReportStatus.SUBMITTED && (
            <>
              <Typography className={classes.auditText}>Review report submitted by:</Typography>
              <Typography className={classes.auditTextValue}>
                {context.reviewReport &&
                  ` ${context.reviewReport?.submittedBy ||
                    context.reviewReport?.lastUpdatedBy ||
                    ''}, ${context.reviewReport &&
                    moment
                      .unix(
                        context.reviewReport.submittedDate ||
                          parseInt(context.reviewReport.lastUpdatedDateTime, 10) / 1000
                      )
                      .format(AppConstants.DATETIME_FORMAT)}
          `}
              </Typography>
            </>
          )}
          <Typography className={classes.auditText}>Review report created by:</Typography>
          <Typography className={classes.auditTextValue}>
            {context.reviewReport &&
              ` ${context.reviewReport?.createdBy || ''}, ${context.reviewReport &&
                moment
                  .unix(parseInt(context.createdDateTime, 10) / 1000)
                  .format(AppConstants.DATETIME_FORMAT)}
          `}
          </Typography>

          <Typography className={classes.auditText}>Technician report created by:</Typography>
          <Typography className={classes.auditTextValue}>
            {submittedBy
              ? ` ${submittedBy}`
              : ` ${primaryTechs[0].mappedEntity.firstName} ${primaryTechs[0].mappedEntity.lastName}`}
            {`, ${moment
              .unix(submittedDate || parseInt(techReportLastUpdated, 10) / 1000)
              .format(AppConstants.DATETIME_FORMAT)}`}
          </Typography>
        </Grid>
      </Grid>
      {openWarningPopup && (
        <SergeantModal
          customPrimaryButtonLabel="Yes"
          dataType="Invoice"
          handleClose={() => setOpenWarningPopup(false)}
          handlePrimaryAction={createInvoiceMode}
          layout={invoiceWarningLayout}
          maxWidth={500}
          mode="Create"
          open={openWarningPopup}
        />
      )}
    </>
  );
};

export default ReviewReportButtons;
