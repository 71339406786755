import React, { memo, useMemo, useRef, useState } from 'react';

import moment from 'moment-timezone';

import { selectVisitData } from '@dispatch/components/Drawer/Drawer.selectors';
import { withDispatchActions, withDispatchStore } from '@dispatch/Dispatch.store';
import useAddMultipleVisits from '@dispatch/mutations/useAddMultipleVisits';
import useAddVisit from '@dispatch/mutations/useAddVisit';
import { useCrewMembersFromLeader } from '@dispatch/queries';
import useJobDetails from '@dispatch/queries/useJobDetails';
import { useCompanyTimezone } from '@pm/components/hooks';
import compose from 'utils/compose';

import CreateVisit from './CreateVisit.component';

const mapActionsToProps = ({ closeDrawer }) => ({
  closeDrawer
});

const mapDispatchToProps = store => ({
  eventData: store.state.eventData
});

const DataProvider = props => {
  const Memoized = useRef(memo(CreateVisit)).current;
  // eslint-disable-next-line react/prop-types
  const { eventData } = props;
  const [jobNumber, setJobNumber] = useState(eventData?.job?.jobNumber);
  const selVisitData = selectVisitData(eventData);

  const [{ data: companyTimezone }] = useCompanyTimezone();
  const addVisitTuple = useAddVisit();
  const addMultipleVisitsTuple = useAddMultipleVisits();
  const jobDetailsResponse = useJobDetails(jobNumber);

  const startOfDayCompanyTimezone = useMemo(() => {
    if (!(selVisitData.scheduledFor && companyTimezone)) return 0;

    return moment
      .unix(selVisitData.scheduledFor)
      .tz(companyTimezone)
      .startOf('day')
      .unix();
  }, [selVisitData.scheduledFor, companyTimezone]);

  const [, crewMembersResponse] = useCrewMembersFromLeader(
    selVisitData.primaryTechId,
    startOfDayCompanyTimezone
  );

  return (
    <Memoized
      {...props}
      addMultipleVisitsTuple={addMultipleVisitsTuple}
      addVisitTuple={addVisitTuple}
      crewMembersResponse={crewMembersResponse}
      jobDetailsResponse={jobDetailsResponse}
      jobNumber={jobNumber}
      selVisitData={selVisitData}
      setJobNumber={setJobNumber}
    />
  );
};

export default compose(
  withDispatchStore(mapDispatchToProps),
  withDispatchActions(mapActionsToProps)
)(DataProvider);
