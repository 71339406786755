import { useCallback, useEffect, useRef, useState } from 'react';

import { isEmpty, omit } from 'lodash';

import { sentryException, sentryMessage } from 'services/Logger';
import { NetworkStatuses } from 'utils/constants';

/**
 * @typedef {Object} AutosaveManagerReturnObject
 * @property {function} autosave - Function that takes in changes to update the
 * entity.
 * @property {NetworkStatuses} status - NetworkStatuses to indicate the status of the
 * autosave manager.
 * @property {number} version - the current version of the entity
 * @property {boolean} confirmLeave - true if the user should not leave the
 * page due to an ongoing request, false otherwise.
 */

/**
 * Hook for managing version and sending update requests one at a time.
 * @param {function} update - Function that updates the entities given a map
 * of changes. On success, must return the updated entity including the
 * version.
 * @param {number} initialVersion - Version of the entity on load. This hook
 * will manage the versioning using this as a starting point.
 * @param {function} snackbarOn - Function to show snackbar to the user.
 * @param {string} entityName - Used for logging purposes
 * @param {function} formatChanges - Optional function to process the changes
 * before calling the update function.
 * @param {function} destructureUpdateResult - Function to get the updated
 * entity from the successful update response.
 * @param {function} versionIncrementedOnBackend - Indicates whether the
 * version increment is handled on the backend implementation.
 * @return {AutosaveManagerReturnObject}
 */
const useAutosaveManager = ({
  update,
  initialVersion,
  snackbarOn,
  entityName,
  formatChanges,
  destructureUpdateResult,
  versionIncrementedOnBackend = false
}) => {
  const [hasNetworkError, setHasNetworkError] = useState(false);
  const [networkStatus, setNetworkStatus] = useState(NetworkStatuses.READY);
  const [hasTempData, setHasTempData] = useState(false);
  const [confirmLeave, setConfirmLeave] = useState(false);

  const version = useRef(initialVersion);
  const retryCount = useRef(0); // used to keep track of # of retries when there are failures

  // state of the current request's params. Changes trigger the useEffect to send requests
  const [paramsForUpdate, setParamsForUpdate] = useState();

  /**
   * Request looks like {
   * promise: Promise,
   * resolve: Promise.resolve,
   * fail: Promise.fail,
   * params: {}
   * }
   * */
  const currentRequest = useRef(null);
  const nextRequest = useRef(null);

  const handleUpdateSuccess = useCallback(
    async ({ updatedEntity, params }) => {
      version.current = updatedEntity.version;

      currentRequest.current.resolve({ ...updatedEntity, version: version.current });

      if (nextRequest.current) {
        // make the next request that was waiting
        currentRequest.current = nextRequest.current;
        setParamsForUpdate(nextRequest.current.params);

        nextRequest.current = null;
      } else {
        currentRequest.current = null;
        setParamsForUpdate(null);
      }
      retryCount.current = 0;
      setHasNetworkError(false);
    },
    [versionIncrementedOnBackend]
  );

  const handleUpdateFailure = useCallback(
    ({ error, params }) => {
      if (
        error.message.includes('outdated') ||
        error.message.includes('modified by another user')
      ) {
        // no point in retrying out of date errors. User needs to refresh to get
        // the latest data
        snackbarOn('error', error.graphQLErrors?.[0]?.message);
      } else if (retryCount.current < 5) {
        retryCount.current += 1;
        setTimeout(() => setParamsForUpdate(p => ({ ...p })), 2000);
        return;
      } else {
        // unknown error. Server is down or user lost internet connection
        snackbarOn('error', 'Unable to reach the server. Please refresh and try again later.');
      }
      const sentryExtra = {
        message: error.message,
        graphQLErrors: error.graphQLErrors,
        retryCount: retryCount.current,
        params
      };
      sentryMessage(`autosave: updating ${entityName} failed`, sentryExtra);
      sentryException(error, sentryExtra);

      currentRequest.current.fail(null);
      currentRequest.current = null;
      nextRequest.current = null;
      setParamsForUpdate(null);
      setHasNetworkError(true);
    },
    [snackbarOn, entityName]
  );

  useEffect(() => {
    const sendRequest = async () => {
      const params = {
        ...paramsForUpdate,
        version: versionIncrementedOnBackend ? version.current : version.current + 1
      };

      try {
        const updatedEntity = destructureUpdateResult(await update(params));
        handleUpdateSuccess({ updatedEntity, params });
      } catch (error) {
        handleUpdateFailure({ error, params });
      }
    };
    if (paramsForUpdate) {
      setHasTempData(false);
      sendRequest();
    }
    // ignoring destructureUpdateResult since it's expected to be static
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsForUpdate, handleUpdateFailure, handleUpdateSuccess, update]);

  const updateWrapper = useCallback(
    async ({ changes: rawChanges, values }) => {
      const changes = omit(formatChanges ? formatChanges(rawChanges) : rawChanges, ['version']);

      if (isEmpty(changes)) {
        setHasTempData(false);
        return null;
      }

      if (nextRequest.current) {
        // merge with the waiting request
        Object.assign(nextRequest.current.params, changes);
        return nextRequest.current.promise;
      }

      if (currentRequest.current) {
        // wait until the current request resolves before making another
        nextRequest.current = { params: changes };
        nextRequest.current.promise = new Promise((resolve, fail) => {
          nextRequest.current.resolve = resolve;
          nextRequest.current.fail = fail;
        });
        return nextRequest.current.promise;
      }

      currentRequest.current = {};
      currentRequest.current.promise = new Promise((resolve, fail) => {
        currentRequest.current.resolve = resolve;
        currentRequest.current.fail = fail;
      });

      setParamsForUpdate(changes);
      return currentRequest.current.promise;
    },
    [formatChanges]
  );

  useEffect(() => {
    if (version.current !== initialVersion) {
      version.current = initialVersion;
    }
  }, [initialVersion]);

  useEffect(() => {
    const getNetworkStatus = () => {
      // retrying isn't needed since to the user, it is the same as updating
      if (paramsForUpdate) return NetworkStatuses.UPDATING;
      if (hasTempData) return NetworkStatuses.HAS_UNSAVED_DATA;
      if (hasNetworkError) return NetworkStatuses.ERROR;
      return NetworkStatuses.READY;
    };

    const newStatus = getNetworkStatus();
    setNetworkStatus(newStatus);
    setConfirmLeave(
      [
        NetworkStatuses.HAS_UNSAVED_DATA,
        NetworkStatuses.RETRYING,
        NetworkStatuses.UPDATING
      ].includes(newStatus)
    );
  }, [hasNetworkError, hasTempData, paramsForUpdate]);

  return {
    autosave: updateWrapper,
    status: networkStatus,
    version: version.current,
    hasTempData,
    setHasTempData,
    confirmLeave
  };
};

export default useAutosaveManager;
