import gql from 'graphql-tag';

import { dispatchVisitFragment } from './visitFragment';

export const addVisitToJobMutation = gql`
  mutation addDispatchVisit($partitionKey: String!, $input:AddVisitToJob!) {
    addVisitToJob(partitionKey: $partitionKey, data: $input) {
      ${dispatchVisitFragment}
    }
  }
`;
