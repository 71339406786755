import { sortBy } from 'lodash';

import { TimesheetsService } from 'services/core';
import { logErrorWithCallback } from 'utils';

export const getPayrollBillingHourTypes = async (tenantId, companyId, snackbarOn) => {
  const TimesheetsServiceObj = new TimesheetsService();
  let responseData = {};
  try {
    const filter = { isActive: { eq: true } };
    const response = await TimesheetsServiceObj.getPayrollBillingHourTypes(
      tenantId,
      companyId,
      filter
    );
    const hourTypes = response?.data?.getCompany?.billingHourTypes?.items;
    responseData = sortBy(hourTypes, 'sortOrder');
  } catch (error) {
    logErrorWithCallback(
      error,
      snackbarOn,
      'Unable to fetch payroll types, please try again later'
    );
  }
  return responseData;
};

export const updatePayrollBillingHourTypeOrder = async (
  data,
  tenantId,
  tenantCompanyId,
  snackbarOn
) => {
  const TimesheetsServiceObj = new TimesheetsService();
  const payload = {
    companyId: tenantCompanyId,
    billingHourTypes: data
  };

  let responseData = {};

  try {
    const response = await TimesheetsServiceObj.addPayrollBillingHourTypes(tenantId, payload);
    snackbarOn('success', `Successfully updated the order`);
    responseData = response?.data?.addBillingHourTypesToCompany;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to update the order, please try again later');
  }
  return responseData;
};

export const addPayrollBillingHourType = async (
  formData = {},
  tenantId,
  tenantCompanyId,
  snackbarOn
) => {
  const TimesheetsServiceObj = new TimesheetsService();
  const payload = {
    companyId: tenantCompanyId,
    billingHourTypes: [
      {
        hourType: formData.hourType,
        hourTypeAbbreviation: formData.hourTypeAbbreviation,
        sortOrder: formData.sortOrder
      }
    ]
  };

  let responseData = {};

  try {
    const response = await TimesheetsServiceObj.addPayrollBillingHourTypes(tenantId, payload);
    snackbarOn('success', `Successfully saved type - ${formData.hourType}`);
    const [billingHourType] = response?.data?.addBillingHourTypesToCompany;
    responseData = billingHourType;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to add type, please try again later');
  }
  return responseData;
};

export const updatePayrollBillingHourType = async (formData = {}, tenantId, snackbarOn) => {
  const TimesheetsServiceObj = new TimesheetsService();
  const payload = {
    id: formData.id,
    version: formData.version,
    hourType: formData.hourType,
    hourTypeAbbreviation: formData.hourTypeAbbreviation
  };

  let responseData = {};

  try {
    const response = await TimesheetsServiceObj.updatePayrollBillingHourTypes(tenantId, payload);
    snackbarOn('success', `Successfully updated ${formData.hourType} type`);
    responseData = response?.data?.updateBillingHourType;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to update type, please try again later');
  }
  return responseData;
};

export const deletePayrollBillingHourType = async (formData = {}, tenantId, snackbarOn) => {
  const TimesheetsServiceObj = new TimesheetsService();
  let responseData = {};

  try {
    const response = await TimesheetsServiceObj.deletePayrollBillingHourType(tenantId, formData.id);
    snackbarOn('success', `Successfully deleted ${formData.hourType} type`);
    responseData = response?.data?.softDeletePayrollBillingHourType;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to delete type, please try again later');
  }
  return responseData;
};
