import { useMemo } from 'react';

import { MUIFormComponentNames } from '@BuildHero/sergeant';

import { col, row } from 'utils/layout';
import { findById } from 'utils/ramda';

export const useTimesheetCardConfiguration = ({
  phaseId,
  departmentId,
  projectPhases,
  payrollHourTypes,
  variant,
  isDisabled
}) =>
  useMemo(() => {
    const color = variant === 'standard' ? 'secondary' : undefined;

    const inputOptions = {
      marginRight: 16,
      marginBottom: 16
    };

    const phaseDepartments = findById(phaseId)(projectPhases)?.ProjectPhaseDepartment || [];
    const costCodes =
      findById(departmentId)(phaseDepartments)?.ProjectPhaseDepartmentCostCode || [];

    const phaseOptions =
      projectPhases?.map(phase => ({ value: phase?.id, label: phase?.name })) || [];

    const departmentOptions = phaseDepartments?.map(department => {
      return {
        value: department?.id,
        label: `${department?.tagName}`
      };
    });

    const costCodeOptions = costCodes?.map(costCode => ({
      value: costCode?.id,
      label: costCode?.description ? `${costCode?.name} - ${costCode?.description}` : costCode?.name
    }));

    return {
      fields: {},
      layouts: {
        default: {
          contents: [
            row(
              [
                col(
                  [
                    {
                      options: {
                        label: 'Phase',
                        valueSet: phaseOptions,
                        isRequired: true,
                        disabled: isDisabled,
                        color,
                        variant
                      },
                      component: MUIFormComponentNames.SelectInput,
                      source: 'projectPhaseId'
                    }
                  ],
                  inputOptions
                ),
                col(
                  [
                    {
                      options: {
                        label: 'Department',
                        valueSet: departmentOptions,
                        isRequired: true,
                        disabled: isDisabled,
                        color,
                        variant
                      },
                      component: MUIFormComponentNames.SelectInput,
                      source: 'projectPhaseDepartmentId'
                    }
                  ],
                  inputOptions
                ),
                col(
                  [
                    {
                      options: {
                        label: 'Cost Code',
                        valueSet: costCodeOptions,
                        isRequired: true,
                        disabled: isDisabled,
                        color,
                        variant
                      },
                      component: MUIFormComponentNames.SelectInput,
                      source: 'projectPhaseDepartmentCostCodeId'
                    }
                  ],
                  { ...inputOptions, marginRight: 0 }
                )
              ],
              { marginBottom: 16 }
            ),
            row([
              col([
                {
                  options: {
                    color,
                    variant,
                    payrollHourTypes,
                    disabled: isDisabled
                  },
                  component: 'TimesheetEntriesField',
                  source: 'timesheetEntries'
                }
              ])
            ])
          ]
        }
      }
    };
  }, [phaseId, departmentId, projectPhases, payrollHourTypes, variant, isDisabled]);
