import React, { useCallback, useEffect, useState } from 'react';

import { Button, ButtonType } from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty, orderBy } from 'lodash';
import { FixedSizeList } from 'react-window';

import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import { ChecklistHeader } from 'scenes/ChecklistLibrary/components/checklistHeader';
import { defaultCreateTemplatePayload } from 'scenes/ChecklistLibrary/helper';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import useAdvancedSchedulingServiceAgreementCustomerPropertiesMetadata from '../../DetailView/ServiceAgreementsTabs/hooks';

import useDeleteAllTemplateGroupsOnServiceAgreement from '../PropertiesAndAssets/hooks/useDeleteAllTemplateGroupsOnServiceAgreement';

import { useAddTaskTemplateFromServiceAgreement } from './hooks/useAddTaskTemplateFromServiceAgreement';

import { TaskTemplate } from './taskTemplateInSA';

export const addTaskConfirmMessage = {
  body:
    'By adding a task, all maintenance information (from step 4) for properties will be erased.',
  title: 'Add Task',
  buttonLabel: 'Add Task',
  buttonType: ButtonType.ERROR
};

const ChecklistInSA = ({ assetData, cList, customerPropertyId, serviceAgreementId }) => {
  const [newTask, setNewTask] = useState();

  const modifyContext = useConfirmModal();

  const flags = useFlags();
  const isDebugEnabled = flags[FeatureFlags.DEBUG_MODE];

  const [deleteAllTemplateGroupsOnServiceAgreement] = useDeleteAllTemplateGroupsOnServiceAgreement({
    serviceAgreementId
  });

  const [
    serviceAgreementPropertyMetadata,
    ,
    refetchServiceAgreementPropertyMetadata
  ] = useAdvancedSchedulingServiceAgreementCustomerPropertiesMetadata({
    serviceAgreementId
  });

  useEffect(() => {
    setNewTask();
  }, [cList]);

  const [
    addTaskTemplateInSA,
    { loading: addingTaskTemplate }
  ] = useAddTaskTemplateFromServiceAgreement({
    serviceAgreementId,
    customerPropertyId,
    propertyAssetId: assetData?.id,
    checklistId: cList.id,
    skip: !serviceAgreementId || (!assetData?.id && !customerPropertyId) || !cList?.id
  });
  const renderTask = useCallback(
    ({ data, isScrolling, index, style }) => (
      <div style={style}>
        <TaskTemplate
          assetData={assetData}
          checklistId={cList.id}
          customerPropertyId={customerPropertyId}
          data={data[index]}
          deleteAllTemplateGroupsOnServiceAgreement={deleteAllTemplateGroupsOnServiceAgreement}
          hasMetadata={serviceAgreementPropertyMetadata?.advancedMaintenanceTemplateGroups?.length}
          isDebugEnabled={isDebugEnabled}
          isLastTask={data.length === 1}
          loading={isScrolling}
          refetchServiceAgreementPropertyMetadata={refetchServiceAgreementPropertyMetadata}
          serviceAgreementId={serviceAgreementId}
        />
      </div>
    ),
    [
      assetData,
      cList.id,
      customerPropertyId,
      deleteAllTemplateGroupsOnServiceAgreement,
      isDebugEnabled,
      refetchServiceAgreementPropertyMetadata,
      serviceAgreementId,
      serviceAgreementPropertyMetadata
    ]
  );

  const lastTaskTemplate = orderBy(cList.mergedTaskTemplates, ['sortOrder'], ['desc'])[0] || {};
  const currentTaskTemplates = [...(cList?.mergedTaskTemplates || []), newTask].filter(Boolean);

  return (
    <>
      <ChecklistHeader cList={cList} numberOfTasks={cList?.mergedTaskTemplates?.length} />
      {currentTaskTemplates.length ? (
        <Box
          sx={{
            width: '100%',
            maxHeight: 430,
            bgcolor: 'background.paper'
          }}
        >
          <FixedSizeList
            height={currentTaskTemplates.length < 5 ? currentTaskTemplates.length * 70 : 370}
            itemCount={currentTaskTemplates.length}
            itemData={currentTaskTemplates}
            itemKey={(index, data) => data[index]?.id}
            itemSize={70}
            overscanCount={5}
            useIsScrolling
            width="100%"
          >
            {renderTask}
          </FixedSizeList>
        </Box>
      ) : null}
      <Box display="flex">
        <Button
          disabled={addingTaskTemplate}
          key={`AddTaskBtn-${cList.id}`}
          type={ButtonType.LEADING}
          onClick={async () => {
            if (serviceAgreementPropertyMetadata?.advancedMaintenanceTemplateGroups?.length) {
              if (!(await modifyContext.confirm(addTaskConfirmMessage))) {
                return;
              }

              await deleteAllTemplateGroupsOnServiceAgreement();
              await refetchServiceAgreementPropertyMetadata();
            }

            const payload = {
              taskTemplateChecklistId: cList.id,
              ...defaultCreateTemplatePayload,
              ...lastTaskTemplate
            };
            if (isEmpty(lastTaskTemplate)) {
              setNewTask(payload);
            } else {
              await addTaskTemplateInSA(payload);
            }
          }}
        >
          + Add Task
        </Button>
      </Box>
    </>
  );
};

export default ChecklistInSA;
