import React, { useMemo, useRef, useState } from 'react';

import { Box, Chip, InputAdornment, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import SearchResults from './SearchResults';
import styles from './styles';

const Properties = ({ form, field, options, classes }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { properties } = form.values;

  const { label } = options;

  const inputRef = useRef(null);

  const handleSelectProperty = value => {
    form.setFieldValue(field.name, [...field.value, value]);
    setSearchQuery('');
  };

  const handleDeleteProperty = value => {
    form.setFieldValue(
      field.name,
      field.value.filter(v => v.value !== value)
    );
  };

  const searchedItems = useMemo(() => {
    const regex = new RegExp(searchQuery, 'i');
    return properties.filter(
      p => regex.test(p.label) && field.value.every(el => el.value !== p.value)
    );
  }, [searchQuery, properties, field.value]);

  return (
    <>
      <Box display="flex" flexDirection="column">
        <TextField
          autoComplete="off"
          className={classes.searchBox}
          fullWidth={false}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ color: '#999999ff', fontSize: 14 }} />
              </InputAdornment>
            )
          }}
          inputProps={{ testingId: 'propertySearch' }}
          label={label}
          placeholder="Search to add properties"
          value={searchQuery}
          variant="outlined"
          // eslint-disable-next-line
          onChange={event => setSearchQuery(event.target.value)}
        />
        <div id="property-search-result" ref={inputRef} />
        <SearchResults
          anchorEl={inputRef.current}
          classes={{ root: classes.searchResults }}
          isOpen={searchQuery !== ''}
          listItems={searchedItems}
          onChange={handleSelectProperty}
          onClickAway={() => setSearchQuery('')}
        />
        <Box display="flex" flexDirection="column">
          {(field?.value || []).map(v => (
            <Chip
              classes={{ root: classes.chip }}
              deleteIcon={<CloseIcon />}
              key={v.value}
              label={v.label}
              onDelete={() => handleDeleteProperty(v.value)}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default withStyles(styles)(Properties);
