import { getCloudinaryImageUrl, isImageAttachment } from '@pm/components/utils';
import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';
import { getUniqueName } from 'utils';

export const isImageFile = file => {
  const imageTypes = ['png', 'jpeg', 'jpg', 'gif', 'heic'];
  return (
    Boolean(file?.type?.includes('image')) ||
    Boolean(file?.fileType?.includes('image')) ||
    imageTypes.includes(
      file?.fileUrl
        ?.split('.')
        ?.pop()
        ?.toLowerCase()
    )
  );
};

export const getImageList = data => {
  if (!data || data?.length === 0) return [];
  return data
    .filter(image => isImageAttachment(image))
    .map(image => ({
      url: getCloudinaryImageUrl(image.fileUrl),
      file: image.file || undefined,
      name: image.fileName,
      description: '',
      newName: image.customFileName || '',
      selected: true
    }));
};

export const toImageAttachment = image => ({
  url: image.url || getCloudinaryImageUrl(image.fileUrl),
  fileType: image.fileType,
  file: image.file || undefined,
  name: image.fileName,
  description: '',
  newName: image.customFileName || '',
  selected: true
});

export const toFileAttachment = file => ({
  ...file,
  newName: file.customFileName || '',
  selected: true
});

export const getFileList = data => {
  if (!data || data?.length === 0) return [];
  return data
    .filter(
      file =>
        file.fileType?.includes('pdf') ||
        file.fileType?.includes('doc') ||
        file.type?.includes('pdf') ||
        file.type?.includes('doc')
    )
    .map(file => ({
      ...file,
      newName: file.customFileName || '',
      selected: true
    }));
};

export const uploadFile = async ({ file, tenantId }) => {
  try {
    const storageService = new StorageService();
    const uniqueName = getUniqueName(tenantId, file.name);
    const s3Response = await storageService.uploadFile(file, uniqueName, e => e, null);
    return storageService.getFile(s3Response);
  } catch (error) {
    Logger.error(error);
  }
};

export const uploadFiles = ({ images, files, tenantId }) => {
  const filesInfo = [...images, ...files];
  return Promise.all(
    filesInfo
      .filter(item => item.file)
      .map(async item => {
        const fullUrl = await uploadFile({ file: item.file, tenantId });
        return {
          customFileName: '',
          fileName: item.file?.name || null,
          fileUrl: fullUrl || null,
          fileType: item.file?.type || item.file?.fileType
        };
      })
  );
};
