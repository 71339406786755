import React from 'react';

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';

import { PageHeader, ResponsiveTable, SergeantModal, Spinner, UserPermission } from 'components';
import PlacesSearch from 'components/BuildHeroFormComponents/PlacesSearch';
import CreateEntryButton from 'components/Buttons/CreateEntryButton';
import Labels from 'meta/labels';
import { vendorsTable } from 'meta/Settings/Vendors';
import createVendorLayout from 'meta/Settings/Vendors/layout';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { CompanyService } from 'services/core';
import AccountingValidationService from 'services/core/graphql-services/AccountingValidation';
import { checkPermission, isTenantSettingEnabled, logErrorWithCallback } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';

import { EntityType, SyncStatus } from 'utils/constants';

import styles from './styles';

class Vendors extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.companyService = new CompanyService();
    this.AccountingValidationService = new AccountingValidationService();
    this.state = {
      mode: 'new',
      data: {},
      openVendorPopUp: false,
      vendors: ''
    };
  }

  componentDidMount = async () => {
    document.title = 'BuildOps - Vendors';
    const vendors = await this.getVendors();
    this.setState({ vendors: vendors || { items: [] } });
  };

  getVendors = async () => {
    const company = await this.companyService.getVendors(
      this.props.user.tenantId,
      `${this.props.user.tenantId}_Company_${this.props.user.tenantCompanyId}`
    );
    const vendors = company.data.getCompany && company.data.getCompany.vendors;

    vendors &&
      vendors.items.forEach(vendor => (vendor.status = vendor.isActive ? 'Active' : 'Inactive'));
    return vendors;
  };

  handlePrimaryAction = async (completed, stopLoading) => {
    const { user } = this.props;
    const { tenantId, tenantCompanyId } = user;
    const shouldSyncImmediately = isTenantSettingEnabled('syncImmediately');
    const syncStatus = shouldSyncImmediately ? SyncStatus.SYNCING : undefined;
    const values = { tenantId, tenantCompanyId, ...completed, syncStatus };

    const validationMessage = await this.AccountingValidationService.validateEntity(
      user.tenantId,
      EntityType.VENDOR,
      values
    );
    if (validationMessage) {
      stopLoading();
      return this.props.snackbarOn('error', validationMessage);
    }

    try {
      let response;
      if (this.state.mode === 'new') {
        response = await this.companyService.addVendor(values);
      } else {
        response = await this.companyService.updateVendor(values);
      }
      if (response?.data) {
        this.props.snackbarOn('success', 'Successfuly added vendor');
        this.setState({ openVendorPopUp: false });
      }
    } catch (error) {
      logErrorWithCallback(
        error,
        (msg, errorObj) => this.props.snackbarOn('error', msg, errorObj),
        'Unable to create vendor, please try again later'
      );
    } finally {
      stopLoading();
      this.setState({ openVendorPopUp: false, vendors: await this.getVendors() });
    }
  };

  render() {
    const { classes, user } = this.props;
    const { vendors, data } = this.state;
    const hasPermissionToEditVendor = user.cognitoRole === 'TenantAdmin';

    if (vendors === '') {
      return <Spinner />;
    }

    return (
      <ErrorBoundaries>
        <UserPermission action={PermissionConstants.MENU_SETTINGS} I="allow">
          <PageHeader
            overrideHeaderButtons={
              <CreateEntryButton
                caslKey={PermissionConstants.ONLY_ADMIN}
                label={Labels.addVendor[this.props.user.locale]}
                onClick={() => this.setState({ openVendorPopUp: true, mode: 'new', data: {} })}
              />
            }
            pageMapKey="vendors"
            userLocale={user.locale}
          />
          <ErrorBoundaries>
            <ResponsiveTable
              data={vendors.items || []}
              noDataMsg={vendorsTable.noDataMsg}
              rowActionButtons={{
                ...(hasPermissionToEditVendor && {
                  edit: {
                    label: 'Edit',
                    caslAction: 'update',
                    icon: 'Edit'
                  }
                })
              }}
              rowActions={(mode, record) =>
                this.setState({ mode, openVendorPopUp: true, data: record })
              }
              rowMetadata={vendorsTable.captionAttributes}
            />
          </ErrorBoundaries>
          <ErrorBoundaries>
            <SergeantModal
              customComponents={{ PlacesSearch }}
              data={data}
              dataType="Vendor"
              formVersion={this.state.mode}
              handleClose={() => this.setState({ openVendorPopUp: false })}
              handlePrimaryAction={this.handlePrimaryAction}
              layout={createVendorLayout(['Supplier', 'Subcontractor'], user.locale ?? 'en')}
              mode={this.state.mode}
              open={this.state.openVendorPopUp}
            />
          </ErrorBoundaries>
        </UserPermission>
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(Vendors);

export default withStyles(styles)(connectedApp);
