import React, { memo, useMemo, useRef } from 'react';

import { useReactiveVar } from '@apollo/client';

import moment from 'moment-timezone';

import { withDispatchActions, withQueryStringStore } from '@dispatch/Dispatch.store';

import { useVisitTransition } from '@dispatch/mutations';
import { useCrewMembersFromLeader, useDepartments, useVisitDetails } from '@dispatch/queries';
import { useCompanyTimezone } from '@pm/components/hooks';
import compose from 'utils/compose';

import EditVisit from './EditVisit.component';
import { primaryTechIdVar } from './EditVisit.vars';

const mapActionsToProps = ({ closeDrawer, createEvent }) => ({
  closeDrawer,
  createEvent
});

const mapQueryStringToProps = store => ({
  visitId: store.state.visitId,
  openVisit: store.openVisit
});

const DataProvider = props => {
  const Memoized = useRef(memo(EditVisit)).current;
  // eslint-disable-next-line react/prop-types
  const { visitId } = props;
  const primaryTechId = useReactiveVar(primaryTechIdVar);
  const departmentsResponse = useDepartments();
  const { refetch, ...visitDetailsResponse } = useVisitDetails(visitId);
  const visitTransitionTuple = useVisitTransition();
  const [{ data: companyTimezone }] = useCompanyTimezone();
  const visit = visitDetailsResponse.data;

  const startOfDayCompanyTimezone = useMemo(() => {
    if (!(visit.scheduledFor && companyTimezone)) return 0;

    return moment
      .unix(visit.scheduledFor)
      .tz(companyTimezone)
      .startOf('day')
      .unix();
  }, [visit.scheduledFor, companyTimezone]);

  const [, crewMembersResponse] = useCrewMembersFromLeader(
    primaryTechId,
    startOfDayCompanyTimezone
  );

  return (
    <Memoized
      {...props}
      crewMembersResponse={crewMembersResponse}
      departmentsResponse={departmentsResponse}
      refetchVisitData={refetch}
      visitDetailsResponse={visitDetailsResponse}
      visitTransitionTuple={visitTransitionTuple}
    />
  );
};

export default compose(
  withQueryStringStore(mapQueryStringToProps),
  withDispatchActions(mapActionsToProps)
)(DataProvider);
