import React, { Component } from 'react';

import {
  Button,
  Fade,
  Grid,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import * as R from 'ramda';
import { connect } from 'react-redux';

import { AutoCompleteDropdown, Modal, Spinner, UserPermission } from 'components';
import AddRecordButton from 'components/Buttons/BlueText';
import Context from 'components/Context';
import ConfirmModal from 'components/Modal/ConfirmDialog';
import RolesLayout from 'meta/Settings/People/Roles';
import RolesData from 'meta/Settings/People/Roles/data';
import { snackbarOn, spinnerOff, spinnerOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { CompanyService } from 'services/core';
import { Logger } from 'services/Logger';
import { removeNestedJson } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';

import PermissionModal from './PermissionModal';
import Labels from './resources/labels';
import styles from './styles';

class RolesTable extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.CompanyService = new CompanyService();
    this.state = {
      rolesData: null,
      companyInfo: null,
      showPermission: {},
      permissionName: '',
      permissionData: '',
      roleName: '',
      otherPermissions: '',
      open: false,
      anchorEl: null,
      openRoleNamePopup: false,
      newRoleName: '',
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: '',
      defaultRoleName: '',
      defaultPermissions: '',
      showSpinner: true
    };
  }

  componentDidMount = async () => {
    const sortKey = `${this.props.user.tenantId}_Company_${this.props.user.tenantCompanyId}`;
    try {
      const { data } = await this.CompanyService.getCompanyWithAppRoles(
        `${this.props.user.tenantId}`,
        sortKey
      );
      if (data.getCompany) {
        const companyInfo = removeNestedJson(data.getCompany);
        const rolesAndPermissions = (data.getCompany.appRoles || {}).items || [];
        if (rolesAndPermissions !== this.state.rolesData && this.mounted) {
          this.setState({
            companyInfo,
            rolesData: rolesAndPermissions,
            showSpinner: false
          });
        }
      }
    } catch (error) {
      Logger.error(`Error in fetching data ${JSON.stringify(error)}`);
      this.props.snackbarOn('error', 'Unable to fetch COmpany data, please try again later', error);
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleOpen = (permissionMeta, item, permissionData, roleName, otherPermissions, roleId) => {
    this.setState({
      open: true,
      showPermission: permissionMeta,
      permissionName: item,
      permissionData,
      roleName,
      roleId,
      otherPermissions
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleRolePopupClose = () => {
    this.setState({
      openRoleNamePopup: false,
      newRoleName: '',
      defaultPermissions: '',
      defaultRoleName: ''
    });
  };

  handlerNewRoleRow = event => {
    const { currentTarget } = event;
    this.setState(state => ({
      anchorEl: currentTarget,
      openRoleNamePopup: !state.openRoleNamePopup
    }));
  };

  contextUpdate = () => {
    Logger.debug('Context is refreshed');
  };

  addRow = async (roleName, defaultPermissions) => {
    try {
      if (!roleName) {
        this.props.snackbarOn('error', 'Role name cannot be empty');
        return;
      }
      if (!defaultPermissions) {
        this.props.snackbarOn('error', 'Select a role template');
        return;
      }

      const permissionString = JSON.stringify(defaultPermissions);
      this.setState({
        newRoleName: '',
        defaultPermissions: '',
        openRoleNamePopup: false,
        showSpinner: true
      });
      const { data } = await this.CompanyService.mutateRole(
        roleName,
        permissionString,
        this.state.companyInfo
      );
      if (data) {
        await this.componentDidMount();
        await Context.setCompanyContext(
          this.props.user.tenantId,
          Context.generateCompanyContextSortKey(this.props.user),
          this.contextUpdate,
          true
        );
        this.props.snackbarOn('success', 'Successfully created role');
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to create role, please try again later', error);
    }
  };

  handleNewRoleInputChange = () => event => {
    this.setState({ newRoleName: event.target.value });
  };

  handleDefaultRoleChange = value => {
    const { flags: launchDarklyFlags } = this.props;
    const chosenRole = RolesData(launchDarklyFlags).filter(role => role.roleName === value);
    if (chosenRole && chosenRole.length === 1 && this.mounted) {
      this.setState({
        defaultPermissions: chosenRole[0].permissions,
        defaultRoleName: chosenRole[0].roleName
      });
    }
  };

  handleRoleOnComplete = () => {
    this.handleClose();
  };

  handleRowDelete = (roleItem, index) => {
    if (roleItem && roleItem.id) {
      if (roleItem.contacts && roleItem.contacts.items && roleItem.contacts.items.length > 0) {
        let errorMsg = 'Unable to delete role as you have following employees assigned \n';

        roleItem.contacts.items.map(employeeMapping => {
          const employee = employeeMapping?.mappedEntity || {};
          const employeeName = `${employee?.firstName || ''} ${employee?.lastName || ''}`;
          errorMsg = `${errorMsg + employeeName}\n`;
          return errorMsg;
        });
        this.props.snackbarOn('error', errorMsg);
      } else {
        this.setState({
          confirmDialog: true,
          confirmAction: async () => this.deleteRole(roleItem),
          confirmMessage: 'role'
        });
      }
    } else if (index && !roleItem.roleId) {
      const rolesArray = this.state.rolesData;
      delete rolesArray[index];
      this.setState(rolesArray);
    }
  };

  deleteRole = async roleItem => {
    try {
      const { data } = await this.CompanyService.deleteRole(
        this.props.user.tenantId,
        roleItem.sortKey
      );

      if (data) {
        await this.componentDidMount();
        await Context.setCompanyContext(
          this.props.user.tenantId,
          Context.generateCompanyContextSortKey(this.props.user),
          this.contextUpdate,
          true
        );
        this.setState({
          confirmDialog: false,
          confirmAction: '',
          confirmMessage: ''
        });
        this.props.snackbarOn('success', `Successfully deleted role: ${roleItem.tagName}`);
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to delete roles, please try again later', error);

      this.setState({
        confirmDialog: false,
        confirmAction: '',
        confirmMessage: ''
      });
    }
  };

  getRoleOptions = () => {
    const { flags: launchDarklyFlags } = this.props;
    const systemRoleNamesArray = R.pluck('roleName', RolesData(launchDarklyFlags));
    const roleAsOptions = [];
    systemRoleNamesArray.forEach(role => {
      roleAsOptions.push({ label: role, value: role });
    });
    return roleAsOptions;
  };

  handleCancelConfirmation = () => {
    this.setState({
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: ''
    });
  };

  renderLinkText = (roleItem, item, meta = []) => {
    const permissionByCategory = roleItem.permissions.filter(
      permission => permission.category === item
    );
    const permissionCount = permissionByCategory.length;
    const metaPermissionCount = meta.reduce(
      (count, rowMeta) => count + rowMeta?.actions?.filter(i => Boolean(i))?.length || 0,
      0
    );

    const fullAccessCount =
      permissionByCategory.length > 0 &&
      permissionByCategory.reduce(
        (total, permissionItem) =>
          permissionItem.actions.length > 0 &&
          permissionItem.actions.reduce(
            (count, action) => action.value === true && count + 1,
            total
          ),
        0
      );

    const noAccess =
      permissionByCategory.length > 0 &&
      permissionByCategory.every(
        permissionItem =>
          permissionItem.actions.length > 0 &&
          permissionItem.actions.every(action => action.value === false)
      );

    if (metaPermissionCount === fullAccessCount) {
      return 'Full access';
    }

    if (noAccess) {
      return 'No access';
    }

    switch (permissionCount) {
      case 0:
        return 'No access';
      default:
        return 'Partial access';
    }
  };

  // based on meta - all categeories, all actions, iterate and construct the table
  renderRolesTable = (roleItem, header, index) => {
    const { flags: launchDarklyFlags, isQuoteToPoEnabled } = this.props;
    const localRoleItem = roleItem;
    const permissionsMap = RolesLayout(launchDarklyFlags, isQuoteToPoEnabled).permissionsMaster
      .permissions;
    localRoleItem.permissions =
      roleItem.appPermissions && roleItem.appPermissions.length > 0
        ? JSON.parse(roleItem.appPermissions)
        : [];
    return (
      <TableRow key={`roleItem${index}`}>
        <TableCell className={this.props.classes.highlightFirstColumn} key={`roleName${index}`}>
          {roleItem.tagName}
        </TableCell>

        {header.map((item, headerIndex) => (
          <TableCell key={`data${headerIndex}`}>
            <Button
              style={{ paddingLeft: 0 }}
              onClick={() =>
                this.handleOpen(
                  permissionsMap.filter(permission => permission.category === item),
                  item,
                  roleItem.permissions.filter(permission => permission.category === item),
                  this.state[`textBox${index}`] ? this.state[`textBox${index}`] : roleItem.tagName,
                  roleItem.permissions.filter(permission => permission.category !== item),
                  roleItem
                )
              }
            >
              {this.renderLinkText(
                roleItem,
                item,
                permissionsMap.filter(permission => permission.category === item)
              )}
            </Button>
          </TableCell>
        ))}
        <TableCell align="right">
          <UserPermission action={PermissionConstants.ONLY_ADMIN} I="delete">
            <Tooltip title="Delete">
              <IconButton
                aria-label="Delete"
                className={this.props.classes.buttonHover}
                onClick={() => this.handleRowDelete(roleItem, index)}
              >
                <DeleteIcon className={this.props.classes.iconColor} color="secondary" />
              </IconButton>
            </Tooltip>
          </UserPermission>
        </TableCell>
      </TableRow>
    );
  };

  render() {
    const { classes, isAdmin, flags: launchDarklyFlags } = this.props;
    const { showSpinner } = this.state;
    const header = RolesLayout(launchDarklyFlags).permissionsMaster.allCategories;
    const id = this.state.openRoleNamePopup ? 'simple-popper' : null;

    if (showSpinner) {
      return <Spinner />;
    }

    return (
      <ErrorBoundaries>
        <UserPermission action={PermissionConstants.ONLY_ADMIN} I="allow">
          <Grid item>
            <AddRecordButton
              disabled={this.props.user.tenantCompanyId === ''}
              handle={this.handlerNewRoleRow}
              icon={AddIcon}
              label={Labels['Add role'][this.props.user.locale]}
            />
          </Grid>
        </UserPermission>
        <ErrorBoundaries>
          <Popper
            anchorEl={this.state.anchorEl}
            id={id}
            open={this.state.openRoleNamePopup || false}
            placement="bottom-start"
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper className={classes.popperPaper}>
                  <Grid
                    alignItems="center"
                    container
                    direction="row"
                    justify="space-between"
                    spacing={3}
                  >
                    <Grid item>
                      <TextField
                        autoFocus
                        id="newRoleId"
                        InputProps={{
                          classes: {
                            underline: classes.cssUnderline
                          }
                        }}
                        label="*Role name"
                        name="newRole"
                        placeholder="Type a role name"
                        variant="filled"
                        onChange={this.handleNewRoleInputChange()}
                      />
                    </Grid>
                    <Grid item style={{ minWidth: 216 }}>
                      <AutoCompleteDropdown
                        handleOnChange={this.handleDefaultRoleChange}
                        label="*Role template"
                        menuHeight={120}
                        name="Role"
                        options={this.getRoleOptions() || []}
                        value={this.state.defaultRoleName}
                      />
                    </Grid>
                    <Button
                      classes={{
                        outlinedSecondary: classes.buttonOutlinedSecondary
                      }}
                      onClick={this.handleRolePopupClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      classes={{
                        outlinedSecondary: classes.buttonOutlinedSecondary
                      }}
                      onClick={async () =>
                        this.addRow(this.state.newRoleName, this.state.defaultPermissions)
                      }
                    >
                      Save
                    </Button>
                  </Grid>
                </Paper>
              </Fade>
            )}
          </Popper>
        </ErrorBoundaries>
        <ErrorBoundaries>
          <Paper className={classes.root}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.highlightFirstColumn}>
                    {Labels.jobTitle[this.props.user.locale]}
                  </TableCell>
                  {header.map((item, index) => (
                    <TableCell key={index}>{Labels[item][this.props.user.locale]}</TableCell>
                  ))}
                  <TableCell key="actions" />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.rolesData &&
                  this.state.rolesData.map((item, index) =>
                    this.renderRolesTable(item, header, index)
                  )}
              </TableBody>
            </Table>
            {this.state.rolesData && this.state.rolesData.length === 0 && (
              <Typography className={classes.noData}>No roles</Typography>
            )}
          </Paper>
        </ErrorBoundaries>
        <Modal handleClose={this.handleClose} height="400" open={this.state.open} width="816">
          <PermissionModal
            data={this.state.permissionData}
            handleClose={this.handleClose}
            isAdmin={isAdmin}
            meta={this.state.showPermission}
            otherPermissions={this.state.otherPermissions}
            parent={this.state.companyInfo}
            roleId={this.state.roleId}
            roleName={this.state.roleName}
            title={this.state.permissionName}
            onComplete={this.handleRoleOnComplete}
          />
        </Modal>
        <ConfirmModal
          cancel={this.handleCancelConfirmation}
          confirm={this.state.confirmAction}
          message={this.state.confirmMessage}
          open={this.state.confirmDialog}
        />
      </ErrorBoundaries>
    );
  }
}

export const styledRoles = withStyles(styles, { withTheme: true })(RolesTable);

const mapStateToProps = state => ({
  user: state.user,
  isQuoteToPoEnabled: state.settings.isQuoteToPoEnabled
});

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog)),
  spinnerOn: () => dispatch(spinnerOn()),
  spinnerOff: () => dispatch(spinnerOff())
});

const reduxConnectedRoles = connect(mapStateToProps, mapDispatchToProps)(styledRoles);

export default withLDConsumer()(reduxConnectedRoles);
