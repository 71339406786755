import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Spinner from 'components/Spinners/CircularIndeterminate';
import { snackbarOn } from 'redux/actions/globalActions';
import { CompanyService } from 'services/core';

import { Logger } from 'services/Logger';
import { EmployeeAction, EmployeeStatus } from 'utils/AppConstants';

import styles from './styles';
/**
 * Renders Readonly inputs as per the build hero designs
 */

class EmployeeLoginToggle extends Component {
  constructor(props) {
    super(props);
    this.CompanyService = new CompanyService();
    this.state = {
      buttonLabel:
        this.props.specialbehaviour.queryResult.employee.status === EmployeeStatus.DEACTIVATED
          ? 'Enable account login'
          : 'Disable account login'
    };
  }

  clickAction = async () => {
    const employeeDetails = this.props.form.values;
    if (employeeDetails) {
      this.setState({ buttonClicked: true });

      const loginDisabled = employeeDetails.status === EmployeeStatus.DEACTIVATED;

      const action = loginDisabled ? EmployeeAction.ENABLE_LOGIN : EmployeeAction.DISABLE_LOGIN;

      try {
        const { data } = await this.CompanyService.saveEmployee({
          input: {
            id: employeeDetails.id,
            email: employeeDetails.email,
            userName: employeeDetails.userName
          },
          action
        });
        if (data) {
          const actionMsg =
            action === EmployeeAction.ENABLE_LOGIN
              ? 'enabled account login'
              : 'disabled account login';
          this.props.snackbarOn('success', `Successfully ${actionMsg}`);
          this.props.specialbehaviour.queryResult.refetch();
          this.setState({ buttonClicked: true });
        }
      } catch (error) {
        Logger.error(error);
        this.setState({ buttonClicked: false });
        const actionMsg =
          action === EmployeeAction.ENABLE_LOGIN ? 'enable account login' : 'disable account login';
        this.props.snackbarOn('error', `Unable to ${actionMsg}, please try again later`);
      }
    }
  };

  render() {
    const { classes } = this.props;
    const employeeDetails = this.props.specialbehaviour.queryResult.employee;

    // Don't show activate/deactivate button if employee is deactivated
    if (!employeeDetails.isActive) return null;

    if (
      [EmployeeStatus.ACTIVE, EmployeeStatus.INVITED].includes(employeeDetails.status) &&
      employeeDetails.id !== this.props.user.employeeId &&
      this.props.user.cognitoRole === 'TenantAdmin'
    ) {
      return (
        <Button
          className={` ${classes.inlineFormButton} ${classes.redButton}`}
          // color="secondary"
          // fullWidth={true}
          disabled={this.state.buttonClicked}
          variant="outlined"
          onClick={this.clickAction}
        >
          {'Disable Login'}
          {this.state.buttonClicked && (
            <Spinner className={this.props.classes.buttonProgress} size={18} />
          )}
        </Button>
      );
    }
    if (
      employeeDetails.status === EmployeeStatus.DEACTIVATED &&
      this.props.user.cognitoRole === 'TenantAdmin'
    ) {
      return (
        <Button
          className={classes.inlineFormButton}
          color="secondary"
          // fullWidth={true}
          variant="outlined"
          onClick={this.clickAction}
        >
          {'Enable Login'}
          {this.state.buttonClicked && (
            <Spinner className={this.props.classes.buttonProgress} size={18} />
          )}
        </Button>
      );
    }
    return null;
  }
}
const styledBuildopDeactivateButton = withStyles(styles)(EmployeeLoginToggle);

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const connectedBuildopsDeactivateButtton = connect(
  null,
  mapDispatchToProps
)(styledBuildopDeactivateButton);

export default connectedBuildopsDeactivateButtton;
