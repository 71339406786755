import React, { useMemo } from 'react';

import { Button, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { Box, Grid, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import uuid from 'uuid';

import { reorder } from 'utils';

import BillingHourTypeCard from './BillingHourTypeCard';

const BillingHourTypesTable = ({ billingHourTypes, setBillingHourTypes, loading }) => {
  const theme = useTheme();

  const creatingNewBillingHourType = billingHourTypes[billingHourTypes.length - 1]?.name === '';

  const resetSortOrder = unorderedHourTypes =>
    unorderedHourTypes.map((g, i) => ({ ...g, sortOrder: i }));

  const onRemoveBillingHourType = ({ index }) => {
    setBillingHourTypes(existingBillingHourTypes =>
      resetSortOrder(existingBillingHourTypes.filter((t, i) => i !== index))
    );
  };

  const onModifyBillingHourType = ({ field, value, index }) => {
    setBillingHourTypes(existingBillingHourTypes => {
      return existingBillingHourTypes.map((g, i) => {
        if (i === index) return { ...g, [field]: value };
        return g;
      });
    });
  };

  const moveModifier = ({ source, destination }) => {
    if (!Number.isInteger(source?.index) || !Number.isInteger(destination?.index)) return; // invalid drag
    setBillingHourTypes(existingBillingHourTypes =>
      resetSortOrder(
        reorder({
          arr: existingBillingHourTypes,
          source: source.index,
          destination: destination.index
        })
      )
    );
  };

  const addBillingHourType = () => {
    setBillingHourTypes(existingBillingHourTypes => [
      ...existingBillingHourTypes,
      {
        id: uuid.v4(),
        hourType: '',
        hourTypeAbbreviation: '',
        billingHourTypeId: null,
        isAutofill: false,
        intacctAccountNumber: null,
        intacctNonBillableAccountNumber: null,
        unsaved: true,
        sortOrder: existingBillingHourTypes.length
      }
    ]);
  };

  return (
    <ThemeProvider>
      <Grid item style={{ padding: 0, width: '100%' }}>
        <Box component="div">
          <DragDropContext onDragEnd={moveModifier}>
            <Droppable droppableId="droppable" isDropDisabled={loading}>
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {billingHourTypes.map((item, index) => (
                    <BillingHourTypeCard
                      billingHourTypes={billingHourTypes}
                      index={index}
                      isDragDisabled={loading}
                      item={item}
                      onAddBillingHourType={addBillingHourType}
                      onModifyBillingHourType={onModifyBillingHourType}
                      onRemoveBillingHourType={onRemoveBillingHourType}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {!creatingNewBillingHourType && (
              <Button type="leading" onClick={addBillingHourType}>
                <AddIcon css={{ height: theme.spacing(3) }} />
                <Typography variant={TV.BASE} weight={TW.MEDIUM}>
                  Billing Hour Type
                </Typography>
              </Button>
            )}
          </DragDropContext>
        </Box>
      </Grid>
    </ThemeProvider>
  );
};

export default BillingHourTypesTable;
