import React, { Component } from 'react';

import { CustomFormPDF, CustomFormPDFFormComponents } from '@BuildHero/sergeant';
import { PDFViewer } from '@react-pdf/renderer';

import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';
import { getCustomerSignatureSrc } from 'utils';

class FormPDF extends Component {
  constructor(props) {
    super(props);
    const { withViewer = true } = props;
    const { formDataJson } = props.data;

    const { meta, assetMap } = formDataJson;
    const [layout, data] = meta;
    this.state = {
      layout,
      data,
      assetMap,
      assets: {},
      withViewer,
      isLoading: true
    };
  }

  async componentDidMount() {
    const { assetMap } = this.state;
    const fileMap = {};
    if (assetMap) {
      const retrievedAssets = await this.getAllFormAssets(assetMap);
      retrievedAssets.forEach(item => {
        fileMap[item.id] = item.itemSources;
      });
    }
    this.setState({ assets: fileMap, isLoading: false });
  }

  getAssetUrl = async fileName => {
    if (!fileName) return '';
    try {
      const storageService = new StorageService();
      const fileURL = await storageService.getFile(fileName);
      if (fileURL) {
        return { fileURL }; // type was no where used. it was used only to check svg. svg is supported in cloudinary
      }
    } catch (error) {
      Logger.error(`Error reading image ${error}`);
    }
    return '';
  };

  getAllFormAssets = async fileMap => {
    const keys = Object.keys(fileMap);
    if (keys === 0) return fileMap;

    const promises = keys.map(async key => {
      const itemSources = fileMap[key];
      let updatedItemSources = [];
      if (itemSources.length > 0) {
        updatedItemSources = await Promise.all(
          itemSources.map(async asset => {
            const { label, fileUrl } = asset;
            const file = await this.getAssetUrl(fileUrl);
            return { label, ...file };
          })
        );
      }
      return { id: key, itemSources: updatedItemSources };
    });
    const newFiles = await Promise.all(promises);
    return newFiles;
  };

  setCustomControls = () => {
    const imageControlWithLocalSource = props => {
      const { name, options } = props;
      const assetMap = { ...this.state.assets };
      const asset = assetMap[name];
      if (asset) {
        const newOptions = { ...options };
        newOptions.source = asset;
        return <CustomFormPDFFormComponents.ImageControl options={newOptions} />;
      }
      return null;
    };

    const customSignatureScreen = props => {
      const { field } = props;
      try {
        let signatureObj = JSON.parse(field.value);
        const uri = getCustomerSignatureSrc({ signatureImageUrl: signatureObj.signature });
        signatureObj = { ...signatureObj, signature: uri };

        const updatedField = {
          ...field,
          value: JSON.stringify(signatureObj)
        };
        const updatedProps = { ...props, field: updatedField };

        return <CustomFormPDFFormComponents.SignatureScreen {...updatedProps} />;
      } catch (error) {
        console.error(`FormSection:FormPDF:customSignatureScreen: ${error}`);
        return <CustomFormPDFFormComponents.SignatureScreen {...props} />;
      }
    };

    const customControls = {
      ImageControl: imageControlWithLocalSource,
      SignatureScreen: customSignatureScreen
    };

    return customControls;
  };

  render() {
    const { layout, data, isLoading, withViewer } = this.state;
    if (isLoading) return null;

    if (!withViewer)
      return (
        <CustomFormPDF
          configuration={layout}
          customComponents={this.setCustomControls()}
          data={data}
          layout="default"
        />
      );

    return (
      <PDFViewer height="100%" width="100%">
        <CustomFormPDF
          configuration={layout}
          customComponents={this.setCustomControls()}
          data={data}
          layout="default"
        />
      </PDFViewer>
    );
  }
}

export default FormPDF;
