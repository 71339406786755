import React, { useEffect } from 'react';

import { FieldType, MUIForm } from '@BuildHero/sergeant';

import { useCompanyTimezone } from '@pm/components/hooks';

const configuration = ({ data, companyTimezone }) => ({
  fields: {},
  layouts: {
    default: {
      options: {},
      contents: [
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                grow: 1,
                padding: 8
              },
              contents: [
                {
                  component: 'SelectInput',
                  source: 'billingType',
                  options: {
                    isRequired: true,
                    valueSet: [
                      {
                        label: 'One Time Payment',
                        value: 'OneTimePayment'
                      },
                      {
                        label: 'Recurring Billing',
                        value: 'RecurringBilling'
                      },
                      {
                        label: 'Bill On Service Completion',
                        value: 'BillOnServiceCompletion'
                      }
                    ],
                    label: 'Billing Type'
                  }
                }
              ]
            },
            {
              options: {
                grow: 1,
                padding: 8
              },
              contents:
                data.billingType === 'RecurringBilling'
                  ? [
                      {
                        component: 'SelectInput',
                        source: 'paymentTerms',
                        options: {
                          isRequired: true,
                          valueSet: [
                            {
                              label: 'Monthly',
                              value: 'Monthly'
                            },
                            {
                              label: 'Bimonthly',
                              value: 'Bimonthly'
                            },
                            {
                              label: 'Quarterly',
                              value: 'Quarterly'
                            },
                            {
                              label: 'Biannually',
                              value: 'Biannually'
                            },
                            {
                              label: 'Annually',
                              value: 'Annually'
                            }
                          ],
                          label: 'Payment Interval'
                        }
                      }
                    ]
                  : []
            },
            {
              options: {
                grow: 1,
                padding: 8
              },
              contents:
                data.billingType === 'RecurringBilling'
                  ? [
                      {
                        component: 'DateInput',
                        source: 'firstBillDate',
                        options: {
                          isRequired: true,
                          valueSet: [],
                          label: 'First Billable Date',
                          timezone: companyTimezone
                        }
                      }
                    ]
                  : []
            }
          ]
        },
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                grow: 1,
                padding: 8
              },
              contents:
                data.billingType === 'RecurringBilling'
                  ? [
                      {
                        component: 'CurrencyInput',
                        source: 'termPrice',
                        options: {
                          isRequired: true,
                          label: 'Term Price'
                        }
                      }
                    ]
                  : []
            },
            {
              options: {
                grow: 1,
                padding: 8
              },
              contents: [
                {
                  component: 'CurrencyInput',
                  source: 'annualContractValue',
                  options: {
                    label: 'Annual Agreement Value'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  }
});

const Billing = ({ service, setService, data, saveData, onDirty, revisiting = false }) => {
  const [{ data: companyTimezone }] = useCompanyTimezone();

  useEffect(() => {
    if (revisiting && service) {
      service.validateForm();
    }
  }, [service]);

  useEffect(() => {
    onDirty(false);
  }, []);

  return (
    <MUIForm
      configuration={configuration({ data, companyTimezone })}
      data={data}
      key="Billing"
      layouts="edit"
      validate={newData => {
        saveData(oldData => ({
          ...oldData,
          ...newData
        }));
        const errors = {};
        if (!newData.billingType) {
          errors.billingType = 'Field is required';
        }
        if (newData.billingType === 'RecurringBilling' && !newData.paymentTerms) {
          errors.paymentTerms = 'Field is required';
        }
        if (newData.billingType === 'RecurringBilling' && !newData.firstBillDate) {
          errors.firstBillDate = 'Field is required';
        }
        if (
          newData.billingType === 'RecurringBilling' &&
          newData.firstBillDate < newData.startDate
        ) {
          errors.firstBillDate = 'First billable date must be on or after start date';
        }
        if (newData.billingType === 'RecurringBilling' && !newData.termPrice) {
          errors.termPrice = 'Field is required';
        }
        return errors;
      }}
      onComplete={() => {}}
      onCreateService={s => {
        setService(s);
      }}
      onDirtyChange={() => onDirty(true)}
      onFieldChange={(field, newValue) =>
        saveData(oldData => ({
          ...oldData,
          [field]: newValue
        }))
      } // for some reason this MUI form sometimes setss dirty back to false when it is still dirty, so hardcoding it to true
    />
  );
};

export default Billing;
