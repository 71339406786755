import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DefaultButton from 'components/Buttons/DefaultButton';
import ResponsiveTable from 'components/ResponsiveTable';
import SergeantModal from 'components/SergeantModal';
import Labels from 'meta/labels';
import simpleRowActionButtons from 'meta/ProjectManagement/global/simpleTableRowActionButton';
import submittalItemRowMeta from 'meta/ProjectManagement/Submittals/submittalItemTable';
import { getSubmittalItems, submittalItemChange } from 'services/API/submittalItem';
import { ProjectManagementSubmittalStatus } from 'utils/AppConstants';

import CustomMulticolorLinearProgress from '../../../components/CustomMulticolorLinearProgress';

import AddSubmittalItem from './AddSubmittalItem';

const useStyles = makeStyles(() => ({
  root: {
    margin: '31px 0px 20px 17px'
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  searchbarAndProgress: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  tableContainer: {
    marginTop: 20
  }
}));

const ItemLists = props => {
  const { user, projectId } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [isEditModal, setIsEditModal] = useState(false);
  const [isOpendModal, setIsOpendModal] = useState(false);
  const [submittalItems, setSubmittalItems] = useState([]);
  const [selectedEditItem, setSelectedEditItem] = useState({});
  const [confirmDelete, setConfirmDelete] = useState({
    confirmAction: () => {},
    confirmDialog: false,
    confirmTitle: ''
  });

  const handleCancelConfirmation = () =>
    setConfirmDelete({ confirmAction: () => {}, confirmDialog: false, confirmTitle: '' });

  const getProgressbarColor = status => {
    switch (status) {
      case ProjectManagementSubmittalStatus.TO_BE_SUBMITTED:
        return theme.palette.other?.statusOrange;
      case ProjectManagementSubmittalStatus.PACKAGED:
        return theme.palette.other?.statusBlue;
      case ProjectManagementSubmittalStatus.SENT:
        return theme.palette.grayscale(80);
      case ProjectManagementSubmittalStatus.REJECTED:
        return theme.palette.other?.statusRed;
      case ProjectManagementSubmittalStatus.APPROVED:
      default:
        return theme.palette.other?.statusGreen;
    }
  };

  const getFormattedProgressData = items => {
    if (!items || items?.length === 0) return [];

    const result = {};
    items.forEach(item => {
      result[item.status] = (result[item.status] || 0) + 1;
    });

    return Object.keys(result).map(chunk => {
      return {
        status: chunk,
        value: parseInt(Number(result[chunk] / items.length) * 100, 10),
        color: getProgressbarColor(chunk)
      };
    });
  };

  const sanitizeData = data => {
    return data.map((item, index) => {
      return {
        ...item,
        lineNumber: index + 1,
        packageNumber: item.submittalPackages[0]?.number || '',
        packageId: item.submittalPackages[0]?.id || ''
      };
    });
  };

  const getAllSubmittals = () => {
    getSubmittalItems(projectId).then(items => {
      setSubmittalItems(sanitizeData(items));
    });
  };

  useEffect(() => {
    getAllSubmittals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteAction = async (record, stopLoading) => {
    const payload = {
      deletedDate: moment().unix()
    };
    // for soft delete
    await submittalItemChange(record.id, payload);
    getAllSubmittals();
    setConfirmDelete({ confirmAction: () => {}, confirmDialog: false, confirmTitle: '' });
    stopLoading();
  };

  const handleRowactions = async (actionType, record) => {
    const selectedItem = submittalItems.filter(item => item.lineNumber === record.lineNumber)[0];

    if (actionType === 'remove') {
      setConfirmDelete({
        confirmAction: (data, stopLoading) => deleteAction(record, stopLoading),
        confirmDialog: true,
        confirmTitle: `Delete Submittal Item ${record.number}`
      });
    } else if (actionType === 'edit') {
      setSelectedEditItem(selectedItem);
      setIsEditModal(true);
      setIsOpendModal(true);
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.toolbarContainer}>
        <Box className={classes.searchbarAndProgress}>
          {/* TODO: search function */}
          {/* <CustomSearchBar key="submittalsPackagesSearch" placeholder="Search Items" /> */}
          <div />
          <CustomMulticolorLinearProgress
            data={getFormattedProgressData(submittalItems)}
            label="Registry status"
            style={{ marginLeft: 20 }}
          />
        </Box>
        <Box>
          <DefaultButton
            buttonProps={{ startIcon: <AddCircleOutlineIcon style={{ fontSize: 14 }} /> }}
            key="addsubmittalitemBtn"
            label={Labels.addSubmittalItem[user.locale]}
            style={{ height: 30, fontSize: 12 }}
            variant="containedPrimary"
            onClick={() => setIsOpendModal(true)}
          />
        </Box>
      </Box>
      <Box className={classes.tableContainer}>
        <ResponsiveTable
          data={submittalItems}
          disableFilter
          fullScreen
          noDataMsg="No Submittal Items"
          rowActionButtons={simpleRowActionButtons}
          rowActions={handleRowactions}
          rowMetadata={submittalItemRowMeta}
          tableName="ProjectManagementSubmittalItems"
        />
      </Box>
      <AddSubmittalItem
        editMode={isEditModal}
        handleClose={() => {
          getAllSubmittals();
          setIsEditModal(false);
          setIsOpendModal(false);
        }}
        initialData={{
          type: isEditModal ? 'edit' : 'default',
          itemData: isEditModal ? selectedEditItem : {}
        }}
        open={isOpendModal}
        projectId={projectId}
        user={user}
      />
      <SergeantModal
        alignCloseRight
        customComponents={{}}
        customPrimaryButtonLabel="Delete"
        dataType="Item"
        handleClose={handleCancelConfirmation}
        handlePrimaryAction={confirmDelete.confirmAction}
        mode="delete"
        open={confirmDelete.confirmDialog}
        title={confirmDelete.confirmTitle}
      />
    </Box>
  );
};

ItemLists.propTypes = {
  user: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired
};

const mapStateToProps = state => ({ user: state.user });
const ReduxConnectedItemRegistry = connect(mapStateToProps)(ItemLists);
export default ReduxConnectedItemRegistry;
