import { useCallback } from 'react';

import gql from 'graphql-tag';

import useExtendedMutation from '../../../../customHooks/useExtendedMutation';
import { GET_REVIEW_REPORT_BY_ID } from '../queries';

const DELETE_PURCHASE_ORDER = gql`
  mutation deletePurchaseOrder($partitionKey: String!, $id: String!) {
    deletePurchaseOrder(partitionKey: $partitionKey, id: $id) {
      id
    }
  }
`;

const DELETE_PURCHASE_ORDER_RECEIPT = gql`
  mutation deletePurchaseOrderReceipt($partitionKey: String!, $id: String!) {
    deletePurchaseOrderReceipt(partitionKey: $partitionKey, id: $id) {
      id
    }
  }
`;

const useDeleteReviewReportPurchaseOrderReceipt = () => {
  return useExtendedMutation(DELETE_PURCHASE_ORDER_RECEIPT, {
    serializer: ({ tenantId, purchaseOrderReceiptId }) => ({
      variables: {
        partitionKey: tenantId,
        id: purchaseOrderReceiptId
      }
    }),
    showConfirmation: () => true,
    confirmationModalOptions: {
      title: 'Delete Purchase Order',
      warn: true,
      modalText: 'Are you sure you want to delete this purchase order?'
    }
  });
};

const useDeleteReviewReportPurchaseOrderItem = reviewReportId => {
  return useExtendedMutation(DELETE_PURCHASE_ORDER, {
    serializer: ({ tenantId, purchaseOrderId }) => ({
      variables: {
        partitionKey: tenantId,
        id: purchaseOrderId
      }
    }),
    update: (cache, { data: { deletePurchaseOrder } }) => {
      const cachedData = cache.readQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        }
      });
      cache.writeQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        },
        data: {
          getReviewReportById: {
            ...(cachedData?.getReviewReportById || {}),
            purchaseOrders: {
              __typename: cachedData?.getReviewReportById?.purchaseOrders?.__typename,
              items:
                cachedData.getReviewReportById?.purchaseOrders?.items?.filter(
                  purchaseOrder => purchaseOrder?.id !== deletePurchaseOrder?.id
                ) || []
            }
          }
        }
      });
    }
  });
};

export const useDeleteReviewReportPurchaseOrder = reviewReportId => {
  const [deletePurchaseOrderReceiptMutation] = useDeleteReviewReportPurchaseOrderReceipt();
  const [deletePurchaseOrderItemMutation] = useDeleteReviewReportPurchaseOrderItem(reviewReportId);
  const mutation = useCallback(
    async ({ tenantId, purchaseOrder }) => {
      const purchaseOrderReceiptId = purchaseOrder?.purchaseOrderReceipts?.items?.[0]?.id;
      if (purchaseOrderReceiptId) {
        await deletePurchaseOrderReceiptMutation({ tenantId, purchaseOrderReceiptId });
      }

      const purchaseOrderId = purchaseOrder?.id;
      await deletePurchaseOrderItemMutation({ tenantId, purchaseOrderId });
    },
    [deletePurchaseOrderReceiptMutation, deletePurchaseOrderItemMutation]
  );
  return [mutation];
};
