import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, ButtonType, ThemeProvider } from '@BuildHero/sergeant';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Redirect } from 'react-router-dom';

import { FullScreenModal } from 'components';
import { useTrigger } from 'customHooks/useTrigger';
import { Mode } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import AgreementInformation from './AgreementInformation';
import useAdvancedScheduling from './hooks/useAdvancedScheduling';
import MaintenancePlan from './MaintenancePlan';
import PropertiesAndAssets from './PropertiesAndAssets';
import ScheduleMaintenances from './ScheduleMaintenances';
import ServiceAgreementCreationWizardFooter from './ServiceAgreementCreationWizardFooter';

const ServiceAgreementCreationWizard = ({ history, computedMatch }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [resave, triggerResave] = useTrigger();
  const [childrenSaving, setChildrenSaving] = useState(false);
  const [transitioningStep, setTransitioningStep] = useState(null);
  const [serviceAgreementId, setServiceAgreementId] = useState(
    computedMatch?.params?.serviceAgreementId
  );
  const [currentStepComplete, setCurrentStepComplete] = useState(false);
  const [childHasUnsavedChanges, setChildHasUnsavedChanges] = useState(false); // this is needed since MUIForms don't save changes to state until they are validated + submitted

  const { data: { advancedScheduling } = {} } = useAdvancedScheduling({
    serviceAgreementId
  });

  const steps = useMemo(
    () => [
      {
        label: 'Enter Agreement Information',
        isRequired: true,
        render: ({ triggerSave, setSaving, dirty }) => (
          <AgreementInformation
            dirty={dirty}
            history={history}
            serviceAgreementId={serviceAgreementId}
            setCurrentStepComplete={setCurrentStepComplete}
            setSaving={setSaving}
            setServiceAgreementId={setServiceAgreementId}
            triggerSave={triggerSave}
            onDirty={setChildHasUnsavedChanges}
          />
        )
      },
      {
        label: 'Add Properties & Assets',
        isRequired: false,
        render: ({ triggerSave, setSaving }) => (
          <PropertiesAndAssets
            serviceAgreementId={serviceAgreementId}
            setCurrentStepComplete={setCurrentStepComplete}
            setSaving={setSaving}
            triggerSave={triggerSave}
          />
        )
      },
      {
        label: 'Create Maintenance Plan',
        isRequired: false,
        render: ({ triggerSave, setSaving }) => (
          <MaintenancePlan
            serviceAgreementId={serviceAgreementId}
            setSaving={setSaving}
            triggerSave={triggerSave}
          />
        )
      },
      {
        label: 'Schedule Maintenaces',
        isRequired: false,
        render: ({ triggerSave, setSaving }) => (
          <ScheduleMaintenances
            serviceAgreementId={serviceAgreementId}
            setSaving={setSaving}
            triggerSave={triggerSave}
          />
        )
      }
    ],
    [history, serviceAgreementId]
  );

  const onChangeStep = useCallback(
    direction => {
      triggerResave();
      setTransitioningStep(direction);
      setChildrenSaving(true);
    },
    [triggerResave]
  );

  useEffect(() => {
    if (transitioningStep && !childrenSaving) {
      setTransitioningStep(null);
      if (transitioningStep === 'FORWARD') {
        setCurrentStepIndex(currIndex => {
          if (currIndex === steps.length - 1) return currIndex;

          return currIndex + 1;
        });
      } else if (transitioningStep === 'BACK') {
        setCurrentStepIndex(currIndex => {
          if (currIndex === 0) return currIndex;

          return currIndex - 1;
        });
      }
    }
  }, [transitioningStep, childrenSaving]);

  const flags = useFlags();
  const serviceAgreementAdvancedScheduling =
    flags[FeatureFlags.SERVICE_AGREEMENTS_ADVANCED_SCHEDULING];

  if (!serviceAgreementAdvancedScheduling) return <></>;

  // Strict check to prevent redirect when undefined while creating new service agreement
  // or loading existing service agreement
  if (advancedScheduling === false)
    return <Redirect to={`/serviceAgreement/${Mode.EDIT}/${serviceAgreementId}`} />;

  return (
    <FullScreenModal
      handleClose={() =>
        serviceAgreementId
          ? history.push(`/serviceAgreement/view/${serviceAgreementId}`)
          : history.push('/serviceAgreement/list')
      }
      modalHeaderButtons={
        <ThemeProvider>
          <Button loading={childrenSaving} type={ButtonType.PRIMARY} onClick={triggerResave}>
            Save as Draft
          </Button>
        </ThemeProvider>
      }
      open={!serviceAgreementId || advancedScheduling}
      title="Create New Agreement"
    >
      {steps[currentStepIndex].render({
        dirty: childHasUnsavedChanges,
        setSaving: setChildrenSaving,
        triggerSave: resave
      })}
      <ServiceAgreementCreationWizardFooter
        currentStepIndex={currentStepIndex}
        disabledNext={
          !currentStepComplete || transitioningStep || childHasUnsavedChanges || childrenSaving
        }
        disabledPrevious={transitioningStep || childrenSaving}
        steps={steps}
        transitioningStep={transitioningStep}
        onChangeStep={onChangeStep}
      />
    </FullScreenModal>
  );
};

export default ServiceAgreementCreationWizard;
