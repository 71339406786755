import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function Customers(props) {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SvgIcon {...props}>
      <path d="M12 1C18.0751 1 23 5.9928 23 12.1517C23 17.4173 19.4002 21.8305 14.5594 23L14.968 19.1521L17.6636 19.5841L18.1372 15.6585L20.25 15.4707L18.3375 10.7375C18.2829 7.20636 15.7147 4.2575 12.327 3.84428C12.0538 3.80671 11.7806 3.78793 11.5073 3.78793C9.46739 3.78793 7.53673 4.74584 6.24354 6.36114C3.8211 9.36636 4.24002 13.8178 7.15423 16.2971L6.76835 21.9639C3.33302 20.0774 1 16.3908 1 12.1517C1 5.9928 5.92487 1 12 1Z" />
    </SvgIcon>
  );
}
