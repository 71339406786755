import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import styles from './styles';
/**
 * Materail UI text input with BuildHero styles
 */
const MuiTextInput = ({ field, form, classes, user, ...rest }) => {
  const { specialbehaviour } = rest;
  const {
    queryResult,
    queryPath,
    dependentFieldName,
    dependentQueryPath,
    dependentReferenceKey
  } = specialbehaviour;

  if (
    specialbehaviour &&
    specialbehaviour.dependentField &&
    form.values[specialbehaviour.dependentField] === 'true'
  ) {
    const dependentValue = form.values[specialbehaviour.dependentValue];
    if (field.value !== dependentValue) {
      form.setFieldValue(field.name, form.values[specialbehaviour.dependentValue], false);
    }
  }

  // for getting values from a dependent field selection and based on selecetion populate value from queryResult
  if (
    dependentQueryPath &&
    dependentFieldName &&
    dependentReferenceKey &&
    form.values[dependentFieldName] &&
    form.values[dependentFieldName] !== ''
  ) {
    let localResult = queryResult;
    let displayDataValue = null;
    let dependentArray = null;
    const localDependentQueryPathSplit = dependentQueryPath.split('.');

    localDependentQueryPathSplit.forEach(element => {
      if (dependentArray === null) dependentArray = queryResult[element];
      else dependentArray = dependentArray[element];
    });
    localResult =
      dependentArray &&
      dependentArray.filter(
        item => item[dependentReferenceKey] === form.values[dependentFieldName]
      );

    // Choosing only the first element in the array after filtering
    if (localResult) {
      [localResult] = localResult;
    }
    // Logger.debug('dependent dropdown query result with selection value filter');
    // Logger.logObject(localResult);

    if (localResult) {
      const fieldPath = queryPath.split('.');
      fieldPath.forEach(element => {
        if (displayDataValue === null) displayDataValue = localResult[element];
        else displayDataValue = displayDataValue[element];
      });

      if (field.value !== displayDataValue) {
        form.setFieldValue(field.name, displayDataValue, false);
      }
    }
  }

  const helperText =
    form.errors && form.errors[field.name] ? form.errors && form.errors[field.name] : ' ';

  return (
    <TextField
      className={classes.textField}
      disabled
      error={form.errors && form.errors[field.name] && form.errors[field.name] !== ''}
      fullWidth
      helperText={helperText}
      variant="filled"
      {...field}
      {...rest}
    />
  );
};
// export default MuiTextInput;
export default withStyles(styles)(MuiTextInput);
