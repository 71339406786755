import React from 'react';

import Divider from '@material-ui/core/Divider';
import { noop } from 'lodash';

import { CorruptData } from 'components';

import { APRIL_30_2021 } from 'utils/AppConstants';

import WorkEvent from './WorkEvent';
import WorkEventDetails from './WorkEventDetails';

const TimesheetCards = ({
  workEvents,
  payrollHourTypes,
  payrollSetting,
  setDismissedBinderMap,
  isDismissed = false,
  updateTimesheetEntry,
  updateTimesheetEntryBinder,
  isEditable,
  setNotesModalData,
  tenantId,
  employeeId,
  tab,
  newAuditLogs = [],
  isIncomplete = false,
  onUpdateDayCard = noop,
  canDismiss = false,
  areBindersEnabled = false
}) => (
  <>
    {workEvents.map((workEvent, i) => {
      if (workEvent?.corruptData?.length > 0) {
        if (
          workEvent.corruptData.every(
            data =>
              Number.isInteger(data?.entry?.actualStartTimeUTC) &&
              data?.entry?.actualStartTimeUTC < APRIL_30_2021
          )
        ) {
          return null;
        }

        const entryWithReason = workEvent.corruptData.find(corruptData => corruptData.reason);
        let reason;
        if (entryWithReason) {
          reason = entryWithReason.reason;
        } else {
          reason = workEvent.corruptData.find(d => typeof d.hourType === 'undefined')
            ? 'Timesheet entry(ies) have hourTypeId not in company PayrollHourTypes'
            : 'Multiple timesheet entries for the same payroll hour type';
        }

        return <CorruptData corruptData={workEvent.corruptData} reason={reason} />;
      }

      return (
        <>
          <WorkEventDetails
            areBindersEnabled={areBindersEnabled}
            canDismiss={canDismiss}
            isDismissed={isDismissed}
            payrollSetting={payrollSetting}
            setDismissedBinderMap={setDismissedBinderMap}
            tab={tab}
            updateTimesheetEntryBinder={updateTimesheetEntryBinder}
            workEvent={workEvent}
            onUpdateDayCard={onUpdateDayCard}
          >
            <WorkEvent
              areBindersEnabled={areBindersEnabled}
              employeeId={employeeId}
              isEditable={isEditable}
              isIncomplete={isIncomplete}
              newAuditLogs={newAuditLogs}
              payrollHourTypes={payrollHourTypes}
              payrollSetting={payrollSetting}
              setNotesModalData={setNotesModalData}
              tenantId={tenantId}
              updateTimesheetEntry={updateTimesheetEntry}
              workEvent={workEvent}
            />
          </WorkEventDetails>
          {i !== workEvents.length - 1 && <Divider />}
        </>
      );
    })}
  </>
);

export default TimesheetCards;
