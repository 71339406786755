const validationMessage = {
  MESSAGE: 'Max digit/decimals reached'
};

export const unitCostValidate = currentValue => {
  if (currentValue.includes('.')) {
    const tempVal = currentValue.split('.')[1];
    if (tempVal.length >= 5) {
      const roundFiveUnitCost = `${currentValue
        .split('.')[0]
        .substring(0, 10)}.${currentValue.split('.')[1].substring(0, 5)}`;
      return [roundFiveUnitCost, validationMessage.MESSAGE];
    }
    return [currentValue, ''];
  }
  if (!currentValue.includes('.') && currentValue.length === 11) {
    return [currentValue.substring(0, 10), validationMessage.MESSAGE];
  }
  return [currentValue, ''];
};
