import gql from 'graphql-tag';

import { updateWatchedNonVisitQueries } from '@dispatch/Dispatch.utils';
import { nonVisitDetailsFragment } from '@dispatch/fragments';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { optimisticRecurringNonVisitEvents } from '../optimisticRecurringNonVisitEvents';

const ADD_RECURRING_NON_BILLABLE_EVENTS = gql`
  mutation addRecurringNonBillableEvents($data: AddRecurringNonBillableEventInput!) {
    addRecurringNonBillableEvents(data: $data) {
        ${nonVisitDetailsFragment}
    }
  }
`;

const optimisticResponseFactory = data => ({
  addRecurringNonBillableEvents: optimisticRecurringNonVisitEvents(data),
  isOptimistic: true
});

const serializer = data => ({
  variables: {
    data
  }
});

const useAddRecurringNonBillableEvents = () => {
  return useExtendedMutation(ADD_RECURRING_NON_BILLABLE_EVENTS, {
    serializer,
    optimisticResponseFactory,
    update: (cache, { data }) =>
      data.addRecurringNonBillableEvents?.forEach(event =>
        updateWatchedNonVisitQueries(cache, event)
      )
  });
};

export default useAddRecurringNonBillableEvents;
