import React, { useMemo, useState } from 'react';

import { Button, ThemeProvider } from '@BuildHero/sergeant';
import { DeleteOutlined, EditOutlined } from '@material-ui/icons';

import every from 'lodash/every';
import { useSelector } from 'react-redux';

import WrapTable, {
  actionButtonColumn,
  tableDateTimeFormatter,
  tableEmptyValueFormatter
} from 'components/WrapTable';
import { VisitStatus } from 'utils/AppConstants';
import { Mode } from 'utils/constants';

import { SectionHeader, summaryLayout, TableRowModal, VisitSubjectField } from '../Components';

import { useCreateVisitSummary, useDeleteVisitSummary, useUpdateVisitSummary } from '../mutations';

const getColumns = ({ isMultiVisits, isEditable, actions }) => {
  const fields = [
    {
      field: 'id',
      hide: true
    },
    {
      field: 'content',
      headerName: 'Summary Note',
      valueFormatter: tableEmptyValueFormatter,
      flex: 11
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      valueFormatter: tableEmptyValueFormatter,
      flex: 1
    },
    {
      field: 'lastEditedBy',
      headerName: 'Last Edited By',
      valueFormatter: tableEmptyValueFormatter,
      flex: 1
    },
    {
      field: 'lastEditedDateTime',
      headerName: 'Last Edited',
      valueFormatter: tableDateTimeFormatter,
      flex: 1
    }
  ];

  if (isMultiVisits) {
    fields.unshift({
      field: 'subject',
      headerName: 'Visit',
      renderCell: ({ row }) => VisitSubjectField({ ...row.visit }),
      flex: 1
    });
  }

  if (isEditable) {
    fields.push(actionButtonColumn({ actions }));
  }

  return fields;
};

const mapVisitsToRows = visits =>
  visits
    .filter(visit => visit.summaries?.items?.length > 0)
    .flatMap(visit => {
      return visit.summaries.items.map(summary => ({
        visit: {
          id: visit.id,
          status: visit.status,
          reviewStatus: visit.reviewStatus,
          visitNumber: visit.visitNumber,
          scheduledFor: visit.scheduledFor
        },
        id: summary.id,
        content: summary.summary,
        version: summary.version,
        includeInInvoice: summary.includeInInvoice,
        lastEditedBy: summary.lastUpdatedBy,
        lastEditedDateTime: summary.lastUpdatedDateTime,
        createdBy: summary.createdBy,
        visitId: visit.id
      }));
    })
    .sort((a, b) => {
      return (
        a.visit.visitNumber - b.visit.visitNumber ||
        a.content.toLowerCase() > b.content.toLowerCase()
      );
    });

const VisitSummaryTable = ({ visits, isMultiVisits, isLoading }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [summaryToEdit, setSummaryToEdit] = useState(null);

  const { tenantId } = useSelector(state => state?.company);
  const [updateSummaryMutation] = useUpdateVisitSummary();
  const [addSummaryMutation] = useCreateVisitSummary();
  const [deleteSummaryMutation] = useDeleteVisitSummary();

  const handleDeleteSummary = async summaryToDelete => {
    await deleteSummaryMutation({
      visitId: summaryToDelete.visit.id, // Required for update
      tenantId,
      summaryId: summaryToDelete.id
    });
  };

  const handleAddSummary = async newSummary => {
    await addSummaryMutation({
      tenantId,
      visitId: newSummary.visitId,
      summaries: [{ summary: newSummary.content }]
    });
    setShowAddModal(false);
  };

  const handleEditSummary = async updatedSummary => {
    await updateSummaryMutation({
      tenantId,
      summaryId: updatedSummary.id,
      version: updatedSummary.version,
      includeInInvoice: updatedSummary.includeInInvoice,
      summary: updatedSummary.content,
      lastEditedBy: updatedSummary.lastUpdatedBy,
      lastEditedDateTime: updatedSummary.lastUpdatedDateTime
    });
    setSummaryToEdit(null);
  };

  const actions = [
    {
      icon: EditOutlined,
      label: 'Edit',
      onClick: row => setSummaryToEdit(row)
    },
    {
      icon: DeleteOutlined,
      label: 'Delete',
      onClick: row => handleDeleteSummary(row)
    }
  ];

  // Applies to all visits, not just filtered ones
  const isEditable = every(visits, visit => visit.status === VisitStatus.CONVERTED);

  const rows = mapVisitsToRows(visits);
  const columns = getColumns({ rows, isMultiVisits, isEditable, actions });

  const visitOptions = useMemo(() => {
    if (!isMultiVisits) return;

    return visits.map(v => ({ label: `Visit ${v.visitNumber}`, value: v.id }));
  }, [visits, isMultiVisits]);

  const layout = useMemo(() => summaryLayout({ isMultiVisits, visitOptions }), [
    isMultiVisits,
    visitOptions
  ]);

  return (
    <>
      <ThemeProvider>
        <SectionHeader title="Visit Summary">
          {isEditable && (
            <Button
              loading={isLoading}
              size="small"
              type="secondary"
              onClick={() => setShowAddModal(true)}
            >
              Add Summary Note
            </Button>
          )}
        </SectionHeader>
        <WrapTable
          columns={columns}
          hideFooter={rows.length < 11}
          loading={isLoading}
          loadingRows={3}
          noDataMessage="No Visit Summaries"
          rows={rows}
        />
      </ThemeProvider>

      {!isLoading && (
        <>
          <TableRowModal
            data={{ visitId: isMultiVisits ? '' : visits[0].id, content: '' }}
            formVersion={Mode.ADD}
            layout={layout}
            mode={Mode.ADD}
            open={showAddModal}
            title="Add Summary Note"
            onAction={handleAddSummary}
            onClose={() => setShowAddModal(false)}
          />
          <TableRowModal
            data={summaryToEdit}
            formVersion={Mode.EDIT}
            layout={layout}
            mode={Mode.EDIT}
            open={Boolean(summaryToEdit)}
            title="Edit Summary Note"
            onAction={handleEditSummary}
            onClose={() => setSummaryToEdit(null)}
          />
        </>
      )}
    </>
  );
};

VisitSummaryTable.defaultProps = {
  visits: [],
  isLoading: false
};

export default VisitSummaryTable;
