/* eslint-disable no-template-curly-in-string */
import React from 'react';

import { FieldType, PDFComponents, TH, TV, TW, Typography } from '@BuildHero/sergeant';
import { Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import moment from 'moment';

import { EnvelopeType, InvoiceItemType } from 'utils/constants';

export const sumTransformFn = item =>
  item.lineItemType === InvoiceItemType.DISCOUNT ? -item.amount : item.amount;

export const footer = render => (
  <PDFComponents.Typography
    props={{
      render,
      style: { position: 'absolute', right: 24, bottom: 16 },
      color: '#808080',
      fixed: true
    }}
  />
);

export const envelopePane = {
  props: {
    testingid: 'billToCustomer',
    style: {
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: '#CCCCCC',
      borderRadius: 12,
      default: {
        width: 302,
        height: 104,
        padding: 10,
        boxSizing: 'border-box',
        flexDirection: 'row',
        justifyContent: 'space-between'
      },
      pdf: {
        width: 243,
        height: 80,
        padding: 8
      }
    }
  },
  contents: [
    {
      component: 'Field',
      source: 'billingAddressString',
      props: {
        testingid: 'billingAddress',
        color: '#000000',
        default: { variant: TV.S2 },
        pdf: { variant: TV.S3 }
      }
    }
  ]
};

export const getFields = ({ readonly, isSageIntegrated, sageJobOptions, paymentTermOptions }) => ({
  customerName: style => ({
    component: 'Field',
    source: 'billingCustomer.link',
    props: {
      testingid: 'customerName',
      label: 'CUSTOMER NAME',
      type: FieldType.LINK,
      style
    }
  }),
  customerProperty: style => ({
    component: 'Field',
    source: 'customerProperty.link',
    props: {
      testingid: 'propertyName',
      label: 'PROPERTY NAME',
      type: FieldType.LINK,
      style
    }
  }),
  propertyAddress: style => ({
    component: 'Field',
    source: 'propertyAddress',
    props: {
      testingid: 'propertyAddress',
      label: 'PROPERTY ADDRESS',
      style
    }
  }),
  dueDate: style => ({
    component: { default: readonly ? 'Field' : 'DateInput', pdf: 'Field' },
    source: 'dueDate',
    props: {
      inputProps: {
        testingid: 'dueDate-invoices'
      },
      slowField: true,
      onChange: (_, value, { setFieldValue, values }) => {
        if (value && values.terms) {
          setFieldValue(
            'issuedDate',
            moment
              .unix(value)
              .subtract(values.terms.value, 'd')
              .unix()
          );
        }
      },
      label: 'DUE DATE',
      type: FieldType.DATE,
      style
    }
  }),
  sageJob: style =>
    isSageIntegrated && {
      component: { default: readonly ? 'Field' : 'Select', pdf: 'Field' },
      source: { default: readonly ? 'sageJob.label' : 'sageJob', pdf: 'sageJob.label' },
      props: {
        label: 'SAGE JOB',
        options: sageJobOptions,
        clearable: true,
        searchable: true,
        style
      }
    },
  authorizedBy: style => ({
    component: { default: readonly ? 'Field' : 'Input', pdf: 'Field' },
    source: 'authorizedBy',
    props: {
      inputProps: {
        testingid: 'authorizedBy'
      },
      label: 'AUTHORIZED BY',
      style
    }
  }),
  customerPO: style => ({
    component: { default: readonly ? 'Field' : 'Input', pdf: 'Field' },
    source: 'customerProvidedPONumber',
    props: {
      inputProps: {
        testingid: 'customer_PO'
      },
      label: 'CUSTOMER PO',
      style
    }
  }),
  customerWO: style => ({
    component: { default: readonly ? 'Field' : 'Input', pdf: 'Field' },
    source: 'customerProvidedWONumber',
    props: {
      inputProps: {
        testingid: 'customer_WO'
      },
      label: 'CUSTOMER WO',
      style
    }
  }),
  nte: style => ({
    component: { default: readonly ? 'Field' : 'CurrencyInput', pdf: 'Field' },
    source: 'amountNotToExceed',
    props: {
      inputProps: {
        testingid: 'currencyinput-amountNotToExceed'
      },
      label: 'NTE',
      type: FieldType.CURRENCY,
      style
    }
  }),
  terms: style => ({
    component: { default: readonly ? 'Field' : 'Select', pdf: 'Field' },
    source: { default: readonly ? 'terms.label' : 'terms', pdf: 'terms.label' },
    props: {
      testingid: 'terms-invoices',
      slowField: true,
      onChange: (_, value, { setFieldValue, values }) => {
        if (value && values.issuedDate) {
          setFieldValue(
            'dueDate',
            moment
              .unix(values.issuedDate)
              .add(value.value, 'd')
              .unix()
          );
        }
      },
      label: 'TERMS',
      options: paymentTermOptions,
      style
    }
  })
});

export const getTotals = ({ readonly, settings, taxRateOptions, adjustmentsFlag }) => ({
  props: {
    pdf: { wrap: false },
    style: {
      flexDirection: 'row',
      justifyContent: 'flex-end'
    }
  },
  contents: [
    {
      props: {
        style: {
          textAlign: 'right',
          width: 'auto',
          pdf: { width: 135 }
        }
      },
      contents: [
        settings.totals.subtotal && {
          component: 'Typography',
          props: {
            testingid: 'subTotal',
            value: 'Subtotal',
            default: {
              variant: TV.BASE,
              style: { marginBottom: 16 }
            },
            pdf: {
              variant: TV.S3,
              style: { marginBottom: 4 }
            }
          }
        },
        settings.totals.discount && {
          component: 'Typography',
          props: {
            value: 'Service Fees',
            default: {
              variant: TV.BASE,
              style: { marginBottom: 16 }
            },
            pdf: {
              variant: TV.S3,
              style: { marginBottom: 4 }
            }
          }
        },
        settings.totals.discount && {
          component: 'Typography',
          props: {
            value: 'Discount',
            default: {
              variant: TV.BASE,
              style: { marginBottom: 32 }
            },
            pdf: {
              variant: TV.S3,
              style: { marginBottom: 8 }
            }
          }
        },
        settings.totals.discount && {
          component: 'Typography',
          props: {
            value: 'Subtotal After Discount/Fees',
            default: {
              variant: TV.BASE,
              style: { marginBottom: 32 }
            },
            pdf: {
              variant: TV.S3,
              style: { marginBottom: 8 }
            }
          }
        },
        settings.totals.tax && {
          component: 'Typography',
          props: {
            value: 'Taxable Subtotal',
            default: {
              variant: TV.BASE,
              style: { marginBottom: 26 }
            },
            pdf: {
              variant: TV.S3,
              style: { marginBottom: 4 }
            }
          }
        },
        settings.totals.tax && {
          component: 'Typography',
          props: {
            value: 'Sales Tax Rate',
            default: {
              variant: TV.BASE,
              style: { marginBottom: 26 }
            },
            pdf: {
              variant: TV.S3,
              style: { marginBottom: 4 }
            }
          }
        },
        settings.totals.tax && {
          component: 'Typography',
          props: {
            value: 'Tax Amount',
            default: {
              variant: TV.BASE,
              style: { marginBottom: 32 }
            },
            pdf: {
              variant: TV.S3,
              style: { marginBottom: 8 }
            }
          }
        },
        {
          component: 'Typography',
          props: {
            weight: TW.BOLD,
            value: 'Total',
            default: {
              variant: TV.BASE,
              style: { marginBottom: 32 }
            },
            pdf: {
              variant: TV.S3,
              style: { marginBottom: 8 }
            }
          }
        },
        settings.totals.amountPaid && {
          component: 'Typography',
          props: {
            value: 'Amount Paid',
            default: {
              variant: TV.BASE,
              style: { marginBottom: 16 }
            },
            pdf: {
              variant: TV.S3,
              style: { marginBottom: 4 }
            }
          }
        },
        settings.totals.balance && {
          component: 'Typography',
          props: {
            value: 'Balance',
            default: { variant: TV.BASE },
            pdf: { variant: TV.S3 }
          }
        },
        settings.totals.balance &&
          adjustmentsFlag && {
            component: 'Typography',
            resolveProps: ({ adjustedAmount }) => ({ hide: !adjustedAmount }),
            props: {
              value: 'Total Adjusted Amount',
              default: {
                variant: TV.BASE,
                style: { marginBottom: 16, marginTop: 32 }
              },
              pdf: {
                variant: TV.S3,
                style: { marginBottom: 4, marginTop: 8 }
              }
            }
          },
        settings.totals.balance &&
          adjustmentsFlag && {
            contents: [
              {
                component: { default: null, pdf: 'Typography' },
                resolveProps: ({ adjustedAmount }) => ({ hide: !adjustedAmount }),
                props: {
                  value: 'Adjusted Balance',
                  variant: TV.S3
                }
              },
              {
                component: { default: 'Container', pdf: null },
                resolveProps: ({ adjustedAmount }) => ({ hide: !adjustedAmount }),
                props: {
                  children: (
                    <Tooltip title="Updated invoice balance based on applied write-off amount.">
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <Typography variant={TV.BASE}>Adjusted Balance</Typography>
                        <InfoOutlined
                          style={{
                            marginLeft: 4,
                            color: 'black',
                            fontSize: 16
                          }}
                        />
                      </span>
                    </Tooltip>
                  )
                }
              }
            ]
          }
      ]
    },
    {
      props: {
        style: {
          pdf: {
            minWidth: 67,
            marginLeft: 32
          },
          default: {
            minWidth: 90,
            marginLeft: 40
          },
          alignItems: 'flex-end'
        }
      },
      contents: [
        settings.totals.subtotal && {
          component: 'Field',
          source: 'subtotal',
          props: {
            testingid: 'subTotal',
            default: {
              variant: TV.BASE,
              style: { marginBottom: 16 }
            },
            pdf: {
              variant: TV.S3,
              style: { marginBottom: 4 }
            },
            weight: TW.BOLD,
            type: FieldType.CURRENCY
          }
        },
        settings.totals.discount && {
          component: 'Field',
          source: 'serviceFee',
          props: {
            testingid: 'serviceFees',
            default: {
              variant: TV.BASE,
              style: { marginBottom: 16 }
            },
            pdf: {
              variant: TV.S3,
              style: { marginBottom: 4 }
            },
            weight: TW.BOLD,
            type: FieldType.CURRENCY
          }
        },
        settings.totals.discount && {
          component: 'Field',
          source: 'discount',
          props: {
            testingid: 'discount',
            variant: { default: TV.BASE, pdf: TV.S3 },
            weight: TW.BOLD,
            type: FieldType.CURRENCY
          }
        },
        settings.totals.discount && {
          component: 'Divider',
          props: {
            color: '#000000',
            margin: { default: 15.5, pdf: 3.5 },
            alignSelf: 'stretch'
          }
        },
        settings.totals.discount && {
          component: 'Field',
          source: 'subtotalAfterDiscountsAndFees',
          props: {
            testingid: 'subtotalAfterDiscountsAndFees',
            variant: { default: TV.BASE, pdf: TV.S3 },
            weight: TW.BOLD,
            type: FieldType.CURRENCY
          }
        },
        settings.totals.discount && {
          component: 'Divider',
          props: {
            color: '#000000',
            margin: { default: 15.5, pdf: 3.5 },
            alignSelf: 'stretch'
          }
        },
        settings.totals.tax && {
          component: 'Field',
          source: 'taxableSubtotal',
          props: {
            testingid: 'taxableSubtotal',
            weight: TW.BOLD,
            type: FieldType.CURRENCY,
            default: {
              variant: TV.BASE,
              style: { marginBottom: readonly ? 26 : 16 }
            },
            pdf: {
              variant: TV.S3,
              style: { marginBottom: 4 }
            }
          }
        },
        settings.totals.tax && {
          component: { default: readonly ? 'Field' : 'Select', pdf: 'Field' },
          source: {
            default: readonly ? 'taxRate.taxRate' : 'taxRate',
            pdf: 'taxRate.taxRate'
          },
          props: {
            testingid: 'salesTaxRate',
            default: readonly
              ? {
                  variant: TV.BASE,
                  weight: TW.BOLD,
                  type: FieldType.PERCENTAGE,
                  style: { marginBottom: 26 }
                }
              : {
                  options: taxRateOptions,
                  clearable: true,
                  searchable: true,
                  style: { minWidth: 200, marginBottom: 16 }
                },
            pdf: {
              variant: TV.S3,
              weight: TW.BOLD,
              type: FieldType.PERCENTAGE,
              style: { marginBottom: 4 }
            }
          }
        },
        settings.totals.tax && {
          component: 'Field',
          source: 'taxAmount',
          props: {
            testingid: 'taxAmount',
            variant: { default: TV.BASE, pdf: TV.S3 },
            weight: TW.BOLD,
            type: FieldType.CURRENCY
          }
        },
        settings.totals.tax && {
          component: 'Divider',
          props: {
            color: '#000000',
            margin: { default: 15.5, pdf: 3.5 },
            alignSelf: 'stretch'
          }
        },
        {
          component: 'Field',
          source: 'totalAmount',
          props: {
            testingid: 'totalAmount',
            variant: { default: TV.BASE, pdf: TV.S3 },
            weight: TW.BOLD,
            type: FieldType.CURRENCY
          }
        },
        {
          component: 'Divider',
          props: {
            color: '#000000',
            margin: { default: 15.5, pdf: 3.5 },
            alignSelf: 'stretch'
          }
        },
        settings.totals.amountPaid && {
          component: 'Field',
          source: 'amountPaid',
          props: {
            testingid: 'amountPaid',
            weight: TW.BOLD,
            type: FieldType.CURRENCY,
            default: {
              variant: TV.BASE,
              style: { marginBottom: 16 }
            },
            pdf: {
              variant: TV.S3,
              style: { marginBottom: 4 }
            }
          }
        },
        settings.totals.balance && {
          component: 'Field',
          source: 'balance',
          props: {
            testingid: 'balanceAmount',
            default: { variant: TV.BASE },
            pdf: { variant: TV.S3 },
            weight: TW.BOLD,
            type: FieldType.CURRENCY
          }
        },
        settings.totals.balance &&
          adjustmentsFlag && {
            component: 'Divider',
            resolveProps: ({ adjustedAmount }) => ({ hide: !adjustedAmount }),
            props: {
              color: '#000000',
              margin: { default: 15.5, pdf: 3.5 },
              alignSelf: 'stretch'
            }
          },
        settings.totals.balance &&
          adjustmentsFlag && {
            component: 'Field',
            resolveProps: ({ adjustedAmount }) => ({ hide: !adjustedAmount }),
            source: 'adjustedAmount',
            props: {
              testingid: 'adjustedAmount',
              default: {
                variant: TV.BASE,
                style: { marginBottom: 16 }
              },
              pdf: {
                variant: TV.S3,
                style: { marginBottom: 4 }
              },
              weight: TW.BOLD,
              type: FieldType.CURRENCY
            }
          },
        settings.totals.balance &&
          adjustmentsFlag && {
            component: 'Field',
            resolveProps: ({ adjustedAmount }) => ({ hide: !adjustedAmount }),
            source: 'adjustedBalance',
            props: {
              testingid: 'adjustedBalance',
              variant: { default: TV.BASE, pdf: TV.S3 },
              weight: TW.BOLD,
              type: FieldType.CURRENCY
            }
          }
      ]
    }
  ]
});

const parentLink = {
  component: { default: 'Field', pdf: 'Typography' },
  source: { default: 'parentLink', pdf: 'parentLink.label' },
  props: {
    testingid: 'parentLink',
    default: { type: FieldType.LINK },
    pdf: { variant: TV.S1 }
  }
};

/**
 * Returns an array to build the bill to envelope pane area.
 *
 * @param isDoublePane {boolean} True if the envelope type is double pane
 * @param readonly {boolean} True if the mode is readonly (no edit)
 */
const getBillToArea = (isDoublePane, readonly) => [
  {
    component: 'Typography',
    props: {
      testingid: 'billToCustomer',
      value: 'Bill To',
      default: {
        variant: TV.S2,
        style: {
          paddingLeft: 54,
          paddingTop: isDoublePane ? 17 : 24,
          paddingBottom: 4
        }
      },
      pdf: {
        variant: TV.S3,
        style: {
          paddingLeft: 43,
          paddingTop: isDoublePane ? 13 : 22,
          paddingBottom: 2
        }
      },
      weight: TW.MEDIUM,
      caps: true,
      color: '#999999'
    }
  },
  {
    props: {
      style: {
        flexDirection: 'row',
        default: {
          paddingLeft: 43,
          paddingBottom: 66
        },
        pdf: {
          paddingLeft: 35,
          paddingBottom: 40
        }
      }
    },
    contents: [
      envelopePane,
      !readonly && {
        component: {
          default: 'EditAddressButton',
          pdf: null
        },
        source: 'billingAddress'
      }
    ]
  }
];

/**
 * The single pane - big logo envelope type has a different header config
 */
const singlePaneBigLogoHeader = ({ readonly, showParent, sender }) => {
  const logo = {
    props: {
      style: {
        pdf: {
          height: 88,
          marginBottom: -6
        },
        default: {
          height: 116,
          marginBottom: 24
        }
      }
    },
    contents: [
      {
        component: 'Image',
        source: 'sender.logoUrl',
        props: {
          alt: sender.name,
          style: {
            height: '100%'
          }
        }
      }
    ]
  };

  const invoiceInfo = [
    {
      props: {
        style: {
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 2
        }
      },
      contents: [
        {
          component: 'Typography',
          props: {
            weight: TW.BOLD,
            value: 'Invoice',
            default: { variant: TV.XL },
            pdf: { variant: TV.BASE }
          }
        },
        {
          component: 'Typography',
          source: 'invoiceNumber',
          props: {
            testingid: 'invoiceNumber',
            default: { variant: TV.L, style: { marginLeft: 8 } },
            pdf: { variant: TV.BASE, style: { marginLeft: 4 } }
          }
        }
      ]
    },
    showParent && {
      ...parentLink,
      props: { ...parentLink.props, pdf: { variant: TV.S2, marginBottom: 2 } }
    },
    {
      component: { default: readonly ? 'Field' : 'DateInput', pdf: 'Field' },
      source: 'issuedDate',
      props: {
        testingid: 'invoice-issueDate',
        slowField: true,
        onChange: (_, value, { setFieldValue, values }) => {
          if (value && values.terms) {
            setFieldValue(
              'dueDate',
              moment
                .unix(value)
                .add(values.terms.value, 'd')
                .unix()
            );
          }
        },
        type: FieldType.DATE,
        default: {
          label: !readonly && 'Issued Date',
          style: {
            width: 170,
            marginBottom: 26,
            marginTop: readonly ? undefined : 12
          }
        },
        pdf: {
          variant: TV.S2,
          style: {
            marginBottom: 20
          }
        }
      }
    }
  ];

  return [logo, ...getBillToArea(false, readonly), ...invoiceInfo];
};

/**
 * Get header depending on single pane or double pane tenant setting (envelopeType)
 */
export const getHeader = ({ readonly, showParent, sender, envelopeType, isSimpleMode }) => {
  if (envelopeType === EnvelopeType.SINGLE_LARGE_LOGO && !isSimpleMode) {
    return singlePaneBigLogoHeader({ readonly, showParent, sender });
  }
  const isDoublePane = envelopeType === EnvelopeType.DOUBLE;

  const invoiceInfo = {
    props: { style: isDoublePane ? { flexGrow: 1 } : undefined },
    contents: [
      {
        props: {
          style: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }
        },
        contents: [
          {
            props: {
              style: {
                flexDirection: 'row',
                alignItems: 'center'
              }
            },
            contents: [
              {
                component: 'Typography',
                props: {
                  weight: TW.BOLD,
                  value: 'Invoice',
                  default: { variant: TV.XL },
                  pdf: { variant: TV.BASE }
                }
              },
              {
                component: 'Typography',
                source: 'invoiceNumber',
                props: {
                  testingid: 'invoiceNumber',
                  default: { variant: TV.L, style: { marginLeft: 8 } },
                  pdf: { variant: TV.BASE, style: { marginLeft: 4 } }
                }
              }
            ]
          },
          {
            component: { default: readonly ? 'Field' : 'DateInput', pdf: 'Field' },
            source: 'issuedDate',
            props: {
              testingid: 'invoice-issueDate',
              slowField: true,
              onChange: (_, value, { setFieldValue, values }) => {
                if (value && values.terms) {
                  setFieldValue(
                    'dueDate',
                    moment
                      .unix(value)
                      .add(values.terms.value, 'd')
                      .unix()
                  );
                }
              },
              type: FieldType.DATE,
              default: {
                label: !readonly && 'Issued Date',
                style: { width: 'auto' },
                variant: TV.L
              },
              pdf: { variant: TV.BASE }
            }
          }
        ]
      },
      showParent && parentLink
    ]
  };

  const companyInfo = {
    props: {
      style: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: isDoublePane ? 'center' : undefined,
        pdf: {
          height: 36,
          marginBottom: isDoublePane ? 0 : 16
        },
        default: {
          height: 52,
          marginBottom: isDoublePane ? 0 : 20
        }
      }
    },
    contents: [
      {
        component: 'Image',
        source: 'sender.logoUrl',
        props: {
          alt: sender.name,
          style: isDoublePane
            ? {
                pdf: {
                  maxWidth: 100,
                  height: 76,
                  objectFit: 'contain'
                },
                default: {
                  height: 'fit-content',
                  maxHeight: 100,
                  maxWidth: 130
                }
              }
            : {
                height: '100%'
              }
        }
      },
      {
        props: {
          style: {
            alignItems: 'flex-end'
          }
        },
        contents: [
          {
            component: 'Typography',
            source: 'sender.address',
            props: {
              testingid: 'senderAddress',
              height: TH.COMFORTABLE,
              style: {
                ...(isDoublePane ? { pdf: { width: 122 }, default: {} } : { width: 160 }),
                textAlign: 'right'
              },
              default: { variant: TV.PRINT }
            }
          },
          {
            component: 'Typography',
            source: 'sender.phoneNumber',
            props: {
              testingid: 'senderContact',
              height: TH.COMFORTABLE,
              default: { variant: TV.PRINT }
            }
          }
        ]
      }
    ]
  };

  const billToInfo = isSimpleMode ? [] : getBillToArea(isDoublePane, readonly);

  return isDoublePane
    ? [
        {
          props: {
            style: {
              flexDirection: 'row',
              default: {
                marginTop: 13
              },
              pdf: {
                marginTop: 19
              }
            }
          },
          contents: [
            {
              props: {
                style: {
                  borderWidth: 2,
                  borderStyle: 'solid',
                  borderColor: '#CCCCCC',
                  borderRadius: 12,
                  justifyContent: 'center',
                  default: {
                    width: 321,
                    height: 104,
                    padding: '17px 16px',
                    boxSizing: 'border-box',
                    marginRight: 54
                  },
                  pdf: {
                    width: 258.288,
                    height: 80,
                    paddingHorizontal: 16,
                    paddingVertical: 15,
                    marginRight: 43.45
                  }
                }
              },
              contents: [companyInfo]
            },
            invoiceInfo
          ]
        },
        ...billToInfo
      ]
    : [companyInfo, invoiceInfo, ...billToInfo];
};
