import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { menuOpen } from 'redux/actions/globalActions';

import styles from './styles';
import HeaderToolbar from './Toolbar';
/**
 * Application header
 * @param props
 */
function AppHeader(props) {
  const { classes } = props;
  const handleDrawerClose = () => props.handleMenuState(!props.menu.isOpen);
  return (
    <div className={classes.root}>
      <AppBar
        className={classNames(classes.appBar, {
          [classes.appBarShift]: props.menu.isOpen
        })}
        color="default"
        position="static"
      >
        <Toolbar>
          <IconButton
            aria-label="Open drawer"
            className={classNames(classes.menuButton, props.menu.isOpen && classes.hide)}
            color="inherit"
            onClick={handleDrawerClose}
          >
            <MenuIcon />
          </IconButton>
          {props.children}
          <HeaderToolbar />
        </Toolbar>
      </AppBar>
    </div>
  );
}

// Add styles from the syles.js as props
const styledHeader = withStyles(styles, { withTheme: true })(AppHeader);

// gets current menu redux state (open/closed) and pass them as parameters
const mapStateToProps = state => ({
  menu: state.menu
});

// Handles menu open and close actions in small screen (sm) modes
const mapDispatchToProps = dispatch => ({
  handleMenuState: menustate => dispatch(menuOpen(menustate))
});

AppHeader.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(styledHeader);
