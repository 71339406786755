import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { CollapsibleSection, ResponsiveTable } from 'components';

import ImageSection from '../JobTabs/ImageSection';

import JobSummary from './JobSummary';
import { productTableMeta, purchasedItemTableMeta, timeTableMeta } from './TableMeta';

const styles = makeStyles(theme => ({
  centeredDiv: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  centeredTable: {
    width: '80%',
    paddingBottom: 24
  },
  subHeading: {
    fontWeight: 'normal',
    color: theme.palette.grayscale(60),
    marginBottom: 24
  }
}));
const MaintenanceReport = props => {
  const { visits, parts, attachments, timesheets } = props;
  const materials = parts?.filter(item => item.origin === 'Inventory');
  const purchasedItems = parts?.filter(item => item.origin === 'Receipt');
  const classes = styles();
  return (
    <>
      <CollapsibleSection title="Job Summary">
        <JobSummary visits={visits} />
      </CollapsibleSection>
      <CollapsibleSection title="Time">
        <Box className={classes.centeredDiv}>
          <Box className={classes.centeredTable}>
            <ResponsiveTable
              data={timesheets}
              disableFilter
              noDataMsg="No data"
              rowMetadata={timeTableMeta}
            />
          </Box>
        </Box>
      </CollapsibleSection>
      <CollapsibleSection title="Material">
        <Typography className={classes.subHeading} variant="h4">
          Inventory
        </Typography>
        <Box className={classes.centeredDiv}>
          <Box className={classes.centeredTable}>
            <ResponsiveTable
              data={materials || []}
              disableFilter
              noDataMsg="No data"
              rowMetadata={productTableMeta}
            />
          </Box>
          <Box className={classes.centeredTable}>
            <Typography className={classes.subHeading} variant="h4">
              Purchased
            </Typography>
            <ResponsiveTable
              data={purchasedItems || []}
              disableFilter
              noDataMsg="No data"
              rowMetadata={purchasedItemTableMeta}
            />
          </Box>
        </Box>
      </CollapsibleSection>
      <CollapsibleSection title="Photos & Videos">
        <Box className={classes.centeredDiv}>
          <Box className={classes.centeredTable}>
            <ImageSection attachments={attachments} disableTitle visits={visits || []} />
          </Box>
        </Box>
      </CollapsibleSection>
    </>
  );
};

export default MaintenanceReport;
