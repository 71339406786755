import React, { useEffect, useRef, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Switch, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useCompanyTimezone } from '@pm/components/hooks';
import usePropertyAssets from 'customHooks/usePropertyEntities';
import labels from 'meta/Scheduler/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import { getTenantSettingValueForKey } from 'utils';
import { PropertyEntities } from 'utils/constants';

import { addVisitToJobMutation } from '../../addVisitToJobMutation';
import VisitForm from '../VisitForm';
import buildVisitCreatePayload from '../VisitForm/helpers/buildVisitCreatePayload';
import getCompanyDepartments from '../VisitForm/helpers/getCompanyDepartments';
import { createRecurringVisit } from '../VisitForm/helpers/mutationRecurringVisits.gql';
import useAvailableForms from '../VisitForm/helpers/useAvailableForms';
import useCrews from '../VisitForm/helpers/useCrews';

const getTitle = locale => labels.newVisit_Title[locale];

const VisitCreateInline = props => {
  const {
    user,
    locale,
    techs,
    showSnackbar,
    customerPropertyId,
    onCreateVisitService,
    disableAssetSelection,
    visitFormData
  } = props;

  const { propertyEntities: propertyAssets } = usePropertyAssets(
    PropertyEntities.ASSETS,
    customerPropertyId,
    showSnackbar
  );
  const [addVisit] = useMutation(addVisitToJobMutation);
  const [addRecurringVisit] = useMutation(createRecurringVisit);

  const validateForm = useRef();
  const propertyAssetsRef = useRef();
  propertyAssetsRef.current = propertyAssets;
  const [errorMessage, setErrorMessage] = useState('');
  const [isMultiVisits, setMultiVisits] = useState(false);

  const departments = getCompanyDepartments();
  const availableForms = useAvailableForms(user);
  const availableFormsRef = useRef();
  availableFormsRef.current = availableForms;
  const crews = useCrews(user);

  const crewTimeTracking = getTenantSettingValueForKey('crewTimeTracking') === 'true';
  const [{ data: companyTimezone }] = useCompanyTimezone();

  const handleFormChange = data => {
    // the visitFormFields are added to avoid chaning drastically on the visit form
    // visit form refers to these 3 fields only
    const visitFormFields = {
      actualDuration: `${data?.visitDuration || 60} minutes`,
      tentativeDate: data?.visitDate ? moment.unix(data.visitDate).format() : undefined,
      tentativeTime: data?.visitTime
    };
    visitFormData.current = { ...data, ...visitFormFields, isMultiVisits };
  };

  const handleSubmit = async job => {
    if (!visitFormData.current) {
      return;
    }

    try {
      setErrorMessage(undefined);

      const payload = buildVisitCreatePayload({
        jobId: job?.id,
        user,
        availableForms: availableFormsRef.current,
        propertyAssets: propertyAssetsRef.current,
        visitFormData: visitFormData.current,
        isMultiVisits: visitFormData.current?.isMultiVisits || false
      });

      if (visitFormData.current?.isMultiVisits) {
        await addRecurringVisit({
          variables: {
            partitionKey: user.tenantId,
            input: payload
          }
        });
      } else {
        await addVisit({
          variables: {
            partitionKey: user.tenantId,
            input: payload
          }
        });
      }
    } catch (error) {
      setErrorMessage(`There was a problem creating this visit: ${error.message}`);
    }
  };

  useEffect(() => {
    onCreateVisitService({
      validate: async () => {
        if (!validateForm.current) {
          return false;
        }
        return validateForm.current();
      },
      submit: async job => {
        return handleSubmit(job);
      }
    });
  }, []);

  return (
    <Box m={-0.5}>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        flexGrow={1}
        justifyContent="space-between"
      >
        <Box>
          <Typography
            style={{ padding: '12px 0', marginLeft: 12 }}
            variant={TV.S1}
            weight={TW.BOLD}
          >
            {getTitle(locale)}
          </Typography>
        </Box>
        <Box>
          <ThemeProvider>
            <Switch
              checked={isMultiVisits}
              horizontalLabel
              label="Create Multiple Visits"
              onChange={e => setMultiVisits(e.target.checked)}
            />
          </ThemeProvider>
        </Box>
      </Box>
      <VisitForm
        availableForms={availableForms}
        companyTimezone={companyTimezone}
        crews={crews}
        crewTimeTracking={crewTimeTracking}
        departments={departments}
        disableAssetSelection={disableAssetSelection}
        disableSchedule={false}
        errorMessage={errorMessage}
        isMultiVisits={isMultiVisits}
        locale={locale}
        preferredTechnicians={
          visitFormData.current?.preferredTechnicians || { extraTechsNumber: 0 }
        }
        propertyAssets={propertyAssets.filter(a => a.isActive)}
        registerValidateCallback={callback => {
          validateForm.current = callback;
        }}
        techs={techs}
        tentativeDateAndTime={{}}
        visit={visitFormData.current}
        onFormChange={handleFormChange}
      />
    </Box>
  );
};

VisitCreateInline.propTypes = {
  visitFormData: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  techs: PropTypes.array.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  customerPropertyId: PropTypes.string.isRequired,
  disableAssetSelection: PropTypes.bool.isRequired,
  onCreateVisitService: PropTypes.func.isRequired
};

const mapDispatcherToProps = dispatch => ({
  showSnackbar: (mode, message) => dispatch(snackbarOn(mode, message))
});

const ReduxConnectedVisitCreateInline = connect(null, mapDispatcherToProps)(VisitCreateInline);

export default ReduxConnectedVisitCreateInline;
