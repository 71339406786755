import React, { useMemo, useState } from 'react';

import { calculateMarginFromMarkup } from '@BuildHero/math';
import { Button, Modal, SgtForm, ThemeProvider } from '@BuildHero/sergeant';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';

import { AddRecordButton, ProductWithPricingSearch, ResponsiveTable } from 'components';
import useJobCostingOptions from 'customHooks/useJobCostingOptions';
import InventoryLayout from 'meta/Jobs/Invoice/InventoryForm';
import invoiceLabels from 'meta/Jobs/Invoice/labels';
import { inventoryItemsRows } from 'meta/Jobs/Invoice/review-report-tables';
import { dataTypeToTitle } from 'scenes/Invoices/InvoiceDetail/InvoiceDetail.constants';
import { toTitleCase } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';
import { InvoiceItemType, PricingStrategy } from 'utils/constants';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import { convertForMathLib } from 'utils/mathLibrary';
import { getMarkupFromMargin } from 'utils/onCalcChange';

import { useSetStopLoading } from '../ReviewReport/ReviewReport.hook';

import RowActions from './ReviewReportRowActions';

const WrappedSgtForm = React.memo(SgtForm);

export const InventoryItemFormModal = ({
  availableTrucks,
  initialValues,
  loading,
  open,
  priceBookId,
  sourceOptions,
  title,
  onClose,
  onSubmit,
  departmentOptions,
  jobReportDepartment,
  setJobReportDepartment,
  visitDepartments
}) => {
  const [formService, setFormService] = useState();
  const truckOptions = useMemo(() => constructSelectOptions(availableTrucks), [availableTrucks]);
  const isMarginEnabled = useSelector(s => s.settings.pricingStrategy === PricingStrategy.MARGIN);

  const {
    costCodeOptions,
    jobCostTypeOptions: costTypeOptions,
    revenueTypeOptions
  } = useJobCostingOptions();

  return (
    <ThemeProvider>
      <Modal
        actions={
          <Button fullWidth loading={loading} onClick={formService?.formikContext?.handleSubmit}>
            Save
          </Button>
        }
        maxWidth={false}
        open={open}
        title={title}
        onClose={onClose}
      >
        <WrappedSgtForm
          configuration={InventoryLayout({
            priceBookId,
            costCodeOptions,
            costTypeOptions,
            revenueTypeOptions,
            truckOptions,
            sourceOptions,
            departmentOptions,
            jobReportDepartment,
            setJobReportDepartment,
            visitDepartments,
            isMarginEnabled
          })}
          customComponents={{ ProductWithPricingSearch }}
          formikProps={{
            validateOnChange: false,
            validateOnBlur: true
          }}
          initialValues={initialValues}
          onCreateService={setFormService}
          onSubmit={onSubmit}
        />
      </Modal>
    </ThemeProvider>
  );
};

const PartsAndMaterials = ({
  classes,
  current: { context, value },
  send,
  user,
  service,
  isReviewReport
}) => {
  const [loading, setLoading] = useState();
  const setStopLoading = useSetStopLoading(service);
  const isMarginEnabled = useSelector(s => s.settings.pricingStrategy === PricingStrategy.MARGIN);

  const inventoryItems = context.inventoryParts || [];
  const formattedInventoryItems = inventoryItems.map(item => ({
    ...item,
    marginValue: convertForMathLib(calculateMarginFromMarkup, item.markupValue)
  }));
  const isEditable = isReviewReport && !context.freezeEdit;

  const inventoryMeta = useMemo(
    () =>
      [
        ...inventoryItemsRows(isMarginEnabled),
        isReviewReport && {
          id: 'actions',
          isCustom: true,
          type: 'actions',
          label: '',
          cellStyle: {
            float: 'right'
          }
        }
      ].filter(Boolean),
    [isReviewReport]
  );

  const InventoryActions = ({ record }) => {
    return (
      <RowActions
        checkboxAction={event =>
          send('INCLUDE_INVENTORY_INVOICE', {
            data: record,
            checked: event.target.checked
          })
        }
        deleteAction={() => send('DELETE_INVENTORY', { data: record })}
        editAction={() => send('EDIT_INVENTORY', { data: record })}
        isEditable={isEditable}
        record={record}
      />
    );
  };

  return (
    <>
      <Typography className={classes.sectionTitle}>
        {invoiceLabels.partsAndMaterials[user.locale]}
      </Typography>
      <Divider classes={{ root: classes.blueDivider }} variant="fullWidth" />
      <Typography className={classes.greySectionTitle} style={{ marginTop: 25 }}>
        {invoiceLabels.inventoryItems[user.locale]}
      </Typography>
      {isEditable && <AddRecordButton handle={() => send('ADD_INVENTORY')} label="+ Add item" />}
      <ResponsiveTable
        caslKey={
          isReviewReport
            ? PermissionConstants.OBJECT_REVIEWREPORT
            : PermissionConstants.OBJECT_VISIT
        }
        customCellComponents={{
          actions: InventoryActions,
          NestedProperty: ({ record, meta }) => {
            const { id, dataKey } = meta;
            const data = record[id] || {};
            return data[dataKey] || '-';
          }
        }}
        data={formattedInventoryItems}
        disableFilter
        disablePagination
        noDataMsg="No items"
        noMaxHeight
        rowMetadata={inventoryMeta}
      />
      <InventoryItemFormModal
        availableTrucks={context.availableTrucks}
        initialValues={context.modalRecord}
        loading={loading}
        open={value?.inventory_new || value?.inventory_edited}
        priceBookId={context.reviewReportPriceBookId}
        productFilter={context.productFilter}
        title={toTitleCase(
          `${value?.inventory_new ? 'Add' : 'Edit'} ${
            dataTypeToTitle[InvoiceItemType.INVENTORY_PART]
          }`
        )}
        onClose={() => send('BACK_TO_LOADED')}
        onSubmit={values => {
          const formattedValues = {
            ...values,
            markupValue: isMarginEnabled
              ? getMarkupFromMargin(values.marginValue)
              : values.markupValue
          };
          setLoading(true);
          setStopLoading(() => setLoading(false));
          send('SAVE', { saveData: formattedValues });
        }}
      />
    </>
  );
};

export default PartsAndMaterials;
