import AmplifyService from 'services/AmplifyService';

import addNonInventoryProduct from '../../graphql/quickbookIntegration/mutations/addNonInventoryProduct';
import addTaxRate from '../../graphql/quickbookIntegration/mutations/addTaxRate';
import syncClasses from '../../graphql/quickbookIntegration/mutations/SyncClasses';
import syncJobCostCodes from '../../graphql/quickbookIntegration/mutations/syncJobCostCodes';
import syncLedgerAccounts from '../../graphql/quickbookIntegration/mutations/SyncLedgerAccounts';
import syncPaymentTypes from '../../graphql/quickbookIntegration/mutations/syncPaymentTypes';
import syncSageJobs from '../../graphql/quickbookIntegration/mutations/SyncSageJobs';
import syncTaxRates from '../../graphql/quickbookIntegration/mutations/SyncTaxRates';
import updateTaxRate from '../../graphql/quickbookIntegration/mutations/updateTaxRate';
import getClasses from '../../graphql/quickbookIntegration/queries/getClasses';
import getGLAccounts from '../../graphql/quickbookIntegration/queries/getGLAccounts';
import getGLAccountsByType from '../../graphql/quickbookIntegration/queries/getGLAccountsByType';
import getProducts from '../../graphql/quickbookIntegration/queries/getProducts';
import getSageJobs from '../../graphql/quickbookIntegration/queries/getSageJobs';
import getSyncLogs from '../../graphql/quickbookIntegration/queries/getSyncLogs';
import getTaxRates from '../../graphql/quickbookIntegration/queries/getTaxRates';
import getUnitOfMeasures from '../../graphql/quickbookIntegration/queries/getUnitOfMeasures';

export default class QuickbooksService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
  }

  getGLAccounts = async (partitionKey, sortKey, startDate, endDate, nextToken) => {
    const params = {
      partitionKey,
      sortKey,
      nextToken
    };
    const response = await this.api.query(getGLAccounts, params);
    return response;
  };

  getGLAccountsByType = async (partitionKey, sortKey, startDate, endDate, nextToken) => {
    const params = {
      partitionKey,
      sortKey,
      nextToken
    };

    const response = await this.api.query(getGLAccountsByType, params);
    return response;
  };

  getTaxRates = async (partitionKey, sortKey, startDate, endDate, nextToken) => {
    const params = {
      partitionKey,
      sortKey,
      nextToken
    };

    const response = await this.api.query(getTaxRates, params);
    return response;
  };

  getClasses = async (partitionKey, sortKey, startDate, endDate, nextToken) => {
    const params = {
      partitionKey,
      sortKey,
      nextToken
    };

    const response = await this.api.query(getClasses, params);
    return response;
  };

  getProducts = async (partitionKey, sortKey, startDate, endDate, nextToken) => {
    const params = {
      partitionKey,
      sortKey,
      nextToken
    };

    const response = await this.api.query(getProducts, params);
    return response;
  };

  getSageJobs = async (partitionKey, sortKey, startDate, endDate, nextToken) => {
    const params = {
      partitionKey,
      sortKey,
      nextToken
    };

    const response = await this.api.query(getSageJobs, params);
    return response;
  };

  getUnitOfMeasures = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getUnitOfMeasures, params);
    return response;
  };

  addNonInventoryProduct = async (partitionKey, values) => {
    const dataSet = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(addNonInventoryProduct, dataSet);
    return response;
  };

  syncLedgerAccounts = async (partitionKey, tenantCompanyId) => {
    const dataSet = {
      partitionKey,
      data: {
        tenantCompanyId
      }
    };

    const response = await this.api.mutate(syncLedgerAccounts, dataSet);
    return response;
  };

  syncJobCostCodes = async (partitionKey, tenantCompanyId) => {
    const dataSet = {
      partitionKey,
      data: {
        tenantCompanyId
      }
    };

    const response = await this.api.mutate(syncJobCostCodes, dataSet);
    return response;
  };

  syncTaxRates = async (partitionKey, tenantCompanyId) => {
    const dataSet = {
      partitionKey,
      data: {
        tenantCompanyId
      }
    };

    const response = await this.api.mutate(syncTaxRates, dataSet);
    return response;
  };

  syncPaymentTypes = async (partitionKey, tenantCompanyId) => {
    const dataSet = {
      partitionKey,
      data: {
        tenantCompanyId
      }
    };

    const response = await this.api.mutate(syncPaymentTypes, dataSet);
    return response;
  };

  syncClasses = async (partitionKey, tenantCompanyId) => {
    const dataSet = {
      partitionKey,
      data: {
        tenantCompanyId
      }
    };

    const response = await this.api.mutate(syncClasses, dataSet);
    return response;
  };

  syncSageJobs = async (partitionKey, tenantCompanyId) => {
    const dataSet = {
      partitionKey,
      data: {
        tenantCompanyId
      }
    };

    const response = await this.api.mutate(syncSageJobs, dataSet);
    return response;
  };

  getSyncLogs = async (partitionKey, sortKey, startTime, endTime, nextToken) => {
    const params = {
      partitionKey,
      sortKey,
      startTime,
      endTime,
      nextToken
    };

    const response = await this.api.query(getSyncLogs, params);
    return response;
  };

  addTaxRate = async (partitionKey, values) => {
    const dataSet = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(addTaxRate, dataSet);
    return response;
  };

  updateTaxRate = async (partitionKey, values) => {
    const dataSet = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(updateTaxRate, dataSet);
    return response;
  };
}
