const groupData = ({ rows, columns, groupField, depth, groupHierarchy }) => {
  const isGrouped = Boolean(rows?.[0]?.__groupField);
  const groupColDef = columns.find(colDef => colDef.field === groupField);

  if (isGrouped) {
    return rows.map(row => ({
      ...row,
      __groupData: groupData({
        rows: row.__groupData,
        columns,
        groupField,
        depth,
        groupHierarchy: groupHierarchy
          ? `${groupHierarchy} > ${rows[0].__groupName}`
          : rows[0].__groupName
      })
    }));
  }

  const groupMap = rows.reduce(
    (acc, row) => ({
      ...acc,
      [row[groupField]]: [...(acc[row[groupField]] || []), row]
    }),
    {}
  );

  const groupedTableData = Object.entries(groupMap).map(([groupValue, rows]) => ({
    id: groupValue,
    __groupValue: groupValue,
    __groupData: rows,
    __groupField: groupField,
    __groupDepth: depth,
    __groupName: groupColDef.headerName,
    __groupHierarchy: groupHierarchy
      ? `${groupHierarchy} > ${groupColDef.headerName}`
      : rows[0].__groupName,
    ...columns.reduce(
      (acc, colDef) => {
        if (colDef.totalGetter) {
          return { ...acc, [colDef.field]: colDef.totalGetter({ rows, colDef }) };
        }
        return acc;
      },
      { [groupField]: groupColDef.valueGetter?.({ row: rows[0] }) || groupValue }
    )
  }));

  return groupedTableData;
};

export const groupTableData = ({ rows, rowGroupingModel, columns }) => {
  return rowGroupingModel.reduce((acc, groupField, depth) => {
    return groupData({ rows: acc, columns, groupField, depth });
  }, rows);
};
