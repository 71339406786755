import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';

import { withTheme } from '@material-ui/styles';

import Modal from '../index';

class DiscardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinnerStatus: false
    };
  }

  confirmDialogButtons = {
    save: {
      label: 'Confirm',
      style: {
        backgroundColor: this.props.theme.palette.brand.logoBlue,
        color: this.props.theme.palette.grayscale(100)
      },
      buttonType: 'contained',
      behavior: {
        new: true,
        edit: true
      }
    },
    cancel: {
      label: 'Cancel',
      color: 'secondary',
      buttonType: 'outlined',
      behavior: {
        new: true,
        edit: true
      }
    }
  };

  componentWillUnmount = () => {
    this.confirmDialogButtons.save.style = {
      backgroundColor: this.props.theme.palette.other.scarletRed,
      color: this.props.theme.palette.grayscale(100)
    };
    this.setState(prevState => ({ ...prevState, spinnerStatus: false }));
  };

  saveAction = async btn => {
    const localBtn = btn;
    localBtn.style = {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      color: this.props.theme.palette.grayscale(100)
    };
    this.setState(prevState => ({ ...prevState, spinnerStatus: true }));
    if (this.props.confirm) await this.props.confirm();
    this.setState(prevState => ({ ...prevState, spinnerStatus: false }));
    this.confirmDialogButtons.save.disabled = false;
    this.confirmDialogButtons.save.style = {
      backgroundColor: this.props.theme.palette.other.scarletRed,
      color: this.props.theme.palette.grayscale(100)
    };
  };

  cancelAction = () => {
    this.confirmDialogButtons.save.disabled = false;
    this.confirmDialogButtons.save.style = {
      backgroundColor: this.props.theme.palette.other.scarletRed,
      color: this.props.theme.palette.grayscale(100)
    };
    this.setState(prevState => ({ ...prevState, spinnerStatus: false }));
    this.props.cancel();
  };

  render() {
    const message = this.props.override
      ? this.props.message
      : `You have unsaved data, Are you sure you want to leave?`;

    this.confirmDialogButtons.save.action = async () =>
      this.saveAction(this.confirmDialogButtons.save);
    this.confirmDialogButtons.cancel.action = () => this.cancelAction();
    return (
      <Modal
        buttons={this.confirmDialogButtons}
        handleClose={this.props.cancel}
        keepMounted
        open={this.props.open}
        spinnerStatus={this.state.spinnerStatus}
        width={696}
      >
        <Typography style={{ whiteSpace: 'pre-line' }} variant="subtitle1">
          {message}
        </Typography>
      </Modal>
    );
  }
}

export default withTheme(DiscardModal);
