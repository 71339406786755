import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { getState } from 'redux/store';
import mergeDeep from 'utils/mergeDeep';

import { BillLineItemStatus } from '../../../../utils/AppConstants';
import { patchArray } from '../../../../utils/patchArray';
import { billLineFragment, reviewReportBillLinesFragment } from '../queries/queryFragment';
import watchedQueries from '../queries/watchedQueries';

const SAVE_REVIEW_REPORT_BILL_LINES = gql`
  mutation saveReviewReportBillItems(
    $partitionKey: String
    $items: [SaveBillItemToReviewReportInput]
  ) {
    saveReviewReportBillItems(partitionKey: $partitionKey, items: $items) {
      id
      costCodeId
      jobCostTypeId
      revenueTypeId
      description
      reviewReportId
      billLineId
      quantity
      unitCost
      taxable
      unitPrice
      markup
      ${billLineFragment}
      reviewReport {
        id
        ${reviewReportBillLinesFragment}
      }
    }
  }
`;

export const useSaveReviewReportBillLines = (reviewReportId, options = {}) => {
  return useExtendedMutation(SAVE_REVIEW_REPORT_BILL_LINES, {
    serializer: ({ billItemsToAdd = [] }) => ({
      variables: {
        partitionKey: getState()?.company?.partitionKey,
        items: billItemsToAdd
      }
    }),
    update: (cache, { data }) => {
      const cachedReviewReport = cache.readQuery(watchedQueries.GET_REVIEW_REPORT_BY_ID);

      cache.writeQuery({
        ...watchedQueries.GET_REVIEW_REPORT_BY_ID,
        data: mergeDeep(cachedReviewReport, {
          getReviewReportById: {
            reviewReportBillItems:
              data.saveReviewReportBillItems[0].reviewReport.reviewReportBillItems
          }
        })
      });

      const cachedBillLines = cache.readQuery(watchedQueries.GET_REVIEW_REPORT_BILL_LINES);

      const selectBillLineUpdate = billItem => ({
        id: billItem.billLineId,
        remainingQuantity: billItem.billLine.remainingQuantity,
        quantity: billItem.quantity,
        invoicedStatus:
          billItem.billLine.remainingQuantity === 0 ? BillLineItemStatus.INVOICED : undefined
      });

      const update = patchArray(
        cachedBillLines.getBillLineListForReviewReport.items,
        data.saveReviewReportBillItems.map(selectBillLineUpdate)
      );

      cache.writeQuery({
        ...watchedQueries.GET_REVIEW_REPORT_BILL_LINES,
        data: mergeDeep(cachedBillLines, {
          getBillLineListForReviewReport: {
            items: update
          }
        })
      });
    },
    ...options
  });
};
