import React, { useState } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { XGrid } from 'components';
import { GET_INVOICES } from 'scenes/Invoices/InvoiceList';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { useInvoiceListColumns } from './InvoiceList.column';
import { useStyles } from './InvoiceList.styles';

const InvoiceList = ({ projectId, refetchTrigger }) => {
  const styles = useStyles();
  const flags = useFlags();
  const [pageSize, setPageSize] = useState(5);

  const isAdjustmentEnabled = flags[FeatureFlags.ADJUSTMENTS];
  const columns = useInvoiceListColumns({ isAdjustmentEnabled });

  return (
    <div css={styles.root}>
      <XGrid
        columns={columns}
        disableToolbar
        entityTypeName="Invoices"
        pageSize={pageSize}
        query={GET_INVOICES}
        refetchTrigger={refetchTrigger}
        rowHeight={40}
        rowsPerPageOptions={[5, 10, 20]}
        silentFilter={[{ columnField: 'projectId', operatorValue: 'equals', value: projectId }]}
        tableName="ProjectInvoicesXGrid"
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      />
    </div>
  );
};

InvoiceList.propTypes = {
  projectId: PropTypes.string.isRequired,
  refetchTrigger: PropTypes.bool.isRequired
};

export default InvoiceList;
