import React, { useEffect, useRef, useState } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

import { DefaultButton, FullScreenModal, Placeholder } from 'components';
import { UserPermission } from 'components/AppPermissions';
import AlgoliaSearchWrapper from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import { VisitCreateInline } from 'components/Visit';
import { MIXPANEL_EVENT, MIXPANEL_PAGE_NAME } from 'constants/mixpanelEvents';
import useServiceAgreementsSettings from 'customHooks/useServiceAgreementsSettings';
import { CustomerRepLayout } from 'meta/Customer/CustomerRep/layout';
import { NewJobLayout } from 'meta/Jobs/NewJobLayout';
import { snackbarOn } from 'redux/actions/globalActions';
import CustomerRep, { RepType } from 'scenes/Customer/CustomerRepModal';
import ErrorBoundaries from 'scenes/Error';
import { JobService, validations } from 'services/core';
import { Logger } from 'services/Logger';
import { sendMixpanelEvent } from 'services/mixpanel';

import { checkPermission, isTenantSettingEnabled } from 'utils';
import { FeatureGateConstants, PermissionConstants, PriorityStatus } from 'utils/AppConstants';
import { AccountingApp } from 'utils/constants';
import customJobNumberEvaluator from 'utils/evaluator';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import getSageJobs from 'utils/getSageJobs';

import {
  checkCustomJobNumberExists,
  getCombinedOurReps,
  getCustomFormPayload,
  getFormattedData,
  getServiceAgreementsOptionsForProperty,
  isTechnician,
  performQuery,
  queryAndSetCustomFormFields
} from './helpers';

const onPageLoad = async ({
  setJobNumber,
  setSageJobs,
  setIsSageEnabled,
  user,
  history,
  customerId,
  ...props
}) => {
  const jobService = new JobService();
  const { data, error } = await jobService.getCurrentCounterJob();
  if (data) {
    const result = data.getCurrentCounterWithoutIncrement;
    const oldJobNumber = JSON.parse(result).job;
    setJobNumber(oldJobNumber + 1);
  }
  if (error) {
    props.snackbarOn('error', 'Unable to create job, please try again later', error);
  }

  const accountingIntegration = isTenantSettingEnabled('accountingApp');
  if (accountingIntegration === AccountingApp.SAGE) {
    try {
      const sJobs = await getSageJobs(user);
      setSageJobs(sJobs.map(job => ({ ...job, label: job.code, value: job.id })));
      setIsSageEnabled(true);
    } catch (e) {
      Logger.error(e);
    }
  }

  const { customerSortKey } = history.location.state || {};
  if (customerSortKey) {
    queryAndSetCustomFormFields({
      user,
      ...props
    });
    await performQuery({
      recordSortKey: customerSortKey,
      user,
      ...props
    });
  } else {
    history.push('/job/list');
  }
};

const NewJob = ({ user, history, ...props }) => {
  const launchDarklyFlags = useFlags();
  const hasServiceAgreements = checkPermission(
    'read',
    PermissionConstants.OBJECT_SERVICE_AGREEMENT,
    user,
    null,
    null,
    FeatureFlags.SERVICE_AGREEMENTS,
    launchDarklyFlags
  );

  const hasMultiplePricebooks = checkPermission(
    'create',
    PermissionConstants.OBJECT_JOB, // Intentionally making it job as we need to check only feature gate. There is no perm for Pricebook
    user,
    null,
    FeatureGateConstants.MULTIPLE_PRICE_BOOKS
  );

  const serviceAgreementsSettings = useServiceAgreementsSettings(user);

  const [openCustomerRep, setOpenCustomerRep] = useState(false);
  const [departments, setDepartments] = useState('');
  const [jobTypes, setJobTypes] = useState([]);
  const [properties, setProperties] = useState('');
  const [jobNumber, setJobNumber] = useState('');
  const [customerSortKey, setCustomerSortKey] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [billingCustomerName, setBillingCustomerName] = useState('');
  const [customExpressionSetting, setCustomExpressionSetting] = useState({});
  const [customJobNumber, setCustomJobNumber] = useState('');
  const [newRepName, setNewRepName] = useState('');
  const [fieldName, setFieldName] = useState('');
  const [customerPropertyId, setCustomerPropertyId] = useState(
    history.location.state.propertyId || ''
  );
  const [formValues, setFormValues] = useState({});
  const [createFirstVisit, setCreateFirstVisit] = useState(false);
  const [visitService, setVisitService] = useState('');
  const [sageJobs, setSageJobs] = useState([]);
  const [isSageEnabled, setIsSageEnabled] = useState(false);
  const [formAttributes, setFormAttributes] = useState([]);
  const [customInlineForm, setCustomInlineForm] = useState();
  const [syncNow, setSyncNow] = useState(false);
  const [defaultPriceBookId, setDefaultPriceBookId] = useState('');
  const [priceBooks, setPriceBooks] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [salesEmployees, setSalesEmployees] = useState([]);
  const [isCustomJobNumberEnabled, setIsCustomJobNumberEnabled] = useState(false);
  const [isQuickbookEnabled, setIsQuickbookEnabled] = useState(false);
  const [isJobTypeCounterEnabled, setIsJobTypeCounterEnabled] = useState(false);
  const [customerReps, setCustomerReps] = useState([]);
  const [customerTenantReps, setCustomerTenantReps] = useState([]);
  const [ourReps, setOurReps] = useState([]);
  const [customerId, setCustomerId] = useState();
  const [billingCustomerId, setBillingCustomerId] = useState();
  const [defaultJobTypeJobCounter, setDefaultJobTypeJobCounter] = useState();
  const [accelerateVisitProcess, setAccelerateVisitProcess] = useState();
  const [selectedRepMap, setSelectedRepMap] = useState({});
  const [modalMode, setModalMode] = useState('');
  const [formService, setFormService] = useState(() => console.log('no form service set'));
  const [newCustomerId, setNewCustomerId] = useState();
  const [newBillingCustomerId, setNewBillingCustomerId] = useState();
  const [differentBillingCustomer, setDifferentBillingCustomer] = useState(false);
  const [billingCustomerFromProperty, setBillingCustomerFromProperty] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [serviceAgreements, setServiceAgreements] = useState([]);
  const [serviceAgreementsForProperty, setServiceAgreementsForProperty] = useState([]);
  const [newJobTypeId, setNewJobTypeId] = useState();
  const [showTotalBudgetedHours, setShowTotalBudgetedHours] = useState(false);
  const [defaultPriceBookMsg, setDefaultPriceBookMsg] = useState('');
  const [priceBookChanged, setPriceBookChanged] = useState(false);
  const [currPriceBookId, setCurrPriceBookId] = useState('');
  const [customerPriceBookId, setCustomerPriceBookId] = useState('');

  const visitFormDataRef = useRef({});
  const oldCustomerId = useRef(null);
  const oldBillingCustomerId = useRef(null);

  const forceCertifiedPayrollJobs = isTenantSettingEnabled('forceCertifiedPayrollJobs');
  const showCertifiedPayroll = isTenantSettingEnabled('certifiedPayrollJobs');

  const setState = newState => {
    setCustomJobNumber(newState.isCustomJobNumberEnabled);
    setSyncNow(newState.sync);
    setDepartments(newState.departments.items.map(d => ({ ...d, label: d.tagName, value: d.id })));
    setDefaultPriceBookId(newState.defaultPriceBookId);
    setPriceBooks(newState.priceBooks.map(pb => ({ ...pb, label: pb.name, value: pb.id })));
    setJobTypes(newState.sortedJobTypes.map(jt => ({ ...jt, label: jt.tagName, value: jt.id })));
    setEmployees(newState.employees);
    setSalesEmployees(newState.salesEmployees.map(se => ({ ...se, label: se.name, value: se.id })));
    setIsCustomJobNumberEnabled(newState.isCustomJobNumberEnabled);
    setIsQuickbookEnabled(newState.isQuickbookEnabled);
    setCustomExpressionSetting(newState.customExpressionSetting);
    setIsJobTypeCounterEnabled(newState.isJobTypeCounterEnabled);
    setShowTotalBudgetedHours(newState.showTotalBudgetedHours);
    setProperties(
      newState.tempProperties.map(p => ({
        ...p,
        label: p.companyName,
        value: p.id,
        customerReps: p.processedCustomerReps || []
      }))
    );
    const defaultCustomerPropertyId = newState.tempProperties?.[0]?.id;
    if (!customerPropertyId) setCustomerPropertyId(defaultCustomerPropertyId);
    setCustomerReps(newState.customerReps.map(cr => ({ ...cr, label: cr.name, value: cr.id })));
    setCustomerTenantReps(newState.customerTenantReps);
    setOurReps(
      getCombinedOurReps(
        newState.customerTenantReps,
        newState.tempProperties,
        customerPropertyId || defaultCustomerPropertyId
      )
    );
    setCustomerSortKey(newState.customerSortKey);
    setCustomerName(newState.customerName);
    setCustomerId(newState.customerId);
    setBillingCustomerId(newState.billingCustomerId);
    setBillingCustomerName(newState.billingCustomerName);
    setDefaultJobTypeJobCounter(newState.defaultJobTypeJobCounter);
    setAccelerateVisitProcess(newState.accelerateVisitProcess);
    setServiceAgreements(newState.serviceAgreements || []);
    setCustomerPriceBookId(newState.customerPriceBookId);
    if (fieldName) {
      setSelectedRepMap(newState.selectedRepMap);
    }
  };

  const handleOnCompleteJob = async dataOrig => {
    setSubmitting(true);

    const data = { ...dataOrig };
    if (!data.billingCustomerId) {
      data.billingCustomerId = billingCustomerId;
    }
    if (createFirstVisit && visitService) {
      const isVisitInputValid = await visitService.validate();

      if (!isVisitInputValid) {
        setSubmitting(false);
        return;
      }
    }

    if (customJobNumber && data.customIdentifier && !customExpressionSetting) {
      const haveErr = await checkCustomJobNumberExists(data.customIdentifier);
      if (haveErr) {
        setSubmitting(false);
        props.snackbarOn('error', 'Job number is already assigned');
        return;
      }
    }

    try {
      // if customer field change its required field for update job
      if (!data.customerId) {
        data.customerId = customerId;
      }

      const currentCustomerProperties = data.properties || properties;

      setCustomerSortKey(data.customerSortKey);
      setProperties(currentCustomerProperties);

      const customFormData = formAttributes ? getCustomFormPayload(formAttributes, data) : null;
      data.formData = customFormData;

      const jobService = new JobService();

      const response = await jobService.addJobsToCustomerProperty(user.tenantId, data, {
        properties,
        jobTypes,
        customerId,
        customerSortKey,
        formAttributes,
        departments,
        syncNow,
        customJobNumber
      });

      const jobData = response?.data?.addJobsToCustomerProperty?.[0];
      if (jobData?.id) {
        const { data: completeJobData } = await jobService.getJobDetailsInfoByJobNumber(
          `${jobData.jobNumber}`
        );
        if (createFirstVisit && visitService) {
          await visitService.submit(completeJobData.getJobByJobNumber);
        }
        sendMixpanelEvent(MIXPANEL_EVENT.CREATED_JOB, MIXPANEL_PAGE_NAME.JOBS);
        history.push(`/job/view/${encodeURIComponent(jobData.jobNumber)}`, {
          recordSortKey: jobData.sortKey,
          customerSortKey
        });
      }
    } catch (error) {
      Logger.error(error);
      setSubmitting(false);
      props.snackbarOn('error', 'Unable to save job', error);
    }
    return data;
  };

  const handleOpenCustomerRepPopUp = ({ field, value, form }) => {
    const fName = field.name;
    const newFormValues = form.values;
    if (
      (fName === 'customerRepId' && newFormValues.customerPropertyId) ||
      fName === 'authorizedById'
    ) {
      setOpenCustomerRep(true);
      setModalMode('new');
      setNewRepName(value);
      setFieldName(fName);
      setFormValues(newFormValues);
      setCustomerPropertyId(newFormValues.customerPropertyId);
      setCustomerName(newFormValues.customerName);
      setCustomerSortKey(newFormValues.customerSortKey || customerSortKey);
    } else {
      props.snackbarOn('error', 'Please select a property first', '');
    }
  };

  const handleClosePopUp = async data => {
    setOpenCustomerRep(false);

    if (data) {
      const { addCustomerRepToCustomer } = data;

      await performQuery({
        recordSortKey: customerSortKey,
        selectedRep: addCustomerRepToCustomer ? addCustomerRepToCustomer[0].id : '',
        user,
        defaultJobTypeJobCounter,
        setState,
        selectedRepMap,
        fieldName,
        snackbarOn: props.snackbarOn,
        hasServiceAgreements
      });
    }
  };

  const refetch = async ({ refetchCustomer, refetchBillingCustomer }) => {
    const effectiveCustomerId = refetchCustomer || customerId;
    let effectiveBillingCustomerId =
      refetchCustomer ?? (refetchBillingCustomer || billingCustomerId);
    if (effectiveCustomerId === effectiveBillingCustomerId) {
      effectiveBillingCustomerId = undefined;
    }

    const recordSortKey = `${user.tenantId}_${user.tenantCompanyId}_Customer_${effectiveCustomerId}`;
    await performQuery({
      recordSortKey,
      user,
      defaultJobTypeJobCounter,
      setState,
      selectedRepMap,
      fieldName,
      snackbarOn: props.snackbarOn,
      hasServiceAgreements,
      billingCustomerId: effectiveBillingCustomerId
    });

    if (refetchCustomer) {
      oldCustomerId.current = null;
      setNewCustomerId(null);
    }
    if (refetchBillingCustomer) {
      oldBillingCustomerId.current = null;
      setNewBillingCustomerId(null);
    }
  };

  const setPriceBookIdFromSA = (selectedOption, form) => {
    const { setValue } = form.getFieldHelpers('priceBookId');
    setValue(selectedOption?.pricebookId || null);
  };

  const getLayoutMeta = (data, showDefaultPricebookMsg) => {
    const layoutMeta = NewJobLayout({
      properties,
      customerReps,
      ourReps,
      differentBillingCustomer,
      billingCustomerFromProperty,
      priceBooks,
      jobTypes,
      customerPropertyId,
      departments,
      salesEmployees,
      employeeOptions: employees.items.map(emp => ({ ...emp, label: emp.name, value: emp.id })),
      priorities: Object.keys(PriorityStatus).map(ps => ({
        value: ps,
        label: PriorityStatus[ps]
      })),
      handleOpenCustomerRepPopUp,
      accelerateVisitProcess,
      isSageEnabled,
      isSpectrumEnabled: props.settings.accountingApp === AccountingApp.SPECTRUM,
      isVistaEnabled: props.settings.accountingApp === AccountingApp.VISTA,
      hasMultiplePricebooks,
      sageJobs,
      hasServiceAgreements,
      serviceAgreementsForProperty,
      customJobNumber,
      disableCustomJobNumber: data.disableCustomJobNumber,
      customInlineForm,
      showTotalBudgetedHours,
      setPriceBookIdFromSA,
      user,
      showCertifiedPayroll,
      forceCertifiedPayrollJobs,
      showDefaultPricebookMsg
    });

    // @TODO figure out what this is all about
    // if (isSageEnabled && layoutMeta.sections[0].rows[1].cols.length === 8) {
    //   const newCols = arrayInsert(layoutMeta.sections[0].rows[1].cols, 9, sageField);
    //   layoutMeta.sections[0].rows[1].cols = newCols;
    // }

    return layoutMeta;
  };

  useEffect(() => {
    onPageLoad({
      setJobNumber,
      setSageJobs,
      setIsSageEnabled,
      user,
      history,
      setFormAttributes,
      setCustomInlineForm,
      defaultJobTypeJobCounter,
      setState,
      selectedRepMap,
      fieldName,
      snackbarOn: props.snackbarOn,
      hasServiceAgreements,
      customerId
    });
  }, []);

  useEffect(() => {
    if (newJobTypeId && customJobNumber && customExpressionSetting) {
      const jobTypeName = jobTypes.find(j => j.id === newJobTypeId).tagName;

      let customIdentifier;
      try {
        const customJobNumberExpression =
          customExpressionSetting && typeof customExpressionSetting === 'string'
            ? JSON.parse(customExpressionSetting)
            : customExpressionSetting || '';
        customIdentifier =
          customJobNumberEvaluator(customJobNumberExpression, {
            jobNumber: parseInt(jobNumber, 10),
            jobType: jobTypeName
          }) || jobNumber;
      } catch (e) {
        customIdentifier = jobNumber;
      }

      formService.formikContext.setFieldValue('customIdentifier', customIdentifier);

      setNewJobTypeId();
    }
  }, [newJobTypeId]);

  useEffect(() => {
    if (newCustomerId && oldCustomerId.current === newCustomerId) {
      // this is a rare/weird useEffect race condition, do nothing.
      // To see this edge case in action, uncomment the following console.log and toggle the customer field
      // console.log("If the case wasn't handled the customer would get set back to the initial customer at this point")
    } else if (customerId !== newCustomerId && newCustomerId) {
      oldCustomerId.current = customerId;
      refetch({ refetchCustomer: newCustomerId });
    }
  }, [newCustomerId]);

  useEffect(() => {
    if (newBillingCustomerId && oldBillingCustomerId.current === newBillingCustomerId) {
      // avoiding race conditions as above
    } else if (billingCustomerId !== newBillingCustomerId && newBillingCustomerId) {
      oldBillingCustomerId.current = billingCustomerId;
      refetch({ refetchBillingCustomer: newBillingCustomerId });
    }
  }, [newBillingCustomerId]);

  useEffect(() => {
    if (!differentBillingCustomer && formService) {
      const { value } = formService.formikContext.getFieldProps('customerId');
      formService.formikContext.setFieldValue('billingCustomerId', value);
    }
  }, [differentBillingCustomer]);

  useEffect(() => {
    if (billingCustomerFromProperty) {
      const currentProperty = properties.find(p => p.id === customerPropertyId);
      setNewBillingCustomerId(currentProperty?.billingCustomerId);
      setBillingCustomerName(currentProperty?.billingCustomer?.customerName);
    } else {
      setNewBillingCustomerId(customerId);
    }

    if (serviceAgreements.length) {
      setServiceAgreementsForProperty(
        getServiceAgreementsOptionsForProperty(customerPropertyId, serviceAgreements)
      );
    }
    setOurReps(getCombinedOurReps(customerTenantReps, properties, customerPropertyId));
  }, [billingCustomerFromProperty, customerPropertyId, serviceAgreements]);

  let renderPageForm = false;
  if (jobNumber !== '' && properties !== '') {
    renderPageForm = true;
  }

  const data = getFormattedData({
    history,
    customerName,
    customerId,
    billingCustomerId,
    billingCustomerName,
    customerPropertyId,
    formValues,
    customExpressionSetting,
    accountingJobNumberExpression: props.settings.custom_spectrum_job_expression,
    defaultPriceBookId,
    jobNumber,
    customJobNumber,
    properties,
    isJobTypeCounterEnabled,
    defaultJobTypeJobCounter,
    hasServiceAgreements,
    hasMultiplePricebooks,
    jobTypes,
    selectedRepMap,
    differentBillingCustomer,
    billingCustomerFromProperty,
    forceCertifiedPayrollJobs,
    serviceAgreementsForProperty,
    ourReps,
    populateSAForNewJob: serviceAgreementsSettings?.populateSAForNewJob,
    setDefaultPriceBookMsg,
    defaultPriceBookMsg,
    priceBookChanged,
    currPriceBookId,
    setPriceBookChanged,
    customerPriceBookId
  });

  const showDefaultPricebookMsg = defaultPriceBookMsg && !priceBookChanged;
  return (
    <ErrorBoundaries>
      <UserPermission action={PermissionConstants.OBJECT_JOB} I="create">
        <FullScreenModal
          fixedHeader
          handleClose={() => {
            history.goBack();
          }}
          modalHeaderButtons={
            <DefaultButton
              color="primary"
              disabled={submitting}
              handle={async () => {
                const isValid = await formService.validateForm();
                if (isEmpty(isValid)) {
                  // set sumbitting was set to avoid duplicate jobs, so have to validate explictly
                  setSubmitting(true);
                  return formService.submit();
                }
              }}
              label="save job"
              showSpinner={submitting}
            />
          }
          open
          title="New Job"
        >
          <>
            {!renderPageForm && <Placeholder repeatCount={3} variant="card" />}
            {renderPageForm && (
              <>
                <div>
                  {/* @TODO
                   * ideally remove external states and combine 3 components
                   * (MUIFORm, Reps, VisitCreateInline) in one Sergeant Form
                   */}
                  <MUIForm
                    configuration={getLayoutMeta(data, showDefaultPricebookMsg)}
                    customComponents={{
                      AlgoliaSearchWrapper
                    }}
                    data={data}
                    layout="edit"
                    onComplete={handleOnCompleteJob}
                    onCreateService={service => setFormService(service)}
                    onFieldChange={async (key, value) => {
                      if (key === 'customerId') {
                        // need to set this to trigger refetch of data for different customer
                        setNewCustomerId(value);
                      }
                      if (key === 'billingCustomerId') {
                        // need to set this to trigger refetch of data for different billing customer (reps)
                        setNewBillingCustomerId(value);
                      }
                      // @TODO - can move conditional disables into layout config when switching to Sergeant Form
                      if (key === 'differentBillingCustomer') {
                        setDifferentBillingCustomer(value);
                      }

                      if (key === 'billingCustomerFromProperty') {
                        setBillingCustomerFromProperty(value);
                      }

                      // need to set this for <Reps />
                      if (key === 'customerPropertyId') {
                        setCustomerPropertyId(value);
                      }

                      // need to set this for <VisitCreateInline />
                      if (key === 'createFirstVisit') {
                        setCreateFirstVisit(value);
                      }

                      if (key === 'jobTypeId') {
                        setNewJobTypeId(value);
                      }
                      if (key === 'priceBookId') {
                        setPriceBookChanged(true);
                        setCurrPriceBookId(value);
                      }
                    }}
                  />
                </div>
                <CustomerRep
                  data={{ firstName: newRepName }}
                  handleClose={(flag, data) => {
                    handleClosePopUp(data);
                  }}
                  layout={CustomerRepLayout}
                  mode="new"
                  open={openCustomerRep}
                  parent={{
                    id: (() => {
                      const temp = customerSortKey && customerSortKey.split('_');
                      return temp[temp.length - 1];
                    })(),
                    sortKey: customerSortKey,
                    customerName,
                    tenantId: user.tenantId,
                    tenantCompanyId: user.tenantCompanyId,
                    hierarchy: `${user.tenantId}_${user.tenantCompanyId}`,
                    entityType: 'Customer',
                    partitionKey: user.tenantId,
                    customerPropertyId: fieldName === 'customerRepId' ? customerPropertyId : ''
                  }}
                  repType={RepType.CUSTOMER}
                  validationSchema={validations.customerRepSchema}
                />
                {createFirstVisit && (
                  <VisitCreateInline
                    customerPropertyId={customerPropertyId || properties[0]?.id}
                    locale={user.locale}
                    techs={employees?.items.filter(isTechnician)}
                    user={user}
                    visitFormData={visitFormDataRef}
                    onCreateVisitService={service => {
                      setVisitService(service);
                    }}
                  />
                )}
              </>
            )}
          </>
        </FullScreenModal>
      </UserPermission>
    </ErrorBoundaries>
  );
};

const mapStateToProps = state => ({
  user: state.user,
  settings: state.settings
});

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const reduxConnectedNewJob = connect(mapStateToProps, mapDispatchToProps)(NewJob);

export default reduxConnectedNewJob;
