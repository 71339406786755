import React, { useEffect, useState } from 'react';

import { Button, ButtonType, Modal, MUIForm, ThemeProvider } from '@BuildHero/sergeant';

import { useFlags } from 'launchdarkly-react-client-sdk';
import * as R from 'ramda';
import { connect } from 'react-redux';

import { CircularProgressWithLabel } from 'components/AttachmentSection/AddAttachment/CircularProgressWithLabel';

import receiptAttachmentLayout from 'meta/Procurement/PurchaseOrders/receiptAttachmentLayout';
import { snackbarOn } from 'redux/actions/globalActions';
import { AttachmentType, ReceiptAttachmentType } from 'scenes/Procurement/constants';
import { Logger } from 'services/Logger';
import { BuildHeroThemeProvider } from 'themes/BuildHeroTheme';
import { VendorApprovalStatus } from 'utils/AppConstants';
import { Mode } from 'utils/constants';
import { removeEmptyValues } from 'utils/index';

import {
  attachmentToFileInput,
  fileInputToAttachment
} from '../../../../components/AttachmentSection/helpers';

const ReceiptAttachmentModal = props => {
  const {
    open,
    data,
    parent,
    mutationService,
    maxFileSizeWarningLabel,
    maxFileSize,
    mode,
    snackbarOn: snackbar,
    disableVendorInvoiceOption,
    receiptData
  } = props;

  let mutationFlag = false;
  const [attachment, setAttachment] = useState({});
  const [formService, setFormService] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState();
  const [cancelUpload, setCancelUpload] = useState();
  const [isVendorInvoice, setIsVendorInvoice] = useState(false);
  const flags = useFlags();

  // Initialize the attachment state
  useEffect(() => {
    if (data?.type === AttachmentType.vendorInvoice) {
      setIsVendorInvoice(true);
    }
    const fileType =
      data?.fileName?.match(/\.(jpeg|jpg|gif|png|webp)$/) != null ? 'image' : 'other';
    attachmentToFileInput(data, fileType).then(fileData => {
      setAttachment(fileData);
    });
  }, [data]);

  const prepareFormSubmission = values => {
    let nullRemovedFormData = removeEmptyValues(values);
    // TODO: fix this dirty fix properly
    if (props.sendAsJSON) {
      nullRemovedFormData = values;
    }
    // when no value is entered, return null to skip submission
    if (R.isEmpty(nullRemovedFormData)) {
      throw new Error('No values entered to save');
    }
    // adding user context values
    const valuesWithSystemInputs = {
      ...data,
      ...nullRemovedFormData,
      tenantId: props.user.tenantId,
      tenantCompanyId: props.user.tenantCompanyId,
      partitionKey: props.user.tenantId,
      lsi1: `${props.user.tenantCompanyId}_${props.entityName}`,
      entityType: 'Attachment',
      parent: props.parent
    };
    return valuesWithSystemInputs;
  };

  // Update the data record for existing attachment
  const updateFileData = ({ customFileName, comment, fileUrl, fileName, newFiles, fileSize }) => {
    return {
      ...data,
      customFileName: customFileName || '',
      comment,
      fileUrl,
      fileName: newFiles ? newFiles[0].file.name : fileName,
      parent,
      fileSize
    };
  };

  // Append name field to completeData to enable unique name generation
  const processFileData = fileData => {
    const completeData = removeEmptyValues(fileData);
    completeData.newFiles[0].file.name = attachment.fileName;
    return completeData;
  };

  const uploadProgressCallback = ({ loaded = 0, total = 1 }, public_id, cancelUploadFn) => {
    setProgress((loaded / total) * 100);
    setCancelUpload(cancelUploadFn);
  };

  const closePopUp = flag => {
    if (progress && progress < 100 && cancelUpload) {
      cancelUpload();
      setIsLoading();
    }
    props.handleClose(flag);
    setProgress();
  };

  const combineWithReceiptData = (fileData, record) => {
    return {
      ...fileData,
      approvalStatus: record?.approvalStatus,
      vendorDocumentNumber: record?.vendorDocumentNumber,
      invoiceAmount: record?.invoiceAmount
    };
  };

  // Corresponds to 3 cases: upload new attachment, edit and replace image, edit without replacing image
  const handleOnComplete = async record => {
    let fileData;
    try {
      if (!data?.id) {
        const attachmentPayload = await fileInputToAttachment(
          props.user.tenantId,
          record,
          record.receiptAttachmentType === ReceiptAttachmentType.VENDOR_INVOICE
            ? AttachmentType.vendorInvoice
            : null,
          uploadProgressCallback,
          flags
        );
        fileData = prepareFormSubmission(attachmentPayload);
      } else if (attachment.newFiles !== record.newFiles) {
        const attachmentPayload = await fileInputToAttachment(
          props.user.tenantId,
          record,
          null,
          uploadProgressCallback,
          flags
        );
        fileData = updateFileData(attachmentPayload);
      } else {
        const processedData = processFileData(record);
        fileData = updateFileData(processedData);
      }
      const finalData = combineWithReceiptData(fileData, record);
      await mutationService(finalData);
      mutationFlag = true;
      closePopUp(mutationFlag);
    } catch (error) {
      Logger.error(error);
      snackbar('error', error.message || 'Unable to upload the attachment', error);
      closePopUp(mutationFlag);
    }
    setIsLoading(false);

    return record;
  };

  const handleFormChange = formData => {
    if (formData.receiptAttachmentType === ReceiptAttachmentType.VENDOR_INVOICE) {
      return setIsVendorInvoice(true);
    }
    if (formData.receiptAttachmentType === ReceiptAttachmentType.PACKING_SLIP_OTHER) {
      setIsVendorInvoice(false);
    }
  };

  const getFormattedData = () => {
    if (data?.id || mode === Mode.EDIT) {
      return {
        ...receiptData,
        ...attachment
      };
    }
    return {
      receiptAttachmentType: ReceiptAttachmentType.PACKING_SLIP_OTHER,
      approvalStatus: receiptData?.approvalStatus || VendorApprovalStatus.UNREVIEWED,
      invoiceAmount: receiptData?.invoiceAmount,
      vendorDocumentNumber: receiptData?.vendorDocumentNumber
    };
  };

  return (
    <ThemeProvider>
      <Modal
        actions={
          <Button
            disabled={loading}
            fullWidth
            type={ButtonType.PRIMARY}
            onClick={async () => {
              const isValid = await formService.validateForm();
              if (R.isEmpty(isValid)) {
                setIsLoading(true);
                formService?.submit();
              }
            }}
          >
            {progress ? 'Saving..' : 'Save'}
            {progress && <CircularProgressWithLabel value={progress} />}
          </Button>
        }
        open={open}
        title={`${data?.id || mode === Mode.EDIT ? 'Edit' : 'Add'} Receipt Image`}
        onClose={() => closePopUp(false)}
      >
        <BuildHeroThemeProvider>
          <MUIForm
            configuration={receiptAttachmentLayout({
              maxFileSizeWarningLabel,
              maxFileSize,
              flags,
              isVendorInvoice,
              disableVendorInvoiceOption
            })}
            confirmRemoveItemLabel={attachment?.customFileName || attachment?.fileName || ''}
            data={getFormattedData()}
            layout={mode}
            open={open}
            onComplete={handleOnComplete}
            onCreateService={service => setFormService(service)}
            onFormChange={handleFormChange}
          />
        </BuildHeroThemeProvider>
      </Modal>
    </ThemeProvider>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

const mapAttachmentToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedAddAttachment = connect(
  mapStateToProps,
  mapAttachmentToProps
)(ReceiptAttachmentModal);

export default reduxConnectedAddAttachment;
