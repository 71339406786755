import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const BATCH_MUTATE_PROPERTY_TASKS = gql`
  mutation batchMutatePropertyTasks($partitionKey: String!, $data: BatchMutatePropertyTasksInput) {
    batchMutatePropertyTasks(partitionKey: $partitionKey, data: $data) {
      id
      moreInfoNeeded
    }
  }
`;

const serializer = ({ tenantId, data }) => ({
  variables: {
    partitionKey: tenantId,
    data
  }
});

export const useBatchMutatePropertyTasks = () => {
  return useExtendedMutation(BATCH_MUTATE_PROPERTY_TASKS, {
    serializer
  });
};
