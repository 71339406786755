/* eslint-disable no-restricted-syntax */
import React from 'react';

import { MUIForm, MUIFormComponents } from '@BuildHero/sergeant';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { omit } from 'lodash';

import DefaultButton from 'components/Buttons/DefaultButton';
import Context from 'components/Context';
import Spinner from 'components/Spinners/CircularIndeterminate';
import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';

import { tempFormFields } from './constants';
import { SignatureScreen } from './SignatureScreen';

class FormViewer extends React.Component {
  constructor(props) {
    super(props);
    const { allowToggle, isEditMode, formMetaData } = props;
    let layout;
    let data;
    let assetMap;
    let smartFieldReferences;

    if (Array.isArray(formMetaData)) {
      [layout, data, assetMap, smartFieldReferences] = formMetaData;
    } else {
      [layout, data] =
        typeof formMetaData === 'string' ? JSON.parse(formMetaData).meta : formMetaData.meta;
      assetMap = formMetaData.assetMap;
      smartFieldReferences = formMetaData.smartFieldReferences;
    }

    const { searchableFields } = formMetaData;
    const initialData = smartFieldReferences
      ? this.setSmartFieldData(data, smartFieldReferences)
      : data;

    this.state = {
      layout,
      data: initialData,
      assetMap,
      assets: {},
      searchableFields,
      allowToggle: allowToggle !== undefined ? allowToggle : false,
      isEditMode: isEditMode !== undefined ? isEditMode : true,
      messages: []
    };
  }

  async componentDidMount() {
    const { assetMap } = this.state;
    const fileMap = {};
    if (assetMap) {
      const retrievedAssets = await this.getAllFormAssets(assetMap);
      retrievedAssets.forEach(item => {
        fileMap[item.id] = item.itemSources;
      });
    }
    this.setState({ assets: fileMap });
  }

  onMessage(type, message) {
    this.setState(state => ({
      messages: [...state.messages, { type, message }]
    }));
  }

  submitForm = async data => {
    const { layout, assetMap, searchableFields } = this.state;
    const { onSubmit } = this.props;
    const payload = omit(data, tempFormFields);
    const [newData, newFormAssetMap] = await this.uploadAssets(payload, assetMap);
    const newFormMetaData = {
      meta: [layout, newData],
      assetMap: newFormAssetMap,
      searchableFields
    };
    if (onSubmit) onSubmit(newFormMetaData, data.tempDescription);
  };

  uploadFile = async file => {
    const { tenantId } = this.props.user;
    if (!file) return '';
    try {
      const storageService = new StorageService();
      const filename = `${tenantId}/${Date.now()}-FB-formAsset-${file.label}`;
      const fileUrl = await storageService.uploadFile(file, filename);
      return fileUrl;
    } catch (error) {
      Logger.error(`Error uploading form asset ${error}`);
      return '';
    }
  };

  uploadAssets = async (data, assetMap) => {
    const newData = { ...data };
    const newFormAssetList = { ...assetMap };
    const promises = Object.keys(newData).map(async itemId => {
      const itemValue = newData[itemId];
      if (Array.isArray(itemValue)) {
        const itemSources = itemValue.filter(item => item.file);
        const updatedItemSources = await Promise.all(
          itemSources.map(async fileObj => {
            const { file, ...updatedFileObj } = fileObj;
            updatedFileObj.fileUrl = await this.uploadFile(file);
            return updatedFileObj;
          })
        );
        newFormAssetList[itemId] = updatedItemSources;
        return { id: itemId, itemSources: updatedItemSources };
      }
      return itemValue;
    });

    const updatedData = await Promise.all(promises);
    updatedData.forEach(resp => {
      if (resp) {
        const { id, itemSources } = resp;
        if (itemSources) newData[id] = itemSources;
      }
    });

    return [newData, newFormAssetList];
  };

  getAssetUrl = async fileName => {
    if (!fileName) return '';
    try {
      const storageService = new StorageService();
      const fileUrl = await storageService.getFile(fileName);
      return fileUrl;
    } catch (error) {
      Logger.error(`Error reading image ${error}`);
    }
    return '';
  };

  getAllFormAssets = async fileMap => {
    const keys = Object.keys(fileMap);
    if (keys === 0) return fileMap;

    const promises = keys.map(async key => {
      const itemSources = fileMap[key];
      let updatedItemSources = [];
      if (itemSources.length > 0) {
        updatedItemSources = await Promise.all(
          itemSources.map(async asset => {
            const { label, fileUrl } = asset;
            return { label, fileUrl: await this.getAssetUrl(fileUrl) };
          })
        );
      }
      return { id: key, itemSources: updatedItemSources };
    });
    const newFiles = await Promise.all(promises);
    return newFiles;
  };

  setSmartFieldData = (data, smartFieldReferences) => {
    const { parent } = this.props;
    const newData = { ...data };
    const companyContext = Context.getCompanyContext();
    smartFieldReferences.forEach(field => {
      const { id, source } = field;
      const [entity, entityAttribute] = source.split(':');
      let smartValue = '';
      if (entity === 'company' && companyContext) {
        smartValue = companyContext.getCompany[entityAttribute];
      } else if (parent) {
        smartValue = parent[entityAttribute];
      }
      newData[id] = smartValue;
    });
    return newData;
  };

  setCustomControls = () => {
    const imageControlWithLocalSource = ({ form, field, options }) => {
      const assetMap = { ...this.state.assets };
      const asset = assetMap[field.name];
      const { value } = field;

      if (asset) {
        const newOptions = { ...options };
        const newField = { ...field };
        if (value) {
          newField.value = asset;
        } else {
          newOptions.source = asset;
        }
        return <MUIFormComponents.ImageControl field={newField} form={form} options={newOptions} />;
      }
      return null;
    };

    const ReadOnlySignatureScreenControl = ({ form, field, options }) => {
      const { isEditMode } = this.state;
      const newOptions = { ...options };
      if (!isEditMode) {
        newOptions.readOnly = true;
        newOptions.isRequired = false;
      }

      return <SignatureScreen field={field} form={form} options={newOptions} />;
    };

    const customControls = {
      ImageControl: imageControlWithLocalSource,
      SignatureScreen: ReadOnlySignatureScreenControl
    };
    return customControls;
  };

  handleViewToggle = () => {
    const { isEditMode } = this.state;
    this.setState({ isEditMode: !isEditMode });
  };

  showSpinner = () => (
    <div style={{ position: 'absolute' }}>
      <Spinner size={24} />
    </div>
  );

  render() {
    const { layout, data, isEditMode, formService, allowToggle } = this.state;
    const { handleClose, isSaving, name: viewOnlyName, description: tempDescription } = this.props;

    return (
      <div>
        {allowToggle && (
          <FormControlLabel
            control={
              <Switch checked={isEditMode} color="primary" onChange={this.handleViewToggle} />
            }
            label="Edit Mode"
          />
        )}
        <div>
          <MUIForm
            configuration={layout}
            customComponents={this.setCustomControls()}
            data={{ ...data, viewOnlyName, tempDescription }}
            layout={isEditMode ? 'edit' : 'default'}
            onComplete={completed => {
              if (allowToggle) this.handleViewToggle();
              this.submitForm(completed);
            }}
            onCreateService={service => this.setState(() => ({ formService: service }))}
            onFormNotification={(type, message) => this.onMessage(type, message)}
          />
          {isEditMode && (
            <DialogActions>
              <DefaultButton
                color="secondary"
                disabled={isSaving}
                handle={handleClose}
                label="Cancel"
              />
              <DefaultButton
                color="primary"
                disabled={isSaving}
                handle={() => formService.submit()}
                label="Submit"
              />
            </DialogActions>
          )}
        </div>
      </div>
    );
  }
}

export default FormViewer;
