import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { logErrorWithCallback } from 'utils';

const getJobsByCustomerPropertyId = gql`
  query getJobsByCustomerPropertyId(
    $id: String!
    $filter: GenericFilterInput
    $sort: [SortingInput]
  ) {
    getCustomerPropertyById(id: $id) {
      id
      jobs: jobsView(filter: $filter, sorting: $sort) {
        items {
          id
          jobNumber
          customIdentifier
          jobTypeInternal
        }
      }
    }
  }
`;

export const useCustomerPropertyJobs = ({ snackbar, customerPropertyId, filter }) => {
  const { data, loading, error } = useExtendedQuery(getJobsByCustomerPropertyId, {
    variables: { id: customerPropertyId, filter },
    fetchPolicy: 'no-cache', // as lot of items can be removed and added to job in realtime.,
    transform: ({ getCustomerPropertyById }) => getCustomerPropertyById?.jobs?.items || []
  });

  if (error) {
    logErrorWithCallback(error, snackbar, 'unable to get the jobs, please try again later.');
  }
  return [data, loading];
};
