import React, { useEffect, useState } from 'react';

import {
  Checkbox,
  TableHead as MuiTableHead,
  TableCell,
  TableRow,
  TableSortLabel
} from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { Placeholder } from 'components';
import labels from 'meta/labels';
import ErrorBoundaries from 'scenes/Error';

import { getCellDataAlignment } from './tableUtils';

const useStyles = makeStyles(theme => ({
  tableHeadRow: {
    height: '48px',
    borderStyle: 'none'
  },
  tableHeadCell: {
    backgroundColor: `${theme.palette.grayscale(94)} !important`,
    borderColor: `${theme.palette.grayscale(80)} !important`,
    borderTopWidth: '1px !important',
    position: 'sticky',
    top: 0,
    zIndex: 2 // needed to elevate over some table body elements
  },
  textStyles: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: '700',
    fontSize: '14px',
    whiteSpace: 'nowrap'
  },
  root: {
    // Override material UI default
    '&:focus': {
      color: theme.palette.brand.green,
      opacity: 1,
      '& $sortLabelIcon': {
        opacity: 1
      }
    },
    // Override material UI default
    '&:hover': {
      color: theme.palette.brand.green,
      opacity: 1,
      '& $sortLabelIcon': {
        opacity: 1
      }
    },
    '&$active': {
      color: theme.palette.grayscale(20),
      opacity: 1,
      '&:hover': {
        color: theme.palette.brand.green,
        '&& $sortLabelIcon': {
          color: theme.palette.brand.green
        }
      },
      '&& $sortLabelIcon': {
        color: theme.palette.grayscale(20)
      }
    }
  },
  sortLabelIcon: {
    opacity: 0,
    marginBottom: '1px'
  },
  active: {
    color: theme.palette.grayscale(20)
  },
  oppositeSideSortIcon: {
    flexDirection: 'row-reverse'
  },
  centeringSortLabel: {
    visibility: 'hidden'
  },
  centerColumnTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  }
}));

export default function TableHead(props) {
  const {
    onSelectAllClick,
    sortOrder,
    sortBy,
    selectedRowsCount,
    rowCount,
    rowDetailLayout,
    rowCollapsible,
    onRequestSort,
    metadata,
    isSelectionEnabled,
    areRowButtonsEnabled,
    user,
    shouldUseQueries,
    backgroundColor,
    isLoading,
    reorderColumn,
    isListDataTable,
    allRowsAreSelectable = true
  } = props;

  const [visibleColumnsMetadata, setVisibleColumnsMetadata] = useState([]);
  const [itemBeingDraggedDraggableId, setItemBeingDraggedDraggableId] = useState(null);
  const [pendingDestinationIndex, setPendingDestinationIndex] = useState(null);

  useEffect(() => {
    setVisibleColumnsMetadata(metadata.filter(x => !(x.hide || x.internal)));
  }, [metadata]);

  const handleDragStart = ({ draggableId }) => setItemBeingDraggedDraggableId(draggableId);
  const handleDragEnd = result => {
    setItemBeingDraggedDraggableId(null);
    setPendingDestinationIndex(null);

    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    if (visibleColumnsMetadata[result.destination.index].isDragDisabled) {
      return;
    }
    const source = visibleColumnsMetadata[result.source.index];
    const destination = visibleColumnsMetadata[result.destination.index];
    const srcIndex = metadata.findIndex(meta => meta.id === source.id);
    const dstIndex = metadata.findIndex(meta => meta.id === destination.id);
    metadata.splice(srcIndex, 1);
    metadata.splice(dstIndex, 0, source);
    setVisibleColumnsMetadata(metadata.filter(x => !x.hide));
    reorderColumn(metadata);
  };

  const handleDragUpdate = ({ source, destination }) => {
    if (!destination?.index) {
      setPendingDestinationIndex(null);
      return;
    }
    if (destination.index > source.index) {
      setPendingDestinationIndex(destination.index + 1);
    } else {
      setPendingDestinationIndex(destination.index);
    }
  };

  const classes = useStyles({ backgroundColor, isLoading, metadata });
  const tableCellClass = `${props.classes.tableCell} ${classes.tableHeadCell}`;
  const noData = rowCount <= 0 && !isLoading;

  // if it's loading, and no metadata, that means it's loading metadata
  if (isLoading && !metadata?.length) {
    return (
      <TableRow className={classes.tableHeadRow}>
        <TableCell className={tableCellClass}>
          <Placeholder
            paddingBottom={0}
            paddingLeft={0}
            paddingRight={0}
            paddingTop={0}
            repeatCount={2}
            variant="tablehead"
          />
        </TableCell>
      </TableRow>
    );
  }

  // need to spread droppableProps onto components
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <ErrorBoundaries>
      <MuiTableHead className={classes.tableHead}>
        <DragDropContext
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          onDragUpdate={handleDragUpdate}
        >
          <Droppable direction="horizontal" droppableId="droppable">
            {droppableProvided => (
              <TableRow
                className={classes.tableHeadRow}
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {isSelectionEnabled && (
                  <TableCell className={tableCellClass} padding="checkbox">
                    <Checkbox
                      checked={
                        rowCount > 0 &&
                        (allRowsAreSelectable
                          ? selectedRowsCount === rowCount
                          : selectedRowsCount > 0)
                      }
                      disabled={rowCount <= 0}
                      indeterminate={selectedRowsCount > 0 && selectedRowsCount < rowCount}
                      onChange={onSelectAllClick}
                    />
                  </TableCell>
                )}
                {visibleColumnsMetadata.map((colMetadata, colIndex) => (
                  <Draggable
                    draggableId={`drag${colMetadata.id}`}
                    index={colIndex}
                    isDragDisabled
                    key={`drag${colMetadata.id}`}
                  >
                    {(draggableProvided, snapshot) => {
                      const columnName =
                        labels[colMetadata.label]?.[user.locale] || colMetadata.label;
                      const columnSortKey =
                        shouldUseQueries && !isListDataTable
                          ? colMetadata.filterKey
                          : colMetadata.id;
                      const isSortingColumn = columnSortKey === sortBy;
                      const align = getCellDataAlignment(colMetadata, colIndex);
                      const background = colMetadata?.headerStyle?.background;

                      // borders are only applied to left of each cell -
                      // if you drag an header item out of its place,
                      // the item to the immediate left should set its right border
                      const indexOfDraggedItem = visibleColumnsMetadata.findIndex(
                        ({ id }) => itemBeingDraggedDraggableId === `drag${id}`
                      );
                      const isImmediateLeftOfDraggedItem = colIndex === indexOfDraggedItem - 1;
                      const isImmediateLeftOfDestination = colIndex === pendingDestinationIndex - 1;

                      return (
                        <TableCell
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          align={align}
                          className={tableCellClass}
                          key={colMetadata.id}
                          padding={colMetadata.disablePadding ? 'none' : null}
                          sortDirection={isSortingColumn ? sortOrder : null}
                          style={{
                            ...colMetadata.headerStyle,
                            ...draggableProvided.draggableProps.style,
                            background: snapshot.isDragging
                              ? 'rgba(245,245,245, 0.75)'
                              : background,
                            ...(snapshot.isDragging ||
                            isImmediateLeftOfDraggedItem ||
                            isImmediateLeftOfDestination
                              ? { borderRightWidth: 1 }
                              : {}),
                            ...(snapshot.isDragging
                              ? {
                                  display: 'flex',
                                  alignItems: 'center'
                                }
                              : {})
                          }}
                        >
                          <div {...draggableProvided.dragHandleProps}>
                            {colMetadata.disableSort || noData ? (
                              <div className={classes.textStyles}>{columnName}</div>
                            ) : (
                              <div
                                className={`${
                                  colMetadata.alignCenter ? classes.centerColumnTitle : ''
                                }`}
                              >
                                {/* When center alignment is requested, a corresopnding invisible sort icon is added
                                    on the opposite side to the visible one so that the text of the header column is
                                    perfectly centered. */}
                                {colMetadata.alignCenter && (
                                  <TableSortLabel className={classes.centeringSortLabel} />
                                )}
                                <TableSortLabel
                                  active={isSortingColumn}
                                  classes={{
                                    root: `${classes.textStyles} ${
                                      classes.root
                                    } ${colMetadata.align !== 'left' &&
                                    colMetadata.numeric &&
                                    colIndex > 0 && // First column is always left aligned for stylistic reasons
                                      classes.oppositeSideSortIcon}`,
                                    active: classes.active,
                                    icon: classes.sortLabelIcon
                                  }}
                                  direction={isSortingColumn ? sortOrder : 'asc'}
                                  onClick={event => {
                                    onRequestSort(event, columnSortKey);
                                  }}
                                >
                                  {columnName}
                                </TableSortLabel>
                              </div>
                            )}
                          </div>
                        </TableCell>
                      );
                    }}
                  </Draggable>
                ))}
                {areRowButtonsEnabled && <TableCell className={tableCellClass} />}
                {rowDetailLayout && !rowCollapsible && <TableCell className={tableCellClass} />}
                {droppableProvided.placeholder}
              </TableRow>
            )}
          </Droppable>
        </DragDropContext>
      </MuiTableHead>
    </ErrorBoundaries>
  );
}

TableHead.propTypes = {
  selectedRowsCount: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  // true if ResponsiveTable.props.listDataService
  // use colMeta.id as sort field
  isListDataTable: PropTypes.bool
};

TableHead.defaultProps = {
  isListDataTable: false
};
