import React, { useState } from 'react';

import { Box, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Labels from 'meta/labels';
import { LeaveDateModalStatus } from 'utils/AppConstants';

import LeaveDateModal from './LeaveDateModal';

const styles = makeStyles(theme => ({
  inputLabel: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'normal',
    letterSpacing: '0.01px',
    lineHeight: '14px',
    marginTop: 17
  },
  inputField: {
    width: 64,
    fontSize: 14
  },
  nextStepButton: {
    marginLeft: 160
  },
  description: {
    paddingTop: '20px',
    paddingBottom: '20px',
    color: theme.palette.grayscale(60)
  },
  button: {
    width: '120px',
    marginTop: '8px',
    height: '38px',
    marginLeft: '40px'
  },
  dialogContent: {
    [theme.breakpoints.up('md')]: {
      minWidth: 550,
      maxWidth: 550
    }
  },
  tipText: { width: '300px', color: theme.palette.grayscale(60) },
  tip: { color: theme.palette.grayscale(10) },
  note: {
    width: '300px',
    color: theme.palette.error.main
  },
  estimatedDays: {
    color: theme.palette.brand.green
  }
}));

const DateStep = props => {
  const { onClickNextStep, userLocale, pageTitle, stepData, items } = props;
  const [days, setDays] = useState(stepData?.days || null);
  const [openLeaveDateModal, setopenLeaveDateModal] = useState(false);
  const totalEstimatedVisits = items?.map(item => item.estimatedVisits);
  const maxEstimatedVisit = Math.max(...totalEstimatedVisits) || 1;
  const [isReady, setIsReady] = useState(true);
  const classes = styles();

  const handleOnChange = event => {
    const { value } = event.target;
    setIsReady(maxEstimatedVisit <= value);
    if (value === '') return setDays(null);
    if (event.target.value < 0) return;
    setDays(event.target.value);
  };

  const handleSubmit = () => {
    const leaveDateStatus = sessionStorage.getItem(LeaveDateModalStatus) || 'false';
    if (days === null && leaveDateStatus !== 'true') {
      setopenLeaveDateModal(true);
    } else {
      setopenLeaveDateModal(false);
      onClickNextStep({ days });
    }
  };
  return (
    <Box margin="auto" maxWidth="500px">
      <Typography className={classes.title} variant="h4">
        {pageTitle}
      </Typography>
      <Box display="flex" flexDirection="column">
        <Typography className={classes.inputLabel} mt={4} variant="body2">
          {Labels.scheduleVisits[userLocale]}
        </Typography>
        <Box alignItems="center" display="flex" flexDirection="row">
          <TextField
            InputProps={{
              className: classes.inputField,
              disableUnderline: true
            }}
            placeholder="Enter #"
            type="number"
            value={days == null ? '' : Number(days)}
            onChange={e => handleOnChange(e)}
          />
          <Typography variant="body2">{Labels.dayBeforeDueDate[userLocale]}</Typography>
          <Button
            className={classes.nextStepButton}
            variant="containedSecondary"
            onClick={handleSubmit}
          >
            {Labels.nextStep[userLocale]}
          </Button>
        </Box>
        {isReady ? (
          <Typography className={classes.tipText} variant="body2">
            <b className={classes.tip}>TIP:</b> Schedule visits at least{' '}
            <b className={classes.estimatedDays}>{maxEstimatedVisit} day(s)</b> before maintenance
            due date to ensure there are no overdue visits.
          </Typography>
        ) : (
          <Typography className={classes.note} variant="body2">
            <b>Note: </b>For maintenance requiring multiple visits, some visits will be scheduled
            past the maintenance due date.
          </Typography>
        )}
      </Box>
      <LeaveDateModal
        classes={classes}
        handleSubmit={() => onClickNextStep({ days })}
        openLeaveDateModal={openLeaveDateModal}
        userLocale={userLocale}
      />
    </Box>
  );
};

DateStep.propTypes = {
  onClickNextStep: PropTypes.func.isRequired,
  userLocale: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired
};

export default DateStep;
