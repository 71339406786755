import React from 'react';

import { ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';

import { CustomRadioGroup } from './ChangeOrderFormatSelect.component';
import {
  changeOrderFormatDescriptions,
  changeOrderFormatLabels,
  changeOrderFormatValues
} from './ChangeOrderFormatSelect.constants';

const ChangeOrderFormatSelect = ({ onChange, value }) => (
  <ThemeProvider>
    <Typography style={{ marginBottom: 10 }} variant={TV.L} weight={TW.BOLD}>
      Change Order Format
    </Typography>
    <CustomRadioGroup
      handleChange={onChange}
      options={[
        ...Object.keys(changeOrderFormatLabels).map(formatName => ({
          value: changeOrderFormatValues[formatName],
          label: changeOrderFormatLabels[formatName],
          description: changeOrderFormatDescriptions[formatName]
        }))
      ]}
      value={value}
    />
  </ThemeProvider>
);

export default React.memo(ChangeOrderFormatSelect);
