/** @jsxRuntime classic */
/** @jsx jsx */
import React, { memo } from 'react';

import { jsx } from '@emotion/react';
import { LinearProgress } from '@material-ui/core';

import HeaderColumn from '../HeaderColumn';
import { useHeaderColumns } from '../WrapTable.hooks';
import { HeaderProps } from '../WrapTable.types';

import { useHeaderStyles } from './Header.styles';

const Header = ({
  columns,
  scrollX,
  scrollY,
  loading,
  noWrap,
  isFirstLoad,
  rowGroupingModel,
  rowGroupingColumnMode
}) => {
  const styles = useHeaderStyles({ scrollY });

  const rowColumns = useHeaderColumns({
    columns,
    rowGroupingModel,
    rowGroupingColumnMode
  });

  return (
    <div css={styles.stickyContainer}>
      <div css={styles.fullWidthContainer}>
        <div css={styles.header}>
          {rowColumns.map((colDef, index) => (
            <HeaderColumn
              colDef={colDef}
              header
              isFirstCol={index === 0}
              isLastCol={index === columns.length - 1}
              key={colDef.field}
              noWrap={noWrap}
              scrollX={scrollX}
              value={colDef.headerName}
            />
          ))}
        </div>
        {!isFirstLoad && loading && <LinearProgress css={styles.progressBar} />}
      </div>
    </div>
  );
};

Header.propTypes = HeaderProps;

export default memo(Header);
