import React from 'react';

import { MoreButton } from '@BuildHero/sergeant';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';

import { LinkButton } from 'components';

export const getRFIMetadata = (status, mode, projectId, handleEdit, handleDelete) => {
  return [
    {
      field: 'number',
      headerName: 'Number',
      maxWidth: 100,
      showLink: 'view',
      renderCell: ({ value, row }) => (
        <LinkButton
          label={value}
          path={`/project/${mode}/${projectId}/rfi/${row.id}`}
          style={{ background: 'none', display: 'block', padding: 0, lineHeight: 'inherit' }}
        />
      )
    },
    {
      field: 'subject',
      flex: 1,
      headerName: 'Subject'
    },
    {
      field: 'sendToName',
      flex: 1,
      headerName: 'Recipient'
    },
    ...(status !== 'draft'
      ? [
          {
            field: 'sentDate',
            flex: 1,
            headerName: 'Sent Date',
            valueFormatter: ({ value }) => {
              return value ? moment.unix(value).format('MMM DD, YYYY') : '-';
            }
          }
        ]
      : []),
    ...(status === 'responded' || status === 'closed'
      ? [
          {
            field: 'rfiAnswerDate',
            flex: 1,
            headerName: 'Answer Date',
            valueFormatter: ({ value }) => {
              return value ? moment.unix(value).format('MMM DD, YYYY') : '-';
            }
          }
        ]
      : []),
    {
      field: 'dueDate',
      flex: 1,
      headerName: 'Return By',
      valueFormatter: ({ value }) => {
        return value ? moment.unix(value).format('MMM DD, YYYY') : '-';
      }
    },
    {
      field: 'daysOverdue',
      flex: 1,
      headerName: 'Days Overdue'
    },
    {
      field: 'actions',
      flex: 1,
      maxWidth: 41,
      headerName: '',
      noPadding: true,
      align: 'center',
      justify: 'center',
      renderCell: ({ row }) => (
        <MoreButton
          options={[
            {
              label: 'Edit',
              icon: EditIcon,
              onClick: () => handleEdit(row)
            },
            {
              label: 'Delete',
              icon: DeleteIcon,
              onClick: () => handleDelete(row)
            }
          ]}
        />
      )
    }
  ];
};
