import { useState } from 'react';

import { split } from 'lodash';
import * as R from 'ramda';

const useDepartmentValue = job => {
  const jobDepartment = R.pipe(
    R.head,
    department => department?.mappedEntity,
    department => {
      if (department) {
        return R.pick(['id', 'tagName'])(department);
      }
    }
  )(job?.departments?.items || []);

  const quoteDepartment = R.pipe(
    R.find(quoteJob => Boolean(quoteJob?.quote?.department?.id)),
    quoteJob => quoteJob?.quote?.department,
    department => {
      if (department) {
        return R.pick(['id', 'tagName'])(department);
      }
    }
  )(job?.quoteJobs?.items || []);

  const visitDepartment = R.pipe(
    R.find(visit => Boolean(visit?.departmentId)),
    visit => {
      if (visit) {
        return {
          id: visit.departmentId,
          tagName: visit.departmentName
        };
      }
    }
  )(job?.visits?.items || []);

  const department = jobDepartment || quoteDepartment || visitDepartment;

  return useState(
    department
      ? {
          label: department?.tagName,
          value: department?.id
        }
      : undefined
  );
};

export default useDepartmentValue;
