import React from 'react';

import { Divider, TW, Typography } from '@BuildHero/sergeant';
import { Box, Grid } from '@material-ui/core';

import CustomLinearProgress from '@pm/components/CustomLinearProgress';
import InfoField from '@pm/components/InfoField';
import { formatDisplayCurrency } from 'utils';
import toFixedNumber from 'utils/toFixedNumber';

import { useStyles } from './ProjectInfo.styles';

const ProgressBar = ({ value }) => {
  const styles = useStyles();

  return (
    <Box css={styles.progressBarContainer}>
      <CustomLinearProgress value={value > 100 ? 100 : value || 0} />
      <Typography>{parseFloat(value) || 0}%</Typography>
    </Box>
  );
};

const ProjectInfo = ({ project, projectKpis }) => {
  const styles = useStyles();

  return (
    <>
      <Grid container css={styles.root} justify="space-between" md={12} spacing={3}>
        <Grid item md={3}>
          <InfoField
            label="Department"
            loading={!project.detailsLoaded}
            value={project?.projectManagerDepartment?.tagName}
            weight={TW.BOLD}
          />
        </Grid>
        <Grid item md={3}>
          <InfoField
            label="Project Manager"
            loading={!project.detailsLoaded}
            value={project?.projectManager?.name}
            weight={TW.BOLD}
          />
        </Grid>
        <Grid item md={4}>
          <InfoField
            component={ProgressBar}
            label="Percent Complete"
            loading={!project.summaryLoaded}
            value={toFixedNumber(projectKpis?.percentComplete)}
            weight={TW.BOLD}
          />
        </Grid>
        <Grid item md={2}>
          <InfoField
            label="AR Balance"
            loading={!projectKpis.loaded}
            value={formatDisplayCurrency(projectKpis?.openAr)}
            weight={TW.BOLD}
          />
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

export default ProjectInfo;
