/* eslint-disable default-case */
import React, { Component } from 'react';

import { MUIForm, MultiSelect } from '@BuildHero/sergeant';
import CardMedia from '@material-ui/core/CardMedia';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { sortBy } from 'lodash';
import { connect } from 'react-redux';

import {
  ImageUpload,
  Modal,
  PageHeader,
  ResponsiveTable,
  SergeantModal,
  Spinner,
  UserPermission
} from 'components';
import Context from 'components/Context';
import FormViewer from 'components/FormSection/FormViewer';
import MapViewStatic from 'components/MapViewStatic';
import { Tab, Tabs } from 'components/Tabs';
import {
  AttachmentLayout,
  attachmentsColumns,
  NoteLayout,
  notesColumns,
  partsAndMaterialsColumns,
  PartsAndMaterialsLayout,
  PropertyAssetLayout,
  serviceHistoryColumns,
  VisitAssetDetailLayout,
  warrantyColumns,
  WarrantyLayout
} from 'meta/Customer/PropertyAsset';
import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';

import ErrorBoundaries from 'scenes/Error';
import LinkFieldWithLabel from 'scenes/Maintenance/DetailView/common/LinkFieldWithLabel';
import List from 'scenes/ServiceAgreements/DetailView/ServiceAgreementsTabs/RecurringMaintenance/Components/MaintenanceTemplate/PartsAndMaterials/List';
import AmplifyService from 'services/AmplifyService';
import { CompanyService, CustomerPropertyService } from 'services/core';
import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';
import {
  capitalizeFirstLetter,
  checkPermission,
  getImageUrl,
  getUniqueName,
  isJSONParseableObjectOrArray
} from 'utils';
import { PermissionConstants } from 'utils/AppConstants';
import { Mode } from 'utils/constants';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import FormLineItem from './FormLineItem';

import {
  getPropertyAssetById,
  removeFormFromPropertyAsset,
  saveFormsToPropertyAsset,
  updatePropertyAsset
} from './gql';
import { ItemSearch } from './ItemSearch';

const DataType = {
  Warranty: 'warranty',
  Attachment: 'attachment',
  Note: 'note',
  PartsAndMaterials: 'partsAndMaterials'
};

const PluralDataType = {
  Warranty: 'warranties',
  Attachment: 'attachments',
  Note: 'notes'
};

const DefaultData = {
  Warranty: { warrantyInfo: '', startDate: null, endDate: null },
  Attachment: { customFileName: '', newFiles: [], comment: '' },
  Note: { subject: '', note: '' },
  PartsAndMaterials: { productName: '', items: [] }
};

const formatPartsAndMaterialTableItems = item => ({
  productCode: item?.product?.code,
  name: item?.product?.name,
  description: item?.productDescription || item?.product?.description,
  quantity: item?.quantity,
  id: item?.id,
  productId: item.productId
});

class AssetDetail extends Component {
  constructor(props) {
    super(props);
    const { computedMatch } = this.props;
    const { mode } = computedMatch.params;
    const { defaultPriceBookId } = Context.getCompanyContext()?.getCompany || {};
    this.storageService = new StorageService();
    this.CustomerPropertyService = new CustomerPropertyService();
    this.CompanyService = new CompanyService();
    this.appSyncClient = AmplifyService.appSyncClient().client;
    this.state = {
      assetData: {},
      loadingAssetForms: false,
      imageUrl: '',
      mode,
      defaultPriceBookId,
      partsAndMaterialsTableItems: [],
      partsAndMaterialsTableIsLoading: true,
      propertyAssetVersion: null,
      showFormViewerModal: false,
      FormViewerRecord: '',
      modal: {
        open: false,
        data: null,
        dataType: '',
        mode: Mode.ADD,
        layout: null,
        deleteItemLabel: ''
      }
    };
  }

  componentDidMount = async () => {
    const { id } = this.props.computedMatch.params;
    const newState = {};
    const assetData = await this.getPropertyAsset(id);
    const { imageUrl, serviceAgreements } = assetData;
    assetData.serviceAgreementDisplays = {
      labelArray: serviceAgreements.map(sa => sa.agreementName),
      linkPathArray: serviceAgreements.map(sa => `/serviceAgreement/view/${sa.id}`)
    };
    newState.assetData = assetData;

    if (imageUrl) {
      newState.imageUrl = await getImageUrl(imageUrl);
    }
    this.setState(newState);
  };

  closeFormViewerModal = () => this.setState({ showFormViewerModal: false });

  getPropertyAsset = async id => {
    try {
      const data = await this.CustomerPropertyService.getPropertyAssetById(id);
      if (data) return data;
    } catch (error) {
      Logger.error(`Error in fetching Asset ${JSON.stringify(error)}`);
      this.props.snackbarOn('error', 'Unable to fetch Asset, please try again later', error);
    }
    return {};
  };

  handleOnComplete = async formData => {
    const payload = formData;
    const { assetData } = this.state;
    try {
      // force spinner
      this.setState({ assetData: {} });

      const { data } = await this.CustomerPropertyService.updatePropertyAsset(payload);
      if (data) {
        this.props.snackbarOn('success', 'Successfully updated asset.');
        this.props.history.push(`/asset/view/${payload.id}`);
      }
    } catch (error) {
      Logger.error(`Error in updating asset ${JSON.stringify(error)}`);
      this.props.snackbarOn('error', 'Unable to update asset, please try again later', error);
      this.setState({ assetData });
    }
  };

  handleSave = () => this.state.formService.submit();

  setCustomControls = () => {
    const imageControlWithLocalSource = () => {
      const { imageUrl } = this.state;
      const imageStyle = {
        height: 136,
        width: 136,
        resizeMode: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
      };

      return <CardMedia image={imageUrl} style={imageStyle} title="Asset Image" />;
    };

    const LogoButton = ({ form, field }) => (
      <ImageUpload field={field} form={form} label="Add Image" user={this.props.user} />
    );

    const assetTitle = ({ field }) => {
      const { value } = field;
      const title = value || 'No Name';
      return (
        <Typography gutterBottom variant="h5">
          {title}
        </Typography>
      );
    };

    const customControls = {
      LinkFieldWithLabel,
      ImageControl: imageControlWithLocalSource,
      LogoButton,
      AssetTitle: assetTitle,
      OcrButton: () => null
    };
    return customControls;
  };

  setHeaderButtons = id => ({
    edit: {
      label: 'Edit Asset',
      color: 'primary',
      buttonType: 'contained',
      caslKey: PermissionConstants.OBJECT_ASSET,
      caslAction: 'edit',
      behavior: {
        view: true
      },
      action: () => this.props.history.push(`/asset/edit/${id}`)
    },
    save: {
      label: 'Save Changes',
      color: 'primary',
      buttonType: 'contained',
      caslKey: PermissionConstants.OBJECT_ASSET,
      behavior: {
        new: true,
        edit: true
      },
      action: this.handleSave
    },
    cancel: {
      label: 'Cancel',
      color: 'secondary',
      buttonType: 'contained',
      caslKey: PermissionConstants.OBJECT_ASSET,
      behavior: {
        new: true,
        edit: true
      },
      action: () => this.props.history.push(`/asset/view/${id}`)
    }
  });

  getPropertyAddressFromProperty = property =>
    property?.companyAddresses?.items?.filter(addr => addr.addressType === 'propertyAddress')[0] ??
    {};

  massageDataForPartsAndMaterialPopup = data => ({
    id: data?.id,
    productId: data.productId,
    quantity: data.quantity,
    description: data.description,
    name: data.name
  });

  handleModalOpen = async (dataType, mode, data = DefaultData[capitalizeFirstLetter(dataType)]) => {
    let layout = {};
    let deleteItemLabel = '';
    let modalData = data;
    let formVersion;
    let onFormChange;

    if (data) {
      switch (dataType) {
        case DataType.Attachment: {
          layout = AttachmentLayout;
          deleteItemLabel = data.displayName;

          modalData = data.id ? await this.attachmentToFileInput(data) : data;
          break;
        }
        case DataType.PartsAndMaterials: {
          const addItem = async product => {
            this.setState(prevState => ({
              modal: {
                ...prevState.modal,
                data: {
                  ...prevState.modal.data,
                  items: [...prevState.modal.data.items, product]
                }
              }
            }));
          };
          const handleItemChange = ({ items }) => {
            this.setState(prevState => ({
              modal: {
                ...prevState.modal,
                disableButton: !items.length
              }
            }));
          };
          onFormChange = handleItemChange;
          layout = PartsAndMaterialsLayout(
            this.state.defaultPriceBookId,
            addItem,
            handleItemChange
          );
          formVersion = mode;
          if (mode === Mode.EDIT) {
            modalData = { items: [this.massageDataForPartsAndMaterialPopup(data)] };
          }

          break;
        }
        case DataType.Note: {
          layout = NoteLayout;
          deleteItemLabel = data.subject;
          break;
        }
        case DataType.Warranty: {
          layout = WarrantyLayout;
          deleteItemLabel = data.warrantyInfo;
        }
      }
    }

    this.setState({
      modal: {
        open: true,
        data: modalData,
        dataType,
        mode,
        formVersion,
        layout,
        deleteItemLabel,
        onFormChange
      }
    });
  };

  handleModalClose = () => {
    const { modal } = this.state;
    modal.open = false;
    this.setState({ modal });
  };

  handleFileUpload = async fileInput => {
    // FileInput from Form library returns: [{ key, label, file, fileUrl }]
    try {
      const uniqueName = getUniqueName(this.state.assetData.tenantId, fileInput.file.name);
      return await this.storageService.uploadFile(fileInput.file, uniqueName, null, null);
    } catch (error) {
      this.props.snackbarOn('error', 'Unable to upload file, please try again later', error);
      return null;
    }
  };

  attachmentToFileInput = async attachment => {
    const s3FileUrl = await this.storageService.getFile(attachment.fileUrl);
    return {
      newFiles: [
        {
          key: 0,
          label: attachment.fileName,
          file: {
            // default to image
            type: 'image'
          },
          fileUrl: s3FileUrl
        }
      ],
      ...attachment
    };
  };

  fileInputToAttachment = async newData => {
    const fileInput = newData.newFiles[0];
    return {
      id: newData.id,
      version: newData.version,
      customFileName: newData.customFileName,
      comment: newData.comment,
      fileName: fileInput.label,
      type: fileInput.file.type,
      fileUrl: await this.handleFileUpload(fileInput)
    };
  };

  updatePartsAndMaterialsInAsset = async (assetData, modalCallback) => {
    try {
      const items = await this.addPartsAndMaterials(assetData);
      this.setState({
        partsAndMaterialsTableItems: items.map(formatPartsAndMaterialTableItems)
      });
    } catch (error) {
      Logger.error(error);
    } finally {
      modalCallback();
      this.props.snackbarOn('success', `Successfully updated parts and materials`);
      this.setState(prevState => ({ modal: { ...prevState.modal, open: false } }));
    }
  };

  handlePrimaryAction = async (newData, modalCallback) => {
    const { assetData } = this.state;
    // the actions in the services are update, add, delete.
    // Rename edit -> update
    const { dataType, mode } = this.state.modal;
    const action = mode === Mode.EDIT ? 'update' : mode;

    const titleCaseDataType = capitalizeFirstLetter(dataType);

    let payload;
    switch (mode) {
      case Mode.ADD: {
        payload = { propertyAssetId: assetData.id, data: newData };
        payload.data.partitionKey = assetData.partitionKey;

        // upload file to S3
        if (dataType === DataType.Attachment) {
          payload.data = await this.fileInputToAttachment(newData);
          if (!payload.data.fileUrl) {
            modalCallback();
            return;
          }
        }

        if (dataType === DataType.PartsAndMaterials) {
          await this.updatePartsAndMaterialsInAsset(assetData, modalCallback);
          return;
        }

        break;
      }
      case Mode.EDIT: {
        payload = newData;
        // TODO: deletion of old image to handled in backend
        // check if the file was changed and if changed, upload new file to S3
        if (dataType === DataType.Attachment && newData.newFiles[0].fileUrl.includes('blob')) {
          payload = await this.fileInputToAttachment(newData);
          if (!payload.fileUrl) {
            modalCallback();
            return;
          }
        }
        if (dataType === DataType.PartsAndMaterials) {
          await this.updatePartsAndMaterialsInAsset(assetData, modalCallback);
          return;
        }
        payload.partitionKey = assetData.partitionKey;

        break;
      }
      case Mode.DELETE: {
        if (dataType === DataType.PartsAndMaterials) {
          const maintenanceTaskRequiredParts = this.state.partsAndMaterialsTableItems
            .filter(item => item?.id !== newData?.id)
            .map(item => ({
              id: item?.id,
              productId: item.productId,
              productDescription: item.productDescription,
              quantity: item.quantity
            }));

          const { data } = await this.appSyncClient.mutate({
            mutation: updatePropertyAsset,
            variables: {
              partitionKey: assetData.partitionKey,
              data: {
                id: assetData.id,
                version: this.state.propertyAssetVersion,
                maintenanceTaskRequiredParts,
                assetName: assetData.assetName
              }
            }
          });

          this.setState(
            {
              modal: {
                ...this.state.modal,
                open: false
              },
              partsAndMaterialsTableItems: data.updatePropertyAsset.maintenanceTaskRequiredParts.items.map(
                formatPartsAndMaterialTableItems
              )
            },
            modalCallback
          );
          return;
        }
        payload = this.state.modal.data.id;
      }
    }

    try {
      // dynamically call the action for the dataType
      // possible values: {add,update,delete}{Note,Attachment,Warranty}OfPropertyAsset,
      const { data } = await this.CustomerPropertyService[
        `${action}${titleCaseDataType}OfPropertyAsset`
      ](payload);
      // flatten gql response
      const flatData = data[Object.keys(data)[0]];
      this.props.snackbarOn('success', `Successfully ${Mode.past(mode)} ${dataType}.`);
      this.handleModalClose();
      this.updateLocally(flatData, titleCaseDataType, this.state.modal.mode);
    } catch (error) {
      Logger.error(
        `Error trying to ${mode} ${titleCaseDataType} (PropertyAsset) ${JSON.stringify(error)}`
      );
      this.props.snackbarOn(
        'error',
        `Unable to ${mode} ${titleCaseDataType}, please try again later`,
        error
      );
    } finally {
      modalCallback();
    }
  };

  addPartsAndMaterials = async assetData => {
    // partsAndMaterialsTableItems state - will have exisitng items
    // modal data state will have new/edited items
    const exisitingItems = this.state.partsAndMaterialsTableItems.map(item => {
      return {
        id: item?.id,
        quantity: item.quantity,
        productId: item.productId
      };
    });

    const dataFromModal = this.state.modal.data.items.map(item => {
      const oldItem = exisitingItems.find(oItem => item?.id === oItem?.id);
      if (!oldItem) {
        return {
          quantity: item.quantity,
          productId: item.productId,
          productDescription: item.description
        };
      }
      return {
        ...oldItem,
        quantity: item.quantity,
        productDescription: item.description
      };
    });

    const maintenanceTaskRequiredParts = [...exisitingItems, ...dataFromModal.filter(i => !!i)];

    const { data } = await this.appSyncClient.mutate({
      mutation: updatePropertyAsset,
      variables: {
        partitionKey: assetData.partitionKey,
        data: {
          id: assetData.id,
          version: this.state.propertyAssetVersion,
          maintenanceTaskRequiredParts,
          assetName: assetData.assetName
        }
      }
    });

    return data.updatePropertyAsset.maintenanceTaskRequiredParts.items;
  };

  updateAssetForms = async newForms => {
    this.setState({
      loadingAssetForms: true
    });
    try {
      const formsToAdd = newForms.filter(
        f => !this.state.assetData.forms.find(af => af.id === f.id)
      );
      const formsToRemove = this.state.assetData.forms.filter(
        af => !newForms.find(f => f.id === af.id)
      );

      // optomistic update to prevent flicker
      this.setState(prevState => ({
        assetData: {
          ...prevState.assetData,
          forms: [
            ...prevState.assetData.forms,
            ...formsToAdd.map(f => ({ ...f, name: f.label, optimistic: true }))
          ].filter(f => !formsToRemove.find(rf => rf.id === f.id))
        }
      }));

      const params = {
        tenantId: this.state.assetData.tenantId,
        items: formsToAdd.map(f => ({
          formId: f.id,
          propertyAssetId: this.state.assetData.id
        }))
      };

      const { data } = await this.appSyncClient.mutate({
        mutation: saveFormsToPropertyAsset,
        variables: params
      });

      const promises = [];
      formsToRemove.forEach(form => {
        const params2 = {
          formId: form.id,
          propertyAssetId: this.state.assetData.id
        };
        const promise = this.appSyncClient.mutate({
          mutation: removeFormFromPropertyAsset,
          variables: params2
        });
        promises.push(promise);
      });

      const removedForms = await Promise.all(promises);

      this.setState(prevState => ({
        assetData: {
          ...prevState.assetData,
          forms: [
            ...prevState.assetData.forms,
            ...data.saveFormsToPropertyAsset.map(f => ({ ...f.form }))
          ].filter(
            f =>
              !f.optimistic &&
              !removedForms.find(rf => rf.data?.removeFormFromPropertyAsset?.form?.id === f.id)
          )
        }
      }));
    } catch (error) {
      this.props.snackbarOn('error', 'Unable to update asset forms, please try again later', error);
    }
    this.setState({
      loadingAssetForms: false
    });
  };

  // dataType is a key of DataType object
  updateLocally = (newData, dataType, mode) => {
    const { assetData } = this.state;
    const itemArray = assetData[PluralDataType[dataType]].items;
    const items = [...itemArray];

    switch (mode) {
      case Mode.ADD:
        items.unshift(newData[0]);
        break;
      case Mode.EDIT: {
        const indexOfItem = itemArray.findIndex(i => i.id === newData.id);
        items[indexOfItem] = newData;
        break;
      }
      case Mode.DELETE: {
        const indexOfItem = itemArray.findIndex(i => i.id === newData.id);
        items.splice(indexOfItem, 1);
        break;
      }
    }

    this.setState(prevState => ({
      assetData: {
        ...prevState.assetData,
        [PluralDataType[dataType]]: { items }
      }
    }));
  };

  handleChangeTab = async (event, value) => {
    if (value === 2) {
      // Parts & Materials Tab

      const { data } = await this.appSyncClient.query({
        query: getPropertyAssetById,
        variables: {
          id: this.state.assetData.id
        }
      });

      this.setState({
        propertyAssetVersion: data.getPropertyAssetById.version,
        partsAndMaterialsTableIsLoading: false,
        partsAndMaterialsTableItems: data.getPropertyAssetById.maintenanceTaskRequiredParts.items.map(
          formatPartsAndMaterialTableItems
        )
      });
    }
  };

  handleClickForm = ({ option }) => {
    this.setState(prevState => {
      const formInfo = prevState.assetData?.forms?.find(f => f.id === option.id);

      if (!formInfo?.latestPublishedFormDefinition) return;

      const { formDefinitionJson } = formInfo.latestPublishedFormDefinition;

      if (!isJSONParseableObjectOrArray(formDefinitionJson)) return;
      return {
        showFormViewerModal: true,
        FormViewerRecord: {
          name: formInfo.name,
          description: formInfo.description,
          formDataJson: JSON.parse(formDefinitionJson)
        }
      };
    });
  };

  render() {
    const { assetData, mode } = this.state;
    if (!assetData.id) {
      return <Spinner />;
    }

    const { user } = this.props;
    const address = this.getPropertyAddressFromProperty(assetData.property);
    const paddedAddressLine2 = address.addressLine2 ? ` ${address.addressLine2}` : '';
    const addressText = `${address.addressLine1}${paddedAddressLine2}, ${address.city} ${address.state} ${address.zipcode}`;
    const convertAttachmentURL = attachment => ({
      ...attachment,
      fileUrl: new StorageService().getFile(attachment.fileUrl)
    });
    const visitAssets = assetData.visitAssets.items.map(v => ({
      id: v.visit.id,
      jobNumber: v.visit.job.jobNumber,
      displayJobNumber: v.visit.job.customIdentifier || v.visit.job.jobNumber,
      visitNumber: v.visit.visitNumber,
      department: v.visit.departmentName,
      primaryTech: v.visit.primaryTechs.items
        .map(t => `${t.mappedEntity.firstName} ${t.mappedEntity.lastName}`)
        .join(', '),
      jobType: v.visit.job.jobTypeName,
      jobTypeInternal: v.visit.job.jobTypeInternal,
      description: v.visit.description,
      attachmentCount: v.visit.attachments?.items.length ?? 0,
      attachments: v.visit.attachments?.items?.map(convertAttachmentURL) || [],
      scheduledFor: v.visit.scheduledFor
    }));

    const attachments = assetData.attachments.items.map(a => ({
      displayName: a.customFileName || a.fileName,
      ...a
    }));
    const rowButtons = {
      edit: {
        label: 'Edit',
        caslAction: 'update',
        caslKey: PermissionConstants.OBJECT_ASSET
      },
      delete: {
        label: 'Remove',
        caslAction: 'delete',
        caslKey: PermissionConstants.OBJECT_ASSET
      }
    };
    const companyContext = Context.getCompanyContext();
    const companyForms = companyContext?.getCompany.forms?.items || [];
    const assetTypes = companyContext?.getCompany?.assetTypes;
    const assetTypeOptions = constructSelectOptions(
      sortBy(assetTypes.items, 'sortOrder'),
      'tagName'
    );

    let sergeantModalTitle;
    let sergeantModalCustomPrimaryButtonLabel = 'Save';
    if (this.state.modal.dataType === DataType.PartsAndMaterials) {
      switch (this.state.modal.mode) {
        case Mode.EDIT:
          sergeantModalTitle = Labels.editPartsAndMaterials[user.locale];
          sergeantModalCustomPrimaryButtonLabel = Labels.editPartsAndMaterials[user.locale];
          break;
        case Mode.DELETE:
          sergeantModalTitle = Labels.deletePartsAndMaterials[user.locale];
          sergeantModalCustomPrimaryButtonLabel = Labels.deletePartsAndMaterialsButton[user.locale];
          break;
        default:
          sergeantModalTitle = Labels.addPartsAndMaterials[user.locale];
          sergeantModalCustomPrimaryButtonLabel = Labels.addPartsAndMaterials[user.locale];
          break;
      }
    }

    const hasServiceAgreements = checkPermission(
      'allow',
      PermissionConstants.OBJECT_SERVICE_AGREEMENT,
      user,
      null,
      null,
      FeatureFlags.SERVICE_AGREEMENTS,
      this.props.flags
    );

    return (
      <ErrorBoundaries>
        <UserPermission action={PermissionConstants.OBJECT_ASSET} I="read">
          <div style={{ flexGrow: 1 }}>
            <PageHeader
              breadcrumbsArray={[
                { title: 'Directory', link: '' },
                { title: Labels.properties[user.locale], link: '/property/list' },
                {
                  title: `${assetData.property.companyName}`,
                  link: `/property/view/${assetData.property.id}`
                }
              ]}
              buttons={this.setHeaderButtons(assetData.id)}
              mode={mode}
              showButtons
              title={Labels.asset[user.locale]}
            />
            <Grid container spacing={3}>
              <Grid item md={8} xs={12}>
                <MUIForm
                  configuration={PropertyAssetLayout(assetTypeOptions, false, hasServiceAgreements)}
                  customComponents={this.setCustomControls()}
                  data={assetData}
                  layout={mode === 'edit' ? 'edit' : 'default'}
                  onComplete={completed => {
                    this.handleOnComplete(completed);
                  }}
                  onCreateService={service => this.setState(() => ({ formService: service }))}
                />
              </Grid>
              <Grid alignItems="flex-end" container direction="column" item md={4} xs={12}>
                <MapViewStatic coordinates={address} dimensions={{ width: 242, height: 124 }} />
                <Typography variant="body1">{addressText}</Typography>
              </Grid>
            </Grid>
            <Grid style={{ paddingTop: 47 }} />
            <Tabs onChange={this.handleChangeTab}>
              <Tab label="Service History" tabKey="serviceHistory">
                <ResponsiveTable
                  caslKey={PermissionConstants.OBJECT_ASSET}
                  data={visitAssets}
                  disableFilter
                  noDataMsg="No Jobs or Maintenance Visits"
                  rowDetailLayout={VisitAssetDetailLayout}
                  rowMetadata={serviceHistoryColumns}
                  showToolbars
                />
              </Tab>
              <Tab label="Attachments" tabKey="attachments">
                <ResponsiveTable
                  addRow={{
                    label: 'Add Attachment',
                    caslKey: PermissionConstants.OBJECT_ASSET,
                    handleAdd: () => this.handleModalOpen(DataType.Attachment, Mode.ADD)
                  }}
                  caslKey={PermissionConstants.OBJECT_ASSET}
                  data={attachments}
                  disableFilter
                  noDataMsg="No Attachments"
                  noEmptyRows
                  rowActionButtons={rowButtons}
                  rowActions={(action, data) =>
                    this.handleModalOpen(DataType.Attachment, action, data)
                  }
                  rowMetadata={attachmentsColumns}
                  showToolbars
                />
              </Tab>
              <Tab label="Parts & Materials" tabKey="PartsAndMaterials">
                <ResponsiveTable
                  addRow={{
                    label: 'Add Parts & Materials',
                    caslKey: PermissionConstants.OBJECT_ASSET,
                    handleAdd: () => this.handleModalOpen(DataType.PartsAndMaterials, Mode.ADD)
                  }}
                  caslKey={PermissionConstants.OBJECT_ASSET}
                  data={this.state.partsAndMaterialsTableItems}
                  disableFilter
                  isLoading={this.state.partsAndMaterialsTableIsLoading}
                  noDataMsg="No Parts & Materials"
                  noEmptyRows
                  rowActionButtons={{
                    edit: {
                      label: 'Edit',
                      caslAction: 'edit',
                      caslKey: PermissionConstants.OBJECT_ASSET,
                      icon: 'Edit'
                    },
                    delete: {
                      label: 'Remove',
                      caslAction: 'delete',
                      caslKey: PermissionConstants.OBJECT_ASSET,
                      icon: 'Delete'
                    }
                  }}
                  rowActions={(action, data) =>
                    this.handleModalOpen(DataType.PartsAndMaterials, action, data)
                  }
                  rowMetadata={partsAndMaterialsColumns}
                  showToolbars
                />
              </Tab>
              {this.props.flags[FeatureFlags.MAINTENANCE_TEMPLATES_V2] && (
                <Tab label="Forms" tabKey="forms">
                  <MultiSelect
                    disabled={this.state.loadingAssetForms}
                    label="Forms"
                    lineItemComponent={(option, selectedOptions) => (
                      <FormLineItem
                        disabled={this.state.loadingAssetForms}
                        option={option}
                        selectedOptions={selectedOptions}
                        updateAssetForms={this.updateAssetForms}
                        onClick={this.handleClickForm}
                      />
                    )}
                    options={companyForms
                      .filter(
                        f =>
                          f.latestPublishedFormDefinitionSortKey &&
                          f.associatedEntityType === 'Task'
                      )
                      .map(f => ({
                        id: f.id,
                        label: f.name,
                        value: f.id
                      }))}
                    placeholder="Search & Select Forms"
                    selectedOptions={assetData.forms.map(f => ({
                      id: f.id,
                      label: f.name,
                      value: f.id
                    }))}
                    showChips
                    showSearchIcon
                    topLevelSelectAllLabel="Select All Forms"
                    onClosePopper={this.updateAssetForms}
                  />
                </Tab>
              )}
              <Tab label="Warranties" tabKey="warranty">
                <ResponsiveTable
                  addRow={{
                    label: 'Add Warranty',
                    caslKey: PermissionConstants.OBJECT_ASSET,
                    handleAdd: () => this.handleModalOpen(DataType.Warranty, Mode.ADD)
                  }}
                  caslKey={PermissionConstants.OBJECT_ASSET}
                  data={assetData.warranties?.items}
                  disableFilter
                  multiline
                  noDataMsg="No Warranty Information"
                  noEmptyRows
                  rowActionButtons={rowButtons}
                  rowActions={(action, data) =>
                    this.handleModalOpen(DataType.Warranty, action, data)
                  }
                  rowMetadata={warrantyColumns}
                  showToolbars
                />
              </Tab>
              <Tab label="Notes" tabKey="notes">
                <ResponsiveTable
                  addRow={{
                    label: 'Add Note',
                    caslKey: PermissionConstants.OBJECT_ASSET,
                    handleAdd: () => this.handleModalOpen(DataType.Note, Mode.ADD)
                  }}
                  caslKey={PermissionConstants.OBJECT_ASSET}
                  data={assetData.notes?.items}
                  disableFilter
                  noDataMsg="No Notes"
                  noEmptyRows
                  rowActionButtons={rowButtons}
                  rowActions={(action, data) => this.handleModalOpen(DataType.Note, action, data)}
                  rowMetadata={notesColumns}
                  showToolbars
                />
              </Tab>
            </Tabs>
            <Modal
              handleClose={this.closeFormViewerModal}
              open={this.state.showFormViewerModal}
              width={696}
            >
              <FormViewer
                allowToggle
                description={this.state.FormViewerRecord.description}
                formMetaData={this.state.FormViewerRecord.formDataJson}
                handleClose={this.closeFormViewerModal}
                name={this.state.FormViewerRecord.name}
              />
            </Modal>
            <SergeantModal
              confirmRemoveItemLabel={this.state.modal.deleteItemLabel}
              customComponents={{ List, ItemSearch }}
              customPrimaryButtonLabel={sergeantModalCustomPrimaryButtonLabel}
              data={this.state.modal.data}
              dataType={this.state.modal.dataType}
              disablePrimaryButton={this.state.modal.disableButton}
              formVersion={this.state.modal.formVersion}
              handleClose={this.handleModalClose}
              handlePrimaryAction={this.handlePrimaryAction}
              layout={this.state.modal.layout}
              mode={this.state.modal.mode}
              open={this.state.modal.open}
              title={sergeantModalTitle}
              onFormChange={this.state.modal.onFormChange}
            />
          </div>
        </UserPermission>
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});

const mapAssetsToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const reduxConnectedAssetDetail = connect(
  mapStateToProps,
  mapAssetsToProps
)(withLDConsumer()(AssetDetail));

export default reduxConnectedAssetDetail;
