import React, { useMemo } from 'react';

import { ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { orderBy } from 'lodash';

import WrapTable, { BoldCell } from 'components/WrapTable';
import useEmployees from 'customHooks/useEmployees';
import useLabourTypes from 'customHooks/useLabourTypes';
import { convertToCurrencyString } from 'utils';

import { SectionHeader } from '../../Components';
import {
  getBillableEventDisplayText,
  getVisitDisplayText,
  tableCurrencyFormatter,
  timeSubmittedDisplay
} from '../../utils';

const getTimesheetData = (visit, timesheet, labourTypes, tech) => {
  const duration = Number.isInteger(timesheet.actualTotalDurationOverride)
    ? timesheet.actualTotalDurationOverride
    : timesheet.actualTotalDuration;
  const durationHours = duration / 3600;
  const durationDisplayText = durationHours > 0 ? `${durationHours} hr` : 'N/A';
  const labourType = labourTypes.find(({ id }) => id === tech?.labourTypeId);

  return {
    technician: `${tech?.name} (${labourType?.name})`,
    timeSubmitted: timeSubmittedDisplay({
      timesheetManualStatus: timesheet?.manualStatus,
      employeeId: tech?.id,
      durationDisplayText,
      duration,
      scheduledFor: visit.scheduledFor
    }),
    payrollHourType: timesheet.hourType?.hourType || '-',
    payrollHourTypeSortOrder: timesheet.hourType?.sortOrder,
    subtotal: timesheet.cost * durationHours,
    visitNumber: visit.visitNumber
  };
};

const getColumns = () => [
  { field: 'source', headerName: 'Source', width: 200 },
  { field: 'technician', headerName: 'Technician' },
  { field: 'timeSubmitted', headerName: 'Time Submitted', width: 125 },
  { field: 'payrollHourType', headerName: 'Payroll Hour Type', width: 140 },
  {
    field: 'subtotal',
    headerName: 'Subtotal',
    width: 100,
    valueFormatter: tableCurrencyFormatter,
    renderCell: BoldCell,
    totalGetter: ({ rows }) => rows.reduce((acc, r) => acc + r.subtotal, 0),
    renderTotal: props => (
      <Typography
        css={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}
        numeric
        variant={TV.BASE}
        weight={TW.BOLD}
      >
        {convertToCurrencyString(props.formattedValue ?? 0)}
      </Typography>
    )
  }
];

const mapVisitsToRows = ({ visits, labourTypes, employees, companyTimezone }) => {
  const rows = [];
  visits.forEach(visit => {
    const timesheetEntries = visit.timesheetEntriesView?.items || [];
    const timesheetEntryBinders = visit.timesheetEntryBinders?.items || [];
    const visitTag = getVisitDisplayText(visit, companyTimezone);

    if (timesheetEntryBinders.length === 0) {
      timesheetEntries
        .filter(t => (t.actualTotalDuration || t.actualTotalDurationOverride) && !t.nonVisitEventId)
        .forEach(timesheet => {
          const tech = employees.find(at => at.id === timesheet.timesheetPeriod?.parentId);
          const timesheetData = getTimesheetData(visit, timesheet, labourTypes, tech);
          rows.push({
            source: visitTag,
            ...timesheetData
          });
        });
    } else {
      timesheetEntryBinders
        .filter(binder => !binder.isDismissed)
        .forEach(binder => {
          const binderTimesheetEntries = binder.timesheetEntries?.items || [];
          const tech = employees.find(at => at.id === binder.employeeId);
          binderTimesheetEntries
            .filter(t => t.actualTotalDurationOverride || t.actualTotalDuration)
            .forEach(timesheet => {
              const timesheetData = getTimesheetData(visit, timesheet, labourTypes, tech);
              rows.push({
                source: visitTag,
                ...timesheetData
              });
            });
        });
    }
  });

  const billableEventRows = [];
  visits.forEach(visit => {
    const nonVisitEvents = visit.nonVisitEvents?.items || [];
    nonVisitEvents.forEach(event => {
      const billableEventTag = getBillableEventDisplayText(event, companyTimezone);
      const eventTimesheetEntries = event.timesheetEntries?.items || [];
      const eventTimesheetEntryBinders = event.timesheetEntryBinders?.items || [];
      if (eventTimesheetEntryBinders.length === 0) {
        eventTimesheetEntries
          .filter(t => t.actualTotalDurationOverride || t.actualTotalDuration)
          .forEach(timesheet => {
            const timesheetData = getTimesheetData(visit, timesheet, labourTypes, event.employee);
            billableEventRows.push({
              source: billableEventTag,
              ...timesheetData
            });
          });
      } else {
        eventTimesheetEntryBinders
          .filter(binder => !binder.isDismissed)
          .forEach(binder => {
            const binderTimesheetEntries = binder.timesheetEntries?.items || [];
            binderTimesheetEntries
              .filter(t => t.actualTotalDurationOverride || t.actualTotalDuration)
              .forEach(timesheet => {
                const timesheetData = getTimesheetData(
                  visit,
                  timesheet,
                  labourTypes,
                  event.employee
                );
                billableEventRows.push({
                  source: billableEventTag,
                  ...timesheetData
                });
              });
          });
      }
    });
  });
  return [
    ...orderBy(rows, ['visitNumber', 'technician', 'payrollHourTypeSortOrder']),
    ...orderBy(billableEventRows, ['visitNumber', 'technician', 'payrollHourTypeSortOrder'])
  ];
};

const JobCloseoutQuotedLaborCosts = ({ visits, companyTimezone, isLoading }) => {
  const [employees, employeesLoading] = useEmployees();
  const [labourTypes, labourTypesLoading] = useLabourTypes();

  const rows = useMemo(
    () =>
      !labourTypesLoading && !employeesLoading
        ? mapVisitsToRows({ visits, labourTypes, employees, companyTimezone })
        : [],
    [companyTimezone, visits, labourTypes, labourTypesLoading, employees, employeesLoading]
  );
  const columns = useMemo(() => getColumns(), []);

  return (
    <ThemeProvider>
      <SectionHeader title="Labor" />
      <WrapTable
        columns={columns}
        enableTotalsRow
        hideFooter={rows.length <= 10}
        loading={isLoading}
        loadingRows={3}
        noDataMessage="No labor data"
        rows={rows}
      />
    </ThemeProvider>
  );
};

JobCloseoutQuotedLaborCosts.defaultProps = {
  isLoading: false
};

export default JobCloseoutQuotedLaborCosts;
