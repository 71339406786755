import { makeStyles } from '@material-ui/core';

import { DispatchTheme } from '@dispatch/Dispatch.styles';

export const useStyles = makeStyles(theme => ({
  root: ({ isVisitCloseoutOn }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: isVisitCloseoutOn ? 'space-between' : 'normal',
    paddingTop: theme.spacing(2)
  }),
  buttonGroup: {
    paddingLeft: theme.spacing(2),
    '& *': {
      textTransform: 'none',
      ...DispatchTheme.mixins.oneLineClamp
    }
  }
}));
