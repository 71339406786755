import React, { useEffect, useState } from 'react';

import { ButtonType, ThemeProvider, Typography } from '@BuildHero/sergeant';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';

import { Spinner } from 'components';
import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import ReceiptAttachmentModal from 'scenes/Procurement/component/ReceiptAttachmentModal';

import { getCloudinaryImageUrl } from 'scenes/ProjectManagement/components/utils';
import { getAttachmentById } from 'services/API/attachment';
import { Mode } from 'utils/constants';
import { download, getFileName } from 'utils/downloadFile';

import useStyles from '../EntityImagesCarousel/styles';

const deleteConfirmContent = {
  body: 'Are you sure you want to delete this attachment?',
  title: 'Delete Attachment',
  buttonLabel: 'Delete Attachment',
  buttonType: ButtonType.ERROR
};

const ImagePreview = ({
  open,
  handleClose,
  attachmentId,
  onDelete,
  disableEdit,
  onEdit,
  user,
  parentData
}) => {
  const classes = useStyles();
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const confirmContext = useConfirmModal();
  const [image, setImage] = useState();

  useEffect(() => {
    const getImageData = async () => {
      const data = await getAttachmentById(attachmentId);
      setImage({
        ...data,
        url: getCloudinaryImageUrl(data.fileUrl)
      });
    };
    getImageData();
  }, [attachmentId, onEdit]);

  const isFileTypePDF = image?.url?.match('.pdf') !== null;

  return (
    <>
      <ThemeProvider>
        <Dialog
          maxWidth="md"
          open={open}
          PaperProps={{
            className: isFileTypePDF ? classes.paperPropsPDF : classes.paperProps
          }}
          scroll="body"
          onClose={handleClose}
        >
          {image?.url ? (
            <>
              <Box className={classes.navContainer}>
                <Box className={classes.leftNavContainer}>
                  <IconButton onClick={handleClose}>
                    <CloseIcon className={classes.muiIcon} />
                  </IconButton>
                  <Typography className={classes.fileName}>{getFileName(image)}</Typography>
                </Box>
                <Box className={classes.rightNavContainer}>
                  <IconButton onClick={() => download(image)}>
                    <GetAppIcon className={classes.muiIcon} />
                  </IconButton>
                  {!disableEdit && (
                    <IconButton onClick={async () => setOpenUploadModal(true)}>
                      <EditIcon className={classes.muiIcon} />
                    </IconButton>
                  )}
                  {!disableEdit && (
                    <IconButton
                      onClick={async () => {
                        if (await confirmContext.confirm(deleteConfirmContent)) {
                          onDelete(image);
                          handleClose();
                        }
                      }}
                    >
                      <DeleteIcon className={classes.muiIcon} />
                    </IconButton>
                  )}
                </Box>
              </Box>
              {isFileTypePDF ? (
                <Grid
                  style={{
                    borderRadius: '4px',
                    width: '100%',
                    padding: 10,
                    height: '100%'
                  }}
                >
                  <iframe height="100%" src={image?.url} title="pdf" width="100%" />
                </Grid>
              ) : (
                <img
                  alt="view"
                  className={classes.imagePreviewStyle}
                  role="presentation"
                  src={image?.url}
                />
              )}
            </>
          ) : (
            <Spinner />
          )}
        </Dialog>
        <ReceiptAttachmentModal
          data={image}
          handleClose={() => setOpenUploadModal(false)}
          mode={Mode.EDIT}
          mutationService={record => onEdit(image?.id, record)}
          open={openUploadModal}
          receiptData={parentData}
          user={user}
        />
      </ThemeProvider>
    </>
  );
};

ImagePreview.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  attachmentId: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  disableEdit: PropTypes.bool,
  user: PropTypes.object,
  parentData: PropTypes.object
};

ImagePreview.defaultProps = {
  open: false,
  handleClose: () => {},
  attachmentId: null,
  disableEdit: false,
  user: {},
  parentData: {}
};

export default ImagePreview;
