import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function Procurement(props) {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SvgIcon {...props}>
      <path d="M22.2265 5.93449C22.5849 5.91454 22.905 6.15733 22.9827 6.50804C23.0571 6.84414 22.8879 7.18454 22.5804 7.32978L21.2725 7.88993V15.4062L21.2733 15.4405L21.2725 15.4749V18.8969C21.2724 19.2661 20.9999 19.5715 20.6452 19.6227L18.8336 19.6225C18.8408 19.703 18.8445 19.7844 18.8445 19.8667C18.8445 21.3517 17.6406 22.5556 16.1556 22.5556C14.6706 22.5556 13.4667 21.3517 13.4667 19.8667C13.4667 19.7844 13.4704 19.703 13.4777 19.6225H8.81135C8.81859 19.703 8.82228 19.7844 8.82228 19.8667C8.82228 21.3517 7.61843 22.5556 6.1334 22.5556C4.64836 22.5556 3.44451 21.3517 3.44451 19.8667C3.44451 19.7844 3.4482 19.703 3.45544 19.6225L1.62727 19.6227C1.28649 19.5735 1.02154 19.2896 1.0013 18.94L1.00001 18.8969V15.4749C0.998051 15.098 1.28188 14.7809 1.65647 14.7416H19.807V7.40871C19.8046 7.13 19.96 6.87556 20.2054 6.74974L20.2497 6.72889L21.9594 5.9956C22.0438 5.9586 22.1344 5.93787 22.2265 5.93449ZM19.8223 15.9556H2.22228V18.4H19.8223V15.9556ZM19.089 10.8222V14.2445H10.5334V10.8222H19.089ZM10.0445 7.24445V14.2445H2.71117V7.24445H10.0445ZM17.8667 1.33334V10.3333H10.5334V1.33334H17.8667Z" />
    </SvgIcon>
  );
}
