import React, { useMemo } from 'react';

import { Field, FieldType, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { Link } from 'react-router-dom';

import WrapTable, { BoldCell } from 'components/WrapTable';
import Routes from 'scenes/Routes';
import { convertToCurrencyString } from 'utils';
import { EntityType, InvoiceStatus, QuoteLineItemType } from 'utils/constants';

import { getMarkupValue } from 'utils/onCalcChange';

import { SectionHeader } from '../../Components';
import { tableCurrencyFormatter } from '../../utils';
import { QuoteStatus } from 'utils/AppConstants';

const SourceLink = ({ formattedValue }) => {
  switch (formattedValue.type) {
    case EntityType.QUOTE: {
      return (
        <Typography variant={TV.BASE} weight={TW.REGULAR}>
          <Link to={Routes.quote({ id: formattedValue.id })}>{formattedValue.label}</Link>
        </Typography>
      );
    }
    case EntityType.INVOICE: {
      return (
        <Typography variant={TV.BASE} weight={TW.REGULAR}>
          <Link to={Routes.invoice({ mode: 'view', id: formattedValue.id })}>
            {formattedValue.label}
          </Link>
        </Typography>
      );
    }
    default: {
      return <></>;
    }
  }
};

const columns = [
  { field: 'source', headerName: 'Source', width: 120, align: 'center', renderCell: SourceLink },
  { field: 'itemName', headerName: 'Item Name', align: 'center' },
  { field: 'description', headerName: 'Description', align: 'center' },
  {
    field: 'unitCost',
    headerName: 'Unit Cost',
    width: 140,
    renderCell: props => <Field type={FieldType.CURRENCY} {...props} />
  },
  {
    field: 'markup',
    headerName: 'Markup',
    width: 140,
    renderCell: props => <Field type={FieldType.PERCENTAGE} {...props} />
  },
  {
    field: 'unitPrice',
    headerName: 'Unit Price',
    width: 140,
    renderCell: props => <Field type={FieldType.CURRENCY} {...props} />
  },
  { field: 'quantity', headerName: 'Quantity', width: 80, align: 'center' },
  {
    field: 'subtotal',
    headerName: 'Subtotal',
    width: 140,
    align: 'center',
    flex: 1,
    totalGetter: ({ rows }) => rows.reduce((acc, r) => acc + r.subtotal, 0),
    renderTotal: ({ formattedValue }) => (
      <Typography
        css={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}
        numeric
        variant={TV.BASE}
        weight={TW.BOLD}
      >
        {convertToCurrencyString(formattedValue ?? 0)}
      </Typography>
    ),
    valueFormatter: tableCurrencyFormatter,
    renderCell: BoldCell
  }
];

const JobCloseoutQuotedPartsAndMaterials = ({ jobData, isLoading }) => {
  const rows = useMemo(
    () =>
      isLoading
        ? []
        : [
            ...jobData.quoteJobs.items.filter(qj => qj.quote.status === QuoteStatus.JOB_ADDED).reduce((partsAndMaterials, qj) => {
              return [
                ...partsAndMaterials,
                ...qj.quote.quoteLineTasks.items.reduce((taskPartAndMaterials, qlt) => {
                  return [
                    ...taskPartAndMaterials,
                    ...qlt.quoteLineProducts.items
                      .filter(p => p.lineItemType === QuoteLineItemType.MATERIAL)
                      .map(p => ({
                        id: p.id,
                        source: {
                          label: `Quote ${qj.quote.customIdentifier || qj.quote.quoteNumber}`,
                          type: EntityType.QUOTE,
                          id: qj.quote.id
                        },
                        itemName: p.name,
                        description: p.description,
                        unitCost: p.unitCost,
                        markup: p.markupValue ?? getMarkupValue(p.unitCost, p.unitPrice),
                        unitPrice: p.unitPrice,
                        quantity: p.quantity,
                        subtotal: p.unitPrice * p.quantity,
                        quoteNumber: parseInt(qj.quote.quoteNumber, 10),
                        quoteCustomIdentifier: qj.quote.customIdentifier
                      }))
                  ];
                }, []),
                ...qj.quote.quoteLineProducts.items
                  .filter(p => p.lineItemType === QuoteLineItemType.MATERIAL)
                  .map(p => ({
                    id: p.id,
                    source: {
                      label: `Quote ${qj.quote.customIdentifier || qj.quote.quoteNumber}`,
                      type: EntityType.QUOTE,
                      id: qj.quote.id
                    },
                    itemName: p.name,
                    description: p.description,
                    unitCost: p.unitCost,
                    markup: p.markupValue ?? getMarkupValue(p.unitCost, p.unitPrice),
                    unitPrice: p.unitPrice,
                    quantity: p.quantity,
                    subtotal: p.unitPrice * p.quantity,
                    quoteNumber: parseInt(qj.quote.quoteNumber, 10),
                    quoteCustomIdentifier: qj.quote.customIdentifier
                  }))
              ];
            }, []),
            ...jobData.inventoryParts.items
              .filter(p => p.invoiceItemId && p.invoiceItem.invoice.status !== InvoiceStatus.VOID)
              .reduce((partsAndMaterials, p) => {
                return [
                  ...partsAndMaterials,
                  {
                    id: p.id,
                    source: {
                      label: `Invoice ${p.invoiceItem.invoice.invoiceNumber}`,
                      type: EntityType.INVOICE,
                      id: p.invoiceItem.parentId
                    },
                    itemName: p.invoiceItem.name,
                    description: p.invoiceItem.description,
                    unitCost: p.invoiceItem.unitCost,
                    markup: p.invoiceItem.markupValue,
                    unitPrice: p.invoiceItem.unitPrice,
                    quantity: p.invoiceItem.quantity,
                    subtotal: p.invoiceItem.unitPrice * p.invoiceItem.quantity,
                    invoiceNumber: p.invoiceItem.invoiceNumber
                  }
                ];
              }, [])
          ].sort((a, b) => {
            const quoteLabelA = a.quoteCustomIdentifier || a.quoteNumber;
            const quoteLabelB = b.quoteCustomIdentifier || b.quoteNumber;

            if (quoteLabelA && !quoteLabelB) return -1;
            if (!quoteLabelA && quoteLabelB) return 1;
            if (quoteLabelA < quoteLabelB) return -1;
            if (quoteLabelA > quoteLabelB) return 1;
            if (a.invoiceNumber < b.invoiceNumber) return -1;
            if (a.invoiceNumber > b.invoiceNumber) return 1;
            if (a.itemName < b.itemName) return -1;
            if (a.itemName > b.itemName) return 1;
            return 0;
          }),
    [jobData, isLoading]
  );

  return (
    <ThemeProvider>
      <SectionHeader title="Parts & Materials" />
      <WrapTable
        columns={columns}
        enableTotalsRow
        hideFooter={rows.length < 11}
        loading={isLoading}
        loadingRows={3}
        noDataMessage="No Parts & Materials"
        rows={rows}
      />
    </ThemeProvider>
  );
};

JobCloseoutQuotedPartsAndMaterials.defaultProps = {
  isLoading: false
};

export default JobCloseoutQuotedPartsAndMaterials;
