import React, { useMemo } from 'react';

import { Button, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { Box, Grid, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import uuid from 'uuid';

import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import { reorder } from 'utils';

import { getArchiveConfirmContent } from '../../../utils';

import PayrollHourTypeCard from './PayrollHourTypeCard';

const PayrollHourTypesTable = ({
  adpExportEnabled,
  billingHourTypes,
  loading,
  mapPayrollHourToBilling,
  payrollHourTypesFormik
}) => {
  const theme = useTheme();
  const confirmContext = useConfirmModal();

  const billingHourTypeOptions = useMemo(
    () => [
      { label: 'None', value: null },
      ...billingHourTypes.map(({ id, hourType }) => ({ label: hourType, value: id }))
    ],
    [billingHourTypes]
  );

  const creatingNewPayrollHourType =
    payrollHourTypesFormik.values[payrollHourTypesFormik.values.length - 1]?.hourType === '';

  const resetSortOrder = unorderedHourTypes =>
    unorderedHourTypes.map((g, i) => ({ ...g, sortOrder: i }));

  const onRemovePayrollHourType = ({ index }) => {
    payrollHourTypesFormik.setValues(
      resetSortOrder(payrollHourTypesFormik.values.filter((t, i) => i !== index))
    );
  };

  const onModifyPayrollHourType = async ({ field, value, index }) => {
    if (
      field !== 'isArchived' ||
      (await confirmContext.confirm(
        getArchiveConfirmContent(value, payrollHourTypesFormik.values[index]?.hourType)
      ))
    ) {
      payrollHourTypesFormik.setFieldValue(`[${index}].${field}`, value);
    }
  };

  const moveModifier = ({ source, destination }) => {
    if (!Number.isInteger(source?.index) || !Number.isInteger(destination?.index)) return; // invalid drag
    payrollHourTypesFormik.setValues(
      resetSortOrder(
        reorder({
          arr: payrollHourTypesFormik.values,
          source: source.index,
          destination: destination.index
        })
      )
    );
  };

  const addPayrollHourType = () => {
    payrollHourTypesFormik.setValues([
      ...payrollHourTypesFormik.values,
      {
        id: uuid.v4(),
        hourType: '',
        hourTypeAbbreviation: '',
        billingHourTypeId: null,
        isAutofill: false,
        intacctAccountNumber: null,
        intacctNonBillableAccountNumber: null,
        sortOrder: payrollHourTypesFormik.values.length,
        isArchived: false,
        unsaved: true
      }
    ]);
  };

  return (
    <ThemeProvider>
      <Grid item style={{ padding: 0, width: '100%' }}>
        <Box component="div">
          <DragDropContext onDragEnd={moveModifier}>
            <Droppable droppableId="droppable" isDropDisabled={loading}>
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {payrollHourTypesFormik.values.map((item, index) => (
                    <PayrollHourTypeCard
                      adpExportEnabled={adpExportEnabled}
                      billingHourTypeOptions={billingHourTypeOptions}
                      index={index}
                      isDragDisabled={loading}
                      item={item}
                      mapPayrollHourToBilling={mapPayrollHourToBilling}
                      payrollHourTypes={payrollHourTypesFormik.values}
                      onModifyPayrollHourType={onModifyPayrollHourType}
                      onRemovePayrollHourType={onRemovePayrollHourType}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {!creatingNewPayrollHourType && (
              <Button type="leading" onClick={addPayrollHourType}>
                <AddIcon css={{ height: theme.spacing(3) }} />
                <Typography variant={TV.BASE} weight={TW.MEDIUM}>
                  Payroll Hour Type
                </Typography>
              </Button>
            )}
          </DragDropContext>
        </Box>
      </Grid>
    </ThemeProvider>
  );
};

PayrollHourTypesTable.propTypes = {
  adpExportEnabled: PropTypes.bool,
  billingHourTypes: PropTypes.array,
  loading: PropTypes.bool,
  mapPayrollHourToBilling: PropTypes.bool,
  payrollHourTypesFormik: PropTypes.object
};

PayrollHourTypesTable.defaultProps = {
  adpExportEnabled: false,
  billingHourTypes: [],
  loading: false,
  mapPayrollHourToBilling: false,
  payrollHourTypesFormik: {}
};
export default PayrollHourTypesTable;
