import gql from 'graphql-tag';

import { updateWatchedVisitQueries } from '@dispatch/Dispatch.utils';
import { emptyVisitDetailsFragment, visitDetailsFragment } from '@dispatch/fragments';
import getOptimisticVisit from '@dispatch/mutations/getOptimisticVisit';
import useExtendedMutation from 'customHooks/useExtendedMutation';
import mergeDeep from 'utils/mergeDeep';

const ADD_VISIT_MUTATION = gql`
  mutation addVisitToJob($partitionKey: String!, $input: AddVisitToJob!) {
    addVisitToJob(partitionKey: $partitionKey, data: $input) {
      ${visitDetailsFragment}
    }
  }
`;

const serializer = ({ tenantId, tenantCompanyId, visit, job }) => {
  const {
    extraTechs,
    extraTechsNumber,
    primaryTechId,
    scheduledFor,
    actualDuration,
    ...visitInput
  } = visit;
  return {
    variables: {
      partitionKey: tenantId,
      input: {
        tenantCompanyId,
        jobId: job.id,
        primaryTechnicianId: primaryTechId,
        extraTechnicians: {
          requiredCount: extraTechsNumber || 0,
          technicianIds: extraTechs || []
        },
        visitDateTime: {
          actualDuration: `${actualDuration} minutes`,
          scheduledFor
        },
        ...visitInput,
        job: undefined
      }
    }
  };
};

const optimisticResponseFactory = ({ visit, job }) => {
  const optimisticVisit = getOptimisticVisit({ visit, job });
  return { addVisitToJob: mergeDeep(emptyVisitDetailsFragment, optimisticVisit) };
};

const useAddVisit = () => {
  return useExtendedMutation(ADD_VISIT_MUTATION, {
    serializer,
    optimisticResponseFactory,
    update: (cache, { data }) => {
      updateWatchedVisitQueries({
        cache,
        updatedVisit: data.addVisitToJob
      });
    }
  });
};

export default useAddVisit;
