import React from 'react';

import { Checkbox, Grid, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import moment from 'moment';

import ErrorBoundaries from 'scenes/Error';
import AppConstants from 'utils/AppConstants';

import styles from './styles';

const ReviewSummary = props => {
  const { classes, freezeEdit, summary } = props;
  const { dateAdded } = summary;
  const { lastUpdatedDateTime, createdDateTime } = summary;
  // when items in review report is not edited, both created and updated time should be same
  const isModified = lastUpdatedDateTime !== createdDateTime;
  // dateAdded will be null for items added directly in review report
  const modifiedDate =
    isModified || !dateAdded ? parseInt(summary.lastUpdatedDateTime, 10) / 1000 : dateAdded;

  return (
    <ErrorBoundaries>
      <Grid alignItems="center" container direction="row" justify="space-between">
        <Grid item lg={11} md={11} sm={11} xl={11} xs={11}>
          <Grid alignItems="center" container direction="row" justify="space-between">
            <Grid item lg={10} md={10} sm={10} xl={10} xs={10}>
              <Typography className={classes.content}>{summary?.summary || ''}</Typography>
              {summary?.lastUpdatedBy && (
                <Typography className={classes.footer}>
                  Last edited by {summary.addedBy || summary.lastUpdatedBy} on &nbsp;
                  {moment.unix(modifiedDate).format(AppConstants.DATETIME_FORMAT)}
                </Typography>
              )}
            </Grid>
            <Grid item lg={1} md={1} sm={1} xl={1} xs={1}>
              {!freezeEdit && (
                <Tooltip title="Edit">
                  <IconButton
                    aria-label="Edit"
                    className={classes.buttonHover}
                    onClick={() => props.editAction()}
                  >
                    <EditIcon className={classes.iconColor} color="secondary" />
                  </IconButton>
                </Tooltip>
              )}
              {!freezeEdit && (
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="Delete"
                    className={classes.buttonHover}
                    onClick={() => props.deleteAction()}
                  >
                    <DeleteIcon className={classes.iconColor} color="secondary" />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={1} md={1} sm={1} xl={1} xs={1}>
          <Checkbox
            checked={props.includeInvoice}
            defaultChecked={props.defChecked}
            disabled={freezeEdit}
            onChange={event =>
              props.changeIncludeInvoice && props.changeIncludeInvoice(event.target.checked)
            }
          />
        </Grid>
      </Grid>
    </ErrorBoundaries>
  );
};

export default withStyles(styles, { withTheme: true })(ReviewSummary);
