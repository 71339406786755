import { object, string } from 'yup';

import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';
import { nameValidationField, toLengthTest } from 'meta/validations';
import { convertionJsonValidationToObjValidation } from 'scenes/Customer/PropertyDetail/helpers';
import { BILLING_INFO_TOOLTIP, EntityType } from 'utils/constants';

const minFirstRowItemWidth = 200;
const minItemWidth = 160;
const padding = 12;

const mainSectionItemsViewMode = ({ formData, usePropertyPricebooks }) => {
  return [
    {
      options: {
        grow: 1,
        padding,
        fontWeight: 'bold',
        fontSize: '18px',
        minWidth: minItemWidth
      },
      contents: [
        {
          component: {
            default: 'FieldWithLabel',
            edit: 'SelectInput'
          },
          source: { default: 'customerPropertyTypeValue', edit: 'customerPropertyTypeId' },
          options: {
            label: 'Property Type',
            valueSet: formData.customerPropertyTypes,
            valuesToSet: [
              {
                customerPropertyTypeValue: 'label'
              }
            ],
            enableSort: false
          }
        }
      ]
    },
    ...(usePropertyPricebooks
      ? [
          {
            options: {
              grow: 1,
              padding,
              fontWeight: 'bold',
              fontSize: '18px',
              minWidth: minItemWidth
            },
            contents: [
              {
                component: {
                  default: 'FieldWithLabel',
                  edit: 'SelectInput'
                },
                source: { default: 'priceBook.name', edit: 'priceBookId' },
                options: {
                  isSearchable: true,
                  label: 'Pricebook',
                  valueSet: formData.priceBookOptions,
                  valuesToSet: [
                    {
                      priceBookValue: 'label'
                    }
                  ],
                  enableSort: false
                }
              }
            ]
          }
        ]
      : []),
    {
      options: {
        grow: 1,
        padding,
        fontWeight: 'bold',
        fontSize: '18px',
        minWidth: minItemWidth
      },
      contents: [
        {
          component: {
            default: 'FieldWithLabel',
            edit: 'SelectInput'
          },
          source: { default: 'taxRateLabel', edit: 'taxRateId' },
          options: {
            label: 'Tax Code',
            isSearchable: true,
            enableSort: false,
            valueSet: formData.taxRateOptions || [],
            valuesToSet: [
              {
                taxRateLabel: 'label'
              }
            ]
          },
          dependentField: {
            fieldName: 'isTaxable',
            expectedValue: false,
            operation: 'bool',
            action: 'DISABLE'
          }
        }
      ]
    },
    {
      options: {
        grow: 1,
        direction: 'column',
        padding,
        fontWeight: 'bold',
        fontSize: '18px',
        minWidth: minItemWidth
      },
      contents: [
        {
          component: 'LabelControl',
          source: 'Taxable'
        },
        {
          component: {
            default: 'ReadOnlyCheckboxInput',
            edit: 'CheckboxInput'
          },
          source: 'isTaxable'
        }
      ]
    },
    {
      options: {
        grow: 1,
        padding,
        fontWeight: 'bold',
        fontSize: '18px',
        minWidth: minItemWidth
      },
      contents: [
        {
          component: {
            default: 'FieldWithLabel'
          },
          source: 'jobCompletedCount',
          options: {
            label: 'Jobs Completed'
          }
        }
      ]
    }
  ];
};

export const mainSectionLayout = ({ formData, usePropertyPricebooks }, customFormMeta = {}) => ({
  fields: {},
  layouts: {
    default: {
      options: {
        direction: 'column',
        grow: 0
      },
      contents: [
        {
          options: {
            direction: 'row',
            grow: 0,
            flexWrap: 'wrap'
          },
          contents: mainSectionItemsViewMode({ formData, usePropertyPricebooks })
        },
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                padding
              },
              contents: [
                {
                  component: {
                    default: 'AddNotesView',
                    edit: 'AddNotesView'
                  },
                  source: 'propertyInstructions'
                }
              ]
            }
          ]
        },
        ...(customFormMeta?.componentArr || [])
      ]
    }
  },
  validation: object({
    taxRateId: string().when('isTaxable', {
      is: val => !!val,
      then: string().required('Tax is required if taxable.')
    }),
    ...convertionJsonValidationToObjValidation(customFormMeta)
  })
});

export default mainSectionLayout;

const sidebarItems = ({
  formData,
  setBillingAddress,
  setSameBillingAddress,
  addressEditAndViewProps,
  handlePropertyAddressEdit
}) => {
  return [
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        paddingTop: 0,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          component: {
            default: 'LinkButton'
          },
          source: 'customer',
          options: {
            label: 'Customer'
          }
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        paddingTop: 0,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          component: {
            default: 'FieldWithLabel',
            edit: 'TextInput'
          },
          source: 'companyName',
          options: {
            label: 'Property',
            isRequired: true
          }
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        paddingTop: 0,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          component: {
            default: 'AddressEditAndView',
            edit: 'AddressEditAndView'
          },
          source: { default: 'propertyAddress', edit: 'propertyAddressFields' },
          options: {
            label: 'Property Address',
            onEdit: handlePropertyAddressEdit,
            enableEdit: { default: false, edit: true },
            useSideSectionStyling: true,
            ...addressEditAndViewProps
          }
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        paddingTop: 0,
        minWidth: minItemWidth
      },
      contents: [
        {
          component: {
            default: 'LocationView'
          },
          source: 'location'
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        minWidth: minItemWidth
      },
      contents: [
        {
          options: {
            label: 'Same as customer',
            onChange: setSameBillingAddress
          },
          component: {
            edit: 'CheckboxInput',
            view: null
          },
          source: 'sameAddress'
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding
      },
      contents: [
        {
          component: {
            edit: 'TextInput',
            view: 'FieldWithLabel'
          },
          options: {
            label: 'Billing Information',
            useSideSectionStyling: true,
            lines: 2,
            tooltip: BILLING_INFO_TOOLTIP,
            placeholder: 'Customer Name\nBill To',
            linesMax: 4
          },
          source: 'billTo'
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          options: {
            dependentField: {
              fieldName: 'sameAddress',
              expectedValue: false,
              operation: 'bool',
              action: 'DISABLE'
            },
            label: 'Billing Customer',
            required: true,
            placeholder: 'Search...',
            searchIndexName: defaultSearchIndex,
            searchResultDisplayFields: ['customerName'],
            setItemName: 1,
            filters: [
              {
                attribute: 'entityType',
                valueArray: ['Customer']
              },
              {
                attribute: 'status',
                valueArray: ['active']
              }
            ],
            onChange: setBillingAddress
          },
          component: {
            edit: 'AlgoliaSearchWrapper',
            view: 'LinkButton'
          },
          source: {
            edit: 'billingCustomerName',
            view: 'billingCustomer'
          }
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          component: {
            default: 'FieldWithLabel'
          },
          source: 'billingAddress',
          options: {
            label: 'Billing Address',
            useSideSectionStyling: true
          }
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        paddingTop: 0,
        minWidth: minItemWidth
      },
      contents: [
        {
          component: {
            default: 'LocationView'
          },
          source: 'billingAddressLocation'
        }
      ]
    },
    {
      options: {
        grow: 0,
        padding,
        fontWeight: 'bold',
        fontSize: '18px',
        minWidth: minItemWidth * 1.5
      },
      contents: [
        {
          component: {
            default: 'FieldWithLabel',
            edit: 'TextInput'
          },
          source: 'accountNumber',
          options: {
            label: 'Account Number',
            fullWidth: true
          }
        }
      ]
    }
  ];
};

export const sidebarSection = options => ({
  fields: {},
  layouts: {
    default: {
      options: {
        direction: 'column',
        grow: 0
      },
      contents: sidebarItems(options)
    }
  },
  validation: object({
    ...nameValidationField(
      'companyName',
      EntityType.CUSTOMER_PROPERTY,
      'Property Name is required'
    ),
    billingCustomerName: string()
      .nullable()
      .required('Billing Customer is required'),
    billTo: string()
      .nullable()
      .test(toLengthTest())
  })
});
