import React, { Component } from 'react';

import { Grid } from '@material-ui/core';
import List from '@material-ui/core/List';

import { connectHits } from 'react-instantsearch-dom';

import AppConstants from 'utils/AppConstants';

import HitComponent from '../Hits';

import ComponentStrategy from './components';
import RangeInput from './components/CustomRangeInput';

class Results extends Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      showResults: true,
      entityType: '',
      sortKey: '',
      searchQuery: props.query
    };
  }

  componentDidMount = () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
    this.setState({ entityType: '', sortKey: '' });
  };

  componentDidUpdate = prevProps => {
    if (this.props.query !== prevProps.query && this.mounted) {
      this.setState({ showResults: true, searchQuery: this.props.query });
    }
  };

  // eslint-disable-next-line react/sort-comp
  hits = connectHits(props => {
    const { hits } = props;
    // filter is to move inactive entries to the end of search results.
    return (
      <>
        {hits
          .filter(x => x.status !== AppConstants.INACTIVE)
          .concat(hits.filter(x => x.status === AppConstants.INACTIVE))
          .map(hit => (
            <HitComponent
              closeSearchResultModal={this.props.closeSearchResultModal}
              hit={hit}
              key={hit.id}
              showResultSection={this.toggleResults}
            />
          ))}
      </>
    );
  });

  toggleResults = props => {
    const { entityType, sortKey } = props;
    if (this.mounted) {
      const { showResults } = this.state;
      this.setState({ showResults: !showResults, entityType, sortKey });
    }
  };

  details = () => {
    const { entityType, sortKey } = this.state;
    if (!entityType || !sortKey) {
      return (
        <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
          No definition available
        </Grid>
      );
    }

    const Detail = ComponentStrategy[entityType];
    return (
      Detail && <Detail {...this.state} backToResult={() => this.setState({ showResults: true })} />
    );
  };

  render() {
    const { WrappedRefinementList } = this.props;
    const { showResults, entityType, sortKey } = this.state;
    const CustomHits = this.hits;

    return (
      <Grid container>
        {showResults && (
          <Grid item lg={9} md={9} sm={9} xl={9} xs={9}>
            <List component="nav">
              <CustomHits />
            </List>
          </Grid>
        )}
        {showResults && (
          <Grid
            item
            lg={3}
            md={3}
            sm={3}
            style={{ backgroundColor: '#f6f6f6', padding: 20 }}
            xl={3}
            xs={3}
          >
            <Grid container direction="column">
              <WrappedRefinementList attribute="entityType" bottomBorder title="SHOWING" />
              <WrappedRefinementList attribute="name" bottomBorder title="PEOPLE" />
              <WrappedRefinementList attribute="customerName" bottomBorder title="CUSTOMER" />
              <WrappedRefinementList attribute="customerPropertyName" title="PROPERTIES" />
              <WrappedRefinementList
                attribute="configuredeactivated"
                bottomBorder
                title="DEACTIVATED"
              />
              <RangeInput attribute="rangeKey" />
            </Grid>
          </Grid>
        )}
        {!showResults && entityType && sortKey && this.details()}
      </Grid>
    );
  }
}

export default Results;
