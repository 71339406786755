import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';

import Modal from '../index';
// import Spinner from 'components/Spinners/CircularDeterminate';

const confirmDialogButtons = {
  save: {
    label: 'Confirm',
    style: { backgroundColor: '#b00020', color: '#ffffff' },
    buttonType: 'contained',
    behavior: {
      new: true,
      edit: true
    }
  },
  cancel: {
    label: 'Cancel',
    color: 'secondary',
    buttonType: 'outlined',
    behavior: {
      new: true,
      edit: true
    }
  }
};

class ConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinnerStatus: false
    };
  }

  componentWillUnmount = () => {
    confirmDialogButtons.save.style = { backgroundColor: '#b00020', color: '#ffffff' };
    this.setState(prevState => ({ ...prevState, spinnerStatus: false }));
  };

  saveAction = async btn => {
    const localBtn = btn;
    // localBtn.disabled = true;
    localBtn.style = { backgroundColor: 'rgba(0, 0, 0, 0.12)', color: '#ffffff' };
    this.setState(prevState => ({ ...prevState, spinnerStatus: true }));
    if (this.props.confirm) await this.props.confirm();
    // TODO: identify better ways to turn off spinner
    // setTimeout(() => {
    this.setState(prevState => ({ ...prevState, spinnerStatus: false }));
    confirmDialogButtons.save.disabled = false;
    confirmDialogButtons.save.style = { backgroundColor: '#b00020', color: '#ffffff' };
    // }, 3000);
  };

  cancelAction = () => {
    confirmDialogButtons.save.disabled = false;
    confirmDialogButtons.save.style = { backgroundColor: '#b00020', color: '#ffffff' };
    this.setState(prevState => ({ ...prevState, spinnerStatus: false }));
    this.props.cancel();
  };

  render() {
    const message = this.props.override
      ? this.props.message
      : `Are you sure you want to delete ${this.props.message}?`;

    confirmDialogButtons.save.action = async () => this.saveAction(confirmDialogButtons.save);
    confirmDialogButtons.cancel.action = () => this.cancelAction();
    return (
      <Modal
        buttons={confirmDialogButtons}
        handleClose={this.props.cancel}
        keepMounted
        open={this.props.open}
        spinnerStatus={this.state.spinnerStatus}
        width={696}
      >
        {/* {this.state.spinnerStatus && <Spinner />} */}
        <Typography style={{ whiteSpace: 'pre-line' }} variant="subtitle1">
          {message}
        </Typography>
      </Modal>
    );
  }
}

export default ConfirmModal;
