import React, { useState } from 'react';

import { Button, TV, TW, Typography } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';

import AttachmentModal from 'components/AttachmentSection/AddAttachment';
import StorageService from 'services/StorageService';

const styles = {
  attachmentLabel: { display: 'flex', flexDirection: 'column' },
  header: { display: 'flex', justifyContent: 'space-between', paddingBottom: 8 },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
};

const CustomAttachmentComponent = props => {
  const { form } = props;
  const { attachments } = form.values;
  const [openAttachment, setOpenAttachment] = useState(false);

  const handleFileClick = async item => {
    const storageService = new StorageService();
    const attachmentUrl = await storageService.getFile(item.fileUrl);
    window.open(attachmentUrl);
  };

  const handleDeleteFile = item => {
    const modifiedAttachments = attachments.filter(a => a.fileUrl !== item.fileUrl);
    form.setFieldValue('attachments', modifiedAttachments);
  };

  return (
    <div>
      <div css={styles.header}>
        <div css={styles.attachmentLabel}>
          <Typography style={{ fontSize: 16 }} weight={TW.BOLD}>
            Attachments
          </Typography>
        </div>
        <Button
          size="small"
          startIcon={<AddCircleOutlineIcon />}
          type="tertiary"
          onClick={() => setOpenAttachment(true)}
        >
          Add Attachments
        </Button>
      </div>
      {attachments.map(item => (
        <div css={styles.itemContainer} key={item?.id}>
          <Typography
            style={{ cursor: 'pointer' }}
            underlined
            variant={TV.BASE}
            weight={TW.REGULAR}
            onClick={() => handleFileClick(item)}
          >
            {item?.customFileName || item?.fileName || '-'}
          </Typography>
          <Button
            endIcon={<CloseIcon />}
            style={{ padding: 8 }}
            type="leading"
            onClick={() => handleDeleteFile(item)}
          />
        </div>
      ))}
      <AttachmentModal
        enableThumbnails
        handleClose={() => setOpenAttachment(false)}
        maxFileSize={10000000}
        maxFileSizeWarningLabel="Attachments over 10MB cannot be sent with the quote."
        mutationService={value => form.setFieldValue('attachments', [...attachments, value])}
        open={openAttachment}
        parent={{}}
        readOnly={false}
      />
    </div>
  );
};

export default CustomAttachmentComponent;
