import React from 'react';

import { shape, string } from 'prop-types';
import { Link } from 'react-router-dom';

const AssetLink = props => <Link to={`/asset/view/${props.row?.id}`}>{props.formattedValue}</Link>;
AssetLink.propTypes = {
  row: shape({
    id: string
  }).isRequired,
  formattedValue: string.isRequired
};

export default AssetLink;
