/* eslint-disable react/prop-types */
/* eslint-disable no-return-await */
import React, { useCallback, useEffect, useState } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import Typography from '@material-ui/core/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DefaultButton from 'components/Buttons/DefaultButton';
import FullScreenModal from 'components/FullScreenModal';
import { snackbarOn } from 'redux/actions/globalActions';
import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';
import AttachmentsV1 from 'scenes/ProjectManagement/components/Attachments';
import AttachmentsV2 from 'scenes/ProjectManagement/components/AttachmentsV2';
import CKEditor from 'scenes/ProjectManagement/components/CKEditor';
import { handleUploadAttachments } from 'scenes/ProjectManagement/components/utils';
import { insertEmail, sendEmail, updateEmail } from 'services/API/sendEmail';
import { sentryException } from 'services/Logger';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import emailLayout from './EmailModal.layout';
import useStyles from './EmailModal.styles';

const EmailModal = props => {
  const classes = useStyles();
  const Attachments = useFlags()[FeatureFlags.PM_FILE_SYSTEM] ? AttachmentsV2 : AttachmentsV1;
  const {
    data,
    previewPdfTitle,
    user,
    open,
    project,
    emailData,
    handleClose,
    close,
    beforeSendEmail,
    onEmailSent
  } = props;
  const [warnAboutSendTo, setWarnAboutSendTo] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [formService, setFormService] = useState();
  const [body, setBody] = useState('');
  const [subject, setSubject] = useState('');
  const [sendDisabled, setSendDisabled] = useState(true);
  const [sendTo, setSendTo] = useState([]);
  const [cc, setCC] = useState([]);
  const [bcc, setBCC] = useState([]);
  const flags = useFlags();

  const DUMMY_URL = 'dummy';
  const dummyPreviewPdf = {
    fileName: previewPdfTitle,
    fileUrl: DUMMY_URL,
    type: 'application/pdf'
  };

  const getSendTo = useCallback(
    item => {
      setSendTo(item?.SendTo?.email ? [item.SendTo] : null);

      if (open && warnAboutSendTo && item?.SendTo && !item?.SendTo?.email) {
        setWarnAboutSendTo(false);
        props.snackbarOn(
          'error',
          'Send To contact does not have an associated email, please select another contact'
        );
        setSendTo([]);
      }
    },
    [open, props, warnAboutSendTo]
  );

  useEffect(() => {
    getSendTo(data);
  }, [data, getSendTo]);

  useEffect(() => {
    if (emailData?.body) {
      setBody(emailData.body);
    }

    if (emailData?.subject) {
      setSubject(emailData.subject);
    }
  }, [emailData]);

  // Ensure that the warning message pops up again if the email
  // modal is closed and re-opened.
  useEffect(() => {
    if (!open) {
      // reset state vars
      setBody(emailData.body);
      setSubject(emailData.subject);
      getSendTo(data);
      setCC([]);
      setBCC([]);
      setAttachments([]);
    }

    if (!warnAboutSendTo && open) {
      setWarnAboutSendTo(true);
    }
  }, [open]);

  const formatAttachmentsForSend = () => {
    return attachments
      .filter(attachment => attachment.fileUrl !== DUMMY_URL)
      .map(attachment => ({
        source: attachment.fileUrl || attachment.url,
        url: attachment.url || attachment.fileUrl,
        type: attachment.type || attachment.fileType,
        fileName: attachment.fileName
      }));
  };

  const handleUploadFileList = (newAttachments, selectedImages, selectedFiles) => {
    const combinedAttachments = handleUploadAttachments(
      newAttachments,
      selectedImages,
      selectedFiles,
      attachments,
      user
    );
    if (combinedAttachments && combinedAttachments.length) {
      setAttachments(
        combinedAttachments.filter(
          attachment => attachment.fileUrl !== DUMMY_URL && !attachment._deleted
        )
      );
    }
  };

  const prepareRecipients = recipients => {
    return recipients
      .map(element => (typeof element === 'string' ? element : element.email))
      .filter(element => element != null);
  };

  const handleSendEmail = async () => {
    let emailResult = false;
    setIsSubmitting(true);
    try {
      const uploadedPreviewData = await beforeSendEmail();
      const formattedAttachments = [uploadedPreviewData, ...formatAttachmentsForSend()];
      const finalAttachments = {
        ...formattedAttachments
          .filter(attachment => attachment.fileUrl || attachment.url)
          .reduce(
            (finalObj, currAttachment) => ({
              ...finalObj,
              [currAttachment.fileName]: currAttachment
            }),
            {}
          )
      };

      const emailReplies = flags[FeatureFlags.EMAIL_REPLIES];

      let fromAddress = 'noreply@buildops.com';

      if (emailReplies) {
        fromAddress = `${user.email.split('@')?.[0]}@${process.env.REACT_APP_env ??
          'dev'}.buildops.com`;
      } else if (flags[FeatureFlags.EMAIL_REPLY_ADDRESS]) {
        fromAddress = `${user.email.split('@')?.[0]}@buildops.com`;
      }

      const initialPayload = {
        from: fromAddress,
        replyTo: emailReplies ? fromAddress : user.email,
        parentId: data.id,
        fromName: user.displayName || undefined,
        importance: 'High',
        subject,
        cc: cc?.length ? prepareRecipients(cc) : undefined,
        bcc: bcc?.length ? prepareRecipients(bcc) : undefined,
        to: prepareRecipients(sendTo || []),
        html: `<!DOCTYPE html><html><body>${body}</body></html>`
      };
      const insertResult = await insertEmail(initialPayload);

      const SNSPayload = {
        ...initialPayload,
        ...insertResult.data,
        attachments: finalAttachments
      };
      const { data: emailResponse } = await sendEmail(SNSPayload);

      let DBPayload = {
        ...SNSPayload,
        sendCc: SNSPayload.cc?.join(';'),
        sendBcc: SNSPayload.bcc?.join(';'),
        cc: undefined,
        bcc: undefined,
        html: undefined,
        to: undefined,
        sendTo: SNSPayload.to?.join(';'),
        body: `<!DOCTYPE html><html><body>${body}</body></html>`,
        attachments: formattedAttachments
          .filter(attachment => attachment.fileUrl)
          .map(attachment => ({
            ...attachment,
            source: undefined
          }))
      };
      if (emailReplies) {
        DBPayload = {
          ...DBPayload,
          from: user.email
        };
      }

      if (emailResponse && !emailResponse.errno) {
        emailResult = true;
        props.snackbarOn('success', 'Email sent successfully');
        await updateEmail(DBPayload.id, DBPayload);
      } else {
        props.snackbarOn('error', 'Unable to send email');
      }
    } catch (e) {
      sentryException(e, 'Unable to send email');
      props.snackbarOn('error', 'Unable to send email');
    } finally {
      setIsSubmitting(false);
      if (emailResult) onEmailSent();
      handleClose(emailResult);
    }
  };

  const handleDisableChange = ({
    subject: subjectPassed,
    sendTo: sendToPassed,
    cc: ccPassed,
    bcc: bccPassed
  }) => {
    setSendDisabled(
      !subjectPassed || !(sendToPassed?.length || ccPassed?.length || bccPassed?.length)
    );
  };

  const EmailBodyTextField = ({ field }) => {
    return <CKEditor initialData={field?.value} updateDataFn={setBody} />;
  };

  const FormLabel = ({ options }) => {
    return <Typography className={classes.to}>{options.label}: </Typography>;
  };

  return (
    <FullScreenModal
      handleClose={close}
      modalHeaderButtons={[
        <DefaultButton
          disabled={isSubmitting || sendDisabled}
          label="SEND"
          showSpinner={isSubmitting}
          variant="containedPrimary"
          onClick={() => formService?.submit()}
        />
      ]}
      open={open}
      title={emailData?.modalTitle || 'New Email'}
    >
      <div className={classes.container}>
        <div className={classes.emailContainer}>
          <MUIForm
            configuration={emailLayout(project, setSendTo, setCC, setBCC, ({ currentValue }) =>
              setSubject(currentValue)
            )}
            customComponents={{
              EmailBodyTextField,
              FormLabel,
              SearchBar
            }}
            data={{
              sendTo,
              subject,
              body
            }}
            onComplete={handleSendEmail}
            onCreateService={service => setFormService(service)}
            onFormChange={handleDisableChange}
          />
        </div>
        <div className={classes.attachmentsContainer}>
          <Attachments
            attachedFileCount={attachments?.length || 1}
            attachedFiles={[dummyPreviewPdf, ...attachments]}
            chunkSize={5}
            imageHeight={100}
            imageWidth={100}
            noTitle
            smallButton
            uploadFileList={handleUploadFileList}
          />
        </div>
      </div>
    </FullScreenModal>
  );
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});
const ReduxEmailModal = connect(mapStateToProps, mapDispatcherToProps)(EmailModal);

EmailModal.propTypes = {
  data: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
  user: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  snackbarOn: PropTypes.func,
  project: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  previewPdfTitle: PropTypes.string.isRequired,
  emailData: PropTypes.object,
  onEmailSent: PropTypes.func.isRequired
};

EmailModal.defaultProps = {
  snackbarOn: () => {},
  handleClose: () => {},
  emailData: {}
};

export default connect(mapStateToProps)(ReduxEmailModal);
