import { sortBy } from 'lodash';
import moment from 'moment';

import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';

export const getProductivitySummary = (data = []) => {
  const formattedData = data.map(productivityPhase => {
    const { phase, installedUnits, unproductiveHours } = productivityPhase;
    return {
      phaseNumber: phase.number,
      phaseName: phase.name,
      installedUnits,
      unproductiveHours
    };
  });
  return sortBy(formattedData, ['phaseNumber']);
};

export const uploadPDF = async ({ title, tenantId, pdfFile }) => {
  const currentDayFileFormat = moment().format('MM-DD-YYYY');
  const fileName = `${title}_${currentDayFileFormat}.pdf`;

  try {
    const storageService = new StorageService();
    const response = await storageService.uploadFile(
      pdfFile,
      `${tenantId}/${fileName}`,
      e => e,
      'application/pdf'
    );

    return {
      response,
      fileName
    };
  } catch (error) {
    Logger.error(error);
    return {};
  }
};
