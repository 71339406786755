import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { logErrorWithCallback } from 'utils';

export const getClockinBreakEntries = gql`
  query getClockinBreakEntries($employeeId: String!, $startDayCompanyTZ: BigInt!) {
    getClockinBreakEntries(employeeId: $employeeId, startDayCompanyTZ: $startDayCompanyTZ) {
      id
      entityType
      tenantId
      tenantCompanyId
      employeeId
      startDayCompanyTZ
      startTime
      startTimeTimezone
      endTime
      endTimeTimezone
      type
    }
  }
`;

const useClockinBreakEntries = ({ employeeId, startDayCompanyTZ }, options = {}) => {
  const { data, loading, refetch } = useExtendedQuery(getClockinBreakEntries, {
    variables: { employeeId, startDayCompanyTZ },
    transform: result => result?.getClockinBreakEntries,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !employeeId || !startDayCompanyTZ,
    ...options,
    onError: (error, snackbar) => {
      logErrorWithCallback(error, snackbar, 'Unable to fetch clockin break entries');
    }
  });

  return [data || [], loading, refetch];
};

export default useClockinBreakEntries;
