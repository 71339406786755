import gql from 'graphql-tag';

const timesheetsFragment = `
timeSheets {
  items {
    id
    hours
    minutes
    totalDuration
    visitDate
    clockInTime
    clockOutTime
    clockInTimezone
    clockOutTimezone
    entityType
    labourType
    gpsLocations {
      items {
        timestamp
        latitude
        longitude
        altitude
        heading
        speed
        accuracy
        altitudeAccuracy
        userAction
      }
    }
  }
}`;

export const jobFragment = `
job: parentEntity {
  ... on Job {
    id
    jobNumber
    jobTypeInternal
    customIdentifier
    customerProperty: parentEntity {
      ... on CustomerProperty {
        id
        companyName
        customer: parentEntity {
          ... on Customer {
            id
            customerName
          }
        }
      }
    }
  }
}`;

export const visitFragment = `
  ... on Visit {
    id
    entityType
    visitNumber
    departmentName
    status
    actualDuration
    startTime
    endTime
    ${jobFragment}
    schedules {
      items {
        id
        status
        onHoldReason
        onHold
        scheduledFor
        startTime
        endTime
        onRoute
        delayed
        delayedReason
        createdDateTime
        createdBy
        employee {
          id
          name
        }
        ${timesheetsFragment}
      }
    }
  }`;

const auditLogFragment = `
  auditLogs {
    items {
      id
      auditedEntityId
      auditedEntityType
      executedBy
      changeLog
      executedDateTime
      executionType
    }
  }`;

const timekeepingLedgersFragment = `
  timekeepingLedgersView {
  nextToken
  items {
    id
    userActionType
    employeeName
    startLatitude
    startLongitude
    endLatitude
    endLongitude
    actualStartTimeUTC
    actualEndTimeUTC
    actualHours
    actualMinutes
    actualTotalDuration
    actualCreatedDate
    employeeId
  }
}`;

export const timesheetEntryBinderFragment = `
  id
  eventId
  eventType
  employeeId
  manualStatus
  isDismissed
  dismissedBy
  dismissedReason
  dismissedDate
  manualApprovedBy
  manualApprovedDate
  manualReopenReason
  manualReopenDate
  manualDisputedReason
  manualDisputedDate
  manualToReviewStatus
  startDayCompanyTZ
  jobStartTime
  jobEndTime
  version
  projectId
  project {
    id
    name
    number
  }
  projectPhaseId
  projectPhase {
    id
    name
  }
  projectPhaseDepartmentId
  projectPhaseDepartment {
    id
    tagName
  }
  projectPhaseDepartmentCostCodeId
  projectPhaseDepartmentCostCode {
    id
    name
  }
  dailyReportId
  dailyReport {
    id
    number
  }
  eventTransitionLedger {
    eventId
    eventType
    executedBy
    executedDateTime
    transitionEvent
    oldStatus
    newStatus
    oldStartDateTimeUTC
    newStartDateTimeUTC
    oldEndDateTimeUTC
    newEndDateTimeUTC
    oldDuration
    newDuration
    primaryTechsRemoved
    primaryTechsAdded
    additionalTechsRemoved
    additionalTechsAdded
    onHoldReason
  }
  event {
    ... on NonVisitEvent {
      id
      entityType
      plannedStartTimeUTC
      plannedEndTimeUTC
      isActive
      name
      status
      department {
        tagName
      }
      assignedEntity {
        id
        entityType
        parentId
        ${visitFragment}
      }
      ${timekeepingLedgersFragment}
    }
    ... on Visit {
      id
      entityType
      visitNumber
      departmentName
      status
      actualDuration
      startTime
      endTime
      ${jobFragment} 
    }
    ... on ManDay {
      id
      entityType
      startDateTime
      endDateTime
      status
      project {
        id
        name
        number
      }
    }
  }
  timesheetEntries {
    items {
      id
      cost
      hourTypeId
      entityType
      timesheetPeriodId
      actualCreatedDate
      actualTotalDuration
      actualTotalDurationOverride
      actualStartTimeUTC
      actualEndTimeUTC
      actualCreatedDate
      manualApprovedBy
      manualApprovedDate
      manualReopenReason
      manualStatus
      hourType {
        id
        hourType
        hourTypeAbbreviation
        sortOrder
      }
      billableEntityId
      billableEntityType
      nonVisitEventId
      ${auditLogFragment}
    }
  }
  timesheetNotes {
    items {
      id
      note
      subject
      createdBy
      employeeId
      createdDateTime
      version
      attachments(entityConnection: "Attachment") {
        items {
          fileUrl
          fileName
          id
          _deleted
        }
      }
    }
  }
  ${auditLogFragment}
  ${timesheetsFragment}
`;

export const timesheetPeriodBindersFragment = `
  id
  dateStartUTC
  dateEndUTC
  approved
  approvalStatus
  timesheetEntryBinders {
    items {
      ${timesheetEntryBinderFragment}
    }
  }
`;

export const timesheetPeriodFragment = `
id
dateStartUTC
dateEndUTC
timesheetEntriesView(entityConnection: "TimesheetEntry" pagination: { limit: 1000 }) {
  items {
    id
    timesheetEntryBinderId
    timesheetEntryBinder {
      id
      eventId
      employeeId
      projectId
      project {
        id
        name
        number
      }
      projectPhaseId
      projectPhase {
        id
        name
      }
      projectPhaseDepartmentId
      projectPhaseDepartment {
        id
        tagName
      }
      projectPhaseDepartmentCostCodeId
      projectPhaseDepartmentCostCode {
        id
        name
      }
      dailyReportId
      dailyReport {
        id
        number
      }
      timesheetNotes {
        items {
          id
          note
          subject
          createdBy
          employeeId
          createdDateTime
          version
          attachments(entityConnection: "Attachment") {
            items {
              fileUrl
              fileName
              id
              _deleted
            }
          }
        }
      }
      event {
        ... on ManDay {
          id
          startDateTime
          endDateTime
          projectId
          project {
            id
            name
            number
          }
        }
      }
    }
    ${auditLogFragment}
    hourTypeId
    entityType
    timesheetPeriodId
    actualCreatedDate
    actualTotalDuration
    actualTotalDurationOverride
    actualStartTimeUTC
    actualEndTimeUTC
    actualCreatedDate
    manualApprovedBy
    manualApprovedDate
    manualReopenReason
    manualStatus
    billableEntityId
    billableEntityType
    nonVisitEventId
    nonVisitEvent {
      id
      name
      assignedEntityType
      assignedEntityId
      plannedStartTimeUTC
      plannedEndTimeUTC
      isActive
      status
      timesheetNotes {
        items {
          id
          note
          subject
          createdBy
          employeeId
          createdDateTime
          version
          attachments(entityConnection: "Attachment") {
            items {
              fileUrl
              fileName
              id
              _deleted
            }
          }
        }
      }
      ${timekeepingLedgersFragment}
      assignedEntity {
        id
        entityType
        parentId
        ${visitFragment}
      }
      plannedStartTimeUTC
      plannedEndTimeUTC
      eventType {
        id
        name
        description
        isBillable
        entityType
      }
      eventTypeId
      departmentId 
      department {
        tagName
      }
      employeeId
      entityType
    }
    billableEntity(entityConnection: "BillableEntity") {
      ... on Visit {
        entityType
        id
        visitNumber
        departmentName
        status
        actualDuration
        startTime
        endTime
        schedules {
          items {
            id
            status
            onHoldReason
            onHold
            scheduledFor
            startTime
            endTime
            onRoute
            delayed
            delayedReason
            createdDateTime
            createdBy
            employee {
              id
              name
            }
            ${timesheetsFragment}
          }
        }
        timesheetNotes(entityConnection: "TimesheetNote") {
          items {
            id
            note
            subject
            createdBy
            employeeId
            createdDateTime
            version
            attachments(entityConnection: "Attachment") {
              items {
                fileUrl
                fileName
                id
                _deleted
              }
            }
          }
        }
        ${jobFragment}
      }
    }
  }
}`;

// TODO: deprecate this query once wrinkle-in-time FF enabled everywhere
export const getTimesheetPeriodByIdQuery = gql`
  query getTimesheetPeriodById($id: String!) {
    getTimesheetPeriodById(id: $id) {
      ${timesheetPeriodFragment}
    }
  }
`;

export const getTimesheetPeriodsPendingForEmployeeQuery = gql`
  query timesheetPeriodsOfStatusForEmployee($employee: String!) {
    timesheetPeriodsOfStatusForEmployee(employeeId: $employee, manualStatus: DISPUTED) {
      ${timesheetPeriodFragment}
    }
  }
`;

export const getTimeOfOldestTimesheetThatNeedsReviewQuery = gql`
  query getTimeOfOldestTimesheetThatNeedsReview($employeeId: String!) {
    getTimeOfOldestTimesheetThatNeedsReview(employeeId: $employeeId)
  }
`;

// TODO: deprecate once wrinkle-in-time FF enabled everywhere
export const getActiveUnsubmittedEventsQuery = gql`
  query getActiveUnsubmittedEvents($employeeId: String!) {
    getActiveUnsubmittedEvents(employeeId: $employeeId) {
      visits {
        id
        status
        actualDuration
        visitNumber
        startTime
        endTime
        departmentName
        description
        scheduledFor
        timesheetEntriesView {
          items {
            id
            manualStatus
            timesheetPeriodId
            timesheetPeriod {
              id
              parentId
            }
          }
        }
        ${jobFragment}
      }
      nonVisitEvents {
        id
        plannedStartTimeUTC
        plannedEndTimeUTC
        isActive
        name
        status
        department {
          tagName
        }
        timesheetEntries {
          items {
            id
            manualStatus
            timesheetPeriodId
            timesheetPeriod {
              id
              parentId
            }
          }
        }
        assignedEntity {
          id
          entityType
          parentId
          ... on Visit {
            id
            visitNumber
            departmentName
            startTime
            endTime
            ${jobFragment}
          }
        }
      }
      manDays {
        id
        startDateTime
        endDateTime
        isActive
        status
        project {
          id
          name
          number
        }
      }
    }
  }
`;

export const getNumberOfSubmittedDayCardsQuery = gql`
  query getNumberofSubmittedDayCards($employeeId: String!) {
    getNumberOfSubmittedDayCards(employeeId: $employeeId)
  }
`;

export const getNumberOfDisputedDayCardsQuery = gql`
  query getNumberofDisputedDayCards($employeeId: String!) {
    getNumberOfDisputedDayCards(employeeId: $employeeId)
  }
`;

export const getTimesheetPeriodSelectOptionsQuery = gql`
  query getTimesheetPeriods($partitionKey: String!, $sortKey: String!) {
    getEmployee(partitionKey: $partitionKey, sortKey: $sortKey) {
      timesheetPeriods(entityConnection: "TimesheetPeriod") {
        items {
          id
          dateStartUTC
          dateEndUTC
        }
      }
    }
  }
`;

export const updateTimesheetEntryHoursMutation = gql`
  mutation updateTimesheetEntryHours(
    $partitionKey: String!
    $data: AddTimesheetEntriesToEmployeeInput!
  ) {
    bulkUpdateTimesheetEntries(partitionKey: $partitionKey, data: $data) {
      id
      hourTypeAbbreviation
      actualTotalDuration
      actualTotalDurationOverride
      actualStartTimeUTC
      actualEndTimeUTC
      manualStatus
      reviewStatus
      manualApprovedBy
      ${auditLogFragment}
    }
  }
`;

export const prevNextTimesheetPeriodQuery = gql`
  query prevNextTimesheetPeriod($employeeId: String!, $data: TimesheetPeriodScrollInput!) {
    prevNextTimesheetPeriod(employeeId: $employeeId, data: $data) {
      id
      dateStartUTC
      dateEndUTC
    }
  }
`;

export const updateTimesheetNoteMutation = gql`
  mutation updateTimesheetNote($partitionKey: String!, $data: UpdateTimesheetNoteInput!) {
    updateTimesheetNote(partitionKey: $partitionKey, data: $data) {
      id
      version
      note
      subject
      referenceEntityId
      referenceEntityType
      employeeId
      attachments {
        items {
          id
          fileUrl
        }
      }
    }
  }
`;

// TODO: deprecate this mutation once wrinkle-in-time FF enabled everywhere
export const addTimesheetNotesToVisitMutation = gql`
  mutation addTimesheetNotesToVisit($partitionKey: String!, $data: AddTimesheetNotesToVisitInput!) {
    addTimesheetNotesToVisit(partitionKey: $partitionKey, data: $data) {
      id
      subject
      note
      createdBy
      lastUpdatedBy
      lastUpdatedDate
      employeeId
      attachments {
        items {
          id
          fileUrl
        }
      }
    }
  }
`;

// TODO: deprecate this mutation once wrinkle-in-time FF enabled everywhere
export const addTimesheetNotesToNonVisitEventMutation = gql`
  mutation addTimesheetNotesToNonVisitEvent(
    $partitionKey: String!
    $data: AddTimesheetNotesToNonVisitEventInput!
  ) {
    addTimesheetNotesToNonVisitEvent(partitionKey: $partitionKey, data: $data) {
      id
      subject
      note
      createdBy
      lastUpdatedBy
      lastUpdatedDate
      employeeId
      attachments {
        items {
          id
          fileUrl
        }
      }
    }
  }
`;

export const addTimesheetNotesToTimesheetEntryBinderMutation = gql`
  mutation addTimesheetNotesToTimesheetEntryBinder(
    $partitionKey: String!
    $data: AddTimesheetNotesToTimesheetEntryBinderInput!
  ) {
    addTimesheetNotesToTimesheetEntryBinder(partitionKey: $partitionKey, data: $data) {
      id
      subject
      note
      createdBy
      lastUpdatedBy
      lastUpdatedDate
      employeeId
      attachments {
        items {
          id
          fileUrl
        }
      }
    }
  }
`;

export default {};
