import React, { useMemo } from 'react';

import { TV, TW, Typography } from '@BuildHero/sergeant';
import { Box, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import { BoldCell } from 'components/WrapTable';

export const usePayrollCostCodesColumns = ({ loading, payrollHourTypes, openModal }) =>
  useMemo(
    () => [
      {
        headerName: 'Group',
        field: 'labourRateGroupName',
        width: 300,
        align: 'center',
        flex: 1,
        renderTotal: BoldCell,
        renderCell: ({ row, isGroupRow }) =>
          isGroupRow ? row.labourRateGroupName : row.labourTypeName
      },
      ...payrollHourTypes
        .filter(({ isArchived }) => !isArchived)
        .map(payrollHourType => ({
          headerName: payrollHourType.hourTypeAbbreviation,
          field: payrollHourType.hourTypeAbbreviation,
          renderCell: ({ value, cellAddress, isGroupRow }) => {
            if (isGroupRow) {
              return <div style={{ width: '100%', height: '100%' }} />;
            }

            const typography =
              !value.costCode?.label || !value.jobCostType?.label ? (
                <Typography color="#999999" variant={TV.BASE} weight={TW.REGULAR}>
                  Select Cost Code &amp; Cost Type
                </Typography>
              ) : (
                <Typography variant={TV.BASE} weight={TW.REGULAR}>
                  {`${value.costCode.label} - ${value.jobCostType.label}`}
                </Typography>
              );

            return (
              <Box alignItems="center" display="flex" flexDirection="row">
                {typography}
                <IconButton
                  css={{ marginLeft: 8 }}
                  disabled={loading}
                  size="small"
                  onClick={() =>
                    openModal({
                      id: cellAddress,
                      costCode: value.costCode,
                      jobCostType: value.jobCostType
                    })
                  }
                >
                  <EditIcon />
                </IconButton>
              </Box>
            );
          }
        }))
    ],
    [payrollHourTypes, loading, openModal]
  );
