import React from 'react';

import { findIndex, sortBy } from 'lodash';
import { object, string } from 'prop-types';

import WrapTable from 'components/WrapTable';
import { TablePlaceholderVariant } from 'components/WrapTable/WrapTable.constants';

import { useGetTimeTrackingTableColumns } from './TimeTrackingReportTable.hooks';
import { useTechVisitCost } from './TimeTrackingReportTable.queries';

const TimeTrackingReportTable = ({ visitId, user }) => {
  const { loading, data, error } = useTechVisitCost({ visitId });

  // Group vistCostViews by employees
  const getGroupedVisitCost = visitCostViews => {
    const groupedVisitCost = [];
    visitCostViews.forEach(view => {
      const { employeeId, duration, totalCost } = view;
      const employeeIndex = findIndex(groupedVisitCost, { employeeId });
      if (employeeIndex !== -1) {
        const prevCostView = groupedVisitCost[employeeIndex];
        const newCostView = {
          ...prevCostView,
          duration: prevCostView.duration + duration,
          totalCost: prevCostView.totalCost + totalCost
        };
        groupedVisitCost[employeeIndex] = newCostView;
      } else {
        groupedVisitCost.push(view);
      }
    });
    return sortBy(groupedVisitCost, ['name']);
  };

  return (
    <WrapTable
      columns={useGetTimeTrackingTableColumns(user)}
      error={error}
      loading={loading}
      noDataMessage="No Time Tracking Report Data"
      placeholderVariant={TablePlaceholderVariant.TEXT}
      rows={getGroupedVisitCost(data)}
    />
  );
};

TimeTrackingReportTable.propTypes = {
  // eslint-disable-next-line react/require-default-props
  visitId: string,
  user: object.isRequired
};

export default TimeTrackingReportTable;
