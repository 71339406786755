import React from 'react';

import { Dialog, DialogContent, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withTheme } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

import { DefaultButton } from 'components';
import Labels from 'meta/labels';
import ErrorBoundaries from 'scenes/Error';
import { LeaveDateModalStatus } from 'utils/AppConstants';

const LeaveDateModal = ({ handleSubmit, openLeaveDateModal, classes, userLocale }) => {
  return (
    <Dialog
      aria-describedby="simple-modal-description"
      aria-labelledby="simple-modal-title"
      maxWidth="lg"
      open={openLeaveDateModal}
      scroll="paper"
    >
      <DialogContent className={classes.dialogContent}>
        <ErrorBoundaries>
          <Typography style={{ paddingTop: '20px' }} variant="h3">
            Leaving Date Blank
          </Typography>
          <Typography className={classes.description} variant="body2">
            If you proceed without a date, your visits will appear with unassigned visits on the
            right side of the dispatch board.
          </Typography>
          <div style={{ display: 'flex' }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="leaveDate"
                  onChange={event => {
                    sessionStorage.setItem(LeaveDateModalStatus, event.target.checked);
                  }}
                />
              }
              label={Labels.doNotShow[userLocale]}
              style={{ width: '350px' }}
            />
            <DefaultButton
              color="primary"
              handle={handleSubmit}
              label={Labels.okay[userLocale].toUpperCase()}
              style={{ width: '120px', marginLeft: '40px' }}
              variant="contained"
            />
          </div>
        </ErrorBoundaries>
      </DialogContent>
    </Dialog>
  );
};

LeaveDateModal.propTypes = {
  openLeaveDateModal: PropTypes.bool,
  userLocale: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object
};

LeaveDateModal.defaultProps = {
  openLeaveDateModal: false,
  classes: {}
};

export default withTheme(LeaveDateModal);
