import React, { useState } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import { bool, func, number, object, oneOf, shape } from 'prop-types';

import {
  eventToFormData,
  formToEventData,
  validateScheduling
} from '../../helpers/NonVisitEvents.helpers';

import AccordionSection from '../AccordionSection';
import CreateMultipleEventsSwitch from '../CreateMultipleEventsSwitch';
import DateTimeInput from '../DateTimeInput';
import DurationInput from '../DurationInput';

import { Modes } from './NonBillableEventForm.constants';
import { useNonBillableEventFormConfig } from './NonBilllableEventFormV2.config';

const NonBillableEventForm = ({
  allowMultipleEventsCreation,
  departmentsResponse,
  disabled,
  enableDepartment,
  eventData,
  mode,
  payrollHourTypesResponse,
  onComplete,
  onCreateService,
  onDirtyChange
}) => {
  const [multipleEventsCreationEnabled, setMultipleEventsCreationEnabled] = useState(false);

  const { data: departments, loading: departmentsLoading } = departmentsResponse;
  const { data: payrollHourTypes, loading: payrollHourTypesLoading } = payrollHourTypesResponse;

  const handleCreateMultipleEventsChange = () =>
    setMultipleEventsCreationEnabled(!multipleEventsCreationEnabled);

  const formConfig = useNonBillableEventFormConfig({
    allowMultipleEventsCreation,
    departments,
    departmentsLoading,
    disablePayrollHourType: eventData?.status !== 'Scheduled',
    disabled,
    enableDepartment,
    mode,
    multipleEventsCreationEnabled,
    onCreateMultipleEventsChange: handleCreateMultipleEventsChange,
    payrollHourTypes,
    payrollHourTypesLoading
  });

  const handleComplete = formData =>
    onComplete(
      formToEventData(formData, multipleEventsCreationEnabled),
      multipleEventsCreationEnabled
    );

  return (
    <MUIForm
      configuration={formConfig}
      customComponents={{
        AccordionSection,
        CreateMultipleEventsSwitch,
        DateTimeInput,
        DurationInput
      }}
      data={eventToFormData(eventData)}
      validate={validateScheduling}
      onComplete={handleComplete}
      onCreateService={onCreateService}
      onDirtyChange={onDirtyChange}
    />
  );
};

NonBillableEventForm.propTypes = {
  allowMultipleEventsCreation: bool,
  departmentsResponse: object.isRequired,
  disabled: bool.isRequired,
  enableDepartment: bool,
  eventData: shape({ from: number }).isRequired,
  mode: oneOf(Object.values(Modes)),
  onComplete: func.isRequired,
  onCreateService: func.isRequired,
  onDirtyChange: func.isRequired,
  payrollHourTypesResponse: object.isRequired
};

NonBillableEventForm.defaultProps = {
  allowMultipleEventsCreation: false,
  enableDepartment: false,
  mode: Modes.ADD
};

export default NonBillableEventForm;
