import React, { Component } from 'react';

import { Grid, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Spinner from 'components/Spinners/CircularIndeterminate';

import styles from './styles';

class OCRDataDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imgHeight: 0
    };
  }

  handleImageLoaded() {
    const height = this.imgElement.clientHeight;
    this.setState({
      imgHeight: height
    });
  }

  render() {
    const { classes, dataOCRInput } = this.props;
    const { imageURL, data, loading } = dataOCRInput;
    const imgHeightToRows = Math.floor(this.state.imgHeight / 20) - 1;
    const maxRow = Math.max(imgHeightToRows, 5);

    return (
      <Grid container direction="row">
        <Grid className={classes.container} item sm={6} xs={12}>
          <img
            alt={imageURL}
            className={classes.thumbnail}
            ref={imgElement => {
              this.imgElement = imgElement;
            }}
            src={imageURL}
            onLoad={this.handleImageLoaded.bind(this)}
          />
        </Grid>
        <Grid className={classes.container} item sm={6} xs={12}>
          {loading ? (
            <Spinner className={classes.spinner} />
          ) : (
            <TextField
              className={classes.ocrInputStyle}
              label="Text from Image"
              multiline
              rowsMax={maxRow}
              value={data}
              variant="outlined"
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(OCRDataDisplay);
