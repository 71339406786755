import React, { useRef } from 'react';

import { ThemeProvider } from '@BuildHero/sergeant';
import isEmpty from 'lodash/isEmpty';
import { func, number, shape, string } from 'prop-types';

import { selectJobPath, selectJobTitle } from '../../selectors';

import ReportHeaderTitle from './components/ReportHeaderTitle';
import { useShowStickyHeader } from './ReportHeaderLayout.hooks';
import { useStyles } from './ReportHeaderLayout.styles';

const headerHeight = 125;
const headerYOffset = 60;
const stickyHeaderHeight = 48;

const ReportHeaderLayout = ({
  reportId,
  job,
  visit,
  reviewReport,
  renderHeader,
  renderButtons,
  formatReportTitle
}) => {
  const styles = useStyles({ headerYOffset, stickyHeaderHeight });
  const jobTitle = selectJobTitle(job) || '';
  const jobPath = selectJobPath(job);
  const visitNumber = visit?.visitNumber;
  const reportTitle = jobTitle && visitNumber ? formatReportTitle({ jobTitle, visitNumber }) : null;
  const visitScheduledFor = visit?.scheduledFor;
  const headerRef = useRef();

  const showStickyHeader = useShowStickyHeader({
    headerRef,
    headerYOffset,
    headerHeight,
    stickyHeaderHeight
  });

  return (
    <div css={styles.container} ref={headerRef}>
      <div>
        {renderHeader({ jobTitle, jobPath })}
        <ThemeProvider>
          <div css={styles.title}>
            <ReportHeaderTitle reportTitle={reportTitle} visitScheduledFor={visitScheduledFor} />
          </div>
        </ThemeProvider>
      </div>
      <div css={styles.buttonsContainer}>
        {!isEmpty(visit) && renderButtons({ reportId, visit, reviewReport, job })}
      </div>
      {showStickyHeader && (
        <div css={styles.stickyContainer}>
          <div css={styles.stickyTitleContainer}>
            <ReportHeaderTitle reportTitle={reportTitle} visitScheduledFor={visitScheduledFor} />
          </div>
          <div css={styles.stickyButtonsContainer}>
            {!isEmpty(visit) &&
              renderButtons({ reportId, visit, reviewReport, job, buttonSize: 'small' })}
          </div>
        </div>
      )}
    </div>
  );
};

ReportHeaderLayout.propTypes = {
  reportId: string.isRequired,
  formatReportTitle: func.isRequired,
  job: shape({
    customIdentifier: string,
    jobNumber: string,
    jobTypeInternal: string
  }).isRequired,
  visit: shape({
    visitNumber: number,
    scheduledFor: number
  }).isRequired,
  renderHeader: func.isRequired,
  renderButtons: func.isRequired
};

export default ReportHeaderLayout;
