import React from 'react';

import isUndefined from 'lodash/isUndefined';

import { useDispatchBoardVisits } from '@dispatch/components/DispatchBoard/queries';
import { selectEndDay } from '@dispatch/Dispatch.selectors';
import { withQueryStringStore } from '@dispatch/Dispatch.store';
import { useCompanyOverview } from '@dispatch/queries';

import MapView from './MapView.component';
import { useUnscheduledVisits } from './MapView.queries';

const mapQueryStringToProps = ({
  state: {
    visitStatuses,
    showVisits,
    showTechs,
    showLocations,
    showUnscheduled,
    endDay,
    weekView,
    mapView,
    day
  }
}) => ({
  visitStatuses,
  showVisits: isUndefined(showVisits) ? true : showVisits,
  showTechs: isUndefined(showTechs) ? true : showTechs,
  showLocations: isUndefined(showLocations) ? true : showLocations,
  showUnscheduled: Boolean(showUnscheduled),
  mapView: Boolean(mapView),
  weekView: Boolean(weekView),
  endDay: selectEndDay(endDay, weekView, day),
  day
});

const DataProvider = props => {
  const unscheduledVisitsResponse = useUnscheduledVisits({ skip: !props.showUnscheduled });
  const companyOverviewResponse = useCompanyOverview();
  const boardVisitsResponse = useDispatchBoardVisits(props);

  return (
    <MapView
      {...props}
      boardVisitsResponse={boardVisitsResponse}
      companyOverviewResponse={companyOverviewResponse}
      unscheduledVisitsResponse={unscheduledVisitsResponse}
    />
  );
};

export default withQueryStringStore(mapQueryStringToProps)(DataProvider);
