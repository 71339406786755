import React from 'react';

import { PDFDocument } from '@BuildHero/sergeant';
import { pdf } from '@react-pdf/renderer';

import { isEmpty, noop, sortBy } from 'lodash';

import { Context } from 'components';
import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';
import { formatAddress, getImageUrl, getTenantSettingValueForKey, roundCurrency } from 'utils';

import {
  AccountingApp,
  AccountType,
  AddressType,
  EnvelopeType,
  InvoiceItemType,
  InvoiceStatus,
  PaymentTermType,
  SyncStatus,
  TransactionType
} from 'utils/constants';

import { constructSelectOptions } from 'utils/constructSelectOptions';
import getSageJobs from 'utils/getSageJobs';

import { CustomerSignaturesDisplayPDF } from './components';
import getInvoiceLayout from './InvoiceConfiguration';
import { defaultInvoiceSettings } from './InvoiceSetting';

/**
 * Format the paymentInvoices to display on the payments table
 * @param paymentInvoices Array of paymentInvoices
 */
const formatPaymentInvoices = paymentInvoices => {
  return paymentInvoices.map(pi => ({
    ...pi.parentEntity,
    appliedAmount: pi.appliedAmount
  }));
};

/**
 * Separates the invoice items by their `lineItemType`
 * @param items Array of invoice items
 */
export const separateInvoiceItems = items => {
  const extractTypes = types =>
    items
      .filter(({ lineItemType }) => types.includes(lineItemType))
      .sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));
  return {
    laborItems: extractTypes([InvoiceItemType.LABOR_LINE_ITEM]),
    partsAndMaterials: extractTypes([
      InvoiceItemType.INVENTORY_PART,
      InvoiceItemType.PUCHASE_ORDER_LINE
    ]),
    discountsAndFees: extractTypes([InvoiceItemType.DISCOUNT, InvoiceItemType.FEE])
  };
};

/**
 * Extract the given address type from an array of addresses
 * @param addresses Array of company addresses
 * @param type Addresss type to extract
 */
const extractAddressType = (addresses = [], type) =>
  formatAddress(addresses.find(({ addressType }) => addressType === type));

/**
 * The sender is the entity sending the invoice. Department is the sender if it exists, otherwise uses the company info.
 * If the setting is single pane with large logo, the company's invoice logo is used if
 * defined.
 * @param company The tenant company
 * @param department Invoice's department if it has one
 * @param envelopeType The envelope type being used for the tenant
 */
const createSender = async (company, department, envelopeType) => {
  return {
    name: company?.companyName,
    logoUrl: await getImageUrl(
      (envelopeType === EnvelopeType.SINGLE_LARGE_LOGO && company?.invoiceLogoUrl) ||
        department?.logoUrl ||
        company?.logoUrl
    ),
    phoneNumber: department?.phonePrimary ?? company?.phonePrimary,
    email: department?.email ?? company?.email,
    address:
      extractAddressType(department?.companyAddresses?.items, AddressType.BILLING) ||
      extractAddressType(company?.companyAddresses?.items, AddressType.BILLING)
  };
};

/**
 * Tax calculation logic can vary by accounting systems
 */

const calculateTax = {
  [AccountingApp.VISTA]: (salesTaxRate, items = []) => {
    // vista calculates taxes at line item level and rounds off to 2 decimal place
    const localSalesTaxRate = parseFloat(salesTaxRate) / 100 || 0;
    return roundCurrency(
      items
        .filter(item => item.taxable)
        ?.reduce(
          (total, item) =>
            total +
            (item.lineItemType === InvoiceItemType.DISCOUNT
              ? -roundCurrency(parseFloat(item.amount) * localSalesTaxRate)
              : roundCurrency(parseFloat(item.amount) * localSalesTaxRate)),
          0
        )
    );
  }
};
/**
 * Calculate invoice total values given line items, tax rate, and paid amount
 * @param items Array of invoice line items
 * @param salesTaxRate Tax rate value
 * @param amountPaid Total paid amount on the invoice
 */
export const calculateTotals = (
  items,
  salesTaxRate = 0,
  amountPaid = 0,
  adjustedAmount = 0,
  isCanadaQuickbooksEnabled = false,
  syncStatus = '',
  databaseTotalAmount = 0,
  accountingApp = 'NONE'
) => {
  const computeSubtotal = arr =>
    arr?.reduce(
      (total, item) =>
        total + (item.lineItemType === InvoiceItemType.DISCOUNT ? -item.amount : item.amount),
      0
    );

  const subtotal = computeSubtotal(
    items.filter(
      item => ![InvoiceItemType.DISCOUNT, InvoiceItemType.FEE].includes(item.lineItemType)
    )
  );
  const subtotalAfterDiscountsAndFees = computeSubtotal(items);
  const serviceFee = computeSubtotal(
    items.filter(item => item.lineItemType === InvoiceItemType.FEE)
  );
  const discount = computeSubtotal(
    items.filter(item => item.lineItemType === InvoiceItemType.DISCOUNT)
  );
  const taxableSubtotal = computeSubtotal(items.filter(item => item.taxable));

  const taxAmount = calculateTax[accountingApp]
    ? calculateTax[accountingApp](salesTaxRate, items)
    : roundCurrency(taxableSubtotal * (salesTaxRate / 100 || 0));

  let totalAmount = subtotalAfterDiscountsAndFees + taxAmount;
  const balance = totalAmount - amountPaid;
  if (isCanadaQuickbooksEnabled && syncStatus === 'InSync') {
    totalAmount = databaseTotalAmount;
  } else {
    totalAmount = roundCurrency(totalAmount);
  }
  return {
    subtotal: roundCurrency(subtotal),
    subtotalAfterDiscountsAndFees: roundCurrency(subtotalAfterDiscountsAndFees),
    serviceFee: roundCurrency(serviceFee),
    discount: roundCurrency(discount),
    taxableSubtotal: roundCurrency(taxableSubtotal),
    taxAmount,
    totalAmount,
    amountPaid: roundCurrency(amountPaid),
    balance: roundCurrency(balance),
    adjustedBalance: roundCurrency(balance - adjustedAmount)
  };
};

export const formatInvoiceData = async (rawData, oldData = {}) => {
  if (!rawData) return rawData;
  const {
    tenantId,
    tenantCompanyId,
    customerSignatures,
    invoiceItems,
    paymentInvoiceView,
    paymentTermName,
    paymentTermValue,
    customer,
    billingCustomer,
    companyAddresses,
    amountPaid: aggregated,
    customerProperty,
    job,
    taxRate,
    sageJob,
    settingsJSON,
    adjustedAmount = 0,
    projectName,
    projectId,
    serviceAgreement,
    adjustmentTransactions,
    ...data
  } = rawData;
  const company =
    Context.getCompanyContext()?.getCompany ??
    (await Context.setCompanyContext(tenantId, `${tenantId}_Company_${tenantCompanyId}`));
  if (!company) {
    Logger.error('Company is null');
    return null;
  }

  let { accountingApp, sageJobOptions, envelopeType } = oldData;
  if (isEmpty(oldData)) {
    // envelope settings - default is single
    envelopeType = getTenantSettingValueForKey('envelopeType') || EnvelopeType.SINGLE;

    // accounting integration
    const settingsString = getTenantSettingValueForKey('accountingAppSettings');
    try {
      accountingApp = settingsString && JSON.parse(settingsString)?.app;

      if (accountingApp === AccountingApp.SAGE) {
        const sageJobs = await getSageJobs({ tenantId, tenantCompanyId });
        sageJobOptions = constructSelectOptions(sageJobs, 'code');
      }
    } catch (error) {
      Logger.error(error);
    }
  }

  // invoice items department selection
  const departmentOptions =
    oldData.departmentOptions ??
    company.departments.items.map(d => ({
      label: d.tagName,
      value: d.id,
      accountingRefIdOfClass: d.accountingRefIdOfClass
    }));

  // payment term options
  const paymentTermOptions =
    oldData.paymentTermOptions ??
    company.paymentTerms.items
      .filter(({ type }) => [PaymentTermType.BOTH, PaymentTermType.INVOICING].includes(type))
      .map(({ name, value }) => ({ label: name, value }));

  // invoice tax rate options
  const taxRateOptions =
    oldData.taxRateOptions ??
    company.taxRates.items
      .filter(t => t.accountType !== AccountType.AP)
      .map(t => ({
        ...t,
        label: `${t.name}: ${t.taxRate}%`,
        value: t.id
      }));

  // invoice settings
  let settings;
  try {
    if (settingsJSON) {
      settings = JSON.parse(settingsJSON);
    } else {
      const matchedPreset = company.userSettings.items.find(
        p => p.id === billingCustomer.invoicePresetId
      );
      settings =
        matchedPreset && matchedPreset.settings
          ? JSON.parse(matchedPreset.settings)
          : defaultInvoiceSettings;
    }
  } catch (error) {
    Logger.error(error);
  }

  // refunds
  const refunds = adjustmentTransactions?.items
    .filter(({ adjustment }) => adjustment.transactionType === TransactionType.REFUND)
    .map(({ adjustment }) => ({
      label: adjustment.number,
      to: `/adjustment/view/${adjustment.id}`
    }));

  const amountPaid = aggregated.items[0].total || 0;
  const calculations = calculateTotals(
    invoiceItems.items,
    taxRate?.taxRate,
    amountPaid,
    adjustedAmount,
    undefined,
    undefined,
    undefined,
    accountingApp
  );

  let parentLink;
  if (projectName) {
    parentLink = {
      label: `Project ${projectName}`,
      to: `/project/view/${projectId}/dashboard`
    };
  } else if (job) {
    parentLink = {
      label: `${job.jobTypeInternal ?? 'Job'} ${data.jobNumber}`,
      to: `/${job.jobTypeInternal?.toLowerCase() ?? 'job'}/view/${encodeURIComponent(
        job.jobNumber
      )}`
    };
  } else if (serviceAgreement) {
    parentLink = {
      label: serviceAgreement.agreementNumber,
      to: `/serviceAgreement/view/${serviceAgreement.id}`
    };
  }

  return {
    ...data,
    tenantId,
    settings,
    refunds,
    sageJob: sageJob
      ? {
          label: sageJob.code,
          value: sageJob.id
        }
      : undefined,
    terms: paymentTermName
      ? {
          label: paymentTermName,
          value: paymentTermValue
        }
      : undefined,
    taxRate: taxRate
      ? {
          ...taxRate,
          label: `${taxRate.name} - ${taxRate.taxRate}%`,
          value: taxRate.id
        }
      : undefined,
    customer: {
      ...customer,
      tags: customer?.customerTags?.items.map(v => ({
        id: v.mappedEntity?.id,
        label: v.mappedEntity?.tagName
      })),
      notes: customer?.notes?.items,
      link: {
        label: customer.customerName,
        to: `/customer/view/${customer.id}`
      }
    },
    billingCustomer: {
      ...billingCustomer,
      tags: billingCustomer?.customerTags?.items.map(v => ({
        id: v.mappedEntity?.id,
        label: v.mappedEntity?.tagName
      })),
      notes: billingCustomer?.notes?.items,
      link: {
        label: billingCustomer.customerName,
        to: `/customer/view/${billingCustomer.id}`
      }
    },
    customerProperty: {
      ...customerProperty,
      notes: customerProperty?.notes?.items,
      link: customerProperty && {
        label: customerProperty.companyName,
        to: `/property/view/${customerProperty.id}`
      }
    },
    parentLink,
    job: {
      ...job,
      tags: job?.jobJobTags?.items.map(v => ({
        id: v.mappedEntity?.id,
        label: v.mappedEntity?.tagName
      })),
      notes: job?.notes?.items,
      jcContractItemOptions: job?.jcContractContractItems?.map(i => ({
        label: i.jcContractItemName,
        value: i.jcContractItemId
      })),
      link: job && {
        label: `${job.jobTypeInternal ?? 'Job'} ${data.jobNumber}`,
        to: `/${job.jobTypeInternal?.toLowerCase() ?? 'job'}/view/${job.jobNumber}`
      }
    },
    sender: await createSender(company, data.department, envelopeType),
    customerSignatures: sortBy(customerSignatures.items, 'visit.visitNumber'),
    ...calculations,
    adjustedAmount,
    payments: formatPaymentInvoices(paymentInvoiceView.items),
    ...separateInvoiceItems(invoiceItems.items),
    invoiceItems: invoiceItems.items,
    billingAddressString: extractAddressType(companyAddresses.items, AddressType.BILLING),
    billingAddress: companyAddresses.items.find(a => a.addressType === AddressType.BILLING),
    propertyAddress:
      extractAddressType(companyAddresses.items, AddressType.PROPERTY) ||
      extractAddressType(companyAddresses.items, AddressType.BUSINESS),
    envelopeType,
    accountingApp,
    sageJobOptions,
    paymentTermOptions,
    taxRateOptions,
    departmentOptions
  };
};

const changesMap = {
  terms: ({ label = null, value = null }) => ({
    paymentTermName: label,
    paymentTermValue: value
  }),
  taxRate: ({ value = null, taxRate = null, accountingRefId = null }) => ({
    taxRateId: value,
    salesTaxRate: taxRate,
    accountingRefIdOfSalesTaxRate: accountingRefId
  }),
  sageJob: ({ value = null }) => ({
    sageJobId: value
  }),
  // for fields not in the invoice but in the data
  adjustedAmount: noop,
  adjustedBalance: noop,
  adjustmentsFlag: noop,
  amountPaid: noop,
  auditLogs: noop,
  balance: noop,
  billingAddressString: noop,
  customerSignatures: noop,
  departmentOptions: noop,
  discount: noop,
  discountsAndFees: noop,
  invoiceInvoiceTags: noop,
  invoiceItems: noop,
  laborItems: noop,
  latestEmail: noop,
  parentLink: noop,
  partsAndMaterials: noop,
  paymentTermOptions: noop,
  payments: noop,
  refunds: noop,
  sageJobOptions: noop, // if somehow the options are changed, leave them out of mutation
  serviceFee: noop,
  settings: noop,
  subtotalAfterDiscountsAndFees: noop,
  taxAmount: noop,
  taxRateOptions: noop
};

export const formatChangesForUpdate = changes => {
  return Object.entries(changes).reduce((acc, [key, value]) => {
    const mappingFn = changesMap[key];
    if (mappingFn) return { ...acc, ...mappingFn(value ?? {}) };
    return { ...acc, [key]: value };
  }, {});
};

const getInvoicePdfName = invoice => `Invoice${invoice.invoiceNumber}.pdf`;

export const getInvoicePdfBlob = async invoice =>
  pdf(
    <PDFDocument
      configuration={getInvoiceLayout(invoice, undefined, false, true)}
      customComponents={{ CustomerSignaturesDisplayPDF }}
      initialValues={invoice}
      layout="pdf"
    />
  ).toBlob();

export const downloadInvoice = async invoice => {
  const url = URL.createObjectURL(await getInvoicePdfBlob(invoice));
  const a = document.createElement('a');
  a.href = url;
  a.download = getInvoicePdfName(invoice);
  a.click();
  URL.revokeObjectURL(url);
};

export const uploadInvoicePdf = async invoice => {
  try {
    const storageService = new StorageService();
    return await storageService.uploadFile(
      await getInvoicePdfBlob(invoice),
      `${invoice.tenantId}/${getInvoicePdfName(invoice)}`,
      e => e,
      'application/pdf'
    );
  } catch (error) {
    Logger.error(error);
    return null;
  }
};

export const isReadonly = ({
  userCanUpdateInvoice,
  status,
  isSageIntegrated,
  isSpectrumIntegrated,
  isVistaIntegrated,
  syncStatus
}) => {
  // the invoice goes into a read-only mode under following circumstances
  // for all accounting systems except for vista and spectrum, make it read-only while "syncing" until we receive an acknowledgement back from accounting system
  return (
    !userCanUpdateInvoice ||
    status === InvoiceStatus.VOID ||
    status === InvoiceStatus.CLOSED ||
    (syncStatus === SyncStatus.SYNCING && (isSpectrumIntegrated || isVistaIntegrated)) ||
    (isSageIntegrated && [InvoiceStatus.EXPORTED, InvoiceStatus.POSTED].includes(status))
  );
};
