import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { UserPermission } from 'components';
import ErrorBoundaries from 'scenes/Error';
import { PermissionConstants } from 'utils/AppConstants';

import CustomTabs from '../../components/CustomTabs';
import SubmenuTitle from '../../components/SubmenuTitle';

import ItemLists from './ItemLists';
import PackageLists from './PackageLists';

const SubmittalsList = props => {
  const { projectId, projectData, packageId } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const tabMenus = [{ tabLabel: 'Items' }, { tabLabel: 'Packages' }];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const showTabPanel = tab => {
    if (packageId || tabMenus[tab].tabLabel === 'Packages') {
      return <PackageLists packageId={packageId} projectData={projectData} projectId={projectId} />;
    }
    return <ItemLists projectId={projectId} />;
  };

  return (
    <ErrorBoundaries>
      <UserPermission action={PermissionConstants.OBJECT_PM_SUBMITTALS} I="read">
        <SubmenuTitle>Submittals</SubmenuTitle>
        <CustomTabs tabMenus={tabMenus} value={selectedTab} onChange={handleTabChange} />
        {showTabPanel(selectedTab)}
      </UserPermission>
    </ErrorBoundaries>
  );
};

SubmittalsList.propTypes = {
  projectId: PropTypes.string.isRequired,
  projectData: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => ({
  projectData: state.pm.project,
  mode: props.match.params.mode,
  projectId: props.match.params.projectId,
  packageId: props.match.params.subpage
});

export default connect(mapStateToProps)(SubmittalsList);
