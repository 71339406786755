import React from 'react';

import { theme } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';

import AlgoliaSearch from 'components/AlgoliaSearch';
import { searchIndex } from 'constants/algoliaIndex';

import ErrorBoundaries from 'scenes/Error';
import { CustomerPropertyService } from 'services/core';
import { constructSelectOptions } from 'utils/constructSelectOptions';

const style = {
  container: {
    width: '33%',
    marginRight: 8,
    '& span[class*="RawLabelControl"]': {
      color: theme.palette.grayscale(50)
    },
    '& div[class*="MuiOutlinedInput"]': {
      borderRadius: 0
    },
    '& p[class*="MuiFormHelperText"]': {
      fontSize: 12
    }
  }
};

const CustomPropertySearch = props => {
  const { form, props: sgtProps } = props;
  const { property } = form.values;
  const { propertyErrorMsg, setAssetOptions, setPropertyErrorMsg, setScopedPricebook } = sgtProps;

  const handleSearchChange = async newItem => {
    if (!newItem?.id) {
      form.setFieldValue('billingCustomer', '');
      form.setFieldValue('customer', '');
      form.setFieldValue('property', '');
      form.setFieldValue('propertyId', '');
      return;
    }

    setPropertyErrorMsg('');
    const { data } = await new CustomerPropertyService().getCustomerPropertyInfoById(
      newItem.parentId
    );

    const scopedPricebook =
      data?.getCustomerPropertyById?.priceBookId ||
      data?.getCustomerPropertyById?.parentEntity?.priceBook?.id;

    if (scopedPricebook) {
      setScopedPricebook(scopedPricebook);
    }
    const assetOptions = constructSelectOptions(
      data?.getCustomerPropertyById?.propertyAssets?.items,
      'assetName'
    );
    setAssetOptions(assetOptions);

    form.setFieldValue(
      'billingCustomer',
      data?.getCustomerPropertyById?.billingCustomer?.customerName
    );
    form.setFieldValue('customer', newItem.customerName);
    form.setFieldValue('property', newItem.customerPropertyName);
    form.setFieldValue('propertyId', newItem.parentId);
  };

  const filters = [
    {
      attribute: 'entityType',
      valueArray: ['CompanyAddress']
    },
    {
      attribute: 'parentEntityType',
      valueArray: ['CustomerProperty']
    },
    {
      attribute: 'addressType',
      valueArray: ['propertyAddress']
    },
    {
      attribute: 'parentStatus',
      valueArray: ['active']
    }
  ];

  return (
    <ErrorBoundaries>
      <div css={style.container}>
        <AlgoliaSearch
          delimiter=" - "
          displayText={['customerPropertyName', 'address']}
          errorMsg={propertyErrorMsg}
          filters={filters}
          greyBackground
          handleChange={handleSearchChange}
          isRequired
          label="Property"
          placeholder="Select a Property"
          searchIndex={searchIndex}
          value={property}
          valueField="customerPropertyName"
        />
      </div>
    </ErrorBoundaries>
  );
};

export default CustomPropertySearch;
