/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

import { sentryException } from '../Logger/index';

import { getPhaseCodesByProject } from './projectPhaseDepartmentCostCode';

export async function getProjectCostCodesByProject(id, phaseId) {
  if (phaseId) {
    return await getPhaseCodesByProject(id, phaseId);
  }
  return await instance
    .get(`projectcostcodes/project/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, `Unable to get Project's cost code`);
      return [];
    });
}

export async function getCostCodesByDep(projectId, depId) {
  if (projectId?.length > 0 && depId?.length > 0) {
    return await instance
      .get(`projectcostcodes/projectdep/${projectId}/${depId}`)
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        sentryException(err, `Unable to get Project's cost code`);
        return [];
      });
  }
  return [];
}

export async function projectCostCodeCreate(costCode) {
  return await instance
    .post('projectcostcodes', costCode)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, `Unable to create Project's cost code`);
      return [];
    });
}

export async function projectCostCodeDelete(costCode) {
  return await instance
    .delete(`projectcostcodes/${costCode}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, `Unable to delete Project's cost code`);
      return [];
    });
}

export async function projectCostCodeChange(id, costCode) {
  return await instance
    .put(`projectcostcodes/${id}`, costCode)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, `Unable to change Project's cost code`);
      return [];
    });
}

export async function projectCostCodeSearch(term, searchColumns, projectId) {
  if (term.length > 0) {
    return await instance
      .post(`projectcostcodes/search/${projectId}/${term}`, {
        searchColumns: searchColumns || ['name']
      })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        sentryException(err, 'Unable to get Projects');
        return [];
      });
  }
  return await getProjectCostCodesByProject(projectId);
}

export async function getProjectCostCodeById(id) {
  return await instance
    .get(`projectcostcodes/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get Project Phase');
      return [];
    });
}
