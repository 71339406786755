import React, { Component } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

// Renders Buildhero branded checkbox for form inputs
class MuiCheckBox extends Component {
  handleChange = () => event => {
    const { field, form } = this.props;
    const boolAsString = !(event.target.value === 'true') ? 'true' : 'false';
    if (
      field.name === 'landlinePreferred' ||
      field.name === 'mobilePreferred' ||
      field.name === 'mailPreferred'
    ) {
      field.name === 'landlinePreferred'
        ? form.setFieldValue(field.name, boolAsString, false)
        : form.setFieldValue('landlinePreferred', 'false', false);
      field.name === 'mobilePreferred'
        ? form.setFieldValue(field.name, boolAsString, false)
        : form.setFieldValue('mobilePreferred', 'false', false);
      field.name === 'mailPreferred'
        ? form.setFieldValue(field.name, boolAsString, false)
        : form.setFieldValue('mailPreferred', 'false', false);
    } else {
      form.setFieldValue(field.name, boolAsString, false);
    }
  };

  render() {
    const { field, form, classes, disabled, ...rest } = this.props;

    return (
      <FormControlLabel
        className={classes.checkboxLabel}
        control={
          <Checkbox
            onChange={this.handleChange()}
            {...rest}
            checked={`${field.value}` === 'true'}
            className={classes.checkboxStyle}
            disabled={disabled}
            value={`${field.value}`}
          />
        }
        label={rest.placeholder}
        labelPlacement="start"
      />
    );
  }
}
const StyledCheckbox = withStyles(styles, { withTheme: true })(MuiCheckBox);
export const ReadOnlyCheckboxInput = props => <StyledCheckbox {...props} disabled />;
export default StyledCheckbox;
