import React from 'react';

import { PDFFormComponents } from '@BuildHero/sergeant';

class LineItemTable extends React.Component {
  render() {
    const { options, field } = this.props;
    const { columns, ...rest } = options;
    const columnsWithoutActions = columns.filter(column => column.type !== 'actionButtons');
    const value = field.value.items ? { value: field.value.items } : field;

    return (
      <PDFFormComponents.Table
        field={value}
        options={{ columns: columnsWithoutActions, ...rest }}
      />
    );
  }
}

export default LineItemTable;
