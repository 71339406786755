import { useCallback } from 'react';

import gql from 'graphql-tag';
import { useDispatch, useSelector } from 'react-redux';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { snackbarOn } from 'redux/actions/globalActions';
import { logErrorWithCallback } from 'utils';

export const appRolesGQL = gql`
  query getAppRoles($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      appRoles(entityConnection: "AppRole") {
        nextToken
        items {
          id
          tagName
          tagType
          appPermissions
        }
      }
    }
  }
`;

const useAppRoles = ({ transform } = {}) => {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const snackbar = useCallback((...args) => dispatch(snackbarOn(...args)), [dispatch]);

  const companySortKey = `${user.tenantId}_Company_${user.tenantCompanyId}`;
  const { data = [], loading, error } = useExtendedQuery(appRolesGQL, {
    variables: {
      partitionKey: user.tenantId,
      sortKey: companySortKey
    },
    transform: ({ getCompany }) => {
      const depts = getCompany?.appRoles?.items || [];
      if (transform) return transform(depts);
      return depts;
    }
  });

  if (error) {
    logErrorWithCallback(error, snackbar, 'Unable to get the roles');
  }
  return [data, loading];
};

export default useAppRoles;
