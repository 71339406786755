import React, { useEffect } from 'react';

// eslint-disable-next-line no-unused-vars
import { TV, TW, Typography } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';
import { CircularProgress } from '@material-ui/core';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import BuildOpsSvg from 'assets/Images/BuildOps.svg';

import { useAuthWithCode } from './ServiceChannel.api';
import styles from './ServiceChannel.style';

const ServiceChannelRedirect = ({ location }) => {
  const authenticate = useAuthWithCode();
  const history = useHistory();

  useEffect(() => {
    const code = queryString.parse(location.search)?.code;
    if (code) authenticate(code);
    else history.push('/settings/integrations');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={styles.redirect.container}>
      <img alt="BuildOps" css={styles.redirect.logo} src={BuildOpsSvg} />
      <div css={styles.redirect.center}>
        <CircularProgress css={styles.redirect.progress} size={80} />
        <Typography variant={TV.L} weight={TW.BOLD}>
          Connecting to Service Channel
        </Typography>
      </div>
    </div>
  );
};

export default ServiceChannelRedirect;
