import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const DELETE_ATTACHMENT = gql`
  mutation deleteAttachmentById($partitionKey: String!, $id: String!) {
    deleteAttachmentById(partitionKey: $partitionKey, id: $id) {
      id
    }
  }
`;

const serializer = ({ tenantId, visitId, attachmentId }) => ({
  visitId, // used for upated
  variables: {
    partitionKey: tenantId,
    id: attachmentId
  }
});

const update = (cache, { data: { deleteAttachmentById } }, ref) => {
  cache.modify({
    id: `Visit:${ref.visitId}`,
    fields: {
      attachments(existingAttachmentsRef) {
        return {
          items: existingAttachmentsRef.items.filter(
            item => item.__ref !== cache.identify(deleteAttachmentById)
          )
        };
      }
    }
  });
};

export const useDeleteAttachment = () => {
  return useExtendedMutation(DELETE_ATTACHMENT, {
    serializer,
    update
  });
};
