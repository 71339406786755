import gql from 'graphql-tag';
import { useSelector } from 'react-redux';

import useExtendedQuery from 'customHooks/useExtendedQuery';

export const TaskTemplateFragment = `
  id
  isActive
  name
  description
  numberOfOccurrences
  startDateTime
  interval
  laborEstimate
  isSingleJob
  inUse
  schedules {
    __typename
    ... on IntervalSchedule {
      dayOfMonth
      monthOfYear
      year
    }
  }
  forms {
    id
    isRequired
  }
`;

export const getTaskTemplateCheckLists = gql`
  query getTaskTemplateCheckLists($tenantId: String!, $assetTypeId: String) {
    taskTemplateChecklists(tenantId: $tenantId, assetTypeId: $assetTypeId) {
      id
      name
      assetTypeId
      isDefault
      taskTemplates {
        ${TaskTemplateFragment}
      }
    }
  }
`;

export const useChecklistLibrary = ({ assetTypeId } = {}, options = {}) => {
  const tenantId = useSelector(state => state.user)?.tenantId;

  const params = { tenantId };
  // if no assetTypeId is passed in then it fetchs all checklists for the tenant.
  if (assetTypeId) params.assetTypeId = assetTypeId;

  return useExtendedQuery(getTaskTemplateCheckLists, {
    variables: params,
    transform: result =>
      result?.taskTemplateChecklists?.map(x => ({
        ...x,
        inUse: x.taskTemplates.some(y => y.inUse)
      })),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options
  });
};

export default useChecklistLibrary;
