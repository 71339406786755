import { convertStringToFloat, getBooleanValue } from 'utils';

const constructPayload = ({
  id,
  version,
  itemName,
  reasonForFee,
  departmentName,
  productId,
  includeInInvoice,
  accountingRefId
}) => ({
  id,
  version,
  itemName,
  reasonForFee,
  includeInInvoice,
  departmentName,
  productId,
  accountingRefId
});

/* Code to include a Fee item to invoice from Review Report */

export const includeFee = async (context, event) => {
  const { services, user, fees } = context;
  const { reviewReportService } = services;
  const { data, checked } = event;

  const formattedData = (({ id, version }) => ({
    id,
    version
  }))(data);
  formattedData.includeInInvoice = checked;

  const queryData = await reviewReportService.updateFee(user.tenantId, formattedData);
  if (queryData) {
    const { updateFee } = queryData?.data;
    const modifiedFees = fees.map(fee => (fee.id === updateFee.id ? updateFee : fee));

    return { fees: modifiedFees };
  }
};

export const deleteFee = async context => {
  const { services, user, modalRecord } = context;
  const { reviewReportService } = services;

  const { data } = await reviewReportService.deleteFee(user.tenantId, modalRecord.data.id);
  const { fees } = context;

  if (data && data.deleteFee) {
    const feeIndex = fees.findIndex(fee => fee.id === modalRecord.data.id);
    fees.splice(feeIndex, 1);
  }

  return { fees, modalRecord: {}, modalIndex: '' };
};

export const editFee = async (context, event) => {
  const { services, user } = context;
  const { reviewReportService } = services;
  const { saveData } = event;

  const formattedData = constructPayload(event?.saveData);
  formattedData.id = saveData.id;
  formattedData.version = saveData.version;
  formattedData.amount = convertStringToFloat(saveData.amount) || 0.0;
  formattedData.taxable = getBooleanValue(saveData.taxable);

  const queryData = await reviewReportService.updateFee(user.tenantId, formattedData);

  const { updateFee } = queryData.data;
  const { fees } = context;

  const modifiedFees = fees.map(fee => (fee.id === updateFee.id ? updateFee : fee));

  return { fees: modifiedFees, modalRecord: {}, modalIndex: '' };
};

export const saveFee = async (context, event) => {
  const { services, id, visit } = context;
  const { reviewReportService } = services;
  const { saveData } = event;

  const formattedData = constructPayload(event?.saveData);
  formattedData.amount = convertStringToFloat(saveData.amount) || 0.0;
  formattedData.taxable = getBooleanValue(saveData.taxable);
  formattedData.reviewReportId = id;

  // To support old visits which do not have departments
  if (!saveData?.departmentName && visit.departmentName && visit.departmentName !== '') {
    formattedData.departmentName = visit.departmentName;
  }

  const data = await reviewReportService.addFeeToReviewReport(context.user.tenantId, formattedData);

  const { addFeeToReviewReport } = data.data;
  const { fees } = context;
  if (addFeeToReviewReport) {
    fees.push(addFeeToReviewReport);
    return { fees, modalRecord: {}, modalIndex: '' };
  }
};
