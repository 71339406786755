import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

/**
 * Generate HTML hidden input field
 */
const MuiInputHidden = ({ field, form, classes, user, ...rest }) => (
  <input type="hidden" {...field} {...rest} style={{ display: 'none' }} />
);
// export default MuiInputHidden;
export default withStyles(styles)(MuiInputHidden);
