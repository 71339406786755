import React from 'react';

import { Button, TV, Typography, TypographyWeight } from '@BuildHero/sergeant';
import { func } from 'prop-types';

import { useReviewReportDisabled } from '../../../../ReviewReport.contexts';

import { useStyles } from './FeesSectionHeader.styles';

const FeesSectionHeader = ({ onAddFees }) => {
  const styles = useStyles();
  const { disabled } = useReviewReportDisabled();

  return (
    <div css={styles.header}>
      <Typography style={{ marginBottom: 8 }} variant={TV.L} weight={TypographyWeight.BOLD}>
        Fees
      </Typography>
      <Button disabled={disabled} size="small" type="secondary" onClick={onAddFees}>
        Add Fee
      </Button>
    </div>
  );
};

FeesSectionHeader.propTypes = {
  onAddFees: func.isRequired
};

export default FeesSectionHeader;
