import React from 'react';

import { TV, TW, Typography } from '@BuildHero/sergeant';
import { Box, useTheme } from '@material-ui/core';

const Placeholder = ({ title, subtitle, height, subtitleWidth }) => {
  const theme = useTheme();
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px dashed #999999',
        borderRadius: '8px',
        height
      }}
    >
      <Typography component="h2" variant={TV.BASE} weight={TW.BOLD}>
        {title}
      </Typography>
      <Typography
        component="p"
        style={{
          marginTop: theme.spacing(0.5),
          lineHeight: '16px',
          textAlign: 'center',
          width: subtitleWidth
        }}
        variant={TV.S1}
        weight={TW.REGULAR}
      >
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Placeholder;
