import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';

const styles = theme => ({
  container: {
    minHeight: 15
  },
  icon: {
    color: theme.palette.other.gold,
    fontSize: 15
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  messageText: {
    color: theme.palette.text.primary,
    fontSize: 11
  }
});

const CrewWarningMessage = ({ options, classes }) => {
  const { showCrewWarningMessage } = options;
  return (
    <div className={classes.container}>
      {showCrewWarningMessage && (
        <div className={classes.messageContainer}>
          <WarningIcon className={classes.icon} />
          <div className={classes.messageText}>This crew has been modified</div>
        </div>
      )}
    </div>
  );
};

CrewWarningMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};
export default withStyles(styles)(CrewWarningMessage);
