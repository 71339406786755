/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, { useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ResponsiveTable from 'components/ResponsiveTable';
import { packageDetailSidebarForm } from 'meta/ProjectManagement/Submittals/packageDetailSidebarForm';
import submittalItemRegistryRowMeta from 'meta/ProjectManagement/Submittals/submittalItemRegistryTable';
import DetailView from 'scenes/ProjectManagement/components/DetailView';
import { getEmployeeOrCustomerRep } from 'scenes/ProjectManagement/components/utils';
import { SubmittalsPackageStatus } from 'scenes/ProjectManagement/constants';
import CreateSubmittalPackage from 'scenes/ProjectManagement/ProjectDetails/SubmittalsList/PackageLists/CreateSubmittalPackage';
import { getCompanyBytenantId } from 'services/API/companies';
import { submittalItemChange } from 'services/API/submittalItem';
import { submittalPackageChange } from 'services/API/submittalPackage';
import { useGetSubmittalPackage, useGetSubmittalPackageItems } from 'services/APIHooks';
import { AppConstants, ProjectManagementSubmittalStatus } from 'utils/AppConstants';

import { useGetSubmittalPackagePDFBlob } from './components/PackagePDF/PackagePdf.hooks';

const PackageDetailView = props => {
  const { projectId, projectData, packageId, user, open, handleClose } = props;

  const [packageAttachments, setPackageAttachments] = useState([]);
  const [companyData, setCompanyData] = useState();
  const [sendToPerson, setSendToPerson] = useState();
  const [returnToPerson, setReturnToPerson] = useState();
  const [title, setTitle] = useState('Submittal Package');

  const [{ data: packageData, loading: isPackageLoading }, refetchPackage] = useGetSubmittalPackage(
    packageId,
    {
      depends: [packageId],
      onSuccess: data => {
        setTitle(`Submittal Package${data?.number ? ` ${data.number}` : ''}`);
        setPackageAttachments(
          data?.submittalPackageAttachments?.filter?.(attachment => !attachment._deleted) || []
        );
        getCompanyBytenantId(user.tenantId).then(company =>
          setCompanyData(Array.isArray(company) ? company[0] : company)
        );
        getEmployeeOrCustomerRep(data?.sendToId).then(sendTo => setSendToPerson(sendTo));
        getEmployeeOrCustomerRep(data?.returnToId).then(returnTo => setReturnToPerson(returnTo));
      },
      skip: !packageId
    },
    { include: '*' }
  );

  const [
    { data: packageItems, loading: areItemsLoading },
    refetchPackageItems
  ] = useGetSubmittalPackageItems(packageId, {
    depends: [packageId],
    transform: items => {
      return items.map(item => ({
        ...item,
        submitBy: item.submittalItem?.submitBy || '',
        specSection: item.submittalItem?.specSection || '',
        subSection: item.submittalItem?.subSection || '',
        description: item.submittalItem?.description || '',
        status: item.submittalItem?.status || '',
        type: item.submittalItem?.type || '',
        notes: item.submittalItem?.notes || '',
        pdfAttachments: item.submittalItem?.submittalItemAttachments?.filter(
          attach => attach.fileType === 'application/pdf'
        ),
        imageAttachments: item.submittalItem?.submittalItemAttachments?.filter(attach =>
          attach.fileType.includes('image')
        )
      }));
    },
    skip: !packageId || isPackageLoading
  });

  const formatDataForSidebar = () => {
    const daysOverdue = packageData?.dueDate
      ? moment().diff(moment.unix(packageData?.dueDate), 'days')
      : 0;

    return {
      ...packageData,
      daysOverdue: daysOverdue > 0 ? daysOverdue : 0,
      createdDate: moment.unix(packageData?.createdDate).format(AppConstants.DATE_FORMAT),
      dueDate: moment.unix(packageData?.dueDate).format(AppConstants.DATE_FORMAT),
      items: packageItems?.length || 0
    };
  };

  const handleAttachmentUpload = allAttachments => {
    // NOTE: The PM Backend doesn't support removing relations when passed up in an update.
    // That means that deselecting attachments will NOT disassociate them from the Package.
    submittalPackageChange(packageId, {
      submittalPackageAttachments: allAttachments
    }).then(() => refetchPackage());
  };

  const handleEmailSent = async () => {
    if (packageData?.status === SubmittalsPackageStatus.DRAFT) {
      await submittalPackageChange(packageData?.id, {
        status: SubmittalsPackageStatus.SENT
      });

      refetchPackage();
    }
    await Promise.all(
      packageItems
        ?.filter(item => item?.status === ProjectManagementSubmittalStatus.PACKAGED)
        ?.map(item =>
          submittalItemChange(item.submittalItemId, {
            status: ProjectManagementSubmittalStatus.SENT
          })
        ) || []
    );
    refetchPackageItems();
  };

  const getSubmittalPackagePDFBlob = useGetSubmittalPackagePDFBlob({
    companyInfo: companyData,
    sendTo: sendToPerson,
    returnTo: returnToPerson,
    projectData,
    packageData,
    packageAttachments,
    packageItems
  });

  if (!packageId) {
    return <></>;
  }

  return (
    <DetailView
      attachments={packageAttachments}
      editData={{
        label: 'EDIT',
        render: (isOpen, close) => (
          <CreateSubmittalPackage
            editMode
            handleClose={() => {
              refetchPackage();
              refetchPackageItems();
              close();
            }}
            initialData={{
              type: 'edit',
              itemData: packageData
            }}
            open={isOpen}
            projectData={projectData}
            projectId={projectId}
            user={user}
          />
        )
      }}
      editingEnabled={packageData?.status !== SubmittalsPackageStatus.APPROVED}
      emailData={{
        modalTitle: 'New Submittal Package Email',
        body:
          `${packageData?.sendToCustomer?.name?.trim() || 'Customer'},` +
          '<br/>' +
          '<br/>' +
          `Please find the attached Submittal Package.` +
          '<br/><br/>' +
          `To mitigate delays on this project we ask that you return on or before ${moment
            .unix(packageData?.dueDate)
            .format(AppConstants.DATE_FORMAT)}` +
          '<br/><br/><br/>' +
          `${user.displayName}`,
        subject: `${projectData?.name || null} Submittal ${packageData?.number}`
      }}
      getPDFBlob={getSubmittalPackagePDFBlob}
      handleClose={handleClose}
      initialData={{ ...packageData, sendTo: sendToPerson, returnTo: returnToPerson }}
      isSubmitting={false}
      open={open}
      projectData={projectData}
      sidebarConfiguration={packageDetailSidebarForm}
      sidebarData={!isPackageLoading && !areItemsLoading && formatDataForSidebar()}
      title={title}
      user={user}
      onAttachmentsChange={handleAttachmentUpload}
      onEmailSent={handleEmailSent}
    >
      <Box style={{ padding: 17 }}>
        <Typography
          style={{
            fontSize: '20px',
            lineHeight: '20px',
            fontWeight: 700,
            letterSpacing: '-0.03em',
            marginBottom: 20
          }}
        >
          Submittal Package Items
        </Typography>
        <ResponsiveTable
          data={packageItems || []}
          disableFilter
          noDataMsg="No Submittal Package Items"
          rowMetadata={submittalItemRegistryRowMeta}
          tableName="ProjectManagementSubmittalDetailItems"
        />
      </Box>
    </DetailView>
  );
};

PackageDetailView.propTypes = {
  packageId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  projectData: PropTypes.object.isRequired
};
PackageDetailView.defaultProps = {};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(PackageDetailView);
