import React, { useEffect, useState } from 'react';

import { Select, ThemeProvider } from '@BuildHero/sergeant';
import { Grid } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';

import ErrorBoundaries from 'scenes/Error';

import { PageHeader } from '../../components';

import { useEmbedsQuery, useGenerateSigmaEmbedLinkMutation } from './sigma.gql';

const MetabaseAppEmbed = () => {
  const tenantId = useSelector(state => state.user?.tenantId);

  const flags = useFlags();
  const [src, setSrc] = useState();
  const [activeEmbed, setActiveEmbed] = useState();
  const { loading, data: embeds } = useEmbedsQuery(tenantId);
  const { generateEmbedUrl } = useGenerateSigmaEmbedLinkMutation();

  const activateEmbed = async embedOption => {
    if (activeEmbed?.id !== embedOption.value) {
      setActiveEmbed(embeds.find(e => e.id == embedOption.value));
      const embedLink = await generateEmbedUrl(tenantId, embedOption.value);
      setSrc(embedLink.url);
    }
  };

  useEffect(async () => {
    const embed = (embeds ?? []).find(e => e.isDefault);
    if (embed) {
      await activateEmbed({ value: embed?.id });
    }
  }, [embeds]);

  if (!flags.sigma) return null;

  const headerButtons = (
    <Grid item xs={8}>
      <ThemeProvider>
        <Select
          loading={loading}
          options={embeds?.map(e => ({ value: e.id, label: e.sigmaEmbedName }))}
          searchable
          value={
            activeEmbed ? { value: activeEmbed.id, label: activeEmbed.sigmaEmbedName } : undefined
          }
          onChange={activateEmbed}
        />
      </ThemeProvider>
    </Grid>
  );

  return (
    <ErrorBoundaries>
      <PageHeader
        overrideHeaderButtons={headerButtons}
        pageMapKey="reportsV2"
        title="Dashboard V2"
      />
      {src ? (
        <iframe
          src={src}
          style={{ border: 'none', width: '100%', height: 'calc(100vh - 212px)' }}
          title={activeEmbed?.sigmaEmbedName}
        />
      ) : (
        <Skeleton height="calc(100vh - 212px)" width="100%" />
      )}
    </ErrorBoundaries>
  );
};

export default MetabaseAppEmbed;
