export const useStyles = () => ({
  title: {
    marginBottom: 4
  },
  subtitle: {
    marginBottom: 24
  },
  attachmentsContainer: theme => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flexStart',
    padding: 24,
    border: `1px solid ${theme.palette.grayscale(80)}`,
    borderRadius: 16
  }),
  inlineAlert: {
    marginTop: 12
  }
});
