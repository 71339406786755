/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';

import { round, roundFive } from '@BuildHero/math';
import { MUIForm } from '@BuildHero/sergeant';
import { makeStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { pickBy } from 'lodash';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import DefaultButton from 'components/Buttons/DefaultButton';
import Labels from 'meta/labels';
import { addItemsFields, addItemsLayout } from 'meta/Procurement/PurchaseOrders/addItemsForm';
import { editItemLayout } from 'meta/Procurement/PurchaseOrders/editItemFormWithAlgoliaSearch';
import MoreAction from 'scenes/Procurement/component/MoreAction';
import MuiFormSectionTitle from 'scenes/Procurement/component/MuiFormSectionTitle';
import PurchaseOrderCost from 'scenes/Procurement/component/PurchaseOrderCost';
import { SelectedItem } from 'scenes/Procurement/constants';
import ProcurementUtils from 'scenes/Procurement/Procurement.utils';
import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';
import buildHeroMuiFormOverridesWithCheckbox from 'scenes/ProjectManagement/components/buildHeroMuiFormOverridesWithCheckbox';
import CustomFieldWithLabel from 'scenes/ProjectManagement/components/CustomFieldWithLabel';
import { generateDefaultValidationSchema } from 'scenes/ProjectManagement/components/formattingUtils';
import { checkRequiredFieldsFilled } from 'scenes/ProjectManagement/components/utils';
import { getProducts, productSearch } from 'services/API/product';
import { getNumberOfDigits, parseFloatAndRound, roundCurrency } from 'utils';

import FeatureFlagConstants from 'utils/FeatureFlagConstants';

import EditItemModal from '../EditItemModal';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    left: '0%',
    right: '0px',
    top: '60px',
    padding: '80px 24px'
  },
  itemsContainer: {
    minWidth: 600,
    marginRight: 8,
    [theme.breakpoints.between(1280, 1400)]: {
      minWidth: 980
    },
    [theme.breakpoints.up(1400)]: {
      minWidth: 1140
    }
  },
  pocContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 7,
    maxWidth: 220,
    alignItems: 'flex-end'
  },
  searchBox: {
    marginRight: 16
  },
  formContainer: buildHeroMuiFormOverridesWithCheckbox(theme)
}));

const AddItemsStep = ({ jobAndProject, associatedProject, setSaveAsDraftEnabled, ...props }) => {
  const classes = useStyles();
  const { userLocale, onClickNextStep, itemsData, costsData, dataReducer } = props;
  const [goNextStep, setGoNextStep] = useState(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedEditItem, setSelectedEditItem] = useState({});
  const flags = useFlags();
  const areJobCostDetailsRequired = useSelector(
    state =>
      state.settings.isVistaEnabled ||
      (flags[FeatureFlagConstants.SPECTRUM_JOB_COST_DETAILS] && state.settings.isSpectrumEnabled)
  );
  const requiredFields = pickBy(
    addItemsFields(associatedProject, areJobCostDetailsRequired),
    value => value?.required === true
  );
  useEffect(() => {
    const result = itemsData.every(item => checkRequiredFieldsFilled(item, requiredFields));

    if (result) {
      setGoNextStep(true);
      setSaveAsDraftEnabled(true);
    } else {
      setGoNextStep(false);
      setSaveAsDraftEnabled(false);
    }
  }, [itemsData, requiredFields, setSaveAsDraftEnabled]);

  const muiFormRef = useRef();

  const CustomMoreAction = ({ options, field }) => {
    const handleEdit = async () => {
      setSelectedEditItem(field.value);
      setEditModalOpen(true);
    };
    const handleDelete = () => {
      dataReducer({ type: 'deleteItem', payload: field.value });
    };

    const actions = [{ label: 'Delete', icon: 'Delete', onClick: handleDelete }];
    if (!associatedProject?.id) {
      actions.push({ label: 'Edit', icon: 'Edit', onClick: handleEdit });
    }

    return <MoreAction actionsList={actions} options={options} style={{ height: 41, width: 41 }} />;
  };

  const getFormattedData = (data, index = null) => {
    return {
      itemNumber: data?.itemNumber || index + 1,
      itemName: data?.itemName || '',
      description: data?.description || '',
      unitOfMeasure: data?.unitOfMeasure || '',
      unitCost: roundFive(String(data?.unitCost)),
      quantity: round(String(data?.quantity), 2),
      totalCost: roundCurrency(String(data?.totalCost)),
      costCodeId: data?.costCodeId || '',
      costCodeDescription: data?.costCode?.description || data?.costCodeDescription || '',
      costCodeObj: data?.costCodeObj || null,
      revenueTypeObj: data?.revenueTypeObj || null,
      jobCostType: data?.jobCostType || null,
      jcPhase: data?.jcPhase || null,
      jcCostType: data?.jcCostType || null,
      phaseDepartment: data?.phaseDepartment || null,
      projectPhase: data?.projectPhase || null,
      projectCostCode: data?.projectCostCode || null,
      projectCostType: data?.projectCostType || '',
      jobOrProjectDisplay: data?.jobOrProjectDisplay || jobAndProject,
      productId: data?.productId || '',
      jobId: data?.jobId || null,
      projectId: data?.projectId || null,
      departmentId: data?.deparmentId || '',
      taxable: data?.taxable || null
    };
  };

  const handleCostFormChange = data => {
    dataReducer({ type: 'editCosts', payload: data });
  };

  const handleItemsFormChange = data => {
    dataReducer({ type: 'editItems', payload: data });
  };

  const handleChangeUnitCost = ({ form, currentValue }) => {
    const { quantity } = form.values;
    if (!currentValue || getNumberOfDigits(parseFloat(currentValue)) < 5) {
      form.setFieldValue(SelectedItem.UNIT_COST, currentValue);
      form.setFieldValue(SelectedItem.TOTAL_COST, roundCurrency(String(currentValue * quantity)));
    } else {
      form.setFieldValue(SelectedItem.UNIT_COST, roundFive(String(currentValue)));
      form.setFieldValue(SelectedItem.TOTAL_COST, roundCurrency(String(currentValue * quantity)));
    }
  };

  const handleChangeQuantity = ({ form, currentValue }) => {
    const { unitCost } = form.values;

    form.setFieldValue(SelectedItem.QUANTITY, parseFloatAndRound(currentValue, 2));
    form.setFieldValue(SelectedItem.TOTAL_COST, roundCurrency(unitCost * currentValue));
  };

  const handleEditItemSave = (completed, stopLoading) => {
    stopLoading();
    setEditModalOpen(false);
    dataReducer({ type: 'editItems', payload: completed });
  };

  const handleClickNextBtn = () => {
    onClickNextStep();
  };

  const handleAddItem = item => {
    if (!item) return;

    let nextNumber = 1;
    let itemWithNumber = itemsData.filter(i => i.itemNumber === nextNumber);
    while (itemWithNumber.length) {
      // eslint-disable-next-line no-plusplus
      nextNumber++;
      // eslint-disable-next-line no-loop-func
      itemWithNumber = itemsData.filter(i => i.itemNumber === nextNumber);
    }

    const addedItem = {
      itemNumber: nextNumber,
      itemName: item?.name || '',
      description: item?.description || '',
      unitOfMeasure: item?.unitOfMeasure?.name || '',
      unitCost: roundFive(String(item?.unitCost)) || '0.00000',
      quantity: item?.quantity || '0.00',
      totalCost: roundCurrency(String(item?.unitCost * item?.quantity)) || '0.00',
      costCodeId: item?.costCodeId || '',
      costCodeDescription: item?.costCode?.description || '',
      costCodeObj: { ...item?.costCode } || {},
      jobCostType: { ...item?.jobCostType } || {},
      jcCostTypeId: item?.jcCostType?.id || item?.jcCostTypeId || null,
      jcPhaseId: item?.jcPhase?.id || item?.jcPhaseId || null,
      phaseDepartment: null,
      projectPhaseId: item?.projectPhase?.id || item?.projectPhaseId || null,
      projectCostCodeId: item?.projectCostCode?.id || item?.projectCostCodeId || null,
      projectCostType: item?.projectCostType || '',
      revenueTypeId: item?.revenueTypeId || '',
      revenueTypeObj: { ...item?.revenueType } || {},
      productId: item?.id || '',
      departmentId: props?.poInfo?.generalInfo?.department?.id || '',
      departmentName: props?.poInfo?.generalInfo?.department?.name || '',
      jobId: props?.poInfo?.generalInfo?.jobId || null,
      projectId: props?.poInfo?.generalInfo?.projectId || null,
      jobAndProject,
      taxable: item?.taxable
    };

    dataReducer({ type: 'addItem', payload: addedItem });

    setTimeout(() => {
      if (muiFormRef.current) {
        // to force focus on the Description field
        muiFormRef.current.children[0].children[0].children[0].children[1].children[1].children[0].children[0].focus();
      }
    }, 100);
  };

  const handleJobOrProjectIdChange = (selectedItem, _selectedItemName, form) => {
    const modifiedValues = {
      ...form.values,
      jobOrProjectDisplay:
        selectedItem?.customIdentifier ||
        selectedItem?.jobNumber ||
        selectedItem?.projectName ||
        selectedItem?.projectNumber ||
        '',
      projectId: selectedItem?.projectNumber ? selectedItem?.id : null,
      jobId: selectedItem?.jobNumber ? selectedItem?.id : null
    };
    form.setValues(modifiedValues);
  };

  return (
    <div className={classes.root}>
      <div className={classes.itemsContainer}>
        <MuiFormSectionTitle options={{ label: 'Add Items' }} />
        <div className={classes.formContainer}>
          {itemsData?.map((item, index) => (
            <div ref={muiFormRef}>
              <MUIForm
                configuration={addItemsLayout({
                  showLabel: index === 0,
                  handleChangeUnitCost,
                  handleChangeQuantity,
                  handleSearchSelect: ProcurementUtils.handleSearchSelect,
                  handleSetDefaultValue: ProcurementUtils.handleSetDefaultValue,
                  associatedProject,
                  areJobCostDetailsRequired,
                  item
                })}
                customComponents={{
                  CustomFieldWithLabel,
                  CustomMoreAction,
                  SearchBar
                }}
                data={getFormattedData(item, index)}
                key={`procurement-po-items${item.itemNumber}`}
                layout="edit"
                validationSchema={generateDefaultValidationSchema(addItemsFields)}
                onComplete={() => {}}
                onCreateService={() => {}}
                onFormChange={data => handleItemsFormChange(data)}
              />
            </div>
          ))}
          <SearchBar
            className={classes.searchBox}
            key="itemSearch"
            options={{
              placeholder: 'Search Product',
              searchFunction: productSearch,
              emptySearchFunction: getProducts,
              hideOptions: false,
              useId: false,
              resultFormatFunction: product => product.name,
              searchColumn: ['name'],
              variant: 'standard',
              color: 'secondary',
              clearOnSelect: true
            }}
            onSelectionChange={results => handleAddItem(results)}
          />
        </div>
      </div>
      <div className={classes.pocContainer}>
        <PurchaseOrderCost
          formLayout="edit"
          freightCosts={costsData.freightCost || 0}
          lineItems={itemsData}
          taxAmounts={costsData.taxAmount || 0}
          taxRate={costsData.taxRate || {}}
          taxRateId={costsData.taxRate?.id || costsData.taxRateId || ''}
          useCalculatedAmt
          onFormChange={handleCostFormChange}
        />
        <DefaultButton
          disabled={!goNextStep}
          label={Labels.nextStep[userLocale]}
          style={{ width: 82, height: 30, fontSize: 12, padding: 8, marginTop: 16 }}
          variant={goNextStep ? 'containedSecondary' : 'contained'}
          onClick={() => handleClickNextBtn()}
        />
      </div>
      <EditItemModal
        btnLabel={Labels.saveChangesButtonText[userLocale]}
        formData={getFormattedData(selectedEditItem)}
        formLayout={editItemLayout(handleJobOrProjectIdChange)}
        handleModalClose={() => setEditModalOpen(false)}
        handleSaveChange={handleEditItemSave}
        open={editModalOpen}
        title="Edit Purchase Order Item"
      />
    </div>
  );
};

AddItemsStep.propTypes = {
  userLocale: PropTypes.string.isRequired,
  onClickNextStep: PropTypes.func.isRequired,
  itemsData: PropTypes.array.isRequired,
  costsData: PropTypes.object.isRequired,
  dataReducer: PropTypes.func.isRequired,
  associatedProject: PropTypes.object.isRequired,
  isVistaEnabled: PropTypes.bool.isRequired
};

export default AddItemsStep;
