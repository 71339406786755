import React, { useCallback, useState } from 'react';

import {
  Button,
  ButtonType,
  DateInput,
  Modal,
  ThemeProvider,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';
import { IconButton } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import gql from 'graphql-tag';
import { useDispatch } from 'react-redux';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { snackbarOn } from 'redux/actions/globalActions';
import { backendDateToMoment } from 'utils';
import { getDateFormat } from 'utils/AppConstants';

const MUTATE_DUE_DATE = gql`
  mutation updateJobAndRelated($data: UpdateJobAndRelatedInput!) {
    updateJobAndRelated(data: $data) {
      id
      version
      dueDate
    }
  }
`;

const getDateText = (data, timezone) => {
  const momentObj = backendDateToMoment(data, timezone);
  return momentObj.isValid() ? momentObj.format(getDateFormat()) : '-';
};

const DueDate = ({ meta, record, refreshList, timezone }) => {
  const localRecord = record;
  const [open, setOpen] = useState();
  const [requiredError, setRequiredError] = useState(false);
  const [dueDate, setDueDate] = useState(localRecord?.[meta.id]);

  const dispatch = useDispatch();
  const snackbar = useCallback((...args) => dispatch(snackbarOn(...args)), [dispatch]);

  const [updateDueDate] = useExtendedMutation(MUTATE_DUE_DATE, {
    serializer: data => ({
      variables: {
        data: {
          customerPropertyId: data.customerPropertyId,
          ignoreDepartmentUpdates: true,
          jobs: [data]
        }
      }
    })
  });

  const updateDueDateInMaintenance = async () => {
    if (!dueDate) return setRequiredError(true);
    if (requiredError) setRequiredError(false);

    const updatedResult = await updateDueDate({
      id: record.id,
      version: record.version,
      dueDate,
      customerPropertyId: record.customerPropertyId,
      status: record.status
    });
    const updatedJob = updatedResult?.data?.updateJobAndRelated?.[0]?.id;
    if (updatedJob) {
      setOpen(false);
      snackbar('success', 'Successfully updated');
      refreshList();
    }
  };

  return (
    <ThemeProvider>
      <Typography
        css={{ minWidth: 86, cursor: 'pointer' }}
        underlined
        variant={TV.BASE}
        weight={TW.REGULAR}
        onClick={() => setOpen(true)}
      >
        {getDateText(record?.[meta.id], timezone)}
      </Typography>
      <IconButton
        aria-label="Edit due date"
        css={{ padding: 0, marginLeft: 4 }}
        onClick={() => setOpen(true)}
      >
        <CreateIcon color="primary" css={{ fontSize: 14 }} />
      </IconButton>
      {open && (
        <Modal
          actions={
            <Button fullWidth type={ButtonType.PRIMARY} onClick={updateDueDateInMaintenance}>
              Save
            </Button>
          }
          open={open}
          PaperProps={{ style: { minWidth: 454 } }}
          title="Edit Due Date"
          onClose={() => {
            setOpen(false);
            setRequiredError(false);
            setDueDate(record?.[meta.id]);
          }}
        >
          <DateInput
            error={requiredError}
            label="Due Date"
            required
            style={{ paddingBottom: 16 }}
            subtext={requiredError ? 'Due date is required' : ''}
            timezone={timezone}
            type="date"
            value={dueDate}
            onChange={newDate => setDueDate(newDate)}
          />
        </Modal>
      )}
    </ThemeProvider>
  );
};

export default React.memo(
  DueDate,
  (prevProps, nextProps) => prevProps.record.id !== nextProps.record.id
);
