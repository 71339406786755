import React from 'react';

import { Button, Divider, Input, Label, MoreButton } from '@BuildHero/sergeant';
import { Box, Grid } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { LABEL_SCHEMA } from '../constants';

export const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    border: `1px solid #808080`,
    borderRadius: `4px`,
    '&:not(:last-child)': {
      borderBottom: 'none'
    },
    margin: '0 0 0 0 !important',
    '&:before': {
      display: 'none'
    }
  },
  expanded: {
    margin: '0 0 0 0 !important'
  }
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    padding: 0,
    minHeight: 0,
    borderBottom: `1px solid #808080`,
    '&.Mui-expanded': {
      padding: 0,
      minHeight: 0
    },
    '& [class^="MuiAccordionSummary-content"]': {
      margin: 0
    }
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: '0'
    }
  }
})(props => {
  const {
    taskGroup,
    classes,
    provided,
    locale,
    onNameChange,
    onCollapseTaskGroup,
    onDeleteTaskGroup
  } = props;
  return (
    <MuiAccordionSummary className={classes.accordionSummary}>
      <div className={classes.dragHandler} {...provided.dragHandleProps}>
        <DragIndicatorIcon />
      </div>
      <Grid container justify="space-between">
        <Grid container item style={{ display: 'flex', alignItems: 'center' }} xs={9}>
          <Box className={classes.taskName} justify="flex-start">
            <Input
              id="scope-input-123456789}"
              placeholder={LABEL_SCHEMA.addTaskPlaceholder[locale]}
              style={{ marginLeft: '2px' }}
              onChange={e =>
                onNameChange({
                  taskGroupNumber: taskGroup.groupNumber,
                  currentValue: e.target.value
                })
              }
            />
          </Box>
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
          xs={2}
        >
          <Button
            startIcon={
              taskGroup?.expanded ? (
                <ExpandLessIcon className={classes.expandCollapseIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandCollapseIcon} />
              )
            }
            style={{ padding: 0 }}
            type="leading"
            onClick={() => onCollapseTaskGroup(taskGroup)}
          />
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
          xs={1}
        >
          <MoreButton
            className={classes.moreButton}
            options={[
              {
                label: 'Delete',
                icon: DeleteIcon,
                onClick: () => {
                  onDeleteTaskGroup(taskGroup);
                }
              }
            ]}
          />
        </Grid>
      </Grid>
    </MuiAccordionSummary>
  );
});

AccordionSummary.muiName = 'AccordionSummary';

export const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(props => {
  const { taskGroup, classes, onDescriptionChange, locale, children } = props;
  return (
    <MuiAccordionDetails>
      <Grid container>
        <Grid item xs={12}>
          <Input
            className={classes.taskDescription}
            label={LABEL_SCHEMA.taskDescriptionPlaceholder[locale]}
            onChange={e =>
              onDescriptionChange({
                taskGroupNumber: taskGroup.groupNumber,
                currentValue: e.target.value
              })
            }
          />
        </Grid>
        <Divider />
        {children}
      </Grid>
    </MuiAccordionDetails>
  );
});
