const transformToCustomerPropertySchema = (
  propertyData,
  customerId,
  syncImmedietlySettingEnabled,
  isInsert
) => {
  const defaultValue = isInsert ? undefined : null; // only null removes the data from database

  const customFields = {};
  const { formAttributes } = propertyData;
  if (propertyData && formAttributes) {
    Object.keys(propertyData).forEach(dataItem => {
      if (dataItem.startsWith('Custom_')) {
        customFields[dataItem.replace('Custom_', '')] = propertyData[dataItem] || null;
      }
    });
    customFields.formId = formAttributes.formId;
    customFields.formSortKey = formAttributes.formSortKey;
    customFields.formDefinitionId = formAttributes.formDefinitionId;
    customFields.formDefinitionSortKey = formAttributes.formDefinitionSortKey;
    if (propertyData.formDataId) {
      customFields.id = propertyData.formDataId;
      customFields.version = propertyData.formDataVersion;
    }
  }

  // object according to CustomerPropertyAndRelatedInput
  const payload = {
    // customerId is the property's parentEntity's id
    customerId,
    customerProperties: [
      {
        companyName: propertyData?.companyName || defaultValue,
        accountNumber: propertyData?.accountNumber || defaultValue,
        customerPropertyTypeValue: propertyData?.customerPropertyTypeValue || defaultValue,
        billTo: propertyData?.billTo || defaultValue,
        billingCustomerId: propertyData?.billingCustomerId,
        id: propertyData.id || defaultValue,
        version: propertyData.version || defaultValue,
        isActive: propertyData.isActive ?? true,
        status: propertyData.status || 'active',
        isTaxable: propertyData.isTaxable === 'true' || propertyData.isTaxable === true,
        sameAddress: propertyData.sameAddress === 'true' || propertyData.sameAddress === true,
        taxRateId: propertyData.taxRateId || defaultValue,
        priceBookId: propertyData.priceBookId || defaultValue
      }
    ]
  };

  if (propertyData.propertyAddressLine1) {
    payload.customerProperties[0].companyAddresses = [
      {
        addressLine1: propertyData.propertyAddressLine1 || defaultValue,
        addressLine2: propertyData.propertyAddressLine2 || defaultValue,
        city: propertyData.propertyCity || defaultValue,
        state: propertyData.propertyState || defaultValue,
        zipcode: propertyData.propertyZipcode || defaultValue,
        latitude: propertyData.propertyLatitude || defaultValue,
        longitude: propertyData.propertyLongitude || defaultValue,
        id: propertyData.propertyAddressId || defaultValue,
        isActive: propertyData.isActive || defaultValue,
        status: propertyData.status || defaultValue,
        addressType: 'propertyAddress'
      }
    ];
  }

  if (propertyData.propertyInstruction) {
    payload.customerProperties[0].notes = [{ note: propertyData.propertyInstruction }];
  }

  if (syncImmedietlySettingEnabled) {
    payload.customerProperties[0].syncStatus = 'Syncing';
  } else {
    payload.customerProperties[0].syncStatus = null;
  }

  if (propertyData && formAttributes?.formId) {
    payload.customerProperties[0].formData = customFields;
  }

  return payload;
};

export default transformToCustomerPropertySchema;
