import React, { useEffect, useMemo, useState } from 'react';

import { DateInput, Select, Button as SergeantButton, ThemeProvider } from '@BuildHero/sergeant';
import { Button, ButtonGroup } from '@material-ui/core';
import { findLastIndex, isEmpty, noop, take, takeRight } from 'lodash';
import moment from 'moment-timezone';

import { AppConstants } from 'utils/AppConstants';

import { timesheetViews } from '../../constants';
import { usePrevNextTimesheetForEmployee } from '../../customHooks';

import { updateTimesheetUrl } from '../../services';
import { startDayToday } from '../helpers';

const ButtonDirections = {
  PREVIOUS: { value: 'PREV', label: 'previous' },
  NEXT: { value: 'NEXT', label: 'next' }
};

const useStyles = () => ({
  controlContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: -70
  },
  innerControlContainer: {
    width: 500
  }
});

const formatTimesheetPeriod = (period, timezone) => {
  if (!period || !timezone) return {};

  const start = moment.tz(moment.unix(period.dateStartUTC).format(), timezone);
  const end = moment.tz(moment.unix(period.dateEndUTC).format(), timezone);

  return {
    label: `${start.format(AppConstants.DATE_FORMAT)} - ${end.format(AppConstants.DATE_FORMAT)}`,
    value: period
  };
};

const DateNavigation = ({
  selectedEmployee,
  timesheetPeriods = [],
  payrollSetting,
  snackbarOn,
  setExportSelectedDate,
  setIsExportDisabled = noop,
  selectedDate = null,
  setSelectedDate = noop,
  selectedPeriod,
  setSelectedPeriod = noop,
  dayOrWeek = timesheetViews.DAY,
  setDayOrWeek = noop,
  weekDate,
  weekDates,
  availableStatuses = [],
  setTimesheetEntriesToUpdate = noop,
  setTimesheetEntryBindersToUpdate = noop
}) => {
  const styles = useStyles();

  const [direction, setDirection] = useState('');
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(true);

  const today = startDayToday(payrollSetting.timeZone);

  const timesheetPeriodOptions = useMemo(() => {
    const currentTimesheetPeriods = [...timesheetPeriods].filter(
      period => period.dateStartUTC <= today
    );
    const sortedPeriods = currentTimesheetPeriods.sort(
      (a, b) => parseFloat(b.dateStartUTC) - parseFloat(a.dateStartUTC)
    );
    return sortedPeriods.map(p => formatTimesheetPeriod(p, payrollSetting.timeZone));
  }, [timesheetPeriods, payrollSetting.timeZone]);

  const handleDateSelect = async date => {
    setSelectedDate(date);
    setDisablePrev(false);
    setDisableNext(false);
    if (!isEmpty(weekDate)) {
      setExportSelectedDate({
        dateStartUTC: weekDate.dayStartUTC,
        dateEndUTC: weekDate.dayEndUTC
      });
      setIsExportDisabled(false);
    }
  };

  const handlePeriodSelection = newPeriod => {
    setSelectedPeriod(newPeriod);
    setExportSelectedDate({
      dateStartUTC: newPeriod.dateStartUTC,
      dateEndUTC: newPeriod.dateEndUTC
    });
    setIsExportDisabled(false);
  };

  const reset = (nextMode = null) => {
    if (nextMode === timesheetViews.DAY) {
      handleDateSelect(selectedDate);
    }

    if (nextMode === timesheetViews.WEEK) {
      handlePeriodSelection(selectedPeriod);
    }

    setTimesheetEntriesToUpdate([]);
    setTimesheetEntryBindersToUpdate([]);
  };

  useEffect(() => {
    if (dayOrWeek === timesheetViews.WEEK) setIsExportDisabled(true);
    reset(dayOrWeek);
  }, [timesheetPeriods]);

  const [
    getPrevNextTimesheetForEmployee,
    { loading: prevNextLoading }
  ] = usePrevNextTimesheetForEmployee({
    onCompleted: result => {
      const nextTimesheet = result?.prevNextTimesheetForEmployee;
      if (!nextTimesheet) {
        snackbarOn('error', `No ${direction.label} timesheets for review`);
        if (direction === ButtonDirections.PREVIOUS) {
          setDisablePrev(true);
        } else {
          setDisableNext(true);
        }
        return;
      }
      setSelectedPeriod(nextTimesheet.timesheetPeriod);
      handleDateSelect(nextTimesheet.startDayCompanyTZ);
    }
  });

  const switchPeriod = dir => {
    getPrevNextTimesheetForEmployee({
      employeeId: selectedEmployee?.id,
      statuses: availableStatuses,
      date: selectedDate,
      dir: dir.value
    });
  };

  const handleClickPrev = () => {
    setDirection(ButtonDirections.PREVIOUS);
    if (!selectedPeriod?.id) {
      switchPeriod(ButtonDirections.PREVIOUS);
    } else {
      const weekDateIndex = weekDates.findIndex(
        ({ dayStartUTC, dayEndUTC }) => dayStartUTC <= selectedDate && selectedDate <= dayEndUTC
      );
      const prevDates = take(weekDates, weekDateIndex);
      const i = findLastIndex(prevDates, d => d.workEvents.length > 0);
      const prevDate = parseInt(selectedDate, 10) - (prevDates.length - i) * 86400; // 86400 seconds in a day

      if (i !== -1) {
        handleDateSelect(prevDate);
      } else {
        switchPeriod(ButtonDirections.PREVIOUS);
      }
    }
  };

  const handleClickNext = () => {
    setDirection(ButtonDirections.NEXT);
    if (!selectedPeriod?.id) {
      switchPeriod(ButtonDirections.NEXT);
    } else {
      const weekDateIndex = weekDates.findIndex(
        ({ dayStartUTC, dayEndUTC }) => dayStartUTC <= selectedDate && selectedDate <= dayEndUTC
      );
      const nextDates = takeRight(weekDates, weekDates.length - weekDateIndex - 1);
      const i = nextDates.findIndex(d => d.workEvents.length > 0 && d.dayStartUTC <= today);
      const nextDate = parseInt(selectedDate, 10) + (i + 1) * 86400; // 86400 seconds in a day

      if (i !== -1) {
        handleDateSelect(nextDate);
      } else {
        switchPeriod(ButtonDirections.NEXT);
      }
    }
  };

  return (
    <div css={styles.controlContainer}>
      <ButtonGroup>
        <Button
          color={dayOrWeek === timesheetViews.DAY && 'primary'}
          variant={dayOrWeek === timesheetViews.DAY && 'contained'}
          onClick={() => {
            if (dayOrWeek === timesheetViews.DAY) return;
            setDayOrWeek(timesheetViews.DAY);
            reset(timesheetViews.DAY);
          }}
        >
          DAY
        </Button>
        <Button
          color={dayOrWeek === timesheetViews.WEEK && 'primary'}
          variant={dayOrWeek === timesheetViews.WEEK && 'contained'}
          onClick={() => {
            if (dayOrWeek === timesheetViews.WEEK) return;
            setIsExportDisabled(true);
            setDayOrWeek(timesheetViews.WEEK);
            reset(timesheetViews.WEEK);
          }}
        >
          WEEK
        </Button>
      </ButtonGroup>
      <div css={styles.innerControlContainer}>
        <ThemeProvider>
          {dayOrWeek === timesheetViews.DAY && (
            <>
              <DateInput
                style={{ marginLeft: 24, height: 36, width: 250 }}
                timezone={payrollSetting.timeZone}
                value={selectedDate}
                onChange={date => {
                  handleDateSelect(date);
                  updateTimesheetUrl({ employee: selectedEmployee, date });
                }}
              />
              <SergeantButton
                disabled={disablePrev}
                loading={prevNextLoading && direction === ButtonDirections.PREVIOUS}
                style={{ height: 36, margin: '0 24px', padding: '5px 15px' }}
                type="tertiary"
                onClick={handleClickPrev}
              >
                Previous
              </SergeantButton>
              <SergeantButton
                disabled={disableNext}
                loading={prevNextLoading && direction === ButtonDirections.NEXT}
                style={{ height: 36, padding: '5px 15px' }}
                type="tertiary"
                onClick={handleClickNext}
              >
                Next
              </SergeantButton>
            </>
          )}
          {dayOrWeek === timesheetViews.WEEK && (
            <Select
              defaultValue={timesheetPeriodOptions.find(
                option => selectedPeriod?.id === option.value.id
              )}
              menuHeight={300}
              options={timesheetPeriodOptions}
              style={{ marginLeft: 24, height: 36, width: 250 }}
              onChange={({ value }) => handlePeriodSelection(value)}
            />
          )}
        </ThemeProvider>
      </div>
    </div>
  );
};

export default DateNavigation;
