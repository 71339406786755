import { ItemType } from 'scenes/Quotes/constants';

import { smartFields } from '../../CKEditor.constants';
import { convertToCurrencyStringCheck } from '../../CKEditor.utils';

const lineItemsOrDiscounts = (smartFieldInfo, type) => {
  const filteredQuoteLineProducts = smartFieldInfo.quoteLineProducts.items.filter(
    item => item.lineItemType === type
  );

  if (!filteredQuoteLineProducts.length) return '';
  const smartfieldType = type === ItemType.MATERIAL ? smartFields.LINE_ITEMS : smartFields.DISCOUNT;
  const productRows = filteredQuoteLineProducts.map(
    item =>
      `<tr>
          <td colspan="2" style="width:50%;border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);">
            <span><strong>${item.description}</strong></span>
          </td>
          <td style="border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);text-align:right;">
            <span>${
              type === ItemType.DISCOUNT
                ? `-${convertToCurrencyStringCheck(item.unitPrice)}`
                : `${convertToCurrencyStringCheck(item.unitPrice)}`
            }</span>
          </td>
        </tr>`
  );

  return `<figure>
              <table style="width:80%;float:right;border-color:hsl(207, 90%, 54%, 0);border-style:solid;" data-smartfield=${smartfieldType}>
                <tbody>${productRows.join('')}</tbody>
              </table>
            </figure>`;
};
export default lineItemsOrDiscounts;
