import React, { Component } from 'react';

import { Grid, Typography, withTheme } from '@material-ui/core';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { merge, omit, orderBy, pick, reduce } from 'lodash';

import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  ChipArray,
  Notes,
  ResponsiveTable,
  SergeantModal,
  Spinner,
  StatusChip,
  Tab,
  Tabs,
  UserPermission
} from 'components';
import Attachment from 'components/AttachmentSection';
import AuditLogsSection from 'components/AuditLogs';
import AddressEditAndView from 'components/BuildHeroFormComponents/AddressEditAndView';
import AlgoliaSearchWrapper from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import LinkButtonForm from 'components/BuildHeroFormComponents/LinkButtonForm';
import Context from 'components/Context';
import LeftSidebarWithContent from 'components/Layouts/LeftSidebarWithContent';
import { AddPropertyLayout, eventRows, jobRowMetaByProp } from 'meta/Customer/CustomerProperty';
import propertyViewLayout from 'meta/Customer/CustomerProperty/view';
import entityRouteMappings from 'meta/entityRouteMappings';
import Labels from 'meta/labels';
import { propertyMaintenanceRowsMeta } from 'meta/ServiceAgreements/tables/maintenance';
import statusChips from 'meta/statusChips';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import LocationView from 'scenes/Maintenance/DetailView/CustomComponents/LocationView';
import {
  CommonService,
  CustomerPropertyService,
  CustomerService,
  QuickbooksService
} from 'services/core';
import AccountingValidationService from 'services/core/graphql-services/AccountingValidation';
import { Logger } from 'services/Logger';
import {
  capitalizeFirstLetter,
  getAddressAndLocation,
  isTenantSettingEnabled,
  logErrorWithCallback,
  sortArrayWithCaseInsensitive,
  upsertList
} from 'utils';

import Constants, {
  AddressType,
  AppConstants,
  JobStatus,
  PermissionConstants,
  QuoteConstants
} from 'utils/AppConstants';
import {
  AccountType,
  EmailInvoiceTypeLabel,
  EntityType,
  Mode,
  TenantSetting
} from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { fetchInlineForm } from '../CustomerDetail/utils';

import PropertyAssetList from './Assets/PropertyAssetList';
import getDropDownOptions from './helpers';
import getMainSectionLayout, { sidebarSection } from './layout';
import Quotes from './Quotes/QuoteTab';
import Reps from './Reps';
import Tasks from './Tasks';
import Tasks2 from './Tasks/TaskTab';

/**
 * Creates customer page. new customer fields & layouts are generated from the meta file
 * labels are fetched from application level
 * locale of the user is referred from user context
 */

const SCHEDULED_FOR_TODAY = 'Scheduled for today';
const ALL = 'All';
const MY_JOBS = 'My jobs';
const { VIEW, EDIT, NEW } = Mode;
const { TAX_RATE_ASSIGN_DISABLED, SYNC_IMMEDIATELY } = TenantSetting;

const buttonTypes = {
  ACTIVATE: 'activate',
  DEACTIVATE: 'deactivate',
  CREATE_JOB: 'createJob',
  CREATE_QUOTE: 'createQuote'
};

export class PropertyDetail extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.CustomerPropertyService = new CustomerPropertyService();
    this.QuickbooksService = new QuickbooksService();
    this.AccountingValidationService = new AccountingValidationService();
    let propertyTypesDefs = {};
    let priceBooks = {};
    let companyAddresses = '';
    let companyState = '';
    let isCustomJobNumberEnabled;
    let isExtendedFieldsEnabled;
    let isTaxRateAutoAssignDisabled = false;
    if (Context.getCompanyContext()) {
      ({
        customerPropertyTypes: propertyTypesDefs,
        companyAddresses,
        priceBooks
      } = Context.getCompanyContext().getCompany);
      if (companyAddresses && companyAddresses.items && companyAddresses.items.length === 1) {
        companyState = companyAddresses.items[0].state;
      }
      const { listTenantSettings } = Context.getCompanyContext();

      const customJobNumberSetting =
        listTenantSettings &&
        listTenantSettings.filter(
          setting => setting.settingKey === 'job_customJobNumber' && setting.settingValue === 'true'
        );
      isCustomJobNumberEnabled = customJobNumberSetting && customJobNumberSetting.length > 0;

      const extendedAddressFields =
        listTenantSettings &&
        listTenantSettings.filter(
          setting =>
            setting.settingKey === 'customer_extendedFields' && setting.settingValue === 'true'
        );

      isExtendedFieldsEnabled = extendedAddressFields && extendedAddressFields.length > 0;
      isTaxRateAutoAssignDisabled = isTenantSettingEnabled(TAX_RATE_ASSIGN_DISABLED);
    }
    document.title = 'BuildOps - Property';
    this.state = {
      propertyTypesDefs,
      openCustomerRep: false,
      openTenantRep: false,
      openPropertyAsset: false,
      openPropertyAttachment: false,
      createTask: false,
      dataRecord: '',
      modalMode: '',
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: '',
      logoURL: '',
      customerData: '',
      customerProperty: '',
      customerRepsData: '',
      tenantRepsData: '',
      assetsData: '',
      companyState: companyState || 'CA',
      loading: true,
      collapsePanel: {
        propertyContacts: 'OFF',
        propertyAssets: 'OFF',
        propertyAttachments: 'OFF',
        upcomingEvents: 'OFF',
        pastEvents: 'OFF'
      },
      customerReps: [],
      tenantReps: [],
      priceBooks,
      isCustomJobNumberEnabled,
      isExtendedFieldsEnabled,
      isTaxRateAutoAssignDisabled,
      globalFilters: '',
      globalFilterName: ALL,
      refreshCounter: 0,
      deactivateConfirmationModal: {
        open: false,
        data: null,
        dataType: 'Property',
        mode: Mode.DEACTIVATE,
        // eslint-disable-next-line no-console
        handlePrimaryAction: () => console.warn('no primary action set'),
        layout: null,
        deleteItemLabel: '',
        deactivateStatement: ''
      },
      company: Context.getCompanyContext() ? Context.getCompanyContext().getCompany : {}
    };
  }

  componentDidMount = async () => {
    const { recordSortKey } = this.props.history.location.state || {};

    this.queryAndSetTaxRates();
    this.queryAndSetCustomerReps(recordSortKey);
    this.queryAndSetTenantReps(recordSortKey);
    this.queryAndSetAttachmentData(recordSortKey);
    this.queryAndSetCustomFormFields();
    await this.queryAndSetPropertyInfo(recordSortKey);
    await this.queryAndSetCustomerCustomFieldData();

    if (!this.props.user.tenantId) return;
    const propertyAssetSubscription = await this.CustomerPropertyService.propertyAssetUpdated(
      this.props.user.tenantId
    );

    const propertyAttachmentSubscription = await this.CustomerPropertyService.propertyAttachmentUpdated(
      this.props.user.tenantId
    );

    const propertyNoteSubscription = await this.CustomerPropertyService.propertyNoteUpdated(
      this.props.user.tenantId
    );

    try {
      propertyAssetSubscription.subscribe(result => {
        const { customerProperty } = this.state;
        const { data } = result;
        if (
          data &&
          this.mounted &&
          data.propertyAssetUpdateNotification.parentId === customerProperty.id
        ) {
          const localAssetItem = data.propertyAssetUpdateNotification;
          const { assetsData } = this.state;
          const updatedAssetData = upsertList(assetsData, localAssetItem);
          this.setState(prevState => ({
            ...prevState,
            assetsData: updatedAssetData
          }));
        }
      });

      propertyAttachmentSubscription.subscribe(result => {
        const { data } = result;
        const { customerProperty } = this.state;
        if (
          data &&
          this.mounted &&
          data.propertyAttachmentUpdateNotification.parentId === customerProperty.id
        ) {
          const attachmentData = this.state.attachmentsData;
          const updatedAttachmentData = upsertList(
            attachmentData.items,
            data.propertyAttachmentUpdateNotification
          );
          const modifiedAttachments = {};
          modifiedAttachments.items = updatedAttachmentData;

          this.setState(prevState => ({
            ...prevState,
            propertyAttachmentsData: modifiedAttachments
          }));
        }
      });

      propertyNoteSubscription.subscribe(result => {
        const { customerProperty } = this.state;
        const { data } = result;

        if (
          data &&
          this.mounted &&
          data.propertyNoteUpdateNotification.parentId === customerProperty.id
        ) {
          const localPropertyInfo = customerProperty;
          const updatedPropertyNotes = upsertList(
            this.state.customerProperty.notes.items,
            data.propertyNoteUpdateNotification
          );
          const { notes } = localPropertyInfo;
          notes.items = updatedPropertyNotes.sort(
            (left, right) => right.lastUpdatedDateTime - left.lastUpdatedDateTime
          );
          this.setState(prevState => ({
            ...prevState,
            customerProperty: localPropertyInfo
          }));
        }
      });
    } catch (error) {
      Logger.error(error);
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  handleOpenPopUp = (popUpKey, mode, record) => {
    this.setState({ [popUpKey]: true, modalMode: mode, dataRecord: record });
  };

  handleClosePopUp = popUpKey => {
    this.setState({ [popUpKey]: false });
  };

  handleChange = panel => {
    const toggle = { ON: 'OFF', OFF: 'ON' };
    const val = toggle[this.state.collapsePanel[panel]];
    const localCollapsePanel = { ...this.state.collapsePanel, [panel]: val };
    this.setState(prevState => ({
      ...prevState,
      collapsePanel: localCollapsePanel
    }));
  };

  handleCancelConfirmation = () => {
    this.setState({
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: ''
    });
  };

  queryAndSetPropertyInfo = async recordSortKey => {
    let data;
    let propertyData;
    let propertyId;
    try {
      if (!recordSortKey) {
        propertyId = this.props.computedMatch.params && this.props.computedMatch.params.id;
        const response = await this.CustomerPropertyService.getCustomerPropertyInfoById(propertyId);
        if (response && response.data && response.data.getCustomerPropertyById) {
          propertyData = response.data.getCustomerPropertyById;
        }
      } else {
        ({ data } = await this.CustomerPropertyService.getCustomerPropertyInfo(
          this.props.user.tenantId,
          recordSortKey
        ));
        if (data && data.getCustomerProperty) {
          propertyData = data.getCustomerProperty;
        }
      }
      document.title = `BuildOps - Property ${propertyData.companyName || ''}`;
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn(
        'error',
        'Unable to fetch Customer property info, please try again later',
        error
      );
    }

    if (propertyData) {
      const localProperty = propertyData;
      if (localProperty.sameAddress) {
        localProperty.sameAddress = `${localProperty.sameAddress}`;
      } else {
        localProperty.sameAddress =
          localProperty.billingCustomerId &&
          localProperty.parentId === localProperty.billingCustomerId
            ? 'true'
            : 'false';
      }
      localProperty.customerNotes = localProperty.notes?.items?.sort(
        (left, right) => right.lastUpdatedDateTime - left.lastUpdatedDateTime
      );

      // for copying customer address to property address
      const customerAddresses = localProperty.parentEntity?.companyAddresses?.items;
      const customerAddress = customerAddresses?.find(
        address => address.addressType === 'businessAddress'
      );
      if (customerAddress) {
        localProperty.customerBillTo = customerAddress.billTo;
        localProperty.customerAddressLine1 = customerAddress.addressLine1;
        localProperty.customerAddressLine2 = customerAddress.addressLine2;
        localProperty.customerCity = customerAddress.city;
        localProperty.customerState = customerAddress.state;
        localProperty.customerZipcode = customerAddress.zipcode;
        localProperty.customerLongitude = customerAddress.longitude;
        localProperty.customerLatitude = customerAddress.latitude;
      }
      const customerBillingAddress = customerAddresses?.find(
        address => address.addressType === 'billingAddress'
      );
      if (customerBillingAddress) {
        localProperty.customerBillingBillTo = customerBillingAddress.billTo;
        localProperty.customerBillingAddressLine1 = customerBillingAddress.addressLine1;
        localProperty.customerBillingAddressLine2 = customerBillingAddress.addressLine2;
        localProperty.customerBillingCity = customerBillingAddress.city;
        localProperty.customerBillingState = customerBillingAddress.state;
        localProperty.customerBillingZipcode = customerBillingAddress.zipcode;
        localProperty.customerBillingLongitude = customerBillingAddress.longitude;
        localProperty.customerBillingLatitude = customerBillingAddress.latitude;
      }

      const billingCustomer = localProperty?.billingCustomer;
      // legacy support for old billing addresses on property
      if (billingCustomer) {
        localProperty.companyAddresses.items = localProperty.companyAddresses?.items?.filter(
          address => address.addressType !== 'billingAddress'
        );
        const billingAddress = localProperty.billingCustomer?.companyAddresses?.items?.find(
          address => address.addressType === 'billingAddress'
        );
        if (billingAddress) {
          localProperty.billingBillTo = billingAddress.billTo;
          localProperty.billingAddressLine1 = billingAddress.addressLine1;
          localProperty.billingAddressLine2 = billingAddress.addressLine2;
          localProperty.billingCity = billingAddress.city;
          localProperty.billingState = billingAddress.state;
          localProperty.billingZipcode = billingAddress.zipcode;
          localProperty.billingLongitude = billingAddress.longitude;
          localProperty.billingLatitude = billingAddress.latitude;
        }
        localProperty.billingCustomerName = billingCustomer.customerName;
      }
      localProperty.customerTaxRateId = localProperty.parentEntity?.taxRateId;
      if (this.state.isExtendedFieldsEnabled) {
        localProperty.isExtendedFieldsEnabled = this.state.isExtendedFieldsEnabled;
      }
      const customerProperty = localProperty;

      // get completed jobs count
      // TODO: add filter with status as completed in the query
      const allJobForProperty = (localProperty.jobs || {}).items || [];
      const completedJobs = allJobForProperty.filter(item => item.status === 'Complete');
      customerProperty.jobCompletedCount = (completedJobs || []).length || 0;

      const notesData = propertyData?.customerNotes?.items || propertyData?.notes?.items || [];
      const propertyInstructions = {
        notesData
      };

      const customerPropertyTypes = orderBy(this.state.company?.customerPropertyTypes?.items, [
        'sortOrder'
      ]);
      const propertyType = customerPropertyTypes?.find(
        type =>
          type?.tagName?.toLowerCase() === propertyData?.customerPropertyTypeValue?.toLowerCase()
      );

      if (customerProperty?.taxRateId === 'None' || !customerProperty?.taxRateId) {
        customerProperty.taxRateId = '';
      }

      const taxRateLabel = localProperty.taxRate
        ? `${localProperty.taxRate.name} - ${localProperty.taxRate.taxRate}`
        : null;
      customerProperty.taxRateLabel = taxRateLabel;
      customerProperty.priceBookId =
        customerProperty.priceBookId === 'None' ? '' : customerProperty.priceBookId;
      const isPropertyActive =
        (customerProperty.status && customerProperty.status === Constants.ACTIVE) ||
        customerProperty.isActive;
      if (this.mounted) {
        /**
         * ! disabling edit if the property is inactive
         */
        const mode = this.getMode();
        if (mode === EDIT && !isPropertyActive) {
          this.replaceMode(mode, VIEW);
        }
        this.setState(prevState => ({
          ...prevState,
          propertyId: customerProperty.id,
          customerProperty,
          customerPropertyTypeValue: propertyData?.customerPropertyTypeValue,
          customerPropertyTypeId: propertyType?.id,
          isPropertyActive,
          customerData: localProperty.parentEntity,
          confirmDialog: false,
          confirmAction: '',
          confirmMessage: '',
          mainSectionFormFields: {
            customerPropertyTypeValue: propertyData?.customerPropertyTypeValue,
            customerPropertyTypeId: propertyType?.id,
            isTaxable: customerProperty?.isTaxable,
            taxRateId: customerProperty?.taxRateId || '',
            propertyInstructions,
            priceBookId: customerProperty?.priceBookId
          }
        }));
      }
    }
  };

  queryAndSetCustomerCustomFieldData = async () => {
    // on first load, the tenant id was not set, hence the check
    if (this.props.user && this.props.user.tenantId === '') {
      return null;
    }

    const recordId = this.props.computedMatch.params && this.props.computedMatch.params.id;

    let data;
    let localCustomerPropertyData;
    try {
      ({ data } = await this.CustomerPropertyService.getCustomerPropertyFieldData(recordId));
      localCustomerPropertyData = data.getCustomerPropertyById;
    } catch (error) {
      logErrorWithCallback(error, this.props.snackbarOn, 'Unable to fetch custom form data');
    }
    let customFields; // inline fields key array
    let customFieldsData; // inline fields data
    if (localCustomerPropertyData) {
      // Custom form data
      let propertyCustomFieldsData;
      if (localCustomerPropertyData?.formData?.items?.length) {
        localCustomerPropertyData.formData.items.forEach(fdata => {
          const { id, version, form, ...localData } = fdata;
          customFieldsData = localData;
          customFields = Object.keys(localData);
          propertyCustomFieldsData = {
            ...localData,
            formDataId: id,
            formDataVersion: version
          };
        });
      }

      if (this.mounted) {
        this.setState(prevState => {
          const tempPropertyData = prevState.customerProperty || {};
          return {
            ...prevState,
            customerProperty: { ...tempPropertyData, ...propertyCustomFieldsData },
            customFields,
            confirmDialog: false,
            confirmAction: '',
            confirmMessage: '',
            mainSectionFormFields: {
              ...prevState.mainSectionFormFields,
              ...customFieldsData
            }
          };
        });
      }
    }
    return localCustomerPropertyData;
  };

  queryAndSetCustomFormFields = async () => {
    const companySortKey = `${this.props.user.tenantId}_Company_${this.props.user.tenantCompanyId}`;
    try {
      const { formAttributes, inlineForm } = await fetchInlineForm(
        this.props.user.tenantId,
        companySortKey,
        'Property'
      );

      if (this.mounted && inlineForm) {
        this.setState(prevState => ({
          ...prevState,
          formAttributes,
          inlineForm
        }));
      }
    } catch (err) {
      Logger.info(err);
      this.props.snackbarOn('error', 'Unable to fetch custom fields in the form', err);
    }
  };

  queryAndSetCustomerReps = async recordSortKey => {
    let response;
    try {
      if (!recordSortKey) {
        const propertyId = this.props.computedMatch.params && this.props.computedMatch.params.id;
        response = await this.CustomerPropertyService.getCustomerRepsByCustomerPropertyById(
          propertyId
        );
      } else {
        response = await this.CustomerPropertyService.getCustomerRepsByCustomerProperty(
          this.props.user.tenantId,
          recordSortKey
        );
      }
    } catch (error) {
      Logger.error(`Error in fetching property customer rep data ${JSON.stringify(error)}`);
      this.props.snackbarOn(
        'error',
        'Unable to fetch Customer Reps, please try again later',
        error
      );
    }
    const propertyData =
      response?.data?.getCustomerPropertyById || response?.data?.getCustomerProperty;
    if (propertyData) {
      const localProperty = propertyData;
      // preparing customer reps
      if (localProperty?.customerReps?.items?.length) {
        localProperty.customerReps.items.forEach((rep, index) => {
          let localData = rep;
          if (!rep.parent) {
            const { mappedEntity, ...parent } = rep;
            localData = { ...mappedEntity, parent };
            localData.status = localData.status ? capitalizeFirstLetter(localData.status) : '';
            localData.repNotes = localData.notes
              ? localData.notes.items.sort(
                  (left, right) => right.lastUpdatedDateTime - left.lastUpdatedDateTime
                )
              : [];
            localData.notesCount = ((localData.notes || {}).items || []).length;

            localData.emailInvoiceLabel =
              EmailInvoiceTypeLabel[localData.emailInvoice] || EmailInvoiceTypeLabel.NonBilling;

            localProperty.customerReps.items[index] = localData;
          }
        });
      }

      // filtering here as filter cannot be applied when query the parentEntity
      const activeRepsForCustomer =
        localProperty?.parentEntity?.customerReps?.items?.filter(
          item => item.status?.toLowerCase() === AppConstants.ACTIVE
        ) || [];

      if (this.mounted) {
        this.setState(prevState => ({
          ...prevState,
          customerRepsDataForCustomer: { items: activeRepsForCustomer },
          customerRepsData: localProperty.customerReps
        }));
      }
    }
  };

  queryAndSetTenantReps = async recordSortKey => {
    let data;
    let propertyData;
    try {
      if (!recordSortKey) {
        const propertyId = this.props.computedMatch.params && this.props.computedMatch.params.id;
        const response = await this.CustomerPropertyService.getTenantRepsByCustomerPropertyById(
          propertyId
        );
        if (response && response.data && response.data.getCustomerPropertyById) {
          propertyData = response.data.getCustomerPropertyById;
        }
      } else {
        ({ data } = await this.CustomerPropertyService.getTenantRepsByCustomerProperty(
          this.props.user.tenantId,
          recordSortKey
        ));
        if (data && data.getCustomerProperty) {
          propertyData = data.getCustomerProperty;
        }
      }
    } catch (error) {
      Logger.error(`Error in fetching property tenant rep data ${JSON.stringify(error)}`);
      this.props.snackbarOn('error', 'Unable to fetch Tenant Reps, please try again later', error);
    }

    if (propertyData) {
      const localProperty = propertyData;

      // preparing tenantReps
      let tenantReps = [];
      if (
        localProperty.tenantReps &&
        localProperty.tenantReps.items &&
        localProperty.tenantReps.items.length > 0
      ) {
        localProperty.tenantReps.items.forEach(rep => {
          const localRep = rep;
          const employee = localRep.mappedEntity || {};
          localRep.name = employee.name || `${employee.firstName} ${employee.lastName}`;
          localRep.status = localRep.status ? capitalizeFirstLetter(localRep.status) : '';
          if (employee.appRoles && employee.appRoles.items && employee.appRoles.items.length > 0) {
            localRep.contactRole = employee.appRoles.items[0].mappedEntity.tagName;
          }
          localRep.repNotes = ((employee.notes || {}).items || []).sort(
            (left, right) => right.lastUpdatedDateTime - left.lastUpdatedDateTime
          );
          localRep.notesCount = ((employee.notes || {}).items || []).length;
          tenantReps.push(localRep);
        });
      }
      tenantReps = sortArrayWithCaseInsensitive('name', tenantReps);
      localProperty.processedTenantReps = tenantReps;

      if (this.mounted) {
        this.setState({
          tenantRepsData: localProperty.processedTenantReps
        });
      }
    }
  };

  queryAndSetAttachmentData = async sortKey => {
    let data;
    let propertyData;
    const recordSortKey = sortKey ?? this.state?.customerProperty?.sortKey;
    try {
      if (!recordSortKey) {
        const propertyId = this.props.computedMatch.params && this.props.computedMatch.params.id;
        const response = await this.CustomerPropertyService.getAttachmentByCustomerPropertyById(
          propertyId
        );
        if (response && response.data && response.data.getCustomerPropertyById) {
          propertyData = response.data.getCustomerPropertyById;
        }
      } else {
        ({ data } = await this.CustomerPropertyService.getAttachmentByCustomerProperty(
          this.props.user.tenantId,
          recordSortKey
        ));
        if (data && data.getCustomerProperty) {
          propertyData = data.getCustomerProperty;
          // backward compatibility
          if (propertyData && propertyData.attachments && propertyData.attachments.items) {
            propertyData.attachments.items.map(item => {
              const localItem = item;
              if (!item.comment) {
                localItem.comment = item.description || '';
              }
              return localItem;
            });
          }
        }
      }
    } catch (error) {
      Logger.error(`Error in fetching property data ${JSON.stringify(error)}`);
      this.props.snackbarOn('error', 'Unable to fetch Attachments, please try again later', error);
    }

    if (propertyData) {
      const localProperty = propertyData;
      if (this.mounted) {
        this.setState(prevState => ({
          ...prevState,
          attachmentsData: localProperty.attachments
        }));
      }
    }
  };

  processVisitData = customerPropertyVisits => {
    const processedVisit = [];
    (customerPropertyVisits || []).forEach(visit => {
      let primaryTech;
      if (visit.primaryTechs && visit.primaryTechs.items && visit.primaryTechs.items.length > 0) {
        const employee = visit.primaryTechs.items[0].mappedEntity || {};
        primaryTech = employee.name || `${employee.firstName} ${employee.lastName}`;
      }

      let date;
      if (!visit.scheduledFor) {
        date = visit.tentativeDate ? moment(visit.tentativeDate) : '';
      } else {
        date = moment.unix(visit.scheduledFor);
      }

      const processedJson = {
        eventType: visit.parentEntity.jobTypeInternal,
        status: visit.status,
        date,
        description: visit.description || '-',
        assignedTo: primaryTech || '-',
        propertyName: visit.parentEntity.customerPropertyName,
        jobTypeInternal: visit.parentEntity.jobTypeInternal,
        jobNumber: visit.parentEntity.jobNumber,
        customerSortKey: visit.parentEntity.customerSortKey,
        jobSortKey: visit.parentEntity.sortKey,
        // To support custom job number on and off scenarios
        customIdentifier: visit.parentEntity.customIdentifier || visit.parentEntity.jobNumber
      };
      processedVisit.push(processedJson);
    });
    return processedVisit;
  };

  queryJobsByProp = async (filter, limit, offset, sortBy, sortOrder) => {
    if (!this.props.user.tenantId) {
      return { items: [], nextToken: '0' };
    }

    // incoming filter may have string filter, hence we need to append the string filters
    const customizedFilter = {
      stringFilters: [
        {
          fieldName: 'Job.jobTypeInternal',
          filterInput: {
            eq: 'Job'
          }
        }
      ]
    };

    let combinedStringFilterArr = customizedFilter.stringFilters;
    if (filter.stringFilters) {
      combinedStringFilterArr = combinedStringFilterArr.concat(filter.stringFilters);
    }
    customizedFilter.stringFilters = combinedStringFilterArr;

    let data;
    const { sortKey } = this.state.customerProperty;
    try {
      data = await this.CustomerPropertyService.getJobsByProperty(
        this.props.user.tenantId,
        sortKey,
        customizedFilter,
        limit,
        offset,
        sortBy,
        sortOrder
      );

      return data;
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to fetch Jobs, please try again later', error);
    }
    return data || {};
  };

  queryMaintenanceRecordsByProp = async (filter, limit, offset, sortBy, sortOrder) => {
    if (!this.props.user.tenantId) {
      return { items: [], nextToken: '0' };
    }
    let data;
    const { sortKey } = this.state.customerProperty;
    try {
      data = await this.CustomerPropertyService.getMaintenanceRecordsByProperty(
        this.props.user.tenantId,
        sortKey,
        filter,
        limit,
        offset,
        sortBy,
        sortOrder
      );
      return data;
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn(
        'error',
        'Unable to fetch Maintenance records, please try again later',
        error
      );
    }
    return data || {};
  };

  queryUpcomingVisit = async (filter, limit, offset, sortBy, sortOrder) => {
    const { sortKey } = this.state.customerProperty;
    try {
      const { data } = await this.CustomerPropertyService.getUpcomingVisitsByCustomerProperty(
        this.props.user.tenantId,
        sortKey,
        filter,
        limit,
        offset,
        sortBy,
        sortOrder
      );

      if (data?.getCustomerProperty?.visits?.items?.length > 0) {
        const processedVisits = this.processVisitData(data.getCustomerProperty.visits.items);
        const returnData = { items: processedVisits };
        return returnData;
      }
    } catch (error) {
      Logger.error(`Error in fetching visits ${JSON.stringify(error)}`);
      this.props.snackbarOn('error', 'Unable to fetch visits, please try again later', error);
    }
    return {};
  };

  queryPastVisit = async (filter, limit, offset, sortBy, sortOrder) => {
    const { sortKey } = this.state.customerProperty;
    try {
      const { data } = await this.CustomerPropertyService.getPastVisitsByCustomerProperty(
        this.props.user.tenantId,
        sortKey,
        filter,
        limit,
        offset,
        sortBy,
        sortOrder
      );

      if (data && data.getCustomerProperty && data.getCustomerProperty.visits) {
        const processedVisits = this.processVisitData(data.getCustomerProperty.visits.items);
        return { items: processedVisits };
      }
    } catch (error) {
      Logger.error(`Error in fetching visits ${JSON.stringify(error)}`);
      this.props.snackbarOn('error', 'Unable to fetch past visits, please try again later', error);
    }
    return {};
  };

  queryAndSetTaxRates = async () => {
    const { user } = this.props;
    const quickBookService = new QuickbooksService();
    const response = await quickBookService.getTaxRates(
      user.tenantId,
      `${user.tenantId}_Company_${user.tenantCompanyId}`
    );
    let taxRateOptions = [];

    if (
      response &&
      response.data &&
      response.data.getCompany &&
      response.data.getCompany.taxRates &&
      response.data.getCompany.taxRates.items
    ) {
      const taxRates = response.data.getCompany.taxRates.items;

      taxRateOptions = orderBy(
        taxRates?.filter(t => t.accountType !== AccountType.AP) || [],
        'name'
      ).map(taxRate => ({
        label: `${taxRate.name} - ${taxRate.taxRate}`,
        ...taxRate
      }));
      this.setState(prevState => ({
        ...prevState,
        taxRates,
        taxRateOptions
      }));
    }
  };

  preparePropertyForMutation = data => {
    const { formAttributes } = this.state;
    const localData = { ...data.propertyAddressFields, ...data, formAttributes };
    if (!localData?.taxRateId) {
      delete localData.taxRateId;
    }
    return localData;
  };

  handleOnCompleteProperty = async (data, reload = true) => {
    // validation - if isTaxable then require taxRate
    if (data.isTaxable && data.isTaxable !== 'false' && !data.taxRateId) {
      return this.props.snackbarOn(
        'error',
        'Tax rate required for a taxable property.\nPlease select a tax rate.'
      );
    }

    const validationMessage = await this.AccountingValidationService.validateEntity(
      this.props.user.tenantId,
      EntityType.CUSTOMER_PROPERTY,
      data
    );
    if (validationMessage) {
      return this.props.snackbarOn('error', validationMessage);
    }

    let customerPropertyData;
    const syncNow = !!isTenantSettingEnabled(SYNC_IMMEDIATELY);
    const localData = this.preparePropertyForMutation(data);

    try {
      customerPropertyData = await this.CustomerPropertyService.updateCustomerPropertyAndRelated(
        this.props.user.tenantId,
        localData,
        localData.parentId,
        syncNow
      );

      if (customerPropertyData?.[0]?.id && reload) {
        this.props.history.push(`/property/view/${customerPropertyData[0].id}`);
      }
      this.componentDidMount();
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to save customer property', error);
    }
    return localData;
  };

  createJob = () => {
    const { customerProperty } = this.state;
    this.props.history.push('/job/new', {
      customerName: customerProperty.parentEntity.customerName,
      propertyName: customerProperty.companyName,
      propertyId: customerProperty.id,
      customerSortKey: customerProperty.parentEntity.sortKey
    });
  };

  isCustomerActive = property =>
    property &&
    property.parentEntity &&
    (property.parentEntity.isActive || property.parentEntity.isActive === null);

  getActionToPerform = () => {
    const { customerProperty } = this.state;
    const displayErrorMessageFn = () =>
      this.props.snackbarOn(
        'error',
        'This property has an inactive customer!\nPlease activate the customer before activating property!'
      );
    const performActivityToggleFn = async () => {
      await this.togglePropertyActivity(customerProperty, true);
      await this.queryAndSetPropertyInfo(customerProperty.sortKey);
      this.props.snackbarOn(
        'success',
        `Successfully activated property - ${customerProperty.companyName}.`
      );
    };

    return !this.isCustomerActive(customerProperty)
      ? displayErrorMessageFn
      : performActivityToggleFn;
  };

  togglePropertyActivity = async (data, isActive) => {
    const localData = this.preparePropertyForMutation(data);
    const syncNowSetting = !!isTenantSettingEnabled(SYNC_IMMEDIATELY);
    localData.status = isActive ? Constants.ACTIVE : Constants.INACTIVE;
    localData.isActive = isActive;
    // addresses
    localData.companyAddresses.items.map(address => ({ ...address, isActive }));
    try {
      await this.CustomerPropertyService.updateCustomerPropertyAndRelated(
        localData.partitionKey,
        localData,
        localData.parentId,
        syncNowSetting
      );
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to save customer property', error);
    }
    return localData;
  };

  deactivateProperty = async () => {
    const data = this.state.customerProperty;
    const { deactivateConfirmationModal } = this.state;
    const updatedModal = { ...deactivateConfirmationModal };
    updatedModal.open = true;
    updatedModal.data = data;

    const openJobs = ['In Progress', 'Open'];
    const { jobs } = data;
    const unClosedJobs = ((jobs || {}).items || []).filter(item => openJobs.includes(item.status));
    if (unClosedJobs.length > 0) {
      updatedModal.deactivateStatement =
        'This property has Open/In Progress jobs. ' +
        'They will remain open if you deactivate this property.';
    }
    updatedModal.deleteItemLabel = data.companyName;
    updatedModal.handlePrimaryAction = this.handleDeactivateModalClick;
    this.setState({ deactivateConfirmationModal: updatedModal });
  };

  getCreateActions = (buttonKey = '') => {
    const { ACTIVATE, DEACTIVATE, CREATE_JOB, CREATE_QUOTE } = buttonTypes;
    const { customerProperty } = this.state;
    if (this.state.isPropertyActive) {
      switch (buttonKey) {
        case DEACTIVATE:
          this.deactivateProperty();
          break;
        case CREATE_JOB:
          this.createJob();
          break;
        case CREATE_QUOTE:
          if (this.props.flags[FeatureFlags.QUOTESV2]) {
            this.props.history.push('/quotes/new', {
              propertyId: customerProperty.id
            });
          }
          break;
        default:
          throw new Error('Wrong button key');
      }
    } else if (buttonKey === ACTIVATE) {
      this.getActionToPerform()();
    }
  };

  setGlobalFilters = async value => {
    let { globalFilters } = this.state;
    const { globalFilterName } = this.state;

    if (value === globalFilterName) {
      return;
    }

    if (value === 'All') {
      this.setState(prevState => ({
        ...prevState,
        globalFilters: '',
        globalFilterName: value,
        refreshCounter: prevState.refreshCounter + 1
      }));
      return;
    }

    if (value === MY_JOBS) {
      let { stringFilters } = globalFilters || {};

      if (!stringFilters) {
        stringFilters = [];
      }

      stringFilters.push({
        fieldName: 'Job.ownerId',
        filterInput: { eq: this.props.user.employeeId }
      });
      if (!globalFilters) {
        globalFilters = {};
      }
      globalFilters.stringFilters = stringFilters;

      if (globalFilters.subQueryFilters) {
        delete globalFilters.subQueryFilters;
      }
    }

    if (value === SCHEDULED_FOR_TODAY) {
      const currentStartOfDay = moment()
        .startOf('day')
        .unix();
      const currentEndOfDay = moment()
        .endOf('day')
        .unix();

      globalFilters = {};
      globalFilters.subQueryFilters = [
        {
          fieldName: 'exists',
          fieldComparator: 'exists',
          subQueryFieldName: 'Visit.id',
          entityConnection: 'Visit',
          filter: {
            stringFilters: [
              {
                fieldName: 'Visit.parentId',
                filterInput: {
                  eq: 'Job.id'
                },
                literal: true
              }
            ],
            integerFilters: [
              {
                fieldName: 'Visit.scheduledFor',
                filterInput: {
                  between: [currentStartOfDay, currentEndOfDay]
                }
              }
            ]
          }
        }
      ];
    }
    this.setState(prevState => ({
      globalFilters,
      globalFilterName: value,
      refreshCounter: prevState.refreshCounter + 1
    }));
  };

  setDeactivatedText = deactivated => {
    const containerStyle = {
      width: 'auto',
      flexGrow: 1,
      justifyContent: 'space-between',
      alignItems: 'center'
    };
    const deactivatedChip = statusChips.deactivated;
    return (
      <Grid container style={containerStyle}>
        <Grid item spacing={3}>
          {deactivated && <StatusChip style={{ marginLeft: 8 }} type={deactivatedChip} />}
        </Grid>
      </Grid>
    );
  };

  handleDeactivateModalClick = async (data, loaded) => {
    const openJobs = [JobStatus.IN_PROGRESS, JobStatus.OPEN];
    const { customerProperty } = this.state;
    const { jobs } = customerProperty;
    const quotes = await this.CustomerPropertyService.getQuotesByCustomerPropertyById(
      customerProperty.id
    );
    const openQuotes = quotes?.items?.filter(
      quote =>
        quote.status &&
        quote.status !== QuoteConstants.Cancelled &&
        quote.status !== QuoteConstants.JobAdded
    );
    let message = `Successfully deactivated - ${data.companyName}.`;
    if (openQuotes?.length > 0) {
      message =
        `Cannot deactivate property that has quotes in progress. ${data.companyName} has ` +
        `${openQuotes.length} quotes in progress. Please cancel or convert these quotes into jobs ` +
        `to deactivate this property.`;
      this.props.snackbarOn('error', message);
    } else {
      const unClosedJobs = ((jobs || {}).items || []).filter(item =>
        openJobs.includes(item.status)
      );
      if (unClosedJobs.length > 0) {
        message =
          `Successfully deactivated - ${data.companyName}. The Open/In progress jobs ` +
          `were left open.`;
      }
      await this.togglePropertyActivity(data, false);
      await this.queryAndSetPropertyInfo(data.sortKey);
      this.props.snackbarOn('success', message);
    }
    loaded();
    this.handleDeactivateModalClose();
  };

  handleDeactivateModalClose = () => {
    const { deactivateConfirmationModal } = this.state;
    const updatedModal = { ...deactivateConfirmationModal };
    updatedModal.open = false;
    this.setState({ deactivateConfirmationModal: updatedModal });
  };

  getMode = () => {
    const { computedMatch } = this.props;
    return (computedMatch && computedMatch.params && computedMatch.params.mode) || 'view';
  };

  replaceMode = (currentMode, newMode) => {
    const mode = currentMode || this.getMode();
    const { location } = this.props.history;
    const newPath = location.pathname.replace(mode, newMode);
    this.props.history.push(newPath);
  };

  handleEdit = () => {
    const mode = this.getMode();
    if (mode !== VIEW) return;

    const { isPropertyActive } = this.state;
    if (!isPropertyActive) {
      this.props.snackbarOn('error', 'Please activate property before editing');
    }
    if (isPropertyActive) {
      this.replaceMode(mode, EDIT);
    }
  };

  render() {
    const {
      customerProperty,
      globalFilters,
      globalFilterName,
      refreshCounter,
      isPropertyActive
    } = this.state;
    const isActive = customerProperty.isActive || customerProperty.isActive === null;
    const mode = this.getMode();
    const propertyViewLayoutWithFlags = propertyViewLayout(this.props.flags);
    const layoutMeta =
      mode === VIEW
        ? propertyViewLayoutWithFlags.entity.layouts.web
        : AddPropertyLayout.entity.layouts.web;
    const data = customerProperty;
    let pageTitle = mode === NEW ? 'New property' : 'Customers > Existing customer > Property';

    if (data) {
      data.customerName = (data.parentEntity || {}).customerName;
      pageTitle =
        // eslint-disable-next-line no-nested-ternary
        mode === NEW
          ? [
              { link: '/property/list', title: 'Properties' },
              { link: '', title: 'New property' }
            ]
          : mode === EDIT
          ? [
              { link: '/property/list', title: 'Properties' },
              { link: '', title: 'Edit Property' }
            ]
          : [
              { link: '/property/list', title: 'Properties' },
              { link: '', title: 'View Property' }
            ];

      if (mode === VIEW) {
        let firstSection = layoutMeta.sections[0];
        firstSection = {
          ...firstSection,
          title: data.companyName || '',
          icon: ''
        };
        layoutMeta.sections[0] = firstSection;
      }
    }
    const isDeactivated = this.state?.customerProperty?.status === Constants.INACTIVE;
    const { ACTIVATE } = buttonTypes;
    const filterButtonMeta = isDeactivated
      ? { [ACTIVATE]: layoutMeta?.buttons?.[ACTIVATE] }
      : omit(layoutMeta?.buttons, ACTIVATE);

    const buttons = mode === VIEW ? filterButtonMeta : {};

    let renderPageForm = false;
    if (mode === NEW) {
      renderPageForm = true;
    }

    if (mode !== NEW && (data !== '' || !!data)) {
      renderPageForm = true;
    }

    const handleMainSectionFormChange = mainSectionData => {
      const { customFields = [] } = this.state;
      const mainSectionFormFields = pick(mainSectionData, [
        'customerPropertyTypeId',
        'customerPropertyTypeValue',
        'isTaxable',
        'taxRateId',
        'propertyInstructions',
        'priceBookId',
        ...customFields
      ]);
      this.setState({ mainSectionFormFields });
    };

    const handlePropertyNote = async noteObj => {
      const { user } = this.props;
      const commonServiceObj = new CommonService();
      const customerPropertyServiceObj = new CustomerPropertyService();
      const payload = {
        customerPropertyId: customerProperty.id,
        notes: [
          {
            note: noteObj.note,
            subject: noteObj.subject
          }
        ]
      };
      if (noteObj?.id) {
        return commonServiceObj.updateNote(user.tenantId, noteObj);
      }

      return customerPropertyServiceObj.addNotesToCustomerProperty(user.tenantId, payload);
    };

    const AddNotesView = ({ form }) => {
      const notesData = form.values.propertyInstructions.notesData || [];
      const companyName = form.values.companyName || '';
      return (
        <Notes
          allNotesFor={companyName}
          linkName={Labels.viewEditAllPropertyNotes[this.props.user.locale]}
          mutateService={handlePropertyNote}
          notesData={notesData}
          parent={customerProperty}
          refetch={() => this.queryAndSetPropertyInfo(customerProperty.sortKey)}
          subtitle={Labels.notesOnProperty[this.props.user.locale]}
          title={Labels.propertyNotes[this.props.user.locale]}
          useAddCircleOutlineIcon
        />
      );
    };

    const mainLayoutWithInlineForm = (options, customForm = {}) => {
      const rawLayout = getMainSectionLayout(options, customForm);
      const mergedValidation = merge(rawLayout.validation, customForm.validation);
      const mergedValidationErrors = merge(rawLayout.validationErrors, customForm.validationErrors);
      return {
        fields: { ...rawLayout.fields, ...customForm.fields },
        layouts: rawLayout.layouts,
        validation: mergedValidation,
        validationErrors: mergedValidationErrors
      };
    };

    const mainSectionViewModeProps = () => {
      const customFormMeta = this.state.inlineForm?.formMeta;
      return {
        configuration: mainLayoutWithInlineForm(
          {
            formData: getDropDownOptions(this.state.company),
            usePropertyPricebooks: this.props.flags[FeatureFlags.USE_PROPERTY_PRICEBOOKS]
          },
          customFormMeta
        ),
        data: { ...data, ...this.state.mainSectionFormFields },
        customComponents: {
          AddNotesView
        },
        onFormChange: handleMainSectionFormChange
      };
    };

    const getBillingCustomerInfo = (propertyData, sameAsCustomer = false) => {
      /**
       * @description returns customer data if sameAsCustomer is true
       * otherwise returns billingCustomer data from property data
       */
      const companyAddresses = sameAsCustomer
        ? propertyData?.parentEntity?.companyAddresses?.items
        : propertyData?.billingCustomer?.companyAddresses?.items;
      const { address: billingAddress, location: billingAddressLocation } = getAddressAndLocation(
        companyAddresses,
        AddressType.BILLING
      );
      return {
        billingAddress,
        billingAddressLocation,
        billTo: propertyData.customerBillingBillTo,
        billingCustomerId: sameAsCustomer
          ? propertyData?.parentEntity?.id
          : propertyData?.billingCustomerId,
        billingCustomerName: sameAsCustomer
          ? propertyData?.parentEntity?.customerName
          : propertyData?.billingCustomerName
      };
    };

    const formatForLeftSection = propertyData => {
      let propertyAddressFields = propertyData?.companyAddresses?.items?.find(
        address => address?.addressType === AddressType.PROPERTY
      );
      const propertyAddressId = propertyAddressFields?.id;
      const { address: propertyAddress, location } = getAddressAndLocation(propertyAddressFields);
      const {
        billingAddress,
        billingCustomerId,
        billingCustomerName,
        billingAddressLocation
      } = getBillingCustomerInfo(propertyData);

      const customer = propertyData?.parentEntity;
      const getRoute = (id, entityType) => {
        const baseRoute = entityRouteMappings[entityType];
        if (!baseRoute || !id) return undefined;
        return baseRoute + id;
      };
      const customerRoute = getRoute(customer?.id, 'Customer');
      const billingCustomerRoute = getRoute(billingCustomerId, 'Customer');
      /**
       * @description creating propertyAddressField Keys of pattern:
       * addressLine1 -> propertyAddressLine1
       */
      if (propertyAddressFields) {
        propertyAddressFields = reduce(
          propertyAddressFields,
          (acc, value, key) => {
            return { ...acc, [`property${capitalizeFirstLetter(key)}`]: value };
          },
          {}
        );
        // Needed for Property Address edit form.
        propertyAddressFields.taxRateId = data?.taxRateId;
      }

      const convertStringBool = input => {
        if (typeof input === 'string') {
          return input === 'true';
        }
        return !!input;
      };
      const sameAddress = convertStringBool(propertyData?.sameAddress);
      return {
        customerName: propertyData?.customerName,
        customer: { label: customer?.customerName, path: customerRoute },
        companyName: propertyData?.companyName,
        propertyAddress,
        propertyAddressFields,
        propertyAddressId,
        location,
        billingAddress,
        billingCustomer: { label: billingCustomerName, path: billingCustomerRoute },
        billingCustomerName,
        billingAddressLocation,
        billingCustomerId,
        sameAddress,
        billTo: propertyData?.billTo,
        accountNumber: propertyData?.accountNumber
      };
    };

    const setBillingAddress = async (value, valueName, form, field) => {
      if (!value) {
        form.setValues({
          ...form.values,
          billingAddress: '',
          billingCustomerId: null,
          billingAddressLocation: {},
          sameAddress: false,
          [field.name]: valueName
        });
        return;
      }
      const customerService = new CustomerService();
      const { data } = await customerService.getCustomerBillingAddress(
        value.partitionKey,
        value.sortKey
      );
      const billingAddressObject = data?.getCustomer?.companyAddresses?.items?.find(
        address => address.addressType === 'billingAddress'
      );

      const { address: billingAddress, location: billingAddressLocation } = getAddressAndLocation(
        billingAddressObject
      );
      const { billingCustomerId } = form.values;
      form.setValues({
        ...form.values,
        billTo: billingAddressObject.billTo,
        billingAddress,
        billingCustomerId: value.id,
        sameAddress: value.id === billingCustomerId,
        billingAddressLocation,
        [field.name]: valueName
      });
    };

    const setSameBillingAddress = (value, form) => {
      const {
        billTo = '',
        billingAddress = '',
        billingCustomerId = '',
        billingCustomerName = '',
        billingAddressLocation = {}
      } = value && getBillingCustomerInfo(data, value);

      form.setValues({
        ...form.values,
        sameAddress: value,
        billTo: value && !form.values.billTo ? billTo : form.values.billTo,
        billingAddress: value ? billingAddress : null,
        billingCustomerId: value ? billingCustomerId : null,
        billingCustomerName: value ? billingCustomerName : null,
        billingAddressLocation: value ? billingAddressLocation : {}
      });
    };

    const leftSectionProps = {
      configuration: sidebarSection({
        formData: getDropDownOptions(this.state.company),
        setBillingAddress,
        setSameBillingAddress,
        handlePropertyAddressEdit: () => this.setState({ showAddressModal: true }),
        addressEditAndViewProps: {
          taxOptions: this.state.company?.taxRates?.items || [],
          updateTaxRateId: id =>
            this.setState(prevState => ({
              ...prevState,
              mainSectionFormFields: {
                ...prevState.mainSectionFormFields,
                taxRateId: id
              }
            })),
          addressTypeLabel: 'property',
          showAddressModal: this.state.showAddressModal,
          closeAddressModal: () => this.setState({ showAddressModal: false }),
          addressLocationSource: 'location',
          addressViewSource: 'propertyAddress',
          addressEditSource: 'propertyAddressFields',
          disableTaxRateAutoAssign: this.state.isTaxRateAutoAssignDisabled
        }
      }),
      data: formatForLeftSection(data),
      customComponents: {
        LinkButton: LinkButtonForm,
        LocationView,
        AddressEditAndView,
        AlgoliaSearchWrapper
      }
    };

    const getHeaderProps = (propertyDetails, locale) => ({
      mode: EDIT,
      title: `Property: ${propertyDetails?.companyName || ''}`,
      userLocale: locale,
      breadcrumbsArray: pageTitle,
      caslKey: [PermissionConstants.OBJECT_PROPERTY],
      icon: 'propertyIcon'
    });

    const headerProps = getHeaderProps(data, this.props.user.locale);

    // table definitions
    return (
      <ErrorBoundaries>
        {!renderPageForm && <Spinner />}
        {renderPageForm && (
          <UserPermission action={PermissionConstants.OBJECT_PROPERTY} I={mode}>
            <LeftSidebarWithContent
              actionButtonHandler={this.getCreateActions}
              actionButtons={buttons}
              actionIconName="MoreHoriz"
              buttonLabel={{
                [VIEW]: 'Edit',
                [EDIT]: 'Save Changes'
              }}
              caslKey={PermissionConstants.OBJECT_PROPERTY}
              handleEdit={this.handleEdit}
              handleFormsSubmit={this.handleOnCompleteProperty}
              headerProps={headerProps}
              leftSectionProps={leftSectionProps}
              lookUpIconMap
              mainSectionProps={mainSectionViewModeProps()}
              mainSectionProps={mainSectionViewModeProps()}
              mode={mode}
              showCancelButton
              statusBackgroundColor={this.props.theme.palette.error.main}
              statusBackgroundColor={this.props.theme.palette.error.main}
              statusLabel={isDeactivated && Constants.DEACTIVATED}
              statusLabel={isDeactivated && Constants.DEACTIVATED}
              useNewMenuStyle
            >
              <SergeantModal
                confirmRemoveItemLabel={this.state.deactivateConfirmationModal.deleteItemLabel}
                confirmRemoveStatement={this.state.deactivateConfirmationModal.deactivateStatement}
                data={this.state.deactivateConfirmationModal.data}
                dataType={this.state.deactivateConfirmationModal.dataType}
                handleClose={this.handleDeactivateModalClose}
                handlePrimaryAction={this.state.deactivateConfirmationModal.handlePrimaryAction}
                layout={this.state.deactivateConfirmationModal.layout}
                maxWidth={500}
                mode={this.state.deactivateConfirmationModal.mode}
                open={this.state.deactivateConfirmationModal.open}
                warningMessageFont
              />
              <br />
              <Grid style={{ paddingTop: 40 }} />
              <Tabs>
                <Tab
                  caslKey={PermissionConstants.OBJECT_JOB}
                  label={Labels.jobsAndVisits[this.props.user.locale]}
                  tabKey="Jobs"
                >
                  <Typography style={{ color: '#3f3f3f' }} variant="body2">
                    {Labels.jobs[this.props.user.locale]}
                  </Typography>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <ChipArray
                      dataArr={[ALL, SCHEDULED_FOR_TODAY, MY_JOBS]}
                      handleClick={this.setGlobalFilters}
                      selectedValue={globalFilterName}
                    />
                  </Grid>
                  <ResponsiveTable
                    addRow={
                      isPropertyActive && {
                        label: 'Create job',
                        caslKey: PermissionConstants.OBJECT_JOB,
                        handleAdd: () => this.createJob()
                      }
                    }
                    caslKey={PermissionConstants.OBJECT_JOB}
                    filter={globalFilters}
                    key={refreshCounter} // @TODO - use toppanel rather than filter prop
                    noDataMsg="No Jobs"
                    rowActions={this.handleEventsRowActions}
                    rowMetadata={jobRowMetaByProp}
                    service={this.queryJobsByProp}
                    showToolbars
                    useServerQueries
                  />
                  <Grid style={{ paddingTop: 40 }} />
                  <Typography style={{ color: '#3f3f3f' }} variant="body2">
                    {Labels.currentVisitsForProperty[this.props.user.locale]}
                  </Typography>
                  <ResponsiveTable
                    disableFilter
                    noDataMsg="No upcoming visits"
                    rowMetadata={eventRows}
                    service={this.queryUpcomingVisit}
                    showToolbars
                  />
                  <Grid style={{ paddingTop: 40 }} />
                  <Typography style={{ color: '#3f3f3f' }} variant="body2">
                    {Labels.pastVisitsForProperty[this.props.user.locale]}
                  </Typography>
                  <ResponsiveTable
                    disableFilter
                    noDataMsg="No past visits"
                    rowMetadata={eventRows}
                    service={this.queryPastVisit}
                    showToolbars
                  />
                </Tab>
                <Tab
                  caslKey={PermissionConstants.OBJECT_SERVICE_AGREEMENT}
                  featureFlag={FeatureFlags.SERVICE_AGREEMENTS}
                  label={Labels.maintenance[this.props.user.locale]}
                  tabKey="maintenance"
                >
                  <ResponsiveTable
                    disableFilter
                    noDataMsg="No Maintenance Items"
                    rowMetadata={propertyMaintenanceRowsMeta}
                    service={this.queryMaintenanceRecordsByProp}
                    showToolbars
                  />
                </Tab>
                <Tab label={Labels.contacts[this.props.user.locale]} tabKey="contacts">
                  <Reps
                    customerData={this.state.customerData}
                    customerProperty={customerProperty}
                    customerRepsData={this.state.customerRepsData}
                    customerRepsDataForCustomer={this.state.customerRepsDataForCustomer}
                    isActive={isActive}
                    refreshCustomerRepsData={this.queryAndSetCustomerReps}
                    refreshTenantRepsData={this.queryAndSetTenantReps}
                    tenantRepsData={this.state.tenantRepsData}
                  />
                </Tab>
                <Tab label={Labels.attachments[this.props.user.locale]} tabKey="attachments">
                  <Attachment
                    data={this.state.attachmentsData}
                    enableThumbnails
                    mutationService={this.CustomerPropertyService.mutatePropertyAttachment}
                    parent={customerProperty}
                    readOnly={!isActive}
                    refresh={() => this.queryAndSetAttachmentData(customerProperty.sortKey)}
                  />
                </Tab>
                <Tab
                  caslKey={PermissionConstants.OBJECT_ASSET}
                  label={Labels.assets[this.props.user.locale]}
                  tabKey="Assets"
                >
                  <PropertyAssetList
                    customerProperty={customerProperty}
                    isActive={isActive}
                    ocrOptions={this.state.attachmentsData}
                    refetchOcrOptions={this.queryAndSetAttachmentData}
                  />
                </Tab>
                {this.props.flags[FeatureFlags.MAINTENANCE_TEMPLATES_V2] ? (
                  <Tab
                    caslKey={PermissionConstants.OBJECT_TASK}
                    label={Labels.tasks[this.props.user.locale]}
                    tabKey="tasks2"
                  >
                    <Tasks2
                      createTask={this.state.createTask}
                      customerPropertyId={this.state.propertyId}
                      isActive={isActive}
                    />
                  </Tab>
                ) : (
                  <Tab
                    caslKey={PermissionConstants.OBJECT_TASK}
                    label={Labels.tasks[this.props.user.locale]}
                    tabKey="tasks"
                  >
                    <Tasks
                      createTask={this.state.createTask}
                      customerPropertyInfo={{
                        partitionKey: this.props.user.tenantId,
                        sortKey: customerProperty.sortKey
                      }}
                      flags={this.props.flags}
                      isActive={isActive}
                      propertyId={this.state.propertyId}
                    />
                  </Tab>
                )}
                {this.props.flags[FeatureFlags.QUOTESV2] && (
                  <Tab
                    caslKey={PermissionConstants.OBJECT_QUOTES}
                    label={Labels.quotes[this.props.user.locale]}
                    tabKey="quotes"
                  >
                    <Quotes
                      flags={this.props.flags}
                      history={this.props.history}
                      isActive={isActive}
                      propertyId={this.state.propertyId}
                      propertyName={this.state.customerProperty.companyName}
                    />
                  </Tab>
                )}
                <Tab key="activity" label="Activity">
                  <AuditLogsSection
                    dataService={() =>
                      this.CustomerPropertyService.getAuditLogsByCustomerPropertyById(
                        this.state.customerProperty.id
                      )
                    }
                  />
                </Tab>
              </Tabs>
              <Grid style={{ paddingTop: 72 }} />
            </LeftSidebarWithContent>
          </UserPermission>
        )}
      </ErrorBoundaries>
    );
  }
}

PropertyDetail.propTypes = {
  mounted: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    tenantId: PropTypes.string.isRequired,
    employeeId: PropTypes.string.isRequired,
    tenantCompanyId: PropTypes.string.isRequired
  }).isRequired,
  snackbarOn: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  computedMatch: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      mode: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  theme: PropTypes.object.isRequired,
  flags: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const connectedPropertyDetail = connect(
  mapStateToProps,
  mapDispatcherToProps
)(withLDConsumer()(PropertyDetail));

export default withTheme(connectedPropertyDetail);
