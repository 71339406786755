import { useMemo } from 'react';

import { MUIFormComponentNames } from '@BuildHero/sergeant';

import { col, colRow, row } from '@dispatch/Dispatch.layoutUtils';

export const useManDayCreateFormConfig = ({ disabled, techsLoading, techOptions, disableSave }) => {
  return useMemo(() => {
    const START_TIME_FIELD_LABEL = {
      component: MUIFormComponentNames.LabelControl,
      source: 'START TIME',
      options: {
        required: true
      }
    };

    const START_TIME_FIELD_INPUT = {
      component: MUIFormComponentNames.TimeInput,
      source: 'fromTime',
      options: {
        disabled
      }
    };

    const END_TIME_FIELD_LABEL = {
      component: MUIFormComponentNames.LabelControl,
      source: 'END TIME',
      options: {
        required: true
      }
    };

    const END_TIME_FIELD_INPUT = {
      component: MUIFormComponentNames.TimeInput,
      source: 'toTime',
      options: {
        disabled
      }
    };

    const START_DATE_FIELD_LABEL = {
      component: MUIFormComponentNames.LabelControl,
      source: 'START DATE',
      options: {
        required: true
      }
    };

    const START_DATE_FIELD_INPUT = {
      component: MUIFormComponentNames.DateInput,
      source: 'fromDate',
      options: {
        disabled
      }
    };

    const END_DATE_FIELD_LABEL = {
      component: MUIFormComponentNames.LabelControl,
      source: 'END DATE',
      options: {
        required: true
      }
    };

    const END_DATE_FIELD_INPUT = {
      component: MUIFormComponentNames.DateInput,
      source: 'toDate',
      options: {
        disabled
      }
    };

    const EXCLUDE_WEEKENDS_FIELD = {
      component: MUIFormComponentNames.CheckboxInput,
      source: 'excludeWeekends',
      options: {
        label: 'Exclude Weekends'
      }
    };

    const ERROR_DISPLAY_FIELD = {
      component: 'ErrorDisplay',
      options: {
        shouldDisplay: disableSave,
        message: "Cannot create a visit on the weekend \n if 'Exclude Weekends' is selected"
      }
    };

    const TECHS_FIELD = [
      {
        component: MUIFormComponentNames.SelectInput,
        source: 'technicianIds',
        options: {
          disabled: techsLoading || disabled,
          placeholder: '-',
          valueSet: techOptions,
          isMultipleSelection: true,
          height: 'auto',
          isSearchable: true
        }
      }
    ];

    const DESCRIPTION_FIELD = [
      {
        component: MUIFormComponentNames.TextInput,
        source: 'description',
        options: {
          label: 'DESCRIPTION',
          lines: 4,
          linesMax: 4,
          maxTextLen: 1000
        }
      }
    ];

    const DETAILS_SECTION_CONTENT = [row(DESCRIPTION_FIELD)];

    const SCHEDULING_SECTION_CONTENT = [
      colRow([col('50%', [START_TIME_FIELD_LABEL]), col('50%', [END_TIME_FIELD_LABEL])]),
      colRow([col('50%', [START_TIME_FIELD_INPUT]), col('50%', [END_TIME_FIELD_INPUT])]),
      colRow([col('50%', [START_DATE_FIELD_LABEL]), col('50%', [END_DATE_FIELD_LABEL])]),
      colRow([col('50%', [START_DATE_FIELD_INPUT]), col('50%', [END_DATE_FIELD_INPUT])]),
      EXCLUDE_WEEKENDS_FIELD,
      ERROR_DISPLAY_FIELD
    ];

    const TECHNICIANS_SECTION_CONTENT = [row(TECHS_FIELD)];

    return {
      fields: {},
      layouts: {
        default: {
          contents: [
            {
              component: 'AccordionSection',
              options: {
                heading: 'Details',
                expanded: true
              },
              contents: DETAILS_SECTION_CONTENT
            },
            {
              component: 'AccordionSection',
              options: {
                heading: 'Scheduling',
                expanded: true
              },
              contents: SCHEDULING_SECTION_CONTENT
            },
            {
              component: 'AccordionSection',
              options: {
                heading: 'Technicians',
                expanded: true
              },
              contents: TECHNICIANS_SECTION_CONTENT
            }
          ]
        }
      },
      validation: {
        type: 'object',
        properties: {
          fromTime: {
            type: 'string',
            nullable: true
          },
          toTime: {
            type: 'string',
            nullable: true
          },
          fromDate: {
            type: 'string',
            nullable: true
          },
          toDate: {
            type: 'string',
            nullable: true
          },
          technicianIds: {
            type: 'array'
          }
        },
        required: ['fromTime', 'toTime', 'fromDate', 'toDate', 'technicianIds']
      },
      validationErrors: {
        fromTime: {
          required: 'Start time is required',
          type: 'Value must be a string'
        },
        toTime: {
          required: 'End time is required',
          type: 'Value must be a string'
        },
        fromDate: {
          required: 'Start Date is required',
          type: 'Value must be a string'
        },
        toDate: {
          required: 'End Date is required',
          type: 'Value must be a string'
        },
        technicianIds: {
          required: 'At least one technician is required',
          type: 'Value must be a list'
        }
      }
    };
  }, [disabled, disableSave, techsLoading, techOptions]);
};
