import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const UPDATE_TASK_ENTRIES = gql`
  mutation updateTaskEntriesForTask($partitionKey: String!, $data: UpdateTaskEntriesForTaskInput!) {
    updateTaskEntriesForTask(partitionKey: $partitionKey, data: $data) {
      id
      description
      markupValue
      quantity
      unitCost
      unitPrice
    }
  }
`;

const serializer = ({ tenantId, taskEntries }) => ({
  variables: {
    partitionKey: tenantId,
    data: {
      taskEntries
    }
  }
});

export const useUpdateTaskEntries = () => {
  return useExtendedMutation(UPDATE_TASK_ENTRIES, {
    serializer
  });
};
