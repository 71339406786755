import React from 'react';

import { bool, object } from 'prop-types';
import { useSelector } from 'react-redux';

import useEditPurchaseOrderLine from 'customHooks/useEditPurchaseOrderLine';
import StorageService from 'services/StorageService';

import { getUniqueName } from '../../../../../../../../../utils';
import {
  useAddReviewReportPurchaseOrder,
  useAddReviewReportPurchaseOrderLine,
  useAddReviewReportPurchaseOrderReceipt,
  useDeleteReviewReportPurchaseOrder,
  useDeleteReviewReportPurchaseOrderLine,
  useEditReviewReportPurchaseOrder,
  useEditReviewReportPurchaseOrderReceipt
} from '../../../../../../mutations';
import {
  selectDepartmentName,
  selectJobPriceBookId,
  selectReviewReportPriceBookId,
  selectReviewReportPurchasesItems
} from '../../../../../../selectors';
import { useReviewReportDisabled } from '../../../../ReviewReport.contexts';

import ReviewReportPurchasedItemsComponent from './ReviewReportPurchasedItems.component';

const uploadFile = ({ tenantId, file }) => {
  const storageService = new StorageService();
  const uniqueName = getUniqueName(tenantId, file?.name);
  return storageService.uploadFile(file, uniqueName);
};

const ReviewReportPurchasedItems = ({ reviewReport, visit, job, loading, readOnly }) => {
  const tenantId = useSelector(state => state.user?.tenantId);
  const defaultPriceBookId = useSelector(state => state?.company?.defaultPriceBookId);
  const { disabled } = useReviewReportDisabled();
  const [addPurchaseOrderMutation] = useAddReviewReportPurchaseOrder(reviewReport.id);
  const [addPurchaseOrderReceiptMutation] = useAddReviewReportPurchaseOrderReceipt(reviewReport.id);
  const [addPurchaseOrderLineMutation] = useAddReviewReportPurchaseOrderLine(reviewReport.id);
  const [editPurchaseOrderMutation] = useEditReviewReportPurchaseOrder();
  const [editPurchaseOrderReceiptMutation] = useEditReviewReportPurchaseOrderReceipt();
  const [
    editPurchaseOrderLineMutation,
    { loading: updatingPurchaseOrderLine }
  ] = useEditPurchaseOrderLine({});
  const [deletePurchaseOrderMutation] = useDeleteReviewReportPurchaseOrder(reviewReport.id);
  const [deletePurchaseOrderLineMutation] = useDeleteReviewReportPurchaseOrderLine(reviewReport.id);

  const items = selectReviewReportPurchasesItems(reviewReport) || [];
  const priceBookId =
    selectReviewReportPriceBookId(reviewReport) || selectJobPriceBookId(job) || defaultPriceBookId;
  const departmentName = selectDepartmentName(visit);

  const handleAddPurchaseOrder = async data => {
    const { newFiles, customFileName, ...purchaseOrder } = data;
    const receipt = newFiles?.[0];
    let purchaseOrderReceipts;
    if (receipt) {
      const imageUrl = await uploadFile({ tenantId, file: receipt.file });
      purchaseOrderReceipts = [
        {
          imageUrl,
          fileName: customFileName
        }
      ];
    }
    await addPurchaseOrderMutation({
      tenantId,
      reviewReportId: reviewReport.id,
      purchaseOrder: { ...purchaseOrder, purchaseOrderReceipts }
    });
  };

  const handleEditPurchaseOrder = async (data, dataPrev) => {
    const { newFiles, customFileName, ...purchaseOrder } = data;
    const { newFiles: newFilesPrev, customFileName: customFileNamePrev } = dataPrev;
    const receipt = newFiles?.[0];
    const receiptPrev = newFilesPrev?.[0];
    const purchaseOrderReceiptData = data?.purchaseOrderReceipts?.items?.[0] || {};
    await editPurchaseOrderMutation({
      tenantId,
      receipt,
      receiptPrev,
      purchaseOrder: { ...purchaseOrder }
    });
    if (!receiptPrev?.fileUrl && receipt?.fileUrl) {
      const imageUrl = await uploadFile({ tenantId, file: receipt.file });
      const purchaseOrderId = purchaseOrder?.id;
      const purchaseOrderReceipts = [
        {
          imageUrl,
          fileName: customFileName
        }
      ];
      await addPurchaseOrderReceiptMutation({
        tenantId,
        purchaseOrderId,
        purchaseOrderReceipts
      });
    } else if (receiptPrev?.fileUrl !== receipt?.fileUrl) {
      const imageUrl = await uploadFile({ tenantId, file: receipt.file });
      const purchaseOrderReceipt = {
        ...purchaseOrderReceiptData,
        imageUrl,
        fileName: customFileName
      };
      await editPurchaseOrderReceiptMutation({
        tenantId,
        purchaseOrderReceipt
      });
    } else if (customFileName && customFileName !== customFileNamePrev) {
      const purchaseOrderReceipt = {
        ...purchaseOrderReceiptData,
        fileName: customFileName
      };
      await editPurchaseOrderReceiptMutation({
        tenantId,
        purchaseOrderReceipt
      });
    }
  };

  const handleDeletePurchaseOrder = async purchaseOrder => {
    await deletePurchaseOrderMutation({
      tenantId,
      purchaseOrder
    });
  };

  const handleAddPurchaseOrderLine = purchaseOrderLine =>
    addPurchaseOrderLineMutation({
      tenantId,
      purchaseOrderLine
    });

  const handleDeletePurchaseOrderLine = async purchaseOrderLine =>
    deletePurchaseOrderLineMutation({
      tenantId,
      purchaseOrderLineId: purchaseOrderLine?.id
    });

  const handleEditPurchaseOrderLine = purchaseOrderLine =>
    editPurchaseOrderLineMutation({
      tenantId,
      purchaseOrderLine
    });

  const handleAddPurchaseOrderLineToInvoice = purchaseOrderLine =>
    editPurchaseOrderLineMutation({
      tenantId,
      purchaseOrderLine: {
        ...purchaseOrderLine,
        includeInInvoice: !purchaseOrderLine.includeInInvoice
      }
    });

  return (
    <ReviewReportPurchasedItemsComponent
      departmentName={departmentName}
      disabled={disabled}
      items={items}
      loading={loading}
      priceBookId={priceBookId}
      readOnly={readOnly}
      updating={updatingPurchaseOrderLine}
      onAddPurchaseOrder={handleAddPurchaseOrder}
      onAddPurchaseOrderLine={handleAddPurchaseOrderLine}
      onAddPurchaseOrderLineToInvoice={handleAddPurchaseOrderLineToInvoice}
      onDeletePurchaseOrder={handleDeletePurchaseOrder}
      onDeletePurchaseOrderLine={handleDeletePurchaseOrderLine}
      onEditPurchaseOrder={handleEditPurchaseOrder}
      onEditPurchaseOrderLine={handleEditPurchaseOrderLine}
    />
  );
};

ReviewReportPurchasedItems.propTypes = {
  reviewReport: object.isRequired,
  visit: object.isRequired,
  job: object.isRequired,
  loading: bool.isRequired
};

export default ReviewReportPurchasedItems;
