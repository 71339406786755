import React, { useRef } from 'react';

import { Input } from '@BuildHero/sergeant';
import { Box, Grid, IconButton, useTheme } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { Draggable } from 'react-beautiful-dnd';

import styles from '../../styles';

const AccordionSummary = withStyles(styles.accordionSummary)(MuiAccordionSummary);

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  marginBottom: '5px',

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'rgb(240, 240, 240)',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getCloseStyle = item => ({
  padding: 0,
  marginLeft: 5,
  height: '100%',
  color: !item.unsaved && 'transparent'
});

const BillingHourTypeCard = ({
  item,
  index,
  isDragDisabled,
  billingHourTypes,
  onAddBillingHourType,
  onModifyBillingHourType,
  onRemoveBillingHourType
}) => {
  const theme = useTheme();
  const anchorRef = useRef();

  return (
    <Draggable
      draggableId={`draggable-${item.id}`}
      index={index}
      isDragDisabled={isDragDisabled}
      key={item.id}
    >
      {(provided, snapshot) => (
        <div style={{ opacity: snapshot.isDragging ? 0.5 : 1 }}>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            <Box css={styles.accordionContainer} m={0} p={0}>
              <MuiAccordion square>
                <AccordionSummary>
                  <div {...provided.dragHandleProps} css={styles.dragHandler}>
                    <IconButton css={styles.dragIcon} disableRipple>
                      <DragIndicatorIcon css={styles.dragIcon} />
                    </IconButton>
                  </div>
                  <Grid item justify="flex-start" xs={12}>
                    <Grid container>
                      <Box component="div" width={theme.spacing(68)}>
                        <Input
                          autoFocus={
                            index === billingHourTypes.length - 1 &&
                            billingHourTypes[index].hourType === ''
                          }
                          defaultValue={item.hourType ?? ''}
                          label=""
                          placeholder="Enter Billing Hour Type (eg 'Regular')"
                          onBlur={e => {
                            if (e.target.value) {
                              onModifyBillingHourType({
                                field: 'hourType',
                                value: e.target.value,
                                index
                              });
                            }
                            e.relatedTarget?.click();
                          }}
                          onChange={e =>
                            onModifyBillingHourType({
                              field: 'hourType',
                              value: e.target.value,
                              index
                            })
                          }
                          onKeyDown={e => {
                            if (e.key === 'Enter') onAddBillingHourType();
                          }}
                        />
                      </Box>
                      <Box
                        component="div"
                        sx={{ marginLeft: theme.spacing(1) }}
                        width={theme.spacing(25)}
                      >
                        <Input
                          defaultValue={item.hourTypeAbbreviation ?? ''}
                          label=""
                          placeholder="Enter Short Form (eg 'REG')"
                          onBlur={e => {
                            if (e.target.value) {
                              onModifyBillingHourType({
                                field: 'hourTypeAbbreviation',
                                value: e.target.value,
                                index
                              });
                            }
                            e.relatedTarget?.click();
                          }}
                          onChange={e =>
                            onModifyBillingHourType({
                              field: 'hourTypeAbbreviation',
                              value: e.target.value,
                              index
                            })
                          }
                          onKeyDown={e => {
                            if (e.key === 'Enter') onAddBillingHourType();
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item justify="flex-end">
                    <IconButton
                      disabled={!item.unsaved}
                      disableRipple
                      ref={anchorRef}
                      style={getCloseStyle(item)}
                      onClick={() => onRemoveBillingHourType({ index })}
                    >
                      <CloseIcon css={styles.closeIcon} />
                    </IconButton>
                  </Grid>
                </AccordionSummary>
              </MuiAccordion>
            </Box>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default BillingHourTypeCard;
