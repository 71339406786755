import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import styles from './styles';

/**
 * Material UI text input with BuildHero styles
 */
class RawTextInput extends Component {
  handleOnChange = event => {
    const currentValue = event.target.value;
    const { field, form } = this.props;
    form.setFieldValue(field.name, currentValue, false);
  };

  render() {
    const { field, options, form, classes } = this.props;
    return (
      <TextField
        className={classes.textField}
        disabled={options.disabled || false}
        error={form.errors && form.errors[field.name] && form.errors[field.name] !== ''}
        fullWidth
        helperText={
          form.errors &&
          form.errors[field.name] &&
          form.errors[field.name] !== '' &&
          form.errors[field.name]
        }
        inputProps={
          options && options.label ? '' : { style: { padding: '0px 10px', minHeight: 36 } }
        }
        label={options ? options.label : ''}
        name={field.name}
        value={field.value === null ? '' : field.value}
        variant="outlined"
        onChange={this.handleOnChange}
      />
    );
  }
}

const styled = withStyles(styles)(RawTextInput);
export { styled as TextInput };
