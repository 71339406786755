import React, { useMemo } from 'react';

import { FieldType, TimeInput, TV, TW, Typography } from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';

import { useClockinBreakEntries } from 'scenes/Timesheets/customHooks';

import { getTimeString } from '../helpers';

const styles = {
  timestampInputs: {
    display: 'flex',
    width: '100%',
    marginBottom: 24,
    marginTop: 24,
    flexWrap: 'wrap'
  },
  header: {
    marginTop: '1em'
  },
  timeInput: {
    marginRight: 24,
    width: '100%',
    maxWidth: 150
  }
};

const labels = {
  CLOCK_IN: {
    startTime: 'Clock-in Time',
    endTime: 'Clock-out Time'
  },
  BREAK: {
    startTime: 'Break Start',
    endTime: 'Break End'
  }
};

const ClockinBreakEntries = ({
  employeeId,
  startDayCompanyTZ,
  timezone,
  showClockinTimes,
  showBreakTimes
}) => {
  const [entries, isLoading] = useClockinBreakEntries({
    employeeId,
    startDayCompanyTZ
  });

  const defaultEntries = useMemo(() => {
    const entryTypes = [];
    if (showClockinTimes) {
      entryTypes.push({ type: 'CLOCK_IN' });
    }
    if (showBreakTimes) {
      entryTypes.push({ type: 'BREAK' });
    }
    return entryTypes;
  }, [showClockinTimes, showBreakTimes]);

  const clockinBreakEntries = useMemo(
    () =>
      entries && entries.length
        ? [...entries].sort((entryA, entryB) => entryA.startTime - entryB.startTime)
        : defaultEntries,
    [entries, defaultEntries]
  );

  if (!showClockinTimes && !showBreakTimes) return null;

  return (
    <>
      <Box css={styles.header}>
        <Typography variant={TV.BASE} weight={TW.BOLD}>
          Timestamps
        </Typography>
      </Box>
      <div css={styles.timestampInputs}>
        {clockinBreakEntries?.map(entry => (
          <>
            <TimeInput
              disabled
              innerStyle={{ fontWeight: 700 }}
              label={labels[entry.type].startTime}
              loading={isLoading}
              style={styles.timeInput}
              type={FieldType.DATE}
              value={getTimeString(entry?.startTime, entry?.startTimeTimezone || timezone) || '-'}
            />
            <TimeInput
              disabled
              innerStyle={{ fontWeight: 700 }}
              label={labels[entry.type].endTime}
              loading={isLoading}
              style={styles.timeInput}
              type={FieldType.DATE}
              value={getTimeString(entry?.endTime, entry?.endTimeTimezone || timezone) || '-'}
            />
          </>
        ))}
      </div>
    </>
  );
};

export default ClockinBreakEntries;
