import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import { getContentText } from '../utils';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    fontWeight: 700,
    fontSize: 28,
    color: '#333333',
    margin: '0px 12px',
    wordSpacing: '16px',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'zero' on, 'salt' on, 'ss01' on"
  },
  title: {
    fontSize: 10,
    textTransform: 'uppercase',
    color: '#999999',
    margin: '0px 4px'
  },
  arrowUpIcon: {
    transform: 'rotate(-90deg)',
    color: '#2DCE89',
    fontSize: 20
  }
}));

const KpiElement = props => {
  const classes = useStyles();
  const { type, content, title, arrowUp } = props;

  return (
    <div className={classes.root}>
      <div className={classes.itemContainer}>
        <span className={classes.content}>{getContentText(type, content, 2)}</span>
        <span className={classes.title}>{title}</span>
      </div>
      {arrowUp && <PlayArrowIcon className={classes.arrowUpIcon} />}
    </div>
  );
};

export default KpiElement;
