import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Select, ThemeProvider } from '@BuildHero/sergeant';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

import { PageHeader, Placeholder } from 'components';
import DefaultButton from 'components/Buttons/DefaultButton';
import { Tab, Tabs } from 'components/Tabs';
import useCrews from 'customHooks/useCrews';
import useEmployees from 'customHooks/useEmployees';
import Labels from 'meta/labels';
import ErrorBoundaries from 'scenes/Error';
import {
  getPayrollHourTypes,
  handleTimesheetDownload
} from 'scenes/Payroll/TimeTrackingReport/services';
import { getTenantSettingValueForKey, isJSONParseableObjectOrArray } from 'utils';
import { timecardOpenStatusTypes, TimeCardStatusTypes } from 'utils/AppConstants';
import { AccountingApp } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { reportTypes, tabType } from '../Payroll/TimeTrackingReport/constants';

import { timesheetViews } from './constants';
import {
  useTimeOfOldestTimesheetThatNeedsReview,
  useTimesheetDayCountByStatus,
  useTimesheetPeriodSelectOptions
} from './customHooks';
import { updateTimesheetUrl } from './services';
import Approved from './Tabs/BindersApproved';
import PendingRevision from './Tabs/BindersPendingRevision';
import ToReview from './Tabs/BindersToReview';
import TimesheetFilter from './TimesheetFilter';
import { timesheetFilter } from './TimesheetFilter/utils';

const useStyles = () => ({
  tabsContainer: () => ({ marginTop: 36 }),
  buttons: () => ({ padding: '5px' })
});

const headerStyles = makeStyles(() => ({
  pageTitleContainer: { maxWidth: '200px' },
  controlsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    top: 40,
    marginTop: -40,
    position: 'relative'
  }
}));

const Timesheets = ({ payrollSettings, dateUnix, employeeId, ...props }) => {
  const flags = useFlags();
  const classes = useStyles();
  const user = useSelector(state => state.user);

  const [employees, employeesLoading] = useEmployees({
    filter: { isActive: { eq: true }, isTech: { eq: true } },
    includeDepartments: true
  });

  const [crews] = useCrews();

  const [filter, setFilter] = useState({});

  const employeeOptions = useMemo(() => timesheetFilter({ filter, employees, crews }), [
    employees,
    filter,
    crews
  ]);

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [dayOrWeek, setDayOrWeek] = useState(timesheetViews.DAY);
  const [selectedTab, setSelectedTab] = useState();

  const [isExportDisabled, setIsExportDisabled] = useState(true);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isADPExportLoading, setIsADPExportLoading] = useState(false);
  const [payrollHourTypes, setPayrollHourTypes] = useState([]);

  const [toReviewSelectedDate, setToReviewSelectedDate] = useState();
  const [approvedSelectedDate, setApprovedSelectedDate] = useState();
  const [exportSelectedDate, setExportSelectedDate] = useState({});

  const [dirty, setDirty] = useState(false);

  const accountingApp = getTenantSettingValueForKey('accountingApp');
  const settingsString = getTenantSettingValueForKey('accountingAppSettings');

  const [timesheetPeriods, isFetchingTimesheetPeriods] = useTimesheetPeriodSelectOptions({
    employee: selectedEmployee?.value
  });

  const [
    timeOfOldestTimesheetThatNeedsReview,
    fetchingDefaultDate
  ] = useTimeOfOldestTimesheetThatNeedsReview({
    employeeId: selectedEmployee?.value?.id
  });

  const [
    numDaycardsToReview,
    fetchingDaycardsToReview,
    ,
    refetchNumDaycardsToReview
  ] = useTimesheetDayCountByStatus({
    employeeId: selectedEmployee?.value?.id,
    manualStatuses: timecardOpenStatusTypes
  });

  const [
    numDaycardsDisputed,
    fetchingDaycardsDisputed,
    ,
    refetchNumDaycardsDisputed
  ] = useTimesheetDayCountByStatus({
    employeeId: selectedEmployee?.value?.id,
    manualStatuses: [TimeCardStatusTypes.DISPUTED],
    includeFutureTimesheets: true
  });

  const defaultReviewDay = useMemo(
    () => dateUnix || timeOfOldestTimesheetThatNeedsReview || moment().unix(),
    [dateUnix, timeOfOldestTimesheetThatNeedsReview]
  );

  const isLoading =
    isFetchingTimesheetPeriods ||
    fetchingDefaultDate ||
    fetchingDaycardsToReview ||
    fetchingDaycardsDisputed;

  const setSelectedPeriodFromDate = useCallback(
    date => {
      const period =
        timesheetPeriods.find(
          ({ dateStartUTC, dateEndUTC }) => dateStartUTC <= date && date <= dateEndUTC
        ) || {};
      setSelectedPeriod(period);
    },
    [timesheetPeriods]
  );

  const ADPSettings =
    accountingApp === AccountingApp.INTACCT &&
    isJSONParseableObjectOrArray(settingsString) &&
    JSON.parse(settingsString);

  useEffect(() => {
    if (employeeId) {
      const employee = employees.find(({ id }) => id === employeeId);
      if (employee) {
        setSelectedEmployee({
          label: employee.code ? `${employee.name} (${employee.code})` : employee.name,
          value: employee
        });
      }
    }
  }, [employeeId, employees]);

  useEffect(() => {
    (() => {
      setIsExportDisabled(true);
      if (selectedEmployee) {
        updateTimesheetUrl({ employee: selectedEmployee.value, date: defaultReviewDay });
        if (!fetchingDefaultDate) {
          setSelectedPeriodFromDate(defaultReviewDay);
        }
      }
    })();
  }, [selectedEmployee, defaultReviewDay]);

  useEffect(() => {
    getPayrollHourTypes({
      user,
      snackbarOn: props.snackbarOn,
      successCallback: setPayrollHourTypes
    });
  }, []);

  const handleExportClick = async summaryType => {
    setIsExportLoading(true);
    await handleTimesheetDownload({
      snackbarOn: props.snackbarOn,
      selectedTimesheetPeriod: exportSelectedDate,
      summaryType
    });
    setIsExportLoading(false);
  };

  const handleADPExportClick = async summaryType => {
    setIsADPExportLoading(true);

    await handleTimesheetDownload({
      snackbarOn: props.snackbarOn,
      selectedTimesheetPeriod: exportSelectedDate,
      summaryType,
      settings: { ADPSettings }
    });
    setIsADPExportLoading(false);
  };

  const handleUpdateDaycard = () => {
    refetchNumDaycardsToReview();
    refetchNumDaycardsDisputed();
  };

  const changeTab = async (_, tab) => {
    const selectedDate =
      (tab === tabType.APPROVED ? approvedSelectedDate : toReviewSelectedDate) || defaultReviewDay;
    setSelectedPeriodFromDate(selectedDate);
    setSelectedTab(tab);
    setDirty(false);
  };

  const sharedTabProps = {
    selectedEmployee: selectedEmployee?.value,
    payrollHourTypes,
    timesheetPeriods,
    payrollSetting: payrollSettings,
    snackbarOn: props.snackbarOn,
    setExportSelectedDate,
    setIsExportDisabled,
    dayOrWeek,
    setDayOrWeek,
    selectedPeriod,
    setSelectedPeriod
  };

  return (
    <ErrorBoundaries>
      <Grid>
        <PageHeader
          breadcrumbsArray={[
            { title: Labels.accountingLabel[user.locale], link: '' },
            { title: Labels.payroll[user.locale], link: '/accounting/payroll/report/time-tracking' }
          ]}
          classes={headerStyles()}
          justifyChildren="flex-start"
          overrideHeaderButtons={[
            <Grid css={classes.buttons}>
              <DefaultButton
                disabled={isExportDisabled || isExportLoading || selectedTab === tabType.PENDING}
                label="Export Manual Timesheet"
                showSpinner={isExportLoading}
                onClick={() => handleExportClick(reportTypes.MANUAL)}
              />
            </Grid>,
            flags[FeatureFlags.ADP_EXPORT] ? (
              <Grid css={classes.buttons}>
                <DefaultButton
                  disabled={isADPExportLoading}
                  label="ADP Export"
                  showSpinner={isADPExportLoading}
                  onClick={() => handleADPExportClick(reportTypes.ADP)}
                />
              </Grid>
            ) : null
          ]}
          pageMapKey="timesheets"
          userLocale={user.locale}
        >
          <ThemeProvider>
            <Select
              loading={employeesLoading}
              menuHeight={230}
              options={employeeOptions}
              placeholder="Select an employee"
              searchable
              style={{ maxWidth: 406 }}
              value={selectedEmployee}
              onChange={employee => {
                if (selectedEmployee?.value?.id !== employee?.value?.id) {
                  setSelectedEmployee(employee);
                  setDayOrWeek(timesheetViews.DAY);
                  setToReviewSelectedDate();
                  setApprovedSelectedDate();
                  setSelectedPeriod();
                  updateTimesheetUrl({ employee: employee.value, date: '' });
                }
              }}
            />
            <div css={{ width: 180, marginLeft: 20 }}>
              <TimesheetFilter
                crews={crews}
                employees={employees}
                filter={filter}
                filterBy={setFilter}
              />
            </div>
          </ThemeProvider>
        </PageHeader>
      </Grid>

      {/* empty div to fix scroll issue */}
      {!selectedEmployee && <div style={{ height: 300 + 52 }} />}
      {selectedEmployee && isLoading && <Placeholder variant="table" />}
      {selectedEmployee && !isLoading && (
        <div css={classes.tabsContainer}>
          <Tabs confirmSwitch={dirty} onChange={changeTab}>
            <Tab label={`To Review (${numDaycardsToReview})`} tabKey={tabType.REVIEW}>
              <ToReview
                {...sharedTabProps}
                selectedDate={toReviewSelectedDate || defaultReviewDay}
                setDirty={setDirty}
                setSelectedDate={date => {
                  setToReviewSelectedDate(date);
                  setSelectedPeriodFromDate(date);
                }}
                user={user}
                onUpdateDayCard={handleUpdateDaycard}
              />
            </Tab>
            <Tab label={`Pending Revision (${numDaycardsDisputed})`} tabKey={tabType.PENDING}>
              <PendingRevision
                payrollHourTypes={payrollHourTypes}
                payrollSetting={payrollSettings}
                selectedEmployee={selectedEmployee.value}
                onUpdateDayCard={handleUpdateDaycard}
              />
            </Tab>
            <Tab label="Approved" tabKey={tabType.APPROVED}>
              <Approved
                {...sharedTabProps}
                selectedDate={approvedSelectedDate || defaultReviewDay}
                setSelectedDate={date => {
                  setApprovedSelectedDate(date);
                  setSelectedPeriodFromDate(date);
                }}
                onUpdateDayCard={handleUpdateDaycard}
              />
            </Tab>
          </Tabs>
        </div>
      )}
    </ErrorBoundaries>
  );
};

export default Timesheets;
