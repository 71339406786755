import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { UserPermission } from 'components';
import ErrorBoundaries from 'scenes/Error';

import { PermissionConstants } from 'utils/AppConstants';

import CustomTabs from '../../components/CustomTabs';
import SubmenuTitle from '../../components/SubmenuTitle';

import Budget from './Budget';
import SoVs from './SoVs';
import Users from './Users';

const ProjectSettings = props => {
  const { projectId } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const [contractValue, setContractValue] = useState(0);
  const tabMenus = [
    { tabLabel: 'Schedule of Values' },
    { tabLabel: 'Budget' },
    { tabLabel: 'Users' }
  ];
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const updateTotalContractValue = total => {
    setContractValue(total || 0);
  };

  const showTabPanel = tab => {
    switch (tabMenus[tab].tabLabel) {
      case 'Schedule of Values':
        return <SoVs projectId={projectId} updateTotalContractValue={updateTotalContractValue} />;
      case 'Budget':
        return <Budget contractValue={contractValue} projectId={projectId} />;
      case 'Users':
        return <Users projectId={projectId} />;
      default:
        return <Typography>No Results</Typography>;
    }
  };
  return (
    <ErrorBoundaries>
      <UserPermission action={PermissionConstants.OBJECT_PM_PROJECT_SETTINGS} I="read">
        <SubmenuTitle>Project Settings</SubmenuTitle>
        <CustomTabs tabMenus={tabMenus} value={selectedTab} onChange={handleTabChange} />
        {showTabPanel(selectedTab)}
      </UserPermission>
    </ErrorBoundaries>
  );
};

ProjectSettings.propTypes = {
  projectId: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => ({
  projectId: props.match?.params?.projectId
});

export default connect(mapStateToProps)(ProjectSettings);
