import React, { useEffect, useState } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DefaultButton from 'components/Buttons/DefaultButton';
import FullScreenModal from 'components/FullScreenModal';
import { defaultRFIForm, fields } from 'meta/ProjectManagement/RFI/defaultRFIForm';
import { snackbarOn } from 'redux/actions/globalActions';

import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';
import buildHeroMuiFormOverrideWithRadio from 'scenes/ProjectManagement/components/buildHeroMuiFormOverrideWithRadio';
import { generateDefaultValidationSchema } from 'scenes/ProjectManagement/components/formattingUtils';
import { getNextNumber, rfiChange, rfiCreate } from 'services/API/rfi';
import { truncateString } from 'utils';
// import { getCustomerById } from 'services/API/customer';

const useStyles = makeStyles(theme => ({
  formContainer: buildHeroMuiFormOverrideWithRadio(theme)
}));

const GenerateRFI = props => {
  const { initialData, editMode, open, projectId, projectData, handleClose, snackbar } = props;
  const classes = useStyles();
  const history = useHistory();
  const [formService, setFormService] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nextNumber, setNextNumber] = useState('');
  // const [propertyBillingCustomer, setPropertyBillingCustomer] = useState(null);

  // Temporarily commented out: not used
  // const getSendToContacts = () => {
  //   const sendToContacts = [];
  //   if (projectData?.customer?.id) {
  //     sendToContacts.push({
  //       label: projectData.customer.customerName,
  //       value: projectData.customer.id
  //     });
  //   }
  //   if (projectData?.billingCustomer?.id) {
  //     sendToContacts.push({
  //       label: projectData.billingCustomer.customerName,
  //       value: projectData.billingCustomer.id
  //     });
  //   }
  //   if (propertyBillingCustomer?.id) {
  //     sendToContacts.push({
  //       label: propertyBillingCustomer.customerName,
  //       value: propertyBillingCustomer.id
  //     });
  //   }
  //   return sendToContacts;
  // };

  const sanitizeData = (data, type) => {
    return {
      status: data.status,
      number: data.number,
      revision: data.revision,
      sendTo: data?.SendTo?.id || null,
      from: data?.ReturnTo?.id || null,
      subject: truncateString(data?.subject, 250),
      informationRequested: data.informationRequested || null,
      recommendation: data.recommendation || null,
      impactToTimeline: data?.impactToTimeline?.toLowerCase() || null,
      impactToCost: data?.impactToCost?.toLowerCase() || null,
      specRef: data.specRef || null,
      drawingRef: data.drawingRef || null,
      detailRef: data.detailRef || null,
      rfiAnswer: data?.rfiAnswer || null,
      viewed: null,
      sentDate: type === 'update' && data.status === 'sent' ? moment().unix() : null,
      receivedDate: null,
      dueDate: data?.dueDateText ? moment(data?.dueDateText).unix() : data?.dueDate,
      rfiAnswerDate: data?.rfiAnswerDateText
        ? moment(data?.rfiAnswerDateText).unix()
        : data?.rfiAnswerDate || null,
      projectId
    };
  };

  const getFormattedData = passedData => {
    if (!passedData) return {};

    return {
      ...passedData,
      status: passedData?.status || 'draft',
      number: passedData?.number || nextNumber,
      revision: editMode ? `${parseInt(passedData.revision, 10) + 1}` : passedData?.revision || '1',
      SendTo: passedData?.SendTo,
      sendToName: passedData?.SendTo?.name || '',
      ReturnTo: passedData?.ReturnTo,
      fromName: passedData?.ReturnTo?.name || '',
      subject: passedData?.subject || null,
      informationRequested: passedData?.informationRequested || null,
      recommendation: passedData?.recommendation || null,
      impactToTimeline: passedData?.impactToTimeline
        ? passedData.impactToTimeline.charAt(0).toUpperCase() + passedData.impactToTimeline.slice(1)
        : null,
      impactToCost: passedData?.impactToCost
        ? passedData.impactToCost.charAt(0).toUpperCase() + passedData.impactToCost.slice(1)
        : null,
      specRef: passedData?.specRef || null,
      drawingRef: passedData?.drawingRef || null,
      detailRef: passedData?.detailRef || null,
      rfiAnswer: passedData?.rfiAnswer || null,
      dueDate:
        passedData?.dueDate || passedData?.dueDateText
          ? moment(passedData?.dueDateText, 'MM/DD/YYYY').unix()
          : null,
      dueDateText:
        passedData?.dueDateText || passedData?.dueDate
          ? moment.unix(passedData.dueDate).format('YYYY-MM-DD')
          : null,
      rfiAnswerDate: passedData?.rfiAnswerDateText
        ? moment(passedData?.rfiAnswerDateText, 'MM/DD/YYYY').unix()
        : null,
      rfiAnswerDateText: passedData?.rfiAnswerDate
        ? moment.unix(passedData?.rfiAnswerDate).format('YYYY-MM-DD')
        : null
    };
  };

  // Temporarily commented out: not used
  // const getCustomerInfo = () => {
  //   if (projectData?.property?.billingCustomerId) {
  //     getCustomerById(projectData.property.billingCustomerId).then(customer =>
  //       setPropertyBillingCustomer(customer)
  //     );
  //   }
  // };

  // useEffect(() => {
  //   getCustomerInfo();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [editMode, open]);

  useEffect(() => {
    getNextNumber(projectId).then(number => setNextNumber(number));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseButton = () => {
    setIsSubmitting(false);
    handleClose();
  };

  const handleUpdate = async data => {
    setIsSubmitting(true);
    const payload = sanitizeData(data, 'update');

    if (payload.rfiAnswerDate && payload.status !== 'closed') {
      payload.status = 'responded';
      // Set sentDate to answer date if no sentDate is present.
      // This can happen if RFI's status is set from 'Draft' to 'Responded'
      // before being sent.
      if (!payload.sentDate) {
        payload.sentDate = payload.rfiAnswerDate;
      }
    }
    await rfiChange(initialData.itemData?.id, payload).then(rfi => {
      if (rfi.id) {
        snackbar('success', `RFI ${rfi.number} Updated`);
      } else {
        snackbar('error', 'Failed to update');
      }
    });
    setIsSubmitting(false);
    handleClose();
  };

  const handleCreate = async data => {
    setIsSubmitting(true);
    const payload = sanitizeData(data, 'create');

    if (payload.rfiAnswerDate && payload.status !== 'closed') {
      payload.status = 'responded';
      payload.sentDate = moment(new Date()).unix();
    }
    await rfiCreate(payload).then(rfi => {
      if (rfi.id) {
        snackbar('success', `RFI ${rfi.number} Saved`);
        history.push(`/project/view/${projectId}/rfi/`);
      } else {
        snackbar('error', 'Failed to create a new RFI');
      }
    });
    setIsSubmitting(false);
    handleClose();
  };

  const formattedData = getFormattedData(initialData.itemData);

  return (
    <FullScreenModal
      handleClose={handleCloseButton}
      modalHeaderButtons={[
        <DefaultButton
          disabled={isSubmitting}
          key="saveSubmittalPackageBtn"
          label="Save RFI"
          showSpinner={isSubmitting}
          variant="containedPrimary"
          onClick={() => formService.submit()}
        />
      ]}
      open={open}
      title={editMode ? `Edit RFI ${initialData.itemData?.number}` : 'Generate RFI'}
    >
      <div className={classes.formContainer}>
        <MUIForm
          configuration={defaultRFIForm({ editMode, project: projectData })}
          customComponents={{ SearchBar }}
          data={formattedData}
          layout="edit"
          validationSchema={generateDefaultValidationSchema(fields)}
          onComplete={editMode ? handleUpdate : handleCreate}
          onCreateService={service => setFormService(service)}
        />
      </div>
    </FullScreenModal>
  );
};

GenerateRFI.propTypes = {
  initialData: PropTypes.object,
  editMode: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  projectData: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  snackbar: PropTypes.func.isRequired
};

GenerateRFI.defaultProps = {
  initialData: {}
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  snackbar: (mode, message) => dispatch(snackbarOn(mode, message))
});
const ReduxConnectedGenerateRFI = connect(mapStateToProps, mapDispatchToProps)(GenerateRFI);
export default ReduxConnectedGenerateRFI;
