import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { useSnackbar } from 'customHooks/useSnackbar';
import { logErrorWithCallback } from 'utils';

export const getTimeOfOldestTimesheetThatNeedsReviewQuery = gql`
  query getTimeOfOldestTimesheetThatNeedsReview($employeeId: String!) {
    getTimeOfOldestTimesheetThatNeedsReview(employeeId: $employeeId)
  }
`;

const useTimeOfOldestTimesheetThatNeedsReview = ({ employeeId }, options = {}) => {
  const snackbar = useSnackbar();

  const { data, loading, error, refetch } = useExtendedQuery(
    getTimeOfOldestTimesheetThatNeedsReviewQuery,
    {
      variables: { employeeId },
      transform: result => result?.getTimeOfOldestTimesheetThatNeedsReview,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: !employeeId,
      ...options
    }
  );

  if (error) {
    logErrorWithCallback(
      error,
      snackbar,
      'Unable to fetch the time of oldest event that needs review'
    );
  }
  return [data || 0, loading, error, refetch];
};

export default useTimeOfOldestTimesheetThatNeedsReview;
