import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { useSnackbar } from 'customHooks/useSnackbar';
import { logErrorWithCallback } from 'utils';

export const getNumberOfSubmittedDayCardsQuery = gql`
  query getNumberofSubmittedDayCards($employeeId: String!) {
    getNumberOfSubmittedDayCards(employeeId: $employeeId)
  }
`;

const useNumberOfSubmittedDayCards = ({ employeeId }, options = {}) => {
  const snackbar = useSnackbar();

  const { data, loading, error, refetch } = useExtendedQuery(getNumberOfSubmittedDayCardsQuery, {
    variables: { employeeId },
    transform: result => result?.getNumberOfSubmittedDayCards,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !employeeId,
    ...options
  });

  if (error) {
    logErrorWithCallback(error, snackbar, `Unable to fetch timesheets to review`);
  }
  return [data || 0, loading, error, refetch];
};

export default useNumberOfSubmittedDayCards;
