import gql from 'graphql-tag';

import { useSelector } from 'react-redux';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { ServiceAgreementStatus } from 'utils/AppConstants';

const CREATE_SERVICE_AGREEMENT = gql`
  mutation AddServiceAgreementToCompany($data: ServiceAgreementInput!) {
    addServiceAgreementToCompany(data: $data) {
      id
      companyId
      status
      agreementName
      agreementNumber
      firstBillDate
    }
  }
`;

export const useCreateDraftServiceAgreement = options => {
  const user = useSelector(state => state.user);

  return useExtendedMutation(CREATE_SERVICE_AGREEMENT, {
    serializer: ({
      customerId,
      billingCustomerId,
      startDate,
      endDate,
      paymentTerms,
      termPrice,
      agreementName,
      firstBillDate,
      customerPoNumber,
      rateCardJSON,
      departmentId,
      soldById,
      billingType,
      annualContractValue,
      pricebookId
    }) => ({
      variables: {
        data: {
          companyId: user.tenantCompanyId,
          customerId,
          billingCustomerId,
          startDate,
          endDate,
          status: ServiceAgreementStatus.DRAFT,
          paymentTerms,
          termPrice: termPrice || 0,
          agreementName,
          firstBillDate,
          customerPoNumber,
          // @TODO - BE currently doesn't / can't create an SA with a rate card. using rateCardJSON as a workaround
          rateCard: rateCardJSON ? JSON.parse(rateCardJSON) : {},
          rateCardJSON,
          departmentId,
          soldById,
          billingType,
          annualContractValue: annualContractValue || 0,
          pricebookId
        }
      }
    }),
    transform: result => result?.addServiceAgreementToCompany,
    onSuccess: (transformedData, snackbarOn) => {
      options?.onComplete?.(transformedData);
      snackbarOn('success', 'Draft Service Agreement Created');
    },
    onError: (transformedData, snackbarOn) =>
      snackbarOn('error', 'Unable to Save Service Agreement')
  });
};
