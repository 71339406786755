import React, { useState } from 'react';

import {
  Button,
  ButtonType,
  Field,
  FieldType,
  Modal,
  ThemeProvider,
  TV,
  Typography
} from '@BuildHero/sergeant';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PageHeader, UserPermission, XGrid } from 'components';
import ErrorBoundaries from 'scenes/Error';
import { PermissionConstants } from 'utils/AppConstants';

import { useGenerateWIPReport } from '../mutations/useGenerateWIPReport';
import { GET_REST_WIP_REPORT_LIST } from '../queries/useGetWIPReportList';

import { WIP_LIST_COLUMNS } from './WIPList.columns';

const WIPReportsList = () => {
  const history = useHistory();
  const user = useSelector(state => state.user);
  const [openModal, setOpenModal] = useState(false);

  const pageHeaderButtons = [
    <ThemeProvider>
      <Button type={ButtonType.PRIMARY} onClick={() => setOpenModal(true)}>
        Generate WIP Report
      </Button>
    </ThemeProvider>
  ];

  const [genWIPReport] = useGenerateWIPReport({
    onRequest: (variables, snackbarOn) => {
      snackbarOn('success', 'Generating WIP Report');
    },
    onSuccess: (wipReport, snackbarOn) => {
      snackbarOn('success', 'Successfully generated WIP Report');
      history.push({
        pathname: `/wipReports/view/${wipReport.id}`
      });
    }
  });

  const handleGenerateWIP = async () => {
    genWIPReport();
    setOpenModal(false);
  };

  return (
    <ErrorBoundaries>
      <UserPermission action={PermissionConstants.MENU_PROJECT_MANAGEMENT} I="allow">
        <PageHeader
          overrideHeaderButtons={pageHeaderButtons}
          pageMapKey="wipReports"
          userLocale={user.locale}
        />
        <XGrid
          columns={WIP_LIST_COLUMNS}
          entityTypeName="WIPReport"
          query={GET_REST_WIP_REPORT_LIST}
          tableName="WIPReportXGrid"
        />
      </UserPermission>
      <ThemeProvider>
        <Modal
          actions={
            <Button fullWidth type={ButtonType.PRIMARY} onClick={handleGenerateWIP}>
              Generate WIP Report
            </Button>
          }
          open={openModal}
          PaperProps={{ style: { width: 454 } }}
          title="Generate WIP Report"
          onClose={() => setOpenModal(false)}
        >
          <Grid container>
            <Field label="As of Date" type={FieldType.DATETIME} value={+moment()} />
          </Grid>
          <Typography style={{ paddingTop: 36 }} variant={TV.BASE}>
            Clicking &quot;GENERATE WIP REPORT&quot; will proceed to generate a WIP report as of the
            date above. Are you sure you wish to continue?
          </Typography>
        </Modal>
      </ThemeProvider>
    </ErrorBoundaries>
  );
};

export default WIPReportsList;
