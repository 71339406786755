import React, { useCallback, useMemo } from 'react';

import { ThemeProvider } from '@BuildHero/sergeant';
import { array, bool, func, object, string } from 'prop-types';

import { ProductWithPricingSearch } from 'components';
import SergeantModal from 'components/SergeantModal';
import { PurchasedItemSgtLayout } from 'meta/Jobs/Invoice/PurchasedItemSgtForm';
import { validations } from 'services/core';
import { constructSelectOptions } from 'utils/constructSelectOptions';

import { useCostCodes, useCostTypes, useS3ImageUrl } from './PurchaseOrderLineModal.hooks';

const PurchaseOrderLineModal = ({
  priceBookId,
  purchaseOrders,
  purchaseOrderLine,
  open,
  mode,
  onClose,
  onAction
}) => {
  const purchaseOrder = useMemo(
    () => purchaseOrders.find(item => item?.id === purchaseOrderLine?.parentId),
    [purchaseOrders, purchaseOrderLine]
  );
  const imageUrl = purchaseOrder?.purchaseOrderReceipts?.items?.[0]?.imageUrl;
  const options = useMemo(() => ({ skip: !open }), [open]);
  const s3Url = useS3ImageUrl(imageUrl, options);
  const { data: costCodes } = useCostCodes(options);
  const {
    data: { jobCostTypes, revenueTypes }
  } = useCostTypes(options);

  const data = useMemo(
    () => ({
      ...purchaseOrderLine,
      markupValue: purchaseOrderLine?.markup,
      amount: purchaseOrderLine?.totalAmount,
      s3Url
    }),
    [purchaseOrderLine, s3Url]
  );

  const formData = useMemo(
    () => ({
      includeImage: Boolean(s3Url),
      priceBookId,
      costCodes: constructSelectOptions(costCodes),
      costTypes: constructSelectOptions(jobCostTypes),
      revenueTypes: constructSelectOptions(revenueTypes)
    }),
    [s3Url, priceBookId, costCodes, jobCostTypes, revenueTypes]
  );

  const handlePrimaryAction = useCallback(
    async (newData, stopLoading) => {
      await onAction(newData);
      stopLoading();
    },
    [onAction]
  );

  const layout = useMemo(
    () =>
      PurchasedItemSgtLayout({
        formData
      }),
    [formData]
  );

  const ThemedProductWithPricingSearch = props => (
    <ThemeProvider>
      <ProductWithPricingSearch {...props} />
    </ThemeProvider>
  );

  return (
    <SergeantModal
      customComponents={{ ThemedProductWithPricingSearch }}
      data={data}
      dataType="receipt line item"
      handleClose={onClose}
      handlePrimaryAction={handlePrimaryAction}
      layout={layout}
      mode={mode}
      open={open}
      validationSchema={validations.reviewReportPurchaseOrderLinesSchema}
    />
  );
};

PurchaseOrderLineModal.propTypes = {
  priceBookId: string.isRequired,
  purchaseOrders: array.isRequired,
  purchaseOrderLine: object.isRequired,
  open: bool.isRequired,
  mode: string.isRequired,
  onClose: func.isRequired,
  onAction: func.isRequired
};

export default PurchaseOrderLineModal;
