import gql from 'graphql-tag';
import { pick } from 'lodash';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { logErrorWithCallback } from 'utils';

const BULK_UPDATE_BILLING_HOUR_TYPES = gql`
  mutation bulkUpdateBillingHourTypes($data: AddBillingHourTypesToCompanyInput!) {
    bulkUpdateBillingHourTypes(data: $data) {
      id
      sortOrder
      hourType
      hourTypeAbbreviation
    }
  }
`;

const serializer = ({ companyId, billingHourTypes }) => ({
  variables: {
    data: {
      companyId,
      billingHourTypes: billingHourTypes.map(type =>
        pick(type, ['id', 'sortOrder', 'hourType', 'hourTypeAbbreviation'])
      )
    }
  }
});

const update = (cache, { data: { bulkUpdateBillingHourTypes } }, ref) => {
  cache.modify({
    id: `Company:${ref.companyId}`,
    fields: {
      billingHourTypes() {
        return bulkUpdateBillingHourTypes.map(billingHourType => {
          return {
            __ref: `${billingHourType.__typename}:${billingHourType.id}`
          };
        });
      }
    }
  });
};

export const useBulkUpdateBillingHourTypes = () => {
  return useExtendedMutation(BULK_UPDATE_BILLING_HOUR_TYPES, {
    serializer,
    update,
    onSuccess: (data, snackbarOn) =>
      snackbarOn('success', 'Successfully updated billing hour types'),
    onError: (err, snackbarOn) =>
      logErrorWithCallback(err, snackbarOn, 'Unable to update billing hour types')
  });
};
