import gql from 'graphql-tag';

import { updateWatchedManDayVisits } from '@dispatch/Dispatch.utils';
import { manDayFragment } from '@dispatch/fragments/manDayFragment';
import useExtendedMutation from 'customHooks/useExtendedMutation';

const UPDATE_MAN_DAY_MUTATION = gql`
  mutation updateManDay($data: UpdateManDayInput!) {
    updateManDay(data: $data) {
      ${manDayFragment}
    }
  }
`;

const serializer = data => {
  const { id, startDateTime, endDateTime, technicianIds, description } = data;
  return {
    variables: {
      data: {
        id,
        startDateTime,
        endDateTime,
        technicianIds,
        description
      }
    }
  };
};

const optimisticResponseFactory = ({
  id,
  startDateTime,
  endDateTime,
  technicianIds,
  description
}) => ({
  updateManDay: {
    __typename: 'ManDay',
    id,
    startDateTime,
    endDateTime,
    technicianIds,
    description,
    isActive: true
  }
});

const useUpdateManDay = () => {
  return useExtendedMutation(UPDATE_MAN_DAY_MUTATION, {
    serializer,
    optimisticResponseFactory,
    update: (cache, { data }) => {
      updateWatchedManDayVisits(cache, data.updateManDay);
    }
  });
};

export default useUpdateManDay;
