import uuid from 'uuid';

import { CompanyService } from 'services/core';
import { logErrorWithCallback } from 'utils';
import { EmployeeAction } from 'utils/AppConstants';

import { getEmployeeDetail } from './helpers';

export const getEmployee = async ({ sortKey, tenantId, snackbarOn, successCallback }) => {
  try {
    if (!sortKey) return null;
    const Service = new CompanyService();
    const { data } = await Service.getEmployee(tenantId, sortKey);
    const employee = getEmployeeDetail(data.getEmployee);
    successCallback(employee);
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to fetch the image, please try again later');
  }
};

export const checkUsernameExists = async ({ userName, snackbarOn, successCallback }) => {
  try {
    const Service = new CompanyService();
    const { data } = await Service.checkUsernameExists(userName);
    successCallback(data?.checkUsernameExists || false);
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to fetch the image, please try again later');
  }
};

export const saveEmployee = async ({ user, employee, snackbarOn, action, successCallback }) => {
  try {
    const Service = new CompanyService();
    const { tenantId, tenantCompanyId: companyId } = user;
    if (employee.id) {
      await Service.saveEmployee({
        input: {
          ...employee,
          name: `${employee.firstName || ''} ${employee.lastName || ''}`
        },
        action
      });
    } else {
      const data = { companyId, employees: [{ ...employee, id: uuid.v4() }] };
      await Service.addEmployeesToCompany(tenantId, data);
    }

    const successMessage = {
      [EmployeeAction.RESEND_CONFIRMATION_EMAIL]: `Resent BuildOps invitation to ${employee.email}`,
      [EmployeeAction.INVITE]: `Sent BuildOps invitation to ${employee.email}`,
      [EmployeeAction.DEACTIVATE]: 'Successfully deactivated employee',
      [EmployeeAction.ACTIVATE]: 'Successfully activated employee',
      [EmployeeAction.ENABLE_LOGIN]: 'Successfully enabled login for employee'
    };
    snackbarOn('success', successMessage[action] ?? 'Successfully saved employee');

    successCallback();
  } catch (error) {
    logErrorWithCallback(
      error,
      snackbarOn,
      'Unable to save Employee Details, please try again later'
    );
  }
};
