import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

export const GET_EMBEDS = gql`
  query getSigmaEmbeds($tenantId: String!) {
    getSigmaEmbeds(tenantId: $tenantId) {
      id
      isDefault
      sigmaEmbedName
      sigmaEmbedUrl
    }
  }
`;

export const GENERATE_EMBED_URL = gql`
  mutation generateSigmaEmbedLink($tenantId: String!, $id: String!) {
    generateSigmaEmbedLink(tenantId: $tenantId, id: $id) {
      id
      url
    }
  }
`;

export const useEmbedsQuery = tenantId => {
  const { loading, error, data: { getSigmaEmbeds: rawData } = {}, ...rest } = useQuery(GET_EMBEDS, {
    variables: { tenantId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: !tenantId
  });

  return { loading: loading || !rawData, error, data: rawData, ...rest };
};

export const useGenerateSigmaEmbedLinkMutation = () => {
  const [generateEmbedUrl, { loading, error }] = useMutation(GENERATE_EMBED_URL);
  return {
    generateEmbedUrl: async (tenantId, id) => {
      const response = await generateEmbedUrl({ variables: { tenantId, id } });
      return response.data.generateSigmaEmbedLink;
    },
    loading,
    error
  };
};
