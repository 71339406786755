import React from 'react';

import { Paper, Typography, withStyles } from '@material-ui/core';
import moment from 'moment';

const ConfirmRemoveContent = withStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    display: 'block'
  },
  caption: {
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    display: 'inline'
  },
  captionAdditionalStatement: {
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    display: 'inline',
    color: 'red',
    fontWeight: 'bold'
  }
}))(
  ({
    classes,
    dataType,
    data,
    mode,
    confirmRemoveItemLabel,
    confirmRemoveStatement,
    warningMessageFont
  }) => (
    <div className={classes.root}>
      <Typography className={classes.caption} variant="caption">
        {`Are you sure you want to ${mode} this ${dataType.toLocaleLowerCase()}? `}
      </Typography>
      <Typography
        className={warningMessageFont ? classes.captionAdditionalStatement : classes.caption}
        variant="caption"
      >
        {confirmRemoveStatement}
      </Typography>
      {confirmRemoveItemLabel && (
        <Paper className={classes.paper} elevation={0} square>
          <Typography variant="h6">{confirmRemoveItemLabel}</Typography>
          {data.lastUpdatedDateTime && (
            <Typography variant="caption">
              Last edited - {moment(+data.lastUpdatedDateTime).fromNow()}
            </Typography>
          )}
        </Paper>
      )}
    </div>
  )
);

export default ConfirmRemoveContent;
