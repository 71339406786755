import React, { useEffect, useState } from 'react';

import { TV, TW, Typography } from '@BuildHero/sergeant';
import {
  FormControlLabel,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

import { connect } from 'react-redux';

import { PageHeader, SectionHeader, UserPermission } from 'components';
import { COMPANY_SETTING_TYPE } from 'constants/common';
import useCompanySettings from 'customHooks/useCompanySettings';
import { useSnackbar } from 'customHooks/useSnackbar';
import useTenantCompanyId from 'customHooks/useTenantCompanyId';
import useTenantId from 'customHooks/useTenantId';
import labels from 'meta/MobileSettings/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { CommonService, CompanyService } from 'services/core';
import { Logger } from 'services/Logger';

import theme from 'themes/BuildHeroTheme';
import { PermissionConstants } from 'utils/AppConstants';

import getWorkflowStepsForCompany from './getWorkflowStepsForCompany';
import updateWorkflowSteps from './updateWorkflowSteps';

const tableRowStyle = {
  '&:first-of-type': {
    borderTop: 'none !important'
  },
  '&:last-of-type': {
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important'
  }
};

const tableCellStyle = {
  border: 'none',
  padding: 0
};

const tableCellTogglesStyle = {
  border: 'none',
  display: 'flex'
};

const settingsConfig = {
  visitSummaryVisible: {
    enabled: true,
    parent: null,
    child: null
  },
  assetsWorkedOnVisible: {
    enabled: true,
    parent: null,
    child: null
  },
  inventoryItemsUsedVisible: {
    enabled: true,
    parent: null,
    child: null
  },
  purchasedItemsVisible: {
    enabled: true,
    parent: null,
    child: 'purchasedItemsVendorNameVisible'
  },
  purchasedItemsVendorNameVisible: {
    enabled: true,
    parent: 'purchasedItemsVisible',
    child: null
  }
};

const subSettingStyle = { paddingLeft: 40 };

function mapDBSettingsToConfig(settings) {
  const _settings = { ...settingsConfig };
  Object.keys(settings).map(key => {
    if (_settings[key]) {
      _settings[key].enabled = settings[key];
    }
  });
  return _settings;
}

function generateCustomerSignatureSettings(settings, user) {
  // filtering out settings without a label
  const filteredSettingKeys = Object.keys(settings).filter(setting => labels[setting]);
  const cusSignatureSettingsSteps = filteredSettingKeys.map(settingKey => {
    return {
      key: settingKey,
      label: labels[settingKey][user.locale],
      enabled: settings[settingKey].enabled,
      child: settings[settingKey].child,
      parent: settings[settingKey].parent
    };
  });

  const cusSignatureSettingsStepMap = {};
  cusSignatureSettingsSteps.forEach(setting => {
    cusSignatureSettingsStepMap[setting.key] = setting;
  });

  return {
    signatureSettingsSteps: cusSignatureSettingsSteps,
    signatureSettingsStepMap: cusSignatureSettingsStepMap
  };
}

const Workflow = props => {
  const { user, snackbarOn } = props;
  const { locale } = user ?? {};
  const tenantId = useTenantId();
  const tenantCompanyId = useTenantCompanyId();
  const snackbar = useSnackbar();
  const commonService = new CommonService();
  const companyService = new CompanyService();
  const { bypassGatedWorkflows } = useFlags();

  document.title = 'BuildOps - Workflows';

  const [data] = useCompanySettings(
    COMPANY_SETTING_TYPE.CUSTOMER_SIGNATURE,
    tenantId,
    tenantCompanyId,
    snackbar
  );

  const [stepMap, setStepMap] = useState(null);
  const [gatedWorkflow, setGatedWorkflow] = useState(null);
  const [customerSignatureSettingsSteps, setCustomerSignatureSettingsSteps] = useState([]);
  const [customerSignatureSettingsStepMap, setCustomerSignatureSettingsStepMap] = useState({});

  useEffect(() => {
    reloadGatedWorkflows();
  }, []);

  useEffect(() => {
    setInitialState(data);
  }, [data]);

  const setInitialState = data => {
    const customerSignatureSettings = data?.settings ? JSON.parse(data.settings) : null;

    const { signatureSettingsSteps, signatureSettingsStepMap } = customerSignatureSettings
      ? generateCustomerSignatureSettings(mapDBSettingsToConfig(customerSignatureSettings), user)
      : generateCustomerSignatureSettings(settingsConfig, user);

    setCustomerSignatureSettingsSteps(signatureSettingsSteps);
    setCustomerSignatureSettingsStepMap(signatureSettingsStepMap);
  };

  const reloadGatedWorkflows = async () => {
    try {
      const sortKey = `${user.tenantId}_Company_${user.tenantCompanyId}`;
      const queryParams = { partitionKey: user.tenantId, sortKey };
      const { data } = await commonService.getQuery(queryParams, getWorkflowStepsForCompany);

      const stepMap = {};
      if (data && data.getCompany) {
        data.getCompany.workflows.items[0].workflowSteps.items.sort((a, b) =>
          a.workflowStepName > b.workflowStepName ? 1 : -1
        );
        data.getCompany.workflows.items[0].workflowSteps.items.forEach(
          step => (stepMap[step.id] = step)
        );
        // TODO amarto right now this requires ensuring the order by Name - add an 'order' key on the obj
        data.getCompany.workflows.items[0].workflowSteps.items.forEach(step => {
          if (step.isRequired == null) {
            step.isRequired = false;
          }
        });
        setGatedWorkflow(data.getCompany.workflows.items[0]);
        setStepMap(stepMap);
      }
    } catch (error) {
      Logger.error(error);
    }
  };

  const handleOnChange = event => {
    const {
      target: { name, checked }
    } = event;

    const updatedStepMap = { ...stepMap };
    const updatedGatedWorkflow = { ...gatedWorkflow };
    updatedStepMap[name].isRequired = checked;
    setGatedWorkflow(updatedGatedWorkflow);
    setStepMap(updatedStepMap);
  };

  const handleSettingsOnChange = event => {
    const {
      target: { name, checked }
    } = event;
    const updatedCustomerSignatureSettingsStepMap = { ...customerSignatureSettingsStepMap };

    updatedCustomerSignatureSettingsStepMap[name].enabled = checked;

    const { child } = updatedCustomerSignatureSettingsStepMap[name];
    if (!checked && child && updatedCustomerSignatureSettingsStepMap[child]) {
      updatedCustomerSignatureSettingsStepMap[child].enabled = checked;
    }

    setCustomerSignatureSettingsStepMap(updatedCustomerSignatureSettingsStepMap);
  };

  const handleSubstepOnChange = (event, stepId, index) => {
    const {
      target: { checked }
    } = event;

    const updatedStepMap = { ...stepMap };
    updatedStepMap[stepId].workflowSubSteps[index] = {
      ...updatedStepMap[stepId]?.workflowSubSteps[index],
      enabled: checked
    };

    setStepMap(updatedStepMap);
  };

  const handleSave = async () => {
    try {
      const saveWorkflowResult = await saveWorkflowSteps();
      const saveSettingsResult = await saveCustomerSignatureSettings();

      if (saveWorkflowResult && saveSettingsResult) {
        snackbarOn('success', 'Successfully saved settings');
      } else {
        const errorMessage = `Unable to save ${
          !saveWorkflowResult ? 'workflow' : 'customer signature'
        } settings, please try again later`;
        snackbarOn('error', errorMessage);
      }
    } catch (err) {
      Logger.error(err);
      snackbarOn('error', 'Unable to save workflow settings, please try again later', err);
    }
  };

  const saveWorkflowSteps = async () => {
    // TODO: batch mutation?
    Object.keys(stepMap).forEach(async key => {
      const substeps = stepMap[key].workflowSubSteps?.map(({ key, label, enabled }) => {
        return { key, label, enabled };
      });

      const params = {
        stepId: stepMap[key].id,
        isRequired: stepMap[key].isRequired,
        version: stepMap[key].version,
        workflowSubSteps: substeps
      };

      const resp = await commonService.runMutation(params, updateWorkflowSteps);
      stepMap[key].version = resp.data.updateWorkflowStep.version;
    });

    return true;
  };

  const saveCustomerSignatureSettings = async () => {
    const { tenantCompanyId } = user;
    const customerSignatureScreenSettings = {};
    customerSignatureSettingsSteps.forEach(setting => {
      customerSignatureScreenSettings[setting.key] = setting.enabled;
    });
    const payload = {
      companyId: tenantCompanyId,
      customerSignatureScreenSettings
    };

    const { data } = await companyService.addCustomerSignatureSettingsToCompany(payload);
    const id = data?.upsertCustomerSignatureSettingsToCompany?.[0]?.id;
    if (id) {
      return true;
    }
    return false;
  };

  const renderInlineSubSteps = step => {
    if (!step.isRequired || !Array.isArray(step?.workflowSubSteps)) return;

    const subSteps = step.workflowSubSteps.filter(
      step => bypassGatedWorkflows || step.label !== 'Allow bypass'
    );

    return (
      <>
        {subSteps.map((subStep, i) => (
          <FormControlLabel
            control={
              <Switch
                checked={subStep.enabled}
                onChange={e => handleSubstepOnChange(e, step.id, i)}
              />
            }
            css={[tableCellStyle, { marginRight: 0 }]}
            key={`${step.id}-sub-step-${i}`}
            label={subStep.label}
          />
        ))}
      </>
    );
  };

  let steps = [];
  if (gatedWorkflow) {
    steps = gatedWorkflow.workflowSteps.items;
    if (user?.username && !user?.tenantCompanyId) {
      snackbarOn(
        'error',
        'No company is associated with you. \nPlease sign in as employee or tenant admin'
      );
      return <> </>;
    }
  }

  return (
    <ErrorBoundaries>
      <PageHeader pageMapKey="mobileSettings" userLocale={locale} />
      {gatedWorkflow && (
        <UserPermission action={PermissionConstants.OBJECT_WORKFLOWS} I="update">
          <Grid container direction="row" spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <SectionHeader
                buttons={[
                  {
                    action: handleSave,
                    label: labels.saveButtonLabel[locale],
                    color: 'primary'
                  }
                ]}
                title={
                  <Typography
                    color={theme.palette.text.primary}
                    style={{ ...theme.typography.h4 }}
                    variant={TV.L}
                    weight={TW.BOLD}
                  >
                    {labels.gatedWorkflowsSectionTitle[locale]}
                  </Typography>
                }
              />
              <Typography color={theme.palette.text.primary} variant={TV.BASE} weight={TW.BOLD}>
                {gatedWorkflow.workflowCopy}
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            {/* replace with responsive form */}
            <Table>
              <TableBody>
                {steps.map((step, index) => (
                  <TableRow css={tableRowStyle} key={`datarow${step.id}`}>
                    <TableCell css={[tableCellStyle, { width: 400 }]} key={`dataCell${step.id}`}>
                      {step.workflowStepCopy}
                    </TableCell>
                    <TableCell css={tableCellTogglesStyle}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={step.isRequired}
                            name={step.id}
                            onChange={e => handleOnChange(e)}
                          />
                        }
                        label="Required"
                        style={{ marginRight: 0 }}
                      />
                      <div style={{ minWidth: 105, marginLeft: 60 }}>
                        {renderInlineSubSteps(step)}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <br />
          <br />
          <br />
        </UserPermission>
      )}
      <Grid container direction="row" spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <SectionHeader
            title={
              <Typography
                color={theme.palette.text.primary}
                style={{ ...theme.typography.h4 }}
                variant={TV.L}
                weight={TW.BOLD}
              >
                {labels.customerSignatureSettingsSectionTitle[locale]}
              </Typography>
            }
          />
          <Typography color={theme.palette.text.primary} variant={TV.BASE} weight={TW.BOLD}>
            {labels.customerSignatureSettingsSectionSubTitle[locale]}
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        {/* replace with responsive form */}
        <Table>
          <TableBody>
            {customerSignatureSettingsSteps?.length > 0 &&
              customerSignatureSettingsSteps.map(setting => (
                <TableRow
                  css={tableRowStyle}
                  key={`datarow${setting.key}`}
                  style={{ height: 'auto', borderBottom: 'none' }}
                >
                  <TableCell
                    css={{ ...tableCellStyle, width: 400 }}
                    key={`dataCell${setting.key}`}
                    style={{
                      ...{ height: 'auto !important' },
                      ...(setting?.parent ? subSettingStyle : {})
                    }}
                  >
                    {setting.label}
                  </TableCell>
                  <TableCell css={tableCellTogglesStyle}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={setting.enabled}
                          disabled={
                            setting.parent &&
                            !customerSignatureSettingsStepMap[setting.parent].enabled
                          }
                          name={setting.key}
                          onChange={e => handleSettingsOnChange(e)}
                        />
                      }
                      label="Display"
                      style={{ marginRight: 0 }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Grid>
    </ErrorBoundaries>
  );
};

const mapStateToProps = state => ({
  user: state.user
});
const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});
const reduxConnectedWorkflows = connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(Workflow));

export default reduxConnectedWorkflows;
