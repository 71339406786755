import { MUIFormComponentNames } from '@BuildHero/sergeant';

import { sortBy } from 'lodash';

import { generateDefaultFieldsObject } from '@pm/components/formattingUtils';
import { col, row } from 'utils/layout';
import { findById } from 'utils/ramda';

const defaultFlexStyle = '0 1 auto';
const defaultItemFlexStyle = '0 0 150px';
const wideItemFlexStyle = '0 0 423px';
const margin = '0px 15px 21px 0px';

const inputOptions = {
  marginRight: 16,
  height: 'auto'
};

// Equipment Information
const fields = {
  description: { required: true },
  quantity: { required: false },
  ProjectCostCode: { required: false },
  sellPrice: { required: false },
  notes: { required: false },
  phaseId: { required: true },
  departmentId: { required: true },
  taxable: { required: false }
};

// Percent Information
const additionalPercentFields = {
  taxPercent: { required: false },
  overheadPercent: { required: false },
  profitPercent: { required: false }
};

const contents = (projectId, phaseId, depId, projectPhases, isHours = false, onChange) => {
  const phaseDepartments = findById(phaseId)(projectPhases)?.ProjectPhaseDepartment || [];
  const costCodes = findById(depId)(phaseDepartments)?.ProjectPhaseDepartmentCostCode || [];

  const phaseOptions =
    sortBy(
      projectPhases?.map(phase => ({ value: phase?.id, label: phase?.name })),
      ['label']
    ) || [];

  const departmentOptions =
    sortBy(
      phaseDepartments?.map(department => {
        return {
          value: department?.id,
          label: `${department?.tagName}`
        };
      }),
      ['label']
    ) || [];

  const costCodeOptions =
    sortBy(
      costCodes?.map(costCode => ({
        value: costCode?.id,
        label: costCode?.description
          ? `${costCode?.name} - ${costCode?.description}`
          : costCode?.name
      })),
      ['label']
    ) || [];

  return [
    // the 1st row
    {
      options: {
        size: 2,
        direction: 'row',
        grow: 1
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: wideItemFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                default: 'TextInput',
                edit: 'TextInput'
              },
              source: 'description',
              options: {
                label: 'Description',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: true
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                default: 'TextInput',
                edit: 'TextInput'
              },
              source: 'quantity',
              options: {
                label: isHours ? 'Hours' : 'Quantity',
                isRequired: false,
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                type: 'number',
                min: 0
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                default: 'CurrencyInput',
                edit: 'CurrencyInput'
              },
              source: 'cost',
              options: {
                label: 'Cost',
                isRequired: false,
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                min: 0,
                onChange
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                default: 'CurrencyInput',
                edit: 'CurrencyInput'
              },
              source: 'sellPrice',
              options: {
                label: 'Unit Price',
                isRequired: false,
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                min: 0
              }
            }
          ]
        }
      ]
    },

    // the 2nd row
    {
      options: {
        size: 1,
        direction: 'row',
        grow: 1,
        flexWrap: 'wrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: wideItemFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                default: 'TextInput',
                edit: 'TextInput'
              },
              source: 'notes',
              options: {
                label: 'Notes',
                isRequired: false,
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary'
              }
            }
          ]
        },
        {
          options: {
            direction: 'row',
            flex: defaultFlexStyle,
            alignItems: 'flex-end',
            margin
          },
          contents: [
            {
              component: {
                default: 'CheckboxInput',
                edit: 'CheckboxInput'
              },
              source: 'taxable',
              options: {
                label: 'Taxable'
              }
            }
          ]
        }
      ]
    },
    // third row
    {
      options: {
        size: 1,
        direction: 'row',
        grow: 1,
        flexWrap: 'wrap'
      },
      contents: [
        row(
          [
            col(
              [
                {
                  options: {
                    label: 'Phase',
                    valueSet: phaseOptions,
                    isRequired: true,
                    placeholder: 'Select Phase'
                  },
                  component: MUIFormComponentNames.SelectInput,
                  source: 'phaseId'
                }
              ],
              { ...inputOptions }
            ),
            col(
              [
                {
                  options: {
                    label: 'Department',
                    valueSet: departmentOptions,
                    isRequired: true,
                    disabled: !phaseId,
                    placeholder: 'Select Department'
                  },
                  component: MUIFormComponentNames.SelectInput,
                  source: 'departmentId'
                }
              ],
              inputOptions
            ),
            col(
              [
                {
                  options: {
                    label: 'Cost Code',
                    valueSet: costCodeOptions,
                    disabled: !depId,
                    placeholder: 'Select Cost Code'
                  },
                  component: MUIFormComponentNames.SelectInput,
                  source: 'costCodeId'
                }
              ],
              inputOptions
            ),
            col(
              [
                {
                  component: 'deleteButton'
                }
              ],
              {
                ...inputOptions,
                width: 'auto',
                marginRight: 0,
                marginTop: 17.5,
                grow: 0,
                direction: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start'
              }
            )
          ],
          { marginBottom: 16 }
        )
      ]
    }
  ];
};

const additionalPercentContents = [
  // the 1st row
  {
    options: {
      size: 2,
      direction: 'row',
      grow: 1
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultItemFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'TextInput',
              edit: 'TextInput'
            },
            source: 'taxPercent',
            options: {
              label: 'Tax Percent',
              isRequired: false,
              alignment: 'left',
              color: 'secondary',
              type: 'percentage',
              min: 0
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultItemFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'TextInput',
              edit: 'TextInput'
            },
            source: 'overheadPercent',
            options: {
              label: 'Overhead Percent',
              isRequired: false,
              alignment: 'left',
              color: 'secondary',
              type: 'percentage',
              min: 0
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultItemFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'TextInput',
              edit: 'TextInput'
            },
            source: 'profitPercent',
            options: {
              label: 'Profit Percent',
              isRequired: false,
              alignment: 'left',
              color: 'secondary',
              type: 'percentage',
              min: 0
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'CustomFieldWithLabel',
              edit: 'CustomFieldWithLabel'
            },
            source: 'costSubtotal',
            options: {
              label: 'Cost Subtotal',
              isRequired: true,
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              type: 'currency'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'CustomFieldWithLabel',
              edit: 'CustomFieldWithLabel'
            },
            source: 'revenueSubtotal',
            options: {
              label: 'Revenue Subtotal',
              isRequired: true,
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              type: 'currency'
            }
          }
        ]
      }
    ]
  }
];

const layout = (projectId, phaseId, depId, projectPhases, isHours, onChange) => {
  return {
    fields: {
      ...generateDefaultFieldsObject(fields)
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [...contents(projectId, phaseId, depId, projectPhases, isHours, onChange)]
      },
      edit: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [...contents(projectId, phaseId, depId, projectPhases, isHours, onChange)]
      }
    }
  };
};

const additionalPercentlayout = () => {
  return {
    fields: {
      ...generateDefaultFieldsObject(additionalPercentFields)
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [...additionalPercentContents]
      },
      edit: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [...additionalPercentContents]
      }
    }
  };
};

export { fields, layout, additionalPercentFields, additionalPercentlayout };
