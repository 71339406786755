import React, { useState } from 'react';

import { MUIForm } from '@BuildHero/sergeant';

import { timeTrackingSetting } from 'meta/Settings/Payroll/TimetrackingSettingMeta';
import { Mode } from 'utils/constants';

import { useAddPayrollSettings, useUpdatePayrollSettings } from '../../mutations';

import SaveButton from './SaveButton';

const GeneralSettings = ({
  isAllowedToEdit,
  payrollSettings,
  setFormChanged,
  user,
  isDirty,
  formEditLayout
}) => {
  const [settingService, setSettingService] = useState();
  const [addPayrollSettings, { loading: addingPayrollSettings }] = useAddPayrollSettings();
  const [updatePayrollSettings, { loading: updatingPayrollSettings }] = useUpdatePayrollSettings();

  return (
    <>
      <MUIForm
        configuration={timeTrackingSetting(isAllowedToEdit, true)}
        data={payrollSettings}
        layout={isAllowedToEdit ? formEditLayout : Mode.VIEW}
        onComplete={async completedData => {
          const settingsJsonPayload = {
            ...payrollSettings,
            ...completedData
          };
          if (completedData?.id) {
            updatePayrollSettings({
              partitionKey: user?.tenantId,
              formData: settingsJsonPayload
            });
          } else {
            addPayrollSettings({
              partitionKey: user?.tenantId,
              companyId: user?.tenantCompanyId,
              formData: settingsJsonPayload
            });
          }
        }}
        onCreateService={formService => {
          setSettingService(formService);
        }}
        onDirtyChange={dirty => setFormChanged(dirty)}
      />
      <SaveButton
        disabled={!isDirty}
        loading={addingPayrollSettings || updatingPayrollSettings}
        onClick={() => settingService?.submit()}
      />
    </>
  );
};

export default GeneralSettings;
