import React from 'react';

import { Text, View } from '@react-pdf/renderer';

import styles from '../style';

const ContractApplicationRightside = () => {
  return (
    <View style={styles.contractApplicationRightside}>
      <Text style={styles.smallText}>
        The undersigned Contractor certifies that to the best of the Contractor's knowledge,
      </Text>
      <Text style={styles.smallText}>
        information and belief the Work covered by this Application for Payment has been
      </Text>
      <Text style={styles.smallText}>
        completed in accordance with the Contract Documents, that all amounts have been paid by
      </Text>
      <Text style={styles.smallText}>
        the Contractor for Work for which previous Certificates for Payment were issued and
      </Text>
      <Text style={styles.smallText}>
        payments received from the Owner, and that current payment shown herein is now due.
      </Text>
      <View style={[styles.displayFlex, { marginTop: 30 }]}>
        <Text style={styles.text}>By:</Text>
        <Text style={[styles.underline, { marginRight: 20, width: 170 }]} />
        <Text style={styles.text}>Date:</Text>
        <Text style={styles.underline} />
      </View>
      <View style={[styles.displayFlex, { marginTop: 40 }]}>
        <Text style={[styles.text, { marginRight: 150 }]}>State of:</Text>
        <Text style={styles.text}>County of:</Text>
      </View>
      <View style={[styles.displayFlex, { marginTop: 30 }]}>
        <Text style={[styles.text, { marginRight: 30 }]}>
          Subscribed and sworn to before me this day
        </Text>
        <Text style={[styles.text, { marginRight: 30 }]}>this month</Text>
        <Text style={styles.text}>this year</Text>
      </View>
      <Text style={[styles.text, { marginTop: 10 }]}>Notary Public:</Text>
      <Text style={[styles.text, { marginTop: 10, marginBottom: 10 }]}>My Commission expires:</Text>
    </View>
  );
};

export default ContractApplicationRightside;
