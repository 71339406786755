import { number, object, string } from 'yup';

import { AccountingApp } from 'utils/constants';

export default accountingApp => {
  return object({
    name:
      accountingApp === AccountingApp.INTACCT
        ? undefined
        : string()
            .nullable()
            .required(),
    ledgerAccount:
      accountingApp !== AccountingApp.INTACCT
        ? undefined
        : object({
            value: string().nullable()
          })
            .nullable()
            .required('GL account is required.'),
    department: object({
      value: string().nullable()
    })
      .nullable()
      .required('Department is required.'),
    quantity: number()
      .nullable()
      .moreThan(0, 'Quantity must be greater than 0.')
      .required('Quantity is required.'),
    unitPrice: number()
      .nullable()
      .required('Unit price is required.'),
    amount: number()
      .nullable()
      .required('Amount is required.')
  });
};
