import React from 'react';

import { Button, TextField, withStyles } from '@material-ui/core';
import classNames from 'classnames';

import { connect } from 'react-redux';

import Modal from 'components/Modal';
import labels from 'meta/Scheduler/labels';

import { snackbarOn } from 'redux/actions/globalActions';

import styles from './styles';

class OnHold extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reasonValue: ''
    };
  }

  handleOnChange = event => {
    this.setState({ reasonValue: event.target.value });
  };

  updateParent = async () => {
    await this.props.save(this.state.reasonValue);
    this.props.handleClose();
  };

  render() {
    const { classes, locale, open, handleClose } = this.props;
    return (
      <Modal handleClose={handleClose} height="400" open={open} width="800">
        <div className={classes.root}>
          <div className={classNames(classes.title)}>
            <span className={classes.icon}>{labels.onHoldVisit_title[locale]}</span>
          </div>
          <div style={{ marginLeft: '-10px' }}>
            <TextField
              className={classes.textField}
              fullWidth
              id="filled-full-width"
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                classes: {
                  root: classes.inputRoot
                }
              }}
              margin="normal"
              multiline
              placeholder="Why is this visit on hold?"
              rows="3"
              style={{ margin: 8 }}
              variant="filled"
              onChange={this.handleOnChange}
            />
          </div>
          <div
            style={{
              padding: '20px 0',
              height: 'auto',
              display: 'block',
              width: '100%'
            }}
          >
            <Button
              className={classes.pageActionButton}
              color="primary"
              variant="contained"
              onClick={() => {
                const { reasonValue } = this.state;
                if (!reasonValue) {
                  return this.props.snackbarOn('error', 'Reason is required');
                }
                return this.updateParent();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

const styledEmailPopUp = withStyles(styles)(OnHold);

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message, error) => dispatch(snackbarOn(mode, message, error))
});

const connectedAllNotes = connect(mapStateToProps, mapDispatchToProps)(styledEmailPopUp);

export default connectedAllNotes;
