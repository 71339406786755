import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import useEmployees from 'customHooks/useEmployees';
import usePricebooks from 'scenes/Jobs/JobDetail/queries/usePricebooks';

export const useCompanyEmployees = () => {
  const [data, loading] = useEmployees({
    includeBillingRate: true,
    includePayrollCost: true,
    includeDepartments: true
  });

  const dataMap = useMemo(
    () =>
      data?.reduce(
        (result, item) => ({
          ...result,
          [item?.id]: item
        }),
        {}
      ) || {},
    [data]
  );
  return { data, dataMap, loading };
};

export const useReportPriceBooks = () => {
  const { tenantId, tenantCompanyId } = useSelector(state => state?.user) || {};
  return usePricebooks(tenantId, tenantCompanyId);
};
