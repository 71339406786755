import React from 'react';

import { TH, TV, TW, Typography } from '@BuildHero/sergeant';

import InvoiceHeaderPreview from './InvoiceHeaderPreview.component';

const InvoiceHeaderSetting = () => {
  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      <>
        <Typography css={{ marginBottom: 7 }} variant={TV.L} weight={TW.BOLD}>
          Invoice Header
        </Typography>
        <Typography css={{ marginBottom: 34 }} height={TH.COMFORTABLE}>
          Applies to single window envelopes only. Click on the logo in the preview to change the
          logo.
          <br />
          <br />
          For best results, the invoice header should be <b>1128px wide x 176px tall.</b>
          <br />
          File format: gif, jpg, or png
        </Typography>
        <InvoiceHeaderPreview />
      </>
    </div>
  );
};

export default InvoiceHeaderSetting;
