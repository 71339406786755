import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import useTenantCompanyId from 'customHooks/useTenantCompanyId';
import useTenantId from 'customHooks/useTenantId';
import { handleError } from 'utils/handleError';

import { defaultDispatchSettings } from './DispatchSettings.constants';

export const GetCompanyDispatchSettings = gql`
  query($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      companySettings(
        entityConnection: "CompanySetting"
        filter: { type: { eq: "DISPATCH" }, isActive: { eq: true } }
      ) {
        items {
          id
          version
          settings
        }
      }
    }
  }
`;

export const useGetCompanyDispatchSettings = options => {
  const tenantId = useTenantId();
  const tenantCompanyId = useTenantCompanyId();

  return useExtendedQuery(GetCompanyDispatchSettings, {
    variables: { partitionKey: tenantId, sortKey: `${tenantId}_Company_${tenantCompanyId}` },
    transform: data => {
      try {
        const rawSettings = data.getCompany.companySettings.items[0]?.settings;
        if (!rawSettings) return {};
        const dispatchSettings = JSON.parse(data.getCompany.companySettings.items[0]?.settings);

        // TODO rename dispatch setting to weekViewStartDay
        if (dispatchSettings?.weekViewStartDate && !dispatchSettings?.weekViewStartDay) {
          dispatchSettings.weekViewStartDay = dispatchSettings.weekViewStartDate;
        }

        return dispatchSettings;
      } catch (err) {
        handleError({ err, hideError: true });
        return {};
      }
    },
    defaultData: defaultDispatchSettings,
    skip: !tenantId || !tenantCompanyId,
    ...options
  });
};
