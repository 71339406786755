import React from 'react';

import { ThemeProvider } from '@BuildHero/sergeant';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { getContentText } from 'scenes/ProjectManagement/components/utils';

const useStyles = makeStyles(theme => ({
  label: {
    color: theme.palette.grayscale(60),
    fontSize: 10,
    letterSpacing: 0.01,
    fontWeight: 'normal',
    textTransform: 'uppercase',
    lineHeight: '14px',
    marginBottom: '0.35em'
  },
  content: {
    fontFamily: theme.typography.fontFamily,
    color: '#333333',
    fontSize: 14,
    letterSpacing: '-0.03em',
    fontWeight: 'normal',
    lineHeight: '41px',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'zero' on, 'salt' on, 'ss01' on"
  },
  linkText: {
    color: theme.palette.grayscale(20)
  },
  chipContainer: {
    display: 'flex'
  },
  chipItem: {
    height: 24,
    background: '#2DCD88',
    borderRadius: '2px',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    padding: '3px 8px',
    marginRight: 8,
    whiteSpace: 'nowrap'
  }
}));

const ShowValueWithOption = ({ form, option, input, wrapType, style }) => {
  const classes = useStyles();
  const hasPath = option.linkPath || (option.linkPath === '' && option.linkReference);

  if (option.isDate) {
    return (
      <Typography className={classes.content} noWrap={wrapType} style={style}>
        {getContentText('date', input.value)}
      </Typography>
    );
  }

  if (option.isUtcDate) {
    return (
      <Typography className={classes.content} noWrap={wrapType} style={style}>
        {getContentText('utcDate', input.value)}
      </Typography>
    );
  }

  if (hasPath && input.value) {
    return (
      <Link
        className={classes.linkText}
        to={`${option.linkPath}/${get(form?.values, option.linkReference) ?? input.value}`}
      >
        <Typography className={classes.content} noWrap={wrapType} style={style}>
          {input.value}
        </Typography>
      </Link>
    );
  }

  if (option.showChip && Array.isArray(input.value)) {
    return (
      <div className={classes.chipContainer}>
        {input.value.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={classes.chipItem} key={`customFWL${input.value}-${index}`}>
            {item}
          </div>
        ))}
      </div>
    );
  }

  if (option.type === 'currency' && input) {
    return (
      <Typography className={classes.content} noWrap={wrapType} style={style}>
        {getContentText('currency', input.value || 0, option.decimalPlaces || 2)}
      </Typography>
    );
  }

  if (option.type === 'percentage' && input) {
    return (
      <Typography className={classes.content} noWrap={wrapType} style={style}>
        {getContentText('percentage', input.value || 0)}
      </Typography>
    );
  }

  return (
    <Typography className={classes.content} noWrap={wrapType} style={style}>
      {(input && input.value) || '-'}
    </Typography>
  );
};

const CustomFieldWithLabel = ({ form, field, options, style, nowrap }) => {
  const classes = useStyles();

  return (
    <ThemeProvider>
      <Typography className={classes.label} variant="caption">
        {options.label}
      </Typography>
      <ShowValueWithOption
        form={form}
        input={field}
        option={options}
        style={style}
        wrapType={nowrap}
      />
    </ThemeProvider>
  );
};

ShowValueWithOption.propTypes = {
  option: PropTypes.instanceOf(Object).isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  style: PropTypes.instanceOf(Object).isRequired,
  wrapType: PropTypes.bool.isRequired
};

CustomFieldWithLabel.propTypes = {
  form: PropTypes.instanceOf(Object).isRequired,
  field: PropTypes.instanceOf(Object).isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  style: PropTypes.instanceOf(Object),
  nowrap: PropTypes.bool
};

CustomFieldWithLabel.defaultProps = {
  style: {},
  nowrap: true
};

export default CustomFieldWithLabel;
