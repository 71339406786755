import React, { useState } from 'react';

import { Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import { ChipArray, PageHeader, UserPermission, XGrid } from 'components';
import CreateEntryButton from 'components/Buttons/CreateEntryButton';
import { setOpenQuickAddModal } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';

import { BuildHeroThemeProvider } from 'themes/BuildHeroTheme';
import { PermissionConstants } from 'utils/AppConstants';
import { QuickAddModal } from 'utils/constants';

import quoteListColumns from './meta/quoteListColumns';
import GET_QUOTES from './queries/getQuoteList';

const useStyles = makeStyles(() => ({
  divider: {
    margin: '8px -24px 18px -24px'
  },
  quickFilters: {
    marginBottom: '28px'
  }
}));

const filterConstants = {
  ALL: 'All',
  DRAFT: 'Draft',
  SENT_TO_CUSTOMER: 'Sent to customer',
  APPROVED: 'Approved',
  MY_QUOTES: 'My quotes'
};

const filterMap = {
  Draft: 'Draft',
  Approved: 'Approved',
  'Sent to customer': 'SentToCustomer'
};

const QuoteList = props => {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const [selectedFilterValue, setSelectedFilterValue] = useState(filterConstants.ALL);

  const pageHeaderButtons = (
    <CreateEntryButton
      caslKey={PermissionConstants.OBJECT_QUOTES}
      handleAdd={() => props.setOpenQuickAddModal(QuickAddModal.CREATE_QUOTE)}
      key="createQuote"
      label="New Quote"
    />
  );

  const quickFilters = ({ setFilterModel, filterModel }) => {
    const handleQuickFilterSelect = filterValue => {
      let newFilterModel = {
        items: []
      };
      if (filterValue === filterConstants.MY_QUOTES) {
        newFilterModel = {
          linkOperator: 'and',
          items: [
            ...filterModel.items?.filter(item => item.id !== 1),
            {
              columnField: 'createdBy',
              operatorValue: 'contains',
              value: user.displayName,
              id: 1
            }
          ]
        };
      } else if (
        filterValue === filterConstants.DRAFT ||
        filterValue === filterConstants.SENT_TO_CUSTOMER ||
        filterValue === filterConstants.APPROVED
      ) {
        newFilterModel = {
          linkOperator: 'and',
          items: [
            ...filterModel.items?.filter(item => item.id !== 1),
            {
              columnField: 'status',
              operatorValue: 'contains',
              value: filterMap[filterValue],
              id: 1
            }
          ]
        };
      }
      setFilterModel(newFilterModel);
      setSelectedFilterValue(filterValue);
    };

    return (
      <Grid container>
        <Grid className={classes.quickFilters} item lg={12} md={12} sm={12} xs={12}>
          <BuildHeroThemeProvider>
            <ChipArray
              dataArr={Object.values(filterConstants)}
              handleClick={handleQuickFilterSelect}
              selectedValue={selectedFilterValue}
            />
          </BuildHeroThemeProvider>
        </Grid>
      </Grid>
    );
  };

  return (
    <ErrorBoundaries>
      <UserPermission action={PermissionConstants.OBJECT_QUOTES} I="read">
        <PageHeader
          overrideHeaderButtons={pageHeaderButtons}
          pageMapKey="quotes"
          userLocale={user.locale}
        />
        <Divider className={classes.divider} />
        <XGrid
          columns={quoteListColumns}
          customComponent={quickFilters}
          enableExport
          entityTypeName="Quote"
          query={GET_QUOTES}
          rowHeight={38}
          tableName="QuoteXGrid"
        />
      </UserPermission>
    </ErrorBoundaries>
  );
};

QuoteList.propTypes = {
  setOpenQuickAddModal: PropTypes.func.isRequired
};

export default connect(null, { setOpenQuickAddModal })(QuoteList);
