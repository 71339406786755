import React from 'react';

import { Divider, MUIForm } from '@BuildHero/sergeant';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  DefaultButton,
  PageHeader,
  StatusChip,
  SyncStatus as SyncStatusDisplay,
  UserPermission,
  withLoading
} from 'components';
import Labels from 'meta/labels';
import ErrorBoundaries from 'scenes/Error';
import { MAX_FORMS_WIDTH } from 'themes/BuildHeroTheme';
import { PermissionConstants } from 'utils/AppConstants';
import { EnumType, SyncStatus } from 'utils/constants';
import { excelFileDownload } from 'utils/fileDownload';

import { useExportWIPReport } from '../mutations/useExportWIPReport';
import { useSyncWIPReport } from '../mutations/useSyncWIPReport';
import { useGetWIPReport } from '../queries/useGetWIPReport';

import WIPDetailFormConfig from './WIPDetail.formConfig';

const MuiFormWithLoading = withLoading(MUIForm);

const WIPDetail = () => {
  const { id: wipReportId } = useParams();
  const user = useSelector(state => state.user);
  const wipReportResponse = useGetWIPReport(wipReportId);
  const wipData = wipReportResponse.data;

  const [exportWipReport, exportWipResponse] = useExportWIPReport({
    onCompleted: data => {
      excelFileDownload({
        fileName: `WIPReport${wipData.recordNumber}`,
        base64Data: data?.exportWIPReport?.base64Data
      });
    }
  });

  const [syncWipReport, syncWipReportResponse] = useSyncWIPReport({
    onSuccess: (data, snackbarOn) => {
      snackbarOn('success', `Successfully exported WIP entry WIP Report ${data.recordNumber}`);
    }
  });

  const { status, syncStatus, syncLog, recordNumber } = wipData;
  const title = `${Labels.wipReport[user.locale]} ${recordNumber ?? '-'}`;

  const handleDownload = () => {
    exportWipReport(wipReportId);
  };

  const exportWIPReport = async () => {
    syncWipReport(wipReportId);
  };

  const setPageHeaderButtons = () => {
    const containerStyle = {
      width: 'auto',
      flexGrow: 1,
      justifyContent: 'flex-end',
      alignItems: 'center'
    };
    const spacing = { marginLeft: 8 };

    return (
      <Grid container style={containerStyle}>
        <Grid item spacing={3}>
          <DefaultButton
            disabled={wipReportResponse.loading || exportWipResponse.loading}
            handle={handleDownload}
            label="DOWNLOAD EXCEL REPORT"
            style={spacing}
            variant="outlined"
          />
        </Grid>
        <Grid item spacing={3}>
          <DefaultButton
            color="primary"
            disabled={syncStatus === SyncStatus.SYNCING || syncWipReportResponse.loading}
            handle={exportWIPReport}
            label="EXPORT WIP ENTRY"
            style={spacing}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <ErrorBoundaries>
      <UserPermission action={PermissionConstants.MENU_PROJECT_MANAGEMENT} I="allow">
        <div style={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <PageHeader
                additionalTitleComponents={
                  <StatusChip
                    enumType={EnumType.WIP_REPORT_STATUS}
                    enumValue={status}
                    label={status}
                  />
                }
                breadcrumbsArray={[
                  { title: 'Projects', link: '' },
                  { title: Labels.wipReports[user.locale], link: '/wipReports/list' }
                ]}
                pageMapKey="wipReports"
                title={title}
                userLocale={user.locale}
              >
                {setPageHeaderButtons()}
              </PageHeader>
              <Divider />
            </Grid>
            <Grid item style={{ maxWidth: MAX_FORMS_WIDTH }} xs={12}>
              <SyncStatusDisplay
                locale={user.locale}
                syncLog={syncLog}
                syncStatus={syncStatus ?? undefined}
              />
              <MuiFormWithLoading
                configuration={WIPDetailFormConfig}
                data={wipData}
                isLoading={wipReportResponse.loading}
              />
            </Grid>
          </Grid>
        </div>
      </UserPermission>
    </ErrorBoundaries>
  );
};

export default WIPDetail;
