import gql from 'graphql-tag';

import taskFragment from '../queries/taskFragment';

const addJobTasksToJob = gql`
  mutation addJobTasksToJobMutation($partitionKey: String!, $data: AddJobTasksToJobInput!) {
    addJobTasksToJob(partitionKey: $partitionKey, data: $data) {
      id
      taskId
      task {
        ...taskFields
      }
    }
  }
  ${taskFragment}
`;

export default addJobTasksToJob;
