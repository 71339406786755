import { Auth } from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';

import confgureForEnvironment from './aws-exports';
import ENV from './env';

const config = confgureForEnvironment(ENV);

export const systemAdminClient = new AWSAppSyncClient({
  url: config.appsync.aws_appsync_graphqlEndpoint,
  region: config.appsync.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
  },
  disableOffline: true,
  cacheOptions: {
    addTypename: false
  }
});

export const tenantCompanyClient = new AWSAppSyncClient({
  url: config.appsync.aws_appsync_graphqlEndpoint,
  region: config.appsync.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
  },
  disableOffline: true
  // cacheOptions: {
  //   addTypename: false
  // }
});
