import React, { useEffect, useMemo, useState } from 'react';

import { Box } from '@material-ui/core';

import { connect } from 'react-redux';

import { snackbarOn } from 'redux/actions/globalActions';

import JobDocumentAttachments from './components/JobDocumentAttachments';
import JobFormAttachments from './components/JobFormAttachments';

import JobReportAttachment from './components/JobReportAttachments';
import { useGetJobAttachments } from './SelectAttachments.queries';

const SelectAttachment = props => {
  const { snackbar, dispatchFilesData, filesData, jobId, dispatchButtonState } = props;
  const { data: jobData, refetch, loading } = useGetJobAttachments(jobId);
  const { jobDocuments, jobForms } = jobData;

  return (
    <div>
      {/* Job Reports */}
      <JobReportAttachment
        dispatchButtonState={dispatchButtonState}
        dispatchFilesData={dispatchFilesData}
        filesData={filesData}
        jobData={jobData}
        snackbar={snackbar}
      />
      <Box display="flex" marginTop={2}>
        {/* Job Documents */}
        {jobDocuments.length > 0 && (
          <Box width="50%">
            <JobDocumentAttachments
              dispatchButtonState={dispatchButtonState}
              dispatchFilesData={dispatchFilesData}
              documents={jobDocuments}
              filesData={filesData}
              jobData={jobData}
              snackbar={snackbar}
            />
          </Box>
        )}
        {/* Job Forms */}
        {jobForms.length > 0 && (
          <Box width="50%">
            <JobFormAttachments
              dispatchButtonState={dispatchButtonState}
              dispatchFilesData={dispatchFilesData}
              filesData={filesData}
              forms={jobForms}
              jobData={jobData}
              snackbar={snackbar}
            />
          </Box>
        )}
      </Box>
    </div>
  );
};

const mapDispatcherToProps = dispatch => ({
  snackbar: (mode, message) => dispatch(snackbarOn(mode, message))
});

export default React.memo(connect(null, mapDispatcherToProps)(SelectAttachment));
