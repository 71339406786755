import React from 'react';

import { Text, View } from '@react-pdf/renderer';

import styles from '../style';

export const ContinuationSheetHeader = (
  <View style={[styles.displayFlex, { alignItems: 'flex-end' }]}>
    <Text
      style={{
        paddingLeft: 7,
        width: '58%',
        fontFamily: 'Helvetica-Bold',
        fontSize: 16
      }}
    >
      CONTINUATION SHEET
    </Text>
    <Text style={{ fontSize: 14, fontFamily: 'Times-Italic' }}>ORIGINAL CONTRACT</Text>
  </View>
);

export default ContinuationSheetHeader;
