import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';

import AddRecord from 'components/Buttons/BlueText';

import { getDatafromPath } from 'utils';

import ProfileIcon from './ProfileIcon';
import styles from './styles';

class DynamicList extends Component {
  state = {};

  renderJsonAsListItem = valueObj => {
    let fieldName = this.props.fieldProps.name;
    let frontIcon;
    if (this.props.fieldProps.specialBehaviour) {
      ({ fieldName } = this.props.fieldProps.specialBehaviour);
      frontIcon = this.props.fieldProps.specialBehaviour.iconName;
    }

    let value = '';
    let imageKey = '';
    if (valueObj) {
      value = getDatafromPath(valueObj, fieldName);
      imageKey = frontIcon ? getDatafromPath(valueObj, frontIcon) : '';

      return (
        <>
          {frontIcon && !imageKey && <AccountCircle className={this.props.classes.listIcon} />}
          {imageKey && <ProfileIcon alt={value} fileName={imageKey} />}
          <ListItemText key={`lstText${valueObj.id}`}>
            <Typography className={this.props.classes.listTextStyle} variant="body1">
              {value}
            </Typography>
          </ListItemText>
        </>
      );
    }
    return <></>;
  };

  render() {
    const { classes, fieldProps } = this.props;
    const { values } = this.props.form;

    return (
      <>
        {fieldProps.allowAdd && this.props.mode !== 'view' && (
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <AddRecord
              handle={this.handleButtonClick}
              icon={AddIcon}
              label={fieldProps.addButtonName}
            />
          </Grid>
        )}

        <Grid item lg={12}>
          <List>
            {values[fieldProps.value] &&
              values[fieldProps.value].length > 0 &&
              values[fieldProps.value].map((listItem, index) => (
                <ListItem className={classes.listStyle} key={`listitem${index}`}>
                  {this.renderJsonAsListItem(listItem, index)}
                </ListItem>
              ))}
          </List>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DynamicList);
