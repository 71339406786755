import moment from 'moment';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import addJobsToCustomerProperty from 'services/core/graphql/jobs/mutations/addJobsToCustomerProperty';
import { getBestContact } from 'utils';
import { MaintenanceStatus } from 'utils/AppConstants';
import { EntityType } from 'utils/constants';

const serializer = data => {
  const {
    property,
    serviceDescription,
    dueDate,
    maintenanceType,
    propertyRep,
    poNumber,
    agreementInfo
  } = data;
  const { department } = agreementInfo;

  return {
    variables: {
      data: {
        customerPropertyId: property.value,
        jobs: [
          {
            jobTypeInternal: EntityType.MAINTENANCE_JOB,
            status: MaintenanceStatus.UNSCHEDULED,
            issueDescription: serviceDescription,
            customerRepSortKey: propertyRep?.value,
            customerRepName: propertyRep?.label,
            bestContact: getBestContact(propertyRep?.repData),
            dueDate: moment(dueDate)
              .startOf('day')
              .unix(),
            jobTypeId: maintenanceType?.value,
            jobTypeName: maintenanceType?.label,
            customerProvidedPONumber: poNumber,
            serviceAgreementId: agreementInfo.id,
            customerId: agreementInfo.customerId,
            customerName: agreementInfo?.customer?.customerName,
            customerSortKey: agreementInfo?.customer?.sortKey,
            billingCustomerId: agreementInfo.billingCustomerId,
            billingCustomerName: agreementInfo?.billingCustomer?.customerName,
            customerPropertyName: property?.label,
            customerPropertyId: property.value,
            departments: department
              ? [
                  {
                    id: department.id,
                    tagName: department.tagName,
                    tenantId: agreementInfo.tenantId
                  }
                ]
              : undefined
          }
        ]
      }
    }
  };
};

export const useAddMaintenance = () => {
  return useExtendedMutation(addJobsToCustomerProperty, {
    serializer
  });
};

export default useAddMaintenance;
