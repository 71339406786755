export const useStyles = () => ({
  container: theme => ({
    marginBottom: theme.default.spacing(2),
    maxWidth: 400
  }),
  label: theme => ({
    fontSize: 10,
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
    marginBottom: theme.default.spacing(0.5)
  }),
  dropdown: {
    width: 300
  }
});
