import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Marker } from '@react-google-maps/api';
import { func, number, shape, string } from 'prop-types';

import CompanyPin from '@dispatch/assets/images/pin.black.svg';

import { useGeocoder } from '../../MapView.hooks';

import { markerShape } from '../PropertyMarker/PropertyMarker.constants';
import { selectAddressString } from '../PropertyMarker/PropertyMarker.selectors';
import { useStyles } from '../PropertyMarker/PropertyMarker.styles';

export const useMarkerIcon = ({ scale = 1 }) => {
  const { Size, Point, MarkerImage } = google.maps;
  const anchor = useRef(new Point(23 * scale, 40 * scale));
  const origin = useRef(new Point(0, 0));
  const size = useRef(new Size(46 * scale, 60 * scale));
  const mapPin = CompanyPin;

  return useMemo(() => {
    return new MarkerImage(
      mapPin,
      null /* size is determined at runtime */,
      origin.current,
      anchor.current,
      size.current
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const CompanyMarker = ({ company, updateBounds }) => {
  const scale = 0.6;
  const classes = useStyles();
  const [position, setPosition] = useState(company.position);
  const { loading, geocode } = useGeocoder();
  const icon = useMarkerIcon({ scale });

  useEffect(() => {
    setPosition(company.position);
  }, [company.position]);

  const handlePositionUpdate = newPosition => {
    setPosition(newPosition);
    updateBounds(newPosition);
  };

  if (!(position?.lat && position?.lng)) {
    if (!loading) geocode(selectAddressString(company.address), handlePositionUpdate);
    return null;
  }

  return (
    <Marker
      icon={icon}
      label={{
        text: company.companyName,
        className: classes.label
      }}
      position={position}
      shape={markerShape}
    />
  );
};

const ADDRESS_PROP = shape({
  addressLine1: string,
  addressLine2: string,
  addressType: string,
  city: string,
  latitude: number,
  longitude: number,
  state: string,
  zipcode: string
});

CompanyMarker.propTypes = {
  company: shape({
    id: string,
    address: ADDRESS_PROP,
    companyName: string,
    position: shape({
      lat: number,
      lng: number
    })
  }).isRequired,
  updateBounds: func.isRequired
};

export default CompanyMarker;
