import { AccountingApp, TransactionType } from 'utils/constants';

export const GetAdjustmentTypeColumns = accountingApp =>
  [
    {
      id: 'name',
      maxTextLen: 30,
      label: 'Adjustment Type Name',
      filterKey: 'AdjustmentType.name',
      filterType: 'string'
    },
    {
      id: 'type',
      maxTextLen: 30,
      label: 'Adjustment Transaction Type',
      filterKey: 'AdjustmentType.type',
      filterType: 'string'
    },
    {
      id: 'ledgerAccount.name',
      label: 'GL Account',
      filterKey: 'LedgerAccount.name',
      filterType: 'string'
    },
    accountingApp !== AccountingApp.INTACCT && {
      id: 'ledgerOffsetAccount.name',
      label: 'GL Offset Account',
      filterKey: 'LedgerOffsetAccount.name',
      filterType: 'string'
    }
  ].filter(Boolean);

export const AdjustmentTypeLayout = (ledgerAccounts, accountingApp) => ({
  fields: {},
  layouts: {
    edit: {
      options: {
        pageSize: 'LETTER',
        direction: 'column',
        grow: 1,
        alignItems: 'center'
      },
      contents: [
        {
          options: { direction: 'row', width: '100%', marginBottom: 16 },
          contents: [
            {
              options: {
                direction: 'column',
                marginRight: 16
              },
              contents: [
                {
                  options: {
                    label: 'Adjustment Type',
                    isRequired: true
                  },
                  component: 'TextInput',
                  source: 'name'
                }
              ]
            },
            {
              options: {
                direction: 'column'
              },
              contents: [
                {
                  options: {
                    label: 'Transaction Type',
                    type: 'number',
                    isRequired: true,
                    inputOptions: [
                      { label: 'Overpayment', value: TransactionType.OVERPAYMENT },
                      { label: 'Refund', value: TransactionType.REFUND },
                      { label: 'Write Off', value: TransactionType.WRITE_OFF }
                    ]
                  },
                  component: 'SelectInput',
                  source: 'type'
                }
              ]
            }
          ]
        },
        {
          options: { width: '100%' },
          contents: [
            {
              options: {
                label: 'GL Account',
                isRequired:
                  accountingApp !== AccountingApp.INTACCT ||
                  (values => values.type === TransactionType.OVERPAYMENT),
                placeholder: 'Search/Select...',
                isSearchable: true,
                inputOptions: ledgerAccounts
              },
              dependentField:
                accountingApp === AccountingApp.INTACCT
                  ? {
                      fieldName: 'type',
                      expectedValue: TransactionType.OVERPAYMENT,
                      operation: 'notEqual',
                      action: 'HIDE'
                    }
                  : undefined,
              component: 'SelectInput',
              source: 'ledgerAccountId'
            }
          ]
        },
        accountingApp !== AccountingApp.INTACCT && {
          options: { width: '100%', marginTop: 16 },
          contents: [
            {
              options: {
                label: 'GL Offset Account',
                isRequired: true,
                placeholder: 'Search/Select...',
                isSearchable: true,
                inputOptions: ledgerAccounts
              },
              dependentField: {
                fieldName: 'type',
                expectedValue: TransactionType.WRITE_OFF,
                operation: 'equal',
                action: 'HIDE'
              },
              component: 'SelectInput',
              source: 'ledgerOffsetAccountId'
            }
          ]
        }
      ].filter(Boolean)
    }
  },
  validation: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        pattern: /\S/, // needs at least one non-whitespace char
        nullable: true,
        required: true
      },
      type: {
        type: 'string',
        nullable: true,
        required: true
      },
      ledgerAccountId: {
        type: 'string',
        required: accountingApp !== AccountingApp.INTACCT,
        nullable: true,
        when:
          accountingApp === AccountingApp.INTACCT
            ? {
                type: {
                  is: type => type === TransactionType.OVERPAYMENT,
                  then: {
                    required: true
                  }
                }
              }
            : undefined
      },
      ledgerOffsetAccountId: {
        type: 'string',
        nullable: true,
        when:
          accountingApp !== AccountingApp.INTACCT
            ? {
                type: {
                  is: type => [TransactionType.OVERPAYMENT, TransactionType.REFUND].includes(type),
                  then: {
                    required: true
                  }
                }
              }
            : undefined
      }
    }
  },
  validationErrors: {
    name: {
      required: 'Name is required.',
      pattern: 'Name is required.'
    },
    type: {
      required: 'Payment term type is required.'
    },
    ledgerAccountId: {
      required: 'GL account is required'
    },
    ledgerOffsetAccountId: {
      required: 'GL offset account is required'
    }
  }
});

export default AdjustmentTypeLayout;
