import { theme as SgtTheme } from '@BuildHero/sergeant';
import { makeStyles } from '@material-ui/core/styles';

// Custom Styles for Old Theme
export const useBuildHeroTableStyles = makeStyles(theme => ({
  root: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    border: 0,
    fontSize: 14,
    'min-height': 200,
    '& .MuiDataGrid-columnsContainer': {
      border: `1px solid ${theme.palette.grayscale(80)}`,
      backgroundColor: theme.palette.grayscale(94.1)
    },
    '& .MuiDataGrid-columnHeader': {
      height: 48
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0
    },
    '& .MuiDataGrid-toolbarContainer': {
      padding: 0,
      marginBottom: 16
    },
    '& .MuiDataGrid-viewport': {
      border: `1px solid ${theme.palette.grayscale(90.2)}`,
      overflow: 'initial'
    },
    '& .MuiDataGrid-columnSeparator': {
      right: -1,
      top: 0,
      display: 'flex',
      zIndex: 100,
      position: 'absolute',
      height: 48,
      width: 3,
      backgroundColor: theme.palette.grayscale(80),
      borderRight: `1px solid ${theme.palette.grayscale(94.1)}`,
      borderLeft: `1px solid ${theme.palette.grayscale(94.1)}`
    },
    '& .MuiDataGrid-colCellTitle': {
      fontWeight: theme.typography.fontWeightBold
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none'
    },
    '& .MuiDataGrid-sortIcon': {
      color: theme.palette.secondary.main
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: theme.palette.other.lightGreen
    },
    '& .MuiDataGrid-colCell.MuiDataGrid-colCellSortable': {
      border: `1px solid ${theme.palette.grayscale(80)}`,
      borderLeft: 0
    },
    '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
      border: `1px solid ${theme.palette.grayscale(90.2)}`,
      borderLeft: 0,
      borderTop: 0
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${theme.palette.grayscale(90.2)}`
    },
    '& .MuiDataGrid-cell': {
      color: theme.palette.text.primary
    },
    '& .MuiDataGrid-cell--textRight': {
      // right aligned = numeric
      ...SgtTheme.typography.numberStyle
    },
    '& .MuiDataGrid-cellMoreButton': {
      padding: 0,
      paddingLeft: 6
    },
    '& .MuiDataGrid-cellBold': {
      fontWeight: theme.typography.fontWeightBold
    },
    '& .MuiDataGrid-cellBoldRed': {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.other.scarletRed
    },
    '& .MuiDataGrid-checkboxInput.Mui-disabled': {
      color: theme.palette.grayscale(80)
    }
  }
}));

// Styles for to look like the demo
// https://material-ui.com/components/data-grid/#commercial-version
export const useMUITableStyles = makeStyles(theme => ({
  root: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    border: 0,
    fontSize: 14,
    '& .MuiDataGrid-main': {
      border: `1px solid ${theme.palette.grayscale(80)}`
    },
    '& .MuiDataGrid-columnsContainer': {
      borderBottom: `1px solid ${theme.palette.grayscale(80)}`
    },
    '& .MuiDataGrid-colCellTitle': {
      fontWeight: theme.typography.fontWeightBold
    },
    '& .MuiDataGrid-toolbarContainer': {
      padding: 0,
      marginBottom: 16
    },
    '& .MuiDataGrid-iconSeparator': {
      opacity: 0.5,
      color: theme.palette.text.primary
    },
    '& .MuiDataGrid-iconSeparator:hover': {
      opacity: 1,
      color: theme.palette.text.primary
    },
    '& .MuiDataGrid-sortIcon': {
      color: theme.palette.secondary.main
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: theme.palette.other.lightGreen
    },
    '& .MuiDataGrid-cell--textRight': {
      // right aligned = numeric
      ...SgtTheme.typography.numberStyle
    },
    '& .MuiDataGrid-cellBold': {
      fontWeight: theme.typography.fontWeightBold
    },
    '& .MuiDataGrid-cellBoldRed': {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.other.scarletRed
    },
    '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${theme.palette.grayscale(80)}`
    },
    '& .MuiDataGrid-checkboxInput.Mui-disabled': {
      color: theme.palette.grayscale(80)
    }
  }
}));
