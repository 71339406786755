import React, { useRef } from 'react';

import { Input, MoreButton } from '@BuildHero/sergeant';
import { Box, Grid, IconButton, useTheme } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';
import ArchiveIcon from '@material-ui/icons/Archive';
import CloseIcon from '@material-ui/icons/Close';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

import StatusChip from 'components/StatusChip';
import { ArchivedStatus, EnumType } from 'utils/constants';

import styles from '../../styles';

const AccordionSummary = withStyles(styles.accordionSummary)(MuiAccordionSummary);

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  marginBottom: '5px',

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'rgb(240, 240, 240)',

  // styles we need to apply on draggables
  ...draggableStyle
});

const LaborTypeCard = ({
  item,
  index,
  isDragDisabled,
  laborTypes,
  onModifyLaborType,
  onAddLaborType,
  onRemoveLaborType
}) => {
  const theme = useTheme();
  const anchorRef = useRef();

  return (
    <Draggable
      draggableId={`draggable-${item.id}`}
      index={index}
      isDragDisabled={isDragDisabled}
      key={item.id}
    >
      {(provided, snapshot) => (
        <div style={{ opacity: snapshot.isDragging ? 0.5 : 1 }}>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            <Box css={styles.accordionContainer} m={0} p={0}>
              <MuiAccordion elevation={item.isArchived ? 0 : 2} square>
                <AccordionSummary>
                  <div {...provided.dragHandleProps} css={styles.dragHandler}>
                    <IconButton css={styles.dragIcon} disableRipple>
                      <DragIndicatorIcon css={styles.dragIcon} />
                    </IconButton>
                  </div>
                  <Box component="div" width={1}>
                    <Input
                      autoFocus={index === laborTypes.length - 1 && laborTypes[index].name === ''}
                      css={styles.input}
                      defaultValue={item.name ?? ''}
                      disabled={item.isArchived}
                      id={`scope-input-${item.id}`}
                      label=""
                      placeholder='Enter Labor Type (eg "FM")'
                      onBlur={e => {
                        if (!e.target.value) {
                          onRemoveLaborType({ index });
                        } else {
                          onModifyLaborType({ field: 'name', value: e.target.value, index });
                        }
                        e.relatedTarget?.click();
                      }}
                      onChange={e =>
                        onModifyLaborType({ field: 'name', value: e.target.value, index })
                      }
                      onKeyDown={e => {
                        if (e.key === 'Enter') onAddLaborType();
                      }}
                    />
                  </Box>
                  {item.isArchived && (
                    <Grid item justify="flex-end">
                      <StatusChip
                        css={{ borderRadius: 2, marginLeft: 8 }}
                        enumType={EnumType.ARCHIVED_STATUS}
                        enumValue={ArchivedStatus.IS_ARCHIVED}
                        label="Archived"
                        showIcon
                      />
                    </Grid>
                  )}
                  <Grid item justify="flex-end">
                    {item.unsaved ? (
                      <IconButton
                        disableRipple
                        ref={anchorRef}
                        style={styles.closeIconButton}
                        onClick={() => onRemoveLaborType({ index })}
                      >
                        <CloseIcon css={styles.closeIcon} />
                      </IconButton>
                    ) : (
                      <MoreButton
                        disableRipple
                        options={[
                          {
                            label: 'Archive',
                            icon: UnarchiveIcon,
                            disabled: item.isArchived,
                            onClick: () =>
                              onModifyLaborType({ field: 'isArchived', value: true, index })
                          },
                          {
                            label: 'Unarchive',
                            icon: ArchiveIcon,
                            disabled: !item.isArchived,
                            onClick: () =>
                              onModifyLaborType({ field: 'isArchived', value: false, index })
                          }
                        ]}
                        style={{
                          ...styles.closeIconButton,
                          height: theme.spacing(2),
                          color: theme.palette.grayscale(80)
                        }}
                      />
                    )}
                  </Grid>
                </AccordionSummary>
              </MuiAccordion>
            </Box>
          </div>
        </div>
      )}
    </Draggable>
  );
};

LaborTypeCard.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  isDragDisabled: PropTypes.bool,
  laborTypes: PropTypes.array,
  onModifyLaborType: PropTypes.func,
  onAddLaborType: PropTypes.func,
  onRemoveLaborType: PropTypes.func
};

LaborTypeCard.defaultProps = {
  item: {},
  index: 0,
  isDragDisabled: false,
  laborTypes: [],
  onModifyLaborType: () => null,
  onAddLaborType: () => null,
  onRemoveLaborType: () => null
};

export default LaborTypeCard;
