import React from 'react';

import { Grid, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  limitNum: {
    fontSize: 12
  },
  selectEmpty: {
    marginTop: 0,
    fontSize: 12
  },
  optionStyle: {
    fontSize: 12,
    paddingTop: 2,
    paddingBottom: 2
  },
  gridStyle: {
    alignItems: 'center',
    alignSelf: 'center'
  }
}));

function ItemCount(props) {
  const {
    numItemsDisplayed,
    totalNumItems,
    limitPerPage,
    rowsPerPageOptions,
    onChangeRowsPerPage,
    disabled
  } = props;
  const classes = useStyles();

  const itemsStatusText =
    numItemsDisplayed > 0
      ? `1-${numItemsDisplayed} of ${totalNumItems}`
      : `0 of ${totalNumItems ?? 0}`;
  return (
    <div>
      <Grid className={classes.gridStyle} container spacing={2}>
        <Grid item>
          <Typography className={classes.limitNum}>{`Rows being shown: `}</Typography>
        </Grid>
        <Grid item style={{ paddingLeft: 0 }}>
          <Select
            className={classes.selectEmpty}
            disabled={disabled}
            displayEmpty
            value={limitPerPage}
            onChange={event => {
              if (onChangeRowsPerPage) {
                onChangeRowsPerPage(event.target.value);
              }
            }}
          >
            {rowsPerPageOptions.map(x => (
              <MenuItem className={classes.optionStyle} value={x}>
                {x}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Typography variant="overline">{itemsStatusText}</Typography>
    </div>
  );
}

ItemCount.propTypes = {
  numItemsDisplayed: PropTypes.number.isRequired,
  totalNumItems: PropTypes.number.isRequired,
  limitPerPage: PropTypes.number.isRequired
};

export default ItemCount;
