import { sentryException } from 'services/Logger';

export const getFileName = file => {
  if (file?.customFileName) {
    const fileNameSplit = file.fileName?.split('.');
    const fileType = fileNameSplit[fileNameSplit.length - 1];
    const customFileName = `${file.customFileName}.${fileType}`;
    return customFileName;
  }
  return file?.fileName || '';
};

export const download = file => {
  fetch(file?.url, {
    method: 'GET',
    headers: {}
  })
    .then(response => {
      response.arrayBuffer().then(buffer => {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', getFileName(file));
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch(err => {
      sentryException(err, 'Unable to get file for download');
    });
};
