import React, { Component } from 'react';

import algoliasearch from 'algoliasearch/lite';
import PropTypes from 'prop-types';
import { Configure, InstantSearch } from 'react-instantsearch-dom';

import configForEnvironment from 'configs/aws-exports';
import ENV from 'configs/env';
import { CommonService } from 'services/core';
import { Logger } from 'services/Logger';

import Autocomplete from './Autocomplete';
import { getHitName } from './utils';
import './style.css';

class AlgoliaSelect extends Component {
  constructor(props) {
    super(props);
    this.mounted = true;
    this.CommonService = new CommonService();
    this.state = {
      searchKey: '',
      searchClient: ''
    };
  }

  componentDidMount = async () => {
    try {
      const { data } = await this.CommonService.getAlgoliaSecuredKey();
      const searchKey = data.getAlgoliaSecuredKey;
      const searchClient = algoliasearch(configForEnvironment(ENV).algoliaAppKey, searchKey);
      if (this.mounted) this.setState({ searchKey, searchClient });
    } catch (error) {
      Logger.error(error);
    }
  };

  onSuggestionSelected = (_, { suggestion }) => {
    const { displayText, delimiter, customHitFormatter, showEntityNameInResults } = this.props;
    const hitName = getHitName(
      suggestion,
      displayText,
      delimiter,
      showEntityNameInResults,
      customHitFormatter
    );
    this.props.handleChange(suggestion, hitName);
  };

  onSuggestionCleared = () => {
    this.props.handleChange('', undefined);
  };

  render() {
    const { searchKey, searchClient } = this.state;
    const { searchIndex, onLoading, filters, attributes, ...formProps } = this.props;
    let filterStr = '';
    const orConditionArr = [];
    const andConditionArr = [];
    if (filters) {
      filters.forEach(filterKey => {
        if (filterKey.valueArray && filterKey.valueArray.length > 1) {
          if (filterKey.parenthetical) {
            const parenFilters = filterKey.attributeArray.map((attributeValue, index) => {
              return `${attributeValue}:${filterKey.valueArray[index]}`;
            });
            andConditionArr.push(`(${parenFilters.join(` ${filterKey.operand} `)}) `);
          } else {
            filterKey.valueArray.forEach(filterValue => {
              orConditionArr.push(`${filterKey.attribute}:${filterValue}`);
            });
          }
        }
        if (filterKey.valueArray && filterKey.valueArray.length === 1) {
          andConditionArr.push(`${filterKey.attribute}:${filterKey.valueArray[0]}`);
        }
      });
    }

    const orConditionStr = orConditionArr.join(' OR ');
    if (orConditionStr) {
      andConditionArr.push(orConditionStr);
    }
    filterStr = andConditionArr.join(' AND ');
    if (!searchKey || !searchClient) {
      onLoading(true);
      return <p>Loading...</p>;
    }
    onLoading(false);

    return (
      <InstantSearch indexName={searchIndex} searchClient={searchClient}>
        {filterStr !== ' AND ' && (
          <Configure
            filters={filterStr}
            hitsPerPage={5}
            restrictSearchableAttributes={attributes}
          />
        )}
        <Autocomplete
          formProps={formProps}
          onSuggestionCleared={this.onSuggestionCleared}
          onSuggestionSelected={this.onSuggestionSelected}
        />
      </InstantSearch>
    );
  }
}

AlgoliaSelect.propTypes = {
  onLoading: PropTypes.func
};

AlgoliaSelect.defaultProps = {
  onLoading: () => {}
};

export default AlgoliaSelect;
