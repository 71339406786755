import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import {
  Button,
  Divider,
  MultiSelect,
  ThemeProvider,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';
import { Grid } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { flatten, noop } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ImageThumbnail from 'components/ImageThumbnail';
import { useTrigger } from 'customHooks/useTrigger';

import { addPropertyAssetsToCustomerProperty } from 'scenes/Customer/Assets/gql';
import AssetModal from 'scenes/Customer/PropertyDetail/Assets/AssetModal';
import { assetPayload } from 'scenes/Customer/PropertyDetail/helpers';
import { getTenantSettingValueForKey } from 'utils';
import { Mode } from 'utils/constants';
import FeatureFlagsConstants from 'utils/FeatureFlagConstants';

import AssetTaskLineItem from './components/AssetTaskLineItem';
import BulkAddTaskCard from './components/BulkAddTaskCard';
import NoAssets from './components/NoAssets';
import TasksBase from './components/TasksBase';
import TaskTableOverflow from './components/TaskTableOverflow';
import GET_PROPERTY_BY_ID from './gql';

const formatMakeModelSerialNumber = o => {
  const make = o.make || '';
  const modelNumber = o.modelNumber || '';
  const serialNo = o.serialNo || '';
  return serialNo ? `${make} ${modelNumber} - ${serialNo}` : `${make} ${modelNumber}`;
};

export const formatAssetOption = o => ({
  id: o.id,
  label: o.assetName,
  rightLabel: formatMakeModelSerialNumber(o),
  value: o,
  group: o.assetType?.tagName
});

const style = {
  assetName: {
    marginRight: 8,
    marginLeft: 8
  },
  assetType: {},
  title: {
    paddingBottom: 8,
    display: 'flex',
    justifyContent: 'space-between'
  },
  subtitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 12
  },
  titleBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  leftTitle: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  rightTitle: {
    display: 'inline-flex'
  },
  topLine: {
    marginTop: 16
  },
  image: {
    cursor: 'pointer'
  },
  moreHeaderButtons: {
    marginLeft: 5,
    display: 'inline-flex'
  }
};

export const Title = ({ options }) => (
  <Typography variant={TV.L} weight={TW.BOLD}>
    {options.label}
  </Typography>
);

export const Subtitle = ({ options }) => (
  <Typography variant="subTitle">{options.label}</Typography>
);

const Tasks = ({
  isActive,
  jobId,
  propertyId,
  assetTasks,
  tasks,
  onSubmit = noop,
  setSubmitService = noop,
  showCheckmarks = false,
  setTouched = () => {},
  headerButtons = [],
  fetchJobData
}) => {
  const [createAssetModalOpen, setCreateAssetModalOpen] = useState(false);
  const [newlyCreatedAssets, setNewlyCreatedAssets] = useState([]);
  const [showBulkAddTaskComponent, setShowBulkAddTaskComponent] = useState(false);
  const [rerender, triggerRerender] = useTrigger();
  const ldFlags = useFlags();

  const isAssetEnabled = getTenantSettingValueForKey('assetTrackingAgainstTask') === 'true';

  const { data: property, loading, error, refetch } = useQuery(GET_PROPERTY_BY_ID, {
    variables: {
      id: propertyId
    },
    fetchPolicy: 'cache-and-network'
  });

  const [addAsset, { loading: addAssetLoading }] = useMutation(addPropertyAssetsToCustomerProperty);

  const assetTasksRef = useRef(assetTasks);
  const setAssetTasks = newAssetTasks => {
    assetTasksRef.current = newAssetTasks;
    setTouched(true);
    triggerRerender();
  };
  const tasksRef = useRef(tasks);
  const setTasks = newTasks => {
    tasksRef.current = newTasks;
    setTouched(true);
    triggerRerender();
  };

  const handleCreateAsset = async (newData, stopLoading) => {
    const payload = assetPayload(newData);

    const { data: newAssetData } = await addAsset({
      variables: {
        data: {
          customerPropertyId: propertyId,
          propertyAssets: [{ ...payload }]
        }
      }
    });
    const asset = newAssetData.addPropertyAssetsToCustomerProperty[0];

    const newAssetTasks = { ...assetTasksRef.current, [asset.id]: [] };
    setNewlyCreatedAssets([...newlyCreatedAssets, asset]);
    setAssetTasks(newAssetTasks);

    stopLoading();
    setCreateAssetModalOpen(false);
  };

  const assetList = useMemo(
    () => [
      ...(property?.getCustomerPropertyById?.propertyAssets?.items || []).filter(p => p.isActive),
      ...newlyCreatedAssets
    ],
    [property, newlyCreatedAssets]
  );

  const assetMap = useMemo(
    () => assetList.reduce((acc, asset) => ({ ...acc, [asset.id]: { ...asset } }), {}),
    [assetList.length]
  );

  const company = useSelector(state => state.company);
  const forms = useMemo(
    () =>
      company?.forms?.items.filter(
        f => f.latestPublishedFormDefinitionSortKey && f.associatedEntityType === 'Task'
      ) || [],
    []
  );

  const formOptions = useMemo(() => {
    if (!property) return [];

    const options = [];

    forms.forEach(form => {
      const associatedAssets = assetList.filter(a =>
        (a?.forms ?? []).find(assetForm => assetForm.id === form.id)
      );

      const value = {
        name: form.name,
        required: false,
        asset: null,
        formDefinitionId: form.latestPublishedFormDefinition.id,
        formSortKey: form.sortKey,
        formDefinitionSortKey: form.latestPublishedFormDefinitionSortKey
      };

      if (associatedAssets.length === 0) {
        options.push({
          id: form.id,
          label: form.name,
          value
        });
      } else {
        associatedAssets.forEach(a => {
          options.push({
            id: form.id,
            label: form.name,
            value: { ...value, asset: a.id }
          });
        });
      }
    });
    return options;
    // form options only need to be calculated once after property load
    // 1) newly created assets cannot have associated forms
    // 2) interface to create forms is not in this view
  }, [property]);

  useEffect(() => {
    if (property) {
      setSubmitService({
        submit: () =>
          onSubmit({ assetTasks: assetTasksRef.current, tasks: tasksRef.current, assetMap })
      });
    }
  }, [property, newlyCreatedAssets]);

  const isLoading = useMemo(() => {
    return !property || loading;
  }, [property, loading]);

  const options = useMemo(() => {
    return assetList.map(formatAssetOption);
  }, [assetList]);

  const selectedOptions = useMemo(() => {
    return Object.keys(assetTasksRef.current)
      .filter(assetId => !!assetMap[assetId])
      .map(assetId => formatAssetOption(assetMap[assetId]));
  }, [assetTasksRef.current, assetMap]);

  return (
    <>
      {ldFlags[FeatureFlagsConstants.TASKS_OVERFLOW_X_GRID] ? (
        <TaskTableOverflow
          assetTasks={assetTasks}
          customerPropertyId={propertyId}
          fetchJobData={fetchJobData}
          formatAssetOption={formatAssetOption}
          isActive={isActive}
          jobId={jobId}
          tasks={tasks}
        />
      ) : (
        <ThemeProvider>
          {isAssetEnabled && (
            <>
              <div css={style.title}>
                <Title options={{ label: 'Tasks' }} />
              </div>
              <div css={style.subtitle}>
                <Subtitle
                  options={{
                    label:
                      'Select which assets will be worked on and what tasks will be performed for each asset.'
                  }}
                />
                <div>
                  <Button
                    disabled={
                      showBulkAddTaskComponent || Object.keys(assetTasksRef.current).length === 0
                    }
                    type="secondary"
                    onClick={() => setShowBulkAddTaskComponent(true)}
                  >
                    Bulk Add Tasks
                  </Button>
                  {headerButtons.map(B => (
                    <div css={style.moreHeaderButtons}>
                      <B />
                    </div>
                  ))}
                </div>
              </div>
              {isLoading ? (
                <Skeleton
                  count={
                    flatten(Object.keys(assetTasks).map(assetId => assetTasks[assetId])).length || 1
                  }
                  height={32}
                />
              ) : (
                <MultiSelect
                  createOptionLabel="Create New Asset"
                  grouped
                  label="Assets To Be Worked On"
                  lineItemComponent={(option, selectedLineItemOptions) => {
                    return (
                      <AssetTaskLineItem
                        asset={option.value}
                        assetMap={assetMap}
                        formOptions={formOptions}
                        isFirstLine={option.id === selectedOptions[0].id}
                        key={`Asset-${option.value.id}`}
                        ref={assetTasksRef}
                        selectedOptions={selectedLineItemOptions}
                        setAssetTasks={setAssetTasks}
                        setShowBulkAddTaskComponent={setShowBulkAddTaskComponent}
                        showBulkAddTaskComponent={showBulkAddTaskComponent}
                        showCheckmarks={showCheckmarks}
                        style={style}
                      />
                    );
                  }}
                  options={options}
                  placeholder="Search Assets"
                  selectedOptions={selectedOptions}
                  showChips
                  topLevelSelectAllLabel="Select All Assets"
                  onChange={assets => {
                    const newAssetTaskMap = assets.reduce((acc, asset) => {
                      if (assetTasksRef.current[asset.id]) {
                        // asset was already selected
                        return { ...acc, [asset.id]: assetTasksRef.current[asset.id] };
                      }
                      return { ...acc, [asset.id]: [] };
                    }, {});

                    setAssetTasks(newAssetTaskMap);
                  }}
                  onClickCreateOption={() => setCreateAssetModalOpen(true)}
                />
              )}
              {!isLoading && Object.keys(assetTasksRef.current).length === 0 && <NoAssets />}
              <Divider />
              <AssetModal
                handleClose={() => setCreateAssetModalOpen(false)}
                handlePrimaryAction={handleCreateAsset}
                mode={Mode.ADD}
                open={createAssetModalOpen}
              />
            </>
          )}
          <>
            <div css={style.title}>
              <Title
                options={{ label: isAssetEnabled ? 'Tasks - No Asset Associated' : 'Tasks' }}
              />
            </div>
            {!isAssetEnabled && (
              <div css={style.subtitle}>
                <Subtitle options={{ label: 'Create the tasks that will be performed.' }} />
                {headerButtons.map(B => (
                  <div css={style.moreHeaderButtons}>
                    <B />
                  </div>
                ))}
              </div>
            )}
            {isLoading ? (
              <Skeleton count={tasks.length || 1} height={32} />
            ) : (
              <TasksBase
                forms={forms.map(form => ({
                  id: form.id,
                  label: form.name,
                  value: {
                    name: form.name,
                    required: false,
                    formDefinitionId: form.latestPublishedFormDefinition.id,
                    formSortKey: form.sortKey,
                    formDefinitionSortKey: form.latestPublishedFormDefinitionSortKey
                  }
                }))}
                showCheckmarks={showCheckmarks}
                tasks={tasksRef.current}
                onTasksChange={setTasks}
              />
            )}
          </>
        </ThemeProvider>
      )}
    </>
  );
};

export default Tasks;
