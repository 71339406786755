import { column, ColumnType, valueGetters } from 'components/XGrid/columnTypes';
import { MultiSelectTypes } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';

const quoteListColumns = [
  {
    field: 'quoteIdentifier',
    headerName: 'Quote #',
    width: 140,
    valueGetter: valueGetters.quoteLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'quoteTitle',
    headerName: 'Title',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'customerName',
    headerName: 'Customer',
    width: 140,
    valueGetter: valueGetters.customerLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'customerPropertyName',
    headerName: 'Property',
    width: 140,
    valueGetter: valueGetters.propertyLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'createdDate',
    headerName: 'Created On',
    width: 150,
    ...column[ColumnType.DATE]
  },
  {
    field: 'dueDate',
    headerName: 'Due Date',
    width: 150,
    ...column[ColumnType.DATE]
  },
  {
    field: 'quoteAmount',
    headerName: 'Amount',
    width: 150,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    enumType: EnumType.QUOTE_STATUS,
    ...column[ColumnType.ENUM]
  },
  {
    field: 'quoteTags',
    headerName: 'Tags',
    width: 100,
    enumType: MultiSelectTypes.QUOTE_TAGS,
    ...column[ColumnType.TAGS]
  },
  {
    field: 'ownerName',
    headerName: 'Project Manager',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'accountManagerValue',
    headerName: 'Account Manager',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'salesByName',
    headerName: 'Sold By',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'billingAddress',
    headerName: 'Billing Address',
    width: 275,
    ...column[ColumnType.ADDRESS]
  },
  {
    field: 'comments',
    headerName: 'Comments',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'customerPoNumber',
    headerName: 'Customer PO#',
    width: 175,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'propertyRepName',
    headerName: 'Property Representative',
    width: 175,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'orderedByName',
    headerName: 'Customer Representative',
    width: 175,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'jobName',
    headerName: 'Job',
    width: 150,
    valueGetter: valueGetters.jobLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'versionNumber',
    headerName: 'Version',
    width: 140,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'versionLabel',
    headerName: 'Version Title',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'departmentName',
    headerName: 'Department',
    width: 150,
    enumType: MultiSelectTypes.DEPARTMENTS,
    ...column[ColumnType.TAGS]
  },
  {
    field: 'notes',
    headerName: 'Notes',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'issueDescription',
    headerName: 'Issue Description',
    width: 150,
    ...column[ColumnType.TEXT]
  }
];

export default quoteListColumns;
