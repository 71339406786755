import { makeStyles } from '@material-ui/core';

import { DispatchTheme, ElementSizes, ElementZIndex } from '@dispatch/Dispatch.styles';

export const useStyles = makeStyles(theme => ({
  dailyViewHeader: {
    display: 'flex',
    width: ElementSizes.laneWidth,
    borderBottom: DispatchTheme.mixins.mainBorder,
    background: theme.palette.background.default,
    position: 'sticky',
    zIndex: ElementZIndex.stickyHeader,
    top: -1,
    paddingLeft: ElementSizes.techCellWidth
  },
  hourHeader: {
    height: ElementSizes.stickyHeaderHeight,
    maxHeight: ElementSizes.stickyHeaderHeight,
    width: ElementSizes.cellWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    textTransform: 'lowercase'
  },
  headerInner: {
    marginRight: -1 * ElementSizes.cellWidth
  },
  lastHeader: {
    overflow: 'hidden'
  }
}));
