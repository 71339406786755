import { payrollSettingFilter } from 'constants/common';
import { convertStringToFloat } from 'utils';
import { ReviewReportStatus } from 'utils/AppConstants';

export const fetchReviewReportDetails = async (context, event) => {
  const { reviewReportService, timesheetsService, companyService } = context.services;
  const { reportSortKey, reportId } = context;
  const companySortKey = `${context.user.tenantId}_Company_${context.user.tenantCompanyId}`;
  let newPromise = '';
  if (reportId) {
    newPromise = Promise.all([
      reviewReportService.getReviewReportById(reportId),
      companyService.getDefaultPricebookForCompany(context.user.tenantId, companySortKey),
      companyService.getCompanySettings(
        context.user.tenantId,
        context.user.tenantCompanyId,
        payrollSettingFilter
      ),
      timesheetsService.getPayrollHourTypes(context.user.tenantId, context.user.tenantCompanyId)
    ]);
  } else {
    /* TODO when job page is refactored, remove the sortkey option in both job detail and here */
    newPromise = Promise.all([
      reviewReportService.getReviewReport(context.user.tenantId, reportSortKey),
      companyService.getDefaultPricebookForCompany(context.user.tenantId, companySortKey)
    ]);
  }

  return newPromise;
};

// save function used by save as draft, submit button, add message to invoice and include in invoice
export const updateReviewReportFn = async (context, event) => {
  const { services, user, id, buttonClicked, reviewReport } = context;
  const { reviewReportService } = services;
  const { status } = event;

  const formattedData = {};
  formattedData.id = id;
  formattedData.status = status;

  const { data } = await reviewReportService.updateReviewReportStatus(user.tenantId, formattedData);

  const { updateReviewReportStatus } = data;
  const { snackbarOn } = context.reduxActions;
  let message;
  if (buttonClicked === 'submit') {
    message = 'Successfully submitted report';
  } else if (buttonClicked === 'dismiss') {
    message = 'Successfully dismissed report';
  } else {
    message = 'Successfully saved report';
  }
  snackbarOn('success', message);

  return {
    modalRecord: {},
    modalIndex: '',
    buttonClicked: '',
    version: updateReviewReportStatus.version,
    status: updateReviewReportStatus.status,
    reviewReport: {
      ...reviewReport,
      lastUpdatedDateTime: updateReviewReportStatus.lastUpdatedDateTime
    },
    freezeEdit: [ReviewReportStatus.SUBMITTED, ReviewReportStatus.DISMISSED].includes(
      updateReviewReportStatus?.status?.toLowerCase()
    )
  };
};

export const addBillFn = async (context, event) => {
  const { services, user } = context;
  const { reviewReportService } = services;
  const { saveData } = event;
  const { data } = await reviewReportService.saveReviewReportBillItem(
    user.tenantId,
    saveData.billItemsToAdd
  );
  const updatedReviewReport = {
    ...context.reviewReport,
    reviewReportBillItems: data?.saveReviewReportBillItems[0]?.reviewReport?.reviewReportBillItems
  };
  return { reviewReport: updatedReviewReport, modalRecord: {}, modalIndex: '' };
};

export const editBillLineItem = async (context, event) => {
  const { services, user, parentOrder } = context;
  const { saveData } = event;
  const { reviewReportService } = services;
  function formatItem(item) {
    return typeof item === 'string' ? convertStringToFloat(item) : item;
  }

  const payload = {
    costCodeId: saveData.costCodeId,
    revenueTypeId: saveData.revenueTypeId,
    jobCostTypeId: saveData.jobCostTypeId,
    departmentId: saveData.departmentId,
    description: saveData.description,
    id: saveData.id,
    billLineId: saveData.billLineId,
    markup: formatItem(saveData.markup),
    productId: saveData.productId,
    quantity: formatItem(saveData.quantity),
    reviewReportId: context.reportId,
    taxable: saveData.taxable === 'true' || saveData.taxable === true,
    unitCost: formatItem(saveData.unitCost),
    unitPrice: formatItem(saveData.unitPrice)
  };
  const { data } = await reviewReportService.saveReviewReportBillItem(user.tenantId, payload);
  if (data?.saveReviewReportBillItems) {
    const editedItem = data.saveReviewReportBillItems[0];
    const updatedBillItems = context.reviewReport.reviewReportBillItems.items.map(item =>
      item.billLineId === editedItem.billLineId
        ? {
            ...editedItem,
            billLine: {
              ...item.billLine,
              remainingQuantity: editedItem.billLine.remainingQuantity
            }
          }
        : item
    );

    const updatedReviewReport = {
      ...context.reviewReport,
      reviewReportBillItems: { items: updatedBillItems }
    };
    return { reviewReport: updatedReviewReport, modalRecord: {}, modalIndex: '' };
  }
};

export const deleteBillLine = async context => {
  const { services, user, modalRecord } = context;
  const { reviewReportService } = services;
  const { data } = await reviewReportService.deleteReviewReportBillItem(
    user.tenantId,
    modalRecord.id
  );

  if (data?.deleteReviewReportBillItem) {
    const updatedBillItems = context.reviewReport.reviewReportBillItems.items.filter(
      billItem => billItem.id !== modalRecord.id && billItem.billLine
    );
    const updatedReviewReport = {
      ...context.reviewReport,
      reviewReportBillItems: { items: updatedBillItems }
    };
    return { reviewReport: updatedReviewReport, modalRecord: {}, modalIndex: '' };
  }
};
