import React, { Component } from 'react';

import Button from '@material-ui/core/Button';

import ImageFullScreenDisplay from '../ImageFullScreenDisplay';

/**
 * Renders a Thumbnail Image in the screen
 */
class ImagesLink extends Component {
  state = {
    linkText: 'Receipt',
    fullScreenActive: false,
    images: []
  };

  toggleView = () => {
    this.setState({ images: this.props.images, fullScreenActive: !this.state.fullScreenActive });
  };

  render() {
    const { linkText, images, fullScreenActive } = this.state;

    return (
      <>
        {images && (
          <Button
            classes={{
              outlinedSecondary: this.props.classes.buttonOutlinedSecondary,
              label: this.props.classes.buttonLabel
            }}
            color="secondary"
            variant="outlined"
            onClick={() => this.toggleView()}
          >
            {linkText}
          </Button>
        )}
        {fullScreenActive ? (
          <ImageFullScreenDisplay images={images} index={0} toggle={this.toggleView} />
        ) : null}
      </>
    );
  }
}

export default ImagesLink;
