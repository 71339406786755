import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { visitFragment } from 'customHooks/useJob';

const REVIEW_VISIT = gql`
  mutation reviewVisit($visitIds: [String]!, $jobId: String!) {
    reviewVisit(visitIds: $visitIds, jobId: $jobId) {
      ${visitFragment}
    }
  }
`;

export const useReviewJobCloseoutReport = () =>
  useExtendedMutation(REVIEW_VISIT, {
    serializer: ({ visitIds, jobId }) => ({
      variables: {
        visitIds,
        jobId
      }
    })
  });
