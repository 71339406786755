import gql from 'graphql-tag';

const getCompany = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      version
      forms: formsView(entityConnection: "Form") {
        items {
          id
          name
          description
          viewType
          latestPublishedFormDefinitionSortKey
          latestPublishedFormDefinition(entityConnection: "latestPublishedFormDefinition") {
            createdBy
            formDefinitionJson
            lastUpdatedDateTime
          }
          latestDraftFormDefinitionSortKey
          latestDraftFormDefinition(entityConnection: "latestDraftFormDefinition") {
            createdBy
            formDefinitionJson
            lastUpdatedDateTime
          }
          associatedEntityType
          hierarchy
          tenantCompanyId
          version
          sortKey
        }
      }
    }
  }
`;

export default getCompany;
