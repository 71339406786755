import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import useExtendedQuery from 'customHooks/useExtendedQuery';

import { generatePayload } from './components/EmailInfoStep/EmailInfoStep.utils';
import { filterUniqueRecipients } from './InvoiceEmailModal.utils';

const getCustomerReps = gql`
  query getCustomerReps(
    $customerId: String!
    $propertyId: String!
    $billingCustomerId: String!
    $withDifferentBillingCustomer: Boolean!
  ) {
    getCustomerById(id: $customerId) {
      id
      customerReps(
        entityConnection: "CustomerRep"
        filter: { status: { eq: "active" } }
        limit: 100
      ) {
        items {
          id
          firstName
          lastName
          email
          emailInvoice
        }
      }
    }
    getCustomerPropertyById(id: $propertyId) {
      id
      customerReps(entityConnection: "CustomerRep") {
        items {
          id
          mappedEntity {
            ... on CustomerRep {
              id
              firstName
              lastName
              email
              emailInvoice
            }
          }
        }
      }
    }
    getBillingCustomerById: getCustomerById(id: $billingCustomerId)
      @include(if: $withDifferentBillingCustomer) {
      id
      customerReps(
        entityConnection: "CustomerRep"
        filter: { status: { eq: "active" } }
        limit: 100
      ) {
        items {
          id
          firstName
          lastName
          email
          emailInvoice
        }
      }
    }
  }
`;

const sendInvoice = gql`
  mutation sendInvoice($partitionKey: String, $data: AddEmailToInvoiceInput!) {
    addEmailToInvoice(partitionKey: $partitionKey, data: $data) {
      id
    }
  }
`;

const getLoggedInEmployee = gql`
  query getLoggedInEmployee($partitionKey: String!, $sortKey: String!) {
    getEmployee(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      name
      email
      personalEmail
    }
  }
`;

export const useGetCustomerReps = (customer, billingCustomer, customerProperty) => {
  const customerId = customer?.id || '';
  const billingCustomerId = billingCustomer?.id || '';
  const propertyId = customerProperty?.id || '';
  const withDifferentBillingCustomer = billingCustomerId && billingCustomerId !== customerId;
  return useExtendedQuery(getCustomerReps, {
    variables: {
      customerId,
      billingCustomerId,
      propertyId,
      withDifferentBillingCustomer
    },
    transform: data => {
      const customerReps = data?.getCustomerById?.customerReps?.items || [];
      const billingCustomerReps = data?.getBillingCustomerById?.customerReps?.items || [];
      const propertyRepsRaw = data?.getCustomerPropertyById?.customerReps?.items || [];
      const propertyReps = propertyRepsRaw.map(v => v.mappedEntity);
      const allReps = [...customerReps, ...billingCustomerReps, ...propertyReps];
      const filteredReps = filterUniqueRecipients(allReps);
      return filteredReps;
    }
  });
};

export const useSendInvoiceEmail = ({ onSuccess, onResponse }) => {
  return useExtendedMutation(sendInvoice, {
    serializer: async ({ partitionKey, formData, emailData, attachmentsData, userEmail }) => {
      const payload = await generatePayload(formData, emailData, attachmentsData, userEmail);
      return {
        variables: {
          partitionKey,
          data: payload
        }
      };
    },
    onSuccess: (data, snackbarOn) => {
      snackbarOn('success', 'Successfully sent email');
      onSuccess(data);
    },
    onResponse: ({ err }, snackbarOn) => {
      onResponse(err);
    }
  });
};

export const useGetLoggedInEmployee = ({ user }) => {
  const { employeeId, tenantId, tenantCompanyId } = user;
  return useExtendedQuery(getLoggedInEmployee, {
    variables: {
      partitionKey: tenantId,
      sortKey: `${tenantId}_${tenantCompanyId}_Employee_${employeeId}`
    },
    transform: data => {
      return data.getEmployee;
    },
    loadingData: {
      id: null,
      tenantId: null,
      name: null,
      email: null,
      personalEmail: null
    },
    skip: !user.employeeId
  });
};
