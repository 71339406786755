import React, { useState } from 'react';

import { Button, ButtonType, Modal, ThemeProvider } from '@BuildHero/sergeant';
import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';

import { PageHeader, UserPermission } from 'components';
import ErrorBoundaries from 'scenes/Error';
import { PermissionConstants } from 'utils/AppConstants';

import InventoryWarehouseTable from './InventoryWarehouseTable';

const InventoryWarehouseList = () => {
  const theme = useTheme();
  const locale = useSelector(state => state?.user?.locale);
  const [openAddWarehouseModal, setOpenAddWarehouseModal] = useState(false);

  const handleSaveAddWarehouseModal = () => {
    // @TODO: add mutation
    setOpenAddWarehouseModal(false);
  };

  return (
    <ErrorBoundaries>
      <UserPermission action={PermissionConstants.OBJECT_JOB} I="read">
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <PageHeader pageMapKey="inventoryWarehouse" userLocale={locale} />
          </Box>
          <ThemeProvider>
            <Button
              type="primary"
              onClick={() => {
                setOpenAddWarehouseModal(true);
              }}
            >
              ADD WAREHOUSE
            </Button>
          </ThemeProvider>
        </Box>
        <InventoryWarehouseTable />
        <ThemeProvider>
          <Modal
            actions={
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1
                }}
              >
                <Button fullWidth type={ButtonType.PRIMARY} onClick={handleSaveAddWarehouseModal}>
                  SAVE
                </Button>
                <Button
                  fullWidth
                  style={{ marginTop: theme.spacing(3) }}
                  type={ButtonType.TERTIARY}
                  onClick={() => {
                    setOpenAddWarehouseModal(false);
                  }}
                >
                  CANCEL
                </Button>
              </Box>
            }
            open={openAddWarehouseModal}
            PaperProps={{ style: { minWidth: 454 } }}
            title="Create New Warehouse"
            onClose={() => {
              setOpenAddWarehouseModal(false);
            }}
          />
        </ThemeProvider>
      </UserPermission>
    </ErrorBoundaries>
  );
};

export default InventoryWarehouseList;
