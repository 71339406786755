const taxableSubtotal = ({
  convertToCurrencyStringCheck,
  settingsTotals,
  SettingConstants,
  smartFieldInfo,
  styles,
  fontFamilyStyle
}) =>
  settingsTotals[SettingConstants.SHOW_TAX]
    ? `<tr>
      <td style="${styles}width:50%;border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);" colspan="2"><strong>Taxable Subtotal</strong></td>
      <td colspan="3" style="${styles}text-align:right;border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);" class="restricted">
        <span fontfamily=${fontFamilyStyle} >${convertToCurrencyStringCheck(
        smartFieldInfo.subTotalAmountTaxableOverride ?? smartFieldInfo.subTotalAmountTaxable
      )}</span></td>
    </tr>`
    : '';

export default taxableSubtotal;
