import theme from 'themes/BuildHeroTheme';

const styles = {
  accordionContainer: (isArchived = false) => ({
    border: `1px solid ${theme.palette.grayscale(isArchived ? 80 : 60)}`,
    borderRadius: '2px'
  }),
  accordionSummary: {
    root: {
      marginTop: 0,
      marginBottom: 0,
      minHeight: '46px !important',
      boxShadow: 'none',
      height: 46,
      paddingLeft: 0,
      paddingRight: theme.spacing(0.5)
    },

    content: {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden'
    }
  },
  dragHandler: {
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    cursor: 'grab'
  },
  dragIcon: {
    height: 24,
    width: 24,
    cursor: 'grab',
    color: '#999999'
  },
  inputBox: {
    maxHeight: 36,
    minHeight: 36,
    height: 36,
    border: '1px solid',
    borderColor: '#999999',
    backgroundColor: '#F0F0F0'
  },
  input: {
    width: '100%',
    height: 36,
    maxHeight: 36,
    minHeight: 36
  },
  closeIcon: { height: theme.spacing(2), display: 'flex', alignItems: 'center' },
  closeIconButton: { padding: 0, marginLeft: 4, height: '100%' },
  stickyHeader: {
    position: 'fixed',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    zIndex: 5,
    top: 60,
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(3)
  },
  greenHeading: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    marginBottom: 8
  },
  settingIcon: {
    color: theme.palette.secondary.main
  },
  formEndDivider: { marginTop: 40, marginBottom: 6 },
  defaultHourTypes: {
    width: 222,
    backgroundColor: theme.palette.other.palePink,
    height: 36,
    textAlign: 'left',
    paddingLeft: 8,
    fontWeight: 500,
    lineHeight: 28,
    margin: 2,
    paddingTop: 8
  },
  payrollHourTypes: {
    width: 222,
    backgroundColor: theme.palette.other.palePurple,
    height: 36,
    textAlign: 'left',
    paddingLeft: 8,
    fontWeight: 500,
    lineHeight: 28,
    margin: 2,
    paddingTop: 8
  },
  hourTypeContainer: {
    marginTop: 25,
    marginBottom: 15
  },
  greyCaption: {
    fontSize: 10,
    color: theme.palette.grayscale(60)
  }
};

export default styles;
