import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import { makeStyles, withTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import AlgoliaSearch from 'components/AlgoliaSearch';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';

import TableView from './TableView';

const useStyles = makeStyles(theme => ({
  inputLabel: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'normal',
    letterSpacing: '0.01px',
    lineHeight: '14px'
  },
  requiredLabel: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    fontSize: '8px',
    letterSpacing: 0,
    lineHeight: '14px'
  }
}));

function AddItemsFromSearch(props) {
  const {
    searchParams,
    selectParams,
    required,
    listedItems,
    handleSearchChange,
    tableHeaders,
    tableFields,
    handleDelete
  } = props;
  const classes = useStyles();
  return (
    <ErrorBoundaries>
      <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
        <TableView
          data={listedItems}
          entityName="Task Entry"
          handleDelete={handleDelete}
          tableFields={tableFields}
          tableHeaders={tableHeaders}
          {...props}
        />
      </Grid>
      <div style={{ position: 'relative', paddingRight: required ? 60 : 0 }}>
        <Typography className={classes.inputLabel} gutterBottom variant="caption">
          {searchParams?.label || selectParams?.label || ''}
        </Typography>
        {required && (
          <Typography className={classes.requiredLabel} variant="caption">
            REQUIRED
          </Typography>
        )}
      </div>
      <Grid item lg={12} md={12} sm={12} styles={{ padding: 0 }} xl={12} xs={12}>
        {searchParams && (
          <AlgoliaSearch
            delimiter={searchParams.delimiter}
            displayText={searchParams.displayText}
            filters={searchParams.filters}
            handleChange={handleSearchChange}
            label=""
            name={searchParams.name}
            overrideVariant="outlined"
            placeholder={searchParams.placeholder}
            searchIndex={searchParams.searchIndex}
            style={{
              color: props.theme.palette.grayscale(90)
            }}
            value=""
          />
        )}
      </Grid>
    </ErrorBoundaries>
  );
}
const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const ReduxConnectedAddItemsFromSearch = connect(
  mapStateToProps,
  mapDispatcherToProps
)(withTheme(AddItemsFromSearch));

export default ReduxConnectedAddItemsFromSearch;
