import React from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CX from 'classnames';
import PropTypes from 'prop-types';
import { Calendar, createStaticRanges, DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Button, ThemeProvider } from '@BuildHero/sergeant';

import { anchorId, anchorOrigin, transformOrigin } from './constants';
import { getDefaultDate, getFormatedDate } from './helpers';
import useStyle from './styles';

const DatePicker = ({
  placeholder,
  classNames,
  labelVariant,
  IconElement,
  defaultValue,
  isDateRange,
  onChange,
  rangeFilters,
  CustomSelectorElement,
  showRangeFilter
}) => {
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(defaultValue);
  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleSelect = ({ selection }) => setSelectedDate(selection);
  React.useEffect(() => {
    setSelectedDate(defaultValue);
  }, [defaultValue]);

  const handleClose = () => {
    setSelectedDate(defaultValue);
    setAnchorEl(null);
  };

  const handleSuccess = () => {
    onChange(selectedDate);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? anchorId : undefined;

  const renderIcon = () => {
    if (IconElement) return IconElement;
    const Icon = open ? ExpandLess : ExpandMore;
    return <Icon color="primary" />;
  };

  const staticRanges = rangeFilters ? createStaticRanges([...rangeFilters]) : rangeFilters;

  return (
    <>
      <Box
        aria-describedby={id}
        className={CX(classes.container, classNames)}
        paddingX={2}
        onClick={handleClick}
      >
        {CustomSelectorElement && CustomSelectorElement}
        {!CustomSelectorElement && (
          <>
            <Typography className={classes.dateLabel} variant={labelVariant}>
              {getFormatedDate(isDateRange, selectedDate) || placeholder}
            </Typography>
            {renderIcon()}
          </>
        )}
      </Box>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        id={id}
        open={open}
        transformOrigin={transformOrigin}
        onClose={handleClose}
      >
        {!isDateRange ? (
          <Calendar
            className={classes.dateRangePicker}
            date={getDefaultDate(isDateRange, selectedDate)}
            onChange={date => setSelectedDate(date)}
          />
        ) : (
          <DateRangePicker
            className={CX(classes.dateRangePicker, { [classes.hideRangeFilter]: !showRangeFilter })}
            ranges={[getDefaultDate(isDateRange, selectedDate)].filter(Boolean)}
            staticRanges={staticRanges}
            onChange={handleSelect}
          />
        )}
        <Grid alignItems="flex-end" className={classes.actionWrapper} container justify="flex-end">
          <ThemeProvider>
            <Button className={classes.cancelButton} type="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleSuccess}>Ok</Button>
          </ThemeProvider>
        </Grid>
      </Popover>
    </>
  );
};

export default DatePicker;

DatePicker.propTypes = {
  isDateRange: PropTypes.bool,
  showRangeFilter: PropTypes.bool,
  IconElement: PropTypes.element,
  placeholder: PropTypes.string,
  labelVariant: PropTypes.string,
  classNames: PropTypes.object,
  defaultValue: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
  /**
   * @func onChange - when date range selected, cb func called with
   *  below object containing startDate and endDate - at least when this is a dateRange filter
   *  TODO - please extend documentation for non date-range
   * @param {Object} - see below for props
   * @prop {Date} startDate - Date obj representing selected start date
   * @prop {Date} endDate - Date obj representing selected end date
   */
  onChange: PropTypes.func.isRequired
};

DatePicker.defaultProps = {
  classNames: {},
  labelVariant: 'h3',
  isDateRange: false,
  showRangeFilter: true,
  placeholder: 'Select Date'
};
