import { employeeGQL } from 'customHooks/useEmployees';
import AmplifyService from 'services/AmplifyService';

import addFormDataToEntity from '../../graphql/common/mutations/addFormDataToEntity';
import addTag from '../../graphql/common/mutations/addTag';
import mutationQuery from '../../graphql/common/mutations/batchCreateEntityData';
import deleteAttachment from '../../graphql/common/mutations/deleteAttachment';
import deleteFormData from '../../graphql/common/mutations/deleteFormData';
import deleteNote from '../../graphql/common/mutations/deleteNote';
import deleteSystemEntityMap from '../../graphql/common/mutations/deleteSystemEntityMap';
import deleteTag from '../../graphql/common/mutations/deleteTag';
import deleteTagById from '../../graphql/common/mutations/deleteTagById';
import updateAttachment from '../../graphql/common/mutations/updateAttachment';
import updateFormData from '../../graphql/common/mutations/updateFormData';
import updateNote from '../../graphql/common/mutations/updateNote';
import getAlgoliaSecuredKey from '../../graphql/common/queries/getAlgoliaSecuredKey';
import getCompanyAttributes from '../../graphql/common/queries/getCompanyAttributes';
import getFormsAvailableToEntity from '../../graphql/common/queries/getFormsAvailableToEntity';
import getTextFromDocument from '../../graphql/common/queries/getTextFromDocument';
import getFormDataByJob from '../../graphql/jobs/queries/getFormDataByJob';
import getQuoteTasksByQuoteId from '../../graphql/quote/queries/getQuoteTasksByQuoteId';
import attachmentSchema from '../../mutation-schema/common/attachment-schema';
import deactivateSchema from '../../mutation-schema/common/deactivate-schema';
import noteMutationSchema from '../../mutation-schema/common/note-schema';
import noteWithRelationNameMutationSchema from '../../mutation-schema/common/noteWithRelationName-schema';

export default class CommonService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
  }

  getCompanyAttributesSync = async (partitionKey, sortKey, tenantPartitionKey, tenantSortKey) => {
    const params = {
      partitionKey,
      sortKey,
      tenantPartitionKey,
      tenantSortKey
    };

    try {
      return this.api.query(getCompanyAttributes, params);
    } catch (error) {
      throw new Error(error);
    }
  };

  mutateAttachment = async values => {
    const structuredValues = attachmentSchema(values);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };

    const response = await this.api.mutate(mutationQuery, dataSet);
    return response;
  };

  updateAttachment = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: {
        id: values.id,
        version: values.version,
        customFileName: values.customFileName || null,
        includeInInvoice: values.includeInInvoice || false,
        fileName: values.fileName || null,
        fileSize: values.fileSize || null,
        description: values.description || null,
        fileUrl: values.fileUrl || null,
        type: values.type || null,
        comment: values.comment || null,
        isInternalFile: values.isInternalFile || false,
        hideFromTechniciansOnMobile: values.hideFromTechniciansOnMobile || false
      }
    };

    const response = await this.api.mutate(updateAttachment, params);
    return response;
  };

  mutateNote = async values => {
    const structuredValues = noteMutationSchema(values);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };

    const response = await this.api.mutate(mutationQuery, dataSet);
    return response;
  };

  mutateNoteWithRelationName = async (values, parent, relationName) => {
    const structuredValues = noteWithRelationNameMutationSchema(values, parent, relationName);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };

    const response = await this.api.mutate(mutationQuery, dataSet);
    return response;
  };

  deleteSystemEntityMap = async (partitionKey, sortKey, invertedSortKey) => {
    const parentParams = {
      input: {
        partitionKey,
        sortKey
      }
    };
    const childParams = {
      input: {
        partitionKey,
        sortKey: invertedSortKey
      }
    };

    let childRecordResponse;
    const parentRecordResponse = await this.api.mutate(deleteSystemEntityMap, parentParams);
    if (parentRecordResponse) {
      childRecordResponse = await this.api.mutate(deleteSystemEntityMap, childParams);
    }

    return { ...parentRecordResponse, ...childRecordResponse };
  };

  deleteEntityMap = async (partitionKey, sortKey) => {
    const params = {
      input: {
        partitionKey,
        sortKey
      }
    };

    const response = await this.api.mutate(deleteSystemEntityMap, params);
    return response;
  };

  deleteTag = async (partitionKey, sortKey) => {
    const params = {
      input: {
        partitionKey,
        sortKey
      }
    };

    const response = await this.api.mutate(deleteTag, params);
    return response;
  };

  addTag = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(addTag, data);
    return response;
  };

  deleteTagById = async (partitionKey, value) => {
    const data = {
      partitionKey,
      id: value
    };

    const response = await this.api.mutate(deleteTagById, data);
    return response;
  };

  deleteAttachment = async (partitionKey, sortKey) => {
    const params = {
      input: {
        partitionKey,
        sortKey
      }
    };

    const response = await this.api.mutate(deleteAttachment, params);
    return response;
  };

  deleteNote = async (partitionKey, sortKey) => {
    const params = {
      input: {
        partitionKey,
        sortKey
      }
    };

    const response = await this.api.mutate(deleteNote, params);
    return response;
  };

  deleteTag = async (partitionKey, sortKey) => {
    const params = {
      input: {
        partitionKey,
        sortKey
      }
    };

    const response = await this.api.mutate(deleteTag, params);
    return response;
  };

  deactivateRecord = async customerRepRecord => {
    const structuredValues = deactivateSchema(customerRepRecord);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };

    const response = await this.api.mutate(mutationQuery, dataSet);
    return response;
  };

  getAlgoliaSecuredKey = async () => {
    const response = await this.api.query(getAlgoliaSecuredKey);
    return response;
  };

  getTextFromDocument = async imageUrl => {
    const params = {
      imageUrl
    };

    const response = await this.api.query(getTextFromDocument, params);
    return response;
  };

  getQuery = async (params, q) => {
    const response = await this.api.query(q, params);
    return response;
  };

  runMutation = async (params, m) => {
    const response = await this.api.mutate(m, params);
    return response;
  };

  addFormDataToEntity = async values => {
    const data = {
      formDefinitionSortKey: values.formDefinitionSortKey,
      parentId: values.parentId,
      parentEntityType: values.parentEntityType,
      tenantId: values.tenantId,
      tenantCompanyId: values.tenantCompanyId,
      formDataJson: values.formDataJson,
      formSortKey: values.formSortKey
    };

    const params = {
      partitionKey: values.tenantId,
      data
    };

    const response = await this.api.mutate(addFormDataToEntity, params);
    return response;
  };

  getFormsAvailableToEntity = async (partitionKey, sortKey, formType) => {
    const params = {
      partitionKey,
      sortKey,
      formType
    };

    const response = await this.api.query(getFormsAvailableToEntity, params);
    return response;
  };

  updateFormData = async values => {
    const { tenantId, ...data } = values;
    const params = {
      partitionKey: values.tenantId,
      data
    };

    const response = await this.api.mutate(updateFormData, params);
    return response;
  };

  deleteFormData = async (partitionKey, sortKey) => {
    const params = {
      input: {
        partitionKey,
        sortKey
      }
    };

    const response = await this.api.mutate(deleteFormData, params);
    return response;
  };

  getAllFormDataFromEntity = async (
    entityType,
    partitionKey,
    sortKey,
    filter,
    limit,
    offset,
    sortBy,
    sortOrder,
    quoteId
  ) => {
    const { stringFilters, ...rest } = filter || {};
    let customizedFilter = {
      stringFilters: []
    };

    let combinedStringFilterArr = customizedFilter.stringFilters;
    if (stringFilters) {
      combinedStringFilterArr = combinedStringFilterArr.concat(stringFilters);
    }
    customizedFilter.stringFilters = combinedStringFilterArr;

    if (rest) {
      customizedFilter = { ...customizedFilter, ...rest };
    }

    const params = {
      partitionKey,
      sortKey,
      filter: customizedFilter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'lastUpdatedDateTime',
          sortDirection: sortOrder || 'asc'
        }
      ]
    };

    let result;
    let response;
    switch (entityType) {
      case 'Job':
        response = await this.api.query(getFormDataByJob, params);
        if (response) {
          result = response.data.getJob?.formData;
          // eslint-disable-next-line no-unused-expressions
          response.data.getJob?.visits.items.forEach(visit => {
            result.items.push(...visit.formData.items);
          });
        }
        break;
      case 'Customer':
        // update Query
        response = await this.api.query(getFormDataByJob, params);
        if (response) result = response.data.getCustomer.formData;
        break;
      case 'Quote':
        // update Query
        response = await this.api.query(getFormDataByJob, params);
        if (response) result = response.data.getQuote.formData;
        break;
      case 'QuoteTasks': {
        response = await this.api.query(getQuoteTasksByQuoteId, { id: quoteId });
        const formData = (response?.data?.getQuoteById?.quoteLineTasks?.items || []).reduce(
          (acc, curr) => ({
            items: [
              ...acc.items,
              ...(curr?.task?.formData?.items || []).filter(item => item.formId)
            ]
          }),
          { items: [] }
        );
        if (response) result = formData;
        break;
      }
      default:
        console.log('No Entity specified');
    }

    const formattedResult = { items: [] };
    if (result.items && result.items.length) {
      result.items.forEach(item => {
        const { form } = item;
        if (form !== null && form?.viewType !== 'Inline') {
          try {
            const formDataJson = JSON.parse(item.formDataJson);
            const { name, description, formData } = formDataJson || {};
            formattedResult.items.push({
              id: item.id,
              name,
              description,
              formDataJson: formData,
              sortKey: item.sortKey,
              lastUpdatedDateTime: item.lastUpdatedDateTime,
              lastUpdatedBy: item.lastUpdatedBy,
              version: item.version
            });
          } catch (e) {
            console.warn('Issue parsing form data', e);
          }
        }
      });
    }

    if (result.nextToken) {
      formattedResult.nextToken = result.nextToken;
    }
    return formattedResult;
  };

  updateNote = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: {
        id: values.id,
        version: values.version || 1,
        note: values.note,
        subject: values.subject
      }
    };

    const response = await this.api.mutate(updateNote, params);
    return response;
  };

  // used in Quote Redesign and new Job page - can be removed if refactored to use hooks
  getEmployees = async params => {
    const response = await this.api.query(employeeGQL, params);
    return (
      response?.data?.getCompany?.employees?.items?.map(emp => ({
        ...emp,
        name: `${emp.firstName || ''} ${emp.lastName || ''}`
      })) || []
    );
  };
}
