import React, { useState } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DefaultButton from 'components/Buttons/DefaultButton';
import sidebarData from 'meta/ProjectManagement/RFI/rfiDetailSidebarMeta';
import { snackbarOn } from 'redux/actions/globalActions';
import DetailView from 'scenes/ProjectManagement/components/DetailView';
import { getEmployeeOrCustomerRep } from 'scenes/ProjectManagement/components/utils';
import { getCompanyBytenantId } from 'services/API/companies';
import { rfiChange } from 'services/API/rfi';
import { useGetRfiById } from 'services/APIHooks';
import { AppConstants, ProjectManagementRFIStatus } from 'utils/AppConstants';

import GenerateRFI from '../GenerateRFI';

import { useGetRFIPDFBlob } from './components/RFIPdf/RFIPdf.hook';

const RFIDetailView = props => {
  const { projectId, projectData, rfiId, snackbar, user, open, handleClose } = props;

  const [attachments, setAttachments] = useState();
  const [companyInfo, setCompanyInfo] = useState();
  const [sendToPerson, setSendToPerson] = useState();
  const [returnToPerson, setReturnToPerson] = useState();
  const [title, setTitle] = useState('RFI');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [{ data: rfiData }, refetchRfi] = useGetRfiById(
    rfiId,
    {
      depends: [rfiId],
      onSuccess: data => {
        setTitle(`RFI${data?.number ? ` ${data.number}` : ''}`);
        setAttachments(data?.RfiAttachment?.filter?.(element => !element._deleted) || []);
        // sendTo is the name of the column for the ID on the table. Need to make a migration for this in the future
        getEmployeeOrCustomerRep(data?.sendTo).then(sendTo => setSendToPerson(sendTo));
        getEmployeeOrCustomerRep(data?.from).then(returnTo => setReturnToPerson(returnTo));
        getCompanyBytenantId(user.tenantId).then(company =>
          setCompanyInfo(Array.isArray(company) ? company[0] : company)
        );
      }
    },
    { include: '*' }
  );

  const formatDataForSidebar = item => {
    if (!item) return {};
    return {
      ...item,
      sendToName: item.recipient?.name || null,
      fromName: item.sender?.name,
      dueDateText: item.dueDate ? moment.unix(item.dueDate).format(AppConstants.DATE_FORMAT) : null
    };
  };

  const handleCloseRFI = async () => {
    setIsSubmitting(true);
    await rfiChange(rfiId, { status: ProjectManagementRFIStatus.CLOSED }).then(() => {
      snackbar('success', 'RFI closed');
    });
    setIsSubmitting(false);
    handleClose();
  };

  const handleAttachmentUpload = allAttachments => {
    // NOTE: The PM Backend doesn't support removing relations when passed up in an update.
    // That means that deselecting attachments will NOT disassociate them from the RFI.
    rfiChange(rfiId, { RfiAttachment: allAttachments }).then(() => refetchRfi());
  };

  const handleEmailSent = async () => {
    if (rfiData?.status === ProjectManagementRFIStatus.DRAFT) {
      await rfiChange(rfiData?.id, {
        status: ProjectManagementRFIStatus.SENT,
        sentDate: moment().unix()
      });
      refetchRfi();
    }
  };

  const getRFIPDFBlob = useGetRFIPDFBlob({
    companyInfo,
    returnTo: returnToPerson,
    sendTo: sendToPerson,
    rfiData,
    attachments,
    projectData
  });

  return (
    <DetailView
      attachments={attachments}
      editData={{
        label: rfiData?.status === 'draft' ? 'EDIT' : 'REVISE',
        render: (isOpen, close) => (
          <GenerateRFI
            editMode
            handleClose={() => {
              refetchRfi();
              close();
            }}
            initialData={{ itemData: rfiData }}
            open={isOpen}
            projectData={projectData}
            projectId={projectId}
          />
        )
      }}
      emailData={{
        modalTitle: 'New RFI Email',
        body: `${sendToPerson?.name},<br/><br/>Please find the attached RFI ${rfiData?.number}.<br/><br/>${user?.displayName}`,
        subject: `${projectData?.name || null} RFI ${rfiData?.number}`
      }}
      getPDFBlob={getRFIPDFBlob}
      handleClose={handleClose}
      headerButtons={[
        rfiData.status !== 'draft' && rfiData.status !== 'closed' && (
          <DefaultButton
            disabled={isSubmitting}
            key="closeRFIButton"
            label="CLOSE RFI"
            showSpinner={isSubmitting}
            variant="containedPrimary"
            onClick={handleCloseRFI}
          />
        )
      ]}
      initialData={{ ...rfiData, sendTo: sendToPerson }}
      isSubmitting={isSubmitting}
      open={open}
      projectData={projectData}
      sidebarConfiguration={sidebarData}
      sidebarData={formatDataForSidebar(rfiData)}
      title={title}
      user={user}
      onAttachmentsChange={handleAttachmentUpload}
      onEmailSent={handleEmailSent}
    />
  );
};

RFIDetailView.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  projectData: PropTypes.object.isRequired,
  rfiId: PropTypes.string.isRequired,
  snackbar: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  projectData: state.pm.project
});

const mapDispatchToProps = dispatch => ({
  snackbar: (mode, message) => dispatch(snackbarOn(mode, message))
});

const ReduxConnectedRFIDetailView = connect(mapStateToProps, mapDispatchToProps)(RFIDetailView);
export default ReduxConnectedRFIDetailView;
