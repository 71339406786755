import React, { useMemo } from 'react';

import { ThemeProvider } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';

import WrapTable, { tableDateTimeFormatter } from 'components/WrapTable';

import { SectionHeader, VisitSubjectField } from '../Components';

const getColumns = ({ isMultiVisits }) =>
  [
    isMultiVisits && {
      field: 'visit',
      headerName: 'Visit',
      renderCell: ({ row }) => VisitSubjectField({ ...row.visit }),
      width: 200
    },
    { field: 'subject', headerName: 'Note Subject', width: 150 },
    { field: 'note', headerName: 'Note Content' },
    { field: 'lastEditedBy', headerName: 'Last Edited By', width: 200 },
    {
      field: 'lastEditedOn',
      headerName: 'Last Edited On',
      width: 200,
      valueFormatter: tableDateTimeFormatter
    }
  ].filter(Boolean);

const mapNotesToRows = notes =>
  notes
    .map(n => ({
      ...n,
      lastEditedBy: n.displayLastUpdatedBy ?? n.lastUpdatedBy,
      lastEditedOn: (n.displayLastUpdatedDateTime ?? n.lastUpdatedDate) * 1000
    }))
    .sort((a, b) => {
      if (a.visitNumber === b.visitNumber) return a.sortOrder - b.sortOrder;
      return a.visitNumber - b.visitNumber;
    });

const TechnicianNotesTable = ({ visits, isMultiVisits, isLoading }) => {
  const rows = useMemo(
    () =>
      mapNotesToRows(
        visits?.reduce(
          (notes, visit) => [
            ...notes,
            ...visit?.notes?.items.map(n => ({
              ...n,
              visit: {
                reviewStatus: visit.reviewStatus,
                visitNumber: parseInt(visit.visitNumber, 10),
                scheduledFor: visit.scheduledFor
              }
            }))
          ],
          []
        )
      ),
    [visits]
  );

  const columns = useMemo(() => getColumns({ isMultiVisits }), [isMultiVisits]);

  return (
    <ThemeProvider>
      <SectionHeader title="Technician Notes (Internal Use)" />
      <WrapTable
        columns={columns}
        hideFooter={rows.length < 11}
        loading={isLoading}
        loadingRows={3}
        noDataMessage="No Technician Notes"
        rows={rows}
      />
    </ThemeProvider>
  );
};

TechnicianNotesTable.defaultProps = {
  visits: [],
  isLoading: false
};

export default TechnicianNotesTable;
