/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

import { sentryException } from '../Logger/index';

export async function getPhaseDeptsByPhase(phaseId) {
  return await instance
    .get(`projectphasedepartment/byphase/${phaseId}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get Project Phase Departments');
      return [];
    });
}
export async function getPhaseDeptCostCodes(phaseDeptId) {
  return await instance
    .get(`projectphasedepartment/costcodes/${phaseDeptId}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get Project Phase Departments');
      return [];
    });
}

export async function phaseDepartmentChange(phaseDeptId, updatedData) {
  return await instance
    .put(`projectphasedepartment/${phaseDeptId}`, updatedData)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to change Project Phase Departments');
      return [];
    });
}

export async function phaseDepartmentCreate(newPhaseDept) {
  return await instance
    .post(`projectphasedepartment`, newPhaseDept)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to create Project Phase Departments');
      return [];
    });
}

export async function getPhaseDepartmentById(id) {
  return await instance
    .get(`projectphasedepartment/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get Project Phase');
      return [];
    });
}
