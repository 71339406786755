import { useSubscription } from '@apollo/client';
import gql from 'graphql-tag';

import { updateWatchedManDayVisits } from '@dispatch/Dispatch.utils';
import { manDayFragment } from '@dispatch/fragments/manDayFragment';
import { getState } from 'redux/store';

export const MAN_DAY_SUBSCRIPTION = gql`
  subscription manDayUpdateNotification($partitionKey: String!) {
    manDayUpdateNotification(partitionKey: $partitionKey) {
      ${manDayFragment}
    }
  }
`;

export const useManDaySubscription = () => {
  const { tenantId } = getState().user;
  return useSubscription(MAN_DAY_SUBSCRIPTION, {
    variables: { partitionKey: tenantId },
    onSubscriptionData: ({ client, subscriptionData }) => {
      updateWatchedManDayVisits(client.cache, subscriptionData.data.manDayUpdateNotification);
    }
  });
};
