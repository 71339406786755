import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { roundCurrency } from '@BuildHero/math';
import {
  CurrencyInput,
  Input,
  MoreButton,
  NumberInput,
  ThemeProvider,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';
import { css } from '@emotion/react';
import { useTheme } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { debounce, isEmpty, union } from 'lodash';

import InvoicesIcon from 'assets/Icons/Invoices';
import { ConfirmLeave } from 'components';
import WrapTable, { tableCurrencyFormatter, tablePercentageFormatter } from 'components/WrapTable';
import useEditPurchaseOrderLine from 'customHooks/useEditPurchaseOrderLine';
import usePricebookEntries from 'customHooks/usePricebookEntries';
import useTenantId from 'customHooks/useTenantId';
import { purchaseOrderReceiptChange } from 'services/API/purchaseOrderReceipt';
import { convertToCurrencyString } from 'utils';
import { LineItemBillingStatus, ProcurementPurchaseOrderStatus } from 'utils/AppConstants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import { getMarkupValue, getUnitPrice } from 'utils/onCalcChange';

import {
  EditableInputWrapper,
  InvoiceItemsEditedField,
  LineItemWithAsterisk,
  SectionHeader
} from '../../Components';
import PORecieptList from '../../PurchasedItemsTable/PORecieptList';

export const incompletePOStatuses = [
  ProcurementPurchaseOrderStatus.DRAFT,
  ProcurementPurchaseOrderStatus.ORDERED
];

const columns = ({
  onRowBlur,
  onDescriptionChange,
  onMarkupChange,
  onUnitPriceChange,
  onChangeBillingStatus,
  theme,
  hasProcurementUsage,
  setRowsEditingUnitPrice,
  rowIsLoading,
  setRowIsLoading,
  displayPricebookMarkup
}) =>
  [
    {
      field: 'itemName',
      headerName: 'Item Name',
      align: 'center',
      renderCell: ({ row }) => {
        if (row.itemNameAsteriskNeeded) {
          return <LineItemWithAsterisk content={row.itemName} />;
        }

        return (
          <Typography variant={TV.BASE} weight={TW.REGULAR}>
            {row.itemName}
          </Typography>
        );
      }
    },
    {
      field: 'description',
      headerName: 'Description',
      renderCell: ({ row }) => {
        if (row.billingStatus === LineItemBillingStatus.BILLED)
          return <Input defaultValue={row.description} disabled type="text" />;

        return (
          <Input
            defaultValue={row.description}
            disabled={row.readOnly}
            type="text"
            onBlur={e => {
              const { value } = e.target;
              if (value === row.description) return;

              const { id, version, isBillLine, poNumber, lineNumber } = row;
              onRowBlur({ id, version, description: value, isBillLine, poNumber, lineNumber });
            }}
            onChange={e => {
              const { value } = e.target;
              if (value === row.description) return;
              onDescriptionChange({ rowId: row.id });
            }}
          />
        );
      }
    },
    { field: 'poNumber', headerName: 'Purchase Order', width: 180, renderCell: PORecieptList },
    { field: 'recieved', headerName: 'Received', width: 100, align: 'center' },
    hasProcurementUsage && { field: 'used', headerName: 'Used', width: 60, align: 'center' },
    { field: 'unitOfMeasure', headerName: 'UOM', width: 70, align: 'center' },
    {
      field: 'unitCost',
      headerName: 'Unit Cost',
      align: 'center',
      width: 120,
      renderCell: ({ row }) => (
        <div css={{ textAlign: 'right' }}>{tableCurrencyFormatter({ value: row.unitCost })}</div>
      )
    },
    displayPricebookMarkup && {
      field: 'pricebookMarkup',
      headerName: 'Pricebook Markup (%)',
      align: 'center',
      width: 100,
      cellCss: ({ row }) => {
        if (row?.billingStatus === LineItemBillingStatus.DO_NOT_INVOICE)
          return { backgroundColor: theme.palette.divider };

        return {};
      },
      renderCell: ({ row }) => {
        if (row.billingStatus === LineItemBillingStatus.DO_NOT_INVOICE) return <></>;
        return (
          <div css={{ width: '100%', textAlign: 'right' }}>
            {tablePercentageFormatter({ value: row.pricebookMarkup })}
          </div>
        );
      }
    },
    {
      field: 'markup',
      headerName: 'Markup (%)',
      align: 'center',
      width: 100,
      cellCss: ({ row }) => {
        if (row?.billingStatus === LineItemBillingStatus.DO_NOT_INVOICE)
          return { backgroundColor: theme.palette.divider };

        return {};
      },
      renderCell: ({ row }) => {
        if (row.billingStatus === LineItemBillingStatus.DO_NOT_INVOICE) return <></>;

        if (row.billingStatus === LineItemBillingStatus.BILLED) {
          return (
            <div css={{ width: '100%', textAlign: 'right' }}>
              {tablePercentageFormatter({ value: row.markup })}
            </div>
          );
        }

        return (
          <EditableInputWrapper
            isMarkup
            row={row}
            rowIsLoading={rowIsLoading}
            setRowIsLoading={setRowIsLoading}
            setRowsEditingUnitPrice={setRowsEditingUnitPrice}
          >
            <NumberInput
              css={css`
                input.Mui-disabled {
                  color: black;
                }
              `}
              readOnly={row.readOnly}
              value={row.markup}
              onBlur={value => {
                const {
                  id,
                  version,
                  unitCost,
                  readOnly,
                  isBillLine,
                  poNumber,
                  lineNumber,
                  description
                } = row;
                if (readOnly) return;
                onRowBlur({
                  id,
                  version,
                  markup: value,
                  unitPrice: getUnitPrice(unitCost, value),
                  isBillLine,
                  poNumber,
                  unitCost,
                  lineNumber
                });
              }}
              onChange={value => {
                onMarkupChange({ rowId: row.id });
              }}
            />
          </EditableInputWrapper>
        );
      }
    },
    {
      field: 'unitPrice',
      headerName: 'Unit Price',
      align: 'center',
      width: 120,
      cellCss: ({ row }) => {
        if (row?.billingStatus === LineItemBillingStatus.DO_NOT_INVOICE)
          return { backgroundColor: theme.palette.divider };

        return {};
      },
      renderCell: ({ row }) => {
        if (row.billingStatus === LineItemBillingStatus.DO_NOT_INVOICE) return <></>;

        if (row.billingStatus === LineItemBillingStatus.BILLED) {
          return (
            <div css={{ textAlign: 'right' }}>
              {tableCurrencyFormatter({ value: row.unitPrice })}
            </div>
          );
        }

        return (
          <EditableInputWrapper
            isUnitPrice
            row={row}
            rowIsLoading={rowIsLoading}
            setRowIsLoading={setRowIsLoading}
            setRowsEditingUnitPrice={setRowsEditingUnitPrice}
          >
            <CurrencyInput
              css={css`
                input.Mui-disabled {
                  color: black;
                }
              `}
              readOnly={row.readOnly}
              value={row.unitPrice}
              onBlur={value => {
                const { id, version, unitCost, readOnly, isBillLine, poNumber, lineNumber } = row;
                if (readOnly) return;
                onRowBlur({
                  id,
                  version,
                  unitPrice: value,
                  markup: getMarkupValue(unitCost, value),
                  isBillLine,
                  poNumber,
                  lineNumber
                });
              }}
              onChange={value => {
                onUnitPriceChange({ rowId: row.id, value });
              }}
            />
          </EditableInputWrapper>
        );
      }
    },
    {
      field: 'invoiceQuantity',
      headerName: 'Invoice Quantity',
      align: 'center',
      width: 150,
      renderCell: ({ row }) => {
        if (row.billingStatus === LineItemBillingStatus.DO_NOT_INVOICE) return <></>;

        return row.invoiceQuantity;
      },
      cellCss: ({ row }) => {
        if (row?.billingStatus === LineItemBillingStatus.DO_NOT_INVOICE)
          return { backgroundColor: theme.palette.divider };

        return {};
      }
    },
    {
      field: 'subtotal',
      headerName: 'Subtotal',
      width: 100,
      align: 'center',
      valueFormatter: tableCurrencyFormatter,
      cellCss: ({ row }) => {
        if (row?.billingStatus === LineItemBillingStatus.DO_NOT_INVOICE)
          return { backgroundColor: theme.palette.divider };

        return {};
      },
      renderCell: ({ row, formattedValue }) => {
        if (row.billingStatus === LineItemBillingStatus.DO_NOT_INVOICE) return <></>;

        return (
          <Typography css={{ width: '100%', textAlign: 'right' }} numeric variant={TV.BASE}>
            {formattedValue}
          </Typography>
        );
      },
      totalGetter: ({ rows }) => {
        return rows.reduce((acc, r) => {
          if (r.billingStatus === LineItemBillingStatus.DO_NOT_INVOICE) return acc;
          return acc + parseFloat(r.subtotal);
        }, 0);
      },
      renderTotal: ({ formattedValue }) => (
        <Typography
          css={{ width: '100%', textAlign: 'right' }}
          numeric
          variant={TV.BASE}
          weight={TW.BOLD}
        >
          {convertToCurrencyString(formattedValue ?? 0)}
        </Typography>
      )
    },
    {
      field: 'actions',
      flex: 1,
      width: 32,
      headerName: '',
      noPadding: true,
      align: 'center',
      justify: 'center',
      renderCell: ({ row }) => {
        const { id, version, billingStatus, readOnly, isBillLine, poNumber, lineNumber } = row;
        if (readOnly || billingStatus === LineItemBillingStatus.BILLED) return <></>;

        if (billingStatus === LineItemBillingStatus.DO_NOT_INVOICE) {
          return (
            <MoreButton
              options={[
                {
                  label: 'Include in Invoice',
                  icon: InvoicesIcon,
                  onClick: () =>
                    onChangeBillingStatus({
                      id,
                      version,
                      billingStatus: LineItemBillingStatus.NOT_INVOICED,
                      isBillLine,
                      poNumber,
                      lineNumber
                    })
                }
              ]}
            />
          );
        }

        return (
          <MoreButton
            options={[
              {
                label: 'Do Not Invoice',
                icon: BlockIcon,
                onClick: () =>
                  onChangeBillingStatus({
                    id,
                    version,
                    billingStatus: LineItemBillingStatus.DO_NOT_INVOICE,
                    isBillLine,
                    poNumber,
                    lineNumber
                  })
              }
            ]}
          />
        );
      }
    }
  ].filter(Boolean);

const relevantBillLines = ({ purchaseOrderLine, billLines }) =>
  billLines.filter(bl => bl.purchaseOrderLine?.id === purchaseOrderLine.id);

const getInvoiceQuantity = ({ hasProcurementUsage, used, fulfilled, invoiceLine }) => {
  if (invoiceLine) return invoiceLine.quantity;

  // @TODO if there is procurement usage we may want to set invoice quantity to "used",
  // but, right now this is not supported by invoice creation logic.
  // if (hasProcurementUsage) return used; @TODO

  return fulfilled;
};

const JobCloseoutPurchasedItemsTableTAndM = ({
  purchaseOrderReceiptLines,
  purchaseOrderLines,
  purchaseOrders,
  priceBookId,
  billLines,
  invoices,
  hideInvoiced,
  isLoading,
  refetchJob
}) => {
  const flags = useFlags();
  const theme = useTheme();
  const tenantId = useTenantId();

  const [displayUnitPriceMap, setDisplayUnitPriceMap] = useState({});
  const [hasTempDataQueue, setHasTempDataQueue] = useState([]);
  const [rowsEditingUnitPrice, setRowsEditingUnitPrice] = useState([]);
  const [rowIsLoading, setRowIsLoading] = useState([]);
  const [updatedUnitPrice, setUpdatedUnitPrice] = useState({});
  const [updatedMarkup, setUpdatedMarkup] = useState({});

  const hasProcurementUsage = flags[FeatureFlags.PROCUREMENT_USAGE];
  const displayPricebookMarkup = flags[FeatureFlags.DISPLAY_PRICEBOOK_MARKUP];
  const lonelyBillLines = useMemo(
    () => (isLoading ? [] : billLines.filter(bl => !bl.purchaseOrderLine)),
    [billLines, isLoading]
  );

  const invoiceLines = useMemo(
    () =>
      isLoading
        ? []
        : invoices.items.reduce((lines, invoice) => {
            return [...lines, ...invoice.invoiceItems.items];
          }, []),
    [invoices, isLoading]
  );

  const [pricebookEntries] = usePricebookEntries({
    pricebookId: priceBookId,
    productSortKeys: isLoading
      ? []
      : [
          ...purchaseOrderLines.map(pol => pol.product.sortKey),
          ...lonelyBillLines.map(bl => bl.product.sortKey)
        ]
  });

  const [updatePurchaseOrderLineMap, setUpdatePurchaseOrderLineMap] = useState({});
  const [updateBillLinesMap, setUpdateBillLinesMap] = useState({});
  const updateTableField = async newData => {
    if (!newData?.isBillLine) {
      setUpdatePurchaseOrderLineMap(oldMap => ({
        ...oldMap,
        [newData.id]: {
          ...(oldMap[newData.id] ?? {}),
          ...newData,
          version: oldMap[newData.id]?.version ?? newData.version
        }
      }));
    } else {
      const poReceiptId = newData.poNumber.receipts[0].id;
      if (isEmpty(updateBillLinesMap[poReceiptId])) {
        const receiptLinesToUpdate = purchaseOrderReceiptLines
          .map(receipt => {
            if (
              newData.lineNumber === receipt.lineNumber &&
              poReceiptId === receipt.purchaseOrderReceiptId
            ) {
              return {
                ...receipt,
                ...(newData?.unitPrice && { unitPrice: newData?.unitPrice }),
                ...(newData?.description && { description: newData?.description }),
                ...(newData?.billingStatus && { billingStatus: newData?.billingStatus }),
                version: newData.version + 1
              };
            }
            return receipt;
          })
          .filter(receiptLine => poReceiptId === receiptLine.purchaseOrderReceiptId);
        setUpdateBillLinesMap(oldMap => ({
          ...oldMap,
          [poReceiptId]: receiptLinesToUpdate
        }));
      } else {
        setUpdateBillLinesMap(oldMap => ({
          ...oldMap,
          [poReceiptId]: [
            ...oldMap[poReceiptId].map(receipt => {
              if (
                newData.lineNumber === receipt.lineNumber &&
                poReceiptId === receipt.purchaseOrderReceiptId
              ) {
                return {
                  ...receipt,
                  ...(newData?.unitPrice && { unitPrice: newData?.unitPrice }),
                  ...(newData?.description && { description: newData?.description }),
                  ...(newData?.billingStatus && { billingStatus: newData?.billingStatus }),
                  version: newData.version + 1
                };
              }
              return receipt;
            })
          ]
        }));
      }
      setRowIsLoading(prev => {
        if (prev.length > 0) {
          prev.shift();
        }
        return prev;
      });
      if (newData?.unitPrice) {
        setUpdatedUnitPrice(prev => ({
          ...prev,
          [newData.id]: newData.unitPrice
        }));
      }
      if (newData?.markup) {
        setUpdatedMarkup(prev => ({
          ...prev,
          [newData.id]: newData.markup
        }));
      }
      setHasTempDataQueue(oldQueue => {
        const newQueue = [...oldQueue];
        newQueue.shift();
        return newQueue;
      });
    }
  };

  const [
    editPurchaseOrderLineMutation,
    { loading: updatingPurchaseOrderLine }
  ] = useEditPurchaseOrderLine({ setHasTempDataQueue });

  const updatePurchaseOrderLines = useCallback(
    debounce(async ({ map, setMap }) => {
      setMap(oldMap => {
        const newMap = {};
        Object.keys(oldMap).forEach(key => {
          const { version, ...rest } = oldMap[key];
          if (!isEmpty(rest)) {
            newMap[key] = { version: version + 1 };
          }
        });
        return newMap;
      });
      const promises = [];
      Object.keys(map).forEach(key => {
        promises.push(
          editPurchaseOrderLineMutation({
            tenantId,
            purchaseOrderLine: { ...map[key] }
          })
        );
      });
      await Promise.all(promises);
    }, 1000),
    [tenantId]
  );

  const updateBillLines = useCallback(
    debounce(async ({ map, setMap }) => {
      setMap({});
      const promises = [];
      Object.keys(map).forEach(async key => {
        promises.push(
          purchaseOrderReceiptChange(key, {
            PurchaseOrderReceiptLine: map[key]
          })
        );
      });
      await Promise.all(promises);
      refetchJob();
    }, 2000),
    [tenantId]
  );

  const emptyPurchaseOrders = useMemo(
    () => (isLoading ? [] : purchaseOrders?.filter(po => po.purchaseOrderLines.items.length === 0)),
    [isLoading, purchaseOrders]
  );

  useEffect(() => {
    if (!isEmpty(updatePurchaseOrderLineMap)) {
      const relevantMap = {};
      Object.keys(updatePurchaseOrderLineMap).forEach(key => {
        const { version, ...rest } = updatePurchaseOrderLineMap[key];
        if (!isEmpty(rest)) {
          relevantMap[key] = { ...updatePurchaseOrderLineMap[key] };
        }
      });

      if (!isEmpty(relevantMap)) {
        updatePurchaseOrderLines({
          map: relevantMap,
          setMap: setUpdatePurchaseOrderLineMap
        });
      }
    }
  }, [updatePurchaseOrderLineMap]);

  useEffect(() => {
    if (!isEmpty(updateBillLinesMap)) {
      const relevantMap = {};
      Object.keys(updateBillLinesMap).forEach(key => {
        if (!isEmpty(updateBillLinesMap[key])) {
          relevantMap[key] = updateBillLinesMap[key];
        }
      });

      if (!isEmpty(relevantMap)) {
        updateBillLines({
          map: relevantMap,
          setMap: setUpdateBillLinesMap
        });
      }
    }
  }, [updateBillLinesMap]);

  useEffect(() => {
    if (!updatingPurchaseOrderLine) {
      setRowIsLoading(prev => {
        if (prev.length > 0) {
          prev.shift();
        }
        return prev;
      });
    }
  }, [updatingPurchaseOrderLine]);

  const rows = useMemo(() => {
    const result = [];
    if (isLoading) return result;

    purchaseOrderLines.forEach(l => {
      const pricebookMarkup = pricebookEntries.find(e => e.productSortKey === l.product.sortKey)
        ?.markupValue;

      const markup = l.markup ?? pricebookMarkup;

      const used = l.purchaseOrderLineVisitList.items.reduce(
        (totalUsed, { quantityUsed }) => totalUsed + quantityUsed,
        0
      );

      // markup may de-sync from unitCost & unitPrice if:
      // - an explicit unitPrice or Markup is defined for the line and then the unitCost on a receipt is changed.
      // - the line is added to an invoice and then the unitCost on a receipt is changed.

      const invoiceLine = invoiceLines.find(il => il.sourceLineItemId === l.id);

      const itemNameAsteriskNeeded = invoiceLine
        ? invoiceLine?.quantity !== l?.quantity || invoiceLine?.unitCost !== l?.unitCost
        : false;

      const markupNeedsToBeRecalculated =
        invoiceLine ||
        (typeof l.unitPrice === 'number' &&
          Math.abs(markup - getMarkupValue(l.unitCost, l.unitPrice)) > 0.01);

      const unitPrice =
        invoiceLine?.unitPrice ??
        displayUnitPriceMap[l.id] ??
        l.unitPrice ??
        getUnitPrice(l.unitCost, markup);

      const invoiceQuantity = getInvoiceQuantity({
        hasProcurementUsage,
        used,
        fulfilled: l.quantityFulfilled,
        invoiceLine
      });

      const unitCost = invoiceLine?.unitCost ?? l.unitCost;
      const recalculatedMarkup = markupNeedsToBeRecalculated
        ? getMarkupValue(unitCost, unitPrice)
        : markup;

      const subtotal = invoiceLine
        ? invoiceLine.quantity * invoiceLine.unitPrice
        : roundCurrency(
            String(
              relevantBillLines({ purchaseOrderLine: l, billLines }).reduce(
                (acc, bl) => acc + bl.quantity * getUnitPrice(bl.unitCost, recalculatedMarkup),
                0
              )
            )
          );

      result.push({
        id: l.id,
        version: l.version,
        itemName: l.itemName,
        itemNameAsteriskNeeded,
        description: l.description,
        sortOrder:
          l.purchaseOrderReceiptLines?.items[0]?.purchaseOrderReceipt.receiptNumber + l.itemName ||
          '999999',
        poNumber: {
          label: `PO ${l.purchaseOrder?.poNumber}`,
          id: l.purchaseOrder?.id,
          receipts:
            l.purchaseOrderReceiptLines?.items?.map(rl => ({
              id: rl.purchaseOrderReceipt?.id,
              label: `Receipt ${rl.purchaseOrderReceipt.receiptNumber}`
            })) || [],
          poStatus: l.purchaseOrder.status
        },
        recieved: incompletePOStatuses.includes(l.purchaseOrder.status)
          ? '-'
          : `${l.quantityFulfilled} of ${l.quantity}`,
        used: incompletePOStatuses.includes(l.purchaseOrder.status) ? '-' : used,
        unitOfMeasure: l.unitOfMeasure || '-',
        unitCost,
        markup: markupNeedsToBeRecalculated ? recalculatedMarkup : markup,
        pricebookMarkup,
        unitPrice: roundCurrency(String(unitPrice)),
        invoiceQuantity,
        subtotal: roundCurrency(String(subtotal)),
        billingStatus: l.billingStatus,
        unitPriceFocus: rowsEditingUnitPrice.includes(l.id),
        rowsEditingUnitPrice,
        invoiced: !!invoiceLine
      });
    });

    emptyPurchaseOrders.forEach(po => {
      result.push({
        id: po.id,
        itemName: '-',
        itemNameAsteriskNeeded: false,
        description: '-',
        sortOrder: '999999',
        poNumber: {
          label: `PO ${po.poNumber}`,
          id: po.id,
          receipts: [],
          poStatus: po.status
        },
        recieved: '-',
        used: '-',
        unitOfMeasure: '-',
        subtotal: 0,
        readOnly: true,
        invoiced: false
      });
    });

    lonelyBillLines.forEach(bl => {
      const pricebookMarkup = pricebookEntries.find(e => e.productSortKey === bl.product.sortKey)
        ?.markupValue;

      const invoiceLine = invoiceLines.find(il => il.sourceLineItemId === bl.id);

      const markup =
        updatedMarkup?.[bl.id] ??
        invoiceLine?.markupValue ??
        (bl.unitPrice || bl.unitPrice === 0
          ? getMarkupValue(bl.unitCost, bl.unitPrice)
          : pricebookMarkup);

      const itemNameAsteriskNeeded = invoiceLine
        ? invoiceLine?.quantity !== bl?.quantity || invoiceLine?.unitCost !== bl?.unitCost
        : false;

      const unitPrice =
        invoiceLine?.unitPrice ??
        updatedUnitPrice?.[bl.id] ??
        bl.unitPrice ??
        getUnitPrice(bl.unitCost, markup);

      const unitCost = invoiceLine?.unitCost ?? bl.unitCost;

      const invoiceQuantity = getInvoiceQuantity({
        hasProcurementUsage,
        used: 0,
        fulfilled: bl.quantity,
        invoiceLine
      });

      const subtotal = invoiceLine
        ? unitPrice * invoiceQuantity
        : roundCurrency(String(bl.quantity * getUnitPrice(bl.unitCost, markup)));

      result.push({
        id: bl.id,
        itemName: bl.product.name,
        itemNameAsteriskNeeded,
        description: bl.description,
        sortOrder: bl.bill.purchaseOrderReceipt.receiptNumber + bl.product.name,
        poNumber: {
          label: 'Receipt Item Only',
          id: null,
          receipts: [
            {
              id: bl.bill.purchaseOrderReceipt.id,
              label: `Receipt ${bl.bill.purchaseOrderReceipt.receiptNumber}`
            }
          ]
        },
        recieved: `${bl.quantity} of ${bl.quantity}`,
        used: '-',
        unitOfMeasure: bl.product.unitOfMeasure?.name || '-',
        unitCost,
        markup,
        pricebookMarkup,
        unitPrice: roundCurrency(String(unitPrice)),
        invoiceQuantity,
        subtotal: roundCurrency(String(subtotal)),
        invoiced: !!invoiceLine,
        version: bl.version,
        unitPriceFocus: rowsEditingUnitPrice.includes(bl.id),
        rowsEditingUnitPrice,
        isBillLine: true,
        lineNumber: bl.lineNumber,
        billingStatus: bl.billingStatus
      });
    });

    return result
      .sort((a, b) => {
        if (a.sortOrder < b.sortOrder) return -1;
        if (a.sortOrder > b.sortOrder) return 1;
        return 0;
      })
      .filter(row => !hideInvoiced || !row.invoiced);
  }, [
    purchaseOrderLines,
    emptyPurchaseOrders,
    pricebookEntries,
    displayUnitPriceMap,
    lonelyBillLines,
    rowsEditingUnitPrice,
    invoiceLines,
    hideInvoiced,
    billLines,
    hasProcurementUsage,
    updatedUnitPrice,
    updatedMarkup
  ]);

  return (
    <div>
      <ThemeProvider>
        <SectionHeader title="Purchased Items" />
        <WrapTable
          columns={columns({
            onDescriptionChange: ({ rowId }) => {
              setHasTempDataQueue(oldQueue => union(oldQueue, [`${rowId}-description`]));
            },
            onRowBlur: updateTableField,
            onMarkupChange: ({ rowId }) => {
              // clear the unit price display value so that it updates after markup updates onBlur
              setDisplayUnitPriceMap(oldUnitPriceMap => {
                const { [rowId]: _, ...rest } = oldUnitPriceMap;
                return rest;
              });
              setHasTempDataQueue(oldQueue => union(oldQueue, [`${rowId}-markup`]));
            },
            onUnitPriceChange: ({ rowId, value }) => {
              // need to set the display value otherwise it reverts to original for 1s before the mutation first.
              setDisplayUnitPriceMap(oldUnitPriceMap => ({ ...oldUnitPriceMap, [rowId]: value }));
              setHasTempDataQueue(oldQueue => union(oldQueue, [`${rowId}-unitprice`]));
            },
            onChangeBillingStatus: updateTableField,
            theme,
            hasProcurementUsage,
            setRowsEditingUnitPrice,
            rowsEditingUnitPrice,
            updatingPurchaseOrderLine,
            rowIsLoading,
            setRowIsLoading,
            updatedMarkup,
            updatedUnitPrice,
            displayPricebookMarkup
          })}
          enableTotalsRow
          hideFooter={rows.length < 11}
          loading={isLoading}
          loadingRows={3}
          noDataMessage="No Purchased Items"
          rows={rows}
        />
        {rows?.some(({ itemNameAsteriskNeeded }) => itemNameAsteriskNeeded === true) && (
          <InvoiceItemsEditedField />
        )}
      </ThemeProvider>
      <ConfirmLeave when={updatingPurchaseOrderLine || hasTempDataQueue.length} />
    </div>
  );
};

JobCloseoutPurchasedItemsTableTAndM.defaultProps = {
  isLoading: false
};

export default JobCloseoutPurchasedItemsTableTAndM;
