import moment from 'moment';

import { removeEmptyValues, removeNestedJson, removeNullValues } from '../../utils';
import InstructionHelper from '../InstructionHelper';

const updateTimeSheetSchemaMapping = schedule => {
  // added while processing query result
  const processedSchedule = removeEmptyValues(removeNullValues(schedule));
  const strippedSchedule = removeNestedJson(processedSchedule);
  const { timeSheets } = schedule;
  let unClosedTimeSheet;

  timeSheets &&
    timeSheets.items &&
    timeSheets.items.forEach(timesheet => {
      if (!timesheet.clockOutTime) {
        unClosedTimeSheet = removeNullValues(timesheet);
      }
    });

  unClosedTimeSheet = { ...unClosedTimeSheet, clockOutTime: moment().unix() };

  const data = [{ ...strippedSchedule }, { ...unClosedTimeSheet }];
  const ins = new InstructionHelper();
  const temp = ins.oneToMany(0, 1);

  const { instructionSet } = temp;
  const ret = { data: JSON.stringify(data), instructionSet: JSON.stringify(instructionSet) };
  return ret;
};

export default updateTimeSheetSchemaMapping;
