import React from 'react';

import { Typography } from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';
import { any } from 'prop-types';

import { convertToCurrencyString } from 'utils';

import { useStyles } from './SubTotalCell.styles';

const SubTotalCell = ({ value }) => {
  const classes = useStyles();
  const displayValue = value ? convertToCurrencyString(value) : '-';
  return (
    <Box css={classes.container}>
      <Typography>{displayValue}</Typography>
    </Box>
  );
};

SubTotalCell.propTypes = {
  value: any.isRequired
};

export default SubTotalCell;
