import { connect } from 'react-redux';

import { snackbarOn } from 'redux/actions/globalActions';
import { refetchProjectDetails } from 'redux/actions/projectManagementActions';

import PayApplicationsList from './PayApplicationList.component';

const mapStateToProps = (state, props) => ({
  user: state.user,
  project: props.project || state.pm.project,
  mode: props.mode || props.match?.params?.mode,
  projectId: props.projectId || props.match?.params?.projectId,
  payApplicationId: props.payApplicationId || props.match?.params?.subpage
});

const mapDispatcherToProps = {
  snackbar: snackbarOn,
  refetchProjectDetails
};

export default connect(mapStateToProps, mapDispatcherToProps)(PayApplicationsList);
