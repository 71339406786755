import gql from 'graphql-tag';
import { useSelector } from 'react-redux';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const ADD_ASSET_TYPE = gql`
  mutation saveAssetTypeToCompany($partitionKey: String!, $data: AddAssetTypeToCompanyInput!) {
    saveAssetTypeToCompany(partitionKey: $partitionKey, data: $data) {
      id
      version
      tagName
      sortOrder
    }
  }
`;

export const useAddAssetTypeToCompany = () => {
  const user = useSelector(state => state.user);
  return useExtendedMutation(ADD_ASSET_TYPE, {
    serializer: ({ assetType, sortOrder }) => ({
      variables: {
        partitionKey: user.tenantId,
        data: {
          companyId: user.tenantCompanyId,
          assetType: [
            {
              tagName: assetType,
              sortOrder
            }
          ]
        }
      }
    }),
    onSuccess: (transformedData, snackbarOn) =>
      snackbarOn('success', 'Successfully added asset type')
  });
};
