import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { inventoryFragment } from 'customHooks/useJob';

const ADD_INVENTORY_PARTS_TO_VISIT = gql`
  mutation addInventoryPartsToVisit($partitionKey: String!, $data: AddInventoryPartsToVisitInput!) {
    addInventoryPartsToVisit(partitionKey: $partitionKey, data: $data) {
      ${inventoryFragment}
    }
  }
`;

const serializer = ({ tenantId, visitId, inventoryParts }) => ({
  variables: {
    partitionKey: tenantId,
    data: {
      visitId,
      inventoryParts
    }
  }
});

const update = (cache, { data: { addInventoryPartsToVisit } }, ref) => {
  cache.modify({
    id: `Visit:${ref.visitId}`,
    fields: {
      inventoryParts(existingInventoryPartsRef) {
        const newRefs = addInventoryPartsToVisit.map(inventoryPart => {
          return {
            __ref: `${inventoryPart.__typename}:${inventoryPart.id}`
          };
        });

        return {
          items: [...existingInventoryPartsRef.items, ...newRefs]
        };
      }
    }
  });
};

export const useAddInventoryPartsToVisit = () => {
  return useExtendedMutation(ADD_INVENTORY_PARTS_TO_VISIT, {
    serializer,
    update
  });
};
