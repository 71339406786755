import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { UserPermission } from 'components';
import { PermissionConstants } from 'utils/AppConstants';

class Home extends Component {
  render() {
    const redirectToCompany = this.props.user.cognitoRole === 'TenantAdmin';
    const redirectToTenants = this.props.user.cognitoRole === 'SystemAdmin';

    if (!this.props.user.isAuthenticated) {
      return <></>;
    }

    if (redirectToTenants) {
      return <Redirect to="/admin/tenant" />;
    }

    if (redirectToCompany) {
      return (
        <UserPermission action={PermissionConstants.MENU_SETTINGS} I="read">
          <Redirect to="/settings/company" />
        </UserPermission>
      );
    }

    return (
      <>
        <UserPermission action={PermissionConstants.OBJECT_CUSTOMER} I="read">
          <Redirect to="/customer/list" />
        </UserPermission>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps, null)(Home);
