import React, { useMemo } from 'react';

import { Checkbox } from '@BuildHero/sergeant';
import { DeleteOutlined, EditOutlined } from '@material-ui/icons';

import { actionButtonColumn } from 'components/WrapTable';

import { lineItemColumns } from '../../../../../PartsAndMaterialsSection/components/PurchasedItems/components/PurchasedLineItemsTable/PurchasedLineItemsTable.component';

export const usePurchaseOrderLinesColumns = ({
  disabled,
  updating,
  onEditPurchaseOrderLine,
  onDeletePurchaseOrderLine,
  onAddPurchaseOrderLineToInvoice,
  isPurchaseOrderLineAddedToInvoice
}) =>
  useMemo(
    () => [
      {
        field: 'parentId',
        hide: true
      },
      {
        field: 'action',
        align: 'center',
        width: 70,
        // eslint-disable-next-line react/prop-types
        renderCell: ({ row }) => (
          <Checkbox
            // eslint-disable-next-line react/prop-types
            checked={isPurchaseOrderLineAddedToInvoice(row)}
            disabled={disabled || updating}
            onChange={() => onAddPurchaseOrderLineToInvoice(row)}
          />
        )
      },
      ...lineItemColumns,
      actionButtonColumn({
        actions: [
          {
            icon: EditOutlined,
            label: 'Edit',
            onClick: onEditPurchaseOrderLine,
            disabled: disabled || updating
          },
          {
            icon: DeleteOutlined,
            label: 'Delete',
            onClick: onDeletePurchaseOrderLine,
            disabled: disabled || updating
          }
        ]
      })
    ],
    [
      disabled,
      updating,
      onEditPurchaseOrderLine,
      onDeletePurchaseOrderLine,
      onAddPurchaseOrderLineToInvoice,
      isPurchaseOrderLineAddedToInvoice
    ]
  );
