import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';

import SectionHeader from 'components/SectionHeader';
import Spinner from 'components/Spinners/CircularIndeterminate';
import { selectFormTable } from 'meta/Settings/Forms';
import { snackbarOn } from 'redux/actions/globalActions';
import { getPublishedProjectForms } from 'services/API/form';
import { formDataCreate } from 'services/API/formData';
import { CommonService, CompanyService } from 'services/core';
import { Logger } from 'services/Logger';

import Table from '../../Table';
import FormViewer from '../FormViewer';

class FormSelector extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.CommonService = new CommonService();
    this.CompanyService = new CompanyService();
    this.state = {
      publishedFormsList: [],
      isListLoading: true,
      formViewerRecord: '',
      isSaving: false
    };
  }

  componentDidMount() {
    this.queryPublishedForms();
  }

  queryPublishedForms = async () => {
    const sortKey = `${this.props.user.tenantId}_Company_${this.props.user.tenantCompanyId}`;
    const { skipMutation, noGQL } = this.props;
    try {
      let data;
      if (skipMutation) {
        const resp = await this.CompanyService.getCompanyWithForms(
          this.props.user.tenantId,
          sortKey
        );
        data = resp.data;
      } else if (noGQL) {
        data = await getPublishedProjectForms();
        if (data) {
          this.setState({ publishedFormsList: data, isListLoading: false });
          return data;
        }
      } else {
        const resp = await this.CommonService.getFormsAvailableToEntity(
          this.props.user.tenantId,
          sortKey,
          this.props.parent.entityType
        );
        data = resp.data;
      }
      if (data) {
        const processedData = this.processQueryData(data);
        this.setState({ publishedFormsList: processedData.items, isListLoading: false });
        return processedData;
      }
    } catch (error) {
      Logger.error(`Error in fetching forms ${JSON.stringify(error)}`);
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        this.props.snackbarOn('error', error.graphQLErrors[0].message);
      } else {
        this.props.snackbarOn('error', 'Unable to fetch forms, please try again later');
      }
    }
    return true;
  };

  onSubmitFormData = (formMetaData, editedDescription) => {
    const {
      sortKey,
      name,
      description,
      latestPublishedFormDefinitionSortKey
    } = this.state.formViewerRecord;
    const formDataJson = {
      name,
      description: editedDescription || description,
      formData: { ...formMetaData }
    };
    const { meta, searchableFields } = formMetaData;
    const formData = {};
    if (searchableFields) {
      Object.keys(searchableFields).forEach(dataId => {
        const mappingId = searchableFields[dataId];
        const dataValue = meta[1][dataId];
        if (dataValue) formData[mappingId] = meta[1][dataId];
      });
    }
    const formPayload = {
      formSortKey: sortKey,
      formDefinitionSortKey: latestPublishedFormDefinitionSortKey,
      formDataJson: JSON.stringify(formDataJson)
    };
    this.setState({ isSaving: true });
    this.addFormDataToEntity({ ...formPayload, ...formData });
  };

  addFormDataToEntity = async formData => {
    const { parent, user, callback, noGQL, projectId, skipMutation } = this.props;
    const { tenantId, tenantCompanyId } = user;
    let serviceIDs;

    if (projectId) {
      serviceIDs = {
        parentId: projectId,
        formId: formData.formSortKey.slice(-36),
        formDefinitionId: formData.formDefinitionSortKey.slice(-36),
        tenantId,
        tenantCompanyId
      };
    } else if (skipMutation) {
      serviceIDs = {
        formId: formData.formSortKey.slice(-36),
        formSortKey: formData.formSortKey
      };
    } else {
      serviceIDs = {
        parentId: parent?.id,
        parentEntityType: parent?.entityType,
        tenantId,
        tenantCompanyId
      };
    }

    const payload = { ...serviceIDs, ...formData };
    let data;
    try {
      if (skipMutation) {
        data = payload;
      } else if (noGQL) {
        data = await formDataCreate(payload);
      } else {
        const resp = await this.CommonService.addFormDataToEntity(payload);
        data = resp.data;
      }
      if (data) {
        this.props.snackbarOn('success', 'Successfully added form.');
        if (callback) callback(data);
      }
      this.setState({ isSaving: false });
    } catch (error) {
      Logger.error(`Error in fetching forms ${JSON.stringify(error)}`);
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        this.props.snackbarOn('error', error.graphQLErrors[0].message);
      } else {
        this.props.snackbarOn('error', 'Unable to create forms, please try again later');
      }
      this.setState({ isSaving: false });
    }
  };

  processQueryData = data => {
    if (!data.getCompany) return;
    const { forms, ...rest } = data.getCompany;
    const { nextToken } = forms;
    const publishedList = forms.items.filter(
      item => item.latestPublishedFormDefinition && item.viewType !== 'Inline'
    );

    publishedList.map(item => {
      const { latestPublishedFormDefinition } = item;
      const form = item;
      form.lastUpdatedTime = latestPublishedFormDefinition.lastUpdatedDateTime;
      form.parent = rest;
      return form;
    });

    // eslint-disable-next-line consistent-return
    return { nextToken, items: publishedList };
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleRowActions = async (mode, record) => {
    if (mode === 'select' || mode === 'view') {
      this.setState({ formViewerRecord: record });
    }
  };

  render() {
    const { caslKey, handleCancel, user, parent } = this.props;
    const { formViewerRecord, publishedFormsList, isListLoading, isSaving } = this.state;
    let formViewerContent;
    if (formViewerRecord) {
      const { formDefinitionJson } = formViewerRecord.latestPublishedFormDefinition;
      formViewerContent = JSON.parse(formDefinitionJson);
    }

    return (
      <Grid>
        {formViewerContent ? (
          <FormViewer
            description={formViewerRecord.description}
            formMetaData={formViewerContent}
            handleClose={() => handleCancel()}
            isSaving={isSaving}
            name={formViewerRecord.name}
            parent={parent}
            user={user}
            onSubmit={this.onSubmitFormData}
          />
        ) : (
          <Grid item sm={12}>
            <SectionHeader title="Select a form" />
            {isListLoading ? (
              <Spinner />
            ) : (
              <Table
                captionAttributes={selectFormTable.captionAttributes}
                caslKey={caslKey}
                dataPathInQueryResult=""
                idField={selectFormTable.idField}
                mounted={this.mounted}
                noDataMsg={selectFormTable.noDataMsg}
                queryResult={publishedFormsList}
                rowActions={this.handleRowActions}
                showRowButtons={['select']}
                showTableHeader={false}
                tableId="forms"
                uiEntityName="forms"
              />
            )}
          </Grid>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapFormSelectorToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedFormSelector = connect(mapStateToProps, mapFormSelectorToProps)(FormSelector);

export default reduxConnectedFormSelector;
