import React from 'react';

import { TV, TW, Typography } from '@BuildHero/sergeant';
import { Box, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

import { getStyles } from '../styles';

const SectionHeader = ({ title, titleIcon, children }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const IconComponent = titleIcon;

  return (
    <Box css={styles.sectionHeader}>
      <Box
        alignItems="center"
        css={{ marginBottom: theme.spacing(1), top: 10, position: 'relative' }}
        display="flex"
      >
        {titleIcon && <IconComponent />}
        <Typography variant={TV.BASE} weight={TW.BOLD}>
          {title}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.object,
  children: PropTypes.object
};

SectionHeader.defaultProps = {
  titleIcon: null,
  children: null
};

export default SectionHeader;
