import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default React.forwardRef(function Maintenance(props, ref) {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SvgIcon {...props} ref={ref}>
      <path d="M14.0796 18.3125L14.0835 21.0547L9.97205 21.0671L9.96906 18.313C9.96906 16.2138 8.44091 15.0656 8.23013 14.9172L8.20613 14.9007C3.79908 12.2501 7.35648 7.01046 7.35648 7.01046L8.04893 6.55352L9.49691 10.636C12.6184 12.2124 14.3125 9.14305 14.3125 9.14305L13.0848 5.27376L13.6206 5C14.6574 5.32108 16.5791 7.37582 16.5791 7.37582C18.8145 10.2932 17.803 11.6511 17.5681 11.902L17.5407 11.9298C17.53 11.9402 17.5239 11.9453 17.5239 11.9453C14.6043 14.918 14.0612 18.3125 14.0612 18.3125H14.0796Z" />
      <path d="M17.416 21.6999C17.0714 21.9296 16.6057 21.8365 16.376 21.4919C16.1462 21.1472 16.2393 20.6816 16.584 20.4518L17.416 21.6999ZM16.6646 3.74666C16.2941 3.56141 16.1439 3.11091 16.3292 2.74043C16.5144 2.36994 16.9649 2.21977 17.3354 2.40502L16.6646 3.74666ZM16.584 20.4518C18.7542 19.005 21.5 16.5021 21.5 12.0758H23C23 17.2495 19.7458 20.1467 17.416 21.6999L16.584 20.4518ZM21.5 12.0758C21.5 7.62161 18.4708 4.64977 16.6646 3.74666L17.3354 2.40502C19.5292 3.5019 23 6.93007 23 12.0758H21.5Z" />
      <path d="M6.83397 2.4518C7.17862 2.22203 7.64427 2.31516 7.87404 2.65981C8.1038 3.00446 8.01067 3.47011 7.66603 3.69987L6.83397 2.4518ZM7.58541 20.405C7.95589 20.5903 8.10606 21.0408 7.92082 21.4112C7.73558 21.7817 7.28507 21.9319 6.91459 21.7467L7.58541 20.405ZM7.66603 3.69987C5.49584 5.14666 2.75 7.64954 2.75 12.0758H1.25C1.25 6.90213 4.50416 4.00501 6.83397 2.4518L7.66603 3.69987ZM2.75 12.0758C2.75 16.5301 5.77918 19.5019 7.58541 20.405L6.91459 21.7467C4.72082 20.6498 1.25 17.2216 1.25 12.0758H2.75Z" />
      <path d="M5.27028 1.22524C4.86773 1.12763 4.46227 1.37483 4.36466 1.77738C4.26705 2.17993 4.51425 2.58538 4.9168 2.683L5.27028 1.22524ZM6.88864 5.07503C6.90765 5.48881 7.25849 5.80883 7.67227 5.78983C8.08605 5.77082 8.40607 5.41998 8.38706 5.0062L6.88864 5.07503ZM7.52313 2.54325L8.27234 2.50883C8.25705 2.17585 8.02382 1.89292 7.69987 1.81437L7.52313 2.54325ZM4.9168 2.683L7.34639 3.27213L7.69987 1.81437L5.27028 1.22524L4.9168 2.683ZM6.77392 2.57766L6.88864 5.07503L8.38706 5.0062L8.27234 2.50883L6.77392 2.57766Z" />
      <path d="M15.4309 19.0917C15.4221 18.6776 15.7507 18.3348 16.1648 18.326C16.5789 18.3173 16.9218 18.6459 16.9305 19.06L15.4309 19.0917ZM18.8426 21.5001C19.2426 21.6076 19.4797 22.0191 19.3721 22.4191C19.2646 22.8191 18.8531 23.0562 18.4531 22.9487L18.8426 21.5001ZM16.2336 21.5753L16.0389 22.2996C15.717 22.213 15.4908 21.9244 15.4837 21.5912L16.2336 21.5753ZM16.9305 19.06L16.9834 21.5594L15.4837 21.5912L15.4309 19.0917L16.9305 19.06ZM16.4283 20.851L18.8426 21.5001L18.4531 22.9487L16.0389 22.2996L16.4283 20.851Z" />
    </SvgIcon>
  );
});
