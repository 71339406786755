import React from 'react';

import { Button, MoreButton } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';
import DeleteIcon from '@material-ui/icons/Delete';
import PostAddIcon from '@material-ui/icons/PostAdd';
import PropTypes from 'prop-types';

import { Mode } from 'utils/constants';

import { deleteConfirmContent, handleMoreInfoNeededClick } from '../../ListView/index.helper';

const ActionBtns = ({
  btnLoading,
  confirmContext,
  deleteTaskById,
  deletingTask,
  handleClick,
  modalConfig,
  refetchTaskList,
  refetchTrigger,
  setBtnLoading,
  setIsCreateModalOpen,
  setRefetchTrigger,
  tenantId,
  updateTask
}) => {
  const handleMoreInfoClicked = async () => {
    setBtnLoading(true);
    await handleMoreInfoNeededClick({
      refetchTrigger,
      row: modalConfig?.row,
      setRefetchTrigger,
      tenantId,
      updateTask
    });
    setBtnLoading(false);
    setIsCreateModalOpen(false);
  };

  const handleDeleteTask = async () => {
    if (await confirmContext?.confirm(deleteConfirmContent)) {
      setBtnLoading(true);
      await deleteTaskById({ id: modalConfig?.row?.id, tenantId });
      if (!deletingTask) {
        setBtnLoading(false);
        setRefetchTrigger(!refetchTrigger);
        setIsCreateModalOpen(false);
        refetchTaskList(tenantId);
      }
    }
  };

  const moreBtnOptions = [
    {
      icon: PostAddIcon,
      label: 'More Info Needed',
      onClick: handleMoreInfoClicked
    },
    { icon: DeleteIcon, label: 'Delete Recommendation', onClick: handleDeleteTask }
  ];

  return (
    <>
      <Button fullWidth loading={btnLoading} onClick={handleClick}>
        {modalConfig.btnText}
      </Button>
      {modalConfig.type === Mode.EDIT && <MoreButton options={moreBtnOptions} />}
    </>
  );
};

export default ActionBtns;

ActionBtns.propTypes = {
  btnLoading: PropTypes.bool,
  confirmContext: PropTypes.object,
  deleteTaskById: PropTypes.func.isRequired,
  deletingTask: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  modalConfig: PropTypes.object,
  refetchTaskList: PropTypes.func.isRequired,
  refetchTrigger: PropTypes.bool,
  setBtnLoading: PropTypes.func.isRequired,
  setIsCreateModalOpen: PropTypes.func.isRequired,
  setRefetchTrigger: PropTypes.func.isRequired,
  tenantId: PropTypes.string,
  updateTask: PropTypes.func.isRequired
};

ActionBtns.defaultProps = {
  btnLoading: false,
  confirmContext: {},
  deletingTask: false,
  modalConfig: {},
  refetchTrigger: false,
  tenantId: ''
};
