import React, { useMemo } from 'react';

import { ThemeProvider } from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';

import PropTypes from 'prop-types';

import { useCompanyTimezone } from '@pm/components/hooks';

import WrapTable from 'components/WrapTable';
import MaintenanceList from 'scenes/Maintenance/MaintenanceList';
import { getFormattedDateString } from 'utils';

import useAdvancedSchedulingServiceAgreementCustomerPropertiesMetadata from '../hooks';

import { SA_MAINTENANCE_LIST_DATA } from '../queries';

const Maintenances = ({ serviceAgreementId, isAgreementActive, agreementInfo }) => {
  const [{ data: companyTimezone }] = useCompanyTimezone();

  const [
    serviceAgreementPropertyMetadata,
    loadingServiceAgreementPropertyMetadata
  ] = useAdvancedSchedulingServiceAgreementCustomerPropertiesMetadata({
    serviceAgreementId
  });

  const groupColumns = useMemo(() => {
    return [
      { field: 'companyName', headerName: 'Property', flex: 1 },
      { field: 'groupName', headerName: 'Maintenance Group', flex: 1 },
      { field: 'maintenanceName', headerName: 'Maintenance', flex: 1 },
      { field: 'maintenanceType', headerName: 'Maintenance Type', flex: 1 },
      {
        field: 'firstDueDate',
        headerName: 'Due Date',
        flex: 1,
        renderCell: cell => getFormattedDateString(cell.value, companyTimezone)
      },
      { field: 'laborEstimate', headerName: 'Labor Estimate', flex: 1 },
      { field: 'budgetedHours', headerName: 'Budgeted Hours', flex: 1 },
      { field: 'assets', headerName: 'Assets', flex: 1 },
      { field: 'visitsScheduled', headerName: 'Visits Scheduled', flex: 1 }
    ];
  }, [companyTimezone]);

  const rows = useMemo(() => {
    if (!loadingServiceAgreementPropertyMetadata && !serviceAgreementPropertyMetadata) {
      return [];
    }

    const saRows = [];

    serviceAgreementPropertyMetadata?.advancedMaintenanceTemplateGroups.forEach(g => {
      g.advancedMaintenanceTemplateGroupSchedules.forEach(m => {
        saRows.push({
          companyName: g.customerProperty.companyName,
          id: m.id,
          groupName: g.name,
          maintenanceName: m.name,
          firstDueDate: m.firstDueDate,
          maintenanceType: g.jobType.tagName,
          visitsScheduled: `${g.visitTemplates.length} visit(s)`,
          assets: m.numberOfAssets,
          laborEstimate: `${Math.round((m.laborEstimate / 60) * 100) / 100} hours`,
          budgetedHours: g.budgetedHours,
          primaryTechnician: g.primaryTech?.name,
          additionalTechnicians: g.additionalTechs.map(t => t.employee.name).join(', ')
        });
      });
    });

    return saRows;
  }, [loadingServiceAgreementPropertyMetadata, serviceAgreementPropertyMetadata]);

  return (
    <>
      {!isAgreementActive ? (
        <ThemeProvider>
          <Box display="flex" width="100%">
            <Box display="flex" flexDirection="column" flexGrow={2} p={2}>
              <WrapTable
                columns={groupColumns}
                loading={loadingServiceAgreementPropertyMetadata}
                noDataMessage="No Property Data"
                rows={rows}
              />
            </Box>
          </Box>
        </ThemeProvider>
      ) : (
        <MaintenanceList
          agreementInfo={agreementInfo}
          companyTimeZone={companyTimezone}
          isInlineTable
          overrideQuery={SA_MAINTENANCE_LIST_DATA}
          resultNode="serviceAgreementMaintenanceListData"
          serviceAgreementId={serviceAgreementId}
        />
      )}
    </>
  );
};

Maintenances.propTypes = {
  serviceAgreementId: PropTypes.string.isRequired
};

Maintenances.defaultProps = {};

export default Maintenances;
