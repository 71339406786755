import React, { useState } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import { bool, func, number, object, shape } from 'prop-types';

import {
  eventToFormData,
  formToEventData,
  validateScheduling
} from '../../helpers/NonVisitEvents.helpers';
import AccordionSection from '../AccordionSection';
import CreateMultipleEventsSwitch from '../CreateMultipleEventsSwitch';
import DateTimeInput from '../DateTimeInput';
import DurationInput from '../DurationInput';

import { useBillableEventFormConfig } from './BillableEventFormV2.config';

const BillableEventForm = ({
  allowMultipleEventsCreation,
  eventData,
  visit,
  job,
  disabled,
  onCreateService,
  onDirtyChange,
  onComplete,
  visitDataLoading
}) => {
  const [multipleEventsCreationEnabled, setMultipleEventsCreationEnabled] = useState(false);

  const handleCreateMultipleEventsChange = () =>
    setMultipleEventsCreationEnabled(!multipleEventsCreationEnabled);

  const formConfig = useBillableEventFormConfig({
    allowMultipleEventsCreation,
    disabled,
    job,
    multipleEventsCreationEnabled,
    onCreateMultipleEventsChange: handleCreateMultipleEventsChange,
    visit,
    visitDataLoading
  });

  const handleComplete = formData => {
    onComplete(
      formToEventData(formData, multipleEventsCreationEnabled),
      multipleEventsCreationEnabled
    );
  };

  return (
    <MUIForm
      configuration={formConfig}
      customComponents={{
        AccordionSection,
        CreateMultipleEventsSwitch,
        DateTimeInput,
        DurationInput
      }}
      data={eventToFormData(eventData)}
      validate={validateScheduling}
      onComplete={handleComplete}
      onCreateService={onCreateService}
      onDirtyChange={onDirtyChange}
    />
  );
};

BillableEventForm.propTypes = {
  allowMultipleEventsCreation: bool,
  disabled: bool.isRequired,
  visit: object,
  job: object,
  visitDataLoading: bool.isRequired,
  eventData: shape({
    from: number
  }).isRequired,
  onCreateService: func.isRequired,
  onDirtyChange: func.isRequired,
  onComplete: func.isRequired
};

BillableEventForm.defaultProps = {
  allowMultipleEventsCreation: false,
  visit: {},
  job: {}
};

export default BillableEventForm;
