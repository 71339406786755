import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import gql from 'graphql-tag';

import { XGrid } from 'components';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 18
  },
  grid: {
    'min-height': 200
  }
}));

const GET_JOB_PHASES = gql`
  query getJobPhases($id: String!) {
    data: getJobById(id: $id) {
      id
      items: jcPhaseCostTypes {
        id
        jcPhaseName
        jcCostTypeName
      }
    }
  }
`;

const GET_JOB_CONTRACT_ITEMS = gql`
  query getJobContractItems($id: String!) {
    data: getJobById(id: $id) {
      id
      items: jcContractContractItems {
        id
        jcContractName
        jcContractItemName
      }
    }
  }
`;

const jobPhasesColumns = [
  {
    field: 'jcPhaseName',
    headerName: 'Job Phase',
    minWidth: 200,
    flex: 1
  },
  {
    field: 'jcCostTypeName',
    headerName: 'Cost Type',
    minWidth: 200,
    flex: 2
  }
];

const jobContractItemColumns = [
  {
    field: 'jcContractName',
    headerName: 'Contract Number',
    minWidth: 200,
    flex: 1
  },
  {
    field: 'jcContractItemName',
    headerName: 'Contract Item',
    minWidth: 200,
    flex: 1
  }
];

const JobPhases = ({ jobId, withContract }) => {
  const queryVariables = {
    id: jobId
  };
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.title} variant="h6">
        Job Phases
      </Typography>
      <XGrid
        columns={jobPhasesColumns}
        disableToolbar
        entityTypeName="Job Phase"
        query={GET_JOB_PHASES}
        queryVariables={queryVariables}
        tableName="VistaJobPhaseXGrid"
        useItemsCountAsRowCount
      />
      {withContract && (
        <Typography className={classes.title} variant="h6">
          Job Contract Items
        </Typography>
      )}
      {withContract && (
        <XGrid
          columns={jobContractItemColumns}
          disableToolbar
          entityTypeName="Job Contract Items"
          query={GET_JOB_CONTRACT_ITEMS}
          queryVariables={queryVariables}
          tableName="VistaJobContractItemsXGrid"
          useItemsCountAsRowCount
        />
      )}
    </>
  );
};

export default JobPhases;
