import { useMemo } from 'react';

import * as R from 'ramda';

import toMap from 'utils/toMap';

export const useTimesheetsByEmployee = (employeesTimesheets, employees) =>
  useMemo(() => {
    const employeeMap = toMap(employees || []);

    const result = R.values(
      employeesTimesheets?.reduce((resultEmployeeTimesheets, item) => {
        const { employeeId } = item;
        const prevEmployeeTimesheets =
          resultEmployeeTimesheets[employeeId]?.employeeTimesheets || [];
        return {
          ...resultEmployeeTimesheets,
          [employeeId]: {
            employee: employeeMap[employeeId],
            employeeTimesheets: [...prevEmployeeTimesheets, item]
          }
        };
      }, {}) || {}
    );
    return R.sortBy(item => item?.employee?.name)(result);
  }, [employeesTimesheets, employees]);
