import React from 'react';

import { string } from 'prop-types';

import { TechStatuses } from '@dispatch/Dispatch.constants';

import { useStyles } from './TechStatus.styles';

const TechStatus = ({ techStatus }) => {
  const status = TechStatuses.getBy('serverValue', techStatus);
  const classes = useStyles({ color: status?.color });
  if (!status) {
    return null;
  }
  return (
    <div>
      {status.icon && <img alt={status.displayValue} className={classes.icon} src={status.icon} />}
      <div className={classes.text}>{status.displayValue}</div>
    </div>
  );
};

TechStatus.propTypes = {
  techStatus: string
};

TechStatus.defaultProps = {
  techStatus: null
};

export default TechStatus;
