import React, { createContext, memo, useContext, useMemo, useReducer, useRef } from 'react';

import { EVENT_TYPES, EventEntities } from '@dispatch/Dispatch.constants';
import { useQueryStringStore } from 'customHooks/useQueryStringStore';

import * as Actions from './Dispatch.actions';
import { appReducer } from './Dispatch.reducer';

export { useQueryStringStore, withQueryStringStore } from 'customHooks/useQueryStringStore';

const useDispatchStoreReducer = () => {
  const [state, dispatch] = useReducer(appReducer, {});

  const dispatchActions = useMemo(() => {
    return Object.entries(Actions).reduce(
      (wrappedActions, [key, action]) => ({
        ...wrappedActions,
        [key]: (...params) => dispatch(action(...params))
      }),
      {}
    );
  }, [dispatch]);

  return {
    ...dispatchActions,
    state
  };
};

const DispatchStoreContext = createContext({});
DispatchStoreContext.displayName = 'DispatchStoreContext';

export const useDispatchStore = () => useContext(DispatchStoreContext);

export const withDispatchProvider = Component => props => {
  const queryStringStore = useDispatchStoreReducer();

  return (
    <DispatchStoreContext.Provider value={queryStringStore}>
      <Component {...props} />
    </DispatchStoreContext.Provider>
  );
};

export const withDispatchStore = (
  mapStoreToProps = store => store,
  { propsAreEqual } = {}
) => Component => props => {
  const mappedProps = mapStoreToProps(useDispatchStore(), props);
  const MemoizedComponent = useRef(memo(Component, propsAreEqual)).current;

  return <MemoizedComponent {...props} {...mappedProps} />;
};

export const withDispatchActions = (
  mapActionsToProps = store => store,
  { propsAreEqual } = {}
) => Component => props => {
  const queryStringStore = useQueryStringStore();
  const dispatchStore = useDispatchStore();

  const actions = useMemo(
    () => ({
      openEvent: ({ eventType, eventId }) => {
        queryStringStore.closeVisitOrEvent();
        dispatchStore.clearDrawerData();

        if (eventType === EVENT_TYPES.BILLABLE_EVENT) {
          return queryStringStore.openBillableEvent(eventId);
        }

        if (eventType === EVENT_TYPES.NON_BILLABLE_EVENT) {
          return queryStringStore.openNonBillableEvent(eventId);
        }

        if (eventType === EventEntities.MAN_DAY.value.clientValue) {
          return queryStringStore.openManDay(eventId);
        }

        return queryStringStore.openVisit(eventId);
      },
      createEvent: ({ eventType, eventData }) => {
        queryStringStore.closeVisitOrEvent();
        dispatchStore.createEventWithData({ eventType, eventData });
      },
      closeDrawer: () => {
        queryStringStore.closeVisitOrEvent();
        dispatchStore.clearDrawerData();
      }
    }),
    []
  );

  const mappedProps = mapActionsToProps(actions);

  const MemoizedComponent = useRef(memo(Component, propsAreEqual)).current;

  return <MemoizedComponent {...props} {...mappedProps} />;
};

export const watchedQueries = {};
