import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { snackbarOn } from 'redux/actions/globalActions';

export const useSnackbar = () => {
  const dispatch = useDispatch();
  return useCallback((...args) => dispatch(snackbarOn(...args)), [dispatch]);
};
