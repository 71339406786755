import { column, ColumnType } from 'components/XGrid/columnTypes';

export const PROJECT_COLUMNS = [
  {
    field: 'number',
    headerName: 'Number',
    valueGetter: ({ id, value }) => {
      return {
        label: value,
        to: `/project/view/${id}/dashboard`
      };
    },
    width: 150,
    ...column[ColumnType.LINK]
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'subtype',
    valueGetter: ({ row }) => {
      return row?.ProjectSubtype?.name || '-';
    },
    headerName: 'Subtype',
    width: 120,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'projectManager',
    headerName: 'Project Manager',
    valueGetter: ({ row }) => {
      return row?.projectManager?.name || '-';
    },
    width: 200,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'dateStart',
    headerName: 'Date Start',
    width: 150,
    ...column[ColumnType.DATE]
  },
  {
    field: 'dateEnd',
    headerName: 'Date End',
    width: 150,
    ...column[ColumnType.DATE]
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'customerName',
    headerName: 'Customer Name',
    valueGetter: ({ row }) => row?.customer?.name ?? '-',
    width: 200,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'projectType',
    headerName: 'Project Type',
    valueGetter: ({ row }) => row?.ProjectType?.name ?? '-',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'department',
    headerName: 'Department',
    valueGetter: ({ row }) => row?.projectManagerDepartment?.tagName ?? '-',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'billingCustomer',
    headerName: 'Billing Customer',
    valueGetter: ({ row }) => row?.billingCustomer?.customerName ?? '-',
    width: 200,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'foreman.name',
    headerName: 'Foreman',
    valueGetter: ({ row }) => row?.foreman?.name ?? '-',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'externalPONumber',
    headerName: 'External Project Number',
    width: 200,
    ...column[ColumnType.TEXT]
  }
];
