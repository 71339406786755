import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import * as R from 'ramda';

import { useSelector } from 'react-redux';

import {
  isImageFile,
  toFileAttachment,
  toImageAttachment,
  uploadFiles
} from './Attachments.helpers';
import { useStyles } from './Attachments.styles';
import AddAttachmentButton from './components/AddAttachmentButton';
import AddAttachments from './components/AddAttachments';
import EmptyAttachmentsList from './components/EmptyAttachmentsList';
import FilesList from './components/FilesList';
import ImagesCarousel from './components/ImagesCarousel';
import Title from './components/Title';

const partitionAttachments = attachments => {
  const [imageAttachments, fileAttachments] = R.partition(attachment =>
    isImageFile(attachment?.file || attachment)
  )(attachments);

  const images = R.map(toImageAttachment)(imageAttachments);
  const files = R.map(toFileAttachment)(fileAttachments);
  return { images, files };
};

const Attachments = props => {
  const {
    attachedFiles,
    smallType,
    noTitle,
    smallButton,
    downloadable,
    noAddButton,
    uploadFileList,
    chunkSize,
    imageWidth,
    imageHeight
  } = props;
  const styles = useStyles();
  const tenantId = useSelector(store => store?.user?.tenantId);
  const [isAttachmentsInitialized, setIsAttachmentsInitialized] = useState(false);
  const [attachments, setAttachments] = useState(attachedFiles || []);
  const { images, files } = partitionAttachments(attachments);

  useEffect(() => {
    if (!isAttachmentsInitialized && attachedFiles?.length) {
      setAttachments(attachedFiles);
      setIsAttachmentsInitialized(true);
    }
  }, [attachments, attachedFiles, isAttachmentsInitialized]);

  const [attachmentsModalOpen, setAttachmentsModalOpen] = useState(false);
  const openAttachmentsModal = useCallback(() => setAttachmentsModalOpen(true), []);
  const closeAttachmentsModal = useCallback(() => setAttachmentsModalOpen(false), []);

  const handleAttachmentsSelected = async data => {
    const selectedImages = data.images;
    const selectedFiles = data.files;

    const uploadedFiles = await uploadFiles({
      images: selectedImages,
      files: selectedFiles,
      tenantId
    });
    const alreadyUploadedImages = selectedImages.filter(image => !image.file);
    const alreadyUploadedFiles = selectedFiles.filter(file => !file.file);

    const newAttachments = [...uploadedFiles, ...alreadyUploadedImages, ...alreadyUploadedFiles];

    setAttachments(newAttachments);
    await uploadFileList(uploadedFiles, selectedImages, selectedFiles);

    closeAttachmentsModal();

    return partitionAttachments(newAttachments);
  };

  return (
    <div css={styles.root}>
      <Title noTitle={noTitle} smallType={smallType} />
      <ImagesCarousel
        chunkSize={chunkSize}
        downloadable={downloadable}
        imageHeight={imageHeight}
        images={images}
        imageWidth={imageWidth}
      />
      <FilesList downloadable={downloadable} files={files} />
      <EmptyAttachmentsList files={files} images={images} noTitle={noTitle} />
      {!noAddButton && (
        <AddAttachmentButton smallButton={smallButton} onClick={openAttachmentsModal} />
      )}
      {attachmentsModalOpen && (
        <AddAttachments
          files={files}
          images={images}
          open={attachmentsModalOpen}
          onAttachmentsSelected={handleAttachmentsSelected}
          onClose={closeAttachmentsModal}
        />
      )}
    </div>
  );
};

Attachments.propTypes = {
  attachedFiles: PropTypes.array,
  smallType: PropTypes.bool,
  smallButton: PropTypes.bool,
  noTitle: PropTypes.bool,
  downloadable: PropTypes.bool,
  noAddButton: PropTypes.bool,
  chunkSize: PropTypes.number,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  uploadFileList: PropTypes.func.isRequired
};

Attachments.defaultProps = {
  attachedFiles: [],
  smallType: false,
  noTitle: false,
  smallButton: false,
  downloadable: false,
  noAddButton: false,
  chunkSize: 5,
  imageWidth: 200,
  imageHeight: 180
};

export default Attachments;
