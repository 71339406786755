import { getState } from 'redux/store';
import AmplifyService from 'services/AmplifyService';
import validateAccountingEntity from 'services/core/graphql/crm/customer/mutations/validateAccountingEntity';

export default class AccountingValidationService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
  }

  validateEntity = async (tenantId, entityName, entityRecord) => {
    const { app, setting } = getState()?.settings?.accountingAppSettings || {};
    if (!setting?.isAccountingValidationEnabled) {
      return null;
    }
    const params = {
      tenantId,
      entityName,
      entityRecord
    };
    const response = await this.api.mutate(validateAccountingEntity, params);
    if (
      response?.data?.validateAccountingEntity &&
      !response?.data?.validateAccountingEntity?.valid
    ) {
      const errorRecords = response?.data?.validateAccountingEntity?.errors;
      const errorMessages = Object.values(errorRecords);
      return errorMessages[0][0].message;
    }
    return null;
  };
}
