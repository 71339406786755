/* eslint-disable no-template-curly-in-string */
import { FieldType, TH, TV, TW } from '@BuildHero/sergeant';

const getJobDetails = ({
  reportType,
  showJobReportHeader = true,
  showJobDescription,
  showJobSummary,
  showCustomerSignature
}) => {
  const reportTypeTitle = `${reportType} Report`;
  return [
    // Logo and Address
    showJobReportHeader && {
      options: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
        height: 140
      },
      contents: [
        // Logo
        {
          component: 'SgtConverter',
          source: 'tenantCompanyLogoUrl',
          options: {
            sgtComponent: 'Image'
          },
          style: {
            width: 140,
            height: 'auto',
            maxHeight: 140
          }
        },
        // Address
        {
          options: {
            alignItems: 'flex-end',
            justifyContent: 'center',
            height: 140
          },
          contents: [
            {
              component: 'SgtConverter',
              source: '${tenantCompanyAddress1} \n ${tenantCompanyAddress3}',
              options: {
                sgtComponent: 'Typography',
                height: TH.COMFORTABLE,
                variant: TV.PRINT
              },
              style: {
                width: 160,
                textAlign: 'right'
              }
            },
            {
              component: 'SgtConverter',
              source: 'tenantCompanyPhone',
              options: {
                sgtComponent: 'Typography',
                height: TH.COMFORTABLE,
                variant: TV.PRINT
              },
              style: {
                width: 160,
                textAlign: 'right'
              }
            }
          ]
        }
      ]
    },
    // Report Title and Date
    showJobReportHeader && {
      options: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginBottom: 16,
        height: 20
      },
      contents: [
        // Title
        {
          component: 'SgtConverter',
          options: {
            sgtComponent: 'Typography',
            weight: TW.BOLD,
            value: reportTypeTitle,
            variant: TV.BASE
          },
          style: { height: 20 }
        },
        // Job Number
        {
          component: 'SgtConverter',
          source: '#${jobNumber}',
          options: {
            sgtComponent: 'Typography',
            variant: TV.BASE
          },
          style: { marginLeft: 4, height: 20 }
        },
        // Date
        {
          component: 'SgtConverter',
          source: 'currentDate',
          options: {
            sgtComponent: 'Field',
            type: FieldType.DATE,
            variant: TV.BASE
          },
          style: { marginLeft: 'auto', height: 20 }
        }
      ]
    },
    // Job Details
    showJobReportHeader && {
      options: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 12
      },
      contents: [
        {
          options: {
            flexDirection: 'column',
            justifyContent: 'space-between'
          },
          contents: [
            {
              component: 'SgtConverter',
              source: 'jobTypeName',
              options: {
                sgtComponent: 'Field',
                label: 'TYPE'
              },
              style: { maxWidth: 141, marginBottom: 8 }
            },
            {
              component: 'SgtConverter',
              source: 'authorizedBy',
              options: {
                sgtComponent: 'Field',
                label: 'AUTHORIZED BY'
              },
              style: { maxWidth: 141, paddingRight: 16 }
            }
          ]
        },
        {
          options: {
            flexDirection: 'column',
            justifyContent: 'space-between'
          },
          contents: [
            {
              component: 'SgtConverter',
              source: 'customerName',
              options: {
                sgtComponent: 'Field',
                label: 'CUSTOMER NAME'
              },
              style: { maxWidth: 141, marginBottom: 8 }
            },
            {
              component: 'SgtConverter',
              source: 'customerProvidedPONumber',
              options: {
                sgtComponent: 'Field',
                label: 'CUSTOMER PO#'
              },
              style: { maxWidth: 141, paddingRight: 16 }
            }
          ]
        },
        {
          options: {
            flexDirection: 'column',
            justifyContent: 'space-between'
          },
          contents: [
            {
              component: 'SgtConverter',
              source: 'customerAddress',
              options: {
                sgtComponent: 'Field',
                label: 'CUSTOMER ADDRESS'
              },
              style: { maxWidth: 141, marginBottom: 8 }
            },
            {
              component: 'SgtConverter',
              source: 'customerProvidedWONumber',
              options: {
                sgtComponent: 'Field',
                label: 'CUSTOMER WO#'
              },
              style: { maxWidth: 141, paddingRight: 16 }
            }
          ]
        },
        {
          options: {
            flexDirection: 'column',
            justifyContent: 'space-between'
          },
          contents: [
            {
              component: 'SgtConverter',
              source: 'propertyName',
              options: {
                sgtComponent: 'Field',
                label: 'PROPERTY NAME'
              },
              style: { marginBottom: 8 }
            },
            {
              component: 'SgtConverter',
              source: 'amountQuoted',
              options: {
                sgtComponent: 'Field',
                label: 'SALES AMOUNT',
                type: FieldType.CURRENCY
              },
              style: { paddingRight: 16 }
            }
          ]
        },
        {
          options: {
            flexDirection: 'column',
            justifyContent: 'space-between'
          },
          contents: [
            {
              component: 'SgtConverter',
              source: 'propertyAddress',
              options: {
                sgtComponent: 'Field',
                label: 'PROPERTY ADDRESS'
              },
              style: { marginBottom: 8 }
            },
            {
              component: 'SgtConverter',
              source: 'amountNotToExceed',
              options: {
                sgtComponent: 'Field',
                label: 'NTE',
                type: FieldType.CURRENCY
              },
              style: { paddingRight: 16 }
            }
          ]
        }
      ]
    },
    // Divider
    showJobReportHeader && {
      component: 'SgtConverter',
      options: {
        sgtComponent: 'Divider'
      }
    },
    // Job Description
    showJobDescription && {
      component: 'SgtConverter',
      options: {
        sgtComponent: 'Typography',
        weight: TW.BOLD,
        value: 'Job Description',
        variant: TV.S2
      },
      style: { marginBottom: 4 }
    },
    showJobDescription && {
      component: 'SgtConverter',
      source: 'issueDescription',
      options: {
        sgtComponent: 'Typography'
      }
    },
    // Divider
    showJobDescription && {
      component: 'SgtConverter',
      options: {
        sgtComponent: 'Divider'
      }
    },
    // Job Summary
    showJobSummary && {
      component: 'SgtConverter',
      options: {
        sgtComponent: 'Typography',
        weight: TW.BOLD,
        value: 'Job Summary',
        variant: TV.S2
      },
      style: { marginBottom: 4 }
    },
    showJobSummary && {
      component: 'VisitSummary',
      source: 'visits',
      options: {
        showCustomerSignature
      }
    },
    // Divider
    showJobSummary && {
      component: 'SgtConverter',
      options: {
        sgtComponent: 'Divider'
      }
    }
  ].filter(Boolean);
};

const getJobTasks = () => [
  {
    component: 'SgtConverter',
    source: 'jobTasks',
    options: {
      sgtComponent: 'Table',
      title: 'Tasks Completed',
      columns: [
        {
          id: 'assetName',
          label: 'Asset',
          width: `${110 / 5.64}%`,
          weight: TW.BOLD
        },
        {
          id: 'taskName',
          label: 'Task',
          width: `${110 / 5.64}%`
        },
        {
          id: 'taskDescription',
          label: 'Task Description',
          width: `${240 / 5.64}%`
        }
      ]
    },
    style: { marginBottom: 8 }
  }
];

const getLaborAndMaterial = ({
  showHoursBilled,
  showHoursWorked,
  showCostSummary,
  showPriceSummary,
  showInventoryItems,
  showPurchasedItems,
  showMaterialsCostSummary,
  showMaterialsPriceSummary,
  showVendor,
  showCostAndPriceSummary,
  procurementFlag
}) => {
  const totalSummaryName = () => {
    const itemName = [];
    if (showCostSummary || showMaterialsCostSummary) itemName.push('Cost');
    if (showPriceSummary || showMaterialsPriceSummary) itemName.push('Price');
    return itemName.length > 1 ? itemName.join(' and ') : itemName?.[0];
  };
  const laborAndMaterial = [
    // Labor
    showHoursWorked && {
      component: 'SgtConverter',
      source: 'laborCosts',
      options: {
        sgtComponent: 'Table',
        title: 'Hours Worked',
        columns: [
          {
            id: 'visitNumber',
            label: 'Visit #',
            width: `${36 / 5.64}%`,
            weight: TW.BOLD
          },
          {
            id: 'technician',
            label: 'Technician',
            width: `${110 / 5.64}%`
          },
          {
            id: 'laborType',
            label: 'Labor Type',
            width: `${100 / 5.64}%`
          },
          {
            id: 'description',
            label: 'Description',
            width: `${122 / 5.64}%`
          },
          {
            id: 'hours',
            label: 'Hours',
            type: FieldType.NUMBER,
            width: `${56 / 5.64}%`,
            numeric: true,
            sum: true
          },
          {
            id: 'cost',
            label: 'Unit Cost',
            type: FieldType.CURRENCY,
            hide: !showCostSummary,
            width: `${60 / 5.64}%`,
            numeric: true
          },
          {
            id: 'total',
            label: 'Cost Subtotal',
            type: FieldType.CURRENCY,
            hide: !showCostSummary,
            width: `${80 / 5.64}%`,
            sum: true
          }
        ]
      },
      style: { marginBottom: 8 }
    },
    showHoursBilled && {
      component: 'SgtConverter',
      source: 'labors',
      options: {
        sgtComponent: 'Table',
        title: 'Hours Billed',
        columns: [
          {
            id: 'visitNumber',
            label: 'Visit #',
            width: `${36 / 5.64}%`,
            weight: TW.BOLD
          },
          {
            id: 'technician',
            label: 'Technician',
            width: `${110 / 5.64}%`
          },
          {
            id: 'laborType',
            label: 'Labor Type',
            width: `${100 / 5.64}%`
          },
          {
            id: 'description',
            label: 'Description',
            width: `${122 / 5.64}%`
          },
          {
            id: 'hours',
            label: 'Hours',
            type: FieldType.NUMBER,
            width: `${56 / 5.64}%`,
            numeric: true,
            sum: true
          },
          {
            id: 'rate',
            label: 'Unit Price',
            type: FieldType.CURRENCY,
            hide: !showPriceSummary,
            width: `${60 / 5.64}%`,
            numeric: true
          },
          {
            id: 'total',
            label: 'Price Subtotal',
            type: FieldType.CURRENCY,
            hide: !showPriceSummary,
            width: `${80 / 5.64}%`,
            sum: true
          }
        ]
      },
      style: { marginBottom: 8 }
    },
    // Divider
    (showHoursBilled || showHoursWorked) && {
      component: 'SgtConverter',
      options: {
        sgtComponent: 'Divider'
      }
    },
    // Inventory
    showInventoryItems && {
      component: 'SgtConverter',
      source: 'partsAndMaterials',
      options: {
        sgtComponent: 'Table',
        title: 'Inventory',
        columns: [
          {
            id: 'itemName',
            label: 'Item Name',
            width: `${104 / 5.64}%`,
            weight: TW.BOLD
          },
          {
            id: 'productCode',
            label: 'Product Code',
            width: `${64 / 5.64}%`
          },
          {
            id: 'description',
            label: 'Description',
            width: `${100 / 5.64}%`
          },
          {
            id: 'taxable',
            label: 'Taxable',
            type: FieldType.BOOL,
            width: `${36 / 5.64}%`
          },
          {
            id: 'quantity',
            label: 'Quantity',
            type: FieldType.NUMBER,
            width: `${40 / 5.64}%`,
            numeric: true,
            sum: true
          },
          {
            id: 'unitCost',
            label: 'Unit Cost',
            type: FieldType.CURRENCY,
            hide: !showMaterialsCostSummary,
            width: `${50 / 5.64}%`
          },
          {
            id: 'costSubtotal',
            label: 'Cost Subtotal',
            type: FieldType.CURRENCY,
            hide: !showMaterialsCostSummary,
            width: `${60 / 5.64}%`,
            sum: true
          },
          {
            id: 'unitPrice',
            label: 'Unit Price',
            type: FieldType.CURRENCY,
            hide: !showMaterialsPriceSummary,
            width: `${50 / 5.64}%`
          },
          {
            id: 'priceSubtotal',
            label: 'Price Subtotal',
            type: FieldType.CURRENCY,
            hide: !showMaterialsPriceSummary,
            width: `${60 / 5.64}%`,
            sum: true
          }
        ]
      },
      style: { marginBottom: 8 }
    },
    // Divider
    showInventoryItems && {
      component: 'SgtConverter',
      options: {
        sgtComponent: 'Divider'
      }
    },
    procurementFlag &&
      showPurchasedItems && {
        component: 'SgtConverter',
        source: 'billItems',
        options: {
          sgtComponent: 'Table',
          title: 'Purchased Items',
          columns: [
            {
              id: 'poNumber',
              label: 'PO Number',
              width: `${80 / 5.64}%`,
              weight: TW.BOLD
            },
            {
              id: 'description',
              label: 'Description',
              width: showVendor ? `${124 / 5.64}%` : `${204 / 5.64}%`
            },
            {
              id: 'vendor',
              label: 'Vendor',
              hide: !showVendor,
              width: `${80 / 5.64}%`
            },
            {
              id: 'quantity',
              label: 'Quantity',
              type: FieldType.NUMBER,
              width: `${40 / 5.64}%`,
              numeric: true,
              sum: true
            },
            {
              id: 'unitCost',
              label: 'Unit Cost',
              type: FieldType.CURRENCY,
              hide: !showMaterialsCostSummary,
              width: `${55 / 5.64}%`
            },
            {
              id: 'costSubtotal',
              label: 'Cost Subtotal',
              type: FieldType.CURRENCY,
              hide: !showMaterialsCostSummary,
              width: `${65 / 5.64}%`,
              sum: true
            },
            {
              id: 'unitPrice',
              label: 'Unit Price',
              type: FieldType.CURRENCY,
              hide: !showMaterialsPriceSummary,
              width: `${55 / 5.64}%`
            },
            {
              id: 'priceSubtotal',
              label: 'Price Subtotal',
              type: FieldType.CURRENCY,
              hide: !showMaterialsPriceSummary,
              width: `${65 / 5.64}%`,
              sum: true
            }
          ]
        },
        style: { marginBottom: 8 }
      },
    // Divider
    procurementFlag &&
      showPurchasedItems && {
        component: 'SgtConverter',
        options: {
          sgtComponent: 'Divider'
        }
      },
    // Purchases
    !procurementFlag &&
      showPurchasedItems && {
        component: 'PurchasedView',
        source: 'partsAndMaterialsPurchased',
        options: {
          hideCost: !showHoursWorked,
          columns: [
            {
              id: 'itemName',
              label: 'Item Name',
              width: `${105 / 5.64}%`,
              weight: TW.BOLD
            },
            {
              id: 'productCode',
              label: 'Product Code',
              width: `${64 / 5.64}%`
            },
            {
              id: 'description',
              label: 'Description',
              width: `${105 / 5.64}%`
            },
            {
              id: 'quantity',
              label: 'Quantity',
              type: FieldType.NUMBER,
              width: `${50 / 5.64}%`,
              numeric: true,
              sum: true
            },
            {
              id: 'unitCost',
              label: 'Unit Cost',
              type: FieldType.CURRENCY,
              hide: !showMaterialsCostSummary,
              width: `${60 / 5.64}%`
            },
            {
              id: 'costSubtotal',
              label: 'Cost Subtotal',
              type: FieldType.CURRENCY,
              hide: !showMaterialsCostSummary,
              width: `${60 / 5.64}%`,
              sum: true
            },
            {
              id: 'unitPrice',
              label: 'Unit Price',
              type: FieldType.CURRENCY,
              hide: !showMaterialsPriceSummary,
              width: `${60 / 5.64}%`
            },
            {
              id: 'priceSubtotal',
              label: 'Price Subtotal',
              type: FieldType.CURRENCY,
              hide: !showMaterialsPriceSummary,
              width: `${60 / 5.64}%`,
              sum: true
            }
          ]
        }
      },
    showCostAndPriceSummary && {
      component: 'SgtConverter',
      source: 'totalSummaryTable',
      hide: !showCostAndPriceSummary,
      options: {
        sgtComponent: 'Table',
        title: `${totalSummaryName() || ''} Summary`,
        columns: [
          {
            id: 'itemName',
            label: 'Item Name',
            width: `${100 / 5.64}%`,
            weight: TW.BOLD
          },
          {
            id: 'costSubtotal',
            label: 'Cost Subtotal',
            type: FieldType.CURRENCY,
            hide: !showCostSummary && !showMaterialsCostSummary,
            width: `${80 / 5.64}%`,
            sum: showCostSummary && showMaterialsCostSummary
          },
          {
            id: 'priceSubtotal',
            label: 'Price Subtotal',
            type: FieldType.CURRENCY,
            hide: !showPriceSummary && !showMaterialsPriceSummary,
            width: `${80 / 5.64}%`,
            sum: showPriceSummary && showMaterialsPriceSummary
          }
        ]
      },
      style: { marginBottom: 8 }
    },
    showCostAndPriceSummary && {
      component: 'SgtConverter',
      options: {
        sgtComponent: 'Divider'
      }
    }
  ].filter(Boolean);
  return laborAndMaterial;
};

const getBeforeAndAfterPhotos = () => [
  {
    options: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginVertical: 16
    },
    contents: [
      // Title
      {
        component: 'SgtConverter',
        options: {
          sgtComponent: 'Typography',
          weight: TW.BOLD,
          value: 'Before & After Photos',
          variant: TV.BASE
        }
      }
    ]
  },
  {
    options: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: 0
    },
    contents: [
      {
        component: 'Attachments',
        source: 'visitAttachments'
      }
    ]
  }
];

const getReceiptsPhotos = () => [
  {
    options: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginVertical: 16
    },
    contents: [
      // Title
      {
        component: 'SgtConverter',
        options: {
          sgtComponent: 'Typography',
          weight: TW.BOLD,
          value: 'Receipts',
          variant: TV.BASE
        }
      }
    ]
  },
  {
    options: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginVertical: 16
    },
    contents: [
      {
        component: 'Attachments',
        source: 'visitReceipts'
      }
    ]
  }
];

const getCustomerSignature = () => [
  {
    options: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: 0,
      padding: 0
    },
    contents: [
      {
        component: 'SignaturesPDF',
        source: 'customerSignatures'
      }
    ]
  }
];

export const PDFDocumentLayout = settings => {
  const {
    pdfTitle,
    customFormLayouts = [],
    customTaskFormLayouts = [],
    procurementFlag,
    showHoursWorked,
    showBeforeAfterPhotos,
    showTaskCompleted,
    showCustomerSignature,
    showForms,
    showTaskForms
  } = settings;
  const jobDetailsSection = getJobDetails(settings);
  const laborAndMaterial = getLaborAndMaterial(settings);
  const jobTasks = (showTaskCompleted && getJobTasks()) || [];
  const documentContents = [...jobDetailsSection, ...jobTasks, ...laborAndMaterial];

  if (showBeforeAfterPhotos) documentContents.push(...getBeforeAndAfterPhotos());
  if (showHoursWorked && !procurementFlag) documentContents.push(...getReceiptsPhotos());
  if (showCustomerSignature) documentContents.push(...(getCustomerSignature() || []));
  let formsToDisplay = [];
  if (showForms) formsToDisplay = customFormLayouts;
  if (showTaskForms) formsToDisplay = [...formsToDisplay, ...customTaskFormLayouts];

  return {
    fields: {},
    layouts: {
      default: {
        options: {
          title: pdfTitle,
          pageHeader: ({ pageNumber }) => {
            if (pageNumber <= 1) return '';
            return pdfTitle;
          },
          pageFooter: ({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`
        },
        contents: documentContents
      }
    },
    pages: formsToDisplay
  };
};
