import configForEnvironment from 'configs/aws-exports';
import ENV from 'configs/env';
import { createAxiosInstance } from 'configs/http-client';

const pmHttpClient = createAxiosInstance(configForEnvironment(ENV).pmapi, {
  skipInterceptors: true
});

export const uploadToPmFiles = async ({ file, projectId, folderPath, folderId }) => {
  const data = new FormData();
  data.append('path', [folderPath, folderId].join('/'));
  data.append('uploadFiles', file);

  return pmHttpClient({
    url: `/fs/pm/${projectId}/upload`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  });
};
