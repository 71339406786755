import React, { Component } from 'react';

import { CardMedia } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';

import styles from './styles';

/**
 * Materail UI Image Display with BuildHero styles
 */
class ImageDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: '',
      imageUrl: ''
    };
  }

  componentDidMount() {
    const { image } = this.props;
    if (image !== this.state.image) {
      this.getImage(image);
    }
  }

  getImage = async image => {
    let imgUrl;
    try {
      const storageService = new StorageService();
      imgUrl = await storageService.getFile(image);

      this.setState({ imageUrl: imgUrl, image });
    } catch (error) {
      Logger.info(`Error uploading image ${error}`);
    }
  };

  render() {
    const { height } = this.props;
    const { imageUrl } = this.state;

    return (
      <>
        {imageUrl && (
          <CardMedia
            component="img"
            // alt={image.fileName}
            height={height || 250}
            image={imageUrl}
            // title={image.fileName}
            style={{ width: 'auto' }}
          />
        )}
      </>
    );
  }
}

export default withStyles(styles)(ImageDisplay);
