import React, { useCallback } from 'react';

import { bool, func, string } from 'prop-types';

import ToggleButton from '@dispatch/components/ToggleButton';
import { EVENT_TYPES } from '@dispatch/Dispatch.constants';

import JobsTable from 'components/JobsTable';
import VisitsTable from 'components/VisitsTable';

import { DispatchTabs } from './DispatchTray.constants';
import { useStyles } from './DispatchTray.styles';

const TabHeader = ({ active, onClick, text }) => {
  const styles = useStyles({ active });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div css={styles.tabHeader} role="button" tabIndex="0" onClick={onClick}>
      {text}
    </div>
  );
};

TabHeader.propTypes = {
  active: bool.isRequired,
  text: string.isRequired,
  onClick: func.isRequired
};

const DispatchTray = ({ tab, openTab, openEvent }) => {
  const styles = useStyles();

  const handleVisitClick = useCallback(
    ({ id }) => {
      openEvent({
        eventId: id,
        eventType: EVENT_TYPES.VISIT
      });
    },
    [openEvent]
  );

  return (
    <div css={styles.container}>
      <div css={styles.header}>
        <div css={styles.tabsContainer}>
          {Object.values(DispatchTabs).map(tabText => (
            <TabHeader
              active={tabText === tab}
              key={tabText}
              text={tabText}
              onClick={() => openTab(tabText)}
            />
          ))}
        </div>
        <ToggleButton />
      </div>
      <div css={styles.content}>
        {tab === DispatchTabs.JOBS && <JobsTable pageSize={100} tableName="DispatchJobsTable" />}
        {tab === DispatchTabs.VISITS && (
          <VisitsTable
            pageSize={100}
            tableName="DispatchVisitsTable"
            onVisitClick={handleVisitClick}
          />
        )}
      </div>
    </div>
  );
};

DispatchTray.propTypes = {
  tab: string,
  openTab: func.isRequired,
  openEvent: func.isRequired
};

DispatchTray.defaultProps = {
  tab: DispatchTabs.JOBS
};

export default DispatchTray;
