import React from 'react';

import { string } from 'prop-types';
import { connect } from 'react-redux';

import { snackbarOn } from 'redux/actions/globalActions';

import { useWeekHeaders } from './WeekViewHeader.hooks';
import { useStyles } from './WeekViewHeader.styles';

const WeekViewHeader = ({ startDay }) => {
  const classes = useStyles();
  const headers = useWeekHeaders({ startDay });

  return (
    <div className={classes.weekViewHeader}>
      {headers.map(day => (
        <div className={classes.dayHeader} key={day}>
          {day}
        </div>
      ))}
    </div>
  );
};

WeekViewHeader.propTypes = {
  // eslint-disable-next-line react/require-default-props
  startDay: string
};

const mapDispatcherToProps = { snackbar: snackbarOn };

const connectedWeekViewHeader = connect(null, mapDispatcherToProps)(WeekViewHeader);

export default connectedWeekViewHeader;
