import React from 'react';

import { jsx } from '@emotion/react';

import { StatusChip } from 'components';
import { StatusValToLabelMapping } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';

import { renderMoreInfoNeeded } from '../../ListView/index.helper';

const Title = modalConfig => {
  const styles = {
    chip: {
      borderRadius: 2,
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: '-0.03em',
      margin: '0 24px'
    },
    moreInfo: { padding: '4px 8px', fontSize: 14, lineHeight: '16px', fontWeight: 500 }
  };

  return (
    <>
      <div>{modalConfig.title}</div>
      {modalConfig?.row && (
        <StatusChip
          enumType={EnumType.RECOMMENDED_TASK_STATUS}
          enumValue={modalConfig?.row?.status}
          label={StatusValToLabelMapping(
            EnumType.RECOMMENDED_TASK_STATUS,
            modalConfig?.row?.status
          )}
          style={styles.chip}
        />
      )}
      {renderMoreInfoNeeded(modalConfig?.row, styles.moreInfo)}
    </>
  );
};

export default Title;
