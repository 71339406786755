/* eslint-disable import/prefer-default-export */
import { ReceiptAttachmentType } from 'scenes/Procurement/constants';
import { getLabelFromValues } from 'scenes/ProjectManagement/components/utils';
import { VendorApprovalStatus } from 'utils/AppConstants';

export const ReceiptAttachmentLayout = ({
  maxFileSizeWarningLabel,
  maxFileSize,
  attachmentRequired = true,
  isVendorInvoice,
  disableVendorInvoiceOption
} = {}) => ({
  fields: {
    customFileName: {
      default: 'customFileName',
      edit: 'customFileName'
    },
    newFiles: {
      default: 'newFiles',
      edit: 'newFiles'
    },
    comment: {
      default: 'comment',
      edit: 'comment'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        direction: 'row',
        width: 454
      },
      contents: [
        {
          options: {
            direction: 'column'
          },
          contents: [
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FileUpload',
                    edit: 'FileUpload'
                  },
                  source: 'newFiles',
                  options: {
                    label: 'Attachment',
                    isRequired: attachmentRequired,
                    multiple: false,
                    maxFileSize,
                    maxFileSizeWarningLabel
                  }
                }
              ]
            },
            !disableVendorInvoiceOption && {
              options: {
                size: 4,
                direction: 'row',
                marginLeft: 10,
                grow: 0
              },
              contents: [
                {
                  component: {
                    default: 'RadioButtonsGroup',
                    edit: null
                  },
                  source: 'receiptAttachmentType',
                  options: {
                    btnDirection: 'Horizontal',
                    inputOptions: Object.values(ReceiptAttachmentType).join(', ')
                  }
                },
                {
                  contents: [
                    {
                      component: null
                    }
                  ]
                }
              ]
            },
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'TextInput',
                    edit: 'TextInput'
                  },
                  source: 'customFileName',
                  options: {
                    label: 'Rename File',
                    placeholder: 'Enter another name for the file',
                    fullWidth: true,
                    alignment: 'left',
                    isRequired: false
                  }
                }
              ]
            },
            isVendorInvoice && {
              options: {
                size: 2,
                direction: 'row',
                grow: 1
              },
              contents: [
                {
                  options: {
                    direction: 'column',
                    padding: 12
                  },
                  contents: [
                    {
                      component: {
                        default: 'TextInput',
                        edit: 'TextInput'
                      },
                      source: 'vendorDocumentNumber',
                      options: {
                        label: 'Vendor Document Number',
                        placeholder: '',
                        fullWidth: false,
                        alignment: 'left'
                      }
                    }
                  ]
                },
                {
                  options: {
                    direction: 'column',
                    padding: 12
                  },
                  contents: [
                    {
                      component: {
                        default: 'SelectInput',
                        edit: 'SelectInput'
                      },
                      source: 'approvalStatus',
                      options: {
                        label: 'Approval Status',
                        placeholder: '',
                        fullWidth: false,
                        alignment: 'left',
                        isRequired: true,
                        valueSet: getLabelFromValues(VendorApprovalStatus)
                      }
                    }
                  ]
                }
              ]
            },
            isVendorInvoice && {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'CurrencyInput',
                    edit: 'CurrencyInput'
                  },
                  source: 'invoiceAmount',
                  options: {
                    label: 'Invoice Amount',
                    placeholder: '',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'TextInput',
                    edit: 'TextInput'
                  },
                  source: 'comment',
                  options: {
                    label: 'Description',
                    placeholder: 'Enter description',
                    fullWidth: true,
                    alignment: 'left',
                    lines: 5
                  }
                }
              ]
            }
          ].filter(Boolean)
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: attachmentRequired
      ? {
          newFiles: {
            type: 'array'
          }
        }
      : [],
    required: attachmentRequired ? ['newFiles'] : []
  },
  validationErrors: attachmentRequired
    ? {
        newFiles: {
          required: 'File is required.'
        }
      }
    : {}
});

export default ReceiptAttachmentLayout;
