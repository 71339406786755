import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';

export const GET_TIMEKEEPING_LEDGER = gql`
  query getTimekeepingLedgerByVisitId($visitId: String!) {
    getTimekeepingLedgerByEventEntityId(id: $visitId) {
      userActionType
      actualStartTimeUTC
      actualEndTimeUTC
      startLatitude
      startLongitude
      endLatitude
      endLongitude
    }
  }
`;

export const useTimekeepingLedger = visitId => {
  const variables = {
    visitId
  };

  return useExtendedQuery(GET_TIMEKEEPING_LEDGER, {
    variables,
    transform: data => data?.getTimekeepingLedgerByEventEntityId,
    defaultData: [],
    skip: !visitId
  });
};
