import React, { useCallback } from 'react';

import { MUIFormComponents } from '@BuildHero/sergeant';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

export const ExclusiveCheckboxInput = ({ form, field, options }) => {
  const { contact, label, color, isRequired, labelPlacement, disabled, onChange } = options;
  const handleFieldChange = useCallback(() => {
    form.setFieldValue('bestContact', contact);
    if (onChange) onChange(contact, form, field);
  }, [form, field, contact, onChange]);

  const error = form.errors && form.errors[field.name];
  const isChecked = form.values.bestContact === contact;
  const text = isRequired ? `* ${label}` : label;

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            checkedIcon={<RadioButtonCheckedIcon />}
            color={color}
            disabled={disabled}
            icon={<RadioButtonUncheckedIcon />}
            onChange={handleFieldChange}
          />
        }
        error={error && error !== ''}
        label={text}
        labelPlacement={labelPlacement}
      />
      <MUIFormComponents.ErrorMessage value={error} />
    </>
  );
};

export const ExclusiveReadOnlyCheckboxInput = ({ form, options }) => {
  const { contact, label, color, labelPlacement } = options;
  const isChecked = form.values.bestContact === contact;
  return (
    <FormControlLabel
      control={<Checkbox checked={isChecked} color={color} />}
      disabled
      label={label}
      labelPlacement={labelPlacement}
    />
  );
};
