import moment from 'moment';
import { object, string } from 'yup';

import { AppConstants } from 'utils/AppConstants';

const getStartOfDayUnixSeconds = number =>
  moment
    .unix(number)
    .startOf('day')
    .unix();

export const templateValidation = agreementInfo =>
  object({
    property: object({ label: string() })
      .nullable()
      .required('Property is required'),
    maintenanceType: object({ label: string() })
      .nullable()
      .required('Maintenance type is required'),
    dueDate: string()
      .nullable()
      .required('Due date is required')
      .test('startingDateValidation', 'N/A', function(dateStr) {
        const val =
          dateStr &&
          moment(dateStr)
            .startOf('day')
            .unix();
        const startDate = getStartOfDayUnixSeconds(agreementInfo.startDate);
        const endDate = getStartOfDayUnixSeconds(agreementInfo.endDate);
        if (Number(val) < moment('1900-1-1').unix()) {
          return this.createError({ message: 'Starting date must be after January 1, 1900' });
        }
        if (Number(val) > moment('3000-1-1').unix()) {
          return this.createError({ message: 'Starting date must be before January 1, 3000' });
        }

        if (Number(val) < startDate) {
          return this.createError({
            message: `Selected date must land between ${moment
              .unix(startDate)
              .format(AppConstants.DATE_FORMAT)} and ${moment
              .unix(endDate)
              .format(AppConstants.DATE_FORMAT)}`
          });
        }

        if (agreementInfo.endDate && Number(val) > endDate) {
          return this.createError({
            message: `Selected date must land between ${moment
              .unix(startDate)
              .format(AppConstants.DATE_FORMAT)} and ${moment
              .unix(endDate)
              .format(AppConstants.DATE_FORMAT)}`
          });
        }
        return true;
      })
  });
