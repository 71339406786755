import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, ButtonType, Modal, SgtForm, ThemeProvider } from '@BuildHero/sergeant';
import { isEmpty, sortBy } from 'lodash';
import { useSelector } from 'react-redux';

import { CustomerRepLayout } from 'meta/Customer/CustomerRep/layout';
import CustomerRepModal, { CustomerRepOperations, RepType } from 'scenes/Customer/CustomerRepModal';
import { validations } from 'services/core';
import { Logger } from 'services/Logger';
import { BestContactType, CustomFieldTypes, Mode } from 'utils/constants';
import { constructSelectOptions } from 'utils/constructSelectOptions';

import { layout } from './CreateMaintenance.layout';
import { templateValidation } from './helper';
import { useAddMaintenance } from './useAddMaintenance';
import { useLazyCustomerRepOptionsByPropertyId } from './useLazyCustomerRepOptionsByPropertyId';

export const CreateMaintenanceModal = ({ open, agreementInfo, handleClose }) => {
  const [service, setService] = useState();
  const [saving, setSaving] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [property, setProperty] = useState();
  const [openCustomerRep, setOpenCustomerRep] = useState(false);
  const [getReps, { data: reps, loading: fetchingReps }] = useLazyCustomerRepOptionsByPropertyId();

  const [addMaintenance] = useAddMaintenance();

  const jobTypes = useSelector(state => state.company?.jobTypes);

  const maintenanceTypes = useMemo(
    () =>
      sortBy(
        jobTypes?.items?.filter(type => type.tagType === CustomFieldTypes.MaintenanceTypes),
        'sortOrder'
      ),
    [jobTypes]
  );
  const maintenanceTypeOptions = useMemo(
    () => constructSelectOptions(maintenanceTypes, 'tagName'),
    [maintenanceTypes]
  );

  useEffect(() => {
    if (!isEmpty(agreementInfo?.propertiesJSON)) {
      let selectedCustomerProperties = [];
      try {
        selectedCustomerProperties = JSON.parse(agreementInfo.propertiesJSON);
      } catch (e) {
        Logger.error('Unable to parse propertiesJSON for Service agreement', e);
      } finally {
        setSelectedProperties(selectedCustomerProperties);
      }
    }
  }, [agreementInfo]);

  useEffect(() => {
    if (property?.value) {
      getReps({ propertyId: property.value });
    }
  }, [getReps, property]);

  const handleComplete = useCallback(
    async formData => {
      setSaving(true);
      await addMaintenance(formData);
      setSaving(false);
      handleClose(true);
    },
    [addMaintenance, handleClose]
  );

  const layoutMeta = useMemo(
    () =>
      layout({
        selectedProperties,
        maintenanceTypeOptions,
        propertyRepOptions: fetchingReps ? [] : reps,
        handleOpenCustomerRepPopUp: () => setOpenCustomerRep(true),
        fetchingReps
      }),
    [selectedProperties, maintenanceTypeOptions, reps, fetchingReps]
  );
  const initialData = useMemo(
    () => ({
      property: null, // for validations to work
      maintenanceType: null,
      propertyRep: null,
      dueDate: null,
      agreementInfo,
      poNumber: agreementInfo?.customerPoNumber || null,
      serviceDescription: null
    }),
    [agreementInfo]
  );

  const validationSchema = useMemo(() => templateValidation(agreementInfo), [agreementInfo]);
  const handleCloseCreateRep = useCallback(
    (repCreated = false) => {
      if (repCreated) getReps({ propertyId: property?.value });
      setOpenCustomerRep(false);
    },
    [getReps, property]
  );

  const onFieldchange = useCallback(async (key, value, formHelpers) => {
    if (key === 'property') {
      // need to set this to trigger refetch of rep data for different property id
      setProperty(value);
      const { setValue } = formHelpers.getFieldHelpers('propertyRep');
      setValue(null);
    }
  }, []);

  const sgtform = useMemo(
    () => (
      <SgtForm
        configuration={layoutMeta}
        initialValues={initialData}
        layout="default"
        validationSchema={validationSchema}
        onCreateService={setService}
        onFieldChange={onFieldchange}
        onSubmit={handleComplete}
      />
    ),
    [handleComplete, initialData, layoutMeta, onFieldchange, validationSchema]
  );

  return (
    <>
      <ThemeProvider>
        <Modal
          actions={
            <Button
              fullWidth
              loading={saving}
              type={ButtonType.PRIMARY}
              onClick={() => service.submit()}
            >
              Add Maintenance
            </Button>
          }
          fullScreen
          open={open}
          title="Add New Maintenance"
          onClose={handleClose}
        >
          {sgtform}
        </Modal>
      </ThemeProvider>
      {openCustomerRep && (
        <CustomerRepModal
          data={{ bestContact: BestContactType.CELLPHONE }}
          handleClose={handleCloseCreateRep}
          layout={CustomerRepLayout}
          mode={Mode.NEW}
          open={openCustomerRep}
          overrideOperationType={CustomerRepOperations.ADD_PROPERTY_REP}
          parent={{
            customerId: agreementInfo.customerId,
            customerPropertyId: property.value,
            tenantId: agreementInfo.tenantId
          }}
          recordSortKey={agreementInfo?.customer?.sortKey}
          repType={RepType.CUSTOMER}
          validationSchema={validations.customerRepSchema}
        />
      )}
    </>
  );
};

export default CreateMaintenanceModal;
