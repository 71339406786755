import React from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import UserPermission from 'components/AppPermissions';

import NamedIcon from './NamedIcon';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.brand?.secondaryBackground ?? theme.palette.background?.light,
    '&.MuiListItem-button:hover': {
      backgroundColor: theme.palette.brand?.background ?? theme.palette.background?.default
    },
    border: '1px solid white'
  },
  list: {
    padding: 0
  }
}));

export default function ActionsMenu(props) {
  const {
    className,
    rowActionButtons,
    rowActions,
    handleMenuClose,
    anchorEl,
    useNewMenuStyle = false
  } = props;
  const classes = useStyles();
  if (!rowActionButtons) return null;
  return (
    <Menu
      anchorEl={anchorEl && anchorEl.ref}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      classes={useNewMenuStyle && { list: classes.list }}
      getContentAnchorEl={null}
      keepMounted
      open={!!anchorEl}
      onClose={handleMenuClose}
    >
      {Object.keys(rowActionButtons).map(buttonKey => (
        <UserPermission
          action={rowActionButtons[buttonKey].caslKey}
          I={rowActionButtons[buttonKey].caslAction}
          key={`actionsMenuItem-${buttonKey}`}
        >
          <MenuItem
            className={className || (useNewMenuStyle && classes.root)}
            testingid={`${rowActionButtons[buttonKey].label}`}
            onClick={() => {
              rowActions(buttonKey, anchorEl && anchorEl.record);
              handleMenuClose();
            }}
          >
            <Grid
              container
              direction={useNewMenuStyle && 'row-reverse'}
              justify={useNewMenuStyle && 'space-between'}
            >
              {rowActionButtons[buttonKey].icon && (
                <Grid alignItems="center" item style={{ display: 'flex', width: 40 }}>
                  <NamedIcon
                    listItem
                    lookUpIconMap={rowActionButtons[buttonKey].lookUpIconMap}
                    name={rowActionButtons[buttonKey].icon}
                  />
                </Grid>
              )}
              <Grid item>
                <ListItemText primary={rowActionButtons[buttonKey].label} />
              </Grid>
            </Grid>
          </MenuItem>
        </UserPermission>
      ))}
    </Menu>
  );
}
