import React from 'react';

import { Field, FieldType } from '@BuildHero/sergeant';

import { secondsToHour } from 'scenes/Payroll/TimeTrackingReport/helpers';

const useStyles = () => ({
  totalsHourTypes: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '2rem'
  },
  hourField: {
    marginRight: '1rem',
    width: '3rem'
  }
});

const HourlyTotals = ({ payrollHourTypes, validHourTypes, hourlyTotals }) => {
  const styles = useStyles();

  const total = secondsToHour(Object.values(hourlyTotals).reduce((acc, v) => acc + v));

  return (
    <div css={styles.totalsHourTypes}>
      <Field
        id="Total"
        innerStyle={{ fontWeight: 700 }}
        label="Total"
        style={styles.hourField}
        type={FieldType.NUMBER}
        value={total ?? 0}
      />
      {payrollHourTypes.map(({ hourTypeAbbreviation }) => {
        const value = secondsToHour(hourlyTotals[hourTypeAbbreviation]);

        if (!value || !validHourTypes.includes(hourTypeAbbreviation)) return <></>;

        return (
          validHourTypes.includes(hourTypeAbbreviation) && (
            <Field
              id={hourTypeAbbreviation}
              innerStyle={{ fontWeight: 700 }}
              key={hourTypeAbbreviation}
              label={hourTypeAbbreviation}
              style={styles.hourField}
              type={FieldType.NUMBER}
              value={value}
            />
          )
        );
      })}
    </div>
  );
};

export default HourlyTotals;
