import React, { useEffect, useState } from 'react';

import { ThemeProvider, TV, Typography } from '@BuildHero/sergeant';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { Placeholder, ResponsiveTable } from 'components';
import ProfileIcon from 'components/Navigation/UserControls/ProfileIcon';
import auditLogsTable from 'meta/Settings/AuditLogs';
import { snackbarOn as snackbar } from 'redux/actions/globalActions';
import { sentryException } from 'services/Logger';
import { backendDateToMoment } from 'utils';
import { getDateFormat } from 'utils/AppConstants';

import { ChangeLogItem, SingleChangePrimitive } from './ChangeLogItem';
import getChangesFromLog from './getChangesFromLog';

// Wrapper for ResponsiveTable custom component
function ChangeLogCell(props) {
  return <ChangeLogItem log={props.record.action} />;
}

const useStyles = makeStyles(theme => ({
  rootContainer: {
    marginTop: theme.spacing(1.5),
    color: theme.palette.text.primary
  },
  avatar: {
    textTransform: 'uppercase',
    background: theme.palette.grayscale(40),
    flexShrink: 0
  },
  changeContainer: {
    marginBottom: theme.spacing(1.5)
  },
  mainLabel: {
    fontSize: 14,
    marginLeft: theme.spacing(0.8),
    flex: '1 0 auto'
  },
  auxiliaryLabel: {
    fontSize: 12,
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1)
  },
  changeLabel: {
    fontSize: 12,
    marginLeft: theme.spacing(1)
  },
  mainTextColor: {
    color: theme.palette.grayscale(20)
  },
  auxiliaryTextColor: {
    color: theme.palette.grayscale(60)
  }
}));
function SingleEntityLogs(props) {
  const { data, showCount = 5, customFilterLogFunc, isLoading, loadingParams } = props;
  const classes = useStyles();
  // Each data item can contain multiple individual field value changes that occurred at the same time.
  // Flatten these into a single array and inject the author name and timestamp into each element.
  // Based on `showCount`, show most recent "n" entries.
  let primitiveChangeList = data
    .sort((a, b) => b.executedDateTime - a.executedDateTime)
    .map(log => ({
      timestamp: backendDateToMoment(log.executedDateTime).format(getDateFormat('DateTime')),
      author: log.executedBy,
      changes: getChangesFromLog(log.action),
      changeType: log.action.executionType,
      customMessage: log.action.customMessage,
      entityType: log.action.auditedEntityType,
      entityField: log.action.field,
      parentEntityType: log.action.auditedEntityParentEntityType
    }))
    .reduce((accumulator, multipleChangesEvent) => {
      const {
        changes,
        author,
        timestamp,
        changeType,
        customMessage,
        entityType,
        parentEntityType
      } = multipleChangesEvent;
      // `changes` array will be empty for some `changeType`s like add and delete, so just add metadata
      const changesWithAuthor =
        changes.length > 0
          ? changes.map(change => ({
              ...change,
              timestamp,
              author,
              changeType,
              customMessage,
              entityType,
              parentEntityType
            }))
          : [{ timestamp, author, changeType, customMessage, entityType, parentEntityType }];
      return accumulator.concat(changesWithAuthor);
    }, []);

  if (customFilterLogFunc) {
    primitiveChangeList = customFilterLogFunc(primitiveChangeList);
  }
  primitiveChangeList = primitiveChangeList.slice(0, showCount);

  return isLoading ? (
    <Placeholder {...loadingParams} />
  ) : (
    <>
      {primitiveChangeList.length === 0 && (
        <Typography className={classes.mainTextColor} variant={TV.BASE}>
          No Activity
        </Typography>
      )}
      {primitiveChangeList.map((change, index) => (
        // Items in change list can be identical because not all data about the change is incluced in each
        // list item. We need indices to distinguish.
        // eslint-disable-next-line react/no-array-index-key
        <ThemeProvider>
          <div
            className={classes.changeContainer}
            key={`changeLogItem-${change.timestamp}-${index}`}
          >
            <div style={{ display: 'flex', alignItems: 'flex-start', padding: '10px 0' }}>
              <ProfileIcon classNames={classes.avatar} employeeName={change.author} />

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 400,
                    flexWrap: 'wrap'
                  }}
                >
                  <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <Typography
                      className={`${classes.mainLabel} ${classes.mainTextColor}`}
                      variant={TV.PRINT}
                    >
                      {`${change.author} `}
                    </Typography>
                    <Typography
                      className={`${classes.mainLabel} ${classes.auxiliaryTextColor}`}
                      variant={TV.PRINT}
                    >
                      {`${change.changeType}${
                        change.field || change.customMessage ? ':' : ' Entity'
                      } `}
                    </Typography>
                  </div>
                  {(change.field || change.customMessage) && (
                    <span className={`${classes.changeLabel} ${classes.auxiliaryTextColor}`}>
                      {change.customMessage ? (
                        change.customMessage
                      ) : (
                        <SingleChangePrimitive data={change} textOnly />
                      )}
                    </span>
                  )}
                </div>
                <Typography
                  className={`${classes.auxiliaryLabel} ${classes.auxiliaryTextColor}`}
                  variant="body2"
                >
                  {change.timestamp}
                </Typography>
              </div>
            </div>
          </div>
        </ThemeProvider>
      ))}
    </>
  );
}

function AuditLogs(props) {
  const {
    dataService,
    disableFilter,
    fullScreen,
    variant,
    auditLogData,
    showCount,
    customFilterLogFunc,
    loadingParams
  } = props;
  const [logData, setLogData] = useState([]);
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (auditLogData || !hasFetched) {
        try {
          const data = auditLogData || (await dataService());
          if (!Array.isArray(data)) {
            throw new TypeError(`Expected array result from ${dataService} but found ${data}`);
          }
          setLogData(
            data.map(log => {
              const { executedDateTime, executedBy, ...others } = log;
              return {
                executedDateTime,
                executedBy,
                action: others
              };
            })
          );
        } catch (e) {
          sentryException(e, { message: 'Error fetching audit logs from data service' });
          snackbar('error', 'Error while fetching activity logs. Please try again.');
        } finally {
          setHasFetched(true);
        }
      }
    }
    fetchData();
  }, [auditLogData]);

  // When we know all `logData` entries will refer to the same entity (e.g. we're displaying
  // the changes made only to a specific job), we can omit the details about the entity
  // and just display a summarized view of the most recent changes.
  return variant === 'singleEntity' ? (
    <SingleEntityLogs
      customFilterLogFunc={customFilterLogFunc}
      data={logData}
      isLoading={!hasFetched && !auditLogData}
      loadingParams={loadingParams}
      showCount={showCount}
    />
  ) : (
    <ResponsiveTable
      customCellComponents={{
        action: ChangeLogCell
      }}
      data={logData}
      defaults={{ sortBy: 'executedDateTime', sortOrder: 'desc' }}
      disableFilter={disableFilter}
      fullScreen={fullScreen}
      isLoading={!hasFetched}
      rowMetadata={auditLogsTable}
    />
  );
}

export default connect(state => ({ user: state.user }), { snackbar })(AuditLogs);
