import React, { useEffect, useState } from 'react';

import { ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { isEqual, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useBulkUpdateLabourRateModifiers } from '../../../mutations';

import styles from '../../styles';

import SaveButton from '../SaveButton';

import LaborRateModifiersTable from './LaborRateModifiersTable';

const LaborRateModifiers = ({
  employees,
  departments,
  isDirty,
  setIsDirty,
  labourRateModifiers,
  labourRateModifiersLoading
}) => {
  const user = useSelector(state => state?.user);
  const [laborRateModifiersToUpdate, setLaborRateModifiersToUpdate] = useState(labourRateModifiers);

  useEffect(() => {
    setLaborRateModifiersToUpdate(labourRateModifiers);
  }, [labourRateModifiers]);

  useEffect(() => {
    setIsDirty(!isEqual(labourRateModifiers, sortBy(laborRateModifiersToUpdate, 'sortOrder')));
  }, [laborRateModifiersToUpdate, labourRateModifiers, setIsDirty]);

  const [
    bulkUpdateLabourRateModifiers,
    { loading: updatingLabourRateModifiers }
  ] = useBulkUpdateLabourRateModifiers();

  const resetSortOrder = unorderedModifiers =>
    unorderedModifiers.map((g, i) => ({ ...g, sortOrder: i }));

  const handleSaveLabourRateModifiers = () => {
    bulkUpdateLabourRateModifiers({
      companyId: user?.tenantCompanyId,
      labourRateModifiers: resetSortOrder(laborRateModifiersToUpdate)
    });
  };

  return (
    <>
      <ThemeProvider>
        <Typography css={styles.greenHeading} variant={TV.BASE} weight={TW.BOLD}>
          Labor Rate Modifiers
        </Typography>
        <LaborRateModifiersTable
          departments={departments}
          employees={employees}
          laborRateModifiers={laborRateModifiersToUpdate}
          loading={updatingLabourRateModifiers}
          resetSortOrder={resetSortOrder}
          setLaborRateModifiers={setLaborRateModifiersToUpdate}
        />
      </ThemeProvider>
      <SaveButton
        disabled={!isDirty}
        loading={labourRateModifiersLoading || updatingLabourRateModifiers}
        onClick={async () => {
          handleSaveLabourRateModifiers();
          setIsDirty(false);
        }}
      />
    </>
  );
};

LaborRateModifiers.propTypes = {
  employees: PropTypes.array,
  departments: PropTypes.array,
  isDirty: PropTypes.bool,
  labourRateModifiers: PropTypes.array,
  labourRateModifiersLoading: PropTypes.bool,
  setIsDirty: PropTypes.func
};

LaborRateModifiers.defaultProps = {
  employees: [],
  departments: [],
  isDirty: false,
  labourRateModifiers: [],
  labourRateModifiersLoading: false,
  setIsDirty: () => {}
};

export default LaborRateModifiers;
