import React from 'react';

import { array, func, object } from 'prop-types';

import FilterButton from '@dispatch/components/FilterButton';

const filterText = {
  departments: {
    label: 'Departments',
    placeholder: 'All departments'
  },
  crews: {
    label: 'Crews',
    placeholder: 'All Crews'
  },
  technicians: {
    label: 'Technicians',
    placeholder: 'Select Technicians'
  }
};

const TechsFilterButton = ({
  filterBy,
  departments,
  crews,
  technicians,
  departmentsResponse,
  crewsResponse,
  techniciansResponse,
  ...props
}) => {
  const handleFilterChange = (field, value) => {
    filterBy({ [field]: value });
  };

  const handleFiltersClear = () => {
    filterBy({
      departments: undefined,
      crews: undefined,
      technicians: undefined
    });
  };

  return (
    <FilterButton
      {...props}
      buttonText="Filter Techs By..."
      filterOptions={{
        departments: departmentsResponse,
        crews: crewsResponse,
        technicians: techniciansResponse
      }}
      filterState={{ departments, crews, technicians }}
      filterText={filterText}
      onFilterChange={handleFilterChange}
      onFiltersClear={handleFiltersClear}
    />
  );
};

TechsFilterButton.propTypes = {
  filterBy: func.isRequired,
  /* eslint-disable react/require-default-props */
  departments: array,
  crews: array,
  technicians: array,
  departmentsResponse: object.isRequired,
  crewsResponse: object.isRequired,
  techniciansResponse: object.isRequired
};

export default TechsFilterButton;
