import React, { useEffect, useMemo, useState } from 'react';

import { TV, TW, Typography } from '@BuildHero/sergeant';

import { BlobProvider } from '@react-pdf/renderer';

import { Placeholder } from 'components';

import CheckboxLineItem from '../CheckboxLineItem';

import { processFormsData } from './JobFormAttachments.utils';

const JobFormAttachments = props => {
  const { forms, dispatchFilesData, filesData } = props;
  const [formsData, setFormsData] = useState(forms);

  useEffect(() => {
    const prepareJobDocuments = async () => {
      const processedJobForms = await processFormsData(forms);
      setFormsData(processedJobForms);
    };
    if (forms.length > 0) {
      prepareJobDocuments();
    }
  }, [forms]);

  const PlaceHolder = useMemo(
    () => (
      <Placeholder
        paddingBottom={0}
        paddingLeft={0}
        paddingRight={0}
        paddingTop={0}
        variant="table"
      />
    ),
    []
  );

  if (formsData === undefined) return PlaceHolder;

  return (
    <>
      <div css={{ marginBottom: 8 }}>
        <Typography variant={TV.L} weight={TW.BOLD}>
          Forms
        </Typography>
      </div>
      {formsData &&
        formsData.map(formData => (
          <BlobProvider document={formData.document}>
            {({ blob, loading }) => {
              return loading ? null : (
                <CheckboxLineItem
                  dispatchFilesData={dispatchFilesData}
                  filesData={filesData}
                  hideDivider
                  reportData={{
                    ...formData,
                    blob,
                    fileSize: blob?.size,
                    fileUrl: blob ? URL.createObjectURL(blob) : null
                  }}
                />
              );
            }}
          </BlobProvider>
        ))}
    </>
  );
};

export default React.memo(JobFormAttachments);
