import { makeStyles } from '@material-ui/core';

import { ElementSizes } from '@dispatch/Dispatch.styles';

export const useStyles = makeStyles(theme => ({
  swimLane: {
    display: 'flex',
    width: `${ElementSizes.laneWidth}px !important`,
    '&:focus': {
      outline: 'none'
    }
  },
  timeContainer: {
    width: ElementSizes.timeWidth,
    overflow: 'hidden',
    position: 'absolute',
    left: ElementSizes.techCellWidth,
    height: '100%'
  }
}));
