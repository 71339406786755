import React, { useState } from 'react';

import { Button, ButtonType, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { Menu, MenuItem, Tooltip, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import { noop } from 'lodash';

import { PropTypes } from 'prop-types';

import { StatusChip } from 'components';
import { enumBackgroundColor, enumIcon } from 'components/StatusChip/helper';
import { getMultiSelectOptions } from 'meta/ListViews';
import { JobStatus, StatusValToLabelMapping } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';

import useUpdateJob from './hooks/useUpdateJob';

const fieldEnumTypeMapping = {
  [EnumType.JOB_BILLING_STATUS]: 'billingStatus',
  [EnumType.JOB_STATUS]: 'status',
  [EnumType.JOB_PROCUREMENT_STATUS]: 'procurementStatus',
  [EnumType.JOB_CLOSEOUT_STATUS]: 'reviewStatus',
  [EnumType.JOB_BILLING_TYPE]: 'billingType'
};

const UIRestrictedStatuses = [JobStatus.EXPORTED];
const useStyles = makeStyles({
  tooltipStatusBackground: ({ theme }) => ({
    backgroundColor: theme?.palette?.support?.grey?.dark,
    padding: theme.spacing(1)
  }),
  tooltipStatusArrow: ({ theme }) => ({
    color: theme?.palette?.support?.grey?.dark
  })
});

export const StatusButtons = ({
  value,
  enumType,
  useStatusLabelMap,
  jobData,
  overrideOptions,
  deriveStatus,
  actionCallback = noop,
  noLabelFormat,
  statusCue,
  tooltipContent,
  overrideHandleStatusChange
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [updateJob] = useUpdateJob();
  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const theme = useTheme();
  const classes = useStyles({
    theme
  });

  const options =
    overrideOptions ||
    getMultiSelectOptions(enumType)?.filter(
      val => val !== value && !UIRestrictedStatuses.includes(val)
    );

  const buttonStyle = {
    backgroundColor: enumBackgroundColor(theme)[enumType]?.[value],
    textTransform: 'none',
    height: 24,
    '&:hover': {
      backgroundColor: enumBackgroundColor(theme)[enumType]?.[value],
      opacity: 0.8
    }
  };

  const handleStatusChange = async statusObj => {
    if (overrideHandleStatusChange) {
      await overrideHandleStatusChange();
    } else {
      let statusPayload = {
        id: jobData?.id,
        version: jobData?.version
      };

      let status;
      if (deriveStatus) {
        status = await deriveStatus(statusObj);
        statusPayload[fieldEnumTypeMapping[enumType]] = status;
      } else {
        statusPayload = { ...statusPayload, ...statusObj };
      }
      const resp = await updateJob(statusPayload);
      await actionCallback(resp?.data?.updateJob);
    }
    handleMenuClose();
  };

  const getButtonComponent = () => (
    <Button
      aria-controls={open ? 'status-dropdown-menu' : undefined}
      aria-expanded={open ? 'true' : undefined}
      aria-haspopup="true"
      css={buttonStyle}
      disableElevation
      endIcon={<ArrowDropDown />}
      size="small"
      startIcon={enumIcon(theme)[enumType]}
      type={ButtonType.PRIMARY}
      onClick={handleMenuOpen}
    >
      <Typography variant={TV.BASE} weight={TW.MEDIUM}>
        {useStatusLabelMap ? StatusValToLabelMapping(enumType, value) : value}
      </Typography>
    </Button>
  );

  return (
    <ThemeProvider>
      {tooltipContent ? (
        <Tooltip
          arrow
          classes={{
            tooltip: classes.tooltipStatusBackground,
            arrow: classes.tooltipStatusArrow
          }}
          title={tooltipContent}
        >
          {getButtonComponent()}
        </Tooltip>
      ) : (
        getButtonComponent()
      )}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        getContentAnchorEl={null}
        id="status-dropdown-menu"
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={handleMenuClose}
      >
        {options?.map(opt => (
          <MenuItem
            css={{
              borderBottom: `1px solid ${theme.palette.grayscale(80)}`,
              marginLeft: 8,
              marginRight: 8,
              padding: 8,
              minWidth: 104,
              flexWrap: 'wrap',
              maxWidth: 150
            }}
            onClick={() => handleStatusChange({ [fieldEnumTypeMapping[enumType]]: opt })}
          >
            {statusCue}
            <StatusChip
              css={{ borderRadius: 2, cursor: 'pointer' }}
              enumType={enumType}
              enumValue={opt}
              label={opt}
              noLabelFormat={noLabelFormat}
              showIcon
            />
          </MenuItem>
        ))}
      </Menu>
    </ThemeProvider>
  );
};

StatusButtons.propTypes = {
  noLabelFormat: PropTypes.bool,
  overrideHandleStatusChange: PropTypes.func,
  statusCue: PropTypes.object,
  tooltipContent: PropTypes.object
};

StatusButtons.defaultProps = {
  noLabelFormat: false,
  overrideHandleStatusChange: null,
  statusCue: null,
  tooltipContent: null
};
