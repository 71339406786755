import { removeNestedJson } from '../../utils';
import InstructionHelper from '../InstructionHelper';

const propertySchemaMapping = props => {
  const strippedCustomer = removeNestedJson(props.parent);
  const propertyAddressTemplate = {
    propertyAddressLine1: 'addressLine1',
    propertyAddressLine2: 'addressLine2',
    propertyCity: 'city',
    propertyState: 'state',
    propertyZipcode: 'zipcode',
    propertyLatitude: 'latitude',
    propertyLongitude: 'longitude',
    propertyId: 'id',
    propertySortkey: 'sortKey',
    propertyHierarchy: 'hierarchy'
  };

  // Forming different address type JSONs
  const propertyAddressJson = {};
  const tagsJson = {
    partitionKey: props.tenantId,
    tenantId: props.tenantId,
    tenantCompanyId: props.tenantCompanyId,
    entityType: 'Tag'
  };
  const notesJson = {
    partitionKey: props.tenantId,
    tenantId: props.tenantId,
    tenantCompanyId: props.tenantCompanyId,
    entityType: 'Note'
  };
  const tagsArray = [];
  const notesArray = [];

  // Flags
  let haspropertyAddressField = false;

  // Iterating JSON keys of filled formik form
  Object.keys(props).forEach(key => {
    // checks if the key is property address template, when the value is not empty transform
    if (propertyAddressTemplate[key] && props[key] && props[key] !== '') {
      haspropertyAddressField = true;
      const templateKey = propertyAddressTemplate[key];
      const value = typeof props[key] === 'string' ? props[key].trim() : props[key];
      propertyAddressJson[templateKey] = value;
      return;
    }

    if (key === 'tags' && props[key]) {
      const localTags = props[key];
      localTags.forEach(tag => {
        tagsArray.push({ ...tagsJson, tagName: tag });
      });
    }

    if (key === 'customerNotes' && props[key]) {
      const localNotes = props[key];
      localNotes.forEach(noteObj => {
        const localNoteJob = noteObj;
        if (localNoteJob.__typename) {
          delete localNoteJob.__typename;
        }
        if (localNoteJob.edited) {
          notesArray.push({ ...notesJson, ...localNoteJob });
        }
      });
    }
  });

  const companyAddresses = [];
  // Iterating props and filling approp
  if (haspropertyAddressField) {
    propertyAddressJson.tenantId = props.tenantId;
    propertyAddressJson.tenantCompanyId = props.tenantCompanyId;
    propertyAddressJson.partitionKey = props.tenantId;
    propertyAddressJson.entityType = 'CompanyAddress';
    propertyAddressJson.addressType = 'propertyAddress';
    propertyAddressJson.isActive = true;
    companyAddresses.push(propertyAddressJson);
  }

  const propertyPayload = {
    companyName: props.companyName,
    customerPropertyTypeValue: props.customerPropertyTypeValue || null,
    accountNumber: props.accountNumber || null,
    billingCustomerId: props.billingCustomerId || null,
    taxRateId: props.taxRateId || null,
    status: 'active',
    isActive: true,
    sameAddress: props.sameAddress === 'true' || props.sameAddress === true || false,
    isTaxable: props.isTaxable === 'true' || props.isTaxable === true || false,
    billTo: props.billTo,
    partitionKey: props.tenantId,
    tenantId: props.tenantId,
    tenantCompanyId: props.tenantCompanyId,
    entityType: 'CustomerProperty'
  };

  if (props.id) {
    propertyPayload.id = props.id;
    propertyPayload.version = props.version;
  }

  if (props.syncNow) {
    propertyPayload.syncStatus = 'Syncing';
  } else {
    propertyPayload.syncStatus = null;
  }

  const data = [{ ...strippedCustomer }, { ...propertyPayload }, { ...propertyAddressJson }];

  const ins = new InstructionHelper();
  let temp = ins.oneToMany(0, 1).oneToMany(1, 2);

  if (tagsArray && tagsArray.length > 0) {
    tagsArray.forEach(tagJson => {
      data.push(tagJson);
      temp = temp.manyToMany(1, data.length - 1, 'companyName', 'tagName');
    });
  }

  if (notesArray && notesArray.length > 0) {
    notesArray.forEach(noteJSON => {
      if (noteJSON.note) {
        data.push(noteJSON);
        temp = temp.oneToMany(1, data.length - 1);
      }
    });
  }
  const { instructionSet } = temp;
  return { data, instructionSet };
};

export default propertySchemaMapping;
