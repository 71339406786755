import labels from 'meta/labels';

const rowDiv = (debug, grow, padding, component, source, options, dependentField) => ({
  options: { debug, grow, padding },
  contents: [{ component, source, options, dependentField }]
});

const addressRow = (addressType, locale, padding, dependentFieldName) => {
  const addressLine1 = `${addressType}AddressLine1`;
  const addressLine2 = `${addressType}AddressLine2`;
  const city = `${addressType}City`;
  const state = `${addressType}State`;
  const zipcode = `${addressType}Zipcode`;
  const longitude = `${addressType}Longitude`;
  const latitude = `${addressType}Latitude`;
  const dependentField = dependentFieldName
    ? {
        fieldName: dependentFieldName,
        operation: 'bool',
        expectedValue: true,
        action: 'DISABLE'
      }
    : null;
  return {
    options: {
      direction: 'row'
    },
    contents: [
      rowDiv(
        false,
        1,
        padding,
        'PlacesSearch',
        addressLine1,
        {
          label: labels.addressLine1[locale],
          setSource: false,
          valuesToSet: [
            {
              [addressLine1]: 'addressLine1',
              [addressLine2]: 'addressLine2',
              [city]: 'city.shortName',
              [state]: 'state.shortName',
              [zipcode]: 'zipcode.shortName',
              [longitude]: 'longitude',
              [latitude]: 'latitude'
            }
          ],
          fullWidth: true,
          isRequired: true
        },
        dependentField
      ),
      rowDiv(
        false,
        1,
        padding,
        'TextInput',
        addressLine2,
        {
          label: labels.addressLine2[locale]
        },
        dependentField
      ),
      rowDiv(
        false,
        1,
        padding,
        'TextInput',
        city,
        {
          label: labels.city[locale],
          isRequired: true
        },
        dependentField
      ),
      rowDiv(
        false,
        1,
        padding,
        'TextInput',
        state,
        {
          label: labels.state[locale],
          isRequired: true
        },
        dependentField
      ),
      rowDiv(
        false,
        1,
        padding,
        'TextInput',
        zipcode,
        {
          label: labels.zipcode[locale],
          isRequired: true
        },
        dependentField
      )
    ]
  };
};

const setSameAddress = (source, target, form) => {
  ['AddressLine1', 'AddressLine2', 'City', 'State', 'Zipcode', 'Longitude', 'Latitude'].forEach(
    field => {
      form.setFieldValue(`${target}${field}`, form.values[`${source}${field}`]);
    }
  );
};

const resetAddress = (type, form) => {
  ['AddressLine1', 'AddressLine2', 'City', 'State', 'Zipcode', 'Longitude', 'Latitude'].forEach(
    field => {
      form.setFieldValue(`${type}${field}`, '');
    }
  );
};

export default (locale, handleTextChange) => {
  const padding = 12;
  const editPadding = 12;
  return {
    fields: {
      logoUrl: 'logoUrl',
      companyName: 'companyName',
      phonePrimary: 'phonePrimary',
      fax: 'fax',
      email: 'email',
      websiteUrl: 'websiteUrl',
      shipping: 'shipping',
      billing: 'billing',
      sameAddress: 'sameAddress'
    },
    layouts: {
      default: {
        options: {
          direction: 'row'
        },
        contents: [
          // Logo
          {
            options: {
              direction: 'column',
              grow: 2
            },
            contents: [rowDiv(false, 1, 0, 'Logo', 'logoUrl', {})]
          },
          // rows
          {
            options: {
              direction: 'column',
              grow: 9,
              wordBreak: 'break-all'
            },
            contents: [
              // 1st row
              {
                options: {
                  direction: 'row'
                },
                contents: [
                  rowDiv(false, 2, [20, padding, 0], 'HighlightText', 'companyName', {
                    label:
                      labels.companyName[locale][0] !== '*'
                        ? labels.companyName[locale]
                        : labels.companyName[locale].substring(1)
                  }),
                  rowDiv(false, 3, [20, padding, 0], 'CombinedAddress', 'shipping', {
                    label: labels.companyAddress[locale]
                  }),
                  rowDiv(false, 3, [20, padding, 0], 'CombinedAddress', 'billing', {
                    label: labels.billingAddress[locale]
                  })
                ]
              },
              // 2nd row
              {
                options: {
                  direction: 'row'
                },
                contents: [
                  rowDiv(false, 2, [20, padding, 20], 'FieldWithLabel', 'phonePrimary', {
                    label:
                      labels.phonePrimary[locale][0] !== '*'
                        ? labels.phonePrimary[locale]
                        : labels.phonePrimary[locale].substring(1)
                  }),
                  rowDiv(false, 3, [20, padding, 20], 'FieldWithLabel', 'email', {
                    label: labels.companyEmail[locale]
                  }),
                  rowDiv(false, 3, [20, padding, 20], 'FieldWithLabel', 'websiteUrl', {
                    label: labels.websiteUrl[locale]
                  })
                ]
              },
              {
                options: {
                  direction: 'row'
                },
                contents: [
                  {
                    options: {
                      grow: 12,
                      padding: [20, padding, 20]
                    },
                    contents: [
                      {
                        component: 'FieldWithLabel',
                        source: 'termsOfService',
                        options: {
                          label: labels.termsOfService[locale]
                        }
                      }
                    ]
                  }
                ]
              },
              {
                options: {
                  direction: 'row'
                },
                contents: [
                  {
                    options: {
                      grow: 12,
                      padding: [20, padding, 20]
                    },
                    contents: [
                      {
                        component: 'FieldWithLabel',
                        source: 'purchaseOrderTermsAndConditions',
                        options: {
                          label: labels.purchaseOrderTermsAndConditions[locale]
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            options: {
              direction: 'column',
              grow: 1,
              debug: false
            },
            contents: []
          }
        ]
      },
      edit: {
        options: {
          direction: 'row'
        },
        contents: [
          // LogoButton
          {
            options: {
              direction: 'column',
              grow: 0
            },
            contents: [
              rowDiv(false, 1, [20, 15, 0, 0], 'LogoButton', 'logoUrl', { label: 'Add Logo' })
            ]
          },
          // rows
          {
            options: {
              direction: 'column',
              grow: 1
            },
            contents: [
              // 1st row
              {
                options: {
                  direction: 'row'
                },
                contents: [
                  rowDiv(
                    false,
                    1,
                    [20, editPadding, 0],
                    'Subtitle',
                    labels['Company Infomation'][locale],
                    {}
                  )
                ]
              },
              {
                options: {
                  direction: 'row'
                },
                contents: [
                  rowDiv(false, 1, editPadding, 'TextInput', 'companyName', {
                    label:
                      labels.companyName[locale][0] !== '*'
                        ? labels.companyName[locale]
                        : labels.companyName[locale].substring(1),
                    isRequired: true
                  }),
                  rowDiv(false, 1, editPadding, 'TextInput', 'phonePrimary', {
                    label:
                      labels.phonePrimary[locale][0] !== '*'
                        ? labels.phonePrimary[locale]
                        : labels.phonePrimary[locale].substring(1),
                    isRequired: true
                  }),
                  rowDiv(false, 1, editPadding, 'TextInput', 'fax', {
                    label: labels.fax[locale]
                  }),
                  rowDiv(false, 1, editPadding, 'TextInput', 'email', {
                    label: labels.companyEmail[locale],
                    isRequired: true
                  }),
                  rowDiv(false, 1, editPadding, 'TextInput', 'websiteUrl', {
                    label: labels.websiteUrl[locale]
                  })
                ]
              },
              // 2nd row
              {
                options: {
                  direction: 'row'
                },
                contents: [
                  rowDiv(
                    false,
                    1,
                    [20, editPadding, 0],
                    'Subtitle',
                    labels.companyAddress[locale],
                    {}
                  )
                ]
              },
              addressRow('shipping', locale, editPadding),
              // 3rd row
              {
                options: {
                  direction: 'row'
                },
                contents: [
                  rowDiv(
                    false,
                    1,
                    [20, editPadding, 0],
                    'Subtitle',
                    labels.billingAddress[locale],
                    {}
                  )
                ]
              },
              {
                options: {
                  direction: 'column'
                },
                contents: [
                  {
                    options: {
                      direction: 'row',
                      grow: 0,
                      padding: [0, editPadding, 0]
                    },
                    contents: [
                      {
                        component: 'CheckboxInput',
                        source: 'sameAddress',
                        options: {
                          label: labels.useBusinessAddress[locale],
                          onChange: (currentValue, form) => {
                            if (currentValue) {
                              setSameAddress('shipping', 'billing', form, false);
                            } else {
                              resetAddress('billing', form);
                            }
                          }
                        }
                      }
                    ]
                  }
                ]
              },
              addressRow('billing', locale, editPadding, 'sameAddress'),
              {
                options: {
                  direction: 'row'
                },
                contents: [
                  rowDiv(false, 1, editPadding, 'TextInput', 'termsOfService', {
                    label: labels.termsOfService[locale],
                    lines: 15
                  })
                ]
              },
              {
                options: {
                  direction: 'row'
                },
                contents: [
                  rowDiv(false, 1, editPadding, 'TextInput', 'purchaseOrderTermsAndConditions', {
                    label: labels.purchaseOrderTermsAndConditions[locale],
                    lines: 15,
                    onChange: handleTextChange,
                    maxLength: 2000
                  })
                ]
              }
            ]
          }
        ]
      }
    }
  };
};
