/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';

import { Field, FieldType, Input, Select, ThemeProvider } from '@BuildHero/sergeant';
import { Box, Divider, IconButton } from '@material-ui/core';

import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import sortBy from 'lodash/sortBy';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { DefaultButton, Spinner } from 'components';
import Context from 'components/Context';
import { getPropertyAssetById } from 'scenes/Customer/Assets/gql';
import AmplifyService from 'services/AmplifyService';
import { Logger } from 'services/Logger';
import { getTenantSettingValueForKey } from 'utils';

import { defaultMaintenanceData } from '../../constants';

import { addPropertyAssetsToCustomerProperty, getServiceAgreementsForAsset } from '../../gql';

import { autoCompleteInputProps } from './constants';
import { updateItemInArray } from './helpers';
import useStyles from './MaintenanceTasks.styles';
import PartsAndMaterials from './PartsAndMaterials';

import warningIcon from './warningIcon.svg';

const ItemWrapper = ({ label, children, ...itemProps }) => (
  <Box display="flex" flexDirection="column" flexGrow={1} mr={2} mt={2} width="30%" {...itemProps}>
    {children}
  </Box>
);

const MaintenanceTasks = ({ options, field, form, user }) => {
  const maintenanceTaskTemplates = field.value || [];
  const { assetOptions, setAssetOptions, isLoadingAssets } = options;
  const { setFieldValue } = form;
  const [propertyId, setPropertyId] = useState(form.values.propertyId);

  const [partsAndMaterials, setPartsAndMaterials] = useState([]);
  const [associatedPartsAndMaterials, setAssociatedPartsAndMaterials] = useState([]);
  const [modalToShow, setModalToShow] = useState(''); // 'partsAndMaterialsFound', 'addParts', 'suggestedAndAddParts'
  const [task, setTask] = useState(null);
  const [index, setIndex] = useState(null);
  const [assetWarningComponents, setAssetWarningComponents] = useState(
    new Array(maintenanceTaskTemplates.length)
  );

  const assetTypes = Context.getCompanyContext()?.getCompany?.assetTypes;
  const isAssetEnabled = getTenantSettingValueForKey('assetTrackingAgainstTask') === 'true';

  const { client } = AmplifyService.appSyncClient();

  const classes = useStyles();
  const handleEdit = useCallback(
    (index, value) => {
      let updatedTemplates;
      if (value) {
        updatedTemplates = updateItemInArray({ index, list: maintenanceTaskTemplates, value });
      } else {
        updatedTemplates = maintenanceTaskTemplates;
        // eslint-disable-next-line
        updatedTemplates?.splice(index, 1);
        const cloned = [...assetWarningComponents];
        cloned?.splice(index, 1);
        setAssetWarningComponents(cloned);
      }
      setFieldValue('maintenanceTaskTemplates', updatedTemplates);
    },
    [maintenanceTaskTemplates, setFieldValue, assetWarningComponents]
  );

  useEffect(() => {
    // set the asset select on first load
    const updatedTemplates = maintenanceTaskTemplates.map(t => ({
      ...t,
      asset: t.assetId
        ? { label: t.asset?.assetName, value: t.assetId, assetTypeId: t.asset?.assetTypeId }
        : undefined
    }));
    setFieldValue('maintenanceTaskTemplates', updatedTemplates);
  }, []);

  useEffect(() => {
    // clear asset selection on propertyId change
    if (form.values.propertyId !== propertyId) {
      const updatedTemplates = maintenanceTaskTemplates.map(t => ({
        ...t,
        asset: undefined
      }));
      setFieldValue('maintenanceTaskTemplates', updatedTemplates);
      setPropertyId(form.values.propertyId);
    }
  }, [form.values.propertyId]);

  useEffect(() => {
    setModalToShow(associatedPartsAndMaterials.length > 0 ? 'partsAndMaterialsFound' : modalToShow);
  }, [associatedPartsAndMaterials]);

  const handleCreateAsset = async (assetName, i) => {
    try {
      const { data } = await client.mutate({
        mutation: addPropertyAssetsToCustomerProperty,
        variables: {
          partitionKey: user.tenantId,
          data: {
            customerPropertyId: form.values.propertyId,
            propertyAssets: [{ assetName }]
          }
        }
      });
      const asset = {
        label: assetName,
        value: data.addPropertyAssetsToCustomerProperty[0].id
      };
      const cloned = [...form.values.maintenanceTaskTemplates];
      cloned[i] = { ...cloned[i], asset };
      setAssetOptions(sortBy([...assetOptions, asset], 'label'));
      form.setFieldValue('maintenanceTaskTemplates', cloned);
    } catch (error) {
      Logger.error(error);
    } finally {
      const cloned = [...assetWarningComponents];
      cloned[i] = null;
      setAssetWarningComponents(cloned);
    }
  };

  const queryAssetForServiceAgreements = async (assetId, i) => {
    try {
      const { data } = await client.query({
        query: getServiceAgreementsForAsset,
        variables: { id: assetId }
      });
      const { serviceAgreements } = data.getPropertyAssetById;

      const cloned = [...assetWarningComponents];
      if (serviceAgreements.length === 1) {
        const { id, agreementName } = serviceAgreements[0];

        cloned[i] = (
          <div>
            This asset is already covered by{' '}
            <Link to={`/serviceAgreement/view/${id}`}>{agreementName}</Link>.
          </div>
        );
      } else if (serviceAgreements.length > 1) {
        cloned[i] = (
          <div>
            This <Link to={`/asset/view/${assetId}`}>asset</Link> is already covered by multiple
            service agreements.
          </div>
        );
      } else {
        cloned[i] = null;
      }
      setAssetWarningComponents(cloned);
    } catch (error) {
      Logger.error(error);
    }
  };

  return (
    <>
      <Box className={classes.container} display="flex" flexDirection="column">
        <ThemeProvider>
          {maintenanceTaskTemplates.map((task, i) => {
            const { maintenanceTaskRequiredParts } = task;
            const partsAndMaterials =
              maintenanceTaskRequiredParts?.items || maintenanceTaskRequiredParts || [];

            const handleTaskEdit = async (taskValue, key) => {
              if (key === 'asset') {
                const { data } = await client.query({
                  query: getPropertyAssetById,
                  variables: {
                    id: taskValue.value // asset id
                  }
                });

                const { items } = data.getPropertyAssetById.maintenanceTaskRequiredParts;
                // need to dedupe partsAndMaterials and associatedPartsAndMaterials
                const partsAndMaterialsIds = partsAndMaterials.map(({ productId }) => productId);
                const filteredItems = items
                  .filter(({ productId }) => !partsAndMaterialsIds.includes(productId))
                  .map(({ product, quantity }) => ({
                    productId: product.id,
                    quantity
                  }));
                setAssociatedPartsAndMaterials(filteredItems);
                setTask(task);
                setIndex(i);
              }
              const value = { ...task, [key]: taskValue };

              handleEdit(i, value);
            };
            const handleTaskDelete = () => {
              handleEdit(i);
            };

            return (
              <>
                {i !== 0 && <Divider />}
                <Box display="flex" flexWrap="wrap" key={i} pb={2}>
                  {isAssetEnabled && (
                    <>
                      <ItemWrapper>
                        {isLoadingAssets ? (
                          <Spinner size={19.6} styles={{ margin: 5 }} />
                        ) : (
                          <>
                            <Select
                              creatable
                              inputProps={autoCompleteInputProps}
                              isDisabled={!form.values.propertyId}
                              label="Asset"
                              menuHeight={108}
                              options={assetOptions}
                              placeholder={
                                form.values.propertyId
                                  ? 'Select or Create Asset'
                                  : 'Please select a property first.'
                              }
                              value={task.asset}
                              warning={!!assetWarningComponents[i]}
                              onChange={option => {
                                handleTaskEdit(option, 'asset');
                                queryAssetForServiceAgreements(option.value, i);
                              }}
                              onCreateOption={assetName => {
                                handleCreateAsset(assetName, i);
                              }}
                            />
                            {assetWarningComponents[i] && (
                              <div style={{ display: 'flex', alignItems: 'center', paddingTop: 3 }}>
                                <div style={{ width: 21, height: 21, marginRight: 3 }}>
                                  <img src={warningIcon} />
                                </div>
                                {assetWarningComponents[i]}
                              </div>
                            )}
                          </>
                        )}
                      </ItemWrapper>
                      <ItemWrapper>
                        <Field
                          label="Asset Type"
                          type={FieldType.TEXT}
                          value={
                            assetTypes.items.find(({ id }) => id === task.asset?.assetTypeId)
                              ?.tagName || '-'
                          }
                        />
                      </ItemWrapper>
                    </>
                  )}
                  <ItemWrapper>
                    <Input
                      label="Task name"
                      value={task.maintenanceTaskName}
                      onChange={e => handleTaskEdit(e.target.value, 'maintenanceTaskName')}
                    />
                  </ItemWrapper>
                  <ItemWrapper width="50%">
                    <Input
                      inputProps={{ style: { padding: '1px 0' } }}
                      label="Task Description"
                      multiline
                      value={task.maintenanceTaskDescription}
                      onChange={e => handleTaskEdit(e.target.value, 'maintenanceTaskDescription')}
                    />
                  </ItemWrapper>
                  <Box alignItems="flex-end" display="flex">
                    <DefaultButton
                      buttonProps={partsAndMaterials.length === 0 ? {} : { endIcon: <EditIcon /> }}
                      color="secondary"
                      handle={() => {
                        setIndex(i);
                        setPartsAndMaterials(partsAndMaterials);
                        setModalToShow('addParts');
                      }}
                      key="addPartsAndMaterials"
                      label={
                        partsAndMaterials.length === 0
                          ? 'ADD PARTS AND MATERIALS'
                          : `${partsAndMaterials.length} Parts and Materials Added`
                      }
                      style={{ marginRight: 8 }}
                      variant="outlined"
                    />
                  </Box>
                  <Box display="flex" style={{ marginLeft: 'auto', alignItems: 'flex-end' }}>
                    <IconButton
                      aria-label="Delete"
                      key={`iconDeleteButton${task.id}`}
                      style={{ padding: '8px 12px 8px 12px' }}
                      onClick={handleTaskDelete}
                    >
                      <DeleteIcon
                        className={classes.iconStyle}
                        key={`deleteIconButton${task.id}`}
                      />
                    </IconButton>
                  </Box>
                </Box>
              </>
            );
          })}
        </ThemeProvider>
        <Box display="flex" mt={2}>
          <DefaultButton
            buttonProps={{ startIcon: <AddCircleOutline /> }}
            label="Add Maintenance Task"
            variant={null}
            onClick={() => {
              form.setFieldValue('maintenanceTaskTemplates', [
                ...maintenanceTaskTemplates,
                ...defaultMaintenanceData.maintenanceTaskTemplates
              ]);
              setAssociatedPartsAndMaterials([]);
            }}
          />
        </Box>
      </Box>
      <PartsAndMaterials
        associatedPartsAndMaterials={associatedPartsAndMaterials}
        data={partsAndMaterials}
        modalToShow={modalToShow}
        setModalToShow={setModalToShow}
        onAddItem={value => {
          const val = { ...task, maintenanceTaskRequiredParts: value };
          handleEdit(index, val);
        }}
      />
    </>
  );
};

MaintenanceTasks.defaultProps = {
  tasks: [],
  isAssetEnabled: false
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(MaintenanceTasks);
