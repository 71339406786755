import { useCallback } from 'react';

import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { TaskTemplateEditType } from '../constants';
import { mutationOperations, updateTaskTemplateCacheForTaskTemplateSAQuery } from '../helper';

import {
  MergedTaskTemplateFragment,
  taskTemplateChecklistsOnServiceAgreement
} from './useTaskTemplateChecklistsOnServiceAgreement';

const UPDATE_TASK_TEMPLATE_IN_SA = gql`
  mutation updateTaskTemplateOnServiceAgreement($serviceAgreementId: String!
    $propertyAssetId: String
    $customerPropertyId: String
    $taskTemplateId: String!
    $input: TaskTemplateUpdateInput!) {
      updateTaskTemplateOnServiceAgreement(
        serviceAgreementId: $serviceAgreementId,
        customerPropertyId: $customerPropertyId, 
        taskTemplateId: $taskTemplateId, 
        propertyAssetId: $propertyAssetId, 
        input: $input) {
      ${MergedTaskTemplateFragment}
    }
  }
`;

export const useUpdateTaskTemplateInServiceAgreement = ({
  serviceAgreementId,
  propertyAssetId,
  customerPropertyId,
  checklistId,
  ...options
} = {}) => {
  const update = useCallback(
    (cache, { data: { updateTaskTemplateOnServiceAgreement } }, ref) => {
      const variables = { serviceAgreementId };
      if (propertyAssetId) {
        variables.propertyAssetId = propertyAssetId;
      } else {
        variables.customerPropertyId = customerPropertyId;
      }

      const cachedData = cache.readQuery({
        query: taskTemplateChecklistsOnServiceAgreement,
        variables
      });

      if (!cachedData) return;

      const parentId =
        ref.editType === TaskTemplateEditType.NONE &&
        ref.taskTemplateId !== updateTaskTemplateOnServiceAgreement.id
          ? ref.taskTemplateId
          : '';

      const updatedTaskTemplateChecklists = updateTaskTemplateCacheForTaskTemplateSAQuery(
        cachedData,
        checklistId,
        updateTaskTemplateOnServiceAgreement,
        mutationOperations.UPDATE,
        ref.taskTemplateId,
        parentId
      );

      cache.writeQuery({
        query: taskTemplateChecklistsOnServiceAgreement,
        variables,
        data: { taskTemplateChecklistsOnServiceAgreement: updatedTaskTemplateChecklists }
      });
    },
    [checklistId, customerPropertyId, propertyAssetId, serviceAgreementId]
  );
  return useExtendedMutation(UPDATE_TASK_TEMPLATE_IN_SA, {
    serializer: ({
      id,
      name,
      description,
      startDateTime,
      interval,
      numberOfOccurrences,
      laborEstimate,
      forms,
      isSingleJob,
      partsInput
    }) => {
      return {
        variables: {
          serviceAgreementId,
          propertyAssetId,
          customerPropertyId: propertyAssetId ? undefined : customerPropertyId,
          taskTemplateId: id,
          input: {
            name,
            description,
            startDateTime,
            interval,
            numberOfOccurrences,
            laborEstimate,
            formsInput: forms?.map(f => ({ id: f.id, isRequired: f.isRequired })),
            partsInput,
            isSingleJob
          }
        }
      };
    },
    update,
    transform: ({ updateTaskTemplate }) => updateTaskTemplate,
    onError: (transformedData, snackbarOn) => snackbarOn('error', 'Unable to update Task'),
    ...options
  });
};
