import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { Button, ThemeProvider } from '@BuildHero/sergeant';
import { Box, Grid } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty, sortBy } from 'lodash';
import { connect, useSelector } from 'react-redux';

import { AuditLogs, Context, Tab, Tabs, withMultipleForms } from 'components';
import Attachment from 'components/AttachmentSection';
import SendEmailPopUp from 'components/BuildHeroFormComponents/SendEmailPopUp';
import { parseHTMLForSmartFields } from 'components/CKEditor/CKEditor.smartfield.utils';
import { generatePDF, reinsertSmartfields } from 'components/CKEditor/CKEditor.utils';
import FormSection from 'components/FormSection';
import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';
import { MIXPANEL_EVENT, MIXPANEL_PAGE_NAME } from 'constants/mixpanelEvents';
import useBillingHourTypes from 'customHooks/useBillingHourTypes';
import useEmployees from 'customHooks/useEmployees';
import { useGetPricebookById } from 'customHooks/useGetPricebookById';
import useLabourRateGroups from 'customHooks/useLabourRateGroups';
import useLabourTypes from 'customHooks/useLabourTypes';
import usePayrollHourTypes from 'customHooks/usePayrollHourTypes';
import { snackbarOn } from 'redux/actions/globalActions';
import { useDeleteAttachmentById } from 'scenes/RecommendedTasks/mutations';

import { CustomerPropertyService, CustomerService, QuoteService } from 'services/core';
import { sendMixpanelEvent } from 'services/mixpanel';
import {
  checkPermission,
  getCombinedAddress,
  getTenantSettingValueForKey,
  logErrorWithCallback
} from 'utils';
import { AppConstants, PermissionConstants, QuoteStatus, TaskConstants } from 'utils/AppConstants';
import { DEFAULT_PRICEBOOK_MSG, EntityType } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { SCOPE_AND_PRICING_TAB } from '../Jobs/DetailView/JobTabs/QuotesFromJobHelper/addIncurredCosts/constants';
import { useExternalMessagesQuery } from '../Settings/Company/CommunicationsSection/service';

import RecommendedTaskAlert from './components/Alerts/RecommendedTaskAlert';
import StatusAlert from './components/Alerts/StatusAlert';
import CKEditorModal from './components/Modals/CKEditorModal';
import CustomerPropertyModal from './components/Modals/CustomerPropertyModal';
import PageHeader from './components/PageHeader';
import Profitability from './components/Profitability';
import QuoteSettings from './components/QuoteSettings';
import quoteSettings from './components/QuoteSettings/defaultQuoteSettings';
import ReadonlyWrapper from './components/ReadonlyWrapper';
import DocHistory from './components/Tabs/DocHistory';
import Info from './components/Tabs/Info/Info';
import { getCustomerRepDetails } from './components/Tabs/Info/utils/Info.reps.utils';
import { getEmployeeName } from './components/Tabs/Info/utils/Info.utils';
import InternalNotes from './components/Tabs/InternalNotes';
import Tasks from './components/Tasks';
import { SettingConstants } from './constants';
import QuotesUtils from './index.utils';
import reducer, { ACTION } from './reducer';
import {
  addQuoteLineProducts,
  addQuoteLineProductsOnTask,
  addTaskGroup,
  addTaskService,
  cancelQuoteMutation,
  cloneOrReopenQuoteMutation,
  createJobMutation,
  createQuoteVersion,
  deleteLaborLineItem,
  deleteLineItem,
  deleteProductEntry,
  deleteQuoteLineTask,
  deleteTaskGroup,
  existingJobMutation,
  getAttachmentsForQuote,
  getQuoteActivityLogs,
  getQuoteById,
  getVersionedQuoteById,
  handleQuoteAttachment,
  queryAndSetQuoteLineTasks,
  setPrimaryVersionMutation,
  shareQuoteMutation,
  updateLaborLineItems,
  updateOverrideAmountOnQuote,
  updateQuoteLineProductsOnTask,
  updateQuoteMutation,
  updateQuoteRep,
  updateTaskGroup,
  updateTaskService,
  updateTaxRateOnQuote,
  useQuoteSettingsQuery,
  useQuoteTemplateQuery,
  useServiceAgreementsQuery
} from './service';
import { styles } from './styles';
import {
  filterQuoteLogs,
  getBillingRates,
  getLaborTypesWithPayrollCosts,
  getQuoteName
} from './utils/helper';
import { changeVersionsView, formatVersionOptionData } from './utils/utils.versions';

const QuoteDetail = props => {
  const { computedMatch, snackbar, history } = props;
  const quoteId = computedMatch?.params?.id;
  const initialState = {
    loading: false,
    quoteInfo: {},
    property: {},
    customFormdata: {},
    versions: {}
  };
  const quoteService = new QuoteService();
  const user = useSelector(s => s.user);
  const hasPermissionToEditQuote = checkPermission('edit', PermissionConstants.OBJECT_QUOTES, user);

  // withMultipleForms HOC props
  const { getHandleCreateService, getHandleComplete } = props;

  const [quoteState, dispatch] = useReducer(reducer, initialState);
  const [laborRates, setLaborRates] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [ckEditorData, setCKEditorData] = useState('');
  const companyContext = Context.getCompanyContext();
  const [propertiesAndRepsData, setPropertiesAndRepsData] = useState({});
  const [propertyDetails, setPropertyDetails] = useState({});
  const [recordSortKey, setRecordSortKey] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [formService, setFormService] = useState({});
  const [quoteVersionsOptionData, setQuoteVersionsOptionData] = useState([]);
  const [hasMultipleVersions, setHasMultipleVersions] = useState(false);
  const [primaryQuoteId, setPrimaryQuoteId] = useState('');
  const [primaryQuoteInfo, setPrimaryQuoteInfo] = useState({});
  const [poNumber, setPoNumber] = useState('');
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [quoteName, setQuoteName] = useState('');
  const [isOverridesModalOpen, setIsOverridesModalOpen] = useState(false);
  const [currentSettings, setCurrentSettings] = useState();
  const [updatedQuoteInfo, setUpdatedQuoteInfo] = useState({});
  const [updatedQuoteNotes, setUpdatedQuoteNotes] = useState({});
  const [openPropertyModal, setOpenPropertyModal] = useState(false);
  const [quoteTags, setQuoteTags] = useState([]);
  const [tasksOnCustomerProperty, setTasksOnCustomerProperty] = useState([]);
  const [refreshCKEditor, setRefreshCKEditor] = useState({ isTrue: false, data: null });
  const [accountManagerOptions, setAccountManagerOptions] = useState([]);
  const [defaultPriceBookId, setDefaultPriceBookId] = useState('');
  const [defaultPriceBookMsg, setDefaultPriceBookMsg] = useState('');
  const [recommendedTasks, setRecommendedTasks] = useState([]);
  const [addQuoteLoading, setAddQuoteLoading] = useState(false);
  const companyPriceBookId = useSelector(s => s.company.defaultPriceBookId);
  // display the Quote's info tab by default
  const selectedTab = useRef(0);
  const initialTab = useRef(null);
  const generatedPDFSizeRef = useRef(0);
  const flags = useFlags();
  const [deleteAttachmentById] = useDeleteAttachmentById();
  const recommendedTasksEnabled = flags[FeatureFlags.RECOMMENDED_TASKS_WORKFLOW];

  const {
    quoteAttachments,
    quoteInfo: { quoteLineTasks = {} },
    quoteInfo: { quoteLineProducts = {} },
    quoteInfo,
    property,
    quotePurchaseOrders
  } = quoteState || {};
  const serviceProps = {
    dispatch,
    tenantId: user.tenantId,
    quoteInfo,
    snackbar,
    propertyId: property.id,
    quoteState,
    setQuoteVersionsOptionData,
    setHasMultipleVersions,
    quoteVersionsOptionData,
    primaryQuoteId
  };

  const isReadOnly = QuotesUtils.isQuoteReadOnly(
    hasPermissionToEditQuote,
    quoteInfo.status,
    primaryQuoteInfo.status
  );

  const { data: companyQuoteTemplate } = useQuoteTemplateQuery(user.tenantId, snackbar);
  const { data: companyQuoteSettings } = useQuoteSettingsQuery(
    user.tenantId,
    user.tenantCompanyId,
    snackbar
  );
  const { data: companyServiceAgreements } = useServiceAgreementsQuery(
    user.tenantId,
    user.tenantCompanyId,
    snackbar
  );
  const { data: companyExternalMessages } = useExternalMessagesQuery(
    user.tenantId,
    user.tenantCompanyId,
    snackbar
  );
  const getPricebookById = useGetPricebookById();
  const [companyEmployees] = useEmployees();
  const [payrollHourTypes, payrollHourTypesLoading] = usePayrollHourTypes();
  const [labourRateGroups, labourRateGroupsLoading] = useLabourRateGroups();
  const [billingHourTypes, billingHourTypesLoading] = useBillingHourTypes({
    filter: { isActive: { eq: true } }
  });
  const [labourTypes, labourTypesLoading] = useLabourTypes({
    filter: { isActive: { eq: true }, isArchived: { eq: false } }
  });
  const isAssetEnabled = getTenantSettingValueForKey('assetTrackingAgainstTask') === 'true';

  async function fetchQuoteInfo(localQuoteId) {
    const quoteResponse = await getQuoteById(localQuoteId, user, snackbar);
    if (quoteResponse) {
      const customSettings = JSON.parse(quoteResponse?.settingsJSON);
      setCurrentSettings(customSettings || quoteSettings(isAssetEnabled));
      dispatch({ type: ACTION.SET_QUOTE_INFO, payload: { ...quoteResponse } });
      setPrimaryQuoteId(quoteResponse?.id);
      setPrimaryQuoteInfo(quoteResponse);
      setPoNumber(quoteResponse?.customerPoNumber);
      formatVersionOptionData(
        quoteResponse,
        setQuoteVersionsOptionData,
        snackbarOn,
        setHasMultipleVersions,
        setQuoteTags
      );
    }
  }

  async function fetchVersionedQuoteInfo(id) {
    const quoteResponse = await getVersionedQuoteById(id, user, snackbar);
    if (quoteResponse) {
      const customSettings = JSON.parse(quoteResponse.settingsJSON);
      setCurrentSettings(customSettings || quoteSettings(isAssetEnabled));
      dispatch({ type: ACTION.SET_QUOTE_INFO, payload: { ...quoteResponse } });
    }
  }

  const pricebook = useMemo(() => getPricebookById(defaultPriceBookId), [
    defaultPriceBookId,
    getPricebookById
  ]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const versionId = urlParams.get('versionId');
    if (versionId) {
      (async () => {
        await fetchQuoteInfo(quoteId);
        fetchVersionedQuoteInfo(versionId);
      })();
    } else if (quoteId) {
      fetchQuoteInfo(quoteId);
    }
  }, [quoteId]);

  const performQuery = async (selectedRep, propertyData, isPropertyChange, customerService) => {
    if (!user?.tenantId || !propertyData.id) {
      return null;
    }
    try {
      const recordSortKeyResp = propertyData.parentSortKey;
      const { data } = await customerService.getPropertiesAndRepsByCustomer(
        user.tenantId,
        recordSortKeyResp
      );
      setRecordSortKey(recordSortKeyResp);
      if (data?.getCustomer) {
        let currentProperty = '';
        const customerProperties = data.getCustomer.customerProperties.items;
        const selectedProperty = customerProperties.find(p => p.id === propertyData.id);
        if (selectedProperty?.priceBookId) {
          setDefaultPriceBookId(selectedProperty?.priceBookId);
          if (isEmpty(quoteInfo)) {
            setDefaultPriceBookMsg(DEFAULT_PRICEBOOK_MSG.PROPERTY);
          }
        } else if (data.getCustomer.priceBookId) {
          setDefaultPriceBookId(data.getCustomer.priceBookId);
          if (isEmpty(quoteInfo)) {
            setDefaultPriceBookMsg(DEFAULT_PRICEBOOK_MSG.CUSTOMER);
          }
        } else {
          setDefaultPriceBookId(companyPriceBookId);
        }
        const customerReps = data.getCustomer.customerReps.items;
        customerProperties.forEach(propertyItem => {
          const localProperty = propertyItem;
          const processedCustomerReps = propertyItem.customerReps.items.map(
            rep => rep.mappedEntity
          );
          localProperty.processedCustomerReps = processedCustomerReps;
        });

        if (selectedRep) {
          currentProperty = customerProperties.find(
            propertyItem => propertyItem?.id === propertyData.id
          );
        }

        let customerRepId = '';

        const repExist = customerReps.some(c => c.id === quoteInfo?.orderedById);
        if (repExist) {
          customerRepId = quoteInfo?.orderedById;
        } else if (selectedRep?.customerRep) {
          customerRepId = selectedRep.customerRep;
        } else if (isPropertyChange) {
          customerRepId = customerReps[0]?.id || '';
        } else {
          customerRepId = propertiesAndRepsData.customerRep || customerReps[0]?.id || '';
        }

        let newState = {
          properties: customerProperties,
          customer: data.getCustomer,
          recordSortKey,
          reps: currentProperty
            ? currentProperty.processedCustomerReps
            : propertiesAndRepsData.reps,
          customerReps: customerReps || [],
          customerRep: customerRepId
        };

        if (selectedRep) {
          // get the selected property rep object from the list of reps
          const propertyRepData = selectedRep.propertyRep
            ? newState.reps.find(r => r.id === selectedRep.propertyRep)
            : null;
          newState = {
            ...newState,
            ...selectedRep,
            // representativeData stores the selected property rep object, and propertyRep only stores the id
            representativeData: propertyRepData || propertiesAndRepsData.representativeData,
            propertyRep: propertyRepData?.id || propertiesAndRepsData.propertyRep,
            customerRepData: selectedRep.customerRep
              ? newState.customerReps.find(r => r.id === selectedRep.customerRep)
              : propertiesAndRepsData.customerRepData
          };
        } else if (isPropertyChange) {
          newState = {
            ...newState,
            propertyRep: newState.reps[0]?.id || '',
            customerRepData: newState.customerReps.find(r => r.id === customerRepId)
          };
        } else {
          newState = {
            ...newState,
            customerRepData: newState.customerReps.find(r => r.id === customerRepId)
          };
        }
        await setPropertiesAndRepsData(newState);
        return newState;
      }
    } catch (error) {
      logErrorWithCallback(
        error,
        snackbarOn,
        'Unable to retrieve customer property details, please try again later'
      );
    }
  };

  const fetchTasksOnCustomerProperty = async () => {
    if (!user.tenantId) {
      return { items: [], nextToken: '0' };
    }
    let data;
    try {
      const customerPropertyService = new CustomerPropertyService();
      data = await customerPropertyService.getTasksByCustomerPropertyById(property?.id);
      setTasksOnCustomerProperty(
        data.items.filter(
          task => task.status === TaskConstants.OPEN || task.status === TaskConstants.PENDING
        )
      );
      QuotesUtils.refreshAttachmentsService({
        ACTION,
        dispatch,
        getAttachmentsForQuote,
        primaryQuoteId: primaryQuoteId || quoteInfo?.id,
        queryAndSetQuoteLineTasks,
        quoteId,
        snackbar,
        user
      });
    } catch (error) {
      snackbarOn('error', 'Unable to fetch tasks, please try again later');
    }
  };

  async function fetchPropertyData(propertyId, isPropertyChange) {
    setIsLoading(true);
    try {
      const customerPropertyService = new CustomerPropertyService();
      const customerService = new CustomerService();
      const { data } = await customerPropertyService.getCustomerPropertyInfoById(propertyId);
      if (data?.getCustomerPropertyById) {
        const propertyData = data.getCustomerPropertyById;
        const updatedPropertiesAndRepsData = await performQuery(
          null,
          propertyData,
          isPropertyChange,
          customerService
        );
        const composedPropertyDetails = QuotesUtils.composePropertyDetails(propertyData);
        setPropertyDetails(composedPropertyDetails);
        await QuotesUtils.composeAccountMgrOptions({
          customerPropertyService,
          customerService,
          propertyDetails: composedPropertyDetails,
          setAccountManagerOptions,
          snackbar
        });
        const newState = await getCustomerRepDetails({
          customerPropertyService,
          propertyData,
          quoteInfo,
          setPropertiesAndRepsData,
          snackbarOn,
          updatedPropertiesAndRepsData,
          user
        });
        fetchTasksOnCustomerProperty();
        return newState;
      }
    } catch (err) {
      logErrorWithCallback(err, snackbarOn, 'Unable to get property information');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const propertyId = isEmpty(property) ? history.location.state?.propertyId : property.id;
    if (!propertyId) return;
    fetchPropertyData(propertyId);
  }, [property.id, quoteInfo.versionNumber]);

  const addRep = async (rep, name, currentlyEditedNote) => {
    try {
      const customerService = new CustomerService();
      if (!rep?.id && rep?.firstName) {
        if (rep.note && rep.note.length > 0) {
          rep.repNotes = [{ subject: '', note: rep.note }];
        }
        const { data } = await customerService.addCustomerRepToCustomer({
          ...rep,
          parent: {
            id: propertyDetails?.property?.parentId || propertyDetails?.property?.parentEntity?.id,
            tenantId: propertyDetails?.property.tenantId,
            customerPropertyId: name === 'propertyRep' ? propertyDetails?.property?.id : null
          }
        });
        const { addCustomerRepToCustomer } = data;
        if (quoteInfo?.id) {
          const keyToUpdate = name === 'propertyRep' ? 'propertyRepId' : 'orderedById';
          await updateQuoteRep({
            ...serviceProps,
            dataToUpdate: { [keyToUpdate]: addCustomerRepToCustomer[0].id }
          });
        }

        if (recordSortKey) {
          await performQuery(
            {
              [name]: addCustomerRepToCustomer[0].id || ''
            },
            propertyDetails?.property,
            false,
            customerService
          );
        }

        snackbarOn('success', `${addCustomerRepToCustomer[0].name} rep created successfully`);
      } else if (rep?.id && rep?.firstName) {
        currentlyEditedNote.note = rep.note;
        if (currentlyEditedNote.id) {
          rep.repNotes = [
            {
              id: currentlyEditedNote.id,
              note: currentlyEditedNote.note,
              subject: currentlyEditedNote.subject
            }
          ];
        } else if (currentlyEditedNote.note?.length > 0) {
          rep.repNotes = [{ subject: '', note: currentlyEditedNote.note }];
        }

        if (rep.version) {
          rep.version = parseInt(rep.version);
        }

        const { data } = await customerService.updateCustomerRep({
          ...rep,
          parent: {
            id: (propertyDetails?.property?.parentEntity || {}).id,
            tenantId: propertyDetails?.property?.tenantId,
            customerPropertyId: propertyDetails?.property?.id
          }
        });
        if (data) {
          const { updateCustomerRepAndRealated } = data;
          if (recordSortKey) {
            fetchQuoteInfo(quoteId);
            await performQuery(
              {
                [name]: updateCustomerRepAndRealated?.id || ''
              },
              propertyDetails?.property,
              false,
              customerService
            );
          }
          snackbarOn('success', `${updateCustomerRepAndRealated?.name} rep updated successfully`);
        }
      }
    } catch (err) {
      logErrorWithCallback(err, snackbarOn, 'Unable to save the representative');
    }
  };

  useEffect(() => {
    if (
      !isEmpty(quoteInfo) &&
      !labourRateGroupsLoading &&
      !labourTypesLoading &&
      !payrollHourTypesLoading &&
      !billingHourTypesLoading
    ) {
      const laborTypesWithPayrollCosts = getLaborTypesWithPayrollCosts({
        labourTypes,
        labourRateGroups,
        payrollHourTypes,
        enterpriseLaborCostingEnabled: flags[FeatureFlags.ENTERPRISE_LABOR_COSTING]
      });
      const laborTypesWithBillingAndLaborRates = getBillingRates({
        laborTypesWithPayrollCosts,
        billingHourTypes,
        quoteState,
        pricebook
      });
      setLaborRates(laborTypesWithBillingAndLaborRates);
    }
  }, [
    quoteInfo,
    quoteState,
    pricebook,
    labourRateGroupsLoading,
    labourTypesLoading,
    payrollHourTypesLoading,
    billingHourTypesLoading,
    billingHourTypes,
    labourTypes,
    labourRateGroups,
    payrollHourTypes,
    flags
  ]);

  const classes = styles();

  const onQuoteInfoSave = async info => {
    const { departments } = companyContext.getCompany;
    const { billTo, propertyAddress } = propertyDetails;
    try {
      setIsButtonDisabled(true);
      const selectedDepartment = departments?.items.find(dept => dept.id === info.departmentId);
      const values = info
        ? {
            billTo,
            name: info.name,
            dueDate: info.dueDate,
            expirationLength: info.expirationLength,
            versionLabel: info.versionLabel,
            ownerId: info.ownerId,
            customerPoNumber: info.customerPoNumber,
            salesById: info.salesById,
            orderedById: propertiesAndRepsData?.customerRep,
            propertyRepId: propertiesAndRepsData?.propertyRep,
            departmentId: info.departmentId,
            departmentValue: selectedDepartment?.tagName,
            // set priceBookId to empty space if no selection is made for conditionals when formatting form data
            priceBookId: info.priceBookId || defaultPriceBookId || ' ',
            serviceAgreementId: info.serviceAgreementId || '',
            accountManagerId: info.accountManagerId,
            accountManagerValue:
              accountManagerOptions.find(option => option.value === info.accountManagerId)?.label ||
              null
          }
        : {};

      if (info?.id) {
        const updatedValues = {
          ...values,
          departmentValue: selectedDepartment?.tagName,
          id: info.id,
          version: quoteInfo?.version,
          ownerValue: getEmployeeName(companyEmployees, values.ownerId),
          salesByValue: getEmployeeName(companyEmployees, values.salesById)
        };
        updateQuoteMutation({
          ...serviceProps,
          updatedValues
        });
      } else if (info) {
        // Creating a new quote
        const createValues = {
          customerAndPropertyAndAddress: '',
          customerName: propertyDetails?.customer?.customerName,
          propertyName: propertyDetails?.property?.id,
          propertyAddress,
          customerId: propertyDetails?.customer?.id,
          customerPropertyId: propertyDetails?.property?.id,
          taxRateId: propertyDetails?.property?.taxRate?.id,
          taxRateValue: propertyDetails?.property?.taxRate?.taxRate,
          versionNumber: 1,
          ...values,
          detailsShownToCustomer: 'LineItemsWithGrandTotalOnly'
        };
        const response = await quoteService.addQuoteToCustomerProperty(
          user.tenantId,
          createValues,
          { employees: companyEmployees }
        );
        if (response?.data) {
          const result = response.data?.addQuotesToCustomerProperty;
          snackbarOn(
            'success',
            `Quote created successfuly ${result[0].customIdentifier || result[0].quoteNumber}`
          );
          sendMixpanelEvent(MIXPANEL_EVENT.CREATED_QUOTE, MIXPANEL_PAGE_NAME.QUOTES);
          if (result && result.length > 0) {
            history.push(`/quote/${result[0].id}`);
          }
        }
      }
    } catch (err) {
      logErrorWithCallback(err, snackbarOn, `Unable to save the quote's info`);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const onQuoteInternalNotesSave = data => {
    try {
      const updatedValues = {
        ...data,
        version: quoteInfo?.version
      };
      updateQuoteMutation({ ...serviceProps, updatedValues });
    } catch (err) {
      logErrorWithCallback(err, snackbarOn, `Unable to save the quote's internal notes`);
    }
  };

  const handleQuoteTemplateSave = ({ newData }) => {
    try {
      const updatedValues = {
        template: newData ?? ckEditorData,
        id: quoteInfo?.id,
        version: quoteInfo?.version
      };
      updateQuoteMutation({ ...serviceProps, updatedValues });
    } catch (err) {
      logErrorWithCallback(err, snackbarOn, `Unable to save the quote's template`);
    }
  };

  const tabOnChange = (_, tabIndex) => {
    if (quoteInfo?.id) {
      selectedTab.current = tabIndex;
    } else if (tabIndex !== 0) {
      // only allow user to select other tabs if the quote has already been created
      snackbar('warning', 'Please save the quote before selecting another tab.');
    }
  };

  useEffect(() => {
    if (quoteInfo?.id && history?.location?.search === SCOPE_AND_PRICING_TAB) {
      initialTab.current = 2;
    }
  }, [quoteInfo]);

  const headerButtons =
    history.location.pathname === '/quotes/new'
      ? [
          <ThemeProvider>
            <Button
              disabled={isLoading || isButtonDisabled}
              readOnly={
                !!(
                  [QuoteStatus.JOB_ADDED, QuoteStatus.CANCELLED, QuoteStatus.APPROVED].includes(
                    quoteInfo.status
                  ) ||
                  (quoteInfo.status === QuoteStatus.DISCARDED &&
                    primaryQuoteInfo.status === QuoteStatus.JOB_ADDED)
                )
              }
              style={{ marginRight: 8, padding: 8 }}
              type="primary"
              onClick={() => formService.submit()}
            >
              SAVE{' '}
            </Button>
          </ThemeProvider>
        ]
      : [
          <ThemeProvider>
            <Button
              disabled={isLoading || isButtonDisabled}
              type="primary"
              onClick={() => setOpenModal(true)}
            >
              GENERATE QUOTE
            </Button>
          </ThemeProvider>
        ];

  // 1) Converts parsed html string back into an html string with smartfields
  // 2) Reparses the newly converted string so it contains updated smart field values
  // 3) Saves reparsed string to quote
  // 4) Triggers CkEditor refresh so UI updated with current smart field values
  const handleRefreshSmartfieldsClick = async () => {
    const htmlWithSmartfields = reinsertSmartfields(ckEditorData, companyQuoteTemplate);
    const parsedHTMLWithUpdatedSmartfieldValues = await parseHTMLForSmartFields({
      hasMultipleVersions,
      htmlStr: htmlWithSmartfields,
      smartFieldInfo: quoteInfo,
      settingsJSON: currentSettings,
      propertyDetails
    });
    await handleQuoteTemplateSave({ newData: parsedHTMLWithUpdatedSmartfieldValues });
    setRefreshCKEditor({ isTrue: true, data: parsedHTMLWithUpdatedSmartfieldValues });
  };

  const createNewVersion = async () => {
    if (!isEmpty(quoteVersionsOptionData)) {
      const primaryQuoteIdMatch = quoteVersionsOptionData.find(
        version => version.isPrimary === true
      ).id;
      const quoteResponse = await createQuoteVersion({
        ...serviceProps,
        primaryQuoteId: primaryQuoteIdMatch,
        quoteState
      });
      if (quoteResponse) {
        const versionOptions = await formatVersionOptionData(
          quoteResponse,
          setQuoteVersionsOptionData,
          snackbarOn,
          setHasMultipleVersions,
          setQuoteTags
        );

        // get the newest version id which is at the end of the sorted version options array
        const newVersionId = versionOptions[versionOptions?.length - 1].id;
        changeVersionsView(newVersionId, versionOptions, fetchQuoteInfo, fetchVersionedQuoteInfo);
      }
    }
  };

  const handleActionIfDeactivated = useMemo(
    () => (service, actionName, type) => {
      if (propertyDetails?.property?.status === AppConstants.INACTIVE) {
        snackbar('error', `Please activate this quote's property before ${actionName}.`);
      } else {
        service(type);
      }
    },
    [propertyDetails, snackbar]
  );

  const emailTemplateOptions = useMemo(
    () =>
      quoteInfo?.departmentId
        ? (companyExternalMessages || []).filter(
            template =>
              template?.type === EntityType.QUOTE &&
              (template?.externalMessageDepartments?.items || []).some(
                d => d?.department?.id === quoteInfo.departmentId
              )
          )
        : [],
    [quoteInfo.departmentId]
  );

  useEffect(() => {
    const name = getQuoteName(quoteInfo, hasMultipleVersions);
    setQuoteName(`Quote ${name}`);
  }, [
    quoteInfo.customIdentifier,
    quoteInfo.quoteNumber,
    hasMultipleVersions,
    quoteInfo.versionNumber,
    quoteInfo.name,
    quoteInfo.versionLabel
  ]);

  const onSettingsChange = (change, { values }) => {
    // Prevent update when user initally tries to enable overrides and show warning instead
    if (change?.[SettingConstants.QUOTE_MODE]?.[SettingConstants.ENABLE_OVERRIDES]) {
      return;
    }
    setCurrentSettings(values);
  };

  const handlePropertyChange = async customerProperty => {
    const propertyId = customerProperty.parentId;
    const { data } = await new CustomerPropertyService().getCustomerPropertyInfoById(propertyId);
    const billingAddress = data.getCustomerPropertyById?.billingCustomer?.companyAddresses?.items.find(
      address => address.addressType === 'billingAddress'
    );
    const customerBillingAddress = getCombinedAddress(billingAddress);
    const isPropertyChange = true;
    const updatedPropertiesAndRepsData = await fetchPropertyData(propertyId, isPropertyChange);
    const updatedPriceBookId =
      data.getCustomerPropertyById.parentEntity.priceBook?.id || companyPriceBookId;
    await updateQuoteMutation({
      ...serviceProps,
      propertyId,
      updatedValues: {
        id: quoteInfo.id,
        billTo: customerBillingAddress || null,
        orderedById: updatedPropertiesAndRepsData?.customerRep || null,
        propertyRepId: updatedPropertiesAndRepsData?.propertyRep || null,
        taxRateId: data?.getCustomerPropertyById?.taxRate?.id || null,
        taxRateValue: data?.getCustomerPropertyById?.taxRate?.taxRate ?? null,
        version: quoteInfo.version,
        priceBookId: updatedPriceBookId
      }
    });
    setOpenPropertyModal(false);
  };

  const SendEmailComponent = useMemo(() => {
    return (
      !isEmpty(quoteInfo) &&
      sendEmailModalOpen && (
        <SendEmailPopUp
          data={{
            attachments: quoteAttachments,
            ckEditorData,
            customer: propertyDetails.customer,
            customerProperty: propertyDetails.property,
            customerRep: { id: quoteInfo?.orderedById, email: quoteInfo?.orderedBy?.email },
            daysUntilExpiration: quoteInfo?.expirationLength,
            propertyRepId: quoteInfo?.propertyRepId,
            emailSubject: `${companyContext?.getCompany?.companyName} - Quote ${quoteInfo?.name ||
              ''}`,
            billingCustomer: propertyDetails?.property?.billingCustomer,
            pdfFileName: `${quoteName.replace(/\s/g, '_')}.pdf`,
            allowEditEmailSubject: true,
            shouldGeneratePDF: true,
            logoUrl: companyContext?.getCompany?.logoUrl,
            sendEmailFn: payload =>
              shareQuoteMutation({
                ...serviceProps,
                email: payload.email,
                expirationDate: payload.expirationDate,
                setQuoteVersionsOptionData,
                quoteVersionsOptionData
              }),
            uploadPDF: () =>
              generatePDF({
                data: ckEditorData,
                fileName: `${quoteName.replace(/\s/g, '_')}.pdf`,
                user,
                status: quoteInfo?.status,
                snackbar,
                generatedPDFSizeRef,
                updateQuoteMutation: pdfUrl =>
                  updateQuoteMutation({
                    ...serviceProps,
                    updatedValues: {
                      id: quoteInfo.id,
                      version: quoteInfo.version,
                      pdfUrl,
                      template: ckEditorData
                    },
                    successMessage: 'Successfully saved and updated quote'
                  })
              })
          }}
          dataType="Share Quote"
          generatedPDFSizeRef={generatedPDFSizeRef}
          maxAttachmentSize={10000000}
          maxAttachmentSizeWarningLabel="Attachments over 10MB cannot be sent with the quote."
          open={sendEmailModalOpen}
          quoteInfo={quoteInfo}
          snackbar={snackbar}
          templates={emailTemplateOptions}
          user={user}
          onModalClose={() => setSendEmailModalOpen(false)}
        />
      )
    );
  }, [quoteInfo, sendEmailModalOpen]);

  useEffect(() => {
    const currentRecommendedTasks = tasksOnCustomerProperty.filter(t => t.isRecommended);
    setRecommendedTasks(currentRecommendedTasks);
  }, [tasksOnCustomerProperty]);

  return (
    <>
      <PageHeader
        addExistingJobToQuote={async (jobId, totalBudgetedHours) => {
          setIsLoading(true);
          const response = await existingJobMutation({
            ...serviceProps,
            jobId,
            primaryQuoteId,
            totalBudgetedHours
          });
          setIsLoading(false);
          return response;
        }}
        addToJob={async (jobCustomIdentifier, totalBudgetedHours) => {
          setIsLoading(true);
          const response = await createJobMutation({
            ...serviceProps,
            jobCustomIdentifier,
            primaryQuoteId,
            totalBudgetedHours
          });
          setIsLoading(false);
          return response;
        }}
        cancelQuoteMutation={async setConfirmData => {
          setIsLoading(true);
          await cancelQuoteMutation({
            ...serviceProps,
            setConfirmData,
            primaryQuoteId
          });
          setIsLoading(false);
        }}
        cloneOrReopenQuoteMutation={type => {
          cloneOrReopenQuoteMutation({
            ...serviceProps,
            history,
            type
          });
        }}
        createNewVersion={() => createNewVersion()}
        customerPropertyId={property.id}
        fetchQuoteInfo={fetchQuoteInfo}
        fetchVersionedQuoteInfo={fetchVersionedQuoteInfo}
        handleActionIfDeactivated={handleActionIfDeactivated}
        hasPermissionToEditQuote={hasPermissionToEditQuote}
        headerButtons={headerButtons}
        isLoading={isLoading}
        poNumber={poNumber}
        primaryQuoteId={primaryQuoteId}
        propertyDetails={propertyDetails}
        quoteAttachments={quoteAttachments}
        quoteInfo={QuotesUtils.composePageHeaderQuoteInfo(quoteInfo, companyEmployees)}
        quotePurchaseOrders={quotePurchaseOrders}
        quoteTags={quoteTags}
        quoteVersions={quoteVersionsOptionData}
        serviceProps={serviceProps}
        setPoNumber={setPoNumber}
        setPrimaryVersion={selectedValue =>
          setPrimaryVersionMutation({ ...serviceProps, selectedValue })
        }
        user={user}
      />
      {quoteState.loading && (
        <QuoteSettings
          currentSettings={currentSettings}
          isAssetEnabled={isAssetEnabled}
          isOverridesModalOpen={isOverridesModalOpen}
          isReadOnly={isReadOnly}
          serviceProps={serviceProps}
          setCurrentSettings={setCurrentSettings}
          setIsOverridesModalOpen={setIsOverridesModalOpen}
          user={user}
          onSettingsChange={onSettingsChange}
        />
      )}
      <StatusAlert
        customerSignatures={quoteInfo?.customerSignatures?.items}
        rejectedReason={quoteInfo?.rejectedReason}
        status={quoteInfo?.status}
      />
      {recommendedTasksEnabled && (
        <RecommendedTaskAlert
          addQuoteLoading={addQuoteLoading}
          addTaskService={newTask =>
            addTaskService({
              ...serviceProps,
              newTask
            })
          }
          fetchTasksOnCustomerProperty={fetchTasksOnCustomerProperty}
          propertyId={propertyDetails?.property?.id}
          propertyName={propertyDetails?.property?.companyName}
          quoteInfo={quoteInfo}
          recommendedTasks={recommendedTasks}
          setAddQuoteLoading={setAddQuoteLoading}
          snackbarOn={snackbarOn}
          status={quoteInfo?.status}
        />
      )}
      <Tabs
        disableBottomPadding
        initialTab={initialTab}
        selectedTab={selectedTab}
        onChange={tabOnChange}
      >
        <Tab caslKey={PermissionConstants.OBJECT_QUOTES} label="Info">
          <Box className={`${classes.greyBorderTop} ${classes.container}`}>
            <ReadonlyWrapper readOnly={isReadOnly}>
              <Info
                accountManagerOptions={accountManagerOptions}
                addRep={addRep}
                companyEmployees={companyEmployees}
                companyServiceAgreements={companyServiceAgreements}
                defaultPriceBookId={defaultPriceBookId}
                defaultPriceBookMsg={defaultPriceBookMsg}
                infoSave={onQuoteInfoSave}
                isLoading={isLoading}
                isReadOnly={isReadOnly}
                openPropertyModal={openPropertyModal}
                primaryQuoteId={primaryQuoteId}
                propertiesAndRepsData={propertiesAndRepsData}
                propertyDetails={propertyDetails}
                quoteInfo={quoteInfo}
                quotePurchaseOrders={quotePurchaseOrders}
                setDefaultPriceBookMsg={setDefaultPriceBookMsg}
                setFormService={setFormService}
                setOpenPropertyModal={setOpenPropertyModal}
                setPropertiesAndRepsData={setPropertiesAndRepsData}
                setUpdatedQuoteInfo={setUpdatedQuoteInfo}
                updatedQuoteInfo={updatedQuoteInfo}
                updateQuoteRep={dataToUpdate =>
                  updateQuoteRep({
                    ...serviceProps,
                    dataToUpdate
                  })
                }
                user={user}
              />
            </ReadonlyWrapper>
          </Box>
        </Tab>
        <Tab caslKey={PermissionConstants.OBJECT_QUOTES} label="Internal Notes">
          <Box className={classes.greyBorderTop}>
            <ReadonlyWrapper readOnly={isReadOnly}>
              <InternalNotes
                handleCreateService={() => getHandleCreateService('notes')}
                handleOnComplete={() => getHandleComplete('notes')}
                hasLoaded={!quoteState?.loading}
                quoteInfo={quoteInfo}
                setUpdatedQuoteNotes={setUpdatedQuoteNotes}
                updatedQuoteNotes={updatedQuoteNotes}
                onQuoteInternalNotesSave={onQuoteInternalNotesSave}
              />
            </ReadonlyWrapper>
          </Box>
        </Tab>
        <Tab caslKey={PermissionConstants.OBJECT_QUOTES} label="Scope & Pricing">
          <Box className={`${classes.greyBorderTop} ${classes.pricingContainer}`}>
            <Grid container>
              <Grid className={classes.taskContainer} item md={10}>
                <Tasks
                  addQuoteLineProducts={product =>
                    addQuoteLineProducts({
                      ...serviceProps,
                      product
                    })
                  }
                  addTaskEntriesToTaskService={(products, task) =>
                    addQuoteLineProductsOnTask({
                      ...serviceProps,
                      products,
                      task
                    })
                  }
                  addTaskGroup={(groupData, taskIds) =>
                    addTaskGroup({
                      ...serviceProps,
                      groupData,
                      taskIds
                    })
                  }
                  addTaskService={newTask =>
                    addTaskService({
                      ...serviceProps,
                      newTask
                    })
                  }
                  config={{
                    showTaskList: true,
                    addToBtnLabel: 'To Quote',
                    addToLabel: 'Quote',
                    showPricing: true,
                    showMarkAsComplete: false,
                    showAudit: false,
                    showActivity: false,
                    ...currentSettings
                  }}
                  customerPropertyId={property.id}
                  deleteLaborLineItem={taskId =>
                    deleteLaborLineItem({
                      ...serviceProps,
                      itemId: taskId
                    })
                  }
                  deleteLineItem={taskId =>
                    deleteLineItem({
                      ...serviceProps,
                      itemId: taskId
                    })
                  }
                  deleteTaskEntryFromTaskService={taskId =>
                    deleteProductEntry({
                      ...serviceProps,
                      itemId: taskId
                    })
                  }
                  deleteTaskGroup={taskGroupId =>
                    deleteTaskGroup({
                      ...serviceProps,
                      itemId: taskGroupId
                    })
                  }
                  deleteTaskService={task =>
                    deleteQuoteLineTask({
                      ...serviceProps,
                      itemId: task
                    })
                  }
                  fetchTasksOnCustomerProperty={fetchTasksOnCustomerProperty}
                  isReadOnly={isReadOnly}
                  laborRates={laborRates}
                  payrollHourTypes={payrollHourTypes}
                  quoteInfo={quoteInfo}
                  quoteLineProducts={
                    (quoteLineTasks.items && sortBy(quoteLineProducts.items, 'sortOrder')) || []
                  }
                  recommendedTasksOn={recommendedTasksEnabled}
                  setTasksOnCustomerProperty={setTasksOnCustomerProperty}
                  taskList={quoteLineTasks.items || []}
                  tasksOnCustomerProperty={tasksOnCustomerProperty}
                  updateLaborLineItems={({ laborLineItemArr, taskId, version }) =>
                    updateLaborLineItems({
                      ...serviceProps,
                      laborLineItemArr,
                      taskId,
                      version
                    })
                  }
                  updateOverrideAmountOnQuote={overrideData => {
                    updateOverrideAmountOnQuote({
                      ...serviceProps,
                      overrideData
                    });
                  }}
                  updateTaskEntriesForTaskService={(products, task) =>
                    updateQuoteLineProductsOnTask({
                      ...serviceProps,
                      task,
                      products
                    })
                  }
                  updateTaskGroup={(groupData, taskIds) =>
                    updateTaskGroup({
                      ...serviceProps,
                      groupData,
                      taskIds
                    })
                  }
                  updateTaskService={taskToUpdate =>
                    updateTaskService({
                      ...serviceProps,
                      taskToUpdate
                    })
                  }
                  updateTaxRateOnQuote={taxRate =>
                    updateTaxRateOnQuote({
                      ...serviceProps,
                      taxRate
                    })
                  }
                />
              </Grid>
              {!currentSettings?.[SettingConstants.QUOTE_MODE][
                SettingConstants.ENABLE_OVERRIDES
              ] && <Profitability quoteInfo={quoteInfo} />}
            </Grid>
          </Box>
        </Tab>
        <Tab
          caslKey={PermissionConstants.OBJECT_QUOTES}
          label={recommendedTasksEnabled ? 'Forms & Attachments' : 'Attachments'}
        >
          <Box className={`${classes.greyBorderTop} ${classes.container}`}>
            <Attachment
              customDeleteMutationService={(partitionKey, sortKey, record) =>
                QuotesUtils.deleteAttachmentService({
                  partitionKey,
                  sortKey,
                  record,
                  quoteService,
                  deleteAttachmentById
                })
              }
              data={quoteAttachments || { items: [] }}
              enableThumbnails
              hasInternalFileOption={flags[FeatureFlags.QUOTE_ATTACHMENTS_ENHANCEMENTS]}
              hasPermissionToEdit={hasPermissionToEditQuote}
              hasShareWithTechsOption={flags[FeatureFlags.QUOTE_ATTACHMENTS_ENHANCEMENTS]}
              maxFileSize={10000000}
              maxFileSizeWarningLabel="Attachments over 10MB cannot be sent with the quote."
              mutationService={values => handleQuoteAttachment(values, primaryQuoteId, user)}
              parent={{}}
              readOnly={false}
              refresh={() =>
                QuotesUtils.refreshAttachmentsService({
                  ACTION,
                  dispatch,
                  getAttachmentsForQuote,
                  primaryQuoteId,
                  queryAndSetQuoteLineTasks,
                  quoteId,
                  snackbar,
                  user
                })
              }
            />
            <Box style={{ padding: '16px 0' }} />
            {recommendedTasksEnabled && (
              <FormSection
                caslKey={[PermissionConstants.OBJECT_JOB]}
                hideAddBtn
                parent={{
                  entityType: 'QuoteTasks',
                  partitionKey: user.tenantId,
                  quoteId: quoteInfo.id
                }}
                triggerRefresh={addQuoteLoading}
              />
            )}
          </Box>
        </Tab>
        <Tab caslKey={PermissionConstants.OBJECT_QUOTES} label="Document History">
          <Box className={`${classes.greyBorderTop} ${classes.container}`}>
            <DocHistory quoteVersionsOptionData={quoteVersionsOptionData} />
          </Box>
        </Tab>
        <Tab caslKey={PermissionConstants.OBJECT_QUOTES} label="Activity">
          <Box className={`${classes.greyBorderTop} ${classes.container}`}>
            <AuditLogs
              customFilterLogFunc={filterQuoteLogs}
              dataService={() => getQuoteActivityLogs(quoteInfo.id, snackbar, primaryQuoteId)}
              key={`${quoteInfo.id}-activity`}
              loadingParams={{
                variant: 'table',
                repeatCount: 8,
                paddingRight: '70%'
              }}
              showCount={30}
              variant="singleEntity"
            />
          </Box>
        </Tab>
      </Tabs>
      <ThemeProvider>
        <CKEditorModal
          ckEditorData={ckEditorData}
          companyQuoteSettings={companyQuoteSettings}
          companyQuoteTemplate={companyQuoteTemplate}
          currentSettings={currentSettings}
          handleQuoteTemplateSave={handleQuoteTemplateSave}
          handleRefreshSmartfieldsClick={handleRefreshSmartfieldsClick}
          hasMultipleVersions={hasMultipleVersions}
          hasPermissionToEditQuote={hasPermissionToEditQuote}
          isLoading={isLoading}
          isReadOnly={isReadOnly}
          openModal={openModal}
          propertyDetails={propertyDetails}
          quoteInfo={quoteInfo}
          refreshCKEditor={refreshCKEditor}
          setCKEditorData={setCKEditorData}
          setOpenModal={setOpenModal}
          setRefreshCKEditor={setRefreshCKEditor}
          setSendEmailModalOpen={setSendEmailModalOpen}
        />
        <CustomerPropertyModal
          defaultSearchIndex={defaultSearchIndex}
          handlePropertyChange={handlePropertyChange}
          openPropertyModal={openPropertyModal}
          setOpenPropertyModal={setOpenPropertyModal}
        />
      </ThemeProvider>
      {SendEmailComponent}
    </>
  );
};

const MultipleForms = withMultipleForms(QuoteDetail, ['info', 'notes', 'scope', 'quoteDefaults']);
export default connect(state => ({ user: state.user }), { snackbar: snackbarOn })(MultipleForms);
