import React, { useMemo } from 'react';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import gql from 'graphql-tag';

import { useSelector } from 'react-redux';

import { ActionsMenu, UserPermission, XGrid } from 'components';
import { column, ColumnType } from 'components/XGrid/columnTypes';
import useJobCostingOptions from 'customHooks/useJobCostingOptions';
import { useTrigger } from 'customHooks/useTrigger';
import ErrorBoundaries from 'scenes/Error';
import { MultiSelectTypes, PermissionConstants } from 'utils/AppConstants';
import { constructSelectOptions } from 'utils/constructSelectOptions';

const GET_PRODUCTS = gql`
  query getProductsList(
    $tenantId: String
    $filter: TableFilterInput
    $pagination: PaginationInput
    $sorting: [TableSortingInput]
  ) {
    data: getProductsList(
      tenantId: $tenantId
      filter: $filter
      pagination: $pagination
      sorting: $sorting
    ) {
      rowCount
      items
    }
  }
`;

const RowActions = ({ productRowButtons, handleActions, record }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  return (
    <>
      <MoreVertIcon
        fontSize="small"
        onClick={event => setAnchorEl({ ref: event.currentTarget, record })}
      />
      <ActionsMenu
        anchorEl={anchorEl}
        handleMenuClose={() => setAnchorEl(null)}
        rowActionButtons={productRowButtons}
        rowActions={handleActions}
      />
    </>
  );
};

const getCostingColumnDef = selectOptions => {
  const def = { ...column[ColumnType.TAG] };
  const filterOperators = def.filterOperators.map(operator => {
    const localOperator = { ...operator };
    localOperator.InputComponentProps = { selectOptions };
    return localOperator;
  });
  def.filterOperators = filterOperators;
  return def;
};

/**
 * We can either massage the data here through valueGetter
 * or we can format the data before it goes into the table.
 */
const getProductColumns = ({
  productRowButtons,
  handleActions,
  costCodeOptions,
  costTypeOptions,
  revenueTypeOptions,
  uomOptions,
  productCategoryOptions,
  vendors,
  isProductCategoriesEnabled
}) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    ...column[ColumnType.TEXT]
  },
  ...(isProductCategoriesEnabled
    ? [
        {
          field: 'productCategoryName',
          headerName: 'Category',
          width: 120,
          ...getCostingColumnDef(productCategoryOptions)
        }
      ]
    : []),
  ...(isProductCategoriesEnabled
    ? [
        {
          field: 'departments',
          headerName: 'Departments',
          width: 250,
          enumType: MultiSelectTypes.DEPARTMENTS,
          ...column[ColumnType.TAGS]
        }
      ]
    : []),
  {
    field: 'vendorSortKey',
    headerName: 'Vendor',
    width: 180,
    ...column[ColumnType.TEXT],
    valueFormatter: value => vendors.find(v => v.sortKey === value.row.vendorSortKey)?.name
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 454,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'code',
    headerName: 'Product Code',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'unitOfMeasureValue',
    headerName: 'UOM',
    width: 120,
    ...getCostingColumnDef(uomOptions)
  },
  {
    field: 'isActive',
    headerName: 'Active',
    width: 120,
    ...column[ColumnType.BOOL]
  },
  {
    field: 'unitCost',
    headerName: 'Unit Cost',
    width: 150,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'costCodeName',
    headerName: 'Cost Code',
    width: 180,
    ...getCostingColumnDef(costCodeOptions)
  },
  {
    field: 'jobTypeName',
    headerName: 'Cost Type',
    width: 180,
    ...getCostingColumnDef(costTypeOptions)
  },
  {
    field: 'revenueTypeName',
    headerName: 'Revenue Type',
    width: 180,
    ...getCostingColumnDef(revenueTypeOptions)
  },
  {
    field: 'taxable',
    headerName: 'Taxable',
    width: 120,
    ...column[ColumnType.BOOL]
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 86,
    ...column[ColumnType.BOOL],
    sortable: false,
    filterable: false,
    align: 'center',
    renderCell: ({ row }) => (
      <RowActions
        handleActions={handleActions}
        productRowButtons={productRowButtons}
        record={row}
      />
    )
  }
];

const ProductList = ({
  productRowButtons,
  handleActions,
  refreshCount,
  vendors = [],
  isProductCategoriesEnabled
}) => {
  const uomList = useSelector(state => state?.company?.unitOfMeasures?.items);
  const [initialCount, setInitialCount] = React.useState(refreshCount);
  const productCategoryList = useSelector(state => state?.company?.productCategories?.items);
  const {
    costCodeOptions,
    jobCostTypeOptions: costTypeOptions,
    revenueTypeOptions
  } = useJobCostingOptions();
  const uomOptions = useMemo(() => constructSelectOptions(uomList, 'name'), [uomList]);
  const productCategoryOptions = useMemo(
    () => constructSelectOptions(productCategoryList, 'name'),
    [productCategoryList]
  );
  const [subscribe, trigger] = useTrigger();

  React.useEffect(() => {
    if (initialCount !== refreshCount) {
      trigger();
      setInitialCount(refreshCount);
    }
  }, [initialCount, refreshCount, trigger]);

  const productRowButtonsLength = Object.keys(productRowButtons).length; // Should be memoized but parent is still class-based
  const columnMeta = useMemo(
    () =>
      getProductColumns({
        productRowButtons,
        handleActions,
        costCodeOptions,
        costTypeOptions,
        revenueTypeOptions,
        uomOptions,
        productCategoryOptions,
        vendors,
        isProductCategoriesEnabled
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      costCodeOptions,
      costTypeOptions,
      handleActions,
      productRowButtonsLength,
      revenueTypeOptions,
      uomOptions,
      vendors
    ]
  );

  return (
    <ErrorBoundaries>
      <UserPermission action={PermissionConstants.OBJECT_INVENTORTY} I="read">
        <XGrid
          columns={columnMeta}
          defaultSort={[{ field: 'name', sort: 'asc' }]}
          key={!!costCodeOptions}
          query={GET_PRODUCTS}
          refetchTrigger={subscribe}
          tableName="Items" // if not memoized, job costing options remain undefined
        />
      </UserPermission>
    </ErrorBoundaries>
  );
};

export default ProductList;
