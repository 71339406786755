import { bundleIndex } from 'constants/algoliaIndex';

const rowStyle = {
  flexDirection: 'row',
  marginBottom: 40
};

const createModalFields = {
  property: { required: true },
  customer: { required: true },
  billingCustomer: { required: true },
  recommendation: { required: true }
};

const configuration = ({
  assetOptions,
  mode,
  scopedPricebook,
  propertyErrorMsg,
  setAssetOptions,
  setPropertyErrorMsg,
  setScopedPricebook
}) => ({
  fields: createModalFields,
  layouts: {
    default: {
      props: {
        default: { margin: '24px 141px 0 141px' }
      },
      contents: [
        // Row 1
        {
          props: {
            style: { ...rowStyle, alignItems: 'baseline' }
          },
          contents: [
            {
              component: 'CustomPropertySearch',
              source: 'property',
              props: {
                propertyErrorMsg,
                setAssetOptions,
                setPropertyErrorMsg,
                setScopedPricebook,
                testingId: 'property'
              }
            },
            {
              component: 'Input',
              source: 'customer',
              props: {
                testingId: 'customer',
                label: 'Customer',
                required: true,
                readOnly: true,
                style: {
                  margin: '0 8px',
                  width: '33%'
                }
              }
            },
            {
              component: 'Input',
              source: 'billingCustomer',
              props: {
                testingId: 'billingCustomer',
                label: 'Billing Customer',
                required: true,
                readOnly: true,
                style: {
                  marginLeft: 8,
                  width: '33%'
                }
              }
            }
          ]
        },
        // Row 2
        {
          props: { style: rowStyle },
          contents: [
            {
              component: 'CustomAssetSelect',
              source: 'assetId',
              props: {
                options: assetOptions
              }
            }
          ]
        },
        // Row 3
        {
          props: { style: rowStyle },
          contents: [
            {
              component: 'Input',
              source: 'recommendation',
              props: {
                label: 'Recommendation',
                placeholder: 'Enter Task Name',
                required: true,
                testingId: 'recommendation'
              }
            }
          ]
        },
        // Row 4
        {
          props: { style: rowStyle },
          contents: [
            {
              component: 'Input',
              source: 'description',
              props: {
                testingId: 'description',
                label: 'Description',
                placeholder: 'Enter Task Name',
                multiline: true,
                rows: 6
              }
            }
          ]
        },
        // Forms
        {
          contents: [{ component: 'CustomFormComponent' }]
        },
        {
          component: 'Divider'
        },
        // Attachments
        {
          contents: [{ component: 'CustomAttachmentComponent' }]
        },
        // Parts and Materials
        {
          contents: [
            {
              component: 'PartsSelect',
              props: {
                testingId: 'partsSearch',
                mode,
                scopedPricebook,
                searchIndexName: 'bh-searchIndex',
                searchParams: [
                  {
                    searchIndex: bundleIndex
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  }
});

export default configuration;
