import React from 'react';

import { Field, FieldType } from '@BuildHero/sergeant';

import {
  BoldCell,
  sum,
  tableCurrencyFormatter,
  tableEmptyValueFormatter
} from 'components/WrapTable';

export const DiscountsColumns = [
  {
    headerName: 'Source',
    field: 'source',
    maxWidth: 150,
    flex: 1,
    renderCell: ({ row }) => (
      <Field type={FieldType.LINK} value={{ label: row.source, to: row.sourceLink }} />
    )
  },
  {
    headerName: 'Item Name',
    field: 'name',
    valueFormatter: tableEmptyValueFormatter,
    flex: 1
  },
  {
    headerName: 'Description',
    field: 'description',
    valueFormatter: tableEmptyValueFormatter,
    flex: 2
  },
  {
    field: 'subtotal',
    flex: 1,
    maxWidth: 100,
    headerName: 'Subtotal',
    renderCell: BoldCell,
    valueFormatter: tableCurrencyFormatter,
    totalGetter: sum,
    totalFormatter: tableCurrencyFormatter,
    renderTotal: BoldCell
  }
];
