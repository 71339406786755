import { MUIFormComponentNames } from '@BuildHero/sergeant';

import { col, row } from 'utils/layout';

import { directoryPermissions } from '../../ProjectFileStorageSettings.constants';

export const addSettingLayout = {
  fields: {
    directoryName: { default: 'directoryName' },
    permissions: { default: 'permissions' }
  },

  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        row([
          col(
            [
              {
                component: MUIFormComponentNames.TextInput,
                source: 'directoryName',
                options: {
                  label: 'File Folder Title',
                  placeholder: 'Name',
                  default_value: '',
                  smartField: '',
                  isRequired: true,
                  fullwidth: true,
                  alignment: 'left'
                }
              }
            ],
            {
              padding: 12
            }
          )
        ]),
        row([
          col(
            [
              {
                component: MUIFormComponentNames.SelectInput,
                source: 'permissions',
                options: {
                  label: 'Access',
                  valueSet: directoryPermissions,
                  isMultipleSelection: true
                }
              }
            ],
            {
              padding: 12
            }
          )
        ])
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      directoryName: {
        type: 'string'
      }
    },
    required: ['directoryName']
  },
  validationerrors: {
    directoryName: {
      required: 'field is required.'
    }
  }
};
