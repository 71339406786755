import gql from 'graphql-tag';

const addCustomerSignatureSettingsToCompany = gql`
  mutation addCustomerSignatureSettingsToCompany($data: MobileSettingsToCompanyInput!) {
    upsertCustomerSignatureSettingsToCompany(data: $data) {
      id
    }
  }
`;

export default addCustomerSignatureSettingsToCompany;
