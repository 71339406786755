import React, { Component } from 'react';

import {
  Button,
  Field,
  FieldType,
  Input,
  ThemeProvider,
  TV,
  Typography
} from '@BuildHero/sergeant';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import Context from 'components/Context';
import { snackbarOff, snackbarOn } from 'redux/actions/globalActions';
import AuthService from 'services/AuthServices';
import { makeMountAware } from 'utils/makeMountAware';

import PasswordInput from '../PasswordInput';
import styles from '../styles';

export class SignIn extends Component {
  constructor(props) {
    super(props);
    this.signIn = this.signIn.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    document.title = 'BuildOps - SignIn';
    Context.resetCompanyContext();
    this.state = {
      username: '',
      password: '',
      passwordResetIsRequired: false,
      passwordResetException: false,
      spinner: false
    };
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.setState({ spinner: true });
      this.signIn();
    }
  };

  async signIn() {
    const { username, password } = this.state;
    if (!username || !password) {
      this.setState({ spinner: false });
      this.props.snackbarOn('error', 'Incorrect username or password');
      return;
    }
    let catchCallback = error => {
      if (error.code === 'NEW_PASSWORD_REQUIRED') {
        this.setState({ passwordResetIsRequired: true, spinner: false });
        return;
      }

      if (error.code === 'PasswordResetRequiredException') {
        this.setState({
          passwordResetIsRequired: true,
          passwordResetException: true,
          spinner: false
        });
        return;
      }
      this.setState({ spinner: false });
      let errorMessage = error.message;
      if (
        error.message &&
        error.message.includes('PreTokenGeneration failed with error Login Failure:')
      ) {
        errorMessage = error.message.replace(
          'PreTokenGeneration failed with error Login Failure:',
          ''
        );
      }
      this.props.snackbarOn('error', errorMessage);
    };
    catchCallback = catchCallback.bind(this);

    await this.props.auth
      .login(username.trim().toLowerCase(), password)
      .then(() => {
        this.props.history.push('/');
      })
      .catch(error => {
        catchCallback(error);
      });
  }

  render() {
    const { passwordResetException, passwordResetIsRequired, username } = this.state;
    const { classes, i18nMessages, user } = this.props;
    const { locale } = user;

    if (passwordResetIsRequired) {
      return (
        <Redirect
          to={{
            pathname: '/forgotpassword',
            state: {
              passwordResetIsRequired,
              username,
              passwordResetException
            }
          }}
        />
      );
    }

    return (
      <ThemeProvider>
        <Grid
          alignContent="center"
          alignItems="center"
          className={classes.banner}
          container
          direction="column"
          justify="center"
          spacing={0}
        >
          <Grid
            alignContent="center"
            alignItems="center"
            container
            direction="column"
            justify="center"
          >
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
              <img
                alt="BuildOps"
                src="/static/images/Logo_Lockup_White.svg"
                style={{ maxWidth: 250 }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid alignItems="center" container direction="column" justify="center">
          <Grid className={classes.authSection} item xs={12}>
            <Typography align="center" variant={TV.XXL}>
              {i18nMessages.signInTitle[locale]}
            </Typography>
          </Grid>
          <Grid className={classes.authSection} item xs={12}>
            <Input
              inputProps={{ testingid: 'usernameInput' }}
              key="username"
              label={i18nMessages.usernameLabel[locale]}
              name="username"
              onChange={event => this.setState({ username: event.target.value })}
              onKeyPress={this.handleKeyPress}
            />
          </Grid>
          <Grid className={classes.authSection} item xs={12}>
            <PasswordInput
              inputProps={{ testingid: 'passwordInput' }}
              key="password"
              label={i18nMessages.passwordLabel[locale]}
              name="password"
              onChange={event => this.setState({ password: event.target.value })}
              onKeyPress={this.handleKeyPress}
            />
          </Grid>
          <Grid className={classes.authSection} item xs={12}>
            <Button
              fullWidth
              loading={this.state.spinner}
              testingid="buttonlogin"
              type="primary"
              onClick={() => {
                this.setState({ spinner: true });
                this.signIn();
              }}
            >
              {i18nMessages.signInButtonText[locale]}
            </Button>
          </Grid>
          <Grid className={classes.authSection} item xs={12}>
            <Button
              disabled={this.state.spinner}
              fullWidth
              type="secondary"
              onClick={() =>
                this.props.history.push('/forgotpassword', {
                  passwordResetIsRequired,
                  username,
                  passwordResetException: true,
                  resetPassword: true
                })
              }
            >
              {i18nMessages.forgotPasswordButtonText[locale]}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://buildops.com/privacy-policy/"
              rel="noreferrer"
              style={{
                color: 'rgb(0, 0, 0)',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '16px',
                letterSpacing: '-0.03em',
                margin: 12
              }}
              target="_blank"
            >
              By signing in, I accept the BuildOps Terms & Privacy Policy
            </a>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}

SignIn.propTypes = {
  // eslint-disable-next-line react/require-default-props
  auth: PropTypes.instanceOf(AuthService)
};

const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});
const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message)),
  snackbarOff: () => dispatch(snackbarOff())
});

const reduxConnectedSignIn = connect(mapStateToProps, mapDispatchToProps)(SignIn);

export const SignInWithStyle = withStyles(styles, { withTheme: true })(SignIn);

export default compose(makeMountAware, withRouter, withStyles(styles))(reduxConnectedSignIn);
