import React, { useMemo } from 'react';

import {
  Button,
  ButtonSize,
  ButtonType,
  Checkbox,
  MoreButton,
  ThemeProvider,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';

import WrapTable from 'components/WrapTable';
import { useConfirmModal } from 'customHooks/ConfirmModalContext';

import { Mode } from 'utils/constants';

import AssetChecklists from './AssetChecklists';
import AssetTypeChecklists from './AssetTypeChecklists';

const changeAssetConfirm = {
  body:
    'By adding or removing an asset, all maintenance information (from step 4) for properties will be erased.',
  title: 'Add or Remove Asset',
  buttonLabel: 'Continue',
  buttonType: ButtonType.ERROR
};

const staticColumns = [
  {
    field: 'assetTypeName',
    headerName: 'Asset Type',
    minWidth: 180,
    renderCell: ({ isGroupRow, formattedValue, row }) => {
      if (isGroupRow) return `${formattedValue} (${row?.__groupData?.length})`;
    }
  },
  {
    field: 'assetName',
    headerName: 'Asset Name',
    minWidth: 120
  },
  {
    field: 'make',
    headerName: 'Make',
    minWidth: 110
  },
  {
    field: 'modelNumber',
    headerName: 'Model',
    minWidth: 110
  },
  {
    field: 'serialNo',
    headerName: 'Serial',
    minWidth: 110
  }
];

const PropertyAssets = ({
  id,
  label,
  rightLabel,
  assets,
  selectedAssetChecklistMap,
  onSelectionModelChange,
  checklists,
  setAddAssetModal,
  setEditAssetModal,
  assetTypeChecklistMap,
  hasMetadata,
  deleteAllTemplateGroupsOnServiceAgreement,
  refreshServiceAgreementPropertyMetadata
}) => {
  const confirmContext = useConfirmModal();

  const assetMap = useMemo(
    () =>
      assets.reduce(
        (acc, a) => ({
          ...acc,
          [a.id]: a
        }),
        {}
      ),
    [assets]
  );

  const checklistMap = useMemo(
    () =>
      checklists.reduce(
        (acc, l) => ({
          ...acc,
          [l.id]: l
        }),
        {}
      ),
    [checklists]
  );

  const headerCheckChange = useMemo(
    () => checked => {
      // select or deselect everything (complete overwrite, no need to know initial state of selectedAssets).
      const newSelectedAssetChecklistMap = checked
        ? assets
            .filter(a => a.assetTypeId && assetTypeChecklistMap[a.assetTypeId]?.length)
            .reduce((acc, asset) => {
              if (selectedAssetChecklistMap[asset.id]) {
                return {
                  ...acc,
                  [asset.id]: selectedAssetChecklistMap[asset.id]
                };
              }

              const defaultChecklists = assetTypeChecklistMap[asset.assetTypeId]
                .filter(l => l.isDefault)
                .map(l => l.id);

              return {
                ...acc,
                // selected the default checklists, if there are none then pick a random one
                [asset.id]: defaultChecklists.length
                  ? defaultChecklists
                  : [assetTypeChecklistMap[asset.assetTypeId][0].id]
              };
            }, {})
        : {};

      onSelectionModelChange(newSelectedAssetChecklistMap);
    },
    [assetTypeChecklistMap, assets, onSelectionModelChange, selectedAssetChecklistMap]
  );

  const rowCheckChange = useMemo(
    () => (checked, cell) => {
      let newSelectedAssetChecklistMap = {};
      if (cell.isGroupRow && checked) {
        // selecting a group of assets
        const { assetTypeId } = cell.row.__groupData[0];
        const assetTypeChecklistIds = assetTypeChecklistMap[assetTypeId]
          .filter(l => l.isDefault)
          .map(l => l.id);

        newSelectedAssetChecklistMap = {
          ...selectedAssetChecklistMap,
          ...assets
            .filter(asset => asset.assetTypeName === cell.row.assetTypeName)
            .reduce((acc, asset) => {
              if (selectedAssetChecklistMap[asset.id]) {
                return {
                  ...acc,
                  [asset.id]: selectedAssetChecklistMap[asset.id]
                };
              }
              return {
                ...acc,
                [asset.id]: assetTypeChecklistIds.length
                  ? assetTypeChecklistIds
                  : [assetTypeChecklistMap[assetTypeId][0].id]
              };
            }, {})
        };
      } else if (cell.isGroupRow && !checked) {
        // deselecting a group of assets
        const assetIdsToRemove = assets
          .filter(asset => asset.assetTypeName === cell.row.assetTypeName)
          .map(asset => asset.id);

        newSelectedAssetChecklistMap = Object.entries(selectedAssetChecklistMap)
          .filter(([assetId]) => !assetIdsToRemove.includes(assetId))
          .reduce(
            (acc, [assetId, checklistIds]) => ({
              ...acc,
              [assetId]: checklistIds
            }),
            {}
          );
      } else if (!cell.isGroupRow && checked) {
        // select a specific asset
        const { assetTypeId } = cell.row;
        const assetTypeChecklistIds = assetTypeChecklistMap[assetTypeId]
          .filter(l => l.isDefault)
          .map(l => l.id);

        newSelectedAssetChecklistMap = {
          ...selectedAssetChecklistMap,
          [cell.row.id]: assetTypeChecklistIds.length
            ? assetTypeChecklistIds
            : [assetTypeChecklistMap[assetTypeId][0].id]
        };
      } else if (!cell.isGroupRow && !checked) {
        // deselect a specific assset
        newSelectedAssetChecklistMap = Object.entries(selectedAssetChecklistMap)
          .filter(([assetId]) => assetId !== cell.row.id)
          .reduce(
            (acc, [assetId, checklistIds]) => ({
              ...acc,
              [assetId]: checklistIds
            }),
            {}
          );
      }
      onSelectionModelChange(newSelectedAssetChecklistMap);
    },
    [assetTypeChecklistMap, assets, onSelectionModelChange, selectedAssetChecklistMap]
  );

  const columns = useMemo(() => {
    return [
      {
        field: 'id',
        minWidth: 32,
        renderHeader: () => {
          // ignore assets with no assetType or assets that have assetTypes with no checklists
          const relevantAssets = assets.filter(
            a => a.assetTypeId && assetTypeChecklistMap[a.assetTypeId]?.length
          );
          const checked = relevantAssets.every(asset => selectedAssetChecklistMap[asset.id]);

          const indeterminate = relevantAssets.some(asset => selectedAssetChecklistMap[asset.id]);

          return (
            <Checkbox
              checked={checked}
              color="secondary"
              indeterminate={indeterminate}
              onChange={async event => {
                const { checked } = event.target;
                if (hasMetadata) {
                  if (!(await confirmContext.confirm(changeAssetConfirm))) {
                    return;
                  }

                  await deleteAllTemplateGroupsOnServiceAgreement();
                  await refreshServiceAgreementPropertyMetadata();
                  headerCheckChange(checked);
                }

                headerCheckChange(checked);
              }}
            />
          );
        },
        renderCell: cell => {
          let relevantAssets = [];

          if (cell.isGroupRow) {
            relevantAssets = assets.filter(asset => asset.assetTypeName === cell.row.assetTypeName);
          }

          return (
            <Checkbox
              checked={
                !!(cell.isGroupRow
                  ? relevantAssets.every(asset => selectedAssetChecklistMap[asset.id])
                  : selectedAssetChecklistMap[cell.value])
              }
              color="secondary"
              disabled={
                // disabled if this is the Undefined group or individual asset without asset types
                (cell.isGroupRow && !assetTypeChecklistMap[cell.row.__groupData[0].assetTypeId]) ||
                (!cell.isGroupRow && !assetTypeChecklistMap[cell.row.assetTypeId])
              }
              indeterminate={relevantAssets.some(asset => selectedAssetChecklistMap[asset.id])}
              onChange={async event => {
                const { checked } = event.target;
                if (hasMetadata) {
                  if (!(await confirmContext.confirm(changeAssetConfirm))) {
                    return;
                  }

                  await deleteAllTemplateGroupsOnServiceAgreement();
                  await refreshServiceAgreementPropertyMetadata();
                  rowCheckChange(checked, cell);
                }

                rowCheckChange(checked, cell);
              }}
            />
          );
        }
      },
      ...staticColumns,
      {
        field: 'checklists',
        headerName: 'Checklists',
        minWidth: 250,
        renderCell: cell => {
          // every row in this __groupData will have the same assetTypeId
          // unless there are assetTypes with duplicate names
          // and this should never happen
          const assetTypeId = cell.isGroupRow
            ? cell.row.__groupData[0].assetTypeId
            : cell.row.assetTypeId;

          const checklistForAsset = assetTypeChecklistMap[assetTypeId];

          if (!checklistForAsset) return <></>; // this happens for assets without assetTypes

          if (cell.isGroupRow) {
            return (
              <AssetTypeChecklists
                assetMap={assetMap}
                assetTypeChecklistMap={assetTypeChecklistMap}
                cell={cell}
                deleteAllTemplateGroupsOnServiceAgreement={
                  deleteAllTemplateGroupsOnServiceAgreement
                }
                hasMetadata={hasMetadata}
                refreshServiceAgreementPropertyMetadata={refreshServiceAgreementPropertyMetadata}
                selectedAssetChecklistMap={selectedAssetChecklistMap}
                onSelectionModelChange={onSelectionModelChange}
              />
            );
          }

          if (!selectedAssetChecklistMap[cell.row.id]) return <></>;

          return (
            <AssetChecklists
              assetTypeChecklistMap={assetTypeChecklistMap}
              cell={cell}
              checklistMap={checklistMap}
              deleteAllTemplateGroupsOnServiceAgreement={deleteAllTemplateGroupsOnServiceAgreement}
              hasMetadata={hasMetadata}
              refreshServiceAgreementPropertyMetadata={refreshServiceAgreementPropertyMetadata}
              selectedAssetChecklistMap={selectedAssetChecklistMap}
              onSelectionModelChange={onSelectionModelChange}
            />
          );
        }
      },
      {
        flex: 1,
        width: 32,
        headerName: '',
        noPadding: true,
        align: 'center',
        renderCell: cell => {
          if (cell.isGroupRow) return <></>;
          return (
            <MoreButton
              css={{ backgroundColor: 'inherit' }}
              options={[
                {
                  label: 'Edit',
                  icon: EditIcon,
                  onClick: () =>
                    setEditAssetModal({
                      open: true,
                      mode: Mode.EDIT,
                      assetId: cell.row.id,
                      data: { ...cell.row, propertyName: label }
                    })
                }
              ]}
            />
          );
        }
      }
    ];
  }, [
    assets,
    assetTypeChecklistMap,
    selectedAssetChecklistMap,
    hasMetadata,
    headerCheckChange,
    confirmContext,
    deleteAllTemplateGroupsOnServiceAgreement,
    refreshServiceAgreementPropertyMetadata,
    rowCheckChange,
    checklistMap,
    onSelectionModelChange,
    assetMap,
    setEditAssetModal,
    label
  ]);

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box
        style={{
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'space-between',
          marginTop: 40,
          marginBottom: 4
        }}
      >
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            color="textPrimary"
            component="h3"
            style={{ marginRight: 8 }}
            variant={TV.BASE}
            weight={TW.BOLD}
          >
            {label}
          </Typography>
          <Typography color="textPrimary" component="h3" variant={TV.BASE} weight={TW.REGULAR}>
            {rightLabel}
          </Typography>
        </Box>
        {setAddAssetModal && (
          <Box>
            <ThemeProvider>
              <Button
                size={ButtonSize.SMALL}
                type={ButtonType.SECONDARY}
                onClick={async () => {
                  if (hasMetadata) {
                    if (!(await confirmContext.confirm(changeAssetConfirm))) {
                      return;
                    }

                    await deleteAllTemplateGroupsOnServiceAgreement();
                    await refreshServiceAgreementPropertyMetadata();
                  }

                  setAddAssetModal({ open: true, mode: Mode.ADD, propertyId: id });
                }}
              >
                ADD ASSET
              </Button>
            </ThemeProvider>
          </Box>
        )}
      </Box>
      <WrapTable
        autoHeight
        checkboxSelection
        columns={columns}
        disableSelectionOnClick
        hideFooter
        rowGroupingModel={['assetTypeName']}
        rows={assets}
        onSelectionModelChange={onSelectionModelChange}
      />
    </Box>
  );
};

export default PropertyAssets;
