import React, { Component } from 'react';

import ImageComponent from './ImageComponent';

/**
 * Materail UI Image array Display with BuildHero styles
 */
class ImageArrayDisplay extends Component {
  render() {
    const { height, field } = this.props;
    const imageArray = field.value;
    return (
      <>
        {imageArray &&
          imageArray.map(image => (
            <ImageComponent height={height} image={image.imageUrl} key={image.id} />
          ))}
      </>
    );
  }
}

export default ImageArrayDisplay;
