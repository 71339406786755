import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CustomTabs from '@pm/components/CustomTabs';
import SubmenuTitle from '@pm/components/SubmenuTitle';
import UserPermission from 'components/AppPermissions';
import ErrorBoundaries from 'scenes/Error';
import { PermissionConstants } from 'utils/AppConstants';
import compose from 'utils/compose';

import DailyReportsList from './DailyReportList';
import FormList from './FormList';

const FieldReport = props => {
  const { projectId } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const tabMenus = [{ tabLabel: 'Daily Reports' }, { tabLabel: 'Forms' }];

  const handleTabChange = (_event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <ErrorBoundaries>
      <UserPermission action={PermissionConstants.OBJECT_PM_FIELD_REPORT} I="read">
        <SubmenuTitle>Field Reports</SubmenuTitle>
        <CustomTabs tabMenus={tabMenus} value={selectedTab} onChange={handleTabChange} />
        <div style={{ paddingLeft: 17 }}>
          {selectedTab === 0 && <DailyReportsList projectId={projectId} />}
          {selectedTab === 1 && <FormList projectId={projectId} />}
        </div>
      </UserPermission>
    </ErrorBoundaries>
  );
};

FieldReport.propTypes = {
  projectId: PropTypes.string.isRequired
};

const mapStateToProps = (_state, props) => ({
  projectId: props.match.params.projectId
});

export default compose(connect(mapStateToProps))(FieldReport);
