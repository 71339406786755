/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

import { sentryException } from '../Logger/index';

export async function attachmentDelete(id) {
  return await instance
    .delete(`attachment/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to delete attachment');
      return [];
    });
}

export async function attachmentChange(id, newData) {
  return await instance
    .put(`attachment/${id}`, newData)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to change attachment');
      return [];
    });
}

export async function getAttachmentById(id) {
  return await instance
    .get(`attachment/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get attachment by ID');
      return [];
    });
}
