import { noop } from 'lodash';
import { pick } from 'ramda';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import updatePurchaseOrderLine from 'services/core/graphql/review-report/mutations/UpdatePurchaseOrderLine';

export const useEditPurchaseOrderLine = ({ setHasTempDataQueue = noop }) =>
  useExtendedMutation(updatePurchaseOrderLine, {
    serializer: ({ tenantId, purchaseOrderLine }) => {
      const data = pick(
        [
          'description',
          'itemName',
          'departmentName',
          'priceBookEntryId',
          'accountingRefIdOfClass',
          'costCodeId',
          'jobCostTypeId',
          'revenueTypeId',
          'id',
          'version',
          'taxable',
          'quantity',
          'markup',
          'unitCost',
          'unitPrice',
          'includeInInvoice',
          'billingStatus'
        ],
        purchaseOrderLine
      );
      if (typeof purchaseOrderLine.markupValue !== 'undefined') {
        return {
          variables: {
            partitionKey: tenantId,
            data: {
              ...data,
              markup: purchaseOrderLine.markupValue
            }
          }
        };
      }

      return {
        variables: {
          partitionKey: tenantId,
          data
        }
      };
    },
    optimisticResponseFactory: ({ purchaseOrderLine }) => {
      return {
        updatePurchaseOrderLine: {
          ...purchaseOrderLine,
          version: purchaseOrderLine.version + 1
        }
      };
    },
    onRequest: () =>
      setHasTempDataQueue(oldQueue => {
        const newQueue = [...oldQueue];
        newQueue.shift();
        return newQueue;
      })
  });

export default useEditPurchaseOrderLine;
