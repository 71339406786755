import React, { useMemo } from 'react';

import { Button, ThemeProvider } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import { useFlags } from 'launchdarkly-react-client-sdk';

import WrapTable, { tableDateTimeFormatter } from 'components/WrapTable';
import useEagerMultiNotes from 'customHooks/useEagerMultiNotes/useEagerMultiNotes';

import { SectionHeader } from '../Components';

const getColumns = ({ openModal, job, notes }) => [
  { field: 'subject', headerName: 'Note Subject', width: 150 },
  { field: 'note', headerName: 'Note Content' },
  { field: 'lastEditedBy', headerName: 'Last Edited By', width: 200 },
  {
    field: 'lastEditedOn',
    headerName: 'Last Edited On',
    width: 200,
    valueFormatter: tableDateTimeFormatter
  },
  {
    field: 'actions',
    flex: 1,
    maxWidth: 41,
    headerName: '',
    noPadding: true,
    align: 'center',
    justify: 'center',
    renderCell: ({ row }) => {
      return (
        <IconButton
          aria-label="EditDelete"
          onClick={() =>
            openModal({
              id: job.id,
              entityType: 'Job',
              notesCount: notes.length,
              name: job.customIdentitifer || job.jobNumber,
              version: job.version
            })
          }
        >
          <EditIcon css={{ height: 12 }} />
        </IconButton>
      );
    }
  }
];

const mapNotesToRows = notes =>
  notes
    .map(n => ({
      ...n,
      lastEditedBy: n.displayLastUpdatedBy ?? n.lastUpdatedBy,
      lastEditedOn: (n.displayLastUpdatedDateTime ?? n.lastUpdatedDate) * 1000
    }))
    .sort((a, b) => a.sortOrder - b.sortOrder);

const JobCloseoutNotesTable = ({ notes, job, refetchJob, displayEntityType, isLoading }) => {
  const flags = useFlags();

  const rows = mapNotesToRows(notes);

  const { MultiNoteModal, openMultiNoteModal } = useEagerMultiNotes(
    // @TODO optimize useEagerMultiNotes to update cache for created/deleted notes so refetch is not needed
    // updating notes already does not need a refetch
    refetchJob,
    true
  );

  const columns = useMemo(() => getColumns({ openModal: openMultiNoteModal, notes, job }), [job]);

  return (
    <ThemeProvider>
      <SectionHeader title={`${displayEntityType} Notes`}>
        <Button
          loading={isLoading}
          size="small"
          type="secondary"
          onClick={() =>
            openMultiNoteModal({
              id: job.id,
              entityType: 'Job',
              displayEntityType,
              notesCount: rows.length,
              name: job.customIdentitifer || job.jobNumber,
              version: job.version
            })
          }
        >
          Add Note
        </Button>
      </SectionHeader>
      <WrapTable
        columns={columns}
        hideFooter={rows.length < 11}
        loading={isLoading}
        loadingRows={3}
        noDataMessage={`No ${displayEntityType} Notes`}
        rows={rows}
      />
      {MultiNoteModal}
    </ThemeProvider>
  );
};

JobCloseoutNotesTable.defaultProps = {
  displayEntityType: 'Job',
  isLoading: false
};

export default JobCloseoutNotesTable;
