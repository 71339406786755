import { useMemo, useRef } from 'react';

import { useMutation } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { isFunction, noop } from 'lodash';

import { handleError } from '@dispatch/Dispatch.utils';
import {
  hideMutationConfirmation,
  showMutationConfirmation,
  snackbarOn as snackbarOnAction
} from 'redux/actions/globalActions';
import { dispatch } from 'redux/store';

const snackbarOn = (mode, message, errorLog) => dispatch(snackbarOnAction(mode, message, errorLog));

/**
 * Wrapper around @apollo/client useMutation that adds options for transforming and resolving data.
 *
 * @param {string} mutation - The graphQl mutation string
 * @param {Object?} options
 *
 * @param {function} options.transform - a function that transforms the resolved data from a successful mutation.
 * @param {any} options.loadingData - will be returned as data when the query is loading
 * @param {any} options.errorData - will be returned as data when there is an error
 * @param {any} options.defaultData - will be returned if data is falsy. errorData and loadingData take priority
 * @param {boolean} options.hideError - suppress notification on error
 * @param {function} serializer - a function that transforms what is provided to the mutation trigger prior to calling it
 * @param {function} options.onRequest - (variables, snackbarOn) => void
 * @param {function} options.onCompleted - (data, snackbarOn) => void
 * @param {function} options.onSuccess - (transformedData, snackbarOn) => void
 * @param {function} options.onError - (err, snackbarOn) => void
 * @param {function} options.onResponse - ({ err, data }, snackbarOn) => void | fires on success or error
 *
 * @returns [mutationTrigger, mutationResponse] - extended [apollo mutation tuple](https://www.apollographql.com/docs/react/api/react/hooks/#result-2).
 *    - mutationTrigger - A function to trigger a mutation from your UI.
 *    - mutationResponse - { data: any, loading: boolean, error: Error, called: boolean, client: ApolloClient }
 */
const useExtendedMutation = (
  mutation,
  {
    transform,
    loadingData,
    errorData,
    defaultData,
    hideError,
    serializer,
    optimisticResponseFactory,
    showConfirmation,
    confirmationModalOptions = {},
    update = noop,
    skip = false,
    ...options
  } = {}
) => {
  const mutateOptionsRef = useRef(null);

  const [trigger, res] = useMutation(mutation, {
    ...options,
    update: (cache, mutationResponse) => update(cache, mutationResponse, mutateOptionsRef.current),
    onCompleted: data => {
      const transformedData = transform?.(data) || data;
      options.onCompleted?.(data, snackbarOn);
      options.onSuccess?.(transformedData, snackbarOn);
      options.onResponse?.({ data: transformedData }, snackbarOn);
    },
    onError: err => {
      options.onError?.(err, snackbarOn);
      options.onResponse?.({ err }, snackbarOn);
      handleError({ err, errContext: getMainDefinition(mutation)?.name?.value });
    }
  });

  const wasLoading = useRef(false);

  if (res.loading && !wasLoading.current) {
    options.onRequest?.(options.variables, snackbarOn);
    wasLoading.current = true;
  }

  if (!res.loading && wasLoading.current) wasLoading.current = false;

  const mutate = async mutateOptions => {
    if (skip) return;

    try {
      mutateOptionsRef.current = mutateOptions;
      const serializedOptions = (await serializer?.(mutateOptions)) || mutateOptions;

      const shouldShowConfirmation = isFunction(showConfirmation)
        ? showConfirmation(mutateOptions)
        : showConfirmation;

      if (shouldShowConfirmation) {
        let handleConfirm;
        let handleCancel;

        const confirmationPromise = new Promise((resolve, reject) => {
          handleConfirm = resolve;
          handleCancel = reject;
        });

        const modalOptions = isFunction(confirmationModalOptions)
          ? confirmationModalOptions(mutateOptions)
          : confirmationModalOptions;

        dispatch(
          showMutationConfirmation({
            onContinue: handleConfirm,
            onClose: handleCancel,
            ...modalOptions
          })
        );

        try {
          await confirmationPromise;
          dispatch(hideMutationConfirmation());
        } catch (e) {
          dispatch(hideMutationConfirmation());
          return;
        }
      }

      return trigger({
        optimisticResponse: optimisticResponseFactory?.(mutateOptions),
        ...serializedOptions
      });
    } catch (err) {
      options.onError?.(err, snackbarOn);
      options.onResponse?.({ err }, snackbarOn);
      handleError({ err, errContext: getMainDefinition(mutation)?.name?.value });
    }
  };

  const transformedData = useMemo(() => {
    return res.data && transform ? transform(res.data) : res.data;
  }, [res.data, transform]);

  const extendedRes = {
    ...res,
    data: (() => {
      if (res.loading && loadingData) return loadingData;
      if (res.error && errorData) return errorData;

      return transformedData || defaultData;
    })()
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => [mutate, extendedRes], [res.data, res.loading, res.error, skip]);
};

export default useExtendedMutation;
