/* eslint-disable react/require-default-props */
import React, { useState } from 'react';

import { Input, ThemeProvider } from '@BuildHero/sergeant';
import { bool, func } from 'prop-types';

import { Modal } from 'components';

import { useStyles } from './OnHoldReasonModal.styles';

const OnHoldReasonModal = ({ open, setOpen, onConfirm }) => {
  const classes = useStyles();
  const [onHoldReason, setOnHoldReason] = useState('');

  return (
    <Modal
      autoSize
      buttons={{
        confirm: {
          buttonType: 'contained',
          color: 'primary',
          label: 'Save',
          disabled: !onHoldReason,
          action: () => {
            onConfirm?.({ onHoldReason });
            setOpen(false);
          }
        },
        cancel: {
          buttonType: 'outlined',
          label: 'Cancel',
          action: () => {
            setOnHoldReason('');
            setOpen(false);
          }
        }
      }}
      center
      handleClose={() => setOpen(false)}
      modalTitle="On Hold Reason"
      open={open}
      showModalHeader
      width={400}
    >
      <p>Why is this visit being put on hold?</p>
      <ThemeProvider>
        <Input
          className={classes.input}
          multiline
          value={onHoldReason}
          onChange={e => {
            setOnHoldReason(e.target.value);
          }}
        />
      </ThemeProvider>
    </Modal>
  );
};

OnHoldReasonModal.propTypes = {
  open: bool,
  setOpen: func,
  onConfirm: func
};

export default OnHoldReasonModal;
