/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';

import { Button, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import WarningIcon from '@material-ui/icons/Warning';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import * as R from 'ramda';
import { connect } from 'react-redux';

import { SectionHeader, Tooltip } from 'components';
import RolesLayout from 'meta/Settings/People/Roles';
import { snackbarOn, spinnerOff, spinnerOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { CompanyService } from 'services/core';
import { Logger } from 'services/Logger';
import { capitalizeFirstLetter } from 'utils';

import Labels from './resources/labels';
import { styles } from './styles';

class PermissionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.CompanyService = new CompanyService();
    props.meta.map((permissionMetaItem, permissionIndex) => {
      let permissionExists = false;
      const permissionCategory = permissionMetaItem.category;
      const permissionSubCategory = permissionMetaItem.subcategory;
      const dataFilter = props.data.filter(
        item => item.category === permissionCategory && item.subcategory === permissionSubCategory
      );

      // setting flag for permission exists
      if (dataFilter.length >= 1) {
        permissionExists = true;
      }
      let defaultValue = false;
      return (
        permissionMetaItem &&
        permissionMetaItem.actions &&
        permissionMetaItem.actions.forEach((metaActionItem, actionIndex) => {
          if (permissionExists) {
            const { actionName } = metaActionItem || {};
            const actionFilter = dataFilter.filter(item => item.actionName === actionName);

            // setting flag for permission exists
            // old value has to be checked if exist anymore
            if (
              actionFilter.length >= 1 &&
              props.data[permissionIndex] &&
              props.data[permissionIndex].actions
            ) {
              // picking data index, as in migrations we may have the data in different order
              const dataIndex = props.data.findIndex(
                item =>
                  item.category === permissionCategory && item.subcategory === permissionSubCategory
              );
              const correspondingActions = props.data[dataIndex].actions.filter(
                dataAction => dataAction.actionName === metaActionItem
              );

              if (correspondingActions && correspondingActions.length > 0) {
                defaultValue = correspondingActions[0].value;
              } else {
                defaultValue = false;
              }
            }
          }
          const checkboxName = `p${permissionIndex}_a${actionIndex}`;
          this.state[checkboxName] = defaultValue;
        })
      );
    });
  }

  handleOnChange = data => event => {
    // If you have edit access, you are assumed to have view access
    if (data && data.endsWith(1) && event.target.checked) {
      const viewString = `${data.slice(0, data.length - 1)}0`;
      this.setState({
        [data]: event.target.checked,
        [viewString]: event.target.checked
      });
    } else {
      this.setState({ [data]: event.target.checked });
    }
  };

  // Notifies success message
  renderSuccess = () => {
    this.props.snackbarOn('success', 'Successfully posted');
    this.props.handleClose();
  };

  // Notifies user on the error
  renderError = () => {
    this.props.snackbarOn('error', 'Unable to save, please try again later');
    return <></>;
  };

  saveChanges = async () => {
    // TODO: rename roleId to roleRecord
    const result = this.props.roleId;
    result.lsi1 = `${this.props.tenantCompanyId}_AppRole`;
    result.entityType = 'AppRole';
    if (result.__typename) {
      delete result.__typename;
    }
    result.partitionKey = this.props.user.tenantId;
    let permissions = [];
    // permissions are grouped and stored against the same database record for each role
    if (this.props.otherPermissions && this.props.otherPermissions.length > 0) {
      permissions = R.concat(permissions, this.props.otherPermissions);
    }

    this.props.meta.map((permissionMetaItem, permissionIndex) => {
      const localPermission = {};
      localPermission.category = permissionMetaItem.category;
      localPermission.subcategory = permissionMetaItem.subcategory;
      const localActions = [];

      permissionMetaItem.actions.map((metaActionItem, actionIndex) => {
        if (!metaActionItem) return;

        const localAction = {};
        localAction.actionName = metaActionItem;
        localAction.value = this.state[`p${permissionIndex}_a${actionIndex}`];
        return localActions.push(localAction);
      });
      localPermission.actions = localActions;
      return permissions.push(localPermission);
    });
    result.appPermissions = JSON.stringify(permissions);
    try {
      await this.CompanyService.mutatePermission(result, this.props.parent);
      this.renderSuccess();
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to save permissions', error);
    }
  };

  render() {
    const { classes, isAdmin, flags: launchDarklyFlags } = this.props;
    const actionType = RolesLayout(launchDarklyFlags).permissionsMaster.allActions;

    return (
      <ErrorBoundaries>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <SectionHeader
              enablePadding
              title={`${Labels[this.props.title][this.props.user.locale]}`}
            />
            {!isAdmin && (
              <Grid className={classes.headerContainer} item xs={12}>
                <Typography className={classes.header}>
                  <WarningIcon className={classes.warningIcon} />
                  For changes in permissions, please reach out to BuildOps team
                </Typography>
              </Grid>
            )}
            <Grid
              alignItems="center"
              className={classes.gridBorder}
              container
              direction="row"
              justify="center"
            >
              <Grid item xs={7} />
              {actionType.map((item, index) => (
                <Grid item key={`actionHeader${index}`} xs={1}>
                  <Typography className={classes.header}>{capitalizeFirstLetter(item)}</Typography>
                </Grid>
              ))}
            </Grid>
            {this.props.meta.map((permissionMetaItem, permissionIndex) => (
              <Grid
                alignItems="center"
                className={classes.gridBorder}
                container
                direction="row"
                justify="flex-start"
                key={`permissionRow${permissionIndex}`}
              >
                <Grid item key={`permissionName${permissionIndex}`} xs={7}>
                  <Grid container>
                    <Grid item>
                      {permissionMetaItem.tabs && permissionMetaItem.tabs > 1 && (
                        <ArrowRightAlt
                          className={classes.rowIcon}
                          style={{
                            marginLeft: permissionMetaItem.tabs * this.props.theme.spacing(2)
                          }}
                        />
                      )}
                    </Grid>
                    <Grid>
                      <Typography className={classes.rowTitle}>
                        {Labels[permissionMetaItem.subcategory]
                          ? Labels[permissionMetaItem.subcategory][this.props.user.locale]
                          : permissionMetaItem.subcategory}
                        {
                          <Tooltip
                            helpText={
                              Labels[permissionMetaItem.helpText]
                                ? Labels[permissionMetaItem.helpText][this.props.user.locale]
                                : permissionMetaItem.helpText
                            }
                          />
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {actionType.map((action, index) => {
                  const elementArray = [];
                  const checkboxIndex = permissionMetaItem.actions.indexOf(action);
                  const checkboxName = `p${permissionIndex}_a${checkboxIndex}`;

                  if (checkboxIndex !== -1) {
                    elementArray.push(
                      <Grid item key={`permissionAction${index}${checkboxName}`} xs={1}>
                        <Checkbox
                          checked={this.state[checkboxName]}
                          classes={{
                            colorSecondary: classes.checkboxSecondary,
                            checked: classes.checkboxChecked
                          }}
                          disabled={!isAdmin}
                          onChange={this.handleOnChange(checkboxName)}
                        />
                      </Grid>
                    );
                  } else {
                    elementArray.push(
                      <Grid item key={`permissionActionEmpty${index}${checkboxName}`} xs={1} />
                    );
                  }

                  return elementArray;
                })}
              </Grid>
            ))}

            {// Commented for prod release for initial custoemrs - BO -1510
            isAdmin && (
              <Grid
                alignItems="center"
                className={classes.footerButton}
                container
                direction="row"
                justify="flex-end"
                spacing={3}
              >
                <Grid item>
                  <Button color="secondary" variant="outlined" onClick={this.props.handleClose}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={async event => {
                      event.preventDefault();
                      return this.saveChanges();
                    }}
                  >
                    Save changes
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </ErrorBoundaries>
    );
  }
}

const styledPermission = withStyles(styles, { withTheme: true })(PermissionModal);

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog)),
  spinnerOn: () => dispatch(spinnerOn()),
  spinnerOff: () => dispatch(spinnerOff())
});

export default withLDConsumer()(connect(mapStateToProps, mapDispatchToProps)(styledPermission));
