import React, { memo, useRef } from 'react';

import { connect } from 'react-redux';

import { useDepartments, useDispatchTechs } from '@dispatch/queries';

import { createMultiVisitStart } from 'redux/actions/dispatchActions';
import { snackbarOn } from 'redux/actions/globalActions';

import VisitDetailsForm from './VisitDetailsForm.component';

const mapDispatchToProps = {
  snackbarOn,
  createMultiVisitStart
};

const mapStateToProps = state => ({
  multipleVisitsCreateInProcess: state.dispatch.multipleVisitsCreateInProcess
});

const DataProvider = props => {
  const Memoized = useRef(memo(VisitDetailsForm)).current;

  const departmentsResponse = useDepartments();
  const techsResponse = useDispatchTechs();

  return (
    <Memoized {...props} departmentsResponse={departmentsResponse} techsResponse={techsResponse} />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DataProvider);
