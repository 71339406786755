import { FieldType, TH, TV, TW } from '@BuildHero/sergeant';

const senderInfo = sender => ({
  props: {
    style: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: 48,
      marginBottom: 36
    }
  },
  contents: [
    {
      component: 'Image',
      source: 'sender.logoUrl',
      props: {
        alt: sender.name,
        style: { height: '100%' }
      }
    },
    {
      props: {
        style: {
          alignItems: 'flex-end'
        }
      },
      contents: [
        {
          component: 'Typography',
          source: 'sender.address',
          props: {
            height: TH.COMFORTABLE,
            style: {
              width: 160,
              textAlign: 'right'
            }
          }
        },
        {
          component: 'Typography',
          source: 'sender.phoneNumber',
          props: {
            height: TH.COMFORTABLE
          }
        }
      ]
    }
  ]
});

const header = paymentNumber => ({
  props: {
    style: { marginBottom: 36 }
  },
  contents: [
    {
      props: {
        style: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }
      },
      contents: [
        {
          props: {
            style: {
              flexDirection: 'row',
              alignItems: 'center'
            }
          },
          contents: [
            {
              component: 'Typography',
              props: {
                weight: TW.BOLD,
                value: 'Payment Receipt',
                variant: TV.BASE
              }
            }
          ]
        },
        {
          component: 'Field',
          source: 'paymentDate',
          props: {
            type: FieldType.DATE,
            variant: TV.BASE
          }
        }
      ]
    },
    {
      component: 'Typography',
      props: {
        variant: TV.S1,
        value: `Payment ${paymentNumber}`
      }
    }
  ]
});

const extraDetails = {
  props: {},
  contents: [
    {
      component: 'Field',
      source: 'billingCustomerBillTo',
      props: {
        label: 'BILLING CUSTOMER',
        variant: TV.S2
      }
    }
  ]
};

const detail = {
  props: {
    style: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  },
  contents: [
    {
      component: 'Field',
      source: 'paymentTypeName',
      props: {
        label: 'PAYMENT METHOD',
        variant: TV.S2
      }
    },
    {
      component: 'Field',
      source: 'paymentAmount',
      props: {
        label: 'PAYMENT AMOUNT',
        type: FieldType.CURRENCY,
        variant: TV.L,
        weight: TW.BOLD
      }
    }
  ]
};

export default ({ paymentNumber, sender }) => ({
  layouts: {
    default: {
      props: {
        title: `Payment ${paymentNumber}`
      },
      contents: [
        senderInfo(sender),
        header(paymentNumber),
        extraDetails,
        { component: 'Divider', props: { margin: 24 } },
        detail
      ]
    }
  }
});
