/* eslint-disable import/no-cycle */
import React from 'react';

import { TV, TW, Typography } from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logoutClient } from 'client';
import { logout, setAppName } from 'redux/actions/globalActions';
import ChangePassword from 'scenes/Authenticator/ChangePassword';
import { CommonService } from 'services/core';

import AddEntryButton from '../AddEntryButton';

import ProfileIcon from './ProfileIcon';

import Search from './SearchComponents';
import styles from './styles';

/**
 * Render header of the pages,
 */

class UserControls extends React.Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
    this.CommonService = new CommonService();
    this.searchRef = React.createRef();
    this.state = {
      anchorEl: null,
      mobileMoreAnchorEl: null,
      employeeRole: null,
      employeeName: null,
      open: false,
      searchKey: '',
      openChangePasswordDialog: false
    };
  }

  componentDidMount = async () => {
    const { user } = this.props;
    const { data } = await this.CommonService.getAlgoliaSecuredKey();
    const employees = await this.CommonService.getEmployees({
      partitionKey: user.tenantId,
      sortKey: `${user.tenantId}_Company_${user.tenantCompanyId}`,
      filter: { isActive: { eq: true } },
      includeOffschedules: false,
      includeDepartments: false,
      includeAppRole: false,
      includeBillingRate: false,
      includePayrollCost: false
    });
    const searchKey = data.getAlgoliaSecuredKey;

    this.setState({
      employees,
      employeeRole: '',
      employeeName: this.props.user.displayName,
      searchKey
    });
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  logout = async e => {
    e.preventDefault();
    this.props.logout(); // reset the redux store state
    await this.props.auth.logout();
    logoutClient();
    this.props.history.push('/signin');
  };

  handleSearch = () => {
    this.setState({
      open: true
    });
  };

  handleSearchClose = () => {
    this.setState({
      open: false
    });
  };

  handleOpenProfile = () => {
    this.props.history.push({ pathname: '/settings/people', openCurrentEmployee: true });
    this.handleMenuClose();
  };

  renderProfileMenu = () => {
    const { anchorEl } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    const appVersion = process.env.REACT_APP_VERSION?.slice(0, 7);
    const appTag = `v${process.env.REACT_APP_RELEASE} (${appVersion})`;
    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        getContentAnchorEl={null}
        open={isMenuOpen}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleOpenProfile}>Profile</MenuItem>
        <MenuItem
          onClick={() => {
            this.setState({ openChangePasswordDialog: true });
            this.handleMenuClose();
          }}
        >
          Change Password
        </MenuItem>
        <MenuItem onClick={this.logout}>Sign Out</MenuItem>
        <Typography
          style={{
            fontSize: 8,
            paddingTop: 8,
            paddingRight: 16,
            display: 'flex',
            justifyContent: 'flex-end',
            cursor: 'default'
          }}
          weight={TW.REGULAR}
        >
          {appTag}
        </Typography>
      </Menu>
    );
  };

  renderMobileProfileMenu = () => {
    const { mobileMoreAnchorEl, currentUser } = this.state;
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    return (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={this.handleMobileMenuClose}
      >
        <MenuItem onClick={this.handleProfileMenuOpen}>
          <Grid alignItems="center" container direction="row" justify="center" spacing={0}>
            <Grid item>
              <ProfileIcon
                alt={this.state.employeeName}
                employeeName={this.state.employeeName}
                imageKey={currentUser?.profilePictureUrl}
              />
            </Grid>
            <Grid item>
              <Typography variant={TV.BASE} weight={TW.MEDIUM}>
                {`${this.props.user.displayName}, ${this.state.employeeRole}`}
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem onClick={this.logout}>Sign Out</MenuItem>
      </Menu>
    );
  };

  render() {
    const { anchorEl, openChangePasswordDialog, employees } = this.state;
    const { classes, user, tenantSettingProcessed, isSmallScreen } = this.props;
    const currentUser = employees?.find(item => item.id === this.props.user.employeeId);
    const isMenuOpen = Boolean(anchorEl);
    return (
      <div className={classes.userControls}>
        <div className={classes.search}>
          <Box display="block" displayPrint="none">
            {this.state.searchKey && <Search searchKey={this.state.searchKey} />}
            <div id="globalSearch" style={{ width: 2, height: 2 }} />
          </Box>
        </div>
        <AddEntryButton
          isSmallScreen={isSmallScreen}
          tenantSettingProcessed={tenantSettingProcessed}
        />
        {!this.state.open && (
          <>
            <div className={classes.sectionDesktop}>
              <Box display="block" displayPrint="none">
                <Grid alignItems="center" container direction="row" justify="center" spacing={0}>
                  <Grid item>
                    <IconButton
                      aria-haspopup="true"
                      aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                      classes={{ root: classes.profileButton }}
                      style={{ opacity: anchorEl && 1 }}
                      onClick={this.handleProfileMenuOpen}
                    >
                      <ProfileIcon
                        alt={this.state.employeeName}
                        employeeName={this.state.employeeName}
                        imageKey={currentUser?.profilePictureUrl}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            </div>
            <div className={classes.sectionMobile}>
              <Box display="block" displayPrint="none">
                <IconButton
                  aria-haspopup="true"
                  color="inherit"
                  onClick={this.handleMobileMenuOpen}
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </div>
            {this.renderProfileMenu()}
            {this.renderMobileProfileMenu()}
          </>
        )}
        <Dialog hideCloseButton open={openChangePasswordDialog} width="340">
          <ChangePassword
            handleClose={() => this.setState({ openChangePasswordDialog: false })}
            user={user}
          />
        </Dialog>
      </div>
    );
  }
}

UserControls.propTypes = {
  classes: PropTypes.object.isRequired
};

const styleHeadertoolbar = withStyles(styles)(UserControls);

const routerHeader = withRouter(styleHeadertoolbar);
// Gets dispatcher action from redux global actions
const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  changeAppName: appName => dispatch(setAppName(appName))
});

// pass user context, app, and menu state from redux as props to the component
const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});

export default connect(mapStateToProps, mapDispatchToProps)(routerHeader);
