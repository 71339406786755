import React, { useRef } from 'react';

import { Input } from '@BuildHero/sergeant';
import { Box, Grid, IconButton } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

import styles from '../../styles';

const AccordionSummary = withStyles(styles.accordionSummary)(MuiAccordionSummary);

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  marginBottom: '5px',

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'rgb(240, 240, 240)',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getCloseStyle = item => ({
  padding: 0,
  marginLeft: 5,
  height: '100%',
  color: !item.unsaved && 'transparent'
});

const LaborRateGroupCard = ({
  item,
  index,
  isDragDisabled,
  laborRateGroups,
  onNameChange,
  onAddLaborRateGroup,
  onRemoveLaborRateGroup
}) => {
  const anchorRef = useRef();

  return (
    <Draggable
      draggableId={`draggable-${item.id}`}
      index={index}
      isDragDisabled={isDragDisabled}
      key={item.id}
    >
      {(provided, snapshot) => (
        <div style={{ opacity: snapshot.isDragging ? 0.5 : 1 }}>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            <Box css={styles.accordionContainer} m={0} p={0}>
              <MuiAccordion square>
                <AccordionSummary>
                  <div {...provided.dragHandleProps} css={styles.dragHandler}>
                    <IconButton css={styles.dragIcon} disableRipple>
                      <DragIndicatorIcon css={styles.dragIcon} />
                    </IconButton>
                  </div>
                  <Box component="div" width={1}>
                    <Input
                      autoFocus={
                        index === laborRateGroups.length - 1 && laborRateGroups[index].name === ''
                      }
                      css={styles.input}
                      defaultValue={item.name ?? ''}
                      id={`scope-input-${item.id}`}
                      label=""
                      placeholder='Enter Labor Rate Group (eg "Locale - Craft")'
                      onBlur={e => {
                        if (!e.target.value) {
                          onRemoveLaborRateGroup({ index });
                        } else {
                          onNameChange({ value: e.target.value, index });
                        }
                        e.relatedTarget?.click();
                      }}
                      onChange={e => onNameChange({ value: e.target.value, index })}
                      onKeyDown={e => {
                        if (e.key === 'Enter') onAddLaborRateGroup();
                      }}
                    />
                  </Box>
                  <Grid item justify="flex-end">
                    <IconButton
                      disabled={!item.unsaved}
                      disableRipple
                      ref={anchorRef}
                      style={getCloseStyle(item)}
                      onClick={() => onRemoveLaborRateGroup({ index })}
                    >
                      <CloseIcon css={styles.closeIcon} />
                    </IconButton>
                  </Grid>
                </AccordionSummary>
              </MuiAccordion>
            </Box>
          </div>
        </div>
      )}
    </Draggable>
  );
};

LaborRateGroupCard.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  laborRateGroups: PropTypes.array,
  onNameChange: PropTypes.func,
  onAddLaborRateGroup: PropTypes.func,
  onRemoveLaborRateGroup: PropTypes.func
};

LaborRateGroupCard.defaultProps = {
  item: {},
  index: 0,
  laborRateGroups: [],
  onNameChange: () => null,
  onAddLaborRateGroup: () => null,
  onRemoveLaborRateGroup: () => null
};

export default LaborRateGroupCard;
