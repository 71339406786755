import React, { useEffect, useMemo, useState } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { debounce, isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as Yup from 'yup';

import { Context, withLoading } from 'components';
import { snackbarOn as snackbar } from 'redux/actions/globalActions';

import ErrorBoundaries from 'scenes/Error';

import { checkPermission } from 'utils';
import { FeatureGateConstants, PermissionConstants, QuoteStatus } from 'utils/AppConstants';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import defaultQuoteForm from '../../../meta/defaultQuoteForm';

import { getServiceAgreementsByProperty } from '../../../utils/helper';

import ExpansionCard from './ExpansionCard';
import QuotePurchaseOrders from './QuotePurchaseOrders';
import {
  customerRepExpandComponent,
  elementProps,
  propertyRepExpandComponent
} from './utils/Info.reps.utils';
import { getAddressFigure, getContactFigure } from './utils/Info.utils';

const useStyles = makeStyles(() => ({
  infoContainer: {
    // single column at 1440px breakpoint, otherwise two columns
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%'
  },
  basicInfo: {
    maxWidth: '691px',
    minWidth: '683px',
    marginBottom: 15,
    marginRight: 10
  },
  cardContainer: {
    maxWidth: '691px',
    minWidth: '683px'
  }
}));

function Info(props) {
  const classes = useStyles();
  const {
    accountManagerOptions,
    primaryQuoteId,
    setOpenPropertyModal,
    updatedQuoteInfo,
    setUpdatedQuoteInfo,
    addRep,
    infoSave,
    isLoading,
    propertiesAndRepsData,
    propertyDetails,
    quoteInfo,
    setFormService,
    setPropertiesAndRepsData,
    updateQuoteRep,
    user,
    companyEmployees,
    companyServiceAgreements,
    isReadOnly,
    quotePurchaseOrders,
    defaultPriceBookId,
    defaultPriceBookMsg,
    setDefaultPriceBookMsg
  } = props;
  const flags = useFlags();
  const MuiFormWithLoading = withLoading(MUIForm);
  const [quoteHasExistingJob, setQuoteHasExistingJob] = useState(false);
  const companyContext = Context.getCompanyContext();
  let departmentsOptions = [];
  let serviceAgreementOptions = [];
  let priceBooksOptions = [];
  if (companyContext) {
    departmentsOptions = constructSelectOptions(
      companyContext.getCompany.departments.items,
      'tagName'
    );
    priceBooksOptions = constructSelectOptions(companyContext.getCompany.priceBooks.items, 'name');
  }
  const serviceAgreementsByProperty = getServiceAgreementsByProperty(
    companyServiceAgreements,
    propertyDetails?.property?.id
  );
  serviceAgreementOptions =
    serviceAgreementsByProperty?.map(agreement => ({
      label: agreement.agreementName,
      value: agreement.id,
      priceBook: agreement.priceBook
    })) || [];
  const activeEmployees = companyEmployees.filter(emp => emp.isActive);
  const projectManagerValues = activeEmployees
    .filter(emp => emp.isSales)
    .map(se => ({
      label: `${se.firstName ? `${se.firstName} ` : ''}${se.lastName ? se.lastName : ''}`,
      value: se.id
    }));
  const soldByValues = activeEmployees.map(se => ({
    label: `${se.firstName ? `${se.firstName} ` : ''}${se.lastName ? se.lastName : ''}`,
    value: se.id
  }));
  const getServiceAgreementPricebook = id => {
    return serviceAgreementOptions?.find(agreement => agreement.value === id)?.priceBook || {};
  };

  const getFormattedData = data => {
    const priceBook = getServiceAgreementPricebook(data.serviceAgreementId);

    // Default acct mgr when creating a quote if only one available
    const defaultAcctMgrId =
      !data.id && accountManagerOptions.length === 1 ? accountManagerOptions[0].value : null;

    const updatedData = {
      id: data.id,
      dueDate: data.dueDate,
      expirationLength: data.expirationLength || 30,
      name: data.name,
      versionLabel: data.versionLabel,
      ownerId: data.ownerId,
      salesById: data.salesById,
      customerPoNumber: data.customerPoNumber,
      departmentId: data.departmentId,
      serviceAgreementId: data.serviceAgreementId,
      priceBookId: data.priceBookId || priceBook.id || defaultPriceBookId,
      accountManagerId: data.accountManagerId || defaultAcctMgrId,
      defaultPriceBookMsg
    };
    return updatedData;
  };

  const hasServiceAgreements = checkPermission(
    'read',
    PermissionConstants.OBJECT_SERVICE_AGREEMENT,
    user,
    null,
    null,
    FeatureFlags.SERVICE_AGREEMENTS,
    flags
  );
  const hasMultiplePricebooks = checkPermission(
    'read',
    PermissionConstants.OBJECT_QUOTES, // Intentionally making it quote as we need to check only feature gate. There is no perm for Pricebook
    user,
    null,
    FeatureGateConstants.MULTIPLE_PRICE_BOOKS
  );

  const handlePricebookDropdown = (valueSet, form) => {
    // display service agreement's priceBook if exist, otherwise display default priceBook
    const priceBook = getServiceAgreementPricebook(valueSet?.value);
    if (priceBook?.id) {
      form.setValues({
        ...form.values,
        priceBookId: priceBook.id,
        serviceAgreementId: valueSet?.value
      });
      if (defaultPriceBookMsg && isEmpty(quoteInfo)) {
        setUpdatedQuoteInfo({
          ...form.values,
          priceBookId: priceBook.id,
          serviceAgreementId: valueSet?.value
        });
        setDefaultPriceBookMsg('');
      }
    } else {
      form.setValues({
        ...form.values,
        priceBookId: defaultPriceBookId,
        serviceAgreementId: valueSet?.value
      });
    }
  };

  const handlePricebookChange = (valueset, form) => {
    form.setValues({
      ...form.values,
      priceBookId: valueset.value
    });

    // Remove msg specifying the PB defaults from the customer or property after the user changes the pricebook
    if (defaultPriceBookMsg && isEmpty(quoteInfo)) {
      setUpdatedQuoteInfo({
        ...form.values,
        defaultPriceBookMsg: '',
        priceBookId: valueset.value
      });
      setDefaultPriceBookMsg('');
    }
  };

  const validationSchema = Yup.object().shape({
    expirationLength: Yup.number().required('Expiration length is required'),
    priceBookId: Yup.string().required('Pricebook is required')
  });

  useEffect(() => {
    if (flags[FeatureFlags.JOB_QUOTE_STATUS])
      setQuoteHasExistingJob(quoteInfo?.quoteJobs?.items?.length > 0);
  }, [quoteInfo?.quoteJobs]);

  const quoteInfoForm = useMemo(() => {
    const isDueDateDisabled =
      quoteInfo.status === QuoteStatus.REJECTED || quoteInfo.status === QuoteStatus.DISCARDED;
    const formattedData = getFormattedData(isEmpty(quoteInfo) ? updatedQuoteInfo : quoteInfo);
    const showDefaultPricebookMsg =
      defaultPriceBookMsg && formattedData.priceBookId === defaultPriceBookId;

    return (
      <Box className={classes.basicInfo} component="div">
        <MuiFormWithLoading
          configuration={defaultQuoteForm(
            projectManagerValues,
            soldByValues,
            departmentsOptions,
            serviceAgreementOptions,
            priceBooksOptions,
            handlePricebookDropdown,
            hasServiceAgreements,
            hasMultiplePricebooks,
            isDueDateDisabled,
            accountManagerOptions,
            isReadOnly,
            handlePricebookChange,
            showDefaultPricebookMsg
          )}
          data={formattedData}
          isLoading={isLoading}
          loadingParams={{
            variant: 'table',
            repeatCount: 5,
            paddingTop: 12,
            paddingLeft: 8,
            paddingRight: 8
          }}
          validateOnChange
          validationSchema={validationSchema}
          onComplete={infoSave}
          onCreateService={service => setFormService(service)}
          onFormChange={debounce(data => {
            if (data.id && !isEqual(formattedData, data) && data.expirationLength) {
              infoSave(data);
            } else {
              // info state is used only on a new quote
              setUpdatedQuoteInfo(data);
            }
          }, 350)}
        />
      </Box>
    );
  }, [
    accountManagerOptions,
    quoteInfo.id,
    quoteInfo.status,
    isLoading,
    companyEmployees,
    propertiesAndRepsData,
    propertyDetails,
    defaultPriceBookMsg,
    quoteInfo.priceBookId
  ]);

  return (
    <ErrorBoundaries>
      <Box className={classes.infoContainer} component="div">
        <Box>
          {quoteInfoForm}
          <QuotePurchaseOrders
            containerClass={classes.basicInfo}
            flags={flags}
            quoteId={quoteInfo?.id}
            quotePurchaseOrders={quotePurchaseOrders}
            user={user}
          />
        </Box>
        <Box className={classes.cardContainer} component="div">
          <ExpansionCard
            btnAction={
              !quoteHasExistingJob &&
              primaryQuoteId &&
              !isReadOnly &&
              (() => setOpenPropertyModal(true))
            }
            info={getAddressFigure(propertyDetails?.property)}
            name={propertyDetails?.property?.companyName || ''}
            title="PROPERTY"
          />
          <ExpansionCard
            info={getAddressFigure(propertyDetails?.customer)}
            name={propertyDetails?.customer?.customerName || ''}
            title="CUSTOMER NAME"
          />
          <ExpansionCard
            enableExpand={!!(propertiesAndRepsData.reps && !isEmpty(propertiesAndRepsData.reps))}
            expandComponent={propertyRepExpandComponent(propertiesAndRepsData, addRep)}
            info={getContactFigure(
              (propertiesAndRepsData?.reps || []).find(
                r => r.id === propertiesAndRepsData.propertyRep
              )
            )}
            reqElementProps={elementProps({
              repType: 'propertyRep',
              propertiesAndRepsData,
              user,
              setPropertiesAndRepsData,
              quoteInfo,
              updateQuoteRep
            })}
            requireElement="dropdownFlyCreation"
            title="Property Representative"
          />
          <ExpansionCard
            enableExpand={
              !!(propertiesAndRepsData.customerReps && !isEmpty(propertiesAndRepsData.customerReps))
            }
            expandComponent={customerRepExpandComponent(propertiesAndRepsData, addRep)}
            info={getContactFigure(
              (propertiesAndRepsData?.customerReps || []).find(
                r => r.id === propertiesAndRepsData.customerRep
              )
            )}
            reqElementProps={elementProps({
              repType: 'customerRep',
              propertiesAndRepsData,
              user,
              setPropertiesAndRepsData,
              quoteInfo,
              updateQuoteRep
            })}
            requireElement="dropdownFlyCreation"
            title="Company Representative / Ordered By"
          />
          <ExpansionCard
            info={getAddressFigure(propertyDetails?.property, true)}
            name={' '}
            title="BILLING"
          />
        </Box>
      </Box>
    </ErrorBoundaries>
  );
}

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbar(mode, message, errorLog))
});

Info.propTypes = {
  updatedQuoteInfo: PropTypes.object.isRequired,
  setUpdatedQuoteInfo: PropTypes.func.isRequired,
  addRep: PropTypes.func.isRequired,
  infoSave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  propertiesAndRepsData: PropTypes.object.isRequired,
  propertyDetails: PropTypes.object.isRequired,
  quoteInfo: PropTypes.object.isRequired,
  setFormService: PropTypes.func.isRequired,
  setPropertiesAndRepsData: PropTypes.func.isRequired,
  updateQuoteRep: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  companyEmployees: PropTypes.array.isRequired,
  companyServiceAgreements: PropTypes.array.isRequired,
  defaultPriceBookId: PropTypes.string.isRequired,
  defaultPriceBookMsg: PropTypes.string.isRequired,
  setDefaultPriceBookMsg: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(Info);
