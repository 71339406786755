import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { useSnackbar } from 'customHooks/useSnackbar';
import { logErrorWithCallback } from 'utils';

const dismissTimesheetEntryBinders = gql`
  mutation dismissTimesheetEntryBinders($data: DismissTimesheetEntryBindersInput!) {
    dismissTimesheetEntryBinders(data: $data) {
      id
    }
  }
`;

const serializer = data => {
  return {
    variables: {
      data: {
        timesheetEntryBinders: data.map(event => ({
          id: event.binderId
        }))
      }
    }
  };
};

const useDismissTimesheetEntryBinders = () => {
  const snackbar = useSnackbar();

  return useExtendedMutation(dismissTimesheetEntryBinders, {
    serializer,
    onError: error => {
      logErrorWithCallback(error, snackbar, 'Unable to dismiss entry');
    },
    onCompleted: () => {
      snackbar('success', 'Successfully dismissed entry');
    }
  });
};

export default useDismissTimesheetEntryBinders;
