import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';

import AmplifyService from 'services/AmplifyService';
import { Logger } from 'services/Logger';
import { logErrorWithCallback } from 'utils';

import {
  addExternalMessagesToCompanyMutation,
  deleteExternalMessageMutation,
  getCompanyDepartmentsQuery,
  getCompanyExternalMessagesQuery,
  updateExternalMessageMutation
} from './queries';

export const getCompanyExternalMessages = async (partitionKey, sortKey) => {
  const appsyncClient = await AmplifyService.appSyncClient();
  try {
    const response = await appsyncClient.client.query({
      query: getCompanyExternalMessagesQuery,
      variables: {
        partitionKey,
        sortKey
      }
    });
    return response;
  } catch (error) {
    Logger.error(error);
  }
};

export const getCompanyDepartments = async (partitionKey, sortKey) => {
  const appsyncClient = await AmplifyService.appSyncClient();
  try {
    const response = await appsyncClient.client.query({
      query: getCompanyDepartmentsQuery,
      variables: {
        partitionKey,
        sortKey
      }
    });
    return response;
  } catch (error) {
    Logger.error(error);
  }
};

export const addExternalMessagesToCompany = async (partitionKey, data) => {
  const appsyncClient = await AmplifyService.appSyncClient();
  try {
    const response = await appsyncClient.client.mutate({
      mutation: addExternalMessagesToCompanyMutation,
      variables: {
        partitionKey,
        data
      }
    });
    return response;
  } catch (error) {
    Logger.error(error);
  }
};

export const updateExternalMessage = async (partitionKey, data) => {
  const appsyncClient = await AmplifyService.appSyncClient();
  try {
    const response = await appsyncClient.client.mutate({
      mutation: updateExternalMessageMutation,
      variables: {
        partitionKey,
        data
      }
    });
    return response;
  } catch (error) {
    Logger.error(error);
  }
};

export const deleteExternalMessage = async (partitionKey, id) => {
  const appsyncClient = await AmplifyService.appSyncClient();
  try {
    const response = await appsyncClient.client.mutate({
      mutation: deleteExternalMessageMutation,
      variables: {
        partitionKey,
        id
      }
    });
    return response;
  } catch (error) {
    Logger.error(error);
  }
};

export const useExternalMessagesQuery = (
  tenantId,
  tenantCompanyId,
  snackbar,
  options = undefined
) => {
  const [data, setData] = useState();
  const { data: rawData, loading, error, refetch } = useQuery(getCompanyExternalMessagesQuery, {
    variables: { partitionKey: tenantId, sortKey: `${tenantId}_Company_${tenantCompanyId}` },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options
  });

  useEffect(() => {
    const run = async () => {
      if (rawData) {
        setData(rawData.getCompany.externalMessages?.items);
      }
    };
    run();
  }, [rawData]);

  if (error) {
    logErrorWithCallback(error, snackbar, 'Failed to load company external messages');
  }
  return { loading, error, data, refetch };
};
