import { removeEmptyValues, removeNestedJson } from '../../utils';
import InstructionHelper from '../InstructionHelper';

const employeeSchema = props => {
  const companyData = removeEmptyValues(props.parent);
  // const skillsData = skills.items;
  const appRolesData = companyData.appRoles.items;

  let selectedroleItem = {};
  // const loginDetails = {};

  const result = [];
  const homeAddressTemplate = {
    homeAddressLine1: 'addressLine1',
    homeAddressLine2: 'addressLine2',
    homeCity: 'city',
    homeState: 'state',
    homeZipcode: 'zipcode',
    homeLatitude: 'latitude',
    homeLongitude: 'longitude',
    homeId: 'id'
  };

  // Forming different address type JSONs
  const homeAddressJson = { addressType: 'homeAddress' };
  const fieldsToOmitFromForm = [
    'rowId',
    'roleId',
    'processedDepartments',
    'processedSkills',
    'processedRole',
    'roleMapping',
    'roleString',
    'departmentString',
    'departmentList',
    'skillList',
    '__typename',
    'parent'
  ];

  const otherFields = {
    contactType: 'employee'
  };

  // Flags
  let hasHomeAddressField = false;
  const selectedDepartments = [];
  const selectedSkills = [];
  const masterDepartments = (props.parent || {}).departments || [];
  const masterSkills = (props.parent || {}).skills || [];
  // Iterating JSON keys of filled formik form
  Object.keys(props).forEach(key => {
    // checks if the key is billing address template, when the value is not empty transform
    if (homeAddressTemplate[key] && props[key] && props[key] !== '') {
      hasHomeAddressField = true;
      const templateKey = homeAddressTemplate[key];
      homeAddressJson[templateKey] = props[key];
      return;
    }

    // non address fileds to be pushed seperately
    if (props[key] && props[key] !== '') {
      // if (key === 'status') {
      //   return null;
      // }
      if (key === 'processedDepartments' && masterDepartments && masterDepartments.length > 0) {
        props.processedDepartments &&
          props.processedDepartments.forEach(departmentId => {
            // not using filter as __typename needs to be removed
            masterDepartments.forEach(department => {
              if (department.id === departmentId) {
                const localDepartment = department;
                if (localDepartment.__typeName) {
                  delete localDepartment.__typeName;
                }
                selectedDepartments.push(localDepartment);
              }
            });
          });
      } else if (key === 'processedRole') {
        // eslint-disable-next-line prefer-destructuring
        selectedroleItem = appRolesData.filter(role => role.tagName === props.processedRole)[0];
      } else if (key === 'processedSkills' && masterSkills.length > 0) {
        props.processedSkills &&
          props.processedSkills.forEach(skillId => {
            // not using filter as __typename needs to be removed
            masterSkills &&
              masterSkills.forEach(skill => {
                if (skill.id === skillId) {
                  const localSkill = skill;
                  if (localSkill.__typeName) delete localSkill.__typeName;
                  selectedSkills.push(localSkill);
                }
              });
          });
      } else if (!fieldsToOmitFromForm.includes(key)) {
        const value = typeof props[key] === 'string' ? props[key].trim() : props[key];
        otherFields[key] = value;
      }
    }
  });

  // let addresses = [];
  // Iterating props and filling approp
  if (hasHomeAddressField) {
    homeAddressJson.entityType = 'ContactAddress';
    homeAddressJson.partitionKey = props.tenantId;
    homeAddressJson.tenantId = props.tenantId;
    homeAddressJson.tenantCompanyId = props.tenantCompanyId;
    result.push(homeAddressJson);
  }

  otherFields.entityType = 'Employee';
  if (props.id) {
    otherFields.id = props.id;
  } else {
    otherFields.status = 'created';
  }
  otherFields.email = otherFields.email ? otherFields.email.toLowerCase().trim() : '';
  otherFields.status = otherFields.status ? otherFields.status.toLowerCase().trim() : '';
  otherFields.name = `${otherFields.firstName.trim()} ${otherFields.lastName.trim()}`;
  result.push(otherFields);

  const rolesJson = removeNestedJson(selectedroleItem);
  const userEmail = props.email ? props.email.toLowerCase().trim() : null;

  const employeePayload = {
    profilePictureUrl: props.profilePictureUrl || null,
    firstName: props.firstName || null,
    lastName: props.lastName || null,
    name: `${otherFields.firstName.trim()} ${otherFields.lastName.trim()}` || null,
    cellPhone: props.cellPhone || null,
    personalEmail: props.personalEmail || null,
    code: props.code || null,
    landlinePhone: props.landlinePhone || null,
    email: userEmail,
    userName:
      props.userName && props.email && props.email !== props.userName
        ? props.userName.toLowerCase().trim()
        : null,
    isAdmin: props.isAdmin === 'true' || props.isAdmin === true,
    status: props.status ? props.status.toLowerCase().trim() : null,
    nickName: props.nickName || null,
    isSales: props.isSales === 'true' || props.isSales === true || false,
    isTech: props.isTech === 'true' || props.isTech === true || false,
    isCrewMember: props.isCrewMember === 'true' || props.isCrewMember === true || false,
    isActive: props.isActive === 'true' || props.isActive === true || false,
    accountingRefId: props.accountingRefId || null,
    partitionKey: props.tenantId,
    tenantId: props.tenantId,
    tenantCompanyId: props.tenantCompanyId,
    entityType: 'Employee',
    contactType: 'employee'
  };

  if (props.id) {
    employeePayload.id = props.id;
    employeePayload.version = props.version;
  } else {
    employeePayload.status = 'created';
  }

  const data = [{ ...removeNestedJson(companyData) }, { ...employeePayload }, { ...rolesJson }];

  // let addresses = [];
  const ins = new InstructionHelper();
  let temp = ins.oneToMany(0, 1).manyToMany(1, 2, 'name', 'tagName');

  // Iterating props and filling approp
  if (hasHomeAddressField) {
    homeAddressJson.entityType = 'ContactAddress';
    homeAddressJson.partitionKey = props.tenantId;
    homeAddressJson.tenantId = props.tenantId;
    homeAddressJson.tenantCompanyId = props.tenantCompanyId;
    data.push(homeAddressJson);
    temp = ins.oneToMany(1, data.length - 1);
  }

  selectedDepartments &&
    selectedDepartments.forEach(department => {
      data.push(removeNestedJson(department));
      temp = temp.manyToMany(1, data.length - 1, 'name', 'tagName');
    });

  selectedSkills &&
    selectedSkills.forEach(skill => {
      data.push(removeNestedJson(skill));
      temp = temp.manyToMany(1, data.length - 1, 'name', 'tagName');
    });

  // // login details to be created only in the first entry
  // if (!props.id) {
  //   data.push(loginDetails);
  //   temp = temp.oneToMany(1, data.length - 1);
  // }

  const { instructionSet } = temp;
  return { data, instructionSet };
};
export default employeeSchema;
