import React, { useEffect } from 'react';

import { Typography } from '@BuildHero/sergeant';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useShouldShowGlobalBanner } from 'customHooks/useShouldShowGlobalBanner';

/**
 * Shows a banner at the top of the screen. Used for global messages.
 * Currently only used to show whether the tenant is a test/training account.
 */
const GlobalBanner = ({ setShowBanner }) => {
  const { palette, spacing } = useTheme();
  const type = useSelector(s => s.company?.tenant?.type);
  const shouldShow = useShouldShowGlobalBanner();

  useEffect(() => {
    setShowBanner?.(shouldShow);
  }, [setShowBanner, shouldShow]);

  if (!shouldShow) return null;

  return (
    <div
      css={{
        color: palette.support.blue.dark,
        backgroundColor: palette.support.blue.medium,
        padding: `${spacing(1)}px ${spacing(3)}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // needs to be hardcoded b/c the navigation is hardcoding the padding of the main content
        maxHeight: 32,
        height: 32
      }}
    >
      <Typography>
        <b>Reminder:</b> You&apos;re in a {type.toLowerCase()} account
      </Typography>
    </div>
  );
};

GlobalBanner.propTypes = {
  setShowBanner: PropTypes.func
};

GlobalBanner.defaultProps = {
  setShowBanner: undefined
};

export default GlobalBanner;
