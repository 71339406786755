import React from 'react';

import { InputAdornment, TextField, Typography } from '@material-ui/core';

const RangeInput = props => {
  const {
    handleOnChange,
    validateValues,
    addAdornment,
    inputClassName,
    adornmentClassName,
    value,
    index,
    keyName,
    type
  } = props;

  return (
    <>
      <TextField
        InputProps={{
          className: inputClassName,
          disableUnderline: true,
          startAdornment: addAdornment && (
            <InputAdornment className={adornmentClassName} position="start">
              <Typography variant="subtitle1"> $</Typography>
            </InputAdornment>
          )
        }}
        placeholder="-"
        type={type}
        value={value}
        onBlur={e => validateValues(keyName, e, index)}
        onChange={e => handleOnChange(keyName, e, index)}
      />
    </>
  );
};

export default RangeInput;
