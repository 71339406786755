import React, { useRef } from 'react';

import { noop } from 'lodash';
import PropType from 'prop-types';
import uuid from 'uuid';

import PreferredTechniciansForm from './PreferredTechniciansForm';
import {
  CrewsPropType,
  DepartmentsPropType,
  PreferredTechniciansPropType,
  TechniciansPropType
} from './PropTypes';

/*
  This is an adapter for PreferredTechniciansForm to support its nesting in another layout.
  It unpacks necessary data from `field` and `options`, passes data up to the containing form,
  and registers validation handler.
 */
const PreferredTechniciansFormLayoutComponent = ({
  field,
  form,
  options,
  parentFormService,
  hideExtraTechNumber,
  crewTimeTracking,
  visitDate,
  isEdit
}) => {
  const fieldId = useRef(`preferred_technicians_${uuid.v4()}`);
  const preferredTechnicians = field?.value || {};
  const { departments, crews, technicians, jobDepartments, restrictedEdits } = options;
  const { getRequiredFields } = options;
  const requiredFields = getRequiredFields();
  const handleChange = data => {
    form.setFieldValue('preferredTechnicians', {
      ...data
    });
  };
  return (
    <PreferredTechniciansForm
      crews={crews}
      crewTimeTracking={crewTimeTracking || options.crewTimeTracking}
      departments={departments}
      hideExtraTechNumber={hideExtraTechNumber || crewTimeTracking}
      isEdit={isEdit}
      jobDepartments={jobDepartments}
      preferredTechnicians={preferredTechnicians}
      requiredFields={requiredFields}
      restrictedEdits={restrictedEdits}
      technicians={technicians}
      visitDate={visitDate}
      onChange={handleChange}
      onComplete={noop}
      onCreateService={service => {
        if (parentFormService?.registerValidationHandler) {
          parentFormService.registerValidationHandler(fieldId.current, () =>
            service.validateForm()
          );
        }
      }}
    />
  );
};

PreferredTechniciansFormLayoutComponent.propTypes = {
  field: PropType.shape({
    value: PreferredTechniciansPropType
  }).isRequired,
  form: PropType.shape({
    setFieldValue: PropType.func.isRequired
  }).isRequired,
  options: PropType.shape({
    departments: DepartmentsPropType.isRequired,
    crews: CrewsPropType.isRequired,
    technicians: TechniciansPropType.isRequired,
    jobDepartments: PropType.array,
    getRequiredFields: PropType.func.isRequired,
    restrictedEdits: PropType.bool,
    crewTimeTracking: PropType.bool
  }).isRequired,
  parentFormService: PropType.func,
  hideExtraTechNumber: PropType.bool,
  crewTimeTracking: PropType.bool,
  visitDate: PropType.number,
  isEdit: PropType.bool
};

PreferredTechniciansFormLayoutComponent.defaultProps = {
  parentFormService: () => {},
  hideExtraTechNumber: false,
  crewTimeTracking: false,
  visitDate: null,
  isEdit: false
};

export default PreferredTechniciansFormLayoutComponent;
