import React, { useCallback, useEffect, useState } from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from 'ckeditor5/packages/ckeditor5-build-decoupled-document/build/ckeditor';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import configForEnvironment from 'configs/aws-exports';
import ENV from 'configs/env';

import './styles.css';
import useStyles from './CKEditor.styles';

const CKEditorTemplate = ({ initialData, lockData, updateDataFn }) => {
  const [htmlStr, setHTMLStr] = useState(initialData);

  useEffect(() => {
    setHTMLStr(initialData);
  }, [initialData]);
  const classes = useStyles();

  /*
  TEMPORARY fix for issues arising from using CKEditor within a modal
  and the dynamic generation of the .ck-body-wrapper element outside of the modal.
  Functionality of inputs on pop-ups was impaired.
  TODO: fix issue in our custom instance of the editor
  */
  const moveElements = () => {
    const ckBodyElement = document.getElementsByClassName('ck-body-wrapper');
    const ckEditorElement = document.getElementsByClassName(
      classes.documentEditorEditableContainer
    );
    if (ckBodyElement.length && ckEditorElement.length) {
      ckEditorElement[0].appendChild(ckBodyElement[0]);
    }
  };

  // If 'Lock Default Scope and Pricing Information' turned on in quote settings, then restricted editing set here
  const setRestrictedEditing = editor => {
    // Table cells made non-editable if they have contenteditable attribute set in CKEditor.utils.js
    editor.model.schema.extend('tableCell', {
      allowAttributes: ['contenteditable', 'class']
    });

    editor.conversion.for('upcast').attributeToAttribute({
      model: {
        name: 'tableCell',
        key: 'contenteditable'
      },
      view: 'contenteditable'
    });

    editor.conversion.for('upcast').attributeToAttribute({
      model: {
        name: 'tableCell',
        key: 'class'
      },
      view: 'class'
    });

    editor.conversion.for('downcast').add(dispatcher => {
      dispatcher.on(`attribute:contenteditable:tableCell`, (evt, data, conversionApi) => {
        const viewElement = conversionApi.mapper.toViewElement(data.item);

        conversionApi.writer.setAttribute('contenteditable', data.attributeNewValue, viewElement);
      });
    });

    editor.conversion.for('downcast').add(dispatcher => {
      dispatcher.on(`attribute:class:tableCell`, (evt, data, conversionApi) => {
        const viewElement = conversionApi.mapper.toViewElement(data.item);

        conversionApi.writer.setAttribute('class', data.attributeNewValue, viewElement);
      });
    });

    // Span (total smart field) made non-editable if it has contenteditable attribute set in CKEditor.utils.js
    editor.model.schema.register('span', {
      allowWhere: '$block',
      allowContentOf: '$root'
    });

    editor.model.schema.addAttributeCheck(context => {
      if (context.endsWith('span')) {
        return true;
      }
    });

    editor.conversion.for('upcast').elementToElement({
      view: 'span',
      model: (viewElement, { writer: modelWriter }) => {
        return modelWriter.createElement('span', viewElement.getAttributes());
      }
    });

    editor.conversion.for('downcast').elementToElement({
      model: 'span',
      view: 'span'
    });

    editor.conversion.for('downcast').add(dispatcher => {
      dispatcher.on('attribute', (evt, data, conversionApi) => {
        if (data.item.name !== 'span') {
          return;
        }

        const viewWriter = conversionApi.writer;
        const viewSpan = conversionApi.mapper.toViewElement(data.item);

        if (data.attributeNewValue) {
          viewWriter.setAttribute(data.attributeKey, data.attributeNewValue, viewSpan);
        } else {
          viewWriter.removeAttribute(data.attributeKey, viewSpan);
        }
      });
    });
  };

  const handleOnReady = useCallback(
    editor => {
      moveElements();
      if (editor) {
        const toolbarContainer = document.querySelector('#toolbar-container');
        toolbarContainer.parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          toolbarContainer
        );
        if (lockData) {
          setRestrictedEditing(editor);
        }
        editor.setData(htmlStr);
      }
    },
    [htmlStr]
  );

  const insertSmartFieldCallback = (editor, data) => {
    editor.model.change(async writer => {
      // Displays unparsed smart fields (i.e. [[companyName]])
      const smartField = writer.createElement('span');
      writer.insertText(data, smartField);
      editor.model.insertContent(smartField, editor.model.document.selection);
    });
  };

  return (
    <>
      <div className={classes.textEditor}>
        <div className={classes.documentEditor}>
          <div className={classes.documentEditorToolbar} id="toolbar-container" />
          <div className={classes.documentEditorEditableContainer}>
            <div className={classes.documentEditorEditable} />
            <CKEditor
              config={{
                fontFamily: {
                  supportAllValues: true
                },
                pagination: {},
                smartFields: {
                  cbFn: insertSmartFieldCallback
                },
                licenseKey: configForEnvironment(ENV).ckeditorLicenseKey
              }}
              editor={DecoupledEditor}
              onChange={debounce((_, editor) => {
                // Prevent deletion of text within restricted elements
                editor.editing.view.document.on(
                  'delete',
                  (evt, data) => {
                    if (data.domTarget.className.includes('restricted')) {
                      evt.stop();
                    }
                  },
                  { priority: 'highest' }
                );

                // Prevent changes within restricted elements
                editor.editing.view.document.on(
                  'keydown',
                  (evt, data) => {
                    if (data.domTarget.className.includes('restricted')) {
                      evt.stop();
                    }
                  },
                  { priority: 'highest' }
                );

                if (updateDataFn) {
                  updateDataFn(editor.getData());
                }
              }, 500)}
              onFocus={(_, editor) => {
                console.log('Focus.', editor.getData());
              }}
              onReady={handleOnReady}
            />
          </div>
        </div>
      </div>
    </>
  );
};

CKEditorTemplate.propTypes = {
  /* Initial data string sent to editor */
  initialData: PropTypes.string,
  /* Boolean to determine whether certain smartfields should be non-editable */
  lockData: PropTypes.bool,
  /* Callback fn to return updated data after edits made in the editor */
  updateDataFn: PropTypes.func
};

CKEditorTemplate.defaultProps = {
  initialData: '',
  lockData: false,
  updateDataFn: () => {}
};

export default CKEditorTemplate;
