import { useMemo } from 'react';

export const useFormattedData = ({
  billingHourTypes = [],
  labourRateGroups = [],
  labourTypes = []
}) =>
  useMemo(
    () =>
      labourRateGroups
        .map(labourRateGroup => {
          const labourTypeBillingHourTypeProducts =
            labourRateGroup.labourTypeBillingHourTypeProducts.items || [];
          return labourTypes
            .filter(({ isArchived }) => !isArchived)
            .map(labourType => {
              const billingHourProductData = billingHourTypes.map(billingHourType => {
                const labourTypeBillingHourTypeProduct = labourTypeBillingHourTypeProducts.find(
                  ({ billingHourTypeId, labourTypeId }) =>
                    billingHourTypeId === billingHourType.id && labourTypeId === labourType.id
                );
                return {
                  [billingHourType.hourTypeAbbreviation]: labourTypeBillingHourTypeProduct?.product
                    ? {
                        label: labourTypeBillingHourTypeProduct?.product?.name,
                        value: labourTypeBillingHourTypeProduct?.productId
                      }
                    : null
                };
              });
              return {
                ...Object.assign({}, ...billingHourProductData),
                ...{
                  labourRateGroupId: labourRateGroup.id,
                  labourRateGroupName: labourRateGroup.name,
                  labourTypeId: labourType.id,
                  labourTypeName: labourType.name
                }
              };
            });
        })
        .flat(),
    [billingHourTypes, labourRateGroups, labourTypes]
  );
