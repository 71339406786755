import React from 'react';

import ToReview from './ToReview';

const Approved = ({
  selectedEmployee,
  payrollHourTypes,
  timesheetPeriods = [],
  payrollSetting,
  snackbarOn,
  setExportSelectedDate,
  unsubmittedEvents,
  isFetchingTimesheetPeriods,
  onUpdateDayCard,
  setIsExportDisabled,
  selectedDate,
  setSelectedDate,
  dayOrWeek,
  setDayOrWeek,
  weekSelectedPeriod,
  setWeekSelectedPeriod
}) => {
  return (
    <ToReview
      dayOrWeek={dayOrWeek}
      isFetchingTimesheetPeriods={isFetchingTimesheetPeriods}
      payrollHourTypes={payrollHourTypes}
      payrollSetting={payrollSetting}
      selectedDate={selectedDate}
      selectedEmployee={selectedEmployee}
      setDayOrWeek={setDayOrWeek}
      setExportSelectedDate={setExportSelectedDate}
      setIsExportDisabled={setIsExportDisabled}
      setSelectedDate={setSelectedDate}
      setWeekSelectedPeriod={setWeekSelectedPeriod}
      snackbarOn={snackbarOn}
      tab="approved"
      timesheetPeriods={timesheetPeriods}
      unsubmittedEvents={unsubmittedEvents}
      weekSelectedPeriod={weekSelectedPeriod}
      onUpdateDayCard={onUpdateDayCard}
    />
  );
};

export default Approved;
