import React from 'react';

import { Box } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const SyncStatusIcon = ({ status }) => {
  const statusIcons = {
    SyncFailed: <RemoveCircleIcon color="error" />,
    InSync: <CheckCircleIcon color="secondary" />,
    Syncing: <CachedIcon color="secondary" />
  };

  const icon = statusIcons[status];
  return (
    <Box display="flex" justifyContent="center">
      {icon || '-'}
    </Box>
  );
};

export default SyncStatusIcon;
