import React from 'react';

import AccessTime from '@material-ui/icons/AccessTime';
import Build from '@material-ui/icons/Build';
// import Done from '@material-ui/icons/Done';
import Calendar from '@material-ui/icons/CalendarToday';
import Close from '@material-ui/icons/Close';
import CloudDone from '@material-ui/icons/CloudDone';
import CloudOff from '@material-ui/icons/CloudOff';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import EventAvailable from '@material-ui/icons/EventAvailable';
import Left from '@material-ui/icons/KeyboardArrowLeft';
import Right from '@material-ui/icons/KeyboardArrowRight';
import Launch from '@material-ui/icons/Launch';
import LocalShipping from '@material-ui/icons/LocalShipping';
import LockOpen from '@material-ui/icons/LockOpen';
import Pause from '@material-ui/icons/Pause';
import Replay from '@material-ui/icons/Replay';
import ReportProblem from '@material-ui/icons/ReportProblem';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';

import Complete from './CompleteIcon';

const iconMap = (icon, size = 14) => {
  const iconArray = {
    onRoute: <LocalShipping style={{ fontSize: size }} />,
    workStarted: <Build style={{ fontSize: size }} />,
    onHold: <Pause style={{ fontSize: size }} />,
    scheduled: <EventAvailable style={{ fontSize: size }} />,
    schedule: <EventAvailable style={{ fontSize: size }} />,
    delayed: <AccessTime style={{ fontSize: size }} />,
    crew: <SupervisorAccount style={{ fontSize: size }} />,
    completed: <Complete style={{ fontSize: size }} viewBox="12 12 14 14" />,
    reschedule: <Replay style={{ fontSize: size }} />,
    continueNew: <Replay style={{ fontSize: size }} />,
    cancel: <Close style={{ fontSize: size }} />,
    launch: <Launch style={{ fontSize: 24 }} />,
    normalLaunch: <Launch style={{ fontSize: size }} />,
    online: <CloudDone style={{ fontSize: size }} />,
    offline: <CloudOff style={{ fontSize: size }} />,
    dispatchToday: <ReportProblem style={{ fontSize: 16 }} />,
    left: <Left style={{ fontSize: 24 }} />,
    right: <Right style={{ fontSize: 24 }} />,
    calendar: <Calendar style={{ fontSize: 24 }} />,
    edit: <Edit style={{ fontSize: size }} />,
    delete: <Delete style={{ fontSize: size }} />,
    reopen: <LockOpen style={{ fontSize: size }} />,
    none: <div />
  };
  return iconArray[icon];
};

export const displayTitle = title => {
  if (title) {
    return (
      <span
        style={{
          paddingLeft: '4px',
          paddingRight: '4px',
          lineHeight: '16px',
          verticalAlign: 'top'
        }}
      >
        {title}
      </span>
    );
  }
  return '';
};

export default iconMap;
