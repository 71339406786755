/* eslint-disable react/no-array-index-key,no-shadow,react/require-default-props */
import React, { useEffect, useRef, useState } from 'react';

import { useTheme } from '@material-ui/core';
import { noop } from 'lodash';
import { array, arrayOf, bool, func, number, object, shape, string } from 'prop-types';

import NonVisitCard from '@dispatch/components/NonVisitCard';
import VisitCard from '@dispatch/components/VisitCard';
import { isToday } from '@dispatch/Dispatch.utils';
import { DragScenarios } from '@dispatch/dnd';
import { TECH_PROP } from '@dispatch/queries';

import { CardTypes } from '../../../../DispatchBoard.constants';
import { useIsLaneHighlighted } from '../../../../DispatchBoard.hooks';
import { selectTechStatus } from '../../../../DispatchBoard.selectors';

import CreateEventMenu from '../../../CreateEventMenu';
import TechCell from '../../../TechCell';
import TimeMarker from '../../../TimeMarker';

import { DAILY_VIEW_HEADERS } from '../../DailyView.constants';
import { useBoardContext } from '../../DailyView.context';
import { useTimePosition } from '../../hocs/withTimePosition';

import DropPreview from './components/DropPreview';
import TimeCell from './components/TimeCell';
import { calculateHoverDataFromPosition, getCreatePreviewProps } from './SwimLane.helpers';
import { useDropPreviewUpdate, useShowDropPreview, useSwimLaneDrop } from './SwimLane.hooks';
import { useStyles } from './SwimLane.styles';
import { createLoadingVisits } from './SwimLane.utils';

const SwimLane = ({
  clearVisitRowHover,
  data,
  day,
  eventData,
  index,
  isHighlightedTech,
  style,
  visitTransitionTuple,
  updateNonVisitTuple,
  updateManDayTuple
}) => {
  const classes = useStyles();
  const { palette } = useTheme();

  const boardRef = useRef({});
  const { tech = {}, techLoading, techEvents = [], eventsLoading, board } = data[index] || {};
  boardRef.current = board;

  const [dropPreview, setDropPreview] = useState({});
  const [hoverPositionData, setHoverPositionData] = useState(calculateHoverDataFromPosition(0));
  const [createEventData, setCreateEventData] = useState(calculateHoverDataFromPosition(0));
  const [showTimeMarker, setShowTimeMarker] = useState(false);
  const [showCreateEventMenu, setShowCreateEventMenu] = useState(false);

  const loadingVisits = useRef(createLoadingVisits());
  const shouldUpdatePreview = useRef(false);
  const offsetRef = useRef(0);
  const itemRef = useRef({});

  const { time } = useTimePosition();
  const { isOverBoard } = useBoardContext();

  const [triggerVisitTransition, visitTransitionResponse] = visitTransitionTuple;
  const [triggerUpdateNonVisit] = updateNonVisitTuple;
  const [triggerUpdateManDay] = updateManDayTuple;

  const shouldShowTechStatus = isToday(day);
  const techStatus = shouldShowTechStatus ? selectTechStatus(tech?.id, techEvents) : null;
  const createPreview = getCreatePreviewProps({ techId: tech.id, eventData });
  const showCreatePreview = !!createPreview;

  const [collected, dropRef] = useSwimLaneDrop({
    tech,
    triggerVisitTransition,
    triggerUpdateNonVisit,
    triggerUpdateManDay,
    dropPreview,
    day,
    clearVisitRowHover
  });

  itemRef.current = collected?.item;

  const isLaneHighlighted = useIsLaneHighlighted({
    ...collected,
    techId: tech.id,
    isHighlightedTech
  });

  shouldUpdatePreview.current = isLaneHighlighted;

  const showDropPreview = useShowDropPreview({
    ...collected,
    transitionLoading: visitTransitionResponse.loading,
    dropPreview,
    isOverBoard
  });

  const handleHoverPositionUpdate = event => {
    if (boardRef.current && !showCreateEventMenu) {
      setShowTimeMarker(true);

      const newHoverPositionData = calculateHoverDataFromPosition(
        boardRef.current.scrollLeft + event.clientX
      );

      if (hoverPositionData.position !== newHoverPositionData.position) {
        setHoverPositionData(newHoverPositionData);
      }
    }
  };

  useDropPreviewUpdate({
    shouldUpdatePreview,
    boardRef,
    offsetRef,
    itemRef,
    setDropPreview,
    techId: tech.id
  });

  const prevScenario = useRef();
  useEffect(() => {
    if (
      collected.dragScenario === DragScenarios.NO_DRAG &&
      prevScenario.current !== DragScenarios.NO_DRAG
    ) {
      // this block = onDragEnd hook
      setDropPreview({ width: 0 });
    }
    prevScenario.current = collected.dragScenario;
  }, [collected.dragScenario]);

  const hideTimeMarker = () => {
    setShowTimeMarker(false);
  };

  const displayCreateEventMenu = () => {
    setShowTimeMarker(false);
    setShowCreateEventMenu(!showCreateEventMenu);
    setCreateEventData(hoverPositionData);
  };

  const handleCreateEventMenuClose = () => {
    setShowCreateEventMenu(false);
  };

  return (
    <div
      className={classes.swimLane}
      role="button"
      style={{
        backgroundColor:
          isLaneHighlighted || showTimeMarker ? palette.support.green.light : 'white',
        ...style
      }}
      tabIndex="0"
      onClick={displayCreateEventMenu}
      onKeyPress={noop}
      onMouseLeave={hideTimeMarker}
      onMouseMove={handleHoverPositionUpdate}
    >
      {showTimeMarker && !collected?.isDragging && (
        <TimeMarker position={hoverPositionData.position} time={hoverPositionData.time} />
      )}
      <CreateEventMenu
        minutes={createEventData.minutes}
        open={showCreateEventMenu}
        position={createEventData.position}
        techId={tech.id}
        techName={tech.name}
        onClose={handleCreateEventMenuClose}
      />
      <TechCell
        showStatus={shouldShowTechStatus}
        tech={{
          ...tech,
          status: techStatus
        }}
        techLoading={techLoading}
      />
      {DAILY_VIEW_HEADERS.map((hour, i) => {
        return <TimeCell currentTime={time} hour={i} key={hour} />;
      })}
      <div className={classes.timeContainer} ref={dropRef}>
        {eventsLoading || techLoading
          ? loadingVisits.current.map((visit, i) => {
              return <VisitCard key={i} loading visit={visit} />;
            })
          : techEvents.map(event => {
              switch (event.__typename) {
                case CardTypes.Visit: {
                  return <VisitCard key={event.id} srcTech={tech.id} visit={event} />;
                }
                case CardTypes.ManDay:
                case CardTypes.NonVisitEvent: {
                  return <NonVisitCard event={event} key={event.id} srcTech={tech.id} />;
                }
                default: {
                  return null;
                }
              }
            })}
        {showDropPreview && <DropPreview {...dropPreview} />}
        {showCreatePreview && <DropPreview {...createPreview} />}
      </div>
    </div>
  );
};

SwimLane.propTypes = {
  clearVisitRowHover: func.isRequired,
  day: string,
  eventData: object,
  visitTransitionTuple: array.isRequired,
  updateNonVisitTuple: array.isRequired,
  updateManDayTuple: array.isRequired,
  isHighlightedTech: bool,
  index: number.isRequired,
  style: object.isRequired,
  data: arrayOf(
    shape({
      tech: TECH_PROP,
      techLoading: bool,
      visitsLoading: bool,
      techVisits: arrayOf(object),
      techNonVisitEvents: arrayOf(object)
    })
  ).isRequired
};

export default SwimLane;
