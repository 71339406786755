import gql from 'graphql-tag';

import { jobCostingFragment } from 'scenes/Jobs/JobDetail/queries/queryFragment';

import { quoteInvoicesFragment } from 'scenes/Quotes/queries/queryFragments';

import taskFragment from './taskFragment';

const getJobDetailsInfoByJobNumber = gql`
  query($jobNumber: String!) {
    getJobByJobNumber(jobNumber: $jobNumber) {
      id
      billingType
      closeoutReport
      version
      status
      procurementStatus
      manualQuoteStatus
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      tenantId
      hierarchy
      entityType
      jobNumber
      certifiedPayroll
      customIdentifier
      customerProvidedPONumber
      customerProvidedWONumber
      jobTypeId
      jobTypeName
      jobTypeInternal
      priority
      customerId
      customerName
      billingCustomerId
      billingCustomerName
      billingStatus
      detailedJobCostingEnabled
      priceBookId
      purchaseOrders { 
        items { 
          status
        } 
      }
      serviceAgreementId
      totalBudgetedHours
      sageJobId
      accountingJobNumber
      visits {
        items {
          id
          status
          inventoryParts {
            items {
              id
            }
          }
          labourRateLineItems {
            items {
              id
            }
          }
          timesheetEntriesView {
            items {
              actualTotalDurationOverride
              actualTotalDuration
            }
          }
        }
      }
      inventoryParts {
        items {
          id
        }
      }
      billLines {
        items {
          id
        }
      }
      invoices { 
        items {
          totalAmount
          status
        }
      }
      reviewStatus
      derivedJobCosting {
        ${jobCostingFragment}
      }
      serviceChannelDescription
      serviceChannelWorkOrderId
      serviceChannelWorkOrder {
        workOrderId
        workOrderNumber
      }
      sageJob(entityConnection: "SageJob") {
        name
        code
        parentCode
        isActive
        id
      }
      accountManagerId
      accountManager {
        id
        name
        firstName
        lastName
      }
      jobTasks(entityConnection: "JobTask") {
        items {
          id
          task {
            ...taskFields
          }
        }
      }
      parentEntity {
        ... on CustomerProperty {
          companyName
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          propertyAssets(entityConnection: "PropertyAsset") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              assetName
              make
              modelNumber
              serialNo
              installDate
              version
              isActive
              forms {
                id
                name
              }
              notes(entityConnection: "Note") {
                items {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  note
                  version
                  createdBy
                  lastUpdatedDate
                  lastUpdatedDateTime
                  lastUpdatedBy
                  hideFromTechniciansOnMobile
                }
              }
            }
          }
        }
      }
      dueDate
      serviceAgreement(entityConnection: "ServiceAgreement") {
        id
        companyId
        startDate
        endDate
        status
        paymentTerms
        termPrice
        agreementName
        agreementNumber
        attachments(entityConnection: "Attachment") {
          items {
            id
            type
            fileName
            fileUrl
            customFileName
            version
          }
        }
        preferredTechnicians {
          departmentId
          crewId
          primaryTechnicianId
          additionalTechnicianIds
        }
      }
      maintenanceTemplate(entityConnection: "MaintenanceTemplate") {
        id
        serviceAgreementId
        serviceDescription
        preferredTechnicians {
          departmentId
          crewId
          primaryTechnicianId
          additionalTechnicianIds
        }
      }
      priceBookId
      priceBook(entityConnection: "PriceBook") {
        id
        name
      }
      customer(entityConnection: "Customer") {
        id
        isActive
        status
        customerName
        customerReps(entityConnection: "CustomerRep", filter: { status: { eq: "active" } }) {
          items {
            id
            name
          }
        }
      }
      billingCustomer(entityConnection: "BillingCustomer") {
        id
        sortKey
        customerName
        isActive
        status
        companyAddresses(entityConnection: "CompanyAddress") {
          items {
            id
            addressType
            addressLine1
            addressLine2
            billTo
            city
            state
            zipcode
            longitude
            latitude
          }
        }
        customerReps(entityConnection: "CustomerRep", filter: { status: { eq: "active" } }) {
          items {
            id
            name
          }
        }
      }
      customerSortKey
      customerRepName
      customerRep(entityConnection: "CustomerRep") {
        id
        name
        bestContact
        email
        cellPhone
        landlinePhone
      }
      customerProperty(entityConnection: "CustomerProperty") {
        id
        isActive
        status
        customerReps(entityConnection: "CustomerRep") {
          items {
            id
            tenantId
            tenantCompanyId
            partitionKey
            sortKey
            invertedSortKey
            hierarchy
            entityType
            mappedEntity {
              ... on CustomerRep {
                id
                tenantId
                tenantCompanyId
                partitionKey
                sortKey
                hierarchy
                entityType
                name
                firstName
                lastName
                email
                cellPhone
                landlinePhone
                isSmsOptOut
                bestContact
                contactRole
              }
            }
          }
        }
        customerPropertyType {
          tagName
        }
        customerPropertyTypeValue
        companyAddresses(entityConnection: "CompanyAddress") {
          items {
            id
            billTo
            addressType
            addressLine1
            addressLine2
            city
            county
            state
            zipcode
            country
            latitude
            longitude
          }
        }
        notes(entityConnection: "Note") {
          items {
            id
            partitionKey
            sortKey
            version
            subject
            note
            createdDate
            createdBy
            lastUpdatedDate
            lastUpdatedDateTime
            lastUpdatedBy
            hideFromTechniciansOnMobile
          }
        }
      }
      customerPropertyId
      customerPropertyName
      customerPropertyTypeName
      customerPropertySortKey
      amountNotToExceed
      amountQuoted
      costAmount
      quoteId
      quoteJobs(entityConnection: "QuoteJob") {
        items {
          quote {
            id
            version
            name
            quoteNumber
            customIdentifier
            ownerValue
            totalEstimatedCost
            totalAmountQuoted
            totalAmountQuotedOverride
            totalBudgetedHours
            createdDate
            billingStatus
            status,
            lastUpdatedDate
            opportunity: parentEntity {
              ... on Opportunity {
                property: parentEntity {
                  ... on CustomerProperty {
                    id
                  }
                }
              }
            }
            ${quoteInvoicesFragment}
            quoteLineTasks {
              items {
                quoteLineLabors {
                  items {
                    labourType {
                      name
                      entityType
                    }
                    quantity
                    quoteLineLaborPayrollHours {
                      items { 
                        hours
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      quote(entityConnection: "Quote") {
        id
        name
        quoteNumber
        customIdentifier
        ownerValue
        totalAmountQuoted
        totalAmountQuotedOverride
        createdDate
      }
      issueDescription
      bestContact
      parentId
      parentSortKey
      createdDateTime
      createdBy
      syncStatus
      ownerId
      owner(entityConnection: "owner") {
        id
        name
        firstName
        lastName
      }
      soldById
      soldBy {
        id
        name
        firstName
        lastName
      }
      departments(entityConnection: "JobDepartment") {
        items {
          id
          mappedEntityId
          mappedEntityType
          mappedEntityValue
          systemEntityId
          systemEntityType
          hierarchy
          sortKey
          invertedSortKey
          partitionKey
          mappedEntity {
            ... on Department {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              tagName
            }
          }
        }
      }
      notes(entityConnection: "Note") {
        items {
          id
          version
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          subject
          note
          createdDate
          createdBy
          lastUpdatedDate
          lastUpdatedDateTime
          lastUpdatedBy
          hideFromTechniciansOnMobile
          sortOrder
          displayLastUpdatedBy
          displayLastUpdatedDateTime
        }
      }
      jobJobTags(entityConnection: "JobJobTag", limit: 50) {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          jobTag(entityConnection: "JobTag") {
            tagType
            tagName
            sortOrder
            id
            tenantId
            tenantCompanyId
            partitionKey
            sortKey
            hierarchy
            entityType
          }
        }
      }
      authorizedBy(entityConnection: "authorizedBy") {
        ... on CustomerRep {
          id
          firstName
          lastName
          version
          isActive
        }
      }
      authorizedById
      labourRateGroupId
      labourRateGroup {
        id
        name
      }
      labourRateModifierJobs {
        items {
          id
          jobId
          labourRateModifierId
          labourRateModifier {
            id
            name
          }
        }
      }
    }
  }
  ${taskFragment}
`;

export default getJobDetailsInfoByJobNumber;
