import React, { useState } from 'react';

import { Typography } from '@material-ui/core';

import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';

import { ConfirmModal, ResponsiveTable } from 'components';
import Spinner from 'components/Spinners/CircularIndeterminate';
import { skillsListMeta } from 'meta/Settings/Company/skillsModal';

import { CompanyService } from 'services/core';
import { logErrorWithCallback } from 'utils';

import ItemActions from '../../Project/ItemActions';

export default function SkillsEdit({ form, snackbarOn, updateContext }) {
  let { skills } = form.values;
  // global key to keep track of skills in the responsive table during local add and delete operations
  let key = 0;
  if (!skills) {
    skills = [];
  }
  const skillsWithKey = skills.map(skill => {
    const tempSkill = skill;
    tempSkill.key = key;
    key += 1;
    return tempSkill;
  });
  const [confirmModalSetting, setConfirmModalSetting] = useState({});

  const handleItemDelete = async record => {
    const companyService = new CompanyService();
    const updatedList = [...skills];
    const indexOfItem = skills.findIndex(i => i.key === record.key);
    if (record?.departments) {
      const departmentsList = record.departments.items || [];
      if (departmentsList.length) {
        let errorMsg = 'This skill is assigned to the current departments,';
        departmentsList.forEach(department => {
          const { tagName } = department.mappedEntity;
          errorMsg = `${errorMsg}\n ${tagName}`;
        });
        errorMsg += '\n Please remove this skill from associated departments before deleting.';
        snackbarOn('error', errorMsg);
      } else {
        const { data, loading, error } = await companyService.deleteCompanySkill(
          record.tenantId,
          record.sortKey
        );
        if (loading) return <Spinner />;
        if (data) {
          snackbarOn('success', `Successfully deleted skill: ${record.tagName}`);
          updatedList.splice(indexOfItem, 1);
          form.setFieldValue('skills', updatedList);
          await updateContext();
        }
        if (error) {
          logErrorWithCallback(error, snackbarOn, 'Unable to delete skill, please try again later');
        }
      }
    } else {
      // delete skill locally only since it is a new add
      updatedList.splice(indexOfItem, 1);
      form.setFieldValue('skills', updatedList);
    }
  };

  const handleRowActions = (actionName, actionData) => {
    switch (actionName) {
      case 'delete':
        setConfirmModalSetting({
          confirmMessage: `this skill: ${actionData.tagName} `,
          open: true,
          cancelAction: () => setConfirmModalSetting({ open: false, confirmMessage: '' }),
          action: async () => {
            handleItemDelete(actionData);
            setConfirmModalSetting({
              open: false,
              confirmMessage: '',
              cancelAction: '',
              action: ''
            });
          }
        });
        break;
      default:
        // No-op
        break;
    }
  };

  const SkillListItem = ({ data }) => {
    const { tagName } = data.record;
    const [name, setName] = useState(tagName);
    const [visible, setVisible] = useState(false);

    const handleChange = event => {
      setVisible(true);
      const { value } = event.target;
      setName(value);
    };

    const handleSave = index => {
      const updatedList = [...skillsWithKey];
      const item = updatedList.find(i => i.key === index);
      item.tagName = name;
      form.setFieldValue('skills', updatedList);
      setVisible(false);
    };

    const handleCancel = () => {
      form.setFieldValue('skills', skills);
      setVisible(false);
    };

    return (
      <ListItem>
        <TextField placeholder="Add skill" value={name} onChange={handleChange} />
        <ItemActions
          handleCancel={handleCancel}
          handleSave={handleSave}
          index={data.record.key}
          isVisible={visible}
        />
      </ListItem>
    );
  };

  return (
    <div>
      <Typography style={{ paddingBottom: 3 }} variant="caption">
        The Skills listed here can be assigned to departments, employees and used to describe jobs.
      </Typography>
      <ResponsiveTable
        addRow={{
          label: 'Add Skills',
          handleAdd: () => {
            form.setFieldValue('skills', [
              {
                id: '',
                key,
                mappedEntity: {
                  tagName: ''
                },
                partitionKey: ''
              },
              ...skills
            ]);
            key += 1;
          }
        }}
        customCellComponents={{
          TextInput: data => <SkillListItem data={data} key={key} />
        }}
        data={skillsWithKey}
        noDataMsg="No skills added"
        rowActionButtons={{
          delete: {
            label: 'Delete',
            icon: 'Delete'
          }
        }}
        rowActions={handleRowActions}
        rowMetadata={skillsListMeta}
        selfBackgroundColor="none"
      />
      <Divider fullWidth />
      <ConfirmModal
        cancel={confirmModalSetting.cancelAction}
        confirm={confirmModalSetting.action}
        message={confirmModalSetting.confirmMessage}
        open={confirmModalSetting.open}
      />
    </div>
  );
}
