import React from 'react';

import { calculateMarginFromMarkup } from '@BuildHero/math';
import { TV, Typography } from '@BuildHero/sergeant';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

import { bundleIndex } from 'constants/algoliaIndex';

import { useGetPricebookById } from 'customHooks/useGetPricebookById';
import { roundFloat } from 'utils';
import { convertForMathLib } from 'utils/mathLibrary';
import { calculateUnitPriceWithMarkupValue, determineMarkupValue } from 'utils/pricebooks';

import { useLazyFetchPricebookEntry } from '../../Tasks/components/useLazyFetchPricebookEntry';
import AlgoliaSearch from '../AlgoliaSearch';

export default function ProductWithPricingSearch({ field, form, ...props }) {
  const {
    priceBookId,
    costCodeOptions,
    costTypeOptions,
    revenueTypeOptions,
    ...givenProps
  } = props.props ? props.props : props.options;
  const { palette } = useTheme();
  const fetchPricebookEntry = useLazyFetchPricebookEntry();
  const getPricebookById = useGetPricebookById();

  const calculateMarkupValue = value => {
    return roundFloat(value);
  };

  const calculateMarginValue = value => {
    return convertForMathLib(calculateMarginFromMarkup, value);
  };

  const algoliaProps = {
    indexName: bundleIndex,
    formatHitLabel: hit => hit.name,
    filters: `entityType:Product`,
    onChange: async value => {
      const markupValue = await determineMarkupValue({
        fetchPricebookEntry,
        getPricebookById,
        pricebookId: priceBookId,
        productSortKey: value.sortKey,
        unitCost: value.unitCost
      });
      const costCode = value.costCodeId
        ? costCodeOptions.find(o => o.value === value.costCodeId)
        : null;
      const jobCostType = value.jobCostTypeId
        ? costTypeOptions.find(o => o.value === value.jobCostTypeId)
        : null;
      const revenueType = value.revenueTypeId
        ? revenueTypeOptions.find(o => o.value === value.revenueTypeId)
        : null;
      const unitPrice = calculateUnitPriceWithMarkupValue({
        unitCost: value.unitCost,
        markupValue
      });

      form.setValues(
        prev => ({
          ...prev,
          itemName: value.name,
          name: value.name,
          productId: value.id,
          productSortKey: value.sortKey,
          description: value.description || '',
          // rounding here as short term fix/safe guard until price book has new requirements for rounding
          unitPrice,
          unitCost: roundFloat(value.unitCost),
          accountingRefIdOfEntity: value.productAccountingRefId,
          markupValue: calculateMarkupValue(markupValue) ?? null,
          marginValue: calculateMarginValue(markupValue) ?? null,
          unitOfMeasure: value.unitOfMeasure || null,
          quantity: 1,
          amount: unitPrice,
          taxable: !!value.taxable,
          costCode,
          jobCostType,
          revenueType,
          product: {
            id: value.id,
            code: value.code,
            unitOfMeasure: {
              name: value.unitOfMeasure
            }
          }
        }),
        true
      );
      // act like blur event -> can't use actul blur b/c mousedown on popper
      // will run the blur event before the selection
      form.setFieldTouched(field.name, true, false);
    },
    onClickAway: () => form.setFieldTouched(field.name, true),
    handleRenderOption: ({ label, value: { code, description } }) => (
      <div>
        <Typography variant={TV.BASE}>{label}</Typography>
        <Typography
          color={palette.text.tertiary}
          style={{ display: 'flex', flexDirection: 'row' }}
          variant={TV.S2}
        >
          <Typography color={palette.text.primary} variant={TV.S2}>
            {code}
          </Typography>
          {code && description && ' - '}
          {description}
        </Typography>
      </div>
    ),
    ...givenProps,
    name: field.name,
    value: field.value
  };

  return <AlgoliaSearch {...algoliaProps} />;
}

ProductWithPricingSearch.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  // any input props
  props: PropTypes.shape({
    priceBookId: PropTypes.string.isRequired,
    costCodeOptions: PropTypes.array.isRequired,
    costTypeOptions: PropTypes.array.isRequired,
    revenueTypeOptions: PropTypes.array.isRequired
  }).isRequired
};
