const PageTitles = {
  settings: {
    en: 'Settings',
    es: 'Settings'
  },
  companySettings: {
    en: 'Company Settings',
    es: 'Company Settings'
  },
  people: {
    en: 'Personnel',
    es: 'Personal'
  },
  customFields: {
    en: 'Custom Fields',
    es: 'Custom Fields'
  },
  forms: {
    en: 'Forms',
    es: 'Formularios'
  },
  invoiceSettings: {
    en: 'Invoice Settings',
    es: 'Configuración de factura'
  },
  accountingSettings: {
    en: 'Accounting Settings',
    es: 'Configuraciones Contables'
  },
  pricebooks: {
    en: 'Pricebooks',
    es: 'Libros De Precios'
  },
  inventorySettings: {
    en: 'Item List',
    es: 'Lista de articulos'
  },
  vendors: {
    en: 'Vendors',
    es: 'Vendedores'
  },
  mobileSettings: {
    en: 'Mobile Settings',
    es: 'Mobile Settings'
  },
  logs: {
    en: 'Activity',
    es: 'Activity'
  },
  dashboards: {
    en: 'Dashboards',
    es: 'Tablero'
  },
  metabase: {
    en: 'Dashboard v1',
    es: 'Dashboard v1'
  },
  sigma: {
    en: 'Dashboard v2',
    es: 'Dashboard v2'
  },
  dispatch: {
    en: 'Dispatch',
    es: 'Dispatch'
  },
  dispatchBoard: {
    en: 'Dispatch Board',
    es: 'Dispatch Board'
  },
  dispatchSettings: {
    en: 'Dispatch Settings',
    es: 'Configuración del despacho'
  },
  jobs: {
    en: 'Jobs',
    es: 'Trabajos'
  },
  visits: {
    en: 'Visits',
    es: 'Visitas'
  },
  technicianReport: {
    en: 'Technician Reports',
    es: 'Informes técnicos'
  },
  reviewReport: {
    en: 'Review Reports',
    es: 'Revisar informes'
  },
  sales: {
    en: 'Sales',
    es: 'Ventas'
  },
  recommendations: {
    en: 'Recommendations',
    es: 'recomendaciones'
  },
  quotes: {
    en: 'Quotes',
    es: 'Citas'
  },
  quoteDetail: {
    en: 'Quote Detail',
    es: 'Citas'
  },
  wipReports: {
    en: 'WIP Reports',
    es: 'Informes WIP'
  },
  projects: {
    en: 'Project Management',
    es: 'Gestión de proyecto'
  },
  procurement: {
    en: 'Procurement',
    es: 'Obtención'
  },
  procurementPOs: {
    en: 'Purchase Orders',
    es: 'Ordenes de compra'
  },
  procurementRBs: {
    en: 'Receipts & Bills',
    es: 'Recibos y facturas'
  },
  inventory: {
    en: 'Inventory',
    es: 'Obtención'
  },
  followUps: {
    en: 'Follow Ups',
    es: 'Seguimiento'
  },
  serviceAgreements: {
    en: 'Service Agreements',
    es: 'acuerdos de servicio'
  },
  invoices: {
    en: 'Invoices',
    es: 'Facturas'
  },
  recurringMaintenanceBilling: {
    en: 'Recurring Maintenance Billing',
    es: 'Facturación de mantenimiento recurrente'
  },
  payments: {
    en: 'Payments',
    es: 'Payments'
  },
  adjustments: {
    en: 'Adjustments',
    es: 'Ajustes'
  },
  timesheets: {
    en: 'Time Tracking',
    es: 'seguimiento del Tiempo'
  },
  customer: {
    en: 'Customers',
    es: 'Cliente'
  },
  properties: {
    en: 'Properties',
    es: 'Propiedades'
  },
  serviceChannel: {
    en: 'Service Channel',
    es: 'Service Channel'
  },
  maintenance: {
    en: 'Maintenance',
    es: 'Mantenimiento'
  },
  laborSettings: {
    en: 'Labor Settings',
    es: 'Configuración laboral'
  },
  integrations: {
    en: 'Integrations',
    es: 'Integraciones'
  },
  projectSetting: {
    en: 'Project Settings',
    es: 'Configuración del proyecto'
  },
  purchaseOrders: {
    en: 'Purchase Orders',
    es: 'Ordenes de Compra'
  },
  quoteSettings: {
    en: 'Quote Settings',
    es: 'Configuración de cotización'
  },
  projectsTab: {
    en: 'Projects',
    es: 'Proyectos'
  },
  checklistLibrary: {
    en: 'Checklist Library',
    es: 'Biblioteca de listas de verificación'
  }
};

const PageMap = {
  settings: {
    title: PageTitles.settings,
    link: ''
  },
  company: {
    title: PageTitles.companySettings,
    parent: 'settings',
    link: 'settings/company',
    icon: 'settings'
  },
  people: {
    title: PageTitles.people,
    parent: 'settings',
    link: 'settings/people',
    icon: 'personnelIcon'
  },
  customFields: {
    title: PageTitles.customFields,
    parent: 'settings',
    link: 'settings/customFields',
    icon: 'settings'
  },
  forms: {
    title: PageTitles.forms,
    parent: 'settings',
    link: 'settings/forms',
    icon: 'settings'
  },
  invoiceSettings: {
    title: PageTitles.invoiceSettings,
    parent: 'settings',
    link: 'settings/invoice',
    icon: 'settings'
  },
  accountingSettings: {
    title: PageTitles.accountingSettings,
    parent: 'settings',
    link: 'settings/accounting',
    icon: 'settings'
  },
  pricebooks: {
    title: PageTitles.pricebooks,
    parent: 'settings',
    link: 'settings/pricebooks',
    icon: 'settings'
  },
  pricebook: {
    parent: 'settings',
    icon: 'settings'
  },
  inventorySettings: {
    title: PageTitles.inventorySettings,
    parent: 'settings',
    link: 'settings/inventory',
    icon: 'settings'
  },
  vendors: {
    title: PageTitles.vendors,
    parent: 'settings',
    link: 'settings/vendors',
    icon: 'settings'
  },
  mobileSettings: {
    title: PageTitles.mobileSettings,
    parent: 'settings',
    link: 'settings/workflows',
    icon: 'settings'
  },
  logs: {
    title: PageTitles.logs,
    parent: 'settings',
    link: 'settings/logs',
    icon: 'settings'
  },
  dashboards: {
    title: PageTitles.dashboards,
    link: 'dashboards',
    icon: 'dashboardIcon'
  },
  metabase: {
    title: PageTitles.metabase,
    link: 'reports',
    icon: 'dispatch'
  },
  sigma: {
    title: PageTitles.sigma,
    link: 'reportsV2',
    icon: 'dispatch'
  },
  dispatch: {
    title: PageTitles.dispatch,
    link: 'dispatch',
    icon: 'dispatchV2'
  },
  dispatchBoard: {
    title: PageTitles.dispatchBoard,
    link: 'dispatch',
    icon: 'dispatchV2'
  },
  dispatchSettings: {
    title: PageTitles.dispatchSettings,
    link: 'settings/dispatch'
  },
  visits: {
    title: PageTitles.visits,
    link: 'visit/list',
    icon: 'jobsIcon'
  },
  operations: {
    title: {
      en: 'Operations'
    },
    link: ''
  },
  jobs: {
    title: PageTitles.jobs,
    parent: 'operations',
    link: 'job/list',
    icon: 'jobsIcon'
  },
  purchaseOrders: {
    title: PageTitles.purchaseOrders,
    parent: 'operations',
    link: 'purchaseOrder/list',
    icon: 'procurementIcon'
  },
  // No title because title is dynamically generated based on job details
  jobDetail: {
    parent: 'operations',
    icon: 'jobsIcon'
  },
  jobCloseOut: {
    title: PageTitles.jobs,
    parent: 'operations',
    link: 'job/list',
    icon: 'reportsIcon'
  },
  technicianReport: {
    title: PageTitles.technicianReport,
    parent: 'operations',
    link: 'technicianreport/list',
    icon: 'techReportsIcon'
  },
  reviewReport: {
    title: PageTitles.reviewReport,
    parent: 'operations',
    link: 'reviewreport/list',
    icon: 'reportsIcon'
  },
  sales: {
    title: PageTitles.sales,
    parent: 'operations',
    link: '',
    icon: 'quotesIcon',
    titleOnly: true
  },
  recommendations: {
    title: PageTitles.recommendations,
    parent: 'operations',
    link: 'recommendations/list',
    icon: 'quotesIcon'
  },
  quotes: {
    title: PageTitles.quotes,
    parent: 'operations',
    link: 'quote/list',
    icon: 'quotesIcon'
  },
  quoteDetail: {
    title: PageTitles.quoteDetail,
    parent: 'operations',
    icon: 'quotesIcon'
  },
  inventory: {
    title: PageTitles.inventory,
    parent: 'operations',
    icon: 'inventoryIcon',
    link: 'inventory/list'
  },
  inventoryWarehouse: {
    title: PageTitles.inventory,
    parent: 'operations',
    icon: 'inventoryIcon',
    link: 'inventoryWarehouse/list'
  },
  followUps: {
    title: PageTitles.followUps,
    parent: 'operations',
    link: 'followup',
    icon: 'followUpsIcon'
  },
  serviceAgreements: {
    title: PageTitles.serviceAgreements,
    parent: 'operations',
    link: 'serviceAgreement/list',
    icon: 'serviceAgreementIcon'
  },
  accounting: {
    title: {
      en: 'Accounting'
    },
    link: ''
  },
  projectManagement: {
    title: PageTitles.projects,
    parent: 'projectsTab',
    link: 'project',
    icon: 'projectIcon'
  },
  wipReports: {
    title: PageTitles.wipReports,
    parent: 'projectsTab',
    link: 'wipReports/list',
    icon: 'wipReportIcon'
  },
  projectsTab: {
    title: PageTitles.projectsTab,
    link: ''
  },
  projects: {
    title: PageTitles.projects,
    parent: 'operations',
    link: 'project',
    icon: 'projectIcon'
  },
  procurement: {
    title: PageTitles.procurementPOs,
    parent: 'operations',
    link: '',
    icon: 'newProcurementIcon',
    titleOnly: true
  },
  procurementReceiptBills: {
    title: PageTitles.procurementRBs,
    parent: 'operations',
    link: '',
    icon: 'newProcurementIcon',
    titleOnly: true
  },
  procurementPOs: {
    title: PageTitles.procurementPOs,
    parent: 'operations',
    link: 'procurement/purchaseorders',
    icon: ''
  },
  procurementRBs: {
    title: PageTitles.procurementRBs,
    parent: 'operations',
    link: 'procurement/receipts-bills',
    icon: ''
  },
  // No title because title is dynamically generated based on job details
  projectDetail: {
    parent: 'operations',
    icon: 'projectIcon'
  },
  invoices: {
    title: PageTitles.invoices,
    parent: 'accounting',
    link: 'invoice/list',
    icon: 'invoicesIcon'
  },

  recurringMaintenanceBilling: {
    title: PageTitles.recurringMaintenanceBilling,
    parent: 'accounting',
    link: 'recurringMaintenanceBilling/list',
    icon: 'invoicesIcon'
  },

  payments: {
    title: PageTitles.payments,
    parent: 'accounting',
    link: 'payments/list',
    icon: 'paymentIcon'
  },
  adjustments: {
    title: PageTitles.adjustments,
    link: 'adjustment/list',
    icon: 'adjustments'
  },
  timesheets: {
    title: PageTitles.timesheets,
    parent: 'accounting',
    link: 'timesheets',
    icon: 'timesheetsIcon'
  },
  directory: {
    title: {
      en: 'Directory'
    },
    link: ''
  },
  customers: {
    title: PageTitles.customer,
    parent: 'directory',
    link: 'customer/list',
    icon: 'customerIcon'
  },
  properties: {
    title: PageTitles.properties,
    parent: 'directory',
    link: 'property/list',
    icon: 'propertyIcon'
  },
  serviceChannel: {
    title: PageTitles.serviceChannel,
    parent: 'directory',
    link: 'serviceChannel/list',
    icon: 'serviceChannelIcon'
  },
  checklistLibrary: {
    title: PageTitles.checklistLibrary,
    parent: 'directory',
    link: 'checklistLibrary',
    icon: 'checklistLibraryIcon'
  },
  maintenance: {
    title: PageTitles.maintenance,
    parent: 'operations',
    link: 'maintenance/list',
    icon: 'maintenanceIcon'
  },
  payrollSetting: {
    title: PageTitles.laborSettings,
    parent: 'settings',
    link: 'settings/payroll',
    icon: 'settings'
  },
  integrations: {
    title: PageTitles.integrations,
    parent: 'settings',
    link: 'settings/integrations',
    icon: 'settings'
  },
  serviceAgreementSettings: {
    title: PageTitles.serviceAgreements,
    parent: 'settings',
    link: 'settings/serviceAgreement',
    icon: 'serviceAgreementIcon'
  },
  projectSetting: {
    title: PageTitles.projectSetting,
    parent: 'settings',
    link: 'settings/project',
    icon: 'settings'
  },
  quoteSettings: {
    title: PageTitles.quoteSettings,
    parent: 'settings',
    link: 'settings/quote',
    icon: 'settings'
  }
};

export default PageMap;
