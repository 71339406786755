import React, { useMemo } from 'react';

import { FieldType, formatValue, TW, Typography } from '@BuildHero/sergeant';
import { getGridStringOperators } from '@mui/x-data-grid-pro';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { RelevantVisitStatuses } from 'scenes/JobCloseout/constants';
import { TechReport } from 'scenes/Jobs/DetailView/JobTabs/InvoiceAndReport';
import { featureFlagFilter } from 'scenes/Jobs/utils';

import {
  JOB_CLOSEOUT_STATUS,
  JobStatus,
  JobTypes,
  MaintenanceStatus,
  MultiSelectTypes,
  StatusValToLabelMapping,
  VISIT_REVIEW_STATUS
} from 'utils/AppConstants';

import { EnumType } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import StatusChip from '../StatusChip';
import { column, ColumnType, enumFilterOperators, valueGetters } from '../XGrid/columnTypes';
import EnumFilterInput from '../XGrid/FilterInputs/EnumFilterInput';

const reviewStatusOptions = [
  { label: JOB_CLOSEOUT_STATUS.REVIEWED, value: VISIT_REVIEW_STATUS.REVIEWED },
  { label: JOB_CLOSEOUT_STATUS.REVIEW_NEEDED, value: VISIT_REVIEW_STATUS.UNREVIEWED }
];

const getReviewStatusColDef = selectOptions => {
  const filterOperator = getGridStringOperators().find(o => o.value === 'equals');
  filterOperator.InputComponent = EnumFilterInput;
  filterOperator.InputComponentProps = {
    selectOptions,
    customApplyValue: (applyFn, item, value) =>
      applyFn({ ...item, value: selectOptions.find(i => i.label === value)?.value })
  };
  return { ...column[ColumnType.ENUM], filterOperators: [filterOperator] };
};

const getVisitColumns = ({ onVisitClick }) => [
  {
    field: 'jobCustomIdentifier',
    headerName: 'Job',
    width: 100,
    valueGetter: valueGetters.jobOrMaintenanceLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'visitNumber',
    headerName: 'Visit',
    width: 100,
    type: 'string',
    renderCell: ({ id, value, colDef, api, getValue }) => {
      const isJobCloseoutReport = Boolean(getValue(id, 'jobCloseoutReport'));
      const isLinkableVisit = RelevantVisitStatuses.includes(getValue(id, 'status'));

      if (onVisitClick) {
        return (
          <Typography
            style={{ cursor: 'pointer' }}
            underlined
            weight={TW.BOLD}
            onClick={() => onVisitClick({ id })}
          >
            Visit {value}
          </Typography>
        );
      }

      if (!isJobCloseoutReport || !isLinkableVisit) return `Visit ${value}`;

      const linkValue = {
        label: `Visit ${value}`,
        to: `/jobreport/${getValue(id, 'jobId')}/visit/${getValue(id, 'id')}`
      };

      return formatValue[FieldType.LINK](linkValue, {
        containerProps: {
          style: { flexWrap: 'unset', gap: '8px' }
        },
        linkProps: { testingid: `record-${colDef.field}-${api.getRowIndex()}` }
      });
    }
  },
  {
    field: 'departmentName',
    headerName: 'Department',
    width: 150,
    enumType: MultiSelectTypes.DEPARTMENTS,
    ...column[ColumnType.TAGS]
  },
  {
    field: 'customerName',
    headerName: 'Customer',
    width: 150,
    valueGetter: valueGetters.customerLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'propertyName',
    headerName: 'Property',
    width: 150,
    valueGetter: valueGetters.propertyLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'status',
    headerName: 'Visit Status',
    width: 150,
    enumType: EnumType.VISIT_STATUS,
    ...column[ColumnType.ENUM]
  },
  {
    ldFlag: FeatureFlags.JOB_CLOSEOUT,
    field: 'reviewStatus',
    headerName: 'Visit Review Status',
    width: 200,
    showIcon: true,
    enumType: EnumType.VISIT_REVIEW_STATUS,
    ...getReviewStatusColDef(reviewStatusOptions)
  },
  {
    field: 'jobCompletionStatus',
    headerName: 'Job Completion Status',
    width: 200,
    showIcon: true,
    type: 'string',
    filterOperators: enumFilterOperators.map(o => ({
      ...o,
      InputComponentProps: { selectOptions: Object.values({ ...JobStatus, ...MaintenanceStatus }) }
    })),
    renderCell: ({ value, id, colDef, getValue }) => {
      const isMaintenanceJob = getValue(id, 'jobTypeInternal') === JobTypes.MAINTENANCE;
      const type = isMaintenanceJob ? EnumType.MAINTENANCE_STATUS : EnumType.JOB_STATUS;
      return (
        value && (
          <StatusChip
            enumType={type}
            enumValue={value}
            label={StatusValToLabelMapping(type, value) || value}
            showIcon={colDef.showIcon}
            style={{ borderRadius: 2 }}
          />
        )
      );
    }
  },
  {
    ldFlag: FeatureFlags.JOB_PROCUREMENT_STATUS,
    field: 'computedJobProcurementStatus',
    headerName: 'Job Procurement Status',
    width: 200,
    enumType: EnumType.JOB_PROCUREMENT_STATUS,
    showIcon: true,
    noLabelFormat: true,
    ...column[ColumnType.ENUM]
  },
  {
    ldFlag: FeatureFlags.JOB_CLOSEOUT,
    field: 'jobBillingStatus',
    headerName: 'Job Invoicing Status',
    width: 200,
    enumType: EnumType.JOB_BILLING_STATUS,
    showIcon: true,
    ...column[ColumnType.ENUM]
  },
  {
    field: 'primaryTech',
    headerName: 'Primary Technician',
    width: 200,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'jobTypeName',
    headerName: 'Job Type',
    width: 150,
    enumType: MultiSelectTypes.JOB_TYPES,
    ...column[ColumnType.TAG]
  },
  {
    field: 'jobTags',
    headerName: 'Tags',
    width: 100,
    enumType: MultiSelectTypes.JOB_TAGS,
    ...column[ColumnType.TAGS]
  },
  {
    field: 'createdDate',
    headerName: 'Created On',
    width: 150,
    ...column[ColumnType.DATE]
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 150,
    type: 'string'
  },
  {
    field: 'jobCompletedDate',
    headerName: 'Job Completion Date',
    width: 200,
    ...column[ColumnType.DATE]
  },
  {
    field: 'reviewedBy',
    headerName: 'Reviewed By',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'reviewedUnixDate',
    headerName: 'Reviewed on',
    width: 150,
    ...column[ColumnType.DATE]
  },
  {
    field: 'projectManagerName',
    headerName: 'Project Manager',
    width: 175,
    type: 'string'
  },
  {
    field: 'techReport',
    headerName: 'Report',
    width: 175,
    renderCell: ({ value }) => (
      <TechReport record={{ techReport: value, technicianReport: 'Download' }} />
    )
  }
];

export const useColumns = ({ onVisitClick }) => {
  const flags = useFlags();
  return useMemo(() => featureFlagFilter(flags)(getVisitColumns({ onVisitClick })), [
    flags,
    onVisitClick
  ]);
};
