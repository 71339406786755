import React, { useState } from 'react';

import { Divider, Grid, useTheme } from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { useSelector } from 'react-redux';

import CustomTabs from '@pm/components/CustomTabs';
import { ConfirmLeave, PageHeader, Spinner } from 'components';
import useBillingHourTypes from 'customHooks/useBillingHourTypes';
import useCostCodes from 'customHooks/useCostCodes';
import useCostTypes from 'customHooks/useCostTypes';
import useDepartments from 'customHooks/useDepartments';
import useEmployees from 'customHooks/useEmployees';
import useLabourRateGroups from 'customHooks/useLabourRateGroups';
import useLabourRateModifiers from 'customHooks/useLabourRateModifiers';
import useLabourTypes from 'customHooks/useLabourTypes';
import usePayrollHourTypes from 'customHooks/usePayrollHourTypes';
import usePayrollSettings from 'customHooks/usePayrollSettings';
import useProducts from 'customHooks/useProducts';
import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { checkPermission } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';
import { Mode } from 'utils/constants';

import { convertStringToSettingsJSON } from '../../utils';

import styles from '../styles';

import BillingHourRatesAndTypes from './BillingHourRatesAndTypes/BillingHourRatesAndTypes';
import GeneralSettings from './GeneralSettings';
import LaborRateGroupsAndTypes from './LaborRateGroupsAndTypes/LaborRateGroupsAndTypes';
import LaborRateModifiers from './LaborRateModifiers/LaborRateModifiers';
import PayrollHourRatesAndTypes from './PayrollHourRatesAndTypes/PayrollHourRatesAndTypes';

const TABS = {
  GENERAL_SETTINGS: { label: 'General Settings', value: 0 },
  LABOR_RATE_GROUPS_AND_TYPES: { label: 'Labor Rate Groups & Types', value: 1 },
  LABOR_RATE_MODIFIERS: { label: 'Labor Rate Modifiers', value: 2 },
  PAYROLL_HOUR_RATES_AND_TYPES: { label: 'Payroll Hour Rates & Types', value: 3 },
  BILLING_HOUR_RATES_AND_TYPES: { label: 'Billing Hour Rates & Types', value: 4 }
};

const EnterpriseLaborSettings = () => {
  const theme = useTheme();
  const user = useSelector(s => s.user);
  const { tenantId, tenantCompanyId } = user;
  const [isDirty, setIsDirty] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const [payrollSettings, loadingPayrollSettings] = usePayrollSettings({
    transform: data => convertStringToSettingsJSON(data?.getCompany?.companySettings?.items?.[0])
  });

  const serviceArgs = [tenantId, tenantCompanyId, snackbarOn];
  const [costCodes] = useCostCodes(...serviceArgs);
  const [costTypes, , , costTypesLoaded] = useCostTypes(...serviceArgs);
  const [billingHourTypes, billingHourTypesLoading] = useBillingHourTypes();
  const [labourTypes, labourTypesLoading] = useLabourTypes();
  const [products, productsLoading] = useProducts();
  const [payrollHourTypes, loadingPayrollHourTypes] = usePayrollHourTypes();
  const [labourRateGroups, labourRateGroupsLoading] = useLabourRateGroups();
  const [labourRateModifiers, labourRateModifiersLoading] = useLabourRateModifiers();
  const [employees, employeesLoading] = useEmployees({ includeDepartments: true });
  const [departments, departmentsLoading] = useDepartments();

  document.title = 'BuildOps - Payroll';

  const isAllowedToEdit = checkPermission(
    Mode.EDIT,
    PermissionConstants.OBJECT_TIMETRACKINGSETTING,
    user
  );

  const formEditLayout = payrollSettings?.id ? Mode.EDIT : Mode.ADD;

  const loading =
    !costTypesLoaded ||
    billingHourTypesLoading ||
    loadingPayrollSettings ||
    loadingPayrollHourTypes ||
    labourRateGroupsLoading ||
    labourRateModifiersLoading ||
    productsLoading ||
    departmentsLoading ||
    employeesLoading ||
    labourTypesLoading;
  return (
    <ErrorBoundaries>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <ConfirmLeave when={isDirty} />
          <Grid container>
            <Grid css={styles.stickyHeader} item>
              <PageHeader
                breadcrumbsArray={[{ link: '', title: Labels.settings[user.locale] }]}
                iconComponent={<SettingsOutlinedIcon css={styles.settingIcon} />}
                title={Labels.laborSettings[user.locale]}
              />
            </Grid>
          </Grid>
          <Grid container direction="column">
            <Grid css={{ marginTop: 150 }} item>
              <CustomTabs
                confirmSwitch={isDirty}
                tabMenus={Object.values(TABS).map(tab => ({
                  tabLabel: tab.label,
                  disabled: formEditLayout === Mode.ADD
                }))}
                value={selectedTab}
                onChange={(event, newValue) => {
                  setSelectedTab(newValue);
                  setIsDirty(false);
                }}
              />
              <Divider css={{ marginBottom: theme.spacing(4) }} fullwidth />
            </Grid>
            {selectedTab === TABS.GENERAL_SETTINGS.value && (
              <GeneralSettings
                formEditLayout={formEditLayout}
                isAllowedToEdit={isAllowedToEdit}
                isDirty={isDirty}
                payrollSettings={payrollSettings}
                setFormChanged={setIsDirty}
                user={user}
              />
            )}
            {selectedTab === TABS.LABOR_RATE_GROUPS_AND_TYPES.value && (
              <LaborRateGroupsAndTypes
                costCodes={costCodes}
                isDirty={isDirty}
                jobCostTypes={costTypes}
                labourRateGroups={labourRateGroups}
                labourRateGroupsLoading={labourRateGroupsLoading}
                labourTypes={labourTypes}
                labourTypesLoading={labourTypesLoading}
                payrollHourTypes={payrollHourTypes}
                setIsDirty={setIsDirty}
              />
            )}
            {selectedTab === TABS.LABOR_RATE_MODIFIERS.value && (
              <LaborRateModifiers
                departments={departments}
                employees={employees}
                isDirty={isDirty}
                labourRateModifiers={labourRateModifiers}
                labourRateModifiersLoading={labourRateModifiersLoading}
                setIsDirty={setIsDirty}
              />
            )}
            {selectedTab === TABS.PAYROLL_HOUR_RATES_AND_TYPES.value && (
              <PayrollHourRatesAndTypes
                billingHourTypes={billingHourTypes}
                isDirty={isDirty}
                labourRateGroups={labourRateGroups}
                labourTypes={labourTypes}
                payrollHourTypes={payrollHourTypes}
                payrollSettings={payrollSettings}
                setIsDirty={setIsDirty}
                user={user}
              />
            )}
            {selectedTab === TABS.BILLING_HOUR_RATES_AND_TYPES.value && (
              <BillingHourRatesAndTypes
                billingHourTypes={billingHourTypes}
                isDirty={isDirty}
                labourRateGroups={labourRateGroups}
                labourTypes={labourTypes}
                payrollHourTypes={payrollHourTypes}
                payrollSettings={payrollSettings}
                products={products}
                setIsDirty={setIsDirty}
                user={user}
              />
            )}
          </Grid>
        </>
      )}
    </ErrorBoundaries>
  );
};

export default EnterpriseLaborSettings;
