import gql from 'graphql-tag';
import orderBy from 'lodash/orderBy';
import { arrayOf, bool, shape, string } from 'prop-types';

import { ERROR_PROP } from '@dispatch/Dispatch.constants';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import { getState } from 'redux/store';

const GET_DEPARTMENTS = gql`
  query getDepartments($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      departments: departmentsView(
        entityConnection: "Department"
        filter: {
          integerFilters: [{ fieldName: "Department.deletedDate", filterInput: { is: null } }]
        }
      ) {
        items {
          id
          tagName
        }
      }
    }
  }
`;

const transformDepartments = data => {
  const departments = data.getCompany.departments.items.map(department => ({
    id: department.id,
    name: department.tagName
  }));

  return orderBy(departments, department => department.name.toLowerCase());
};

export const useDepartments = () => {
  const state = getState();
  const { tenantId, tenantCompanyId } = state.user;
  const sortKey = `${tenantId}_Company_${tenantCompanyId}`;

  return useExtendedQuery(GET_DEPARTMENTS, {
    variables: { partitionKey: tenantId, sortKey },
    defaultData: [],
    transform: transformDepartments
  });
};

export const DEPARTMENT_PROP = shape({
  id: string,
  name: string
});

export const DEPARTMENTS_RESPONSE_PROP = shape({
  loading: bool,
  error: ERROR_PROP,
  data: arrayOf(DEPARTMENT_PROP)
});
