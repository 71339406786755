import React from 'react';

import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CancelIcon from '@material-ui/icons/Cancel';
import classNames from 'classnames';
import Select, { createFilter } from 'react-select';

import styles from './styles';

function NoOptionsMessage(props) {
  return (
    <Typography
      className={props.selectProps.classes.noOptionsMessage}
      color="textSecondary"
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} id="something" />;
}

function Placeholder(props) {
  return (
    <Typography
      className={props.selectProps.classes.placeholder}
      color="textSecondary"
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(singleValueProps, props) {
  return (
    <Typography
      className={
        props.fromFilter
          ? singleValueProps.selectProps.classes.singleValueFilter
          : singleValueProps.selectProps.classes.singleValue
      }
      {...singleValueProps.innerProps}
    >
      {singleValueProps.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div
      className={props.selectProps.classes.valueContainer}
      style={props.selectProps.multiInputHeight ? { height: 'auto' } : null}
    >
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      deleteIcon={<CancelIcon {...props.removeProps} />}
      label={props.children}
      tabIndex={-1}
      onDelete={props.removeProps.onClick}
    />
  );
}

function Control(props) {
  const { inputProps = {}, fromFilter } = props;
  const {
    variant = 'filled',
    inputClassNames,
    textFieldClassName,
    customInnerProps = {},
    ...customProps
  } = inputProps;

  return (
    <TextField
      className={textFieldClassName}
      fullWidth
      InputLabelProps={{ shrink: !!props.selectProps.value || !!props.selectProps.inputValue }}
      InputProps={{
        inputComponent,
        inputProps: {
          className: classNames(
            fromFilter ? props.selectProps.classes.inputFilter : props.selectProps.classes.input,
            inputClassNames
          ),
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
          ...customInnerProps
        },
        style: {
          minHeight: props.selectProps.multiInputHeight,
          height: `${props.selectProps.multiInputHeight ? 'auto' : ''}`
        },
        ...customProps
      }}
      variant={variant}
      {...props.selectProps.textFieldProps}
    />
  );
}

// function Menu(props) {
//   return (
//     <Paper
//       square
//       className={props.selectProps.classes.paper}
//       {...props.innerProps}
//       // style={{ top: '-200px', height: '200px', overflow: 'scroll' }}
//     >
//       {props.children}
//     </Paper>
//   );
// }

class IntegrationReactSelect extends React.Component {
  constructor(props) {
    super(props);
    this.components = {
      Control: controlProps => (
        <Control fromFilter {...controlProps} inputProps={props.inputProps} />
      ),
      // Menu: Menu,
      MultiValue,
      // Option: Option,
      NoOptionsMessage,
      Placeholder,
      SingleValue: singleValueProps => SingleValue(singleValueProps, props),
      ValueContainer,
      ClearIndicator: () => '',
      IndicatorSeparator: () => '',
      DropdownIndicator:
        props.DropdownIndicator ||
        (() => <ArrowDropDownIcon style={{ marginTop: -10, color: '#646464' }} />)
    };
  }

  handleOnChange = item => {
    const { isMulti, getObjectOnChange, handleOnChange } = this.props;
    if (isMulti && item && item.length > 0) {
      const itemList = [];
      item.forEach(eachItem => {
        itemList.push(eachItem.value);
      });
      handleOnChange(itemList.length > 0 ? itemList : '');
    } else {
      if (getObjectOnChange) {
        handleOnChange(item);
      }
      handleOnChange(item?.value || '', item?.label || '');
    }
  };

  render() {
    const {
      fromFilter,
      name,
      label,
      placeholder = '',
      errorMsg,
      isMulti,
      classes,
      value,
      options,
      menuHeight,
      menuPortalTarget
    } = this.props;
    let defaultValueList;
    let defaultValue;

    const selectStyles = {
      menu: cstyles => ({
        ...cstyles,
        zIndex: 5,
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: fromFilter ? 12 : 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.29,
        letterSpacing: 'normal',
        color: '#3f3f3f',
        maxHeight: menuHeight || 160
      }),
      option: (customstyles, { isFocused, isSelected }) => ({
        ...customstyles,
        backgroundColor: isFocused ? '#f2f2f2' : isSelected ? '#f2f2f2' : null,
        color: isFocused ? '#3f3f3f' : isSelected ? '#3f3f3f' : null,
        maxHeight: 160
      }),
      menuList: custstyles => ({
        ...custstyles,
        left: 0,
        top: 0,
        maxHeight: menuHeight || 160
      })
    };

    if (isMulti) {
      defaultValueList = [];
      if (value && value.length > 0) {
        value.forEach(valueItem => {
          const localArr = options.filter(item => item.value === valueItem);
          defaultValueList = defaultValueList.concat(localArr);
        });
      }

      defaultValue = defaultValueList;
    } else {
      defaultValueList = options.filter(item => item.label === value || item.value === value);
      defaultValue = defaultValueList.length > 0 ? defaultValueList[0] : '';
    }

    const filterConfig = {
      ignoreCase: true,
      trim: true,
      matchFrom: 'start'
    };

    return (
      <>
        <Select
          classes={classes}
          components={this.components}
          filterOption={createFilter(filterConfig)}
          isClearable
          isDisabled={this.props.disabled}
          isMulti={isMulti}
          menuPlacement={this.props.menuPlacement}
          menuPortalTarget={menuPortalTarget}
          multiInputHeight={isMulti ? 90 : ''}
          name={name}
          options={options}
          placeholder={placeholder}
          styles={selectStyles}
          textFieldProps={{
            label,
            error: !!errorMsg
          }}
          value={defaultValue}
          onChange={this.handleOnChange}
        />
        <div className={classes.errorMsg}>{errorMsg}</div>
      </>
    );
  }
}

export default withStyles(styles)(IntegrationReactSelect);
