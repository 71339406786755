import AmplifyService from 'services/AmplifyService';

import deleteAdjustmentType from './query/deleteAdjustmentType';
import getAdjustmentTypes from './query/getAdjustmentTypes';
import getBankAccounts from './query/getBankAccounts';
import getIntacctDimensionsForCompany from './query/getIntacctDimensionsForCompany';
import getItemGLGroups from './query/getItemGLGroups';
import syncBankAccounts from './query/syncBankAccounts';
import syncIntacctDimension from './query/syncIntacctDimension';
import syncItemGLGroups from './query/syncItemGLGroups';
import upsertAdjustmentType from './query/upsertAdjustmentType';

function AccountingService() {
  const api = AmplifyService.appSyncClient();
  return {
    syncIntacctDimensions: async (tenantId, tenantCompanyId) => {
      const dataSet = {
        tenantId,
        data: {
          tenantCompanyId
        }
      };
      const response = await api.mutate(syncIntacctDimension, dataSet);
      return response;
    },
    fetchIntact: async (partitionKey, sortKey, startDate, endDate, nextToken) => {
      const params = {
        partitionKey,
        sortKey,
        nextToken
      };
      const response = await api.query(getIntacctDimensionsForCompany, params);
      return response;
    },
    syncItemGLGroups: async (tenantId, tenantCompanyId) => {
      const dataSet = {
        tenantId,
        data: {
          tenantCompanyId
        }
      };
      const response = await api.mutate(syncItemGLGroups, dataSet);
      return response;
    },
    fetchGLGroups: async (partitionKey, sortKey, startDate, endDate, nextToken) => {
      const params = {
        partitionKey,
        sortKey,
        nextToken
      };
      const response = await api.query(getItemGLGroups, params);
      return response;
    },
    syncBankAccounts: async (tenantId, tenantCompanyId) => {
      const dataSet = {
        tenantId,
        data: {
          tenantCompanyId
        }
      };
      const response = await api.mutate(syncBankAccounts, dataSet);
      return response;
    },
    fetchBankAccounts: async (partitionKey, sortKey, startDate, endDate, nextToken) => {
      const params = {
        partitionKey,
        sortKey,
        nextToken
      };
      const response = await api.query(getBankAccounts, params);
      return response;
    },
    getAdjustmentTypes: async (partitionKey, sortKey) => {
      const params = {
        partitionKey,
        sortKey
      };

      return api.query(getAdjustmentTypes, params);
    },
    upsertAdjustmentType: async (partitionKey, data) => {
      const params = {
        partitionKey,
        data
      };

      return api.mutate(upsertAdjustmentType, params);
    },
    deleteAdjustmentType: async (partitionKey, id) => {
      const params = {
        partitionKey,
        id
      };

      return api.mutate(deleteAdjustmentType, params);
    }
  };
}
export default AccountingService;
