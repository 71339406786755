const taskGroups = ({
  convertToCurrencyStringCheck,
  HTML,
  updateTasksAndGroups,
  smartFieldInfo,
  settingsJSON,
  SettingConstants,
  styles,
  fontFamilyStyle
}) => {
  const { tasksWithGroups, tasksWithNoGroup } = updateTasksAndGroups({
    groups: smartFieldInfo.quoteTaskGroups.items,
    tasks: smartFieldInfo.quoteLineTasks.items
  });
  const groupName = name =>
    settingsJSON[SettingConstants.TASK_GROUPS][SettingConstants.SHOW_TASK_GROUP_TITLE] ? name : '';
  const groupPricing = totalAmount =>
    settingsJSON[SettingConstants.TASK_GROUPS][SettingConstants.SHOW_TASK_GROUP_PRICING]
      ? convertToCurrencyStringCheck(totalAmount)
      : '';

  const groupedTaskData = HTML.groupedTaskData({
    tasksWithGroups,
    settingsJSON,
    SettingConstants,
    groupName,
    groupPricing,
    smartFieldInfo,
    HTML,
    styles,
    fontFamilyStyle
  });

  const ungroupedTaskData = HTML.ungroupedTaskData({
    tasksWithGroups,
    tasksWithNoGroup,
    settingsJSON,
    smartFieldInfo,
    HTML,
    styles,
    fontFamilyStyle
  });

  return `${groupedTaskData}${ungroupedTaskData}`;
};

export default taskGroups;
