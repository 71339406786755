import React from 'react';

import { ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

export const CircularProgressWithLabel = props => (
  <ThemeProvider>
    <Box display="inline-flex" marginLeft={0.5} position="relative">
      <CircularProgress variant="determinate" {...props} color="secondary" />
      <Box
        alignItems="center"
        bottom={0}
        display="flex"
        justifyContent="center"
        left={0}
        position="absolute"
        right={0}
        top={0}
      >
        <Typography
          color="textSecondary"
          component="div"
          variant={TV.S1}
          weight={TW.BOLD}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  </ThemeProvider>
);

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired
};

export default CircularProgressWithLabel;
