import React from 'react';

import { ButtonType } from '@BuildHero/sergeant';
import { isArray } from 'lodash';
import { array, object, string } from 'yup';

import { getImageUrl } from 'utils';
import { uniqueIdentifier } from 'utils/constants';

const extractRecipientsList = rawList => {
  let list = rawList;
  // It can happen because the Sergeant Select does not support array-like default value
  if (!isArray(list)) {
    list = [rawList];
  }
  return list.map(item => {
    const { value } = item;
    return value?.indexOf(uniqueIdentifier) > -1 ? value.slice(value?.indexOf('-') + 1) : value;
  });
};

export const generatePayload = async (
  {
    recipients: recipientsList,
    ccRecipients: ccRecipientList = [],
    bccRecipients: bccRecipientList = [],
    body,
    subject
  },
  emailData,
  attachmentsData,
  userEmail
) => {
  const strippedRecipientList = extractRecipientsList(recipientsList);
  const strippedccRecipientList = extractRecipientsList(ccRecipientList);
  const strippedbccRecipientList = extractRecipientsList(bccRecipientList);

  const payload = {
    invoiceId: emailData.invoiceId,
    paymentId: emailData.paymentId,
    email: {
      fromName: emailData.fromName || undefined,
      from: 'noreply@buildops.com',
      replyTo: emailData.replyTo || userEmail,
      importance: 'High',
      body: body || null,
      sendTo: strippedRecipientList.join(';'),
      sendCc: strippedccRecipientList.join(';'),
      sendBcc: strippedbccRecipientList.join(';'),
      subject: subject || emailData.subject,
      attachments:
        attachmentsData?.map(item => ({
          fileName: item.fileName,
          fileUrl: item.fileUrl,
          type: 'pdf',
          comment: item.comment
        })) || []
    }
  };

  if (emailData.logoUrl) {
    payload.email.attachments.push({
      fileName: 'logo',
      fileUrl: emailData.logoUrl.startsWith('https')
        ? emailData.logoUrl
        : await getImageUrl(emailData.logoUrl),
      type: 'image',
      comment: 'logo'
    });
  }

  return payload;
};

export const getEmailFormValidationSchema = () =>
  object({
    recipients: array()
      .of(
        object({
          value: string()
        })
      )
      .nullable()
      .required('At least one recipient is required'),
    body: string()
      .nullable()
      .required('The message cannot be empty')
  });

export const getFileSizeAlertMessage = numFiles => {
  switch (numFiles) {
    case 1:
      return (
        <span>
          <b> Error:</b> This file is too large to send via email. The maximum supported file size
          is 40MB. Please go back and upload or select smaller files.
        </span>
      );
    default:
      return (
        <span>
          <b> Error:</b> The attached files are too large to send via email. The maximum supported
          file size is 40MB. Please go back and upload or select smaller files.
        </span>
      );
  }
};

export const stepBackConfirmContent = {
  body:
    'You would lose the files attachments if you go back to the previous step. Do you wish to proceed?',
  title: 'Back to Add Attachment Step',
  buttonLabel: 'Confirm',
  buttonType: ButtonType.ERROR
};
