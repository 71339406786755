export const filterUniqueRecipients = reps => {
  // Remove any reps without email address
  const filterNonemptyEmails = reps.filter(cr => cr.email !== null && cr.email !== '');

  // Filter reps on ids
  const uniqueAllReps = Array.from(new Set(filterNonemptyEmails.map(rep => rep.id))).map(id => {
    return filterNonemptyEmails.find(rep => rep.id === id);
  });

  // Remove reps with same 'firstName', 'lastName' and 'email'
  const uniqueRecipients = uniqueAllReps.filter(
    (v, i, a) =>
      a.findIndex(v2 => ['email', 'firstName', 'lastName'].every(k => v2[k] === v[k])) === i
  );
  return uniqueRecipients;
};

// Add an index before extension to eliminate name collision
export const getUniqueFileName = (filesData, fileName, index = 0) => {
  let uniqueName = fileName;
  let ext = '';
  if (index) {
    if (uniqueName.indexOf('.') > -1) {
      const tokens = uniqueName.split('.');
      ext = `.${tokens.pop()}`;
      uniqueName = tokens.join('.');
    }
    uniqueName = `${uniqueName}_(${index})${ext}`;
  }
  const nameExists = filesData.filter(fileData => fileData.fileName === uniqueName).length;
  return nameExists ? getUniqueFileName(filesData, fileName, index + 1) : uniqueName;
};
