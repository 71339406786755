import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const ADD_ATTACHMENTS_TO_VIST = gql`
  mutation addAttachmentsToVisit($partitionKey: String!, $data: AddAttachmentsToVisitInput!) {
    addAttachmentsToVisit(partitionKey: $partitionKey, data: $data) {
      id
      customFileName
      fileName
      fileUrl
      type
      comment
      addedBy
      addedDateTime
      createdBy
      createdDateTime
      includeInInvoice
      hideFromTechniciansOnMobile
      version
    }
  }
`;

const serializer = ({ tenantId, visitId, attachments }) => ({
  variables: {
    partitionKey: tenantId,
    data: {
      visitId,
      attachments
    }
  }
});

const update = (cache, { data: { addAttachmentsToVisit } }, ref) => {
  cache.modify({
    id: `Visit:${ref.visitId}`,
    fields: {
      attachments(existingSummariesRef) {
        const newRefs = addAttachmentsToVisit.map(attachment => {
          return {
            __ref: `${attachment.__typename}:${attachment.id}`
          };
        });

        return {
          items: [...existingSummariesRef.items, ...newRefs]
        };
      }
    }
  });
};

export const useAddAttachmentsToVisit = () => {
  return useExtendedMutation(ADD_ATTACHMENTS_TO_VIST, {
    serializer,
    update
  });
};
