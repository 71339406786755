/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable operator-assignment */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import AlgoliaSelect from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import DefaultButton from 'components/Buttons/DefaultButton';
import FullScreenModal from 'components/FullScreenModal';
import withMultipleFormsDynamic from 'components/WithMultipleForms/dynamic';
import { useSnackbar } from 'customHooks/useSnackbar';
import Labels from 'meta/labels';
import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';
import buildHeroMuiFormOverrides from 'scenes/ProjectManagement/components/buildHeroMuiFormOverrides';
import { generateDefaultValidationSchema } from 'scenes/ProjectManagement/components/formattingUtils';
import {
  changeOrderChange,
  changeOrderCreate,
  getNextChangeOrderNumber
} from 'services/API/changeorder';

import { useProjectPhases } from '../../FieldReport/DailyReportList/DailyReport.hooks';

import FieldForm from './FieldForm';
import { generalInformationFields, layoutGeneral } from './layout';

const useStyles = makeStyles(theme => ({
  contentContainer: {
    margin: 'auto',
    padding: 21
  },
  sectionTitle: {
    color: theme.palette.grayscale(20),
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: '-0.03em',
    marginBottom: 20
  },
  formContainer: buildHeroMuiFormOverrides(theme)
}));

const costTypes = ['labor', 'material', 'equipment', 'subcontractor', 'other', 'overhead'];

const GenerateChangeOrder = props => {
  const {
    changeOrderData,
    open,
    handleClose,
    projectId,
    projectData,
    update,
    getHandleCreateService,
    setOnSubmitFinal,
    getHandleComplete,
    handleSubmitStart,
    getHandleRemoveService,
    getHandleRemoveAllServices
  } = props;
  const { locale } = useSelector(state => state.user);
  const snackbar = useSnackbar();
  const classes = useStyles();
  const [{ data: projectPhases }] = useProjectPhases(projectId);
  const projectPhasesRef = useRef();
  projectPhasesRef.current = projectPhases;
  const [nextNum, setNextNum] = useState(0);
  const [newChangeOrderId, setNewChangeOrderId] = useState();
  const fullscreenModalRef = useRef(null);
  const [dataToUpdate, setDataToUpdate] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [lineItems, setLineItems] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [costTypeComponents, setCostTypeComponents] = useState([]);
  const [removeAllFormService, setRemoveAllFormService] = useState(false);

  const sectionTitle = ({ options }) => (
    <Typography className={classes.sectionTitle} variant="subtitle1">
      {options.label}
    </Typography>
  );

  const updateItems = () => {
    setLineItems(changeOrderData?.ChangeOrderLineItem || []);
  };

  const onSubmitFinal = async submittedData => {
    setSubmitting(true);
    const payload = {
      ...submittedData.GeneralInfo,
      ChangeOrderLineItem: Object.values(submittedData.ChangeOrderLineItem || {}) || []
    };

    if (payload.ChangeOrderLineItem?.length) {
      payload.ChangeOrderLineItem.map(line => ({
        ...line,
        ...submittedData.Percentages[line.type]
      }));
    }

    if (payload.id) {
      await changeOrderChange(payload.id, payload);
    } else {
      await changeOrderCreate(payload);
    }

    setCostTypeComponents([]);
    setRemoveAllFormService(true);
    setSubmitting(false);
    handleClose();
  };

  const formatItemList = (itemList, id) => {
    itemList.map(item => {
      // eslint-disable-next-line no-param-reassign
      item.quantity = Number(item.quantity);
      delete item.costCode;
      delete item.phase;
      item.changeOrderId = id;
      if (item.id && typeof item.id !== 'string') {
        delete item.id;
      }
    });

    return itemList;
  };

  useEffect(() => {
    if (removeAllFormService) {
      getHandleRemoveAllServices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeAllFormService]);

  useEffect(() => {
    if (open) {
      setRemoveAllFormService(false);
      setOnSubmitFinal(onSubmitFinal);
      updateItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (open && changeOrderData?.id && changeOrderData?.status === 'Approved') {
      snackbar(
        'error',
        `Editing an approved Change Order changes its status to 'Draft'. If you save any changes, you will need to reapprove it.`
      );
    }
  }, [changeOrderData.id, changeOrderData.status, open, snackbar]);

  useEffect(() => {
    if (changeOrderData && Object.keys(changeOrderData).length === 0) {
      getNextChangeOrderNumber(projectId).then(num => setNextNum(num));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeOrderData]);

  useEffect(() => {
    setCostTypeComponents([
      ...costTypes.map(type => {
        return (
          <FieldForm
            getHandleComplete={getHandleComplete}
            getHandleCreateService={getHandleCreateService}
            getHandleRemoveService={getHandleRemoveService}
            initialData={lineItems.length > 0 ? lineItems.filter(line => line.type === type) : []}
            key={type}
            locale={locale}
            mode="default"
            name={type}
            projectId={projectId}
            projectPhases={projectPhases}
          />
        );
      })
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItems]);

  useEffect(() => {
    const setMaxWidth = () => {
      if (fullscreenModalRef?.current?.parentNode?.style.maxWidth < 1440) {
        fullscreenModalRef.current.parentNode.style.maxWidth = '1440px';
        fullscreenModalRef.current.parentNode.style.marginTop = '0px';
      }
    };
    setMaxWidth();
  }, []);

  useEffect(() => {
    const insertLines = async () => {
      let cost = 0;
      let revenue = 0;
      let profit = 0;
      let margin = 0;
      const itemList = [];
      for (const type in dataToUpdate) {
        if (dataToUpdate[type].costSubtotal !== '' && dataToUpdate[type].revenueSubtotal !== '') {
          cost += dataToUpdate[type].costSubtotal;
          revenue += dataToUpdate[type].revenueSubtotal;
          if (dataToUpdate[type].costSubtotal > 0 || dataToUpdate[type].revenueSubtotal > 0) {
            itemList.push(...dataToUpdate[type].codes);
          }
        }
      }
      profit = revenue - cost;
      margin = (profit / revenue) * 100;

      if (Object.keys(changeOrderData).length > 0) {
        await changeOrderChange(changeOrderData.id, {
          ChangeOrderLineItem: formatItemList(itemList, newChangeOrderId),
          cost,
          revenue,
          profit,
          margin
        }).then(() => update());
      } else if (newChangeOrderId) {
        await changeOrderChange(newChangeOrderId, {
          ChangeOrderLineItem: formatItemList(itemList, newChangeOrderId),
          cost,
          revenue,
          profit,
          margin
        }).then(() => update());
      }

      setNewChangeOrderId(undefined);
      setDataToUpdate({});
    };

    if (
      ((changeOrderData && newChangeOrderId) || Object.keys(changeOrderData).length !== 0) &&
      Object.keys(dataToUpdate).length !== 0
    ) {
      insertLines();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newChangeOrderId, dataToUpdate]);

  const formatGeneralInfoForSave = data => {
    return {
      id: data?.id || undefined,
      date: data?.date,
      daysValid: Number(data?.daysValid),
      number: data?.number,
      returnTo: data?.ReturnTo?.name,
      returnToId: data?.ReturnTo?.id,
      scheduleExtensionRequested: Number(data?.scheduleExtensionRequested),
      scopeOfWork: data?.scopeOfWork,
      sentTo: data?.SendTo?.name,
      sentToId: data?.SendTo?.id,
      status: data?.status,
      subject: data?.subject,
      projectId: projectId || '',
      gcChangeOrderNumber: data?.gcChangeOrderNumber,
      gcPurchaseOrderNumber: data?.gcPurchaseOrderNumber,
      dueDate: moment
        .unix(data?.date)
        .add(Number(data?.daysValid), 'days')
        .valueOf()
    };
  };

  // General Information
  const getFormattedGeneralInformationData = useCallback(
    data => {
      return {
        id: data?.id || '',
        number: data?.number ? data.number : nextNum,
        date: data?.date ?? moment().unix(),
        subject: data?.subject || '',
        status: !data?.status || data.status === 'Approved' ? 'Draft' : data.status,
        SendTo: data?.SendTo,
        ReturnTo: data?.ReturnTo,
        scopeOfWork: data?.scopeOfWork || '',
        daysValid: data?.daysValid ?? 30,
        gcChangeOrderNumber: data?.gcChangeOrderNumber,
        gcPurchaseOrderNumber: data?.gcPurchaseOrderNumber,
        scheduleExtensionRequested: data?.scheduleExtensionRequested ?? 30,
        scheduleAdditionalInfo: data?.scheduleAdditionalInfo || ''
      };
    },
    [nextNum]
  );

  const initialGeneralInformationFormData = useMemo(
    () => getFormattedGeneralInformationData(changeOrderData),
    [getFormattedGeneralInformationData, changeOrderData]
  );

  return (
    <FullScreenModal
      handleClose={() => {
        setCostTypeComponents([]);
        setRemoveAllFormService(true);
        handleClose();
      }}
      modalHeaderButtons={[
        <DefaultButton
          disabled={submitting}
          key="save"
          label="Save Change Order"
          showSpinner={submitting}
          variant="containedPrimary"
          onClick={handleSubmitStart}
        />
      ]}
      open={open}
      title={`${
        changeOrderData?.id ? Labels.editChageOrder[locale] : Labels.generateChageOrder[locale]
      } ${changeOrderData?.number ? changeOrderData.number : nextNum}`}
    >
      <Box className={classes.contentContainer} ref={fullscreenModalRef}>
        <Box className={classes.formContainer}>
          <MUIForm
            configuration={layoutGeneral(selectedCustomer, projectData)}
            customComponents={{
              sectionTitle,
              AlgoliaSelect,
              Divider,
              SearchBar
            }}
            data={initialGeneralInformationFormData}
            layout="default"
            validationSchema={generateDefaultValidationSchema({ ...generalInformationFields })}
            onComplete={getHandleComplete('GeneralInfo', formatGeneralInfoForSave)}
            onCreateService={getHandleCreateService('GeneralInfo')}
          />
        </Box>

        {costTypeComponents}
      </Box>
    </FullScreenModal>
  );
};

GenerateChangeOrder.propTypes = {
  changeOrderData: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  projectData: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  getHandleCreateService: PropTypes.func.isRequired,
  setOnSubmitFinal: PropTypes.func.isRequired,
  getHandleComplete: PropTypes.func.isRequired,
  handleSubmitStart: PropTypes.func.isRequired,
  getHandleRemoveService: PropTypes.func.isRequired,
  getHandleRemoveAllServices: PropTypes.func.isRequired
};

const withMultiple = withMultipleFormsDynamic(GenerateChangeOrder);
export default withMultiple;
