import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { Divider, PageHeader, UserPermission } from 'components';
import ResponsiveTable from 'components/ResponsiveTable';
import Labels from 'meta/labels';
import { assetRowMeta } from 'meta/ListViews';

import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { ListService } from 'services/core';
import { Logger } from 'services/Logger';
import { PermissionConstants } from 'utils/AppConstants';

import styles from './styles';

class AssetView extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.ListService = new ListService();
    this.state = {
      serviceFn: this.getAllAssets
    };
  }

  componentDidMount = async () => {
    document.title = 'BuildOps - Asset List';
  };

  getAllAssets = async (filter, limit, offset, sortBy, sortOrder) => {
    if (!this.props.user.tenantId) {
      return { items: [], nextToken: '0' };
    }
    let data;
    const sortKey = `${this.props.user.tenantId}_Company_${this.props.user.tenantCompanyId}`;
    try {
      data = await this.ListService.getAllAssets(
        this.props.user.tenantId,
        sortKey,
        filter,
        limit,
        offset,
        sortBy,
        sortOrder
      );

      return data;
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to fetch Assets, please try again later', error);
    }
    return data || [];
  };

  render() {
    const { user } = this.props;
    const { serviceFn } = this.state;
    return (
      <ErrorBoundaries>
        <UserPermission action={PermissionConstants.OBJECT_ASSET} I="read">
          <PageHeader title={Labels.asset[user.locale]} />
          <Divider />
          <ResponsiveTable
            caslKey={PermissionConstants.OBJECT_ASSET}
            noDataMsg="No Assets"
            rowMetadata={assetRowMeta}
            service={serviceFn}
            useServerQueries
          />
        </UserPermission>
      </ErrorBoundaries>
    );
  }
}

const styledAssetView = withStyles(styles, { withTheme: true })(AssetView);

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const reduxConnectedAsset = connect(mapStateToProps, mapDispatcherToProps)(styledAssetView);

export default reduxConnectedAsset;
