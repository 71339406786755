import { removeNestedJson } from '../../utils';
import InstructionHelper from '../InstructionHelper';
// import { Logger } from "services/core";

const tenantRepSchema = props => {
  const { parent, repNotes, ...rest } = props;
  const employeesForCompany = parent.employees;

  // for adding tenant rep to property, we also need to add it to customer
  // const customer = parent.parent || '';
  // let strippedCustomer = '';
  // if (customer !== '') {
  //   strippedCustomer = removeNestedJson(customer);
  // }

  const parentRef = parent.customerName ? 'customerName' : 'companyName';
  const strippedParent = removeNestedJson(parent);
  const notesJson = {
    partitionKey: props.tenantId,
    tenantId: props.tenantId,
    tenantCompanyId: props.tenantCompanyId,
    entityType: 'Note'
  };
  let employeeJson = '';
  employeesForCompany &&
    employeesForCompany.forEach(employee => {
      if (employee.name === rest.name) {
        employeeJson = employee;
      }
    });

  const notesArray = [];
  if (repNotes && repNotes.length > 0) {
    repNotes.forEach(noteObj => {
      const localNoteJob = noteObj;
      if (localNoteJob.__typename) {
        delete localNoteJob.__typename;
      }
      if (localNoteJob.edited && localNoteJob.note) {
        notesArray.push({ ...notesJson, ...localNoteJob });
      }
    });
  }
  // create name
  rest.name = `${rest.firstName} ${rest.lastName}`;
  // inverting the data array intentionally as same pop up is used both in customer
  // and customer property level
  const data = [{ ...employeeJson }, { ...strippedParent }];
  const ins = new InstructionHelper();
  let temp = ins.manyToMany(1, 0, parentRef, 'firstName', '', 'TenantRep');
  // TODO: check if its a requirement
  // if (strippedCustomer !== '') {
  //   data.push(strippedCustomer);
  //   temp = ins.manyToMany(data.length - 1, 0, 'customerName', 'firstName');
  // }

  if (notesArray && notesArray.length > 0) {
    notesArray.forEach(noteJSON => {
      data.push(noteJSON);
      temp = temp.oneToMany(0, data.length - 1);
    });
  }
  const { instructionSet } = temp;
  return { data, instructionSet };
};

export default tenantRepSchema;
