import isArray from 'lodash/isArray';

import { snackbarOn } from 'redux/actions/globalActions';
import { dispatch, getState } from 'redux/store';
import { Logger } from 'services/Logger';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

export const handleError = ({ err, errContext = '', hideError = false }) => {
  const normalizedError = isArray(err) ? err[0] : err;
  if (!normalizedError) return;
  const showVerboseErrors = getState()?.launchDarklyFlags?.flags?.[FeatureFlags.VERBOSE_ERRORS];
  let displayMessage = normalizedError.message || '';
  const networkErrorResult = normalizedError?.networkError?.result;

  const networkError = err?.isAxiosError
    ? err?.response?.data?.errors?.[0]?.message
    : typeof networkErrorResult === 'string'
    ? networkErrorResult
    : networkErrorResult?.errors?.[0]?.message;

  if (networkError) displayMessage = `${displayMessage} \n ${networkError}`;
  if (errContext) displayMessage = `${errContext}: ${displayMessage}`;

  displayMessage = displayMessage.slice(0, 500);

  Logger.error(normalizedError, { errContext, displayMessage });

  if (!hideError) {
    dispatch(
      snackbarOn(
        'error',
        `ERROR: ${displayMessage}`,
        showVerboseErrors ? normalizedError : displayMessage
      )
    );
  }
};
