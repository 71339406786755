import React from 'react';

import { Link } from 'react-router-dom';

import { VisitStatuses } from '@dispatch/Dispatch.constants';
import { VISIT_DETAILS_PROP } from '@dispatch/queries';

import Routes from '../../../Routes';

const completedStates = [
  VisitStatuses.ON_HOLD.key,
  VisitStatuses.COMPLETE.key,
  VisitStatuses.CLOSED.key,
  VisitStatuses.CONVERTED.key
];

const VisitLink = ({ visit }) => {
  const { job } = visit;
  const isCloseoutReportEnabled = job.closeoutReport;
  const isVisitCompleted = completedStates.includes(visit?.status);
  const shouldLinkVisit = isCloseoutReportEnabled && isVisitCompleted;

  return shouldLinkVisit ? (
    <Link
      to={Routes.jobCloseoutVisit({
        jobid: encodeURIComponent(job?.id),
        id: encodeURIComponent(visit?.id)
      })}
    >
      Visit {visit?.visitNumber}
    </Link>
  ) : (
    <span>Visit {visit?.visitNumber}</span>
  );
};

VisitLink.propTypes = {
  visit: VISIT_DETAILS_PROP.isRequired
};

export default VisitLink;
