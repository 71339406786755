import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { getServiceAgreementById } from 'customHooks/useServiceAgreement';
import mergeDeep from 'utils/mergeDeep';

const removePropertyAssetsAndChecklistsFromServiceAgreement = gql`
  mutation removePropertyAssetsAndChecklistsFromServiceAgreement(
    $serviceAgreementId: String!
    $input: [PropertyAssetAndChecklistsInput!]!
  ) {
    removePropertyAssetsAndChecklistsFromServiceAgreement(
      serviceAgreementId: $serviceAgreementId
      input: $input
    ) {
      id
      relatedIds
    }
  }
`;

const useRemovePropertyAssetsAndChecklistsFromServiceAgreement = ({ user, serviceAgreementId }) => {
  return useExtendedMutation(removePropertyAssetsAndChecklistsFromServiceAgreement, {
    serializer: ({ assetChecklistsToRemove }) => ({
      variables: {
        serviceAgreementId,
        input: assetChecklistsToRemove
      }
    }),
    onSuccess: (data, snackbar) => {
      snackbar('success', 'Service Agreement Saved');
    },
    onError: (error, snackbar) => {
      snackbar('error', 'Unable to Save Service Agreement');
    },
    skip: !serviceAgreementId,
    update: (
      cache,
      {
        data: { removePropertyAssetsAndChecklistsFromServiceAgreement: assetsWithRemovedChecklists }
      }
    ) => {
      const query = {
        query: getServiceAgreementById,
        variables: {
          partitionKey: user.tenantId,
          id: serviceAgreementId
        }
      };
      const cachedData = cache.readQuery(query);

      const newItems = cachedData.serviceAgreement.propertyAssets.items
        .map(a => {
          const relevantAsset = assetsWithRemovedChecklists.find(awrcl => awrcl.id === a.id);
          if (!relevantAsset) return a;

          return {
            ...a,
            mergedTaskTemplateChecklists: a.mergedTaskTemplateChecklists.filter(
              l => !relevantAsset.relatedIds.includes(l.id)
            )
          };
        })
        .filter(a => a.mergedTaskTemplateChecklists.length);
      const update = { serviceAgreement: { propertyAssets: { items: newItems } } };

      const result = mergeDeep(cachedData, update);

      cache.writeQuery({ ...query, data: result });
    }
  });
};

export default useRemovePropertyAssetsAndChecklistsFromServiceAgreement;
