/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Link } from 'react-router-dom';

import IconMap from 'meta/IconMap';
import PageMap from 'meta/PageMap';

import styles from '../styles';

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
    pageMapKey: PropTypes.string
  }),
  compact: PropTypes.bool,
  showIcons: PropTypes.bool,
  tenantSettingProcessed: PropTypes.shape({})
};

const defaultProps = {
  data: {},
  showIcons: true,
  tenantSettingProcessed: {},
  compact: false
};

function MenuItem(props) {
  const RenderDivider = () => <Divider />;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { tenantSettingProcessed, data, compact, showIcons } = props;
  const compactStyling = {
    fontSize: '12px',
    marginTop: 2,
    marginBottom: 2
  };

  const handlePopoverOpen = (event, id) => {
    setAnchorEl({ ele: event.currentTarget, id });
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const RouterLink = () =>
    React.forwardRef((linkProps, ref) => (
      <Link {...linkProps} ref={ref} style={{ textDecoration: 'none' }} />
    ));

  const RenderMenu = menuProps => {
    let menuTitle = menuProps.data.title;
    let menuIcon = menuProps.data.icon;
    let menuLink = menuProps.data.url;
    const { pageMapKey } = menuProps.data;
    // Use values from PageMap
    if (pageMapKey) {
      menuTitle = PageMap[pageMapKey].title?.en || menuProps.data.title;
      menuIcon = PageMap[pageMapKey].icon || menuProps.data.icon;
      menuLink = PageMap[pageMapKey].link || menuProps.data.url;
    }

    return (
      <ListItem
        classes={{
          root: menuProps.isPopover
            ? menuProps.classes.menuPopoverItem
            : menuProps.classes.menuItem,
          selected: menuProps.isPopover ? null : menuProps.classes.menuSelected
        }}
        component={RouterLink()}
        key={`menuitem${menuTitle}`}
        selected={
          menuProps.isPopover ? null : window.location.pathname.includes(menuProps.data.url)
        }
        testingid={data.testingid || menuTitle}
        to={`/${menuLink}`}
        onClick={() => {
          if (menuProps.handleClosePopover) menuProps.handleClosePopover();
        }}
      >
        {menuIcon && !compact && showIcons && (
          <ListItemIcon
            classes={{ root: menuProps.classes.listIcons }}
            key={`menuicon${menuTitle}`}
          >
            <IconMap icon={menuIcon} style={{ fontSize: 16 }} />
          </ListItemIcon>
        )}
        <ListItemText
          classes={{ root: menuProps.classes.listTexts }}
          disableTypography
          key={`menutext${menuTitle}`}
          primary={menuTitle}
          style={compact ? compactStyling : {}}
        />
      </ListItem>
    );
  };

  if (data.type !== 'menu') {
    return <RenderDivider />;
  }
  if (data.featureGate) {
    // when there is no tenant setting data available, dont show menu
    // applies when the context query returns after the menu is rendered
    // to avoid showing and hiding
    if (R.isEmpty(tenantSettingProcessed)) {
      return null;
    }
    // when the value of tenant setting is not matched, return null
    if (
      tenantSettingProcessed &&
      tenantSettingProcessed[data.featureGate] !== data.featureGateValue
    ) {
      return null;
    }
  }

  return (
    <RenderMenu
      {...props}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      onOpen={handlePopoverOpen}
    />
  );
}

MenuItem.propTypes = propTypes;
MenuItem.defaultProps = defaultProps;

const styledMenu = withStyles(styles)(MenuItem);

export default styledMenu;
