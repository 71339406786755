import React, { useEffect, useState } from 'react';

import { Typography } from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';
import { debounce } from 'lodash';

import { array, bool, func, string } from 'prop-types';

import { AutoCompleteDropdown } from 'components';

import { productSearch } from 'services/API/product';

import ItemActions from '../../../../ItemActions';

import { useStyles } from './ProjectAccountingSettingItem.styles';

const ProjectAccountingSettingItem = ({
  name,
  settingValue,
  options,
  isBeingEdited,
  onSettingChange,
  onSettingChangeCancel,
  onSettingSave,
  disabled,
  ...rest
}) => {
  const styles = useStyles();

  const [searchTerm, setSearchTerm] = useState(null);
  const [searchOptions, setSearchOptions] = useState([]);

  useEffect(() => {
    if (options?.length && !searchOptions?.length) {
      setSearchOptions(options);
    }
  }, [options, searchOptions]);

  useEffect(() => {
    (async () => {
      if (searchTerm) {
        const searchResult = await productSearch(searchTerm);
        const newSearchOptions =
          searchResult
            ?.filter(item => Boolean(item?.id) && Boolean(item?.name))
            ?.map(item => ({
              value: item.id,
              label: item.name
            })) || [];
        setSearchOptions(newSearchOptions);
      }
    })();
  }, [searchTerm]);

  const handleSettingChange = (value, label) => {
    onSettingChange({ label, value });
  };

  const clearSearch = () => {
    setSearchTerm(null);
  };

  const handleSettingCancel = () => {
    clearSearch();
    setSearchOptions([]);
    onSettingChangeCancel();
  };

  const handleProductsSearch = debounce(async event => {
    setSearchTerm(event.target.value);
  }, 250);

  return (
    <Box css={styles.container} display="flex">
      <Box>
        <Typography css={styles.label}>{name}</Typography>
        <div css={styles.dropdown}>
          <AutoCompleteDropdown
            disabled={disabled}
            handleOnChange={handleSettingChange}
            inputProps={{ onChange: handleProductsSearch, onBlur: clearSearch }}
            isClearable
            options={searchOptions}
            placeholder="Search product"
            value={settingValue}
            {...rest}
          />
        </div>
      </Box>
      <Box>
        <Typography css={styles.label}>&nbsp;</Typography>
        <ItemActions
          handleCancel={handleSettingCancel}
          handleSave={onSettingSave}
          isVisible={isBeingEdited}
        />
      </Box>
    </Box>
  );
};

ProjectAccountingSettingItem.propTypes = {
  name: string.isRequired,
  settingValue: string.isRequired,
  options: array.isRequired,
  disabled: bool.isRequired,
  isBeingEdited: bool.isRequired,
  onSettingChange: func.isRequired,
  onSettingChangeCancel: func.isRequired,
  onSettingSave: func.isRequired
};

export default ProjectAccountingSettingItem;
