import React, { Component } from 'react';

import { Typography } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import BackArrowIcon from '@material-ui/icons/ArrowBack';
import { connect } from 'react-redux';

import { CustomerService } from 'services/core';

import { Logger } from 'services/Logger';
import { capitalizeFirstLetter, processAddressArrayAsJson } from 'utils';

import CustomerMenus from './components/customer-menus';
import styles from './styles';

class Customer extends Component {
  constructor(props) {
    super(props);
    this.CustomerService = new CustomerService();
    this.state = {
      customerData: '',
      propertiesData: ''
    };
  }

  async componentDidMount() {
    const recordSortKey = this.props.sortKey;
    this.queryAndSetProperties(recordSortKey);
    await this.queryAndSetCustomerInfo(recordSortKey);
  }

  queryAndSetCustomerInfo = async recordSortKey => {
    // on first load, the tenant id was not set, hence the check
    if (this.props.user && this.props.user.tenantId === '') {
      return null;
    }

    let data;
    let localCusomterData;
    try {
      if (recordSortKey) {
        ({ data } = await this.CustomerService.getCustomerInfo(
          this.props.user.tenantId,
          recordSortKey
        ));
        localCusomterData = data.getCustomer;
      }
    } catch (error) {
      Logger.error(`Error in fetching customer details ${JSON.stringify(error)}`);
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        // const errorMessage =
        //   error.graphQLErrors[0].message === 'Query failed with NO results'
        //     ? 'Unable to find customer'
        //     : error.graphQLErrors[0].message;
        // this.props.snackbarOn('error', errorMessage);
        this.props.history.push('/customer/list');
      }
      this.props.snackbarOn(
        'error',
        'Unable to fetch Customer data, please try again later',
        error
      );
    }

    const customerTagArray = [];

    if (localCusomterData) {
      localCusomterData.status = localCusomterData.status
        ? capitalizeFirstLetter(localCusomterData.status)
        : '';
      if (localCusomterData.sameAddress) {
        localCusomterData.sameAddress = `${localCusomterData.sameAddress}`;
      }

      localCusomterData.customerNotes = localCusomterData.notes.items.sort(
        (left, right) => right.lastUpdatedDateTime - left.lastUpdatedDateTime
      );

      // for mutli dropdown, when the values are removed the M to M relation needs to be removed
      // hence create 'mapping' and injected to form
      const tagMapping = {};
      if (
        localCusomterData.customerTags &&
        localCusomterData.customerTags.items &&
        localCusomterData.customerTags.items.length > 0
      ) {
        localCusomterData.customerTags.items.forEach(tag => {
          const { mappedEntity, sortKey, invertedSortKey } = tag;
          if (mappedEntity) {
            const relationKeys = [];
            relationKeys.push(sortKey);
            relationKeys.push(invertedSortKey);
            tagMapping[mappedEntity.id] = relationKeys;
            customerTagArray.push(mappedEntity.id);
          }
        });
      }

      // from customer
      localCusomterData.processedCustomerTags = customerTagArray;
      localCusomterData.customerDbIds = customerTagArray;
      // localCusomterData.processedTenantReps = tenantReps;

      // from company - master data
      const { company } = this.state;
      localCusomterData.customerTagsList = this.state.customerTags;
      localCusomterData.customerPropertyTypes = ((company || {}).customerPropertyTypes || {}).items;
      localCusomterData.employees = ((company || {}).employees || {}).items;

      // for deleting many to many relations
      localCusomterData.tagMapping = tagMapping;

      if (this.state.noOfProperties) {
        data.noOfProperties = this.state.noOfProperties;
      }

      this.setState(prevState => ({
        ...prevState,
        customerData: localCusomterData
      }));
    }

    return localCusomterData;
  };

  queryAndSetProperties = async recordSortKey => {
    // on first load, the tenant id was not set, hence the check
    if (this.props.user && this.props.user.tenantId === '') {
      return null;
    }

    let data;
    let localCusomterData;
    try {
      if (recordSortKey) {
        ({ data } = await this.CustomerService.getPropertiesByCustomer(
          this.props.user.tenantId,
          recordSortKey
        ));
        localCusomterData = data.getCustomer;
      }
    } catch (error) {
      Logger.error(`Error in fetching customer details ${JSON.stringify(error)}`);
      this.props.snackbarOn('error', 'Unable to fetch data, please try again later');
    }

    if (localCusomterData) {
      let noOfProperties;
      // adding no of properties
      localCusomterData.noOfProperties =
        localCusomterData.customerProperties && localCusomterData.customerProperties.items
          ? localCusomterData.customerProperties.items.length
          : 0;
      if (
        localCusomterData.customerProperties &&
        localCusomterData.customerProperties.items &&
        localCusomterData.customerProperties.items.length > 0
      ) {
        noOfProperties = localCusomterData.customerProperties.items.length;
        localCusomterData.customerProperties.items.forEach((property, index) => {
          if (
            property.companyAddresses &&
            property.companyAddresses.items &&
            property.companyAddresses.items.length > 0
          ) {
            const localCombinedAddress = processAddressArrayAsJson(property.companyAddresses.items);
            localCusomterData.customerProperties.items[index] = {
              ...property,
              ...localCombinedAddress
            };
          }
          // get completed jobs count
          // TODO: add filter with status as completed in the query
          localCusomterData.customerProperties.items[index].jobCompletedCount = (
            (property.jobs || {}).items || []
          ).length;
        });
      }
      this.setState(prevState => ({
        ...prevState,
        propertiesData: localCusomterData.customerProperties,
        noOfProperties: noOfProperties || 0
      }));
    }

    return localCusomterData;
  };

  render() {
    const { customerData, noOfProperties } = this.state;
    const { classes } = this.props;
    return (
      <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
        <Grid
          alignItems="flex-end"
          className={classes.detailTop}
          container
          direction="row"
          justify="flex-start"
          style={{ padding: 20 }}
        >
          <Grid item style={{ paddingRight: 10 }}>
            <IconButton
              aria-label="Back"
              className={classes.button}
              onClick={() => this.props.backToResult()}
            >
              <BackArrowIcon className={classes.backIcon} />
            </IconButton>
          </Grid>
          <Grid item style={{ paddingRight: 15 }}>
            <Typography className={classes.searchText}>Search</Typography>
          </Grid>
          <Grid item style={{ paddingRight: 5 }}>
            <Typography className={classes.entityTypeText}>Customer: </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.customerName}>{customerData.customerName}</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid className={classes.highlightPanel} item lg={12} md={12} sm={12} xl={12} xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography className={classes.highlightText}>
                  {customerData.customerName}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Grid container spacing={3}>
                  <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
                    <Typography variant="caption">Customer type</Typography>
                    <Typography variant="subtitle1">{customerData.customerTypeValue}</Typography>
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
                    <Typography variant="caption">No. of properties</Typography>
                    <Typography variant="subtitle1">{noOfProperties}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3} />
              <Grid item xs={9}>
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                    <Typography variant="caption">Tags</Typography>
                    {customerData.customerTags &&
                      customerData.customerTags.items.map(tag => (
                        <Chip
                          className={classes.chipTop}
                          key={tag.id}
                          label={tag.mappedEntityValue}
                        />
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.otherPanel} item lg={12} md={12} sm={12} xl={12} xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <CustomerMenus />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="caption">Billing address</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="caption">Business address</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const styledCustomer = withStyles(styles, { withTheme: true })(Customer);

// pass user context, app, and menu state from redux as props to the component
const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps, null)(styledCustomer);
