import React from 'react';

import NumberInput from './NumberInput';

const CostDetailInput = ({ meta, isEditable, record, onEdit }) => {
  const { dataKey } = meta;
  const value = record[dataKey];
  const onEditHandler = value => onEdit(dataKey, value);

  return (
    <NumberInput
      isEditable={isEditable}
      showPriceSymbol
      value={value}
      onEditHandler={onEditHandler}
    />
  );
};

export default CostDetailInput;
