import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const DELETE_INVENTORY_PARTS_FROM_VISIT = gql`
  mutation deleteInventoryPart($partitionKey: String!, $id: String!, $jobId: String) {
    deleteInventoryPart(partitionKey: $partitionKey, id: $id, jobId: $jobId) {
      id
    }
  }
`;

const serializer = ({ tenantId, visitId, inventoryPartId, jobId }) => ({
  visitId,
  variables: {
    partitionKey: tenantId,
    id: inventoryPartId,
    jobId
  }
});

const update = (cache, { data: { deleteInventoryPart } }, ref) => {
  cache.modify({
    id: `Visit:${ref.visitId}`,
    fields: {
      inventoryParts(existingInventoryPartsRef) {
        return {
          items: existingInventoryPartsRef.items.filter(
            item => item.__ref !== cache.identify(deleteInventoryPart)
          )
        };
      }
    }
  });
};

export const useDeleteInventoryPartsFromVisit = () => {
  return useExtendedMutation(DELETE_INVENTORY_PARTS_FROM_VISIT, {
    serializer,
    update
  });
};
