import React from 'react';

import FormPDF from 'components/FormSection/FormPDF';

export const processFormsData = async forms => {
  return Promise.all(
    forms.map(async form => {
      const fileName = `${form.name.replace(/\s/g, '_').replace(/\.[^/.]+$/, '')}.pdf`;

      return {
        ...form,
        document: <FormPDF data={form} withViewer={false} />,
        fileName,
        remote: false,
        type: 'application/pdf'
      };
    })
  );
};
