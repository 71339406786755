import React, { useMemo } from 'react';

import { ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { Divider, useTheme } from '@material-ui/core';

import JobCloseoutInformation from 'scenes/JobCloseout/InformationSection';
import QuotedInventoryCosts from 'scenes/JobCloseout/InventoryCosts';

import { AdditionalInfo } from '../AdditionalInfo';
import { JobCloseoutTypes } from '../constants';
import { CustomerSignatures } from '../CustomerSignatures';
import NotesTable from '../NotesTable';
import { getStyles } from '../styles';
import JobCloseoutTasksSection from '../TasksSection';
import TechnicianNotesTable from '../TechnicianNotesTable';

import {
  VisitDescriptionTable,
  VisitFieldOrdersTable,
  VisitLaborTable,
  VisitReceiptsTable,
  VisitSummaryTable
} from '../VisitsTables';

import ActionBar from './ActionBar';

export const MODES = {
  VIEW_MULTIPLE_VISITS: 'VIEW_MULTIPLE_VISITS',
  VIEW_SINGLE_VISIT: 'VIEW_SINGLE_VISIT'
};

const JobCloseoutVisitsTab = ({
  allVisits,
  refetchJob,
  visits,
  setSpecificVisits,
  jobData,
  JobCloseoutType,
  companyTimezone,
  isLoading
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const amountOfVisits =
    visits.length +
    visits.map(visit => visit.nonVisitEvents?.items?.length || 0).reduce((a, b) => a + b, 0);

  const entityTypeName = JobCloseoutType === JobCloseoutTypes.MAINTENANCE ? 'Maintenance' : 'Job';
  const mode = useMemo(
    () => (amountOfVisits === 1 ? MODES.VIEW_SINGLE_VISIT : MODES.VIEW_MULTIPLE_VISITS),
    [amountOfVisits]
  );

  if (!amountOfVisits && !isLoading) return 'No Visits'; // should not be possible

  return (
    <ThemeProvider>
      <ActionBar
        allVisits={allVisits}
        isLoading={isLoading}
        jobId={jobData.id}
        mode={mode}
        refetchJob={refetchJob}
        setSpecificVisits={setSpecificVisits}
        visits={visits}
      />
      <JobCloseoutInformation isLoading={isLoading} jobData={jobData} visitTabDisplay />
      <NotesTable
        displayEntityType={entityTypeName}
        isLoading={isLoading}
        job={jobData}
        notes={jobData?.notes?.items || []}
        refetchJob={refetchJob}
      />

      <VisitDescriptionTable isLoading={isLoading} visits={visits} />
      <VisitSummaryTable
        isLoading={isLoading}
        isMultiVisits={mode === MODES.VIEW_MULTIPLE_VISITS}
        visits={visits}
      />
      <TechnicianNotesTable
        isLoading={isLoading}
        isMultiVisits={mode === MODES.VIEW_MULTIPLE_VISITS}
        visits={visits}
      />
      <Divider css={styles.divider} />
      <Typography variant={TV.L} weight={TW.BOLD}>
        Labor &amp; Material
      </Typography>
      <VisitLaborTable
        isLoading={isLoading}
        isMultiVisits={mode === MODES.VIEW_MULTIPLE_VISITS}
        visits={visits}
      />
      <VisitFieldOrdersTable isLoading={isLoading} visits={visits} />
      <QuotedInventoryCosts
        isLoading={isLoading}
        isMultiVisits={mode === MODES.VIEW_MULTIPLE_VISITS}
        isVisitsTab
        jobData={jobData}
        refetchJob={refetchJob}
        visits={visits}
      />
      <VisitReceiptsTable
        isLoading={isLoading}
        isMultiVisits={mode === MODES.VIEW_MULTIPLE_VISITS}
        jobData={jobData}
        visits={visits}
      />
      <Divider css={styles.divider} />
      <AdditionalInfo
        companyTimezone={companyTimezone}
        isLoading={isLoading}
        isMultiVisits={mode === MODES.VIEW_MULTIPLE_VISITS}
        job={jobData}
        visits={visits}
      />
      <JobCloseoutTasksSection
        companyTimezone={companyTimezone}
        isLoading={isLoading}
        isMultiVisits={mode === MODES.VIEW_MULTIPLE_VISITS}
        job={jobData}
        refetchJob={refetchJob}
        visits={visits}
      />
      <CustomerSignatures companyTimezone={companyTimezone} job={jobData} visits={visits} />
    </ThemeProvider>
  );
};

export default JobCloseoutVisitsTab;

JobCloseoutVisitsTab.defaultProps = {
  isLoading: false
};
