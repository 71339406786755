export const FileDataDispatchActions = {
  INITIALIZE: 'initialize',
  ADD: 'add',
  ADD_ALL: 'addAll',
  REMOVE: 'remove'
};

export const AttachmentOptions = {
  COMBINE: 'combine',
  SEPARATE: 'separate'
};
