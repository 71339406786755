import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  dragHandler: {
    padding: '0px',
    borderRadius: '0px',
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  accordionSummary: {
    height: 32,
    minHeight: 32,
    '& [class*="MuiAccordionSummary-content"]': {
      margin: theme.spacing(0)
    }
  },
  taskName: {
    width: '100%',
    '& [class^="MuiInputBase-root"]': {
      background: 'none',
      border: 'none'
    }
  },
  taskDescription: {
    width: '100%'
  },
  expandCollapseIcon: {
    color: theme.palette.grayscale(20),
    margin: 0,
    '&[class*="MuiSvgIcon-root"]': {
      fontSize: 24
    }
  },
  moreButton: {
    padding: theme.spacing(1)
  },
  addTaskBtn: {
    color: theme?.palette?.support?.green?.dark,
    marginTop: theme.spacing(1)
  },
  partTable: {
    '& [class*="MuiCheckbox-root"]': {
      margin: 0
    },
    '& [class*="-Header"] > [class*="-HeaderColumn"]': {
      justifyContent: 'center'
    },
    '& [class*="-Row"] > [class*="-Column"]': {
      alignItems: 'center'
    },
    '& [class*="-Row"] > [class*="-Column"] [class*="MuiTypography-root"]': {
      width: '100%'
    },
    '& [class*="-Row"] > [class*="-Column"] [class*="Mui-error"]': {
      border: `1px solid ${theme?.palette?.error?.main}`
    }
  }
}));

export const useModalStyles = makeStyles(theme => ({
  skipCosts: {
    marginRight: theme.spacing(1)
  },
  toolbar: {
    color: theme.palette.text.primary
  },
  modalContent: {
    maxWidth: 'calc(100% - 48px)'
  }
}));

export const getDraggableStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  paddingTop: '16px',
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'none',

  // styles we need to apply on draggables
  ...draggableStyle
});
