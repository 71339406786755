import gql from 'graphql-tag';

import { updateWatchedNonVisitQueries } from '@dispatch/Dispatch.utils';
import { nonVisitDetailsFragment } from '@dispatch/fragments';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { optimisticRecurringNonVisitEvents } from '../optimisticRecurringNonVisitEvents';

const ADD_RECURRING_BILLABLE_EVENTS = gql`
  mutation addRecurringBillableEvents($data: AddRecurringBillableEventInput!) {
    addRecurringBillableEvents(data: $data) {
        ${nonVisitDetailsFragment}
    }
  }
`;

const optimisticResponseFactory = data => ({
  addRecurringBillableEvents: optimisticRecurringNonVisitEvents(data),
  isOptimistic: true
});

const serializer = data => ({
  variables: {
    data
  }
});

const useAddRecurringBillableEvents = () => {
  return useExtendedMutation(ADD_RECURRING_BILLABLE_EVENTS, {
    serializer,
    optimisticResponseFactory,
    update: (cache, { data }) =>
      data.addRecurringBillableEvents?.forEach(event => updateWatchedNonVisitQueries(cache, event))
  });
};

export default useAddRecurringBillableEvents;
