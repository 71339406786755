import { makeStyles } from '@material-ui/core';

import { DispatchTheme, ElementSizes, ElementZIndex } from '@dispatch/Dispatch.styles';

const { headerContentHeight, mainNavHeight, rightDrawerWidth, globalBannerHeight } = ElementSizes;

export const useStyles = makeStyles(theme => ({
  fullScreenContainer: ({ shouldShowGlobalBanner }) => ({
    position: 'relative',
    height: `calc(100vh - ${mainNavHeight + (shouldShowGlobalBanner ? globalBannerHeight : 0)}px)`,
    margin: theme.spacing(3) * -1,
    color: theme.palette.text.dark,
    display: 'flex',
    overflow: 'hidden',
    '& *': {
      // normalize google maps style override
      fontFamily: 'Inter, sans-serif'
    },
    '& .MuiButton-label': {
      overflow: 'hidden'
    }
  }),
  header: {
    padding: theme.spacing(3),
    flexGrow: 0,
    flexShrink: 0
  },
  headerContents: {
    height: headerContentHeight,
    maxHeight: headerContentHeight,
    '& button *': {
      ...DispatchTheme.mixins.oneLineClamp
    }
  },
  board: {
    height: '1px',
    background: theme.palette.grayscale(95),
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '1px',
    userSelect: 'none',
    '& *': {
      outline: 'none !important'
    }
  },
  bottomTray: {
    userSelect: 'none',
    width: '100%',
    background: theme.palette.background.default,
    position: 'relative',
    flexGrow: 0,
    flexShrink: 0
  },
  dragger: {
    height: '4px',
    width: '100%',
    cursor: 'ns-resize',
    top: 0,
    position: 'absolute',
    borderTop: DispatchTheme.mixins.darkBorder
  },
  drawerContainer: {
    transition: DispatchTheme.mixins.transitionSpeed,
    backgroundColor: theme.palette.background.default,
    width: rightDrawerWidth,
    height: '100%',
    borderLeft: DispatchTheme.mixins.darkBorder,
    zIndex: ElementZIndex.rightDrawer,
    flexGrow: 0,
    flexShrink: 0
  },
  rightDrawer: {
    width: rightDrawerWidth
  },
  leftContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '1px',
    overflow: 'hidden'
  }
}));
