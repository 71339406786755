import React from 'react';

import { TV, TW, Typography } from '@BuildHero/sergeant';
import { useTheme } from '@material-ui/core';

export const InvoiceItemsEditedField = () => {
  const theme = useTheme();

  return (
    <Typography variant={TV.BASE} weight={TW.REGULAR}>
      <span css={{ color: theme.palette.support.yellow.dark }}>*</span>
      <span>&nbsp;</span>
      <span>Line items have been modified from an invoice</span>
    </Typography>
  );
};

export const LineItemWithAsterisk = ({ content }) => {
  const theme = useTheme();

  return (
    <Typography variant={TV.BASE} weight={TW.REGULAR}>
      <span>{content}</span>
      <span>&nbsp;</span>
      <span css={{ color: theme.palette.support.yellow.dark }}>*</span>
    </Typography>
  );
};
