import React from 'react';

import { jsx } from '@emotion/react';
import { Box, Typography } from '@material-ui/core';

import classes from '../styles';

export const Title = ({ options }) => (
  <Typography css={classes.title} variant="subtitle">
    {options.label}
  </Typography>
);

export const TitleSubText = ({ text }) => (
  <Typography css={classes.titleSubText} variant="body2">
    {text}
  </Typography>
);

export const TitlePostText = ({ text, display }) => {
  if (!display) return null;
  return (
    <Typography css={classes.titleSubText} variant="body2">
      <span style={{ color: '#959595' }}>Expiring in: </span> {text} <span> Days</span>
    </Typography>
  );
};

export const MarkUpDescription = () => (
  <Box alignItems="center" display="flex">
    <Typography css={classes.markUpDescription} variant="body2">
      <span>Sub Markup</span> <span>(On Invoice Total)</span>
    </Typography>
  </Box>
);
