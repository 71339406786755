import React, { useMemo } from 'react';

import { Edit, LaunchOutlined, PictureAsPdfOutlined } from '@material-ui/icons';

import {
  actionButtonColumn,
  tableDateTimeFormatter,
  tableEmptyValueFormatter
} from 'components/WrapTable';

import FormModalLink from '../FormModalLink';

export const useFormTableColumns = ({
  setFormData,
  setFormModalOpen,
  setPDFDialogOpen,
  setFormModalMode,
  editable
}) => {
  return useMemo(
    () => [
      {
        field: 'id',
        flex: 1,
        hide: true
      },
      {
        field: 'name',
        flex: 2,
        headerName: 'Name',
        justify: 'center',
        noWrap: true,
        renderCell: ({ row }) => <FormModalLink formData={row} />
      },
      {
        field: 'description',
        flex: 2,
        headerName: 'Notes',
        justify: 'center',
        valueGetter: ({ row }) => row?.formDataJson?.description ?? '',
        valueFormatter: tableEmptyValueFormatter
      },
      {
        field: 'lastUpdatedBy',
        flex: 2,
        justify: 'center',
        headerName: 'Last Updated By',
        valueFormatter: tableEmptyValueFormatter
      },
      {
        field: 'lastUpdatedDateTime',
        flex: 2,
        justify: 'center',
        headerName: 'Last Updated',
        valueFormatter: tableDateTimeFormatter
      },
      actionButtonColumn({
        actions: [
          {
            icon: LaunchOutlined,
            label: 'View',
            onClick: form => {
              setFormData(form);
              setFormModalMode('view');
              setFormModalOpen(true);
            }
          },
          {
            icon: PictureAsPdfOutlined,
            label: 'PDF',
            onClick: form => {
              setFormData(form);
              setPDFDialogOpen(true);
            }
          },
          editable && {
            icon: Edit,
            label: 'Edit',
            onClick: form => {
              setFormData(form);
              setFormModalMode('edit');
              setFormModalOpen(true);
            }
          }
        ].filter(Boolean)
      })
    ],
    [setFormData, setFormModalOpen, setPDFDialogOpen, editable]
  );
};
