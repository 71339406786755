import React, { useState } from 'react';

import { ThemeProvider } from '@BuildHero/sergeant';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import ImagePreview from '../ImagePreview';

const useStyles = makeStyles(theme => ({
  label: {
    color: theme.palette.grayscale(60),
    fontSize: 10,
    letterSpacing: 0.01,
    fontWeight: 'normal',
    textTransform: 'uppercase',
    lineHeight: '14px',
    marginBottom: '0.35em',
    marginRight: '3.5px'
  },
  content: {
    fontFamily: theme.typography.fontFamily,
    color: '#333333',
    fontSize: 14,
    letterSpacing: '-0.03em',
    fontWeight: 'normal',
    lineHeight: '16px',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'zero' on, 'salt' on, 'ss01' on"
  }
}));

const VendorDocumentNumberImage = ({ form, field, options, style, nowrap }) => {
  const classes = useStyles();
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const { onEdit, onDelete, disableEdit } = options;
  const {
    vendorDocumentAttachmentId,
    approvalStatus,
    vendorDocumentNumber,
    invoiceAmount
  } = form?.values;

  return (
    <ThemeProvider>
      <Typography className={classes.label} variant="caption">
        {options.label}
      </Typography>
      {vendorDocumentAttachmentId ? (
        <Typography
          className={classes.content}
          noWrap={nowrap}
          style={{ textDecoration: 'underline', cursor: 'pointer', ...style }}
          onClick={() => setOpenImagePreview(true)}
        >
          {field.value}
        </Typography>
      ) : (
        <Typography className={classes.content} noWrap={nowrap} style={{ ...style }}>
          {field.value}
        </Typography>
      )}
      <ImagePreview
        attachmentId={vendorDocumentAttachmentId}
        disableEdit={disableEdit}
        handleClose={() => setOpenImagePreview(false)}
        open={openImagePreview}
        parentData={{
          approvalStatus,
          vendorDocumentNumber,
          invoiceAmount
        }}
        onDelete={onDelete}
        onEdit={onEdit}
      />
    </ThemeProvider>
  );
};

export default VendorDocumentNumberImage;
