import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';

import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { addAttachmentToServiceAgreement } from 'scenes/ServiceAgreements/DetailView/service';
import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';

import { getUniqueName } from 'utils';

import Attacher from './Attacher';

import { attachmentTypes } from './constants';

const storageService = new StorageService();
const fileUpload = (tenantId, attachments, type) => {
  try {
    return Promise.all(
      attachments.map(async attachment => {
        if (attachment?.fileUrl?.startsWith('blob')) {
          const rawUrl = await storageService.uploadFile(
            attachment.blob,
            getUniqueName(tenantId, attachment.fileName),
            null,
            attachment.type
          );

          // id from storage service conflicts with database id - remove it
          const { id, ...remainder } = attachment;
          return { ...remainder, remote: false, fileUrl: storageService.getFile(rawUrl), type };
        }

        return { ...attachment, remote: true };
      })
    );
  } catch (err) {
    Logger.error(err);
    return [];
  }
};

const Attachments = React.forwardRef((props, ref) => {
  const { data, saveData } = props;
  const [contracts, setContracts] = useState(data?.contracts ?? []);
  const [fileAttachments, setFileAttachments] = useState(data?.attachments?.items ?? []);
  const [attachers, setAttachers] = useState([]);
  const user = useSelector(s => s.user);

  const onSave = useCallback(
    async saId => {
      if (!contracts.length && !fileAttachments.length) {
        return;
      }

      const inputs = await Promise.all([
        fileUpload(user.tenantId, contracts, attachmentTypes.CONTRACT),
        fileUpload(user.tenantId, fileAttachments, attachmentTypes.ATTACHMENT)
      ]);

      await Promise.all(
        inputs
          .flat()
          .filter(x => !x.remote)
          .map(x => addAttachmentToServiceAgreement(saId, x, () => {}))
      );

      setContracts(contracts.map(x => ({ ...x, remote: true })));
      setFileAttachments(fileAttachments.map(x => ({ x, remote: true })));
    },
    [contracts, fileAttachments, user]
  );

  useImperativeHandle(ref, () => ({ onSave }));

  useEffect(
    () =>
      setAttachers([
        {
          limit: 1,
          title: 'Upload Contract',
          data: contracts,
          updateFileArray: setContracts,
          multiple: false
        },
        {
          limit: 0,
          title: 'Upload Attachments',
          data: fileAttachments,
          updateFileArray: setFileAttachments,
          multiple: true
        }
      ]),
    [contracts, fileAttachments, setContracts, setFileAttachments]
  );

  useEffect(() => {
    saveData(oldData => ({
      ...oldData,
      contracts,
      fileAttachments
    }));
  }, [contracts, fileAttachments, saveData]);

  return (
    <div ref={ref}>
      {attachers.map((attach, index) => (
        <Attacher
          data={attach.data}
          // eslint-disable-next-line react/no-array-index-key
          key={`attacher_${index}_${attach.title.replaceAll(' ', '')}`}
          limit={attach.limit}
          multiple={attach.multiple}
          title={attach.title}
          updateFileArray={attach.updateFileArray}
        />
      ))}
    </div>
  );
});

Attachments.propTypes = {
  data: PropTypes.object.isRequired,
  saveData: PropTypes.func.isRequired
};

Attachments.defaultProps = {};

export default Attachments;
