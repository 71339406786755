import React, { useState } from 'react';

import { MoreButton } from '@BuildHero/sergeant';

import XGrid from 'components/XGrid';

import { GET_INVENTORY_WAREHOUSES } from './query';

const columns = [
  {
    field: 'name',
    headerName: 'Warehouse Name',
    width: 300
  },
  {
    field: 'truckEmployees', // @TODO: fix
    headerName: 'Technicians',
    width: 300
  },
  {
    field: 'productBundle', // @TODO: fix
    headerName: 'Inventory Template',
    width: 300
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 300
  },
  {
    field: '',
    headerName: '',
    width: 20,
    flex: 1,
    renderCell: () => (
      <MoreButton options={[{ label: 'Edit' }]} style={{ backgroundColor: 'inherit' }} />
    )
  }
];

const InventoryWarehouseTable = props => {
  const [selectedIds, setSelectedIds] = useState([]);

  return (
    <XGrid
      checkboxSelection
      columns={columns}
      disableSelectionOnClick
      entityTypeName="Truck" // @TODO: use new entity
      query={GET_INVENTORY_WAREHOUSES}
      tableName="InventoryWarehousesXGrid"
      onSelectionModelChange={setSelectedIds}
      {...props}
    />
  );
};

export default InventoryWarehouseTable;
