import moment from 'moment';

import { ElementSizes } from '@dispatch/Dispatch.styles';
import { dragOffset } from '@dispatch/dnd';

const { techCellWidth, cellWidth } = ElementSizes;

export const selectSnappedOffset = (scrollOffset, cursorOffset, dragOffsetX) => {
  const timeOffset = scrollOffset + cursorOffset - techCellWidth - (dragOffsetX ?? dragOffset.x);
  return (Math.round((timeOffset / cellWidth) * 4) / 4) * cellWidth;
};

export const selectUnixFromOffset = (offset, day) => {
  const offsetInMin = (offset / cellWidth) * 60;

  return moment(day)
    .startOf('day')
    .add(offsetInMin, 'minutes')
    .unix();
};

export const selectLaneHeight = (i, itemData) => {
  if (i === 0) return ElementSizes.stickyHeaderHeight;

  let maxPosition = 0;

  if (itemData[i]?.techEvents) {
    itemData[i].techEvents.forEach(event => {
      if (event.position > maxPosition) maxPosition = event.position;
    });
  }

  return (maxPosition + 1) * ElementSizes.cellHeight;
};
