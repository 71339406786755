import gql from 'graphql-tag';
import { useSelector } from 'react-redux';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { useSnackbar } from 'customHooks/useSnackbar';
import { logErrorWithCallback } from 'utils';

export const propertyAssetFragment = `
  id
  entityType
  assetName
  isActive
  make
  modelNumber
  serialNo
  parentId
  imageUrl
  installDate
  location
  propertyZone
  version
  internalIdentifier
  ownerIdentifier
  assetType(entityConnection: "AssetType") {
    id
    tagName
  }
  mergedTaskTemplateChecklists {
    id
    name
    assetTypeId
    isDefault
    isActive
  }
`;

const customerPropertyFragment = `
  id
  entityType
  companyName
  isActive
  customerReps(entityConnection: "CustomerRep") {
    items {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      invertedSortKey
      hierarchy
      entityType
      mappedEntityId
      mappedEntity {
        ... on CustomerRep {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          name
          firstName
          lastName
          email
          cellPhone
          landlinePhone
          isSmsOptOut
          bestContact
          contactRole
        }
      }
    }
  }
  companyAddresses(entityConnection: "CompanyAddress") {
    items {
      id
      addressLine1
      addressLine2
      state
      city
      zipcode
      addressType
    }
  }
  propertyAssets(entityConnection: "PropertyAsset") {
    items {
      ${propertyAssetFragment}
    }
  }
`;

const customerPropertyWithChecklistsFragment = `
  ${customerPropertyFragment}
  mergedTaskTemplateChecklists {
    id
    name
    assetTypeId
    isDefault
    isActive
  }
`;

export const getServiceAgreementById = gql`
  query serviceAgreementId($partitionKey: String, $id: String) {
    serviceAgreement(partitionKey: $partitionKey, id: $id) {
      id
      tenantId
      status
      agreementName
      agreementNumber
      startDate
      endDate
      version
      paymentTerms
      annualContractValue
      totalContractValue
      termPrice
      firstBillDate
      customerPoNumber
      isActive
      pricebookId
      departmentId
      soldById
      attachments {
        items {
          id
          type
          fileName
          comment
          fileUrl
          fileSize
          customFileName
          version
          createdBy
          createdDate
          createdDateTime
          lastUpdatedBy
          lastUpdatedDateTime
        }
      }
      contractPdfUrl
      customerId
      customer {
        id
        customerName
        customerProperties {
          items {
            ${customerPropertyFragment}
          }
        }
      }
      billingType
      billingCustomerId
      billingCustomer {
        id
        customerName
      }
      rateCardJSON
      customerProperties {
        items {
          ${customerPropertyWithChecklistsFragment}
        }
      }
      propertyAssets {
        items {
          ${propertyAssetFragment}
        }
      }
    }
  }
`;

const useServiceAgreement = ({ serviceAgreementId, ...options }) => {
  const user = useSelector(state => state.user);
  const snackbar = useSnackbar();

  const { data, loading, error, refetch } = useExtendedQuery(getServiceAgreementById, {
    variables: {
      partitionKey: user.tenantId,
      id: serviceAgreementId
    },
    skip: !serviceAgreementId,
    transform: result => result?.serviceAgreement,
    onError: err =>
      logErrorWithCallback(
        err,
        snackbar,
        'Unable to fetch Service Agreement, please try again later'
      ),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options
  });

  return [data || {}, loading, error, refetch];
};

export default useServiceAgreement;
