/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';

import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import _ from 'lodash';
import CreatableSelect from 'react-select/creatable';

import { CommonService } from 'services/core';

import styles from './styles';

// To be shared across mutliple function components. React-select will not share the orign component props across
// set in render of the component
let createLabelText;

function formatCreateLabel(inputValue, createLabel) {
  createLabelText = `(${createLabel})` || '(New rep)';
  return (
    <Typography color="textSecondary">
      {inputValue} {createLabelText}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      component="div"
      selected={props.isFocused}
      style={{
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.29,
        letterSpacing: 'normal',
        color: '#3f3f3f',
        zIndex: 3
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      className={props.selectProps.classes.placeholder}
      color="textSecondary"
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      deleteIcon={<CancelIcon {...props.removeProps} />}
      label={props.children}
      tabIndex={-1}
      onDelete={props.removeProps.onClick}
    />
  );
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputLabelProps={{ shrink: !!props.selectProps.value || !!props.selectProps.inputValue }}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      variant="outlined"
      {...props.selectProps.textFieldProps}
    />
  );
}

function Menu(props) {
  return (
    <Paper className={props.selectProps.classes.paper} square {...props.innerProps}>
      {props.children}
      <MenuItem
        component="div"
        style={{
          fontFamily: ['Inter', 'sans-serif'].join(','),
          fontSize: 14,
          fontWeight: 'normal',
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: 1.29,
          letterSpacing: 'normal',
          borderTop: '2px solid',
          borderColor: '#f2f2f2',
          color: '#3f3f3f',
          zIndex: 3
        }}
        onClick={() => props.setValue('#new#')}
      >
        {`Create ${createLabelText || ''}`}
      </MenuItem>
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  ClearIndicator: () => '',
  IndicatorSeparator: () => '',
  DropdownIndicator: () => <ExpandMoreIcon style={{ marginTop: -10, color: '#646464' }} />
};

class DropdownCreatable extends Component {
  constructor(props) {
    super(props);
    this.CommonService = new CommonService();
  }

  handleChange = async (newValue, actionMeta) => {
    const { createActionFromOption, onChange } = this.props;
    if (newValue === '#new#') {
      // Click coming from Menu(props) function, menu item outside of the function
      await createActionFromOption({ label: '', value: '' });
    } else if (actionMeta.action === 'create-option' && createActionFromOption) {
      await createActionFromOption(newValue);
    } else if (newValue) {
      onChange(newValue.value);
    }
  };

  render() {
    const { name, helperText, label, options, classes, form } = this.props;
    const displayDataArray = options.valueSet || [];
    form.errors = form.errors || {};
    const selectStyles = {
      menu: cstyles => ({
        ...cstyles,
        zIndex: 5,
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.29,
        letterSpacing: 'normal',
        color: '#3f3f3f',
        maxHeight: 160
      }),
      option: (customstyles, { isFocused, isSelected }) => ({
        ...customstyles,
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: isFocused ? '#f2f2f2' : isSelected ? '#f2f2f2' : null,
        // eslint-disable-next-line no-nested-ternary
        color: isFocused ? '#3f3f3f' : isSelected ? '#3f3f3f' : null,
        maxHeight: 160
      }),
      menuList: custstyles => ({
        ...custstyles,
        left: 0,
        top: 0,
        maxHeight: 160
      })
    };

    const defaultValueList = displayDataArray.filter(item => form.values[name] === item.value);
    let defaultValue = defaultValueList.length > 0 ? defaultValueList[0] : '';
    if (!defaultValue) {
      defaultValue = !_.isEmpty(displayDataArray) ? displayDataArray[0] : '';
    }

    createLabelText = `(${this.props.createLabel})` || '(New rep)';

    return (
      <>
        <FormControl
          className={classes.formControl}
          error={(form.errors && form.errors[name] && form.errors[name].length > 0) || false}
          fullWidth
          variant="outlined"
        >
          <CreatableSelect
            classes={classes}
            components={components}
            formatCreateLabel={value => formatCreateLabel(value, this.props.createLabel)}
            isClearable
            name={name}
            options={displayDataArray}
            placeholder=""
            styles={selectStyles}
            textFieldProps={{
              label: label || '',
              error: form.errors && form.errors[name] && form.errors[name].length > 0
            }}
            value={defaultValue}
            onChange={this.handleChange}
          />
          <FormHelperText>{helperText || ''}</FormHelperText>
        </FormControl>
      </>
    );
  }
}

const styledDropdownCreatable = withStyles(styles)(DropdownCreatable);
export default styledDropdownCreatable;
