import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useLazyQuery } from '@apollo/client';

import { getMainDefinition } from '@apollo/client/utilities';

import { handleError } from '@dispatch/Dispatch.utils';

import { snackbarOn as snackbarOnAction } from '../redux/actions/globalActions';
import { dispatch } from '../redux/store';

const snackbarOn = (mode, message, errorLog) => dispatch(snackbarOnAction(mode, message, errorLog));

/**
 * Wrapper around @apollo/client useLazyQuery that adds options for transforming and resolving data.
 *
 * @param {string} query - The graphQl query string.
 * @param {Object?} options - An extended [useLazyQuery options object](https://www.apollographql.com/docs/react/api/react/hooks/#options-1).
 *
 * @param {function} options.transform - a function that transforms the resolved data from a successful mutation.
 * @param {Object} options.subscribeToMore - an object to be passed to the query's subscribeToMore function. [see docs](https://www.apollographql.com/docs/react/data/subscriptions/#subscribing-to-updates-for-a-query)
 * @param {any} options.loadingData - will be returned as data when the query is loading
 * @param {any} options.errorData - will be returned as data when there is an error
 * @param {any} options.defaultData - will be returned if data is falsy. errorData and loadingData take priority
 * @param {boolean} options.hideError - suppress notification on error
 * @param {array} options.depends - an array of dependencies that will trigger a request if changed. Much like a `useEffect` deps array.
 * @param {function} options.serializer - a function that transforms what is provided to the query function prior to calling it
 * @param {boolean} options.skip - when true refetch calls made based on options.depends will be ignored
 * @param {function} options.onRequest - (variables, snackbarOn) => void
 * @param {function} options.onCompleted - (data, snackbarOn) => void
 * @param {function} options.onSuccess - (transformedData, snackbarOn) => void
 * @param {function} options.onError - (err, snackbarOn) => void
 * @param {function} options.onResponse - ({ err, data }, snackbarOn) => void | fires on success or error
 *
 * @returns [queryResponse](https://www.apollographql.com/docs/react/api/react/hooks/#result-1) { data: any, loading: boolean, error: Error, called: boolean, client: ApolloClient }
 */
const useExtendedLazyQuery = (
  query,
  {
    transform,
    loadingData,
    errorData,
    defaultData,
    depends,
    hideError,
    serializer,
    skip,
    subscribeToMore,
    onError,
    ...options
  } = {}
) => {
  const [trigger, res] = useLazyQuery(query, {
    ...options,
    onCompleted: data => {
      const transformedData = transform?.(data, options.variables) || data;
      options.onCompleted?.(data, snackbarOn);
      options.onSuccess?.(transformedData, snackbarOn);
      options.onResponse?.({ data: transformedData }, snackbarOn);
    },
    onError: err => {
      options.onError?.(err, snackbarOn);
      options.onResponse?.({ err }, snackbarOn);
      handleError({ err, errContext: getMainDefinition(query)?.name?.value });
    }
  });

  const queryFn = useCallback(
    queryOptions => {
      try {
        return trigger({
          ...(serializer?.(queryOptions) || queryOptions)
        });
      } catch (err) {
        onError?.(err);
        const queryName = getMainDefinition(query)?.name?.value;
        handleError({ err, errContext: queryName });
      }
    },
    [trigger, serializer, query, onError]
  );

  const fetchMoreFn = useMemo(() => {
    if (!res.fetchMore) return;
    return queryOptions => {
      try {
        return res.fetchMore?.({
          ...(serializer?.(queryOptions) || queryOptions)
        });
      } catch (err) {
        onError?.(err);
        const queryName = getMainDefinition(query)?.name?.value;
        handleError({ err, errContext: queryName });
      }
    };
  }, [res, serializer, query, onError]);

  const wasLoading = useRef(false);

  if (res.loading && !wasLoading.current) {
    options.onRequest?.(options.variables, snackbarOn);
    wasLoading.current = true;
  }

  if (!res.loading && wasLoading.current) wasLoading.current = false;

  useEffect(() => {
    if (depends && !skip) queryFn({ variables: options.variables });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, depends || []);

  useEffect(() => {
    const unsubscribe = subscribeToMore ? res?.subscribeToMore?.(subscribeToMore) : null;

    return () => unsubscribe?.();
  }, []);

  const transformedData = useMemo(() => {
    return res.data && transform ? transform(res.data) : res.data;
  }, [res.data, transform]);

  const data = (() => {
    if (res.loading && loadingData) return loadingData;
    if (res.error && errorData) return errorData;

    return transformedData || defaultData;
  })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => [queryFn, { ...res, data, fetchMore: fetchMoreFn }], [
    queryFn,
    fetchMoreFn,
    res.data,
    res.loading,
    res.error
  ]);
};

export default useExtendedLazyQuery;
