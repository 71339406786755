import React from 'react';

import { Switch, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const style = {
  row: {
    height: 25,
    marginTop: 5,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  formName: {
    display: 'flex'
  },
  closeButton: {
    width: 14,
    height: 14
  },
  label: {
    paddingTop: 5,
    cursor: 'pointer'
  }
};

const FormLineItem = ({ option, selectedOptions, updateAssetForms, onClick, disabled }) => (
  <ThemeProvider key={option.id}>
    <div css={style.row}>
      <div css={style.formName}>
        <IconButton
          aria-label="close"
          disabled={disabled}
          onClick={() => {
            const newItems = selectedOptions.filter(it => it.id !== option.id);
            updateAssetForms(newItems);
          }}
        >
          <CloseIcon css={style.closeButton} />
        </IconButton>

        <Typography
          css={style.label}
          underlined
          variant={TV.BASE}
          weight={TW.MEDIUM}
          onClick={() => onClick({ option })}
        >
          {option.label}
        </Typography>
      </div>
    </div>
  </ThemeProvider>
);

export default FormLineItem;
