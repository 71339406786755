import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, ButtonType, FieldType, Modal, SgtForm, ThemeProvider } from '@BuildHero/sergeant';

import { logErrorWithCallback } from 'utils';

import { AGREEMENT_ACTIONS } from '../constants';
import { transitionServiceAgreementMutation } from '../queries';

const layout = {
  layoutsList: 'default',
  layouts: {
    default: {
      contents: [
        {
          props: {
            style: {
              flexDirection: 'row',
              width: 496
            }
          },
          contents: [
            {
              component: 'Input',
              source: 'agreementName',
              required: true,
              props: {
                label: 'Agreement Name',
                type: FieldType.TEXT,
                style: { flex: '1 1 100%' }
              }
            }
          ]
        }
      ]
    }
  }
};

export const SAEditNameModal = ({
  open,
  agreementName,
  agreementInfo,
  handleClose,
  updateAgreementName,
  snackbar
}) => {
  const [service, setService] = useState();
  const [saving, setSaving] = useState(false);
  const [updateServiceAgreement] = useMutation(transitionServiceAgreementMutation);

  const handleComplete = async formData => {
    setSaving(true);
    await updateServiceAgreement({
      variables: {
        data: {
          id: agreementInfo.id,
          agreementName: formData.agreementName
        },
        action: AGREEMENT_ACTIONS.UPDATE_INVOICE_SETTINGS
      },
      onError: error =>
        logErrorWithCallback(error, snackbar, 'Unable to save, please try again later')
    });
    updateAgreementName(formData.agreementName);
    setSaving(false);
    handleClose();
  };
  if (!open) return null;
  return (
    <ThemeProvider>
      <Modal
        actions={
          <Button
            fullWidth
            loading={saving}
            type={ButtonType.PRIMARY}
            onClick={() => service.submit()}
          >
            Save
          </Button>
        }
        open={open}
        title="Edit Agreement Name"
        onClose={handleClose}
      >
        <SgtForm
          configuration={layout}
          initialValues={{ agreementName }}
          layout="default"
          onCreateService={setService}
          onSubmit={handleComplete}
        />
      </Modal>
    </ThemeProvider>
  );
};
