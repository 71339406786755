import React, { useState } from 'react';

import { InlineAlert, InlineAlertTypes } from '@BuildHero/sergeant';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { useSnackbar } from 'customHooks/useSnackbar';

import { ADDING_MAINTENANCE_JOBS_STATUS } from 'utils/constants';

import { AGREEMENT_ACTIONS } from '../../ServiceAgreements/DetailView/constants';
import { transitionServiceAgreement } from '../../ServiceAgreements/DetailView/service';

const UpcomingMaintenanceJobsInlineAlert = ({ agreementInfo, companyTimeZone }) => {
  const [isLoading, setIsLoading] = useState(false);
  const snackbar = useSnackbar();
  const history = useHistory();
  const {
    addingMaintenanceJobsStatus,
    upcomingMaintenanceJobs: {
      earliestDueDate,
      latestDueDate,
      nextGenerationDate,
      nextStartDate,
      number
    }
  } = agreementInfo;

  let upcomingMaintenanceJobsMessage = '';
  const format = 'MMM D, YYYY';
  const upcoming = moment.tz(moment.unix(nextGenerationDate), companyTimeZone).format(format);
  const start = moment.tz(moment.unix(earliestDueDate), companyTimeZone).format(format);
  const end = moment.tz(moment.unix(latestDueDate), companyTimeZone).format(format);
  const maintenance = number > 1 ? 'maintenances' : 'maintenance';

  if (addingMaintenanceJobsStatus === ADDING_MAINTENANCE_JOBS_STATUS.FAILED) {
    upcomingMaintenanceJobsMessage = `Failed to create ${number} ${maintenance} for ${start} - ${end}, please contact BuildOps Support`;
  } else {
    upcomingMaintenanceJobsMessage = `${number} more ${maintenance} will be created for ${start} - ${end} on ${upcoming}`;
  }

  const getAlertType = () => {
    // Note: covers immediate feedback and also if user reloads page
    if (isLoading || addingMaintenanceJobsStatus === ADDING_MAINTENANCE_JOBS_STATUS.ADDING) {
      return InlineAlertTypes.BLUE;
    }
    if (addingMaintenanceJobsStatus === ADDING_MAINTENANCE_JOBS_STATUS.DONE) {
      return InlineAlertTypes.GREY;
    }
    if (addingMaintenanceJobsStatus === ADDING_MAINTENANCE_JOBS_STATUS.FAILED) {
      return InlineAlertTypes.RED;
    }
    return InlineAlertTypes.GREY;
  };

  const createNextMaintenanceJobs = async () => {
    setIsLoading(true);
    await transitionServiceAgreement(
      AGREEMENT_ACTIONS.GENERATE_MAINTENANCE_RECORDS,
      {
        id: agreementInfo.id,
        startDate: nextStartDate
      },
      agreementInfo.tenantId,
      snackbar
    );
    // page reload should clear isLoading
    history.push(`/serviceAgreement/view/${agreementInfo.id}`);
  };

  // Status will be ADDING if you load the page mid-creation
  const handleOnClick =
    isLoading ||
    addingMaintenanceJobsStatus === ADDING_MAINTENANCE_JOBS_STATUS.FAILED ||
    addingMaintenanceJobsStatus === ADDING_MAINTENANCE_JOBS_STATUS.ADDING
      ? () => {}
      : createNextMaintenanceJobs;

  return (
    <InlineAlert
      message={upcomingMaintenanceJobsMessage}
      type={getAlertType()}
      onClick={handleOnClick}
    />
  );
};

UpcomingMaintenanceJobsInlineAlert.propTypes = {
  agreementInfo: PropTypes.shape({
    addingMaintenanceJobsStatus: PropTypes.oneOf([
      ADDING_MAINTENANCE_JOBS_STATUS.DONE,
      ADDING_MAINTENANCE_JOBS_STATUS.ADDING,
      ADDING_MAINTENANCE_JOBS_STATUS.FAILED
    ])
  }).isRequired,
  companyTimeZone: PropTypes.string
};

UpcomingMaintenanceJobsInlineAlert.defaultProps = {
  companyTimeZone: ''
};

export default UpcomingMaintenanceJobsInlineAlert;
