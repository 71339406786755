import AmplifyService from 'services/AmplifyService';

import deletePurchaseOrderType from '../../graphql/PurchaseOrder/mutations/deletePurchaseOrderType';
import savePurchaseOrderTypeToCompany from '../../graphql/PurchaseOrder/mutations/savePurchaseOrderTypeToCompany';
import getPurchaseOrderTypesForCompany from '../../graphql/PurchaseOrder/queries/getPurchaseOrderTypesForCompany';

export default class PurchaseOrderService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
  }

  getPurchaseOrderTypesForCompany = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };
    const response = await this.api.query(getPurchaseOrderTypesForCompany, params);
    return response;
  };

  savePurchaseOrderTypeToCompany = async (partitionKey, data) => {
    const params = {
      partitionKey,
      data
    };
    const response = await this.api.mutate(savePurchaseOrderTypeToCompany, params);
    const formattedResponse = {
      data: {
        savePurchaseOrderTypes: response.data.savePurchaseOrderTypes[0]
      }
    };
    return formattedResponse;
  };

  deletePurchaseOrderType = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };
    return this.api.mutate(deletePurchaseOrderType, params);
  };
}
