import React, { useMemo } from 'react';

import { InlineAlert, InlineAlertTypes } from '@BuildHero/sergeant';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { QuoteStatus } from 'utils/AppConstants';

import QuotesUtils from '../../index.utils';

const StatusAlert = ({ status, rejectedReason, customerSignatures }) => {
  const getAlertColor = useMemo(() => {
    let color;
    switch (status) {
      case QuoteStatus.REJECTED:
        color = InlineAlertTypes.RED;
        break;
      case QuoteStatus.APPROVED:
        color = InlineAlertTypes.GREEN;
        break;
      case QuoteStatus.CANCELLED:
        color = InlineAlertTypes.ORANGE;
        break;
      default:
        color = InlineAlertTypes.GREEN;
    }
    return color;
  }, [status]);

  return (
    [
      QuoteStatus.REJECTED,
      QuoteStatus.APPROVED,
      QuoteStatus.CANCELLED,
      QuoteStatus.JOB_ADDED
    ].includes(status) && (
      <Grid style={{ padding: '12px 0' }}>
        <InlineAlert
          message={QuotesUtils.createStatusAlertMsg(rejectedReason, customerSignatures)}
          type={getAlertColor}
        />
      </Grid>
    )
  );
};

export default StatusAlert;

StatusAlert.propTypes = {
  customerSignatures: PropTypes.array,
  rejectedReason: PropTypes.string,
  status: PropTypes.string
};

StatusAlert.defaultProps = {
  customerSignatures: [],
  rejectedReason: '',
  status: ''
};
