import React from 'react';

import { object } from 'prop-types';

import { VisitStatuses } from '@dispatch/Dispatch.constants';
import { WarningIcon } from '@dispatch/icons';

import PreviewVisitCard from '../PreviewVisitCard';

import { useStyles } from './ConfirmContinueModalBody.styles';

const ConfirmContinueModalBody = ({ visit }) => {
  const classes = useStyles();
  return (
    <div className={classes.modalBody}>
      <div className={classes.modalTitle}>
        <img alt="warning" src={WarningIcon} /> Continue in New Visit
      </div>
      <div>
        The current <strong>On Hold</strong> visit will be set to <strong>Closed</strong>
      </div>
      <PreviewVisitCard
        visit={{
          ...visit,
          status: VisitStatuses.CLOSED.key
        }}
      />
      <div>A new visit will be created:</div>
      <PreviewVisitCard
        visit={{
          ...visit,
          visitNumber: (visit?.visitNumber || 0) + 1
        }}
      />
    </div>
  );
};

ConfirmContinueModalBody.propTypes = {
  visit: object.isRequired
};

export default ConfirmContinueModalBody;
