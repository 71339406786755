const constructLaborTypeGroupOptions = laborRates =>
  laborRates.map(option => ({
    label: option.name,
    value: `${option?.labourRateGroupId} - ${option?.labourTypeId}`
  }));

const fieldLayout = (source, label, fieldType, onChange, hourTypeId, placeholder = '', type) => ({
  options: {
    direction: 'column'
  },

  contents: [
    {
      component: {
        default: fieldType,
        edit: fieldType
      },
      source,
      options: {
        type,
        label,
        placeholder,
        isRequired: false,
        variant: 'standard',
        color: 'secondary',
        onChange: form => onChange(form, hourTypeId)
      }
    }
  ]
});

export const AddLaborForm = settings => ({
  fields: {
    id: {
      default: 'id'
    },
    totalCost: {
      default: 'totalCost'
    },
    totalPrice: {
      default: 'totalPrice'
    },
    quantity: {
      default: 'quantity'
    },
    markup: {
      default: 'markup'
    },
    unitCost: {
      default: 'unitCost'
    },
    unitPrice: {
      default: 'unitPrice'
    }
  },
  validation: {
    type: 'object',
    properties: {
      labourTypeId: {
        type: 'string'
      },
      totalCost: {
        type: 'string'
      },
      totalPrice: {
        type: 'string'
      },
      quantity: {
        type: 'string',
        nullable: true
      },
      markup: {
        type: 'string',
        nullable: true
      },
      unitPrice: {
        type: 'string',
        nullable: true
      }
    },
    required: ['labourTypeId', 'quantity', 'markup', 'unitPrice']
  },
  validationErrors: {
    labourTypeId: {
      required: 'Labor type is required.'
    },
    quantity: {
      required: 'Total quantity is required.'
    },
    markup: {
      required: 'Markup is required.'
    },
    unitPrice: {
      required: 'Average unit price is required.'
    }
  },

  layouts: {
    edit: {
      options: {
        pageSize: 'LETTER',
        fontFeatureSettings: "'tnum' on, 'lnum' on, 'zero' on, 'salt' on, 'ss01' on"
      },
      contents: [
        // 1st row: Labor Type Select
        {
          options: {
            size: 1,
            direction: 'row',
            paddingTop: 16,
            paddingRight: settings.index === 0 || settings.selectedLaborType.id ? 0 : 48,
            position: 'relative'
          },
          contents: [
            {
              component: {
                default: 'SelectInput',
                edit: 'SelectInput'
              },
              source: 'labourRateId',
              options: {
                label: 'Labor Type',
                placeholder: settings.enterpriseLaborCostingEnabled
                  ? 'Select Labor Rate Group - Labor Type'
                  : 'Select Labor Type',
                isRequired: false,
                variant: 'standard',
                color: 'secondary',
                inputOptions: constructLaborTypeGroupOptions(settings.laborRates)
              }
            }
          ]
        },
        // 2nd row: Description Input
        {
          options: {
            size: 1,
            direction: 'row',
            paddingTop: 16,
            position: 'relative'
          },
          contents: [
            {
              options: {
                direction: 'column'
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'TextInput'
                  },
                  source: 'description',
                  options: {
                    label: 'Description',
                    placeholder: 'Enter description',
                    variant: 'standard',
                    color: 'secondary'
                  }
                }
              ]
            }
          ]
        },
        // 3rd row: Section Headers
        {
          options: {
            size: 1,
            direction: 'row',
            paddingTop: 16
          },
          contents: settings.selectedLaborType?.labourTypeId
            ? [{ options: {}, component: 'SectionHeaders' }]
            : []
        },

        // Cost/Price Inputs
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                direction: 'row'
              },
              contents: [
                {
                  options: {
                    direction: 'column',
                    paddingRight: 48
                  },
                  contents:
                    settings.selectedLaborType?.quoteLineLaborPayrollHours?.map((type, i) => ({
                      options: {
                        size: 1,
                        direction: 'row',
                        paddingTop: 16
                      },

                      contents: [
                        fieldLayout(
                          `quoteLineLaborPayrollHours[${i}].unitCost`,
                          `${type.hourType} Rate`,
                          'CustomFieldWithLabel'
                        ),
                        fieldLayout(
                          `quoteLineLaborPayrollHours[${i}].hours`,
                          'Hours',
                          'TextInput',
                          settings.handleHoursChange,
                          type.payrollHourTypeId,
                          'Enter hours',
                          'numeric'
                        )
                      ]
                    })) || []
                },

                {
                  options: {
                    direction: 'column'
                  },
                  contents:
                    settings.selectedLaborType?.quoteLineLaborBillingHours?.map((type, i) => ({
                      options: {
                        size: 1,
                        direction: 'row',
                        paddingTop: 16
                      },
                      contents: [
                        fieldLayout(
                          `quoteLineLaborBillingHours[${i}].unitPrice`,
                          `${type.hourType} Rate`,
                          'CustomFieldWithLabel'
                        ),
                        fieldLayout(
                          `quoteLineLaborBillingHours[${i}].hours`,
                          'Hours',
                          'TextInput',
                          settings.handleHoursChange,
                          type.billingHourTypeId,
                          'Enter hours',
                          'numeric'
                        )
                      ]
                    })) || []
                }
              ]
            }
          ]
        },

        // Totals
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                direction: 'row'
              },
              contents: [
                {
                  options: {
                    direction: 'column',
                    paddingRight: 32
                  },
                  contents: settings.selectedLaborType?.quoteLineLaborPayrollHours
                    ? [
                        {
                          options: {
                            size: 1,
                            direction: 'row',
                            paddingTop: 16
                          },
                          contents: [fieldLayout('totalCost', 'Total Cost', 'CustomFieldWithLabel')]
                        }
                      ]
                    : []
                },

                {
                  options: {
                    direction: 'column'
                  },
                  contents: settings.selectedLaborType?.quoteLineLaborBillingHours
                    ? [
                        {
                          options: {
                            size: 1,
                            direction: 'row',
                            paddingTop: 16
                          },
                          contents: [
                            fieldLayout('totalPrice', 'Total Price', 'CustomFieldWithLabel')
                          ]
                        }
                      ]
                    : []
                }
              ]
            }
          ]
        },

        // Unit Averages
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                direction: 'row'
              },
              contents: [
                {
                  options: {
                    direction: 'column',
                    paddingRight: 16,
                    flex: '0 1 auto'
                  },
                  contents: settings.selectedLaborType?.quoteLineLaborPayrollHours
                    ? [
                        {
                          options: {
                            size: 1,
                            direction: 'row',
                            paddingTop: 16,
                            paddingRight: 16
                          },
                          contents: [
                            fieldLayout(
                              'unitCost',
                              'Average Unit Cost',
                              'CustomFieldWithLabel',
                              settings.handleUnitTotalsChange
                            )
                          ]
                        }
                      ]
                    : []
                },
                {
                  options: {
                    direction: 'column'
                  },
                  contents:
                    settings.selectedLaborType?.quoteLineLaborBillingHours &&
                    settings.isMarkupEnabled
                      ? [
                          {
                            options: {
                              size: 1,
                              direction: 'row',
                              paddingTop: 16,
                              paddingRight: 16
                            },
                            contents: [
                              fieldLayout(
                                'markup',
                                'Markup',
                                'TextInput',
                                settings.handleUnitTotalsChange,
                                null,
                                null,
                                settings.selectedLaborType.totalCost === 0 ? 'text' : 'percentage'
                              )
                            ]
                          }
                        ]
                      : []
                },
                {
                  options: {
                    direction: 'column'
                  },
                  contents:
                    settings.selectedLaborType?.quoteLineLaborBillingHours &&
                    settings.isMarginEnabled
                      ? [
                          {
                            options: {
                              size: 1,
                              direction: 'row',
                              paddingTop: 16,
                              paddingRight: 16
                            },
                            contents: [
                              fieldLayout(
                                'margin',
                                'Margin',
                                'TextInput',
                                settings.handleUnitTotalsChange,
                                null,
                                null,
                                settings.selectedLaborType.totalCost === 0 ? 'text' : 'percentage'
                              )
                            ]
                          }
                        ]
                      : []
                },

                {
                  options: {
                    direction: 'column'
                  },
                  contents: settings.selectedLaborType?.quoteLineLaborBillingHours
                    ? [
                        {
                          options: {
                            size: 1,
                            direction: 'row',
                            paddingTop: 16,
                            paddingRight: 16
                          },
                          contents: [
                            fieldLayout(
                              'unitPrice',
                              'Average Unit Price',
                              'CurrencyInput',
                              settings.handleUnitTotalsChange
                            )
                          ]
                        }
                      ]
                    : []
                },
                {
                  options: {
                    direction: 'column'
                  },
                  contents: settings.selectedLaborType?.quoteLineLaborBillingHours
                    ? [
                        {
                          options: {
                            size: 1,
                            direction: 'row',
                            paddingTop: 16
                          },
                          contents: [
                            fieldLayout(
                              'quantity',
                              'Total Quantity',
                              'TextInput',
                              settings.handleUnitTotalsChange
                            )
                          ]
                        }
                      ]
                    : []
                }
              ]
            }
          ]
        }
      ]
    }
  }
});
