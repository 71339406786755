import React from 'react';

import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CancelIcon from '@material-ui/icons/Cancel';
import classNames from 'classnames';
import * as R from 'ramda';

import Select, { createFilter } from 'react-select';

import ConfirmModal from 'components/Modal/ConfirmDialog';
import { CommonService } from 'services/core';
// import Grid from '@material-ui/core/Grid';

import { Logger } from 'services/Logger';
import { getDatafromPath, asyncForEach /* , getNumberValue */ } from 'utils';

import styles from './styles';

function NoOptionsMessage(props) {
  return (
    <Typography
      className={props.selectProps.classes.noOptionsMessage}
      color="textSecondary"
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      component="div"
      selected={props.isFocused}
      style={{
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.29,
        letterSpacing: 'normal',
        // backgroundColor: "#f2f2f2",
        color: '#3f3f3f'
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      className={props.selectProps.classes.placeholder}
      color="textSecondary"
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      deleteIcon={<CancelIcon style={{ color: '#fff', fontSize: 14 }} {...props.removeProps} />}
      label={props.children}
      tabIndex={-1}
      onDelete={props.removeProps.onClick}
    />
  );
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputLabelProps={{
        shrink:
          (!!props.selectProps.value && props.selectProps.value.length > 0) ||
          !!props.selectProps.inputValue
      }}
      InputProps={{
        inputComponent,
        className: props.selectProps.classes.selectControl,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      variant="filled"
      {...props.selectProps.textFieldProps}
      classes={{ root: props.input }}
    />
  );
}
function Menu(props) {
  return (
    <Paper className={props.selectProps.classes.paper} square {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  ClearIndicator: () => '',
  IndicatorSeparator: () => '',
  DropdownIndicator: () => <ArrowDropDownIcon style={{ marginTop: -10, color: '#646464' }} />
};

class IntegrationReactSelect extends React.Component {
  constructor(props) {
    super(props);
    this.CommonService = new CommonService();
    this.state = {
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: ''
    };
  }

  handleCancelConfirmation = () => {
    this.setState({ confirmDialog: false, confirmAction: '', confirmMessage: '' });
  };

  deleteRelationships = async (records, chosenValue, databaseIdField) => {
    const fieldName = this.props.fieldProps.name;
    const databaseIds = this.props.form.values[databaseIdField];
    // const { childField } = this.props.specialbehaviour;

    try {
      await asyncForEach(records, async sortKey => {
        return this.CommonService.deleteEntityMap(this.props.user.tenantId, sortKey);
      });

      records.forEach(item => {
        const splitStr = item.split('_');
        const recordId = splitStr[splitStr.length - 1];
        const position = databaseIds.indexOf(recordId);
        if (position > -1) {
          databaseIds.splice(position, 1);
        }
      });

      const { values } = this.props.form;
      const newValues = { ...values, [fieldName]: chosenValue, [databaseIdField]: databaseIds };

      this.props.form.setValues(newValues);
      this.setState({
        confirmDialog: false,
        confirmAction: '',
        confirmMessage: ''
      });
    } catch (error) {
      Logger.error(error);
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        this.props.snackbarOn('error', error.graphQLErrors[0].message);
      } else {
        this.props.snackbarOn('error', 'Unable to delete assignments, please try again later');
      }
    }
  };

  // takes the current record id and passed id will be sent to specific cascade delete function
  // cascade delete function will be passed through query result of pageForm
  cascadeDelete = async (serviceName, recordsList, chosenValue, databaseIdField) => {
    const employeeId = this.props.form.values.id;
    const fieldName = this.props.fieldProps.name;
    const deleteServiceFunction = this.props.specialbehaviour.queryResult[serviceName];
    const databaseIds = this.props.form.values[databaseIdField];
    const recordId = recordsList[0];

    try {
      const response = await deleteServiceFunction(employeeId, recordId);
      const responseString = JSON.stringify(response);
      const isDeleted = responseString.toLowerCase().includes('success');

      if (isDeleted) {
        const indexOfId = databaseIds.indexOf(recordId);
        let modifiedArray;
        if (indexOfId > -1) {
          databaseIds.splice(indexOfId, 1);
        }
        const { values } = this.props.form;
        let newValues = { ...values, [fieldName]: chosenValue };
        if (modifiedArray) {
          newValues = { ...newValues, [databaseIdField]: databaseIds };
        }
        this.props.form.setValues(newValues);
        this.setState({
          confirmDialog: false,
          confirmAction: '',
          confirmMessage: ''
        });
      }
    } catch (error) {
      Logger.error(error);
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        this.props.snackbarOn('error', error.graphQLErrors[0].message);
      } else {
        this.props.snackbarOn('error', 'Unable to delete assignments, please try again later');
      }
    }
  };

  getMappings = async (deletedValueList, selectedArr, databaseIdField) => {
    if (!deletedValueList) return;
    const databaseMapping = this.props.specialbehaviour.databaseMapping || '';
    let databaseSortkeys;
    if (databaseMapping) {
      // mapping to be available in form itself
      databaseSortkeys = this.props.form.values[databaseMapping];
    }
    let keysToDelete = [];
    if (databaseSortkeys) {
      deletedValueList.forEach(value => {
        keysToDelete = keysToDelete.concat(databaseSortkeys[value]);
      });
    }

    if (keysToDelete.length > 0) {
      await this.deleteRelationships(keysToDelete, selectedArr, databaseIdField);
    }
  };

  handleSelection = selectedArray => {
    const { fieldProps, form } = this.props;
    const databaseDeleteServiceName = this.props.specialbehaviour.deleteService || null;
    const databaseIdField = this.props.specialbehaviour.databaseId;
    const databaseIds = form.values[databaseIdField];
    const selectedArr =
      selectedArray && selectedArray.length > 0 ? R.pluck('value', selectedArray) : [];

    let diffArray;
    if (databaseIds && databaseIds.length > 0 && selectedArr) {
      if (selectedArr.length > 0) {
        diffArray = databaseIds.filter(value => !selectedArr.includes(value));
      } else if (selectedArr.length === 0) {
        diffArray = databaseIds;
      }
    }

    let deleteFunction;
    if (databaseDeleteServiceName) {
      // used in employee - department delete
      deleteFunction = () =>
        this.cascadeDelete(databaseDeleteServiceName, diffArray, selectedArr, databaseIdField);
    } else {
      deleteFunction = () => this.getMappings(diffArray, selectedArr, databaseIdField);
    }

    if (diffArray && diffArray.length > 0) {
      // handle deletion scenario
      this.setState({
        confirmDialog: true,
        confirmAction: () => deleteFunction(),
        confirmMessage:
          'Your previous assignment will be removed. Are you sure you want to continue?'
      });
    } else {
      form.setFieldValue(fieldProps.name, selectedArr, false);
    }
  };

  handleDelete = toDeleteValue => () => {
    const { fieldProps, form } = this.props;
    const databaseDeleteServiceName = this.props.specialbehaviour.deleteService || null;
    const databaseIdField = this.props.specialbehaviour.databaseId;
    const databaseIds = form.values[databaseIdField];
    const formValues = form.values[fieldProps.name];
    const selectedArr = formValues.filter(item => item !== toDeleteValue);

    let diffArray;
    if (selectedArr && selectedArr.length > 0 && databaseIds && databaseIds.length > 0) {
      diffArray = databaseIds.filter(value => !selectedArr.includes(value));
    }

    let deleteFunction;
    if (databaseDeleteServiceName) {
      // used in employee - department delete
      deleteFunction = () =>
        this.cascadeDelete(databaseDeleteServiceName, diffArray, selectedArr, databaseIdField);
    } else {
      deleteFunction = () => this.getMappings(diffArray, selectedArr, databaseIdField);
    }

    if (selectedArr && selectedArr.length === 0 && databaseIds && databaseIds.length > 0) {
      diffArray = databaseIds;
    }

    if (diffArray && diffArray.length > 0) {
      // handle deletion scenario
      this.setState({
        confirmDialog: true,
        confirmAction: () => deleteFunction(),
        confirmMessage:
          'Your previous assignment will be removed. Are you sure you want to continue?'
      });
    } else {
      form.setFieldValue(fieldProps.name, selectedArr, false);
    }
  };

  render() {
    const { fieldProps, form, classes, specialbehaviour, mode, ...rest } = this.props;
    const { queryResult, queryPath, fieldName, dependentFieldName } = specialbehaviour;

    const selectStyles = {
      menu: cstyles => ({
        ...cstyles,
        zIndex: 5,
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.29,
        letterSpacing: 'normal',
        color: '#3f3f3f',
        maxHeight: 160
      }),
      option: (customstyles, { isFocused, isSelected }) => ({
        ...customstyles,
        backgroundColor: isFocused ? '#f2f2f2' : isSelected ? '#f2f2f2' : null,
        color: isFocused ? '#3f3f3f' : isSelected ? '#3f3f3f' : null,
        maxHeight: 160
      }),
      menuList: custstyles => ({
        ...custstyles,
        left: 0,
        top: 0,
        maxHeight: 160
      })
    };

    if (!queryResult) {
      return null;
    }

    let displayDataArray;

    // if dependent, pick the values from dependentfield
    // get path of dependent array and pull the selected values array
    if (dependentFieldName && form.values[dependentFieldName]) {
      if (Array.isArray(form.values[dependentFieldName])) {
        form.values[dependentFieldName].forEach(dependentItem => {
          const dependentIdValue = dependentItem;
          const values = getDatafromPath(queryResult, queryPath);
          const correspondingPicklistValues = values[dependentIdValue];
          // recieving the skills list for the department from the processed data
          displayDataArray = displayDataArray
            ? displayDataArray.concat(correspondingPicklistValues)
            : correspondingPicklistValues;
        });

        // get current selected values
        const selectedValuesList = form.values[fieldProps.name];

        // if ids not there, remove from the selected list
        if (
          selectedValuesList &&
          selectedValuesList.length > 0 &&
          displayDataArray &&
          displayDataArray.length > 0
        ) {
          const displayIds = [];
          displayDataArray.forEach(data => {
            displayIds.push(data.id);
          });
          const modifiedList = selectedValuesList.filter(id => displayIds.includes(id));
          if (modifiedList && modifiedList.length !== selectedValuesList.length) {
            form.setFieldValue(fieldProps.name, modifiedList, false);
          }
        }
      }
    } else {
      displayDataArray = getDatafromPath(queryResult, queryPath) || [];
    }

    const helperText =
      form.errors && form.errors[fieldProps.name]
        ? form.errors && form.errors[fieldProps.name]
        : ' ';

    // const isValueSet = value => {
    //   const selectedValuesArr = form.values[fieldProps.name];

    //   let isValuePresent = false;
    //   if (selectedValuesArr) {
    //     selectedValuesArr.forEach(val => {
    //       if (val === value.id) {
    //         isValuePresent = true;
    //       }
    //     });
    //   }
    //   return isValuePresent;
    // };

    // const selectStyles = {
    //   input: base => ({
    //     ...base,
    //     // color: theme.palette.text.primary,
    //     '& input': {
    //       font: 'inherit'
    //     }
    //   })
    // };

    const selectOptionValues = [];
    if (displayDataArray && displayDataArray.length > 0) {
      displayDataArray.forEach(item => {
        selectOptionValues.push({ label: item[fieldName], value: item.id });
      });
    }

    const defaultValueList =
      form.values[fieldProps.name] && selectOptionValues.length > 0
        ? selectOptionValues.filter(item => form.values[fieldProps.name].includes(item.value || ''))
        : [];
    // const defaultValue = defaultValueList.length > 0 ? defaultValueList[0] : '';

    const filterConfig = {
      ignoreCase: true,
      trim: true,
      matchFrom: 'start'
    };
    // let smValue;

    // let mdValue;

    // let lgValue = 12;

    // if (fieldProps.behavior[mode]) {
    //   smValue = getNumberValue(fieldProps.behavior[mode].sm);
    //   mdValue = getNumberValue(fieldProps.behavior[mode].md);
    //   lgValue = getNumberValue(fieldProps.behavior[mode].lg);
    // }

    return (
      // <Grid item xs={smValue} sm={smValue} md={mdValue} lg={lgValue} xl={lgValue}>
      <>
        <FormControl error={form.errors && form.errors[fieldProps.name]} fullWidth variant="filled">
          <Select
            classes={classes}
            components={components}
            filterOption={createFilter(filterConfig)}
            isClearable={false}
            isMulti
            name={fieldProps.name}
            options={selectOptionValues}
            placeholder=""
            styles={selectStyles}
            textFieldProps={{
              label: rest.label,
              error: form.errors && form.errors[fieldProps.name]
            }}
            value={defaultValueList}
            onChange={this.handleSelection}
          />
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
        <ConfirmModal
          cancel={this.handleCancelConfirmation}
          confirm={this.state.confirmAction}
          message={this.state.confirmMessage}
          open={this.state.confirmDialog}
          override
        />
      </>
    );
  }
}

export default withStyles(styles)(IntegrationReactSelect);
