import { gql } from '@apollo/client/core';
import { arrayOf, bool, shape } from 'prop-types';

import { ERROR_PROP } from '@dispatch/Dispatch.constants';
import { watchedQueries } from '@dispatch/Dispatch.store';
import { visitDetailsFragment } from '@dispatch/fragments';
import { transformVisitDetails, VISIT_DETAILS_PROP } from '@dispatch/queries';
import { useDispatchSettings } from '@dispatch/settings';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import { getState } from 'redux/store';

import {
  selectEndTime,
  selectStartTime,
  selectVisitWidth,
  selectVisitXPosition
} from '../DispatchBoard.selectors';

const GET_DISPATCH_BOARD_VISITS = gql`
    query getDispatchBoardVisits(
        $partitionKey: String!
        $sortKey: String!
        $startTime: Int!
        $endTime: Int!
    ) {
        scheduledVisits: getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
            id
            visitsInRange: visitsView(
                entityConnection: "Visit"
                pagination: { limit: 10000 }
                filter: {
                    integerFilters: [
                        {
                            fieldName: "Visit.scheduledFor"
                            filterInput: { between: [$startTime, $endTime] }
                        }
                    ]
                }
            ) {
                items {
                    ${visitDetailsFragment}
                }
            }
        }
    }
`;

const DEFAULT_DATA = [];

// format to match VISIT_DETAILS_PROP
export const transformBoardVisits = data => {
  const visits = data?.scheduledVisits?.visitsInRange?.items;

  if (!visits) return DEFAULT_DATA;

  return visits.map(visit => {
    const transformedVisit = transformVisitDetails({ visit });

    return {
      ...transformedVisit,
      left: selectVisitXPosition(transformedVisit),
      width: selectVisitWidth(transformedVisit)
    };
  });
};

export const useDispatchBoardVisits = ({ day, endDay, mapView, weekView }) => {
  const { tenantId: partitionKey, tenantCompanyId } = getState().user;
  const sortKey = `${partitionKey}_Company_${tenantCompanyId}`;
  const { weekViewStartDay } = useDispatchSettings();
  const startTime = selectStartTime({ day, weekView, weekViewStartDay });
  const endTime = selectEndTime({ day, endDay, mapView, weekView, weekViewStartDay });

  const queryVariables = {
    partitionKey,
    sortKey,
    startTime,
    endTime
  };

  watchedQueries.useDispatchBoardVisits = {
    query: GET_DISPATCH_BOARD_VISITS,
    variables: queryVariables
  };

  return useExtendedQuery(GET_DISPATCH_BOARD_VISITS, {
    transform: transformBoardVisits,
    defaultData: DEFAULT_DATA,
    variables: queryVariables
  });
};

export const BOARD_VISIT_RESPONSE_PROP = shape({
  error: ERROR_PROP,
  loading: bool,
  data: arrayOf(VISIT_DETAILS_PROP)
});
