import gql from 'graphql-tag';

const updateDefaultLineItem = gql`
  mutation setDefaultLineItem($data: DefaultLineItemInput!) {
    setDefaultLineItem(data: $data) {
      id
      tenantId
      settingValue
    }
  }
`;

export default updateDefaultLineItem;
