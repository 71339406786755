import React from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Button, ButtonType, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { orderBy } from 'lodash';

import { NotesSection } from 'components';
import AttachmentSection from 'components/AttachmentSection/AttachmentList';

import updateAttachmentMutation from 'services/core/graphql/common/mutations/updateAttachment';
import updateNoteMutation from 'services/core/graphql/common/mutations/updateNote';
import deleteAttachmentMutation from 'services/core/graphql/review-report/mutations/DeleteAttachmentById';
import deleteNoteMutation from 'services/core/graphql/review-report/mutations/DeleteNoteById';
import { Logger } from 'services/Logger';
import { logErrorWithCallback } from 'utils';
import { Mode } from 'utils/constants';

import { generateAttachmentPayload } from '../../helpers';
import {
  addAttachmentToServiceAgreementMutation,
  addNoteToServiceAgreementMutation,
  copyNotesToRenewedServiceAgreementMutation,
  getServiceAgreementAttachmentsAndNotesQuery
} from '../../queries';

export const NotesAndAttachments = ({ agreementInfo, showSnackbar, user }) => {
  const { data: rawData, refetch, loading, error: queryError } = useQuery(
    getServiceAgreementAttachmentsAndNotesQuery,
    {
      variables: { id: agreementInfo.id },
      skip: !agreementInfo?.id,
      fetchPolicy: 'network-only'
    }
  );
  const { renewalGroupId, isNotesAndAttachmentCopied } = rawData?.serviceAgreement || {};
  const attachmentList =
    rawData?.serviceAgreement?.attachments?.items?.filter(item => item.type !== 'Contract') || [];

  const noteList = rawData?.serviceAgreement.notes?.items;

  const [addAttachment, { error: addAttachmentMutationError }] = useMutation(
    addAttachmentToServiceAgreementMutation
  );

  const [updateAttachment, { error: updateAttachmentMutationError }] = useMutation(
    updateAttachmentMutation
  );
  const [deleteAttachment, { error: deleteAttachmentMutationError }] = useMutation(
    deleteAttachmentMutation
  );
  const [addNote, { error: addNoteMutationError }] = useMutation(addNoteToServiceAgreementMutation);
  const [updateNote, { error: updateNoteMutationError }] = useMutation(updateNoteMutation);
  const [deleteNote, { error: deleteNoteMutationError }] = useMutation(deleteNoteMutation);

  const [
    copyNotesToRenewedServiceAgreement,
    { loading: copyingMutaionLoading, error: copyingMutationError }
  ] = useMutation(copyNotesToRenewedServiceAgreementMutation);

  const handleAttachmentMutations = async (mode, attachmentRecord) => {
    const attachmentPayload = generateAttachmentPayload(attachmentRecord);
    if (mode === Mode.EDIT && attachmentPayload?.id) {
      await updateAttachment({ variables: { data: attachmentPayload } });
    } else if (mode === Mode.DELETE) {
      await deleteAttachment({ variables: { id: attachmentPayload.id } });
    } else {
      await addAttachment({
        variables: {
          data: {
            serviceAgreementId: agreementInfo.id,
            attachments: [attachmentPayload]
          }
        }
      });
    }
    const updateMsg = mode === Mode.EDIT ? 'updated' : 'added';
    showSnackbar(
      'success',
      `Successfuly ${mode === Mode.DELETE ? 'deleted' : updateMsg} attachment`
    );
    refetch();
  };

  const handleNoteMutations = async (mode, noteRecord) => {
    const notePayload = {
      id: noteRecord.id,
      subject: noteRecord.subject || null,
      note: noteRecord.note || null,
      version: noteRecord.version
    };

    if (mode === Mode.EDIT && notePayload?.id) {
      await updateNote({
        variables: { partitionKey: user.tenantId, data: notePayload }
      });
    } else if (mode === Mode.DELETE) {
      await deleteNote({ variables: { id: notePayload.id } });
    } else {
      await addNote({
        variables: {
          data: {
            serviceAgreementId: agreementInfo.id,
            notes: [notePayload]
          }
        }
      });
    }
    const updateMsg = mode === Mode.EDIT ? 'updated' : 'added';
    showSnackbar('success', `Successfuly ${mode === Mode.DELETE ? 'deleted' : updateMsg} note`);
    refetch();
  };

  const handleCopyNotesAndAttachments = async (skipNoteAndAttachment = false) => {
    await copyNotesToRenewedServiceAgreement({
      variables: { id: agreementInfo.id, skipNoteAndAttachment }
    });
    // show snackbar only when copying, dismissing can be done silently
    if (!skipNoteAndAttachment) {
      showSnackbar('success', `Successfuly copied notes and attachments`);
    }
    refetch();
  };

  const mutationAttachmentError =
    addAttachmentMutationError || updateAttachmentMutationError || deleteAttachmentMutationError;
  const mutationNoteError =
    addNoteMutationError || updateNoteMutationError || deleteNoteMutationError;

  if (mutationAttachmentError || mutationNoteError || queryError) {
    const mutationMsg = `Unable to update ${mutationAttachmentError ? 'attachment' : 'note'}`;
    logErrorWithCallback(
      mutationAttachmentError || mutationNoteError,
      showSnackbar,
      queryError ? 'Unable to query attachments and notes' : mutationMsg
    );
    Logger.error(mutationAttachmentError || mutationNoteError || queryError);
  }
  if (copyingMutationError) {
    logErrorWithCallback(
      copyingMutationError,
      showSnackbar,
      'Unable to copy notes and attachments from previous agreement'
    );
  }
  const theme = useTheme();

  return (
    <>
      {renewalGroupId && !isNotesAndAttachmentCopied && (
        <ThemeProvider>
          <Box
            alignItems="center"
            bgcolor={theme.palette.other.blue1Light}
            color={theme.palette.other.blue1Dark}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mb={1}
            p={1}
          >
            <Box alignItems="center" display="flex" flexDirection="row">
              <Button
                loading={copyingMutaionLoading}
                size="small"
                startIcon={<CloseIcon style={{ color: theme.palette.other.blue1Dark }} />}
                style={{ backgroundColor: 'inherit' }}
                type={ButtonType.LEADING}
                onClick={() => handleCopyNotesAndAttachments(true)}
              />
              <Typography variant={TV.BASE} weight={TW.MEDIUM}>
                Add notes &amp; attachments from previous agreement?
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <Button
                loading={copyingMutaionLoading}
                size="small"
                style={{ backgroundColor: 'inherit' }}
                type={ButtonType.LEADING}
                onClick={() => handleCopyNotesAndAttachments(false)}
              >
                <Typography
                  color={theme.palette.other.blue1Dark}
                  variant={TV.BASE}
                  weight={TW.BOLD}
                >
                  Add Notes &amp; Attachments
                </Typography>
              </Button>
            </Box>
          </Box>
        </ThemeProvider>
      )}
      <NotesSection
        dataArray={orderBy(noteList, 'createdDateTime', 'desc')}
        handleNoteMutations={handleNoteMutations}
        isLoading={loading}
      />
      <Box p={2} />
      <AttachmentSection
        dataArray={orderBy(attachmentList, 'createdDateTime', 'desc')}
        handleAttachmentMutations={handleAttachmentMutations}
        isLoading={loading}
      />
    </>
  );
};
