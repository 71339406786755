import React, { memo, useRef } from 'react';

import { withDispatchActions, withQueryStringStore } from '@dispatch/Dispatch.store';
import useDeleteBillableNonVisit from '@dispatch/mutations/useDeleteBillableNonVisit';
import useUpdateBillableNonVisit from '@dispatch/mutations/useUpdateBillableNonVisit';
import useNonVisitEventDetails from '@dispatch/queries/useNonVisitEventDetails';
import compose from 'utils/compose';

import EditNonBillableEvent from './EditNonBillableEvent.component';

const mapActionsToProps = ({ closeDrawer }) => ({
  closeDrawer
});

const mapQueryStringToProps = store => ({
  nonBillableEventId: store.state.nonBillableEventId
});

const DataProvider = props => {
  const Memoized = useRef(memo(EditNonBillableEvent)).current;
  // eslint-disable-next-line react/prop-types
  const { nonBillableEventId } = props;
  const updateBillableNonVisitTuple = useUpdateBillableNonVisit();
  const deleteBillableNonVisitTuple = useDeleteBillableNonVisit();
  const nonVisitEventDetailsResponse = useNonVisitEventDetails(nonBillableEventId);

  return (
    <Memoized
      {...props}
      deleteBillableNonVisitTuple={deleteBillableNonVisitTuple}
      nonVisitEventDetailsResponse={nonVisitEventDetailsResponse}
      updateBillableNonVisitTuple={updateBillableNonVisitTuple}
    />
  );
};

export default compose(
  withDispatchActions(mapActionsToProps),
  withQueryStringStore(mapQueryStringToProps)
)(DataProvider);
