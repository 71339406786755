import React from 'react';

import { TV, Typography } from '@BuildHero/sergeant';
import moment from 'moment';
import { arrayOf, bool, number, shape, string } from 'prop-types';

import TablePlaceholder from 'components/WrapTable/TablePlaceholder';
import { TablePlaceholderVariant } from 'components/WrapTable/WrapTable.constants';
import { getCustomerSignatureSrc } from 'utils';
import { AppConstants } from 'utils/AppConstants';

import { useStyles } from './SignatureInfoSection.styles';

const SignatureInfoSection = ({ signatures }) => {
  const styles = useStyles();

  return (
    <>
      {signatures.length ? (
        signatures.map(signature => {
          const capturedBy = signature.capturedBy || signature.createdBy;
          const capturedDate = moment
            .unix(signature.signedDateTime / 1000 || signature.createdDate)
            .format(AppConstants.DATETIME_FORMAT);
          const signee = signature.nameOfSignee || '-';
          return (
            <React.Fragment key={`customer-signature-${signature.id}`}>
              <div css={styles.container}>
                <img
                  alt="Customer Signature"
                  css={styles.image}
                  src={getCustomerSignatureSrc(signature)}
                />
                <div css={styles.info}>
                  <Typography variant={TV.S1}>
                    Captured By: {capturedBy}, {capturedDate}
                  </Typography>
                  <Typography variant={TV.S1}>Signee: {signee}</Typography>
                </div>
              </div>
            </React.Fragment>
          );
        })
      ) : (
        <TablePlaceholder message="No Customer Signature" variant={TablePlaceholderVariant.TEXT} />
      )}
    </>
  );
};

SignatureInfoSection.propTypes = {
  signatures: arrayOf(
    shape({
      includeInInvoice: bool.isRequired,
      signedDateTime: string.isRequired,
      nameOfSignee: string.isRequired,
      signatureImageUrl: string.isRequired,
      version: number.isRequired
    })
  ).isRequired
};

export default SignatureInfoSection;
