import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import styles from './styles';

/**
 * Materail UI text input with BuildHero styles
 */
const MutiTextArea = ({ field, options, form, classes }) => {
  const handleOnChange = event => {
    const currentValue = event.target.value;
    form.setFieldValue(field.name, currentValue, false);
  };

  return (
    <TextField
      className={classes.textField}
      disabled={options.disabled || false}
      error={form.errors && form.errors[field.name] && form.errors[field.name] !== ''}
      fullWidth
      InputProps={{
        classes: {
          underline: classes.cssUnderline,
          root: classes.textField
        }
      }}
      label={options ? options.label : ''}
      multiline
      name={field.name}
      rows={['issueDescription', 'description'].includes(field.name) ? 12 : ''}
      rowsMax="9"
      value={field.value === null ? '' : field.value}
      variant="outlined"
      onChange={handleOnChange}
    />
  );
};
// export default MutiTextArea;
export default withStyles(styles)(MutiTextArea);
