import { useDrag } from 'react-dnd';

import { isScheduledProjectVisitOrNonVisit } from './NonVisitCard.helpers';

export const useNonVisitDrag = ({ type, event, srcTech, wrinkleTimeFF, isOptimistic }) => {
  return useDrag({
    item: { type, data: event, srcTech },
    canDrag: () => {
      if (isOptimistic) return false;
      return isScheduledProjectVisitOrNonVisit(event, wrinkleTimeFF);
    }
  });
};

export const useEventBorderDrag = ({
  type,
  event,
  srcTech,
  weekView,
  wrinkleTimeFF,
  isOptimistic
}) => {
  return useDrag({
    item: { type, data: event, srcTech },
    canDrag: () => {
      if (isOptimistic) return false;
      return !weekView && isScheduledProjectVisitOrNonVisit(event, wrinkleTimeFF);
    }
  });
};
