import React, { useEffect, useState } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import Labels from 'meta/labels';
import { getProjectById } from 'services/API/project';
import { QuoteAddedItem, QuoteStatus } from 'utils/AppConstants';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

const AssociatedQuoteAddedItem = ({ quoteInfo, locale }) => {
  const [project, setProject] = useState(null);
  const flags = useFlags();

  useEffect(() => {
    if (
      !project &&
      quoteInfo &&
      quoteInfo.addedItem === QuoteAddedItem.PROJECT &&
      quoteInfo.projectId
    ) {
      getProjectById(quoteInfo.projectId).then(proj => {
        setProject(proj);
      });
    }
  }, [quoteInfo]);

  return (
    <>
      {((quoteInfo?.addedItem &&
        quoteInfo.addedItem !== QuoteAddedItem.NONE &&
        flags[FeatureFlags.PROJECT_MANAGEMENT]) ||
        quoteInfo?.status === QuoteStatus.JOB_ADDED) && (
        <div data-testid="associated-quote-added-item-container">
          {quoteInfo.addedItem === QuoteAddedItem.PROJECT && quoteInfo.projectId && project && (
            <p data-testid="associated-quote-added-item-project" style={{ margin: 0 }}>
              {Labels.quoteAssociatedProject[locale]}:{' '}
              <a href={`/project/view/${quoteInfo.projectId}/dashboard`}>{project?.name}</a>
            </p>
          )}
        </div>
      )}
      {quoteInfo?.quoteJobs?.items[0]?.job?.jobNumber && (
        <p data-testid="associated-quote-added-item-job" style={{ margin: 0 }}>
          {Labels.quoteAssociatedJob[locale]}:{' '}
          <a href={`/job/view/${quoteInfo.quoteJobs?.items[0]?.job?.jobNumber}`}>
            {quoteInfo.quoteJobs?.items[0]?.job?.customIdentifier ||
              quoteInfo.quoteJobs?.items[0]?.job?.jobNumber}
          </a>
        </p>
      )}
    </>
  );
};

export default AssociatedQuoteAddedItem;
