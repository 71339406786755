import React, { useEffect, useRef, useState } from 'react';

import { ThemeProvider, TV, Typography } from '@BuildHero/sergeant';
import { any, bool, func, number, shape, string } from 'prop-types';

import TimeMarker from '@dispatch/components/DispatchBoard/components/TimeMarker';
import { selectRangeText } from '@dispatch/Dispatch.selectors';
import { TECH_PROP } from '@dispatch/queries';

import CreateEventMenu from '../../../../../CreateEventMenu';
import { useWeekViewContext } from '../../../../WeekView.context';

import { usePositionUpdate, useVisitDrop } from './AvailableTimeCard.hooks';
import { useStyles } from './AvailableTimeCard.styles';

const AvailableTimeCard = ({
  availableTime,
  tech,
  cell,
  triggerVisitTransition,
  triggerUpdateNonVisit,
  triggerUpdateManDay,
  isLastEvent
}) => {
  const rootRef = useRef();
  const [timePosition, setTimePosition] = useState({});
  const { setActiveCell } = useWeekViewContext();
  const rangeText = selectRangeText(availableTime);
  const [showCreateEventMenu, setShowCreateEventMenu] = useState(false);

  const [collected, dropRef] = useVisitDrop({
    availableTime,
    tech,
    timePosition,
    triggerVisitTransition,
    triggerUpdateNonVisit,
    triggerUpdateManDay
  });

  const styles = useStyles({ ...collected, isLastEvent });

  usePositionUpdate({
    rootRef,
    setTimePosition,
    range: availableTime.range,
    timePosition,
    ...collected
  });

  useEffect(() => {
    // setTimeout ensures setting new active cell takes precedence over clearing old active cell
    if (collected.dropTargetActive) setTimeout(() => setActiveCell(cell), 0);
    else setActiveCell(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collected.isOver]);

  const displayCreateEventMenu = () => {
    setShowCreateEventMenu(!showCreateEventMenu);
  };

  const handleCreateEventMenuClose = () => {
    setShowCreateEventMenu(false);
  };

  const handleSetRootRef = ref => {
    dropRef(ref);
    rootRef.current = ref;
  };

  return (
    <div css={styles.root}>
      <div
        css={styles.border}
        ref={handleSetRootRef}
        role="button"
        tabIndex="0"
        onClick={displayCreateEventMenu}
        onKeyPress={() => {}}
      >
        <ThemeProvider>
          <Typography variant={TV.S2}>
            {availableTime.emptyDay ? 'Unscheduled' : rangeText}
            <CreateEventMenu
              open={showCreateEventMenu}
              position={1}
              techId={tech.id}
              techName={tech.name}
              weekViewDate={availableTime.range.start.format('L')}
              onClose={handleCreateEventMenuClose}
            />
          </Typography>
        </ThemeProvider>
        {collected.dropTargetActive && <TimeMarker horizontal {...timePosition} />}
      </div>
    </div>
  );
};

AvailableTimeCard.propTypes = {
  availableTime: shape({
    __typename: string,
    emptyDay: bool,
    isLastEvent: bool,
    range: shape({
      start: any,
      end: any
    })
  }).isRequired,
  tech: TECH_PROP.isRequired,
  cell: shape({
    laneIndex: number,
    dayIndex: number
  }).isRequired,
  triggerVisitTransition: func.isRequired,
  triggerUpdateNonVisit: func.isRequired,
  triggerUpdateManDay: func.isRequired,
  // eslint-disable-next-line react/require-default-props
  isLastEvent: bool
};

export default AvailableTimeCard;
