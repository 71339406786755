import React, { useState } from 'react';

import {
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Typography
} from '@material-ui/core';

import { DefaultButton } from 'components';
import theme from 'themes/BuildHeroTheme';
import { compareCurrencyFloats, convertToCurrencyString, roundCurrency } from 'utils';
import { Mode } from 'utils/constants';

const AddPaymentCell = ({ id, amount, handleChange, applyPayment, availableAmount, error }) => (
  <div style={{ direction: 'column' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip
        title={`Amount Available: ${
          availableAmount ? convertToCurrencyString(availableAmount) : '-'
        }`}
      >
        <OutlinedInput
          id={id}
          placeholder="Amount"
          startAdornment={
            <InputAdornment position="start">
              <Typography variant="body2">$</Typography>
            </InputAdornment>
          }
          type="number"
          value={amount}
          variant="filled"
          onChange={handleChange}
        />
      </Tooltip>
      <DefaultButton
        handle={() => applyPayment(id, roundCurrency(amount))}
        label="Apply"
        style={{ width: 'auto', margin: 8 }}
      />
    </div>
    {error && (
      <FormHelperText style={{ color: theme.palette.error.main, marginBottom: 4 }}>
        {error}
      </FormHelperText>
    )}
  </div>
);

const RemovePaymentCell = ({ id, amount, removePayment }) => {
  const convertedValue = convertToCurrencyString(amount);
  return (
    <>
      <div style={{ fontWeight: 700 }}>{convertedValue}</div>
      <DefaultButton
        handle={() => removePayment(id, amount)}
        label="Cancel"
        style={{ width: 'auto', margin: 8 }}
      />
    </>
  );
};

const ApplyPaymentFromInvoiceInput = ({
  id,
  value,
  isApplied,
  addAppliedPaymentsMap,
  updateAmountMap,
  availableAmount,
  invoiceOutstandingBalance
}) => {
  const [amount, setAmount] = useState(roundCurrency(value));
  const [error, setError] = useState();
  const handleChange = (paymentId, newAmount) => {
    const roundedNewAmount = roundCurrency(newAmount);
    setAmount(roundedNewAmount);
    updateAmountMap({ paymentId, amount: roundedNewAmount });
  };
  const applyPayment = (paymentId, newAmount) => {
    const roundedNewAmount = roundCurrency(newAmount);
    if (roundedNewAmount === null) return;
    if (!availableAmount) {
      setError('Payments must have an available amount to apply to an Invoice.');
    } else if (compareCurrencyFloats(invoiceOutstandingBalance, roundedNewAmount) < 0) {
      setError(
        `Can't apply more than outstanding balance ${convertToCurrencyString(
          invoiceOutstandingBalance
        )}.`
      );
    } else if (compareCurrencyFloats(availableAmount, roundedNewAmount) < 0) {
      setError(`Can't apply more than ${convertToCurrencyString(availableAmount)}.`);
    } else if (roundedNewAmount < 0.01) {
      setError(`Payments must have a positive non-zero amount.`);
    } else {
      addAppliedPaymentsMap({ paymentId, amount: roundedNewAmount });
    }
  };
  const removePayment = (paymentId, removedAmount) =>
    addAppliedPaymentsMap({
      paymentId,
      amount: Mode.DELETE,
      removedAmount: roundCurrency(removedAmount)
    });

  return (
    <div
      style={{
        display: 'flex',
        direction: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      {isApplied ? (
        <RemovePaymentCell amount={amount} id={id} removePayment={removePayment} />
      ) : (
        <AddPaymentCell
          amount={amount}
          applyPayment={applyPayment}
          availableAmount={availableAmount}
          error={error}
          handleChange={e => {
            handleChange(id, e.target.value);
          }}
          id={id}
        />
      )}
    </div>
  );
};

export default ApplyPaymentFromInvoiceInput;
