import { theme } from '@BuildHero/sergeant';

import { TaskStatus } from 'utils/AppConstants';

export const PARTS_LIST_COLUMNS = [
  {
    headerName: 'Part Name',
    field: 'name',
    flex: 1
  },
  {
    headerName: 'Description',
    field: 'description',
    flex: 2
  },
  {
    headerName: 'Quantity',
    field: 'quantity',
    maxWidth: 120,
    align: 'center',
    headerAlign: 'center'
  }
];

export const TaskTableLayout = {
  VISIT_TASK: 'VISIT_TASK',
  JOB_TASK: 'JOB_TASK',
  JOB_CLOSEOUT_VISIT_TASK: 'JOB_CLOSEOUT_VISIT_TASK',
  JOB_CLOSEOUT_JOB_TASK: 'JOB_CLOSEOUT_JOB_TASK',
  JOB_CLOSEOUT_MULTI_VISIT_TASK: 'JOB_CLOSEOUT_MULTI_VISIT_TASK',
  JOB_CLOSEOUT_MULTI_JOB_TASK: 'JOB_CLOSEOUT_MULTI_JOB_TASK'
};

export const TaskStatusLabels = {
  [TaskStatus.OPEN]: 'Open'
};

export const TaskStatusColors = {
  [TaskStatus.OPEN]: theme.palette.grayscale(90),
  [TaskStatus.COMPLETED]: theme.palette.support.green.light,
  [TaskStatus.IN_QUOTE]: theme.palette.support.blue.light,
  [TaskStatus.IN_JOB]: theme.palette.support.blue.light,
  [TaskStatus.APPROVED]: theme.palette.support.yellow.light,
  [TaskStatus.REJECTED]: theme.palette.support.red.light
};
