import { convertStringToFloat, getBooleanValue } from 'utils';

const constructPayload = ({
  description,
  itemName,
  departmentName,
  productId,
  accountingRefId
}) => ({
  description,
  itemName,
  departmentName,
  productId,
  accountingRefId
});

export const saveLaborLine = async (context, event) => {
  const { services, user, id } = context;
  const { reviewReportService } = services;
  const { saveData } = event;

  const formattedData = constructPayload(saveData);
  formattedData.reviewReportId = id;
  formattedData.taxable = getBooleanValue(saveData.taxable);
  formattedData.billableTimeInHours = convertStringToFloat(saveData.billableTimeInHours) || 0.0;
  formattedData.hourlyCharge = convertStringToFloat(saveData.hourlyCharge) || 0.0;

  const { data } = await reviewReportService.addLaborLineItemToReviewReport(
    user.tenantId,
    formattedData
  );

  const { addLaborLineItemToReviewReport } = data;
  const { laborLineItems } = context;
  laborLineItems.push(addLaborLineItemToReviewReport);

  return { laborLineItems, modalRecord: {}, modalIndex: '' };
};

export const editLaborLine = async (context, event) => {
  const { services, user } = context;
  const { reviewReportService } = services;
  const { saveData } = event;

  const formattedData = constructPayload(saveData);
  formattedData.taxable = getBooleanValue(saveData.taxable);
  formattedData.billableTimeInHours = convertStringToFloat(saveData.billableTimeInHours) || 0.0;
  formattedData.hourlyCharge = convertStringToFloat(saveData.hourlyCharge) || 0.0;
  formattedData.version = saveData.version;
  formattedData.id = saveData.id;

  const { data } = await reviewReportService.updateLaborLineItem(user.tenantId, formattedData);

  const { updateLaborLineItem } = data;
  const { laborLineItems } = context;

  const modifiedLaborLineItems = laborLineItems.map(labourItem => {
    let localLabourItem = labourItem;
    if (localLabourItem.id === updateLaborLineItem.id) {
      localLabourItem = updateLaborLineItem;
    }
    return localLabourItem;
  });

  return { laborLineItems: modifiedLaborLineItems, modalRecord: {}, modalIndex: '' };
};

export const deleteLaborLine = async context => {
  const { services, user, modalRecord } = context;
  const { reviewReportService } = services;
  const { data } = await reviewReportService.deleteLaborLineItem(user.tenantId, modalRecord.id);

  // const { deleteLaborLineItem } = data;
  const { laborLineItems } = context;

  if (data?.deleteLaborLineItem) {
    const recordIndex = laborLineItems.findIndex(
      laborLineItem => laborLineItem.id === modalRecord.id
    );
    laborLineItems.splice(recordIndex, 1);
  }

  return { laborLineItems, modalRecord: {}, modalIndex: '' };
};

export const includeLaborLine = async (context, event) => {
  const { services, user } = context;
  const { data, checked } = event;
  const { reviewReportService } = services;
  const formattedData = (({ id, version }) => ({ id, version }))(data);
  formattedData.includeInInvoice = checked;

  const queryData = await reviewReportService.updateLaborLineItem(user.tenantId, formattedData);
  if (queryData) {
    const { updateLaborLineItem } = queryData.data;

    const { laborLineItems } = context;
    const modifiedLaborLineItems = laborLineItems.map(labourItem => {
      let localLabourItem = labourItem;
      if (localLabourItem.id === updateLaborLineItem.id) {
        localLabourItem = updateLaborLineItem;
      }
      return localLabourItem;
    });

    return { laborLineItems: modifiedLaborLineItems, modalRecord: {}, modalIndex: '' };
  }
};
