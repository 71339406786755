import gql from 'graphql-tag';

import { fragment as arBalance } from './getARBalanceData';

const getCustomerInfo = gql`
  query getCustomer($partitionKey: String!, $sortKey: String!) {
    getCustomer(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      customerName
      customerTypeValue
      accountNumber
      customerNumber
      status
      email
      phonePrimary
      amountNotToExceed
      sameAddress
      receiveSMS
      version
      isTaxable
      isActive
      syncStatus
      syncLog
      accountingRefId
      paymentTermId
      paymentTerm(entityConnection: "PaymentTerm") {
        id
        name
        value
        type
      }
      taxRateId
      taxRate(entityConnection: "TaxRate") {
        name
        taxRate
      }
      companyAddresses(entityConnection: "CompanyAddress") {
        items {
          id
          version
          tenantId
          partitionKey
          sortKey
          hierarchy
          addressLine1
          addressLine2
          state
          city
          zipcode
          entityType
          addressType
          billTo
          shipTo
          longitude
          latitude
        }
      }

      customerTags(entityConnection: "CustomerTag") {
        items {
          id
          version
          mappedEntityId
          mappedEntityType
          mappedEntityValue
          systemEntityId
          systemEntityType
          hierarchy
          sortKey
          invertedSortKey
          partitionKey
          mappedEntity {
            id
            ... on CustomerTag {
              tagType
              tagName
              sortKey
              partitionKey
              hierarchy
              id
              entityType
            }
          }
        }
      }
      notes(entityConnection: "Note", limit: 100) {
        items {
          id
          version
          partitionKey
          sortKey
          hierarchy
          entityType
          subject
          note
          createdBy
          lastUpdatedDate
          lastUpdatedDateTime
          lastUpdatedBy
        }
      }
      ${arBalance}
    }
  }
`;

export default getCustomerInfo;
