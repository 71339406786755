/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

import { sentryException } from '../Logger/index';

export async function getChangeOrderLineItems(projectId) {
  return await instance
    .get(`changeorderlineitem/byproject/${projectId}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getChangeOrderLineItemsByOrder(coId) {
  return await instance
    .get(`changeorderlineitem/byorder/${coId}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function changeOrderLineChange(id, line) {
  return await instance
    .put(`changeorderlineitem/${id}`, line)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function changeOrderLineItemDelete(id) {
  return await instance
    .delete(`changeorderlineitem/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to delete Change Order');
      return [];
    });
}
