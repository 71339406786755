import { useMemo } from 'react';

import { VisitFormFields } from './VisitDetailsForm.constants';

export const useVisitFormData = (visit = {}, job = {}, crewTimeTracking = false) => {
  return useMemo(() => {
    const jobDepartments = job.departments?.items || [];
    const defaultJobDepartmentId = jobDepartments.length === 1 ? jobDepartments[0].id : undefined;
    return {
      [VisitFormFields.id.key]: visit.id,
      [VisitFormFields.status.key]: visit.status,
      [VisitFormFields.description.key]: visit.description,
      [VisitFormFields.scheduledFor.key]: visit.scheduledFor,
      [VisitFormFields.departmentId.key]: visit.departmentId || defaultJobDepartmentId,
      [VisitFormFields.prerequisites.key]: visit.prerequisites,
      [VisitFormFields.prerequisitesAcknowledged.key]: visit.prerequisitesAcknowledged,
      [VisitFormFields.actualDuration.key]: visit.actualDuration,
      [VisitFormFields.primaryTechId.key]: visit.primaryTechId,
      [VisitFormFields.extraTechs.key]: visit.extraTechs,
      [VisitFormFields.extraTechsNumber.key]: crewTimeTracking ? 0 : visit.extraTechsNumber || 0,
      [VisitFormFields.endDate.key]: visit.endDate,
      [VisitFormFields.excludeWeekend.key]: visit.excludeWeekends,
      job
    };
  }, [visit, job, crewTimeTracking]);
};
