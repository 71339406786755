import React from 'react';

import { Typography } from '@BuildHero/sergeant';

import { makeStyles, Table, TableBody } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  noDataText: {
    color: theme.palette.grayscale(50),
    padding: theme.spacing(2),
    fontSize: 14
  }
}));

function TimesheetBillingEntityDetailsView({ items, renderItem, noDataMessage }) {
  const classes = useStyles();

  if (!items?.length) {
    return <Typography classes={{ root: classes.noDataText }}>{noDataMessage}</Typography>;
  }

  return (
    <div
      style={{
        maxHeight: 186,
        overflowY: 'scroll',
        width: '100%'
      }}
    >
      <Table>
        <TableBody>
          {items.map((item, index) => renderItem(item, index, items[index - 1]))}
        </TableBody>
      </Table>
    </div>
  );
}

export default TimesheetBillingEntityDetailsView;
