import React from 'react';

import {
  calculateMargin,
  calculateMarginFromMarkup,
  calculateMarkup,
  calculateMarkupFromMargin,
  calculateUnitPriceWithMargin,
  calculateUnitPriceWithMarkup,
  roundCurrency
} from '@BuildHero/math';
import { Field, FieldType, MUIForm, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import { snackbarOn } from 'redux/actions/globalActions';
import { logErrorWithCallback } from 'utils';
import { PricingStrategy } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import { convertForMathLib } from 'utils/mathLibrary';

import { updateTasksAndGroups } from '../../TaskGroups/TaskGroup.utils';

import { LABOR_CONSTANTS, LABOR_FIELDS } from './LaborModal.constants';
import { AddLaborForm } from './LaborModal.layout';
import {
  calculateAverageUnitAmount,
  calculateQuantity,
  formatLaborLineItemBeforeSaving,
  setNewBillingRates
} from './LaborModal.utils';

const useStyles = makeStyles(() => ({
  customField: {
    '& [class*="MuiTypography-body1"]': {
      fontSize: 16,
      fontWeight: 500,
      paddingTop: 11
    },
    '& p': {
      padding: '8px 0'
    }
  },
  customMarkup: {
    '& input': {
      fontSize: 16,
      padding: '9px 9px'
    }
  },
  title: {
    padding: 0,
    width: '100%',
    '&.costTable': {
      paddingRight: 48,
      boxSizing: 'content-box'
    }
  },
  container: {
    display: 'flex',
    width: '100%'
  }
}));

function LaborForm({
  selectedLaborType,
  laborRates,
  payrollHourTypes,
  idx,
  setSelectedLaborTypes,
  setFormService,
  allSelectedLaborTypes,
  updateLaborLineItems,
  item,
  setIsSubmitting,
  setIsLaborModalOpen,
  setTaskGroups
}) {
  const flags = useFlags();
  const pricingStrategy = useSelector(s => s.settings.pricingStrategy);
  const isMarginEnabled =
    pricingStrategy === PricingStrategy.MARGIN ||
    pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN;
  const isMarkupEnabled =
    pricingStrategy === PricingStrategy.MARKUP ||
    pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN;

  const CustomFieldWithLabel = props => {
    const classes = useStyles();
    return (
      <ThemeProvider>
        <Box className={classes.customField}>
          <Field label={props.options.label} type={FieldType.CURRENCY} value={props.field.value} />
        </Box>
      </ThemeProvider>
    );
  };

  const SectionHeaders = () => {
    const classes = useStyles();
    return (
      <Box className={classes.container}>
        <Typography className={`${classes.title} costTable`} variant={TV.BASE} weight={TW.BOLD}>
          Cost
        </Typography>
        <Typography className={classes.title} variant={TV.BASE} weight={TW.BOLD}>
          Price To Customer
        </Typography>
      </Box>
    );
  };

  // Calculate new totals and markup value depending on whether hours for billing rates or payroll rates adjusted
  const calculateTotals = (hourlyRateType, form, modifiedFormValues, totalField) => {
    const sum = modifiedFormValues[hourlyRateType].reduce(
      (acc, b) => (b?.[totalField] ? b[totalField] + acc : acc),
      0
    );
    if (hourlyRateType === LABOR_CONSTANTS.payrollCosts) {
      const numOfCostHours = calculateQuantity({ modifiedFormValues, hourlyRateType });
      const averageUnitCost = calculateAverageUnitAmount({
        quantity: modifiedFormValues.quantity || 0,
        costQuantity: numOfCostHours ?? 0,
        sum
      });

      const totalCost = averageUnitCost * modifiedFormValues.quantity;
      const markupValue = convertForMathLib(
        calculateMarkup,
        totalCost,
        modifiedFormValues.totalPrice
      );

      form.setValues({
        ...form.values,
        [LABOR_FIELDS.AVERAGE_UNIT_COST]: averageUnitCost,
        [LABOR_FIELDS.COST_QUANTITY]: numOfCostHours,
        [LABOR_FIELDS.MARKUP]: markupValue,
        [LABOR_FIELDS.MARGIN]: convertForMathLib(calculateMarginFromMarkup, markupValue),
        [totalField]: sum,
        [hourlyRateType]: modifiedFormValues[hourlyRateType]
      });
    }

    if (hourlyRateType === LABOR_CONSTANTS.billingRates) {
      const numOfBillingHours = calculateQuantity({ modifiedFormValues, hourlyRateType });
      const averageUnitPrice = calculateAverageUnitAmount({
        sum,
        quantity: numOfBillingHours
      });
      const averageUnitCost = calculateAverageUnitAmount({
        quantity: numOfBillingHours,
        sum: modifiedFormValues.totalCost
      });

      const totalCost = averageUnitCost * numOfBillingHours;
      const totalPrice = averageUnitPrice * numOfBillingHours;
      const markupValue = convertForMathLib(calculateMarkup, totalCost, totalPrice);

      form.setValues({
        ...form.values,
        [LABOR_FIELDS.AVERAGE_UNIT_COST]: averageUnitCost,
        [LABOR_FIELDS.AVERAGE_UNIT_PRICE]: averageUnitPrice,
        [LABOR_FIELDS.QUANTITY]: numOfBillingHours,
        [LABOR_FIELDS.MARKUP]: markupValue,
        [LABOR_FIELDS.MARGIN]: convertForMathLib(calculateMarginFromMarkup, markupValue),
        [LABOR_FIELDS.TOTAL_COST]: form.values.totalCost === null ? 0 : form.values.totalCost,
        [totalField]: totalPrice,
        [hourlyRateType]: modifiedFormValues[hourlyRateType]
      });
    }
  };

  const handleHoursChange = ({ form, field, currentValue }, hourTypeId) => {
    const fieldName = field.name;
    const hourlyRateType = fieldName.includes('Payroll')
      ? LABOR_CONSTANTS.payrollCosts
      : LABOR_CONSTANTS.billingRates;

    const totalField = fieldName.includes('Payroll')
      ? LABOR_FIELDS.TOTAL_COST
      : LABOR_FIELDS.TOTAL_PRICE;
    // Calculate subtotal for specific rate type (i.e Regular, overtime) based on hours entered

    const decimalCount = num => {
      const numStr = String(num);
      return numStr.includes('.') ? numStr.split('.')[1].length : 0;
    };

    let hourAmt;
    if (currentValue === '' || Number.isNaN(Number(currentValue))) {
      hourAmt = null;
    } else if (decimalCount(currentValue) >= 2) {
      hourAmt = convertForMathLib(roundCurrency, currentValue);
    } else {
      hourAmt = Number(currentValue);
    }

    const modifiedRates = form.values[hourlyRateType].map(rateType =>
      rateType.billingHourTypeId === hourTypeId || rateType.payrollHourTypeId === hourTypeId
        ? {
            ...rateType,
            hours: hourAmt,
            [totalField]: (rateType.unitCost || rateType.unitPrice) * Number(currentValue)
          }
        : rateType
    );
    const modifiedFormValues = {
      ...form.values,
      [hourlyRateType]: modifiedRates
    };

    calculateTotals(hourlyRateType, form, modifiedFormValues, totalField);
  };

  // Updating the Avg Unit Price, Markup, or Quantity fields causes the billing rates/markup/avg unit price to manually update.
  // All billing rates are set to the newly calculated rate, and all hours are allocated to the regular rate
  const handleUnitTotalsChange = ({ form, field, currentValue }) => {
    const { unitCost: averageUnitCost, totalPrice, quantity } = form.values;
    if (field.name === LABOR_FIELDS.MARKUP) {
      const avgUnitPrice = convertForMathLib(
        calculateUnitPriceWithMarkup,
        averageUnitCost,
        currentValue
      );
      const newAvgUnitPrice = avgUnitPrice;
      const newBillingRates = setNewBillingRates({
        billingRates: form.values[LABOR_CONSTANTS.billingRates],
        newAvgUnitPrice,
        hours: quantity
      });

      form.setFieldValue(LABOR_FIELDS.MARKUP, currentValue);
      form.setFieldValue(
        LABOR_FIELDS.MARGIN,
        convertForMathLib(calculateMarginFromMarkup, currentValue)
      );
      form.setFieldValue(LABOR_FIELDS.AVERAGE_UNIT_PRICE, newAvgUnitPrice);
      form.setFieldValue(LABOR_FIELDS.BILLING_RATES, newBillingRates);
      form.setFieldValue(LABOR_FIELDS.TOTAL_PRICE, avgUnitPrice * quantity);
    }

    if (field.name === LABOR_FIELDS.MARGIN) {
      const avgUnitPrice = convertForMathLib(
        calculateUnitPriceWithMargin,
        averageUnitCost,
        currentValue
      );
      const newAvgUnitPrice = avgUnitPrice;
      const newBillingRates = setNewBillingRates({
        billingRates: form.values[LABOR_CONSTANTS.billingRates],
        newAvgUnitPrice,
        hours: quantity
      });

      form.setFieldValue(LABOR_FIELDS.MARGIN, currentValue);
      form.setFieldValue(
        LABOR_FIELDS.MARKUP,
        convertForMathLib(calculateMarkupFromMargin, currentValue)
      );
      form.setFieldValue(LABOR_FIELDS.AVERAGE_UNIT_PRICE, newAvgUnitPrice);
      form.setFieldValue(LABOR_FIELDS.BILLING_RATES, newBillingRates);
      form.setFieldValue(LABOR_FIELDS.TOTAL_PRICE, avgUnitPrice * quantity);
    }

    if (field.name === LABOR_FIELDS.QUANTITY) {
      const quantityInt = Number(currentValue);
      const newAvgUnitPrice = calculateAverageUnitAmount({
        quantity: quantityInt,
        sum: totalPrice
      });
      const newBillingRates = setNewBillingRates({
        billingRates: form.values[LABOR_CONSTANTS.billingRates],
        newAvgUnitPrice,
        hours: quantityInt
      });
      const totalCost = averageUnitCost * quantityInt;
      const markupValue = convertForMathLib(calculateMarkup, totalCost, totalPrice);
      const marginValue = convertForMathLib(calculateMargin, totalCost, totalPrice);
      form.setFieldValue(LABOR_FIELDS.AVERAGE_UNIT_PRICE, newAvgUnitPrice);
      form.setFieldValue(LABOR_FIELDS.BILLING_RATES, newBillingRates);
      form.setFieldValue(LABOR_FIELDS.MARKUP, markupValue);
      form.setFieldValue(LABOR_FIELDS.MARGIN, marginValue);
      form.setFieldValue(LABOR_FIELDS.QUANTITY, quantityInt);
    }

    if (field.name === LABOR_FIELDS.AVERAGE_UNIT_PRICE) {
      const roundedUnitPrice = convertForMathLib(roundCurrency, currentValue);
      form.setFieldValue(LABOR_FIELDS.AVERAGE_UNIT_PRICE, roundedUnitPrice);
      const newBillingRates = setNewBillingRates({
        billingRates: form.values[LABOR_CONSTANTS.billingRates],
        newAvgUnitPrice: currentValue,
        hours: quantity
      });
      const newTotalPrice = quantity * roundedUnitPrice;
      const totalCost = averageUnitCost * quantity;
      const markupValue = convertForMathLib(calculateMarkup, totalCost, newTotalPrice);
      const marginValue = convertForMathLib(calculateMarginFromMarkup, markupValue);
      form.setFieldValue(LABOR_FIELDS.TOTAL_PRICE, newTotalPrice);
      form.setFieldValue(LABOR_FIELDS.BILLING_RATES, newBillingRates);
      form.setFieldValue(LABOR_FIELDS.MARKUP, markupValue);
      form.setFieldValue(LABOR_FIELDS.MARGIN, marginValue);
    }
  };

  const handleFormSubmit = async () => {
    setIsSubmitting(true);

    try {
      const laborLineItemArr = allSelectedLaborTypes.map(estimatedTask =>
        formatLaborLineItemBeforeSaving(estimatedTask)
      );
      const response = await updateLaborLineItems({ laborLineItemArr, taskId: item.id });
      if (response) {
        updateTasksAndGroups({
          groups: response.quoteTransition.quoteTaskGroups.items,
          tasks: response.quoteTransition.quoteLineTasks.items,
          setTaskGroups
        });
        setSelectedLaborTypes([]);
      }
    } catch (error) {
      logErrorWithCallback(
        error,
        snackbarOn,
        'Unable to update labor item, please try again later'
      );
    } finally {
      setIsSubmitting(false);
      setIsLaborModalOpen({
        isOpen: false,
        type: null
      });
    }
  };

  return (
    <div style={{ width: '65%', margin: 'auto' }}>
      {idx === 0 && (
        <>
          <Typography variant={TV.BASE} weight={TW.BOLD}>
            Labor Estimation
          </Typography>
          <Typography color="#808080" variant={TV.S3}>
            {LABOR_CONSTANTS.estimationMsg}
          </Typography>
        </>
      )}
      <MUIForm
        configuration={AddLaborForm({
          laborRates,
          payrollHourTypes,
          selectedLaborType,
          index: idx,
          handleHoursChange,
          handleUnitTotalsChange,
          isMarginEnabled,
          isMarkupEnabled,
          enterpriseLaborCostingEnabled: flags[FeatureFlags.ENTERPRISE_LABOR_COSTING]
        })}
        customComponents={{
          SectionHeaders,
          CustomFieldWithLabel
        }}
        data={selectedLaborType}
        layout="edit"
        onComplete={handleFormSubmit}
        onCreateService={service => setFormService(service)}
        onFormChange={formValues => {
          setSelectedLaborTypes(prev => {
            const newState = [...prev];

            // Selecting a labor type from dropdown
            if (
              prev[idx]?.labourTypeId !== formValues.labourTypeId ||
              prev[idx]?.labourRateGroupId !== formValues.labourRateGroupId ||
              prev[idx]?.labourRateId !== formValues.labourRateId
            ) {
              const [labourRateGroupId, labourTypeId] = formValues.labourRateId?.split(' - ') || [
                '',
                ''
              ];
              const selectedLaborOption = laborRates.find(
                type =>
                  type.labourTypeId === labourTypeId &&
                  (!type.labourRateGroupId || type.labourRateGroupId === labourRateGroupId)
              );
              const resetLaborType = (rateType, hourTypeId) =>
                selectedLaborOption[rateType].map(
                  elem => ({
                    ...elem,
                    hours: null,
                    id: formValues[rateType].find(
                      formItem => formItem[hourTypeId] === elem[hourTypeId]
                    )?.id
                  }) // Reset all hours when switching labor types and retain ID to properly use update mutation
                );

              const billingHours = formValues?.quoteLineLaborBillingHours
                ? resetLaborType(LABOR_CONSTANTS.billingRates, LABOR_CONSTANTS.billingHourTypeId)
                : selectedLaborOption?.quoteLineLaborBillingHours; // Selecting labor type for first time
              const payrollHours = formValues?.quoteLineLaborPayrollHours
                ? resetLaborType(LABOR_CONSTANTS.payrollCosts, LABOR_CONSTANTS.payrollHourTypeId)
                : selectedLaborOption?.quoteLineLaborPayrollHours;

              newState[idx] = {
                quoteLineLaborBillingHours: billingHours,
                quoteLineLaborPayrollHours: payrollHours,
                labourRateId: `${selectedLaborOption?.labourRateGroupId} - ${selectedLaborOption?.labourTypeId}`,
                labourTypeId: selectedLaborOption?.labourTypeId,
                labourRateGroupId: selectedLaborOption?.labourRateGroupId,
                id: prev[idx]?.id ? prev[idx].id : null,
                markup: null,
                margin: null,
                unitPrice: null,
                quantity: null,
                unitCost: null,
                totalCost: null,
                totalPrice: null
              };
              return newState;
            }

            const labourRateId = `${prev[idx]?.labourRateGroupId} - ${prev[idx]?.labourTypeId}`;
            newState[idx] = { ...prev[idx], ...selectedLaborType, ...formValues, labourRateId };
            return newState;
          });
        }}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedLaborForm = connect(mapStateToProps, mapDispatcherToProps)(LaborForm);

export default reduxConnectedLaborForm;

LaborForm.propTypes = {
  allSelectedLaborTypes: PropTypes.array,
  idx: PropTypes.number,
  item: PropTypes.object,
  laborRates: PropTypes.array,
  payrollHourTypes: PropTypes.array,
  selectedLaborType: PropTypes.object,
  setFormService: PropTypes.func,
  setIsLaborModalOpen: PropTypes.func,
  setIsSubmitting: PropTypes.func,
  setSelectedLaborTypes: PropTypes.func,
  setTaskGroups: PropTypes.func,
  updateLaborLineItems: PropTypes.func
};

LaborForm.defaultProps = {
  allSelectedLaborTypes: [],
  idx: null,
  item: {},
  laborRates: [],
  payrollHourTypes: [],
  selectedLaborType: {},
  setFormService: () => {},
  setIsLaborModalOpen: () => {},
  setIsSubmitting: () => {},
  setSelectedLaborTypes: () => {},
  setTaskGroups: () => {},
  updateLaborLineItems: []
};
