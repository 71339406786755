import React, { useMemo } from 'react';

import { ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';

import { useTheme } from '@material-ui/core';

import { timesheetViews } from '../../constants';

import HourlyTotals from './HourlyTotals';

const useStyles = theme => ({
  fixedSection: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100vw',
    backgroundColor: theme.palette.grayscale(95),
    borderTop: 'solid 1px',
    borderColor: theme.palette.grayscale(80),
    padding: 24
  },
  footerContent: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

const TabFooter = ({ dayOrWeek, weekDate, weekDates, payrollHourTypes, children }) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const validHourTypes = useMemo(() => {
    if (dayOrWeek === timesheetViews.DAY) {
      return weekDate.validHourTypes;
    }
    const unsortedHourTypesToShow = weekDates.reduce(
      (hourTypes, date) =>
        date.workEvents.length > 0 ? [...hourTypes, ...date.validHourTypes] : hourTypes,
      []
    );
    return payrollHourTypes.reduce(
      (hourTypes, type) =>
        unsortedHourTypesToShow.includes(type.hourTypeAbbreviation)
          ? [...hourTypes, type.hourTypeAbbreviation]
          : hourTypes,
      []
    );
  }, [payrollHourTypes, weekDate, weekDates, dayOrWeek]);

  const hourlyTotals = useMemo(() => {
    if (dayOrWeek === timesheetViews.DAY) {
      return weekDate.dailyTotals;
    }

    return payrollHourTypes.reduce((acc, p) => {
      return {
        ...acc,
        [p.hourTypeAbbreviation]: weekDates.reduce((hourTotal, day) => {
          const { dailyTotals } = day;
          return hourTotal + dailyTotals[p.hourTypeAbbreviation];
        }, 0)
      };
    }, {});
  }, [payrollHourTypes, weekDate, weekDates, dayOrWeek]);

  return (
    <ThemeProvider>
      <div css={styles.fixedSection}>
        <div css={styles.footerContent}>
          <>
            <Typography style={{ marginTop: 10 }} variant={TV.L} weight={TW.BOLD}>
              {dayOrWeek === timesheetViews.WEEK ? 'Weekly' : 'Daily'} Totals
            </Typography>
            <HourlyTotals
              hourlyTotals={hourlyTotals}
              payrollHourTypes={payrollHourTypes}
              validHourTypes={validHourTypes}
            />
          </>
          {children}
        </div>
      </div>
      {/* empty div to allow scrolling to bottom of page */}
      <div
        style={{
          height: dayOrWeek === timesheetViews.WEEK ? 131 : 171
        }}
      />
    </ThemeProvider>
  );
};

export default TabFooter;
