import React, { Component } from 'react';

import { Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { sortBy } from 'lodash';
import { connect } from 'react-redux';

import {
  AddRecordButton,
  ConfirmModal,
  Context,
  ResponsiveTable,
  Spinner,
  UserPermission
} from 'components';
import Labels from 'meta/labels';
import employeeLayout, { tableProperties } from 'meta/Settings/People/Employee';
import { setPageMode, snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { CompanyService } from 'services/core';
import { Logger } from 'services/Logger';
import { capitalizeFirstLetter, processAddressArrayAsJson, removeNestedJson } from 'utils';
import { EmployeeStatus, PermissionConstants } from 'utils/AppConstants';

import Employee from './Employee';

class People extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.CompanyService = new CompanyService();
    const numberOfRoles = props?.appRoles?.length || 0;

    this.state = {
      hasRoles: numberOfRoles > 0,
      openEmployee: false,
      modalMode: '',
      dataRecord: '',
      queryResult: '',
      employeeWithDepartmentSkillAndRoles: '',
      refetch: () => this.componentDidMount(),
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: '',
      showCurrentEmployee: true
    };
  }

  componentDidMount = async () => {
    const sortKey = `${this.props.user.tenantId}_Company_${this.props.user.tenantCompanyId}`;
    try {
      const { data } = await this.CompanyService.getCompanyWithEmployees(
        `${this.props.user.tenantId}`,
        sortKey
      );

      if (data && data.getCompany) {
        const employeeWithDepartmentSkillAndRoles = this.processQueryResultForEmployees(data);
        if (this.mounted) {
          this.setState({
            queryResult: data,
            employeeWithDepartmentSkillAndRoles,
            confirmDialog: false,
            confirmAction: '',
            confirmMessage: ''
          });
        }
      }
    } catch (error) {
      Logger.error(`Error in fetching data ${JSON.stringify(error)}`);
      this.props.snackbarOn('error', 'Unable to fetch Company data, please try again later', error);
      /* if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        this.props.snackbarOn('error', error.graphQLErrors[0].message);
      } else {
        this.props.snackbarOn('error', 'Unable to fetch data, please try again later');
      } */
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleEmployeeOpen = () => {
    this.setState({ openEmployee: true, modalMode: 'new', dataRecord: '' });
  };

  handleClose = () => {
    this.setState({
      openEmployee: false,
      modalMode: '',
      dataRecord: '',
      showCurrentEmployee: false
    });
  };

  inactivateEmployee = async employeeDetails => {
    try {
      const { data } = await this.CompanyService.inactivateEmployee(employeeDetails);
      if (data) {
        await this.componentDidMount();
        this.props.snackbarOn(
          'success',
          `Successfully deactivated \n ${employeeDetails.firstName} ${employeeDetails.lastName}`
        );
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to deactivate, please try again later', error);
      /* if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        this.props.snackbarOn('error', error.graphQLErrors[0].message);
      } else {
        this.props.snackbarOn('error', 'Unable to deactivate, please try again later');
      } */

      this.setState({
        confirmDialog: false,
        confirmAction: '',
        confirmMessage: ''
      });
    }
  };

  handleRowActions = (mode, record, refetch) => {
    if (mode === 'delete') {
      this.setState({
        confirmDialog: true,
        confirmAction: async () => this.inactivateEmployee(record),
        confirmMessage: 'Are you sure you want to deactivate employee'
      });
    } else {
      this.setState({
        openEmployee: true,
        modalMode: mode,
        dataRecord: record,
        refetch
      });
    }
  };

  processQueryResultForEmployees = data => {
    const queryResult = data.getCompany.employees.items;
    const companyInfo = removeNestedJson(data.getCompany);
    const processedResult = [];

    queryResult.map(result => {
      const { departments, appRoles, skills, ...rest } = result;
      let addressJson = {};

      rest.loginStatusDisplay = [
        EmployeeStatus.ACTIVE,
        EmployeeStatus.DEACTIVATED,
        EmployeeStatus.INVITED
      ].includes(rest.status)
        ? capitalizeFirstLetter(rest.status)
        : 'No Login';

      rest.activeStatus = rest.isActive ? 'Active' : 'Inactive';

      // process address for view mode
      // address JSON is needed for new and edit mode
      if (rest.contactAddresses) {
        addressJson = processAddressArrayAsJson(rest.contactAddresses.items);
      }

      const transformedData = { ...rest, ...addressJson };
      // let localDepartments = result.departments;
      // let localRoles = result.appRoles;

      // iterate departments and flatten the JSON
      let departmentString = '';
      const departmentMapping = [];
      const processedDepartment = [];
      if (departments?.items) {
        const sortedDepartments = sortBy(departments.items, 'mappedEntity.tagName');
        sortedDepartments.forEach((department, index) => {
          departmentString += (department.mappedEntity || {}).tagName || '';
          departmentMapping.push(department.sortKey);
          departmentMapping.push(department.invertedSortKey);
          if (index < departments.items.length - 1) {
            departmentString += ', ';
          }
          processedDepartment.push({
            departmentName: (department.mappedEntity || {}).tagName || '',
            departmentId: (department.mappedEntity || {}).id || '',
            parent: department
            // adding skill id for deleteion scenario
            // skillIds:
          });
        });
        transformedData.departments = processedDepartment;
      }

      transformedData.departmentString = departmentString;
      // departmentMapping assignment to transform data is moved below after skills

      // iterate appRoles and flatten the JSON
      let roleString = '';
      const roleMapping = [];
      if (appRoles) {
        appRoles.items.map((role, index) => {
          roleString += (role.mappedEntity || {}).tagName || '';
          roleMapping.push(role.sortKey);
          roleMapping.push(role.invertedSortKey);
          if (index < appRoles.length - 1) {
            roleString += ',';
          }
          return roleString;
        });

        // for now assuming only one value
        if (appRoles.items.length === 1) {
          transformedData.role = (appRoles.items[0].mappedEntity || {}).tagName;
          transformedData.roleId = (appRoles.items[0].mappedEntity || {}).id;
        }
      }
      transformedData.roleString = roleString;
      transformedData.roleMapping = roleMapping;
      transformedData.landlinePhone = transformedData.landlinePhone || '-';

      // process selected skills
      const transformedSkillArray = [];
      const skillIds = [];
      const mappingSkills = [];
      if (skills) {
        skills.items.map(skill => {
          skillIds.push(skill.mappedEntityId);
          // as skills needs to be deleted when department is changed,
          // adding to department mapping itself
          departmentMapping.push(skill.sortKey);
          departmentMapping.push(skill.invertedSortKey);
          const { mappedEntity, ...others } = skill;
          mappedEntity.parent = others;
          return transformedSkillArray.push(mappedEntity);
        });
        transformedData.skills = transformedSkillArray;
        transformedData.skillIds = skillIds;
        transformedData.mappingSkills = mappingSkills;
      }
      transformedData.parent = companyInfo;
      transformedData.departmentMapping = departmentMapping;
      transformedData.sortKey = rest.sortKey;
      transformedData.userName = transformedData.userName || transformedData.email;
      return processedResult.push(transformedData);
    });

    // add related skills system entity keys to departments as related
    // transformedData.departments.forEach(department => {
    //   department.
    // })
    return {
      employeeWithDepartmentSkillAndRoles: processedResult.sort(
        (left, right) => left.lastUpdatedDateTime - right.lastUpdatedDateTime
      )
    };
  };

  handleCancelConfirmation = () => {
    this.setState({
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: ''
    });
  };

  render() {
    const employeeLayoutMeta = employeeLayout.entity.layouts.web;
    const { buttons } = employeeLayoutMeta;
    const {
      employeeWithDepartmentSkillAndRoles,
      dataRecord,
      showCurrentEmployee,
      hasRoles
    } = this.state;

    // set button actions
    if (buttons) {
      if (buttons.cancel) buttons.cancel.action = this.handleClose;
      if (buttons.edit) {
        buttons.edit.action = () => this.props.changePageMode('edit');
      }
    }

    if (employeeWithDepartmentSkillAndRoles === '') {
      return <Spinner />;
    }

    if (this.props.openCurrentEmployee && showCurrentEmployee) {
      const currentEmployees = employeeWithDepartmentSkillAndRoles.employeeWithDepartmentSkillAndRoles.filter(
        employee => employee.id === this.props.user.employeeId
      );
      if (!dataRecord) {
        this.handleRowActions('view', currentEmployees[0], () => this.componentDidMount());
      }
    }

    return (
      <ErrorBoundaries>
        <UserPermission action={PermissionConstants.OBJECT_EMPLOYEE} I="create">
          <Box>
            <AddRecordButton
              disabled={!hasRoles}
              handle={this.handleEmployeeOpen}
              icon={AddIcon}
              label={Labels['Add employees'][this.props.user.locale]}
            />
          </Box>
        </UserPermission>
        <ResponsiveTable
          caslKey={PermissionConstants.OBJECT_EMPLOYEE}
          data={
            this.state.employeeWithDepartmentSkillAndRoles[tableProperties.dataPathInQueryResult]
          }
          noDataMsg={tableProperties.noDataMsg}
          rowActionButtons={{
            view: {
              label: 'View',
              caslAction: 'read',
              icon: 'Launch'
            },
            edit: {
              label: 'Edit',
              caslAction: 'update',
              icon: 'Edit'
            }
          }}
          rowActions={this.handleRowActions}
          rowMetadata={tableProperties.captionAttributes}
        />
        {this.state.openEmployee && (
          <Employee
            data={this.state.dataRecord}
            handleClose={this.handleClose}
            isOpen={this.state.openEmployee}
            mode={this.state.modalMode}
            parent={this.state.dataRecord.parent}
            queryResult={this.state.queryResult}
            refetch={() => this.componentDidMount()}
            snackbar={this.props.snackbarOn}
            user={this.props.user}
          />
        )}
        <ConfirmModal
          cancel={this.handleCancelConfirmation}
          confirm={this.state.confirmAction}
          message={this.state.confirmMessage}
          open={this.state.confirmDialog}
          override
        />
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});

const mapDispatchToProps = dispatch => ({
  changePageMode: pageMode => dispatch(setPageMode(pageMode)),
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const connectedPeople = connect(mapStateToProps, mapDispatchToProps)(People);

export default connectedPeople;
