import React, { Component } from 'react';

import { merge } from 'lodash';
import { connect } from 'react-redux';

import { SergeantModal } from 'components';
import { attachmentToFileInput, fileInputToAttachment } from 'components/AttachmentSection/helpers';
import AttachmentReviewSergeantLayout from 'meta/attachment-review-sergeant-layout';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { CommonService } from 'services/core';
import { Logger } from 'services/Logger';
import { Mode } from 'utils/constants';

class Attachment extends Component {
  constructor(props) {
    super(props);
    this.CommonService = new CommonService();
    this.state = {
      data: props.data
    };
  }

  componentDidMount = () => {
    if (this.state.data) {
      const fileType =
        this.state.data.fileUrl?.match(/\.(jpeg|jpg|gif|png)$/) != null ? 'image' : 'video';
      attachmentToFileInput(this.state.data, fileType)
        .then(data => this.setState({ data }))
        .catch(e => Logger.error(e));
    }
  };

  handlePrimaryAction = async (newData, stopLoading) => {
    const { user, parent, onComplete, setStopLoading } = this.props;
    setStopLoading(stopLoading);
    const { data } = this.state;
    const finalData = { ...newData, parent };
    if (!data?.id || data.newFiles !== newData.newFiles) {
      const attachmentData = await fileInputToAttachment(user.tenantId, newData, newData.type);
      merge(finalData, attachmentData);
    }
    if (!finalData.customFileName) finalData.customFileName = finalData.fileName ?? '';
    onComplete(finalData);
  };

  render() {
    const { open, mode, title, handleClose } = this.props;
    const { data } = this.state;
    if (data && data.fileName && !data.customFileName) {
      data.customFileName = data.fileName;
    }
    let formVersion = mode;
    if (formVersion === Mode.NEW) formVersion = Mode.EDIT;
    return (
      <ErrorBoundaries>
        <SergeantModal
          data={this.state.data}
          formVersion={formVersion}
          handleClose={handleClose}
          handlePrimaryAction={this.handlePrimaryAction}
          layout={AttachmentReviewSergeantLayout}
          mode={mode}
          open={open}
          title={title}
        />
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = { snackbarOn };

const reduxConnectedAddAttachment = connect(mapStateToProps, mapDispatcherToProps)(Attachment);

export default reduxConnectedAddAttachment;
