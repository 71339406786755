import gql from 'graphql-tag';

export const getMaintenanceTemplatesQuery = gql`
  query serviceAgreement($partitionKey: String, $id: String!) {
    serviceAgreement(partitionKey: $partitionKey, id: $id) {
      maintenanceTemplates(entityConnection: "MaintenanceTemplate") {
        items {
          id
          serviceAgreementId
          propertyId
          customerPropertyRepId
          customerProperty {
            id
            companyName
          }
          schedule
          firstDueDate
          numberOfOccurrences
          endDate
          jobTagsJSON
          serviceDescription
          budgetedHours
          estimatedVisits
          numberOfTechs
          totalBudgetedHours
          preferredTechnicians {
            departmentId
            crewId
            primaryTechnicianId
            additionalTechnicianIds
          }
          maintenanceTaskTemplates(entityConnection: "MaintenanceTaskTemplate") {
            items {
              id
              maintenanceTemplateId
              assetId
              assetTypeId
              sortOrder
              asset {
                id
                assetName
                assetTypeId
                maintenanceTaskRequiredParts {
                  items {
                    id
                    productId
                  }
                }
              }
              maintenanceTaskName
              maintenanceTaskDescription
              maintenanceTaskRequiredParts(entityConnection: "MaintenanceTaskRequiredPart") {
                items {
                  id
                  quantity
                  productId
                  product {
                    id
                    name
                    description
                    code
                  }
                }
              }
              maintenanceTaskForms {
                items {
                  id
                  formId
                  isRequired
                  form {
                    id
                    name
                  }
                }
              }
            }
          }
          maintenanceType(entityConnection: "JobType") {
            tagName
            tagType
            id
            sortKey
          }
          version
        }
      }
    }
  }
`;

export const addMaintenanceTemplatesToServiceAgreementMutation = gql`
  mutation addMaintenanceTemplatesToServiceAgreement(
    $data: AddMaintenanceTemplatesToServiceAgreementInput!
  ) {
    addMaintenanceTemplatesToServiceAgreement(data: $data) {
      id
    }
  }
`;

export const updateMaintenanceTemplateMutation = gql`
  mutation updateMaintenanceTemplate($data: MaintenanceTemplateUpdateInput!) {
    updateMaintenanceTemplate(data: $data) {
      id
    }
  }
`;

export const deleteMaintenanceTemplateMutation = gql`
  mutation deleteMaintenanceTemplate($id: String!) {
    deleteMaintenanceTemplate(id: $id) {
      id
    }
  }
`;

export const addPropertyAssetsToCustomerProperty = gql`
  mutation addPropertyAssetsToCustomerProperty(
    $partitionKey: String
    $data: AddPropertyAssetsToCustomerPropertyInput!
  ) {
    addPropertyAssetsToCustomerProperty(partitionKey: $partitionKey, data: $data) {
      id
    }
  }
`;

export const getServiceAgreementsForAsset = gql`
  query getPropertyAssetById($id: String!) {
    getPropertyAssetById(id: $id) {
      serviceAgreements {
        id
        agreementName
      }
    }
  }
`;

export default getMaintenanceTemplatesQuery;
