import React, { Fragment, useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PropTypes from 'prop-types';

import { addToFilters, isSelected, removeFromFilters } from './helpers';
import useStyles from './styles';

export default function FilterMenu({
  refineList,
  isVisible,
  filters,
  selectedFilters,
  updateSelectedFilters
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempFilters, setTempFilters] = useState([]);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  useEffect(() => {
    setTempFilters(selectedFilters);
  }, [selectedFilters]);
  if (!isVisible || refineList.length === 0) return null;

  const filterNames = Object.keys(filters);
  const filterItems = filterNames.map(name => filters[name].items);

  const isEmptyFilter = [filterNames, filterItems.flat()].some(val => val.length === 0);

  const handleFilterClick = (filter, checked) => {
    const action = checked ? removeFromFilters : addToFilters;
    action(filter, tempFilters, setTempFilters);
  };

  const clearAll = () => setTempFilters([]);

  const applyFilter = () => {
    setAnchorEl(null);
    updateSelectedFilters(tempFilters);
  };

  const renderMenuItem = filterName => {
    const { items = [], name } = filters[filterName];

    if (items.length === 0) return null;
    const refinement = refineList.find(({ attribute }) => attribute === name);

    return (
      <Grid className={classes.itemWrapper} container>
        <Typography className={classes.itemHeading} variant="h6">
          {refinement?.label ?? name}
        </Typography>
        {items.map(item => {
          const modifiedItem = { ...item, attribute: filterName };
          const checked = isSelected(modifiedItem, tempFilters);
          const controlCheckbox = (
            <Checkbox
              checked={checked}
              checkedIcon={<CheckBoxIcon className={`${classes.checkbox} ${classes.selected}`} />}
              icon={<CheckBoxOutlineBlankIcon className={classes.checkbox} />}
              onClick={event => {
                event.preventDefault();
                handleFilterClick(modifiedItem, checked);
              }}
            />
          );
          const labelClassName = `${classes.filterAction} ${checked ? classes.selected : ''}`;
          const controlLabel = (
            <Typography className={labelClassName} variant="subtitle1">
              {item.label}
            </Typography>
          );

          return (
            <MenuItem className={classes.menuItem}>
              <FormControlLabel
                className={classes.formControl}
                control={controlCheckbox}
                label={controlLabel}
              />
            </MenuItem>
          );
        })}
      </Grid>
    );
  };

  return (
    <div>
      <Grid className={classes.filterIcon} onClick={event => setAnchorEl(event.currentTarget)}>
        <ArrowDropDown color="primary" />
      </Grid>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        classes={{ paper: classes.popover }}
        className={classes.menu}
        getContentAnchorEl={null}
        keepMounted
        open={open}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setAnchorEl(null)}
      >
        {filterNames.map(renderMenuItem)}
        <Grid className={classes.filterActionWrapper}>
          {!isEmptyFilter && (
            <>
              <Button className={classes.filterAction} variant="subtitle1" onClick={clearAll}>
                Reset
              </Button>
              <Typography variant="subtitle1">|</Typography>
              <Button className={classes.filterAction} variant="subtitle2" onClick={applyFilter}>
                Apply
              </Button>
            </>
          )}
          {isEmptyFilter && <Typography variant="body2">No Filters found</Typography>}
        </Grid>
      </Menu>
    </div>
  );
}

FilterMenu.propTypes = {
  filters: PropTypes.object,
  refineList: PropTypes.array.isRequired,
  isVisible: PropTypes.bool.isRequired
};
