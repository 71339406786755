import React, { useCallback } from 'react';

import { Checkbox, Typography } from '@BuildHero/sergeant';
import { Grid } from '@material-ui/core';

import { bool, object } from 'prop-types';
import { useSelector } from 'react-redux';

import withLazyMount from 'utils/withLazyMount';

import { selectCustomerSignatures } from '../../selectors';

import ReportSection from '../ReportSection';

import { useReviewReportDisabled } from '../ReviewReport/ReviewReport.contexts';

import SignatureInfoSection from './components/SignatureInfoSection';
import { useToggleCustomerSignature } from './components/SignatureInfoSection/SignatureInfoSection.hooks';
import { useStyles } from './components/SignatureInfoSection/SignatureInfoSection.styles';

const SignatureSection = ({ reviewReport, isReviewReport, visit }) => {
  const user = useSelector(state => state.user);
  const { disabled } = useReviewReportDisabled();
  const [toggleCustomerSignature, { loading: updateLoading }] = useToggleCustomerSignature();
  const signatures = selectCustomerSignatures(isReviewReport ? reviewReport : visit) || [];
  const handleToggle = useCallback(async () => {
    await toggleCustomerSignature({
      tenantId: user.tenantId,
      signatureId: signatures?.[0]?.id,
      version: signatures?.[0]?.version,
      includeInInvoice: !signatures?.[0]?.includeInInvoice
    });
  }, [toggleCustomerSignature, user, signatures]);

  const styles = useStyles();
  const title = 'Customer Signature';

  return (
    <ReportSection title={title}>
      <SignatureInfoSection signatures={signatures} />
      {isReviewReport && signatures.length > 0 && (
        <Grid alignItems="center" container>
          <Grid item>
            <Checkbox
              checked={signatures[0]?.includeInInvoice}
              css={styles.button}
              disabled={updateLoading || disabled}
              onChange={handleToggle}
            />
          </Grid>
          <Grid item>
            <Typography> Add to invoice </Typography>
          </Grid>
        </Grid>
      )}
    </ReportSection>
  );
};

SignatureSection.propTypes = {
  reviewReport: object,
  visit: object,
  isReviewReport: bool
};

SignatureSection.defaultProps = {
  reviewReport: {},
  visit: {},
  isReviewReport: false
};

export default withLazyMount(SignatureSection);
