import gql from 'graphql-tag';
import { useDispatch, useSelector } from 'react-redux';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { useSnackbar } from 'customHooks/useSnackbar';
import { startGetJob, startGetJobFailure, startGetJobSuccess } from 'redux/actions/jobActions';
import { formFragment } from 'scenes/Jobs/JobDetail/queries/queryFragment';
import { timesheetEntryBinderFragment } from 'scenes/Timesheets/customHooks/gql';
import { logErrorWithCallback } from 'utils';

const quoteLineProductFragment = `
id
unitCost
unitPrice
quantity
name
description
markupValue
lineItemType
`;

const noteFragment = `
id
note
subject
sortOrder
hideFromTechniciansOnMobile
displayLastUpdatedBy
displayLastUpdatedDateTime
lastUpdatedBy
lastUpdatedDate
lastUpdatedDateTime
parentId
`;

export const lineItemFragment = `
  id
  quantity
  unitCost
  unitPrice
  billingStatus
`;

export const inventoryFragment = `
id
version
addedBy
createdBy
itemName
description
departmentId
unitCost
unitPrice
unitOfMeasure
taxable
sortKey
includeInInvoice
accountingRefId
vendorName
invoiceItemId
invoiceItem {
  id
  parentId
  name
  description
  unitCost
  markupValue
  unitPrice
  quantity
  source
  invoice {
    id
    invoiceNumber
    status
  }
}
revenueType {
  id
  name
  type
}
quantity
productId
markup
jobCostTypeId
jobCostType {
  name
  id
  type
}
costCodeId
costCode {
  name
  id
}
product {
  name
  id
  description
  costCodeId
  code
  type
  sortKey
  accountingRefId
}
truck {
  id
  name
}
billingStatus
source
`;

const employeeFragment = `
id
name
labourTypeId
labourType {
  id
  name
  jobCostTypeId
  revenueTypeId
  costCodeId
  labourTypeBillingHourTypeProducts {
    items {
      id
      billingHourTypeId
      productId
      product {
        id
        name
      }
    }
  }
}
billingRates {
  items {
    id
    rate
    hourType {
      id
      hourType
    }
  }
}
payrollCosts {
  items {
    id
    cost
    hourType {
      id
      hourType
    }
  }
}
truckEmployeeView {
  items {
    id
    parentEntity {
      ... on Truck {
        id
        name
        isActive
      }
    }
  }
}
`;

export const visitFragment = `
id
visitNumber
description
status
sortKey
scheduledFor
reviewStatus
reviewedBy
reviewedUnixDate
techReport
version
departmentName
departmentId
primaryTechs {
  items {
    id
    mappedEntity {
      ... on Employee {
        ${employeeFragment}
      }
    }
  }
}
extraTechs {
  items {
    id
    mappedEntity {
      ... on Employee {
        ${employeeFragment}
      }
    }
  }
}
customerSignatures {
  items {
    id
    version
    nameOfSignee
    signatureImageUrl
    includeInInvoice
    capturedBy
    signedDateTime
    createdDate
    createdBy
  }
}
attachments {
  items {
    id
    customFileName
    fileName
    fileUrl
    type
    comment
    addedBy
    addedDateTime
    createdBy
    createdDateTime
    includeInInvoice
    hideFromTechniciansOnMobile
    version
  }
}
assets: visitAssets(
  filter: { propertyAssetId: { ne: "null" } }
) {
  items {
    id
    asset: propertyAsset {
      id
      assetName
      make
      modelNumber
      serialNo
      installDate
      propertyZone
      location
      imageUrl
    }
  }
}
formData {
  items {
    ${formFragment}
  }
}
labourRateLineItems {
  items {
    id
    visitId
    version
    employeeId
    labourTypeId
    entityType
    labourType {
      id
      name
    }
    version
    createdDate
    createdDateTime
    labourRateBillingHourLines {
      items {
        id
        version
        rate
        version
        hourTypeId
        totalHours
        costCodeId
        revenueTypeId
        timesheetEntryId
        timesheetEntry {
          id
          timesheetEntryBinderId
          timesheetEntryBinder {
            id
            isDismissed
          }
        }
        invoiceItemId
        entityType
        billingStatus
        source
        product {
          id
          name
          description
          unitOfMeasure {
            id
            name
          }
        }
      }
    }
  }
}
reviewReports {
  items {
    labourRateLineItems {
      items {
        id
        employeeId
        labourTypeId
        departmentId
        version
        createdDate
        createdDateTime
        labourRateBillingHourLines {
          items {
            id
            rate
            version
            hourTypeId
            totalHours
            costCodeId
            revenueTypeId
          }
        }
      }
    }
  }
}
timesheetEntryBinders {
  items {
    ${timesheetEntryBinderFragment}
  }
}
timesheetEntriesView {
  items {
    id
    cost
    actualTotalDuration
    actualTotalDurationOverride
    reviewStatus
    approvalStatus
    manualStatus
    costCodeId
    jobCostTypeId
    parentId
    timesheetPeriod {
      parentId
    }
    hourTypeId
    hourType {
      id
      parentId
      hourType
      sortOrder
    }
    billableEntityType
    billableEntityId
    notes {
      items {
        ${noteFragment}
      }
    }
    nonVisitEventId
  }
}
inventoryParts {
  items {
    ${inventoryFragment}
  }
}
summaries {
  items {
    id
    summary
    createdBy
    lastUpdatedBy
    lastUpdatedDateTime
    includeInInvoice
    version
  }
}
notes {
  items {
    ${noteFragment}
  }
}
visitPurchaseOrders {
  items {
    id
    poNumber
    status
    vendorName
    description
    createdBy
    totalAmountPreTax
    poType {
      name
    }
    department {
      tagName
    }
    purchaseOrderReceipts {
      items {
        id
        createdBy
        description
        receiptNumber
        freight
        vendor{
          name
        }
        department{
          tagName
        }
        purchaseOrderReceiptLines {
          items {
            unitCost
            quantity
          }
        }
      }
    }
  }
}
nonVisitEvents {
  items {
    id
    name
    description
    assignedEntityType
    assignedEntityId
    plannedStartTimeUTC
    plannedEndTimeUTC
    status
    eventTypeId
    departmentId
    assignedEntityId
    employeeId
    employee {
      ${employeeFragment}
    }
    parentId
    isActive
    entityType
    createdBy
    createdDate
    createdDateTime
    timesheetEntryBinders {
      items {
        ${timesheetEntryBinderFragment}
      }
    }
    timesheetEntries {
      items {
        id
        reviewStatus
        approvalStatus
        actualTotalDuration
        actualTotalDurationOverride
        cost
        hourTypeId
        hourType {
          id
          hourType
          hourTypeAbbreviation
        }
        userActionType
        billableEntityId
        entryType
        manualStatus
      }
    }
  }
}
`;

const TaskFragment = `
  id
  status
  taskTypeInternal
  visitId
  assetId
  quoteId
  parentId
  taskNumber
  name
  description
  createdBy
  lastUpdatedBy
  isActive
  version
  formData {
    items {
      ${formFragment}
    }
  }
  jobs {
    id
    jobNumber
    customIdentifier
    jobTypeInternal
  }
  taskEntries {
    items {
      id
      quantity
      name
    }
  }
  asset {
    id
    assetName
  }
`;

const getJobById = gql`
  query getJobById($partitionKey: String!, $id: String!) {
    getJobById(partitionKey: $partitionKey, id: $id) {
      id
      tenantId
      jobNumber
      reviewStatus
      procurementStatus
      manualQuoteStatus
      billingStatus
      customIdentifier
      closeoutReport
      version
      priceBookId
      customerName
      customerId
      reviewStatus
      billingCustomerName
      billingCustomerId
      status
      billingType
      purchaseOrderReceiptLines{
        items {
          id
          quantity
          lineNumber
          unitCost
          purchaseOrderId
          purchaseOrderLineId
          productId
          departmentId
          jobId
          entityType
          version
          purchaseOrderReceiptId
          costCodeId
          jobCostTypeId
          revenueTypeId
          taxable
          accountingRefId
          sortKey
          tenantId
          tenantCompanyId
          parentId
          createdBy
          createdDate
          lastUpdatedBy
          lastUpdatedDate
          rangeKey
          partitionKey
          department {
            id
            tagName
          }
          purchaseOrder {
            id
            poNumber
            taxRateId
            taxRate {
              taxRate
              id
            }
          }
          purchaseOrderReceipt {
            id
          }
        }
      }
      purchaseOrders {
        items {
          id
          poNumber
          status
          purchaseOrderLines {
            items {
              id
            }
          }
        }
      }
      billingCustomer {
        id
        customerName
        companyAddresses {
          items {
            billTo
            id
            addressType
            addressLine1
            addressLine2
            city
            state
            zipcode
          }
        }
      }
      jobTypeName
      issueDescription
      customerProvidedPONumber
      customerProvidedWONumber
      customerPropertyId
      customerPropertyName
      customerProperty {
        id
        billTo
        companyAddresses {
          items {
            billTo
            id
            addressType
            addressLine1
            addressLine2
            city
            state
            zipcode
          }
        }
        tasks {
          items {
            ${TaskFragment}
          }
        }
      }
      owner {
        id
        name
      }
      jobJobTags {
        items {
          id
          entityType
          jobTag {
            id
            entityType
            tagName
          }
        }
      }
      billLines {
        items {
          id
          quantity
          description
          remainingQuantity
          lineNumber
          unitCost
          unitPrice
          taxable
          invoicedStatus
          billingStatus       
          version
          product {
            id
            name
            sortKey
            unitOfMeasure {
              id
              name
            }
          }
          bill {
            id
            tax
            purchaseOrderReceiptId
            purchaseOrderReceipt {
              id
              receiptNumber
            }
          }
          purchaseOrderLine {
            ${lineItemFragment}
            markup
          }
        }
      }
    	purchaseOrders {
        items {
          id
          poType {
            name
          }
        }
      }
      purchaseOrderLines(filter: { status: { ne: "Void" } }) {
        items {
          id
          itemName
          description
          quantity
          quantityFulfilled
          unitOfMeasure
          unitCost
          unitPrice
          markup
          version
          billingStatus
          product {
            id
            unitCost
            sortKey
          }
          purchaseOrderLineVisitList {
            items {
              id
              quantityUsed
            }
          }
          purchaseOrderReceiptLines {
            items {
              id
              lineNumber
              purchaseOrderReceiptId
              purchaseOrderReceipt{
                id
                receiptNumber
              }
            }
          }
          purchaseOrder {
            id
            poNumber
            status
          }
        }
      }
      jobTasks {
        items {
          id
          task {
            ${TaskFragment}
          }
        }
      }
      visits {
        items {
          ${visitFragment}
        }
      }
      quoteId
      quote {
        id
        name
        quoteNumber
        customIdentifier
      }
      quoteJobs {
        items {
          id
          quote {
            id
            billingStatus
            name
            description
            quoteNumber
            customIdentifier
            totalAmountQuoted
            totalAmountQuotedOverride
            subTotal
            subTotalOverride
            totalTaxAmount
            versionLabel
            versionNumber
            status
            quoteInvoices {
              items {
                quoteId
                id
                invoice {
                  id
                  status
                  totalAmount
                  subtotal
                  invoiceItems {
                    items {
                      id
                      name
                      source
                      sourceLineItemId
                      description
                      lineItemType
                      quantity
                      unitPrice
                      unitCost
                      markupValue
                      taxable
                      amount
                      quoteId
                      product {
                        name
                        description
                        id
                      }
                    }
                  }
                }
              }
            }
            department {
                id
                tagName
            }
            quoteLineTasks {
              items {
                id
                name
                description
                propertyAsset{
                  id
                  assetName
                  assetType{
                    id
                    tagName
                  }
                }
                quoteTaskGroup{
                  id
                  name
                }
                quoteLineProducts {
                  items {
                    ${quoteLineProductFragment}
                  }
                }
                quoteLineLabors {
                  items {
                    id
                    description
                    labourType {
                      id
                      name
                    }
                    unitCost
                    markup
                    unitPrice
                    quantity
                    totalPrice
                    totalCost
                  }
                }
              }
            }
            quoteLineProducts {
              items {
                ${quoteLineProductFragment}
              }
            }
          }
        }
      }
      amountQuoted
      jobTypeInternal
      departments {
        items {
          id
          mappedEntity {
            ... on Department {
              id
              tagName
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
            }
          }
        }
      }
      notes {
        items {
          ${noteFragment}
        }
      }
      inventoryParts {
        items {
          ${inventoryFragment}
        }
      }
      invoices(filter: {status: {ne: "void" }}) {
        items {
          id
          version
          invoiceNumber
          status
          totalAmount
          invoiceItems {
            items {
              id
              name
              description
              source
              sourceLineItemId
              description
              lineItemType
              quantity
              unitPrice
              unitCost
              markupValue
              taxable
              amount
              quoteId
              product {
                name
                description
                id
              }
            }
          }
        }
      }
    }
  }
`;

const useJob = ({ id }, options = {}) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const snackbar = useSnackbar();

  dispatch(startGetJob());
  const { data, loading, error, refetch } = useExtendedQuery(getJobById, {
    variables: {
      partitionKey: user.tenantId,
      id
    },
    transform: result => result?.getJobById,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options
  });

  if (data) {
    dispatch(startGetJobSuccess(data));
  } else if (error) {
    dispatch(startGetJobFailure(error));
    logErrorWithCallback(error, snackbar, 'Unable to get the job');
  }
  return [data || {}, loading, error, refetch];
};

export default useJob;
