import {
  any,
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string
} from 'prop-types';

import { TablePlaceholderVariant } from './WrapTable.constants';

export const GridAlignment = oneOf(['left', 'right', 'center']);

export const cellCssParams = shape({
  row: object,
  value: any,
  isFirstCol: bool,
  isLastCol: bool,
  isFirstRow: bool,
  isLastRow: bool
});

export const headerCssParams = shape({
  isFirstCol: bool,
  isLastCol: bool
});

export const ColDef = shape({
  /**
   * The column identifier. It's used to map with grid data values.
   */
  field: string,

  /**
   * The title of the column rendered in the column header cell.
   */
  headerName: string,

  // /**
  //  * The description of the column rendered as tooltip if the column header name is not fully displayed.
  //  */
  // description: string,

  /**
   * Set the width of the column.
   */
  width: number,

  /**
   * If set, it indicates that a column has fluid width. Range [0, ∞).
   */
  flex: number,

  /**
   * Sets the maximum width of a column.
   */
  maxWidth: number,

  /**
   * Sets the minimum width of a column.
   * @default 50
   */
  minWidth: number,

  /**
   * Allows user to override default cell css styles.
   * If cellCss is a callback function:
   * @param {cellCssParams} params Object containing parameters for cell styling.
   * @returns {cellCss} The generated cell styles.
   */
  cellCss: oneOfType([object, func]),

  /**
   * Allows user to override default header css styles.
   * If headerCss is a callback function:
   * @param {headerCssParams} params Object containing parameters for header styling.
   * @returns {headerCss} The generated header styles.
   */
  headerCss: oneOfType([object, func]),

  /**
   * If `true`, hide the column.
   * @default false
   */
  hide: bool,

  // /**
  //  * If `true`, the column is sortable.
  //  * @default true
  //  */
  // sortable: bool,

  // /**
  //  * If `true`, the column is resizable.
  //  * @default true
  //  */
  // resizable: bool,

  /**
   * If `true`, the cells of the column are editable.
   * @default false
   */
  editable: bool,

  // /**
  //  * A comparator function used to sort rows.
  //  */
  // sortComparator: GridComparatorFn,

  // /**
  //  * Type allows to merge this object with a default definition [[GridColDef]].
  //  * @default 'string'
  //  */
  // type: any,

  // /**
  //  * To be used in combination with `type: 'singleSelect'`. This is an array of the possible cell values and labels.
  //  */
  // valueOptions: Array<string | number | { value: any, label: string }>,

  /**
   * Allows to align the column values in cells.
   */
  align: any,

  /**
   * Control the vertical justifyContent property of a cell.
   */
  justify: any,

  /**
   * Function that allows to get a specific data instead of field to render in the cell.
   * @param params
   */
  valueGetter: func,

  /**
   * Function that allows to apply a formatter before rendering its value.
   * @param {any} params Object containing parameters for the formatter.
   * @returns {any} The formatted value.
   */
  valueFormatter: func,

  // /**
  //  * Function that takes the user-entered value and converts it to a value used internally.
  //  * @param {any} value The user-entered value.
  //  * @param {GridCellParams} params The params when called before saving the value.
  //  * @returns {any} The converted value to use internally.
  //  */
  // valueParser: (value: any, params: GridCellParams) => any,

  // /**
  //  * Class name that will be added in cells for that column.
  //  */
  // cellClassName: GridCellClassNamePropType,

  /**
   * Allows to override the component rendered as cell for this column.
   * @param params
   */
  renderCell: func,

  /**
   * Allows to override the component rendered in edit cell mode for this column.
   * @param params
   */
  renderEditCell: func,

  // /**
  //  * Class name that will be added in the column header cell.
  //  */
  // headerClassName: GridColumnHeaderClassNamePropType,

  /**
   * Allows to render a component in the column header cell.
   * @param params
   */
  renderHeader: func,

  /**
   * Header cell element alignment.
   */
  headerAlign: GridAlignment,

  // /**
  //  * Toggle the visibility of the sort icons.
  //  * @default false
  //  */
  // hideSortIcons: bool,

  // /**
  //  * If `true`, the column menu is disabled for this column.
  //  * @default false
  //  */
  // disableColumnMenu: bool,

  // /**
  //  * If `true`, the column is filterable.
  //  * @default true
  //  */
  // filterable: bool,

  // /**
  //  * Allows setting the filter operators for this column.
  //  */
  // filterOperators: GridFilterOperator[],

  // /**
  //  * If `true`, this column cannot be reordered.
  //  * @default false
  //  */
  // disableReorder: bool,

  // /**
  //  * If `true`, this column will not be included in exports.
  //  * @default false
  //  */
  // disableExport: bool,

  //-----------------------------------------------------------------------------
  // Additional props unique to WrapTable (not supported by Xgrid or DataGridPro)
  //-----------------------------------------------------------------------------

  /**
   * Disables wrapping for the column.
   * @default false
   */
  noWrap: bool,

  /**
   * Show an MUI tool tip with the cell value on cell hover.
   * @default false
   */
  showTooltip: bool,

  /**
   * Function that calculates the total for a column.
   *    ({ rows, colDef: { cellValueGetter }}) => total.
   *    import { sum, average } from 'components/WrapTable';
   * @param params
   */
  totalGetter: func,

  /**
   * Function that allows to apply a formatter before rendering its total.
   * @param {any} params Object containing parameters for the formatter.
   * @returns {any} The formatted total.
   */
  totalFormatter: func,

  /**
   * Allows to override the component rendered as total for this column.
   * @param params
   */
  renderTotal: func
});

export const GridRowsProp = arrayOf(object);

export const ColumnProps = {
  value: any,
  colDef: ColDef,
  className: any,
  header: bool,
  scrollX: bool,
  lockRowHeight: bool
};

export const HeaderProps = {
  columns: arrayOf(ColDef),
  scrollX: bool,
  scrollY: bool
};

export const WrapTableProps = {
  /**
   * @ignore
   */
  className: string,

  /**
   * Set of columns of type [[ColDef[]]].
   */
  columns: arrayOf(ColDef),

  /**
   * An error that will turn the grid into its error state and display the error component.
   */
  error: any,

  /**
   * If `true`, a  loading overlay is displayed.
   */
  loading: bool,

  /**
   * Set of rows of type [[GridRowsProp]].
   */
  rows: GridRowsProp,

  /**
   * If `single`, all column we are grouping by will be represented in the same grouping the same column.
   * If `multiple`, each column we are grouping by will be represented in its own column.
   * @default 'single'
   */
  rowGroupingColumnMode: oneOf(['multiple', 'single']),

  /**
   * Set the row grouping model of the grid.
   */
  rowGroupingModel: arrayOf(string),

  // /**
  //  * Callback fired when the row grouping model changes.
  //  * @param {GridRowGroupingModel} model Columns used as grouping criteria.
  //  * @param {GridCallbackDetails} details Additional details for this callback.
  //  */
  // onRowGroupingModelChange: PropTypes.func,

  //-----------------------------------------------------------------------------
  // Additional props unique to WrapTable (not supported by Xgrid or DataGridPro)
  //-----------------------------------------------------------------------------

  /**
   * Render a bottom sticky totals row.
   * @default false
   */
  enableTotalsRow: bool,

  /**
   * Render an inline group header for grouped rows.
   * @default false
   */
  enableGroupHeaders: bool,

  /**
   * The text that appears in the table's loading state.
   */
  errorMessage: string,

  /**
   * The number of rows to show in the initial loading state
   * @default 10
   */
  loadingRows: number,

  /**
   * Enforces static row height
   * @default false
   */
  lockRowHeight: bool,

  /**
   * The maxHeight of the table body when scrollY is true
   * @default 350
   */
  maxHeight: number,

  /**
   * An optional limit to depth of nested row grouping
   */
  maxGroupingDepth: number,

  /**
   * The text that appears in a the table's empty state.
   * @default 'string'
   */
  noDataMessage: string,

  /**
   * Sets the column def default to noWrap
   * @default false
   */
  noWrap: bool,

  /**
   * Sets the display variant when there is no data. "Table", "BOX", or "TEXT"
   * @default "TABLE"
   */
  placeholderVariant: oneOf(Object.values(TablePlaceholderVariant)),

  /**
   * Allow the table to be wider than the viewport and scroll horizontally
   * @default false
   */
  scrollX: bool,

  /**
   * Apply maxHeight to the table body and allow rows to scroll vertically
   * @default false
   */
  scrollY: bool,

  /**
   * Hides the table footer
   * @default false
   */
  hideFooter: bool,

  /**
   * boxSizing prop, default 'border-box'
   */
  boxSizing: string
};
