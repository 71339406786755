import { MUIFormComponentNames } from '@BuildHero/sergeant';

export const getCreateMultipleEventsSwitch = ({
  allowMultipleEventsCreation,
  disabled,
  multipleEventsCreationEnabled,
  onCreateMultipleEventsChange,
  label
}) => [
  {
    component: allowMultipleEventsCreation ? 'CreateMultipleEventsSwitch' : null,
    options: {
      disabled,
      label,
      checked: multipleEventsCreationEnabled,
      onChange: onCreateMultipleEventsChange
    }
  }
];

export const getStartDateTimeField = ({ disabled }) => [
  {
    component: MUIFormComponentNames.LabelControl,
    source: 'Start Date & Time',
    options: {
      required: true
    }
  },
  {
    component: 'DateTimeInput',
    source: 'startDateTime',
    options: {
      disabled
    }
  }
];

export const getDateTimeField = ({ disabled }) => [
  {
    component: MUIFormComponentNames.LabelControl,
    source: 'Date & Time',
    options: {
      required: true
    }
  },
  {
    component: 'DateTimeInput',
    source: 'startDateTime',
    options: {
      disabled
    }
  }
];

export const getEndDateField = ({ disabled }) => [
  {
    component: MUIFormComponentNames.LabelControl,
    source: 'End Date'
  },
  {
    component: MUIFormComponentNames.DateInput,
    source: 'endDate',
    options: {
      disabled
    }
  }
];

export const getDurationField = ({ disabled }) => [
  {
    component: MUIFormComponentNames.LabelControl,
    source: 'Duration'
  },
  {
    component: 'DurationInput',
    source: 'durationInMin',
    options: {
      disabled
    }
  }
];

export const getExcludeWeekendsField = () => [
  {
    component: MUIFormComponentNames.CheckboxInput,
    source: 'excludeWeekends',
    options: {
      label: 'Exclude Weekends'
    }
  }
];
