import { FieldType, TV, TW } from '@BuildHero/sergeant';

import {
  AccountingApp,
  EnvelopeType,
  InvoiceItemSourceType,
  InvoiceItemType
} from 'utils/constants';

import {
  envelopePane,
  footer,
  getFields,
  getHeader,
  getTotals,
  sumTransformFn
} from './sharedConfigs';

const typeOrder = {
  [InvoiceItemType.LABOR_LINE_ITEM]: 0,
  [InvoiceItemType.PUCHASE_ORDER_LINE]: 1,
  [InvoiceItemType.INVENTORY_PART]: 1,
  [InvoiceItemType.DISCOUNT]: 2,
  [InvoiceItemType.FEE]: 2
};

const customSortFn = (a, b) => {
  const aTable = typeOrder[a.lineItemType];
  const bTable = typeOrder[b.lineItemType];
  return aTable === bTable ? a.sortOrder - b.sortOrder : aTable - bTable;
};

/**
 * Intented to only be used for print view. So only PDF layout is included
 * @param invoiceData The invoice data to display
 */
export default ({
  invoiceNumber,
  sender,
  accountingApp,
  settings,
  parentLink,
  summary,
  termsOfService,
  envelopeType,
  adjustmentsFlag
}) => {
  const isSageIntegrated = accountingApp === AccountingApp.SAGE;
  const showParent = !!parentLink;
  const showSummary = !!summary;
  const showTermsOfService = !!termsOfService;

  const fields = getFields({ isSageIntegrated });
  const isDoublePane = envelopeType === EnvelopeType.DOUBLE;

  return {
    layoutsList: ['default', 'pdf'],
    layouts: {
      pdf: {
        props: {
          title: `Invoice ${invoiceNumber}`,
          footer: footer(
            ({ pageNumber, totalPages }) =>
              `Invoice ${invoiceNumber} - Page ${pageNumber} of ${totalPages}`
          )
        },
        contents: [
          ...getHeader({ showParent, sender, envelopeType, isSimpleMode: settings.mode.simple }),
          {
            props: {
              style: {
                paddingLeft: 35,
                paddingTop: isDoublePane ? 10 : 18,
                paddingBottom: 40,
                flexDirection: 'row'
              }
            },
            contents: [
              {
                props: {
                  style: { paddingRight: 12 }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      value: 'Bill To',
                      variant: TV.S3,
                      style: {
                        paddingLeft: 8,
                        paddingTop: isDoublePane ? 4 : 12,
                        paddingBottom: 2
                      },
                      weight: TW.MEDIUM,
                      caps: true,
                      color: '#999999'
                    }
                  },
                  envelopePane
                ]
              },
              {
                props: { style: { width: 274 } },
                contents: [
                  {
                    props: {
                      style: {
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 12
                      }
                    },
                    contents: [
                      fields.customerName({ width: 70, marginRight: 16 }),
                      fields.customerProperty({ width: 70, marginRight: 16 }),
                      fields.propertyAddress({ width: 102 })
                    ]
                  },
                  {
                    props: {
                      style: {
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 12
                      }
                    },
                    contents: [
                      fields.dueDate({ width: 60, marginRight: 16 }),
                      fields.authorizedBy({ width: 91, marginRight: 16 }),
                      fields.customerPO({ width: 91 })
                    ]
                  },
                  {
                    props: {
                      style: {
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }
                    },
                    contents: [
                      fields.customerWO({
                        width: isSageIntegrated ? 56.5 : 75.333,
                        marginRight: 16
                      }),
                      fields.nte({ width: isSageIntegrated ? 56.5 : 75.333, marginRight: 16 }),
                      fields.terms({
                        width: isSageIntegrated ? 56.5 : 75.333,
                        marginRight: isSageIntegrated ? 16 : 0
                      }),
                      fields.sageJob({ width: isSageIntegrated ? 56.5 : 75.333 })
                    ]
                  }
                ]
              }
            ]
          },
          showSummary && {
            component: 'Typography',
            props: {
              weight: TW.BOLD,
              value: 'Invoice Summary',
              variant: TV.S2,
              style: { marginBottom: 4 }
            }
          },
          showSummary && {
            component: 'Typography',
            source: 'summary'
          },
          showSummary && {
            component: 'Divider'
          },
          {
            component: 'Table',
            source: 'invoiceItems',
            props: {
              customSortFn,
              columns: [
                {
                  id: 'name',
                  label: 'Item Name',
                  width: `${116 / 5.64}%`,
                  weight: TW.BOLD
                },
                {
                  id: 'description',
                  label: 'Description',
                  width: `${228 / 5.64}%`
                },
                {
                  id: 'quantity',
                  label: 'Quantity',
                  type: FieldType.NUMBER,
                  width: `${60 / 5.64}%`,
                  numeric: true,
                  sum: true
                },
                {
                  id: 'unitPrice',
                  label: 'Unit Price',
                  type: FieldType.CURRENCY,
                  width: `${80 / 5.64}%`
                },
                {
                  id: 'amount',
                  label: 'Price Subtotal',
                  type: FieldType.CURRENCY,
                  width: `${80 / 5.64}%`,
                  sum: true,
                  sumTransformFn,
                  displayTransformFn: sumTransformFn
                }
              ]
            }
          },
          {
            component: 'Divider'
          },
          getTotals({ settings, adjustmentsFlag }),
          showTermsOfService && {
            component: 'Divider'
          },
          showTermsOfService && {
            component: 'Typography',
            props: {
              testingid: 'termsOfService',
              weight: TW.BOLD,
              value: 'Terms of Service',
              variant: TV.S2,
              style: { marginBottom: 4 }
            }
          },
          showTermsOfService && {
            component: 'Typography',
            source: 'termsOfService'
          }
        ]
      }
    }
  };
};
