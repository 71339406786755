import React from 'react';

import {
  Button,
  InlineAlert,
  InlineAlertTypes,
  Input,
  Modal,
  PDFForm,
  ThemeProvider
} from '@BuildHero/sergeant';
import { Grid, withStyles } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

import PDFAttachments from 'components/BuildHeroFormComponents/pdf-components/Attachments';
import PDFDivider from 'components/BuildHeroFormComponents/pdf-components/Divider/Divider';
import LineItemTable from 'components/BuildHeroFormComponents/pdf-components/LineItemTable';
import PDFLogo from 'components/BuildHeroFormComponents/pdf-components/Logo';
import RichContentPDf from 'components/BuildHeroFormComponents/pdf-components/RichContentViewer';
import PDFTaskList from 'components/BuildHeroFormComponents/pdf-components/Task/TaskList';
import PDFTaskSection from 'components/BuildHeroFormComponents/pdf-components/Task/TaskSection';
import Spinner from 'components/Spinners/CircularIndeterminate';
import configForEnvironment from 'configs/aws-exports';
import ENV from 'configs/env';
import pdfForms from 'meta/Quotes/PDF';
import ErrorBoundary from 'scenes/Error';
import AppConstants from 'utils/AppConstants';
import { isPDFSupportedByBrowser } from 'utils/browsers';

import actions from '../actions';

import styles from './styles';

class QuotesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      data: null
    };
  }

  componentDidMount = async () => {
    const data = await this.formatData(this.props.dataToRender);
    this.setState({
      data,
      openReasonsModal: false,
      reason: '',
      quoteStatus: '',
      poNumber: data.customerPoNumber || '',
      nameOfSignee: '',
      approvalSignatureRequired: data.approvalSignatureRequired
    });
  };

  performAction = action => {
    const { token } = this.props;
    const { reason, poNumber, quoteStatus, nameOfSignee } = this.state;
    let requestBody;
    if (action.addReason) {
      if (quoteStatus === 'Approve') {
        requestBody = {
          token,
          action: action.name,
          data: { reason, customerPoNumber: poNumber || null, nameOfSignee }
        };
      } else {
        requestBody = { token, action: action.name, data: { reason } };
      }
    } else {
      requestBody = { token, action: action.name };
    }

    axios
      .post(configForEnvironment(ENV).publicUrlEndpoint, requestBody)
      .then(() => window.location.reload())
      .catch(e => this.setState({ error: e.message }));
  };

  renderStatusRelatedInfo = () => {
    const { classes } = this.props;
    const { loading, error, data } = this.state;
    const { status } = data || {};

    if (['Approved', 'Rejected'].includes(status)) {
      return <div className={classes.warningText}>{`This quote is ${status.toLowerCase()}`}</div>;
    }

    if (status !== 'CustomerViewed') {
      return <div className={classes.warningText}>This quote cannot be approved or declined</div>;
    }

    if (loading && !error) return <Spinner />;

    if (error) {
      return (
        <div className={classes.warningText}>
          Error: Cannot perform the required action. Please try again.
        </div>
      );
    }
    return null;
  };

  formatData = async rawData => {
    // TODO: standardize the data that is sent into multiple PDF formats
    const { quote, senderInfo } = rawData;
    const {
      opportunity,
      owner,
      orderedBy,
      tasks,
      tasksTemplateItems,
      directProducts,
      formData,
      ...quoteInfo
    } = quote || {};
    const { customerProperty } = opportunity || {};
    const { customer } = customerProperty || {};
    const data = { ...rawData.quote, ...rawData.senderInfo };

    data.createdDate = quote.quoteCreatedDate
      ? moment.unix(quote.quoteCreatedDate).format(AppConstants.DATE_FORMAT)
      : '-';
    data.expirationDate = isNaN(quote.expirationDate)
      ? quote.expirationDate
      : moment.unix(quoteInfo.expirationDate).format(AppConstants.DATE_FORMAT);
    data.templatePDFKey = quote.templatePDFKey;
    data.senderInfoLogo = `${configForEnvironment(ENV).CLOUDINARY_THUMBNAIL_URL}${
      senderInfo?.logoUrl
    }`;
    const companySettings = data?.companySettings?.items[0]?.settings;
    data.approvalSignatureRequired = JSON.parse(companySettings || '{}')?.approvalSignatureRequired;
    // Added for Holmes template
    data.quoteInfo = quoteInfo;
    data.customer = customer;
    data.quoteCustomFieldsData = {};

    if (formData && formData.items && formData.items.length > 0) {
      const { id, version, ...customFields } = formData.items[0] || {};
      Object.keys(customFields || {}).forEach(fieldKey => {
        data.quoteCustomFieldsData[`Custom_${fieldKey}`] = customFields[fieldKey];
      });
    }

    // TO avoid forms library crashing
    if (!data.quoteCustomFieldsData) {
      data.quoteCustomFieldsData = {};
    }

    // Added for Holmes template
    const propertyInfo = { companyName: customerProperty.companyName || '' };
    const propertyAddress = ((customerProperty.companyAddresses || {}).items || [])[0] || {};
    const addressLine3 = [];
    if (propertyAddress?.city) addressLine3.push(propertyAddress.city);
    if (propertyAddress?.state) addressLine3.push(propertyAddress.state);
    if (propertyAddress?.zipcode) addressLine3.push(propertyAddress.zipcode);

    data.property = propertyInfo;

    // Added for Holmes template
    data.propertyDetail = {
      propertyAddress1: propertyAddress?.addressLine1 || '',
      propertyAddress2: propertyAddress?.addressLine2 || '',
      propertyAddress3: (addressLine3.length > 0 && addressLine3.join(', ')) || ''
    };

    const billingInfo =
      (customer.companyAddresses &&
        customer.companyAddresses.items &&
        customer.companyAddresses.items[0]) ||
      {};

    data.billTo = billingInfo.billTo || '-';
    data.billingAddressLine1 = billingInfo.billingAddressLine1 || '-';
    data.billingAddressLine2 = billingInfo.billingAddressLine2 || '';
    data.billingAddressLine3 = `${billingInfo.billingCity &&
      `${billingInfo.billingCity}, `}${billingInfo.billingState && `${billingInfo.billingState} `}${
      billingInfo.billingZipcode
    }`;

    const shippingAddress = senderInfo.companyAddresses.items.filter(
      address => address.addressType === 'shippingAddress'
    );

    const companyAddress = shippingAddress.length > 0 ? shippingAddress[0] : {};

    const companyAddressLine3 = [];
    if (companyAddress.city) companyAddressLine3.push(companyAddress.city);
    if (companyAddress.state) companyAddressLine3.push(companyAddress.state);
    if (companyAddress.zipcode) companyAddressLine3.push(companyAddress.zipcode);

    // Added for Holmes template
    data.senderInfo = {
      addressLine1: companyAddress.addressLine1,
      addressLine2: companyAddress.addressLine2,
      addressLine3: companyAddressLine3.join(', '),
      email: senderInfo.email,
      companyName: senderInfo.companyName
    };

    // SW Mech
    data.senderInfo.faxNumber = data.fax;
    data.senderInfo.phoneNumber = data.phonePrimary;
    data.customerContactNameTitle = data.orderedBy?.name;
    data.customerContactEmailTitle = data.orderedBy?.email;
    data.customerContactPhoneTitle = data.orderedBy?.cellPhone;

    // Added for Holmes template
    data.owner = owner || {};
    data.orderedBy = orderedBy || {};

    // Added for Holmes template
    data.projectManager = quote.ownerValue;

    let quoteTasks = [];
    if (tasks && tasks.items) {
      tasks.items.forEach(task => {
        const updatedTask = task;
        updatedTask.products =
          (task.quoteLineProducts &&
            task.quoteLineProducts.items &&
            task.quoteLineProducts.items.map(product => ({
              ...product,
              amount: product.unitPrice * product.quantity
            }))) ||
          [];
        quoteTasks.push(updatedTask);
      });
    }

    quoteTasks = quoteTasks.map(task => {
      const updatedTask = task;
      let taskSubtotal = 0;

      task.products.forEach(part => {
        taskSubtotal += part.unitPrice * part.quantity;
      });

      if (task.unitPrice && task.quantity) {
        taskSubtotal = task.unitPrice * task.quantity;
      }

      updatedTask.amount = taskSubtotal || 0;
      updatedTask.subtotalValue = taskSubtotal;
      return updatedTask;
    });

    data.quoteTasks = quoteTasks;

    const subTotalSum = data.subTotalAmountTaxable + data.subTotalAmountNonTaxable;
    data.totals = [
      { description: 'Subtotal', amount: subTotalSum || 0 },
      { description: 'Taxable subtotal', amount: data.subTotalAmountTaxable || 0 },
      { description: 'Sales tax', amount: data.totalTaxAmount || 0 },
      {
        description: 'Total',
        amount: data.totalAmountQuotedOverride || data.totalAmountQuoted || 0
      }
    ];

    // For holmes template - added Attn, phone and via email
    data.customerContactNameTitle = orderedBy?.name || '';
    data.customerContactEmailTitle = orderedBy?.email || '';
    data.customerContactPhoneTitle = orderedBy?.cellPhone || '';

    data.tasks = tasks;

    if (data.tasks && data.tasks.items && !_.isEmpty(data.tasks.items)) {
      data.tasks.items.map(item => {
        if (item.products && !_.isEmpty(item.products)) {
          item.products = { items: [...item.products] };
        }
        return item;
      });
    }

    data.finalAmount = (
      (data.totalAmountQuotedOverride || data.totalAmountQuoted || 0).toFixed(2) || '0.00'
    ).replace(/\d(?=(\d{3})+\.)/g, '$&,');

    let customizedQuoteNumber = `${quote.customIdentifier || quote.quoteNumber}`;
    if (!data?.versionedQuoteView || data?.versionedQuoteView?.items.length >= 1) {
      customizedQuoteNumber += ` Version ${quote.versionNumber}`;
    }

    data.customizedQuoteNumber = customizedQuoteNumber;
    data.quoteInfo.description = data.quoteInfo && (data.quoteInfo.summary || '');

    return data;
  };

  setTaskList = ({ form, field, options }) => {
    const { detailsShownToCustomer } = this.state?.data || {};
    let newOptions = { ...options };
    if (detailsShownToCustomer) {
      newOptions = { ...newOptions, detailsShownToCustomer };
    }
    return <PDFTaskList field={field} form={form} options={newOptions} />;
  };

  getPDFForm = ({ data, formToRender, showDescription, showTasks }) => {
    return (
      <PDFForm
        configuration={formToRender({
          showSummary: showDescription,
          showTasks,
          hideTotalAmount: data.hideTotalAmount
        })}
        customComponents={{
          Table: LineItemTable,
          Logo: PDFLogo,
          AttachmentView: PDFAttachments,
          TaskSection: PDFTaskSection,
          TaskList: this.setTaskList,
          Divider: PDFDivider,
          RichContentViewer: RichContentPDf
        }}
        data={data}
        layout="pdf"
      />
    );
  };

  renderCreatedPDF = data => {
    const pdfToRender = data.pdfTemplateKey || '';
    const formToRender = (pdfToRender && pdfForms[pdfToRender]) || pdfForms.default;
    const showDescription = data.summary && data.summary.length > 0;
    const showTasks = data.quoteTasks && data.quoteTasks.length > 0;
    const supportsPDF = isPDFSupportedByBrowser();
    const FormPDF = this.getPDFForm({ data, formToRender, showDescription, showTasks });
    const fileName = `Quote_${data?.quoteNumber}_${data?.name}`;
    return supportsPDF ? (
      <Grid
        item
        lg={7}
        md={7}
        sm={12}
        style={{
          borderRadius: '4px',
          boxShadow:
            '0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 3px 4px 0 rgba(0, 0, 0, 0.14)',
          width: 905,
          padding: 40,
          height: 780
        }}
        xl={7}
        xs={12}
      >
        <PDFViewer height="100%" width="100%">
          {FormPDF}
        </PDFViewer>
      </Grid>
    ) : (
      <Grid item style={{ padding: 12 }} xs={12}>
        <ThemeProvider>
          <InlineAlert
            message="Quote preview is not supported on this browser or device."
            type={InlineAlertTypes.GREY}
          />
          <div
            style={{
              color: '#000',
              marginTop: 40,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <AttachFileIcon style={{ fontSize: '14px' }} />
            <PDFDownloadLink document={FormPDF} fileName={fileName} style={{ color: '#000' }}>
              {({ blob, url, loading, error }) => (loading ? '...' : `Download ${fileName}`)}
            </PDFDownloadLink>
          </div>
        </ThemeProvider>
      </Grid>
    );
  };

  render() {
    const {
      loading,
      data,
      openReasonsModal,
      reason,
      quoteStatus,
      poNumber,
      approvalSignatureRequired,
      nameOfSignee
    } = this.state;
    const { classes } = this.props;
    if (!data) return <Spinner type="FAST" />;

    return (
      <ErrorBoundary>
        {this.renderStatusRelatedInfo()}
        <Grid
          alignItems="center"
          className={classes.container}
          container
          direction="row"
          justify="center"
        >
          {data.pdfUrl ? (
            <Grid
              item
              lg={7}
              md={7}
              sm={12}
              style={{
                borderRadius: '4px',
                boxShadow:
                  '0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 3px 4px 0 rgba(0, 0, 0, 0.14)',
                width: 905,
                padding: 40,
                height: 780
              }}
              xl={7}
              xs={12}
            >
              <iframe height="100%" src={data.pdfUrl} title="pdf" width="100%" />
            </Grid>
          ) : (
            this.renderCreatedPDF(data)
          )}
          <Grid item md={7} sm={12} style={{ marginTop: 40 }} xs={12}>
            {!loading && data.status === 'CustomerViewed' && (
              <Grid alignItems="center" container direction="row" justify="center">
                <ThemeProvider>
                  <Button
                    disabled={loading || data.status !== 'CustomerViewed'}
                    style={{ marginRight: 40 }}
                    type="primary"
                    onClick={() =>
                      this.setState({ openReasonsModal: true, quoteStatus: 'Approve' })
                    }
                  >
                    Approve
                  </Button>
                  <Button
                    disabled={loading || data.status !== 'CustomerViewed'}
                    type="secondary"
                    onClick={() => this.setState({ openReasonsModal: true, quoteStatus: 'Reject' })}
                  >
                    Reject
                  </Button>
                </ThemeProvider>
              </Grid>
            )}
          </Grid>
        </Grid>
        <ThemeProvider>
          <Modal
            actions={
              <Button
                disabled={approvalSignatureRequired && quoteStatus === 'Approve' && !nameOfSignee}
                fullWidth
                loading={loading}
                style={{ marginTop: '1rem' }}
                onClick={async () => {
                  this.setState({ openReasonsModal: false, loading: true, error: null });
                  await this.performAction(
                    quoteStatus === 'Approve' ? actions.approveQuote : actions.rejectQuote
                  );
                  this.setState({ quoteStatus: '' });
                }}
              >
                Confirm
              </Button>
            }
            open={openReasonsModal}
            PaperProps={{ style: { minWidth: 454 } }}
            title={`${quoteStatus} Quote`}
            onClose={() => this.setState({ openReasonsModal: false })}
          >
            {quoteStatus === 'Approve' && (
              <Input
                label="PO Number"
                style={{ paddingBottom: 16 }}
                value={poNumber}
                onChange={event => this.setState({ poNumber: event.target.value })}
              />
            )}
            <Input
              label={quoteStatus === 'Approve' ? 'Approval Comments' : 'Rejection Comments'}
              multiline
              rows="4"
              value={reason}
              onChange={event => this.setState({ reason: event.target.value })}
            />
            {approvalSignatureRequired && quoteStatus === 'Approve' && (
              <Input
                label="Approver Name"
                required
                style={{ paddingTop: 16 }}
                value={nameOfSignee}
                onChange={evt => this.setState({ nameOfSignee: evt.target.value })}
              />
            )}
          </Modal>
        </ThemeProvider>
      </ErrorBoundary>
    );
  }
}

export default withStyles(styles)(QuotesPage);
