import React, { useCallback, useMemo } from 'react';

import { PDFDocument } from '@BuildHero/sergeant';
import moment from 'moment';
import PropTypes from 'prop-types';

import { getCloudinaryImageUrl, getCombinedAddressFromProjectData } from '@pm/components/utils';
import PDFDocumentLayout from 'meta/ProjectManagement/FieldReport/dailyReportPreviewPdf';
import { formatAddress, getCombinedAddress } from 'utils';
import { AppConstants } from 'utils/AppConstants';

import { getProductivitySummary } from '../../GeneratePdfModal/GeneratePdfModal.helpers';

const DailyReportPdf = ({
  user,
  projectData,
  companyInfo,
  dailyReportData,
  timesheets,
  payrollHourTypes
}) => {
  const productivitySummary = getProductivitySummary(dailyReportData?.ProductivityPhases);

  const getFormattedData = useCallback(() => {
    const foremanSignature = dailyReportData?.foremanSignatures?.[0];
    const customerSignature = dailyReportData?.customerSignatures?.[0];

    return {
      // general info
      senderLogoUrl: getCloudinaryImageUrl(companyInfo?.logoUrl || null),
      senderAddress: formatAddress(companyInfo?.companyAddresses || null, true) || null,
      senderPhoneNumber: companyInfo?.phonePrimary || null,
      packageNumber: dailyReportData?.number ? `#${dailyReportData?.number}` : null,
      date: dailyReportData?.date
        ? moment.unix(dailyReportData.date).format('L')
        : moment().format(AppConstants.DATE_FORMAT),
      // project info
      projectName: projectData.name || '-',
      projectAddress: getCombinedAddressFromProjectData(projectData) || '-',
      // address info: TODO: get from info
      fromName: user?.displayName || '-',
      fromEmail: user?.email || '-',
      fromCompany: projectData?.company?.companyName || '-',
      fromCompanyAddress: getCombinedAddress(companyInfo?.companyAddresses) || '-',

      // title of notes
      notes: dailyReportData?.notes || [{}],
      timesheets,
      productivitySummary,
      // Materials
      materials: dailyReportData?.materials || [{}],
      // Equipment
      equipment: dailyReportData?.equipment || [{}],
      // Subcontractor
      subcontractors: dailyReportData?.subcontractors || [{}],

      // Signature
      foremanSignatureImageUrl: foremanSignature?.signatureImageUrl || null,
      foremanTitle: foremanSignature?.title || '',
      foremanFirstName: foremanSignature?.firstName || '',
      foremanLastName: foremanSignature?.lastName || '',
      foremanCompany: foremanSignature?.company || '',
      foremanEmail: foremanSignature?.emailAddress || '',
      customerSignatureImageUrl: customerSignature?.signatureImageUrl || null,
      customerTitle: customerSignature?.title || '',
      customerFirstName: customerSignature?.firstName || '',
      customerLastName: customerSignature?.lastName || '',
      customerCompany: customerSignature?.company || '',
      customerEmail: customerSignature?.emailAddress || ''
    };
  }, [dailyReportData, productivitySummary, projectData, timesheets, user, companyInfo]);

  return useMemo(() => {
    return (
      <PDFDocument
        configuration={PDFDocumentLayout({
          weather: dailyReportData?.weather?.weatherData || [],
          notes:
            dailyReportData?.notes?.map(x => ({
              ...x,
              noteSections: x.noteSections.filter(y => y.sectionType !== 'INTERNAL_NOTES')
            })) || [],
          attachments: dailyReportData?.DailyReportAttachment || [],
          materials: dailyReportData?.materials || [],
          equipment: dailyReportData?.equipment || [],
          subcontractors: dailyReportData?.subcontractors || [],
          payrollHourTypes,
          timesheets,
          productivitySummary
        })}
        initialValues={getFormattedData()}
        layout="pdf"
      />
    );
  }, [dailyReportData, getFormattedData, payrollHourTypes, timesheets, productivitySummary]);
};

DailyReportPdf.propTypes = {
  user: PropTypes.object.isRequired,
  projectData: PropTypes.object.isRequired,
  dailyReportData: PropTypes.object.isRequired,
  timesheets: PropTypes.array,
  payrollHourTypes: PropTypes.array,
  title: PropTypes.string
};

DailyReportPdf.defaultProps = {
  title: 'Daily Report',
  timesheets: [],
  payrollHourTypes: []
};

export default DailyReportPdf;
