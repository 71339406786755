import { ButtonType } from '@BuildHero/sergeant';

// unique to SA, design team is not planniong to use anywhere else
export const editColorCodes = {
  dark: '#990099',
  light: '#FFEBFF'
};

export const addColorCodes = {
  dark: '#FFB3B3',
  light: '#FFB3B3'
};

export const deleteTaskConfirmMessage = {
  body: 'Are you sure you want to delete this task?',
  title: 'Delete Task',
  buttonLabel: 'Delete Task',
  buttonType: ButtonType.ERROR
};

export const modifyTaskConfirmMessage = {
  body:
    'By modifying this task, all maintenance information (from step 4) for properties will be erased.',
  title: 'Modify Task',
  buttonLabel: 'Modify Task',
  buttonType: ButtonType.ERROR
};

export const deleteTaskWithMetadataMessage = {
  body:
    'By deleting this task, all maintenance information (from step 4) for properties will be erased.',
  title: 'Delete Task',
  buttonLabel: 'Delete Task',
  buttonType: ButtonType.ERROR
};

export const TaskTemplateEditType = {
  NONE: 'NONE',
  ADD: 'ADD',
  EDIT: 'EDIT',
  DELETE: 'DELETE'
};

export const editInputCss = {
  backgroundColor: editColorCodes.light,
  borderColor: editColorCodes.dark,
  border: '1px solid'
};

export const addInputCss = {
  backgroundColor: addColorCodes.light,
  borderColor: addColorCodes.dark,
  border: '1px solid'
};
