import gql from 'graphql-tag';

import { isEmpty, omit } from 'lodash';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const CREATE_INVOICE = gql`
  mutation CreateInvoice($data: CreateInvoiceInput!) {
    createInvoice(data: $data) {
      id
    }
  }
`;

const serializer = data => {
  const billingAddress = data.billingAddress && { ...data.billingAddress, billTo: data.billTo };
  return {
    variables: {
      data: {
        tenantCompanyId: data.tenantCompanyId,
        parentId: data.parentId,
        parentType: data.parentType,
        departmentId: data.department?.value,
        priceBookId: data.priceBook?.value,
        addresses: [billingAddress, data.propertyAddress]
          .map(a => omit(a, '__typename'))
          .filter(a => !isEmpty(a))
      }
    }
  };
};

export const useCreateInvoice = (options = undefined) => {
  return useExtendedMutation(CREATE_INVOICE, { serializer, ...options });
};
