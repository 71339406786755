import React, { useState } from 'react';

import { DeleteOutlined, EditOutlined } from '@material-ui/icons';
import every from 'lodash/every';
import pick from 'lodash/pick';
import { useSelector } from 'react-redux';

import { actionButtonColumn, checkboxColumn } from 'components/WrapTable';
import StorageService from 'services/StorageService';
import { getUniqueName } from 'utils';
import withLazyMount from 'utils/withLazyMount';

import {
  useAddReviewReportAttachment,
  useDeleteReviewReportAttachment,
  useEditReviewReportAttachment
} from '../../../../mutations';
import { selectReviewReportAttachments } from '../../../../selectors';
import ReportTable from '../../../ReportTable';

import SectionHeader from '../../../SectionHeader';

import { useReviewReportDisabled } from '../../ReviewReport.contexts';
import { reviewReportDefaultProps, reviewReportPropTypes } from '../../reviewReportPropTypes';

import TableRowModal from '../TableRowModal';

import { attachmentLayout } from './AttachmentModal.layout';
import { useReviewReportColumns } from './ReviewReportAttachmentTable.hooks';

const pickAttachmentFields = attachment =>
  pick(attachment, ['id', 'version', 'customFileName', 'type', 'comment', 'includeInInvoice']);

const uploadFile = ({ tenantId, file }) => {
  const storageService = new StorageService();
  const uniqueName = getUniqueName(tenantId, file?.name);
  return storageService.uploadFile(file, uniqueName);
};

const prepareAttachmentForEdit = async data => {
  const storageService = new StorageService();
  const fileUrl = await storageService.getFile(data.fileUrl);
  return {
    ...data,
    attachments: [
      {
        key: 0,
        label: data.fileName,
        file: {
          type: fileUrl?.match(/\.(jpeg|jpg|gif|png)$/) ? 'image' : 'video'
        },
        fileUrl
      }
    ]
  };
};

const ReviewReportAttachmentTable = ({ reviewReport, loading, error }) => {
  const user = useSelector(s => s.user);
  const title = 'Photos & Videos';
  const noDataMessage = 'No Photos & Videos';
  const { disabled } = useReviewReportDisabled();
  const rows = selectReviewReportAttachments(reviewReport) || [];
  const tenantId = useSelector(state => state.user?.tenantId);

  const [editAttachmentMutation, { loading: updating }] = useEditReviewReportAttachment();
  const [attachmentToEdit, setAttachmentToEdit] = useState(null);
  const showEditModal = async data => {
    const preparedData = await prepareAttachmentForEdit(data);
    setAttachmentToEdit(preparedData);
  };
  const closeEditModal = () => setAttachmentToEdit(null);
  const handleEdit = async updatedAttachment => {
    if (attachmentToEdit?.attachments === updatedAttachment?.attachments) {
      await editAttachmentMutation({
        tenantId,
        attachment: pickAttachmentFields(updatedAttachment)
      });
    } else {
      const fileInput = updatedAttachment.attachments?.[0];
      const fileUrl = await uploadFile({ tenantId, file: fileInput?.file });
      const fileName = fileInput?.label;
      await editAttachmentMutation({
        tenantId,
        attachment: { ...pickAttachmentFields(updatedAttachment), fileUrl, fileName }
      });
    }
    closeEditModal();
  };

  const handleIncludeInInvoiceChange = row => async event => {
    const { checked } = event.target;
    await editAttachmentMutation({
      tenantId,
      attachment: { ...pickAttachmentFields(row), includeInInvoice: checked }
    });
  };

  const handleIncludeAllInInvoiceChange = async event => {
    const { checked } = event.target;
    return Promise.all(
      rows
        .filter(row => (checked ? !row.includeInInvoice : row.includeInInvoice))
        .map(row =>
          editAttachmentMutation({
            tenantId,
            attachment: { ...pickAttachmentFields(row), includeInInvoice: checked }
          })
        )
    );
  };

  const [addAttachment] = useAddReviewReportAttachment(reviewReport?.id);
  const [attachmentToAdd, setAttachmentToAdd] = useState(null);
  const showAddModal = data => setAttachmentToAdd(data);
  const closeAddModal = () => setAttachmentToAdd(null);

  const handleAdd = async ({ attachments, type, comment, customFileName }) => {
    const fileInput = attachments?.[0];
    const fileUrl = await uploadFile({ tenantId, file: fileInput?.file });
    await addAttachment({
      tenantId,
      reviewReportId: reviewReport?.id,
      attachment: {
        fileName: fileInput?.label,
        isUploaded: true,
        type,
        comment,
        customFileName,
        fileUrl,
        addedBy: user.displayName,
        dateAdded: Math.floor(Date.now() / 1000)
      }
    });
    closeAddModal();
  };

  const [deleteAttachmentMutation] = useDeleteReviewReportAttachment(reviewReport?.id);
  const handleDelete = attachmentToDelete =>
    deleteAttachmentMutation({
      tenantId,
      attachmentId: attachmentToDelete?.id
    });

  const rowActions = [
    { icon: EditOutlined, label: 'Edit', onClick: showEditModal, disabled },
    {
      icon: DeleteOutlined,
      label: 'Delete',
      onClick: handleDelete,
      disabled
    }
  ];

  const attachmentColumns = useReviewReportColumns();

  const columns = [
    checkboxColumn({
      disabled: disabled || updating,
      onRowToggle: handleIncludeInInvoiceChange,
      onHeaderToggle: handleIncludeAllInInvoiceChange,
      isRowChecked: row => row?.includeInInvoice,
      isHeaderChecked: () => every(rows, row => row?.includeInInvoice)
    }),
    ...attachmentColumns,
    actionButtonColumn({ actions: rowActions })
  ];

  const renderTitle = () => (
    <SectionHeader buttonText="Add Photo or Video" title={title} onButtonClick={showAddModal} />
  );

  return (
    <>
      <ReportTable
        columns={columns}
        error={error}
        loading={loading}
        noDataMessage={noDataMessage}
        renderTitle={renderTitle}
        rows={rows}
      />
      <TableRowModal
        data={attachmentToEdit}
        layout={attachmentLayout}
        open={Boolean(attachmentToEdit)}
        title="Edit Photo or Video"
        onAction={handleEdit}
        onClose={closeEditModal}
      />
      <TableRowModal
        data={attachmentToAdd}
        layout={attachmentLayout}
        open={Boolean(attachmentToAdd)}
        title="Add Photo or Video"
        onAction={handleAdd}
        onClose={closeAddModal}
      />
    </>
  );
};

ReviewReportAttachmentTable.propTypes = reviewReportPropTypes;
ReviewReportAttachmentTable.defaultProps = reviewReportDefaultProps;

export default withLazyMount(ReviewReportAttachmentTable);
