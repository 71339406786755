/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

import { sentryException } from '../Logger/index';

export async function getJobCostTypes() {
  return await instance
    .get('jobcosttypes')
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getJobCostByCONum(coNum) {
  return await instance
    .get(`jobcosttypes/cotypes/${coNum}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function jobCostCreate(jct) {
  console.log(jct);
  return await instance
    .post('jobcosttypes', jct)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to create Job Cost Type');
      return [];
    });
}

export async function getTypes() {
  return await instance
    .get(`jobcosttypes/JOB_COST`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getRevenues() {
  return await instance
    .get(`jobcosttypes/REVENUE`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function searchTypes(term, searchColumns) {
  if (term.length > 0) {
    return await instance
      .post(`jobcosttypes/JOB_COST/${term}`, { searchColumns: searchColumns || ['name'] })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  }
  return await getTypes();
}

export async function searchRevenues(term, searchColumns) {
  if (term.length > 0) {
    return await instance
      .post(`jobcosttypes/REVENUE/${term}`, { searchColumns: searchColumns || ['name'] })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  }
  return await getRevenues();
}
