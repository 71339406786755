import { gql } from '@apollo/client/core';
import moment from 'moment';
import { arrayOf, number, shape, string } from 'prop-types';

import { watchedQueries } from '@dispatch/Dispatch.store';
import { manDayFragment } from '@dispatch/fragments/manDayFragment';
import useExtendedQuery from 'customHooks/useExtendedQuery';

import { selectCardXPosition, selectEndTime, selectStartTime } from '../DispatchBoard.selectors';

export const LIST_DISPATCH_BOARD_MAN_DAY_QUERY = gql`
  query listManDay($startDateTime: Int!, $endDateTime: Int!) {
    listManDay(from: $startDateTime, to: $endDateTime, limit: 1000) {
      ${manDayFragment}
    }
  }
`;

const transform = data => {
  return (
    data?.listManDay?.reduce((result, item) => {
      const techsItems =
        item.technicianIds?.reduce((techItems, technicianId) => {
          if (!technicianId) {
            return techItems;
          }
          const startPosition = selectCardXPosition(item.startDateTime);
          const endPosition = selectCardXPosition(item.endDateTime);
          const project = item.project || {};
          return {
            ...techItems,
            [technicianId]: [
              ...(result[technicianId] || []),
              {
                ...item,
                name:
                  project?.number && project?.name
                    ? `${project.name} – #${project.number}`
                    : project?.name || project?.number,
                left: startPosition,
                width: endPosition - startPosition,
                range: moment.range(moment.unix(item.startDateTime), moment.unix(item.endDateTime))
              }
            ]
          };
        }, {}) || {};
      return {
        ...result,
        ...techsItems
      };
    }, {}) || {}
  );
};

const getVariables = ({ day, weekView, weekViewStartDay }) => {
  const dispatchWindowStartTime = selectStartTime({ day, weekView, weekViewStartDay });
  const dispatchWindowEndTime = selectEndTime({ day, weekView, weekViewStartDay });

  return {
    startDateTime: dispatchWindowStartTime,
    endDateTime: dispatchWindowEndTime
  };
};

export const useDispatchBoardManDayItems = ({ day, weekView, weekViewStartDay, ...options }) => {
  const variables = getVariables({ day, weekView, weekViewStartDay });

  watchedQueries.useDispatchBoardManDayItems = {
    query: LIST_DISPATCH_BOARD_MAN_DAY_QUERY,
    variables
  };

  return useExtendedQuery(LIST_DISPATCH_BOARD_MAN_DAY_QUERY, {
    transform,
    defaultData: [],
    variables,
    ...options
  });
};

export const BOARD_MAN_DAY_ITEM_RESPONSE_PROPS = shape({
  items: arrayOf(
    shape({
      id: string,
      startDateTime: number,
      endDateTime: number
    })
  )
});
