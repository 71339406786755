import React from 'react';

import { bool, func } from 'prop-types';

import { SergeantModal } from 'components';

import { addSettingLayout } from './AddProjectFileStorageSettingModal.layout';

const AddProjectFileStorageSettingModal = ({ open, onPrimaryAction, onClose }) => {
  if (!open) {
    return null;
  }

  return (
    <SergeantModal
      handleClose={onClose}
      handlePrimaryAction={onPrimaryAction}
      layout={addSettingLayout}
      maxWidth={500}
      open={open}
      title="Add Project File Folder"
    />
  );
};

AddProjectFileStorageSettingModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  onPrimaryAction: func.isRequired
};

export default AddProjectFileStorageSettingModal;
