/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

import { sentryException } from '../Logger/index';

export async function getChangeOrders(projectId) {
  return await instance
    .get(`changeorder/byproject/${projectId}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getChangeOrderById(id) {
  return await instance
    .get(`changeorder/${id}`, {
      params: {
        include: [
          'ChangeOrderLineItem',
          'AuditLogEntry',
          'ChangeOrderAttachment',
          'sendToCompany',
          'sendToEmployee',
          'returnToCompany',
          'returnToEmployee'
        ]
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getCOByProjectId(id) {
  return await instance
    .get(`changeorder/project/${id}`, { params: { include: '*' } })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function changeOrderCreate(co) {
  return await instance
    .post('changeorder', co)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function changeOrderChange(id, co) {
  return await instance
    .put(`changeorder/${id}`, co)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function changeOrderDelete(id) {
  return await instance
    .delete(`changeorder/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to delete Change Order');
      return [];
    });
}

export async function getNextChangeOrderNumber(projectId) {
  let paramsClause = {};
  if (projectId) {
    paramsClause = { projectId };
  }
  return await instance
    .get('changeOrder/nextnumber', {
      params: paramsClause
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
