import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function BypassIcon(props) {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SvgIcon {...props} className="bypass-icon">
      <path
        d="M4 22.5H14C15.6 22.5 16.9167 22.2333 17.95 21.7C18.9833 21.1667 20.05 20.35 21.15 19.25L23.3 21.4C22.9333 21.8333 22.4333 22.2917 21.8 22.775C21.1667 23.2583 20.5833 23.6667 20.05 24C20.9167 24.5667 21.6667 25.125 22.3 25.675C22.9333 26.225 23.4667 26.75 23.9 27.25C25.4667 29.1833 27.3583 30.575 29.575 31.425C31.7917 32.275 34.7 32.6 38.3 32.4L33.8 27.9L35.9 25.8L44 33.9L35.9 42L33.8 39.9L38.3 35.4C34.1 35.5667 30.7917 35.1583 28.375 34.175C25.9583 33.1917 23.7 31.55 21.6 29.25C20.2667 27.7833 19.05 26.7917 17.95 26.275C16.85 25.7583 15.5333 25.5 14 25.5H4V22.5ZM31.8 12.9C32.4 12.7667 33.275 12.6583 34.425 12.575C35.575 12.4917 36.85 12.4833 38.25 12.55L33.8 8.1L35.9 6L44 14.1L35.9 22.2L33.8 20.1L38.3 15.6C37.0333 15.5333 35.925 15.5167 34.975 15.55C34.025 15.5833 33.2 15.6667 32.5 15.8L31.8 12.9ZM23.25 17.1C23.85 16.5333 24.6417 15.9083 25.625 15.225C26.6083 14.5417 27.6833 14.0167 28.85 13.65L29.6 16.6C28.7667 16.9 27.9667 17.3 27.2 17.8C26.4333 18.3 25.8167 18.7667 25.35 19.2L23.25 17.1Z"
        style={{ transform: 'scale(0.5)' }}
      />
    </SvgIcon>
  );
}
