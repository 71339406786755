import React, { useEffect, useMemo, useRef, useState } from 'react';

import * as R from 'ramda';

import { useGetProducts } from '@pm/hooks/useGetProducts';

import {
  parseSettings,
  useGetProjectAccountingSettings
} from 'customHooks/useGetProjectAccountingSettings';

import ProjectAccountingSettingItem from './components/ProjectAccountingSettingItem';
import { AccountingSettings } from './ProjectAccountingSettings.constants';

import {
  serializeSettings,
  useUpdateProjectAccountingSettings
} from './ProjectAccountingSettings.hooks';

const ProjectAccountingSettings = () => {
  const [settings, setSettings] = useState({});
  const settingsRef = useRef();
  settingsRef.current = settings;

  const [originalSettings, setOriginalSettings] = useState({});
  const originalSettingsRef = useRef();
  originalSettingsRef.current = originalSettings;

  const [settingBeingEdited, setSettingBeingEdited] = useState();
  const [{ data: fetchedSettings, loading: loadingSettings }] = useGetProjectAccountingSettings();
  const [, updateSettings] = useUpdateProjectAccountingSettings();
  const [{ data: products, loading: loadingProducts }] = useGetProducts();

  useEffect(() => {
    if (!R.isEmpty(fetchedSettings)) {
      setSettings(fetchedSettings);
      setOriginalSettings(fetchedSettings);
    }
  }, [fetchedSettings]);

  const productOptions = useMemo(() => {
    if (!loadingSettings && !loadingProducts) {
      return R.pipe(
        R.uniqWith(R.eqProps('value'))(),
        R.sortBy(R.prop('label'))
      )([
        ...Object.entries(settings).map(([, value]) => value),
        ...(products || [])?.map(product => ({
          value: product.id,
          label: product.name
        }))
      ]);
    }
    return [];
  }, [products, settings, loadingSettings, loadingProducts]);

  const handleSettingChangeCancel = settingKey => () => {
    setSettings({
      ...settingsRef.current,
      [settingKey]: originalSettingsRef.current[settingKey] || {}
    });
    setSettingBeingEdited(null);
  };

  const handleSettingChange = settingKey => option => {
    setSettings({
      ...settings,
      [settingKey]: option
    });
    setSettingBeingEdited(settingKey);
  };

  const handleSettingSave = async () => {
    setSettingBeingEdited(null);
    const { data: updatedSettingsRaw } = await updateSettings({
      data: serializeSettings(settings)
    });
    const updatedSettings = parseSettings(updatedSettingsRaw);
    setSettings(updatedSettings);
    setOriginalSettings(updatedSettings);
  };

  const loading = loadingSettings || loadingProducts;

  return (
    <>
      <ProjectAccountingSettingItem
        disabled={
          loading ||
          (Boolean(settingBeingEdited) && settingBeingEdited !== AccountingSettings.RETAINAGE)
        }
        isBeingEdited={settingBeingEdited === AccountingSettings.RETAINAGE}
        name="Retainage"
        options={productOptions}
        settingValue={settings?.[AccountingSettings.RETAINAGE]?.value}
        onSettingChange={handleSettingChange(AccountingSettings.RETAINAGE)}
        onSettingChangeCancel={handleSettingChangeCancel(AccountingSettings.RETAINAGE)}
        onSettingSave={handleSettingSave}
      />
      <ProjectAccountingSettingItem
        disabled={
          loading ||
          (Boolean(settingBeingEdited) && settingBeingEdited !== AccountingSettings.CHANGE_ORDERS)
        }
        isBeingEdited={settingBeingEdited === AccountingSettings.CHANGE_ORDERS}
        name="Change Orders"
        options={productOptions}
        settingValue={settings?.[AccountingSettings.CHANGE_ORDERS]?.value}
        onSettingChange={handleSettingChange(AccountingSettings.CHANGE_ORDERS)}
        onSettingChangeCancel={handleSettingChangeCancel(AccountingSettings.CHANGE_ORDERS)}
        onSettingSave={handleSettingSave}
      />
    </>
  );
};

export default ProjectAccountingSettings;
