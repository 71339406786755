import React, { useCallback, useEffect, useState } from 'react';

import { Button, ButtonType } from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';
import { orderBy } from 'lodash';

import { FixedSizeList } from 'react-window';

import { ChecklistHeader } from './components/checklistHeader';
import { defaultCreateTemplatePayload } from './helper';

import { useCreateTaskTemplate } from './hooks/useCreateTaskTemplate';

import { TaskTemplate } from './taskTemplate';

const Checklist = ({
  cList,
  setChecklistModal,
  updateChecklist,
  updatingChecklist,
  assetTypeId
}) => {
  const [newTask, setNewTask] = useState();

  useEffect(() => {
    setNewTask();
  }, [cList]);

  const [createTaskTemplate, { loading: creatingTaskTemplateLoading }] = useCreateTaskTemplate({
    checklistId: cList.id,
    assetTypeId,
    skip: !cList.id,
    onSuccess: (transformedData, snackbarOn) => {
      snackbarOn('success', 'Task added');
    }
  });

  const renderTask = useCallback(
    ({ data, index, isScrolling, style }) => (
      <div style={style}>
        <TaskTemplate
          assetTypeId={assetTypeId}
          checklistId={cList.id}
          data={data[index]}
          disabled={cList.inUse}
          loading={isScrolling}
          setNewTask={setNewTask}
        />
      </div>
    ),
    [assetTypeId, cList.inUse]
  );

  const lastTaskTemplate = orderBy(cList?.taskTemplates || [], ['sortOrder'], ['desc'])[0];
  const currentTaskTemplates = [...(cList?.taskTemplates || []), newTask].filter(Boolean);

  return (
    <>
      <ChecklistHeader
        cList={cList}
        disabled={cList?.inUse}
        isChecklistDefinition
        numberOfTasks={currentTaskTemplates.length}
        setChecklistModal={setChecklistModal}
        updateChecklist={updateChecklist}
        updatingChecklist={updatingChecklist}
      />
      {currentTaskTemplates.length > 0 ? (
        <Box
          sx={{
            width: '100%',
            maxHeight: 430,
            bgcolor: 'background.paper'
          }}
        >
          <FixedSizeList
            height={currentTaskTemplates.length < 5 ? currentTaskTemplates.length * 70 : 370}
            itemCount={currentTaskTemplates.length}
            itemData={currentTaskTemplates}
            itemKey={(index, data) => data[index]?.id}
            itemSize={70}
            overscanCount={5}
            useIsScrolling
            width="100%"
          >
            {renderTask}
          </FixedSizeList>
        </Box>
      ) : null}
      {!cList?.inUse && !newTask && (
        <Box display="flex" flexGrow={1}>
          <Button
            disabled={creatingTaskTemplateLoading}
            key={`AddTaskBtn-${cList.id}`}
            type={ButtonType.LEADING}
            onClick={async () => {
              const payload = {
                taskTemplateChecklistId: cList.id,
                ...defaultCreateTemplatePayload,
                ...lastTaskTemplate
              };
              if (lastTaskTemplate?.id) {
                await createTaskTemplate(payload);
              } else {
                setNewTask(payload);
              }
            }}
          >
            + Add Task
          </Button>
        </Box>
      )}
    </>
  );
};

export default Checklist;
