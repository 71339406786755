import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { SectionHeader, UserPermission } from 'components';
import Labels from 'meta/labels';
import ErrorBoundaries from 'scenes/Error';
import { MAX_DEFAULT_TABLE_HEIGHT } from 'themes/BuildHeroTheme';
import { PermissionConstants } from 'utils/AppConstants';

import AfterHourSection from './AfterHourSection';
import CommunicationSection from './CommunicationsSection';
import CompanySection from './CompanySection';
import DepartmentSection from './DepartmentSection';
import RolesTable from './RolesTable';
import { styles } from './styles';

class ViewCompany extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.state = {
      departmentsUpdatedCount: 0,
      companySkillsUpdatedCount: 0
    };
  }

  componentDidMount() {
    document.title = 'BuildOps - Company';
  }

  setHasRoles = boolValue => {
    if (this.mounted && this.state.hasRoles !== boolValue) {
      this.setState({ hasRoles: boolValue });
    }
  };

  setCompanySkillsUpdatedCount = () => {
    this.setState(prevState => ({
      companySkillsUpdatedCount: prevState.companySkillsUpdatedCount + 1
    }));
  };

  setDepartmentsUpdatedCount = () => {
    this.setState(prevState => ({
      departmentsUpdatedCount: prevState.departmentsUpdatedCount + 1
    }));
  };

  render() {
    const { departmentsUpdatedCount, companySkillsUpdatedCount } = this.state;
    const { history } = this.props;
    const { search } = history.location;
    const isAdmin = search && search.toLowerCase().indexOf('isadmin') !== -1;

    const sectionContainerStyles = {
      width: '100%',
      minHeight: MAX_DEFAULT_TABLE_HEIGHT - 100,
      marginBottom: 40,
      overflow: 'hidden',
      flexDirection: 'column'
    };

    return (
      <ErrorBoundaries>
        <UserPermission action={PermissionConstants.MENU_SETTINGS} I="allow">
          <Grid container direction="row" style={sectionContainerStyles}>
            <CompanySection
              departmentsUpdatedCount={departmentsUpdatedCount}
              mounted={this.mounted}
              setCompanySkillsUpdatedCount={this.setCompanySkillsUpdatedCount}
            />
          </Grid>
          <Grid container direction="row" style={sectionContainerStyles}>
            <DepartmentSection
              companySkillsUpdatedCount={companySkillsUpdatedCount}
              mounted={this.mounted}
              setDepartmentsUpdatedCount={this.setDepartmentsUpdatedCount}
            />
          </Grid>
          <Grid container direction="row" style={sectionContainerStyles}>
            <AfterHourSection departmentsUpdatedCount={departmentsUpdatedCount} />
          </Grid>
          <Grid container direction="row" style={sectionContainerStyles}>
            <SectionHeader title={Labels['Roles and Access Rights'][this.props.user.locale]} />
            <RolesTable isAdmin={isAdmin} mounted={this.mounted} />
          </Grid>
          <Grid container direction="row" style={sectionContainerStyles}>
            <CommunicationSection
              departmentsUpdatedCount={departmentsUpdatedCount}
              mounted={this.mounted}
            />
          </Grid>
        </UserPermission>
      </ErrorBoundaries>
    );
  }
}

const styledViewCompany = withStyles(styles, { withTheme: true })(ViewCompany);

const mapStateToProps = state => ({
  user: state.user
});

const reduxConnectedViewCompany = connect(mapStateToProps, null)(styledViewCompany);

export default reduxConnectedViewCompany;
