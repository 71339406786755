import React, { useState } from 'react';

import { Input } from '@BuildHero/sergeant';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const PasswordInput = inputProps => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Input
      {...inputProps}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            style={{ height: 30, width: 30 }}
            onClick={() => setShowPassword(!showPassword)}
            onMouseDown={event => event.preventDefault()}
          >
            {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
          </IconButton>
        </InputAdornment>
      }
      type={showPassword ? 'text' : 'password'}
    />
  );
};

export default PasswordInput;
