import { omit } from 'lodash';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import UPDATE_DISCOUNT from 'services/core/graphql/review-report/mutations/UpdateDiscount';

const serializer = ({ tenantId, discount }) => {
  const payload = {
    variables: {
      partitionKey: tenantId,
      data: { ...omit(discount, ['__typename', 'createdDateTime', 'sortKey', '_rowIndex']) }
    }
  };
  return payload;
};

const optimisticResponseFactory = ({ discount }) => {
  return {
    updateDiscount: {
      ...discount
    }
  };
};

export const useUpdateReviewReportDiscount = () => {
  return useExtendedMutation(UPDATE_DISCOUNT, {
    serializer,
    optimisticResponseFactory
  });
};
