import React, { useState } from 'react';

import { InlineAlert, InlineAlertTypes } from '@BuildHero/sergeant';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { QuoteStatus } from 'utils/AppConstants';

import RecommendedTasksModal from '../Modals/RecommendedTasksModal/index';

const RecommendedTaskAlert = props => {
  const { status, recommendedTasks } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleAlertClick = () => {
    setIsModalOpen(true);
  };
  return (
    [QuoteStatus.DRAFT].includes(status) &&
    recommendedTasks.length > 0 && (
      <div>
        <Grid style={{ padding: '12px 0' }}>
          <InlineAlert
            message={`[${recommendedTasks.length}] recommendations found for this property.  Review and add recommendations to quote >`}
            type={InlineAlertTypes.BLUE}
            onClick={handleAlertClick}
          />
        </Grid>
        <RecommendedTasksModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          {...props}
        />
      </div>
    )
  );
};

export default RecommendedTaskAlert;

RecommendedTaskAlert.propTypes = {
  fetchTasksOnCustomerProperty: PropTypes.func.isRequired,
  propertyId: PropTypes.string,
  propertyName: PropTypes.string,
  quoteInfo: PropTypes.object,
  snackbarOn: PropTypes.func.isRequired,
  status: PropTypes.string,
  recommendedTasks: PropTypes.array
};

RecommendedTaskAlert.defaultProps = {
  propertyId: '',
  propertyName: '',
  quoteInfo: {},
  status: '',
  recommendedTasks: []
};
