export const addAssetTypeLayout = {
  fields: {
    assetTypeName: {
      default: 'assetTypeName'
    }
  },
  layouts: {
    default: {
      options: {
        grow: 0
      },
      contents: [
        {
          options: {
            size: 1,
            direction: 'column',
            grow: 1
          },
          contents: [
            {
              options: {
                grow: 1,
                padding: 8
              },
              contents: [
                {
                  component: 'TextInput',
                  source: 'assetTypeName',
                  options: {
                    label: 'Asset Type',
                    isRequired: true,
                    placeholder: 'Enter Asset type'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      assetTypeName: {
        type: 'string'
      }
    },
    required: ['assetTypeName']
  },
  validationErrors: {
    assetTypeName: {
      required: 'Field is required.'
    }
  }
};

export default addAssetTypeLayout;
