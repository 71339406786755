import * as React from 'react';

import { Button, ButtonType } from '@BuildHero/sergeant';
import EditIcon from '@material-ui/icons/Edit';

import { column, ColumnType } from '../../../../components/XGrid/columnTypes';

const createMaterialMarkupValueGetter = computeMarkupValueColumn => {
  const fn = params => {
    return computeMarkupValueColumn(params.row.id, params.row.unitCost);
  };
  return fn;
};

const createTotalMarkupValueColumn = computeTotalMarkupValueColumn => {
  const fn = params => {
    return computeTotalMarkupValueColumn(params.row.id, params.row.unitCost);
  };
  return fn;
};

const createUnitPriceValueColumn = computeUnitPriceValueColumn => {
  const fn = params => {
    return computeUnitPriceValueColumn(params.row.id, params.row.unitCost);
  };
  return fn;
};

export const getColumns = ({
  computeMarkupValueColumn,
  computeTotalMarkupValueColumn,
  computeUnitPriceValueColumn,
  showEditItem
}) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 420,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'taxable',
    headerName: 'Taxable',
    width: 120,
    ...column[ColumnType.BOOL]
  },
  {
    field: 'unitCost',
    headerName: 'Unit Cost',
    width: 130,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'materialMarkup',
    headerName: 'Material Markup',
    width: 175,
    ...column[ColumnType.PERCENTAGE],
    valueGetter: createMaterialMarkupValueGetter(computeMarkupValueColumn)
  },
  {
    field: 'totalMarkup',
    headerName: 'Total Markup',
    width: 160,
    ...column[ColumnType.PERCENTAGE],
    valueGetter: createTotalMarkupValueColumn(computeTotalMarkupValueColumn)
  },
  {
    field: 'unitPrice',
    headerName: 'Unit Price',
    width: 130,
    ...column[ColumnType.CURRENCY],
    valueGetter: createUnitPriceValueColumn(computeUnitPriceValueColumn)
  },
  {
    field: 'Edit',
    headerName: '',
    width: 100,
    renderCell: ({ row }) => (
      <Button
        endIcon={<EditIcon />}
        type={ButtonType.LEADING}
        onClick={() => {
          showEditItem(row);
        }}
      />
    )
  }
];
