import React, { useCallback, useState } from 'react';

import { TW, Typography } from '@BuildHero/sergeant';
import { useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useSelector } from 'react-redux';

import CircularIndeterminate from 'components/Spinners/CircularIndeterminate';
import { useSnackbar } from 'customHooks/useSnackbar';
import StorageService from 'services/StorageService';

import { useCompanyMutation } from './InvoiceHeaderUploader.gql';

const InvoiceHeaderUploader = () => {
  const snackbar = useSnackbar();
  const user = useSelector(state => state.user);
  const company = useSelector(state => state.company);

  const updateCompany = useCompanyMutation();

  const { palette } = useTheme();

  const [storageService] = useState(new StorageService());
  const [logoUrl, setLogoUrl] = useState(
    company.invoiceLogoUrl && storageService.getFile(company.invoiceLogoUrl)
  );
  const [loading, setLoading] = useState(false);

  const handleFileSelection = useCallback(
    async ({ target }) => {
      const file = target.files[0];
      if (!file) return;

      setLoading(true);
      try {
        const filename = `${user.tenantId}/${Date.now()}-${file.name}`;
        const uploadedFileName = await storageService.uploadFile(file, filename, () => {});

        const url = storageService.getFile(uploadedFileName);
        setLogoUrl(url);

        await updateCompany({ ...company, invoiceLogoUrl: uploadedFileName });
        const img = new Image();
        img.onload = () => setLoading(false);
        img.src = url;
      } catch (err) {
        snackbar('error', 'There was an error updating the invoice header logo.');
        setLoading(false);
      }
    },
    [storageService, user.tenantId, company, updateCompany, snackbar]
  );

  return (
    <label
      css={{
        width: 564,
        height: 88,
        backgroundColor: logoUrl ? 'unset' : palette.background.light,
        position: 'relative',
        cursor: loading ? 'wait' : 'pointer',
        '&:hover': {
          backgroundColor: palette.background.dark
        },
        '&:hover > .hidden': {
          display: 'block'
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      htmlFor="invoice-header-uploader-input"
    >
      <input
        accept="image/jpeg, image/jpg, image/png, image/gif"
        css={{ display: 'none' }}
        id="invoice-header-uploader-input"
        type="file"
        onChange={handleFileSelection}
      />
      {loading ? (
        <CircularIndeterminate />
      ) : (
        <Typography
          className="hidden"
          css={{
            display: logoUrl ? 'none' : 'block',
            pointerEvents: 'none',
            zIndex: 2
          }}
          weight={TW.MEDIUM}
        >
          <AddIcon css={{ marginRight: 5 }} fontSize="inherit" />
          Upload Invoice Header
        </Typography>
      )}
      {!loading && logoUrl && (
        <div
          css={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            cursor: loading ? 'wait' : 'pointer',
            zIndex: 1,
            opacity: '100%',
            '&:hover': { opacity: '20%' }
          }}
        >
          <img
            alt="invoice logo"
            css={{
              objectPosition: 'center',
              objectFit: 'contain',
              height: '100%'
            }}
            src={logoUrl}
          />
        </div>
      )}
    </label>
  );
};

export default InvoiceHeaderUploader;
