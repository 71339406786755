import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { formType } from 'constants/bundle';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';

import Form from './Form';
import Header from './Header';
import List from './List';

import { deleteBundle, getBundles } from './service';

function Bundle({ user, snackbarOn }) {
  const [visibleFormType, setVisibleFormType] = useState(null);
  const [refreshCount, setRefreshCount] = useState(0);
  const [tempBundle, setTempBundle] = useState();
  const closeForm = () => setVisibleFormType(null);
  const openForm = formName => setVisibleFormType(formName);
  const addRefreshCount = () => setRefreshCount(refreshCount + 1);
  const handleFormClose = () => {
    setTempBundle();
    closeForm();
  };

  const handleEdit = bundle => {
    setTempBundle(bundle);
    openForm(formType.newBundle);
  };

  const productBundles = ({ ...tableArguments }) =>
    getBundles({ ...tableArguments, user, snackbarOn });

  const handleDelete = bundle =>
    deleteBundle({
      bundle,
      user,
      snackbarOn,
      successCallback: addRefreshCount
    });

  const handleAddToBundle = bundle => {
    setTempBundle(bundle);
    openForm(formType.addToBundle);
  };

  return (
    <ErrorBoundaries>
      <Grid container>
        <Header newItemLabel="new bundle" onNewItemClick={() => openForm(formType.newBundle)} />
        <List
          addToBundle={handleAddToBundle}
          getBundles={productBundles}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          refreshCount={refreshCount}
        />
        <Form
          addRefreshCount={addRefreshCount}
          data={tempBundle}
          handleFormClose={handleFormClose}
          isEdit={!isEmpty(tempBundle)}
          isVisible={visibleFormType === formType.newBundle}
          snackbarOn={snackbarOn}
          user={user}
        />
      </Grid>
    </ErrorBoundaries>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const reduxConnectedBundle = connect(mapStateToProps, mapDispatchToProps)(Bundle);

Bundle.propTypes = {
  user: PropTypes.object.isRequired,
  snackbarOn: PropTypes.func.isRequired
};

export default reduxConnectedBundle;
