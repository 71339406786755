import React from 'react';

import { Text, View } from '@react-pdf/renderer';

import styles from '../style';

const ContinuationSheetTableHeaders = () => {
  return (
    <View>
      {/* 1st table header */}
      <View style={styles.displayFlex}>
        <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '5%' }]}>A</Text>
        <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '27%' }]}>B</Text>
        <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '12%' }]}>C</Text>
        <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '8%' }]}>D</Text>
        <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '8%' }]}>E</Text>
        <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '10%' }]}>F</Text>
        <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '18%' }]}>G</Text>
        <Text style={[styles.text, styles.rightLastCell, styles.textCenter, { width: '12%' }]}>
          H
        </Text>
      </View>
      {/* 2nd table header */}
      <View style={styles.displayFlex}>
        <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '5%' }]}>
          ITEM{'\n'}NO.
        </Text>
        <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '27%' }]}>
          DESCRIPTION OF WORK
        </Text>
        <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '12%' }]}>
          CONTRACT{'\n'}VALUE
        </Text>
        <View style={{ width: '16%' }}>
          <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '100%' }]}>
            WORK COMPLETED
          </Text>
          <View style={[styles.displayFlex, { width: '100%' }]}>
            <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '50%' }]}>
              FROM{'\n'}PREVIOUS{'\n'}APPLICATION{'\n'}
            </Text>
            <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '50%' }]}>
              THIS{'\n'}PERIOD
            </Text>
          </View>
        </View>
        <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '10%' }]}>
          MATERIALS{'\n'}PRESENTLY{'\n'}STORED{'\n'}(NOT IN{'\n'}D OR E)
        </Text>
        <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '10%' }]}>
          TOTAL{'\n'}COMPLETED{'\n'}AND STORED{'\n'}TO DATE{'\n'}(D + E + F)
        </Text>
        <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '8%' }]}>
          %{'\n'}(G ÷ C)
        </Text>
        <Text style={[styles.text, styles.rightLastCell, styles.textCenter, { width: '12%' }]}>
          BALANCE{'\n'}TO FINISH{'\n'}(C - G)
        </Text>
      </View>
      {/* 3rd table header */}
      <View style={styles.displayFlex}>
        <Text style={[styles.text, styles.borderDoubleBottom, { width: '5%' }]} />
        <Text
          style={[
            styles.text,
            styles.borderDoubleBottom,
            styles.textCenter,
            { width: '27%', fontFamily: 'Times-Bold' }
          ]}
        >
          ORIGINAL CONTRACT
        </Text>
        <Text style={[styles.text, styles.borderDoubleBottom, { width: '12%' }]} />
        <Text style={[styles.text, styles.borderDoubleBottom, { width: '8%' }]} />
        <Text style={[styles.text, styles.borderDoubleBottom, { width: '8%' }]} />
        <Text style={[styles.text, styles.borderDoubleBottom, { width: '10%' }]} />
        <Text style={[styles.text, styles.borderDoubleBottom, { width: '10%' }]} />
        <Text style={[styles.text, styles.borderDoubleBottom, { width: '8%' }]} />
        <Text style={[styles.text, styles.borderDoubleBottom, { width: '12%', borderRight: 0 }]} />
      </View>
    </View>
  );
};

export default ContinuationSheetTableHeaders;
