import React from 'react';

import { Field } from '@BuildHero/sergeant';
import PropTypes from 'prop-types';

import { formatAddress } from 'utils';

export default function Address({ field, props }) {
  const address = formatAddress(field.value, true) || '-';
  return <Field {...props} value={address} />;
}

Address.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  // any input props or anything to override
  props: PropTypes.shape({
    style: PropTypes.object,
    label: PropTypes.string,
    required: PropTypes.bool
  }).isRequired
};
