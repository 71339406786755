import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Divider,
  InlineAlert,
  InlineAlertTypes,
  SgtForm,
  ThemeProvider,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';

import { Box } from '@material-ui/core';
import _ from 'lodash';

import { AttachmentItemPreview } from 'components';
import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';

import { getUniqueName } from 'utils';
import { constructContactEmailOptions, constructSelectOptions } from 'utils/constructSelectOptions';

import validateEmail from 'utils/validateEmail';

import { StepButtonStateConstants } from '../../InvoiceEmailModal.constants';
import { useSendInvoiceEmail } from '../../InvoiceEmailModal.queries';

import { getUniqueFileName } from '../../InvoiceEmailModal.utils';

import CombinedAttachment from './components/CombinedAttachment';
import { useGetBackStepHandler } from './EmailInfoStep.hooks';
import { getEmailInfoConfiguration } from './EmailInfoStep.layout';

import { getEmailFormValidationSchema, getFileSizeAlertMessage } from './EmailInfoStep.utils';

const getAttachmentUrl = url => {
  const storageService = new StorageService();
  return storageService.getFile(url);
};

const uploadAttachments = async (tenantId, attachments) => {
  // Name collision can cause missing attachments
  const attachmentsWithUniqueFileNames = attachments.reduce((arr, attachment) => {
    return [...arr, { ...attachment, fileName: getUniqueFileName(arr, attachment.fileName) }];
  }, []);

  try {
    const storageService = new StorageService();
    return Promise.all(
      attachmentsWithUniqueFileNames.map(async attachment => {
        const uniqueName = getUniqueName(tenantId, attachment.fileName);
        if (!attachment.remote) {
          const rawUrl = await storageService.uploadFile(
            attachment.blob,
            uniqueName,
            null,
            attachment.type
          );
          return { ...attachment, fileUrl: getAttachmentUrl(rawUrl) };
        }
        return { ...attachment, fileUrl: attachment.remoteUrl };
      })
    );
  } catch (err) {
    Logger.error(err);
    return [];
  }
};

const EmailInfoStep = ({
  stepIndex,
  setNextStepHandlers,
  setBackStepHandlers,
  customerRepsData,
  templates,
  initialValues,
  user,
  emailData,
  handleAttachmentsChange,
  onButtonStateChange,
  onModalClose
}) => {
  const { attachments } = emailData;

  const templateOptions = useMemo(() => constructSelectOptions(templates), [templates]);
  const existingRecipientOptions =
    useMemo(() => constructContactEmailOptions(customerRepsData), [customerRepsData]) || [];

  const [recipientOptions, setRecipientOptions] = useState(existingRecipientOptions);
  const [fileSizeLimitAlert, setFileSizeLimitAlert] = useState('');
  const [formErrors, setFormErrors] = useState({ fileName: '' });

  const backStepHandler = useGetBackStepHandler();

  const dispatchButtonState = useCallback(
    buttonState => {
      onButtonStateChange(stepIndex, buttonState);
    },
    [onButtonStateChange, stepIndex]
  );

  // Reset the step button states on first render
  useEffect(() => {
    dispatchButtonState(StepButtonStateConstants.INITIALIZED);
  }, [dispatchButtonState]);

  useEffect(() => {
    setBackStepHandlers(prev => {
      const newBackStepHandlers = [...prev];
      newBackStepHandlers[stepIndex] = {
        hasConfirm: true,
        handler: () => {
          return backStepHandler();
        }
      };
      return newBackStepHandlers;
    });
  }, [backStepHandler, setBackStepHandlers, dispatchButtonState, stepIndex]);

  // Show size limit alert when attachment is over 40 MB
  useEffect(() => {
    const totalFileSize = _.sumBy(attachments, 'fileSize');
    if (totalFileSize > 40 * 1024 * 1024) {
      setFileSizeLimitAlert(getFileSizeAlertMessage(attachments.length));
      dispatchButtonState(StepButtonStateConstants.DISABLED);
    } else {
      dispatchButtonState(StepButtonStateConstants.ACTIVE);
    }
  }, [attachments, dispatchButtonState]);

  const [sendInvoiceEmail] = useSendInvoiceEmail({
    onSuccess: () => {
      onModalClose();
    },
    onResponse: () => {
      dispatchButtonState(StepButtonStateConstants.ACTIVE);
    }
  });

  const onSelectTemplate = useCallback(
    (field, template, form) => {
      if (!template?.value) return;
      const templateWithMessage = templates.find(t => t.id === template.value);
      form.setFieldValue('body', templateWithMessage.message);
    },
    [templates]
  );

  const onCreateRecipient = useCallback((field, value, form) => {
    const validEmail = validateEmail(value);
    if (!validEmail) return;

    const newOption = { label: value, value };
    setRecipientOptions(prev => [...prev, newOption]);
    const newFieldValue = Array.isArray(field.value) ? [...field.value, newOption] : [newOption];
    form.setFieldValue(field.name, newFieldValue);
  }, []);

  const configuration = useMemo(
    () =>
      getEmailInfoConfiguration({
        recipientOptions,
        templateOptions,
        onSelectTemplate,
        onCreateRecipient
      }),
    [recipientOptions, templateOptions, onSelectTemplate, onCreateRecipient]
  );

  const handleSendEmail = useCallback(
    async formData => {
      if (Object.values(formErrors).every(err => err)) return;
      dispatchButtonState(StepButtonStateConstants.LOADING);
      const { tenantId } = user;
      const attachmentsData = await uploadAttachments(tenantId, attachments);
      await sendInvoiceEmail({
        userEmail: user?.email,
        formData,
        emailData,
        attachmentsData,
        partitionKey: tenantId
      });
    },
    [attachments, emailData, formErrors, dispatchButtonState, sendInvoiceEmail, user]
  );

  const validationSchema = getEmailFormValidationSchema();

  return (
    <ThemeProvider>
      <div css={{ width: 688 }}>
        <SgtForm
          configuration={configuration}
          formikProps={{ validateOnChange: false, validateOnBlur: true }}
          initialValues={initialValues}
          layout="default"
          validationSchema={validationSchema}
          onCreateService={service =>
            setNextStepHandlers(prev => {
              const newStepHandlers = [...prev];
              newStepHandlers[stepIndex] = service.formikContext.handleSubmit;
              return newStepHandlers;
            })
          }
          onSubmit={handleSendEmail}
        />
        <Divider />
        <Typography variant={TV.L} weight={TW.BOLD}>
          Attachments
        </Typography>
        {/* Display the renamable attachment card only when there is one final attachment */}
        {attachments.length === 1 ? (
          <CombinedAttachment
            alertMessage={fileSizeLimitAlert}
            attachment={attachments[0]}
            dispatchButtonState={dispatchButtonState}
            errors={formErrors}
            handleAttachmentsChange={handleAttachmentsChange}
            setErrors={setFormErrors}
          />
        ) : (
          <>
            <Box display="flex" flexWrap="wrap" marginTop={1}>
              {attachments.map(attachment => (
                <AttachmentItemPreview fileData={attachment} removable={false} />
              ))}
            </Box>
            {!!fileSizeLimitAlert && (
              <InlineAlert
                message={fileSizeLimitAlert}
                style={{ marginTop: 16 }}
                type={InlineAlertTypes.RED}
              />
            )}
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

export default React.memo(EmailInfoStep);
