import * as R from 'ramda';
import * as Yup from 'yup';

export const getCostFieldsValidationSchema = ({ costCodes, selectEditCostCode }) => {
  const duplicatedNames =
    R.pipe(
      R.pluck('name'),
      selectEditCostCode?.name ? R.without([selectEditCostCode.name]) : R.identity,
      R.map(item => R.toLower(item))
    )(costCodes) || [];

  return Yup.object({
    name: Yup.string()
      .lowercase()
      .required('Name is required')
      .notOneOf(duplicatedNames, 'A cost code with this name already exists'),
    description: Yup.string()
  });
};
