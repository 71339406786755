import { get } from 'lodash';

import { backendDateToMoment, getTenantSettingValueForKey, isObject } from 'utils';
import { CellDataTypes } from 'utils/constants';

import DisplayData from './DisplayData';

function getComparablesFormatter(sortType) {
  switch (sortType) {
    case 'string':
      return (a, b) => [a?.toString?.().toLowerCase() ?? '', b?.toString?.().toLowerCase() ?? ''];
    case 'date':
      return (a, b) => [backendDateToMoment(a), backendDateToMoment(b)];
    case 'number':
    default:
      return (a, b) => [a, b];
  }
}

export function getComparator(sortOrder, sortBy, sortType) {
  const comparablesFormatter = getComparablesFormatter(sortType);
  const descendingComparator = (a, b, sortBy) => {
    // Optionally pre-process elements we're comparing based on their data type
    const [normA, normB] = comparablesFormatter(get(a, sortBy), get(b, sortBy));
    if (normA < normB) return 1;
    if (normA > normB) return -1;
    return 0;
  };
  return sortOrder === 'desc'
    ? (a, b) => descendingComparator(a, b, sortBy)
    : (a, b) => -descendingComparator(a, b, sortBy);
}

// Sort by `cmp` function, but with the additional constraint that if adjacent
// elements are already equal, never reorder them (hence "stable")
export function stableSort(array, cmp) {
  if (!Array.isArray(array)) return [];
  const stabilizedArray = array.map((el, index) => [el, index]);
  stabilizedArray.sort((a, b) => {
    const sortOrder = cmp(a[0], b[0]);
    if (sortOrder !== 0) return sortOrder;
    return a[1] - b[1];
  });
  const result = stabilizedArray.map(el => el[0]);
  return result;
}

const { DATE_ARRAY } = CellDataTypes;

export function dataTypeIsDate(dataType) {
  const matchingTypeNames = ['date', 'dateOnly', 'dateTime', 'datetime', DATE_ARRAY];
  return matchingTypeNames.includes(dataType);
}

export function getCellDataAlignment(metadata, colIndex) {
  if (metadata?.align) return metadata.align;
  if (metadata?.cellStyle?.textAlign) return metadata.cellStyle.textAlign;
  if (!metadata || colIndex === 0) return 'left';
  if (metadata.alignCenter) return 'center';
  return metadata.numeric ? 'right' : 'left';
}

export function enforceMetadataDefaults(metadata) {
  const updatedMeta = { ...metadata };
  const isCustomJobNumberEnabled =
    getTenantSettingValueForKey('job_customJobNumber') === 'true' || false;
  if (updatedMeta) {
    if (updatedMeta.id === 'status') updatedMeta.type = 'enum';
    if (metadata.type === 'enum' && !metadata.noAlignCenter) metadata.alignCenter = true;
    if (updatedMeta.filterType === 'number' || updatedMeta.type === 'currency')
      updatedMeta.numeric = true;

    if (!updatedMeta.sortType) {
      if (updatedMeta.numeric) updatedMeta.sortType = 'number';
      else if (dataTypeIsDate(updatedMeta.type)) updatedMeta.sortType = 'date';
      else if (updatedMeta.type === 'boolean') updatedMeta.sortType = 'boolean';
      else updatedMeta.sortType = 'string';
    }

    // Replace job number with customIdentifier
    if (isCustomJobNumberEnabled && updatedMeta.id === 'jobNumber') {
      return {
        id: 'customIdentifier',
        filterKey: 'Job.customIdentifier',
        filterType: 'string',
        numeric: false,
        label: 'Job',
        type: 'jobLink',
        bold: updatedMeta?.bold
      };
    }

    if (!updatedMeta.type) updatedMeta.type = updatedMeta.id;
  }
  return updatedMeta;
}

const getSubqueryKeys = subQuery => {
  if (!subQuery) {
    return null;
  }
  const keys = [];
  const fieldComparators = isObject(subQuery?.fieldComparator)
    ? Object.keys(subQuery?.fieldComparator).map(k => subQuery?.fieldComparator[k])
    : [subQuery?.fieldComparator];

  fieldComparators.forEach(c => {
    keys.push(
      `${subQuery?.fieldName} | ${c} | ${subQuery?.entityConnection} | ${subQuery?.subQueryFieldName}`
    );
  });

  return keys;
};

const getFieldKey = (type, filter) => {
  return `${type} | ${JSON.stringify(filter)}`;
};

const getPreConditions = subQueryCondition => {
  const preConditions = [];
  Object.keys(subQueryCondition.filter).forEach(type => {
    if (subQueryCondition.filter[type]?.length) {
      subQueryCondition.filter[type].forEach(filter => {
        preConditions.push(getFieldKey(type, filter));
      });
    }
  });
  return preConditions.length ? preConditions : null;
};

const oppositeConditions = { in: 'notIn' };

const convertToFrontendFilterCondition = (
  filterInput,
  filterType,
  options = null,
  fieldComparator = 'exists'
) => {
  const filterInputKeys = filterInput && Object.keys(filterInput);
  if (!filterInputKeys || filterInputKeys.length === 0) return null;
  // E.g. "eq" for equals or "lt" for less than
  const matchCondition = filterInputKeys[0];
  const fieldValue = {
    condition: matchCondition,
    conditionToTurnIntoText:
      fieldComparator === 'notExists' && oppositeConditions[matchCondition]
        ? oppositeConditions[matchCondition]
        : matchCondition,
    // `type` is comparison data type (bool, string, int, or float)
    type: filterType,
    // `value` is the actual value to compare against (e.g. "abc")
    // this value arr is supposed to be human readable and is set by options when it differs from filterInput[matchCondition]
    value:
      Array.isArray(options) && Array.isArray(filterInput[matchCondition])
        ? filterInput[matchCondition].map(val => {
            const humanReadableOption = options.find(o => o.value === val)?.label;
            return humanReadableOption || val;
          })
        : filterInput[matchCondition]
  };
  return fieldValue;
};

/**
 * Flattens the metadata, including the filterOptions key of the meta as part of the meta
 * This is used for when a column has multiple filter options
 * @param {meta[]} metadata
 */
export function getMetadataWithFilterOptions(metadata) {
  return metadata?.flatMap(meta => {
    if (meta.filterOptions) {
      return meta.filterOptions.map(option =>
        option.subQueryCondition
          ? {
              ...option,
              subQueryKeys: getSubqueryKeys(option.subQueryCondition),
              preConditions: getPreConditions(option.subQueryCondition)
            }
          : option
      );
    }
    return meta;
  });
}

// Vocab for filter conversion utility funcs: a filter is a collection of fields.
// A field is a particular criterion by which to include or exclude results
// (e.g. "Job number contains '23'" would be a single field).

export function backendToFrontendFilter(backendFilter, metadata) {
  // account for filterOptions key, which has nested filter meta
  const columnMetadata = getMetadataWithFilterOptions(metadata);

  const frontendFilter = {};
  Object.keys(backendFilter).forEach(filterType => {
    // E.g. all fields of type 'integerFilters'
    const fieldsOfType = backendFilter[filterType];
    if (!Array.isArray(fieldsOfType)) return;

    fieldsOfType.forEach(field => {
      const { filterInput, fieldName } = field;
      const options =
        columnMetadata.find(meta => {
          return meta.id === fieldName && meta.convertToFilterWithHumanReadableOptions === true;
        })?.options || [];
      const value = convertToFrontendFilterCondition(filterInput, filterType, options);
      if (value) {
        frontendFilter[fieldName] = value;
      }
    });
  });
  if (backendFilter?.subQueryFilters && columnMetadata) {
    backendFilter.subQueryFilters.forEach(backendSubQuery => {
      const backendFieldComparator = backendSubQuery.fieldComparator;
      const backendSubQueryKey = getSubqueryKeys(backendSubQuery)[0]; // there should only be one key here
      if (backendSubQueryKey) {
        const columnWithSubQuery = columnMetadata.find(
          columnMeta =>
            Array.isArray(columnMeta.subQueryKeys) &&
            columnMeta.subQueryKeys.some(k => k === backendSubQueryKey)
        );

        const metaFilterType = columnWithSubQuery?.filterType;

        const potentiallyAppliedFiltersNotReflectedInBackendFilter = isObject(metaFilterType)
          ? metaFilterType.customFieldConditionOptions.filter(o => o.defaultValue === 'noFilter')
          : [];

        const metaFieldComparator = columnWithSubQuery?.subQueryCondition?.fieldComparator;

        const potentiallyAppliedFilterComparators = isObject(metaFieldComparator)
          ? Object.keys(metaFieldComparator)
              .filter(k =>
                potentiallyAppliedFiltersNotReflectedInBackendFilter.some(f => f.label === k)
              )
              .map(k => ({ value: metaFieldComparator[k], key: k }))
          : [];

        if (columnWithSubQuery && columnWithSubQuery.preConditions) {
          const { preConditions } = columnWithSubQuery;
          Object.keys(backendSubQuery.filter).forEach(type => {
            backendSubQuery.filter[type].forEach(filter => {
              const fieldValueKey = getFieldKey(type, filter);
              const { filterInput, fieldName } = filter;
              const { options } = columnWithSubQuery;

              const metaFilterComparator = potentiallyAppliedFilterComparators.find(
                c => c.value === backendFieldComparator
              );
              const metaFilter = potentiallyAppliedFiltersNotReflectedInBackendFilter.find(
                f => f.label === metaFilterComparator?.key
              );

              if (preConditions.indexOf(fieldValueKey) < 0) {
                const value = convertToFrontendFilterCondition(
                  filterInput,
                  type,
                  options,
                  backendFieldComparator
                );
                if (value) {
                  frontendFilter[fieldName] = value;
                }
              } else if (metaFilter && backendSubQuery.filter[type].length < 2) {
                frontendFilter[fieldName] = { condition: metaFilter.value };
              }
            });
          });
        }

        if (!isObject(metaFieldComparator)) {
          Object.keys(backendSubQuery.filter).forEach(type => {
            backendSubQuery.filter[type].forEach(filter => {
              if (backendSubQuery?.fieldComparator === 'notExists') {
                /*
                Subquery with fieldComparator 'notExists' does not have integerFilter
                but it needs the same value as 'empty' integerFilter to display the
                correct frontend filter
              */
                const { fieldName } = filter;
                const value = {
                  condition: 'empty',
                  type: 'integerFilters',
                  value: 0
                };
                frontendFilter[fieldName] = value;
              }
            });
          });
        }
      }
    });
  }
  return frontendFilter;
}

export function frontendToBackendFilter(frontendFilter, subQueryFilter = null) {
  const backendFilter = {};
  if (subQueryFilter) {
    backendFilter.subQueryFilters = subQueryFilter;
  }
  if (!frontendFilter) return backendFilter;
  Object.keys(frontendFilter).forEach(fieldName => {
    const frontendField = frontendFilter[fieldName];
    if (frontendField.value || frontendField.value === 0 || frontendField.value === false) {
      const backendField = {
        fieldName,
        filterInput: { [frontendField.condition]: frontendField.value }
      };
      if (!backendFilter[frontendField.type]) {
        backendFilter[frontendField.type] = [];
      }
      const { convertToSubQuery, subQueryCondition } = frontendField;
      if (convertToSubQuery && subQueryCondition) {
        const { fieldComparator } = subQueryCondition;
        const subQueryFilterOnFilterKey = {
          ...subQueryCondition,
          fieldComparator: isObject(fieldComparator)
            ? fieldComparator[frontendField.label]
            : fieldComparator
        };

        if (!subQueryFilterOnFilterKey.filter) {
          subQueryFilterOnFilterKey.filter = {};
        }
        if (!subQueryFilterOnFilterKey.filter[frontendField.type]) {
          subQueryFilterOnFilterKey.filter[frontendField.type] = [];
        }

        if (backendField.filterInput[frontendField.condition] !== 'noFilter') {
          subQueryFilterOnFilterKey.filter[frontendField.type].push(backendField);
        }
        if (!backendFilter.subQueryFilters) {
          backendFilter.subQueryFilters = [];
        }
        backendFilter.subQueryFilters.push(subQueryFilterOnFilterKey);
      } else {
        backendFilter[frontendField.type].push(backendField);
      }
    }
  });
  return backendFilter;
}

export const updateConditionalSubQuery = meta => {
  const updatedConditionalMeta = { ...meta };
  Object.keys(updatedConditionalMeta.conditionalSubQuery.subQueryFields).forEach(field => {
    meta.subQueryCondition[field] = meta.conditionalSubQuery.subQueryFields[field];
  });
  return updatedConditionalMeta;
};

// @TODO - consider moving isDragDisabled, subQueryKeys, preConditions into meta.
export const updateColumnMetadata = processedColumnsMetadata => {
  const metadata = [...processedColumnsMetadata];
  metadata.forEach((meta, ind) => {
    if (meta.subQueryCondition) {
      metadata[ind].subQueryKeys = getSubqueryKeys(meta.subQueryCondition);
      metadata[ind].preConditions = getPreConditions(meta.subQueryCondition);
    }
  });
  return metadata;
};

export const getSubQueryFilters = (filter, metadata) => {
  const filterMetadata = getMetadataWithFilterOptions(metadata);
  if (filter?.subQueryFilters) {
    const conditionalSubQueryKeysArr = filterMetadata
      .filter(
        columnMeta =>
          columnMeta.subQueryCondition &&
          columnMeta.convertToSubQuery &&
          columnMeta.conditionalSubQuery
      )
      .map(columnMeta => {
        const conditionalMeta = updateConditionalSubQuery(columnMeta);
        return getSubqueryKeys(conditionalMeta.subQueryCondition);
      });
    const metaSubQueryKeysArr = filterMetadata
      .filter(columnMeta => columnMeta.subQueryCondition && columnMeta.convertToSubQuery)
      .map(columnMeta => columnMeta.subQueryKeys);
    const subQueryKeysArr = metaSubQueryKeysArr.concat(conditionalSubQueryKeysArr);
    const allSubQueryKeys = filter.subQueryFilters.map(subQuery => getSubqueryKeys(subQuery)[0]);
    const filteredSubquery = [];
    const subQueryKeys = subQueryKeysArr.reduce((acc, cv) => {
      if (!acc) {
        return [...cv];
      }
      return [...acc, ...cv];
    }, []);
    allSubQueryKeys.forEach((subQueryKey, ind) => {
      if (subQueryKeys.indexOf(subQueryKey) < 0) {
        filteredSubquery.push(filter.subQueryFilters[ind]);
      }
    });
    return filteredSubquery.length ? filteredSubquery : null;
  }
  return null;
};

const filterTypeConditionOptions = {
  text: [
    { label: 'Is', value: 'eq' },
    { label: 'Is not', value: 'ne' },
    { label: 'Contains', value: 'contains' },
    { label: 'Does not contain', value: 'notContains' },
    { label: 'Starts with', value: 'beginsWith' },
    { label: 'Ends with', value: 'endsWith' },
    { label: 'Is empty', value: 'empty', defaultValue: ' ' },
    { label: 'Is not empty', value: 'notEmpty', defaultValue: ' ' }
  ],
  // @TODO - remove this hack when we figure out why empty filter sometimes doesn't work
  textNoEmpty: [
    { label: 'Is', value: 'eq' },
    { label: 'Is not', value: 'ne' },
    { label: 'Contains', value: 'contains' },
    { label: 'Does not contain', value: 'notContains' },
    { label: 'Starts with', value: 'beginsWith' },
    { label: 'Ends with', value: 'endsWith' },
    { label: 'Is not empty', value: 'notEmpty', defaultValue: ' ' }
  ],
  numberNoEmpty: [
    { label: 'Is equal to', value: 'eq' },
    { label: 'Is not equal to', value: 'ne' },
    { label: 'Is less than', value: 'lt' },
    { label: 'Is greater than', value: 'gt' },
    { label: 'Is equal or greater than', value: 'ge' },
    { label: 'Is equal or less than', value: 'le' },
    { label: 'Is not empty', value: 'notEmpty', defaultValue: 0 }
  ],
  number: [
    { label: 'Is equal to', value: 'eq' },
    { label: 'Is not equal to', value: 'ne' },
    { label: 'Is less than', value: 'lt' },
    { label: 'Is greater than', value: 'gt' },
    { label: 'Is equal or greater than', value: 'ge' },
    { label: 'Is equal or less than', value: 'le' },
    { label: 'Is empty', value: 'empty', defaultValue: 0 },
    { label: 'Is not empty', value: 'notEmpty', defaultValue: 0 }
  ],
  select: [
    { label: 'Is', value: 'eq' },
    { label: 'Is not', value: 'ne' },
    { label: 'Is empty', value: 'empty', defaultValue: ' ' },
    { label: 'Is not empty', value: 'notEmpty', defaultValue: ' ' }
  ],
  multiSelect: [
    { label: 'Is', value: 'in' },
    { label: 'Is not', value: 'notIn' },
    { label: 'Is empty', value: 'empty', defaultValue: ' ' },
    { label: 'Is not empty', value: 'notEmpty', defaultValue: ' ' }
  ],
  date: [{ label: 'Between', value: 'between' }],
  boolean: [
    { label: 'Is', value: 'eq', defaultValue: true },
    { label: 'Is not', value: 'ne', defaultValue: true }
  ]
};

export function getFilterType(dataType) {
  let filterType;
  let filterConditionOptionsKey;

  if (isObject(dataType)) {
    return {
      filterType: dataType.filterType,
      conditionOptions: dataType.customFieldConditionOptions
    };
  }

  switch (dataType) {
    case 'boolean':
      filterType = 'booleanFilters';
      filterConditionOptionsKey = 'boolean';
      break;
    case 'number':
      filterType = 'integerFilters';
      filterConditionOptionsKey = 'number';
      break;
    case 'date':
      filterType = 'integerFilters';
      filterConditionOptionsKey = 'date';
      break;
    case 'dateString':
      filterType = 'stringFilters';
      filterConditionOptionsKey = 'date';
      break;
    case 'float':
    case 'currency':
      filterType = 'floatFilters';
      filterConditionOptionsKey = 'number';
      break;
    case 'multi-select':
      filterType = 'stringFilters';
      filterConditionOptionsKey = 'multiSelect';
      break;
    case 'select':
      filterType = 'stringFilters';
      filterConditionOptionsKey = 'select';
      break;
    case 'computed':
      filterType = 'computedColumnFilters';
      filterConditionOptionsKey = 'number';
      break;
    // @TODO - remove this hack when we figure out why empty filter sometimes doesn't work
    case 'stringNoEmpty':
      filterType = 'stringFilters';
      filterConditionOptionsKey = 'textNoEmpty';
      break;
    case 'numberNoEmpty':
      filterType = 'integerFilters';
      filterConditionOptionsKey = 'numberNoEmpty';
      break;

    default:
      filterType = 'stringFilters';
      filterConditionOptionsKey = 'text';
      break;
  }
  return {
    conditionOptions: filterTypeConditionOptions[filterConditionOptionsKey],
    filterType
  };
}

// Convert filter condition to human readable form (e.g. "lt" to "less than")
export function getFilterConditionName(condition, dataType, conditionToTurnIntoText = null) {
  const { conditionOptions } = getFilterType(dataType);

  const labelBasedOnUniqueId =
    Array.isArray(conditionOptions) &&
    conditionToTurnIntoText &&
    conditionOptions.find(o => o.id === conditionToTurnIntoText)?.label;

  const label =
    Array.isArray(conditionOptions) && conditionOptions.find(o => o.value === condition)?.label;

  return labelBasedOnUniqueId || label;
}

function isEmpty(rowValue) {
  return rowValue === undefined || rowValue === null || !rowValue.length;
}

// Returns true if the supplied table row matches the supplied filter criterion (field);
// false otherwise.
export function matchesFilterField(row, field, fieldName) {
  if (!field) return true;
  let { condition, value: filterValue } = field;
  const filterAttr = fieldName.slice(fieldName.lastIndexOf('.') + 1);
  let rowValue = row[filterAttr];
  if (rowValue == null) return false;

  if (typeof rowValue === 'string') rowValue = rowValue.trim().toLowerCase();
  if (typeof filterValue === 'string') filterValue = filterValue.trim().toLowerCase();
  switch (condition) {
    case 'beginsWith':
      return rowValue.startsWith?.(filterValue);
    case 'endsWith':
      return rowValue.endsWith?.(filterValue);
    case 'empty':
      return isEmpty(rowValue);
    case 'notEmpty':
      return !isEmpty(rowValue);
    case 'contains':
      return rowValue.includes?.(filterValue);
    case 'notContains':
      return !rowValue.includes?.(filterValue);
    case 'eq':
      return rowValue === filterValue;
    case 'ne':
      return rowValue !== filterValue;
    case 'lt':
      return rowValue < filterValue;
    case 'le':
      return rowValue <= filterValue;
    case 'gt':
      return rowValue > filterValue;
    case 'ge':
      return rowValue >= filterValue;

    default:
      return true;
  }
}

export function getCellComponent(metadata, customCellComponents) {
  // Custom components (e.g. download button) are passed as key-value pairs
  const CustomDataComponent =
    (metadata.isCustom && customCellComponents && customCellComponents[metadata.type]) ||
    // Dummy component
    function() {
      return null;
    };
  return metadata.isCustom ? CustomDataComponent : DisplayData;
}

export function getRowId(row) {
  if (!row) return row;
  if (row.id) return row.id;
  const idComponents = [];
  Object.keys(row).forEach(field => {
    idComponents.push(JSON.stringify(row[field]));
  });
  return idComponents.join('');
}
