import React, { useState } from 'react';

import { calculateMarkupFromMargin } from '@BuildHero/math';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import Context from 'components/Context';
import { snackbarOn } from 'redux/actions/globalActions';
import { createPriceBook } from 'services/API/priceBook';
import { CompanyService } from 'services/core';
import { Logger } from 'services/Logger';
import { logErrorWithCallback } from 'utils';
import { PricingStrategy } from 'utils/constants';
import { convertForMathLib } from 'utils/mathLibrary';

import Labels from '../../../meta/labels';
import ModifyPricebookCommon from '../ModifyPricebook';

const CreatePricebookPopup = ({ open, handleClose, user, onSubmit }) => {
  const companyService = new CompanyService();
  const pricebookVersion = useSelector(s => s.company.pricebookVersion);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const marginEnabled = useSelector(s => s.settings.pricingStrategy === PricingStrategy.MARGIN);
  const markupAndMarginEnabled = useSelector(
    s => s.settings.pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN
  );
  const createPricebookV1 = async (tenantId, payload) => {
    try {
      const result = await companyService.addPricebookToCompany(tenantId, payload);
      return result;
    } catch (error) {
      logErrorWithCallback(
        error,
        snackbarOn,
        'Unable to add new pricebook, please try again later'
      );
    }
  };

  const createPricebookV2 = async inPayload => {
    const labourPriceBook2Id =
      inPayload.labourPricebookId && inPayload.labourPricebookId.length > 0
        ? inPayload.labourPricebookId
        : undefined;

    try {
      const payload = {
        labourEntries: inPayload.labourEntries,
        labourPriceBook2Id,
        priceBookIdToClone: inPayload.materialMarkupPricebookId,
        priceBook2: {
          name: inPayload.name,
          description: inPayload.description,
          baseMaterialMarkup: inPayload.markupValue,
          rangedMaterialMarkupJSON: JSON.stringify(inPayload.materialMarkupEntries)
        }
      };
      const result = await createPriceBook(payload);
      return result;
    } catch (error) {
      logErrorWithCallback(
        error,
        snackbarOn,
        'Unable to add new pricebook, please try again later'
      );
    }
  };

  const createPricebook = async (tenantId, payload) => {
    const result =
      pricebookVersion >= 2
        ? await createPricebookV2(payload)
        : await createPricebookV1(tenantId, payload);
    return result;
  };

  const handleCreatePricebook = async data => {
    setIsSubmitting(true);
    const hasSelectedPricebookFromLaborSelect = !Array.isArray(data.laborRate);
    const hasSelectedPricebookFromMaterialSelect = !Array.isArray(data.materialRate);
    const handleContextRefresh = () => Logger.debug('Context is refreshed');
    const labourEntries =
      hasSelectedPricebookFromLaborSelect || !data.laborRate
        ? []
        : data.laborRate.reduce((acc, laborType) => {
            const billingHourTypeIds = Object.keys(laborType).filter(str => str.includes('-')); // looks for hashes in object
            return [
              ...acc,
              ...billingHourTypeIds.map(billingHourTypeId => ({
                isActive: true,
                labourTypeId: laborType.id,
                costCodeId: laborType.costCodeId,
                revenueTypeId: laborType.revenueTypeId,
                billingHourTypeId,
                rate: laborType[billingHourTypeId].rate || 0
              }))
            ];
          }, []);

    const payload = {
      tenantCompanyId: user.tenantCompanyId,
      name: data.name,
      markupType: 'Percentage',
      markupValue: data.defaultMarkup || 0,
      description: data.description,
      isDefault: false, // deprecated for defaultPriceBookId under Company
      labourPricebookId:
        hasSelectedPricebookFromLaborSelect && data.laborRate ? data.laborRate.value : '',
      labourEntries,
      materialMarkupPricebookId:
        hasSelectedPricebookFromMaterialSelect && data.materialRate ? data.materialRate.value : '',
      materialMarkupEntries: !hasSelectedPricebookFromMaterialSelect
        ? data.materialRate
        : [{ start: 0, end: null, rate: 0 }]
    };
    if (marginEnabled || data?.pricebookConfig === PricingStrategy.MARGIN) {
      payload.markupValue = convertForMathLib(calculateMarkupFromMargin, data.defaultMarkup) ?? 0;
      payload.materialMarkupEntries = payload.materialMarkupEntries?.map(m => ({
        ...m,
        rate: convertForMathLib(calculateMarkupFromMargin, m.rate)
      }));
    }

    const createResult = await createPricebook(user.tenantId, payload);

    onSubmit(createResult?.data?.addPriceBook_new);
    setIsSubmitting(false);
    handleClose();
    Context.setCompanyContext(
      user.tenantId,
      Context.generateCompanyContextSortKey(user),
      handleContextRefresh,
      true
    );
  };

  return (
    <ModifyPricebookCommon
      allowFormatChange
      handleClose={handleClose}
      initializeMaterialRate={false}
      isSubmitting={isSubmitting}
      marginEnabled={marginEnabled}
      open={open}
      showConfigSection={markupAndMarginEnabled}
      showLabourRateSection
      title={Labels.addPricebook[user.locale]}
      user={user}
      onSubmit={handleCreatePricebook}
    />
  );
};

CreatePricebookPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    tenantCompanyId: PropTypes.string.isRequired,
    tenantId: PropTypes.string.isRequired
  }).isRequired,
  onSubmit: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = { snackbarOn };
export default connect(mapStateToProps, mapDispatchToProps)(CreatePricebookPopup);
