import React from 'react';

import { connect } from 'react-redux';

import { JobsTable, PageHeader, UserPermission } from 'components';
import CreateEntryButton from 'components/Buttons/CreateEntryButton';
import { setOpenQuickAddModal, snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { PermissionConstants } from 'utils/AppConstants';
import { QuickAddModal } from 'utils/constants';

import './JobList.css';

const JobsList = ({ user, addModal }) => {
  const pageHeaderButtons = [
    <CreateEntryButton
      caslKey={PermissionConstants.OBJECT_JOB}
      handleAdd={() => addModal(QuickAddModal.CREATE_JOB)}
      key="createJob"
      label="New Job"
    />
  ];

  return (
    <ErrorBoundaries>
      <UserPermission action={PermissionConstants.OBJECT_JOB} I="read">
        <PageHeader
          overrideHeaderButtons={pageHeaderButtons}
          pageMapKey="jobs"
          userLocale={user.locale}
        />
        <JobsTable />
      </UserPermission>
    </ErrorBoundaries>
  );
};

const mapStateToProps = state => ({
  user: state.user
});
const mapDispatchToProps = { snackbar: snackbarOn, addModal: setOpenQuickAddModal };

const connectedJobs = connect(mapStateToProps, mapDispatchToProps)(JobsList);

export default connectedJobs;
