import { useExtendedFetchForPMAPI as useExtendedFetch } from 'customHooks/useExtendedFetch';

export const parseSettings = data => data?.settingsJSON || [];

export const serializeSettings = settings => ({
  settingsJSON:
    settings
      .filter(setting => Boolean(setting.directoryName))
      .map(({ key, directoryName, permissions }) => ({
        key,
        directoryName,
        permissions: permissions || []
      })) || []
});

export const useGetProjectFileStorageSettings = () => {
  return useExtendedFetch(
    {
      url: 'fs-settings/pm'
    },
    {
      defaultData: [],
      transform: parseSettings
    }
  );
};

export const useUpdateProjectFileStorageSettings = () => {
  return useExtendedFetch(
    {
      url: 'fs-settings/pm',
      method: 'POST'
    },
    {
      defaultData: [],
      manual: true
    }
  );
};
