import { useCallback } from 'react';

import gql from 'graphql-tag';

import { useSelector } from 'react-redux';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import {
  mutationOperations,
  updateTaskTemplateCacheForTaskTemplateChecklistQuery
} from '../helper';

import { getTaskTemplateCheckLists, TaskTemplateFragment } from './useChecklistLibrary';

const UPDATE_TASK_TEMPLATE = gql`
  mutation updateTaskTemplate($taskTemplateId: String!, $input: TaskTemplateUpdateInput!) {
    updateTaskTemplate(taskTemplateId: $taskTemplateId, input: $input) {
      ${TaskTemplateFragment}
    }
  }
`;

export const useUpdateTaskTemplate = ({ assetTypeId, checklistId, ...options } = {}) => {
  const user = useSelector(state => state.user);
  const update = useCallback(
    (cache, { data: { updateTaskTemplate } }) => {
      const query = {
        query: getTaskTemplateCheckLists
      };
      const queryVariables = {
        tenantId: user.tenantId,
        assetTypeId: assetTypeId || 'null'
      };

      const cachedData = cache.readQuery({
        query: getTaskTemplateCheckLists,
        variables: queryVariables
      });

      if (!cachedData) return;

      const updatedTaskTemplateChecklists = updateTaskTemplateCacheForTaskTemplateChecklistQuery(
        cachedData,
        checklistId,
        updateTaskTemplate,
        mutationOperations.UPDATE
      );

      cache.writeQuery({
        ...query,
        variables: queryVariables,
        data: { taskTemplateChecklists: updatedTaskTemplateChecklists }
      });
    },
    [assetTypeId, checklistId, user.tenantId]
  );
  return useExtendedMutation(UPDATE_TASK_TEMPLATE, {
    serializer: ({
      id,
      name,
      description,
      startDateTime,
      interval,
      numberOfOccurrences,
      laborEstimate,
      forms,
      isSingleJob
    }) => ({
      variables: {
        taskTemplateId: id,
        input: {
          name,
          description,
          startDateTime,
          interval,
          numberOfOccurrences,
          laborEstimate,
          formsInput: forms?.map(f => ({ id: f.id, isRequired: f.isRequired })),
          isSingleJob
        }
      }
    }),
    update,
    transform: ({ updateTaskTemplate }) => updateTaskTemplate,
    onError: (transformedData, snackbarOn) => snackbarOn('error', 'Unable to update Task'),
    ...options
  });
};
