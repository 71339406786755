import React from 'react';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Field, FieldArray } from 'formik';
import { connect } from 'react-redux';

import labels from 'meta/labels';

import { getNumberValue } from 'utils';

import MuiButton from '../Button';
import Hidden from '../Hidden';

import FieldStrategy from './strategy';

import styles from './styles';
/**
 * Renders Formik fields with material UI inputs. The fieldtype will be picked from the layout meta
 * @param {field} props
 */
const FormikFields = props => {
  const fieldItem = props.cols;
  const { index } = props;
  let lgValue = 2;
  let mdValue = 4;
  let smValue = 12;
  // when no behaviour, the field should not
  if (fieldItem.behavior[props.mode]) {
    smValue = getNumberValue(fieldItem.behavior[props.mode].sm) || 12;
    mdValue = getNumberValue(fieldItem.behavior[props.mode].md) || 4;
    lgValue = getNumberValue(fieldItem.behavior[props.mode].lg) || 2;
  } else {
    return null;
  }

  // include special behaviour only when it exists
  if (!fieldItem.specialBehaviour) {
    fieldItem.specialBehaviour = 'false';
  } else if (fieldItem.specialBehaviour.includeQueryResult) {
    fieldItem.specialBehaviour.queryResult = props.queryResult;
  }

  if (fieldItem.type === 'dbField') {
    return (
      <Grid
        item
        key={`grid${index}`}
        lg={lgValue}
        md={mdValue}
        sm={smValue}
        xl={lgValue}
        xs={smValue}
        //  className={customStyles.gridCustomStyle}
      >
        <Field
          component={FieldStrategy[fieldItem.behavior[props.mode].ui]}
          key={`field${index}`}
          label={
            (labels[fieldItem.label] && labels[fieldItem.label][props.user.locale]) ||
            fieldItem.label
          }
          name={fieldItem.name}
          //    helperText={fieldItem.value}
          placeholder={
            (labels[fieldItem.label] && labels[fieldItem.label][props.user.locale]) ||
            fieldItem.label
          }
          specialbehaviour={fieldItem.specialBehaviour}
          user={props.user}
        />
      </Grid>
    );
  }

  if (fieldItem.type === 'dbArrayField') {
    // strip off some props

    const { classes, ...rest } = props;
    const pageMode = props.mode;
    const FieldArrayType = FieldStrategy[fieldItem.behavior[props.mode].ui];
    return (
      <Grid
        item
        key={`gridArr${index}`}
        lg={lgValue}
        md={mdValue}
        sm={smValue}
        xl={lgValue}
        xs={smValue}
        //  className={customStyles.gridCustomStyle}
      >
        <FieldArray name={fieldItem.value} validateOnChange={false}>
          {fieldArrayProps => (
            <FieldArrayType
              {...fieldArrayProps}
              buttons={props.buttons}
              fieldProps={fieldItem}
              label={
                (labels[fieldItem.label] && labels[fieldItem.label][props.user.locale]) ||
                fieldItem.label
              }
              mode={pageMode}
              specialbehaviour={fieldItem.specialBehaviour}
              {...rest}
            />
          )}
        </FieldArray>
      </Grid>
    );
  }

  if (fieldItem.type === 'dbHidden') {
    return (
      <Field
        component={Hidden}
        key={`field${index}`}
        // placeholder={labels[fieldItem.label][props.user.locale]}
        // label={labels[fieldItem.label][props.user.locale]}
        //    helperText={fieldItem.value}
        name={fieldItem.value}
      />
    );
  }

  if (fieldItem.type === 'dbAction') {
    return (
      <Grid
        item
        key={`grid${index}`}
        lg={lgValue}
        md={mdValue}
        sm={smValue}
        xl={lgValue}
        xs={smValue}
      >
        <Field
          buttons={props.buttons}
          component={MuiButton}
          key={`field${index}`}
          label={labels[fieldItem.label][props.user.locale]}
          name={fieldItem.name}
          placeholder={labels[fieldItem.label][props.user.locale]}
          specialbehaviour={fieldItem.specialBehaviour}
        />
        <div style={{ paddingTop: 22 }} />
      </Grid>
    );
  }
};

// User state from Redux is fetched to retrieve user locale
const mapStateToProps = state => ({
  user: state.user
});

const styledFormikField = withStyles(styles, { withTheme: true })(FormikFields);

export default connect(mapStateToProps, null)(styledFormikField);
