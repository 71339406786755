import { groupBy } from 'lodash';

import { getState } from 'redux/store';
import { BillLineItemStatus } from 'utils/AppConstants';
import { getMarkupFromMargin } from 'utils/onCalcChange';
import parseBool from 'utils/parseBool';

export const selectPOModalInitialData = ({ visit, job }) => ({
  department: {
    tagName: visit?.departmentName || '',
    id: visit?.departmentId
  },
  jobAndProject: {
    id: job?.id,
    jobNumber: job?.customIdentifier || job?.jobNumber || '',
    customerPropertyId: job?.customerProperty?.id
  },
  assignedTo: null
});

export const selectPurchasedItemProps = poWithBills => ({
  fileUrl: poWithBills.purchaseOrderReceipt?.imageUrl,
  createdBy: poWithBills.purchaseOrder?.createdBy,
  vendorName: poWithBills.purchaseOrder?.vendorName,
  receiptNumber: poWithBills.purchaseOrderReceipt?.receiptNumber,
  poNumber: poWithBills.purchaseOrder?.poNumber,
  dateOfPurchase: poWithBills.purchaseOrder?.dateOfPurchase,
  totalAmountPreTax: poWithBills.purchaseOrder?.totalAmountPreTax
});

export const selectBillModalProps = ({
  visit,
  job,
  reviewReport,
  groupedBills,
  reviewReportBillLines
}) => ({
  data: {},
  mode: null,
  title: 'Add Bill Item(s)',
  service: {
    subscribe: () => ({})
  },
  user: getState()?.user,
  billOptions:
    reviewReportBillLines
      ?.filter(bl =>
        [BillLineItemStatus.NOT_INVOICED, BillLineItemStatus.PARTIALLY_INVOICED].includes(
          bl.invoicedStatus
        )
      )
      .map(bl => ({
        ...bl,
        usedQuantityOnVisit: Number.parseInt(bl.usedQuantityOnVisit, 10),
        remainingQuantity: Number.parseInt(bl.remainingQuantity, 10)
      })) || [],
  reviewReportId: reviewReport?.id,
  billsForTable: groupedBills
});

export const selectEditLineItemInput = ({ lineItem, reviewReportId, isMarginEnabled }) => ({
  costCodeId: lineItem.costCodeId,
  revenueTypeId: lineItem.revenueTypeId,
  jobCostTypeId: lineItem.jobCostTypeId,
  description: lineItem.description,
  id: lineItem.id,
  billLineId: lineItem.billLineId,
  markup: isMarginEnabled ? getMarkupFromMargin(lineItem.markup) : parseFloat(lineItem.markup),
  quantity: parseInt(lineItem.quantity, 10),
  reviewReportId,
  taxable: parseBool(lineItem.taxable),
  unitCost: parseFloat(lineItem.unitCost),
  unitPrice: parseFloat(lineItem.unitPrice)
});

export const selectGroupedBillsForModal = ({ reviewReportBillLines }) => {
  if (!reviewReportBillLines) return [];

  return Object.values(
    groupBy(
      reviewReportBillLines.filter(
        billLineItem =>
          billLineItem.remainingQuantity &&
          billLineItem.invoicedStatus !== BillLineItemStatus.INVOICED
      ),
      'billId'
    )
  ).map(billLineItems => ({
    billId: billLineItems[0]?.billId,
    billNumber: billLineItems[0]?.billNumber,
    billLineItems: billLineItems.map(billLineItem => ({
      billLineId: billLineItem.id,
      description: billLineItem.description,
      invoicedStatus: billLineItem.invoicedStatus,
      jobId: billLineItem.jobId,
      quantity: parseInt(billLineItem.quantity, 10),
      remainingQuantity: parseInt(billLineItem.remainingQuantity, 10),
      taxable: billLineItem.taxable
    }))
  }));
};
