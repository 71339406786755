import React, { useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { MUIForm } from '@BuildHero/sergeant';
import { Divider } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import PreferredTechniciansFormLayoutComponent from 'components/PreferredTechniciansForm/PreferredTechniciansFormLayoutComponent';
import { Subtitle, Title } from 'components/Tasks';
import { maintenanceFrequency } from 'constants/common';
import useEmployees from 'customHooks/useEmployees';
import { CustomerRepLayout } from 'meta/Customer/CustomerRep/layout';
import { snackbarOn } from 'redux/actions/globalActions';
import CustomerRep, { RepType } from 'scenes/Customer/CustomerRepModal';
import { validations } from 'services/core';

import { getTenantSettingValueForKey, isTenantSettingEnabled } from 'utils';

import { CustomFieldTypes } from 'utils/constants';
import { constructSelectOptions } from 'utils/constructSelectOptions';

import { getDepartments, useCrews } from '../../../../../helpers';
import { defaultMaintenanceData } from '../../../constants';

import { templateValidation } from '../helpers';

import { getCustomerRepsQuery } from './gql';
import { MaintenanceForm } from './layout';

const getPreferredTechnicians = (maintenanceTemplate, serviceAgreement) => {
  const { preferredTechnicians } =
    maintenanceTemplate?.id || maintenanceTemplate?.propertyId
      ? maintenanceTemplate
      : serviceAgreement;
  return _.pick(preferredTechnicians, [
    'departmentId',
    'crewId',
    'primaryTechnicianId',
    'additionalTechnicianIds'
  ]);
};

const maintenanceFrequencyOptions = Object.entries(maintenanceFrequency).map(([key, value]) => ({
  label: value,
  value: key
}));

const MaintenanceTemplateInfo = ({
  selectedProperties,
  data,
  agreementInfo,
  serviceAgreementsSettings,
  formServiceRefArr,
  handleFormService,
  handleOnCompleteForm,
  stepIndex,
  user,
  snackbarOn: snackbar,
  companyTimeZone
}) => {
  const [jobTags, setJobTags] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [openCustomerRep, setOpenCustomerRep] = useState(false);
  const [newRepName, setNewRepName] = useState('');
  const [propertyId, setPropertyId] = useState(data.propertyId);
  const [customerPropertyRepId, setCustomerPropertyRepId] = useState(data.customerPropertyRepId);

  const [isNumberOfOccurrencesDisabled, setIsNumberOfOccurrencesDisabled] = useState(
    !!data?.endDate
  );
  const [isEndDateDisabled, setIsEndDateDisabled] = useState(data?.numberOfOccurrences > 0);

  const company = useSelector(state => state.company);

  const crewTimeTracking = getTenantSettingValueForKey('crewTimeTracking') === 'true';

  useEffect(() => {
    setJobTags(company.jobTags);
    setJobTypes(company.jobTypes);
  }, [company]);

  const { data: getCustomerReps, refetch } = useQuery(getCustomerRepsQuery, {
    variables: {
      partitionKey: user.tenantId,
      sortKey: agreementInfo.customer.sortKey
    }
  });
  const properties = getCustomerReps?.getCustomer.customerProperties.items || [];

  const showTotalBudgetedHours = isTenantSettingEnabled('budgetLaborHoursAtJobLevel');
  const isAssetEnabled = isTenantSettingEnabled('assetTrackingAgainstTask');

  const jobTagOptions = constructSelectOptions(_.sortBy(jobTags?.items, 'sortOrder'), 'tagName');

  const maintenanceTypes = _.sortBy(
    jobTypes?.items?.filter(type => type.tagType === CustomFieldTypes.MaintenanceTypes),
    'sortOrder'
  );
  const maintenanceTypeOptions = constructSelectOptions(maintenanceTypes, 'tagName');

  const departments = getDepartments();
  const [technicians] = useEmployees({
    includeDepartments: true,
    filter: { isActive: { eq: true }, isTech: { eq: true } }
  });
  const crews = useCrews();

  const showPreferredTechnicians =
    serviceAgreementsSettings?.enableTechnicianSelectionAtMaintenanceTemplateLevel;

  const formData = useMemo(
    () => ({
      ...(data || defaultMaintenanceData),
      tags: (data?.tags || []).map(tag => (tag.id ? tag.id : tag)),
      preferredTechnicians: showPreferredTechnicians
        ? getPreferredTechnicians(data, agreementInfo)
        : undefined,
      customerPropertyRepId
    }),
    [data, defaultMaintenanceData, agreementInfo, showPreferredTechnicians, customerPropertyRepId]
  );

  const handleOpenCustomerRepPopUp = ({ field, value, form }) => {
    const fName = field.name;
    const newFormValues = form.values;
    if (fName === 'customerPropertyRepId' && newFormValues.propertyId) {
      setOpenCustomerRep(true);
      setNewRepName(value);
      setPropertyId(newFormValues.propertyId);
    } else {
      snackbar('error', 'Please select a property first', '');
    }
  };

  const handleClosePopUp = res => {
    setOpenCustomerRep(false);
    if (res) {
      setCustomerPropertyRepId(res.addCustomerRepToCustomer[0].id);
      refetch();
    }
  };

  const handleEndDateChange = ({ currentValue }) => {
    const date = currentValue ?? 0;
    setIsNumberOfOccurrencesDisabled(date > 0);
  };

  const handleNumberOfOccurrencesChange = ({ currentValue }) => {
    const occurrences = currentValue ?? 0;
    setIsEndDateDisabled(occurrences > 0);
  };

  return (
    <>
      <MUIForm
        configuration={MaintenanceForm({
          crews,
          departments,
          handleOpenCustomerRepPopUp,
          handlePropertyChange: ({ value }) => setPropertyId(value),
          jobTagOptions,
          maintenanceFrequencyOptions,
          maintenanceTypeOptions,
          properties,
          propertyId,
          propertyOptions: selectedProperties,
          showPreferredTechnicians,
          showPropertySubtext:
            !!data.propertyId && data.maintenanceTaskTemplates?.length > 0 && isAssetEnabled,
          showTotalBudgetedHours,
          technicians,
          handleEndDateChange,
          isEndDateDisabled,
          handleNumberOfOccurrencesChange,
          isNumberOfOccurrencesDisabled,
          companyTimeZone,
          crewTimeTracking
        })}
        customComponents={{
          Title,
          Subtitle,
          Divider,
          PreferredTechniciansFormLayoutComponent: componentProps => (
            <PreferredTechniciansFormLayoutComponent
              hideExtraTechNumber
              parentFormService={formServiceRefArr.current?.[stepIndex]}
              {...componentProps}
            />
          )
        }}
        data={formData}
        layout="edit"
        validationSchema={templateValidation(agreementInfo)}
        onComplete={handleOnCompleteForm}
        onCreateService={service => handleFormService(service, stepIndex)}
      />
      <CustomerRep
        data={{ firstName: newRepName }}
        handleClose={(flag, response) => {
          handleClosePopUp(response);
        }}
        layout={CustomerRepLayout}
        mode="new"
        open={openCustomerRep}
        parent={{
          id: (() => {
            const { sortKey } = agreementInfo.customer;
            return _.last(sortKey.split('_'));
          })(),
          sortKey: agreementInfo.customer.sortKey,
          customerName: agreementInfo.customer.customerName,
          tenantId: user.tenantId,
          tenantCompanyId: user.tenantCompanyId,
          hierarchy: `${user.tenantId}_${user.tenantCompanyId}`,
          entityType: 'Customer',
          partitionKey: user.tenantId,
          customerPropertyId: propertyId
        }}
        repType={RepType.CUSTOMER}
        validationSchema={validations.customerRepSchema}
      />
    </>
  );
};

MaintenanceTemplateInfo.propTypes = {
  user: PropTypes.shape({
    tenantId: PropTypes.string,
    tenantCompanyId: PropTypes.string
  }).isRequired,
  serviceAgreementsSettings: PropTypes.object.isRequired,
  selectedProperties: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.object.isRequired,
  agreementInfo: PropTypes.object.isRequired,
  formServiceRefArr: PropTypes.array.isRequired,
  handleFormService: PropTypes.func.isRequired,
  handleOnCompleteForm: PropTypes.func.isRequired,
  stepIndex: PropTypes.number.isRequired,
  companyTimeZone: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});
const mapDispatcherToProps = { snackbarOn };

export default connect(mapStateToProps, mapDispatcherToProps)(MaintenanceTemplateInfo);
