import React, { useCallback, useEffect, useState } from 'react';

import { Button, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ResponsiveTable, Spinner } from 'components';
import { snackbarOn } from 'redux/actions/globalActions';
import { ListService } from 'services/core';
import theme from 'themes/BuildHeroTheme';
import { logErrorWithCallback, roundCurrency } from 'utils';
import { Mode } from 'utils/constants';

import ApplyPaymentFromInvoiceInput from './ApplyPaymentFromInvoiceInput';
import { addInvoiceToPaymentRowsMeta } from './layout';

const RouterLink = React.forwardRef((props, ref) => <Link {...props} ref={ref} />);

const PaymentApplicationTable = ({ form, field, user }) => {
  const {
    billingCustomerId,
    billingCustomerName,
    invoiceBalance,
    invoiceId,
    totalAmount
  } = form?.values;
  const [showResults, setShowResults] = useState(false);
  const [payments, setPayments] = useState([]);
  const [amountMap, setAmountMap] = useState({});
  const [appliedPaymentsMap, setAppliedPaymentsMap] = useState({});

  const [totalSpentPayment, setTotalSpentPayment] = useState();
  const localAmountMap = {};

  // Update the 'invoiceBalance' field when totalAmount or totalSpentPayment
  // has been updated.
  useEffect(() => {
    const newBalance = roundCurrency(totalAmount - totalSpentPayment);
    if (newBalance !== invoiceBalance) {
      form.setFieldValue('invoiceBalance', newBalance);
    }
  }, [form, totalSpentPayment, invoiceBalance, totalAmount]);

  const getCustomerPayments = async (limit, offset, sortBy, sortOrder, status) => {
    const sortKey = `${user.tenantId}_Company_${user.tenantCompanyId}`;
    const listService = new ListService();
    const hardFilter = {
      stringFilters: [
        {
          fieldName: 'BillingCustomer.id',
          filterInput: { eq: billingCustomerId }
        }
      ]
    };

    try {
      const { items, paymentInvoicesForInvoice } = await listService.getAllPayments(
        user.tenantId,
        sortKey,
        hardFilter,
        limit,
        offset,
        sortBy,
        sortOrder,
        status,
        invoiceId
      );
      setTotalSpentPayment(
        items.reduce((total, payment) => total + (payment.amountToApply || 0), 0)
      );
      form.setFieldValue('paymentInvoices', paymentInvoicesForInvoice);
      form.setFieldValue(
        'paymentMap',
        items.reduce((map, payment) => {
          const localMap = map;
          localMap[payment.id] = payment;
          return localMap;
        }, {})
      );

      // Hide payments that have no available amount and don't have paymentInvoices already for this invoice
      setPayments(items.filter(payment => payment.availableAmount || payment.amountToApply));
      setShowResults(true);
    } catch (error) {
      logErrorWithCallback(error, snackbarOn, 'Unable to fetch Payments, please try again later');
      setPayments([]);
    }
  };

  // if invoiceId is provided, load right away
  useEffect(() => {
    if (invoiceId && billingCustomerId) getCustomerPayments();
  }, [invoiceId, billingCustomerId]); // eslint-disable-line react-hooks/exhaustive-deps

  const addAppliedPaymentsMap = useCallback(
    ({ paymentId, amount, removedAmount }) => {
      const newAmountMap = { ...amountMap };
      newAmountMap[paymentId] = amount;
      const newAppliedPaymentsMap = { ...appliedPaymentsMap };
      newAppliedPaymentsMap[paymentId] = amount;
      let updateAvailableAmount;

      // If we are canceling an amount, add it to the total. Otherwise, remove it.
      if (invoiceBalance || invoiceBalance === 0) {
        let newTotalSpentPayment = totalSpentPayment;
        if (amount === Mode.DELETE) {
          newTotalSpentPayment -= removedAmount;
          updateAvailableAmount = removedAmount;
        } else {
          newTotalSpentPayment += amount;
          updateAvailableAmount = -1 * amount;
        }
        setTotalSpentPayment(roundCurrency(newTotalSpentPayment));
      }
      // Update the Balance cell
      const newPayments = payments.map(record => {
        const updatedRecord = { ...record };
        if (updatedRecord.id === paymentId) {
          updatedRecord.availableAmount = roundCurrency(
            updatedRecord.availableAmount + updateAvailableAmount
          );
        }
        return updatedRecord;
      });

      setPayments(newPayments);
      setAmountMap({ ...newAmountMap, ...localAmountMap });
      setAppliedPaymentsMap(newAppliedPaymentsMap);
      form.setFieldValue(field.name, newAppliedPaymentsMap);
    },
    [
      form,
      field,
      appliedPaymentsMap,
      localAmountMap,
      amountMap,
      invoiceBalance,
      totalSpentPayment,
      payments
    ]
  );

  const customCellComponents = {
    applyPaymentToInvoice: ({ record }) => {
      const { id, amountToApply } = record;
      const appliedValue = appliedPaymentsMap[id] || amountToApply;
      const value = amountMap[id] || amountToApply;
      const isApplied = appliedValue !== null && appliedValue !== Mode.DELETE;

      return (
        <ApplyPaymentFromInvoiceInput
          addAppliedPaymentsMap={addAppliedPaymentsMap}
          availableAmount={record.availableAmount}
          id={id}
          invoiceOutstandingBalance={totalAmount}
          isApplied={isApplied}
          updateAmountMap={({ paymentId, amount }) => {
            localAmountMap[paymentId] = amount;
          }}
          value={value}
        />
      );
    }
  };

  return (
    <>
      {showResults ? (
        <>
          <Typography style={{ marginTop: theme.spacing(3) }} variant="h6">
            Available Payments
          </Typography>
          <div style={{ display: 'flex', marginBottom: theme.spacing(2) }}>
            <Typography style={{ marginRight: 4 }} variant="body2">
              for
            </Typography>
            <Button
              component={RouterLink}
              disableRipple
              to={{ pathname: `/customer/view/${billingCustomerId}` }}
            >
              {billingCustomerName}
            </Button>
          </div>
          <ResponsiveTable
            customCellComponents={customCellComponents}
            data={payments || []}
            disableFilter
            rowMetadata={addInvoiceToPaymentRowsMeta}
            showToolbars
          />
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const connectedPaymentApplicationTable = connect(
  mapStateToProps,
  mapDispatcherToProps
)(PaymentApplicationTable);

export default connectedPaymentApplicationTable;
