import { useMemo } from 'react';

import { useApolloClient } from '@apollo/client';
import { gql } from '@apollo/client/core';

export const useInitialData = visit => {
  const { cache } = useApolloClient();
  let assignedTo;

  if (visit?.primaryTechId) {
    assignedTo = cache.readFragment({
      id: cache.identify({ __typename: 'Employee', id: visit?.primaryTechId }),
      fragment: gql`
        fragment Tech on Employee {
          id
          name
        }
      `
    });
  }

  return useMemo(
    () => ({
      jobAndProject: {
        id: visit?.job?.id,
        jobNumber: visit?.job?.customIdentifier || visit?.job?.jobNumber,
        customerPropertyId: visit?.property?.id
      },
      department: {
        tagName: visit?.departmentName,
        id: visit?.departmentId
      },
      ...(assignedTo ? { assignedTo } : {})
    }),
    [visit, assignedTo]
  );
};
