export const unsavedChanges = ({ onConfirm, onCancel }) => ({
  children: 'You have unsaved changes. Are you sure you want to continue?',
  buttons: {
    confirm: {
      buttonType: 'contained',
      color: 'primary',
      label: 'Confirm',
      action: onConfirm
    },
    cancel: {
      buttonType: 'outlined',
      label: 'Cancel',
      action: onCancel
    }
  }
});

export const deleteItem = ({ onConfirm, onCancel }) => ({
  children: 'Are you sure you want delete this item?',
  buttons: {
    confirm: {
      buttonType: 'contained',
      color: 'primary',
      label: 'Confirm',
      action: onConfirm
    },
    cancel: {
      buttonType: 'outlined',
      label: 'Cancel',
      action: onCancel
    }
  }
});
