import gql from 'graphql-tag';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { first, isArray } from 'lodash';

import { EventEntities } from '@dispatch/Dispatch.constants';
import { COMPANY_SETTING_TYPE } from 'constants/common';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import { getState } from 'redux/store';
import { Logger } from 'services/Logger';
import { TimeTrackingSettings } from 'utils/AppConstants';

const SETTINGS_QUERY = gql`
  query getCompanySettings(
    $partitionKey: String!
    $sortKey: String!
    $filter: TableCompanySettingFilterInput
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      companySettings(entityConnection: "CompanySetting", filter: $filter) {
        items {
          id
          type
          version
          settings
        }
      }
    }
    listTenantSettings(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      partitionKey
      sortKey
      hierarchy
      tenantId
      tenantCompanyId
      settingKey
      settingPath
      settingValue
    }
  }
`;

const parseEnabledEventTypes = (settings, settingKey) => {
  if (!settings) {
    return {};
  }
  try {
    const parsedSettings = JSON.parse(settings);
    if (isArray(parsedSettings[settingKey])) {
      return parsedSettings[settingKey].reduce(
        (result, setting) => ({
          ...result,
          [setting]: true
        }),
        {}
      );
    }
    Logger.warn(`Invalid "${settingKey}" settings`);
  } catch (error) {
    Logger.warn('Unable to parse Enabled Event Types settings:', error);
  }
  return {};
};

const parseEnabledEventsSetting = data => {
  const settings = first(data?.getCompany?.companySettings?.items)?.settings;
  const enabledEventTypes = parseEnabledEventTypes(settings, 'enableTimestampsForEventTypes');
  const enabledEventTypesForTechs = parseEnabledEventTypes(settings, 'allowTechToCreateEventTypes');
  return {
    [EventEntities.BILLABLE_EVENT.value.clientValue]: {
      enabled: !!enabledEventTypes[TimeTrackingSettings.BILLABLE_NON_VISIT],
      enabledForTech: !!enabledEventTypesForTechs[TimeTrackingSettings.BILLABLE_NON_VISIT]
    },
    [EventEntities.NON_BILLABLE_EVENT.value.clientValue]: {
      enabled: !!enabledEventTypes[TimeTrackingSettings.NON_BILLABLE],
      enabledForTech: !!enabledEventTypesForTechs[TimeTrackingSettings.NON_BILLABLE]
    }
  };
};

const parseReleaseToTech = data => {
  const releaseToTechSetting = data?.listTenantSettings?.find(
    setting => setting?.settingKey === 'visit_releaseToTechEnabled'
  );
  return (
    releaseToTechSetting?.settingValue === true || releaseToTechSetting?.settingValue === 'true'
  );
};

const parseShowDepartmentForNonBillable = data => {
  const setting = data?.listTenantSettings?.find(
    settingItem => settingItem?.settingKey === 'showDepartmentForNonBillableEvents'
  );
  return setting?.settingValue === true || setting?.settingValue === 'true';
};

const transform = data => {
  return {
    enabledEvents: parseEnabledEventsSetting(data),
    releaseToTechEnabled: parseReleaseToTech(data),
    showDepartmentForNonBillable: parseShowDepartmentForNonBillable(data)
  };
};

const useSettings = defaultSettings => {
  const { tenantId, tenantCompanyId } = getState().user;
  const sortKey = `${tenantId}_Company_${tenantCompanyId}`;

  const { data } = useExtendedQuery(SETTINGS_QUERY, {
    transform,
    defaultData: defaultSettings,
    variables: {
      partitionKey: tenantId,
      sortKey,
      filter: {
        type: { eq: COMPANY_SETTING_TYPE.PAYROLL }
      }
    }
  });
  return data;
};

export default useSettings;
