import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { useSnackbar } from 'customHooks/useSnackbar';
import { logErrorWithCallback } from 'utils';

const getCustomerById = gql`
  query getCustomerById($id: String!) {
    getCustomerById(id: $id) {
      id
      priceBookId
    }
  }
`;

const useCustomer = ({ customerId, ...options }) => {
  const snackbar = useSnackbar();

  const { data, loading, error, refetch } = useExtendedQuery(getCustomerById, {
    variables: {
      id: customerId
    },
    skip: !customerId,
    transform: result => result?.getCustomerById,
    onError: err =>
      logErrorWithCallback(err, snackbar, 'Unable to fetch Customer, please try again later'),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options
  });

  return [data || {}, loading, error, refetch];
};

export default useCustomer;
