import { isNull, orderBy } from 'lodash';

import { formatLaborLineItemsForDisplay } from 'scenes/Quotes/components/Tasks/TaskAccordian/LaborModal/LaborModal.utils';
import { ItemType, SettingConstants } from 'scenes/Quotes/constants';

import { smartFields } from '../../CKEditor.constants';
import { convertNewLines, convertToCurrencyStringCheck } from '../../CKEditor.utils';

// Total price for each task
function getTaskPricingTotal(task, materialTaskItems) {
  const materialTotal = materialTaskItems.reduce(
    (currentValue, product) =>
      currentValue + (product.quantity ? product.quantity * product.unitPrice : 0),
    0
  );
  const laborTaskItems = formatLaborLineItemsForDisplay(task?.quoteLineLabors?.items);
  const laborTotal =
    laborTaskItems?.reduce((currentValue, laborItem) => currentValue + laborItem.totalPrice, 0) ||
    0;
  return { materialTotal, laborTotal };
}

// FORMAT TASKS according to configuration settings for display in editor
function parseTasks({
  HTML,
  group,
  smartFieldInfo,
  settingsJSON,
  removeTaskSmartfield,
  styles,
  fontFamilyStyle,
  taskGroupIdx,
  groupTitle
}) {
  const tasks = orderBy(group?.tasks || smartFieldInfo.quoteLineTasks?.items, 'sortOrder', 'asc')
    .map((task, idx) => {
      const materialTaskItems = task?.products?.items || task?.quoteLineProducts?.items || [];
      const laborTaskItems = task.quoteLineLabors.items;
      const { materialTotal, laborTotal } = getTaskPricingTotal(task, materialTaskItems);
      const taskName = settingsJSON.Tasks[SettingConstants.SHOW_TASK_TITLE]
        ? `${taskGroupIdx || idx + 1}. ${task.name ? task.name : ''}`
        : '';
      const taskTotal = !isNull(task.unitPrice)
        ? convertToCurrencyStringCheck(task.unitPrice)
        : convertToCurrencyStringCheck(materialTotal + laborTotal);
      const taskPricing = settingsJSON.Tasks[SettingConstants.SHOW_TASK_PRICING] ? taskTotal : '';
      const taskTitle = HTML.taskTitle(
        settingsJSON,
        SettingConstants,
        taskName,
        taskPricing,
        styles,
        fontFamilyStyle
      );
      const descriptionWithLineBreaks = convertNewLines(task.description);
      const taskDescription = HTML.taskDescription(
        settingsJSON,
        SettingConstants,
        descriptionWithLineBreaks,
        styles,
        fontFamilyStyle
      );
      const taskAssetHeader = HTML.taskAssetHeader(
        settingsJSON,
        SettingConstants,
        task,
        styles,
        fontFamilyStyle
      );

      const materialToggles = [
        SettingConstants.SHOW_ITEMIZED_MATERIAL_ITEMS,
        SettingConstants.SHOW_ITEMIZED_MATERIAL_ITEM_PRICING,
        SettingConstants.SHOW_ITEMIZED_MATERIAL_QUANTITY
      ];

      const taskMaterialsHeader = HTML.taskMaterialsHeader(
        settingsJSON,
        SettingConstants,
        materialToggles,
        styles,
        fontFamilyStyle
      );

      const laborToggles = [
        SettingConstants.SHOW_ITEMIZED_LABOR,
        SettingConstants.SHOW_ITEMIZED_LABOR_PRICING,
        SettingConstants.SHOW_ITEMIZED_LABOR_QUANTITY
      ];

      const taskLaborHeader = HTML.taskLaborHeader(
        settingsJSON,
        SettingConstants,
        laborToggles,
        styles,
        fontFamilyStyle
      );

      const taskItemsForDisplay = `${groupTitle || ''}
        ${taskTitle}
        ${taskDescription}
        ${taskAssetHeader}
        ${taskMaterialsHeader}
        ${HTML.taskMaterialsItems({
          HTML,
          SettingConstants,
          settingsJSON,
          taskMaterialsHeader,
          materialTaskItems,
          styles,
          fontFamilyStyle
        })}
        ${HTML.itemizedTotal({
          taskItems: materialTaskItems,
          displayTotal: settingsJSON.Tasks.Material[SettingConstants.SHOW_MATERIAL_TOTAL],
          type: ItemType.MATERIAL,
          styles,
          fontFamilyStyle
        })}
        ${taskLaborHeader}
        ${HTML.taskLaborItems({
          HTML,
          SettingConstants,
          settingsJSON,
          taskLaborHeader,
          laborTaskItems,
          styles,
          fontFamilyStyle
        })}
        ${HTML.itemizedTotal({
          taskItems: laborTaskItems,
          displayTotal: settingsJSON.Tasks.Time[SettingConstants.SHOW_LABOR_TOTAL],
          type: ItemType.LABOR,
          styles,
          fontFamilyStyle
        })}
          <tr>
            <td colspan="5" style="border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;"></td>
          </tr>
        `;

      return group
        ? taskItemsForDisplay
        : `<figure><table data-smartfield=${
            idx === 0 && !removeTaskSmartfield ? smartFields.TASKS : smartFields.REMOVE_ITEM
          } style="width:95%;padding-bottom:10px;border-color:hsl(207, 90%, 54%, 0);border-style:solid;">${taskItemsForDisplay}</table></figure>`;
    })
    .join('');

  if (
    !smartFieldInfo?.quoteLineTasks?.items.length ||
    !tasks ||
    (!Object.values(settingsJSON.Tasks).some(value => value === true) &&
      !Object.values(settingsJSON.Tasks.Material).some(value => value === true) &&
      !Object.values(settingsJSON.Tasks.Time).some(value => value === true))
  ) {
    return `<meta data-smartfield=${
      removeTaskSmartfield ? smartFields.REMOVE_ITEM : smartFields.TASKS
    }>&nbsp;`;
  }

  return tasks;
}

export default parseTasks;
