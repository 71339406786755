import React from 'react';

import { Divider, FieldType, SgtFormComponents, Typography } from '@BuildHero/sergeant';
import { FastField } from 'formik';
import { useFlags } from 'launchdarkly-react-client-sdk';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

const typeToInput = {
  [FieldType.BOOL]: SgtFormComponents.Checkbox,
  [FieldType.TEXT]: SgtFormComponents.Input,
  [FieldType.CHIP]: SgtFormComponents.Select,
  json: props => {
    return (
      <JSONInput
        {...props}
        height={200}
        locale={locale}
        placeholder={props.field.value ? JSON.parse(props.field.value) : undefined}
        viewOnly={props.props.disabled}
        onBlur={e => !e.error && props.form.setFieldValue(props.field.name, e.json)}
      />
    );
  }
};

const Row = React.memo(({ setting, index, layout }) => {
  const flags = useFlags();

  if (
    flags?.[FeatureFlags.REQUISITION_FOR_JOB_VISITS] === false &&
    setting.displayName === 'Field Orders from Mobile'
  ) {
    return <></>;
  }

  return (
    <>
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <Typography css={{ marginBottom: 4 }}>{setting.displayName}</Typography>
        <FastField
          component={typeToInput[setting.type]}
          name={`tenantSettings.${index}.settingValue`}
          props={{
            ...setting.props,
            style: { maxWidth: 250 },
            disabled: layout !== 'edit'
          }}
        />
      </div>
      <Divider />
    </>
  );
});

const TenantSettingList = ({ form, name, props }) => {
  return (
    <div>
      {form.values.tenantSettings.map((setting, index) => (
        <Row
          index={index}
          key={`${form.values.id}-${setting.settingKey}`}
          layout={props.layout}
          setting={setting}
        />
      ))}
    </div>
  );
};

export default TenantSettingList;
