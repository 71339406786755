import { Auth } from 'aws-amplify';
import axios from 'axios';

import ENV from 'configs/env';

import configForEnvironment from './aws-exports';

export function createAxiosInstance(baseURL, options = {}) {
  const instance = axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });

  // https://docs.amplify.aws/lib/auth/manageusers/q/platform/js#retrieve-current-session
  // Auth.currentSession will refresh the session if needed
  const getUserAttributes = async () => {
    const session = await Auth.currentSession();
    if (!session?.idToken?.payload) throw new Error('Invalid user session attributes!');
    return session.idToken.payload;
  };

  const getJwtToken = async () => {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
  };

  const postInterceptor = async config => {
    const attributes = await getUserAttributes();
    const userName = `${attributes?.family_name}, ${attributes?.given_name}`;
    if (config.data) {
      return {
        ...config,
        data: { ...config.data, createdBy: userName, lastUpdatedBy: userName }
      };
    }
  };

  const putInterceptor = async config => {
    const attributes = await getUserAttributes();
    const userName = `${attributes?.family_name}, ${attributes?.given_name}`;
    if (config.data) {
      return { ...config, data: { ...config.data, lastUpdatedBy: userName } };
    }
  };

  instance.interceptors.request.use(async config => {
    let localConfig = config;

    if (!options.skipInterceptors && config.method?.toLowerCase() === 'post') {
      localConfig = await postInterceptor(localConfig);
    }

    if (!options.skipInterceptors && config.method?.toLowerCase() === 'put') {
      localConfig = await putInterceptor(localConfig);
    }

    localConfig.headers.Authorization = await getJwtToken();

    return { ...localConfig };
  });

  return instance;
}

export const axiosInstancePMAPI = createAxiosInstance(configForEnvironment(ENV).pmapi);

export const axiosInstanceRestAPI = createAxiosInstance(configForEnvironment(ENV).restapi);
