import React, { useEffect, useMemo, useState } from 'react';

import { CurrencyInput, PercentageInput, Typography } from '@BuildHero/sergeant';
import { Chip } from '@material-ui/core';
import { some } from 'lodash';
import { array, bool, func, object } from 'prop-types';

import { JobBillingStatus } from 'utils/AppConstants';
import { InvoiceStatus } from 'utils/constants';
import getJobIdentifier from 'utils/getJobIdentifier';

import { useCreateInvoiceFromJobQuotes } from '../../hooks/useCreateInvoiceFromJobQuotes';
import useDepartmentValue from '../../hooks/useDepartmentValue';
import CreateInvoiceModal from '../CreateInvoiceModal';
import DepartmentSelect from '../DepartmentSelect';
import InvoiceAmountFooter from '../InvoiceAmountFooter';
import JobLabel from '../JobLabel';

import { useStyles } from '../QuotedAmountJobCreateInvoiceModal/QuotedAmountJobCreateInvoiceModal.styles';

import EmptyQuoteListMessage from './components/EmptyQuoteListMessage';
import MultipleQuoteMessage from './components/MultipleQuoteMessage';
import QuoteItem from './components/QuoteItem';
import QuotesListHeader from './components/QuotesListHeader';
import { useQuoteItems } from './QuotedJobCreateInvoiceModal.hooks';

const QuotedJobCreateInvoiceModal = ({ jobData, quotes, open, onClose, onSuccess }) => {
  const quoteItems = useQuoteItems(quotes);
  const [selectedQuotes, setSelectedQuotes] = useState({});
  const [partialBilling, setPartialBilling] = useState(false);
  const [canSubmitInvoiceAmount, setCanSubmitInvoiceAmount] = useState(true);
  const itemsAlreadyInvoiced = useMemo(
    () =>
      jobData?.invoices?.items?.flatMap(({ status, invoiceItems: { items } }) =>
        items?.map(item => status !== InvoiceStatus.VOID && item)
      ),
    [jobData]
  );
  const amountRemainingToBeInvoiced = useMemo(
    () =>
      quoteItems?.reduce((amount, quote) => {
        if (selectedQuotes[quote.id]) {
          const amountAlreadyInvoiced = itemsAlreadyInvoiced.reduce((total, item) => {
            if (item.quoteId === quote.id) {
              return total + item.amount;
            }
            return total;
          }, 0);
          return amount + quote.subTotal - amountAlreadyInvoiced;
        }
        return amount;
      }, 0) || 0,
    [quoteItems, selectedQuotes, itemsAlreadyInvoiced]
  );
  const [partialBillingAmount, setPartialBillingAmount] = useState(amountRemainingToBeInvoiced);
  const [loading, setLoading] = useState(false);
  const [departmentValue, setDepartmentValue] = useDepartmentValue(jobData);
  const [createInvoice] = useCreateInvoiceFromJobQuotes();
  const jobIdentifier = getJobIdentifier(jobData);
  const isJobFullyInvoiced = jobData.billingStatus === JobBillingStatus.FULLY_INVOICED;

  const styles = useStyles();
  const canCreateInvoice =
    some(Object.values(selectedQuotes), isSelected => isSelected) &&
    Boolean(departmentValue) &&
    canSubmitInvoiceAmount;

  useEffect(() => {
    const quotesToSelect = quoteItems?.reduce(
      (selectQuotes, quote) => ({
        ...selectQuotes,
        [quote.id]: true
      }),
      {}
    );
    setSelectedQuotes(quotesToSelect);
  }, [quoteItems]);

  useEffect(() => {
    if (quoteItems.length === 1) {
      setSelectedQuotes({
        [quoteItems[0].id]: true
      });
    }
  }, [quoteItems]);

  useEffect(() => {
    setPartialBillingAmount(amountRemainingToBeInvoiced);
  }, [selectedQuotes]);

  useEffect(() => {
    if (!partialBilling) {
      setCanSubmitInvoiceAmount(true);
    }
  }, [partialBilling]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const selectedQuoteIds = quoteItems
        .filter(quote => selectedQuotes[quote?.id])
        .map(quote => quote.id);
      const response = await createInvoice({
        jobId: jobData?.id,
        departmentId: departmentValue?.value,
        quoteIds: selectedQuoteIds,
        amountRemainingToBeInvoiced,
        ...(partialBilling && {
          percentage: partialBillingAmount / amountRemainingToBeInvoiced || 0
        })
      });
      await onSuccess();
      return response?.data?.createInvoiceFromJobQuotes;
    } finally {
      setLoading(false);
    }
  };

  const handleQuoteSelectChange = quoteId =>
    setSelectedQuotes(prevSelectedQuotes => ({
      ...prevSelectedQuotes,
      [quoteId]: !prevSelectedQuotes[quoteId]
    }));

  const renderBody = () => {
    if (isJobFullyInvoiced) {
      return (
        <>
          <JobLabel jobIdentifier={jobIdentifier} />
          <div css={styles.container}>
            <Typography>All quoted jobs were invoiced.</Typography>
          </div>
        </>
      );
    }
    return (
      <>
        <JobLabel jobIdentifier={jobIdentifier} />
        <div css={styles.partialBillingContainer}>
          <Chip
            css={partialBilling ? styles.partialBillingChip : styles.selectedPartialBillingChip}
            label="Entire Job"
            onClick={() => setPartialBilling(false)}
          />
          <Chip
            css={partialBilling ? styles.selectedPartialBillingChip : styles.partialBillingChip}
            label="Partial Billing"
            onClick={() => setPartialBilling(true)}
          />
        </div>
        {quoteItems.length === 0 && <EmptyQuoteListMessage />}
        {quoteItems.length === 1 && (
          <>
            <QuotesListHeader />
            <QuoteItem checked hideCheckbox invoices={itemsAlreadyInvoiced} quote={quoteItems[0]} />
          </>
        )}
        {quoteItems.length > 1 && (
          <>
            <MultipleQuoteMessage />
            <QuotesListHeader />
            {quoteItems.map(quote => (
              <QuoteItem
                checked={Boolean(selectedQuotes[quote.id])}
                hideCheckbox={!partialBilling}
                invoices={itemsAlreadyInvoiced}
                quote={quote}
                onChange={handleQuoteSelectChange}
              />
            ))}
            {!partialBilling && <InvoiceAmountFooter invoiceAmount={amountRemainingToBeInvoiced} />}
          </>
        )}
        {partialBilling && quoteItems.length > 0 && (
          <div css={styles.partialBillingInputContainer}>
            <div css={styles.partialBillingInput}>
              <CurrencyInput
                disabled={loading}
                label="Invoice Amount"
                subtext={
                  !canSubmitInvoiceAmount &&
                  'Invoice amount cannot be greater than quote subtotal or less than $0'
                }
                value={partialBillingAmount}
                warning={!canSubmitInvoiceAmount}
                onBlur={val => {
                  setCanSubmitInvoiceAmount(val >= 0 && val <= amountRemainingToBeInvoiced);
                  setPartialBillingAmount(val);
                }}
              />
            </div>
            <div css={styles.partialBillingInput}>
              <PercentageInput
                label="Invoice Percent"
                readOnly
                value={(partialBillingAmount / amountRemainingToBeInvoiced) * 100 || 0}
              />
            </div>
          </div>
        )}
        <DepartmentSelect
          disabled={loading}
          value={departmentValue}
          onChange={setDepartmentValue}
        />
      </>
    );
  };

  return (
    <CreateInvoiceModal
      canCreateInvoice={canCreateInvoice}
      open={open}
      renderBody={renderBody}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

QuotedJobCreateInvoiceModal.propTypes = {
  jobData: object.isRequired,
  quotes: array.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
  onSuccess: func.isRequired
};

export default QuotedJobCreateInvoiceModal;
