import React from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import { bool, func, number, object, shape } from 'prop-types';

import AccordionSection from '../../../AccordionSection';

import { useManDayEditFormConfig } from './ManDayEditForm.config';

export const validateScheduling = values => {
  const errors = {};
  if (values?.startTime >= values?.endTime) {
    errors.endTime = 'The end time of the event must be after its start';
  }
  return errors;
};

const ManDayEditForm = ({
  data,
  disabled,
  onCreateService,
  onDirtyChange,
  onComplete,
  techsResponse
}) => {
  const { loading: techsLoading, data: techsData } = techsResponse;
  const techOptions = techsData?.map(tech => ({
    value: tech.id,
    label: tech.name
  }));
  const formConfig = useManDayEditFormConfig({
    disabled,
    techsLoading,
    techOptions
  });

  const customComponents = {
    AccordionSection
  };

  if (!data) {
    return null;
  }

  return (
    <MUIForm
      configuration={formConfig}
      customComponents={customComponents}
      data={data}
      validate={validateScheduling}
      onComplete={onComplete}
      onCreateService={onCreateService}
      onDirtyChange={onDirtyChange}
    />
  );
};

ManDayEditForm.propTypes = {
  disabled: bool.isRequired,
  data: shape({
    start: number,
    end: number
  }).isRequired,
  onCreateService: func.isRequired,
  onDirtyChange: func.isRequired,
  onComplete: func.isRequired,
  techsResponse: object.isRequired
};

export default ManDayEditForm;
