import React, { useCallback, useMemo } from 'react';

import StepsStatus from 'components/FullScreenModal/StepsStatus';

import { AddAttachmentStep, EmailInfoStep } from './components';

import { StepButton } from './components/ModalHeaderButtons';

export const useGetHeaderButtons = ({
  nextStepHandlers,
  backStepHandlers,
  index,
  lastIndex,
  setCurrentStepIndex,
  nextButtonState
}) =>
  useMemo(() => {
    const handleStepButtonClick = async stepIndex => {
      let confirm = true;
      if (stepIndex > index && nextStepHandlers[index]) {
        await nextStepHandlers[index]();
      } else if (backStepHandlers[index]) {
        // If the Back action has a confirmation modal
        if (backStepHandlers[index].hasConfirm) {
          confirm = (await backStepHandlers[index]?.handler()) || false;
        } else {
          await backStepHandlers[index]();
        }
      }
      if (confirm && stepIndex !== lastIndex) {
        setCurrentStepIndex(stepIndex);
      }
    };

    switch (index) {
      case 0:
        return [
          <StepButton
            color="primary"
            disabled={nextButtonState[0].disabled}
            key="step-next"
            label="next"
            showSpinner={nextButtonState[0].loading}
            stepIndex={index + 1}
            onClick={handleStepButtonClick}
          />
        ];
      case 1:
        return [
          <StepButton
            key="step-back"
            label="back"
            marginRight
            stepIndex={index - 1}
            variant="outlined"
            onClick={handleStepButtonClick}
          />,
          <StepButton
            color="primary"
            disabled={nextButtonState[1].disabled}
            key="send-email"
            label="Send Invoice Email"
            showSpinner={nextButtonState[1].loading}
            stepIndex={index + 1}
            onClick={handleStepButtonClick}
          />
        ];
      default:
        return [];
    }
  }, [nextStepHandlers, backStepHandlers, lastIndex, index, setCurrentStepIndex, nextButtonState]);

export const useGetSteps = ({
  initialValues,
  emailData,
  setEmailData,
  customerRepsData,
  templates,
  data,
  user,
  setNextStepHandlers,
  setBackStepHandlers,
  handleButtonStateChange,
  onModalClose
}) => {
  const handleAttachmentsChange = useCallback(
    attachments => {
      setEmailData(prev => ({ ...prev, attachments }));
    },
    [setEmailData]
  );
  return useMemo(
    () => [
      {
        label: 'Review Invoice & Add Attachments',
        render: () => (
          <AddAttachmentStep
            handleAttachmentsChange={handleAttachmentsChange}
            invoiceData={data}
            setNextStepHandlers={setNextStepHandlers}
            stepIndex={0}
            onButtonStateChange={handleButtonStateChange}
          />
        )
      },
      {
        label: 'Edit Email & Send',
        render: () => (
          <EmailInfoStep
            customerRepsData={customerRepsData}
            emailData={emailData}
            handleAttachmentsChange={handleAttachmentsChange}
            initialValues={initialValues}
            setBackStepHandlers={setBackStepHandlers}
            setNextStepHandlers={setNextStepHandlers}
            stepIndex={1}
            templates={templates}
            user={user}
            onButtonStateChange={handleButtonStateChange}
            onModalClose={onModalClose}
          />
        )
      }
    ],
    [
      data,
      emailData,
      handleAttachmentsChange,
      initialValues,
      user,
      setNextStepHandlers,
      setBackStepHandlers,
      customerRepsData,
      templates,
      handleButtonStateChange,
      onModalClose
    ]
  );
};

export const useGetHeaderCenterComponent = ({ steps, currentStepIndex, setCurrentStepIndex }) =>
  useMemo(
    () => (
      <StepsStatus
        currentStepIndex={currentStepIndex}
        steps={steps.map(({ label }) => label)}
        onClickStep={index => setCurrentStepIndex(index)}
      />
    ),
    [steps, currentStepIndex, setCurrentStepIndex]
  );
