import React, { useCallback, useEffect, useRef, useState } from 'react';

import { TV, TW } from '@BuildHero/sergeant';
import { Typography } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty, pick } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { Notes, NotesV2, StatusChip, TagButtons } from 'components';
import AttachItem from 'components/AttachmentSection/AttachItem';
import LinkButtonForm from 'components/BuildHeroFormComponents/LinkButtonForm';
import LeftSidebarWithContent from 'components/Layouts/LeftSidebarWithContent';
import { useMutationSubscription } from 'customHooks/useMutationSubscription';
import usePayrollSettings from 'customHooks/usePayrollSettings';
import { useSnackbar } from 'customHooks/useSnackbar';
import { CustomerRepLayout } from 'meta/Customer/CustomerRep/layout';
import Labels from 'meta/labels';
import CustomerRep, { RepType } from 'scenes/Customer/CustomerRepModal';
import useUpdateJob from 'scenes/JobCloseout/JobCloseoutHeader/hooks/useUpdateJob';
import { generateProcurementStatusTooltip, getProcurementStatus } from 'scenes/JobCloseout/utils';
import {
  combineDataForJobUpdate,
  formatExistingDataForJobUpdate,
  formatExistingDepartmentsForUpdate,
  getPropertyRepresentative
} from 'scenes/Jobs/DetailView/formattingUtils';
import { CommonService, CustomerPropertyService, JobService, validations } from 'services/core';
import { Logger, sentryMessage } from 'services/Logger';
import theme from 'themes/BuildHeroTheme';
import { checkPermission, isTenantSettingEnabled } from 'utils';
import {
  AppConstants,
  JOB_CLOSEOUT_STATUS,
  JobProcurementStatus,
  JobStatus,
  MaintenanceStatus,
  PermissionConstants,
  TagType,
  VisitStatus
} from 'utils/AppConstants';
import { CustomFieldTypes, EntityType, EnumType } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { StatusButtons } from '../../JobCloseout/JobCloseoutHeader/StatusButtons';
import MaintenanceTabs from '../../Jobs/DetailView/JobTabs';
import { MAINTENANCE_ACTION_BUTTONS, SUBMIT_BUTTON_LABELS } from '../constant';

import LinkFieldWithLabel from './common/LinkFieldWithLabel';
import ActivityList from './CustomComponents/ActivityList';
import LocationView from './CustomComponents/LocationView';
import {
  formatMaintenanceDataForSidebarForm,
  formatServiceAgreementDetails,
  getHeaderProps,
  getStatusBasedAction
} from './helpers';
import { mainSectionViewMode, sidebarSection } from './layout';

const DetailView = props => {
  const { computedMatch } = props;
  const [maintenanceData, setMaintenanceData] = useState({});
  const [mode, setMode] = useState('view');
  const [updateJob] = useUpdateJob();
  const isUmnounted = useRef(false);
  const customerProperty = useRef('');
  const customerPropertyId = useRef('');
  const customerPropertyName = useRef('');
  const jobVisits = useRef({});
  const [visits, setVisits] = useState([]);
  const [newRep, setNewRep] = useState({});
  const [customerRepModal, setCustomerRepModal] = useState(false);
  const [configurationOptions, setConfigurationOptions] = useState({});
  const flags = useFlags();
  const user = useSelector(state => state.user);
  const jobTypes = useSelector(state =>
    state.company?.jobTypes?.items?.filter(
      type => type.tagType === CustomFieldTypes.MaintenanceTypes
    )
  );
  const snackbar = useSnackbar();

  const [payrollSettings] = usePayrollSettings();
  const companyTimeZone = payrollSettings?.timeZone;

  const showJobProcurementStatus = flags[FeatureFlags.JOB_PROCUREMENT_STATUS];

  const { id: maintenanceNumberEncoded } = computedMatch?.params || {};
  const maintenanceNumber = decodeURIComponent(maintenanceNumberEncoded);

  const statusBackgroundColor = {
    [EnumType.MAINTENANCE_STATUS]: {
      [MaintenanceStatus.OVERDUE]: theme.palette.other.statusRed,
      [MaintenanceStatus.SCHEDULED]: theme.palette.other.statusGreen,
      [MaintenanceStatus.UNSCHEDULED]: theme.palette.other.statusBlue,
      [MaintenanceStatus.SKIPPED]: theme.palette.other.statusOrange,
      [MaintenanceStatus.COMPLETED]: theme.palette.grayscale(80),
      [JobStatus.COMPLETE]: theme.palette.grayscale(60)
    }
  };

  const showTotalBudgetedHours = isTenantSettingEnabled('budgetLaborHoursAtJobLevel');

  const handlePropertyNote = useCallback(
    async noteObj => {
      const commonServiceObj = new CommonService();
      const customerPropertyServiceObj = new CustomerPropertyService();
      const payload = {
        customerPropertyId: customerPropertyId.current,
        notes: [
          {
            note: noteObj.note,
            subject: noteObj.subject
          }
        ]
      };
      if (noteObj?.id) {
        return commonServiceObj.updateNote(user.tenantId, noteObj);
      }

      return customerPropertyServiceObj.addNotesToCustomerProperty(user.tenantId, payload);
    },
    [user.tenantId]
  );

  function getSidebarFormOptions(data) {
    const propertyRepDropdown = (data?.customerProperty?.customerReps?.items || []).map(rep => ({
      label: rep.mappedEntity?.name,
      value: rep.mappedEntity?.id
    }));
    return {
      propertyRepList: propertyRepDropdown
    };
  }

  const fetchMaintenanceData = useCallback(async (number = false, callback) => {
    try {
      let { data } = await new JobService().getJobDetailsInfoByJobNumber(`${number}`);

      if (!data?.getJobByJobNumber?.priceBookId) {
        sentryMessage('Maintenance missing pricebook', { maintenance: data?.getJobByJobNumber });
      }

      data = data?.getJobByJobNumber;
      if (!isUmnounted.current && data) {
        customerProperty.current = data?.customerProperty;
        customerPropertyId.current = data?.customerProperty?.id;
        customerPropertyName.current = data?.customerPropertyName;
        setMaintenanceData(data);
        const initialOptions = getSidebarFormOptions(data);
        setConfigurationOptions(initialOptions);
      }
      if (callback) callback(data);

      return () => {
        isUmnounted.current = true;
      };
    } catch (error) {
      Logger.error(error);
    }
  }, []);

  useEffect(() => {
    if (!user?.tenantId) return;
    fetchMaintenanceData(maintenanceNumber);
  }, [fetchMaintenanceData, user.tenantId, maintenanceNumber]);

  const MaintenanceTagButtons = () => (
    <TagButtons
      getService={() => new JobService()}
      info={{
        id: maintenanceData?.id,
        version: maintenanceData?.version,
        customerPropertyId: maintenanceData?.customerPropertyId,
        departments: formatExistingDepartmentsForUpdate(maintenanceData?.departments?.items)
      }}
      refetch={() => fetchMaintenanceData(maintenanceNumber)}
      tags={maintenanceData?.jobJobTags?.items || []}
      TagType={TagType.JOB}
    />
  );

  const AddNotesView = useCallback(
    formData => {
      return (
        <Notes
          allNotesFor={customerPropertyName.current || ''}
          linkName={Labels.viewEditAllPropertyNotes[user.locale]}
          mutateService={handlePropertyNote}
          notesData={Array.isArray(formData?.displayValue) ? formData.displayValue : []}
          parent={maintenanceData?.customerProperty}
          refetch={async () => fetchMaintenanceData(maintenanceNumber)}
          subtitle={Labels.notesOnProperty[user.locale]}
          title={Labels.propertyNotes[user.locale]}
        />
      );
    },
    [fetchMaintenanceData, handlePropertyNote, maintenanceNumber, maintenanceData, user.locale]
  );

  const handleMaintenanceNote = useCallback(
    async noteObj => {
      const jobServiceObj = new JobService();
      const commonServiceObj = new CommonService();
      const payload = {
        jobId: maintenanceData?.id,
        notes: [
          {
            note: noteObj.note,
            subject: noteObj.subject
          }
        ]
      };
      if (noteObj?.id) {
        return commonServiceObj.updateNote(user.tenantId, noteObj);
      }

      return jobServiceObj.addNotesToJob(user.tenantId, payload);
    },
    [maintenanceData, user.tenantId]
  );

  const OfficeNotes = useCallback(
    formProps => (
      <Notes
        allNotesFor={`Maintenance #${maintenanceNumber}`}
        linkName={Labels.viewAllMaintenanceNotes[user.locale]}
        mutateService={handleMaintenanceNote}
        notesData={formProps?.displayValue || []}
        parent={maintenanceData}
        refetch={async () => fetchMaintenanceData(maintenanceNumber)}
        title={Labels.jobNotes[user.locale]}
      />
    ),
    [fetchMaintenanceData, handleMaintenanceNote, maintenanceData, maintenanceNumber, user.locale]
  );

  const OfficeNotesV2 = useCallback(
    formProps => (
      <NotesV2
        allNotesFor={`Maintenance #${maintenanceNumber}`}
        linkName={Labels.viewAllMaintenanceNotesV2[user.locale]}
        mutateService={handleMaintenanceNote}
        notesData={formProps?.displayValue || []}
        parent={{
          ...maintenanceData,
          name: maintenanceData.customIdentifier || maintenanceData.jobNumber,
          displayEntityType: 'Maintenance'
        }}
        refetch={async () => fetchMaintenanceData(maintenanceNumber)}
        title={Labels.jobNotes[user.locale]}
      />
    ),
    [fetchMaintenanceData, handleMaintenanceNote, maintenanceData, maintenanceNumber, user.locale]
  );

  const handleCloseRepsPopUp = async popUpData => {
    if (!popUpData) {
      setCustomerRepModal(false);
    }
    await fetchMaintenanceData(maintenanceNumber, latestData => {
      const updatedJobData = { ...latestData };
      const { addCustomerRepToCustomer } = popUpData;
      const addedRep = addCustomerRepToCustomer.pop();
      const fieldName = newRep.field.name;
      if (fieldName === 'propertyRepresentative') {
        updatedJobData.customerRep = addedRep;
        updatedJobData.propertyRepresentative = addedRep.id;
      }
      setMaintenanceData(prevState => ({
        ...prevState,
        customerRep: updatedJobData.customerRep,
        propertyRepresentative: updatedJobData.propertyRepresentative
      }));
      setCustomerRepModal(false);
    });
  };

  const leftSectionProps = {
    configuration: sidebarSection({
      ...configurationOptions,
      onCreatePropRep: newSelectedOption => {
        setNewRep(newSelectedOption);
        setCustomerRepModal(true);
      }
    }),
    data: formatMaintenanceDataForSidebarForm(maintenanceData, mode),
    customComponents: {
      LocationView,
      AddNotesView,
      LinkFieldWithLabel,
      LinkButton: LinkButtonForm
    }
  };

  const procurementStatusList = [
    {
      label: 'POs Needed',
      value: 'POs Needed'
    }
  ];

  const formatReviewStatusText = reviewStatus => {
    return reviewStatus?.replaceAll(' ', '_')?.toUpperCase();
  };

  const handleStatusChange = async () => {
    const switchingToAutoStatus = maintenanceData.procurementStatus;
    const statusObj = {
      procurementStatus: switchingToAutoStatus ? null : JobProcurementStatus.POS_NEEDED
    };
    await updateJob({
      id: maintenanceData?.id,
      version: maintenanceData?.version,
      ...statusObj
    });
    fetchMaintenanceData(maintenanceNumber);
  };

  const restoreStatusLabel = () =>
    maintenanceData.procurementStatus && (
      <Typography style={{ marginBottom: theme.spacing(0.5) }} variant={TV.S2} weight={TW.MEDIUM}>
        Restore automated status
      </Typography>
    );

  const getAdditionalStatusLabels = () => {
    // Use Manual status or get automated status
    const automatedLabel = getProcurementStatus(maintenanceData?.purchaseOrders?.items);
    const procurementStatusLabel =
      maintenanceData.procurementStatus ||
      getProcurementStatus(maintenanceData?.purchaseOrders?.items);
    return (
      <>
        {showJobProcurementStatus && (
          <StatusButtons
            enumType={EnumType.JOB_PROCUREMENT_STATUS}
            jobData={maintenanceData}
            noLabelFormat
            overrideHandleStatusChange={handleStatusChange}
            overrideOptions={[
              maintenanceData.procurementStatus ? automatedLabel : JobProcurementStatus.POS_NEEDED
            ]}
            statusCue={restoreStatusLabel()}
            tooltipContent={generateProcurementStatusTooltip(
              maintenanceData?.purchaseOrders?.items,
              theme
            )}
            value={procurementStatusLabel}
          />
        )}
        {maintenanceData.closeoutReport && maintenanceData.reviewStatus && (
          <StatusChip
            css={{ borderRadius: 2 }}
            enumType={EnumType.JOB_CLOSEOUT_STATUS}
            enumValue={JOB_CLOSEOUT_STATUS[formatReviewStatusText(maintenanceData?.reviewStatus)]}
            label={JOB_CLOSEOUT_STATUS[formatReviewStatusText(maintenanceData?.reviewStatus)]}
            showIcon
          />
        )}
      </>
    );
  };

  const data = formatServiceAgreementDetails(maintenanceData, companyTimeZone);
  const mainSectionViewModeProps = {
    configuration: mainSectionViewMode(data, {
      showTotalBudgetedHours,
      showJobProcurementStatus,
      procurementStatusList,
      jobTypes
    }),
    data,
    customComponents: {
      AttachItem,
      LinkFieldWithLabel,
      Notes: OfficeNotesV2
    }
  };

  const headerProps = getHeaderProps(
    user.locale,
    maintenanceData?.jobNumber,
    getAdditionalStatusLabels(),
    maintenanceData || {}
  );

  const handlePrimaryAction = async allFormsData => {
    const formattedExistingData = formatExistingDataForJobUpdate({
      ...maintenanceData,
      dueDate: allFormsData.dueDate,
      totalBudgetedHours: allFormsData.totalBudgetedHours,
      issueDescription: allFormsData.serviceDescription,
      jobTypeName: allFormsData.jobTypeName
    });
    const additionalPayload = getPropertyRepresentative(maintenanceData, allFormsData);
    const finalPayload = combineDataForJobUpdate(
      formattedExistingData,
      {
        procurementStatus: allFormsData.procurementStatus,
        customerProvidedPONumber: allFormsData.customerProvidedPONumber
      },
      additionalPayload
    );

    try {
      const service = new JobService();
      const response = await service.updateJobAndRelated(user.tenantId, finalPayload);
      const updatedData = response?.data?.updateJobAndRelated?.[0];
      const dueDateFormatted = updatedData.dueDate
        ? moment.unix(updatedData.dueDate).format(AppConstants.DATE_FORMAT)
        : null;

      snackbar('success', `Successfully updated Maintenance ${maintenanceNumber}.`);

      const dataToUpdateState = {
        customerProvidedPONumber: updatedData.customerProvidedPONumber,
        dueDateFormatted,
        dueDate: updatedData.dueDate,
        status: updatedData.status,
        procurementStatus: updatedData.procurementStatus
      };

      if (allFormsData.propertyRepresentative) {
        const propertyRepresentative = customerProperty.current.customerReps?.items.find(
          rep => rep?.mappedEntity?.id === allFormsData?.propertyRepresentative
        );

        const customerRep = pick(propertyRepresentative.mappedEntity, [
          'id',
          'name',
          'bestContact',
          'cellPhone',
          'landlinePhone',
          'email'
        ]);
        const { bestContact } = additionalPayload;
        setMaintenanceData(prevState => ({
          ...prevState,
          customerRep,
          bestContact,
          ...dataToUpdateState
        }));
      } else {
        setMaintenanceData(prevState => ({
          ...prevState,
          customerRep: {
            id: '',
            name: '',
            bestContact: '',
            cellPhone: '',
            landlinePhone: '',
            email: ''
          },
          bestContact: '',
          ...dataToUpdateState
        }));
      }
      setMode('view');
    } catch (error) {
      Logger.error(error);
      snackbar(
        'error',
        `Unable to update Maintenance ${maintenanceNumber}. Please try again.`,
        error
      );
    }
  };

  const finalVisitStatuses = [
    VisitStatus.COMPLETE,
    VisitStatus.CLOSED,
    VisitStatus.CONVERTED,
    VisitStatus.CANCELED
  ];

  const hasPermissionToEdit = checkPermission('edit', PermissionConstants.OBJECT_JOB, user);

  const actionButtonHandler = async action => {
    if (Object.keys(MAINTENANCE_ACTION_BUTTONS).includes(action)) {
      const isValidAction = jobVisits.current.visits?.every(visit =>
        finalVisitStatuses.includes(visit.status)
      );

      if (!isValidAction) {
        snackbar(
          'warning',
          `Cannot mark this maintenance as ${MAINTENANCE_ACTION_BUTTONS[action].label}. All visits must be cancelled, completed, or closed.`
        );
        return;
      }
    }

    try {
      const response = await new JobService().updateJobStatus(maintenanceData.id, action);
      if (response?.data?.updateJobStatus?.status) {
        snackbar(
          'success',
          `Maintenance:  ${maintenanceData.current?.jobNumber || ''} ${response?.data
            ?.updateJobStatus?.status || ''}`
        );
      }
      setMaintenanceData(prevState => ({
        ...prevState,
        status: response?.data?.updateJobStatus?.status
      }));
    } catch (error) {
      Logger.error(error);
      snackbar('error', `Unable to update status`, error);
    }
  };

  const newUpdates = useMutationSubscription(user.tenantId, maintenanceData.id, EntityType.JOB, [
    'status'
  ]);

  // To update newly updated status to state
  useEffect(() => {
    if (newUpdates && maintenanceData?.status !== newUpdates.status) {
      setMaintenanceData(prev => ({ ...prev, status: newUpdates.status }));
    }
  }, [newUpdates]);

  return (
    <LeftSidebarWithContent
      actionButtonHandler={actionButtonHandler}
      actionButtons={
        mode !== 'edit' && hasPermissionToEdit && getStatusBasedAction(maintenanceData?.status)
      }
      actionIconName="MoreHoriz"
      buttonLabel={SUBMIT_BUTTON_LABELS}
      caslKey={PermissionConstants.OBJECT_JOB}
      handleCancel={() => setMode('view')}
      handleEdit={() => setMode('edit')} // TODO: add token to theme, or update color in list view, detail view & report views to use text color theme token
      handleFormsSubmit={handlePrimaryAction}
      headerProps={headerProps}
      isLoading={!maintenanceData?.id}
      leftSectionProps={leftSectionProps}
      mainSectionProps={mainSectionViewModeProps}
      mode={mode}
      shouldDisallowEditing={maintenanceData?.status === MaintenanceStatus.COMPLETE}
      showCancelButton
      statusBackgroundColor={
        statusBackgroundColor[EnumType.MAINTENANCE_STATUS]?.[
          newUpdates?.status || maintenanceData?.status
        ]
      }
      statusLabel={newUpdates?.status || maintenanceData?.status}
      statusLabelColor="#000000ff"
      TagButtons={<MaintenanceTagButtons />}
      userLocale={user.locale}
    >
      {maintenanceData?.customerSortKey && (
        <CustomerRep
          data={{ firstName: newRep?.value }}
          handleClose={(flag, data) => {
            handleCloseRepsPopUp(data);
          }}
          layout={CustomerRepLayout}
          mode="new"
          open={customerRepModal}
          parent={{
            id: (() => {
              const temp = maintenanceData.customerSortKey.split('_');
              return temp[temp.length - 1];
            })(),
            sortKey: maintenanceData.customerSortKey,
            customerName: maintenanceData.customerName,
            tenantId: user.tenantId,
            tenantCompanyId: user.tenantCompanyId,
            hierarchy: `${user.tenantId}_${user.tenantCompanyId}`,
            entityType: 'Customer',
            partitionKey: user.tenantId,
            customerPropertyId:
              newRep?.field?.name === 'propertyRepresentative'
                ? maintenanceData.customerPropertyId
                : ''
          }}
          repType={RepType.CUSTOMER}
          validationSchema={validations.customerRepSchema}
        />
      )}
      <MaintenanceTabs
        hasLoaded={!isEmpty(maintenanceData)}
        isJobTypeInternal
        jobData={maintenanceData}
        jobsVisitRef={jobVisits}
        setVisits={setVisits}
        visits={visits}
      />
      <ActivityList maintenanceNumber={maintenanceNumber} />
    </LeftSidebarWithContent>
  );
};

export default DetailView;
