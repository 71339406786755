import compact from 'lodash/compact';
import findLastIndex from 'lodash/findLastIndex';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import moment from 'moment-timezone';

import { generateWeekDate } from 'scenes/Payroll/TimeTrackingReport/helpers';
import { sentryMessage } from 'services/Logger';
import { TimeCardStatusTypes } from 'utils/AppConstants';

import { eventTypes } from '../constants';
import { getTimesheetPeriodsPendingForEmployee } from '../services';

// UNDEFINED_HOUR_TYPE is just a placeholder,
// DayCard component will be blocked by <CorruptData /> component when its time to render.
const UNDEFINED_HOUR_TYPE = 'undefined';

export const startDayToday = timezone => {
  // we are doing unique formatting to get the startDateUTC, replicate it here
  return moment()
    .tz(timezone)
    .startOf('day')
    .utc()
    .unix();
};

export const showDivider = (index, day, weekDates) =>
  (day.workEvents.length || day.dismissedBinders.length) &&
  index !== findLastIndex(weekDates, w => w.workEvents.length || w.dismissedBinders.length);

const getScheduledTime = ({ startTime, endTime, actualDuration }) => {
  let value = 0;

  if (startTime && endTime) {
    value = (endTime - startTime) / 3600;
  } else if (actualDuration) {
    // for some reason we use actualDuration instead of populating start/end time for visits
    const minutes = actualDuration?.split(' ')[0];
    value = minutes / 60;
  }

  const hour = value !== 0 ? value.toFixed(2) : value;
  return hour > 0 ? `${Math.round(hour * 2) / 2} hrs` : '-';
};

export const getTimeString = (time, timezone = '') => {
  if (!time) return '';

  const date = timezone ? moment.tz(moment.unix(time).format(), timezone) : moment(time);
  if (time && date.isValid()) {
    return date.format('HH:mm');
  }

  return '';
};

const constructManDayIdentifier = ({
  id,
  project,
  projectPhase,
  projectPhaseDepartment,
  projectPhaseDepartmentCostCode,
  dailyReport,
  status,
  startDateTime,
  endDateTime,
  actualStartTimeUTC,
  actualEndTimeUTC,
  jobStartTime,
  jobEndTime
}) => ({
  type: eventTypes.MAN_DAY,
  id,
  projectId: project?.id,
  dailyReportId: dailyReport?.id,
  dailyReportNumber: dailyReport?.number,
  project: project?.name,
  projectNumber: project?.number,
  costCode: projectPhaseDepartmentCostCode?.name,
  phase: projectPhase?.name,
  department: projectPhaseDepartment?.tagName,
  status,
  scheduledTime: getScheduledTime({
    startTime: startDateTime || actualStartTimeUTC,
    endTime: endDateTime || actualEndTimeUTC
  }),
  jobStartTime: getTimeString(jobStartTime),
  jobEndTime: getTimeString(jobEndTime)
});

const constructNonVisitIndentifier = (nonVisitEvent = {}, timesheetEntryBinder = {}) => {
  const {
    status,
    name,
    assignedEntity,
    department,
    plannedStartTimeUTC,
    plannedEndTimeUTC
  } = nonVisitEvent;

  const departmentName = assignedEntity?.departmentName ?? department?.tagName ?? '';
  const job = assignedEntity?.job?.customIdentifier ?? assignedEntity?.job?.jobNumber ?? '';
  const visit = assignedEntity?.visitNumber ?? '';
  const property = assignedEntity?.job?.customerProperty?.companyName ?? '';
  const customer = assignedEntity?.job?.customerProperty?.customer?.customerName ?? '';
  const scheduledTime = getScheduledTime({
    startTime: plannedStartTimeUTC,
    endTime: plannedEndTimeUTC
  });

  return {
    type: eventTypes.NON_VISIT_EVENT,
    name,
    job,
    jobNumber: nonVisitEvent.assignedEntity?.job?.jobNumber,
    jobType: nonVisitEvent.assignedEntity?.job?.jobTypeInternal,
    visit,
    customer,
    property,
    status,
    department: departmentName,
    id: compact([name, job, visit, property, customer, status, departmentName]).join(' | '),
    scheduledTime,
    jobStartTime: getTimeString(timesheetEntryBinder.jobStartTime),
    jobEndTime: getTimeString(timesheetEntryBinder.jobEndTime)
  };
};

const constructVisitIndentifier = (billableEntity = {}, timesheetEntryBinder = {}) => {
  const { actualDuration, job } = billableEntity;

  const customer = job?.customerProperty?.customer?.customerName ?? '';
  const department = billableEntity.departmentName ?? '';
  const jobIdentifier = job?.customIdentifier ?? billableEntity.job?.jobNumber;
  const visit = billableEntity.visitNumber;
  const property = job?.customerProperty?.companyName ?? '';
  const { status } = billableEntity;
  const scheduledTime = getScheduledTime({ actualDuration });

  return {
    type: eventTypes.VISIT,
    job: jobIdentifier,
    jobNumber: job?.jobNumber,
    jobType: job?.jobTypeInternal,
    visit,
    customer,
    property,
    status,
    department,
    id: compact([job, visit, property, customer, status, department]).join(' | '),
    scheduledTime,
    jobStartTime: getTimeString(timesheetEntryBinder.jobStartTime),
    jobEndTime: getTimeString(timesheetEntryBinder.jobEndTime)
  };
};

const constructEventIdentifier = (event, timesheetEntryBinder) => {
  switch (event?.entityType) {
    case eventTypes.NON_VISIT_EVENT:
      return constructNonVisitIndentifier(event, timesheetEntryBinder);
    case eventTypes.VISIT:
      return constructVisitIndentifier(event, timesheetEntryBinder);
    case eventTypes.MAN_DAY:
    default: {
      // daily reports won't have an event type - treat as man day
      // without event, we need to get start and end time from timesheet entry
      const { actualStartTimeUTC, actualEndTimeUTC } = timesheetEntryBinder.timesheetEntries?.items
        ? timesheetEntryBinder.timesheetEntries?.items[0]
        : {};
      return constructManDayIdentifier({
        ...timesheetEntryBinder,
        ...event,
        actualStartTimeUTC,
        actualEndTimeUTC
      });
    }
  }
};

const buildTimekeepingLedger = ledgerItems =>
  ledgerItems
    .map(l => ({
      ...l,
      clockInTime: l.actualStartTimeUTC,
      clockOutTime: l.actualEndTimeUTC,
      labourType: l.userActionType,
      totalDuration: l.actualTotalDuration,
      gpsLocations: { items: [{ latitude: l.startLatitude, longitude: l.startLongitude }] }
    }))
    .sort((timeSheetA, timeSheetB) => timeSheetA.clockInTime - timeSheetB.clockInTime);

const constructTimestampsFromEvent = (timeSheets, event) => {
  if (timeSheets && timeSheets?.items.length) {
    return timeSheets.items
      .map(item => ({
        ...item,
        employeeName: item.createdBy
      }))
      .sort((timeSheetA, timeSheetB) => timeSheetA.clockInTime - timeSheetB.clockInTime);
  }

  // we only generate TimeSheet data for new NVE actions, old ones only have TimekeepingLedgers
  // keep this until all tenants have been migrated onto WIT for at least a month
  if (event?.entityType === eventTypes.NON_VISIT_EVENT) {
    return buildTimekeepingLedger(event.timekeepingLedgersView?.items || []);
  }

  return [];
};

const constructWorkEvent = (timesheetEntryBinder, timesheetHours) => {
  const {
    id,
    startDayCompanyTZ,
    event,
    manualStatus,
    timesheetNotes,
    eventTransitionLedger,
    auditLogs,
    timesheetEntries,
    timeSheets
  } = timesheetEntryBinder;
  const timesheetNotesForSort = timesheetNotes?.items ? [...timesheetNotes?.items] : [];
  const allAuditLogs = [
    ...auditLogs?.items,
    ...timesheetEntries?.items.reduce((acc, entry) => {
      return [...acc, ...entry?.auditLogs?.items];
    }, [])
  ];

  return {
    binderId: id,
    binderStatus: manualStatus,
    eventTransitionLedger,
    ...event,
    startDayCompanyTZ,
    timesheetHours,
    auditLogs: allAuditLogs.sort(
      (logA, logB) => parseInt(logB.executedDateTime, 10) - parseInt(logA.executedDateTime, 10)
    ),
    timesheetNotes: timesheetNotesForSort.sort(
      (noteA, noteB) => parseInt(noteB.createdDateTime, 10) - parseInt(noteA.createdDateTime, 10)
    ),
    timestamps: constructTimestampsFromEvent(timeSheets, event),
    identifier: constructEventIdentifier(event, timesheetEntryBinder),
    canceled: event?.isActive === false
  };
};

const parseTimesheetHourFromEntry = (timesheetEntry, payrollHourTypes) => {
  const { id, hourTypeId, actualTotalDuration, actualTotalDurationOverride } = timesheetEntry;
  const hourType = payrollHourTypes.find(t => t.id === hourTypeId);

  return {
    hourType: hourType?.hourTypeAbbreviation || UNDEFINED_HOUR_TYPE,
    values: {
      actualTotalDuration,
      actualTotalDurationOverride,
      hourType,
      id,
      calculatedDuration: Number.isInteger(actualTotalDurationOverride)
        ? actualTotalDurationOverride
        : actualTotalDuration || 0
    }
  };
};

export const generateWorkEventDataFromDismissedBinders = (
  timesheetEntryBinders,
  payrollHourTypes
) => {
  const dismissedBinderData = {
    totalDismissedTime: 0,
    workEvents: []
  };

  timesheetEntryBinders.forEach(binder => {
    const {
      timesheetEntries: { items: entriesOnThisDay }
    } = binder;

    const timesheetHours = {};

    entriesOnThisDay.forEach(timesheetEntry => {
      const { hourType, values } = parseTimesheetHourFromEntry(timesheetEntry, payrollHourTypes);
      timesheetHours[hourType] = values;

      dismissedBinderData.totalDismissedTime += values.calculatedDuration;
    });

    dismissedBinderData.workEvents.push(constructWorkEvent(binder, timesheetHours));
  });

  return dismissedBinderData;
};

export const generateWeekDataFromBinder = (
  timesheetEntryBinders,
  date,
  timezone,
  payrollHourTypes,
  selectedStatuses,
  entriesToUpdate = [],
  dismissedBinderMap = { dismissed: [], undismissed: [] }
) => {
  const payrollHourDailyTotals = payrollHourTypes.reduce(
    (acc, hourType) => ({
      ...acc,
      [hourType.hourTypeAbbreviation]: 0
    }),
    {}
  );
  const weekDays = generateWeekDate(date, timezone);
  const timesheetsByWeekDay = weekDays.reduce(
    (acc, day) => ({
      ...acc,
      [`${day.dayStartUTC}-${day.dayEndUTC}`]: {
        ...day,
        // create a copy of payrollHourDailyTotals so we have a separate object for each week day
        dailyTotals: { ...payrollHourDailyTotals },
        workEvents: [],
        bindersOnThisDay: [],
        validHourTypes: [],
        dismissedBinders: []
      }
    }),
    {}
  );

  timesheetEntryBinders.forEach(binder => {
    const {
      id,
      startDayCompanyTZ,
      manualStatus,
      isDismissed,
      timesheetEntries: { items: entriesOnThisDay }
    } = binder;

    if (!selectedStatuses.includes(manualStatus)) {
      return;
    }

    const weekDayKey = Object.keys(timesheetsByWeekDay).filter(key => {
      const [dayStartUTC, dayEndUTC] = key.split('-');
      const startDay = parseInt(startDayCompanyTZ, 10);
      return startDay <= parseInt(dayEndUTC, 10) && startDay >= parseInt(dayStartUTC, 10);
    })[0];

    const weekDay = timesheetsByWeekDay[weekDayKey];
    if (!weekDay) {
      sentryMessage(
        'TimesheetEntryBinder does not have a startDayCompanyTZ within assigned TimesheetPeriod',
        {
          timesheetEntryBinder: binder
        }
      );
      return;
    }

    if (
      (isDismissed && !dismissedBinderMap.undismissed.includes(id)) ||
      dismissedBinderMap.dismissed.includes(id)
    ) {
      timesheetsByWeekDay[weekDayKey] = {
        ...weekDay,
        dismissedBinders: [...weekDay.dismissedBinders, binder]
      };
      return;
    }

    const timesheetHours = {};

    entriesOnThisDay.forEach(entry => {
      let timesheetEntry = entry;

      // update entry if included in entriesToUpdate
      const updatedEntry = entriesToUpdate.find(e => e.id === entry.id);
      if (updatedEntry) {
        const { extra, ...props } = updatedEntry;
        timesheetEntry = { ...entry, ...props };
      }

      const { hourType, values } = parseTimesheetHourFromEntry(timesheetEntry, payrollHourTypes);
      timesheetHours[hourType] = values;

      weekDay.dailyTotals[hourType] += values.calculatedDuration;
      weekDay.validHourTypes = uniq([...weekDay.validHourTypes, hourType]);
    });

    weekDay.workEvents.push(constructWorkEvent(binder, timesheetHours));
    weekDay.bindersOnThisDay.push(binder);
  });

  return Object.values(timesheetsByWeekDay);
};

// Deprecate this method once wrinkle-in-time FF enabled everywhere
export const generateWeekData = (
  timesheetEntries,
  date,
  timezone,
  payrollHourTypes,
  approvalStatus,
  selectedEmployee,
  unsubmittedEvents
) =>
  generateWeekDate(date, timezone).map(day => {
    const dailyTotals = payrollHourTypes.reduce(
      (acc, hourType) => ({
        ...acc,
        [hourType.hourTypeAbbreviation]: 0
      }),
      {}
    );

    const entriesOnThisDay = timesheetEntries
      .filter(e => e.actualStartTimeUTC <= day.dayEndUTC && e.actualStartTimeUTC >= day.dayStartUTC)
      .filter(e => e.manualStatus === approvalStatus);

    // BUOP-9995 unknown root cause & not reproduceable, adding this filter to 3.17.0 to detect if it occurs.
    // Remove after root cause has been found or if it never happens again.
    const entriesWithoutManualStatus = timesheetEntries.filter(e => e.manualStatus === null);
    if (entriesWithoutManualStatus.length) {
      sentryMessage('TimesheetEntry(ies) missing manual status', {
        entries: entriesWithoutManualStatus
      });
    }

    // TODO: simplify and get rid of a lot of this logic now that we have the work event info we need
    const workEvents = entriesOnThisDay.reduce((acc, entry) => {
      const workEventIndex = acc.findIndex(v => {
        const hasWorkEventId = !!v.id;
        const nonVisitMatch = v.id === entry.nonVisitEventId;
        const visitMatch =
          v.id === entry.billableEntity?.id && v.entityType === entry.billableEntity?.entityType;
        const binderMatch = v.id === entry.timesheetEntryBinderId;
        return hasWorkEventId && (nonVisitMatch || visitMatch || binderMatch);
      });

      const {
        actualTotalDuration,
        actualTotalDurationOverride,
        actualStartTimeUTC,
        actualEndTimeUTC,
        hourTypeId,
        id,
        auditLogs
      } = entry;
      const hourType = payrollHourTypes.find(t => t.id === hourTypeId);

      if (workEventIndex !== -1) {
        const newArr = [...acc];

        const corruptEntry =
          !hourType || newArr[workEventIndex].timesheetHours?.[hourType.hourTypeAbbreviation]
            ? { entry, newArr, hourType }
            : null;

        newArr[workEventIndex] = {
          ...newArr[workEventIndex],
          timesheetHours: {
            ...newArr[workEventIndex].timesheetHours,
            [hourType?.hourTypeAbbreviation || UNDEFINED_HOUR_TYPE]: {
              actualTotalDuration,
              actualTotalDurationOverride,
              hourType,
              id
            }
          },
          auditLogs: (newArr[workEventIndex].auditLogs ?? [])
            .concat((auditLogs?.items ?? []).map(log => ({ ...log, hourType, entry })))
            .sort(
              (logA, logB) =>
                parseInt(logB.executedDateTime, 10) - parseInt(logA.executedDateTime, 10)
            ),
          corruptData: corruptEntry
            ? [...newArr[workEventIndex].corruptData, corruptEntry]
            : newArr[workEventIndex].corruptData
          // if an workEvent is already added, no need to re-add timesheetNotes or schedules prop to it.
        };
        return newArr;
      }

      let corruptData = hourType ? [] : [{ entry, hourType }];

      if (entry.timesheetEntryBinder) {
        return [
          ...acc,
          {
            id: entry.timesheetEntryBinderId,
            timesheetHours: {
              [hourType?.hourTypeAbbreviation || UNDEFINED_HOUR_TYPE]: {
                actualTotalDuration,
                actualTotalDurationOverride,
                hourType,
                id
              }
            },
            entityType: 'TimesheetEntryBinder',
            auditLogs: auditLogs.items
              .map(log => ({ ...log, hourType, entry }))
              .sort(
                (logA, logB) =>
                  parseInt(logB.executedDateTime, 10) - parseInt(logA.executedDateTime, 10)
              ),
            timesheetNotes: (entry.timesheetEntryBinder?.timesheetNotes?.items || []).sort(
              (noteA, noteB) =>
                parseInt(noteA.createdDateTime, 10) - parseInt(noteB.createdDateTime, 10)
            ),
            timestamps: [], // @TODO future enhacement
            identifier: constructManDayIdentifier({
              ...entry.timesheetEntryBinder,
              actualStartTimeUTC: entry.actualStartTimeUTC,
              actualEndTimeUTC: entry.actualEndTimeUTC
            }),
            corruptData,
            disableRequestRevision: !entry.timesheetEntryBinder.eventId, // @TODO at Dec. 1 2021 the only .eventType are ManDays. In the future when Binders are generalized to Visits and NVEs this needs to be changed to entry.timesheetEntryBinder.event?.entityType === 'ManDay'
            actualStartTimeUTC,
            actualEndTimeUTC
          }
        ];
      }

      if (entry.billableEntity) {
        return [
          ...acc,
          {
            ...entry.billableEntity,
            timesheetHours: {
              [hourType?.hourTypeAbbreviation || UNDEFINED_HOUR_TYPE]: {
                actualTotalDuration,
                actualTotalDurationOverride,
                hourType,
                id
              }
            },
            auditLogs: auditLogs.items
              .map(log => ({ ...log, hourType, entry }))
              .sort(
                (logA, logB) =>
                  parseInt(logB.executedDateTime, 10) - parseInt(logA.executedDateTime, 10)
              ),
            timesheetNotes: (entry.billableEntity?.timesheetNotes?.items || [])
              .filter(note => note.employeeId === selectedEmployee.id)
              .sort((noteA, noteB) => noteA.createdDateTime - noteB.createdDateTime),
            timestamps: (entry.billableEntity?.schedules?.items || [])
              .filter(s => s.employee?.id === selectedEmployee.id)
              .reduce((timeSheets, s) => {
                return timeSheets.concat(
                  s.timeSheets.items.map(item => ({ ...item, employeeName: s.createdBy }))
                );
              }, [])
              .sort((timeSheetA, timeSheetB) => timeSheetA.clockInTime - timeSheetB.clockInTime),
            identifier: constructVisitIndentifier(entry.billableEntity),
            canceled: entry.billableEntity.status === 'Canceled',
            corruptData
          }
        ];
      }

      if (entry.nonVisitEvent) {
        return [
          ...acc,
          {
            ...entry.nonVisitEvent,
            timesheetHours: {
              [hourType?.hourTypeAbbreviation || UNDEFINED_HOUR_TYPE]: {
                actualTotalDuration,
                actualTotalDurationOverride,
                hourType,
                id
              }
            },
            auditLogs: auditLogs.items
              .map(log => ({ ...log, hourType, entry }))
              .sort(
                (logA, logB) =>
                  parseInt(logB.executedDateTime, 10) - parseInt(logA.executedDateTime, 10)
              ),
            timesheetNotes: (entry.nonVisitEvent?.timesheetNotes?.items || [])
              .filter(note => note.employeeId === selectedEmployee.id)
              .sort((noteA, noteB) => noteA.createdDateTime - noteB.createdDateTime),
            timestamps: (entry.nonVisitEvent?.timekeepingLedgersView?.items || [])
              .filter(ledger => ledger.employeeId === selectedEmployee.id)
              .map(l => ({
                ...l,
                clockInTime: l.actualStartTimeUTC,
                clockOutTime: l.actualEndTimeUTC,
                labourType: l.userActionType,
                totalDuration: l.actualTotalDuration
              }))
              .sort((timeSheetA, timeSheetB) => timeSheetA.clockInTime - timeSheetB.clockInTime),
            identifier: constructNonVisitIndentifier(entry.nonVisitEvent),
            canceled: entry.nonVisitEvent?.isActive === false,
            corruptData
          }
        ];
      }

      corruptData = [
        {
          entry,
          hourType,
          reason: 'TimesheetEntry missing billableEntity and nonVisitEvent and timesheetEntryBinder'
        }
      ];

      return [
        ...acc,
        {
          corruptData
        }
      ];
    }, []);

    const validHourTypes = [];
    entriesOnThisDay.forEach(e => {
      const { hourTypeId, actualTotalDuration, actualTotalDurationOverride } = e;

      const hourType = payrollHourTypes.find(t => t.id === hourTypeId);

      dailyTotals[hourType?.hourTypeAbbreviation || UNDEFINED_HOUR_TYPE] += Number.isInteger(
        actualTotalDurationOverride
      )
        ? actualTotalDurationOverride
        : actualTotalDuration;

      validHourTypes.push(hourType?.hourTypeAbbreviation || UNDEFINED_HOUR_TYPE);
    });

    return {
      ...day,
      entriesOnThisDay,
      workEvents,
      dailyTotals,
      validHourTypes: uniq(validHourTypes),
      dailyUnsubmittedEvents: unsubmittedEvents
        .filter(
          e => day.dayStartUTC < e.plannedStartTimeUTC && e.plannedStartTimeUTC < day.dayEndUTC
        )
        .map(e => {
          if (e.isVisit) return { ...e, identifier: constructVisitIndentifier(e) };
          if (e.isNonVisitEvent) return { ...e, identifier: constructNonVisitIndentifier(e) };
          if (e.isProjectVisit) return { ...e, identifier: constructManDayIdentifier(e) };
          return e;
        })
    };
  });

export const getPendingDates = async ({
  employee,
  snackbarOn,
  payrollSetting,
  payrollHourTypes,
  unsubmittedEvents
}) => {
  const periods = await getTimesheetPeriodsPendingForEmployee({
    employee,
    snackbarOn
  });
  const weekDatesData = flatten(
    periods.map(period => {
      const {
        timesheetEntriesView: { items: manualTimesheetEntries }
      } = period;

      return generateWeekData(
        manualTimesheetEntries,
        period,
        payrollSetting.timeZone,
        payrollHourTypes,
        TimeCardStatusTypes.DISPUTED,
        employee,
        unsubmittedEvents
      );
    })
  ).filter(d => d.workEvents.length);

  return weekDatesData;
};
