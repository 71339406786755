import { makeStyles } from '@material-ui/core';

import { DispatchTheme, ElementSizes, ElementZIndex } from '@dispatch/Dispatch.styles';

export const useStyles = makeStyles(theme => ({
  weekViewHeader: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    borderBottom: DispatchTheme.mixins.mainBorder,
    background: theme.palette.background.default,
    position: 'sticky',
    zIndex: ElementZIndex.stickyHeader,
    top: -1,
    paddingLeft: ElementSizes.techCellWidth
  },
  dayHeader: {
    height: ElementSizes.stickyHeaderHeight,
    maxHeight: ElementSizes.stickyHeaderHeight,
    width: `calc((100vh - ${ElementSizes.techCellWidth}px) / 7)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
