import gql from 'graphql-tag';

const getCompanyInfo = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      sameAddress
      companyAddresses(entityConnection: "CompanyAddress") {
        items {
          addressType
          addressLine1
          addressLine2
          city
          state
          zipcode
          id
          tenantId
          partitionKey
          sortKey
          hierarchy
          entityType
          latitude
          longitude
        }
      }
      logoUrl
      invoiceLogoUrl
      companyName
      email
      fax
      phonePrimary
      websiteUrl
      status
      termsOfService
      purchaseOrderTermsAndConditions
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      version
      skills(entityConnection: "Skill") {
        items {
          id
          tagName
          sortKey
          tenantId
          departments(entityConnection: "Department") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              invertedSortKey
              hierarchy
              entityType
              mappedEntity {
                ... on Department {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  tagName
                  tagType
                }
              }
            }
          }
          contacts(entityConnection: "Employee") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              invertedSortKey
              hierarchy
              entityType
              mappedEntity {
                ... on Employee {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  name
                  firstName
                  lastName
                  nickName
                  profilePictureUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getCompanyInfo;
