import React, { useEffect, useState } from 'react';

import { Divider, ThemeProvider } from '@BuildHero/sergeant';
import findLastIndex from 'lodash/findLastIndex';

import { Spinner } from 'components';

import { DayCard } from './components/DayCard';
import { getPendingDates } from './helpers';

const PendingRevision = ({
  selectedEmployee,
  payrollHourTypes,
  timesheetPeriods = [],
  payrollSetting,
  snackbarOn,
  unsubmittedEvents,
  setNumDaycardsPending
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [weekDates, setWeekDates] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const pendingDates = await getPendingDates({
        employee: selectedEmployee,
        snackbarOn,
        payrollSetting,
        payrollHourTypes,
        unsubmittedEvents
      });

      setWeekDates(pendingDates);
      setNumDaycardsPending(pendingDates.length);
      setIsLoading(false);
    })();
  }, [timesheetPeriods]);

  return (
    <div>
      <ThemeProvider>
        {weekDates.map((day, i) => (
          <>
            <DayCard
              day={day}
              key={day.dayStartUTC}
              payrollHourTypes={payrollHourTypes}
              payrollSetting={payrollSetting}
            />
            {day.workEvents.length !== 0 &&
              i !== findLastIndex(weekDates, w => w.workEvents.length !== 0) && <Divider />}
          </>
        ))}
      </ThemeProvider>
      {weekDates.length === 0 && !isLoading && <div style={{ height: 300 }} />}
      {isLoading && <Spinner />}
    </div>
  );
};

export default PendingRevision;
