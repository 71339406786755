import React from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import PropTypes from 'prop-types';

import LaborTimeField from '../LaborTimeField/LaborTimeField.component';
import LaborTimeRemainingField from '../LaborTimeRemainingField';
import LunchTimeValidationField from '../LunchTimeValidationField';

import { useLaborProductivityFormConfig } from './LaborProductivityForm.config';

const LaborProductivityForm = ({ employees, laborData, onLaborProductivityChange }) => {
  const configuration = useLaborProductivityFormConfig({ employees });
  return (
    <div>
      <MUIForm
        configuration={configuration}
        customComponents={{
          LaborTimeField,
          LaborTimeRemainingField,
          LunchTimeValidationField
        }}
        data={laborData}
        onFormChange={onLaborProductivityChange}
      />
    </div>
  );
};

LaborProductivityForm.propTypes = {
  employees: PropTypes.array.isRequired,
  laborData: PropTypes.object.isRequired,
  onLaborProductivityChange: PropTypes.func.isRequired
};

export default LaborProductivityForm;
