import gql from 'graphql-tag';
import { pick } from 'lodash';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { logErrorWithCallback } from 'utils';

const BULK_UPDATE_LABOUR_RATE_GROUP_ENTRIES = gql`
  mutation bulkUpdateLabourRateGroupEntries($data: UpdateLabourRateGroupEntriesInput!) {
    bulkUpdateLabourRateGroupEntries(data: $data) {
      id
      rate
      payrollHourTypeId
      payrollHourType {
        id
        hourType
        hourTypeAbbreviation
        sortOrder
      }
      labourTypeId
      labourType {
        id
        name
        jobCostTypeId
        revenueTypeId
        costCodeId
      }
      costCodeId
      costCode {
        name
        id
      }
      jobCostTypeId
      jobCostType {
        name
        id
        type
      }
    }
  }
`;

const serializer = ({ labourRateGroupEntries }) => ({
  variables: {
    data: {
      labourRateGroupEntries: labourRateGroupEntries.map(entry =>
        pick(entry, [
          'id',
          'payrollHourTypeId',
          'rate',
          'labourTypeId',
          'costCodeId',
          'jobCostTypeId'
        ])
      )
    }
  }
});

export const useBulkUpdateLabourRateGroupEntries = () => {
  return useExtendedMutation(BULK_UPDATE_LABOUR_RATE_GROUP_ENTRIES, {
    serializer,
    onSuccess: (data, snackbarOn) =>
      snackbarOn('success', 'Successfully updated labor rate group entries'),
    onError: (err, snackbarOn) =>
      logErrorWithCallback(err, snackbarOn, 'Unable to update labor rate group entries')
  });
};
