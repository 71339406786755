import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { sortBy } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';

import { ImageUpload, SergeantModal } from 'components';
import { PropertyAssetLayout } from 'meta/Customer/PropertyAsset';
import LinkFieldWithLabel from 'scenes/Maintenance/DetailView/common/LinkFieldWithLabel';
import { checkPermission } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

export const defaultData = {
  imageUrl: '',
  assetName: '',
  make: '',
  modelNumber: '',
  serialNo: '',
  installDate: moment().unix(),
  location: '',
  propertyZone: ''
};

const AssetModal = ({
  open,
  data = {},
  mode,
  confirmRemoveItemLabel = '',
  user,
  ocrButton = () => null,
  handlePrimaryAction,
  handleClose,
  company,
  hideServiceAgreements = false
}) => {
  const launchDarklyFlags = useFlags();

  const LogoButton = ({ form, field }) => (
    <ImageUpload field={field} form={form} label="Add Image" user={user} />
  );

  const assetTypeOptions = constructSelectOptions(
    sortBy(company?.assetTypes?.items, 'sortOrder'),
    'tagName'
  );

  const hasServiceAgreements = checkPermission(
    'allow',
    PermissionConstants.OBJECT_SERVICE_AGREEMENT,
    user,
    null,
    null,
    FeatureFlags.SERVICE_AGREEMENTS,
    launchDarklyFlags
  );

  return (
    <SergeantModal
      confirmRemoveItemLabel={confirmRemoveItemLabel}
      customComponents={{
        LogoButton,
        OcrButton: ocrButton,
        LinkFieldWithLabel
      }}
      data={{ ...defaultData, ...data }}
      dataType="Asset"
      handleClose={handleClose}
      handlePrimaryAction={handlePrimaryAction}
      layout={PropertyAssetLayout(
        assetTypeOptions,
        true,
        hasServiceAgreements && !hideServiceAgreements
      )}
      mode={mode}
      open={open}
    />
  );
};

const mapStateToProps = state => ({
  user: state.user,
  company: state.company
});

export default connect(mapStateToProps)(AssetModal);
