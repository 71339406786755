import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

export const AddCompanyDispatchSettings = gql`
  mutation($partitionKey: String!, $companyId: String!, $settings: String!) {
    addCompanySettingsToCompany(
      partitionKey: $partitionKey
      data: {
        companyId: $companyId
        companySettings: { type: DISPATCH, settings: $settings, isActive: true }
      }
    ) {
      id
      version
      settings
    }
  }
`;

export const UpdateCompanyDispatchSettings = gql`
  mutation($partitionKey: String!, $settings: String!, $id: String!, $version: Int!) {
    updateCompanySetting(
      partitionKey: $partitionKey
      data: { type: DISPATCH, settings: $settings, isActive: true, id: $id, version: $version }
    ) {
      id
      version
      settings
    }
  }
`;

export const useSaveCompanyDispatchSettings = ({
  tenantId,
  tenantCompanyId,
  isUpdate,
  setIsDirty
}) => {
  return useExtendedMutation(
    isUpdate ? UpdateCompanyDispatchSettings : AddCompanyDispatchSettings,
    {
      serializer: ({ formData, prevSettingData }) => ({
        variables: prevSettingData
          ? {
              id: prevSettingData.id,
              version: prevSettingData.version,
              partitionKey: tenantId,
              settings: JSON.stringify(formData)
            }
          : {
              partitionKey: tenantId,
              companyId: tenantCompanyId,
              settings: JSON.stringify(formData)
            }
      }),
      onSuccess: (data, snackbarOn) => {
        setIsDirty(false);
        snackbarOn('success', 'Successfully saved company dispatch settings');
      }
    }
  );
};
