import React, { useRef, useState } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import { Box, Button, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckIcon from '@material-ui/icons/Check';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { debounce } from 'lodash';
import moment from 'moment';
import { Draggable } from 'react-beautiful-dnd';

import DragIndicator from 'assets/Icons/Handle.svg';
import { AuditLogs, Context, PopperMenu, SergeantModal } from 'components';
import Modal from 'components/Modal';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import AmplifyService from 'services/AmplifyService';
import { CustomerPropertyService } from 'services/core';
import { getTenantSettingValueForKey, logErrorWithCallback } from 'utils';
import AppConstants, { TaskConstants } from 'utils/AppConstants';
import { EntityType, Mode } from 'utils/constants';

import AssetSelect from './AssetSelect';
import { deleteJobTask } from './DeleteTask.gql';
import PartsSelect from './PartsSelect';
import useStyles from './Tasks.styles';
import createTasksLayout from './TasksLayout';

const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 'none'
    },
    margin: '0 0 0 0 !important',
    '&:before': {
      display: 'none'
    }
  },
  expanded: {
    margin: '0 0 0 0 !important'
  }
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    minHeight: 0,
    '&$expanded': {
      padding: 0,
      minHeight: 0
    }
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: '0'
    }
  },
  expanded: {}
})(props => <MuiAccordionSummary {...props} />);

AccordionSummary.muiName = 'AccordionSummary';

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing.unit * 2
  }
}))(MuiAccordionDetails);

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  marginBottom: '5px',

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle
});

const ScopeTextField = withStyles(theme => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.grayscale(100)
    },
    '& .MuiTextField-root': {
      width: '100% !important'
    },
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.grayscale(100)
    },
    '& .MuiOutlinedInput-input': {
      marginTop: '2px',
      backgroundColor: theme.palette.grayscale(100)
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grayscale(100),
        border: 'none'
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grayscale(100)
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grayscale(100)
      }
    }
  }
}))(TextField);

export function TaskCard({
  handleJobTaskDelete,
  index,
  item,
  jobData,
  setTaskGroups,
  setTasksDataForTable,
  taskGroups,
  user
}) {
  const classes = useStyles();
  const theme = useTheme();
  const anchorRef = useRef();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [activityModalOpen, setActivityModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const assetsList = jobData?.parentEntity?.propertyAssets?.items.filter(i => i.isActive);

  // only 1 group so hardcoded to 0, groups are defined by assetIds now so no need for groupIndex, also this component will be deleted in later PR
  const taskIndex = taskGroups[0].findIndex(element => element.id === item.id);

  const customerPropertyService = new CustomerPropertyService();
  const appSyncClient = AmplifyService.appSyncClient().client;

  const priceBookId =
    jobData?.priceBookId || Context.getCompanyContext().getCompany?.defaultPriceBookId;
  const handleClick = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const dragHandler = () => (
    <img alt="dragIndicator" className={classes.dragIndicatorImgIcon} src={DragIndicator} />
  );
  const rowActionButtons = [
    {
      label: 'Activity',
      icon: 'Activity',
      onClick: () => setActivityModalOpen(true)
    },
    {
      label: 'Delete',
      icon: 'Delete',
      onClick: () => setDeleteModalOpen(true)
    }
  ];

  const taskChangeActions = {
    ASSET: 'asset',
    STATUS: 'status',
    DESCRIPTION: 'description',
    NAME: 'name'
  };

  const handleTaskChange = field => async e => {
    const localItem = {
      id: item.id,
      version: item?.version || 1
    };
    if (field !== taskChangeActions.ASSET) {
      localItem[field] = e.currentValue;
    } else if (field === taskChangeActions.STATUS) {
      const newState = [...taskGroups];
      newState[0][index][field] = e.currentValue;
      setTaskGroups(newState);
    } else {
      localItem.assetId = e.id;
    }
    setLoading(true);
    try {
      const { data } = await customerPropertyService.updateTask(user.tenantId, localItem);
      const newState = [...taskGroups];
      newState[0][index].version = data.updateTask.version;
      newState[0][index].status = data.updateTask.status;
      if (field !== 'asset') {
        newState[0][index][field] = e.currentValue;
      } else {
        newState[0][index].assetId = e.id;
        newState[0][index].asset = e;
      }
      setTaskGroups(newState);
    } catch (error) {
      logErrorWithCallback(error, snackbarOn, 'Unable to update task');
    } finally {
      setLoading(false);
    }
  };
  const onTextChange = debounce(handleTaskChange(taskChangeActions.DESCRIPTION), 500);
  const onNameChange = debounce(handleTaskChange(taskChangeActions.NAME), 500);

  const isAssetEnabled = getTenantSettingValueForKey('assetTrackingAgainstTask') === 'true';

  return (
    <Draggable draggableId={`${item.id}`} index={index} key={item.id}>
      {(provided, snapshot) => (
        <div style={{ opacity: snapshot.isDragging ? 0.5 : 1 }}>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            <Box boxShadow={2} m={0} p={0}>
              <Accordion expanded={expanded} square>
                <AccordionSummary>
                  <div className={classes.dragHandler} {...provided.dragHandleProps}>
                    {dragHandler()}
                  </div>
                  <Grid item justify="flex-start">
                    <IconButton
                      disabled={loading}
                      disableRipple
                      style={{ padding: 0, marginTop: 8, marginLeft: 10, marginRight: 5 }}
                      onClick={() =>
                        handleTaskChange(taskChangeActions.STATUS)({
                          currentValue:
                            taskGroups[0][index].status === TaskConstants.COMPLETED
                              ? TaskConstants.INJOB
                              : TaskConstants.COMPLETED
                        })
                      }
                    >
                      <CheckIcon
                        style={{
                          fontSize: 24,
                          color:
                            taskGroups[0][index].status === TaskConstants.COMPLETED
                              ? 'green'
                              : theme.palette.grayscale(90)
                        }}
                      />
                    </IconButton>
                  </Grid>
                  <Grid item justify="flex-start" xs={12}>
                    <Grid container>
                      <Box component="div" width={1}>
                        <ScopeTextField
                          defaultValue={item.name}
                          id={`scope-input-${item.id}`}
                          label=""
                          placeholder="Add task"
                          size="large"
                          style={{ width: '100%', marginLeft: '2px' }}
                          variant="outlined"
                          onBlur={e => onNameChange({ currentValue: e.target.value })}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item justify="flex-end" style={{ marginLeft: '10px' }} xs={5}>
                    <Typography
                      className={classes.label}
                      key="footer"
                      style={{ position: 'absolute', bottom: 9, color: 'grey' }}
                      variant="caption"
                    >
                      Last edited by {item.lastUpdatedBy} on{' '}
                      {moment(parseInt(item.lastUpdatedDateTime)).format(
                        AppConstants.DATETIME_FORMAT
                      )}
                    </Typography>
                  </Grid>
                  <Grid item justify="flex-end">
                    <Tooltip title="Expand Task">
                      <IconButton
                        disableRipple
                        style={{ padding: 0, marginTop: 9 }}
                        onClick={() => setExpanded(!expanded)}
                      >
                        {expanded ? (
                          <ExpandLessIcon
                            style={{ fontSize: 24, color: theme.palette.grayscale(20) }}
                          />
                        ) : (
                          <ExpandMoreIcon
                            style={{ fontSize: 24, color: theme.palette.grayscale(20) }}
                          />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item justify="flex-end">
                    <IconButton
                      disableRipple
                      ref={anchorRef}
                      style={{ padding: 0, marginTop: 9, marginLeft: 5, marginRight: 10 }}
                      onClick={handleClick}
                    >
                      <MoreVertIcon style={{ fontSize: 24, color: theme.palette.grayscale(20) }} />
                    </IconButton>
                    <PopperMenu
                      anchorEl={anchorRef.current}
                      itemList={rowActionButtons}
                      open={isOpen}
                      onClose={handleClose}
                    />
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <MUIForm
                    configuration={createTasksLayout(
                      {
                        item,
                        jobData,
                        onTextChange,
                        handleTaskChange,
                        taskChangeActions,
                        priceBookId
                      },
                      assetsList,
                      { taskGroups, setTaskGroups, taskIndex },
                      isAssetEnabled
                    )}
                    customComponents={{ PartsSelect, AssetSelect }}
                    data={item}
                    layout="edit"
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          </div>
          <SergeantModal
            dataType={EntityType.TASK}
            handleClose={() => setDeleteModalOpen(false)}
            handlePrimaryAction={async () => {
              try {
                const data = await appSyncClient.mutate({
                  mutation: deleteJobTask,
                  variables: {
                    id: item.jobTaskId
                  }
                });

                if (data) {
                  const newState = [...taskGroups];
                  newState[0].splice(index, 1);
                  setTaskGroups(newState);
                  setTasksDataForTable(newState);
                  setDeleteModalOpen(false);
                  await handleJobTaskDelete(item.id);
                }
              } catch (e) {
                logErrorWithCallback(
                  e,
                  snackbarOn,
                  'Unable to delete task. Please try again later'
                );
              }
            }}
            mode={Mode.DELETE}
            open={deleteModalOpen}
          />
          <Modal handleClose={() => setActivityModalOpen(false)} open={activityModalOpen}>
            <div className={classes.activityContainer}>
              <ErrorBoundaries>
                <Typography className={classes.activityTitle} variant="h4">
                  Activity
                </Typography>
                <div style={{ padding: 10, marginTop: 10 }}>
                  <AuditLogs
                    dataService={() => {
                      return item.auditLogEntries.items;
                    }}
                    variant="singleEntity"
                  />
                </div>
              </ErrorBoundaries>
            </div>
          </Modal>
        </div>
      )}
    </Draggable>
  );
}

export const NewCardButton = ({
  taskGroups,
  setTaskGroups,
  quoteService,
  user,
  jobData,
  fetchJobData
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const onClick = async () => {
    setLoading(true);
    const newTaskObj = {
      name: '',
      description: '',
      status: TaskConstants.OPEN,
      taskTypeInternal: 'General',
      isActive: true,
      sortOrder: taskGroups[0].length
    };
    const payload = {
      customerPropertyId: jobData.customerPropertyId,
      jobId: jobData.id,
      tasks: [newTaskObj]
    };
    const result = await quoteService.addTasksToCustomerPropertyAndJob(user.tenantId, payload);
    if (result?.data?.addTasksToCustomerPropertyAndJob?.length) {
      // had to refetch as the addTaskToPropertyAndJob can only return task. For delete to work we need jobTasks
      await fetchJobData();
    }
    setLoading(false);
  };

  return (
    <Button
      className={classes.addTaskbtn}
      size="small"
      startIcon={<AddCircleOutlineIcon />}
      variant="contained"
      onClick={onClick}
    >
      {loading ? 'Adding Task...' : 'Add Task'}
    </Button>
  );
};
