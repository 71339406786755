/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

import { sentryException } from '../Logger/index';

export async function getCostCodes() {
  return await instance
    .get('costcodes')
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get Cost Codes');
      return [];
    });
}

export async function getCostCodesByCompany(id) {
  return await instance
    .get(`costcodes/company/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get Cost Codes');
      return [];
    });
}

export async function getCostCodesByProject(id) {
  return await instance
    .get(`costcodes/project/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get Cost Codes');
      return [];
    });
}

export async function costCodeCreate(costCode) {
  return await instance
    .post('costcodes', costCode)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to create Cost Codes');
      return [];
    });
}

export async function costCodeDelete(costCode) {
  return await instance
    .delete(`costcodes/${costCode}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to delete Cost Codes');
      return [];
    });
}

export async function costCodeChange(id, costCode) {
  return await instance
    .put(`costcodes/${id}`, costCode)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to change Cost Codes');
      return [];
    });
}

export async function costCodeSearch(term, searchColumns) {
  if (term.length > 0) {
    return await instance
      .post(`costcodes/search/${term}`, { searchColumns: searchColumns || ['description'] })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        sentryException(err, 'Unable to get Cost Codes');
        return [];
      });
  }
  return await getCostCodes();
}
