import React, { Component } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';

import BillingCustomerSelect from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import Dropdown from 'components/BuildHeroFormComponents/Dropdown';
import { snackbarOn } from 'redux/actions/globalActions';
import { PaymentService } from 'services/core';
import { getTenantSettingValueForKey, logErrorWithCallback } from 'utils';
import { AccountingApp } from 'utils/constants';
import { constructSelectOptions, filterIsActive } from 'utils/constructSelectOptions';

import { PaymentAccountTypeDisplay } from '../constants';

import { constructOptionsForBankAccount } from '../helper';

import AccountingService from './accountingService';
import InvoiceApplicationTable from './InvoiceApplicationTable';
import PaymentFormLayout from './layout';

const NO_OVER_APPLYING_ERR_MSG = 'Payment amount cannot be less than the amount already applied';
class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.PaymentService = new PaymentService();
    this.AccountingService = new AccountingService();
    this.state = {
      paymentTypes: [],
      bankAccounts: [],
      glAccounts: [],
      formData: {},
      isIntacctEnabled: false
    };
  }

  componentDidMount = async () => {
    this.fetchPaymentOptions();
    this.fetchOptions(
      this.PaymentService.getActivePaymentTypesForCompany,
      'paymentTypes',
      'Payment Types',
      constructSelectOptions
    );
  };

  fetchPaymentOptions = () => {
    const accountingIntegration = getTenantSettingValueForKey('accountingApp');
    const isIntacctEnabled = accountingIntegration === AccountingApp.INTACCT;
    if (isIntacctEnabled) {
      this.setState({ isIntacctEnabled });
      this.fetchOptions(
        this.AccountingService.fetchBankAccounts,
        'bankAccounts',
        'Intacct',
        constructOptionsForBankAccount
      );
      this.fetchOptions(
        this.AccountingService.fetchGLAccounts,
        'ledgerAccounts',
        'Intacct',
        constructSelectOptions
      );
    }
  };

  fetchOptions = async (queryFn, name, displayName, selectOptions) => {
    const { user } = this.props;
    let nextToken = null;
    try {
      do {
        // eslint-disable-next-line no-await-in-loop
        const { data } = await queryFn(
          user.tenantId,
          `${user.tenantId}_Company_${user.tenantCompanyId}`,
          nextToken
        );
        if (data) {
          const items = data.getCompany?.[name]?.items || [];
          const options = selectOptions(items);
          // when nextToken is avaiable, that is when we need to mindfull of prev states
          if (nextToken) {
            this.setState(prevState => ({ [name]: [...options, ...(prevState[name] || [])] }));
          } else {
            this.setState({ [name]: [...options] });
          }
          // override with new next token
          nextToken = data.getCompany?.[name]?.nextToken;
        }
      } while (nextToken);
    } catch (error) {
      logErrorWithCallback(
        'error',
        this.props.snackbarOn,
        `Unable to fetch ${displayName}, please try again later`,
        error
      );
    }
  };

  onFormChange = formData => {
    this.setState({ formData });
  };

  getAccountRequiredFields = () => {
    const { data } = this.props;
    const { formData } = this.state;
    const completeData = { ...data, ...formData };
    if (completeData.accountTypeDisplay === PaymentAccountTypeDisplay.BANK_ACCOUNT) {
      return ['accountTypeDisplay', 'bankAccountId'];
    }
    return ['accountTypeDisplay', 'glAccountId'];
  };

  getOptions = () => {
    const { data, flags } = this.props;
    const { paymentTypes, bankAccounts, ledgerAccounts, isIntacctEnabled } = this.state;
    return {
      paymentTypeOptions: paymentTypes,
      bankAccounts: filterIsActive(bankAccounts, data?.bankAccountId),
      glAccounts: filterIsActive(ledgerAccounts, data?.glAccountId),
      isIntacctEnabled,
      requiredFields: isIntacctEnabled ? this.getAccountRequiredFields() : [],
      adjustmentsEnabled: flags.adjustments,
      isPaymentAppliedToInvoices: data?.paymentInvoices?.length > 0
    };
  };

  render() {
    const { onCreateService, onComplete, data, isEditMode, onFieldChange } = this.props;
    let layout = 'default';
    if (isEditMode) {
      // if invoiceId is present, the payment is only being applied to that invoice
      layout = data.invoiceId ? 'editForInvoice' : 'edit';
    }

    return (
      <MUIForm
        configuration={PaymentFormLayout(this.getOptions())}
        customComponents={{ BillingCustomerSelect, Dropdown, InvoiceApplicationTable }}
        data={data}
        layout={layout}
        onComplete={values => {
          if (values.availableAmount !== undefined) {
            if (values.availableAmount != null && values.availableAmount >= 0) {
              onComplete(values);
            } else {
              this.props.snackbarOn('error', NO_OVER_APPLYING_ERR_MSG);
            }
          } else {
            onComplete(values);
          }
        }}
        onCreateService={onCreateService}
        onFieldChange={onFieldChange}
        onFormChange={this.onFormChange}
      />
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapSnackBarToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const reduxConnectedPaymentForm = connect(
  mapStateToProps,
  mapSnackBarToProps
)(withLDConsumer()(PaymentForm));

export default reduxConnectedPaymentForm;
