import React, { useState } from 'react';

import { PresetSelect } from '@BuildHero/sergeant';

import { sentryMessage } from 'services/Logger';
import { isJSONParseableObjectOrArray } from 'utils';

export const SENTRY_CORRUPT_SETTINGS = 'Corrupt Preset Settings';

const PresetSelectMUIWrapper = ({ form, options, field }) => {
  const [selectedOption, setSelectedOption] = useState(
    options.options.find(o => o.id === field.value)
  );

  const { onClickCreateNew = () => {}, onClickUpdate = () => {} } = options;

  return (
    <PresetSelect
      {...options}
      selectedOption={selectedOption}
      onClickCreateNew={() => onClickCreateNew({ values: form.values })}
      onClickUpdate={() => onClickUpdate({ presetId: selectedOption.id, values: form.values })}
      onSelect={id => {
        if (id === selectedOption.id) return;
        const newSelectedOption = options.options.find(o => o.id === id);

        if (!newSelectedOption) {
          sentryMessage('Invalid Preset', { options: options.options, presetId: id });
          return;
        }

        setSelectedOption(newSelectedOption);

        if (!isJSONParseableObjectOrArray(newSelectedOption.settings)) {
          sentryMessage(SENTRY_CORRUPT_SETTINGS, { preset: newSelectedOption });

          return;
        }

        const settingsObj = JSON.parse(newSelectedOption.settings);

        Object.keys(settingsObj).forEach(k => {
          form.setFieldValue(k, settingsObj[k]);
        });
      }}
    />
  );
};

export default PresetSelectMUIWrapper;
