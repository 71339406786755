import React from 'react';

import { func } from 'prop-types';

import techAvatarImg from '@dispatch/assets/images/avatar-dark.png';
import { CONFIG } from '@dispatch/Dispatch.constants';
import { TECH_PROP } from '@dispatch/queries';

import CustomMarker from '../CustomMarker';

const TechMarker = ({ tech: { name, position, profilePictureUrl }, updateBounds }) => {
  return (
    <CustomMarker
      image={profilePictureUrl ? CONFIG.CLOUDINARY_IMAGE_URL + profilePictureUrl : techAvatarImg}
      label={name}
      position={position}
      updateBounds={updateBounds}
      zIndex={10}
    />
  );
};

TechMarker.propTypes = {
  tech: TECH_PROP.isRequired,
  updateBounds: func.isRequired
};

export default TechMarker;
