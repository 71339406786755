import React, { useMemo } from 'react';

import { CurrencyInput } from '@BuildHero/sergeant';

import { BoldCell } from 'components/WrapTable';

export const usePayrollHourRatesColumns = ({ setFieldValue, payrollHourTypes }) =>
  useMemo(
    () => [
      {
        headerName: 'Group',
        field: 'labourRateGroupName',
        width: 300,
        align: 'center',
        flex: 1,
        renderTotal: BoldCell,
        renderCell: ({ row, isGroupRow }) =>
          isGroupRow ? row.labourRateGroupName : row.labourTypeName
      },
      ...payrollHourTypes
        .filter(({ isArchived }) => !isArchived)
        .map(payrollHourType => ({
          headerName: payrollHourType.hourTypeAbbreviation,
          field: payrollHourType.hourTypeAbbreviation,
          editable: true,
          renderEditCell: ({ value, cellAddress, isGroupRow }) =>
            isGroupRow ? (
              <div style={{ width: '100%', height: '100%' }} />
            ) : (
              <CurrencyInput
                placeholder="--.--"
                testingid={cellAddress}
                value={value}
                onBlur={newValue =>
                  setFieldValue(cellAddress, newValue === undefined ? null : newValue)
                }
              />
            )
        }))
    ],
    [payrollHourTypes, setFieldValue]
  );
