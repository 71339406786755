// Base element sizes need to make various layout and positioning calculations
import { EVENT_TYPES } from '@dispatch/Dispatch.constants';

const BaseElementSizes = {
  availableTimeHeight: 30,
  cellHeight: 62,
  cellWidth: 120,
  defaultTrayHeight: 350,
  filterPopoverWidth: 280,
  globalBannerHeight: 32,
  headerContentHeight: 36,
  hoveredWeekDropTarget: 120,
  mainNavHeight: 60,
  minTrayHeight: 50,
  rightDrawerWidth: 400,
  stickyHeaderHeight: 44,
  techCellWidth: 200,
  tickerHeight: 22,
  tickerWidth: 72
};

export const ElementSizes = {
  ...BaseElementSizes,
  maxTrayHeight: window.innerHeight - (BaseElementSizes.mainNavHeight + 100),
  laneWidth: BaseElementSizes.techCellWidth + BaseElementSizes.cellWidth * 24,
  timeWidth: BaseElementSizes.cellWidth * 24
};

const BaseDispatchTheme = {
  mainBorderColor: '#CCCCCC',
  lightBorderColor: '#E6E6E6',
  darkBorderColor: '#030303'
};

export const DispatchTheme = {
  ...BaseDispatchTheme,
  mixins: {
    mainBorder: `solid 1px ${BaseDispatchTheme.mainBorderColor}`,
    lightBorder: `solid 1px ${BaseDispatchTheme.lightBorderColor}`,
    darkBorder: `solid 1px ${BaseDispatchTheme.darkBorderColor}`,
    topShadow: { boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)' },
    bottomShadow: { boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)' },
    transitionSpeed: 0,
    oneLineClamp: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      lineHeight: '1.2em',
      textOverflow: 'ellipsis'
    },
    twoLineClamp: {
      display: '-webkit-box',
      lineClamp: 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden'
    }
  }
};

// The order of element names determines relative Z-index
// Elements at the top have the highest z-index
export const ElementZIndex = [
  'rightDrawerHeader',
  'rightDrawer',
  'boardFilterContainer',
  'addVisitOptions',
  'techCell',
  'stickyHeader',
  'dropPreview',
  'eventCard',
  'timeMarker',
  'timeOverlay',
  'timeLine'
]
  .reverse()
  .reduce(
    (layerMap, layer, i) => ({
      ...layerMap,
      [layer]: i
    }),
    {}
  );

export const EventColors = {
  [EVENT_TYPES.VISIT]: '#333333',
  [EVENT_TYPES.BILLABLE_EVENT]: '#009900',
  [EVENT_TYPES.NON_BILLABLE_EVENT]: '#808080',
  [EVENT_TYPES.MAN_DAY]: '#003399'
};
