import { gql } from '@apollo/client/core';

import { COMPANY_SETTING_TYPE } from 'constants/common';
import useExtendedMutation from 'customHooks/useExtendedMutation';
import { logErrorWithCallback } from 'utils';

import { convertSettingsJSONtoString, convertStringToSettingsJSON } from '../utils';

const UPDATE_COMPANY_SETTING = gql`
  mutation updatePayrollSettings($partitionKey: String!, $data: UpdateCompanySettingInput!) {
    updateCompanySetting(partitionKey: $partitionKey, data: $data) {
      id
      version
      settings
    }
  }
`;

const serializer = ({ partitionKey, formData }) => ({
  variables: {
    partitionKey,
    data: {
      id: formData.id,
      type: COMPANY_SETTING_TYPE.PAYROLL,
      version: formData.version,
      settings: convertSettingsJSONtoString(formData)
    }
  }
});

const transform = ({ updateCompanySetting }) => {
  return convertStringToSettingsJSON(updateCompanySetting);
};

export const useUpdatePayrollSettings = () => {
  return useExtendedMutation(UPDATE_COMPANY_SETTING, {
    serializer,
    transform,
    onSuccess: (data, snackbarOn) => snackbarOn('success', 'Successfully updated payroll settings'),
    onError: (err, snackbarOn) =>
      logErrorWithCallback(err, snackbarOn, 'Unable to update settings, please try again later')
  });
};
