/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';

import moment from 'moment';
import { connect } from 'react-redux';

import { PageHeader, SergeantModal, UserPermission } from 'components';
import CreateEntryButton from 'components/Buttons/CreateEntryButton';
import ResponsiveTable from 'components/ResponsiveTable';
import SyncStatusIcon from 'components/SyncStatusIcon';
import { paymentRowsMeta } from 'meta/ListViews';
import { setOpenQuickAddModal, snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { ListService, PaymentService } from 'services/core';
import { getTenantSettingValueForKey, logErrorWithCallback } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';
import { Mode, QuickAddModal } from 'utils/constants';

import { disableStatusList } from './constants';

class PaymentsList extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.ListService = new ListService();
    this.PaymentService = new PaymentService();
    this.state = {
      refreshCounter: 0,
      deleteConfirmationModal: {
        open: false,
        data: null,
        dataType: 'payment',
        mode: Mode.DELETE,
        // eslint-disable-next-line no-console
        handlePrimaryAction: () => console.log('no primary action set'),
        layout: null,
        deleteItemLabel: ''
      }
    };

    this.pageHeaderButtons = [
      <CreateEntryButton
        handleAdd={() => props.setOpenQuickAddModal(QuickAddModal.CREATE_PAYMENT)}
        key="createPayment"
        label="New Payment"
      />
    ];

    this.paymentTypesName = 'paymentTypes';
  }

  componentDidMount = async () => {
    document.title = 'BuildOps - Payments';

    await this.fetchPaymentTypes();
  };

  fetchPaymentTypes = async () => {
    const { user } = this.props;
    let nextToken = null;

    try {
      do {
        // eslint-disable-next-line no-await-in-loop
        const { data } = await this.PaymentService.getAllPaymentTypesForCompany(
          user.tenantId,
          `${user.tenantId}_Company_${user.tenantCompanyId}`,
          moment()
            .subtract(7, 'd')
            .unix(),
          moment().unix(),
          nextToken
        );
        if (data && this.mounted) {
          const name = this.paymentTypesName;
          const items = data.getCompany?.[name]?.items || [];
          const paymentTypes = items.map(v => v.name);

          if (nextToken) {
            this.setState(prevState => ({ [name]: [...paymentTypes, ...(prevState[name] || [])] }));
          } else {
            this.setState({ [name]: [...paymentTypes] });
          }
          nextToken = data.getCompany?.[name]?.nextToken;
        }
      } while (nextToken);
    } catch (error) {
      console.log(error);

      logErrorWithCallback(
        'error',
        this.props.snackbarOn,
        `Unable to fetch payment types, please try again later`,
        error
      );
    }
  };

  getAllPayments = async (filter, limit, offset, sortBy, sortOrder, status) => {
    const { user } = this.props;
    const noResponse = { items: [], nextToken: '0' };
    if (!user?.tenantId) return noResponse;

    const { tenantId, tenantCompanyId } = user;
    const sortKey = `${tenantId}_Company_${tenantCompanyId}`;
    try {
      const resp = await this.ListService.getAllPayments(
        tenantId,
        sortKey,
        filter,
        limit,
        offset,
        sortBy,
        sortOrder,
        status
      );
      const { items, ...rest } = resp;
      if (!items) return resp;
      return {
        items: this.formatListData(items),
        ...rest
      };
    } catch (error) {
      logErrorWithCallback(
        error,
        this.props.snackbarOn,
        'Unable to fetch Payments, please try again later'
      );
    }
    return noResponse;
  };

  formatListData = payments => {
    return payments.map(({ billingCustomer, paymentType, ...rest }) => {
      return {
        billingCustomerName: billingCustomer?.customerName,
        billingCustomerId: billingCustomer?.id,
        paymentTypeName: paymentType?.name,
        paymentTypeId: paymentType?.id,
        ...rest
      };
    });
  };

  handleRowActions = (actionName, record) => {
    if (actionName === 'delete') {
      const { deleteConfirmationModal } = this.state;
      const updatedModal = { ...deleteConfirmationModal };
      updatedModal.open = true;
      updatedModal.data = record;
      updatedModal.deleteItemLabel = `Payment ${record.paymentNumber}`;
      updatedModal.handlePrimaryAction = this.handleDeleteModalClick;
      this.setState({ deleteConfirmationModal: updatedModal });
    }
  };

  handleDeleteModalClick = async (record, loaded) => {
    if (record?.id) {
      const { id } = record;
      const { tenantId } = this.props.user;
      try {
        const { data } = await this.PaymentService.deletePayment(tenantId, id);
        if (data) {
          this.props.snackbarOn('success', `Successfully deleted Payment ${data.id || ''}`);
          this.setState(prevState => ({
            refreshCounter: prevState.refreshCounter + 1
          }));
        }
      } catch (error) {
        logErrorWithCallback(
          error,
          this.props.snackbarOn,
          'Unable to delete Payment, please try again later'
        );
      }
    }
    loaded();
    this.handleDeleteModalClose();
  };

  handleDeleteModalClose = () => {
    const { deleteConfirmationModal } = this.state;
    const updatedModal = { ...deleteConfirmationModal };
    updatedModal.open = false;
    this.setState({ deleteConfirmationModal: updatedModal });
  };

  getPaymentTableMeta = () => {
    const paymentTypes = this.state[this.paymentTypesName] || [];
    const accountingIntegration = getTenantSettingValueForKey('accountingApp');
    const isAccountIntegrationEnabled = !!accountingIntegration;
    return paymentRowsMeta({ isAccountIntegrationEnabled, paymentTypes });
  };

  render() {
    const { user } = this.props;
    const { refreshCounter, deleteConfirmationModal } = this.state;

    return (
      <ErrorBoundaries>
        <UserPermission action={PermissionConstants.OBJECT_INVOICE} I="read">
          <PageHeader
            overrideHeaderButtons={this.pageHeaderButtons}
            pageMapKey="payments"
            userLocale={user.locale}
          />
          <ResponsiveTable
            allowDynamicRowMetadata
            caslKey={PermissionConstants.OBJECT_INVOICE}
            customCellComponents={{
              syncStatus: ({ record }) => <SyncStatusIcon status={record.syncStatus} />
            }}
            fullScreen
            key={refreshCounter}
            noDataMsg="No Payments"
            rowActionButtons={{
              delete: {
                label: 'Delete',
                icon: 'DeleteOutlined',
                disable: ({ exportStatus }) => disableStatusList.includes(exportStatus)
              }
            }}
            rowActions={this.handleRowActions}
            rowMetadata={this.getPaymentTableMeta()}
            service={this.getAllPayments}
            showToolbars
            tableName="Payments"
          />
        </UserPermission>
        <SergeantModal
          confirmRemoveItemLabel={deleteConfirmationModal.deleteItemLabel}
          data={deleteConfirmationModal.data}
          dataType={deleteConfirmationModal.dataType}
          handleClose={this.handleDeleteModalClose}
          handlePrimaryAction={deleteConfirmationModal.handlePrimaryAction}
          layout={deleteConfirmationModal.layout}
          mode={deleteConfirmationModal.mode}
          open={deleteConfirmationModal.open}
        />
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = { snackbarOn, setOpenQuickAddModal };

const reduxConnectedPayments = connect(mapStateToProps, mapDispatcherToProps)(PaymentsList);

export default reduxConnectedPayments;
