import { useMemo } from 'react';

import { MUIFormComponentNames } from '@BuildHero/sergeant';

import { col, colRow, row } from '@dispatch/Dispatch.layoutUtils';

export const useManDayEditFormConfig = ({ disabled, techsLoading, techOptions }) => {
  return useMemo(() => {
    const START_TIME_FIELD_LABEL = {
      component: MUIFormComponentNames.LabelControl,
      source: 'START TIME',
      options: {
        required: true
      }
    };

    const START_TIME_FIELD_INPUT = {
      component: MUIFormComponentNames.TimeInput,
      source: 'startTime',
      options: {
        disabled
      }
    };

    const END_TIME_FIELD_LABEL = {
      component: MUIFormComponentNames.LabelControl,
      source: 'END TIME',
      options: {
        required: true
      }
    };

    const END_TIME_FIELD_INPUT = {
      component: MUIFormComponentNames.TimeInput,
      source: 'endTime',
      options: {
        disabled
      }
    };

    const DATE_FIELD_LABEL = {
      component: MUIFormComponentNames.LabelControl,
      source: 'DATE',
      options: {
        required: true
      }
    };

    const DATE_FIELD_INPUT = {
      component: MUIFormComponentNames.DateInput,
      source: 'date',
      options: {
        disabled
      }
    };

    const TECHS_FIELD = [
      {
        component: MUIFormComponentNames.SelectInput,
        source: 'technicianIds',
        options: {
          disabled: techsLoading || disabled,
          placeholder: '-',
          valueSet: techOptions,
          isMultipleSelection: true,
          height: 'auto',
          isSearchable: true
        }
      }
    ];

    const DESCRIPTION_FIELD = [
      {
        component: MUIFormComponentNames.TextInput,
        source: 'description',
        options: {
          label: 'DESCRIPTION',
          lines: 4,
          linesMax: 4,
          maxTextLen: 1000
        }
      }
    ];

    const DETAILS_SECTION_CONTENT = [row(DESCRIPTION_FIELD)];

    const SCHEDULING_SECTION_CONTENT = [
      colRow([col('50%', [START_TIME_FIELD_LABEL]), col('50%', [END_TIME_FIELD_LABEL])]),
      colRow([col('50%', [START_TIME_FIELD_INPUT]), col('50%', [END_TIME_FIELD_INPUT])]),
      colRow([col('100%', [DATE_FIELD_LABEL])]),
      colRow([col('100%', [DATE_FIELD_INPUT])])
    ];

    const TECHNICIANS_SECTION_CONTENT = [row(TECHS_FIELD)];

    return {
      fields: {},
      layouts: {
        default: {
          contents: [
            {
              component: 'AccordionSection',
              options: {
                heading: 'Details',
                expanded: true
              },
              contents: DETAILS_SECTION_CONTENT
            },
            {
              component: 'AccordionSection',
              options: {
                heading: 'Scheduling',
                expanded: true
              },
              contents: SCHEDULING_SECTION_CONTENT
            },
            {
              component: 'AccordionSection',
              options: {
                heading: 'Technicians',
                expanded: true
              },
              contents: TECHNICIANS_SECTION_CONTENT
            }
          ]
        }
      },
      validation: {
        type: 'object',
        properties: {
          startTime: {
            type: 'string',
            nullable: true
          },
          endTime: {
            type: 'string',
            nullable: true
          },
          date: {
            type: 'string',
            nullable: true
          },
          technicianIds: {
            type: 'array'
          }
        },
        required: ['startTime', 'endTime', 'date', 'technicianIds']
      },
      validationErrors: {
        startTime: {
          required: 'Start time is required',
          type: 'Value must be a string'
        },
        endTime: {
          required: 'End time is required',
          type: 'Value must be a string'
        },
        date: {
          required: 'Date is required',
          type: 'Value must be a string'
        },
        technicianIds: {
          required: 'At least one technician is required',
          type: 'Value must be a list'
        }
      }
    };
  }, [disabled, techsLoading, techOptions]);
};
