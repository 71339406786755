import React from 'react';

import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

import displayIconElement, { displayTitle } from '../assets/icons';

import styles from './styles';

const propTypes = {
  shouldDisplay: PropTypes.bool.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  classes: PropTypes.shape({}),
  handleClick: PropTypes.func,
  isLoading: PropTypes.bool
};

const defaultProps = {
  icon: '',
  title: '',
  color: '',
  classes: {},
  handleClick: () => {},
  isLoading: false
};

const VisitActionButton = ({
  shouldDisplay,
  icon,
  title,
  color,
  classes,
  handleClick,
  isLoading
}) => {
  return (
    shouldDisplay && (
      <Button
        className={classes.root}
        disabled={isLoading}
        style={{ borderColor: color }}
        variant="outlined"
        onClick={handleClick}
      >
        {isLoading && <CircularProgress className={classes.spinner} size={20} />}
        <span className={classes.text} style={{ color }}>
          {displayIconElement(icon)}
          {displayTitle(title)}
        </span>
      </Button>
    )
  );
};

VisitActionButton.propTypes = propTypes;
VisitActionButton.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(VisitActionButton);
