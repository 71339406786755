import taskGroupsTitle from './TaskGroupsTitle';

const groupedTaskData = ({
  tasksWithGroups,
  settingsJSON,
  SettingConstants,
  groupName,
  groupPricing,
  smartFieldInfo,
  HTML,
  styles,
  fontFamilyStyle
}) =>
  tasksWithGroups
    .map(
      (group, i) =>
        `${(group?.tasks || [])
          .map(
            (taskItem, idx) =>
              `<figure data-smartfield=${i === 0 && idx === 0 ? 'TaskGroups' : 'RemoveItem'}>
                  <table style="border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);width:100%;">
                    ${HTML.tasks({
                      groupTitle:
                        idx === 0 &&
                        taskGroupsTitle({
                          settingsJSON,
                          SettingConstants,
                          group,
                          groupName,
                          groupPricing,
                          styles,
                          fontFamilyStyle
                        }),
                      HTML,
                      group: { tasks: [taskItem] },
                      smartFieldInfo,
                      settingsJSON,
                      removeTaskSmartfield: true,
                      styles,
                      fontFamilyStyle,
                      taskGroupIdx: idx + 1
                    })}</table></figure>`
          )
          .join('')}
      </tr></table></figure>`
    )
    .join('');

export default groupedTaskData;
