import moment from 'moment';

class SchedulingRules {
  constructor() {
    this.steps = {
      scheduleInThePast: this.scheduleInThePastSetup(),
      hasOfflineMember: this.hasOfflineMemberSetup(),
      missingExtraCrew: this.missingExtraCrewSetup(),
      missingPrimaryCrew: this.missingPrimaryCrewSetup(),
      missingCrew: this.missingCrewSetup()
    };
    this.canPassSteps = {
      scheduleInThePast: false
    };
    this.visit = null;
  }

  scheduleInThePastSetup = params => {
    const { buttons, errorMessage } = params || {};
    const localButtons = {
      ok: {
        key: 'ok',
        name: 'OK'
      },
      cancel: {
        key: 'cancel',
        name: 'CANCEL'
      }
    };

    if (buttons && buttons.ok) {
      localButtons.ok = { ...localButtons.ok, ...buttons.ok };
    }
    if (buttons && buttons.cancel) {
      localButtons.cancel = { ...localButtons.cancel, ...buttons.cancel };
    }

    let localErrorMessage =
      'This event will be scheduled in the past. \r\nThis visit wil be moved to Dispatch Today section if it`s not started by the time you reload this page!';
    if (errorMessage) localErrorMessage = errorMessage;

    const ret = {
      name: 'Start of the scheduled event is in the past',
      errorMessage: localErrorMessage,
      condition: params => this.canScheduleInThePast(params),
      buttons: localButtons
    };
    if (this.steps) {
      this.steps.scheduleInThePast = ret;
    }
    return ret;
  };

  missingCrewSetup = params => {
    const { buttons, errorMessage } = params || {};
    const localButtons = {
      cancel: {
        key: 'cancel',
        name: 'CANCEL'
      },
      edit: {
        key: 'edit',
        name: 'EDIT VISIT'
      }
    };

    if (buttons && buttons.cancel) {
      localButtons.cancel = { ...localButtons.cancel, ...buttons.cancel };
    }
    if (buttons && buttons.edit) {
      localButtons.edit = { ...localButtons.edit, ...buttons.edit };
    }

    let localErrorMessage = 'This event has an incomplete crew';
    if (errorMessage) localErrorMessage = errorMessage;

    const ret = {
      name: 'Incomplete crew',
      errorMessage: localErrorMessage,
      condition: params => this.hasCompleteCrew(params),
      buttons: localButtons
    };
    if (this.steps) {
      this.steps.missingCrew = ret;
    }
    return ret;
  };

  hasOfflineMemberSetup = params => {
    const { buttons, errorMessage } = params || {};
    const localButtons = {
      cancel: {
        key: 'cancel',
        name: 'CANCEL'
      },
      edit: {
        key: 'edit',
        name: 'EDIT VISIT'
      }
    };

    if (buttons && buttons.cancel) {
      localButtons.cancel = { ...localButtons.cancel, ...buttons.cancel };
    }
    if (buttons && buttons.edit) {
      localButtons.edit = { ...localButtons.edit, ...buttons.edit };
    }

    let localErrorMessage = 'This event has an offline crew member';
    if (errorMessage) localErrorMessage = errorMessage;

    const ret = {
      name: 'Offline crew member',
      errorMessage: localErrorMessage,
      condition: params => this.hasOfflineMember(params),
      buttons: localButtons
    };
    if (this.steps) {
      this.steps.hasOfflineMember = ret;
    }
    return ret;
  };

  missingExtraCrewSetup = params => {
    const { buttons, errorMessage } = params || {};
    const localButtons = {
      ok: {
        key: 'ok',
        name: 'OK'
      },
      cancel: {
        key: 'cancel',
        name: 'CANCEL'
      }
    };

    if (buttons && buttons.ok) {
      localButtons.ok = { ...localButtons.ok, ...buttons.ok };
    }
    if (buttons && buttons.cancel) {
      localButtons.cancel = { ...localButtons.cancel, ...buttons.cancel };
    }

    let localErrorMessage = 'This user will be added as the missing extra crew member';
    if (errorMessage) localErrorMessage = errorMessage;

    const ret = {
      name: 'Incomplete crew',
      errorMessage: localErrorMessage,
      condition: params => this.missingExtraCrew(params),
      buttons: localButtons
    };
    if (this.steps) {
      this.steps.missingExtraCrew = ret;
    }
    return ret;
  };

  missingPrimaryCrewSetup = params => {
    const { buttons, errorMessage } = params || {};
    const localButtons = {
      ok: {
        key: 'ok',
        name: 'OK'
      },
      cancel: {
        key: 'cancel',
        name: 'CANCEL'
      }
    };

    if (buttons && buttons.ok) {
      localButtons.ok = { ...localButtons.ok, ...buttons.ok };
    }
    if (buttons && buttons.cancel) {
      localButtons.cancel = { ...localButtons.cancel, ...buttons.cancel };
    }

    let localErrorMessage = 'This user will be added as primary technician';
    if (errorMessage) localErrorMessage = errorMessage;

    const ret = {
      name: 'Incomplete crew',
      errorMessage: localErrorMessage,
      condition: params => this.missingPrimaryCrew(params),
      buttons: localButtons
    };
    if (this.steps) {
      this.steps.missingPrimaryCrew = ret;
    }
    return ret;
  };

  hasOfflineTechs = (techs, offline) => {
    if (offline) {
      const offlines = offline.map(el => {
        if (el.off) return el.id;
      });
      let ret = false;
      techs.forEach(el => {
        if (offlines.includes(el)) ret = true;
      });
      return ret;
    }
    return false;
  };

  hasOfflineMember = (additionalParams = {}) => {
    // const primaryCrewKeys = [];
    // for (let i = 0; i < this.visit.primaryTechs.length; i += 1) {
    //   primaryCrewKeys.push(this.visit.primaryTechs[i]);
    // }
    // const extraCrewKeys = [];
    // for (let i = 0; i < this.visit.extraTechs.length; i += 1) {
    //   extraCrewKeys.push(this.visit.extraTechs[i]);
    // }
    // const crewKeys = primaryCrewKeys.concat(extraCrewKeys);
    this.visit.crewKeys.push(additionalParams.tech);
    if (this.hasOfflineTechs(this.visit.crewKeys, additionalParams.offlineResources)) return false;

    return true;
  };

  missingPrimaryCrew = (additionalParams = {}) => {
    if (this.visit.primaryTechs.length === 0) {
      return false;
    }
    return true;
  };

  missingExtraCrew = (additionalParams = {}) => {
    if (this.visit.primaryTechs.length === 1) {
      // const extraCrewKeys = [];
      // for (let i = 0; i < this.visit.extraTechs.length; i += 1) {
      //   extraCrewKeys.push(this.visit.extraTechs[i]);
      // }
      if (this.visit.extraTechsRequired && this.visit.extraTechsNumber) {
        if (this.visit.extraTechsNumber - this.visit.extraTechs.length === 1) {
          if (additionalParams.tech && !this.visit.extraCrewKeys.includes(additionalParams.tech)) {
            return false;
          }
        }
      }
    }
    return true;
  };

  hasCompleteCrew = (additionalParams = {}) => {
    // const primaryCrewKeys = [];
    // for (let i = 0; i < this.visit.primaryTechs.length; i += 1) {
    //   primaryCrewKeys.push(this.visit.primaryTechs[i]);
    // }
    // const extraCrewKeys = [];
    // for (let i = 0; i < this.visit.extraTechs.length; i += 1) {
    //   extraCrewKeys.push(this.visit.extraTechs[i]);
    // }
    // const crewKeys = primaryCrewKeys.concat(extraCrewKeys);
    // if (this.hasOfflineTechs(this.visit.crewKeys, additionalParams.offlineResources)) return false;

    const crewLength = this.visit.primaryTechs.length + this.visit.extraTechs.length;
    if (this.visit.extraTechsRequired && this.visit.extraTechsNumber) {
      if (this.visit.extraTechsNumber + 1 - crewLength > 1) return false;
      if (this.visit.extraTechsNumber + 1 - crewLength === 1) {
        if (additionalParams.tech && this.visit.crewKeys.includes(additionalParams.tech)) {
          return false;
        }
      }
    }
    return true;
  };

  canScheduleInThePast = (additionalParams = {}) => {
    let scheduledTime = this.visit.scheduledFor;
    if (this.visit.actualDuration && this.visit.scheduledFor) {
      const duration = this.visit.actualDuration.split(' ');
      const scheduledMoment = moment.unix(this.visit.scheduledFor);
      const endVisit = scheduledMoment.add(duration[0], duration[1]);
      scheduledTime = endVisit.unix();
    }
    if (this.canPassSteps.scheduleInThePast) {
      return true;
    }
    if (
      scheduledTime <=
      moment()
        .startOf('day')
        .unix()
    ) {
      return false;
    }
    return true;
  };

  resetSteps = () => {
    this.canPassSteps = {
      scheduleInThePast: false
    };
  };

  // should pass error and success callbacks here
  canSchedule = (visit, skipSteps, additionalParams = {}) => {
    this.canPassSteps = { ...this.canPassSteps, ...skipSteps };
    this.visit = visit;
    this.visit.primaryCrewKeys = [];
    for (let i = 0; i < this.visit.primaryTechs.length; i += 1) {
      this.visit.primaryCrewKeys.push(this.visit.primaryTechs[i]);
    }
    this.visit.extraCrewKeys = [];
    for (let i = 0; i < this.visit.extraTechs.length; i += 1) {
      this.visit.extraCrewKeys.push(this.visit.extraTechs[i]);
    }
    this.visit.crewKeys = this.visit.primaryCrewKeys.concat(this.visit.extraCrewKeys);
    let ret = true;
    Object.keys(this.steps).forEach(step => {
      const actualStep = this.steps[step];
      if (!actualStep.condition(additionalParams)) {
        ret = { message: actualStep.errorMessage, buttons: actualStep.buttons };
      }
    });
    this.resetSteps();
    return ret;
  };
}

export default SchedulingRules;
