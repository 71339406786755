import React, { useCallback } from 'react';

import { ThemeProvider } from '@BuildHero/sergeant';
import PropTypes from 'prop-types';

import WrapTable from 'components/WrapTable';

import { usePayrollHourRatesColumns } from './PayrollHourRatesTable.columns';
import { GROUP_BY_LABOUR_RATE_GROUP_NAME } from './PayrollHourRatesTable.constants';

const PayrollHourRatesTable = ({ tableLength, loading, payrollHourTypes, formik }) => {
  const setFieldValue = useCallback(
    (cellAddress, newValue) => formik.setFieldValue(cellAddress, newValue),
    []
  );

  return (
    <ThemeProvider>
      <WrapTable
        columns={usePayrollHourRatesColumns({ setFieldValue, payrollHourTypes })}
        hideFooter={tableLength <= 10}
        loading={loading}
        rowGroupingModel={GROUP_BY_LABOUR_RATE_GROUP_NAME}
        rows={formik.values}
      />
    </ThemeProvider>
  );
};

PayrollHourRatesTable.propTypes = {
  tableLength: PropTypes.number,
  loading: PropTypes.bool,
  payrollHourTypes: PropTypes.array,
  formik: PropTypes.object
};

PayrollHourRatesTable.defaultProps = {
  tableLength: 0,
  loading: false,
  payrollHourTypes: [],
  formik: {}
};

export default PayrollHourRatesTable;
