import { number, shape, string } from 'prop-types';

export const REPORT_FORM_PROP = shape({
  // __typeName: 'FormData',
  id: string,
  formDefinition: shape({
    formDefinitionJson: string
  }),
  form: shape({
    name: string,
    description: string
  }),
  formDataJson: string,
  lastUpdatedBy: string,
  lastUpdatedDate: number
});
