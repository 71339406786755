import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function Payments(props) {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SvgIcon {...props}>
      <path d="M10.0688 1.16631C10.0181 1.23135 9.98738 1.31213 9.98738 1.40093V3.61044C7.57083 4.26378 6.2847 5.95952 6.2847 8.21951C6.2847 11.3021 9.16837 12.4069 11.4853 13.277C13.2229 13.9422 14.7426 14.5184 14.7426 15.6974C14.7426 17.0842 13.3787 17.7227 12.1174 17.7227C9.83791 17.7227 8.03483 16.0775 8.00992 16.0656C7.85733 15.8963 7.53657 15.929 7.40578 16.1013L6.07917 17.7584C5.96395 17.9069 5.9764 18.1148 6.10408 18.2632C6.16948 18.3375 7.81685 20.054 10.8282 20.4609V22.608C10.8282 22.7679 10.9277 22.9002 11.0656 22.9622C5.39853 22.4935 1 17.7966 1 12C1 6.56791 4.86283 2.10152 10.0133 1.17605L10.0688 1.16631ZM23 12C23 17.4226 19.1507 21.8828 14.0138 22.8191C14.0537 22.7586 14.0762 22.6863 14.0762 22.608V20.4609C16.6516 19.8016 18 18.0524 18 15.5489L17.9935 15.3028C17.8472 12.5342 15.2542 11.5713 12.952 10.726C11.124 10.0519 9.55452 9.47275 9.55452 8.17199C9.55452 7.05536 10.6881 6.28025 12.323 6.28025C14.2537 6.28025 15.8108 7.59586 15.8232 7.60774C16.0381 7.75919 16.3339 7.7295 16.4679 7.49488L17.5111 5.70412C17.6014 5.55563 17.5765 5.3715 17.4457 5.23786C17.3959 5.18738 16.0039 3.83614 13.3911 3.55401V1.39499C13.3911 1.25499 13.3197 1.13493 13.2095 1.06506C18.7436 1.65987 23 6.29789 23 12Z" />
    </SvgIcon>
  );
}
