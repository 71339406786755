import { gql } from '@apollo/client/core';
import { sortBy } from 'lodash';
import { useSelector } from 'react-redux';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { generateCompanySortKey } from 'services/core/utils';
import { logErrorWithCallback } from 'utils';

const getLabourRateModifiersByCompany = gql`
  query getLabourRateModifiersByCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      labourRateModifiers {
        items {
          id
          name
          sortOrder
          appliesToCategory
          modifierValue
          modifierType
          employeeLabourRateModifiers {
            items {
              id
              employeeId
              employee {
                id
                name
              }
            }
          }
          departmentLabourRateModifiers {
            items {
              id
              departmentId
              department {
                id
                tagName
              }
            }
          }
        }
      }
    }
  }
`;

const useLabourRateModifiers = (options = {}) => {
  const user = useSelector(state => state.user);

  const { data, loading, error, refetch } = useExtendedQuery(getLabourRateModifiersByCompany, {
    variables: {
      partitionKey: user.tenantId,
      sortKey: generateCompanySortKey(user.tenantId, user.tenantCompanyId)
    },
    transform: result => {
      const response = result?.getCompany?.labourRateModifiers?.items || [];
      const modifiers = response.map(modifier => {
        return {
          ...modifier,
          appliesTo: [
            ...modifier.employeeLabourRateModifiers?.items?.map(item => item.employeeId),
            ...modifier.departmentLabourRateModifiers?.items?.map(item => item.departmentId)
          ]
        };
      });
      return sortBy(modifiers, 'sortOrder');
    },
    onError: (err, snackbar) =>
      logErrorWithCallback(
        err,
        snackbar,
        'Unable to get labour rate modifiers, please try again later'
      ),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options
  });

  return [data || [], loading, error, refetch];
};

export default useLabourRateModifiers;
