import React, { Component } from 'react';

import { connect } from 'react-redux';

import { PageHeader, UserPermission } from 'components';
import Context from 'components/Context';
import ResponsiveTable from 'components/ResponsiveTable';
import { MIXPANEL_EVENT, MIXPANEL_PAGE_NAME } from 'constants/mixpanelEvents';
import { reviewReportRowMeta } from 'meta/ListViews';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { ListService } from 'services/core';
import { Logger } from 'services/Logger';
import { sendMixpanelEvent } from 'services/mixpanel';
import { getTenantSettingValueForKey } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';

class ReviewReportView extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.ListService = new ListService();
    this.state = {
      meta: reviewReportRowMeta()
    };
  }

  componentDidMount = async () => {
    sendMixpanelEvent(MIXPANEL_EVENT.PAGE_VIEW, MIXPANEL_PAGE_NAME.REVIEW_REPORTS);
    document.title = 'BuildOps - Review Report List';
    // have to support sorting and filtering. When job number, it should work as number sorting, when custom job number will work as string sorting
    // hence the below checks are added
    if (Context.getCompanyContext() && Context.getCompanyContext().getCompany) {
      const isCustomJobNumberEnabled =
        getTenantSettingValueForKey('job_customJobNumber') === 'true' || false;
      if (isCustomJobNumberEnabled && this.mounted) {
        const filterMeta = reviewReportRowMeta().find(item => item.id === 'jobNumber');
        if (filterMeta) {
          // To avoid null in rerender
          filterMeta.id = 'customIdentifier';
          filterMeta.filterKey = 'Job.customIdentifier';
          filterMeta.filterType = 'string';
          this.setState({ meta: reviewReportRowMeta() });
        }
      }
    }
  };

  getAllReviewReports = async (filter, limit, offset, sortBy, sortOrder) => {
    if (!this.props.user.tenantId) {
      return { items: [], nextToken: '0' };
    }
    let data;
    const sortKey = `${this.props.user.tenantId}_Company_${this.props.user.tenantCompanyId}`;
    if (filter?.subQueryFilters?.[0]?.fieldComparator === 'notExists') {
      filter.subQueryFilters[0].filter.integerFilters = [];
    }
    try {
      data = await this.ListService.getAllReviewReports(
        this.props.user.tenantId,
        sortKey,
        filter,
        limit,
        offset,
        sortBy,
        sortOrder
      );
      return data;
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn(
        'error',
        'Unable to fetch review reports, please try again later',
        error
      );
    }
    return data || [];
  };

  render() {
    const { user } = this.props;
    const { meta } = this.state;

    return (
      <ErrorBoundaries>
        <UserPermission action={PermissionConstants.OBJECT_REVIEWREPORT} I="read">
          <PageHeader pageMapKey="reviewReport" userLocale={user.locale} />
          <ResponsiveTable
            caslKey={PermissionConstants.OBJECT_REVIEWREPORT}
            fullScreen
            noDataMsg="No Review Reports"
            rowMetadata={meta}
            service={this.getAllReviewReports}
            tableName="Review Reports"
          />
        </UserPermission>
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const reduxConnectedReview = connect(mapStateToProps, mapDispatcherToProps)(ReviewReportView);

export default reduxConnectedReview;
