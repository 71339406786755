import { useEffect, useMemo, useRef } from 'react';

import { isNumber } from 'lodash';
import { useSelector } from 'react-redux';

import { filterWithIndex } from 'components/WrapTable';
import { getPaymentTermById } from 'services/API/paymentTerm';

export const usePaymentTerm = () => {
  const paymentTerm = useRef(null);
  const paymentTermId = useSelector(state => state?.pm?.project?.billingCustomer?.paymentTermId);

  useEffect(() => {
    if (paymentTermId) {
      getPaymentTermById(paymentTermId).then(payTerm => {
        paymentTerm.current = payTerm;
      });
    }
  }, [paymentTermId]);

  return paymentTerm.current;
};

export const useFilteredRows = ({ periodTo, payAppLineItems }) => {
  return useMemo(() => {
    return filterWithIndex(payAppLineItems, li => {
      if (!periodTo || !li.dateApproved) return true;
      return periodTo >= li.dateApproved;
    });
  }, [periodTo, payAppLineItems]);
};

export const useRetainageRows = ({
  currentTotalRetainedAmount,
  previouslyBilledRetainage,
  currentBilledRetainage
}) =>
  useMemo(
    () => [
      {
        totalBillableRetainage: currentTotalRetainedAmount,
        contractValue: currentTotalRetainedAmount,
        previouslyBilledRetainage,
        billedRetainage: currentBilledRetainage || 0,
        totalAmountOfBilledRetainage: previouslyBilledRetainage + (currentBilledRetainage || 0)
      }
    ],
    [currentTotalRetainedAmount, previouslyBilledRetainage, currentBilledRetainage]
  );

export const useInitializeCurrentBilledRetainage = ({
  payAppData,
  currentBilledRetainage,
  setCurrentBilledRetainage
}) => {
  const payAppBilledRetainage = payAppData?.billedRetainage;
  useEffect(() => {
    if (!isNumber(currentBilledRetainage) && isNumber(payAppBilledRetainage)) {
      setCurrentBilledRetainage(payAppBilledRetainage);
    }
  }, [payAppBilledRetainage, currentBilledRetainage, setCurrentBilledRetainage]);
};
