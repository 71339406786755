import { formFragment } from 'scenes/Jobs/JobDetail/queries/queryFragment';

const noteFragment = `
id
note
subject
sortOrder
hideFromTechniciansOnMobile
displayLastUpdatedBy
displayLastUpdatedDateTime
lastUpdatedBy
lastUpdatedDate
lastUpdatedDateTime
parentId
`;

const inventoryFragment = `
id
version
addedBy
createdBy
itemName
description
departmentId
unitCost
unitPrice
unitOfMeasure
taxable
sortKey
includeInInvoice
accountingRefId
vendorName
invoiceItemId
priceBookEntryId
productId
invoiceItem {
  id
  parentId
  productId
  name
  description
  unitCost
  markupValue
  unitPrice
  quantity
  source
  invoice {
    id
    invoiceNumber
    status
  }
}
revenueType {
  id
  name
  type
}
quantity
productId
markup
jobCostTypeId
jobCostType {
  name
  id
  type
}
costCodeId
costCode {
  name
  id
}
product {
  name
  id
  description
  costCodeId
  code
  type
  sortKey
  accountingRefId
}
truck {
  id
  name
}
billingStatus
source
`;

const employeeFragment = `
id
name
labourTypeId
labourType {
  id
  name
  jobCostTypeId
  revenueTypeId
  costCodeId
  labourTypeBillingHourTypeProducts {
    items {
      id
      billingHourTypeId
      productId
      product {
        id
        name
      }
    }
  }
}
billingRates {
  items {
    id
    rate
    hourType {
      id
      hourType
    }
  }
}
payrollCosts {
  items {
    id
    cost
    hourType {
      id
      hourType
    }
  }
}
truckEmployeeView {
  items {
    id
    parentEntity {
      ... on Truck {
        id
        name
        isActive
      }
    }
  }
}
`;

const visitFragment = `
id
visitNumber
description
status
sortKey
scheduledFor
reviewStatus
reviewedBy
reviewedUnixDate
techReport
version
departmentName
departmentId
tasks {
    items {
        id
    }
}
primaryTechs {
  items {
    id
    mappedEntity {
      ... on Employee {
        ${employeeFragment}
      }
    }
  }
}
extraTechs {
  items {
    id
    mappedEntity {
      ... on Employee {
        ${employeeFragment}
      }
    }
  }
}
customerSignatures {
  items {
    id
    version
    nameOfSignee
    signatureImageUrl
    includeInInvoice
    capturedBy
    signedDateTime
    createdDate
    createdBy
  }
}
attachments {
  items {
    id
    customFileName
    fileName
    fileUrl
    type
    comment
    addedBy
    addedDateTime
    createdBy
    createdDateTime
    includeInInvoice
    hideFromTechniciansOnMobile
    version
  }
}
assets: visitAssets(
  filter: { propertyAssetId: { ne: "null" } }
) {
  items {
    id
    asset: propertyAsset {
      id
      assetName
      make
      modelNumber
      serialNo
      installDate
      propertyZone
      location
      imageUrl
    }
  }
}
formData {
  items {
    ${formFragment}
  }
}
labourRateLineItems {
  items {
    id
    version
    employeeId
    labourTypeId
    entityType
    labourType {
      id
      name
    }
    version
    createdDate
    createdDateTime
    labourRateBillingHourLines {
      items {
        id
        version
        rate
        version
        hourTypeId
        totalHours
        costCodeId
        revenueTypeId
        timesheetEntryId
        invoiceItemId
        entityType
        billingStatus
        source
        product {
          id
          name
          description
          unitOfMeasure {
            id
            name
          }
        }
      }
    }
  }
}
reviewReports {
  items {
    labourRateLineItems {
      items {
        id
        employeeId
        labourTypeId
        departmentId
        version
        createdDate
        createdDateTime
        labourRateBillingHourLines {
          items {
            id
            rate
            version
            hourTypeId
            totalHours
            costCodeId
            revenueTypeId
          }
        }
      }
    }
  }
}
timesheetEntriesView {
  items {
    id
    cost
    actualTotalDuration
    actualTotalDurationOverride
    reviewStatus
    approvalStatus
    manualStatus
    costCodeId
    jobCostTypeId
    timesheetPeriod {
      parentId
    }
    hourTypeId
    hourType {
      id
      parentId
      hourType
      sortOrder
    }
    billableEntityType
    billableEntityId
    notes {
      items {
        ${noteFragment}
      }
    }
  }
}
inventoryParts {
  items {
    ${inventoryFragment}
  }
}
summaries {
  items {
    id
    summary
    createdBy
    lastUpdatedBy
    lastUpdatedDateTime
    includeInInvoice
    version
  }
}
notes {
  items {
    ${noteFragment}
  }
}
visitPurchaseOrders {
  items {
    id
    poNumber
    status
    vendorName
    description
    createdBy
    totalAmountPreTax
    poType {
      name
    }
    department {
      tagName
    }
    purchaseOrderReceipts {
      items {
        id
        createdBy
        description
        receiptNumber
        freight
        vendor{
          name
        }
        department{
          tagName
        }
        purchaseOrderReceiptLines {
          items {
            unitCost
            quantity
          }
        }
      }
    }
  }
}
nonVisitEvents {
  items {
    id
    name
    description
    assignedEntityType
    assignedEntityId
    plannedStartTimeUTC
    plannedEndTimeUTC
    status
    eventTypeId
    departmentId
    assignedEntityId
    employeeId
    employee {
      ${employeeFragment}
    }
    parentId
    isActive
    entityType
    createdBy
    createdDate
    createdDateTime
    timesheetEntries {
      items {
        id
        reviewStatus
        approvalStatus
        actualTotalDuration
        actualTotalDurationOverride
        cost
        hourTypeId
        hourType {
          id
          hourType
          hourTypeAbbreviation
        }
        userActionType
        billableEntityId
        entryType
        manualStatus
      }
    }
  }
}
`;

const incurredCostFragment = `
      inventoryParts {
        items {
          ${inventoryFragment}
        }
      }
      visits {
        items {
          ${visitFragment}
        }
      }
`;

export default incurredCostFragment;
