import React from 'react';

import { InputAdornment, TextField, withStyles } from '@material-ui/core';

import styles from './styles';

/* This component is designed as a generic control for showing Percentage values in a PageForm */

class PercentageInput extends React.Component {
  state = {
    currentValue: this.props.field.value || 0
  };

  componentDidMount() {
    const { form, field } = this.props;
    if (!field.value) {
      this.setState({ currentValue: 0 });
      setTimeout(() => {
        form.setFieldValue(field.name, 0, false);
      }, 1);
    }
  }

  handleOnChange = event => {
    const currentValue = event.target.value;
    const { field, form } = this.props;

    /* Replace all alpha characters and % symbols before setting form value */
    let formattedVal = '';
    formattedVal = currentValue.replace(/[^\d.-]/g, '');

    /* Setting state variable to display in desired format */
    this.setState({ currentValue: `${formattedVal}` });

    form.setFieldValue(field.name, parseFloat(formattedVal), false);
  };

  render() {
    const { field, form, classes, specialbehaviour, user, theme, ...rest } = this.props;
    const { currentValue } = this.state;

    const helperText =
      form.errors && form.errors[field.name] ? form.errors && form.errors[field.name] : ' ';

    return (
      <TextField
        className={classes.textField}
        error={form.errors && form.errors[field.name] && form.errors[field.name] !== ''}
        fullWidth
        helperText={helperText}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>
        }}
        name={field.name}
        value={currentValue === 0 ? 0 : currentValue}
        variant="filled"
        onChange={this.handleOnChange}
        {...rest}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(PercentageInput);
