import React from 'react';

import { func, shape } from 'prop-types';
import { useParams } from 'react-router-dom';

import SelectFolderFileManager from '@pm/components/FileManagement/SelectFolderFileManager';

const FileManagerFormComponentWrapper = ({ options }) => {
  const { projectId } = useParams();
  const { onSelectFolder } = options;
  return <SelectFolderFileManager projectId={projectId} onSelectFolder={onSelectFolder} />;
};

FileManagerFormComponentWrapper.propTypes = {
  options: shape({
    onSelectFolder: func.isRequired
  }).isRequired
};

export default FileManagerFormComponentWrapper;
