import React from 'react';

import { Typography } from '@material-ui/core';
import { object } from 'prop-types';

import { calculateRemainingTime } from '../../LaborFieldForm.helpers';

import { useStyles } from './LaborTimeRemainingField.styles';

const LaborTimeField = ({ options, form }) => {
  const classes = useStyles();
  const { startTime, endTime, lunchInTime, lunchOutTime, phases } = form.values;

  const remainingTime = calculateRemainingTime({
    startTime,
    endTime,
    lunchInTime,
    lunchOutTime,
    phases
  });

  const timeDiff = endTime > startTime ? endTime - startTime : 0;
  const error =
    startTime && endTime && timeDiff <= 0 ? 'Start time must be before End time.' : undefined;

  const remainingValue = Math.abs(remainingTime) === 0 ? 0 : remainingTime;
  const displayValue = startTime && endTime ? remainingValue : '';

  return (
    <div className={classes.root}>
      <Typography className={classes.label} noWrap variant="caption">
        {options.label}
      </Typography>
      {error ? (
        <Typography className={classes.error}>{error}</Typography>
      ) : (
        <Typography className={classes.content}>{displayValue}</Typography>
      )}
    </div>
  );
};

LaborTimeField.propTypes = {
  form: object.isRequired,
  options: object.isRequired
};

export default LaborTimeField;
