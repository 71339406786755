import React, { useEffect, useState } from 'react';

import { useTheme } from '@material-ui/core/styles';
import GridIcon from '@material-ui/icons/Apps';
import ListIcon from '@material-ui/icons/List';
import { useSelector } from 'react-redux';

import { ProjectsViewMode } from '@pm/ProjectsView/ProjectsView.constants';
import { UserPermission } from 'components';
import PageHeader from 'components/PageHeader';
import Labels from 'meta/labels';
import ErrorBoundaries from 'scenes/Error';
import { PermissionConstants } from 'utils/AppConstants';

import AddProject from '../components/CreateProject/components/AddProject';

import ProjectsGallery from './components/ProjectsGallery';
import ProjectsList from './components/ProjectsList';
import { useStyles } from './ProjectsView.styles';

const ProjectView = () => {
  const styles = useStyles();
  const user = useSelector(store => store.user) || {};

  useEffect(() => {
    document.title = 'BuildOps - Project Management';
    return () => {
      document.title = 'BuildOps';
    };
  });

  const theme = useTheme();
  const [viewMode, setViewMode] = useState(ProjectsViewMode.LIST);
  const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] = useState(false);

  return (
    <ErrorBoundaries>
      <UserPermission action={PermissionConstants.OBJECT_INVOICE} I="read">
        <PageHeader
          breadcrumbsArray={[
            {
              link: '',
              title: 'Projects'
            }
          ]}
          pageMapKey="projects"
          userLocale={user.locale}
        >
          <div css={styles.viewModeIconContainer}>
            <ListIcon
              css={styles.viewModeIcon}
              htmlColor={
                viewMode === ProjectsViewMode.LIST ? theme.palette.support.green.dark : undefined
              }
              onClick={() => setViewMode(ProjectsViewMode.LIST)}
            />
            <GridIcon
              css={styles.viewModeIcon}
              data-testid="project-gallery-icon"
              htmlColor={
                viewMode === ProjectsViewMode.GALLERY ? theme.palette.support.green.dark : undefined
              }
              onClick={() => setViewMode(ProjectsViewMode.GALLERY)}
            />
          </div>
          <AddProject
            addProjectButtonLabel={Labels.addProject[user.locale]}
            isDisabled={false}
            isModalOpenExternally={isCreateProjectModalOpen}
            parentCallback={setIsCreateProjectModalOpen}
            user={user}
          />
        </PageHeader>
      </UserPermission>

      <UserPermission action={PermissionConstants.OBJECT_CUSTOMER} I="read">
        {viewMode === ProjectsViewMode.GALLERY && (
          <ProjectsGallery onAddProject={() => setIsCreateProjectModalOpen(true)} />
        )}
        {viewMode === ProjectsViewMode.LIST && <ProjectsList />}
      </UserPermission>
    </ErrorBoundaries>
  );
};

export default ProjectView;
