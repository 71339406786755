import React, { useEffect, useState } from 'react';

import { Typography } from '@BuildHero/sergeant';
import * as R from 'ramda';
import uuid from 'uuid';

import AddProjectFileStorageSettingButton from './components/AddProjectFileStorageSettingButton';
import AddProjectFileStorageSettingModal from './components/AddProjectFileStorageSettingModal';
import ProjectFileStorageSettingItem from './components/ProjectFileStorageSettingItem';
import {
  parseSettings,
  serializeSettings,
  useGetProjectFileStorageSettings,
  useUpdateProjectFileStorageSettings
} from './ProjectFileStorageSettings.hooks';
import { useStyles } from './ProjectFileStorageSettings.styles';

const ProjectFileStorageSettings = () => {
  const styles = useStyles();
  const [settings, setSettings] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [{ loading, data }] = useGetProjectFileStorageSettings();
  const [, updateSettings] = useUpdateProjectFileStorageSettings();

  const showAddSettingModal = () => setOpenModal(true);
  const closeAddSettingModal = () => setOpenModal(false);

  useEffect(() => {
    if (data.length) {
      setSettings(data);
    }
  }, [data]);

  const handleSettingChange = updatedSetting => {
    const newSettings = settings.map(setting => {
      if (setting.key === updatedSetting.key) {
        return {
          ...setting,
          ...updatedSetting
        };
      }
      return { ...setting, isBeingEdited: false };
    });
    setSettings(newSettings);
  };

  const saveSettings = async settingsToSave => {
    const { data: settingsRaw } = await updateSettings({
      data: serializeSettings(settingsToSave)
    });
    setSettings(parseSettings(settingsRaw));
  };

  const handleSettingsSave = () => saveSettings(settings);

  const handleAddSetting = async newSetting => {
    await saveSettings([
      ...settings,
      {
        ...newSetting,
        key: uuid.v4()
      }
    ]);
    closeAddSettingModal();
  };

  const isSettingBeingEdited = R.any(R.prop('isBeingEdited'))(settings);

  return (
    <div css={styles.container}>
      <div css={styles.settingsContainer}>
        {!loading && !settings.length && (
          <Typography>No File Folders Settings configured yet</Typography>
        )}
        {settings.map(item => (
          <ProjectFileStorageSettingItem
            key={item.key}
            setting={item}
            onSettingChange={handleSettingChange}
            onSettingSave={handleSettingsSave}
          />
        ))}
      </div>
      <AddProjectFileStorageSettingButton
        disabled={isSettingBeingEdited}
        onClick={showAddSettingModal}
      />
      <AddProjectFileStorageSettingModal
        open={openModal}
        onClose={closeAddSettingModal}
        onPrimaryAction={handleAddSetting}
      />
    </div>
  );
};

ProjectFileStorageSettings.propTypes = {};

export default ProjectFileStorageSettings;
