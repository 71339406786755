import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Button,
  ButtonType,
  DateInput,
  Input,
  Modal,
  MoreButton,
  MultiSelect,
  NumberInput,
  Select,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';
import { Box, InputAdornment, Tooltip, useTheme } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import { debounce, groupBy, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { useCompanyTimezone } from '@pm/components/hooks';

import MaintenanceIcon from 'assets/Icons/Maintenance';
import FormLineItem from 'components/Tasks/components/FormLineItem';
import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import { EntityType } from 'utils/constants';

import { ScheduleInterval } from './constants';

import { useCreateTaskTemplate } from './hooks/useCreateTaskTemplate';
import { useDeleteTaskTemplate } from './hooks/useDeleteTaskTemplate';
import { useUpdateTaskTemplate } from './hooks/useUpdateTaskTemplate';

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const options = [
  // { label: 'Daily', value: ScheduleInterval.DAILY },
  { label: 'Weekly', value: ScheduleInterval.WEEKLY },
  { label: 'Every 2 weeks', value: ScheduleInterval.EVERY_2_WEEKS },
  { label: 'Monthly', value: ScheduleInterval.MONTHLY },
  { label: 'Every 2 months', value: ScheduleInterval.EVERY_2_MONTHS },
  { label: 'Quarterly', value: ScheduleInterval.QUARTERLY },
  { label: 'Every 4 months', value: ScheduleInterval.EVERY_4_MONTHS },
  { label: 'Every 6 months', value: ScheduleInterval.EVERY_6_MONTHS },
  { label: 'Annually', value: ScheduleInterval.ANNUALLY }
];

const deleteTaskConfirmMessage = {
  body: 'Are you sure you want to delete this task?',
  title: 'Delete Task',
  buttonLabel: 'Delete Task',
  buttonType: ButtonType.ERROR
};

const getOccurrences = data => {
  const exisitingOccurences = {};
  if (!data?.schedules) return [];
  const groupedExistingOccurrences = groupBy(data.schedules, 'monthOfYear');
  Object.keys(groupedExistingOccurrences).forEach(occ => {
    exisitingOccurences[months[occ - 1]] = groupedExistingOccurrences[occ].length;
  });
  return exisitingOccurences;
};

const isValidTaskTemplate = (data = {}) =>
  data.name && data.startDateTime && data.interval && data.numberOfOccurrences;

export const TaskTemplate = ({ checklistId, data, assetTypeId, disabled, setNewTask }) => {
  const theme = useTheme();

  const company = useSelector(state => state.company);
  const companyForms = company?.forms?.items || [];
  const forms = useMemo(
    () =>
      companyForms.filter(
        f => f.latestPublishedFormDefinitionSortKey && f.associatedEntityType === EntityType.TASK
      ) || [],
    [companyForms]
  );
  const confirmContext = useConfirmModal();

  const [taskTemplate, setTaskTemplate] = useState(data || {});
  const [formsModal, setFormsModal] = useState({ open: false });
  const occurrences = getOccurrences(taskTemplate);

  const [{ data: companyTimezone }] = useCompanyTimezone();

  useEffect(() => {
    setTaskTemplate(data);
  }, [data]);

  const [createTaskTemplate] = useCreateTaskTemplate({
    checklistId,
    assetTypeId,
    onSuccess: (transformedData, snackbarOn) => {
      snackbarOn('success', 'Task added to checklist');
    }
  });

  const [deleteTaskTemplate] = useDeleteTaskTemplate({
    assetTypeId,
    checklistId,
    skip: !assetTypeId || !taskTemplate?.id,
    onSuccess: (transformedData, snackbarOn) => {
      snackbarOn('success', 'Task template deleted');
    }
  });

  const [updateTaskTemplate] = useUpdateTaskTemplate({
    assetTypeId,
    checklistId,
    skip: !assetTypeId || !taskTemplate?.id,
    onSuccess: ({ schedules, numberOfOccurrences }) => {
      setTaskTemplate(prev => ({ ...prev, schedules, numberOfOccurrences }));
    }
  });

  const formOptions = useMemo(
    () =>
      forms.map(form => ({
        id: form.id,
        label: form.name,
        value: { id: form.id }
      })),
    [forms]
  );

  const taskTemplateForms = taskTemplate?.forms || [];
  const selectedFormOptions = useMemo(() => {
    const formIds = taskTemplateForms.map(item => item.id);
    const requiredFormIds = taskTemplateForms
      .filter(f => f.required || f.isRequired)
      .map(item => item.id);
    return formOptions
      .filter(item => formIds.includes(item.id))
      .map(form => ({
        ...form,
        value: { id: form.id, required: requiredFormIds.includes(form.id) }
      }));
  }, [formOptions, taskTemplateForms]);

  const handleFormChange = useCallback(selectedOpts => {
    const selectedTaskTemplateForms = selectedOpts.map(form => ({
      id: form.id,
      isRequired: form.value?.required ?? false
    }));
    setTaskTemplate(prev => ({ ...prev, forms: selectedTaskTemplateForms }));
  }, []);

  const inputStyles = {
    paddingBottom: 16,
    paddingRight: 8,
    backgroundColor: theme.palette.background.default
  };

  const inputCss = {
    backgroundColor: theme.palette.grayscale(100)
  };

  const handleInputChange = useCallback(
    (value, fieldName) => {
      if (value === taskTemplate[fieldName]) return;
      setTaskTemplate(prev => ({ ...prev, [fieldName]: value }));
    },
    [taskTemplate]
  );

  const handleSave = useCallback(
    debounce(
      async dataToUpdate => {
        if (
          !dataToUpdate.name &&
          dataToUpdate.id &&
          (await confirmContext.confirm(deleteTaskConfirmMessage))
        ) {
          return deleteTaskTemplate(taskTemplate);
        }
        const isValid = isValidTaskTemplate(dataToUpdate ?? {});
        if (!isValid) return;
        if (dataToUpdate.id) {
          return updateTaskTemplate(dataToUpdate);
        }

        if (dataToUpdate && !dataToUpdate.id) {
          await createTaskTemplate(dataToUpdate);
        }
      },
      2000,
      { trailing: true }
    ),
    []
  );

  const saveChanges = async () => {
    handleSave.cancel();
    await handleSave(taskTemplate);
  };

  if (taskTemplate === null) return null;

  const isTaskReadyForUpdate = taskTemplate.id && taskTemplate.name;

  return (
    <Box display="flex" flexDirection="row">
      <Box display="flex" flexGrow={2}>
        <Input
          autoFocus={!taskTemplate?.id}
          css={inputCss}
          disabled={disabled}
          endAdornment={
            <InputAdornment position="end">
              <Tooltip arrow title="Add Form(s) to Task">
                <DescriptionOutlinedIcon
                  css={{
                    color: `${
                      taskTemplate?.forms?.length > 0
                        ? theme.palette.support.blue.dark
                        : theme.palette.grayscale(60)
                    }`,
                    cursor: 'pointer'
                  }}
                  fontSize="small"
                  onClick={() => setFormsModal({ open: true })}
                />
              </Tooltip>
            </InputAdornment>
          }
          fullWidth={false}
          label="Task"
          startAdornment={
            taskTemplate?.isSingleJob ? (
              <InputAdornment position="start">
                <Tooltip arrow title="This task will be scheduled as its own maintenance job">
                  <MaintenanceIcon
                    css={{
                      color: theme.palette.text.primary,
                      cursor: 'pointer'
                    }}
                    fontSize="small"
                  />
                </Tooltip>
              </InputAdornment>
            ) : (
              undefined
            )
          }
          style={{ ...inputStyles, minWidth: 168 }}
          value={taskTemplate.name || ''}
          onBlur={saveChanges}
          onChange={e => handleInputChange(e.target.value, 'name')}
        />
        <Input
          css={inputCss}
          disabled={disabled || !isTaskReadyForUpdate}
          endAdornment={
            <InputAdornment css={{ minWidth: 36 }} position="end">
              <Typography variant={TV.S2} weight={TW.MEDIUM}>
                min
              </Typography>
            </InputAdornment>
          }
          fullWidth={false}
          label="Labor Estimate"
          style={{ ...inputStyles, minWidth: 96 }}
          value={taskTemplate.laborEstimate || ''}
          onBlur={saveChanges}
          onChange={e => handleInputChange(Number(e.target.value), 'laborEstimate')}
        />
        <Select
          disabled={disabled || !isTaskReadyForUpdate}
          inputControlProps={{ style: { backgroundColor: theme.palette.background.default } }}
          label="Interval"
          options={options}
          portal
          searchable
          style={{ ...inputStyles, minWidth: 140 }}
          value={options.find(item => item.value === taskTemplate.interval)}
          onChange={async e => {
            handleInputChange(e.value, 'interval');
            if (taskTemplate.id) return updateTaskTemplate({ ...taskTemplate, interval: e.value });
            await createTaskTemplate(taskTemplate);
          }}
        />
        <DateInput
          css={inputCss}
          disabled={disabled || !isTaskReadyForUpdate}
          fullWidth={false}
          label="First Due date"
          style={{ ...inputStyles, minWidth: 140 }}
          value={taskTemplate.startDateTime}
          onBlur={saveChanges}
          onChange={val => handleInputChange(val, 'startDateTime')}
          timezone={companyTimezone}
        />
      </Box>
      <Box display="flex" flexGrow={1} position="relative">
        {months.map(mon => {
          const value = occurrences[mon];
          const bgColor = value
            ? theme.palette.support.blue.light
            : theme.palette.background.default;

          return (
            <NumberInput
              css={{
                border: 'none',
                backgroundColor: bgColor
              }}
              disabled={disabled}
              fullWidth={false}
              inputProps={{ disabled: true, style: { paddingRight: 4, textAlign: 'right' } }}
              key={`MonthValue-${mon}`}
              label={mon}
              placeholder="-"
              style={{
                paddingBottom: 16,
                width: 30,
                marginRight: 8,
                color: value ? theme.palette.support.blue.dark : theme.palette.grayscale(50),
                textAlign: 'center'
              }}
              type="number"
              value={Number.isNaN(value) ? '' : value}
            />
          );
        })}

        <NumberInput
          css={{ ...inputCss, paddingRight: 4 }}
          disabled={disabled || !isTaskReadyForUpdate}
          fullWidth={false}
          inputProps={{ style: { textAlign: 'center' } }}
          label="Total"
          style={{
            paddingBottom: 16,
            width: 48,
            borderLeft: `1px solid ${theme.palette.grayscale(60)}`,
            paddingLeft: 12
          }}
          value={
            isEmpty(occurrences)
              ? 1
              : Object.values(occurrences).reduce((prev, curr) => prev + curr, 0)
          }
          onBlur={saveChanges}
          onChange={val => handleInputChange(Number(val), 'numberOfOccurrences')}
        />
      </Box>
      <Box justifyContent="flex-end">
        <MoreButton
          css={{ padding: 4 }}
          disabled={disabled}
          options={[
            {
              label: 'Delete task',
              icon: p => <DeleteIcon fontSize="small" {...p} />,
              onClick: async () => {
                if (!data.id) {
                  setNewTask();
                } else if (await confirmContext.confirm(deleteTaskConfirmMessage)) {
                  await deleteTaskTemplate(taskTemplate);
                }
              }
            },
            assetTypeId === 'null' && {
              label: 'Individual job',
              icon: p => <MaintenanceIcon fontSize="small" {...p} />,
              onClick: () => {
                setTaskTemplate(oldTemplate => {
                  const newTemplate = {
                    ...oldTemplate,
                    isSingleJob: !oldTemplate.isSingleJob
                  };
                  handleSave(newTemplate);
                  return newTemplate;
                });
              }
            }
          ].filter(Boolean)}
        />
      </Box>

      <Modal
        actions={
          <Button
            disabled={disabled}
            fullWidth
            type={ButtonType.PRIMARY}
            onClick={async () => {
              await saveChanges();
              setFormsModal({ open: false });
            }}
          >
            Save
          </Button>
        }
        open={formsModal.open}
        PaperProps={{ style: { minWidth: 480, minHeight: 316 } }}
        title="Add Forms To Task"
        onClose={() => setFormsModal({ open: false })}
      >
        <MultiSelect
          disabled={disabled}
          label="Forms"
          lineItemComponent={(option, selectedOptions) => (
            <FormLineItem
              formsChange={handleFormChange}
              option={option}
              selectedOptions={selectedOptions}
            />
          )}
          options={formOptions}
          placeholder="Select Forms"
          popperHeight={130}
          selectedOptions={selectedFormOptions}
          showChips
          onChange={handleFormChange}
        />
      </Modal>
    </Box>
  );
};
