import { convertStringToFloat, getBooleanValue } from 'utils';

const constructPayload = ({
  id,
  version,
  itemName,
  reasonForDiscount,
  departmentName,
  productId,
  includeInInvoice,
  accountingRefId
}) => ({
  id,
  version,
  itemName,
  reasonForDiscount,
  includeInInvoice,
  departmentName,
  productId,
  accountingRefId
});

/* Code to include a Discount item to invoice from Review Report */

export const includeDiscount = async (context, event) => {
  const { services, user, discounts } = context;
  const { reviewReportService } = services;
  const { data, checked } = event;

  const formattedData = (({ id, version }) => ({
    id,
    version
  }))(data);
  formattedData.includeInInvoice = checked;

  const queryData = await reviewReportService.updateDiscount(user.tenantId, formattedData);
  if (queryData) {
    const { updateDiscount } = queryData?.data;
    const modifiedDiscounts = discounts.map(discount => {
      let localDiscount = discount;
      if (localDiscount.id === updateDiscount.id) {
        localDiscount = updateDiscount;
      }
      return localDiscount;
    });

    return { discounts: modifiedDiscounts };
  }
};

export const deleteDiscount = async context => {
  const { services, user, modalRecord } = context;
  const { reviewReportService } = services;

  const { data } = await reviewReportService.deleteDiscount(user.tenantId, modalRecord.data.id);
  const { discounts } = context;

  if (data && data.deleteDiscount) {
    const discountIndex = discounts.findIndex(discount => discount.id === modalRecord.data.id);
    discounts.splice(discountIndex, 1);
  }

  return { discounts, modalRecord: {}, modalIndex: '' };
};

export const editDiscount = async (context, event) => {
  const { services, user } = context;
  const { reviewReportService } = services;
  const { saveData } = event;

  const formattedData = constructPayload(event?.saveData);
  formattedData.id = saveData.id;
  formattedData.version = saveData.version;
  formattedData.amount = convertStringToFloat(saveData.amount) || 0.0;
  formattedData.taxable = getBooleanValue(saveData.taxable);

  const queryData = await reviewReportService.updateDiscount(user.tenantId, formattedData);

  const { updateDiscount } = queryData.data;
  const { discounts } = context;

  const modifiedDiscounts = discounts.map(discount => {
    let localDiscount = discount;
    if (localDiscount.id === updateDiscount.id) {
      localDiscount = updateDiscount;
    }
    return localDiscount;
  });

  return { discounts: modifiedDiscounts, modalRecord: {}, modalIndex: '' };
};

export const saveDiscount = async (context, event) => {
  const { services, id, visit } = context;
  const { reviewReportService } = services;
  const { saveData } = event;
  const formattedData = constructPayload(event?.saveData);
  formattedData.amount = convertStringToFloat(saveData.amount) || 0.0;
  formattedData.taxable = getBooleanValue(saveData.taxable);
  formattedData.reviewReportId = id;

  // To support old visits which do not have departments
  if (!saveData?.departmentName && visit.departmentName && visit.departmentName !== '') {
    formattedData.departmentName = visit.departmentName;
  }

  const data = await reviewReportService.addDiscountToReviewReport(
    context.user.tenantId,
    formattedData
  );

  const { addDiscountToReviewReport } = data.data;
  const { discounts } = context;
  if (addDiscountToReviewReport) {
    discounts.push(addDiscountToReviewReport);
    return { discounts, modalRecord: {}, modalIndex: '' };
  }
};
