import CX from 'classnames';
import moment from 'moment';

import {
  afterHourTimeFormat,
  dayIndexLabelMap,
  DEFAULT_ERROR_MSG,
  INVALID_DATE,
  weekDayFullName
} from './constants';

export const getWeekDays = () => {
  const startDate = moment().day(0);
  const dates = [...Array(7).keys()].reduce((acc, count) => {
    const dayString = moment(startDate)
      .add(count, 'days')
      .format('dddd');
    return [...acc, { dayIndex: count, dayString }];
  }, []);

  return dates;
};

export const getTextFieldProps = classes => ({
  placeholder: '-',
  InputProps: {
    className: classes.input,
    disableUnderline: true
  }
});

export const getAutoCompleteProps = classes => ({
  inputProps: {
    textFieldClassName: classes.selectionPlaceholder,
    inputClassNames: classes.selectionInput,
    className: CX(classes.selectionWrapper),
    disableUnderline: true
  }
});

export const getKeyboardTimePickerProps = classes => ({
  mask: '__:__ _M',
  format: afterHourTimeFormat,
  placeholder: '-',
  className: classes.timeInput,
  InputProps: {
    classes: {
      root: CX(classes.timeInputRoot),
      focused: classes.timeInputFocused
    },
    startAdornment: null,
    focused: classes.timeInputFocused,
    disableUnderline: true
  }
});

export const getSelectedText = (values, selected = []) => {
  const list = values.reduce((labels, { label, value }) => {
    if (selected.includes(value)) {
      labels.push(label || '-');
    }
    return labels;
  }, []);
  return list.join(', ');
};

export const getDayValue = dayIndex => dayIndexLabelMap[dayIndex];

export const getHourSettingValue = (hourSettings = [], dayIndex) => {
  const day = getDayValue(dayIndex);
  if (!day) return {};
  const value = hourSettings.find(({ startDay }) => startDay === day);
  return value || { startDay: day, endDay: day, endTime: null, startTime: null, technicianIds: [] };
};

export const getEmployeeIds = afterHoursTimings =>
  afterHoursTimings.reduce((employeeIds, { technicianIds = [] }) => {
    return [...employeeIds, ...technicianIds];
  }, []);

export const extractAfterHourSettings = (tenantSetting = {}) => {
  const { settings } = tenantSetting;
  if (!settings) return [];
  const afterHourSettings = JSON.parse(settings);
  return afterHourSettings;
};

export const getAfterHoursTimings = (hourTimingsSettings = []) => {
  const weekDays = getWeekDays();
  const values = weekDays.map(({ dayIndex }) => getHourSettingValue(hourTimingsSettings, dayIndex));
  return values;
};

export const validateOverlap = (startTime, endTime) => {
  const start = moment(startTime, afterHourTimeFormat);
  const end = moment(endTime, afterHourTimeFormat);
  return start.isAfter(end);
};

export const timeValidation = (value, timeField) => {
  const { startTime, endTime } = value;
  if (!startTime || !endTime) {
    return '';
  }
  const message = {
    startTime: 'Invalid Time',
    endTime: 'Invalid Time'
  };

  if (
    startTime === INVALID_DATE ||
    endTime === INVALID_DATE ||
    (startTime && startTime === endTime) ||
    validateOverlap(startTime, endTime)
  ) {
    return message[timeField];
  }
  return '';
};

export const validateHourSettings = (afterHourSettings, snackbarOn) => {
  let isScheduleEmpty = true;

  const { afterHoursTimings, name } = afterHourSettings;
  let errorMsg = DEFAULT_ERROR_MSG;

  if (!name) {
    errorMsg += '\n Missing Schedule Name';
  }

  afterHoursTimings.forEach(day => {
    if ((isScheduleEmpty && day.startTime !== null) || day.endTime !== null) {
      isScheduleEmpty = false;
    }
    if (day.startTime === INVALID_DATE || day.endTime === INVALID_DATE) {
      errorMsg += `\n Invalid times for ${weekDayFullName[day.startDay]}`;
    } else if (day.startTime && day.startTime === day.endTime) {
      errorMsg += `\n Invalid times for ${weekDayFullName[day.startDay]}`;
    } else if (validateOverlap(day.startTime, day.endTime)) {
      errorMsg += `\n Time overlap for ${weekDayFullName[day.startDay]}`;
    }
  });

  if (isScheduleEmpty) {
    errorMsg += `\n Schedule is empty`;
  }

  if (errorMsg.length > DEFAULT_ERROR_MSG.length) {
    snackbarOn('error', errorMsg);
    return false;
  }
  return true;
};
