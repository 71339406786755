import React from 'react';

import Divider from '@material-ui/core/Divider';

import { isTenantSettingEnabled } from 'utils';

import ClockinBreakEntries from './ClockinBreakEntries';
import Signature from './Signature';

const styles = {
  fieldRow: {
    display: 'flex',
    width: '100%',
    marginBottom: '1em',
    marginTop: '1em'
  },
  flexBox: {
    flex: '1 1 0'
  }
};

const DayCardFields = ({ employeeId, startDayCompanyTZ, timezone }) => {
  const showClockinTimes = isTenantSettingEnabled('manualDailyShift');
  const showBreakTimes = isTenantSettingEnabled('explicitBreakTimes');
  const showSignature = isTenantSettingEnabled('requireTechSignOff');

  if (!showClockinTimes && !showBreakTimes && !showSignature) return null;

  return (
    <>
      <div css={styles.fieldRow}>
        {(showClockinTimes || showBreakTimes) && (
          <div css={styles.flexBox}>
            <ClockinBreakEntries
              employeeId={employeeId}
              showBreakTimes={showBreakTimes}
              showClockinTimes={showClockinTimes}
              startDayCompanyTZ={startDayCompanyTZ}
              timezone={timezone}
            />
          </div>
        )}
        {showSignature && (
          <div css={styles.flexBox}>
            <Signature
              employeeId={employeeId}
              startDayCompanyTZ={startDayCompanyTZ}
              timezone={timezone}
            />
          </div>
        )}
      </div>
      <Divider />
    </>
  );
};

export default DayCardFields;
