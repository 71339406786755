import AmplifyService from 'services/AmplifyService';
import updateAttachmentMutation from 'services/core/graphql/common/mutations/updateAttachment';
import deleteAttachmentMutation from 'services/core/graphql/review-report/mutations/DeleteAttachmentById';
import { Logger } from 'services/Logger';
import { logErrorWithCallback } from 'utils';

import { CREWS, SERVICE_AGREEMENTS_SETTINGS } from '../../../components/Visit/components/queries';

import { AGREEMENT_ACTIONS } from './constants';
import {
  addAttachmentToServiceAgreementMutation,
  getActiveServiceAgreementsForCustomerQuery,
  getRateCardQuery,
  getServiceAgreementByIdQuery,
  getServiceAgreementsQuery,
  transitionServiceAgreementMutation,
  updateServiceAgreementMutation
} from './queries';

export const getRateCard = async (serviceAgreementId, user) => {
  const appsyncClient = await AmplifyService.appSyncClient();
  let response;
  try {
    response = await appsyncClient.client.query({
      query: getRateCardQuery,
      variables: {
        partitionKey: user.tenantId,
        id: serviceAgreementId
      }
    });
  } catch (error) {
    Logger.error(error);
  }
  return response?.data?.serviceAgreement;
};

export const getServiceAgreements = async (companyId, customerId, sorting, pagination) => {
  const appsyncClient = await AmplifyService.appSyncClient();
  let response;
  try {
    response = await appsyncClient.client.query({
      query: getServiceAgreementsQuery,
      variables: {
        companyId,
        customerId,
        sorting,
        pagination
      }
    });
  } catch (error) {
    Logger.error(error);
  }
  return response?.data?.serviceAgreements?.items;
};

export const getActiveServiceAgreementsForCustomer = async (tenantId, companyId, customerId) => {
  const appsyncClient = await AmplifyService.appSyncClient();
  let response;
  try {
    response = await appsyncClient.client.query({
      query: getActiveServiceAgreementsForCustomerQuery,
      variables: {
        partitionKey: tenantId,
        sortKey: `${tenantId}_Company_${companyId}`,
        customerId
      }
    });
  } catch (error) {
    Logger.error(error);
  }
  return response?.data?.getCompany?.serviceAgreements?.items || [];
};

export const getServiceAgreementById = async (serviceAgreementId, user) => {
  const appsyncClient = await AmplifyService.appSyncClient();
  let response;
  try {
    response = await appsyncClient.client.query({
      query: getServiceAgreementByIdQuery,
      variables: {
        partitionKey: user.tenantId,
        id: serviceAgreementId
      }
    });
  } catch (error) {
    Logger.error(error);
  }
  return response?.data?.serviceAgreement;
};

export const transitionServiceAgreement = async (
  action,
  data,
  partitionKey,
  snackbar = () => {}
) => {
  let localAction = action;
  const appsyncClient = await AmplifyService.appSyncClient();
  let updatedServiceAgreement;
  try {
    const response = await appsyncClient.client.mutate({
      mutation: transitionServiceAgreementMutation,
      variables: {
        partitionKey,
        data,
        action: localAction
      }
    });
    updatedServiceAgreement = response?.data?.serviceAgreementTransition;
    // massaging only for success message
    if (localAction === AGREEMENT_ACTIONS.UPDATE_INVOICE_SETTINGS) {
      localAction = AGREEMENT_ACTIONS.UPDATE;
    }
    const verb =
      localAction === AGREEMENT_ACTIONS.CANCEL ? 'cancelled' : `${localAction.toLowerCase()}d`;
    snackbar(
      'success',
      `Succesfully ${verb} agreement - ${data.agreementName ||
        updatedServiceAgreement.agreementName ||
        ''}`
    );
  } catch (error) {
    logErrorWithCallback(error, snackbar, `Unable to ${action?.toLowerCase()} the agreement`);
    Logger.error(error);
  }
  return updatedServiceAgreement;
};

export const updateServiceAgreement = async (data, snackbar) => {
  const appsyncClient = await AmplifyService.appSyncClient();
  let updatedServiceAgreement;
  try {
    const response = await appsyncClient.client.mutate({
      mutation: updateServiceAgreementMutation,
      variables: {
        data
      }
    });
    updatedServiceAgreement = response?.data?.updateServiceAgreement;
  } catch (error) {
    logErrorWithCallback(error, snackbar, 'Unable to update the agreement');
    Logger.error(error);
  }
  return updatedServiceAgreement;
};

export const addAttachmentToServiceAgreement = async (serviceAgreementId, data, snackbar) => {
  const appsyncClient = await AmplifyService.appSyncClient();
  let uploadedAttachment;
  try {
    const response = await appsyncClient.client.mutate({
      mutation: addAttachmentToServiceAgreementMutation,
      variables: {
        data: {
          serviceAgreementId,
          attachments: [
            {
              id: data.id,
              fileName: data.fileName,
              fileUrl: data.fileUrl,
              fileSize: data.fileSize,
              customFileName: data.customFileName,
              comment: data.comment,
              version: data.version,
              type: data.type,
              isUploaded: data.isUploaded
            }
          ]
        }
      }
    });
    uploadedAttachment = response?.data?.addAttachmentsToServiceAgreement;
    snackbar('success', `Succesfully updated agreement ${data.agreementName || ''}`);
  } catch (error) {
    logErrorWithCallback(error, snackbar, `Unable to upload attachment`);
    Logger.error(error);
  }
  return uploadedAttachment;
};

export const updateAttachment = async (data, snackbar) => {
  const appsyncClient = await AmplifyService.appSyncClient();
  let uploadedAttachment;
  try {
    const response = await appsyncClient.client.mutate({
      mutation: updateAttachmentMutation,
      variables: {
        data
      }
    });
    uploadedAttachment = response?.data?.updateAttachment;
    snackbar('success', `Succesfully updated agreement ${data.agreementName || ''}`);
  } catch (error) {
    logErrorWithCallback(error, snackbar, `Unable to upload attachment`);
    Logger.error(error);
  }
  return uploadedAttachment;
};

export const deleteAttachment = async (id, snackbar) => {
  const appsyncClient = await AmplifyService.appSyncClient();
  let deletedAttachment;
  try {
    const response = await appsyncClient.client.mutate({
      mutation: deleteAttachmentMutation,
      variables: {
        id
      }
    });
    deletedAttachment = response?.data?.deleteAttachmentById;
    snackbar('success', `Succesfully deleted attachment`);
  } catch (error) {
    logErrorWithCallback(error, snackbar, `Unable to delete attachment`);
    Logger.error(error);
  }
  return deletedAttachment;
};

export const getCrews = async () => {
  const appsyncClient = await AmplifyService.appSyncClient();
  let response;
  try {
    response = await appsyncClient.client.query({
      query: CREWS
    });
  } catch (error) {
    Logger.error('Error fetching crews:', error);
  }
  return response?.data?.crews;
};

export const getServiceAgreementSettings = async user => {
  const appsyncClient = await AmplifyService.appSyncClient();
  let response;
  try {
    response = await appsyncClient.client.query({
      query: SERVICE_AGREEMENTS_SETTINGS,
      variables: {
        partitionKey: user.tenantId,
        sortKey: `${user.tenantId}_Company_${user.tenantCompanyId}`
      }
    });
  } catch (error) {
    Logger.error('Error fetching service agreements settings:', error);
  }
  return response?.data?.getCompany?.companySettings?.items[0];
};

export default getServiceAgreementById;
