import React, { useState } from 'react';

import { XGrid } from 'components';

import { PROJECT_COLUMNS } from './ProjectsList.columns';
import { GET_PROJECTS } from './ProjectsList.queries';

const ProjectsList = () => {
  const [pageSize, setPageSize] = useState(25);
  return (
    <XGrid
      columns={PROJECT_COLUMNS}
      defaultSort={[{ field: 'number', sort: 'asc' }]}
      entityTypeName="Report"
      pageSize={pageSize}
      query={GET_PROJECTS}
      tableName="ProjectsXGrid"
      onPageSizeChange={setPageSize}
    />
  );
};

export default ProjectsList;
