import React from 'react';

import { Chip } from '@material-ui/core';
import { func, string } from 'prop-types';

import { TimeAndMaterialsItemsTypes } from '../../../../CreateInvoice.constants';

import { useStyles } from './ItemsTypeSelect.styles';

const ItemsTypeSelect = ({ selectedItemsType, onSelect }) => {
  const styles = useStyles();
  return (
    <div css={styles.itemsTypeChipsContainer}>
      {[
        { type: TimeAndMaterialsItemsTypes.ENTIRE_JOB, label: 'Entire Job' },
        { type: TimeAndMaterialsItemsTypes.VISITS, label: 'Visits' },
        { type: TimeAndMaterialsItemsTypes.LABOR_ITEMS, label: 'Labor' },
        { type: TimeAndMaterialsItemsTypes.INVENTORY_ITEMS, label: 'Inventory Items' },
        { type: TimeAndMaterialsItemsTypes.PURCHASED_ITEMS, label: 'Purchased Items' }
      ].map(({ type, label }) => (
        <Chip
          css={type === selectedItemsType ? styles.selectedItemsTypeChip : styles.itemsTypeChip}
          label={label}
          onClick={() => onSelect(type)}
        />
      ))}
    </div>
  );
};

ItemsTypeSelect.propTypes = {
  selectedItemsType: string.isRequired,
  onSelect: func.isRequired
};

export default ItemsTypeSelect;
