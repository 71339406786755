import React from 'react';

import { ThemeProvider, TV, Typography, TypographyWeight } from '@BuildHero/sergeant';
import isFunction from 'lodash/isFunction';
import { func, node, string } from 'prop-types';

import { useStyles } from './ReportSection.styles';

const ReportSection = ({ title, renderTitle, children }) => {
  const styles = useStyles();
  return (
    <ThemeProvider>
      <div css={styles.container}>
        {isFunction(renderTitle) ? (
          renderTitle()
        ) : (
          <Typography style={{ marginBottom: 8 }} variant={TV.L} weight={TypographyWeight.BOLD}>
            {title}
          </Typography>
        )}
        <div>{children}</div>
      </div>
    </ThemeProvider>
  );
};

ReportSection.propTypes = {
  title: string,
  renderTitle: func,
  children: node.isRequired
};

ReportSection.defaultProps = {
  title: '',
  renderTitle: undefined
};

export default ReportSection;
