import { CompanyService } from 'services/core';
import { logErrorWithCallback } from 'utils';

import { validateHourSettings } from './helpers';

export const saveHourSetting = async ({
  user,
  afterHourSetting,
  successCallback,
  snackbarOn,
  stopSpinner
}) => {
  const isHoursSettingsValidated = validateHourSettings(afterHourSetting, snackbarOn);
  if (isHoursSettingsValidated) {
    try {
      const service = new CompanyService();
      const { tenantId, tenantCompanyId } = user;
      const payload = {
        companyId: tenantCompanyId,
        afterHoursSettings: [afterHourSetting]
      };
      const { data } = await service.addAfterHoursSettingsToCompany(tenantId, payload);
      const id = data?.saveAfterHoursSettingsToCompany?.id;
      successCallback({ ...afterHourSetting, id });
      snackbarOn('success', 'Settings Saved SuccessFully');
    } catch (error) {
      logErrorWithCallback(error, snackbarOn, 'Failed to save the settings');
    }
  }
  stopSpinner();
};

export const fetchAllDepartmentsAndEmployees = async ({ user, snackbarOn }) => {
  const sortKey = `${user.tenantId}_Company_${user.tenantCompanyId}`;
  try {
    const service = new CompanyService();
    const { data } = await service.getAllDepartmentsAndEmployees(user.tenantId, sortKey);
    if (data?.getCompany) {
      const fetchedData = {
        departmentItems: data.getCompany.departments?.items || [],
        employeeItems: data.getCompany.employees?.items || []
      };
      return fetchedData;
    }
  } catch (error) {
    snackbarOn(
      'error',
      `Unable to fetch the company's departments and employees, please try again later`,
      error
    );
  }
};
