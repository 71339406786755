import { gql } from '@apollo/client/core';

import { COMPANY_SETTING_TYPE } from 'constants/common';
import useExtendedMutation from 'customHooks/useExtendedMutation';
import { logErrorWithCallback } from 'utils';

import { convertSettingsJSONtoString, convertStringToSettingsJSON } from '../utils';

const ADD_COMPANY_SETTING = gql`
  mutation AddCompanySetting($partitionKey: String!, $data: AddCompanySettingsToCompanyInput!) {
    addCompanySettingsToCompany(partitionKey: $partitionKey, data: $data) {
      id
      type
      version
      isActive
      settings
    }
  }
`;

const serializer = ({ partitionKey, companyId, formData }) => ({
  variables: {
    partitionKey,
    data: {
      companyId,
      companySettings: [
        {
          type: COMPANY_SETTING_TYPE.PAYROLL,
          settings: convertSettingsJSONtoString(formData)
        }
      ]
    }
  }
});

const transform = ({ addCompanySettingsToCompany }) => {
  return convertStringToSettingsJSON(addCompanySettingsToCompany);
};

export const useAddPayrollSettings = () => {
  return useExtendedMutation(ADD_COMPANY_SETTING, {
    serializer,
    transform,
    onSuccess: (data, snackbarOn) => snackbarOn('success', 'Successfully added payroll settings'),
    onError: (err, snackbarOn) =>
      logErrorWithCallback(err, snackbarOn, 'Unable to add settings, please try again later')
  });
};
