import React, { useEffect, useMemo, useState } from 'react';

import { ApolloProvider as Apollo3Provider } from '@apollo/client';
import { Auth, Hub } from 'aws-amplify';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { getHttpClient } from 'client';
import withTracker from 'components/WithTracker';
import { signOut } from 'redux/actions/authActions';
import ErrorBoundaries from 'scenes/Error';
import { checkPermission } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';
import { makeMountAware } from 'utils/makeMountAware';

const checkWebAccess = cognitoUser =>
  checkPermission('allow', PermissionConstants.FUNCTIONS_WEB, {
    cognitoRole: cognitoUser?.attributes?.['custom:roles'],
    appPermissionRules: cognitoUser?.signInUserSession?.idToken?.payload?.rules,
    tenantId: cognitoUser?.attributes?.['custom:tenantId']
  });

const PrivateRoute = ({ history, auth, systemAdmin, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  const [userId, setUserId] = useState();

  const authenticateOnMount = () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        const webAccessEnabled = checkWebAccess(user);
        if (!webAccessEnabled) return history.push('/noaccess');
        setIsAuthenticated(true);
        setIsSystemAdmin(user.attributes['custom:role'] === 'SystemAdmin');
        setUserId(user.username);
        Hub.dispatch('auth', { event: 'signIn', data: user }, 'Auth');
        setLoaded(true);
        return user;
      })
      .catch(async () => {
        auth.store.dispatch(signOut());
        setIsAuthenticated(false);
        setIsSystemAdmin(false);
        history.push('/signin');
        return null;
      });
  };

  const authenticateOnUnmount = () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        const webAccessEnabled = checkWebAccess(user);
        if (!webAccessEnabled) return history.push('/noaccess');
        return user;
      })
      .catch(async () => {
        history.push('/signin');
        return null;
      });
  };

  useEffect(() => {
    authenticateOnMount();

    const unlisten = history.listen(() => {
      authenticateOnUnmount();
    });

    return () => {
      unlisten();
    };
    // do not want to re-run this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { component: CustomComponent, children, ...rest } = props;

  const TrackedComponent = useMemo(
    () => CustomComponent && withTracker(CustomComponent, { userId }),
    [CustomComponent, userId]
  );

  if (!loaded) return null;

  if (systemAdmin && !isSystemAdmin) {
    // route is only for system admins
    return <Redirect to={{ pathname: '/' }} />;
  }

  if (TrackedComponent) {
    const client = getHttpClient();

    return (
      <Route
        {...rest}
        render={renderProps => {
          if (isAuthenticated) {
            return (
              <ErrorBoundaries>
                <Apollo3Provider client={client}>
                  <TrackedComponent
                    {...renderProps}
                    auth={auth}
                    {...rest}
                    client={client}
                    key={props?.location?.key}
                  />
                </Apollo3Provider>
              </ErrorBoundaries>
            );
          }
          return <Redirect to={{ pathname: '/signin' }} />;
        }}
      />
    );
  }

  return (
    <Route {...rest}>
      {isAuthenticated ? (
        <ErrorBoundaries>
          <Apollo3Provider client={getHttpClient()}>{children}</Apollo3Provider>
        </ErrorBoundaries>
      ) : (
        <Redirect to={{ pathname: '/signin' }} />
      )}
    </Route>
  );
};

export default compose(makeMountAware, withRouter)(PrivateRoute);
