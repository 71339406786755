import React, { useCallback, useState } from 'react';

import {
  Button,
  ButtonType,
  FieldType,
  formatValue,
  MoreButton,
  ThemeProvider,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { CollapsibleSection } from 'components';
import { UserPermission } from 'components/AppPermissions';
import { ColumnType } from 'components/XGrid/columnTypes';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { checkPermission } from 'utils';
import { PermissionConstants, TaskConstants, TaskListType } from 'utils/AppConstants';
import { Mode } from 'utils/constants';

import AddTaskToPropertyModal from './AddEditTaskOnPropertyModal';
import AddTasksToJobModal from './AddTaskToJob';
import { getCustomerPropertyTasksCountByType } from './TaskQuery.gql';
import TaskTable from './TaskTable';

const associatedJobColumn = {
  field: 'associatedWork',
  headerName: 'Associated Work',
  width: 250,
  ...ColumnType.LINK,
  renderCell: ({ api, row }) => {
    const job = row?.jobs?.[0];
    if (!job) return '-';

    const linkValue = {
      label: `${job.jobTypeInternal} ${job.customIdentifier || job.jobNumber}`,
      to: `/${job.jobTypeInternal.toLowerCase()}/view/${job.jobNumber}`
    };
    return formatValue[FieldType.LINK](linkValue, {
      containerProps: {
        style: { flexWrap: 'unset', gap: '8px' }
      },
      linkProps: { testingid: `record-associatedWork-${api.getRowIndex()}` }
    });
  }
};

const excludeCompletedStatusFilter = {
  items: {
    columnField: 'status',
    operatorValue: 'notContains',
    value: TaskConstants.COMPLETED
  }
};

export const TaskTab = ({ customerPropertyId, isActive }) => {
  const user = useSelector(s => s.user);
  const [fetchOpenTask, toggleFetchOpenTask] = useState(false);
  const [fetchJobTask, toggleFetchJobTask] = useState(false);
  const [addTasksModalOpen, setAddTasksModalOpen] = useState(false);
  const [selectedTaskIds, setselectedTaskIds] = React.useState();
  const [addTasksToJobModalOpen, setAddTasksToJobModalOpen] = useState(false);
  const dispatch = useDispatch();
  const snackbar = useCallback((...args) => dispatch(snackbarOn(...args)), [dispatch]);

  const { data: completedTaskCount } = useExtendedQuery(getCustomerPropertyTasksCountByType, {
    variables: {
      customerPropertyId,
      listType: TaskListType.COMPLETED_TASKS
    },
    transform: data => data?.getTasksByPropertyAndListType?.rowCount,
    fetchPolicy: 'no-cache'
  });

  const getCollapsibleSectionTitle = collapsed =>
    collapsed
      ? `Show Completed Tasks (${completedTaskCount ?? 'Loading...'})`
      : `Hide Completed Tasks (${completedTaskCount ?? 'Loading...'})`;

  const associatedWorkColumn = {
    field: 'associatedWork',
    headerName: 'Associated Work',
    width: 250,
    ...ColumnType.LINK,
    renderCell: ({ api, row }) => {
      const job = row?.jobs?.[0];
      const quote = row?.quote;
      if (!job && !quote) return '';

      const jobLinkValue = job && {
        label: `${job.jobTypeInternal} ${job.customIdentifier || job.jobNumber}`,
        to: `/${job.jobTypeInternal.toLowerCase()}/view/${job.jobNumber}`
      };

      const quoteLinkValue = quote && {
        label: `Quote ${quote.customIdentifier || quote.quoteNumber}`,
        to: `/quote/${quote.id}`
      };
      return formatValue[FieldType.LINK](jobLinkValue || quoteLinkValue, {
        containerProps: {
          style: { flexWrap: 'unset', gap: '8px' }
        },
        linkProps: { testingid: `record-associatedWork-${api.getRowIndex()}` }
      });
    }
  };

  const hasPermissionToEditTask = checkPermission('edit', PermissionConstants.OBJECT_TASK, user);

  return (
    <ErrorBoundaries>
      <Box>
        <Box
          alignItems="flex-end"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginBottom={1}
        >
          <ThemeProvider>
            <Typography variant={TV.BASE} weight={TW.BOLD}>
              Ready for Job or Maintenance
            </Typography>

            <Box alignItems="center" display="flex" flexDirection="row">
              <UserPermission action={PermissionConstants.OBJECT_TASK} I="create">
                <Button
                  disabled={!isActive}
                  size="small"
                  type={ButtonType.SECONDARY}
                  onClick={() => setAddTasksModalOpen(true)}
                >
                  Add Task(s)
                </Button>
              </UserPermission>

              {isActive && (
                <UserPermission action={PermissionConstants.OBJECT_JOB} I="create">
                  <MoreButton
                    options={[
                      {
                        label: 'Add to Job/Maintenance',
                        onClick: () => {
                          if (!selectedTaskIds || selectedTaskIds.length === 0) {
                            return snackbar(
                              'error',
                              'Please select the tasks to add to a Job or Maintenance'
                            );
                          }
                          setAddTasksToJobModalOpen(true);
                        }
                      }
                    ]}
                    style={{ padding: 8, margin: '0 4px' }}
                  />
                </UserPermission>
              )}
            </Box>
          </ThemeProvider>
        </Box>
        <Box height={300}>
          <TaskTable
            customerPropertyId={customerPropertyId}
            isActive={isActive && hasPermissionToEditTask}
            key={`ReadyForJob${fetchOpenTask}`}
            listType={TaskListType.READY_FOR_JOB}
            selectionModel={selectedTaskIds}
            onSelectionModelChange={newTaskArray => {
              setselectedTaskIds(newTaskArray);
            }}
          />
        </Box>
        <Box height={300}>
          <Box
            alignContent=""
            alignItems="flex-end"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginBottom={1}
          >
            <ThemeProvider>
              <Typography variant={TV.BASE} weight={TW.BOLD}>
                Added to Job or Maintenance
              </Typography>
            </ThemeProvider>
          </Box>
          <TaskTable
            associatedWorkXgridColumn={associatedJobColumn}
            customerPropertyId={customerPropertyId}
            filter={excludeCompletedStatusFilter}
            includeJob
            isActive={isActive && hasPermissionToEditTask}
            key={`AddedToJob${fetchJobTask}`}
            listType={TaskListType.ADDED_TO_JOB}
          />
        </Box>
        <Box height={300}>
          <CollapsibleSection
            defaultState
            getDynamicTitle={getCollapsibleSectionTitle}
            title=""
            useDynamicTitle
          >
            <TaskTable
              associatedWorkXgridColumn={associatedWorkColumn}
              customerPropertyId={customerPropertyId}
              includeJob
              includeQuote
              isActive={isActive}
              key="completedTasks"
              listType={TaskListType.COMPLETED_TASKS}
            />
          </CollapsibleSection>

          <AddTaskToPropertyModal
            key={`AddToProperty${fetchOpenTask}`}
            open={addTasksModalOpen}
            propertyId={customerPropertyId}
            refetchTasks={() => toggleFetchOpenTask(prevState => !prevState)}
            onClose={() => setAddTasksModalOpen(false)} // to reset the form
          />
          <AddTasksToJobModal
            customerPropertyId={customerPropertyId}
            key={`AddToJob${fetchJobTask}`}
            mode={Mode.ADD}
            open={addTasksToJobModalOpen}
            refetchTasks={() => {
              toggleFetchOpenTask(prevState => !prevState);
              toggleFetchJobTask(prevState => !prevState);
            }}
            selectedTaskIds={selectedTaskIds}
            onClose={() => setAddTasksToJobModalOpen(false)} // to reset the form
          />
        </Box>
      </Box>
    </ErrorBoundaries>
  );
};

export default TaskTab;
