import React, { Fragment, useState } from 'react';

import { Badge, Button, Grid, Tooltip, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PropTypes from 'prop-types';
import { Configure } from 'react-instantsearch-dom';

import styles from './styles';

const StyledBadge = withStyles(theme => ({
  badge: {
    top: '50%',
    right: -18,
    // The border color match the background color.
    border: `2px solid ${
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    }`
  }
}))(Badge);

const withTooltip = (WrappedComponent, tooltipText) => (
  <Tooltip title={tooltipText}>{WrappedComponent}</Tooltip>
);

const Label = React.forwardRef((props, ref) => {
  const { label, className, filterLabels, ...rest } = props;
  return (
    <Typography
      {...rest}
      className={className}
      key={`refineText-${label}`}
      ref={ref}
      variant="body2"
    >
      {filterLabels[label?.toLowerCase()] || label}
    </Typography>
  );
});

Label.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  filterLabels: PropTypes.object.isRequired
};

const BadgeText = React.forwardRef((props, ref) => {
  if (props?.label?.length > 15) {
    return withTooltip(<Label {...props} ref={ref} />, props.label);
  }
  return <Label {...props} ref={ref} />;
});

BadgeText.propTypes = {
  label: PropTypes.string.isRequired
};

export const RefinementList = props => {
  const [showMoreState, setShowMoreState] = useState(false);

  const [filterDeactivated, setFilterDeactivate] = useState(
    'NOT isActive:false AND NOT status:inactive'
  );

  const checked = item => {
    const { attribute, currentRefinement } = props;
    if (attribute === 'configuredeactivated') {
      return filterDeactivated === '';
    }
    return item.isRefined || currentRefinement.includes(item.label);
  };

  const configure = item => {
    const { refine, attribute } = props;
    if (attribute === 'configuredeactivated') {
      setFilterDeactivate(
        filterDeactivated === '' ? 'NOT isActive:false AND NOT status:inactive' : ''
      );
    } else refine(item.value);
  };

  const { attribute, showMore, limit, classes } = props;
  let { items } = props;
  const filterLabels = {
    customerproperty: 'Property',
    customerrep: 'Reps',
    purchaseordernumber: 'PO',
    companyaddress: 'Address',
    systementitymap: 'Visit',
    configuredeactivated: 'Show Deactivated'
  };

  // If specifying filters for <configure>, ignore other items besides value of attribute.
  if (attribute === 'configuredeactivated') {
    items = [
      {
        label: attribute,
        isRefined: false
      }
    ];
  }

  return (
    <Grid className={classes.refinementContainer} item lg={12} md={12} sm={12} xl={12} xs={12}>
      <Configure filters={filterDeactivated} />
      {items.map((item, index) => (
        <Grid
          alignItems="center"
          container
          justify="flex-start"
          key={`refineListContainer-${item.label}`}
        >
          {showMore && (index < limit || showMoreState) && (
            <Fragment key={`refineFragContainer-${item.label}`}>
              <Grid item key={`refineGridItem-${item.label}`} xs={9}>
                <StyledBadge badgeContent={item.count} className={classes.badge} color="secondary">
                  <BadgeText
                    className={classes.filterLabels}
                    filterLabels={filterLabels}
                    label={item?.label}
                  />
                </StyledBadge>
              </Grid>
              <Grid item key={`refineGridItemCheckbox-${item.label}`} xs={3}>
                <Checkbox
                  checked={checked(item)}
                  checkedIcon={
                    <CheckBoxIcon
                      className={classes.checkboxInList}
                      fontSize="small"
                      key={`refineCheckboxCheckedIcon-${item.label}`}
                    />
                  }
                  className={classes.checkboxGroup}
                  icon={
                    <CheckBoxOutlineBlankIcon
                      className={classes.checkboxInList}
                      fontSize="small"
                      key={`refineCheckboxBlankIcon-${item.label}`}
                    />
                  }
                  key={`refineCheckbox-${item.label}`}
                  value={item.label}
                  onClick={event => {
                    event.preventDefault();
                    configure(item);
                  }}
                />
              </Grid>
            </Fragment>
          )}
        </Grid>
      ))}
      {showMore && items?.length - 1 >= limit && (
        <Grid item xs={12}>
          {!showMoreState && (
            <Button
              className={classes.showMoreBtn}
              color="primary"
              onClick={() => setShowMoreState(true)}
            >
              More...
            </Button>
          )}
          {showMoreState && (
            <Button
              className={classes.showMoreBtn}
              color="primary"
              onClick={() => setShowMoreState(false)}
            >
              ...Less
            </Button>
          )}
          <Typography />
        </Grid>
      )}
    </Grid>
  );
};

RefinementList.propTypes = {
  attribute: PropTypes.string.isRequired,
  currentRefinement: PropTypes.arrayOf(PropTypes.string).isRequired,
  refine: PropTypes.func.isRequired,
  showMore: PropTypes.bool.isRequired,
  limit: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      count: PropTypes.number,
      isRefined: PropTypes.bool
    })
  ).isRequired
};

export default withStyles(styles, { withTheme: true })(RefinementList);
