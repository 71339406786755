import React from 'react';

import { ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';

import ChecklistInSA from './checklistInSA';
import { useTaskTemplateChecklistsOnServiceAgreement } from './hooks/useTaskTemplateChecklistsOnServiceAgreement';

const AssetViewMaintenancePlan = ({
  data: assetData,
  serviceAgreementId,
  customerPropertyId,
  refetchServiceAgreement
}) => {
  const subtitle = [assetData.make, assetData.model, assetData.serialNo].filter(Boolean);
  const { data: checklistArray = [] } = useTaskTemplateChecklistsOnServiceAgreement({
    serviceAgreementId,
    propertyAssetId: assetData.id,
    customerPropertyId
  });

  return (
    <ThemeProvider>
      <Typography variant={TV.L} weight={TW.BOLD}>
        {assetData.assetName}
      </Typography>
      {subtitle.length > 0 && (
        <Typography variant={TV.S1} weight={TW.REGULAR}>
          {subtitle.join(' - ')}
        </Typography>
      )}
      <span css={{ paddingBottom: 8 }} />
      {checklistArray.map(cList => (
        <ChecklistInSA
          assetData={assetData}
          cList={cList}
          customerPropertyId={customerPropertyId}
          key={cList?.id}
          refetchServiceAgreement={refetchServiceAgreement}
          serviceAgreementId={serviceAgreementId}
        />
      ))}
    </ThemeProvider>
  );
};

export default AssetViewMaintenancePlan;
