import { useMemo } from 'react';

import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { useDrop } from 'react-dnd';

import { trackDrop } from '@dispatch/components/DispatchBoard/DispatchBoard.utils';
import { DragScenarios, ItemTypes, selectDragScenario } from '@dispatch/dnd';

import { LOADING_TECHS } from './DailyView.constants';
import { selectSnappedOffset } from './DailyView.selectors';
import { serializeDropData } from './DailyView.serializers';

const moment = extendMoment(Moment);

const filterOutOfRangeEvents = ({ range, events = [] }) => {
  return events.filter(techEvent => techEvent.range.overlaps(range));
};

export const useItemData = ({ techsResponse, dispatchEventsResponse, boardRef, day }) => {
  return useMemo(() => {
    if (techsResponse.loading) return LOADING_TECHS;

    const dayRange = moment.range(moment(day).startOf('day'), moment(day).endOf('day'));

    const itemData = techsResponse.data.map(tech => {
      const loaded = !!dispatchEventsResponse.data[tech.id];
      return {
        tech,
        techLoading: false,
        eventsLoading: dispatchEventsResponse.loading || !loaded,
        techEvents: filterOutOfRangeEvents({
          range: dayRange,
          events: dispatchEventsResponse?.data?.[tech.id]
        }),
        board: boardRef
      };
    });

    return [{}, ...itemData];
  }, [
    techsResponse.loading,
    techsResponse.data,
    dispatchEventsResponse.data,
    dispatchEventsResponse.loading,
    boardRef,
    day
  ]);
};

export const useVisitDrop = ({
  clientOffsetRef,
  triggerVisitTransition,
  boardRef,
  day,
  clearVisitRowHover
}) => {
  const [collected, dropRef] = useDrop({
    accept: [ItemTypes.TABLE_VISIT, ItemTypes.BOARD_VISIT],
    collect: monitor => ({
      isOver: monitor.isOver(),
      dragScenario: selectDragScenario(monitor.getItem()),
      primaryTech: monitor.getItem()?.data?.primaryTechId,
      extraTechs: monitor.getItem()?.data?.extraTechs,
      timesheetEntryBinders: monitor.getItem()?.data?.timesheetEntryBinders
    }),
    hover: (item, monitor) => {
      // workaround for getClientOffset returning null on drop monitor
      const clientOffset = monitor.getClientOffset();
      // eslint-disable-next-line no-param-reassign
      if (clientOffset) clientOffsetRef.current = clientOffset;
    },
    drop: item => {
      const dragScenario = collected?.dragScenario;
      const itemType = collected?.item?.type;
      trackDrop({ dragScenario, itemType });
      if (
        collected.dragScenario === DragScenarios.FULLY_ASSIGNED_MULTI_TECH ||
        collected.dragScenario === DragScenarios.FULLY_ASSIGNED_SINGLE_TECH
      ) {
        triggerVisitTransition(
          serializeDropData({
            item,
            dropPreview: {
              left: selectSnappedOffset(boardRef.scrollLeft, clientOffsetRef.current.x)
            },
            day,
            ...collected
          })
        );
      }
      clearVisitRowHover();
    }
  });

  return [collected, dropRef];
};
