import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  settingIcon: {
    color: theme.palette.secondary.main
  },
  image: {
    width: '175px'
  },
  textEditor: {
    width: '80%'
  },
  // @see https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/content-styles.html
  documentEditorEditableContainer: {
    background: '#EBEBEB',

    /* Make it possible to scroll the "page" of the edited content. */
    overflowY: 'auto',
    '& .ckEditorEditable ': {
      border: '1px hsl( 0,0%,82.7% ) solid',
      borderRadius: 'var(--ck-border-radius)',
      background: 'white',
      boxShadow: '0 0 5px hsla( 0,0%,0%,.1 )',
      margin: '0 auto'
    },
    '& .ck.ck-toolbar': {
      border: 'none',
      borderBottom: '1px solid var(--ck-color-toolbar-border)'
    }
  },
  documentEditor: {
    maxHeight: 'calc( 100vh - 100px )',
    borderRadius: 'var(--ck-border-radius)',
    display: 'flex',
    flexFlow: 'column nowrap',
    border: '1px solid var(--ck-color-base-border)',
    '&.hideSmartFieldPlugin .smartfield-icon': {
      display: 'none'
    },
    '& .ck-content .image': {
      width: '150px',
      margin: '0 auto'
    },
    '& .ck.ck-editor__editable_inline': {
      margin: '24px auto',
      width: 'calc(215.9mm + 15px)',
      minHeight: '279.4mm',
      padding: '6mm 19mm',
      boxSizing: 'border-box',
      fontFamily: 'Inter, sans-serif',
      lineHeight: '1.15em',
      background: 'white'
    },
    '& .ck-content .table': {
      margin: '0.5em auto',
      display: 'table',
      '& td': {
        overflowWrap: 'anywhere'
      }
    },
    '& .ck-content hr': {
      margin: '.8em auto',
      height: '2px',
      background: '#dedede',
      border: '0'
    },
    '& .ck-content p': {
      fontSize: '1em',
      lineHeight: '1.15em',
      margin: 0,
      paddingTop: '0em',
      marginBottom: '0em'
    },
    '& div.ck-content > ol': {
      margin: '.5em 0',
      paddingInlineStart: '1.2em'
    },
    '& div.ck-content > ul': {
      margin: '.5em 0',
      paddingInlineStart: '1.2em'
    },
    '& .ck-content .restricted': {
      background: theme.palette.grayscale(90),
      pointerEvents: 'none'
    },
    '& .ck-content span.restricted h3': {
      background: theme.palette.grayscale(90),
      width: 'fit-content'
    }
  },
  documentEditorToolbar: {
    zIndex: 1
  }
}));

export const pdfCSS = `
@font-face {
    font-family: "Arial"; 
    src: url("//db.onlinewebfonts.com/t/8d223b3ad8d4819e9dcf22757e4cc2c4.eot"); 
    src: url("//db.onlinewebfonts.com/t/8d223b3ad8d4819e9dcf22757e4cc2c4.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8d223b3ad8d4819e9dcf22757e4cc2c4.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8d223b3ad8d4819e9dcf22757e4cc2c4.woff") format("woff"), url("//db.onlinewebfonts.com/t/8d223b3ad8d4819e9dcf22757e4cc2c4.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8d223b3ad8d4819e9dcf22757e4cc2c4.svg#Arial") format("svg"); 
}
@font-face {
    font-family: "Courier New"; 
    src: url("//db.onlinewebfonts.com/t/72c75860d89e91a517dfb1e7d308c180.eot"); 
    src: url("//db.onlinewebfonts.com/t/72c75860d89e91a517dfb1e7d308c180.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/72c75860d89e91a517dfb1e7d308c180.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/72c75860d89e91a517dfb1e7d308c180.woff") format("woff"), url("//db.onlinewebfonts.com/t/72c75860d89e91a517dfb1e7d308c180.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/72c75860d89e91a517dfb1e7d308c180.svg#Courier New") format("svg"); 
}
@font-face {
    font-family: "Georgia"; 
    src: url("//db.onlinewebfonts.com/t/7dca09e227fdfe16908cebb4244589e4.eot"); 
    src: url("//db.onlinewebfonts.com/t/7dca09e227fdfe16908cebb4244589e4.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/7dca09e227fdfe16908cebb4244589e4.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/7dca09e227fdfe16908cebb4244589e4.woff") format("woff"), url("//db.onlinewebfonts.com/t/7dca09e227fdfe16908cebb4244589e4.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/7dca09e227fdfe16908cebb4244589e4.svg#Georgia") format("svg"); 
}
@font-face {
    font-family: "Lucida Sans Unicode"; 
    src: url("//db.onlinewebfonts.com/t/26c724b3b181aad246aa1321eaa9ea21.eot"); 
    src: url("//db.onlinewebfonts.com/t/26c724b3b181aad246aa1321eaa9ea21.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/26c724b3b181aad246aa1321eaa9ea21.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/26c724b3b181aad246aa1321eaa9ea21.woff") format("woff"), url("//db.onlinewebfonts.com/t/26c724b3b181aad246aa1321eaa9ea21.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/26c724b3b181aad246aa1321eaa9ea21.svg#Lucida Sans Unicode") format("svg"); 
}
@font-face {
    font-family: "Tahoma"; 
    src: url("//db.onlinewebfonts.com/t/cd0381aa3322dff4babd137f03829c8c.eot"); 
    src: url("//db.onlinewebfonts.com/t/cd0381aa3322dff4babd137f03829c8c.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/cd0381aa3322dff4babd137f03829c8c.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/cd0381aa3322dff4babd137f03829c8c.woff") format("woff"), url("//db.onlinewebfonts.com/t/cd0381aa3322dff4babd137f03829c8c.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/cd0381aa3322dff4babd137f03829c8c.svg#Tahoma") format("svg"); 
}
@font-face {
    font-family: "Times New Roman"; 
    src: url("//db.onlinewebfonts.com/t/32441506567156636049eb850b53f02a.eot"); 
    src: url("//db.onlinewebfonts.com/t/32441506567156636049eb850b53f02a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/32441506567156636049eb850b53f02a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/32441506567156636049eb850b53f02a.woff") format("woff"), url("//db.onlinewebfonts.com/t/32441506567156636049eb850b53f02a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/32441506567156636049eb850b53f02a.svg#Times New Roman") format("svg"); 
}
@font-face {
    font-family: "Trebuchet MS"; 
    src: url("//db.onlinewebfonts.com/t/03e852a9d1635cf25800b41001ee80c7.eot"); 
    src: url("//db.onlinewebfonts.com/t/03e852a9d1635cf25800b41001ee80c7.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/03e852a9d1635cf25800b41001ee80c7.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/03e852a9d1635cf25800b41001ee80c7.woff") format("woff"), url("//db.onlinewebfonts.com/t/03e852a9d1635cf25800b41001ee80c7.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/03e852a9d1635cf25800b41001ee80c7.svg#Trebuchet MS") format("svg"); 
}
@font-face {
    font-family: "Verdana"; 
    src: url("//db.onlinewebfonts.com/t/562fa31bba08b3f71cb71257ddb880d5.eot"); 
    src: url("//db.onlinewebfonts.com/t/562fa31bba08b3f71cb71257ddb880d5.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/562fa31bba08b3f71cb71257ddb880d5.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/562fa31bba08b3f71cb71257ddb880d5.woff") format("woff"), url("//db.onlinewebfonts.com/t/562fa31bba08b3f71cb71257ddb880d5.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/562fa31bba08b3f71cb71257ddb880d5.svg#Verdana") format("svg"); 
}
:root {
    --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
    --ck-color-mention-text: hsl(341, 100%, 30%);
    --ck-highlight-marker-blue: hsl(201, 97%, 72%);
    --ck-highlight-marker-green: hsl(120, 93%, 68%);
    --ck-highlight-marker-pink: hsl(345, 96%, 73%);
    --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
    --ck-highlight-pen-green: hsl(112, 100%, 27%);
    --ck-highlight-pen-red: hsl(0, 85%, 49%);
    --ck-image-style-spacing: 1.5em;
    --ck-todo-list-checkmark-size: 16px;
}
.ck-content .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
}
.ck-content .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
}
.ck-content .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
}
.ck-content .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
}
.ck-content .image.image_resized img {
    width: 100%;
}
.ck-content .image.image_resized > figcaption {
    display: block;
}
.ck-content .image > figcaption {
    display: table-caption;
    caption-side: bottom;
    word-break: break-word;
    color: hsl(0, 0%, 20%);
    background-color: hsl(0, 0%, 97%);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}
.ck-content .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 0em auto;
}
.ck-content .image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 50px;
}
.ck-content .marker-yellow {
    background-color: var(--ck-highlight-marker-yellow);
}
.ck-content .marker-green {
    background-color: var(--ck-highlight-marker-green);
}
.ck-content .marker-pink {
    background-color: var(--ck-highlight-marker-pink);
}
.ck-content .marker-blue {
    background-color: var(--ck-highlight-marker-blue);
}
.ck-content .pen-red {
    color: var(--ck-highlight-pen-red);
    background-color: transparent;
}
.ck-content .pen-green {
    color: var(--ck-highlight-pen-green);
    background-color: transparent;
}
.ck-content .text-tiny {
    font-size: .7em;
}
.ck-content .text-small {
    font-size: .85em;
}
.ck-content .text-big {
    font-size: 1.4em;
}
.ck-content .text-huge {
    font-size: 1.8em;
}
.ck-content blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%);
}
.ck-content[dir="rtl"] blockquote {
    border-left: 0;
    border-right: solid 5px hsl(0, 0%, 80%);
}
.ck-content code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: .15em;
    border-radius: 2px;
}
.ck-content .table td {
    overflow-wrap: anywhere;
}
.ck-content .table table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double hsl(0, 0%, 70%);
    display: table;
}
.ck-content .table table td,
.ck-content .table table th {
    min-width: 2em;
    padding: .4em;
    border: 1px solid hsl(0, 0%, 75%);
}
.ck-content .table table th {
    font-weight: bold;
    background: hsla(0, 0%, 0%, 5%);
}
.ck-content[dir="rtl"] .table th {
    text-align: right;
}
.ck-content[dir="ltr"] .table th {
    text-align: left;
}
.ck-content .page-break {
    position: relative;
    clear: both;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ck-content .page-break::after {
    content: '';
    position: absolute;
    border-bottom: 2px dashed hsl(0, 0%, 77%);
    width: 100%;
}
.ck-content .page-break__label {
    position: relative;
    z-index: 1;
    padding: .3em .6em;
    display: block;
    text-transform: uppercase;
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    font-size: 0.75em;
    font-weight: bold;
    color: hsl(0, 0%, 20%);
    background: hsl(0, 0%, 100%);
    box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.ck-content .todo-list {
    list-style: none;
}
.ck-content .todo-list li {
    margin-bottom: 5px;
}
.ck-content .todo-list li .todo-list {
    margin-top: 5px;
}
.ck-content .todo-list .todo-list__label > input {
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    width: var(--ck-todo-list-checkmark-size);
    height: var(--ck-todo-list-checkmark-size);
    vertical-align: middle;
    border: 0;
    left: -25px;
    margin-right: -15px;
    right: 0;
    margin-left: 0;
}
.ck-content .todo-list .todo-list__label > input::before {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid hsl(0, 0%, 20%);
    border-radius: 2px;
    transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
}
.ck-content .todo-list .todo-list__label > input::after {
    display: block;
    position: absolute;
    box-sizing: content-box;
    pointer-events: none;
    content: '';
    left: calc( var(--ck-todo-list-checkmark-size) / 3 );
    top: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
    width: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
    height: calc( var(--ck-todo-list-checkmark-size) / 2.6 );
    border-style: solid;
    border-color: transparent;
    border-width: 0 calc( var(--ck-todo-list-checkmark-size) / 8 ) calc( var(--ck-todo-list-checkmark-size) / 8 ) 0;
    transform: rotate(45deg);
}
.ck-content .todo-list .todo-list__label > input[checked]::before {
    background: hsl(126, 64%, 41%);
    border-color: hsl(126, 64%, 41%);
}
.ck-content .todo-list .todo-list__label > input[checked]::after {
    border-color: hsl(0, 0%, 100%);
}
.ck-content .todo-list .todo-list__label .todo-list__label__description {
    vertical-align: middle;
}
.ck-content .media {
    clear: both;
    margin: 1em 0;
    display: block;
    min-width: 15em;
}
.ck-content .raw-html-embed {
    margin: 1em auto;
    min-width: 15em;
    font-style: normal;
}
.ck-content hr {
    margin: 15px 0;
    height: 4px;
    background: hsl(0, 0%, 87%);
    border: 0;
}
.ck-content pre {
    padding: 1em;
    color: hsl(0, 0%, 20.8%);
    background: hsla(0, 0%, 78%, 0.3);
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px;
}
.ck-content pre code {
    background: unset;
    padding: 0;
    border-radius: 0;
}
.ck-content .mention {
    background: var(--ck-color-mention-background);
    color: var(--ck-color-mention-text);
}
.ck-content hr {
  margin: .8em auto;
  height: 2px;
  background: #dedede;
  border: 0;
}

.ck-content {
    font-family: "Inter", sans-serif;
    line-height: 1.15em;
    width: 204.9mm;
  }
.ck-content p {
    font-size: 1em;
    line-height: 1.15em;
    margin: 0;
    padding-top: 0;
    margin-bottom: 0;
}
.ck-content ol {
    margin: .5em 0;
}
.ck-content .image {
  width: 150px;
}
.ck-content .table {
    margin: .5em auto;
    display: table;
}
.ck-content > ol {
    margin: .5em -.5em;
},
.ck-content > ul {
    margin: .5em 0;
    padding-inline-start: 1.2em;
}
.ck-content .table thead {
    display: table-row-group;
}
.ck-content .table tr {
    break-inside: avoid;
    break-after: auto;
}

@media print {
    body {
        margin: 0 !important;
    }
    /* ckeditor5-page-break/theme/pagebreak.css */
    .ck-content .page-break {
        padding: 0;
    }
    /* ckeditor5-page-break/theme/pagebreak.css */
    .ck-content .page-break::after {
        display: none;
    }
}`;

export default styles;
