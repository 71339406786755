import React from 'react';

import { MUIFormViewer } from '@BuildHero/sergeant';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDivider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import * as FormBuilderLayouts from '../controls';
import * as SmartFields from '../controls/SmartFieldData';

export default class EditItemModal extends React.Component {
  constructor(props) {
    super(props);
    const { item, currentSmartField } = this.props;
    const [oldItem, row, column] = item;
    const [layout, data] = FormBuilderLayouts[oldItem.editFormLayout];
    const newData = { ...data, ...oldItem };

    this.meta = [layout, newData];
    this.smartFieldOptions = SmartFields.Company.concat(SmartFields[currentSmartField] || []);
    this.itemLocation = [row, column];
  }

  onSubmit = data => {
    const { item, submit } = this.props;
    const [options, row, column] = item;
    const newItem = { ...options, ...data[1] };

    if (submit) submit(newItem, row, column);
  };

  render() {
    const { handleClose, classes, copyItem, deleteItem } = this.props;

    return (
      <div>
        <Dialog
          aria-labelledby="Edit Item"
          className={classes.dialogContent}
          fullWidth
          maxWidth={false}
          open
          style={{ maxWidth: 696 }}
          onClose={handleClose}
        >
          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
          <DialogContent>
            <Grid alignItems="center" container direction="row">
              <Grid container direction="row">
                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <div style={{ position: 'relative', float: 'left', width: '100%' }}>
                    <div style={{ float: 'left', width: 'auto' }}>
                      <Typography
                        className={classes.sectionHeader}
                        gutterBottom
                        key={`'Edit Attributes'`}
                        style={{ float: 'left', marginRight: 12 }}
                        variant="subtitle2"
                      >
                        Edit Attributes
                      </Typography>
                      <Button
                        color="secondary"
                        style={{ float: 'left', margin: '0 12px 6px 0' }}
                        variant="contained"
                        onClick={() => {
                          copyItem(...this.itemLocation);
                          handleClose();
                        }}
                      >
                        <FileCopyIcon
                          className={classes.itemControlIcon}
                          style={{ marginRight: 6 }}
                        />
                        Copy Item
                      </Button>
                      <Button
                        className={classes.itemDeleteButton}
                        style={{ float: 'left', margin: '0 12px 6px 0' }}
                        variant="contained"
                        onClick={() => {
                          deleteItem(...this.itemLocation);
                          handleClose();
                        }}
                      >
                        <DeleteIcon
                          className={classes.itemControlIcon}
                          style={{ marginRight: 6 }}
                        />
                        Delete Item
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <MuiDivider className={classes.sectionDivider} variant="fullWidth" />
                </Grid>
              </Grid>
            </Grid>
            <MUIFormViewer
              handleClose={handleClose}
              meta={this.meta}
              name="Edit Item"
              smartFieldOptions={this.smartFieldOptions}
              onSubmit={this.onSubmit}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
