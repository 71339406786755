import React, { useCallback, useMemo } from 'react';

import { object } from 'prop-types';
import * as R from 'ramda';

import toMap from 'utils/toMap';

import TimesheetEntryItem from './components/TimesheetEntryItem';
import { useStyles } from './TimesheetEntriesField.styles';

const TimesheetEntriesField = ({ field, form, options }) => {
  const styles = useStyles();

  const payrollHoursMap = useMemo(() => toMap(options?.payrollHourTypes || []), [
    options.payrollHourTypes
  ]);

  const items = useMemo(
    () => R.sortBy(item => payrollHoursMap[item?.hourTypeId]?.sortOrder)(field.value || []),
    [field.value, payrollHoursMap]
  );

  const handleOnChange = useCallback(
    ({ id, value }) => {
      form.setFieldValue(
        field.name,
        form?.values?.[field.name]?.map(item => {
          if (item?.hourTypeId === id) {
            return {
              ...item,
              duration: value
            };
          }
          return item;
        })
      );
    },
    [field, form]
  );

  return (
    <div css={styles.container}>
      {items.map(item => {
        return (
          <TimesheetEntryItem
            color={options.color}
            disabled={options.disabled}
            key={item?.id}
            payrollHourType={payrollHoursMap[item?.hourTypeId]}
            value={item?.duration}
            variant={options.variant}
            onChange={handleOnChange}
          />
        );
      })}
    </div>
  );
};

TimesheetEntriesField.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
  options: object.isRequired
};

export default TimesheetEntriesField;
