export const getFormattedForms = (formData, visits) => {
  const combinedForms = visits.items.reduce(
    (arr, visit) => {
      if (!visit.formData.items.length) {
        return arr;
      }
      return [...arr, ...visit.formData.items];
    },
    [...formData?.items]
  );
  const formattedForms = [];
  if (combinedForms && combinedForms.length) {
    combinedForms.forEach(item => {
      const { form } = item;
      if (form !== null && form.viewType !== 'Inline') {
        try {
          const formDataJson = JSON.parse(item.formDataJson);
          const { name, description, formData: parsedFormData } = formDataJson || {};
          formattedForms.push({
            id: item.id,
            name,
            description,
            formDataJson: parsedFormData,
            sortKey: item.sortKey,
            lastUpdatedDateTime: item.lastUpdatedDateTime,
            lastUpdatedBy: item.lastUpdatedBy,
            version: item.version
          });
        } catch (e) {
          console.warn('Issue parsing form data', e);
        }
      }
    });
  }
  return formattedForms;
};
