import { FieldType } from '@BuildHero/sergeant';

import Labels from 'meta/labels';
import { EnvelopeType, MobileFieldOrders, PricingStrategy } from 'utils/constants';

export default [
  {
    type: FieldType.BOOL,
    settingKey: 'autoPopulateStartEndTime',
    settingPath: 'Timesheets',
    settingValue: 'false',
    displayName: 'Start and End Times will be auto-populated with technician time stamp.'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'manualDailyShift',
    settingPath: 'Timesheets',
    settingValue: 'false',
    displayName:
      'Turn on daily shift for technicians, to record their clock-in and clock-out time for the day. (for manual time tracking only).'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'explicitBreakTimes',
    settingPath: 'Timesheets',
    settingValue: 'false',
    displayName: 'Explicitly record technician break times (manual time tracking only).'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'requireTechSignOff',
    settingPath: 'Timesheets',
    settingValue: 'false',
    displayName: 'Require Technician to sign off prior to submitting time.'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'job_productLines',
    settingPath: 'Scheduling/Job',
    settingValue: 'false',
    displayName: 'Product lines'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'job_customJobNumber',
    settingPath: 'Scheduling/Job',
    settingValue: 'false',
    displayName: 'Use custom job number for display'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'quote_customquoteNumber',
    settingPath: 'Quote',
    settingValue: 'false',
    displayName: 'Use custom quote number for display'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'jobTypeSequence',
    settingPath: 'Scheduling/Job',
    settingValue: 'false',
    displayName:
      'Enable job type based job number sequence. Job created for each job type will have its own sequence number'
  },
  {
    type: 'json',
    settingKey: 'custom_job_expression',
    settingPath: 'Expression/JobNumber',
    settingValue: '',
    displayName: 'Custom Job number expression'
  },
  {
    type: 'json',
    settingKey: 'custom_quote_expression',
    settingPath: 'Expression/QuoteNumber',
    settingValue: '',
    displayName: 'Custom Quote number expression'
  },
  {
    type: 'json',
    settingKey: 'custom_spectrum_job_expression',
    settingPath: 'Expression/SpectrumJobNumber',
    settingValue: '',
    displayName: 'Custom Spectrum job number expression'
  },
  {
    type: FieldType.TEXT,
    settingKey: 'quickbooks',
    settingPath: 'Accounting/Integration',
    settingValue: '',
    displayName: 'Used to sync accounts, products and invoice with quickbooks'
  },
  // deprecated the setting in favour of templates setting
  // {
  //   settingKey: 'quotesPdfKey',
  //   settingPath: 'Quotes/PDF',
  //   settingValue: '',
  //   displayName: 'The key for Quotes PDF to be rendered'
  // },
  {
    type: FieldType.BOOL,
    settingKey: 'techAllowedToCompleteJob',
    settingPath: 'Job/techAllowedToCompleteJob',
    settingValue: 'false',
    displayName: 'Provides ability for tech (mobile) user to close the job'
  },
  {
    type: 'json',
    settingKey: 'templates',
    settingPath: 'PDF/templates',
    settingValue: '',
    displayName: 'The key for PDF or email templates to be used'
  },
  {
    type: FieldType.TEXT,
    settingKey: 'accountingApp',
    settingPath: 'Accounting/Integration/App',
    settingValue: '',
    displayName: 'Application used for integration in lowercase: e.g.: quickbooks, vista'
  },
  {
    type: 'json',
    settingKey: 'accountingAppSettings',
    settingPath: 'Accounting/Integration/Settings',
    settingValue: '',
    displayName: 'Integration setting required for connection'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'syncImmediately',
    settingPath: 'Accounting/Integration/Sync',
    settingValue: 'false',
    displayName: 'Sync immediately with accounting systems'
  },
  {
    type: FieldType.CHIP,
    props: { options: Object.values(EnvelopeType).map(t => ({ label: t, value: t })) },
    settingKey: 'envelopeType',
    settingPath: 'Accounting/Invoicing/Envelope',
    settingValue: EnvelopeType.SINGLE,
    displayName: 'Choose an option to set the Invoice PDF Template'
  },
  {
    type: FieldType.CHIP,
    props: {
      options: Object.values(PricingStrategy).map(t => ({ label: Labels[t].en, value: t }))
    },
    settingKey: 'pricingStrategy',
    settingPath: 'margin',
    settingValue: PricingStrategy.MARKUP,
    displayName: 'Enables the selection of a Pricing Strategy'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'pricebook',
    settingPath: 'Invoicing/Pricebook',
    settingValue: 'false',
    displayName: 'Enables the use of pricebooks'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'customer_extendedFields',
    settingPath: 'Customer',
    settingValue: 'false',
    displayName: 'Used to introduce - Bill to and Account number fields'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'dynamicForms',
    settingPath: 'forms',
    settingValue: 'false',
    displayName: 'Show forms'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'enableOCR',
    settingPath: 'forms/OCR',
    settingValue: 'false',
    displayName: 'Enable Optical Character Recognition (OCR)'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'quotes',
    settingPath: 'Quotes',
    settingValue: 'false',
    displayName: 'Show quotes'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'followUps',
    settingPath: 'followUps',
    settingValue: 'false',
    displayName: 'Show follow ups'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'logs',
    settingPath: 'logs',
    settingValue: 'false',
    displayName: 'Show Audit Logs'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'timesheets',
    settingPath: 'timesheets',
    settingValue: 'false',
    displayName: 'Show Timesheets'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'crewTimeTracking',
    settingPath: 'Timesheets',
    settingValue: 'false',
    displayName:
      'Landscaping - Enable Crew Scheduling & Apply primary techs timesheets to all members of the crew (timestamp based tracking must be enabled).'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'startEndTimeTimesheets',
    settingPath: 'Timesheets/Mobile/Compliance',
    settingValue: 'false',
    displayName:
      'Require technicians to enter start and end times in Timesheets (manual time tracking must be enabled).'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'assetTracking',
    settingPath: 'Job/Visit/Assets',
    settingValue: 'false',
    displayName: 'Allow tracking of assets at the visit level'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'assetTrackingAgainstTask',
    settingPath: 'Task/Assets',
    settingValue: 'false',
    displayName: 'Enable Asset tracking against tasks '
  },
  {
    type: FieldType.BOOL,
    settingKey: 'mobilePendingTasks',
    settingPath: 'Task/Mobile/Pending',
    settingValue: 'false',
    displayName: 'Enable mobile pending tasks'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'disablePONumber',
    settingPath: 'Job/tech report',
    settingValue: 'false',
    displayName: 'Hide PO number in mobile'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'showOneVisitInMobile',
    settingPath: 'Mobile',
    settingValue: 'false',
    displayName: 'Show only next visit to tech'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'disable',
    settingPath: 'Mobile/Notification',
    settingValue: 'false',
    displayName: 'Disable Mobile Notifications'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'visit_releaseToTechEnabled',
    settingPath: 'Job/Visit',
    settingValue: 'false',
    displayName: "Hide a technician's visit from them until the dispatcher releases it to them"
  },
  {
    type: FieldType.BOOL,
    settingKey: 'workflowsEnabled',
    settingPath: 'Company/Workflow',
    settingValue: 'false',
    displayName: 'Enable marking workflows as required/non-required'
  },
  {
    type: 'json',
    settingKey: 'customDoneWorkingMessage',
    settingPath: 'Mobile/messages',
    settingValue: '{"title": "", "body": ""}',
    displayName: 'Display message to be shown for "Work Done" Alert'
  },
  {
    type: FieldType.TEXT,
    settingKey: 'countrySetting',
    settingPath: 'Address',
    settingValue: '',
    displayName:
      'Country filter on address search. Must be in all lowercase country code. default is us. Max of 5 countries comma separated'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'showTimeSheetCompliance',
    settingPath: 'Timesheets/Mobile/Compliance',
    settingValue: 'false',
    displayName: 'Enables the compliance form before submitting timesheet in mobile'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'enableTieredPricing',
    settingPath: 'Accounting/Products',
    settingValue: 'false',
    displayName: 'Enable tiered pricing based on expression while creating products'
  },
  {
    type: FieldType.TEXT,
    settingKey: 'tieredPricingExpression',
    settingPath: 'Accounting/Products',
    settingValue: '',
    displayName: 'Lisp expression for calculating product markups'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'disableViewPastJob',
    settingPath: 'Mobile/PastJobs',
    settingValue: 'false',
    displayName: 'View past job in the mobile app will be hidden'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'filterByDepartment',
    settingPath: 'Company/filterByDepartment',
    settingValue: 'false',
    displayName: 'Filter service calls by Department (Multi-Office)'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'accelerateVisitProcess',
    settingPath: 'Job/accelerateVisitProcess',
    settingValue: 'false',
    displayName:
      'When a job is created, there will be a checkbox that allows creating the first visit alongside the job.'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'isAutoassignTaxRateDisabled',
    settingPath: 'Customer/isAutoassignTaxRateDisabled',
    settingValue: 'false',
    displayName: 'Do not assign tax code automatically upon selecting an address'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'serviceAgreements',
    settingPath: 'serviceAgreements',
    settingValue: 'false',
    displayName: 'Show Service Agreements and Maintenance Jobs'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'recurringMaintenanceBilling',
    settingPath: 'recurringMaintenanceBilling',
    settingValue: 'false',
    displayName: 'Show Recurring Maintenance Billing page'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'tableViews',
    settingPath: 'tableViews',
    settingValue: 'false',
    displayName: 'Enable custom Views for tables'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'multiplePricebooks',
    settingPath: 'multiplePricebooks',
    settingValue: 'false',
    displayName: 'Enable tenants to have multiple Pricebooks'
  },
  {
    type: FieldType.TEXT,
    settingKey: 'maxDaysMobileVisitHistory',
    settingPath: 'Mobile/Visits',
    settingValue: '',
    displayName:
      'Mobile - show visits with scheduled times between X days in the past to X days in the future'
  },
  {
    type: FieldType.TEXT,
    settingKey: 'defaultEmailSenderValueForQuote',
    settingPath: 'Quote/EmailSender',
    settingValue: '',
    displayName: 'Default Sender Email value for Quote Emails. eg CompanyName'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'budgetLaborHoursAtJobLevel',
    settingPath: 'ServiceAgreement/MaintenanceTemplate',
    settingValue: 'false',
    displayName: 'Show budget and used hours on Job'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'showDepartmentForNonBillableEvents',
    settingPath: 'showDepartmentForNonBillableEvents',
    settingValue: 'false',
    displayName: 'Show department drop-down for Non-Billable Events'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'disableManualItemEntry',
    settingPath: 'Invoicing/Pricebook',
    settingValue: 'false',
    displayName: 'Disable Manual Item Entry (Force item selection from pricebook only)'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'certifiedPayrollJobs',
    settingPath: 'Job',
    settingValue: 'false',
    displayName: 'Show certified payroll checkbox for jobs'
  },
  {
    type: FieldType.BOOL,
    settingKey: 'forceCertifiedPayrollJobs',
    settingPath: 'Job',
    settingValue: 'false',
    displayName: 'Force all jobs to certified payroll on'
  },
  {
    type: FieldType.CHIP,
    props: {
      options: Object.values(MobileFieldOrders).map(t => ({ label: t, value: t }))
    },
    settingKey: 'fieldOrdersFromMobile',
    settingPath: 'Mobile/PurchaseOrder',
    settingValue: MobileFieldOrders.FIELD_ORDERS_ONLY,
    displayName: 'Field Orders from Mobile'
  },
  {
    type: FieldType.TEXT,
    settingKey: 'defaultLineItem',
    settingPath: 'defaultLineItem',
    settingValue: '',
    displayName: 'Default line item for free-form items'
  }
];
