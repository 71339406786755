import React, { Component } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { snackbarOn as boSnackbarOn } from 'redux/actions/globalActions';
import { CompanyService } from 'services/core';

import styles from './styles';

/**
 * Renders Readonly inputs as per the build hero designs
 */

class BuildOpsMakeAdmin extends Component {
  constructor(props) {
    super(props);
    this.CompanyService = new CompanyService();
    this.state = {
      action:
        this.props.specialbehaviour.queryResult.employee.isAdmin === true
          ? 'reset admin rights'
          : 'added admin rights'
    };
  }

  handleChange = () => event => {
    const { field, form, specialbehaviour } = this.props;
    // Formik expects string values
    const boolAsString = `${event.target.checked}`;

    if (specialbehaviour.controlField && field.value !== boolAsString) {
      const { mappings } = specialbehaviour;
      const mappedValues = {};
      const checkBoxChecked = boolAsString === 'true';

      Object.keys(mappings).forEach(key => {
        mappedValues[key] = checkBoxChecked ? form.values[mappings[key]] || '' : '';
      });
      const newValues = { ...form.values, ...mappedValues, [field.name]: boolAsString };
      form.setValues(newValues);
      return;
    }

    form.setFieldValue(field.name, boolAsString, false);
    // this.clickAction(); Not need as the save should be when employee is saved
  };

  // clickAction = async () => {
  //   const employeeDetails = this.props.specialbehaviour.queryResult.employee || '';
  //   if (employeeDetails) {
  //     employeeDetails.isAdmin = !employeeDetails.isAdmin;
  //     try {
  //       const { data } = await this.CompanyService.saveEmployee({
  //         input: {
  //           id: employeeDetails.id,
  //           isAdmin: employeeDetails.isAdmin
  //         }
  //       });
  //       if (data) {
  //         this.props.snackbarOn('success', `Successfully ${this.state.action}`);
  //         this.props.specialbehaviour.queryResult.refetch();
  //       }
  //     } catch (error) {
  //       Logger.error(error);
  //       if (error.graphQLErrors && error.graphQLErrors.length > 0) {
  //         this.props.snackbarOn('error', error.graphQLErrors[0].message);
  //       } else {
  //         this.props.snackbarOn('error', `Unable to ${this.state.action}, please try again later`);
  //       }
  //     }
  //   }
  // };

  render() {
    const { field, form, specialbehaviour, classes, user, theme, snackbarOn, ...rest } = this.props;
    const { showField, queryResult } = specialbehaviour;
    if (
      (showField && queryResult[showField]) ||
      this.props.specialbehaviour.queryResult.employee.id === this.props.user.employeeId ||
      this.props.user.cognitoRole !== 'TenantAdmin'
    ) {
      return <></>;
    }
    return (
      <FormControlLabel
        className={classes.inlineFormButton}
        control={
          <Checkbox
            onChange={this.handleChange()}
            {...rest}
            checked={`${field.value}` === 'true'}
            style={{ padding: 6 }}
            value={`${field.value}`}
          />
        }
        label={rest.placeholder}
        labelPlacement="start"
      />
    );
  }
}
const styledBuildopMakeAdminButton = withStyles(styles)(BuildOpsMakeAdmin);

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(boSnackbarOn(mode, message))
});

const connectedBuildopsMakeAdminButtton = connect(
  null,
  mapDispatchToProps
)(styledBuildopMakeAdminButton);

export default connectedBuildopsMakeAdminButtton;
