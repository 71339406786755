import { bundleIndex } from 'constants/algoliaIndex';
import { QuoteLineItemType } from 'utils/constants';

export default (lineItemType, formData, onItemSelection, showMargin, showMarkup) => {
  const isDiscount = lineItemType === QuoteLineItemType.DISCOUNT;
  const isInventory = lineItemType === QuoteLineItemType.MATERIAL;

  const rowOptions = { direction: 'row', margin: [15, 0, 0, 0] };
  const horizontalSpacing = 20;

  const markupField = showMarkup && {
    options: {
      direction: 'column',
      marginRight: horizontalSpacing,
      maxWidth: showMargin ? 100 : 200
    },
    contents: [
      {
        options: {
          label: 'Markup',
          type: 'numeric',
          endAdornmentText: '%', // we only support % as of now
          onChange: formData.onCalcChange,
          color: 'secondary',
          variant: 'standard'
        },
        component: 'TextInput',
        source: 'markupValue'
      }
    ]
  };

  const marginField = showMargin && {
    options: {
      direction: 'column',
      marginRight: horizontalSpacing,
      maxWidth: showMarkup ? 100 : 200
    },
    contents: [
      {
        options: {
          label: 'Margin',
          type: 'numeric',
          endAdornmentText: '%', // we only support % as of now
          onChange: formData.onCalcChange,
          color: 'secondary',
          variant: 'standard'
        },
        component: 'TextInput',
        source: 'marginValue'
      }
    ]
  };

  return {
    fields: {
      name: {
        default: 'name'
      },
      productId: {
        default: 'productId'
      },
      productSortKey: {
        default: 'productSortKey'
      },
      departmentId: {
        default: 'departmentId'
      },
      taxable: {
        default: 'taxable'
      },
      description: {
        default: 'description'
      },
      quantity: {
        default: 'quantity'
      },
      unitPrice: {
        default: 'unitPrice'
      },
      unitCost: {
        default: 'unitCost'
      },
      amount: {
        default: 'amount'
      },
      jobCostTypeId: {
        default: 'jobCostTypeId'
      },
      costCodeId: {
        default: 'costCodeId'
      },
      revenueTypeId: {
        default: 'revenueTypeId'
      }
    },
    validation: {
      type: 'object',
      properties: {
        name: {
          type: 'string'
        },
        productId: {
          type: 'string'
        },
        productSortKey: {
          type: 'string'
        },
        departmentId: {
          type: 'string',
          nullable: true
        },
        discount: {
          type: 'string'
        },
        quantity: {
          type: 'number',
          min: 1
        },
        unitPrice: {
          type: 'number'
        },
        amount: {
          type: 'number'
        }
      },
      required: ['name', 'departmentId', 'quantity', 'unitPrice', 'amount']
    },
    validationErrors: {
      name: {
        required: 'Product is required.'
      },
      departmentId: {
        required: 'Department is required.'
      },
      quantity: {
        required: 'Quantity is required.',
        min: 1
      },
      unitPrice: {
        required: 'Unit price is required.'
      },
      unitCost: {
        required: 'Unit cost is required.'
      },
      amount: {
        required: 'Amount is required.',
        type: 'Amount must be a valid number'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0,
          width: 750
        },
        contents: [
          {
            // row 1
            options: {
              ...rowOptions,
              marginTop: 0,
              alignItems: 'flex-end'
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  marginRight: horizontalSpacing
                },
                contents: [
                  {
                    options: {
                      label: 'Product',
                      required: true,
                      placeholder: 'Search products...',
                      searchIndexName: bundleIndex,
                      searchResultDisplayFields: ['name', 'code'],
                      delimiter: ' - ',
                      onChange: onItemSelection,
                      setItemName: 0,
                      filters: [
                        {
                          attribute: 'entityType',
                          valueArray: ['Product']
                        }
                      ],
                      color: 'secondary',
                      variant: 'standard'
                    },
                    component: 'AlgoliaSearchWrapper',
                    source: 'name'
                  }
                ]
              },
              {
                options: { direction: 'column', marginRight: horizontalSpacing },
                contents: [
                  {
                    options: {
                      label: 'Department',
                      isRequired: true,
                      placeholder: 'Select...',
                      valueSet: formData.departmentList,
                      valuesToSet: [
                        {
                          accountingRefIdOfClass: 'accountingRefIdOfClass'
                        }
                      ],
                      color: 'secondary',
                      variant: 'standard'
                    },
                    component: 'SelectInput',
                    source: 'departmentId'
                  }
                ]
              },
              isDiscount
                ? {
                    options: { direction: 'column' },
                    contents: [
                      {
                        options: {
                          label: 'Discount',
                          isRequired: true,
                          placeholder: 'Select...',
                          valueSet: [
                            {
                              label: QuoteLineItemType.DISCOUNT,
                              value: QuoteLineItemType.DISCOUNT
                            }
                            // { label: QuoteLineItemType.FEE, value: QuoteLineItemType.FEE }
                          ],
                          defaultValueList: [{ value: QuoteLineItemType.DISCOUNT }],
                          color: 'secondary',
                          variant: 'standard'
                        },
                        component: 'SelectInput',
                        source: 'lineItemType'
                      }
                    ]
                  }
                : { component: null }
            ]
          },
          {
            // row 2
            options: rowOptions,
            contents: [
              {
                options: { direction: 'column' },
                contents: [
                  {
                    options: {
                      label: 'Description',
                      lines: 3,
                      color: 'secondary',
                      variant: 'standard'
                    },
                    component: 'TextInput',
                    source: 'description'
                  }
                ]
              }
            ]
          },
          isDiscount
            ? {
                component: null
              }
            : {
                // row 3
                options: rowOptions,
                contents: [
                  {
                    options: { direction: 'column', marginRight: horizontalSpacing },
                    contents: [
                      {
                        options: {
                          label: 'Quantity',
                          isRequired: true,
                          type: 'numeric',
                          onChange: formData.onCalcChange,
                          endAdornmentRef: 'unitOfMeasure',
                          endAdornmentText: 'units',
                          color: 'secondary',
                          variant: 'standard'
                        },
                        component: 'TextInput',
                        source: 'quantity'
                      }
                    ]
                  },
                  !isInventory
                    ? {
                        component: null
                      }
                    : {
                        options: { direction: 'column', marginRight: horizontalSpacing },
                        contents: [
                          {
                            options: {
                              label: 'Unit Cost',
                              isRequired: true,
                              onChange: formData.onCalcChange,
                              color: 'secondary',
                              variant: 'standard'
                            },
                            component: 'CurrencyInput',
                            source: 'unitCost'
                          }
                        ]
                      },
                  !isInventory
                    ? {
                        component: null
                      }
                    : markupField,
                  !isInventory
                    ? {
                        component: null
                      }
                    : marginField,
                  {
                    options: { direction: 'column' },
                    contents: [
                      {
                        options: {
                          label: 'Unit Price',
                          isRequired: true,
                          onChange: formData.onCalcChange,
                          color: 'secondary',
                          variant: 'standard'
                        },
                        component: 'CurrencyInput',
                        source: 'unitPrice'
                      }
                    ]
                  }
                ].filter(Boolean)
              },
          // row 4
          {
            options: rowOptions,
            contents: [
              {
                options: { direction: 'column', marginRight: horizontalSpacing },
                contents: [
                  {
                    options: {
                      label: 'Cost Code',
                      marginRight: 10,
                      placeholder: 'Select...',
                      inputOptions: formData.costCodes,
                      color: 'secondary',
                      variant: 'standard'
                    },
                    component: 'SelectInput',
                    source: 'costCodeId'
                  }
                ]
              },
              {
                options: { direction: 'column', marginRight: horizontalSpacing },
                contents: [
                  {
                    options: {
                      label: 'Cost Type',
                      marginRight: 10,
                      placeholder: 'Select...',
                      inputOptions: formData.costTypes,
                      color: 'secondary',
                      variant: 'standard'
                    },
                    component: 'SelectInput',
                    source: 'jobCostTypeId'
                  }
                ]
              },
              {
                options: { direction: 'column' },
                contents: [
                  {
                    options: {
                      label: 'Revenue Type',
                      marginRight: 10,
                      placeholder: 'Select...',
                      inputOptions: formData.revenueTypes,
                      color: 'secondary',
                      variant: 'standard'
                    },
                    component: 'SelectInput',
                    source: 'revenueTypeId'
                  }
                ]
              }
            ]
          },
          {
            // row 5
            options: rowOptions,
            contents: [
              {
                options: { direction: 'column', marginRight: horizontalSpacing },
                contents: [
                  {
                    options: {
                      label: 'Total Amount',
                      isRequired: true,
                      onChange: formData.onCalcChange,
                      disabled: !isDiscount,
                      color: 'secondary',
                      variant: 'standard'
                    },
                    component: 'CurrencyInput',
                    source: 'amount'
                  }
                ]
              },
              {
                options: {
                  direction: 'column',
                  marginRight: horizontalSpacing,
                  alignSelf: 'center'
                },
                contents: [
                  {
                    options: {
                      label: 'Taxable'
                    },
                    component: 'CheckboxInput',
                    source: 'taxable'
                  }
                ]
              },
              {
                options: { direction: 'column' },
                contents: []
              }
            ]
          }
        ]
      }
    }
  };
};
