import React from 'react';

import { bool, number, oneOf, string } from 'prop-types';

import NeedsReview from 'assets/Icons/NeedsReview.svg';
import Reviewed from 'assets/Icons/Reviewed.svg';
import { tableDateFormatter } from 'components/WrapTable';
import { VISIT_REVIEW_STATUS } from 'utils/AppConstants';

const VisitSubjectField = ({ reviewStatus, visitNumber, scheduledFor, isBillableEvent }) => {
  // see https://codepen.io/sosuke/pen/Pjoqqp for calculating colors for svgs that start as black
  const filter =
    reviewStatus === VISIT_REVIEW_STATUS.REVIEWED
      ? 'invert(26%) sepia(62%) saturate(5068%) hue-rotate(150deg) brightness(91%) contrast(103%)'
      : 'invert(50%) sepia(99%) saturate(442%) hue-rotate(13deg) brightness(95%) contrast(102%)';

  return (
    <div style={{ display: 'flex' }}>
      <img
        alt="reviewStatus"
        css={{
          height: '14px',
          filter
        }}
        src={reviewStatus === VISIT_REVIEW_STATUS.REVIEWED ? Reviewed : NeedsReview}
      />
      <span style={{ lineHeight: '14px', marginLeft: '5px' }}>
        {isBillableEvent
          ? `Billable Event - ${tableDateFormatter({ value: scheduledFor })}`
          : `Visit ${visitNumber} - ${tableDateFormatter({ value: scheduledFor })}`}
      </span>
    </div>
  );
};

VisitSubjectField.propTypes = {
  reviewStatus: oneOf([VISIT_REVIEW_STATUS.REVIEWED, VISIT_REVIEW_STATUS.UNREVIEWED]).isRequired,
  visitNumber: number.isRequired,
  scheduledFor: string.isRequired,
  isBillableEvent: bool
};

VisitSubjectField.defaultProps = {
  isBillableEvent: false
};

export default VisitSubjectField;
