import memoize from 'fast-memoize';
import gql from 'graphql-tag';

import {
  DEFAULT_VISIT_DURATION_IN_MIN,
  VisitStatusAttributes,
  VisitStatuses
} from '@dispatch/Dispatch.constants';

import { ElementSizes } from '@dispatch/Dispatch.styles';
import { getVisitStateMachine } from '@dispatch/stateMachines';
import useExtendedQuery from 'customHooks/useExtendedQuery';

import { selectActualDuration, selectDndTransition, selectSaveTransition } from './useVisitDetails';

export const GET_VISITS = gql`
  query visitsList(
    $columns: [String]
    $filter: GenericFilterInput
    $sorting: [SortingInput]
    $pagination: PaginationInput
  ) {
    visitsListData(columns: $columns, filter: $filter, sorting: $sorting, pagination: $pagination) {
      meta
      items
      totalRecordCount
    }
  }
`;

export const useVisits = ({ columns, filter, sorting, pagination }, options = {}) => {
  return useExtendedQuery(GET_VISITS, {
    variables: { columns, filter, sorting, pagination },
    ...options
  });
};

const transformToServerVisit = data => ({
  __typename: 'Visit',
  id: data.id,
  job: {
    __typename: 'Job',
    id: 'optimistic-job-id',
    customerName: data.customer,
    customIdentifier: data.jobCustomIdentifier,
    jobNumber: data.jobNo,
    jobType: data.jobType,
    jobTypeInternal: data.jobTypeInternal,
    priority: data.priority,
    customerPropertyName: data.property,
    serviceChannelWorkOrderWOId: data.serviceChannelWorkOrderWOId
  },
  departmentName: data.departmentName,
  description: data.description,
  extraTechs: {
    __typename: 'SystemEntityMapConnection',
    items: data.extraTechIds.map((id, i) => ({
      __typename: 'SystemEntityMap',
      id: `optimistic_extra_tech_${i}`,
      mappedEntityId: id
    }))
  },
  extraTechsNumber: data.extraTechsNumber,
  extraTechsRequired: data.extraTechsRequired,
  prerequisites: data.prerequisites,
  primaryTechs: {
    __typename: 'SystemEntityMapConnection',
    items: data.primaryTechIds.map((id, i) => ({
      __typename: 'SystemEntityMap',
      id: `optimistic_primary_tech_${i}`,
      mappedEntityId: id
    }))
  },
  scheduledFor: data.scheduledFor,
  status: data.status,
  visitNumber: data.visitNumber
});

// return VISIT_DETAILS_PROP fragment
const transformVisitRowFn = data => {
  const Status = VisitStatuses.getBy(VisitStatusAttributes.serverValue, data.status) || {};
  const visitStateMachine = getVisitStateMachine(data.id, Status.key);
  const visitState = visitStateMachine.initialState;
  const actualDuration = selectActualDuration(data.actualDuration);

  return {
    __typename: 'Visit',
    id: data.id,
    actualDuration,
    customerName: data.customer,
    departmentName: data.departmentName,
    description: data.description,
    dndTransition: selectDndTransition(visitState.nextEvents),
    extraTechs: data.extraTechIds,
    extraTechsNumber: data.extraTechsNumber,
    extraTechsRequired: data.extraTechsRequired,
    job: {
      customIdentifier: data.jobCustomIdentifier,
      jobNumber: data.jobNo,
      jobTypeInternal: data.jobTypeInternal,
      priority: data.priority,
      serviceChannelWorkOrderWOId: data.serviceChannelWorkOrderWOId
    },
    nextEvents: visitState.nextEvents,
    prerequisites: data.prerequisites,
    primaryTechId: data.primaryTechIds?.[0],
    property: {
      customerPropertyName: data.property,
      address: data.propertyAddr
    },
    saveTransition: selectSaveTransition(visitState.nextEvents),
    scheduledFor: data.scheduledFor,
    serverVisit: transformToServerVisit(data),
    status: Status.key || VisitStatuses.UNKNOWN.key,
    visitNumber: data.visitNumber,
    visitStateMachine,
    width: ((actualDuration || DEFAULT_VISIT_DURATION_IN_MIN) / 60) * ElementSizes.cellWidth
  };
};

export const transformVisitRow = memoize(transformVisitRowFn);

export const transformVisitDetailToTable = visit => ({
  id: visit.id,
  actualDuration: visit.actualDuration,
  customer: visit.customerName,
  departmentName: visit.departmentName,
  description: visit.description,
  extraTechIds: visit.extraTechs,
  extraTechsNumber: visit.extraTechsNumber,
  extraTechsRequired: visit.extraTechsRequired,
  jobCustomIdentifier: visit.job?.customIdentifier,
  jobNo: visit.job?.jobNumber,
  jobTypeInternal: visit.job?.jobTypeInternal,
  priority: visit.job?.priority,
  prerequisites: visit.prerequisites,
  primaryTechIds: visit.primaryTechId ? [visit.primaryTechId] : undefined,
  property: visit.property?.customerPropertyName,
  scheduledFor: visit.scheduledFor,
  serviceChannelWorkOrderWOId: visit.job?.serviceChannelWorkOrderWOId,
  propertyAddr: visit.property?.address,
  status: visit.status,
  visitNumber: visit.visitNumber
});
