import { useEffect, useState } from 'react';

import { Auth } from 'aws-amplify';

import configForEnvironment from 'configs/aws-exports';
import ENV from 'configs/env';

import { getFileUrl } from './FileManagement.helpers';

export const getFilesApiUrl = projectId => `${configForEnvironment(ENV).pmapi}fs/pm/${projectId}`;

export const beforeSend = jwtToken => request => {
  request.ajaxSettings.beforeSend = args => {
    args.httpRequest.setRequestHeader('Authorization', jwtToken);
  };
};

export const beforeImageLoad = request => {
  request.imageUrl = getFileUrl(request.fileDetails?.[0]);
};

export const beforeDownload = jwtToken => request => {
  request.data.jwtToken = jwtToken;
};

export const useJwtToken = () => {
  const [token, setToken] = useState();

  useEffect(() => {
    let timeoutId;

    const watchToken = ({ expiresInMs }) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        Auth.currentSession().then(newSession => {
          setToken(newSession.getIdToken().getJwtToken());
          const newExpiresInMs = newSession.getAccessToken().payload.exp * 1000 - Date.now() + 1000;
          watchToken({
            expiresInMs: newExpiresInMs
          });
        });
      }, expiresInMs);
    };

    watchToken({
      expiresInMs: 0
    });

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  return token;
};
