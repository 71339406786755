import { customerRepSearch } from 'services/API/customerRep';
import { employeeSearch } from 'services/API/employee';

import { generateDefaultFieldsObject } from '../../../components/formattingUtils';

const margin = '0px 15px 21px 0px';

// General Information
const generalInformationFields = {
  date: { required: true, nullable: true },
  subject: { required: true },
  status: { required: false },
  number: { required: true },
  SendTo: { required: true },
  ReturnTo: { required: true },
  scopeOfWork: { required: true },
  daysValid: { required: true },
  scheduleExtensionRequested: { required: true },
  scheduleAdditionalInfo: { required: false }
};

const generalInformationContents = (selectedCustomer, project) => [
  // the 1st section title
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1
    },
    contents: [
      {
        contents: [
          {
            component: {
              default: 'sectionTitle',
              edit: 'sectionTitle'
            },
            options: {
              label: 'General Information'
            }
          }
        ]
      }
    ]
  },

  // the 1st row
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1,
      flexWrap: 'wrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            component: {
              default: 'DateInput',
              edit: 'DateInput'
            },
            source: 'date',
            options: {
              label: 'Date',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            component: {
              default: 'SelectInput',
              edit: 'SelectInput'
            },
            source: 'status',
            options: {
              label: 'Status',
              placeholder: '',
              enableSort: false,
              valueSet: [
                { label: 'Draft', value: 'Draft' },
                { label: 'Sent for Approval', value: 'Sent for Approval' },
                { label: 'Responded', value: 'Responded' }
              ],
              isRequired: true,
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar',
              edit: 'SearchBar'
            },
            source: 'SendTo',
            options: {
              resultFormatFunction: customerRep => customerRep.name,
              searchFunction: (value, searchCols) =>
                customerRepSearch(value, searchCols, project?.customer?.id || undefined, project),
              sortFunction: (a, b) => (a.name > b.name ? 1 : -1),
              label: 'Send To',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true,
              errorText: 'Field is required',
              placeholder: 'Search contacts'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar',
              edit: 'SearchBar'
            },
            source: 'ReturnTo',
            options: {
              resultFormatFunction: employee => employee.name,
              searchFunction: employeeSearch,
              filterFunction: data => data.filter(item => item.status === 'active'),
              sortFunction: (a, b) => (a.name > b.name ? 1 : -1),
              searchParams: ['name'],
              label: 'Return to',
              placeholder: 'Search contacts',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true,
              errorText: 'Field is required'
            }
          }
        ]
      }
    ]
  },

  // the 2nd row, subject field
  {
    options: {
      size: 2,
      direction: 'row',
      grow: 1,
      flexWrap: 'wrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            component: {
              default: 'TextInput',
              edit: 'TextInput'
            },
            source: 'subject',
            options: {
              label: 'Subject',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true
            }
          }
        ]
      }
    ]
  },
  // third row, description row
  {
    options: {
      size: 2,
      direction: 'row',
      grow: 1,
      flexWrap: 'wrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            component: {
              default: 'TextInput',
              edit: 'TextInput'
            },
            source: 'scopeOfWork',
            options: {
              label: 'Scope of work',
              fullWidth: true,
              lines: 4,
              alignment: 'left',
              placeholder: 'Enter Scope of Work',
              variant: 'standard',
              color: 'secondary',
              isRequired: true
            }
          }
        ]
      }
    ]
  },

  // the 4th row
  {
    options: {
      size: 4,
      direction: 'row',
      grow: 0
    },
    contents: [
      {
        options: {
          size: 1,
          direction: 'row',
          grow: 1,
          flexWrap: 'wrap'
        },
        contents: [
          {
            options: {
              direction: 'column',
              margin
            },
            contents: [
              {
                component: {
                  default: 'TextInput',
                  edit: 'TextInput'
                },
                source: 'daysValid',
                options: {
                  label: 'Days valid',
                  fullWidth: true,
                  alignment: 'left',
                  variant: 'standard',
                  color: 'secondary',
                  isRequired: true
                }
              }
            ]
          },
          {
            options: {
              direction: 'column',
              margin
            },
            contents: [
              {
                component: {
                  default: 'TextInput',
                  edit: 'TextInput'
                },
                source: 'scheduleExtensionRequested',
                options: {
                  label: 'Schedule extension',
                  fullWidth: true,
                  alignment: 'left',
                  variant: 'standard',
                  color: 'secondary',
                  isRequired: true
                }
              }
            ]
          },
          {
            options: {
              direction: 'column',
              margin
            },
            contents: [
              {
                component: {
                  default: 'TextInput',
                  edit: 'TextInput'
                },
                source: 'gcChangeOrderNumber',
                options: {
                  label: 'GC Change Order Number',
                  fullWidth: true,
                  alignment: 'left',
                  variant: 'standard',
                  color: 'secondary'
                }
              }
            ]
          },
          {
            options: {
              direction: 'column',
              margin
            },
            contents: [
              {
                component: {
                  default: 'TextInput',
                  edit: 'TextInput'
                },
                source: 'gcPurchaseOrderNumber',
                options: {
                  label: 'GC Purchase Order Number',
                  fullWidth: true,
                  alignment: 'left',
                  variant: 'standard',
                  color: 'secondary'
                }
              }
            ]
          }
        ]
      }
    ]
  },

  // divider
  {
    options: {
      margin: [9, 0, 0, 0]
    },
    contents: [
      {
        component: {
          default: 'Divider'
        }
      }
    ]
  }
];

const layoutGeneral = (selectedCustomer, project) => {
  return {
    fields: {
      ...generateDefaultFieldsObject(generalInformationFields)
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [...generalInformationContents(selectedCustomer, project)]
      },
      edit: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [...generalInformationContents(selectedCustomer, project)]
      }
    }
  };
};

export { generalInformationFields, layoutGeneral };
