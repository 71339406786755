/* eslint-disable no-template-curly-in-string */
import { number, object, string } from 'yup';

import { roundCurrency } from 'utils';
import { AccountingApp } from 'utils/constants';

const accountingAppLimit = {
  [AccountingApp.INTACCT]: 200,
  [AccountingApp.QUICKBOOKS]: 11
};

export default accountingApp => {
  return object({
    number: string()
      .nullable()
      .max(accountingAppLimit[accountingApp] || 255, "Can't exceed ${max} characters.")
      .required('Adjustment number is required.'),
    billingCustomer: object({
      customerName: string().required('Billing customer is required.')
    }),
    adjustmentType: object({
      value: string()
    })
      .nullable()
      .required('Adjustment type is required.'),
    department: object({
      value: string()
    })
      .nullable()
      .required('Department is required.'),
    amount: number()
      .nullable()
      .test({
        name: 'notEnough',
        exclusive: false,
        params: {},
        message: "Can't be lower than the applied amount",
        test(amount) {
          return roundCurrency(amount) >= this.parent.appliedAmount;
        }
      })
      .moreThan(0, 'Amount must be greater than 0.')
      .required('Amount is required.'),
    appliedAmount: number().nullable(),
    date: number()
      .nullable()
      .required('Date is required.'),
    taxLedgerAccount:
      accountingApp === AccountingApp.INTACCT
        ? object({
            value: string()
          })
            .nullable()
            .when('taxRate', (taxRate, schema) =>
              taxRate?.taxRate ? schema.required('GL Account for tax is required.') : schema
            )
        : undefined,
    taxRate: object({
      taxRate: number().nullable()
    }).nullable()
  });
};
