import React from 'react';

import { Chip, useTheme } from '@material-ui/core';
import { useGridSlotComponentProps } from '@mui/x-data-grid-pro';

import { StatusValToLabelMapping } from 'utils/AppConstants';

import { operatorLabel } from './columnTypes';

const useStyles = () => {
  const { palette, spacing } = useTheme();
  return {
    container: {
      marginTop: spacing(1),
      marginBottom: spacing(1),
      display: 'flex',
      flexWrap: 'wrap',
      flex: '1 1 0',
      gap: spacing(0.5)
    },
    chip: {
      backgroundColor: palette.text.disabled
    }
  };
};

export const getValidFilters = items =>
  items.filter(f => ['isEmpty', 'isNotEmpty'].includes(f.operatorValue) || f.value !== undefined);

const FilterChips = ({ style }) => {
  const { apiRef } = useGridSlotComponentProps();
  const { filter, columns } = apiRef.current.state;
  const styles = useStyles();

  const chips = getValidFilters(filter.items).map(f => {
    const colDef = columns.lookup[f.columnField];
    const col = colDef?.headerName || f.columnField;
    const op = operatorLabel[f.operatorValue] ?? f.operatorValue;
    const val = ['isEmpty', 'isNotEmpty'].includes(f.operatorValue) ? undefined : f.value;
    let label;
    if (colDef?.enumType) {
      label = StatusValToLabelMapping(colDef.enumType, f.value);
    }
    return { item: f, label: `${col} ${op}${val ? ` ${label || val}` : ''}` };
  });

  return (
    <div css={styles.container} style={style}>
      {chips.map(c => (
        <Chip
          css={styles.chip}
          label={c.label}
          onDelete={() => apiRef.current.deleteFilter(c.item)}
        />
      ))}
    </div>
  );
};

export default FilterChips;
