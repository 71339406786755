import React from 'react';

import { Button, Checkbox, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-cycle
import { AlgoliaSearch, Context, ResponsiveTable } from 'components';
import ConfirmModal from 'components/Modal/ConfirmDialog';
import Spinner from 'components/Spinners/CircularIndeterminate';
import { productItemsRows, viewProductItemsRows } from 'meta/Products/tables';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { checkPermission, roundFloat } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';

import { bundleIndex as defaultProductIndex } from '../../constants/algoliaIndex';
import { useGetPricebookById } from '../../customHooks/useGetPricebookById';
import { calculateUnitPriceWithMarkupValue, determineMarkupValue } from '../../utils/pricebooks';
import CostMarkup from '../CostMarkup';
import { useLazyFetchPricebookEntry } from '../Tasks/components/useLazyFetchPricebookEntry';

const styles = makeStyles(theme => ({
  pageActionButton: {
    float: 'right',
    width: '176px',
    height: '36px',
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(3.75),
    marginLeft: theme.spacing(2.5)
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

function AddProductsFromSearch(props) {
  const classes = styles();
  const { handleSave, handleClose, handleDeleteProduct } = props;
  const [refreshCounter, setRefreshCounter] = React.useState(0);
  const [addToProductTable, setAddToProductTable] = React.useState(props.productArray || []);
  const [oldProdArray, setOldProdArray] = React.useState();
  const [confirmDelete, setConfirmDelete] = React.useState({});
  const [btnClicked, setBtnClicked] = React.useState(false);
  const [totals, setTotals] = React.useState(0);
  const fetchPricebookEntry = useLazyFetchPricebookEntry();
  const getPricebookById = useGetPricebookById();
  const priceBookId = Context.getCompanyContext().getCompany?.defaultPriceBookId;

  const handleCancelConfirmation = () =>
    setConfirmDelete({ confirmMessage: '', confirmAction: '', confirmDialog: '' });

  React.useEffect(() => {
    if (oldProdArray !== props.productArray) {
      setAddToProductTable(props.productArray);
      setOldProdArray(props.productArray);
      setRefreshCounter(refreshCounter + 1);
      const productTotal = props.productArray.reduce(
        (sum, item) => sum + (item.unitPrice || 0) * (item.quantity || 0),
        0
      );
      setTotals(productTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
    }
  }, [oldProdArray, props.productArray, refreshCounter]);

  const formatAlgoliaSelection = async selection => {
    const markupValue = await determineMarkupValue({
      fetchPricebookEntry,
      getPricebookById,
      pricebookId: priceBookId,
      productSortKey: selection.sortKey,
      unitCost: selection.unitCost
    });
    const unitPrice = calculateUnitPriceWithMarkupValue({
      unitCost: selection.unitCost,
      markupValue
    });

    return {
      id: selection.id,
      sortKey: selection.sortKey,
      partitionKey: selection.partitionKey,
      entityType: selection.entityType,
      hierarchy: selection.hierarchy,
      unitCost: roundFloat(selection.unitCost),
      unitPrice,
      markupValue,
      isActive: selection.isActive,
      costCodeId: selection.costCodeId,
      revenueTypeId: selection.revenueTypeId,
      jobCostTypeId: selection.jobCostTypeId,
      taxable: selection.taxable,
      name: selection.name,
      description: selection.description,
      code: selection.code,
      quantity: selection.quantity,
      unitOfMeasure: selection.unitOfMeasure,
      version: selection.version
    };
  };

  const updateProductTable = async selection => {
    if (!selection) return;
    const value = await formatAlgoliaSelection(selection);
    let isValueUpdated = false;
    const updatedProdTable = addToProductTable.map(row => {
      let localRow = row;
      if (row.id === value.id) {
        localRow = value;
        isValueUpdated = true;
      }
      return localRow;
    });
    if (!isValueUpdated) {
      updatedProdTable.unshift(value);
    }
    if (props.handleChange) {
      props.handleChange(updatedProdTable);
    } else {
      setAddToProductTable(updatedProdTable);
      setRefreshCounter(refreshCounter + 1);
    }
  };

  const InputComponent = valueProps => {
    const { record, meta } = valueProps;
    const [item, setItem] = React.useState(record[meta.id]);
    return (
      <TextField
        label={meta.label}
        name={meta.id}
        style={{ width: 90, paddingRight: 4, marginBottom: -6 }}
        value={item}
        variant="filled"
        onBlur={event => {
          record[meta.id] = event.target.value || 0;
          updateProductTable(record);
          return record;
        }}
        onChange={event => {
          setItem(event.target.value);
        }}
      />
    );
  };

  const CheckBoxComponent = valueProps => {
    const { record, meta } = valueProps;
    const [item, setItem] = React.useState(record[meta.id]);
    return (
      <Checkbox
        checked={item}
        className={classes.checkboxStyle}
        value={item}
        onChange={event => {
          setItem(event.target.checked);
          record[meta.id] = event.target.checked;
          updateProductTable(record);
          return record;
        }}
      />
    );
  };

  const CostMarkupComponent = valueProps => {
    const { record } = valueProps;
    return <CostMarkup handleChange={updateProductTable} values={record} />;
  };

  const deleteAction = async record => {
    if (handleDeleteProduct && record.entityType !== 'PriceBookEntry') {
      await handleDeleteProduct(record);
    }

    setConfirmDelete({ confirmMessage: '', confirmAction: '', confirmDialog: '' });
    const modifiedData = addToProductTable.filter(item => item.id !== record.id);
    if (props.handleChange) {
      props.handleChange(modifiedData);
    } else {
      setAddToProductTable(modifiedData);
      setRefreshCounter(refreshCounter + 1);
    }
  };

  const DeleteButton = valueProps => {
    const { record } = valueProps;
    if (!props.viewOnly) {
      return (
        <IconButton
          aria-label="Delete"
          onClick={() =>
            setConfirmDelete({
              confirmAction: () => deleteAction(record),
              confirmMessage: 'product',
              confirmDialog: true
            })
          }
        >
          <DeleteIcon className={classes.iconColor} color="secondary" />
        </IconButton>
      );
    }
    return null;
  };

  return (
    <ErrorBoundaries>
      <Grid alignItems="center" container>
        {!props.viewOnly && (
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <AlgoliaSearch
              delimiter=" - "
              displayText={['name', 'code', 'description']}
              filters={[
                {
                  attribute: 'entityType',
                  valueArray: ['Product']
                }
              ]}
              handleChange={updateProductTable}
              label={props.overrideLabel || 'Select products'}
              locatedInPopUp
              name="selectProduct"
              searchIndex={defaultProductIndex}
              value=""
            />
          </Grid>
        )}
        {checkPermission('allow', PermissionConstants.DATA_VIEW_PRICE_DATA, props.user) && (
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Typography style={!props.viewOnly ? { marginLeft: 10 } : null}>
              Items total: $ {totals}
            </Typography>
          </Grid>
        )}
        <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
          <ResponsiveTable
            customCellComponents={{
              input: InputComponent,
              costMarkup: CostMarkupComponent,
              deleteButton: DeleteButton,
              checkbox: CheckBoxComponent
            }}
            data={addToProductTable}
            disableFilter
            disablePagination
            key={refreshCounter}
            noDataMsg="No items"
            noEmptyRows
            rowMetadata={props.viewOnly ? viewProductItemsRows : productItemsRows}
          />
        </Grid>
        {!props.viewOnly && props.handleSave && props.handleClose && (
          <Grid item lg={12} md={12} sm={12} style={{ padding: 0 }} xl={12} xs={12}>
            <Grid container direction="row-reverse" spacing={1}>
              <Grid item>
                <Button
                  className={classes.pageActionButton}
                  color="secondary"
                  disabled={btnClicked}
                  variant="contained"
                  onClick={e => {
                    e.preventDefault();
                    setBtnClicked(true);
                    handleSave(addToProductTable);
                  }}
                >
                  {btnClicked && <Spinner className={classes.buttonProgress} size={24} />}
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.pageActionButton}
                  color="secondary"
                  variant="outlined"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <ConfirmModal
        cancel={handleCancelConfirmation}
        confirm={confirmDelete.confirmAction}
        message={confirmDelete.confirmMessage}
        open={confirmDelete.confirmDialog}
      />
    </ErrorBoundaries>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const ReduxConnectedAddProductsFromSearch = connect(
  mapStateToProps,
  mapDispatcherToProps
)(AddProductsFromSearch);

export default ReduxConnectedAddProductsFromSearch;
