import React, { useMemo } from 'react';

import { ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { orderBy } from 'lodash';

import WrapTable, { BoldCell } from 'components/WrapTable';
import useEmployees from 'customHooks/useEmployees';
import useLabourTypes from 'customHooks/useLabourTypes';
import { convertToCurrencyString } from 'utils';

import { SectionHeader, VisitSubjectField } from '../Components';
import { tableCurrencyFormatter, timeSubmittedDisplay } from '../utils';

const getColumns = isSingleVisit => {
  const columns = [
    { field: 'technician', headerName: 'Technician' },
    { field: 'timeSubmitted', headerName: 'Time Submitted', width: 125 },
    { field: 'payrollHourType', headerName: 'Payroll Hour Type', width: 140 },
    {
      field: 'subtotal',
      headerName: 'Subtotal',
      width: 100,
      valueFormatter: tableCurrencyFormatter,
      renderCell: BoldCell,
      totalGetter: ({ rows }) => rows.reduce((acc, r) => acc + r.subtotal, 0),
      renderTotal: props => (
        <Typography
          css={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}
          numeric
          variant={TV.BASE}
          weight={TW.BOLD}
        >
          {convertToCurrencyString(props.formattedValue ?? 0)}
        </Typography>
      )
    }
  ];

  if (!isSingleVisit) {
    return [
      {
        field: 'subject',
        headerName: 'Source',
        width: 230,
        renderCell: ({ row }) => VisitSubjectField({ ...row.subject })
      },
      ...columns
    ];
  }

  return columns;
};

const getTimesheetData = (visit, timesheet, labourTypes, tech) => {
  const duration = Number.isInteger(timesheet.actualTotalDurationOverride)
    ? timesheet.actualTotalDurationOverride
    : timesheet.actualTotalDuration;
  const durationHours = duration / 3600;
  const durationDisplayText = durationHours > 0 ? `${durationHours} hr` : 'N/A';
  const labourType = labourTypes.find(({ id }) => id === tech?.labourTypeId);

  return {
    technician: `${tech?.name} (${labourType?.name})`,
    timeSubmitted: timeSubmittedDisplay({
      timesheetManualStatus: timesheet?.manualStatus,
      employeeId: tech?.id,
      durationDisplayText,
      duration,
      scheduledFor: visit.scheduledFor
    }),
    payrollHourType: timesheet.hourType?.hourType || '-',
    payrollHourTypeSortOrder: timesheet.hourType?.sortOrder,
    subtotal: timesheet.cost * durationHours,
    visitNumber: visit.visitNumber
  };
};

const mapVisitsToRows = ({ visits, labourTypes, employees }) => {
  const rows = [];
  visits.forEach(visit => {
    const timesheetEntries = visit.timesheetEntriesView?.items || [];
    const timesheetEntryBinders = visit.timesheetEntryBinders?.items || [];
    if (timesheetEntryBinders.length === 0) {
      timesheetEntries
        .filter(t => (t.actualTotalDuration || t.actualTotalDurationOverride) && !t.nonVisitEventId)
        .forEach(timesheet => {
          const tech = employees.find(e => e.id === timesheet.timesheetPeriod.parentId);
          rows.push({
            subject: {
              ...visit
            },
            ...getTimesheetData(visit, timesheet, labourTypes, tech)
          });
        });
    } else {
      timesheetEntryBinders
        .filter(binder => !binder.isDismissed)
        .forEach(binder => {
          const binderTimesheetEntries = binder.timesheetEntries?.items || [];
          const tech = employees.find(e => e.id === binder.employeeId);
          binderTimesheetEntries
            .filter(t => t.actualTotalDurationOverride || t.actualTotalDuration)
            .forEach(timesheet => {
              rows.push({
                subject: {
                  ...visit
                },
                ...getTimesheetData(visit, timesheet, labourTypes, tech)
              });
            });
        });
    }
  });

  const billableEventRows = [];
  visits.forEach(visit => {
    const nonVisitEvents = visit.nonVisitEvents?.items || [];
    nonVisitEvents.forEach(event => {
      const timesheets = event.timesheetEntries?.items || [];
      const eventTimesheetEntryBinders = event.timesheetEntryBinders?.items || [];
      if (eventTimesheetEntryBinders.length === 0) {
        timesheets
          .filter(t => t.actualTotalDurationOverride || t.actualTotalDuration)
          .forEach(timesheet => {
            billableEventRows.push({
              subject: {
                ...visit,
                isBillableEvent: true
              },
              ...getTimesheetData(visit, timesheet, labourTypes, event.employee)
            });
          });
      } else {
        eventTimesheetEntryBinders
          .filter(binder => !binder.isDismissed)
          .forEach(binder => {
            const binderTimesheetEntries = binder.timesheetEntries?.items || [];
            binderTimesheetEntries
              .filter(t => t.actualTotalDurationOverride || t.actualTotalDuration)
              .forEach(timesheet => {
                billableEventRows.push({
                  subject: {
                    ...visit,
                    isBillableEvent: true
                  },
                  ...getTimesheetData(visit, timesheet, labourTypes, event.employee)
                });
              });
          });
      }
    });
  });
  return [
    ...orderBy(rows, ['visitNumber', 'technician', 'payrollHourTypeSortOrder']),
    ...orderBy(billableEventRows, ['visitNumber', 'technician', 'payrollHourTypeSortOrder'])
  ];
};

const VisitLaborTable = ({ visits, isMultiVisits, isLoading }) => {
  const [labourTypes, labourTypesLoading] = useLabourTypes();
  const [employees, employeesLoading] = useEmployees();

  const rows = useMemo(
    () =>
      !labourTypesLoading && !employeesLoading
        ? mapVisitsToRows({ visits, labourTypes, employees })
        : [],
    [visits, labourTypes, labourTypesLoading, employees, employeesLoading]
  );
  const columns = useMemo(
    () =>
      getColumns(
        !isMultiVisits &&
          visits
            .map(visit =>
              visit.nonVisitEvents?.items?.map(event => event.timesheetEntries?.items || [])
            )
            .flat().length < 0
      ),
    [isMultiVisits, visits]
  );

  return (
    <ThemeProvider>
      <SectionHeader title="Labor" />
      <WrapTable
        columns={columns}
        enableTotalsRow
        hideFooter={rows.length <= 10}
        loading={isLoading}
        loadingRows={3}
        noDataMessage="No labor data"
        rows={rows}
      />
    </ThemeProvider>
  );
};

VisitLaborTable.defaultProps = {
  visits: [],
  isLoading: false
};

export default VisitLaborTable;
