import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DefaultButton, SergeantModal, UserPermission } from 'components';
import WrapTable from 'components/WrapTable';
import ErrorBoundaries from 'scenes/Error';
import CustomDataCard from 'scenes/ProjectManagement/components/CustomDataCard';
import CustomHighlightCard from 'scenes/ProjectManagement/components/CustomHighlightCard';
import SubmenuTitle from 'scenes/ProjectManagement/components/SubmenuTitle';
import { rfiChange } from 'services/API/rfi';
import { useDeleteRfi, useGetRfis } from 'services/APIHooks';
import { PermissionConstants } from 'utils/AppConstants';

import GenerateRFI from './GenerateRFI';
import RFIDetailView from './RFIDetailView';
import { getRFIMetadata } from './RFIList.layout';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 17
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: 20,
    marginBottom: 30
  }
}));

const RFIList = props => {
  const classes = useStyles();
  const { projectId, mode, projectData, rfiId } = props;
  const [isOpenedGenerateRFIModal, setIsOpenedGenerateRFIModal] = useState(false);
  const [isDetailViewOpen, setIsDetailViewOpen] = useState(!!rfiId);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedEditItem, setSelectedEditItem] = useState({});
  const [avgDays, setAvgDays] = useState(0);
  const [overdueRFIs, setOverdueRfis] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState('draft');

  const getOverdueDays = rfi => {
    if (rfi.rfiAnswerDate) {
      return Math.round(Math.max(0, moment().diff(moment.unix(rfi.dueDate), 'days')));
    }
    return Math.max(0, moment().diff(moment.unix(rfi.dueDate), 'days'));
  };

  const [{ data: rfis, loading: isLoading }, getAllRFIs] = useGetRfis(
    {
      transform: data => {
        return data.map(rfi => {
          return {
            ...rfi,
            dueDateText: rfi.dueDate ? moment.unix(rfi.dueDate).format('MM/DD/YYYY') : null,
            sendToName: rfi?.SendTo?.name || rfi?.SendTo?.customerName || rfi?.sendToName,
            rfiAnswerDateText: rfi.rfiAnswerDate
              ? moment.unix(rfi.rfiAnswerDate).format('MM/DD/YYYY')
              : null
          };
        });
      },
      onSuccess: data => {
        let tempAvgDays = 0;
        let tempOverdue = 0;
        data.forEach(item => {
          const overdueDays = getOverdueDays(item);
          if (overdueDays > 0) {
            tempAvgDays += overdueDays;
            tempOverdue += 1;
          }
        });
        if (tempOverdue > 0) {
          tempAvgDays /= tempOverdue;
        }
        setAvgDays(tempAvgDays);
        setOverdueRfis(tempOverdue);
      },
      depends: [projectId]
    },
    {
      where: { projectId },
      include: '*'
    }
  );

  const [, rfiDelete] = useDeleteRfi({ onSuccess: () => {} });

  const [confirmDelete, setConfirmDelete] = useState({
    confirmAction: () => {},
    confirmDialog: false,
    confirmTitle: ''
  });

  const handleCancelConfirmation = () =>
    setConfirmDelete({ confirmAction: () => {}, confirmDialog: false, confirmTitle: '' });

  const deleteAction = async (record, stopLoading) => {
    await rfiDelete({ url: `rfis/${record.id}` });
    await getAllRFIs();
    setConfirmDelete({ confirmAction: () => {}, confirmDialog: false, confirmTitle: '' });
    stopLoading();
  };

  const handleEdit = async record => {
    setIsEditMode(true);
    setSelectedEditItem({ ...record });
    setIsOpenedGenerateRFIModal(true);
  };

  const handleDelete = async record => {
    setConfirmDelete({
      confirmAction: (data, stopLoading) => deleteAction(record, stopLoading),
      confirmDialog: true,
      confirmTitle: `Delete RFI ${record.number}`
    });
  };

  const cardItems = [
    {
      highlight: Math.round(avgDays),
      title: 'Average Days Overdue'
    },
    {
      highlight: overdueRFIs,
      title: 'Overdue RFIs'
    }
  ];

  return (
    <ErrorBoundaries>
      {/* --------------- RFI main view --------------- */}
      <UserPermission action={PermissionConstants.OBJECT_PM_RFI} I="read">
        <RFIDetailView
          handleClose={() => {
            getAllRFIs();
            setIsDetailViewOpen(false);
          }}
          open={isDetailViewOpen}
          projectId={projectId}
          rfiId={rfiId}
        />
        <SubmenuTitle>RFIs</SubmenuTitle>
        <div className={classes.root}>
          <div className={classes.toolbarContainer}>
            {/* TODO: search function */}
            {/* <CustomSearchBar key="rfiSearch" placeholder="Search RFIs" /> */}
            <div />
            <DefaultButton
              buttonProps={{ startIcon: <AddCircleOutlineIcon style={{ fontSize: 14 }} /> }}
              key="generateRFIBtn"
              label="Generate RFI"
              style={{ height: 30, fontSize: 12 }}
              variant="containedPrimary"
              onClick={() => {
                setSelectedEditItem({});
                setIsEditMode(false);
                setIsOpenedGenerateRFIModal(true);
              }}
            />
          </div>
          <div>
            <SergeantModal
              alignCloseRight
              customComponents={{}}
              customPrimaryButtonLabel="Delete"
              dataType="Item"
              handleClose={handleCancelConfirmation}
              handlePrimaryAction={confirmDelete.confirmAction}
              mode="delete"
              open={confirmDelete.confirmDialog}
              title={confirmDelete.confirmTitle}
            />
            <Grid container spacing={2}>
              <Grid container direction="row">
                {['Draft', 'Sent', 'Responded', 'Closed'].map(statusName => (
                  <CustomHighlightCard
                    handleClick={() => {
                      setSelectedStatus(statusName.toLowerCase());
                    }}
                    highlight={rfis.filter(rfi => rfi.status === statusName.toLowerCase()).length}
                    selected={selectedStatus === statusName.toLowerCase()}
                    title={statusName}
                  />
                ))}
                <CustomDataCard items={cardItems} />
              </Grid>
              <Grid item xs={12}>
                <WrapTable
                  columns={getRFIMetadata(
                    selectedStatus,
                    mode,
                    projectId,
                    handleEdit,
                    handleDelete
                  )}
                  loading={isLoading}
                  noDataMessage={`No '${selectedStatus.toUpperCase()}' RFIs`}
                  rows={rfis
                    .filter(rfi => rfi.status === selectedStatus)
                    .map(item => {
                      const tableData = {
                        ...item,
                        daysOverdue: getOverdueDays(item)
                      };
                      return tableData;
                    })}
                />
              </Grid>
            </Grid>
          </div>
        </div>

        {/* --------------- RFI Generate view --------------- */}
        <GenerateRFI
          editMode={isEditMode}
          handleClose={() => {
            getAllRFIs();
            setIsEditMode(false);
            setIsOpenedGenerateRFIModal(false);
          }}
          initialData={{ itemData: { ...selectedEditItem } }}
          open={isOpenedGenerateRFIModal}
          projectData={projectData}
          projectId={projectId}
        />
      </UserPermission>
    </ErrorBoundaries>
  );
};

RFIList.propTypes = {
  projectId: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  projectData: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => ({
  projectData: state.pm.project,
  mode: props.match.params.mode,
  projectId: props.match.params.projectId,
  rfiId: props.match.params.subpage
});

export default connect(mapStateToProps)(RFIList);
