import React, { useEffect, useState } from 'react';

import { array, func, object, string } from 'prop-types';

import { EVENT_TYPES } from '@dispatch/Dispatch.constants';
import { useFormService } from '@dispatch/hooks';

import { getState } from 'redux/store';
import { getTenantSettingValueForKey } from 'utils';

import DrawerLayout from '../DrawerLayout';
import EventBadge from '../EventBadge';
import JobDetailsHeader from '../JobDetailsHeader';
import VisitDetailsForm from '../VisitDetailsForm';

import JobSearchHeader from './components/JobSearchHeader';

const CreateVisit = ({
  closeDrawer,
  crewMembersResponse,
  jobNumber,
  setJobNumber,
  selVisitData,
  addVisitTuple,
  addMultipleVisitsTuple,
  jobDetailsResponse
}) => {
  const { tenantId, tenantCompanyId } = getState().user;
  const { data: job, loading: fetchingJobDetails } = jobDetailsResponse;
  const [triggerAddVisitMutation, addVisitResponse] = addVisitTuple;
  const [triggerAddMultipleVisits, addMultipleVisitsResponse] = addMultipleVisitsTuple;
  const { isDirty, onCreateService, onDirtyChange, resetForm, submitForm } = useFormService();
  const { loading: submittingVisit } = addVisitResponse;
  const { loading: submittingMultipleVisits } = addMultipleVisitsResponse;
  const loading = submittingVisit || submittingMultipleVisits || fetchingJobDetails;
  const disabled = !job || loading;
  const hasJobSelected = !!jobNumber && !!job;
  const crewTimeTracking = getTenantSettingValueForKey('crewTimeTracking') === 'true';
  const [initialVisitData, setInitialVisitData] = useState(selVisitData);
  const [crewMembersAdded, setCrewMembersAdded] = useState(false);

  useEffect(() => {
    if (crewTimeTracking && !crewMembersResponse.loading && crewMembersResponse.data) {
      setCrewMembersAdded(!!(!selVisitData.extraTechs && crewMembersResponse?.data.length));

      setInitialVisitData({
        ...initialVisitData,
        extraTechs: selVisitData.extraTechs ?? [...crewMembersResponse.data]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crewMembersResponse, crewTimeTracking]);

  const handleFormReset = () => {
    setJobNumber(null);
    resetForm();
  };

  const createVisit = (visit, shouldCreateMultipleVisits) => {
    if (shouldCreateMultipleVisits) {
      triggerAddMultipleVisits({
        tenantId,
        tenantCompanyId,
        visit,
        job
      });
    } else {
      triggerAddVisitMutation({
        tenantId,
        tenantCompanyId,
        visit,
        job
      });
    }
    closeDrawer();
    handleFormReset();
  };

  const renderHeader = () => {
    return (
      <>
        <EventBadge eventType={EVENT_TYPES.VISIT} />
        {hasJobSelected ? (
          <JobDetailsHeader job={job} />
        ) : (
          <JobSearchHeader onJobSelected={setJobNumber} />
        )}
      </>
    );
  };

  const renderBody = () => (
    <VisitDetailsForm
      allowMultipleVisitsCreation
      collapseDetails
      crewMembersAdded={crewMembersAdded}
      crewTimeTracking={crewTimeTracking}
      disabled={disabled}
      job={job}
      visit={initialVisitData}
      visitLoading={loading}
      onComplete={createVisit}
      onCreateService={onCreateService}
      onDirtyChange={onDirtyChange}
    />
  );

  return (
    <DrawerLayout
      disabledClose={loading}
      disabledSave={!job || loading}
      isDirty={hasJobSelected || isDirty}
      loading={loading}
      renderBody={renderBody}
      renderHeader={renderHeader}
      onResetForm={handleFormReset}
      onSubmitForm={submitForm}
    />
  );
};

CreateVisit.propTypes = {
  closeDrawer: func.isRequired,
  jobNumber: string.isRequired,
  setJobNumber: func.isRequired,
  addVisitTuple: array.isRequired,
  addMultipleVisitsTuple: array.isRequired,
  jobDetailsResponse: object.isRequired
};

export default CreateVisit;
