import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const UPSERT_ADVANCED_MAINTENANCE_TEMPLATE_GROUP = gql`
  mutation upsertAdvancedMaintenanceTemplateGroup(
    $data: UpsertAdvancedMaintenanceTemplateGroupInput!
  ) {
    upsertAdvancedMaintenanceTemplateGroup(input: $data) {
      id
    }
  }
`;

const useUpsertAdvancedMaintenanceTemplateGroup = ({
  serviceAgreementId,
  onSuccess = () => {}
}) => {
  return useExtendedMutation(UPSERT_ADVANCED_MAINTENANCE_TEMPLATE_GROUP, {
    serializer: ({
      id,
      customerPropertyId,
      selectedCrew,
      primaryTechId,
      name,
      description,
      jobTypeId,
      singleJobTaskTemplateId,
      numberOfVisits,
      budgetedHours,
      additionalTechIds,
      jobTagIds,
      visitTemplates,
      advancedMaintenanceTemplateGroupSchedules
    }) => ({
      variables: {
        data: {
          id,
          serviceAgreementId,
          customerPropertyId,
          selectedCrew,
          primaryTechId,
          name,
          description,
          jobTypeId,
          singleJobTaskTemplateId,
          numberOfVisits,
          budgetedHours,
          additionalTechIds,
          jobTagIds,
          visitTemplates,
          advancedMaintenanceTemplateGroupSchedules
        }
      }
    }),
    onSuccess: (transformedData, snackbarOn) => {
      snackbarOn('success', 'Service Agreement Maintenance Template Group Saved');
      onSuccess();
    },
    onError: (transformedData, snackbarOn) =>
      snackbarOn('error', 'Unable to Save Service Agreement Maintenance Template Group')
  });
};

export default useUpsertAdvancedMaintenanceTemplateGroup;
