import { useCallback } from 'react';

import { useConfirmModal } from 'customHooks/ConfirmModalContext';

import { stepBackConfirmContent } from './EmailInfoStep.utils';

export const useGetBackStepHandler = () => {
  const confirmContext = useConfirmModal();
  return useCallback(() => confirmContext.confirm(stepBackConfirmContent), [confirmContext]);
};
