import React, { useCallback } from 'react';

import { Typography } from '@BuildHero/sergeant';
import { any, array, bool } from 'prop-types';
import { useSelector } from 'react-redux';

import WrapTable from 'components/WrapTable';
import { TablePlaceholderVariant } from 'components/WrapTable/WrapTable.constants';
import { PricingStrategy } from 'utils/constants';

import { selectInventoryParts } from '../../../../selectors';

import { useInventoryItemsColumns } from './InventoryItemsSection.hooks';
import { useStyles } from './InventoryItemsSection.styles';

const InventoryItems = ({
  items,
  loading,
  error,
  isReviewReport,
  handleInvoiceToggle,
  handleEditClick,
  handleDeleteClick
}) => {
  const marginEnabled = useSelector(s => s.settings.pricingStrategy === PricingStrategy.MARGIN);
  const styles = useStyles();
  const inventoryItems = useCallback(selectInventoryParts(items), [items]) || [];
  const columns = useInventoryItemsColumns({
    isReviewReport,
    handleDeleteClick,
    handleEditClick,
    handleInvoiceToggle,
    marginEnabled
  });

  return (
    <>
      {!isReviewReport && <Typography css={styles.title}>Inventory items</Typography>}
      <WrapTable
        columns={columns}
        error={error}
        loading={loading}
        noDataMessage="No Inventory Items"
        placeholderVariant={TablePlaceholderVariant.TEXT}
        rows={inventoryItems}
      />
    </>
  );
};

InventoryItems.propTypes = {
  items: array.isRequired,
  loading: bool.isRequired,
  error: any
};

InventoryItems.defaultProps = {
  error: undefined
};

export default InventoryItems;
