import { round, values } from 'lodash';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { logErrorWithCallback } from 'utils';

import { getLaborCostForJob } from './JobCostingReport.gql';

// returns array of array, grouped by the key passed [[rec1, rec2], [rec4, rec3]]
export const groupByKeyInArrayOfObjects = (data, key) =>
  values(
    data.reduce((result, accumulator) => {
      const localResult = result;
      const localKeyValue = accumulator[key];
      localResult[localKeyValue] = localResult[localKeyValue] || [];
      localResult[localKeyValue].push(accumulator);
      return localResult;
    }, {})
  );

const processJobCostingLabor = ({ getLabourCostForJob: data }) => {
  const groupedByLabourType = groupByKeyInArrayOfObjects(data, 'labourTypeId');
  const processedItems = [];
  /// group by labor type
  groupedByLabourType.forEach(eachLabourGroup => {
    const firstGroupEmp = eachLabourGroup?.[0];
    // to insert the group total
    const currentLength = processedItems.length;
    let totalDuration = 0;

    // group by employee under each labor group
    const employeeGrouped = groupByKeyInArrayOfObjects(eachLabourGroup, 'employeeId');
    employeeGrouped.forEach(employeeArr => {
      // For each labor and employee, multiple entries on diff hr type is possible
      employeeArr.forEach(emp => {
        const roundedDuration = round(emp?.duration || 0, 2);
        totalDuration += roundedDuration;
        const exisitingEmployee = processedItems?.find(e => e.employeeId === emp.employeeId);

        if (exisitingEmployee) {
          const totalHrs = exisitingEmployee.totalHrs + roundedDuration;
          exisitingEmployee[emp.hourTypeId] = roundedDuration;
          exisitingEmployee.totalHrs = round(totalHrs, 2);
        } else {
          processedItems.push({
            ...emp,
            display: emp?.name,
            [emp?.hourTypeId]: roundedDuration,
            totalHrs: roundedDuration
          });
        }
      });
    });

    processedItems.splice(currentLength, 0, {
      display: firstGroupEmp.labourTypeName,
      labourTypeId: firstGroupEmp.id,
      totalHrs: round(totalDuration, 2),
      isLaborTypeRow: true
    });
  });

  return processedItems;
};

export const useJobCostingLabor = ({ user, snackbarOn, jobId }) => {
  const { data, loading, error } = useExtendedQuery(getLaborCostForJob, {
    variables: { partitionKey: user.tenantId, jobId },
    fetchPolicy: 'network-only', // as lot of items can be removed and added to job in realtime.,
    transform: processJobCostingLabor
  });

  if (error) {
    logErrorWithCallback(
      error,
      snackbarOn,
      'unable to get the job costing report, please try again later.'
    );
  }
  return [data, loading];
};
