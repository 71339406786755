import { assign, Machine } from 'xstate';

import { roundCurrency } from 'utils';

import actions, { guards } from './page-actions';

const pageStateMachine = Machine(
  {
    context: {
      canView: true,
      jobData: '',
      taskData: '',
      pageStatus: ''
    },
    states: {
      idle: {
        id: 'ef58c1a5',
        on: {
          LOADING: {
            target: 'loading',
            actions: [assign({ pageStatus: 'loading' })]
          }
        }
      },
      access_denied: {
        type: 'final'
      },
      page_error: {
        type: 'final'
      },
      loading: {
        id: 'page_state_loading',
        invoke: {
          id: 'fetchJobFromDatabase',
          src: actions.fn_fetchVisitDetails,
          onDone: {
            target: 'loaded',
            actions: [
              assign((context, event) => {
                let purchaseOrders;
                let inventoryParts;
                let summaries;
                let attachments;
                let notes;
                let createdDateTime;
                let scheduledFor;
                let visitNumber;
                let customerSignatures;
                let timeSheets;
                let status;
                let description;
                let lastUpdatedBy;
                let lastUpdatedDateTime;
                let primaryTechs;
                let extraTechs;
                let departmentName;
                let reviewReports;
                let parentEntity;
                let sortKey;
                let timeCardLinesView;
                let timeCardVisitsView;
                let visitAssets;
                let timesheetNotes;
                let payrollSetting;
                let isAutomatedTimeTrackingEnabled;
                let timesheetEntries;
                let payrollHourTypes;
                let submittedBy;
                let submittedDate;
                let nonVisitEvents;

                if (event.data) {
                  let result = {};
                  event.data.forEach(item => {
                    const { reportId } = context;
                    if (reportId) {
                      result = { ...result, ...item.data.getVisitById };
                    } else {
                      result = { ...result, ...item.data.getVisit };
                    }

                    if (item?.data?.getCompany?.companySettings?.items) {
                      // extracting settings obj
                      const payrollSettingStr =
                        item.data.getCompany?.companySettings.items?.[0]?.settings || '{}';
                      payrollSetting = JSON.parse(payrollSettingStr);
                      isAutomatedTimeTrackingEnabled =
                        payrollSetting?.enableAutomatedTimeTracking || false;
                    }

                    payrollHourTypes = item?.data.getCompany?.payrollHourTypes?.items;

                    ({
                      purchaseOrders,
                      inventoryParts,
                      summaries,
                      attachments,
                      notes,
                      createdDateTime,
                      scheduledFor,
                      visitNumber,
                      customerSignatures,
                      timeSheets,
                      status,
                      parentEntity,
                      departmentName,
                      reviewReports,
                      sortKey,
                      submittedBy,
                      submittedDate,
                      description,
                      lastUpdatedBy,
                      lastUpdatedDateTime,
                      primaryTechs,
                      extraTechs,
                      timeCardLinesView,
                      timeCardVisitsView,
                      visitAssets,
                      timesheetNotes,
                      timesheetEntries,
                      nonVisitEvents
                    } = result);
                  });
                }
                // Unit of measure is defined at the product level. It should always referred from the product
                const processedInventoryItems = (inventoryParts?.items || []).map(item => {
                  const localUnitOfMeasure = item?.product?.unitOfMeasure?.name;
                  return {
                    ...item,
                    // existing logic from PartsAndMaterials moved to here
                    addedBy: item.addedBy || item.createdBy,
                    amount: roundCurrency(item.quantity * item.unitPrice),
                    unitOfMeasure: localUnitOfMeasure
                  };
                });
                return {
                  purchaseOrders: purchaseOrders.items || [],
                  inventoryParts: processedInventoryItems || [],
                  summaryNotes: summaries.items || [],
                  attachments: (attachments?.items || []).filter(
                    item => item.type && ['before', 'after'].includes(item.type.toLowerCase())
                  ),
                  notes: notes.items || [],
                  visitAssets: visitAssets.items || [],
                  visitCreatedDate: createdDateTime,
                  primaryTechs: primaryTechs || [],
                  extraTechs: extraTechs || [],
                  visitScheduledFor: scheduledFor,
                  visitNo: visitNumber,
                  departmentName,
                  description,
                  lastUpdatedBy,
                  lastUpdatedDateTime,
                  submittedBy,
                  submittedDate,
                  customerSignatures: customerSignatures.items || [],
                  timeSheets: timeSheets.items,
                  timeCardLines: timeCardLinesView.items,
                  timeCardVisits: timeCardVisitsView.items,
                  visitStatus: status,
                  companyAddresses:
                    parentEntity &&
                    parentEntity.parentEntity &&
                    parentEntity.parentEntity.companyAddresses,
                  reviewReports: reviewReports.items,
                  jobInfoData: {
                    ...parentEntity,
                    customJobNumber: parentEntity.customIdentifier || parentEntity.jobNumber
                  },
                  reportSortKey: sortKey,
                  showContent: true,
                  isAutomatedTimeTrackingEnabled,
                  timesheetEntries: timesheetEntries?.items || [],
                  visitTimesheetNotes: (timesheetNotes?.items || []).map(note => ({
                    ...note,
                    eventName: 'Visit'
                  })),
                  payrollHourTypes,
                  nonVisitEvents: nonVisitEvents?.items.filter(i => i.isActive) || []
                };
              })
            ]
          },
          onError: {
            target: 'page_error',
            actions: [actions.fn_queryErrorNotification]
          }
        }
      },
      loaded: {
        id: '7860429e',
        on: {
          ACCESS_DENIED: 'access_denied',
          CREATE_REVIEW_REPORT: 'review_report_new',
          VIEW_TECH_NOTES: {
            target: 'view_tech_notes',
            actions: assign({
              modalRecord: (context, event) => event.record
            })
          },
          RELOAD: {
            target: 'loading',
            actions: [
              assign((context, event) => ({
                reportId: event.reportId,
                reportIndex: event.reportIndex,
                prevReportId: event.prevReportId,
                nextReportId: event.nextReportId
              }))
            ]
          }
        }
      },
      view_tech_notes: {
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'view',
        states: {
          view: {
            on: {
              CLOSE: {
                target: 'closed',
                actions: assign({
                  modalRecord: ''
                })
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      review_report_new: {
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'saving',
        states: {
          saving: {
            invoke: {
              id: 'saveReviewReport',
              src: actions.fn_createReviewReport,
              onDone: {
                target: 'idle',
                actions: [
                  assign({
                    reviewReportId: (context, event) =>
                      // event.data && event.data[0].data.createReviewReportFromVisit.sortKey
                      event.data && event.data[0].data.createReviewReportFromVisit.id
                  }),
                  actions.fn_successMessage,
                  actions.fn_redirect_to_reviewReport
                ]
              },
              onError: {
                target: 'page_error',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          idle: {
            id: '091913'
          },
          page_error: {
            type: 'final'
          }
        }
      }
    },
    initial: 'idle'
  },
  { actions, guards }
);

export default pageStateMachine;
