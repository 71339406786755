import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MailIcon from '@material-ui/icons/Mail';
import SendIcon from '@material-ui/icons/Send';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { DefaultButton } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    width: 'calc(100vw - 260px)',
    padding: 17
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  titleBar: {
    display: 'flex'
  },
  mailIcon: {
    fontSize: 20,
    marginRight: 4
  },
  title: {
    fontSize: '20px',
    lineHeight: '20px',
    fontWeight: 700,
    letterSpacing: '-0.03em'
  },
  noMsgText: {
    fontSize: '20px',
    lineHeight: '20px',
    fontWeight: 500,
    letterSpacing: '-0.03em'
  },
  titleButton: {
    float: 'right'
  },
  messagesContainer: {
    marginTop: 8
  },
  messageCardContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    marginBottom: 10,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '-0.02em',
    cursor: 'pointer',
    borderRadius: '2px',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1))',
    '&:hover': {
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5))'
    }
  },
  messageUnread: {
    fontWeight: 700,
    background: theme.palette.common.white
  },
  messageRead: {
    background: theme.palette.grayscale(90)
  },
  recipient: {
    minWidth: 169,
    maxWidth: 169,
    padding: 18,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  emailContent: {
    minWidth: 400,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    flexShrink: '1',
    flexGrow: '1'
  },
  date: {
    minWidth: 83,
    padding: 18,
    textAlign: 'right'
  },
  showLabelContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    borderTop: `1px solid ${theme.palette.grayscale(90)}`
  },
  dividerContainer: {
    marginTop: theme.spacing(1.5)
  },
  showLabel: {
    width: 80,
    height: 20,
    marginTop: -10,
    fontSize: '10px',
    textTranform: 'uppercase',
    textDecoration: 'none',
    color: theme.palette.grayscale(60),
    background: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.grayscale(95)
    }
  },
  spacer: {
    paddingBottom: '30px'
  }
}));

const Preview = ({ data, classes, onClick }) => {
  return (
    <div
      className={classNames(
        classes.messageCardContainer,
        data.read ? classes.messageRead : classes.messageUnread
      )}
      role="button"
      tabIndex={0}
      onClick={() => onClick(data.id)}
      onKeyPress={event => {
        if (event.key === 'Enter') {
          onClick(data.id);
        }
      }}
    >
      <div className={classes.recipient}>{data.recipient}</div>
      <div className={classes.emailContent}>{data.subject}</div>
      <div className={classes.date}>
        {new Intl.DateTimeFormat('en-US', {
          month: 'short',
          day: 'numeric'
        }).format(data.date)}
      </div>
    </div>
  );
};

const Messages = ({ messageData, button, isLoading, buttonLabel, buttonAction, emailClicked }) => {
  const classes = useStyles();
  const [maxRows, setMaxRows] = useState(8);

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <div className={classes.titleBar}>
          <MailIcon className={classes.mailIcon} />
          <Typography className={classes.title}>Communication History</Typography>
        </div>
        <div className={classes.titleButton}>
          {button || (
            <DefaultButton
              buttonProps={{ startIcon: <SendIcon /> }}
              disabled={isLoading}
              label={buttonLabel}
              showSpinner={isLoading}
              variant="containedPrimary"
              onClick={buttonAction}
            />
          )}
        </div>
      </div>
      <div className={classes.messagesContainer}>
        {messageData?.length === 0 && (
          <div className={classes.noMsgText}>
            <Typography>No Messages Found</Typography>
          </div>
        )}
        {messageData.map(
          (message, index) =>
            index < maxRows && (
              <Preview
                classes={classes}
                data={message}
                key={`email-${message.recipient}-${index}`}
                // eslint-disable-next-line react/no-array-index-key
                onClick={id => emailClicked(id)}
              />
            )
        )}
      </div>
      {maxRows < messageData.length ? (
        <>
          <div className={classes.showLabelContainer}>
            <Button className={classes.showLabel} onClick={() => setMaxRows(maxRows + 5)}>
              SHOW MORE
            </Button>
          </div>
          <div className={classes.spacer} />
        </>
      ) : (
        <div className={classes.spacer} />
      )}
    </div>
  );
};

Preview.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

Messages.propTypes = {
  buttonLabel: PropTypes.string,
  messageData: PropTypes.array,
  buttonAction: PropTypes.func,
  emailClicked: PropTypes.func,
  button: PropTypes.string,
  isLoading: PropTypes.bool
};

Messages.defaultProps = {
  buttonLabel: '-',
  messageData: [],
  buttonAction: () => {},
  emailClicked: () => {},
  button: '',
  isLoading: false
};

export default Messages;
