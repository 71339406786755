import React from 'react';

import { Button, ButtonType, MUIForm, ThemeProvider } from '@BuildHero/sergeant';
import { Grid } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutlineOutlined';

import { FullScreenModal, SergeantModal } from 'components';
import { typeModalDefaultState } from 'constants/common';
import { Mode } from 'utils/constants';

import { DraggableContainer } from './DraggableContainer';

import styles from './styles';

export const DynamicItemWrapper = props => {
  const { user, snackbarOn, getAction, orderUpdateAction, disableAdd, refetchFn } = props;
  const { tenantId, tenantCompanyId } = user;
  const [items, setItems] = React.useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formService, setFormService] = React.useState();
  const [openTypeModal, setOpenTypeModal] = React.useState(typeModalDefaultState);

  React.useEffect(() => {
    const getItem = async () => {
      const response = await getAction(tenantId, tenantCompanyId, snackbarOn);
      setItems(response);
    };
    getItem();
  }, [getAction, tenantId, tenantCompanyId, snackbarOn]);

  const handleAction = async (filledFormData, onComplete = () => {}) => {
    setIsSubmitting(true);
    filledFormData.sortOrder = openTypeModal.sortOrder;
    const commonArguments = [filledFormData, tenantId, snackbarOn];

    if (openTypeModal.mode === Mode.DELETE) {
      const deletedType = await props.deleteAction(...commonArguments);
      const deletedItems = items.filter(item => item.id !== deletedType.id);
      setItems(deletedItems);
    }

    if (openTypeModal.mode === Mode.EDIT) {
      const updatedItem = await props.updateAction(...commonArguments);
      const updatedItemIndex = items.findIndex(item => item.id === updatedItem.id);
      items[updatedItemIndex] = updatedItem;
      setItems(items);
    }

    if (openTypeModal.mode === Mode.ADD) {
      const addedItemArr = await props.addAction(
        filledFormData,
        tenantId,
        tenantCompanyId,
        snackbarOn
      );
      setItems(items.concat(addedItemArr));
    }
    if (refetchFn) refetchFn();
    setIsSubmitting(false);
    setOpenTypeModal({ ...openTypeModal, open: false });
    onComplete();
  };

  const handleOrderChange = async item => {
    const newItems =
      orderUpdateAction && (await orderUpdateAction(item, tenantId, tenantCompanyId, snackbarOn));
    if (newItems) {
      setItems(newItems);
    }
  };
  const onCancel = () => setOpenTypeModal({ ...openTypeModal, open: false });
  return (
    <Grid item>
      <Grid container css={styles.hourTypeContainer} direction="column">
        <DraggableContainer
          css={styles.defaultHourTypes}
          getDisplayLabel={props.getDisplayLabel}
          isAllowedToEdit={props.isAllowedToEdit}
          openTypeModal={openTypeModal}
          setOpenTypeModal={setOpenTypeModal}
          typesList={items}
          updateOrder={handleOrderChange}
        />
      </Grid>
      {props.isAllowedToAdd && (
        <ThemeProvider>
          <Button
            disabled={disableAdd}
            startIcon={<AddCircleOutlineIcon />}
            type={ButtonType.TERTIARY}
            onClick={() =>
              setOpenTypeModal({
                open: true,
                mode: Mode.ADD,
                data: {},
                dataType: props.title,
                sortOrder: items.length + 1
              })
            }
          >
            {props.addActionLabel}
          </Button>
        </ThemeProvider>
      )}
      {props.isFullScreenForm && (
        <FullScreenModal
          fixedHeader
          handleClose={onCancel}
          modalHeaderButtons={[
            <ThemeProvider>
              <Button
                loading={isSubmitting}
                type={ButtonType.PRIMARY}
                onClick={() => formService.submit()}
              >
                Save
              </Button>
            </ThemeProvider>
          ]}
          open={openTypeModal.open}
          title={props.title}
        >
          <MUIForm
            configuration={props.layout}
            customComponents={props.customComponents}
            data={openTypeModal.data}
            layout={openTypeModal.mode}
            validationSchema={props.validationSchema}
            onComplete={handleAction}
            onCreateService={service => setFormService(service)}
          />
        </FullScreenModal>
      )}
      {!props.isFullScreenForm && (
        <SergeantModal
          confirmRemoveItemLabel={openTypeModal.deleteItemLabel}
          customComponents={props.customComponents}
          data={openTypeModal.data}
          dataType={openTypeModal.dataType}
          handleClose={onCancel}
          handlePrimaryAction={handleAction}
          hideTitle={props.hideTitle}
          layout={props.layout}
          mode={openTypeModal.mode}
          open={openTypeModal.open}
          validationSchema={props.validationSchema}
        />
      )}
    </Grid>
  );
};

export default DynamicItemWrapper;
