export const useStyles = ({ disabled }) => ({
  button: theme => ({
    margin: '16px 0',
    '& *': {
      color: disabled ? theme.palette.grayscale(70) : undefined
    }
  }),
  icon: {
    marginRight: 5
  }
});
