import React from 'react';

import { connect } from 'react-redux';

import { snackbarOn } from 'redux/actions/globalActions';
import { refetchProjectDetails } from 'redux/actions/projectManagementActions';

import GeneratePayApplication from './GeneratePayApplication.component';

const mapStateToProps = (state, props) => ({
  mode: props.mode || props.match?.params?.mode,
  projectId: props.projectId || props.match?.params?.projectId
});

const mapDispatcherToProps = {
  snackbar: snackbarOn,
  refetchProjectDetails
};

const Wrapper = props => {
  if (!props.open) return null;
  return <GeneratePayApplication {...props} />;
};

export default connect(mapStateToProps, mapDispatcherToProps)(Wrapper);
