import { FieldType, TV, TW } from '@BuildHero/sergeant';
import { object, string } from 'yup';

const rowStyle = {
  gap: 16,
  flexDirection: 'row',
  marginBottom: 16,
  flexWrap: 'wrap'
};

const colStyle = {
  width: 250,
  default: {
    height: 52
  }
};

export const tenantTypeOptions = [
  'Live',
  'Training',
  'Test',
  'Validation',
  'Churned',
  'Inactive'
].map(t => ({ label: t, value: t }));

export const Layouts = { VIEW: 'default', EDIT: 'edit' };

export const getConfiguration = auth => ({
  layouts: {
    default: {
      props: { flex: '1 1 auto', props: { flex: '1 1 auto' } },
      contents: [
        {
          props: {
            style: {
              edit: { ...rowStyle, marginRight: 189 },
              default: { ...rowStyle, marginRight: 100, minHeight: 36 }
            }
          },
          contents: [
            {
              component: { edit: 'Typography', default: 'Field' },
              props: {
                slowField: true,
                style: { display: 'flex', alignItems: 'center' },
                variant: TV.XL,
                weight: TW.BOLD,
                edit: {
                  value: 'Tenant: '
                },
                default: {
                  type: FieldType.LINK,
                  value: { to: '/admin/tenant', label: 'Tenant: ' }
                }
              }
            },
            {
              component: { edit: 'Input', default: 'Typography' },
              source: 'companyName',
              props: {
                slowField: true,
                style: { edit: { width: 500 }, default: { display: 'flex', alignItems: 'center' } },
                variant: TV.XL,
                weight: TW.BOLD
              }
            }
          ]
        },
        {
          props: { style: rowStyle },
          contents: [
            {
              component: { edit: 'Input', default: 'Field' },
              source: 'firstName',
              props: {
                slowField: true,
                style: colStyle,
                label: 'First Name',
                required: true
              }
            },
            {
              component: { edit: 'Input', default: 'Field' },
              source: 'lastName',
              props: {
                slowField: true,
                style: colStyle,
                label: 'Last Name'
              }
            },
            {
              component: 'Field',
              source: 'email',
              props: {
                slowField: true,
                style: colStyle,
                label: 'Email'
              }
            },
            {
              component: { edit: 'Input', default: 'Field' },
              source: 'tier',
              props: {
                slowField: true,
                style: colStyle,
                label: 'Tier'
              }
            },
            {
              component: { edit: 'Input', default: 'Field' },
              source: 'status',
              props: {
                slowField: true,
                style: colStyle,
                label: 'Status'
              }
            },
            {
              component: { edit: 'Input', default: 'Field' },
              source: 'accountName',
              props: {
                slowField: true,
                style: colStyle,
                label: 'Account Name'
              }
            },
            {
              component: { edit: 'Input', default: 'Field' },
              source: 'ownerName',
              props: {
                slowField: true,
                style: colStyle,
                label: 'Owner Name'
              }
            },
            {
              component: 'Field',
              source: 'userName',
              props: {
                slowField: true,
                style: colStyle,
                label: 'Username'
              }
            },
            {
              component: { edit: 'Select', default: 'Field' },
              source: { edit: 'type', default: 'type.label' },
              props: {
                slowField: true,
                style: colStyle,
                label: 'Type',
                options: tenantTypeOptions
              }
            },
            {
              component: { edit: 'Input', default: 'Field' },
              source: 'salesforceId',
              props: {
                slowField: true,
                style: colStyle,
                label: 'Salesforce ID'
              }
            }
          ]
        },
        {
          props: {
            style: { flex: '1 1 auto', minHeight: 500 }
          },
          contents: [
            {
              component: 'TenantTabs',
              src: 'tenantSettings',
              props: {
                slowField: true,
                auth,
                layout: { default: 'default', edit: 'edit' }
              }
            }
          ]
        }
      ]
    }
  },
  validation: object({
    companyName: string()
      .nullable()
      .required('Company name is required.'),
    firstName: string()
      .nullable()
      .required('First name is required.'),
    userName: string()
      .nullable()
      .required('Username is required.'),
    email: string()
      .nullable()
      .required('Email is required.'),
    type: string()
      .nullable()
      .required('Type is required.')
  })
});
