import React from 'react';

import { ClickAwayListener, ListItem, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { connect } from 'react-redux';

import Popper from '../Popper';
import styles from '../styles';

function MenuSectionCollapsible({
  data,
  classes,
  tenantSettingProcessed,
  isSmallScreen,
  clickTogglesPopover,
  user
}) {
  const menuItems = data.menus;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isPopoverOpen = Boolean(anchorEl);

  const showPopover = event => {
    setAnchorEl(event.currentTarget);
  };

  const hidePopover = () => {
    setAnchorEl(null);
  };

  const handleClick = event => {
    if (isPopoverOpen) {
      hidePopover();
    } else {
      showPopover(event);
    }
  };

  const hasSelectedMenuItem = (menuItem, url) => {
    const { sections = [] } = menuItem;
    return sections.some(section => url.includes(section));
  };

  const menuId = `menuId_${data.title}_${data.url}`;
  const id = isPopoverOpen ? `simple-Popper${data.title}` : undefined;
  return (
    <ClickAwayListener onClickAway={hidePopover}>
      <ListItem
        aria-haspopup="true"
        aria-owns={isPopoverOpen ? id : undefined}
        classes={{
          root: isPopoverOpen ? classes.menuItemOpened : classes.menuItem,
          selected: classes.menuSelected
        }}
        component="a"
        id={menuId}
        key={`listitem${data.title}`}
        selected={isPopoverOpen || hasSelectedMenuItem(data, window.location.pathname)}
        testingid={data.title}
        onClick={clickTogglesPopover && handleClick}
        onMouseEnter={!clickTogglesPopover && showPopover}
        onMouseLeave={!clickTogglesPopover && hidePopover}
      >
        <ListItemText
          classes={{ root: classes.listTexts }}
          disableTypography
          key={`listitems${data.title}`}
          primary={data.title}
        />
        {isPopoverOpen ? (
          <KeyboardArrowUp className={classes.menuItemArrow} />
        ) : (
          <KeyboardArrowDown className={classes.menuItemArrow} />
        )}
        <Popper
          anchorEl={anchorEl}
          handleClosePopover={hidePopover}
          id={id}
          isSmallScreen={isSmallScreen}
          menuItems={menuItems}
          open={isPopoverOpen}
          tenantSettingProcessed={tenantSettingProcessed}
          user={user}
        />
      </ListItem>
    </ClickAwayListener>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps, null)(withStyles(styles)(MenuSectionCollapsible));
