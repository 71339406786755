import gql from 'graphql-tag';

import { watchedQueries } from '@dispatch/Dispatch.store';
import useExtendedMutation from 'customHooks/useExtendedMutation';

const DELETE_MAN_DAY_MUTATION = gql`
  mutation deleteManDay($data: DeleteManDayInput!) {
    deleteManDay(data: $data) {
      id
      isActive
    }
  }
`;

const serializer = ({ id }) => ({
  variables: {
    data: {
      id
    }
  }
});

const useDeleteManDay = () => {
  return useExtendedMutation(DELETE_MAN_DAY_MUTATION, {
    serializer,
    update: (cache, { data }) => {
      if (watchedQueries.useDispatchBoardEvents) {
        const prevEventsResponse = cache.readQuery(watchedQueries.useDispatchBoardEvents);
        const prevItems = prevEventsResponse?.getDispatchEventsByTechIds?.manDays;
        const deletedManDayId = data?.deleteManDay?.id;
        const nextItems = prevItems.filter(({ id }) => id !== deletedManDayId);

        const newData = {
          getDispatchEventsByTechIds: {
            ...prevEventsResponse.getDispatchEventsByTechIds,
            manDays: nextItems
          }
        };

        cache.writeQuery({
          query: watchedQueries.useDispatchBoardEvents.query,
          data: newData,
          variables: { replace: true }
        });
      }

      if (watchedQueries.useDispatchBoardManDayItems) {
        const query = watchedQueries.useDispatchBoardManDayItems;
        const prevItems = cache.readQuery(query)?.listManDay;

        if (prevItems) {
          const deletedManDayId = data?.deleteManDay?.id;
          const nextItems = prevItems.filter(({ id }) => id !== deletedManDayId);
          cache.writeQuery({
            ...query,
            data: {
              listManDay: nextItems
            }
          });
        }
      }
    }
  });
};

export default useDeleteManDay;
