import React from 'react';

import { InputAdornment, TextField, withStyles } from '@material-ui/core';

import { convertCurrencyStringToFloat, convertToCurrencyString } from 'utils';

import styles from './styles';

const removeLeadingZero = value => (value && value.replace(/^0+/, '')) || value;

// TODO: avoid using state and make it as fully controlled component
class CostMarkup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unitCost: null,
      unitCostText: '',
      markupValue: null,
      markupType: 'Percentage',
      unitPrice: null,
      unitPriceText: ''
    };
  }

  componentDidMount() {
    const { values } = this.props;
    const { unitCost, unitPrice, markupValue, markupType, nonDefaultMarkupType } = values;
    // removed % as we are for now using markup as percentage.
    // If fixed value is started to be in use, we need to fix the label shrink problem. Every time that state changes, it shrinks from the start
    const markupText = markupType === 'Fixed' ? markupValue : `${markupValue || 0}`;
    const isMarkUpKeyPresent = !!nonDefaultMarkupType || false;
    this.setState({
      unitCost,
      unitCostText: `${unitCost || ''}`,
      unitPrice,
      unitPriceText: `${unitPrice || ''}`,
      markupText,
      markupValue,
      markupType,
      isMarkUpKeyPresent
    });
  }

  setValuesInForm = (unitCost, unitPrice, markupValue, markupType) => {
    const { handleChange, values } = this.props;
    handleChange({
      ...values,
      unitCost,
      unitPrice,
      markupType,
      markupValue
    });
  };

  computeUnitPrice = (unitCost, markupValue, markupType) => {
    let unitPrice = parseFloat(unitCost);
    if (markupValue && markupValue !== 0) {
      unitPrice =
        markupType === 'Percentage'
          ? (parseFloat(unitCost) + parseFloat(unitCost) * (parseFloat(markupValue) / 100)).toFixed(
              2
            )
          : (parseFloat(unitCost) + parseFloat(markupValue)).toFixed(2);
    }

    return { unitPrice, unitPriceText: unitPrice };
  };

  computeMarkup = (unitCost, unitPrice) => {
    const markup = (
      ((parseFloat(unitPrice) - parseFloat(unitCost)) / parseFloat(unitCost)) *
      100
    ).toFixed(2);

    return { markupValue: markup, markupText: `${markup}`, markupType: 'Percentage' };
  };

  handleUnitCostChange = event => {
    this.setState({ unitCostText: event.target.value });
  };

  handleUnitPriceChange = event => {
    this.setState({ unitPriceText: event.target.value });
  };

  handleMarkupChange = event => {
    this.setState({ markupText: event.target.value });
  };

  formatMarkup = event => {
    const { unitCost, isMarkUpKeyPresent } = this.state;
    // replacing 0 in the front
    let markup = removeLeadingZero(event.target.value);
    let type = 'Percentage';
    if (isMarkUpKeyPresent) {
      type = markup.endsWith('%') ? 'Percentage' : 'Fixed';
    } else if (!markup.endsWith('%')) {
      markup = `${markup}`;
    }

    const markupValue = markup.endsWith('%')
      ? parseFloat(markup.substring(0, markup.length - 1))
      : parseFloat(markup);
    const { unitPrice, unitPriceText } = this.computeUnitPrice(unitCost, markupValue, type);
    this.setValuesInForm(unitCost, unitPrice, markupValue, type);
    this.setState({
      markupValue,
      markupType: type,
      markupText: markup,
      unitPriceText
    });
  };

  formatUnitPrice = event => {
    const currentValue = convertCurrencyStringToFloat(event.target.value) || 0;
    let { markupValue, markupType, unitCost, markupText, unitCostText } = this.state;

    ({ markupValue, markupText, markupType } = this.computeMarkup(
      unitCost || currentValue,
      currentValue
    ));
    const formattedUnitPrice = convertToCurrencyString(currentValue);
    if (!unitCost) {
      unitCost = currentValue;
      unitCostText = formattedUnitPrice;
    }
    this.setValuesInForm(unitCost || currentValue, currentValue, markupValue, markupType);

    this.setState({
      unitPriceText: formattedUnitPrice,
      markupValue,
      markupType,
      markupText,
      unitCost,
      unitCostText
    });
  };

  formatUnitCost = event => {
    const currentValue = convertCurrencyStringToFloat(event.target.value) || 0;
    let { unitCost, unitPrice, unitPriceText } = this.state;
    const { markupValue, markupType, markupText } = this.state;
    unitCost = currentValue === '' ? 0 : currentValue;

    ({ unitPrice, unitPriceText } = this.computeUnitPrice(unitCost || 0, markupValue, markupType));

    unitPriceText = convertToCurrencyString(unitPrice);

    this.setValuesInForm(unitCost, unitPrice, markupValue, markupType);

    const currencyFloat = currentValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    this.setState({
      unitCostText: `${currencyFloat || ''}`,
      unitPriceText,
      markupText
    });
  };

  render() {
    const { unitCostText, unitPriceText, markupText } = this.state;
    const { classes, disableCostAndPrice } = this.props;
    return (
      <>
        {!disableCostAndPrice && (
          <TextField
            className={classes.textField}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
            label="*Unit Cost"
            margin="normal"
            value={unitCostText}
            variant="filled"
            onBlur={this.formatUnitCost}
            onChange={this.handleUnitCostChange}
          />
        )}
        <TextField
          className={classes.textField}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
          label="*Markup"
          margin="normal"
          value={markupText}
          variant="filled"
          onBlur={this.formatMarkup}
          onChange={this.handleMarkupChange}
        />
        {!disableCostAndPrice && (
          <TextField
            className={classes.textField}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
            label="*Unit Price"
            margin="normal"
            style={{ marginRight: 3 }}
            value={unitPriceText}
            variant="filled"
            onBlur={this.formatUnitPrice}
            onChange={event => this.handleUnitPriceChange(event)}
          />
        )}
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CostMarkup);
