import React from 'react';

import { TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import PropTypes from 'prop-types';

import { useStyles } from './LaborTimeField.styles';

const LaborTimeField = ({ form, field, options }) => {
  const { reportDate } = form.values;

  const value = field?.value ? moment.unix(field.value).format('HH:mm') : '';
  const classes = useStyles();

  const errorText = (form && field && form.errors && form.errors[field.name]) || '';

  const handleChange = event => {
    const [hour, minute] = event.target.value.split(':');
    const newTime = moment(reportDate).set({ hour, minute });
    const newValue = Math.floor(newTime.unix() / 60) * 60;
    form.setFieldValue(field.name, newValue, false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography className={classes.label} noWrap variant="caption">
          {options.label}
        </Typography>
        {options.isRequired ? (
          <Typography className={classes.requiredLabel} noWrap variant="caption">
            REQUIRED
          </Typography>
        ) : null}
      </div>
      <TextField
        error={errorText !== ''}
        helperText={errorText ? options.errorText : options.helperText}
        type="time"
        value={value}
        variant={options.variant}
        onChange={handleChange}
      />
    </div>
  );
};

LaborTimeField.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

export default LaborTimeField;
