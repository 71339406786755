import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Tab, Tabs } from 'components';

import { getLaborRowsAndCols } from 'components/LabourRateSection/LabourRateHelpers';
import { getCompany } from 'components/LabourRateSection/queries';

import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';

import { getPricebookById } from 'scenes/Settings/Pricebooks/PricebookDetail/gql';
import AmplifyService from 'services/AmplifyService';

import { logErrorWithCallback } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';
import compose from 'utils/compose';

import { InvoiceTab } from './Invoice';
import JobsTab from './Jobs';
import Maintenances from './Maintenances';
import { NotesAndAttachments } from './NoteAndAttachments';
import Pricebook from './Pricebook';
import PropertyAndAssets from './PropertyAndAssets';
import RateCard from './RateCard';
import RecurringMaintenance from './RecurringMaintenance';
import RenewalsTab from './Renewals/Tab';

const ServiceAgreementsTabs = props => {
  const {
    user,
    selectedProperties,
    agreementInfo,
    isAgreementActive,
    isExpired,
    isReadOnly,
    history,
    showSnackbar,
    onTabUpdate,
    serviceAgreementsSettings,
    companyTimeZone
  } = props;

  const [laborItems, setLaborItems] = useState([]);
  const [laborMeta, setLaborMeta] = useState([]);
  const [materialMarkupEntries, setMaterialMarkupEntries] = useState([]);
  const appSyncClient = AmplifyService.appSyncClient();

  const getLaborItems = useCallback(
    async pricebook => {
      const { data } = await appSyncClient.client.query({
        query: getCompany,
        variables: {
          partitionKey: user.tenantId,
          sortKey: `${user.tenantId}_Company_${user.tenantCompanyId}`
        }
      });
      const [rows, cols] = getLaborRowsAndCols(data, pricebook.pricebookLabourEntries?.items);
      setLaborItems(rows);
      setLaborMeta(cols);
    },
    [appSyncClient.client, user.tenantCompanyId, user.tenantId]
  );

  const getPricebook = useCallback(
    async pricebookId => {
      try {
        const { data } = await appSyncClient.client.query({
          query: getPricebookById,
          variables: { id: pricebookId }
        });
        const pricebook = data?.getPricebookById || {};
        pricebook.id = pricebookId;
        setMaterialMarkupEntries(
          pricebook.materialMarkupJSON && JSON.parse(pricebook.materialMarkupJSON)
        );
        getLaborItems(pricebook);
      } catch (error) {
        logErrorWithCallback(
          error,
          showSnackbar,
          'Unable to fetch pricebook information, please try again later'
        );
      }
    },
    [appSyncClient.client, getLaborItems, showSnackbar]
  );

  useEffect(() => {
    const pricebookId = agreementInfo?.pricebookId;

    if (pricebookId) {
      getPricebook(pricebookId);
    }
  }, [agreementInfo, getPricebook]);

  return (
    <Tabs>
      <Tab
        caslKey={PermissionConstants.OBJECT_SERVICE_AGREEMENT}
        label={
          agreementInfo?.advancedScheduling
            ? 'Maintenances'
            : Labels.recurringMaintenance[user.locale]
        }
      >
        {!agreementInfo?.advancedScheduling ? (
          <RecurringMaintenance
            agreementInfo={agreementInfo}
            companyTimeZone={companyTimeZone}
            history={history}
            isAgreementActive={isAgreementActive}
            isReadOnly={isReadOnly}
            selectedProperties={selectedProperties}
            serviceAgreementsSettings={serviceAgreementsSettings}
            onTemplateUpdate={onTabUpdate}
          />
        ) : (
          <Maintenances
            agreementInfo={agreementInfo}
            isAgreementActive={isAgreementActive}
            serviceAgreementId={agreementInfo?.id}
          />
        )}
      </Tab>
      {agreementInfo?.advancedScheduling && (
        <Tab caslKey={PermissionConstants.OBJECT_SERVICE_AGREEMENT} label="Properties & Assets">
          <PropertyAndAssets serviceAgreementId={agreementInfo?.id} />
        </Tab>
      )}
      {isAgreementActive || isExpired ? (
        <Tab
          caslKey={PermissionConstants.OBJECT_SERVICE_AGREEMENT}
          label={Labels.pricebook[user.locale]}
        >
          <Pricebook
            agreementInfo={agreementInfo}
            laborItems={laborItems}
            laborMeta={laborMeta}
            materialMarkupEntries={materialMarkupEntries}
          />
        </Tab>
      ) : (
        !agreementInfo?.advancedScheduling && (
          <Tab
            caslKey={PermissionConstants.OBJECT_SERVICE_AGREEMENT}
            label={Labels.rateCard[user.locale]}
          >
            <RateCard
              agreementInfo={agreementInfo}
              isReadOnly={isReadOnly}
              onRateCardUpdate={onTabUpdate}
            />
          </Tab>
        )
      )}
      {(isAgreementActive || isExpired || agreementInfo?.billingType === 'RecurringBilling') && (
        <Tab
          caslKey={PermissionConstants.OBJECT_INVOICE}
          label={
            isAgreementActive || isExpired
              ? Labels.invoices[user.locale]
              : Labels.invoiceSettings[user.locale]
          }
        >
          <InvoiceTab
            agreementInfo={agreementInfo}
            isAgreementActive={isAgreementActive}
            isExpired={isExpired}
            serviceAgreementsSettings={serviceAgreementsSettings}
            showSnackbar={showSnackbar}
            user={user}
          />
        </Tab>
      )}
      <Tab
        caslKey={PermissionConstants.OBJECT_SERVICE_AGREEMENT}
        label={Labels.renewals[user.locale]}
      >
        <RenewalsTab
          agreementInfo={agreementInfo}
          showSnackbar={showSnackbar}
          onTabUpdate={onTabUpdate}
        />
      </Tab>
      <Tab
        caslKey={PermissionConstants.OBJECT_SERVICE_AGREEMENT}
        label={Labels.notesAndAttachments[user.locale]}
      >
        <NotesAndAttachments
          agreementInfo={agreementInfo}
          showSnackbar={showSnackbar}
          user={user}
        />
      </Tab>
      <Tab caslKey={PermissionConstants.OBJECT_JOB} label={Labels.jobsPullThroughWork[user.locale]}>
        <JobsTab agreementInfo={agreementInfo} showSnackbar={showSnackbar} user={user} />
      </Tab>
    </Tabs>
  );
};

ServiceAgreementsTabs.propTypes = {
  isReadOnly: PropTypes.bool,
  user: PropTypes.object.isRequired,
  serviceAgreementsSettings: PropTypes.object.isRequired
};

ServiceAgreementsTabs.defaultProps = {
  isReadOnly: false
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  showSnackbar: (mode, message) => dispatch(snackbarOn(mode, message))
});

export default compose(connect(mapStateToProps, mapDispatcherToProps))(ServiceAgreementsTabs);
