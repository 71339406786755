import React, { useMemo } from 'react';

import { Button, ThemeProvider } from '@BuildHero/sergeant';
import PropTypes from 'prop-types';

import ProjectNumberTitle from '@pm/components/ProjectNumberTitle';
import WeatherTicker from '@pm/components/WeatherTicker';
import { PageHeader, StatusChip } from 'components';
import PageMap from 'meta/PageMap';
import projectStatusChips from 'meta/projectStatusChips';

const PMHeader = ({ user, project, handleOpenModal }) => {
  const headerButtons = useMemo(
    () => [
      <div key="projectDetailFrameHeaderButtons">
        <ThemeProvider>
          <Button type="tertiary" onClick={handleOpenModal}>
            Edit project
          </Button>
        </ThemeProvider>
      </div>
    ],
    [handleOpenModal]
  );

  const additionalTitleComponents = [
    project?.number ? (
      <ProjectNumberTitle key="projectDetailFrameProjectNumberTitle">
        {project?.number}
      </ProjectNumberTitle>
    ) : null,
    project?.status ? (
      <StatusChip
        backgroundColor={projectStatusChips[project?.status]?.backgroundColor}
        key="projectDetailFrameTitleStatusChips"
        label={projectStatusChips[project?.status]?.name[user.locale]}
        textColor={projectStatusChips[project?.status]?.textColor}
      />
    ) : null,
    project?.weather?.weatherData?.length > 0 ? (
      <WeatherTicker
        key="projectDetailFrameWeatherTicker"
        style={{ paddingLeft: 20 }}
        weatherData={project?.weather?.weatherData?.slice(0, 7) || []}
      />
    ) : null
  ];

  return (
    <PageHeader
      additionalTitleComponents={additionalTitleComponents}
      breadcrumbsArray={[
        {
          link: '',
          title: 'Projects'
        },
        {
          link: '/project',
          title: PageMap.projects.title[user.locale]
        }
      ]}
      key="projectDetailFramePageHeader"
      overrideHeaderButtons={headerButtons}
      pageMapKey="projectDetail"
      title={`Project: ${project?.name || ''}`}
      userLocale={user.locale}
    />
  );
};

PMHeader.propTypes = {
  user: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  handleOpenModal: PropTypes.func.isRequired
};
export default PMHeader;
