import * as R from 'ramda';

import attachmentsToCanvas from '@pm/components/attachmentsToCanvas';
import { getTenantSettingValueForKey } from 'utils';
import { InvoiceStatus, SyncStatus } from 'utils/constants';

import { formatChangesForUpdate } from '../../../../Invoices/InvoiceDetail/InvoiceDetail.utils';

export const getCanvasAttachments = async payApplication => {
  return attachmentsToCanvas(
    payApplication?.PayApplication?.PayApplicationAttachment?.filter(
      attachment => attachment.fileType.includes('pdf') && !attachment._deleted
    )
  );
};

export const voidInvoiceFormatter = accountingApp => {
  return R.pipe(
    R.curry(formatChangesForUpdate),
    R.pick([
      // must match deployment's UpdateInvoiceInput
      'billingAddress',
      'authorizedBy',
      'amountNotToExceed',
      'customerProvidedPONumber',
      'customerProvidedWONumber',
      'sageJobId',
      'accountingVersion',
      'closedDate',
      'departmentId',
      'invoiceNumber',
      'paymentTermName',
      'paymentTermValue',
      'status',
      'showLineItems',
      'issuedDate',
      'dueDate',
      'salesTaxRate',
      'taxRateId',
      'note',
      'termsOfService',
      'invoicePdfUrl',
      'accountingRefId',
      'syncStatus',
      'syncLog',
      'totalAmount',
      'subtotal',
      'billingCustomerId',
      'accountingRefIdOfCustomer',
      'accountingRefIdOfBillingCustomer',
      'accountingRefIdOfCustomerProperty',
      'accountingRefIdOfJob',
      'accountingRefIdOfSalesTaxRate',
      'taxableSubtotal',
      'priceBookId',
      'id',
      'settingsJSON',
      'summary',
      'version',
      'invoiceInvoiceTags'
    ]),
    R.evolve({
      version: R.inc,
      status: R.always(InvoiceStatus.VOID),
      syncStatus: R.always(accountingApp ? SyncStatus.SYNCING : undefined)
    })
  );
};

export const serializeVoidPayApp = data => {
  const settingsString = getTenantSettingValueForKey('accountingAppSettings');
  const accountingApp = settingsString && JSON.parse(settingsString)?.app;

  return { variables: { data: { ...voidInvoiceFormatter(accountingApp)(data) } } };
};
