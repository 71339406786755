import { useSubscription } from '@apollo/client';
import gql from 'graphql-tag';

import { updateWatchedVisitQueries } from '@dispatch/Dispatch.utils';

import { visitDetailsFragment } from '@dispatch/fragments';
import { getState } from 'redux/store';

export const VISIT_BULK_SUBSCRIPTION = gql`
    subscription visitBulkUpdateNotification($partitionKey: String!) {
        visitBulkUpdateNotification(partitionKey: $partitionKey) {
            items {
                ${visitDetailsFragment}
            }
        }
    }
`;

export const useVisitBulkSubscription = () => {
  const { tenantId } = getState().user;

  return useSubscription(VISIT_BULK_SUBSCRIPTION, {
    variables: { partitionKey: tenantId },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const { cache } = client;
      subscriptionData.data.visitBulkUpdateNotification.items.forEach(visit =>
        updateWatchedVisitQueries({
          cache,
          updatedVisit: visit
        })
      );
    }
  });
};
