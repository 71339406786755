import React from 'react';

import { bool, func } from 'prop-types';

import DefaultButton from 'components/Buttons/DefaultButton';

import AddButton from '../AddButton';

import { useStyles } from './AddAttachmentButton.styles';

const AddAttachmentButton = ({ smallButton, onClick }) => {
  const styles = useStyles();

  if (smallButton) {
    return <AddButton title="Add Attachment" onClick={onClick} />;
  }

  return (
    <DefaultButton
      buttonProps={{
        css: styles.button
      }}
      disabled={false}
      label="Add Attachment"
      showSpinner={false}
      variant="tertiary"
      onClick={onClick}
    />
  );
};

AddAttachmentButton.propTypes = {
  smallButton: bool.isRequired,
  onClick: func.isRequired
};

export default AddAttachmentButton;
