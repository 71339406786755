export const useStyles = () => ({
  fileItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '10px 12px 10px 12px',
    width: 100
  },
  pdfContainer: {
    width: 100,
    height: 125,
    boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden'
  },
  stepperButtonContainer: theme => ({
    '& [class*="Mui-disabled"]': {
      color: theme.palette.grayscale(50)
    }
  }),
  removeIconButton: theme => ({
    color: theme.palette.grayscale(50),
    position: 'absolute',
    right: -8,
    top: -8,
    zIndex: 99
  }),
  removeIcon: {
    fontSize: 16
  },
  removeIconWrapper: {
    position: 'relative'
  },
  stepperButton: theme => ({
    color: 'black'
  }),
  fileDescriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
});
