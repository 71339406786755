import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function Invoices(props) {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SvgIcon {...props}>
      <path d="M20 1C20.5523 1 21 1.44772 21 2V23L18.75 21L16.5 23L14.25 21L12 23L9.75 21L7.5 23L5.25 21L3 23V2C3 1.44772 3.44772 1 4 1H20ZM15.75 17H8.25C8.11193 17 8 17.1119 8 17.25V17.75C8 17.8881 8.11193 18 8.25 18H15.75C15.8881 18 16 17.8881 16 17.75V17.25C16 17.1119 15.8881 17 15.75 17ZM17.75 15H6.25C6.11193 15 6 15.1119 6 15.25V15.75C6 15.8881 6.11193 16 6.25 16H17.75C17.8881 16 18 15.8881 18 15.75V15.25C18 15.1119 17.8881 15 17.75 15ZM12.4084 3L11.3327 3.0027C11.2419 3.0027 11.1614 3.08099 11.1614 3.18224V4.18655C10.1545 4.48353 9.61863 5.25432 9.61863 6.28159C9.61863 7.68278 10.8202 8.18494 11.7855 8.58045C12.5096 8.88283 13.1428 9.14471 13.1428 9.68062C13.1428 10.311 12.5744 10.6012 12.0489 10.6012C11.0991 10.6012 10.3478 9.8534 10.3375 9.848C10.2739 9.77106 10.1402 9.78591 10.0857 9.8642L9.53299 10.6174C9.48498 10.6849 9.49017 10.7794 9.54337 10.8469C9.57062 10.8807 10.257 11.6609 11.5117 11.8458V12.8218C11.5117 12.9231 11.5922 13 11.6843 13H12.6938C12.7911 13 12.8651 12.9217 12.8651 12.8218V11.8458C13.9382 11.5462 14.5 10.7511 14.5 9.61312L14.4932 9.43641C14.4078 8.34276 13.5323 7.88726 12.6682 7.5308L12.3967 7.4209C11.635 7.11447 10.9811 6.85124 10.9811 6.25999C10.9811 5.75243 11.4534 5.40011 12.1346 5.40011C12.939 5.40011 13.5878 5.99811 13.593 6.00351C13.6825 6.07235 13.8058 6.05886 13.8616 5.95221L14.2963 5.13823C14.3339 5.07073 14.3235 4.98704 14.269 4.9263L14.2351 4.89208C14.0953 4.75739 13.5322 4.27312 12.5796 4.16091V3.17954C12.5796 3.07829 12.5044 3 12.4084 3Z" />
    </SvgIcon>
  );
}
