/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';

import { TW, Typography } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';

export const BoldCell = ({ formattedValue }) => {
  return <Typography weight={TW.BOLD}>{formattedValue}</Typography>;
};
