import { TV, TW, Typography } from '@BuildHero/sergeant';
import { object, string } from 'yup';

const rowStyle = {
  gap: 16,
  flexDirection: 'row',
  marginBottom: 16,
  flexWrap: 'wrap'
};

const itemStyle = { width: 250 };
const longItemStyle = { width: 516 };
const fullItemStyle = { width: 1032 };

export const peopleLayout = ({ departments, propReps, crews, techOptions }) => ({
  fields: {},
  layouts: {
    default: {
      contents: [
        {
          // row 1
          props: { style: rowStyle },
          contents: [
            {
              props: {
                style: itemStyle,
                searchable: true,
                options: propReps,
                label: 'Property Representative'
              },
              component: 'Select',
              source: 'propertyRepId'
            },
            {
              props: {
                style: itemStyle,
                searchable: true,
                options: departments,
                label: 'Department'
              },
              component: 'Select',
              source: 'departmentId'
            },
            {
              props: { style: itemStyle, searchable: true, options: crews, label: 'Crew' },
              component: 'Select',
              source: 'crewId'
            }
          ]
        },
        {
          props: { style: rowStyle },
          contents: [
            {
              resolveProps: ({ extraTechIds }) => ({
                options: techOptions.filter(
                  o => !extraTechIds?.map(t => t.value)?.includes(o.value)
                )
              }),
              props: {
                style: itemStyle,
                searchable: true,
                label: 'Primary Technician'
              },
              component: 'Select',
              source: 'primaryTechId'
            },
            {
              resolveProps: ({ primaryTechId }) => ({
                options: techOptions.filter(o => o.value !== primaryTechId?.value)
              }),
              props: {
                style: longItemStyle,
                searchable: true,
                label: 'Additional Technicians',
                multi: true
              },
              component: 'Select',
              source: 'extraTechIds'
            }
          ]
        }
      ]
    }
  }
});

export const maintenanceGroupLayout = ({
  maintenanceGroupNames,
  maintenanceTagOptions,
  maintenanceTypeOptions,
  crewOptions,
  techOptions
}) => ({
  fields: {},
  layouts: {
    default: {
      contents: [
        {
          // row 1
          props: {
            style: rowStyle
          },
          contents: [
            {
              props: {
                style: longItemStyle,
                label: 'Maintenance Group Name',
                required: true
              },
              component: 'Input',
              source: 'maintenanceGroupName'
            },
            {
              props: {
                style: longItemStyle,
                label: 'Maintenance Tags',
                multi: true,
                portal: true,
                options: maintenanceTagOptions,
                searchable: true
              },
              component: 'Select',
              source: 'maintenanceTags'
            }
          ]
        },
        {
          // row 2
          props: {
            style: rowStyle
          },
          contents: [
            {
              props: {
                style: itemStyle,
                label: 'Maintenance Type',
                portal: true,
                options: maintenanceTypeOptions,
                searchable: true,
                required: true
              },
              component: 'Select',
              source: 'maintenanceType'
            },
            {
              props: {
                style: itemStyle,
                label: 'Budgeted Hours'
              },
              component: 'NumberInput',
              source: 'budgetedHours'
            },
            {
              props: {
                style: itemStyle,
                label: 'Number of Visits'
              },
              component: 'NumberInput',
              source: 'numberOfVisits'
            },
            {
              props: {
                style: itemStyle,
                label: 'Schedule Visits',
                renderSuffix: () => (
                  <Typography css={{ minWidth: 150 }} variant={TV.BASE} weight={TW.MEDIUM}>
                    days before due date
                  </Typography>
                )
              },
              component: 'NumberInput',
              source: 'daysBeforeDueDate'
            }
          ]
        },
        {
          // row 3
          props: {
            style: rowStyle
          },
          contents: [
            {
              props: {
                style: itemStyle,
                label: 'Crew',
                portal: true,
                options: crewOptions,
                clearable: true,
                searchable: true
              },
              component: 'Select',
              source: 'crewId'
            },
            {
              resolveProps: ({ extraTechIds }) => ({
                options: techOptions.filter(
                  o => !extraTechIds?.map(t => t.value)?.includes(o.value)
                )
              }),
              props: {
                style: itemStyle,
                label: 'Primary Technician',
                portal: true,
                searchable: true
              },
              component: 'Select',
              source: 'primaryTechId'
            },
            {
              resolveProps: ({ primaryTechId }) => ({
                options: techOptions.filter(o => o.value !== primaryTechId?.value)
              }),
              props: {
                style: longItemStyle,
                label: 'Additional Technicians',
                portal: true,
                multi: true,
                searchable: true
              },
              component: 'Select',
              source: 'extraTechIds'
            }
          ]
        },
        {
          // row 4
          props: {
            style: rowStyle
          },
          contents: [
            {
              props: {
                label: 'Service Description',
                multiline: true,
                rows: 4,
                style: fullItemStyle
              },
              component: 'Input',
              source: 'description'
            }
          ]
        },
        {
          // row 5
          props: {
            style: rowStyle
          },
          contents: [
            {
              resolveProps: ({
                numberOfVisits,
                budgetedHours,
                daysBeforeDueDate,
                primaryTechId,
                extraTechIds
              }) => ({
                numberOfVisits,
                budgetedHours,
                daysBeforeDueDate,
                primaryTechId,
                extraTechIds
              }),
              props: { style: fullItemStyle, techOptions },
              component: 'VisitScheduler',
              source: 'visitsToSchedule'
            }
          ]
        }
      ]
    }
  },
  validation: object({
    maintenanceGroupName: string()
      .required('Maintenance Group Name is required')
      .test(
        'maintenanceGroupNameIsUnique',
        'Maintenance Group Name should be unique',
        value => !maintenanceGroupNames.includes(value)
      ),
    maintenanceType: object({
      value: string().required('Maintenace Type is Required'),
      label: string()
    })
  })
});

export default peopleLayout;
