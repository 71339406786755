/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useMemo, useState } from 'react';

import { Modal, TV, TW, Typography } from '@BuildHero/sergeant';
import { useTheme } from '@emotion/react';
import { Box } from '@material-ui/core';
import moment from 'moment';

import { StatusChip } from 'components';
import { getCloudinaryImageUrl } from 'scenes/ProjectManagement/components/utils';
import { useTimesheetSignature } from 'scenes/Timesheets/customHooks';

import { EnumType, SIGNATURE_STATUS } from 'utils/constants';

const IMAGE_SIZE = 125;

const imageBorder = theme => ({
  borderStyle: 'solid',
  borderColor: theme.palette.grayscale(80),
  borderRadius: '0.25em',
  borderWidth: '1.5px'
});

const useStyles = theme => ({
  header: {
    marginTop: '1em'
  },
  signatureRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  statusChip: {
    marginLeft: '1em',
    borderRadius: '0.25em'
  },
  imageWrapper: {
    marginTop: '1em',
    alignItems: 'center'
  },
  emptyImage: {
    backgroundColor: theme.palette.grayscale(95),
    color: theme.palette.grayscale(70),
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    textAlign: 'center',
    padding: '25% 0',
    ...imageBorder(theme)
  },
  signatureImage: {
    width: '100%',
    height: '100%',
    maxWidth: IMAGE_SIZE,
    maxHeight: IMAGE_SIZE,
    cursor: 'pointer',
    ...imageBorder(theme)
  },
  signatureText: {
    color: theme.palette.grayscale(70),
    marginLeft: '1em'
  },
  text: {
    margin: '1em',
    flexWrap: 'wrap'
  },
  imageModal: {
    width: 'auto',
    height: 'auto',
    maxWidth: 'calc(100vh - 96px)',
    maxHeight: 'calc(100vh - 96px)'
  }
});

const TimesheetSignature = ({ employeeId, startDayCompanyTZ, timezone }) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [modalOpen, setModalOpen] = useState(false);

  const [signature, isLoading] = useTimesheetSignature({
    employeeId,
    startDayCompanyTZ
  });

  const signatureStatus = useMemo(
    () =>
      signature && signature?.signatureImageUrl
        ? SIGNATURE_STATUS.COMPLETE
        : SIGNATURE_STATUS.PENDING,
    [signature]
  );

  const signatureDate = useMemo(
    () =>
      signature && signature?.lastUpdatedDate
        ? moment
            .unix(signature?.lastUpdatedDate)
            .tz(timezone)
            .format('MMMM Do YYYY, h:mm:ssa z')
        : null,
    [signature, timezone]
  );

  return (
    <div css={styles.flexBox}>
      <Box css={styles.signatureRow}>
        <Typography variant={TV.BASE} weight={TW.BOLD}>
          Signature
        </Typography>
        {!isLoading && (
          <StatusChip
            enumType={EnumType.SIGNATURE_STATUS}
            enumValue={signatureStatus}
            label={signatureStatus}
            style={styles.statusChip}
            textColor="white"
          />
        )}
      </Box>
      <Box css={styles.signatureRow}>
        <div css={styles.imageWrapper}>
          {signature?.signatureImageUrl ? (
            <>
              <div
                key={signature?.id}
                role="button"
                onClick={() => setModalOpen(true)}
                onKeyDown={() => null}
              >
                <img
                  alt="Signature"
                  src={getCloudinaryImageUrl(signature?.signatureImageUrl)}
                  style={styles.signatureImage}
                />
              </div>
              <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <img
                  alt="Signature"
                  src={getCloudinaryImageUrl(signature?.signatureImageUrl)}
                  style={styles.imageModal}
                />
              </Modal>
            </>
          ) : (
            <div css={styles.emptyImage}>
              <Typography css={styles.text} variant={TV.BASE} weight={TW.BOLD}>
                Signature Pending
              </Typography>
            </div>
          )}
        </div>
        {!isLoading && signatureDate && (
          <Typography css={styles.signatureText} variant={TV.BASE} weight={TW.REGULAR}>
            {`Signed ${signatureDate}`}
          </Typography>
        )}
      </Box>
    </div>
  );
};

export default TimesheetSignature;
