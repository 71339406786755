import React, { useEffect, useState } from 'react';

import { ButtonType, Divider, SplitButton, ThemeProvider } from '@BuildHero/sergeant';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { LinkButton, ResponsiveTable, SectionHeader, Spinner } from 'components';
import { UserPermission } from 'components/AppPermissions';
import DisplayData from 'components/ResponsiveTable/DisplayData';
import { partProcurementsRows } from 'meta/Jobs/Invoice';
import Labels from 'meta/labels';
import {
  billRowsMeta,
  inventoryItemRowsMeta,
  purchaseOrderItemRowsMeta,
  purchaseOrdersMeta
} from 'meta/partsPurchasing-layout';
import { getPurchaseOrderByJobId } from 'services/API/purchaseOrder';
import { getPurchaseOrderTags } from 'services/API/purchaseOrderTag';
import { JobService } from 'services/core';
import { Logger } from 'services/Logger';
import { convertToCurrencyString, roundCurrency } from 'utils';
import { ProcurementPurchaseOrderStatus } from 'utils/AppConstants';
import { constructSelectOptions } from 'utils/constructSelectOptions';

import CreateFieldOrder from '../../../Procurement/PurchaseOrders/CreateFieldOrder';
import CreatePurchaseOrder from '../../../Procurement/PurchaseOrders/CreatePurchaseOrder';

const useStyles = makeStyles(theme => ({
  linkButtonRoot: {
    whiteSpace: 'nowrap'
  },
  costSummaryWrapper: {
    borderTop: `2px solid ${theme.palette.grayscale(0)}`
  },
  costSummaryLabel: {
    minWidth: 100,
    paddingRight: 20
  }
}));

const ACTIVE_MODAL_ADD_FIELD_ORDER = 'add_field_order';
const ACTIVE_MODAL_ADD_PURCHASE_ORDER = 'add_purchase_order';
const ACTIVE_MODAL_NONE = null;

const LinkButtonCell = props => {
  const classes = useStyles();
  let entityType;
  let basePath;
  if (props.record?.source === 'Technician Report') {
    entityType = 'Technician Report';
    basePath = '/technicianreport/view/';
  } else {
    entityType = 'Review Report';
    basePath = '/reviewreport/view/';
  }
  const entityId = props.record?.repoId;
  return (
    <LinkButton
      classes={{ root: classes.linkButtonRoot }}
      label={entityType}
      path={basePath + entityId}
    />
  );
};

const renderVisitNumbers = ({ meta, record, ...rest }) => {
  const customMeta = { ...meta, type: 'text', isCustom: false };
  const updatedRecord = {
    ...record,
    visitNumbers: record[meta?.id]?.join(', ')
  };
  return <DisplayData meta={customMeta} record={updatedRecord} {...rest} />;
};

/**
 * Creates Parts and Purchasing Section with Purchase Order Items, Bills, and Inventory Items tables.
 */

const PartsPurchasing = props => {
  const classes = useStyles();
  const { parts, flags, user, snackbarOn, jobData, history, caslKey } = props;
  if (isEmpty(jobData)) {
    return <Spinner />;
  }

  const createModalInitialData = {
    jobAndProject: {
      id: jobData?.id,
      jobNumber: jobData?.customIdentifier || jobData?.jobNumber,
      customerPropertyId: jobData?.customerProperty?.id
    },
    // only assign the department property if department exist to prevent falsely enabling Vender field in FO modal
    department: jobData?.departments?.items[0]
      ? {
          tagName: jobData.departments.items[0].mappedEntity?.tagName || '',
          id: jobData.departments.items[0].mappedEntity?.id || null
        }
      : null,
    assignedTo: null,
    projectManager: {
      id: jobData?.owner?.id,
      name: jobData?.owner?.name
    }
  };

  const [activeModal, setActiveModal] = useState(ACTIVE_MODAL_NONE);
  const [billItems, setBillItems] = useState();
  const [purchaseOrderItems, setPurchaseOrderItems] = useState();
  const [inventoryItems, setInventoryItems] = useState();
  const [tagOptions, setTagOptions] = useState([]);
  const [purchaseOrderItemsTotal, setPurchaseOrderItemsTotal] = useState(0);
  const [billItemsTotal, setBillItemsTotal] = useState(0);
  const [inventoryItemsTotal, setInventoryItemsTotal] = useState(0);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [purchaseOrdersTotal, setPurchaseOrdersTotal] = useState(0);

  // This is similar to the formatPoData helper found in src/scenes/Procurement/PurchaseOrders/index.js,
  // however that is heavier and it seems contains some unnecessary steps. Keeping this separate for now.
  const formatPurchaseOrders = POs => {
    let total = 0;
    const formattedPOs = POs.filter(po => po.status !== ProcurementPurchaseOrderStatus.VOID).map(
      po => {
        total += parseFloat(po.totalCost) || 0;

        const newPo = { ...po };

        if (newPo.purchaseOrderTags?.length) {
          newPo.purchaseOrderTags = newPo.purchaseOrderTags.map(tag => tag?.tagName || {});
        }
        if (newPo.assignedTo) {
          newPo.assignedToName = newPo.assignedTo.name;
        }
        // MySQL stores bools as tinyints.
        // Similar logic in formatPoData in PurchaseOrders/index.js.
        newPo.isFieldOrder = !!newPo.isFieldOrder;

        return newPo;
      }
    );
    setPurchaseOrdersTotal(total);
    return formattedPOs;
  };

  const formatPurchaseOrderItems = purchaseOrderList => {
    let overallTotalCost = 0;
    const purchaseOrderItemList = purchaseOrderList.map(line => {
      const totalCost = roundCurrency(parseFloat(line.unitCost || 0) * parseFloat(line.quantity));
      overallTotalCost += totalCost;
      return {
        id: line.id,
        poNumberId: line.purchaseOrder?.id,
        poNumber: line.purchaseOrder?.poNumber,
        description: line.description,
        vendorName: line.purchaseOrder?.vendorName,
        status: line.status,
        quantity: line.quantity,
        quantityFulfilled: line.quantityFulfilled,
        totalCost
      };
    });
    setPurchaseOrderItemsTotal(overallTotalCost);
    return purchaseOrderItemList;
  };

  const getPrimaryTechnician = (techs = []) => {
    return [techs[0]?.employee?.firstName, techs[0]?.employee?.lastName].join(' ');
  };

  const formatInventoryItems = visits => {
    let overallTotalCost = 0;
    const formattedInventoryItems = [];
    visits.forEach(visit => {
      const primaryTechnician = getPrimaryTechnician(visit.primaryTechs.items);
      // The review reports inventory items will be the source of truth if any exist over a tech report on this visit
      if (visit.reviewReports.items?.length) {
        visit.reviewReports.items.forEach(report => {
          report.inventoryParts.items.forEach(line => {
            const totalCost = roundCurrency(
              parseFloat(line.unitCost || 0) * parseFloat(line.quantity)
            );
            overallTotalCost += totalCost;
            formattedInventoryItems.push({
              visitNumber: visit.visitNumber,
              itemName: line.itemName,
              itemDescription: line.description,
              unitCost: line.unitCost,
              quantityUsed: line.quantity,
              totalCost,
              primaryTechnician
            });
          });
        });
      } else {
        // the inventory items from the tech report will be used if review reports hasn't been created for this visit
        visit.inventoryParts.items.forEach(line => {
          const totalCost = roundCurrency(
            parseFloat(line.unitCost || 0) * parseFloat(line.quantity)
          );
          overallTotalCost += totalCost;
          formattedInventoryItems.push({
            visitNumber: visit.visitNumber,
            itemName: line.itemName,
            itemDescription: line.description,
            unitCost: line.unitCost,
            quantityUsed: line.quantity,
            totalCost,
            primaryTechnician
          });
        });
      }
    });
    setInventoryItemsTotal(overallTotalCost);
    return formattedInventoryItems;
  };

  const getBillItemsTotal = billList => {
    const overallTotalCost = billList?.reduce((acc, curr) => acc + curr.totalCost, 0) || 0;
    setBillItemsTotal(overallTotalCost);
  };

  useEffect(() => {
    const getAllPartsPurchasingData = async () => {
      if (jobData?.id) {
        try {
          const jobService = new JobService();
          const response = await jobService.getPartsPurchasingTablesData(
            jobData.id,
            jobData.sortKey
          );
          const { purchaseOrderLines, billsTableData, visits } = response.data.getJob;
          const formattedPurchaseOrderItems = formatPurchaseOrderItems(
            purchaseOrderLines.items || []
          );
          setPurchaseOrderItems(formattedPurchaseOrderItems);
          getBillItemsTotal(billsTableData.items);
          setBillItems(billsTableData.items || []);
          const formattedInventoryItems = formatInventoryItems(visits.items || []);
          setInventoryItems(formattedInventoryItems);
        } catch (error) {
          Logger.error(error);
          snackbarOn(
            'error',
            'Unable to fetch Parts & Purchasing data, please try again later',
            error
          );
        }
      }
    };
    if (flags.procurement) {
      getAllPartsPurchasingData();
      getPurchaseOrderTags().then(purchaseOrderTags => {
        setTagOptions(constructSelectOptions(purchaseOrderTags, 'tagName'));
      });
      const jobId = jobData?.id;
      if (jobId) {
        getPurchaseOrderByJobId(jobId).then(fetchedPurchaseOrders => {
          setPurchaseOrders(formatPurchaseOrders(fetchedPurchaseOrders));
        });
      }
    }
  }, [jobData]);

  const startPO = () => {
    const kickoffPO = () => {
      // open the create purchase order order modal
      setActiveModal(ACTIVE_MODAL_ADD_PURCHASE_ORDER);
    };
    kickoffPO();
  };

  const startFO = () => {
    const kickoffFO = () => {
      // open the create field order modal
      setActiveModal(ACTIVE_MODAL_ADD_FIELD_ORDER);
    };
    kickoffFO();
  };

  const handleCreatePO = po => {
    if (po.id) history.push(`/procurement/purchaseorders/view/${po.id}`);
    setActiveModal(ACTIVE_MODAL_NONE);
  };

  const overrideHeaderButtons = [
    <ThemeProvider key="addButton">
      <SplitButton
        options={[
          {
            label: Labels.addPurchaseOrder[user.locale],
            onClick: startPO
          },
          {
            label: Labels.addFieldOrder[user.locale],
            onClick: startFO
          }
        ]}
        type={ButtonType.PRIMARY}
        onClick={startPO}
      >
        {Labels.addPurchaseOrder[user.locale]}
      </SplitButton>
    </ThemeProvider>
  ];

  const renderOverallTotalCost = totalValue => (
    <Box display="flex" justifyContent="flex-end">
      <Box
        className={classes.costSummaryWrapper}
        display="flex"
        flexDirection="column"
        p={1}
        pr={2}
      >
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Typography className={classes.costSummaryLabel} variant="body2">
            Overall Total Cost
          </Typography>
          <Typography variant="h6">{convertToCurrencyString(totalValue)}</Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Grid container>
      {!flags.procurement ? (
        <ResponsiveTable
          customCellComponents={{ LinkButton: LinkButtonCell }}
          data={parts || []}
          disableFilter
          isLoading={!parts}
          rowMetadata={partProcurementsRows}
        />
      ) : (
        <UserPermission action={[caslKey]} I="new" user={user}>
          {/* Purchase Orders */}
          <Grid item xs={12}>
            <SectionHeader
              enablePadding
              icon="descriptionIcon"
              overrideHeaderButtons={overrideHeaderButtons}
              title={Labels.purchaseOrders[user.locale]}
            />
            <ResponsiveTable
              caslKey={caslKey}
              data={purchaseOrders}
              disableFilter
              isLoading={!purchaseOrderItems}
              rowMetadata={purchaseOrdersMeta}
              sortOrder="desc"
            />
            {renderOverallTotalCost(purchaseOrdersTotal)}
            <Divider margin={40} />
          </Grid>
          {/* Purchase Order Items */}
          <Grid item xs={12}>
            <SectionHeader
              enablePadding
              icon="descriptionIcon"
              title={Labels.purchaseOrderItems[user.locale]}
            />
            <ResponsiveTable
              caslKey={caslKey}
              data={purchaseOrderItems || []}
              disableFilter
              isLoading={!purchaseOrderItems}
              rowMetadata={purchaseOrderItemRowsMeta}
              sortOrder="desc"
            />
            {renderOverallTotalCost(purchaseOrderItemsTotal)}
            <Divider margin={40} />
          </Grid>
          {/* Bills */}
          <Grid item xs={12}>
            <SectionHeader enablePadding icon="descriptionIcon" title={Labels.bills[user.locale]} />
            <ResponsiveTable
              caslKey={caslKey}
              customCellComponents={{
                viewVisitNumbers: renderVisitNumbers
              }}
              data={billItems || []}
              disableFilter
              isLoading={!billItems}
              rowMetadata={() => billRowsMeta(jobData)}
              sortOrder="desc"
            />
            {renderOverallTotalCost(billItemsTotal)}
            <Divider margin={40} />
          </Grid>
          {/* Inventory Items */}
          <Grid item xs={12}>
            <SectionHeader
              enablePadding
              icon="descriptionIcon"
              title={Labels.InventoryItems[user.locale]}
            />
            <ResponsiveTable
              caslKey={caslKey}
              data={inventoryItems || []}
              disableFilter
              isLoading={!inventoryItems}
              rowMetadata={inventoryItemRowsMeta}
              sortOrder="desc"
            />
            {renderOverallTotalCost(inventoryItemsTotal)}
          </Grid>
        </UserPermission>
      )}
      <CreateFieldOrder
        handleClose={handleCreatePO}
        initialData={createModalInitialData}
        jobData={jobData}
        open={activeModal === ACTIVE_MODAL_ADD_FIELD_ORDER}
        tagOptions={tagOptions}
        user={user}
      />
      {activeModal === ACTIVE_MODAL_ADD_PURCHASE_ORDER && (
        <CreatePurchaseOrder
          handleClose={handleCreatePO}
          initialData={createModalInitialData}
          jobData={jobData}
          open={activeModal === ACTIVE_MODAL_ADD_PURCHASE_ORDER}
          tagOptions={tagOptions}
          user={user}
        />
      )}
    </Grid>
  );
};

PartsPurchasing.propTypes = {
  parts: PropTypes.array,
  flags: PropTypes.object,
  user: PropTypes.object.isRequired,
  snackbarOn: PropTypes.func.isRequired,
  jobData: PropTypes.object,
  caslKey: PropTypes.string
};

PartsPurchasing.defaultProps = {
  parts: [],
  flags: {},
  jobData: {},
  caslKey: ''
};

export default PartsPurchasing;
