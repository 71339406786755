import React, { Component } from 'react';

import Labels from 'meta/labels';

import Notes from '../../../../Notes';

class CondensedNotes extends Component {
  render() {
    const { specialbehaviour, user } = this.props;
    const {
      title,
      linkName,
      allNotesFor,
      notesData,
      parent,
      refetch,
      queryResult,
      mutateService,
      noteLimit
    } = specialbehaviour;

    let notesDataArray;
    let parentObject;
    let refetchFn;
    let mutateServiceFn;

    if (queryResult) {
      notesDataArray = queryResult[notesData];
      parentObject = queryResult[parent];
      refetchFn = queryResult[refetch];
      mutateServiceFn = queryResult[mutateService];
    }
    return (
      <Notes
        allNotesFor={allNotesFor}
        // subtitle={Labels[subtitle][user.locale]}
        linkName={Labels[linkName][user.locale]}
        mutateService={mutateServiceFn}
        noteLimit={noteLimit}
        notesData={notesDataArray || []}
        parent={parentObject}
        refetch={refetchFn}
        title={Labels[title][user.locale]}
      />
    );
  }
}

export default CondensedNotes;
