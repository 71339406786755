import { useEffect, useState } from 'react';

import { getDailyReports } from 'services/API/dailyReport';

export const useDailyReportDates = ({ projectId, currentReportId }) => {
  const [dailyReportDates, setDailyReportDates] = useState([]);
  useEffect(() => {
    (async () => {
      const reports = await getDailyReports(projectId);
      const reportDates = reports
        .filter(report => report?.id !== currentReportId)
        .map(report => report?.date);
      setDailyReportDates(reportDates);
    })();
  }, [projectId, currentReportId]);
  return dailyReportDates;
};
