import React, { useRef, useState } from 'react';

import { Button, ButtonType, Switch } from '@BuildHero/sergeant';
import { Grid, List, ListItem, Popover } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ReorderIcon from '@material-ui/icons/Reorder';
import { useGridSlotComponentProps } from '@mui/x-data-grid-pro';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const styles = theme => ({
  dragIconRoot: {
    color: theme.palette.grayscale(60),
    display: 'flex'
  },
  listItemRoot: {
    height: '30px',
    padding: '0 8px'
  }
});

function ColumnsToolbarButton(props) {
  const { classes } = props;
  const { state, apiRef } = useGridSlotComponentProps();
  const buttonRef = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [count, refreshCounter] = React.useState(0);

  const handleDragEnd = result => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    apiRef.current.setColumnIndex(state.columns.all[result.source.index], result.destination.index);
    refreshCounter(count + 1);
  };

  const toggle = () => setIsMenuOpen(!isMenuOpen);

  const handleChange = colMetadata => {
    apiRef.current.updateColumn({ ...colMetadata, hide: !colMetadata.hide });
    refreshCounter(count + 1);
  };

  return (
    <>
      <Button
        color="secondary"
        endIcon={<ArrowDropDownIcon />}
        ref={buttonRef}
        type={ButtonType.LEADING}
        onClick={toggle}
      >
        COLUMNS
      </Button>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable direction="vertical" droppableId="droppableMenu">
          {droppableProvided => (
            <Popover
              anchorEl={() => buttonRef.current}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              getContentAnchorEl={null}
              id="add-menu"
              keepMounted
              open={isMenuOpen}
              ref={droppableProvided.innerRef}
              onClose={() => setIsMenuOpen(false)}
            >
              <List {...droppableProvided.droppableProps}>
                {state.columns.all
                  .filter(id => !state.columns.lookup[id].metaOnly)
                  .map((id, colIndex) => {
                    const colMetadata = state.columns.lookup[id];
                    const { headerName, hide } = colMetadata;
                    return (
                      <Draggable
                        draggableId={`dragMenu${id}`}
                        index={colIndex}
                        key={`dragMenu${id}`}
                      >
                        {(draggableProvided, snapshot) => {
                          return (
                            <ListItem
                              classes={{
                                root: classes.listItemRoot
                              }}
                              key={`menu${id}`}
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              style={{
                                ...draggableProvided.draggableProps.style,
                                background: snapshot.isDragging && 'rgba(245,245,245, 0.75)'
                              }}
                            >
                              <Grid
                                {...draggableProvided.dragHandleProps}
                                classes={{ root: classes.dragIconRoot }}
                              >
                                <ReorderIcon />
                              </Grid>
                              <Switch
                                checked={!hide}
                                horizontalLabel
                                label={headerName}
                                name={`switch${id}`}
                                onChange={() => handleChange(colMetadata)}
                              />
                            </ListItem>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                {droppableProvided.placeholder}
              </List>
            </Popover>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default withStyles(styles)(ColumnsToolbarButton);
