import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { TV, TW, Typography } from '@BuildHero/sergeant';
import { Box, useTheme } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { groupBy } from 'lodash';

import useServiceAgreement from 'customHooks/useServiceAgreement';

import { EntityType } from 'utils/constants';

import AssetTypeViewMaintenancePlan from './AssetTypeViewMaintenancePlan';
import AssetViewMaintenancePlan from './AssetViewMaintenancePlan';
import PropertyViewMaintenancePlan from './PropertyViewMaintenancePlan';

const ASSET_TYPE = 'AssetType';

const styledLabel = (label, isTitle = false) => (
  <Typography variant={TV.B2} weight={isTitle ? TW.BOLD : TW.REGULAR}>
    {label}
  </Typography>
);

const MaintenancePlan = ({ serviceAgreementId, setSaving, triggerSave }) => {
  const theme = useTheme();

  const [expanded, setExpanded] = useState([]);

  const [mainContent, setMainContent] = useState({
    data: null,
    type: null
  });

  const [
    {
      customerProperties: { items: customerProperties = [] } = {},
      propertyAssets: { items: propertyAssets = [] } = {}
    } = {},
    isLoadingServiceAgreement,
    ,
    refetchServiceAgreement
  ] = useServiceAgreement({
    serviceAgreementId
  });

  const properties = useMemo(
    () =>
      customerProperties.map(
        ({
          id: customerPropertyId,
          entityType: customerPropertyEntityType,
          companyName: propertyName,
          mergedTaskTemplateChecklists: propertyChecklist
        }) => ({
          id: customerPropertyId,
          entityType: customerPropertyEntityType,
          propertyName,
          propertyChecklist,
          assets: propertyAssets
            .filter(({ parentId }) => parentId === customerPropertyId)
            .map(
              ({
                id: propertyAssetId,
                entityType: propertyAssetEntityType,
                assetName,
                assetType: { id: assetTypeId, tagName: assetType = '' } = {},
                make,
                modelNumber: model,
                serialNo,
                mergedTaskTemplateChecklists
              }) => ({
                id: propertyAssetId,
                entityType: propertyAssetEntityType,
                assetName,
                assetTypeId,
                assetType,
                make,
                model,
                serialNo,
                mergedTaskTemplateChecklists
              })
            )
        })
      ),
    [customerProperties, propertyAssets]
  );

  useEffect(() => {
    // Initialize main content to first property on first load
    if (properties.length && (!mainContent.data || !mainContent.type)) {
      setMainContent({ data: properties[0], type: properties[0].entityType });
    }
  }, [properties]);

  useEffect(() => {
    setSaving(false);
  }, [triggerSave]);

  const expand = useCallback(
    nodeId => {
      setExpanded(previousExpanded => {
        const indexOfNodeId = previousExpanded.indexOf(nodeId);
        return indexOfNodeId === -1
          ? [...previousExpanded, nodeId]
          : [
              ...previousExpanded.slice(0, indexOfNodeId),
              ...previousExpanded.slice(indexOfNodeId + 1)
            ];
      });
    },
    [setExpanded]
  );

  const getLatestAssetData = useCallback(
    ({ assetData, propertyId }) => {
      // property checklists
      if (!assetData.id) {
        const { mergedTaskTemplateChecklists = [] } =
          customerProperties.find(p => p.id === propertyId) || {};
        return { mergedTaskTemplateChecklists };
      }
      return propertyAssets.find(asset => asset.id === assetData.id);
    },
    [customerProperties, propertyAssets]
  );

  if (isLoadingServiceAgreement) {
    return null;
  }

  return (
    <Box
      display="flex"
      height="130vh"
      left="10px"
      marginTop="-40px"
      paddingRight={3}
      position="absolute"
      width="100%"
    >
      <Box
        borderRight={`1px solid ${theme.palette.grayscale(80)}`}
        display="flex"
        p={2}
        width={244}
      >
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
        >
          {properties.map(property => {
            const groupedByAssetType = groupBy(property.assets, 'assetType');
            return (
              <React.Fragment key={property.id}>
                <TreeItem
                  label={styledLabel(property.propertyName, true)}
                  nodeId={property.id}
                  onClick={() => {
                    setMainContent({ data: property, type: property.entityType });
                    expand(property.id);
                  }}
                >
                  {Object.entries(groupedByAssetType).map(([assetType, assetList]) => {
                    const assetTypeNodeId = `${property.id}-${assetType}`;
                    return (
                      <React.Fragment key={assetType}>
                        <TreeItem
                          label={styledLabel(`${assetType} (${assetList.length})`)}
                          nodeId={assetTypeNodeId}
                          onClick={() => {
                            setMainContent({
                              data: assetList,
                              assetType,
                              assetTypeId: assetList[0]?.assetTypeId,
                              assetTypeNodeId,
                              propertyName: property.propertyName,
                              type: ASSET_TYPE
                            });
                            expand(assetTypeNodeId);
                          }}
                        >
                          {assetList.map(asset => (
                            <React.Fragment key={asset.id}>
                              <TreeItem
                                label={styledLabel(asset.assetName)}
                                nodeId={asset.id}
                                onClick={() => {
                                  setMainContent({
                                    data: asset,
                                    assetType,
                                    customerPropertyId: property.id,
                                    propertyName: property.propertyName,
                                    type: asset.entityType
                                  });
                                }}
                              />
                            </React.Fragment>
                          ))}
                        </TreeItem>
                      </React.Fragment>
                    );
                  })}
                  {property.propertyChecklist?.length > 0 && (
                    <TreeItem
                      label={`Property Checklists (${property.propertyChecklist.length})`}
                      nodeId="propertyChecklist"
                      onClick={() => {
                        setMainContent({
                          data: {
                            assetName: 'Property Checklists',
                            assetTypeId: 'null',
                            taskTemplateChecklists: property.propertyChecklist
                          },
                          customerPropertyId: property.id,
                          propertyName: property.propertyName,
                          type: EntityType.ASSET
                        });
                      }}
                    />
                  )}
                </TreeItem>
              </React.Fragment>
            );
          })}
        </TreeView>
      </Box>
      <Box display="flex" flexDirection="column" flexGrow={2} p={2}>
        {mainContent.type === EntityType.CUSTOMER_PROPERTY && (
          <PropertyViewMaintenancePlan
            customerPropertyId={mainContent.data.id}
            data={mainContent.data}
            serviceAgreementId={serviceAgreementId}
          />
        )}
        {mainContent.type === ASSET_TYPE && (
          <AssetTypeViewMaintenancePlan
            assetType={mainContent.assetType}
            assetTypeId={mainContent.assetTypeId}
            assetTypeNodeId={mainContent.assetTypeNodeId}
            data={mainContent.data}
            expand={expand}
            expanded={expanded}
            propertyName={mainContent.propertyName}
            serviceAgreementId={serviceAgreementId}
            setMainContent={setMainContent}
          />
        )}
        {mainContent.type === EntityType.ASSET && (
          <AssetViewMaintenancePlan
            customerPropertyId={mainContent.customerPropertyId}
            data={getLatestAssetData({
              assetData: mainContent.data,
              propertyId: mainContent.customerPropertyId
            })}
            key={`${mainContent.customerPropertyId}-${mainContent.data.id}`}
            refetchServiceAgreement={refetchServiceAgreement}
            serviceAgreementId={serviceAgreementId}
          />
        )}
      </Box>
    </Box>
  );
};

export default MaintenancePlan;
