import React, { useEffect, useState } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import { Divider, Typography } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';

import PreferredTechniciansFormLayoutComponent from 'components/PreferredTechniciansForm/PreferredTechniciansFormLayoutComponent';
import { getPreferredTechnicians } from 'components/PreferredTechniciansForm/PreferredTechniciansHelpers';
import { getAllCrews } from 'scenes/Settings/People/utils';

import { getTenantSettingValueForKey } from 'utils';

import layout from './layout';

function CreateVisitsForm({
  techs,
  departments,
  data,
  preferredTechniciansConfig,
  formsList,
  visitIndex,
  getFormService,
  getHandleCreateService,
  getHandleComplete,
  onFormCompleted
}) {
  const preferredTechnicians = getPreferredTechnicians(preferredTechniciansConfig);
  const [visitData, setVisitData] = useState(data);
  const [visitDateWarning, setVisitDateWarning] = useState('');
  const MuiFormName = `form-${data.maintenanceId}-${visitIndex}`;
  const crewTimeTracking = getTenantSettingValueForKey('crewTimeTracking') === 'true';
  const [crews, setCrews] = useState([]);

  useEffect(() => {
    getAllCrews().then(({ items }) => {
      setCrews(items);
    });
  }, []);

  const onFormChange = formValues => {
    setVisitData(prev => ({
      ...prev,
      ...formValues
    }));

    const { dueDate, visitDate } = formValues;
    if (!!dueDate && !!visitDate && moment.unix(visitDate).diff(moment(dueDate), 'days') > 0) {
      setVisitDateWarning('This date is after maintenance due date');
    } else {
      setVisitDateWarning('');
    }

    const { departmentId } = formValues?.preferredTechnicians || {};
    if (departmentId) {
      onFormCompleted(visitIndex);
    }
  };

  const configuration = layout({
    technicians: techs,
    departments,
    crews,
    formsList,
    visitDateWarning,
    crewTimeTracking
  });

  return (
    <>
      <Divider style={{ marginTop: 16 }} variant="fullWidth" />
      <Typography style={{ marginTop: '25px', paddingLeft: '5px' }} variant="body2">
        <b>Visit {visitIndex} </b>
      </Typography>
      <MUIForm
        configuration={configuration}
        customComponents={{
          PreferredTechniciansFormLayoutComponent: componentProps => (
            <PreferredTechniciansFormLayoutComponent
              parentFormService={getFormService(MuiFormName)}
              {...componentProps}
            />
          )
        }}
        data={{
          ...visitData,
          preferredTechnicians
        }}
        layout="edit"
        onComplete={getHandleComplete(MuiFormName, true)}
        onCreateService={getHandleCreateService(MuiFormName)}
        onFormChange={onFormChange}
      />
    </>
  );
}

CreateVisitsForm.propTypes = {
  departments: PropTypes.array,
  techs: PropTypes.array
};

CreateVisitsForm.defaultProps = {
  departments: [],
  techs: []
};

export default CreateVisitsForm;
