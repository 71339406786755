import React from 'react';

import { calculateMargin, calculateMarkup } from '@BuildHero/math';
import { Divider, Field, FieldType, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';
import { Box, Card, CardContent, Grid, Tooltip, useTheme } from '@material-ui/core';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import PropTypes from 'prop-types';

import QuotesProfitability from 'assets/Icons/QuotesProfitability';

import { convertForMathLib } from 'utils/mathLibrary';

import { useStyles } from './Profitability.styles';

const Profitability = ({ quoteInfo }) => {
  const { palette } = useTheme();
  const classes = useStyles(palette);
  const totalCost = quoteInfo.totalEstimatedCost ?? 0;
  const grossProfit = quoteInfo.subTotal - totalCost;
  const markup = convertForMathLib(calculateMarkup, totalCost, quoteInfo.subTotal);
  const grossMargin = convertForMathLib(calculateMargin, totalCost, quoteInfo.subTotal);

  const ProfitabilityItem = (
    label,
    { type = FieldType.CURRENCY, value = '', weight = TW.BOLD, color = '', tooltip }
  ) => {
    return (
      <Box
        alignItems="baseline"
        display="flex"
        justifyContent="space-between"
        style={{ padding: '8px 0' }}
      >
        {tooltip ? (
          <Tooltip title={tooltip}>
            <Box display="flex">
              <Typography variant={TV.S1}>{label}</Typography>
              <InfoRoundedIcon style={{ fontSize: '1em', marginLeft: '4px' }} />
            </Box>
          </Tooltip>
        ) : (
          <Typography variant={TV.S1}>{label}</Typography>
        )}

        <Field color={color} type={type} value={value} weight={weight} />
      </Box>
    );
  };

  return (
    <Grid style={{ margin: '0 auto' }}>
      <Card>
        <CardContent css={classes.root}>
          <Box css={classes.cardHeader}>
            <QuotesProfitability />
            <ThemeProvider>
              <Typography style={{ color: palette.grayscale(100) }} variant={TV.L} weight={TW.BOLD}>
                Profitability
              </Typography>
            </ThemeProvider>
          </Box>
          <Box css={classes.cardContent}>
            <ThemeProvider>
              <Grid container direction="column" style={{ padding: '8px 16px' }}>
                {ProfitabilityItem('Quote Amount', {
                  value: quoteInfo.subTotal,
                  color: palette.support.green.dark,
                  tooltip: 'Quote Amount corresponds to pre-tax subtotal'
                })}
                {ProfitabilityItem('Total Cost', {
                  value: totalCost
                })}
                <Box alignItems="baseline" display="flex" justifyContent="flex-end">
                  <Divider color={palette.grayscale(20)} margin={0} width="50%" />
                </Box>
                {ProfitabilityItem('Gross Profit', {
                  value: grossProfit
                })}
                {ProfitabilityItem('Markup', {
                  type: FieldType.PERCENTAGE,
                  value: markup
                })}
                {ProfitabilityItem('Gross Margin', {
                  type: FieldType.PERCENTAGE,
                  value: grossMargin
                })}
              </Grid>
            </ThemeProvider>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

Profitability.propTypes = {
  quoteInfo: PropTypes.object.isRequired
};

export default Profitability;
