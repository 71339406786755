import React, { Component } from 'react';

import { ButtonType, Button as SGButton, ThemeProvider } from '@BuildHero/sergeant';
import { Button, Dialog, Grid, Slide, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { connect } from 'react-redux';

import { AddRecordButton, Modal, ResponsiveTable, SectionHeader } from 'components';
import ConfirmModal from 'components/Modal/ConfirmDialog';
import Labels from 'meta/labels';
import { formSectionTable } from 'meta/Settings/Forms';
import { snackbarOn } from 'redux/actions/globalActions';
import { CommonService } from 'services/core';
import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';

import FormPDF from './FormPDF';
import FormSelector from './FormSelector';
import FormViewer from './FormViewer';

const b64Rgx = '(?:[A-Za-z0-9+\\/]{4})*(?:[A-Za-z0-9+\\/]{2}==|[A-Za-z0-9+\\/]{3}=)?';
const mimeRgx = '(data:\\w+\\/[a-zA-Z\\+\\-\\.]+;base64,)';
const base64ImageRegex = new RegExp(`^${mimeRgx}?${b64Rgx}$`, 'i');
function b64toBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
}

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

class FormSection extends Component {
  constructor(props) {
    super(props);
    const { parent } = props;
    const updatedParent = { ...parent };
    this.CommonService = new CommonService();

    if (parent.customJobNumber && parent.customIdentifier) {
      updatedParent.jobNumber = parent.customIdentifier;
    }

    this.state = {
      addForm: false,
      openForm: false,
      FormViewerRecord: '',
      modalMode: '',
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: '',
      isSaving: false,
      refreshCounter: 0,
      showPdf: false,
      pdfRecord: '',
      formData: '',
      parent: updatedParent
    };
  }

  componentDidMount = async () => {
    await this.getAllFormDataFromEntity();
  };

  componentDidUpdate = async prevProps => {
    if (!this.props.triggerRefresh && prevProps.triggerRefresh) {
      this.refreshTableData();
    }
  };

  getAllFormDataFromEntity = async (limit, offset, sortBy, sortOrder) => {
    const { user, parent, filter } = this.props;
    const { entityType, sortKey, quoteId } = parent;
    if (!user.tenantId) {
      return { items: [], nextToken: '0' };
    }
    let data;
    try {
      data = await this.CommonService.getAllFormDataFromEntity(
        entityType,
        user.tenantId,
        sortKey,
        filter,
        limit,
        offset,
        sortBy,
        sortOrder,
        quoteId
      );

      this.setState({ formData: data.items });
      return data;
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to fetch Jobs, please try again later', error);
    }
    return data || [];
  };

  editFormData = async (formMetaData, editedDescription) => {
    if (!formMetaData) return;
    const { id, name, description, version } = this.state.FormViewerRecord;
    const { tenantId } = this.props.user;
    const formDataJson = {
      name,
      description: editedDescription || description,
      formData: formMetaData
    };

    this.setState({ isSaving: true });
    const { meta, searchableFields } = formMetaData;
    const formData = {};
    if (searchableFields) {
      Object.keys(searchableFields).forEach(dataId => {
        const mappingId = searchableFields[dataId];
        const dataValue = meta[1][dataId];
        if (dataValue) {
          const isNumber = mappingId.indexOf('number') >= 0;
          formData[mappingId] = isNumber ? +meta[1][dataId] : meta[1][dataId];
        }
      });
    }

    // 1. Get all fields that use Signature Component
    // 2. grab all the base64 values for those fields
    // 3. upload those images
    // 4. replace the image with the base64
    const signatureFieldNames = [];
    const layout = meta?.[0]?.layouts?.default || meta?.[0]?.layouts?.edit;
    layout?.contents?.forEach(content => {
      content?.contents?.forEach(row => {
        row?.contents?.forEach(column => {
          if (
            column?.component?.default === 'SignatureScreen' ||
            column?.component?.edit === 'SignatureScreen'
          ) {
            signatureFieldNames.push(column.source);
          }
        });
      });
    });

    const values = formMetaData?.meta?.[1];
    const storageService = new StorageService();
    await Promise.all(
      signatureFieldNames.map(async fieldName => {
        if (values[fieldName]) {
          const signatureValue = JSON.parse(values[fieldName]);
          if (base64ImageRegex.test(signatureValue.signature)) {
            const { tenantId } = this.props.user;
            const filename = `${tenantId}/${Date.now()}-signature.png`;
            const uri = await storageService.uploadFile(
              signatureValue.signature,
              filename,
              e => e,
              'image/png'
            );
            values[fieldName] = JSON.stringify({
              ...signatureValue,
              signature: uri
            });
          }
        }
      })
    );

    const formPayload = {
      id,
      formDataJson: JSON.stringify(formDataJson),
      version,
      tenantId
    };
    const payload = { ...formPayload, ...formData };

    try {
      const { data } = await this.CommonService.updateFormData(payload);
      if (data) {
        this.props.snackbarOn('success', `Successfully submitted form ${meta.name || ''}`);
        this.handleClosePopUp('openForm');
        this.setState({ isSaving: false });
        this.refreshTableData();
      }
    } catch (error) {
      Logger.error(error);
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        this.props.snackbarOn('error', error.graphQLErrors[0].message);
      } else {
        this.props.snackbarOn('error', 'Unable to submit form, please try again later');
      }
      this.setState({ isSaving: false });
    }
  };

  deleteFormData = async record => {
    if (!record) return;

    try {
      const { data } = await this.CommonService.deleteFormData(
        this.props.user.tenantId,
        record.sortKey
      );
      if (data) {
        this.props.snackbarOn('success', `Successfully deleted form ${record.name || ''}`);
        this.setState({ confirmDialog: false, confirmAction: '', confirmMessage: '' });
        this.refreshTableData();
      }
    } catch (error) {
      Logger.error(error);
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        this.props.snackbarOn('error', error.graphQLErrors[0].message);
      } else {
        this.props.snackbarOn('error', 'Unable to delete form, please try again later');
      }
    }
  };

  refreshTableData = () => {
    this.getAllFormDataFromEntity();
    this.setState(prevState => ({
      ...prevState,
      refreshCounter: prevState.refreshCounter + 1
    }));
  };

  handleFormRowActions = (mode, record) => {
    if (mode === 'delete') {
      this.setState({
        confirmDialog: true,
        confirmAction: async () => this.deleteFormData(record),
        confirmMessage: 'this form?'
      });
    } else if (mode === 'pdf') {
      this.handlePDFPreview(record);
    } else {
      this.handleOpenPopUp('openForm', mode, record);
    }
  };

  handleCancelConfirmation = () => {
    this.setState({ confirmDialog: false, confirmAction: '', confirmMessage: '' });
  };

  handleOpenPopUp = (popUpKey, mode, record) => {
    this.setState({ [popUpKey]: true, modalMode: mode, FormViewerRecord: record });
  };

  handleClosePopUp = popUpKey => {
    this.setState({ [popUpKey]: false });
  };

  handlePDFPreview = formMetaData => {
    this.setState({
      showPdf: true,
      pdfRecord: formMetaData
    });
  };

  renderFormNameViewLink = ({ record }) => (
    <Button disableRipple onClick={() => this.handleOpenPopUp('openForm', 'view', record)}>
      {record.name}
    </Button>
  );

  getRowActions = () => {
    const { caslKey, readOnly } = this.props;
    const rowActionButtons = {
      view: {
        label: 'View',
        caslKey,
        caslAction: 'view',
        icon: 'Launch'
      },
      pdf: {
        label: 'PDF',
        caslKey,
        caslAction: 'view',
        icon: 'PictureAsPdf'
      }
    };
    if (!readOnly) {
      rowActionButtons.edit = {
        label: 'Edit',
        caslKey,
        caslAction: 'edit',
        icon: 'Edit'
      };
      rowActionButtons.delete = {
        label: 'Delete',
        caslKey,
        caslAction: 'delete',
        icon: 'Delete'
      };
    }
    return rowActionButtons;
  };

  render() {
    const { user, caslKey, readOnly, hideHeader, hideAddBtn } = this.props;
    const {
      openForm,
      addForm,
      modalMode,
      FormViewerRecord,
      confirmDialog,
      confirmAction,
      confirmMessage,
      isSaving,
      refreshCounter,
      showPdf,
      pdfRecord,
      formData,
      parent
    } = this.state;
    const combinedParent = { ...(parent || {}), ...(this.props.parent || {}) };
    return (
      <Grid item xs={12}>
        {!hideHeader && (
          <SectionHeader
            enablePadding
            icon="descriptionIcon"
            overrideHeaderButtons={
              !readOnly &&
              !hideAddBtn && [
                <ThemeProvider>
                  <SGButton
                    key="addButton"
                    startIcon={<AddCircleOutlineIcon />}
                    type={ButtonType.SECONDARY}
                    onClick={() => this.handleOpenPopUp('addForm', 'new')}
                  >
                    Add Form
                  </SGButton>
                </ThemeProvider>
              ]
            }
            title={Labels.Forms[user.locale]}
          />
        )}
        <ResponsiveTable
          customCellComponents={{ formName: this.renderFormNameViewLink }}
          data={formData || []}
          disableFilter
          isLoading={formData === ''}
          key={refreshCounter}
          rowActionButtons={this.getRowActions()}
          rowActions={this.handleFormRowActions}
          rowMetadata={formSectionTable.captionAttributes}
          rowsPerPage={5}
          showToolbars
        />

        <Modal
          disableBackdropClick
          handleClose={() => this.handleClosePopUp('addForm')}
          open={addForm}
          width="960"
        >
          <FormSelector
            callback={() => {
              this.handleClosePopUp('addForm');
              this.refreshTableData();
            }}
            caslKey={caslKey}
            handleCancel={() => this.handleClosePopUp('addForm')}
            parent={combinedParent}
          />
        </Modal>

        {FormViewerRecord && (
          <Modal
            disableBackdropClick={modalMode === 'edit'}
            handleClose={() => this.handleClosePopUp('openForm')}
            open={openForm}
            width="960"
          >
            <FormViewer
              description={FormViewerRecord.description}
              formMetaData={FormViewerRecord.formDataJson}
              handleClose={() => this.handleClosePopUp('openForm')}
              isEditMode={modalMode === 'edit'}
              isSaving={isSaving}
              name={FormViewerRecord.name}
              parent={combinedParent}
              user={user}
              onSubmit={this.editFormData}
            />
          </Modal>
        )}

        <ConfirmModal
          cancel={this.handleCancelConfirmation}
          confirm={confirmAction}
          message={confirmMessage}
          open={confirmDialog}
        />

        <Dialog
          fullScreen
          open={showPdf}
          TransitionComponent={Transition}
          onClose={() => this.setState({ showPdf: false })}
        >
          <Grid alignItems="center" container direction="row" justify="space-between" spacing={2}>
            <Grid item style={{ marginLeft: 30 }}>
              <Typography variant="subtitle2">{`${pdfRecord.name} - Preview`}</Typography>
            </Grid>
            <Grid item>
              <AddRecordButton handle={() => this.setState({ showPdf: false })} label="CLOSE" />
            </Grid>
          </Grid>
          <FormPDF data={pdfRecord} viewPdf />
        </Dialog>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const connectedFormSection = connect(mapStateToProps, mapDispatchToProps)(FormSection);

export default connectedFormSection;
