import React from 'react';

import { TV, TW, Typography } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';

import { useTheme } from '@material-ui/core/styles';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import { Link } from 'react-router-dom';

import Routes from 'scenes/Routes';

import { incompletePOStatuses } from './index';

const PORecieptList = ({ formattedValue }) => {
  const theme = useTheme();

  const receiptsExist = !!formattedValue.receipts.length;
  const incompletePO = incompletePOStatuses.includes(formattedValue.poStatus);
  const noPO = !formattedValue.id;
  return (
    <div>
      {!noPO && (
        <Typography variant={TV.BASE} weight={TW.REGULAR}>
          <Link
            to={Routes.procurementPurchaseOrdersDetail({ mode: 'view', poId: formattedValue.id })}
          >
            {formattedValue.label}
          </Link>
        </Typography>
      )}

      {noPO && (
        <Typography variant={TV.BASE} weight={TW.REGULAR}>
          {formattedValue.label}
        </Typography>
      )}
      {incompletePO && (
        <div css={{ display: 'flex' }}>
          <SubdirectoryArrowRightIcon style={{ color: theme.palette.text.secondary }} />
          <Typography css={{ marginTop: 8 }} variant={TV.BASE} weight={TW.REGULAR}>
            {formattedValue.poStatus}
          </Typography>
        </div>
      )}
      {!incompletePO &&
        receiptsExist &&
        formattedValue.receipts.map(r => (
          <div css={{ display: 'flex' }} key={r.id}>
            <SubdirectoryArrowRightIcon style={{ color: theme.palette.text.secondary }} />
            <Typography css={{ marginTop: 8 }} variant={TV.BASE} weight={TW.REGULAR}>
              <Link to={Routes.procurementReceiptsDetail({ mode: 'view', receiptId: r.id })}>
                {r.label}
              </Link>
            </Typography>
          </div>
        ))}
      {!incompletePO && !receiptsExist && (
        <div css={{ display: 'flex' }}>
          <SubdirectoryArrowRightIcon style={{ color: theme.palette.text.secondary }} />
          <Typography css={{ marginTop: 8 }} variant={TV.BASE} weight={TW.REGULAR}>
            Ordered
          </Typography>
        </div>
      )}
    </div>
  );
};

export default PORecieptList;
