import { useMemo } from 'react';

import { intersection } from 'lodash';

import { useDrag } from 'react-dnd';

import { VisitActions } from '@dispatch/Dispatch.constants';
import { ItemTypes } from '@dispatch/dnd';

import { CornerBannerTypes } from './VisitCard.constants';

const canDragTime = (nextEvents, weekView, mapView, wrinkleTimeFF) =>
  [
    nextEvents?.includes(VisitActions.RESCHEDULE.key),
    ...(wrinkleTimeFF ? [!mapView] : [!(weekView || mapView)])
  ].every(x => x);

export const useVisitDrag = ({ visit, srcTech, isDrawer, isOptimistic }) => {
  return useDrag({
    item: { type: isDrawer ? ItemTypes.TABLE_VISIT : ItemTypes.BOARD_VISIT, data: visit, srcTech },
    canDrag: () => {
      if (isOptimistic) return false;
      return Boolean(
        intersection(visit.nextEvents, [VisitActions.SCHEDULE.key, VisitActions.RESCHEDULE.key])[0]
      );
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag()
    })
  });
};

export const useVisitStartDrag = ({
  visit,
  srcTech,
  weekView,
  mapView,
  wrinkleTimeFF,
  isOptimistic
}) => {
  return useDrag({
    item: { type: ItemTypes.VISIT_START, data: visit, srcTech },
    canDrag: () => {
      if (isOptimistic) return false;
      return canDragTime(visit.nextEvents, weekView, mapView, wrinkleTimeFF);
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag()
    })
  });
};

export const useVisitEndDrag = ({
  visit,
  srcTech,
  weekView,
  mapView,
  wrinkleTimeFF,
  isOptimistic
}) => {
  return useDrag({
    item: { type: ItemTypes.VISIT_END, data: visit, srcTech },
    canDrag: () => {
      if (isOptimistic) return false;
      return canDragTime(visit.nextEvents, weekView, mapView, wrinkleTimeFF);
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag()
    })
  });
};

export const useGetCornerBannerProps = (visit, visitCloseoutFF) =>
  useMemo(() => {
    const activeVisitFlags = visit.visitFlags?.filter(flag => !flag.isDismissed) || [];
    if (activeVisitFlags.length > 0 && visitCloseoutFF) {
      return { bannerType: CornerBannerTypes.Flagged, checked: undefined };
    }
    if (visit.prerequisites) {
      return { bannerType: CornerBannerTypes.ToDo, checked: visit.prerequisitesAcknowledged };
    }
    return {};
  }, [visit, visitCloseoutFF]);
