/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import { useCompanyTimezone } from '@pm/components/hooks';
import { getImageUrl, isStartBeforeEndDate, uploadFiletoCloud } from '@pm/components/utils';
import PlacesSearch from 'components/BuildHeroFormComponents/PlacesSearch';
import DefaultButton from 'components/Buttons/DefaultButton';

import FullScreenModal from 'components/FullScreenModal';
// eslint-disable-next-line import/no-cycle
import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import { setTimezone } from 'redux/actions/projectManagementActions';
import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';
import CustomDivider from 'scenes/ProjectManagement/components/CustomDivider';
import CustomFieldWithLabel from 'scenes/ProjectManagement/components/CustomFieldWithLabel';
import { generateDefaultValidationSchema } from 'scenes/ProjectManagement/components/formattingUtils';
import { getProjects, projectChange, projectCreate } from 'services/API/project';
import { camelCaseToTitleCase } from 'utils';

import { ProjectNumber } from './components';
import { contactFields, dataFields, generalInformationFields, layout } from './layout';
import { useStyles } from './styles';
import { getFormattedData, getFormattedPayload } from './utils';

const CustomDividerStyled = () => {
  return <CustomDivider backgroundColor="#E5E5E5" padding={0} width="100%" />;
};

const CreateProject = props => {
  const { initialData, open, handleClose, user, onSubmit } = props;
  const classes = useStyles();
  const [formService, setFormService] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [showNumberValidation, setShowNumberValidation] = useState(false);
  const [uploadImageUrl, setUploadImageUrl] = useState({ fileUrl: '' });
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [number, setNumber] = useState('');
  const imageUploader = useRef(null);
  const fullscreenModalRef = useRef(null);

  const timezone = useSelector(s => s.pm.timezone.timezone);
  const [{ data: timezoneData, loading }] = useCompanyTimezone();
  useEffect(() => {
    if (!loading && timezoneData) {
      setTimezone(timezoneData, loading);
    }
  }, [timezoneData, loading]);

  const defaultFormData = useMemo(() => getFormattedData(initialData.project), [initialData]);

  const PropertySearchBar = ({ options, form, field }) => {
    const fieldDuplicate = { ...field };
    // Will need a refresh if the selected customer's ID doesn't match the selected property
    const needRefresh = field.value ? field.value.parentId !== form?.values?.customer?.id : false;
    if (needRefresh) {
      fieldDuplicate.value = null;
    }
    return <SearchBar field={fieldDuplicate} form={form} options={{ ...options, needRefresh }} />;
  };

  const sectionTitle = ({ options }) => (
    <Typography className={classes.sectionTitle} variant="subtitle1">
      {options.label}
    </Typography>
  );

  const showAddPhoto = (
    <div className={classes.iconTextContainer}>
      <AddCircleOutlineIcon className={classes.addPhotoIcon} />
      <Typography className={classes.addPhotoText}>
        {camelCaseToTitleCase(Labels.addPhoto[user.locale])}
      </Typography>
    </div>
  );

  const showChangePhoto = (
    <div className={classes.iconTextContainer}>
      <CachedOutlinedIcon className={classes.changePhotoIcon} />
      <Typography className={classes.changePhotoText}>
        {camelCaseToTitleCase(Labels.changePhoto[user.locale])}
      </Typography>
    </div>
  );

  const submitCompleted = useCallback(
    newId => {
      setIsSubmitting(false);
      onSubmit(newId);
      handleClose();
    },
    [handleClose, onSubmit]
  );

  const save = useCallback(
    finalizedData => {
      setIsSubmitting(true);
      if (formService) {
        const payload = getFormattedPayload({ data: finalizedData, user, uploadImageUrl });
        if (initialData.type === 'default') {
          projectCreate(payload).then(project => submitCompleted(project.id));
        } else {
          projectChange(initialData.project.id, payload).then(() => submitCompleted());
        }
      }
    },
    [formService, initialData, user, submitCompleted, uploadImageUrl]
  );

  const handleImageUpload = async event => {
    const uploadFile = {};
    [uploadFile.file] = event.target.files;

    if (uploadFile.file) {
      const s3FileUrl = await uploadFiletoCloud(uploadFile, user.tenantId);
      uploadFile.fileUrl = await getImageUrl(s3FileUrl);
      setUploadImageUrl(uploadFile);
      setIsImageSelected(true);
    }
  };

  useEffect(() => {
    const setImageData = () => {
      const { imgUrl } = initialData.project;
      setUploadImageUrl({ fileUrl: imgUrl });
      setIsImageSelected(!!imgUrl);
    };
    setImageData();
  }, [initialData.project]);

  useEffect(() => {
    if (fullscreenModalRef?.current?.parentNode?.style.maxWidth < 1440) {
      fullscreenModalRef.current.parentNode.style.maxWidth = '1440px';
      fullscreenModalRef.current.parentNode.style.marginTop = '0px';
    }
  });

  const handleFormSave = completed => {
    if (isStartBeforeEndDate(completed.dateStart, completed.dateEnd)) {
      save(completed);
    } else {
      props.snackbarOn('error', 'Start date must be before End date');
    }
  };

  return (
    <FullScreenModal
      handleClose={() => {
        setNumber('');
        setShowNumberValidation(false);
        handleClose();
      }}
      modalHeaderButtons={[
        <DefaultButton
          disabled={isSubmitting}
          key="save"
          label="Save project"
          showSpinner={isSubmitting}
          variant="containedPrimary"
          onClick={() => {
            getProjects({ number }).then(projList => {
              let valid = true;
              if (projList.length > 0) {
                valid = false;
              }
              setShowNumberValidation(!valid);
              if (valid) {
                formService.submit();
              }
            });
          }}
        />
      ]}
      open={open}
      title={
        initialData.type === 'default'
          ? Labels.addProject[user.locale]
          : Labels.editProject[user.locale]
      }
    >
      <Box className={classes.contentContainer} ref={fullscreenModalRef}>
        <Box className={classes.formContainer}>
          <Box
            className={classNames(
              classes.validationError,
              !showNumberValidation && classes.hideElement
            )}
          >
            That project number already exists.
          </Box>
          <MUIForm
            configuration={layout(timezone, selectedCustomer, setSelectedCustomer)}
            customComponents={{
              CustomFieldWithLabel,
              CustomDividerStyled,
              sectionTitle,
              SearchBar,
              PlacesSearch,
              ProjectNumber,
              PropertySearchBar
            }}
            data={defaultFormData}
            layout="default"
            validationSchema={generateDefaultValidationSchema({
              ...generalInformationFields,
              ...contactFields,
              ...dataFields
            })}
            onComplete={handleFormSave}
            onCreateService={service => setFormService(service)}
            onFieldChange={(field, currVal) => {
              if (field === 'number') {
                setNumber(currVal);
              }
            }}
            // TODO: onFormChange, etc
          />
        </Box>
        <Box className={classes.uploadImageContainer}>
          <ButtonBase className={classes.imageInputContainer} focusRipple>
            <input
              accept="image/*"
              className={classes.hideElement}
              ref={imageUploader}
              type="file"
              onChange={handleImageUpload}
            />
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div className={classes.imageContainer} onClick={() => imageUploader.current.click()}>
              <div
                className={classNames(
                  isImageSelected ? classes.selectedImage : classes.hideElement
                )}
                style={{ backgroundImage: `url(${uploadImageUrl.fileUrl})` }}
              />
              <span className={classNames(!isImageSelected && classes.imageBackdrop)} />
              <span className={classes.imageButton}>
                {isImageSelected ? showChangePhoto : showAddPhoto}
              </span>
            </div>
          </ButtonBase>
        </Box>
      </Box>
    </FullScreenModal>
  );
};

CreateProject.propTypes = {
  initialData: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  snackbarOn: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatcherToProps = { snackbarOn };
export default connect(mapStateToProps, mapDispatcherToProps)(CreateProject);
