import React, { useMemo, useState } from 'react';

import {
  CurrencyInput,
  InlineAlert,
  InlineAlertTypes,
  PercentageInput,
  Typography
} from '@BuildHero/sergeant';
import { Chip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { bool, func, object } from 'prop-types';
import { Link } from 'react-router-dom';

import Routes from 'scenes/Routes';
import { convertToCurrencyString } from 'utils';
import { JobBillingStatus } from 'utils/AppConstants';
import { InventoryPartSourceType } from 'utils/constants';
import getJobIdentifier from 'utils/getJobIdentifier';

import useCreateInvoiceFromQuotedAmountJob from '../../hooks/useCreateInvoiceFromQuotedAmountJob';
import useDepartmentValue from '../../hooks/useDepartmentValue';
import useQuotedAmountSetting from '../../hooks/useQuotedAmountSetting';
import CreateInvoiceModal from '../CreateInvoiceModal';
import DepartmentSelect from '../DepartmentSelect';
import JobLabel from '../JobLabel';

import { useStyles } from './QuotedAmountJobCreateInvoiceModal.styles';

const QuotedAmountJobCreateInvoiceModal = ({ jobData, open, onClose, onSuccess }) => {
  const { amountQuoted, inventoryParts } = jobData;
  const amountRemainingToBeInvoiced = useMemo(
    () =>
      amountQuoted -
      (inventoryParts?.items?.reduce((amount, part) => {
        if (part.source !== InventoryPartSourceType.AMOUNT_QUOTED || !part.invoiceItemId)
          return amount;
        return amount + part.unitPrice;
      }, 0) || 0),
    [inventoryParts, amountQuoted]
  );
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [departmentValue, setDepartmentValue] = useDepartmentValue(jobData);
  const [partialBilling, setPartialBilling] = useState(false);
  const [partialBillingAmount, setPartialBillingAmount] = useState(amountRemainingToBeInvoiced);
  const quotedAmountSetting = useQuotedAmountSetting();
  const [createInvoice] = useCreateInvoiceFromQuotedAmountJob();

  const jobIdentifier = getJobIdentifier(jobData);

  const isJobFullyInvoiced = jobData.billingStatus === JobBillingStatus.FULLY_INVOICED;

  const canCreateInvoice = !isJobFullyInvoiced && Boolean(departmentValue);

  const renderBody = () => {
    if (isJobFullyInvoiced) {
      return (
        <>
          <JobLabel jobIdentifier={jobIdentifier} />
          <div css={styles.container}>
            <Typography>All quoted amount was invoiced.</Typography>
          </div>
        </>
      );
    }

    if (quotedAmountSetting.length) {
      return (
        <>
          <JobLabel jobIdentifier={jobIdentifier} />
          <div css={styles.partialBillingContainer}>
            <Chip
              css={partialBilling ? styles.partialBillingChip : styles.selectedPartialBillingChip}
              label="Entire Job"
              onClick={() => setPartialBilling(false)}
            />
            <Chip
              css={partialBilling ? styles.selectedPartialBillingChip : styles.partialBillingChip}
              label="Partial Billing"
              onClick={() => setPartialBilling(true)}
            />
          </div>

          {quotedAmountSetting.map(setting => {
            const { id, name, percentage } = setting || {};
            let amount = (amountRemainingToBeInvoiced * (percentage || 0)) / 100;
            if (partialBilling) amount = (partialBillingAmount * (percentage || 0)) / 100;
            return (
              <div css={styles.container} key={id}>
                <Typography>{name || ''}</Typography>
                <Typography>{convertToCurrencyString(amount)}</Typography>
              </div>
            );
          })}
          {partialBilling && (
            <div css={styles.partialBillingInputContainer}>
              <div css={styles.partialBillingInput}>
                <CurrencyInput
                  disabled={loading}
                  label="Invoice Amount"
                  value={partialBillingAmount}
                  onBlur={val => {
                    const amount = Math.min(val ?? 0, amountRemainingToBeInvoiced);
                    setPartialBillingAmount(amount);
                  }}
                />
              </div>
              <div css={styles.partialBillingInput}>
                <PercentageInput
                  label="Invoice Percent"
                  readOnly
                  value={(partialBillingAmount / amountRemainingToBeInvoiced) * 100}
                />
              </div>
            </div>
          )}
          <DepartmentSelect
            disabled={loading}
            value={departmentValue}
            onChange={setDepartmentValue}
          />
        </>
      );
    }

    return (
      <>
        <JobLabel jobIdentifier={jobIdentifier} />
        <div css={styles.container}>
          <InlineAlert Icon={WarningIcon} type={InlineAlertTypes.YELLOW}>
            Please set your default line items in&nbsp;
            <Link to={Routes.settingsQuotes}>quote settings</Link>
            &nbsp;before invoicing this job
          </InlineAlert>
        </div>
      </>
    );
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await createInvoice({
        jobId: jobData?.id,
        departmentId: departmentValue?.value,
        amountQuoted: partialBilling ? partialBillingAmount : amountRemainingToBeInvoiced
      });
      await onSuccess();
      return response?.data?.createInvoiceFromQuotedAmountJob;
    } finally {
      setLoading(false);
    }
  };

  return (
    <CreateInvoiceModal
      canCreateInvoice={canCreateInvoice}
      open={open}
      renderBody={renderBody}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

QuotedAmountJobCreateInvoiceModal.propTypes = {
  jobData: object.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
  onSuccess: func.isRequired
};

export default QuotedAmountJobCreateInvoiceModal;
