import { useMemo } from 'react';

import { QuoteBillingStatus, QuoteStatus } from 'utils/AppConstants';

export const useQuoteItems = quotes =>
  useMemo(() => {
    return (
      quotes
        ?.filter(item => item?.quote?.billingStatus !== QuoteBillingStatus.BILLED && item?.quote?.status === QuoteStatus.JOB_ADDED)
        ?.map(item => {
          const quote = item?.quote || {};
          const name = quote?.customIdentifier || quote?.name;
          const quoteNumber = quote?.quoteNumber;
          const amount = quote?.totalAmountQuotedOverride || quote?.totalAmountQuoted || 0;
          return {
            id: quote?.id,
            amount,
            label: `${quoteNumber} - ${name}`,
            subTotal: quote?.subTotalOverride ?? quote?.subTotal
          };
        }) || []
    );
  }, [quotes]);
