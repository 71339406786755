/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
import React, { useEffect, useReducer, useRef, useState } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import { getLastLineNumber } from 'scenes/Procurement/component/utils';
import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';
import buildHeroMuiFormOverrides from 'scenes/ProjectManagement/components/buildHeroMuiFormOverrides';
import CustomFieldWithLabel from 'scenes/ProjectManagement/components/CustomFieldWithLabel';
import { generateDefaultValidationSchema } from 'scenes/ProjectManagement/components/formattingUtils';
import FormWithAccordion from 'scenes/ProjectManagement/components/FormWithAccordion';
import SimpleTitleContent from 'scenes/ProjectManagement/components/SimpleTitleContent';
import { changeOrderLineItemDelete } from 'services/API/changeOrderLineItem';
import { roundTo } from 'utils';
import { findById } from 'utils/ramda';

import {
  getDefaultIdFromMapping,
  getDefaultIds,
  getPhaseDeptCostCodesMapping
} from '../../../FieldReport/DailyReportList/GenerateDailyReport/DailyReportsForm/LaborFieldForm/LaborFieldForm.helpers';

import { additionalPercentlayout, layout, fields as typeFormFields } from './layout';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 24
  },
  accordionFooter: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '21px 28px'
  },
  hideElement: {
    display: 'none'
  },
  addButton: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '14px',
    textDecoration: 'none'
  },
  deleteButton: {
    background: theme?.palette?.error?.light
  },
  footerForm: {
    width: 702
  },
  footerTotal: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 21
  },
  noButton: {
    visibility: 'hidden',
    disabled: true
  },
  formContainer: buildHeroMuiFormOverrides(theme)
}));

// This form is truly terrible, if you are the one who ends up converting to a newer form
// version, blow this away and start from scratch. Do not lift-and-shift or reference this
// code at all.

const TypeForm = props => {
  const {
    projectId,
    data,
    type,
    index,
    projectPhases,
    getHandleCreateService,
    getHandleComplete,
    onChange,
    footerData,
    deleteButton
  } = props;

  const [selectedIds, setSelectedIds] = useState({
    phaseId: undefined,
    departmentId: undefined,
    costCodeId: undefined
  });
  const selectedIdsRef = useRef();
  selectedIdsRef.current = selectedIds;

  const onFormChange = value => {
    const { phaseId, departmentId, costCodeId } = value;

    if (
      phaseId !== selectedIdsRef.current.phaseId ||
      departmentId !== selectedIdsRef.current.departmentId ||
      costCodeId !== selectedIdsRef.current.costCodeId
    ) {
      setSelectedIds({ phaseId, departmentId, costCodeId });

      const phase = findById(phaseId)(projectPhases || []);
      const department = findById(departmentId)(phase?.ProjectPhaseDepartment);
      const costCode = findById(costCodeId)(department?.ProjectPhaseDepartmentCostCode);

      const defaultValueMapping = getPhaseDeptCostCodesMapping(projectPhases);
      const defaultDepartmentId =
        getDefaultIdFromMapping(defaultValueMapping?.[phaseId]) || departmentId;
      const defaultCostCodeId = getDefaultIdFromMapping(
        defaultValueMapping?.[phaseId]?.[defaultDepartmentId]
      );

      onChange({
        ...value,
        ProjectPhase: phase,
        ProjectPhaseDepartment: department,
        ProjectPhaseDepartmentCostCode: costCode,
        phaseId: phase?.id || undefined,
        costCodeId: costCode?.id || defaultCostCodeId,
        departmentId: department?.id || defaultDepartmentId
      });
    } else {
      onChange(value);
    }
  };

  const formatChangeOrderLineForSave = finalData => {
    return {
      id: data?.id || undefined,
      type,
      cost: finalData?.cost || 0,
      projectCostCodeId: finalData.ProjectPhaseDepartmentCostCode?.id || null,
      projectCostCodeName: finalData.ProjectPhaseDepartmentCostCode?.name || null,
      projectCostCodeDescription: finalData.ProjectPhaseDepartmentCostCode?.description || null,
      description: finalData.description,
      notes: finalData.notes,
      sellPrice: Number(finalData.sellPrice),
      quantity: Number(finalData.quantity),
      projectPhaseId: finalData.ProjectPhase?.id || null,
      projectPhaseName: finalData.ProjectPhase?.name || null,
      taxable: finalData.taxable || 0,
      phaseDepartmentId: finalData.ProjectPhaseDepartment?.id || null,
      phaseDepartmentTagName: finalData.ProjectPhaseDepartment?.tagName || null,
      phaseDepartmentLaborDollars: Number(finalData.ProjectPhaseDepartment?.laborDollars ?? 0),
      phaseDepartmentMaterialCost: Number(finalData.ProjectPhaseDepartment?.materialCost ?? 0),
      phaseDepartmentEquipmentCost: Number(finalData.ProjectPhaseDepartment?.equipmentCost ?? 0),
      phaseDepartmentSubcontractorCost: Number(
        finalData.ProjectPhaseDepartment?.subcontractorCost ?? 0
      ),
      phaseDepartmentOverheadCost: Number(finalData.ProjectPhaseDepartment?.overheadCost ?? 0),
      phaseDepartmentOtherCost: Number(finalData.ProjectPhaseDepartment?.otherCost ?? 0),
      projectId,
      overheadPercent: roundTo(footerData?.overheadPercent || 0),
      profitPercent: roundTo(footerData?.profitPercent || 0),
      taxPercent: roundTo(footerData?.taxPercent || 0)
    };
  };

  const formattedData = (item, indexNum, serviceName) => {
    const defaultData = {
      ...item,
      index: indexNum,
      serviceName
    };

    if (!item.ProjectPhase) {
      defaultData.ProjectPhase =
        defaultData.projectPhaseId && defaultData.projectPhaseName
          ? { id: defaultData.projectPhaseId, name: defaultData.projectPhaseName ?? '' }
          : null;
    }
    if (!item.ProjectPhaseDepartment) {
      defaultData.ProjectPhaseDepartment =
        defaultData.phaseDepartmentId && defaultData.phaseDepartmentTagName
          ? {
              id: defaultData.phaseDepartmentId,
              tagName: defaultData.phaseDepartmentTagName ?? '',
              laborDollars: defaultData.phaseDepartmentLaborDollars ?? null,
              materialCost: defaultData.phaseDepartmentMaterialCost ?? null,
              equipmentCost: defaultData.phaseDepartmentEquipmentCost ?? null,
              subcontractorCost: defaultData.phaseDepartmentSubcontractorCost ?? null,
              overheadCost: defaultData.phaseDepartmentOverheadCost ?? null,
              otherCost: defaultData.phaseDepartmentOtherCost ?? null
            }
          : null;
    }
    if (!item.ProjectPhaseDepartmentCostCode) {
      defaultData.ProjectPhaseDepartmentCostCode =
        defaultData.projectCostCodeId && defaultData.projectCostCodeName
          ? {
              id: defaultData.projectCostCodeId,
              name: defaultData.projectCostCodeName ?? '',
              description: defaultData.projectCostCodeDescription ?? ''
            }
          : null;
    }
    return defaultData;
  };

  return (
    <div style={{ marginTop: '15px' }}>
      <MUIForm
        configuration={layout(
          projectId,
          data?.ProjectPhase?.id ?? selectedIds.phaseId,
          data?.ProjectPhaseDepartment?.id ?? selectedIds.departmentId,
          projectPhases,
          type === 'labor',
          () => {}
        )}
        customComponents={{
          SearchBar,
          deleteButton
        }}
        data={formattedData(data, index, `${type}-${index}`)}
        validationSchema={generateDefaultValidationSchema(typeFormFields)}
        onComplete={getHandleComplete(`${type}-${index}`, formatChangeOrderLineForSave, [
          'ChangeOrderLineItem'
        ])}
        onCreateService={getHandleCreateService(`${type}-${index}`)}
        onFormChange={onFormChange}
      />
    </div>
  );
};

// State is an array of the items in this FieldForm.
// We have to use redux here since we're using the values from the form
// to calculate the Cost Subtotal and Revenue Subtotal. It also makes
// it much easier to handle deleting an item.
const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'update': {
      const stateCopy = [...state];
      const itemIndex = stateCopy.findIndex(item => item.index === payload.index);
      if (itemIndex >= 0) stateCopy[itemIndex] = payload;
      return [...stateCopy];
    }
    case 'add': {
      return [...state, payload];
    }
    case 'delete': {
      return [...state.filter(value => payload.index !== value.index)];
    }
    case 'initialize': {
      return [];
    }
    default: {
      return [...payload];
    }
  }
};

const getInitialFormData = initData => {
  return initData.length > 0
    ? initData.map((value, index) => ({
        ...value,
        phaseId: value?.projectPhaseId,
        departmentId: value?.phaseDepartmentId,
        costCodeId: value?.projectCostCodeId,
        index
      }))
    : [];
};

const FieldForm = props => {
  const {
    initialData,
    mode,
    getHandleCreateService,
    getHandleComplete,
    getHandleRemoveService,
    locale,
    projectId,
    projectPhases,
    name
  } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const [panelExpanded, setPanelExpanded] = useState(false);
  const [laborDataTotal, setLaborDataTotal] = useState([]);
  const [footerData, setFooterData] = useState(initialData[0] || {});
  const [totals, setTotals] = useState([]);
  const [totalsObj, setTotalsObj] = useState({});

  const [stateItems, dispatchStateItems] = useReducer(reducer, getInitialFormData(initialData));

  const getFormattedData = (data, index) => {
    const { defaultPhaseId, defaultDepartmentId, defaultCostCodeId } = getDefaultIds(projectPhases);

    return {
      index,
      description: data?.description || '',
      quantity: data?.quantity || '',
      cost: data?.cost || '',
      sellPrice: data?.sellPrice || '',
      notes: data?.notes || '',
      ProjectPhase: data?.ProjectPhase || '',
      ProjectPhaseDepartmentCostCode: data?.ProjectPhaseDepartmentCostCode || '',
      phaseId: defaultPhaseId,
      departmentId: defaultDepartmentId,
      costCodeId: defaultCostCodeId,
      taxable: data?.taxable || false
    };
  };

  const combineTotals = () => {
    const value = totals.reduce(
      (total, current) => {
        return {
          cost: total.cost + current.cost,
          revenue: total.revenue + current.revenue,
          taxAmount: total.taxAmount + (current.taxAmount || 0)
        };
      },
      {
        cost: 0,
        revenue: 0,
        updatedRevenue: 0,
        marginPercentage: 0,
        profit: 0,
        taxAmount: 0
      }
    );

    value.updatedRevenue =
      value.revenue *
        (((footerData.overheadPercent || 0) + (footerData.profitPercent || 0)) / 100 + 1) +
      value.taxAmount;

    value.profit = value.updatedRevenue - value.cost;
    value.marginPercentage = value.revenue > 0 ? (value.profit / value.updatedRevenue) * 100 : 0;
    value.revenue = value.updatedRevenue;
    return value;
  };

  useEffect(() => {
    const obj = combineTotals();
    setTotalsObj(obj);
    setLaborDataTotal([
      {
        type: 'currency',
        content: obj?.cost || 0,
        title: 'Cost subtotal'
      },
      {
        type: 'currency',
        content: obj?.revenue || 0,
        title: 'Revenue subtotal'
      },
      {
        type: 'percentage',
        content: obj?.marginPercentage || 0,
        title: 'Margin percentage'
      }
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totals]);

  useEffect(() => {
    if (initialData?.length > 0) {
      dispatchStateItems({ payload: getInitialFormData(initialData) });
      const newTotals = [];
      initialData.map(line => {
        const cost = Number(line.cost) * Number(line.quantity);
        const revenue =
          (line.sellPrice || 0) *
          (line.quantity || 0) *
          ((((line.taxable && line.taxPercent) || 0) +
            (line.overheadPercent || 0) +
            (line.profitPercent || 0)) /
            100 +
            1);

        newTotals.push({
          cost,
          revenue
        });
      });

      setFooterData({
        taxPercent: initialData[0]?.taxPercent || 0,
        overheadPercent: initialData[0]?.overheadPercent || 0,
        profitPercent: initialData[0]?.profitPercent || 0
      });
      setTotals(newTotals);
    } else {
      dispatchStateItems({ type: 'initialize' });
      setFooterData({
        taxPercent: 0,
        overheadPercent: 0,
        profitPercent: 0
      });
      setTotals([]);
    }
  }, [initialData]);

  const handleDeleteButton = async item => {
    if (item.id) {
      await changeOrderLineItemDelete(item.id);
      props.snackbarOn('success', 'An item was successfully deleted');
    }
    if (item.serviceName) getHandleRemoveService(item.serviceName);
    dispatchStateItems({ type: 'delete', payload: item });
  };

  const deleteButton = ({ field }) => {
    return (
      <Button
        className={classes.deleteButton}
        color="secondary"
        variant="contained"
        onClick={() => handleDeleteButton(field?.value || {})}
      >
        Delete
      </Button>
    );
  };

  const getFormattedFooterData = data => {
    return {
      taxPercent: data?.taxPercent || '',
      overheadPercent: data?.overheadPercent || '',
      profitPercent: data?.profitPercent || '',
      costSubtotal: totalsObj.cost,
      revenueSubtotal: totalsObj.revenue,
      marginPercentage: totalsObj.marginPercentage
    };
  };

  const setLocaleText = () => {
    let ret = '';
    switch (name) {
      case 'equipment':
        ret = Labels.addEquipment[locale];
        break;
      case 'labor':
        ret = Labels.addLabor[locale];
        break;
      case 'material':
        ret = Labels.addMaterial[locale];
        break;
      case 'other':
        ret = Labels.addOther[locale];
        break;
      case 'overhead':
        ret = Labels.addOverhead[locale];
        break;
      case 'subcontractor':
        ret = Labels.addSubcontractor[locale];
        break;
      default:
    }
    return ret;
  };

  const handleAddNewFormData = () => {
    const indexNumber = stateItems.length ? getLastLineNumber(stateItems, 'index') + 1 : 0;
    const newFormData = getFormattedData(null, indexNumber);
    dispatchStateItems({ type: 'add', payload: newFormData });
  };

  const addFormBtn = () => {
    return (
      <Button
        className={classes.addButton}
        startIcon={<AddCircleOutlineIcon />}
        onClick={handleAddNewFormData}
      >
        {setLocaleText()}
      </Button>
    );
  };

  useEffect(() => {
    const newTotals = [];
    stateItems.forEach(value => {
      const cost = Number(value?.cost || 0) * Number(value?.quantity || 0);
      const revenue = Number(value?.sellPrice || 0) * Number(value?.quantity || 0);
      let updatedRevenue = revenue;
      if (value?.taxable) {
        updatedRevenue += revenue * ((footerData.taxPercent || 0) / 100);
      }
      newTotals.push({
        cost,
        revenue,
        updatedRevenue,
        taxAmount: updatedRevenue - revenue
      });
    });
    setTotals(newTotals);
  }, [stateItems, footerData]);

  const onChange = value => {
    dispatchStateItems({ type: 'update', payload: value });
  };

  const footerComponent = (
    <>
      <Box className={classNames(classes.footerForm, classes.formContainer)}>
        <MUIForm
          configuration={additionalPercentlayout()}
          customComponents={{ CustomFieldWithLabel }}
          data={{
            ...getFormattedFooterData(footerData)
          }}
          layout={mode}
          onComplete={getHandleComplete(`${name}`, null, ['Percentages'])}
          onCreateService={getHandleCreateService(`${name}`)}
          onFormChange={data => {
            if (data) {
              setFooterData(data);
            }
          }}
        />
      </Box>
    </>
  );

  return (
    <div className={classes.root}>
      <FormWithAccordion
        buttonComponent={addFormBtn()}
        expanded={panelExpanded}
        footerComponent={stateItems.length ? footerComponent : null}
        formComponent={
          <div>
            {stateItems.map(value => (
              <TypeForm
                data={value}
                deleteButton={deleteButton}
                footerData={footerData}
                getHandleComplete={getHandleComplete}
                getHandleCreateService={getHandleCreateService}
                index={value.index}
                key={`form-container-${value.index}-${name}`}
                projectId={projectId}
                projectPhases={projectPhases}
                type={name}
                onChange={onChange}
              />
            ))}
          </div>
        } // prevent services from being created for types that have no values
        handlePanelExpand={() => setPanelExpanded(!panelExpanded)}
        sectionName={name}
      />
      <Box className={classNames(classes.accordionFooter, panelExpanded && classes.hideElement)}>
        {laborDataTotal.map(item => (
          <SimpleTitleContent data={item} key={item.title} />
        ))}
      </Box>
      <Divider />
    </div>
  );
};

TypeForm.propTypes = {
  projectId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  getHandleCreateService: PropTypes.func.isRequired,
  getHandleComplete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  projectPhases: PropTypes.object.isRequired,
  footerData: PropTypes.object.isRequired,
  deleteButton: PropTypes.func.isRequired
};

FieldForm.propTypes = {
  initialData: PropTypes.array.isRequired,
  projectPhases: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  getHandleCreateService: PropTypes.func.isRequired,
  getHandleComplete: PropTypes.func.isRequired,
  getHandleRemoveService: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatcherToProps = { snackbarOn };
const ReduxConnectedFieldForm = connect(mapStateToProps, mapDispatcherToProps)(FieldForm);

export default ReduxConnectedFieldForm;
