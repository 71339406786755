import React, { useMemo, useState } from 'react';

import { Modal, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { Box, useTheme } from '@material-ui/core';

import { useCompanyTimezone } from '@pm/components/hooks';

import CalendarUpcoming from 'assets/Icons/CalendarUpcoming';
import { LinkButton } from 'components';
import WrapTable from 'components/WrapTable';

import {
  getFormattedDateString,
  getFormattedHoursString,
  getMomentTimezoneDate,
  getQuantityString
} from 'utils';

import useCustomerPropertyMaintenanceJobPreview from './hooks/useCustomerPropertyMaintenanceJobPreview';

const PropertyViewMaintenancePlan = ({ serviceAgreementId, customerPropertyId, data }) => {
  const theme = useTheme();

  const [{ data: companyTimezone }] = useCompanyTimezone();

  const [tasksPreviewModal, setTasksPreviewModal] = useState({ open: false });

  const currentYear = useMemo(
    () => getMomentTimezoneDate(Date.now() / 1000, companyTimezone).year(),
    [companyTimezone]
  );
  const columns = useMemo(
    () => [
      {
        field: 'id',
        hide: true
      },
      {
        field: 'maintenanceName',
        headerName: 'Maintenances',
        renderCell: cell => {
          const maintenanceYear = getMomentTimezoneDate(
            cell.row.firstDueDate,
            companyTimezone
          ).year();

          return maintenanceYear > currentYear ? (
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              {cell.value}{' '}
              <CalendarUpcoming
                css={{ fill: theme.palette.grayscale(50), marginLeft: 8, marginTop: 8 }}
              />
            </Box>
          ) : (
            cell.value
          );
        },
        flex: 1
      },
      {
        field: 'firstDueDate',
        headerName: 'Due Date',
        renderCell: cell => getFormattedDateString(cell.value, companyTimezone),
        flex: 1
      },
      {
        field: 'numberOfPropertyAssets',
        headerName: 'Assets Being Serviced',
        renderCell: cell => getQuantityString(cell.value, 'asset'),
        flex: 1
      },
      {
        field: 'cumulativeLaborEstimate',
        headerName: 'Labor Estimate',
        renderCell: cell => getFormattedHoursString(cell.value),
        flex: 1
      },
      {
        field: 'propertyChecklistNames',
        headerName: 'Property Checklists',
        flex: 1
      },
      {
        field: 'mergedTaskTemplates',
        headerName: 'Maintenance Tasks',
        renderCell: cell => (
          <LinkButton
            disableLink
            label={`${cell.value.length} Tasks`}
            onClick={() =>
              setTasksPreviewModal({
                open: true,
                tasks: cell.value
              })
            }
          />
        ),
        flex: 1
      }
    ],
    [companyTimezone]
  );

  const { data: rows = [], loading } = useCustomerPropertyMaintenanceJobPreview({
    serviceAgreementId,
    customerPropertyId
  });

  if (loading) {
    return null;
  }

  return (
    <div css={{ height: '100vh', overflow: 'scroll' }}>
      <Typography variant={TV.L} weight={TW.BOLD}>
        {data.propertyName || ''}
      </Typography>
      <Typography css={{ paddingBottom: 24 }} variant={TV.B2} weight={TW.REGULAR}>
        Individual maintenances are created for each property by grouping tasks with the same due
        dates.
      </Typography>
      <Typography variant={TV.B1} weight={TW.BOLD}>
        Annual Maintenances
      </Typography>
      <WrapTable columns={columns} hideFooter={rows.length < 11} loadingRows={3} rows={rows} />
      {rows.some(
        ({ firstDueDate }) => getMomentTimezoneDate(firstDueDate).year() > currentYear
      ) && (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <CalendarUpcoming css={{ fill: '#808080', marginTop: 8 }} />
          Will occur next calendar year
        </Box>
      )}
      <ThemeProvider>
        <Modal
          open={tasksPreviewModal.open}
          title="Maintenance Tasks"
          onClose={() => setTasksPreviewModal({ open: false })}
        >
          {tasksPreviewModal.tasks?.map(t => (
            <div key={t.id}>{t.name}</div>
          ))}
        </Modal>
      </ThemeProvider>
    </div>
  );
};

export default PropertyViewMaintenancePlan;
