import React from 'react';

import { Text, View } from '@react-pdf/renderer';

import styles from '../style';

const VerifierCertificate = () => {
  return (
    <View style={styles.verifierCertificate}>
      <Text style={styles.title}>VERIFIER'S CERTIFICATE FOR PAYMENT</Text>
      <Text style={[styles.smallText, { marginTop: 5 }]}>
        In accordance with the Contract Documents, based on on-site observations and the data
      </Text>
      <Text style={styles.smallText}>
        comprising the application, the Architect certifies to the Owner that to the best of the
      </Text>
      <Text style={styles.smallText}>
        Architect's knowledge, information and belief the Work has progressed as indicated,
      </Text>
      <Text style={styles.smallText}>
        the quality of the Work is in accordance with the Contract Documents, and the Contractor
      </Text>
      <Text style={styles.smallText}>is entitled to payment of the AMOUNT CERTIFIED.</Text>
      <View style={[styles.displayFlex, { marginTop: 10 }]}>
        <Text style={styles.text}>AMOUNT CERTIFIED . . . . . . . . . . . $</Text>
        <Text style={[styles.underline, { marginLeft: 25, width: 170 }]} />
      </View>
      <Text style={[styles.smallText, { marginTop: 10, fontFamily: 'Times-Italic' }]}>
        (Attach explanation if amount certified differs from the amount applied. Initial all figures
        on this
      </Text>
      <Text style={[styles.smallText, { fontFamily: 'Times-Italic' }]}>
        Application and onthe Continuation Sheet that are changed to conform with the amount
        certified.)
      </Text>
      <Text style={[styles.text, { marginTop: 10 }]}>VERIFICATION</Text>
      <View style={[styles.displayFlex, { marginTop: 10 }]}>
        <Text style={styles.text}>By:</Text>
        <Text style={[styles.underline, { marginRight: 20, width: 170 }]} />
        <Text style={styles.text}>Date:</Text>
        <Text style={styles.underline} />
      </View>
      <Text style={[styles.text, { marginTop: 5 }]}>
        This Certificate is not negotiable. The AMOUNT CERTIFIED is payable only to the
      </Text>
      <Text style={styles.text}>
        Contractor named herein. Issuance, payment and acceptance of payment are without
      </Text>
      <Text style={styles.text}>
        prejudice to any rights of the Owner or Contractor under this Contract.
      </Text>
    </View>
  );
};

export default VerifierCertificate;
