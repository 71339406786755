import React from 'react';

import { Checkbox } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

import Labels from 'meta/labels';

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, rows, classes, showButtonsColumn, user } = this.props;
    const highlightFirstColumn = !!this.props.highlightFirstColumn;

    return (
      <TableHead>
        <TableRow>
          {rows.map(
            (row, index) => (
              <TableCell
                align={row.numeric ? 'right' : 'left'}
                className={
                  index === 0 && highlightFirstColumn ? classes.highlightFirstColumn : 'none'
                }
                //    padding={row.disablePadding ? "none" : "default"}
                key={row.id}
                sortDirection={orderBy === row.id ? order : false}
              >
                {row.id === 'checkBox' ? (
                  <Checkbox onChange={event => this.props.handleSelectAll(event)} />
                ) : (
                  <Tooltip
                    enterDelay={300}
                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                    title="Sort"
                  >
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {Labels[row.label]?.[user.locale] || row.label}
                    </TableSortLabel>
                  </Tooltip>
                )}
              </TableCell>
            ),
            this
          )}
          {showButtonsColumn && <TableCell />}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
};

export default EnhancedTableHead;
