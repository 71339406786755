import React from 'react';

import { Divider, ThemeProvider } from '@BuildHero/sergeant';
import { useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { PageHeader, UserPermission } from 'components';
import ErrorBoundary from 'scenes/Error';
import { PermissionConstants } from 'utils/AppConstants';

import { InvoiceHeaderSetting } from './components';

const InvoiceSettings = () => {
  const user = useSelector(state => state.user);
  const { spacing } = useTheme();

  return (
    <UserPermission action={PermissionConstants.MENU_ACCOUNTING} I="read">
      <ErrorBoundary>
        <PageHeader pageMapKey="invoiceSettings" userLocale={user.locale} />
        <ThemeProvider>
          <Divider marginLeft={-spacing(3)} width={`calc(100% + ${spacing(6)}px)`} />
          <div css={{ display: 'flex', flexDirection: 'column' }}>
            <InvoiceHeaderSetting />
          </div>
        </ThemeProvider>
      </ErrorBoundary>
    </UserPermission>
  );
};

export default InvoiceSettings;
