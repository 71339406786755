import React from 'react';

import { Typography } from '@material-ui/core';

import { AlgoliaSearch, Modal } from 'components';
import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';
import AppConstants from 'utils/AppConstants';

const CreateJobModal = props => {
  const { open, history, handleClose } = props;

  const handleSearchResultsFilter = resultList => {
    const allowedIds = [];
    const uniqueCustomerIds = [];
    const companyBillingAddressResult = resultList.filter(
      hit =>
        hit.entityType === 'CompanyAddress' &&
        hit.parentEntityType === 'Customer' &&
        hit.addressType === 'billingAddress'
    );

    const companyPropertyAddressResult = resultList
      .filter(
        hit =>
          hit.entityType === 'CompanyAddress' &&
          hit.parentEntityType === 'CustomerProperty' &&
          hit.addressType === 'propertyAddress' &&
          hit.customerSortKey.includes('_Customer_')
      )
      .map(hit => ({
        id: hit.id,
        parentId: hit.customerSortKey?.split('_Customer_')?.[1] // the parentId is the Property id, this is the id of the Customer.
      }));

    const combinedAddresses = [...companyBillingAddressResult, ...companyPropertyAddressResult];

    if (combinedAddresses && combinedAddresses.length > 0) {
      combinedAddresses.forEach(a => {
        if (!uniqueCustomerIds.includes(a.parentId)) {
          uniqueCustomerIds.push(a.parentId);
          allowedIds.push(a.id);
        }
      });
    }

    const customerList = resultList.filter(hit => hit.entityType === 'Customer');
    if (customerList && customerList.length > 0) {
      customerList.forEach(customer => {
        if (!uniqueCustomerIds.includes(customer.id)) {
          allowedIds.push(customer.id);
        }
      });
    }

    const filterList = resultList.filter(hit => allowedIds.includes(hit.id));

    return filterList;
  };

  const redirectToNewJobPage = hit => {
    if (hit.parentEntityType === 'CustomerProperty') {
      history.push('/job/new', {
        customerName: hit.customerSortKey?.split('_Customer_')?.[1],
        customerSortKey: hit.customerSortKey,
        propertyId: hit.parentId
      });
    } else {
      history.push('/job/new', {
        customerName: hit.parentId || hit.id,
        customerSortKey: hit.parentSortKey || hit.sortKey
      });
    }
    handleClose();
  };

  return (
    <Modal handleClose={() => handleClose()} height="700" open={open} width="1100">
      <div style={{ marginTop: 20, marginBottom: 240 }}>
        <Typography style={{ marginBottom: 10 }}>
          Please select a customer to create a job:
        </Typography>
        <AlgoliaSearch
          delimiter=" "
          displayText={['customerName', 'address']}
          filters={[
            {
              attribute: 'entityType',
              valueArray: ['CompanyAddress', 'Customer']
            },
            {
              parenthetical: true,
              operand: 'OR',
              attributeArray: ['status', 'parentStatus'],
              valueArray: [AppConstants.ACTIVE, AppConstants.ACTIVE]
            }
          ]}
          handleChange={customer => customer && redirectToNewJobPage(customer)}
          handleFilter={handleSearchResultsFilter}
          label="Search by customer name or address"
          locatedInPopUp
          name="selectCustomer"
          searchIndex={defaultSearchIndex}
          value=""
        />
      </div>
    </Modal>
  );
};
export default CreateJobModal;
