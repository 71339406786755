import React, { useState } from 'react';

import { Divider, Field, Select, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { Grid } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';

const useStyles = makeStyles(() => ({
  titleWPadding: {
    padding: '0 0 16px 8px'
  },
  selectBox: {
    padding: '0 8px 0 8px'
  }
}));

function AssetSelect(props) {
  const { options } = props;
  const {
    valueSet,
    updateService,
    updateServiceFields: [updateServiceFields],
    label,
    placeholder,
    taskData,
    striped
  } = options;
  const currentTask = taskData[0];
  const classes = useStyles();
  const [asset, setAsset] = useState(currentTask?.propertyAsset);
  const onSelect = async selectedAsset => {
    if (selectedAsset?.id !== asset?.id) {
      const updatedAsset = selectedAsset || { id: null };
      setAsset(updatedAsset);
      await updateService(updateServiceFields.ASSET)(updatedAsset);
    }
  };

  const selectOptions = valueSet
    ? valueSet.map(elem => ({
        ...elem,
        label: elem.assetName,
        value: elem.id,
        assetType: elem.assetType?.tagName
      }))
    : [];
  const defaultVal = selectOptions.find(opt => opt.id === asset?.id);

  return (
    <>
      <ErrorBoundaries>
        <Divider margin={16} />
        <Typography
          className={classes.titleWPadding}
          component="div"
          id="assetTitle"
          variant={TV.BASE}
          weight={TW.BOLD}
        >
          {label}
        </Typography>
        <ThemeProvider>
          <Grid className={classes.selectBox} container spacing={2}>
            <Grid item xs={6}>
              <Select
                clearable
                label="Asset Name"
                options={selectOptions}
                placeholder={placeholder}
                searchable
                striped={striped}
                value={defaultVal}
                onChange={onSelect}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                label="Asset Type"
                striped={striped}
                type="text"
                value={defaultVal?.assetType || '-'}
              />
            </Grid>
          </Grid>
        </ThemeProvider>
      </ErrorBoundaries>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedAssetSelect = connect(
  mapStateToProps,
  mapDispatcherToProps
)(withTheme(AssetSelect));

export default reduxConnectedAssetSelect;
