import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import { snackbarOn } from '../../../../redux/actions/globalActions';

const ErrorMessageComponent = ({ snackbar, message }) => {
  useEffect(() => {
    if (message && message.length) {
      snackbar('error', message);
    }
  }, [snackbar, message]);
  return <></>;
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  snackbar: (mode, message, error) => dispatch(snackbarOn(mode, message, error))
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessageComponent);
