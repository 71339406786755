import React from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ResponsiveTable } from 'components';
import useBillingHourTypes from 'customHooks/useBillingHourTypes';
import useLabourRates from 'customHooks/useLabourRates';
import useLabourTypeDependency from 'customHooks/useLabourTypeDependency';
import useLabourTypes from 'customHooks/useLabourTypes';
import { snackbarOn } from 'redux/actions/globalActions';

import { addHourTypeToMeta, constructLabourData } from './LabourRateHelpers';
import LabourRateInput from './LabourRateInput';

const labourRatesMeta = [
  {
    id: 'name',
    type: 'text',
    label: 'Labor Type'
  }
];

const LabourRate = ({ user, ...props }) => {
  const { tenantId, tenantCompanyId } = user;
  const serviceArgs = [tenantId, tenantCompanyId, props.snackbarOn];
  const { metaData, data, isReadOnly, isLoading = false } = props;
  const [costCodes, costTypes, revenueTypes, , labourTypeDependecyLoaded] = useLabourTypeDependency(
    ...serviceArgs
  );

  const [billingHourTypes, billingHourTypesLoading] = useBillingHourTypes();
  const [labourTypes, labourTypesLoading] = useLabourTypes();
  const [labourRates, labourRateLoading] = useLabourRates();

  const tableMeta = addHourTypeToMeta([...labourRatesMeta], billingHourTypes);
  const tableData = constructLabourData({
    costCodes,
    labourTypes,
    labourRates,
    billingHourTypes,
    jobCostTypes: [...revenueTypes, ...costTypes]
  });
  const isLoaded =
    !labourRateLoading &&
    !billingHourTypesLoading &&
    labourTypeDependecyLoaded &&
    !labourTypesLoading &&
    !isLoading;
  const laborData = !isEmpty(data) ? data : tableData;
  const laborMetaData = !isEmpty(metaData) ? metaData : tableMeta;

  return (
    <ResponsiveTable
      customCellComponents={{
        RateText: ({ ...recordProps }) => (
          <LabourRateInput
            {...recordProps}
            handleInputChanges={props.handleLabourRateChanges}
            isReadOnly={isReadOnly}
          />
        )
      }}
      data={laborData}
      disableFilter={props.disableFilter}
      isLoading={!isLoaded}
      key={`${data?.length}-${laborMetaData?.length}`}
      rowMetadata={laborMetaData}
    />
  );
};

const mapStateToProps = ({ user }) => ({ user });
const mapDispatcherToProps = { snackbarOn };

LabourRate.propTypes = {
  user: PropTypes.object.isRequired,
  handleLabourRateChanges: PropTypes.func,
  addNewRates: PropTypes.bool,
  disableFilter: PropTypes.bool,
  showDefaults: PropTypes.bool
};

LabourRate.defaultProps = {
  addNewRates: false,
  disableFilter: false,
  showDefaults: true,
  handleLabourRateChanges: () => {}
};

const connectedLabourRate = connect(mapStateToProps, mapDispatcherToProps)(LabourRate);

export default connectedLabourRate;
