import React from 'react';

import { TW, Typography } from '@BuildHero/sergeant';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { getTenantSettingValueForKey } from 'utils';
import FeatureFlagsConstants from 'utils/FeatureFlagConstants';
import withLazyMount from 'utils/withLazyMount';

import { ReportTableSectionDefaultProps, ReportTableSectionPropTypes } from '../../propTypes';
import { selectAssets } from '../../selectors';

import ReportSection from '../ReportSection';

import {
  useAssets,
  useCompletedTasks,
  usePendingTasks,
  useRecommendedTasks,
  useRemainingTasks
} from './TasksSection.hooks';
import { useStyles } from './TasksSection.styles';

import TasksTable, { TaskTableLayout } from './TasksTable';

const TasksSection = ({ visit, job, loading, error }) => {
  const ldFlags = useFlags();
  const styles = useStyles();
  const assets = selectAssets(visit) || [];
  const completedTasks = useAssets(useCompletedTasks({ job, visit }), assets);
  const remainingTasks = useAssets(useRemainingTasks({ job }), assets);
  const recommendedTasks = useAssets(useRecommendedTasks({ job, visit }), assets);
  const pendingTasks = useAssets(usePendingTasks({ job, visit }), assets);
  const isPendingTasksEnabled = getTenantSettingValueForKey('mobilePendingTasks') === 'true';
  const isPendingTasksTableEnabled = ldFlags[FeatureFlagsConstants.PENDING_TASKS_TABLE];

  return (
    <>
      <ReportSection title="Tasks">
        <div css={styles.iconHeader}>
          <CheckIcon css={styles.greenCheckIcon} />
          <Typography weight={TW.BOLD}>Tasks completed during this visit</Typography>
        </div>
        <TasksTable
          error={error}
          loading={loading}
          noDataMessage="No Tasks Completed"
          tasks={completedTasks}
        />
        <div css={[styles.iconHeader, styles.sectionSpacing]}>
          <WarningIcon css={styles.redWarningIcon} />
          <Typography weight={TW.BOLD}>Tasks remaining on this job</Typography>
        </div>
        <TasksTable
          error={error}
          loading={loading}
          noDataMessage="No Tasks Remaining"
          tasks={remainingTasks}
        />
      </ReportSection>
      <ReportSection title="Tasks Created On This Visit">
        <div css={styles.iconHeader}>
          <Typography weight={TW.BOLD}>Recommended Tasks</Typography>
        </div>
        <TasksTable
          error={error}
          loading={loading}
          noDataMessage="No Recommended Tasks"
          tasks={recommendedTasks}
          taskTableLayout={TaskTableLayout.VISIT_TASK}
        />
        {isPendingTasksEnabled && isPendingTasksTableEnabled && (
          <>
            <div css={[styles.iconHeader, styles.sectionSpacing]}>
              <Typography weight={TW.BOLD}>Pending Tasks</Typography>
            </div>
            <TasksTable
              error={error}
              loading={loading}
              noDataMessage="No Pending Tasks"
              tasks={pendingTasks}
              taskTableLayout={TaskTableLayout.VISIT_TASK}
            />
          </>
        )}
      </ReportSection>
    </>
  );
};

TasksSection.propTypes = ReportTableSectionPropTypes;
TasksSection.defaultProps = ReportTableSectionDefaultProps;

export default withLazyMount(TasksSection);
