import React from 'react';

import { SgtFormComponents } from '@BuildHero/sergeant';

import { TransactionType } from 'utils/constants';

export default props => {
  if (props.form.values.transactionType === TransactionType.OVERPAYMENT) {
    return <SgtFormComponents.CurrencyInput {...props} />;
  }
  return (
    <SgtFormComponents.Field
      {...props}
      props={{ ...props.props, style: { ...props.props.style, marginBottom: 14 } }}
    />
  );
};
