import React from 'react';

import { connect } from 'react-redux';

import { PageHeader, UserPermission } from 'components';

import VisitsTable from 'components/VisitsTable';
import { setOpenQuickAddModal, snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { PermissionConstants } from 'utils/AppConstants';

const JobsList = ({ user }) => {
  return (
    <ErrorBoundaries>
      <UserPermission action={PermissionConstants.OBJECT_JOB} I="read">
        <PageHeader pageMapKey="visits" userLocale={user.locale} />
        <VisitsTable />
      </UserPermission>
    </ErrorBoundaries>
  );
};

const mapStateToProps = state => ({
  user: state.user
});
const mapDispatchToProps = { snackbar: snackbarOn, addModal: setOpenQuickAddModal };

const connectedJobs = connect(mapStateToProps, mapDispatchToProps)(JobsList);

export default connectedJobs;
