import React from 'react';

import {
  InlineAlert,
  InlineAlertTypes,
  MoreButton,
  Switch,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';

import { Box, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { noop } from 'lodash';

import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import { Mode } from 'utils/constants';

import { getCheckListFormValues, updateDefaultCheckListConfirmMessage } from '../helper';

export const ChecklistHeader = ({
  cList,
  isChecklistDefinition,
  numberOfTasks = 0,
  updateChecklist = noop,
  updatingChecklist = false,
  setChecklistModal,
  disabled
}) => {
  const theme = useTheme();
  const confirmContext = useConfirmModal();

  return (
    <>
      <Box
        css={{
          ...(!disabled ? { borderBottom: `1px solid ${theme.palette.grayscale(20)}` } : {}),
          marginBottom: 8
        }}
        display="flex"
        flexDirection="row"
        padding={1}
      >
        <Box alignItems="center" display="flex" flexGrow={1}>
          <Typography css={{ paddingRight: 8 }} variant={TV.BASE} weight={TW.BOLD}>
            {cList.name}
          </Typography>
          {numberOfTasks > 0 && (
            <Typography
              color={theme.palette.grayscale(50)}
              css={{ paddingRight: 16 }}
              variant={TV.BASE}
              weight={TW.REGULAR}
            >
              ({numberOfTasks} tasks)
            </Typography>
          )}
          {isChecklistDefinition && (
            <Switch
              css={{ marginLeft: 24, maxWidth: 170 }}
              disabled={disabled || updatingChecklist}
              formControlProps={{
                fullWidth: false
              }}
              horizontalLabel
              key={`isDefault-${cList.id}`}
              label="Set as Default"
              tooltip="Default checklists are automatically added to service agreements containing this asset type"
              value={cList.isDefault ?? false}
              onChange={async e => {
                if (await confirmContext.confirm(updateDefaultCheckListConfirmMessage)) {
                  await updateChecklist({ ...cList, isDefault: !e.target.checked });
                }
              }}
            />
          )}
        </Box>

        <Box display="flex" justifyContent="center" minWidth="44%">
          <Typography variant={TV.BASE} weight={TW.BOLD}>
            Monthly Occurrences
          </Typography>
        </Box>
        {isChecklistDefinition && (
          <Box justifyContent="flex-end">
            <MoreButton
              css={{ padding: 4 }}
              disabled={disabled}
              key={`MoreBtnBox-${cList.id}`}
              options={[
                {
                  label: 'Edit Checklist',
                  icon: p => <EditIcon fontSize="small" {...p} />,
                  onClick: () =>
                    setChecklistModal({
                      open: true,
                      data: getCheckListFormValues(cList),
                      mode: Mode.EDIT
                    })
                },
                {
                  label: 'Delete Checklist',
                  icon: p => <DeleteIcon fontSize="small" {...p} />,
                  onClick: () =>
                    setChecklistModal({
                      open: true,
                      data: getCheckListFormValues(cList),
                      mode: Mode.DELETE
                    })
                }
              ]}
            />
          </Box>
        )}
      </Box>
      {disabled && (
        <Box
          css={{
            borderBottom: `1px solid ${theme.palette.grayscale(20)}`,
            marginBottom: 8
          }}
          display="flex"
          flexDirection="row"
          padding={1}
        >
          <InlineAlert
            message="Checklists cannot be edited once they are used on an active agreement"
            type={InlineAlertTypes.BLUE}
          />
        </Box>
      )}
    </>
  );
};
