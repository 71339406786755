import React from 'react';

import { FieldType } from '@BuildHero/sergeant';
import { InputAdornment } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { InventoryPartSourceType } from 'utils/constants';
import { sgtItemCalc } from 'utils/onCalcChange';

import { InventoryPartSelectionSource } from '../../../scenes/JobCloseout/constants';

export default ({
  priceBookId,
  truckOptions,
  costCodeOptions,
  costTypeOptions,
  revenueTypeOptions,
  sourceOptions,
  departmentOptions,
  jobReportDepartment,
  setJobReportDepartment,
  visitDepartments,
  isMarginEnabled
}) => {
  const rowStyle = {
    gap: 16,
    flexDirection: 'row',
    marginBottom: 16
  };
  const colStyle = {
    width: 200
  };

  const { truckStockInventory } = useFlags();

  return {
    validation: {
      type: 'object',
      properties: {
        name: {
          type: 'string',
          required: true
        },
        ...(departmentOptions
          ? {
              department: {
                type: 'string',
                required: true,
                nullable: true
              }
            }
          : {}),
        quantity: {
          type: 'number',
          moreThan: 0,
          required: true,
          nullable: true
        },
        unitPrice: {
          type: 'number',
          required: true,
          nullable: true
        },
        unitCost: {
          type: 'number',
          nullable: true
        },
        amount: {
          type: 'number',
          required: true,
          nullable: true
        }
      }
    },
    validationErrors: {
      name: {
        required: 'Product is required.'
      },
      department: { required: 'Department is required.' },
      quantity: {
        required: 'Quantity is required.',
        moreThan: '0'
      },
      unitPrice: {
        required: 'Unit price is required.'
      },
      amount: {
        required: 'Amount is required.',
        type: 'Amount must be a valid number'
      }
    },
    layouts: {
      default: {
        contents: [
          // row 1
          {
            props: { style: rowStyle },
            contents: [
              {
                component: 'ProductWithPricingSearch',
                source: 'name',
                props: {
                  style: { width: 416 },
                  label: 'Product',
                  required: true,
                  priceBookId,
                  costCodeOptions,
                  costTypeOptions,
                  revenueTypeOptions
                }
              },
              truckStockInventory && {
                component: 'Select',
                source: 'truck',
                props: {
                  style: colStyle,
                  label: 'Truck',
                  options: truckOptions,
                  searchable: true
                },
                resolveProps: ({ selectedSource, source }) => {
                  return {
                    disabled:
                      selectedSource?.value === InventoryPartSelectionSource.JOB_REPORT ||
                      source === InventoryPartSourceType.JOB_REPORT
                  };
                }
              },
              sourceOptions && {
                component: 'Select',
                source: 'selectedSource',
                resolveProps: () => {
                  return {
                    disabled: sourceOptions.length <= 1
                  };
                },
                props: {
                  style: colStyle,
                  label: 'Source',
                  options: sourceOptions,
                  required: true,
                  onChange: (key, selectedSource, form) => {
                    if (
                      key === 'selectedSource' &&
                      selectedSource.value !== InventoryPartSelectionSource.JOB_REPORT
                    ) {
                      const visit = visitDepartments?.find(({ id }) => id === selectedSource.value);
                      const departmentOption = departmentOptions.find(
                        option => visit?.departmentId === option.value
                      );

                      if (departmentOption) form.setFieldValue('department', departmentOption);
                    } else if (
                      key === 'selectedSource' &&
                      selectedSource.value === InventoryPartSelectionSource.JOB_REPORT
                    ) {
                      form.setFieldValue('department', jobReportDepartment);
                      form.setFieldValue('truck', null);
                    }
                  }
                }
              },
              departmentOptions && {
                component: 'Select',
                source: 'department',
                resolveProps: ({ selectedSource }) => {
                  return {
                    disabled: selectedSource?.value !== InventoryPartSelectionSource.JOB_REPORT
                  };
                },
                props: {
                  style: colStyle,
                  label: 'Department',
                  required: true,
                  portal: true,
                  options: departmentOptions,
                  searchable: true,
                  onChange: (key, department, form) => {
                    if (
                      key === 'department' &&
                      form.values.selectedSource.label === InventoryPartSelectionSource.JOB_REPORT
                    ) {
                      setJobReportDepartment(department);
                    }
                  }
                }
              }
            ].filter(Boolean)
          },
          // row 2
          {
            props: { style: rowStyle },
            contents: [
              {
                component: 'Input',
                source: 'description',
                props: { label: 'Description', multiline: true, rows: 4 }
              }
            ]
          },
          // row 3
          {
            props: { style: rowStyle },
            contents: [
              {
                component: 'NumberInput',
                source: 'quantity',
                resolveProps: ({ priceBookEntry, product }) => ({
                  endAdornment: (
                    <InputAdornment position="end">
                      {priceBookEntry?.product?.unitOfMeasure?.name ||
                        product?.unitOfMeasure?.name ||
                        'units'}
                    </InputAdornment>
                  )
                }),
                props: {
                  style: colStyle,
                  label: 'Quantity',
                  required: true,
                  onChange: sgtItemCalc
                }
              },
              {
                component: 'CurrencyInput',
                source: 'unitCost',
                props: {
                  style: colStyle,
                  label: 'Unit Cost',
                  slowField: true,
                  onChange: sgtItemCalc
                }
              },
              isMarginEnabled
                ? {
                    component: 'PercentageInput',
                    source: 'marginValue',
                    props: {
                      style: colStyle,
                      label: 'Margin',
                      slowField: true,
                      onChange: sgtItemCalc
                    }
                  }
                : {
                    component: 'PercentageInput',
                    source: 'markupValue',
                    props: {
                      style: colStyle,
                      label: 'Markup',
                      slowField: true,
                      onChange: sgtItemCalc
                    }
                  },
              {
                component: 'CurrencyInput',
                source: 'unitPrice',
                props: {
                  style: colStyle,
                  label: 'Unit Price',
                  required: true,
                  slowField: true,
                  onChange: sgtItemCalc,
                  localeStringOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }
                }
              }
            ]
          },
          // row 4
          {
            props: { style: rowStyle },
            contents: [
              {
                component: 'Select',
                source: 'costCode',
                props: {
                  style: colStyle,
                  label: 'Cost Code',
                  options: costCodeOptions,
                  portal: true,
                  menuHeight: 150,
                  searchable: true,
                  clearable: true
                }
              },
              {
                component: 'Select',
                source: 'jobCostType',
                props: {
                  style: colStyle,
                  label: 'Cost Type',
                  options: costTypeOptions,
                  portal: true,
                  menuHeight: 150,
                  searchable: true,
                  clearable: true
                }
              },
              {
                component: 'Select',
                source: 'revenueType',
                props: {
                  style: colStyle,
                  label: 'Revenue Type',
                  options: revenueTypeOptions,
                  portal: true,
                  menuHeight: 150,
                  searchable: true,
                  clearable: true
                }
              }
            ]
          },
          // row 5
          {
            props: { style: { flexDirection: 'row', gap: 16 } },
            contents: [
              {
                component: 'Field',
                source: 'amount',
                props: {
                  style: colStyle,
                  label: 'Subtotal',
                  slowField: true,
                  type: FieldType.CURRENCY
                }
              },
              {
                component: 'Checkbox',
                source: 'taxable',
                props: {
                  style: colStyle,
                  label: 'Taxable'
                }
              }
            ]
          }
        ]
      }
    }
  };
};
