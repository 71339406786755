import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const DELETE_FORM_DATA_BY_ID = gql`
  mutation deleteFormDataById($partitionKey: String!, $id: String!) {
    deleteFormDataById(partitionKey: $partitionKey, id: $id) {
      id
      formId
    }
  }
`;

const serializer = ({ tenantId, id }) => ({
  variables: {
    partitionKey: tenantId,
    id
  }
});

export const useDeleteFormById = () => {
  return useExtendedMutation(DELETE_FORM_DATA_BY_ID, {
    serializer
  });
};
