import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const UPDATE_ADVANCED_SERVICE_AGREEMENT = gql`
  mutation updateAdvancedServiceAgreement(
    $serviceAgreementId: String!
    $input: AdvancedServiceAgreementUpdateInput!
  ) {
    updateAdvancedServiceAgreement(serviceAgreementId: $serviceAgreementId, input: $input) {
      id
      advancedSchedulingState
    }
  }
`;

export const useUpdateAdvancedServiceAgreement = ({ serviceAgreementId, onSuccess = () => {} }) => {
  return useExtendedMutation(UPDATE_ADVANCED_SERVICE_AGREEMENT, {
    serializer: ({ serviceAgreementState }) => ({
      variables: {
        serviceAgreementId,
        input: {
          serviceAgreementState
        }
      }
    }),
    skip: !serviceAgreementId,
    onSuccess: (transformedData, snackbarOn) => {
      snackbarOn('success', 'Service Agreement was updated');
      onSuccess(transformedData);
    },
    onError: (transformedData, snackbarOn) =>
      snackbarOn('error', 'Unable to Update Service Agreement')
  });
};
