import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Button, ButtonType, ThemeProvider } from '@BuildHero/sergeant';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import { FullScreenModal } from 'components';
import StepsStatus from 'components/FullScreenModal/StepsStatus';
import Labels from 'meta/labels';

import { snackbarOn } from 'redux/actions/globalActions';
import { omitTypename } from 'scenes/ServiceAgreements/utils';

import AddTasksStep from './AddTasksStep';
import GeneralInfoStep from './GeneralInfoStep';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold'
  },
  formContainer: {
    flex: 1
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerButton: {
    marginRight: theme.spacing(2)
  },
  hiddenButton: {
    marginRight: theme.spacing(2),
    visibility: 'hidden'
  }
}));

const NavigationModes = {
  NEXT: 'next',
  PREVIOUS: 'prev'
};

const MaintenanceTemplateV2 = props => {
  const classes = useStyles();
  const {
    user,
    data = {},
    isOpen,
    serviceAgreementsSettings,
    selectedProperties,
    snackbar,
    onSaveTemplate,
    agreementInfo,
    companyTimeZone
  } = props;
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigationModeRef = useRef(NavigationModes.NEXT);
  const prevDestinationIndex = useRef(0);

  const setNavigationMode = dir => {
    navigationModeRef.current = dir;
  };

  const [stepData, setData] = useState(data);
  const formServiceRefArr = useRef([]);

  const handleFormService = (service, stepIndex) => {
    formServiceRefArr.current[stepIndex] = service;
  };

  useEffect(() => {
    setData(data);
  }, [data]);

  const continueStep = async (dir = NavigationModes.NEXT) => {
    setNavigationMode(dir);
    if (!formServiceRefArr.current.length) return;
    const stepFormService = formServiceRefArr.current[currentStepIndex];
    return stepFormService.submit();
  };

  const { jobTags } = useSelector(state => state.company);

  const handleOnComplete = useCallback(
    async dataToSave => {
      setIsSubmitting(true);
      const { tags = [] } = dataToSave;
      const modifiedTags = (jobTags?.items || []).reduce((acc, tag) => {
        if (tags.includes(tag.id)) {
          acc.push({ id: tag.id, tagName: tag.tagName, tagType: tag.tagType });
        }
        return acc;
      }, []);
      await onSaveTemplate({
        ...dataToSave,
        tags: modifiedTags,
        maintenanceTaskTemplates:
          dataToSave.maintenanceTaskTemplates?.map(t => ({
            id: t.id,
            assetId: t.assetId,
            assetTypeId: t.assetTypeId,
            maintenanceTaskName: t.maintenanceTaskName,
            maintenanceTaskDescription: t.maintenanceTaskDescription,
            sortOrder: t.sortOrder,
            maintenanceTaskRequiredParts:
              t.maintenanceTaskRequiredParts.items?.map(p => ({
                quantity: p.quantity,
                productId: p.id
              })) || [],
            maintenanceTaskForms:
              t.maintenanceTaskForms?.items?.map(f => ({
                formId: f.formId,
                isRequired: f.isRequired
              })) || []
          })) || []
      });
      setIsSubmitting(false);
    },
    [jobTags, onSaveTemplate]
  );

  const STEP_ORDER = {
    GENERAL_INFO: 0,
    ADD_TASKS: 1
  };

  const handleOnCompleteForm = useCallback(
    async (formValues, isLastStep = false) => {
      const changedPropertyId =
        formValues.propertyId && stepData.propertyId !== formValues.propertyId;

      const updatedData = omitTypename({ ...stepData, ...formValues });
      const dataToSave = {
        ...updatedData,
        maintenanceTaskTemplates: changedPropertyId
          ? updatedData.maintenanceTaskTemplates?.filter(t => !t.assetId)
          : updatedData.maintenanceTaskTemplates
      };
      setData(dataToSave);

      if (navigationModeRef.current === NavigationModes.NEXT && isLastStep) {
        await handleOnComplete(dataToSave);
      } else if (navigationModeRef.current === NavigationModes.PREVIOUS) {
        setCurrentStepIndex(prevDestinationIndex.current);
      } else {
        setCurrentStepIndex(currentStepIndex + 1);
      }
    },
    [handleOnComplete, stepData, currentStepIndex]
  );

  const steps = useMemo(
    () => [
      {
        label: 'General Info',
        render: () => (
          <GeneralInfoStep
            agreementInfo={agreementInfo}
            companyTimeZone={companyTimeZone}
            data={stepData}
            formServiceRefArr={formServiceRefArr}
            handleFormService={handleFormService}
            handleOnCompleteForm={handleOnCompleteForm}
            selectedProperties={selectedProperties}
            serviceAgreementsSettings={serviceAgreementsSettings}
            stepIndex={STEP_ORDER.GENERAL_INFO}
          />
        )
      },
      {
        label: 'Tasks',
        render: () => (
          <AddTasksStep
            agreementInfo={agreementInfo}
            data={stepData}
            formServiceRefArr={formServiceRefArr}
            handleFormService={handleFormService}
            handleOnCompleteForm={handleOnCompleteForm}
            isLastStep
            stepIndex={STEP_ORDER.ADD_TASKS}
          />
        )
      }
    ],
    [stepData, agreementInfo, serviceAgreementsSettings, selectedProperties, handleOnCompleteForm]
  );

  const isLastStep = currentStepIndex === steps.length - 1;

  const handleExited = () => {
    setCurrentStepIndex(0);
    setData({});
  };

  return (
    <FullScreenModal
      handleClose={() => props.handleClose()}
      headerCenterComponent={
        <StepsStatus
          currentStepIndex={currentStepIndex}
          maxStepperWidth={320}
          steps={steps.map(({ label }) => label)}
          onClickStep={index => {
            if (index < currentStepIndex) {
              continueStep(NavigationModes.PREVIOUS);
              prevDestinationIndex.current = index;
            }
          }}
        />
      }
      modalHeaderButtons={
        <ThemeProvider>
          <Button
            className={isLastStep ? classes.hiddenButton : classes.headerButton}
            type={ButtonType.SECONDARY}
            onClick={() => {
              continueStep(NavigationModes.NEXT);
            }}
          >
            Next Step
          </Button>
          <Button
            disabled={!isLastStep}
            loading={isSubmitting}
            type={ButtonType.PRIMARY}
            onClick={() => {
              const stepFormService = formServiceRefArr.current[currentStepIndex];
              return stepFormService?.submit();
            }}
          >
            {`${data?.id ? 'Update' : 'Create'} Template`}
          </Button>
        </ThemeProvider>
      }
      open={isOpen}
      title={
        props?.data?.id
          ? Labels.editMaintenanceTemplate[user.locale]
          : Labels.newMaintenanceTemplate[user.locale]
      }
      onExited={handleExited}
    >
      <div className={classes.container}>
        <div className={classes.formContainer}>{steps[currentStepIndex].render()}</div>
      </div>
    </FullScreenModal>
  );
};

MaintenanceTemplateV2.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  serviceAgreementsSettings: PropTypes.object.isRequired,
  selectedProperties: PropTypes.arrayOf(PropTypes.object).isRequired,
  snackbar: PropTypes.func.isRequired,
  onSaveTemplate: PropTypes.func.isRequired,
  agreementInfo: PropTypes.object.isRequired,
  user: PropTypes.shape({
    // e.g. "en" - options are "en" or "es" - used to decide on language
    locale: PropTypes.string.isRequired
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  companyTimeZone: PropTypes.string.isRequired
};

MaintenanceTemplateV2.defaultProps = {
  data: {}
};

export default connect(state => ({ user: state.user }), { snackbar: snackbarOn })(
  MaintenanceTemplateV2
);
