import React, { useState } from 'react';

import { Button, ButtonType, Modal, MUIForm, ThemeProvider } from '@BuildHero/sergeant';
import { PropTypes } from 'prop-types';

import { statusToNoteLabel } from 'scenes/Procurement/constants';
import { Mode } from 'utils/constants';

import { ApprovalRejectionLayout } from './approval-rejection-layout';

const statusToSaveOrAddLabel = {
  Approved: 'Approve',
  Rejected: 'Reject'
};

export const ApprovalRejectionModal = ({
  isOpen,
  mode,
  data,
  label,
  handleOnComplete,
  handleClose,
  status
}) => {
  const [formService, setFormService] = useState(null);

  const getButtonLabel = () => {
    if (mode === Mode.ADD) {
      return `${statusToSaveOrAddLabel[status]} ${label}`;
    }
    return 'Save Changes';
  };

  const getModalTitle = () => {
    if (mode === Mode.EDIT) {
      return `Edit ${statusToNoteLabel[status]}`;
    }
    return `${statusToSaveOrAddLabel[status]} ${label}`;
  };

  return (
    <ThemeProvider>
      <Modal
        actions={
          <Button fullWidth type={ButtonType.PRIMARY} onClick={() => formService?.submit()}>
            {getButtonLabel()}
          </Button>
        }
        open={isOpen}
        title={getModalTitle()}
        onClose={handleClose}
      >
        <MUIForm
          configuration={ApprovalRejectionLayout(status)}
          data={mode === Mode.EDIT ? data : {}}
          layout={mode}
          open={isOpen}
          onComplete={handleOnComplete}
          onCreateService={service => setFormService(service)}
        />
      </Modal>
    </ThemeProvider>
  );
};

ApprovalRejectionModal.propTypes = {
  isOpen: PropTypes.bool,
  mode: PropTypes.string,
  data: PropTypes.object,
  label: PropTypes.string,
  handleOnComplete: PropTypes.func,
  handleClose: PropTypes.func,
  status: PropTypes.string
};

ApprovalRejectionModal.defaultProps = {
  isOpen: false,
  mode: 'Add',
  data: {},
  label: '',
  handleOnComplete: () => {},
  handleClose: () => {},
  status: ''
};
