import React from 'react';

import { MoreButton } from '@BuildHero/sergeant';
import { isFunction } from 'lodash';
import { arrayOf, object } from 'prop-types';

import { useStyles } from './RowActionsMenu.styles';

export const RowActionsMenu = ({ row, actions }) => {
  const style = useStyles();
  const options = actions.map(action => ({
    icon: action.icon,
    label: action.label,
    value: row,
    onClick: action.onClick,
    disabled: isFunction(action.disabled) ? action.disabled(row) : action.disabled
  }));

  return (
    <div style={style.buttonWrapper}>
      <MoreButton options={options} />
    </div>
  );
};

RowActionsMenu.propTypes = {
  row: object.isRequired,
  actions: arrayOf(object)
};

RowActionsMenu.defaultProps = {
  actions: []
};
