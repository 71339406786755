export const Company = [
  { label: 'Company Name', value: 'company:companyName' },
  { label: 'Company Email', value: 'company:email' },
  { label: 'Company Primary Phone', value: 'company:phonePrimary' },
  { label: 'Company Website', value: 'company:websiteUrl' }
];

export const Job = [
  { label: 'Job Amount Not To Exceed', value: 'parent:amountNotToExceed' },
  { label: 'Job Amount Quoted', value: 'parent:amountQuoted' },
  { label: 'Job Best Contact', value: 'parent:bestContact' },
  { label: 'Job Billing Address', value: 'parent:billingAddress' },
  { label: 'Job Created By', value: 'parent:createdBy' },
  { label: 'Job Customer Name', value: 'parent:customerName' },
  { label: 'Job Customer Property Name', value: 'parent:customerPropertyName' },
  { label: 'Job Number', value: 'parent:jobNumber' }
];

export const Customer = [
  { label: 'Customer Name', value: 'parent:customerName' },
  { label: 'Customer Account Number', value: 'parent:accountNumber' },
  { label: 'Customer Type Value', value: 'parent:customerTypeValue' },
  { label: 'Customer Not To Exceed', value: 'parent:amountNotToExceed' },
  { label: 'Customer Email', value: 'parent:email' },
  { label: 'Customer Is Active', value: 'parent:isActive' },
  { label: 'Customer Logo URL', value: 'parent:logoUrl' },
  { label: 'Customer Primary Phone', value: 'parent:phonePrimary' },
  { label: 'Customer Alternate Phone', value: 'parent:phoneAlternate' },
  { label: 'Customer Status', value: 'parent:status' },
  { label: 'Customer Website URL', value: 'parent:websiteUrl' },
  { label: 'Customer Added By', value: 'parent:createdBy' },
  { label: 'Customer Added Date', value: 'parent:createdDate' },
  { label: 'Customer Last Updated By', value: 'parent:lastUpdatedBy' },
  { label: 'Customer Last Updated Date', value: 'parent:lastUpdatedDate' }
];

export const Quote = [
  { label: 'Quote Number', value: 'parent:quoteNumber' },
  { label: 'Quote Customer Name', value: 'parent:customerName' },
  { label: 'Quote Property Name', value: 'parent:propertyName' },
  { label: 'Quote Billing Address', value: 'parent:billingAddress' },
  { label: 'Quote Expiration Date', value: 'parent:expirationDate' }
];

export const TimeCard = [
  { label: 'TimeCard Date', value: 'parent:timeCardDate' },
  { label: 'TimeCard Start', value: 'parent:shiftStartTime' },
  { label: 'TimeCard End', value: 'parent:shiftEndTime' },
  { label: 'TimeCard Added By', value: 'parent:createdBy' },
  { label: 'TimeCard Added Date', value: 'parent:createdDate' },
  { label: 'TimeCard Last Updated By', value: 'parent:lastUpdatedBy' },
  { label: 'TimeCard Last Updated Date', value: 'parent:lastUpdatedDate' }
];

export const Task = [
  { label: 'Task Name', value: 'parent:taskName' },
  { label: 'Property Name', value: 'parent:propertyName' },
  { label: 'Property Address', value: 'parent:combinedPropertyAddress' },
  { label: 'Asset Name', value: 'parent:assetName' },
  { label: 'Asset Type', value: 'parent:assetType' },
  { label: 'Asset Make', value: 'parent:make' },
  { label: 'Asset Model', value: 'parent:model' },
  { label: 'Asset Serial Number', value: 'parent:serialNumber' },
  { label: 'Asset Location', value: 'parent:assetLocation' },
  // intentionally keeping seperate - customIdentifier || jobNumber
  { label: 'Job Number Display', value: 'parent:displayJobNumber' },
  { label: 'Job Property Address', value: 'parent:propertyAddress' },
  { label: 'Customer Name', value: 'parent:customerName' }
];

export const Visit = [
  { label: 'Visit Primary Technician', value: 'visit:primaryTech' },
  { label: 'Visit Scheduled Date', value: 'visit:scheduledFor' },
  { label: 'Visit Travel Start Time', value: 'visit:travelStartTime' },
  { label: 'Visit Work Start Time', value: 'visit:workStartTime' },
  { label: 'Visit Work End Time', value: 'visit:workEndTime' },
  { label: 'Visit Summary', value: 'visit:visitSummary' },
  { label: 'Customer Name', value: 'job:customerName' },
  { label: 'Property Name', value: 'job:customerPropertyName' },
  { label: 'Job Number', value: 'job:customIdentifier' }
];
