import React from 'react';

import { ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';
import { Box, Divider, useTheme } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { sortBy } from 'lodash';
import moment from 'moment-timezone';

import StorageService from 'services/StorageService';
import { AppConstants, AttachmentTypes } from 'utils/AppConstants';

import { getStyles } from '../styles';

export const CustomerSignatures = ({ job, visits, companyTimezone }) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const openPDFUrl = async fileKey => {
    if (!fileKey) return;
    const storageService = new StorageService();
    const url = await storageService.getFile(fileKey);
    window.open(url);
  };

  const signatureList = sortBy(
    visits
      .map(visit => {
        const att = visit?.attachments?.items?.find(
          attachment => attachment.type === AttachmentTypes.CUSTOMER_SIGNATURE
        );

        const signatureObj = visit?.customerSignatures?.items?.[0];
        return (
          signatureObj && {
            displayText: `${job.customIdentifier}-${visit.visitNumber}-customer-review.pdf`,
            pdfUrl: att?.fileUrl,
            auditText: `Signed by ${signatureObj?.nameOfSignee?.trim() || ''}, ${moment
              .tz(moment.unix(signatureObj.signedDateTime / 1000), companyTimezone)
              .format(AppConstants.DATETIME_FORMAT_WITH_TIMEZONE) || ''}`,
            visitNumber: visit.visitNumber
          }
        );
      })
      .filter(Boolean),
    'visitNumber'
  );

  if (!signatureList.length) return null;

  return (
    <ThemeProvider>
      <Divider css={styles.divider} />
      <Typography css={{ marginBottom: 8 }} variant={TV.L} weight={TW.BOLD}>
        Customer Signature
      </Typography>
      {signatureList.map(sign => (
        <Box alignItems="flex-end" display="flex" flexDirection="row">
          <PictureAsPdfIcon
            css={{
              width: 13,
              color: theme.palette.secondary.dark,
              marginBottom: 4,
              marginRight: 4
            }}
          />
          <Typography
            css={{
              marginBottom: 8,
              color: theme.palette.secondary.dark,
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
            variant={TV.BASE}
            weight={TW.REGULAR}
            onClick={() => openPDFUrl(sign.pdfUrl)}
          >
            {sign.displayText}
          </Typography>
          <Typography
            css={{ marginBottom: 8, color: theme.palette.grayscale(50) }}
            variant={TV.BASE}
            weight={TW.REGULAR}
          >
            - {sign.auditText}
          </Typography>
        </Box>
      ))}

      <Box css={{ marginBottom: 40 }} />
    </ThemeProvider>
  );
};
