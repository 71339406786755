import React from 'react';

import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import { sumBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  getCloudinaryImageUrl,
  getCombinedAddressFromProjectData
} from 'scenes/ProjectManagement/components/utils';
import { convertToCurrencyString, getCombinedAddress } from 'utils';
import { AppConstants } from 'utils/AppConstants';

import { changeOrderFormatValues } from '../ChangeOrderFormatModal/ChangeOrderFormatSelect.constants';

import { percents } from './ChangeOrderPDF.constants';
import {
  getCostSectionView,
  getFieldWithTitle,
  getItemTablesDetailView,
  getSubTotalsLines,
  getTotalsLineItem
} from './ChangeOrderPDF.helper';
import styles from './ChangeOrderPDF.styles';

const PDFDocument = props => {
  const { companyInfo, projectData, changeOrderData, sendTo, returnTo, pdfFormatOptionRef } = props;
  const { ALL_DETAILS, SECTION_SUBTOTALS, GRAND_TOTAL } = changeOrderFormatValues;

  const totalSummaryView = () => {
    const subTotals = changeOrderData?.typesWithFormattedLines?.map(typeData => ({
      sectionSubtotal: sumBy(typeData.items || [], 'subtotal'),
      label: typeData.label,
      taxAmount:
        R.pipe(
          R.filter(item => item.taxable),
          R.map(item => item.taxPercent * item.subtotal * 0.01),
          R.sum
        )(typeData.items) || 0,
      ...R.pick(percents, typeData.items?.[0])
    }));

    // The sum of subtotal for all cost types
    const subtotal = sumBy(subTotals, 'sectionSubtotal');
    // The line items (subtotal, tax, overhead, profit) for all cost types
    const subTotalsLines = getSubTotalsLines(subTotals);

    return [getTotalsLineItem({ label: 'Subtotal', value: subtotal }), subTotalsLines];
  };

  const typeTotals = [];
  const itemTables =
    changeOrderData?.typesWithFormattedLines?.map(typeData => {
      typeTotals.push(
        <View style={[styles.displayFlex, { textAlign: 'right', justifyContent: 'flex-end' }]}>
          <Text style={[styles.date, { width: '30%' }]}>{typeData.label}</Text>
          <Text style={[styles.sectionTitle, { width: '20%' }]}>
            {convertToCurrencyString(typeData.total)}
          </Text>
        </View>
      );

      return pdfFormatOptionRef.current === ALL_DETAILS
        ? // Case 1: Format option is "ALL_DETAILS"
          getItemTablesDetailView(typeData)
        : // Case 2: Format option is "SECTION_SUBTOTALS"
          getCostSectionView({ label: typeData.label, cost: typeData.total });
    }) || [];

  return (
    <Document title={`Change Order ${changeOrderData?.number}`}>
      <Page size="letter" style={styles.root}>
        <View>
          {/* Logo and address */}
          {companyInfo && (
            <View style={[styles.displayFlex, { alignItems: 'center' }]}>
              {companyInfo?.logoUrl ? (
                <Image src={getCloudinaryImageUrl(companyInfo?.logoUrl)} style={styles.logoImage} />
              ) : (
                <View />
              )}
              <View style={[styles.displayFlexColumn, { minWidth: 150, alignItems: 'flex-end' }]}>
                <Text style={[styles.text]}>
                  {`${companyInfo?.companyAddresses?.addressLine1}, ${companyInfo?.companyAddresses?.addressLine2}`}
                </Text>
                <Text style={[styles.text]}>
                  {`${companyInfo?.companyAddresses?.city}, ${companyInfo?.companyAddresses?.state}, ${companyInfo?.companyAddresses?.zipcode}`}
                </Text>
                <Text style={[styles.text]}>{companyInfo?.phonePrimary || null}</Text>
              </View>
            </View>
          )}
          {/* package number and date */}
          <View style={[styles.displayFlex, { marginTop: 20 }]}>
            <View style={styles.displayFlex}>
              <Text style={styles.numberTitle}>Change Order Request </Text>
              <Text style={styles.numberSubtitle}>
                {changeOrderData?.number
                  ? `${projectData.number} - ${changeOrderData?.number}`
                  : null}
              </Text>
            </View>
            <Text style={styles.date}>{moment().format(AppConstants.DATE_FORMAT)}</Text>
          </View>

          {/* project data */}
          <View style={[styles.displayFlex, { marginTop: 20, justifyContent: 'flex-start' }]}>
            {getFieldWithTitle('PROJECT NAME', projectData.name, {
              minWidth: '15%',
              marginRight: 8
            })}
            {getFieldWithTitle('PROJECT NUMBER', projectData.number, {
              minWidth: '15%',
              marginRight: 8
            })}
            {getFieldWithTitle(
              'PROJECT ADDRESS',
              getCombinedAddressFromProjectData(projectData) || '-',
              { minWidth: '35%', marginRight: 8 }
            )}
          </View>

          {/* To | From */}
          <View style={[styles.displayFlex, { marginTop: 20, alignItems: 'flex-start' }]}>
            <View style={[styles.displayFlexColumn, { width: '20%', marginRight: 8 }]}>
              <Text style={styles.sectionTitle}>To</Text>
              {getFieldWithTitle('NAME', sendTo?.name || '-')}
              {getFieldWithTitle('COMPANY', sendTo?.parentEntity?.customerName || '-')}
            </View>
            <View style={[styles.displayFlexColumn, { width: '28%', marginRight: 8 }]}>
              <Text style={styles.sectionTitle}> </Text>
              {getFieldWithTitle('EMAIL', sendTo?.email || '-')}
              {getFieldWithTitle(
                'ADDRESS',
                getCombinedAddress(sendTo?.parentEntity?.companyAddresses[0]) || '-'
              )}
            </View>
            <View style={[styles.displayFlexColumn, { width: '20%', marginRight: 8 }]}>
              <Text style={styles.sectionTitle}>From</Text>
              {getFieldWithTitle('NAME', returnTo?.name || '-')}
              {getFieldWithTitle(
                'COMPANY',
                returnTo?.company ? returnTo?.company?.companyName : '-'
              )}
            </View>
            <View style={[styles.displayFlexColumn, { width: '28%' }]}>
              <Text style={styles.sectionTitle}> </Text>
              {getFieldWithTitle('EMAIL', returnTo?.email || '-')}
              {getFieldWithTitle(
                'ADDRESS',
                getCombinedAddress(returnTo?.company?.companyAddresses) || '-'
              )}
            </View>
          </View>
          {/* Divider */}
          <View style={styles.divider} />
          {/* Subject */}
          <View style={styles.displayFlexColumn}>
            <Text style={styles.sectionTitle}>Subject</Text>
            <Text style={styles.text}>{changeOrderData?.subject || 'No subject'}</Text>
          </View>
          {/* Divider */}
          <View style={styles.divider} />

          {/* Days valid & Schedule Extension & Scope of Work */}
          <View style={[styles.displayFlex, { marginBottom: 12, justifyContent: 'flex-start' }]}>
            {getFieldWithTitle('DAYS VALID', changeOrderData?.daysValid || '-', {
              width: '15%'
            })}
            {getFieldWithTitle(
              'SCHEDULE EXTENSION REQUESTED',
              changeOrderData?.scheduleExtensionRequested || '-',
              {
                width: '30%'
              }
            )}
          </View>
          <View style={[styles.displayFlexColumn, { marginBottom: 12 }]}>
            <Text style={[styles.sectionTitle, { marginBottom: 10 }]}>SCOPE OF WORK</Text>
            <Text style={styles.text}>{changeOrderData?.scopeOfWork}</Text>
          </View>
          <View style={styles.divider} />

          {/* Items Table */}
          {pdfFormatOptionRef.current === GRAND_TOTAL
            ? // Case 3: Format option is "GRAND_TOTAL"
              getCostSectionView({ label: 'Total', cost: changeOrderData?.total })
            : itemTables}

          {/* Subtotal Summaries (if not Grand Total Display) and Total */}
          {pdfFormatOptionRef.current !== GRAND_TOTAL && (
            <View>
              <View style={[styles.displayFlexColumn, { marginTop: typeTotals.length ? 8 : 0 }]}>
                <View style={styles.displayFlexColumn}>{totalSummaryView()}</View>
              </View>
              <View style={[styles.displayFlex, { justifyContent: 'flex-end' }]}>
                <View style={styles.totalLinesDivider} />
              </View>
            </View>
          )}
          <View style={[styles.displayFlex, { justifyContent: 'flex-end' }]}>
            <Text style={[styles.totalsLineItemText, { width: '30%', fontWeight: 700 }]}>
              Total
            </Text>
            <Text style={[styles.totalsLineItemText, { fontWeight: 700 }]}>
              {convertToCurrencyString(changeOrderData?.total)}
            </Text>
          </View>
          <View style={styles.divider} />

          {/* Attachments */}
          <View style={[styles.displayFlex, { justifyContent: 'flex-start', flexWrap: 'wrap' }]}>
            {changeOrderData?.ChangeOrderAttachment?.filter(
              attachment => attachment.fileType.includes('image') && !attachment._deleted
            ).map(image => (
              <Image
                src={image.fileUrl}
                style={{ maxWidth: '19%', maxHeight: 70, marginRight: 3, marginBottom: 3 }}
              />
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

PDFDocument.propTypes = {
  companyInfo: PropTypes.object.isRequired,
  changeOrderData: PropTypes.object.isRequired,
  projectData: PropTypes.object.isRequired,
  sendTo: PropTypes.object.isRequired,
  returnTo: PropTypes.object.isRequired,
  pdfFormatOptionRef: PropTypes.string.isRequired
};

export default PDFDocument;
