import React, { useCallback } from 'react';

import { Label, ThemeProvider } from '@BuildHero/sergeant';
import { TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { bool, shape, string } from 'prop-types';

import { useStyles } from './ProjectNumber.styles';

const MAX_PROJECT_NUMBER_LENGTH = 10;

const ProjectNumber = React.memo(({ field, options }) => {
  const styles = useStyles();
  const { setFieldValue, errors } = useFormikContext();
  const handleChange = useCallback(
    event => {
      setFieldValue(field.name, event.target.value);
    },
    [field.name, setFieldValue]
  );
  const error = errors[field.name];
  return (
    <>
      <ThemeProvider>
        <Label label="Project Number" required={options.required} style={{ size: 12 }} />
      </ThemeProvider>
      <TextField
        css={styles.input}
        error={!!error}
        inputProps={{ maxLength: MAX_PROJECT_NUMBER_LENGTH }}
        value={field.value}
        variant="outlined"
        onChange={handleChange}
      />
      {error && <div css={styles.error}>{error}</div>}
    </>
  );
});

ProjectNumber.propTypes = {
  field: shape({
    name: string,
    value: string
  }).isRequired,
  options: shape({
    required: bool
  }).isRequired
};

export default ProjectNumber;
