import React, { useRef } from 'react';

import { CurrencyInput, Input, MultiSelect, NumberInput, Select } from '@BuildHero/sergeant';
import { Box, Grid, IconButton, InputAdornment, useTheme } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Draggable } from 'react-beautiful-dnd';

import styles from '../../styles';

const AccordionSummary = withStyles(styles.accordionSummary)(MuiAccordionSummary);

const toggleButtonTheme = createMuiTheme({
  palette: {
    action: {
      disabledBackground: 'rgba(102, 102, 102, 1)',
      disabled: 'rgba(102, 102, 102, 1)'
    }
  }
});

const applyToDropdownOptions = [
  { label: 'Department', value: 'department' },
  { label: 'All Employees', value: 'allEmployees' },
  { label: 'Employees', value: 'employee' }
];

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  marginBottom: '5px',

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'rgb(240, 240, 240)',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getCloseStyle = item => ({
  padding: 0,
  marginLeft: 5,
  height: '100%',
  color: !item.unsaved && 'transparent'
});

const LaborRateModifierCard = ({
  item,
  index,
  isDragDisabled,
  laborRateModifiers,
  onModifierChange,
  onAddModifier,
  onRemoveModifier,
  departmentOptions,
  employeeOptions
}) => {
  const theme = useTheme();
  const anchorRef = useRef();

  const primaryToSecondaryDropdownMap = {
    department: departmentOptions,
    employee: employeeOptions
  };

  const appliesToCategory = applyToDropdownOptions.find(
    option => option.value === item.appliesToCategory
  );

  const selectedOptions =
    appliesToCategory?.value === 'employee'
      ? employeeOptions.filter(option => item.appliesTo.includes(option.value))
      : departmentOptions.filter(option => item.appliesTo.includes(option.value));

  return (
    <Draggable
      draggableId={`draggable-${item.id}`}
      index={index}
      isDragDisabled={isDragDisabled}
      key={item.id}
    >
      {(provided, snapshot) => (
        <div style={{ opacity: snapshot.isDragging ? 0.5 : 1 }}>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            <Box css={styles.accordionContainer} m={0} p={0}>
              <MuiAccordion square>
                <AccordionSummary>
                  <div {...provided.dragHandleProps} css={styles.dragHandler}>
                    <IconButton css={styles.dragIcon} disableRipple>
                      <DragIndicatorIcon css={styles.dragIcon} />
                    </IconButton>
                  </div>
                  <Grid css={{ overflow: 'hidden' }} item justify="flex-start" xs={12}>
                    <Grid container wrap="nowrap">
                      <Box component="div" minWidth={theme.spacing(35)} width={theme.spacing(35)}>
                        <Input
                          autoFocus={
                            index === laborRateModifiers.length - 1 &&
                            laborRateModifiers[index].name === ''
                          }
                          defaultValue={item.name ?? ''}
                          id={`scope-input-${item.id}`}
                          label=""
                          placeholder="Enter Modifier Name"
                          onBlur={e => {
                            if (e.target.value) {
                              onModifierChange({ field: 'name', value: e.target.value, index });
                            }
                            e.relatedTarget?.click();
                          }}
                          onKeyDown={e => {
                            if (e.key === 'Enter') onAddModifier({ value: e.target.value });
                          }}
                        />
                      </Box>
                      <Box
                        component="div"
                        marginLeft={1}
                        minWidth={theme.spacing(20)}
                        width={theme.spacing(20)}
                      >
                        <Select
                          options={applyToDropdownOptions}
                          placeholder="Apply to"
                          portal
                          style={{ width: '100%', height: '100%' }}
                          value={appliesToCategory}
                          onChange={newValue => {
                            if (newValue === appliesToCategory) return;
                            onModifierChange({
                              field: 'appliesToCategory',
                              value: newValue.value,
                              index
                            });
                            onModifierChange({
                              field: 'appliesTo',
                              value: [],
                              index
                            });
                          }}
                        />
                      </Box>
                      {appliesToCategory && appliesToCategory?.value !== 'allEmployees' && (
                        <Box
                          component="div"
                          marginLeft={1}
                          marginRight={1}
                          minWidth={theme.spacing(10)}
                        >
                          <MultiSelect
                            options={primaryToSecondaryDropdownMap[appliesToCategory?.value]}
                            placeholder={`Select ${appliesToCategory?.label}`}
                            selectedOptions={selectedOptions}
                            showSearchIcon
                            onChange={newValues => {
                              onModifierChange({
                                field: 'appliesTo',
                                value: newValues.map(newValue => newValue.value),
                                index
                              });
                            }}
                          />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item justify="flex-end">
                    <Box component="div">
                      <ThemeProvider theme={toggleButtonTheme}>
                        <ToggleButtonGroup
                          aria-label="text alignment"
                          exclusive
                          size="small"
                          value={item.modifierType}
                          onChange={(event, newValue) => {
                            onModifierChange({
                              field: 'modifierType',
                              value: newValue,
                              index
                            });
                            onModifierChange({
                              field: 'modifierValue',
                              value: 0.0,
                              index
                            });
                          }}
                        >
                          <ToggleButton aria-label="percentage" value="percentage">
                            %
                          </ToggleButton>
                          <ToggleButton aria-label="currency" value="currency">
                            $
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </ThemeProvider>
                    </Box>
                  </Grid>
                  <Grid item justify="flex-end">
                    <Box component="div" marginLeft={1} width={theme.spacing(18)}>
                      {item.modifierType === 'currency' ? (
                        <CurrencyInput
                          value={item.modifierValue ?? 0}
                          onChange={newValue => {
                            onModifierChange({
                              field: 'modifierValue',
                              value: newValue || 0,
                              index
                            });
                          }}
                        />
                      ) : (
                        <NumberInput
                          endAdornment={<InputAdornment>%</InputAdornment>}
                          value={item.modifierValue}
                          onChange={newValue => {
                            onModifierChange({
                              field: 'modifierValue',
                              value: newValue || 0,
                              index
                            });
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item justify="flex-end">
                    <IconButton
                      disabled={!item.unsaved}
                      disableRipple
                      ref={anchorRef}
                      style={getCloseStyle(item)}
                      onClick={() => onRemoveModifier({ index })}
                    >
                      <CloseIcon css={styles.closeIcon} />
                    </IconButton>
                  </Grid>
                </AccordionSummary>
              </MuiAccordion>
            </Box>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default LaborRateModifierCard;
