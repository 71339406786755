import StorageService from 'services/StorageService';

export const getVisitRangeText = visitNumberString => {
  const visitNumberArray = visitNumberString.split(',').sort((a, b) => a - b);
  if (visitNumberArray.length === 0) return '';
  const ranges = [];
  let start;
  let end;
  for (let i = 0; i < visitNumberArray.length; i += 1) {
    start = visitNumberArray[i];
    end = start;
    while (visitNumberArray[i + 1] - visitNumberArray[i] === 1) {
      end = visitNumberArray[i + 1]; // increment the index if the numbers sequential
      i += 1;
    }
    ranges.push(start === end ? `${start}` : `${start}-${end}`);
  }
  return ranges.join(', ');
};

export const processReportsData = async (reportsData, customIdentifier) => {
  const storageService = new StorageService();

  return Promise.all(
    reportsData
      // Filter out the job report whose report pdf has not been generated yet
      .filter(reportData => reportData.reportURL)
      .map(async reportData => {
        const remoteUrl = await storageService.getFileUrl(reportData.reportURL);
        const reportFile = await storageService.getFileUrl(reportData.reportURL, {
          download: true
        });
        if (reportFile?.Body) {
          const blob = new Blob([reportFile.Body], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);

          return {
            ...reportData,
            blob,
            fileName: reportData.name || `JobReport${customIdentifier}.pdf`,
            visitsIncluded: getVisitRangeText(reportData.visitNumbers),
            fileSize: reportFile.ContentLength,
            type: 'application/pdf',
            remote: true,
            remoteUrl,
            fileUrl: url
          };
        }
        return null;
      })
      .filter(Boolean)
  );
};
