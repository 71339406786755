import React from 'react';

import { TV, TW, Typography } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';
import { Grid, useTheme } from '@material-ui/core';

const style = {
  noAssetsContainer: {
    width: '100%',
    border: 'black dashed 3px',
    padding: 50,
    marginTop: 10
  },
  noAssetsRow: {
    width: '100%',
    textAlign: 'center'
  }
};

const NoAssets = () => {
  const { palette } = useTheme();

  return (
    <Grid container css={style.noAssetsContainer}>
      <div css={style.noAssetsRow}>
        <Typography color={palette.text.primary} variant={TV.XL} weight={TW.BOLD}>
          No Assets Selected
        </Typography>
      </div>
      <div css={style.noAssetsRow}>
        <Typography color={palette.text.primary} variant={TV.BASE} weight={TW.REGULAR}>
          You will be able to create tasks for
        </Typography>
      </div>
      <div css={style.noAssetsRow}>
        <Typography color={palette.text.primary} variant={TV.BASE} weight={TW.REGULAR}>
          assets once they have been selected.
        </Typography>
      </div>
    </Grid>
  );
};

export default NoAssets;
