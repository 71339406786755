import React, { useMemo } from 'react';

import { ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { sortBy } from 'lodash';
import { useSelector } from 'react-redux';

import { COLUMNS } from '../../Jobs/JobDetail/componentsV2/AssetTable/AssetTable.component';
import FormTable from '../../Jobs/JobDetail/componentsV2/FormTable';
import ReportTable from '../../Jobs/JobDetail/componentsV2/ReportTable';
import { VisitDisplay } from '../../Jobs/JobDetail/componentsV2/TasksSection/TasksTable/TasksTable.components';
import { SectionHeader } from '../Components';
import { getVisitDisplayText } from '../utils';

import PhotosAndVideosTable from './PhotosAndVideosTable';

const multiVisitColumn = {
  field: 'visitDisplayText',
  flex: 2,
  headerName: 'Visit',
  renderCell: VisitDisplay
};

export const AdditionalInfo = ({ visits, isMultiVisits, companyTimezone, isLoading }) => {
  const tenantId = useSelector(state => state.user?.tenantId);

  const assetRows = useMemo(() => {
    const assets = [];
    visits.forEach(visit =>
      visit.assets?.items?.forEach(va => {
        assets.push({
          ...(va?.asset || {}),
          visitDisplayText: getVisitDisplayText(visit, companyTimezone),
          visitNumber: visit.visitNumber
        }); // TODO: replace visit Display text after other pr merges
      })
    );
    return sortBy(assets, 'visitNumber');
  }, [visits]);

  const formRows = useMemo(() => {
    const forms = [];
    visits.forEach(visit =>
      visit.formData.items.forEach(form => {
        forms.push({
          ...form,
          visitDisplayText: getVisitDisplayText(visit, companyTimezone),
          visitNumber: visit.visitNumber
        }); // TODO: replace visit Display text after other pr merges
      })
    );
    return sortBy(forms, 'visitNumber');
  }, [visits]);

  return (
    <ThemeProvider>
      <Typography variant={TV.L} weight={TW.BOLD}>
        Additional Information
      </Typography>
      <SectionHeader title="Assets Worked On" />
      <ReportTable
        columns={isMultiVisits ? [multiVisitColumn, ...COLUMNS] : COLUMNS}
        key={assetRows.length}
        loading={isLoading}
        loadingRows={3}
        noDataMessage="No Assets Worked On"
        rows={assetRows}
        tableOnly
        title="Assets Worked On"
      />

      <SectionHeader title="Forms" />
      <FormTable
        columns={isMultiVisits ? [multiVisitColumn] : []}
        editable
        forms={formRows}
        loading={isLoading}
        loadingRows={3}
        tableOnly
      />
      <PhotosAndVideosTable isMultiVisits={isMultiVisits} tenantId={tenantId} visits={visits} />
    </ThemeProvider>
  );
};

AdditionalInfo.defaultProps = {
  isLoading: false
};
