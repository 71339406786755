import React, { useCallback, useState } from 'react';

import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useSelector } from 'react-redux';

import { PageHeader, Tab, Tabs, UserPermission, XGrid } from 'components';
import { useTrigger } from 'customHooks/useTrigger';
import rbBillListTableColumns from 'meta/Procurement/PurchaseOrders/rbBillListTableMeta';
import rbReceiptListTableColumns from 'meta/Procurement/PurchaseOrders/rbReceiptListTableMeta';
import ErrorBoundaries from 'scenes/Error';
import ImagePreview from 'scenes/Procurement/component/ImagePreview';
import ProcurementUtils from 'scenes/Procurement/Procurement.utils';
import theme from 'themes/BuildHeroTheme';
import { PermissionConstants } from 'utils/AppConstants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import OverallTotalCostDisplay from '../component/OverallTotalCostDisplay';
import GET_BILL_LIST from '../queries/getBillList';
import GET_PURCHASE_ORDER_RECEIPT_LIST from '../queries/getPurchaseOrderReceiptList';

const useStyles = makeStyles(() => ({
  divider: {
    margin: '8px -24px 32px -24px'
  }
}));

const ReceiptsBills = () => {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const flags = useFlags();
  const isVendorInvoiceApproval = flags[FeatureFlags.VENDOR_INVOICE_APPROVAL];
  const [subscribe, trigger] = useTrigger();
  const [openImagePreview, setOpenImagePreview] = useState({ isOpen: false });

  const handleCellClick = useCallback(
    ({ row, field, value }, disableEdit) => {
      if (field === 'vendorDocumentNumber' && row.vendorDocumentAttachmentId)
        setOpenImagePreview({
          isOpen: true,
          receiptId: row.id,
          status: row.status,
          vendorDocumentAttachmentId: row.vendorDocumentAttachmentId,
          approvalStatus: row.approvalStatus,
          vendorDocumentNumber: row.vendorDocumentNumber,
          invoiceAmount: row.invoiceAmount,
          disableEdit
        });
    },
    [openImagePreview]
  );

  return (
    <ErrorBoundaries>
      <UserPermission action={PermissionConstants.OBJECT_PURCHASE_ORDER} I="read">
        <PageHeader
          breadcrumbsArray={[
            {
              link: '',
              title: 'Procurement'
            }
          ]}
          pageMapKey="procurementReceiptBills"
          userLocale={user.locale}
        />
        <Divider className={classes.divider} />
        <Tabs showDivider>
          <Tab label="Receipts">
            <XGrid
              columns={rbReceiptListTableColumns(theme, isVendorInvoiceApproval)}
              enableExport
              entityTypeName="Receipts"
              query={GET_PURCHASE_ORDER_RECEIPT_LIST}
              refetchTrigger={subscribe}
              rowHeight={38}
              tableName="PurchaseOrdersReceiptXGrid"
              toolbarCustomComponent={rows => OverallTotalCostDisplay(rows)}
              onCellClick={data => handleCellClick(data, false)}
            />
          </Tab>
          <Tab label="Bills">
            <XGrid
              columns={rbBillListTableColumns(isVendorInvoiceApproval)}
              enableExport
              entityTypeName="Bills"
              query={GET_BILL_LIST}
              refetchTrigger={subscribe}
              rowHeight={38}
              tableName="BillXGrid"
              toolbarCustomComponent={rows => OverallTotalCostDisplay(rows)}
              onCellClick={data => handleCellClick(data, true)}
            />
          </Tab>
        </Tabs>
        <ImagePreview
          attachmentId={openImagePreview.vendorDocumentAttachmentId}
          disableEdit={openImagePreview.disableEdit}
          handleClose={() => setOpenImagePreview({ isOpen: false })}
          open={openImagePreview.isOpen}
          parentData={{
            approvalStatus: openImagePreview.approvalStatus,
            vendorDocumentNumber: openImagePreview.vendorDocumentNumber,
            invoiceAmount: openImagePreview.invoiceAmount
          }}
          onDelete={async record => {
            await ProcurementUtils.handleDeleteImage(record, openImagePreview);
            trigger();
          }}
          onEdit={async (attachmentId, record) => {
            await ProcurementUtils.handleEditImage(attachmentId, record, openImagePreview, user);
            trigger();
            setOpenImagePreview({ isOpen: false });
          }}
        />
      </UserPermission>
    </ErrorBoundaries>
  );
};

export default ReceiptsBills;
