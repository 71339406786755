import { MultiSelectTypes } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';

export const billRowsMeta = [
  {
    id: 'visitNumbers',
    numeric: false,
    disablePadding: true,
    label: 'Visit',
    isCustom: true,
    type: 'viewVisitNumbers'
  },
  {
    id: 'billNumber',
    label: 'Bill Number',
    filterKey: 'billNumber',
    filterType: 'string',
    linkPath: '/procurement/receipts-bills/bill/view',
    linkReference: 'id',
    numeric: true,
    showLink: 'view',
    type: 'number'
  },
  {
    id: 'invoicedStatus',
    label: 'Invoiced Status',
    disablePadding: true,
    enumType: EnumType.BILL_STATUS,
    type: 'enum'
  },
  {
    id: 'PurchaseOrderReceipt.receiptNumber',
    filterKey: 'receiptNumber',
    filterType: 'string',
    label: 'Receipt Number',
    linkPath: '/procurement/receipts-bills/receipt/view',
    linkReference: 'PurchaseOrderReceipt.id',
    numeric: true,
    showLink: 'view',
    type: 'number'
  },
  {
    id: 'vendorName',
    numeric: false,
    disablePadding: true,
    label: 'Vendor'
  },
  {
    id: 'PurchaseOrder.poNumber',
    label: 'PO Number',
    filterKey: 'poNumber',
    filterType: 'string',
    linkPath: '/procurement/purchaseorders/view',
    linkReference: 'PurchaseOrder.id',
    numeric: true,
    showLink: 'view',
    type: 'number'
  },
  {
    id: 'createdDate',
    numeric: false,
    disablePadding: true,
    label: 'Date Issued',
    type: 'date'
  },
  {
    id: 'createdBy',
    numeric: false,
    disablePadding: true,
    label: 'Created By'
  },
  {
    id: 'totalCost',
    numeric: false,
    disablePadding: true,
    label: 'Total',
    type: 'currency'
  }
];

export const purchaseOrderItemRowsMeta = [
  {
    id: 'PurchaseOrder.poNumber',
    label: 'PO Number',
    linkPath: '/procurement/purchaseorders/view',
    linkReference: 'PurchaseOrder.id',
    numeric: true,
    showLink: 'view',
    type: 'number'
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: true,
    label: 'Item Description'
  },
  {
    id: 'vendorName',
    numeric: false,
    disablePadding: true,
    label: 'Vendor'
  },
  {
    id: 'status',
    label: 'Fulfillment Status',
    disablePadding: true,
    enumType: MultiSelectTypes.PURCHASE_ORDER_STATUS,
    type: 'enum'
  },
  {
    id: 'quantity',
    numeric: true,
    disablePadding: true,
    label: 'Quantity Ordered',
    type: 'number'
  },
  {
    id: 'quantityFulfilled',
    numeric: true,
    disablePadding: true,
    label: 'Quantity Received',
    type: 'number'
  },
  {
    id: 'totalCost',
    numeric: false,
    disablePadding: true,
    label: 'Total Cost',
    type: 'currency'
  }
];
