import { uniq, uniqBy } from 'lodash';

export const mergeDispatchEventsByTechIds = (existing, incoming, { variables }) => {
  if (!existing || variables?.replace) return incoming;

  return {
    manDays: uniqBy([...existing.manDays, ...incoming.manDays], '__ref'),
    nonVisitEvents: uniqBy([...existing.nonVisitEvents, ...incoming.nonVisitEvents], '__ref'),
    visits: uniqBy([...existing.visits, ...incoming.visits], '__ref'),
    techIds: uniq([...existing.techIds, ...incoming.techIds])
  };
};

const typePolicies = {
  Query: {
    fields: {
      getDispatchEventsByTechIds: {
        keyArgs: false,
        merge: mergeDispatchEventsByTechIds
      }
    }
  }
};

export default typePolicies;
