import gql from 'graphql-tag';

import { attachmentFragment } from './queryFragments';

export const getAttachmentsByQuoteId = gql`
  query getQuoteById($id: String!) {
    getQuoteById(id: $id) {
      attachments: attachments(entityConnection: "Attachment") {
        items {
        ${attachmentFragment}
        }
      }
    }
}`;
