import React from 'react';

import { Button, ButtonType, ThemeProvider } from '@BuildHero/sergeant';

const SaveButton = ({ disabled, onClick, loading }) => {
  return (
    <ThemeProvider>
      <div css={{ height: 88, marginTop: 100 }} />
      <div
        css={theme => ({
          backgroundColor: theme.palette.grayscale(94),
          position: 'fixed',
          bottom: 0,
          height: 88,
          width: '100%',
          padding: 24,
          left: 0,
          textAlign: 'right'
        })}
      >
        <Button disabled={disabled} loading={loading} type={ButtonType.SECONDARY} onClick={onClick}>
          Save Changes
        </Button>
      </div>
    </ThemeProvider>
  );
};

export default SaveButton;
