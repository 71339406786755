import React, { Component } from 'react';

import { Button, ButtonType, Modal, ThemeProvider, Typography } from '@BuildHero/sergeant';
import Grid from '@material-ui/core/Grid';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

import { ResponsiveTable, UserPermission } from 'components';
import CreateEntryButton from 'components/Buttons/CreateEntryButton';
import Context from 'components/Context';
import { customerPropertyRows } from 'meta/Customer';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { CustomerPropertyService, CustomerService } from 'services/core';
import AccountingValidationService from 'services/core/graphql-services/AccountingValidation';
import { Logger } from 'services/Logger';

import { PermissionConstants } from 'utils/AppConstants';

import { EntityType } from 'utils/constants';

import AddProperty from '../../AddProperty';

/**
 * Creates customer page. new customer fields & layouts are generated from the meta file
 * labels are fetched from application level
 * locale of the user is referred from user context
 */
class Properties extends Component {
  constructor(props) {
    super(props);
    this.CustomerPropertyService = new CustomerPropertyService();
    this.AccountingValidationService = new AccountingValidationService();
    this.mounted = props.mounted;
    this.CustomerService = new CustomerService();
    this.state = {
      openAddProperty: false,
      dataRecord: '',
      modalMode: '',
      confirmDialog: false,
      confirmAction: '',
      formService: () => console.log('No form service'),
      isSubmitting: false
    };
  }

  // add property clicked from page create actions
  // componentDidUpdate = prevProps => {
  //   if (prevProps.createProperty !== this.props.createProperty) {
  //     this.handleOpenPopUp('openAddProperty', 'new', '');
  //   }
  // };

  handleOpenPopUp = (popUpKey, mode, record) => {
    this.setState({
      [popUpKey]: true,
      modalMode: mode,
      dataRecord: record || {}
    });
  };

  handleClosePopUp = popUpKey => {
    this.setState({ [popUpKey]: false });
  };

  deactivateRecord = async (values, companyName) => {
    try {
      const { data } = await this.CustomerService.deleteCustomerProperty(values);
      if (data) {
        await this.props.refetch(this.props.customerData.sortKey);
        this.props.snackbarOn('success', `Successfully deactivated property: ${companyName}`);
        this.setState({
          confirmDialog: false,
          confirmAction: ''
        });
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to delete Customer, please try again later', error);
    }
  };

  handleCustomerPropertyRowActions = (mode, record) => {
    const { incompleteJobNumbers } = this.props;

    if (mode === 'delete') {
      if (!isEmpty(incompleteJobNumbers)) {
        const propertyIncompleteJobNums = incompleteJobNumbers.get(record.id);
        if (propertyIncompleteJobNums?.length) {
          const jobNumberString = propertyIncompleteJobNums.join(', ');
          this.props.snackbarOn(
            'error',
            `Property has Open/In progress jobs. Kindly complete/cancel the pending jobs: \n ${jobNumberString}`
          );
          return;
        }
      }
      this.setState({
        confirmDialog: true,
        confirmAction: () => this.deactivateRecord(record, record.companyName)
      });
    } else {
      this.props.history.push(`/property/${mode}/${record.id}`, {
        recordSortKey: record.sortKey
      });
    }
  };

  handleCancelConfirmation = () => {
    this.setState({
      confirmDialog: false,
      confirmAction: ''
    });
  };

  handleNoteCreation = (data, dataRecord) => {
    const noteData = {};
    noteData.parent = data;
    noteData.parent.id = dataRecord.data.batchCreateEntityData[0].id;
    noteData.entityType = 'Note';
    noteData.tenantId = data.tenantId;
    noteData.tenantCompanyId = data.tenantCompanyId;
    noteData.subject = 'Property Instruction';
    noteData.note = data.propertyInstruction;

    return noteData;
  };

  handleOnCompleteProperty = async data => {
    // validation - if isTaxable then require taxRate
    if (data.isTaxable && data.isTaxable !== 'false' && !data.taxRateId) {
      return this.props.snackbarOn(
        'error',
        'Tax rate required for a taxable property.\nPlease select a tax rate.'
      );
    }
    const validationMessage = await this.AccountingValidationService.validateEntity(
      this.props.user.tenantId,
      EntityType.CUSTOMER_PROPERTY,
      data
    );
    if (validationMessage) {
      return this.props.snackbarOn('error', validationMessage);
    }
    const { listTenantSettings } = Context.getCompanyContext();
    const instantSyncSetting =
      listTenantSettings &&
      listTenantSettings.filter(
        setting => setting.settingKey === 'syncImmediately' && setting.settingValue === 'true'
      );
    const syncNow = instantSyncSetting && instantSyncSetting.length > 0;
    data.syncNow = syncNow;
    data.sameAddress = data.billingAddressCheckbox;
    this.setState({ isSubmitting: true });
    try {
      const customerPropertyData = await this.CustomerPropertyService.addCustomerPropertiesToCustomer(
        this.props.user.tenantId,
        data,
        this.props.customerData.id,
        syncNow
      );
      if (customerPropertyData?.[0]?.id) {
        this.props.history.push(`/property/view/${customerPropertyData[0].id}`);
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to save customer property', error);
    }
    this.setState({ isSubmitting: false });
    return data;
  };

  processCustomerProperties = () => {
    const { customerProperties } = this.props;
    return customerProperties
      ? customerProperties.items.map(property => this.processProperty(property))
      : [];
  };

  getActivityText = property =>
    property.isActive || property.isActive === null ? 'Active' : 'Deactivated';

  processProperty = property => ({
    ...property,
    activityText: this.getActivityText(property)
  });

  setLayout = mode => {
    return mode === 'view' ? 'default' : 'edit';
  };

  setTitle = mode => {
    return `${mode.charAt(0).toUpperCase() + mode.slice(1)} Property`;
  };

  handleSave = () => {
    this.state.formService.submit();
  };

  setModalHeaderButtons = () => {
    if (this.state.modalMode === 'view') return null;
    return (
      <ThemeProvider>
        <Button
          disabled={this.state.isSubmitting}
          loading={this.state.isSubmitting}
          type={ButtonType.PRIMARY}
          onClick={this.handleSave}
        >
          Save Property
        </Button>
      </ThemeProvider>
    );
  };

  render() {
    const { customerData, usePropertyPricebooks } = this.props;
    const isActiveCustomer = customerData.isActive || customerData.isActive === null;

    return (
      <ErrorBoundaries>
        {isActiveCustomer && (
          <UserPermission action={PermissionConstants.OBJECT_PROPERTY} I="new">
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CreateEntryButton
                caslKey={PermissionConstants.OBJECT_PROPERTY}
                handleAdd={() => this.handleOpenPopUp('openAddProperty', 'new', '')}
                key="addPropertyButton"
                label="Add Property"
              />
            </div>
          </UserPermission>
        )}
        <ResponsiveTable
          caslKey={PermissionConstants.OBJECT_PROPERTY}
          data={this.processCustomerProperties()}
          getNonEditableList={{
            service: (serviceResults, statusPath) => {
              return serviceResults.filter(entity => !entity[statusPath]).map(entity => entity.id);
            },
            statusPath: 'isActive'
          }}
          noDataMsg="No customer properties"
          rowActionButtons={{
            view: {
              label: 'View property',
              caslAction: 'read',
              caslKey: PermissionConstants.OBJECT_PROPERTY,
              icon: 'Launch'
            },
            edit: {
              label: 'Edit property',
              caslAction: 'update',
              caslKey: PermissionConstants.OBJECT_PROPERTY,
              icon: 'Edit'
            },
            delete: {
              label: 'Deactivate',
              caslAction: 'delete',
              caslKey: PermissionConstants.OBJECT_PROPERTY,
              icon: 'Delete'
            }
          }}
          rowActions={this.handleCustomerPropertyRowActions}
          rowMetadata={customerPropertyRows}
        />
        <Grid style={{ paddingTop: 40 }} />
        <AddProperty
          caslKey={PermissionConstants.OBJECT_PROPERTY}
          data={this.state.dataRecord}
          handleClose={() => this.handleClosePopUp('openAddProperty')}
          headerButtons={this.setModalHeaderButtons()}
          layout={this.setLayout(this.state.modalMode)}
          open={this.state.openAddProperty}
          parent={customerData}
          recordSortKey={customerData.recordSortKey}
          title={this.setTitle(this.state.modalMode)}
          usePropertyPricebooks={usePropertyPricebooks}
          onComplete={this.handleOnCompleteProperty}
          onCreateService={service => this.setState(() => ({ formService: service }))}
        />
        <ThemeProvider>
          <Modal
            actions={
              <Button fullWidth type="error" onClick={this.state.confirmAction}>
                Deactivate Property
              </Button>
            }
            open={this.state.confirmDialog}
            title="Deactivate Property"
            onClose={this.handleCancelConfirmation}
          >
            <Typography>Are you sure you want to deactivate this property?</Typography>
          </Modal>
        </ThemeProvider>
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const connectedProperties = connect(mapStateToProps, mapDispatcherToProps)(Properties);
export default connectedProperties;
