export const mutationOperations = {
  ADD: 'add',
  UPDATE: 'update',
  DELETE: 'delete'
};

export const updateTaskTemplateCacheForTaskTemplateSAQuery = (
  cachedData,
  checklistId,
  newData,
  operation,
  parentId
) => {
  if (!checklistId || !cachedData || !newData) return;
  const updatedTaskTemplateChecklists = cachedData.taskTemplateChecklistsOnServiceAgreement.map(
    cList => {
      if (cList.id === checklistId) {
        const localCList = { ...cList };
        let updatedTaskTemplates;
        if (operation === mutationOperations.ADD)
          updatedTaskTemplates = [...cList.mergedTaskTemplates, newData];
        if (operation === mutationOperations.UPDATE) {
          updatedTaskTemplates = cList.mergedTaskTemplates.map(task => {
            // on the first edit, parent id generated from checkflist defintion needs to be replaced with new child id created against SA
            if (task.id === parentId) return newData;
            return task.id === newData.id ? newData : task;
          });
        }
        if (operation === mutationOperations.DELETE) {
          updatedTaskTemplates = cList.mergedTaskTemplates.filter(
            task => !newData.includes(task.id)
          );
        }
        localCList.mergedTaskTemplates = updatedTaskTemplates;
        return localCList;
      }
      return cList;
    }
  );
  return updatedTaskTemplateChecklists;
};

export const formatProductOptions = product => ({
  id: product.id,
  label: product.name,
  rightLabel: product.description,
  value: {
    productId: product.id,
    name: product.name,
    code: product.code,
    description: product.description,
    quantity: product.quantity ?? 1
  }
});

export const getProductsFromTaskTemplatePart = (parts = []) =>
  parts.map(item =>
    formatProductOptions({
      ...item.product,
      description: item.productDescription,
      quantity: item.quantity
    })
  );
