import gql from 'graphql-tag';
import { useSelector } from 'react-redux';

import useExtendedQuery from 'customHooks/useExtendedQuery';

const serviceAgreement = gql`
  query serviceAgreement($partitionKey: String, $id: String) {
    serviceAgreement(partitionKey: $partitionKey, id: $id) {
      id
      advancedScheduling
    }
  }
`;

const useAdvancedScheduling = ({ serviceAgreementId, ...options }) => {
  const tenantId = useSelector(state => state.user)?.tenantId;

  return useExtendedQuery(serviceAgreement, {
    variables: {
      partitionKey: tenantId,
      id: serviceAgreementId
    },
    skip: !tenantId || !serviceAgreementId,
    transform: result => result?.serviceAgreement,
    ...options
  });
};

export default useAdvancedScheduling;
