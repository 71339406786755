import React from 'react';

import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { useConfirmModal } from 'customHooks/ConfirmModalContext';

const StyledTabs = withStyles(() => ({
  root: {
    marginLeft: 16,
    borderTop: '1px solid #E5E5E5',
    minHeight: 41,
    height: 41
  },
  indicator: {
    backgroundColor: 'rgba(255, 255, 255, 0)'
  }
}))(Tabs);

const StyledTab = withStyles(theme => ({
  root: {
    padding: '12px 20px',
    minWidth: 100,
    minHeight: 40,
    height: 40,
    color: theme.palette.grayscale(50),
    backgroundColor: theme.palette.grayscale(94),
    opacity: 1,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '16px',
    borderBottom: '1px solid #E5E5E5',
    '& .row-count-indicator': {
      marginLeft: 8,
      padding: '0 2px',
      borderRadius: 4,
      color: 'white',
      background: theme.palette.grayscale(50),
      fontSize: 11
    }
  },
  selected: {
    color: '#00874D',
    backgroundColor: theme.palette.common.white,
    borderRight: '1px solid #E5E5E5',
    borderLeft: '1px solid #E5E5E5',
    borderBottom: 'none',
    '&:first-child': {
      borderLeft: 'none'
    },
    '& .row-count-indicator': {
      marginLeft: 8,
      padding: '0 2px',
      borderRadius: 4,
      color: 'white',
      background: theme.palette.grayscale(20),
      fontSize: 11
    }
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
}))(props => <Tab disableRipple {...props} />);

const StyledBox = withStyles(theme => ({
  root: {
    width: '100%',
    flexGrow: 1,
    borderBottom: '1px solid #E5E5E5',
    backgroundColor: theme.palette.grayscale(94)
  }
}))(Box);

const switchConfirmContent = {
  body: 'You have unsaved work, are you sure you want to switch tabs?',
  title: 'Switch Tab',
  buttonLabel: 'Switch Tab',
  cancelButtonLabel: 'Cancel'
};

const CustomTabs = ({ value, tabMenus, onChange, tabStyle, confirmSwitch }) => {
  const confirmContext = useConfirmModal();

  const handleChange = async (_, newValue) => {
    if (!confirmSwitch || (await confirmContext.confirm(switchConfirmContent))) {
      if (onChange) onChange(_, newValue);
    }
  };

  return (
    <StyledTabs indicatorColor="secondary" value={value} onChange={handleChange}>
      {tabMenus.map((tab, i) => (
        <StyledTab
          disabled={tab.disabled}
          // eslint-disable-next-line react/no-array-index-key
          key={`${tab.tabLabel}-${i}`}
          label={tab.tabLabel}
          style={tabStyle}
        />
      ))}
      <StyledBox />
    </StyledTabs>
  );
};

export default CustomTabs;
