import { useExtendedFetchForPMAPI as useExtendedFetch } from './useExtendedFetch';

export const parseSettings = data => data?.settingsJSON || {};

export const useGetProjectAccountingSettings = () => {
  return useExtendedFetch(
    {
      url: 'accounting-settings'
    },
    {
      defaultData: {},
      transform: parseSettings
    }
  );
};
