import React from 'react';

import { ThemeProvider, Typography } from '@BuildHero/sergeant';
import { useSelector } from 'react-redux';

import WrapTable from 'components/WrapTable';
import { TablePlaceholderVariant } from 'components/WrapTable/WrapTable.constants';
import usePayrollHourTypes from 'customHooks/usePayrollHourTypes';
import usePayrollSettings from 'customHooks/usePayrollSettings';
import { findAddressByAddressType } from 'utils';
import withLazyMount from 'utils/withLazyMount';

import { ReportTableSectionDefaultProps, ReportTableSectionPropTypes } from '../../propTypes';
import { useTimekeepingLedger } from '../../queries/useTimekeepingLedger';
import {
  selectNonVisitTimesheetNotes,
  selectTimesheetManualEntry,
  selectTimesheets,
  selectVisitTimesheetNotes
} from '../../selectors';

import ReportSection from '../ReportSection';

import TimeTrackingReportTable from './components/TimeTrackingReportTable';
import { NOTED_COLUMNS, TIMESHEET_COLUMNS } from './LaborSection.constants';
import { useGetManualTimeCols, useGetManualTimeRows } from './LaborSection.hooks';
import { useStyles } from './LaborSection.styles';

const LaborSection = ({ visit, job, loading, error, isReviewReport }) => {
  const styles = useStyles();
  const title = 'Labor';
  const user = useSelector(s => s.user);
  const propertyAddress = findAddressByAddressType(
    job?.customerProperty?.companyAddresses?.items,
    'propertyAddress'
  );
  const [settings] = usePayrollSettings();
  const isAutomatedTimeTracking = !!settings?.enableAutomatedTimeTracking;
  const { data: locationData } = useTimekeepingLedger(visit.id);

  // Time tracked by mobile App
  const mobileAppLaborRows = isAutomatedTimeTracking
    ? selectTimesheets({ visit, propertyAddress }) || []
    : selectTimesheetManualEntry({ visit, propertyAddress, locationData }) || [];

  // Timesheet notes consist of visit notes and billable nonvisit notes
  const visitNoteRows = selectVisitTimesheetNotes(visit) || [];
  const nonVisitNoteRows = selectNonVisitTimesheetNotes({ visit, isAutomatedTimeTracking }) || [];
  const allNotes = [...visitNoteRows, ...nonVisitNoteRows];

  // Time entered by technicians
  const [payrollHourTypes] = usePayrollHourTypes();
  const manualTimeCols = useGetManualTimeCols({ payrollHourTypes, styles, user });
  const manualTimeRows = useGetManualTimeRows({ visit, isAutomatedTimeTracking });

  return (
    <ThemeProvider>
      <ReportSection title={title}>
        <Typography css={styles.subSectionTitle}>Time Tracked by Mobile app</Typography>
        <WrapTable
          columns={TIMESHEET_COLUMNS}
          error={error}
          loading={loading}
          noDataMessage="No Time Tracked"
          placeholderVariant={TablePlaceholderVariant.TEXT}
          rows={mobileAppLaborRows}
        />
        <Typography css={styles.subSectionTitle}>Timesheet Note by Technician</Typography>
        <WrapTable
          columns={NOTED_COLUMNS}
          error={error}
          loading={loading}
          noDataMessage="No Timesheet Notes"
          placeholderVariant={TablePlaceholderVariant.TEXT}
          rows={allNotes}
        />
        {isReviewReport && isAutomatedTimeTracking && (
          <>
            <Typography css={styles.subSectionTitle}>Time Tracking Report</Typography>
            <TimeTrackingReportTable user={user} visitId={visit.id} />
          </>
        )}
        {!isAutomatedTimeTracking && (
          <>
            <Typography css={styles.subSectionTitle}>Time entered by Technician</Typography>
            <WrapTable
              columns={manualTimeCols}
              error={error}
              loading={loading}
              noDataMessage="No Time entered By Technician"
              placeholderVariant={TablePlaceholderVariant.TEXT}
              rows={manualTimeRows}
              scrollX
            />
          </>
        )}
      </ReportSection>
    </ThemeProvider>
  );
};

LaborSection.propTypes = ReportTableSectionPropTypes;
LaborSection.defaultProps = ReportTableSectionDefaultProps;

export default withLazyMount(LaborSection);
