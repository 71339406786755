import * as Yup from 'yup';

import { getEmployeeById } from 'services/API/employee';

export async function formatProjectDataForSidebarForm(projectData) {
  if (!projectData) return {};
  const {
    projectOwnerId,
    gcProjectManagerId,
    gcSuperintendentId,
    contactNumber,
    email
  } = projectData;
  // TODO: put this somewhere better
  const projectOwner = await getEmployeeById(projectOwnerId);
  const gcProjectManager = await getEmployeeById(gcProjectManagerId);
  const gcSuperintendent = await getEmployeeById(gcSuperintendentId);

  return {
    customer: projectOwner !== null ? projectOwner.name : '',
    gcProjectManager: gcProjectManager !== null ? gcProjectManager.name : '',
    gcSuperintendent: gcSuperintendent !== null ? gcSuperintendent.name : '',
    contactInfo: `${contactNumber || '-'} ${email || ''}`
  };
}

export function formatProjectImageForSidebarForm(projectData) {
  if (!projectData) return {};

  return {
    projectImage: projectData.imgUrl
  };
}

export async function formatProjectDataForHeaderForm(projectData) {
  if (!projectData) return {};

  const { type, projectManagerId, projectProgress, arBalance } = projectData;
  // TODO: put this somewhere better
  const projectManager = await getEmployeeById(projectManagerId);
  return {
    type,
    projectManager: projectManager?.name && projectManager?.firstName ? projectManager?.name : '-',
    projectProgress,
    arBalance
  };
}

export function generateDefaultFieldsObject(fields) {
  const result = {};
  if (typeof fields !== 'object' && !Array.isArray(fields)) return result;
  const iterableFields = Array.isArray(fields) ? fields : Object.keys(fields);

  iterableFields.forEach(field => {
    result[field] = {
      default: field,
      edit: field
    };
  });
  return result;
}

export const generateDefaultValidationSchema = validationSchema => {
  const result = {};
  if (!validationSchema || typeof validationSchema !== 'object') return Yup.object().shape(result);

  Object.keys(validationSchema).forEach(field => {
    if (validationSchema[field]?.required) {
      if (validationSchema[field]?.nullable) {
        result[field] = Yup.string()
          .nullable()
          .required('Field is required');
      } else {
        result[field] = Yup.string().required('Field is required');
      }
    }
    const { maxCharLimit } = validationSchema[field];
    if (maxCharLimit) {
      result[field] = Yup.string().max(
        maxCharLimit,
        `You have exceeded the maximum ${maxCharLimit} character limit`
      );
    }
  });
  return Yup.object().shape(result);
};
