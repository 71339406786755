import { connect } from 'react-redux';

import { snackbarOn } from 'redux/actions/globalActions';

import DispatchSettings from './DispatchSettings.component';

const mapDispatcherToProps = { snackbar: snackbarOn };

const mapStateToProps = state => ({
  user: state.user
});

const reduxConnectedDispatchSettings = connect(
  mapStateToProps,
  mapDispatcherToProps
)(DispatchSettings);

export default reduxConnectedDispatchSettings;
