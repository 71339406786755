import React, { useRef } from 'react';

import { Input, MoreButton, Select, TV, TW, Typography } from '@BuildHero/sergeant';
import { Box, Grid, IconButton, Switch, useTheme } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';
import ArchiveIcon from '@material-ui/icons/Archive';
import CloseIcon from '@material-ui/icons/Close';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

import StatusChip from 'components/StatusChip';
import { ArchivedStatus, EnumType } from 'utils/constants';

import styles from '../../styles';

const AccordionSummary = withStyles(styles.accordionSummary)(MuiAccordionSummary);

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  marginBottom: '5px',

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'rgb(240, 240, 240)',

  // styles we need to apply on draggables
  ...draggableStyle
});

const PayrollHourTypeCard = ({
  item,
  index,
  isDragDisabled,
  adpExportEnabled,
  payrollHourTypes,
  billingHourTypeOptions,
  mapPayrollHourToBilling,
  onModifyPayrollHourType,
  onRemovePayrollHourType
}) => {
  const theme = useTheme();
  const anchorRef = useRef();

  return (
    <Draggable
      draggableId={`draggable-${item.id}`}
      index={index}
      isDragDisabled={isDragDisabled}
      key={item.id}
    >
      {(provided, snapshot) => (
        <div style={{ opacity: snapshot.isDragging ? 0.5 : 1 }}>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            <Box css={styles.accordionContainer(item.isArchived)} m={0} p={0}>
              <MuiAccordion elevation={item.isArchived ? 0 : 2} square>
                <AccordionSummary>
                  <div {...provided.dragHandleProps} css={styles.dragHandler}>
                    <IconButton css={styles.dragIcon} disableRipple>
                      <DragIndicatorIcon css={styles.dragIcon} />
                    </IconButton>
                  </div>
                  <Grid item justify="flex-start" xs={12}>
                    <Grid container>
                      <Box component="div" width={theme.spacing(32)}>
                        <Input
                          autoFocus={
                            index === payrollHourTypes.length - 1 &&
                            payrollHourTypes[index].hourType === ''
                          }
                          defaultValue={item.hourType ?? ''}
                          disabled={item.isArchived}
                          label=""
                          placeholder="Enter Payroll Hour Type (eg 'Regular')"
                          onBlur={e => {
                            if (e.target.value) {
                              onModifyPayrollHourType({
                                field: 'hourType',
                                value: e.target.value,
                                index
                              });
                            }
                            e.relatedTarget?.click();
                          }}
                        />
                      </Box>
                      <Box
                        component="div"
                        sx={{ marginLeft: theme.spacing(1) }}
                        width={theme.spacing(24)}
                      >
                        <Input
                          defaultValue={item.hourTypeAbbreviation ?? ''}
                          disabled={item.isArchived}
                          label=""
                          placeholder="Enter Short Form (eg 'REG')"
                          onBlur={e => {
                            if (e.target.value) {
                              onModifyPayrollHourType({
                                field: 'hourTypeAbbreviation',
                                value: e.target.value,
                                index
                              });
                            }
                            e.relatedTarget?.click();
                          }}
                        />
                      </Box>
                      {adpExportEnabled && (
                        <>
                          <Box
                            component="div"
                            sx={{ marginLeft: theme.spacing(1) }}
                            width={theme.spacing(22)}
                          >
                            <Input
                              defaultValue={item.intacctAccountNumber ?? ''}
                              disabled={item.isArchived}
                              label=""
                              placeholder="Enter Billable Account #"
                              onBlur={e => {
                                if (e.target.value) {
                                  onModifyPayrollHourType({
                                    field: 'intacctAccountNumber',
                                    value: e.target.value,
                                    index
                                  });
                                }
                                e.relatedTarget?.click();
                              }}
                            />
                          </Box>
                          <Box
                            component="div"
                            sx={{ marginLeft: theme.spacing(1) }}
                            width={theme.spacing(26)}
                          >
                            <Input
                              defaultValue={item.intacctNonBillableAccountNumber ?? ''}
                              disabled={item.isArchived}
                              label=""
                              placeholder="Enter Non-Billable Account #"
                              onBlur={e => {
                                if (e.target.value) {
                                  onModifyPayrollHourType({
                                    field: 'intacctNonBillableAccountNumber',
                                    value: e.target.value,
                                    index
                                  });
                                }
                                e.relatedTarget?.click();
                              }}
                            />
                          </Box>
                        </>
                      )}
                      {mapPayrollHourToBilling && (
                        <Box component="div" marginLeft={1} width={theme.spacing(32)}>
                          <Select
                            disabled={item.isArchived}
                            options={billingHourTypeOptions}
                            placeholder="Select Related Billing Hour Type"
                            portal
                            style={{ width: '100%', height: 36 }}
                            value={billingHourTypeOptions.find(
                              ({ value }) => value === item.billingHourTypeId
                            )}
                            onChange={newValue => {
                              if (newValue.value === item.billingHourTypeId) return;
                              onModifyPayrollHourType({
                                field: 'billingHourTypeId',
                                value: newValue.value,
                                index
                              });
                            }}
                          />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item justify="flex-end">
                    <Box component="div" marginLeft={1} width={theme.spacing(18)}>
                      <div css={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          color={theme.palette.grayscale(60)}
                          variant={TV.BASE}
                          weight={TW.MEDIUM}
                        >
                          Autofill time
                        </Typography>
                        <Switch
                          defaultChecked={item.isAutofill}
                          disabled={item.isArchived}
                          onChange={(_, value) => {
                            onModifyPayrollHourType({ field: 'isAutofill', value, index });
                          }}
                        />
                      </div>
                    </Box>
                  </Grid>
                  {item.isArchived && (
                    <Grid item justify="flex-end">
                      <StatusChip
                        css={{ borderRadius: 2, marginLeft: 8 }}
                        enumType={EnumType.ARCHIVED_STATUS}
                        enumValue={ArchivedStatus.IS_ARCHIVED}
                        label="Archived"
                        showIcon
                      />
                    </Grid>
                  )}
                  <Grid item justify="flex-end">
                    {item.unsaved ? (
                      <IconButton
                        disableRipple
                        ref={anchorRef}
                        style={styles.closeIconButton}
                        onClick={() => onRemovePayrollHourType({ index })}
                      >
                        <CloseIcon css={styles.closeIcon} />
                      </IconButton>
                    ) : (
                      <MoreButton
                        disableRipple
                        options={[
                          {
                            label: 'Archive',
                            icon: UnarchiveIcon,
                            disabled: item.isArchived,
                            onClick: () =>
                              onModifyPayrollHourType({ field: 'isArchived', value: true, index })
                          },
                          {
                            label: 'Unarchive',
                            icon: ArchiveIcon,
                            disabled: !item.isArchived,
                            onClick: () =>
                              onModifyPayrollHourType({ field: 'isArchived', value: false, index })
                          }
                        ]}
                        style={{
                          ...styles.closeIconButton,
                          height: theme.spacing(2),
                          color: theme.palette.grayscale(80)
                        }}
                      />
                    )}
                  </Grid>
                </AccordionSummary>
              </MuiAccordion>
            </Box>
          </div>
        </div>
      )}
    </Draggable>
  );
};

PayrollHourTypeCard.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  isDragDisabled: PropTypes.bool,
  adpExportEnabled: PropTypes.bool,
  payrollHourTypes: PropTypes.array,
  billingHourTypeOptions: PropTypes.array,
  mapPayrollHourToBilling: PropTypes.bool,
  onModifyPayrollHourType: PropTypes.func,
  onRemovePayrollHourType: PropTypes.func
};

PayrollHourTypeCard.defaultProps = {
  item: {},
  index: 0,
  isDragDisabled: false,
  adpExportEnabled: false,
  payrollHourTypes: [],
  billingHourTypeOptions: [],
  mapPayrollHourToBilling: false,
  onModifyPayrollHourType: () => null,
  onRemovePayrollHourType: () => null
};

export default PayrollHourTypeCard;
