import compact from 'lodash/compact';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import {
  DEFAULT_VISIT_DURATION_IN_MIN,
  JobTypes,
  VisitStatusAttributes,
  VisitStatuses
} from '@dispatch/Dispatch.constants';
import { ElementSizes } from '@dispatch/Dispatch.styles';
import { getVisitStateMachine } from '@dispatch/stateMachines';

import {
  selectActualDuration,
  selectDisplayAddress,
  selectDndTransition,
  selectPosition,
  selectSaveTransition
} from './useVisitDetails.selectors';

const moment = extendMoment(Moment);

export const transformVisitDetails = ({ visit }) => {
  const Status =
    VisitStatuses.getBy(VisitStatusAttributes.serverValue, visit.status) || VisitStatuses.UNKNOWN;
  const visitStateMachine = getVisitStateMachine(visit.id, Status.key);
  const visitState = visitStateMachine.initialState;
  const actualDuration = selectActualDuration(visit.actualDuration);
  const propertyAddress = selectDisplayAddress(visit);
  const position = selectPosition(propertyAddress);

  return {
    __typename: visit?.__typename,
    id: visit?.id,
    actualDuration,
    companyName: visit?.job?.property?.companyName ?? '',
    customerId: visit?.job?.customerId ?? '',
    customerName: visit?.job?.customerName ?? '',
    customerRep: visit?.job?.customerRep,
    departmentId: visit.departmentId,
    departmentName: visit?.departmentName ?? '',
    description: visit?.description ?? '',
    detailsSent: visit?.detailsSent,
    dndTransition: selectDndTransition(visitState.nextEvents),
    extraTechs: compact(visit?.extraTechs?.items?.map(tech => tech?.mappedEntityId) || []),
    extraTechsNumber: visit?.extraTechsNumber,
    extraTechsRequired: visit?.extraTechsRequired,
    job: {
      id: visit?.job?.id ?? '',
      customIdentifier: visit?.job?.customIdentifier,
      jobNumber: visit?.job?.jobNumber ?? '',
      jobTypeInternal: visit?.job?.jobTypeInternal ?? JobTypes.JOB.value.serverValue,
      priority: visit?.job?.priority ?? '',
      status: visit?.job?.status ?? '',
      procurementStatus: visit?.job?.procurementStatus ?? '',
      purchaseOrders: visit?.job?.purchaseOrders ?? '',
      closeoutReport: visit?.job?.closeoutReport ?? false
    },
    nextEvents: visitState.nextEvents,
    onHoldReason: visit?.onHoldReason ?? '',
    prerequisites: visit?.prerequisites ?? '',
    prerequisitesAcknowledged: visit?.prerequisitesAcknowledged,
    primaryTechId: visit?.primaryTechs?.items?.[0]?.mappedEntityId,
    property: {
      id: visit?.job?.property?.id,
      customerPropertyName: visit?.job?.property?.companyName ?? '',
      address: propertyAddress,
      position
    },
    range: moment.range(
      moment.unix(visit.scheduledFor),
      moment.unix(visit.scheduledFor).add(actualDuration, 'minutes')
    ),
    saveTransition: selectSaveTransition(visitState.nextEvents),
    scheduledFor: visit?.scheduledFor,
    schedules: visit?.schedules?.items?.map(schedule => ({
      ...schedule,
      lastUpdatedDateTime: parseInt(schedule.lastUpdatedDateTime, 10) || 0,
      timeSheets: schedule?.timeSheets?.items
    })),
    serverVisit: visit,
    status: Status.key,
    Status,
    timesheetEntryBinders: {
      items: visit?.timesheetEntryBinders?.items ?? []
    },
    version: visit?.version,
    visitFlags: visit?.visitFlags,
    visitNumber: visit?.visitNumber ?? '',
    visitStateMachine,
    width: ((actualDuration || DEFAULT_VISIT_DURATION_IN_MIN) / 60) * ElementSizes.cellWidth
  };
};
