import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import BillableEventForm from './BillableEventForm.component';
import BillableEventFormV2 from './BillableEventFormV2.component';

const BillableEventFormContainer = props => {
  const isRecurringNves = useFlags()[FeatureFlags.RECURRING_NVES];
  if (isRecurringNves) {
    return <BillableEventFormV2 {...props} />;
  }
  return <BillableEventForm {...props} />;
};

export default BillableEventFormContainer;
