import { useCallback } from 'react';

import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { mutationOperations, updateTaskTemplateCacheForTaskTemplateSAQuery } from '../helper';

import {
  MergedTaskTemplateFragment,
  taskTemplateChecklistsOnServiceAgreement
} from './useTaskTemplateChecklistsOnServiceAgreement';

const ADD_TASK_TEMPLATE_IN_SA = gql`
  mutation addTaskTemplateToServiceAgreement(
    $propertyAssetId: String
    $customerPropertyId: String
    $serviceAgreementId: String!
    $taskTemplateChecklistId: String!
    $input: TaskTemplateCreateInput!) {
      addTaskTemplateToServiceAgreement(
        customerPropertyId: $customerPropertyId,
        serviceAgreementId: $serviceAgreementId,
        taskTemplateChecklistId: $taskTemplateChecklistId
        propertyAssetId: $propertyAssetId,
        input: $input) {
      ${MergedTaskTemplateFragment}
    }
  }
`;

export const useAddTaskTemplateFromServiceAgreement = ({
  serviceAgreementId,
  propertyAssetId,
  customerPropertyId,
  checklistId,
  ...options
}) => {
  const update = useCallback(
    (cache, { data: { addTaskTemplateToServiceAgreement } }) => {
      const variables = { serviceAgreementId };
      if (propertyAssetId) {
        variables.propertyAssetId = propertyAssetId;
      } else {
        variables.customerPropertyId = customerPropertyId;
      }

      const cachedData = cache.readQuery({
        query: taskTemplateChecklistsOnServiceAgreement,
        variables
      });

      if (!cachedData) return;

      const updatedTaskTemplateChecklists = updateTaskTemplateCacheForTaskTemplateSAQuery(
        cachedData,
        checklistId,
        addTaskTemplateToServiceAgreement,
        mutationOperations.ADD
      );

      cache.writeQuery({
        query: taskTemplateChecklistsOnServiceAgreement,
        variables,
        data: { taskTemplateChecklistsOnServiceAgreement: updatedTaskTemplateChecklists }
      });
    },
    [checklistId, customerPropertyId, propertyAssetId, serviceAgreementId]
  );

  return useExtendedMutation(ADD_TASK_TEMPLATE_IN_SA, {
    serializer: ({
      name,
      description,
      startDateTime,
      interval,
      numberOfOccurrences,
      laborEstimate,
      forms,
      partsInput,
      isSingleJob,
      taskTemplateChecklistId
    }) => {
      return {
        variables: {
          serviceAgreementId,
          propertyAssetId,
          customerPropertyId: propertyAssetId ? undefined : customerPropertyId,
          taskTemplateChecklistId,
          input: {
            name,
            description,
            startDateTime,
            interval,
            numberOfOccurrences,
            laborEstimate,
            formsInput: forms?.map(f => ({ id: f.id, isRequired: f.isRequired })),
            partsInput,
            isSingleJob
          }
        }
      };
    },
    update,
    onError: (transformedData, snackbarOn) =>
      snackbarOn('error', 'Unable to Add Task template to SA'),
    transform: data => data?.addTaskTemplateToServiceAgreement,
    ...options
  });
};
