import { sortBy } from 'lodash';

import * as yup from 'yup';

import { AccountType } from 'utils/constants';
import getSelectOptions from 'utils/constructSelectOptions';

export default function getDropDownOptions(company = {}) {
  let customerPropertyTypes = sortBy(company?.customerPropertyTypes?.items, 'sortOrder');
  customerPropertyTypes = getSelectOptions(customerPropertyTypes, 'tagName');

  let taxRateOptions = [{ label: 'None', value: '' }];
  taxRateOptions = taxRateOptions.concat(
    (company?.taxRates?.items || [])
      .filter(t => t.accountType !== AccountType.AP)
      .map(taxCode => {
        const label = `${taxCode?.name} - ${taxCode?.taxRate}`;
        return {
          value: taxCode?.id,
          label
        };
      })
  );

  const sortedPriceBooks = (company?.priceBooks?.items || []).sort((a, b) =>
    a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : -1
  );
  const priceBookOptions = getSelectOptions(sortedPriceBooks, 'name');
  return {
    customerPropertyTypes,
    taxRateOptions,
    priceBookOptions
  };
}

export const assetPayload = ({
  assetName,
  assetTypeId,
  make,
  modelNumber,
  serialNo,
  installDate,
  imageUrl,
  propertyZone,
  ownerIdentifier,
  internalIdentifier,
  location,
  id,
  version
}) => ({
  assetName,
  assetTypeId,
  make,
  modelNumber,
  serialNo,
  installDate,
  imageUrl,
  propertyZone,
  ownerIdentifier,
  internalIdentifier,
  location,
  id,
  version
});

const getYupFunctionBasedOnFieldType = fieldName => {
  if (fieldName.includes('date')) return yup.number();
  if (fieldName.includes('number')) return yup.number();
  if (fieldName.includes('checkBox')) return yup.boolean();
  return yup.string();
};

export const convertionJsonValidationToObjValidation = ({ validation, validationErrors }) => {
  const validationFields = {};
  const requiredArray = validation?.required || [];
  requiredArray.forEach(attr => {
    validationFields[attr] = getYupFunctionBasedOnFieldType(attr)
      .nullable()
      .required(validationErrors[attr]?.required);
  });
  return validationFields;
};
