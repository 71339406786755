import gql from 'graphql-tag';

const getCustomerPropertyInfoById = gql`
  query getCustomerPropertyInfoById($id: String!) {
    getCustomerPropertyById(id: $id) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      parentId
      parentSortKey
      companyName
      customerPropertyTypeValue
      customerPropertyTypeSortKey
      accountNumber
      status
      email
      priceBookId
      priceBook(entityConnection: "PriceBook") {
        name
        id
      }
      propertyAssets {
        items {
          id
          assetName
        }
      }
      phonePrimary
      amountNotToExceed
      sameAddress
      version
      isActive
      isTaxable
      billTo
      taxRateId
      taxRate(entityConnection: "TaxRate") {
        id
        name
        taxRate
      }
      billingCustomerId
      billingCustomer(entityConnection: "Customer") {
        id
        customerName
        isActive
        isTaxable
        taxRateId
        companyAddresses(entityConnection: "CompanyAddress") {
          items {
            id
            tenantId
            tenantCompanyId
            partitionKey
            sortKey
            hierarchy
            entityType
            addressLine1
            addressLine2
            longitude
            latitude
            state
            city
            zipcode
            addressType
            entityType
            sortKey
            billTo
          }
        }
      }
      companyAddresses(entityConnection: "CompanyAddress") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          addressLine1
          addressLine2
          state
          city
          zipcode
          addressType
          entityType
          sortKey
          billTo
          longitude
          latitude
        }
      }
      tenantReps(entityConnection: "Employee") {
        items {
          id
          mappedEntity {
            ... on Employee {
              id
              firstName
              lastName
              name
              status
            }
          }
        }
      }
      notes(entityConnection: "Note") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          subject
          note
          createdBy
          lastUpdatedDate
          lastUpdatedDateTime
          lastUpdatedBy
          version
        }
      }
      jobs: jobsView(entityConnection: "Job") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          customIdentifier
          jobNumber
          jobTypeName
          jobTypeInternal
          priority
          status
          customerName
          customerPropertyName
        }
      }
      parentEntity {
        ... on Customer {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          customerName
          isActive
          isTaxable
          taxRateId
          priceBook {
            name
            id
          }
          companyAddresses(entityConnection: "CompanyAddress") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              addressLine1
              addressLine2
              state
              city
              zipcode
              addressType
              entityType
              sortKey
              billTo
              longitude
              latitude
            }
          }
          tenantReps(entityConnection: "Employee") {
            items {
              id
              mappedEntity {
                ... on Employee {
                  id
                  firstName
                  lastName
                  name
                  status
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default getCustomerPropertyInfoById;
