import React from 'react';

import { calculateMarkup, calculateUnitPriceWithMarkup } from '@BuildHero/math';
import { Checkbox, CurrencyInput, Input, NumberInput, PercentageInput } from '@BuildHero/sergeant';

import { convertToCurrencyString } from 'utils';
import { InvoiceStatus } from 'utils/constants';

import { convertForMathLib } from 'utils/mathLibrary';

import { getVisitDisplayText } from '../../../../../JobCloseout/utils';

import { INVENTORY_TABLE_COLUMNS } from './constants';

/* START INVENTORY & MATERIAL CONFIG */
const getPartQuantity = part => {
  return part?.invoiceItem?.invoice?.status !== InvoiceStatus.VOID
    ? part?.invoiceItem?.quantity ?? part.quantity
    : part.quantity;
};

const mapInventoryPartsToRow = (visit, companyTimezone, partFromJobReport) => {
  const inventoryPartsMap = [];
  visit?.inventoryParts?.items?.forEach(inventoryPart => {
    const visitTag = partFromJobReport
      ? inventoryPart?.source
      : getVisitDisplayText(visit, companyTimezone);

    inventoryPartsMap.push({
      source: visitTag,
      itemName: inventoryPart.itemName?.trim(),
      description: inventoryPart.description?.trim(),
      quantity: inventoryPart.quantity,
      unitOfMeasure: inventoryPart.unitOfMeasure?.trim(),
      unitCost: inventoryPart.unitCost,
      markup: inventoryPart.markup,
      unitPrice: inventoryPart.unitPrice,
      subtotal: inventoryPart.unitPrice * getPartQuantity(inventoryPart),
      id: inventoryPart.id,
      priceBookEntryId: inventoryPart.priceBookEntryId,
      taxable: inventoryPart.taxable,
      tax: inventoryPart.tax,
      taskIncludedInGroup: null
    });
  });
  return inventoryPartsMap;
};

const updateDynamicInventoryFields = (row, field, value, partsFormData, setPartsFormData) => {
  let updatedFieldRow;
  switch (field) {
    case INVENTORY_TABLE_COLUMNS.QUANTITY:
      updatedFieldRow = { ...row, subtotal: row.unitPrice * value };
      break;
    case INVENTORY_TABLE_COLUMNS.UNIT_COST:
      {
        const unitPrice = convertForMathLib(calculateUnitPriceWithMarkup, value, row?.markup);
        updatedFieldRow = {
          ...row,
          unitPrice,
          subtotal: unitPrice * row.quantity
        };
      }
      break;
    case INVENTORY_TABLE_COLUMNS.MARKUP:
      {
        const unitPrice = convertForMathLib(calculateUnitPriceWithMarkup, row?.unitCost, value);
        updatedFieldRow = {
          ...row,
          unitPrice,
          subtotal: unitPrice * row.quantity
        };
      }
      break;
    case INVENTORY_TABLE_COLUMNS.UNIT_PRICE:
      {
        const markup = convertForMathLib(calculateMarkup, row?.unitCost, value);
        updatedFieldRow = {
          ...row,
          markup,
          subtotal: value * row.quantity
        };
      }
      break;
    default:
      updatedFieldRow = row;
      break;
  }
  if (partsFormData) {
    const newMappedItems = partsFormData?.map(invPart => {
      if (invPart.id === row.id) {
        return {
          ...invPart,
          ...updatedFieldRow,
          [field]: value
        };
      }
      return invPart;
    });
    setPartsFormData(newMappedItems);
  }
};

const onInventoryPartsChange = (row, field, value, partsFormData, setPartsFormData) => {
  if (partsFormData) {
    const newMappedItems = partsFormData?.map(invPart => {
      if (invPart.id === row.id) {
        return {
          ...invPart,
          [field]: value
        };
      }
      return invPart;
    });
    setPartsFormData(newMappedItems);
  }
};

const taskItemAlreadySelected = (row, taskGroupNumber) => {
  return row?.taskIncludedInGroup === null || row?.taskIncludedInGroup === taskGroupNumber;
};

export const getPartsRows = (
  taskGroupNumber,
  incurredCostsData,
  partsFormData,
  setPartsFormData,
  companyTimezone
) => {
  if (incurredCostsData?.visits || incurredCostsData?.inventoryParts) {
    if (!partsFormData) {
      const inventoryPartsMap = [];
      incurredCostsData?.visits?.items
        ?.filter(visit => visit?.inventoryParts?.items?.length > 0)
        ?.forEach(visit => {
          inventoryPartsMap.push(...mapInventoryPartsToRow(visit, companyTimezone));
        });

      inventoryPartsMap.push(...mapInventoryPartsToRow(incurredCostsData, companyTimezone, true));
      setPartsFormData(inventoryPartsMap);
    }
  }
  if (partsFormData) {
    return partsFormData
      .filter(row => taskItemAlreadySelected(row, taskGroupNumber))
      .map(row => {
        return {
          checked: (
            <Checkbox
              checked={row?.taskIncludedInGroup !== null}
              color="secondary"
              onClick={() => {
                onInventoryPartsChange(
                  row,
                  'taskIncludedInGroup',
                  row?.taskIncludedInGroup === null ? taskGroupNumber : null,
                  partsFormData,
                  setPartsFormData
                );
              }}
            />
          ),
          source: row.source,
          itemName: row.itemName,
          [INVENTORY_TABLE_COLUMNS.DESCRIPTION]: (
            <Input
              placeholder="Description"
              value={row?.description}
              onChange={e => {
                onInventoryPartsChange(
                  row,
                  INVENTORY_TABLE_COLUMNS.DESCRIPTION,
                  e?.target?.value,
                  partsFormData,
                  setPartsFormData
                );
              }}
            />
          ),
          [INVENTORY_TABLE_COLUMNS.QUANTITY]: (
            <NumberInput
              error={row?.quantity <= 0}
              inputMode="numeric"
              value={row?.quantity}
              onBlur={value => {
                updateDynamicInventoryFields(
                  row,
                  INVENTORY_TABLE_COLUMNS.QUANTITY,
                  value,
                  partsFormData,
                  setPartsFormData
                );
              }}
              onChange={value => {
                onInventoryPartsChange(
                  row,
                  INVENTORY_TABLE_COLUMNS.QUANTITY,
                  value,
                  partsFormData,
                  setPartsFormData
                );
              }}
            />
          ),
          [INVENTORY_TABLE_COLUMNS.UNIT_OF_MEASURE]: (
            <Input
              value={row?.unitOfMeasure}
              onBlur={e => {
                updateDynamicInventoryFields(
                  row,
                  INVENTORY_TABLE_COLUMNS.UNIT_OF_MEASURE,
                  e?.target?.value,
                  partsFormData,
                  setPartsFormData
                );
              }}
              onChange={e => {
                onInventoryPartsChange(
                  row,
                  INVENTORY_TABLE_COLUMNS.UNIT_OF_MEASURE,
                  e?.target?.value,
                  partsFormData,
                  setPartsFormData
                );
              }}
            />
          ),
          [INVENTORY_TABLE_COLUMNS.UNIT_COST]: (
            <CurrencyInput
              error={row?.unitCost <= 0}
              inputMode="decimal"
              value={row?.unitCost}
              onBlur={value => {
                updateDynamicInventoryFields(
                  row,
                  INVENTORY_TABLE_COLUMNS.UNIT_COST,
                  value,
                  partsFormData,
                  setPartsFormData
                );
              }}
              onChange={value => {
                onInventoryPartsChange(
                  row,
                  INVENTORY_TABLE_COLUMNS.UNIT_COST,
                  value,
                  partsFormData,
                  setPartsFormData
                );
              }}
            />
          ),
          [INVENTORY_TABLE_COLUMNS.MARKUP]: (
            <PercentageInput
              error={row?.markup <= 0}
              value={row?.markup}
              onBlur={value => {
                updateDynamicInventoryFields(
                  row,
                  INVENTORY_TABLE_COLUMNS.MARKUP,
                  value,
                  partsFormData,
                  setPartsFormData
                );
              }}
              onChange={value => {
                onInventoryPartsChange(
                  row,
                  INVENTORY_TABLE_COLUMNS.MARKUP,
                  value,
                  partsFormData,
                  setPartsFormData
                );
              }}
            />
          ),
          [INVENTORY_TABLE_COLUMNS.UNIT_PRICE]: (
            <CurrencyInput
              error={row?.unitPrice <= 0}
              value={row?.unitPrice}
              onBlur={value => {
                updateDynamicInventoryFields(
                  row,
                  INVENTORY_TABLE_COLUMNS.UNIT_PRICE,
                  value,
                  partsFormData,
                  setPartsFormData
                );
              }}
              onChange={value => {
                onInventoryPartsChange(
                  row,
                  INVENTORY_TABLE_COLUMNS.UNIT_PRICE,
                  value,
                  partsFormData,
                  setPartsFormData
                );
              }}
            />
          ),
          subtotal: convertToCurrencyString(row.subtotal)
        };
      });
  }
  return [];
};

export const getPartsColumns = (taskGroupNumber, partsFormData, setPartsFormData) => [
  {
    field: 'checked',
    headerName: (
      <Checkbox
        color="secondary"
        onClick={event => {
          setPartsFormData(
            partsFormData?.map(partsAndMaterial => {
              if (event.target.checked && partsAndMaterial.taskIncludedInGroup === null) {
                return {
                  ...partsAndMaterial,
                  taskIncludedInGroup: taskGroupNumber
                };
              }
              if (
                !event.target.checked &&
                partsAndMaterial.taskIncludedInGroup === taskGroupNumber
              ) {
                return {
                  ...partsAndMaterial,
                  taskIncludedInGroup: null
                };
              }
              return partsAndMaterial;
            })
          );
        }}
      />
    ),
    width: 40
  },
  { field: 'source', headerName: 'Source', width: 'calc(10% - 5px)' },
  { field: 'itemName', headerName: 'Item Name', width: 'calc(15% - 5px)' },
  { field: 'description', headerName: 'Description', width: '20%' },
  { field: 'quantity', headerName: 'Qty', width: 'calc(5% - 5px)' },
  { field: 'unitOfMeasure', headerName: 'UOM', width: 'calc(10% - 5px)' },
  { field: 'unitCost', headerName: 'Unit Cost', width: 'calc(10% - 5px)' },
  { field: 'markup', headerName: 'Markup', width: 'calc(10% - 5px)' },
  { field: 'unitPrice', headerName: 'Unit Price', width: 'calc(10% - 5px)' },
  { field: 'subtotal', headerName: 'Subtotal', width: 'calc(10% - 5px)' }
];
/* END INVENTORY & MATERIAL CONFIG */
