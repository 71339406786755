import React from 'react';

import { JobTypes } from '@dispatch/Dispatch.constants';
import { JobPropTypes } from '@dispatch/Dispatch.props';

export const selectJobDisplayText = ({ job }) => {
  if (!job) return '';

  const label =
    job?.jobTypeInternal === JobTypes.MAINTENANCE.value.serverValue
      ? JobTypes.MAINTENANCE.value.displayValue
      : JobTypes.JOB.value.displayValue;

  return `${label} ${job?.customIdentifier || job?.jobNumber}`;
};

const JobNumber = ({ job }) => <>{selectJobDisplayText({ job })}</>;

JobNumber.propTypes = {
  job: JobPropTypes.isRequired
};

export default JobNumber;
