import { useMemo } from 'react';

const flattenRows = rows => {
  return rows.reduce(
    (acc, row) => [...acc, ...(row.__groupData ? flattenRows(row.__groupData) : [row])],
    []
  );
};

export const useRetrievedValue = ({ value, valueGetter, row, rows, colDef, isTotal }) => {
  return useMemo(() => {
    if (isTotal) {
      const flattenedRows = flattenRows(rows);
      return valueGetter?.({ value, row, rows: flattenedRows, colDef }) || value;
    }

    return valueGetter?.({ value, row, rows, colDef }) || value;
  }, [value, valueGetter, row, rows, colDef]);
};

export const useFormattedValue = ({ valueFormatter, retrievedValue, row, rows, colDef }) => {
  return useMemo(() => {
    return valueFormatter?.({ value: retrievedValue, row, rows, colDef }) || retrievedValue;
  }, [valueFormatter, retrievedValue, row, rows, colDef]);
};
