import React from 'react';

import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CancelIcon from '@material-ui/icons/Cancel';
import classNames from 'classnames';
import NativeSelect, { createFilter } from 'react-select';

import styles from './styles';

function NoOptionsMessage(props) {
  return (
    <Typography
      className={props.selectProps.classes.noOptionsMessage}
      color="textSecondary"
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      component="div"
      selected={props.isFocused}
      style={{
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.29,
        letterSpacing: 'normal',
        // backgroundColor: "#f2f2f2",
        color: '#3f3f3f',
        zIndex: 3
      }}
      testingid="menuItem"
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      className={props.selectProps.classes.placeholder}
      color="textSecondary"
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      deleteIcon={<CancelIcon {...props.removeProps} />}
      label={props.children}
      tabIndex={-1}
      onDelete={props.removeProps.onClick}
    />
  );
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputLabelProps={{ shrink: !!props.selectProps.value || !!props.selectProps.inputValue }}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      variant="filled"
      {...props.selectProps.textFieldProps}
    />
  );
}
function Menu(props) {
  return (
    <Paper className={props.selectProps.classes.paper} square {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  ClearIndicator: () => '',
  IndicatorSeparator: () => '',
  DropdownIndicator: () => <ArrowDropDownIcon style={{ marginTop: -10, color: '#646464' }} />
};

class IntegrationReactSelect extends React.Component {
  handleOnChange = selectedOption => {
    const { field, form } = this.props;
    form.setFieldValue(field.name, selectedOption.label, false);
    this.props.form.values && this.props.form.values.onTermsSelect(selectedOption);
  };

  render() {
    const { field, form, classes, options } = this.props;
    const displayDataArray = options.valueSet || [];
    const helperText =
      form.errors && form.errors[field.name] ? form.errors && form.errors[field.name] : ' ';

    const selectStyles = {
      menu: cstyles => ({
        ...cstyles,
        zIndex: 5,
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.29,
        letterSpacing: 'normal',
        color: '#3f3f3f',
        maxHeight: 160
      }),
      option: (customstyles, { isFocused, isSelected }) => ({
        ...customstyles,
        backgroundColor: isFocused ? '#f2f2f2' : isSelected ? '#f2f2f2' : null,
        color: isFocused ? '#3f3f3f' : isSelected ? '#3f3f3f' : null,
        maxHeight: 160
      }),
      menuList: custstyles => ({
        ...custstyles,
        left: 0,
        top: 0,
        maxHeight: 160
      })
    };

    const selectOptionValues = [];
    if (displayDataArray) {
      displayDataArray.forEach(item => {
        selectOptionValues.push({ label: item.label, value: item.value });
      });
    }

    const defaultValueList = selectOptionValues.filter(item => item.label === field.value);
    const defaultValue = defaultValueList.length > 0 ? defaultValueList[0] : '';

    const filterConfig = {
      ignoreCase: true,
      trim: true,
      matchFrom: 'start'
    };
    return (
      <>
        <FormControl
          className={classes.formControl}
          error={form.errors && form.errors[field.name]}
          fullWidth
          variant="filled"
        >
          <NativeSelect
            classes={classes}
            components={components}
            filterOption={createFilter(filterConfig)}
            inputProps={(testingid = options.testingid)}
            isClearable
            name={field.name}
            options={selectOptionValues}
            placeholder=""
            styles={selectStyles}
            textFieldProps={{
              label: options.label,
              error: form.errors && form.errors[field.name]
            }}
            value={defaultValue}
            onChange={this.handleOnChange}
          />
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      </>
    );
  }
}

export default withStyles(styles)(IntegrationReactSelect);
