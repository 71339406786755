import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import { getDatafromPath } from 'utils';

import styles from './styles';

// Renders Buildhero branded checkbox for form inputs
const MuiCheckBoxArray = ({ field, form, fieldProps, classes, specialbehaviour, ...rest }) => {
  const handleCheckboxChange = (name, index) => event => {
    if (
      event.target.checked &&
      !(form.values[fieldProps.name] && form.values[fieldProps.name].includes(name.dataItem))
    ) {
      rest.push(name.dataItem);
    }

    if (!event.target.checked && form.values[fieldProps.name].includes(name.dataItem)) {
      rest.remove(index);
    }
  };

  const {
    queryResult,
    queryPath,
    dependentFieldName,
    dependentQueryPath,
    dependentReferenceKey
  } = specialbehaviour;

  if (!queryResult) {
    return null;
  }
  // let localResult = queryResult;
  let displayDataArray = [];
  // if dependent, pick the values from dependentfield
  // get path of dependent array and pull the selected values array
  if (dependentFieldName) {
    let dependentArray = null;
    const localDependentQueryPathSplit = dependentQueryPath.split('.');
    localDependentQueryPathSplit.forEach(element => {
      if (dependentArray === null) dependentArray = queryResult[element];
      else dependentArray = dependentArray[element];
    });

    dependentArray.forEach(
      //  item => item[dependentReferenceKey] === form.values[dependentFieldName][dependentReferenceKey]
      item =>
        form.values[dependentFieldName].forEach(dependentItem => {
          if (dependentItem[dependentReferenceKey] === item[dependentReferenceKey]) {
            const values = getDatafromPath(item, queryPath);
            displayDataArray = [...displayDataArray, ...values];
          }
        })
    );
  }

  // when parent changes ensure unwanted values are removed
  form.values[fieldProps.name].forEach((item, index) => {
    if (!(displayDataArray && displayDataArray.includes(item))) {
      rest.remove(index);
    }
  });

  return (
    <>
      {displayDataArray &&
        displayDataArray.map((dataItem, index) => (
          <Grid item key={`gridCheckbox${dataItem}`} lg={12} md={12} sm={12} xl={12} xs={12}>
            <FormControlLabel
              className={classes.checkboxLabel}
              control={
                <Checkbox
                  checked={
                    form.values[fieldProps.name] && form.values[fieldProps.name].includes(dataItem)
                  }
                  className={classes.checkboxStyle}
                  key={`checkbox${dataItem}`}
                  value={`${form.values[fieldProps.name] &&
                    form.values[fieldProps.name].includes(dataItem)}`}
                  onChange={handleCheckboxChange({ dataItem, index })}
                />
              }
              key={`label${dataItem}`}
              label={dataItem}
            />
          </Grid>
        ))}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(MuiCheckBoxArray);
