import React, { useEffect } from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';

import { sentryMessage } from 'services/Logger';

const CorruptData = ({ reason, corruptData }) => {
  const classes = useStyles();

  useEffect(() => {
    sentryMessage(reason, corruptData);
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <ErrorIcon />
        <Typography variant="h4">There was an issue. </Typography>
        <div className={classes.text}>
          <Typography color="inherit" variant="body2">
            BuildOps has been informed.
          </Typography>
          <Typography color="inherit" variant="body2">
            Please contact BuildOps support for further assistance.
          </Typography>
          <Typography color="inherit" variant="body2">
            support@buildops.com
          </Typography>
          <Typography color="inherit" variant="body2">
            323-487-8882
          </Typography>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    minHeight: '200px',
    justifyContent: 'center',
    border: '3px #8f0000 solid'
  },
  content: {
    padding: '50px',
    color: '#8f0000',
    textAlign: 'center'
  },
  text: {
    color: 'grey'
  }
}));

export default CorruptData;
