/* eslint-disable no-confusing-arrow */
import React, { Component } from 'react';

import { Fade, Grid, Paper, Popper, Typography } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import algoliasearch from 'algoliasearch/lite';
import {
  connectCurrentRefinements,
  connectRefinementList,
  connectSearchBox,
  connectStateResults,
  InstantSearch
} from 'react-instantsearch-dom';

import styles from '../styles';

import './style.css';
import RefinementList from './RefinementList';

import ENV from 'configs/env';
import configForEnvironment from 'configs/aws-exports';

import Results from './Results';

import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';

const ClearRefinementsFn = ({ items, refine, classes, changeState }) => (
  <IconButton
    aria-label="Close"
    classes={{ root: classes.clearSearchButton }}
    style={{ display: !items.length ? 'none' : 'block', backgroundColor: 'transparent' }}
    onClick={() => {
      refine(items);
      changeState({ name: 'inputValue', value: '' });
    }}
  >
    <CloseIcon classes={{ root: classes.clearIcon }} disabled={!items.length} />
  </IconButton>
);
const CustomRefinementList = connectRefinementList(RefinementList);

const WrappedRefinementList = props => (
  <Grid item style={{ height: '100%' }} xs={12}>
    <Typography style={{ padding: 10 }} variant="body2">
      {props.title}
    </Typography>
    <CustomRefinementList
      attribute={props.attribute}
      configure={props.configure}
      limit={4}
      operator="or"
      showMore
    />
  </Grid>
);

const CustomClearRefinements = connectCurrentRefinements(ClearRefinementsFn);

class SearchComponent extends Component {
  // eslint-disable-next-line react/sort-comp
  constructor(props) {
    super(props);
    const searchClient = algoliasearch(
      configForEnvironment(ENV).algoliaAppKey,
      this.props.searchKey
    );
    this.searchClient = searchClient;
    this.state = {
      closeModal: false,
      focus: false,
      inputValue: ''
    };
  }

  searchResults = connectStateResults(({ searchState }) => {
    const open = searchState !== '' && !this.state.closeModal;
    return searchState && searchState.query ? (
      <Popper
        anchorEl={() => document.getElementById('globalSearch')}
        disablePortal
        id="searchResults"
        open={open}
        placement="bottom-end"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={0}>
            <Paper
              style={{
                backgroundColor: '#fafafa',
                width: window.innerWidth < 600 ? '100%' : window.innerWidth * 0.64,
                maxHeight: window.innerHeight * 0.7,
                overflowY: 'auto',
                marginTop: 15,
                boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.5)'
              }}
            >
              <Results
                closeSearchResultModal={this.handleCloseModal}
                query={searchState.query}
                WrappedRefinementList={WrappedRefinementList}
              />
            </Paper>
          </Fade>
        )}
      </Popper>
    ) : null;
  });

  SearchBox = ({ currentRefinement, refine, classes, isSearchStalled }) => (
    <Grid item>
      <InputBase
        autoFocus={this.state.focus}
        classes={{
          root: classes.searchInputRoot,
          input: classes.searchInput
        }}
        inputProps={{ 'aria-label': 'Search' }}
        placeholder={isSearchStalled ? 'Loading...' : 'Search'}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon className={classes.searchIcon} />
          </InputAdornment>
        }
        style={{ width: '100%' }}
        value={currentRefinement || this.state.inputValue}
        onChange={event => refine(event.currentTarget.value)}
        onClick={() => {
          if (this.state.closeModal) {
            this.setState({ closeModal: false, focus: true });
          }
        }}
      />
    </Grid>
  );

  handleCloseModal = () => {
    // adding the condition as if you click anywhere in the page, it will always trigger
    if (!this.state.closeModal) {
      this.setState({ closeModal: true, inputValue: '', focus: false });
    }
  };

  changeState = ({ name, value }) => this.setState({ [name]: value });

  render() {
    const { classes } = this.props;
    const CustomSearchBox = connectSearchBox(this.SearchBox);
    const CustomResults = this.searchResults;
    return (
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => this.handleCloseModal()}>
        <InstantSearch indexName={defaultSearchIndex} searchClient={this.searchClient} showReset>
          <Grid
            alignItems="flex-start"
            container
            direction="row"
            id="globalSearch"
            justify="flex-start"
          >
            <Grid item xs={12}>
              <CustomSearchBox classes={classes} />
            </Grid>
            <CustomClearRefinements changeState={this.changeState} classes={classes} clearsQuery />
          </Grid>
          <CustomResults />
        </InstantSearch>
      </ClickAwayListener>
    );
  }
}

export default withStyles(styles, { withTheme: true })(React.memo(SearchComponent));
