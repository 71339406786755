import React from 'react';

import { theme } from '@BuildHero/sergeant';
import { object } from 'prop-types';

import JobNumber from '@dispatch/components/JobNumber';
import { VisitStatuses } from '@dispatch/Dispatch.constants';

import { useStyles } from './PreviewVisitCard.styles';

const PreviewVisitCard = ({ visit }) => {
  const classes = useStyles();
  const status = VisitStatuses.get(visit?.status)?.value || {};
  return (
    <div
      className={classes.container}
      style={{
        backgroundColor: status.secondaryColor || theme.palette.grayscale(98)
      }}
    >
      <div className={classes.header} style={{ background: status.primaryColor }}>
        {visit?.job?.priority && <div className={classes.priority}>{visit?.job?.priority}</div>}
        <div className={classes.status}>{status.displayValue}</div>
      </div>
      <div className={classes.body}>
        <div>{visit?.companyName}</div>
        <div className={classes.property}>{visit?.property?.customerPropertyName}</div>
        <div>
          <JobNumber job={visit?.job} /> - Visit {visit?.visitNumber}
        </div>
      </div>
    </div>
  );
};

PreviewVisitCard.propTypes = {
  visit: object.isRequired
};

export default PreviewVisitCard;
