import React, { useEffect, useState } from 'react';

import { InlineAlert, InlineAlertTypes, Switch, ThemeProvider, TV, TW } from '@BuildHero/sergeant';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import { makeUseAxios } from 'axios-hooks';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SearchBar from '@pm/components/APISearchComponents/SearchBar';
import WrapTable from 'components/WrapTable';
import { axiosInstancePMAPI as instance } from 'configs/http-client';
import getTableColumn from 'meta/ProjectManagement/ProjectSettings/usersTable';
import { snackbarOn } from 'redux/actions/globalActions';

import { departmentSearch } from 'services/API/department';
import { projectChange } from 'services/API/project';
import { useGetProjectById } from 'services/APIHooks';
import { useFetchEmployees } from 'services/APIHooks/employee';
import { useGetProjectPermissionByProjectId } from 'services/APIHooks/projectPermission';

const useAxios = makeUseAxios({ axios: instance });
const useStyles = makeStyles(() => ({
  root: {
    margin: '31px 0px 20px 17px',
    display: 'flex',
    gap: 15
  },
  switchContainer: {},
  tableHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  tableHeader: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  tableBodyContainer: {
    maxWidth: 550
  },
  tableFilterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 550,
    marginBottom: 10
  },
  filterSearchBarsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px'
  },
  bigSearchBar: {},
  toggleAllContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: 150
  },
  projectVisitsToggle: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: '2rem',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15
  },
  alertContainer: {
    padding: 30,
    borderLeft: '1px solid #eee',
    maxWidth: 600
  },
  alertStyle: {
    '& *:not(svg)': { fontSize: 14 } // the "style" prop wasn't working for InlineAlert
  }
}));

const Users = props => {
  const classes = useStyles();
  const { user, projectId } = props;
  const [toggleAllSwitch, setToggleAllSwitch] = useState(true);
  const [needRefresh, setNeedRefresh] = useState(false);
  const [tableFilter, setTableFilter] = useState({ id: null, value: null });
  const [projectVisitsToggle, setProjectVisitsToggle] = useState(true);
  const [{ loading: toggleAllOnLoading }, executeToggleAllOn] = useAxios(
    { url: `projectuserpermissions/allon`, method: 'post' },
    { manual: true }
  );

  const [{ loading: toggleAllOffLoading }, executeToggleAllOff] = useAxios(
    { url: `projectuserpermissions/alloff`, method: 'post' },
    { manual: true }
  );

  const [{ data: projectData }] = useGetProjectById(projectId, { depends: [needRefresh] });

  useEffect(() => {
    if (projectData) setProjectVisitsToggle(projectData?.techsCanEnterTime);
  }, [projectData]);

  const [{ data: employees, loading: loadingUser, error: errorUser }] = useFetchEmployees({
    skip: !projectId,
    depends: [tableFilter.id],
    transform: data => {
      let filteredUsers;
      const validUsers = [];

      if (tableFilter.id) {
        filteredUsers = data.filter(employee =>
          employee.departments.some(department => department.id === tableFilter.id)
        );
      } else {
        filteredUsers = data;
      }

      filteredUsers.sort((a, b) => (a.name > b.name ? 1 : -1));
      filteredUsers.forEach(employee => {
        if (employee.isActive && (employee.firstName || employee.lastName)) {
          validUsers.push({
            id: employee.id,
            firstName: `${employee.firstName ?? ''}`,
            lastName: `${employee.lastName ?? ''}`,
            name: `${employee.firstName ?? ''} ${employee.lastName ?? ''}`,
            profilePictureUrl: `${employee.profilePictureUrl ?? ''}`,
            projectId,
            employeeId: employee.id
          });
        }
      });
      return validUsers;
    }
  });

  const [
    { data: users, loading: loadingPermission, error: errorPermission }
  ] = useGetProjectPermissionByProjectId(projectId, {
    skip: !projectId,
    depends: [loadingUser, needRefresh, toggleAllOnLoading, toggleAllOffLoading],
    transform: permissionData => {
      return employees.map(employee => {
        const permission = find(permissionData, { employeeId: `${employee.id}` });
        return {
          id: employee.id,
          profileData: {
            firstName: `${employee.firstName ?? ''}`,
            lastName: `${employee.lastName ?? ''}`,
            name: `${employee.firstName ?? ''} ${employee.lastName ?? ''}`,
            profilePictureUrl: `${employee.profilePictureUrl ?? ''}`,
            employeeId: employee.id
          },
          switchData: {
            projectId,
            employeeId: employee.id,
            permissionId: permission?.id ?? null,
            permission: !permission?.id
          }
        };
      });
    }
  });

  const handleSwitchToggle = () => {
    setNeedRefresh(!needRefresh);
  };

  const handleProjectVisitToggle = () => {
    projectChange(projectId, { techsCanEnterTime: !projectVisitsToggle }).then(updatedProject => {
      setProjectVisitsToggle(updatedProject?.techsCanEnterTime);
    });
  };

  const handleToggleAll = async event => {
    if (event.target.checked) {
      await executeToggleAllOn({ data: { projectId, employeeId: user.employeeId } });
      setToggleAllSwitch(true);
    } else {
      await executeToggleAllOff({
        data: {
          projectId,
          employeeIds: users
            .filter(employee => employee.id !== user.employeeId)
            .map(data => data.id)
        }
      });
      setToggleAllSwitch(false);
    }
  };

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.tableHeaderContainer}>
          <Typography className={classes.tableHeader}>User Permissions</Typography>
          <div />
        </div>
        <div className={classes.tableFilterContainer}>
          <div className={classes.filterSearchBarsContainer}>
            <SearchBar
              className={classes.bigSearchBar}
              options={{
                placeholder: 'Filter by Department',
                resultFormatFunction: department => department.tagName,
                searchFunction: departmentSearch,
                sortFunction: (a, b) => (a.tagName > b.tagName ? 1 : -1),
                searchColumns: ['tagName'],
                disabled: loadingUser || loadingPermission,
                onSelect: selection =>
                  selection?.tagName
                    ? setTableFilter({ id: selection?.id, value: selection?.tagName })
                    : setTableFilter({ id: null, value: null })
              }}
            />
          </div>
          <div className={classes.toggleAllContainer}>
            <Typography className={classes.switchLabel}>Toggle All</Typography>
            <ThemeProvider>
              <Switch
                checked={toggleAllSwitch}
                disabled={loadingUser || loadingPermission}
                onChange={handleToggleAll}
              />
            </ThemeProvider>
          </div>
        </div>
        <div className={classes.tableBodyContainer}>
          <WrapTable
            columns={getTableColumn({ handleSwitchToggle })}
            error={errorPermission || errorUser}
            loading={loadingUser}
            loadingRows={10}
            lockRowHeight={false}
            noDataMessage="No Users found"
            rows={users}
          />
        </div>
      </div>
      <div className={classes.alertContainer}>
        <div className={classes.projectVisitsToggle}>
          <Typography variant={TV.BASE} weight={TW.BOLD}>
            Allow technicians to enter the time for Project Visits
          </Typography>
          <ThemeProvider>
            <Switch
              checked={projectVisitsToggle}
              disabled={loadingUser || loadingPermission}
              onChange={handleProjectVisitToggle}
            />
          </ThemeProvider>
        </div>
        <div className={classes.alertStyle}>
          <InlineAlert
            icon={WarningIcon}
            message="Changes to this permission will take effect for any future Project Visits, but will
              not apply to Project Visits already scheduled for today."
            type={InlineAlertTypes.YELLOW}
          />
        </div>
      </div>
    </div>
  );
};

Users.propTypes = {
  projectId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});
const ReduxConnectedUsers = connect(mapStateToProps, mapDispatcherToProps)(Users);

export default ReduxConnectedUsers;
