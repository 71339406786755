import { Auth } from 'aws-amplify';

import AmplifyService from 'services/AmplifyService';

import mutationQuery from '../../graphql/common/mutations/batchCreateEntityData';
import listTenantSettings from '../../graphql/common/queries/getTenantSettings';
import createTenant from '../../graphql/onboarding/mutations/createTenant';
import getTenants from '../../graphql/onboarding/queries/getTenants';
import getTenantUsers from '../../graphql/onboarding/queries/getTenantUsers';
import tenantSchema from '../../mutation-schema/onboarding/tenant-schema';
import tenantServiceSchema from '../../mutation-schema/onboarding/tenant-service-schema';

export default class TenantService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
  }

  mutateTenant = async values => {
    const structuredValues = tenantSchema(values);
    const response = await this.api.mutate(createTenant, structuredValues);
    return response;
  };

  mutateTenantService = async (valuesArray, parent) => {
    const structuredValues = tenantServiceSchema(valuesArray, parent);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };

    const response = await this.api.mutate(mutationQuery, dataSet);
    return response;
  };

  getTenants = async () => {
    const params = {
      partitionKey: 'BUILDOPS'
    };
    const response = await this.api.query(getTenants, params);
    return response;
  };

  listTenantSettings = async () => {
    const currentUser = await Auth.currentAuthenticatedUser();
    const { attributes } = currentUser;
    const tenantId = attributes['custom:tenantId'];
    const sortKey = `${tenantId}_Tenant_${tenantId}`;

    const params = {
      partitionKey: tenantId,
      sortKey
    };
    const response = await this.api.query(listTenantSettings, params);
    return response;
  };

  getTenantUsers = async (tenantId, companyId) => {
    const sortKey = `${tenantId}_Company_${companyId}`;
    const params = {
      partitionKey: tenantId,
      sortKey
    };
    const response = await this.api.query(getTenantUsers, params);
    return response;
  };
}
