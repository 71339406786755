import { number, oneOf, shape, string } from 'prop-types';

import { JobTypes } from '@dispatch/Dispatch.constants';

export const EventDataPropTypes = shape({
  scheduledFor: number,
  actualDuration: number,
  primaryTechId: string
});

export const JobPropTypes = shape({
  jobNumber: string,
  customIdentifier: string,
  jobTypeInternal: oneOf([JobTypes.JOB.value.serverValue, JobTypes.MAINTENANCE.value.serverValue])
});
