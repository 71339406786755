import React, { useEffect, useState } from 'react';

import {
  Button,
  ButtonType,
  ImageCarousel,
  ThemeProvider,
  ThumbnailSize,
  Typography
} from '@BuildHero/sergeant';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import { Mode } from 'utils/constants';

import { download, getFileName } from 'utils/downloadFile';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import AttachmentModal from '../../../../components/AttachmentSection/AddAttachment';

import ReceiptAttachmentModal from '../ReceiptAttachmentModal';

import useStyles from './styles';

const deleteConfirmContent = {
  body: 'Are you sure you want to delete this attachment?',
  title: 'Delete Attachment',
  buttonLabel: 'Delete Attachment',
  buttonType: ButtonType.ERROR
};

const EntityImagesCarousel = ({
  images,
  thumbnailSize,
  onSave,
  onDelete,
  parentId,
  disableEdit,
  onEdit,
  disableVendorInvoiceOption,
  user,
  parentData
}) => {
  const classes = useStyles();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState({ isOpen: false, mode: '' });
  const [selectedImage, setSelectedImage] = useState(0);
  const confirmContext = useConfirmModal();
  const flags = useFlags();

  useEffect(() => {
    if (!images.length) {
      setOpenImageModal(false);
    }
  }, [images]);

  const mutationHandler = record => {
    if (openUploadModal.mode === Mode.EDIT) {
      onEdit(images[selectedImage]?.id, record, selectedImage);
    } else if (openUploadModal.mode === Mode.NEW) {
      onSave(parentId, record);
    }
  };

  const isFileTypePDF = images[selectedImage]?.url?.match('.pdf') !== null;

  return (
    <>
      <ThemeProvider>
        <ImageCarousel
          handleClickMainImage={
            images.length
              ? index => {
                  setSelectedImage(index);
                  setOpenImageModal(true);
                }
              : null
          }
          images={images}
          thumbnailSize={thumbnailSize}
          onSelectionChanged={setSelectedImage}
        />
        {onSave && !disableEdit && (
          <div className={classes.addImageButton}>
            <Button
              key="addButton"
              startIcon={<AddCircleOutlineIcon />}
              type={ButtonType.LEADING}
              onClick={() => setOpenUploadModal({ isOpen: true, mode: Mode.NEW })}
            >
              Add Image
            </Button>
          </div>
        )}
        <Dialog
          maxWidth="md"
          open={openImageModal}
          PaperProps={{
            className: isFileTypePDF ? classes.paperPropsPDF : classes.paperProps
          }}
          scroll="body"
          onClose={() => setOpenImageModal(false)}
        >
          <Box className={classes.navContainer}>
            <Box className={classes.leftNavContainer}>
              <IconButton onClick={() => setOpenImageModal(false)}>
                <CloseIcon className={classes.muiIcon} />
              </IconButton>
              <Typography className={classes.fileName}>
                {getFileName(images[selectedImage])}
              </Typography>
            </Box>
            <Box className={classes.rightNavContainer}>
              <IconButton onClick={() => download(images[selectedImage])}>
                <GetAppIcon className={classes.muiIcon} />
              </IconButton>
              {!disableEdit && (
                <IconButton
                  onClick={async () => {
                    setOpenUploadModal({ isOpen: true, mode: Mode.EDIT });
                  }}
                >
                  <EditIcon className={classes.muiIcon} />
                </IconButton>
              )}
              {!disableEdit && (
                <IconButton
                  onClick={async () => {
                    if (await confirmContext.confirm(deleteConfirmContent)) {
                      setSelectedImage(0);
                      onDelete(images[selectedImage], selectedImage);
                    }
                  }}
                >
                  <DeleteIcon className={classes.muiIcon} />
                </IconButton>
              )}
            </Box>
          </Box>
          <ImageCarousel
            images={images}
            initialImageIndex={selectedImage}
            showNavArrows
            thumbnailSize={ThumbnailSize.LARGE}
            onSelectionChanged={setSelectedImage}
          />
        </Dialog>
        {flags[FeatureFlags.VENDOR_INVOICE_APPROVAL] ? (
          <ReceiptAttachmentModal
            data={openUploadModal.mode === Mode.EDIT ? images[selectedImage] : {}}
            disableVendorInvoiceOption={disableVendorInvoiceOption}
            handleClose={() => setOpenUploadModal({ isOpen: false, mode: '' })}
            maxFileSize={20971520}
            maxFileSizeWarningLabel="Attachments over 20MB cannot be uploaded."
            mode={openUploadModal.mode}
            mutationService={mutationHandler}
            open={openUploadModal.isOpen}
            receiptData={parentData}
            user={user}
          />
        ) : (
          <AttachmentModal
            data={openUploadModal.mode === Mode.EDIT ? images[selectedImage] : {}}
            handleClose={() => setOpenUploadModal({ isOpen: false, mode: '' })}
            mode={openUploadModal.mode}
            mutationService={mutationHandler}
            open={openUploadModal.isOpen}
          />
        )}
      </ThemeProvider>
    </>
  );
};

EntityImagesCarousel.propTypes = {
  images: PropTypes.array,
  thumbnailSize: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  parentId: PropTypes.string.isRequired,
  disableEdit: PropTypes.bool,
  disableVendorInvoiceOption: PropTypes.bool,
  user: PropTypes.object,
  parentData: PropTypes.object
};

EntityImagesCarousel.defaultProps = {
  images: [],
  disableEdit: false,
  disableVendorInvoiceOption: true,
  user: {},
  parentData: {}
};

export default EntityImagesCarousel;
