import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  CurrencyInput,
  MoreButton,
  NumberInput,
  Select,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';
import { Box, useTheme } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import WarningIcon from '@material-ui/icons/Warning';
import { debounce, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import InvoicesIcon from 'assets/Icons/Invoices';

import WrapTable, { tableEmptyValueFormatter } from 'components/WrapTable';
import useBillingHourTypes from 'customHooks/useBillingHourTypes';
import useEmployees from 'customHooks/useEmployees';
import useLabourRates from 'customHooks/useLabourRates';
import useLabourTypes from 'customHooks/useLabourTypes';
import Routes from 'scenes/Routes';
import { convertToCurrencyString } from 'utils';
import { LineItemBillingStatus } from 'utils/AppConstants';
import { InvoiceItemType, InvoiceStatus, LaborLineSourceType } from 'utils/constants';

import { InvoiceItemsEditedField, LineItemWithAsterisk, SectionHeader } from '../../Components';
import {
  useGenerateLabourRateLineItemsForVisit,
  useUpdateLabourRateBillingHourLine,
  useUpdateLabourRateLineItem
} from '../../mutations';
import {
  calculateLaborRate,
  getBillableEventDisplayText,
  getVisitDisplayText,
  tableCurrencyFormatter,
  timeSubmittedDisplay
} from '../../utils';

const getTimesheetDuration = timesheet =>
  Number.isInteger(timesheet.actualTotalDurationOverride)
    ? timesheet.actualTotalDurationOverride
    : timesheet.actualTotalDuration;

const WarningComponent = () => {
  const theme = useTheme();
  return (
    <WarningIcon
      css={{ marginRight: 8, color: theme.palette.support.yellow.dark }}
      fontSize="small"
    />
  );
};

const getColumns = ({
  billingHourTypes,
  labourTypes,
  setLabourRateBillingHourLineUnsavedChanges,
  updateLabourRateBillingHourLines,
  labourRates,
  priceBookId,
  updatingLabourRateLineItem,
  tenantId,
  updateLabourRateLineItem
}) => {
  const payrollOptions = billingHourTypes.map(p => ({ label: p.hourType, value: p.id }));
  const labourOptions = labourTypes.map(({ name, id, isArchived }) => ({
    label: name,
    value: id,
    isArchived
  }));

  return [
    {
      field: 'source',
      headerName: 'Source',
      width: 200,
      align: 'center',
      flex: 1,
      renderCell: ({ row }) => {
        const showWarning = row.labourRate === null;
        if (row.invoiceNumber) {
          return (
            <>
              {showWarning && <WarningComponent />}
              <Typography variant={TV.BASE} weight={TW.REGULAR}>
                <Link to={Routes.invoice({ mode: 'view', id: row.invoiceId })}>{row.source}</Link>
              </Typography>
            </>
          );
        }

        if (row.sourceAsteriskNeeded) {
          return (
            <>
              {showWarning && <WarningComponent />}
              <LineItemWithAsterisk content={row.source} />
            </>
          );
        }

        return (
          <>
            {showWarning && <WarningComponent />}
            <Typography variant={TV.BASE} weight={TW.REGULAR}>
              {row.source}
            </Typography>
          </>
        );
      }
    },
    { field: 'technician', headerName: 'Technician', align: 'center', flex: 1 },
    { field: 'timeSubmitted', headerName: 'Time Submitted', width: 125, align: 'center', flex: 1 },
    {
      field: 'payrollHourType',
      headerName: 'Payroll Hour Type',
      width: 140,
      align: 'center',
      flex: 1
    },
    {
      field: 'billingHourType',
      headerName: 'Billing Hour Type',
      width: 200,
      overflow: 'visible',
      align: 'center',
      renderCell: ({ row }) => {
        const {
          doNotInvoice,
          billingHourType,
          labourRateBillingHourLineId,
          labourRateBillingHourLineVersion,
          labourType
        } = row;

        if (doNotInvoice || !billingHourType) return <></>;
        return (
          <Select
            disabled={row.readOnly || updatingLabourRateLineItem}
            options={payrollOptions}
            value={billingHourType}
            onChange={newBillingHourType => {
              if (newBillingHourType.value === billingHourType.value) return;

              setLabourRateBillingHourLineUnsavedChanges(existingUnsavedMap => {
                const newUnsavedMap = {
                  ...existingUnsavedMap,
                  [labourRateBillingHourLineId]: {
                    ...(existingUnsavedMap[labourRateBillingHourLineId] ?? {}),
                    version: labourRateBillingHourLineVersion,
                    hourTypeId: newBillingHourType.value,
                    productId: labourTypes
                      .find(({ id }) => id === labourType.value)
                      ?.labourTypeBillingHourTypeProducts?.items?.find(
                        ({ billingHourTypeId }) => billingHourTypeId === newBillingHourType.value
                      )?.productId,
                    rate: calculateLaborRate({
                      labourRates,
                      labourTypeId: labourType.value,
                      payrollBillingHourTypeId: newBillingHourType.value,
                      priceBookId
                    })
                  }
                };
                updateLabourRateBillingHourLines(newUnsavedMap);
                return newUnsavedMap;
              });
            }}
          />
        );
      },
      cellCss: ({ row }) => {
        if (row?.doNotInvoice) return { backgroundColor: '#E6E6E6' };

        return {};
      }
    },
    {
      field: 'labourType',
      headerName: 'Labor Type',
      width: 200,
      overflow: 'visible',
      align: 'center',
      renderCell: ({ row }) => {
        const {
          doNotInvoice,
          labourType,
          labourRateLineItemId,
          labourRateLineItemVersion,
          readOnly
        } = row;
        if (doNotInvoice || !labourType) return <></>;
        return (
          <Select
            disabled={readOnly || updatingLabourRateLineItem}
            options={labourOptions.filter(
              ({ isArchived, value }) => !isArchived || labourType.id === value
            )}
            value={labourType}
            onChange={async newLabourType => {
              if (newLabourType.value === labourType.value) return;

              const updatedLineItem = await updateLabourRateLineItem({
                tenantId,
                lineItemId: labourRateLineItemId,
                version: labourRateLineItemVersion,
                labourTypeId: newLabourType.value
              });
              const labourTypeId = updatedLineItem?.data?.updateLabourRateLineItem?.labourTypeId;
              const hourLines =
                updatedLineItem?.data?.updateLabourRateLineItem?.labourRateBillingHourLines
                  ?.items || [];

              setLabourRateBillingHourLineUnsavedChanges(existingUnsavedMap => {
                let newUnsavedMap = { ...existingUnsavedMap };
                hourLines.forEach(hourLine => {
                  newUnsavedMap = {
                    ...newUnsavedMap,
                    [hourLine.id]: {
                      ...newUnsavedMap[hourLine.id],
                      version: hourLine.version,
                      rate: calculateLaborRate({
                        labourRates,
                        labourTypeId,
                        payrollBillingHourTypeId: hourLine.hourTypeId,
                        priceBookId
                      }),
                      productId: labourTypes
                        .find(({ id }) => id === labourTypeId)
                        ?.labourTypeBillingHourTypeProducts?.items?.find(
                          ({ billingHourTypeId }) => billingHourTypeId === hourLine.hourTypeId
                        )?.productId
                    }
                  };
                });
                updateLabourRateBillingHourLines(newUnsavedMap);
                return newUnsavedMap;
              });
            }}
          />
        );
      },
      cellCss: ({ row }) => {
        if (row?.doNotInvoice) return { backgroundColor: '#E6E6E6' };

        return {};
      }
    },
    {
      field: 'hours',
      headerName: 'Hours',
      width: 60,
      align: 'center',
      renderCell: ({ row }) => {
        const {
          doNotInvoice,
          readOnly,
          hours,
          labourRateBillingHourLineId,
          labourRateBillingHourLineVersion
        } = row;

        if (doNotInvoice) return <></>;
        if (readOnly) {
          return (
            <div css={{ width: '100%', textAlign: 'right' }}>
              {tableEmptyValueFormatter({ value: hours })}
            </div>
          );
        }
        return (
          <NumberInput
            isEditable
            value={hours}
            onBlur={value => {
              if (value === hours) return;
              setLabourRateBillingHourLineUnsavedChanges(existingUnsavedMap => {
                const newUnsavedMap = {
                  ...existingUnsavedMap,
                  [labourRateBillingHourLineId]: {
                    ...(existingUnsavedMap[labourRateBillingHourLineId] ?? {}),
                    version: labourRateBillingHourLineVersion,
                    totalHours: value
                  }
                };
                updateLabourRateBillingHourLines(newUnsavedMap);
                return newUnsavedMap;
              });
            }}
          />
        );
      },
      cellCss: ({ row }) => {
        if (row?.doNotInvoice) return { backgroundColor: '#E6E6E6' };

        return {};
      }
    },
    {
      field: 'labourRate',
      headerName: 'Labor Rate',
      width: 100,
      align: 'center',
      renderCell: ({ row }) => {
        const {
          doNotInvoice,
          readOnly,
          bulkUpdateLabourRateBillingHourLinesLoading,
          labourRate,
          labourRateBillingHourLineId,
          labourRateBillingHourLineVersion
        } = row;

        if (doNotInvoice) return <></>;
        if (readOnly || bulkUpdateLabourRateBillingHourLinesLoading) {
          return (
            <div css={{ width: '100%', textAlign: 'right' }}>
              <div css={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}>
                {tableCurrencyFormatter({ value: row.labourRate })}
              </div>
            </div>
          );
        }
        return (
          <CurrencyInput
            isEditable
            readOnly={readOnly || updatingLabourRateLineItem}
            value={labourRate}
            onBlur={value => {
              if (value === labourRate) return;

              setLabourRateBillingHourLineUnsavedChanges(existingUnsavedMap => {
                const newUnsavedMap = {
                  ...existingUnsavedMap,
                  [labourRateBillingHourLineId]: {
                    ...(existingUnsavedMap[labourRateBillingHourLineId] ?? {}),
                    version: labourRateBillingHourLineVersion,
                    rate: value
                  }
                };
                updateLabourRateBillingHourLines(newUnsavedMap);

                return newUnsavedMap;
              });
            }}
          />
        );
      },
      cellCss: ({ row }) => {
        if (row?.doNotInvoice) return { backgroundColor: '#E6E6E6' };

        return {};
      }
    },
    {
      field: 'subtotal',
      headerName: 'Subtotal',
      width: 100,
      align: 'center',
      flex: 1,
      valueFormatter: tableCurrencyFormatter,
      renderCell: ({ row, formattedValue }) =>
        row.doNotInvoice ? (
          <></>
        ) : (
          <Typography css={{ width: '100%', textAlign: 'right' }} numeric variant={TV.BASE}>
            {formattedValue}
          </Typography>
        ),
      totalGetter: ({ rows }) =>
        rows.filter(r => !r.doNotInvoice).reduce((acc, r) => acc + r.subtotal, 0),
      renderTotal: ({ formattedValue }) => (
        <Typography
          css={{ width: '100%', textAlign: 'right' }}
          numeric
          variant={TV.BASE}
          weight={TW.BOLD}
        >
          {convertToCurrencyString(formattedValue ?? 0)}
        </Typography>
      ),
      cellCss: ({ row }) => {
        if (row?.doNotInvoice) return { backgroundColor: '#E6E6E6' };

        return {};
      }
    },
    {
      field: 'actions',
      flex: 1,
      width: 32,
      headerName: '',
      noPadding: true,
      align: 'center',
      justify: 'center',
      renderCell: ({ row }) => {
        const {
          labourRateBillingHourLineId,
          labourRateBillingHourLineVersion,
          doNotInvoice,
          isInvoiced
        } = row;

        if (isInvoiced) return <></>;

        if (doNotInvoice) {
          return (
            <MoreButton
              options={[
                {
                  label: 'Include in Invoice',
                  icon: InvoicesIcon,
                  onClick: () => {
                    setLabourRateBillingHourLineUnsavedChanges(existingUnsavedMap => {
                      const newUnsavedMap = {
                        ...existingUnsavedMap,
                        [labourRateBillingHourLineId]: {
                          ...(existingUnsavedMap[labourRateBillingHourLineId] ?? {}),
                          version: labourRateBillingHourLineVersion,
                          billingStatus: LineItemBillingStatus.NOT_INVOICED
                        }
                      };
                      updateLabourRateBillingHourLines(newUnsavedMap);

                      return newUnsavedMap;
                    });
                  }
                }
              ]}
            />
          );
        }

        return (
          <MoreButton
            options={[
              {
                label: 'Do Not Invoice',
                icon: BlockIcon,
                onClick: () => {
                  setLabourRateBillingHourLineUnsavedChanges(existingUnsavedMap => {
                    const newUnsavedMap = {
                      ...existingUnsavedMap,
                      [labourRateBillingHourLineId]: {
                        ...(existingUnsavedMap[labourRateBillingHourLineId] ?? {}),
                        version: labourRateBillingHourLineVersion,
                        billingStatus: LineItemBillingStatus.DO_NOT_INVOICE
                      }
                    };
                    updateLabourRateBillingHourLines(newUnsavedMap);

                    return newUnsavedMap;
                  });
                }
              }
            ]}
          />
        );
      }
    }
  ];
};

const getLaborDataForTimesheet = ({
  timesheet,
  employee,
  visit,
  labourRateLineItems,
  labourTypes,
  billingHourTypes,
  invoices
}) => {
  const duration = getTimesheetDuration(timesheet);
  const durationHours = duration / 3600;
  const durationTag = durationHours > 0 ? `${durationHours} hr` : 'N/A';

  const labourRateLineItem = labourRateLineItems.find(lineItem =>
    lineItem.labourRateBillingHourLines?.items
      ?.flat()
      .find(hourLine => hourLine.timesheetEntryId === timesheet.id)
  );

  const labourRateBillingHourLine = labourRateLineItems
    .map(lineItem => lineItem.labourRateBillingHourLines?.items)
    ?.flat()
    ?.find(hourLine => hourLine.timesheetEntryId === timesheet.id);

  const labourType = labourTypes.find(({ id }) => id === labourRateLineItem?.labourTypeId);
  const billingHourType = billingHourTypes.find(
    ({ id }) => id === labourRateBillingHourLine?.hourTypeId
  );

  const invoiceItem = invoices
    .flatMap(invoice => invoice.invoiceItems?.items || [])
    .find(({ id }) => id === labourRateBillingHourLine?.invoiceItemId);

  const sourceAsteriskNeeded = invoiceItem
    ? invoiceItem.quantity !== labourRateBillingHourLine?.totalHours ||
      invoiceItem.unitPrice !== labourRateBillingHourLine?.rate
    : false;

  return {
    sourceAsteriskNeeded,
    technician: `${employee?.name} (${employee?.labourType?.name})`,
    timeSubmitted: timeSubmittedDisplay({
      timesheetManualStatus: timesheet?.manualStatus,
      employeeId: employee?.id,
      durationDisplayText: durationTag,
      duration,
      scheduledFor: visit.scheduledFor
    }),
    payrollHourType: timesheet.hourType?.hourType || '-',
    payrollHourTypeSortOrder: timesheet.hourType?.sortOrder,
    billingHourType: {
      label: billingHourType?.hourType || '',
      value: billingHourType?.id || ''
    },
    labourType: { label: labourType?.name || '', value: labourType?.id || '' },
    isInvoiced: labourRateBillingHourLine?.billingStatus === LineItemBillingStatus.BILLED,
    readOnly: labourRateBillingHourLine?.billingStatus === LineItemBillingStatus.BILLED,
    doNotInvoice: labourRateBillingHourLine?.billingStatus === LineItemBillingStatus.DO_NOT_INVOICE,
    hours: labourRateBillingHourLine?.totalHours,
    labourRate: labourRateBillingHourLine?.rate,
    subtotal: labourRateBillingHourLine?.rate * labourRateBillingHourLine?.totalHours,
    visitNumber: visit.visitNumber,
    labourRateBillingHourLineId: labourRateBillingHourLine?.id,
    labourRateBillingHourLineVersion: labourRateBillingHourLine?.version,
    labourRateLineItemId: labourRateLineItem?.id,
    labourRateLineItemVersion: labourRateLineItem?.version
  };
};

const mapVisitsToRows = ({
  visits,
  invoices,
  hideInvoiced,
  bulkUpdateLabourRateBillingHourLinesLoading,
  labourTypes,
  billingHourTypes,
  employees,
  companyTimezone
}) => {
  const visitRows = [];
  const billableEventRows = [];
  visits.forEach(visit => {
    const visitTag = getVisitDisplayText(visit, companyTimezone);
    const timesheetEntries = visit.timesheetEntriesView?.items || [];
    const labourRateLineItems = visit.labourRateLineItems?.items || [];
    const timesheetEntryBinders = visit.timesheetEntryBinders?.items || [];

    if (timesheetEntryBinders.length === 0) {
      timesheetEntries
        .filter(t => (t.actualTotalDuration || t.actualTotalDurationOverride) && !t.nonVisitEventId)
        .forEach(timesheet => {
          const tech = employees.find(at => at.id === timesheet.timesheetPeriod.parentId);
          visitRows.push({
            source: visitTag,
            bulkUpdateLabourRateBillingHourLinesLoading,
            ...getLaborDataForTimesheet({
              timesheet,
              employee: tech,
              visit,
              labourRateLineItems,
              labourTypes,
              billingHourTypes,
              invoices
            })
          });
        });
    } else {
      timesheetEntryBinders
        .filter(binder => !binder.isDismissed)
        .forEach(binder => {
          const binderTimesheetEntries = binder.timesheetEntries?.items || [];
          const tech = employees.find(at => at.id === binder.employeeId);
          binderTimesheetEntries
            .filter(t => t.actualTotalDuration || t.actualTotalDurationOverride)
            .forEach(timesheet => {
              visitRows.push({
                source: visitTag,
                bulkUpdateLabourRateBillingHourLinesLoading,
                ...getLaborDataForTimesheet({
                  timesheet,
                  employee: tech,
                  visit,
                  labourRateLineItems,
                  labourTypes,
                  billingHourTypes,
                  invoices
                })
              });
            });
        });
    }

    visit.nonVisitEvents?.items?.forEach(event => {
      const billableEventTag = getBillableEventDisplayText(event, companyTimezone);
      const eventTimesheetEntries = event.timesheetEntries?.items || [];
      const eventTimesheetEntryBinders = event.timesheetEntryBinders?.items || [];
      if (eventTimesheetEntryBinders.length === 0) {
        eventTimesheetEntries
          .filter(t => t.actualTotalDuration || t.actualTotalDurationOverride)
          .forEach(timesheet => {
            billableEventRows.push({
              source: billableEventTag,
              bulkUpdateLabourRateBillingHourLinesLoading,
              ...getLaborDataForTimesheet({
                timesheet,
                employee: event.employee,
                visit,
                labourRateLineItems,
                labourTypes,
                billingHourTypes,
                invoices
              })
            });
          });
      } else {
        eventTimesheetEntryBinders
          .filter(binder => !binder.isDismissed)
          .forEach(binder =>
            (binder.timesheetEntries?.items || [])
              .filter(t => t.actualTotalDuration || t.actualTotalDurationOverride)
              .forEach(timesheet => {
                billableEventRows.push({
                  source: billableEventTag,
                  bulkUpdateLabourRateBillingHourLinesLoading,
                  ...getLaborDataForTimesheet({
                    timesheet,
                    employee: event.employee,
                    visit,
                    labourRateLineItems,
                    labourTypes,
                    billingHourTypes,
                    invoices
                  })
                });
              })
          );
      }
    });
  });

  const invoiceRows = invoices
    .filter(({ status }) => status !== InvoiceStatus.VOID)
    .map(invoice =>
      (invoice?.invoiceItems?.items || [])
        .filter(
          ({ lineItemType, source }) =>
            lineItemType === InvoiceItemType.LABOR_LINE_ITEM &&
            source === LaborLineSourceType.INVOICE
        )
        .map(item => ({
          source: `Invoice ${invoice.invoiceNumber}`,
          isInvoiced: true,
          hours: item.quantity,
          labourRate: item.unitPrice,
          subtotal: (item.quantity ?? 0) * (item.unitPrice ?? 0),
          invoiceNumber: invoice.invoiceNumber,
          invoiceId: invoice.id,
          readOnly: true
        }))
    )
    .flat();
  return [
    ...orderBy(visitRows, ['visitNumber', 'technician', 'payrollHourTypeSortOrder']),
    ...orderBy(billableEventRows, ['visitNumber', 'technician', 'payrollHourTypeSortOrder']),
    ...orderBy(invoiceRows, ['invoiceNumber'])
  ].filter(({ isInvoiced }) => !hideInvoiced || !isInvoiced);
};

const TimeAndMaterialLaborCosts = ({
  invoices,
  visits,
  priceBookId,
  hideInvoiced,
  bulkUpdateLabourRateBillingHourLines,
  bulkUpdateLabourRateBillingHourLinesLoading,
  companyTimezone,
  isLoading,
  onLoad
}) => {
  const user = useSelector(state => state?.user);
  const { tenantId } = user;
  const [employees, employeesLoading] = useEmployees();
  const [labourTypes, labourTypesLoading] = useLabourTypes();
  const [labourRates, labourRatesLoading] = useLabourRates();
  const [billingHourTypes, billingHourTypesLoading] = useBillingHourTypes();

  const [
    generateLabourRateLineItemsForVisit,
    { loading: generateLabourRateLineItemsForVisitLoading }
  ] = useGenerateLabourRateLineItemsForVisit();
  const [updateLabourRateBillingHourLine] = useUpdateLabourRateBillingHourLine();
  const [
    updateLabourRateLineItem,
    { loading: updatingLabourRateLineItem }
  ] = useUpdateLabourRateLineItem();

  const [priceBookIdDiff, setPriceBookIdDiff] = useState(null);

  const [_, setLabourRateBillingHourLineUnsavedChanges] = useState({});

  const dataLoaded =
    !employeesLoading &&
    !labourTypesLoading &&
    !labourRatesLoading &&
    !billingHourTypesLoading &&
    !!priceBookId &&
    !isLoading;

  useEffect(() => {
    onLoad(dataLoaded);
  }, [dataLoaded]);

  const updateLabourRateBillingHourLines = useCallback(
    debounce(async lines => {
      setLabourRateBillingHourLineUnsavedChanges({});
      const promises = Object.keys(lines).map(id =>
        updateLabourRateBillingHourLine({
          tenantId,
          hourLineId: id,
          ...lines[id]
        })
      );
      Promise.all(promises);
    }, 1000),
    [setLabourRateBillingHourLineUnsavedChanges]
  );

  const rows = useMemo(
    () =>
      dataLoaded
        ? mapVisitsToRows({
            visits,
            invoices,
            hideInvoiced,
            bulkUpdateLabourRateBillingHourLinesLoading,
            labourTypes,
            billingHourTypes,
            employees,
            companyTimezone
          })
        : [],
    [
      dataLoaded,
      visits,
      invoices,
      hideInvoiced,
      bulkUpdateLabourRateBillingHourLinesLoading,
      labourTypes,
      employees,
      billingHourTypes,
      companyTimezone
    ]
  );

  const columns = useMemo(
    () =>
      getColumns({
        billingHourTypes,
        labourTypes,
        setLabourRateBillingHourLineUnsavedChanges,
        updateLabourRateBillingHourLines,
        labourRates,
        priceBookId,
        updatingLabourRateLineItem,
        tenantId,
        updateLabourRateLineItem
      }),
    [
      labourTypes,
      billingHourTypes,
      setLabourRateBillingHourLineUnsavedChanges,
      updateLabourRateBillingHourLines,
      labourRates,
      priceBookId,
      updatingLabourRateLineItem,
      tenantId,
      updateLabourRateLineItem
    ]
  );

  // use effect hook that modifies labour rates on pricebookId change
  useEffect(() => {
    if (!priceBookId || !dataLoaded || generateLabourRateLineItemsForVisitLoading) return;

    // used to check if the priceBookID is changing due to a first render or user change
    if (priceBookIdDiff !== null && priceBookIdDiff !== priceBookId) {
      const hourLinesToBeUpdated = visits
        .flatMap(v => v?.labourRateLineItems?.items || [])
        .flatMap(({ labourRateBillingHourLines, labourTypeId }) =>
          (labourRateBillingHourLines?.items || [])
            .filter(({ billingStatus }) => billingStatus !== LineItemBillingStatus.BILLED)
            .map(hourLine => ({
              id: hourLine.id,
              version: hourLine.version,
              rate: calculateLaborRate({
                labourRates,
                labourTypeId,
                payrollBillingHourTypeId: hourLine.hourTypeId,
                priceBookId
              })
            }))
        );

      bulkUpdateLabourRateBillingHourLines({
        tenantId,
        labourRateBillingHourLines: hourLinesToBeUpdated
      });
    }

    setPriceBookIdDiff(priceBookId);
  }, [priceBookId, priceBookIdDiff, dataLoaded]);

  const doTechTimesheetsHaveBillingHourLines = (timesheets, labourRateLineItems) => {
    const labourRateBillingHourLines =
      labourRateLineItems?.map(lineItem => lineItem.labourRateBillingHourLines?.items)?.flat() ||
      [];

    return timesheets.every(({ id }) =>
      labourRateBillingHourLines.find(hourLine => hourLine.timesheetEntryId === id)
    );
  };

  // use effect hook that is used to populate the labour rates for timesheets without
  // corresponding labourRateLineItems/labourRateBillingHourLines
  useEffect(() => {
    if (!dataLoaded || generateLabourRateLineItemsForVisitLoading) return;

    visits.forEach(async visit => {
      const visitLabourRateLineItems = visit.labourRateLineItems?.items || [];
      const timesheetEntries = visit.timesheetEntriesView?.items || [];
      const billableEvents = visit.nonVisitEvents?.items || [];
      const allTimesheets = [
        ...timesheetEntries,
        ...billableEvents.map(event => event.timesheetEntries?.items || []).flat()
      ];
      if (!doTechTimesheetsHaveBillingHourLines(allTimesheets, visitLabourRateLineItems)) {
        generateLabourRateLineItemsForVisit({ tenantId, visitId: visit.id });
      }
    });
  }, [dataLoaded, visits]);

  const rowsWithWarnings = rows?.filter(item => item.labourRate === null) || [];

  return (
    <>
      <SectionHeader title="Labor" />
      <WrapTable
        columns={columns}
        enableTotalsRow
        hideFooter={rows.length <= 10}
        loading={!dataLoaded}
        loadingRows={3}
        noDataMessage="No Labor Data"
        rows={rows}
      />
      {rowsWithWarnings.length > 0 && (
        <Box alignItems="center" display="flex" flexDirection="row" paddingY={0.5}>
          <WarningComponent />
          <Typography variant={TV.S1} weight={TW.REGULAR}>
            Labor line is missing Billing Hour Type or Labor Rate. This labor line will not
            propagate to an invoice and job report pdf until the issue is resolved.
          </Typography>
        </Box>
      )}

      {rows?.some(({ sourceAsteriskNeeded }) => sourceAsteriskNeeded === true) && (
        <InvoiceItemsEditedField />
      )}
    </>
  );
};

export default TimeAndMaterialLaborCosts;
