import React from 'react';

import { TH, TV, TW, Typography } from '@BuildHero/sergeant';

import InvoiceHeaderUploader from './InvoiceHeaderUploader.component';

const InvoiceHeaderPreview = () => {
  return (
    <div
      css={{
        width: 612,
        height: 330,
        paddingTop: 34,
        paddingLeft: 24,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 0px 40px -1px rgba(0, 0, 0, 0.25)'
      }}
    >
      <InvoiceHeaderUploader />
      <div
        css={{
          width: 243,
          height: 80,
          marginTop: 30,
          marginLeft: 34,
          marginBottom: 35,
          border: '1px solid #CCCCCC',
          borderRadius: 12,
          padding: 8,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Typography height={TH.COMPACT} variant={TV.PRINT} weight={TW.MEDIUM}>
          {'Miles Customer\n12345 Street\nAnytown, ST 12345'}
        </Typography>
      </div>
      <Typography color="black">
        <b>Invoice</b> 99-123456
      </Typography>
      <Typography variant={TV.PRINT}>
        <b>Job</b> 99-54321
      </Typography>
      <Typography variant={TV.PRINT}>31 Dec 2030</Typography>
    </div>
  );
};

export default InvoiceHeaderPreview;
