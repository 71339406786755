import React, { useMemo } from 'react';

import { Modal, PDFDocument, ThemeProvider } from '@BuildHero/sergeant';
import { PDFViewer } from '@react-pdf/renderer';

import getConfiguration from './PaymentReceipt.config';

const PaymentReceiptModal = ({ open, onClose, payment }) => {
  const MemoizedPDF = useMemo(
    () =>
      payment?.id && (
        <PDFViewer height="100%" width="100%">
          <PDFDocument configuration={getConfiguration(payment)} initialValues={payment} />
        </PDFViewer>
      ),
    [payment]
  );

  return (
    <ThemeProvider>
      <Modal fullScreen open={open} title="Payment Receipt" onClose={onClose}>
        {MemoizedPDF}
      </Modal>
    </ThemeProvider>
  );
};

export default PaymentReceiptModal;
