import React, { createContext, useContext, useState } from 'react';

import { Modal } from 'components';

export const ModalContext = createContext({});

export const useModalContext = () => useContext(ModalContext);

export const withModal = Component => props => {
  const [modalProps, setModalProps] = useState(false);
  const showModal = Boolean(modalProps);

  const openModal = newProps => {
    setModalProps(newProps);
  };

  const closeModal = () => {
    setModalProps(null);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      <Component {...props} />
      <Modal
        autoSize
        center
        handleClose={closeModal}
        open={showModal}
        showModalHeader
        width={400}
        {...modalProps}
      />
    </ModalContext.Provider>
  );
};
