const styles = theme => ({
  settingsHeader: {
    color: theme.palette.grayscale(20),
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    height: 17,
    letterSpacing: 0,
    width: 58,
    fontWeight: 'normal'
  },
  inventoryHeader: {
    color: theme.palette.brand.logoBlue,
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    height: 29,
    letterSpacing: -0.02,
    width: 168,
    marginLeft: 10
  },
  settingIcon: {
    height: 17,
    width: 17
  },
  greenHeading: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    marginBottom: 8
  }
});

export default styles;
