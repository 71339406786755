import { Timezone } from 'scenes/Procurement/constants';
import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';
import { departmentSearch, getDepartmentsByJob } from 'services/API/department';
import { employeeSearch } from 'services/API/employee';
import { paymentTermSearch } from 'services/API/paymentTerm';

const defaultFlexStyle = '0 0 219px';
const margin = '0px 0px 18px 0px';
const marginEdit = '0px 16px 16px 0px';

const rbFrameTopFormFields = {
  orderedBy: { required: false },
  department: { required: false },
  poType: { required: false },
  assignedTo: { required: false },
  jobAndProject: { required: false },
  issuedBy: { required: true },
  paymentTerm: { required: false },
  vendorDocumentNumber: { required: false },
  projectManager: { required: false },
  invoiceAmount: { required: false },
  syncStatus: { required: false }
};

const rbFrameTopFormDefaultContents = (
  isVendorInvoiceApproval,
  handleEditImage,
  handleDeleteImage,
  disableVendorInvoiceEdit
) => {
  return [
    // the section title 1st row
    {
      options: {
        size: 1,
        direction: 'row'
      },
      contents: [
        {
          options: {
            margin: '0px 0px 10px 0px'
          },
          contents: [
            {
              component: {
                default: 'MuiFormSectionTitle'
              },
              options: {
                label: 'Receipt Information'
              }
            }
          ]
        }
      ]
    },
    // the 2nd row
    {
      options: {
        direction: 'row'
      },
      contents: [
        {
          options: {
            direction: 'column',
            minWidth: '97px',
            margin: '0px'
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'jobOrProjectText',
              options: {
                label: 'Job / Project',
                placeholder: '',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false,
                linkPath: '',
                linkReference: 'jobOrProjectLink'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            minWidth: '66px',
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'poType.name',
              options: {
                label: 'PO Type',
                placeholder: '',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            minWidth: '91px',
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'Department.tagName',
              options: {
                label: 'Department',
                placeholder: '',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            minWidth: '150px',
            margin
          },
          contents: [
            {
              component: {
                default: 'VendorDocumentNumberImage'
              },
              source: 'vendorDocumentNumber',
              options: {
                label: 'Vendor Document Number',
                onEdit: handleEditImage,
                onDelete: handleDeleteImage,
                disableEdit: disableVendorInvoiceEdit
              }
            }
          ]
        },
        isVendorInvoiceApproval
          ? {
              options: {
                direction: 'column',
                minWidth: '151px',
                margin
              },
              contents: [
                {
                  component: {
                    default: 'CustomFieldWithLabelNarrow'
                  },
                  source: 'projectManager.name',
                  options: {
                    label: 'Project Manager',
                    placeholder: '',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary',
                    isRequired: false
                  }
                }
              ]
            }
          : {
              options: {
                direction: 'column',
                minWidth: '151px',
                margin
              },
              contents: []
            },
        isVendorInvoiceApproval
          ? {
              options: {
                direction: 'column',
                minWidth: '151px',
                margin
              },
              contents: [
                {
                  component: {
                    default: 'CustomFieldWithLabelNarrow'
                  },
                  source: 'invoiceAmount',
                  options: {
                    label: 'Invoice Amount',
                    placeholder: '',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary',
                    isRequired: false,
                    type: 'currency'
                  }
                }
              ]
            }
          : {
              options: {
                direction: 'column',
                minWidth: '151px',
                margin
              },
              contents: []
            }
      ]
    },
    {
      options: {
        direction: 'row'
      },
      contents: [
        {
          options: {
            direction: 'column',
            minWidth: '70px',
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'OrderedBy.name',
              options: {
                label: 'Ordered By',
                placeholder: '',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            minWidth: '106px',
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'issuedBy',
              options: {
                isUtcDate: true,
                label: 'Issued By',
                placeholder: '',
                fullWidth: false,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            minWidth: '106px',
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'dueDate',
              options: {
                isUtcDate: true,
                label: 'Due Date',
                placeholder: '',
                fullWidth: false,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            minWidth: '106px',
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'paymentTerm.name',
              options: {
                label: 'Payment Terms',
                placeholder: '',
                fullWidth: false,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            minWidth: '151px',
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'PurchaseOrder.assignedTo.name',
              options: {
                label: 'Assigned To',
                placeholder: '',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            minWidth: '151px',
            margin
          },
          contents: []
        }
      ]
    }
  ];
};

const rbFrameTopFormEditContents = (data, departmentsAreLimitedByJob, isVendorInvoiceApproval) => {
  return [
    // the section title
    {
      options: {
        size: 1,
        direction: 'row'
      },
      contents: [
        {
          options: {
            margin: '0px 0px 10px 0px'
          },
          contents: [
            {
              component: {
                edit: 'MuiFormSectionTitle'
              },
              options: {
                label: 'Receipt Information'
              }
            }
          ]
        }
      ]
    },

    // the 1st row
    {
      options: {
        direction: 'row',
        grow: 0,
        flexWrap: 'nowrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            margin: marginEdit,
            flex: defaultFlexStyle
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'jobOrProjectText',
              options: {
                label: 'Job / Project',
                placeholder: '',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false,
                linkPath: '',
                linkReference: 'jobOrProjectLink'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            margin: marginEdit,
            flex: defaultFlexStyle
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'poType.name',
              options: {
                label: 'PO Type',
                placeholder: '',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin: marginEdit
          },
          contents: [
            {
              component: {
                edit: 'SearchBar'
              },
              source: 'Department',
              options: {
                resultFormatFunction: department => `${department.tagName}`,
                searchFunction:
                  departmentsAreLimitedByJob && data.jobId
                    ? () => getDepartmentsByJob(data.jobId)
                    : departmentSearch,
                searchColumns: ['tagName'],
                label: 'Department',
                placeholder: 'Select Department',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false,
                searchOnOpen: departmentsAreLimitedByJob
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin: marginEdit
          },
          contents: [
            {
              component: {
                edit: 'TextInput'
              },
              source: 'vendorDocumentNumber',
              options: {
                label: 'Vendor Document Number',
                placeholder: 'Enter Vendor Document Number',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary'
              }
            }
          ]
        },
        isVendorInvoiceApproval
          ? {
              options: {
                direction: 'column',
                margin: marginEdit,
                flex: defaultFlexStyle
              },
              contents: [
                {
                  component: {
                    default: 'CustomFieldWithLabelNarrow'
                  },
                  source: 'projectManager.name',
                  options: {
                    label: 'Project Manager',
                    placeholder: '',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary',
                    isRequired: false
                  }
                }
              ]
            }
          : {
              options: {
                direction: 'column',
                margin: marginEdit,
                flex: defaultFlexStyle
              },
              contents: []
            },
        isVendorInvoiceApproval
          ? {
              options: {
                direction: 'column',
                flex: defaultFlexStyle
              },
              contents: [
                {
                  component: {
                    edit: 'CurrencyInput'
                  },
                  source: 'invoiceAmount',
                  options: {
                    label: 'Invoice Amount',
                    placeholder: 'Enter Invoice Amount',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary'
                  }
                }
              ]
            }
          : {
              options: {
                direction: 'column',
                flex: defaultFlexStyle
              },
              contents: []
            }
      ]
    },

    // the 2nd row
    {
      options: {
        direction: 'row',
        grow: 0,
        flexWrap: 'nowrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin: marginEdit
          },
          contents: [
            {
              component: {
                edit: 'SearchBar'
              },
              source: 'OrderedBy',
              options: {
                resultFormatFunction: employee => `${employee.name}`,
                searchFunction: employeeSearch,
                searchColumns: ['name'],
                label: 'Ordered By',
                placeholder: 'Select Employee',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin: marginEdit
          },
          contents: [
            {
              component: {
                edit: 'DateInput'
              },
              source: 'issuedBy',
              options: {
                label: 'Issued By',
                placeholder: 'Enter Date',
                fullWidth: false,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: true,
                timezone: Timezone.GMT
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            margin: marginEdit,
            flex: defaultFlexStyle
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'dueDate',
              options: {
                isUtcDate: true,
                label: 'Due Date',
                placeholder: '',
                fullWidth: false,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin: marginEdit
          },
          contents: [
            {
              component: {
                edit: 'SearchBar'
              },
              source: 'paymentTerm',
              options: {
                resultFormatFunction: paymentTerm => `${paymentTerm.name}`,
                searchFunction: paymentTermSearch,
                searchColumns: ['name'],
                label: 'Payment Terms',
                placeholder: '',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            margin: marginEdit,
            flex: defaultFlexStyle
          },
          contents: [
            {
              component: {
                edit: 'SearchBar'
              },
              source: 'PurchaseOrder.assignedTo',
              options: {
                resultFormatFunction: employee => `${employee.name}`,
                searchFunction: employeeSearch,
                searchColumns: ['name'],
                searchParams: [data],
                label: 'Assign To',
                placeholder: 'Select Assignee',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              }
            }
          ]
        }
      ]
    }
  ];
};

const rbFrameTopFormLayout = ({
  data,
  departmentsAreLimitedByJob,
  isVendorInvoiceApproval,
  handleEditImage,
  handleDeleteImage,
  disableVendorInvoiceEdit
}) => {
  return {
    fields: generateDefaultFieldsObject(rbFrameTopFormFields),
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: rbFrameTopFormDefaultContents(
          isVendorInvoiceApproval,
          handleEditImage,
          handleDeleteImage,
          disableVendorInvoiceEdit
        )
      },
      edit: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: rbFrameTopFormEditContents(
          data,
          departmentsAreLimitedByJob,
          isVendorInvoiceApproval
        )
      }
    }
  };
};

export { rbFrameTopFormFields, rbFrameTopFormLayout };
