import { FieldType, TH, theme, TV, TW } from '@BuildHero/sergeant';
import * as R from 'ramda';

import { getCloudinaryImageUrl, isImageAttachment } from '@pm/components/utils';

const NoteTypesMap = {
  WORK_COMPLETED: {
    sortOrder: 1,
    label: 'WORK PERFORMED'
  },
  DELAYS_ISSUES: {
    sortOrder: 2,
    label: 'DELAYS/ISSUES'
  },
  OUT_OF_SCOPE_WORK: {
    sortOrder: 3,
    label: 'OUT OF SCOPE WORK'
  },
  SAFETY_CONCERNS: {
    sortOrder: 4,
    label: 'SAFETY CONCERNS'
  },
  OTHER_OBSERVABLES: {
    sortOrder: 5,
    label: 'OTHER OBSERVATIONS'
  },
  INTERNAL_NOTES: {
    sortOrder: 6,
    label: 'INTERNAL NOTES'
  }
};

const getPayrollHoursColumns = payrollHourTypes => {
  return (
    R.sortBy(item => item?.sortOrder, payrollHourTypes || [])?.map(payrollHour => ({
      id: payrollHour?.id,
      label: payrollHour?.hourTypeAbbreviation || '',
      width: `${400 / (5.64 * payrollHourTypes.length)}%`,
      style: { maxWidth: `${78 / 5.64}%` },
      type: FieldType.NUMBER
    })) || []
  );
};

const getImageContent = value => ({
  component: 'Image',
  props: {
    value,
    style: {
      maxWidth: '129px',
      maxHeight: '114px',
      objectFit: 'cover',
      marginLeft: 6,
      marginRight: 6
    }
  }
});

const getNoteSectionContent = noteSection => {
  const attachmentsUrls = R.pipe(
    R.filter(isImageAttachment),
    R.map(R.prop('fileUrl')),
    R.map(getCloudinaryImageUrl)
  )(noteSection?.attachments || []);

  return {
    contents: [
      {
        props: {
          style: {
            flexDirection: 'row',
            marginRight: 16
          }
        },
        contents: [
          {
            component: 'Typography',
            props: {
              value: NoteTypesMap[noteSection?.sectionType]?.label || '',
              pdf: {
                variant: TV.S3,
                style: { width: 90, marginRight: 12, marginBottom: 4 }
              }
            }
          },
          {
            component: 'Typography',
            props: {
              value: noteSection?.summary || '',
              pdf: {
                style: { width: 460, marginBottom: 4 }
              }
            }
          }
        ]
      },
      {
        props: {
          style: {
            flexDirection: 'row',
            marginBottom: 8
          }
        },
        contents: [
          {
            props: {
              style: {
                width: '100%',
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'flex-start'
              }
            },
            contents: attachmentsUrls.map(getImageContent)
          }
        ]
      }
    ]
  };
};

const PDFDocumentLayout = ({
  weather,
  notes,
  attachments,
  materials,
  equipment,
  subcontractors,
  payrollHourTypes,
  timesheets,
  productivitySummary
}) => {
  const noteContents = notes?.map(note => {
    const noteSections = R.pipe(
      R.filter(item => Boolean(item?.summary) || item?.attachments?.length),
      R.sortBy(item => NoteTypesMap[item?.sectionType]?.sortOrder || Number.MAX_SAFE_INTEGER)
    )(note?.noteSections || []);

    return {
      props: {
        style: {
          flexDirection: 'column',
          justifyContent: 'flex-start',
          pdf: {
            marginBottom: 8
          }
        }
      },
      contents: [
        noteSections.length
          ? {
              props: {
                style: {
                  flexDirection: 'row'
                }
              },
              contents: [
                {
                  component: 'Typography',
                  props: {
                    value: 'Notes',
                    weight: TW.BOLD,
                    pdf: {
                      variant: TV.S2,
                      style: { marginBottom: 4 }
                    }
                  }
                }
              ]
            }
          : null,
        ...noteSections.map(getNoteSectionContent)
      ]
    };
  });

  const attachmentContents = attachments?.map(image => {
    return {
      component: 'Image',
      props: {
        value: `${image.fileUrl}`,
        style: {
          maxWidth: '129px',
          maxHeight: '114px',
          objectFit: 'cover',
          marginLeft: 6,
          marginRight: 6
        }
      }
    };
  });

  const weatherContents = weather
    ?.filter((_item, index) => index >= 6 && index <= 18 && index % 3 === 0)
    .map(weatherRow => {
      return {
        props: {
          style: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            pdf: {
              marginBottom: 0
            }
          }
        },
        contents: [
          {
            props: {
              style: {
                marginRight: 32,
                width: '7%'
              }
            },
            contents: [
              {
                component: 'Typography',
                props: {
                  value: `${weatherRow?.time ?? '-'}`.toUpperCase(),
                  pdf: {
                    variant: TV.S3,
                    weight: TW.BOLD,
                    style: { marginBottom: 0 }
                  }
                }
              }
            ]
          },
          {
            props: {
              style: {
                marginRight: 8,
                width: '10%'
              }
            },
            contents: [
              {
                component: 'Image',
                props: {
                  value: `${weatherRow?.imgUrl}`,
                  style: {
                    maxWidth: '25px',
                    maxHeight: '15px',
                    objectFit: 'cover'
                  }
                }
              }
            ]
          },
          {
            props: {
              style: {
                marginRight: 8,
                width: '10%'
              }
            },
            contents: [
              {
                component: 'Typography',
                props: {
                  value: `${weatherRow?.weatherType ?? '-'}`,
                  pdf: {
                    variant: TV.S3,
                    weight: TW.BOLD,
                    style: { marginBottom: 0 }
                  }
                }
              }
            ]
          },
          {
            props: {
              style: {
                marginRight: 8,
                width: '10%'
              }
            },
            contents: [
              {
                component: 'Typography',
                props: {
                  value: `${weatherRow?.feelsLike ? Math.round(weatherRow?.feelsLike) : '-'} °F`,
                  pdf: {
                    variant: TV.S3,
                    style: { marginBottom: 0 }
                  }
                }
              }
            ]
          },
          {
            props: {
              style: {
                marginRight: 8,
                width: '10%'
              }
            },
            contents: [
              {
                component: 'Typography',
                props: {
                  value: `${weatherRow?.humidity || '-'} %`,
                  pdf: {
                    variant: TV.S3,
                    style: { marginBottom: 0 }
                  }
                }
              }
            ]
          },
          {
            props: {
              style: {
                marginRight: 8,
                width: '10%'
              }
            },
            contents: [
              {
                component: 'Typography',
                props: {
                  value: `${weatherRow?.windSpeed ? Math.round(weatherRow?.windSpeed) : '-'} mph`,
                  pdf: {
                    variant: TV.S3,
                    style: { marginBottom: 0 }
                  }
                }
              }
            ]
          }
        ]
      };
    });

  return {
    layoutsList: ['default', 'pdf'],
    layouts: {
      pdf: {
        props: {
          title: 'Daily Report',
          pageSize: 'letter'
        },
        contents: [
          // 1st row: logo image, company address and phone
          {
            props: {
              style: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                pdf: {
                  height: 36,
                  marginBottom: 16
                },
                default: {
                  height: 52,
                  marginBottom: 20
                }
              }
            },
            contents: [
              {
                component: 'Image',
                source: 'senderLogoUrl',
                props: {
                  style: {
                    height: '100%'
                  }
                }
              },
              {
                props: {
                  style: {
                    alignItems: 'flex-end'
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    source: 'senderAddress',
                    props: {
                      height: TH.COMFORTABLE,
                      style: {
                        width: 160,
                        textAlign: 'right'
                      },
                      default: { variant: TV.PRINT }
                    }
                  },
                  {
                    component: 'Typography',
                    source: 'senderPhoneNumber',
                    props: {
                      height: TH.COMFORTABLE,
                      default: { variant: TV.PRINT }
                    }
                  }
                ]
              }
            ]
          },

          // 2nd row: Daily report number and date
          {
            props: {
              style: {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }
            },
            contents: [
              {
                props: {
                  style: {
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 24
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      weight: TW.BOLD,
                      value: 'Daily Report',
                      default: { variant: TV.XL },
                      pdf: { variant: TV.BASE }
                    }
                  },
                  {
                    component: 'Typography',
                    source: 'packageNumber',
                    props: {
                      default: { variant: TV.L, style: { marginLeft: 8 } },
                      pdf: { variant: TV.BASE, style: { marginLeft: 4 } }
                    }
                  }
                ]
              },
              {
                component: 'Typography',
                source: 'date',
                props: {
                  default: {
                    label: 'Issued Date',
                    style: { width: 'auto', marginBottom: 12 },
                    variant: TV.L
                  },
                  pdf: { style: { marginBottom: 24 }, variant: TV.S2 }
                }
              }
            ]
          },

          // 3rd row: project information
          {
            props: {
              style: {
                flexDirection: 'row',
                justifyContent: 'flex-start',
                default: {
                  marginBottom: 17
                },
                pdf: {
                  marginBottom: 24
                }
              }
            },
            contents: [
              {
                props: {
                  style: {
                    flexDirection: 'column',
                    marginRight: 16,
                    width: '20%'
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      value: 'PROJECT NAME',
                      default: {
                        variant: TV.BASE,
                        style: { marginBottom: 8 }
                      },
                      pdf: {
                        variant: TV.S3,
                        style: { marginBottom: 4 }
                      }
                    }
                  },
                  {
                    component: 'Typography',
                    source: 'projectName'
                  }
                ]
              },
              {
                props: {
                  style: {
                    flexDirection: 'column',
                    marginRight: 16,
                    minWidth: 80
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      value: 'PROJECT ADDRESS',
                      default: {
                        variant: TV.BASE,
                        style: { marginBottom: 8 }
                      },
                      pdf: {
                        variant: TV.S3,
                        style: { marginBottom: 4 }
                      }
                    }
                  },
                  {
                    component: 'Typography',
                    source: 'projectAddress'
                  }
                ]
              }
            ]
          },

          // 4th row: ship to address title
          {
            props: {
              style: {
                flexDirection: 'row',
                justifyContent: 'flex-start'
              }
            },
            contents: [
              {
                props: {
                  style: {
                    flexDirection: 'column'
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      weight: TW.BOLD,
                      value: 'From',
                      default: {
                        variant: TV.BASE,
                        style: { marginBottom: 8 }
                      },
                      pdf: {
                        variant: TV.S2,
                        style: { marginBottom: 4 }
                      }
                    }
                  }
                ]
              }
            ]
          },
          // 4-1 row: name, email
          {
            props: {
              style: {
                flexDirection: 'row',
                justifyContent: 'flex-start',
                default: {
                  marginBottom: 8
                },
                pdf: {
                  marginBottom: 8
                }
              }
            },
            contents: [
              {
                props: {
                  style: {
                    flexDirection: 'column',
                    marginRight: 16,
                    width: '20%'
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      value: 'NAME',
                      default: {
                        variant: TV.BASE,
                        style: { marginBottom: 8 }
                      },
                      pdf: {
                        variant: TV.S3,
                        style: { marginBottom: 4 }
                      }
                    }
                  },
                  {
                    component: 'Typography',
                    source: 'fromName'
                  }
                ]
              },
              {
                props: {
                  style: {
                    flexDirection: 'column',
                    marginRight: 16,
                    minWidth: 30
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      value: 'EMAIL',
                      default: {
                        variant: TV.BASE,
                        style: { marginBottom: 8 }
                      },
                      pdf: {
                        variant: TV.S3,
                        style: { marginBottom: 4 }
                      }
                    }
                  },
                  {
                    component: 'Typography',
                    source: 'fromEmail'
                  }
                ]
              }
            ]
          },
          // 4-2 row: company, address
          {
            props: {
              style: {
                flexDirection: 'row',
                justifyContent: 'flex-start',
                default: {
                  marginBottom: 8
                },
                pdf: {
                  marginBottom: 12
                }
              }
            },
            contents: [
              {
                props: {
                  style: {
                    flexDirection: 'column',
                    marginRight: 16,
                    width: '20%'
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      value: 'COMPANY',
                      default: {
                        variant: TV.BASE,
                        style: { marginBottom: 8 }
                      },
                      pdf: {
                        variant: TV.S3,
                        style: { marginBottom: 4 }
                      }
                    }
                  },
                  {
                    component: 'Typography',
                    source: 'fromCompany'
                  }
                ]
              },
              {
                props: {
                  style: {
                    flexDirection: 'column',
                    marginRight: 16,
                    minWidth: 30
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      value: 'ADDRESS',
                      default: {
                        variant: TV.BASE,
                        style: { marginBottom: 8 }
                      },
                      pdf: {
                        variant: TV.S3,
                        style: { marginBottom: 4 }
                      }
                    }
                  },
                  {
                    component: 'Typography',
                    source: 'fromCompanyAddress'
                  }
                ]
              }
            ]
          },

          // Divider
          weather?.length
            ? {
                component: 'Divider'
              }
            : null,

          // 5th row: Weather
          weather?.length
            ? {
                component: 'Typography',
                props: {
                  weight: TW.BOLD,
                  value: 'Weather',
                  default: {
                    variant: TV.BASE,
                    style: { marginBottom: 8 }
                  },
                  pdf: {
                    variant: TV.S2,
                    style: { marginBottom: 4 }
                  }
                }
              }
            : null,
          weather?.length
            ? {
                props: {
                  style: {
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    pdf: {
                      marginBottom: 4
                    }
                  }
                },
                contents: [
                  {
                    props: {
                      style: {
                        marginRight: 32,
                        width: '7%'
                      }
                    },
                    contents: [
                      {
                        component: 'Typography',
                        props: {
                          value: 'Time',
                          pdf: {
                            variant: TV.S3,
                            color: theme.palette.text.secondary,
                            style: { marginBottom: 0 }
                          }
                        }
                      }
                    ]
                  },
                  {
                    props: {
                      style: {
                        marginRight: 8,
                        width: '10%'
                      }
                    },
                    contents: [
                      {
                        component: 'Typography',
                        props: {
                          value: 'Weather',
                          pdf: {
                            variant: TV.S3,
                            color: theme.palette.text.secondary,
                            style: { marginBottom: 0 }
                          }
                        }
                      }
                    ]
                  },
                  {
                    props: {
                      style: {
                        marginRight: 8,
                        width: '10%'
                      }
                    },
                    contents: [
                      {
                        component: 'Typography',
                        props: {
                          value: 'Type',
                          pdf: {
                            variant: TV.S3,
                            color: theme.palette.text.secondary,
                            style: { marginBottom: 0 }
                          }
                        }
                      }
                    ]
                  },
                  {
                    props: {
                      style: {
                        marginRight: 8,
                        width: '10%'
                      }
                    },
                    contents: [
                      {
                        component: 'Typography',
                        props: {
                          value: 'Temperature',
                          pdf: {
                            variant: TV.S3,
                            color: theme.palette.text.secondary,
                            style: { marginBottom: 0 }
                          }
                        }
                      }
                    ]
                  },
                  {
                    props: {
                      style: {
                        marginRight: 8,
                        width: '10%'
                      }
                    },
                    contents: [
                      {
                        component: 'Typography',
                        props: {
                          value: 'Humidity',
                          pdf: {
                            variant: TV.S3,
                            color: theme.palette.text.secondary,
                            style: { marginBottom: 0 }
                          }
                        }
                      }
                    ]
                  },
                  {
                    props: {
                      style: {
                        marginRight: 8,
                        width: '10%'
                      }
                    },
                    contents: [
                      {
                        component: 'Typography',
                        props: {
                          value: 'Wind Speed',
                          pdf: {
                            variant: TV.S3,
                            color: theme.palette.text.secondary,
                            style: { marginBottom: 0 }
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            : null,

          // weather
          ...weatherContents,

          // Divider
          notes[0]?.workCompleted ||
          notes[0]?.issues ||
          notes[0]?.outOfScopeWork ||
          notes[0]?.safetyConcerns ||
          notes[0]?.otherObservations ||
          notes[0]?.internalNotes
            ? { component: 'Divider' }
            : null,

          // 6th row: Notes and Attachments
          ...noteContents,
          {
            props: {
              style: {
                width: '100%',
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'flex-start'
              }
            },
            contents: [...attachmentContents]
          },

          // Divider
          timesheets?.length ? { component: 'Divider' } : null,

          // 7th row: Labor Productivity
          timesheets?.length
            ? {
                component: 'Table',
                source: 'timesheets',
                props: {
                  title: 'Workers',
                  onMove: () => {},
                  onCreate: () => {},
                  onUpdate: () => {},
                  onDelete: () => {},
                  columns: [
                    {
                      id: 'name',
                      label: 'Name',
                      width: `${80 / 5.64}%`,
                      weight: TW.BOLD
                    },
                    {
                      id: 'phaseName',
                      label: 'Phase',
                      width: `${80 / 5.64}%`
                    },
                    ...getPayrollHoursColumns(payrollHourTypes)
                  ]
                }
              }
            : null,
          productivitySummary?.length ? { component: 'Divider' } : null,

          // 8th row: Installed Units & Unproductive Hours
          productivitySummary?.length
            ? {
                component: 'Table',
                source: 'productivitySummary',
                props: {
                  title: 'Installed Units & Unproductive Hours',
                  onMove: () => {},
                  onCreate: () => {},
                  onUpdate: () => {},
                  onDelete: () => {},
                  columns: [
                    {
                      id: 'phaseName',
                      label: 'Phase Name',
                      width: `${80 / 5.64}%`
                    },
                    {
                      id: 'installedUnits',
                      label: 'Installed Units',
                      width: `${80 / 5.64}%`,
                      type: FieldType.NUMBER,
                      style: {
                        paddingRight: 8
                      }
                    },
                    {
                      id: 'unproductiveHours',
                      label: 'Unproductive Hours',
                      width: `${80 / 5.64}%`,
                      type: FieldType.NUMBER
                    }
                  ]
                }
              }
            : null,

          // Divider
          materials?.length ? { component: 'Divider' } : null,

          // 9th row: Material
          materials?.length
            ? {
                component: 'Table',
                source: 'materials',
                props: {
                  title: 'Materials',
                  onMove: () => {},
                  onCreate: () => {},
                  onUpdate: () => {},
                  onDelete: () => {},
                  columns: [
                    {
                      id: 'name',
                      label: 'Item Name',
                      width: `${200 / 5.64}%`,
                      weight: TW.BOLD
                    },
                    {
                      id: 'description',
                      label: 'Description',
                      width: `${240 / 5.64}%`
                    },
                    {
                      id: 'quantity',
                      label: 'Quantity',
                      width: `${66 / 5.64}%`,
                      numeric: true
                    },
                    {
                      id: 'unitOfMeasure',
                      label: 'Unit of Measure',
                      width: `${70 / 5.64}%`
                    }
                  ]
                }
              }
            : null,

          // Divider
          equipment?.length ? { component: 'Divider' } : null,

          // 10th row: Equipment
          equipment?.length
            ? {
                component: 'Table',
                source: 'equipment',
                props: {
                  title: 'Equipment',
                  onMove: () => {},
                  onCreate: () => {},
                  onUpdate: () => {},
                  onDelete: () => {},
                  columns: [
                    {
                      id: 'name',
                      label: 'Equipment Name',
                      width: `${200 / 5.64}%`,
                      weight: TW.BOLD
                    },
                    {
                      id: 'description',
                      label: 'Description',
                      width: `${240 / 5.64}%`
                    },
                    {
                      id: 'hours',
                      label: 'Hours',
                      width: `${66 / 5.64}%`,
                      numeric: true
                    },
                    {
                      id: 'comments',
                      label: 'Comments',
                      width: `${70 / 5.64}%`
                    }
                  ]
                }
              }
            : null,

          // Divider
          subcontractors?.length ? { component: 'Divider' } : null,

          // 11th row: Subcontractor
          subcontractors?.length
            ? {
                component: 'Table',
                source: 'subcontractors',
                props: {
                  title: 'Subcontractor',
                  onMove: () => {},
                  onCreate: () => {},
                  onUpdate: () => {},
                  onDelete: () => {},
                  columns: [
                    {
                      id: 'name',
                      label: 'Name',
                      width: `${200 / 5.64}%`,
                      weight: TW.BOLD
                    },
                    {
                      id: 'workersOnsite',
                      label: 'Workers Onsite',
                      width: `${164 / 5.64}%`,
                      numeric: true
                    },
                    {
                      id: 'comments',
                      label: 'Comments',
                      width: `${200 / 5.64}%`
                    }
                  ]
                }
              }
            : null,

          // Divider
          { component: 'Divider' },

          // 12th row: Signature
          {
            props: {
              style: {
                flexDirection: 'row',
                justifyContent: 'flex-start'
              }
            },
            contents: [
              {
                props: {
                  style: {
                    flexDirection: 'column',
                    marginRight: 12,
                    width: '50%'
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      weight: TW.BOLD,
                      value: 'Foreman Signature',
                      pdf: {
                        variant: TV.S2,
                        style: { marginBottom: 8 }
                      }
                    }
                  }
                ]
              },
              {
                props: {
                  style: {
                    flexDirection: 'column'
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      weight: TW.BOLD,
                      value: 'Customer Signature',
                      pdf: {
                        variant: TV.S2,
                        style: { marginBottom: 8 }
                      }
                    }
                  }
                ]
              }
            ]
          },
          // 12-1 row: signature images
          {
            props: {
              style: {
                flexDirection: 'row',
                justifyContent: 'flex-start'
              }
            },
            contents: [
              {
                props: {
                  style: {
                    flexDirection: 'column',
                    marginRight: 12,
                    width: '50%'
                  }
                },
                contents: [
                  {
                    component: 'Image',
                    source: 'foremanSignatureImageUrl',
                    props: {
                      style: {
                        maxWidth: '200px',
                        height: '60px',
                        objectFit: 'contain'
                      }
                    }
                  }
                ]
              },
              {
                props: {
                  style: {
                    flexDirection: 'column'
                  }
                },
                contents: [
                  {
                    component: 'Image',
                    source: 'customerSignatureImageUrl',
                    props: {
                      style: {
                        maxWidth: '200px',
                        height: '60px',
                        objectFit: 'contain'
                      }
                    }
                  }
                ]
              }
            ]
          },
          // 12-2 row: title, name, / title, name
          {
            props: {
              style: {
                flexDirection: 'row',
                justifyContent: 'flex-start',
                pdf: {
                  marginBottom: 8
                }
              }
            },
            contents: [
              {
                props: {
                  style: {
                    flexDirection: 'column',
                    marginRight: 16,
                    width: '10%'
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      value: 'TITLE',
                      pdf: {
                        variant: TV.S3,
                        style: { marginBottom: 4 }
                      }
                    }
                  },
                  {
                    source: 'foremanTitle',
                    component: 'Typography'
                  }
                ]
              },
              {
                props: {
                  style: {
                    flexDirection: 'column',
                    marginRight: 12,
                    width: '16%'
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      value: 'FIRST NAME',
                      pdf: {
                        variant: TV.S3,
                        style: { marginBottom: 4 }
                      }
                    }
                  },
                  {
                    component: 'Typography',
                    source: 'foremanFirstName'
                  }
                ]
              },
              {
                props: {
                  style: {
                    flexDirection: 'column',
                    marginRight: 8,
                    width: '20%'
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      value: 'LAST NAME',
                      pdf: {
                        variant: TV.S3,
                        style: { marginBottom: 4 }
                      }
                    }
                  },
                  {
                    component: 'Typography',
                    source: 'foremanLastName'
                  }
                ]
              },
              {
                props: {
                  style: {
                    flexDirection: 'column',
                    marginRight: 16,
                    width: '10%'
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      value: 'TITLE',
                      pdf: {
                        variant: TV.S3,
                        style: { marginBottom: 4 }
                      }
                    }
                  },
                  {
                    component: 'Typography',
                    source: 'customerTitle'
                  }
                ]
              },
              {
                props: {
                  style: {
                    flexDirection: 'column',
                    marginRight: 12,
                    width: '16%'
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      value: 'FIRST NAME',
                      pdf: {
                        variant: TV.S3,
                        style: { marginBottom: 4 }
                      }
                    }
                  },
                  {
                    component: 'Typography',
                    source: 'customerFirstName'
                  }
                ]
              },
              {
                props: {
                  style: {
                    flexDirection: 'column',
                    width: '20%'
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      value: 'LAST NAME',
                      pdf: {
                        variant: TV.S3,
                        style: { marginBottom: 4 }
                      }
                    }
                  },
                  {
                    component: 'Typography',
                    source: 'customerLastName'
                  }
                ]
              }
            ]
          },
          // 12-3 row: company, email address / company, address
          {
            props: {
              style: {
                flexDirection: 'row',
                justifyContent: 'flex-start',
                pdf: {
                  marginBottom: 12
                }
              }
            },
            contents: [
              {
                props: {
                  style: {
                    flexDirection: 'column',
                    marginRight: 16,
                    width: '20%'
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      value: 'COMPANY',
                      pdf: {
                        variant: TV.S3,
                        style: { marginBottom: 4 }
                      }
                    }
                  },
                  {
                    component: 'Typography',
                    source: 'foremanCompany'
                  }
                ]
              },
              {
                props: {
                  style: {
                    flexDirection: 'column',
                    marginRight: 8,
                    width: '28%'
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      value: 'EMAIL ADDRESS',
                      pdf: {
                        variant: TV.S3,
                        style: { marginBottom: 4 }
                      }
                    }
                  },
                  {
                    component: 'Typography',
                    source: 'foremanEmail'
                  }
                ]
              },
              {
                props: {
                  style: {
                    flexDirection: 'column',
                    marginRight: 16,
                    width: '20%'
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      value: 'COMPANY',
                      pdf: {
                        variant: TV.S3,
                        style: { marginBottom: 4 }
                      }
                    }
                  },
                  {
                    component: 'Typography',
                    source: 'customerCompany'
                  }
                ]
              },
              {
                props: {
                  style: {
                    flexDirection: 'column',
                    marginRight: 16,
                    minWidth: 30
                  }
                },
                contents: [
                  {
                    component: 'Typography',
                    props: {
                      value: 'EMAIL ADDRESS',
                      pdf: {
                        variant: TV.S3,
                        style: { marginBottom: 4 }
                      }
                    }
                  },
                  {
                    component: 'Typography',
                    source: 'customerEmail'
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  };
};

export default PDFDocumentLayout;
