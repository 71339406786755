import { gql } from '@apollo/client/core';
import { sortBy } from 'lodash';
import { useSelector } from 'react-redux';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { generateCompanySortKey } from 'services/core/utils';
import { logErrorWithCallback } from 'utils';

const getLabourTypes = gql`
  query getLabourTypes(
    $partitionKey: String!
    $sortKey: String!
    $filter: TableLabourTypeFilterInput
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      labourTypes(filter: $filter) {
        items {
          id
          name
          version
          isActive
          createdDate
          createdDateTime
          sortOrder
          isArchived
          payrollCosts {
            items {
              id
              cost
              hourTypeId
              costCodeId
              costCode {
                id
                name
              }
              jobCostTypeId
              jobCostType {
                id
                name
              }
            }
          }
          labourTypeBillingHourTypeProducts {
            items {
              id
              labourRateGroupId
              billingHourTypeId
              billingHourType {
                id
                hourType
                hourTypeAbbreviation
              }
              labourTypeId
              productId
              product {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const useLabourTypes = (options = {}) => {
  const user = useSelector(state => state.user);

  const { data, loading, error, refetch } = useExtendedQuery(getLabourTypes, {
    variables: {
      filter: options.filter || { isActive: { eq: true } },
      partitionKey: user.tenantId,
      sortKey: generateCompanySortKey(user.tenantId, user.tenantCompanyId)
    },
    transform: result => {
      const labourTypes = result?.getCompany?.labourTypes?.items || [];
      return sortBy(labourTypes, 'sortOrder');
    },
    onError: (err, snackbar) =>
      logErrorWithCallback(err, snackbar, 'Unable to fetch labour types, please try again later'),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options
  });

  return [data || [], loading, error, refetch];
};

export default useLabourTypes;
