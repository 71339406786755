import React from 'react';

import {
  Button,
  ButtonType,
  MoreButton,
  theme,
  ThemeProvider,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PostAddIcon from '@material-ui/icons/PostAdd';

import FormModalLink from 'scenes/Jobs/JobDetail/componentsV2/FormModalLink';
import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';

import { TaskStatus } from 'utils/AppConstants';
import { Mode } from 'utils/constants';

import { onEditTaskSave } from '../CreateModal/CreateModal.handlers';

export const bulkDeleteConfirmContent = length => ({
  body: 'Are you sure you want to delete these recommendations? This action cannot be undone.',
  title: `Delete [${length}] Recommendations`,
  buttonLabel: 'Delete Recommendations',
  buttonType: ButtonType.ERROR
});

export const deleteConfirmContent = {
  body: 'Are you sure you want to delete this recommendation?',
  title: 'Delete Recommendation',
  buttonLabel: 'Delete Recommendation',
  buttonType: ButtonType.ERROR
};

export const handleMoreInfoNeededClick = async ({
  row,
  setRefetchTrigger,
  tenantId,
  updateTask
}) => {
  try {
    await updateTask({
      tenantId,
      data: {
        moreInfoNeeded: !row.moreInfoNeeded,
        id: row.id,
        version: row.version
      }
    });
    setRefetchTrigger(prev => !prev);
  } catch (error) {
    Logger.info(`Error updating Task ${error}`);
  }
};
export const renderAttachmentData = value => {
  if (value) {
    const parsedAttachments = JSON.parse(value);
    if (!Array.isArray(parsedAttachments)) return;

    const handleFileClick = async item => {
      const storageService = new StorageService();
      const attachmentUrl = await storageService.getFile(item.fileUrl);
      window.open(attachmentUrl);
    };
    return parsedAttachments.map(item => {
      return (
        <ThemeProvider>
          <Typography
            style={{ cursor: 'pointer', marginRight: 8 }}
            underlined
            variant={TV.BASE}
            weight={TW.REGULAR}
            onClick={() => handleFileClick(item)}
          >
            {item?.fileName}
          </Typography>
        </ThemeProvider>
      );
    });
  }
};

export const renderFormData = (value, deleteCB) => {
  if (value) {
    const formArray = Array.isArray(value) ? value : JSON.parse(value);
    const getFormMetaData = formDataJson => {
      try {
        const formData = formDataJson?.formData;
        return typeof formData === 'string' ? formData : JSON.stringify(formData || {});
      } catch (error) {
        Logger.error(error);
        return '{}';
      }
    };

    return formArray.map(f => {
      if (!f.id) return null;
      const parsedFormDataJson = JSON.parse(f.formDataJson);
      const formMetaData = getFormMetaData(parsedFormDataJson);
      const composedFormData = {
        id: f.id,
        name: f.name || parsedFormDataJson?.name,
        description: parsedFormDataJson?.description,
        formDataId: f?.formDataId,
        formDataJson: formMetaData
      };

      return (
        <div
          key={f.id}
          style={{ marginRight: 8, display: 'flex', justifyContent: 'space-between' }}
        >
          <FormModalLink formData={composedFormData} />
          {deleteCB && (
            <Button
              endIcon={<CloseIcon />}
              style={{ padding: 8 }}
              type="leading"
              onClick={() => deleteCB(f)}
            />
          )}
        </div>
      );
    });
  }
};

export const renderMoreButton = props => {
  const {
    confirmContext,
    deleteTaskById,
    isLoading,
    refetchTaskList,
    row,
    setIsCreateModalOpen,
    setModalConfig,
    setRefetchTrigger,
    setSelectionModel,
    tenantId,
    updateTask
  } = props;
  const handleEditClick = () => {
    setModalConfig({
      btnText: 'Save Recommendation',
      row,
      title: 'Edit Recommendation',
      type: Mode.EDIT,
      onSave: ({ payload, onComplete, refetchTaskData }) => {
        onEditTaskSave({ payload, onComplete, refetchTaskData, ...props });
      }
    });
    setIsCreateModalOpen(true);
  };

  const handleDeleteTask = async () => {
    if (await confirmContext.confirm(deleteConfirmContent)) {
      await deleteTaskById({ id: row?.id, tenantId });
      if (!isLoading) {
        refetchTaskList(tenantId);
        setRefetchTrigger(prev => !prev);
        setSelectionModel([]);
      }
    }
  };
  const options = [
    ...(row.status === TaskStatus.OPEN
      ? [{ icon: EditIcon, label: 'Edit', onClick: handleEditClick }]
      : []),

    {
      icon: PostAddIcon,
      label: 'More Info Needed',
      onClick: () => {
        handleMoreInfoNeededClick({
          row,
          setRefetchTrigger,
          tenantId,
          updateTask
        });
      }
    },
    {
      icon: DeleteIcon,
      label: 'Delete Recommendation',
      onClick: handleDeleteTask
    }
  ];
  return <MoreButton loading={isLoading} options={options} />;
};

export const renderMoreInfoNeeded = (row = {}, style = {}) => {
  return (
    <ThemeProvider>
      <Typography
        style={{
          background: row.moreInfoNeeded
            ? theme.palette.other?.supportYellow1 ?? theme.palette.support.yellow.medium
            : 'none',
          padding: '3px 2px',
          ...style
        }}
        variant={TV.S1}
      >
        {row.moreInfoNeeded ? 'More Info Needed' : ''}
      </Typography>
    </ThemeProvider>
  );
};

export const composeSilentFilters = selectedTab => {
  let silentFilter = null;
  let silentFilterOperator = null;
  switch (selectedTab) {
    case 0:
      silentFilter = [{ columnField: 'status', operatorValue: 'equals', value: TaskStatus.OPEN }];
      break;
    case 1:
      silentFilter = [
        { columnField: 'status', operatorValue: 'equals', value: TaskStatus.IN_QUOTE },
        { columnField: 'status', operatorValue: 'equals', value: TaskStatus.APPROVED },
        { columnField: 'status', operatorValue: 'equals', value: TaskStatus.REJECTED }
      ];
      silentFilterOperator = 'or';
      break;
    case 2:
      silentFilter = [
        { columnField: 'status', operatorValue: 'equals', value: TaskStatus.IN_JOB },
        { columnField: 'status', operatorValue: 'equals', value: TaskStatus.COMPLETED }
      ];
      silentFilterOperator = 'or';
      break;
    case 3:
      silentFilter = [];
      break;
    default:
      silentFilter = [{ columnField: 'status', operatorValue: 'equals', value: TaskStatus.OPEN }];
  }
  return [silentFilter, silentFilterOperator];
};

export const composeTabMenu = recommendedTaskResponse => {
  const tabMenuData = [
    {
      title: 'Recommended',
      rowCount: (recommendedTaskResponse?.items || []).filter(
        item => item.status === TaskStatus.OPEN
      ).length
    },
    {
      title: 'In Quote',
      rowCount: (recommendedTaskResponse?.items || []).filter(item =>
        [TaskStatus.APPROVED, TaskStatus.IN_QUOTE, TaskStatus.REJECTED].includes(item.status)
      ).length
    },
    {
      title: 'In Job',
      rowCount: (recommendedTaskResponse?.items || []).filter(item =>
        [TaskStatus.IN_JOB, TaskStatus.COMPLETED].includes(item.status)
      ).length
    },
    {
      title: 'All',
      rowCount: recommendedTaskResponse?.rowCount || 0
    }
  ];

  return tabMenuData.map((item, i) => ({
    tabLabel: (
      // eslint-disable-next-line react/no-array-index-key
      <div css={{ display: 'flex', alignItems: 'center' }} key={i}>
        {item.title}
        <span className="row-count-indicator">{item.rowCount}</span>
      </div>
    )
  }));
};
