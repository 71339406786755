import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

import { getCombinedAddress } from 'utils';

import styles from './styles';
import { getHitName } from './utils';

function Hit(props) {
  if (props.noResults) {
    return (
      <ListItem className={props.classes.results} disableGutters>
        <ListItemText className={props.classes.resultsText}>{props.noResultsMessage}</ListItemText>
      </ListItem>
    );
  }

  const { formProps, hit, classes, testingid } = props;
  const {
    displayText,
    delimiter,
    customHitFormatter,
    handleChange,
    showEntityNameInResults
  } = formProps;
  const hitName = getHitName(
    hit,
    displayText,
    delimiter,
    showEntityNameInResults,
    customHitFormatter
  );
  const address = getCombinedAddress(hit);

  return (
    <ListItem
      button
      className={classes.results}
      disableGutters
      testingid={testingid}
      onClick={() => handleChange(hit, hitName)}
    >
      <ListItemText className={classes.resultsText}>
        {hitName}
        {displayText && displayText.includes('address') && address && (
          <>
            <br />
            <p style={{ marginBottom: 0 }}>{address}</p>
          </>
        )}
      </ListItemText>
    </ListItem>
  );
}

export default withStyles(styles)(Hit);
