import React from 'react';

import { TV, Typography } from '@BuildHero/sergeant';
import { useTheme } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PropTypes from 'prop-types';

import { labelSchema } from 'meta/labels';
import { EnumType, SyncStatus as Status } from 'utils/constants';

import useTenantId from '../../customHooks/useTenantId';
import useUserFriendlyError from '../../customHooks/useUserFriendlyError';
import StatusChip from '../StatusChip';

export default function SyncStatus({ locale, syncStatus = Status.NOT_SYNCED, syncLog }) {
  const { palette } = useTheme();
  const tenantId = useTenantId();
  const { loading: loadingUserFriendlyMessage, userFriendlyErrorMessage } = useUserFriendlyError(
    tenantId,
    syncLog
  );

  return (
    <Typography>
      <b>Accounting Software Sync Status: </b>
      <StatusChip
        enumType={EnumType.SYNC_STATUS}
        enumValue={syncStatus}
        label={labelSchema[syncStatus][locale]}
      />
      <br />
      {syncLog && (
        <Typography
          color={palette.error.main}
          style={{ display: 'flex', alignItems: 'center' }}
          variant={TV.S1}
        >
          <ErrorOutlineIcon style={{ fontSize: 16, marginRight: 4 }} />
          <b>Sync Failed: </b>
          <Typography
            color={palette.error.main}
            style={{ display: 'flex', flexDirection: 'column' }}
            variant={TV.S1}
          >
            {!loadingUserFriendlyMessage && userFriendlyErrorMessage && (
              <>
                {userFriendlyErrorMessage}
                <div style={{ fontSize: 9 }}>
                  {userFriendlyErrorMessage && 'Original error: '}
                  {syncLog}
                </div>
              </>
            )}
            {!userFriendlyErrorMessage && <> {syncLog} </>}
          </Typography>
        </Typography>
      )}
    </Typography>
  );
}

SyncStatus.propTypes = {
  locale: PropTypes.string,
  syncStatus: PropTypes.string,
  syncLog: PropTypes.string
};

SyncStatus.defaultProps = {
  locale: 'en',
  syncStatus: undefined,
  syncLog: undefined
};
