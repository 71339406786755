import { connect } from 'react-redux';

import { withDispatchActions, withDispatchStore } from '@dispatch/Dispatch.store';
import { disableQuietRefresh } from 'redux/actions/dispatchActions';
import compose from 'utils/compose';

import VisitsTable from './VisitsTable.component';

const mapActionsToProps = ({ openEvent }) => ({
  openEvent
});

const mapStoreToProps = store => ({
  hoverVisitRow: store.hoverVisitRow,
  clearVisitRowHover: store.clearVisitRowHover
});

const mapStateToProps = state => ({
  refreshTrigger: state.dispatch.refreshTrigger,
  refreshQuietly: state.dispatch.refreshQuietly
});

const mapDispatchToProps = {
  disableQuietRefresh
};

export default compose(
  withDispatchActions(mapActionsToProps),
  withDispatchStore(mapStoreToProps),
  connect(mapStateToProps, mapDispatchToProps)
)(VisitsTable);
