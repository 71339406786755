import useExtendedMutation from 'customHooks/useExtendedMutation';
import deletePurchaseOrderLine from 'services/core/graphql/review-report/mutations/DeletePurchaseOrderLine';

import { GET_REVIEW_REPORT_BY_ID } from '../queries';

export const useDeleteReviewReportPurchaseOrderLine = reviewReportId =>
  useExtendedMutation(deletePurchaseOrderLine, {
    serializer: ({ tenantId, purchaseOrderLineId }) => {
      return {
        variables: {
          partitionKey: tenantId,
          id: purchaseOrderLineId
        }
      };
    },
    showConfirmation: () => true,
    confirmationModalOptions: {
      title: 'Delete Item',
      warn: true,
      modalText: 'Are you sure you want to delete this item?'
    },
    update: (cache, { data: { deletePurchaseOrderLine: purchaseOrderLine } }) => {
      if (!purchaseOrderLine) {
        return;
      }

      const cachedData = cache.readQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        }
      });

      cache.writeQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        },
        data: {
          getReviewReportById: {
            ...(cachedData?.getReviewReportById || {}),
            purchaseOrders: {
              __typename: cachedData.getReviewReportById?.purchaseOrderLines?.__typename,
              items:
                cachedData.getReviewReportById?.purchaseOrders?.items?.map(purchaseOrder => {
                  if (purchaseOrder.id === purchaseOrderLine.parentId) {
                    return {
                      ...purchaseOrder,
                      purchaseOrderLines: {
                        __typename: 'PurchaseOrderLineConnection',
                        items:
                          purchaseOrder?.purchaseOrderLines?.items?.filter(
                            item => item?.id !== purchaseOrderLine?.id
                          ) || []
                      }
                    };
                  }
                  return purchaseOrder;
                }) || []
            }
          }
        }
      });
    }
  });
