import React from 'react';

import { CurrencyInput } from '@BuildHero/sergeant';
import { Box, Typography } from '@material-ui/core';

import { Select } from 'components';
import useCostCodes from 'customHooks/useCostCodes';
import useCostTypes from 'customHooks/useCostTypes';
import usePayrollHourTypes from 'customHooks/usePayrollHourTypes';
import { constructSelectOptions } from 'utils/constructSelectOptions';

import styles from './styles';

const PayrollHourRate = ({ user, snackbarOn, ...props }) => {
  const { values } = props.form;
  const { payrollCosts = [] } = values;
  const { tenantId, tenantCompanyId } = user;
  const [payrollHourTypes] = usePayrollHourTypes();
  const [costCodes] = useCostCodes(tenantId, tenantCompanyId, snackbarOn);
  const [costTypes] = useCostTypes(tenantId, tenantCompanyId, snackbarOn);

  const onChange = (value, fieldName, hourTypeId) => {
    const { setFieldValue } = props.form;
    const payrollCostEntry = payrollCosts.find(cost => cost.hourTypeId === hourTypeId);
    if (payrollCostEntry) {
      payrollCostEntry[fieldName] = value;
    } else {
      payrollCosts.push({ hourTypeId, [fieldName]: value });
    }

    setFieldValue('payrollCosts', payrollCosts, props);
  };

  return (
    <Box display="flex" flexDirection="column" paddingTop={3}>
      <Box alignItems="center" display="flex" flexDirection="row">
        <Box alignItems="left" paddingRight={2} width="25%">
          <Typography css={styles.greyCaption} variant="caption">
            PAYROLL HOUR TYPE
          </Typography>
        </Box>
        <Box alignItems="left" paddingRight={2} width="25%">
          <Typography css={styles.greyCaption} variant="caption">
            RATE
          </Typography>
        </Box>
        <Box alignItems="left" paddingRight={2} width="25%">
          <Typography css={styles.greyCaption} variant="caption">
            COST CODE
          </Typography>
        </Box>
        <Box alignItems="left" paddingRight={2} width="25%">
          <Typography css={styles.greyCaption} variant="caption">
            COST TYPE
          </Typography>
        </Box>
      </Box>
      {payrollHourTypes.map(hourType => {
        const payrollCostEntry = payrollCosts.find(cost => cost.hourTypeId === hourType.id);
        const costTypeOptions = constructSelectOptions(costTypes, 'name', 'id');
        const costCodeOptions = constructSelectOptions(costCodes, 'name', 'id');
        return (
          <Box alignItems="center" display="flex" flexDirection="row">
            <Box alignItems="left" key={`hourBox${hourType.id}`} paddingRight={2} width="25%">
              <Typography key={`hrAbb${hourType.id}`} variant="body2">
                {hourType.hourTypeAbbreviation}
              </Typography>
            </Box>
            <Box alignItems="right" key={`currencyBox${hourType.id}`} paddingRight={2} width="25%">
              <CurrencyInput
                key={`currency${hourType.id}`}
                placeholder="---.--"
                style={{ width: 96 }}
                value={payrollCostEntry?.cost || ''}
                onChange={value => onChange(value, 'cost', hourType.id)}
              />
            </Box>
            <Box
              alignItems="left"
              key={`costcodeBox${hourType.id}`}
              marginTop={0.5}
              paddingRight={2}
              width="25%"
            >
              <Select
                isGreyBackground
                key={`costcodeSelect${hourType.id}`}
                menuHeight="auto"
                options={costCodeOptions}
                value={costCodeOptions.find(item => item.value === payrollCostEntry?.costCodeId)}
                onChange={valueOption => onChange(valueOption.value, 'costCodeId', hourType.id)}
              />
            </Box>
            <Box
              alignItems="left"
              key={`costTypeBox${hourType.id}`}
              marginTop={0.5}
              paddingRight={2}
              width="25%"
            >
              <Select
                isGreyBackground
                key={`costTypeSelect${hourType.id}`}
                menuHeight="auto"
                options={costTypeOptions}
                value={costTypeOptions.find(item => item.value === payrollCostEntry?.jobCostTypeId)}
                onChange={valueOption => onChange(valueOption.value, 'jobCostTypeId', hourType.id)}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default PayrollHourRate;
