import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { useCompanyTimezone } from '@pm/components/hooks';
import { FullScreenModal } from 'components';
import labels from 'meta/Scheduler/labels';
import { getTenantSettingValueForKey } from 'utils';

import { canReschedule, isEditsRestricted } from '../../helper';
import VisitForm from '../VisitForm';
import buildVisitUpdateActionAndPayload from '../VisitForm/helpers/buildVisitUpdateActionAndPayload';
import getCompanyDepartments from '../VisitForm/helpers/getCompanyDepartments';
import getPreferredTechniciansForExistingVisit from '../VisitForm/helpers/getPreferredTechniciansForExistingVisit';
import getTentativeDateAndTimeForExistingVisit from '../VisitForm/helpers/getTentativeDateAndTimeForExistingVisit';
import mutationUpdateVisit from '../VisitForm/helpers/mutationUpdateVisit';
import useAvailableForms from '../VisitForm/helpers/useAvailableForms';
import useCrews from '../VisitForm/helpers/useCrews';
import SubmitButton from '../VisitForm/SubmitButton';

const getTitle = (reschedule, locale) =>
  reschedule ? labels.editVisit_Reschedule_Title[locale] : labels.editVisit_Title[locale];

const VisitEditModal = props => {
  const {
    user,
    visit,
    locale,
    techs,
    open,
    onComplete,
    handleClose,
    propertyAssets,
    job,
    reschedule,
    onSubmit
  } = props;

  const visitFormData = useRef();
  const validateForm = useRef();
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const departments = getCompanyDepartments();
  const availableForms = useAvailableForms(user);
  const preferredTechnicians = getPreferredTechniciansForExistingVisit(visit);
  const tentativeDateAndTime = getTentativeDateAndTimeForExistingVisit(visit);
  const crews = useCrews(user);

  const crewTimeTracking = getTenantSettingValueForKey('crewTimeTracking') === 'true';
  const [{ data: companyTimezone }] = useCompanyTimezone();

  const handleFormChange = data => {
    visitFormData.current = data;
  };

  const handleSubmit = async () => {
    if (!validateForm.current || !visitFormData.current) {
      return;
    }

    try {
      setSubmitting(true);
      setErrorMessage(undefined);

      const isValidForm = await validateForm.current();
      if (isValidForm) {
        const { action, payload } = buildVisitUpdateActionAndPayload({
          jobId: job?.id,
          user,
          departments,
          techs,
          availableForms,
          propertyAssets,
          originalVisit: visit,
          visitFormData: visitFormData.current
        });

        const submitFn = async () => {
          await mutationUpdateVisit({ action, payload });
          onComplete();
        };

        if (onSubmit) {
          await onSubmit(submitFn, payload);
        } else {
          await submitFn();
        }
      }
    } catch (error) {
      setErrorMessage(`There was a problem updating this visit: ${error.message}`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <FullScreenModal
      handleClose={handleClose}
      modalHeaderButtons={<SubmitButton handleSubmit={handleSubmit} submitting={submitting} />}
      open={open}
      title={getTitle(reschedule, locale)}
    >
      <VisitForm
        availableForms={availableForms}
        companyTimezone={companyTimezone}
        crews={crews}
        crewTimeTracking={crewTimeTracking}
        departments={departments}
        disableAssetSelection={false}
        disableSchedule={!canReschedule(visit)}
        errorMessage={errorMessage}
        isEdit
        locale={locale}
        preferredTechnicians={preferredTechnicians}
        propertyAssets={propertyAssets}
        registerValidateCallback={callback => {
          validateForm.current = callback;
        }}
        restrictedEdits={isEditsRestricted(visit)}
        techs={techs}
        tentativeDateAndTime={tentativeDateAndTime}
        visit={visit}
        onFormChange={handleFormChange}
      />
    </FullScreenModal>
  );
};

VisitEditModal.propTypes = {
  user: PropTypes.object.isRequired,
  visit: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  techs: PropTypes.array.isRequired,
  propertyAssets: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  reschedule: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default VisitEditModal;
