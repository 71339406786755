import { useRef } from 'react';

import gql from 'graphql-tag';
import find from 'lodash/find';

import { updateWatchedNonVisitQueries } from '@dispatch/Dispatch.utils';
import { emptyNonVisitDetailsFragment, nonVisitDetailsFragment } from '@dispatch/fragments';
import useExtendedMutation from 'customHooks/useExtendedMutation';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import { getState } from 'redux/store';
import mergeDeep from 'utils/mergeDeep';

const EVENT_TYPE_ID_QUERY = gql`
  query GetBillableNonVisitEventTypeId($partitionKey: String!, $companySortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $companySortKey) {
      id
      eventTypes(entityConnection: "EventType") {
        items {
          id
          name
        }
      }
    }
  }
`;

const ADD_NON_VISIT_EVENT_MUTATION = gql`
    mutation addNonVisitEventsToCompany(
        $partitionKey: String!
        $data: AddNonVisitEventsToCompanyInput!
    ) {
        addNonVisitEventsToCompany(partitionKey: $partitionKey, data: $data) {
            ${nonVisitDetailsFragment}
        }
    }
`;

const selectEventTypeId = (response, eventEntityType) => {
  const eventType = find(
    response?.data?.getCompany?.eventTypes?.items,
    event => event?.name === eventEntityType
  );

  return eventType?.id;
};

const createSerializer = ({ eventTypeIdResponseRef, eventEntityType }) => ({
  tenantId,
  tenantCompanyId,
  data,
  visit,
  techId
}) => {
  const { from, to, eventName, description, departmentId, payrollHourTypeId } = data;
  const eventTypeIdResponse = eventTypeIdResponseRef.current;

  const assignedEntity = visit
    ? {
        assignedEntityId: visit.visitId,
        assignedEntityType: 'Visit'
      }
    : undefined;

  return {
    variables: {
      partitionKey: tenantId,
      data: {
        companyId: tenantCompanyId,
        nonVisitEvents: [
          {
            eventTypeId: selectEventTypeId(eventTypeIdResponse, eventEntityType),
            employeeId: techId,
            name: eventName,
            description,
            departmentId,
            payrollHourTypeId,
            plannedStartTimeUTC: from,
            plannedEndTimeUTC: to,
            ...assignedEntity
          }
        ]
      }
    }
  };
};

const createOptimisticResponseFactory = ({ eventTypeIdResponseRef, eventEntityType }) => ({
  data,
  visit,
  techId
}) => {
  const eventTypeIdResponse = eventTypeIdResponseRef.current;

  const optimisticNonVisit = {
    assignedEntityId: visit?.id,
    entityType: 'NonVisitEvent',
    employeeId: techId,
    eventTypeId: selectEventTypeId(eventTypeIdResponse, eventEntityType),
    isActive: true,
    name: data.eventName,
    description: data.description || null,
    plannedStartTimeUTC: data.from,
    plannedEndTimeUTC: data.to
  };

  return {
    addNonVisitEventsToCompany: [mergeDeep(emptyNonVisitDetailsFragment, optimisticNonVisit)],
    isOptimistic: true
  };
};

const useEventTypeId = () => {
  const state = getState();
  const { tenantId, tenantCompanyId } = state.user;
  const companySortKey = `${tenantId}_Company_${tenantCompanyId}`;

  return useExtendedQuery(EVENT_TYPE_ID_QUERY, {
    variables: {
      partitionKey: tenantId,
      companySortKey
    }
  });
};

const useAddNonVisit = (day, eventEntityType) => {
  const eventTypeIdResponse = useEventTypeId();
  const eventTypeIdResponseRef = useRef({});
  eventTypeIdResponseRef.current = eventTypeIdResponse;

  return useExtendedMutation(ADD_NON_VISIT_EVENT_MUTATION, {
    serializer: createSerializer({ eventTypeIdResponseRef, eventEntityType }),
    optimisticResponseFactory: createOptimisticResponseFactory({
      eventTypeIdResponseRef,
      eventEntityType
    }),
    update: (cache, { data }) => {
      const updatedNonVisit = data.addNonVisitEventsToCompany?.[0];
      updateWatchedNonVisitQueries(cache, updatedNonVisit);
    }
  });
};

export default useAddNonVisit;
