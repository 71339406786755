import React, { useState } from 'react';

import { Button, ButtonType, ThemeProvider } from '@BuildHero/sergeant';
import Grid from '@material-ui/core/Grid';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  DefaultButton,
  ResponsiveTable,
  SectionHeader,
  SergeantModal,
  UserPermission
} from 'components';
import ConfirmModal from 'components/Modal/ConfirmDialog';

import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import { Mode } from 'utils/constants';

import { noteLayout, noteRows } from './note-seargent-layout';

const defaultModalState = {
  openNote: false,
  dataRecord: '',
  modalMode: '',
  confirmDialog: false,
  confirmAction: '',
  confirmMessage: ''
};

/**
 * Component to show attachments as list table which uses SG modals
 */
export const NotesSection = props => {
  const {
    user,
    snackbar,
    isLoading,
    dataArray,
    caslKey,
    handleNoteMutations,
    disabledNoteHeader,
    readOnly
  } = props;
  const [modalState, setModalState] = useState(defaultModalState);

  const handleOpenPopUp = (popUpKey, mode, record) => {
    setModalState({ [popUpKey]: true, modalMode: mode, dataRecord: record });
  };

  const handleClosePopUp = () => {
    setModalState(defaultModalState);
  };

  const performNoteDelete = async record => {
    if (!record) {
      return;
    }
    await handleNoteMutations(Mode.DELETE, record);
    setModalState(defaultModalState);
  };

  const handleComplete = async (noteData, stopLoading) => {
    if (!noteData?.subject && !noteData?.note) {
      snackbar('error', 'Note or Subject is required');
      stopLoading();
      return;
    }
    await handleNoteMutations(modalState.modalMode, noteData);
    handleClosePopUp('openNote');
    stopLoading();
  };

  const handleNoteRowActions = (mode, record = {}) => {
    if (mode === Mode.DELETE) {
      setModalState({
        confirmDialog: true,
        confirmAction: async () => performNoteDelete(record),
        confirmMessage: 'note'
      });
    } else {
      handleOpenPopUp('openNote', mode, record);
    }
  };

  const handleCancelConfirmation = () => {
    setModalState({ confirmDialog: false, confirmAction: '', confirmMessage: '' });
  };

  return (
    <Grid item xs={12}>
      <UserPermission action={caslKey} I="new">
        {!disabledNoteHeader && (
          <SectionHeader
            enablePadding
            overrideHeaderButtons={[
              !readOnly && (
                <ThemeProvider>
                  <Button
                    key="addButton"
                    startIcon={<AddCircleOutlineIcon />}
                    type={ButtonType.SECONDARY}
                    onClick={() => handleOpenPopUp('openNote', 'new', '')}
                  >
                    Add Note
                  </Button>
                </ThemeProvider>
              )
            ]}
            title={Labels.notes[user.locale]}
          />
        )}
      </UserPermission>
      <ResponsiveTable
        caslKey={caslKey}
        data={dataArray || []}
        disableFilter
        isLoading={isLoading}
        rowActionButtons={
          readOnly
            ? null
            : {
                [Mode.EDIT]: {
                  label: 'Edit',
                  icon: 'BorderColor'
                },
                [Mode.DELETE]: {
                  label: 'Delete',
                  icon: 'Delete'
                }
              }
        }
        rowActions={handleNoteRowActions}
        rowMetadata={noteRows}
      />
      <SergeantModal
        confirmRemoveItemLabel={modalState.dataRecord?.note || ''}
        data={modalState.dataRecord?.id ? modalState.dataRecord : {}}
        dataType="Note"
        handleClose={handleClosePopUp}
        handlePrimaryAction={handleComplete}
        layout={noteLayout}
        mode={modalState.modalMode}
        open={modalState.openNote}
      />
      <ConfirmModal
        cancel={handleCancelConfirmation}
        confirm={modalState.confirmAction}
        message={modalState.confirmMessage}
        open={modalState.confirmDialog}
      />
    </Grid>
  );
};

NotesSection.propTypes = {
  // injected from redux, set while user logs in
  user: PropTypes.object.isRequired,
  snackbar: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  dataArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  caslKey: PropTypes.string,
  handleNoteMutations: PropTypes.func.isRequired,
  disabledNoteHeader: PropTypes.bool,
  readOnly: PropTypes.bool
};

NotesSection.defaultProps = {
  isLoading: false,
  caslKey: '',
  disabledNoteHeader: false,
  readOnly: false
};

const connectedNotesSection = connect(state => ({ user: state.user }), { snackbar: snackbarOn })(
  NotesSection
);

export default connectedNotesSection;
