import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const ADD_TASK_TO_PROPERTY = gql`
  mutation addTasksToCustomerProperty(
    $partitionKey: String!
    $data: AddTasksToCustomerPropertyInput!
  ) {
    addTasksToCustomerProperty(partitionKey: $partitionKey, data: $data) {
      id
      assetId
      assetValue
      taskNumber
      name
      description
      isRecommended
      parentId
      status
      taskTypeInternal
    }
  }
`;

const serializer = ({ tenantId, customerPropertyId, tasks }) => ({
  variables: {
    partitionKey: tenantId,
    data: {
      customerPropertyId,
      tasks
    }
  }
});

export const useAddTaskToProperty = () => {
  return useExtendedMutation(ADD_TASK_TO_PROPERTY, {
    serializer
  });
};
