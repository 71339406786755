import React from 'react';

import { Modal, TV, TW, Typography } from '@BuildHero/sergeant';
import { Grid } from '@material-ui/core';
import PinDropIcon from '@material-ui/icons/PinDrop';
import PropTypes from 'prop-types';

import MapViewStatic from 'components/MapViewStatic';

const useStyles = theme => ({
  detailText: {
    color: theme.palette.grayscale(50),
    marginTop: '1em',
    marginBottom: '1.5em'
  },
  noGpsText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.grayscale(50),
    marginTop: '2em',
    marginBottom: '2em'
  },
  text: {
    margin: '0.25em'
  }
});

const TimestampDetailModal = ({
  title,
  bodyTextLines,
  gpsLocation: { latitude, longitude },
  isOpen,
  onClose,
  theme
}) => {
  const styles = useStyles(theme);

  if (!isOpen) return null;

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Typography css={styles.text} variant={TV.L} weight={TW.BOLD}>
        {title}
      </Typography>
      <div css={styles.detailText}>
        {bodyTextLines?.map(line => (
          <Typography css={styles.text} variant={TV.BASE}>
            {line}
          </Typography>
        ))}
      </div>
      {latitude && longitude ? (
        <Grid item>
          <MapViewStatic
            coordinates={{
              latitude,
              longitude
            }}
            dimensions={{ height: 250, width: 270 }}
          />
        </Grid>
      ) : (
        <div css={styles.noGpsText}>
          <PinDropIcon />
          <Typography css={styles.text} variant={TV.BASE}>
            GPS Location Unavailable
          </Typography>
        </div>
      )}
    </Modal>
  );
};

TimestampDetailModal.propTypes = {
  title: PropTypes.string.isRequired,
  bodyTextLines: PropTypes.arrayOf(PropTypes.string),
  gpsLocation: PropTypes.shape({
    latitude: PropTypes.string,
    longitude: PropTypes.string
  }),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired
};

TimestampDetailModal.defaultProps = {
  bodyTextLines: [],
  gpsLocation: {
    latitude: '',
    longitude: ''
  },
  isOpen: false
};

export default TimestampDetailModal;
