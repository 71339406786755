import { removeEmptyValues, removeNestedJson } from '../../utils';
import InstructionHelper from '../InstructionHelper';

const loginSchema = (employeeDetails, companyInfo, actionType) => {
  const localEmployeeDetails = employeeDetails;
  if (employeeDetails.__typename) {
    delete localEmployeeDetails.__typename;
  }
  localEmployeeDetails.status = actionType === 'invited' ? 'invited' : 'inactive';

  // adding Login record
  const loginDetails = {
    userName: localEmployeeDetails.userName.trim(),
    email: localEmployeeDetails.email.trim(),
    firstName: localEmployeeDetails.firstName.trim(),
    lastName: localEmployeeDetails.lastName.trim(),
    parentId: localEmployeeDetails.id,
    tenantId: localEmployeeDetails.tenantId,
    tenantCompanyId: localEmployeeDetails.tenantCompanyId,
    isAdmin: false,
    entityType: 'Login',
    actionType: actionType === 'invited' ? 'create' : 'delete',
    isActive: false
  };
  const strippedEmployee = removeNestedJson(localEmployeeDetails);
  const withValidEmployeeAttribute = removeEmptyValues(strippedEmployee);
  const data = [{ ...companyInfo }, { ...withValidEmployeeAttribute }, { ...loginDetails }];

  const ins = new InstructionHelper();
  let temp = ins.oneToMany(0, 1);
  temp = temp.oneToMany(1, 2);
  const { instructionSet } = temp;
  return { data, instructionSet };
};

export default loginSchema;
