import React, { useRef, useState } from 'react';

import { connect } from 'react-redux';

import { DefaultButton, FullScreenModal } from 'components';
import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import { CommonService } from 'services/core';
import { logErrorWithCallback } from 'utils';

import CrewForm from '../CrewForm';

import { ADD_CREW } from '../queries';

export const CreateCrewPopup = props => {
  const { user, open, handleClose, snackbarOn } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const commonService = useRef(new CommonService());
  // need to pass in callback function to return functijkjon to set var to function
  const formService = useRef(null);

  const handleOnComplete = async completedFormData => {
    setIsSubmitting(true);
    try {
      const { data } = await commonService.current.runMutation(
        { input: completedFormData },
        ADD_CREW
      );
      const { id } = data.addCrewToCompany;
      props.history.push(`/settings/crew/view/${id}`);
      handleClose();
    } catch (err) {
      logErrorWithCallback(err, snackbarOn, 'Unable to save crew, please try again later');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FullScreenModal
      handleClose={handleClose}
      modalHeaderButtons={[
        <DefaultButton
          color="primary"
          disabled={isSubmitting}
          key="save"
          label="Save Crew"
          showSpinner={isSubmitting}
          onClick={() => formService.current?.submit()}
        />
      ]}
      open={open}
      title={Labels.createCrew[user.locale]}
    >
      <CrewForm
        layout="edit"
        onComplete={completedFormData => handleOnComplete(completedFormData)}
        onCreateService={serviceFn => {
          formService.current = serviceFn;
        }}
      />
    </FullScreenModal>
  );
};

const mapStateToProps = state => ({ user: state.user });
const mapSnackBarToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});
export default connect(mapStateToProps, mapSnackBarToProps)(CreateCrewPopup);
