// Handle conditional display of itemized quantity for labor/materials
function itemizedQuantity({ quantity, shouldDisplayQuantity, styles, fontFamilyStyle }) {
  return shouldDisplayQuantity
    ? `<td style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;text-align:right;width:48px;" class="restricted">
            <span fontfamily=${fontFamilyStyle} class="text-small" >${quantity}</span>
          </td>`
    : `<td style="border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;text-align:right;width:48px;"><span class="text-small"></span></td>`;
}

export default itemizedQuantity;
