import { gql } from '@apollo/client/core';

import { sortBy } from 'lodash';
import { useSelector } from 'react-redux';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { generateCompanySortKey } from 'services/core/utils';
import { logErrorWithCallback } from 'utils';

const getPayrollHourTypes = gql`
  query getPayrollHourTypes(
    $partitionKey: String!
    $sortKey: String!
    $filter: TablePayrollHourTypeFilterInput
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      payrollHourTypes(filter: $filter) {
        items {
          id
          sortOrder
          hourType
          hourTypeAbbreviation
          billingHourTypeId
          hourTypeTag
          version
          isAutofill
          intacctAccountNumber
          intacctNonBillableAccountNumber
          isArchived
        }
      }
    }
  }
`;

const usePayrollHourTypes = (options = {}) => {
  const user = useSelector(state => state.user);

  const { data, loading, error, refetch } = useExtendedQuery(getPayrollHourTypes, {
    variables: {
      partitionKey: user.tenantId,
      sortKey: generateCompanySortKey(user.tenantId, user.tenantCompanyId),
      filter: options.filter || { isActive: { eq: true } }
    },
    transform: result => {
      const hourTypes = result?.getCompany?.payrollHourTypes?.items || [];
      return sortBy(hourTypes, 'sortOrder');
    },
    onError: (err, snackbar) =>
      logErrorWithCallback(err, snackbar, 'Unable to fetch payroll types, please try again later'),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options
  });

  return [data || [], loading, error, refetch];
};

export default usePayrollHourTypes;
