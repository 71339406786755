import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import ViewIcon from '@material-ui/icons/OpenInNew';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';

import { ButtonArray } from 'components';
import LaborRate from 'components/LabourRateSection/LabourRates';
import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';

import MaterialItems from './MaterialItems';
import PricebookRate from './PricebookRateCard';

import styles from './style';

const buttonArray = ['laborRates', 'materialItems', 'rateCard'];

const Pricebook = props => {
  const { user, classes, laborMeta, laborItems, materialMarkupEntries, agreementInfo } = props;
  const [selectedValue, setValue] = useState(Labels[buttonArray[0]][user.locale]);
  const history = useHistory();

  return (
    <>
      <ErrorBoundaries>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className={classes.viewActions}>
            <ButtonArray
              dataArr={buttonArray}
              handleClick={value => setValue(value)}
              selectedValue={selectedValue}
              style={{ textTransform: 'capitalize' }}
              user={user}
            />
            <div className={classes.auditText}>
              {Labels.viewFullPricebook[user.locale].toUpperCase()}
              <IconButton
                aria-label="View details"
                className={classes.buttonHover}
                onClick={() =>
                  history.push(`/settings/pricebooks/view/${agreementInfo?.pricebookId}`)
                }
              >
                <ViewIcon className={classes.iconButton} />
              </IconButton>
            </div>
          </div>
          {selectedValue === Labels[buttonArray[0]][user.locale] && (
            <div style={{ marginTop: 5 }}>
              <LaborRate
                addNewRates={false}
                data={laborItems}
                disableFilter
                isReadOnly
                metaData={laborMeta}
              />
            </div>
          )}

          {selectedValue === Labels[buttonArray[1]][user.locale] && (
            <MaterialItems pricebookId={agreementInfo?.pricebookId} />
          )}

          {selectedValue === Labels[buttonArray[2]][user.locale] && (
            <PricebookRate
              laborItems={laborItems}
              laborMeta={laborMeta}
              materialMarkupEntries={materialMarkupEntries}
            />
          )}
        </Grid>
      </ErrorBoundaries>
    </>
  );
};

Pricebook.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object
};

Pricebook.defaultProps = {
  classes: {}
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  showSnackbar: (mode, message) => dispatch(snackbarOn(mode, message))
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatcherToProps)
)(Pricebook);
