import { VisitStatuses } from '@dispatch/Dispatch.constants';

const getOptimisticVisit = ({ visit, job }) => ({
  actualDuration: visit.actualDuration ? `${visit.actualDuration} minutes` : undefined,
  description: visit.description,
  extraTechsNumber: visit.extraTechsNumber,
  prerequisites: visit.prerequisites,
  prerequisitesAcknowledged: visit.prerequisitesAcknowledged,
  scheduledFor: visit.scheduledFor,
  status:
    visit.extraTechsNumber && visit.extraTechsNumber > visit.extraTechs?.length
      ? VisitStatuses.UNASSIGNED.value.serverValue
      : VisitStatuses.SCHEDULED.value.serverValue,
  job: {
    customIdentifier: job.customIdentifier,
    customerId: job.customerId,
    customerName: job.customerName,
    jobNumber: job.jobNumber,
    jobTypeInternal: job.jobTypeInternal,
    priority: job.priority,
    status: job.status || '',
    property: {
      companyName: job.property?.companyName
    }
  },
  primaryTechs: {
    items: [
      {
        __typename: 'SystemEntityMap',
        id: `optimistic_primary_tech`,
        mappedEntityId: visit.primaryTechId
      }
    ]
  },
  extraTechs: {
    items: visit.extraTechs?.map((id, i) => ({
      __typename: 'SystemEntityMap',
      id: `optimistic_extra_tech_${i}`,
      mappedEntityId: id
    }))
  }
});

export default getOptimisticVisit;
