import React, { useCallback, useReducer, useState } from 'react';

import { FullScreenModal } from 'components';

import { StepButtonStateConstants } from './InvoiceEmailModal.constants';
import {
  useGetHeaderButtons,
  useGetHeaderCenterComponent,
  useGetSteps
} from './InvoiceEmailModal.hooks';
import { useGetCustomerReps, useGetLoggedInEmployee } from './InvoiceEmailModal.queries';

const getInitialValues = ({ invoiceData, user, senderData }) => {
  const senderEmail = senderData?.personalEmail || senderData?.email;
  return {
    subject: `Invoice ${invoiceData?.invoiceNumber}`,
    invoiceId: invoiceData.invoiceId,
    paymentId: invoiceData.paymentId,
    fromName: user.displayName || undefined,
    replyTo: senderEmail,
    logoUrl: invoiceData.logoUrl,
    body: undefined,
    recipients: undefined,
    tenantId: user.tenantId,
    ccRecipients: senderData.email ? { label: senderEmail, value: senderEmail } : undefined,
    attachments: []
  };
};

const buttonStateReducer = (state, { buttonState, payload }) => {
  const { index } = payload;
  const prevState = [...state];

  switch (buttonState) {
    case StepButtonStateConstants.DISABLED: {
      prevState[index] = { disabled: true, loading: false };
      return [...prevState];
    }
    case StepButtonStateConstants.LOADING: {
      prevState[index] = { disabled: true, loading: true };
      return [...prevState];
    }
    case StepButtonStateConstants.ACTIVE: {
      prevState[index] = { disabled: false, loading: false };
      return [...prevState];
    }
    case StepButtonStateConstants.INITIALIZED: {
      return prevState.map(s => ({ disabled: false, loading: false }));
    }
    default: {
      return state;
    }
  }
};

const InvoiceEmailModal = props => {
  const { open, data, onModalClose, user, title, templates } = props;
  const { customer, billingCustomer, customerProperty } = data;
  const { data: senderData } = useGetLoggedInEmployee({
    user
  });

  const initialValues = getInitialValues({ senderData, user, invoiceData: data });
  const [nextStepHandlers, setNextStepHandlers] = useState([]);
  const [backStepHandlers, setBackStepHandlers] = useState([]);

  const [nextButtonState, dispatchNextButtonState] = useReducer(buttonStateReducer, [
    { disabled: false, loading: false },
    { disabled: false, loading: false }
  ]);

  const [emailData, setEmailData] = useState(initialValues);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const handleButtonStateChange = useCallback((index, buttonState) => {
    dispatchNextButtonState({ buttonState, payload: { index } });
  }, []);

  const handleExited = useCallback(() => {
    setCurrentStepIndex(0);
    setEmailData(initialValues);
  }, [setCurrentStepIndex, setEmailData, initialValues]);

  const { data: customerRepsData, refetch, loading } = useGetCustomerReps(
    customer,
    billingCustomer,
    customerProperty
  );

  const steps = useGetSteps({
    data,
    user,
    initialValues,
    emailData,
    setEmailData,
    nextStepHandlers,
    setNextStepHandlers,
    setBackStepHandlers,
    customerRepsData,
    templates,
    handleButtonStateChange,
    onModalClose
  });

  const HeaderButtons = useGetHeaderButtons({
    nextStepHandlers,
    backStepHandlers,
    index: currentStepIndex,
    setCurrentStepIndex,
    lastIndex: steps.length,
    nextButtonState,
    onModalClose
  });

  const HeaderCenterComponent = useGetHeaderCenterComponent({
    steps,
    currentStepIndex,
    setCurrentStepIndex
  });

  return (
    <FullScreenModal
      centerComponentStyles={{ maxWidth: 550 }}
      contentStyle={{ width: 'auto' }}
      handleClose={onModalClose}
      headerButtonStyles={{ minWidth: 270 }}
      headerCenterComponent={HeaderCenterComponent}
      modalHeaderButtons={HeaderButtons}
      open={open}
      title={title}
      onExited={handleExited}
    >
      {steps[currentStepIndex] && steps[currentStepIndex].render()}
    </FullScreenModal>
  );
};

export default React.memo(InvoiceEmailModal);
