import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

/**
 * Renders Readonly Percentage values
 */
const PercentageDisplay = ({ field, form, specialbehaviour, classes, ...rest }) => {
  const { fontWeight } = specialbehaviour;
  const suffix = '%';
  const { value } = field;
  let fieldLabel = rest.label;
  if (fieldLabel && fieldLabel.charAt(0) === '*') {
    fieldLabel = fieldLabel.slice(1);
  }

  return (
    <>
      <Typography className={classes.label} variant="caption">
        {fieldLabel}
      </Typography>
      <Typography
        className={
          fontWeight && fontWeight === 'normal' ? classes.normalContent : classes.boldContent
        }
      >
        {value || '0%'} {value && suffix}
      </Typography>
    </>
  );
};

export default withStyles(styles)(PercentageDisplay);
