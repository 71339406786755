import React from 'react';

import { useLocation } from 'react-router-dom';

import { useTenantFlags } from 'customHooks/useTenantFlags';
import FeatureFlagsConstants from 'utils/FeatureFlagConstants';

import ReviewReportV2 from '../componentsV2/ReviewReport';
import ReviewReportV1 from '../ReviewReport';

const TechnicianReportRoute = props => {
  const { search } = useLocation();
  const { loading, flags } = useTenantFlags();

  if (loading) return null;

  if (search === '?v2') return <ReviewReportV2 {...props} />;

  if (search === '?v1' || !flags[FeatureFlagsConstants.REPORTS_V2]) {
    return <ReviewReportV1 {...props} />;
  }

  return <ReviewReportV2 {...props} />;
};

export default TechnicianReportRoute;
