import React from 'react';

import { pdf } from '@react-pdf/renderer';
import moment from 'moment';

import { axiosInstancePMAPI as axios } from 'configs/http-client';
import { getState } from 'redux/store';

import { PayApplicationPdf } from './GeneratePdfModal/GeneratePdfModal.component';

import { getPayApplicationNumber } from './GeneratePdfModal/utils';

export const getPayApplicationFileName = payApp => {
  const title = `Pay Application ${getPayApplicationNumber(payApp)}`;
  return `${title.replaceAll(' ', '-')}_${moment().format('MM-DD-YYYY')}.pdf`;
};

const fetchPayAppTableData = ({ projectId, paNumber }) =>
  axios({
    url: `PayApplication/lineitem/tabledata/`,
    params: { projectId, paNumber }
  });

const fetchPayAppSummaryTotals = ({ projectId, paNumber }) =>
  axios({
    url: `PayApplication/summary/totals`,
    params: { projectId, paNumber }
  });

export const generatePayApplicationPdf = async ({ payAppSummary, project }) => {
  const payApplication = payAppSummary?.PayApplication;
  const { company } = getState();

  const projectId = project.id;
  const paNumber = payApplication.number;
  const [payAppTableDataResponse, payAppSummaryTotalsResponse] = await Promise.all([
    fetchPayAppTableData({ projectId, paNumber }),
    fetchPayAppSummaryTotals({ projectId, paNumber })
  ]);
  const fileName = getPayApplicationFileName(payApplication);
  const tableData = payAppTableDataResponse.data;
  const payAppTotals = payAppSummaryTotalsResponse.data;
  const blob = await pdf(
    <PayApplicationPdf
      company={company}
      payAppSummary={payAppSummary}
      payAppTotals={payAppTotals}
      project={project}
      tableData={tableData}
    />
  ).toBlob();
  return new File([blob], fileName, {
    type: blob.type
  });
};
