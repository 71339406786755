import React, { useMemo, useState } from 'react';

import { Input, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { debounce, union } from 'lodash';

import { ConfirmLeave } from 'components';
import WrapTable from 'components/WrapTable';
import useEditPurchaseOrderLine from 'customHooks/useEditPurchaseOrderLine';
import useTenantId from 'customHooks/useTenantId';
import { convertToCurrencyString } from 'utils';
import { ProcurementPurchaseOrderStatus } from 'utils/AppConstants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { SectionHeader } from '../Components';

import PORecieptList from './PORecieptList';

export const incompletePOStatuses = [
  ProcurementPurchaseOrderStatus.DRAFT,
  ProcurementPurchaseOrderStatus.ORDERED
];

const columns = ({ tenantId, onDescriptionBlur, onDescriptionChange, hasProcurementUsage }) =>
  [
    { field: 'itemName', headerName: 'Item Name' },
    {
      field: 'description',
      headerName: 'Description',
      renderCell: ({ row }) => {
        const onBlur = debounce(onDescriptionBlur, 1000);
        return (
          <Input
            defaultValue={row.description}
            disabled={row.readOnly}
            type="text"
            onBlur={e => {
              const { value } = e.target;
              if (value === row.description) return;

              const { id, version } = row;
              onBlur({ tenantId, purchaseOrderLine: { id, version, description: value } });
            }}
            onChange={e => {
              const { value } = e.target;
              if (value === row.description) return;
              onDescriptionChange({ rowId: row.id });
            }}
          />
        );
      }
    },
    { field: 'poNumber', headerName: 'Purchase Order', width: 180, renderCell: PORecieptList },
    // { field: 'image', headerName: 'Image' }, - @TODO future enhacement, images can be viewed from the receipt detail.
    { field: 'recieved', width: 80, headerName: 'Received' },
    hasProcurementUsage && { field: 'used', width: 60, headerName: 'Used' },
    { field: 'unitOfMeasure', width: 70, headerName: 'UOM' },
    {
      field: 'subtotal',
      headerName: 'Subtotal',
      width: 140,
      totalGetter: ({ rows }) => rows.reduce((acc, r) => acc + parseFloat(r.subtotal), 0),
      renderTotal: props => (
        <Typography
          css={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}
          numeric
          variant={TV.BASE}
          weight={TW.BOLD}
        >
          {convertToCurrencyString(props.formattedValue ?? 0)}
        </Typography>
      ),
      renderCell: props => (
        <Typography
          css={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
          numeric
          {...props}
        >
          {convertToCurrencyString(props.formattedValue ?? 0)}
        </Typography>
      )
    }
  ].filter(Boolean);

const relevantBillLines = ({ purchaseOrderLine, billLines }) =>
  billLines.filter(bl => bl.purchaseOrderLine?.id === purchaseOrderLine.id);

const JobCloseoutPurchasedItemsTable = ({
  purchaseOrderLines,
  billLines,
  purchaseOrders,
  isLoading
}) => {
  const tenantId = useTenantId();
  const flags = useFlags();
  const hasProcurementUsage = flags[FeatureFlags.PROCUREMENT_USAGE];

  const [hasTempDataQueue, setHasTempDataQueue] = useState([]);
  const [
    editPurchaseOrderLineMutation,
    { loading: updatingPurchaseOrderLine, error: errorPurchaseOrderLine }
  ] = useEditPurchaseOrderLine({ setHasTempDataQueue });

  const emptyPurchaseOrders = useMemo(() =>
    purchaseOrders.filter(po => po.purchaseOrderLines.items.length === 0)
  );

  const lonelyBillLines = useMemo(() => billLines.filter(bl => !bl.purchaseOrderLine), [billLines]);

  const rows = useMemo(
    () =>
      [
        ...purchaseOrderLines.map(l => ({
          id: l.id,
          version: l.version,
          itemName: l.itemName,
          description: l.description,
          poNumber: {
            label: `PO ${l.purchaseOrder?.poNumber}`,
            id: l.purchaseOrder?.id,
            sortOrder: parseInt(l.purchaseOrder?.poNumber, 10),
            receipts:
              l.purchaseOrderReceiptLines?.items?.map(rl => ({
                id: rl.purchaseOrderReceipt?.id,
                label: `Receipt ${rl.purchaseOrderReceipt.receiptNumber}`
              })) || [],
            poStatus: l.purchaseOrder.status
          },
          recieved: incompletePOStatuses.includes(l.purchaseOrder.status)
            ? '-'
            : `${l.quantityFulfilled} of ${l.quantity}`,
          used: incompletePOStatuses.includes(l.purchaseOrder.status)
            ? '-'
            : l.purchaseOrderLineVisitList.items.reduce(
                (totalUsed, { quantityUsed }) => totalUsed + quantityUsed,
                0
              ),
          unitOfMeasure: l.unitOfMeasure || '-',
          subtotal: relevantBillLines({ purchaseOrderLine: l, billLines }).reduce(
            (acc, bl) => acc + bl.unitCost * bl.quantity,
            0
          )
        })),
        ...emptyPurchaseOrders.map(po => ({
          id: po.id,
          itemName: '-',
          description: '-',
          poNumber: {
            label: `PO ${po.poNumber}`,
            id: po.id,
            sortOrder: parseInt(po.poNumber, 10),
            receipts: [],
            poStatus: po.status
          },
          recieved: '-',
          used: '-',
          unitOfMeasure: '-',
          subtotal: 0,
          readOnly: true
        })),
        ...lonelyBillLines.map(bl => ({
          id: '-',
          itemName: bl.product.name,
          description: bl.description,
          poNumber: {
            label: 'No PO',
            id: null,
            sortOrder: 999999,
            receipts: [
              {
                id: bl.bill.purchaseOrderReceipt.id,
                label: `Receipt ${bl.bill.purchaseOrderReceipt.receiptNumber}`
              }
            ]
          },
          recieved: `${bl.quantity} of ${bl.quantity}`,
          used: '-',
          unitOfMeasure: bl.product.unitOfMeasure?.name || '-',
          subtotal: bl.unitCost * bl.quantity,
          readOnly: true
        }))
      ].sort((a, b) => a.poNumber?.sortOrder - b.poNumber?.sortOrder),
    [purchaseOrderLines, billLines, emptyPurchaseOrders]
  );

  return (
    <div>
      <ThemeProvider>
        <SectionHeader title="Purchased Items" />
        <WrapTable
          columns={columns({
            tenantId,
            onDescriptionChange: ({ rowId }) =>
              setHasTempDataQueue(oldQueue => union(oldQueue, [rowId])),
            onDescriptionBlur: editPurchaseOrderLineMutation,
            hasProcurementUsage
          })}
          enableTotalsRow
          hideFooter={rows.length < 11}
          loading={isLoading}
          loadingRows={3}
          noDataMessage="No Purchased Items"
          rows={rows}
        />
      </ThemeProvider>
      <ConfirmLeave when={updatingPurchaseOrderLine || hasTempDataQueue.length} />
    </div>
  );
};

JobCloseoutPurchasedItemsTable.defaultProps = {
  purchaseOrderLines: [],
  billLines: [],
  purchaseOrders: [],
  isLoading: false
};

export default JobCloseoutPurchasedItemsTable;
