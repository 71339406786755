import React, { useCallback, useState } from 'react';

import { Button, ButtonType, Modal, Select, ThemeProvider } from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { snackbarOn } from 'redux/actions/globalActions';

import { MaintenanceStatus } from 'utils/AppConstants';
import { JobStatus } from 'utils/constants';

import { useCreateTaskForJob } from '../hooks/useCreateTaskForJob';
import { useCustomerPropertyJobs } from '../hooks/useCustomerPropertyJobs';

const AddTaskToJobModal = ({
  open,
  onClose,
  selectedTaskIds,
  refetchTasks,
  customerPropertyId
}) => {
  const [job, setJob] = useState({});

  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const snackbar = useCallback((...args) => dispatch(snackbarOn(...args)), [dispatch]);

  const [openJobs, jobsLoading] = useCustomerPropertyJobs({
    customerPropertyId,
    snackbar,
    filter: {
      stringFilters: {
        fieldName: 'Job.status',
        filterInput: {
          notIn: [
            JobStatus.COMPLETE,
            JobStatus.CANCELED,
            JobStatus.CONVERTED,
            MaintenanceStatus.SKIPPED
          ]
        }
      }
    },
    skip: !open
  });
  const [addTaskToJob, { loading: mutationLoading }] = useCreateTaskForJob();

  if (!open) return null;

  const options =
    openJobs?.map(item => ({
      label: `${item?.jobTypeInternal}-${item?.customIdentifier || item?.jobNumber}`,
      value: get(item, 'id')
    })) || [];

  const addTaskToSelectedJob = async () => {
    try {
      await addTaskToJob({
        partitionKey: user.tenantId,
        data: { jobId: job?.value, jobTasks: selectedTaskIds.map(taskId => ({ taskId })) }
      });

      snackbar('success', `Task${selectedTaskIds?.length > 1 ? 's' : ''} added to ${job.label}`);
      refetchTasks();
    } catch (error) {
      snackbar(
        'error',
        `Unable to add the selected task${selectedTaskIds?.length > 1 ? 's' : ''}`,
        error
      );
    } finally {
      onClose();
    }
  };

  return (
    <ThemeProvider>
      <Modal
        actions={
          <Button
            disabled={isEmpty(job)}
            fullWidth
            loading={mutationLoading}
            type={ButtonType.PRIMARY}
            onClick={addTaskToSelectedJob}
          >
            ADD TASK TO JOB
          </Button>
        }
        open={open}
        title="Add Task To Job/Maintenance"
        onClose={onClose}
      >
        <Box height={140} width={496}>
          <Select
            clearable
            loading={jobsLoading}
            options={options}
            placeholder="Search or Select Job/Maintenance"
            portal
            searchable
            value={job}
            onChange={d => setJob(d)}
          />
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default AddTaskToJobModal;
