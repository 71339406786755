import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';
import { ledgerAccountAndItemGlGroupSearch } from 'services/API/ledgerAccount';
import { getProjectCostCodesByProject } from 'services/API/projectCostCode';
import { getPhaseDeptsByPhase } from 'services/API/projectPhaseDepartment';
import { getPhasesByProject } from 'services/API/projectPhases';

const defaultFlexStyle = '0 1 auto';
const wideItemFlexStyle = '0 0 423px';
const margin = '0px 15px 21px 0px';

// Equipment Information
const fields = {
  description: { required: true },
  quantity: { required: false },
  ProjectCostCode: { required: false },
  sellPrice: { required: false },
  notes: { required: false },
  ProjectPhase: { required: true },
  taxable: { required: false }
};

const contents = (projectId, phaseId, depId, onChange) => [
  // the 1st row
  {
    options: {
      size: 2,
      direction: 'row',
      grow: 1
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: wideItemFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'TextInput',
              edit: 'TextInput'
            },
            source: 'description',
            options: {
              label: 'Description',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'TextInput',
              edit: 'TextInput'
            },
            source: 'quantity',
            options: {
              label: 'Quantity',
              isRequired: false,
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'CurrencyInput',
              edit: 'CurrencyInput'
            },
            source: 'cost',
            options: {
              label: 'Cost',
              isRequired: false,
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              type: 'number',
              onChange
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'CurrencyInput',
              edit: 'CurrencyInput'
            },
            source: 'sellPrice',
            options: {
              label: 'Unit Price',
              isRequired: false,
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      }
    ]
  },

  // the 2nd row
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1,
      flexWrap: 'wrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: wideItemFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'TextInput',
              edit: 'TextInput'
            },
            source: 'notes',
            options: {
              label: 'Notes',
              isRequired: false,
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'phaseSearchBar',
              edit: 'phaseSearchBar'
            },
            source: 'ProjectPhase',
            options: {
              resultFormatFunction: obj => `${obj.name}`,
              searchFunction: () => getPhasesByProject(projectId),
              searchColumns: ['name'],
              label: 'Phase',
              placeholder: 'Search Phase',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true,
              errorText: 'Field is required'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar',
              edit: 'SearchBar'
            },
            source: 'ProjectPhaseDepartment',
            options: {
              resultFormatFunction: obj => obj?.tagName || '',
              searchFunction: () => (phaseId ? getPhaseDeptsByPhase(phaseId) : async () => {}),
              searchColumns: ['tagName'],
              label: 'Department',
              placeholder: 'Search Department',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar',
              edit: 'SearchBar'
            },
            source: 'ProjectCostCode',
            options: {
              resultFormatFunction: obj => `${obj.name} - ${obj.description}`,
              searchFunction: () => getProjectCostCodesByProject(projectId),
              searchColumns: ['name', 'description'],
              label: 'Cost Code',
              placeholder: 'Search Cost Code',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar',
              edit: 'SearchBar'
            },
            source: 'glAccountName',
            options: {
              resultFormatFunction: obj => obj.name,
              searchFunction: ledgerAccountAndItemGlGroupSearch,
              searchColumns: ['name'],
              label: 'G.L. Account',
              placeholder: 'Search G.L. Account',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'row',
          flex: defaultFlexStyle,
          alignItems: 'flex-end',
          margin
        },
        contents: [
          {
            component: {
              default: 'CheckboxInput',
              edit: 'CheckboxInput'
            },
            source: 'taxable',
            options: {
              label: 'Taxable'
            }
          }
        ]
      }
    ]
  }
];

const layout = (projectId, phaseId, depId, onChange) => {
  return {
    fields: generateDefaultFieldsObject(fields),
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: contents(projectId, phaseId, depId, onChange)
      },
      edit: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: contents(projectId, phaseId, depId, onChange)
      }
    }
  };
};

export { fields, layout };
