import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';

import { Button, ButtonType, Modal, ThemeProvider, Typography } from '@BuildHero/sergeant';

const ConfirmModalContext = createContext();

const defaultContent = {
  body: 'Are you sure?',
  title: 'Confirm',
  buttonLabel: 'Confirm',
  buttonType: ButtonType.PRIMARY,
  cancelButtonLabel: 'Cancel',
  cancelButtonType: ButtonType.SECONDARY,
  maxWidth: 'sm'
};

export function ConfirmModalProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState();
  const resolver = useRef();

  const handleClose = useCallback(() => {
    if (resolver.current) resolver.current(false);
    setOpen(false);
  }, []);

  const handleConfirm = useCallback(() => {
    if (resolver.current) resolver.current(true);
    setOpen(false);
  }, []);

  const value = useMemo(
    () => ({
      confirm: c => {
        setContent(c);
        setOpen(true);
        return new Promise(resolve => {
          resolver.current = resolve;
        });
      }
    }),
    []
  );

  return (
    <ConfirmModalContext.Provider value={value}>
      {children}
      <ThemeProvider>
        <Modal
          actions={
            <div style={{ width: '100%' }}>
              <Button fullWidth name="confirm" type={content?.buttonType} onClick={handleConfirm}>
                {content?.buttonLabel ?? defaultContent.buttonLabel}
              </Button>
              {content?.cancelButtonLabel && (
                <Button
                  fullWidth
                  style={{ marginTop: 8 }}
                  type={content?.cancelButtonType ?? defaultContent.cancelButtonType}
                  onClick={handleClose}
                >
                  {content?.cancelButtonLabel ?? defaultContent.cancelButtonLabel}
                </Button>
              )}
            </div>
          }
          maxWidth={content?.maxWidth ?? defaultContent.maxWidth}
          open={open}
          title={content?.title ?? defaultContent.title}
          onClose={handleClose}
        >
          <Typography>{content?.body ?? defaultContent.body}</Typography>
          {content?.body2 && (
            <div style={{ marginTop: 8 }}>
              <Typography>{content?.body2}</Typography>
            </div>
          )}
        </Modal>
      </ThemeProvider>
    </ConfirmModalContext.Provider>
  );
}

export const useConfirmModal = () => useContext(ConfirmModalContext);
