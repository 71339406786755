import { InMemoryCache } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { ApolloClient } from 'apollo-client';
import { Auth } from 'aws-amplify';

class SubscriptionClient {
  config = null;

  client = null;

  static getClient = config => {
    this.config = config;
    const wsLink = new WebSocketLink({
      uri: this.config.subscriptionEndpoint,
      options: {
        reconnect: true,
        minTimeout: 7000,
        connectionParams: async () => {
          console.log('APPSYNC_CLIENT::TOKEN_FETCH');
          return { authorization: (await Auth.currentSession()).getIdToken().getJwtToken() };
        }
      }
    });
    wsLink.setOnError(reason => {
      console.log(`Subscriptin client failed due to ${reason}`);
    });
    return new ApolloClient({
      link: wsLink,
      cache: new InMemoryCache()
    });
  };
}

export default SubscriptionClient;
