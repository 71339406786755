import { useCallback, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import { useDispatch, useSelector } from 'react-redux';

import { COST_TYPE } from 'constants/common';
import { generateCompanySortKey } from 'services/core/utils';
import { Logger } from 'services/Logger';
import { constructSelectOptions } from 'utils/constructSelectOptions';

import { snackbarOn } from '../redux/actions/globalActions';

const GET_JOB_COSTING_ENTITIES = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      jobCostTypes(entityConnection: "JobCostType") {
        nextToken
        items {
          id
          name
          type
        }
      }
      costCodes(entityConnection: "CostCode") {
        nextToken
        items {
          id
          name
        }
      }
    }
  }
`;

export default queryOptions => {
  const [options, setOptions] = useState();
  const { tenantId, tenantCompanyId: companyId } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const snackbar = useCallback((...args) => dispatch(snackbarOn(...args)), [dispatch]);

  const { data: { getCompany: data } = {}, loading, error } = useQuery(GET_JOB_COSTING_ENTITIES, {
    variables: { partitionKey: tenantId, sortKey: generateCompanySortKey(tenantId, companyId) },
    ...queryOptions
  });

  useEffect(() => {
    if (error && snackbar) {
      Logger.error('useJobCostingOptions', error);
      snackbar('error', 'Error loading job costing options. Please try again.');
    }
  }, [error, snackbar]);

  useEffect(() => {
    if (data) {
      const costCodeOptions = constructSelectOptions(data.costCodes.items);
      const { jobCostTypeOptions, revenueTypeOptions } = data.jobCostTypes.items.reduce(
        (acc, { id, name, type }) => {
          // eslint-disable-next-line no-shadow
          const { jobCostTypeOptions, revenueTypeOptions } = acc;
          const option = { label: name, value: id };
          if ([COST_TYPE.REVENUE, COST_TYPE.BOTH].includes(type)) {
            revenueTypeOptions.push(option);
          }
          if ([COST_TYPE.JOB_COST, COST_TYPE.BOTH].includes(type)) {
            jobCostTypeOptions.push(option);
          }
          return { jobCostTypeOptions, revenueTypeOptions };
        },
        { jobCostTypeOptions: [], revenueTypeOptions: [] }
      );
      setOptions({ costCodeOptions, jobCostTypeOptions, revenueTypeOptions });
    }
  }, [data]);

  return { ...options, loading, error };
};
