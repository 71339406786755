import _ from 'lodash';
import moment from 'moment';

import AmplifyService from 'services/AmplifyService';

import SubscriptionClient from '../graphql-services/helper';
import addVisitAssetsToVisit from '../graphql/jobs/mutations/addVisitAssetsToVisit';
import mutation from '../graphql/mutations';
import deleteSystemEntityMap from '../graphql/mutations/delete-mapping';
import deleteVisit from '../graphql/mutations/job/deleteVisit';
import updateVisitQuery from '../graphql/mutations/job/updateVisit';
import updateScheduleQuery from '../graphql/mutations/schedule/updateSchedule';
import saveOffSchedule, {
  deleteOffSchedule,
  saveOffScheduleFull
} from '../graphql/scheduling/visit/mutations/saveOffSchedules';
import saveVisit from '../graphql/scheduling/visit/mutations/saveVisit';
import getDispatchedVisitsInRange from '../graphql/scheduling/visit/queries/getDispatchedVisitsInRange';
import getScheduledInThePastVisits from '../graphql/scheduling/visit/queries/getScheduledInThePastVisits';
import getScheduledInThePastWithCache from '../graphql/scheduling/visit/queries/getScheduledInThePastWithCache';
import getScheduledInThePastWithKeys from '../graphql/scheduling/visit/queries/getScheduledInThePastWithKeys';
import getTechs from '../graphql/scheduling/visit/queries/getTechs';
import getUnassignedVisits from '../graphql/scheduling/visit/queries/getUnassignedVisits';
import getUnassignedVisitsWithCache from '../graphql/scheduling/visit/queries/getUnassignedVisitsWithCache';
import getUnassignedVisitsWithKeys from '../graphql/scheduling/visit/queries/getUnassignedVisitsWithKeys';
import getVisit from '../graphql/scheduling/visit/queries/getVisit';
import getVisitCrew from '../graphql/scheduling/visit/queries/getVisitCrew';
import getVisitsInRange from '../graphql/scheduling/visit/queries/getVisitsInRange';
import getVisitsInRangePast from '../graphql/scheduling/visit/queries/getVisitsInRangePast';
import getVisitsInRangePastWithCache from '../graphql/scheduling/visit/queries/getVisitsInRangePastWithCache';
import getVisitsInRangePastWithKeys from '../graphql/scheduling/visit/queries/getVisitsInRangePastWithKeys';
import getVisitsInRangeWithKeys from '../graphql/scheduling/visit/queries/getVisitsInRangeWithKeys';
import getVisitsOnHold from '../graphql/scheduling/visit/queries/getVisitsOnHold';
import getVisitsOnHoldWithCache from '../graphql/scheduling/visit/queries/getVisitsOnHoldWithCache';
import getVisitsOnHoldWithKeys from '../graphql/scheduling/visit/queries/getVisitsOnHoldWithKeys';
import getVisitVersion from '../graphql/scheduling/visit/queries/getVisitVersion';
import visitUpdateNotification from '../graphql/scheduling/visit/subscriptions/visitUpdateNotification';
import createSchemaMapping from '../mutation-schema/create-schema';
import updateVisitSchemaMapping from '../mutation-schema/jobs/update-visit-schema';
import visitSchemaDataset from '../mutation-schema/jobs/visit-schema-dataset';
import createScheduleMapping from '../mutation-schema/schedule/create-schedule-schema';
import createScheduleWithTimesheetSchemaMapping from '../mutation-schema/schedule/create-schedule-with-timesheet';
import createTimeSheetMapping from '../mutation-schema/timesheet/create-timesheet-schema';
import updateTimeSheetMapping from '../mutation-schema/timesheet/update-timesheet-schema';

import { asyncForEach } from '../utils/index';

import SchedulingRules from './SchedulingRules';

class RulesEngine {
  constructor() {
    this.client = AmplifyService.appSyncClient();
    this.schedulingRules = new SchedulingRules();
    this.subscriptionClient = SubscriptionClient.getClient(AmplifyService.config);
  }

  updateVisit = async visit => {
    const localVisit = {};
    const allowedFields = [
      'sortKey',
      'visitNumber',
      'status',
      'prerequisites',
      'prerequisitesAcknowledged',
      'extraTechsRequired',
      'extraTechsNumber',
      'minimumDuration',
      'actualDuration',
      'scheduledFor',
      'tentativePeriod',
      'tentativeDate',
      'tentativeTime',
      'startTime',
      'endTime',
      'onRoute',
      'delayed',
      'delayedReason',
      'onHold',
      'onHoldReason',
      'online',
      'detailsSent',
      'entityType',
      'version'
    ];
    allowedFields.forEach(key => {
      if (Object.keys(visit).includes(key)) {
        localVisit[key] = visit[key];
        if (localVisit[key] === '') localVisit[key] = null;
      }
    });
    if (!localVisit.version) localVisit.version = 1;
    const dataset = {
      input: localVisit
    };
    let client = AmplifyService.appSyncClient();
    const result = client.mutate(updateVisitQuery, dataset);
    client = null;

    return result;
  };

  chunk = (array, size) => {
    const chunkedArr = [];
    let index = 0;
    while (index < array.length) {
      chunkedArr.push(array.slice(index, size + index));
      index += size;
    }
    return chunkedArr;
  };

  checkTechTimelineConflicts = (tech, events) => {
    const sortedEvents = events.sort((e1, e2) => moment(e1.start).diff(moment(e2.start)));
    const conflicts = [];
    sortedEvents.forEach(event => {
      const localEvent = event;
      delete localEvent.conflict;
    });
    sortedEvents.forEach((event, index) => {
      if (sortedEvents[index + 1]) {
        const nextEvent = sortedEvents[index + 1];
        if (moment(event.end) > moment(nextEvent.start)) {
          nextEvent.conflict = true;
          conflicts.push(nextEvent);
        }
      }
    });
    return conflicts;
  };

  pauseVisit = async (vistSortKey, version, reason, startTime) => {
    const visit = {
      sortKey: vistSortKey,
      onHold: true,
      onHoldReason: reason,
      status: 'On hold',
      version
    };

    if (moment().unix() > moment(startTime)) {
      const duration = moment.duration(moment().diff(startTime)).asMinutes();
      // console.log(`On hold - setting duration to ${duration} minutes`);
      visit.actualDuration = `${duration} minutes`;
    }

    return this.updateVisit(visit);
  };

  onHoldVisit = async (visitPartitionKey, vistSortKey, reason, startTime) => {
    const visit = {
      sortKey: vistSortKey,
      onHold: true,
      onHoldReason: reason,
      status: 'On hold'
    };

    if (moment().unix() > moment(startTime)) {
      const duration = moment.duration(moment().diff(startTime)).asMinutes();
      // console.log(`On hold - setting duration to ${duration} minutes`);
      visit.actualDuration = `${duration} minutes`;
    }

    const { version } = await this.getVisitVersionAndStatus(visitPartitionKey, vistSortKey);

    visit.version = version;

    return this.updateVisit(visit);
  };

  changeStatus = async (visitSortKey, version, newSatus) => {
    const visit = {
      sortKey: visitSortKey,
      status: newSatus,
      version
    };
    return this.updateVisit(visit);
  };

  cancelVisit = async (visitSortKey, version) => {
    const visit = {
      sortKey: visitSortKey,
      status: 'Canceled',
      scheduledFor: null,
      version,
      onHold: false,
      onHoldReason: '',
      actualDuration: ''
    };
    return this.updateVisit(visit);
  };

  completeVisit = async (visitSortKey, visitVersion, visitPartitionKey = null) => {
    const visit = {
      sortKey: visitSortKey,
      status: 'Complete',
      endTime: moment().unix(),
      onHold: false,
      onHoldReason: '',
      version: visitVersion
    };

    if (visitPartitionKey) {
      const { sortKey } = visit;
      const { version } = await this.getVisitVersionAndStatus(visitPartitionKey, sortKey);

      const visitToBeUpdated = {
        ...visit,
        version
      };

      return this.updateVisit(visitToBeUpdated);
    }

    return this.updateVisit(visit);
  };

  unassignVisit = async (visitSortKey, version) => {
    const visit = {
      sortKey: visitSortKey,
      status: 'Unassigned',
      scheduledFor: null,
      version,
      onHold: false,
      onHoldReason: '',
      actualDuration: ''
    };
    return this.updateVisit(visit);
  };

  startWorking = async (visitSortKey, version) => {
    const visit = {
      sortKey: visitSortKey,
      status: 'Working',
      startTime: moment().unix(),
      version
    };
    return this.updateVisit(visit);
  };

  teamMatchesReq = visit => {
    let ret = true;
    const { extraTechsRequired, extraTechsNumber } = visit;
    let { primaryTechs, extraTechs } = visit;
    if (!primaryTechs) primaryTechs = [];
    if (!extraTechs) extraTechs = [];
    ret = ret && primaryTechs && primaryTechs.length > 0;
    if (extraTechsRequired) {
      ret = ret && extraTechs.length === extraTechsNumber;
    }
    return ret;
  };

  isFullyScheduled = visit => {
    let ret = true;
    const {
      tentativePeriod,
      tentativeDate,
      tentativeTime,
      primaryTechs,
      extraTechs,
      extraTechsRequired,
      extraTechsNumber
    } = visit;
    if (!primaryTechs) ret = false;
    else {
      ret = ret && primaryTechs.length > 0;
    }
    if (extraTechsRequired) {
      if (!extraTechs) ret = false;
      else {
        ret = ret && extraTechs.length === extraTechsNumber;
      }
    }
    ret = ret && tentativePeriod === 'Day';
    ret = ret && tentativeDate;
    const timeSplit = tentativeTime ? tentativeTime.split(' ') : [];
    ret = ret && timeSplit.length === 2;
    if (!ret) return ret;
    let scheduledFor = moment(tentativeDate).startOf('day');
    const timeToAdd = parseInt(timeSplit[0].split(':')[0], 10);
    if (timeSplit[1] === 'am' && timeToAdd < 12) {
      scheduledFor = scheduledFor.add(timeToAdd, 'hours');
    } else if (timeSplit[1] === 'pm' && timeToAdd === 12) {
      scheduledFor = scheduledFor.add(timeToAdd, 'hours');
    } else if (timeSplit[1] === 'am' && timeToAdd === 12) {
      scheduledFor = scheduledFor.add(0, 'hours');
    } else if (timeSplit[1] === 'pm' && timeToAdd < 12) {
      scheduledFor = scheduledFor.add(timeToAdd + 12, 'hours');
    }
    return scheduledFor;
  };

  isDispatchToday = visit => {
    const { tentativePeriod, tentativeDate, scheduledFor, onHold, status } = visit;
    const endOfToday = moment().endOf('day');
    const startOfToday = moment().startOf('day');
    if (!tentativeDate) return false;
    if (
      onHold ||
      status === 'Converted' ||
      status === 'Complete' ||
      status === 'On hold' ||
      status === 'Working'
    ) {
      return false;
    }
    if (scheduledFor) {
      if (scheduledFor > 0 && scheduledFor <= endOfToday) {
        return true;
      }
    }
    if (tentativePeriod === 'Day') {
      if (tentativeDate) {
        const date = moment(tentativeDate);
        if (date.isBefore(endOfToday)) {
          return true;
        }
      }
    }
    if (tentativePeriod === 'Week') {
      const eoW = moment(tentativeDate)
        .add(1, 'weeks')
        .subtract(2, 'days');
      if (startOfToday.isAfter(eoW)) {
        return true;
      }
    }
    return false;
  };

  prepVisit = visit => {
    const localVisit = {
      ...visit,
      schedules: visit && visit.schedules ? visit.schedules.items : [],
      primaryTechs:
        visit && visit.primaryTechs && visit.primaryTechs.items
          ? visit.primaryTechs.items.map(tech => ({
              ...tech.mappedEntity,
              directSortKey: tech.invertedSortKey,
              invertedSortKey: tech.sortKey
            }))
          : [],
      extraTechs:
        visit && visit.extraTechs && visit.extraTechs.items
          ? visit.extraTechs.items.map(tech => ({
              ...tech.mappedEntity,
              directSortKey: tech.invertedSortKey,
              invertedSortKey: tech.sortKey
            }))
          : []
    };
    return localVisit;
  };

  getOnHoldVisitsWithCache = async params => {
    const queryParams = {
      partitionKey: params.partitionKey,
      sortKey: params.sortKey
    };
    let client = AmplifyService.appSyncClient();
    const result = client
      .queryWithCache(getVisitsOnHoldWithCache, queryParams)
      .then(data => data.data.getCompany.visits.items)
      .catch(err => {
        console.log(`getOnHoldVisits error ${err}`);
        throw err;
      });
    client = null;
    return result;
  };

  getOnHoldVisitsWithKeys = async params => {
    const keysBatches = this.chunk(params.keys, 50);
    return Promise.all(
      keysBatches.map(keys => {
        const queryParams = {
          partitionKey: params.partitionKey,
          sortKey: params.sortKey,
          keys
        };
        let client = AmplifyService.appSyncClient();
        const result = client
          .queryWithCache(getVisitsOnHoldWithKeys, queryParams)
          .then(data => data.data.getCompany.visits.items.map(visit => this.prepVisit(visit)))
          .catch(err => {
            console.log(`getOnHoldVisits error ${err}`);
            throw err;
          });
        client = null;
        return result;
      })
    )
      .then(results => [].concat.apply([], results))
      .catch(err => {
        throw err;
      });
  };

  getOnHoldVisits = async params => {
    const queryParams = {
      partitionKey: params.partitionKey,
      sortKey: params.sortKey
    };
    let client = AmplifyService.appSyncClient();
    const result = client
      .queryWithCache(getVisitsOnHold, queryParams)
      .then(data => data.data.getCompany.visits.items.map(visit => this.prepVisit(visit)))
      .catch(err => {
        console.log(`getOnHoldVisits error ${err}`);
        throw err;
      });
    client = null;
    return result;
  };

  getUnassignedVisitsWithCache = async params => {
    const queryParams = {
      partitionKey: params.partitionKey,
      sortKey: params.sortKey
    };
    let client = AmplifyService.appSyncClient();
    const result = client
      .queryWithCache(getUnassignedVisitsWithCache, queryParams)
      .then(data => {
        const dispatchToday = [];
        const unassigned = [];
        const allUnassigned = data.data.getCompany.visits.items;
        allUnassigned.forEach(visit => {
          if (!this.isDispatchToday(visit)) unassigned.push(visit);
          else dispatchToday.push(visit);
        });
        return {
          dispatchToday,
          unassigned
        };
      })
      .catch(err => {
        console.log(`getUnassignedVisits error ${err}`);
        throw err;
      });
    client = null;
    return result;
  };

  getUnassignedVisitsWithKeys = async params => {
    const keysBatches = this.chunk(params.keys, 50);
    return Promise.all(
      keysBatches.map(keys => {
        const queryParams = {
          partitionKey: params.partitionKey,
          sortKey: params.sortKey,
          keys
        };
        let client = AmplifyService.appSyncClient();
        const result = client
          .queryWithCache(getUnassignedVisitsWithKeys, queryParams)
          .then(data => {
            const dispatchToday = [];
            const unassigned = [];
            const allUnassigned = data.data.getCompany.visits.items.map(visit =>
              this.prepVisit(visit)
            );
            allUnassigned.forEach(visit => {
              if (!this.isDispatchToday(visit)) unassigned.push(visit);
              else dispatchToday.push(visit);
            });
            return {
              dispatchToday,
              unassigned
            };
          })
          .catch(err => {
            console.log(`getUnassignedVisits error ${err}`);
            throw err;
          });
        client = null;
        return result;
      })
    )
      .then(results => {
        let dispatchToday = [];
        let unassigned = [];
        for (let i = 0, len = results.length; i < len; i++) {
          dispatchToday = dispatchToday.concat.apply(dispatchToday, results[i].dispatchToday);
          unassigned = unassigned.concat.apply(unassigned, results[i].unassigned);
        }
        return {
          dispatchToday,
          unassigned
        };
      })
      .catch(err => {
        throw err;
      });
  };

  getUnassignedVisits = async params => {
    const queryParams = {
      partitionKey: params.partitionKey,
      sortKey: params.sortKey
    };
    let client = AmplifyService.appSyncClient();
    const result = client
      .queryWithCache(getUnassignedVisits, queryParams)
      .then(data => {
        const dispatchToday = [];
        const unassigned = [];
        const allUnassigned = data.data.getCompany.visits.items.map(visit => this.prepVisit(visit));
        allUnassigned.forEach(visit => {
          if (!this.isDispatchToday(visit)) unassigned.push(visit);
          else dispatchToday.push(visit);
        });
        return {
          dispatchToday,
          unassigned
        };
      })
      .catch(err => {
        console.log(`getUnassignedVisits error ${err}`);
        throw err;
      });
    client = null;
    return result;
  };

  getEventsScheduledInThePastCached = async params => {
    const queryParams = {
      partitionKey: params.partitionKey,
      sortKey: params.sortKey,
      startTime: moment()
        .startOf('day')
        .subtract(4, 'weeks')
        .unix(),
      endTime: moment()
        .startOf('day')
        .unix()
    };
    let client = AmplifyService.appSyncClient();
    const result = client
      .queryWithCache(getScheduledInThePastWithCache, queryParams)
      .then(data => data.data.getCompany.visitsInRange.items)
      .catch(err => {
        throw err;
      });
    client = null;
    return result;
  };

  getEventsScheduledInThePastWithKeys = async params => {
    // split the keys in batches of 100
    const keysBatches = this.chunk(params.keys, 50);

    return Promise.all(
      keysBatches.map(keys => {
        const queryParams = {
          partitionKey: params.partitionKey,
          sortKey: params.sortKey,
          startTime: moment()
            .startOf('day')
            .subtract(4, 'weeks')
            .unix(),
          endTime: moment()
            .startOf('day')
            .unix(),
          keys
        };
        let client = AmplifyService.appSyncClient();
        const result = client
          .queryWithCache(getScheduledInThePastWithKeys, queryParams)
          .then(data =>
            data.data.getCompany.visitsInRange.items.map(visit => this.prepVisit(visit))
          )
          .catch(err => {
            throw err;
          });
        client = null;
        return result;
      })
    )
      .then(results => [].concat.apply([], results))
      .catch(err => {
        throw err;
      });
  };

  getEventsScheduledInThePast = async params => {
    const rangeIntervals = [];
    let lastInterval = moment().startOf('day');
    while (lastInterval > moment().subtract(4, 'week')) {
      const endMoment = lastInterval.clone();
      const startMoment = endMoment.subtract(1, 'week');
      const queryParams = {
        partitionKey: params.partitionKey,
        sortKey: params.sortKey,
        startTime: startMoment.unix(),
        endTime: lastInterval.unix()
      };
      rangeIntervals.push(queryParams);
      lastInterval = startMoment.clone();
    }
    let client = AmplifyService.appSyncClient();
    let visits = [];
    return Promise.all(
      rangeIntervals.map(rangeInterval =>
        client
          .queryWithCache(getScheduledInThePastVisits, rangeInterval)
          .then(data =>
            data.data.getCompany.visitsInRange.items.map(visit => this.prepVisit(visit))
          )
          .catch(err => err)
      )
    )
      .then(results => {
        results.forEach(rangeVisits => {
          visits = visits.concat(rangeVisits);
        });
        client = null;
        return visits;
      })
      .catch(err => {
        client = null;
        throw err;
      });
  };

  getEventsScheduledForDay = async params => {
    const currentStartOfDay = moment()
      .startOf('day')
      .unix();
    const startOfToday = moment(params.startDate)
      .startOf('day')
      .unix();
    const rangeIntervals = [];
    let lastInterval = moment(params.startDate).startOf('day');
    while (lastInterval < moment(params.endDate).endOf('day')) {
      const startMoment = lastInterval.clone();
      const endMoment = startMoment.add(12, 'hours');
      const queryParams = {
        partitionKey: params.partitionKey,
        sortKey: params.sortKey,
        startTime: lastInterval.unix(),
        endTime: endMoment.unix()
      };
      rangeIntervals.push(queryParams);
      lastInterval = endMoment.clone();
    }
    let client = AmplifyService.appSyncClient();
    let visits = [];
    if (currentStartOfDay <= startOfToday) {
      return Promise.all(
        rangeIntervals.map(rangeInterval =>
          client
            .queryWithCache(getVisitsInRange, rangeInterval)
            .then(data =>
              data.data.getCompany.visitsInRange.items.map(visit => this.prepVisit(visit))
            )
            .catch(err => err)
        )
      )
        .then(results => {
          results.forEach(rangeVisits => {
            visits = visits.concat(rangeVisits);
          });
          client = null;
          return visits;
        })
        .catch(err => {
          client = null;
          throw err;
        });
    }
    return Promise.all(
      rangeIntervals.map(rangeInterval =>
        client
          .queryWithCache(getVisitsInRangePast, rangeInterval)
          .then(data =>
            data.data.getCompany.visitsInRange.items.map(visit => this.prepVisit(visit))
          )
          .catch(err => err)
      )
    )
      .then(results => {
        results.forEach(rangeVisits => {
          visits = visits.concat(rangeVisits);
        });
        client = null;
        return visits;
      })
      .catch(err => {
        client = null;
        throw err;
      });
  };

  getScheduledForDayWithCache = async params => {
    const currentStartOfDay = moment()
      .startOf('day')
      .unix();
    const startOfDay = moment(params.startDate)
      .startOf('day')
      .unix();
    const endOfDay = moment(params.startDate)
      .endOf('day')
      .unix();
    const queryParams = {
      partitionKey: params.partitionKey,
      sortKey: params.sortKey,
      startTime: startOfDay,
      endTime: endOfDay
    };
    let client = AmplifyService.appSyncClient();
    if (currentStartOfDay <= startOfDay) {
      const result = client
        .queryWithCache(getDispatchedVisitsInRange, queryParams)
        .then(data => data.data.getCompany.visitsInRange.items)
        .catch(err => err);
      client = null;
      return result;
    }
    const res = client
      .queryWithCache(getVisitsInRangePastWithCache, queryParams)
      .then(data => data.data.getCompany.visitsInRange.items)
      .catch(err => err);
    client = null;
    return res;
  };

  getScheduledForDayWithKeys = async params => {
    const keysBatches = this.chunk(params.keys, 50);
    const currentStartOfDay = moment()
      .startOf('day')
      .unix();
    const startOfDay = moment(params.startDate)
      .startOf('day')
      .unix();
    const endOfDay = moment(params.startDate)
      .endOf('day')
      .unix();

    return Promise.all(
      keysBatches.map(keys => {
        const queryParams = {
          partitionKey: params.partitionKey,
          sortKey: params.sortKey,
          startTime: startOfDay,
          endTime: endOfDay,
          keys
        };
        let client = AmplifyService.appSyncClient();
        if (currentStartOfDay <= startOfDay) {
          const result = client
            .queryWithCache(getVisitsInRangeWithKeys, queryParams)
            .then(data =>
              data.data.getCompany.visitsInRange.items.map(visit => this.prepVisit(visit))
            )
            .catch(err => {
              throw err;
            });
          client = null;
          return result;
        }
        const res = client
          .queryWithCache(getVisitsInRangePastWithKeys, queryParams)
          .then(data =>
            data.data.getCompany.visitsInRange.items.map(visit => this.prepVisit(visit))
          )
          .catch(err => {
            throw err;
          });
        client = null;
        return res;
      })
    )
      .then(results => [].concat.apply([], results))
      .catch(err => {
        throw err;
      });
  };

  getScheduledForTodayCached = async params => {
    const queryParams = {
      partitionKey: params.partitionKey,
      sortKey: params.sortKey,
      startTime: moment()
        .startOf('day')
        .unix(),
      endTime: moment()
        .endOf('day')
        .unix()
    };
    let client = AmplifyService.appSyncClient();
    const result = client
      .queryWithCache(getDispatchedVisitsInRange, queryParams)
      .then(data => data.data.getCompany.visitsInRange.items)
      .catch(err => {
        throw err;
      });
    client = null;
    return result;
  };

  getEventsScheduledForTodayWithEvents = async params => {
    const keysBatches = this.chunk(params.keys, 50);
    return Promise.all(
      keysBatches.map(keys => {
        const queryParams = {
          partitionKey: params.partitionKey,
          sortKey: params.sortKey,
          startTime: moment()
            .startOf('day')
            .unix(),
          endTime: moment()
            .endOf('day')
            .unix(),
          keys
        };
        let client = AmplifyService.appSyncClient();
        const result = client
          .queryWithCache(getVisitsInRangeWithKeys, queryParams)
          .then(data =>
            data.data.getCompany.visitsInRange.items.map(visit => this.prepVisit(visit))
          )
          .catch(err => {
            throw err;
          });
        client = null;
        return result;
      })
    )
      .then(results => [].concat.apply([], results))
      .catch(err => {
        throw err;
      });
  };

  getEventsScheduledForToday = async params => {
    const rangeIntervals = [];
    let lastInterval = moment().startOf('day');
    while (lastInterval < moment().endOf('day')) {
      const startMoment = lastInterval.clone();
      const endMoment = startMoment.add(24, 'hours');
      const queryParams = {
        partitionKey: params.partitionKey,
        sortKey: params.sortKey,
        startTime: lastInterval.unix(),
        endTime: endMoment.unix()
      };
      rangeIntervals.push(queryParams);
      lastInterval = endMoment.clone();
    }
    let visits = [];
    let client = AmplifyService.appSyncClient();
    return Promise.all(
      rangeIntervals.map(rangeInterval =>
        client
          .queryWithCache(getVisitsInRange, rangeInterval)
          .then(data =>
            data.data.getCompany.visitsInRange.items.map(visit => this.prepVisit(visit))
          )
          .catch(err => err)
      )
    )
      .then(results => {
        results.forEach(rangeVisits => {
          visits = visits.concat(rangeVisits);
        });
        client = null;
        return visits;
      })
      .catch(err => {
        client = null;
        throw err;
      });
  };

  getTechs = async params => {
    let client = AmplifyService.appSyncClient();
    const result = client
      .query(getTechs, params)
      .then(data => data.data.getCompany.employees)
      .catch(err => {
        console.log(`getTechs error: ${err}`);
        throw err;
      });
    client = null;
    return result;
  };

  getVisit = async params => {
    let client = AmplifyService.appSyncClient();
    const result = client
      .query(getVisit, params)
      .then(data => data.data.getVisit)
      .catch(err => {
        console.log(`getVisit error: ${err}`);
        throw err;
      });
    client = null;
    return result;
  };

  canSchedule = (visit, skipSteps, additionalParams = {}) =>
    this.schedulingRules.canSchedule(visit, skipSteps, additionalParams);

  createOffSchedule = async offSchedule => saveOffSchedule(this.client, offSchedule);

  createOffScheduleFull = async (offSchedule, optimisticResponse, update, refetchQueries) =>
    saveOffScheduleFull(this.client, offSchedule, optimisticResponse, update, refetchQueries);

  deleteOffSchedule = async offSchedule => deleteOffSchedule(this.client, offSchedule);

  getVisitCrew = async visitObj => {
    let client = AmplifyService.appSyncClient();
    const result = client
      .query(getVisitCrew, {
        partitionKey: visitObj.partitionKey,
        sortKey: visitObj.sortKey
      })
      .then(data => {
        const visit = data.data.getVisit;
        const primaryTechs = visit.primaryTechs.items.map(el => ({
          ...el.mappedEntity,
          directSortKey: el.sortKey,
          invertedSortKey: el.invertedSortKey
        }));
        const extraTechs = visit.extraTechs.items.map(el => ({
          ...el.mappedEntity,
          directSortKey: el.sortKey,
          invertedSortKey: el.invertedSortKey
        }));
        return {
          primaryTechs,
          extraTechs
        };
      })
      .catch(err => {
        console.log(`getVisitCrew error: ${err}`);
        throw err;
      });
    client = null;
    return result;
  };

  replaceVisitCrew = async (visit, toBeAdded, toBeRemoved, type) => {
    const promises = [
      this.removeUserFromVisit(visit, toBeRemoved),
      this.addUserToVisit(visit, toBeAdded, type)
    ];
    await Promise.all(promises);
    return this.getVisitCrew(visit);
  };

  removeUserFromVisit = async (visit, user) => {
    const directParams = {
      input: {
        partitionKey: visit.partitionKey,
        sortKey: user.directSortKey
      }
    };
    const invertedParams = {
      input: {
        partitionKey: visit.partitionKey,
        sortKey: user.invertedSortKey
      }
    };
    let client = AmplifyService.appSyncClient();
    const promises = [
      client.mutate(deleteSystemEntityMap, directParams),
      client.mutate(deleteSystemEntityMap, invertedParams)
    ];
    client = null;
    return Promise.all(promises);
  };

  addUserToVisit = async (visit, user, type) => {
    let client = AmplifyService.appSyncClient();
    const dataset = updateVisitSchemaMapping(visit, user, type);
    const result = client.mutate(mutation, dataset);
    client = null;
    return result;
  };

  saveNote = async (note, connectionName) => {
    const dataset = createSchemaMapping(note, connectionName);
    let client = AmplifyService.appSyncClient();
    const result = client.mutate(mutation, dataset);
    client = null;
    return result;
  };

  savePart = async note => {
    const dataset = createSchemaMapping(note);
    let client = AmplifyService.appSyncClient();
    const result = client.mutate(mutation, dataset);
    client = null;
    return result;
  };

  saveAsset = async asset => {
    const dataset = createSchemaMapping(asset);
    let client = AmplifyService.appSyncClient();
    const result = client.mutate(mutation, dataset);
    client = null;
    return result;
  };

  saveAttachment = async attachment => {
    const dataset = createSchemaMapping(attachment);
    let client = AmplifyService.appSyncClient();
    const result = client.mutate(mutation, dataset);
    client = null;
    return result;
  };

  onRoute = async visit => {
    // employee specific schedules are filtered and loaded in visit.schedule (in mobile app)
    const schedules = visit.schedules || [];

    if (schedules.length === 1) {
      // clock in should update the schedule
      try {
        const { partitionKey, sortKey } = visit;
        const { version } = await this.getVisitVersionAndStatus(partitionKey, sortKey);
        const visitToBeUpdated = {
          sortKey,
          partitionKey,
          version
        };

        const scheduleMutation = await this.updateSchedule(schedules[0], true);
        await this.updateVisit(visitToBeUpdated);

        return scheduleMutation;
      } catch (error) {
        throw new Error(error);
      }
    } else if (schedules.length === 0) {
      try {
        const { partitionKey, sortKey } = visit;
        const { version } = await this.getVisitVersionAndStatus(partitionKey, sortKey);
        const visitToBeUpdated = {
          sortKey,
          partitionKey,
          version
        };
        const dataset = createScheduleMapping({ ...visit, ...visitToBeUpdated });
        const scheduleMutation = await this.client.mutate(mutation, dataset);
        await this.updateVisit(visitToBeUpdated);

        return scheduleMutation;
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  updateSchedule = async (schedule, routeValue) => {
    const localSchedule = {};
    const allowedFields = [
      'sortKey',
      'employeeSortKey',
      'scheduledFor',
      'startTime',
      'endTime',
      'onRoute',
      'delayed',
      'delayedReason',
      'onHold',
      'onHoldReason',
      'version'
    ];
    allowedFields.forEach(key => {
      if (Object.keys(schedule).includes(key)) {
        localSchedule[key] = schedule[key];
        if (localSchedule[key] === '') delete localSchedule[key];
      }
    });

    localSchedule.onRoute = routeValue;

    if (!localSchedule.version) localSchedule.version = 1;
    const dataset = {
      input: localSchedule
    };
    let client = AmplifyService.appSyncClient();
    const result = client.mutate(updateScheduleQuery, dataset);
    client = null;
    return result;
  };

  clockIn = async visit => {
    const schedule = visit.currentSchedule;

    const { partitionKey, sortKey } = visit;
    const { version } = await this.getVisitVersionAndStatus(partitionKey, sortKey);

    const visitWithUpdatedVersion = {
      ...visit,
      version
    };

    let dataset = {};
    if (!schedule) {
      dataset = createScheduleWithTimesheetSchemaMapping(visitWithUpdatedVersion);
    } else {
      dataset = createTimeSheetMapping(schedule);
      if (schedule.onRoute) {
        console.log('make schedule.onRoute false', { schedule });
        await this.updateSchedule(schedule, false);
      }
    }

    const visitToBeUpdated = {
      sortKey,
      partitionKey,
      version,
      status: 'Working'
    };

    await this.updateVisit(visitToBeUpdated);

    let client = AmplifyService.appSyncClient();
    // eslint-disable-next-line consistent-return
    const result = client.mutate(mutation, dataset);

    console.log('clockIn - mutate', { result, dataset });

    client = null;
    return result;
  };

  getVisitVersionAndStatus = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    try {
      let client = AmplifyService.appSyncClient();
      const response = await client.query(getVisitVersion, params);
      client = null;
      return {
        version: response.data.getVisit.version,
        status: response.data.getVisit.status
      };
    } catch (error) {
      console.log(`getVisitVersion error: ${error}`);
    }
  };

  clockOut = async schedule => {
    const dataset = updateTimeSheetMapping(schedule);
    let client = AmplifyService.appSyncClient();
    client.mutate(mutation, dataset);
    client = null;
    return null;
  };

  deleteVisit = async visit => {
    // primary techs associations
    let client = AmplifyService.appSyncClient();
    asyncForEach(visit.primaryTechs, tech => {
      client.mutate(deleteSystemEntityMap, {
        input: {
          partitionKey: visit.partitionKey,
          sortKey: tech.directSortKey
        }
      });
      client.mutate(deleteSystemEntityMap, {
        input: {
          partitionKey: visit.partitionKey,
          sortKey: tech.invertedSortKey
        }
      });
    });

    // extra techs associations
    asyncForEach(visit.extraTechs, tech => {
      client.mutate(deleteSystemEntityMap, {
        input: {
          partitionKey: visit.partitionKey,
          sortKey: tech.directSortKey
        }
      });
      client.mutate(deleteSystemEntityMap, {
        input: {
          partitionKey: visit.partitionKey,
          sortKey: tech.invertedSortKey
        }
      });
    });

    // implement the task deletetion

    // delete the visit
    const result = client.mutate(deleteVisit, {
      input: {
        partitionKey: visit.partitionKey,
        sortKey: visit.sortKey
      }
    });
    client = null;
    return result;
  };

  visitUpdated = async params => {
    const result = this.subscriptionClient.subscribe({
      query: visitUpdateNotification,
      variables: params
    });
    return result;
  };

  saveVisit = async visit => this.createVisitData(visit);

  createVisitData = async visit => {
    const { propertyAssets, ...restData } = visit;
    const dataset = visitSchemaDataset({ ...restData });
    const saveVisitResp = await saveVisit(this.client, dataset);
    const visitId = saveVisitResp && saveVisitResp.data.moveVisit.id;

    if (propertyAssets) {
      let anyChangeInAssets = false;

      // WHne there is no change in assets, dont mutate
      const visitAssetsItems = (restData.visitAssets && restData.visitAssets.items) || [];
      const compareAssetIds = (visitAsset, propertyAsset) => {
        // if the asset id in visit assets is not in property assets
        if (
          visitAsset &&
          visitAsset.propertyAsset &&
          propertyAsset &&
          visitAsset.propertyAsset.id === propertyAsset.id
        ) {
          return true;
        }
      };

      if (!_.isEqualWith(visitAssetsItems, propertyAssets, compareAssetIds)) {
        anyChangeInAssets = true;
      }

      if (!anyChangeInAssets) {
        return saveVisitResp;
      }

      let propertyAssetsInput;
      const propertyIds = [];
      propertyAssets.forEach(asset => {
        const propertyId = { propertyAssetId: asset.id };
        propertyIds.push(propertyId);
      });
      propertyAssetsInput = { visitAssets: [...propertyIds] };
      propertyAssetsInput.visitId = visitId;
      const client = AmplifyService.appSyncClient();
      await client.mutate(addVisitAssetsToVisit, { data: propertyAssetsInput });
    }

    return saveVisitResp;
  };
}

export default RulesEngine;
