import React, { useEffect } from 'react';

import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Popover,
  Switch,
  TextField
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';

import RenameModal from 'components/ResponsiveTable/RenameModal';

import { viewStyles } from './SavedFilterActionsMenuItem.styles';

export const ChangeView = props => {
  const classes = viewStyles();
  const { id, open, currentLabel, handleFilterRename, changeLabelRef, handleClose } = props;
  const [viewName, setViewName] = React.useState(currentLabel);

  return (
    <Popover
      anchorEl={() => changeLabelRef.current}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right'
      }}
      id={id}
      keepMounted
      marginThreshold={32}
      open={open}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      onClick={e => e.stopPropagation()}
      onClose={() => handleClose()}
    >
      <Box className={classes.changeLabelStyles}>
        <TextField
          autoFocus
          label="New label"
          value={viewName}
          onChange={event => setViewName(event.currentTarget.value)}
        />
        <IconButton
          color="secondary"
          onClick={e => {
            e.stopPropagation();
            handleClose();
            handleFilterRename(viewName);
          }}
        >
          <CheckCircleIcon />
        </IconButton>
      </Box>
    </Popover>
  );
};

const Submenu = props => {
  const {
    filter,
    handleCloseSubmenu,
    handleUpsertFilter,
    handleDeleteFilter,
    handleSetIsDefault,
    anchor,
    disabled
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(anchor);
  const [showRenameModal, setShowRenameModal] = React.useState();
  const changeLabelRef = React.useRef();

  useEffect(() => {
    setAnchorEl(anchor);
  }, [anchor]);

  const classes = viewStyles();

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        getContentAnchorEl={null}
        id={`filterAction${filter.displayName}`}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={e => e.stopPropagation()}
        onClose={handleCloseSubmenu}
      >
        <MenuItem
          aria-describedby={`${filter.displayName}Rename`}
          className={classes.menuText}
          disabled={disabled}
          ref={changeLabelRef}
          onClick={e => {
            e.stopPropagation();
            setShowRenameModal(true);
          }}
        >
          <ListItemIcon className={classes.menuIcon}>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Rename
        </MenuItem>
        <MenuItem
          className={classes.menuText}
          disabled={disabled || filter.isDefault}
          onClick={async e => {
            e.stopPropagation();
            await handleDeleteFilter(filter.displayName, filter.id);
            handleCloseSubmenu();
          }}
        >
          <ListItemIcon className={classes.menuIcon}>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Remove
        </MenuItem>
        <Grid
          alignItems="center"
          className={`${classes.menuTopBorder} ${classes.defaultBtnContainer}`}
          container
          direction="row"
          justify="space-between"
          onClick={e => e.stopPropagation()}
        >
          <Grid className={classes.menuText} item>
            Make default
          </Grid>
          <Grid item>
            <Switch
              checked={filter.isDefault}
              checkedIcon={<CheckCircleIcon />}
              classes={{
                switchBase: classes.switchBase,
                track: classes.track,
                colorSecondary: classes.colorSecondary,
                checked: classes.checked
              }}
              disabled={disabled}
              onChange={async e => {
                await handleSetIsDefault(filter.displayName, filter.id, e.target.checked);
                handleCloseSubmenu();
              }}
            />
          </Grid>
        </Grid>
      </Menu>
      <RenameModal
        currentLabel={filter.displayName}
        dataType="Filter"
        handleClose={() => setShowRenameModal(null)}
        handleViewRename={async newDisplayName => {
          await handleUpsertFilter(newDisplayName, filter.id);
          handleCloseSubmenu();
        }}
        open={showRenameModal}
        onClick={e => e.stopPropagation()}
      />
    </>
  );
};

Submenu.propTypes = {
  filter: PropTypes.object.isRequired,
  anchor: PropTypes.object.isRequired,
  handleCloseSubmenu: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

Submenu.defaultProps = {
  disabled: false
};

export default Submenu;
