import React from 'react';

import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import { DropTarget } from 'react-dnd';

import Item from '../item';
import ItemTypes from '../ItemTypes';

const StyledIconButton = withStyles(theme => ({
  root: {
    padding: 2
  }
}))(IconButton);

const Column = props => {
  const {
    item,
    row,
    column,
    width,
    blankWorkspace,
    handleDrop,
    openEditItemModal,
    canDrop,
    isOver,
    connectDropTarget,
    classes,
    draggingItem,
    updateDragging,
    getLocalAssets
  } = props;

  const isActive = canDrop && isOver;
  const isEmpty = item === null;
  const dragMessage = isActive ? 'Release to drop' : 'Drag a box here';
  const fullWidthColumnStyles = {
    default: classes.columnFull,
    empty: classes.columnEmptyFull,
    available: classes.columnAvailableFull,
    over: classes.columnOverFull
  };

  const columnStyles = {
    default: classes.column,
    empty: classes.columnEmpty,
    available: isEmpty ? classes.columnEmptyAvailable : classes.columnAvailable,
    over: classes.columnOver
  };
  const columnClassesMap = width === '100%' ? fullWidthColumnStyles : columnStyles;
  let columnClasses = isEmpty ? columnClassesMap.empty : columnClassesMap.default;

  if (canDrop) columnClasses += ` ${columnClassesMap.available}`;
  if (isActive) columnClasses += ` ${columnClassesMap.over}`;

  const placeholder =
    width === '100%' ? null : (
      <div
        className={classes.emptyColumnPlaceholder}
        style={{
          background: draggingItem && 'lightblue',
          border: isActive && '1px dashed #13809e'
        }}
      />
    );

  if (blankWorkspace) {
    columnClasses = isActive ? classes.blankWorkspaceColumnOver : classes.blankWorkspaceColumn;
    return (
      <div className={columnClasses} ref={connectDropTarget} style={{ width }}>
        <Typography variant="body2">{dragMessage}</Typography>
      </div>
    );
  }

  return (
    <div className={columnClasses} ref={connectDropTarget} style={{ width }}>
      {item ? (
        <div>
          <div className={classes.itemControls}>
            <StyledIconButton
              aria-controls="edit-item-button"
              aria-haspopup="true"
              aria-label="Edit item button"
              onClick={e => {
                e.stopPropagation();
                openEditItemModal(item, row, column);
              }}
            >
              <EditIcon style={{ fontSize: '0.9em' }} />
            </StyledIconButton>
          </div>
          <Item
            classes={classes}
            column={column}
            getLocalAssets={getLocalAssets}
            handleDrop={handleDrop}
            item={item}
            row={row}
            updateDragging={updateDragging}
          />
        </div>
      ) : (
        placeholder
      )}
    </div>
  );
};

export default DropTarget(
  ItemTypes.ITEM,
  {
    drop: props => ({
      row: props.row,
      column: props.column
    }),
    canDrop(props, monitor) {
      const { availableColumns } = props;
      if (availableColumns === 0) {
        return false;
      }
      return true;
    }
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  })
)(Column);
