import React, { createContext, useContext, useState } from 'react';

import { useHistory } from 'react-router-dom';

import ProcurementPurchaseOrderModal from 'scenes/Procurement/PurchaseOrders/CreatePurchaseOrder';

export const POModalContext = createContext({});

export const usePOModalContext = () => useContext(POModalContext);

export const withAddPOModal = Component => props => {
  const [modalProps, setModalProps] = useState(false);
  const showModal = Boolean(modalProps);
  const history = useHistory();
  const openAddPOModal = newProps => {
    setModalProps(newProps);
  };

  const closeAddPOModal = po => {
    if (po.id) history.push(`/procurement/purchaseorders/view/${po.id}`);
    setModalProps(null);
  };

  return (
    <POModalContext.Provider value={{ openAddPOModal, closeAddPOModal }}>
      <Component {...props} />
      {modalProps && (
        <ProcurementPurchaseOrderModal
          handleClose={closeAddPOModal}
          open={showModal}
          {...modalProps}
        />
      )}
    </POModalContext.Provider>
  );
};
