export const useStyles = () => ({
  container: theme => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.default.spacing(1),
    marginTop: theme.default.spacing(1)
  }),
  partialBillingContainer: theme => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex'
  }),
  selectedPartialBillingChip: theme => ({
    background: 'transparent',
    fontSize: 12,
    border: `1px solid ${theme.palette.support.green.dark}`,
    color: theme.palette.support.green.dark,
    width: '49%'
  }),
  partialBillingChip: theme => ({
    background: 'transparent',
    fontSize: 12,
    border: `1px solid ${theme.palette.text.primary}`,
    pointer: 'cursor',
    width: '49%'
  }),
  partialBillingInputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '450px'
  },
  partialBillingInput: {
    width: '49%'
  }
});
