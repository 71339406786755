import React, { useEffect, useState } from 'react';

import { Fade, Paper, Popper } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { connect } from 'react-redux';

import UserPermisson from 'components/AppPermissions';
import { CompanyService } from 'services/core';
import { Logger } from 'services/Logger';
import { getImageUrl, truncateString } from 'utils';

import MenuItem from '../MenuItem';
import styles from '../styles';

const MAX_COMPANY_NAME_LENGTH = 20;

const CompanyButton = ({
  companyContext,
  data,
  classes,
  tenantSettingProcessed,
  isSmallScreen,
  user
}) => {
  const menuItems = data.menus;
  const [anchorEl, setAnchorEl] = useState(null);
  const [buttonLabel, setButtonLabel] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [hasCompanyData, setHasCompanyData] = useState(false);

  const setCompanyButtonState = async companyData => {
    if (!companyData) return;
    const { companyName, logoUrl } = companyData;
    const label = truncateString(companyName, MAX_COMPANY_NAME_LENGTH);
    setButtonLabel(label);
    if (logoUrl) {
      const imageUrl = await getImageUrl(logoUrl);
      setCompanyLogo(imageUrl);
    }
    setHasCompanyData(true);
  };

  const getCompanyData = async () => {
    if (!user?.tenantId) return;
    const companyService = new CompanyService();
    const { tenantId, tenantCompanyId } = user;
    const sortKey = `${tenantId}_Company_${tenantCompanyId}`;
    try {
      const response = await companyService.getCompanyInfo(`${tenantId}`, sortKey);
      const { getCompany } = response?.data;
      setCompanyButtonState(getCompany);
    } catch (error) {
      Logger.error(`Error in fetching all company information ${JSON.stringify(error)}`);
    }
  };

  useEffect(() => {
    setCompanyButtonState(companyContext);
  }, [companyContext]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasSelectedMenuItem = (menuItem, url) => {
    const { sections = [] } = menuItem;
    const currentSection = sections.filter(section => url.includes(section));
    return currentSection.length > 0;
  };

  if (!hasCompanyData) getCompanyData();
  const open = Boolean(anchorEl);
  const id = open ? `simple-Popper${data.title}` : undefined;
  const menuId = `menuId_${data.title}_${data.url}`;
  const popoverMenuWidth = isSmallScreen ? 'auto' : 200;

  return (
    <ListItem
      aria-haspopup="true"
      aria-owns={open ? id : undefined}
      classes={{
        root: classes.companyButtonWrapper,
        selected: classes.menuSelected
      }}
      component="a"
      id={menuId}
      key={`listitem${data.title}`}
      selected={open || hasSelectedMenuItem(data, window.location.pathname)}
      onMouseEnter={handleClick}
      onMouseLeave={handleClose}
    >
      <div className={classes.companyButton} testingid="companySettingsDropdown">
        <ListItemText
          classes={{ root: classes.listTexts }}
          disableTypography
          key={`listitems${data.title}`}
          primary={buttonLabel}
        />
        {open ? (
          <KeyboardArrowUp className={classes.menuItemArrow} />
        ) : (
          <KeyboardArrowDown className={classes.menuItemArrow} />
        )}
      </div>
      <Popper
        anchorEl={anchorEl}
        classes={{ root: classes.menuPopover }}
        id={id}
        open={open}
        placement="bottom-start"
        style={{ zIndex: 500 }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={0}>
            <Paper
              style={{
                boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
                marginTop: 4,
                borderRadius: 4,
                width: popoverMenuWidth
              }}
            >
              <List component="div" disablePadding key={`sectionlist${data.title}`}>
                {companyLogo && (
                  <img alt={buttonLabel} className={classes.companyLogo} src={companyLogo} />
                )}
                {menuItems.map(item => (
                  <UserPermisson
                    action={item.menu.caslKey}
                    I={item.menu.caslAction}
                    key={`perm${item.menu.title || item.menu.pageMapKey}`}
                    scope={item.menu.scope}
                  >
                    <MenuItem
                      compact
                      data={item.menu}
                      handleClosePopover={handleClose}
                      isPopover
                      isSmallScreen={isSmallScreen}
                      key={`${item.menu.title || item.menu.pageMapKey}_${item.menu.url}`}
                      nested="true"
                      tenantSettingProcessed={tenantSettingProcessed}
                    />
                  </UserPermisson>
                ))}
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>
    </ListItem>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps, null)(withStyles(styles)(CompanyButton));
