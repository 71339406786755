import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import {
  formDataFragment,
  taskEntriesFragment
} from 'scenes/Customer/PropertyDetail/Tasks/TaskQuery.gql';

export const getTasksByJobId = gql`
  query getTasksByJobId($jobId: String!, $sorting: [TableSortingInput]) {
    data: getTasksByJobId(jobId: $jobId, sorting: $sorting) {
      rowCount
      items {
        id
        name
        sortKey
        isActive
        description
        assetId
        asset {
          assetName
        }
        ${taskEntriesFragment}
        ${formDataFragment}
      }
    }
  }
`;

export const useGetTasksByJobid = ({ jobId, listType }) => {
  const { data, loading, error, refetch } = useExtendedQuery(getTasksByJobId, {
    variables: {
      jobId,
      listType
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });
  return [data || [], loading, error, refetch];
};
