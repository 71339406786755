import React from 'react';

import { Box, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import StatusChip from 'components/StatusChip';
import statusChips from 'meta/statusChips';

import { anchorOrigin, placeholder, timePickerRangeAnchorId, transformOrigin } from './constants';
import { getFormatedDate } from './helpers';
import useStyles from './TimesheetPeriodSelector.styles';

const TimesheetPeriodSelector = ({ selected, timesheetPeriods, onChange }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? timePickerRangeAnchorId : undefined;
  const Icon = open ? ExpandLess : ExpandMore;

  const renderDate = (timePeriod, emptyText = '') => {
    const { approved = false } = timePeriod;
    const isTimePeriodEmpty = isEmpty(timePeriod);
    return (
      <Box display="flex" paddingX={1}>
        <Typography className={classes.dateLabel} variant="h5">
          {getFormatedDate(timePeriod) || emptyText}
        </Typography>
        {!isTimePeriodEmpty && approved && (
          <StatusChip style={{ marginLeft: 8, height: '25px' }} type={statusChips.approved} />
        )}
        {!isTimePeriodEmpty && !approved && (
          <StatusChip style={{ marginLeft: 8, height: '25px' }} type={statusChips.notApproved} />
        )}
      </Box>
    );
  };

  const handleOnClick = timesheetPeriod => {
    onChange(timesheetPeriod);
    handleClose();
  };

  return (
    <>
      <Box aria-describedby={id} className={classes.container} paddingX={2} onClick={handleClick}>
        {renderDate(selected, placeholder)}
        <Icon color="primary" />
      </Box>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        id={id}
        open={open}
        transformOrigin={transformOrigin}
        onClose={handleClose}
      >
        {timesheetPeriods.map(timesheetPeriod => (
          <MenuItem
            className={classes.item}
            key={timesheetPeriod.id}
            onClick={() => handleOnClick(timesheetPeriod)}
          >
            {renderDate(timesheetPeriod)}
          </MenuItem>
        ))}
        {timesheetPeriods.length === 0 && (
          <MenuItem className={classes.item}>No time period found</MenuItem>
        )}
      </Popover>
    </>
  );
};

TimesheetPeriodSelector.propTypes = {
  selected: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  timesheetPeriods: PropTypes.array.isRequired
};

export default TimesheetPeriodSelector;
