import React, { memo } from 'react';

import { shape, string } from 'prop-types';

import { formatDurationValue } from '../../VisitsTable.helpers';

const Duration = ({ record }) => {
  if (!record?.actualDuration) {
    return null;
  }

  const value = formatDurationValue(record?.actualDuration);
  if (!value) {
    return null;
  }

  return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>;
};

Duration.propTypes = {
  record: shape({
    actualDuration: string
  }).isRequired
};

export default memo(Duration);
