import React from 'react';

import PropTypes from 'prop-types';

import AlgoliaSearch from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import SergeantModal from 'components/SergeantModal';
import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';
import { Mode } from 'utils/constants';

const EditItemModal = props => {
  const { open, title, btnLabel, formData, formLayout, handleSaveChange, handleModalClose } = props;

  return (
    <SergeantModal
      customComponents={{ SearchBar, AlgoliaSearch }}
      customPrimaryButtonLabel={btnLabel}
      data={formData}
      dataType="Item"
      handleClose={handleModalClose}
      handlePrimaryAction={handleSaveChange}
      layout={formLayout}
      maxWidth={496}
      mode={Mode.EDIT}
      open={open}
      title={title}
    />
  );
};

EditItemModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  btnLabel: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  formLayout: PropTypes.object.isRequired,
  handleSaveChange: PropTypes.func,
  handleModalClose: PropTypes.func
};

EditItemModal.defaultProps = {
  handleSaveChange: null,
  handleModalClose: null
};

export default EditItemModal;
