import React from 'react';

import LocationOn from '@material-ui/icons/LocationOn';
import { convertDistance, getDistance } from 'geolib';
import { object, string } from 'prop-types';

import { tableDateTimeFormatter } from 'components/WrapTable';

import { useStyles } from './TimeTrackingDateWithLocation.styles';

const DateWithLocation = ({ value, label, row }) => {
  const styles = useStyles();
  const dateText = value ? tableDateTimeFormatter({ value }) : '';
  const { technicianLocation, propertyLocation } = row;

  if (technicianLocation?.startLatitude || technicianLocation?.endLatitude) {
    const coordinates =
      label === 'Start'
        ? {
            latitude: technicianLocation.startLatitude,
            longitude: technicianLocation.startLongitude
          }
        : { latitude: technicianLocation.endLatitude, longitude: technicianLocation.endLongitude };
    const propertyCoordinates = propertyLocation;

    if (
      coordinates?.longitude &&
      coordinates?.latitude &&
      propertyCoordinates?.longitude &&
      propertyCoordinates?.latitude
    ) {
      const distance = getDistance(coordinates, propertyCoordinates, 100);
      const url = `https://www.google.com/maps/dir/${coordinates.latitude},${coordinates.longitude}/${propertyCoordinates.latitude},${propertyCoordinates.longitude}`;
      return (
        <div css={styles.textStyle}>
          {dateText}
          <br />
          <LocationOn css={styles.locationStyle} />
          <a
            css={styles.locationStyle}
            href={url}
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            {`${convertDistance(distance, 'mi').toFixed(2)} mi from job`}
          </a>
        </div>
      );
    }
  }
  return <div css={styles.textStyle}>{dateText}</div>;
};

DateWithLocation.propTypes = {
  value: string,
  label: string.isRequired,
  row: object.isRequired
};

DateWithLocation.defaultProps = {
  value: ''
};

export default DateWithLocation;
