import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { cloneDeep } from 'lodash';

import { InvoiceSettingConstants } from '../scenes/Invoices/constants';
import { defaultInvoiceSettings } from '../scenes/Invoices/InvoiceDetail/InvoiceSetting';
import defaultQuoteSettings from '../scenes/Quotes/components/QuoteSettings/defaultQuoteSettings';
import { SettingConstants } from '../scenes/Quotes/constants';

export const updatePresetPayload = (
  user,
  presetId,
  presetName,
  settings,
  accessType,
  entityName
) => {
  const payloadSettings = cloneDeep(settings);
  if (entityName === SettingConstants.QUOTE_PRESETS) {
    payloadSettings[SettingConstants.QUOTE_MODE][SettingConstants.ENABLE_OVERRIDES] = false;
  }

  return {
    data: {
      companyId: user.tenantCompanyId,
      id: presetId,
      name: presetName,
      displayName: presetName,
      settings: JSON.stringify(payloadSettings),
      access: accessType
    }
  };
};

export const usePresetsQuery = (user, query, entityName, isAssetEnabled) => {
  const [data, setData] = useState();
  const { data: rawData, loading, error, refetch } = useQuery(query, {
    variables: {
      partitionKey: user.tenantId,
      sortKey: `${user.tenantId}_Company_${user.tenantCompanyId}`
    }
  });
  useEffect(() => {
    const run = async () => {
      const rawPresets = rawData?.getCompany?.userSettings?.items?.map(item =>
        JSON.parse(item.settings)
      );

      let presets =
        (rawPresets && rawPresets.filter(v => v[entityName] !== null && !!v[entityName].value)) ||
        [];

      presets = presets.map(p => ({
        ...p,
        [entityName]: { ...p[entityName], isDefault: false }
      }));

      if (entityName === SettingConstants.QUOTE_PRESETS) {
        const defaultPreset = cloneDeep(defaultQuoteSettings(isAssetEnabled));
        presets = [defaultPreset, ...presets];
      } else if (entityName === InvoiceSettingConstants.INVOICE_PRESETS) {
        const defaultPreset = cloneDeep(defaultInvoiceSettings);
        presets = [defaultPreset, ...presets];
      }

      setData(presets);
    };
    run();
  }, [rawData]);

  return { loading: loading || !data, error, data, refetch };
};
