import React from 'react';

import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

import { bool } from 'prop-types';

import SubduedButton from 'components/Buttons/SubduedButton';

import { useStyles } from './AddProjectFileStorageSettingButton.styles';

const AddProjectFileStorageSettingButton = props => {
  const styles = useStyles({ disabled: props.disabled });
  return (
    <SubduedButton
      color="secondary"
      css={styles.button}
      variant="outlined"
      onClick={() => {}}
      {...props}
    >
      <AddCircleOutlineOutlinedIcon css={styles.icon} fontSize="inherit" />
      Add Project File folder
    </SubduedButton>
  );
};

AddProjectFileStorageSettingButton.propTypes = {
  disabled: bool
};
AddProjectFileStorageSettingButton.defaultProps = {
  disabled: false
};

export default AddProjectFileStorageSettingButton;
