import React from 'react';

import { Button, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';

import { PropTypes } from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';

import { FullScreenModal } from 'components';

import { LABEL_SCHEMA } from '../constants';

const IncurredCostsModal = ({
  open,
  handleClose,
  locale,
  classes,
  skipAddCosts,
  handleAddCostsToQuote,
  loading,
  onDragEnd,
  children
}) => {
  const { skipCosts, ...modalClasses } = classes;

  return (
    <FullScreenModal
      classes={modalClasses}
      handleClose={handleClose}
      modalHeaderButtons={
        <>
          <Button className={skipCosts} loading={loading} type="secondary" onClick={skipAddCosts}>
            {LABEL_SCHEMA.skipIncurredCosts[locale]}
          </Button>
          <Button
            className={classes.addCostsToQuote}
            loading={loading}
            type="primary"
            onClick={handleAddCostsToQuote}
          >
            {LABEL_SCHEMA.addCostsToQuote[locale]}
          </Button>
        </>
      }
      open={open}
      title={LABEL_SCHEMA.addIncurredCostsHeader[locale]}
    >
      <Typography style={{ color: '#333333', marginBottom: 4 }} variant={TV.XL} weight={TW.BOLD}>
        {LABEL_SCHEMA.addTasks[locale]}
      </Typography>
      <Typography
        style={{ color: '#333333', marginBottom: 16 }}
        variant={TV.BASE}
        weight={TW.REGULAR}
      >
        {LABEL_SCHEMA.createTaskHeader[locale]}
      </Typography>
      <ThemeProvider>
        <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>
      </ThemeProvider>
    </FullScreenModal>
  );
};

IncurredCostsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  skipAddCosts: PropTypes.func.isRequired,
  handleAddCostsToQuote: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onDragEnd: PropTypes.func.isRequired
};

export default IncurredCostsModal;
