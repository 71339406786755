import React from 'react';

import { TV, TW, Typography } from '@BuildHero/sergeant';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

import ImageThumbnail from 'components/ImageThumbnail';

import BulkAddTaskCard from './BulkAddTaskCard';
import TasksBase from './TasksBase';

const formatMakeModelSerialNumber = o => {
  const make = o.make || '';
  const modelNumber = o.modelNumber || '';
  const serialNo = o.serialNo || '';
  return serialNo ? `${make} ${modelNumber} - ${serialNo}` : `${make} ${modelNumber}`;
};

export const formatAssetOption = o => ({
  id: o.id,
  label: o.assetName,
  rightLabel: formatMakeModelSerialNumber(o),
  value: o,
  group: o.assetType?.tagName
});

const AssetTaskLineItem = React.forwardRef(
  (
    {
      style,
      asset,
      selectedOptions,
      showBulkAddTaskComponent,
      setShowBulkAddTaskComponent,
      formOptions,
      assetMap,
      setAssetTasks,
      showCheckmarks,
      isFirstLine
    },
    assetTasksRef
  ) => {
    if (!asset) return null;
    return (
      <div key={asset.id}>
        {showBulkAddTaskComponent && isFirstLine && (
          <Grid container css={style.topLine}>
            <div>
              <Typography variant={TV.L} weight={TW.BOLD}>
                Bulk Add Tasks
              </Typography>
            </div>
            <BulkAddTaskCard
              assetMap={assetMap}
              assetOptions={selectedOptions}
              formOptions={formOptions}
              onCancel={() => setShowBulkAddTaskComponent(false)}
              onCreateTasks={newAssetTasks => {
                setAssetTasks(
                  Object.keys(assetTasksRef.current).reduce(
                    (acc, assetId) => ({
                      ...acc,
                      [assetId]: [
                        ...assetTasksRef.current[assetId],
                        ...(newAssetTasks[assetId] || [])
                      ]
                    }),
                    {}
                  )
                );
                setShowBulkAddTaskComponent(false);
              }}
            />
          </Grid>
        )}
        <Grid container css={isFirstLine ? style.topLine : {}}>
          <div css={style.titleBlock}>
            <div css={style.leftTitle}>
              <div css={style.image}>
                <ImageThumbnail image={{ fileUrl: asset.imageUrl }} />
              </div>
              <Link target="_blank" to={`/asset/view/${asset.id}`}>
                <Typography css={style.assetName} weight={TW.BOLD}>
                  {asset.assetName}
                </Typography>
              </Link>
              <Typography css={style.assetType} weight={TW.MEDIUM}>
                {asset.assetType?.tagName}
              </Typography>
            </div>
            <div css={style.rightTitle}>
              <Typography weight={TW.MEDIUM}>{formatMakeModelSerialNumber(asset)}</Typography>
            </div>
          </div>

          <TasksBase
            asset={asset}
            forms={formOptions}
            showCheckmarks={showCheckmarks}
            tasks={assetTasksRef.current[asset.id] ?? []}
            onTasksChange={newTasks => {
              setAssetTasks({ ...assetTasksRef.current, [asset.id]: newTasks });
            }}
          />
        </Grid>
      </div>
    );
  }
);

export default React.memo(AssetTaskLineItem);
