import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { getProfileInitials } from '@pm/components/utils';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 17
  },
  title: {
    marginBottom: '14px',
    fontSize: '20px',
    fontWeight: 'bold'
  },
  activity: {
    display: 'flex',
    marginTop: '10px',
    alignItems: 'center'
  },
  profilePic: {
    width: '35px',
    height: '35px',
    overflow: 'hidden',
    borderRadius: '50%',
    bottom: '0px',
    fontSize: 14,
    marginRight: 10
  },
  contentContainer: {
    display: 'flex'
  },
  nameplate: {
    fontSize: '14px',
    marginRight: 4
  },
  action: {
    fontSize: '14px',
    marginRight: 4,
    color: theme.palette.grayscale(60)
  },
  file: {
    fontSize: '14px',
    color: '#00854B'
  },
  date: {
    fontSize: '12px',
    color: theme.palette.grayscale(60)
  }
}));

const Activity = ({ activityData }) => {
  const classes = useStyles();

  const getFommattedDateTime = dateCreated => {
    let dateString = '';
    let dateFlag = false;

    return (
      new Intl.DateTimeFormat('en-US', {
        hour12: true,
        month: 'short',
        year: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      })
        .formatToParts(dateCreated)
        // eslint-disable-next-line array-callback-return
        .map(part => {
          if (dateFlag) {
            dateString = dateString.concat(' - ');
            dateFlag = false;
          } else {
            dateString = dateString.concat(part.value);
          }
          if (part.type === 'year') {
            dateFlag = true;
          }
        }) && dateString
    );
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Activity</Typography>
      {activityData.map((activity, index) => (
        <div
          className={classes.activity}
          // eslint-disable-next-line react/no-array-index-key
          key={`activity-${index}-${activity.timestamp}-${activity.user.firstName}`}
        >
          {activity.user.profilePic ? (
            <Avatar
              alt={`${activity.user.firstName} ${activity.user.lastName}`}
              className={classes.profilePic}
              src={activity.user.profilePic}
            />
          ) : (
            <Avatar className={classes.profilePic}>
              {getProfileInitials(activity.user.firstName || '', activity.user.lastName || '')}
            </Avatar>
          )}
          <div style={{ display: 'block' }}>
            <div className={classes.contentContainer}>
              <Typography className={classes.nameplate}>
                {activity.user.firstName} {activity.user.lastName}
              </Typography>
              <Typography className={classes.action}>{activity.action}</Typography>
              <a
                href={activity.file}
                rel="noopener noreferrer"
                style={{ color: '#00854B' }}
                target="_blank"
              >
                <Typography className={classes.file}>{activity.filename}</Typography>
              </a>
            </div>
            <div>
              <Typography className={classes.date}>
                {getFommattedDateTime(activity.timestamp)}
              </Typography>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Activity;
