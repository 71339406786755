import React, { Component } from 'react';

import {
  Fade,
  IconButton,
  InputAdornment,
  List,
  Paper,
  Popper,
  TextField
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import algoliasearch from 'algoliasearch/lite';
import {
  Configure,
  connectCurrentRefinements,
  connectSearchBox,
  connectStateResults,
  Hits,
  InstantSearch
} from 'react-instantsearch-dom';

import configForEnvironment from 'configs/aws-exports';
import ENV from 'configs/env';
import { bundleIndex as defaultProductIndex } from 'constants/algoliaIndex';
import { CommonService } from 'services/core';
import { Logger } from 'services/Logger';

import Hit from './Hit';
import styles from './styles';
import './style.css';

class AlgoliaSearchDropdown extends Component {
  constructor(props) {
    super(props);
    this.CommonService = new CommonService();
    this.state = {
      searchKey: '',
      searchClient: ''
    };
    this.isEdited = false;
    this.currentRefinement = '';
    this.inputRef = React.createRef();
  }

  componentDidMount = async () => {
    try {
      const { data } = await this.CommonService.getAlgoliaSecuredKey();
      const searchKey = data.getAlgoliaSecuredKey;
      const searchClient = algoliasearch(configForEnvironment(ENV).algoliaAppKey, searchKey);
      this.searchClient = searchClient;
      this.setState({ searchKey, searchClient });
    } catch (error) {
      Logger.error(error);
    }
  };

  clearRefinementsComponent = ({ items, refine }) => (
    <IconButton
      aria-label="Close"
      style={{ backgroundColor: 'transparent' }}
      onClick={() => {
        this.props.form.setFieldValue(this.props.field.name, '', false);
        return refine(items);
      }}
    >
      <CloseIcon
        disabled={!items || (items && !items.length)}
        style={{ color: '#3f3f3f', fontSize: 20 }}
      />
    </IconButton>
  );

  HitComp = props => (
    <Hit
      formProps={this.props}
      isFieldEdited={() => {
        if (this.isEdited) {
          this.isEdited = false;
        }

        return this.isFieldEdited;
      }}
      {...props}
    />
  );

  searchResults = props => {
    const { searchState, searchResults } = props;
    const { HitComp } = this;

    return searchState && searchState.query ? (
      <Popper
        anchorEl={() => this.inputRef.current}
        disablePortal
        id="searchResults"
        open={searchState !== ''}
        placement="bottom-start"
        style={{ zIndex: 5, width: 350 }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              style={{
                backgroundColor: '#fafafa',
                width: '100%',
                maxHeight: 240,
                overflowY: 'auto',
                marginTop: -20
              }}
            >
              <List component="nav">
                <Hits hitComponent={HitComp} />
                {searchResults && searchResults.nbHits === 0 && (
                  <p style={{ paddingLeft: 5 }}>
                    Unable to find item, please choose item from pricebook
                  </p>
                )}
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>
    ) : null;
  };

  SearchBox = props => {
    const { currentRefinement, refine } = props;
    const { field, label, form } = this.props;
    const { errors } = form;
    const { value } = field;
    this.currentRefinement = currentRefinement;
    const valueToDisplay = this.isEdited ? currentRefinement : value;
    const ClearRefinementsComponent = connectCurrentRefinements(this.clearRefinementsComponent);
    const hasError = errors && errors[field.name] && errors[field.name] !== '';
    return (
      <TextField
        autoComplete="off"
        className={hasError ? null : this.props.classes.inputRoot}
        error={hasError}
        fullWidth
        helperText={errors[field.name] || ''}
        id={this.props.field.name}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ClearRefinementsComponent clearsQuery />
            </InputAdornment>
          )
        }}
        label={label}
        value={valueToDisplay}
        variant="filled"
        onChange={event => {
          refine(event.currentTarget.value);
          if (!this.isEdited) {
            this.isEdited = true;
          }
        }}
      />
    );
  };

  render() {
    const { searchClient, searchKey } = this.state;
    const { specialbehaviour, form } = this.props;
    const { filterString } = specialbehaviour;
    const CustomSearchBox = connectSearchBox(this.SearchBox);
    const CustomResults = connectStateResults(this.searchResults);
    const filterCriteria = form.values[filterString];

    const filterStrArr = [];
    if (filterCriteria && filterCriteria.entityType) {
      filterStrArr.push(`entityType:${filterCriteria.entityType}`);
    }

    if (filterCriteria && filterCriteria.priceBookId) {
      filterStrArr.push(`priceBookId:${filterCriteria.priceBookId}`);
    }

    if (!searchKey || !searchClient) {
      return <p>Loading...</p>;
    }
    return (
      <InstantSearch indexName={defaultProductIndex} searchClient={this.searchClient}>
        <CustomSearchBox />
        <div id="alogliaSearchResultsFormik" ref={this.inputRef} />
        <CustomResults />
        <Configure filters={filterStrArr.join(' AND ')} />
      </InstantSearch>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AlgoliaSearchDropdown);
