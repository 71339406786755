import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const deleteAdvancedMaintenanceTemplateGroupScheduleById = gql`
  mutation deleteAdvancedMaintenanceTemplateGroupScheduleById($scheduleId: String!) {
    deleteAdvancedMaintenanceTemplateGroupScheduleById(scheduleId: $scheduleId)
  }
`;

const useDeleteAdvancedMaintenanceTemplateGroupScheduleById = ({ onSuccess = () => {} }) => {
  return useExtendedMutation(deleteAdvancedMaintenanceTemplateGroupScheduleById, {
    serializer: ({ scheduleId }) => ({
      variables: {
        scheduleId
      }
    }),
    onSuccess: (transformedData, snackbarOn) => {
      snackbarOn('success', 'Successfully removed maintenance from group');
      onSuccess();
    },
    onError: (transformedData, snackbarOn) =>
      snackbarOn('error', 'Unable to remove maintenance from group')
  });
};

export default useDeleteAdvancedMaintenanceTemplateGroupScheduleById;
