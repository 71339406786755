import gql from 'graphql-tag';

export const getPricebookById = gql`
  query($id: String!) {
    getPricebookById(id: $id) {
      name
      description
      isActive
      markupType
      markupValue
      version
      lastUpdatedDateTime
      lastUpdatedBy
      materialMarkupJSON
      pricebookLabourEntries {
        items {
          id
          version
          rate
          labourTypeId
          labourType {
            name
          }
          billingHourTypeId
          billingHourType {
            hourType
          }
          costCode {
            name
          }
          revenueType {
            name
          }
        }
      }
    }
  }
`;

export const getPricebookEntries = gql`
  query getPricebookById(
    $id: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getPricebookById(id: $id) {
      priceBookEntriesView(
        entityConnection: "PriceBookEntry"
        pagination: { limit: $limit, offset: $offset }
        filter: $filter
        sorting: $sort
      ) {
        nextToken
        items {
          id
          version
          unitCost
          unitPrice
          markupValue
          materialMarkup
          product {
            name
            code
            taxable
            description
            unitCost
            glIncomeAccountRefSortKey
            glIncomeAccountRef {
              name
            }
          }
          costCode {
            name
          }
          revenueType {
            name
          }
          jobCostType {
            name
          }
          autoUpdateScaledMarkup
        }
      }
    }
  }
`;

export const getOnlyPricebookEntries = gql`
  query getPricebookById(
    $id: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getPricebookById(id: $id) {
      priceBookEntriesView(
        entityConnection: "PriceBookEntry"
        pagination: { limit: $limit, offset: $offset }
        filter: $filter
        sorting: $sort
      ) {
        nextToken
        items {
          id
          productSortKey
          materialMarkup
          autoUpdateScaledMarkup
          version
        }
      }
    }
  }
`;

export const updatePricebookEntry = gql`
  mutation updatePricebookEntry($partitionKey: String!, $data: UpdatePriceBookEntryInput!) {
    updatePriceBookEntry(partitionKey: $partitionKey, data: $data) {
      version
    }
  }
`;

export const addLabourRate = gql`
  mutation addLabourRate($partitionKey: String, $data: AddPricebookLabourEntriesToPriceBookInput!) {
    addPricebookLabourEntriesToPriceBook(partitionKey: $partitionKey, data: $data) {
      id
      version
    }
  }
`;

export const updateLabourRate = gql`
  mutation updateLabourRate($partitionKey: String, $data: UpdatePricebookLabourEntryInput!) {
    updatePricebookLabourEntry(partitionKey: $partitionKey, data: $data) {
      version
      rate
    }
  }
`;

export default getPricebookById;
