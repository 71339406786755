/* eslint-disable no-underscore-dangle */
import React from 'react';

import { Field, MUIForm, ThemeProvider } from '@BuildHero/sergeant';
import { ButtonGroup, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { connect } from 'react-redux';

import DeleteIcon from 'assets/Icons/Delete.svg';
import ReplenishIcon from 'assets/Icons/Replenish.svg';
import {
  ConfirmModal,
  DefaultButton,
  Modal,
  PageHeader,
  Spinner,
  UserPermission
} from 'components';
import ProductSearch from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import ResponsiveTable from 'components/ResponsiveTable';
import { inventoryListMeta } from 'meta/Inventory/tables';
import { truckInventoryForm } from 'meta/Inventory/truckInventoryForm';
import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { InventoryService } from 'services/core';

import { Logger } from 'services/Logger';
import { PermissionConstants } from 'utils/AppConstants';
import minMaxValidation from 'utils/minMaxValidation';

import {
  addTruckInventories,
  bulkDeleteTruckInventories,
  deleteTruckInventories,
  doReplenishmentForItems,
  updateTruckInventories
} from '../truck-service';

import LocalInventorySearch from './LocalInventorySearch';

import styles from './styles';

const getInventoryMassagedData = inventoryItems => {
  const formattedData = inventoryItems.map(item => ({
    productName: item?.product?.name,
    productCode: item?.product?.code,
    productDescription: item?.product?.description,
    unitOfMeasureValue: item?.product?.unitOfMeasure?.name,
    ...item
  }));
  return formattedData || [];
};

let service;

function TruckDetail(props) {
  const InventoryServiceObj = new InventoryService();
  const { user } = props;
  const classes = styles();
  const truckId = props.computedMatch.params && props.computedMatch.params.id;
  const [truckData, setTruckData] = React.useState();
  const [inventoryItems, setInventoryItems] = React.useState();
  const [filterApplied, setFilterApplied] = React.useState(false);
  const [itemForm, setItemForm] = React.useState({});
  const [confirmModalSetting, setConfirmModalSetting] = React.useState({});
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [refreshCounter, setRefreshCounter] = React.useState(0);
  document.title = `BuildOps - Truck ${truckData?.name || ''}`;

  const getTruckData = async () => {
    try {
      const truckDetails = await InventoryServiceObj.getTruckDetails(truckId);
      if (truckDetails) {
        setTruckData(truckDetails);
        setInventoryItems(truckDetails?.truckInventories?.items || []);
      }
    } catch (err) {
      Logger.error(err);
      snackbarOn('error', 'Unable to fetch truck details');
    }
  };

  React.useEffect(() => {
    if (truckId && !truckData) {
      getTruckData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [truckId]);

  const handleInventoryFormSave = async (formdata, mode) => {
    const name = formdata.name || formdata.productName;
    let message = `Successfully added inventory item - ${name}`;
    const { isValid, message: validationMessage } = minMaxValidation(formdata);
    if (!isValid) return props.snackbarOn('error', validationMessage);

    switch (mode) {
      case 'new':
        await addTruckInventories(formdata, truckId);
        break;
      case 'edit':
        await updateTruckInventories(formdata);
        message = `Successfully updated inventory item - ${name}`;
        break;
      default:
        break;
    }
    setItemForm({ open: false, record: '', mode: 'new' });
    props.snackbarOn('success', message);
    await getTruckData();
  };

  const handleRowActions = (actionName, actionData) => {
    // If action is not select, the associated data is all selected rows;
    // for delete and edit, it is a single row
    switch (actionName) {
      case 'select':
        setSelectedItems(actionData);
        break;

      case 'delete':
        setConfirmModalSetting({
          confirmMessage: `item - ${actionData.productName}`,
          open: true,
          cancelAction: () => setConfirmModalSetting({ open: false, confirmMessage: '' }),
          action: async () => {
            await deleteTruckInventories(actionData.id, actionData.productName, snackbarOn);
            setConfirmModalSetting({
              open: false,
              confirmMessage: '',
              cancelAction: '',
              action: ''
            });
            await getTruckData();
          }
        });
        break;

      case 'edit':
        setItemForm({ open: true, record: actionData, mode: 'edit' });
        break;

      default:
        // No-op
        break;
    }
  };

  const handleReplenishment = async () => {
    if (selectedItems.length <= 0) {
      return props.snackbarOn('warning', 'Please select inventory items');
    }
    await doReplenishmentForItems(selectedItems, truckId);
    props.snackbarOn('success', `Successfully replenished ${selectedItems.length} items`);
    await getTruckData();
    setSelectedItems([]);
    setRefreshCounter(refreshCounter + 1);
  };

  const handleBulkDeleteItem = async () => {
    await bulkDeleteTruckInventories(selectedItems);
    props.snackbarOn('success', `Successfully deleted ${selectedItems.length} items`);
    setSelectedItems([]);
    await getTruckData();
    setRefreshCounter(refreshCounter + 1);
  };

  const handleBulkDeleteConfirmation = () => {
    if (selectedItems.length <= 0) {
      return props.snackbarOn('warning', 'Please select inventory items');
    }
    setConfirmModalSetting({
      confirmMessage: 'the selected Inventory Items',
      open: true,
      cancelAction: () => setConfirmModalSetting({ open: false, confirmMessage: '' }),
      action: async () => {
        await handleBulkDeleteItem();
        setConfirmModalSetting({
          open: false,
          confirmMessage: '',
          cancelAction: '',
          action: ''
        });
      }
    });
  };

  const handleInventoryFilter = async event => {
    const searchText = event.currentTarget.value;
    if (!searchText) {
      setInventoryItems(truckData?.truckInventories?.items);
      return;
    }

    const filteredInventoryItems = inventoryItems.filter(
      item =>
        item?.product?.name?.includes(searchText) ||
        item?.product?.description?.includes(searchText)
    );
    setInventoryItems(filteredInventoryItems);
  };

  const getCriteria = filterKey => {
    let criteria;
    switch (filterKey) {
      case 'inStock':
        criteria = item =>
          item.quantityOnHand >= item.minQuantity && item.quantityOnHand <= item.maxQuantity;
        break;
      case 'belowStock':
        criteria = item => item.quantityOnHand < item.minQuantity;
        break;
      case 'outOfStock':
        criteria = item => item.quantityOnHand === 0;
        break;
      default:
        criteria = null;
    }
    return criteria;
  };

  const handleQuantityOnHandFilter = async filterKeyArray => {
    // when reset / All is clicked
    if (!filterKeyArray.length || filterKeyArray.includes('all')) {
      setInventoryItems(truckData?.truckInventories?.items);
      return;
    }

    const finalCriteria = filterKeyArray.map(key => getCriteria(key));
    if (!finalCriteria) {
      setInventoryItems(truckData?.truckInventories?.items);
      setFilterApplied(true);
      return;
    }
    const filteredItems = truckData?.truckInventories?.items.filter(item =>
      finalCriteria.some(criteriaFn => criteriaFn(item))
    );
    setInventoryItems(filteredItems);
    setFilterApplied(true);
  };

  let employeeNames;

  if ((truckData?.truckEmployees?.items || []).length > 0) {
    employeeNames = truckData?.truckEmployees?.items
      .map(item => `${item?.employee?.firstName ?? ''} ${item?.employee?.lastName ?? ''}`)
      .join(', ');
  }

  if (!truckData || !inventoryItems) {
    return <Spinner />;
  }

  return (
    <ErrorBoundaries>
      <UserPermission action={PermissionConstants.OBJECT_INVENTORTY_TRUCK} I="read">
        <Grid container>
          <Grid className={classes.topBar} item lg={12} md={12} sm={12} xl={12} xs={12}>
            <PageHeader
              breadcrumbsArray={[
                { link: '/inventory/list', title: Labels.inventory[user.locale] },
                { link: '', title: truckData?.name || '' }
              ]}
            />
            <ThemeProvider>
              <Grid className={classes.topBarDetails} container spacing={3}>
                <Grid item>
                  <Field label="Truck Name" value={truckData?.name} />
                </Grid>
                <Grid item>
                  <Field label={Labels.technicianName[user.locale]} value={employeeNames} />
                </Grid>
                <Grid item>
                  <Field
                    label={Labels.inventoryBundle[user.locale]}
                    value={truckData?.productBundle?.name}
                  />
                </Grid>
              </Grid>
            </ThemeProvider>
          </Grid>
          <Grid
            alignItems="center"
            className={classes.inventoryTitleContainer}
            container
            justify="space-between"
          >
            <Grid item>
              <Typography className={classes.titleStyle}>
                {Labels.InventoryItems[user.locale]}
              </Typography>
            </Grid>
            <Grid item>
              <DefaultButton
                color="primary"
                handle={() => setItemForm({ open: true, record: '', mode: 'new' })}
                label={Labels.newItem[user.locale]}
                variant="contained"
              />
            </Grid>
          </Grid>
          <Grid
            alignItems="center"
            className={classes.inventoryTitleContainer}
            container
            justify="space-between"
          >
            <Grid item>
              <LocalInventorySearch
                data={inventoryItems}
                filterApplied={filterApplied}
                onFilter={handleQuantityOnHandFilter}
                onSearch={handleInventoryFilter}
              />
            </Grid>
            <Grid item>
              <ButtonGroup>
                <UserPermission action={PermissionConstants.OBJECT_INVENTORTY_TRUCK} I="update">
                  <Tooltip title="Replenish">
                    <IconButton
                      aria-label="Replenish"
                      className={classes.iconButtonLeftStyles}
                      onClick={handleReplenishment}
                    >
                      <img alt="Replenish" src={ReplenishIcon} />
                    </IconButton>
                  </Tooltip>
                </UserPermission>
                <UserPermission action={PermissionConstants.OBJECT_INVENTORTY_TRUCK} I="delete">
                  <Tooltip title="Delete">
                    <IconButton
                      aria-label="delete"
                      className={classes.iconButtonRightStyles}
                      onClick={handleBulkDeleteConfirmation}
                    >
                      <img alt="Delete" src={DeleteIcon} />
                    </IconButton>
                  </Tooltip>
                </UserPermission>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <ResponsiveTable
              caslKey={PermissionConstants.OBJECT_INVENTORTY_TRUCK}
              data={getInventoryMassagedData(inventoryItems || [])}
              disableFilter
              key={refreshCounter}
              noDataMsg="No inventory added"
              rowActionButtons={{
                select: {
                  referenceKey: 'id'
                },
                edit: {
                  label: 'Edit',
                  icon: 'BorderColor',
                  caslAction: 'update',
                  caslKey: PermissionConstants.OBJECT_INVENTORTY_TRUCK
                },
                delete: {
                  label: 'Delete',
                  icon: 'Delete',
                  caslAction: 'delete',
                  caslKey: PermissionConstants.OBJECT_INVENTORTY_TRUCK
                }
              }}
              rowActions={handleRowActions}
              rowMetadata={inventoryListMeta}
            />
          </Grid>
        </Grid>
        <Modal
          buttons={{
            cancel: {
              buttonType: 'contained',
              color: 'secondary',
              label: 'Cancel',
              action: () => setItemForm({ open: false, record: '', mode: '' })
            },
            save: {
              buttonType: 'contained',
              color: 'primary',
              action: () => {
                if (service) {
                  service.submit();
                } else {
                  Logger.error('Service not available in New truck creation');
                }
              },
              label: 'Save'
            }
          }}
          handleClose={() => setItemForm({ open: false, record: '', mode: '' })}
          modalTitle={itemForm.mode === 'edit' ? 'Edit item' : 'New item'}
          open={itemForm.open || false}
          showModalHeader
          width="500"
        >
          <MUIForm
            configuration={truckInventoryForm}
            customComponents={{ ProductSearch }}
            data={itemForm?.record || {}}
            layout={itemForm.mode}
            onComplete={completedData => {
              handleInventoryFormSave(completedData, itemForm.mode);
            }}
            onCreateService={formService => {
              if (formService) service = formService;
            }}
          />
        </Modal>
        <ConfirmModal
          cancel={confirmModalSetting.cancelAction}
          confirm={confirmModalSetting.action}
          message={confirmModalSetting.confirmMessage}
          open={confirmModalSetting.open}
        />
      </UserPermission>
    </ErrorBoundaries>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

export default connect(mapStateToProps, mapDispatcherToProps)(TruckDetail);
