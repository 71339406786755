import React, { useRef } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Labels from 'meta/labels';
import { constructSelectOptions } from 'utils/constructSelectOptions';

import visitFormLayout from './layout';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: 24
  }
});

function VisitForm(props) {
  const classes = useStyles();
  const { onClickNextStep, forms, userLocale, pageTitle, stepData } = props;
  const formService = useRef();
  const layout = visitFormLayout(constructSelectOptions(forms));

  return (
    <Box margin="auto" maxWidth="500px">
      <Typography className={classes.title} variant="h4">
        {pageTitle}
      </Typography>
      <MUIForm
        configuration={layout}
        data={stepData}
        layout="edit"
        onComplete={onClickNextStep}
        onCreateService={service => {
          formService.current = service;
        }}
      />
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          variant="containedSecondary"
          onClick={() => formService.current.submit()}
        >
          {Labels.nextStep[userLocale]}
        </Button>
      </div>
    </Box>
  );
}

VisitForm.propTypes = {
  onClickNextStep: PropTypes.func.isRequired,
  userLocale: PropTypes.isRequired,
  forms: PropTypes.array
};

VisitForm.defaultProps = {
  forms: []
};

export default VisitForm;
