import { useMemo } from 'react';

import { useDrop } from 'react-dnd';

import { ItemTypes, selectDragScenario } from '@dispatch/dnd';
import { useDispatchSettings } from '@dispatch/settings';

import { LOADING_TECHS } from './WeekView.constants';
import { selectTechEvents } from './WeekView.selectors';

export const useItemData = ({ techsResponse, dispatchEventsResponse, startDay }) => {
  const { weekViewStartDay } = useDispatchSettings();

  return useMemo(() => {
    if (techsResponse.loading || dispatchEventsResponse.loading) {
      return LOADING_TECHS;
    }

    const itemData = techsResponse.data.map(tech => ({
      tech,
      techLoading: false,
      eventsLoading: !dispatchEventsResponse.data[tech.id],
      visitsLoading: dispatchEventsResponse.loading,
      techEvents: selectTechEvents(
        dispatchEventsResponse?.data?.[tech.id],
        startDay,
        weekViewStartDay
      )
    }));

    return [{}, ...itemData];
  }, [techsResponse, dispatchEventsResponse, startDay, weekViewStartDay]);
};

export const useVisitDrop = () => {
  const [collected, dropRef] = useDrop({
    accept: [ItemTypes.TABLE_VISIT, ItemTypes.BOARD_VISIT],
    collect: monitor => ({
      isOver: monitor.isOver(),
      dragScenario: selectDragScenario(monitor.getItem()),
      primaryTech: monitor.getItem()?.data?.primaryTechId,
      extraTechs: monitor.getItem()?.data?.extraTechs,
      timesheetEntryBinders: monitor.getItem()?.data?.timesheetEntryBinders
    })
  });

  return [collected, dropRef];
};
