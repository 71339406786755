import { FieldType } from '@BuildHero/sergeant';

import { FeatureFlags } from 'utils/FeatureFlagConstants';
import { sgtPricebookItemCalc } from 'utils/onCalcChange';

export const pricebookEntryLayout = (isQuickBooksEnabled, showMargin, showMarkup, flags = {}) => {
  const rowStyle = {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 15,
    marginTop: 15
  };
  const itemStyle = { flex: '1 1 100%' };

  const row4a = showMarkup && {
    props: {
      style: rowStyle
    },
    contents: [
      {
        props: {
          style: itemStyle,
          label: 'Price Book Markup',
          type: FieldType.PERCENTAGE
        },
        component: 'Field',
        source: 'pricebookMarkup'
      },
      {
        props: {
          style: itemStyle,
          label: 'Material Markup',
          onChange: sgtPricebookItemCalc,
          isRequired: true
        },
        component: 'PercentageInput',
        source: 'materialMarkup'
      },
      {
        props: {
          style: itemStyle,
          label: 'Total Markup',
          onChange: sgtPricebookItemCalc,
          isRequired: true
        },
        component: 'PercentageInput',
        source: 'markupValue'
      }
    ]
  };

  const row4b = showMargin && {
    props: {
      style: rowStyle
    },
    contents: [
      {
        props: {
          style: itemStyle,
          label: 'Price Book Margin',
          type: FieldType.PERCENTAGE
        },
        component: 'Field',
        source: 'pricebookMargin'
      },
      {
        props: {
          style: itemStyle,
          label: 'Material Margin',
          onChange: sgtPricebookItemCalc,
          isRequired: true
        },
        component: 'PercentageInput',
        source: 'materialMargin'
      },
      {
        props: {
          style: itemStyle,
          label: 'Total Margin',
          onChange: sgtPricebookItemCalc,
          isRequired: true
        },
        component: 'PercentageInput',
        source: 'marginValue'
      }
    ]
  };

  return {
    fields: {
      materialMarkup: {
        default: 'materialMarkup'
      },
      materialMargin: {
        default: 'materialMarkup'
      },
      unitCost: {
        default: 'unitCost'
      },
      unitPrice: {
        default: 'unitPrice'
      }
    },
    validation: {
      type: 'object',
      properties: {
        materialMarkup: {
          type: 'number'
        },
        materialMargin: {
          type: 'number'
        },
        unitCost: {
          type: 'number'
        },
        unitPrice: {
          type: 'number'
        }
      },
      required: ['materialMarkup', 'unitCost', 'unitPrice']
    },
    validationErrors: {
      materialMarkup: {
        required: 'Material markup is required.',
        type: 'Material markup must be a valid number.'
      },
      materialMargin: {
        required: 'Material margin is required.',
        type: 'Material margin must be a valid number.'
      },
      unitCost: {
        required: 'Unit cost is required.',
        type: 'Unit cost must be a valid number.'
      },
      unitPrice: {
        required: 'Unit price is required.',
        type: 'Unit price must be a valid number.'
      }
    },
    layouts: {
      default: {
        contents: [
          {
            // row 1
            props: {
              style: {
                ...rowStyle,
                marginTop: 0
              }
            },
            contents: [
              {
                props: {
                  style: itemStyle,
                  label: 'Product Name',
                  type: FieldType.TEXT
                },
                component: 'Field',
                source: 'product.name'
              },
              {
                props: {
                  style: itemStyle,
                  label: 'Product Code',
                  type: FieldType.TEXT
                },
                component: 'Field',
                source: 'product.code'
              },
              isQuickBooksEnabled && {
                props: {
                  style: itemStyle,
                  label: 'Income Account',
                  type: FieldType.CURRENCY
                },
                component: 'Field',
                source: 'product.glIncomeAccountRef.name'
              },
              {
                props: {
                  style: itemStyle,
                  label: 'Taxable',
                  disabled: true
                },
                component: 'Checkbox',
                source: 'product.taxable'
              }
            ].filter(Boolean)
          },
          {
            // row 2
            props: {
              style: rowStyle
            },
            contents: [
              {
                props: {
                  label: 'Product Description',
                  type: FieldType.TEXT
                },
                component: 'Field',
                source: 'product.description'
              }
            ]
          },
          {
            // row 3
            props: {
              style: rowStyle
            },
            contents: [
              {
                props: {
                  style: itemStyle,
                  label: 'Cost Code',
                  type: FieldType.TEXT
                },
                component: 'Field',
                source: 'costCode.name'
              },
              {
                props: {
                  style: itemStyle,
                  label: 'Cost Type',
                  type: FieldType.TEXT
                },
                component: 'Field',
                source: 'jobCostType.name'
              },
              {
                props: {
                  style: itemStyle,
                  label: 'Revenue Type',
                  type: FieldType.TEXT
                },
                component: 'Field',
                source: 'revenueType.name'
              }
            ]
          },
          row4a,
          row4b,
          {
            // row 5
            props: {
              style: rowStyle
            },
            contents: [
              {
                props: {
                  style: itemStyle,
                  label: 'Unit Cost',
                  type: FieldType.CURRENCY
                },
                component: 'Field',
                source: 'unitCost'
              },
              {
                props: {
                  style: itemStyle,
                  label: 'Unit Price',
                  isRequired: true,
                  onChange: sgtPricebookItemCalc,
                  precision: 2
                },
                component: 'CurrencyInput',
                source: 'unitPrice'
              },
              flags[FeatureFlags.PRICEBOOK_AUTO_UPDATE_SCALED_MARKUP] && {
                props: {
                  style: itemStyle,
                  label: 'Auto-update scaled markup',
                  tooltip:
                    'Unit price will update automatically if changes in unit cost move the item into a different range-based markup bracket.'
                },
                component: 'Checkbox',
                source: 'autoUpdateScaledMarkup'
              }
            ].filter(Boolean)
          }
        ]
      }
    }
  };
};
