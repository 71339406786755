import React from 'react';

import { Fade, List, ListItem, ListItemIcon, ListItemText, Paper, Popper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import UserPermisson from '../../AppPermissions';
import displayIconElement from '../icons';
import MenuItem from '../MenuItem';
import MenuSubSection from '../MenuSubSection';

const useStyles = makeStyles(theme => ({
  menuPopover: {
    paddingTop: theme.spacing(10),
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
    zIndex: 500
  },
  menuPopoverItem: {
    color: theme.palette.text.primary,
    borderBottom: '2px solid transparent',
    backgroundColor: theme.palette.grayscale(100),
    '&:hover': {
      backgroundColor: theme.palette.grayscale(98),
      color: theme.palette.secondary.main
    }
  },
  listIconWrapper: {
    color: 'inherit',
    margin: 0,
    marginRight: theme.spacing(1)
  },
  listIcon: {
    fontSize: '16px'
  },
  listTexts: {
    fontWeight: 'inherit',
    textTransform: 'capitalize',
    cursor: 'pointer'
  }
}));

const BHPopper = ({
  menuItems,
  tenantSettingProcessed,
  handleClosePopover,
  id,
  open,
  anchorEl,
  isSmallScreen,
  buttonElements,
  user,
  placement = 'bottom-start'
}) => {
  const classes = useStyles();
  const popoverMenuWidth = isSmallScreen ? 'auto' : 200;

  const renderSections = item =>
    item.subSection ? (
      <UserPermisson
        action={item.menu}
        I={item.caslAction}
        key={`perm${item.title || item.pageMapKey}`}
        scope={item.scope}
      >
        <MenuSubSection
          isPopover
          isSmallScreen={isSmallScreen}
          key={`perm${item.subSection.title || item.subSection.pageMapKey}`}
          subSection={item.subSection}
          tenantSettingProcessed={tenantSettingProcessed}
          user={user}
        />
      </UserPermisson>
    ) : (
      <UserPermisson
        action={item.menu}
        I={item.menu.caslAction} // When object, permission component will extract caslkey
        key={`perm${item.menu.title || item.menu.pageMapKey}`}
        scope={item.menu.scope}
      >
        <MenuItem
          data={item.menu}
          handleClosePopover={handleClosePopover}
          isPopover
          isSmallScreen={isSmallScreen}
          key={`${item.menu.title || item.menu.pageMapKey}_${item.menu.url}`}
          nested="true"
          tenantSettingProcessed={tenantSettingProcessed}
        />
      </UserPermisson>
    );

  return (
    <Popper
      anchorEl={anchorEl}
      classes={{ root: classes.menuPopover }}
      id={id}
      open={open}
      placement={placement}
      style={{ zIndex: 500 }}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={0}>
          <Paper
            style={{
              boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
              marginTop: 4,
              borderRadius: 4,
              width: popoverMenuWidth
            }}
          >
            <List component="div" disablePadding>
              {menuItems.map(item =>
                buttonElements ? (
                  <ButtonItem
                    iconKey={item.menu.iconKey}
                    title={item.menu.title || item.menu.pageMapKey}
                    onClick={item.menu.onClick}
                  />
                ) : (
                  renderSections(item)
                )
              )}
            </List>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

const ButtonItem = props => {
  const classes = useStyles();
  const { title, iconKey, onClick } = props;
  const icon = displayIconElement(iconKey);
  return (
    <ListItem
      classes={{
        root: classes.menuPopoverItem
      }}
      key={`menuItem-${title}`}
      onClick={event => {
        if (onClick) onClick(event);
      }}
    >
      {icon && (
        <ListItemIcon classes={{ root: classes.listIconWrapper }}>
          {React.cloneElement(displayIconElement('addCircleIcon'), { className: classes.listIcon })}
        </ListItemIcon>
      )}
      <ListItemText classes={{ root: classes.listTexts }} disableTypography primary={title} />
    </ListItem>
  );
};

export default BHPopper;
