import React, { useMemo } from 'react';

import { roundSecondsToPayrollHour } from '@BuildHero/math';
import { useTheme } from '@emotion/react';
import findLastIndex from 'lodash/findLastIndex';
import moment from 'moment';
import PropTypes from 'prop-types';

import { AppConstants, VisitEndEvents } from 'utils/AppConstants';

import VisitTimeHistoryEntry from './VisitTimeHistoryEntry';

const useStyles = theme => ({
  timeHistoryRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginBottom: '2em',
    marginTop: '2em',
    flexWrap: 'wrap',
    gap: '0.5em'
  },
  line: {
    width: '2em',
    height: '1px',
    border: '0.5px solid',
    borderColor: theme.palette.grayscale(80)
  }
});

const labourTypeLabels = {
  OnABreak: 'Break'
};

const formatDuration = ({ clockInTime, clockOutTime, format = '' }) => {
  if (!clockInTime) return '-';
  if (!clockOutTime) return 'In Progress';
  const d = moment.duration(clockOutTime - clockInTime, 'seconds');

  if (format === 'hours') {
    return `${roundSecondsToPayrollHour(d.asSeconds())}h`;
  }
  const day = d.days();
  const h = d.hours();
  const m = d.minutes();
  const s = d.seconds();

  const days = day > 0 ? `${day}d` : '';
  const hours = h > 0 ? `${h}h` : '';
  const minutes = m > 0 ? `${m}m` : '';
  const seconds = s > 0 ? `${s}s` : '';

  return [days, hours, minutes, seconds].filter(str => str !== '').join(' ');
};

const momentTimezone = (time, timeZone) => moment.unix(time).tz(timeZone);

const parseTimestamp = (timestamp, companyTZ) => {
  const {
    labourType,
    clockInTime,
    clockInTimezone,
    clockOutTime,
    clockOutTimezone,
    gpsLocations
  } = timestamp;

  const clockInTZ = clockInTimezone || companyTZ;
  const clockOutTZ = clockOutTimezone || clockInTimezone || companyTZ;
  const dateTimeFormat = AppConstants.DATETIME_FORMAT_WITH_TIMEZONE;

  return {
    ...timestamp,
    labourType: labourType ? labourTypeLabels[labourType] || labourType : 'N/A',
    clockInDateTime: momentTimezone(clockInTime, clockInTZ).format(dateTimeFormat),
    clockOutDateTime: momentTimezone(clockOutTime, clockOutTZ).format(dateTimeFormat),
    clockOutTimeShort: momentTimezone(clockOutTime, clockOutTZ).format('hh:mma'),
    duration: formatDuration({ clockInTime, clockOutTime }),
    hourDuration: formatDuration({ clockInTime, clockOutTime, format: 'hours' }),
    gpsLocation: gpsLocations?.items && gpsLocations?.items.length ? gpsLocations.items[0] : {}
  };
};

const VisitTimeHistory = ({ timestamps, payrollSetting, status }) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const formattedTimestamps = useMemo(() => {
    const displayTimestamps = [];
    timestamps.forEach((timestamp, i) => {
      if (!timestamp.labourType || !timestamp.clockInTime) return;
      const nextTimestamp = timestamps[i + 1];
      // when we perform actions too close together the previous action might not get closed
      // derive the clockout time from next action start
      const derivedTimestamp =
        timestamp.clockOutTime || !nextTimestamp
          ? timestamp
          : { ...timestamp, clockOutTime: nextTimestamp.clockInTime };
      displayTimestamps.push(parseTimestamp(derivedTimestamp, payrollSetting.timeZone));
    });
    return displayTimestamps;
  }, [timestamps, payrollSetting]);

  if (!timestamps.length) return null;

  return (
    <>
      <div css={styles.timeHistoryRow}>
        {formattedTimestamps?.map((timestamp, i) => (
          <>
            <VisitTimeHistoryEntry
              gpsLocation={timestamp.gpsLocation}
              modalDetails={[
                `Clock In: ${timestamp.clockInDateTime}`,
                `Duration: ${timestamp.duration}`
              ]}
              modalTitle={timestamp.labourType}
              theme={theme}
              timestampDetails={timestamp.hourDuration}
              timestampLabel={timestamp.labourType}
              toolTipDetails={[`Clock In: ${timestamp.clockInDateTime}`]}
              toolTipTitle={`${timestamp.labourType}: ${timestamp.duration}`}
            />

            {i < findLastIndex(formattedTimestamps) && <div css={styles.line} />}
            {i === findLastIndex(formattedTimestamps) &&
              timestamp.clockOutTime &&
              VisitEndEvents.includes(status) && (
                <>
                  <div css={styles.line} />
                  <VisitTimeHistoryEntry
                    gpsLocation={timestamp.gpsLocation}
                    modalDetails={[`Clock Out: ${timestamp.clockOutDateTime}`]}
                    modalTitle="Work Completed"
                    theme={theme}
                    timestampDetails={timestamp.clockOutTimeShort}
                    timestampLabel="Finished at"
                    toolTipDetails={[`Clock Out: ${timestamp.clockOutDateTime}`]}
                    toolTipTitle="Work Completed"
                  />
                </>
              )}
          </>
        ))}
      </div>
    </>
  );
};

VisitTimeHistory.propTypes = {
  timestamps: PropTypes.arrayOf(PropTypes.object),
  payrollSetting: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired
};

VisitTimeHistory.defaultProps = {
  timestamps: []
};

export default VisitTimeHistory;
