import { useEffect, useState } from 'react';

import { FieldType } from '@BuildHero/sergeant';
import gql from 'graphql-tag';
import { assign, cloneDeep, pick } from 'lodash';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import useExtendedQuery from 'customHooks/useExtendedQuery';

import DefaultTenantSettings from './data';

export const tenantFields = [
  'id',
  'companyName',
  'accountName',
  'ownerName',
  'tier',
  'email',
  'status',
  'userName',
  'firstName',
  'lastName',
  'type',
  'salesforceId'
];

export const tenantSettingFields = ['id', 'settingKey', 'settingPath', 'settingValue'];

const TENANT_FRAGMENT = gql`
  fragment TenantFields on Tenant {
    ${tenantFields.join('\n')}
    tenantSettings(entityConnection: "TenantSetting") {
      items {
        ${tenantSettingFields.join('\n')}
      }
    }
  }
`;

export const formatDataForServer = val => {
  return {
    ...pick(val, tenantFields),
    type: val.type?.value,
    tenantSettings: val.tenantSettings.map(ts => {
      const setting = pick(ts, tenantSettingFields);

      switch (ts.type) {
        case FieldType.BOOL: {
          setting.settingValue = setting.settingValue.toString();
          break;
        }
        case FieldType.CHIP: {
          setting.settingValue = setting.settingValue?.value;
          break;
        }
        default: {
          break;
        }
      }

      return setting;
    })
  };
};

const formatDataFromServer = val => {
  const settingKeyToSetting = val.tenantSettings.items.reduce(
    (acc, setting) => ({ ...acc, [setting.settingKey]: setting }),
    {}
  );

  const tenantSettings = cloneDeep(DefaultTenantSettings).map(ts => {
    const setting = ts;

    // override the default setting with value from server
    const settingFromServer = settingKeyToSetting[ts.settingKey];
    if (settingFromServer) {
      assign(setting, settingFromServer);
    }

    // fix the data type for each setting
    switch (setting.type) {
      case FieldType.BOOL: {
        setting.settingValue = ['true', '1'].includes(setting.settingValue?.toLowerCase?.());
        break;
      }
      case FieldType.CHIP: {
        if (typeof setting.settingValue === 'string')
          setting.settingValue = { label: setting.settingValue, value: setting.settingValue };
        break;
      }
      default: {
        break;
      }
    }

    return setting;
  });

  return {
    ...val,
    type: { label: val.type, value: val.type },
    tenantSettings
  };
};

const GET_TENANT = gql`
  query getTenantById($id: String!) {
    getTenantById(id: $id) {
      ...TenantFields
    }
  }
  ${TENANT_FRAGMENT}
`;
export const useTenantQuery = id => {
  const [tenant, setTenant] = useState(null);

  const { data, ...rest } = useExtendedQuery(GET_TENANT, {
    transform: ({ getTenantById: val }) => formatDataFromServer(val),
    variables: { id },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  useEffect(() => {
    setTenant(data);
  }, [data]);

  return { ...rest, loading: rest.loading || !tenant, data: tenant, setData: setTenant };
};

const MUTATE_TENANT = gql`
  mutation updateTenant($partitionKey: String, $data: UpdateTenantInput!) {
    updateTenant(partitionKey: $partitionKey, data: $data) {
      ...TenantFields
    }
  }
  ${TENANT_FRAGMENT}
`;

export const useTenantMutation = onCompleted => {
  return useExtendedMutation(MUTATE_TENANT, {
    serializer: data => ({
      variables: { partitionKey: data.id, data }
    }),
    onCompleted: ({ updateTenant: val }) => {
      onCompleted(formatDataFromServer(val));
    }
  });
};
