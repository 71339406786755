import { BILLING_INFO_TOOLTIP } from 'utils/constants';

import validation from './CreateInvoice.validation';

const rowStyle = {
  gap: 16,
  flexDirection: 'row',
  marginBottom: 16
};
const colStyle = {
  width: 200
};

export default ({
  departmentOptions,
  priceBookOptions,
  isMultiplePriceBooksEnabled,
  getRelatedData,
  isVistaIntegrated,
  defaultPriceBook
}) => ({
  layouts: {
    default: {
      props: {
        style: { paddingBottom: 64, maxWidth: 1200, margin: 'auto' }
      },
      contents: [
        {
          // row 1
          props: { style: rowStyle },
          contents: [
            {
              component: 'InvoiceParentSearch',
              source: 'parentName',
              resolveProps: ({ loading }) => ({ disabled: loading }),
              props: {
                testingid: 'invoiceParentSearch',
                style: { width: 616 },
                label: 'Create invoice for',
                required: true,
                getRelatedData,
                defaultPriceBook
              }
            }
          ]
        },
        {
          // row 2
          props: { style: rowStyle },
          contents: [
            {
              component: 'Select',
              source: 'department',
              resolveProps: ({ loading, parentName, jobDepartmentOptions }) => {
                const props = { disabled: loading };
                // for vista, we limit departments to the selected job's
                // jobDepartmentOptions is populated on job selection
                if (isVistaIntegrated) {
                  const isParentEmpty = !parentName;
                  const hasOneNewOption = jobDepartmentOptions?.length === 1;
                  props.tooltip =
                    (isParentEmpty && 'Please select the invoice parent first.') ||
                    (hasOneNewOption && 'There is only one option.');
                  props.options = jobDepartmentOptions;
                  props.disabled = props.disabled || isParentEmpty || hasOneNewOption;
                }

                return props;
              },
              props: {
                testingid: 'selectDepartment',
                style: colStyle,
                label: 'Department',
                options: departmentOptions,
                required: isVistaIntegrated,
                searchable: true
              }
            },
            isMultiplePriceBooksEnabled && {
              component: 'Select',
              source: 'priceBook',
              resolveProps: ({ loading }) => ({ disabled: loading }),
              props: {
                style: colStyle,
                label: 'Price Book',
                options: priceBookOptions,
                required: true,
                searchable: true,
                defaultPriceBook,
                testingid: 'priceBook'
              }
            }
          ]
        },
        {
          // row 3
          props: { style: rowStyle },
          contents: [
            {
              component: 'Input',
              source: 'billTo',
              resolveProps: ({ loading }) => ({ disabled: loading }),
              props: {
                inputProps: {
                  testingid: 'bill-to-customer'
                },
                style: { width: 416 },
                label: 'Billing Information',
                placeholder: 'Customer Name\nBill To',
                tooltip: BILLING_INFO_TOOLTIP,
                multiline: true,
                rows: 2,
                rowsMax: 4,
                required: true
              }
            }
          ]
        },
        {
          // row 4
          props: { style: rowStyle },
          contents: [
            {
              component: 'Address',
              source: 'billingAddress',
              props: {
                testingid: 'billingAddress',
                style: colStyle,
                label: 'Billing Address'
              }
            }
          ]
        },
        {
          // row 5
          props: { style: rowStyle },
          contents: [
            {
              component: 'Address',
              source: 'propertyAddress',
              props: {
                testingid: 'propertyAddress',
                style: colStyle,
                label: 'Property Address'
              }
            }
          ]
        }
      ]
    }
  },
  validation: validation(isVistaIntegrated)
});
