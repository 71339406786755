import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const BULK_DELETE_TASKS = gql`
  mutation bulkDeleteTasks($partitionKey: String!, $taskIds: [String]) {
    softDeleteTasks(partitionKey: $partitionKey, taskIds: $taskIds) {
      id
      isActive
      status
    }
  }
`;

const serializer = ({ tenantId, taskIds }) => ({
  variables: {
    partitionKey: tenantId,
    taskIds
  }
});

export const useBulkDeleteTasks = () => {
  return useExtendedMutation(BULK_DELETE_TASKS, {
    serializer
  });
};
