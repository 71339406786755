import { times } from 'lodash';
import moment from 'moment';

import { emptyNonVisitDetailsFragment } from '@dispatch/fragments';
import mergeDeep from 'utils/mergeDeep';

export const optimisticRecurringNonVisitEvents = ({
  endDate,
  startDateTime,
  excludeWeekends,
  durationInMin,
  ...params
}) => {
  const end = moment.unix(endDate);
  const start = moment.unix(startDateTime).startOf('day');
  const numberOfEvents = 1 + end.diff(start, 'days');
  return times(numberOfEvents)
    .map(index => moment.unix(startDateTime).add(index, 'day'))
    .filter(date => {
      const weekDay = date.isoWeekday();
      return !excludeWeekends || weekDay < 6;
    })
    .map((date, index) =>
      mergeDeep(emptyNonVisitDetailsFragment, {
        ...params,
        id: `optimistic-non-visit_${index}`,
        plannedStartTimeUTC: date.unix(),
        plannedEndTimeUTC: date.unix() + durationInMin * 60,
        isActive: true
      })
    );
};
