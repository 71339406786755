import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';

import StatusChip from 'components/StatusChip';

const useStyles = makeStyles(() => ({
  errorMessage: {
    display: 'flex',
    alignItems: 'center'
  },
  container: {
    fontSize: '0.9em',
    display: 'flex',
    flexDirection: 'column'
  }
}));

const statusToLabel = {
  SyncFailed: 'Failed',
  InSync: 'Synced',
  Syncing: 'Syncing',
  Bypassed: 'Bypassed',
  NotSynced: 'Not Synced'
};

const SyncStatusChip = props => {
  const { syncStatus, syncLog } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="body2">
        Accounting Software Sync Status:{' '}
        <StatusChip
          enumType="SYNC_STATUS"
          enumValue={syncStatus}
          fontSize="0.9em"
          label={statusToLabel[syncStatus] || syncStatus || statusToLabel.NotSynced}
          style={{
            height: 20,
            lineHeight: '20px',
            borderRadius: 2,
            marginLeft: 6
          }}
        />
      </Typography>
      {syncLog && (
        <div className={classes.errorMessage}>
          <ErrorIcon color="error" fontSize="small" style={{ margin: '0 5px 0 0' }} />
          <Typography color="error" variant="caption">
            <b>Sync Failed:</b> {syncLog}
          </Typography>
        </div>
      )}
    </div>
  );
};

SyncStatusChip.propTypes = {
  syncStatus: PropTypes.string,
  syncLog: PropTypes.string
};

SyncStatusChip.defaultProps = {
  syncStatus: undefined,
  syncLog: undefined
};

export default SyncStatusChip;
