import gql from 'graphql-tag';

import { manDayFragment } from '@dispatch/fragments/manDayFragment';
import useExtendedQuery from 'customHooks/useExtendedQuery';

const GET_MAN_DAY_QUERY = gql`
  query getManDay($id: String!) {
    getManDay(id: $id) {
      ${manDayFragment}
    }
  }
`;

const transform = data => {
  return data?.getManDay || {};
};

const useManDayDetails = id => {
  return useExtendedQuery(GET_MAN_DAY_QUERY, {
    transform,
    variables: {
      id
    }
  });
};

export default useManDayDetails;
