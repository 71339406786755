import React, { useEffect, useState } from 'react';

import { Button, ButtonType, ThemeProvider, Typography } from '@BuildHero/sergeant';
import Chip from '@material-ui/core/Chip';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { array, func, object } from 'prop-types';
import * as R from 'ramda';

import { TimeCardStatusTypes } from 'utils/AppConstants';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import TimesheetCard from '../../../TimesheetCard';

import { useStyles } from './EmployeeTimesheets.styles';

const getTimesheetStatusText = status => {
  switch (status) {
    case TimeCardStatusTypes.DISPUTED:
      return 'Pending Revision';
    case TimeCardStatusTypes.APPROVED:
      return 'Approved';
    case TimeCardStatusTypes.PENDING:
      return 'Unsubmitted';
    case TimeCardStatusTypes.SUBMITTED:
      return 'Submitted';
    default:
      return undefined;
  }
};

const EmployeeTimesheets = ({
  employee,
  employeeTimesheets,
  projectPhases,
  payrollHourTypes,
  onTimesheetChange
}) => {
  const styles = useStyles();

  const flags = useFlags();
  const wrinkleTimeFF = flags[FeatureFlags.WRINKLE_IN_TIME];

  const [timesheetStatus, setTimesheetStatus] = useState(null);
  const [timesheetStatusText, setTimesheetStatusText] = useState('');
  const [isDismissed, setIsDismissed] = useState(false);
  const [timesheets, setTimesheets] = useState([]);

  useEffect(() => {
    if (!employeeTimesheets?.length) {
      return;
    }

    const status = R.find(R.prop('timesheetStatus'), employeeTimesheets)?.timesheetStatus;

    setTimesheets(employeeTimesheets);
    setTimesheetStatus(status);
    setTimesheetStatusText(getTimesheetStatusText(status));
    setIsDismissed(employeeTimesheets.some(x => x.isDismissed));
  }, [employeeTimesheets]);

  const dismissTimesheetBinders = () => {
    setTimesheets(
      timesheets.map(x => ({
        ...x,
        isDismissed: true
      }))
    );
  };

  const statusChip = () => {
    if (isDismissed) {
      return (
        <ThemeProvider>
          <Chip color="secondary" css={styles.approvedLabel} label="DISMISSED" />
        </ThemeProvider>
      );
    }

    if (timesheetStatusText) {
      return (
        <Chip
          color={timesheetStatus === TimeCardStatusTypes.APPROVED ? 'secondary' : 'primary'}
          css={styles.approvedLabel}
          label={timesheetStatusText}
        />
      );
    }

    return null;
  };

  return (
    <div css={styles.root}>
      <div css={styles.header}>
        <div css={styles.headerLeft}>
          <Typography css={styles.employeeName}>
            {employee?.name || ''}
            {statusChip()}
          </Typography>
        </div>
        <div css={styles.headerRight}>
          {wrinkleTimeFF && (
            <ThemeProvider>
              {!isDismissed ? (
                <Button type={ButtonType.SECONDARY} onClick={dismissTimesheetBinders}>
                  Dismiss
                </Button>
              ) : null}
            </ThemeProvider>
          )}
        </div>
      </div>
      {timesheets?.map(employeeTimesheet => (
        <TimesheetCard
          employeeTimesheet={employeeTimesheet}
          isDisabled={isDismissed}
          key={employeeTimesheet.id}
          payrollHourTypes={payrollHourTypes}
          projectPhases={projectPhases}
          variant="standard"
          onChange={onTimesheetChange}
        />
      ))}
    </div>
  );
};

EmployeeTimesheets.propTypes = {
  employee: object.isRequired,
  employeeTimesheets: array.isRequired,
  projectPhases: array.isRequired,
  payrollHourTypes: array.isRequired,
  onTimesheetChange: func.isRequired
};

export default EmployeeTimesheets;
