import React from 'react';

import { Typography } from '@BuildHero/sergeant';
import Tooltip from '@material-ui/core/Tooltip';
import { bool, shape, string } from 'prop-types';
import Skeleton from 'react-loading-skeleton';

import TechStatus from '../TechStatus';

import { useStyles } from './TechCard.styles';

const TechCard = ({ tech, techLoading, showStatus }) => {
  const { profilePictureUrl } = tech;
  const classes = useStyles({ profilePictureUrl });
  return (
    <>
      <div className={classes.techPhoto} />
      <div>
        <Typography className={classes.techName} loading={techLoading} variant="h6" width={100}>
          <Tooltip title={tech.role}>
            <span>{tech.name}</span>
          </Tooltip>
        </Typography>
        {!techLoading && showStatus && <TechStatus techStatus={tech?.status} />}
      </div>
    </>
  );
};

TechCard.propTypes = {
  tech: shape({
    name: string,
    profilePictureUrl: string,
    role: string,
    status: string
  }).isRequired,
  techLoading: bool,
  showStatus: bool
};

TechCard.defaultProps = {
  techLoading: false,
  showStatus: false
};

export default TechCard;
