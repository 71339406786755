import React from 'react';

import { Box } from '@material-ui/core';
import { func, shape } from 'prop-types';

import { useSelector } from 'react-redux';

import AddButton from '@pm/components/AttachmentsV2/components/AddButton';
import Labels from 'meta/labels';

import { useStyles } from './AddAttachmentsFromFilesButton.styles';

const AddAttachmentsFromFilesButton = ({ options: { onClick } }) => {
  const styles = useStyles();
  const user = useSelector(store => store.user);
  return (
    <Box css={styles.container}>
      <AddButton title={Labels.addAttachmentsFromFiles[user.locale]} onClick={onClick} />
    </Box>
  );
};

AddAttachmentsFromFilesButton.propTypes = {
  options: shape({
    onClick: func.isRequired
  }).isRequired
};

export default AddAttachmentsFromFilesButton;
