import React, { useCallback, useState } from 'react';

import { Button, ButtonSize, ButtonType, Modal, SgtForm } from '@BuildHero/sergeant';

import { useConfirmModal } from 'customHooks/ConfirmModalContext';

import IntegrationCard from '../IntegrationCard';

import Icon from './icon.svg';
import { useServiceChannelSetting } from './ServiceChannel.api';
import Configuration from './ServiceChannel.config';

const confirmDisconnectModalContent = {
  body: 'Are you sure you want to disconnect this integration?',
  title: 'Disconnect Integration',
  buttonLabel: 'Disconnect',
  buttonType: ButtonType.ERROR
};

const ServiceChannelIntegration = () => {
  const [open, setOpen] = useState(false);
  const [formService, setFormService] = useState();
  const { data, loading, mutationLoading, upsert } = useServiceChannelSetting();
  const confirmModal = useConfirmModal();

  const handleClick = useCallback(() => {
    formService.formikContext.handleSubmit();
  }, [formService]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSubmit = useCallback(
    payload => {
      upsert(payload);
    },
    [upsert]
  );

  const handleDisconnect = useCallback(async () => {
    if (await confirmModal.confirm(confirmDisconnectModalContent)) {
      // empty the settings to remove all the settings data
      upsert({});
    }
  }, [upsert, confirmModal]);

  return (
    <>
      <IntegrationCard
        buttonProps={
          data.refreshToken // refreshToken only exists if there was successful connection
            ? {
                children: 'Disconnect',
                type: ButtonType.ERROR,
                size: ButtonSize.SMALL,
                onClick: handleDisconnect,
                loading: mutationLoading
              }
            : {
                children: 'Connect',
                type: ButtonType.SECONDARY,
                size: ButtonSize.SMALL,
                onClick: () => setOpen(true)
              }
        }
        description="Automatically create jobs from service channel work orders."
        image={Icon}
        loading={loading}
        subtitle="Service Provider"
        title="Service Channel"
      />
      <Modal
        actions={
          <Button fullWidth loading={mutationLoading} onClick={handleClick}>
            Continue
          </Button>
        }
        fullWidth
        open={open}
        title="Connect To Service Channel"
        onClose={handleClose}
      >
        <SgtForm
          configuration={Configuration}
          formikProps={{ validateOnChange: false, validateOnBlur: true }}
          initialValues={data}
          onCreateService={setFormService}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  );
};

export default ServiceChannelIntegration;
