import React from 'react';

import { Button, ThemeProvider } from '@BuildHero/sergeant';
import { ButtonGroup } from '@material-ui/core';
import { bool, func } from 'prop-types';

const MapViewSelector = ({ mapView, setMapView }) => (
  <ThemeProvider>
    <ButtonGroup>
      <Button type={!mapView ? 'secondary' : 'tertiary'} onClick={() => setMapView(false)}>
        Schedule
      </Button>
      <Button type={mapView ? 'secondary' : 'tertiary'} onClick={() => setMapView(true)}>
        Map
      </Button>
    </ButtonGroup>
  </ThemeProvider>
);

MapViewSelector.propTypes = {
  mapView: bool,
  setMapView: func.isRequired
};

MapViewSelector.defaultProps = {
  mapView: false
};

export default MapViewSelector;
