import React from 'react';

import { oneOf } from 'prop-types';

import { EVENT_NAMES, EVENT_TYPES } from '@dispatch/Dispatch.constants';
import { EventIcons } from '@dispatch/Dispatch.icons';
import { EventColors } from '@dispatch/Dispatch.styles';

import { useStyles } from './EventBadge.styles';

const EventBadge = ({ eventType }) => {
  const text = EVENT_NAMES[eventType];
  const icon = EventIcons[eventType];
  const backgroundColor = EventColors[eventType];
  const classes = useStyles({ backgroundColor });
  return (
    <div className={classes.badge}>
      <img alt={text} src={icon} />
      {text}
    </div>
  );
};

EventBadge.propTypes = {
  eventType: oneOf(Object.values(EVENT_TYPES)).isRequired
};
export default EventBadge;
