import { string } from 'yup';

import { getState } from 'redux/store';
import { AccountingApp, EntityType } from 'utils/constants';

const accountingAppNameCharLimit = {
  [AccountingApp.QUICKBOOKS]: {
    [EntityType.CUSTOMER]: 41,
    [EntityType.CUSTOMER_PROPERTY]: 41,
    [EntityType.VENDOR]: 41,
    [EntityType.PRODUCT]: 31
  }
};

export const nameValidationField = (field, entityType, requiredErrorMessage) => {
  const { app, setting } = getState().settings.accountingAppSettings || {};

  let validation = string()
    .nullable()
    .matches(/^[^:]+$/, 'Name cannot contain :')
    .required(requiredErrorMessage);

  if (!setting?.isQBO) {
    const max = accountingAppNameCharLimit[app]?.[entityType];
    if (max) {
      validation = validation.max(max, `Cannot exceed ${max} characters.`);
    }
  }

  return {
    [field]: validation
  };
};
