export const manDayFragment = `
  id
  startDateTime
  endDateTime
  description
  project {
    id
    name
    number
  }
  technicianIds
  isActive
  status
  timesheetEntryBinders {
    items {
      id
      manualStatus
    }
  }
`;
