import React, { useState } from 'react';

import { Tab, Tabs, ThemeProvider } from '@material-ui/core';

import theme from 'themes/BuildHeroTheme';

import TenantSettingList from './TenantSettingList';
import Users from './Users';

const TabPanel = React.memo(props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      css={{ flex: '1 1 auto' }}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && children}
    </div>
  );
});

const TenantTabs = props => {
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        css={{ marginBottom: 16 }}
        indicatorColor="primary"
        textColor="primary"
        value={value}
        onChange={handleChange}
      >
        <Tab label="Settings" />
        <Tab label="Users" />
      </Tabs>
      <TabPanel index={0} value={value}>
        <TenantSettingList {...props} />
      </TabPanel>
      <TabPanel index={1} value={value}>
        <ThemeProvider theme={theme}>
          <Users auth={props.props.auth} tenantId={props.form.values.id} />
        </ThemeProvider>
      </TabPanel>
    </>
  );
};

export default React.memo(TenantTabs);
