import { useTheme } from '@material-ui/core';

import { DispatchTheme } from '@dispatch/Dispatch.styles';

export const useStyles = ({ active } = {}) => {
  const theme = useTheme();

  return {
    container: {
      height: 'inherit'
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      width: '100%',
      height: '48px',
      borderBottom: DispatchTheme.mixins.mainBorder,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    content: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      height: 'calc(100% - 48px)'
    },
    tabsContainer: {
      display: 'flex',
      whiteSpace: 'nowrap',
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    tabHeader: {
      borderTop: DispatchTheme.mixins.mainBorder,
      borderRight: DispatchTheme.mixins.mainBorder,
      borderLeft: DispatchTheme.mixins.mainBorder,
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      borderWidth: '1px',
      borderBottomStyle: 'none',
      cursor: 'pointer',
      lineHeight: '38px',
      marginRight: '4px',
      outline: 'none',
      textAlign: 'center',
      width: 90,
      backgroundColor: active ? 'white' : theme.palette.grayscale(94),
      color: active ? theme.palette.brand.green : theme.palette.grayscale(50),
      fontWeight: active ? 600 : 400,
      marginBottom: active ? -1 : 0
    }
  };
};
