import React from 'react';

import { TV, TW, Typography } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';
import { Box } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';

import { AuditLogs } from 'components';

const styles = {
  title: {
    color: 'black'
  }
};

const PaymentActivityList = ({ data, loading }) => {
  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Typography css={styles.title} variant={TV.BASE} weight={TW.BOLD}>
        Activity
      </Typography>
      {loading ? (
        <Skeleton count={7} height={100} />
      ) : (
        <AuditLogs auditLogData={data?.auditLogs?.items || []} variant="singleEntity" />
      )}
    </Box>
  );
};

export default PaymentActivityList;
