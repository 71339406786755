import { generateDefaultFieldsObject } from '@pm/components/formattingUtils';
import { customerRepSearch } from 'services/API/customerRep';

const margin = '0px 15px 21px 0px';

const approvalFields = {
  dateApproved: { required: true },
  approvedBy: { required: true },
  amountApproved: { required: true },
  gcChangeOrderNumber: { required: false },
  gcPurchaseOrderNumber: { required: false }
};

const approvalContents = project => [
  {
    options: {
      direction: 'row'
    },
    contents: [
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            component: {
              default: 'DateInput',
              edit: 'DateInput'
            },
            source: 'dateApproved',
            options: {
              label: 'Date Approved',
              variant: 'standard',
              color: 'secondary',
              placeholder: 'Select Date'
            }
          }
        ]
      },
      {
        options: { margin },
        contents: []
      }
    ]
  },
  {
    options: {
      direction: 'row'
    },
    contents: [
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar',
              edit: 'SearchBar'
            },
            source: 'approvedBy',
            options: {
              resultFormatFunction: customerRep => customerRep.name,
              searchFunction: (value, searchCols) =>
                customerRepSearch(value, searchCols, project?.customer?.id || undefined, project),
              sortFunction: (a, b) => (a.name > b.name ? 1 : -1),
              label: 'Approved By',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              placeholder: 'Search contacts'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            component: {
              default: 'CurrencyInput',
              edit: 'CurrencyInput'
            },
            source: 'amountApproved',
            options: {
              label: 'Amount Approved',
              variant: 'standard',
              color: 'secondary',
              type: 'currency',
              disabled: true
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      direction: 'row'
    },
    contents: [
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            component: {
              default: 'CustomInput',
              edit: 'CustomInput'
            },
            source: 'gcChangeOrderNumber',
            options: {
              label: 'GC Change Order Number',
              variant: 'standard',
              color: 'secondary',
              placeholder: ''
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            component: {
              default: 'CustomInput',
              edit: 'CustomInput'
            },
            source: 'gcPurchaseOrderNumber',
            options: {
              label: 'GC Purchase Order Number',
              variant: 'standard',
              color: 'secondary',
              placeholder: ''
            }
          }
        ]
      }
    ]
  }
];

const approveChangeOrderLayout = project => {
  return {
    fields: generateDefaultFieldsObject(approvalFields),
    layouts: {
      default: {
        options: {},
        contents: approvalContents(project)
      },
      edit: {
        options: {},
        contents: approvalContents(project)
      }
    }
  };
};

export default approveChangeOrderLayout;
