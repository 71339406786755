import React, { useCallback, useState } from 'react';

import { Button, ThemeProvider } from '@BuildHero/sergeant';
import { Box, Chip, Grid, IconButton, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import CreateEntryButton from 'components/Buttons/CreateEntryButton';
import Labels from 'meta/labels';
import ErrorBoundaries from 'scenes/Error';

import { getTenantSettingValueForKey, isObject } from 'utils';
import AppConstants from 'utils/AppConstants';

import ColumnsAction from './ColumnsAction';
import Filter, { preprocessFilter } from './Filter';
import {
  frontendToBackendFilter,
  getFilterConditionName,
  getMetadataWithFilterOptions
} from './tableUtils';
import Views from './Views';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    minHeight: 0
  },
  input: {
    margin: theme.spacing(1)
  },
  refreshStart: {
    animation: '$refresh 2s linear infinite'
  },
  refreshStop: {
    'animation-play-state': 'paused'
  },
  '@keyframes refresh': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' }
  },
  toolbarButton: {
    textDecoration: 'none',
    padding: '10px 8px',
    margin: '14px 8px 0',
    fontSize: 12,
    height: 32,
    '&:hover': {
      backgroundColor: theme.palette.grayscale(90)
    }
  },
  toolbarButtonActive: {
    backgroundColor: theme.palette.grayscale(90)
  },
  filterChip: {
    backgroundColor: theme.palette.brand.logoGreen,
    borderRadius: 12,
    color: theme.palette.grayscale(100),
    height: 28,
    letterSpacing: 0.24,
    margin: theme.spacing(0.5),
    paddingRight: 5,
    '&:focus': {
      backgroundColor: theme.palette.brand.logoGreen
    }
  },
  deleteIconColorPrimary: {
    '&:hover': {
      background: '#3fb97c',
      borderRadius: 10,
      color: theme.palette.grayscale(90)
    }
  },
  deleteIcon: {
    color: theme.palette.grayscale(100),
    height: 13,
    width: 13
  },
  activeFilterContainer: {
    marginTop: 12
  }
}));

function ResponsiveTableToolbar(props) {
  const {
    handleExport,
    sortBy,
    sortOrder,
    metadata,
    handleViewChange,
    applyFilter,
    filter,
    disableFilter,
    user,
    enableRefreshBtn,
    refresh,
    shouldUseQueries,
    isRefreshing,
    reorderColumn,
    tableName,
    noOfRows,
    defaultSetting,
    exportButtonTitle,
    fullScreen,
    actionPanel,
    selectedRecords,
    isListDataTable
  } = props;

  const [isExporting, setIsExporting] = useState(false);
  const classes = useStyles();

  const createFilterLabels = () => {
    if (!filter) return null;
    const filterLabels = [];
    // account for filterOptions key that has nested columns
    const filterMetadata = getMetadataWithFilterOptions(metadata);
    Object.keys(filter).forEach(fieldName => {
      // Find metadata related to the column by which we are filtering
      const fieldMetadata = isListDataTable
        ? filterMetadata.find(meta => meta.id === fieldName)
        : filterMetadata.find(
            meta =>
              meta.filterKey === fieldName ||
              (meta?.convertToSubQuery &&
                meta?.subQueryCondition?.filter?.stringFilters?.[0]?.fieldName === fieldName)
          );
      const field = filter[fieldName];
      if (!fieldMetadata || !field) return;

      fieldMetadata.label =
        (Labels[fieldMetadata.label] || {})[user?.locale] || fieldMetadata.label;

      let conditionName =
        getFilterConditionName(
          field.condition,
          fieldMetadata.filterType,
          field.conditionToTurnIntoText
        ) || '';

      let compareText;
      let fieldType = fieldMetadata.filterType;
      if (fieldMetadata.type === 'dateOnly') {
        fieldType = 'date';
      }
      switch (fieldType) {
        // Date type needs special handling since it is treated as an ordinary number (Unix timestamp) in the backend
        case 'date':
          if (Array.isArray(field.value) && field.value.length === 2) {
            compareText = `${moment
              .unix(field.value[0])
              .format(AppConstants.DATE_FORMAT)} and ${moment
              .unix(field.value[1])
              .format(AppConstants.DATE_FORMAT)}`;
          } else {
            compareText = moment.unix(field.value).format(AppConstants.DATE_FORMAT);
          }
          break;
        case 'distance':
          const [radius, property] = field.value.split('|');
          compareText = _.truncate(`${radius} miles from ${property}`, { length: 50 });
          break;
        case 'boolean':
          if (fieldMetadata.options) {
            const optionItem = fieldMetadata.options.find(item => item.value === field.value);
            conditionName = conditionName += ` ${optionItem.label}`;
          }
          // `conditionName` already encodes all information (e.g. "is yes"); delete trailing space
          compareText = '\b';
          break;
        case 'currency':
          compareText = `$${field.value}`;
          break;
        case 'string':
          compareText = `"${field.value}"`;
          break;
        default:
          compareText = field.value;
          break;
      }

      if (field.condition === 'empty' || field.condition === 'notEmpty') {
        compareText = ``;
      }

      if (_.isFunction(fieldMetadata?.filterValueFormatter)) {
        compareText = fieldMetadata.filterValueFormatter(compareText);
      }

      // Attach type and fieldName so that each filter field can be removed
      // from the overall array just by user click on UI element using this label
      filterLabels.push({
        label: `${fieldMetadata.label} ${conditionName} ${compareText}`,
        type: field.type,
        fieldName
      });
    });
    return filterLabels;
  };
  const filterLabels = createFilterLabels();
  const columns = metadata.filter(row => !row.hide).map(row => row.id);
  const showViews =
    fullScreen && !!tableName && getTenantSettingValueForKey('tableViews') === 'true';
  const showFilters = fullScreen && !disableFilter;

  const removeFilterField = useCallback(
    filterName => {
      // E.g. all fields of type 'integerFilters'
      if (!filter[filterName]) return null;
      const newFilter = _.cloneDeep(filter);
      delete newFilter[filterName];
      return applyFilter(newFilter);
    },
    [filter, applyFilter]
  );

  const viewsWidth = showViews ? 4 : 0;
  const filterWidth = showFilters ? 4 : 0;

  return (
    <ErrorBoundaries>
      <Toolbar className={classes.root}>
        <Grid alignItems="flex-end" container justify="space-between">
          <Grid container item key="options" md={viewsWidth + filterWidth}>
            {showViews && (
              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <Views
                  defaultSetting={defaultSetting}
                  filter={filter}
                  handleViewChange={handleViewChange}
                  metadata={metadata}
                  noOfRows={noOfRows}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  tableName={tableName}
                />
              </Box>
            )}
            {showViews && (
              <Box>
                <ColumnsAction
                  metadata={metadata}
                  reorderColumn={reorderColumn}
                  toolbarClasses={classes}
                  user={user}
                />
              </Box>
            )}
            {showFilters && (
              <Box style={{ display: 'flex' }}>
                <Filter
                  applyFilter={applyFilter}
                  filter={filter}
                  metadata={metadata}
                  toolbarClasses={classes}
                  user={user}
                />
                <Box className={classes.activeFilterContainer}>
                  {filterLabels &&
                    filterLabels.map(field => (
                      <Chip
                        classes={{
                          root: classes.filterChip,
                          deleteIcon: classes.deleteIcon,
                          deleteIconColorPrimary: classes.deleteIconColorPrimary
                        }}
                        color="primary"
                        deleteIcon={<ClearIcon />}
                        key={`filter-chip-${field.label}`}
                        label={field.label.replace(/('|")/g, '')}
                        onDelete={() => removeFilterField(field.fieldName)}
                      />
                    ))}
                </Box>
              </Box>
            )}
          </Grid>
          <Grid
            container
            item
            justify="flex-end"
            key="refresh-grid"
            md={12 - filterWidth - viewsWidth}
          >
            {props.addRow && (
              <>
                {props.addRow.isRefreshing && (
                  <IconButton
                    className={clsx(classes.refreshStart, {
                      [classes.refreshStop]: false
                    })}
                    key="AutorenewAddRowBtn"
                  >
                    <AutorenewIcon color="primary" />
                  </IconButton>
                )}
                <CreateEntryButton {...props.addRow} disabled={props.addRow.isRefreshing} />
              </>
            )}
            {enableRefreshBtn && shouldUseQueries && (
              <IconButton
                className={clsx(classes.refreshStart, {
                  [classes.refreshStop]: !isRefreshing
                })}
                key="AutorenewBtn"
                onClick={() => refresh()}
              >
                <AutorenewIcon color="primary" />
              </IconButton>
            )}
            {handleExport && (
              <ThemeProvider>
                <Button
                  loading={isExporting}
                  size="medium"
                  type="secondary"
                  onClick={async () => {
                    setIsExporting(true);

                    const processedFilter = Object.keys(filter).reduce(
                      (processedFilterConditions, filterKey) =>
                        preprocessFilter(
                          filter[filterKey].value,
                          filter[filterKey].type,
                          filterKey,
                          {
                            [filterKey]: filter[filterKey],
                            ...processedFilterConditions
                          },
                          () => {},
                          metadata
                        ),
                      {}
                    );

                    const backendFilter = frontendToBackendFilter(processedFilter);

                    await handleExport(columns, backendFilter, sortBy, sortOrder, noOfRows);
                    setIsExporting(false);
                  }}
                >
                  {exportButtonTitle}
                </Button>
              </ThemeProvider>
            )}
            {actionPanel(selectedRecords)}
          </Grid>
        </Grid>
      </Toolbar>
    </ErrorBoundaries>
  );
}

ResponsiveTableToolbar.propTypes = {
  /**
   * @async @func handleExport
   * @param {Array.<string>} columns - table column names in sorted order to
   *    include in export
   * @param {GenericFilterInput} filters - check index.js
   * @param {string} sortBy - backend column name to sort on
   * @param {string} sortOrder - "asc" or "desc"
   */
  handleExport: PropTypes.func,
  // backend table column to sort on.
  // sortBy and sortOrder only used if handleExport
  sortBy: PropTypes.string,
  // "asc" or "desc"
  sortOrder: PropTypes.string,
  // export button name - only used if handleExport
  exportButtonTitle: PropTypes.string.isRequired,
  actionPanel: PropTypes.func,
  // if select is enabled via rowActionButtons.select = true, this will be the
  // selected items
  selectedRecords: PropTypes.arrayOf(PropTypes.object)
};

ResponsiveTableToolbar.defaultProps = {
  handleExport: undefined,
  sortBy: undefined,
  sortOrder: undefined,
  actionPanel: () => null,
  selectedRecords: []
};

export default ResponsiveTableToolbar;
