import { TV, TW } from '@BuildHero/sergeant';

const EmailInfoStepFields = {
  recipients: { required: true },
  ccRecipients: { required: false },
  bccRecipients: { required: false },
  selectedTemplate: { required: false },
  subject: { required: false },
  body: { required: true }
};

const marginBottom = {
  marginBottom: 16
};

export const getEmailInfoConfiguration = props => {
  const { recipientOptions, templateOptions, onSelectTemplate, onCreateRecipient } = props;
  const sharedRecipientProps = {
    multi: true,
    searchable: true,
    creatable: true,
    onCreate: onCreateRecipient,
    options: recipientOptions
  };
  return {
    layouts: {
      default: {
        props: {
          style: {
            width: 688
          }
        },
        contents: [
          {
            props: {
              style: {}
            },
            contents: [
              {
                component: 'Typography',
                props: {
                  style: { marginBottom: 4 },
                  variant: TV.L,
                  weight: TW.BOLD,
                  value: 'Edit Invoice Email'
                }
              },
              {
                component: 'Typography',
                props: {
                  variant: TV.BASE,
                  value: 'Select the email recipients and craft the message you want to send.'
                }
              }
            ]
          },
          {
            component: 'Divider'
          },
          {
            props: {},
            contents: [
              {
                component: 'Select',
                props: {
                  ...sharedRecipientProps,
                  label: 'EMAIL TO',
                  style: marginBottom,
                  placeholder: 'Select recipients...',
                  options: recipientOptions
                },
                source: 'recipients'
              },
              {
                component: 'Select',
                props: {
                  ...sharedRecipientProps,
                  label: 'CC',
                  style: marginBottom,
                  placeholder: 'Select CC recipients...'
                },
                source: 'ccRecipients'
              },
              {
                component: 'Select',
                props: {
                  ...sharedRecipientProps,
                  label: 'BCC',
                  placeholder: 'Select BCC recipients...'
                },
                source: 'bccRecipients'
              }
            ]
          },
          {
            component: 'Divider'
          },
          {
            props: {},
            contents: [
              {
                component: 'Select',
                props: {
                  label: 'Template',
                  style: marginBottom,
                  options: templateOptions,
                  onChange: onSelectTemplate
                },
                source: 'selectedTemplate'
              },
              {
                component: 'Input',
                props: {
                  label: 'Subject',
                  style: marginBottom
                },
                source: 'subject'
              },
              {
                component: 'Input',
                props: {
                  label: 'Message',
                  multiline: true,
                  rows: 8,
                  required: true
                },
                source: 'body'
              }
            ]
          }
        ]
      }
    }
  };
};

export default getEmailInfoConfiguration;
