import React from 'react';

import { ThemeProvider } from '@BuildHero/sergeant';

import WrapTable from 'components/WrapTable';

import { usePayrollCostCodesColumns } from './PayrollCostCodesTable.columns';
import { GROUP_BY_LABOUR_RATE_GROUP_NAME } from './PayrollCostCodesTable.constants';
import usePayrollCostCodesTableModal from './usePayrollCostCodesTableModal';

const PayrollCostCodesTable = ({
  tableLength,
  loading,
  payrollHourTypes,
  formik,
  costCodeOptions,
  jobCostTypeOptions
}) => {
  const { PayrollCostCodesTableModal, openCostCodesTableModal } = usePayrollCostCodesTableModal({
    costCodeOptions,
    jobCostTypeOptions,
    payrollCostCodesTableFormik: formik
  });

  return (
    <ThemeProvider>
      <WrapTable
        columns={usePayrollCostCodesColumns({
          payrollHourTypes,
          loading,
          openModal: openCostCodesTableModal
        })}
        hideFooter={tableLength <= 10}
        loading={loading}
        noWrap
        rowGroupingModel={GROUP_BY_LABOUR_RATE_GROUP_NAME}
        rows={formik.values}
      />
      {PayrollCostCodesTableModal}
    </ThemeProvider>
  );
};

export default PayrollCostCodesTable;
