import React, { useCallback } from 'react';

import { makeStyles, TextField, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  labelContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  }
}));

const CustomInput = ({
  field,
  form,
  options: { type, lines, variant, label, isRequired, isOptional }
}) => {
  const classes = useStyles();
  const onFieldChange = useCallback(
    event => {
      const currentValue = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
      form.setFieldValue(field.name, currentValue);
    },
    [form]
  );

  let fieldType;
  if (type && type === 'numeric') {
    fieldType = 'number';
  }
  const error = form.errors && form.errors[field.name];
  return (
    <>
      <div className={classes.labelContainer}>
        {label && (
          <Typography className={classes.label} gutterBottom variant="caption">
            {label}
          </Typography>
        )}
        {isRequired && (
          <Typography className={classes.requiredLabel} gutterBottom variant="caption">
            REQUIRED
          </Typography>
        )}
        {isOptional && (
          <Typography className={classes.requiredLabel} gutterBottom variant="caption">
            OPTIONAL
          </Typography>
        )}
      </div>
      <TextField
        color="secondary"
        error={error && error !== ''}
        multiline={lines && lines > 1}
        name={field.name}
        rows={lines || 1}
        type={fieldType}
        value={field.value === null ? '' : field.value}
        variant={variant || 'outlined'}
        onChange={onFieldChange}
      />
    </>
  );
};

export default CustomInput;
