import { useSubscription } from '@apollo/client';
import gql from 'graphql-tag';

import { updateWatchedVisitQueries } from '@dispatch/Dispatch.utils';
import { visitDetailsFragment } from '@dispatch/fragments';
import { quietRefreshTableData } from 'redux/actions/dispatchActions';
import { dispatch, getState } from 'redux/store';

export const VISIT_SUBSCRIPTION = gql`
  subscription visitUpdateNotification($partitionKey: String!) {
    visitUpdateNotification(partitionKey: $partitionKey) {
      ${visitDetailsFragment}
    }
  }
`;

export const useVisitSubscription = () => {
  const { tenantId } = getState().user;

  return useSubscription(VISIT_SUBSCRIPTION, {
    variables: { partitionKey: tenantId },
    onSubscriptionData: ({ client, subscriptionData }) => {
      updateWatchedVisitQueries({
        updatedVisit: subscriptionData.data.visitUpdateNotification,
        cache: client.cache
      });
      dispatch(quietRefreshTableData());
    }
  });
};
