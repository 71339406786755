import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

const styles = {
  tooltipContents: {
    alignItems: 'center',
    width: '100%',
    margin: '1em'
  }
};

const CustomComponent = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <div {...props} ref={ref}>
      {children}
    </div>
  );
});

const ToolTipBody = ({ toolTipContents }) => (
  <div css={styles.tooltipContents}>{toolTipContents}</div>
);

const CustomTooltip = ({ toolTipContents, children, ...props }) => (
  <Tooltip {...props} title={<ToolTipBody toolTipContents={toolTipContents} />}>
    <CustomComponent>{children}</CustomComponent>
  </Tooltip>
);

export default CustomTooltip;
