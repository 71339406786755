import gql from 'graphql-tag';

export const saveFormsToPropertyAsset = gql`
  mutation saveFormsToPropertyAsset($tenantId: String, $items: [AddFormsToPropertyAssetInput]) {
    saveFormsToPropertyAsset(tenantId: $tenantId, items: $items) {
      id
      formId
      form {
        id
        name
        description
        latestPublishedFormDefinition {
          id
          formDefinitionJson
        }
      }
      propertyAssetId
    }
  }
`;

export const removeFormFromPropertyAsset = gql`
  mutation removeFormFromPropertyAsset($formId: String!, $propertyAssetId: String!) {
    removeFormFromPropertyAsset(formId: $formId, propertyAssetId: $propertyAssetId) {
      id
      form {
        id
      }
    }
  }
`;

export const getPropertyAssetById = gql`
  query getPropertyAssetById($id: String!) {
    getPropertyAssetById(id: $id) {
      id
      version
      parentId
      maintenanceTaskRequiredParts {
        items {
          id
          quantity
          productId
          productDescription
          product {
            id
            name
            description
            code
          }
          priceBookEntryDescription
          pricebookEntryId
          pricebookEntry {
            id
            product {
              name
              description
              code
            }
          }
        }
      }
      assetName
      assetTypeId
    }
  }
`;

export const updatePropertyAsset = gql`
  mutation updatePropertyAsset($partitionKey: String, $data: UpdatePropertyAssetInput!) {
    updatePropertyAsset(partitionKey: $partitionKey, data: $data) {
      id
      maintenanceTaskRequiredParts {
        items {
          id
          quantity
          productId
          productDescription
          product {
            id
            name
            description
            code
          }
          priceBookEntryDescription
          pricebookEntryId
          pricebookEntry {
            id
            product {
              name
              description
              code
            }
          }
        }
      }
    }
  }
`;

export const addPropertyAssetsToCustomerProperty = gql`
  mutation addPropertyAssetsToCustomerProperty(
    $partitionKey: String
    $data: AddPropertyAssetsToCustomerPropertyInput!
  ) {
    addPropertyAssetsToCustomerProperty(partitionKey: $partitionKey, data: $data) {
      id
      assetName
      assetTypeId
      assetType {
        id
        tagName
      }
      isActive
      make
      serialNo
      modelNumber
      imageUrl
    }
  }
`;

export const deletePropertyAssetById = gql`
  mutation deletePropertyAssetById($partitionKey: String!, $id: String!) {
    deletePropertyAssetById(partitionKey: $partitionKey, id: $id) {
      id
    }
  }
`;

export const getAssetsByCustomerPropertyById = gql`
  query getAssetsByCustomerPropertyById($id: String!) {
    getCustomerPropertyById(id: $id) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      parentId
      parentSortKey
      propertyAssets(entityConnection: "PropertyAsset") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          assetName
          isActive
          imageUrl
          make
          version
          modelNumber
          serialNo
          installDate
          location
          propertyZone
          lastUpdatedDateTime
          ownerIdentifier
          internalIdentifier
          assetTypeId
          assetType(entityConnection: "AssetType") {
            id
            tagName
          }
          serviceAgreements {
            id
            agreementName
          }
        }
      }
    }
  }
`;

export const getPropertyAssetByIdWithAssociatedTasks = gql`
  query getPropertyAssetById($id: String!) {
    getPropertyAssetById(id: $id) {
      id
      serviceAgreements {
        id
        agreementName
        status
      }
      jobs(filter: { status: { in: ["In Progress"] } }) {
        id
        jobTypeInternal
        jobNumber
        status
      }
      quotes {
        id
        quoteNumber
        status
      }
    }
  }
`;
