import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { connectRefinementList } from 'react-instantsearch-dom';

const RefinementList = ({ list, onRefinementHandler }) => {
  const Refinement = refinementOption => {
    const { attribute: refineAttribute } = refinementOption;
    const attributeProperty = { name: refineAttribute, ...refinementOption };
    const attribute = { [refineAttribute]: attributeProperty };

    if (onRefinementHandler) onRefinementHandler(attribute);
    return null;
  };

  const CustomRefinementList = connectRefinementList(Refinement);

  const renderList = option => <CustomRefinementList operator="or" showMore {...option} />;
  return <>{list.map(renderList)}</>;
};

export default RefinementList;

RefinementList.propTypes = {
  list: PropTypes.array,
  onRefinementHandler: PropTypes.func
};

RefinementList.defaultProps = {
  list: []
};
