import React from 'react';

import MomentUtils from '@date-io/moment';
import Box from '@material-ui/core/Box';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CX from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import useStyles from './TimeInput.styles';

const TimeInput = ({
  mask,
  format,
  value,
  onChange,
  isOverlap,
  startAdornment,
  InputProps,
  TimePickerProps,
  classNames,
  hasEditPermission,
  inputClassNames
}) => {
  const classes = useStyles();
  const disabledStyle = { [classes.disabled]: !hasEditPermission };

  return (
    <Box
      alignItems="center"
      className={CX(classes.time, disabledStyle, { [classes.overlap]: isOverlap }, classNames)}
      display="flex"
      justifyContent="center"
    >
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <KeyboardTimePicker
          className={CX(classes.timeInput)}
          disabled={!hasEditPermission}
          format={format}
          InputProps={{
            classes: {
              root: CX(classes.timeInputRoot, disabledStyle, inputClassNames),
              focused: classes.timeInputFocused
            },
            startAdornment,
            disableUnderline: true,
            ...InputProps
          }}
          mask={mask}
          value={value}
          onChange={onChange}
          {...TimePickerProps}
        />
      </MuiPickersUtilsProvider>
    </Box>
  );
};

TimeInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  classNames: PropTypes.object,
  hasEditPermission: PropTypes.bool,
  isOverlap: PropTypes.bool,
  inputClassNames: PropTypes.object,
  mask: PropTypes.string,
  format: PropTypes.string,
  InputProps: PropTypes.object,
  TimePickerProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  startAdornment: PropTypes.element
};

TimeInput.defaultProps = {
  value: null,
  hasEditPermission: true,
  isOverlap: false,
  classNames: {},
  inputClassNames: {},
  TimePickerProps: {},
  InputProps: {},
  mask: '__:__ _M',
  format: 'hh:mm A'
};

export default TimeInput;
