import React from 'react';

import { ButtonType } from '@BuildHero/sergeant';
import { Box, Typography } from '@material-ui/core';

import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';

import { StatusChip } from 'components';
import { QuoteStatus } from 'utils/AppConstants';
import { InvoiceStatusColor } from 'utils/constants';

const invoiceLinkItems = invoices => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: 5
      }}
    >
      {invoices?.map(invoice => (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <a href={`/invoice/view/${invoice.id}`}>{`Invoice ${invoice.invoiceNumber}`}</a>
          <StatusChip
            backgroundColor={InvoiceStatusColor[invoice.status]}
            label={invoice.status}
            style={{ borderRadius: 2, margin: 5 }}
          />
        </Box>
      ))}
    </Box>
  );
};

const dissociateQuoteConfirmConfig = (quote, job) => ({
  body: (
    <>
      <Typography>
        Are you sure you want to remove this quote from the job? The following items will be
        recalculated or removed from the job:
      </Typography>
      {[QuoteStatus.APPROVED, QuoteStatus.JOB_ADDED].includes(quote?.status) ? (
        <ul>
          <li>Quote status on job</li>
          <li>Budgeted hours</li>
          <li>Budgeted costs</li>
          <li>Budgeted revenue</li>
          <li>Tasks and included labor and materials</li>
        </ul>
      ) : (
        <ul>
          <li>Quote status on job</li>
        </ul>
      )}
    </>
  ),
  title: `Remove Quote from Job ${job?.customIdentifier || job?.jobNumber}`,
  buttonLabel: 'YES, REMOVE',
  buttonType: ButtonType.PRIMARY,
  cancelButton: true,
  cancelButtonLabel: 'Cancel',
  cancelButtonType: ButtonType.TERTIARY
});

const invoiceStatusWarningConfig = (invoices, palette) => ({
  body: (
    <>
      <Typography>
        Please visit the invoice page, void the invoice &amp; return to this step to dissociate the
        quote from this job.
      </Typography>
      {invoiceLinkItems(invoices)}
    </>
  ),
  title: (
    <>
      <WarningIcon style={{ marginRight: 10, color: palette.support.yellow.dark }} />
      Invoice Drafted or Posted - Unable to Dissociate
    </>
  ),
  buttonLabel: 'Ok',
  buttonType: ButtonType.SECONDARY,
  cancelButton: true,
  cancelButtonLabel: 'Cancel',
  cancelButtonType: ButtonType.TERTIARY
});

const invoiceStatusErrorConfig = (invoices, palette) => ({
  body: (
    <>
      <Typography style={{ marginBottom: 5 }}>
        Due to an exported or closed invoice for this quote, it cannot be dissociated from the job.
      </Typography>
      {invoiceLinkItems(invoices)}
    </>
  ),
  title: (
    <>
      <ErrorIcon style={{ marginRight: 10, color: palette.support.red.dark }} />
      Error: Invoice Exported or Closed - Unable to Dissociate
    </>
  ),
  buttonLabel: 'Ok',
  buttonType: ButtonType.SECONDARY,
  cancelButton: false
});

export { dissociateQuoteConfirmConfig, invoiceStatusWarningConfig, invoiceStatusErrorConfig };
