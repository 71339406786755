import React from 'react';

import { Field, Input, ThemeProvider } from '@BuildHero/sergeant';
import { Box, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import useStyles from './styles';

const List = ({ field, form, options }) => {
  const { setFieldValue } = form;
  const { showAddButton, allowEdit = false, onChange } = options;
  const classes = useStyles();
  const partsAndMaterials = field?.value?.items || [];
  const handleEdit = (value, index) => {
    partsAndMaterials[index] = value;
    if (onChange) {
      onChange({ items: partsAndMaterials });
    }
    setFieldValue('items', partsAndMaterials);
  };

  const handleDelete = index => {
    partsAndMaterials.splice(index, 1);
    if (onChange) {
      onChange({ items: partsAndMaterials });
    }
    setFieldValue('items', partsAndMaterials);
  };

  return (
    <Box display="flex" flexDirection="column" py={2}>
      {partsAndMaterials.map((item, i) => {
        return (
          <ThemeProvider>
            <Box display="flex" key={item.id} py={2}>
              <Field
                label="Item"
                style={{ width: 250 }}
                value={
                  item.pricebookEntry?.product?.name ||
                  item.priceBookEntryName ||
                  item.product?.name ||
                  item.name
                }
              />
              <Input
                defaultValue={
                  item.priceBookEntryDescription ||
                  item.pricebookEntry?.product?.description ||
                  item.product?.description ||
                  item.description
                }
                disabled={!showAddButton && !allowEdit}
                label="Description"
                multiline
                style={{ marginLeft: 16, width: 350 }}
                onChange={e =>
                  handleEdit(
                    {
                      ...item,
                      ...(item.priceBookEntryDescription
                        ? { priceBookEntryDescription: e.target.value }
                        : { description: e.target.value })
                    },
                    i
                  )
                }
              />
              <Input
                disabled={!showAddButton && !allowEdit}
                label="Quantity"
                style={{ marginLeft: 16, width: 150 }}
                value={item.quantity || 0}
                onChange={e => handleEdit({ ...item, quantity: Number(e.target.value || 0) }, i)}
              />
              {showAddButton && (
                <IconButton
                  aria-label="Delete"
                  className={classes.deleteIcon}
                  key={`iconDeleteButton${item.id}`}
                  onClick={() => handleDelete(i)}
                >
                  <DeleteIcon className={classes.iconStyle} key={`deleteIconButton${item.id}`} />
                </IconButton>
              )}
            </Box>
          </ThemeProvider>
        );
      })}
    </Box>
  );
};

export default List;
