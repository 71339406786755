import { FieldType, TV, TW } from '@BuildHero/sergeant';

import { AccountingApp, ExportStatus, TransactionType } from 'utils/constants';

import { calculateTotals } from './Adjustment.utils';

export const exportConfirmContent = {
  body:
    'This is a one-time transaction. Please make sure your adjustment has been associated with payments or invoices. Once synced, the transaction will be closed',
  title: 'Save & Export',
  buttonLabel: 'Save & Export'
};

const rowStyle = {
  gap: 16,
  flexDirection: 'row',
  marginBottom: 16
};
const colStyle = {
  width: 200
};

export const GetEditConfiguration = ({
  adjustmentTypeOptions,
  departmentOptions,
  priceBookOptions,
  accountingApp
}) => ({
  layouts: {
    default: {
      props: {
        style: { paddingBottom: 64, width: 1200, minWidth: 880, margin: 'auto' }
      },
      contents: [
        {
          props: { style: rowStyle },
          contents: [
            {
              component: 'Typography',
              props: {
                testingid: 'generalInformation-adjustments',
                variant: TV.L,
                weight: TW.BOLD,
                value: 'General Information'
              }
            }
          ]
        },
        {
          // row 1
          props: { style: rowStyle },
          contents: [
            {
              component: 'Input',
              source: 'number',
              props: {
                inputProps: {
                  testingid: 'input-adjustmentNumber'
                },
                style: colStyle,
                label: 'Adjustment Number',
                required: true
              }
            }
          ]
        },
        {
          // row 2
          props: { style: rowStyle },
          contents: [
            {
              component: 'CustomerSearch',
              source: 'billingCustomer.customerName',
              resolveProps: ({ applicationMapRef, property }) => {
                const hasProperty = property?.companyName;
                const hasTransactions =
                  applicationMapRef?.current &&
                  Object.values(applicationMapRef?.current).some(a => a.appliedAmount > 0);
                const tooltip =
                  (hasProperty && "Can't change billing customer when property is selected") ||
                  (hasTransactions && "Can't change billing customer when applied to transactions");
                return {
                  disabled: !!tooltip,
                  tooltip
                };
              },
              props: {
                testingid: 'search-billToCustomerSearch-adjustment',
                style: { width: 416 },
                label: 'Billing Customer',
                required: true
              }
            },
            {
              component: 'PropertySearch',
              source: 'property.companyName',
              resolveProps: ({ applicationMapRef, billingCustomer, transactionType }) => {
                const noBillingCustomer = !billingCustomer?.customerName;
                const hasTransactions =
                  applicationMapRef?.current &&
                  Object.values(applicationMapRef?.current).some(a => a.appliedAmount > 0);
                const error =
                  (noBillingCustomer && 'Select a billing customer first to select a property') ||
                  (hasTransactions && "Can't change property when applied to transactions");
                return {
                  hide: !transactionType || transactionType === TransactionType.OVERPAYMENT,
                  filters:
                    !noBillingCustomer &&
                    `entityType:CustomerProperty AND status:active AND customerName:"${billingCustomer?.customerName}"`,
                  disabled: !!error,
                  tooltip:
                    error ||
                    'Leave empty if applying the adjustment to an invoice created at the customer level'
                };
              },
              props: {
                testingid: 'propertySearch-adjustment',
                style: colStyle,
                label: 'Property'
              }
            }
          ]
        },
        {
          // row 3
          props: { style: rowStyle },
          contents: [
            {
              component: 'Select',
              source: 'department',
              props: {
                testingid: 'department-adjustment',
                style: colStyle,
                label: 'Department',
                options: departmentOptions,
                required: true,
                searchable: true,
                clearable: true
              }
            },
            accountingApp !== AccountingApp.INTACCT && {
              component: 'Select',
              source: 'priceBook',
              resolveProps: ({ transactionType, adjustmentItems }) => {
                const disabled = adjustmentItems.length > 0;
                return {
                  hide: !transactionType || transactionType === TransactionType.OVERPAYMENT,
                  disabled,
                  tooltip: disabled && 'Please remove all items to change the price book'
                };
              },
              props: {
                testingid: 'priceBook-adjustments',
                style: colStyle,
                label: 'Price Book',
                options: priceBookOptions,
                required: true,
                searchable: true
              }
            },
            accountingApp !== AccountingApp.INTACCT && {
              component: 'Checkbox',
              source: 'creditCardTransaction',
              resolveProps: ({ transactionType }) => ({
                hide: !transactionType || transactionType === TransactionType.WRITE_OFF
              }),
              props: {
                testingid: 'checkbox-creditcard-transaction-adjustment',
                style: colStyle,
                label: 'Credit Card Transaction',
                tooltip:
                  'Indicates whether the adjustment is for a credit card transaction. Otherwise, adjustment will be treated as a check refund'
              }
            }
          ]
        },
        {
          // row 4
          props: { style: rowStyle },
          contents: [
            {
              component: 'Select',
              source: 'adjustmentType',
              resolveProps: ({ applicationMapRef, exportStatus }) => {
                // using applicationMapRef instead of appliedAmount to get latest data
                const hasApplications =
                  applicationMapRef?.current &&
                  Object.values(applicationMapRef?.current).some(a => a.appliedAmount > 0);
                const isPosted = [ExportStatus.EXPORTED, ExportStatus.POSTED].includes(
                  exportStatus
                );
                return {
                  disabled: isPosted || hasApplications,
                  tooltip:
                    (isPosted && "Can't change adjustment type when posted") ||
                    (hasApplications && "Can't change adjustment type when applied to transactions")
                };
              },
              props: {
                testingid: 'adjustmentType-adjustment',
                style: colStyle,
                label: 'Adjustment Type',
                options: adjustmentTypeOptions,
                onChange: (_, { label, value, type, ledgerAccount, ledgerOffsetAccount }, form) =>
                  form.setValues(prev => ({
                    ...prev,
                    ...(prev.transactionType === TransactionType.OVERPAYMENT &&
                      [TransactionType.WRITE_OFF, TransactionType.REFUND].includes(type) &&
                      calculateTotals(prev.adjustmentItems, prev.taxRate?.taxRate)),
                    adjustmentType: { label, value },
                    transactionType: type,
                    ledgerAccount,
                    ledgerOffsetAccount
                  })),
                required: true,
                searchable: true
              }
            },
            {
              component: 'Field',
              source: 'transactionType',
              props: {
                testingid: 'transactionType-adjustment',
                style: colStyle,
                label: 'Transaction Type'
              }
            },
            {
              component: 'Field',
              source: 'ledgerAccount.label',
              resolveProps:
                accountingApp === AccountingApp.INTACCT
                  ? ({ transactionType }) => ({
                      hide: !transactionType || transactionType !== TransactionType.OVERPAYMENT
                    })
                  : undefined,
              props: {
                testingid: 'GL-Account-adjustment',
                style: colStyle,
                label: 'GL Account'
              }
            },
            accountingApp !== AccountingApp.INTACCT && {
              component: 'Field',
              source: 'ledgerOffsetAccount.label',
              resolveProps: ({ transactionType }) => ({
                hide: !transactionType || transactionType === TransactionType.WRITE_OFF
              }),
              props: {
                testingid: 'GL-Offset-Account-adjustment',
                style: colStyle,
                label: 'GL Offset Account'
              }
            }
          ]
        },
        {
          // row 5
          props: { style: rowStyle },
          contents: [
            {
              component: 'InputOrField',
              source: 'amount',
              props: {
                testingid: 'currency-amount-adjustment',
                style: colStyle,
                label: 'Amount',
                type: FieldType.CURRENCY,
                localeStringOptions: {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                },
                required: true,
                slowField: true
              }
            },
            {
              component: 'Field',
              source: 'availableAmount',
              props: {
                testingid: 'currency-amountAvailable-adjustment',
                style: colStyle,
                label: 'Amount Available',
                type: FieldType.CURRENCY
              }
            }
          ]
        },
        {
          // row 6
          contents: [
            {
              component: 'DateInput',
              source: 'date',
              props: {
                testingid: 'adjustment-date',
                style: colStyle,
                label: 'Date',
                required: true
              }
            }
          ]
        },
        {
          component: 'AdjustmentItem',
          source: 'adjustmentItems',
          resolveProps: ({ transactionType }) => ({
            hide: !transactionType || transactionType === TransactionType.OVERPAYMENT
          })
        },
        {
          component: 'Divider',
          props: {
            margin: 24
          }
        },
        {
          component: 'AdjustmentApplication',
          props: { slowField: true }
        }
      ]
    }
  }
});

export const GetViewConfiguration = accountingApp => ({
  layouts: {
    default: {
      contents: [
        {
          // row 1
          props: { style: rowStyle },
          contents: [
            {
              component: 'Field',
              source: 'billingCustomer.link',
              props: {
                testingid: 'billingCustomer-view-adjustment',
                style: colStyle,
                label: 'Billing Customer',
                type: FieldType.LINK
              }
            },
            {
              component: 'Field',
              source: 'property.link',
              props: {
                testingid: 'property-view-adjustment',
                style: colStyle,
                label: 'Property',
                type: FieldType.LINK
              }
            }
          ]
        },
        {
          // row 2
          props: { style: rowStyle },
          contents: [
            {
              component: 'Field',
              source: 'department.label',
              props: {
                testingid: 'department-view-adjustment',
                style: colStyle,
                label: 'Department'
              }
            },
            accountingApp !== AccountingApp.INTACCT && {
              component: 'Field',
              source: 'priceBook.label',
              resolveProps: ({ transactionType }) => ({
                hide: transactionType === TransactionType.OVERPAYMENT
              }),
              props: {
                testingid: 'pricebook-view-adjustment',
                style: colStyle,
                label: 'Price Book'
              }
            },
            accountingApp !== AccountingApp.INTACCT && {
              component: 'Field',
              source: 'creditCardTransaction',
              resolveProps: ({ transactionType }) => ({
                hide: transactionType === TransactionType.WRITE_OFF
              }),
              props: {
                testingid: 'creditCardTranscation-view-adjustment',
                style: colStyle,
                label: 'Credit Card Transaction',
                tooltip:
                  'Indicates whether the adjustment is for a credit card transaction. Otherwise, adjustment will be treated as a check refund',
                type: FieldType.BOOL
              }
            }
          ]
        },
        {
          // row 3
          props: { style: rowStyle },
          contents: [
            {
              component: 'Field',
              source: 'adjustmentType.label',
              props: {
                testingid: 'adjustmentType-view-adjustment',
                style: colStyle,
                label: 'Adjustment Type'
              }
            },
            {
              component: 'Field',
              source: 'transactionType',
              props: {
                testingid: 'transcationType-view-adjustment',
                style: colStyle,
                label: 'Transaction Type'
              }
            },
            {
              component: 'Field',
              source: 'ledgerAccount.label',
              resolveProps:
                accountingApp === AccountingApp.INTACCT
                  ? ({ transactionType }) => ({
                      hide: transactionType !== TransactionType.OVERPAYMENT
                    })
                  : undefined,
              props: {
                testingid: 'field-GL-Account-view-adjustment',
                style: colStyle,
                label: 'GL Account'
              }
            },
            accountingApp !== AccountingApp.INTACCT && {
              component: 'Field',
              source: 'ledgerOffsetAccount.label',
              resolveProps: ({ transactionType }) => ({
                hide: transactionType === TransactionType.WRITE_OFF
              }),
              props: {
                testingid: 'field-GL-Account-Offset-view-adjustment',
                style: colStyle,
                label: 'GL Offset Account'
              }
            }
          ]
        },
        {
          // row 4
          props: { style: rowStyle },
          contents: [
            {
              component: 'Field',
              source: 'amount',
              props: {
                testingid: 'amount-view-adjustment',
                style: colStyle,
                label: 'Amount',
                type: FieldType.CURRENCY
              }
            },
            {
              component: 'Field',
              source: 'availableAmount',
              props: {
                testingid: 'amountAvailable-view-adjustment',
                style: colStyle,
                label: 'Amount Available',
                type: FieldType.CURRENCY
              }
            }
          ]
        },
        {
          // row 5
          props: { style: rowStyle },
          contents: [
            {
              component: 'Field',
              source: 'date',
              props: {
                testingid: 'date-view-adjustment',
                label: 'Date',
                type: FieldType.DATE
              }
            }
          ]
        },
        {
          // row 6
          contents: [
            {
              component: 'TransactionList',
              source: 'transactions',
              props: {
                testingid: 'transactionList-view-adjustment',
                label: 'Associated Transactions'
              }
            }
          ]
        }
      ]
    }
  }
});
