import { useTheme } from '@emotion/react';

import { DEFAULT_MAX_HEIGHT, ROW_HEIGHT } from '../WrapTable.constants';

export const useTableStyles = ({ scrollX, scrollY, maxHeight, enableTotalsRow }) => {
  const { palette } = useTheme();

  return {
    scrollContainer: {
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      borderRight: `1px solid ${palette.grayscale(90)}`,
      borderLeft: scrollX ? `1px solid ${palette.grayscale(90)}` : undefined,
      borderBottom: scrollY ? `1px solid ${palette.grayscale(90)}` : undefined,
      borderTop: `1px solid ${palette.grayscale(80)}`,
      overflowX: scrollX ? 'auto' : undefined,
      '& [class^="MuiSkeleton-text"]': {
        transform: 'unset',
        borderRadius: 0
      }
    },
    tableBody: {
      overflowY: scrollY ? 'auto' : undefined
    },
    rowContainer: {
      maxHeight: scrollY
        ? maxHeight || DEFAULT_MAX_HEIGHT + (enableTotalsRow ? ROW_HEIGHT + 1 : 0)
        : undefined
    },
    noDataMessage: {
      height: ROW_HEIGHT,
      borderLeft: `1px solid ${palette.grayscale(90)}`,
      borderBottom: `1px solid ${palette.grayscale(90)}`,
      textAlign: 'center',
      padding: '12px 8px'
    }
  };
};
