import React, { useState } from 'react';

import { makeStyles, Tab, Tabs } from '@material-ui/core';

import AddButtonText from 'components/Buttons/AddButtonText';
import { Mode } from 'utils/constants';

import HourInput from '../../HourInput';

import AuditLogEntry from './AuditLogEntry';
import BinderAuditLogEntry from './BinderAuditLogEntry';
import TimesheetBillingEntityDetailsView from './TimesheetBillingEntityDetailsView';
import TimesheetNoteEntry from './TimesheetNoteEntry';

const useStyles = makeStyles(theme => ({
  payrollHourTypes: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  tabs: {
    boxSizing: 'border-box',
    minHeight: 'auto',
    backgroundColor: 'transparent',
    width: '100%'
  },
  indicator: {
    backgroundColor: theme.palette.grayscale(30)
  },
  tab: {
    minHeight: 'auto',
    textTransform: 'none',
    minWidth: 'fit-content',
    backgroundColor: 'transparent',
    color: theme.palette.grayscale(30)
  },
  break: {
    flexBasis: '100%',
    height: 0
  },
  workEventHours: {
    display: 'flex',
    width: '100%'
  },
  miniTabs: {
    width: '100%',
    margin: '24px 0'
  },
  addNoteButton: {
    marginTop: 24,
    marginLeft: 24
  }
}));

const WorkEvent = ({
  workEvent,
  payrollHourTypes,
  updateTimesheetEntry,
  isEditable,
  isIncomplete,
  newAuditLogs,
  payrollSetting,
  setNotesModalData,
  tenantId,
  employeeId,
  areBindersEnabled = false
}) => {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);

  let renderDetailRow;
  let detailItems;
  let noDataMessage;

  switch (tabIndex) {
    case 0:
      detailItems = workEvent.timesheetNotes;
      renderDetailRow = note => (
        <TimesheetNoteEntry
          employeeId={employeeId}
          isEditable={isEditable}
          note={note}
          payrollSetting={payrollSetting}
          setNotesModalData={setNotesModalData}
          tenantId={tenantId}
          workEvent={workEvent}
        />
      );
      noDataMessage = 'No notes found';

      break;
    case 1:
      if (areBindersEnabled) {
        detailItems = workEvent.auditLogs;
        renderDetailRow = auditLog => (
          <BinderAuditLogEntry
            auditLog={auditLog}
            payrollSetting={payrollSetting}
            workEvent={workEvent}
          />
        );
      } else {
        detailItems = newAuditLogs
          .filter(l => l.entry.workEvent.id === workEvent.id)
          .concat(workEvent.auditLogs);
        renderDetailRow = (auditLog, index, previousLog) => (
          <AuditLogEntry
            auditLog={auditLog}
            hourType={auditLog.hourType}
            key={auditLog.id}
            payrollSetting={payrollSetting}
            previousLog={previousLog}
            timesheetEntry={auditLog.entry}
          />
        );
      }
      noDataMessage = 'No activity logs found';
      break;
    default:
      detailItems = [];
      renderDetailRow = () => {};
      noDataMessage = 'No notes found';
  }

  return (
    <div className={classes.payrollHourTypes}>
      <div className={classes.workEventHours}>
        {payrollHourTypes?.map((t, i) => {
          const { hourTypeAbbreviation } = t;
          const showInput = Object.keys(workEvent.timesheetHours).includes(hourTypeAbbreviation);
          return (
            showInput && (
              <HourInput
                hourType={t}
                hourTypeAbbreviation={hourTypeAbbreviation}
                isEditable={isEditable}
                isIncomplete={isIncomplete}
                key={`${workEvent.identifier.id}_${hourTypeAbbreviation}`}
                style={{ marginRight: 24 }}
                updateTimesheetEntry={updateTimesheetEntry}
                workEvent={workEvent}
              />
            )
          );
        })}
      </div>
      <div className={classes.break} />

      <div className={classes.miniTabs}>
        <Tabs
          classes={{
            root: classes.tabs,
            indicator: classes.indicator
          }}
          value={tabIndex}
          onChange={(event, index) => setTabIndex(index)}
        >
          <Tab classes={{ root: classes.tab }} label="Notes" />
          <Tab classes={{ root: classes.tab }} label="Timesheet Activity Logs" />
        </Tabs>
        <div className={classes.break} />
        {isEditable && tabIndex === 0 && (
          <div className={classes.addNoteButton}>
            <AddButtonText
              label="Add Timesheet Note"
              onClick={() => {
                const data = {
                  mode: Mode.ADD,
                  parent: {
                    id: workEvent.id,
                    entityType: workEvent.entityType,
                    tenantId,
                    binderId: workEvent?.binderId
                  },
                  data: {
                    employeeId
                  }
                };
                setNotesModalData(data);
              }}
            />
          </div>
        )}
        <TimesheetBillingEntityDetailsView
          items={detailItems}
          noDataMessage={noDataMessage}
          renderItem={renderDetailRow}
        />
      </div>
    </div>
  );
};

export default WorkEvent;
