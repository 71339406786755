import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import moment from 'moment';

import { MIXPANEL_EVENT, MIXPANEL_PAGE_NAME } from 'constants/mixpanelEvents';
import AmplifyService from 'services/AmplifyService';
import { CommonService, QuoteService } from 'services/core';
import { Logger, sentryException } from 'services/Logger';
import { sendMixpanelEvent } from 'services/mixpanel';
import { isJSONParseableObjectOrArray, logErrorWithCallback } from 'utils';

import { QuoteStatus } from 'utils/AppConstants';

import { GetCompanyQuoteSettings, GetCompanyQuoteTemplate } from '../Settings/Quote/gql';

import PageHeaderUtils from './components/PageHeader/index.utils';
import QuotesUtils from './index.utils';
import { getAttachmentsByQuoteId } from './queries/getAttachmentsByQuoteId';
import getCompanyServiceAgreements from './queries/getCompanyServiceAgreements';
import {
  getCustomFormDataByQuoteId,
  getCustomFormDataByVersionedQuoteId
} from './queries/getCustomFormDataByQuoteId';
import getJobs from './queries/getJobsList';
import getQuoteInfoById from './queries/getQuoteInfoByQuoteId';
import getQuoteVersionInfoById from './queries/getVersionedQuoteById';
import { ACTION } from './reducer';

import { updateVersionDropdown } from './utils/utils.versions';

export const quoteTransitionActions = {
  APPROVE: 'APPROVE',
  UPDATE: 'UPDATE',
  DELETE_LINE_ITEM: 'DELETE_LINE_ITEM',
  REJECT: 'REJECT',
  MARK_AS_SENT: 'MARK_AS_SENT',
  ADD_TO_PROJECT: 'ADD_TO_PROJECT',
  APPROVE_QUOTE_ADDED_FROM_JOB: 'APPROVE_QUOTE_ADDED_FROM_JOB',
  ADD_JOB_FROM_QUOTE: 'ADD_JOB_FROM_QUOTE',
  ADD_TO_JOB: 'ADD_TO_JOB',
  DISSOCIATE_JOB_FROM_QUOTE: 'DISSOCIATE_JOB_FROM_QUOTE'
};

export const dispatchAction = ({ dispatch, quoteState, response }) => {
  dispatch({
    type: ACTION.SET_QUOTE_INFO,
    payload: {
      attachments: quoteState?.quoteAttachments,
      opportunity: quoteState,
      formData: quoteState.customFormdata,
      ...response.data.quoteTransition
    }
  });
};

export const queryAndSetQuoteLineTasks = async (quoteId, snackbar, primaryQuoteId) => {
  const quoteService = new QuoteService();
  let data;
  try {
    if (quoteId === primaryQuoteId) {
      data = await quoteService.getQuoteTasksById(primaryQuoteId);
    } else {
      data = await quoteService.getQuoteTasksById(quoteId);
    }
  } catch (error) {
    Logger.error(error);
    snackbar('error', 'Unable to fetch quote`s task details, please try again later', error);
  }
  return data;
};

export const handleQuoteAttachment = async (values, quoteId, user) => {
  let response;
  if (values.id) {
    response = await new CommonService().updateAttachment(user.tenantId, values);
  } else {
    response = await new QuoteService().addAttachmentsToQuote(user.tenantId, quoteId, values);
  }
  return response;
};

export const getQuoteById = async (quoteId, user, snackbar) => {
  const appsyncClient = await AmplifyService.appSyncClient();
  let response;
  try {
    response = await appsyncClient.client.query({
      query: getQuoteInfoById,
      variables: {
        partitionKey: user.tenantId,
        id: quoteId
      }
    });
  } catch (error) {
    logErrorWithCallback(error, snackbar, 'Unable to fetch quote details');
  }
  return response?.data?.getQuoteById;
};

export const getVersionedQuoteById = async (versionedQuoteId, user, snackbar) => {
  const appsyncClient = await AmplifyService.appSyncClient();
  let response;
  try {
    response = await appsyncClient.client.query({
      query: getQuoteVersionInfoById,
      variables: {
        partitionKey: user.tenantId,
        id: versionedQuoteId
      }
    });
  } catch (error) {
    logErrorWithCallback(error, snackbar, 'Unable to fetch quote details');
  }
  return response?.data?.getVersionedQuoteById;
};

export const getAttachmentsForQuote = async (quoteId, user, snackbar) => {
  if (!quoteId) return { items: [] };
  const appsyncClient = await AmplifyService.appSyncClient();
  let response;
  try {
    response = await appsyncClient.client.query({
      query: getAttachmentsByQuoteId,
      variables: {
        partitionKey: user.tenantId,
        id: quoteId
      }
    });
  } catch (error) {
    logErrorWithCallback(error, snackbar, 'Unable to fetch quote details');
  }

  return response?.data?.getQuoteById;
};

export const getCustomFormDefinitionForQuote = async (user, snackbar) => {
  const companySortKey = `${user.tenantId}_Company_${user.tenantCompanyId}`;
  const commonService = new CommonService();
  try {
    const response = await commonService.getFormsAvailableToEntity(
      user.tenantId,
      companySortKey,
      'Quote'
    );

    const quoteInlineForm = response?.data?.getCompany?.forms?.items?.find(
      form => form.viewType === 'Inline'
    );

    if (quoteInlineForm) {
      return {
        formDefinitionSortKey: quoteInlineForm.latestPublishedFormDefinitionSortKey,
        formSortKey: quoteInlineForm.sortKey,
        formId: quoteInlineForm.id,
        formDefinitionId: quoteInlineForm.latestPublishedFormDefinition?.id,
        name: quoteInlineForm.name,
        description: quoteInlineForm.description,
        formDataJson: JSON.parse(quoteInlineForm.latestPublishedFormDefinition?.formDefinitionJson)
      };
    }
  } catch (error) {
    logErrorWithCallback(error, snackbar, 'Unable to fetch quote defaults');
  }
  return null;
};

export const getCustomFormDataForQuote = async (quoteId, isPrimary, user, snackbar) => {
  if (!quoteId) return { items: [] };
  const appsyncClient = await AmplifyService.appSyncClient();
  let response;
  try {
    response = await appsyncClient.client.query({
      query: isPrimary ? getCustomFormDataByQuoteId : getCustomFormDataByVersionedQuoteId,
      variables: {
        partitionKey: user.tenantId,
        id: quoteId
      }
    });
  } catch (error) {
    logErrorWithCallback(error, snackbar, 'Unable to fetch quote details');
  }
  const nodeName = isPrimary ? 'getQuoteById' : 'getVersionedQuoteById';
  return response?.data?.[nodeName];
};

export const addQuoteMutation = (values, propertyId, user) => {
  return {};
};

export const updateQuoteMutation = async ({
  dispatch,
  propertyId,
  quoteState,
  snackbar,
  tenantId,
  updatedValues,
  setQuoteVersionsOptionData,
  setHasMultipleVersions,
  quoteVersionsOptionData,
  primaryQuoteId,
  successMessage
}) => {
  try {
    const payload = {
      partitionKey: tenantId,
      action: 'UPDATE',
      data: {
        customerPropertyId: propertyId,
        quote: { ...updatedValues }
      }
    };
    const response = await new QuoteService().updateQuoteUsingTransition(payload);
    if (response && response.data && response.data.quoteTransition) {
      dispatchAction({ dispatch, quoteState, response });
      updateVersionDropdown(
        response,
        quoteVersionsOptionData,
        setQuoteVersionsOptionData,
        snackbar,
        setHasMultipleVersions,
        primaryQuoteId,
        tenantId,
        getQuoteById
      );
      snackbar('success', successMessage || 'Successfully updated quote');
      return response?.data?.quoteTransition;
    }
  } catch (error) {
    logErrorWithCallback(error, snackbar, 'Unable to update quote');
  }
};

export const addTaskService = async (
  {
    dispatch,
    newTask,
    propertyId,
    quoteInfo,
    quoteState,
    snackbar,
    // sortOrder,
    tenantId
  },
  skipDispatch
) => {
  const quoteService = new QuoteService();
  try {
    const newTasks = Array.isArray(newTask) ? newTask : null;

    const payload = {
      partitionKey: tenantId,
      action: quoteTransitionActions.UPDATE,
      data: {
        customerPropertyId: propertyId,
        quote: {
          id: quoteInfo.id,
          version: quoteInfo.version,
          quoteLineTasks: newTasks || [
            {
              name: '',
              description: '',
              unitPrice: null,
              unitCost: null,
              taxable: false,
              markupValue: null,
              quantity: null,
              sortOrder: newTask?.sortOrder || 0,
              quoteLineProducts: []
            }
          ]
        }
      }
    };
    const response = await quoteService.updateQuoteUsingTransition(payload);
    if (response?.data) {
      snackbar('success', 'Successfully created task');
      if (skipDispatch) return;
      dispatchAction({ dispatch, quoteState, response });
      return response.data;
    }
  } catch (error) {
    logErrorWithCallback(error, snackbar, 'Unable to create Task');
  }
  return null;
};
export const updateTaskService = async ({
  dispatch,
  taskToUpdate,
  propertyId,
  quoteInfo,
  quoteState,
  snackbar,
  tenantId,
  setQuoteVersionsOptionData,
  setHasMultipleVersions,
  skipDispatch,
  quoteVersionsOptionData,
  primaryQuoteId
}) => {
  const quoteService = new QuoteService();

  try {
    const payload = {
      partitionKey: tenantId,
      action: quoteTransitionActions.UPDATE,
      data: {
        customerPropertyId: propertyId,
        quote: {
          id: quoteInfo.id,
          version: quoteInfo.version,
          quoteLineTasks: [{ ...taskToUpdate }]
        }
      }
    };
    const response = await quoteService.updateQuoteUsingTransition(payload);
    if (response?.data) {
      if (skipDispatch) return response.data;
      dispatchAction({ dispatch, quoteState, response });
      updateVersionDropdown(
        response,
        quoteVersionsOptionData,
        setQuoteVersionsOptionData,
        snackbar,
        setHasMultipleVersions,
        primaryQuoteId,
        tenantId,
        getQuoteById
      );
      snackbar('success', 'Successfully updated task');
      return response.data;
    }
  } catch (error) {
    Logger.error(error);
    snackbar('error', 'Unable to update quote`s task details, please try again later', error);
  }
  return null;
};

// TYPE: DELETING TASK ITEMS
export const deleteTaskService = async ({
  dispatch,
  itemId,
  tenantId,
  propertyId,
  quoteInfo,
  quoteState,
  snackbar,
  type,
  setQuoteVersionsOptionData,
  setHasMultipleVersions,
  quoteVersionsOptionData,
  primaryQuoteId
}) => {
  const quoteService = new QuoteService();
  try {
    const payload = {
      partitionKey: tenantId,
      action: quoteTransitionActions.DELETE_LINE_ITEM,
      data: {
        customerPropertyId: propertyId,
        quote: {
          id: quoteInfo.id,
          version: quoteInfo.version,
          deleteLineItemId: itemId
        }
      }
    };
    const response = await quoteService.updateQuoteUsingTransition(payload);
    if (response?.data) {
      dispatchAction({ dispatch, quoteState, response });
      updateVersionDropdown(
        response,
        quoteVersionsOptionData,
        setQuoteVersionsOptionData,
        snackbar,
        setHasMultipleVersions,
        primaryQuoteId,
        tenantId,
        getQuoteById
      );
      snackbar('success', `Successfully deleted ${type}`);
      return response.data;
    }
  } catch (error) {
    logErrorWithCallback(
      error,
      (msg, errorObj) => snackbar('error', msg, errorObj),
      `Unable to delete ${type}, please try again later`
    );
  }
  return null;
};

export const deleteProductEntry = props =>
  deleteTaskService({ ...props, type: 'part or material' });
export const deleteQuoteLineTask = props => deleteTaskService({ ...props, type: 'task' });
export const deleteLaborLineItem = props =>
  deleteTaskService({ ...props, type: 'labor line item' });
export const deleteTaskGroup = props => deleteTaskService({ ...props, type: 'task group' });

// TYPE: MUTATING PRODUCTS ON A TASK
const taskProductsMutation = async ({
  dispatch,
  products,
  propertyId,
  tenantId,
  snackbar,
  quoteInfo,
  quoteState,
  task,
  setQuoteVersionsOptionData,
  setHasMultipleVersions,
  quoteVersionsOptionData,
  primaryQuoteId
}) => {
  const quoteService = new QuoteService();
  try {
    const payload = {
      partitionKey: tenantId,
      action: quoteTransitionActions.UPDATE,
      data: {
        customerPropertyId: propertyId,
        quote: {
          id: quoteInfo.id,
          version: quoteInfo.version,
          quoteLineTasks: [{ id: task.id, quoteLineProducts: products }]
        }
      }
    };
    const response = await quoteService.updateQuoteUsingTransition(payload);

    if (response?.data) {
      dispatchAction({ dispatch, quoteState, response });
      updateVersionDropdown(
        response,
        quoteVersionsOptionData,
        setQuoteVersionsOptionData,
        snackbar,
        setHasMultipleVersions,
        primaryQuoteId,
        tenantId,
        getQuoteById
      );
      snackbar('success', 'Successfully updated task');
      return response.data;
    }
  } catch (error) {
    Logger.error(error);
    snackbar('error', 'Unable to update quote`s task details, please try again later', error);
  }
};

export const updateQuoteLineProductsOnTask = props => taskProductsMutation({ ...props });
export const addQuoteLineProductsOnTask = props => taskProductsMutation({ ...props });

// TYPE: MUTATING QUOTE LINE ITEM (PRODUCTS/DISCOUNT)
export const addQuoteLineProducts = async ({
  dispatch,
  product,
  propertyId,
  tenantId,
  snackbar,
  quoteInfo,
  quoteState,
  setQuoteVersionsOptionData,
  setHasMultipleVersions,
  quoteVersionsOptionData,
  primaryQuoteId
}) => {
  const quoteService = new QuoteService();
  try {
    const payload = {
      partitionKey: tenantId,
      action: quoteTransitionActions.UPDATE,
      data: {
        customerPropertyId: propertyId,
        quote: {
          id: quoteInfo.id,
          version: quoteInfo.version,
          quoteLineProducts: product
        }
      }
    };
    const response = await quoteService.updateQuoteUsingTransition(payload);
    if (response?.data) {
      dispatchAction({ dispatch, quoteState, response });
      updateVersionDropdown(
        response,
        quoteVersionsOptionData,
        setQuoteVersionsOptionData,
        snackbar,
        setHasMultipleVersions,
        primaryQuoteId,
        tenantId,
        getQuoteById
      );
      snackbar('success', 'Successfully updated task');
      return response.data;
    }
  } catch (error) {
    Logger.error(error);
    snackbar('error', 'Unable to update quote`s task details, please try again later', error);
  }
};

// TYPE: DELETE QUOTE LINE ITEM (PRODUCTS/DISCOUNTS)
export const deleteLineItem = async ({
  dispatch,
  itemId,
  tenantId,
  propertyId,
  quoteInfo,
  quoteState,
  snackbar,
  setQuoteVersionsOptionData,
  setHasMultipleVersions,
  quoteVersionsOptionData,
  primaryQuoteId
}) => {
  const quoteService = new QuoteService();
  try {
    const payload = {
      partitionKey: tenantId,
      action: quoteTransitionActions.DELETE_LINE_ITEM,
      data: {
        customerPropertyId: propertyId,
        quote: {
          id: quoteInfo.id,
          version: quoteInfo.version,
          deleteLineItemId: itemId
        }
      }
    };
    const response = await quoteService.updateQuoteUsingTransition(payload);
    if (response?.data) {
      dispatchAction({ dispatch, quoteState, response });
      updateVersionDropdown(
        response,
        quoteVersionsOptionData,
        setQuoteVersionsOptionData,
        snackbar,
        setHasMultipleVersions,
        primaryQuoteId,
        tenantId,
        getQuoteById
      );
      snackbar('success', `Successfully deleted line item`);
      return response.data;
    }
  } catch (error) {
    Logger.error(error);
    snackbar('error', 'Unable to delete line item, please try again later', error);
  }
  return null;
};

export const refetchQuoteLineTask = () => {};

export const updateLaborLineItems = async ({
  dispatch,
  laborLineItemArr,
  tenantId,
  snackbar,
  propertyId,
  quoteInfo,
  quoteState,
  taskId,
  version,
  setQuoteVersionsOptionData,
  setHasMultipleVersions,
  quoteVersionsOptionData,
  primaryQuoteId
}) => {
  const quoteService = new QuoteService();
  try {
    const payload = {
      partitionKey: tenantId,
      action: quoteTransitionActions.UPDATE,
      data: {
        customerPropertyId: propertyId,
        quote: {
          id: quoteInfo.id,
          version: version || quoteInfo.version,
          quoteLineTasks: [{ id: taskId, quoteLineLabors: laborLineItemArr }]
        }
      }
    };
    const response = await quoteService.updateQuoteUsingTransition(payload);
    if (response?.data) {
      dispatchAction({ dispatch, quoteState, response });
      updateVersionDropdown(
        response,
        quoteVersionsOptionData,
        setQuoteVersionsOptionData,
        snackbar,
        setHasMultipleVersions,
        primaryQuoteId,
        tenantId,
        getQuoteById
      );
      snackbar('success', 'Successfully updated task');
      return response.data;
    }
  } catch (error) {
    Logger.error(error);
    snackbar('error', 'Unable to update quote`s labor line items, please try again later', error);
  }
};

// ADD/UPDATE TASK GROUPS
const taskGroupMutation = async ({
  dispatch,
  groupData,
  tenantId,
  snackbar,
  propertyId,
  quoteInfo,
  quoteState,
  taskIds,
  mode
}) => {
  const quoteService = new QuoteService();
  try {
    const payload = {
      partitionKey: tenantId,
      action: quoteTransitionActions.UPDATE,
      data: {
        customerPropertyId: propertyId,
        quote: {
          id: quoteInfo.id,
          version: quoteInfo.version,
          quoteTaskGroups: Array.isArray(groupData)
            ? groupData
            : [
                {
                  id: groupData.id,
                  name: groupData.name,
                  taskIds,
                  sortOrder: groupData.sortOrder || quoteInfo.quoteTaskGroups.items.length || 0,
                  totalAmountOverride: groupData?.totalAmountOverride
                }
              ]
        }
      }
    };
    const response = await quoteService.updateQuoteUsingTransition(payload);
    if (response && response.data) {
      const msg =
        mode === 'update' ? 'Successfully updated task group' : 'Successfully added a task group';
      snackbar('success', msg);
      dispatchAction({ dispatch, quoteState, response });
      return response.data;
    }
  } catch (error) {
    Logger.error(error);
    snackbar('error', `Unable to ${mode} task group, please try again later`, error);
  }
};

export const addTaskGroup = props => taskGroupMutation({ ...props, mode: 'add' });
export const updateTaskGroup = props => taskGroupMutation({ ...props, mode: 'update' });

export const updateTaxRateOnQuote = async ({
  dispatch,
  propertyId,
  quoteInfo,
  quoteState,
  snackbar,
  tenantId,
  taxRate,
  setQuoteVersionsOptionData,
  setHasMultipleVersions,
  quoteVersionsOptionData,
  primaryQuoteId
}) => {
  const quoteService = new QuoteService();

  try {
    const payload = {
      partitionKey: tenantId,
      action: quoteTransitionActions.UPDATE,
      data: {
        customerPropertyId: propertyId,
        quote: {
          id: quoteInfo.id,
          version: quoteInfo.version,
          taxRateId: taxRate.id,
          taxRateValue: taxRate.value
        }
      }
    };
    const response = await quoteService.updateQuoteUsingTransition(payload);
    if (response?.data) {
      dispatchAction({ dispatch, quoteState, response });
      updateVersionDropdown(
        response,
        quoteVersionsOptionData,
        setQuoteVersionsOptionData,
        snackbar,
        setHasMultipleVersions,
        primaryQuoteId,
        tenantId,
        getQuoteById
      );
      snackbar('success', 'Successfully updated the quote`s tax rate');
      return response.data;
    }
  } catch (error) {
    Logger.error(error);
    snackbar('error', 'Unable to update the quote`s tax rate, please try again later', error);
  }
  return null;
};

export const updateOverrideAmountOnQuote = async ({
  dispatch,
  propertyId,
  quoteInfo,
  quoteState,
  snackbar,
  tenantId,
  overrideData,
  setQuoteVersionsOptionData,
  setHasMultipleVersions,
  quoteVersionsOptionData,
  primaryQuoteId
}) => {
  const quoteService = new QuoteService();

  try {
    const payload = {
      partitionKey: tenantId,
      action: quoteTransitionActions.UPDATE,
      data: {
        customerPropertyId: propertyId,
        quote: {
          id: quoteInfo.id,
          version: quoteInfo.version,
          [overrideData.fieldToUpdate]: overrideData.value
        }
      }
    };
    const response = await quoteService.updateQuoteUsingTransition(payload);
    if (response?.data) {
      dispatchAction({ dispatch, quoteState, response });
      updateVersionDropdown(
        response,
        quoteVersionsOptionData,
        setQuoteVersionsOptionData,
        snackbar,
        setHasMultipleVersions,
        primaryQuoteId,
        tenantId,
        getQuoteById
      );
      snackbar('success', 'Successfully updated the quote`s total amount quoted');
      return response.data;
    }
  } catch (error) {
    Logger.error(error);
    snackbar(
      'error',
      'Unable to update the quote`s total amount quoted, please try again later',
      error
    );
  }
  return null;
};

export const updateConfigSettingsOnQuote = async ({
  dispatch,
  propertyId,
  quoteInfo,
  quoteState,
  snackbar,
  tenantId,
  settings
}) => {
  const quoteService = new QuoteService();

  try {
    const payload = {
      partitionKey: tenantId,
      action: quoteInfo.status !== QuoteStatus.APPROVED && quoteTransitionActions.UPDATE,
      data: {
        customerPropertyId: propertyId,
        quote: {
          id: quoteInfo.id,
          version: quoteInfo.version,
          settingsJSON: JSON.stringify(settings)
        }
      }
    };
    const response = await quoteService.updateQuoteUsingTransition(payload);
    if (response?.data) {
      snackbar('success', 'Successfully updated the quote`s configuration settings');
      dispatchAction({ dispatch, quoteState, response });
      return response.data;
    }
  } catch (error) {
    Logger.error(error);
    snackbar('error', 'Unable to update the quote`s configuration settings', error);
  }
  return null;
};

export const updateQuoteRep = async ({
  dispatch,
  propertyId,
  quoteInfo,
  quoteState,
  snackbar,
  tenantId,
  dataToUpdate
}) => {
  try {
    const payload = {
      partitionKey: tenantId,
      action: 'UPDATE',
      data: {
        customerPropertyId: propertyId,
        quote: {
          id: quoteInfo?.id,
          version: quoteInfo?.version,
          ...dataToUpdate
        }
      }
    };
    const response = await new QuoteService().updateQuoteUsingTransition(payload);
    if (response?.data?.quoteTransition) {
      dispatchAction({ dispatch, quoteState, response });
      snackbar('success', 'Successfully updated quote');
    }
  } catch (error) {
    Logger.error(error);
    snackbar('error', 'Unable update quote', error);
  }
};

export const createQuoteVersion = async ({
  dispatch,
  primaryQuoteId,
  propertyId,
  quoteInfo,
  quoteState,
  snackbar,
  tenantId
}) => {
  try {
    const payload = {
      partitionKey: tenantId,
      action: 'NEW_QUOTE_VERSION',
      data: {
        customerPropertyId: propertyId,
        quote: {
          id: primaryQuoteId || quoteInfo.id,
          version: quoteInfo?.version
        }
      }
    };
    const response = await new QuoteService().updateQuoteUsingTransition(payload);
    if (response?.data?.quoteTransition) {
      dispatchAction({ dispatch, quoteState, response });
      snackbar('success', 'New quote version created successfully');
      return response?.data?.quoteTransition;
    }
  } catch (error) {
    Logger.error(error);
    snackbar('error', 'Unable to create new version of quote, please try again later', error);
  }
};

export const setPrimaryVersionMutation = async ({
  dispatch,
  selectedValue,
  propertyId,
  quoteInfo,
  quoteState,
  snackbar,
  tenantId,
  setQuoteVersionsOptionData,
  setHasMultipleVersions,
  quoteVersionsOptionData,
  primaryQuoteId
}) => {
  try {
    const payload = {
      partitionKey: tenantId,
      action: 'MAKE_PRIMARY',
      data: {
        customerPropertyId: propertyId,
        quote: {
          id: selectedValue,
          version: quoteInfo?.version
        }
      }
    };
    const response = await new QuoteService().updateQuoteUsingTransition(payload);
    if (response?.data?.quoteTransition) {
      dispatchAction({ dispatch, quoteState, response });
      updateVersionDropdown(
        response,
        quoteVersionsOptionData,
        setQuoteVersionsOptionData,
        snackbar,
        setHasMultipleVersions,
        primaryQuoteId,
        tenantId,
        getQuoteById
      );
      snackbar('success', 'Successfully changed Primary version');
    }
  } catch (error) {
    Logger.error(error);
    snackbar('error', 'Unable to make primary version of quote, please try again later', error);
  }
};

export const existingJobMutation = async ({
  dispatch,
  jobId,
  primaryQuoteId,
  propertyId,
  quoteInfo,
  quoteState,
  snackbar,
  tenantId,
  totalBudgetedHours
}) => {
  try {
    const payload = {
      partitionKey: tenantId,
      action: 'ADD_TO_JOB',
      data: {
        customerPropertyId: propertyId,
        jobId,
        quote: {
          id: primaryQuoteId,
          version: quoteInfo?.version,
          totalBudgetedHours
        }
      }
    };
    const response = await new QuoteService().updateQuoteUsingTransition(payload);
    if (response?.data?.quoteTransition) {
      dispatchAction({ dispatch, quoteState, response });
      snackbar('success', 'Quote added to existing job successfully');
      sendMixpanelEvent(MIXPANEL_EVENT.CREATED_JOB_FROM_QUOTE, MIXPANEL_PAGE_NAME.QUOTES);
      return response.data.quoteTransition;
    }
  } catch (error) {
    Logger.error(error);
    snackbar('error', 'Unable to add quote to existing job, please try again later', error);
  }
  return null;
};

export const createJobMutation = async ({
  dispatch,
  jobCustomIdentifier,
  primaryQuoteId,
  propertyId,
  quoteInfo,
  quoteState,
  snackbar,
  tenantId,
  totalBudgetedHours
}) => {
  try {
    const payload = {
      partitionKey: tenantId,
      action: 'ADD_TO_JOB',
      data: {
        customerPropertyId: propertyId,
        jobCustomIdentifier,
        quote: {
          id: primaryQuoteId,
          version: quoteInfo?.version,
          totalBudgetedHours
        }
      }
    };
    const response = await new QuoteService().updateQuoteUsingTransition(payload);
    if (response?.data?.quoteTransition) {
      dispatchAction({ dispatch, quoteState, response });
      snackbar('success', 'Job created successfully');
      sendMixpanelEvent(MIXPANEL_EVENT.CREATED_JOB_FROM_QUOTE, MIXPANEL_PAGE_NAME.QUOTES);
      return response.data.quoteTransition;
    }
  } catch (error) {
    Logger.error(error);
    snackbar('error', 'Unable to create job, please try again later', error);
  }
  return null;
};

/*
  If a quote was created from a job, it will already be associated to a job;  
  After approval we should transition to JobAdded state to allow tasks, hours, etc..
  to be correctly updated on the job.
*/
const transitionQuoteCreatedFromJobToJobAddedState = async (
  quoteInfo,
  tenantId,
  propertyId,
  quoteId
) => {
  const totalBudgetedHours = PageHeaderUtils.calcTotalBudgetedLaborHours(
    quoteInfo.quoteLineTasks,
    true
  );
  const approveAndAddJobPayload = {
    partitionKey: tenantId,
    action: quoteTransitionActions.ADD_TO_JOB,
    data: {
      customerPropertyId: propertyId,
      jobId: quoteInfo?.quoteJobs?.items?.[0]?.job?.id,
      jobExistsOnQuote: true,
      quote: {
        id: quoteId || quoteInfo?.id,
        version: quoteInfo?.version,
        totalBudgetedHours
      }
    }
  };
  const addJobToQuoteResponse = await new QuoteService().updateQuoteUsingTransition(
    approveAndAddJobPayload
  );
  return addJobToQuoteResponse;
};

export const updateQuoteStatusMutation = async ({
  dispatch,
  poNumber,
  propertyId,
  quoteInfo,
  quoteState,
  snackbar,
  status,
  statusReason,
  addedItem,
  projectId,
  tenantId,
  setQuoteVersionsOptionData,
  setHasMultipleVersions,
  quoteVersionsOptionData,
  primaryQuoteId
}) => {
  try {
    if (status === QuoteStatus.APPROVED) {
      const quoteCreatedFromJob = QuotesUtils.quoteHasJob(quoteInfo);
      const payload = {
        partitionKey: tenantId,
        action: quoteTransitionActions.APPROVE,
        data: {
          customerPropertyId: propertyId,
          quote: {
            id: quoteInfo?.id,
            version: quoteInfo?.version,
            status,
            rejectedReason: `Approved Note: ${statusReason}`,
            customerPoNumber: poNumber
          }
        }
      };
      let response = await new QuoteService().updateQuoteUsingTransition(payload);
      if (response.data.quoteTransition) {
        const quoteId = response?.data?.quoteTransition?.id;
        if (quoteCreatedFromJob) {
          response = await transitionQuoteCreatedFromJobToJobAddedState(
            quoteInfo,
            tenantId,
            propertyId,
            quoteId
          );
        }
        if (quoteId) {
          dispatchAction({ dispatch, quoteState, response });
          snackbar('success', 'Quote Approved Successfully');
          updateVersionDropdown(
            response,
            quoteVersionsOptionData,
            setQuoteVersionsOptionData,
            snackbar,
            setHasMultipleVersions,
            primaryQuoteId,
            tenantId,
            getQuoteById
          );
        }
      }
    } else if (status === QuoteStatus.REJECTED) {
      const payload = {
        partitionKey: tenantId,
        action: quoteTransitionActions.REJECT,
        data: {
          customerPropertyId: propertyId,
          quote: {
            id: quoteInfo?.id,
            version: quoteInfo?.version,
            status,
            rejectedReason: `Rejected Note: ${statusReason}`
          }
        }
      };
      const response = await new QuoteService().updateQuoteUsingTransition(payload);
      if (response.data.quoteTransition) {
        dispatchAction({ dispatch, quoteState, response });
        snackbar('success', 'Quote Rejected Successfully');
        updateVersionDropdown(
          response,
          quoteVersionsOptionData,
          setQuoteVersionsOptionData,
          snackbar,
          setHasMultipleVersions,
          primaryQuoteId,
          tenantId,
          getQuoteById
        );
      }
    } else if (status === QuoteStatus.SENT_TO_CUSTOMER) {
      const payload = {
        partitionKey: tenantId,
        action: quoteTransitionActions.MARK_AS_SENT,
        data: {
          customerPropertyId: propertyId,
          quote: {
            id: quoteInfo?.id,
            version: quoteInfo?.version,
            status
          }
        }
      };
      const response = await new QuoteService().updateQuoteUsingTransition(payload);
      if (response.data.quoteTransition) {
        dispatchAction({ dispatch, quoteState, response });
        snackbar('success', 'Quote Marked as Sent Successfully');
        updateVersionDropdown(
          response,
          quoteVersionsOptionData,
          setQuoteVersionsOptionData,
          snackbar,
          setHasMultipleVersions,
          primaryQuoteId,
          tenantId,
          getQuoteById
        );
      }
    } else if (status === QuoteStatus.PROJECT_ADDED) {
      const payload = {
        partitionKey: tenantId,
        action: quoteTransitionActions.ADD_TO_PROJECT,
        data: {
          customerPropertyId: propertyId,
          quote: {
            id: quoteInfo?.id,
            version: quoteInfo?.version,
            status,
            addedItem,
            projectId
          }
        }
      };
      await new QuoteService().updateQuoteUsingTransition(payload);
    }
  } catch (error) {
    Logger.error(error);
    snackbar('error', `Unable to make ${status} quote, please try again later`, error);
  }
};

export const cancelQuoteMutation = async ({
  setConfirmData,
  dispatch,
  primaryQuoteId,
  propertyId,
  quoteInfo,
  quoteState,
  snackbar,
  tenantId
}) => {
  try {
    const payload = {
      partitionKey: tenantId,
      action: 'CANCEL',
      data: {
        customerPropertyId: propertyId,
        quote: {
          id: primaryQuoteId,
          version: quoteInfo?.version
        }
      }
    };
    const response = await new QuoteService().updateQuoteUsingTransition(payload);
    if (response?.data?.quoteTransition) {
      dispatchAction({ dispatch, quoteState, response });
      setConfirmData({ confirmDialog: false, confirmMessage: '', confirmAction: '' });
      snackbar('success', 'Quote cancelled successfully');
    }
  } catch (error) {
    Logger.error(error);
    snackbar('error', 'Unable to cancel quote, please try again later', error);
  }
  return null;
};

export const cloneOrReopenQuoteMutation = async ({
  dispatch,
  history,
  propertyId,
  quoteInfo,
  quoteState,
  snackbar,
  tenantId,
  type
}) => {
  const action = type === 'clone' ? 'CLONE_QUOTE' : 'REOPEN';
  const successType = type === 'clone' ? 'cloned' : 'reopened';

  try {
    const payload = {
      partitionKey: tenantId,
      action,
      data: {
        customerPropertyId: propertyId,
        quote: {
          id: quoteInfo?.id,
          version: quoteInfo?.version
        }
      }
    };
    if (action === 'REOPEN') {
      payload.data.quote.rejectedReason = '';
    }
    const response = await new QuoteService().updateQuoteUsingTransition(payload);
    if (response?.data?.quoteTransition) {
      dispatchAction({ dispatch, quoteState, response });
      const quoteId = response?.data?.quoteTransition?.id;
      if (quoteId) {
        history.push(`/quote/${quoteId}`);
      }
      snackbar('success', `Successfully ${successType} quote`);
    }
  } catch (error) {
    Logger.error(error);
    snackbar('error', `Unable to ${type} quote, please try again later`, error);
  }
};

export const shareQuoteMutation = async ({
  dispatch,
  propertyId,
  quoteInfo,
  quoteState,
  snackbar,
  tenantId,
  email,
  expirationDate,
  setQuoteVersionsOptionData,
  quoteVersionsOptionData
}) => {
  try {
    const payload = {
      partitionKey: tenantId,
      action: 'SEND_TO_CUSTOMER',
      data: {
        customerPropertyId: propertyId,
        quote: {
          id: quoteInfo?.id,
          version: quoteInfo?.version,
          dateSent: moment(new Date()).unix(),
          emails: [email],
          expirationDate
        }
      }
    };
    const response = await new QuoteService().updateQuoteUsingTransition(payload);
    const updatedData = response?.data?.quoteTransition;
    if (updatedData) {
      dispatchAction({ dispatch, quoteState, response });
      // Updating quote versions data so document history tab has new pdf
      const newQuoteVersionsData = quoteVersionsOptionData.map(version =>
        version.id !== updatedData.id ? version : { ...version, emails: updatedData.emails.items }
      );
      setQuoteVersionsOptionData(newQuoteVersionsData);
      snackbar('success', `Successfully shared quote`);
    }
  } catch (error) {
    Logger.error(error);
    snackbar('error', `Unable to share quote, please try again later`, error);
  }
};

export const useQuoteTemplateQuery = (tenantId, snackbar, options = undefined) => {
  const [data, setData] = useState();
  const { data: rawData, loading, error, refetch } = useQuery(GetCompanyQuoteTemplate, {
    variables: { tenantId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options
  });

  useEffect(() => {
    const run = async () => {
      if (rawData) {
        const itemZero = rawData.getQuoteTemplates?.items[0] || {};
        if (rawData.items?.length > 1) {
          sentryException('Conflicting Quote Template Data', {
            conflictingItems: rawData.getQuoteTemplates?.items
          });
        }
        setData(itemZero);
      }
    };
    run();
  }, [rawData]);

  if (error) {
    logErrorWithCallback(error, snackbar, 'Failed to load company quote template');
  }
  return { loading: loading || !data, error, data, refetch };
};

export const useQuoteSettingsQuery = (tenantId, tenantCompanyId, snackbar, options = undefined) => {
  const [data, setData] = useState();
  const { data: rawData, loading, error, refetch } = useQuery(GetCompanyQuoteSettings, {
    variables: {
      partitionKey: tenantId,
      sortKey: `${tenantId}_Company_${tenantCompanyId}`
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options
  });

  useEffect(() => {
    const run = async () => {
      if (rawData) {
        const itemZero = rawData?.getCompany?.companySettings?.items[0];
        if (rawData?.getCompany?.companySettings?.items?.length > 1) {
          sentryException('Conflicting Quote Settings Data', {
            conflictingItems: rawData?.getCompany?.companySettings?.items
          });
        }
        if (itemZero && !isJSONParseableObjectOrArray(itemZero.settings)) {
          sentryException('Corrupt Quote Settings Data', {
            conflictingData: itemZero.settings
          });
          snackbar('error', 'Corrupt Settings Data - Contact Support');
        }
        setData(itemZero);
      }
    };
    run();
  }, [rawData]);

  if (error) {
    logErrorWithCallback(error, snackbar, 'Failed to load company quote settings');
  }
  return { loading, error, data, refetch };
};

export const useServiceAgreementsQuery = (
  tenantId,
  tenantCompanyId,
  snackbar,
  options = undefined
) => {
  const [data, setData] = useState();
  const { data: rawData, loading, error, refetch } = useQuery(getCompanyServiceAgreements, {
    variables: {
      partitionKey: tenantId,
      sortKey: `${tenantId}_Company_${tenantCompanyId}`
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options
  });

  useEffect(() => {
    const run = async () => {
      if (rawData) {
        setData(rawData.getCompany.serviceAgreements.items);
      }
    };
    run();
  }, [rawData]);

  if (error) {
    logErrorWithCallback(error, snackbar, 'Failed to load service agreements');
  }
  return { loading, error, data, refetch };
};

export const useGetJobsQuery = (tenantId, pagination, filter, snackbar, options = undefined) => {
  const [data, setData] = useState();
  const { data: rawData, loading, error, refetch } = useQuery(getJobs, {
    variables: {
      tenantId,
      pagination,
      filter
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options
  });

  useEffect(() => {
    const run = async () => {
      if (rawData) {
        setData(rawData.data.items);
      }
    };
    run();
  }, [rawData]);

  if (error) {
    logErrorWithCallback(error, snackbar, 'Failed to load jobs');
  }
  return { loading, error, data, refetch };
};

export const getQuoteActivityLogs = async (quoteId, snackbar, primaryQuoteId) => {
  const quoteService = new QuoteService();
  let data;
  try {
    if (quoteId === primaryQuoteId) {
      data = await quoteService.getAllAuditLogByQuoteId(primaryQuoteId);
    } else {
      data = await quoteService.getAllAuditLogByVersionedQuoteId(quoteId);
    }
  } catch (error) {
    Logger.error(error);
    logErrorWithCallback(
      error,
      snackbar,
      'Unable to fetch the quote`s activity logs, please try again later'
    );
  }
  return data;
};

export const updateQuotePurchaseOrders = async ({ po, parentData }) => {
  const quoteService = new QuoteService();

  const { primaryQuoteId, quoteInfo, tenantId, snackbar, dispatch } = parentData;
  const payload = {
    quoteId: quoteInfo.id,
    purchaseOrderId: po.id,
    primaryQuoteId,
    tenantId
  };

  try {
    const quotePurchaseOrder = await quoteService.updateQuotePurchaseOrders(payload);
    const { purchaseOrder, quote, versionedQuote } = quotePurchaseOrder;
    dispatch({
      type: ACTION.UPDATE_QUOTE_PURCHASE_ORDERS,
      payload: {
        poId: purchaseOrder?.id,
        poNumber: purchaseOrder?.poNumber,
        poStatus: purchaseOrder?.status,
        quoteVersionNumber: quote?.versionNumber || versionedQuote?.versionNumber
      }
    });
  } catch (error) {
    logErrorWithCallback(
      error,
      snackbar,
      'Unable to add the purchase order to the quote, please try again later'
    );
  }
};

export const dissociateJobFromQuote = async ({
  dispatch,
  propertyId,
  quoteInfo,
  quoteState,
  snackbar,
  tenantId,
  setQuoteVersionsOptionData,
  setHasMultipleVersions,
  quoteVersionsOptionData,
  primaryQuoteId
}) => {
  try {
    const payload = {
      partitionKey: tenantId,
      action: quoteTransitionActions.DISSOCIATE_JOB_FROM_QUOTE,
      data: {
        customerPropertyId: propertyId,
        quote: {
          id: quoteInfo?.id,
          version: quoteInfo?.version
        }
      }
    };
    const response = await new QuoteService().updateQuoteUsingTransition(payload);
    if (response.data.quoteTransition) {
      dispatchAction({ dispatch, quoteState, response });
      snackbar('success', 'Job dissociated from Quote Successfully');
      updateVersionDropdown(
        response,
        quoteVersionsOptionData,
        setQuoteVersionsOptionData,
        snackbar,
        setHasMultipleVersions,
        primaryQuoteId,
        tenantId,
        getQuoteById
      );
      sendMixpanelEvent(MIXPANEL_EVENT.DISSOCIATED_QUOTE_FROM_JOB, MIXPANEL_PAGE_NAME.QUOTES);
    }
  } catch (error) {
    logErrorWithCallback(
      error,
      snackbar,
      'Unable to dissociate job from quote, please try again later'
    );
  }
};
