import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const CREATE_INVOICE_FROM_TIME_AND_MATERIALS_JOB = gql`
  mutation createInvoiceFromTimeAndMaterialsJob($data: CreateInvoiceFromTimeAndMaterialsJobInput!) {
    createInvoiceFromTimeAndMaterialsJob(data: $data) {
      id
    }
  }
`;

const serializer = ({
  jobId,
  lineItemIds,
  visitIds,
  summary,
  includeTechnicianName,
  includeVisitDate
}) => ({
  variables: {
    data: {
      jobId,
      lineItemIds,
      visitIds,
      summary,
      includeTechnicianName,
      includeVisitDate
    }
  }
});

const useCreateInvoiceFromTimeAndMaterialsJob = () =>
  useExtendedMutation(CREATE_INVOICE_FROM_TIME_AND_MATERIALS_JOB, {
    serializer
  });

export default useCreateInvoiceFromTimeAndMaterialsJob;
