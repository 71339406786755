import React from 'react';

import { Dialog, DialogContent, Divider, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

import { DefaultButton } from 'components';

import Labels from 'meta/labels';
import ErrorBoundaries from 'scenes/Error';

const ScheduleModal = ({
  handleClose,
  openScheduleModal,
  handleScheduleVisits,
  classes,
  isSubmitting,
  user
}) => {
  return (
    <Dialog
      aria-describedby="simple-modal-description"
      aria-labelledby="simple-modal-title"
      maxWidth="lg"
      open={openScheduleModal}
      scroll="paper"
      onClose={handleClose}
    >
      <DialogContent className={classes.dialogContent}>
        <ErrorBoundaries>
          <div style={{ display: 'flex' }}>
            <CloseIcon className={classes.closeIcon} onClick={handleClose} />
            <Typography classes={{ h3: classes.header }} variant="h3">
              Incomplete Scheduling
            </Typography>
          </div>
          <Typography className={classes.description} variant="body2">
            Some visits haven't been scheduled. If you proceed, only scheduled visits will be
            created.
          </Typography>
          <Divider style={{ marginTop: '24px' }} variant="fullWidth" />
          <DefaultButton
            color="primary"
            disabled={isSubmitting}
            handle={handleScheduleVisits}
            label={Labels.proceedCreateVisit[user.locale].toUpperCase()}
            showSpinner={isSubmitting}
            style={{ width: '100%', marginTop: '20px', height: '38px' }}
            variant="contained"
          />
          <DefaultButton
            color="secondary"
            handle={handleClose}
            label={Labels.continueScheduling[user.locale].toUpperCase()}
            style={{ width: '100%', marginTop: '8px', height: '38px' }}
            variant="contained"
          />
        </ErrorBoundaries>
      </DialogContent>
    </Dialog>
  );
};

ScheduleModal.propTypes = {
  user: PropTypes.object.isRequired,
  openScheduleModal: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleScheduleVisits: PropTypes.func.isRequired,
  classes: PropTypes.object
};

ScheduleModal.defaultProps = {
  openScheduleModal: false,
  classes: {}
};

export default withTheme(ScheduleModal);
