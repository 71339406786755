import React, { useEffect } from 'react';

import { any } from 'prop-types';
import { useParams } from 'react-router-dom';

import { UserPermission } from 'components';
import ErrorBoundaries from 'scenes/Error';
import { PermissionConstants, ReviewReportStatus } from 'utils/AppConstants';
import { LazyMountScrollContainer } from 'utils/withLazyMount';

import { useReviewReport } from '../../queries';
import { selectForms } from '../../selectors';

import AssetTable from '../AssetTable';
import FormTable from '../FormTable';
import LaborSection from '../LaborSection';
import ReportInfo from '../ReportInfo';

import SignatureSection from '../SignatureSection';
import TasksSection from '../TasksSection';

import ReviewReportAttachmentTable from './components/ReviewReportAttachmentTable';
import ReviewReportDiscounts from './components/ReviewReportDiscounts';
import ReviewReportFees from './components/ReviewReportFees';
import ReviewReportHeader from './components/ReviewReportHeader';
import ReviewReportLaborLineItems from './components/ReviewReportLaborLineItems';
import ReviewReportNotesTable from './components/ReviewReportNotesTable';

import ReviewReportPartsAndMaterialsSection from './components/ReviewReportPartsAndMaterialsSection';
import ReviewReportSummaryNotesTable from './components/ReviewReportSummaryNotesTable';
import { withAddPOModal } from './hocs/withAddPOModal';
import { ReviewReportDisabledContext } from './ReviewReport.contexts';
import { useStyles } from './ReviewReport.styles';

const ReviewReport = ({ client }) => {
  useEffect(() => {
    // ensure all data is fresh when navigating back to dispatch
    client.resetStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { id } = useParams();
  const styles = useStyles();

  const {
    data: {
      visit: { job, ...visit },
      ...reviewReport
    },
    loading,
    error,
    refetch
  } = useReviewReport(id);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const disabled = reviewReport?.status?.toLowerCase() !== ReviewReportStatus.DRAFT;

  return (
    <ErrorBoundaries>
      <UserPermission action={PermissionConstants.OBJECT_VISIT} I="read">
        <ReviewReportDisabledContext.Provider value={{ disabled }}>
          <LazyMountScrollContainer className={styles.scrollContainer}>
            <ReviewReportHeader job={job} reportId={id} reviewReport={reviewReport} visit={visit} />
            <ReportInfo job={job} mountBeforeScroll reviewReport={reviewReport} visit={visit} />
            <ReviewReportSummaryNotesTable
              error={error}
              loading={loading}
              mountBeforeScroll
              reviewReport={reviewReport}
            />
            <ReviewReportNotesTable
              error={error}
              loading={loading}
              mountBeforeScroll
              reviewReport={reviewReport}
            />
            <AssetTable error={error} loading={loading} mountBeforeScroll visit={visit} />
            <TasksSection error={error} job={job} loading={loading} visit={visit} />
            <FormTable
              editable={!disabled}
              error={error}
              forms={selectForms(visit)}
              loading={loading}
            />
            <ReviewReportAttachmentTable
              error={error}
              loading={loading}
              reviewReport={reviewReport}
            />
            <SignatureSection isReviewReport reviewReport={reviewReport} />
            <ReviewReportPartsAndMaterialsSection
              error={error}
              job={job}
              loading={loading}
              reviewReport={reviewReport}
              visit={visit}
            />
            <LaborSection error={error} isReviewReport job={job} loading={loading} visit={visit} />
            <ReviewReportLaborLineItems
              job={job}
              loading={loading}
              reviewReport={reviewReport}
              visit={visit}
            />
            <ReviewReportDiscounts
              error={error}
              job={job}
              loading={loading}
              reviewReport={reviewReport}
              visit={visit}
            />
            <ReviewReportFees
              error={error}
              job={job}
              loading={loading}
              reviewReport={reviewReport}
              visit={visit}
            />
          </LazyMountScrollContainer>
        </ReviewReportDisabledContext.Provider>
      </UserPermission>
    </ErrorBoundaries>
  );
};

ReviewReport.propTypes = {
  client: any.isRequired
};

export default withAddPOModal(ReviewReport);
