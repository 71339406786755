import React from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(0.5)
  },
  today: {
    backgroundColor: theme.palette.grayscale(95),
    borderRadius: 5
  },
  dayOfWeek: {
    fontSize: 12,
    fontWeight: 500,
    color: '#333333',
    textTransform: 'uppercase'
  },
  weatherImg: {
    width: 25,
    height: 25,
    objectFit: 'cover'
  },
  tempatureContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tempatureMax: {
    fontSize: 10,
    fontWeight: 500,
    color: '#333333',
    marginRight: 5
  },
  tempatureMin: {
    fontSize: 10,
    fontWeight: 400,
    color: '#666666'
  }
}));

function WeatherTicker({ weatherData, style }) {
  const classes = useStyles();

  return (
    <div className={classes.root} style={style}>
      {weatherData?.map?.((item, index) => (
        <Box
          className={classes.itemContainer}
          // eslint-disable-next-line react/no-array-index-key
          key={`weatherTicker-${index}-${item.dayOfWeek}`}
        >
          <Typography className={classes.dayOfWeek}>{item.dayOfWeek}</Typography>
          <img alt={item.weatherType} className={classes.weatherImg} src={item.imgUrl} />
          <Box className={classes.tempatureContainer}>
            <Typography className={classes.tempatureMax}>{`${
              item.tempMax ? Math.round(item?.tempMax) : '-'
            }°`}</Typography>
            <Typography className={classes.tempatureMin}>{`${
              item.tempMin ? Math.round(item?.tempMin) : '-'
            }°`}</Typography>
          </Box>
        </Box>
      ))}
    </div>
  );
}

WeatherTicker.propTypes = {
  weatherData: PropTypes.array.isRequired,
  style: PropTypes.object
};

WeatherTicker.defaultProps = {
  style: {}
};

export default WeatherTicker;
