/* global google */
import React, { useMemo, useRef } from 'react';

import { MUIFormComponents } from '@BuildHero/sergeant';

import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { GoogleApiWrapper } from 'google-maps-react';

import { get } from 'lodash';
import PropTypes from 'prop-types';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useSelector } from 'react-redux';

import { Spinner } from 'components';
import { getTenantSettingValueForKey } from 'utils';
import { GOOGLE_MAPS_API_KEY, GoogleMapsComponentTypeToAddress } from 'utils/AppConstants';
import { AccountingApp } from 'utils/constants';

import { matchField } from './util';

const itemStyle = {
  cursor: 'pointer',
  paddingBottom: 3
};

const activeItemStyle = {
  ...itemStyle,
  background: '#f3f3f3'
};

// if setSource, it will set the field.name (source) with the selected address description
// searchOptions is a single object inside an array (due to how forms library handles objects)
// valuesToSet is a single object inside an array (due to how forms library handles objects)
//    The object is a mapping of form keys to address object keys
const PlacesSearch = ({
  form,
  field,
  options: {
    setSource = true,
    searchOptions,
    valuesToSet,
    fieldsToMatch,
    nullIfNoMatch = true,
    ...inputOptions
  },
  style
}) => {
  const ref = useRef();
  const placesService = useMemo(
    /* eslint-disable-next-line no-undef */
    () => {
      if (google.maps.places) {
        return new google.maps.places.PlacesService(document.createElement('div'));
      }
    },
    []
  );
  const tenantSettings = useSelector(s => s.settings);
  const isSpectrumEnabled = tenantSettings?.accountingApp === AccountingApp.SPECTRUM;

  const placesSearchOptions = {
    componentRestrictions: {
      // the setting value is a comma separated list of country abbreviations
      country: (getTenantSettingValueForKey('countrySetting') || 'us')?.split(',') // default is us
    },
    ...searchOptions?.[0]
  };

  // returns an address object like {
  //   city: { shortName: 'shortCityName', longName: 'longCityName' },
  //   state: { shortName: 'shortStateName', longName: 'longStateName' },
  //   ...etc
  // }
  const getAddressObject = async placeId =>
    new Promise((resolve, reject) =>
      placesService.getDetails(
        {
          placeId,
          fields: ['address_components', 'types', 'geometry']
        },
        (place, status) => {
          /* eslint-disable-next-line no-undef */
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            const { address_components: addressComponents, geometry } = place;
            const placeDetail = addressComponents.reduce((acc, c) => {
              const localAcc = acc;
              const { types, short_name, long_name } = c;
              if (!types) return acc;
              types.forEach(type => {
                localAcc[GoogleMapsComponentTypeToAddress[type]] = {
                  shortName: short_name,
                  longName: long_name
                };
              });
              return localAcc;
            }, {});
            placeDetail.latitude = geometry.location.lat();
            placeDetail.longitude = geometry.location.lng();
            resolve(placeDetail);
          }
          reject();
        }
      )
    );

  const handleChange = async address => {};
  const handleSelect = async (address, placeId) => {
    const addressObject = await getAddressObject(placeId);
    // TO DO: to be cleaned up when the search range is limited to valid addresses
    if (addressObject.street && addressObject.streetNumber) {
      addressObject.addressLine1 = `${addressObject.streetNumber.shortName} ${addressObject.street.shortName}`;
    } else {
      // filtering address to avoid repeating city / state / country
      // needed as spectrum has char limit on address line 1
      addressObject.addressLine1 = address
        .split(',')
        .filter(
          v =>
            ![
              addressObject?.country?.shortName,
              addressObject?.state?.shortName,
              addressObject?.city?.shortName,
              'USA'
            ].includes(v.trim())
        )
        .join(',');
    }

    if (isSpectrumEnabled && addressObject.addressLine1.length > 30) {
      addressObject.addressLine1 = addressObject.addressLine1.slice(0, 29);
    }

    addressObject.addressChanged = true;
    const { values, setValues } = form;
    // object passed in meta are stripped by form library. Expecting only one object within the arr
    const valuesToSetMap = valuesToSet?.[0] || {};
    const updatedValues = {};

    Object.keys(valuesToSetMap).forEach(key => {
      updatedValues[key] = get(addressObject, valuesToSetMap[key], '');
    });

    let matchedFields = {};
    if (fieldsToMatch) {
      matchedFields = matchField(fieldsToMatch[0], addressObject, nullIfNoMatch);
    }

    setValues({
      ...values,
      ...updatedValues,
      ...matchedFields,
      ...(setSource && { [field.name]: address })
    });
  };
  return (
    <div>
      {google.maps.places && (
        <PlacesAutocomplete
          searchOptions={placesSearchOptions}
          value={field.value || ''}
          onChange={handleChange}
          onSelect={handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <MUIFormComponents.TextInput
                field={field}
                form={form}
                inputProps={getInputProps({
                  ref,
                  disabled: inputOptions.disabled,
                  className: 'location-search-input',
                  autoComplete: 'new-password',
                  testingid: `${field.name}-placesearch`
                })}
                options={inputOptions}
                style={style}
              />
              <Popper
                anchorEl={ref.current}
                modifiers={{
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: 'window'
                  },
                  flip: {
                    enabled: false
                  }
                }}
                open={suggestions?.length > 0}
                placement="bottom-start"
                style={{ zIndex: 1500 }}
                transition // MUI modal is zIndex 1300
              >
                <Paper style={{ padding: '15px 15px 5px 15px', zIndex: 'auto' }}>
                  {loading ? (
                    <Spinner />
                  ) : (
                    suggestions.map(s => {
                      const className = s.active ? 'suggestion-item--active' : 'suggestion-item';
                      const style = s.active ? activeItemStyle : itemStyle;
                      return (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <div {...getSuggestionItemProps(s, { className, style, key: s.id })}>
                          <Typography variant="subtitle2">{s.description}</Typography>
                        </div>
                      );
                    })
                  )}
                  <div id="google-logo" style={{ textAlign: 'right', paddingTop: 5 }}>
                    <img
                      alt="Powered by Google"
                      src="https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png"
                    />
                  </div>
                </Paper>
              </Popper>
            </div>
          )}
        </PlacesAutocomplete>
      )}
    </div>
  );
};

PlacesSearch.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  options: PropTypes.shape({
    setSource: PropTypes.bool,
    searchOptions: PropTypes.array,
    valuesToSet: PropTypes.array,
    fieldsToMatch: PropTypes.array,
    /**
     * @prop @constant nullIfNoMatch
     * set the form value of the fields
     * to match as undefined if a match is not found
     */
    nullIfNoMatch: PropTypes.bool
  }).isRequired,
  style: PropTypes.object.isRequired
};

export default GoogleApiWrapper({ apiKey: GOOGLE_MAPS_API_KEY, libraries: ['places'] })(
  PlacesSearch
);
