import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

import { getCombinedAddress } from 'utils';

import styles from './styles';

function Hit(props) {
  const { formProps, hit, classes, isFieldEdited } = props;
  const { form, field, specialbehaviour } = formProps;
  const {
    displayText,
    valueField,
    delimiter,
    additionalFormField,
    additionalFormFieldValue,
    filterString,
    mappings,
    showAddress
    // costField,
    // priceField,
    // markupField,
    // markupTypeField,
    // taxField,
    // priceBookEntryId
  } = specialbehaviour;
  let resultText = '';
  if (displayText && Array.isArray(displayText)) {
    const displayValueArr = [];
    displayText.forEach(text => {
      if (hit[text]) {
        displayValueArr.push(hit[text]);
      }
    });
    resultText = displayValueArr.join(delimiter);
  } else if (displayText && typeof displayText === 'string') {
    resultText = hit[displayText];
  }
  const valueText = hit[valueField] || displayText[valueField];
  const { values } = form;
  let newValues = {
    ...values,
    [field.name]: valueText
  };

  if (additionalFormField && additionalFormFieldValue) {
    newValues = { ...newValues, [additionalFormField]: hit[additionalFormFieldValue] };
  }

  let addressText = '';

  if (showAddress) {
    addressText = getCombinedAddress(hit);
  }

  if (mappings) {
    Object.entries(mappings).forEach(([key, valueKey]) => {
      newValues[key] = typeof valueKey === 'string' ? get(hit, valueKey) : valueKey;
    });
  }

  return (
    <ListItem button className={classes.results} disableGutters>
      <ListItemText
        className={classes.resultsText}
        secondary={addressText}
        onClick={() => {
          form.setValues(newValues);
          if (form.values[filterString].getDependentFieldData) {
            form.values[filterString].getDependentFieldData(hit);
          }
          isFieldEdited();
        }}
      >
        {resultText}
      </ListItemText>
    </ListItem>
  );
}

export default withStyles(styles)(Hit);
