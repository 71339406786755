import React, { memo } from 'react';

import { Button, ThemeProvider } from '@BuildHero/sergeant';

import { Mode } from 'utils/constants';

import { onCreateTaskSave } from '../../CreateModal/CreateModal.handlers';
import { useAddTaskEntryToTask, useAddTaskToProperty } from '../../mutations';

const WrappedComponent = ({
  refetchTrigger,
  setIsCreateModalOpen,
  setModalConfig,
  setRefetchTrigger
}) => {
  const [addTaskEntryToTask] = useAddTaskEntryToTask();
  const [addTaskToProperty] = useAddTaskToProperty();

  const handleClick = () => {
    const config = {
      btnText: 'Create Recommendation',
      title: 'Create Recommendation',
      type: Mode.ADD,
      onSave: ({ onComplete, payload, tenantId }) =>
        onCreateTaskSave({
          addTaskEntryToTask,
          addTaskToProperty,
          onComplete,
          payload,
          refetchTrigger,
          setRefetchTrigger,
          tenantId
        })
    };
    setModalConfig(config);
    setIsCreateModalOpen(true);
  };
  return (
    <ThemeProvider>
      <Button onClick={handleClick}>Create Recommendation</Button>
    </ThemeProvider>
  );
};

const PageHeaderButtons = memo(WrappedComponent);

export default PageHeaderButtons;
