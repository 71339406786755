import React, { memo, useRef } from 'react';

import { EventEntities } from '@dispatch/Dispatch.constants';
import {
  withDispatchActions,
  withDispatchStore,
  withQueryStringStore
} from '@dispatch/Dispatch.store';
import useAddNonVisit from '@dispatch/mutations/useAddNonVisit';
import useAddRecurringNonBillableEvents from '@dispatch/mutations/useAddRecurringNonBillableEvents';
import compose from 'utils/compose';

import CreateNonBillableEvent from './CreateNonBillableEvent.component';

const mapActionsToProps = ({ closeDrawer }) => ({
  closeDrawer
});

const mapDispatchToProps = store => ({
  eventType: store.state.eventType,
  eventData: store.state.eventData
});

const mapQueryStringToProps = store => ({
  day: store.state.day
});

const DataProvider = props => {
  const Memoized = useRef(memo(CreateNonBillableEvent)).current;
  // eslint-disable-next-line react/prop-types
  const { day } = props;
  const addNonVisitTuple = useAddNonVisit(day, EventEntities.NON_BILLABLE_EVENT.value.serverValue);
  const addRecurringNonBillableTuple = useAddRecurringNonBillableEvents();
  return (
    <Memoized
      {...props}
      addNonVisitTuple={addNonVisitTuple}
      addRecurringNonBillableTuple={addRecurringNonBillableTuple}
    />
  );
};

export default compose(
  withDispatchActions(mapActionsToProps),
  withDispatchStore(mapDispatchToProps),
  withQueryStringStore(mapQueryStringToProps)
)(DataProvider);
