import { departmentSearch } from 'services/API/department';
import { getProjectCostCodesByProject } from 'services/API/projectCostCode';

import { generateDefaultFieldsObject } from '../../../components/formattingUtils';

const margin = '0px 15px 21px 0px';

// Equipment Information
export const budgetFields = {
  name: { required: true },
  description: { required: false },
  status: { required: false },
  billingType: { required: false },
  startDate: { required: true },
  endDate: { required: true },
  costCode: { required: false },
  ProjectPhaseDepartment: { required: true }
};

export const costFields = {
  name: { required: true },
  description: { required: false }
};

const defaultFlexStyle = '0 1 219px';
const smallItemFlexStyle = '0 0 182px';
const numberItemFlexStyle = '0 0 102px';
const wideItemFlexStyle = '0 0 453px';

const budgetContents = (projectId, addCodeToTable) => [
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1
    },
    contents: [
      {
        options: {
          margin
        },
        contents: [
          {
            component: 'sectionTitle',
            options: {
              label: 'General Information'
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      size: 4,
      direction: 'row',
      grow: 0,
      flexWrap: 'wrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: smallItemFlexStyle,
          margin
        },
        contents: [
          {
            source: 'name',
            component: 'TextInput',
            options: {
              label: 'Name',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: smallItemFlexStyle,
          margin
        },
        contents: [
          {
            component: 'SelectInput',
            source: 'status',
            options: {
              label: 'Status',
              variant: 'standard',
              color: 'secondary',
              enableSort: false,
              valueSet: [
                {
                  label: 'Not Started',
                  value: 'Not Started'
                },
                {
                  label: 'In Progress',
                  value: 'In Progress'
                },
                {
                  label: 'Complete',
                  value: 'Complete'
                }
              ]
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: smallItemFlexStyle,
          margin
        },
        contents: [
          {
            component: 'DateInput',
            source: 'startDate',
            options: {
              isRequired: true,
              variant: 'standard',
              color: 'secondary',
              label: 'Start Date'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: smallItemFlexStyle,
          margin
        },
        contents: [
          {
            component: 'DateInput',
            source: 'endDate',
            options: {
              isRequired: true,
              variant: 'standard',
              color: 'secondary',
              label: 'End Date'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: numberItemFlexStyle,
          marginLeft: '200px'
        },
        contents: [
          {
            source: 'number',
            component: 'CustomFieldWithLabel',
            options: {
              label: 'Number',
              fullWidth: true,
              alignment: 'right',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      size: 2,
      direction: 'row',
      grow: 0
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: wideItemFlexStyle,
          margin
        },
        contents: [
          {
            source: 'description',
            component: 'TextInput',
            options: {
              lines: '4',
              label: 'Description',
              variant: 'standard',
              color: 'secondary',
              placeholder: 'Enter Description'
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1,
      margin: '8px 0px 10px'
    },
    contents: [
      {
        component: 'CustomDividerStyled'
      }
    ]
  },
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1
    },
    contents: [
      {
        options: {
          margin
        },
        contents: [
          {
            component: 'sectionTitle',
            options: {
              label: 'Department'
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      size: 2,
      direction: 'row',
      grow: 0,
      flexWrap: 'wrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: 'SearchBar',
            source: 'ProjectPhaseDepartment',
            options: {
              resultFormatFunction: obj => `${obj.tagName}`,
              searchFunction: departmentSearch,
              searchColumns: ['tagName'],
              variant: 'standard',
              color: 'secondary',
              label: 'Department',
              isRequired: true,
              placeholder: 'Search Departments'
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      size: 2,
      direction: 'row',
      grow: 0,
      flexWrap: 'wrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            source: 'remainingValue',
            component: 'CustomFieldWithLabel',
            options: {
              label: 'Contract Value Remaining',
              fullWidth: true,
              alignment: 'right',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1
    },
    contents: [
      {
        options: {
          margin
        },
        contents: [
          {
            component: 'MuiFormSectionTitle',
            options: {
              label: 'Revenue Estimates'
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      direction: 'row'
    },
    contents: [
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            source: 'ProjectPhaseDepartment.laborDollars',
            component: 'CurrencyInput',
            options: {
              label: 'Labor Dollars',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            source: 'ProjectPhaseDepartment.materialCost',
            component: 'CurrencyInput',
            options: {
              label: 'Material',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            source: 'ProjectPhaseDepartment.equipmentCost',
            component: 'CurrencyInput',
            options: {
              label: 'Equipment',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            source: 'ProjectPhaseDepartment.subcontractorCost',
            component: 'CurrencyInput',
            options: {
              label: 'Subcontractor',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            source: 'ProjectPhaseDepartment.overheadCost',
            component: 'CurrencyInput',
            options: {
              label: 'Overhead',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            source: 'ProjectPhaseDepartment.otherCost',
            component: 'CurrencyInput',
            options: {
              label: 'Other',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1
    },
    contents: [
      {
        options: {
          margin
        },
        contents: [
          {
            component: 'MuiFormSectionTitle',
            options: {
              label: 'Estimates'
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      size: 2,
      direction: 'row',
      grow: 0,
      flexWrap: 'wrap'
    },
    contents: [
      {
        component: 'SearchBar',
        source: 'costCode',
        options: {
          resultFormatFunction: obj => `${obj.name} - ${obj.description}`,
          searchFunction: () => getProjectCostCodesByProject(projectId),
          searchColumns: ['name', 'description'],
          variant: 'standard',
          color: 'secondary',
          placeholder: 'Search Cost Codes',
          onSelect: addCodeToTable
        }
      }
    ]
  }
];

const costContents = [
  {
    options: {
      direction: 'row'
    },
    contents: [
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            component: 'TextInput',
            source: 'name',
            options: {
              variant: 'standard',
              color: 'secondary',
              label: 'Name',
              isRequired: true,
              placeholder: 'Enter Cost Code Name'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            source: 'description',
            component: 'TextInput',
            options: {
              label: 'Description',
              variant: 'standard',
              color: 'secondary',
              placeholder: 'Enter Cost Code Description'
            }
          }
        ]
      }
    ]
  }
];

export const budgetLayout = (projectId, addCodeToTable) => {
  return {
    fields: {
      ...generateDefaultFieldsObject(budgetFields)
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [...budgetContents(projectId, addCodeToTable)]
      },
      edit: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [...budgetContents(projectId, addCodeToTable)]
      }
    }
  };
};

export const costLayout = {
  fields: generateDefaultFieldsObject(costFields),
  layouts: {
    edit: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: costContents
    }
  }
};

export const budgetRowMeta = [
  {
    id: 'number',
    label: 'Number',
    type: 'number'
  },
  {
    id: 'name',
    label: 'Phase',
    showLink: 'view',
    linkPath: 'projectsettings/phase',
    linkReference: 'phase',
    type: 'string'
  },
  {
    id: 'startDate',
    label: 'Start Date',
    type: 'date'
  },
  {
    id: 'endDate',
    label: 'End Date',
    type: 'date'
  },
  {
    id: 'percentComplete',
    label: 'Progress',
    type: 'percentage'
  }
];

export const budgetCodeRowMeta = [
  {
    id: 'name',
    label: 'Name',
    type: 'number'
  },
  {
    id: 'installedUnits',
    label: 'Installed Units',
    type: 'customInput',
    numeric: true,
    isCustom: true
  },
  {
    id: 'materialCost',
    label: 'Material',
    type: 'customInput',
    numeric: true,
    isCustom: true
  },
  {
    id: 'equipmentCost',
    label: 'Equipment',
    type: 'customInput',
    numeric: true,
    isCustom: true
  },
  {
    id: 'subcontractorCost',
    label: 'Subcontractor',
    type: 'customInput',
    numeric: true,
    isCustom: true
  },
  {
    id: 'overheadCost',
    label: 'Overhead',
    type: 'customInput',
    numeric: true,
    isCustom: true
  },
  {
    id: 'otherCost',
    label: 'Other',
    type: 'customInput',
    numeric: true,
    isCustom: true
  },
  {
    id: 'totalCost',
    label: 'Total',
    type: 'currency'
  }
];

export const costRowMeta = [
  {
    id: 'name',
    label: 'Name',
    showLink: 'view',
    linkPath: 'projectsettings/costcode',
    linkReference: 'costcode',
    type: 'string'
  },
  {
    id: 'description',
    label: 'Description',
    type: 'string'
  }
];
