import React from 'react';

import { MUIFormComponents } from '@BuildHero/sergeant';
import { Box, TextField } from '@material-ui/core';

import { checkUsernameExists } from '../services';

const UserName = ({ options, field, snackbarOn, form }) => {
  const [userNameExist, setUserNameExist] = React.useState(false);
  const [isEdited, setIsEdited] = React.useState(false);
  const { setFieldValue } = form;
  const error = form.errors && form.errors[field.name];

  const handleOnChange = e => {
    setFieldValue(field.name, e.target.value);
    setIsEdited(true);
  };

  const handleOnBlur = () =>
    isEdited &&
    checkUsernameExists({ userName: field.value, successCallback: setUserNameExist, snackbarOn });

  const renderErrorMessage = message => <MUIFormComponents.ErrorMessage value={message} />;

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <MUIFormComponents.LabelControl required={options.isRequired} value={options.label} />
        <TextField
          error={userNameExist || error}
          value={field.value}
          variant="outlined"
          onBlur={handleOnBlur}
          onChange={handleOnChange}
        />
      </Box>
      {error && renderErrorMessage(error)}
      {userNameExist && renderErrorMessage('User name Already Exist')}
    </Box>
  );
};

export default UserName;
