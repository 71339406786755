/* eslint-disable react/jsx-no-duplicate-props */
import React, { useCallback, useState } from 'react';

import { Input, ThemeProvider } from '@BuildHero/sergeant';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import { useFormikContext } from 'formik';

// FIXME: Never gets displayed
const VALIDATION_FAILURE_MESSAGE = 'Please enter a number in quarter-hour increments';

// Returns null if it can't parse a valid value
export function parseDuration(value) {
  if (
    !Number.isNaN(value) &&
    // We require a min increment of quarter hours
    Math.abs(Math.round(value * 4) - value * 4) < 1e-8
  ) {
    if (typeof value === 'string') return parseFloat(value);
    return value;
  }

  return null;
}

// NB: editValue is in hours, as that's what we display to the user; field.value
// is in minutes, to avoid floating point problems as much as possible
export const DurationInput = React.memo(({ field, options }) => {
  const { label } = options;
  const { errors, setFieldError, setFieldValue } = useFormikContext();
  const [editValue, setEditValue] = useState(null);

  const handleChange = useCallback(
    event => {
      const currentValue = event.target.value;
      if (currentValue === '') {
        setFieldValue(field.name, null);
        setEditValue();
        return;
      }

      const parsed = parseDuration(currentValue);
      if (parsed) {
        setFieldError(field.name, undefined);
        setFieldValue(field.name, parsed * 60);
        setEditValue(null);
      } else {
        setFieldError(field.name, VALIDATION_FAILURE_MESSAGE);
        setEditValue(currentValue);
      }
    },
    [setFieldError, setEditValue]
  );

  const error = errors && errors[field.name];

  return (
    <ThemeProvider>
      <Input
        endAdornment={
          <InputAdornment position="end">
            <Typography style={{ marginRight: '5px' }}>hr</Typography>
          </InputAdornment>
        }
        error={error && error !== ''}
        inputProps={{ step: 0.25, min: 0 }}
        label={label}
        name={field.name}
        subtext={error}
        type="number"
        value={editValue ?? (field.value ? field.value / 60 : '')}
        variant="outlined"
        onChange={handleChange}
      />
    </ThemeProvider>
  );
});
