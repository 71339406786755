import { MultiSelectTypes } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';

const poReceiptListTableMeta = isVendorInvoiceApproval => [
  {
    id: 'receiptNumber',
    label: 'Receipt Number',
    type: 'number',
    numeric: true,
    align: 'left',
    showLink: 'view',
    linkPath: '/procurement/receipts-bills/receipt/view',
    linkReference: 'id'
  },
  {
    id: 'Vendor.name',
    label: 'Vendor',
    type: 'string',
    align: 'left'
  },
  {
    id: 'vendorDocumentNumber',
    label: 'Vendor Document Number',
    type: 'vendorDocumentNumber',
    align: 'left'
  },
  {
    id: 'Department.tagName',
    label: 'Department',
    type: 'string',
    align: 'left'
  },
  {
    id: 'status',
    label: 'Status',
    enumType: MultiSelectTypes.PURCHASE_ORDER_RECEIPT_STATUS,
    type: 'enum',
    showIcon: true,
    align: 'left'
  },
  {
    id: 'jobOrProjectText',
    label: 'Job / Project',
    showLink: 'view',
    linkPath: '',
    linkReference: 'jobOrProjectLink',
    align: 'left'
  },
  {
    id: 'totalCost',
    label: 'Total',
    type: 'currency',
    numeric: true,
    align: 'left'
  },
  ...(isVendorInvoiceApproval
    ? [
        {
          id: 'projectManager.name',
          label: 'Project Manager',
          type: 'string',
          align: 'left'
        },
        {
          id: 'approvalStatus',
          label: 'Approval Status',
          enumType: EnumType.VENDOR_APPROVAL_STATUS,
          type: 'enum',
          showIcon: true,
          align: 'left'
        },
        {
          id: 'invoiceAmount',
          label: 'Invoice Amount',
          type: 'currency',
          numeric: true,
          align: 'left'
        }
      ]
    : [])
];

export default poReceiptListTableMeta;
