import React from 'react';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import AlarmIcon from '@material-ui/icons/Alarm';
import AssignmentOutlined from '@material-ui/icons/AssignmentOutlined';
import BuildOutlined from '@material-ui/icons/BuildOutlined';
import BusinessOutlined from '@material-ui/icons/BusinessOutlined';
import ContactIcon from '@material-ui/icons/Contacts';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DescriptionIcon from '@material-ui/icons/Description';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MonetizationOnSharpIcon from '@material-ui/icons/MonetizationOnSharp';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import QueryBuilderOutlined from '@material-ui/icons/QueryBuilderOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import StoreIcon from '@material-ui/icons/Store';
import ApplicationIcon from '@material-ui/icons/Tab';
import CustomerIcon from '@material-ui/icons/WorkOutlineOutlined';

import { ProcurementIcon } from './CustomIcons';
import TimesheetsIcon from './CustomIcons/TimesheetsIcon';

const iconMap = icon => {
  const iconArray = {
    addCircleIcon: <AddCircleIcon />,
    contactIcon: <ContactIcon style={{ fontSize: 16 }} />,
    applicationIcon: <ApplicationIcon style={{ fontSize: 16 }} />,
    customerIcon: <CustomerIcon style={{ fontSize: 16 }} />,
    localShippingIcon: <LocalShippingIcon style={{ fontSize: 16 }} />,
    settingsIcon: <SettingsIcon style={{ fontSize: 16 }} />,
    deviceHubIcon: <DeviceHubIcon style={{ fontSize: 16 }} />,
    permIdentityIcon: <PermIdentityIcon style={{ fontSize: 16 }} />,
    storeIcon: <StoreIcon style={{ fontSize: 16 }} />,
    buildIcon: <BuildOutlined style={{ fontSize: 16 }} />,
    descriptionIcon: <DescriptionIcon style={{ fontSize: 16 }} />,
    formatListBulletedIcon: <FormatListBulletedIcon style={{ fontSize: 16 }} />,
    paymentIcon: <MonetizationOnSharpIcon style={{ fontSize: 16 }} />,
    alarmIcon: <AlarmIcon style={{ fontSize: 16 }} />,
    dashboardIcon: <DashboardIcon style={{ fontSize: 16 }} />,
    productsIcon: <MenuBookIcon style={{ fontSize: 16 }} />,
    historyIcon: <QueryBuilderOutlined style={{ fontSize: 16 }} />,
    techReportsIcon: <AssignmentOutlined style={{ fontSize: 16 }} />,
    reportsIcon: <DescriptionOutlined style={{ fontSize: 16 }} />,
    propertyIcon: <BusinessOutlined style={{ fontSize: 16 }} />,
    procurementIcon: <ProcurementIcon style={{ fontSize: 16, marginTop: 6 }} />,
    timesheetsIcon: <TimesheetsIcon style={{ fontSize: 16, marginTop: 6 }} />
  };
  return iconArray[icon];
};

export default iconMap;
