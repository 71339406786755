import React, { useMemo } from 'react';

import { TV, TW, Typography } from '@BuildHero/sergeant';

import WrapTable from 'components/WrapTable';

import usePropertyAssetMaintenanceJobPreview from './hooks/usePropertyAssetMaintenanceJobPreview';

const AssetTypeViewMaintenancePlan = ({
  assetType,
  assetTypeId,
  assetTypeNodeId,
  data,
  expand,
  expanded,
  propertyName,
  serviceAgreementId,
  setMainContent
}) => {
  const columns = useMemo(
    () => [
      {
        field: 'id',
        hide: true
      },
      {
        field: 'assetName',
        headerName: 'Asset',
        renderCell: cell => {
          return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              href=""
              role="button"
              tabIndex="0"
              onClick={event => {
                event.preventDefault(); // prevent page refresh from href=""
                const asset = data.find(({ id }) => id === cell.row.id);
                setMainContent({
                  data: asset,
                  assetType,
                  propertyName,
                  type: asset.entityType
                });
                if (!expanded.includes(assetTypeNodeId)) {
                  expand(assetTypeNodeId);
                }
              }}
            >
              {cell.value}
            </a>
          );
        },
        flex: 1
      },
      {
        field: 'make',
        headerName: 'Make',
        flex: 1
      },
      {
        field: 'modelNumber',
        headerName: 'Model',
        flex: 1
      },
      {
        field: 'serialNo',
        headerName: 'Serial',
        flex: 1
      },
      {
        field: 'numberOfAnnualMaintenanceJobs',
        headerName: 'Annual Maintenances',
        flex: 1
      },
      {
        field: 'mergedTaskTemplateChecklists',
        headerName: 'Checklists',
        renderCell: cell => {
          return cell.value.map(({ name }) => name).join(', ');
        },
        flex: 1
      }
    ],
    [assetType, assetTypeNodeId, data, expanded, expand, propertyName, setMainContent]
  );

  const { data: rows = [], loading } = usePropertyAssetMaintenanceJobPreview({
    serviceAgreementId,
    assetTypeId
  });

  if (loading) {
    return null;
  }

  return (
    <>
      <Typography css={{ paddingBottom: 24 }} variant={TV.L} weight={TW.BOLD}>
        {`All ${assetType}s at ${propertyName}`}
      </Typography>
      <WrapTable columns={columns} hideFooter={rows.length < 11} loadingRows={3} rows={rows} />
    </>
  );
};

export default AssetTypeViewMaintenancePlan;
