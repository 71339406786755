import gql from 'graphql-tag';
import { omit } from 'lodash';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { logErrorWithCallback } from 'utils';

const BULK_UPDATE_LABOUR_RATE_MODIFIERS = gql`
  mutation bulkUpdateLabourRateModifiers($data: AddLabourRateModifiersToCompanyInput!) {
    bulkUpdateLabourRateModifiers(data: $data) {
      id
      name
      sortOrder
      appliesToCategory
      modifierValue
      modifierType
      employeeLabourRateModifiers {
        items {
          id
          employeeId
          employee {
            id
            name
          }
        }
      }
      departmentLabourRateModifiers {
        items {
          id
          departmentId
          department {
            id
            tagName
          }
        }
      }
    }
  }
`;

const serializer = ({ companyId, labourRateModifiers }) => ({
  variables: {
    data: {
      companyId,
      labourRateModifiers: labourRateModifiers.map(modifier =>
        omit(modifier, [
          '__typename',
          'unsaved',
          'employeeLabourRateModifiers',
          'departmentLabourRateModifiers'
        ])
      )
    }
  }
});

const update = (cache, { data: { bulkUpdateLabourRateModifiers } }, ref) => {
  cache.modify({
    id: `Company:${ref.companyId}`,
    fields: {
      labourRateModifiers() {
        return bulkUpdateLabourRateModifiers.map(labourRateModifier => {
          return {
            __ref: `${labourRateModifier.__typename}:${labourRateModifier.id}`
          };
        });
      }
    }
  });
};

export const useBulkUpdateLabourRateModifiers = () => {
  return useExtendedMutation(BULK_UPDATE_LABOUR_RATE_MODIFIERS, {
    serializer,
    update,
    onSuccess: (data, snackbarOn) =>
      snackbarOn('success', 'Successfully updated labor rate modifiers'),
    onError: (err, snackbarOn) =>
      logErrorWithCallback(err, snackbarOn, 'Unable to update labor rate modifiers')
  });
};
