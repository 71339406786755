import React, { useCallback, useState } from 'react';

import {
  Button,
  ButtonType,
  InlineAlert,
  InlineAlertTypes,
  Input,
  theme,
  TW,
  Typography
} from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';
import { debounce } from 'lodash';

import { AttachmentItemPreview } from 'components';
import { StepButtonStateConstants } from 'scenes/Invoices/InvoiceDetail/InvoiceEmailModal/InvoiceEmailModal.constants';

const CombinedAttachment = ({
  attachment,
  alertMessage,
  handleAttachmentsChange,
  dispatchButtonState,
  errors,
  setErrors
}) => {
  const { fileName, fileUrl } = attachment;
  const [finalFileName, setFinalFileName] = useState(fileName);

  const downloadPDF = useCallback(() => {
    const downloadLink = document.createElement('a');
    downloadLink.href = fileUrl;
    downloadLink.download = finalFileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }, [fileUrl, finalFileName]);

  const validateFileName = newFileName => {
    return newFileName.match(/\.[^/.]*(pdf)$/);
  };

  const debouncedChangeFileName = useCallback(
    debounce(e => {
      const newFileName = e.target.value;
      if (validateFileName(newFileName)) {
        setFinalFileName(e.target.value);
        handleAttachmentsChange([{ ...attachment, fileName: e.target.value }]);
        setErrors(prev => ({ ...prev, fileName: '' }));
        dispatchButtonState(StepButtonStateConstants.ACTIVE);
      } else {
        setErrors(prev => ({ ...prev, fileName: 'The file name should end with .pdf' }));
        dispatchButtonState(StepButtonStateConstants.DISABLED);
      }
    }, 500)
  );

  return (
    <Box display="flex">
      <AttachmentItemPreview fileData={attachment} hideFileName removable={false} />
      <Box display="flex" flexDirection="column" paddingTop={1} width="100%">
        <Input
          defaultValue={fileName}
          error={Boolean(errors.fileName)}
          label="File Name"
          subtext={errors.fileName}
          onChange={debouncedChangeFileName}
        />
        <Box marginTop={1}>
          <Button
            css={{ padding: 0, margin: '8px 8px 8px 0', textTransform: 'none' }}
            size="small"
            type={ButtonType.LEADING}
            onClick={() => window.open(fileUrl, '_blank')}
          >
            <Typography color={theme.palette.support.green.dark} underlined weight={TW.MEDIUM}>
              Preview PDF
            </Typography>
          </Button>
          <Button
            css={{ padding: 0, margin: '8px 8px 8px 0', textTransform: 'none' }}
            size="small"
            type={ButtonType.LEADING}
            onClick={downloadPDF}
          >
            <Typography color={theme.palette.support.green.dark} underlined weight={TW.MEDIUM}>
              Download PDF
            </Typography>
          </Button>
        </Box>
        {!!alertMessage && (
          <InlineAlert
            message={alertMessage}
            style={{ marginTop: 8 }}
            type={InlineAlertTypes.RED}
          />
        )}
      </Box>
    </Box>
  );
};

export default React.memo(CombinedAttachment);
