import React, { useEffect, useReducer, useState } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import { Divider, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import buildHeroMuiFormOverrides from 'scenes/ProjectManagement/components/buildHeroMuiFormOverrides';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: '15px',
    marginBottom: '15px'
  },
  formContainer: buildHeroMuiFormOverrides(theme)
}));

const MUIFormListView = props => {
  const {
    keyName,
    onComplete,
    formatFuncForSave,
    layout,
    formData,
    configuration,
    onCreateService,
    customComponents,
    validationSchema,
    onFormChange,
    useIndexConfigCallback
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();

  const reducer = (state, data) => {
    if (data === false) {
      return [];
    }
    return [...state, data];
  };

  // eslint-disable-next-line no-unused-vars
  const [completedData, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    if (isSubmitting && completedData.length === formData.length) {
      setIsSubmitting(false);
      onComplete(completedData);
      dispatch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedData]);

  return (
    <div>
      {formData.map((formItem, index) => {
        const item = { ...formItem, index: formItem.index ? formItem.index : index };
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div
            className={classes.formContainer}
            key={`${keyName}-${formItem.index ? formItem.index : index}`}
          >
            <MUIForm
              configuration={
                useIndexConfigCallback
                  ? configuration(formItem.index ? formItem.index : index)
                  : configuration
              }
              customComponents={customComponents}
              data={item}
              layout={layout}
              validationSchema={validationSchema}
              onComplete={onComplete(
                `${keyName}-${formItem.index ? formItem.index : index}`,
                formatFuncForSave,
                [`${keyName}`]
              )}
              onCreateService={onCreateService(
                `${keyName}-${formItem.index ? formItem.index : index}`
              )}
              onFormChange={onFormChange}
            />
            <Divider className={classes.divider} />
          </div>
        );
      })}
    </div>
  );
};

MUIFormListView.propTypes = {
  keyName: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  formatFuncForSave: PropTypes.func,
  layout: PropTypes.string,
  formData: PropTypes.array.isRequired,
  configuration: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  onCreateService: PropTypes.func.isRequired,
  customComponents: PropTypes.object,
  validationSchema: PropTypes.object.isRequired,
  onFormChange: PropTypes.func,
  useIndexConfigCallback: PropTypes.bool
};

MUIFormListView.defaultProps = {
  formatFuncForSave: () => {},
  layout: 'default',
  customComponents: {},
  onFormChange: () => {},
  useIndexConfigCallback: false
};

export default MUIFormListView;
