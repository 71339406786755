import React from 'react';

import { ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { Link } from 'react-router-dom';

const quoteToPoGeneralInfoTitle = quoteInfo => {
  return () => (
    <ThemeProvider>
      <Typography variant={TV.BASE} weight={TW.REGULAR}>
        Purchase Order for items on{' '}
        <Link to={`/quote/${quoteInfo.id}`}>
          <Typography display="inline" variant={TV.BASE} weight={TW.MEDIUM}>
            Version {quoteInfo.versionNumber} of Quote{' '}
            {quoteInfo.customIdentifier || quoteInfo.quoteNumber} - {quoteInfo.name}
          </Typography>
        </Link>
      </Typography>
    </ThemeProvider>
  );
};

export default quoteToPoGeneralInfoTitle;
