import React, { useMemo, useState } from 'react';

import { Field, FieldType, Input } from '@BuildHero/sergeant';

import { secondsToHour } from 'scenes/Payroll/TimeTrackingReport/helpers';

const HourInput = ({
  workEvent,
  hourTypeAbbreviation,
  updateTimesheetEntry = () => {},
  isEditable,
  isIncomplete,
  hourType,
  style
}) => {
  const { timesheetHours } = workEvent;
  const [displayValue, setDisplayValue] = useState();

  const value = useMemo(() => {
    if (displayValue !== undefined) return displayValue;

    return Number.isInteger(timesheetHours[hourTypeAbbreviation].actualTotalDurationOverride)
      ? secondsToHour(timesheetHours[hourTypeAbbreviation].actualTotalDurationOverride)
      : secondsToHour(timesheetHours[hourTypeAbbreviation].actualTotalDuration);
  }, [timesheetHours, displayValue]);

  return (
    <>
      {isEditable ? (
        <Input
          disabled={isIncomplete}
          label={hourTypeAbbreviation}
          placeholder="0.0"
          style={{ maxWidth: 100, ...style }}
          type={FieldType.NUMBER}
          value={value}
          onBlur={e => {
            updateTimesheetEntry({
              id: timesheetHours[hourTypeAbbreviation].id,
              actualTotalDurationOverride: parseInt(parseFloat(e.target.value || 0) * 3600, 10),
              extra: {
                workEvent,
                hourType
              }
            });
            setDisplayValue(undefined);
          }}
          onChange={e => setDisplayValue(e.target.value)}
        />
      ) : (
        <Field
          label={hourTypeAbbreviation}
          placeholder="0.0"
          style={{ width: '100%', maxWidth: 100, ...style }}
          type={FieldType.NUMBER}
          value={value}
        />
      )}
    </>
  );
};

export default HourInput;
