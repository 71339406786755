import React from 'react';

import { ThemeProvider } from '@BuildHero/sergeant';

import WrapTable from 'components/WrapTable';

import { useBillingProductsColumns } from './BillingProductsTable.columns';
import { GROUP_BY_LABOUR_RATE_GROUP_NAME } from './BillingProductsTable.constants';

const BillingProductsTable = ({
  tableLength,
  loading,
  billingHourTypes,
  formik,
  productOptions
}) => (
  <ThemeProvider>
    <WrapTable
      columns={useBillingProductsColumns({ billingHourTypes, loading, productOptions, formik })}
      hideFooter={tableLength <= 10}
      loading={loading}
      noWrap
      rowGroupingModel={GROUP_BY_LABOUR_RATE_GROUP_NAME}
      rows={formik.values}
    />
  </ThemeProvider>
);

export default BillingProductsTable;
