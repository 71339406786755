import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function Reports(props) {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SvgIcon {...props}>
      <path d="M17 1L21 5V21C21 22.1046 20.1046 23 19 23H5C3.89543 23 3 22.1046 3 21V3C3 1.89543 3.89543 1 5 1H17ZM17 18H5V20H17V18ZM16 15H5V17H16V15ZM17 12H5V14H17V12ZM17 7H5V9H17V7ZM10 3H5V5H10V3Z" />
      <path clipRule="evenodd" d="M21 5H17V1L21 5Z" fill="#00874D" fillRule="evenodd" />
    </SvgIcon>
  );
}
