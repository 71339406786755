import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const UPDATE_SERVICE_AGREEMENT = gql`
  mutation($data: ServiceAgreementUpdateInput!) {
    updateServiceAgreement(input: $data) {
      id
      customerId
      billingCustomerId
      startDate
      endDate
      paymentTerms
      termPrice
      agreementName
      firstBillDate
      customerPoNumber
      rateCardJSON
      departmentId
      soldById
      billingType
      annualContractValue
      pricebookId
    }
  }
`;

export const useUpdateServiceAgreement = () => {
  return useExtendedMutation(UPDATE_SERVICE_AGREEMENT, {
    serializer: ({
      id,
      customerId,
      billingCustomerId,
      startDate,
      endDate,
      paymentTerms,
      termPrice,
      agreementName,
      firstBillDate,
      customerPoNumber,
      rateCardJSON,
      departmentId,
      soldById,
      billingType,
      annualContractValue,
      pricebookId
    }) => ({
      variables: {
        data: {
          id,
          customerId,
          billingCustomerId,
          startDate,
          endDate,
          paymentTerms,
          termPrice,
          agreementName,
          firstBillDate,
          customerPoNumber,
          rateCard: rateCardJSON ? JSON.parse(rateCardJSON) : undefined,
          departmentId,
          soldById,
          billingType,
          annualContractValue,
          pricebookId
        }
      }
    }),
    onSuccess: (transformedData, snackbarOn) => {
      snackbarOn('success', 'Service Agreement Saved');
    },
    onError: (transformedData, snackbarOn) =>
      snackbarOn('error', 'Unable to Save Service Agreement')
  });
};
