import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Divider,
  Field,
  FieldType,
  Select,
  SgtFormComponents,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';

import useJobCostingOptions from 'customHooks/useJobCostingOptions';
import { snackbarOn } from 'redux/actions/globalActions';
import { QuickbooksService } from 'services/core';
import { AccountingApp, Mode } from 'utils/constants';

import AdjustmentItemModal from './AdjustmentItem.modal';
import { formatAdjustmentItem } from './AdjustmentItem.utils';

const Header = (
  <>
    <Divider margin={24} />
    <Typography style={{ marginBottom: 4 }} variant={TV.L} weight={TW.BOLD}>
      Add Items
    </Typography>
    <Typography style={{ maxWidth: 880, marginBottom: 24 }}>
      In order to record the accurate refund total with sales tax taken into consideration, BuildOps
      requires adding line items and indicate whether the added item is taxable or not. (Only
      applies when the transaction type is refund/write-off.)
    </Typography>
  </>
);

const TotalDivider = <Divider alignSelf="stretch" color="black" margin={15.5} />;

const modalTitle = {
  [Mode.ADD]: 'Add Item',
  [Mode.EDIT]: 'Edit Item',
  [Mode.DELETE]: 'Delete Item'
};

const getColumns = accountingApp => [
  accountingApp === AccountingApp.INTACCT && {
    id: 'ledgerAccount.label',
    label: 'GL Account',
    width: `${116 / 5.64}%`,
    weight: TW.BOLD
  },
  accountingApp !== AccountingApp.INTACCT && {
    id: 'name',
    label: 'Item Name',
    width: `${116 / 5.64}%`,
    weight: TW.BOLD
  },
  {
    id: 'department.label',
    label: 'Department',
    width: `${64 / 5.64}%`
  },
  {
    id: 'description',
    label: 'Description',
    width: `${132 / 5.64}%`
  },
  {
    id: 'taxable',
    label: 'Taxable',
    type: FieldType.BOOL,
    width: `${36 / 5.64}%`
  },
  {
    id: 'quantity',
    label: 'Quantity',
    type: FieldType.NUMBER,
    width: `${56 / 5.64}%`,
    numeric: true,
    sum: true
  },
  {
    id: 'unitPrice',
    label: 'Unit Price',
    type: FieldType.CURRENCY,
    width: `${80 / 5.64}%`
  },
  {
    id: 'amount',
    label: 'Price Subtotal',
    type: FieldType.CURRENCY,
    width: `${80 / 5.64}%`,
    sum: true
  }
];

export default function AdjustmentItem({ form, ...props }) {
  const [ledgerAccountOptions, setLedgerAccountOptions] = useState();
  const [itemModal, setItemModal] = useState({ open: false });

  const {
    costCodeOptions,
    jobCostTypeOptions: costTypeOptions,
    revenueTypeOptions
  } = useJobCostingOptions();

  const { setFieldValue, setFieldTouched, values, errors } = form;

  const {
    tenantCompanyId,
    tenantId,
    subtotal,
    taxableSubtotal,
    taxAmount,
    taxLedgerAccount,
    amount,
    taxRate,
    department,
    priceBook,
    taxRateOptions,
    departmentOptions,
    accountingApp
  } = values;

  useEffect(() => {
    const getGLAccounts = async () => {
      const service = new QuickbooksService();
      let accounts = [];
      let token;
      do {
        const {
          data: {
            getCompany: {
              ledgerAccounts: { items, nextToken }
            }
          }
          // eslint-disable-next-line no-await-in-loop
        } = await service.getGLAccounts(
          tenantId,
          `${tenantId}_Company_${tenantCompanyId}`,
          undefined,
          undefined,
          token
        );
        accounts = accounts.concat(items.map(l => ({ label: l.name, value: l.id })));
        token = nextToken;
      } while (token);
      setLedgerAccountOptions(accounts);
    };
    if (accountingApp === AccountingApp.INTACCT) getGLAccounts();
  }, [accountingApp, tenantCompanyId, tenantId]);

  const tableProps = useMemo(() => {
    const handleClose = () => setItemModal(prev => ({ ...prev, open: false }));
    const openModal = (mode, givenData, callback) =>
      setItemModal({
        open: true,
        mode,
        data: {
          department,
          name: null,
          ledgerAccount: null,
          taxable: false,
          quantity: null,
          unitCost: null,
          unitPrice: null,
          amount: null,
          ...givenData,
          priceBook,
          // temp id on create - removed during upsert
          id: givenData?.id ?? `-${givenData?.sortOrder}`,
          ledgerAccountOptions,
          costCodeOptions,
          costTypeOptions,
          revenueTypeOptions,
          departmentOptions,
          accountingApp
        },
        title: modalTitle[mode],
        handlePrimaryAction: newData => {
          callback(formatAdjustmentItem(newData));
          handleClose();
        },
        onClose: handleClose
      });

    return {
      columns: getColumns(accountingApp),
      onCreate: (sortOrder, callback) => openModal(Mode.ADD, { sortOrder }, callback),
      onUpdate: (data, callback) => openModal(Mode.EDIT, data, callback),
      onDelete: (data, callback) => openModal(Mode.DELETE, data, callback)
    };
  }, [
    accountingApp,
    department,
    priceBook,
    ledgerAccountOptions,
    costCodeOptions,
    costTypeOptions,
    revenueTypeOptions,
    departmentOptions
  ]);

  const taxRateSelect = useMemo(
    () => (
      <Select
        clearable
        options={taxRateOptions}
        searchable
        style={{ marginBottom: 16 }}
        testingid="taxRate"
        value={taxRate}
        onChange={value => setFieldValue('taxRate', value, true)}
      />
    ),
    [setFieldValue, taxRate, taxRateOptions]
  );

  const loading = accountingApp === AccountingApp.INTACCT && ledgerAccountOptions === undefined;
  return (
    <>
      {Header}
      {loading ? (
        <Skeleton height={126} />
      ) : (
        <SgtFormComponents.Table {...props} form={form} props={tableProps} />
      )}
      <Box display="flex" justifyContent="flex-end">
        {accountingApp === AccountingApp.INTACCT && (
          <Box marginRight={2} marginTop={13.25} minWidth={200}>
            {loading ? (
              <Skeleton height={56} />
            ) : (
              <Select
                clearable
                error={!!errors.taxLedgerAccount}
                label="GL Account"
                options={ledgerAccountOptions}
                required={!!taxRate?.taxRate}
                searchable
                subtext={errors.taxLedgerAccount}
                testingid="GL-account"
                tooltip="GL Account for tax item. Required if a tax rate is selected"
                value={taxLedgerAccount}
                onBlur={() => setTimeout(() => setFieldTouched('taxLedgerAccount', true, true))}
                onChange={value => setFieldValue('taxLedgerAccount', value, true)}
              />
            )}
          </Box>
        )}
        <Box display="flex" flexDirection="column" textAlign="right" width="105">
          <Typography style={{ marginBottom: 32 }}>Subtotal</Typography>
          <Typography style={{ marginBottom: 26 }}>Taxable Subtotal</Typography>
          <Typography style={{ marginBottom: 26 }}>Tax Rate</Typography>
          <Typography style={{ marginBottom: 32 }}>Tax Amount</Typography>
          <Typography weight={TW.BOLD}>Total</Typography>
        </Box>
        <Box
          alignItems="flex-end"
          display="flex"
          flexDirection="column"
          marginLeft={5}
          minWidth={200}
        >
          <Field testingid="subTotal" type={FieldType.CURRENCY} value={subtotal} weight={TW.BOLD} />
          {TotalDivider}
          <Field
            style={{ marginBottom: 16 }}
            testingid="taxable-subTotal"
            type={FieldType.CURRENCY}
            value={taxableSubtotal}
            weight={TW.BOLD}
          />
          {taxRateSelect}
          <Field
            testingid="taxAmount"
            type={FieldType.CURRENCY}
            value={taxAmount}
            weight={TW.BOLD}
          />
          {TotalDivider}
          <Field
            testingid="total-amount"
            type={FieldType.CURRENCY}
            value={amount}
            weight={TW.BOLD}
          />
        </Box>
      </Box>
      <AdjustmentItemModal {...itemModal} />
    </>
  );
}
