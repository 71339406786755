import useExtendedMutation from 'customHooks/useExtendedMutation';
import { batchMutateJobTasks } from 'scenes/Jobs/DetailView/JobTabs/Tasks/BatchMutateJobTasks.gql';

import { getTasksByJobId } from './useGetTasksByJobId';

const useBatchMutateJobTasks = ({ partitionKey, jobId }) => {
  return useExtendedMutation(batchMutateJobTasks, {
    serializer: ({ data }) => ({
      variables: {
        partitionKey,
        data
      }
    }),
    onSuccess: (data, snackbar) => {
      snackbar('success', 'Tasks successfully added');
    },
    onError: (error, snackbar) => {
      snackbar('error', 'Unable to add tasks');
    },
    update: (cache, { data: { batchMutateJobTasks: batchMutateJobTasksMutationResponse } }) => {
      const query = {
        query: getTasksByJobId,
        variables: {
          jobId,
          listType: 'All'
        }
      };
      cache.writeQuery({ ...query, data: { items: batchMutateJobTasksMutationResponse } });
    }
  });
};

export default useBatchMutateJobTasks;
