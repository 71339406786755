import React, { useState } from 'react';

import {
  Button,
  ButtonType,
  NumberInput,
  ThemeProvider,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';
import { Grid } from '@material-ui/core';

import { debounce } from 'lodash';

import { Modal } from 'components';
import ResponsiveTable from 'components/ResponsiveTable';

import billLineMeta from 'meta/Jobs/ReviewReport/AddBillModal';

import { useSetStopLoading } from '../ReviewReport/ReviewReport.hook';

export const ModalSendActions = {
  CLOSE: 'CLOSE',
  SAVE: 'SAVE'
};

const BillModal = ({
  billOptions,
  billsForTable,
  open,
  reviewReportId,
  saving,
  send,
  service,
  title
}) => {
  const addQuantityNotYetInvoiced = 'Add Quantity Not Yet Invoiced';
  const addQuantityUsedOnThisVisit = 'Add Quantity Used on this Visit';
  const setStopLoading = useSetStopLoading(service);
  const [checkedBills, setCheckedBills] = useState([]);
  const [billItemsData, setBillItemsData] = useState(billOptions);
  const [isError, setIsError] = useState({});

  const handlePrimaryAction = async (values, stopLoading) => {
    let hasErrors = false;
    if (!checkedBills.length) {
      return send('CLOSE');
    }

    const getMatchingBillLineOnReviewReport = lineItem =>
      billsForTable.reduce(
        (acc, tableLineItem) =>
          (lineItem.billNumber === tableLineItem.billNumber &&
            tableLineItem.billLineItems.find(item => item.billLineId === lineItem.id)) ||
          acc,
        {}
      );

    const billItemsToAdd = checkedBills.map(lineItem => {
      // Update existing bill line on review report instead of adding a new line
      const billLineMatch = getMatchingBillLineOnReviewReport(lineItem);

      if (!lineItem.quantityToBill) {
        setIsError(prevState => ({ ...prevState, [lineItem.id]: true }));
        hasErrors = true;
      }

      return {
        id: billLineMatch?.id,
        billLineId: lineItem.id,
        description: lineItem.description,
        taxable: lineItem.taxable,
        quantity: billLineMatch?.quantity
          ? lineItem.quantityToBill + billLineMatch.quantity
          : lineItem.quantityToBill,
        reviewReportId
      };
    });
    if (hasErrors) return;
    const saveData = { ...values, billItemsToAdd };
    setStopLoading(stopLoading);
    send('SAVE', { saveData });
  };

  const handleCheckboxChange = (_, billLines) => setCheckedBills(billLines);

  const handleInputChange = debounce((evt, record) => {
    if (record.remainingQuantity >= 0 && (evt < 0 || evt > record.remainingQuantity)) {
      setIsError({ ...isError, [record.id]: true });
    } else if (record.remainingQuantity < 0 && evt < record.remainingQuantity) {
      setIsError({ ...isError, [record.id]: true });
    } else {
      setIsError({ ...isError, [record.id]: false });
    }

    const newRecord = { ...record, quantityToBill: evt };
    const updatedCheckedBills = checkedBills.map(item =>
      item.id !== newRecord.id ? item : newRecord
    );
    const newBillOptions = billItemsData.map(item => (item.id !== newRecord.id ? item : newRecord));
    setCheckedBills(updatedCheckedBills);
    setBillItemsData(newBillOptions);
  }, 750);

  const handleAddQuantityBtnClick = type => {
    const updatedBillItems = billItemsData.map(item => {
      const isChecked = checkedBills.some(checked => checked.id === item.id);
      if (isChecked && item[type] > item.remainingQuantity) {
        setIsError({ ...isError, [item.id]: true });
      } else if (isChecked) {
        setIsError({ ...isError, [item.id]: false });
      }

      return {
        ...item,
        quantityToBill: isChecked ? item[type] : null
      };
    });

    const updatedCheckedBills = checkedBills.map(checked => ({
      ...checked,
      quantityToBill: checked[type]
    }));

    setBillItemsData(updatedBillItems);
    setCheckedBills(updatedCheckedBills);
  };

  const CustomQuantityToBill = ({ record }) => (
    <ThemeProvider>
      <NumberInput
        error={isError[record.id]}
        subtext={isError[record.id] && 'Quantity is invalid'}
        value={record.quantityToBill}
        onChange={evt => handleInputChange(evt, record)}
      />
    </ThemeProvider>
  );

  return (
    <Modal
      disablePrimaryButton={!billOptions.length}
      handleClose={() => send('CLOSE')}
      maxWidth={false}
      open={open}
    >
      <div>
        <ThemeProvider>
          <Typography style={{ paddingBottom: 14 }} variant={TV.XL} weight={TW.BOLD}>
            {billOptions.length ? title : 'No Bills To Add'}
          </Typography>
          <Grid container justify="flex-end">
            <Button
              disabled={!checkedBills.length}
              style={{ marginRight: 8 }}
              type={ButtonType.SECONDARY}
              onClick={() => handleAddQuantityBtnClick('remainingQuantity')}
            >
              {addQuantityNotYetInvoiced}
            </Button>
            <Button
              disabled={!checkedBills.length}
              type={ButtonType.TERTIARY}
              onClick={() => handleAddQuantityBtnClick('usedQuantityOnVisit')}
            >
              {addQuantityUsedOnThisVisit}
            </Button>
          </Grid>
        </ThemeProvider>
        <ResponsiveTable
          customCellComponents={{ CustomQuantityToBill }}
          data={billItemsData}
          rowActionButtons={{
            select: {
              referenceKey: 'billId'
            }
          }}
          rowActions={handleCheckboxChange}
          rowMetadata={billLineMeta}
        />
        <ThemeProvider>
          <Button
            disabled={!checkedBills.length || Object.values(isError).includes(true)}
            fullWidth
            loading={saving}
            style={{ marginTops: 24 }}
            type="primary"
            onClick={handlePrimaryAction}
          >
            {`Add ${checkedBills.length} Items to Review Report`}
          </Button>
        </ThemeProvider>
      </div>
    </Modal>
  );
};

export default BillModal;
