import uuidV4 from 'uuid/v4';

import { CustomFieldTypes } from 'utils/constants';

import InstructionHelper from '../InstructionHelper';

const tagsToJson = (tagsArray, type, jsonTemplate) => {
  const tagsSchemaArray = [];
  // eslint-disable-next-line no-unused-expressions
  Array.isArray(tagsArray) &&
    tagsArray
      .filter(tag => tag.tagName)
      .forEach((tag, index) => {
        const tempId = tag.id ? tag.id : uuidV4();
        const tagSchema = {
          ...jsonTemplate,
          tagName: tag.tagName,
          tagType: type,
          id: tempId
        };

        if (typeof tag.showOnMobile === 'boolean') {
          tagSchema.showOnMobile = tag.showOnMobile;
        }

        if (
          type !== CustomFieldTypes.JobTags &&
          type !== CustomFieldTypes.InvoiceTags &&
          type !== CustomFieldTypes.PurchaseOrderTags &&
          type !== CustomFieldTypes.QuoteTags
        ) {
          if (type === CustomFieldTypes.MaintenanceTypes) {
            tagSchema.entityType = CustomFieldTypes.JobTypes;
          } else {
            tagSchema.entityType = type;
          }
        }
        if (type !== 'TimeCardEntryType') {
          tagSchema.sortOrder = typeof tag.sortOrder === 'number' ? tag.sortOrder : index + 1;
        }

        if (tag.tagAbbreviation) tagSchema.tagAbbreviation = tag.tagAbbreviation;
        tagsSchemaArray.push(tagSchema);
      });
  return tagsSchemaArray;
};

export const jobTagSchema = props => {
  let jobTagsArray = [];
  if (props.jobTags) {
    jobTagsArray = jobTagsArray.concat(tagsToJson(props.jobTags, 'JobTag', {}));
  }
  return {
    partitionKey: props.tenantId,
    companyId: props.tenantCompanyId,
    data: jobTagsArray
  };
};

export const invoiceTagSchema = props => {
  let invoiceTagsArray = [];
  if (props.invoiceTags) {
    invoiceTagsArray = invoiceTagsArray.concat(tagsToJson(props.invoiceTags, 'InvoiceTag', {}));
  }
  return {
    partitionKey: props.tenantId,
    companyId: props.tenantCompanyId,
    data: invoiceTagsArray
  };
};

export const purchaseOrderTagSchema = props => {
  let purchaseOrderTagsArray = [];
  if (props.purchaseOrderTags) {
    purchaseOrderTagsArray = purchaseOrderTagsArray.concat(
      tagsToJson(props.purchaseOrderTags, 'PurchaseOrderTag', {})
    );
  }
  return {
    partitionKey: props.tenantId,
    companyId: props.tenantCompanyId,
    data: purchaseOrderTagsArray
  };
};

export const quoteTagSchema = props => {
  let quoteTagsArray = [];
  if (props.quoteTags) {
    quoteTagsArray = quoteTagsArray.concat(tagsToJson(props.quoteTags, 'QuoteTag', {}));
  }
  return {
    partitionKey: props.tenantId,
    companyId: props.tenantCompanyId,
    data: quoteTagsArray
  };
};

export const projectTypeSchema = props => {
  const projectTypes = (props.projectTypes || []).map(p => {
    const val = { name: p.tagName, sortOrder: p.sortOrder };
    if (p.id) val.id = p.id;
    return val;
  });
  return {
    partitionKey: props.tenantId,
    data: {
      companyId: props.tenantCompanyId,
      projectTypes
    }
  };
};

export const projectSubTypeSchema = props => {
  const projectSubTypes = (props.projectSubTypes || []).map(p => {
    const val = { name: p.tagName, sortOrder: p.sortOrder };
    if (p.id) val.id = p.id;
    return val;
  });
  return {
    partitionKey: props.tenantId,
    data: {
      companyId: props.tenantCompanyId,
      projectSubTypes
    }
  };
};

export const productCategorySchema = props => {
  const productCategories = (props.productCategories || []).map(p => {
    const val = { name: p.tagName, sortOrder: p.sortOrder };
    if (p.id) val.id = p.id;
    return val;
  });
  return {
    partitionKey: props.tenantId,
    data: {
      companyId: props.tenantCompanyId,
      productCategory: productCategories
    }
  };
};

export const customFieldsSchema = props => {
  const jsonTemplate = {
    tenantId: props.tenantId,
    tenantCompanyId: props.tenantCompanyId
  };

  let customerTypeArray = [];
  if (props.customerTypes) {
    customerTypeArray = customerTypeArray.concat(
      tagsToJson(props.customerTypes, 'CustomerType', jsonTemplate)
    );
  }

  let customerTagArray = [];
  if (props.customerTags) {
    customerTagArray = customerTagArray.concat(
      tagsToJson(props.customerTags, 'CustomerTag', jsonTemplate)
    );
  }

  let propertyTypeArray = [];
  if (props.customerPropertyTypes) {
    propertyTypeArray = propertyTypeArray.concat(
      tagsToJson(props.customerPropertyTypes, 'CustomerPropertyType', jsonTemplate)
    );
  }

  let jobTypeArray = [];
  if (props.jobTypes) {
    jobTypeArray = jobTypeArray.concat(tagsToJson(props.jobTypes, 'JobType', jsonTemplate));
  }
  if (props.maintenanceTypes) {
    jobTypeArray = jobTypeArray.concat(
      tagsToJson(props.maintenanceTypes, 'MaintenanceType', jsonTemplate)
    );
  }
  let taskTypeArray = [];
  if (props.taskTypes) {
    taskTypeArray = taskTypeArray.concat(tagsToJson(props.taskTypes, 'TaskType', jsonTemplate));
  }

  const data = [{ ...props.parent }];
  const ins = new InstructionHelper();
  let temp = ins;
  if (customerTagArray && customerTagArray.length > 0) {
    customerTagArray.forEach(tag => {
      if (tag.tagName) {
        data.push(tag);
        temp = temp.oneToMany(0, data.length - 1);
      }
    });
  }

  if (customerTypeArray && customerTypeArray.length > 0) {
    customerTypeArray.forEach(tag => {
      if (tag.tagName) {
        data.push(tag);
        temp = temp.oneToMany(0, data.length - 1);
      }
    });
  }

  if (propertyTypeArray && propertyTypeArray.length > 0) {
    propertyTypeArray.forEach(tag => {
      if (tag.tagName) {
        data.push(tag);
        temp = temp.oneToMany(0, data.length - 1);
      }
    });
  }

  if (jobTypeArray && jobTypeArray.length > 0) {
    jobTypeArray.forEach(tag => {
      if (tag.tagName) {
        data.push(tag);
        temp = temp.oneToMany(0, data.length - 1);
      }
    });
  }

  if (taskTypeArray && taskTypeArray.length > 0) {
    taskTypeArray.forEach(tag => {
      if (tag.tagName) {
        data.push(tag);
        temp = temp.oneToMany(0, data.length - 1);
      }
    });
  }

  return { data, instructionSet: temp.instructionSet };
};
