import React, { useEffect, useMemo, useState } from 'react';

import { Divider, Label, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';
import { useFormik } from 'formik';
import { isEqual, sortBy } from 'lodash';

import AlgoliaSearch from 'components/SgtAlgoliaSearch/AlgoliaSearch';
import { bundleIndex } from 'constants/algoliaIndex';

import {
  useBulkUpdateBillingHourTypes,
  useBulkUpdateLabourTypeBillingHourTypeProducts,
  useUpdatePayrollSettings
} from '../../../mutations';

import styles from '../../styles';

import SaveButton from '../SaveButton';

import BillingHourTypesTable from './BillingHourTypesTable';
import BillingProductsTable from './BillingProductsTable';
import { useFormattedData } from './BillingProductsTable/BillingProductsTable.hooks';

const getDirtyLabourTypeBillingHourTypeProducts = ({ labourRateGroups, updatedBillingProducts }) =>
  labourRateGroups
    .map(labourRateGroup => {
      const labourTypeBillingHourTypeProducts =
        labourRateGroup?.labourTypeBillingHourTypeProducts?.items || [];
      return labourTypeBillingHourTypeProducts.reduce((result, entry) => {
        const tableLineItem = updatedBillingProducts.find(
          item =>
            item.labourRateGroupId === labourRateGroup.id &&
            item.labourTypeId === entry.labourTypeId
        );

        const { hourTypeAbbreviation } = entry.billingHourType;

        if (
          tableLineItem?.[hourTypeAbbreviation]?.value &&
          tableLineItem[hourTypeAbbreviation].value !== entry.productId
        ) {
          return [...result, { ...entry, productId: tableLineItem[hourTypeAbbreviation].value }];
        }
        return result;
      }, []);
    })
    .flat();

const BillingHourRatesAndTypes = ({
  labourRateGroups,
  labourTypes,
  payrollSettings,
  products,
  billingHourTypes,
  setIsDirty,
  isDirty,
  user
}) => {
  const [
    bulkUpdateLabourTypeBillingHourTypeProducts,
    { loading: updatingLabourTypeBillingHourTypeProducts }
  ] = useBulkUpdateLabourTypeBillingHourTypeProducts();

  const [
    bulkUpdateBillingHourTypes,
    { loading: updatingBillingHourTypes }
  ] = useBulkUpdateBillingHourTypes();

  const [updatePayrollSettings, { loading: updatingPayrollSettings }] = useUpdatePayrollSettings();

  const formattedData = useFormattedData({
    billingHourTypes,
    labourRateGroups,
    labourTypes
  });
  const billingProductsTableFormik = useFormik({
    enableReinitialize: true,
    initialValues: formattedData
  });

  const payrollSettingsFormik = useFormik({
    enableReinitialize: true,
    initialValues: payrollSettings
  });

  const [billingHourTypesToUpdate, setBillingHourTypesToUpdate] = useState(billingHourTypes);

  useEffect(() => {
    setBillingHourTypesToUpdate(billingHourTypes);
  }, [billingHourTypes]);

  useEffect(() => {
    setIsDirty(
      !isEqual(sortBy(billingHourTypesToUpdate, 'sortOrder'), billingHourTypes) ||
        billingProductsTableFormik.dirty ||
        payrollSettingsFormik.dirty
    );
  }, [
    setIsDirty,
    billingHourTypesToUpdate,
    billingHourTypes,
    billingProductsTableFormik.dirty,
    payrollSettingsFormik.dirty
  ]);

  const productOptions = useMemo(
    () => products.map(product => ({ label: product.name, value: product.id })),
    [products]
  );

  return (
    <>
      <Typography css={styles.greenHeading} variant={TV.BASE} weight={TW.BOLD}>
        Billing
      </Typography>
      <BillingProductsTable
        billingHourTypes={billingHourTypes}
        formik={billingProductsTableFormik}
        loading={updatingLabourTypeBillingHourTypeProducts}
        productOptions={productOptions}
        tableLength={labourRateGroups.length}
      />
      <Divider />
      <Typography css={styles.greenHeading} variant={TV.BASE} weight={TW.BOLD}>
        Billing Hour Types
      </Typography>
      <Box sx={{ marginBottom: 24 }}>
        <ThemeProvider>
          <Label label="Default Billing Product for Labor Invoice Items" />
          <AlgoliaSearch
            disableClear
            filters="entityType:Product"
            formatHitLabel={h => `${h.name || ''}${h.description ? `, ${h.description}` : ''}`}
            indexName={bundleIndex}
            name="defaultProductSetting"
            placeholder="Search"
            required
            value={payrollSettingsFormik.values.defaultProductName}
            onChange={({ id, name }) => {
              payrollSettingsFormik.setFieldValue('defaultProductId', id);
              payrollSettingsFormik.setFieldValue('defaultProductName', name);
            }}
          />
          <Typography variant={TV.S2} weight={TW.REGULAR}>
            The new default will only be applied to new Billing Hour Types created
          </Typography>
        </ThemeProvider>
      </Box>
      <BillingHourTypesTable
        billingHourTypes={billingHourTypesToUpdate}
        loading={updatingBillingHourTypes}
        setBillingHourTypes={setBillingHourTypesToUpdate}
      />
      <SaveButton
        disabled={!isDirty}
        loading={
          updatingBillingHourTypes ||
          updatingPayrollSettings ||
          updatingLabourTypeBillingHourTypeProducts
        }
        onClick={async () => {
          if (billingProductsTableFormik.dirty) {
            const dirtyEntries = getDirtyLabourTypeBillingHourTypeProducts({
              labourRateGroups,
              updatedBillingProducts: billingProductsTableFormik.values
            });
            bulkUpdateLabourTypeBillingHourTypeProducts({
              companyId: user?.tenantCompanyId,
              labourTypeBillingHourTypeProducts: dirtyEntries
            });
          }

          bulkUpdateBillingHourTypes({
            companyId: user?.tenantCompanyId,
            billingHourTypes: billingHourTypesToUpdate
          });

          if (payrollSettingsFormik.dirty) {
            updatePayrollSettings({
              partitionKey: user?.tenantId,
              formData: payrollSettingsFormik.values
            });
          }
        }}
      />
    </>
  );
};

export default BillingHourRatesAndTypes;
