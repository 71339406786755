import { defaultInvoiceSettings, parseInvoiceSettings } from '../InvoiceSetting';

import getDefaultLayout from './default';
import getSimpleLayout from './simple';

/**
 * Generate the config for invoice (default, pdf modes)
 * @param data Invoice data
 * @param handleInvoiceItemChange Function to handle invoice item mutations
 * @param isEditable If true, will ignore invoice settings and render inputs
 * @param isPDF If true, will render with invoice settings and simple invoice
 */
export default (data, handleInvoiceItemChange, isEditable = false, isPDF = false) => {
  // settings - if edit mode, show all details (default settings)
  const settings = parseInvoiceSettings(isEditable ? defaultInvoiceSettings : data.settings);
  const dataWithSettings = { ...data, settings };

  if (settings.mode.simple && !isEditable && isPDF) {
    return getSimpleLayout(dataWithSettings);
  }

  return getDefaultLayout(dataWithSettings, handleInvoiceItemChange, isEditable);
};
