import { removeNestedJson } from '../../utils';
import InstructionHelper from '../InstructionHelper';

const propertySchemaMapping = props => {
  // can remove notes - leaving for backend compatibility and mobile
  const { parent, assetNotes, attachments, ...otherFields } = props;
  const strippedproperty = removeNestedJson(parent);
  const ins = new InstructionHelper();
  let temp = ins.oneToMany(0, 1);

  otherFields.assetName = otherFields.assetName.trim();
  otherFields.imageUrl = otherFields.fileUrl || otherFields.imageUrl;

  const data = [{ ...strippedproperty }, { ...otherFields }];

  const commonObjectProperties = {
    partitionKey: props.tenantId,
    tenantId: props.tenantId,
    tenantCompanyId: props.tenantCompanyId
  };

  const prepData = (entityType, entityList = []) => {
    entityList.map(entity => {
      if (entity.__typename) delete entity.__typename;
      data.push({ entityType, ...commonObjectProperties, ...entity });
      temp = temp.oneToMany(1, data.length - 1);
    });
  };

  prepData('Attachment', attachments);
  prepData('Note', assetNotes);

  return { data, instructionSet: temp.instructionSet };
};

export default propertySchemaMapping;
