import React from 'react';

import { ThemeProvider, TW, Typography } from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';
import { groupBy } from 'lodash';

import { StatusChip } from 'components';
import Labels from 'meta/labels';
import { purchaseOrderStatusChips } from 'meta/Procurement/PurchaseOrders/frameStatusChips';
import { getEntireStatus } from 'scenes/Procurement/component/utils';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

const QuotePurchaseOrders = ({ containerClass, quotePurchaseOrders, flags, quoteId, user }) => {
  if (!quoteId) return null;
  const posGroupedByQuoteVersion = Object.entries(
    groupBy(quotePurchaseOrders, 'quoteVersionNumber')
  );

  return (
    <Box className={containerClass} sx={{ paddingLeft: 9 }}>
      <ThemeProvider>
        <Typography weight={TW.BOLD}>{Labels.quoteAssociatedPurchaseOrder[user.locale]}</Typography>
        {posGroupedByQuoteVersion.length > 0 ? (
          posGroupedByQuoteVersion.map(po => {
            return (
              <Box>
                <Typography caps style={{ margin: '12px 0 8px 0' }}>
                  Version {po[0]}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  {po[1].map(item => {
                    const status = item?.poStatus ? getEntireStatus(item.poStatus) : null;
                    return (
                      <Box>
                        <a href={`/procurement/purchaseorders/view/${item?.poId}`}>
                          {item.poNumber}
                        </a>
                        <StatusChip
                          backgroundColor={purchaseOrderStatusChips[status]?.backgroundColor}
                          label={item?.poStatus}
                          style={{ borderRadius: 2, margin: '0 20px 0 4px' }}
                          textColor={purchaseOrderStatusChips[status]?.textColor}
                        />
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            );
          })
        ) : (
          <Typography> - </Typography>
        )}
      </ThemeProvider>
    </Box>
  );
};

export default QuotePurchaseOrders;
