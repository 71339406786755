/** @jsxRuntime classic */
/** @jsx jsx */
import React, { memo } from 'react';

import { jsx } from '@emotion/react';
import { Tooltip, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { isUndefined } from 'lodash';

import compose from 'utils/compose';
import ConditionalWrapper from 'utils/ConditionalWrapper';
import withErrorBoundary from 'utils/withErrorBoundary';

import { ColumnProps } from '../WrapTable.types';

import { useFormattedValue, useRetrievedValue } from './Column.hooks';
import { useColumnStyles, useLoadingColumnStyles } from './Column.styles';

const Column = props => {
  const {
    className,
    colDef,
    groupDepth,
    groupExpanded,
    isGroupRow,
    isTotal,
    maxGroupingDepth,
    row,
    rows,
    setGroupExpanded,
    sortIndex,
    value
  } = props;
  const isGroupControlCell = sortIndex === 0 && setGroupExpanded && isGroupRow;
  const canGroup = isUndefined(maxGroupingDepth) || maxGroupingDepth > groupDepth;
  const { valueFormatter, renderCell, valueGetter, showTooltip } = colDef;
  const cellAddress = `[${row?._rowIndex ?? row?.__groupValue}].${colDef.field}`;
  const retrievedValue = useRetrievedValue({ value, valueGetter, row, rows, colDef, isTotal });
  const formattedValue = useFormattedValue({ valueFormatter, retrievedValue, row, rows, colDef });
  const styles = useColumnStyles({ ...props, groupExpanded });

  return (
    <ConditionalWrapper
      condition={showTooltip && retrievedValue}
      wrapper={children => (
        <Tooltip placement="right" title={formattedValue}>
          {children}
        </Tooltip>
      )}
    >
      <div css={[styles.column, className]}>
        {isGroupControlCell && canGroup && (
          <IconButton
            css={styles.expandButton}
            data-testid={`toggle-group-${row?.__groupValue}`}
            size="small"
            onClick={() => setGroupExpanded(!groupExpanded)}
          >
            {groupExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        )}
        {renderCell ? (
          renderCell({
            ...props,
            formattedValue,
            retrievedValue,
            cellAddress
          })
        ) : (
          <Typography testingid={cellAddress}>{formattedValue}</Typography>
        )}
      </div>
    </ConditionalWrapper>
  );
};

Column.propTypes = ColumnProps;

const ErrorCell = props => {
  const styles = useColumnStyles(props);

  return (
    <div css={[styles.column, styles.error]}>
      <Typography>CELL ERROR</Typography>
    </div>
  );
};

export const LoadingColumn = memo(({ flex, minWidth, maxWidth, width, scrollX }) => {
  const styles = useLoadingColumnStyles({ flex, minWidth, maxWidth, width, scrollX });

  return <div css={styles.loadingColumn} />;
});

export default compose(withErrorBoundary(ErrorCell), memo)(Column);
