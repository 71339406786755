/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import { MultiSelect } from '@BuildHero/sergeant';
import algoliasearch from 'algoliasearch/lite';

import configForEnvironment from 'configs/aws-exports';
import ENV from 'configs/env';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import KEY_QUERY from 'services/core/graphql/common/queries/getAlgoliaSecuredKey';

const useAlgoliaSecuredKey = () =>
  useExtendedQuery(KEY_QUERY, {
    transform: data => data.getAlgoliaSecuredKey
  });

const SgtAlgoliaMultiSelect = ({
  options: {
    algoliaIndex,
    restructureAlgoliaHitToOptions = hit => [hit],
    algoliaFilter = '',
    preSearchOptions = [],
    persistSuggestions = false,
    ...multiSelectProps
  }
}) => {
  const [index, setIndex] = useState();
  const { data: key } = useAlgoliaSecuredKey();
  useEffect(() => {
    const configure = async () => {
      const client = algoliasearch(configForEnvironment(ENV).algoliaAppKey, key);
      const i = client.initIndex(algoliaIndex);
      setIndex(i);
    };
    if (key) configure();
  }, [key]);

  return (
    <MultiSelect
      {...multiSelectProps}
      customSearch={async ({ value, setSearchedOptions }) => {
        if (!value) return setSearchedOptions(preSearchOptions);
        const { hits } = await index.search(value, {
          filters: algoliaFilter
        });

        const searchOptions = hits.reduce((acc, hit) => {
          const hitOptions = restructureAlgoliaHitToOptions(hit);
          return [...acc, ...hitOptions];
        }, []);
        setSearchedOptions([
          ...(persistSuggestions ? multiSelectProps.suggestedOptions : []),
          ...searchOptions
        ]);
      }}
      noResultsText="Type to see options…"
    />
  );
};

export default SgtAlgoliaMultiSelect;
