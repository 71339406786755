import React from 'react';

import { TV, TW, Typography } from '@BuildHero/sergeant';
import { Dialog, Grid, Slide } from '@material-ui/core';

import { bool, func } from 'prop-types';

import { AddRecordButton } from 'components';
import FormPDF from 'components/FormSection/FormPDF';

import { REPORT_FORM_PROP } from 'scenes/Jobs/JobDetail/propTypes';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const PDFDialog = ({ open, setOpen, onClose, formData }) => {
  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition} onClose={onClose}>
      <Grid alignItems="center" container direction="row" justify="space-between" spacing={2}>
        <Grid item style={{ marginLeft: 30 }}>
          <Typography variant={TV.BASE} weight={TW.BOLD}>
            {`${formData?.name} - Preview`}
          </Typography>
        </Grid>
        <Grid item>
          <AddRecordButton handle={() => setOpen(false)} label="CLOSE" />
        </Grid>
      </Grid>
      <FormPDF data={{ formDataJson: formData?.formDataJson }} viewPdf />
    </Dialog>
  );
};

PDFDialog.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
  formData: REPORT_FORM_PROP,
  onClose: func.isRequired
};

PDFDialog.defaultProps = {
  formData: {}
};

export default PDFDialog;
