import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { WIP_REPORT_FRAGMENT } from '../fragments/wipReportFragment';

const SYNC_WIP_REPORT = gql`
  ${WIP_REPORT_FRAGMENT}
  mutation syncWIPReport($input: any) {
    syncWIPReport(input: $input)
      @rest(type: "WIPReport", path: "wipreports/sync", endpoint: "pmapi", method: "POST") {
      ...WIPReportFragment
    }
  }
`;

export const useSyncWIPReport = (options = {}) => {
  return useExtendedMutation(SYNC_WIP_REPORT, {
    serializer: wipReportId => ({ variables: { input: { wipReportId } } }),
    transform: data => data?.syncWIPReport,
    ...options
  });
};
