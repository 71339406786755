import gql from 'graphql-tag';

import useExtendedLazyQuery from 'customHooks/useExtendedLazyQuery';

export const getCustomerRepsForPropertyById = gql`
  query getCustomerPropertyInfoById($id: String!) {
    getCustomerPropertyById(id: $id) {
      id
      customerReps(entityConnection: "CustomerRep") {
        items {
          mappedEntity {
            ... on CustomerRep {
              id
              sortKey
              name
              firstName
              lastName
              bestContact
              email
              cellPhone
              landlinePhone
            }
          }
        }
      }
    }
  }
`;

const transform = data =>
  data?.getCustomerPropertyById?.customerReps?.items
    ?.map(rep => {
      const repData = rep?.mappedEntity;
      if (repData) {
        return {
          label: repData?.name || `${repData?.firstName || ''} ${repData?.lastName || ''}`,
          value: repData.sortKey,
          repData
        };
      }
      return null;
    })
    ?.filter(Boolean) || [];

const serializer = ({ propertyId }) => ({
  variables: { id: propertyId },
  skip: !propertyId
});

export const useLazyCustomerRepOptionsByPropertyId = () =>
  useExtendedLazyQuery(getCustomerRepsForPropertyById, {
    transform,
    serializer,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

export default useLazyCustomerRepOptionsByPropertyId;
