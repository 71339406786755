/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { pick } from 'lodash';
import { connect } from 'react-redux';

import { Context, DefaultButton, ImageUpload, SectionHeader, SergeantModal } from 'components';
import PlacesSearch from 'components/BuildHeroFormComponents/PlacesSearch';
import ImageComponent from 'components/ImageComponent';
import ResponsiveTable from 'components/ResponsiveTable';
import Labels from 'meta/labels';
import { departmentModalForm, departmentRowsMeta } from 'meta/Settings/Company/Department';
import ToolTips from 'meta/toolTips';
import { setOpenQuickAddModal, snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import AmplifyService from 'services/AmplifyService';
import { CompanyService, QuickbooksService, TenantService } from 'services/core';
import { Logger } from 'services/Logger';
import { PermissionConstants } from 'utils/AppConstants';
import { AccountingApp, FormVersion, IntacctDimentionType, Mode } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { constructSelectOptions, filterIsActive } from '../../../../utils/constructSelectOptions';

import EmployeesView from './EmployeesView';
import getIntacctDimensionsForCompany from './query/getIntacctDimensionsForCompany';
import SkillsView from './SkillsView';

class DepartmentSection extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.CompanyService = new CompanyService();
    this.QuickbooksService = new QuickbooksService();
    this.TenantService = new TenantService();
    this.api = AmplifyService.appSyncClient();
    this.departmentNames = new Map();
    this.sectionHeaderButton = [
      <DefaultButton handle={() => this.handleRowActions(Mode.ADD, {})} label="Add Department" />
    ];
    this.state = {
      refreshCounter: 0,
      departmentsList: [],
      modal: {
        open: false,
        data: null,
        dataType: 'Department',
        mode: null,
        handlePrimaryAction: () => console.log('no primary action set'),
        formVersion: ''
      },
      hasFetched: false,
      isQuickbooksEnabled: false,
      isSpectrumEnabled: false,
      isIntacctEnabled: false,
      intacctDimensions: [],
      classes: [],
      skillOptions: []
    };
  }

  contextUpdate = () => {
    Logger.debug('Context is refreshed');
  };

  getAccountingDetails = async () => {
    const { accountingApp } = this.props.settings;
    const isQuickbooksEnabled = accountingApp === AccountingApp.QUICKBOOKS;
    const isIntacctEnabled = accountingApp === AccountingApp.INTACCT;
    const isSpectrumEnabled = accountingApp === AccountingApp.SPECTRUM;

    if (isQuickbooksEnabled || isIntacctEnabled || isSpectrumEnabled) {
      try {
        const classesResponse = await this.QuickbooksService.getClasses(
          `${this.props.user.tenantId}`,
          `${this.props.user.tenantId}_Company_${this.props.user.tenantCompanyId}`
        );
        const classesData = classesResponse?.data?.getCompany?.classes?.items;
        const classesOptions = constructSelectOptions(
          classesData,
          'name',
          isIntacctEnabled ? 'id' : 'accountingRefId'
        );
        this.setState(prevState => ({
          ...prevState,
          isQuickbooksEnabled,
          isSpectrumEnabled,
          classes: classesOptions
        }));
      } catch (error) {
        Logger.error(error);
      }
    }
    if (isIntacctEnabled) {
      const { user } = this.props;
      let nextToken = null;
      try {
        do {
          const { tenantId, tenantCompanyId } = user;
          const params = {
            partitionKey: tenantId,
            sortKey: `${tenantId}_Company_${tenantCompanyId}`,
            nextToken
          };
          // eslint-disable-next-line no-await-in-loop
          const { data } = await this.api.query(getIntacctDimensionsForCompany, params);
          if (data) {
            const intacctDimensions = data?.getCompany?.dimensions?.items || [];
            if (nextToken) {
              this.setState(prevState => ({
                isIntacctEnabled: true,
                intacctDimensions: [...intacctDimensions, ...(prevState[intacctDimensions] || [])]
              }));
            } else {
              this.setState(prevState => ({
                ...prevState,
                isIntacctEnabled: true,
                intacctDimensions: [...intacctDimensions]
              }));
            }
            // override with new next token
            nextToken = data.getCompany?.dimensions?.nextToken;
          }
        } while (nextToken);
      } catch (error) {
        Logger.error(error);
      }
    }
  };

  componentDidMount = async () => {
    if (this.mounted) {
      this.getAllDepartments();
      this.getAccountingDetails();
    }
  };

  componentDidUpdate = prevProps => {
    // if company skills has been updated, fetch updated skills
    if (prevProps.companySkillsUpdatedCount !== this.props.companySkillsUpdatedCount) {
      this.getAllDepartments();
    }
    if (prevProps.settings?.accountingApp !== this.props.settings?.accountingApp) {
      this.getAccountingDetails();
    }
  };

  // process array of addresses from getAllDepartments
  processQueryAddresses = (localRecord, addresses) => {
    const record = localRecord;
    addresses.forEach(address => {
      if (address.addressType === 'billingAddress') {
        record.billingAddressLine1 = address.addressLine1;
        record.billingAddressLine2 = address.addressLine2;
        record.billingCity = address.city;
        record.billingState = address.state;
        record.billingZipcode = address.zipcode;
        record.billingAddressId = address.id;
        record.billingAddressVersion = address.version;
      } else if (address.addressType === 'shippingAddress') {
        record.addressLine1 = address.addressLine1;
        record.addressLine2 = address.addressLine2;
        record.city = address.city;
        record.state = address.state;
        record.zipcode = address.zipcode;
        record.shippingAddressId = address.id;
        record.shippingAddressVersion = address.version;
      }
    });
  };

  // process data to be added to departmentsList in state
  processQueryData = data => {
    // get query data
    const queryResult = data.getCompany;
    const { classes } = this.state;
    // iterate departments list, find the count of skills within each department, assign all other variables
    return queryResult.departmentsView?.items?.map(department => {
      const localRecord = {};
      localRecord.tagName = department.tagName;
      localRecord.sortKey = department.sortKey;
      localRecord.partitionKey = department.partitionKey;
      localRecord.id = department.id;
      localRecord.skillCount = department.skills.items.length || 0;
      localRecord.employeeCount = department.contacts.items.length || 0;
      localRecord.employees = department.contacts.items;
      localRecord.skills = department.skills?.items?.map(skill => skill.mappedEntity.tagName);
      localRecord.version = department.version;
      localRecord.logoUrl = department.logoUrl;
      localRecord.phonePrimary = department.phonePrimary;
      localRecord.email = department.email;
      localRecord.accountingRefIdOfClass = department.accountingRefIdOfClass;
      localRecord.intacctClassId = department.intacctClassId;
      localRecord.intacctClassName = department.classDimension?.name;
      localRecord.intacctDepartmentId = department.intacctDepartmentId;
      localRecord.intacctDepartmentName = department.departmentDimension?.name;
      localRecord.intacctEntityId = department.intacctEntityId;
      localRecord.intacctEntityName = department.entityDimension?.name;
      localRecord.intacctLocationId = department.intacctLocationId;
      localRecord.intacctLocationName = department.locationDimension?.name;
      localRecord.className = classes.find(
        c => c.value === department.accountingRefIdOfClass
      )?.label;
      localRecord.ADPExportDepartmentId = department.ADPExportDepartmentId;
      localRecord.ADPExportClassId = department.ADPExportClassId;
      localRecord.ADPExportLocationCode = department.ADPExportLocationCode;
      this.processQueryAddresses(localRecord, department.companyAddresses.items);
      return localRecord;
    });
  };

  getAllDepartments = async () => {
    const { user } = this.props;
    if (!user.tenantId) return;
    const sortKey = `${user.tenantId}_Company_${user.tenantCompanyId}`;
    try {
      const { data } = await this.CompanyService.getCompanyWithDepartmentSKill(
        user.tenantId,
        sortKey
      );
      if (data?.getCompany) {
        const { departmentsView, skills, ...companyData } = data.getCompany;
        const skillOptions = skills?.items?.map(skill => ({
          label: skill.tagName,
          value: skill.tagName
        }));
        const departmentsList = this.processQueryData(data);
        if (departmentsView && this.mounted) {
          this.setState({
            companyData,
            departmentsList,
            skillOptions,
            skillsInfo: skills
          });
        }
      }
    } catch (error) {
      this.props.snackbarOn('error', 'Unable to fetch Company data, please try again later', error);
    } finally {
      this.setState({
        hasFetched: true
      });
    }
  };

  handleRowActions = (actionName, record) => {
    const { modal } = this.state;
    const updatedModal = { ...modal };
    updatedModal.open = true;
    updatedModal.data = record;
    updatedModal.mode = actionName;
    updatedModal.data.accountNumber = 'Account Number';

    switch (actionName) {
      case Mode.VIEW: {
        updatedModal.formVersion = FormVersion.DEFAULT;
        break;
      }
      case Mode.EDIT: {
        updatedModal.handlePrimaryAction = this.handleEditAction;
        updatedModal.formVersion = FormVersion.EDIT;
        break;
      }
      case Mode.DELETE: {
        updatedModal.handlePrimaryAction = this.handleDeleteAction;
        break;
      }
      default: {
        updatedModal.handlePrimaryAction = this.handleAddAction;
        updatedModal.formVersion = FormVersion.EDIT;
      }
    }
    this.setState({ modal: updatedModal });
  };

  // process data for adding or updating departments queries
  processCompanyAddressInput = record => {
    const { tenantId } = this.props.user;
    const companyAddresses = [];
    const address = {};
    const billingAddress = {};
    // process shipping address information
    address.addressLine1 = record.addressLine1;
    address.addressLine2 = record.addressLine2;
    address.city = record.city;
    address.state = record.state;
    address.zipcode = record.zipcode;
    address.tenantId = tenantId;
    address.latitude = record.latitude;
    address.longitude = record.longitude;
    address.addressType = 'shippingAddress';
    address.entityType = 'CompanyAddress';
    address.id = record.shippingAddressId;
    address.version = record.shippingAddressVersion;
    companyAddresses.push(address);
    // process billing address information
    billingAddress.addressLine1 = record.billingAddressLine1;
    billingAddress.addressLine2 = record.billingAddressLine2;
    billingAddress.city = record.billingCity;
    billingAddress.state = record.billingState;
    billingAddress.zipcode = record.billingZipcode;
    billingAddress.tenantId = tenantId;
    billingAddress.latitude = record.billingLatitude;
    billingAddress.longitude = record.billingLongitude;
    billingAddress.addressType = 'billingAddress';
    billingAddress.entityType = 'CompanyAddress';
    billingAddress.id = record.billingAddressId;
    billingAddress.version = record.billingAddressVersion;
    companyAddresses.push(billingAddress);
    return companyAddresses;
  };

  processSkillsData = record => {
    const { skillsInfo } = this.state;
    const skillList =
      record?.skills?.map(skill => {
        const item = skillsInfo.items?.find(i => i.tagName === skill);
        return {
          id: item.id,
          tagName: skill,
          tagType: 'skill'
        };
      }) || [];
    return skillList;
  };

  processCompanyDepartmentInput = record => {
    return pick(record, ['tagName', 'id', 'logoUrl', 'phonePrimary', 'email']);
  };

  processEditDepartmentData = record => {
    const { isIntacctEnabled } = this.state;
    const data = this.processCompanyDepartmentInput(record);
    data.version = record.version; // version only needed in edit department
    data.companyAddresses = this.processCompanyAddressInput(record);
    data.accountingRefIdOfClass = record.accountingRefIdOfClass;
    data.skills = this.processSkillsData(record);
    if (isIntacctEnabled) {
      // if the value is empty string '', save null instead
      data.intacctEntityId = record.intacctEntityId || null;
      data.intacctClassId = record.intacctClassId || null;
      data.intacctDepartmentId = record.intacctDepartmentId || null;
      data.intacctLocationId = record.intacctLocationId || null;
    }
    if (this.props.flags[FeatureFlags.ADP_EXPORT]) {
      data.ADPExportDepartmentId = record.ADPExportDepartmentId || null;
      data.ADPExportClassId = record.ADPExportClassId || null;
      data.ADPExportLocationCode = record.ADPExportLocationCode || null;
    }
    return data;
  };

  processAddDepartmentData = record => {
    const data = {};
    const { isIntacctEnabled, isADPExportEnabled } = this.state;
    const { tenantCompanyId } = this.props.user;
    data.companyId = tenantCompanyId;
    data.departments = this.processCompanyDepartmentInput(record);
    data.departments.companyAddresses = this.processCompanyAddressInput(record);
    data.departments.accountingRefIdOfClass = record.accountingRefIdOfClass;
    data.departments.skills = this.processSkillsData(record);
    if (isIntacctEnabled) {
      data.departments.intacctEntityId = record.intacctEntityId || null;
      data.departments.intacctClassId = record.intacctClassId || null;
      data.departments.intacctDepartmentId = record.intacctDepartmentId || null;
      data.departments.intacctLocationId = record.intacctLocationId || null;
    }
    if (this.props.flags[FeatureFlags.ADP_EXPORT]) {
      data.departments.ADPExportDepartmentId = record.ADPExportDepartmentId || null;
      data.departments.ADPExportClassId = record.ADPExportClassId || null;
      data.departments.ADPExportLocationCode = record.ADPExportLocationCode || null;
    }
    return data;
  };

  // assigns the id and version from the request return data
  updateVersions = (record, data) => {
    const { version, companyAddresses } = data;
    const newRecord = { ...record };
    newRecord.version = version;
    companyAddresses.items.forEach(address => {
      if (address.addressType === 'billingAddress') {
        newRecord.billingAddressId = address.id;
        newRecord.billingAddressVersion = address.version;
      } else if (address.addressType === 'shippingAddress') {
        newRecord.shippingAddressId = address.id;
        newRecord.shippingAddressVersion = address.version;
      }
    });
    return newRecord;
  };

  // initialize properties of the new record for add department action
  initLocalDepartment = (record, data) => {
    const { id } = data;
    let newRecord = { ...record, id, skillCount: record?.skills?.length || 0, employeeCount: 0 };
    newRecord = this.updateVersions(newRecord, data);
    return newRecord;
  };

  handleAddAction = async (record, stopLoadingModal) => {
    const { departmentsList } = this.state;
    const { tenantId } = this.props.user;
    const updatedList = [...departmentsList];
    if (record?.tagName) {
      const processedRecord = this.processAddDepartmentData(record);
      try {
        const { data } = await this.CompanyService.addDepartmentsToCompany(
          tenantId,
          processedRecord
        );
        if (data) {
          this.props.snackbarOn(
            'success',
            `Successfully added department: ${record.tagName || ''}`
          );
          const newRecord = this.initLocalDepartment(record, data.addDepartmentsToCompany[0]);
          updatedList.unshift(newRecord);
          this.setState({ departmentsList: updatedList });
          Context.setCompanyContext(
            tenantId,
            Context.generateCompanyContextSortKey(this.props.user),
            this.contextUpdate,
            true
          );
          this.props.setDepartmentsUpdatedCount();
        }
      } catch (error) {
        Logger.debug(error);
        this.props.snackbarOn('error', 'Unable to add department, please try again later', error);
      }
    }
    stopLoadingModal();
    this.handleModalClose();
    this.getAllDepartments();
  };

  validateSkillDelete = data => {
    let valid = true;
    const { skills, employees } = this.state.modal.data;
    const deletedSkills = skills?.filter(skill => data.skills.indexOf(skill) === -1) || [];
    let errorMsg = 'Cannot remove skills currently being assigned to employees,';

    if (employees?.length && deletedSkills.length) {
      employees.forEach(employee => {
        deletedSkills.forEach(deletedSkill => {
          const index = employee.mappedEntity?.skills?.items?.findIndex(
            skill => skill.mappedEntity.tagName === deletedSkill
          );
          if (index !== -1) {
            const { firstName, lastName } = employee.mappedEntity;
            valid = false;
            errorMsg += `\n Skill: ${deletedSkill}, `;
            errorMsg += `Employee: ${firstName} ${lastName}, `;
          }
        });
      });
    }
    if (!valid) {
      errorMsg += '\n Please reassign Employee skills before removing them in Department.';
      this.props.snackbarOn('error', errorMsg);
    }
    return valid;
  };

  // update department handler
  handleEditAction = async (record, stopLoadingModal) => {
    const { departmentsList } = this.state;
    const { tenantId } = this.props.user;
    const updatedList = [...departmentsList];
    const isSkillValidated = this.validateSkillDelete(record);
    if (record?.tagName && isSkillValidated) {
      const processedRecord = this.processEditDepartmentData(record);
      try {
        const { data } = await this.CompanyService.updateDepartment(tenantId, processedRecord);
        if (data) {
          this.props.snackbarOn(
            'success',
            `Successfully update department: ${record.tagName || ''}`
          );
          const newRecord = this.updateVersions(record, data.updateDepartment);
          const indexOfItem = updatedList.findIndex(i => i.id === record.id);
          newRecord.skillCount = record?.skills?.length || 0;
          updatedList[indexOfItem] = newRecord;
          this.setState({ departmentsList: updatedList });
          Context.setCompanyContext(
            tenantId,
            Context.generateCompanyContextSortKey(this.props.user),
            this.contextUpdate,
            true
          );
          this.props.setDepartmentsUpdatedCount();
        }
      } catch (error) {
        Logger.debug(error);
        this.props.snackbarOn(
          'error',
          'Unable to update department, please try again later',
          error
        );
      }
    }
    stopLoadingModal();
    this.handleModalClose();
    this.getAllDepartments();
  };

  // delete department handler
  handleDeleteAction = async (record, stopLoadingModal) => {
    const { departmentsList } = this.state;
    const updatedList = [...departmentsList];
    if (record?.id) {
      const { id } = record;
      const { tenantId } = this.props.user;
      try {
        const { data } = await this.CompanyService.softDeleteDepartment(tenantId, id);
        if (data) {
          this.props.snackbarOn(
            'success',
            `Successfully deleted department: ${record.tagName || ''}`
          );
          const indexOfItem = updatedList.findIndex(i => i.id === id);
          updatedList.splice(indexOfItem, 1);
          this.setState({ departmentsList: updatedList });
          Context.setCompanyContext(
            tenantId,
            Context.generateCompanyContextSortKey(this.props.user),
            this.contextUpdate,
            true
          );
          this.props.setDepartmentsUpdatedCount();
        }
      } catch (error) {
        Logger.debug(error);
        this.props.snackbarOn(
          'error',
          'Unable to delete department, please try again later',
          error
        );
      }
    }
    stopLoadingModal();
    this.handleModalClose();
  };

  handleModalClose = () => {
    const { modal } = this.state;
    const updatedModal = { ...modal };
    updatedModal.open = false;
    updatedModal.sameAddress = false;
    this.setState({ modal: updatedModal });
  };

  setCustomControls = () => {
    const imageControlWithLocalSource = values => {
      const fileUrl = values.displayValue;
      const image = {
        name: 'Asset Image',
        fileUrl
      };
      const style = {
        width: 'auto',
        objectFit: 'contain'
      };
      if (fileUrl !== 'logoUrl' || '') {
        return <ImageComponent height="150" image={image} style={style} />;
      }
      return <div />;
    };

    const LogoButton = ({ form, field }) => {
      return <ImageUpload field={field} form={form} label="Add Image" user={this.props.user} />;
    };

    const adaptedPlacesSearch = ({ form, field, options }) => {
      const valuesToSet = [
        {
          addressLine1: 'addressLine1',
          city: 'city.shortName',
          state: 'state.shortName',
          zipcode: 'zipcode.shortName',
          latitude: 'latitude',
          longitude: 'longitude'
        }
      ];
      const adaptedOptions = { ...options, valuesToSet };
      return (
        <PlacesSearch field={field} form={form} options={adaptedOptions} value={field.value} />
      );
    };

    const billingAdaptedPlacesSearch = ({ form, field, options }) => {
      const valuesToSet = [
        {
          billingAddressLine1: 'addressLine1',
          billingCity: 'city.shortName',
          billingState: 'state.shortName',
          billingZipcode: 'zipcode.shortName',
          billingLatitude: 'latitude',
          billingLongitude: 'longitude'
        }
      ];
      const adaptedOptions = { ...options, valuesToSet };
      return (
        <PlacesSearch field={field} form={form} options={adaptedOptions} value={field.value} />
      );
    };

    const customControls = {
      ImageControl: imageControlWithLocalSource,
      LogoButton,
      PlacesSearch: adaptedPlacesSearch,
      BillingPlacesSearch: billingAdaptedPlacesSearch,
      SkillsView,
      EmployeesView
    };
    return customControls;
  };

  getIntacctOptions = (type, selectedId) => {
    const { intacctDimensions } = this.state;
    return filterIsActive(
      constructSelectOptions(
        intacctDimensions.filter(x => x.type === type),
        'name',
        'id'
      ),
      selectedId
    );
  };

  getFormOptions = () => {
    const {
      skillOptions,
      classes,
      isQuickbooksEnabled,
      isSpectrumEnabled,
      isIntacctEnabled,
      modal
    } = this.state;
    return {
      classes,
      isQuickbooksEnabled,
      isIntacctEnabled,
      isSpectrumEnabled,
      isADPExportEnabled: this.props.flags[FeatureFlags.ADP_EXPORT],
      intactEntityOptions: this.getIntacctOptions(
        IntacctDimentionType.ENTITY,
        modal?.data?.intacctEntityId
      ),
      intactDepartmentOptions: this.getIntacctOptions(
        IntacctDimentionType.DEPARTMENT,
        modal?.data?.intacctDepartmentId
      ),
      intactLocationOptions: this.getIntacctOptions(
        IntacctDimentionType.LOCATION,
        modal?.data?.intacctLocationId
      ),
      companySkills: skillOptions
    };
  };

  render() {
    const { user } = this.props;
    const { departmentsList, refreshCounter, modal, hasFetched } = this.state;

    return (
      <ErrorBoundaries>
        <SectionHeader
          infoToolTip={ToolTips.departmentAndSkills}
          overrideHeaderButtons={this.sectionHeaderButton}
          title={Labels['Department and Skills'][user.locale]}
        />
        <ResponsiveTable
          caslKey={PermissionConstants.OBJECT_INVOICE}
          data={departmentsList}
          disableFilter
          isLoading={!hasFetched}
          key={refreshCounter}
          noDataMsg="No Departments"
          rowActionButtons={{
            view: {
              label: 'View',
              icon: 'Launch'
            },
            edit: {
              label: 'Edit',
              icon: 'EditOutlined'
            },
            delete: {
              label: 'Delete',
              icon: 'DeleteOutlined'
            }
          }}
          rowActions={this.handleRowActions}
          rowMetadata={departmentRowsMeta}
          showToolbars
        />
        <SergeantModal
          customComponents={this.setCustomControls()}
          data={modal.data}
          dataType={modal.dataType}
          formVersion={modal.formVersion}
          handleClose={this.handleModalClose}
          handlePrimaryAction={modal.handlePrimaryAction}
          layout={departmentModalForm(this.getFormOptions())}
          mode={modal.mode}
          open={modal.open}
        />
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  settings: state.settings,
  company: state.company
});

const mapDispatcherToProps = { snackbarOn, setOpenQuickAddModal };
export default connect(mapStateToProps, mapDispatcherToProps)(withLDConsumer()(DepartmentSection));
