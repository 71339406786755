import AmplifyService from '../../../AmplifyService';
import updateDefaultLineItem from '../../graphql/common/mutations/updateDefaultLineItem';
import updateTenantSettingGql from '../../graphql/common/mutations/updateTenantSetting';
import SubscriptionClient from '../helper';

export default class TenantSettingService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
    this.subscriptionClient = SubscriptionClient.getClient(AmplifyService.config);
  }

  updateTenantSetting = async data => {
    const params = {
      data
    };

    const response = await this.api.mutate(updateTenantSettingGql, params);
    return response;
  };

  mutateDefaultLineItem = async data => {
    const response = await this.api.mutate(updateDefaultLineItem, data);
    return response?.data?.setDefaultLineItem;
  };
}
