import React from 'react';

import { Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import useStyles from './Header.style';

export default function Header({ isVisible, onNewItemClick, newItemLabel }) {
  const classes = useStyles();
  if (!isVisible) return null;

  return (
    <Grid
      alignItems="flex-end"
      className={classes.container}
      container
      direction="row"
      justify="flex-end"
    >
      <Grid item>
        <Button className={classes.newItem} variant="contained" onClick={onNewItemClick}>
          {newItemLabel}
        </Button>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  isVisible: PropTypes.bool,
  onNewItemClick: PropTypes.func.isRequired,
  newItemLabel: PropTypes.string.isRequired
};

Header.defaultProps = {
  isVisible: true
};
