import { useMemo } from 'react';

import { calculateMarginFromMarkup } from '@BuildHero/math';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import {
  actionButtonColumn,
  tableCurrencyFormatter,
  tableEmptyValueFormatter,
  tablePercentageFormatter
} from 'components/WrapTable';
import { convertForMathLib } from 'utils/mathLibrary';

import { useReviewReportDisabled } from '../../../../../../ReviewReport.contexts';

export const useBillLineItemColumns = ({ onEditClick, onDeleteClick, isMarginEnabled }) => {
  const { disabled } = useReviewReportDisabled();

  return useMemo(
    () => [
      {
        field: 'id',
        hide: true
      },
      {
        field: 'itemName',
        headerName: 'Name',
        valueFormatter: tableEmptyValueFormatter
      },
      {
        field: 'description',
        headerName: 'Description',
        valueFormatter: tableEmptyValueFormatter
      },
      {
        field: 'quantity',
        headerName: 'Quantity',
        valueFormatter: tableEmptyValueFormatter
      },
      {
        field: 'remainingQuantity',
        headerName: 'Quantity Not Yet Invoiced',
        valueFormatter: tableEmptyValueFormatter
      },
      {
        field: 'unitCost',
        headerName: 'Unit Cost',
        valueFormatter: tableCurrencyFormatter
      },
      isMarginEnabled
        ? {
            field: 'markup',
            headerName: 'Margin',
            valueFormatter: tablePercentageFormatter,
            valueGetter: ({ row }) =>
              isMarginEnabled
                ? convertForMathLib(calculateMarginFromMarkup, row.markup)
                : row.markup
          }
        : {
            field: 'markup',
            headerName: 'Markup',
            valueFormatter: tablePercentageFormatter
          },
      {
        field: 'unitPrice',
        headerName: 'Unit Price',
        valueFormatter: tableCurrencyFormatter
      },
      {
        field: 'amount',
        headerName: 'Total Price',
        valueGetter: ({ row }) => row.quantity * row.unitCost * (1 + row.markup / 100),
        valueFormatter: tableCurrencyFormatter
      },
      actionButtonColumn({
        actions: [
          {
            label: 'Edit',
            icon: EditIcon,
            disabled,
            onClick: row => onEditClick(row)
          },
          {
            label: 'Delete',
            icon: DeleteIcon,
            disabled,
            onClick: row => onDeleteClick(row)
          }
        ]
      })
    ],
    [disabled, onEditClick, onDeleteClick]
  );
};
