import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, ButtonType, Modal, ThemeProvider } from '@BuildHero/sergeant';
import { findKey, isEmpty } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';

import { TagButtons, UserPermission } from 'components';
import AttachItem from 'components/AttachmentSection/AttachItem';
import AlgoliaSearchWrapper from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import LeftSidebarWithContent from 'components/Layouts/LeftSidebarWithContent';
import useEmployees from 'customHooks/useEmployees';
import { useMutationSubscription } from 'customHooks/useMutationSubscription';
import usePayrollSettings from 'customHooks/usePayrollSettings';
import useServiceAgreementsSettings from 'customHooks/useServiceAgreementsSettings';
import { snackbarOn } from 'redux/actions/globalActions';
import { ServiceAgreement } from 'services/core';
import { Logger } from 'services/Logger';
import theme from 'themes/BuildHeroTheme';

import { checkPermission, toTitleCase } from 'utils';
import { PermissionConstants, ServiceAgreementStatus, TagType } from 'utils/AppConstants';
import compose from 'utils/compose';
import { EntityType, Mode } from 'utils/constants';
import { constructSelectOptions } from 'utils/constructSelectOptions';

import { usePricebookById } from '../../RecommendedTasks/queries';

import { AGREEMENT_ACTIONS, BILLING_TYPES, ERROR_MESSAGES } from './constants';
import Attachments from './CustomComponents/Attachments';
import CloneAgreementModal from './CustomComponents/CloneAgreementModal/index';
import {
  MarkUpDescription,
  Title,
  TitlePostText,
  TitleSubText
} from './CustomComponents/CustomTitles';
import { LinkButtonForm, LinkListForm } from './CustomComponents/ListAttachment';
import PreferredTechnicians from './CustomComponents/PreferredTechnicians';
import Properties from './CustomComponents/Properties/index';
import { SAEditNameModal } from './CustomComponents/SAEditNameModal';

import {
  formatForLeftSection,
  formatForMainSection,
  getDepartments,
  getHeaderProps,
  getStatusBasedMoreActionBtns,
  serviceAgreementValidation,
  setBillingAddress,
  setSameAsCustomer,
  updateServiceAgreementPayload
} from './helpers';
import { useUpdateAdvancedServiceAgreement } from './hooks/useUpdateAdvancedServiceAgreement';

import { mainSection, sidebarSection } from './layout';
import { getServiceAgreementById, transitionServiceAgreement } from './service';
import ServiceAgreementsTabs from './ServiceAgreementsTabs';
import { RenewModal } from './ServiceAgreementsTabs/Renewals/RenewModal';

const DetailView = ({ user, snackbar, computedMatch, history }) => {
  const { id: agreementId, mode } = computedMatch?.params || {};

  const [renewModalIsOpen, setRenewModalIsOpen] = useState(false);
  const [agreementInfo, setAgreementInfo] = useState({});
  const [agreementName, setAgreementName] = useState();
  const [editSANameModal, setEditSANameModal] = useState(false);
  const [showActivateAdvancedAgreementModal, setShowActivateAdvancedAgreementModal] = useState(
    false
  );
  const currentDate = moment(new Date());
  const [formData, setFormData] = useState({
    startDate: currentDate,
    endDate: currentDate,
    billingType: agreementInfo?.billing
  });

  const [selectedProperties, setSelectedProperties] = useState([]);
  const [preferredTechnicians, setPreferredTechnicians] = useState(
    agreementInfo.preferredTechnicians
  );
  const [showCloneAgreementModal, setShowCloneAgreementModal] = useState(false);
  const newUpdates = useMutationSubscription(
    user.tenantId,
    agreementInfo.id,
    EntityType.SERVICE_AGREEMENT,
    ['status']
  );

  const { data: pricebookData } = usePricebookById(agreementInfo?.pricebookId);

  const [payrollSettings] = usePayrollSettings();
  const companyTimeZone = payrollSettings?.timeZone; // when undefined, timezone will not be applied in sg date input

  const [soldByDropdown] = useEmployees({
    filter: { isActive: { eq: true } },
    transform: activeEmployees => constructSelectOptions(activeEmployees, 'name')
  });

  const currentStatus = agreementInfo?.advancedScheduling
    ? toTitleCase(agreementInfo?.advancedSchedulingState?.toLowerCase())
    : newUpdates?.status || agreementInfo?.status;

  document.title = `Service Agreement - ${agreementInfo.agreementName}`;

  const userLocale = user.locale;

  const headerProps = useMemo(
    () => ({
      ...getHeaderProps(agreementName, userLocale),
      editTitleAction: mode === Mode.EDIT ? () => setEditSANameModal(true) : null
    }),
    [agreementName, mode, userLocale]
  );

  const isActivating = currentStatus === ServiceAgreementStatus.ACTIVATING;
  const isActivated = currentStatus === ServiceAgreementStatus.ACTIVE;
  const isDraft = currentStatus === ServiceAgreementStatus.DRAFT;
  const isApproved = currentStatus === ServiceAgreementStatus.APPROVED;
  const isCancelled =
    currentStatus === ServiceAgreementStatus.CANCELLED ||
    currentStatus === ServiceAgreementStatus.CANCELED;
  const isExpired = currentStatus === ServiceAgreementStatus.EXPIRED;
  const statusColor = isDraft ? theme.palette.grayscale(20) : theme.palette.brand.green;
  const end = moment.unix(agreementInfo?.endDate);
  const daysUntilExpiration = end.diff(moment(), 'days');
  const hasPermissionToEdit = checkPermission(
    'edit',
    PermissionConstants.OBJECT_SERVICE_AGREEMENT,
    user
  );

  const getServiceAgreement = useCallback(
    async id => {
      const agreementData = (await getServiceAgreementById(id, user)) || {};
      setAgreementInfo(sa => ({
        ...sa,
        ...agreementData
      }));
      setAgreementName(agreementData?.agreementName);
      setPreferredTechnicians(agreementData.preferredTechnicians);
    },
    [user]
  );

  const serviceAgreementsSettings = useServiceAgreementsSettings(user);

  const [
    updateAdvancedServiceAgreement,
    { loading: updatingAdvancedServiceAgreement }
  ] = useUpdateAdvancedServiceAgreement({
    serviceAgreementId: agreementInfo.id,
    onSuccess: newData => {
      setShowActivateAdvancedAgreementModal(false);
      setAgreementInfo(ai => ({
        ...ai,
        advancedSchedulingState: newData?.updateAdvancedServiceAgreement?.advancedSchedulingState
      }));
      getServiceAgreement(agreementInfo.id);
    }
  });

  useEffect(() => {
    const fetchAgreement = recordId => {
      getServiceAgreement(recordId);
    };

    fetchAgreement(agreementId);
  }, [agreementId, user, getServiceAgreement]);

  useEffect(() => {
    if (!isEmpty(agreementInfo)) {
      const { propertiesJSON } = agreementInfo;
      if (!isEmpty(propertiesJSON)) {
        let selectedCustomerProperties = [];
        try {
          selectedCustomerProperties = JSON.parse(propertiesJSON);
        } catch (e) {
          Logger.error('Unable to parse propertiesJSON for Service agreement', e);
        } finally {
          setSelectedProperties(selectedCustomerProperties);
        }
      }
    }
  }, [agreementInfo]);

  const onUpdatePreferredTechnicians = async newPreferredTechnicians => {
    setPreferredTechnicians(newPreferredTechnicians);
  };

  const onFormChange = data => {
    if (!isEmpty(data)) {
      setFormData(data);
    }
  };

  const handleLeftSectionFieldChange = (fieldKey, fieldValue) => {
    if (fieldKey === 'selectedProperties') {
      setSelectedProperties(fieldValue);
    }
  };

  const leftSectionProps = {
    configuration: sidebarSection({
      setBillingAddress,
      onUpdatePreferredTechnicians,
      setSameAsCustomer,
      agreementInfo
    }),
    onFieldChange: handleLeftSectionFieldChange,
    data: {
      ...formatForLeftSection(agreementInfo || {}, mode, user, snackbar, isCancelled),
      preferredTechnicians: agreementInfo.preferredTechnicians
    },
    customComponents: {
      Title,
      Properties,
      AlgoliaSearchWrapper,
      LinkButton: LinkButtonForm,
      LinkList: LinkListForm,
      PreferredTechnicians
    }
  };

  const departmentOptions = constructSelectOptions(getDepartments(), 'tagName', 'id');
  const mainSectionProps = {
    configuration: mainSection({
      agreementInfo,
      departmentOptions,
      formData,
      soldByList: soldByDropdown,
      companyTimeZone
    }),
    data: formatForMainSection(agreementInfo || {}, snackbar, isCancelled, companyTimeZone),
    onFormChange,
    customComponents: {
      Title,
      MarkUpDescription,
      Attachments,
      AttachItem
    },
    validationSchema: serviceAgreementValidation
  };

  const handlePrimaryAction = async allFormsData => {
    if (agreementInfo?.advancedScheduling) {
      setShowActivateAdvancedAgreementModal(true);
      return;
    }

    if (mode === Mode.VIEW && isExpired) {
      setShowCloneAgreementModal(true);
      return;
    }

    if (
      Number(allFormsData.startDate) &&
      Number(allFormsData.endDate) &&
      allFormsData.startDate > allFormsData.endDate
    ) {
      return snackbar('error', ERROR_MESSAGES[3]);
    }

    if (
      allFormsData.startDate &&
      allFormsData.firstBillDate &&
      allFormsData.startDate > allFormsData.firstBillDate &&
      allFormsData.billingType ===
        findKey(BILLING_TYPES, val => val === BILLING_TYPES.RecurringBilling)
    ) {
      return snackbar('error', ERROR_MESSAGES[6]);
    }

    let actionName;
    if (mode === Mode.VIEW && isApproved) {
      actionName = AGREEMENT_ACTIONS.ACTIVATE;
    }
    // if SA is active, then use updateServiceAgreement mutation - no transition
    // action name necessary
    if (mode === Mode.EDIT && !isActivated) {
      actionName = AGREEMENT_ACTIONS.UPDATE;
    }

    if (mode === Mode.EDIT && !isExpired) {
      if (allFormsData.endDate) {
        // Note: compare to end of day so that if we choose an SA endDate of "today"
        // we will not automatically expire the service agreement
        const endOfDayEndDate = moment.unix(allFormsData.endDate).endOf('day');
        if (endOfDayEndDate.diff(currentDate, 'seconds') < 0) {
          actionName = AGREEMENT_ACTIONS.EXPIRE;
        }
      }
    }

    // actionName specified if it's a SA state transition e.g. Draft => Approved
    if (actionName) {
      // TODO: instead of fixing the buttons on the
      const updatePayload = updateServiceAgreementPayload(
        allFormsData || agreementInfo,
        actionName === AGREEMENT_ACTIONS.ACTIVATE
      );
      await transitionServiceAgreement(actionName, updatePayload, user.tenantId, snackbar);
      history.push(`/serviceAgreement/view/${agreementId}`);
    } else {
      const updatePayload = updateServiceAgreementPayload(
        allFormsData || agreementInfo,
        isActivated
      );
      await transitionServiceAgreement(
        AGREEMENT_ACTIONS.UPDATE_INVOICE_SETTINGS,
        updatePayload,
        user.tenantId,
        snackbar
      );
      history.push(`/serviceAgreement/view/${agreementId}`);
    }
  };

  const actionButtonHandler = async action => {
    let actionName;

    if (agreementInfo?.advancedScheduling) {
      if (action === 'cancel') {
        updateAdvancedServiceAgreement({ serviceAgreementState: 'CANCELED' });
      }
      return;
    }

    if (action === 'renew') {
      return setRenewModalIsOpen(true);
    }

    if (action === Mode.EDIT) {
      const { location } = history;
      const newPath = location.pathname.replace(mode, Mode.EDIT);
      return history.push(newPath);
    }

    if (action === 'approve') {
      actionName = AGREEMENT_ACTIONS.APPROVE;
    } else if (action === 'cancel') {
      actionName = AGREEMENT_ACTIONS.CANCEL;
    } else if (action === 'clone') {
      setShowCloneAgreementModal(true);
      return;
    }

    const updatedServiceAgreement = await transitionServiceAgreement(
      actionName,
      { id: agreementInfo.id },
      user.tenantId,
      snackbar
    );

    if (updatedServiceAgreement?.status) {
      snackbar(
        'success',
        `Agreement( # ${agreementInfo?.agreementNumber || ''}) ${updatedServiceAgreement?.status ||
          ''}`
      );
    }

    if (updatedServiceAgreement) {
      setAgreementInfo(sa => ({
        ...sa,
        status: updatedServiceAgreement?.status,
        version: updatedServiceAgreement?.version
      }));
    }
  };

  const handleTabUpdates = data => {
    setAgreementInfo({ ...agreementInfo, ...data });
  };

  const handleRenewModalClose = () => setRenewModalIsOpen(false);
  const viewModeprimaryBtnLabel = isExpired ? 'Clone Agreement' : 'Activate Agreement';

  if (mode === Mode.EDIT && agreementInfo?.advancedScheduling)
    return <Redirect to={`/serviceAgreement/${Mode.EDIT_DRAFT}/${agreementId}`} />;

  const showPrimaryButton = () => {
    if (agreementInfo?.advancedScheduling) {
      return isDraft && hasPermissionToEdit;
    }

    return (
      !(
        (isDraft || isActivated || isCancelled || isActivating || !currentStatus) &&
        mode === Mode.VIEW
      ) && hasPermissionToEdit
    );
  };

  return (
    <UserPermission action={PermissionConstants.OBJECT_SERVICE_AGREEMENT} I="read">
      <LeftSidebarWithContent
        actionButtonHandler={actionButtonHandler}
        actionButtons={
          mode !== Mode.EDIT &&
          hasPermissionToEdit &&
          getStatusBasedMoreActionBtns({
            status: currentStatus,
            alreadyRenewed: agreementInfo?.isRenewed,
            advancedScheduling: agreementInfo?.advancedScheduling
          })
        }
        advancedScheduling={agreementInfo?.advancedScheduling}
        buttonLabel={
          isApproved || isDraft || isActivated
            ? {
                edit: 'Save',
                view: 'Edit Agreement'
              }
            : undefined
        }
        caslKey={PermissionConstants.OBJECT_SERVICE_AGREEMENT}
        defaultSubmitButton={btnProps =>
          showPrimaryButton() && (
            <ThemeProvider>
              <Button
                css={{ marginRight: 8 }}
                disabled={btnProps.disabled || agreementInfo?.advancedScheduling}
                loading={btnProps.showSpinner}
                type={ButtonType.PRIMARY}
                onClick={() => btnProps.handle()}
              >
                {mode === Mode.VIEW ? viewModeprimaryBtnLabel : 'Save'}
              </Button>
            </ThemeProvider>
          )
        }
        handleEdit={
          agreementInfo?.advancedScheduling
            ? () => history.push(history.location.pathname.replace(mode, Mode.EDIT_DRAFT))
            : undefined
        }
        handleFormsSubmit={handlePrimaryAction}
        headerProps={headerProps}
        isLoading={!agreementInfo?.id}
        leftSectionProps={leftSectionProps}
        mainSectionProps={mainSectionProps}
        mode={mode}
        pricebookId={agreementInfo?.pricebookId}
        pricebookName={pricebookData?.name}
        showCancelButton
        showSubmitButton={
          (isApproved || isDraft || (isActivated && !agreementInfo?.advancedScheduling)) &&
          mode === Mode.VIEW
        }
        statusBackgroundColor={statusColor}
        statusLabel={currentStatus}
        TagButtons={
          <TagButtons
            getService={() => new ServiceAgreement()}
            info={{
              id: agreementInfo?.id,
              version: agreementInfo?.version || 1
            }}
            tags={agreementInfo?.serviceAgreementServiceAgreementTags}
            TagType={TagType.SERVICE_AGREEMENT}
          />
        }
        TitlePostText={
          <TitlePostText
            display={isActivated && agreementInfo.endDate}
            text={daysUntilExpiration}
          />
        }
        TitleSubText={
          <TitleSubText text={`Agreement No: ${agreementInfo?.agreementNumber || '#'}`} />
        }
        userLocale={userLocale}
      >
        <ServiceAgreementsTabs
          agreementInfo={{
            ...agreementInfo,
            preferredTechnicians
          }}
          companyTimeZone={companyTimeZone}
          history={history}
          isAgreementActive={isActivated}
          isExpired={isExpired}
          isReadOnly={!(isApproved || isDraft || isActivated) || isCancelled || isExpired}
          selectedProperties={selectedProperties}
          serviceAgreementsSettings={serviceAgreementsSettings}
          onTabUpdate={handleTabUpdates}
        />
      </LeftSidebarWithContent>
      <CloneAgreementModal
        agreementInfo={agreementInfo}
        history={history}
        open={showCloneAgreementModal}
        snackbar={snackbar}
        user={user}
        onClose={() => setShowCloneAgreementModal(false)}
      />
      <RenewModal
        agreementInfo={agreementInfo}
        handleRenewModalClose={handleRenewModalClose}
        renewModalIsOpen={renewModalIsOpen}
        snackbar={snackbar}
        user={user}
      />
      <SAEditNameModal
        agreementInfo={agreementInfo}
        agreementName={agreementName}
        handleClose={() => setEditSANameModal(false)}
        open={editSANameModal}
        snackbar={snackbar}
        updateAgreementName={updatedName => setAgreementName(updatedName)}
      />
      <ThemeProvider>
        <Modal
          actions={
            <Button
              fullWidth
              loading={updatingAdvancedServiceAgreement}
              type={ButtonType.PRIMARY}
              onClick={() => updateAdvancedServiceAgreement({ serviceAgreementState: 'ACTIVE' })}
            >
              Activate Agreement
            </Button>
          }
          maxWidth={false}
          open={showActivateAdvancedAgreementModal}
          title="Activate Service Agreement"
          onClose={() => setShowActivateAdvancedAgreementModal(false)}
        >
          <div css={{ maxWidth: 347 }}>
            By activating this agreement you’ll lose the ability to add, remove, or edit assets and
            tasks on those assets. You’ll also create all maintenance jobs that occur within 90 days
            of today.
          </div>
        </Modal>
      </ThemeProvider>
    </UserPermission>
  );
};

export default compose(
  connect(state => ({ user: state.user, company: state.company }), { snackbar: snackbarOn })
)(DetailView);
