import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { theme, TV, Typography } from '@BuildHero/sergeant';
import { ButtonBase, Grid, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CancelIcon from '@material-ui/icons/Cancel';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';

import { Placeholder } from 'components';

import { useStyles } from './AttachmentItemPreview.style';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const bytesToMB = sizeInBytes => {
  const sizeInMB = _.round(sizeInBytes / 1024 ** 2, 1);
  if (sizeInMB < 1) {
    return `${_.round(sizeInBytes / 1024, 1)} KB`;
  }
  return `${sizeInMB} MB`;
};

const AttachmentItemPreview = ({
  fileData,
  removable = true,
  onRemoveFile,
  hideFileName = false,
  suppressRenderError = false
}) => {
  const { fileUrl, fileSize, fileName } = fileData;
  const [file, setFile] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const styles = useStyles();

  useEffect(() => {
    setFile({ url: fileUrl });
  }, [fileUrl]);

  const onDocumentLoadSuccess = useCallback(({ numPages: count }) => {
    setNumPages(count);
    setPageNumber(1);
  }, []);

  const LoadingPlaceholder = useMemo(
    () => (
      <Placeholder
        paddingBottom={4}
        paddingLeft={4}
        paddingRight={4}
        paddingTop={4}
        repeatCount={1}
        variant="card"
      />
    ),
    []
  );

  const handleRemoveFile = useCallback(() => {
    onRemoveFile('remove', fileData);
  }, [onRemoveFile, fileData]);

  return (
    <div css={styles.fileItemContainer} key={fileData.id}>
      <div>
        {removable && (
          <div css={styles.removeIconWrapper}>
            <ButtonBase css={styles.removeIconButton} onClick={handleRemoveFile}>
              <CancelIcon css={styles.removeIcon} />
            </ButtonBase>
          </div>
        )}
        <div css={styles.pdfContainer}>
          <Document
            {...(suppressRenderError ? { error: '' } : {})}
            file={file}
            loading={LoadingPlaceholder}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page height={125} pageNumber={pageNumber} />
          </Document>
        </div>
        {fileSize && fileName && (
          <div>
            <Grid container direction="row" justify="space-between">
              <Grid css={styles.stepperButtonContainer} item>
                <IconButton
                  css={styles.stepperButton}
                  disabled={pageNumber === 1}
                  size="small"
                  onClick={() => setPageNumber(index => index - 1)}
                >
                  <ArrowBackIcon style={{ fontSize: 16 }} />
                </IconButton>
              </Grid>
              <Grid css={styles.stepperButtonContainer} item>
                <IconButton
                  css={styles.stepperButton}
                  disabled={pageNumber === numPages}
                  size="small"
                  onClick={() => setPageNumber(index => index + 1)}
                >
                  <ArrowForwardIcon style={{ fontSize: 16 }} />
                </IconButton>
              </Grid>
            </Grid>
            <div css={styles.fileDescriptionContainer}>
              {!hideFileName && (
                <ButtonBase
                  style={{ marginBottom: 8 }}
                  onClick={() => window.open(fileUrl, '_blank')}
                >
                  <Typography underlined variant={TV.S1}>
                    {fileName}
                  </Typography>
                </ButtonBase>
              )}
              <Typography color={theme.palette.grayscale(50)} variant={TV.S1}>
                {bytesToMB(fileSize)}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

AttachmentItemPreview.propTypes = {
  fileData: PropTypes.object.isRequired,
  removable: PropTypes.bool,
  onRemoveFile: PropTypes.func.isRequired,
  hideFileName: PropTypes.bool,
  suppressRenderError: PropTypes.bool
};

AttachmentItemPreview.defaultProps = {
  removable: true,
  hideFileName: false,
  suppressRenderError: false
};

export default React.memo(AttachmentItemPreview);
