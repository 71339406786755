/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export const ProcurementIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M16.916129,13.8738428 C15.3795303,13.8738428 14.133871,12.655263 14.133871,11.1520686 C14.133871,9.64887422 15.3795303,8.4302944 16.916129,8.4302944 C18.4527277,8.4302944 19.6983871,9.64887422 19.6983871,11.1520686 C19.6943103,12.6536079 18.4510359,13.8698546 16.916129,13.8738428 L16.916129,13.8738428 Z M16.916129,10.2448105 C16.4039295,10.2448105 15.9887097,10.6510038 15.9887097,11.1520686 C15.9887097,11.6531334 16.4039295,12.0593267 16.916129,12.0593267 C17.4283286,12.0593267 17.8435484,11.6531334 17.8435484,11.1520686 C17.8435484,10.6510038 17.4283286,10.2448105 16.916129,10.2448105 L16.916129,10.2448105 Z"
      id="Shape"
    ></path>
    <path
      d="M5.19354839,13.8738428 C3.65694969,13.8738428 2.41129032,12.655263 2.41129032,11.1520686 C2.41129032,9.64887422 3.65694969,8.4302944 5.19354839,8.4302944 C6.73014709,8.4302944 7.97580645,9.64887422 7.97580645,11.1520686 C7.97172965,12.6536079 6.72845525,13.8698546 5.19354839,13.8738428 Z M5.19354839,10.2448105 C4.68134882,10.2448105 4.26612903,10.6510038 4.26612903,11.1520686 C4.26612903,11.6531334 4.68134882,12.0593267 5.19354839,12.0593267 C5.70574795,12.0593267 6.12096774,11.6531334 6.12096774,11.1520686 C6.12096774,10.6510038 5.70574795,10.2448105 5.19354839,10.2448105 L5.19354839,10.2448105 Z"
      id="Shape"
    ></path>
    <path
      d="M20.9596774,12.0593267 L18.7709677,12.0593267 C18.2587682,12.0593267 17.8435484,11.6531334 17.8435484,11.1520686 C17.8435484,10.6510038 18.2587682,10.2448105 18.7709677,10.2448105 L20.0322581,10.2448105 L20.0322581,6.00247182 L16.3225806,1.8145686 L13.8,1.8145686 L13.8,10.2448105 L15.0612903,10.2448105 C15.5734899,10.2448105 15.9887097,10.6510038 15.9887097,11.1520686 C15.9887097,11.6531334 15.5734899,12.0593267 15.0612903,12.0593267 L12.8725806,12.0593267 C12.3603811,12.0593267 11.9451613,11.6531334 11.9451613,11.1520686 L11.9451613,0.907310531 C11.9451613,0.406245738 12.3603811,4.97379915e-13 12.8725806,4.97379915e-13 L16.7306452,4.97379915e-13 C17.0011684,-0.0028080756 17.2591564,0.111426865 17.4354839,0.312149241 L21.6608065,5.08069763 C21.8026631,5.24278932 21.8826406,5.44800596 21.8870968,5.66134279 L21.8870968,11.1520686 C21.8870968,11.6531334 21.471877,12.0593267 20.9596774,12.0593267 L20.9596774,12.0593267 Z"
      id="Path"
    ></path>
    <path
      d="M12.8725806,12.0593267 L7.0483871,12.0593267 C6.53618753,12.0593267 6.12096774,11.6531334 6.12096774,11.1520686 C6.12096774,10.6510038 6.53618753,10.2448105 7.0483871,10.2448105 L12.8725806,10.2448105 C13.3847802,10.2448105 13.8,10.6510038 13.8,11.1520686 C13.8,11.6531334 13.3847802,12.0593267 12.8725806,12.0593267 L12.8725806,12.0593267 Z"
      id="Path"
    ></path>
    <path
      d="M3.33870968,12.0593267 L0.927419355,12.0593267 C0.415219788,12.0593267 -7.7172836e-14,11.6531334 -7.7172836e-14,11.1520686 L-7.7172836e-14,5.66134279 C-7.7172836e-14,5.160278 0.415219788,4.75408472 0.927419355,4.75408472 L20.9596774,4.75408472 C21.471877,4.75408472 21.8870968,5.160278 21.8870968,5.66134279 C21.8870968,6.16240758 21.471877,6.56860085 20.9596774,6.56860085 L1.85483871,6.56860085 L1.85483871,10.2448105 L3.33870968,10.2448105 C3.85090924,10.2448105 4.26612903,10.6510038 4.26612903,11.1520686 C4.26612903,11.6531334 3.85090924,12.0593267 3.33870968,12.0593267 L3.33870968,12.0593267 Z"
      id="Path"
    ></path>
    <path
      d="M4.72777778,3.75 L1.66111111,3.75 C1.44940196,3.75 1.27777778,3.58210678 1.27777778,3.375 L1.27777778,0.375 C1.27777778,0.167893219 1.44940196,0 1.66111111,0 L4.72777778,0 C4.93948693,0 5.11111111,0.167893219 5.11111111,0.375 L5.11111111,3.375 C5.11111111,3.58210678 4.93948693,3.75 4.72777778,3.75 Z M2.55555556,2.5 L3.83333333,2.5 L3.83333333,1.25 L2.55555556,1.25 L2.55555556,2.5 Z"
      id="Shape"
    ></path>
    <path
      d="M9.83888889,3.75 L6.77222222,3.75 C6.56051307,3.75 6.38888889,3.58210678 6.38888889,3.375 L6.38888889,0.375 C6.38888889,0.167893219 6.56051307,0 6.77222222,0 L9.83888889,0 C10.050598,0 10.2222222,0.167893219 10.2222222,0.375 L10.2222222,3.375 C10.2222222,3.58210678 10.050598,3.75 9.83888889,3.75 Z M7.66666667,2.5 L8.94444444,2.5 L8.94444444,1.25 L7.66666667,1.25 L7.66666667,2.5 Z"
      id="Shape"
    ></path>
  </SvgIcon>
);

export default ProcurementIcon;
