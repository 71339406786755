import gql from 'graphql-tag';

import { useFlags } from 'launchdarkly-react-client-sdk';

import useExtendedLazyQuery from 'customHooks/useExtendedLazyQuery';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

const GET_CREW_MEMBERS_FROM_LEADER = gql`
  query getMembersFromLeader($leaderId: String!, $date: Int!) {
    getMembersFromLeader(leaderId: $leaderId, date: $date) {
      crewMemberIds
    }
  }
`;

const transformCrewMembers = data => data?.getMembersFromLeader?.crewMemberIds ?? [];

export const useCrewMembersFromLeader = (leaderId = '', date = 0, options = {}) => {
  const isCrewPickListEnabled = useFlags()[FeatureFlags.CREW_PICK_LIST];

  return useExtendedLazyQuery(GET_CREW_MEMBERS_FROM_LEADER, {
    variables: { leaderId, date },
    transform: transformCrewMembers,
    fetchPolicy: 'cache-and-network',
    defaultData: [],
    skip: !(leaderId && date && isCrewPickListEnabled),
    depends: [leaderId, date],
    ...options
  });
};
