import React from 'react';

import { summaryNotesColumns } from '../../../../meta';
import { ReportTableSectionDefaultProps, ReportTableSectionPropTypes } from '../../../../propTypes';
import { selectVisitSummaries } from '../../../../selectors';
import ReportTable from '../../../ReportTable';

const TechnicianReportSummaryNotesTable = ({ visit, loading, error }) => {
  const title = 'Visit Summary';
  const rows = selectVisitSummaries(visit) || [];
  return (
    <ReportTable
      columns={summaryNotesColumns}
      error={error}
      loading={loading}
      noDataMessage="No Summary Note"
      rows={rows}
      title={title}
    />
  );
};

TechnicianReportSummaryNotesTable.propTypes = ReportTableSectionPropTypes;
TechnicianReportSummaryNotesTable.defaultProps = ReportTableSectionDefaultProps;

export default TechnicianReportSummaryNotesTable;
