import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Typography } from '@BuildHero/sergeant';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { object } from 'prop-types';
import { useSelector } from 'react-redux';

import { getPurchaseOrderTags } from 'services/API/purchaseOrderTag';
import { PricingStrategy } from 'utils/constants';
import getSelectOptions from 'utils/constructSelectOptions';
import FeatureFlagsConstants from 'utils/FeatureFlagConstants';

import { EditBillItemLineModal } from '../../../../../../components/BillItemLine';
import BillModal, { ModalSendActions } from '../../../../../../components/BillModal';
import BillModalOld from '../../../../../../components/BillModal_old';
import { getGroupedBills } from '../../../../../../components/Bills';

import { useDeleteReviewReportBillItem } from '../../../../../../mutations/useDeleteReviewReportBillItem';
import { useSaveReviewReportBillLines } from '../../../../../../mutations/useSaveReviewReportBillLines';
import { useReviewReportBillLines } from '../../../../../../queries/useReviewReportBillLines';
import PurchasedItem from '../../../../../PartsAndMaterialsSection/components/PurchasedItems/components/PurchasedItem';

import { usePOModalContext } from '../../../../hocs/withAddPOModal';
import { useReviewReportDisabled } from '../../../../ReviewReport.contexts';

import BillLineItemsTable from './components/BillLineItemsTable';
import {
  selectBillModalProps,
  selectEditLineItemInput,
  selectGroupedBillsForModal,
  selectPOModalInitialData,
  selectPurchasedItemProps
} from './ReviewReportPurchasedItems.selectors';

import { useStyles } from './ReviewReportPurchasedItems.styles';

const ReviewReportPurchasedItems = ({ visit, job, reviewReport, loading, error }) => {
  const styles = useStyles();
  const user = useSelector(s => s.user);
  const { disabled } = useReviewReportDisabled();

  const ldFlags = useFlags();
  const isProcurementUsageOn = ldFlags[FeatureFlagsConstants.PROCUREMENT_USAGE];

  const isMarginEnabled = useSelector(s => s.settings.pricingStrategy === PricingStrategy.MARGIN);

  const [tagOptions, setTagOptions] = useState([]);
  const initialData = selectPOModalInitialData({ visit, job });
  const { openAddPOModal } = usePOModalContext();

  const groupedBills = useCallback(getGroupedBills({ reviewReport }), [reviewReport]);
  const [billLinesModalOpen, setBillLinesModalOpen] = useState(false);
  const [billLineEditModalOpen, setBillLineEditModalOpen] = useState(false);
  const billLineEditData = useRef({});

  const { data: reviewReportBillLines } = useReviewReportBillLines({
    visitId: visit?.id,
    jobId: job?.id
  });

  const [saveBillLines, saveBillLinesResponse] = useSaveReviewReportBillLines(reviewReport.id, {
    onCompleted: () => {
      setBillLinesModalOpen(false);
      setBillLineEditModalOpen(false);
    },
    onError: () => {
      setBillLinesModalOpen(false);
      setBillLineEditModalOpen(false);
    }
  });

  const [deleteBillLine] = useDeleteReviewReportBillItem();

  const billModalProps = selectBillModalProps({
    visit,
    job,
    reviewReport,
    groupedBills,
    reviewReportBillLines,
    setBillLinesModalOpen
  });

  const handleSend = (action, payload) => {
    switch (action) {
      case ModalSendActions.CLOSE: {
        setBillLinesModalOpen(false);
        break;
      }
      case ModalSendActions.SAVE: {
        const { billItemsToAdd } = payload.saveData;
        saveBillLines({
          billItemsToAdd: billItemsToAdd.map(lineItem =>
            selectEditLineItemInput({ lineItem, reviewReportId: reviewReport.id })
          )
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleEditLineItemClick = lineItem => {
    billLineEditData.current = lineItem;
    setBillLineEditModalOpen(true);
  };

  const handleDeleteLineItemClick = ({ id, billLineId }) => {
    deleteBillLine({ id, billLineId });
  };

  const handleLineItemSubmit = lineItem => {
    saveBillLines({
      billItemsToAdd: [
        selectEditLineItemInput({ lineItem, reviewReportId: reviewReport.id, isMarginEnabled })
      ]
    });
  };

  useEffect(() => {
    getPurchaseOrderTags().then(purchaseOrderTags => {
      setTagOptions(getSelectOptions(purchaseOrderTags, 'tagName'));
    });
  }, []);

  return (
    <>
      <div css={styles.subSectionContainer}>
        <div css={styles.headerContainer}>
          <div>
            <Typography css={styles.title}>Purchased items</Typography>
          </div>
          <Button
            disabled={disabled}
            size="small"
            type="secondary"
            onClick={() => {
              openAddPOModal({
                user,
                initialData,
                tagOptions
              });
            }}
          >
            Add Purchased Order
          </Button>
        </div>
      </div>
      <Typography>Bill line items that have not been invoiced for this job</Typography>
      <Button
        css={{ alignSelf: 'flex-start' }}
        disabled={disabled}
        startIcon={<AddCircleOutlineIcon />}
        type="leading"
        onClick={() => setBillLinesModalOpen(true)}
      >
        Add Bill
      </Button>
      {!loading &&
        groupedBills.map(poWithBills => (
          <>
            <div css={styles.container}>
              <PurchasedItem item={selectPurchasedItemProps(poWithBills)} />
            </div>
            <BillLineItemsTable
              billLineItems={poWithBills.billLineItems}
              error={error}
              isMarginEnabled={isMarginEnabled}
              loading={loading || saveBillLinesResponse.loading}
              onDeleteClick={handleDeleteLineItemClick}
              onEditClick={handleEditLineItemClick}
            />
          </>
        ))}
      {billLinesModalOpen && isProcurementUsageOn && (
        <BillModal
          {...billModalProps}
          open={billLinesModalOpen}
          saving={saveBillLinesResponse.loading}
          send={handleSend}
        />
      )}
      {billLinesModalOpen && !isProcurementUsageOn && (
        <BillModalOld
          {...billModalProps}
          billOptions={selectGroupedBillsForModal({ reviewReportBillLines })}
          open={billLinesModalOpen}
          saving={saveBillLinesResponse.loading}
          send={handleSend}
        />
      )}
      {billLineEditModalOpen && (
        <EditBillItemLineModal
          data={billLineEditData.current}
          open={billLineEditModalOpen}
          onCancel={() => setBillLineEditModalOpen(false)}
          onClose={() => setBillLineEditModalOpen(false)}
          onComplete={handleLineItemSubmit}
        />
      )}
    </>
  );
};

ReviewReportPurchasedItems.propTypes = {
  visit: object.isRequired,
  job: object.isRequired
};

export default ReviewReportPurchasedItems;
