import { useMemo } from 'react';

export const useFormattedData = ({
  payrollHourTypes = [],
  labourRateGroups = [],
  labourTypes = []
}) =>
  useMemo(
    () =>
      labourRateGroups
        .map(labourRateGroup => {
          const labourRateGroupEntries = labourRateGroup?.labourRateGroupEntries?.items || [];
          return labourTypes
            .filter(({ isArchived }) => !isArchived)
            .map(labourType => {
              const payrollHourCostTypeData = payrollHourTypes
                .filter(({ isArchived }) => !isArchived)
                .map(payrollHourType => {
                  const labourRateGroupEntry = labourRateGroupEntries.find(
                    entry =>
                      entry.labourTypeId === labourType.id &&
                      entry.payrollHourTypeId === payrollHourType.id
                  );

                  return {
                    [payrollHourType.hourTypeAbbreviation]: {
                      costCode: labourRateGroupEntry?.costCode
                        ? {
                            label: labourRateGroupEntry?.costCode?.name,
                            value: labourRateGroupEntry?.costCodeId
                          }
                        : null,
                      jobCostType: labourRateGroupEntry?.jobCostType
                        ? {
                            label: labourRateGroupEntry?.jobCostType?.name,
                            value: labourRateGroupEntry?.jobCostTypeId
                          }
                        : null
                    }
                  };
                });

              return {
                ...Object.assign({}, ...payrollHourCostTypeData),
                ...{
                  labourRateGroupId: labourRateGroup.id,
                  labourRateGroupName: labourRateGroup.name,
                  labourTypeId: labourType.id,
                  labourTypeName: labourType.name
                }
              };
            });
        })
        .flat(),
    [payrollHourTypes, labourRateGroups, labourTypes]
  );
