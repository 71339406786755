import React from 'react';

import { Button, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { Box, Grid, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import uuid from 'uuid';

import { reorder } from 'utils';

import LaborRateGroupCard from './LaborRateGroupCard';

const LaborRateGroupsTable = ({ laborRateGroups, setLaborRateGroups, loading }) => {
  const theme = useTheme();

  const creatingNewLaborRateGroup = laborRateGroups[laborRateGroups.length - 1]?.name === '';

  const resetSortOrder = unorderedGroups => unorderedGroups.map((g, i) => ({ ...g, sortOrder: i }));

  const onNameChange = ({ value, index }) => {
    setLaborRateGroups(existingGroups => {
      return existingGroups.map((g, i) => {
        if (i === index) return { ...g, name: value };
        return g;
      });
    });
  };

  const onRemoveLaborRateGroup = ({ index }) => {
    setLaborRateGroups(existingGroups =>
      resetSortOrder(existingGroups.filter((t, i) => i !== index))
    );
  };

  const moveGroup = ({ source, destination }) => {
    if (!Number.isInteger(source?.index) || !Number.isInteger(destination?.index)) return; // invalid drag
    setLaborRateGroups(existingGroups =>
      resetSortOrder(
        reorder({
          arr: existingGroups,
          source: source.index,
          destination: destination.index
        })
      )
    );
  };

  const onAddLaborRateGroup = () => {
    setLaborRateGroups(existingGroups => [
      ...existingGroups,
      { id: uuid.v4(), name: '', sortOrder: existingGroups.length, unsaved: true }
    ]);
  };

  return (
    <ThemeProvider>
      <Grid item style={{ padding: 0, width: '100%' }}>
        <Box component="div">
          <DragDropContext isDropDisabled={loading} onDragEnd={moveGroup}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {laborRateGroups.map((item, index) => (
                    <LaborRateGroupCard
                      index={index}
                      isDragDisabled={loading}
                      item={item}
                      laborRateGroups={laborRateGroups}
                      onAddLaborRateGroup={onAddLaborRateGroup}
                      onNameChange={onNameChange}
                      onRemoveLaborRateGroup={onRemoveLaborRateGroup}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {!creatingNewLaborRateGroup && (
              <Button type="leading" onClick={onAddLaborRateGroup}>
                <AddIcon css={{ height: theme.spacing(3) }} />
                <Typography variant={TV.BASE} weight={TW.MEDIUM}>
                  Add Labor Rate Group
                </Typography>
              </Button>
            )}
          </DragDropContext>
        </Box>
      </Grid>
    </ThemeProvider>
  );
};

LaborRateGroupsTable.propTypes = {
  laborRateGroups: PropTypes.array,
  setLaborRateGroups: PropTypes.func
};

LaborRateGroupsTable.defaultProps = {
  laborRateGroups: [],
  setLaborRateGroups: () => {}
};

export default LaborRateGroupsTable;
