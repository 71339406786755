import { useMemo } from 'react';

export const useFormattedData = ({
  payrollHourTypes = [],
  labourRateGroups = [],
  labourTypes = []
}) =>
  useMemo(
    () =>
      labourRateGroups
        .map(labourRateGroup => {
          const labourRateGroupEntries = labourRateGroup?.labourRateGroupEntries?.items || [];
          return labourTypes
            .filter(({ isArchived }) => !isArchived)
            .map(labourType => {
              const payrollHourTypeData = payrollHourTypes
                .filter(({ isArchived }) => !isArchived)
                .map(payrollHourType => ({
                  [payrollHourType.hourTypeAbbreviation]: labourRateGroupEntries.find(
                    entry =>
                      entry.labourTypeId === labourType.id &&
                      entry.payrollHourTypeId === payrollHourType.id
                  )?.rate
                }));

              return {
                ...Object.assign({}, ...payrollHourTypeData),
                ...{
                  labourRateGroupId: labourRateGroup.id,
                  labourRateGroupName: labourRateGroup.name,
                  labourTypeId: labourType.id,
                  labourTypeName: labourType.name
                }
              };
            });
        })
        .flat(),
    [payrollHourTypes, labourRateGroups, labourTypes]
  );
