import React, { useEffect, useMemo, useState } from 'react';

import { TV, TW, Typography } from '@BuildHero/sergeant';

import { Placeholder } from 'components';

import CheckboxLineItem from '../CheckboxLineItem';

import { processDocumentsData } from './JobDocumentAttachments.utils';

const JobDocumentAttachments = props => {
  const { documents, dispatchFilesData, filesData } = props;
  const [documentsData, setDocumentsData] = useState();

  useEffect(() => {
    const prepareJobDocuments = async () => {
      const processedDocuments = await processDocumentsData(documents);
      setDocumentsData(processedDocuments);
    };
    if (documents) {
      prepareJobDocuments();
    }
  }, [documents]);

  const PlaceHolder = useMemo(
    () => (
      <Placeholder
        paddingBottom={0}
        paddingLeft={0}
        paddingRight={0}
        paddingTop={0}
        variant="table"
      />
    ),
    []
  );

  if (documentsData === undefined) return PlaceHolder;

  return (
    <>
      <div css={{ marginBottom: 8 }}>
        <Typography variant={TV.L} weight={TW.BOLD}>
          Documents
        </Typography>
      </div>
      {documentsData &&
        documentsData.map(reportData => (
          <CheckboxLineItem
            dispatchFilesData={dispatchFilesData}
            filesData={filesData}
            hideDivider
            reportData={reportData}
          />
        ))}
    </>
  );
};

export default React.memo(JobDocumentAttachments);
