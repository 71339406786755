import React from 'react';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

/**
 * Renders Readonly inputs as per the build hero designs
 */
const MuiButton = ({ field, form, classes, buttons, specialbehaviour, ...rest }) => {
  const clickAction = () => {
    if (specialbehaviour.controlField) {
      const { mappings } = specialbehaviour;
      const mappedValues = {};

      Object.keys(mappings).forEach(key => {
        mappedValues[key] = form.values[mappings[key]] || '';
      });
      const newValues = { ...form.values, ...mappedValues };
      form.setValues(newValues);
    }
    if (rest.specialbehaviour && rest.specialbehaviour.validate) {
      form.validateForm();
      if (!form.errors) buttons[field.name](form.values);
    }
  };

  return (
    <Button
      className={classes.inlineFormButton}
      color="secondary"
      // fullWidth={true}
      variant="outlined"
      onClick={clickAction}
    >
      {rest.placeholder}
    </Button>
  );
};
export default withStyles(styles)(MuiButton);
