import React from 'react';

import { Box, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import MoreIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';

import { setAppName } from 'redux/actions/globalActions';

import styles from './styles';

/**
 * Build hero application toolbars on the header - search, notifications, profile
 */
class HeaderToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.logout = this.logout.bind(this);
    this.handleMenuChange = this.handleMenuChange.bind(this);
  }

  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null
  };

  // provies abilitiy to change the menu. Tenant admin can switch between apps
  handleMenuChange = () => {
    this.props.changeAppName(this.props.menu.appName === 'admin' ? 'crm' : 'admin');
    this.handleMenuClose();
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  logout = e => {
    e.preventDefault();
  };

  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const { classes } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    // read default app from the redux state
    const otherApp = this.props.menu.appName === 'admin' ? 'Crm' : 'Admin';

    const renderHeaderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={this.handleMenuClose}
      >
        <MenuItem className={classes.profileMenu} onClick={this.handleMenuClose}>
          My Profile
        </MenuItem>
        <MenuItem className={classes.profileMenu} onClick={this.handleMenuChange}>
          Switch to {otherApp}
        </MenuItem>
        <MenuItem className={classes.profileMenu} onClick={this.logout}>
          Logout
        </MenuItem>
      </Menu>
    );

    // renders menu in expandable drawer
    const renderHeaderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={this.handleMobileMenuClose}
      >
        <MenuItem onClick={this.handleProfileMenuOpen}>
          <IconButton color="inherit">
            <ArrowDropDown />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    );

    return (
      <Grid container direction="row" justify="flex-end">
        <Grid item>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              placeholder="Search Customers, jobs…"
            />
          </div>
        </Grid>
        <Grid item>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Grid alignItems="center" container direction="row" justify="space-evenly" spacing={1}>
              <Grid item>
                <Avatar
                  alt="Remy Sharp"
                  className={classes.avatar}
                  src="/static/images/uxceo-128.jpg"
                />
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="h6">
                  Remy Sharp
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  aria-haspopup="true"
                  aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                  color="inherit"
                  onClick={this.handleProfileMenuOpen}
                >
                  <ArrowDropDown />
                </IconButton>
              </Grid>
            </Grid>
          </div>
          <div className={classes.sectionMobile}>
            <Box display="block" displayPrint="none">
              <IconButton aria-haspopup="true" color="inherit" onClick={this.handleMobileMenuOpen}>
                <MoreIcon />
              </IconButton>
            </Box>
          </div>
          {renderHeaderMenu}
          {renderHeaderMobileMenu}
        </Grid>
      </Grid>
    );
  }
}

const styleHeadertoolbar = withStyles(styles)(HeaderToolbar);

// Gets dispatcher action from redux global actions
const mapDispatchToProps = dispatch => ({
  changeAppName: appName => dispatch(setAppName(appName))
});

// pass user context, app, and menu state from redux as props to the component
const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});

export default connect(mapStateToProps, mapDispatchToProps)(styleHeadertoolbar);
