import gql from 'graphql-tag';

import { dispatchVisitFragment } from '../queries/visitFragments';

const visitUpdateNotification = gql`
  subscription visitUpdateNotification($partitionKey: String!) {
    visitUpdateNotification(partitionKey: $partitionKey) {
      ${dispatchVisitFragment}
    }
  }
`;

export default visitUpdateNotification;
