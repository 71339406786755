import React, { useMemo } from 'react';

import { Select, ThemeProvider } from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';

import useEditJobPricebook from 'scenes/JobCloseout/mutations/useEditJobPricebook';

const PricebookSelect = ({ jobId, jobVersion, priceBookId, isLoading }) => {
  const pricebooks = useSelector(state => state.company?.priceBooks?.items);

  const pricebookOptions = useMemo(
    () => pricebooks?.map(b => ({ label: b.name, value: b.id })) || [],
    [pricebooks]
  );

  const selectedPricebook = useMemo(() => pricebookOptions.find(o => o.value === priceBookId), [
    pricebookOptions,
    priceBookId
  ]);

  const [editPricebook, { loading: editingPricebook }] = useEditJobPricebook();

  return (
    <ThemeProvider>
      <Box display="flex" flexDirection="column" marginBottom="8px" marginTop="8px" width="200px">
        <Select
          defaultValue={selectedPricebook}
          label="Pricebook"
          loading={editingPricebook || isLoading}
          options={pricebookOptions}
          searchable
          value={selectedPricebook}
          onChange={newPricebook =>
            editPricebook({ id: jobId, priceBookId: newPricebook.value, jobVersion })
          }
        />
      </Box>
    </ThemeProvider>
  );
};

PricebookSelect.defaultProps = {
  isLoading: false
};

export default PricebookSelect;
