import { projectFileDirectoryPermissions } from '../../constants';

export const directoryPermissions = [
  {
    label: projectFileDirectoryPermissions.officeAccess,
    value: `Object_${projectFileDirectoryPermissions.officeAccess}`
  },
  {
    label: projectFileDirectoryPermissions.webAccess,
    value: `Object_${projectFileDirectoryPermissions.webAccess}`
  },
  {
    label: projectFileDirectoryPermissions.mobileAccess,
    value: `Object_${projectFileDirectoryPermissions.mobileAccess}`
  }
];
