import React, { memo } from 'react';

import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useStyles } from '@dispatch/components/VisitsTabs/VisitsTabs.styles';
import { JobTypes } from '@dispatch/Dispatch.constants';

const Maintenance = ({ record }) => {
  const classes = useStyles();
  if (record?.jobTypeInternal === JobTypes.MAINTENANCE.value.serverValue) {
    return <Typography className={classes.Maintenance}>Maintenance</Typography>;
  }

  return <Typography className={classes.ServiceJob}>Job</Typography>;
};

Maintenance.propTypes = {
  record: PropTypes.object.isRequired
};

export default memo(Maintenance);
