import React, { Component } from 'react';

import { MUIFormComponents } from '@BuildHero/sergeant';

import { Button, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';

import ConfirmModal from 'components/Modal/ConfirmDialog';
import { snackbarOn } from 'redux/actions/globalActions';
import { CommonService } from 'services/core';
import { Logger } from 'services/Logger';
import { getNumberValue } from 'utils';

const styles = theme => ({
  label: {
    fontSize: 10,
    letterSpacing: 0.01,
    fontWeight: 'normal',
    textTransform: 'uppercase'
  },
  deleteIconStyle: {
    fontSize: 18
  },
  inputStyle: {
    marginTop: 5,
    paddingBottom: 2
  },
  inputPropStyle: {
    paddingBottom: 2,
    height: 'auto',
    minHeight: 90
  },
  multiLineRow: {
    height: 'auto',
    marginBottom: 5,
    border: 'none'
  },
  multiLineCell: {
    border: 'none',
    '&:last-child': {
      paddingRight: 5
    },
    width: '90%'
  },
  iconTray: {
    width: '10%',
    border: 'none'
  },
  cssUnderline: {
    '&:after': {
      borderBottomColor: theme.palette.brand.logoBlue
    },
    '&&&&:hover:before': {
      borderBottom: `1px solid ${theme.palette.brand.logoBlue}`
    }
  },
  linkStyles: {
    color: 'inherit',
    textDecoration: 'none'
  },
  linkText: {
    fontWeight: 'normal',
    paddingTop: 15.5
  },
  linkIcon: {
    fontSize: 12
  },

  buttonOutlinedSecondary: {
    border: 'none',
    textTransform: 'unset',
    padding: 0,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.3,
    '&:hover': {
      border: 'none',
      backgroundColor: '#fff'
    },
    '&:disabled': {
      border: 'none',
      backgroundColor: '#fff'
    }
  }
});

class NotesTable extends Component {
  constructor(props) {
    super(props);
    this.CommonService = new CommonService();
    this.state = {
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: ''
    };
  }

  // On button click, render row with text box
  handleAddButtonClick = refFieldName => {
    // const notesValues = this.props.form.values[this.props.fieldProps.name];
    // notesValues.splice(0, 0, { [refFieldName]: '' });
    this.props.push({ [refFieldName]: '' });
    // this.props.form.setFieldValue(this.props.fieldProps.name, notesValues, false);
  };

  handleOnDelete = value => {
    this.setState(prevState => ({
      ...prevState,
      confirmDialog: true,
      confirmAction: () => this.handleDeleteListRow(value),
      confirmMessage: 'note'
    }));
  };

  handleDeleteListRow = async value => {
    if (value.listItem && value.listItem.sortKey) {
      try {
        const { data } = await this.CommonService.deleteNote(
          value.listItem.partitionKey,
          value.listItem.sortKey
        );
        if (data) {
          this.props.snackbarOn('success', 'Successfully deleted note');
          // this.props.remove(value.index);
          const itemList = this.props.form.values[this.props.fieldProps.value];
          const modifiedList = itemList.filter(item => item.id !== value.listItem.id);
          this.props.form.setFieldValue(this.props.fieldProps.name, modifiedList || [], false);
          this.setState(prevState => ({
            ...prevState,
            confirmDialog: false,
            confirmAction: '',
            confirmMessage: ''
          }));
        }
      } catch (error) {
        Logger.error(error);
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          this.props.snackbarOn('error', error.graphQLErrors[0].message);
        } else {
          this.props.snackbarOn('error', 'Unable to delete note, please try again later');
        }
      }
    } else {
      this.props.remove(value.index);
      this.setState(prevState => ({
        ...prevState,
        confirmDialog: false,
        confirmAction: '',
        confirmMessage: ''
      }));
    }
  };

  handleChangeInput = (index, listItem, refFieldName) => event => {
    this.props.replace(index, {
      ...listItem,
      edited: true,
      [refFieldName]: event.target.value
    });
  };

  handleCancelConfirmation = () => {
    this.setState(prevState => ({
      ...prevState,
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: ''
    }));
  };

  render() {
    const { classes, fieldProps, specialbehaviour, mode } = this.props;
    const fieldName = fieldProps.value;
    const itemList = this.props.form.values[this.props.fieldProps.value] || [];

    // TODO: always refer to object reference field name, departments are not having these defs
    const refFieldName =
      specialbehaviour && specialbehaviour.objectReferenceFieldName
        ? specialbehaviour.objectReferenceFieldName
        : 'tagName';
    const subjField = 'subject';
    // construct table
    // when values exist build rows
    // if string one text cell
    // if JSON, get tableCols and iterate
    let smValue;

    let mdValue;

    let lgValue = 12;
    if (fieldProps.behavior[mode]) {
      smValue = getNumberValue(fieldProps.behavior[mode].sm) || 12;
      mdValue = getNumberValue(fieldProps.behavior[mode].md) || 4;
      lgValue = getNumberValue(fieldProps.behavior[mode].lg) || 2;
    }
    return (
      <Grid item lg={lgValue} md={mdValue} sm={smValue} xl={lgValue} xs={smValue}>
        {mode === 'view' && itemList.length !== 0 && (
          <Typography className={classes.label} variant="caption">
            {this.props.label}
          </Typography>
        )}
        <Table>
          <TableBody>
            {fieldProps.allowAdd && mode !== 'view' && (
              <TableRow className={classes.multiLineRow}>
                <TableCell classes={{ root: classes.multiLineCell }}>
                  <Button
                    classes={{
                      outlinedSecondary: classes.buttonOutlinedSecondary
                    }}
                    type="button"
                    onClick={() => this.handleAddButtonClick(refFieldName)}
                  >
                    <AddIcon className={classes.linkIcon} />
                    {fieldProps.addButtonName}
                  </Button>
                </TableCell>
              </TableRow>
            )}

            {itemList &&
              itemList.map((listItem, index) => (
                <TableRow className={classes.multiLineRow} key={`row${listItem.id || index}`}>
                  <TableCell
                    classes={{ root: classes.multiLineCell }}
                    key={`itemObjectCell${listItem.id || index}`}
                  >
                    {listItem && (
                      <>
                        <TextField
                          className={classes.textField}
                          // fullWidth
                          disabled={mode === 'view'}
                          key={`text${listItem.id || index}`}
                          label="Subject"
                          name="subject"
                          style={{ paddingTop: 4, paddingBottom: 20 }}
                          value={listItem[subjField]}
                          variant="filled"
                          onChange={this.handleChangeInput(index, listItem, subjField)}
                        />
                        <TextField
                          className={classes.inputStyle}
                          disabled={mode === 'view'}
                          fullWidth
                          InputProps={{
                            classes: {
                              underline: classes.cssUnderline,
                              root: classes.inputPropStyle
                            }
                          }}
                          key={`text${listItem.id || index}`}
                          label="Note"
                          multiline
                          name={`${fieldName}.${index}.${refFieldName}`}
                          rows={
                            specialbehaviour && specialbehaviour.numberOfLines
                              ? specialbehaviour.numberOfLines
                              : '3'
                          }
                          rowsMax={
                            specialbehaviour && specialbehaviour.numberOfLines
                              ? specialbehaviour.numberOfLines
                              : '3'
                          }
                          style={{ paddingBottom: 20 }}
                          value={listItem[refFieldName]}
                          variant="filled"
                          onChange={this.handleChangeInput(index, listItem, refFieldName)}
                        />
                      </>
                    )}
                  </TableCell>
                  {mode === 'edit' && (
                    <TableCell align="right" classes={{ root: classes.iconTray }}>
                      <IconButton
                        aria-label="Delete"
                        className={this.props.classes.listIcon}
                        key={`deliconbtn${listItem.id || index}`}
                        onClick={() => this.handleOnDelete({ listItem, index })}
                      >
                        <DeleteIcon
                          className={this.props.classes.deleteIconStyle}
                          key={`delicon${listItem.id || index}`}
                        />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <ConfirmModal
          cancel={this.handleCancelConfirmation}
          confirm={this.state.confirmAction}
          message={this.state.confirmMessage}
          open={this.state.confirmDialog}
        />
      </Grid>
    );
  }
}

const Notes = withStyles(styles, { withTheme: true })(NotesTable);

const NotesWrapper = props => {
  const { form, field, options } = props;
  const { label, mode } = options;
  const error = form.errors && form.errors[field.name];
  const fieldProps = {
    addButtonName: 'Add note',
    allowAdd: true,
    behavior: {
      edit: {
        sm: '12',
        md: '12',
        lg: '12'
      },
      new: {
        sm: '12',
        md: '12',
        lg: '12'
      }
    },
    label: 'notes',
    name: 'repNotes',
    value: 'repNotes'
  };
  const specialbehaviour = {
    numberOfLines: 3,
    objectReferenceFieldName: 'note',
    showMore: false
  };
  return (
    <>
      <Notes
        fieldProps={fieldProps}
        form={form}
        label={label}
        mode={mode}
        push={newNote => {
          form.setFieldValue(field.name, [...(field.value || []), newNote]);
        }}
        remove={index => {
          field.value.splice(index, 1);
          form.setFieldValue(field.name, [...field.value]);
        }}
        replace={(index, updated) => {
          field.value.splice(index, 1, updated);
          form.setFieldValue(field.name, [...field.value]);
        }}
        snackbarOn={props.snackbarOn}
        specialbehaviour={specialbehaviour}
      />
      <MUIFormComponents.ErrorMessage value={error} />
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedNotesWrapper = connect(null, mapDispatchToProps)(NotesWrapper);

export default reduxConnectedNotesWrapper;
