import React, { useCallback, useMemo } from 'react';

import { Checkbox, Divider, Typography } from '@BuildHero/sergeant';
import { ButtonBase } from '@material-ui/core';
import * as R from 'ramda';

import { FileDataDispatchActions } from '../../../../AddAttachmentStep.constants';

import { useStyles } from './CheckboxLineItem.styles';

const JobReportCheckboxLineItem = ({
  reportData,
  dispatchFilesData,
  filesData,
  hideDivider = false
}) => {
  const styles = useStyles();
  const { fileName, fileUrl, id: reportId, visitsIncluded } = reportData;

  const handleCheckboxChange = useCallback(
    (value, fileData) => {
      if (value) {
        dispatchFilesData({ type: FileDataDispatchActions.ADD, payload: { fileData } });
      } else {
        dispatchFilesData({ type: FileDataDispatchActions.REMOVE, payload: { fileData } });
      }
    },
    [dispatchFilesData]
  );

  const checked = useMemo(() => R.pipe(R.pluck('id'), R.includes(reportId))(filesData), [
    filesData,
    reportId
  ]);

  return (
    <>
      <div
        css={[
          styles.flexWrapper,
          { paddingRight: 8, justifyContent: 'space-between', marginBottom: hideDivider ? 8 : 0 }
        ]}
        key={reportId}
      >
        <div css={[styles.flexWrapper]}>
          <div css={[styles.flexWrapper, { marginRight: 4 }]}>
            <Checkbox
              checked={checked}
              color="secondary"
              css={{ marginTop: 0 }}
              onChange={e => handleCheckboxChange(e.target.checked, reportData)}
            />
          </div>
          <div css={[styles.flexWrapper]}>
            <ButtonBase onClick={() => window.open(fileUrl, '_blank')}>
              <Typography css={{ textAlign: 'left' }} underlined>
                {fileName}
              </Typography>
            </ButtonBase>
          </div>
        </div>
        {visitsIncluded && (
          <div>
            <Typography css={[styles.flexWrapper, { alignItems: 'center' }]}>
              {`Visits ${visitsIncluded}`}
            </Typography>
          </div>
        )}
      </div>
      {!hideDivider && <Divider margin={8} />}
    </>
  );
};

export default React.memo(JobReportCheckboxLineItem);
