import { gql } from '@apollo/client/core';
import { useSelector } from 'react-redux';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { generateCompanySortKey } from 'services/core/utils';
import { logErrorWithCallback } from 'utils';

const getProducts = gql`
  query getProducts($partitionKey: String!, $sortKey: String!, $filter: TableProductFilterInput) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      products(filter: $filter, limit: 100000) {
        items {
          id
          name
          description
          code
          isActive
        }
      }
    }
  }
`;

const useProducts = (options = {}) => {
  const user = useSelector(state => state.user);

  const { data, loading, error, refetch } = useExtendedQuery(getProducts, {
    variables: {
      filter: { isActive: { eq: true } },
      partitionKey: user.tenantId,
      sortKey: generateCompanySortKey(user.tenantId, user.tenantCompanyId)
    },
    transform: result => result?.getCompany?.products?.items,
    onError: (err, snackbar) =>
      logErrorWithCallback(err, snackbar, 'Unable to get products, please try again later'),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options
  });

  return [data || [], loading, error, refetch];
};

export default useProducts;
