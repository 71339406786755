import React, { useCallback, useMemo, useState } from 'react';

import { Button, Modal, SgtForm, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';

const configuration = ({ costCodeOptions, jobCostTypeOptions }) => ({
  layouts: {
    default: {
      // formLevel
      contents: [
        {
          // row 1
          props: {
            width: 400
          },
          contents: [
            {
              component: 'Select',
              source: 'costCode',
              props: {
                testingid: 'costCode-select',
                label: 'Cost code',
                options: costCodeOptions,
                placeholder: 'Select Cost Code',
                portal: true,
                required: true,
                searchable: true,
                clearable: true,
                menuHeight: 150,
                onChange: (field, costCode, form) => {
                  if (!costCode?.value) return;
                  form.setFieldValue(field, costCode);
                }
              }
            }
          ]
        },
        {
          // row 2
          props: {
            width: 400,
            marginTop: 2
          },
          contents: [
            {
              component: 'Select',
              source: 'jobCostType',
              props: {
                testingid: 'jobCostType-select',
                label: 'Cost type',
                options: jobCostTypeOptions,
                placeholder: 'Select Cost Type',
                portal: true,
                required: true,
                searchable: true,
                clearable: true,
                menuHeight: 150,
                onChange: (field, jobCostType, form) => {
                  if (!jobCostType?.value) return;
                  form.setFieldValue(field, jobCostType);
                }
              }
            }
          ]
        }
      ]
    }
  }
});

const usePayrollCostCodesTableModal = ({
  costCodeOptions,
  jobCostTypeOptions,
  payrollCostCodesTableFormik
}) => {
  const [open, setOpen] = useState(false);
  const [parent, setParent] = useState({});
  const [formService, setFormService] = useState();

  const handleSave = useCallback(
    ({ costCode, jobCostType, id }) => {
      payrollCostCodesTableFormik.setFieldValue(id, { costCode, jobCostType });
      setOpen(false);
    },
    [payrollCostCodesTableFormik]
  );

  const openCostCodesTableModal = useCallback(givenData => {
    setParent(givenData);
    setOpen(true);
  }, []);

  const PayrollCostCodesTableModal = useMemo(
    () => (
      <ThemeProvider>
        <Modal
          actions={
            <Button fullWidth onClick={formService?.formikContext.handleSubmit}>
              Save
            </Button>
          }
          open={open}
          title={
            <Typography variant={TV.XL} weight={TW.BOLD}>
              Select Cost Code &amp; Cost Type
            </Typography>
          }
          width="500"
          onClose={() => {
            setOpen(false);
          }}
        >
          <SgtForm
            configuration={configuration({ costCodeOptions, jobCostTypeOptions })}
            initialValues={{ ...parent }}
            onCreateService={setFormService}
            onSubmit={handleSave}
          />
        </Modal>
      </ThemeProvider>
    ),
    [formService, open, costCodeOptions, jobCostTypeOptions, parent, handleSave]
  );

  return { PayrollCostCodesTableModal, openCostCodesTableModal };
};

export default usePayrollCostCodesTableModal;
