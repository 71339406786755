import { merge } from 'lodash';

import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';
import { arrayInsert, camelCaseToTitleCase, checkPermission } from 'utils';
import { AppConstants, FeatureGateConstants, PermissionConstants } from 'utils/AppConstants';
import { LABOR_RATE_INFO_TOOLTIP } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import {
  generateDefaultFieldsObject,
  generateDefaultValidationObject
} from 'utils/muiFormsLibrary';

const jobMainFields = options => ({
  jobType: {},
  departments: { required: options.isDepartmentRequired },
  projectManager: {},
  priority: {},
  issueDescription: {},
  serviceChannelDescription: {},
  officeNotes: {}
});

const jobSidebarFields = {
  customer: { required: true },
  billingCustomer: { required: true },
  property: { required: true },
  propertyType: {},
  address: {},
  authorizedBy: {},
  propertyRepresentative: {},
  bestContactMethod: {},
  propertyInstructions: {},
  purchaseOrder: {},
  workOrder: {}
};

const jobMainLayout = (options, customLayouts = []) => {
  // In px
  const itemMinWidth = 230;
  const padding = 12;
  const multilineCount = 4;

  const serviceChannelEnabled = Boolean(options.flags[FeatureFlags.SERVICE_CHANNEL_INTEGRATION]);
  const enterpriseLaborCostingEnabled = Boolean(
    options.flags[FeatureFlags.ENTERPRISE_LABOR_COSTING]
  );

  return {
    options: {
      pageSize: 'LETTER',
      grow: 0,
      height: 'auto'
    },
    contents: [
      {
        // Job Type, Departments, Project Manager, Priority
        options: {
          direction: 'row',
          grow: 0,
          wrap: 'wrap'
        },
        contents: [
          {
            options: {
              direction: 'column',
              grow: 0,
              padding,
              minWidth: itemMinWidth
            },
            contents: [
              {
                component: {
                  edit: 'SelectInput',
                  view: 'FieldWithLabel'
                },
                source: 'jobType',
                options: {
                  label: 'Job Type',
                  inputOptions: options.jobTypeList,
                  enableSort: false
                }
              }
            ]
          },
          {
            options: {
              direction: 'column',
              grow: 0,
              padding,
              minWidth: itemMinWidth
            },
            contents: [
              {
                component: {
                  edit: 'SelectInput',
                  view: 'FieldWithLabel'
                },
                source: 'projectManager',
                options: {
                  label: 'Project Manager',
                  isSearchable: true,
                  inputOptions: options.projectManagerList
                }
              }
            ]
          },
          {
            options: {
              direction: 'column',
              grow: 0,
              padding,
              minWidth: itemMinWidth
            },
            contents: [
              {
                component: {
                  edit: 'SelectInput',
                  view: 'FieldWithLabel'
                },
                source: 'accountManager',
                options: {
                  label: 'Account Manager',
                  isSearchable: true,
                  inputOptions: options.accountManagerList
                }
              }
            ]
          },
          {
            options: {
              direction: 'column',
              grow: 0,
              padding,
              minWidth: itemMinWidth
            },
            contents: [
              {
                component: {
                  edit: 'SelectInput',
                  view: 'FieldWithLabel'
                },
                source: 'soldBy',
                options: {
                  label: 'Sold By',
                  isSearchable: true,
                  inputOptions: options.soldByList
                }
              }
            ]
          },
          enterpriseLaborCostingEnabled && {
            options: {
              direction: 'column',
              grow: 0,
              padding,
              minWidth: itemMinWidth
            },
            contents: [
              {
                component: {
                  edit: 'SelectInput',
                  view: 'FieldWithLabel'
                },
                source: 'labourRateGroup',
                options: {
                  label: 'Labor Rate',
                  placeholder: 'Select Labor Rate',
                  tooltip: LABOR_RATE_INFO_TOOLTIP,
                  isSearchable: true,
                  inputOptions: options.laborRateGroupList,
                  infoText: 'Only affects labor costs tied to future visits'
                }
              }
            ]
          },
          enterpriseLaborCostingEnabled && {
            options: {
              direction: 'column',
              grow: 0,
              padding,
              minWidth: itemMinWidth
            },
            contents: [
              {
                component: {
                  edit: 'SelectInput',
                  view: 'ChipsList'
                },
                source: 'labourRateModifiers',
                options: {
                  label: 'Modifiers',
                  placeholder: 'Select Modifiers',
                  isMultipleSelection: true,
                  inputOptions: options.laborRateModifiersList,
                  infoText: 'Only affects labor costs tied to future visits'
                }
              }
            ]
          }
        ].filter(Boolean)
      },
      {
        options: {
          size: 1,
          direction: 'row',
          grow: 0
        },
        contents: [
          {
            options: {
              direction: 'column',
              grow: 1,
              padding,
              minWidth: itemMinWidth
            },
            contents: [
              {
                component: {
                  edit: 'SelectInput',
                  view: 'ChipsList'
                },
                source: 'departments',
                options: {
                  label: 'Departments',
                  isMultipleSelection: true,
                  inputOptions: options.departmentList,
                  isRequired: options.isDepartmentRequired
                }
              }
            ]
          }
        ]
      },
      // Issue Description, Office Notes
      {
        options: {
          size: 1,
          direction: 'row',
          grow: 0
        },
        contents: [
          {
            options: {
              direction: 'column',
              grow: serviceChannelEnabled ? 0 : 1,
              minWidth: serviceChannelEnabled ? itemMinWidth : undefined,
              padding
            },
            contents: [
              {
                component: {
                  edit: 'TextInput',
                  view: 'FieldWithLabel'
                },
                source: 'issueDescription',
                options: {
                  label: 'Issue Description',
                  lines: multilineCount
                }
              }
            ]
          },
          serviceChannelEnabled && {
            options: {
              direction: 'column',
              grow: 0,
              minWidth: itemMinWidth * 2,
              padding
            },
            contents: [
              {
                component: {
                  edit: 'TextInput',
                  view: 'FieldWithLabel'
                },
                source: 'serviceChannelDescription',
                options: {
                  label: 'Service Channel Description',
                  lines: multilineCount
                }
              }
            ]
          },
          {
            options: {
              direction: 'column',
              grow: 0,
              padding,
              minWidth: itemMinWidth
            },
            contents: [
              {
                component: {
                  edit: 'SelectInput',
                  view: 'FieldWithLabel'
                },
                source: 'priority',
                options: {
                  label: 'Priority',
                  inputOptions: options.priorityList
                }
              }
            ]
          },
          {
            options: {
              direction: 'column',
              grow: 1,
              padding
            },
            contents: [
              {
                // TODO: Should be editable but isn't because design specificies single text field but
                // current backend implementation has array of note objects with subject and body
                component: {
                  edit: 'Notes',
                  view: 'Notes'
                },
                source: 'officeNotes',
                options: {
                  label: 'Office Notes',
                  lines: multilineCount
                }
              }
            ]
          }
        ].filter(Boolean)
      },
      {
        options: {
          direction: 'row',
          grow: 0,
          wrap: 'wrap'
        },
        contents: [
          {
            options: {
              direction: 'column',
              grow: 0,
              padding,
              minWidth: itemMinWidth
            },
            contents: [
              {
                component: {
                  edit: 'CheckboxInput',
                  view: 'DetailedJobCostingLabel'
                },
                source: 'detailedJobCostingEnabled',
                options: {
                  label: 'Enable detail job costing',
                  lines: multilineCount
                }
              }
            ]
          },
          options.showCertifiedPayroll && {
            options: {
              direction: 'column',
              grow: 0,
              padding,
              minWidth: itemMinWidth
            },
            contents: [
              {
                component: {
                  edit: options.forceCertifiedPayrollJobs
                    ? 'ReadOnlyCheckboxInput'
                    : 'CheckboxInput',
                  view: 'CertifiedPayrollLabel'
                },
                source: 'certifiedPayroll',
                options: {
                  label: 'Enable certified payroll',
                  lines: multilineCount
                }
              }
            ]
          },
          {
            options: {
              direction: 'column',
              grow: 0,
              padding,
              minWidth: itemMinWidth,
              hide: !options.hasServiceAgreements
            },
            contents: [
              {
                component: {
                  edit: 'SelectInput',
                  view: 'LinkButton'
                },
                source: 'serviceAgreementId',
                options: {
                  label: 'Service Agreement',
                  isSearchable: true,
                  inputOptions: options.serviceAgreementList,
                  onChange: options.onSAChange
                }
              }
            ]
          },
          {
            options: {
              grow: 0,
              padding,
              minWidth: itemMinWidth,
              hide: !options.showTotalBudgetedHours
            },
            contents: [
              {
                component: {
                  edit: 'TextInput',
                  default: 'LabelWithTip'
                },
                source: 'totalBudgetedHours',
                options: {
                  label: 'Total Budgeted Hours',
                  type: 'number',
                  min: 1,
                  step: '.01'
                }
              }
            ]
          },
          {
            options: {
              direction: 'column',
              grow: 0,
              padding,
              minWidth: itemMinWidth,
              hide: !options.hasMultiplePricebooks
            },
            contents: [
              {
                component: {
                  edit: 'SelectInput',
                  view: 'LinkButton'
                },
                source: 'priceBookId',
                options: {
                  label: 'Pricebook',
                  inputOptions: options.priceBooks,
                  isSearchable: true,
                  linkButtonLabel: 'Pricebook'
                }
              }
            ]
          },
          {
            options: {
              direction: 'column',
              grow: 0,
              padding,
              minWidth: itemMinWidth
            },
            contents: [
              options.isSageEnabled && {
                component: {
                  edit: 'SelectInput',
                  view: 'FieldWithLabel'
                },
                source: 'sageJobId',
                options: {
                  label: 'Sage Job',
                  inputOptions: options.sageJobs,
                  isSearchable: true
                }
              },
              options.isSpectrumEnabled && {
                component: {
                  edit: 'TextInput',
                  view: 'FieldWithLabel'
                },
                source: 'accountingJobNumber',
                options: {
                  label: 'Spectrum Job'
                }
              }
            ].filter(Boolean)
          }
        ].filter(Boolean)
      },
      ...customLayouts
    ]
  };
};

const jobMainLayoutWrapper = (
  options,
  { fields = {}, componentArr = [], validation = {}, validationErrors = {} }
) => {
  const { user, isSpectrumEnabled, isVistaEnabled } = options;
  const isDepartmentRequired = isSpectrumEnabled || isVistaEnabled;
  const generatedFields = generateDefaultFieldsObject(jobMainFields({ isDepartmentRequired }));
  const generatedValidation = generateDefaultValidationObject(
    jobMainFields({ isDepartmentRequired })
  );
  const mergedValidation = merge(validation, generatedValidation.validation);

  const hasServiceAgreements = checkPermission(
    'allow',
    PermissionConstants.OBJECT_SERVICE_AGREEMENT,
    user,
    null,
    'serviceAgreements'
  );
  const hasMultiplePricebooks = checkPermission(
    'read',
    PermissionConstants.OBJECT_JOB, // Intentionally making it job as we need to check only feature gate. There is no perm for Pricebook
    user,
    null,
    FeatureGateConstants.MULTIPLE_PRICE_BOOKS
  );
  const optionsWithPermissions = {
    ...options,
    isDepartmentRequired,
    hasServiceAgreements,
    hasMultiplePricebooks
  };
  return {
    fields: { ...generatedFields, ...fields },
    validation: mergedValidation,
    validationErrors: { ...generatedValidation.validationErrors, ...validationErrors },
    layouts: {
      view: jobMainLayout(optionsWithPermissions, componentArr),
      edit: jobMainLayout(optionsWithPermissions, componentArr)
    }
  };
};

const jobSidebarLayout = options => {
  const { user, disableEditAmountQuoted, disableEditCostAmount, jobQuoteStatusFlag } = options;
  let fieldsBySource = [
    { source: 'customer', viewComponent: 'LinkButton', disableEdit: true },
    { source: 'propertyType', disableEdit: true },
    { source: 'address', disableEdit: true },
    { source: 'location', viewComponent: 'LocationView', disableEdit: true },
    {
      source: 'authorizedBy',
      editComponent: 'SelectInput',
      isSearchable: true,
      inputOptions: options.authorizedByList,
      creatable: true,
      onCreate: options.onCreatePropRep
    },
    {
      source: 'propertyRepresentative',
      editComponent: 'SelectInput',
      isSearchable: true,
      inputOptions: options.propertyRepList,
      creatable: true,
      createLabel: 'Property Representative',
      onCreate: options.onCreatePropRep
    },
    { source: 'bestContactMethod', disableEdit: true },
    {
      source: 'propertyInstructions',
      editComponent: 'Notes',
      viewComponent: 'Notes'
    },
    { source: 'purchaseOrder', label: 'Purchase Order (Customer)', overflowWrap: 'anywhere' },
    { source: 'workOrder', label: 'Work Order (Customer)', overflowWrap: 'anywhere' },
    {
      source: 'amountQuoted',
      viewComponent: jobQuoteStatusFlag ? 'LabelWithTip' : null,
      editComponent: 'CurrencyInput',
      type: 'currency',
      disableEdit: disableEditAmountQuoted
    },
    { source: 'amountNotToExceed', editComponent: 'CurrencyInput', type: 'currency' },
    {
      source: 'costAmount',
      editComponent: 'CurrencyInput',
      viewComponent: jobQuoteStatusFlag ? 'LabelWithTip' : null,
      type: 'currency',
      disableEdit: disableEditCostAmount
    }
  ];

  const hasShowFinanacialDataAccess = checkPermission(
    'allow',
    PermissionConstants.DATA_SHOW_FINANCIAL_DATA,
    user
  );
  const hasNTEAccess = checkPermission('allow', PermissionConstants.DATA_VIEW_NTE_DATA, user);

  if (!hasShowFinanacialDataAccess) {
    fieldsBySource = fieldsBySource.filter(
      item => !['amountQuoted', 'costAmount'].includes(item.source)
    );
  }

  if (!hasNTEAccess) {
    fieldsBySource = fieldsBySource.filter(item => item.source !== 'amountNotToExceed');
  }

  function standardField(
    source,
    isEditable = true,
    viewComponent,
    editComponent,
    verticalPadding,
    endVariant = 'none',
    fieldOptions
  ) {
    const { label } = fieldOptions;
    const finalLabel = label ?? camelCaseToTitleCase(source);
    return {
      options: {
        direction: 'column',
        paddingTop: endVariant !== 'firstChild' && verticalPadding,
        paddingBottom: endVariant !== 'lastChild' && verticalPadding
      },
      contents: [
        {
          component: {
            edit: isEditable ? editComponent : viewComponent,
            view: viewComponent
          },
          source,
          options: {
            label: finalLabel,
            ...fieldOptions
          }
        }
      ]
    };
  }

  // In px
  const verticalPadding = 8;

  const { ACTIVE } = AppConstants;
  const billingCustomerComponent = {
    options: { direction: 'column', paddingBottom: verticalPadding },
    contents: [
      {
        component: {
          edit: 'AlgoliaSelect',
          view: 'LinkButton'
        },
        source: 'billingCustomer',
        options: {
          label: 'Billing Customer',
          placeholder: 'Search...',
          fullWidth: true,
          alignment: 'left',
          searchIndexName: defaultSearchIndex,
          onChange: options.onSearchChange,
          searchResultDisplayFields: ['customerName', 'address'],
          value: '',
          delimiter: ' ',
          locatedInPopUp: true,
          filters: [
            {
              attribute: 'entityType',
              valueArray: ['CompanyAddress']
            },
            {
              attribute: 'parentEntityType',
              valueArray: ['Customer']
            },
            {
              parenthetical: true,
              operand: 'OR',
              attributeArray: ['status', 'parentStatus'],
              valueArray: [ACTIVE, ACTIVE]
            }
          ]
        }
      }
    ]
  };

  const propertyComponent = {
    options: { direction: 'column', paddingBottom: verticalPadding },
    contents: [
      {
        component: {
          edit: 'AlgoliaSelect',
          view: 'LinkButton'
        },
        source: 'property',
        options: {
          label: 'Property',
          placeholder: 'Search...',
          fullWidth: true,
          alignment: 'left',
          searchIndexName: defaultSearchIndex,
          onChange: options.onPropertySearchChange,
          searchResultDisplayFields: ['customerPropertyName', 'address'],
          filters: [
            {
              attribute: 'entityType',
              valueArray: ['CompanyAddress']
            },
            {
              attribute: 'parentEntityType',
              valueArray: ['CustomerProperty']
            }
          ]
        }
      }
    ]
  };

  const customComponentsToAdd = [
    { component: billingCustomerComponent, index: 1 },
    { component: propertyComponent, index: 2 }
  ];

  const editedFieldsBySource = fieldsBySource.map((field, index) => {
    let endVariant = 'none';
    const { source, viewComponent, editComponent, ...fieldOptions } = field;
    if (index === 0) endVariant = 'firstChild';
    else if (index === fieldsBySource.length - 1) endVariant = 'lastChild';
    return standardField(
      source,
      !field.disableEdit,
      viewComponent || 'FieldWithLabel',
      editComponent || 'TextInput',
      verticalPadding,
      endVariant,
      fieldOptions
    );
  });

  const sidebarComponents = customComponentsToAdd.reduce(
    (acc, item) => arrayInsert(acc, item.index, item.component),
    editedFieldsBySource
  );

  return {
    options: {
      pageSize: 'LETTER',
      grow: 0
    },
    contents: [
      // Entire sidebar is comprised of single column
      {
        options: {
          direction: 'column'
        },
        contents: sidebarComponents
      }
    ]
  };
};

const jobSidebarLayoutWrapper = options => ({
  fields: generateDefaultFieldsObject(jobSidebarFields),
  ...generateDefaultValidationObject(jobSidebarFields),
  layouts: {
    view: jobSidebarLayout(options),
    edit: jobSidebarLayout(options)
  }
});

export { jobMainLayoutWrapper as jobMainLayout, jobSidebarLayoutWrapper as jobSidebarLayout };
