import { column, ColumnType, valueGetters } from 'components/XGrid/columnTypes';
import { noNewLineValueSetter } from 'components/XGrid/columnUtils';
import { MultiSelectTypes } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';

const purchaseOrdersTableMeta = theme => [
  {
    field: 'poNumber',
    headerName: 'PO Number',
    width: 140,
    valueGetter: valueGetters.purchaseOrderLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    enumType: MultiSelectTypes.PURCHASE_ORDER_STATUS,
    customTheme: theme,
    ...column[ColumnType.ENUM]
  },
  {
    field: 'isFieldOrder',
    headerName: 'Field Order',
    width: 130,
    ...column[ColumnType.BOOL]
  },
  {
    field: 'vendorName',
    headerName: 'Vendor',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 150,
    ...noNewLineValueSetter,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'departmentName',
    headerName: 'Department',
    width: 150,
    enumType: MultiSelectTypes.DEPARTMENTS,
    ...column[ColumnType.TAGS]
  },
  {
    field: 'jobOrProjectName',
    headerName: 'Job / Project',
    width: 150,
    valueGetter: valueGetters.jobOrMaintenanceLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'totalCost',
    headerName: 'Total',
    width: 150,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'purchaseOrderTags',
    headerName: 'Tags',
    width: 100,
    enumType: MultiSelectTypes.PURCHASE_ORDER_TAGS,
    ...column[ColumnType.TAGS]
  },
  {
    field: 'assignedTo',
    headerName: 'Assigned To',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'createdDate',
    headerName: 'Created On',
    width: 150,
    ...column[ColumnType.DATE]
  }
];

export default purchaseOrdersTableMeta;
