import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { useSnackbar } from 'customHooks/useSnackbar';
import { logErrorWithCallback } from 'utils';

import { timesheetEntryBinderFragment } from '../gql';

export const reopenTimesheetMutation = gql`
  mutation reopenTimesheet(
    $data: ReopenTimesheetInput!
  ) {
    reopenTimesheet(data: $data) {
      ${timesheetEntryBinderFragment}
    }
  }
`;

const serializer = ({ employeeId, startDate, reason = '' }) => {
  return {
    variables: {
      data: {
        employeeId,
        startDate,
        reason
      }
    }
  };
};

const useReopenTimesheet = () => {
  const snackbar = useSnackbar();

  return useExtendedMutation(reopenTimesheetMutation, {
    serializer,
    onError: error => {
      logErrorWithCallback(error, snackbar, 'Unable to reopen timesheet');
    },
    onCompleted: () => {
      snackbar('success', 'Successfully reopened timesheet');
    }
  });
};

export default useReopenTimesheet;
