import React, { useMemo } from 'react';

import { ButtonType, MultiSelect } from '@BuildHero/sergeant';

import { useConfirmModal } from 'customHooks/ConfirmModalContext';

const changeChecklistConfirm = {
  body:
    'By adding or removing a checklist, all maintenance information (from step 4) for properties will be erased.',
  title: 'Add or Remove Checklist',
  buttonLabel: 'Continue',
  buttonType: ButtonType.ERROR
};

// @TODO write tests around the selection logic.
const AssetChecklists = ({
  cell,
  assetTypeChecklistMap,
  selectedAssetChecklistMap,
  checklistMap,
  onSelectionModelChange,
  deleteAllTemplateGroupsOnServiceAgreement,
  hasMetadata,
  refreshServiceAgreementPropertyMetadata
}) => {
  const { assetTypeId } = cell.row;

  const confirmContext = useConfirmModal();

  const checklistOptions = useMemo(
    () =>
      assetTypeChecklistMap[assetTypeId].map(l => ({
        id: l.id,
        label: l.name,
        value: l
      })),
    [assetTypeChecklistMap, assetTypeId]
  );

  return (
    <MultiSelect
      options={checklistOptions}
      placeholder="Select checklists"
      selectedOptions={selectedAssetChecklistMap[cell.row.id].map(checklistId => ({
        id: checklistId,
        label: checklistMap[checklistId].name,
        value: checklistMap[checklistId]
      }))}
      showChips
      onChange={async newSelectedOptions => {
        if (hasMetadata) {
          if (!(await confirmContext.confirm(changeChecklistConfirm))) {
            onSelectionModelChange({
              ...selectedAssetChecklistMap
            });
            return;
          }

          await deleteAllTemplateGroupsOnServiceAgreement();
          await refreshServiceAgreementPropertyMetadata();
        }

        const assetId = cell.row.id;

        let newSelectedAssetChecklistMap;
        if (!newSelectedOptions.length) {
          // deselect this asset
          newSelectedAssetChecklistMap = Object.entries(selectedAssetChecklistMap).reduce(
            (acc, [aId, lIds]) => {
              if (aId === assetId) return acc;
              return {
                ...acc,
                [aId]: lIds
              };
            },
            {}
          );
        } else {
          newSelectedAssetChecklistMap = {
            ...selectedAssetChecklistMap,
            [assetId]: newSelectedOptions.map(o => o.id)
          };
        }
        onSelectionModelChange(newSelectedAssetChecklistMap);
      }}
      onClickCreateOption={() => {}}
    />
  );
};

export default AssetChecklists;
