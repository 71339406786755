import { useCallback } from 'react';

import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { mutationOperations, updateTaskTemplateCacheForTaskTemplateSAQuery } from '../helper';

import { taskTemplateChecklistsOnServiceAgreement } from './useTaskTemplateChecklistsOnServiceAgreement';

const REMOVE_TASK_TEMPLATES_FROM_SA = gql`
  mutation removeTaskTemplatesFromServiceAgreement(
    $serviceAgreementId: String!
    $propertyAssetId: String
    $customerPropertyId: String
    $input: [String!]!
  ) {
    removeTaskTemplatesFromServiceAgreement(
      serviceAgreementId: $serviceAgreementId
      customerPropertyId: $customerPropertyId
      propertyAssetId: $propertyAssetId
      input: $input
    )
  }
`;

export const useRemoveTaskTemplateFromServiceAgreement = ({
  serviceAgreementId,
  propertyAssetId,
  customerPropertyId,
  checklistId,
  ...options
}) => {
  const update = useCallback(
    (cache, { data: { removeTaskTemplatesFromServiceAgreement } }) => {
      const variables = { serviceAgreementId };
      if (propertyAssetId) {
        variables.propertyAssetId = propertyAssetId;
      } else {
        variables.customerPropertyId = customerPropertyId;
      }

      const cachedData = cache.readQuery({
        query: taskTemplateChecklistsOnServiceAgreement,
        variables
      });

      if (!cachedData) return;

      const updatedTaskTemplateChecklists = updateTaskTemplateCacheForTaskTemplateSAQuery(
        cachedData,
        checklistId,
        removeTaskTemplatesFromServiceAgreement,
        mutationOperations.DELETE
      );

      cache.writeQuery({
        query: taskTemplateChecklistsOnServiceAgreement,
        variables,
        data: { taskTemplateChecklistsOnServiceAgreement: updatedTaskTemplateChecklists }
      });
    },
    [checklistId, customerPropertyId, propertyAssetId, serviceAgreementId]
  );

  return useExtendedMutation(REMOVE_TASK_TEMPLATES_FROM_SA, {
    serializer: ({ id }) => ({
      variables: {
        serviceAgreementId,
        propertyAssetId,
        customerPropertyId: propertyAssetId ? undefined : customerPropertyId,
        input: [id]
      }
    }),
    update,
    onError: (transformedData, snackbarOn) => snackbarOn('error', 'Unable to Delete Task template'),
    ...options
  });
};
