import gql from 'graphql-tag';
import compact from 'lodash/compact';
import orderBy from 'lodash/orderBy';
import { arrayOf, bool, shape, string } from 'prop-types';

import { ERROR_PROP } from '@dispatch/Dispatch.constants';
import { watchedQueries } from '@dispatch/Dispatch.store';
import useExtendedQuery from 'customHooks/useExtendedQuery';

import { selectPosition } from './useVisitDetails';

const GET_DISPATCH_TECHS = gql`
  query getDispatchTechs {
    getDispatchTechs {
      id
      name
      isTech
      isAdmin
      contactAddresses {
        items {
          id
          addressType
          addressLine1
          addressLine2
          city
          state
          zipcode
          latitude
          longitude
        }
      }
      departments {
        items {
          id
        }
      }
      profilePictureUrl
      appRoles {
        items {
          mappedEntity {
            ... on AppRole {
              id
              tagName
            }
          }
        }
      }
    }
  }
`;

const DEFAULT_DATA = [];

const transformDispatchTechs = data => {
  const techs = data.getDispatchTechs.map(tech => {
    const address = tech.contactAddresses.items?.[0] || {};
    const position = selectPosition(address);

    return {
      ...tech,
      name: tech?.name ?? '',
      address,
      position,
      departments: compact(tech.departments?.items?.map(department => department?.id) || []),
      role: tech.appRoles.items[0]?.mappedEntity?.tagName,
      isTech: !!tech?.isTech,
      isAdmin: !!tech?.isAdmin
    };
  });

  return orderBy(techs, tech => tech.name.toLowerCase());
};

export const useDispatchTechs = () => {
  watchedQueries.useDispatchTechs = {
    query: GET_DISPATCH_TECHS
  };

  return useExtendedQuery(GET_DISPATCH_TECHS, {
    defaultData: DEFAULT_DATA,
    errorData: [],
    transform: transformDispatchTechs
  });
};

export const TECH_PROP = shape({
  id: string,
  name: string,
  profilePictureUrl: string,
  role: string,
  status: string
});

export const TECHS_RESPONSE_PROP = shape({
  loading: bool,
  error: ERROR_PROP,
  data: arrayOf(TECH_PROP),
  status: null
});
