import React from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    width: 296,
    display: 'flex',
    flexDirection: 'column'
  },
  progressLabel: {
    fontSize: '10px',
    color: '#999999',
    textTransform: 'uppercase',
    marginBottom: 3
  },
  barContainer: {
    height: 16,
    borderRadius: 10,
    backgroundColor: '#F0F0F0'
  },
  barInnerContainer: {
    display: 'flex',
    margin: '3px 7px 3px 12px',
    height: 10
  },
  bar: {
    marginLeft: -5,
    borderRadius: 5
  }
}));

const CustomMulticolorLinearProgress = props => {
  const classes = useStyles();
  const { label, data, style } = props;

  const bars = data
    ? data.map(item => (
        <Box
          className={classes.bar}
          key={item.status}
          style={{ backgroundColor: item.color, width: `calc(${item.value}% + 5px)` }}
        />
      ))
    : null;

  return (
    <Box className={classes.root} style={style}>
      {label && <Typography className={classes.progressLabel}>{label}</Typography>}
      <Box className={classes.barContainer}>
        <Box className={classes.barInnerContainer}>{bars}</Box>
      </Box>
    </Box>
  );
};

CustomMulticolorLinearProgress.propTypes = {
  label: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  style: PropTypes.object
};

CustomMulticolorLinearProgress.defaultProps = {
  style: {}
};

export default CustomMulticolorLinearProgress;
