import React, { useEffect, useState } from 'react';

import gql from 'graphql-tag';
import { useFlags } from 'launchdarkly-react-client-sdk';

import configForEnvironment from 'configs/aws-exports';
import ENV from 'configs/env';
import AmplifyService from 'services/AmplifyService';
import { Logger } from 'services/Logger';

const getMetabaseJWT = gql`
  query q {
    getMetabaseJWT
  }
`;

const PATH = '/auth/sso?jwt=';
const RETURN_TO = '&return_to=/';

const MetabaseAppEmbed = ({ title = 'Metabase', className, style = {} }) => {
  const flags = useFlags();

  const [src, setSrc] = useState();

  const config = configForEnvironment(ENV);
  const { METABASE_URL } = config;

  const appSyncClient = AmplifyService.appSyncClient();
  useEffect(() => {
    (async () => {
      try {
        const { data } = await appSyncClient.client.query({
          query: getMetabaseJWT
        });
        setSrc(`${METABASE_URL}${PATH}${data.getMetabaseJWT}${RETURN_TO}`);
      } catch (error) {
        Logger.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!flags.metabase) return null;

  return src ? (
    <iframe
      className={className}
      src={src}
      style={{ border: 'none', width: '100%', height: 'calc(100vh - 64px - 48px)', ...style }}
      title={title}
    />
  ) : (
    <p>Loading...</p>
  );
};

export default MetabaseAppEmbed;
