import { column, ColumnType, valueGetters } from 'components/XGrid/columnTypes';
import { MultiSelectTypes, OpenTaskStatus } from 'utils/AppConstants';

export const taskRowsMeta = ({
  renderFormsCell,
  renderPartsCell,
  renderMoreButton,
  associatedWorkXgridColumn,
  showQuoteTaskStatus = false,
  assetAgainstTaskEnabled,
  isCompleted = false,
  showVisitJob = true
} = {}) =>
  [
    assetAgainstTaskEnabled && {
      field: 'asset',
      headerName: 'Asset',
      width: 200,
      dataField: 'assetId',
      valueGetter: valueGetters.assetLink,
      ...column[ColumnType.LINK]
    },
    {
      field: 'name',
      headerName: 'Task',
      width: 200
    },
    showQuoteTaskStatus && {
      field: 'status',
      headerName: 'Status',
      width: 150,
      valueGetter: ({ row }) =>
        row.quote ? OpenTaskStatus.ADDED_TO_QUOTE : OpenTaskStatus.UNQUOTED,
      enumType: MultiSelectTypes.OPEN_TASK_STATUS,
      ...column[ColumnType.ENUM]
    },
    {
      field: 'description',
      headerName: 'Task Description',
      width: 300
    },
    {
      field: 'formData',
      headerName: 'Forms',
      width: 150,
      renderCell: ({ value }) => renderFormsCell(value)
    },
    {
      field: 'partsAndMaterialsCount',
      headerName: 'Parts & Materials',
      width: 150,
      type: ColumnType.TEXT,
      valueGetter: ({ row }) => row?.taskEntries?.items,
      renderCell: ({ value }) => renderPartsCell(value)
    },
    !isCompleted && {
      field: 'createdBy',
      headerName: 'Created By',
      width: 150
    },
    isCompleted && {
      field: 'lastUpdatedBy',
      headerName: 'Completed By',
      width: 150
    },
    isCompleted && {
      field: 'lastUpdatedDateTime',
      headerName: 'Completed Date',
      width: 150,
      ...column[ColumnType.DATE],
      valueGetter: ({ value }) => (value ? value / 1000 : '')
    },
    showVisitJob && {
      field: 'jobNumber',
      headerName: 'Job',
      width: 120,
      valueGetter: ({ row }) => {
        const jobNumber = row?.visit?.parentEntity?.jobNumber || '';
        return {
          label: jobNumber,
          to: `/job/view/${encodeURIComponent(jobNumber)}`
        };
      },
      ...column[ColumnType.LINK]
    },
    associatedWorkXgridColumn && { ...associatedWorkXgridColumn },
    {
      field: 'action', // doesnt matter as we are not using the value
      headerName: ' ',
      width: 36,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => renderMoreButton(row)
    }
  ].filter(Boolean);

export default taskRowsMeta;
