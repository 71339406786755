import React from 'react';

import PropTypes from 'prop-types';

import { JobTypes } from 'utils/AppConstants';

import LinkButton from '../LinkText/LinkButton';

export const JobLink = ({ record, meta, testingid }) => {
  const isMaintenanceJob = record?.jobTypeInternal === JobTypes.MAINTENANCE;
  return (
    <LinkButton
      label={record.customIdentifier || record.jobNumber}
      path={`/${isMaintenanceJob ? 'maintenance' : 'job'}/view/${encodeURIComponent(
        record.jobNumber
      )}`}
      style={{ fontWeight: meta?.bold ? 'bold' : 'normal', whiteSpace: 'nowrap' }}
      testingid={testingid}
    />
  );
};

JobLink.propTypes = {
  record: PropTypes.shape({
    customIdentifier: PropTypes.string.isRequired,
    jobNumber: PropTypes.number.isRequired,
    jobTypeInternal: PropTypes.string.isRequired
  }).isRequired,
  meta: PropTypes.shape({ bold: PropTypes.bool }),
  testingid: PropTypes.string
};

JobLink.defaultProps = {
  meta: {
    bold: false,
    testingid: 'jobLink'
  }
};

export default JobLink;
