import React, { useMemo } from 'react';

import { Select } from '@BuildHero/sergeant';

import { BoldCell } from 'components/WrapTable';

export const useBillingProductsColumns = ({ loading, billingHourTypes, productOptions, formik }) =>
  useMemo(
    () => [
      {
        headerName: 'Group',
        field: 'labourRateGroupName',
        width: 300,
        align: 'center',
        flex: 1,
        renderTotal: BoldCell,
        renderCell: ({ row, isGroupRow }) =>
          isGroupRow ? row.labourRateGroupName : row.labourTypeName
      },
      ...billingHourTypes.map(billingHourType => ({
        headerName: billingHourType.hourTypeAbbreviation,
        field: billingHourType.hourTypeAbbreviation,
        renderCell: ({ value, cellAddress, isGroupRow }) =>
          isGroupRow ? (
            <div style={{ width: '100%', height: '100%' }} />
          ) : (
            <Select
              defaultValue={value}
              disabled={loading}
              options={productOptions}
              placeholder="Billing Product for Labor Invoice Items"
              portal
              searchable
              onChange={newValue => {
                formik.setFieldValue(cellAddress, newValue);
              }}
            />
          )
      }))
    ],
    [billingHourTypes, loading, productOptions, formik]
  );
