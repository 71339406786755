import React, { useState } from 'react';

import { Button, Modal, TV, TW, Typography } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import FormSelector from 'components/FormSection/FormSelector';

import { renderFormData } from '../../ListView/index.helper';

const styles = {
  header: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  label: { display: 'flex', flexDirection: 'column', paddingBottom: 8 },
  modal: {
    minWidth: '40%',
    '& div[id*="MuiPaper-root"]': {
      minWidth: '40%'
    }
  }
};

const CustomFormComponent = props => {
  const { form } = props;
  const { addedForms } = form.values;
  const [openModal, setOpenModal] = useState(false);

  const updateCB = data => {
    setOpenModal(false);
    form.setFieldValue('addedForms', [...addedForms, { ...data, id: data.formId }]);
  };

  const deleteCB = item => {
    const modifiedForms = addedForms.filter(
      f => f.formId !== item.formId || f.formDataId !== item.formDataId
    );
    form.setFieldValue('addedForms', modifiedForms);
  };

  return (
    <div>
      <div css={styles.header}>
        <div css={styles.label}>
          <Typography style={{ fontSize: 16, paddingBottom: 4 }} weight={TW.BOLD}>
            Forms
          </Typography>
          <Typography variant={TV.S1}>
            Add forms to provide context for quote creation. Forms added here will become quote
            attachments.
          </Typography>
        </div>
        <Button
          size="small"
          startIcon={<AddCircleOutlineIcon />}
          type="tertiary"
          onClick={() => setOpenModal(true)}
        >
          Add Form
        </Button>
      </div>
      {renderFormData(addedForms, deleteCB)}
      <Modal fullWidth maxWidth="md" open={openModal} onClose={() => setOpenModal(false)}>
        <FormSelector
          callback={updateCB}
          css={styles.modal}
          handleCancel={() => setOpenModal(false)}
          skipMutation
        />
      </Modal>
    </div>
  );
};

export default CustomFormComponent;
