import React from 'react';

import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  getCloudinaryImageUrl,
  getCombinedAddressFromProjectData
} from 'scenes/ProjectManagement/components/utils';
import { getCombinedAddress } from 'utils';
import { AppConstants } from 'utils/AppConstants';

import styles from './PackagePdf.styles';

const PDFDocument = props => {
  const { companyInfo, projectData, packageData, packageItems, sendTo, returnTo } = props;

  const showItemSpecCol = packageItems?.some(item => item.specSection);
  const showItemSubCol = packageItems?.some(item => item.subSection);
  const showItemDescriptionCol = packageItems?.some(item => item.description);
  const showItemTypeCol = packageItems?.some(item => item.type);

  return (
    <Document title={`Submittal Package ${packageData?.number}`}>
      <Page size="letter" style={styles.root}>
        <View>
          {/* Logo and address */}
          {companyInfo && (
            <View style={[styles.displayFlex, { marginTop: 10, alignItems: 'flex-start' }]}>
              {companyInfo?.logoUrl ? (
                <Image src={getCloudinaryImageUrl(companyInfo?.logoUrl)} style={styles.logoImage} />
              ) : (
                <View />
              )}
              <View style={[styles.displayFlexColumn, { minWidth: 150, alignItems: 'flex-end' }]}>
                <Text style={[styles.text]}>
                  {`${companyInfo?.companyAddresses?.addressLine1}, ${companyInfo?.companyAddresses?.addressLine2}`}
                </Text>
                <Text style={[styles.text]}>
                  {`${companyInfo?.companyAddresses?.city}, ${companyInfo?.companyAddresses?.state}, ${companyInfo?.companyAddresses?.zipcode}`}
                </Text>
                <Text style={[styles.text]}>{companyInfo?.phonePrimary || null}</Text>
              </View>
            </View>
          )}
          {/* package number and date */}
          <View style={[styles.displayFlex, { marginTop: 20 }]}>
            <View style={styles.displayFlex}>
              <Text style={styles.numberTitle}>Submittal Package </Text>
              <Text style={styles.numberSubtitle}>
                {packageData?.number ? `${projectData.number} - ${packageData?.number}` : null}
              </Text>
            </View>
            <Text style={styles.date}>{moment().format(AppConstants.DATE_FORMAT)}</Text>
          </View>
          {/* project data */}
          <View style={[styles.displayFlex, { marginTop: 20, justifyContent: 'flex-start' }]}>
            <View style={[styles.displayFlexColumn, { minWidth: '15%', marginRight: 8 }]}>
              <Text style={styles.labelNormal}>PROJECT NAME</Text>
              <Text style={styles.text}>{projectData.name || '-'}</Text>
            </View>
            <View style={[styles.displayFlexColumn, { minWidth: '15%', marginRight: 8 }]}>
              <Text style={styles.labelNormal}>PROJECT NUMBER</Text>
              <Text style={styles.text}>{projectData.number || '-'}</Text>
            </View>
            <View style={[styles.displayFlexColumn, { minWidth: '35%', marginRight: 8 }]}>
              <Text style={styles.labelNormal}>PROJECT ADDRESS</Text>
              <Text style={styles.text}>
                {getCombinedAddressFromProjectData(projectData) || '-'}
              </Text>
            </View>
            <View style={[styles.displayFlexColumn, { minWidth: '15%' }]}>
              <Text style={styles.labelNormal}>DUE DATE</Text>
              <Text style={styles.text}>
                {packageData.dueDate
                  ? moment.unix(packageData.dueDate).format(AppConstants.DATE_FORMAT)
                  : '-'}
              </Text>
            </View>
          </View>
          {/* To | From */}
          <View style={[styles.displayFlex, { marginTop: 20, alignItems: 'flex-start' }]}>
            <View style={[styles.displayFlexColumn, { width: '20%', marginRight: 8 }]}>
              <Text style={styles.sectionTitle}>To</Text>
              <Text style={styles.labelNormal}>NAME</Text>
              <Text style={[styles.text, styles.marginBottom6]}>{sendTo?.name || '-'}</Text>
              <Text style={styles.labelNormal}>COMPANY</Text>
              <Text style={styles.text}>{sendTo?.parentEntity?.customerName || '-'}</Text>
            </View>
            <View style={[styles.displayFlexColumn, { width: '28%', marginRight: 8 }]}>
              <Text style={styles.sectionTitle}> </Text>
              <Text style={styles.labelNormal}>EMAIL</Text>
              <Text style={[styles.text, styles.marginBottom6]}>{sendTo?.email || '-'}</Text>
              <Text style={styles.labelNormal}>ADDRESS</Text>
              <Text style={styles.text}>
                {getCombinedAddress(sendTo?.parentEntity?.companyAddresses[0]) || '-'}
              </Text>
            </View>
            <View style={[styles.displayFlexColumn, { width: '20%', marginRight: 8 }]}>
              <Text style={styles.sectionTitle}>From</Text>
              <Text style={styles.labelNormal}>NAME</Text>
              <Text style={[styles.text, styles.marginBottom6]}>{returnTo?.name || '-'}</Text>
              <Text style={styles.labelNormal}>COMPANY</Text>
              <Text style={styles.text}>
                {returnTo?.company ? returnTo?.company?.companyName : '-'}
              </Text>
            </View>
            <View style={[styles.displayFlexColumn, { width: '28%' }]}>
              <Text style={styles.sectionTitle}> </Text>
              <Text style={styles.labelNormal}>EMAIL</Text>
              <Text style={[styles.text, styles.marginBottom6]}>{returnTo?.email || '-'}</Text>
              <Text style={styles.labelNormal}>ADDRESS</Text>
              <Text style={styles.text}>
                {getCombinedAddress(returnTo?.company?.companyAddresses) || '-'}
              </Text>
            </View>
          </View>
          {/* Divider */}
          <View style={styles.divider} />
          {/* Subject */}
          <View style={styles.displayFlexColumn}>
            <Text style={styles.sectionTitle}>Subject</Text>
            <Text style={styles.text}>{packageData?.subject || 'No subject'}</Text>
          </View>
          {/* Divider */}
          <View style={styles.divider} />
          {/* Notes */}
          {packageData?.specDescription && (
            <>
              <View style={styles.displayFlexColumn}>
                <Text style={styles.sectionTitle}>Notes</Text>
                <Text style={styles.text}>{packageData.specDescription}</Text>
              </View>
              {/* Divider */}
              <View style={styles.divider} />
            </>
          )}

          {/* Package Items */}
          {packageItems?.length > 0 && (
            <>
              <View style={styles.displayFlexColumn}>
                <Text style={styles.sectionTitle}>Package Items</Text>
                {/* Table header */}
                <View style={styles.displayFlex}>
                  {showItemSpecCol && (
                    <Text style={[styles.labelNormal, { width: '15%', marginRight: 8 }]}>Spec</Text>
                  )}
                  {showItemSubCol && (
                    <Text style={[styles.labelNormal, { width: '15%', marginRight: 8 }]}>
                      Subsection
                    </Text>
                  )}
                  {showItemDescriptionCol && (
                    <Text style={[styles.labelNormal, { minWidth: '46%', marginRight: 8 }]}>
                      Description
                    </Text>
                  )}
                  {showItemTypeCol && (
                    <Text style={[styles.labelNormal, { width: '17%' }]}>Type</Text>
                  )}
                </View>
                {packageItems.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <View key={`packageCover-item-${item.id}-${index}`} style={styles.displayFlex}>
                    {showItemSpecCol && (
                      <Text
                        style={[styles.text, styles.textBold, { width: '15%', marginRight: 8 }]}
                      >
                        {item.specSection}
                      </Text>
                    )}
                    {showItemSubCol && (
                      <Text style={[styles.text, { width: '15%', marginRight: 8 }]}>
                        {item.subSection}
                      </Text>
                    )}
                    {showItemDescriptionCol && (
                      <Text style={[styles.text, { minWidth: '46%', marginRight: 8 }]}>
                        {item.description}
                      </Text>
                    )}
                    {showItemTypeCol && (
                      <Text style={[styles.text, { width: '17%' }]}>{item.type}</Text>
                    )}
                  </View>
                ))}
              </View>
              <View style={styles.divider} />
            </>
          )
          // EOD
          }
        </View>
        <View style={[styles.displayFlex, { justifyContent: 'flex-start', flexWrap: 'wrap' }]}>
          {packageData?.submittalPackageAttachments
            ?.filter(attachment => attachment.fileType.includes('image') && !attachment._deleted)
            .map(image => (
              <Image
                src={image.fileUrl}
                style={{ maxWidth: '19%', maxHeight: 70, marginRight: 3, marginBottom: 3 }}
              />
            ))}
        </View>
      </Page>
    </Document>
  );
};

PDFDocument.propTypes = {
  companyInfo: PropTypes.object.isRequired,
  packageData: PropTypes.object.isRequired,
  projectData: PropTypes.object.isRequired,
  packageItems: PropTypes.array.isRequired,
  sendTo: PropTypes.object.isRequired,
  returnTo: PropTypes.object.isRequired
};

export default PDFDocument;
