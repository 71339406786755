import { getMutationParams } from 'scenes/Customer/PropertyDetail/Tasks/AddTaskToQuote';
import { updateTaskService } from 'scenes/Quotes/service';
import { CustomerPropertyService, QuoteService } from 'services/core';
import { logErrorWithCallback } from 'utils';

export const handleBulkDeleteRecommendations = async ({
  bulkDeleteTasks,
  refetchTaskList,
  refetchTrigger,
  selectionModel,
  setIsLoading,
  setRefetchTrigger,
  setSelectionModel,
  tenantId
}) => {
  if (!selectionModel.length) return;
  setIsLoading(true);
  const response = await bulkDeleteTasks({ tenantId, taskIds: selectionModel });
  if (response) {
    refetchTaskList(tenantId);
    setRefetchTrigger(!refetchTrigger);
  }
  setSelectionModel([]);
  setIsLoading(false);
};

export const handleBulkMoreInfoNeeded = async ({
  batchMutatePropertyTasks,
  recommendedTaskResponse,
  refetchTrigger,
  selectionModel,
  setIsLoading,
  setRefetchTrigger,
  setSelectionModel,
  tenantId
}) => {
  if (!selectionModel.length) return;
  setIsLoading(true);
  const tasks = selectionModel.map(item => ({ id: item, moreInfoNeeded: true }));
  const customerPropertyId = (recommendedTaskResponse?.items || []).find(
    task => task.id === selectionModel[0]
  )?.parentId;
  const data = { customerPropertyId, tasks };
  const response = await batchMutatePropertyTasks({ tenantId, data });
  if (response) {
    setRefetchTrigger(!refetchTrigger);
  }
  setSelectionModel([]);
  setIsLoading(false);
};

export const handleAddToExistingQuote = async ({
  recommendedTaskResponse,
  selectionModel,
  setAddTasksToQuoteModalOpen,
  setCustPropertyId,
  setTasksToAddToQuote,
  snackbarOn
}) => {
  try {
    const propertyId = (recommendedTaskResponse?.items || []).find(
      task => task.id === selectionModel[0]
    )?.parentId;
    const customerPropertyServiceObj = new CustomerPropertyService();
    const promises = selectionModel.map(async taskId => {
      const response = await customerPropertyServiceObj.getTaskById(taskId);
      const selectedTask = recommendedTaskResponse.items.find(task => task.id === taskId);
      return {
        taskEntries: response,
        id: taskId,
        assetId: selectedTask?.assetId,
        description: selectedTask?.description,
        name: selectedTask?.name
      };
    });
    const tasksPayload = await Promise.all(promises);
    setTasksToAddToQuote(tasksPayload);
    setCustPropertyId(propertyId);
    setAddTasksToQuoteModalOpen(true);
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to add tasks to existing quote');
  }
};

export const handleAddToNewQuote = async ({
  companyEmployees,
  history,
  recommendedTaskResponse,
  selectionModel,
  setAddQuoteLoading,
  snackbarOn,
  tenantId
}) => {
  try {
    setAddQuoteLoading(true);
    const quoteService = new QuoteService();
    const propertyId = (recommendedTaskResponse?.items || []).find(
      task => task.id === selectionModel[0]
    )?.parentId;
    const createValues = {
      customerPropertyId: propertyId
    };
    const response = await quoteService.addQuoteToCustomerProperty(tenantId, createValues, {
      employees: companyEmployees
    });
    const result = response.data?.addQuotesToCustomerProperty;
    if (result?.length > 0) {
      const addTasksToQuotePromises = selectionModel.map(async taskId => {
        const customerPropertyServiceObj = new CustomerPropertyService();
        const taskResponse = await customerPropertyServiceObj.getTaskById(taskId);
        const selectedTask = recommendedTaskResponse.items.find(task => task.id === taskId);
        const formattedTask = {
          taskEntries: taskResponse,
          id: taskId,
          assetId: selectedTask?.assetId,
          description: selectedTask?.description,
          name: selectedTask?.name
        };
        const quote = { data: result[0] };
        const mutationParams = getMutationParams(quote, [formattedTask], tenantId, propertyId);
        return updateTaskService({ ...mutationParams[0], skipDispatch: true });
      });
      await Promise.all(addTasksToQuotePromises);
      snackbarOn(
        'success',
        `Quote with recommended tasks created successfuly ${result[0].customIdentifier ||
          result[0].quoteNumber}`
      );
      history.push(`/quote/${result[0].id}`);
    }
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to add tasks to new quote');
  } finally {
    setAddQuoteLoading(false);
  }
};
