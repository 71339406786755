import React, { useMemo, useState } from 'react';

import {
  Button,
  CurrencyInput,
  Field,
  FieldType,
  Input,
  MoreButton,
  MUIForm,
  ThemeProvider
} from '@BuildHero/sergeant';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { useTheme, withStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckIcon from '@material-ui/icons/Check';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { debounce } from 'lodash';
import moment from 'moment';
import { Draggable } from 'react-beautiful-dnd';

import DragIndicator from 'assets/Icons/Handle.svg';
import { AuditLogs, SergeantModal } from 'components';
import Modal from 'components/Modal';
import ErrorBoundaries from 'scenes/Error';
import ReadonlyWrapper from 'scenes/Quotes/components/ReadonlyWrapper';
import { SettingConstants } from 'scenes/Quotes/constants';
import { overridesAreEnabled } from 'scenes/Quotes/utils/helper';
import { Logger } from 'services/Logger';
import { getTenantSettingValueForKey } from 'utils';
import AppConstants, { TaskConstants } from 'utils/AppConstants';
import { EntityType, Mode } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import AssetSelect from './TaskAccordian/AssetSelect';
import AddLaborModal from './TaskAccordian/LaborModal';
import { formatLaborLineItemsForDisplay } from './TaskAccordian/LaborModal/LaborModal.utils';
import LaborSelect from './TaskAccordian/LaborSelect';
import PartsSelect from './TaskAccordian/PartsSelect';
import { updateTasksAndGroups } from './TaskGroups/TaskGroup.utils';
import createTasksLayout from './Tasks.layout';
import useStyles from './Tasks.styles';

const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 'none'
    },
    margin: '0 0 0 0 !important',
    '&:before': {
      display: 'none'
    }
  },
  expanded: {
    margin: '0 0 0 0 !important'
  }
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    minHeight: 0,
    '&$expanded': {
      padding: 0,
      minHeight: 0
    }
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: '0'
    }
  },
  expanded: {}
})(props => <MuiAccordionSummary {...props} />);

AccordionSummary.muiName = 'AccordionSummary';

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiAccordionDetails);

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  paddingTop: '16px',
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'none',

  // styles we need to apply on draggables
  ...draggableStyle
});

function TaskCard({
  item,
  index,
  groupIndex,
  taskGroups,
  setTaskGroups,
  deleteTaskService,
  updateTaskService,
  updateTaskEntriesForTaskService,
  addTaskEntriesToTaskService,
  deleteTaskEntryFromTaskService,
  assetList,
  config,
  preferredPricebookId,
  laborRates,
  payrollHourTypes,
  quoteInfo,
  updateLaborLineItems,
  deleteLaborLineItem,
  fetchTasksOnCustomerProperty,
  isReadOnly
}) {
  const classes = useStyles();
  const theme = useTheme();
  const flags = useFlags();
  const isRecommendedTasksEnabled = flags[FeatureFlags.RECOMMENDED_TASKS_WORKFLOW];
  const [expanded, setExpanded] = useState(false);
  const [activityModalOpen, setActivityModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const taskIndex = taskGroups[groupIndex].tasks.findIndex(element => element.id === item.id);

  const iconStyling = { fontSize: 24, color: theme.palette.grayscale(20), margin: 0 };
  const [isLaborModalOpen, setIsLaborModalOpen] = useState({
    isOpen: false,
    type: null
  });
  const [selectedLaborTypes, setSelectedLaborTypes] = useState([]);

  const isAssetEnabled = getTenantSettingValueForKey('assetTrackingAgainstTask') === 'true';
  // Custom component passed to MUI Form, also injecting the task entry services for CRUDs
  const ConnectedPartsSelect = partsProps => (
    <PartsSelect
      addTaskEntriesToTaskService={addTaskEntriesToTaskService}
      config={config}
      deleteTaskEntryFromTaskService={deleteTaskEntryFromTaskService}
      preferredPricebookId={preferredPricebookId}
      setTaskGroups={setTaskGroups}
      task={item}
      updateTaskEntriesForTaskService={updateTaskEntriesForTaskService}
      {...partsProps}
    />
  );

  const openEditLaborModal = selectedItem => {
    setSelectedLaborTypes([selectedItem]);
    setIsLaborModalOpen({
      isOpen: true,
      type: Mode.EDIT
    });
  };

  const ConnectedLaborSelect = laborProps => (
    <LaborSelect
      addTaskEntriesToTaskService={addTaskEntriesToTaskService}
      deleteLaborLineItem={deleteLaborLineItem}
      deleteTaskEntryFromTaskService={deleteTaskEntryFromTaskService}
      laborRates={laborRates}
      task={item}
      updateTaskEntriesForTaskService={updateTaskEntriesForTaskService}
      {...laborProps}
      openEditLaborModal={openEditLaborModal}
      quoteInfo={quoteInfo}
      setIsLaborModalOpen={setIsLaborModalOpen}
      updateLaborLineItems={updateLaborLineItems}
    />
  );

  const dragHandler = () => (
    <img alt="dragIndicator" className={classes.dragIndicatorImgIcon} src={DragIndicator} />
  );
  const rowActionButtons = [
    {
      label: 'Delete',
      icon: 'Delete',
      onClick: () => setDeleteModalOpen(true)
    }
  ];

  if (config?.showActivity) {
    rowActionButtons.unshift({
      label: 'Activity',
      icon: 'Activity',
      onClick: () => setActivityModalOpen(true)
    });
  }

  const taskChangeActions = {
    ASSET: 'asset',
    STATUS: 'status',
    DESCRIPTION: 'description',
    NAME: 'name',
    TASKCOSTCHANGE: 'unitPrice'
  };

  const handleTaskChange = field => async e => {
    const localItem = {
      id: item.id
    };

    if (field === taskChangeActions.TASKCOSTCHANGE) {
      localItem.quantity = 1;
    }

    if (field === taskChangeActions.DESCRIPTION) {
      localItem[field] = e;
    } else if (field !== taskChangeActions.ASSET) {
      localItem[field] = e.currentValue;
    } else if (field === taskChangeActions.STATUS) {
      const newState = [...taskGroups];
      newState[groupIndex].tasks[index][field] = e.currentValue;
      setTaskGroups(newState);
    } else {
      localItem.propertyAssetId = e.id;
    }
    const response = await updateTaskService(localItem);
    if (response?.quoteTransition) {
      updateTasksAndGroups({
        groups: response.quoteTransition.quoteTaskGroups.items,
        tasks: response.quoteTransition.quoteLineTasks.items,
        setTaskGroups
      });
    }
  };
  const onTextChange = debounce(handleTaskChange(taskChangeActions.DESCRIPTION), 1500);
  const onNameChange = debounce(handleTaskChange(taskChangeActions.NAME), 1500);
  const onTaskCostChange = debounce(handleTaskChange(taskChangeActions.TASKCOSTCHANGE), 1500);

  const taskItems = item?.products?.items || item?.quoteLineProducts?.items || [];
  const materialTotal = taskItems.reduce(
    (currentValue, product) =>
      currentValue + (product.quantity ? product.quantity * product.unitPrice : 0),
    0
  );
  const laborTaskItems = formatLaborLineItemsForDisplay(item?.quoteLineLabors?.items);
  const laborTotal =
    laborTaskItems?.reduce((currentValue, laborItem) => currentValue + laborItem.totalPrice, 0) ||
    0;

  const CustomInput = inputProps => (
    <ThemeProvider>
      <Input
        defaultValue={item.description}
        label="Task Description"
        multiline
        placeholder="Enter task description"
        rows={5}
        striped={inputProps.options?.striped}
        onChange={e => onTextChange(e.target.value)}
      />
    </ThemeProvider>
  );

  const taskPricingInput = useMemo(
    () => (
      <CurrencyInput
        striped={!config?.Tasks?.[SettingConstants.SHOW_TASK_PRICING]}
        value={item.unitPrice ?? materialTotal + laborTotal}
        onChange={value => onTaskCostChange({ currentValue: value || 0 })}
      />
    ),
    [config?.Tasks?.[SettingConstants.SHOW_TASK_PRICING], materialTotal, laborTotal, quoteInfo.id]
  );

  return (
    <Draggable draggableId={`${item.id}`} index={index} key={item.id}>
      {(provided, snapshot) => (
        <div style={{ opacity: snapshot.isDragging ? 0.5 : 1 }}>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            <Box boxShadow={2} m={0} p={0}>
              <Accordion expanded={expanded} square style={{ userSelect: 'text' }}>
                <AccordionSummary>
                  <div className={classes.dragHandler} {...provided.dragHandleProps}>
                    {dragHandler()}
                  </div>
                  <Grid container justify="space-between">
                    <Grid
                      item
                      style={{ display: 'flex', alignItems: 'center' }}
                      xs={config.showPricing ? 9 : 10}
                    >
                      {config?.showMarkAsComplete && (
                        <Box component="div" justify="flex-start">
                          <IconButton
                            disableRipple
                            style={{ padding: 0, marginTop: 8, marginLeft: 10, marginRight: 5 }}
                            onClick={() =>
                              handleTaskChange(taskChangeActions.STATUS)({
                                currentValue:
                                  taskGroups[groupIndex].tasks[index].status ===
                                  TaskConstants.COMPLETED
                                    ? TaskConstants.INJOB
                                    : TaskConstants.COMPLETED
                              })
                            }
                          >
                            <CheckIcon
                              style={{
                                fontSize: 24,
                                color:
                                  taskGroups[groupIndex].tasks[index].status ===
                                  TaskConstants.COMPLETED
                                    ? 'green'
                                    : theme.palette.grayscale(90)
                              }}
                            />
                          </IconButton>
                        </Box>
                      )}
                      <Typography
                        color="secondary"
                        style={{ paddingLeft: '13px', paddingRight: '27px' }}
                      >
                        {index + 1}
                      </Typography>
                      <ReadonlyWrapper readOnly={isReadOnly}>
                        <Grid container item justify="flex-start">
                          <Box
                            className={
                              config?.Tasks?.[SettingConstants.SHOW_TASK_TITLE]
                                ? classes.taskName
                                : null
                            }
                          >
                            <Input
                              defaultValue={item.name}
                              id={`scope-input-${item.id}`}
                              placeholder="Add task"
                              striped={!config?.Tasks?.[SettingConstants.SHOW_TASK_TITLE]}
                              style={{ marginLeft: '2px' }}
                              onChange={e => onNameChange({ currentValue: e.target.value })}
                            />
                          </Box>
                        </Grid>
                      </ReadonlyWrapper>
                    </Grid>
                    <Grid alignItems="center" container item xs={config.showPricing ? 3 : 2}>
                      <Grid alignItems="center" container justify="space-evenly">
                        {config?.showAudit && (
                          <Grid item justify="flex-end" style={{ marginLeft: '10px' }} xs={8}>
                            <Typography
                              className={classes.label}
                              key="footer"
                              style={{ position: 'absolute', bottom: 9, color: 'grey' }}
                              variant="caption"
                            >
                              Last edited by {item.lastUpdatedBy} on{' '}
                              {moment(parseInt(item.lastUpdatedDateTime, 10)).format(
                                AppConstants.DATETIME_FORMAT
                              )}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={1}>
                          <ThemeProvider>
                            <Button
                              startIcon={
                                expanded ? (
                                  <ExpandLessIcon style={iconStyling} />
                                ) : (
                                  <ExpandMoreIcon style={iconStyling} />
                                )
                              }
                              style={{ padding: 0 }}
                              type="leading"
                              onClick={() => setExpanded(!expanded)}
                            />
                          </ThemeProvider>
                        </Grid>
                        <Grid item>
                          <ReadonlyWrapper readOnly={isReadOnly}>
                            {config?.showPricing && overridesAreEnabled(config)
                              ? taskPricingInput
                              : config?.showPricing && (
                                  <Box className={classes.taskPriceField}>
                                    <Field
                                      striped={!config?.Tasks?.[SettingConstants.SHOW_TASK_PRICING]}
                                      style={{ margin: 'auto 0', cursor: 'default' }}
                                      type={FieldType.CURRENCY}
                                      value={materialTotal + laborTotal}
                                    />
                                  </Box>
                                )}
                          </ReadonlyWrapper>
                        </Grid>
                        <Grid item xs={1}>
                          <ThemeProvider>
                            <MoreButton
                              disabled={isReadOnly}
                              options={[
                                {
                                  label: 'Delete',
                                  onClick: () => setDeleteModalOpen(true)
                                }
                              ]}
                              style={{ fontSize: '1.2rem' }}
                            />
                          </ThemeProvider>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <ReadonlyWrapper readOnly={isReadOnly}>
                  <AccordionDetails>
                    <MUIForm
                      configuration={createTasksLayout(
                        {
                          item,
                          onTextChange,
                          handleTaskChange,
                          taskChangeActions,
                          priceBookId: preferredPricebookId,
                          config,
                          isReadOnly
                        },
                        assetList,
                        isAssetEnabled,
                        { taskGroups, setTaskGroups, groupIndex, taskIndex }
                      )}
                      customComponents={{
                        CustomInput,
                        PartsSelect: ConnectedPartsSelect,
                        AssetSelect,
                        LaborSelect: ConnectedLaborSelect
                      }}
                      data={item}
                      layout="edit"
                      onComplete={() => {}}
                    />
                  </AccordionDetails>
                </ReadonlyWrapper>
              </Accordion>
            </Box>
          </div>
          <SergeantModal
            confirmRemoveStatement={
              item?.task?.isRecommended && isRecommendedTasksEnabled
                ? '\nThis quote task comes from a recommendation. If deleted, the recommendation will remain but changes made to the recommendation will be lost.'
                : ''
            }
            dataType={EntityType.TASK}
            handleClose={() => setDeleteModalOpen(false)}
            handlePrimaryAction={async () => {
              try {
                const data = await deleteTaskService(item.id);
                if (data) {
                  updateTasksAndGroups({
                    groups: data.quoteTransition.quoteTaskGroups.items,
                    tasks: data.quoteTransition.quoteLineTasks.items,
                    setTaskGroups
                  });
                  fetchTasksOnCustomerProperty();
                }
              } catch (e) {
                Logger.error(e);
              } finally {
                setDeleteModalOpen(!deleteModalOpen);
              }
            }}
            mode={Mode.DELETE}
            open={deleteModalOpen}
          />
          <AddLaborModal
            isLaborModalOpen={isLaborModalOpen}
            item={item}
            laborRates={laborRates}
            payrollHourTypes={payrollHourTypes}
            selectedLaborTypes={selectedLaborTypes}
            setIsLaborModalOpen={setIsLaborModalOpen}
            setSelectedLaborTypes={setSelectedLaborTypes}
            setTaskGroups={setTaskGroups}
            updateLaborLineItems={updateLaborLineItems}
          />
          <Modal handleClose={() => setActivityModalOpen(false)} open={activityModalOpen}>
            <div className={classes.activityContainer}>
              <ErrorBoundaries>
                <Typography className={classes.activityTitle} variant="h4">
                  Activity
                </Typography>
                <div style={{ padding: 10, marginTop: 10 }}>
                  <AuditLogs
                    dataService={() => {
                      return item.auditLogEntries.items;
                    }}
                    variant="singleEntity"
                  />
                </div>
              </ErrorBoundaries>
            </div>
          </Modal>
        </div>
      )}
    </Draggable>
  );
}

const NewTaskBtn = props => {
  const [spinner, setSpinner] = React.useState(false);

  React.useEffect(() => {
    if (spinner && !props.isLoading) {
      setSpinner(false);
    }
  }, [props.isLoading, spinner]);
  return (
    <ThemeProvider>
      <Button
        disabled={props.isReadOnly || props.isLoading}
        size="small"
        startIcon={<AddCircleOutlineIcon />}
        style={{ marginTop: 16 }}
        type="secondary"
        onClick={() => {
          setSpinner(true);
          props.action();
        }}
      >
        {props.addLabel}
      </Button>
    </ThemeProvider>
  );
};

/* Exports: RenderCard, NewCardButton */

export const RenderCard = props => <TaskCard {...props} />;

export const NewCardButton = ({
  addTaskService,
  config,
  isReadOnly,
  setTaskGroups,
  taskGroups
}) => {
  const onClick = async () => {
    const lastTaskGroup = taskGroups[taskGroups.length - 1];
    const newTaskObj = {
      name: '',
      description: '',
      taskTypeInternal: 'General',
      isActive: true,
      sortOrder: lastTaskGroup?.tasks[lastTaskGroup.tasks.length - 1]?.sortOrder + 1 || 0
    };

    const resultTask = await addTaskService(newTaskObj);
    if (resultTask) {
      updateTasksAndGroups({
        groups: resultTask.quoteTransition.quoteTaskGroups.items,
        tasks: resultTask.quoteTransition.quoteLineTasks.items,
        setTaskGroups
      });
    }
  };
  return (
    <NewTaskBtn
      action={onClick}
      addLabel={`Add Task ${config.addToBtnLabel}`}
      addTaskService={addTaskService}
      isReadOnly={isReadOnly}
    />
  );
};
