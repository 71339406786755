import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function CalendarUpcoming(props) {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SvgIcon {...props}>
      <path d="M13.3334 2.00002H12.6667V0.666687H11.3334V2.00002H4.66671V0.666687H3.33337V2.00002H2.66671C1.93337 2.00002 1.33337 2.60002 1.33337 3.33335V14C1.33337 14.7334 1.93337 15.3334 2.66671 15.3334H13.3334C14.0667 15.3334 14.6667 14.7334 14.6667 14V3.33335C14.6667 2.60002 14.0667 2.00002 13.3334 2.00002ZM13.3334 14H2.66671V5.33335H13.3334V14Z" />
      <path d="M10.005 9.16669H4V10.1667H10.005V11.6667L12 9.66669L10.005 7.66669V9.16669Z" />
    </SvgIcon>
  );
}
