/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CancelIcon from '@material-ui/icons/Cancel';
import classNames from 'classnames';
import Select, { createFilter } from 'react-select';

import ConfirmModal from 'components/Modal/ConfirmDialog';
import { CommonService } from 'services/core';
import { Logger } from 'services/Logger';
import { asyncForEach, getDatafromPath } from 'utils';

import styles from './styles';

// import customJobNumberEvaluator from 'utils/evaluator';

function NoOptionsMessage(props) {
  return (
    <Typography
      className={props.selectProps.classes.noOptionsMessage}
      color="textSecondary"
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      component="div"
      selected={props.isFocused}
      style={{
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.29,
        letterSpacing: 'normal',
        // backgroundColor: "#f2f2f2",
        color: '#3f3f3f',
        zIndex: 3
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      className={props.selectProps.classes.placeholder}
      color="textSecondary"
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      deleteIcon={<CancelIcon {...props.removeProps} />}
      label={props.children}
      tabIndex={-1}
      onDelete={props.removeProps.onClick}
    />
  );
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputLabelProps={{ shrink: !!props.selectProps.value || !!props.selectProps.inputValue }}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      variant="filled"
      {...props.selectProps.textFieldProps}
    />
  );
}
function Menu(props) {
  return (
    <Paper className={props.selectProps.classes.paper} square {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  ClearIndicator: () => '',
  IndicatorSeparator: () => '',
  DropdownIndicator: () => <ArrowDropDownIcon style={{ marginTop: -10, color: '#646464' }} />
};

class IntegrationReactSelect extends React.Component {
  constructor(props) {
    super(props);
    this.CommonService = new CommonService();
    this.state = {
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: ''
    };
  }

  handleOnChange = value => {
    const chosenValue = value ? value.value : '';
    const { field, form, specialbehaviour, setValues } = this.props;
    // const { values } = form;
    // only when special behavious
    if (specialbehaviour) {
      const databaseMapping = specialbehaviour.databaseMapping || '';
      const databaseId = specialbehaviour.databaseId || '';
      // in many to many, the system entity map needs to be deleted
      // by defining 'databaseMappings' in meta
      if (
        chosenValue !== form.initialValues[field.name] &&
        databaseMapping &&
        databaseId &&
        form.values[databaseId]
      ) {
        const queryResult = specialbehaviour.queryResult || '';
        const databaseSortkeys = getDatafromPath(queryResult, databaseMapping);
        if (databaseSortkeys && databaseSortkeys.length > 1) {
          // handle deletion scenario
          this.setState({
            confirmDialog: true,
            confirmAction: () =>
              this.deleteRelationships(databaseSortkeys, chosenValue, databaseId),
            confirmMessage:
              'Your previous assignment will be removed. Are you sure you want to continue?'
          });
          return;
        }
      }
      // for copying values from dependent fields
      if (
        specialbehaviour.dependentField &&
        form.values[specialbehaviour.dependentField] === 'true'
      ) {
        const newValues = {
          ...form.values,
          [field.name]: chosenValue,
          [specialbehaviour.dependentValue]: chosenValue
        };
        setValues(this.props.field.name, newValues);
        return;
      }

      // for copying values from dependent fields
      // TODO: cleanup the dirty fix
      if (
        specialbehaviour.additionalField &&
        specialbehaviour.additionalFieldValue &&
        specialbehaviour.queryPath
      ) {
        const {
          fieldValue,
          fieldName,
          additionalField,
          additionalFieldValue,
          queryResult,
          queryPath
        } = specialbehaviour;

        const dependentArray = queryResult[queryPath];
        const mappingKey = fieldValue || fieldName;
        const chosenArray =
          (dependentArray && dependentArray.filter(item => item[mappingKey] === chosenValue)) || [];

        const newValues = {
          ...form.values,
          [field.name]: chosenValue,
          [additionalField]: (chosenArray.length > 0 && chosenArray[0][additionalFieldValue]) || ''
        };
        setValues(this.props.field.name, newValues);
        return;
      }
    }
    this.props.form.setFieldValue(field.name, chosenValue, false);
    setValues(this.props.field.name, chosenValue);
  };

  deleteRelationships = async (records, chosenValue, deleteFlagTracking) => {
    // const sortKey = record[0];
    // const invertedSortKey = record[1];
    const fieldName = this.props.field.name;
    try {
      await asyncForEach(records, async sortKey =>
        this.CommonService.deleteEntityMap(this.props.user.tenantId, sortKey)
      );

      const { values } = this.props.form;
      const newValues = { ...values, [fieldName]: chosenValue, [deleteFlagTracking]: '' };

      this.props.setValues(fieldName, newValues);
      this.setState({
        confirmDialog: false,
        confirmAction: '',
        confirmMessage: ''
      });
    } catch (error) {
      Logger.error(error);
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        this.props.snackbarOn('error', error.graphQLErrors[0].message);
      } else {
        this.props.snackbarOn('error', 'Unable to delete assignments, please try again later');
      }
    }
  };

  handleCancelConfirmation = () => {
    this.setState({ confirmDialog: false, confirmAction: '', confirmMessage: '' });
  };

  render() {
    const { field, form, classes, theme, specialbehaviour, setValues, ...rest } = this.props;
    const {
      queryResult,
      queryPath,
      fieldName,
      fieldValue,
      dependentFieldName,
      dependentQueryPath,
      dependentReferenceKey,
      allowDefault,
      allowDefaultContext,
      hideFieldOnCondition,
      dependentField,
      // used only when converting the buildhero-form metadata
      options
    } = specialbehaviour;

    if (
      hideFieldOnCondition &&
      dependentField &&
      (form.values[dependentField] === '' || form.values[dependentField] === 'false')
    ) {
      return <></>;
    }

    let displayDataArray = null;

    if (queryResult) {
      // dependent picklist. e.g.: address copying
      if (
        dependentQueryPath &&
        dependentFieldName &&
        dependentReferenceKey &&
        form.values[dependentFieldName] &&
        form.values[dependentFieldName] !== ''
      ) {
        let localResult = queryResult;
        const dependentArray = getDatafromPath(localResult, dependentQueryPath);
        localResult =
          dependentArray &&
          dependentArray.filter(
            item => item[dependentReferenceKey] === form.values[dependentFieldName]
          );
        // Logger.debug('dependent dropdown query result with selection value filter');
        // Logger.logObject(localResult);
        // Choosing only the first element in the array after filtering
        if (localResult) {
          [localResult] = localResult;
        }

        if (localResult) {
          displayDataArray = getDatafromPath(localResult, queryPath);
        }
      } else {
        displayDataArray = getDatafromPath(queryResult, queryPath);
      }
    }

    // defaulting to 1st value
    if (
      displayDataArray &&
      displayDataArray.length === 1 &&
      !field.value &&
      field.value !== undefined &&
      allowDefault
    ) {
      const firstValue = displayDataArray[0][fieldValue] || displayDataArray[0][fieldName];
      if (firstValue) {
        let { values } = form;
        values = { ...values, [field.name]: firstValue };
        // field.value = firstValue;
        // form.setFieldValue(field.name, firstValue, false);
        setValues(this.props.field.name, values);
      }
    }

    // defaulting context value
    if (displayDataArray && displayDataArray.length > 0 && !field.value && allowDefaultContext) {
      const defaultValue = queryResult[allowDefaultContext];
      let { values } = form;
      values = { ...values, [field.name]: defaultValue };
      setValues(this.props.field.name, values);
    }

    const helperText =
      form.errors && form.errors[field.name] ? form.errors && form.errors[field.name] : ' ';

    // const selectStyles = {
    //   input: base => ({
    //     ...base,
    //     // color: theme.palette.text.primary,
    //     '& input': {
    //       font: 'inherit'
    //     }
    //   })
    // };

    const selectStyles = {
      menu: cstyles => ({
        ...cstyles,
        zIndex: 5,
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.29,
        letterSpacing: 'normal',
        color: '#3f3f3f',
        maxHeight: 160
      }),
      option: (customstyles, { isFocused, isSelected }) => ({
        ...customstyles,
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: isFocused ? '#f2f2f2' : isSelected ? '#f2f2f2' : null,
        // eslint-disable-next-line no-nested-ternary
        color: isFocused ? '#3f3f3f' : isSelected ? '#3f3f3f' : null,
        maxHeight: 160
      }),
      menuList: custstyles => ({
        ...custstyles,
        left: 0,
        top: 0,
        maxHeight: 160
      })
    };

    let selectOptionValues = [];
    if (displayDataArray) {
      displayDataArray.forEach(item => {
        selectOptionValues.push({
          label: item[fieldName],
          value: (fieldValue && item[fieldValue]) || item[fieldName]
        });
      });
    }

    if (options) {
      selectOptionValues = [...selectOptionValues, ...options];
    }

    const selectNoneOption = { value: undefined, label: 'None' };
    selectOptionValues.unshift(selectNoneOption);
    const defaultValueList = selectOptionValues.filter(item => item.value === field.value);
    const defaultValue = defaultValueList.length > 0 ? defaultValueList[0] : '';

    const filterConfig = {
      ignoreCase: true,
      trim: true,
      matchFrom: 'start'
    };

    return (
      <>
        <FormControl
          className={classes.formControl}
          error={
            (form.errors && form.errors[field.name] && form.errors[field.name].length > 0) || false
          }
          fullWidth
          variant="filled"
        >
          <Select
            classes={classes}
            components={components}
            filterOption={createFilter(filterConfig)}
            isClearable
            name={field.name}
            options={selectOptionValues}
            placeholder=""
            styles={selectStyles}
            textFieldProps={{
              label: rest.label,
              error: form.errors && form.errors[field.name] && form.errors[field.name].length > 0
            }}
            value={defaultValue}
            onChange={this.handleOnChange}
          />
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
        <ConfirmModal
          cancel={this.handleCancelConfirmation}
          confirm={this.state.confirmAction}
          message={this.state.confirmMessage}
          open={this.state.confirmDialog}
          override
        />
      </>
    );
  }
}

export default withStyles(styles)(IntegrationReactSelect);
