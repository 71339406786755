import { removeNestedJson } from '../../utils';
import InstructionHelper from '../InstructionHelper';

const attachmentSchemaMapping = values => {
  const { parent, ...otherFields } = values;
  const strippedproperty = removeNestedJson(parent);
  const data = [{ ...strippedproperty }, { ...otherFields }];
  const ins = new InstructionHelper();
  const temp = ins.oneToMany(0, 1);

  const { instructionSet } = temp;
  return { data, instructionSet };
};

export default attachmentSchemaMapping;
