import React from 'react';

import { tableDurationFormatter, tableEmptyValueFormatter } from 'components/WrapTable';

import ThumbnailCell from '../ThumbnailCell';

import DateWithLocation from './components/TimeTrackingDateWithLocation';

export const TIMESHEET_COLUMNS = [
  {
    field: 'id',
    hide: true
  },
  {
    field: 'name',
    flex: 2,
    headerName: 'Name',
    valueFormatter: tableEmptyValueFormatter
  },
  {
    field: 'startTime',
    flex: 2,
    headerName: 'Start',
    renderCell: ({ row, retrievedValue }) => {
      if (row && row.id !== 'totals') {
        return <DateWithLocation label="Start" row={row} value={retrievedValue} />;
      }
    }
  },
  {
    field: 'endTime',
    flex: 2,
    headerName: 'End',
    renderCell: ({ row, retrievedValue }) => {
      if (row && row.id !== 'totals') {
        return <DateWithLocation label="End" row={row} value={retrievedValue} />;
      }
    }
  },
  {
    field: 'Travel',
    flex: 2,
    headerName: 'Travel',
    valueFormatter: tableDurationFormatter
  },
  {
    field: 'Work',
    flex: 2,
    headerName: 'Work',
    valueFormatter: tableDurationFormatter
  },
  {
    field: 'Break',
    flex: 2,
    headerName: 'Break',
    valueFormatter: tableDurationFormatter
  },
  {
    field: 'Pause',
    flex: 2,
    headerName: 'Pause',
    valueFormatter: tableDurationFormatter
  },
  {
    field: 'BillableNonVisitEvent',
    flex: 2,
    headerName: 'Billable Non-Visit',
    valueFormatter: tableDurationFormatter
  }
];

export const NOTED_COLUMNS = [
  {
    field: 'id',
    hide: true
  },
  {
    field: 'createdBy',
    flex: 2,
    headerName: 'Name'
  },
  {
    field: 'subject',
    flex: 2,
    headerName: 'Note Subject'
  },
  {
    field: 'note',
    flex: 2,
    headerName: 'Note Content'
  },
  {
    field: 'attachments',
    flex: 2,
    headerName: 'Image',
    renderCell: ({ row }) => {
      const url = row?.attachments?.items?.[0]?.fileUrl;
      return url && <ThumbnailCell formattedValue={url} />;
    }
  },
  {
    field: 'eventName',
    flex: 2,
    headerName: 'Event Type'
  }
];
