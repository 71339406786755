import { createStore } from 'redux';

import {
  setCompany,
  setCompanySettings,
  setOptions,
  setSettings,
  setUserTenantSettings
} from 'redux/actions/globalActions';
import { contextNotification, visitUpdateNotification } from 'redux/reducers/applicationReducers';
import { dispatch } from 'redux/store';
import AmplifyService from 'services/AmplifyService';
import CacheService from 'services/CacheService';
import { CommonService, RulesEngine } from 'services/core';
import { Logger } from 'services/Logger';
import { constructSelectOptions } from 'utils/constructSelectOptions';

const companyStore = createStore(contextNotification);
const visitStore = createStore(visitUpdateNotification);

const transformCompanySettings = (settings, companyId) => {
  return settings.reduce((result, setting) => {
    if (setting?.type && setting?.settings) {
      try {
        const parseSettings = JSON.parse(setting.settings);
        return {
          ...result,
          [setting.type]: parseSettings
        };
      } catch (error) {
        Logger.warn(`Cannot parse settings for company ${companyId}: ${error}`);
      }
    }
    return result;
  }, {});
};

const setStore = ctx => {
  const options = {
    departments: constructSelectOptions(ctx.getCompany.departments.items, 'tagName'),
    priceBooks: constructSelectOptions(ctx.getCompany.priceBooks.items)
  };
  dispatch(setSettings(ctx.listTenantSettings));
  dispatch(setUserTenantSettings(ctx.listTenantSettings));
  dispatch(setCompany(ctx.getCompany));
  dispatch(setOptions(options));
  dispatch(
    setCompanySettings(transformCompanySettings(ctx.getCompany?.companySettings?.items || []))
  );
};

const ContextVariable = () => {
  let companyContext = null;
  let visitUpdate = null;

  const getCompanyContext = function fetchCompanyContext() {
    return companyContext;
  };

  const getCompanySettingsMap = () => {
    const settings = companyContext?.getCompany?.companySettings?.items || [];
    return transformCompanySettings(settings, companyContext?.getCompany?.id);
  };

  const companyContextNotification = () => ({
    type: 'CONTEXT_UPDATED',
    companyContext
  });

  const visitUpdateHandler = () => ({
    type: 'VISIT_UPDATED',
    visitUpdate
  });

  const subscribeToVisitUpdate = async (params, retrying = 0) => {
    const rulesEngine = new RulesEngine();
    const sub = await rulesEngine.visitUpdated(params);
    setTimeout(() => {
      sub.subscribe({
        next: result => {
          retrying = 0;
          visitUpdate = result.data.visitUpdateNotification;
          visitStore.dispatch(visitUpdateHandler());
        },
        error: error => {
          if (retrying === 0) {
            retrying = 50;
          }
          subscribeToVisitUpdate(params, retrying * 2);
        }
      });
    }, retrying);
  };

  const performQuery = async (partitionKey, sortKey) => {
    const tenantPartitionKey = partitionKey;
    const tenantSortKey = `Tenant_${partitionKey}`;
    const CommonServiceObj = new CommonService();
    try {
      // if (observableQuery) observableQuery.unsubscribe();
      AmplifyService.resetClient();
      const result = await CommonServiceObj.getCompanyAttributesSync(
        partitionKey,
        sortKey,
        tenantPartitionKey,
        tenantSortKey
      );
      if (result) {
        const { data } = result;
        if (data) {
          if (companyContext !== data) {
            companyContext = data;
            companyStore.dispatch(companyContextNotification());
            // avoiding null error for system admin login
            if (data.getCompany && data.getCompany.sortKey) {
              companyContext.sortKey = `CTX_${data.getCompany.sortKey}`;
              CacheService.cacheItem(companyContext);
              setStore(data);
            }
          }
        }
      }
    } catch (error) {
      Logger.error(error);
      Logger.debug('unable to set context 1');
    }
    return true;
  };

  const setCompanyContext = async function assignCompanyContext(
    partitionKey,
    sortKey,
    callback,
    refetch
  ) {
    if (!companyContext || refetch) {
      try {
        const ctx = CacheService.getCachedItem(`CTX_${sortKey}`);
        if (ctx) {
          companyContext = ctx;
          setStore(ctx);
        }
        if (refetch || !ctx) {
          await performQuery(partitionKey, sortKey);
        }
      } catch (err) {
        Logger.error('unable to set context 2');
        Logger.error(err);
      }
      if (callback) callback(companyContext);
      return companyContext;
    }
  };

  const generateCompanyContextSortKey = user => {
    return `${user.tenantId}_Company_${user.tenantCompanyId}`;
  };

  const resetCompanyContext = () => {
    companyContext = null;
  };
  return {
    getCompanyContext,
    setCompanyContext,
    resetCompanyContext,
    subscribeToVisitUpdate,
    getCompanySettingsMap,
    generateCompanyContextSortKey
  };
};

export default ContextVariable();
export { companyStore, visitStore };
