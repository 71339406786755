import React from 'react';

import { TV, Typography } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';

import { Mode } from 'utils/constants';

const TaskDetails = modalConfig => {
  if (modalConfig.type !== Mode.EDIT) return null;

  const { createdBy, createdDate, submittedFrom } = modalConfig?.row;
  const d = new Date(createdDate * 1000);
  const date = d.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' });
  const time = d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const line1 = `Created by ${createdBy} on ${date} at ${time}`;
  const line2 = `Submitted from: Job ${submittedFrom?.jobIdentifier} - Visit #${submittedFrom?.visitNumber}`;

  return (
    <div css={{ marginLeft: 141 }}>
      <Typography style={{ padding: '2px 0' }} variant={TV.S1}>
        {line1}{' '}
      </Typography>
      {submittedFrom && (
        <Typography style={{ padding: '2px 0' }} variant={TV.S1}>
          {line2}
        </Typography>
      )}
    </div>
  );
};

export default TaskDetails;
