import React from 'react';

import { ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { useTheme } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { bool, func } from 'prop-types';
import { Link } from 'react-router-dom';

import JobNumber from '@dispatch/components/JobNumber';
import VisitCard from '@dispatch/components/VisitCard';
import { JobTypes, VisitStatuses } from '@dispatch/Dispatch.constants';
import { VISIT_DETAILS_PROP } from '@dispatch/queries';
import { StatusChip } from 'components';
import { generateProcurementStatusTooltip, getProcurementStatus } from 'scenes/JobCloseout/utils';
import Routes from 'scenes/Routes';
import { EnumType } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import StatusButtonGroup from '../StatusButtonGroup';

import { useStyles } from './VisitStatusHeader.styles';

const VisitStatusHeader = ({
  visit,
  visitDetailsLoading,
  visitTransitionLoading,
  handleVisitTransition
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const flags = useFlags();

  const isMaintenance = visit.job?.jobTypeInternal === JobTypes.MAINTENANCE.value.serverValue;
  const route = isMaintenance ? Routes.maintenance : Routes.job;
  const showJobProcurementStatus = flags[FeatureFlags.JOB_PROCUREMENT_STATUS];

  // Use Manual status or get automated status
  const automatedLabel =
    visit?.job?.procurementStatus || getProcurementStatus(visit?.job?.purchaseOrders?.items);

  return (
    <div className={classes.root}>
      <ThemeProvider>
        <Typography className={classes.jobNumber} variant={TV.BASE} weight={TW.BOLD}>
          <Link to={route({ mode: 'view', id: encodeURIComponent(visit.job?.jobNumber) })}>
            <JobNumber job={visit.job} />
          </Link>
        </Typography>
        {showJobProcurementStatus && (
          <div className={classes.statusChipBar}>
            <StatusChip
              className={classes.statusChip}
              enumType={EnumType.JOB_PROCUREMENT_STATUS}
              enumValue={automatedLabel}
              label={automatedLabel}
              noLabelFormat
              showIcon
              toolTipText={generateProcurementStatusTooltip(
                visit?.job?.purchaseOrders?.items,
                theme
              )}
            />
          </div>
        )}
      </ThemeProvider>
      <VisitCard isDrawer loading={visitDetailsLoading} visit={visit} />
      {visit.status === VisitStatuses.ON_HOLD.key && visit.onHoldReason && (
        <ThemeProvider>
          <Typography className={classes.onHoldReason} variant={TV.S1} weight={TW.REGULAR}>
            ON HOLD REASON: {visit.onHoldReason}
          </Typography>
        </ThemeProvider>
      )}
      <StatusButtonGroup
        handleVisitTransition={handleVisitTransition}
        loading={visitDetailsLoading || visitTransitionLoading}
        visit={visit}
      />
    </div>
  );
};

VisitStatusHeader.propTypes = {
  visit: VISIT_DETAILS_PROP.isRequired,
  visitDetailsLoading: bool.isRequired,
  visitTransitionLoading: bool.isRequired,
  handleVisitTransition: func.isRequired
};

export default VisitStatusHeader;
