import { getLabelFromValues } from 'scenes/ProjectManagement/components/utils';
import { ProjectStatus } from 'scenes/ProjectManagement/constants';
import { customerSearch } from 'services/API/customer';
import { getCustomerPropertiesByCustomer } from 'services/API/customerProperty';
import { customerRepSearch } from 'services/API/customerRep';
import { departmentSearch } from 'services/API/department';
import { employeeSearch } from 'services/API/employee';
import { projectSubtypeSearch } from 'services/API/projectSubtype';
import { projectTypeSearch } from 'services/API/projectType';

import { generateDefaultFieldsObject } from '../formattingUtils';

const defaultFlexStyle = '0 1 219px';
const smallItemFlexStyle = '0 0 102px';
const wideItemFlexStyle = '0 0 453px';
const margin = '0px 15px 21px 0px';
const statusValues = getLabelFromValues(ProjectStatus);

const getAlphabeticalSortFn = field => (a, b) =>
  a?.[field]?.toLowerCase() > b?.[field]?.toLowerCase() ? 1 : -1;

// General Information
const generalInformationFields = {
  name: { required: true },
  ProjectType: { required: true },
  ProjectSubtype: { required: true },
  number: { required: true },
  customer: { required: true },
  billingCustomer: { required: true },
  property: { required: false },
  projectManagerDepartment: { required: true },
  address1: { required: false },
  address2: { required: false },
  addressCity: { required: false },
  addressState: { required: false },
  addressPostal: { required: false },
  description: { required: false },
  status: { required: true },
  externalPONumber: { required: false }
};

// Contacts
const contactFields = {
  projectOwner: { required: false },
  entryForArchitect: { required: false },
  gcSuperintendent: { required: false },
  gcProjectManager: { required: false },
  projectManager: { required: true },
  soldBy: { required: false },
  foreman: { required: false }
};

// Dates
const dataFields = {
  dateCreated: { required: true, nullable: true },
  dateStart: { required: false, nullable: true },
  dateEnd: { required: false, nullable: true },
  contractDate: { required: false, nullable: true }
};

const generalInformationContents = (selectedCustomer, setSelectedCustomer) => [
  // the 1st section title
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1
    },
    contents: [
      {
        contents: [
          {
            component: {
              default: 'sectionTitle',
              edit: 'sectionTitle'
            },
            options: {
              label: 'General Information'
            }
          }
        ]
      }
    ]
  },

  // the 1st row
  {
    options: {
      size: 4,
      direction: 'row',
      grow: 0,
      flexWrap: 'wrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'TextInput',
              edit: 'TextInput'
            },
            source: 'name',
            options: {
              label: 'Project Name',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar',
              edit: 'SearchBar'
            },
            source: 'ProjectType',
            options: {
              resultFormatFunction: projectType => `${projectType.name}`,
              searchFunction: projectTypeSearch,
              searchColumns: ['name'],
              sortFunction: (a, b) => a.sortOrder - b.sortOrder,
              // onChange: val => console.log("We just selected a value", val),
              label: 'Project Type',
              placeholder: '',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true,
              errorText: 'Field is required'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar',
              edit: 'SearchBar'
            },
            source: 'ProjectSubtype',
            options: {
              // TODO: searchParam : projectType
              resultFormatFunction: projectSubtype => `${projectSubtype.name}`,
              searchFunction: projectSubtypeSearch,
              sortFunction: (a, b) => a.sortOrder - b.sortOrder,
              searchColumns: ['name'],
              label: 'Project Subtype',
              placeholder: '',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true,
              errorText: 'Field is required'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'ProjectNumber',
              edit: 'ProjectNumber'
            },
            source: 'number',
            options: {
              required: generalInformationFields.number.required,
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      }
    ]
  },

  // the 2nd row
  {
    options: {
      size: 4,
      direction: 'row',
      grow: 0,
      flexWrap: 'wrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar',
              edit: 'SearchBar'
            },
            source: 'customer',
            options: {
              resultFormatFunction: customer => customer.customerName,
              searchFunction: customerSearch,
              sortFunction: getAlphabeticalSortFn('customerName'),
              label: 'Customer',
              placeholder: 'Search customers',
              onSelect: val => {
                setSelectedCustomer(val);
              },
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true,
              errorText: 'Field is required'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar',
              edit: 'SearchBar'
            },
            source: 'billingCustomer',
            options: {
              resultFormatFunction: customer => `${customer.customerName}`,
              searchFunction: customerSearch,
              sortFunction: getAlphabeticalSortFn('customerName'),
              label: 'Billing Customer',
              placeholder: 'Search billing customers',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true,
              errorText: 'Field is required'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'PropertySearchBar',
              edit: 'PropertySearchBar'
            },
            source: 'property',
            options: {
              resultFormatFunction: property => property.companyName,
              searchFunction: term => getCustomerPropertiesByCustomer(selectedCustomer?.id, term),
              sortFunction: getAlphabeticalSortFn('companyName'),
              label: 'Property',
              placeholder: 'Search properties',
              fullWidth: true,
              searchOnOpen: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              disabled: !selectedCustomer?.id,
              errorText: 'Field is required'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar',
              edit: 'SearchBar'
            },
            source: 'projectManagerDepartment',
            options: {
              resultFormatFunction: department => department.tagName,
              searchFunction: departmentSearch,
              sortFunction: getAlphabeticalSortFn('tagName'),
              searchColumns: ['tagName'],
              label: 'Department',
              placeholder: 'Search departments',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true,
              errorText: 'Field is required'
              // TODO: replace the component with SelectInput to support multiple selection
              // isMultipleSelection: true,
              // valueSet: departments
            }
          }
        ]
      }
    ]
  },

  // the 3rd row
  {
    options: {
      size: 4,
      direction: 'row',
      grow: 0,
      flexWrap: 'wrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'PlacesSearch',
              edit: 'PlacesSearch'
            },
            source: 'address1',
            options: {
              label: 'Project Address Line 1',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              setSource: false,
              valuesToSet: [
                {
                  address1: 'addressLine1',
                  addressCity: 'city.shortName',
                  addressState: 'state.shortName',
                  addressPostal: 'zipcode.shortName'
                }
              ]
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'TextInput',
              edit: 'TextInput'
            },
            source: 'address2',
            options: {
              label: 'Project Address Line 2',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'TextInput',
              edit: 'TextInput'
            },
            source: 'addressCity',
            options: {
              label: 'City',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: smallItemFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'TextInput',
              edit: 'TextInput'
            },
            source: 'addressState',
            options: {
              label: 'State',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: smallItemFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'TextInput',
              edit: 'TextInput'
            },
            source: 'addressPostal',
            options: {
              label: 'Zip/Postal Code',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      }
    ]
  },

  // the 4th row
  {
    options: {
      size: 2,
      direction: 'row',
      grow: 0
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: wideItemFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'TextInput',
              edit: 'TextInput'
            },
            source: 'description',
            options: {
              label: 'description',
              fullWidth: true,
              lines: 4,
              alignment: 'left',
              placeholder: 'Enter Description',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'SelectInput',
              edit: 'SelectInput'
            },
            source: 'status',
            options: {
              label: 'Status',
              placeholder: ' ',
              enableSort: false,
              valueSet: statusValues,
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'TextInput',
              edit: 'TextInput'
            },
            source: 'externalPONumber',
            options: {
              label: 'External Project Number',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      }
    ]
  },

  // Divider
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1,
      margin: '8px 0px 20px'
    },
    contents: [
      {
        component: {
          default: 'CustomDividerStyled',
          edit: 'CustomDividerStyled'
        }
      }
    ]
  }
];

const contactContents = selectedCustomer => [
  // the 2nd section title
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1
    },
    contents: [
      {
        contents: [
          {
            component: {
              default: 'sectionTitle',
              edit: 'sectionTitle'
            },
            options: {
              label: 'Contacts'
            }
          }
        ]
      }
    ]
  },

  // the 1st row
  {
    options: {
      size: 4,
      direction: 'row',
      grow: 0,
      flexWrap: 'wrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar',
              edit: 'SearchBar'
            },
            source: 'projectOwner',
            options: {
              resultFormatFunction: customerRep => customerRep.name,
              searchFunction: (value, searchCols) =>
                customerRepSearch(value, searchCols, selectedCustomer?.id),
              sortFunction: getAlphabeticalSortFn('name'),
              label: 'Project Owner',
              placeholder: 'Search',
              fullWidth: true,
              searchOnOpen: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar',
              edit: 'SearchBar'
            },
            source: 'entryForArchitect',
            options: {
              resultFormatFunction: customerRep => customerRep.name,
              searchFunction: (value, searchCols) =>
                customerRepSearch(value, searchCols, selectedCustomer?.id),
              sortFunction: getAlphabeticalSortFn('name'),
              label: 'Entry for Architect',
              placeholder: 'Search',
              fullWidth: true,
              searchOnOpen: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar',
              edit: 'SearchBar'
            },
            source: 'gcSuperintendent',
            options: {
              resultFormatFunction: customerRep => customerRep.name,
              searchFunction: (value, searchCols) =>
                customerRepSearch(value, searchCols, selectedCustomer?.id),
              sortFunction: getAlphabeticalSortFn('name'),
              label: 'GC Superintendent',
              placeholder: 'Search',
              fullWidth: true,
              searchOnOpen: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar',
              edit: 'SearchBar'
            },
            source: 'gcProjectManager',
            options: {
              resultFormatFunction: customerRep => customerRep.name,
              searchFunction: (value, searchCols) =>
                customerRepSearch(value, searchCols, selectedCustomer?.id),
              sortFunction: getAlphabeticalSortFn('name'),
              label: 'GC Project Manager',
              placeholder: 'Search',
              fullWidth: true,
              searchOnOpen: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      }
    ]
  },

  // the 3rd row
  {
    options: {
      size: 2,
      direction: 'row',
      grow: 0,
      flexWrap: 'wrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar',
              edit: 'SearchBar'
            },
            source: 'projectManager',
            options: {
              resultFormatFunction: employee => employee.name,
              searchFunction: employeeSearch,
              filterFunction: data => data.filter(item => item.status === 'active'),
              sortFunction: getAlphabeticalSortFn('name'),
              label: 'Project Manager',
              placeholder: 'Search',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true,
              errorText: 'Field is required'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar',
              edit: 'SearchBar'
            },
            source: 'soldBy',
            options: {
              resultFormatFunction: employee => employee.name,
              searchFunction: employeeSearch,
              filterFunction: data => data.filter(item => item.status === 'active'),
              sortFunction: getAlphabeticalSortFn('name'),
              label: 'Sold By',
              placeholder: 'Search',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      }
    ]
  },

  // the 4th row
  {
    options: {
      size: 2,
      direction: 'row',
      grow: 0,
      flexWrap: 'wrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar',
              edit: 'SearchBar'
            },
            source: 'foreman',
            options: {
              resultFormatFunction: employee => employee.name,
              searchFunction: employeeSearch,
              filterFunction: data => data.filter(item => item.status === 'active'),
              sortFunction: getAlphabeticalSortFn('name'),
              label: 'Foreman',
              placeholder: 'Search',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      }
    ]
  },

  // Divider
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1,
      margin: '8px 0px 20px'
    },
    contents: [
      {
        component: {
          default: 'CustomDividerStyled',
          edit: 'CustomDividerStyled'
        }
      }
    ]
  }
];

const dateContents = timezone => [
  // the 3rd section title
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1
    },
    contents: [
      {
        contents: [
          {
            component: {
              default: 'sectionTitle',
              edit: 'sectionTitle'
            },
            options: {
              label: 'Dates'
            }
          }
        ]
      }
    ]
  },

  // the 1st row
  {
    options: {
      size: 4,
      direction: 'row',
      grow: 0,
      flexWrap: 'wrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: 'DateInput',
            source: 'dateCreated',
            options: {
              label: 'Date Created',
              fullWidth: false,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: 'DateInput',
            source: 'dateStart',
            options: {
              timezone,
              label: 'Start Date',
              placeholder: 'Select Date',
              fullWidth: false,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: 'DateInput',
            source: 'dateEnd',
            options: {
              timezone,
              label: 'End Date',
              placeholder: 'Select Date',
              fullWidth: false,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      }
    ]
  },

  // the 2nd row
  {
    options: {
      size: 4,
      direction: 'row',
      grow: 0,
      flexWrap: 'wrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'DateInput',
              edit: 'DateInput'
            },
            source: 'contractDate',
            options: {
              label: 'Contract Date',
              placeholder: 'Select Date',
              fullWidth: false,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      }
    ]
  }
];

const layout = (timezone, selectedCustomer, setSelectedCustomer) => {
  return {
    fields: {
      ...generateDefaultFieldsObject(generalInformationFields),
      ...generateDefaultFieldsObject(contactFields),
      ...generateDefaultFieldsObject(dataFields)
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          ...generalInformationContents(selectedCustomer, setSelectedCustomer),
          ...contactContents(selectedCustomer),
          ...dateContents(timezone)
        ]
      },
      edit: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          ...generalInformationContents(selectedCustomer, setSelectedCustomer),
          ...contactContents(selectedCustomer),
          ...dateContents(timezone)
        ]
      }
    }
    // can't use validation and validationErrors objects here because of the DateInput's nullable validation checking
  };
};

export { generalInformationFields, contactFields, dataFields, layout };
