import React from 'react';

import { MUIForm } from '@BuildHero/sergeant';

import Context from 'components/Context';
import { EmployeeStatus } from 'utils/AppConstants';

import ForemanSelector from './ForemanSelector';
import crewFormLayout from './layout';
import MultiEmployeeSelector from './MultiEmployeeSelector';

function belongsToDepartment(employee, departmentId) {
  // no options if no department specified
  if (!departmentId) return false;

  return employee?.departments?.items?.some(department => department?.id === departmentId);
}

export default function CrewForm(props) {
  const { onCreateService, onComplete } = props;
  const company = Context.getCompanyContext()?.getCompany;
  const departments = company?.departments?.items ?? [];
  // TODO - figure out if I need to filter out deleted employees and departments

  // layout expects departments in shape { label, value }
  const departmentOptions = departments.map(department => ({
    label: department?.tagName,
    value: department.id
  }));

  const layout = props?.layout || 'default';

  const employeesFilterOptions = (employees, form) =>
    employees
      .filter(employee => employee.isActive)
      .filter(employee => belongsToDepartment(employee, form.values.departmentId))
      .filter(employee => employee.id !== form.values.foremanId)
      .filter(employee => !form.values?.techIds?.includes(employee.id));

  const hasActiveBuildopsLogin = employee => employee.status === EmployeeStatus.ACTIVE;

  const foremanFilterOptions = (employee, form) =>
    employee
      .filter(hasActiveBuildopsLogin)
      .filter(e => belongsToDepartment(e, form.values?.departmentId))
      .filter(e => !form?.values?.techIds?.includes(e.id));

  const settings = {
    departmentOptions,
    employeesFilterOptions,
    foremanFilterOptions
  };

  return (
    <MUIForm
      configuration={crewFormLayout(settings)}
      customComponents={{ ForemanSelector, MultiEmployeeSelector }}
      data={props.data}
      layout={layout}
      onComplete={onComplete}
      onCreateService={onCreateService}
    />
  );
}
