import gql from 'graphql-tag';
import { pick } from 'lodash';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { logErrorWithCallback } from 'utils';

const BULK_UPDATE_LABOUR_RATE_GROUPS = gql`
  mutation bulkUpdateLabourRateGroups($data: AddLabourRateGroupsToCompanyInput!) {
    bulkUpdateLabourRateGroups(data: $data) {
      id
      name
      sortOrder
      labourTypeBillingHourTypeProducts {
        items {
          id
          labourTypeId
          labourType {
            id
            name
          }
          billingHourTypeId
          billingHourType {
            id
            hourType
            hourTypeAbbreviation
          }
          productId
          product {
            id
            name
          }
        }
      }
      labourRateGroupEntries {
        items {
          id
          rate
          payrollHourTypeId
          payrollHourType {
            id
            hourType
            hourTypeAbbreviation
            sortOrder
          }
          labourTypeId
          labourType {
            id
            name
            jobCostTypeId
            revenueTypeId
            costCodeId
          }
          costCodeId
          costCode {
            name
            id
          }
          jobCostTypeId
          jobCostType {
            name
            id
            type
          }
        }
      }
    }
  }
`;

const serializer = ({ companyId, labourRateGroups }) => ({
  variables: {
    data: {
      companyId,
      labourRateGroups: labourRateGroups.map(group => pick(group, ['id', 'name', 'sortOrder']))
    }
  }
});

const update = (cache, { data: { bulkUpdateLabourRateGroups } }, ref) => {
  cache.modify({
    id: `Company:${ref.companyId}`,
    fields: {
      labourRateGroups() {
        return bulkUpdateLabourRateGroups.map(labourRateGroup => {
          return {
            __ref: `${labourRateGroup.__typename}:${labourRateGroup.id}`
          };
        });
      }
    }
  });
};

export const useBulkUpdateLabourRateGroups = () => {
  return useExtendedMutation(BULK_UPDATE_LABOUR_RATE_GROUPS, {
    serializer,
    update,
    onSuccess: (data, snackbarOn) =>
      snackbarOn('success', 'Successfully updated labor rate groups'),
    onError: (err, snackbarOn) =>
      logErrorWithCallback(err, snackbarOn, 'Unable to update labor rate groups')
  });
};
