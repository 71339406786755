import React from 'react';

import { theme, TV, TW, Typography } from '@BuildHero/sergeant';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';

import { statusToNoteLabel } from 'scenes/Procurement/constants';
import { backendDateToMoment } from 'utils';
import { getDateFormat } from 'utils/AppConstants';

const statusToColor = {
  Approved: theme.palette.support.green,
  Rejected: theme.palette.support.red
};

export const NoteDetail = ({ name, dateTime, note, status }) => {
  if (!['Approved', 'Rejected'].includes(status)) return null;

  const useStyles = makeStyles(() => ({
    container: {
      padding: '12px',
      margin: '12px 0 24px 0',
      backgroundColor: statusToColor[status].light,
      borderRadius: '4px',
      minHeight: '64px'
    },
    title: {
      color: statusToColor[status].dark,
      fontSize: '14px'
    },
    nameDateTime: {
      fontSize: '12px'
    },
    note: {
      lineHeight: '24px',
      color: statusToColor[status].dark,
      fontSize: '14px'
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography className={classes.title} variant={TV.BASE} weight={TW.BOLD}>
          {statusToNoteLabel[status]}
        </Typography>
        <Typography className={classes.nameDateTime} variant={TV.BASE} weight={TW.REGULAR}>
          {`${name} - ${
            dateTime ? backendDateToMoment(dateTime).format(getDateFormat('DateTime')) : ''
          }`}
        </Typography>
      </div>
      <Typography className={classes.note} variant={TV.BASE} weight={TW.MEDIUM}>
        {note}
      </Typography>
    </div>
  );
};

NoteDetail.propTypes = {
  name: PropTypes.string,
  dateTime: PropTypes.number,
  note: PropTypes.string,
  status: PropTypes.string
};

NoteDetail.defaultProps = {
  name: '',
  dateTime: null,
  note: '',
  status: ''
};
