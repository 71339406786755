import gql from 'graphql-tag';
import { bool, number, shape, string } from 'prop-types';

import { ERROR_PROP } from '@dispatch/Dispatch.constants';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import { getState } from 'redux/store';

const GET_COMPANY_OVERVIEW = gql`
  query getCompanyOverview($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      companyName
      logoUrl
      email
      phonePrimary
      phoneAlternate
      websiteUrl
      companyAddresses {
        items {
          id
          addressType
          addressLine1
          addressLine2
          city
          state
          zipcode
          latitude
          longitude
        }
      }
    }
  }
`;

const transformCompanyOverview = ({ getCompany: data }) => {
  const address =
    data.companyAddresses.items.find(addr => addr.addressType === 'billingAddress') ||
    data.companyAddresses.items[0] ||
    {};

  const position = address?.latitude &&
    address?.longitude && {
      lat: address?.latitude,
      lng: address?.longitude
    };

  return {
    ...data,
    position,
    address
  };
};

export const useCompanyOverview = () => {
  const { tenantId, tenantCompanyId } = getState().user;
  const sortKey = `${tenantId}_Company_${tenantCompanyId}`;

  return useExtendedQuery(GET_COMPANY_OVERVIEW, {
    variables: { partitionKey: tenantId, sortKey },
    defaultData: {
      position: {},
      address: {}
    },
    transform: transformCompanyOverview
  });
};

export const COMPANY_OVERVIEW_PROP = shape({
  id: string,
  companyName: string,
  logoUrl: string,
  email: string,
  phonePrimary: string,
  websiteUrl: string,
  position: shape({
    lat: number,
    lng: number
  }),
  address: shape({
    addressType: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    zipcode: string,
    latitude: number,
    longitude: number
  })
});

export const COMPANY_OVERVIEW_RESPONSE_PROP = shape({
  loading: bool,
  error: ERROR_PROP,
  data: COMPANY_OVERVIEW_PROP
});
