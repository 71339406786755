import React from 'react';

import withLazyMount from 'utils/withLazyMount';

import { notesColumns } from '../../../../meta/notesColumns';
import { selectReviewReportNotes } from '../../../../selectors';
import ReportTable from '../../../ReportTable';
import { reviewReportDefaultProps, reviewReportPropTypes } from '../../reviewReportPropTypes';

const ReviewReportNotesTable = ({ reviewReport, loading, error }) => {
  const title = 'Technician Notes (Internal Use)';
  const noDataMessage = 'No Technician Notes';
  const rows = selectReviewReportNotes(reviewReport) || [];
  return (
    <ReportTable
      columns={notesColumns}
      error={error}
      loading={loading}
      noDataMessage={noDataMessage}
      rows={rows}
      title={title}
    />
  );
};

ReviewReportNotesTable.propTypes = reviewReportPropTypes;
ReviewReportNotesTable.defaultProps = reviewReportDefaultProps;

export default withLazyMount(ReviewReportNotesTable);
