import React, { useRef, useState } from 'react';

import { Button, ButtonType, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { Menu, MenuItem, useTheme } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import moment from 'moment';

import PropTypes from 'prop-types';

import { StatusChip } from 'components';
import { enumBackgroundColor, enumIcon } from 'components/StatusChip/helper';
import { getMultiSelectOptions } from 'meta/ListViews';
import { purchaseOrderReceiptChange } from 'services/API/purchaseOrderReceipt';
import { StatusValToLabelMapping, VendorApprovalStatus } from 'utils/AppConstants';

import { Mode } from 'utils/constants';

import { ApprovalRejectionModal } from '../ApprovalRejectionModal';

export const ProcurementStatusButtons = ({
  value,
  enumType,
  useStatusLabelMap,
  noLabelFormat,
  receiptId,
  frameDataReducer,
  user
}) => {
  const theme = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isApprovalRejectionModalOpen, setIsApprovalRejectionModalOpen] = useState(false);
  const approvalRejectionModalRef = useRef({
    status: null
  });

  const options = getMultiSelectOptions(enumType)?.filter(val => val !== value);

  const handleReceiptStatusUpdate = async payload => {
    await purchaseOrderReceiptChange(receiptId, payload);
    frameDataReducer({
      type: 'updateApprovalStatus',
      payload,
      user
    });
  };

  const handleStatusChange = async opt => {
    if (opt === VendorApprovalStatus.APPROVED || opt === VendorApprovalStatus.REJECTED) {
      setIsMenuOpen(false);
      setIsApprovalRejectionModalOpen(true);
      approvalRejectionModalRef.current = {
        status: opt
      };
    } else {
      const payload = {
        approvalStatus: opt,
        approvalNote: null,
        approvalNoteDateTime: null,
        approvalNoteById: null
      };
      await handleReceiptStatusUpdate(payload);
    }
  };

  const handleSaveApprovalRejectionNote = async data => {
    const payload = {
      approvalStatus: approvalRejectionModalRef.current.status,
      approvalNote: data?.approvalNote,
      approvalNoteDateTime: moment().valueOf(),
      approvalNoteById: user?.employeeId
    };
    await handleReceiptStatusUpdate(payload);
    setIsApprovalRejectionModalOpen(false);
    approvalRejectionModalRef.current = {
      status: null
    };
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const buttonStyle = {
    backgroundColor: enumBackgroundColor(theme)[enumType]?.[value],
    textTransform: 'none',
    height: 24,
    '&:hover': {
      backgroundColor: enumBackgroundColor(theme)[enumType]?.[value],
      opacity: 0.8
    }
  };

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const getButtonComponent = () => (
    <Button
      aria-controls={isMenuOpen ? 'status-dropdown-menu' : undefined}
      aria-expanded={isMenuOpen ? 'true' : undefined}
      aria-haspopup="true"
      css={buttonStyle}
      disableElevation
      endIcon={<ArrowDropDown />}
      size="small"
      startIcon={enumIcon(theme)[enumType]}
      type={ButtonType.PRIMARY}
      onClick={handleMenuOpen}
    >
      <Typography variant={TV.BASE} weight={TW.MEDIUM}>
        {useStatusLabelMap ? StatusValToLabelMapping(enumType, value) : value}
      </Typography>
    </Button>
  );

  return (
    <ThemeProvider>
      {getButtonComponent()}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        getContentAnchorEl={null}
        id="status-dropdown-menu"
        open={isMenuOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={handleMenuClose}
      >
        {options?.map(opt => (
          <MenuItem
            css={{
              borderBottom: `1px solid ${theme.palette.grayscale(80)}`,
              marginLeft: 8,
              marginRight: 8,
              padding: 8,
              minWidth: 104,
              flexWrap: 'wrap',
              maxWidth: 150
            }}
            onClick={() => handleStatusChange(opt)}
          >
            <StatusChip
              css={{ borderRadius: 2, cursor: 'pointer' }}
              enumType={enumType}
              enumValue={opt}
              label={opt}
              noLabelFormat={noLabelFormat}
              showIcon
            />
          </MenuItem>
        ))}
      </Menu>
      <ApprovalRejectionModal
        handleClose={() => setIsApprovalRejectionModalOpen(false)}
        handleOnComplete={handleSaveApprovalRejectionNote}
        isOpen={isApprovalRejectionModalOpen}
        label="Vendor Invoice"
        mode={Mode.ADD}
        status={approvalRejectionModalRef.current.status}
      />
    </ThemeProvider>
  );
};

ProcurementStatusButtons.propTypes = {
  value: PropTypes.string,
  enumType: PropTypes.string,
  useStatusLabelMap: PropTypes.bool,
  noLabelFormat: PropTypes.bool,
  receiptId: PropTypes.string,
  frameDataReducer: PropTypes.func,
  user: PropTypes.object
};

ProcurementStatusButtons.defaultProps = {
  value: '',
  enumType: '',
  useStatusLabelMap: false,
  noLabelFormat: false,
  receiptId: '',
  frameDataReducer: () => {},
  user: {}
};
