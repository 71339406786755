import gql from 'graphql-tag';
import { sortBy } from 'lodash';

import useExtendedQuery from 'customHooks/useExtendedQuery';

const customerPropertyMaintenanceJobPreview = gql`
  query customerPropertyMaintenanceJobPreview(
    $serviceAgreementId: String!
    $customerPropertyId: String!
  ) {
    customerPropertyMaintenanceJobPreview(
      serviceAgreementId: $serviceAgreementId
      customerPropertyId: $customerPropertyId
    ) {
      name
      firstDueDate
      numberOfPropertyAssets
      cumulativeLaborEstimate
      singleJobTaskTemplateId
      singleJobTaskTemplateName
      mergedTaskTemplates {
        id
        name
      }
      mergedTaskChecklists {
        id
        name
        assetTypeId
      }
    }
  }
`;

const useCustomerPropertyMaintenanceJobPreview = (
  { serviceAgreementId, customerPropertyId },
  options = {}
) => {
  return useExtendedQuery(customerPropertyMaintenanceJobPreview, {
    variables: {
      serviceAgreementId,
      customerPropertyId
    },
    skip: !serviceAgreementId || !customerPropertyId,
    transform: response =>
      sortBy(
        (response?.customerPropertyMaintenanceJobPreview || []).map(
          (
            {
              name,
              firstDueDate,
              numberOfPropertyAssets,
              cumulativeLaborEstimate,
              singleJobTaskTemplateId,
              singleJobTaskTemplateName,
              mergedTaskTemplates,
              mergedTaskChecklists
            },
            index
          ) => ({
            id: singleJobTaskTemplateId
              ? `${customerPropertyId}-${firstDueDate}-${singleJobTaskTemplateId}`
              : `${customerPropertyId}-${firstDueDate}`,
            maintenanceName: `Maintenance ${index + 1}`,
            name,
            firstDueDate,
            numberOfPropertyAssets,
            cumulativeLaborEstimate,
            singleJobTaskTemplateId,
            singleJobTaskTemplateName,
            mergedTaskTemplates: singleJobTaskTemplateId
              ? [{ id: singleJobTaskTemplateId, name: singleJobTaskTemplateName }]
              : mergedTaskTemplates,
            propertyChecklistNames: mergedTaskChecklists
              .filter(cl => !cl.assetTypeId)
              .map(cl => cl.name)
              .join(', ')
          })
        ),
        'propertyChecklistNames'
      ),
    fetchPolicy: 'no-cache',
    ...options
  });
};

export default useCustomerPropertyMaintenanceJobPreview;
