import gql from 'graphql-tag';

import { watchedQueries } from '@dispatch/Dispatch.store';
import useExtendedMutation from 'customHooks/useExtendedMutation';

const DELETE_MAN_DAY_EVENTS_GROUP_MUTATION = gql`
  mutation deleteManDayEventsGroup($data: DeleteManDayInput!) {
    deleteManDayEventsGroup(data: $data) {
      id
      isActive
    }
  }
`;

const serializer = ({ id }) => ({
  variables: {
    data: {
      id
    }
  }
});

const useDeleteManDayEventsGroup = () => {
  return useExtendedMutation(DELETE_MAN_DAY_EVENTS_GROUP_MUTATION, {
    serializer,
    update: (cache, { data }) => {
      if (watchedQueries.useDispatchBoardEvents) {
        const prevEventsResponse = cache.readQuery(watchedQueries.useDispatchBoardEvents);
        const prevItems = prevEventsResponse?.getDispatchEventsByTechIds?.manDays;

        if (prevItems) {
          const deletedManDayIdsMap =
            data?.deleteManDayEventsGroup?.reduce(
              (result, manDay) => ({
                ...result,
                [manDay.id]: true
              }),
              {}
            ) || {};

          const nextItems = prevItems.filter(({ id }) => !deletedManDayIdsMap[id]);

          const newData = {
            getDispatchEventsByTechIds: {
              ...prevEventsResponse.getDispatchEventsByTechIds,
              manDays: nextItems
            }
          };

          cache.writeQuery({
            query: watchedQueries.useDispatchBoardEvents.query,
            data: newData,
            variables: { replace: true }
          });
        }
      }

      if (watchedQueries.useDispatchBoardManDayItems) {
        const query = watchedQueries.useDispatchBoardManDayItems;
        const prevItems = cache.readQuery(query)?.listManDay;

        if (prevItems) {
          const deletedManDayIdsMap =
            data?.deleteManDayEventsGroup?.reduce(
              (result, manDay) => ({
                ...result,
                [manDay.id]: true
              }),
              {}
            ) || {};

          const nextItems = prevItems.filter(({ id }) => !deletedManDayIdsMap[id]);

          cache.writeQuery({
            ...query,
            data: {
              listManDay: nextItems
            }
          });
        }
      }
    }
  });
};

export default useDeleteManDayEventsGroup;
