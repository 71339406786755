import { generateDefaultFieldsObject } from '@pm/components/formattingUtils';
import { departmentSearch } from 'services/API/department';
import { projectCostCodeSearch } from 'services/API/projectCostCode';

import { costTypeFieldLabelMapping } from './constants';

const margin = '0px 15px 21px 0px';
const codeMargin = '0px 15px 2px 0px';

// Equipment Information
export const budgetFields = {
  ProjectPhaseDepartment: { required: true }
};

const defaultFlexStyle = '0 1 219px';

const generateCostTypeInputs = (
  costTypeMapping,
  isCostCode = false,
  showLabel = true,
  disabled = false
) => {
  const fieldNames = Object.keys(costTypeMapping);
  return (
    fieldNames.map(fieldName => ({
      options: {
        direction: 'column',
        margin: isCostCode ? codeMargin : margin,
        justifyContent: 'center'
      },
      contents: [
        {
          source: fieldName,
          component: 'CurrencyInput',
          options: {
            label: showLabel ? costTypeMapping[fieldName] : undefined,
            variant: 'standard',
            color: 'secondary',
            disabled,
            min: 0
          }
        }
      ]
    })) || []
  );
};

const deptCodeContents = (deptSelected, codeIndex) => {
  const showLabel = codeIndex === 0;
  return [
    {
      options: {
        direction: 'row'
      },
      contents: [
        {
          options: {
            direction: 'column',
            justifyContent: 'center'
          },
          contents: [
            {
              source: 'name',
              component: 'CustomFieldWithLabel',
              options: {
                label: showLabel ? 'Name' : undefined,
                variant: 'standard',
                color: 'secondary',
                disabled: deptSelected
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            margin: codeMargin,
            justifyContent: 'center'
          },
          contents: [
            {
              source: 'installUnits',
              component: 'TextInput',
              options: {
                label: showLabel ? 'Install Units' : undefined,
                variant: 'standard',
                color: 'secondary',
                disabled: deptSelected,
                type: 'number',
                min: 0
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            margin: codeMargin,
            justifyContent: 'center'
          },
          contents: [
            {
              source: 'laborHours',
              component: 'TextInput',
              options: {
                label: showLabel ? 'Labor Hours' : undefined,
                variant: 'standard',
                color: 'secondary',
                disabled: deptSelected,
                type: 'number',
                min: 0
              }
            }
          ]
        },
        ...generateCostTypeInputs(costTypeFieldLabelMapping, true, showLabel, deptSelected),
        {
          options: {
            direction: 'column',
            margin: codeMargin,
            justifyContent: 'center'
          },
          contents: [
            {
              source: 'totalCost',
              component: 'CustomFieldWithLabel',
              options: {
                label: showLabel ? 'Total' : undefined,
                variant: 'standard',
                color: 'secondary',
                type: 'currency',
                min: 0
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            margin: codeMargin,
            marginTop: 20,
            justifyContent: 'center'
          },
          contents: [
            {
              component: {
                default: 'deleteCostCodeButton',
                edit: 'deleteCostCodeButton'
              },
              options: {
                codeIndex
              }
            }
          ]
        }
      ]
    }
  ];
};

const deptFormContents = (
  projectId,
  phaseDeptIds,
  phaseDeptCCIds,
  addCodeToTable,
  handleDeptSelect,
  deptSelected
) => [
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1,
      margin: '8px 0px 10px'
    },
    contents: [
      {
        component: 'CustomDividerStyled'
      }
    ]
  },
  {
    options: {
      size: 2,
      direction: 'row',
      grow: 0,
      flexWrap: 'wrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: 'SearchBar',
            source: 'Department',
            options: {
              resultFormatFunction: obj => `${obj.tagName || ''}`,
              searchFunction: departmentSearch,
              sortFunction: (a, b) => (a.tagName > b.tagName ? 1 : -1),
              frontendFiltering: results => {
                return results.filter(dept => !phaseDeptIds.includes(dept.id));
              },
              searchColumns: ['tagName'],
              variant: 'standard',
              color: 'secondary',
              label: 'Department',
              isRequired: true,
              placeholder: 'Search Departments',
              onSelect: handleDeptSelect
            }
          }
        ]
      },
      {
        options: {
          size: 1,
          direction: 'column',
          grow: 0,
          marginTop: 20
        },
        contents: [
          {
            component: {
              default: 'deleteDeptButton',
              edit: 'deleteDeptButton'
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      size: 2,
      direction: 'row',
      grow: 0,
      flexWrap: 'wrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            source: 'contractValue',
            component: 'CustomFieldWithLabel',
            options: {
              label: 'Contract Value Remaining',
              fullWidth: true,
              alignment: 'right',
              variant: 'standard',
              color: 'secondary',
              type: 'currency'
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1
    },
    contents: [
      {
        options: {
          margin
        },
        contents: [
          {
            component: 'MuiFormSectionTitle',
            options: {
              label: 'Revenue Estimates'
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      direction: 'row'
    },
    contents: [
      ...generateCostTypeInputs(costTypeFieldLabelMapping, false, true, deptSelected),
      {
        options: {
          margin
        },
        contents: [
          {
            source: 'totalRevenue',
            component: 'CustomFieldWithLabel',
            options: {
              label: 'Total',
              variant: 'standard',
              color: 'secondary',
              type: 'currency',
              min: 0
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1
    },
    contents: [
      {
        options: {
          margin
        },
        contents: [
          {
            component: 'MuiFormSectionTitle',
            options: {
              label: 'Cost Estimates'
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      size: 2,
      direction: 'row',
      grow: 0,
      flexWrap: 'wrap'
    },
    contents: [
      {
        component: 'SearchBar',
        source: 'costCode',
        options: {
          resultFormatFunction: obj => `${obj.name} - ${obj.description}`,
          sortFunction: (a, b) => (a.name > b.name ? 1 : -1),
          searchFunction: (term, searchColumns) =>
            projectCostCodeSearch(term, searchColumns, projectId),
          frontendFiltering: results => {
            return results.filter(costCode => !phaseDeptCCIds.includes(costCode.id));
          },
          searchColumns: ['name', 'description'],
          variant: 'standard',
          color: 'secondary',
          placeholder: 'Search Cost Codes',
          onSelect: addCodeToTable,
          disabled: deptSelected
        }
      }
    ]
  }
];

export default function layout(
  projectId,
  phaseDeptIds,
  phaseDeptCCIds,
  addCodeToTable,
  handleDeptSelect,
  deptSelected
) {
  return {
    fields: {
      ...generateDefaultFieldsObject(budgetFields)
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          ...deptFormContents(
            projectId,
            phaseDeptIds,
            phaseDeptCCIds,
            addCodeToTable,
            handleDeptSelect,
            !deptSelected
          )
        ]
      }
    }
  };
}

export function codesLayout(deptSelected, codeIndex) {
  return {
    fields: {},
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [...deptCodeContents(!deptSelected, codeIndex)]
      }
    }
  };
}
