import moment from 'moment';

export const Actions = {
  CLEAR_VISIT_ROW_HOVER: 'CLEAR_VISIT_ROW_HOVER',
  CLEAR_DRAWER_DATA: 'CLEAR_DRAWER_DATA',
  CLOSE_TRAY: 'CLOSE_TRAY',
  CLOSE_VISIT_OR_EVENT: 'CLOSE_VISIT_OR_EVENT',
  CREATE_EVENT_WITH_DATA: 'CREATE_EVENT_WITH_DATA',
  FILTER_BY: 'FILTER_BY',
  HOVER_VISIT_ROW: 'HOVER_VISIT_ROW',
  OPEN_TAB: 'OPEN_TAB',
  OPEN_TRAY: 'OPEN_TRAY',
  OPEN_VISIT: 'OPEN_VISIT',
  OPEN_BILLABLE_EVENT: 'OPEN_BILLABLE_EVENT',
  OPEN_NON_BILLABLE_EVENT: 'OPEN_NON_BILLABLE_EVENT',
  OPEN_MAN_DAY: 'OPEN_MAN_DAY',
  SELECT_DAY: 'SELECT_DAY',
  SELECT_END_DAY: 'SELECT_END_DAY',
  SET_DISPATCH_SETTINGS: 'SET_DISPATCH_SETTINGS',
  SET_WEEK_VIEW: 'SET_WEEK_VIEW',
  SET_MAP_VIEW: 'SET_MAP_VIEW',
  SET_FLAGGED_VISITS: 'SET_FLAGGED_VISITS'
};

export const openVisit = visitId => ({
  type: Actions.OPEN_VISIT,
  payload: {
    visitId
  }
});

export const openBillableEvent = billableEventId => ({
  type: Actions.OPEN_BILLABLE_EVENT,
  payload: {
    billableEventId
  }
});

export const openNonBillableEvent = nonBillableEventId => ({
  type: Actions.OPEN_NON_BILLABLE_EVENT,
  payload: {
    nonBillableEventId
  }
});

export const openManDay = manDayId => ({
  type: Actions.OPEN_MAN_DAY,
  payload: {
    manDayId
  }
});

export const createEventWithData = ({ eventType, eventData }) => ({
  type: Actions.CREATE_EVENT_WITH_DATA,
  payload: {
    eventType,
    eventData
  }
});

export const clearDrawerData = () => ({
  type: Actions.CLEAR_DRAWER_DATA
});

export const closeVisitOrEvent = () => ({
  type: Actions.CLOSE_VISIT_OR_EVENT
});

export const openTray = () => ({
  type: Actions.OPEN_TRAY
});

export const closeTray = () => ({
  type: Actions.CLOSE_TRAY
});

export const openTab = tab => ({
  type: Actions.OPEN_TAB,
  payload: { tab }
});

export const hoverVisitRow = data => ({
  type: Actions.HOVER_VISIT_ROW,
  payload: {
    primaryTechIds: data.primaryTechIds,
    extraTechIds: data.extraTechIds
  }
});

export const clearVisitRowHover = () => ({
  type: Actions.CLEAR_VISIT_ROW_HOVER
});

export const filterBy = filter => ({
  type: Actions.FILTER_BY,
  payload: filter
});

export const selectDay = day => {
  const isToday = moment()
    .startOf('day')
    .isSame(day, 'day');

  return {
    type: Actions.SELECT_DAY,
    payload: isToday ? '' : day.format('MM-DD-YYYY')
  };
};

export const selectEndDay = (endDay, selectedDay, weekView) => {
  const isSameDay = weekView
    ? moment(selectedDay)
        .add(7, 'days')
        .isSame(endDay, 'day')
    : selectedDay.isSame(endDay, 'day');

  return {
    type: Actions.SELECT_END_DAY,
    payload: isSameDay ? '' : endDay.format('MM-DD-YYYY')
  };
};

export const setDispatchSettings = settings => ({
  type: Actions.SET_DISPATCH_SETTINGS,
  payload: settings
});

export const setWeekView = boolean => ({
  type: Actions.SET_WEEK_VIEW,
  payload: boolean
});

export const setMapView = boolean => ({
  type: Actions.SET_MAP_VIEW,
  payload: boolean
});

export const setFlaggedVisits = boolean => ({
  type: Actions.SET_FLAGGED_VISITS,
  payload: boolean
});
