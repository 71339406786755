/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import { TV, TW, Typography } from '@BuildHero/sergeant';
import PropTypes from 'prop-types';

import CustomTooltip from 'components/CustomTooltip';

import TimestampDetailModal from './TimestampDetailModal';

const useStyles = theme => ({
  tooltipEntry: {
    fontSize: '1em',
    alignItems: 'center',
    width: '100%',
    margin: '1em'
  },
  timestampLabel: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer'
  },
  timestamp: {
    marginLeft: '0.3em',
    textDecoration: 'underline'
  },
  toolTipContents: {
    fontSize: '1em',
    alignItems: 'center',
    width: '100%'
  },
  toolTipDetails: {
    marginTop: '0.5em'
  },
  text: {
    margin: '0.25em'
  }
});

const VisitTimeHistoryEntry = ({
  timestampLabel,
  timestampDetails,
  toolTipTitle,
  toolTipDetails,
  modalTitle,
  modalDetails,
  gpsLocation,
  theme
}) => {
  const styles = useStyles(theme);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <CustomTooltip
        arrow
        toolTipContents={
          <div css={styles.toolTipContents}>
            <Typography variant={TV.BASE} weight={TW.BOLD}>
              {toolTipTitle}
            </Typography>
            {toolTipDetails.map(line => (
              <Typography css={styles.toolTipDetails} variant={TV.BASE}>
                {line}
              </Typography>
            ))}
          </div>
        }
      >
        <div css={styles.timestampLabel} onClick={() => setModalOpen(true)}>
          <Typography variant={TV.BASE}>{timestampLabel}</Typography>
          <Typography css={styles.timestamp} variant={TV.BASE} weight={TW.BOLD}>
            {timestampDetails}
          </Typography>
        </div>
      </CustomTooltip>

      <TimestampDetailModal
        bodyTextLines={modalDetails}
        gpsLocation={gpsLocation}
        isOpen={modalOpen}
        theme={theme}
        title={modalTitle}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};

VisitTimeHistoryEntry.propTypes = {
  timestampLabel: PropTypes.string.isRequired,
  timestampDetails: PropTypes.string.isRequired,
  toolTipTitle: PropTypes.string.isRequired,
  toolTipDetails: PropTypes.arrayOf(PropTypes.string),
  modalTitle: PropTypes.string.isRequired,
  modalDetails: PropTypes.arrayOf(PropTypes.string),
  gpsLocation: PropTypes.shape({
    latitude: PropTypes.string,
    longitude: PropTypes.string
  }),
  theme: PropTypes.object.isRequired
};

VisitTimeHistoryEntry.defaultProps = {
  gpsLocation: {
    latitude: '',
    longitude: ''
  },
  toolTipDetails: [],
  modalDetails: []
};

export default VisitTimeHistoryEntry;
