import React from 'react';

import { Chip, withStyles } from '@material-ui/core';

import chipStatuses from 'meta/Scheduler/statuses/chips';

import styles from './styles';

class VisitStatus extends React.Component {
  render() {
    const { classes, locale, reason } = this.props;
    let { statuses } = this.props;
    statuses = statuses.map(status => chipStatuses[status]);

    return (
      <div className={classes.root}>
        {statuses.map(status => (
          <Chip
            className={classes.chip}
            key={status.name[locale]}
            label={status.name[locale]}
            style={{ backgroundColor: status.backgroundColor, color: status.color }}
          />
        ))}
        {reason ? (
          <Chip
            className={classes.chip}
            key="reason"
            label={reason}
            style={{ backgroundColor: '#959595', color: '#ffffff' }}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default withStyles(styles)(VisitStatus);
