import React, { useMemo, useState } from 'react';

import { Divider, Typography } from '@BuildHero/sergeant';
import { isEmpty } from 'lodash';

import ToggleLabel from 'components/ToggleLabel';

import { secondsToHour } from 'scenes/Payroll/TimeTrackingReport/helpers';

import { generateWorkEventDataFromDismissedBinders } from '../helpers';

import TimesheetCards from './TimesheetCards';

const styles = {
  dismissedBox: {
    display: 'flex',
    flexDirection: 'row'
  },
  totalHours: {
    marginLeft: 'auto'
  }
};

const DismissedBinders = ({
  dismissedBinders,
  payrollHourTypes,
  payrollSetting,
  updateTimesheetEntry,
  setNotesModalData,
  setDismissedBinderMap,
  tenantId,
  employeeId,
  onUpdateDayCard,
  canDismiss = false
}) => {
  const [toggle, setToggle] = useState(false);

  const dismissedWorkEvents = useMemo(() => {
    return generateWorkEventDataFromDismissedBinders(dismissedBinders, payrollHourTypes);
  }, [dismissedBinders, payrollHourTypes]);

  const { workEvents, totalDismissedTime } = dismissedWorkEvents;

  if (isEmpty(dismissedBinders)) return null;

  return (
    <>
      <Divider />
      <div css={styles.dismissedBox}>
        <ToggleLabel
          label={`dismissed binders (${workEvents.length})`}
          toggle={toggle}
          onClick={() => setToggle(!toggle)}
        />
        <Typography css={styles.totalHours}>
          {`${secondsToHour(totalDismissedTime)} hours dismissed`}
        </Typography>
      </div>
      <Divider />
      {toggle && (
        <TimesheetCards
          areBindersEnabled
          canDismiss={canDismiss}
          employeeId={employeeId}
          isDismissed
          isEditable={false}
          payrollHourTypes={payrollHourTypes}
          payrollSetting={payrollSetting}
          setDismissedBinderMap={setDismissedBinderMap}
          setNotesModalData={setNotesModalData}
          tenantId={tenantId}
          updateTimesheetEntry={updateTimesheetEntry}
          workEvents={workEvents}
          onUpdateDayCard={onUpdateDayCard}
        />
      )}
    </>
  );
};

export default DismissedBinders;
