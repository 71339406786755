import React, { useState } from 'react';

import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { getHourValue, hourToSeconds, totalFieldCheck } from './helpers';
import useStyles from './HourSummary.style';

const HourView = ({
  detail,
  isEditable,
  hasEditPermission,
  onHourSummaryFocusOut,
  onHourSummaryChange
}) => {
  const classes = useStyles();

  const [displayHourValue, setDisplayHourValue] = useState();

  const value = getHourValue(detail);
  const isTotalField = totalFieldCheck(detail);
  const showLabel = [!isEditable, isTotalField, !hasEditPermission].some(Boolean);
  if (showLabel) {
    return (
      <Typography className={classes.valueLabel} variant="h3">
        {detail.hour}
      </Typography>
    );
  }

  const updateValue = v => ({
    ...detail,
    isEdited: true,
    actualTotalDurationOverride: v
  });

  const handleTimeChange = e => {
    setDisplayHourValue(e.target.value);
    const v = e.target.value ? hourToSeconds(parseFloat(e.target.value)) : 0;

    return onHourSummaryChange(updateValue(v));
  };
  const handleTimeFocusOut = e => {
    const hours = e.target.value ? parseFloat(e.target.value) : 0;

    if (detail.isEdited) {
      const v = hourToSeconds(hours);
      const { isEdited, ...payload } = updateValue(v);
      onHourSummaryFocusOut(payload);
    }
    setDisplayHourValue(undefined);
  };

  return (
    <TextField
      className={classes.time}
      InputProps={{
        classes: {
          root: classes.timeInputRoot
        },
        step: 'any',
        disableUnderline: true
      }}
      type="number"
      value={displayHourValue || displayHourValue === '' ? displayHourValue : value}
      onBlur={handleTimeFocusOut}
      onChange={handleTimeChange}
    />
  );
};

export default HourView;
