import React from 'react';

import { Select } from '@BuildHero/sergeant';

const CustomAssetComponent = props => {
  const options = props?.props?.options || [];

  const handleChange = selection => {
    props.form.setFieldValue('assetId', selection?.value);
    props.form.setFieldValue('assetValue', selection?.label);
  };

  return (
    <Select
      clearable
      fullwWdth
      label="Asset"
      options={options}
      placeholder="Select an Asset"
      value={options.find(item => item.value === props?.field?.value)}
      onChange={handleChange}
    />
  );
};

export default CustomAssetComponent;
