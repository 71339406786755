import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  Button,
  ButtonSize,
  ButtonType,
  Input,
  MoreButton,
  Switch,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';
import { makeStyles, useTheme } from '@material-ui/core';
import { Check, Close, Delete, Edit } from '@material-ui/icons';
import { get } from 'lodash';
import moment from 'moment';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import DragIndicator from 'assets/Icons/Handle.svg';

import ViewableIcon from './ViewableIcon';

const useStyles = makeStyles(() => ({
  notesContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > span + span': {
      marginTop: 8
    },
    padding: 0
  },
  noteContainer: {
    display: 'flex',
    border: '1px solid #e5e5e5',
    backgroundColor: '#ffffff',
    padding: 0
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '8px 8px 8px 16px',
    '& > div + div': {
      marginTop: 4
    }
  },
  dragHandle: {
    width: 10,
    backgroundImage: `url(${DragIndicator})`,
    backgroundRepeat: 'repeat-y',
    backgroundPosition: 'center center'
  },
  noteActionsContainer: {
    // alignSelf: 'center'
  },
  subject: {
    display: 'flex'
  }
}));

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  opacity: isDragging ? 0.5 : 1
});

const Note = ({
  note,
  index,
  replace,
  remove,
  hasShareWithTechsOption,
  editingArr,
  setEditingArr
}) => {
  const classes = useStyles();
  const ref = useRef(null);
  const [innerSubject, setInnerSubject] = useState(note.subject);
  const [innerNote, setInnerNote] = useState(note.note);
  const [innerShareWithTechnicianOnMobile, setInnerShareWithTechnicianOnMobile] = useState(
    !note.hideFromTechniciansOnMobile
  );
  const editing = editingArr[index];
  const setEditing = edit => setEditingArr(editingArr.map((val, i) => (i === index ? edit : val)));

  const theme = useTheme();

  const Contents = editing ? (
    <>
      <Input
        placeholder="Subject"
        value={innerSubject}
        onChange={e => setInnerSubject(e.currentTarget.value)}
      />
      <Input
        multiline
        placeholder="Content"
        value={innerNote}
        onChange={e => setInnerNote(e.currentTarget.value)}
      />
      {hasShareWithTechsOption && (
        <Switch
          defaultChecked={innerShareWithTechnicianOnMobile}
          horizontalLabel
          label="                                                             Share with Techs on Mobile"
          onChange={e => setInnerShareWithTechnicianOnMobile(e.target.checked)}
        />
      )}
    </>
  ) : (
    <>
      <div className={classes.subject}>
        <Typography weight={TW.BOLD}>{note.subject}</Typography>
        {hasShareWithTechsOption && <ViewableIcon viewable={!note.hideFromTechniciansOnMobile} />}
      </div>
      <Typography>{note.note}</Typography>
    </>
  );

  const Buttons = editing ? (
    <>
      <Button
        disabled={!innerSubject?.trim() && !innerNote?.trim()}
        size={ButtonSize.SMALL}
        type={ButtonType.LEADING}
        onClick={() => {
          setEditing(false);
          setInnerSubject(innerSubject?.trim() || '');
          setInnerNote(innerNote?.trim() || '');
          const newNote = {
            ...note,
            lastUpdatedBy: undefined,
            subject: innerSubject?.trim() || '',
            note: innerNote?.trim() || '',
            hideFromTechniciansOnMobile: !innerShareWithTechnicianOnMobile
          };
          replace(index, newNote);
        }}
      >
        <Check />
      </Button>
      <Button
        size={ButtonSize.SMALL}
        type={ButtonType.LEADING}
        onClick={() => {
          if (!note?.subject?.trim() && !note?.note?.trim()) {
            remove(index);
          }
          setEditing(false);
          setInnerSubject(note?.subject?.trim() || '');
          setInnerNote(note?.note?.trim() || '');
          setInnerShareWithTechnicianOnMobile(!note.hideFromTechniciansOnMobile);
        }}
      >
        <Close />
      </Button>
    </>
  ) : (
    <MoreButton
      options={[
        {
          label: 'Edit Note',
          icon: () => <Edit style={{ height: 16, color: theme.palette?.text?.tertiary }} />,
          onClick: () => {
            setEditing(true);
            setTimeout(() => ref.current.scrollIntoView({ block: 'nearest', behavior: 'smooth' }));
          }
        },
        {
          label: 'Delete Note',
          icon: () => <Delete style={{ height: 16, color: theme.palette?.text?.tertiary }} />,
          onClick: () => remove(index)
        },

        hasShareWithTechsOption && {
          label: note.hideFromTechniciansOnMobile ? 'Show on Mobile' : 'Hide on Mobile',
          icon: () => (
            <ViewableIcon
              color={theme.palette?.text?.tertiary}
              viewable={note.hideFromTechniciansOnMobile}
            />
          ),
          onClick: () => {
            const newNote = {
              ...note,
              hideFromTechniciansOnMobile: !note.hideFromTechniciansOnMobile
            };
            setInnerShareWithTechnicianOnMobile(!newNote.hideFromTechniciansOnMobile);
            replace(index, newNote);
          }
        }
      ].filter(Boolean)}
    />
  );

  return (
    <Draggable draggableId={note.id} index={index}>
      {({ innerRef, draggableProps, dragHandleProps }, snapshot) => (
        <span
          className={classes.noteContainer}
          ref={innerRef}
          {...draggableProps}
          style={getItemStyle(snapshot.isDragging, draggableProps.style)}
        >
          <span {...dragHandleProps} className={classes.dragHandle} />
          <div className={classes.container} ref={ref}>
            {Contents}
            {note.lastUpdatedBy && (
              <Typography variant={TV.S2}>
                Last updated by {note.displayLastUpdatedBy || note.lastUpdatedBy} on{' '}
                {moment
                  .unix(note.displayLastUpdatedDateTime || note.lastUpdatedDate)
                  .format('llll')}
              </Typography>
            )}
          </div>
          <div className={classes.noteActionsContainer}>{Buttons}</div>
        </span>
      )}
    </Draggable>
  );
};

export default function Notes({ form, name, move, remove, replace, props }) {
  const hasShareWithTechsOption = props?.hasShareWithTechsOption;
  const setDisableSave = props?.setDisableSave;
  const classes = useStyles();
  const notes = useMemo(() => get(form.values, name), [form, name]);

  const isEmptyNote = note => !note.subject && !note.note;
  const [editingArr, setEditingArr] = useState(Array.from(notes).map(isEmptyNote));
  useEffect(() => setEditingArr(Array.from(notes).map(isEmptyNote)), [notes.length]);
  useEffect(() => setDisableSave(editingArr.some(editing => editing === true)), [editingArr]);

  const handleDragEnd = useCallback(
    ({ source, destination }) =>
      destination && destination.index !== source.index && move(source.index, destination.index),
    [move]
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable direction="vertical" droppableId="droppable-notes">
        {provided => (
          <span
            className={classes.notesContainer}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {notes?.map((note, index) => (
              <Note
                editingArr={editingArr}
                hasShareWithTechsOption={hasShareWithTechsOption}
                index={index}
                key={note.id}
                note={note}
                remove={remove}
                replace={replace}
                setEditingArr={setEditingArr}
              />
            ))}
            {provided.placeholder}
          </span>
        )}
      </Droppable>
    </DragDropContext>
  );
}
