import React, { useCallback, useEffect, useState } from 'react';

import { Button, ThemeProvider } from '@BuildHero/sergeant';
import { Box, Typography } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CancelIcon from '@material-ui/icons/Close';
import { isEmpty, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SergeantModal } from 'components';
import Context from 'components/Context';
import { typeModalDefaultState } from 'constants/common';
import { snackbarOn } from 'redux/actions/globalActions';
import { Logger } from 'services/Logger';
import { toTitleCase } from 'utils';

import { Mode, SergeantButtonTypes } from 'utils/constants';
import { constructSelectOptions } from 'utils/constructSelectOptions';

import TagLayout from './tagLayout';

/**
 * @description
 * Tags are stored at the company level.
 * When adding a new tag to an entity, we need the company tag id ( entityTag?.id).
 * When deleting an entity tag, we need the entity tag id (i.e. entityEntityTag?.id).
 */

const useStyles = makeStyles(theme => ({
  chip: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    borderRadius: 2,
    height: theme.spacing(2.25),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    fontSize: 12,
    backgroundColor: theme.palette.other.dimGray
  },
  label: {
    paddingRight: 0,
    paddingLeft: theme.spacing(1)
  },
  addTagIcon: {
    padding: theme.spacing(0.3),
    color: theme.palette.other.greyScale
  },
  tagDeleteIcon: {
    color: theme.palette.other.greyScale,
    height: theme.spacing(2),
    marginLeft: theme.spacing(0.1)
  },
  addTagText: {
    fontSize: 12
  }
}));

function TagButtons(props) {
  const [existingEntityEntityTags, setExistingEntityEntityTags] = useState([]);
  const [openTypeModal, setOpenTypeModal] = useState(typeModalDefaultState);
  const [removeTagModal, setRemoveTagModal] = useState(false);
  const [loading, setLoading] = useState(false);

  /**
   * @constant tagMap will be used to delete tags.
   * * i.e. entityTag.id => entityEntityTag.id
   * entityEntityTagIds are only populated on @constant entityTags after reload
   */
  const [tagMap, setTagMap] = useState(new Map());

  const classes = useStyles();
  const { info, tags = [], snackbar, TagType, refetch = () => {} } = props;
  const { [TagType.entityPlural]: tagList } = Context.getCompanyContext()?.getCompany || {};
  const allTags = constructSelectOptions(sortBy(tagList?.items, 'sortOrder'), 'tagName');
  const updateTagMapAndTags = useCallback(tagItems => {
    const updatedMap = new Map();
    tagItems.forEach(tag => {
      updatedMap.set(tag?.[TagType.entity]?.id, tag?.[TagType.deleteKey]);
    });
    setTagMap(updatedMap);
    const constructedTags = constructSelectOptions(
      tagItems,
      `${TagType.entity}.tagName`,
      `${TagType.entity}.id`
    );
    setExistingEntityEntityTags(constructedTags);
  }, []);

  useEffect(() => {
    updateTagMapAndTags(tags);
  }, [tags, updateTagMapAndTags]);

  const addTags = useCallback(
    async tgs => {
      /**
       * @param tgs is an array of @constant entityTags
       * previously Selected tags have id and entityTag entity
       * newly selected tags only has value = entityTag.id
       * */
      const createTagsPayload = () => {
        const newEntityEntityTags = [];
        const updatedTagMap = new Map(tagMap);
        tgs.forEach(tag => {
          const selectedTagId = tag?.value;
          if (!tag?.id && selectedTagId) {
            // tag has not been selected yet
            newEntityEntityTags.push({
              [TagType.entityId]: selectedTagId
            });

            updatedTagMap.set(selectedTagId, undefined);
          }
        });
        return { newEntityEntityTags, updatedTagMap };
      };

      const { newEntityEntityTags, updatedTagMap } = createTagsPayload();
      if (!newEntityEntityTags?.length) return;

      try {
        setLoading(true);
        const { id, version: currentVersion, ...extra } = info;
        if (!id || !currentVersion) {
          // @TODO switch to sentryMessage
          Logger.debug(`Insufficient entity info`, { id, currentVersion });
          return;
        }
        const version = currentVersion;
        const payload = TagType.structurePayload({
          partitionKey: props?.user?.tenantId,
          id,
          version,
          newEntityEntityTags,
          existingEntityEntityTags,
          extra
        });

        if (props.useAPI) {
          const service = props.getService;
          const response = await TagType.update(service, payload);
          if (isEmpty(response)) {
            snackbar('error', `${toTitleCase(TagType.name)} Tag update failed`);
          } else {
            snackbar('success', `Added ${toTitleCase(TagType.name)} Tag`);
          }
          return;
        }
        const service = props.getService();
        const response = await TagType.update(service, payload);
        // update map with the entityEntityTag ids
        const updatedEntityEntityTags = TagType.destructureToEntityEntityTags(response) || [];

        updatedEntityEntityTags.forEach(entityEntityTag => {
          const entityTagId = entityEntityTag?.[TagType.entity]?.id;
          if (entityTagId) {
            updatedTagMap.set(entityTagId, entityEntityTag?.[TagType.deleteKey]);
          }
        });

        const newTags = constructSelectOptions(
          updatedEntityEntityTags,
          `${TagType.entity}.tagName`,
          `${TagType.entity}.id`
        );
        const oldTags = existingEntityEntityTags.filter(
          t => !newTags.some(nt => nt.value === t.value)
        );
        setExistingEntityEntityTags([...newTags, ...oldTags]);
        setTagMap(updatedTagMap);
        snackbar('success', `Added ${toTitleCase(TagType.name)} Tag`);
      } catch (error) {
        snackbar('error', `${toTitleCase(TagType.name)} Tag update failed`, error);
      } finally {
        refetch();
        setLoading(false);
      }
    },
    [
      info,
      props.user,
      snackbar,
      tagMap,
      existingEntityEntityTags,
      TagType,
      props.getService,
      refetch
    ]
  );

  const Open = () => {
    setOpenTypeModal({
      ...openTypeModal,
      dataType: 'Tag',
      open: true,
      mode: Mode.ADD
    });
  };

  const close = () => {
    setOpenTypeModal({
      ...openTypeModal,
      open: false
    });
  };

  const save = (values, modalCallback) => {
    if (values?.[TagType.entityIdPlural]) {
      const selectedTags = allTags.filter(
        tag => values?.[TagType.entityIdPlural]?.indexOf(tag?.value) >= 0
      );
      addTags(selectedTags);
    }
    close();
    modalCallback();
  };

  const handleDelete = async (value, stopLoading) => {
    try {
      if (props.useAPI) {
        const { ...extra } = info;
        const response = await TagType.delete(props.getService, value, extra);
        if (!isEmpty(response)) {
          snackbar('success', `Deleted ${toTitleCase(TagType.name)} tag`);
        } else {
          snackbar('error', `Failed to delete ${toTitleCase(TagType.name)} tag`);
        }
        stopLoading();
        setRemoveTagModal(false);
        return;
      }

      const service = props.getService();
      const tagToDelete = tagMap.get(value);
      if (!tagToDelete) {
        throw new Error('Tag id unavailable');
      }
      const { id, version: currentVersion } = info;
      const version = currentVersion + 1;
      await TagType.delete({
        service,
        tenantId: props.user.tenantId,
        tagToDelete,
        tagId: value,
        info: { version, id }
      });
      setExistingEntityEntityTags(tgs => tgs.filter(option => option?.value !== value));
      snackbar('success', `Deleted ${toTitleCase(TagType.name)} tag`);
      refetch();
      stopLoading();
      setRemoveTagModal(false);
    } catch (error) {
      snackbar('error', `Failed to delete ${toTitleCase(TagType.name)} tag`, error);
    }
  };

  const assignedTagIds = existingEntityEntityTags.map(tag => tag?.value);
  const layout = TagLayout(
    allTags.filter(x => assignedTagIds.indexOf(x.value) < 0),
    TagType.entityIdPlural
  );

  return (
    <>
      <Box alignItems="center" display="flex" flexDirection="row">
        {!props.readonly && (
          <Box>
            <ThemeProvider>
              <Button
                loading={loading}
                style={{ padding: 8 }}
                testingid="button-addTag"
                type={SergeantButtonTypes.LEADING}
                onClick={Open}
              >
                <AddCircleOutlineOutlinedIcon className={classes.addTagIcon} />
                <Typography className={classes.addTagText}>ADD TAG</Typography>
              </Button>
            </ThemeProvider>
          </Box>
        )}
        <Box flex={1}>
          {existingEntityEntityTags.map(tag => (
            <Chip
              classes={{ root: classes.chip, label: classes.label }}
              deleteIcon={!props.readonly && <CancelIcon className={classes.tagDeleteIcon} />}
              key={tag.value}
              label={tag.label}
              onDelete={!props.readonly && (() => setRemoveTagModal(tag.value))}
            />
          ))}
        </Box>
      </Box>
      <SergeantModal
        data={openTypeModal.data}
        dataType={openTypeModal.dataType}
        handleClose={close}
        handlePrimaryAction={save}
        layout={layout}
        maxWidth={500}
        mode={openTypeModal.mode}
        open={openTypeModal.open}
      />
      <SergeantModal
        customPrimaryButtonLabel="Remove"
        data={removeTagModal}
        dataType={`${toTitleCase(TagType.name)} Tag`}
        handleClose={() => setRemoveTagModal(false)}
        handlePrimaryAction={handleDelete}
        mode={Mode.DELETE}
        open={!!removeTagModal}
      />
    </>
  );
}

TagButtons.propTypes = {
  user: PropTypes.shape({ tenantId: PropTypes.string.isRequired }).isRequired,
  readonly: PropTypes.bool,
  info: PropTypes.shape({
    id: PropTypes.string.isRequired,
    version: PropTypes.number.isRequired,
    customerPropertyId: PropTypes.string.isRequired
  }).isRequired,
  tags: PropTypes.array,
  snackbar: PropTypes.func.isRequired,
  TagType: PropTypes.shape({
    name: PropTypes.string.isRequired,
    namePlural: PropTypes.string.isRequired,
    entityId: PropTypes.string.isRequired,
    entityIdPlural: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    entityPlural: PropTypes.string.isRequired,
    update: PropTypes.func.isRequired,
    destructureToEntityEntityTags: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    deleteKey: PropTypes.string.isRequired,
    structurePayload: PropTypes.func.isRequired
  }).isRequired
};

TagButtons.defaultProps = {
  readonly: false,
  tags: []
};

export default connect(state => ({ user: state.user }), { snackbar: snackbarOn })(TagButtons);
