import React from 'react';

import PropTypes from 'prop-types';

import { ElementSizes } from '@dispatch/Dispatch.styles';

import { useTimePosition } from '../../hocs/withTimePosition';

import { useStyles } from './CurrentTimeTicker.styles';

const { tickerWidth, stickyHeaderHeight } = ElementSizes;

const CurrentTimeTicker = ({ boardHeight, day }) => {
  const classes = useStyles();
  const { time, position } = useTimePosition();
  const text = time.format('h:mm a');
  const linePosition = position;
  const boxPosition = linePosition - tickerWidth / 2;

  // existence of day in querystring indicates current day is not selected
  if (day) return null;

  return (
    <>
      <div className={classes.box} style={{ left: boxPosition }}>
        {text}
      </div>
      <div
        className={classes.line}
        style={{ left: linePosition, height: boardHeight - stickyHeaderHeight }}
      />
    </>
  );
};

CurrentTimeTicker.propTypes = {
  boardHeight: PropTypes.number.isRequired,
  // eslint-disable-next-line react/require-default-props
  day: PropTypes.string
};

export default CurrentTimeTicker;
