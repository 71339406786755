import gql from 'graphql-tag';
import orderBy from 'lodash/orderBy';
import { arrayOf, bool, shape, string } from 'prop-types';

import { ERROR_PROP } from '@dispatch/Dispatch.constants';
import useExtendedQuery from 'customHooks/useExtendedQuery';

const GET_CREWS = gql`
  query getCrews {
    crews {
      items {
        id
        name
        departmentId
        foremanId
        techs {
          id
        }
      }
    }
  }
`;

const transformCrews = data => {
  return orderBy(data.crews.items, [crew => crew.name.toLowerCase()]);
};

export const useCrews = (options = {}) => {
  return useExtendedQuery(GET_CREWS, {
    transform: transformCrews,
    defaultData: [],
    ...options
  });
};

export const CREW_PROP = shape({
  id: string,
  name: string,
  departmentId: string,
  techs: arrayOf(shape({ id: string }))
});

export const CREWS_RESPONSE_PROP = shape({
  loading: bool,
  error: ERROR_PROP,
  data: arrayOf(CREW_PROP)
});
