import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const UPSERT_CUSTOMER_PROPERTY_SERVICE_AGREEMENT_METADATA = gql`
  mutation upsertCustomerPropertyServiceAgreementMetadata(
    $data: UpsertCustomerPropertyServiceAgreementMetadataInput!
  ) {
    upsertCustomerPropertyServiceAgreementMetadata(input: $data) {
      id
      department {
        id
        tagName
      }
      propertyRep {
        id
        name
      }
      primaryTech {
        id
        name
      }
      selectedCrew {
        id
        name
      }
      additionalTechs {
        id
        employee {
          id
          name
        }
      }
    }
  }
`;

const useUpsertCustomerPropertyServiceAgreementMetadata = ({
  serviceAgreementId,
  onSuccess = () => {}
}) => {
  return useExtendedMutation(UPSERT_CUSTOMER_PROPERTY_SERVICE_AGREEMENT_METADATA, {
    serializer: ({
      id,
      customerPropertyId,
      departmentId,
      propertyRepId,
      primaryTechId,
      selectedCrew,
      additionalTechIds
    }) => ({
      variables: {
        data: {
          id,
          serviceAgreementId,
          customerPropertyId,
          departmentId,
          propertyRepId,
          primaryTechId,
          selectedCrew,
          additionalTechIds
        }
      }
    }),
    onSuccess: (transformedData, snackbarOn) => {
      snackbarOn('success', 'Service Agreement Property Metadata Saved');
      onSuccess();
    },
    onError: (transformedData, snackbarOn) =>
      snackbarOn('error', 'Unable to Save Service Agreement Property Metadata')
  });
};

export default useUpsertCustomerPropertyServiceAgreementMetadata;
