import React, { createRef, useCallback, useContext, useEffect, useState } from 'react';

import { Button, ButtonType, TV, TW, Typography } from '@BuildHero/sergeant';
import { Grid, ListItemIcon, MenuItem, Select } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UpdateIcon from '@material-ui/icons/Update';
import { useGridSlotComponentProps } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenameModal from 'components/ResponsiveTable/RenameModal';
import { snackbarOn } from 'redux/actions/globalActions';
import theme from 'themes/BuildHeroTheme';
import { Mode } from 'utils/constants';

import { viewStyles } from '../ResponsiveTable/styles';
import ViewMenuItem from '../ResponsiveTable/ViewActionsMenuItem';
import ViewContext from '../ResponsiveTable/ViewContext';
import {
  accessConstants,
  getSettingObjectFromView,
  saveView,
  viewIsDefault
} from '../ResponsiveTable/ViewHelper';

const moreIconStyle = { fontSize: 16 };

const ViewsToolbarButton = props => {
  const {
    user,
    tableName,
    entityTypeName,
    numberOfRows,
    handleViewChange,
    snackbarOn: snackbar,
    defaultSetting
  } = props;

  const { views, setViews, viewsLoaded, setViewsLoaded } = useContext(ViewContext);
  const defaultView = {
    displayName: 'Standard View',
    name: tableName,
    settings: JSON.stringify(defaultSetting)
  };
  const classes = viewStyles();
  const viewSelectRef = createRef();
  const { state, apiRef } = useGridSlotComponentProps();
  const columns = apiRef.current.getAllColumns();
  const filters = state.filter;
  const sort = apiRef.current.getSortModel();
  const [showCreateNewViewModal, setShowCreateNewViewModal] = useState(false);
  const defaultNewViewName = `Views #${(views?.length || 0) + 1}`;
  const defaultViewDefinedByUser = views?.find(v => viewIsDefault(v));
  const nonDefaultViews = views?.filter(v => v.id !== defaultViewDefinedByUser?.id);

  const [selectedView, setSelectedView] = useState(defaultView);
  const [subMenuView, setSubmenuView] = useState();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const MenuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    anchorEl: () => viewSelectRef.current,
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        width: 220,
        background: theme.palette.grayscale(100)
      }
    },
    autoFocus: false
  };

  // set state only when there is default view defined
  useEffect(() => {
    // only select the default view when views have been loaded, and only do it once.
    if (isFirstLoad && viewsLoaded) {
      if (defaultViewDefinedByUser) setSelectedView(defaultViewDefinedByUser);
      setIsFirstLoad(false);
    }
  }, [defaultViewDefinedByUser, isFirstLoad, viewsLoaded]);

  const selectedViewIsEditable = useCallback(
    view =>
      view &&
      (view.access === 'PRIVATE' ||
        (view.access === 'PUBLIC' && user.cognitoRole === 'TenantAdmin')),
    [user]
  );

  const updateViewsState = viewsToUpdate => {
    const updatedViews = views.map(v => {
      const updateIndex = viewsToUpdate.findIndex(vtu => vtu && v.id === vtu.id);
      if (updateIndex !== -1) {
        return viewsToUpdate[updateIndex];
      }
      return v;
    });
    setViews(updatedViews);
  };

  const addToViewsState = view => {
    const newViews = [...views, view];
    setViews(newViews);
  };

  const removeFromViewsState = view => {
    const newViews = views.filter(v => v.id !== view.id);
    setViews(newViews);
  };

  const handleCreateView = async newViewName => {
    setViewsLoaded(false);
    const tableSettingObj = {
      meta: columns,
      numberOfRows,
      filters,
      density: state?.density?.value,
      sort,
      sortOrder: sort.length && sort[0].sort,
      sortBy: sort.length && sort[0].field
    };
    const defaultViewName = newViewName || defaultNewViewName;
    const viewData = {
      companyId: user.tenantCompanyId,
      type: 'TABLE_SETTING',
      settings: JSON.stringify(tableSettingObj),
      name: tableName,
      displayName: defaultViewName,
      access: accessConstants.PRIVATE
    };

    const createdView = await saveView(viewData, user.tenantId, snackbar);
    if (createdView) {
      addToViewsState(createdView);
      const settingsObj = getSettingObjectFromView(createdView);
      handleViewChange(settingsObj);
      setSelectedView(createdView);
      snackbar('success', `${createdView.displayName} has been created`);
    }

    setViewsLoaded(true);
  };

  const handleUpdateView = async () => {
    setViewsLoaded(false);
    const tableSettingObj = {
      meta: apiRef.current.getAllColumns(),
      numberOfRows,
      filters,
      density: state?.density?.value,
      sort,
      sortOrder: sort.length && sort[0].sort,
      sortBy: sort.length && sort[0].field
    };
    const viewData = {
      id: selectedView.id,
      companyId: user.tenantCompanyId,
      settings: JSON.stringify(tableSettingObj)
    };

    const updatedView = await saveView(viewData, user.tenantId, snackbar);
    if (updatedView) {
      updateViewsState([updatedView]);
      const settingsObj = getSettingObjectFromView(updatedView);
      handleViewChange(settingsObj);
      setSelectedView(updatedView);
      snackbar('success', `${updatedView.displayName} has been updated successfully`);
    }
    setViewsLoaded(true);
  };

  return (
    <>
      <Typography className={classes.viewCaption} variant={TV.BASE} weight={TW.MEDIUM}>
        VIEWS
      </Typography>
      <Select
        className={classes.selectWrapper}
        disabled={!viewsLoaded}
        MenuProps={MenuProps}
        ref={d => {
          if (d) {
            viewSelectRef.current = d;
          }
        }}
        renderValue={() => (viewsLoaded ? selectedView?.displayName : '...Loading')}
        value={selectedView?.id ? selectedView : defaultView}
        variant="outlined"
        onChange={event => {
          if (!viewsLoaded) return;
          const selected = event.target.value;
          if (!selected) return;
          setSelectedView(selected);
          const settingsObj = getSettingObjectFromView(selected);
          const isStandardView = selected?.displayName === 'Standard View' && !selected?.id; // id check just incase a custom view with name "Standard View" exists
          handleViewChange(settingsObj, isStandardView);
        }}
      >
        <Typography className={classes.viewsTitle} variant={TV.BASE}>
          {`Views for ${entityTypeName ?? tableName}`}
        </Typography>
        <Typography className={classes.sectionTitle} variant={TV.S2}>
          DEFAULT VIEW
        </Typography>

        <MenuItem className={classes.menuContainer} disabled={!viewsLoaded} value={defaultView}>
          <span className={classes.menuText}>{defaultView.displayName}</span>
        </MenuItem>

        {defaultViewDefinedByUser && (
          <MenuItem
            className={classes.menuContainer}
            disabled={!viewsLoaded}
            value={defaultViewDefinedByUser}
          >
            <span className={classes.menuText}>{defaultViewDefinedByUser.displayName}</span>
            <ListItemIcon
              aria-controls={`viewAction${defaultViewDefinedByUser.displayName}`}
              aria-haspopup="true"
              aria-label="more"
              className={classes.moreIcon}
              onClick={event => {
                event.stopPropagation();
                setSubmenuView({ view: defaultViewDefinedByUser, anchorEl: event.currentTarget });
              }}
            >
              <MoreVertIcon style={moreIconStyle} />
            </ListItemIcon>
          </MenuItem>
        )}

        <Typography className={classes.sectionTitle} variant={TV.S2}>
          SAVED VIEWS
        </Typography>
        {nonDefaultViews?.map(view => (
          <MenuItem
            className={classes.menuContainer}
            disabled={!viewsLoaded}
            key={view.id}
            value={view}
          >
            <span className={classes.menuText}>{view.displayName}</span>
            <ListItemIcon
              aria-controls={`viewAction${view.displayName}`}
              aria-haspopup="true"
              aria-label="more"
              className={classes.moreIcon}
              onClick={event => {
                event.stopPropagation();
                setSubmenuView({ view, anchorEl: event.currentTarget });
              }}
            >
              <MoreVertIcon style={moreIconStyle} />
            </ListItemIcon>
          </MenuItem>
        ))}
        <Grid item>
          <Button
            className={classes.addBtn}
            color="secondary"
            disabled={!viewsLoaded}
            startIcon={<AddCircleIcon />}
            type={ButtonType.LEADING}
            onClick={e => {
              e.stopPropagation();
              setShowCreateNewViewModal(true);
            }}
          >
            Save as new view
          </Button>
          <Button
            className={classes.addBtn}
            color="secondary"
            disabled={!selectedViewIsEditable(selectedView) || !viewsLoaded}
            startIcon={<UpdateIcon />}
            type={ButtonType.LEADING}
            onClick={async e => {
              e.stopPropagation();
              handleUpdateView();
            }}
          >
            Update selected view
          </Button>
        </Grid>
      </Select>
      {subMenuView && (
        <ViewMenuItem
          addToViewsState={addToViewsState}
          anchor={subMenuView?.anchorEl}
          disabled={!viewsLoaded}
          fallbackView={defaultView}
          handleCloseSubmenu={() => setSubmenuView(null)}
          removeFromViewsState={removeFromViewsState}
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          setViewsLoaded={setViewsLoaded}
          snackbar={snackbar}
          updateViewsState={updateViewsState}
          user={user}
          view={subMenuView?.view}
          views={views}
        />
      )}
      <RenameModal
        currentLabel={defaultNewViewName}
        handleClose={() => setShowCreateNewViewModal(null)}
        handleViewRename={async newLabel => handleCreateView(newLabel)}
        mode={Mode.NEW}
        open={showCreateNewViewModal}
        onClick={e => e.stopPropagation()}
      />
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user
});
const mapDispatcherToProps = { snackbarOn };

const connectedViews = connect(mapStateToProps, mapDispatcherToProps)(ViewsToolbarButton);

ViewsToolbarButton.propTypes = {
  // passed from parent component, the proptype is same as table component
  // responsible for fetching the saved views from the backend
  tableName: PropTypes.string,
  // sets the views on the main table component. used when the dropdown value is changed
  handleViewChange: PropTypes.func,
  //
  defaultSetting: PropTypes.shape({
    meta: PropTypes.array,
    numberOfRows: PropTypes.number,
    filters: PropTypes.object,
    sortOrder: PropTypes.string,
    sortBy: PropTypes.string
  })
};

ViewsToolbarButton.defaultProps = {
  tableName: undefined,
  defaultSetting: {},
  handleViewChange: () => {}
};

export default connectedViews;
