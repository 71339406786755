import React, { useEffect, useState } from 'react';

import { DateInput, ThemeProvider } from '@BuildHero/sergeant';
import moment from 'moment';
import { bool, func, string } from 'prop-types';

import { useStyles } from './DayRangeSelector.styles';

const DayRangeSelector = ({ day, selectDay, endDay, selectEndDay, weekView }) => {
  const classes = useStyles();
  const [selectedDay, setSelectedDay] = useState(moment());
  const [selectedEndDay, setSelectedEndDay] = useState(moment());

  useEffect(() => {
    setSelectedDay(moment(day));

    if (!endDay) {
      selectEndDay(weekView ? moment(day).add(7, 'days') : moment(day), selectedDay, weekView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day, endDay, selectEndDay, weekView]);

  useEffect(() => {
    setSelectedEndDay(moment(endDay));
  }, [endDay]);

  const handleDateChange = value => {
    selectDay(moment.unix(value));
  };

  const handleEndDateChange = value => {
    selectEndDay(moment.unix(value), selectedDay, weekView);
  };

  return (
    <ThemeProvider>
      <div className={classes.root}>
        <DateInput
          className={classes.startDay}
          label="Start Day"
          value={selectedDay.unix()}
          onChange={handleDateChange}
        />
        <DateInput
          className={classes.endDay}
          disabled
          label="End Day"
          value={selectedEndDay.unix()}
          onChange={handleEndDateChange}
        />
      </div>
    </ThemeProvider>
  );
};

DayRangeSelector.propTypes = {
  day: string.isRequired,
  selectDay: func.isRequired,
  endDay: string.isRequired,
  selectEndDay: func.isRequired,
  weekView: bool.isRequired
};

export default DayRangeSelector;
