import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Button,
  ButtonType,
  Modal,
  MoreButton,
  ThemeProvider,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';
import { noop, sortBy } from 'lodash';
import { useSelector } from 'react-redux';

import { Modal as OldModal, SergeantModal, UserPermission, XGrid } from 'components';
import FormViewer from 'components/FormSection/FormViewer';
import { column, ColumnType, valueGetters } from 'components/XGrid/columnTypes';
import ErrorBoundaries from 'scenes/Error';
import { useDeleteTaskById } from 'scenes/RecommendedTasks/mutations/useDeleteTaskById';
import { ViewPartsAndMaterials } from 'scenes/ServiceAgreements/DetailView/ServiceAgreementsTabs/RecurringMaintenance/Components/Templates/ViewPartsAndMaterials';
import { Logger } from 'services/Logger';
import { PermissionConstants, TaskListType } from 'utils/AppConstants';
import { Mode } from 'utils/constants';

import { getTasksByJobId, useGetTasksByJobid } from '../hooks/useGetTasksByJobId';

import AddEditTasksToJobModal from './AddEditTasksToJobModal';

const useStyles = () => ({
  link: {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
});

const defaultPartsAndMaterial = { open: false, partsAndMaterials: [] };

const defaultSGModalState = {
  open: false,
  renderContent: null,
  data: null,
  dataType: 'Task',
  layout: null,
  mode: Mode.VIEW,
  title: 'Activity'
};

const TaskTableOverflow = ({
  jobId,
  isActive,
  customerPropertyId,
  listType = 'All',
  formatAssetOption
}) => {
  const [showAddEditTasksModal, setShowAddEditTasksModal] = useState(false);
  const [fetchJobTask, toggleFetchJobTask] = useState(false);
  const [viewPartsAndMaterials, setViewPartsAndMaterials] = useState(defaultPartsAndMaterial);
  const [currentForm, setCurrentForm] = useState({});
  const [forms, setForms] = useState([]);
  const [formViewerModalOpen, setFormViewerModalOpen] = useState(false);
  const [formsModalOpen, setFormsModalOpen] = useState(false);
  const [task, setTask] = useState({});
  const [openSGModal, setSGModal] = useState(defaultSGModalState);
  const [mode, setMode] = useState(Mode.ADD);
  const { tenantId } = useSelector(s => s.user);
  const [deleteJobTask] = useDeleteTaskById();
  const styles = useStyles();

  const [
    { data: { items: tasks = [] } = {} },
    loadingTasks,
    error,
    refetchJobTasks
  ] = useGetTasksByJobid({ jobId, listType });

  const renderFormsCell = useCallback(value => {
    const formsData = value.items;

    if (formsData.length === 0) return '-';
    if (formsData.length === 1) {
      const f = formsData[0];
      return (
        <div
          css={styles.link}
          role="button"
          tabIndex={0}
          onClick={() => {
            setFormViewerModalOpen(true);
            setCurrentForm(f);
          }}
          onKeyDown={noop}
        >
          <Typography>{f.form.name}</Typography>
        </div>
      );
    }

    return (
      <div
        css={styles.link}
        role="button"
        tabIndex={0}
        onClick={() => {
          setForms(formsData);
          setFormsModalOpen(true);
        }}
        onKeyDown={noop}
      >
        {formsData.length} Forms
      </div>
    );
  }, []);

  const renderPartsCell = useCallback(
    partsAndMaterials => {
      let cellText;
      const productsLength = partsAndMaterials?.length || 0;
      if (productsLength) {
        cellText = `${productsLength} Part${productsLength > 1 ? 's' : ''}`;
      }
      return (
        <ThemeProvider>
          <div
            css={productsLength === 0 ? undefined : styles.link}
            role="button"
            tabIndex={0}
            onClick={() => setViewPartsAndMaterials({ open: true, partsAndMaterials })}
            onKeyDown={noop}
          >
            <Typography variant={TV.BASE} weight={TW.REGULAR}>
              {cellText || '-'}
            </Typography>
          </div>
        </ThemeProvider>
      );
    },
    [setViewPartsAndMaterials]
  );

  const handleDeleteJobTask = useCallback(
    async ({ id }) => {
      await deleteJobTask({
        tenantId,
        id
      });
      setSGModal(defaultSGModalState);
      toggleFetchJobTask(prev => !prev);
    },
    [tenantId]
  );

  const renderMoreButton = useCallback(
    row => (
      <ThemeProvider>
        <MoreButton
          options={[
            {
              label: 'Edit',
              onClick: () => {
                setShowAddEditTasksModal(true);
                setMode(Mode.EDIT);
                setTask({
                  taskId: row.id,
                  assetId: row.assetId,
                  taskName: row.name,
                  description: row.description,
                  forms: sortBy(row.formData.items, item => item.sortOrder).map(item => ({
                    id: item.form.id,
                    label: item.form.name,
                    value: { ...item.form, updateId: item.id, required: item.isRequired }
                  })),
                  partsAndMaterials: sortBy(row.taskEntries.items, item => item.sortOrder).map(
                    item => ({
                      id: item.priceBookEntryId,
                      label: item.name,
                      value: {
                        ...item,
                        updateId: item.id,
                        code: item.priceBookEntry.product.code
                      }
                    })
                  ),
                  version: row.version
                });
              }
            },
            {
              label: 'Delete Task',
              onClick: async () => {
                setSGModal({
                  open: true,
                  data: { ...row },
                  layout: null,
                  mode: Mode.DELETE,
                  dataType: 'Task',
                  handleAction: handleDeleteJobTask,
                  deleteItemLabel: row.name
                });
              }
            }
          ].filter(Boolean)}
          style={{ padding: 4, marginLeft: 3 }}
        />
      </ThemeProvider>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteJobTask]
  );

  const columns = useMemo(
    () =>
      [
        {
          field: 'asset',
          headerName: 'Asset',
          width: 200,
          dataField: 'assetId',
          valueGetter: valueGetters.assetLink,
          ...column[ColumnType.LINK]
        },
        {
          field: 'name',
          headerName: 'Task',
          flex: 1
        },
        {
          field: 'description',
          headerName: 'Description',
          flex: 1
        },
        {
          field: 'partsAndMaterialsCount',
          flex: 1,
          headerName: 'Materials',
          type: ColumnType.TEXT,
          valueGetter: ({ row }) =>
            row?.taskEntries?.items?.map(entry => ({
              ...entry,
              priceBookEntryName: entry.name,
              priceBookEntryDescription: entry.description
            })),
          renderCell: ({ value }) => renderPartsCell(value)
        },
        {
          field: 'formData',
          flex: 1,
          headerName: 'Forms',
          sortable: false,
          renderCell: ({ value }) => renderFormsCell(value)
        },
        ![TaskListType.COMPLETED_TASKS].includes(listType) &&
          isActive && {
            field: 'action', // doesnt matter as we are not using the value
            headerName: ' ',
            width: 36,
            sortable: false,
            filterable: false,
            renderCell: ({ row }) => (row?.isActive ? renderMoreButton(row) : null)
          }
      ].filter(Boolean),
    [isActive, renderPartsCell, renderFormsCell, renderMoreButton]
  );

  const formMetaData = useMemo(() => {
    try {
      if (currentForm.formDataJson) {
        return JSON.parse(currentForm.formDataJson).formData;
      }
      return currentForm.formDefinition
        ? JSON.parse(currentForm.formDefinition.formDefinitionJson)
        : {};
    } catch (error) {
      Logger.error(error);
      return {};
    }
  }, [currentForm]);

  useEffect(() => {
    refetchJobTasks();
  }, [fetchJobTask]);

  return (
    <ErrorBoundaries>
      <Box>
        <Box display="flex" justifyContent="flex-end" marginBottom={1}>
          <ThemeProvider>
            <Box alignItems="center" display="flex" flexDirection="row">
              <UserPermission action={PermissionConstants.OBJECT_TASK} I="create">
                <Button
                  disabled={!isActive}
                  size="small"
                  type={ButtonType.SECONDARY}
                  onClick={() => {
                    setShowAddEditTasksModal(true);
                    setMode(Mode.ADD);
                  }}
                >
                  Add Task(s)
                </Button>
              </UserPermission>
            </Box>
          </ThemeProvider>
        </Box>
        <Box height={500}>
          <XGrid
            columns={columns}
            disableToolbar
            entityTypeName="Tasks"
            pagination
            query={getTasksByJobId}
            queryVariables={{
              jobId
            }}
            refetchTrigger={fetchJobTask}
            rowHeight={40}
            rowsPerPageOptions={[5, 10, 20]}
            useItemsCountAsRowCount
          />
        </Box>
        <Box height={300}>
          <AddEditTasksToJobModal
            formatAssetOption={formatAssetOption}
            jobId={jobId}
            key={`AddToJob${fetchJobTask}`}
            mode={mode}
            open={showAddEditTasksModal}
            partitionKey={tenantId}
            propertyId={customerPropertyId}
            refetchTasks={() => toggleFetchJobTask(prevState => !prevState)}
            task={task}
            tasks={tasks}
            onClose={() => setShowAddEditTasksModal(false)} // to reset the form
          />
          <SergeantModal
            confirmRemoveItemLabel={openSGModal.deleteItemLabel}
            confirmRemoveStatement={
              listType === TaskListType.READY_FOR_JOB
                ? 'This delete will permanently delete the task from the job'
                : undefined
            }
            data={openSGModal.data}
            dataType={openSGModal.dataType}
            handleClose={() => setSGModal(defaultSGModalState)}
            handlePrimaryAction={openSGModal.handleAction}
            key={openSGModal.open}
            layout={openSGModal.layout}
            mode={openSGModal.mode}
            open={openSGModal.open}
            renderContent={openSGModal.renderContent}
            title={openSGModal.title}
          />
          <ViewPartsAndMaterials
            data={viewPartsAndMaterials.partsAndMaterials}
            disableQtyInput
            isVisible={viewPartsAndMaterials.open}
            onModalClose={() => setViewPartsAndMaterials(defaultPartsAndMaterial)}
          />
          <ThemeProvider>
            <Modal
              actions={
                <Button fullWidth onClick={() => setFormsModalOpen(false)}>
                  Close
                </Button>
              }
              open={formsModalOpen}
              PaperProps={{ style: { minWidth: 400 } }}
              title="Task Forms"
              onClose={() => setFormsModalOpen(false)}
            >
              {forms.map((f, i) => (
                <div
                  css={{ ...styles.link, marginTop: i === 0 ? 0 : 8 }}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setFormViewerModalOpen(true);
                    setFormsModalOpen(false);
                    setCurrentForm(f);
                  }}
                  onKeyDown={noop}
                >
                  <Typography>{f.form.name}</Typography>
                </div>
              ))}
            </Modal>
          </ThemeProvider>
          <OldModal handleClose={() => setFormViewerModalOpen(false)} open={formViewerModalOpen}>
            <FormViewer
              description={currentForm.form?.description}
              formMetaData={formMetaData}
              handleClose={() => setFormViewerModalOpen(false)}
              isEditMode={false}
              name={currentForm.form?.name}
            />
          </OldModal>
        </Box>
      </Box>
    </ErrorBoundaries>
  );
};

export default TaskTableOverflow;
