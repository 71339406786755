import React, { useMemo } from 'react';

import {
  Field,
  FieldType,
  InlineAlert,
  InlineAlertTypes,
  TimeInput,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { difference, noop } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { StatusChip } from 'components';
import DefaultButton from 'components/Buttons/DefaultButton';
import Routes from 'scenes/Routes';
import {
  useDismissTimesheetEntryBinders,
  useUndismissTimesheetEntryBinders
} from 'scenes/Timesheets/customHooks';
import { Logger } from 'services/Logger';
import { getTenantSettingValueForKey, isTenantSettingEnabled } from 'utils';
import { JobTypes, TimeCardStatusTypes } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';

import { eventTypes } from '../../constants';

import EventTransitionLedger from './EventTransitionLedger';
import VisitTimeHistory from './VisitTimeHistory/index';

const styles = {
  eventIdentifiers: {
    display: 'flex',
    width: '100%',
    marginBottom: 24,
    marginTop: 24
  },
  eventHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  eventLink: {
    fontSize: 16,
    marginRight: '1em'
  },
  disabledLink: {
    pointerEvents: 'none',
    textDecoration: 'none'
  },
  statusChip: {
    borderRadius: '0.25em'
  },
  dismissButton: {
    marginLeft: 'auto'
  },
  header: {
    marginTop: '2em',
    display: 'flex',
    flexDirection: 'row'
  },
  unsubmittedWarning: {
    marginTop: '1em',
    marginBotton: '1em'
  }
};

const EventIdentifierColumns = [
  {
    key: 'customer',
    label: 'customer'
  },
  {
    key: 'property',
    label: 'property'
  },
  {
    key: 'phase',
    label: 'phase'
  },
  {
    key: 'department',
    label: 'department'
  },
  {
    key: 'costCode',
    label: 'cost code'
  },
  {
    key: 'scheduledTime',
    label: 'scheduled time'
  },
  {
    key: 'jobStartTime',
    label: 'start time'
  },
  {
    key: 'jobEndTime',
    label: 'end time'
  }
];

const getJobRoute = event => {
  return event.jobType === JobTypes.MAINTENANCE
    ? Routes.maintenance({ mode: 'view', id: event.jobNumber })
    : Routes.job({ mode: 'view', id: event.jobNumber });
};

const getEventLinkInfo = event => {
  switch (event.type) {
    case eventTypes.MAN_DAY:
      return event.dailyReportNumber && event.dailyReportId
        ? {
            route: Routes.projectDailyReportDetailView({
              mode: 'view',
              projectId: event.projectId,
              id: event.dailyReportId
            }),
            label: `Project ${event.projectNumber}, ${event.project}, Daily Report ${event.dailyReportNumber}`
          }
        : {
            route: Routes.projectDetails({
              mode: 'view',
              projectId: event.projectId,
              page: 'dashboard'
            }),
            label: `Project ${event.projectNumber}, ${event.project}`
          };
    case eventTypes.VISIT:
      return {
        route: getJobRoute(event),
        label: `Job ${event.job}, Visit ${event.visit}`
      };
    case eventTypes.NON_VISIT_EVENT:
      return event.jobNumber && event.visit
        ? {
            route: getJobRoute(event),
            label: `${event.name} - Job ${event.job}, Visit ${event.visit}`
          }
        : {
            route: '', // no link for non-billable events
            label: event.name
          };
    default:
      Logger.error(`Attempting to parse invalid timesheet event ${JSON.stringify(event)}`);
      return {
        route: '',
        label: 'Event'
      };
  }
};

const WorkEventDetails = ({
  workEvent,
  setDismissedBinderMap,
  isDismissed,
  children,
  payrollSetting,
  tab,
  onUpdateDayCard = noop,
  updateTimesheetEntryBinder = noop,
  canDismiss = false,
  areBindersEnabled = false
}) => {
  const startEndTimeTimesheets = getTenantSettingValueForKey('startEndTimeTimesheets');
  const startEndTimeTimesheetsEnabled =
    startEndTimeTimesheets === 'true' || startEndTimeTimesheets === true;
  const { id, identifier, status, binderStatus, eventTransitionLedger, timestamps } = workEvent;

  const showDepartmentForNonBillableEvents = isTenantSettingEnabled(
    'showDepartmentForNonBillableEvents'
  );

  const [
    dismissTimesheetEntryBinders,
    { loading: dismissLoading }
  ] = useDismissTimesheetEntryBinders();

  const [
    undismissTimesheetEntryBinders,
    { loading: undismissLoading }
  ] = useUndismissTimesheetEntryBinders();

  const isNonVisitEventDepartmentLabel = ({ label, event }) =>
    showDepartmentForNonBillableEvents &&
    label === 'department' &&
    event.type === eventTypes.NON_VISIT_EVENT;

  const isStartorEndTime = ({ label }) => label === 'jobStartTime' || label === 'jobEndTime';

  const eventLinkInfo = useMemo(() => getEventLinkInfo(identifier), [identifier]);

  return (
    <div key={id}>
      <div css={styles.header}>
        <Box css={styles.eventHeader}>
          <Typography css={styles.eventLink} variant={TV.BASE} weight={TW.BOLD}>
            <Link
              css={eventLinkInfo.route ? '' : styles.disabledLink}
              rel="noopener noreferrer"
              target="_blank"
              to={eventLinkInfo.route}
            >
              {eventLinkInfo.label}
            </Link>
          </Typography>
          {status && (
            <StatusChip
              enumType={EnumType.VISIT_STATUS}
              enumValue={status}
              label={status}
              style={styles.statusChip}
              textColor="white"
            />
          )}
        </Box>
        {areBindersEnabled && !isDismissed && canDismiss && (
          <DefaultButton
            label="Dismiss"
            showSpinner={dismissLoading}
            style={styles.dismissButton}
            onClick={() => {
              dismissTimesheetEntryBinders([workEvent]).then(result => {
                if (!result) return;
                const {
                  data: { dismissTimesheetEntryBinders: dismissedBinders }
                } = result;

                const newdismissedBinderMap = dismissedBinders.map(binder => binder.id);
                setDismissedBinderMap(({ dismissed, undismissed }) => ({
                  dismissed: [...dismissed, ...newdismissedBinderMap],
                  undismissed: difference(undismissed, newdismissedBinderMap)
                }));

                onUpdateDayCard();
              });
            }}
          />
        )}
        {areBindersEnabled && isDismissed && canDismiss && (
          <DefaultButton
            label="Add To Timesheet"
            showSpinner={undismissLoading}
            style={styles.dismissButton}
            variant="outlinedSecondary"
            onClick={() => {
              undismissTimesheetEntryBinders([workEvent]).then(result => {
                if (!result) return;
                const {
                  data: { undismissTimesheetEntryBinders: undismissedBinders }
                } = result;

                const undismissedBinderMap = undismissedBinders.map(binder => binder.id);
                setDismissedBinderMap(({ dismissed, undismissed }) => ({
                  dismissed: difference(dismissed, undismissedBinderMap),
                  undismissed: [...undismissed, ...undismissedBinderMap]
                }));

                onUpdateDayCard();
              });
            }}
          />
        )}
      </div>
      <EventTransitionLedger
        eventTransitionLedger={eventTransitionLedger}
        timeZone={payrollSetting.timeZone}
      />
      <VisitTimeHistory payrollSetting={payrollSetting} status={status} timestamps={timestamps} />
      {areBindersEnabled && binderStatus === TimeCardStatusTypes.PENDING && (
        <InlineAlert
          Icon={WarningIcon}
          style={styles.unsubmittedWarning}
          type={InlineAlertTypes.YELLOW}
        >
          Entries include unsubmitted time from technicians
        </InlineAlert>
      )}
      <div css={styles.eventIdentifiers}>
        {EventIdentifierColumns.filter(
          column =>
            identifier[column.key] ||
            isNonVisitEventDepartmentLabel({ label: column.key, event: identifier }) ||
            (startEndTimeTimesheetsEnabled &&
              isStartorEndTime({ label: column.key }) &&
              areBindersEnabled)
        ).map(column =>
          isStartorEndTime({ label: column.key }) ? (
            <TimeInput
              disabled={tab !== 'toReview'}
              innerStyle={{ fontWeight: 700 }}
              label={column.label}
              style={{ marginRight: 24, width: '100%', maxWidth: 250 }}
              type={FieldType.DATE}
              value={identifier[column.key] || '-'}
              onBlur={e => {
                const [hours, minutes] = e.target.value.split(':');
                if (hours && minutes) {
                  const newValue = moment
                    .unix(workEvent.startDayCompanyTZ)
                    .set({ hours, minutes })
                    .valueOf();
                  updateTimesheetEntryBinder({
                    id: workEvent.binderId,
                    [column.key]: newValue
                  });
                }
              }}
            />
          ) : (
            <Field
              innerStyle={{ fontWeight: 700 }}
              key={`${column.key}_${id}`}
              label={column.label}
              style={{ marginRight: 24, width: '100%', maxWidth: 100 }}
              value={identifier[column.key] || '-'}
            />
          )
        )}
      </div>
      {children}
    </div>
  );
};

export default WorkEventDetails;
