import React, { useEffect, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import AddRecordButton from 'components/Buttons/BlueText';
import DefaultButton from 'components/Buttons/DefaultButton';
import FormPDF from 'components/FormSection/FormPDF';
import FormSelector from 'components/FormSection/FormSelector';
import FormViewer from 'components/FormSection/FormViewer';
import ResponsiveTable from 'components/ResponsiveTable';
import SergeantModal from 'components/SergeantModal';
import formSelectLayout from 'meta/ProjectManagement/FieldReport/formModalForm';
import formRowsMeta from 'meta/ProjectManagement/FieldReport/formTable';
import formTableRowActionButton from 'meta/ProjectManagement/FieldReport/formTableRowActionButton';
import { formDataChange, formDataDelete, getFormDataByProject } from 'services/API/formData';

const useStyles = makeStyles(() => ({
  tableContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px'
  },
  gridItem: {
    marginLeft: 30
  }
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const FormList = props => {
  const classes = useStyles();
  const { projectId } = props;
  const [forms, setForms] = useState([]);
  const [currentEditForm, setCurrentEditForm] = useState(null);
  const [editFormModal, setEditModal] = useState(false);
  const [addFormModal, setFormModal] = useState(false);
  const [pdfViewModal, setPdfViewModal] = useState(false);

  const update = () => {
    getFormDataByProject(projectId).then(data => {
      setForms(data);
    });
  };

  useEffect(() => {
    update();
  }, [projectId]);

  const [confirmDelete, setConfirmDelete] = useState({
    confirmAction: () => {},
    confirmDialog: false,
    confirmTitle: ''
  });

  const handleCancelConfirmation = () =>
    setConfirmDelete({ confirmAction: () => {}, confirmDialog: false, confirmTitle: '' });

  const deleteAction = async (record, stopLoading) => {
    await formDataDelete(record.id);
    setConfirmDelete({ confirmAction: () => {}, confirmDialog: false, confirmTitle: '' });
    update();
    stopLoading();
  };

  const handleFormRowactions = async (actionType, record) => {
    if (actionType === 'remove') {
      setConfirmDelete({
        confirmAction: (data, stopLoading) => deleteAction(record, stopLoading),
        confirmDialog: true,
        confirmTitle: `Delete ${JSON.parse(record.formDataJson).name}`
      });
    } else if (actionType === 'edit') {
      setCurrentEditForm(record);
      setEditModal(true);
    } else if (actionType === 'pdf') {
      setCurrentEditForm(record);
      setPdfViewModal(true);
    }
  };

  const handleEditForm = async newItem => {
    const { name, description, id } = currentEditForm;
    const formDataJson = {
      name,
      description,
      formData: newItem
    };
    const payload = { formDataJson: JSON.stringify(formDataJson) };
    formDataChange(id, payload).then(() => {
      setEditModal(false);
      update();
    });
  };

  const customFormSelector = () => {
    return (
      <FormSelector
        callback={() => {
          setFormModal(false);
          update();
        }}
        handleCancel={() => setFormModal(false)}
        noGQL
        projectId={projectId}
      />
    );
  };

  const customFormViewer = () => {
    return (
      <FormViewer
        description={currentEditForm.Form.description}
        formMetaData={JSON.parse(currentEditForm.formDataJson).formData}
        handleClose={() => setEditModal(false)}
        isEditMode
        name={currentEditForm.Form.name}
        noGQL
        projectId={projectId}
        onSubmit={handleEditForm}
      />
    );
  };

  const pdfData = () => {
    if (!currentEditForm?.formDataJson) {
      return null;
    }

    const jsonParse = R.curryN(1, JSON.parse);
    const formDataJson = R.pipe(
      jsonParse,
      R.prop('formData'),
      R.ifElse(R.is(String), jsonParse, R.identity)
    )(currentEditForm.formDataJson);

    return {
      formDataJson
    };
  };

  return (
    <div>
      <div className={classes.tableContainer}>
        <div />
        <DefaultButton
          buttonProps={{ startIcon: <AddCircleOutlineIcon style={{ fontSize: 14 }} /> }}
          key="generateDailyReportModalButton"
          label="Add Form"
          style={{ height: 30, fontSize: 12 }}
          variant="containedPrimary"
          onClick={() => setFormModal(true)}
        />
      </div>
      {/* Modal for Add Form */}
      <SergeantModal
        customComponents={{ customFormSelector }}
        dataType="Item"
        disablePrimaryButton
        handleClose={() => setFormModal(false)}
        layout={formSelectLayout}
        maxWidth={960}
        open={addFormModal}
        title="Add Form"
      />
      {/* Modal for Edit Form */}
      <SergeantModal
        customComponents={{ customFormSelector: customFormViewer }}
        dataType="Item"
        disablePrimaryButton
        handleClose={() => setEditModal(false)}
        layout={formSelectLayout}
        maxWidth={960}
        open={editFormModal}
        title="Edit Form"
      />
      {/* Form lists */}
      <ResponsiveTable
        data={forms}
        dataType="Item"
        disableFilter
        noDataMsg="No Forms"
        rowActionButtons={formTableRowActionButton}
        rowActions={handleFormRowactions}
        rowMetadata={formRowsMeta}
      />
      {/* Modal for Delete Form */}
      <SergeantModal
        alignCloseRight
        customComponents={{}}
        customPrimaryButtonLabel="Delete"
        dataType="Item"
        handleClose={handleCancelConfirmation}
        handlePrimaryAction={confirmDelete.confirmAction}
        mode="delete"
        open={confirmDelete.confirmDialog}
        title={confirmDelete.confirmTitle}
      />
      {/* Modal for PDF View */}
      <Dialog
        fullScreen
        open={pdfViewModal}
        TransitionComponent={Transition}
        onClose={() => setPdfViewModal(false)}
      >
        <Grid alignItems="center" container direction="row" justify="space-between" spacing={2}>
          <Grid className={classes.gridItem} item>
            <Typography variant="subtitle2">{`${currentEditForm?.Form?.name} - Preview`}</Typography>
          </Grid>
          <Grid item>
            <AddRecordButton handle={() => setPdfViewModal(false)} label="CLOSE" />
          </Grid>
        </Grid>
        <FormPDF data={pdfData()} viewPdf />
      </Dialog>
    </div>
  );
};

FormList.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default FormList;
