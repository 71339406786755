import React, { useState } from 'react';

import { Button, ButtonType } from '@BuildHero/sergeant';
import { MuiThemeProvider } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';

import { EditAddressModal } from 'components/Modal/EditAddressModal';
import theme from 'themes/BuildHeroTheme';
import { capitalizeFirstLetter, formatAddress } from 'utils';

const addressFields = ['billTo', 'addressLine1', 'addressLine2', 'city', 'state', 'zipcode'];

/**
 * Button that opens an edit address modal
 * Kind of hacky to fit the expected data of the EditAddressModal used in
 * Customer and property detail pages.
 */
export default function EditAddressButton({ form, field }) {
  const [open, setOpen] = useState(false);
  // data is expected to be billingAddressLine1 instead of addressLine1 in EditAddressModal
  const data = addressFields.reduce((acc, cur) => {
    acc[`billing${capitalizeFirstLetter(cur)}`] = field.value?.[cur];
    return acc;
  }, {});

  const handleComplete = newData => {
    const newAddress = addressFields.reduce((acc, cur) => {
      acc[cur] = newData[`billing${capitalizeFirstLetter(cur)}`];
      return acc;
    }, {});
    form.setFieldValue(field.name, {
      ...field.value,
      ...newAddress,
      __typename: undefined
    });
    form.setFieldValue(`${field.name}String`, formatAddress(newAddress));
  };

  return (
    <>
      <Button
        style={{ padding: 0, height: 'min-content', marginLeft: 4 }}
        testingid="editAddressButton"
        type={ButtonType.LEADING}
        onClick={() => setOpen(true)}
      >
        <EditIcon />
      </Button>
      <MuiThemeProvider theme={theme}>
        <EditAddressModal
          data={data}
          dataType="Billing Address"
          editAddressType="billing"
          open={open}
          taxOptions={[]}
          onClose={() => setOpen(false)}
          onComplete={handleComplete}
        />
      </MuiThemeProvider>
    </>
  );
}
