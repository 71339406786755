import React from 'react';

import { Button, MUIForm, MUIFormComponentNames, ThemeProvider } from '@BuildHero/sergeant';
import { useTheme } from '@material-ui/core';
import { arrayOf, func, object, objectOf, oneOf, shape, string } from 'prop-types';

import PopOverButton, { PopOverButtonTypes } from 'components/PopOverButton';

import SavedFilter from '../SavedFilter';

import { FilterSwitch, Names } from './components';
import { useStyles } from './FilterButton.styles';

const getOptions = items => {
  return (
    items
      ?.filter(item => item?.name && item?.id)
      .map(item => ({ label: item.name, value: item.id })) || []
  );
};

const getNumberOfAppliedFilters = ({ filterState }) => {
  return Object.values(filterState).reduce((result, values) => {
    if (values?.length) {
      return result + 1;
    }
    return result;
  }, 0);
};

const getButtonText = ({ numberOfAppliedFilters, buttonText }) => {
  if (numberOfAppliedFilters === 1) {
    return '1 Filter Applied';
  }
  if (numberOfAppliedFilters >= 1) {
    return `${numberOfAppliedFilters} Filters Applied`;
  }
  return buttonText;
};

export const FilterTypes = {
  multiSelect: 'multiSelect',
  switch: 'switch'
};

const useFilterConfig = ({ filterOptions, filterText }) => {
  const theme = useTheme();
  const options = {
    marginBottom: theme.spacing(3)
  };

  return {
    fields: {},
    layouts: {
      default: {
        contents: Object.entries(filterOptions).map(([source, { data, type }]) => {
          switch (type) {
            case FilterTypes.switch: {
              return {
                options,
                contents: [
                  {
                    component: Names.FilterSwitch,
                    source,
                    options: {
                      label: filterText[source]?.label
                    }
                  }
                ]
              };
            }

            default:
            case FilterTypes.multiSelect: {
              return {
                options,
                contents: [
                  {
                    component: MUIFormComponentNames.SelectInput,
                    source,
                    options: {
                      label: filterText[source]?.label,
                      placeholder: filterText[source]?.placeholder,
                      isMultipleSelection: true,
                      isSearchable: true,
                      valueSet: getOptions(data)
                    }
                  }
                ]
              };
            }
          }
        })
      }
    }
  };
};

const FilterButton = ({
  buttonText,
  buttonType,
  filterState,
  filterOptions,
  filterText,
  onFilterChange,
  onFiltersClear,
  classes: propClasses
}) => {
  const classes = useStyles();
  const configuration = useFilterConfig({ filterOptions, filterText });
  const numberOfAppliedFilters = getNumberOfAppliedFilters({ filterState });
  const text = getButtonText({ numberOfAppliedFilters, buttonText });

  let buttonClass;
  if (buttonType === PopOverButtonTypes.select) {
    buttonClass = numberOfAppliedFilters ? classes.withFilters : classes.withoutFilters;
  }
  return (
    <PopOverButton
      buttonText={text}
      classes={{
        ...propClasses,
        button: buttonClass
      }}
      type={buttonType}
    >
      <ThemeProvider>
        <SavedFilter handleFilterChange={onFilterChange} handleFiltersClear={onFiltersClear} />
      </ThemeProvider>
      <MUIForm
        configuration={configuration}
        customComponents={{ FilterSwitch }}
        data={filterState}
        onFieldChange={onFilterChange}
      />
      <ThemeProvider>
        <Button className={classes.button} type="tertiary" onClick={onFiltersClear}>
          Clear all
        </Button>
      </ThemeProvider>
    </PopOverButton>
  );
};

FilterButton.propTypes = {
  buttonText: string,
  // eslint-disable-next-line react/require-default-props
  buttonType: oneOf(Object.values(PopOverButtonTypes)),
  classes: object,
  filterState: objectOf(arrayOf(string)),
  filterOptions: objectOf(
    shape({
      type: oneOf(Object.values(FilterTypes)),
      data: arrayOf(
        shape({
          id: string,
          name: string
        })
      )
    })
  ),
  filterText: objectOf(
    shape({
      label: string,
      placeholder: string
    })
  ),
  onFilterChange: func.isRequired,
  onFiltersClear: func.isRequired
};

FilterButton.defaultProps = {
  buttonText: 'Filter By...',
  buttonType: PopOverButtonTypes.select,
  classes: {},
  filterState: {},
  filterOptions: {},
  filterText: {}
};

export { PopOverButtonTypes };

export default FilterButton;
