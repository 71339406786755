import { useCallback } from 'react';

import gql from 'graphql-tag';
import { useSelector } from 'react-redux';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { PROPERTY_CHECKLISTS } from '../index';

import { getTaskTemplateCheckLists, TaskTemplateFragment } from './useChecklistLibrary';

const CREATE_TASK_TEMPLATE = gql`
  mutation createTaskTemplateChecklist($input: TaskTemplateChecklistCreateInput!) {
    createTaskTemplateChecklist(input: $input) {
      taskTemplateChecklist {
        id
        name
        isDefault
        assetTypeId
        taskTemplates {
          ${TaskTemplateFragment}
        }
      }
      updatedTaskTemplateChecklistIsDefault {
        id
        isDefault
      }
    }
  }
`;

export const useCreateTaskTemplateChecklist = (options = {}) => {
  const user = useSelector(state => state.user);

  const update = useCallback(
    (cache, { data: { createTaskTemplateChecklist } }) => {
      const variables = {
        tenantId: user.tenantId,
        assetTypeId: createTaskTemplateChecklist.taskTemplateChecklist.assetTypeId || 'null'
      };

      const cachedData = cache.readQuery({
        query: getTaskTemplateCheckLists,
        variables
      });

      const existingData = cachedData?.taskTemplateChecklists || [];
      const updatedData = {
        taskTemplateChecklists: [...existingData, createTaskTemplateChecklist.taskTemplateChecklist]
      };
      cache.writeQuery({ query: getTaskTemplateCheckLists, variables, data: updatedData });
    },
    [user.tenantId]
  );

  return useExtendedMutation(CREATE_TASK_TEMPLATE, {
    serializer: ({ name, assetTypeId, isDefault = false }) => ({
      variables: {
        input: {
          tenantId: user.tenantId,
          name,
          isDefault,
          assetTypeId: assetTypeId === PROPERTY_CHECKLISTS.id ? undefined : assetTypeId
        }
      }
    }),
    update,
    onSuccess: (transformedData, snackbarOn) => snackbarOn('success', 'Checklist added'),
    onError: (transformedData, snackbarOn) => snackbarOn('error', 'Unable to Add Checklist'),
    ...options
  });
};
