import gql from 'graphql-tag';
import { omit } from 'lodash';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { logErrorWithCallback } from 'utils';

const BULK_UPDATE_PAYROLL_HOUR_TYPES = gql`
  mutation bulkUpdatePayrollHourTypes($data: AddPayrollHourTypesToCompanyInput!) {
    bulkUpdatePayrollHourTypes(data: $data) {
      id
      sortOrder
      hourType
      hourTypeAbbreviation
      billingHourTypeId
      hourTypeTag
      version
      isAutofill
      intacctAccountNumber
      intacctNonBillableAccountNumber
    }
  }
`;

const serializer = ({ companyId, payrollHourTypes }) => ({
  variables: {
    data: {
      companyId,
      payrollHourTypes: payrollHourTypes.map(group =>
        omit(group, ['__typename', 'unsaved', 'version'])
      )
    }
  }
});

const update = (cache, { data: { bulkUpdatePayrollHourTypes } }, ref) => {
  cache.modify({
    id: `Company:${ref.companyId}`,
    fields: {
      payrollHourTypes() {
        return bulkUpdatePayrollHourTypes.map(payrollHourType => {
          return {
            __ref: `${payrollHourType.__typename}:${payrollHourType.id}`
          };
        });
      }
    }
  });
};

export const useBulkUpdatePayrollHourTypes = () => {
  return useExtendedMutation(BULK_UPDATE_PAYROLL_HOUR_TYPES, {
    serializer,
    update,
    onSuccess: (data, snackbarOn) =>
      snackbarOn('success', 'Successfully updated payroll hour types'),
    onError: (err, snackbarOn) =>
      logErrorWithCallback(err, snackbarOn, 'Unable to update payroll hour types')
  });
};
