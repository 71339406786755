import React from 'react';

import { Button, Grid, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // console.error(error);
    // console.info(info);
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        <Grid
          alignItems="center"
          container
          direction="row"
          justify="flex-start"
          style={{ marginTop: 24 }}
        >
          <Grid item>
            <ErrorIcon />
          </Grid>
          <Grid item style={{ marginLeft: 10 }}>
            <Typography variant="caption">Oops..something was off. Please refresh</Typography>
            <Button
              style={{ marginLeft: 10, color: 'black', borderColor: 'black' }}
              variant="outlined"
              onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
            >
              Report feedback
            </Button>
            <Button
              style={{ marginLeft: 10, color: 'black', borderColor: 'black' }}
              variant="outlined"
              onClick={e => {
                const textField = document.createElement('textarea');
                textField.innerText = JSON.stringify(
                  this.state.error,
                  Object.getOwnPropertyNames(this.state.error)
                );
                document.body.appendChild(textField);
                textField.select();
                document.execCommand('copy');
                textField.remove();
                e.preventDefault();
                e.target.innerHTML = 'Copied';
                e.target.disabled = true;
              }}
            >
              Copy Error
            </Button>
          </Grid>
        </Grid>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
