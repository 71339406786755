import { withDispatchActions, withQueryStringStore } from '@dispatch/Dispatch.store';
import compose from 'utils/compose';

import VisitCard from './VisitCard.component';

const mapActionsToProps = ({ openEvent }) => ({
  openEvent
});

const mapQueryStringToProps = ({ state }) => ({
  mapView: state.mapView,
  weekView: state.weekView
});

export default compose(
  withQueryStringStore(mapQueryStringToProps),
  withDispatchActions(mapActionsToProps)
)(VisitCard);
