import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { connect } from 'react-redux';

import { AddRecordButton, ConfirmModal, ResponsiveTable } from 'components';
import { customerRepRows, tenantRepRows } from 'meta/Customer/CustomerProperty';
import {
  customerPropertyCustomerRepLayout,
  CustomerRepLayout
} from 'meta/Customer/CustomerRep/layout';
import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { CustomerPropertyService, validations } from 'services/core';
import { Logger } from 'services/Logger';
import { BestContactType } from 'utils/constants';

import CustomerRep, { RepType } from '../../CustomerRepModal';
import TenantRep from '../../TenantRepModal';

/**
 * Creates customer page. new customer fields & layouts are generated from the meta file
 * labels are fetched from application level
 * locale of the user is referred from user context
 */
class Reps extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.CustomerPropertyService = new CustomerPropertyService();
    this.state = {
      openCustomerRep: false,
      openTenantRep: false,
      dataRecord: {},
      modalMode: '',
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: ''
    };
  }

  handleOpenPopUp = (popUpKey, mode, record) => {
    this.setState({ [popUpKey]: true, modalMode: mode, dataRecord: record });
  };

  handleClosePopUp = popUpKey => {
    this.setState({ [popUpKey]: false });
  };

  handleCancelConfirmation = () => {
    this.setState({
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: ''
    });
  };

  handleCustomerRepRowActions = (mode, record) => {
    if (mode === 'delete') {
      this.setState({
        confirmDialog: true,
        confirmAction: async () => this.deleteCustomerRep(record.parent, record.name),
        confirmMessage: 'customer representative'
      });
    } else {
      this.handleOpenPopUp('openCustomerRep', mode, record);
    }
  };

  deleteCustomerRep = async (record, name) => {
    try {
      const { partitionKey, sortKey, invertedSortKey } = record;
      const { data } = await this.CustomerPropertyService.deleteCustomerRep(
        partitionKey,
        sortKey,
        invertedSortKey
      );
      if (data) {
        await this.props.refreshCustomerRepsData(this.props.customerProperty.sortKey);
        this.setState({
          confirmDialog: false,
          confirmAction: '',
          confirmMessage: ''
        });
        this.props.snackbarOn('success', `Successfully deleted representative: ${name}`);
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn(
        'error',
        'Unable to delete representative, please try again later',
        error
      );
      /* if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        this.props.snackbarOn('error', error.graphQLErrors[0].message);
      } else {
        this.props.snackbarOn('error', 'Unable to delete representative, please try again later');
      } */
      this.setState({
        confirmDialog: false,
        confirmAction: '',
        confirmMessage: ''
      });
    }
  };

  handleTenantRepRowActions = (mode, record) => {
    if (mode === 'delete') {
      this.setState({
        confirmDialog: true,
        confirmAction: async () => this.deleteTenantRep(record),
        confirmMessage: 'representative'
      });
    } else {
      this.handleOpenPopUp('openTenantRep', mode, record);
    }
  };

  deleteTenantRep = async record => {
    const { partitionKey, sortKey, invertedSortKey } = record;
    try {
      const { data } = await this.CustomerPropertyService.deleteTenantRep(
        partitionKey,
        sortKey,
        invertedSortKey
      );
      if (data) {
        await this.props.refreshTenantRepsData(this.props.customerProperty.sortKey);
        this.setState({
          confirmDialog: false,
          confirmAction: '',
          confirmMessage: ''
        });
        this.props.snackbarOn(
          'success',
          `Successfully deleted our representative: ${record.mappedEntity.name}`
        );
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn(
        'error',
        'Unable to delete our representative, please try again later',
        error
      );
      /* if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        this.props.snackbarOn('error', error.graphQLErrors[0].message);
      } else {
        this.props.snackbarOn(
          'error',
          'Unable to delete our representative, please try again later'
        );
      } */
      this.setState({
        confirmDialog: false,
        confirmAction: '',
        confirmMessage: ''
      });
    }
  };

  render() {
    const {
      customerProperty,
      customerRepsDataForCustomer,
      customerRepsData,
      tenantRepsData,
      refreshCustomerRepsData,
      refreshTenantRepsData,
      isActive
    } = this.props;

    return (
      <ErrorBoundaries>
        <Grid item style={{ paddingBottom: 22 }}>
          <Typography style={{ color: '#3f3f3f' }} variant="body2">
            {Labels['customer representative'][this.props.user.locale]}
          </Typography>
        </Grid>
        <Grid item>
          {isActive && (
            <AddRecordButton
              handle={() =>
                this.handleOpenPopUp('openCustomerRep', 'new', {
                  bestContact: BestContactType.CELLPHONE
                })
              }
              icon={AddIcon}
              label="Add property representative"
            />
          )}

          <ErrorBoundaries>
            <ResponsiveTable
              data={customerRepsData?.items}
              noDataMsg="No customer reps"
              refreshTable={() => refreshCustomerRepsData(customerProperty.sortKey)}
              rowActionButtons={{
                view: {
                  label: 'View',
                  caslAction: 'read',
                  icon: 'Launch'
                },
                edit: {
                  label: 'Edit',
                  caslAction: 'update',
                  icon: 'Edit'
                },
                delete: {
                  label: 'Delete',
                  caslAction: 'delete',
                  icon: 'Delete'
                }
              }}
              rowActions={this.handleCustomerRepRowActions}
              rowMetadata={customerRepRows}
            />
          </ErrorBoundaries>
        </Grid>
        <Grid item style={{ paddingTop: 40, paddingBottom: 22 }}>
          <Typography style={{ color: '#3f3f3f' }} variant="body2">
            {Labels['representative on our side'][this.props.user.locale]}
          </Typography>
        </Grid>
        <Grid item>
          {isActive && (
            <AddRecordButton
              handle={() => this.handleOpenPopUp('openTenantRep', 'new', {})}
              icon={AddIcon}
              label="Add representative on our side just for this property"
            />
          )}

          <ErrorBoundaries>
            <ResponsiveTable
              data={tenantRepsData}
              noDataMsg="No reps on our side"
              refreshTable={() => refreshTenantRepsData(customerProperty.sortKey)}
              rowActionButtons={{
                edit: {
                  label: 'Edit',
                  caslAction: 'update',
                  icon: 'Edit'
                },
                delete: {
                  label: 'Delete',
                  caslAction: 'delete',
                  icon: 'Delete'
                }
              }}
              rowActions={this.handleTenantRepRowActions}
              rowMetadata={tenantRepRows}
            />
          </ErrorBoundaries>
        </Grid>

        {this.state.openCustomerRep && (
          <CustomerRep
            data={this.state.dataRecord}
            entityName="CustomerRep"
            existingReps={(customerRepsDataForCustomer || {}).items || []}
            handleClose={flag => {
              this.handleClosePopUp('openCustomerRep');
              if (flag) {
                // for refreshing the page
                refreshCustomerRepsData(customerProperty.sortKey);
              }
            }}
            layout={
              this.state.modalMode !== 'new' ? CustomerRepLayout : customerPropertyCustomerRepLayout
            }
            mode={this.state.modalMode}
            open={this.state.openCustomerRep}
            parent={{
              customerPropertyId: customerProperty.id,
              customerId: customerProperty?.parentEntity?.id,
              tenantId: this.props.user.tenantId
            }}
            repType={RepType.PROPERTY}
            validationSchema={validations.customerPropertyCustomerRepSchema}
          />
        )}

        {this.state.openTenantRep && (
          <TenantRep
            data={this.state.dataRecord}
            handleClose={flag => {
              this.handleClosePopUp('openTenantRep');
              if (flag) {
                // for refreshing the page
                refreshTenantRepsData(customerProperty.sortKey);
              }
            }}
            mode={this.state.modalMode}
            mounted={this.mounted}
            open={this.state.openTenantRep}
            parent={customerProperty}
          />
        )}

        <ConfirmModal
          cancel={this.handleCancelConfirmation}
          confirm={this.state.confirmAction}
          message={this.state.confirmMessage}
          open={this.state.confirmDialog}
        />
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const connectedReps = connect(mapStateToProps, mapDispatcherToProps)(Reps);

export default connectedReps;
