import React, { useState } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { bool, func } from 'prop-types';

import { useParams } from 'react-router-dom';

import { DefaultButton } from 'components';

import SergeantModal from 'components/SergeantModal';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { uploadToPmFiles } from './AddToFiles.hooks';
import { addFilesLayout } from './AddToFiles.layout';

import FileManagerFormComponentWrapper from './components/FileManagerFormComponentWrapper';

const AddToFiles = ({ getAttachmentFile, disabled, showSpinner }) => {
  const isFileSystemEnabled = useFlags()[FeatureFlags.PM_FILE_SYSTEM];

  const { projectId } = useParams();
  const [showFileManager, setShowFileManager] = useState(false);
  const [currentFolderData, setCurrentFolderData] = useState({
    id: projectId,
    path: `${projectId}/`
  });

  const handleSelectFolder = ({ path, id }) => {
    setCurrentFolderData({ path, id });
  };

  const uploadAttachmentsToSelectedFolder = async (_, stopLoading) => {
    try {
      const file = await getAttachmentFile();
      await uploadToPmFiles({
        file,
        projectId,
        folderPath: currentFolderData.path,
        folderId: currentFolderData.id
      });
    } finally {
      stopLoading();
      setShowFileManager(false);
    }
  };

  if (!isFileSystemEnabled) {
    return null;
  }
  return (
    <>
      <DefaultButton
        disabled={disabled}
        key="addToFiles"
        label="Add to Files"
        showSpinner={showSpinner}
        style={{ marginRight: 8 }}
        variant="outlined"
        onClick={() => {
          setShowFileManager(true);
        }}
      />
      <SergeantModal
        alignCloseRight
        customComponents={{
          FilesManagerComponent: FileManagerFormComponentWrapper
        }}
        customPrimaryButtonLabel="Add files here"
        dataType="Select Files"
        handleClose={() => {
          setShowFileManager(false);
        }}
        handlePrimaryAction={uploadAttachmentsToSelectedFolder}
        layout={addFilesLayout({ onSelectFolder: handleSelectFolder })}
        open={showFileManager}
        title="Select where you want to add your files"
      />
    </>
  );
};

AddToFiles.propTypes = {
  getAttachmentFile: func.isRequired,
  disabled: bool,
  showSpinner: bool
};

AddToFiles.defaultProps = {
  disabled: false,
  showSpinner: false
};

export default AddToFiles;
