import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, ButtonType, Input, Modal, TV, TW, Typography } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';
import { Box, Grid, IconButton, useTheme } from '@material-ui/core';

import Tooltip from '@material-ui/core/Tooltip';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import MobileOffIcon from '@material-ui/icons/MobileOff';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import DragIndicator from 'assets/Icons/Handle.svg';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

const draggableTagStyle = {
  iconWrapper: {
    padding: '0px',
    borderRadius: '0px',
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    flexDirection: 'row'
  },
  icon: {
    height: '48px'
  },
  rowContainer: {
    height: '48px'
  }
};

const useOrderNumberStyle = () => {
  const { palette } = useTheme();
  return {
    color: palette.primary.main,
    display: 'inline-block',
    lineHeight: '20px',
    marginLeft: '20px',
    width: '40px'
  };
};

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  opacity: isDragging ? 0.5 : 1
});

const TagsWithOptionToHideOnMobile = ['jobTags'];

export const DndTags = ({ form, name, move, remove, replace, props, push }) => {
  const data = useMemo(() => get(form.values, name), [form, name]);

  const { fieldName, fieldKey, displayName, onDelete, setChanged } = props;
  const handleDragEnd = useCallback(
    ({ source, destination }) => {
      if (destination && destination.index !== source.index) {
        move(source.index, destination.index);
        setChanged(fieldName);
      }
    },
    [move, setChanged, fieldName]
  );

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="1">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {data.map((item, index) => (
                <Draggable
                  draggableId={`draggableTag-${item.id}${index}`}
                  index={index}
                  key={`${item.id}-${index}`}
                >
                  {(provided, snapshot) => (
                    <Box
                      boxShadow={2}
                      m={1}
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <DndTag
                        displayName={displayName}
                        fieldKey={fieldKey}
                        fieldName={fieldName}
                        index={index}
                        item={item}
                        key={`${item.id}`}
                        provided={provided}
                        remove={remove}
                        replace={replace}
                        setChanged={setChanged}
                        onDelete={onDelete}
                      />
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Box marginTop={1}>
          <Button
            key={`add${displayName}Button`}
            startIcon={<AddCircleOutlineIcon />}
            type="leading"
            onClick={() => {
              push({
                [fieldKey]: '',
                sortOrder: data.length + 1,
                ...(TagsWithOptionToHideOnMobile.includes(fieldName) ? { showOnMobile: true } : {})
              });
              setChanged(fieldName);
            }}
          >
            Add {displayName}
          </Button>
        </Box>
      </DragDropContext>
    </>
  );
};

export const DndTag = ({
  provided,
  item,
  index,
  replace,
  remove,
  fieldKey,
  fieldName,
  displayName,
  onDelete,
  setChanged
}) => {
  const [value, setValue] = useState(item[fieldKey]);
  const [showOnMobileValue, setShowOnMobileValue] = useState(item.showOnMobile);
  const [confirmation, setConfirmation] = useState({ open: false, action: '', message: '' });
  const { palette } = useTheme();

  // had to reset value when order changed
  useEffect(() => {
    if (item[fieldKey] !== value) {
      setValue(item[fieldKey]);
      setShowOnMobileValue(item.showOnMobileValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item[fieldKey]]);

  const onChange = newV => {
    const newData = { ...item };
    newData[fieldKey] = newV;
    replace(index, newData);
    setChanged(fieldName);
  };

  const onChangeShowOnMobile = newV => {
    const newData = { ...item };
    newData.showOnMobile = newV;
    replace(index, newData);
    setChanged(fieldName);
  };

  const handleClose = () => {
    setConfirmation({ open: false, action: '', message: '' });
  };

  const handleDelete = () => {
    setConfirmation({
      open: true,
      action: () => {
        remove(index);
        onDelete(item, displayName);
        handleClose();
      },
      message: displayName
    });
  };

  return (
    <>
      <Grid alignItems="center" container css={draggableTagStyle.rowContainer} wrap="nowrap">
        <Grid direction="row" item>
          <div css={draggableTagStyle.iconWrapper} {...provided.dragHandleProps}>
            <img alt="dragIndicator" css={draggableTagStyle.icon} src={DragIndicator} />
          </div>
        </Grid>
        <Grid alignItems="center" item xs={1}>
          <Typography css={useOrderNumberStyle()} variant={TV.L} weight={TW.MEDIUM}>
            {index + 1}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Input
            style={{ padding: 4 }}
            value={value}
            onChange={e => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </Grid>
        {typeof item?.showOnMobile === 'boolean' && (
          <Grid direction="row-reverse" item>
            {showOnMobileValue ? (
              <Tooltip arrow title="Shown on Mobile">
                <IconButton
                  aria-label="Shown on Mobile"
                  onClick={() => {
                    setShowOnMobileValue(false);
                    onChangeShowOnMobile(false);
                  }}
                >
                  <MobileFriendlyIcon style={{ color: palette.support.green.dark }} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip arrow title="Hidden on Mobile">
                <IconButton
                  aria-label="Hidden on Mobile"
                  onClick={() => {
                    setShowOnMobileValue(true);
                    onChangeShowOnMobile(true);
                  }}
                >
                  <MobileOffIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        )}
        <Grid direction="row-reverse" item>
          <IconButton aria-label="Delete" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Modal
        actions={
          <Button fullWidth type={ButtonType.ERROR} onClick={confirmation.action}>
            Confirm
          </Button>
        }
        open={confirmation.open}
        PaperProps={{ style: { minWidth: 454 } }}
        title={`Remove ${confirmation.message}`}
        onClose={handleClose}
      >
        <Typography style={{ paddingBottom: 24 }}>
          Are you sure you want to remove <b>{item[fieldKey]}</b>?
        </Typography>
      </Modal>
    </>
  );
};

DndTags.propTypes = {
  form: PropTypes.any.isRequired, // provides Formik state and helpers
  name: PropTypes.string.isRequired, // the field name used to get data from SgtForm
  props: PropTypes.func.isRequired, // props passed from the SgtForm configuration
  // move, remove, replace, push are all <FieldArray/> helpers (https://formik.org/docs/api/fieldarray)
  move: PropTypes.func.isRequired, // move an element in an array to another index
  remove: PropTypes.func.isRequired, // remove an element at an index of an array and return it
  replace: PropTypes.func.isRequired, // replace a value at the given index into the array
  push: PropTypes.func.isRequired // add a value to the end of an array
};

DndTag.propTypes = {
  provided: PropTypes.any.isRequired, // props inherited from <Draggable/>
  item: PropTypes.string.isRequired, // a row of tag data among DndTags
  index: PropTypes.func.isRequired, // the index of the current row
  replace: PropTypes.func.isRequired, // replace a value at the given index into the array
  remove: PropTypes.func.isRequired, // remove an element at an index of an array and return it
  fieldKey: PropTypes.string.isRequired, // the key of the item object to get the tag name ("tagName")
  fieldName: PropTypes.string.isRequired, // name of the field (e.g. "customerTags")
  displayName: PropTypes.string.isRequired, // the name of the field to display (e.g. "Customer Tags")
  onDelete: PropTypes.func.isRequired, // a callback function that should be called after deletion
  setChanged: PropTypes.func.isRequired // a callback function to notify the form has changed
};
