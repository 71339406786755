import InstructionHelper from '../InstructionHelper';

export const departmentSkillSchema = props => {
  const { departmentName, skills, tenantId, tenantCompanyId } = props;
  const data = [{ ...props.parent }];

  const departmentJson = {
    //  input: {
    tagType: 'department',
    tagName: departmentName.trim(),
    tenantId,
    tenantCompanyId,
    entityType: 'Department'
  };
  if (props.id) {
    departmentJson.id = props.id;
  }
  if (props.accountingRefIdOfClass) {
    departmentJson.accountingRefIdOfClass = props.accountingRefIdOfClass;
  }
  data.push(departmentJson);
  const tempSkillArray = [];

  skills &&
    skills.forEach(skill => {
      if (skill.tagName && skill.tagName !== '' && skill.tagName.trim().length > 0) {
        const localSkill = {
          tagName: skill.tagName,
          tagType: 'skill',
          tenantId,
          tenantCompanyId,
          entityType: 'Skill'
        };
        if (skill.id) {
          localSkill.id = skill.id;
        }

        data.push(localSkill);
        tempSkillArray.push(localSkill);
        // data.push(departmentSkillMap);
      }
    });

  // data.input.skills = tempSkillArray;

  const ins = new InstructionHelper();
  let temp = ins.oneToMany(0, 1);
  tempSkillArray &&
    tempSkillArray.forEach((skill, index) => {
      temp = temp.oneToMany(0, index + 2);
    });
  tempSkillArray &&
    tempSkillArray.forEach((skill, index) => {
      temp = temp.manyToMany(1, index + 2, 'tagName', 'tagName');
    });
  const { instructionSet } = temp;

  return { data, instructionSet };
};

export default departmentSkillSchema;
