import gql from 'graphql-tag';

import { dispatchVisitFragment } from './visitFragments';

const getCompanyDispatchScheduledVisits = gql`
  query getCompanyDispatchScheduledVisits(
    $partitionKey: String!
    $sortKey: String!
    $dispatchWindowStartTime: Int!
    $dispatchWindowEndTime: Int!
  ) {
    scheduledVisits: getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      visitsInRange: visitsView(entityConnection: "Visit", 
      pagination: { limit: 10000 }
      filter: {
      	integerFilters: [{
          fieldName: "Visit.scheduledFor",
          filterInput: {between: [$dispatchWindowStartTime, $dispatchWindowEndTime]}
        }],
        stringFilters: [
          {
            fieldName: "Visit.status",
            filterInput: {
              in: ["Scheduled", "Complete", "Working", "On hold", "Traveling", "Paused", "Converted"] 
            }
          }
        ]
      }){
        items {
          ${dispatchVisitFragment}
        }
      }
    }
  }
`;

export default getCompanyDispatchScheduledVisits;
