import React from 'react';

import { TV, TW, Typography } from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';

import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import PropTypes from 'prop-types';

const useStyles = () => ({
  toggleLabel: theme => ({
    display: 'flex',
    flexDirection: 'row',
    flexBasis: 'content',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'fit-content',
    color: theme.palette.support.grey
  })
});

const ToggleLabel = ({ label, toggle, onClick }) => {
  const styles = useStyles();
  return (
    <Box css={styles.toggleLabel} onClick={onClick}>
      <Typography variant={TV.BASE} weight={TW.NORMAL}>
        {`${toggle ? 'Hide' : 'Show'} ${label}`}
      </Typography>
      {toggle ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
    </Box>
  );
};

export default ToggleLabel;

ToggleLabel.propTypes = {
  label: PropTypes.string.isRequired,
  toggle: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};
