export const eventToFormData = eventData => {
  const { from, to, eventName, employeeId, description, departmentId, payrollHourTypeId, status } =
    eventData || {};
  return {
    departmentId,
    description,
    employeeId,
    eventName,
    payrollHourTypeId,
    startDateTime: from,
    status,
    durationInMin: (to - from) / 60,
    excludeWeekends: true
  };
};

export const formToEventData = (formData, isRecurringEvent) => {
  const {
    departmentId,
    description,
    durationInMin,
    employeeId,
    endDate,
    eventName,
    excludeWeekends,
    payrollHourTypeId,
    startDateTime,
    status
  } = formData;

  if (isRecurringEvent) {
    return {
      departmentId,
      description,
      durationInMin,
      endDate,
      excludeWeekends,
      name: eventName,
      payrollHourTypeId,
      startDateTime
    };
  }

  return {
    departmentId,
    description,
    employeeId,
    eventName,
    payrollHourTypeId,
    status,
    from: startDateTime,
    to: startDateTime + durationInMin * 60
  };
};

export const validateScheduling = values => {
  let validationErrors;

  if (!values.durationInMin) {
    validationErrors = {
      durationInMin: 'Event duration must be greater than 0'
    };
  }

  if (values.endDate < values.startDateTime) {
    validationErrors = {
      ...(validationErrors || {}),
      endDate: 'The end of the event must be after its start'
    };
  }

  return validationErrors;
};

export const getFormValidation = multipleEventsCreationEnabled => ({
  type: 'object',
  properties: {
    eventName: {
      type: 'string'
    },
    startDateTime: {
      type: 'string'
    },
    endDate: {
      type: 'string'
    },
    durationInMin: {
      type: 'number'
    }
  },
  required: multipleEventsCreationEnabled
    ? ['eventName', 'startDateTime', 'endDate', 'durationInMin']
    : ['eventName', 'startDateTime', 'durationInMin']
});

export const getFormValidationErrors = () => ({
  eventName: {
    required: 'Event Name is required',
    type: 'Value must be a string'
  },
  startDateTime: {
    required: 'Start date and time is required',
    type: 'Value must be a string'
  },
  endDate: {
    required: 'End Date is required',
    type: 'Value must be a string'
  },
  durationInMin: {
    required: 'Duration is required',
    type: 'Value must be a number'
  }
});
