import gql from 'graphql-tag';

const getReviewReportById = gql`
  query getReviewReportById($id: String!) {
    getReviewReportById(id: $id) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      tenantId
      hierarchy
      entityType
      status
      version
      createdDateTime
      createdBy
      lastUpdatedBy
      submittedBy
      submittedDate
      messageToCustomerInvoice
      priceBookId
      isLabourRateLineItemFromTimeTracking
      includeMessageToCustomerInInvoice
      lastUpdatedDateTime
      parentEntity {
        ... on Visit {
          id
          visitNumber
          departmentName
          departmentId
          description
          accountingRefIdOfClass
          scheduledFor
          createdBy
          createdDateTime
          lastUpdatedDateTime
          submittedBy
          submittedDate
          timesheetNotes(entityConnection: "TimesheetNote") {
            items {
              id
              note
              subject
              createdBy
              lastUpdatedBy
              attachments(entityConnection: "Attachment") {
                items {
                  fileUrl
                  id
                  _deleted
                }
              }
            }
          }
          timesheetEntries: timesheetEntriesView(entityConnection: "TimesheetEntry") {
            items {
              id
              reviewStatus
              approvalStatus
              reviewedDate
              reviewedBy
              actualStartTimeUTC
              actualStartTimeOverrideUTC
              actualEndTimeUTC
              actualEndTimeOverrideUTC
              actualTotalDuration
              actualTotalDurationOverride
              lastUpdatedBy
              lastUpdatedDateTime
              createdBy
              cost
              hourTypeId
              hourType {
                id
                hourType
                hourTypeAbbreviation
              }
              timesheetEntryBinder {
                id
                isDismissed
              }
              userActionType
              billableEntityId
              entryType
              manualStatus
              manualApprovedBy
              manualApprovedDate
              startLatitude
              startLongitude
              endLatitude
              endLongitude
              timesheetPeriodId
              timesheetPeriod(entityConnection: "TimesheetPeriod") {
                parentId
                approvedByName
                approvedDateUTC
              }
              timekeepingLedger(entityConnection: "TimekeepingLedger") {
                id
                employeeId
                employeeName
                billableEntityType
                eventType(entityConnection: "EventType") {
                  id
                  name
                  isBillable
                }
                eventEntity(entityConnection: "EventEntity") {
                  ... on NonVisitEvent {
                    id
                    name
                    timesheetNotes(entityConnection: "TimesheetNote") {
                      items {
                        id
                        note
                        subject
                        createdBy
                        attachments(entityConnection: "Attachment") {
                          items {
                            fileUrl
                            id
                            _deleted
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          visitAssets(entityConnection: "VisitAsset", filter: { propertyAssetId: { ne: "null" } }) {
            items {
              id
              propertyAsset(entityConnection: "PropertyAsset") {
                id
                assetName
                make
                modelNumber
                serialNo
                installDate
                location
                propertyZone
                imageUrl
              }
            }
          }
          primaryTechs(entityConnection: "VisitPrimaryTech") {
            items {
              id
              mappedEntity {
                ... on Employee {
                  id
                  firstName
                  lastName
                  truckEmployeeView(entityConnection: "TruckEmployee") {
                    items {
                      id
                      parentEntity {
                        ... on Truck {
                          id
                          name
                          isActive
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          extraTechs(entityConnection: "VisitExtraTech") {
            items {
              id
              mappedEntity {
                ... on Employee {
                  id
                  firstName
                  lastName
                  truckEmployeeView(entityConnection: "TruckEmployee") {
                    items {
                      id
                      parentEntity {
                        ... on Truck {
                          id
                          name
                          isActive
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          customerSignatures(entityConnection: "CustomerSignature") {
            items {
              id
              nameOfSignee
              signatureImageUrl
              signedDateTime
              capturedBy
              includeInInvoice
              createdBy
              createdDate
              version
            }
          }
          parentEntity {
            ... on Job {
              id
              jobNumber
              jobTypeName
              jobTypeInternal
              customIdentifier
              tenantId
              issueDescription
              lastUpdatedBy
              lastUpdatedDateTime
              sortKey
              priceBookId
              totalBudgetedHours
              owner(entityConnection: "owner") {
                id
                name
                firstName
                lastName
              }
              invoices(entityConnection: "Invoice") {
                items {
                  id
                  invoiceNumber
                  isActive
                  status
                  invoiceVisits(entityConnection: "InvoiceVisit") {
                    items {
                      visit(entityConnection: "visit") {
                        visitNumber
                      }
                    }
                  }
                }
              }
              billingCustomer(entityConnection: "BillingCustomer") {
                id
                sortKey
                customerName
                companyAddresses(entityConnection: "CompanyAddress") {
                  items {
                    id
                    addressType
                    addressLine1
                    addressLine2
                    billTo
                    city
                    state
                    zipcode
                    longitude
                    latitude
                  }
                }
              }
              parentEntity {
                ... on CustomerProperty {
                  companyName
                  id
                  billTo
                  parentEntity {
                    ... on Customer {
                      customerName
                      id
                      companyAddresses(entityConnection: "CompanyAddress") {
                        items {
                          id
                          addressType
                          addressLine1
                          addressLine2
                          city
                          state
                          zipcode
                          longitude
                          latitude
                          billTo
                        }
                      }
                    }
                  }
                  companyAddresses(entityConnection: "CompanyAddress") {
                    items {
                      id
                      addressType
                      addressLine1
                      addressLine2
                      city
                      state
                      zipcode
                      longitude
                      latitude
                      addressType
                    }
                  }
                }
              }
            }
          }
          lastUpdatedBy
          lastUpdatedDateTime
          schedules(entityConnection: "Schedule") {
            items {
              status
              lastUpdatedBy
              lastUpdatedDateTime
            }
          }
          timeSheets(entityConnection: "TimeSheet") {
            items {
              id
              createdBy
              visitDate
              clockInTime
              clockOutTime
              totalDuration
              labourType
              gpsLocations(entityConnection: "GpsLocation") {
                items {
                  latitude
                  longitude
                  userAction
                }
              }
            }
          }
          timeCardLinesView(entityConnection: "TimeCardLine") {
            items {
              lastUpdatedBy
              lastUpdatedDateTime
              timeType
              timeTypeAbbreviation
              timeMinutes
              hourTypeId
              employee {
                id
                name
              }
              employeeId
              employeeName
              cost
            }
          }
          timeCardVisitsView(entityConnection: "TimeCardVisit") {
            items {
              approvedBy
              approvedDateTime
              status
              employeeId
              employeeName
              notes(entityConnection: "Note") {
                items {
                  note
                  subject
                  lastUpdatedBy
                  lastUpdatedDateTime
                }
              }
            }
          }
          nonVisitEvents {
            nextToken
            items {
              id
              name
              description
              assignedEntityType
              assignedEntityId
              plannedStartTimeUTC
              plannedEndTimeUTC
              status
              eventTypeId
              departmentId
              assignedEntityId
              employeeId
              parentId
              isActive
              entityType
              createdBy
              createdDate
              createdDateTime
              timesheetNotes {
                items {
                  id
                  note
                  subject
                  createdBy
                  lastUpdatedBy
                  attachments(entityConnection: "Attachment") {
                    items {
                      fileUrl
                      id
                      _deleted
                    }
                  }
                }
              }
              timekeepingLedgersView {
                nextToken
                items {
                  id
                  userActionType
                  employeeName
                  startLatitude
                  startLongitude
                  endLatitude
                  endLongitude
                  actualStartTimeUTC
                  actualEndTimeUTC
                  actualHours
                  actualMinutes
                  actualTotalDuration
                  actualCreatedDate
                  employeeId
                }
              }
              timesheetEntries {
                items {
                  id
                  reviewStatus
                  approvalStatus
                  actualStartTimeUTC
                  actualStartTimeOverrideUTC
                  actualEndTimeUTC
                  actualEndTimeOverrideUTC
                  actualTotalDuration
                  actualTotalDurationOverride
                  lastUpdatedBy
                  lastUpdatedDateTime
                  createdBy
                  cost
                  hourTypeId
                  hourType {
                    id
                    hourType
                    hourTypeAbbreviation
                  }
                  timesheetEntryBinder {
                    id
                    isDismissed
                  }
                  startLatitude
                  startLongitude
                  endLatitude
                  endLongitude
                  userActionType
                  billableEntityId
                  entryType
                  manualStatus
                  manualApprovedBy
                  manualApprovedDate
                  timesheetPeriod {
                    parentId
                  }
                }
              }
            }
          }
        }
      }
      purchaseOrders(entityConnection: "PurchaseOrder") {
        items {
          id
          sortKey
          vendorName
          receiptNumber
          poNumber
          dateOfPurchase
          totalAmountPreTax
          tax
          departmentName
          addedBy
          createdBy
          version
          purchaseOrderLines(entityConnection: "PurchaseOrderLine") {
            items {
              id
              sortKey
              itemName
              departmentName
              priceBookEntryId
              accountingRefIdOfClass
              vendorName
              description
              quantity
              unitCost
              unitPrice
              markup
              amount
              createdBy
              includeInInvoice
              taxable
              version
              parentId
              costCodeId
              jobCostTypeId
              revenueTypeId
            }
          }
          purchaseOrderReceipts(entityConnection: "PurchaseOrderReceipt") {
            items {
              id
              imageUrl
              fileName
              version
              parentId
            }
          }
        }
      }
      inventoryParts(entityConnection: "InventoryPart") {
        items {
          id
          sortKey
          name: itemName
          departmentName
          accountingRefIdOfClass
          description
          truck(entityConnection: "Truck") {
            value: id
            label: name
          }
          vendorName
          quantity
          unitCost
          unitPrice
          markupValue: markup
          amount
          taxable
          includeInInvoice
          version
          addedBy
          createdBy
          costCode(entityConnection: "CostCode") {
            value: id
            label: name
          }
          jobCostType(entityConnection: "jobCostType") {
            value: id
            label: name
            type
          }
          revenueType(entityConnection: "revenueType") {
            value: id
            label: name
            type
          }
          priceBookEntry(entityConnection: "PriceBookEntry") {
            id
            version
            product(entityConnection: "Product") {
              id
              name
              version
              unitOfMeasure(entityConnection: "UnitOfMeasure") {
                id
                name
              }
            }
          }
          product {
            id
            name
            version
            unitOfMeasure(entityConnection: "UnitOfMeasure") {
              id
              name
            }
          }
        }
      }
      summaries(entityConnection: "Summary") {
        items {
          id
          sortKey
          summary
          version
          includeInInvoice
          addedBy
          dateAdded
          createdBy
          createdDateTime
          lastUpdatedBy
          lastUpdatedDateTime
        }
      }
      technicianNotes(entityConnection: "TechnicianNote") {
        items {
          id
          sortKey
          createdBy
          subject
          note
          createdDate
          createdDateTime
          lastUpdatedBy
          lastUpdatedDateTime
          addedBy
          addedDateTime
        }
      }
      discounts(entityConnection: "Discount") {
        items {
          id
          sortKey
          itemName
          reasonForDiscount
          departmentName
          accountingRefIdOfClass
          taxable
          version
          amount
          includeInInvoice
        }
      }
      fees(entityConnection: "Fee") {
        items {
          id
          sortKey
          itemName
          reasonForFee
          departmentName
          accountingRefIdOfClass
          taxable
          version
          amount
          includeInInvoice
        }
      }
      attachments(entityConnection: "Attachment") {
        items {
          id
          sortKey
          customFileName
          fileName
          fileUrl
          type
          dateAdded
          description
          comment
          sortOrder
          createdBy
          createdDate
          addedBy
          addedDateTime
          version
          includeInInvoice
          addedBy
        }
      }
      customerSignatures(entityConnection: "CustomerSignature") {
        items {
          id
          nameOfSignee
          signatureImageUrl
          signedDateTime
          capturedBy
          includeInInvoice
          createdBy
          createdDate
          version
        }
      }
      labourRateLineItems(entityConnection: "LabourRateLineItem") {
        items {
          id
          employeeId
          labourTypeId
          version
          createdDate
          createdDateTime
          labourRateBillingHourLines(entityConnection: "LabourRateBillingHourLine") {
            items {
              id
              rate
              version
              hourTypeId
              totalHours
              costCodeId
              revenueTypeId
            }
          }
        }
      }
      laborLineItems(entityConnection: "LaborLineItem") {
        items {
          id
          sortKey
          itemName
          description
          taxable
          departmentName
          accountingRefIdOfClass
          billableTimeInHours
          hourlyCharge
          includeInInvoice
          version
        }
      }
      reviewReportBillItems(limit: 100000) {
        items {
          id
          billLineId
          quantity
          markup
          unitCost
          unitPrice
          createdBy
          createdDate
          description
          taxable
          costCodeId
          jobCostTypeId
          revenueTypeId
          billLine {
            billId
            jobId
            invoicedStatus
            remainingQuantity
            product {
              description
              name
            }
            bill {
              billNumber
              purchaseOrderReceipt {
                imageUrl
                receiptNumber
              }
              purchaseOrder {
                addedBy
                createdBy
                receiptNumber
                vendorName
                poNumber
                dateOfPurchase
                totalAmountPreTax
              }
            }
          }
        }
      }
    }
  }
`;

export default getReviewReportById;
