import moment from 'moment';
import Observable from 'zen-observable';

import AmplifyService from 'services/AmplifyService';

import getCompanyActiveTechsWindowed from '../../graphql/common/queries/getCompanyActiveTechsWindowed';
import getCompanyTechs from '../../graphql/common/queries/getCompanyTechs';
import getTaskNumber from '../../graphql/crm/customer-property/queries/getTaskNumber';
import updateFullVisitQuery from '../../graphql/mutations/job/updateFullVisit';
import getVisitDetails from '../../graphql/queries/getVisitDetails';
import CreateReviewReportMutation from '../../graphql/review-report/mutations/CreateReviewReport';
import getCompanyDispatchOverview from '../../graphql/scheduling/visit/queries/getCompanyDispatchOverview';
import getCompanyDispatchScheduledVisits from '../../graphql/scheduling/visit/queries/getCompanyDispatchScheduledVisits';
import visitUpdateNotification from '../../graphql/scheduling/visit/subscriptions/visitUpdateNotification';
import getHeartbeatById from '../../graphql/tracking/employee/queries/getHeartbeatById';
import techHeartbeatNotification from '../../graphql/tracking/employee/subscriptions/techHeartbeatNotification';
import CommonService from '../Common/CommonService';
import SubscriptionClient from '../helper';

export default class VisitService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
    this.CommonService = new CommonService();
    this.subscriptionClient = SubscriptionClient.getClient(AmplifyService.config);
  }

  getVisitDetails = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getVisitDetails, params);
    return response;
  };

  createReviewReport = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(CreateReviewReportMutation, data);
    return response;
  };

  mutateVisit = async visit =>
    this.api.fullMutate(
      updateFullVisitQuery,
      {
        input: {
          id: visit.id,
          sortKey: visit.sortKey,
          version: visit.version,
          visitNumber: visit.visitNumber,
          status: visit.status,
          onHold: visit.onHold,
          onHoldReason: visit.onHoldReason || null,
          //          startTime: visit.startTime,
          //          endTime: visit.endTime,
          scheduledFor: visit.scheduledFor,
          actualDuration: visit.actualDuration,
          departmentName: visit.departmentName,
          departmentId: visit.departmentId,
          extraTechsRequired: visit.extraTechsNumber > 0,
          extraTechsNumber: visit.extraTechsNumber
          //          minimumDuration: '',
          //          tentativePeriod: '',
          //          tentativeDate: '',
          //          tentativeTime: '',
          //          onRoute: false,
          //          delayed: false,
          //          delayedReason: '',
          //          online: true,
          //          detailsSent: true,
          //          entityType: 'Visit',
        }
      },
      param => {
        const optimisticResponse = {
          // create optimistic response with (faked but expected) new version
          updateVisit: {
            ...visit,
            onHoldReason: visit.onHoldReason || null,
            // TODO: determine a standard way of doing this..
            //  changing the type on the optimistic response *should* not affect
            //  anything on the front but not 100% on that..
            //
            // nb. it doesn't seem to be possible to add new keys to the items
            // already in the cache, eg. we can't do something like optimistic: true
            // here.
            entityType: 'OptimisticVisit',
            version: visit.version + 1,
            __typename: 'Visit'
          }
        };
        return optimisticResponse;
      },
      (cache, update) => {
        // we need to update both scheduled and open visits cache..
        // TODO: we should probably do this on the client, but then we would somehow still
        // need to figure out what query/queries to update.. or do we?
        // TODO: we should also (somehow) figure out where the visit was scheduled before to
        // update that query too (eg. remove it from there)..
        const {
          data: { updateVisit }
        } = update;
        this.updateCompanyDispatchScheduledVisitsCache(cache, updateVisit);
        this.updateCompanyDispatchOverviewCache(cache, updateVisit);
      }
    );

  updateCompanyDispatchScheduledVisitsWindowedCache = (
    cache,
    [startTime, endTime],
    updatedVisit,
    previousVisit
  ) => {
    const sortParts = updatedVisit.sortKey.split('_');
    const sortKey = `${sortParts[0]}_Company_${sortParts[1]}`;
    // read the correct queries from cache
    const scheduledQueryParams = {
      query: getCompanyDispatchScheduledVisits,
      variables: {
        partitionKey: updatedVisit.partitionKey,
        sortKey,
        dispatchWindowStartTime: startTime,
        dispatchWindowEndTime: endTime
      }
    };
    let scheduledData = { scheduledVisits: { visitsInRange: { items: [] } } };
    try {
      scheduledData = cache.readQuery(scheduledQueryParams);
    } catch (e) {
      // console.warn(`Could not read cache for scheduled query: ${e}`);
      return;
    }
    if (!scheduledData.scheduledVisits) {
      scheduledData.scheduledVisits = { visitsInRange: { items: [] } };
    }
    const visitsInRange =
      scheduledData.scheduledVisits && scheduledData.scheduledVisits.visitsInRange;
    // make shallow copies of the visit array to fiddle with
    const scheduledVisits = [...((visitsInRange && visitsInRange.items) || [])];
    // and locate the updated visit in the arrays
    const scheduledIndex = scheduledVisits.findIndex(
      visit =>
        visit.id === updatedVisit.id ||
        // check to see if the updated visit actually should overwrite a created visit..
        // TODO: determine if this is the best way to approach it..
        (visit.id === 'new' &&
          updatedVisit.version === 1 &&
          visit.jobNumber === updatedVisit.jobNumber &&
          visit.scheduledFor === updatedVisit.scheduledFor)
    );
    /* if (scheduledIndex === -1) {
      console.log("NOT FOUND", updatedVisit, scheduledVisits.map( v => ({ id: v.id, ver: v.version, num: v.jobNumber, sf: v.scheduledFor })))
    } */
    if (
      updatedVisit.status === 'On hold' ||
      updatedVisit.onHold ||
      updatedVisit.status === 'Unassigned' ||
      updatedVisit.status === 'Canceled'
    ) {
      // this visit is not visible on the board
      // TODO: replicate actual server behaviour here
      if (scheduledIndex > -1) {
        // remove it from scheduled if it exists there
        scheduledVisits.splice(scheduledIndex, 1);
      }
    } else if (scheduledIndex > -1) {
      // visit looks like it's going/on the board
      // update / insert it
      if (previousVisit) {
        scheduledVisits[scheduledIndex] = {
          ...previousVisit,
          ...updatedVisit
        };
      } else {
        scheduledVisits[scheduledIndex] = updatedVisit;
      }
    } else {
      // just add, not in existing scheduled visits and should be..
      scheduledVisits.push(updatedVisit);
    }
    // finally, update the actual data to be written to cache
    scheduledData.scheduledVisits.visitsInRange.items = scheduledVisits;
    cache.writeQuery({
      ...scheduledQueryParams,
      data: scheduledData
    });
  };

  updateCompanyDispatchScheduledVisitsCache = (cache, updatedVisit, previousVisit) => {
    const scheduledTime = updatedVisit.scheduledFor || moment().unix();
    const visitTime = moment(scheduledTime * 1000).local();
    this.updateCompanyDispatchScheduledVisitsWindowedCache(
      cache,
      [
        visitTime
          .clone()
          .startOf('day')
          .subtract(1, 'week')
          .unix(),
        visitTime
          .clone()
          .startOf('day')
          .add(24, 'hours')
          .unix()
      ],
      updatedVisit,
      previousVisit
    );
    this.updateCompanyDispatchScheduledVisitsWindowedCache(
      cache,
      [
        visitTime
          .clone()
          .startOf('isoWeek')
          .unix(),
        visitTime
          .clone()
          .startOf('isoWeek')
          .add(7 * 24, 'hours')
          .unix()
      ],
      updatedVisit,
      previousVisit
    );
  };

  queryCompanyDispatchScheduledVisits = async (partitionKey, sortKey, startTime, endTime) => {
    const params = {
      partitionKey,
      sortKey,
      dispatchWindowStartTime: startTime,
      dispatchWindowEndTime: endTime
    };
    //    console.log({ getCompanyDispatchScheduledVisits, dispatchVisitFragment });
    const response = await this.api.observableQuery(getCompanyDispatchScheduledVisits, params);
    return Observable.from(response).map(r => {
      if (
        !r.data ||
        !r.data.scheduledVisits ||
        !r.data.scheduledVisits.visitsInRange ||
        !r.data.scheduledVisits.visitsInRange.items
      ) {
        return { data: [] };
      }
      return {
        data: r.data.scheduledVisits.visitsInRange.items
      };
    });
  };

  updateCompanyDispatchOverviewCache = (cache, updatedVisit, previousVisit, visitsAfterTime) => {
    const sortParts = updatedVisit.sortKey.split('_');
    const sortKey = `${sortParts[0]}_Company_${sortParts[1]}`;

    const overviewQueryParams = {
      query: getCompanyDispatchOverview,
      variables: {
        partitionKey: updatedVisit.partitionKey,
        sortKey,
        visitsAfter: visitsAfterTime
      }
    };

    let overviewData = { overview: { visits: { items: [] } } };
    try {
      overviewData = cache.readQuery(overviewQueryParams);
    } catch (e) {
      console.warn(`Could not read cache for overview query: ${e}`);
      return;
    }

    const overviewVisits = [...overviewData.overview.visits.items];
    const overviewIndex = overviewVisits.findIndex(v => v.id === updatedVisit.id);

    if (updatedVisit.status === 'On hold' || updatedVisit.status === 'Unassigned') {
      // on sidebar, update / insert into overview
      if (overviewIndex > -1) {
        if (previousVisit) {
          overviewVisits[overviewIndex] = {
            ...previousVisit,
            ...updatedVisit,
            parentEntity: updatedVisit.parentEntity || previousVisit.parentEntity // override this, since we
            // get nulls from visitTransition that overwrite the original parententity
          };
        } else {
          overviewVisits[overviewIndex] = updatedVisit;
        }
      } else {
        overviewVisits.push(updatedVisit);
      }
    } else {
      // on board / canceled / other states, remove from overview if necessary
      if (overviewIndex > -1) {
        overviewVisits.splice(overviewIndex, 1);
      }
    }
    // finally, actually update the cache
    overviewData.overview.visits.items = overviewVisits;
    cache.writeQuery({
      ...overviewQueryParams,
      data: overviewData
    });
  };

  queryCompanyDispatchOverview = async variables => {
    const response = await this.api.observableQuery(getCompanyDispatchOverview, variables);

    // this *should*/could just be response.map() but it's only fixed in
    // apollo-client 2.6.4+ see
    // https://github.com/apollographql/apollo-client/issues/3721
    return Observable.from(response).map(r => {
      const { loading, stale, networkStatus, data } = r;

      const outBase = { loading, stale, networkStatus };

      if (!data || !data.overview)
        return {
          ...outBase,
          data: {
            visits: [],
            departments: [],
            pms: [],
            companyAddresses: []
          }
        };

      const items = field => data.overview[field].items;

      const outData = {};
      for (const field of ['visits', 'departments', 'pms', 'companyAddresses'])
        outData[field] = items(field);

      return { ...outBase, data: outData };
    });
  };

  queryCompanyActiveTechsWindowed = async (
    partitionKey,
    sortKey,
    startTime,
    endTime,
    heartbeatStart = (new Date().getTime() / 1000 - 3600) | 0
  ) => {
    // default to querying for heartbeats in the last hour
    const params = {
      partitionKey,
      sortKey,
      offscheduleStart: startTime,
      offscheduleEnd: endTime,
      heartbeatStart
    };

    const response = await this.api.observableQuery(getCompanyActiveTechsWindowed, params);
    return Observable.from(response).map(r => {
      if (
        !r.data ||
        !r.data.getCompany ||
        !r.data.getCompany.employees ||
        !r.data.getCompany.employees.items
      ) {
        return {
          loading: r.loading,
          stale: r.stale,
          networkStatus: r.networkStatus,
          data: []
        };
      }
      // return only the most recent heartbeat for each tech
      r.data.getCompany.employees.items.forEach(tech => {
        if (tech.heartbeats.items.length) {
          tech.heartbeats.items = [
            tech.heartbeats.items.find(function(hb) {
              return (
                hb.timestamp ===
                Math.max.apply(
                  Math,
                  tech.heartbeats.items.map(function(heartbeat) {
                    return heartbeat.timestamp;
                  })
                )
              );
            })
          ];
        }
      });
      return {
        loading: r.loading,
        stale: r.stale,
        networkStatus: r.networkStatus,
        data: r.data.getCompany.employees.items
      };
    });
  };

  updateCompanyTechOffScheduleQueryCache(
    cache,
    queryParams,
    updatedOffSchedule,
    createdOffSchedule
  ) {
    let companyData = { getCompany: { employees: { items: [] } } };
    try {
      companyData = cache.readQuery(queryParams);
    } catch (e) {
      console.warn(`Could not read cache for company tech query: ${e}`);
      return;
    }
    const offScheduleTechId = updatedOffSchedule.hierarchy.substr(
      updatedOffSchedule.hierarchy.lastIndexOf('_') + 1
    );
    for (let i = 0; i < companyData.getCompany.employees.items.length; i += 1) {
      if (companyData.getCompany.employees.items[i].id === offScheduleTechId) {
        // found the correct tech, update or insert the updated offschedule.
        const offSchedules = [...companyData.getCompany.employees.items[i].offSchedules.items];
        const osIndex = offSchedules.findIndex(os => os.id === updatedOffSchedule.id);
        if (osIndex > -1) {
          offSchedules[osIndex] = {
            ...createdOffSchedule,
            ...offSchedules[osIndex],
            ...updatedOffSchedule
          };
        } else {
          offSchedules.push({
            ...createdOffSchedule,
            ...updatedOffSchedule
          });
        }
        companyData.getCompany.employees.items[i].offSchedules.items = offSchedules;
        break;
      }
    }
    cache.writeQuery({
      ...queryParams,
      data: companyData
    });
  }

  updateCompanyTechOffScheduleCache = (cache, updatedOffSchedule, createdOffSchedule = {}) => {
    const sortParts = updatedOffSchedule.sortKey.split('_');
    const companySortKey = `${sortParts[0]}_Company_${sortParts[1]}`;
    this.updateCompanyTechOffScheduleQueryCache(
      cache,
      {
        query: getCompanyTechs,
        variables: {
          partitionKey: updatedOffSchedule.partitionKey,
          sortKey: companySortKey
        }
      },
      updatedOffSchedule,
      createdOffSchedule
    );
  };

  updateCompanyActiveTechWindowedOffScheduleCache = (
    cache,
    updatedOffSchedule,
    createdOffSchedule = {},
    startTime,
    endTime,
    heartbeatStart
  ) => {
    const sortParts = updatedOffSchedule.sortKey.split('_');
    const companySortKey = `${sortParts[0]}_Company_${sortParts[1]}`;
    this.updateCompanyTechOffScheduleQueryCache(
      cache,
      {
        query: getCompanyActiveTechsWindowed,
        variables: {
          partitionKey: updatedOffSchedule.partitionKey,
          sortKey: companySortKey,
          offscheduleStart: startTime,
          offscheduleEnd: endTime,
          heartbeatStart
        }
      },
      updatedOffSchedule,
      createdOffSchedule
    );
  };

  updateCompanyActiveTechWindowedOffScheduleCacheDelete = (
    cache,
    response,
    startTime,
    endTime,
    heartbeatStart
  ) => {
    const sortParts = response.sortKey.split('_');
    const companySortKey = `${sortParts[0]}_Company_${sortParts[1]}`;
    const qv = {
      query: getCompanyActiveTechsWindowed,
      variables: {
        partitionKey: response.partitionKey,
        sortKey: companySortKey,
        offscheduleStart: startTime,
        offscheduleEnd: endTime,
        heartbeatStart
      }
    };
    let prev = { getCompany: { employees: { items: [] } } };
    try {
      prev = cache.readQuery(qv);
    } catch (e) {
      console.warn(`Could not read cache for company tech query: ${e}`);
      return;
    }
    const offScheduleTechId = response.hierarchy.substr(response.hierarchy.lastIndexOf('_') + 1);
    const newEmployeeItems = [...prev.getCompany.employees.items];
    for (let i = 0; i < prev.getCompany.employees.items.length; i += 1) {
      if (prev.getCompany.employees.items[i].id === offScheduleTechId) {
        // found the correct tech, remove the offschedule (if it exists anymore..).
        newEmployeeItems[i] = {
          ...newEmployeeItems[i],
          offSchedules: {
            ...newEmployeeItems[i].offSchedules,
            items: newEmployeeItems[i].offSchedules.items.filter(p => p.id !== response.id)
          }
        };
      }
    }
    // and update the employees
    const next = {
      ...prev,
      getCompany: {
        ...prev.getCompany,
        employees: {
          ...prev.getCompany.employees,
          items: newEmployeeItems
        }
      }
    };
    cache.writeQuery({
      ...qv,
      data: next
    });
  };

  subscribeToTenantVisits = async partitionKey => {
    const params = {
      partitionKey
    };

    const subscription = await this.subscriptionClient.subscribe({
      query: visitUpdateNotification,
      variables: params
    });
    return Observable.from(subscription).map(r => {
      if (r.data && r.data.visitUpdateNotification) {
        return r.data.visitUpdateNotification;
      }
      return null;
    });
  };

  subscribeToTechHeartbeat = async partitionKey => {
    const params = {
      partitionKey
    };

    const subscription = await this.subscriptionClient.subscribe({
      query: techHeartbeatNotification,
      variables: params
    });
    return Observable.from(subscription).map(r => {
      console.log('subscribeToTechHeartbeat', r);
      if (r.data && r.data.techHeartbeatNotification) {
        return r.data.techHeartbeatNotification;
      }
      return null;
    });
  };

  queryTechHeartbeat = async (partitionKey, sortKey, startTime, endTime) => {
    const params = {
      partitionKey,
      sortKey,
      dispatchWindowStartTime: startTime,
      dispatchWindowEndTime: endTime
    };
    const response = await this.api.observableQuery(getHeartbeatById, params);
    return Observable.from(response).map(r => {
      console.log('queryTechHeartbeat', r);
      if (
        !r.data ||
        !r.data.scheduledVisits ||
        !r.data.scheduledVisits.visitsInRange ||
        !r.data.scheduledVisits.visitsInRange.items
      ) {
        return { data: [] };
      }
      return {
        data: r.data.scheduledVisits.visitsInRange.items
      };
    });
  };

  getTaskNumber = async () => {
    const response = await this.api.client.query({
      query: getTaskNumber,
      fetchPolicy: 'network-only'
    });
    return response;
  };
}
