/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import { calculateMarginFromMarkup } from '@BuildHero/math';
import { ThemeProvider } from '@BuildHero/sergeant';
import { Grid } from '@material-ui/core';

import {
  AddRecordButton,
  ProductWithPricingSearch,
  ResponsiveTable,
  SergeantModal
} from 'components';
import {
  getCostCodes,
  getJobCostTypes,
  splitJobCostTypes
} from 'customHooks/useLabourTypeDependency';
import PurchasedItemLayout from 'meta/Jobs/Invoice/PurchasedItemForm';
import { PurchasedItemSgtLayout } from 'meta/Jobs/Invoice/PurchasedItemSgtForm';
import { purchasedItemsRows } from 'meta/Jobs/Invoice/review-report-tables';
import { validations } from 'services/core';
import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import { convertForMathLib } from 'utils/mathLibrary';
import { getMarkupFromMargin } from 'utils/onCalcChange';

import RowActions from './ReviewReportRowActions';

const PurchaseOrders = ({
  current,
  send,
  receiptItem,
  isReviewReport,
  setStopLoading,
  user,
  snackbarOn,
  marginEnabled
}) => {
  const { context, value } = current;
  const [s3Url, setS3Url] = useState('');
  const [formData, setFormData] = useState({});
  const imageUrl = value.purchase_order_line_item_new
    ? context.modalRecord?.purchaseOrderReceipts?.items?.[0]?.imageUrl
    : context.modalRecord?.image?.[0]?.imageUrl;

  useEffect(() => {
    async function fetchS3ImageUrl() {
      try {
        const storageService = new StorageService();
        const url = await storageService.getFile(imageUrl);
        setS3Url([{ fileUrl: url }]);
        setFormData(f => ({
          ...f,
          includeImage: url
        }));
      } catch (error) {
        Logger.error(error);
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          snackbarOn('error', error.graphQLErrors[0].message);
        } else {
          snackbarOn('error', 'Unable to load receipt image, please try again later');
        }
        setS3Url([{ fileUrl: null }]);
        setFormData(f => ({
          ...f,
          includeImage: false
        }));
      }
    }

    const getJobCostingFields = async () => {
      const costCodes = await getCostCodes(user.tenantId, user.tenantCompanyId, snackbarOn);
      const costCodeTypeDetails = await getJobCostTypes(
        user.tenantId,
        user.tenantCompanyId,
        snackbarOn
      );
      const { jobCostTypes, revenueTypes } = splitJobCostTypes(costCodeTypeDetails);
      setFormData(f => ({
        ...f,
        costCodes: [...constructSelectOptions(costCodes)],
        costTypes: constructSelectOptions(jobCostTypes),
        revenueTypes: constructSelectOptions(revenueTypes)
      }));
    };

    setFormData(f => ({
      ...f,
      priceBookId: context.reviewReportPriceBookId
    }));

    fetchS3ImageUrl();
    getJobCostingFields();
  }, [imageUrl, user.tenantId, user.tenantCompanyId, context.reviewReportPriceBookId, snackbarOn]);

  const PurchasedLayoutMeta = PurchasedItemLayout.entity.layouts.web;
  PurchasedLayoutMeta.buttons.cancel.action = () => send('CLOSE');

  const isEditable = isReviewReport && !context.freezeEdit;

  const PurchaseOrderItemActions = ({ record }) => {
    return (
      <RowActions
        checkboxAction={event =>
          send('INCLUDE_PURCHASE_ORDER_LINE_INVOICE', {
            data: record,
            checked: event.target.checked,
            parent: receiptItem
          })
        }
        deleteAction={() =>
          send('DELETE_PURCHASE_ORDER_LINE_ITEM', { data: record, parent: receiptItem })
        }
        editAction={() =>
          send('EDIT_PURCHASE_ORDER_LINE_ITEM', { data: record, parent: receiptItem })
        }
        isEditable={isEditable}
        record={record}
      />
    );
  };

  const purchasedItemsTableMeta = [...purchasedItemsRows(marginEnabled)];
  if (isEditable) {
    purchasedItemsTableMeta.push({
      id: 'actions',
      isCustom: true,
      type: 'actions',
      label: ''
    });
  }

  const ThemedProductWithPricingSearch = props => (
    <ThemeProvider>
      <ProductWithPricingSearch {...props} />
    </ThemeProvider>
  );

  return (
    <>
      <Grid style={{ marginLeft: 100, marginBottom: 10 }}>
        <ResponsiveTable
          customCellComponents={{ actions: PurchaseOrderItemActions }}
          data={receiptItem?.purchaseOrderLines?.items || []}
          disableFilter
          disablePagination
          noDataMsg="No items"
          noMaxHeight
          rowMetadata={purchasedItemsTableMeta}
        />
      </Grid>

      {isEditable && (
        <AddRecordButton
          label="+ Add Item"
          style={{ marginLeft: 168 }}
          onClick={() => {
            send('ADD_PURCHASE_ORDER_LINE_ITEM', {
              data: receiptItem
            });
          }}
        />
      )}
      <SergeantModal
        customComponents={{ ThemedProductWithPricingSearch }}
        data={
          value.purchase_order_line_item_new
            ? {
                s3Url,
                departmentName: context.visit.departmentName,
                productFilter: context.productFilter,
                accountingRefIdOfClass: context.visit.accountingRefIdOfClass || '',
                isPriceBookEnabled: context.isPriceBookEnabled
              }
            : {
                ...context.modalRecord,
                s3Url,
                markupValue: context.modalRecord.markup,
                marginValue: convertForMathLib(
                  calculateMarginFromMarkup,
                  context.modalRecord.markup
                )
              }
        }
        dataType="receipt line item"
        handleClose={() => send('CLOSE')}
        handlePrimaryAction={async (values, stopLoading) => {
          const formattedValues = {
            ...values,
            markup: marginEnabled ? getMarkupFromMargin(values.marginValue) : values.markupValue
          };
          setStopLoading(stopLoading);
          send('SAVE', { saveData: formattedValues });
        }}
        layout={PurchasedItemSgtLayout({
          formData,
          marginEnabled
        })}
        mode={value.purchase_order_line_item_new ? 'new' : 'edit'}
        open={value?.purchase_order_line_edited || value?.purchase_order_line_item_new || false}
        validationSchema={validations.reviewReportPurchaseOrderLinesSchema}
      />
    </>
  );
};

export default PurchaseOrders;
