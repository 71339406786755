/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Context, SergeantModal } from 'components';

import ProductSearch from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import layout from 'meta/ServiceAgreements/PartsAndMaterialsLayout';
import { Mode } from 'utils/constants';

import { formatPartsAndMaterial } from '../helpers';

import {
  AddAdditionalParts,
  CheckboxList,
  DividerWithPadding,
  SuggestedParts
} from './customComponents';
import associatedPartsAndMaterialsLayout from './layout';
import List from './List';

const PartsAndMaterials = ({
  data,
  onAddItem,
  modalToShow = '',
  setModalToShow,
  associatedPartsAndMaterials
}) => {
  const { defaultPriceBookId } = Context.getCompanyContext().getCompany || {};
  const [items, setItems] = React.useState([]);
  const [checkboxItems, setCheckboxItems] = React.useState(associatedPartsAndMaterials);

  React.useEffect(() => {
    setItems(data);
  }, [data]);

  React.useEffect(() => {
    setCheckboxItems(associatedPartsAndMaterials);
  }, [associatedPartsAndMaterials]);

  const primaryAction = (material, stopLoading) => {
    const { items, checkboxItems = [] } = material;
    onAddItem([...items, ...checkboxItems]);
    stopLoading();
    setModalToShow('');
  };

  const ItemSearch = itemSearchProps => {
    const { options } = itemSearchProps;
    const onChange = item => {
      if (item) {
        setItems(existingItems => [...existingItems, formatPartsAndMaterial(item)]);
      }
    };
    return <ProductSearch {...itemSearchProps} options={{ ...options, onChange }} />;
  };

  return (
    <>
      <SergeantModal
        customComponents={{
          Description: () => (
            <Typography>
              There are parts and materials associated with this asset. Would you like to add them
              to this maintenance template?
            </Typography>
          )
        }}
        customPrimaryButtonLabel="Add Default Parts and Materials"
        customSecondaryButtonColor="secondary"
        customSecondaryButtonLabel="Continue without adding Parts and Materials"
        handleClose={() => setModalToShow('')}
        handlePrimaryAction={(_, stopLoading) => {
          setItems([]);
          setModalToShow('suggestedAndAddParts');
          stopLoading();
        }}
        handleSecondaryAction={(_, stopLoading) => {
          setModalToShow('');
          stopLoading();
        }}
        layout={associatedPartsAndMaterialsLayout()}
        maxWidth={550}
        open={modalToShow === 'partsAndMaterialsFound'}
        title="Parts & Materials Found"
      />
      <SergeantModal
        customComponents={{
          SuggestedParts,
          CheckboxList,
          DividerWithPadding,
          AddAdditionalParts,
          List,
          ItemSearch
        }}
        customPrimaryButtonLabel="Add Parts And Materials"
        data={{ items, checkboxItems }}
        dataType="Parts And Materials"
        handleClose={() => setModalToShow('')}
        handlePrimaryAction={primaryAction}
        layout={layout(
          defaultPriceBookId,
          true,
          'suggestedAndAddParts',
          associatedPartsAndMaterials
        )}
        mode={Mode.ADD}
        open={modalToShow === 'suggestedAndAddParts'}
      />
      <SergeantModal
        customComponents={{ List, ItemSearch }}
        customPrimaryButtonLabel="Add Parts And Materials"
        data={{ items }}
        dataType="Parts And Materials"
        handleClose={() => setModalToShow('')}
        handlePrimaryAction={primaryAction}
        layout={layout(defaultPriceBookId, true, 'addParts')}
        mode={Mode.ADD}
        open={modalToShow === 'addParts'}
      />
    </>
  );
};

PartsAndMaterials.propTypes = {
  data: PropTypes.array,
  onAddItem: PropTypes.func,
  associatedPartsAndMaterials: PropTypes.array
};

PartsAndMaterials.defaultProps = {
  data: [],
  onAddItem: () => {},
  associatedPartsAndMaterials: []
};

export default PartsAndMaterials;
