import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { checkPermission } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';

import { calculatedTotal, hourTotal, secondsToHour } from './helpers';

import useStyles from './HourSummary.style';

import HourView from './HourView';

const HourSummary = ({
  data,
  user,
  className,
  isEditable,
  onHourSummaryFocusOut,
  onHourSummaryChange,
  isVisible
}) => {
  const classes = useStyles();
  if (data.length === 0 || !isVisible) return null;

  const hasEditPermission = checkPermission(
    'allow',
    PermissionConstants.FUNCTIONS_TIMETRACKING_REPORT,
    user
  );

  const renderDetails = (detail, i) => {
    const { actualTotalDurationOverride, isTotal } = detail;
    const showCalculated = actualTotalDurationOverride !== undefined;

    const durationIsInUnitsOfSecond = Number.isInteger(actualTotalDurationOverride);
    return (
      <Grid className={classes.summaryItem} key={i}>
        <Typography className={classes.summaryType} variant="caption">
          {detail.hourTypeAbbreviation}
        </Typography>
        <HourView
          classes={classes}
          detail={detail}
          hasEditPermission={hasEditPermission}
          isEditable={isEditable}
          onHourSummaryChange={onHourSummaryChange}
          onHourSummaryFocusOut={onHourSummaryFocusOut}
        />
        {/*
          @TODO - consider  removing or redesigning this row. Currently it is very confusing from a user perspective.
        */}
        {showCalculated && (
          <Grid className={classes.calculatedSummary}>
            <Typography className={classes.calculatedValue} variant="h3">
              {isTotal &&
                durationIsInUnitsOfSecond &&
                secondsToHour(detail.actualTotalDurationOverride)}{' '}
              {isTotal && !durationIsInUnitsOfSecond && detail.actualTotalDurationOverride}
              {!isTotal && detail.hour}
            </Typography>
            <Typography className={classes.calculatedAbbreviation} variant="caption">
              {detail.calculatedAbbreviation || 'Calculated'}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Grid className={`${classes.summaryContainer} ${className}`} container>
      <Grid className={classes.summaryInfoWrapper} item>
        {data.map(renderDetails)}
        {renderDetails({
          isTotal: true,
          hourTypeAbbreviation: 'Total',
          calculatedAbbreviation: 'Total',
          hour: hourTotal(data),
          actualTotalDurationOverride: calculatedTotal(data)
        })}
      </Grid>
    </Grid>
  );
};

export default HourSummary;

HourSummary.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
  onHourSummaryChange: PropTypes.func,
  data: PropTypes.array,
  isEditable: PropTypes.bool,
  isVisible: PropTypes.bool
};

HourSummary.defaultProps = {
  className: '',
  data: [],
  onHourSummaryChange: () => {},
  isEditable: false,
  isVisible: true
};
