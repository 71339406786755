export const ScheduleInterval = {
  // DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  EVERY_2_WEEKS: 'EVERY_2_WEEKS',
  MONTHLY: 'MONTHLY',
  EVERY_2_MONTHS: 'EVERY_2_MONTHS',
  QUARTERLY: 'QUARTERLY',
  EVERY_4_MONTHS: 'EVERY_4_MONTHS',
  EVERY_6_MONTHS: 'EVERY_6_MONTHS',
  ANNUALLY: 'ANNUALLY'
};
