import React, { useState } from 'react';

import {
  Button,
  ButtonType,
  Modal,
  SgtForm,
  SgtFormComponents,
  ThemeProvider
} from '@BuildHero/sergeant';
import { IconButton, InputAdornment } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { number, object, string } from 'yup';

import { RenewLayout } from 'meta/ServiceAgreements/Renew';

import { calculateRenewalNumber } from '../../../utils';
import { AGREEMENT_ACTIONS } from '../../constants';
import { transitionServiceAgreement } from '../../service';

const renewalValidationSchema = () =>
  object({
    agreementName: string()
      .nullable()
      .required('Agreement name is required'),
    agreementNumber: string()
      .nullable()
      .required('Agreement number is required'),
    startDate: number()
      .nullable()
      .required('Start date is required')
      .min(
        moment()
          .startOf('day')
          .unix(),
        'Renewal date must be a current or future date'
      )
  });

const AgreementNumber = fieldProps => {
  const { field, form } = fieldProps;
  return (
    <SgtFormComponents.Input
      {...fieldProps}
      props={{
        ...fieldProps.props,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="New agreement number"
              style={{ paddingRight: 2 }}
              onClick={() => form.setFieldValue(field.name, form?.values?.nextAgreementNumber)}
            >
              <ReplayIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export const RenewModal = ({
  agreementInfo,
  renewModalIsOpen,
  handleRenewModalClose,
  user,
  snackbar
}) => {
  const [loading, setLoading] = useState(false);
  const [formService, setFormService] = useState();
  const history = useHistory();

  const handleSubmit = async formData => {
    setLoading(true);

    const renewalPayload = {
      id: agreementInfo.id,
      agreementName: formData.agreementName,
      agreementNumber: formData.agreementNumber,
      startDate: formData.startDate,
      carryEndDate: formData.carryEndDate || false
    };

    const updatedServiceAgreement = await transitionServiceAgreement(
      AGREEMENT_ACTIONS.RENEWAL,
      renewalPayload,
      user.tenantId,
      snackbar
    );

    setLoading(false);
    if (updatedServiceAgreement?.id) {
      history.push(updatedServiceAgreement.id);
    }
  };

  const nextAgreementNumber = calculateRenewalNumber(agreementInfo.agreementNumber);

  const renewalDateMoment = agreementInfo?.renewalDate
    ? moment.unix(agreementInfo.renewalDate)
    : moment();

  return (
    <ThemeProvider>
      <Modal
        actions={
          <Button
            fullWidth
            loading={loading}
            type={ButtonType.PRIMARY}
            onClick={formService?.formikContext.handleSubmit}
          >
            Renew Agreement
          </Button>
        }
        maxWidth={false}
        open={renewModalIsOpen}
        title="Renew Agreement"
        onClose={handleRenewModalClose}
      >
        <SgtForm
          configuration={RenewLayout}
          customComponents={{ AgreementNumber }}
          initialValues={{
            agreementName: agreementInfo?.agreementName || '',
            agreementNumber: nextAgreementNumber,
            nextAgreementNumber, // to support reset
            startDate: renewalDateMoment.startOf('day').unix()
          }}
          validationSchema={renewalValidationSchema()}
          onCreateService={setFormService}
          onSubmit={handleSubmit}
        />
      </Modal>
    </ThemeProvider>
  );
};
