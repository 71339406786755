import React from 'react';

import { Box, Typography } from '@material-ui/core';

import DefaultButton from 'components/Buttons/DefaultButton';

import { dayTableMeta, timeFields } from './constants';
import { getEmployeeIds, getHourSettingValue, getSelectedText, getWeekDays } from './helpers';

import useStyles from './Setting.styles';
import { ItemWrapper, RenderHeader, RenderTitle } from './TinyComponents';

const Setting = ({ data, employeeOptions, departmentOptions, onEdit, isLoading }) => {
  const classes = useStyles();
  const weekDays = getWeekDays();
  const { afterHoursTimings = [], departmentIds, name } = data;

  const tableHeaders = [...dayTableMeta];
  tableHeaders.pop();

  const employeeIds = getEmployeeIds(afterHoursTimings);

  const employeeText = getSelectedText(employeeOptions, employeeIds);
  const departmentText = getSelectedText(departmentOptions, departmentIds);

  const selectedSection = [
    { title: 'Department(s)', flex: 1.5, value: departmentText },
    { title: 'Assigned technician(s)', flex: 2, value: employeeText }
  ];

  return (
    <Box display="flex" flexDirection="column" py={1}>
      <Box display="flex" justifyContent="space-between">
        <Typography style={{ paddingTop: 6, paddingBottom: 6 }} variant="h6">
          {name}
        </Typography>
        <DefaultButton disabled={isLoading} label="edit" variant="outlined" onClick={onEdit} />
      </Box>
      <Box display="flex" py={2}>
        {selectedSection.map((item, i) => (
          <Box display="flex" flex={item.flex} flexDirection="column" key={i}>
            <RenderTitle title={item.title} />
            <Typography style={{ fontWeight: 'normal' }} variant="body1">
              {item.value || '-'}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box display="flex" flexDirection="column">
        <RenderHeader items={tableHeaders} />
        <Box display="flex" flexDirection="column">
          {weekDays.map(weekDay => {
            const { dayIndex } = weekDay;
            const value = getHourSettingValue(afterHoursTimings, dayIndex);

            return (
              <Box alignItems="center" display="flex" key={dayIndex}>
                <Box alignItems="center" className={classes.itemBorder} display="flex">
                  <ItemWrapper>
                    <Typography variant="body1">{weekDay.dayString}</Typography>
                  </ItemWrapper>
                  <Box display="flex">
                    {timeFields.map((timeField, i) => (
                      <ItemWrapper key={i}>
                        <Typography variant="body1">{value[timeField]}</Typography>
                      </ItemWrapper>
                    ))}
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
export default Setting;
