import { useTheme } from '@material-ui/core';

import { DispatchTheme, ElementSizes, ElementZIndex } from '@dispatch/Dispatch.styles';

export const useStyles = ({
  mapView,
  loading,
  weekView,
  visitWidth,
  isOptimistic,
  isDragging,
  isDrawer,
  canDragVisit,
  canDragStart,
  canDragEnd
}) => {
  const theme = useTheme();
  let width = visitWidth || ElementSizes.cellWidth - 1;
  if (mapView) width = ElementSizes.cellWidth * 1.5;
  if (isDrawer) width = '100%';
  if (weekView) {
    if (isDragging) width = `calc((100vw - ${ElementSizes.techCellWidth}px) / 7)`;
    else width = '100%';
  }

  return {
    root: {
      backgroundColor: 'white',
      borderRadius: 4,
      overflow: 'hidden',
      boxShadow: isDragging ? theme.shadows[4] : 'none',
      marginTop: mapView ? theme.spacing(1) : 0,
      marginBottom: weekView ? 2 : 0,
      position: mapView || weekView || isDrawer ? 'initial' : 'absolute',
      height: mapView ? undefined : ElementSizes.cellHeight - 1,
      minHeight: mapView ? ElementSizes.cellHeight - 1 : undefined,
      flex: weekView ? `0 0 ${ElementSizes.cellHeight - 1}px` : undefined,
      // eslint-disable-next-line no-nested-ternary
      cursor: loading || isOptimistic ? 'initial' : canDragVisit ? 'grab' : 'pointer',
      width,
      zIndex: ElementZIndex.eventCard,
      '& a': {
        color: 'initial',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline'
        }
      }
    },
    positioningBoundary: {
      height: '100%',
      width: '100%',
      position: 'relative'
    },
    dragStart: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: 5,
      height: '100%',
      cursor: canDragStart ? 'ew-resize' : 'initial'
    },
    dragEnd: {
      position: 'absolute',
      right: 0,
      top: 0,
      width: 5,
      height: '100%',
      cursor: canDragEnd ? 'ew-resize' : 'initial'
    },
    content: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      '& *': {
        maxWidth: loading ? undefined : 'fit-content'
      }
    },
    companyName: {
      fontSize: 10,
      display: 'inline',
      ...DispatchTheme.mixins.oneLineClamp
    },
    propertyName: {
      fontSize: 14,
      fontWeight: 700,
      ...DispatchTheme.mixins.oneLineClamp
    },
    visitNumber: {
      fontSize: 10,
      display: 'inline',
      ...DispatchTheme.mixins.oneLineClamp
    },
    description: {
      fontSize: 10,
      display: 'inline',
      ...DispatchTheme.mixins.twoLineClamp
    },
    scheduledFor: {
      fontSize: 10,
      display: 'inline',
      ...DispatchTheme.mixins.oneLineClamp
    }
  };
};

export const useCornerBannerStyles = ({ bannerConfig, checked }) => {
  const { background, textColor } = bannerConfig;
  return {
    root: ({ palette }) => ({
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: '20',
      height: '16px',
      width: '100px',
      right: '-30px',
      top: '15px',
      background: background ?? palette.grayscale(20),
      fontSize: 12,
      color: textColor ?? 'white',
      transform: 'rotate(45deg)'
    }),
    checkbox: ({ palette }) => ({
      transform: 'scale(0.7)',
      margin: 0,
      color: `${checked ? palette.primary.light : 'white'} !important`,
      opacity: '1 !important'
    })
  };
};
