import {
  dissociateQuoteConfirmConfig,
  invoiceStatusErrorConfig,
  invoiceStatusWarningConfig
} from 'scenes/Quotes/confirmConfig';

import { InvoiceStatus } from 'utils/constants';

const validateDissociateJobFromQuote = async ({ confirmContext, data, jobData, palette }) => {
  const invoices = data?.quoteInvoices?.items?.map(item => item.invoice);
  const relatedInvoiceExportedOrClosed = invoices?.filter(invoice =>
    [InvoiceStatus.EXPORTED, InvoiceStatus.CLOSED].includes(invoice.status)
  );
  const relatedInvoiceDraftOrPosted = invoices?.filter(invoice =>
    [InvoiceStatus.DRAFT, InvoiceStatus.POSTED].includes(invoice.status)
  );
  if (relatedInvoiceExportedOrClosed?.length) {
    await confirmContext.confirm(invoiceStatusErrorConfig(relatedInvoiceExportedOrClosed, palette));
    return false;
  }
  if (relatedInvoiceDraftOrPosted?.length) {
    await confirmContext.confirm(invoiceStatusWarningConfig(relatedInvoiceDraftOrPosted, palette));
    return false;
  }
  return confirmContext.confirm(dissociateQuoteConfirmConfig(data, jobData));
};

export default validateDissociateJobFromQuote;
