import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import AlgoliaSearch from 'components/AlgoliaSearch';
import AlgoliaSelect from 'components/AlgoliaSearch/AlgoliaSelect';

import { TESTING_ID } from 'utils/constants';
import TestingIdUtils from 'utils/TestingIdUtils';

const styles = makeStyles(theme => ({
  inputLabel: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'normal',
    letterSpacing: '0.01px',
    lineHeight: '14px'
  },
  requiredLabel: {
    fontSize: '8px',
    letterSpacing: 0,
    lineHeight: '14px'
  }
}));

const AlgoliaComponent = props => {
  const { select } = props;
  if (select) return <AlgoliaSelect {...props} />;
  return <AlgoliaSearch {...props} />;
};

const AlgoliaSearchFormWrapper = props => {
  const classes = styles();
  const { options, field, form } = props;
  const {
    testingid,
    label,
    required,
    valuesToSet,
    searchIndexName,
    searchResultDisplayFields,
    filters,
    attributes,
    placeholder,
    showEntityNameInResults,
    setItemName = true,
    onChange,
    delimiter,
    disabled,
    color,
    variant,
    formStyles,
    select,
    helperText
  } = options;
  const { values, setValues, errors } = form;
  const algoliaSearchTestingId = TestingIdUtils.generateTestingId({
    customTestingId: testingid,
    componentDefaultTestingId: TESTING_ID.ALGOLIA_SEARCH
  });

  const defaultHandleChange = (selectedItem, selectedItemName) => {
    if (!selectedItem) {
      setValues({
        ...values,
        [field.name]: undefined
      });
      return;
    }
    if (valuesToSet?.length) {
      // object passed in meta are stripped by form library. Expecting only one object within the arr
      const keysToExtractFromResult = Object.keys(valuesToSet[0]);
      const extractedValues = {};
      keysToExtractFromResult.forEach(key => {
        const valueKey = valuesToSet[0][key];
        // set as the valueKey if it exists, otherwise set as the value given
        extractedValues[key] =
          typeof valueKey === 'string' ? get(selectedItem, valueKey, null) : valueKey;
      });
      setValues({
        ...values,
        ...extractedValues,
        // Set search bar text to selected item
        // selectedItemName is coming always undefined. fixing it for a hotfix, hence instead of removing have a null check
        // TODO: pls evaluate and remove
        ...(setItemName && selectedItemName ? { [field.name]: selectedItemName } : {})
      });
    } else {
      setValues({
        ...values,
        ...(setItemName ? { [field.name]: selectedItemName } : {})
      });
    }
  };

  return (
    <>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography className={classes.inputLabel} gutterBottom variant="caption">
          {label || ''}
        </Typography>
        {required && (
          <Typography className={classes.requiredLabel} variant="caption">
            REQUIRED
          </Typography>
        )}
      </div>
      <AlgoliaComponent
        attributes={attributes}
        color={color}
        customStyles={formStyles ? formStyles() : {}}
        delimiter={delimiter || ' - '}
        disabled={disabled}
        displayText={searchResultDisplayFields}
        errorMsg={(errors && errors[field.name]) || ''}
        filters={filters}
        handleChange={
          onChange
            ? (selectedItem, selectedItemName) => {
                onChange(selectedItem, selectedItemName, form, field);
              }
            : defaultHandleChange
        }
        helperText={helperText}
        locatedInPopUp
        name={`select${searchIndexName}`}
        overrideVariant={variant || 'outlined'}
        placeholder={placeholder}
        searchIndex={searchIndexName}
        select={select}
        showEntityNameInResults={showEntityNameInResults}
        testingid={algoliaSearchTestingId}
        value={field.value}
      />
    </>
  );
};

AlgoliaSearchFormWrapper.propTypes = {
  options: PropTypes.shape({
    testingid: PropTypes.string,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    valuesToSet: PropTypes.array,
    searchIndexName: PropTypes.string.isRequired,
    searchResultDisplayFields: PropTypes.array,
    filters: PropTypes.array,
    attributes: PropTypes.array,
    placeholder: PropTypes.string.isRequired,
    showEntityNameInResults: PropTypes.bool,
    setItemName: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    delimiter: PropTypes.string,
    disabled: PropTypes.bool,
    color: PropTypes.string,
    variant: PropTypes.string,
    helperText: PropTypes.string
  }).isRequired,
  field: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    name: PropTypes.string.isRequired
  }).isRequired,
  form: PropTypes.shape({
    values: PropTypes.object.isRequired,
    setValues: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired
  }).isRequired,
  theme: PropTypes.object.isRequired
};

export default withTheme(AlgoliaSearchFormWrapper);
