import airtechPDFForm from './airtechPDFForm';
import basic2PDFForm from './basic2PDFForm';
import basicPDFForm from './basicPDFForm';
import dormatechPDFForm from './dormatechPDFForm';
import elafonPDFForm from './elafonPDFForm';
import facilitySystemsPDFForm from './FacilitySystemsPDFForm';
import holmesForm from './holmesPdfForm';
import jacksonMechPDFForm from './jacksonMechPDFForm';
import mottPDFForm from './mottPDFForm';
import powerlinkPDFForm from './powerlinkPDFForm';
import service1stForm from './service1stPDFForms';
import southwestMechanical from './southwestMechanicalPdfForm';
import thermotechPDFForm from './thermotechPDFForm';

export default {
  default: holmesForm,
  basic: basicPDFForm,
  basic2: basic2PDFForm,
  Holmes: holmesForm,
  Service1st: service1stForm,
  SouthwestMechanical: southwestMechanical,
  Mott: mottPDFForm,
  Elafon: elafonPDFForm,
  Thermotech: thermotechPDFForm,
  Powerlink: powerlinkPDFForm,
  FacilitySystems: facilitySystemsPDFForm,
  Airtech: airtechPDFForm,
  JacksonMechanical: jacksonMechPDFForm,
  Dormatech: dormatechPDFForm
};
