import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { DefaultButton } from 'components';
import { getTenantSettingValueForKey } from 'utils';

import Item from './Item';

const MaintenanceTemplates = ({
  handleAddTemplate,
  handleEditTemplate,
  handleDeleteTemplate,
  data,
  shouldShowAddButton,
  isReadOnly,
  companyTimeZone
}) => {
  const isAssetEnabled = getTenantSettingValueForKey('assetTrackingAgainstTask') === 'true';

  const renderTemplateItem = item => (
    <Item
      companyTimeZone={companyTimeZone}
      data={item}
      handleDeleteTemplate={handleDeleteTemplate}
      handleEditTemplate={handleEditTemplate}
      isAssetEnabled={isAssetEnabled}
      isReadOnly={isReadOnly}
    />
  );

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4">Maintenance Templates</Typography>
        {shouldShowAddButton && (
          <DefaultButton
            color="secondary"
            label="Add maintenance template"
            onClick={handleAddTemplate}
          />
        )}
      </Box>
      <Box display="flex" flexDirection="column" mt={2}>
        {data.map(renderTemplateItem)}
      </Box>
    </Box>
  );
};

export default MaintenanceTemplates;
