import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CreateEntryButton from 'components/Buttons/CreateEntryButton';
import { snackbarOn } from 'redux/actions/globalActions';
import { PermissionConstants } from 'utils/AppConstants';

import CreateProject from '../../index';

const AddProject = ({
  user,
  addProjectDetails = {},
  isDisabled,
  isModalOpenExternally = false,
  projectCreationFailHandler = () => {},
  addProjectButtonLabel,
  addProjectButtonStyle,
  addProjectButtonIcon,
  parentCallback,
  updateWithProjectIdHandler = () => {}
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!isModalOpen && isModalOpenExternally) {
      parentCallback(isModalOpen);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (isModalOpenExternally && !isModalOpen) {
      setIsModalOpen(isModalOpenExternally);
    }
  }, [isModalOpenExternally]);

  const handleCloseModal = id => {
    if (id) {
      updateWithProjectIdHandler(id);
      history.push(`/project/view/${id}/projectsettings`);
    } else {
      projectCreationFailHandler();
    }
  };

  return (
    <>
      <CreateEntryButton
        caslKey={PermissionConstants.OBJECT_QUOTES}
        data-testid="add-project-button"
        disabled={isDisabled}
        handleAdd={() => setIsModalOpen(true)}
        key="createProject"
        label={addProjectButtonLabel}
        startIcon={addProjectButtonIcon}
        style={addProjectButtonStyle}
      />
      <CreateProject
        handleClose={() => setIsModalOpen(false)}
        initialData={{
          type: 'default',
          project: addProjectDetails
        }}
        open={isModalOpen}
        user={user}
        onSubmit={handleCloseModal}
      />
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  snackbar: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

export default connect(null, mapDispatchToProps)(AddProject);
