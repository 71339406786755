import React from 'react';

import { MUIFormViewer } from '@BuildHero/sergeant';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { MetaDataViewer } from './metadata';

export default function PreviewModal({ classes, meta, handleClose, modal }) {
  let modalContent = <MUIFormViewer allowToggle handleClose={handleClose} meta={meta} />;
  if (modal === 'metadata') modalContent = <MetaDataViewer meta={meta} />;
  return (
    <Dialog
      className={classes.dialogContent}
      fullWidth
      maxWidth={false}
      open
      style={{ maxWidth: 696 }}
      onClose={handleClose}
    >
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
      <DialogContent>{modalContent}</DialogContent>
    </Dialog>
  );
}
