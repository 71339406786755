import { MIXPANEL_EVENT, MIXPANEL_PAGE_NAME } from 'constants/mixpanelEvents';
import { QuoteService } from 'services/core';

import { sendMixpanelEvent } from 'services/mixpanel';

import QuotesUtils from '../../../../Quotes/index.utils';
import { quoteTransitionActions } from '../../../../Quotes/service';

import { SCOPE_AND_PRICING_TAB } from './addIncurredCosts/constants';

const getPropertyRepId = jobData => {
  const reps = jobData?.customerProperty?.customerReps?.items?.[0]?.mappedEntity;
  return reps?.id;
};

const jobToQuoteValueMapping = ({ jobData, currentProperty }) => {
  const composedPropertyDetails = QuotesUtils.composePropertyDetails(currentProperty);
  return {
    customerAndPropertyAndAddress: '',
    customerName: jobData?.customerName,
    propertyName: composedPropertyDetails?.property?.companyName,
    propertyAddress: composedPropertyDetails?.propertyAddress,
    customerId: jobData?.customerId,
    customerPropertyId: composedPropertyDetails?.property?.id,
    taxRateId: composedPropertyDetails?.property?.taxRateId,
    taxRateValue: composedPropertyDetails?.property?.taxRate?.taxRate,
    versionNumber: composedPropertyDetails?.property?.version,
    billTo: composedPropertyDetails.billTo,
    expirationLength: 30,
    ownerId: jobData?.ownerId,
    customerPoNumber: jobData?.customerProvidedPONumber || '',
    salesById: jobData?.soldById,
    orderedById: jobData?.authorizedById,
    propertyRepId: getPropertyRepId(jobData),
    departmentId: jobData?.departments?.items?.[0]?.id || '',
    departmentValue: jobData?.departments?.items?.[0]?.mappedEntityValue || '',
    priceBookId: jobData?.priceBookId,
    serviceAgreementId: jobData?.serviceAgreementId,
    accountManagerId: jobData?.accountManagerId,
    accountManagerValue: jobData?.accountManager?.name,
    detailsShownToCustomer: 'LineItemsWithGrandTotalOnly',
    issueDescription: jobData?.issueDescription || '',
    status: 'Draft'
  };
};

const addJobToQuotePayload = ({ jobData, currentProperty, user, quoteResult }) => {
  const composedPropertyDetails = QuotesUtils.composePropertyDetails(currentProperty);
  return {
    partitionKey: user.tenantId,
    action: quoteTransitionActions.ADD_JOB_FROM_QUOTE,
    data: {
      customerPropertyId: composedPropertyDetails?.property?.id,
      jobId: jobData?.id,
      quote: {
        id: quoteResult[0].id,
        version: quoteResult[0]?.version,
        totalBudgetedHours: 0,
        status: 'Draft'
      }
    }
  };
};
const updateQuotePayload = ({ jobData, currentProperty, user, quoteResult, incurredCosts }) => {
  const composedPropertyDetails = QuotesUtils.composePropertyDetails(currentProperty);
  return {
    partitionKey: user.tenantId,
    action: quoteTransitionActions.UPDATE,
    data: {
      customerPropertyId: composedPropertyDetails?.property?.id,
      jobId: jobData?.id,
      quote: {
        id: quoteResult[0].id,
        version: quoteResult[0]?.version,
        quoteLineTasks: incurredCosts
      }
    }
  };
};

const navigateToQuote = (quoteResponse, history, createdWithIncurredCosts) => {
  if (quoteResponse && quoteResponse.length > 0) {
    history.push(
      `/quote/${quoteResponse[0].id}${createdWithIncurredCosts ? SCOPE_AND_PRICING_TAB : ''}`
    );
  }
};

/*
  Helper function to add a quote from an existing job

  Service calls in order (if call succeeds, will continue to next call):
  1. addQuoteToCustomerProperty -> Creates the Quote with the job details
  2. quoteTransition with `quoteJobPayload` -> Associates the quote with the job
  3. quoteTransition with `quoteIncurredCostsPayload` -> Adds tasks to the quote selected from the job
*/
export const addQuoteFromJob = async ({
  currentProperty,
  jobData,
  history,
  user,
  companyEmployees,
  snackbar,
  incurredCosts
}) => {
  const quoteService = new QuoteService();
  const quoteValues = jobToQuoteValueMapping({ jobData, currentProperty });
  let quoteResult = null;
  try {
    history.push(`/quote/new`);
    const response = await quoteService.addQuoteToCustomerProperty(user.tenantId, quoteValues, {
      employees: companyEmployees
    });
    if (response?.data) {
      quoteResult = response.data?.addQuotesToCustomerProperty;
      snackbar(
        'success',
        `Quote created successfuly ${quoteResult[0].customIdentifier || quoteResult[0].quoteNumber}`
      );
      sendMixpanelEvent(MIXPANEL_EVENT.CREATED_QUOTE, MIXPANEL_PAGE_NAME.QUOTES);

      const quoteJobPayload = addJobToQuotePayload({
        jobData,
        currentProperty,
        user,
        quoteResult
      });
      const quoteJobResponse = await quoteService.updateQuoteUsingTransition(quoteJobPayload);
      let taskResponse;
      if (quoteJobResponse?.data?.quoteTransition) {
        if (incurredCosts) {
          const quoteIncurredCostsPayload = updateQuotePayload({
            jobData,
            currentProperty,
            user,
            quoteResult,
            incurredCosts
          });
          taskResponse = await quoteService.updateQuoteUsingTransition(quoteIncurredCostsPayload);
        }
        navigateToQuote(quoteResult, history, taskResponse);
        snackbar('success', 'Quote added to existing job successfully');
        sendMixpanelEvent(MIXPANEL_EVENT.CREATED_JOB_FROM_QUOTE, MIXPANEL_PAGE_NAME.QUOTES);
      }
    }
  } catch (error) {
    navigateToQuote(quoteResult, history);
    snackbar('error', `Unable to create quote from job: ${error}`);
  }
};

const mapToQuoteLineProduct = value => {
  return {
    priceBookEntryId: value?.priceBookEntryId,
    name: value?.itemName?.trim(),
    description: value?.description?.trim(),
    unitPrice: value?.unitPrice,
    unitCost: value?.unitCost,
    markupType: 'Percentage',
    markupValue: value?.markup,
    productId: value?.id,
    quantity: value?.quantity,
    taxable: value?.taxable,
    sortOrder: 1
  };
};

export const mapValuesToIncurredCosts = (taskGroups, partsAndMaterials) => {
  const quoteLineTasks = [];
  taskGroups?.forEach(taskGroup => {
    const quoteLineProducts = partsAndMaterials.reduce((acc, partAndMaterial) => {
      if (partAndMaterial?.taskIncludedInGroup === taskGroup.groupNumber) {
        const quoteLineProductMap = mapToQuoteLineProduct(partAndMaterial);
        acc.push(quoteLineProductMap);
      }
      return acc;
    }, []);

    const lineTask = {
      name: taskGroup?.groupName?.trim() || `Incurred Costs`,
      description: taskGroup?.groupDescription?.trim() || '',
      quoteLineProducts,
      unitPrice: null,
      unitCost: null,
      markupValue: null,
      taxable: false,
      quantity: null,
      sortOrder: 0
    };
    quoteLineTasks.push(lineTask);
  });
  return quoteLineTasks;
};

export const handleAddCostsToQuote = async (
  taskGroups,
  partsAndMaterialForm,
  setAddQuoteLoading,
  currentProperty,
  jobData,
  history,
  user,
  companyEmployees,
  snackbar
) => {
  const incurredCosts = mapValuesToIncurredCosts(taskGroups, partsAndMaterialForm);
  setAddQuoteLoading(true);
  await addQuoteFromJob({
    currentProperty,
    jobData,
    history,
    user,
    companyEmployees,
    snackbar,
    incurredCosts
  });
  setAddQuoteLoading(false);
};

export const skipCostsAndAddToQuote = async (
  setAddQuoteLoading,
  currentProperty,
  jobData,
  history,
  user,
  companyEmployees,
  snackbar
) => {
  setAddQuoteLoading(true);
  await addQuoteFromJob({
    currentProperty,
    jobData,
    history,
    user,
    companyEmployees,
    snackbar
  });
};

export const dissociateQuoteFromJob = async ({ quoteInfo, tenantId, snackbar, refetch }) => {
  const payload = {
    partitionKey: tenantId,
    action: quoteTransitionActions.DISSOCIATE_JOB_FROM_QUOTE,
    data: {
      customerPropertyId: quoteInfo?.opportunity?.property?.id,
      quote: {
        id: quoteInfo?.id,
        version: quoteInfo?.version
      }
    }
  };
  try {
    const response = await new QuoteService().updateQuoteUsingTransition(payload);
    if (response.data.quoteTransition) {
      snackbar('success', 'Quote dissociated from job successfully');
      sendMixpanelEvent(MIXPANEL_EVENT.DISSOCIATED_QUOTE_FROM_JOB, MIXPANEL_PAGE_NAME.JOB);
      refetch();
    }
  } catch (error) {
    snackbar('error', `Unable to dissociate quote from job: ${error}`);
  }
};
