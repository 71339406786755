import React, { useCallback, useMemo, useState } from 'react';

import { calculateMarginFromMarkup } from '@BuildHero/math';
import {
  Button,
  ButtonType,
  FieldType,
  Modal,
  SgtForm,
  Table,
  ThemeProvider,
  Typography
} from '@BuildHero/sergeant';
import { useDispatch, useSelector } from 'react-redux';

import { ProductWithPricingSearch } from 'components';
import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import useJobCostingOptions from 'customHooks/useJobCostingOptions';
import { snackbarOn } from 'redux/actions/globalActions';
import { logErrorWithCallback, roundFloat, toTitleCase } from 'utils';
import { AccountingApp, InvoiceItemType, Mode, PricingStrategy } from 'utils/constants';
import { convertForMathLib } from 'utils/mathLibrary';
import { getMarkupValue } from 'utils/onCalcChange';

import { dataTypeToTitle } from '../InvoiceDetail.constants';
import { separateInvoiceItems } from '../InvoiceDetail.utils';

import InvoiceItemConfig from './InvoiceItem.config';
import { useBatchUpdateInvoiceItems, useMutateInvoiceItem } from './InvoiceItem.gql';

export default function useInvoiceItems(invoice = {}, invoiceFormServiceRef) {
  const [modal, setModal] = useState({
    open: false,
    title: '',
    data: {},
    config: {},
    mode: Mode.DELETE,
    handlePrimaryAction: () => {}
  });
  const [formService, setFormService] = useState();

  const dispatch = useDispatch();
  const snackbar = useCallback((...args) => dispatch(snackbarOn(...args)), [dispatch]);

  const {
    costCodeOptions,
    jobCostTypeOptions: costTypeOptions,
    revenueTypeOptions
  } = useJobCostingOptions();

  const isVista = useSelector(s => s.settings.accountingApp) === AccountingApp.VISTA;

  const pricingStrategy = useSelector(s => s.settings.pricingStrategy);
  const showMarkupAndMargin = pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN;
  const showMargin = pricingStrategy === PricingStrategy.MARGIN || showMarkupAndMargin;
  const showMarkup = pricingStrategy === PricingStrategy.MARKUP || showMarkupAndMargin;

  const confirmContext = useConfirmModal();

  const [mutateInvoiceItem, { loading: mutateLoading }] = useMutateInvoiceItem(
    invoice.id,
    showMargin
  );
  const [batchUpdateInvoiceItems, { loading: batchUpdateLoading }] = useBatchUpdateInvoiceItems();
  const loading = mutateLoading || batchUpdateLoading;

  const InvoiceItemModal = useMemo(
    () => (
      <ThemeProvider>
        <Modal
          actions={
            <Button
              fullWidth
              loading={loading}
              type={modal.mode === Mode.DELETE ? ButtonType.ERROR : undefined}
              onClick={
                modal.mode === Mode.DELETE
                  ? () => modal.handlePrimaryAction(modal.data)
                  : formService?.formikContext?.handleSubmit
              }
            >
              {modal.mode === Mode.DELETE ? 'Delete' : 'Save'}
            </Button>
          }
          maxWidth={false}
          open={modal.open}
          title={modal.title}
          onClose={() => setModal(prev => ({ ...prev, open: false }))}
        >
          {modal.mode === Mode.DELETE ? (
            <>
              <Typography style={{ paddingBottom: 24 }}>
                Are you sure you want to remove this line item?
              </Typography>
              <Table
                columns={[
                  { id: 'name', label: 'Item Name', width: '67.487685%' },
                  {
                    id: 'quantity',
                    label: 'Quantity',
                    type: FieldType.NUMBER,
                    width: '12.807882%'
                  },
                  { id: 'amount', label: 'Subtotal', type: FieldType.CURRENCY, width: '20.689655%' }
                ]}
                data={[modal.data]}
                style={{ minWidth: 325 }}
              />
            </>
          ) : (
            <SgtForm
              configuration={modal.config}
              customComponents={{ ProductWithPricingSearch }}
              formikProps={{
                validateOnChange: false,
                validateOnBlur: true
              }}
              initialValues={modal.data}
              onCreateService={setFormService}
              onSubmit={modal.handlePrimaryAction}
            />
          )}
        </Modal>
      </ThemeProvider>
    ),
    [modal, formService, loading]
  );

  const handleInvoiceItemChange = useCallback(
    (lineItemType, updateLocalCallback, lineItem = undefined, remove = false) => {
      if (!invoice.id) return;
      setModal(prev => {
        let mode = lineItem?.id ? Mode.EDIT : Mode.ADD;
        const isQuoteItem = lineItem?.source === 'QUOTE';
        if (remove) mode = Mode.DELETE;

        const jcContractItemOptions = invoice.job?.jcContractItemOptions;

        let markupInitialVal;

        if (lineItem.unitPrice && !lineItem.markupValue) {
          markupInitialVal = showMarkup && getMarkupValue(lineItem.unitCost, lineItem.unitPrice);
        } else {
          markupInitialVal = showMarkup && lineItem.markupValue && roundFloat(lineItem.markupValue);
        }
        const marginInitialVal =
          showMargin &&
          lineItem.markupValue &&
          convertForMathLib(calculateMarginFromMarkup, lineItem.markupValue);
        // provide values for fields that are validated (so that it knows to touch them during submit)
        const data = {
          lineItemType,
          type: {
            value: lineItemType,
            label: lineItemType
          },
          name: '',
          department: invoice.department
            ? {
                label: invoice.department.tagName,
                value: invoice.department.id,
                accountingRefIdOfClass: invoice.department.accountingRefIdOfClass
              }
            : null,
          jcContractItem: jcContractItemOptions?.length === 1 ? jcContractItemOptions[0] : null,
          quantity: null,
          unitPrice: null,
          unitCost: null,
          amount: null,
          taxable: false,
          ...lineItem,
          markupValue: markupInitialVal,
          marginValue: marginInitialVal
        };

        return {
          ...prev,
          data,
          open: true,
          mode,
          title: toTitleCase(`${mode} ${dataTypeToTitle[lineItemType]}`),
          config: remove
            ? {}
            : InvoiceItemConfig({
                lineItemType,
                isQuoteItem,
                priceBookId: invoice.priceBookId,
                departmentOptions: invoice.departmentOptions,
                costCodeOptions,
                costTypeOptions,
                revenueTypeOptions,
                isVista,
                showMargin,
                showMarkup,
                jcContractItemOptions,
                disableUnitCost: lineItemType === InvoiceItemType.PUCHASE_ORDER_LINE || isQuoteItem,
                disableCostingFields: isQuoteItem
              }),
          handlePrimaryAction: async newData => {
            try {
              const {
                laborItems,
                partsAndMaterials,
                discountsAndFees
              } = invoiceFormServiceRef.current.formikContext.values;
              const invoiceItemCount =
                laborItems.length + partsAndMaterials.length + discountsAndFees.length;
              if (
                // if department is changed from previous and there are 1+ items
                (mode === Mode.EDIT &&
                  invoiceItemCount > 1 &&
                  lineItem.department?.value !== newData.department?.value) ||
                // if creating a new item and department was changed from the default and there are other items
                (mode === Mode.ADD &&
                  invoiceItemCount > 0 &&
                  invoice.department?.id &&
                  invoice.department.id !== newData.department?.value)
              ) {
                // offer to batch update all invoice items if department is changed
                const userConfirmed = await confirmContext.confirm({
                  body: (
                    <>
                      Change department to <b>{newData.department.label}</b> for all items?
                    </>
                  ),
                  title: 'Change Department for All Items',
                  buttonLabel: 'Yes',
                  cancelButton: true,
                  cancelButtonLabel: 'No'
                });
                if (userConfirmed) {
                  const updatedItems = await batchUpdateInvoiceItems({
                    invoiceId: invoice.id,
                    departmentId: newData.department.value
                  });
                  invoiceFormServiceRef.current.formikContext.setValues(values => {
                    return {
                      ...values,
                      syncStatus: null,
                      invoiceItems: updatedItems,
                      ...separateInvoiceItems(updatedItems)
                    };
                  });
                }
              }

              const result = await mutateInvoiceItem(mode, newData);
              setModal(prevModal => ({ ...prevModal, open: false }));
              updateLocalCallback({ ...newData, ...result });
            } catch (e) {
              logErrorWithCallback(
                e,
                snackbar,
                `Failed to ${mode} ${dataTypeToTitle[lineItemType]}, please try again`
              );
            }
          }
        };
      });
    },
    [
      batchUpdateInvoiceItems,
      confirmContext,
      costCodeOptions,
      costTypeOptions,
      invoice.department,
      invoice.departmentOptions,
      invoice.id,
      invoice.priceBookId,
      invoiceFormServiceRef,
      mutateInvoiceItem,
      revenueTypeOptions,
      snackbar
    ]
  );
  if (!invoice.id) return { InvoiceItemModal };

  return {
    loading,
    handleInvoiceItemChange,
    InvoiceItemModal
  };
}
