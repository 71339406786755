import { object, string } from 'yup';

import { AttachmentTypes, TaskStatus, TaskTypeInternal } from 'utils/AppConstants';

export const getValidationSchema = () =>
  object({
    property: string()
      .nullable()
      .required('Property is a required field'),
    customer: string()
      .nullable()
      .required('Customer is a required field'),
    billingCustomer: string()
      .nullable()
      .required('Billing Customer is a required field'),
    recommendation: string()
      .nullable()
      .required('Recommendation is a required field')
  });

export const composeTaskEntriesPayload = partsData => {
  const existingParts = [];
  const newParts = [];
  (partsData || []).forEach(item => {
    if (!('__typename' in item) && !('version' in item)) {
      const { marginValue, ...rest } = item;
      newParts.push(rest);
    } else {
      const { marginValue, version, __typename, ...rest } = item;
      existingParts.push(rest);
    }
  });
  return [existingParts, newParts];
};

export const composeAttachmentsPayload = attachments =>
  (attachments || []).map(item => ({
    id: item.id,
    fileName: item.fileName,
    fileUrl: item.fileUrl,
    comment: item.comment,
    type: AttachmentTypes.RECOMMENDED_TASK
  }));

export const composeFormsPayload = addedForms =>
  (addedForms || []).map(item => ({
    formDataJson: item.formDataJson,
    formDefinitionSortKey: item.formDefinitionSortKey,
    formId: item.formId,
    formSortKey: item.formSortKey
  }));

export const composeAddTaskPayload = payload => {
  const formPayload = composeFormsPayload(payload.addedForms);
  const attachmentsPayload = composeAttachmentsPayload(payload.attachments);

  return {
    assetId: payload.assetId,
    assetValue: payload.assetValue,
    attachments: attachmentsPayload,
    description: payload.description,
    formData: formPayload,
    name: payload.recommendation,
    status: TaskStatus.OPEN,
    taskTypeInternal: TaskTypeInternal.RECOMMENDED
  };
};

export const composeUpdateTaskPayload = (payload, taskEntries) => {
  const formPayload = composeFormsPayload(payload.addedForms);
  const attachmentsPayload = composeAttachmentsPayload(payload.attachments);

  return {
    assetId: payload.assetId,
    assetValue: payload.assetValue,
    attachments: attachmentsPayload,
    description: payload.description,
    formData: formPayload,
    name: payload.recommendation,
    status: TaskStatus.OPEN,
    taskEntries,
    taskTypeInternal: TaskTypeInternal.RECOMMENDED
  };
};
