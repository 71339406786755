/* eslint-disable camelcase */
import React from 'react';

import { Divider, Typography } from '@material-ui/core';

import { AddRecordButton, ResponsiveTable, SergeantModal } from 'components';
import AlgoliaSearch from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import { useLazyFetchPricebookEntry } from 'components/Tasks/components/useLazyFetchPricebookEntry';
import feeLayoutSergeant from 'meta/Jobs/Invoice/FeeFormSergeant';
import invoiceLabels from 'meta/Jobs/Invoice/labels';
import { feeRows } from 'meta/Jobs/Invoice/review-report-tables';
import { PermissionConstants } from 'utils/AppConstants';

import { useGetPricebookById } from '../../../../customHooks/useGetPricebookById';
import {
  calculateUnitPriceWithMarkupValue,
  determineMarkupValue
} from '../../../../utils/pricebooks';
import { useSetStopLoading } from '../ReviewReport/ReviewReport.hook';

import RowActions from './ReviewReportRowActions';

const Fees = ({ current, send, service, user, classes, isReviewReport }) => {
  const { context, value } = current;
  const setStopLoading = useSetStopLoading(service);

  /* Amount Calculation for Fee Items */
  const feeItems = context?.fees?.map(feeItem => {
    const fee = feeItem;
    if (fee && fee.amount) {
      fee.amount = parseFloat(fee.amount).toFixed(2);
    }
    return fee;
  });

  const feeMeta = [...feeRows];

  feeMeta.push({
    id: 'actions',
    isCustom: true,
    type: 'actions',
    label: '',
    cellStyle: {
      float: 'right'
    }
  });

  const FeeActions = ({ record }) => (
    <RowActions
      checkboxAction={event =>
        send('INCLUDE_FEE_INVOICE', {
          data: record,
          checked: event.target.checked
        })
      }
      deleteAction={() => send('DELETE_FEE', { data: record })}
      editAction={() => send('EDIT_FEE', { data: record })}
      isEditable
      record={record}
    />
  );

  const isEditable = isReviewReport && !context.freezeEdit;
  const { productFilter } = context.modalRecord;
  const filters = Object.keys(productFilter || {}).map(key => ({
    attribute: key,
    valueArray: [...productFilter[key]]
  }));
  let title = ' ';
  if (value.fee_new) title = 'Add Fee';
  if (value.fee_edited) title = 'Edit Fee';

  const fetchPricebookEntry = useLazyFetchPricebookEntry();
  const getPricebookById = useGetPricebookById();
  const handleOnItemSelection = async (selectedItem, selectedItemName, form) => {
    const { values } = form;
    if (!selectedItem)
      return form.setValues({
        ...values,
        itemName: '',
        taxable: false,
        amount: '',
        productId: ''
      });
    const markupValue = await determineMarkupValue({
      fetchPricebookEntry,
      getPricebookById,
      pricebookId: context.reviewReportPriceBookId,
      productSortKey: selectedItem.sortKey,
      unitCost: selectedItem.unitCost
    });
    const valuesToSet = {
      ...values,
      itemName: selectedItem.name,
      taxable: selectedItem.taxable,
      amount: calculateUnitPriceWithMarkupValue({
        unitCost: selectedItem.unitCost,
        markupValue
      }),
      productId: selectedItem.id
    };
    form.setValues(valuesToSet);
  };

  return (
    <>
      <Typography className={classes.sectionTitle}>{invoiceLabels.fee[user.locale]}</Typography>
      <Divider classes={{ root: classes.blueDivider }} variant="fullWidth" />
      {isEditable && <AddRecordButton handle={() => send('ADD_FEE')} label="+ Add fee" />}
      <ResponsiveTable
        caslKey={PermissionConstants.OBJECT_REVIEWREPORT}
        customCellComponents={{ actions: FeeActions }}
        data={feeItems}
        disableFilter
        disablePagination
        noDataMsg="No items"
        noMaxHeight
        rowMetadata={feeMeta}
      />
      <SergeantModal
        customComponents={{ AlgoliaSearch }}
        data={context.modalRecord}
        formVersion="edit"
        handleClose={() => send('BACK_TO_LOADED')}
        handlePrimaryAction={(values, stopLoading) => {
          setStopLoading(stopLoading);
          send('SAVE', { saveData: values });
        }}
        layout={feeLayoutSergeant(filters, handleOnItemSelection)}
        mode={value.fee_new ? 'new' : 'edit'}
        open={value?.fee_new || value?.fee_edited}
        title={title}
      />
    </>
  );
};
export default Fees;
