import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';

import watchedQueries from './watchedQueries';

export const GET_REVIEW_REPORT_BILL_LINES = gql`
  query getReviewReportBillLines(
    $columnField: String!
    $operatorValue: String!
    $value: String!
    $visitId: String
  ) {
    getBillLineListForReviewReport(
      filter: { items: { columnField: $columnField, operatorValue: $operatorValue, value: $value } }
      visitId: $visitId
    ) {
      items
    }
  }
`;

export const useReviewReportBillLines = ({ jobId, visitId }) => {
  const variables = {
    columnField: 'jobId',
    operatorValue: '=',
    value: jobId,
    visitId
  };

  watchedQueries.GET_REVIEW_REPORT_BILL_LINES = {
    query: GET_REVIEW_REPORT_BILL_LINES,
    variables
  };

  return useExtendedQuery(GET_REVIEW_REPORT_BILL_LINES, {
    variables,
    transform: data => data?.getBillLineListForReviewReport?.items || [],
    skip: !(jobId && visitId)
  });
};
