import gql from 'graphql-tag';

import useExtendedLazyQuery from 'customHooks/useExtendedLazyQuery';

const defaultFragment = `
    id
    jobNumber
    status
`;

const getJobById = (fragment = defaultFragment) => gql`
    query getJobById($partitionKey: String!, $id: String!) {
        getJobById(partitionKey: $partitionKey, id: $id) {
            ${fragment}
        }
    }
`;

const transform = data => {
  return data?.getJobById;
};

export const useLazyJobDetails = fields => {
  return useExtendedLazyQuery(getJobById(fields), {
    serializer: ({ id, partitionKey }) => ({
      variables: {
        id,
        partitionKey
      }
    }),
    fetchPolicy: 'no-cache', // since only select fields are queried not updating cache
    transform,
    defaultData: {
      getJobById: {
        id: ''
      }
    }
  });
};
