import React from 'react';

import { TW, Typography } from '@BuildHero/sergeant';
import { string } from 'prop-types';

import { useStyles } from './JobLabel.styles';

const JobLabel = ({ jobIdentifier }) => {
  const styles = useStyles();
  return (
    <Typography css={styles.label} weight={TW.BOLD}>
      Invoicing for Job {jobIdentifier}
    </Typography>
  );
};

JobLabel.propTypes = {
  jobIdentifier: string.isRequired
};

export default JobLabel;
