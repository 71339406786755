import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';

import { logErrorWithCallback } from 'utils';

const GET_ADVANCED_SCHEDULING_SERVICE_AGREEMENT_CUSTOMER_PROPERTY_METADATA = gql`
  query getAdvancedSchedulingServiceAgreementCustomerPropertyMetadata(
    $serviceAgreementId: String!
    $customerPropertyId: String!
  ) {
    getAdvancedSchedulingServiceAgreementCustomerPropertyMetadata(
      serviceAgreementId: $serviceAgreementId
      customerPropertyId: $customerPropertyId
    ) {
      customerPropertyServiceAgreementsMetadata {
        id
        department {
          id
          tagName
        }
        propertyRep {
          id
          name
        }
        primaryTech {
          id
          name
        }
        selectedCrew {
          id
          name
        }
        additionalTechs {
          id
          employee {
            id
            name
          }
        }
      }
      advancedMaintenanceTemplateGroups {
        id
        name
        description
        primaryTech {
          id
          name
        }
        additionalTechs {
          id
          employee {
            id
            name
          }
        }
        jobType {
          id
          tagName
        }
        selectedCrew {
          id
        }
        singleJobTaskTemplateId
        numberOfVisits
        budgetedHours
        jobTags {
          id
          jobTag {
            id
            tagName
          }
        }
        visitTemplates {
          id
          primaryTech {
            id
          }
          startTimeMinutesSinceMidnight
          daysBeforeDueDate
          duration
          numberOfTechs
          additionalTechs {
            id
            employee {
              id
            }
          }
          forms {
            id
            formId
          }
        }
        advancedMaintenanceTemplateGroupSchedules {
          id
          name
          firstDueDate
          numberOfAssets
          laborEstimate
        }
      }
    }
  }
`;

const useAdvancedSchedulingServiceAgreementCustomerPropertyMetadata = ({
  serviceAgreementId,
  customerPropertyId
}) => {
  const { data, loading, refetch } = useExtendedQuery(
    GET_ADVANCED_SCHEDULING_SERVICE_AGREEMENT_CUSTOMER_PROPERTY_METADATA,
    {
      variables: { serviceAgreementId, customerPropertyId },
      skip: !serviceAgreementId || !customerPropertyId,
      transform: result => result?.getAdvancedSchedulingServiceAgreementCustomerPropertyMetadata,
      onError: (err, snackbarOn) => {
        logErrorWithCallback(err, snackbarOn, 'Unable to get Service Agreement Property Metadata');
      },
      fetchPolicy: 'no-cache'
    }
  );

  return [data, loading, refetch];
};

export default useAdvancedSchedulingServiceAgreementCustomerPropertyMetadata;
