import React, { useEffect, useState } from 'react';

import { array, func, object } from 'prop-types';

import {
  EventEntities,
  JOB_COMPLETE_STATUS,
  VisitActions,
  VisitStatuses
} from '@dispatch/Dispatch.constants';

import { useFormService } from '@dispatch/hooks';
import { useDispatchSettings } from '@dispatch/settings';
import { getTenantSettingValueForKey } from 'utils';

import DrawerLayout from '../DrawerLayout';
import VisitDetailsForm from '../VisitDetailsForm';

import OnHoldReasonModal from './components/OnHoldReasonModal';
import VisitStatusHeader from './components/VisitStatusHeader';
import { extraTechsVar, primaryTechIdVar } from './EditVisit.vars';

const isOnHoldVisit = visit => visit?.status === VisitStatuses.ON_HOLD.key;

const canBeReleasedToTech = (visit, releaseToTechEnabled) =>
  releaseToTechEnabled && !visit?.detailsSent && visit?.status === VisitStatuses.SCHEDULED.key;

const shouldDisableVisitUpdate = visit => !visit.saveTransition || isOnHoldVisit(visit);

const EditVisit = ({
  closeDrawer,
  createEvent,
  crewMembersResponse,
  departmentsResponse,
  refetchVisitData,
  visitDetailsResponse,
  visitTransitionTuple
}) => {
  const { releaseToTechEnabled } = useDispatchSettings();
  const { isDirty, onCreateService, onDirtyChange, resetForm, submitForm } = useFormService();
  const [triggerVisitTransition, visitTransitionResponse] = visitTransitionTuple;
  const visitDetailsLoading = visitDetailsResponse.loading;
  const visit = visitDetailsResponse.data;
  const visitTransitionLoading = visitTransitionResponse.loading;

  const [initialVisitData, setInitialVisitData] = useState(visit);

  useEffect(() => {
    if (!visitTransitionLoading) {
      setInitialVisitData(visit);
      primaryTechIdVar(visit.primaryTechId);
    }
  }, [visit, visitTransitionLoading]);

  useEffect(() => {
    extraTechsVar(visit.extraTechs);
  }, [visit.extraTechs]);

  const crewTimeTracking = getTenantSettingValueForKey('crewTimeTracking') === 'true';

  const [crewMembersAdded, setCrewMembersAdded] = useState(false);

  useEffect(() => {
    if (crewTimeTracking && !crewMembersResponse.loading && crewMembersResponse.data) {
      const isCrewMemebers = Boolean(crewMembersResponse?.data.length);

      setCrewMembersAdded(isCrewMemebers);

      setInitialVisitData({
        ...visit,
        primaryTechId: primaryTechIdVar(),
        extraTechs: isCrewMemebers ? crewMembersResponse.data : extraTechsVar()
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crewMembersResponse, crewTimeTracking]);

  const [onHoldReasonModalOpen, setOnHoldReasonModalOpen] = useState(false);

  const handleVisitTransition = ({ transitionAction, originalVisit = visit, inputData }) => {
    if (transitionAction === VisitActions.PUT_ON_HOLD.key) {
      setOnHoldReasonModalOpen(true);
      return;
    }

    const departmentName = departmentsResponse.data?.find(
      department => department.id === inputData?.departmentId
    )?.name;

    const extraTechsNumber = inputData?.extraTechsNumber === '' ? 0 : inputData?.extraTechsNumber;

    triggerVisitTransition({
      transitionAction,
      originalVisit,
      inputData: { ...inputData, departmentName, extraTechsNumber }
    });
  };

  const handlePutOnHold = ({ onHoldReason }) => {
    triggerVisitTransition({
      transitionAction: VisitActions.PUT_ON_HOLD.key,
      originalVisit: visit,
      inputData: { onHoldReason }
    });
    closeDrawer();
  };

  const handleFormComplete = inputData => {
    handleVisitTransition({ inputData });
    closeDrawer();
  };

  const handleClone = () => {
    createEvent({
      eventType: EventEntities.VISIT.value.clientValue,
      eventData: visit
    });
  };

  const handleContinueAsNew = () => {
    createEvent({
      eventType: EventEntities.CONTINUED_VISIT.value.clientValue,
      eventData: visit
    });
  };

  const handleReleaseToTech = () => {
    return triggerVisitTransition({
      transitionAction: VisitActions.RELEASE_TO_TECHS.key,
      originalVisit: visit,
      inputData: visit
    });
  };

  const renderHeader = () => {
    return (
      <VisitStatusHeader
        handleVisitTransition={handleVisitTransition}
        visit={visit}
        visitDetailsLoading={visitDetailsLoading}
        visitTransitionLoading={visitTransitionLoading}
      />
    );
  };

  const renderBody = () => {
    return (
      <VisitDetailsForm
        crewMembersAdded={crewMembersAdded}
        crewTimeTracking={crewTimeTracking}
        disabled={visitTransitionLoading || visitDetailsLoading || !visit.saveTransition}
        showTechStatuses
        visit={initialVisitData}
        visitLoading={visitDetailsLoading}
        onComplete={handleFormComplete}
        onCreateService={onCreateService}
        onDirtyChange={onDirtyChange}
      />
    );
  };

  const canCloneVisit = visit.job.status !== JOB_COMPLETE_STATUS;

  return (
    <>
      <DrawerLayout
        disabledClose={visitTransitionLoading}
        disabledSave={shouldDisableVisitUpdate(visit)}
        isDirty={isDirty}
        loading={visitTransitionLoading || visitDetailsLoading}
        refetchVisitData={refetchVisitData}
        renderBody={renderBody}
        renderHeader={renderHeader}
        showProcurement={visit.status !== VisitStatuses.CANCELED.key}
        visit={visit}
        onClone={canCloneVisit ? handleClone : undefined}
        onContinueAsNew={isOnHoldVisit(visit) ? handleContinueAsNew : undefined}
        onReleaseToTech={
          canBeReleasedToTech(visit, releaseToTechEnabled) ? handleReleaseToTech : undefined
        }
        onResetForm={resetForm}
        onSubmitForm={submitForm}
      />
      <OnHoldReasonModal
        open={onHoldReasonModalOpen}
        setOpen={setOnHoldReasonModalOpen}
        onConfirm={handlePutOnHold}
      />
    </>
  );
};

EditVisit.propTypes = {
  closeDrawer: func.isRequired,
  createEvent: func.isRequired,
  departmentsResponse: object.isRequired,
  refetchVisitData: func,
  visitDetailsResponse: object.isRequired,
  visitTransitionTuple: array.isRequired
};

EditVisit.defaultProps = {
  refetchVisitData: () => {}
};

export default EditVisit;
