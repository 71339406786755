/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';

import { ButtonType, SplitButton, ThemeProvider } from '@BuildHero/sergeant';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SectionHeader, UserPermission } from 'components';
import ResponsiveTable from 'components/ResponsiveTable';
import StatusFilters from 'components/StatusFilter';
import Labels from 'meta/labels';
import purchaseOrdersTableMeta from 'meta/ProjectManagement/Purchasing/purchaseOrdersTable';
import ErrorBoundaries from 'scenes/Error';
import CreateFieldOrder from 'scenes/Procurement/PurchaseOrders/CreateFieldOrder';
import CreatePurchaseOrder from 'scenes/Procurement/PurchaseOrders/CreatePurchaseOrder';
import { getPurchaseOrderTags } from 'services/API/purchaseOrderTag';
import { useGetBills, useGetPurchaseOrderLines, useGetPurchaseOrders } from 'services/APIHooks';
import { PermissionConstants, ProcurementPurchaseOrderStatus } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';

import SubmenuTitle from '../../components/SubmenuTitle';

import { billRowsMeta, purchaseOrderItemRowsMeta } from './PurchasingList.layout';

const PO_MODAL = 'purchaseOrderModal';
const FO_MODAL = 'fieldOrderModal';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 17,
    '& .MuiTypography-caption': {
      color: theme.palette.grayscale(60),
      fontWeight: 400
    }
  },
  purchaseOrders: {
    paddingTop: 20,
    marginBottom: 40
  },
  table: {
    paddingBottom: 20
  },
  statusFilter: {
    marginBottom: 25
  }
}));

const PurchasingList = props => {
  const classes = useStyles();
  const { user, projectId, projectData } = props;
  const [activeModal, setActiveModal] = useState(null);

  const [tagOptions, setTagOptions] = useState([]);

  const [{ data: purchaseOrders }] = useGetPurchaseOrders(
    {
      transform: data => {
        return data.map(item => {
          const receiptCount = item.purchaseOrderReceipts?.length || 0;
          const billCount = item.purchaseOrderBills?.length || 0;

          if (item.job) {
            item.jobNumber = item.job.jobNumber;
          }
          item.receipts = `${receiptCount} ${receiptCount === 1 ? 'Receipt' : 'Receipts'}`;
          item.bills = `${billCount} ${billCount === 1 ? 'Bill' : 'Bills'}`;

          item.assignedToName = item?.assignedTo?.name || '';
          return item;
        });
      },
      depends: [projectId, !activeModal]
    },
    {
      where: { projectId },
      include: '*'
    }
  );

  const [{ data: purchaseOrderLines }] = useGetPurchaseOrderLines(
    {
      transform: lines => {
        return lines.map(line => {
          if (!line.vendorName) {
            line.vendorName = line.PurchaseOrder.vendorName;
          }
          if (line.quantity) {
            if (line.unitCost) line.totalCost = Number(line.quantity) * Number(line.unitCost);
            line.quantity = Number(line.quantity).toFixed(0);
          }
          if (line.quantityFulfilled)
            line.quantityFulfilled = Number(line.quantityFulfilled).toFixed(0);
          return line;
        });
      },
      depends: [projectId, !activeModal]
    },
    {
      where: { projectId },
      include: '*'
    }
  );

  const [{ data: bills }] = useGetBills(
    {
      depends: [projectId, !activeModal]
    },
    {
      where: { projectId },
      include: '*'
    }
  );

  useEffect(() => {
    getPurchaseOrderTags().then(purchaseOrderTags => {
      setTagOptions(
        purchaseOrderTags.map(tag => {
          return { label: tag.tagName, value: tag.id };
        })
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModal = () => {
    setActiveModal();
  };

  const createModalInitialData = {
    jobAndProject: {
      id: projectData?.id,
      projectNumber: projectData?.number,
      name: projectData?.name
    },
    projectManager: {
      id: projectData?.projectManager?.id,
      name: projectData?.projectManager?.name
    }
  };

  return (
    <ErrorBoundaries>
      <UserPermission action={PermissionConstants.OBJECT_PM_PURCHASING} I="read">
        <SubmenuTitle>Purchasing</SubmenuTitle>
        <div className={classes.root}>
          <div className={classes.purchaseOrders}>
            <ResponsiveTable
              actionPanel={() => (
                <ThemeProvider>
                  <SplitButton
                    options={[
                      {
                        label: Labels.addPurchaseOrder[user.locale],
                        onClick: () => setActiveModal(PO_MODAL)
                      },
                      {
                        label: Labels.addFieldOrder[user.locale],
                        onClick: () => setActiveModal(FO_MODAL)
                      }
                    ]}
                    type={ButtonType.PRIMARY}
                    onClick={() => setActiveModal(PO_MODAL)}
                  >
                    {Labels.addPurchaseOrder[user.locale]}
                  </SplitButton>
                </ThemeProvider>
              )}
              caslKey={PermissionConstants.OBJECT_PURCHASE_ORDER}
              data={purchaseOrders?.map(item => ({
                ...item,
                assignedToName: item?.assignedTo?.name || '-'
              }))}
              defaults={{
                sortBy: 'poNumber',
                sortOrder: 'asc'
              }}
              fullScreen
              noDataMsg="No Purchase Order Items"
              rowMetadata={purchaseOrdersTableMeta}
              tableName="purchaseOrders"
              topPanel={({ filter, setFilter }) => (
                <Box
                  alignItems="center"
                  className={classes.statusFilter}
                  display="flex"
                  flexDirection="row"
                >
                  <StatusFilters
                    filterData={ProcurementPurchaseOrderStatus}
                    filterEnumType={EnumType.PROCUMENT_PURCHASE_ORDER_STATUS}
                    onClickStatusFilter={status => {
                      setFilter({
                        ...filter,
                        status: {
                          condition: 'eq',
                          type: 'stringFilters',
                          value: status
                        }
                      });
                    }}
                  />
                </Box>
              )}
            />
          </div>
          <div className={classes.table}>
            <SectionHeader
              enablePadding
              icon="descriptionIcon"
              title={Labels.purchaseOrderItems[user.locale]}
            />
            <ResponsiveTable
              data={purchaseOrderLines || []}
              disableFilter
              noDataMsg="No Purchase Order Lines"
              rowMetadata={purchaseOrderItemRowsMeta}
              tableName="purchaseOrderLines"
            />
          </div>
          <div className={classes.table}>
            <SectionHeader enablePadding icon="descriptionIcon" title={Labels.bills[user.locale]} />
            <ResponsiveTable
              data={bills || []}
              disableFilter
              noDataMsg="No Bills"
              rowMetadata={billRowsMeta}
              tableName="bills"
            />
          </div>
        </div>
        <CreatePurchaseOrder
          associatedProject={projectData}
          handleClose={handleCloseModal}
          initialData={createModalInitialData}
          open={activeModal === PO_MODAL}
          projectId={projectId}
          tagOptions={tagOptions}
        />
        <CreateFieldOrder
          handleClose={handleCloseModal}
          initialData={createModalInitialData}
          open={activeModal === FO_MODAL}
          tagOptions={tagOptions}
        />
      </UserPermission>
    </ErrorBoundaries>
  );
};

PurchasingList.propTypes = {
  user: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  projectData: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => ({
  user: state.user,
  projectData: state.pm.project,
  projectId: props.match.params.projectId
});
const ReduxConnectedPurchasingList = connect(mapStateToProps)(PurchasingList);
export default ReduxConnectedPurchasingList;
