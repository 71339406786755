import React, { useEffect, useState } from 'react';

import { InfoWindow, Marker } from '@react-google-maps/api';

import { arrayOf, func, number, shape, string } from 'prop-types';

import VisitCard from '@dispatch/components/VisitCard';
import { VISIT_DETAILS_PROP } from '@dispatch/queries';

import { useGeocoder } from '../../MapView.hooks';

import { markerShape } from './PropertyMarker.constants';
import { useMarkerIcon } from './PropertyMarker.hooks';
import { selectAddressString } from './PropertyMarker.selectors';
import { useStyles } from './PropertyMarker.styles';

const PropertyMarker = ({ property, updateBounds }) => {
  const scale = 0.6;
  const classes = useStyles();
  const { Size } = google.maps;
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState(property.position);
  const { loading, geocode } = useGeocoder();
  const icon = useMarkerIcon({ scale, visits: property.visits });

  useEffect(() => {
    setPosition(property.position);
  }, [property.position]);

  const handlePositionUpdate = newPosition => {
    setPosition(newPosition);
    updateBounds(newPosition);
  };

  if (!(position?.lat && position?.lng)) {
    if (!loading) geocode(selectAddressString(property.address), handlePositionUpdate);
    return null;
  }

  return (
    <>
      <Marker
        icon={icon}
        label={{
          text: property.customerPropertyName,
          className: classes.label
        }}
        position={position}
        shape={markerShape}
        zIndex={20}
        onClick={() => setIsOpen(true)}
      />
      {isOpen && (
        <InfoWindow
          disableAutoPan
          options={{
            pixelOffset: new Size(0, -40 * scale)
          }}
          position={position}
          onCloseClick={() => setIsOpen(false)}
        >
          <div>
            {property.visits.map(visit => (
              <VisitCard key={visit.id} visit={visit} />
            ))}
          </div>
        </InfoWindow>
      )}
    </>
  );
};

const ADDRESS_PROP = shape({
  addressLine1: string,
  addressLine2: string,
  addressType: string,
  city: string,
  latitude: number,
  longitude: number,
  state: string,
  zipcode: string
});

PropertyMarker.propTypes = {
  property: shape({
    id: string,
    address: ADDRESS_PROP,
    customerPropertyName: string,
    position: shape({
      lat: number,
      lng: number
    }),
    visits: arrayOf(VISIT_DETAILS_PROP)
  }).isRequired,
  updateBounds: func.isRequired
};

export default PropertyMarker;
