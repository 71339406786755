import React, { useEffect } from 'react';

import { Select, ThemeProvider } from '@BuildHero/sergeant';
import { Box, Divider, Typography } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { intersection } from 'lodash';

import UserPermisson from 'components/AppPermissions';
import Placeholder from 'components/Placeholder';
import useBillingHourTypes from 'customHooks/useBillingHourTypes';
import useLabourRateGroups from 'customHooks/useLabourRateGroups';
import useLabourRateModifiers from 'customHooks/useLabourRateModifiers';
import useLabourTypes from 'customHooks/useLabourTypes';
import usePayrollHourTypes from 'customHooks/usePayrollHourTypes';
import { PermissionConstants } from 'utils/AppConstants';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import Rates from './Rates';
import useStyles from './styles';

const orderOptions = values => {
  return values && values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed));
};

const BillingRate = ({ billingRateInfo, isViewMode, isEditMode, onChange }) => {
  const classes = useStyles();
  const flags = useFlags();
  const {
    labourTypeId,
    labourRateGroupId,
    labourRateModifierIds,
    departments,
    billingRates,
    payrollCosts
  } = billingRateInfo;
  const [labourTypes, laborTypesLoading] = useLabourTypes();
  const [hourTypes, payrollHourTypesLoading] = usePayrollHourTypes({
    filter: { isActive: { eq: true }, isArchived: { eq: false } }
  });
  const [billingHourTypes, billingHourTypesLoading] = useBillingHourTypes();
  const [labourRateGroups, labourRateGroupsLoading] = useLabourRateGroups();
  const [labourRateModifiers, labourRateModifiersLoading] = useLabourRateModifiers();

  const handleOnChange = (key, value) => onChange({ ...billingRateInfo, [key]: value });
  const labourTypeOptions = constructSelectOptions(
    labourTypes.filter(labourType => !labourType.isArchived || labourTypeId === labourType.id)
  );
  const labourRateGroupOptions = constructSelectOptions(labourRateGroups);

  // using isFixed here in order to have fixed options that are default in the select
  // and cannot be removed. These are either modifiers that are applicable to all
  // employees or to employees that belong to departments with modifiers
  const isFixedModifiers = orderOptions(
    labourRateModifiers.reduce((result, modifier) => {
      const modifierWithDisplay = {
        ...modifier,
        displayName: `${modifier.name} (${modifier.modifierType === 'currency' ? '$' : '%'})`
      };
      if (modifier.appliesToCategory === 'allEmployees') {
        return [...result, { ...modifierWithDisplay, isFixed: true }];
      }

      if (modifier.appliesToCategory === 'department') {
        if (intersection(modifier.appliesTo, departments).length > 0) {
          return [...result, { ...modifierWithDisplay, isFixed: true }];
        }
        return result;
      }

      return [...result, modifierWithDisplay];
    }, [])
  );
  const labourRateModifierOptions = constructSelectOptions(isFixedModifiers, 'displayName');

  useEffect(() => {
    if (!labourTypeId && labourTypes?.length && !isEditMode) {
      onChange({ ...billingRateInfo, labourTypeId: labourTypes[0]?.id });
    }
  }, [billingRateInfo, isEditMode, labourTypeId, labourTypes, onChange]);

  useEffect(() => {
    if (!labourRateGroupId && labourRateGroups?.length && !isEditMode) {
      onChange({ ...billingRateInfo, labourRateGroupId: labourRateGroups[0]?.id });
    }
  }, [
    billingRateInfo,
    isEditMode,
    labourRateGroupId,
    labourRateGroups,
    labourRateGroups.length,
    onChange
  ]);

  const isLoading =
    laborTypesLoading ||
    payrollHourTypesLoading ||
    billingHourTypesLoading ||
    labourRateGroupsLoading ||
    labourRateModifiersLoading;
  if (isLoading) return <Placeholder />;

  const modifierValues = labourRateModifierOptions.filter(
    ({ isFixed, value }) => isFixed || labourRateModifierIds?.includes(value)
  );

  return (
    <Box display="flex" flexDirection="column" p={3}>
      <Typography variant="h5">Technician Labor Type &amp; Hour Rate Settings</Typography>
      <Divider classes={{ root: classes.blueDivider }} variant="fullWidth" />
      <ThemeProvider>
        <Box display="flex" marginTop={4}>
          <Box display="flex" flexDirection="column" width={240}>
            {flags?.[FeatureFlags.ENTERPRISE_LABOR_COSTING] && (
              <Select
                disabled={isViewMode}
                label="Labor Rate Group"
                options={labourRateGroupOptions}
                placeholder="Select Labor Rate Group"
                searchable
                style={{ marginBottom: 16 }}
                value={labourRateGroupOptions.find(option => option.value === labourRateGroupId)}
                onChange={newLabourRateGroup =>
                  handleOnChange('labourRateGroupId', newLabourRateGroup.value)
                }
              />
            )}
            <Select
              disabled={isViewMode}
              label="Labor Type"
              options={labourTypeOptions}
              placeholder="Select Labor Type"
              searchable
              style={{ marginBottom: 16 }}
              value={labourTypeOptions.find(option => option.value === labourTypeId)}
              onChange={newLabourType => handleOnChange('labourTypeId', newLabourType.value)}
            />
            {flags?.[FeatureFlags.ENTERPRISE_LABOR_COSTING] && (
              <Select
                css={{ width: '100%' }}
                disabled={isViewMode}
                isClearable={false}
                label="Labor Rate Modifiers"
                multi
                options={labourRateModifierOptions}
                placeholder="Select Labor Rate Modifiers"
                searchable
                styles={{
                  multiValueRemove: (base, state) => {
                    return state.data.isFixed ? { ...base, display: 'none' } : base;
                  },
                  valueContainer: base => ({ ...base, padding: 0 }),
                  indicatorsContainer: base => ({ ...base, height: 24, width: 30 })
                }}
                value={modifierValues}
                onChange={newLabourRateModifiers =>
                  handleOnChange(
                    'labourRateModifierIds',
                    newLabourRateModifiers
                      .filter(({ isFixed }) => !isFixed)
                      .map(({ value }) => value)
                  )
                }
              />
            )}
          </Box>
          <UserPermisson
            action={PermissionConstants.DATA_VIEW_EDIT_EMPLOYEE_HOURLY_RATES}
            I="allow"
          >
            <Rates
              dataKey="cost"
              existingRates={payrollCosts}
              hourTypes={hourTypes}
              isViewMode={isViewMode}
              title="Payroll Hour Rates"
              onChange={values => handleOnChange('payrollCosts', values)}
            />
            <Rates
              dataKey="rate"
              existingRates={billingRates}
              hourTypes={billingHourTypes}
              isViewMode={isViewMode}
              title="Billing Hour Rates"
              onChange={values => handleOnChange('billingRates', values)}
            />
          </UserPermisson>
        </Box>
      </ThemeProvider>
    </Box>
  );
};

export default BillingRate;
