import React from 'react';

import { Button, TV, Typography, TypographyWeight } from '@BuildHero/sergeant';
import { func } from 'prop-types';

import { useReviewReportDisabled } from '../../../../ReviewReport.contexts';

import { useStyles } from './DiscountsSectionHeader.styles';

const DiscountsSectionHeader = ({ onAddDiscounts }) => {
  const styles = useStyles();
  const { disabled } = useReviewReportDisabled();

  return (
    <div css={styles.header}>
      <Typography style={{ marginBottom: 8 }} variant={TV.L} weight={TypographyWeight.BOLD}>
        Discounts
      </Typography>
      <Button disabled={disabled} size="small" type="secondary" onClick={onAddDiscounts}>
        Add Discount
      </Button>
    </div>
  );
};

DiscountsSectionHeader.propTypes = {
  onAddDiscounts: func.isRequired
};

export default DiscountsSectionHeader;
