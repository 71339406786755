import React, { useCallback, useEffect, useState } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import { Divider, Typography } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import * as Yup from 'yup';

import { DefaultButton, FullScreenModal } from 'components';
import PreferredTechniciansFormLayoutComponent from 'components/PreferredTechniciansForm/PreferredTechniciansFormLayoutComponent';

import { maintenanceFrequency } from 'constants/common';
import useEmployees from 'customHooks/useEmployees';
import Labels from 'meta/labels';
import { MaintenanceForm } from 'meta/ServiceAgreements/MaintenanceTemplateLayout';
import { snackbarOn } from 'redux/actions/globalActions';
import { getTenantSettingValueForKey, isTenantSettingEnabled } from 'utils';

import { CustomFieldTypes } from 'utils/constants';
import { constructSelectOptions } from 'utils/constructSelectOptions';

import { getDepartments, useCrews } from '../../../../helpers';
import { defaultMaintenanceData } from '../../constants';

import MaintenanceTasks from './MaintenanceTasks';
import { getAssetOptionsForProperty } from './services';
import useStyles from './styles';

const getPreferredTechnicians = (maintenanceTemplate, serviceAgreement) => {
  const preferredTechniciansEntity = maintenanceTemplate?.id
    ? maintenanceTemplate?.preferredTechnicians
    : serviceAgreement?.preferredTechnicians;
  return _.pick(preferredTechniciansEntity, [
    'departmentId',
    'crewId',
    'primaryTechnicianId',
    'additionalTechnicianIds'
  ]);
};

const MaintenanceTemplate = props => {
  const [formService, setFormService] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assetOptions, setAssetOptions] = useState([]);
  const [isLoadingAssets, setIsLoadingAssets] = useState(false);
  const {
    open,
    user,
    company,
    snackbarOn: snackbar,
    selectedProperties,
    handleClose,
    onSaveTemplate,
    data,
    agreementInfo,
    serviceAgreementsSettings
  } = props;
  const classes = useStyles();
  const { jobTags, jobTypes } = company;
  const showTotalBudgetedHours = isTenantSettingEnabled('budgetLaborHoursAtJobLevel');
  const crewTimeTracking = getTenantSettingValueForKey('crewTimeTracking') === 'true';

  const handlePropertyChange = useCallback(
    async option => {
      setIsLoadingAssets(true);
      const customerProperties = await getAssetOptionsForProperty({
        id: option.value,
        partitionKey: user.tenantId,
        snackbar
      });
      const options = customerProperties.map(({ id, assetName, assetTypeId, isActive }) => ({
        label: assetName,
        value: id,
        assetTypeId,
        isActive
      }));
      setAssetOptions(_.sortBy(options, 'label'));
      setIsLoadingAssets(false);
    },
    [snackbar, user.tenantId]
  );

  useEffect(() => {
    if (data?.propertyId) handlePropertyChange({ value: data?.propertyId });
  }, [handlePropertyChange, data.propertyId]);

  const jobTagOptions = constructSelectOptions(_.sortBy(jobTags?.items, 'sortOrder'), 'tagName');
  const maintenanceFrequencyOptions = Object.entries(maintenanceFrequency).map(([key, value]) => ({
    label: value,
    value: key
  }));
  const maintenanceTypes = _.sortBy(
    jobTypes.items?.filter(type => type.tagType === CustomFieldTypes.MaintenanceTypes),
    'sortOrder'
  );
  const maintenanceTypeOptions = constructSelectOptions(maintenanceTypes, 'tagName');

  const handleOnComplete = async templateData => {
    setIsSubmitting(true);
    const { tags = [] } = templateData;
    const modifiedTags = (jobTags?.items || []).reduce((acc, tag) => {
      if (tags.includes(tag.id)) {
        acc.push({ id: tag.id, tagName: tag.tagName, tagType: tag.tagType });
      }
      return acc;
    }, []);
    await onSaveTemplate({ ...templateData, tags: modifiedTags });
    setIsSubmitting(false);
  };

  const modalHeaderButtons = [
    <DefaultButton
      color="primary"
      disabled={isSubmitting}
      key="newmaintenance"
      label={props?.data?.id ? Labels.updateRecord[user.locale] : Labels.createRecord[user.locale]}
      showSpinner={isSubmitting}
      onClick={() => formService?.submit()}
    />
  ];

  const Title = ({ options }) => (
    <Typography className={classes.title} variant="subTitle">
      {options.label}
    </Typography>
  );

  const Subtitle = ({ options }) => (
    <Typography className={classes.subtitle} variant="subTitle">
      {options.label}
    </Typography>
  );

  const reshapeForForm = formData => {
    if (formData == null) return null;
    return {
      ...formData,
      tags: (formData?.tags || []).map(({ id }) => id)
    };
  };

  const getStartOfDayUnixSeconds = number =>
    moment
      .unix(number)
      .startOf('day')
      .unix();

  const templateValidation = Yup.object().shape({
    propertyId: Yup.string().required('Property is required'),
    firstDueDate: Yup.string()
      .nullable()
      .test('startingDateValidation', 'N/A', function(val) {
        if (Number(val) < moment('1900-1-1').unix()) {
          return this.createError({ message: 'First due date must be after January 1, 1900' });
        }
        if (Number(val) > moment('3000-1-1').unix()) {
          return this.createError({ message: 'First due date must be before January 1, 3000' });
        }
        if (
          getStartOfDayUnixSeconds(Number(val)) < getStartOfDayUnixSeconds(agreementInfo.startDate)
        ) {
          return this.createError({
            message: 'Maintenance First due date should not be earlier than contract start date'
          });
        }

        if (
          agreementInfo.endDate &&
          getStartOfDayUnixSeconds(Number(val)) > getStartOfDayUnixSeconds(agreementInfo.endDate)
        ) {
          return this.createError({
            message: 'Maintenance First due date should not be later than contract end date'
          });
        }
        return true;
      }),
    endDate: Yup.string()
      .nullable()
      .test('endDateValidation', 'N/A', function(val) {
        // if value is not defined, dont validate
        if (!val) return true;
        if (Number(val) < moment('1900-1-1').unix()) {
          return this.createError({ message: 'End date must be after January 1, 1900' });
        }
        if (Number(val) > moment('3000-1-1').unix()) {
          return this.createError({ message: 'End date must be before January 1, 3000' });
        }
        if (
          getStartOfDayUnixSeconds(Number(val)) < getStartOfDayUnixSeconds(agreementInfo.startDate)
        ) {
          return this.createError({
            message: 'Maintenance end date should not be earlier than contract start date'
          });
        }

        if (
          agreementInfo.endDate &&
          getStartOfDayUnixSeconds(Number(val)) > getStartOfDayUnixSeconds(agreementInfo.endDate)
        ) {
          return this.createError({
            message: 'Maintenance end date should not be later than contract end date'
          });
        }

        if (Number(val) < Number(this.parent.firstDueDate)) {
          return this.createError({
            message: 'Maintenance end date should not be earlier than Maintenance start date'
          });
        }
        return true;
      })
  });

  const departments = getDepartments();
  const [technicians] = useEmployees({
    includeDepartments: true,
    filter: { isActive: { eq: true }, isTech: { eq: true } }
  });
  const crews = useCrews();

  const showPreferredTechnicians =
    serviceAgreementsSettings?.enableTechnicianSelectionAtMaintenanceTemplateLevel;
  const formData = {
    ...(reshapeForForm(data) || defaultMaintenanceData),
    numberOfTechs: data?.numberOfTechs || 1,
    preferredTechnicians: showPreferredTechnicians
      ? getPreferredTechnicians(data, agreementInfo)
      : undefined
  };

  return (
    <FullScreenModal
      fixedHeader
      handleClose={handleClose}
      modalHeaderButtons={modalHeaderButtons}
      open={open}
      title={
        props?.data?.id
          ? Labels.editMaintenanceTemplate[user.locale]
          : Labels.newMaintenanceTemplate[user.locale]
      }
    >
      <MUIForm
        configuration={MaintenanceForm({
          jobTagOptions,
          assetOptions,
          propertyOptions: selectedProperties,
          maintenanceFrequencyOptions,
          handlePropertyChange,
          isLoadingAssets,
          agreementInfo,
          maintenanceTypeOptions,
          departments,
          crews,
          technicians,
          showPreferredTechnicians,
          setAssetOptions,
          showTotalBudgetedHours,
          crewTimeTracking
        })}
        customComponents={{
          Title,
          Subtitle,
          Divider,
          MaintenanceTasks,
          PreferredTechniciansFormLayoutComponent: componentProps => (
            <PreferredTechniciansFormLayoutComponent
              hideExtraTechNumber
              parentFormService={formService}
              {...componentProps}
            />
          )
        }}
        data={formData}
        layout="edit"
        validationSchema={templateValidation}
        onComplete={handleOnComplete}
        onCreateService={service => setFormService(service)}
      />
    </FullScreenModal>
  );
};

MaintenanceTemplate.propTypes = {
  user: PropTypes.shape({
    tenantId: PropTypes.string,
    tenantCompanyId: PropTypes.string
  }).isRequired,
  serviceAgreementsSettings: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  company: state.company
});
const mapDispatcherToProps = { snackbarOn };

export default connect(mapStateToProps, mapDispatcherToProps)(MaintenanceTemplate);
