import React from 'react';

import { Avatar, Box, IconButton, Typography } from '@material-ui/core';
import BackArrowIcon from '@material-ui/icons/ArrowBack';
import classNames from 'classnames';
import parse from 'html-react-parser';

import moment from 'moment';

import Attachments from 'scenes/ProjectManagement/components/Attachments';
import { getInitials } from 'scenes/ProjectManagement/components/utils';

import useStyles from './Message.styles';

const Message = props => {
  const { messageData, user, onOpen, onClose, showContent } = props;

  const classes = useStyles();

  const EmailContents = () => {
    const { recipients } = messageData;
    const body = parse(messageData.emailContent.split('\n').join('<br/>'));

    const getRecipientAvatar = recipient => {
      if (recipient?.profilePictureUrl) {
        return (
          <Avatar
            alt={`${recipient.customerName || recipient.name}`}
            className={classes.profilePic}
            src={recipient.profilePictureUrl}
          />
        );
      }
      if (recipient.customerName || recipient.name) {
        return (
          <Avatar className={classes.profilePic}>
            {getInitials(recipient.customerName?.split(' ') || recipient.name?.split(' ') || [])}
          </Avatar>
        );
      }
      return <div />;
    };

    return (
      <div className={classes.messageDetails}>
        <div className={classes.subjectContainer}>
          <IconButton aria-label="Back" className={classes.button} onClick={onClose}>
            <BackArrowIcon className={classes.backIcon} />
          </IconButton>
          <Typography className={classes.title}>{messageData.subject}</Typography>
        </div>
        {recipients?.length ? (
          <div className={classes.flexList} style={{ marginBottom: 10 }}>
            {recipients?.map((recipient, index) => {
              return (
                <div className={classes.flexList}>
                  {getRecipientAvatar(recipient)}
                  <Typography className={classes.nameplate}>
                    {`${recipient.customerName || recipient.name || ''} <${recipient.email}>`}
                    {index !== recipients?.length - 1 && ', '}
                  </Typography>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={classes.gridRow}>
            <Avatar className={classes.profilePic} />
            <Typography className={classes.nameplate}>-</Typography>
          </div>
        )}
        <div className={classes.gridRow}>
          <div />
          <div className={classes.bodyContainer}>
            <div className={classes.body}>
              <Typography>{body}</Typography>
            </div>
          </div>
        </div>
        <div className={classes.attachmentsContainer}>
          <Attachments
            attachedFileCount={messageData.attachments?.length || 0}
            attachedFiles={
              messageData.attachments
                ? messageData.attachments.map(element => {
                    return { ...element, fileType: element.type };
                  })
                : []
            }
            chunkSize={5}
            downloadable
            imageHeight={100}
            // TODO: check this upload function
            imageWidth={100}
            noAddButton
            noTitle
            uploadFileList={() => {}}
          />
        </div>
      </div>
    );
  };

  return showContent ? (
    EmailContents()
  ) : (
    <Box
      className={classNames(
        classes.root,
        showContent ? classes.showContent : '',
        messageData?.read ? classes.messageRead : classes.messageUnread
      )}
      onClick={onOpen}
    >
      <Typography>{!messageData.inReplyTo ? user.displayName : messageData.from}</Typography>
      <Typography>{messageData?.subject}</Typography>
      <Typography>
        {messageData?.date && moment(messageData?.date).format('MMM DD, YYYY')}
      </Typography>
    </Box>
  );
};

export default Message;
