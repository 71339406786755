import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { useSnackbar } from 'customHooks/useSnackbar';
import { logErrorWithCallback } from 'utils';

export const updateTimesheetEntryBindersMutation = gql`
  mutation bulkUpdateTimesheetEntryBinders(
    $partitionKey: String
    $data: UpdateTimesheetEntryBindersInput!
  ) {
    bulkUpdateTimesheetEntryBinders(partitionKey: $partitionKey, data: $data) {
      id
      version
      manualStatus
      manualApprovedBy
      manualApprovedDate
      manualReopenReason
      jobStartTime
      jobEndTime
      timesheetEntries {
        items {
          id
          version
          timesheetPeriodId
          actualTotalDuration
          actualTotalDurationOverride
          billableEntityType
          reviewStatus
          manualStatus
          manualApprovedBy
          manualApprovedDate
          manualReopenReason
        }
      }
    }
  }
`;

const serializer = ({ employee, timesheetEntryBinders }) => {
  const { tenantId } = employee;
  return {
    variables: {
      partitionKey: tenantId,
      data: {
        timesheetEntryBinders: timesheetEntryBinders.map(e => {
          const { extra, ...props } = e;
          return props;
        })
      }
    }
  };
};

const useUpdateTimesheetEntryBinders = () => {
  const snackbar = useSnackbar();

  return useExtendedMutation(updateTimesheetEntryBindersMutation, {
    serializer,
    // TODO: add caching
    onError: error => {
      logErrorWithCallback(error, snackbar, 'Unable to update time entry binders');
    },
    onCompleted: () => {
      snackbar('success', 'Updated timesheet binder successfully');
    }
  });
};

export default useUpdateTimesheetEntryBinders;
