import React, { useCallback, useRef, useState } from 'react';

import { Button, ThemeProvider } from '@BuildHero/sergeant';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SendIcon from '@material-ui/icons/Send';

import AddToFiles from '@pm/components/AddToFiles';
import { FullScreenModal } from 'components';
import WrapTable from 'components/WrapTable';

import {
  useGetNextPayApplicationNumber,
  usePayApplicationSummaryByProject,
  usePutPayApplication
} from 'services/APIHooks';

import { BuildHeroThemeProvider } from 'themes/BuildHeroTheme';
import { InvoiceStatus } from 'utils/constants';

import GeneratePayApplication from './GeneratePayApplication';
import GeneratePdfModal from './GeneratePdfModal';
import { getPayApplicationNumber } from './GeneratePdfModal/utils';
import PayApplicationEmail from './PayApplicationEmail';
import { usePayApplicationColumns } from './PayApplicationList.columns';
import {
  useInitialData,
  useLastPayApplication,
  useLinkedPayApplications,
  useVoidPayAppInvoice
} from './PayApplicationList.hooks';
import { getCanvasAttachments } from './PayApplicationList.serializer';
import { useStyles } from './PayApplicationList.styles';

import PayApplicationShow from './PayApplicationShow';
import { generatePayApplicationPdf } from './PayApplicationsList.helpers';

const SgtButton = props => (
  <ThemeProvider>
    <Button {...props} />
  </ThemeProvider>
);

const ModalMode = {
  ADD: 'ADD',
  EDIT: 'EDIT'
};

const PayApplicationList = ({ user, project, projectId, refetchProjectDetails, snackbar }) => {
  const styles = useStyles();
  const [generateModalPayApp, setGenerateModalPayApp] = useState(null);
  const [viewModalPayApp, setViewModalPayApp] = useState(null);
  const [pdfModalPayApp, setPdfModalPayApp] = useState(null);
  const [emailModalPayApp, setEmailModalPayApp] = useState(null);
  const modalMode = useRef();
  const isEdit = modalMode.current === ModalMode.EDIT;

  const attachments = viewModalPayApp?.PayApplication?.PayApplicationAttachment || [];

  const [payApplicationsResponse, refetchPayAppSummaries] = usePayApplicationSummaryByProject(
    { projectId },
    {
      defaultData: []
    }
  );

  const linkedPayApplications = useLinkedPayApplications({
    payApplications: payApplicationsResponse.data
  });

  const lastPayApplication = useLastPayApplication({
    payApplications: payApplicationsResponse.data
  });

  const [nextPayAppNumberResponse, refetchNextPayApp] = useGetNextPayApplicationNumber(
    projectId,
    {}
  );

  const [putPayAppResponse, putPayApp] = usePutPayApplication({
    onSuccess: () => {
      refetchPayAppSummaries();
      refetchProjectDetails();
    }
  });

  const initialData = useInitialData({
    isEdit,
    generateModalPayApp,
    nextPayAppNumberResponse,
    lastPayApplication,
    project
  });

  const handleEditPayAppClick = useCallback(payApplication => {
    modalMode.current = ModalMode.EDIT;
    setGenerateModalPayApp(payApplication);
  }, []);

  const handleAddPayAppClick = useCallback(() => {
    modalMode.current = ModalMode.ADD;
    setGenerateModalPayApp(true);
  }, []);

  const handleGenerateCloseModal = useCallback(() => {
    setGenerateModalPayApp(null);
  }, []);

  const handleViewPayAppClick = useCallback(payApplication => {
    setViewModalPayApp(payApplication);
  }, []);

  const handleCloseViewModal = useCallback(() => {
    refetchPayAppSummaries();
    setViewModalPayApp(null);
  }, []);

  const handlePreviewPdfClick = useCallback(payApplication => {
    getCanvasAttachments(payApplication).then(canvasAttachments =>
      setPdfModalPayApp({
        ...payApplication,
        canvasAttachments
      })
    );
  }, []);

  const handleClosePdfModal = useCallback(() => {
    setPdfModalPayApp(null);
  }, []);

  const handlePreviewEmailClick = useCallback(payApplication => {
    getCanvasAttachments(payApplication).then(canvasAttachments =>
      setEmailModalPayApp({
        ...payApplication,
        canvasAttachments
      })
    );
  }, []);

  const handleCloseEmailModal = useCallback(() => {
    setEmailModalPayApp(null);
  }, []);

  const handleSavePayAppSuccess = useCallback(() => {
    refetchPayAppSummaries();
    refetchNextPayApp();
  }, [refetchPayAppSummaries, refetchNextPayApp]);

  const [voidInvoice] = useVoidPayAppInvoice();

  const handleVoidPayAppClick = useCallback(
    async payApplication => {
      if (payApplication.appliedAmount > 0) {
        snackbar(
          'error',
          'This Pay Application has Received Payments against it and cannot be VOIDED'
        );
      } else {
        // run graphql in deployment to handle any accounting sync
        await voidInvoice(payApplication.PayApplication.Invoice);

        refetchPayAppSummaries();
      }
    },
    [putPayApp, snackbar, voidInvoice]
  );

  const handleAttachmentUpdate = updatedAttachments =>
    setViewModalPayApp(payApp => ({
      ...payApp,
      PayApplication: {
        ...payApp.PayApplication,
        PayApplicationAttachment: updatedAttachments
      }
    }));

  const columns = usePayApplicationColumns({
    handleEditPayAppClick,
    handleViewPayAppClick,
    handlePreviewPdfClick,
    handlePreviewEmailClick,
    handleVoidPayAppClick
  });

  const getPayAppPdfFile = useCallback(async () => {
    const canvasAttachments = await getCanvasAttachments(viewModalPayApp);
    const payAppSummary = {
      ...(viewModalPayApp || {}),
      canvasAttachments
    };
    return generatePayApplicationPdf({
      payAppSummary,
      project
    });
  }, [project, viewModalPayApp]);
  return (
    <ThemeProvider>
      <div css={styles.container}>
        <Button
          css={styles.addButton}
          disabled={payApplicationsResponse.loading}
          size="small"
          startIcon={<AddCircleOutlineIcon />}
          onClick={handleAddPayAppClick}
        >
          Generate Pay Application
        </Button>
        <WrapTable
          columns={columns}
          error={payApplicationsResponse.error}
          loading={payApplicationsResponse.loading}
          noDataMessage="No pay applications generated"
          rows={linkedPayApplications}
        />
      </div>
      <GeneratePayApplication
        initialData={initialData}
        open={Boolean(generateModalPayApp)}
        paNumber={isEdit ? generateModalPayApp?.number : nextPayAppNumberResponse?.data}
        payApplicationId={generateModalPayApp?.id}
        previousPeriodTo={
          generateModalPayApp?.previousPayApplication?.PayApplication?.periodTo ||
          (isEdit ? null : lastPayApplication?.periodTo)
        }
        title={isEdit ? 'Edit Pay Application' : 'Add Pay Application'}
        onClose={handleGenerateCloseModal}
        onSuccess={handleSavePayAppSuccess}
      />
      <BuildHeroThemeProvider>
        <FullScreenModal
          handleClose={handleCloseViewModal}
          modalHeaderButtons={[
            <AddToFiles getAttachmentFile={getPayAppPdfFile} />,
            <SgtButton
              css={{ marginRight: 8 }}
              type="tertiary"
              onClick={() => handlePreviewPdfClick(viewModalPayApp)}
            >
              Preview
            </SgtButton>,
            <SgtButton
              disabled={viewModalPayApp?.status !== InvoiceStatus.DRAFT}
              type="secondary"
              onClick={() => handleEditPayAppClick(viewModalPayApp)}
            >
              Edit
            </SgtButton>
          ]}
          open={Boolean(viewModalPayApp)}
          title={`Pay Application ${getPayApplicationNumber(viewModalPayApp?.PayApplication)}`}
        >
          <PayApplicationShow
            emailButton={
              <SgtButton
                css={{ marginRight: 8 }}
                disabled={viewModalPayApp?.status === InvoiceStatus.VOID}
                startIcon={<SendIcon />}
                onClick={() => handlePreviewEmailClick(viewModalPayApp)}
              >
                Email
              </SgtButton>
            }
            payApplication={viewModalPayApp?.PayApplication}
            payApplicationId={viewModalPayApp?.id}
            projectId={projectId}
            onAttachmentUpdate={handleAttachmentUpdate}
          />
        </FullScreenModal>
      </BuildHeroThemeProvider>
      <GeneratePdfModal
        open={Boolean(pdfModalPayApp)}
        payAppSummary={pdfModalPayApp}
        project={project}
        onClose={handleClosePdfModal}
      />

      <PayApplicationEmail
        handleClose={handleCloseEmailModal}
        open={Boolean(emailModalPayApp)}
        payAppSummary={emailModalPayApp}
        project={project}
        user={user}
      />
    </ThemeProvider>
  );
};

export default PayApplicationList;
