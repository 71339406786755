import React, { Fragment, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import diff from 'deep-diff';

import { LinkButton } from 'components';
import entityRouteMappings from 'meta/entityRouteMappings';
import { camelCaseToTitleCase, flattenObject } from 'utils';

import getChangesFromLog from './getChangesFromLog';

const useStylesPrimitive = makeStyles({
  text: {
    fontWeight: 'bold'
  }
});
// Displays a single primitive change (e.g. 'changed number from 0 to 1') on a single line.
export function SingleChangePrimitive(props) {
  function formatValue(value) {
    switch (typeof value) {
      case 'number':
        return value.toString();

      case 'boolean':
        return value ? 'true' : 'false';

      default: {
        let retVal = value ?? 'Empty';
        if (typeof retVal === 'object') {
          retVal = JSON.stringify(retVal, 0, 2);
        }
        return retVal;
      }
    }
  }

  const classes = useStylesPrimitive();
  const accentClass = classes.text;

  const { field, hideChanges } = props.data || {};
  if (!field) return <span>-</span>;

  // TODO: Record human-readable names for changed values instead of IDs and sortKeys
  // or use `get[EntityType]ById` query methods to convert IDs and sortKeys to names.
  // For now, just don't show these changes since they aren't much help to platform users.
  const showValues =
    !hideChanges &&
    !field.toLowerCase().includes(' id') &&
    !field.toLowerCase().includes('sort key');
  const changedValue = {
    from: formatValue(props.data.old),
    to: formatValue(props.data.new)
  };

  return props.textOnly ? (
    <>{`Changed field ${field}${
      showValues ? ` from ${changedValue.from} to ${changedValue.to}` : ''
    }`}</>
  ) : (
    <>
      Changed field <span className={accentClass}>{field}</span>
      {showValues && (
        <>
          {' '}
          from <span className={accentClass}>{changedValue.from}</span> to{' '}
          <span className={accentClass}>{changedValue.to}</span>
        </>
      )}
      <br />
    </>
  );
}

// For complex object changes (e.g. Settings), do a recursive deep object diff
// and list each change in a way intelligible to the user.
function SingleChangeObject(props) {
  function parseIfObject(item) {
    if (typeof item !== 'object') return item;
    const flattened = flattenObject(item);
    return Object.keys(flattened)
      .map(currentKey => `${currentKey}: ${flattened[currentKey]}`)
      .join(', ');
  }

  // See https://github.com/flitbit/diff for documentation and diff data structure
  function parseDiffArray(difference) {
    const pathDelimiter = '-';
    const actionTypeByAbbreviation = { N: 'Added', D: 'Deleted', E: 'Changed' };
    return difference.map(diffItemWithArray => {
      let diffItem = { ...diffItemWithArray };
      // 'A' means 'N', 'D', or 'E' modification was made in an array
      if (diffItemWithArray.kind === 'A') {
        diffItem = { ...diffItem, ...diffItem.item };
      }
      const action = actionTypeByAbbreviation[diffItem.kind];
      const fieldName = camelCaseToTitleCase(diffItem.path?.join(` ${pathDelimiter} `));
      let change = '';
      const rhs = parseIfObject(diffItem.rhs);
      const lhs = parseIfObject(diffItem.lhs);
      switch (diffItem.kind) {
        // 'N' means new field was added
        case 'N':
          change = diffItem.rhs ? (
            <>
              with value <b>{rhs}</b>
            </>
          ) : null;
          break;
        // 'D' means field was deleted
        case 'D':
          change = diffItem.lhs ? (
            <>
              with value <b>{lhs}</b>
            </>
          ) : null;
          break;
        // 'E' means field was edited
        case 'E':
        default:
          change =
            diffItem.lhs && diffItem.rhs ? (
              <>
                from <b>{lhs}</b> to <b>{rhs}</b>
              </>
            ) : null;
          break;
      }
      const index = diffItem.index ? (
        <>
          {' '}
          at position <b>{diffItem.index}</b> in list
        </>
      ) : null;
      return {
        action,
        fieldName,
        change,
        index,
        key: `${rhs}-${lhs}`
      };
    });
  }

  const { field } = props.data;
  const { oldObject, newObject } = props;
  const deepDiff = diff(oldObject, newObject);
  if (!Array.isArray(deepDiff) || deepDiff.length === 0)
    return (
      <>
        Modified {field}{' '}
        {props.data.newParent ? (
          <span>
            (<b>{props.data.newParent}</b>)
          </span>
        ) : (
          ''
        )}
        <br />
      </>
    );
  const diffArray = parseDiffArray(deepDiff);
  return (
    <>
      <i>Made the following modifications in {field}:</i>
      <br />
      {diffArray.map(item => (
        <Fragment key={`diffArray-${item.action}-${item.fieldName}-${item.key}`}>
          {item.action} field <b>{item.fieldName}</b> {item.change}
          {item.index}
          <br />
        </Fragment>
      ))}
    </>
  );
}

function SingleChange(props) {
  const { data } = props;
  let isObjectOrJson = false;
  let oldObject;
  let newObject;
  try {
    if (typeof data.old === 'string' && typeof data.new === 'string') {
      oldObject = JSON.parse(data.old);
      newObject = JSON.parse(data.new);
      isObjectOrJson = true;
    } else if (typeof data.old === 'object' && typeof data.new === 'object') {
      oldObject = data.old;
      newObject = data.new;
      isObjectOrJson = true;
    }
  } catch (e) {
    // Usually exception based control flow is bad, but this is the best way to easily check
    // if a string object has JSON structure.
    // https://stackoverflow.com/questions/9804777/how-to-test-if-a-string-is-json-or-not
  }
  if (isObjectOrJson && oldObject && newObject)
    return <SingleChangeObject data={data} newObject={newObject} oldObject={oldObject} />;
  return <SingleChangePrimitive data={data} />;
}

function getRoute(routeData) {
  let { type, id } = routeData;
  const { name, sortKey, parentType, parentId, parentName } = routeData;

  // Entities that don't have their own routes; should use route of parent entity
  const parentRouteTypes = [
    'Note',
    'Discount',
    'Summary',
    'Attachments',
    'InventoryPart',
    'PurchaseOrder',
    'PurchaseOrderNumber',
    'CustomerSignature',
    'TaxRate',
    'TechnicianNote'
  ];
  if (parentRouteTypes.includes(type)) {
    type = parentType || '';
    id = parentId || '';
  }

  const baseRoute = entityRouteMappings[type];
  if (!baseRoute) return undefined;

  switch (type) {
    case 'Job': {
      // When a job has a custom number, it is appended as [standardNumber]~[customNumber].
      // The custom number is not included in the corresponding job's route.
      const jobNumber = name.split('~');
      // Use name (job number) instead of id.
      return baseRoute + (jobNumber[0] || '');
    }

    case 'Visit': {
      // Visits are also handled in a special case because their parent entity (Job) is a special case.
      const jobNumber = parentName?.split('~');
      return baseRoute + (jobNumber?.[0] || '');
    }

    default: {
      // Entities that don't have IDs included in their routes
      const routeWithoutIdTypes = [
        'CompanyAddress',
        'Employee',
        'CustomerTag',
        'Vendor',
        'PriceBook'
      ];
      if (routeWithoutIdTypes.includes(baseRoute)) return baseRoute;
      return baseRoute + id;
    }
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  inlineButton: {
    // To align properly by overriding Mui Button Base
    verticalAlign: 'unset',
    marginLeft: theme.spacing(0.5)
  },
  marginRight: {
    marginRight: theme.spacing(0.5)
  },
  wrap: {
    whiteSpace: 'pre-line'
  },
  showMoreContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  moreText: {
    marginRight: theme.spacing(0.5)
  }
}));

export function ChangeLogItem(props) {
  const [showAllChanges, setShowAllChanges] = useState(false);
  const classes = useStyles();

  const defaultText = '-';
  if (!props.log) return defaultText;

  const actionTypes = {
    ADD: 'Added',
    EDIT: 'Edited',
    DELETE: 'Deleted'
  };

  const {
    executionType: actionType,
    auditedEntityType: type, // e.g. Job
    auditedEntityDisplayName: name, // e.g. 1234 (Job number)
    auditedEntityId: id,
    auditedEntitySortKey: sortKey,
    auditedEntityParentEntityType: parentType,
    auditedEntityParentDisplayName: parentName,
    auditedEntityParentId: parentId,
    customMessage
  } = props.log;

  const route = getRoute({ type, id, name, sortKey, parentType, parentId, parentName });

  // TimetrackingSetting type has very long and not useful object JSON as associated name.
  // Don't show it to the user.
  const excludeNamesForTypes = ['TimetrackingSetting'];

  const changeLog = getChangesFromLog(props.log);
  const hasAllParentData = Boolean(parentType && parentName);
  const parentEntityString = ` on ${camelCaseToTitleCase(parentType)}${
    parentName ? ` ${parentName}` : ''
  }`;
  const mainEntityString = `${camelCaseToTitleCase(type)} ${
    !excludeNamesForTypes.includes(type) ? `${name}` : ''
  }`;
  const topLevelChangeComponent = (
    <span className={`${classes.wrap} ${classes.marginRight}`}>
      {actionType}
      {route ? (
        <LinkButton
          classes={{
            root: `${classes.inlineButton} ${hasAllParentData ? classes.marginRight : ''}`
          }}
          label={mainEntityString}
          path={route}
        />
      ) : (
        <span className={classes.wrap}>{mainEntityString}</span>
      )}
      {hasAllParentData ? parentEntityString : ''}
      {actionType === actionTypes.EDIT && changeLog.length > 0 ? ':' : ''}
    </span>
  );

  const defaultShownChangeCount = 2;
  const shownChangeCount = showAllChanges ? changeLog.length : defaultShownChangeCount;
  const shownChangeLog = changeLog.slice(0, shownChangeCount);
  const changes = shownChangeLog.map(change => (
    <SingleChange data={change} key={JSON.stringify(change)} />
  ));

  const hiddenChangeCount = changeLog.length - shownChangeCount;
  const isShowAllButtonVisible = hiddenChangeCount > 0 && !showAllChanges;

  return (
    <div className={classes.root}>
      {customMessage ?? topLevelChangeComponent}
      {changes.length > 1 && (
        <>
          <br />
          <br />
        </>
      )}
      {changes}
      {isShowAllButtonVisible && (
        <>
          <br />
          <div className={classes.showMoreContainer}>
            <span className={classes.moreText}>And {hiddenChangeCount} more...</span>
            <LinkButton
              disableLink
              label="[show]"
              onClick={() => {
                setShowAllChanges(true);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}
