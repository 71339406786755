import React, { createRef, useEffect, useReducer, useState } from 'react';

import { Button, Grid, ListItemIcon, MenuItem, Select, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UpdateIcon from '@material-ui/icons/Update';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import * as R from 'ramda';
import { connect } from 'react-redux';

import RenameModal from 'components/ResponsiveTable/RenameModal';
import { snackbarOn } from 'redux/actions/globalActions';
import { Mode } from 'utils/constants';

import {
  useDispatchBoardDeleteFilter,
  useDispatchBoardFilters,
  useDispatchBoardMutateFilter
} from '../DispatchBoard/queries';

import { useStyles } from './SavedFilter.styles';
import SavedFilterActionsMenuItem from './SavedFilterActionsMenuItem';

const reductions = {
  update: (state, payload) =>
    R.update(
      state.findIndex(item => item.index === payload.index),
      payload,
      state
    ),
  add: (state, payload) => [...state, payload],
  delete: (state, payload) => [...state.filter(value => payload.id !== value.id)],
  set: (_, payload) => [...payload]
};

const savedFilterReducer = (state, { type, payload }) =>
  reductions[type]?.(state, payload) ?? state;

const SavedFilter = props => {
  const { handleFilterChange, handleFiltersClear, snackbarOn: snackbar } = props;

  const { data: filters, loading } = useDispatchBoardFilters();

  const [saveFilters] = useDispatchBoardMutateFilter();

  const [deleteFilter] = useDispatchBoardDeleteFilter();

  const [filterData, dispatchFilterData] = useReducer(savedFilterReducer);

  const [filtersLoaded, setFiltersLoaded] = useState(false);

  const [subMenuFilter, setSubmenuFilter] = useState();

  const [defaultFilterDefinedByUser, setDefaultFilterDefinedByUser] = useState(null);

  const getValidQueryStringPairs = R.pick(['departments', 'crews', 'technicians']);

  const loadFilter = R.pipe(
    qs => queryString.parse(qs?.filter),
    R.mergeRight({
      departments: undefined,
      crews: undefined,
      technicians: undefined
    }),
    getValidQueryStringPairs,
    R.forEachObjIndexed((value, key) => handleFilterChange(key, value))
  );

  const formatFilterForSave = R.pipe(
    qs => queryString.parse(qs),
    getValidQueryStringPairs,
    qs => `?${queryString.stringify(qs)}`
  );

  const defaultFilter = {
    displayName: 'Default Filter',
    filter: ''
  };
  const defaultNewFilterName = 'Custom Filter';

  const classes = useStyles();
  const filterSelectRef = createRef();

  const [showCreateNewViewModal, setShowCreateNewViewModal] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(defaultFilter);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const MenuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    anchorEl: () => filterSelectRef.current,
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        width: 220
      }
    },
    autoFocus: false
  };

  useEffect(() => {
    if (!loading) {
      const data = filters?.getDispatchFiltersByEmployee;
      if (data) {
        dispatchFilterData({
          type: 'set',
          payload: data
        });
        setDefaultFilterDefinedByUser(data?.find(x => x.isDefault));
      }

      setFiltersLoaded(true);
    }
  }, [filters, loading]);

  useEffect(() => {
    if (isFirstLoad && filtersLoaded) {
      if (defaultFilterDefinedByUser) {
        setSelectedFilter(defaultFilterDefinedByUser);
        loadFilter(defaultFilterDefinedByUser);
      } else {
        setSelectedFilter(defaultFilter);
        handleFiltersClear();
      }
      setIsFirstLoad(false);
    }
  }, [
    isFirstLoad,
    filtersLoaded,
    defaultFilterDefinedByUser,
    defaultFilter,
    loadFilter,
    handleFiltersClear
  ]);

  const handleSetIsDefault = async (filterName, id, defaultChecked) => {
    setFiltersLoaded(false);

    const fn = defaultChecked
      ? // setting a new default: set and clear all the others
        filter => ({
          ...filter,
          isDefault: filter.id === id ? defaultChecked : false
        })
      : // clearing a default: clear only that one
        filter => ({
          ...filter,
          ...(filter.id === id && { isDefault: false })
        });

    const saveResult = await saveFilters(filterData.map(fn));
    const newFilterList = saveResult?.data?.upsertDispatchFilter ?? [];
    if (newFilterList.length) {
      dispatchFilterData({
        type: 'set',
        payload: newFilterList
      });
      setDefaultFilterDefinedByUser(newFilterList?.find(x => x.isDefault));
      snackbar('success', `${filterName} has been made default successfully`);
    }

    setFiltersLoaded(true);
  };

  const handleUpsertFilter = async (newFilterName, id) => {
    setFiltersLoaded(false);

    const filter = formatFilterForSave(window.location.search);

    const filterToSave = id
      ? {
          ...filterData?.find(x => x.id === id),
          displayName: newFilterName,
          filter
        }
      : {
          displayName: newFilterName || defaultNewFilterName,
          filter,
          isDefault: false
        };

    const saveResult = await saveFilters([filterToSave]);
    const newFilterList = saveResult?.data?.upsertDispatchFilters ?? [];
    if (newFilterList.length) {
      dispatchFilterData({
        type: 'set',
        payload: newFilterList
      });

      if (selectedFilter?.id === id) {
        const updatedFilter = newFilterList.find(x => x.id === id);
        setSelectedFilter(updatedFilter);
        loadFilter(updatedFilter);
      }

      snackbar('success', `${filterToSave.displayName} has been saved successfully`);
    }

    setFiltersLoaded(true);
  };

  const handleDeleteFilter = async (filterName, id) => {
    setFiltersLoaded(false);

    const deleteResult = await deleteFilter(id);
    if (deleteResult) {
      dispatchFilterData({
        type: 'delete',
        payload: id
      });

      if (selectedFilter?.id === id) {
        setSelectedFilter(defaultFilter);
        handleFiltersClear();
      }

      snackbar('success', `${filterName} has been deleted successfully`);
    }

    setFiltersLoaded(true);
  };

  return (
    <div className={classes.savedFilterWrapper}>
      <Typography className={classes.filterCaption} variant="caption">
        FILTERS
      </Typography>
      <Select
        className={classes.selectWrapper}
        disabled={!filtersLoaded}
        MenuProps={MenuProps}
        ref={d => {
          if (d) {
            filterSelectRef.current = d;
          }
        }}
        renderValue={() => (filtersLoaded ? selectedFilter?.displayName : '...Loading')}
        value={selectedFilter?.id ? selectedFilter.displayName : defaultFilter.displayName}
        variant="outlined"
        onChange={event => {
          if (!filtersLoaded) {
            return;
          }
          const selected = event.target.value;
          if (!selected) {
            return;
          }

          setSelectedFilter(selected);
          if (!selected.filter) {
            handleFiltersClear();
            return;
          }

          loadFilter(selected);
        }}
      >
        <Typography className={classes.filtersTitle} variant="body2">
          Filters for Dispatch
        </Typography>
        <Typography className={classes.sectionTitle} variant="caption">
          DEFAULT FILTER
        </Typography>

        <MenuItem className={classes.menuText} disabled={!filtersLoaded} value={defaultFilter}>
          {defaultFilter.displayName}
        </MenuItem>

        {defaultFilterDefinedByUser && (
          <MenuItem
            className={classes.menuText}
            disabled={!filtersLoaded}
            value={defaultFilterDefinedByUser}
          >
            {defaultFilterDefinedByUser.displayName}
            <ListItemIcon
              aria-controls={`viewAction${defaultFilterDefinedByUser.displayName}`}
              aria-haspopup="true"
              aria-label="more"
              className={classes.moreIcon}
              onClick={event => {
                event.stopPropagation();
                setSubmenuFilter({
                  filter: defaultFilterDefinedByUser,
                  anchorEl: event.currentTarget
                });
              }}
            >
              <MoreVertIcon className={classes.moreIconStyle} />
            </ListItemIcon>
          </MenuItem>
        )}

        <Typography className={classes.sectionTitle} variant="caption">
          SAVED FILTERS
        </Typography>
        {filterData
          ?.filter(x => x.id !== defaultFilterDefinedByUser?.id)
          ?.map(filter => (
            <MenuItem
              className={classes.menuText}
              disabled={!filtersLoaded}
              key={filter.id}
              value={filter}
            >
              {filter.displayName}
              <ListItemIcon
                aria-controls={`filterAction${filter.displayName}`}
                aria-haspopup="true"
                aria-label="more"
                className={classes.moreIcon}
                onClick={event => {
                  event.stopPropagation();
                  setSubmenuFilter({ filter, anchorEl: event.currentTarget });
                }}
              >
                <MoreVertIcon className={classes.moreIconStyle} />
              </ListItemIcon>
            </MenuItem>
          ))}
        <Grid item>
          <Button
            className={classes.button}
            disabled={!filterData || !window.location.search}
            variant="text"
            onClick={e => {
              e.stopPropagation();
              setShowCreateNewViewModal(true);
            }}
          >
            <AddCircleIcon className={classes.iconStyle} /> Save as new filter
          </Button>
          <Button
            className={classes.button}
            disabled={!selectedFilter?.id || selectedFilter?.filter === window.location.search}
            variant="text"
            onClick={e => {
              e.stopPropagation();
              handleUpsertFilter(selectedFilter.displayName, selectedFilter?.id);
            }}
          >
            <UpdateIcon className={classes.iconStyle} /> Update selected filter
          </Button>
        </Grid>
      </Select>
      {subMenuFilter && (
        <SavedFilterActionsMenuItem
          anchor={subMenuFilter?.anchorEl}
          disabled={!filtersLoaded}
          fallbackView={defaultFilter}
          filter={subMenuFilter?.filter}
          filters={filterData}
          handleCloseSubmenu={() => setSubmenuFilter(null)}
          handleDeleteFilter={handleDeleteFilter}
          handleSetIsDefault={handleSetIsDefault}
          handleUpsertFilter={handleUpsertFilter}
          selectedView={selectedFilter}
          setSelectedView={setSelectedFilter}
          setViewsLoaded={setFiltersLoaded}
          snackbar={snackbar}
        />
      )}
      <RenameModal
        currentLabel={defaultNewFilterName}
        dataType="Filter"
        handleClose={() => setShowCreateNewViewModal(null)}
        handleViewRename={async newLabel => handleUpsertFilter(newLabel)}
        mode={Mode.NEW}
        open={showCreateNewViewModal}
        onClick={e => e.stopPropagation()}
      />
    </div>
  );
};

SavedFilter.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  handleFiltersClear: PropTypes.func.isRequired
};

SavedFilter.defaultProps = {};

export default connect(null, { snackbarOn }, null, { forwardRef: true })(SavedFilter);
