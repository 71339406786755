import React from 'react';

import { Field, FieldType, TV } from '@BuildHero/sergeant';
import { Tooltip, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { isNull } from 'lodash';

const useStyles = makeStyles(theme => ({
  infoIcon: {
    color: theme?.palette?.grayscale(60),
    fontSize: theme?.typography?.body1?.fontSize,
    marginLeft: theme.spacing(0.5),
    top: '-2px',
    position: 'relative'
  },
  label: {
    color: theme?.palette?.grayscale(20),
    verticalAlign: 'top'
  },
  labelContainer: {
    position: 'relative',
    height: '10px',
    marginBottom: theme.spacing(0.75)
  },
  tooltipStatusBackground: {
    backgroundColor: theme?.palette?.support?.grey?.dark,
    padding: theme.spacing(1)
  },
  tooltipStatusArrow: {
    color: theme?.palette?.support?.grey?.dark
  }
}));

export const LabelWithTip = ({ form, field, options }) => {
  const classes = useStyles();
  const formValue = form?.values?.[field?.name];
  const tipContent = formValue?.tipContent;
  const fieldValue = (tipContent && formValue.fieldValue) || formValue;
  const fieldType = !isNull(fieldValue) && fieldValue !== undefined ? options.type : FieldType.TEXT;

  return (
    <div>
      <div className={classes.labelContainer}>
        <Typography className={classes.label} variant="overline">
          {options?.label}
        </Typography>
        {tipContent && (
          <Tooltip
            arrow
            classes={{
              tooltip: classes.tooltipStatusBackground,
              arrow: classes.tooltipStatusArrow
            }}
            title={tipContent}
          >
            <InfoIcon classes={{ root: classes.infoIcon }} fontSize="small" />
          </Tooltip>
        )}
      </div>
      <Field type={fieldType || FieldType.TEXT} value={fieldValue ?? '-'} variant={TV.BASE} />
    </div>
  );
};

LabelWithTip.propTypes = {};

LabelWithTip.defaultProps = {};

export default LabelWithTip;
