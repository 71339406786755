/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

import { sentryException } from '../Logger/index';

export async function getReceipts(include, options = { limit: 10, offset: 0 }) {
  return await instance
    .get(`purchaseorderreceipt`, {
      params: {
        include: include || '*',
        ...options
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get purchase order receipts');
      return [];
    });
}

export async function getReceiptsByPoId(poId) {
  return await instance
    .post(`purchaseorderreceipt/search/${poId}`, { searchColumns: ['parentId'] })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get receipts by purchase order ID');
      return [];
    });
}

export async function getPOReceiptsByPoId(
  poId,
  include = [
    'PurchaseOrder',
    'Department',
    'Vendor',
    'Job',
    'PurchaseOrderReceiptLine',
    'OrderedBy',
    'Project'
  ]
) {
  return await instance
    .get(`purchaseorderreceipt/po/${poId}`, {
      params: {
        include
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get purchase order receipts by purchase order ID');
      return [];
    });
}

export async function purchaseOrderReceiptDelete(id) {
  return await instance
    .delete(`purchaseorderreceipt/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to delete purchase order receipt');
      return [];
    });
}

export async function getReceiptsById(id) {
  return await instance
    .get(`purchaseorderreceipt/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get receipts by ID');
      return [];
    });
}

export async function getNextReceiptNumber(poId) {
  return await instance
    .get(`purchaseorderreceipt/${poId}/nextreceiptnumber`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get next receipt number');
      return [];
    });
}

export async function purchaseOrderReceiptCreate(receiptData) {
  return await instance
    .post(`purchaseorderreceipt`, receiptData)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to create purchase order receipt');
      return [];
    });
}

export async function getReceiptByNumber(number) {
  const relations = ['PurchaseOrderReceiptLine', 'Department'];

  return await instance
    .post(`purchaseorderreceipt/search/${number}?include=${relations}`, {
      searchColumns: ['receiptNumber']
    })
    .then(({ data }) => {
      return data[0];
    })
    .catch(err => {
      sentryException(err, 'Unable to get receipt by number');
      return [];
    });
}

export async function purchaseOrderReceiptChange(receiptId, newData) {
  return await instance
    .put(`purchaseorderreceipt/${receiptId}`, newData)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to change purchase order receipt');
      return [];
    });
}

export async function postReceiptPost(receiptId) {
  return await instance
    .post(`purchaseorderreceipt/postReceipt/${receiptId}`, {})
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to post receipt');
      return {};
    });
}

export async function addReceiptAttachment(receiptId, attachment) {
  return await instance
    .post(`purchaseorderreceipt/${receiptId}/attachment`, attachment)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to add receipt attachment');
      return {};
    });
}
