import React, { useEffect, useState } from 'react';

import { Button, ThemeProvider } from '@BuildHero/sergeant';
import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';

import ManualStatusChangeModal from 'components/ManualStatusChangeModal/index';
import ResponsiveTable from 'components/ResponsiveTable';
import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import useEmployees from 'customHooks/useEmployees';
import { quoteRowMeta } from 'meta/Jobs/quoteTable';
import { snackbarOn } from 'redux/actions/globalActions';
import useUpdateJob from 'scenes/JobCloseout/JobCloseoutHeader/hooks/useUpdateJob';
import validateDissociateJobFromQuote from 'scenes/Quotes/utils/changeOrderHelper';
import { jobQuoteStatus } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import AddIncurredCosts from './QuotesFromJobHelper/addIncurredCosts';
import { addQuoteFromJob, dissociateQuoteFromJob } from './QuotesFromJobHelper/index';

// seperated out as a seperate componenet for futuristic purpose
const QuoteList = ({ data, jobData, currentProperty, history, snackbar, user, refetch }) => {
  const processedData = data.map(quote => ({
    ...quote,
    displayQuoteNumber: quote?.customIdentifier || quote?.quoteNumber,
    totalAmountQuoted: quote?.totalAmountQuotedOverride || quote?.totalAmountQuoted
  }));

  const [companyEmployees] = useEmployees();
  const [addQuoteLoading, setAddQuoteLoading] = useState(false);
  const [incuredCostModalOpen, setIncuredCostModalOpen] = useState(false);
  const [openQuoteStatusChangeModal, setOpenQuoteStatusChangeModal] = useState(false);
  const [quoteStatusModalData, setQuoteStatusModalData] = useState(null);
  const [updateJob] = useUpdateJob();
  const flags = useFlags();
  const confirmContext = useConfirmModal();
  const { palette } = useTheme();

  const dissociateJobHandler = async record => {
    const confirmDissociateJob = await validateDissociateJobFromQuote({
      confirmContext,
      data: record,
      jobData,
      palette
    });
    if (confirmDissociateJob) {
      dissociateQuoteFromJob({
        quoteInfo: record,
        tenantId: jobData?.tenantId,
        snackbar,
        refetch
      });
    }
  };

  useEffect(() => {
    setQuoteStatusModalData({
      manualStatus: jobData?.manualQuoteStatus,
      automatedStatus: jobQuoteStatus.QUOTE_IN_DRAFT,
      id: jobData?.id,
      version: jobData?.version
    });
  }, [jobData]);

  const useStyles = makeStyles(theme => ({
    quoteButton: {
      width: 86,
      padding: theme.spacing(1),
      alignSelf: 'flex-end',
      marginBottom: theme.spacing(1),
      '& *': {
        fontSize: 12
      }
    }
  }));

  const classes = useStyles();

  const jobHasIncuredCosts = () => {
    return jobData?.visits?.items?.find(item => {
      return (
        item?.inventoryParts?.items?.length > 0 ||
        item?.timesheetEntriesView?.items?.find(
          timeSheetI => timeSheetI?.actualTotalDuration || timeSheetI?.actualTotalDurationOverride
        )
      );
    });
  };

  const handleAddQuote = async () => {
    setAddQuoteLoading(true);
    if (jobHasIncuredCosts() && flags[FeatureFlags.JOB_QUOTE_INCURRED_COST]) {
      setIncuredCostModalOpen(true);
    } else {
      await addQuoteFromJob({
        currentProperty,
        jobData,
        history,
        user,
        companyEmployees,
        snackbar
      });
    }
    setAddQuoteLoading(false);
  };

  const checkJobQuoteStatusChange = () => {
    if (flags[FeatureFlags.JOB_QUOTE_STATUS] && quoteStatusModalData.manualStatus) {
      setOpenQuoteStatusChangeModal(true);
    } else {
      handleAddQuote();
    }
  };

  const updateJobAndSubmit = async () => {
    await updateJob({
      id: quoteStatusModalData?.id,
      version: quoteStatusModalData?.version,
      manualQuoteStatus: null
    });
    handleAddQuote();
  };

  const handleRowActions = (actionType, record) => {
    if (actionType === 'removeFromJob') {
      dissociateJobHandler(record);
    }
  };

  return (
    <>
      {flags[FeatureFlags.JOB_QUOTE_STATUS] && (
        <ThemeProvider>
          <Button
            className={classes.quoteButton}
            loading={addQuoteLoading}
            type="secondary"
            onClick={checkJobQuoteStatusChange}
          >
            Add Quote
          </Button>
          {flags[FeatureFlags.JOB_QUOTE_INCURRED_COST] && (
            <AddIncurredCosts
              currentProperty={currentProperty}
              handleOnClose={() => setIncuredCostModalOpen(false)}
              jobData={jobData}
              open={incuredCostModalOpen}
              snackbar={snackbar}
            />
          )}
          <ManualStatusChangeModal
            data={quoteStatusModalData}
            handleClose={xButtonClosed => {
              if (!xButtonClosed) {
                handleAddQuote();
              }
              setOpenQuoteStatusChangeModal(false);
            }}
            handleSubmit={() => {
              updateJobAndSubmit();
              setOpenQuoteStatusChangeModal(false);
            }}
            open={openQuoteStatusChangeModal}
            statusEnumType={EnumType.JOB_QUOTE_STATUS}
            title="Update Quote Status"
          />
        </ThemeProvider>
      )}
      <ResponsiveTable
        data={processedData}
        disableFilter
        disableSort
        fullScreen
        noDataMsg="No quotes"
        rowActionButtons={
          flags[FeatureFlags.JOB_CLOSEOUT] &&
          flags[FeatureFlags.CHANGE_ORDER_WORKFLOW] && {
            removeFromJob: {
              label: 'Remove from Job',
              icon: 'Delete',
              caslAction: 'delete',
              caslKey: ''
            }
          }
        }
        rowActions={handleRowActions}
        rowMetadata={quoteRowMeta(flags)}
      />
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  snackbar: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedQuote = connect(mapStateToProps, mapDispatchToProps)(QuoteList);

export default reduxConnectedQuote;
