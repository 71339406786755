import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function Projects(props) {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SvgIcon {...props}>
      <path
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.895431 23.1046 0 22 0H2ZM14 3H1V5H14V3ZM9 7H20V9H9V7ZM18 11H2V13H18V11ZM13 15H21V17H13V15ZM23 19H7V21H23V19Z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
