import React, { useEffect, useState } from 'react';

import { Typography } from '@material-ui/core';
import { find, flattenDeep, groupBy, uniq } from 'lodash';
import PropTypes from 'prop-types';

import { ResponsiveTable } from 'components';
import CompanyContext from 'components/Context';
import usePayrollHourTypes from 'customHooks/usePayrollHourTypes';
import invoiceLabels from 'meta/Jobs/Invoice/labels';
import ErrorBoundaries from 'scenes/Error';
import { ReviewReportService } from 'services/core';
import { convertNumberToFixed, parseNumbersFromString, pickStatusByPriority } from 'utils';
import {
  getTimesheetStatusPriorities,
  PermissionConstants,
  TimesheetReviewStatusTypes
} from 'utils/AppConstants';
import { EnumType } from 'utils/constants';

const auditColumns = [
  {
    id: 'totalTime',
    label: 'Total',
    type: 'string',
    align: 'right'
  },
  {
    id: 'totalCost',
    label: 'Cost',
    type: 'currency',
    align: 'right'
  },
  {
    id: 'reviewedBy',
    label: 'Reviewed By',
    type: 'string',
    maxTextLen: 80
  },
  {
    id: 'reviewedDates',
    label: 'Date Reviewed',
    type: 'dateArray'
  },
  {
    id: 'approvedBy',
    label: 'Approved By',
    type: 'string',
    maxTextLen: 80
  },
  {
    id: 'approvedDates',
    label: 'Date Approved',
    type: 'dateArray'
  }
];

const DECIMAL_RESOLUTION = 2;

export const getFormattedData = (visitCostInfo = []) => {
  const hourTypeIds = uniq(visitCostInfo.map(timesheet => timesheet?.hourTypeId).filter(Boolean));

  const visitCostInfoListByEmployeeId = groupBy(visitCostInfo, 'employeeId');

  const timesheetByPayrollHrTypeForEmployee = Object.values(visitCostInfoListByEmployeeId).map(
    visitCostInfoByEmployee => {
      const timesheetStatusValues = visitCostInfoByEmployee
        .map(timesheet => {
          if (
            !timesheet?.approvalStatus ||
            timesheet?.approvalStatus
              .split(',')
              ?.every(approvalStatus => approvalStatus !== TimesheetReviewStatusTypes.APPROVED)
          )
            return timesheet?.reviewStatus?.split(',');
          return TimesheetReviewStatusTypes.APPROVED;
        })
        .filter(Boolean);

      const status = pickStatusByPriority(
        flattenDeep(timesheetStatusValues),
        getTimesheetStatusPriorities()
      );
      const durationsByPayrollHourTypes = hourTypeIds.reduce(
        (durations, payrollHourType) => {
          const costInfo = find(
            visitCostInfoByEmployee,
            constInfo => constInfo?.hourTypeId === payrollHourType
          );
          const duration =
            convertNumberToFixed(Number(costInfo?.duration), DECIMAL_RESOLUTION) || 0;
          const totalCost = convertNumberToFixed(
            durations?.totalCost + (costInfo?.totalCost || 0),
            DECIMAL_RESOLUTION
          );
          const totalTime = convertNumberToFixed(
            durations?.totalTime + (duration || 0),
            DECIMAL_RESOLUTION
          );

          return {
            ...durations,
            [costInfo?.hourTypeId]: { duration: costInfo?.duration, cost: costInfo?.totalCost },
            totalCost,
            totalTime
          };
        },
        { totalCost: 0, totalTime: 0 }
      );

      const reportDetails = visitCostInfoByEmployee.find(
        costInfo => costInfo?.reviewStatus === status || costInfo?.approvalStatus === status
      );

      // when pending avoiding date processing logic
      if (status === TimesheetReviewStatusTypes.PENDING) {
        return {
          ...durationsByPayrollHourTypes,
          employeeId: reportDetails?.employeeId,
          employeeName: reportDetails?.name,
          status,
          approvedDates: [],
          reviewedDates: [],
          reviewedBy: '',
          approvedBy: ''
        };
      }

      const approvedDates = parseNumbersFromString(reportDetails?.approvedDateList);
      const approvedBy = reportDetails?.approvedByList;
      const reviewedDates = parseNumbersFromString(reportDetails?.reviewedDateList);
      const reviewedBy = reportDetails?.reviewedByList;

      return {
        ...durationsByPayrollHourTypes,
        employeeId: reportDetails?.employeeId,
        employeeName: reportDetails?.name,
        status,
        approvedDates,
        reviewedDates,
        reviewedBy,
        approvedBy
      };
    }
  );
  return timesheetByPayrollHrTypeForEmployee;
};

function TimeTrackingReport(props) {
  const { classes, user, context } = props;
  const [visitCostInfo, setVisitCostInfo] = useState([]);
  const company = CompanyContext?.getCompanyContext()?.getCompany;

  const visitId = context?.visit?.id;
  useEffect(() => {
    const fetchTechVisitCost = async () => {
      const response = await new ReviewReportService().getTechVisitCost(company?.tenantId, visitId);
      setVisitCostInfo(response?.data?.getTechVisitCost || []);
    };
    fetchTechVisitCost();
  }, [company, visitId]);

  const timeTrackingReportRows = [
    {
      id: 'employeeName',
      label: 'Tech Name'
    }
  ];

  const [payrollHourTypes, payrollHourTypesLoading] = usePayrollHourTypes();

  if (!context.isAutomatedTimeTrackingEnabled) return null;

  const payrollHourTypeRows = payrollHourTypes.reduce((rows, hourType, index) => {
    const isLastItem = index === payrollHourTypes.length - 1;
    const border = '2px solid #000000';

    const rateBorderStyle = {
      borderRight: isLastItem ? border : ''
    };

    const hourBorderStyle = {
      borderLeft: border
    };

    return [
      ...rows,
      {
        id: `${hourType.id}.duration`,
        label: hourType.hourTypeAbbreviation,
        type: 'string',
        align: 'right',
        cellStyle: hourBorderStyle,
        headerStyle: hourBorderStyle
      },
      {
        id: `${hourType.id}.cost`,
        label: 'Cost',
        type: 'currency',
        align: 'right',
        cellStyle: rateBorderStyle,
        headerStyle: rateBorderStyle
      }
    ];
  }, []);

  const statusRow = {
    id: 'status',
    label: 'Status',
    type: 'enum',
    enumType: EnumType.TIMESHEET_REVIEW_STATUS
  };
  const rowMeta = timeTrackingReportRows.concat(payrollHourTypeRows, statusRow, auditColumns);
  const data = getFormattedData(visitCostInfo);

  return (
    <ErrorBoundaries>
      <Typography className={classes.greySectionTitle}>
        {invoiceLabels.timeTrackingReport[user.locale]}
      </Typography>
      <ResponsiveTable
        caslKey={PermissionConstants.OBJECT_REVIEWREPORT}
        data={data}
        disableFilter
        disablePagination
        isLoading={payrollHourTypesLoading}
        noMaxHeight
        rowMetadata={rowMeta}
      />
    </ErrorBoundaries>
  );
}

TimeTrackingReport.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    tenantId: PropTypes.string.isRequired,
    tenantCompanyId: PropTypes.string.isRequired
  }).isRequired,
  context: PropTypes.shape({
    timesheetEntries: PropTypes.array.isRequired,
    visit: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
    reduxActions: PropTypes.shape({ snackbarOn: PropTypes.func.isRequired }).isRequired,
    isAutomatedTimeTrackingEnabled: PropTypes.bool.isRequired
  }).isRequired
};

export default TimeTrackingReport;
