import { isEmpty, pick } from 'lodash';

import { Context } from 'components';
import entityRouteMappings from 'meta/entityRouteMappings';
import {
  addressObjectToString,
  convertToCurrencyString,
  getBestContact,
  isTenantSettingEnabled,
  parseFloatAndRound,
  removeNullValues
} from 'utils';
import { QuoteStatus } from 'utils/AppConstants';
import { CustomFieldTypes } from 'utils/constants';

import { generateQuoteHoursTip, generateQuotePricingTip } from '../../JobCloseout/utils';
import PageHeaderUtils from '../../Quotes/components/PageHeader/index.utils';

const customFieldPrefix = 'Custom_';

// AppSync fails when there are extraneous fields on a mutation variable
// In this case, pluck only the fields on the department entity that are
// also present on the `CreateDepartmentInput` type.
// Adam: this type of work should ideally be abstracted from the frontend.
const backendDepartmentFields = [
  'id',
  'sortKey',
  'entityType',
  'tenantId',
  'tagName',
  'tagType',
  'accountingRefId',
  'accountingRefIdOfClass',
  'hierarchy'
];

export function sortNotes(notes) {
  if (!Array.isArray(notes)) return notes;
  return notes.sort((a, b) => a.lastUpdatedDate - b.lastUpdatedDate);
}

export function aggregateNotes(notes) {
  if (!Array.isArray(notes)) return notes;
  return notes
    .sort((a, b) => a.lastUpdatedDate - b.lastUpdatedDate)
    .reduce(
      (accumulator, currentValue, index) =>
        `${currentValue.subject ? `${currentValue.subject}\n` : ''}${currentValue.note}${
          index > 0 ? '\n\n' : ''
        }${accumulator}`,
      ''
    );
}

// Adds requisite data to raw list of new department IDs to be passed to the job update input object.
// Example input: ['department_id_1', 'department_id_2']
// Example output: [{id: 'department_id_1', sortKey: ..., etc.}, {id: 'department_id_2', sortKey: ..., etc.}]
function formatFormDepartmentsForUpdate(departmentIdList) {
  if (!Array.isArray(departmentIdList)) return [];
  const { departments } = Context.getCompanyContext()?.getCompany || {};
  if (!departments.items) return [];

  const result = [];
  departmentIdList.forEach(selectedId => {
    const selectedDepartment = departments.items.find(dep => dep.id === selectedId);
    if (selectedDepartment) {
      result.push(pick(selectedDepartment, backendDepartmentFields));
    }
  });
  return result;
}

export function formatExistingDepartmentsForUpdate(departmentList) {
  if (!Array.isArray(departmentList)) return [];
  // When a many-to-many connection exists, the actual entity is nested within a `mappedEntity` field.
  // The parent object has a forward join and a reverse join between the two entities.
  return departmentList
    .map(item => item.mappedEntity)
    .map(item => pick(item, backendDepartmentFields));
}

export function getPropertyRepresentative(existingData, formData) {
  // If user has selected a new property then the property rep should be cleared if one has not been newly selected
  const propertyHasBeenUpdated = existingData.customerPropertyId !== formData.propertyId;
  const repId = propertyHasBeenUpdated ? '' : existingData.customerRep?.id;
  const propertyRepId = formData.propertyRepresentative || repId;
  const chosenPropertyRep = (existingData.customerProperty?.customerReps?.items || []).find(
    rep => propertyRepId === rep.mappedEntity.id
  );
  const repMappedEntity = chosenPropertyRep?.mappedEntity || {};

  const contactMethodMap = {
    email: 'email',
    landline: 'landlinePhone',
    'cell phone': 'cellPhone'
  };
  const contactMethod = contactMethodMap[repMappedEntity.bestContact?.toLowerCase()] || 'cellPhone';
  const bestContact = repMappedEntity[contactMethod] || null;

  const partialPayload = {
    customerRepSortKey: repMappedEntity.sortKey || null,
    customerRepName: repMappedEntity.name || null,
    bestContact
  };
  return partialPayload;
}

export function combineDataForJobUpdate(
  formattedExistingData,
  formattedFormData,
  additionalPayload,
  customFormData,
  tags
) {
  const payload = {
    // `formData` takes precedence over `existingData` (new should overwrite old)
    customerPropertyId:
      formattedFormData.customerPropertyId || formattedExistingData.customerPropertyId || undefined,
    jobs: [
      {
        ...formattedExistingData,
        ...formattedFormData,
        ...additionalPayload,
        ...customFormData,
        ...tags
      }
    ]
  };
  return payload;
}

export function formatFormDataForJobUpdate(data) {
  const result = {
    accountingJobNumber: data.accountingJobNumber,
    amountNotToExceed: data.amountNotToExceed !== '' ? data.amountNotToExceed : null,
    amountQuoted: data.amountQuoted !== '' ? data.amountQuoted : null,
    authorizedById: data.authorizedBy,
    billingCustomerId: data.billingCustomerId,
    billingCustomerName: data.billingCustomer || null,
    certifiedPayroll: data.certifiedPayroll,
    costAmount: data.costAmount !== '' ? data.costAmount : null,
    customerId: data?.customerId,
    customerName: data?.customer?.label,
    customerPropertyId: data.propertyId,
    customerPropertyName: data.property,
    customerPropertySortKey: data.propertySortKey,
    customerProvidedPONumber: data.purchaseOrder,
    customerProvidedWONumber: data.workOrder,
    customerSortKey: data?.customerSortKey,
    departments: formatFormDepartmentsForUpdate(data.departments),
    detailedJobCostingEnabled: data.detailedJobCostingEnabled,
    labourRateGroupId: data.labourRateGroup !== '' ? data.labourRateGroup : null,
    labourRateModifierIds: data.labourRateModifiers,
    issueDescription: data.issueDescription,
    serviceChannelDescription: data.serviceChannelDescription,
    ownerId: data.projectManager,
    priceBookId: data.priceBookId,
    priority: data.priority,
    sageJobId: data.sageJobId,
    // Reset service agreement if customer property has been changed bc SA may not be available for new property
    // propertyId field only present when property changed
    serviceAgreementId: data.propertyId ? '' : data.serviceAgreementId,
    soldById: data.soldBy,
    totalBudgetedHours: data.totalBudgetedHours,
    procurementStatus: data.procurementStatus,
    accountManagerId: data.accountManager
  };

  const jobTypeId = data.jobType;
  const jobTypes = Context.getCompanyContext()?.getCompany?.jobTypes?.items;
  if (jobTypes) {
    jobTypes.items = jobTypes.items?.filter(type => type.tagType === CustomFieldTypes.JobTypes);
  }
  const jobTypeName = jobTypes && jobTypes.find(type => type.id === jobTypeId)?.tagName;
  if (jobTypeName && data.jobType) {
    result.jobTypeId = jobTypeId;
    result.jobTypeName = jobTypeName;
  }
  return result;
}

export function formatExistingDataForJobUpdate(data) {
  const { getCompany } = Context.getCompanyContext() || {};
  const { jobTypes } = getCompany || {};
  jobTypes.items = jobTypes.items?.filter(type => type.tagType === CustomFieldTypes.JobTypes);

  const shouldSyncImmediately = isTenantSettingEnabled('syncImmediately');

  const chosenJobType = (jobTypes?.items || []).find(type => type.tagName === data.jobTypeName);

  let jobUpdateData = {};
  [
    'amountNotToExceed',
    'amountQuoted',
    'authorizedById',
    'billingCustomerId',
    'billingCustomerName',
    'certifiedPayroll',
    'costAmount',
    'customerId',
    'customerName',
    'customerPropertyId',
    'customerPropertyName',
    'customerPropertySortKey',
    'customerProvidedPONumber',
    'customerProvidedWONumber',
    'customerSortKey',
    'customIdentifier', // custom job number
    'detailedJobCostingEnabled',
    'dueDate',
    'id',
    'issueDescription',
    'serviceChannelDescription',
    'jobNumber',
    'jobTypeName',
    'ownerId',
    'priority',
    'soldById',
    'totalBudgetedHours',
    'labourRateGroupId',
    'labourRateModifierIds',
    'version'
  ].forEach(key => {
    jobUpdateData[key] = data[key] ?? null;
  });

  const customInputs = {
    status: data.status || 'Open',
    customerPropertyTypeName: data.customerPropertyTypeValue || null,
    jobTypeSortKey: chosenJobType?.sortKey || null,
    jobTypeId: chosenJobType?.id || data.jobTypeId || null,
    departments: formatExistingDepartmentsForUpdate(data.departments.items),
    ownerId: data.projectManager || null,
    soldById: data.soldBy || null,
    labourRateGroupId: data.labourRateGroup || null,
    labourRateModifierIds: data.labourRateModifiers || null
  };

  jobUpdateData = { ...jobUpdateData, ...customInputs };

  if (data.customJobNumber && data.customIdentifier && !data.customJobNumberExpression) {
    jobUpdateData.customIdentifier = data.customIdentifier || null;
  }
  const isSyncing = data?.syncStatus === 'Syncing';
  if (shouldSyncImmediately && !isSyncing) {
    jobUpdateData.syncStatus = 'Syncing';
  } else if (!shouldSyncImmediately) {
    jobUpdateData.syncStatus = null;
  }

  return jobUpdateData;
}

function formatJobTags(tagsMapping, newJobTags) {
  return newJobTags.map(tag => {
    if (!tagsMapping[tag.value]) {
      return {
        jobTagId: tag.value
      };
    }
    return {
      id: tagsMapping[tag.value],
      jobTagId: tag.value
    };
  });
}

export function getChangedTagsEntity(existingData, newJobTags) {
  const tagsMapping = {};
  const deletedTags = [];
  const existingTags = existingData?.jobJobTags?.items || [];
  existingTags.forEach(jobJobTag => {
    const { jobTag, id } = jobJobTag;
    tagsMapping[jobTag.id] = id;
    if (newJobTags.findIndex(tag => tag.value === jobTag.id) < 0) {
      deletedTags.push(id);
    }
  });
  const addedTags = { jobJobTags: formatJobTags(tagsMapping, newJobTags) };
  return { addedTags, deletedTags };
}

function getRoute(id, entityType) {
  const baseRoute = entityRouteMappings[entityType];
  if (!baseRoute || !id) return undefined;
  return baseRoute + id;
}

function getName(mulitpleNamesEntity) {
  const finalName = mulitpleNamesEntity?.name
    ? mulitpleNamesEntity?.name
    : `${mulitpleNamesEntity?.firstName || ''} ${mulitpleNamesEntity?.lastName || ''}`;
  return finalName.trim() === '' ? null : finalName;
}

function getLocation(companyAddresses) {
  const addressItems = companyAddresses?.items || [];
  const foundAddress = addressItems.find(item => {
    const { addressLine1, addressLine2 } = item;
    if (addressLine1 || addressLine2) {
      return item;
    }
    return null;
  });
  const { latitude = 0, longitude = 0 } = foundAddress || {};
  return { latitude, longitude };
}

export function formatJobDataForMainForm(
  jobData,
  mode = 'view',
  inlineFormData,
  priceBooks,
  jobQuoteStatusFlag
) {
  if (!jobData) return {};
  const {
    certifiedPayroll,
    departments,
    detailedJobCostingEnabled,
    issueDescription,
    serviceChannelDescription,
    jobTypeId,
    jobTypeName,
    owner,
    priority,
    sageJob,
    serviceAgreement,
    soldBy: soldByEmployee,
    labourRateGroup: jobLabourRateGroup,
    labourRateModifierJobs,
    procurementStatus,
    totalBudgetedHours
  } = jobData;
  let totalAggregatedBudgetedHours;
  if (jobQuoteStatusFlag) {
    totalAggregatedBudgetedHours = jobData?.quoteJobs?.items
      ?.filter(
        qJ =>
          qJ?.quote?.status === QuoteStatus.APPROVED || qJ?.quote?.status === QuoteStatus.JOB_ADDED
      )
      .reduce((acc, quoteJob) => {
        return (
          acc +
            (quoteJob?.quote?.quoteLineTasks &&
              PageHeaderUtils.calcTotalBudgetedLaborHours(quoteJob?.quote?.quoteLineTasks, true)) ||
          0
        );
      }, 0);
    if (mode === 'view') {
      totalAggregatedBudgetedHours = totalAggregatedBudgetedHours && {
        fieldValue: totalAggregatedBudgetedHours,
        tipContent: generateQuoteHoursTip(totalAggregatedBudgetedHours, jobData?.quoteJobs?.items)
      };
    }
  }

  let { priceBookId, priceBook, sageJobId, accountingJobNumber } = jobData;
  let departmentsList = [];
  let jobType = '';
  let projectManager = '';
  let soldBy = '';
  let labourRateGroup = '';
  let labourRateModifiers = [];
  let serviceAgreementId = '';
  let accountManager = '';

  // In edit mode, we need IDs to set the new values in the backend on submit.
  // In view mode, we need names so the fields are understandable to the user.
  if (mode === 'edit') {
    departmentsList = (departments?.items || []).map(department => department.mappedEntity?.id);
    jobType = jobTypeId;
    projectManager = owner?.id;
    soldBy = soldByEmployee?.id;
    labourRateGroup = jobLabourRateGroup?.id;
    labourRateModifiers = (labourRateModifierJobs?.items || []).map(
      modifierJob => modifierJob?.labourRateModifier?.id
    );
    accountManager = jobData?.accountManager?.id;
    serviceAgreementId = serviceAgreement?.id;
  } else {
    // mode === 'view'
    departmentsList = (departments?.items || []).map(department => ({
      label: department.mappedEntity?.tagName
    }));
    jobType = jobTypeName;
    projectManager = owner?.name;
    soldBy = soldByEmployee?.name;
    labourRateGroup = jobLabourRateGroup?.name;
    labourRateModifiers = (labourRateModifierJobs?.items || []).map(({ labourRateModifier }) => ({
      label: `${labourRateModifier?.name} (${
        labourRateModifier?.modifierType === 'percentage' ? '%' : '$'
      })`
    }));
    accountManager = jobData?.accountManager?.name;
    priceBookId = {
      label: priceBook?.name,
      path: getRoute(priceBook?.id, 'PriceBookView')
    };
    serviceAgreementId = {
      label: serviceAgreement?.agreementName,
      path: getRoute(serviceAgreement?.id, 'ServiceAgreement')
    };
    sageJobId = sageJob?.code;
  }

  const officeNotes = jobData?.notes?.items.sort((a, b) => a.sortOrder - b.sortOrder);

  const customFormData = {};
  // Add Custom_ to inline form data for identification. Will be available only when created in tenant. Holmes uses it
  if (inlineFormData) {
    const {
      form,
      formDataJson,
      lastUpdatedDateTime,
      createdDateTime,
      sortKey,
      __typename,
      ...customFields
    } = inlineFormData;

    Object.keys(customFields).forEach(fieldName => {
      // changing text1: 'something' to Custom_text1: 'something'
      // Custom_ will used to identify custom fields, it will picked and send as formData in the job update payload
      customFormData[`${customFieldPrefix}${fieldName}`] = customFields[fieldName];
    });
  }

  return removeNullValues({
    accountingJobNumber,
    certifiedPayroll,
    departments: departmentsList,
    detailedJobCostingEnabled,
    issueDescription: issueDescription ?? '',
    serviceChannelDescription,
    jobType,
    officeNotes,
    priceBook,
    priceBookId,
    priceBooks,
    priority,
    procurementStatus,
    projectManager,
    sageJobId,
    serviceAgreementId,
    soldBy,
    labourRateGroup,
    labourRateModifiers,
    totalBudgetedHours: totalAggregatedBudgetedHours || totalBudgetedHours,
    accountManager,
    ...customFormData
  });
}

export function formatJobDataForSidebarForm(
  jobData,
  mode = 'view',
  currentProperty,
  jobQuoteStatusFlag
) {
  if (!jobData) return {};
  const {
    customer,
    billingCustomer,
    customerProperty,
    customerRep,
    bestContact,
    authorizedBy,
    customerProvidedPONumber,
    customerProvidedWONumber,
    amountNotToExceed
  } = jobData;

  const customerName = customer?.customerName;
  const billingCustomerName = billingCustomer?.customerName;

  const propertyAddressObject = customerProperty?.companyAddresses?.items?.find(
    address => address.addressType === 'propertyAddress'
  );
  const propertyAddress = addressObjectToString(propertyAddressObject);
  const customerRoute = getRoute(customer?.id, 'Customer');
  const billingCustomerRoute = getRoute(billingCustomer?.id, 'Customer');
  const propertyRoute = getRoute(customerProperty?.id, 'Property');
  const propertyInstructions = sortNotes(customerProperty?.notes?.items);
  const location = propertyAddressObject && getLocation({ items: [propertyAddressObject] });
  const bestContactValue = getBestContact(customerRep);
  let { aggregatedAmountQuoted, aggregatedCost } = {
    aggregatedAmountQuoted: 0,
    aggregatedCost: 0
  };
  if (jobQuoteStatusFlag) {
    ({ aggregatedAmountQuoted, aggregatedCost } =
      jobData?.quoteJobs?.items
        ?.filter(
          qJ =>
            qJ?.quote?.status === QuoteStatus.APPROVED ||
            qJ?.quote?.status === QuoteStatus.JOB_ADDED
        )
        .reduce(
          (acc, quoteJob) => {
            return {
              aggregatedAmountQuoted:
                acc.aggregatedAmountQuoted + quoteJob?.quote?.totalAmountQuoted,
              aggregatedCost: acc.aggregatedCost + quoteJob?.quote?.totalEstimatedCost
            };
          },
          { aggregatedAmountQuoted: 0, aggregatedCost: 0 }
        ) || {});
    aggregatedAmountQuoted =
      aggregatedAmountQuoted && parseFloatAndRound(aggregatedAmountQuoted, 2);
    aggregatedCost = aggregatedCost && parseFloatAndRound(aggregatedCost, 2);
    if (mode === 'view') {
      aggregatedAmountQuoted = aggregatedAmountQuoted && {
        fieldValue: aggregatedAmountQuoted,
        tipContent: generateQuotePricingTip(
          aggregatedAmountQuoted,
          jobData?.quoteJobs?.items,
          'totalAmountQuoted'
        )
      };
      aggregatedCost = aggregatedCost && {
        fieldValue: aggregatedCost,
        tipContent: generateQuotePricingTip(
          aggregatedCost,
          jobData?.quoteJobs?.items,
          'totalEstimatedCost',
          'Cost Amount'
        )
      };
    }
  }

  return removeNullValues({
    customer: { label: customerName, path: customerRoute },
    billingCustomer:
      mode === 'view'
        ? { label: billingCustomerName, path: billingCustomerRoute }
        : billingCustomerName,
    billingCustomerId: mode === 'edit' ? billingCustomer?.id : undefined,
    property:
      mode === 'view'
        ? { label: currentProperty?.companyName, path: propertyRoute }
        : currentProperty?.companyName,
    propertyType: customerProperty?.customerPropertyTypeValue,
    authorizedBy: mode === 'view' ? getName(authorizedBy) : authorizedBy?.id,
    propertyRepresentative: mode === 'view' ? getName(customerRep) : customerRep?.id,
    purchaseOrder: customerProvidedPONumber,
    workOrder: customerProvidedWONumber,
    location,
    amountQuoted: aggregatedAmountQuoted || jobData?.amountQuoted,
    amountNotToExceed,
    costAmount: aggregatedCost || jobData?.costAmount,
    bestContactMethod: bestContactValue || bestContact,
    propertyInstructions,
    address: propertyAddress
  });
}

export const getFetchErrorMessage = (entityType, jobNumber) => {
  return `Unable to get ${entityType} for Job ${jobNumber}. Please try again.`;
};

export const formatCustomFormDataForJobUpdate = (filledFormData, form) => {
  // When no inline form defined return
  if (!form) return {};

  let formData = {};
  Object.keys(filledFormData).forEach(dataItem => {
    if (dataItem.startsWith(customFieldPrefix)) {
      if (!formData) formData = {};
      formData[dataItem.replace(customFieldPrefix, '')] = filledFormData[dataItem] || null;
    }
  });
  formData.formDefinitionSortKey = form.latestPublishedFormDefinitionSortKey;
  formData.formSortKey = form.sortKey;
  formData.formId = form?.id;
  formData.formDefinitionId = form?.latestPublishedFormDefinition?.id;
  return { formData };
};
