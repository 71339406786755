/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';

import moment from 'moment';
import { connect } from 'react-redux';

import { FullScreenModal, SplitButton } from 'components';
import { MIXPANEL_EVENT, MIXPANEL_PAGE_NAME } from 'constants/mixpanelEvents';
import { snackbarOn } from 'redux/actions/globalActions';
import { PaymentService } from 'services/core';
import { sendMixpanelEvent } from 'services/mixpanel';
import { getTenantSettingValueForKey, logErrorWithCallback } from 'utils';
import { ExportStatus, SyncStatus } from 'utils/constants';

import { handleAccountDetail, handlePaymentInvoices } from '../PaymentDetail/helper';
import PaymentForm from '../PaymentForm';

class CreatePayment extends Component {
  constructor(props) {
    super(props);
    const { initialData } = props;
    const today = moment().unix();
    const formData = {
      paymentNumber: undefined,
      paymentAmount: undefined,
      paymentDate: today,
      paymentStatus: undefined,
      billingCustomerId: undefined,
      exportStatus: undefined,
      ...initialData
    };

    this.PaymentService = new PaymentService();
    this.state = {
      formService: () => console.log('No form service'),
      formData,
      isSubmitting: false,
      syncStatus: undefined,
      exportStatus: undefined,
      accountingIntegration: undefined
    };
  }

  componentDidMount = () => {
    const accountingIntegration = getTenantSettingValueForKey('accountingApp');
    if (accountingIntegration) {
      this.setState({ accountingIntegration });
    }
  };

  setModalHeaderButtons = accountingIntegration => [
    <SplitButton
      color="primary"
      disabled={this.state.isSubmitting}
      key="save"
      label="Save Payment"
      options={
        accountingIntegration && [
          { label: 'Save & Post Payment', onClick: this.handleSavePost },
          { label: 'Save & Bypass', onClick: this.handleSaveBypass }
        ]
      }
      testingid="save-payment"
      onClick={this.handleSave}
    />
  ];

  handleOnComplete = async completedFormData => {
    if (!completedFormData) return;
    const { syncStatus, exportStatus } = this.state;
    const { tenantId, tenantCompanyId } = this.props.user;
    const {
      paymentNumber,
      paymentAmount,
      paymentDate,
      billingCustomerId,
      paymentType,
      invoices,
      accountTypeDisplay,
      bankAccountId,
      glAccountId
    } = completedFormData;
    const processedPaymentInvoices = handlePaymentInvoices(invoices);
    const accountDetail = handleAccountDetail(accountTypeDisplay, bankAccountId, glAccountId);

    const payload = {
      companyId: tenantCompanyId,
      payments: [
        {
          paymentNumber: parseInt(paymentNumber, 10),
          paymentAmount: parseFloat(paymentAmount),
          paymentDate: parseInt(paymentDate, 10),
          billingCustomerId,
          paymentTypeId: paymentType,
          syncStatus,
          exportStatus,
          ...processedPaymentInvoices,
          ...accountDetail
        }
      ]
    };

    this.setState({ isSubmitting: true });
    try {
      const payments = await this.PaymentService.upsertPaymentsToCompany(tenantId, payload);
      if (payments?.[0]) {
        this.props.snackbarOn(
          'success',
          `Successfully created Payment ${completedFormData.paymentNumber || ''}`
        );
        sendMixpanelEvent(MIXPANEL_EVENT.CREATED_PAYMENT, MIXPANEL_PAGE_NAME.PAYMENTS, {
          numberOfInvoicesAppliedTo: processedPaymentInvoices.createPaymentInvoices.length
        });

        const { id } = payments[0];
        this.props.handleClose();
        if (this.props.onSubmit) this.props.onSubmit();
        if (!this.props.initialData.invoiceId) this.props.history.push(`/payments/view/${id}`);
      }
    } catch (error) {
      logErrorWithCallback(
        error,
        this.props.snackbarOn,
        'Unable to create payment. Please try again.'
      );
    }
    this.setState({ isSubmitting: false });
  };

  handleSave = () => {
    this.state.formService.submit();
  };

  handleSavePost = () => {
    this.setState({ syncStatus: SyncStatus.SYNCING });
    this.state.formService.submit();
  };

  handleSaveBypass = () => {
    this.setState({ syncStatus: SyncStatus.BYPASSED, exportStatus: ExportStatus.BYPASSED });
    this.state.formService.submit();
  };

  render() {
    const { open, handleClose } = this.props;
    const { formData, accountingIntegration } = this.state;
    return (
      <FullScreenModal
        fixedHeader
        handleClose={handleClose}
        modalHeaderButtons={this.setModalHeaderButtons(accountingIntegration)}
        open={open}
        title="New Payment"
      >
        <PaymentForm
          data={formData}
          isEditMode
          onComplete={completedFormData => this.handleOnComplete(completedFormData)}
          onCreateService={service => this.setState(() => ({ formService: service }))}
        />
      </FullScreenModal>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});

const mapPaymentsToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const reduxConnectedCreatePayment = connect(mapStateToProps, mapPaymentsToProps)(CreatePayment);

export default reduxConnectedCreatePayment;
