import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { useSnackbar } from 'customHooks/useSnackbar';
import { logErrorWithCallback } from 'utils';

const undismissTimesheetEntryBinders = gql`
  mutation undismissTimesheetEntryBinders($data: DismissTimesheetEntryBindersInput!) {
    undismissTimesheetEntryBinders(data: $data) {
      id
    }
  }
`;

const serializer = data => {
  return {
    variables: {
      data: {
        timesheetEntryBinders: data.map(event => ({
          id: event.binderId
        }))
      }
    }
  };
};

const useUndismissTimesheetEntryBinders = () => {
  const snackbar = useSnackbar();

  return useExtendedMutation(undismissTimesheetEntryBinders, {
    serializer,
    onError: error => {
      logErrorWithCallback(error, snackbar, 'Unable to add entry to timesheet');
    },
    onCompleted: () => {
      snackbar('success', 'Successfully added entry to timesheet');
    }
  });
};

export default useUndismissTimesheetEntryBinders;
