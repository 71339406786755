import _ from 'lodash';

import Context from 'components/Context';
import { fetchInlineForm } from 'scenes/Customer/CustomerDetail/utils';
import { getActiveServiceAgreementsForCustomer } from 'scenes/ServiceAgreements/DetailView/service';
import { CommonService, CustomerService, JobService } from 'services/core';
import { Logger } from 'services/Logger';
import { getTenantSettingValueForKey } from 'utils';
import { AppConstants, EmployeeStatus } from 'utils/AppConstants';
import { CustomFieldTypes, DEFAULT_PRICEBOOK_MSG } from 'utils/constants';
import customJobNumberEvaluator from 'utils/evaluator';

export const getJobTypeCounter = async ({ jobType, jobTypes, user, ...props }) => {
  const chosenJobTypeId = (jobTypes || []).find(item => item.tagName === jobType)?.id;

  const jobService = new JobService();
  const { data, error } = await jobService.getJobTypeCurrentCounterJob(
    chosenJobTypeId,
    user.tenantId
  );
  if (data) {
    const jobTypeCounter = data?.getJobTypeCounterWithoutIncrement?.currentCounter || '';
    return jobTypeCounter ? (jobTypeCounter || 0) + 1 : null;
  }
  if (error) {
    props.snackbarOn(
      'error',
      'Unable to generate job number for the job type, please try again later',
      error
    );
  }
};

export const performQuery = async ({
  recordSortKey,
  selectedRep,
  user,
  defaultJobTypeJobCounter,
  setState,
  selectedRepMap,
  fieldName,
  hasServiceAgreements,
  billingCustomerId,
  ...props
}) => {
  // on first load, the tenant id was not set, hence the check
  if (user && user.tenantId === '') {
    return null;
  }
  let data;
  let customExpressionSetting = {};
  const customerService = new CustomerService();
  try {
    ({ data } = await customerService.getPropertiesAndRepsByCustomer(
      user.tenantId,
      recordSortKey,
      billingCustomerId,
      true // include our reps
    ));

    if (data) {
      // processing data for avoiding querypath in the field
      data.getCustomer.customerProperties.items.forEach(property => {
        const localProperty = property;
        const processedCustomerReps = [];
        const processedTenantReps = [];
        property.customerReps.items.forEach(rep => {
          processedCustomerReps.push(rep.mappedEntity);
        });
        property.tenantReps.items.forEach(rep => {
          processedTenantReps.push(rep.mappedEntity);
        });
        localProperty.processedCustomerReps = processedCustomerReps;
        localProperty.processedTenantReps = processedTenantReps;
      });
      let isCustomJobNumberEnabled;
      let isJobTypeCounterEnabled;
      let sync;
      let isQuickbookEnabled;
      let accelerateVisitProcess;
      let defaultPriceBookId;
      let priceBooks;
      let serviceAgreements;
      let showTotalBudgetedHours;
      let { departments, jobTypes, sortedJobTypes, salesEmployees } = {};
      const employees = await new CommonService().getEmployees({
        partitionKey: user.tenantId,
        sortKey: `${user.tenantId}_Company_${user.tenantCompanyId}`,
        filter: { isActive: { eq: true } },
        includeOffschedules: false,
        includeDepartments: true,
        includeAppRole: false,
        includeBillingRate: false,
        includePayrollCost: false
      });
      const companyContext = Context.getCompanyContext();
      const activeEmployees = { items: [] };
      if (companyContext?.getCompany) {
        ({
          departments,
          jobTypes,
          defaultPriceBookId,
          priceBooks
        } = Context.getCompanyContext().getCompany);
        jobTypes.items = jobTypes.items?.filter(type => type.tagType === CustomFieldTypes.JobTypes);
        activeEmployees.items =
          employees
            ?.map(emp => ({ ...emp, name: emp.name || `${emp.firstName} ${emp.lastName}` }))
            ?.filter(emp => emp.status === EmployeeStatus.ACTIVE) || [];

        sortedJobTypes = _.orderBy(jobTypes?.items || [], ['sortOrder'], ['asc']);

        salesEmployees = activeEmployees.items.filter(emp => emp.isSales === true);

        customExpressionSetting = getTenantSettingValueForKey('custom_job_expression');
        const quickbooksSetting = getTenantSettingValueForKey('accountingApp');
        sync = getTenantSettingValueForKey('syncImmediately') === 'true';
        isQuickbookEnabled = quickbooksSetting === 'quickbooks';

        isCustomJobNumberEnabled = getTenantSettingValueForKey('job_customJobNumber') === 'true';
        isJobTypeCounterEnabled = getTenantSettingValueForKey('jobTypeSequence') === 'true';
        accelerateVisitProcess = getTenantSettingValueForKey('accelerateVisitProcess') === 'true';
        showTotalBudgetedHours =
          getTenantSettingValueForKey('budgetLaborHoursAtJobLevel') === 'true';
      }

      const tempProperties = data.getCustomer.customerProperties.items;
      let newDefaultJobTypeJobCounter;

      if (isJobTypeCounterEnabled && !defaultJobTypeJobCounter) {
        newDefaultJobTypeJobCounter = await getJobTypeCounter({
          jobType: sortedJobTypes[0].tagName,
          jobTypes: sortedJobTypes,
          user,
          snackbarOn: props.snackbarOn
        });
      }

      if (hasServiceAgreements) {
        try {
          serviceAgreements = await getActiveServiceAgreementsForCustomer(
            user.tenantId,
            user.tenantCompanyId,
            data.getCustomer.id
          );
        } catch (e) {
          Logger.error(e);
        }
      }

      const customerReps = [
        ...data.getCustomer.customerReps.items,
        ...(data.getBillingCustomerById?.customerReps.items || [])
      ];

      const customerTenantReps = (data?.getCustomer?.tenantReps?.items || []).map(
        item => item.mappedEntity
      );

      const newState = {
        customJobNumber: isCustomJobNumberEnabled,
        sync,
        departments,
        defaultPriceBookId: data.getCustomer.priceBookId || defaultPriceBookId,
        priceBooks: priceBooks?.items.map(pb => ({ id: pb.id, name: pb.name })),
        sortedJobTypes,
        employees: activeEmployees,
        salesEmployees,
        isCustomJobNumberEnabled,
        isQuickbookEnabled,
        customExpressionSetting,
        isJobTypeCounterEnabled,
        tempProperties,
        customerReps,
        customerTenantReps,
        customerSortKey: data.getCustomer.sortKey,
        customerName: data.getCustomer.customerName,
        customerId: data.getCustomer.id,
        billingCustomerId: data.getBillingCustomerById?.id ?? data.getCustomer.id,
        billingCustomerName:
          data.getBillingCustomerById?.customerName ?? data.getCustomer.customerName,
        defaultJobTypeJobCounter: newDefaultJobTypeJobCounter || '',
        accelerateVisitProcess,
        showTotalBudgetedHours,
        selectedRepMap: {
          ...selectedRepMap,
          [`${fieldName}SelectedRep`]: selectedRep || ''
        },
        fieldName,
        serviceAgreements,
        customerPriceBookId: data.getCustomer.priceBookId
      };

      setState(newState);
    }
  } catch (error) {
    Logger.error(error);
    props.snackbarOn('error', 'Unable to retrieve customer details, please try again later', error);
  }

  return data;
};

export const queryAndSetCustomFormFields = async ({
  user,
  setFormAttributes,
  setCustomInlineForm,
  setConvertedValidationSchema,
  ...props
}) => {
  const companySortKey = `${user.tenantId}_Company_${user.tenantCompanyId}`;
  try {
    const { formAttributes, inlineForm } = await fetchInlineForm(
      user.tenantId,
      companySortKey,
      'Job'
    );

    if (formAttributes) {
      setFormAttributes(formAttributes);
      setCustomInlineForm(inlineForm?.formMeta);
    }
  } catch (err) {
    Logger.error(err);
    props.snackbarOn('error', 'Unable to fetch custom fields in the form', err);
  }
};

export const checkCustomJobNumberExists = async customJobNumber => {
  const jobService = new JobService();
  if (!customJobNumber) {
    return '';
  }

  const isCustomNumberExist = await jobService.checkCustomJobNumberExists(`${customJobNumber}`);
  if (!isCustomNumberExist) {
    return '';
  }

  return 'This job number has already been assigned';
};

// @TODO: ideal places to have the below methods in generic helper
const customFieldPrefix = 'Custom_';
export const getCustomFormPayload = (form, data) => {
  const customFormData = {};
  const customFields = Object.keys(data).filter(key => key.startsWith(customFieldPrefix));
  customFields.forEach(fieldName => {
    // changing text1: 'something' to Custom_text1: 'something'
    // Custom_ will used to identify custom fields, it will picked and send as formData in the job payload
    customFormData[`${fieldName.replace(customFieldPrefix, '')}`] = data[fieldName];
  });
  customFormData.formDefinitionSortKey = form?.formDefinitionSortKey;
  customFormData.formSortKey = form?.formSortKey;
  customFormData.formId = form?.formId;
  customFormData.formDefinitionId = form?.formDefinitionId;
  return customFormData;
};

export const getFormattedData = ({
  history,
  customerName,
  customerId,
  billingCustomerId,
  billingCustomerName,
  customerPropertyId,
  formValues,
  customExpressionSetting,
  accountingJobNumberExpression,
  defaultPriceBookId,
  jobNumber,
  customJobNumber,
  properties,
  isJobTypeCounterEnabled,
  defaultJobTypeJobCounter,
  hasServiceAgreements,
  hasMultiplePricebooks,
  jobTypes,
  selectedRepMap,
  differentBillingCustomer,
  billingCustomerFromProperty,
  forceCertifiedPayrollJobs,
  serviceAgreementsForProperty = [],
  ourReps = [],
  populateSAForNewJob = false,
  setDefaultPriceBookMsg,
  defaultPriceBookMsg,
  priceBookChanged,
  currPriceBookId,
  setPriceBookChanged,
  customerPriceBookId
}) => {
  const { customerSortKey } = history.location.state || {};
  let company;
  if (Context.getCompanyContext()?.getCompany) {
    const { companyName, email, phonePrimary, websiteUrl } = Context.getCompanyContext().getCompany;
    company = { companyName, email, phonePrimary, websiteUrl };
  }
  let data = {
    customerName,
    billingCustomerId,
    billingCustomerName,
    customerId,
    company,
    differentBillingCustomer,
    billingCustomerFromProperty,
    certifiedPayroll: forceCertifiedPayrollJobs,
    defaultPriceBookMsg
  };

  if (customerPropertyId || formValues.customerPropertyId) {
    const propertyPricebookId = (properties || []).find(p => p.id === customerPropertyId)
      ?.priceBookId;
    data = {
      ...data,
      customerPropertyId: customerPropertyId || formValues.customerPropertyId,
      priceBookId: propertyPricebookId
    };
    if (
      propertyPricebookId &&
      !priceBookChanged &&
      defaultPriceBookMsg !== DEFAULT_PRICEBOOK_MSG.PROPERTY
    ) {
      setDefaultPriceBookMsg(DEFAULT_PRICEBOOK_MSG.PROPERTY);
    } else if (priceBookChanged && propertyPricebookId === currPriceBookId) {
      setPriceBookChanged(false);
    }
  }

  let customJobNumberExpression;

  try {
    customJobNumberExpression =
      customExpressionSetting && typeof customExpressionSetting === 'string'
        ? JSON.parse(customExpressionSetting)
        : customExpressionSetting || '';
  } catch (err) {
    Logger.error(err);
    Logger.info('Unable to parse custom job number expresstion setting');
  }

  data.jobNumber = jobNumber;
  data.customerSortKey = customerSortKey;
  data.customJobNumber = customJobNumber;
  data.disableCustomJobNumber = !!isJobTypeCounterEnabled || !!customJobNumberExpression;
  data.computeBasedOnJobNumber = !isJobTypeCounterEnabled;
  data.customJobNumberExpression = customJobNumberExpression;
  data.defaultJobTypeJobCounter = defaultJobTypeJobCounter;
  data.customerFilter = {
    entityType: ['Customer'],
    status: [AppConstants.ACTIVE]
  };
  data.detailedJobCostingEnabled = false;
  data.customerProvidedPONumber = '';
  data.jobTypeId = jobTypes?.length > 0 ? jobTypes[0].value : '';

  if (!_.isEmpty(formValues)) {
    data = { ...data, ...formValues };
  }

  if (data && !data.customerPropertyId && properties && !_.isEmpty(properties)) {
    data.customerPropertyId = properties[0].value;
    if (
      properties[0].priceBookId &&
      !priceBookChanged &&
      defaultPriceBookMsg !== DEFAULT_PRICEBOOK_MSG.PROPERTY
    ) {
      setDefaultPriceBookMsg(DEFAULT_PRICEBOOK_MSG.PROPERTY);
    }
  }
  if (!hasServiceAgreements) data.hasServiceAgreements = '';
  if (!hasMultiplePricebooks) data.hasMultiplePricebooks = '';
  if (selectedRepMap.customerRepIdSelectedRep)
    data.customerRepId = selectedRepMap.customerRepIdSelectedRep;

  if (selectedRepMap.authorizedByIdSelectedRep)
    data.authorizedById = selectedRepMap.authorizedByIdSelectedRep;

  if (customJobNumber && customJobNumberExpression) {
    try {
      data.customIdentifier =
        customJobNumberEvaluator(customJobNumberExpression, {
          jobNumber: parseInt(jobNumber, 10),
          jobType: jobTypes?.length > 0 ? jobTypes[0].tagName : undefined
        }) || jobNumber;
    } catch (e) {
      data.customIdentifier = jobNumber;
    }
  }

  if (accountingJobNumberExpression) {
    data.accountingJobNumber = customJobNumberEvaluator(accountingJobNumberExpression);
  }

  if (
    populateSAForNewJob &&
    Array.isArray(serviceAgreementsForProperty) &&
    serviceAgreementsForProperty.length === 1
  ) {
    data.serviceAgreementId = serviceAgreementsForProperty[0].id;
    if (!data.priceBookId && serviceAgreementsForProperty[0].pricebookId) {
      data.priceBookId = serviceAgreementsForProperty[0].pricebookId;
    }
  }

  if (!data.priceBookId) {
    data.priceBookId = defaultPriceBookId;
    if (
      customerPriceBookId &&
      defaultPriceBookId === customerPriceBookId &&
      defaultPriceBookMsg !== DEFAULT_PRICEBOOK_MSG.CUSTOMER
    ) {
      setDefaultPriceBookMsg(DEFAULT_PRICEBOOK_MSG.CUSTOMER);
    } else if (priceBookChanged && customerPriceBookId === currPriceBookId) {
      setPriceBookChanged(false);
    }
  }

  if (ourReps.length === 1) {
    data.accountManagerId = ourReps[0].id;
  }

  return data;
};

export const getServiceAgreementsOptionsForProperty = (propertyId, saList) => {
  const propertySpecificSAs = saList
    ?.map(sa => {
      const propertyJsonArr = JSON.parse(sa.propertiesJSON || '[]');
      const isPropertyAddedInSA = propertyJsonArr.find(item => item.value === propertyId);
      return isPropertyAddedInSA ? sa : null;
    })
    ?.filter(Boolean);
  const saOptions = propertySpecificSAs?.map(sa => ({
    ...sa,
    label: `${sa.agreementNumber}-${sa.agreementName}`,
    value: sa.id
  }));
  return saOptions;
};

export const selectPriceBookForSA = (form, priceBook) => {
  if (priceBook?.priceBookId) {
    form.setFieldValue('priceBookId', priceBook);
  }
};

export const isTechnician = employee => employee?.status === 'active' && employee?.isTech;

export const getCombinedOurReps = (
  customerTenantReps = [],
  propertiesWithOurReps,
  customerPropertyId
) => {
  if (!Array.isArray(propertiesWithOurReps)) return [];
  const propertyOurReps =
    propertiesWithOurReps?.find(p => p.id === customerPropertyId)?.processedTenantReps || [];
  return _.uniqBy(customerTenantReps.concat(propertyOurReps), 'id').map(cr => ({
    ...cr,
    label: cr.name,
    value: cr.id
  }));
};
