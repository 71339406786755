import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';

const GET_PRICEBOOK_BY_ID = gql`
  query getPricebookById($id: String!) {
    data: getPricebookById(id: $id) {
      id
      name
      markupType
      markupValue
    }
  }
`;

const transform = response => {
  return response?.data || {};
};

export const usePricebookById = id => {
  return useExtendedQuery(GET_PRICEBOOK_BY_ID, {
    transform,
    skip: !id,
    variables: {
      id
    }
  });
};
