import React, { useState } from 'react';

import { any, bool, object } from 'prop-types';
import { useSelector } from 'react-redux';

import AlgoliaSearch from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import { useLazyFetchPricebookEntry } from 'components/Tasks/components/useLazyFetchPricebookEntry';
import { useGetPricebookById } from 'customHooks/useGetPricebookById';
import feeLayoutSergeant from 'meta/Jobs/Invoice/FeeFormSergeant';
import { selectReviewReportFees } from 'scenes/Jobs/JobDetail/selectors';

import { calculateUnitPriceWithMarkupValue, determineMarkupValue } from 'utils/pricebooks';
import withLazyMount from 'utils/withLazyMount';

import ReportTable from '../../../ReportTable';

import { useReviewReportDisabled } from '../../ReviewReport.contexts';
import TableRowModal from '../TableRowModal';

import FeesSectionHeader from './components/FeesSectionHeader';
import {
  useAddReviewReportFee,
  useDeleteReviewReportFee,
  useUpdateReviewReportFee
} from './mutations';
import { useFeesTableColumns, useGetProductFilter } from './ReviewReportFees.hooks';

const ReviewReportFees = ({ reviewReport, job, loading, error }) => {
  const tenantId = useSelector(state => state.user)?.tenantId;
  const defaultPricebook = useSelector(state => state.company)?.defaultPricebook;
  const { disabled } = useReviewReportDisabled();
  const rows = selectReviewReportFees(reviewReport) || [];
  const productFilters = useGetProductFilter({ job });

  const fetchPricebookEntry = useLazyFetchPricebookEntry();
  const getPricebookById = useGetPricebookById();
  const handleOnItemSelection = async (selectedItem, selectedItemName, form) => {
    const { values } = form;
    if (!selectedItem)
      return form.setValues({
        ...values,
        itemName: '',
        taxable: false,
        amount: '',
        productId: ''
      });
    const markupValue = await determineMarkupValue({
      fetchPricebookEntry,
      getPricebookById,
      pricebookId: job?.priceBookId || defaultPricebook.id,
      productSortKey: selectedItem.sortKey,
      unitCost: selectedItem.unitCost
    });
    const valuesToSet = {
      ...values,
      itemName: selectedItem.name,
      taxable: selectedItem?.taxable || false,
      amount: calculateUnitPriceWithMarkupValue({ unitCost: selectedItem.unitCost, markupValue }),
      productId: selectedItem.id
    };
    form.setValues(valuesToSet);
  };

  // Functions and mutation for adding Fee
  const [handleAddFee, { loading: addLoading }] = useAddReviewReportFee(reviewReport.id);
  const [feeToAdd, setFeeToAdd] = useState(null);
  const showAddModal = row => setFeeToAdd(row);
  const closeAddModal = () => setFeeToAdd(null);
  const handleAdd = async data => {
    await handleAddFee({
      tenantId,
      reviewReportId: reviewReport?.id,
      fee: data
    });
    closeAddModal();
  };

  // Functions and mutation for updating Fee
  const [handleUpdateFee, { loading: updateLoading }] = useUpdateReviewReportFee();
  const [feeToUpdate, setFeeToUpdate] = useState(null);
  const showEditModal = row => setFeeToUpdate(row);
  const closeEditModal = () => setFeeToUpdate(null);
  const handleEdit = async ({ _rowIndex, ...data }) => {
    await handleUpdateFee({
      tenantId,
      fee: data
    });
    closeEditModal();
  };

  // Mutation for deleting Fee
  const [handleDelete, { loading: deleteLoading }] = useDeleteReviewReportFee(reviewReport.id);

  const handleIncludeInInvoiceChange = row => event =>
    handleUpdateFee({
      tenantId,
      fee: {
        ...row,
        includeInInvoice: event.target.checked
      }
    });

  const handleIncludeInInvoiceAllChange = event => {
    const { checked } = event.target;
    return Promise.all(
      rows
        .filter(row => (checked ? !row.includeInInvoice : row.includeInInvoice))
        .map(row =>
          handleUpdateFee({
            tenantId,
            fee: {
              ...row,
              includeInInvoice: event.target.checked
            }
          })
        )
    );
  };

  const columns = useFeesTableColumns({
    showAddModal,
    showEditModal,
    handleDelete,
    tenantId,
    updateLoading,
    rows,
    handleIncludeInInvoiceChange,
    handleIncludeInInvoiceAllChange,
    disabled
  });

  const renderTitle = () => (
    <FeesSectionHeader
      onAddFees={() => {
        showAddModal({});
      }}
    />
  );

  return (
    <>
      <ReportTable
        columns={columns}
        error={error}
        loading={loading || addLoading || updateLoading || deleteLoading}
        noDataMessage="No Fees"
        renderTitle={renderTitle}
        rows={rows}
      />
      <TableRowModal
        customComponents={{ AlgoliaSearch }}
        data={feeToUpdate}
        layout={feeLayoutSergeant(productFilters, handleOnItemSelection)}
        open={Boolean(feeToUpdate)}
        title="Edit Fee"
        onAction={handleEdit}
        onClose={closeEditModal}
      />
      <TableRowModal
        customComponents={{ AlgoliaSearch }}
        data={feeToAdd}
        layout={feeLayoutSergeant(productFilters, handleOnItemSelection)}
        open={Boolean(feeToAdd)}
        title="Add Fee"
        onAction={handleAdd}
        onClose={closeAddModal}
      />
    </>
  );
};

ReviewReportFees.propTypes = {
  reviewReport: object.isRequired,
  job: object.isRequired,
  loading: bool.isRequired,
  error: any
};
ReviewReportFees.defaultProps = {
  error: undefined
};

export default withLazyMount(ReviewReportFees);
