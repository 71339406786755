import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { getCostCodes } from 'customHooks/useCostCodes';
import { getJobCostTypes, splitJobCostTypes } from 'customHooks/useLabourTypeDependency';
import { useSnackbar } from 'customHooks/useSnackbar';
import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';

export const useS3ImageUrl = (imageUrl, options) => {
  const [s3ImageUrl, setS3ImageUrl] = useState();
  useEffect(() => {
    const fetchS3ImageUrl = async () => {
      try {
        const storageService = new StorageService();
        const url = await storageService.getFile(imageUrl);
        setS3ImageUrl([{ fileUrl: url }]);
      } catch (error) {
        Logger.error(error);
        setS3ImageUrl([{ fileUrl: null }]);
      }
    };
    if (!options?.skip && imageUrl) {
      fetchS3ImageUrl();
    }
  }, [imageUrl, options]);
  return s3ImageUrl;
};

const useFetchDataWrapper = (fetchData, options) => {
  const { tenantId, tenantCompanyId } = useSelector(state => state.user);
  const snackbar = useSnackbar();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    (async () => {
      if (!options?.skip) {
        const result = await fetchData(tenantId, tenantCompanyId, snackbar);
        setData(result);
        setLoading(false);
      }
    })();
  }, [fetchData, tenantId, tenantCompanyId, snackbar, options]);
  return { data, loading };
};

export const useCostCodes = options => {
  return useFetchDataWrapper(getCostCodes, options);
};

export const useCostTypes = options => {
  const { data: costTypes, loading } = useFetchDataWrapper(getJobCostTypes, options);
  const { jobCostTypes, revenueTypes } = splitJobCostTypes(costTypes || []);
  return {
    data: {
      jobCostTypes,
      revenueTypes
    },
    loading
  };
};
