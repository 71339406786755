import React from 'react';

import { Button, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { ButtonGroup } from '@material-ui/core';
import classnames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { bool, func, string } from 'prop-types';

import { VisitActions } from '@dispatch/Dispatch.constants';
import { VISIT_DETAILS_PROP } from '@dispatch/queries';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { useStyles } from './StatusButtonGroup.styles';

const VisitActionButton = ({ action, className, loading, visit, handleVisitTransition }) => {
  const classes = useStyles();
  const Action = VisitActions.get(action);

  const isAllowed = Boolean(
    visit.nextEvents.find(availableAction => availableAction === Action.key)
  );

  const handleClick = () => {
    handleVisitTransition({ transitionAction: Action.key });
  };

  return (
    <Button
      className={classnames(classes.button, className)}
      disabled={!isAllowed}
      loading={loading}
      size="small"
      type={isAllowed ? 'tertiary' : 'secondary'}
      onClick={handleClick}
    >
      {Action?.value}
    </Button>
  );
};

VisitActionButton.propTypes = {
  action: VisitActions.propType().isRequired,
  // className provided by ButtonGroupComponent
  // eslint-disable-next-line react/require-default-props
  className: string,
  handleVisitTransition: func.isRequired,
  loading: bool.isRequired,
  visit: VISIT_DETAILS_PROP.isRequired
};

const StatusButtonGroup = ({ visit, loading, handleVisitTransition }) => {
  const flags = useFlags();
  const isVisitCloseoutOn = flags?.[FeatureFlags.VISIT_CLOSEOUT_ACTIONS];
  const classes = useStyles({ isVisitCloseoutOn });

  return (
    <ThemeProvider>
      <div className={classes.root}>
        <Typography variant={TV.S1} weight={TW.REGULAR}>
          Change Visit Status:
        </Typography>
        <ButtonGroup className={classes.buttonGroup}>
          {!isVisitCloseoutOn && (
            <VisitActionButton
              action={VisitActions.PUT_ON_HOLD.key}
              handleVisitTransition={handleVisitTransition}
              loading={loading}
              visit={visit}
            />
          )}
          <VisitActionButton
            action={VisitActions.CANCEL.key}
            handleVisitTransition={handleVisitTransition}
            loading={loading}
            visit={visit}
          />
          <VisitActionButton
            action={VisitActions.COMPLETE.key}
            handleVisitTransition={handleVisitTransition}
            loading={loading}
            visit={visit}
          />
        </ButtonGroup>
      </div>
    </ThemeProvider>
  );
};

StatusButtonGroup.propTypes = {
  handleVisitTransition: func.isRequired,
  loading: bool.isRequired,
  visit: VISIT_DETAILS_PROP.isRequired
};

export default StatusButtonGroup;
