import React, { useMemo } from 'react';

import { Box } from '@material-ui/core';
import moment from 'moment';

import { VisitStatus } from 'utils/AppConstants';

const styles = {
  transitionHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    fontSize: 10,
    fontWeight: 400
  }
};

const formatDate = (unixDate, timeZone) =>
  !unixDate
    ? '-'
    : moment
        .unix(+unixDate)
        .tz(timeZone)
        .format('MMMM Do YYYY, h:mm:ss a');

const retrieveTechNames = techs => {
  const parsedTechs = JSON.parse(techs);
  if (Array.isArray(parsedTechs)) {
    return parsedTechs.map(t => t?.name || t).join(', ');
  }
  return [];
};

const EventTransitionLedger = ({ eventTransitionLedger, timeZone }) => {
  const transitionNote = useMemo(() => {
    if (!eventTransitionLedger) {
      return '';
    }

    const {
      oldStatus,
      newStatus,
      primaryTechsAdded = '[]',
      primaryTechsRemoved = '[]',
      additionalTechsAdded = '[]',
      additionalTechsRemoved = '[]',
      onHoldReason,
      oldStartDateTimeUTC,
      newStartDateTimeUTC,
      executedDateTime
    } = eventTransitionLedger;

    const formattedNote = [];

    if (oldStatus !== newStatus && newStatus === VisitStatus.ONHOLD) {
      formattedNote.push(`Event status changed from "${oldStatus}" to "${newStatus}"`);
    }

    if (onHoldReason) {
      formattedNote.push(`On hold reason: ${onHoldReason}`);
    }

    const parsedPrimaryTechsRemoved = retrieveTechNames(primaryTechsRemoved);
    if (parsedPrimaryTechsRemoved.length) {
      formattedNote.push(`Techs removed: ${parsedPrimaryTechsRemoved}`);
    }

    const parsedPrimaryTechsAdded = retrieveTechNames(primaryTechsAdded);
    if (parsedPrimaryTechsAdded.length) {
      formattedNote.push(`Techs added: ${parsedPrimaryTechsAdded}`);
    }

    const parsedAdditionalTechsRemoved = retrieveTechNames(additionalTechsRemoved);
    if (parsedAdditionalTechsRemoved.length) {
      formattedNote.push(`Additional techs removed: ${parsedAdditionalTechsRemoved}`);
    }

    const parsedAdditionalTechsAdded = retrieveTechNames(additionalTechsAdded);
    if (parsedAdditionalTechsAdded.length) {
      formattedNote.push(`Additional techs added: ${parsedAdditionalTechsAdded}`);
    }

    if (oldStartDateTimeUTC !== newStartDateTimeUTC) {
      formattedNote.push(`Event rescheduled: ${formatDate(newStartDateTimeUTC, timeZone)}`);
    }

    formattedNote.push(`Changed on ${formatDate(executedDateTime, timeZone)}`);

    return formattedNote.reduce((acc, cur) => [...acc, cur, <br />], []);
  }, [eventTransitionLedger, timeZone]);

  return (
    <div css={styles.transitionHeader}>
      <Box>{transitionNote}</Box>
    </div>
  );
};

export default EventTransitionLedger;
