import React from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import { Box, Divider, Typography } from '@material-ui/core';
import { flattenDeep, sortBy } from 'lodash';

import { ImageUpload, withLoading } from 'components';
import Dropdown from 'components/BuildHeroFormComponents/Dropdown';
import PlacesSearch from 'components/BuildHeroFormComponents/PlacesSearch';
import ImageComponent from 'components/ImageComponent';
import { CompanyService, validations } from 'services/core';
import { constructSelectOptions } from 'utils/constructSelectOptions';

import useAppRoles from '../../../useAppRoles';

import { filterSkillsOptions, getFormCompleteData, getLayout, getSkillDetails } from './helpers';
import layout from './layout';
import useStyle from './styles';
import UserName from './UserName';

const MuiFormWithLoading = withLoading(MUIForm);

const SectionTitle = ({ options }) => {
  const classes = useStyle();
  return (
    <Box display="flex" flex={1} flexDirection="column">
      <Typography style={{ textTransform: 'capitalize' }} variant="h5">
        {options.label}
      </Typography>
      <Divider classes={{ root: classes.blueDivider }} variant="fullWidth" />
    </Box>
  );
};

const SelectInputInfoView = ({ options, displayValue }) => {
  const classes = useStyle();
  const { valueSet } = options;

  const values = flattenDeep([displayValue]);
  const selectedValueLabels = valueSet.reduce((acc, { value, label }) => {
    if (values.includes(value)) {
      acc.push(label);
    }
    return acc;
  }, []);
  return (
    <Box display="flex" flexDirection="column">
      <Typography className={classes.customLabelTitle} variant="body2">
        {options.label}
      </Typography>
      <Typography style={{ textTransform: 'capitalize' }} variant="body1">
        {selectedValueLabels.join(', ') || '-'}
      </Typography>
    </Box>
  );
};

const EmployeeForm = ({
  user,
  snackbar,
  isViewMode,
  data,
  onFormService,
  onFormComplete,
  employeeCreated,
  onDepartmentChange,
  loading
}) => {
  const classes = useStyle();
  const [companySkills, setCompanySkills] = React.useState([]);
  const [companyAppRoles] = useAppRoles();
  const [companyDepartments, setCompanyDepartments] = React.useState([]);
  const [skillsOptions, setSkillsOptions] = React.useState([]);
  const [skillsDropdown, setSkillsDropdown] = React.useState([]);

  React.useEffect(() => {
    const fetchDepartmentsAndSkills = async () => {
      const response = await new CompanyService().getAllDepartmentsWithSkills(
        user.tenantId,
        `${user.tenantId}_Company_${user.tenantCompanyId}`
      );
      if (response?.data) {
        const { departments, skills } = response?.data?.getCompany;
        const processedSkills = getSkillDetails(skills?.items);
        setCompanySkills(processedSkills);
        setCompanyDepartments(sortBy(departments?.items, 'tagName'));
      }
    };
    if (user.tenantId) {
      fetchDepartmentsAndSkills();
    }
  }, [user.tenantCompanyId, user.tenantId]);

  React.useEffect(() => {
    const formattedSkills = companySkills.map(skill => ({
      label: skill.tagName,
      value: skill.id,
      departmentIds: skill.departmentIds
    }));
    setSkillsOptions(formattedSkills);
    const initialSkillOptions = filterSkillsOptions(formattedSkills, data.departments, true);
    setSkillsDropdown(initialSkillOptions);
  }, [companySkills, data.departments]);

  const profileImage = ({ form, field }) => {
    if (isViewMode) {
      const { value: imageUrl } = field;
      return imageUrl ? (
        <ImageComponent
          height={150}
          image={{ fileUrl: imageUrl, name: 'Profile Image' }}
          style={{ objectFit: 'contain' }}
        />
      ) : (
        '-'
      );
    }
    return <ImageUpload field={field} form={form} label="Add Image" user={user} />;
  };

  const rolesOptions = constructSelectOptions(companyAppRoles, 'tagName');
  const departmentOptions = constructSelectOptions(companyDepartments, 'tagName');

  const handleOnComplete = async _data => {
    const employeeData = getFormCompleteData({
      employee: _data,
      appSkills: skillsOptions,
      appRoles: rolesOptions,
      appDepartments: departmentOptions
    });
    await onFormComplete(employeeData);
  };

  const NonEditableElement = ({ placeholder, value }) => (
    <Box display="flex" flexDirection="column">
      <Typography className={classes.statusTitle} variant="body2">
        {placeholder}
      </Typography>
      <Typography className={classes.statusContent} variant="body1">
        {value || '-'}
      </Typography>
    </Box>
  );

  const Status = ({ options, form }) => {
    const { dataKey, placeholder, isBoolean } = options;
    const { values } = form;
    let visibleValue = values[dataKey];
    if (isBoolean) {
      visibleValue = values[dataKey] ? 'Active' : 'Inactive';
    }
    return <NonEditableElement placeholder={placeholder} value={visibleValue} />;
  };

  const formLayout = getLayout({ user, isViewMode });
  const userName = data.userName || data.email;

  return (
    <MuiFormWithLoading
      configuration={layout({
        skillsOptions,
        rolesOptions,
        departmentOptions,
        skillsDropdown,
        setSkillsDropdown,
        onDepartmentChange,
        employeeCreated
      })}
      customComponents={{
        Status,
        Dropdown,
        SectionTitle,
        PlacesSearch,
        SelectInputInfoView,
        LogoButton: profileImage,
        UserName: ({ ...userNameProps }) => <UserName {...userNameProps} snackbarOn={snackbar} />,
        EmptyElement: () => null
      }}
      data={{ ...data, userName }}
      isLoading={loading}
      layout={formLayout}
      validationSchema={validations.employeeSchema}
      onComplete={handleOnComplete}
      onCreateService={onFormService}
    />
  );
};

export default EmployeeForm;
