import React from 'react';

import { MenuItem, Paper, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactSelect from 'react-select';

import { styles as stylesFn } from './styles';

const Menu = ({ selectProps, innerProps, children }) => (
  <Paper className={selectProps.classes.paper} square {...innerProps}>
    {children}
  </Paper>
);

const Option = ({ innerRef, isFocused, innerProps, children, selectProps, ...rest }) => (
  <MenuItem
    buttonRef={innerRef}
    className={selectProps.classes.menuItem}
    component="div"
    selected={isFocused}
    testingid={`option${rest?.data?.index}`}
    {...innerProps}
  >
    <Typography variant="body2">{children}</Typography>
  </MenuItem>
);

const NoOptionsMessage = ({ innerProps, selectProps, children }) => (
  <Typography className={selectProps.classes.noOptionsMessage} variant="subtitle" {...innerProps}>
    {children}
  </Typography>
);

const ValueContainer = ({ isMulti, children, selectProps }) => (
  <div
    className={
      isMulti ? selectProps.classes.multiValueContainer : selectProps.classes.valueContainer
    }
  >
    {children}
  </div>
);

const Select = ({ testingid, components, styles, label, required, isGreyBackground, ...props }) => {
  const theme = useTheme();
  const useStyles = makeStyles(stylesFn);
  const classes = useStyles(props);

  return (
    <div>
      {label && (
        <div
          style={{ position: 'relative', paddingRight: required ? 60 : 0 }}
          testingid={testingid}
        >
          <Typography className={classes.inputLabel} gutterBottom variant="caption">
            {label || ''}
          </Typography>
          {required && (
            <Typography className={classes.requiredLabel} variant="caption">
              REQUIRED
            </Typography>
          )}
        </div>
      )}
      <ReactSelect
        classes={classes}
        styles={{
          control: (provided, state) => ({
            ...provided,
            width: props.inputWidth || '100%',
            minHeight: 35,
            paddingLeft: 10,
            paddingRight: props.isMulti ? 0 : 10,
            borderRadius: 0,
            borderWidth: state.isFocused ? 2 : 1,
            boxShadow: 0,
            borderColor: isGreyBackground
              ? theme.palette.grayscale(100)
              : theme.palette.grayscale(80),
            '&:hover': {
              borderColor: theme.palette.grayscale(0)
            },
            backgroundColor: isGreyBackground
              ? theme.palette.grayscale(94)
              : theme.palette.grayscale(100)
          }),
          menuList: (provided, state) => ({
            ...provided,
            height: props.menuHeight ?? 'unset',
            maxHeight: props.menuHeight ?? 300,
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: 0,
            marginBottom: 0
          }),
          placeholder: (provided, state) => ({
            ...provided,
            color: '#333'
          }),
          multiValueRemove: (provided, state) => ({
            ...provided,
            color: theme.palette.other.darkBlue
          }),
          ...styles
        }}
        {...props}
        components={{
          Menu,
          Option,
          IndicatorSeparator: () => null,
          ValueContainer,
          NoOptionsMessage,
          DropdownIndicator: () =>
            props.isMulti ? null : <ExpandMoreIcon style={{ fontSize: 22, marginTop: 0 }} />,
          ...components
        }}
      />
    </div>
  );
};

export default Select;
