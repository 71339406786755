import { gql } from '@apollo/client/core';
import { useSelector } from 'react-redux';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { generateCompanySortKey } from 'services/core/utils';

const GET_PAYROLL_SETTINGS = gql`
  query getPayrollSettings($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      companySettings(entityConnection: "CompanySetting", filter: { type: { eq: "PAYROLL" } }) {
        items {
          id
          type
          version
          settings
        }
      }
    }
  }
`;

const transform = data => {
  const settings = data?.getCompany?.companySettings?.items?.[0]?.settings;
  return settings ? JSON.parse(settings) : {};
};

const usePayrollSettings = (options = {}) => {
  const user = useSelector(state => state.user);
  const { data, loading, error, refetch } = useExtendedQuery(GET_PAYROLL_SETTINGS, {
    transform: options.transform ?? transform,
    variables: {
      partitionKey: user.tenantId,
      sortKey: generateCompanySortKey(user.tenantId, user.tenantCompanyId)
    }
  });

  return [data || {}, loading, error, refetch];
};

export default usePayrollSettings;
