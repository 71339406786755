import React, { memo, useEffect, useState } from 'react';

import Footer from './Footer';
import TableBody from './TableBody';
import { DEFAULT_ROWS_PER_PAGE, GroupingColumnMode } from './WrapTable.constants';
import { useNormalizedRows, useSortedColumns } from './WrapTable.hooks';
import { useRootStyles } from './WrapTable.styles';
import { WrapTableProps } from './WrapTable.types';

const WrapTable = ({
  boxSizing,
  columns,
  enableGroupHeaders,
  enableTotalsRow,
  hideFooter,
  loading,
  loadingRows,
  lockRowHeight,
  maxGroupingDepth,
  maxHeight,
  noDataMessage,
  noWrap,
  rowGroupingColumnMode,
  rowGroupingModel,
  rows: baseRows,
  scrollX,
  scrollY,
  numberOfRowsPerPage
}) => {
  const styles = useRootStyles(boxSizing);
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRowsPerPage);
  const [page, setPage] = useState(0);
  const [visibleRows, setVisibleRows] = useState([]);
  const sortedColumns = useSortedColumns({ columns, rowGroupingModel });
  const rows = useNormalizedRows({ baseRows, rowGroupingModel, columns: sortedColumns });
  const isFirstLoad = loading && !rows.length;

  useEffect(() => {
    const update = rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    setVisibleRows(update);
  }, [page, rowsPerPage, rows]);

  useEffect(() => {
    setPage(0);
  }, [rowsPerPage]);

  return (
    <section css={styles.root}>
      <TableBody
        {...{
          columns: sortedColumns,
          enableGroupHeaders,
          enableTotalsRow,
          isFirstLoad,
          loading,
          loadingRows,
          lockRowHeight,
          maxGroupingDepth,
          maxHeight,
          noDataMessage,
          noWrap,
          rowGroupingColumnMode,
          rowGroupingModel,
          rows,
          scrollX,
          scrollY,
          visibleRows
        }}
      />
      {!hideFooter && (
        <Footer {...{ rowsPerPage, setRowsPerPage, page, setPage, count: rows.length }} />
      )}
    </section>
  );
};

WrapTable.propTypes = WrapTableProps;

WrapTable.defaultProps = {
  boxSizing: 'border-box',
  loadingRows: DEFAULT_ROWS_PER_PAGE,
  numberOfRowsPerPage: DEFAULT_ROWS_PER_PAGE,
  lockRowHeight: false,
  hideFooter: false,
  rowGroupingColumnMode: GroupingColumnMode.SINGLE
};

export default memo(WrapTable);
