import React, { useEffect, useReducer, useState } from 'react';

import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid';

import DefaultButton from 'components/Buttons/DefaultButton';

import SergeantModal from 'components/SergeantModal';
import Labels from 'meta/labels';
import attachmentModalLayout from 'meta/ProjectManagement/global/attachmentModalForm';
import {
  getCloudinaryImageUrl,
  isImageAttachment
} from 'scenes/ProjectManagement/components/utils';
import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';
import { camelCaseToTitleCase, getUniqueName } from 'utils';

import buildHeroMuiFormOverrides from '../buildHeroMuiFormOverrides';
import CustomCarousel from '../CustomCarousel';
import CustomFileLists from '../CustomFileLists';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 17
  },
  title: {
    fontSize: 20,
    lineHeight: '20px',
    fontWeight: 700,
    letterSpacing: '-0.03em',
    marginBottom: 10
  },
  smallTitle: {
    fontSize: 12
  },
  noAttachment: {
    fontSize: 14,
    paddingLeft: 16,
    marginBottom: 10
  },
  addButton: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '14px',
    textDecoration: 'none',
    '& .MuiButton-iconSizeMedium': {
      marginLeft: 0,
      marginRight: 4
    },
    '& .MuiButton-iconSizeMedium > *:first-child': {
      fontSize: 20
    }
  },
  smallAddButton: {
    fontSize: 12,
    '& .MuiButton-iconSizeMedium > *:first-child': {
      fontSize: 14
    }
  },
  selectedImagesContainer: {
    margin: '20px 0px'
  },
  selectedFilesContainer: {
    margin: '20px 0px'
  },
  sectionTitle: {
    color: theme.palette.grayscale(20),
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: '-0.03em',
    marginBottom: 15
  },
  iconTextContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  uploadFileIcon: {
    color: theme.palette.grayscale(20),
    fontSize: 20
  },
  uploadFileText: {
    color: theme.palette.grayscale(20),
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '-0.02em',
    textDecoration: 'none',
    marginLeft: theme.spacing(0.5)
  },
  carouselNoImages: {
    color: theme.palette.grayscale(60),
    fontSize: 14
  },
  fileListContainer: {
    marginBottom: 15
  },
  formContainer: buildHeroMuiFormOverrides(theme)
}));

const initialState = {
  images: [],
  files: []
};

const uploadedFilesReducer = (state, action) => {
  switch (action.type) {
    case 'addNewImage':
      return { ...state, images: [...action.payload] };
    case 'addNewFile':
      return { ...state, files: [...action.payload] };
    case 'initialize':
      return { images: [], files: [] };
    default:
      return state;
  }
};

const Attachments = props => {
  const {
    attachedFiles,
    attachedFileCount,
    smallType,
    noTitle,
    smallButton,
    downloadable,
    noAddButton,
    uploadFileList,
    user,
    chunkSize,
    imageWidth,
    imageHeight
  } = props;
  const classes = useStyles();
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  // total files on the "Add Attachment" modal view
  const [attachmentLists, setAttachmentLists] = useState({ images: [], files: [] });
  // uploading completed files after clicking "Add Selected Attachments"
  const [uploadedFiles, dispatchUploadedFiles] = useReducer(uploadedFilesReducer, initialState);

  const uploadFile = async file => {
    try {
      const storageService = new StorageService();
      const uniqueName = getUniqueName(user.tenantId, file.name);
      const s3Response = await storageService.uploadFile(file, uniqueName, e => e, null);
      const fileUrl = await storageService.getFile(s3Response);
      return fileUrl;
    } catch (error) {
      Logger.error(error);
    }
  };

  const uploadAllFiles = async (images, files) => {
    const filesInfo = [...images, ...files];

    const result = await Promise.all(
      filesInfo
        .filter(item => item.file)
        .map(async item => {
          const fullUrl = await uploadFile(item.file);
          return {
            customFileName: '',
            fileName: item.file?.name || null,
            fileUrl: fullUrl || null,
            fileType: item.file?.type || item.file?.fileType
          };
        })
    );

    return result;
  };

  const getImageList = data => {
    if (!data || data?.length === 0) return [];
    return data
      .filter(image => isImageAttachment(image))
      .map(image => ({
        url: getCloudinaryImageUrl(image.fileUrl),
        file: image.file || undefined,
        name: image.fileName,
        description: '',
        newName: image.customFileName || '',
        selected: true
      }));
  };

  const getFileList = data => {
    if (!data || data?.length === 0) return [];
    return data
      .filter(
        file =>
          file.fileType?.includes('pdf') ||
          file.fileType?.includes('doc') ||
          file.type?.includes('pdf') ||
          file.type?.includes('doc')
      )
      .map(file => ({
        ...file,
        newName: file.customFileName || '',
        selected: true
      }));
  };

  const update = () => {
    if (!attachedFiles || !Array.isArray(attachedFiles)) return;

    const imageList = getImageList(attachedFiles);
    const fileList = getFileList(attachedFiles);

    dispatchUploadedFiles({ type: 'addNewImage', payload: imageList });
    dispatchUploadedFiles({ type: 'addNewFile', payload: fileList });
    setAttachmentLists({ images: [...imageList], files: [...fileList] });
  };

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(attachedFiles), attachedFileCount]);

  const handleModalClose = () => {
    setAttachmentModalOpen(false);
    // Temporarily commented out
    // setAttachmentLists({ images: [], files: [] });
  };

  const handleSelectedAttachment = async (data, stopLoading) => {
    const selectedImages = data.images.filter(item => item.selected === true);
    const selectedFiles = data.files.filter(item => item.selected === true);
    const attachmentsInfo = await uploadAllFiles(selectedImages, selectedFiles);
    if (uploadFileList)
      await uploadFileList(
        JSON.parse(JSON.stringify(attachmentsInfo)),
        JSON.parse(JSON.stringify(selectedImages)),
        JSON.parse(JSON.stringify(selectedFiles))
      );
    dispatchUploadedFiles({ type: 'addNewImage', payload: selectedImages });
    dispatchUploadedFiles({ type: 'addNewFile', payload: selectedFiles });
    handleModalClose();
    stopLoading();
  };

  const handleFieldChange = event => {
    const newLists = { ...attachmentLists };
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;

    if (selectedFile.type.includes('pdf') || selectedFile.type.includes('doc')) {
      newLists.files.push({
        file: selectedFile,
        name: selectedFile.name,
        description: '',
        newName: '',
        selected: true
      });
    } else if (selectedFile.type.includes('image')) {
      newLists.images.push({
        file: selectedFile,
        name: selectedFile.name,
        url: URL.createObjectURL(selectedFile),
        description: '',
        newName: '',
        selected: true
      });
    }

    setAttachmentLists(newLists);
  };

  const formUploadAttachmentBtn = () => {
    const id = uuid.v4();
    return (
      <ButtonBase focusRipple>
        <label htmlFor={id}>
          <input
            accept=".pdf,.doc,.docx,image/*"
            id={id}
            style={{
              display: 'none'
            }}
            type="file"
            onChange={e => handleFieldChange(e)}
          />
          <div className={classes.iconTextContainer}>
            <AddCircleOutlineIcon className={classes.uploadFileIcon} />
            <Typography className={classes.uploadFileText}>
              {camelCaseToTitleCase(Labels.uploadAttachment[user.locale])}
            </Typography>
          </div>
        </label>
      </ButtonBase>
    );
  };

  const formImageCarousel = () => {
    const handleSelectedImages = selectedImages => {
      setAttachmentLists(oldLists => ({ ...oldLists, images: selectedImages }));
    };

    return attachmentLists.images.length ? (
      <Box>
        <CustomCarousel
          chunkSize={4}
          imageData={attachmentLists.images}
          imageDataLength={attachmentLists.images.length}
          imageHeight={139}
          imageWidth={172}
          margin={15}
          selectable
          timeout={200}
          titleText="Select Field Photos"
          onSelect={handleSelectedImages}
        />
      </Box>
    ) : null;
  };

  const formFilesLists = () => {
    const handleSelectedFiles = selectedFiles => {
      return setAttachmentLists(oldLists => ({ ...oldLists, files: selectedFiles }));
    };

    return attachmentLists.files.length ? (
      <Box className={classes.fileListContainer}>
        <CustomFileLists
          fileData={attachmentLists.files}
          fileDataLength={attachmentLists.files.length}
          selectable
          titleText="Select Files"
          onSelect={handleSelectedFiles}
        />
      </Box>
    ) : null;
  };

  const sectionTitle = ({ options }) => (
    <Typography className={classes.sectionTitle} variant="subtitle1">
      {options.label}
    </Typography>
  );

  const title = () => {
    if (noTitle) {
      return;
    }
    return (
      <Typography className={classNames(classes.title, smallType && classes.smallTitle)}>
        Attachments
      </Typography>
    );
  };

  const addAttachmentButton = () => {
    if (smallButton) {
      return (
        <ButtonBase
          focusRipple
          onClick={() => {
            setAttachmentModalOpen(true);
          }}
        >
          <div className={classes.iconTextContainer}>
            <AddCircleOutlineIcon className={classes.uploadFileIcon} />
            <Typography className={classes.uploadFileText}> Add Attachment </Typography>
          </div>
        </ButtonBase>
      );
    }
    return (
      <DefaultButton
        disabled={false}
        label="Add Attachment"
        showSpinner={false}
        style={{ width: '150px', border: '1px solid black', borderRadius: '4px' }}
        variant="tertiary"
        onClick={() => {
          setAttachmentModalOpen(true);
        }}
      />
    );
  };

  return (
    <div className={classes.root}>
      {title()}
      {uploadedFiles.images.length > 0 ? (
        <Box className={classes.selectedImagesContainer}>
          <CustomCarousel
            chunkSize={chunkSize}
            downloadable={downloadable}
            imageData={uploadedFiles.images}
            imageDataLength={uploadedFiles.images.length}
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            margin={15}
            timeout={200}
          />
        </Box>
      ) : null}
      {uploadedFiles.files.length > 0 ? (
        <Box className={classes.selectedFilesContainer}>
          <CustomFileLists
            downloadable={downloadable}
            fileData={uploadedFiles.files}
            fileDataLength={uploadedFiles.files.length}
          />
        </Box>
      ) : null}
      {uploadedFiles.images.length === 0 && uploadedFiles.files.length === 0 && !noTitle ? (
        <Typography className={classes.noAttachment}>No Attachments</Typography>
      ) : null}
      {noAddButton ? null : addAttachmentButton()}
      <SergeantModal
        alignCloseRight
        customComponents={{
          sectionTitle,
          formUploadAttachmentBtn,
          formImageCarousel,
          formFilesLists
        }}
        customPrimaryButtonLabel="Add Selected Attachment(s)"
        data={attachmentLists}
        dataType="attachment"
        disablePrimaryButton={
          !(attachmentLists.images.length || attachmentLists.files.length) &&
          !(uploadedFiles.images.length || uploadedFiles.files.length)
        }
        handleClose={handleModalClose}
        handlePrimaryAction={handleSelectedAttachment}
        layout={attachmentModalLayout}
        maxWidth={859}
        mode="activate"
        open={attachmentModalOpen}
        title="Add Attachment(s)"
      />
    </div>
  );
};

Attachments.propTypes = {
  attachedFiles: PropTypes.array,
  attachedFileCount: PropTypes.number.isRequired,
  smallType: PropTypes.bool,
  smallButton: PropTypes.bool,
  noTitle: PropTypes.bool,
  downloadable: PropTypes.bool,
  noAddButton: PropTypes.bool,
  chunkSize: PropTypes.number,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  uploadFileList: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

Attachments.defaultProps = {
  attachedFiles: [],
  smallType: false,
  noTitle: false,
  smallButton: false,
  downloadable: false,
  noAddButton: false,
  chunkSize: 5,
  imageWidth: 200,
  imageHeight: 180
};

const mapStateToProps = state => ({ user: state.user });
const ReduxConnectedAttachments = connect(mapStateToProps)(Attachments);
export default ReduxConnectedAttachments;
