import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { useSnackbar } from 'customHooks/useSnackbar';
import { logErrorWithCallback } from 'utils';

export const getTimesheetDayCountByStatus = gql`
  query getTimesheetDayCountByStatus(
    $employeeId: String!
    $manualStatuses: [String!]
    $includeFutureTimesheets: Boolean
  ) {
    getTimesheetDayCountByStatus(
      employeeId: $employeeId
      manualStatuses: $manualStatuses
      includeFutureTimesheets: $includeFutureTimesheets
    )
  }
`;

const useTimesheetDayCountByStatus = (
  { employeeId, manualStatuses, includeFutureTimesheets = false },
  options = {}
) => {
  const snackbar = useSnackbar();

  const { data, loading, error, refetch } = useExtendedQuery(getTimesheetDayCountByStatus, {
    variables: { employeeId, manualStatuses, includeFutureTimesheets },
    transform: result => result?.getTimesheetDayCountByStatus,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !employeeId,
    ...options
  });

  if (error) {
    logErrorWithCallback(
      error,
      snackbar,
      `Unable to fetch number of ${JSON.stringify(manualStatuses)} timesheets`
    );
  }
  return [data || 0, loading, error, refetch];
};

export default useTimesheetDayCountByStatus;
