import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import { connect } from 'react-redux';

import ConfirmModal from 'components/Modal/ConfirmDialog';
import { attachmentRows } from 'meta/attachment-layout';
import { CommonService } from 'services/core';
import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';

import { AddRecordButton, Modal, Table } from '../../../../index';

import AttachmentModal from './AddAttachment';

/**
 * Creates customer page. new customer fields & layouts are generated from the meta file
 * labels are fetched from application level
 * locale of the user is referred from user context
 */
class AttachmentDetail extends Component {
  constructor(props) {
    super(props);
    this.CommonService = new CommonService();
    this.state = {
      openAttachment: false,
      dataRecord: '',
      modalMode: '',
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: ''
    };
  }

  handleOpenPopUp = (popUpKey, mode, record) => {
    this.setState({ [popUpKey]: true, modalMode: mode, dataRecord: record });
  };

  handleClosePopUp = popUpKey => {
    this.setState({ [popUpKey]: false });
  };

  performAttachmentDelete = async record => {
    if (!record) {
      return;
    }

    try {
      const { partitionKey, sortKey } = record;
      const { data } = await this.CommonService.deleteAttachment(partitionKey, sortKey);
      if (data) {
        const attachmentList = this.props.form.values[this.props.fieldProps.name];
        const modifiedAttachmentList = [];
        attachmentList.forEach(attachment => {
          if (attachment.id && attachment.id !== record.id) {
            modifiedAttachmentList.push(attachment);
          }
        });

        this.props.form.setFieldValue(this.props.fieldProps.name, modifiedAttachmentList, false);
        this.props.snackbarOn(
          'success',
          `Successfully deleted attachment ${record.fileName || ''}`
        );
        this.setState({
          confirmDialog: false,
          confirmAction: '',
          confirmMessage: ''
        });
      }
    } catch (error) {
      Logger.error(error);
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        this.props.snackbarOn('error', error.graphQLErrors[0].message);
      } else {
        this.props.snackbarOn('error', 'Unable to delete attachment, please try again later');
      }
    }
  };

  deleteFile = async fileName => {
    const storageService = new StorageService();
    const data = await storageService.deleteFile(fileName);
    if (data) {
      return true;
    }
    return false;
  };

  checkRemoteFileDelete = async record => {
    let isFileDeleted = record.fileName === '';
    if (!isFileDeleted) {
      isFileDeleted = await this.deleteFile(record.fileUrl);
    }
    if (isFileDeleted && record.sortKey) {
      await this.performAttachmentDelete(record);
    } else if (isFileDeleted) {
      const attachmentList = this.props.form.values[this.props.fieldProps.name];
      const newAttachmentList = attachmentList.filter(
        item => item.newRecordId !== record.newRecordId
      );
      this.setState({
        confirmDialog: false,
        confirmAction: '',
        confirmMessage: ''
      });
      this.props.form.setFieldValue(this.props.fieldProps.name, newAttachmentList, false);
    }
  };

  handleAttachmentRowActions = (mode, record) => {
    if (mode === 'delete') {
      this.setState({
        confirmDialog: true,
        confirmAction: () => this.checkRemoteFileDelete(record),
        confirmMessage: 'attachment'
      });
    } else {
      this.handleOpenPopUp('openAttachment', mode, record);
    }
  };

  handleCancelConfirmation = () => {
    this.setState({ confirmDialog: false, confirmAction: '', confirmMessage: '' });
  };

  handleAttachmentSave = record => {
    const attachmentList = this.props.form.values[this.props.fieldProps.name];
    let isAdded = false;
    attachmentList.forEach((attachment, index) => {
      if (attachment.id && attachment.id === record.id) {
        isAdded = true;
        attachmentList[index] = record;
      } else if (attachment.rowId && attachment.rowId === record.rowId) {
        isAdded = true;
        attachmentList[index] = record;
      } else if (attachment.newRecordId && attachment.newRecordId === record.newRecordId) {
        isAdded = true;
        attachmentList[index] = record;
      }
    });
    if (!isAdded) {
      attachmentList[attachmentList.length] = record;
    }

    this.props.form.setFieldValue(this.props.fieldProps.name, attachmentList, false);
  };

  render() {
    const { parent, form, fieldProps, caslKey } = this.props;
    // const { data } = this.state;

    // table definitions
    return (
      <Grid item xs={12}>
        <AddRecordButton
          caslKey={caslKey}
          handle={() => this.handleOpenPopUp('openAttachment', 'new', '')}
          icon={AddIcon}
          label="Add attachment"
        />
        <Table
          captionAttributes={attachmentRows({})}
          caslKey={caslKey}
          dataPathInQueryResult={fieldProps.name}
          idField="id"
          noDataMsg="No attachments"
          queryResult={form.values}
          rowActions={this.handleAttachmentRowActions}
          showRowButtons={['edit', 'delete']}
          showTableHeader={false}
          tableId="attachments"
        />

        <Modal
          handleClose={() => this.handleClosePopUp('openAttachment')}
          open={this.state.openAttachment}
          width="960"
        >
          <AttachmentModal
            addTo={this.handleAttachmentSave}
            caslKey={caslKey}
            data={this.state.dataRecord}
            handleClose={() => {
              this.handleClosePopUp('openAttachment');
            }}
            mode={this.state.modalMode}
            nextRowId={(this.props.form.values[this.props.fieldProps.name] || []).length + 1 || 1}
            parent={parent}
          />
        </Modal>
        <ConfirmModal
          cancel={this.handleCancelConfirmation}
          confirm={this.state.confirmAction}
          message={this.state.confirmMessage}
          open={this.state.confirmDialog}
        />
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});

const connectedAttachmentDetail = connect(mapStateToProps, null)(AttachmentDetail);

export default connectedAttachmentDetail;
