import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, Grid, Typography } from '@material-ui/core';

import { AddRecordButton } from 'components';
import useBillingHourTypes from 'customHooks/useBillingHourTypes';
import useLabourRates from 'customHooks/useLabourRates';
import useLabourTypes from 'customHooks/useLabourTypes';
import labourLineItems, { subtotalMeta } from 'meta/Jobs/LabourLineItems/table';

import { emptyFieldMessage, emptyFieldMessageForDelete } from './constants';
import {
  addTimesheetEmployeeToLineItem,
  checkLineItemPropEmpty,
  editLineItem,
  getLineItemsForLabourRateSheet,
  handleRemove,
  modifyLineItemMeta,
  newItemTemplate,
  overAllTotal
} from './helpers';

import useStyles from './LineItem.styles';
import { deleteLabourRateLineItem, getTimesheetByVisit, saveLabourRateLineItems } from './service';
import Table from './Table';

const LineItemTable = ({
  employees,
  data,
  user,
  snackbarOn,
  visitId,
  fetchReport,
  isDefaultRate,
  reviewReportId,
  departmentName,
  isEditable,
  timeCardLines,
  reportEditable,
  priceBookId,
  isLoading,
  setReportLoaded,
  reinitialize,
  triggerAddTimesheetEmployeesToLineItem,
  onInitializeComplete,
  onInitializeStart,
  onAddTimesheetEmployeeToLineItemStart,
  overwriteRatesOnInitialize,
  onChangeNumLineItems,
  onEdit
}) => {
  const classes = useStyles();
  const { tenantId } = user;

  const [currentPage, setCurrentPage] = useState({
    page: 0,
    rowsPerPage: 10
  });

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [lineItems, setLineItems] = useState([]);
  const [visitTimesheet, setVisitTimesheet] = useState([]);

  const [allLabourRates, labourRatesLoading] = useLabourRates();
  const [payrollLabourTypes, payrollLabourTypesLoading] = useLabourTypes();
  const [billingHourTypes, billingHourTypesLoading] = useBillingHourTypes();

  const labourRates = useMemo(
    () => allLabourRates?.filter(rate => rate?.parentId === priceBookId) || [],
    [priceBookId, allLabourRates]
  );

  const [visitTimesheetLoaded, setVisitTimesheetLoaded] = useState(false);

  const initalizeLineItemsFromData = useCallback(() => {
    if (!labourRatesLoading) {
      onInitializeStart();
      const items = getLineItemsForLabourRateSheet({
        lineItems: data,
        employees,
        visitTimesheet,
        isDefaultRate,
        labourTypes: payrollLabourTypes,
        labourRates,
        overwriteRates: overwriteRatesOnInitialize
      });
      setLineItems(items);
      onChangeNumLineItems(items.length);
      onInitializeComplete();
    }
  }, [
    data,
    employees,
    visitTimesheet,
    isDefaultRate,
    payrollLabourTypes,
    labourRates,
    labourRatesLoading,
    setLineItems,
    onInitializeStart,
    onInitializeComplete,
    overwriteRatesOnInitialize,
    onChangeNumLineItems
  ]);

  // on page load
  useEffect(() => {
    if (!isLoading && !labourRatesLoading && !payrollLabourTypesLoading && isFirstLoad) {
      setIsFirstLoad(false);
      setVisitTimesheetLoaded(false);

      initalizeLineItemsFromData();

      const successCallback = timesheets => setVisitTimesheet(timesheets);

      getTimesheetByVisit(visitId, successCallback);
      setVisitTimesheetLoaded(true);
    }
  }, [
    data,
    isFirstLoad,
    isDefaultRate,
    employees,
    labourRates,
    labourRatesLoading,
    visitTimesheet,
    payrollLabourTypes,
    payrollLabourTypesLoading,
    visitId,
    timeCardLines,
    isLoading,
    initalizeLineItemsFromData
  ]);

  // on reinitialize
  useEffect(() => {
    if (reinitialize && !isFirstLoad) {
      initalizeLineItemsFromData();
    }
  }, [reinitialize, initalizeLineItemsFromData, isFirstLoad]);

  // on create labour line items based on technicians who worked click
  useEffect(() => {
    if (triggerAddTimesheetEmployeesToLineItem) {
      onAddTimesheetEmployeeToLineItemStart();
      const newItems = addTimesheetEmployeeToLineItem({
        employees,
        labourRates,
        isDefaultRate,
        visitTimesheet,
        labourTypes: payrollLabourTypes,
        hourTypes: billingHourTypes
      }).filter(
        item => !lineItems.some(existingItem => existingItem.employeeId === item.employeeId)
      );
      const items = [...lineItems, ...newItems];
      setLineItems(items);
      onChangeNumLineItems(items.length);

      if (newItems.length === 0) {
        snackbarOn('error', 'All Timesheet employees already have labour line items');
      }
    }
  }, [
    triggerAddTimesheetEmployeesToLineItem,
    onAddTimesheetEmployeeToLineItemStart,
    employees,
    labourRates,
    isDefaultRate,
    visitTimesheet,
    payrollLabourTypes,
    billingHourTypes,
    lineItems,
    snackbarOn,
    onChangeNumLineItems
  ]);

  const calculateRowIndex = displayRowIndex =>
    currentPage.rowsPerPage * currentPage.page + displayRowIndex;

  const handleEdit = ({ isItemSelection = false, rowIndex, ...args }) => {
    onEdit();
    const updateValue = editLineItem({
      labourRates,
      lineItems,
      isDefaultRate,
      visitTimesheet,
      labourTypes: payrollLabourTypes,
      employees,
      rowIndex: calculateRowIndex(rowIndex),
      ...args
    });
    setLineItems(updateValue);
  };

  const handleDelete = (rowIndex, lineItemId) => {
    if (lineItemId) {
      const isPropNotEmpty = checkLineItemPropEmpty(lineItems);
      if (!isPropNotEmpty) {
        return snackbarOn('error', emptyFieldMessageForDelete);
      }

      setReportLoaded(false);

      const successCallback = () => {
        const successCallbackAfterDelete = () => {
          return fetchReport();
        };
        deleteLabourRateLineItem({
          tenantId,
          lineItemId,
          successCallback: successCallbackAfterDelete,
          snackbarOn
        });
      };

      // need to save temporary work before delete.
      saveLabourRateLineItems({
        reviewReportId,
        tenantId,
        snackbarOn,
        successCallback,
        labourRateLineItems: lineItems
      });
    } else {
      // this entry only existed in FE view
      const items = handleRemove(lineItems, calculateRowIndex(rowIndex));
      setLineItems(items);
      onChangeNumLineItems(items.length);
    }
  };

  const computedMeta = [...modifyLineItemMeta(labourLineItems, billingHourTypes), subtotalMeta];

  const handleAutocompleteSelect = ({ rowIndex, meta, value }) => {
    const { dataKey: key } = meta;
    handleEdit({ rowIndex, value, key, isItemSelection: true });
  };

  const handleAddNewItem = () => {
    const item = newItemTemplate(billingHourTypes);
    const items = [...lineItems, item];
    setLineItems(items);
    onChangeNumLineItems(items.length);
  };

  const handleSaveAction = () => {
    const isPropNotEmpty = checkLineItemPropEmpty(lineItems);
    if (!isPropNotEmpty) {
      return snackbarOn('error', emptyFieldMessage);
    }

    setReportLoaded(false);

    const successCallback = () => {
      fetchReport();
    };

    saveLabourRateLineItems({
      reviewReportId,
      tenantId,
      snackbarOn,
      successCallback,
      labourRateLineItems: lineItems
    });
  };

  return (
    <Grid className={classes.itemMargin} container flexDirection="column">
      <Table
        departmentName={departmentName}
        employees={employees}
        handleAutocompleteSelect={handleAutocompleteSelect}
        isDefaultRate={isDefaultRate}
        isEditable={isEditable}
        isLoading={
          isLoading ||
          payrollLabourTypesLoading ||
          billingHourTypesLoading ||
          labourRatesLoading ||
          !visitTimesheetLoaded
        }
        labourLineItems={labourLineItems}
        labourTypes={payrollLabourTypes}
        lineItems={lineItems}
        meta={computedMeta}
        reportEditable={reportEditable}
        onChangePage={page => setCurrentPage({ ...currentPage, page })}
        onChangeRowsPerPage={rowsPerPage => setCurrentPage({ ...currentPage, rowsPerPage })}
        onDelete={handleDelete}
        onEdit={handleEdit}
      />

      <Box className={classes.itemMargin} display="flex" flex={1} justifyContent="flex-end">
        <Box display="flex" flex={1} flexDirection="column">
          <Box display="flex" flex={1}>
            <Box display="flex" flex={1} pt={2}>
              <div>
                <AddRecordButton
                  disabled={!reportEditable}
                  handle={handleAddNewItem}
                  label="+ Add labor line item"
                />
              </div>
            </Box>
            <Box alignItems="flex-end" display="flex" flex={1} flexDirection="column" p={1}>
              <Typography variant="body1">Total</Typography>
              <Typography variant="h6">{overAllTotal(lineItems)}</Typography>
            </Box>
          </Box>
          <Box display="flex" flex={1} justifyContent="flex-end" pt={1}>
            <Button
              className={classes.saveButton}
              color="primary"
              disabled={!reportEditable}
              variant="contained"
              onClick={handleSaveAction}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default LineItemTable;
