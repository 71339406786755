import React, { useEffect, useRef, useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { pick } from 'lodash';
import { useSelector } from 'react-redux';

import { DefaultButton, FullScreenModal, PageHeader, Spinner } from 'components';
import { useSnackbar } from 'customHooks/useSnackbar';
import ErrorBoundaries from 'scenes/Error';
import { CommonService } from 'services/core';

import { Logger } from 'services/Logger';
import { MAX_FORMS_WIDTH } from 'themes/BuildHeroTheme';
import { logErrorWithCallback } from 'utils';

import CrewForm from '../CrewForm';
import { CREW, UPDATE_CREW } from '../queries';

export function CrewDetail(props) {
  const { computedMatch } = props;
  const snackbarOn = useSnackbar();
  const user = useSelector(s => s.user);

  const [loading, setLoading] = useState(true);
  const [crew, setCrew] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formService = useRef();
  const commonService = useRef(new CommonService()).current;

  const processIds = data => ({
    ...data,
    // used for edit mode
    foremanId: data.foreman?.id, // used for edit
    departmentId: data.department?.id,
    techIds: data.techs?.map(({ id }) => id) ?? []
  });

  const formatForView = data => ({
    ...data,
    foremanName: data.foreman?.name,
    departmentName: data.department?.tagName,
    techNames: data.techs?.map(({ name }) => name).join('\n')
  });

  useEffect(() => {
    async function getCrew() {
      const {
        data: { crew: crewData = null }
      } = await commonService.getQuery({ id: computedMatch?.params?.id }, CREW);
      return crewData;
    }
    setLoading(true);
    getCrew()
      .then(crewData => {
        setCrew(processIds(crewData));
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        Logger.error(err);
        snackbarOn('error', 'Cannot fetch crew. Please try again later.');
      });
  }, [snackbarOn, computedMatch, commonService]);

  const handleOnComplete = async formData => {
    setIsSubmitting(true);
    try {
      // formService.current
      const {
        data: { updateCrew = null }
      } = await commonService.runMutation(
        {
          input: pick(formData, ['id', 'name', 'foremanId', 'departmentId', 'techIds'])
        },
        UPDATE_CREW
      );

      if (!updateCrew) throw new Error('data.updateCrew not found on updateCrew mutation');

      setCrew(processIds(updateCrew));
      setIsEditMode(false);
    } catch (error) {
      logErrorWithCallback(error, snackbarOn, 'Unable to update crew. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) return <Spinner />;
  return (
    <ErrorBoundaries>
      <div style={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PageHeader
              breadcrumbsArray={[
                { title: 'Settings', link: '' },
                { title: 'Personnel', link: '/settings/people' }
              ]}
              overrideHeaderButtons={
                <DefaultButton
                  color="secondary"
                  handle={() => setIsEditMode(true)}
                  key="edit"
                  label="Edit Crew"
                  variant="outlined"
                />
              }
              pageMapKey="people"
              title="Crew"
              userLocale={user.locale}
            />
          </Grid>
          <Grid item style={{ maxWidth: MAX_FORMS_WIDTH }} xs={12}>
            <CrewForm data={formatForView(crew)} onComplete={() => {}} />
          </Grid>
        </Grid>
        <FullScreenModal
          fixedHeader
          handleClose={() => setIsEditMode(false)}
          modalHeaderButtons={
            <DefaultButton
              color="primary"
              disabled={isSubmitting}
              label="Save Crew"
              showSpinner={isSubmitting}
              onClick={() => formService.current?.submit()}
            />
          }
          open={isEditMode}
          title="Edit Crew"
        >
          <CrewForm
            data={crew}
            layout="edit"
            onComplete={completedFormData => handleOnComplete(completedFormData)}
            onCreateService={service => {
              formService.current = service;
            }}
          />
        </FullScreenModal>
      </div>
    </ErrorBoundaries>
  );
}

export default CrewDetail;
