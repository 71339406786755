import { sortBy } from 'lodash';

import removeObjProperties from 'utils/removeObjProperties';

export const formatList = items => items.map(({ id: value, name: label }) => ({ value, label }));

export const getLabourTypes = (types = []) =>
  sortBy(
    types?.map(
      ({ payrollCosts, labourTypeBillingHourTypeProducts, id, name, isActive, sortOrder }) => {
        const costs = payrollCosts?.items || [];
        const modifiedList = costs.map(cost =>
          removeObjProperties(cost, [
            '__tagname',
            '__typename',
            'version',
            'costCode',
            'jobCostType'
          ])
        );
        return {
          id,
          isActive,
          name,
          payrollCosts: modifiedList,
          sortOrder,
          labourTypeBillingHourTypeProducts: labourTypeBillingHourTypeProducts?.items.map(item =>
            removeObjProperties(item, ['__typename'])
          )
        };
      }
    ) || [],
    'sortOrder'
  );
