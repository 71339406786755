import React, { useEffect, useState } from 'react';

import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { array, bool, func, number, shape, string } from 'prop-types';
import SignaturePad from 'react-signature-canvas';

import { generateDefaultValidationSchema } from '@pm/components/formattingUtils';
import FormWithAccordion from '@pm/components/FormWithAccordion';
import MUIFormListView from '@pm/components/MUIFormListView';

import { signatureFields, signatureLayout } from './layout';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 24
  },
  accordionFooter: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '21px 28px'
  },
  hideElement: {
    display: 'none'
  },
  addButton: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '14px',
    textDecoration: 'none'
  },
  footerForm: {
    width: 702
  },
  footerTotal: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 21
  },
  signatureCanvas: {
    width: 453,
    height: 102,
    background: '#F0F0F0'
  },
  label: {
    fontSize: 10,
    letterSpacing: 0.01,
    fontWeight: 'normal',
    textTransform: 'uppercase',
    lineHeight: '14px',
    marginBottom: '0.35em'
  }
}));

const SignaturePadStyled = ({ options }) => {
  const classes = useStyles();

  const clear = () => {
    options.getCanvasRef(options.index).current.clear();
  };

  return (
    <>
      <Typography className={classes.label} variant="caption">
        {options.signatureType === 'customer' ? 'Customer Signature' : 'Foreman Signature'}
      </Typography>
      <div>
        <SignaturePad
          canvasProps={{ className: classes.signatureCanvas }}
          ref={options.getCanvasRef(options.index)}
        />
        <Button onClick={clear}>Clear</Button>
      </div>
    </>
  );
};

SignaturePadStyled.propTypes = {
  options: shape({
    signatureType: string.isRequired,
    index: number.isRequired,
    getCanvasRef: func.isRequired
  }).isRequired
};

const SignatureFieldForm = props => {
  const { initialData, mode, signatureType, onCreateService, onComplete, loading } = props;
  const classes = useStyles();
  const [panelExpanded, setPanelExpanded] = useState(false);
  const [canvasRef, setCanvasRef] = useState([]);
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    setFormData(
      initialData && Array.isArray(initialData) && initialData.length > 0 ? initialData : []
    );
  }, [initialData]);

  const getCanvasRef = index => {
    const newCanvasRef = [];
    if (index >= canvasRef.length) {
      let i = 0;
      while (i < (index + 1 > canvasRef.length ? index + 1 : canvasRef.length)) {
        if (canvasRef.length <= i) {
          newCanvasRef.push({});
        } else {
          newCanvasRef.push(canvasRef[i]);
        }
        i += 1;
      }
      setCanvasRef(newCanvasRef);
      return newCanvasRef[index];
    }
    return canvasRef[index];
  };

  const getSignature = index => {
    return canvasRef[index].current.getTrimmedCanvas
      ? canvasRef[index].current.toDataURL('image/png')
      : '';
  };

  const getFormattedData = data => {
    return {
      id: data?.id || '',
      title: data?.title || '',
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
      company: data?.company || '',
      emailAddress: data?.email || '',
      signatureImageUrl: data?.signatureImageUrl || null
    };
  };

  const setSignature = (signatureImageUrl, index) => {
    if (getCanvasRef(index)?.current) {
      getCanvasRef(index).current.fromDataURL(signatureImageUrl);
    }
  };

  useEffect(() => {
    let i = 0;
    while (i < formData.length) {
      setSignature(formData[i].signatureImageUrl, i);
      i += 1;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasRef]);

  const addNewFormData = () => {
    const newFormData = getFormattedData(null);
    setFormData([...formData, newFormData]);
  };

  const handlePanelExpand = (event, newExpanded) => {
    setPanelExpanded(newExpanded);
  };

  const sanitizeData = data => {
    return { ...data, signatureImageUrl: getSignature(data.index) };
  };

  const configurationCallback = index => {
    return signatureLayout(getCanvasRef, index, signatureType);
  };

  const muiForms = () => (
    <MUIFormListView
      configuration={configurationCallback}
      customComponents={{ SignaturePadStyled }}
      formatFuncForSave={sanitizeData}
      formData={formData}
      key={signatureType === 'customer' ? 'customerSignatures' : 'foremanSignatures'}
      keyName={signatureType === 'customer' ? 'customerSignatures' : 'foremanSignatures'}
      layout={mode}
      mode="default"
      useIndex
      useIndexConfigCallback
      validationSchema={generateDefaultValidationSchema(signatureFields)}
      onComplete={onComplete}
      onCreateService={onCreateService}
    />
  );

  const addFormBtn = (
    <Button
      className={classes.addButton}
      startIcon={<AddCircleOutlineIcon />}
      onClick={addNewFormData}
    >
      <Typography>Add Signature</Typography>
    </Button>
  );

  return (
    <div className={classes.root}>
      <FormWithAccordion
        buttonComponent={addFormBtn}
        expanded={panelExpanded}
        formComponent={muiForms}
        handlePanelExpand={handlePanelExpand}
        loading={loading}
        sectionName={signatureType === 'customer' ? 'customerSignature' : 'foremanSignature'}
      />
      <Divider />
    </div>
  );
};

SignatureFieldForm.propTypes = {
  initialData: array.isRequired,
  mode: string,
  signatureType: string.isRequired,
  onCreateService: func.isRequired,
  onComplete: func.isRequired,
  loading: bool
};

SignatureFieldForm.defaultProps = {
  mode: 'default',
  loading: false
};

export default SignatureFieldForm;
