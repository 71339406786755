import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { GET_REVIEW_REPORT_BY_ID } from '../queries';
import { purchasedOrderFragment } from '../queries/queryFragment';

const ADD_PURCHASE_ORDER = gql`
  mutation AddPurchaseOrderToReviewReport(
    $partitionKey: String
    $data: AddPurchaseOrderToReviewReportInput!
  ) {
    addPurchaseOrderToReviewReport(partitionKey: $partitionKey, data: $data) {
      ${purchasedOrderFragment}
    }
  }
`;

const serializer = ({ tenantId, reviewReportId, purchaseOrder }) => {
  const { dateOfPurchase, ...data } = purchaseOrder;
  return {
    variables: {
      partitionKey: tenantId,
      data: {
        reviewReportId,
        purchaseOrder: {
          ...data,
          dateOfPurchase: `${dateOfPurchase || ''}`
        }
      }
    }
  };
};

export const useAddReviewReportPurchaseOrder = reviewReportId => {
  return useExtendedMutation(ADD_PURCHASE_ORDER, {
    serializer,
    update: (cache, { data: { addPurchaseOrderToReviewReport } }) => {
      if (!addPurchaseOrderToReviewReport) {
        return;
      }

      const cachedData = cache.readQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        }
      });

      cache.writeQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        },
        data: {
          getReviewReportById: {
            ...(cachedData?.getReviewReportById || {}),
            purchaseOrders: {
              __typename: cachedData.getReviewReportById?.purchaseOrders?.__typename,
              items: [
                ...(cachedData.getReviewReportById?.purchaseOrders?.items || []),
                addPurchaseOrderToReviewReport
              ]
            }
          }
        }
      });
    }
  });
};
