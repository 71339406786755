/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Button,
  ButtonType,
  DateInput,
  Input,
  Modal,
  MoreButton,
  MultiSelect,
  NumberInput,
  Select,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';
import { Box, InputAdornment, Tooltip, useTheme } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import { debounce, groupBy } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { useCompanyTimezone } from '@pm/components/hooks';

import MaintenanceIcon from 'assets/Icons/Maintenance';
import { ReactComponent as ItemsIcon } from 'assets/Icons/Union.svg';
import { SgtAlgoliaMultiSelect } from 'components';
import FormLineItem from 'components/Tasks/components/FormLineItem';
import PartsAndMaterialsLineItem from 'components/Tasks/components/PartsAndMaterialsLineItem';
import { bundleIndex } from 'constants/algoliaIndex';
import { useConfirmModal } from 'customHooks/ConfirmModalContext';

import { months, options } from 'scenes/ChecklistLibrary/taskTemplate';
import { EntityType } from 'utils/constants';

import {
  addInputCss,
  deleteTaskConfirmMessage,
  deleteTaskWithMetadataMessage,
  editInputCss,
  modifyTaskConfirmMessage,
  TaskTemplateEditType
} from './constants';
import { formatProductOptions, getProductsFromTaskTemplatePart } from './helper';
import { useAddTaskTemplateFromServiceAgreement } from './hooks/useAddTaskTemplateFromServiceAgreement';
import { useRemoveTaskTemplateFromServiceAgreement } from './hooks/useRemoveTaskTemplateFromServiceAgreement';
import { useUpdateTaskTemplateInServiceAgreement } from './hooks/useUpdateTaskTemplateInServiceAgreement';

const currentMonthNumber = moment().month() + 1;

const getOccurrences = data => {
  const exisitingOccurences = {};
  const groupedExistingOccurrences = groupBy(data?.schedules, 'monthOfYear');
  Object.keys(groupedExistingOccurrences).forEach(occ => {
    exisitingOccurences[months[occ - 1]] = groupedExistingOccurrences[occ].length;
  });
  return exisitingOccurences;
};

const isValidTaskTemplate = (data = {}) =>
  data.name && data.startDateTime && data.interval && data.numberOfOccurrences;

export const TaskTemplate = ({
  data,
  assetData,
  checklistId,
  customerPropertyId,
  serviceAgreementId,
  isDebugEnabled = false,
  style,
  hasMetadata,
  deleteAllTemplateGroupsOnServiceAgreement,
  refetchServiceAgreementPropertyMetadata,
  isLastTask
}) => {
  const theme = useTheme();

  const company = useSelector(state => state.company);
  const companyForms = company?.forms?.items || [];

  const taskTemplateId = useMemo(() => data?.id, [data]);

  const [{ data: companyTimezone }] = useCompanyTimezone();

  const forms = useMemo(
    () =>
      companyForms.filter(
        f => f.latestPublishedFormDefinitionSortKey && f.associatedEntityType === EntityType.TASK
      ) || [],
    [companyForms]
  );
  const confirmContext = useConfirmModal();
  const modifyContext = useConfirmModal();
  const deleteWithMetadataContext = useConfirmModal();

  const [taskTemplate, setTaskTemplate] = useState(data || {});
  const [formsModal, setFormsModal] = useState({ open: false });
  const [itemsModal, setItemsModal] = useState({ open: false });
  const [products, setProducts] = useState(getProductsFromTaskTemplatePart(data?.parts?.items));
  const occurrences = getOccurrences(taskTemplate);

  useEffect(() => {
    setTaskTemplate(data);
  }, [data]);

  const [deleteTaskTemplateFromSA] = useRemoveTaskTemplateFromServiceAgreement({
    propertyAssetId: assetData?.id,
    serviceAgreementId,
    customerPropertyId,
    checklistId,
    skip: (!assetData?.id && !customerPropertyId) || !taskTemplateId || !checklistId,
    onSuccess: (transformedData, snackbarOn) => {
      snackbarOn('success', 'Task template removed from Service agreement');
      setTaskTemplate(null);
    }
  });

  const [updateTaskTemplateInSA] = useUpdateTaskTemplateInServiceAgreement({
    serviceAgreementId,
    propertyAssetId: assetData?.id,
    customerPropertyId,
    checklistId,
    skip: (!assetData?.id && !customerPropertyId) || !taskTemplateId || !checklistId
  });

  const [addTaskTemplateInSA] = useAddTaskTemplateFromServiceAgreement({
    serviceAgreementId,
    customerPropertyId,
    propertyAssetId: assetData?.id,
    checklistId,
    skip: !serviceAgreementId || (!assetData?.id && !customerPropertyId) || checklistId
  });

  const formOptions = useMemo(
    () =>
      forms.map(form => ({
        id: form.id,
        label: form.name,
        value: { id: form.id }
      })),
    [forms]
  );

  const taskTemplateForms = taskTemplate?.forms || [];
  const selectedFormOptions = useMemo(() => {
    const formIds = taskTemplateForms.map(item => item.id);
    const requiredFormIds = taskTemplateForms
      .filter(f => f.required || f.isRequired)
      .map(item => item.id);
    return formOptions
      .filter(item => formIds.includes(item.id))
      .map(form => ({
        ...form,
        value: { id: form.id, required: requiredFormIds.includes(form.id) }
      }));
  }, [formOptions, taskTemplateForms]);

  const handleFormChange = useCallback(selectedOpts => {
    const selectedTaskTemplateForms = selectedOpts.map(form => ({
      id: form.id,
      isRequired: form.value?.required ?? false
    }));
    setTaskTemplate(prev => ({ ...prev, forms: selectedTaskTemplateForms }));
  }, []);

  const inputStyles = {
    paddingBottom: 16,
    paddingRight: 8,
    backgroundColor: theme.palette.background.default
  };

  const inputCss = {
    backgroundColor: theme.palette.grayscale(100)
  };

  const handleInputChange = useCallback(
    async (value, fieldName) => {
      if (value === taskTemplate[fieldName]) return;

      if (hasMetadata) {
        if (!(await modifyContext.confirm(modifyTaskConfirmMessage))) {
          setTaskTemplate({ ...taskTemplate });
          return false;
        }

        await deleteAllTemplateGroupsOnServiceAgreement();
        await refetchServiceAgreementPropertyMetadata();
      }

      setTaskTemplate(prev => ({ ...prev, [fieldName]: value }));

      return true;
    },
    [
      deleteAllTemplateGroupsOnServiceAgreement,
      hasMetadata,
      modifyContext,
      refetchServiceAgreementPropertyMetadata,
      taskTemplate
    ]
  );

  const handleSave = useCallback(
    debounce(
      async (dataToUpdate, productList = []) => {
        let deleteConfirmed = false;
        if (hasMetadata) {
          if (!(await deleteWithMetadataContext.confirm(deleteTaskWithMetadataMessage))) {
            return;
          }

          deleteConfirmed = true;

          await deleteAllTemplateGroupsOnServiceAgreement();
          await refetchServiceAgreementPropertyMetadata();
        }

        if (
          !dataToUpdate.name &&
          dataToUpdate.id &&
          (deleteConfirmed || (await confirmContext.confirm(deleteTaskConfirmMessage)))
        ) {
          await deleteTaskTemplateFromSA({
            serviceAgreementId,
            propertyAssetId: assetData.id,
            taskTemplateId,
            ...taskTemplate
          });
        } else {
          const isValid = isValidTaskTemplate(dataToUpdate ?? {});
          if (isValid) {
            const partsInput = productList
              .map(
                ({ value }) =>
                  value?.quantity && {
                    productId: value?.productId,
                    productDescription: value?.description,
                    quantity: value?.quantity
                  }
              )
              .filter(Boolean);
            dataToUpdate?.id
              ? await updateTaskTemplateInSA({
                  ...dataToUpdate,
                  serviceAgreementId,
                  taskTemplateId,
                  editType: taskTemplate.editType,
                  propertyAssetId: assetData.id,
                  partsInput
                })
              : await addTaskTemplateInSA({
                  ...dataToUpdate,
                  serviceAgreementId,
                  propertyAssetId: assetData.id,
                  partsInput
                });
          }
        }
      },
      2000,
      { trailing: true }
    ),
    [serviceAgreementId, hasMetadata]
  );

  const saveChanges = async (newValues = {}) => {
    handleSave.cancel();
    await handleSave({ ...taskTemplate, ...newValues }, products);
  };

  if (taskTemplate === null) return null;

  const getStylesForInputs = () => {
    if (!isDebugEnabled) return inputCss;
    if (taskTemplate?.editType === TaskTemplateEditType.EDIT) return editInputCss;
    if (taskTemplate?.editType === TaskTemplateEditType.ADD) return addInputCss;
    return inputCss;
  };

  return (
    <Box display="flex" flexDirection="row" style={style}>
      <Box display="flex" flexGrow={2}>
        <Input
          css={getStylesForInputs()}
          endAdornment={
            <InputAdornment position="end">
              <ItemsIcon
                css={{
                  cursor: 'pointer',
                  marginRight: 4
                }}
                fill={
                  products?.length > 0
                    ? theme.palette.support.blue.dark
                    : theme.palette.grayscale(60)
                }
                height={20}
                width={20}
                onClick={() => setItemsModal({ open: true })}
              />
              <Tooltip arrow title="Add Form(s) to Task">
                <DescriptionOutlinedIcon
                  css={{
                    color: `${
                      taskTemplate?.forms?.length > 0
                        ? theme.palette.support.blue.dark
                        : theme.palette.grayscale(60)
                    }`,
                    cursor: 'pointer'
                  }}
                  fontSize="small"
                  onClick={() => setFormsModal({ open: true })}
                />
              </Tooltip>
            </InputAdornment>
          }
          fullWidth={false}
          label="Task"
          startAdornment={
            taskTemplate?.isSingleJob && (
              <InputAdornment position="start">
                <Tooltip arrow title="This task will be scheduled as its own maintenance job">
                  <MaintenanceIcon
                    css={{
                      color: theme.palette.text.primary,
                      cursor: 'pointer'
                    }}
                    fontSize="small"
                  />
                </Tooltip>
              </InputAdornment>
            )
          }
          style={{ ...inputStyles, minWidth: 168 }}
          value={taskTemplate.name || ''}
          onBlur={saveChanges}
          onChange={e => handleInputChange(e.target.value, 'name')}
        />
        <Input
          css={getStylesForInputs()}
          disabled={!taskTemplate.name}
          endAdornment={
            <InputAdornment css={{ minWidth: 36 }} position="end">
              <Typography variant={TV.S2} weight={TW.MEDIUM}>
                min
              </Typography>
            </InputAdornment>
          }
          fullWidth={false}
          label="Labor Estimate"
          style={{ ...inputStyles, minWidth: 96 }}
          value={taskTemplate.laborEstimate || ''}
          onBlur={saveChanges}
          onChange={e => handleInputChange(Number(e.target.value), 'laborEstimate')}
        />
        <Select
          disabled={!taskTemplate.name}
          inputControlProps={{
            style: {
              backgroundColor: getStylesForInputs().backgroundColor
            }
          }}
          label="Interval"
          options={options}
          portal
          searchable
          style={{ ...inputStyles, minWidth: 140 }}
          value={options.find(item => item.value === taskTemplate.interval)}
          onChange={async e => {
            if (!(await handleInputChange(e.value, 'interval'))) {
              // if handleInputChange returns false - means cancel on change warning dialog
              return;
            }

            if (taskTemplate?.id) {
              saveChanges({ interval: e.value });
            }
          }}
        />
        <DateInput
          css={getStylesForInputs()}
          disabled={!taskTemplate.name}
          fullWidth={false}
          label="First Due date"
          style={{ ...inputStyles, minWidth: 140 }}
          value={taskTemplate.startDateTime}
          onBlur={saveChanges}
          onChange={val => handleInputChange(val, 'startDateTime')}
          timezone={companyTimezone}
        />
      </Box>
      <Box display="flex" flexGrow={1} position="relative">
        <span
          css={{
            borderLeft: `1px solid ${theme.palette.support.red.dark}`,
            position: 'relative',
            top: 0,
            left: currentMonthNumber * 30 + 15,
            zIndex: 10,
            height: '103%'
          }}
        />

        {months.map((mon, index) => {
          const value = occurrences[mon];
          const valueBgColor =
            index + 1 < currentMonthNumber
              ? theme.palette.grayscale(90)
              : theme.palette.support.blue.light;
          const bgColor = value ? valueBgColor : theme.palette.background.default;

          return (
            <NumberInput
              css={{
                border: 'none',
                backgroundColor: bgColor
              }}
              fullWidth={false}
              inputProps={{ disabled: true, style: { paddingRight: 4, textAlign: 'right' } }}
              key={`MonthValue-${mon}`}
              label={mon}
              placeholder="-"
              style={{
                paddingBottom: 16,
                width: 30,
                marginRight: 8,
                color: value ? theme.palette.support.blue.dark : theme.palette.grayscale(50),
                textAlign: 'center'
              }}
              type="number"
              value={Number.isNaN(value) ? '' : value}
            />
          );
        })}

        <NumberInput
          css={{ ...getStylesForInputs(), paddingRight: 4 }}
          fullWidth={false}
          inputProps={{ style: { textAlign: 'center' } }}
          label="Total"
          style={{
            paddingBottom: 16,
            width: 48,
            borderLeft: `1px solid ${theme.palette.grayscale(60)}`,
            paddingLeft: 12
          }}
          value={Object.values(occurrences).reduce((prev, curr) => prev + curr, 0) || 0}
          onBlur={saveChanges}
          onChange={val => handleInputChange(Number(val), 'numberOfOccurrences')}
        />
      </Box>
      <Box justifyContent="flex-end">
        <MoreButton
          css={{ padding: 4 }}
          options={[
            {
              label: 'Delete task',
              disabled: isLastTask,
              icon: p => <DeleteIcon fontSize="small" {...p} />,
              onClick: async () => {
                let deleteConfirmed = false;

                if (hasMetadata) {
                  if (!(await deleteWithMetadataContext.confirm(deleteTaskWithMetadataMessage))) {
                    return;
                  }

                  deleteConfirmed = true;

                  await deleteAllTemplateGroupsOnServiceAgreement();
                  await refetchServiceAgreementPropertyMetadata();
                }

                if (deleteConfirmed || (await confirmContext.confirm(deleteTaskConfirmMessage))) {
                  await deleteTaskTemplateFromSA({
                    serviceAgreementId,
                    propertyAssetId: assetData.id,
                    taskTemplateId,
                    ...taskTemplate
                  });
                }
              }
            },
            !serviceAgreementId && {
              label: 'Individual job',
              icon: p => <MaintenanceIcon fontSize="small" {...p} />,
              onClick: () => {}
            }
          ].filter(Boolean)}
        />
      </Box>

      <Modal
        actions={
          <Button
            fullWidth
            type={ButtonType.PRIMARY}
            onClick={async () => {
              saveChanges();
              setFormsModal({ open: false });
            }}
          >
            Save
          </Button>
        }
        open={formsModal.open}
        PaperProps={{ style: { minWidth: 480, minHeight: 316 } }}
        title="Add Forms To Task"
        onClose={() => setFormsModal({ open: false })}
      >
        <MultiSelect
          label="Forms"
          lineItemComponent={(option, selectedOptions) => (
            <FormLineItem
              formsChange={handleFormChange}
              option={option}
              selectedOptions={selectedOptions}
            />
          )}
          options={formOptions}
          placeholder="Select Forms"
          popperHeight={130}
          selectedOptions={selectedFormOptions}
          showChips
          onChange={handleFormChange}
        />
      </Modal>
      <Modal
        actions={
          <Button
            fullWidth
            type={ButtonType.PRIMARY}
            onClick={async () => {
              saveChanges();
              setItemsModal({ open: false });
            }}
          >
            Save
          </Button>
        }
        open={itemsModal.open}
        PaperProps={{ style: { minWidth: 780, minHeight: 316 } }}
        title="Add Items To Task"
        onClose={() => setItemsModal({ open: false })}
      >
        <SgtAlgoliaMultiSelect
          options={{
            label: 'Parts & Materials',
            lineItemComponent: (option, selectedOptions) => (
              <PartsAndMaterialsLineItem
                key={option.id}
                option={option}
                partsAndMaterialsChange={setProducts}
                selectedOptions={selectedOptions}
              />
            ),
            onChange: setProducts,
            options: [],
            placeholder: 'Search & Select Parts & Materials',
            showChips: true,
            showSearchIcon: true,
            selectedOptions: products,
            restructureAlgoliaHitToOptions: hit => {
              return [formatProductOptions(hit)];
            },
            algoliaFilter: `entityType:Product`,
            algoliaIndex: bundleIndex
          }}
        />
      </Modal>
    </Box>
  );
};
