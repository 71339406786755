import flatten from 'lodash/flatten';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { ElementSizes } from '@dispatch/Dispatch.styles';

import { CardTypes } from '../../DispatchBoard.constants';
import { sortByStart } from '../../DispatchBoard.utils';

import { WeekCardHeights } from './WeekView.constants';

const { cellHeight, stickyHeaderHeight, availableTimeHeight, hoveredWeekDropTarget } = ElementSizes;

const moment = extendMoment(Moment);

const selectDayRanges = (startDay, weekViewStartDay) => {
  const dayRanges = [];

  if (weekViewStartDay) {
    if (
      moment().isoWeekday() >=
      moment()
        .isoWeekday(weekViewStartDay)
        .isoWeekday()
    ) {
      for (let i = 0; i < 7; i += 1) {
        const start = moment(startDay)
          .isoWeekday(weekViewStartDay)
          .add(i, 'days')
          .startOf('day');

        const end = moment(startDay)
          .isoWeekday(weekViewStartDay)
          .add(i, 'days')
          .endOf('day');

        dayRanges.push({ range: moment.range(start, end) });
      }
    } else {
      for (let i = 0; i < 7; i += 1) {
        const start = moment(startDay)
          .subtract(7, 'days')
          .isoWeekday(weekViewStartDay)
          .add(i, 'days')
          .startOf('day');

        const end = moment(startDay)
          .subtract(7, 'days')
          .isoWeekday(weekViewStartDay)
          .add(i, 'days')
          .endOf('day');

        dayRanges.push({ range: moment.range(start, end) });
      }
    }
  } else {
    for (let i = 0; i < 7; i += 1) {
      const start = moment(startDay)
        .startOf('isoWeek')
        .add(i, 'days')
        .startOf('day');

      const end = moment(startDay)
        .startOf('isoWeek')
        .add(i, 'days')
        .endOf('day');

      dayRanges.push({ range: moment.range(start, end) });
    }
  }

  return dayRanges;
};

const subtractRanges = (dayRange, dayEvents) => {
  return dayEvents.reduce(
    (remaining, event) => {
      return flatten(remaining.map(r => r.subtract(event.range)));
    },
    [dayRange]
  );
};

export const selectTechEvents = (events = [], startDay, weekViewStartDay) => {
  const techDays = Array.from({ length: 7 }).map(() => []);
  const days = selectDayRanges(startDay, weekViewStartDay);

  events.forEach(event => {
    days.forEach((day, i) => {
      if (day.range.overlaps(event.range)) {
        techDays[i].push({ ...event, day });
      }
    });
  });

  return techDays.map((dayEvents, i) => {
    const day = days[i];

    const availableTimes = subtractRanges(day.range.clone(), dayEvents).map(range => ({
      __typename: CardTypes.AvailableTime,
      range
    }));

    if (!dayEvents.length) {
      availableTimes[0].emptyDay = true;
    }

    const events = [...dayEvents, ...availableTimes].sort(sortByStart);

    events[events.length - 1].isLastEvent = true;

    return events;
  }, []);
};

export const selectLaneHeight = (i, itemData, activeCell) => {
  if (i === 0) return stickyHeaderHeight;

  let laneHeight = cellHeight;
  const dayHeights = {};

  itemData[i].techEvents.forEach((dayEvents, dayIndex) => {
    const dayHeight =
      dayEvents.reduce((height, event) => height + WeekCardHeights[event.__typename], 0) + 1;

    dayHeights[dayIndex] = dayHeight;

    if (dayHeight > laneHeight) {
      laneHeight = dayHeight;
    }
  });

  const expandedHeight =
    dayHeights[activeCell?.dayIndex] + (hoveredWeekDropTarget - availableTimeHeight);

  return expandedHeight > laneHeight && i === activeCell?.laneIndex ? expandedHeight : laneHeight;
};
