import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';

const GET_PROJECT_FINANCE_DATA = gql`
  query getProjectFinanceData($projectId: string) {
    getProjectFinanceData(projectId: $projectId)
      @rest(
        type: "ProjectFinanceDataResponse"
        path: "projects/financedata/{args.projectId}"
        endpoint: "pmapi"
      ) {
      items @type(name: "ProjectFinanceDataPoint") {
        id
        entityType
        status
        costBudget
        actualCost
        revenueBudget
        actualRevenue
        hourBudget
        actualHours
        invoiceAmount
        paymentAmount
        projectId
        projectPhaseId
        projectPhaseDepartmentId
        projectPhaseDepartmentCostCodeId
        costType
        departmentId
        departmentName
        projectCostCodeId
        projectCostCodeName
        projectPhaseName
        title
      }
    }
  }
`;

export const useProjectFinanceData = (projectId, options = {}) => {
  return useExtendedQuery(GET_PROJECT_FINANCE_DATA, {
    variables: { projectId },
    transform: data => data?.getProjectFinanceData?.items,
    defaultData: [],
    ...options
  });
};
