import { ButtonType } from '@BuildHero/sergeant';

import { Logger } from 'services/Logger';
import { TimeTrackingSettings } from 'utils/AppConstants';

/* Backend format
    settings: JSON.stringify({
    dailyShift: Bool
    enableTimestampsForEventTypes: ['NonBillable', 'BillableNonVisit']
    allowTechToCreateEventTypes:['NonBillable', 'BillableNonVisit']
    dailyShiftTimestampsOnly: Bool,
    weekStartDay: 'MONDAY',
    enableAutomatedTimeTracking: Bool,
    includeTechnicianName: Bool,
    includeVisitDate: Bool
  })
*/
export const convertSettingsJSONtoString = formData => {
  const enableTimestampsForEventTypes = [];
  const allowTechToCreateEventTypes = [];

  if (formData.billableNonvisitEvents) {
    enableTimestampsForEventTypes.push(TimeTrackingSettings.BILLABLE_NON_VISIT);
    if (formData.allowTechCreateBillableNonvisitEvents) {
      allowTechToCreateEventTypes.push(TimeTrackingSettings.BILLABLE_NON_VISIT);
    }
  }

  if (formData.nonBillableNonvisitEvents) {
    enableTimestampsForEventTypes.push(TimeTrackingSettings.NON_BILLABLE);
    if (formData.allowTechCreateNonBillableNonvisitEvents) {
      allowTechToCreateEventTypes.push(TimeTrackingSettings.NON_BILLABLE);
    }
  }

  const settingsJSON = {
    dailyShift: formData.dailyShift || false,
    enableTimestampsForEventTypes,
    allowTechToCreateEventTypes,
    dailyShiftTimestampsOnly: formData.timestamps === TimeTrackingSettings.DAILY_TIMESTAMPS,
    timeZone: formData.timeZone,
    enableAutomatedTimeTracking: formData.enableAutomatedTimeTracking || false,
    weekStartDay: formData.weekStartDay,
    defaultProductId: formData.defaultProductId,
    defaultProductName: formData.defaultProductName,
    mapPayrollHourToBilling: formData.mapPayrollHourToBilling,
    includeTechnicianName: formData.includeTechnicianName,
    includeVisitDate: formData.includeVisitDate
  };

  return JSON.stringify(settingsJSON);
};

export const convertStringToSettingsJSON = payrollSettingObj => {
  const payrollSettingStr = payrollSettingObj?.settings;
  const settingJSON = {
    id: payrollSettingObj?.id,
    version: payrollSettingObj?.version
  };

  if (!payrollSettingStr) return settingJSON;

  try {
    const settingObj = JSON.parse(payrollSettingStr);
    settingJSON.dailyShift = settingObj.dailyShift === true;
    settingJSON.timeZone = settingObj?.timeZone || '';
    settingJSON.timestamps = settingObj.dailyShiftTimestampsOnly
      ? TimeTrackingSettings.DAILY_TIMESTAMPS
      : TimeTrackingSettings.ALL_TIMESTAMPS;
    settingJSON.billableNonvisitEvents = settingObj.enableTimestampsForEventTypes.includes(
      TimeTrackingSettings.BILLABLE_NON_VISIT
    );
    settingJSON.allowTechCreateBillableNonvisitEvents = settingObj.allowTechToCreateEventTypes.includes(
      TimeTrackingSettings.BILLABLE_NON_VISIT
    );
    settingJSON.nonBillableNonvisitEvents = settingObj.enableTimestampsForEventTypes.includes(
      TimeTrackingSettings.NON_BILLABLE
    );
    settingJSON.allowTechCreateNonBillableNonvisitEvents = settingObj.allowTechToCreateEventTypes.includes(
      TimeTrackingSettings.NON_BILLABLE
    );

    settingJSON.enableAutomatedTimeTracking = settingObj.enableAutomatedTimeTracking;
    settingJSON.weekStartDay = settingObj.weekStartDay;
    settingJSON.defaultProductId = settingObj.defaultProductId;
    settingJSON.defaultProductName = settingObj.defaultProductName;
    settingJSON.mapPayrollHourToBilling = settingObj.mapPayrollHourToBilling;
    settingJSON.includeTechnicianName = settingObj.includeTechnicianName;
    settingJSON.includeVisitDate = settingObj.includeVisitDate;
  } catch (err) {
    Logger.error('Unable to parse time tracking settings');
  }
  return settingJSON;
};

export const getArchiveConfirmContent = (value, name) => ({
  body: value
    ? `Archiving ${name} will hide it from appearing on any tables and timesheets.\n\nTechnicians and users will no longer be able to log hours and rates against ${name}.`
    : `Unarchiving ${name} will make it visible on all tables and timesheets.\n\nTechnicians and user will be able to log hours and rates against ${name}.`,
  title: `Are you sure you want to ${value ? 'archive' : 'unarchive'} this item?`,
  buttonLabel: value ? 'ARCHIVE' : 'UNARCHIVE',
  buttonType: value ? ButtonType.ERROR : ButtonType.PRIMARY,
  maxWidth: 'xs'
});
