/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { Storage } from 'aws-amplify';
import gql from 'graphql-tag';
import uuidV4 from 'uuid/v4';

import config from 'configs/aws-exports';
import env from 'configs/env';
import AmplifyService from 'services/AmplifyService';
import { Logger } from 'services/Logger';
import {
  getFileExtension,
  getGuardedFileName,
  isCloudinaryImageType,
  isCloudinaryVideoType
} from 'utils';

const getCloudinarySignatureQuery = gql`
  query getSignature($data: CloudinaryParams) {
    getCloudinarySignature(data: $data)
  }
`;

export default class StorageService {
  uploadToCloudinary = async (
    file,
    cloudinaryParams,
    progressCallback,
    completionCallback,
    errorCallback,
    tags
  ) => {
    const { CLOUDINARY_UPLOAD_URL, CLOUDINARY_API_KEY } = config(env);
    const url = CLOUDINARY_UPLOAD_URL;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    // eslint-disable-next-line no-param-reassign
    cloudinaryParams.tags = tags;
    const appsyncClient = AmplifyService.appSyncClient();
    const signature = await appsyncClient.client.query({
      query: getCloudinarySignatureQuery,
      variables: {
        data: cloudinaryParams
      }
    });
    xhr.open('POST', url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    // Update progress (can be used to show progress indicator)
    xhr.upload.addEventListener('progress', function(e) {
      if (progressCallback) {
        const cancelUploadFn = () => {
          xhr.abort();
        };
        progressCallback(
          { loaded: e.loaded, total: e.total },
          cloudinaryParams.public_id,
          () => cancelUploadFn
        );
      }
    });

    xhr.upload.addEventListener('error', function(e) {
      Logger.info(`invoke::cloudinary-helper::ERROR : ${JSON.stringify(e)}`);
    });

    xhr.onreadystatechange = function(e) {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // File uploaded successfully
        if (completionCallback) completionCallback('Success', cloudinaryParams.public_id);
      }
      if (xhr.readyState === 4 && e.target?.responseHeaders?.['X-Cld-Error']) {
        const errorMessage = e.target?.responseHeaders['X-Cld-Error'];
        Logger.info(`invoke::cloudinary-helper::ERROR ${errorMessage}`);
        if (errorCallback) errorCallback('Failed', cloudinaryParams.public_id);
      }
      if (xhr.readyState === 4 && e.target?.status === 413) {
        // 413 Payload Too Large error
        const errorMessage = e.target?.statusText;
        Logger.info(`invoke::cloudinary-helper::ERROR ${errorMessage}`);
        if (errorCallback) errorCallback('File too large', cloudinaryParams.public_id);
      }
      if (xhr.readyState === 4 && e.target?.status === 400) {
        // 400 Bad request - invalid image file
        let resp;
        try {
          resp = e.target.responseText && JSON.parse(e.target.responseText);
        } catch (err) {
          Logger.info('Unable to parse error message');
        }
        const errorMessage = e.target?.statusText;
        Logger.info(`invoke::cloudinary-helper::ERROR ${resp?.error?.message || errorMessage}`);
        if (errorCallback)
          errorCallback(resp?.error?.message || errorMessage, cloudinaryParams.public_id);
      }
    };

    fd.append('eager', cloudinaryParams.eager);
    fd.append('public_id', cloudinaryParams.public_id);
    fd.append('folder', cloudinaryParams.folder);
    fd.append('timestamp', cloudinaryParams.timestamp);
    fd.append('api_key', CLOUDINARY_API_KEY);
    fd.append('signature', signature.data.getCloudinarySignature);
    fd.append('tags', cloudinaryParams.tags);
    fd.append('file', file);
    xhr.send(fd);
  };

  async uploadFile(file, filename, progressCallback, fileType) {
    // the file processing is done here becuase of time crunch, otherwise it needs to be updated in 40+ files
    const tenantId = filename?.split('/')?.[0];
    const fileExt = getFileExtension(filename);
    const public_id = uuidV4();
    // Get the timestamp in seconds

    const timestamp = Math.round(new Date().getTime() / 1000);
    const folder = `/public/${tenantId}`;
    const eager = '';
    try {
      return new Promise((resolve, reject) =>
        this.uploadToCloudinary(
          file,
          {
            public_id,
            timestamp,
            folder,
            eager
          },
          progressCallback,
          result => resolve(`${tenantId}/${public_id}.${fileExt}`),
          error => {
            console.error(error);
            reject(new Error(error));
          },
          `${tenantId}/${public_id}.${fileExt}`
        ).catch(err => console.error('Error: Failed to upload file ', public_id, err))
      );
    } catch (err) {
      console.error('Error: Failed to upload file ', filename);
    }
  }

  isValidUrl = urlString => {
    try {
      return !!new URL(urlString);
    } catch (e) {
      return false;
    }
  };

  getFile = (filename, isThubmnail) => {
    if (!filename) {
      return;
    }
    let localFileName = encodeURI(filename);
    const {
      CLOUDINARY_IMAGE_URL,
      CLOUDINARY_VIDEO_URL,
      CLOUDINARY_RAW_DOWNLOAD_URL,
      CLOUDINARY_THUMBNAIL_URL
    } = config(env);

    let fileExt = getFileExtension(localFileName);
    // override transformed extensions - Cloudinary will transform images like HEIC to jpg
    // TODO find better ways to handle
    if (fileExt?.toLowerCase() === 'heic') {
      fileExt = 'jpg';
      localFileName = localFileName.replace('.HEIC', '.jpg').replace('.heic', '.jpg');
    }

    // test if already URL, return if it is
    if (this.isValidUrl(localFileName)) {
      return localFileName;
    }

    // variable is used for easy debugging
    let cloudinaryUrl = `${CLOUDINARY_RAW_DOWNLOAD_URL}${localFileName}`;
    if (isCloudinaryImageType(fileExt)) {
      if (isThubmnail) {
        cloudinaryUrl = `${CLOUDINARY_THUMBNAIL_URL}${getGuardedFileName(localFileName)}`;
      } else {
        cloudinaryUrl = `${CLOUDINARY_IMAGE_URL}${localFileName}`;
      }
    }

    if (isCloudinaryVideoType(fileExt)) {
      cloudinaryUrl = `${CLOUDINARY_VIDEO_URL}${localFileName}`;
    }
    return cloudinaryUrl;
  };

  async getFileUrl(filename, options) {
    if (!filename) {
      return;
    }
    let stored;
    try {
      stored = await Storage.get(filename, {
        level: 'public',
        expires: 60,
        ...options
      });
    } catch (error) {
      Logger.info(`${this} Error in getting file : ${JSON.stringify(error)}`);
      throw new Error(error);
    }
    return stored;
  }

  // Used in OCR alone
  async deleteFile(filename) {
    let stored;
    try {
      stored = await Storage.remove(filename, { level: 'public' });
    } catch (error) {
      Logger.error(`Error in deleting file : ${JSON.stringify(error)}`);
      throw new Error(error);
    }
    try {
      Storage.remove(`${filename}.medium`, { level: 'public' });
    } catch (error) {
      Logger.info(error);
    }
    try {
      await Storage.remove(`${filename}.small`, { level: 'public' });
    } catch (error) {
      Logger.info(error);
    }
    return stored;
  }
}
