import React from 'react';

import { Grid } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

import { removeFromFilters } from './helpers';
import useStyles from './styles';

export default function FilterSummary({
  isVisible,
  resultCount,
  selectedFilters,
  updateSelectedFilter
}) {
  const classes = useStyles();

  if (!isVisible) return null;

  const appliedFilter = selectedFilters.filter(({ isApplied = true }) => isApplied);

  const renderFilterChip = filter => {
    return (
      <Chip
        className={classes.chip}
        deleteIcon={<CloseIcon />}
        label={filter.label}
        onDelete={() => removeFromFilters(filter, selectedFilters, updateSelectedFilter)}
      />
    );
  };

  return (
    <Grid className={classes.filterSummary} item>
      <Typography className={classes.filterCount}>
        Filters (
        <Typography className={classes.resultCount} variant="body3">
          {resultCount} results
        </Typography>
        ):
      </Typography>
      <Grid item>
        {appliedFilter.map(renderFilterChip)}
        {appliedFilter.length !== 0 && (
          <Typography
            className={classes.clear}
            variant="body3"
            onClick={() => updateSelectedFilter([])}
          >
            Clear All
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

FilterSummary.propTypes = {
  isVisible: PropTypes.bool,
  resultCount: PropTypes.number.isRequired,
  selectedFilter: PropTypes.array.isRequired,
  onClearFilter: PropTypes.func.isRequired
};

FilterSummary.defaultProps = {
  isVisible: true
};
