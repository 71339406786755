import isNumber from 'lodash/isNumber';
import sortBy from 'lodash/sortBy';
import moment from 'moment';

import Constants from 'utils/AppConstants';

export const selectReviewReportCreatedBy = reviewReport => reviewReport?.createdBy;

export const selectReviewReportCreatedDate = reviewReport =>
  reviewReport?.createdDateTime
    ? moment
        .unix(parseInt(reviewReport.createdDateTime, 10) / 1000)
        .format(Constants.DATETIME_FORMAT)
    : undefined;

export const selectReviewReportSummaryNotes = reviewReport =>
  sortBy(reviewReport?.summaries?.items, 'createdDateTime') || [];

export const selectReviewReportNotes = reviewReport => reviewReport?.technicianNotes?.items;

export const selectReviewReportDiscounts = reviewReport =>
  sortBy(reviewReport?.discounts?.items, 'createdDateTime');

export const selectReviewReportFees = reviewReport =>
  sortBy(reviewReport?.fees?.items, 'createdDateTime');

export const selectReviewReportAttachments = reviewReport =>
  sortBy(reviewReport?.attachments?.items, 'createdDateTime');

export const selectLaborLineItems = reviewReport =>
  sortBy(reviewReport?.labourRateLineItems?.items, 'createdDateTime');

export const selectReviewReportPriceBookId = reviewReport => reviewReport?.priceBookId;

export const selectReviewReportPurchasesItems = reviewReport =>
  sortBy(reviewReport?.purchaseOrders?.items, 'dateOfPurchase');

export const selectReviewReportPurchasedLineItemRows = reviewReport =>
  reviewReport?.purchaseOrderLines?.items?.map(lineItem => ({
    ...lineItem,
    totalAmount:
      isNumber(lineItem?.quantity) && isNumber(lineItem?.unitPrice)
        ? lineItem.quantity * lineItem.unitPrice
        : undefined
  })) || [];
