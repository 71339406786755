import React from 'react';

import { bool, func, object, string } from 'prop-types';

import { SergeantModal } from 'components';

const TableRowModal = ({ open, data, layout, title, onAction, onClose, customComponents }) => {
  return (
    <SergeantModal
      customComponents={customComponents}
      data={data}
      dataType=""
      handleClose={onClose}
      handlePrimaryAction={async (values, stopLoading) => {
        try {
          await onAction(values);
        } finally {
          stopLoading();
        }
      }}
      layout={layout}
      open={open}
      title={title}
    />
  );
};

TableRowModal.propTypes = {
  open: bool.isRequired,
  data: object.isRequired,
  layout: object.isRequired,
  title: string.isRequired,
  onAction: func.isRequired,
  onClose: func.isRequired,
  customComponents: object
};

TableRowModal.defaultProps = {
  customComponents: {}
};

export default TableRowModal;
