import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Spinner, UserPermission } from 'components';
import ErrorBoundaries from 'scenes/Error';
import { PermissionConstants } from 'utils/AppConstants';

import { useVisitDetails } from '../../queries';
import { selectForms } from '../../selectors';

import AssetTable from '../AssetTable';
import FormTable from '../FormTable';
import LaborSection from '../LaborSection';
import PartsAndMaterialsSection from '../PartsAndMaterialsSection';
import ReportInfo from '../ReportInfo';
import SignatureSection from '../SignatureSection';
import TasksSection from '../TasksSection';

import TechnicianReportAttachmentTable from './components/TechnicianReportAttachmentTable';
import TechnicianReportHeader from './components/TechnicianReportHeader';
import TechnicianReportNotesTable from './components/TechnicianReportNotesTable';
import TechnicianReportSummaryNotesTable from './components/TechnicianReportSummaryNotesTable';

const TechnicianReport = () => {
  const { id } = useParams();
  const { loading, data, error, refetch } = useVisitDetails(id);
  const { job, ...visit } = data;

  useEffect(() => {
    refetch();
  }, []);

  if (job && job.closeoutReport) return null;

  return loading ? (
    <Spinner />
  ) : (
    <ErrorBoundaries>
      <UserPermission action={PermissionConstants.OBJECT_VISIT} I="read">
        <TechnicianReportHeader job={job} reportId={id} visit={visit} />
        <ReportInfo job={job} visit={visit} />
        <TechnicianReportSummaryNotesTable error={error} loading={loading} visit={visit} />
        <TechnicianReportNotesTable error={error} loading={loading} visit={visit} />
        <AssetTable error={error} loading={loading} visit={visit} />
        <TasksSection error={error} job={job} loading={loading} visit={visit} />
        <FormTable error={error} forms={selectForms(visit)} loading={loading} />
        <TechnicianReportAttachmentTable error={error} loading={loading} visit={visit} />
        <SignatureSection visit={visit} />
        <PartsAndMaterialsSection error={error} loading={loading} visit={visit} />
        <LaborSection error={error} job={job} loading={loading} visit={visit} />
      </UserPermission>
    </ErrorBoundaries>
  );
};

export default TechnicianReport;
