import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { LinkButton } from 'components';
import { camelCaseToTitleCase } from 'utils';

const useStyles = makeStyles(theme => ({
  fieldTitle: {
    color: theme.palette.grayscale(20),
    marginBottom: '0.35em'
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start'
  },
  linkText: {
    fontSize: 16
  },
  inputLabel: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'normal',
    letterSpacing: '0.01px',
    lineHeight: '14px',
    marginBottom: theme.spacing(0.3)
  }
}));

export default function LinkButtonForm({ form, field, options }) {
  const classes = useStyles();
  const fieldValue = form?.values?.[field?.name];
  const { label, path } = fieldValue || {};
  return (
    <div className={classes.fieldContainer}>
      <Typography className={classes.fieldTitle} variant="overline">
        {options?.linkButtonLabel || camelCaseToTitleCase(field?.name)}
      </Typography>
      {path ? (
        <LinkButton classes={{ root: classes.linkText }} label={label} path={path} />
      ) : (
        <Typography>-</Typography>
      )}
    </div>
  );
}
