import { selectEndDay } from '@dispatch/Dispatch.selectors';
import { withQueryStringStore } from '@dispatch/Dispatch.store';

import DayRangeSelector from './DayRangeSelector.component';

const mapQueryStringToProps = ({ state, ...store }) => ({
  selectDay: store.selectDay,
  selectEndDay: store.selectEndDay,
  day: state.day,
  endDay: selectEndDay(state.endDay, state.weekView, state.day),
  weekView: Boolean(state.weekView)
});

export default withQueryStringStore(mapQueryStringToProps)(DayRangeSelector);
