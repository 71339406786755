import gql from 'graphql-tag';
import moment from 'moment';

import { VisitActions, VisitStatuses } from '@dispatch/Dispatch.constants';
import { updateWatchedVisitQueries, visitsHaveSameSchedule } from '@dispatch/Dispatch.utils';
import { emptyVisitDetailsFragment, visitDetailsFragment } from '@dispatch/fragments';
import { useDispatchSettings } from '@dispatch/settings';
import useExtendedMutation from 'customHooks/useExtendedMutation';
import { quietRefreshTableData } from 'redux/actions/dispatchActions';
import { dispatch } from 'redux/store';
import mergeDeep from 'utils/mergeDeep';

import * as Serializers from './useVisitTransition.serializers';

export const VISIT_TRANSITION = gql`
  mutation visitTransition(
    $transitionAction: String!
    $visitTransitionInput: visitTransitionInput!
  ) {
    visitTransition(visitAction: $transitionAction, data: $visitTransitionInput) {
      ${visitDetailsFragment}
    }
  }
`;

export const selectTransitionAction = ({ requestedAction, originalVisit }) => {
  const availableActions = originalVisit.nextEvents;

  if (requestedAction) {
    if (availableActions.includes(requestedAction)) return requestedAction;
    throw new Error(
      `Requested action ${requestedAction} is not available for visit status ${originalVisit.status}. Available actions are: ${availableActions}`
    );
  }

  if (!originalVisit.saveTransition) {
    throw new Error('Unable to infer transition action. Try passing the requestedAction param.');
  }

  return originalVisit.saveTransition;
};

// transitionAction - one of Dispatch.constants VisitActions
// originalVisit - in VISIT_DETAILS_PROP shape
// inputData - in VisitDetailsForm.data shape
export const selectVisitTransitionInput = ({ transitionAction, originalVisit, inputData }) => {
  const serializationActions = originalVisit.visitStateMachine.transition(
    originalVisit.visitStateMachine.initialState,
    { type: transitionAction, originalVisit, inputData }
  ).actions;

  return serializationActions.reduce(
    (visitTransitionInput, serializationAction) =>
      Serializers[serializationAction].serializer({
        event: { transitionAction, originalVisit, inputData },
        visitTransitionInput
      }),
    { visit: { id: originalVisit.id } }
  );
};

const serializeVisitDetails = ({ transitionAction, originalVisit, inputData }) => {
  if (!(originalVisit.departmentId || originalVisit.departmentName || inputData.departmentId)) {
    throw new Error('You must assign a department to transition this visit');
  }

  return {
    variables: {
      transitionAction,
      visitTransitionInput: selectVisitTransitionInput({
        transitionAction,
        originalVisit,
        inputData
      })
    }
  };
};

const optimisticResponseFactory = ({
  transitionAction: requestedAction,
  originalVisit,
  inputData
}) => {
  const transitionAction = selectTransitionAction({ requestedAction, originalVisit, inputData });

  const transitioned = originalVisit.visitStateMachine.transition(
    originalVisit.visitStateMachine.initialState,
    { type: transitionAction, originalVisit, inputData }
  );

  const visitTransitionInput = selectVisitTransitionInput({
    transitionAction,
    originalVisit,
    inputData
  });

  const { serverVisit } = originalVisit;

  const optimisticVisit = {
    ...visitTransitionInput.visit,
    status: VisitStatuses.get(transitioned.value)?.value?.serverValue,
    extraTechs: {
      items: visitTransitionInput.extraTechIds
        ? visitTransitionInput.extraTechIds.map(id => ({
            __typename: 'SystemEntityMap',
            id: `optimistic_extra_tech_${id}`,
            mappedEntityId: id
          }))
        : serverVisit.extraTechs.items
    },
    primaryTechs: {
      items: visitTransitionInput.primaryTechIds
        ? visitTransitionInput.primaryTechIds.map(id => ({
            __typename: 'SystemEntityMap',
            id: `optimistic_primary_tech_${id}`,
            mappedEntityId: id
          }))
        : serverVisit.primaryTechs.items
    }
  };

  const continueInNewVisitOverride =
    transitionAction === VisitActions.CONTINUE_IN_NEW_VISIT.key
      ? {
          detailsSent: null,
          id: emptyVisitDetailsFragment.id,
          onHold: null,
          onHoldReason: null,
          status: VisitStatuses.SCHEDULED.value.serverValue
        }
      : {};

  const optimisticResponse = {
    visitTransition: mergeDeep(
      emptyVisitDetailsFragment,
      serverVisit,
      optimisticVisit,
      continueInNewVisitOverride
    )
  };

  return optimisticResponse;
};

const CONFIRMATION_TEXT =
  'If any technicians working on this visit ' +
  'are offline and have already started working on this visit, ' +
  'any information that has not been uploaded from their mobile app ' +
  'may be lost. Are you sure you want to continue?';

export const useVisitTransition = options => {
  const { releaseToTechEnabled } = useDispatchSettings();

  return useExtendedMutation(VISIT_TRANSITION, {
    serializer: ({ transitionAction: requestedAction, originalVisit, inputData }) => {
      const transitionAction = selectTransitionAction({ requestedAction, originalVisit });

      return serializeVisitDetails({ transitionAction, originalVisit, inputData });
    },
    optimisticResponseFactory,
    showConfirmation: ({ transitionAction: requestedAction, originalVisit, inputData }) => {
      const transitionAction = selectTransitionAction({ requestedAction, originalVisit });

      const isNotReleasedToTech = releaseToTechEnabled && !originalVisit?.detailsSent;
      if (isNotReleasedToTech) return false;

      const isFutureVisit = moment.unix(originalVisit.scheduledFor).isAfter(moment().endOf('day'));
      if (isFutureVisit) return false;

      if (visitsHaveSameSchedule(originalVisit, inputData)) return false;

      return [
        VisitActions.COMPLETE.key,
        VisitActions.CANCEL.key,
        VisitActions.PUT_ON_HOLD.key,
        VisitActions.RESCHEDULE.key
      ].includes(transitionAction);
    },
    confirmationModalOptions: ({ transitionAction: requestedAction, originalVisit }) => {
      const transitionAction = selectTransitionAction({ requestedAction, originalVisit });

      return {
        title: `${VisitActions.get(transitionAction)?.value} Visit`,
        warn: true,
        modalText: CONFIRMATION_TEXT
      };
    },
    onCompleted: () => dispatch(quietRefreshTableData()),
    update: (cache, { data }, { transitionAction: requestedAction, originalVisit }) => {
      const transitionAction = selectTransitionAction({ requestedAction, originalVisit });

      updateWatchedVisitQueries({
        cache,
        updatedVisit: data.visitTransition,
        transitionAction,
        originalVisit
      });
    },
    ...options
  });
};
