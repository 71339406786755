import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { getServiceAgreementById } from 'customHooks/useServiceAgreement';

const addCustomerPropertyChecklistsToServiceAgreement = gql`
  mutation addCustomerPropertyChecklistsToServiceAgreement(
    $serviceAgreementId: String!
    $input: [CustomerPropertyTaskTemplateChecklistsInput!]!
  ) {
    addCustomerPropertyChecklistsToServiceAgreement(
      serviceAgreementId: $serviceAgreementId
      input: $input
    ) {
      items {
        id
        companyName
        entityType
        isActive
        status
      }
    }
  }
`;

const useAddCustomerPropertyChecklistsToServiceAgreement = ({ user, serviceAgreementId }) => {
  return useExtendedMutation(addCustomerPropertyChecklistsToServiceAgreement, {
    serializer: ({ generalChecklistsToAdd }) => ({
      variables: {
        serviceAgreementId,
        input: generalChecklistsToAdd
      }
    }),
    onSuccess: (data, snackbar) => {
      snackbar('success', 'Service Agreement Saved');
    },
    skip: !serviceAgreementId,
    onError: (error, snackbar) => {
      snackbar('error', 'Unable to Save Service Agreement');
    },
    // @TODO refetchQueries is placeholder for the update, once we figure out the issue with undesirable auto cache updating
    refetchQueries: [
      {
        query: getServiceAgreementById,
        variables: {
          partitionKey: user.tenantId,
          id: serviceAgreementId
        }
      }
    ]
  });
};

export default useAddCustomerPropertyChecklistsToServiceAgreement;
