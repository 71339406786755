import React from 'react';

import ExpandMore from '@material-ui/icons/ExpandMore';
import { array, bool, func, string } from 'prop-types';

import AutoCompleteDropdown from 'components/AutoCompleteDropdown';

import { useStyles } from './PriceBookSelect.styles';

const PriceBookSelect = ({ priceBookId, priceBooks, onChange, disabled }) => {
  const classes = useStyles();
  const options =
    priceBooks?.map(laborType => ({ value: laborType.id, label: laborType.name })) || [];
  return (
    <AutoCompleteDropdown
      disabled={disabled}
      DropdownIndicator={() => <ExpandMore />}
      handleOnChange={onChange}
      inputProps={{
        inputClassNames: classes.selectionInput,
        className: classes.selectionWrapper,
        disableUnderline: true
      }}
      options={options}
      value={priceBookId}
    />
  );
};

PriceBookSelect.propTypes = {
  priceBookId: string.isRequired,
  priceBooks: array.isRequired,
  onChange: func.isRequired,
  disabled: bool.isRequired
};

export default PriceBookSelect;
