import React from 'react';

import { Typography, withStyles } from '@material-ui/core';
import LocationOn from '@material-ui/icons/LocationOn';

import { convertDistance, getDistance } from 'geolib';
import _ from 'lodash';
import { connect } from 'react-redux';

import { ResponsiveTable } from 'components';

import invoiceLabels from 'meta/Jobs/Invoice/labels';
import { technicianTimesheetNotes, timeTrackingRows } from 'meta/Jobs/Invoice/review-report-tables';
import ErrorBoundaries from 'scenes/Error';
import { AttachmentImages } from 'scenes/Payroll/TimeTrackingReport/TimesheetNotes';

import { backendDateToMoment } from 'utils';
import { getDateFormat, PermissionConstants, TimesheetWorkTypes } from 'utils/AppConstants';

import styles from './styles';

function TimeTrackingTable(props) {
  const DateWithLocation = ({ meta, record }) => {
    const momentObj = record[meta.id] ? backendDateToMoment(record[meta.id]) : null;
    const dateText = momentObj && momentObj.format(getDateFormat('datetime'));

    if (record.startLatitude || record.endLatitude) {
      const coordinates =
        meta.label === 'Start'
          ? { latitude: record.startLatitude, longitude: record.startLongitude }
          : { latitude: record.endLatitude, longitude: record.endLongitude };
      const propertyCoordinates = record.propertyLocation;

      if (
        coordinates?.longitude &&
        coordinates?.latitude &&
        propertyCoordinates?.longitude &&
        propertyCoordinates?.latitude
      ) {
        const distance = getDistance(coordinates, propertyCoordinates, 100);
        const url = `https://www.google.com/maps/dir/${coordinates.latitude},${coordinates.longitude}/${propertyCoordinates.latitude},${propertyCoordinates.longitude}`;
        return (
          <div className={props.classes.textStyle}>
            {dateText}
            <br />
            <LocationOn className={props.classes.locationStyle} />
            <a
              className={props.classes.locationText}
              href={url}
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              {`${convertDistance(distance, 'mi').toFixed(2)} mi from job`}
            </a>
          </div>
        );
      }
    }
    return <div className={props.classes.textStyle}>{dateText}</div>;
  };

  const { classes, timesheetEntries, user, isReviewReport, context } = props;
  const { jobInfoData } = context;

  if (!context.isAutomatedTimeTrackingEnabled) return null;

  const formatDifferenceInSecsAsText = seconds => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    return `${hrs || 0}h ${mins || 0}m`;
  };

  const getTimeDifference = (startTime, endTime) => {
    if (!startTime || !endTime) {
      return { timeDifferenceInSeconds: 0, timeDifferenceInText: '-' };
    }

    const timeDifferenceInSeconds = endTime - startTime;

    return {
      timeDifferenceInSeconds,
      timeDifferenceInText: formatDifferenceInSecsAsText(timeDifferenceInSeconds)
    };
  };

  const totalsByType = (total, val, actionType) => {
    let localSum = total;
    if (val[actionType]) {
      localSum = Math.round(total) + Math.round(val.timeDifferenceInSeconds);
    }
    return localSum;
  };

  if (!context.isAutomatedTimeTrackingEnabled) {
    return null;
  }

  const propertyAddress = jobInfoData?.parentEntity?.companyAddresses?.items?.find(
    addr => addr.addressType === 'propertyAddress'
  );

  const processedTimesheetEntries = timesheetEntries.map(entry => {
    const localEntry = entry;

    localEntry.startTime = localEntry?.actualStartTimeOverrideUTC || localEntry?.actualStartTimeUTC;
    localEntry.endTime = localEntry?.actualEndTimeOverrideUTC || localEntry?.actualEndTimeUTC;

    const timeDifference = getTimeDifference(localEntry.startTime, localEntry.endTime);
    const eventType = localEntry?.timekeepingLedger?.eventType;

    if (eventType?.name === 'Visit') {
      localEntry[localEntry.userActionType] = timeDifference?.timeDifferenceInText;
      localEntry.timeDifferenceInSeconds = timeDifference?.timeDifferenceInSeconds;
    } else if (eventType?.isBillable) {
      localEntry.billableEvents = timeDifference?.timeDifferenceInText;
      localEntry.timeDifferenceInSeconds = timeDifference?.timeDifferenceInSeconds;
    }

    localEntry.employeeName = localEntry?.timekeepingLedger?.employeeName;

    if (propertyAddress) {
      localEntry.propertyLocation = {
        latitude: propertyAddress?.latitude,
        longitude: propertyAddress?.longitude
      };
    }

    return localEntry;
  });

  // Generate totals by work action type
  const totalsEntry = { employeeName: 'Totals' };
  Object.values(TimesheetWorkTypes).forEach(workType => {
    totalsEntry[workType] = formatDifferenceInSecsAsText(
      timesheetEntries.reduce((total, val) => totalsByType(total, val, workType), 0)
    );
  });

  processedTimesheetEntries.push(totalsEntry);

  const nonVisitEventNotes = [];
  processedTimesheetEntries.forEach(entry => {
    const eventType = entry?.timekeepingLedger?.eventType;
    const eventEntity = entry?.timekeepingLedger?.eventEntity || {};
    if (eventType?.name !== 'Visit' && eventType?.isBillable) {
      const notes = eventEntity.timesheetNotes?.items || [];
      notes.forEach(note => nonVisitEventNotes.push({ ...note, eventName: 'Billable Event' }));
    }
  });
  const sortedTimesheetEntries = _.sortBy(processedTimesheetEntries, 'startTime');
  const combinedTimsheetNotes = (context.visitTimesheetNotes || []).concat(nonVisitEventNotes);
  const uniqTimesheetNotes = _.uniqBy(combinedTimsheetNotes, 'id');

  return (
    <ErrorBoundaries>
      <Typography className={classes.greySectionTitle}>
        {invoiceLabels.timeTracking[user.locale]}
      </Typography>
      <ResponsiveTable
        caslKey={
          isReviewReport
            ? PermissionConstants.OBJECT_REVIEWREPORT
            : PermissionConstants.OBJECT_VISIT
        }
        customCellComponents={{ DateWithLocation }}
        data={sortedTimesheetEntries || []}
        disableFilter
        disablePagination
        noMaxHeight
        rowMetadata={timeTrackingRows}
      />
      <Typography className={classes.greySectionTitle}>
        {invoiceLabels.notesByTechnician[user.locale]}
      </Typography>

      <ResponsiveTable
        customCellComponents={{
          AttachmentImages: ({ record }) => <AttachmentImages attachments={record.attachments} />
        }}
        data={uniqTimesheetNotes || []}
        disableFilter
        disablePagination
        noMaxHeight
        rowMetadata={technicianTimesheetNotes}
      />
    </ErrorBoundaries>
  );
}

const styledTimeTrackingTable = withStyles(styles)(TimeTrackingTable);

const mapStateToProps = state => ({
  user: state.user
});

const reduxConnectedTimeTrackingTable = connect(mapStateToProps, null)(styledTimeTrackingTable);

export default reduxConnectedTimeTrackingTable;
