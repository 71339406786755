const padding = 12;
const minWidth = 260;

const PreferredTechniciansFormLayout = ({
  departmentOptions,
  technicianOptions,
  additionalTechniciansOptions,
  crewOptions,
  requiredFields,
  showCrewWarningMessage,
  color,
  hideExtraTechNumber = false,
  restrictedEdits,
  crewTimeTracking,
  primaryTechName
}) => ({
  fields: {},
  layouts: {
    edit: {
      options: {
        pageSize: 'LETTER'
      },
      contents: [
        {
          options: {
            direction: 'row',
            flexWrap: 'wrap'
          },
          contents: [
            {
              options: {
                padding: [padding, padding, padding, 0],
                grow: 1,
                minWidth
              },
              contents: [
                {
                  component: 'SelectInput',
                  source: 'departmentId',
                  options: {
                    label: 'Department',
                    isRequired: requiredFields.departmentId,
                    isSearchable: true,
                    isClearable: false,
                    placeholder: 'Select Department',
                    valueSet: departmentOptions,
                    color,
                    disabled: restrictedEdits
                  }
                }
              ]
            },
            {
              options: {
                padding,
                grow: 1,
                minWidth
              },
              contents: [
                {
                  component: 'SelectInput',
                  source: 'crewId',
                  options: {
                    color,
                    isSearchable: true,
                    label: 'Crew',
                    placeholder: 'Select Crew',
                    valueSet: crewOptions,
                    disabled: restrictedEdits
                  }
                }
              ]
            },
            {
              options: {
                padding: [padding, 0, padding, padding],
                grow: 1,
                minWidth
              },
              contents: [
                {
                  component: 'SelectInput',
                  source: 'primaryTechnicianId',
                  options: {
                    color,
                    label: 'Primary Technician',
                    isRequired: requiredFields.primaryTechnicianId,
                    isSearchable: true,
                    placeholder: 'Select Primary Technician',
                    valueSet: technicianOptions,
                    disabled: restrictedEdits
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                padding: [0, padding]
              },
              contents: [
                {
                  component: 'CrewFormWarningMessage',
                  options: {
                    showCrewWarningMessage
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                padding: [padding, padding, padding, 0],
                flex: 1,
                hide: hideExtraTechNumber || crewTimeTracking,
                maxWidth: 310
              },
              contents: [
                {
                  component: 'TextInput',
                  source: 'extraTechsNumber',
                  options: {
                    label: 'ADDITIONAL TECHNICIANS REQUIRED',
                    type: 'number',
                    min: 0,
                    color,
                    disabled: restrictedEdits
                  }
                }
              ]
            },
            {
              options: {
                padding: [padding, 0, padding, padding],
                paddingLeft: hideExtraTechNumber || crewTimeTracking ? 0 : padding,
                flex: 2
              },
              contents: [
                {
                  component: 'SelectInput',
                  source: 'additionalTechnicianIds',
                  options: {
                    color,
                    label: 'Additional Technicians',
                    placeholder: 'Select Additional Technicians',
                    isMultipleSelection: true,
                    isSearchable: true,
                    valueSet: additionalTechniciansOptions
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              contents: [
                {
                  component: 'CrewMembersAddedMessage',
                  options: {
                    primaryTechName
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      departmentId: {
        type: 'string'
      },
      crewId: {
        type: 'string',
        nullable: true
      },
      primaryTechnicianId: {
        type: 'string',
        nullable: true
      },
      additionalTechnicianIds: {
        type: 'array'
      }
    },
    required: Object.keys(requiredFields)
  },
  validationErrors: {
    departmentId: {
      required: 'Department is required'
    },
    primaryTechnicianId: {
      required: 'Primary Technician is required'
    }
  }
});

export default PreferredTechniciansFormLayout;
