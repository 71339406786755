import { EVENT_TYPES } from '@dispatch/Dispatch.constants';

import * as Icons from './icons';

export const EventIcons = {
  [EVENT_TYPES.VISIT]: Icons.VisitIcon,
  [EVENT_TYPES.BILLABLE_EVENT]: Icons.BillableNonVisitIcon,
  [EVENT_TYPES.NON_BILLABLE_EVENT]: Icons.NonVisitIcon,
  [EVENT_TYPES.MAN_DAY]: Icons.ManDayIcon
};
