import React from 'react';

import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import PropTypes from 'prop-types';

function PopperMenu(props) {
  const { itemList, anchorEl, open, onClose } = props;
  return (
    <Popper
      anchorEl={anchorEl}
      open={open}
      property="bottom-end"
      style={{ zIndex: 9999 }}
      transition
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList>
                {itemList.map(itemParams => (
                  <MenuItem
                    className={itemParams.className}
                    disabled={itemParams.disabled}
                    key={itemParams.key || itemParams.label}
                    onClick={() => {
                      if (itemParams.onClick) {
                        itemParams.onClick();
                      }
                      onClose();
                    }}
                  >
                    {itemParams.label}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

PopperMenu.propTypes = {
  itemList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      key: PropTypes.string,
      className: PropTypes.string
    })
  ).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
    .isRequired
};

export default PopperMenu;
