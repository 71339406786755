import React, { useState } from 'react';

import FullScreenModal from 'components/FullScreenModal';

import { getFileUrl } from '../FileManagement.helpers';

import { useStyles } from './FileViewer.styles';

const SUPPORTED_FILE_TYPES = {
  PDF: 'pdf'
};

const FileViewer = props => {
  const styles = useStyles();

  const [fileData, setFileData] = useState({});

  const handleFileOpen = ({ fileDetails }) => {
    const { type: fileType, name } = fileDetails || {};
    if (fileType === '.pdf') {
      setFileData({
        name,
        url: getFileUrl(fileDetails),
        type: SUPPORTED_FILE_TYPES.PDF
      });
    }
  };

  const handleModalClose = () => {
    setFileData({});
  };

  return (
    <>
      {props.children(handleFileOpen)}

      <div css={styles.modalContainer}>
        <FullScreenModal
          contentStyle={{ marginTop: 0, marginBottom: 0, flex: '1 1 auto' }}
          handleClose={handleModalClose}
          modalHeaderButtons={[]}
          open={Boolean(fileData.type)}
          style={{ display: 'flex', flex: '1 1 auto' }}
          title={fileData.name || ''}
        >
          {fileData.type === SUPPORTED_FILE_TYPES.PDF && (
            <iframe height="100%" src={fileData.url} title="merged" width="100%" />
          )}
        </FullScreenModal>
      </div>
    </>
  );
};

FileViewer.propTypes = {};

export default FileViewer;
