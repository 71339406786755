import React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import * as R from 'ramda';

import { Placeholder } from 'components';
import labels from 'meta/labels';
import ErrorBoundaries from 'scenes/Error';

import ActionsMenu from './ActionsMenu';
import { getCellComponent, getRowId } from './tableUtils';

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  fieldName: {
    fontSize: 14,
    fontWeight: 'bold'
  }
});

export default function ResponsiveAccordions(props) {
  const {
    rows,
    rowActionButtons,
    metadata,
    customCellComponents,
    rowActions,
    noDataMsg,
    user,
    isSelectionEnabled,
    selectedRows,
    onSelectRowClick,
    footerComponent
  } = props;

  let highlightMeta = metadata.filter(item => item.isHighlight);
  if (!highlightMeta || highlightMeta.length === 0) {
    highlightMeta = metadata.filter((item, index) => index < 3);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenuClick = (event, rowData) => {
    setAnchorEl({ ref: event.currentTarget, record: rowData });
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  if (props.isLoading) {
    return (
      <Paper>
        <Placeholder repeatCount={3} variant="card" />
      </Paper>
    );
  }

  return (
    <ErrorBoundaries>
      <div className={classes.root}>
        {rows.map(rowData => {
          const isRowSelected = selectedRows.has(getRowId(rowData));
          const rowKey = `${getRowId(rowData)}`;
          return (
            <ErrorBoundaries key={`accordion-${rowKey}`}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid alignItems="center" container direction="row" justify="space-between">
                    {isSelectionEnabled && (
                      <Checkbox
                        checked={isRowSelected}
                        key={`selectCheckbox-${rowKey}`}
                        onClick={event => onSelectRowClick(event, rowData)}
                      />
                    )}
                    {highlightMeta.map(meta => {
                      const CellData = getCellComponent(meta, customCellComponents);
                      return (
                        <Grid item key={`rowHighlight-${meta.id}-${rowKey}`}>
                          <CellData meta={meta} record={rowData} />
                        </Grid>
                      );
                    })}
                    {rowActionButtons && !R.isEmpty(rowActionButtons) && (
                      <IconButton
                        key={`viewRowActionsButton-${rowKey}`}
                        onClick={event => {
                          event.stopPropagation();
                          handleMenuClick(event, rowData);
                        }}
                      >
                        <MoreHorizIcon color="secondary" />
                      </IconButton>
                    )}
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    alignItems="center"
                    container
                    direction="row"
                    justify="space-between"
                    spacing={1}
                  >
                    {metadata.map(meta => {
                      const CellData = getCellComponent(meta, customCellComponents);
                      return (
                        <React.Fragment key={`rowDetail-${meta.id}-${rowKey}`}>
                          <Grid item xs={4}>
                            <Typography className={classes.fieldName}>
                              {(labels[meta.label] || {})[user.locale] || meta.label}
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <CellData meta={meta} record={rowData} />
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </ErrorBoundaries>
          );
        })}
        {rows.length === 0 && <div style={{ color: 'black' }}>{noDataMsg}</div>}
        {footerComponent()}
        <ErrorBoundaries>
          <ActionsMenu
            anchorEl={anchorEl}
            handleMenuClose={handleMenuClose}
            rowActionButtons={rowActionButtons}
            rowActions={rowActions}
          />
        </ErrorBoundaries>
      </div>
    </ErrorBoundaries>
  );
}
