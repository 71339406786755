import AmplifyService from 'services/AmplifyService';

import updateNote from '../../graphql/common/mutations/updateNote';
import addEmployeePayrollsToEmployee from '../../graphql/timesheets/mutations/addEmployeePayrollsToEmployee';
import addNotesToTimeCardVisit from '../../graphql/timesheets/mutations/addNotesToTimeCardVisit';
import addPayrollBillingHourTypesMutation from '../../graphql/timesheets/mutations/addPayrollBillingHourTypes';
import addPayrollHourTypesMutation from '../../graphql/timesheets/mutations/addPayrollHourTypes';
import addPayrollLabourTypesMutation from '../../graphql/timesheets/mutations/addPayrollLabourTypes';
import addTimeCardLinesToTimeCard from '../../graphql/timesheets/mutations/addTimeCardLinesToTimeCard';
import addTimeCardsToEmployeePayroll from '../../graphql/timesheets/mutations/addTimeCardsToEmployeePayroll';
import addTimeCardVisitsToTimeCard from '../../graphql/timesheets/mutations/addTimeCardVisitsToTimeCard';
import deletePayrollLabourTypesMutation from '../../graphql/timesheets/mutations/softDeleteLabourType';
import deletePayrollBillingHourTypesMutation from '../../graphql/timesheets/mutations/softDeletePayrollBillingHourTypes';
import deletePayrollHourTypesMutation from '../../graphql/timesheets/mutations/softDeletePayrollTypes';
import softDeleteTimeCardLine from '../../graphql/timesheets/mutations/softDeleteTimeCardLine';
import updatePayrollBillingHourTypesMutation from '../../graphql/timesheets/mutations/updatePayrollBillingHourTypes';
import updatePayrollHourTypesMutation from '../../graphql/timesheets/mutations/updatePayrollHourTypes';
import updatePayrollLabourTypesMutation from '../../graphql/timesheets/mutations/updatePayrollLabourType';
import updateTimeCard from '../../graphql/timesheets/mutations/updateTimeCard';
import updateTimeCardAndRelated from '../../graphql/timesheets/mutations/updateTimeCardAndRelated';
import updateTimeCardLine from '../../graphql/timesheets/mutations/updateTimeCardLine';
import updateTimeCardVisit from '../../graphql/timesheets/mutations/updateTimeCardVisit';
import getEmployeeWithVisitsAndPayrolls from '../../graphql/timesheets/queries/getEmployeeWithVisitsAndPayrolls';
import getEmptyTimeCards from '../../graphql/timesheets/queries/getEmptyTimeCards';
import getNonVisitEventsForEmployee from '../../graphql/timesheets/queries/getNonVisitEventsForEmployee';
import getPayrollBillingHourTypesQuery from '../../graphql/timesheets/queries/getPayrollBillingHourTypes';
import getPayrollHourTypesQuery from '../../graphql/timesheets/queries/getPayrollHourTypes';
import getTimeCardById from '../../graphql/timesheets/queries/getTimeCardById';

import { generateCompanySortKey } from '../../utils';

export default class TimesheetsService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
  }

  getEmptyTimeCards = async (partitionKey, SortKey, visitRange) => {
    const params = {
      partitionKey,
      SortKey,
      visitRange
    };

    try {
      const response = await this.api.query(getEmptyTimeCards, params);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

  getEmployeeWithVisitsAndPayrolls = async (partitionKey, sortKey, startTime, endTime) => {
    const params = {
      partitionKey,
      sortKey,
      startTime,
      endTime
    };

    try {
      let response = await this.api.query(getEmployeeWithVisitsAndPayrolls, params);
      response = response.data;

      const employee = response && response.getEmployee;

      const res = {
        visits: employee.allVisitsView.items,
        offSchedules: employee && employee.offSchedules && employee.offSchedules.items,
        employee: {
          id: employee.id,
          partitionKey: employee.partitionKey,
          sortKey: employee.sortKey
        },
        employeePayrolls: employee.employeePayrolls && employee.employeePayrolls.items
      };

      return res;
    } catch (error) {
      throw new Error(error);
    }
  };

  getNonVisitEventsForEmployee = (partitionKey, sortKey, employeeId, startTime, endTime) => {
    const params = {
      partitionKey,
      sortKey,
      startTime,
      endTime,
      employeeId
    };

    return this.api.query(getNonVisitEventsForEmployee, params);
  };

  addEmployeePayrollsToEmployee = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(addEmployeePayrollsToEmployee, params);
    return response;
  };

  addTimeCardVisitsToTimeCard = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(addTimeCardVisitsToTimeCard, params);
    return response;
  };

  addNotesToTimeCardVisit = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(addNotesToTimeCardVisit, params);
    return response;
  };

  addTimeCardsToEmployeePayroll = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(addTimeCardsToEmployeePayroll, params);
    return response;
  };

  addTimeCardLinesToTimeCard = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(addTimeCardLinesToTimeCard, params);
    return response;
  };

  updateTimeCardLine = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(updateTimeCardLine, params);
    return response;
  };

  updateNote = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: {
        id: values.id,
        version: values.version,
        note: values.note,
        subject: values.subject
      }
    };

    const response = await this.api.mutate(updateNote, params);
    return response;
  };

  updateTimeCard = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(updateTimeCard, params);
    return response;
  };

  updateTimeCardVisit = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(updateTimeCardVisit, params);
    return response;
  };

  softDeleteTimeCardLine = async (partitionKey, id) => {
    const data = {
      partitionKey,
      id
    };
    const response = await this.api.mutate(softDeleteTimeCardLine, data);
    return response;
  };

  updateTimeCardAndRelated = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(updateTimeCardAndRelated, params);
    return response;
  };

  getTimeCardById = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };
    const response = await this.api.query(getTimeCardById, params);
    return response;
  };

  getPayrollHourTypes = async (partitionKey, companyId) => {
    const params = {
      partitionKey,
      sortKey: generateCompanySortKey(partitionKey, companyId)
    };
    const response = await this.api.query(getPayrollHourTypesQuery, params);
    return response;
  };

  addPayrollHourTypes = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(addPayrollHourTypesMutation, params);
    return response;
  };

  updatePayrollHourTypes = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(updatePayrollHourTypesMutation, params);
    return response;
  };

  deletePayrollHourType = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };

    const response = await this.api.mutate(deletePayrollHourTypesMutation, params);
    return response;
  };

  addPayrollLabourTypes = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(addPayrollLabourTypesMutation, params);
    return response;
  };

  updatePayrollLabourTypes = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(updatePayrollLabourTypesMutation, params);
    return response;
  };

  deletePayrollHourType = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };

    const response = await this.api.mutate(deletePayrollLabourTypesMutation, params);
    return response;
  };

  getPayrollBillingHourTypes = async (partitionKey, companyId, filter) => {
    const params = {
      filter,
      partitionKey,
      sortKey: generateCompanySortKey(partitionKey, companyId)
    };
    const response = await this.api.query(getPayrollBillingHourTypesQuery, params);
    return response;
  };

  addPayrollBillingHourTypes = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(addPayrollBillingHourTypesMutation, params);
    return response;
  };

  updatePayrollBillingHourTypes = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(updatePayrollBillingHourTypesMutation, params);
    return response;
  };

  deletePayrollBillingHourType = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };

    const response = await this.api.mutate(deletePayrollBillingHourTypesMutation, params);
    return response;
  };
}
