import React from 'react';

import { Box, TextField, Typography } from '@material-ui/core';
import CX from 'classnames';
import { isEmpty } from 'lodash';

import { modifyListItem, replaceItem } from './helpers';
import ItemActions from './ItemActions';
import useStyles from './styles';

const CostCodes = ({ items, onUpdateCostCode, isAllowedToEdit, onUpdateCostCodeState }) => {
  const classes = useStyles();
  const [costCodes, setCostCodes] = React.useState([]);
  React.useEffect(() => {
    setCostCodes(items);
  }, [items]);

  const handleItemEdit = (value, key, index) => {
    const newItems = modifyListItem(costCodes, value, key, index);
    setCostCodes(newItems);
  };

  const handleSave = index => {
    const successCallback = response => {
      const newItems = replaceItem(costCodes, index, response);
      onUpdateCostCodeState(newItems);
    };
    const costCode = costCodes[index];
    onUpdateCostCode(costCode, successCallback);
  };

  const handleCancel = index => {
    const existingItem = items[index];
    const newItems = replaceItem(costCodes, index, existingItem);
    setCostCodes(newItems);
  };

  return (
    <Box display="flex" flex={1} flexDirection="column" py={2}>
      {isEmpty(items) && <Typography variant="body2">No Cost code entered</Typography>}
      {costCodes.map((item, i) => (
        <Box display="flex" key={i}>
          <Box className={classes.itemBorder} display="flex" width={350}>
            {['name', 'description'].map(itemKey => {
              const value = item[itemKey];
              if (!isAllowedToEdit) {
                return (
                  <Typography className={classes.notEditableValue} variant="body1">
                    {value || '-'}
                  </Typography>
                );
              }
              return (
                <TextField
                  className={CX(classes.textInputWrapper, {
                    [classes.descriptionInput]: itemKey === 'description'
                  })}
                  InputProps={{
                    className: classes.textInput,
                    disableUnderline: true
                  }}
                  key={itemKey}
                  placeholder={itemKey}
                  value={item[itemKey]}
                  onChange={e => handleItemEdit(e.target.value, itemKey, i)}
                />
              );
            })}
          </Box>
          <ItemActions
            handleCancel={handleCancel}
            handleSave={handleSave}
            index={i}
            isVisible={item.isEdited}
          />
        </Box>
      ))}
    </Box>
  );
};

export default CostCodes;
