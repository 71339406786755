import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { useSnackbar } from 'customHooks/useSnackbar';
import { logErrorWithCallback } from 'utils';

import { timesheetEntryBinderFragment } from '../gql';

export const updateTimesheetManualStatusMutation = gql`
  mutation updateTimesheetManualStatus(
    $data: UpdateTimesheetManualStatusInput!
  ) {
    updateTimesheetManualStatus(data: $data) {
      ${timesheetEntryBinderFragment}
    }
  }
`;

const serializer = ({ employeeId, startDate, manualStatus, reason = '' }) => {
  return {
    variables: {
      data: {
        employeeId,
        startDate,
        manualStatus,
        reason
      }
    }
  };
};

const useUpdateTimesheetManualStatus = () => {
  const snackbar = useSnackbar();

  return useExtendedMutation(updateTimesheetManualStatusMutation, {
    serializer,
    onError: error => {
      logErrorWithCallback(error, snackbar, 'Unable to update timesheet status');
    },
    onCompleted: () => {
      snackbar('success', 'Successfully updated timesheet status');
    }
  });
};

export default useUpdateTimesheetManualStatus;
