import React, { useRef, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Switch, ThemeProvider } from '@BuildHero/sergeant';
import { Box } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';

import { useCompanyTimezone } from '@pm/components/hooks';
import { FullScreenModal } from 'components';
import labels from 'meta/Scheduler/labels';
import { getTenantSettingValueForKey } from 'utils';

import { addVisitToJobMutation } from '../../addVisitToJobMutation';
import VisitForm from '../VisitForm';
import buildVisitCreatePayload from '../VisitForm/helpers/buildVisitCreatePayload';
import getCompanyDepartments from '../VisitForm/helpers/getCompanyDepartments';
import getPreferredTechniciansForNewVisit from '../VisitForm/helpers/getPreferredTechniciansForNewVisit';
import { createRecurringVisit } from '../VisitForm/helpers/mutationRecurringVisits.gql';
import useAvailableForms from '../VisitForm/helpers/useAvailableForms';
import useCrews from '../VisitForm/helpers/useCrews';
import SubmitButton from '../VisitForm/SubmitButton';

const getTitle = locale => labels.newVisit_Title[locale];

const VisitCreateModal = props => {
  const { user, visit, locale, techs, open, onComplete, handleClose, propertyAssets, job } = props;

  const visitFormData = useRef(visit);
  const validateForm = useRef();
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isMultiVisits, setMultiVisits] = useState(false);

  const [addVisit] = useMutation(addVisitToJobMutation);
  const [addRecurringVisit] = useMutation(createRecurringVisit);

  const departments = getCompanyDepartments();
  const availableForms = useAvailableForms(user);
  const preferredTechnicians = getPreferredTechniciansForNewVisit(job);
  const crews = useCrews(user);

  const crewTimeTracking = getTenantSettingValueForKey('crewTimeTracking') === 'true';
  const [{ data: companyTimezone }] = useCompanyTimezone();

  const handleFormChange = data => {
    visitFormData.current = data;
  };

  const closeModal = () => {
    setMultiVisits(false);
    handleClose();
    visitFormData.current = {};
  };

  const handleSubmit = async () => {
    if (!validateForm.current || !visitFormData.current) {
      return;
    }

    try {
      setSubmitting(true);
      setErrorMessage(undefined);
      const isValidForm = await validateForm.current();

      if (isValidForm) {
        const payload = buildVisitCreatePayload({
          jobId: job?.id,
          user,
          availableForms,
          propertyAssets,
          visitFormData: visitFormData.current,
          isMultiVisits
        });

        if (isMultiVisits) {
          await addRecurringVisit({
            variables: {
              partitionKey: user.tenantId,
              input: payload
            }
          });
        } else {
          await addVisit({
            variables: {
              partitionKey: user.tenantId,
              input: payload
            }
          });
        }

        onComplete();
        closeModal();
      }
    } catch (error) {
      setErrorMessage(`There was a problem creating this visit: ${error.message}`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <FullScreenModal
      handleClose={closeModal}
      modalHeaderButtons={<SubmitButton handleSubmit={handleSubmit} submitting={submitting} />}
      open={open}
      title={getTitle(locale)}
    >
      <Box display="flex" flexDirection="row" flexGrow={1} justifyContent="flex-end">
        <Box>
          <ThemeProvider>
            <Switch
              checked={isMultiVisits}
              horizontalLabel
              label="Create Multiple Visits"
              onChange={e => setMultiVisits(e.target.checked)}
            />
          </ThemeProvider>
        </Box>
      </Box>
      <VisitForm
        availableForms={availableForms}
        companyTimezone={companyTimezone}
        crews={crews}
        crewTimeTracking={crewTimeTracking}
        departments={departments}
        disableAssetSelection={false}
        disableSchedule={false}
        errorMessage={errorMessage}
        isMultiVisits={isMultiVisits}
        jobDepartments={job?.departments?.items}
        locale={locale}
        preferredTechnicians={
          // passing current form data to support the toggle of create mutliple visits without loosing data
          visitFormData?.current?.preferredTechnicians?.departmentId
            ? visitFormData.current.preferredTechnicians
            : preferredTechnicians
        }
        propertyAssets={propertyAssets}
        registerValidateCallback={callback => {
          validateForm.current = callback;
        }}
        techs={techs}
        tentativeDateAndTime={{}}
        visit={{
          ...visitFormData.current,
          // passing current form data to support the toggle of create mutliple visits without loosing data
          tentativeDate: visitFormData.current?.visitDate
            ? moment.unix(visitFormData.current.visitDate).format()
            : undefined,
          tentativeTime: visitFormData.current?.visitTime
        }}
        onFormChange={handleFormChange}
      />
    </FullScreenModal>
  );
};

VisitCreateModal.propTypes = {
  user: PropTypes.object.isRequired,
  visit: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
  techs: PropTypes.array.isRequired,
  propertyAssets: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default VisitCreateModal;
