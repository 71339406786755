import { Auth } from 'aws-amplify';
import axios from 'axios';

import configForEnvironment from 'configs/aws-exports';
import ENV from 'configs/env';
import { logErrorWithCallback } from 'utils';

export const generateJobReportPDFs = async ({
  visitIds,
  viewSettings,
  jobId,
  isJobCloseoutReport,
  snackbarOn
}) => {
  if (!visitIds?.length) {
    return snackbarOn('error', 'No visits selected');
  }
  try {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const baseURL = configForEnvironment(ENV).restapi;

    const options = {
      method: 'post',
      url: `${baseURL}/createJobReport/${jobId}`,
      data: { visitIds, viewSettings, isJobCloseoutReport },
      headers: {
        Accept: '*/*',
        Authorization: token,
        'Content-Type': 'application/json'
      }
    };

    await axios(options);
    return true;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to generate job report');
  }
};

export const availableJobReportPDFs = async (jobId, snackbarOn) => {
  try {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const baseURL = configForEnvironment(ENV).restapi;

    const options = {
      method: 'get',
      url: `${baseURL}/jobReport/${jobId}`,
      headers: {
        Accept: '*/*',
        Authorization: token,
        'Content-Type': 'application/json'
      }
    };

    const res = await axios(options);
    return res?.data || [];
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to fetch generated job reports');
  }
};

export const generateTechReportForVisit = async ({ visitId, snackbarOn }) => {
  try {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const baseURL = configForEnvironment(ENV).restapi;

    const options = {
      method: 'post',
      url: `${baseURL}/createTechReport/${visitId}`,
      headers: {
        Accept: '*/*',
        Authorization: token,
        'Content-Type': 'application/json'
      }
    };

    await axios(options);
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to generate Tech report');
  }
};
