import gql from 'graphql-tag';
import { sortBy } from 'lodash';
import { useSelector } from 'react-redux';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { generateCompanySortKey } from 'services/core/utils';
import { logErrorWithCallback } from 'utils';

const getLabourRateGroupsByCompany = gql`
  query getLabourRateGroupsByCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      labourRateGroups {
        items {
          id
          name
          sortOrder
          labourTypeBillingHourTypeProducts {
            items {
              id
              labourTypeId
              labourType {
                id
                name
              }
              billingHourTypeId
              billingHourType {
                id
                hourType
                hourTypeAbbreviation
              }
              productId
              product {
                id
                name
              }
              labourRateGroupId
            }
          }
          labourRateGroupEntries {
            items {
              id
              rate
              payrollHourTypeId
              payrollHourType {
                id
                hourType
                hourTypeAbbreviation
                sortOrder
              }
              labourTypeId
              labourType {
                id
                name
                jobCostTypeId
                revenueTypeId
                costCodeId
              }
              costCodeId
              costCode {
                name
                id
              }
              jobCostTypeId
              jobCostType {
                name
                id
                type
              }
            }
          }
        }
      }
    }
  }
`;

const useLabourRateGroups = (options = {}) => {
  const user = useSelector(state => state.user);

  const { data, loading, error, refetch } = useExtendedQuery(getLabourRateGroupsByCompany, {
    variables: {
      partitionKey: user.tenantId,
      sortKey: generateCompanySortKey(user.tenantId, user.tenantCompanyId)
    },
    transform: result => {
      const groups = result?.getCompany?.labourRateGroups?.items || [];
      return sortBy(groups, 'sortOrder');
    },
    onError: (err, snackbar) =>
      logErrorWithCallback(
        err,
        snackbar,
        'Unable to get labour rate groups, please try again later'
      ),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options
  });

  return [data || [], loading, error, refetch];
};

export default useLabourRateGroups;
