import React from 'react';

import SectionHeader from '../SectionHeader';

import { useStyles } from './TimeHeader.styles';

const LaborFormHeader = () => {
  const styles = useStyles();
  return (
    <>
      <SectionHeader>Crew & Time</SectionHeader>
      <div css={styles.description}>
        Select all workers with identical start and end times, project phase,
        <br />
        and other details. When entry is complete, click the button to
        <br />
        generate individual timesheets.
      </div>
    </>
  );
};

export default LaborFormHeader;
