import React, { Component, Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment';

import LinkButton from 'components/Buttons/BlueText';
import Modal from 'components/Modal';
import { getNumberValue } from 'utils';

import AppConstants from 'utils/AppConstants';

import MultiNotes from '../../../../MultiNotes';

import styles from './styles';

class CondensedNotesWithData extends Component {
  state = {
    open: false,
    titleName: '',
    data: [],
    parent: ''
  };

  handleClosePopUp = () => {
    this.setState({ open: false, data: [] });
  };

  handleOpenPopUp = (data, titleName, parent) => {
    this.setState({ open: true, data, titleName, parent });
  };

  render() {
    const { classes, fieldProps, specialbehaviour, mode } = this.props;
    const itemList = this.props.form.values[this.props.fieldProps.value];

    // TODO: filtering logic for latest note
    let displayNote = '';
    if (itemList && itemList.length > 0) {
      displayNote = itemList[0];
    }

    // TODO: always refer to object reference field name, departments are not having these defs
    const refFieldName =
      specialbehaviour && specialbehaviour.objectReferenceFieldName
        ? specialbehaviour.objectReferenceFieldName
        : 'tagName';
    // construct table
    // when values exist build rows
    // if string one text cell
    // if JSON, get tableCols and iterate
    let smValue;

    let mdValue;

    let lgValue = 12;
    if (fieldProps.behavior[mode]) {
      smValue = getNumberValue(fieldProps.behavior[mode].sm) || 12;
      mdValue = getNumberValue(fieldProps.behavior[mode].md) || 4;
      lgValue = getNumberValue(fieldProps.behavior[mode].lg) || 2;
    }
    return (
      <Grid item lg={lgValue} md={mdValue} sm={smValue} xl={lgValue} xs={smValue}>
        <Typography className={classes.label} variant="caption">
          {this.props.label}
        </Typography>

        {displayNote && (
          <Grid item xs={12}>
            <Typography className={classes.content} key="latestNote">
              {displayNote[refFieldName]}
            </Typography>
          </Grid>
        )}
        {displayNote.lastUpdatedDateTime && (
          <Grid className={classes.footer} item xs={12}>
            <Typography className={classes.label} key="footer" variant="caption">
              Last edited by {displayNote.lastUpdatedBy} on &nbsp;
              <Moment format={AppConstants.DATETIME_FORMAT} unix>
                {displayNote.lastUpdatedDateTime / 1000}
              </Moment>
            </Typography>
          </Grid>
        )}
        {specialbehaviour.moreLinks.map((noteLink, index) => (
          <Fragment key={`frag${index}`}>
            <Grid item key={`grd${index}`} xs={12}>
              <LinkButton
                handle={() =>
                  this.handleOpenPopUp(
                    (this.props.queryResult || {})[noteLink.dataPath],
                    noteLink.titleName,
                    (this.props.queryResult || {})[noteLink.parent]
                  )
                }
                key={`linkbtn${index}`}
                label={noteLink.linkName}
                style={{ paddingLeft: 0 }}
              />
            </Grid>
            <Modal
              handleClose={() => {
                this.handleClosePopUp();
                (this.props.queryResult || {})[noteLink.refetch]();
              }}
              key={`modal${index}`}
              open={this.state.open}
              width="696"
            >
              <MultiNotes
                key={`mutlinote${index}`}
                notesData={this.state.data}
                parent={this.state.parent}
                title={this.state.titleName}
              />
            </Modal>
          </Fragment>
        ))}
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CondensedNotesWithData);
