import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Moment from 'react-moment';

import AppConstants from 'utils/AppConstants';

import styles from './styles';

const TechSummary = props => {
  const { classes, summary } = props;
  return (
    <Grid alignItems="center" container direction="row" justify="space-between">
      <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
        <Grid alignItems="center" container direction="row" justify="space-between">
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <Typography className={classes.bodyContent}>{summary.summary}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.footer}>
        Last edited by {summary.lastUpdatedBy} on &nbsp;
        <Moment format={AppConstants.DATETIME_FORMAT} unix>
          {parseInt(summary.lastUpdatedDateTime, 10) / 1000}
        </Moment>
      </Typography>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(TechSummary);
