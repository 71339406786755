import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const CREATE_INVOICE_FROM_JOB_QUOTES_GQL = gql`
  mutation createInvoiceFromJobQuotes($data: CreateInvoiceFromJobQuotesInput!) {
    createInvoiceFromJobQuotes(data: $data) {
      id
    }
  }
`;

const serializer = ({ jobId, departmentId, quoteIds, percentage }) => ({
  variables: {
    data: {
      jobId,
      departmentId,
      quoteIds,
      percentage
    }
  }
});

export const useCreateInvoiceFromJobQuotes = () =>
  useExtendedMutation(CREATE_INVOICE_FROM_JOB_QUOTES_GQL, {
    serializer
  });
