/* eslint-disable react/jsx-no-duplicate-props */
import React, { useCallback } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useFormikContext } from 'formik';
import { bool, number, shape, string } from 'prop-types';

const DurationInput = React.memo(({ field, options }) => {
  const { errors, setFieldValue } = useFormikContext();

  const handleChange = useCallback(
    event => {
      // eslint-disable-next-line no-shadow
      const { value } = event.target;
      setFieldValue(field.name, value * 60);
    },
    [setFieldValue]
  );

  const error = errors && errors[field.name];

  return (
    <TextField
      disabled={options.disabled}
      error={error && error !== ''}
      helperText={error}
      inputProps={{ step: 0.25, min: 0 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Typography style={{ marginRight: '5px' }}>hr</Typography>
          </InputAdornment>
        )
      }}
      name={field.name}
      type="number"
      value={field.value ? field.value / 60 : 0}
      variant="outlined"
      onChange={handleChange}
    />
  );
});

DurationInput.propTypes = {
  field: shape({
    name: string,
    value: number
  }).isRequired,
  options: shape({
    disabled: bool,
    defaultValue: string
  }).isRequired
};

export default DurationInput;
