import gql from 'graphql-tag';
import { sortBy } from 'lodash';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { EntityType } from 'utils/constants';

import { GET_NOTES } from './useLazyQueryNotes';

const MUTATE_NOTES = gql`
  mutation mutateNotes(
    $id: String!
    $entityType: String!
    $notes: [BatchMutateNotesInput]
    $version: Int!
  ) {
    mutateNotesByParentId(id: $id, entityType: $entityType, notes: $notes, version: $version) {
      version
      notes {
        id
        subject
        note
        sortOrder
        lastUpdatedDate
        lastUpdatedBy
        hideFromTechniciansOnMobile
        displayLastUpdatedBy
        displayLastUpdatedDateTime
      }
    }
  }
`;

const GET_JOB_BY_ID = gql`
  query getJobById($partitionKey: String!, $id: String!) {
    getJobById(partitionKey: $partitionKey, id: $id) {
      id
      version
    }
  }
`;

export const isNewNote = n => n.id.startsWith('-');

const serializer = ({ newNotes, parent }) => ({
  variables: {
    id: parent?.id,
    entityType: parent?.entityType,
    version: parent?.version,
    notes: newNotes
      .filter(n => n.subject || n.note)
      .map(n => {
        return {
          id: isNewNote(n) ? undefined : n.id,
          subject: n.subject,
          note: n.note,
          hideFromTechniciansOnMobile: n.hideFromTechniciansOnMobile,
          displayLastUpdatedBy: n.displayLastUpdatedBy,
          displayLastUpdatedDateTime: n.displayLastUpdatedDateTime
        };
      })
  }
});

const useMutateNotes = (parent, partitionKey) =>
  useExtendedMutation(MUTATE_NOTES, {
    serializer,
    update: (
      cache,
      {
        data: {
          mutateNotesByParentId: { notes: newNotes, version }
        }
      }
    ) => {
      cache.writeQuery({
        query: GET_NOTES,
        variables: { id: parent?.id },
        data: {
          getNotesByParentId: sortBy(newNotes, n => n.sortOrder)
        }
      });

      switch (parent.entityType) {
        case EntityType.JOB: {
          cache.writeQuery({
            query: GET_JOB_BY_ID,
            variables: { id: parent?.id, partitionKey },
            data: {
              getJobById: {
                version
              }
            }
          });
          break;
        }
        // TO DO: Add Case for Purchase Order Entity
        default: {
          break;
        }
      }
    }
  });

export default useMutateNotes;
