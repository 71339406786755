import gql from 'graphql-tag';
import { pick } from 'lodash';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { logErrorWithCallback } from 'utils';

const BULK_UPDATE_LABOUR_TYPES = gql`
  mutation bulkUpdateLabourTypes($data: AddLabourTypesToCompanyInput!) {
    bulkUpdateLabourTypes(data: $data) {
      id
      name
      isArchived
      sortOrder
    }
  }
`;

const serializer = ({ companyId, labourTypes }) => ({
  variables: {
    data: {
      companyId,
      labourTypes: labourTypes.map(type => pick(type, ['id', 'name', 'sortOrder', 'isArchived']))
    }
  }
});

const update = (cache, { data: { bulkUpdateLabourTypes } }, ref) => {
  cache.modify({
    id: `Company:${ref.companyId}`,
    fields: {
      labourTypes() {
        return bulkUpdateLabourTypes.map(labourType => {
          return {
            __ref: `${labourType.__typename}:${labourType.id}`
          };
        });
      }
    }
  });
};

export const useBulkUpdateLabourTypes = () => {
  return useExtendedMutation(BULK_UPDATE_LABOUR_TYPES, {
    serializer,
    update,
    onSuccess: (data, snackbarOn) => snackbarOn('success', 'Successfully updated labor types'),
    onError: (err, snackbarOn) =>
      logErrorWithCallback(err, snackbarOn, 'Unable to update labor types')
  });
};
