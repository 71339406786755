export const description = {
  costCodes: {
    strongText: 'Cost Codes',
    descriptionText:
      'are labels assigned to costs and revenues as a way of tracking detail on a job.'
  },
  costTypes: {
    strongText: 'Cost Types',
    descriptionText: `are high level labels that allow us to view a roll-up of
      simplified job costing. For example “Materials”, “Subcontractors”, etc.`
  },
  costingSetting: {
    descriptionText: `Select whether detailed Job Costing is on by default or selectable on a Job-by-Job
      basis`
  },
  projectFileStorageSettings: {
    strongText: 'Project File Folders',
    descriptionText:
      'are file folders that are automatically generated when a project is created. Select who you want to have access to the files inside.'
  },
  projectAccountSettings: {
    descriptionText: 'Default product items for Project Accounting Settings'
  }
};

export const costCodeNeededFields = [
  { key: 'id' },
  { key: 'name' },
  { key: 'description' },
  { key: 'isActive' }
];

export const costTypeNeededFields = [
  { key: 'id' },
  { key: 'name' },
  { key: 'type' },
  { key: 'isActive' }
];

export const costCodeUpdateUnUsedFields = ['isEdited', '__typename'];
export const costTypeUpdateUnUsedFields = ['isEdited', '__typename', 'createdDate'];

export const projectFileDirectoryPermissions = {
  officeAccess: 'PM Files - Office Use Only',
  webAccess: 'PM Files - Web Access',
  mobileAccess: 'PM Files - Mobile Access'
};
