export const GROUP_BY_PHASE = ['projectPhaseId', 'projectCostCodeId', 'costType'];

export const GROUP_BY_PHASE_COST_TYPE = ['projectPhaseId', 'costType'];

export const GROUP_BY_COST_TYPE_AND_PHASE = ['costType', 'projectPhaseId'];

export const GROUP_BY_COST_TYPE_ONLY = ['costType'];

export const GROUP_BY_COST_CODE = ['projectCostCodeId', 'projectPhaseId', 'costType'];

export const GROUP_BY_COST_TYPE = ['costType', 'projectPhaseId', 'projectCostCodeId'];

export const ProjectFinanceTableView = {
  COST_BUDGET: 'COST_BUDGET',
  REVENUE_BUDGET: 'REVENUE_BUDGET',
  LABOR_REPORT: 'LABOR_REPORT'
};

export const ProjectFinanceEntity = {
  ProjectPhaseDepartment: 'ProjectPhaseDepartment',
  ProjectPhaseDepartmentCostCode: 'ProjectPhaseDepartmentCostCode',
  ChangeOrderLineItem: 'ChangeOrderLineItem',
  PurchaseOrderLine: 'PurchaseOrderLine',
  BillLine: 'BillLine',
  TimesheetEntry: 'TimesheetEntry'
};
