import { mappingRequired } from '../../../common/schemas/baseEntity';
import employeeSchema from '../../../crm/employee/schemas/employee';

import scheduleSchema from './schedule';
import timeSheetSchema from './timeSheet';

const visitSchema = [
  'visitNumber',
  'departmentName',
  'departmentId',
  'description',
  'prerequisites',
  'prerequisitesAcknowledged',
  'accountingRefIdOfClass',
  'status',
  'onRoute',
  'delayed',
  'delayedReason',
  'onHold',
  'onHoldReason',
  'online',
  'detailsSent',
  'extraTechsRequired',
  'extraTechsNumber',
  'minimumDuration',
  'actualDuration',
  'scheduledFor',
  'tentativePeriod',
  'tentativeDate',
  'tentativeTime',
  'startTime',
  'endTime',
  'parentId',
  'hierarchy',
  'id',
  'entityType',
  'version',
  'tenantId',
  'tenantCompanyId',
  'partitionKey',
  'parentSortKey',
  'sortKey',
  'createdBy',
  'createdDate',
  'createdDateTime',
  'deletedBy',
  'deletedDate',
  'deletedDateTime',
  'lastUpdatedBy',
  'lastUpdatedDate',
  'lastUpdatedDateTime'
];

const visitSchemaMappings = [
  {
    key: 'schedules',
    mapType: '1M',
    connection: scheduleSchema
  },
  {
    key: 'timeSheets',
    mapType: '1M',
    connection: timeSheetSchema
  },
  {
    key: 'primaryTechs',
    mapType: 'MM',
    connection: employeeSchema,
    mapping: {
      directEntityField: 'visitNumber',
      invertedEntityField: 'lastName',
      directEntity: 'PrimaryTechVisit',
      invertedEntity: 'PrimaryTech'
    }
  },
  {
    key: 'extraTechs',
    mapType: 'MM',
    connection: employeeSchema,
    mapping: {
      directEntityField: 'visitNumber',
      invertedEntityField: 'lastName',
      directEntity: 'ExtraTechVisit',
      invertedEntity: 'ExtraTech'
    }
  },
  {
    key: 'parentEntity',
    type: 'parent',
    mapType: '1M',
    required: mappingRequired
  }
];

export default visitSchema;
export { visitSchemaMappings };
