import React from 'react';

import { ThemeProvider } from '@BuildHero/sergeant';

import { JobCloseoutTypes } from '../constants';
import CostInvoiceItems from '../CostInvoiceItems';
import JobCloseoutInformation from '../InformationSection';
import NotesTable from '../NotesTable';

const TimeAndMaterialOverviewTab = ({
  jobData,
  visits,
  refetchJob,
  companyTimezone,
  isLoading,
  jobCloseoutType
}) => (
  <ThemeProvider>
    <JobCloseoutInformation isLoading={isLoading} jobData={jobData} />
    <NotesTable
      displayEntityType={jobCloseoutType === JobCloseoutTypes.MAINTENANCE ? 'Maintenance' : 'Job'}
      isLoading={isLoading}
      job={jobData}
      notes={jobData?.notes?.items || []}
      refetchJob={refetchJob}
    />
    <CostInvoiceItems
      companyTimezone={companyTimezone}
      isLoading={isLoading}
      jobData={jobData}
      refetchJob={refetchJob}
      visits={visits}
    />
  </ThemeProvider>
);

TimeAndMaterialOverviewTab.defaultProps = {
  isLoading: false
};

export default TimeAndMaterialOverviewTab;
