import { CompanyService, ReviewReportService, TimeTrackingService } from 'services/core';
import { logErrorWithCallback } from 'utils';
import removeObjProperties from 'utils/removeObjProperties';

import { formatTimesheetEntries } from './helpers';

export const updateReviewReport = async (tenantId, reviewReport, successCallback, snackbarOn) => {
  try {
    const service = new ReviewReportService();
    const { data } = await service.updateReviewReport(tenantId, reviewReport);
    const updatedInfo = data?.updateReviewReport;
    successCallback(updatedInfo);
    snackbarOn('success', 'Review Report Updated Successfully');
    return updatedInfo;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Failed to fetch pricebook information');
  }
};

export const getReviewReport = async (reviewReportId, successCallback, snackbarOn) => {
  let reviewReport = {};
  try {
    const service = new ReviewReportService();
    const { data } = await service.getReviewReportById(reviewReportId);
    reviewReport = data?.getReviewReportById;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Failed to fetch pricebook information');
  } finally {
    // @TODO - discuss - why are we using finally for this?
    successCallback(reviewReport);
  }
};

export const getTimesheetByVisit = async (visitId, successCallback, snackbarOn) => {
  try {
    if (!visitId) return;
    const service = new TimeTrackingService();
    const { data } = await service.getTimesheetByVisitId(visitId);
    const timesheetItems = data?.getVisitById?.timesheetEntries?.items;

    const timesheetEntry = formatTimesheetEntries(timesheetItems);
    successCallback(timesheetEntry);
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Failed to fetch pricebook information');
  }
};

export const saveLabourRateLineItems = async ({
  tenantId,
  reviewReportId,
  labourRateLineItems,
  successCallback,
  snackbarOn
}) => {
  try {
    const service = new ReviewReportService();
    const modifiedLineItems = labourRateLineItems.map(item =>
      removeObjProperties(item, ['subTotal', 'temporaryId'])
    );
    const payload = {
      reviewReportId,
      labourRateLineItems: modifiedLineItems
    };

    const { data } = await service.addLabourRateLineItemsToReviewReport(tenantId, payload);
    const reviewReport = data?.addLabourRateLineItemsToReviewReport || [];
    successCallback(reviewReport);
    snackbarOn('success', 'Line Item Saved Successfully');
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Failed to save line items');
  }
};

export const deleteLabourRateLineItem = async ({
  tenantId,
  lineItemId,
  successCallback,
  snackbarOn
}) => {
  try {
    const service = new ReviewReportService();
    await service.deleteLabourRateLineItem(tenantId, lineItemId);
    snackbarOn('success', 'Line Item Deleted Successfully');
    successCallback();
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Failed to save line items');
  }
};
