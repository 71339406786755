import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Button, Input, Modal, MultiSelect, Select, ThemeProvider } from '@BuildHero/sergeant';
import { flatten, keys } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { SgtAlgoliaMultiSelect } from 'components';
import FormLineItem from 'components/Tasks/components/FormLineItem';
import PartsAndMaterialsLineItem from 'components/Tasks/components/PartsAndMaterialsLineItem';
import { useLazyFetchPricebookEntry } from 'components/Tasks/components/useLazyFetchPricebookEntry';
import { bundleIndex } from 'constants/algoliaIndex';
import { snackbarOn } from 'redux/actions/globalActions';

import { getAssetsByCustomerPropertyById } from 'scenes/Customer/Assets/gql';
import { Logger } from 'services/Logger';

import { OpenTaskStatus, TaskConstants } from 'utils/AppConstants';
import { Mode } from 'utils/constants';

import useBatchMutateJobTasks from '../hooks/useBatchMutateJobTasks';

const AddEditTasksToJobModal = ({
  jobId,
  mode = Mode.ADD,
  open,
  onClose,
  propertyId,
  refetchTasks,
  tasks,
  formatAssetOption,
  partitionKey,
  task = {
    // only used in Mode.EDIT
    taskId: '',
    assetId: '',
    taskName: '',
    description: '',
    forms: [],
    partsAndMaterials: [],
    version: 1
  }
}) => {
  const [asset, setAsset] = useState({});
  const [assets, setAssets] = useState([]);
  const [taskName, setTaskName] = useState('');
  const [description, setDescription] = useState('');
  const [forms, setForms] = useState([]);
  const [partsAndMaterials, setPartsAndMaterials] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const snackbar = useCallback((...args) => dispatch(snackbarOn(...args)), [dispatch]);
  const defaultPriceBookId = useSelector(s => s.company.defaultPriceBookId);
  const fetchPricebookEntry = useLazyFetchPricebookEntry();
  const formOptions = useSelector(s =>
    (s.company?.forms?.items || [])
      .filter(f => f.associatedEntityType === 'Task')
      .map(f => ({
        id: f.id,
        label: f.name,
        value: { ...f, required: false }
      }))
  );

  const { data } = useQuery(getAssetsByCustomerPropertyById, {
    variables: { id: propertyId },
    fetchPolicy: 'no-cache'
  });

  const [batchMutateJobTasks] = useBatchMutateJobTasks({ partitionKey, jobId });

  const assetOptions = useMemo(
    () => data?.getCustomerPropertyById.propertyAssets.items.map(formatAssetOption),
    [data]
  );

  useEffect(() => {
    if (mode === Mode.EDIT && open) {
      setAsset(assetOptions?.find(({ id }) => id === task.assetId));
      setTaskName(task.taskName);
      setDescription(task.description);
      setForms(task.forms);
      setPartsAndMaterials(task.partsAndMaterials);
    }
    if (!open) {
      setAsset({});
      setAssets([]);
      setTaskName('');
      setDescription('');
      setForms([]);
      setPartsAndMaterials([]);
    }
  }, [open, mode]);

  const addTasksToJob = async () => {
    if (taskName === '') {
      snackbar('error', 'Please enter a task name');
      return;
    }

    const promises = partsAndMaterials.map(part =>
      fetchPricebookEntry({
        pricebookId: defaultPriceBookId,
        productSortKey: part.value?.productSortKey ?? part.value?.sortKey
      })
    );

    const pricebookEntries = await Promise.all(promises);

    setLoading(true);

    const tasksToAdd =
      assets.length > 0
        ? assets?.map(a => ({
            name: taskName,
            description,
            taskTypeInternal: TaskConstants.PENDING,
            assetId: a?.id,
            assetValue: a?.label,
            status: OpenTaskStatus.OPEN,
            isActive: true,
            formData: forms.map(form => ({
              formId: form.value.id,
              formDefinitionId: form.value.latestPublishedFormDefinition.id,
              formDefinitionSortKey: form.value.latestPublishedFormDefinitionSortKey,
              formSortKey: form.value.sortKey,
              isRequired: form.value.required
            })),
            taskEntries: partsAndMaterials.map((pm, j) => ({
              productId: pm.value.productId,
              priceBookEntryId: pricebookEntries[j]?.id,
              name: pm.value.name,
              sortOrder: j,
              description: pm.value.description,
              quantity: pm.value.quantity
            }))
          }))
        : [
            {
              name: taskName,
              description,
              taskTypeInternal: TaskConstants.PENDING,
              assetId: undefined,
              assetValue: undefined,
              status: OpenTaskStatus.OPEN,
              isActive: true,
              formData: forms.map(form => ({
                formId: form.value.id,
                formDefinitionId: form.value.latestPublishedFormDefinition.id,
                formDefinitionSortKey: form.value.latestPublishedFormDefinitionSortKey,
                formSortKey: form.value.sortKey,
                isRequired: form.value.required
              })),
              taskEntries: partsAndMaterials.map((pm, j) => ({
                productId: pm.value.productId,
                priceBookEntryId: pricebookEntries[j]?.id,
                name: pm.value.name,
                sortOrder: j,
                description: pm.value.description,
                quantity: pm.value.quantity
              }))
            }
          ];

    const params = {
      data: {
        jobId,
        tasks: [
          ...tasks.map((t, i) => {
            return {
              id: t?.id,
              name: t.name,
              sortOrder: i,
              assetId: t?.assetId ?? undefined,
              status: t.status,
              formData:
                t.formData?.items?.map(({ form, isRequired, id }) => {
                  return {
                    isRequired,
                    id,
                    formId: form.id,
                    formDefinitionId: form.latestPublishedFormDefinition.id,
                    formSortKey: form.formSortKey,
                    formDefinitionSortKey: form.latestPublishedFormDefinitionSortKey
                  };
                }) ?? [],
              taskEntries:
                t.taskEntries?.items?.map((entry, ei) => ({
                  id: entry.id,
                  name: entry.name,
                  description: entry.description,
                  quantity: entry.quantity,
                  productId: entry.productId,
                  priceBookEntryId: entry.pricebookEntryId,
                  sortOrder: ei
                })) ?? []
            };
          }),
          ...tasksToAdd
        ]
      }
    };

    try {
      await batchMutateJobTasks(params);
      refetchTasks();
    } catch (e) {
      snackbar('error', e.message || 'Failed to add tasks, please try again later', e);
      Logger.error(e);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const editTask = async () => {
    if (taskName === '') {
      snackbar('error', 'Please enter a task name');
      return;
    }

    setLoading(true);

    const promises = partsAndMaterials.map(part =>
      fetchPricebookEntry({
        pricebookId: defaultPriceBookId,
        productSortKey: part.value?.productSortKey ?? part.value?.sortKey
      })
    );

    const pricebookEntries = await Promise.all(promises);

    if (!tasks) return;

    const newTaskArray = tasks?.reduce((acc, t, i) => {
      if (t.id === task.taskId) {
        return [
          ...acc,
          {
            id: task.taskId,
            assetId: asset?.id || null,
            name: taskName,
            description,
            sortOrder: i,
            formData: forms.map(form => ({
              id: form.value.updateId,
              formId: form.value.id,
              formDefinitionId: form.value.latestPublishedFormDefinition.id,
              formDefinitionSortKey: form.value.latestPublishedFormDefinitionSortKey,
              formSortKey: form.value.sortKey,
              isRequired: form.value.required
            })),
            taskEntries: partsAndMaterials.map((pm, j) => ({
              id: pm.value.updateId,
              productId: pm.value.productId,
              priceBookEntryId: pm.value.priceBookEntryId,
              name: pm.value.name,
              sortOrder: j,
              description: pm.value.description,
              quantity: pm.value.quantity
            }))
          }
        ];
      }
      return [
        ...acc,
        {
          id: t?.id,
          name: t.name,
          sortOrder: i,
          assetId: t.assetId,
          status: t.status,
          formData:
            t.formData?.items?.map(form => ({
              isRequired: form.isRequired,
              formId: form.formId,
              formDefinitionId: form.formDefinitionId,
              formSortKey: form.formSortKey,
              formDefinitionSortKey: form.formDefinitionSortKey,
              id: form.id
            })) ?? [],
          taskEntries:
            t.taskEntries?.items?.map((entry, ei) => ({
              id: entry.id,
              name: entry.product?.name,
              description: entry.description,
              quantity: entry.quantity,
              productId: entry.productId,
              priceBookEntryId: pricebookEntries[ei]?.id,
              sortOrder: ei
            })) ?? []
        }
      ];
    }, []);

    const params = {
      data: {
        jobId,
        tasks: newTaskArray
      }
    };

    try {
      await batchMutateJobTasks(params);
      refetchTasks();
    } catch (e) {
      snackbar('error', e.message || 'Failed to edit task, please try again later', e);
      Logger.error(e);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <ThemeProvider>
      <Modal
        actions={
          <Button
            fullWidth
            loading={loading}
            onClick={mode === Mode.ADD ? addTasksToJob : editTask}
          >
            {mode === Mode.ADD ? 'Add Task(s)' : 'Save'}
          </Button>
        }
        open={open}
        PaperProps={{ style: { minWidth: 800, overflowY: 'unset' } }}
        title={mode === Mode.ADD ? 'Add Task(s) to Job' : 'Edit Task'}
        onClose={() => {
          setAsset({});
          setAssets([]);
          setTaskName('');
          setDescription('');
          setForms([]);
          setPartsAndMaterials([]);
          onClose();
          setLoading(false);
        }}
      >
        {mode === Mode.ADD ? (
          <MultiSelect
            grouped
            label="Assets"
            options={assetOptions}
            placeholder="Search & Select Assets"
            selectedOptions={assets}
            showChips
            showSearchIcon
            onChange={setAssets}
          />
        ) : (
          <Select
            clearable
            defaultValue={asset}
            label="Asset"
            options={assetOptions}
            placeholder="Search & Select Asset"
            searchable
            onChange={setAsset}
          />
        )}
        <div style={{ height: 16 }} />
        <Input
          defaultValue={taskName}
          label="Task"
          multiline
          placeholder="Enter Task"
          required
          onBlur={e => setTaskName(e.target.value)}
        />
        <div style={{ height: 16 }} />
        <Input
          defaultValue={description}
          label="Task Description"
          multiline
          placeholder="Enter Description"
          onBlur={e => setDescription(e.target.value)}
        />
        <div style={{ height: 16 }} />
        <MultiSelect
          label="Forms"
          lineItemComponent={(option, selectedOptions) => (
            <FormLineItem
              formsChange={setForms}
              option={option}
              selectedOptions={selectedOptions}
            />
          )}
          options={formOptions}
          placeholder="Search & Select Forms"
          selectedOptions={forms}
          showChips
          showSearchIcon
          onChange={setForms}
        />
        <div style={{ height: 16 }} />
        <SgtAlgoliaMultiSelect
          options={{
            label: 'Parts & Materials',
            lineItemComponent: (option, selectedOptions) => (
              <PartsAndMaterialsLineItem
                option={option}
                partsAndMaterialsChange={setPartsAndMaterials}
                selectedOptions={selectedOptions}
              />
            ),
            onChange: setPartsAndMaterials,
            options: [],
            placeholder: 'Search & Select Parts & Materials',
            showChips: true,
            showSearchIcon: true,
            selectedOptions: partsAndMaterials,
            restructureAlgoliaHitToOptions: hit => {
              return [
                {
                  id: hit.id,
                  label: hit.name,
                  rightLabel: hit.description,
                  value: {
                    productId: hit.id,
                    productSortKey: hit.sortKey,
                    name: hit.name,
                    code: hit.code,
                    description: hit.description,
                    quantity: 1
                  }
                }
              ];
            },
            algoliaFilter: `entityType:Product`,
            algoliaIndex: bundleIndex
          }}
        />
      </Modal>
    </ThemeProvider>
  );
};

export default AddEditTasksToJobModal;
