import React from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import AppConstants from 'utils/AppConstants';

const styles = makeStyles(theme => ({
  boldBody2Text: {
    fontWeight: 700
  },
  visitColumn: {
    width: '5%',
    marginRight: 10
  },
  visitDetailsColumn: {
    width: '90%'
  },
  assetPadding: {
    paddingBottom: 8
  },
  visitSummarySpacing: {
    paddingBottom: 48
  }
}));

const VisitSummary = ({ visit }) => {
  const classes = styles();
  const techNames = [
    `${visit?.primaryTechs?.[0]?.firstName} ${visit?.primaryTechs?.[0]?.lastName}`
  ];
  if (visit?.extraTechs)
    visit.extraTechs.forEach(tech => techNames.push(`${tech.firstName} ${tech.lastName}`));
  return (
    <>
      <Grid container>
        <Grid className={classes.visitColumn} item>
          <Typography variant="overline">Visit</Typography>
          <Typography className={classes.boldBody2Text} variant="body2">
            {visit.visitNumber}
          </Typography>
        </Grid>
        <Grid className={classes.visitDetailsColumn} item>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="overline">DATE PERFORMED</Typography>
              <Typography variant="body2">
                {moment.unix(visit.scheduledFor).format(AppConstants.DATE_FORMAT)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="overline">TECHNICIAN(S)</Typography>
              <Typography variant="body2">{techNames.join(', ')}</Typography>
            </Grid>
          </Grid>
          {visit?.visitAssets?.items?.map(vAsset => (
            <Grid className={classes.assetPadding} item>
              <Typography variant="overline">ASSET</Typography>
              <Typography className={classes.boldBody2Text} variant="body2">
                {vAsset?.propertyAsset?.assetName}
              </Typography>
              <Typography variant="body2">Make: {vAsset?.propertyAsset?.make || '-'}</Typography>
              <Typography variant="body2">
                Model Number: {vAsset?.propertyAsset?.modalNumber || '-'}
              </Typography>
              <Typography variant="body2">
                Serial Number: {vAsset?.propertyAsset?.serialNumber || '-'}
              </Typography>
            </Grid>
          ))}
          {visit?.summaries?.items?.map(summaryObj => (
            <Grid className={classes.assetPadding} item>
              <Typography variant="overline">WORK PERFORMED</Typography>
              <Typography variant="body2">{summaryObj.summary}</Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

const JobSummary = ({ visits }) => {
  const classes = styles();
  return visits?.map(visit => (
    <Box className={classes.visitSummarySpacing}>
      <VisitSummary visit={visit} />
    </Box>
  ));
};

export default JobSummary;
