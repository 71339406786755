import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';

async function getLogo(logoUrl) {
  try {
    const storageService = new StorageService();
    const url = await storageService.getFile(logoUrl);
    return `<figure class="image"><img src=${url}></figure>`;
  } catch (error) {
    Logger.info(`Error fetching image ${error}`);
    return '';
  }
}

export default getLogo;
