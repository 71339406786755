import React, { Component } from 'react';

import { Label, ThemeProvider, Typography } from '@BuildHero/sergeant';
import { Box, withStyles } from '@material-ui/core';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';

import { PageHeader, Spinner, Tab, Tabs, UserPermission } from 'components';
import Context from 'components/Context';
import AlgoliaSearch from 'components/SgtAlgoliaSearch/AlgoliaSearch';
import { bundleIndex } from 'constants/algoliaIndex';

import { snackbarOn, updateSetting } from 'redux/actions/globalActions';
import { dispatch } from 'redux/store';
import ErrorBoundaries from 'scenes/Error';
import { CompanyService, PricebookService, QuickbooksService } from 'services/core';
import TenantSettingService from 'services/core/graphql-services/TenantSetting/TenantSettingService';
import { Logger } from 'services/Logger';

import { PermissionConstants } from 'utils/AppConstants';

import Bundle from './Bundle';
import ProductsList from './ProductsList';
import styles from './styles';

class Products extends Component {
  constructor(props) {
    super(props);
    document.title = 'BuildOps - Inventory';
    this.PricebookService = new PricebookService();
    this.QuickbooksService = new QuickbooksService();
    this.CompanyService = new CompanyService();
    this.mounted = props.mounted || true;
    this.state = {
      openAddItem: false,
      accounts: [],
      vendors: [],
      mode: 'new',
      record: '',
      updatedData: '',
      isQuickBooksEnabled: false,
      openAddProductsToBundle: false,
      spinner: true,
      productBundle: '',
      selectedItem: null,
      defaultLineItem: null
    };
  }

  handleContextRefresh = () => Logger.debug('Context is refreshed');

  refreshCompanyContext = () => {
    Context.setCompanyContext(
      this.props.user.tenantId,
      Context.generateCompanyContextSortKey(this.props.user),
      this.handleContextRefresh,
      true
    );
  };

  componentDidMount = async () => {
    await this.queryAndSetTenantSettings();
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleDefaultLineItemChange = async item => {
    const { defaultLineItem } = this.state;
    const updateTenantSettingResponse = await new TenantSettingService().mutateDefaultLineItem({
      data: {
        id: defaultLineItem?.id,
        settingValue: JSON.stringify({
          productId: item.id,
          productName: item.name
        }),
        tenantId: this.props.user.tenantId,
        tenantCompanyId: this.props.user.tenantCompanyId
      }
    });
    this.setState(
      {
        defaultLineItem: updateTenantSettingResponse,
        selectedItem: item
      },
      () => {
        dispatch(updateSetting(updateTenantSettingResponse));
        this.refreshCompanyContext();
      }
    );
  };

  queryAndSetTenantSettings = async () => {
    this.refreshCompanyContext();
    let isQuickBooksEnabled = false;
    let departments = [];
    let defaultLineItem = null;
    let selectedItem = null;
    try {
      let listTenantSettings = null;
      let quickbooksSetting = null;
      if (Context.getCompanyContext() && Context.getCompanyContext().getCompany) {
        ({ listTenantSettings } = await Context.getCompanyContext());
        quickbooksSetting =
          listTenantSettings &&
          listTenantSettings.filter(
            setting => setting.settingKey === 'accountingApp' && setting.settingValue !== ''
          );

        isQuickBooksEnabled =
          quickbooksSetting &&
          quickbooksSetting.length > 0 &&
          quickbooksSetting[0].settingValue === 'quickbooks';

        departments = Context.getCompanyContext().getCompany?.departments?.items;
        defaultLineItem = listTenantSettings?.find(
          setting => setting.settingKey === 'defaultLineItem'
        );
        const parsedItem =
          defaultLineItem?.settingValue && JSON.parse(defaultLineItem?.settingValue);
        selectedItem = {
          id: parsedItem?.productId,
          name: parsedItem?.productName
        };
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable fetch settings');
    }

    if (this.mounted) {
      this.setState(prevState => ({
        ...prevState,
        isQuickBooksEnabled,
        spinner: false,
        departments,
        defaultLineItem,
        selectedItem
      }));
    }
  };

  render() {
    const { user, flags: launchDarklyFlags } = this.props;
    const { isQuickBooksEnabled, spinner, departments, selectedItem } = this.state;

    if (spinner) {
      return <Spinner />;
    }

    return (
      <ErrorBoundaries>
        <UserPermission action={PermissionConstants.MENU_INVENTORY} I="allow">
          <PageHeader pageMapKey="inventorySettings" userLocale={user.locale} />
          <Tabs>
            <Tab disableBottomPadding key="inventory" label="Items">
              <ProductsList
                departments={departments}
                flags={launchDarklyFlags}
                isQuickBooksEnabled={isQuickBooksEnabled}
              />
            </Tab>
            {launchDarklyFlags?.truckStockInventory ? (
              <Tab disableBottomPadding key="bundle" label="Bundle">
                <Bundle />
              </Tab>
            ) : null}
            <Tab disableBottomPadding key="defaultLineItem" label="Settings">
              <Typography className={this.props.classes.greenHeading} variant="body2">
                Default Invoice Item
              </Typography>
              <Box width={450}>
                <ThemeProvider>
                  <Label label="Default Invoice Item For Manually Entered Inventory And Purchased Items" />
                  <AlgoliaSearch
                    disableClear
                    filters="entityType:Product"
                    formatHitLabel={h =>
                      `${h.name || ''}${h.description ? `, ${h.description}` : ''}`
                    }
                    indexName={bundleIndex}
                    name="defaultProductSetting"
                    placeholder="Search and select item"
                    value={selectedItem?.name}
                    onChange={this.handleDefaultLineItemChange}
                  />
                </ThemeProvider>
              </Box>
            </Tab>
          </Tabs>
        </UserPermission>
      </ErrorBoundaries>
    );
  }
}

const styledProducts = withStyles(styles)(Products);

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedProducts = connect(mapStateToProps, mapDispatcherToProps)(styledProducts);

export default withLDConsumer()(reduxConnectedProducts);
