import React from 'react';

import { Button, ButtonType, TV, TW, Typography } from '@BuildHero/sergeant';

import { Box } from '@material-ui/core';

import { Mode } from 'utils/constants';

export const ChecklistPlaceholder = ({
  theme,
  setChecklistModal,
  currentAssetType,
  overrideAddChecklistLabel
}) => (
  <div
    css={{
      border: `dashed 2px ${theme.palette.grayscale(50)}`,
      minHeight: 500,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <Box margin="0 auto" textAlign="center" width={400}>
      <Typography css={{ paddingBottom: 8 }} variant={TV.XL} weight={TW.BOLD}>
        No Checklists
      </Typography>
      {!overrideAddChecklistLabel && (
        <Typography
          css={{ color: theme.palette.grayscale(50), paddingBottom: 8 }}
          variant={TV.BASE}
          weight={TW.REGULAR}
        >
          Set them up once, use them forever. Checklists help create Service Agreements more
          efficiently.
        </Typography>
      )}
      <Button
        css={{ paddingBottom: 8 }}
        type={ButtonType.SECONDARY}
        onClick={() =>
          setChecklistModal({
            open: true,
            mode: Mode.ADD,
            data: { assetTypeId: currentAssetType.id }
          })
        }
      >
        {overrideAddChecklistLabel || 'Add Checklist'}
      </Button>
    </Box>
  </div>
);
