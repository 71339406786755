import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useTheme,
  withStyles
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';

import AddRecordButton from 'components/Buttons/BlueText';
import Spinner from 'components/Spinners/CircularIndeterminate';
import ErrorBoundaries from 'scenes/Error';
import { withBuildHeroTheme } from 'themes/BuildHeroTheme';
import compose from 'utils/compose';

import styles from './styles';

const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: 20,
    height: 56,
    borderRadius: '4px 4px 0px 0px',
    boxShadow: '0px 2px 7px 0px rgba(0, 0, 0, 0.06)'
  },
  titleStyle: {
    fontSize: 16,
    color: theme.palette.other.luckyPoint
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.brand.logoBlue
  }
});

const DialogTitleComp = withStyles(titleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle className={classes.root} disableTypography {...other}>
      <Typography className={classes.titleStyle}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon style={{ fontSize: 18 }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

function SimpleModal(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  let open = false;
  const {
    maxWidth = 'lg',
    width,
    buttons,
    classes,
    hideCloseButton,
    disableBackdropClick,
    style,
    showModalHeader,
    modalTitle,
    spinnerStatus
  } = props;
  open = !!props.open;
  return (
    <Dialog
      aria-describedby="simple-modal-description"
      aria-labelledby="simple-modal-title"
      disableBackdropClick={disableBackdropClick || false}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      open={open}
      PaperProps={{ style }}
      scroll="paper"
      onClose={props.handleClose}
    >
      <div className={classes.sizingContainer}>
        {showModalHeader && (
          <DialogTitleComp onClose={props.handleClose}>{modalTitle}</DialogTitleComp>
        )}
        <DialogContent className={classes.dialogContent} style={{ maxWidth: `${width}px` }}>
          <ErrorBoundaries>
            {!hideCloseButton && !showModalHeader && (
              <Grid alignItems="flex-start" container direction="row" justify="flex-end">
                <Grid item>
                  <AddRecordButton handle={props.handleClose} label="CLOSE" />
                </Grid>
              </Grid>
            )}

            <Grid className={classes.dialogContentGrid} container direction="row">
              <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                {props.children}
              </Grid>
            </Grid>
            {buttons && (
              <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                <DialogActions className={classes.dialogActions}>
                  {buttons &&
                    Object.keys(buttons).map(key => (
                      <Button
                        className={props.classes.pageActionButton}
                        color={buttons[key].color}
                        disabled={buttons[key].disabled || spinnerStatus || false}
                        fullWidth={buttons[key].fullWidth}
                        key={key}
                        style={buttons[key].style}
                        variant={buttons[key].buttonType}
                        onClick={async () => buttons[key].action()}
                      >
                        {buttons[key].label}
                        {spinnerStatus && key === 'save' && (
                          <Spinner className={props.classes.buttonProgress} size={24} />
                        )}
                      </Button>
                    ))}
                </DialogActions>
              </Grid>
            )}
          </ErrorBoundaries>
        </DialogContent>
      </div>
    </Dialog>
  );
}

// We need an intermediary variable for handling the recursive nesting.
const SimpleModalWrapped = compose(
  withBuildHeroTheme,
  withStyles(styles, { withTheme: true })
)(SimpleModal);

export default SimpleModalWrapped;
