import React, { Component } from 'react';

import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Photo from '@material-ui/icons/Photo';
import _ from 'lodash';

import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';
import { getFileExtension, isCloudinaryVideoType } from 'utils';

import ImageFullScreenDisplay from '../ImageFullScreenDisplay';

/**
 * Renders a Thumbnail Image in the screen
 */
class ImageThumbnail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s3ImageUrl: '',
      imageUrlOriginal: '',
      fullScreenActive: false,
      images: this.props.allImages || []
    };
  }

  componentWillMount = async () => {
    await this.getImage();
  };

  componentDidUpdate = async prevProps => {
    if (this.props.image.fileUrl !== prevProps.image.fileUrl) {
      await this.getImage();
    }
  };

  getImage = async () => {
    const imageKey = this.props.image.fileUrl;
    const { images } = this.state;
    let s3ImageUrl = null;
    try {
      if (imageKey) {
        const storageService = new StorageService();
        s3ImageUrl = await storageService.getFile(imageKey, true);
      }
    } catch (error) {
      // avoid sending to Sentry as not all images will have thumbnails
      Logger.info(`Error uploading image ${error}`);
    }
    let addImages = [...images, { fileUrl: imageKey }];
    addImages = _.uniqBy(addImages, 'fileUrl');
    this.setState({ s3ImageUrl, images: addImages });
  };

  getOriginalImage = async () => {
    const imageKey = this.props.image.fileUrl;
    let imageUrlOriginal;

    try {
      const storageService = new StorageService();
      imageUrlOriginal = await storageService.getFile(imageKey);
      this.setState({ imageUrlOriginal }, () => {
        if (this.state.imageUrlOriginal) {
          window.open(imageUrlOriginal);
        }
      });
    } catch (error) {
      Logger.info(`Error uploading image ${error}`);
    }
  };

  setFullScreenParams = () => {
    this.setState({ fullScreenActive: true });
  };

  toggleView = () => {
    this.setState(prevState => ({
      fullScreenActive: !prevState.fullScreenActive
    }));
  };

  render() {
    const { image, location } = this.props;
    const { customSvgIconWidth } = this.props;
    const { s3ImageUrl, images, fullScreenActive } = this.state;
    const height = location && location === 'Gallery' ? 114 : 60;
    const width = location && location === 'Gallery' ? 114 : 60;
    const fileExt = getFileExtension(s3ImageUrl);
    const isVideo = isCloudinaryVideoType(fileExt);
    const mediaType = isVideo ? 'video' : 'img';

    return (
      <>
        {s3ImageUrl && (
          <CardMedia
            alt={image.name}
            component={mediaType}
            src={s3ImageUrl}
            style={{ height, width, objectFit: 'contain' }}
            title={image.name}
            onClick={() => {
              if (!location) this.setFullScreenParams();
            }}
          />
        )}
        {s3ImageUrl === null && (
          <IconButton aria-label="Photo/Video" style={{ padding: 0 }}>
            <Photo style={{ fontSize: customSvgIconWidth || width }} />
          </IconButton>
        )}
        {fullScreenActive ? (
          <ImageFullScreenDisplay images={images} index={0} toggle={this.toggleView} />
        ) : null}
      </>
    );
  }
}

export default ImageThumbnail;
