import { FieldType, TV, TW } from '@BuildHero/sergeant';

import { snackbarOn } from 'redux/actions/globalActions';
import { dispatch } from 'redux/store';
import {
  AccountingApp,
  EnvelopeType,
  InvoiceItemSourceType,
  InvoiceItemType
} from 'utils/constants';

import { isReadonly } from '../InvoiceDetail.utils';

import { footer, getFields, getHeader, getTotals, sumTransformFn } from './sharedConfigs';

const hasTrue = obj => Object.values(obj).some(Boolean);

/**
 * Generate the config for invoice (default, pdf modes)
 * @param data Invoice data
 * @param handleInvoiceItemChange Function to handle invoice item mutations
 * @param editRender If true, will ignore invoice settings
 */
export default (
  {
    invoiceNumber,
    sender,
    sageJobOptions,
    paymentTermOptions,
    taxRateOptions,
    status,
    accountingApp,
    settings,
    parentLink,
    summary,
    laborItems,
    partsAndMaterials,
    discountsAndFees,
    payments,
    termsOfService,
    envelopeType,
    adjustmentsFlag,
    customerSignatures,
    syncStatus
  },
  handleInvoiceItemChange,
  isEditable = false
) => {
  const isSageIntegrated = accountingApp === AccountingApp.SAGE;
  const isVistaIntegrated = accountingApp === AccountingApp.VISTA;
  const isSpectrumIntegrated = accountingApp === AccountingApp.SPECTRUM;
  const readonly = isReadonly({
    userCanUpdateInvoice: isEditable,
    status,
    isSageIntegrated,
    isVistaIntegrated,
    isSpectrumIntegrated,
    syncStatus
  });

  const showParent = !!parentLink;
  const showSummary = isEditable || !!summary;
  const showTermsOfService = isEditable || !!termsOfService;

  const showLabor = isEditable || (laborItems.length > 0 && hasTrue(settings.labor));
  const showLaborTitleSum = settings.labor.total && !settings.labor.subtotal;

  const showPM =
    isEditable || (partsAndMaterials.length > 0 && hasTrue(settings.partsAndMaterials));
  const showPMTitleSum = settings.partsAndMaterials.total && !settings.partsAndMaterials.subtotal;

  const showDF = isEditable || (discountsAndFees.length > 0 && hasTrue(settings.discountsAndFees));
  const showDFTitleSum = settings.discountsAndFees.total && !settings.discountsAndFees.subtotal;

  const showPayments = isEditable || (payments.length > 0 && hasTrue(settings.payments));
  const showPaymentsTitleSum = settings.payments.total && !settings.payments.amount;

  const showCustomerSignatures = customerSignatures.length > 0;

  const fields = getFields({ readonly, isSageIntegrated, sageJobOptions, paymentTermOptions });
  const isDoublePane = envelopeType === EnvelopeType.DOUBLE;

  return {
    layoutsList: ['pdf', 'default'],
    layouts: {
      default: {
        props: {
          pdf: {
            title: `Invoice ${invoiceNumber}`,
            footer: footer(
              ({ pageNumber, totalPages }) =>
                `Invoice ${invoiceNumber} - Page ${pageNumber} of ${totalPages}`
            )
          },
          default: {
            width: 816,
            padding: '56px 40px',
            border: '1px solid #999999'
          }
        },
        contents: [
          ...getHeader({ readonly, showParent, sender, envelopeType }),
          {
            props: {
              style: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                default: {
                  marginBottom: 17
                },
                pdf: {
                  marginBottom: 12
                }
              }
            },
            contents: [
              fields.customerName({ marginRight: 16, flex: 1 }),
              fields.customerProperty({ marginRight: 16, flex: 1 }),
              fields.propertyAddress({ marginRight: 16, flex: 1 }),
              fields.dueDate({ marginRight: isSageIntegrated ? 16 : 0, flex: 1 }),
              fields.sageJob({ flex: 1 })
            ]
          },
          {
            props: {
              style: {
                flexDirection: 'row',
                justifyContent: 'space-between'
              }
            },
            contents: [
              fields.authorizedBy({ marginRight: 16, flex: 1 }),
              fields.customerPO({ marginRight: 16, flex: 1 }),
              fields.customerWO({ marginRight: 16, flex: 1 }),
              fields.nte({ marginRight: 16, flex: 1 }),
              fields.terms({ flex: 1 })
            ]
          },
          {
            component: 'Divider'
          },
          showSummary && {
            component: 'Typography',
            props: {
              weight: TW.BOLD,
              value: 'Invoice Summary',
              default: {
                variant: TV.BASE,
                style: { marginBottom: 8 }
              },
              pdf: {
                variant: TV.S2,
                style: { marginBottom: 4 }
              }
            }
          },
          showSummary && {
            component: { default: readonly ? 'Typography' : 'Input', pdf: 'Typography' },
            source: 'summary',
            props: {
              inputProps: {
                testingid: 'invoiceSummary'
              },
              default: {
                multiline: true,
                rows: 8,
                rowsMax: 30
              }
            }
          },
          showSummary && {
            component: 'Divider'
          },
          showLabor && {
            component: 'Table',
            source: 'laborItems',
            props: {
              title: 'Labor',
              titleSumColumn: showLaborTitleSum ? { id: 'amount', type: FieldType.CURRENCY } : null,
              ...(!readonly && {
                onCreate: (sortOrder, callback) =>
                  handleInvoiceItemChange(InvoiceItemType.LABOR_LINE_ITEM, callback, {
                    sortOrder
                  }),
                onUpdate: (data, callback) =>
                  handleInvoiceItemChange(InvoiceItemType.LABOR_LINE_ITEM, callback, data),
                onDelete: (data, callback) => {
                  if (data.source === InvoiceItemSourceType.QUOTE) {
                    dispatch(snackbarOn('error', 'Labor line item from Quote cannot be removed.'));
                  } else {
                    handleInvoiceItemChange(InvoiceItemType.LABOR_LINE_ITEM, callback, data, true);
                  }
                }
              }),
              errorCondition: row =>
                isVistaIntegrated && !row.jcContractItem?.value && 'Missing JC Contract Item',
              columns: [
                settings.labor.name && {
                  id: 'name',
                  label: 'Labor Name',
                  width: `${116 / 5.64}%`,
                  weight: TW.BOLD
                },
                settings.labor.description && {
                  id: 'description',
                  label: 'Description',
                  width: `${192 / 5.64}%`
                },
                settings.labor.taxable && {
                  id: 'taxable',
                  label: 'Taxable',
                  type: FieldType.BOOL,
                  width: `${36 / 5.64}%`
                },
                settings.labor.hours && {
                  id: 'quantity',
                  label: 'Hours',
                  type: FieldType.NUMBER,
                  width: `${60 / 5.64}%`,
                  numeric: true,
                  sum: settings.labor.total,
                  style: { marginLeft: 'auto' }
                },
                settings.labor.rate && {
                  id: 'unitPrice',
                  label: 'Rate',
                  type: FieldType.CURRENCY,
                  width: `${80 / 5.64}%`,
                  style: settings.labor.hours || { marginLeft: 'auto' }
                },
                settings.labor.subtotal && {
                  id: 'amount',
                  label: 'Price Subtotal',
                  type: FieldType.CURRENCY,
                  width: `${80 / 5.64}%`,
                  sum: settings.labor.total,
                  style: settings.labor.hours || settings.labor.rate || { marginLeft: 'auto' }
                }
              ]
            }
          },
          showLabor && {
            component: 'Divider'
          },
          showPM && {
            component: 'Table',
            source: 'partsAndMaterials',
            props: {
              title: 'Parts & Materials',
              titleSumColumn: showPMTitleSum ? { id: 'amount', type: FieldType.CURRENCY } : null,
              ...(!readonly && {
                onCreate: (sortOrder, callback) =>
                  handleInvoiceItemChange(InvoiceItemType.INVENTORY_PART, callback, {
                    sortOrder
                  }),
                onUpdate: (data, callback) =>
                  handleInvoiceItemChange(data.lineItemType, callback, data),
                onDelete: (data, callback) => {
                  if (data.source === InvoiceItemSourceType.QUOTE) {
                    dispatch(
                      snackbarOn(
                        'error',
                        'Parts & Material line item from Quote cannot be removed.'
                      )
                    );
                  } else {
                    handleInvoiceItemChange(InvoiceItemType.INVENTORY_PART, callback, data, true);
                  }
                }
              }),
              errorCondition: row =>
                isVistaIntegrated && !row.jcContractItem?.value && 'Missing JC Contract Item',
              columns: [
                settings.partsAndMaterials.name && {
                  id: 'name',
                  label: 'Item Name',
                  width: `${116 / 5.64}%`,
                  weight: TW.BOLD
                },
                settings.partsAndMaterials.code && {
                  id: 'product.code',
                  label: 'Product Code',
                  width: `${64 / 5.64}%`
                },
                settings.partsAndMaterials.description && {
                  id: 'description',
                  label: 'Description',
                  width: `${132 / 5.64}%`
                },
                settings.partsAndMaterials.taxable && {
                  id: 'taxable',
                  label: 'Taxable',
                  type: FieldType.BOOL,
                  width: `${36 / 5.64}%`
                },
                settings.partsAndMaterials.quantity && {
                  id: 'quantity',
                  label: 'Quantity',
                  type: FieldType.NUMBER,
                  width: `${56 / 5.64}%`,
                  style: { marginLeft: 'auto' },
                  numeric: true,
                  sum: settings.partsAndMaterials.total
                },
                settings.partsAndMaterials.unitPrice && {
                  id: 'unitPrice',
                  label: 'Unit Price',
                  type: FieldType.CURRENCY,
                  width: `${80 / 5.64}%`,
                  style: settings.partsAndMaterials.quantity || { marginLeft: 'auto' }
                },
                settings.partsAndMaterials.subtotal && {
                  id: 'amount',
                  label: 'Price Subtotal',
                  type: FieldType.CURRENCY,
                  width: `${80 / 5.64}%`,
                  style: settings.partsAndMaterials.quantity ||
                    settings.partsAndMaterials.unitPrice || { marginLeft: 'auto' },
                  sum: settings.partsAndMaterials.total
                }
              ].filter(Boolean)
            }
          },
          showPM && {
            component: 'Divider'
          },
          showDF && {
            component: 'Table',
            source: 'discountsAndFees',
            props: {
              title: 'Discounts & Fees',
              titleSumColumn: showDFTitleSum
                ? { id: 'amount', type: FieldType.CURRENCY, sumTransformFn }
                : null,
              // onMove: () => {},
              ...(!readonly && {
                onCreate: (sortOrder, callback) =>
                  handleInvoiceItemChange(InvoiceItemType.FEE, callback, { sortOrder }),
                onUpdate: (data, callback) =>
                  handleInvoiceItemChange(data.lineItemType, callback, data),
                onDelete: (data, callback) => {
                  if (data.source === InvoiceItemSourceType.QUOTE) {
                    dispatch(
                      snackbarOn('error', 'Discount & Fee line item from Quote cannot be removed.')
                    );
                  } else {
                    handleInvoiceItemChange(InvoiceItemType.DISCOUNT, callback, data, true);
                  }
                }
              }),
              errorCondition: row =>
                isVistaIntegrated && !row.jcContractItem?.value && 'Missing JC Contract Item',
              columns: [
                settings.discountsAndFees.name && {
                  id: 'name',
                  label: 'Item Name',
                  width: `${116 / 5.64}%`,
                  weight: TW.BOLD
                },
                settings.discountsAndFees.description && {
                  id: 'description',
                  label: 'Description',
                  width: `${144 / 5.64}%`
                },
                settings.discountsAndFees.type && {
                  id: 'lineItemType',
                  label: 'Type',
                  width: `${48 / 5.64}%`
                },
                settings.discountsAndFees.taxable && {
                  id: 'taxable',
                  label: 'Taxable',
                  type: FieldType.BOOL,
                  width: `${36 / 5.64}%`
                },
                settings.discountsAndFees.quantity && {
                  id: 'quantity',
                  label: 'Quantity',
                  type: FieldType.NUMBER,
                  width: `${60 / 5.64}%`,
                  style: { marginLeft: 'auto' },
                  numeric: true,
                  sum: settings.discountsAndFees.total
                },
                settings.discountsAndFees.unitPrice && {
                  id: 'unitPrice',
                  label: 'Unit Price',
                  type: FieldType.CURRENCY,
                  width: `${80 / 5.64}%`,
                  style: settings.discountsAndFees.quantity || { marginLeft: 'auto' }
                },
                settings.discountsAndFees.subtotal && {
                  id: 'amount',
                  label: 'Price Subtotal',
                  type: FieldType.CURRENCY,
                  width: `${80 / 5.64}%`,
                  style: settings.discountsAndFees.quantity ||
                    settings.discountsAndFees.unitPrice || { marginLeft: 'auto' },
                  sum: settings.discountsAndFees.total,
                  sumTransformFn,
                  displayTransformFn: sumTransformFn
                }
              ]
            }
          },
          showDF && {
            component: 'Divider'
          },
          showPayments && {
            component: 'Table',
            source: 'payments',
            props: {
              title: 'Payments',
              titleSumColumn: showPaymentsTitleSum
                ? { id: 'appliedAmount', type: FieldType.CURRENCY }
                : null,
              columns: [
                settings.payments.number && {
                  id: 'paymentNumber',
                  label: 'Payment',
                  width: `${116 / 5.64}%`,
                  weight: TW.BOLD
                },
                settings.payments.type && {
                  id: 'paymentType.name',
                  label: 'Type',
                  width: `${48 / 5.64}%`
                },
                settings.payments.date && {
                  id: 'paymentDate',
                  label: 'Date',
                  type: FieldType.DATE,
                  width: `${320 / 5.64}%`
                },
                settings.payments.amount && {
                  id: 'appliedAmount',
                  label: 'Amount',
                  type: FieldType.CURRENCY,
                  width: `${80 / 5.64}%`,
                  sum: settings.payments.total
                }
              ]
            }
          },
          showPayments && {
            component: 'Divider'
          },
          getTotals({ readonly, settings, taxRateOptions, adjustmentsFlag }),
          showTermsOfService && {
            component: 'Divider'
          },
          showTermsOfService && {
            component: 'Typography',
            props: {
              testingid: 'termsOfService',
              weight: TW.BOLD,
              value: 'Terms of Service',
              default: {
                variant: TV.BASE,
                style: { marginBottom: 8 }
              },
              pdf: {
                variant: TV.S2,
                style: { marginBottom: 4 }
              }
            }
          },
          showTermsOfService && {
            component: { default: readonly ? 'Typography' : 'Input', pdf: 'Typography' },
            source: 'termsOfService',
            props: {
              inputProps: {
                testingid: 'termsOfService'
              },
              default: {
                multiline: true,
                rows: 8,
                rowsMax: 30
              }
            }
          },
          showCustomerSignatures && {
            component: 'Divider'
          },
          showCustomerSignatures && {
            component: 'Typography',
            props: {
              weight: TW.BOLD,
              value: 'Customer Signature(s)',
              default: {
                variant: TV.BASE,
                style: { marginBottom: 8 }
              },
              pdf: {
                variant: TV.S2,
                style: { marginBottom: 4 }
              }
            }
          },
          showCustomerSignatures && {
            contents: [
              {
                component: {
                  default: 'CustomerSignaturesDisplay',
                  pdf: 'CustomerSignaturesDisplayPDF'
                },
                source: 'customerSignatures'
              }
            ]
          }
        ]
      }
    }
  };
};
