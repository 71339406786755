import gql from 'graphql-tag';
import { useSelector } from 'react-redux';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { propertyAssetFragment } from 'customHooks/useServiceAgreement';

export const updatePropertyAsset = gql`
  mutation updatePropertyAsset($partitionKey: String, $data: UpdatePropertyAssetInput!) {
    updatePropertyAsset(partitionKey: $partitionKey, data: $data) {
        ${propertyAssetFragment}
    }
  }
`;

const useUpdatePropertyAsset = () => {
  const user = useSelector(state => state.user);
  return useExtendedMutation(updatePropertyAsset, {
    serializer: ({ payload }) => ({
      variables: {
        partitionKey: user.tenantId,
        data: payload
      }
    }),

    onSuccess: (data, snackbar) => {
      snackbar('success', 'Property Asset updated');
    },
    onError: (error, snackbar) => {
      snackbar('error', 'Unable to update Property Asset');
    }
  });
};

export default useUpdatePropertyAsset;
