import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  textInput: {
    fontWeight: 'bold',
    fontSize: 16,
    padding: theme.spacing(0.75),
    border: `6px solid ${theme.palette.grayscale(100)}`,
    background: theme.palette.grayscale(100),
    margin: `0`,
    '&:hover': {
      background: theme.palette.grayscale(92)
    },
    '& input': {
      padding: 0
    }
  },
  dropdown: {
    minWidth: 300
  },
  textLabel: {
    fontSize: 10,
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
    marginBottom: 5
  },
  itemRow: {
    marginBottom: theme.spacing(2)
  }
}));

export default styles;
