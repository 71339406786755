import React, { useCallback, useMemo, useState } from 'react';

import { Button, Modal, MoreButton, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { noop, sortBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { AuditLogs, Modal as OldModal, SergeantModal, XGrid } from 'components';
import FormViewer from 'components/FormSection/FormViewer';
import { snackbarOn } from 'redux/actions/globalActions';

import { ViewPartsAndMaterials } from 'scenes/ServiceAgreements/DetailView/ServiceAgreementsTabs/RecurringMaintenance/Components/Templates/ViewPartsAndMaterials';
import { CustomerPropertyService } from 'services/core';
import { Logger } from 'services/Logger';
import { TaskListType } from 'utils/AppConstants';
import { Mode } from 'utils/constants';

import EditTaskOnPropertyModal from './AddEditTaskOnPropertyModal';
import { getTasksByCustomerProperty } from './TaskQuery.gql';
import taskColumns from './TaskTableMeta';

const defaultSGModalState = {
  open: false,
  renderContent: null,
  data: null,
  dataType: 'Task',
  layout: null,
  mode: Mode.VIEW,
  title: 'Activity'
};
const defaultPartsAndMaterial = { open: false, partsAndMaterials: [] };

const useStyles = () => ({
  link: {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
});

const TaskTable = ({
  customerPropertyId,
  includeAuditLogs = true,
  includeJob = false,
  includeQuote = false,
  listType = 'All',
  showQuoteTaskStatus = false,
  associatedWorkXgridColumn,
  filter,
  isActive = true,
  ...tableProps
} = {}) => {
  const styles = useStyles();
  const [pageSize, setPageSize] = React.useState(5);
  const [openSGModal, setSGModal] = useState(defaultSGModalState);
  const [currentForm, setCurrentForm] = useState({});
  const [forms, setForms] = useState([]);
  const [formViewerModalOpen, setFormViewerModalOpen] = useState(false);
  const [formsModalOpen, setFormsModalOpen] = useState(false);
  const [viewPartsAndMaterials, setViewPartsAndMaterials] = useState(defaultPartsAndMaterial);
  const [refetchTrigger, setRefetchTrigger] = useState(false);
  const [editTaskModalOpen, setEditTaskModalOpen] = useState(false);
  const [task, setTask] = useState({});
  const dispatch = useDispatch();
  const snackbar = useCallback((...args) => dispatch(snackbarOn(...args)), [dispatch]);
  const user = useSelector(s => s.user);
  const assetAgainstTaskEnabled = useSelector(s => s.settings.assetTrackingAgainstTask);

  const deleteTask = useCallback(
    async row => {
      try {
        const service = new CustomerPropertyService();
        await service.deleteTaskFromProperty(user.tenantId, row.id);
        snackbar('success', `Successfully deleted task ${row.name}`);
        setRefetchTrigger(prevState => !prevState);
      } catch (error) {
        Logger.error(error);
        snackbar('error', 'Unable to delete task, please try again later', error);
      } finally {
        setSGModal(defaultSGModalState);
      }
    },
    [snackbar, user.tenantId]
  );

  const renderFormsCell = useCallback(value => {
    const formsData = value.items;

    if (formsData.length === 0) return '-';
    if (formsData.length === 1) {
      const f = formsData[0];
      return (
        <div
          css={styles.link}
          role="button"
          tabIndex={0}
          onClick={() => {
            setFormViewerModalOpen(true);
            setCurrentForm(f);
          }}
          onKeyDown={noop}
        >
          <Typography>{f.form.name}</Typography>
        </div>
      );
    }

    return (
      <div
        css={styles.link}
        role="button"
        tabIndex={0}
        onClick={() => {
          setForms(formsData);
          setFormsModalOpen(true);
        }}
        onKeyDown={noop}
      >
        {formsData.length} Forms
      </div>
    );
  }, []);

  const renderPartsCell = useCallback(
    partsAndMaterials => {
      let cellText;
      const productsLength = partsAndMaterials?.length || 0;
      if (productsLength) {
        cellText = `${productsLength} Part${productsLength > 1 ? 's' : ''}`;
      }
      return (
        <ThemeProvider>
          <div
            css={productsLength === 0 ? undefined : styles.link}
            role="button"
            tabIndex={0}
            onClick={() => setViewPartsAndMaterials({ open: true, partsAndMaterials })}
            onKeyDown={noop}
          >
            <Typography variant={TV.BASE} weight={TW.REGULAR}>
              {cellText || '-'}
            </Typography>
          </div>
        </ThemeProvider>
      );
    },
    [setViewPartsAndMaterials]
  );

  const renderMoreButton = useCallback(
    row => (
      <ThemeProvider>
        <MoreButton
          options={[
            {
              label: 'View Activity',
              onClick: () => {
                const activityComponent = () => (
                  <AuditLogs
                    dataService={() => row?.auditLogEntries?.items || []}
                    variant="singleEntity"
                  />
                );
                setSGModal({
                  open: true,
                  renderContent: activityComponent,
                  data: {},
                  layout: null,
                  mode: Mode.VIEW,
                  title: 'Activity'
                });
              }
            },
            ![TaskListType.COMPLETED_TASKS].includes(listType) &&
              isActive && {
                label: 'Edit',
                onClick: () => {
                  setEditTaskModalOpen(true);
                  setTask({
                    taskId: row.id,
                    assetId: row.assetId,
                    taskName: row.name,
                    description: row.description,
                    forms: sortBy(row.formData.items, item => item.sortOrder).map(item => ({
                      id: item.form.id,
                      label: item.form.name,
                      value: { ...item.form, updateId: item.id, required: item.isRequired }
                    })),
                    partsAndMaterials: sortBy(row.taskEntries.items, item => item.sortOrder).map(
                      item => ({
                        id: item.product.id,
                        label: item.name,
                        value: {
                          ...item,
                          updateId: item.id,
                          code: item.product.code
                        }
                      })
                    ),
                    version: row.version
                  });
                }
              },
            ![TaskListType.ADDED_TO_JOB, TaskListType.COMPLETED_TASKS].includes(listType) &&
              isActive && {
                label: 'Delete Task',
                onClick: async () => {
                  setSGModal({
                    open: true,
                    data: { ...row },
                    layout: null,
                    mode: Mode.DELETE,
                    dataType: 'Task',
                    handleAction: deleteTask,
                    deleteItemLabel: row.name
                  });
                }
              }
          ].filter(Boolean)}
          style={{ padding: 4, marginLeft: 3 }}
        />
      </ThemeProvider>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteTask, refetchTrigger]
  );

  const memoizedTaskColumns = useMemo(
    () =>
      taskColumns({
        renderFormsCell,
        renderPartsCell,
        renderMoreButton,
        associatedWorkXgridColumn,
        showQuoteTaskStatus,
        assetAgainstTaskEnabled,
        isCompleted: listType === TaskListType.COMPLETED_TASKS
      }),
    [
      listType,
      renderPartsCell,
      associatedWorkXgridColumn,
      showQuoteTaskStatus,
      renderMoreButton,
      assetAgainstTaskEnabled
    ]
  );

  const formMetaData = useMemo(() => {
    try {
      if (currentForm.formDataJson) {
        return JSON.parse(currentForm.formDataJson).formData;
      }
      return currentForm.formDefinition
        ? JSON.parse(currentForm.formDefinition.formDefinitionJson)
        : {};
    } catch (error) {
      Logger.error(error);
      return {};
    }
  }, [currentForm]);

  return (
    <>
      <XGrid
        checkboxSelection={
          ![TaskListType.ADDED_TO_JOB, TaskListType.COMPLETED_TASKS].includes(listType) && isActive
        }
        columns={memoizedTaskColumns}
        disableToolbar
        entityTypeName="Tasks"
        pageSize={pageSize}
        pagination
        query={getTasksByCustomerProperty}
        queryVariables={{
          customerPropertyId,
          listType,
          includeAuditLogs,
          includeJob,
          includeQuote,
          filter
        }}
        refetchTrigger={refetchTrigger}
        rowHeight={40}
        rowsPerPageOptions={[5, 10, 20]}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        {...tableProps}
      />
      <SergeantModal
        confirmRemoveItemLabel={openSGModal.deleteItemLabel}
        confirmRemoveStatement={
          listType === TaskListType.READY_FOR_JOB
            ? 'This delete will permanently delete the task from the property'
            : undefined
        }
        data={openSGModal.data}
        dataType={openSGModal.dataType}
        handleClose={() => setSGModal(defaultSGModalState)}
        handlePrimaryAction={openSGModal.handleAction}
        key={openSGModal.open}
        layout={openSGModal.layout}
        mode={openSGModal.mode}
        open={openSGModal.open}
        renderContent={openSGModal.renderContent}
        title={openSGModal.title}
      />
      <ViewPartsAndMaterials
        data={viewPartsAndMaterials.partsAndMaterials}
        disableQtyInput
        isVisible={viewPartsAndMaterials.open}
        onModalClose={() => setViewPartsAndMaterials(defaultPartsAndMaterial)}
      />
      <ThemeProvider>
        <Modal
          actions={
            <Button fullWidth onClick={() => setFormsModalOpen(false)}>
              Close
            </Button>
          }
          open={formsModalOpen}
          PaperProps={{ style: { minWidth: 400 } }}
          title="Task Forms"
          onClose={() => setFormsModalOpen(false)}
        >
          {forms.map((f, i) => (
            <div
              css={{ ...styles.link, marginTop: i === 0 ? 0 : 8 }}
              role="button"
              tabIndex={0}
              onClick={() => {
                setFormViewerModalOpen(true);
                setFormsModalOpen(false);
                setCurrentForm(f);
              }}
              onKeyDown={noop}
            >
              <Typography>{f.form.name}</Typography>
            </div>
          ))}
        </Modal>
      </ThemeProvider>
      <OldModal handleClose={() => setFormViewerModalOpen(false)} open={formViewerModalOpen}>
        <FormViewer
          description={currentForm.form?.description}
          formMetaData={formMetaData}
          handleClose={() => setFormViewerModalOpen(false)}
          isEditMode={false}
          name={currentForm.form?.name}
        />
      </OldModal>
      <EditTaskOnPropertyModal
        mode={Mode.EDIT}
        open={editTaskModalOpen}
        propertyId={customerPropertyId}
        refetchTasks={() => setRefetchTrigger(prevState => !prevState)}
        task={task}
        onClose={() => setEditTaskModalOpen(false)}
      />
    </>
  );
};

export default TaskTable;
