import React from 'react';

import PropTypes from 'prop-types';

import { SergeantModal } from 'components';
import { RenameViewLayout } from 'meta/Jobs/RenameView';
import { Mode } from 'utils/constants';

const RenameModal = props => {
  const { open, mode, currentLabel, dataType, handleViewRename, handleClose } = props;

  const handleSaveAction = async (data, loaded) => {
    await handleViewRename(data?.viewName);
    loaded();
    handleClose();
  };

  return (
    <SergeantModal
      alignCloseRight
      customPrimaryButtonLabel="Save & Exit"
      data={{
        viewName: currentLabel
      }}
      dataType={dataType ?? 'View'}
      handleClose={handleClose}
      handlePrimaryAction={handleSaveAction}
      layout={RenameViewLayout}
      mode={mode || Mode.RENAME}
      open={open}
    />
  );
};

RenameModal.propTypes = {
  open: PropTypes.bool,
  mode: PropTypes.string,
  currentLabel: PropTypes.string,
  handleViewRename: PropTypes.func,
  handleClose: PropTypes.func.isRequired
};

export default RenameModal;
