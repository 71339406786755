import { TimesheetsService } from 'services/core';
import { logErrorWithCallback } from 'utils';
import removeObjProperties from 'utils/removeObjProperties';

import { getLabourTypes } from './helpers';

export const unUsedLineItemProperties = [
  '__typename',
  'productName',
  'version',
  'createdDate',
  'createdDateTime'
];

// removing unwanted keys in the payload
const getLabourTypePayload = ({
  id,
  name,
  isActive,
  payrollCosts,
  labourTypeBillingHourTypeProducts = [],
  sortOrder
}) => ({
  id,
  name,
  isActive,
  sortOrder,
  payrollCosts: Array.isArray(payrollCosts)
    ? payrollCosts.map(({ cost, costCodeId, hourTypeId, id: payrollCostId, jobCostTypeId }) => ({
        cost: cost || null, // when undefined, apollo ignores the attribute in the payload
        costCodeId,
        hourTypeId,
        id: payrollCostId,
        jobCostTypeId
      }))
    : [],
  labourTypeBillingHourTypeProducts: labourTypeBillingHourTypeProducts.map(
    ({ id: billingHourTypeMappingId, billingHourTypeId, productId }) => ({
      id: billingHourTypeMappingId,
      billingHourTypeId,
      productId: productId || null
    })
  )
});

export const addPayrollLabourType = async (
  formData = {},
  tenantId,
  tenantCompanyId,
  snackbarOn
) => {
  let responseData = {};
  try {
    const Service = new TimesheetsService();
    const payload = {
      companyId: tenantCompanyId,
      labourTypes: getLabourTypePayload(formData)
    };
    const response = await Service.addPayrollLabourTypes(tenantId, payload);
    snackbarOn('success', `Successfully saved type - ${formData.name}`);
    [responseData = {}] = getLabourTypes(response?.data?.addLabourTypesToCompany);
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to add type, please try again later');
  }
  return responseData;
};
export const updatePayrollLabourType = async (formData = {}, tenantId, snackbarOn) => {
  let responseData = {};
  try {
    const Service = new TimesheetsService();
    const payload = removeObjProperties(formData, unUsedLineItemProperties);
    const response = await Service.updatePayrollLabourTypes(tenantId, payload);
    responseData = getLabourTypes(response?.data?.updateLabourType);
    snackbarOn('success', `Successfully updated ${formData.name} type`);
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to update type, please try again later');
  }
  return responseData;
};

export const deletePayrollLabourType = async (formData = {}, tenantId, snackbarOn) => {
  let responseData = {};
  try {
    const Service = new TimesheetsService();
    const payload = removeObjProperties(formData, ['__typename', 'sortOrder']);
    const response = await Service.deletePayrollLabourType(tenantId, payload);
    responseData = response?.data?.deleteLabourType;
    snackbarOn('success', `Successfully deleted ${formData.hourType} type`);
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to delete type, please try again later');
  }
  return responseData;
};

export const updatePayrollLabourTypeOrder = async (
  formData = {},
  tenantId,
  tenantCompanyId,
  snackbarOn
) => {
  let responseData = {};
  try {
    const cleanData = formData.map(item => ({
      ...item,
      labourTypeBillingHourTypeProducts: Array.isArray(item.labourTypeBillingHourTypeProducts)
        ? item.labourTypeBillingHourTypeProducts.map(lt => ({
            billingHourTypeId: lt.billingHourTypeId,
            id: lt.id,
            productId: lt.productId
          }))
        : item.labourTypeBillingHourTypeProducts
    }));

    const Service = new TimesheetsService();
    const payload = {
      companyId: tenantCompanyId,
      labourTypes: cleanData
    };
    const response = await Service.addPayrollLabourTypes(tenantId, payload);
    snackbarOn('success', `Successfully updated the order`);
    responseData = getLabourTypes(response?.data?.addLabourTypesToCompany);
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to update the order, please try again later');
  }
  return responseData;
};
