import { gql } from '@apollo/client/core';
import { useSelector } from 'react-redux';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { generateCompanySortKey } from 'services/core/utils';
import { logErrorWithCallback } from 'utils';

export const getLabourRateItems = pricebookItems =>
  pricebookItems.reduce((items, pricebook) => {
    const { pricebookLabourEntries } = pricebook;
    return [...items, ...(pricebookLabourEntries?.items || [])];
  }, []);

const getLabourRates = gql`
  query getLabourRates(
    $partitionKey: String!
    $sortKey: String!
    $filter: TablePriceBookFilterInput
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      priceBooks: priceBooks(entityConnection: "PriceBook", filter: $filter) {
        items {
          id
          isDefault
          pricebookLabourEntries(entityConnection: "PricebookLabourEntry") {
            items {
              id
              parentId
              rate
              version
              isActive
              costCodeId
              labourTypeId
              revenueTypeId
              billingHourTypeId
            }
          }
        }
      }
    }
  }
`;

const useLabourRates = priceBookId => {
  const user = useSelector(state => state.user);

  const { data, loading, error, refetch } = useExtendedQuery(getLabourRates, {
    variables: {
      partitionKey: user.tenantId,
      sortKey: generateCompanySortKey(user.tenantId, user.tenantCompanyId)
    },
    transform: result => {
      const labourRates = getLabourRateItems(result?.getCompany?.priceBooks?.items || []);
      return priceBookId ? labourRates?.filter(rate => rate.parentId === priceBookId) : labourRates;
    },
    onError: (err, snackbar) =>
      logErrorWithCallback(err, snackbar, 'Unable to fetch labour rates, please try again later'),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  return [data || [], loading, error, refetch];
};

export default useLabourRates;
