import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function Properties(props) {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SvgIcon {...props}>
      <path d="M8.99792 1L21.5562 3.82547V20.4885L23 21.0681V23H1V21.0681L2.44375 20.4885V7.44786L8.10417 5.9506V20.4885H8.99792V1ZM16.25 17H14.25V21H16.25V17ZM19.25 17H17.25V19H19.25V17ZM13.25 17H11.25V19H13.25V17ZM6.25 17H4.25V19H6.25V17ZM19.25 14H17.25V16H19.25V14ZM16.25 14H14.25V16H16.25V14ZM13.25 14H11.25V16H13.25V14ZM6.25 14H4.25V16H6.25V14ZM19.25 11H17.25V13H19.25V11ZM16.25 11H14.25V13H16.25V11ZM13.25 11H11.25V13H13.25V11ZM6.25 11H4.25V13H6.25V11ZM19.25 5H17.25V9H19.25V5ZM16.25 5H14.25V9H16.25V5ZM13.25 5H11.25V9H13.25V5Z" />
    </SvgIcon>
  );
}
