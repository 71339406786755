import React, { useCallback, useMemo, useState } from 'react';

import { PresetSelect, SgtForm, SideSheet, ThemeProvider } from '@BuildHero/sergeant';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenameModal from 'components/ResponsiveTable/RenameModal';
import useMutatePresets from 'customHooks/useMutatePresets';
import { snackbarOn } from 'redux/actions/globalActions';

import { InvoiceSettingConstants } from 'scenes/Invoices/constants';
import { Mode } from 'utils/constants';
import { generateSettingLayout } from 'utils/settingsHelper';

const title = 'Invoice Configuration';
const subtext = 'These settings will change what is shown on the customer facing invoice';

const initialRenameModalState = {
  open: false,
  mode: Mode.ADD,
  presetOption: null
};

/**
 * Renders the invoice settings in a side sheet.
 *
 * @param props
 * @param props.onSettingsSave Callback for saving the invoice settings
 * @param props.onSettingsChange Callback for changing the invoice settings
 * @param props.settings The object containing invoice setting data
 * @param props.blockInteractions True when there should be no user interaction or loading
 * @param props.user user/tenant,
 * @param props.snackbar Snackbar
 */
function InvoiceSetting({
  onSettingsSave,
  onSettingsChange,
  settings,
  blockInteractions,
  user,
  snackbar
}) {
  const [formService, setFormService] = useState();
  const {
    presets,
    loading,
    findPreset,
    createPreset,
    updatePreset,
    renamePreset,
    duplicatePreset,
    deletePreset
  } = useMutatePresets({
    user,
    snackbar,
    entityName: InvoiceSettingConstants.INVOICE_PRESETS
  });

  const maps = useMemo(
    () => ({
      dependentMap: {
        Labor: { fieldName: 'Invoice Mode.Simple Invoice', disableIf: true },
        'Parts & Materials': { fieldName: 'Invoice Mode.Simple Invoice', disableIf: true },
        'Discounts & Fees': { fieldName: 'Invoice Mode.Simple Invoice', disableIf: true },
        Payments: { fieldName: 'Invoice Mode.Simple Invoice', disableIf: true }
      },
      propsMap: {
        [InvoiceSettingConstants.INVOICE_PRESETS]: presets?.map(
          preset => preset[InvoiceSettingConstants.INVOICE_PRESETS]
        )
      },
      changeHandlerMap: {
        'Invoice Mode.Simple Invoice': (key, value, { setFieldValue }) => {
          // for simple invoice, change all fields under line items, subsections to false
          if (value) {
            Object.keys(layout.fields).forEach(
              field =>
                ['Labor', 'Parts & Materials', 'Discounts & Fees', 'Payments'].some(i =>
                  field.startsWith(i)
                ) && setFieldValue(field, false)
            );
          }
        }
      }
    }),
    [presets, formService]
  );

  const presetsSelectChangeHandler = (key, value, { setValues }) => {
    setValues(findPreset(value.value));
  };

  const {
    [InvoiceSettingConstants.INVOICE_PRESETS]: presetsSetting,
    ...sectionsSettings
  } = settings;

  const layout = useMemo(
    () =>
      generateSettingLayout(
        title,
        sectionsSettings,
        presets,
        maps,
        'PresetSelect',
        presetsSelectChangeHandler,
        subtext,
        null,
        InvoiceSettingConstants.INVOICE_PRESETS
      ),
    [settings, maps, presets]
  );

  const [renameModalState, setRenameModalState] = useState(initialRenameModalState);

  const onClickCreateNewPreset = useCallback(
    async values =>
      setRenameModalState({
        open: true,
        mode: Mode.ADD,
        presetOption: {
          settings: values
        }
      }),
    []
  );

  const onClickRenamePreset = useCallback(
    async presetId =>
      setRenameModalState({
        open: true,
        mode: Mode.RENAME,
        presetOption: findPreset(presetId)
      }),
    [findPreset]
  );

  const onRenamePreset = useCallback(
    async name => {
      if (loading) return;

      if (!name) {
        snackbar('error', 'Preset name is required');
        return;
      }
      switch (renameModalState.mode) {
        case Mode.RENAME: {
          await renamePreset(
            name,
            renameModalState.presetOption[InvoiceSettingConstants.INVOICE_PRESETS].value
          );
          break;
        }
        case Mode.ADD: {
          await createPreset(name, renameModalState.presetOption.settings);
          break;
        }
        default: {
          // do nothing
        }
      }
    },
    [loading, renameModalState, snackbar, renamePreset, createPreset]
  );

  if (loading || blockInteractions) return null;
  return (
    <ThemeProvider>
      <SideSheet topOffset="180px">
        <SgtForm
          configuration={layout}
          customComponents={{
            PresetSelect: componentProps => (
              <PresetSelect
                options={componentProps.props.options.map(preset => ({
                  label: preset.label,
                  id: preset.value,
                  isDefault: preset.isDefault
                }))}
                selectedOption={{
                  label: componentProps.field.value.label,
                  id: componentProps.field.value.value
                }}
                onClickCreateNew={() => onClickCreateNewPreset(componentProps.form.values)}
                onClickDuplicate={duplicatePreset}
                onClickRemove={deletePreset}
                onClickRename={onClickRenamePreset}
                onClickUpdate={() => updatePreset(componentProps.form.values)}
                onSelect={newValue => {
                  const key = componentProps.field.name;
                  const value = {
                    label: '',
                    value: newValue
                  };
                  componentProps.props.onChange(key, value, componentProps.form);
                }}
                {...componentProps}
              />
            )
          }}
          initialValues={settings}
          layout="default"
          onCreateService={setFormService}
          onFormChange={onSettingsChange}
          onSaveOnChange={onSettingsSave}
        />
      </SideSheet>

      <RenameModal
        currentLabel={
          renameModalState.mode === Mode.ADD
            ? ''
            : renameModalState.presetOption[InvoiceSettingConstants.INVOICE_PRESETS].label ?? ''
        }
        dataType={InvoiceSettingConstants.INVOICE_PRESETS}
        handleClose={() => setRenameModalState(initialRenameModalState)}
        handleViewRename={onRenamePreset}
        mode={renameModalState.mode}
        open={renameModalState.open}
      />
    </ThemeProvider>
  );
}

InvoiceSetting.propTypes = {
  onSettingsSave: PropTypes.func.isRequired,
  onSettingsChange: PropTypes.func.isRequired,
  onSaveAsNewPreset: PropTypes.func.isRequired,
  onUpdatePreset: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired, // from Redux
  blockInteractions: PropTypes.bool,
  settings: PropTypes.object,
  snackbar: PropTypes.func.isRequired
};

InvoiceSetting.defaultProps = {
  settings: {},
  blockInteractions: false
};

export default connect(state => ({ user: state.user }), { snackbar: snackbarOn })(InvoiceSetting);
