/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

import { sentryException } from '../Logger/index';

export async function getPhaseCodesByProject(projectId, phaseId) {
  const params = {};
  if (phaseId) {
    params.where = { projectPhaseId: phaseId };
  }
  return await instance
    .get(`projectphasedepartmentcostcode/byproject/${projectId}`, { params })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get Project Phase Departments');
      return [];
    });
}

export async function getPhaseDepartmentCostCodeById(id) {
  return await instance
    .get(`projectphasedepartmentcostcode/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get Project Phase');
      return [];
    });
}
