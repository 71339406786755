import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Modal, SgtForm, theme, ThemeProvider } from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';

import ReportIcon from '@material-ui/icons/Report';

import { useSelector } from 'react-redux';

import { CustomerPropertyService } from 'services/core';
import { Mode } from 'utils/constants';
import { constructSelectOptions } from 'utils/constructSelectOptions';

import { useDeleteTaskById } from '../mutations';
import { useTaskById } from '../queries';

import ActionBtns from './Components/ActionBtns';
import TaskDetails from './Components/TaskDetails';
import Title from './Components/Title';
import configuration from './CreateModal.layout';
import { getValidationSchema } from './CreateModal.utils';
import CustomAssetComponent from './CustomComponents/CustomAssetSelect';
import CustomAttachmentComponent from './CustomComponents/CustomAttachments';
import CustomFormComponent from './CustomComponents/CustomForms';
import CustomPartsAndMaterials from './CustomComponents/CustomPartsAndMaterials';
import CustomPropertySearch from './CustomComponents/CustomPropertySearch';

const style = {
  inputLabel: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'normal',
    letterSpacing: '0.01px',
    lineHeight: '14px'
  },
  modal: {
    '& li[class*="MuiMenuItem-root"]': {
      borderBottom: `1px solid ${theme.palette.grayscale(90)}`
    }
  },
  searchbarGrid: {
    '& .MuiInputBase-root': {
      fontSize: 14,
      padding: '0 11px'
    }
  },
  errorMsg: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: 14,
      marginRight: 4
    }
  }
};

const CreateRecommendationModal = ({
  confirmContext,
  isCreateModalOpen,
  modalConfig,
  refetchTaskList,
  refetchTrigger,
  setIsCreateModalOpen,
  setRefetchTrigger,
  updateTask
}) => {
  const user = useSelector(state => state.user);
  const defaultPricebookId = useSelector(state => state.company.defaultPriceBookId);
  const [formService, setFormService] = useState();
  const [propertyErrorMsg, setPropertyErrorMsg] = useState('');
  const [assetOptions, setAssetOptions] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [scopedPricebook, setScopedPricebook] = useState(defaultPricebookId);
  const { refetch: refetchTaskData, data: taskData } = useTaskById(modalConfig?.row?.id || '');
  const [deleteTaskById, { loading: deletingTask }] = useDeleteTaskById();

  useEffect(() => {
    if (modalConfig.type === Mode.EDIT) {
      const {
        assetValue,
        attachmentData,
        customerPropertyId,
        customerPropertyName,
        description,
        formData,
        name
      } = modalConfig?.row;

      let propertyData;
      const fetchPropertyData = async () => {
        const { data } = await new CustomerPropertyService().getCustomerPropertyInfoById(
          customerPropertyId
        );
        propertyData = data.getCustomerPropertyById;
        const options = constructSelectOptions(propertyData?.propertyAssets?.items, 'assetName');
        setAssetOptions(options);

        formService?.formikContext?.setValues({
          ...formService.formikContext.values,
          addedForms: formData ? JSON.parse(formData) : [],
          assetId: taskData?.assetId,
          assetValue,
          attachments: attachmentData ? JSON.parse(attachmentData) : [],
          billingCustomer: propertyData?.billingCustomer?.customerName,
          customer: propertyData?.parentEntity?.customerName,
          description,
          partsData: taskData?.taskEntries?.items,
          property: customerPropertyName,
          propertyId: customerPropertyId,
          recommendation: name || ''
        });
      };
      fetchPropertyData();
    }
  }, [modalConfig, formService, taskData]);

  const ConnectedAssetSelect = props => <CustomAssetComponent {...props} />;
  const ConnectedPartsSelect = props => <CustomPartsAndMaterials {...props} />;
  const ConnectedAttachments = props => <CustomAttachmentComponent {...props} />;
  const ConnectedPropertySearch = props => <CustomPropertySearch {...props} />;

  const handleClick = useCallback(() => {
    if (!formService.formikContext.values.propertyId) {
      setPropertyErrorMsg(
        <span css={style.errorMsg}>
          <ReportIcon />
          Property is a required field
        </span>
      );
    }
    formService.formikContext.handleSubmit();
  }, [formService]);

  const handleSubmit = useCallback(
    async payload => {
      setBtnLoading(true);

      modalConfig.onSave({
        payload,
        tenantId: user.tenantId,
        refetchTaskData,
        onComplete: () => {
          setBtnLoading(false);
          setIsCreateModalOpen(false);
          setRefetchTrigger(!refetchTrigger);
          if (modalConfig.type === Mode.ADD) {
            refetchTaskList(user.tenantId);
          }
        }
      });
    },
    [
      modalConfig,
      refetchTaskData,
      refetchTaskList,
      refetchTrigger,
      setRefetchTrigger,
      setIsCreateModalOpen,
      user.tenantId
    ]
  );

  const validationSchema = getValidationSchema();

  const layoutMeta = useMemo(
    () =>
      configuration({
        assetOptions,
        mode: modalConfig?.type,
        scopedPricebook,
        propertyErrorMsg,
        setAssetOptions,
        setPropertyErrorMsg,
        setScopedPricebook
      }),
    [assetOptions, modalConfig.type, scopedPricebook, propertyErrorMsg, setPropertyErrorMsg]
  );

  return (
    <ThemeProvider>
      <Modal
        actions={ActionBtns({
          btnLoading,
          confirmContext,
          deleteTaskById,
          deletingTask,
          handleClick,
          modalConfig,
          refetchTaskList,
          refetchTrigger,
          setBtnLoading,
          setIsCreateModalOpen,
          setRefetchTrigger,
          tenantId: user.tenantId,
          updateTask
        })}
        css={style.modal}
        fullScreen
        open={isCreateModalOpen}
        title={Title(modalConfig)}
        onClose={() => setIsCreateModalOpen(false)}
      >
        {TaskDetails(modalConfig)}
        <SgtForm
          configuration={layoutMeta}
          customComponents={{
            CustomAssetSelect: ConnectedAssetSelect,
            CustomFormComponent,
            CustomAttachmentComponent: ConnectedAttachments,
            CustomPropertySearch: ConnectedPropertySearch,
            PartsSelect: ConnectedPartsSelect
          }}
          initialValues={{
            addedForms: [],
            assetId: '',
            attachments: [],
            billingCustomer: '',
            customer: '',
            description: '',
            partsData: [],
            property: '',
            recommendation: ''
          }}
          layout="default"
          validationSchema={validationSchema}
          onCreateService={setFormService}
          onSubmit={handleSubmit}
        />
      </Modal>
    </ThemeProvider>
  );
};

export default CreateRecommendationModal;
