import React, { createContext, useContext } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { EventEntities } from '@dispatch/Dispatch.constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { useGetCompanyDispatchSettings } from '../../Settings/Dispatch/DispatchSettings.queries';

import { DEFAULT_SETTINGS } from './default';
import useSettings from './useSettings';

const DispatchSettingsContext = createContext(DEFAULT_SETTINGS);

export const useDispatchSettings = () => useContext(DispatchSettingsContext);

export const withDispatchSettings = Component => props => {
  const settings = useSettings(DEFAULT_SETTINGS);
  const launchDarklyFlags = useFlags();
  const isManDayEnabled = launchDarklyFlags[FeatureFlags.PROJECT_MANAGEMENT];
  const { data } = useGetCompanyDispatchSettings();

  const allSettings = {
    ...data,
    ...settings,
    enabledEvents: {
      ...settings.enabledEvents,
      [EventEntities.MAN_DAY.value.clientValue]: {
        enabled: isManDayEnabled
      }
    }
  };

  return (
    <DispatchSettingsContext.Provider value={allSettings}>
      <Component {...props} />
    </DispatchSettingsContext.Provider>
  );
};
