import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const EDIT_ATTACHMENT = gql`
  mutation updateAttachment($partitionKey: String!, $data: UpdateAttachmentInput!) {
    updateAttachment(partitionKey: $partitionKey, data: $data) {
      id
      customFileName
      fileName
      fileUrl
      type
      comment
      createdBy
      createdDate
      createdDateTime
      includeInInvoice
      version
    }
  }
`;

const serializer = ({ tenantId, attachment }) => ({
  variables: {
    partitionKey: tenantId,
    data: attachment
  }
});

const optimisticResponseFactory = ({ attachment }) => ({
  updateAttachment: {
    ...(attachment || {}),
    version: (attachment?.version || 0) + 1,
    __typename: 'Attachment'
  }
});

export const useEditReviewReportAttachment = () => {
  return useExtendedMutation(EDIT_ATTACHMENT, {
    serializer,
    optimisticResponseFactory
  });
};
