import { BILLING_INFO_TOOLTIP } from 'utils/constants';

const padding = 8;
const generalInformationItems = (customerTypeOptions, priceBookOptions) => {
  return [
    {
      options: {
        grow: 1,
        padding
      },
      contents: [
        {
          options: {
            fontWeight: 700,
            marginBottom: 0
          },
          component: 'Subtitle',
          source: 'General Information'
        }
      ]
    },
    {
      options: {
        direction: 'row'
      },
      contents: [
        {
          options: {
            grow: 1,
            padding
          },
          contents: [
            {
              component: 'TextInput',
              source: 'customerName',
              options: {
                label: 'Name',
                isRequired: true
              }
            }
          ]
        },
        {
          options: {
            grow: 1,
            padding
          },
          contents: [
            {
              component: 'SelectInput',
              source: 'customerTypeValue',
              options: {
                label: 'Customer Type',
                valueSet: customerTypeOptions,
                enableSort: false
              }
            }
          ]
        },
        {
          options: {
            grow: 3,
            padding: [padding, 3 * padding, padding, 3 * padding]
          },
          contents: [
            {
              component: null
            }
          ]
        }
      ]
    },
    {
      options: {
        direction: 'row'
      },
      contents: [
        {
          options: {
            grow: 1,
            padding
          },
          contents: [
            {
              component: 'TextInput',
              source: 'email',
              options: {
                label: 'email address',
                type: 'string'
              }
            }
          ]
        },
        {
          options: {
            grow: 1,
            padding
          },
          contents: [
            {
              component: 'TextInput',
              source: 'phonePrimary',
              options: {
                label: 'phone'
              }
            }
          ]
        },
        {
          options: {
            grow: 1,
            padding: [2.5 * padding, padding, padding, padding]
          },
          contents: [
            {
              component: 'CheckboxInput',
              source: 'receiveSMS',
              options: {
                label: 'Receive SMS'
              },
              dependentField: {
                fieldName: 'phonePrimary',
                expectedValue: false,
                operation: 'bool',
                action: 'DISABLE'
              }
            }
          ]
        },
        {
          options: {
            grow: 2,
            padding: [padding, 2 * padding, padding, 2 * padding]
          },
          contents: [
            {
              component: null
            }
          ]
        }
      ]
    },
    {
      options: {
        direction: 'row'
      },
      contents: [
        {
          options: {
            padding,
            grow: 1
          },
          contents: [
            {
              component: 'SelectInput',
              source: 'priceBookId',
              options: {
                label: 'Pricebook',
                isSearchable: true,
                valueSet: priceBookOptions
              }
            }
          ]
        },
        {
          options: {
            grow: 4,
            padding: [padding, 4 * padding, padding, 4 * padding]
          },
          contents: [
            {
              component: null
            }
          ]
        }
      ]
    }
  ];
};
const getAddressFields = addressType => {
  const addressFields = {
    addressLine1: `${addressType}AddressLine1`,
    addressLine2: `${addressType}AddressLine2`,
    city: `${addressType}City`,
    state: `${addressType}State`,
    zipcode: `${addressType}Zipcode`,
    latitude: `${addressType}Latitude`,
    longitude: `${addressType}Longitude`,
    country: `${addressType}Country`,
    toSource: '',
    toLabel: ''
  };

  const [toSource, toLabel] =
    addressType === 'billing'
      ? ['billingBillTo', 'billing information']
      : ['businessShipTo', 'ship to'];
  addressFields.toLabel = toLabel;
  addressFields.toSource = toSource;
  addressFields.subtitle = addressType === 'billing' ? 'Billing Address' : 'Business Address';
  return addressFields;
};
const billingItems = ({ addressType, taxOptions, paymentTermOptions, invoicePresetOptions }) => {
  const addressFields = getAddressFields(addressType);

  return [
    {
      options: {
        grow: 1
      },
      contents: [
        {
          options: {
            fontWeight: 700,
            padding,
            marginBottom: 0
          },
          component: 'Subtitle',
          source: addressFields.subtitle
        }
      ]
    },
    {
      options: {
        direction: 'row'
      },
      contents: [
        {
          options: {
            grow: 2,
            padding
          },
          contents: [
            {
              component: 'TextInput',
              source: addressFields.toSource,
              options: {
                label: addressFields.toLabel,
                linesMax: 4,
                ...(addressFields.toSource === 'billingBillTo' && {
                  lines: 2,
                  tooltip: BILLING_INFO_TOOLTIP,
                  placeholder: 'Customer Name\nBill To',
                  isRequired: true
                })
              }
            }
          ]
        },
        {
          options: {
            grow: 3,
            padding: [padding, padding, padding, 2.5 * padding]
          },
          contents: [
            {
              component: null
            }
          ]
        }
      ]
    },
    {
      options: {
        direction: 'row'
      },
      contents: [
        {
          options: {
            size: 1,
            padding
          },
          contents: [
            {
              component: 'PlacesSearch',
              source: addressFields.addressLine1,
              options: {
                label: 'Address Line 1',
                valuesToSet: [
                  {
                    [addressFields.addressLine1]: 'addressLine1',
                    [addressFields.city]: 'city.shortName',
                    [addressFields.state]: 'state.shortName',
                    [addressFields.zipcode]: 'zipcode.shortName',
                    [addressFields.latitude]: 'latitude',
                    [addressFields.longitude]: 'longitude',
                    [addressFields.country]: 'country.longName'
                  }
                ],
                fieldsToMatch: addressType === 'billing' ? [{ taxRateId: [taxOptions, 'id'] }] : '',
                setSource: false,
                isRequired: true
              }
            }
          ]
        },
        {
          options: {
            padding
          },
          contents: [
            {
              component: 'TextInput',
              source: addressFields.addressLine2,
              options: {
                label: 'Address Line 2'
              }
            }
          ]
        },
        {
          options: {
            padding
          },
          contents: [
            {
              component: 'TextInput',
              source: addressFields.city,
              options: {
                label: 'city',
                isRequired: true
              }
            }
          ]
        },
        {
          options: {
            padding
          },
          contents: [
            {
              component: 'TextInput',
              source: addressFields.state,
              options: {
                label: 'state/province',
                isRequired: true
              }
            }
          ]
        },
        {
          options: {
            padding
          },
          contents: [
            {
              component: 'TextInput',
              source: addressFields.zipcode,
              options: {
                label: 'zip/postal code',
                isRequired: true
              }
            }
          ]
        }
      ]
    },
    {
      options: {
        direction: 'row'
      },
      contents:
        addressType === 'billing'
          ? [
              {
                options: {
                  grow: 1,
                  padding
                },
                contents: [
                  {
                    component: 'SelectInput',
                    source: 'taxRateId',
                    options: {
                      label: 'Tax code',
                      isSearchable: true,
                      valueSet: taxOptions,
                      enableSort: false
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 1,
                  padding: [2.5 * padding, padding, padding, padding]
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'isTaxable',
                    options: {
                      label: 'Taxable'
                    }
                  }
                ]
              },
              {
                options: {
                  padding
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'taxExemptIdValue',
                    options: {
                      label: 'Tax exempt ID'
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 1,
                  padding
                },
                contents: [
                  {
                    component: 'SelectInput',
                    source: 'paymentTermId',
                    options: {
                      label: 'Payment Terms',
                      isSearchable: true,
                      valueSet: paymentTermOptions
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 1,
                  padding
                },
                contents: [
                  {
                    component: 'SelectInput',
                    source: 'invoicePresetId',
                    options: {
                      label: 'Invoice Preset',
                      isSearchable: true,
                      valueSet: invoicePresetOptions
                    }
                  }
                ]
              }
            ]
          : []
    }
  ];
};

const otherInformationItems = tagOptions => {
  return [
    {
      options: {
        grow: 1,
        padding
      },
      contents: [
        {
          options: {
            fontWeight: 700,
            marginBottom: 0
          },
          component: 'Subtitle',
          source: 'Other Information'
        }
      ]
    },
    {
      options: {
        direction: 'row'
      },
      contents: [
        {
          options: {
            grow: 3,
            padding
          },
          contents: [
            {
              component: 'SelectInput',
              source: 'processedCustomerTags',
              options: {
                isMultipleSelection: true,
                label: 'Tags',
                valueSet: tagOptions,
                menuPlacement: 'auto',
                enableSort: false
              }
            }
          ]
        },
        {
          options: {
            grow: 2,
            padding
          },
          contents: [
            {
              component: null
            }
          ]
        }
      ]
    },
    {
      options: {
        grow: 1,
        padding
      },
      contents: [
        {
          component: 'AddNotesView',
          source: 'customerNotes'
        }
      ]
    }
  ];
};

export const NewCustomerLayout = (
  options,
  showBusinessAddress,
  handleShowMore,
  inlineFormMeta = {}
) => {
  const {
    customerTypeOptions,
    taxOptions,
    paymentTermOptions,
    tagOptions,
    invoicePresetOptions,
    priceBookOptions
  } = options;
  const {
    componentArr = [],
    fields: customFields = {},
    validationErrors: customValidationErrors = {},
    validations: customValidations = {}
  } = inlineFormMeta;

  return {
    fields: customFields,
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          {
            options: {
              direction: 'column'
            },
            contents: generalInformationItems(customerTypeOptions, priceBookOptions)
          },
          {
            options: {
              direction: 'column',
              grow: 1
            },
            contents: [
              {
                component: 'StyledSectionDivider'
              }
            ]
          },
          {
            options: {
              direction: 'column'
            },
            contents: billingItems({
              addressType: 'billing',
              taxOptions,
              paymentTermOptions,
              invoicePresetOptions
            })
          },
          {
            options: {
              padding: [0, padding, padding, padding]
            },
            contents: [
              {
                component: 'CheckboxInput',
                source: 'defaultProperty',
                options: {
                  label: 'This address is also a property'
                }
              }
            ]
          },
          {
            options: {
              direction: 'row'
            },
            contents: [
              {
                component: 'AddBusinessAddressBtn'
              }
            ]
          },
          {
            options: {
              direction: 'column',
              flexBasis: 0 // It makes the empty component to have height 0
            },
            contents: showBusinessAddress
              ? [
                  {
                    options: {
                      grow: 1
                    },
                    contents: [
                      {
                        component: 'StyledSectionDivider'
                      }
                    ]
                  },
                  {
                    options: {
                      direction: 'column'
                    },
                    contents: billingItems({
                      addressType: 'business',
                      taxOptions,
                      paymentTermOptions,
                      invoicePresetOptions
                    })
                  }
                ]
              : []
          },
          {
            options: {
              direction: 'column',
              grow: 1
            },
            contents: [
              {
                component: 'StyledSectionDivider'
              }
            ]
          },
          {
            options: {
              direction: 'column'
            },
            contents: otherInformationItems(tagOptions)
          },
          {
            options: {
              direction: 'column',
              margin: -4 // To align inlineForm section with default form sections
            },
            contents: [...componentArr]
          }
        ]
      }
    },
    validation: customValidations,
    validationErrors: customValidationErrors
  };
};
