import React from 'react';

import CircularDeterminate from './CircularDeterminate';
import CircularIndeterminate from './CircularIndeterminate';

/**
 * Returns different spinners based on strategy
 */
const spinner = {
  SLOW: {
    title: 'Circular Determinate Spinner',
    renderSpinner: <CircularDeterminate />
  },
  FAST: {
    title: 'Circular Indeterminate Spinner',
    renderSpinner: <CircularIndeterminate />
  }
};

export default spinner;
