const taskGroupsTitle = ({
  settingsJSON,
  SettingConstants,
  group,
  groupName,
  groupPricing,
  styles,
  fontFamilyStyle
}) =>
  settingsJSON[SettingConstants.TASK_GROUPS][SettingConstants.SHOW_TASK_GROUP_TITLE]
    ? `
    <tr>
      <td style="${styles}width:65%;border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);width:65%;padding:2px;" colspan="3" class="restricted">
        <span fontfamily=${fontFamilyStyle} ><strong>${`${groupName(group.name)}`}</strong></span>
      </td>
      <td style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);text-align:right;padding:2px;" colspan="2" class="restricted">
        <span fontfamily=${fontFamilyStyle} >${groupPricing(
        group.totalAmountOverride ?? group.totalAmount
      )}</span> 
      </td>
    </tr>
  `
    : '';

export default taskGroupsTitle;
