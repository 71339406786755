import { QuoteAddedItem, QuoteStatus } from 'utils/AppConstants';

import { updateQuoteStatusMutation } from '../../service';

class PageHeaderHandlers {
  static async updateQuoteProjectStatus({ projectId, poNumber, serviceProps }) {
    await updateQuoteStatusMutation({
      status: QuoteStatus.PROJECT_ADDED,
      addedItem: QuoteAddedItem.PROJECT,
      projectId,
      poNumber,
      ...serviceProps
    });
  }

  static async updateQuoteNonProjectStatus({ status, statusReason, poNumber, serviceProps }) {
    await updateQuoteStatusMutation({
      status,
      statusReason,
      poNumber,
      ...serviceProps
    });
  }
}

export default PageHeaderHandlers;
