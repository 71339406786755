import React from 'react';

import Divider from '@material-ui/core/Divider';

const CustomDivider = ({ width, padding, backgroundColor = '#E5E5E5' }) => {
  return (
    <div style={{ width, padding }}>
      <Divider style={{ width: '100%', backgroundColor }} />
    </div>
  );
};

export default CustomDivider;
