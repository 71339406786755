import { useCallback } from 'react';

import { useApolloClient } from '@apollo/client';

import { getPricebookById } from 'scenes/Settings/Pricebooks/PricebookDetail/gql';

export const useGetPricebookById = () => {
  const client = useApolloClient();
  return useCallback(
    async pricebookId => {
      const res = await client.query({
        query: getPricebookById,
        variables: { id: pricebookId }
      });
      return res?.data?.getPricebookById;
    },
    [client]
  );
};
