import React from 'react';

import { FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';

import Labels from 'meta/labels';
import { updateSetting, updateUserTenantSetting } from 'redux/actions/globalActions';
import TenantSettingService from 'services/core/graphql-services/TenantSetting/TenantSettingService';
import { PricingStrategy, TenantSetting } from 'utils/constants';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import Context from '../../../../components/Context';
import { Logger } from '../../../../services/Logger';

const useStyles = makeStyles(theme => ({
  header: {
    color: '#00854B'
  },
  optionWrapper: {
    display: 'flex',
    flexDirection: 'row'
  },
  option: {
    color: 'black',
    fontSize: 14
  }
}));

const PricingStrategyOptions = () => {
  const pricingStrategy = useSelector(state =>
    state.user.tenantSettings.find(setting => setting.settingKey === TenantSetting.PRICING_STRATEGY)
  );
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const flags = useFlags();
  const classes = useStyles();

  const PricingStrategyRadioButton = pStrategy => {
    return <Typography className={classes.option}>{Labels[pStrategy][user.locale]}</Typography>;
  };

  const handleContextRefresh = () => Logger.debug('Context is refreshed');

  const refreshCompanyContext = () => {
    Context.setCompanyContext(
      user.tenantId,
      Context.generateCompanyContextSortKey(user),
      handleContextRefresh,
      true
    );
  };

  const pricingStrategyUpdate = async event => {
    try {
      const pricingStrategyRequest = {
        id: pricingStrategy.id,
        settingValue: event.target.value
      };

      const updateTenantSettingResponse = await new TenantSettingService().updateTenantSetting(
        pricingStrategyRequest
      );

      dispatch(updateSetting(updateTenantSettingResponse.data.updateTenantSetting));
      dispatch(updateUserTenantSetting(updateTenantSettingResponse.data.updateTenantSetting));

      refreshCompanyContext();
    } catch (error) {
      console.log('Updating pricingStrategy has failed with the following error: ', error);
    }
  };

  return (
    <>
      <p className={classes.header}>Show Markup/Margins</p>
      <RadioGroup
        className={classes.optionWrapper}
        value={pricingStrategy.settingValue}
        onChange={pricingStrategyUpdate}
      >
        <FormControlLabel
          control={<Radio />}
          label={PricingStrategyRadioButton(PricingStrategy.MARKUP)}
          value={PricingStrategy.MARKUP}
        />
        <FormControlLabel
          control={<Radio />}
          label={PricingStrategyRadioButton(PricingStrategy.MARGIN)}
          value={PricingStrategy.MARGIN}
        />
        {flags[FeatureFlags.MARKUP_AND_MARGIN] && (
          <FormControlLabel
            control={<Radio />}
            label={PricingStrategyRadioButton(PricingStrategy.MARKUP_AND_MARGIN)}
            value={PricingStrategy.MARKUP_AND_MARGIN}
          />
        )}
      </RadioGroup>
    </>
  );
};

export default PricingStrategyOptions;
