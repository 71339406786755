// Handle conditional display of itemized elements for labor/materials
function itemizedElements({ description, shouldDisplayItem, styles, fontFamilyStyle }) {
  return shouldDisplayItem
    ? `<td colspan="2" style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;width:600px;" class="restricted">
            <span fontfamily=${fontFamilyStyle} class="text-small" >${description || ''}</span>
          </td>`
    : `<td style="border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;width:160px;"><span class="text-small"></span></td>
        <td style="border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;"><span class="text-small"></span></td>`;
}

export default itemizedElements;
