import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const deleteAllTemplateGroupsOnServiceAgreement = gql`
  mutation deleteAllTemplateGroupsOnServiceAgreement($serviceAgreementId: String!) {
    deleteAllTemplateGroupsOnServiceAgreement(serviceAgreementId: $serviceAgreementId)
  }
`;

const useDeleteAllTemplateGroupsOnServiceAgreement = ({ serviceAgreementId }) => {
  return useExtendedMutation(deleteAllTemplateGroupsOnServiceAgreement, {
    serializer: () => ({
      variables: {
        serviceAgreementId
      }
    }),
    onError: (error, snackbar) => {
      snackbar('error', 'Unable to delete all template groups on Service Agreement');
    }
  });
};

export default useDeleteAllTemplateGroupsOnServiceAgreement;
