import React, { useEffect, useMemo } from 'react';

import { Divider, Select, Switch, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { useTheme } from '@material-ui/core';
import { useFormik } from 'formik';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import {
  useBulkUpdateLabourRateGroupEntries,
  useBulkUpdatePayrollHourTypes,
  useUpdatePayrollSettings
} from '../../../mutations';

import styles from '../../styles';

import SaveButton from '../SaveButton';

import PayrollHourRatesTable from './PayrollHourRatesTable';
import { useFormattedData } from './PayrollHourRatesTable/PayrollHourRatesTable.hooks';
import PayrollHourTypesTable from './PayrollHourTypesTable';

const getDirtyLabourRateGroupEntries = ({ labourRateGroups, updatedRatesTable }) =>
  labourRateGroups
    .map(labourRateGroup => {
      const labourRateGroupEntries = labourRateGroup?.labourRateGroupEntries?.items || [];
      return labourRateGroupEntries.reduce((result, entry) => {
        const tableLineItem = updatedRatesTable.find(
          item =>
            item.labourRateGroupId === labourRateGroup.id &&
            item.labourTypeId === entry.labourTypeId
        );

        if (
          tableLineItem &&
          tableLineItem[entry.payrollHourType.hourTypeAbbreviation] !== entry.rate
        ) {
          return [
            ...result,
            { ...entry, rate: tableLineItem[entry.payrollHourType.hourTypeAbbreviation] }
          ];
        }
        return result;
      }, []);
    })
    .flat();

const PayrollHourRatesAndTypes = ({
  billingHourTypes,
  payrollHourTypes,
  labourRateGroups,
  labourTypes,
  user,
  payrollSettings,
  setIsDirty,
  isDirty
}) => {
  const flags = useFlags();
  const theme = useTheme();

  const formattedData = useFormattedData({
    payrollHourTypes,
    labourRateGroups,
    labourTypes
  });

  const payrollHourRatesTableFormik = useFormik({
    enableReinitialize: true,
    initialValues: formattedData
  });

  const payrollSettingsFormik = useFormik({
    enableReinitialize: true,
    initialValues: payrollSettings
  });

  const payrollHourTypesFormik = useFormik({
    enableReinitialize: true,
    initialValues: payrollHourTypes
  });

  useEffect(() => {
    setIsDirty(
      payrollHourRatesTableFormik.dirty ||
        payrollSettingsFormik.dirty ||
        payrollHourTypesFormik.dirty
    );
  }, [
    setIsDirty,
    payrollHourRatesTableFormik.dirty,
    payrollSettingsFormik.dirty,
    payrollHourTypesFormik.dirty
  ]);

  const [
    bulkUpdatePayrollHourTypes,
    { loading: updatingPayrollHourTypes }
  ] = useBulkUpdatePayrollHourTypes();

  const [
    bulkUpdateLabourRateGroupEntries,
    { loading: updatingLabourRateGroupEntries }
  ] = useBulkUpdateLabourRateGroupEntries();

  const [updatePayrollSettings, { loading: updatingPayrollSettings }] = useUpdatePayrollSettings();

  const payrollHourTypeOptions = useMemo(
    () =>
      payrollHourTypesFormik.values.map(t => ({
        label: `${t.hourType} (${t.hourTypeAbbreviation})`,
        value: t
      })),
    [payrollHourTypesFormik.values]
  );

  const payrollRegHourTypeSelect = useMemo(
    () => payrollHourTypeOptions.find(o => o.value.hourTypeTag === 'REG'),
    [payrollHourTypeOptions]
  );

  const payrollOTHourTypeSelect = useMemo(
    () => payrollHourTypeOptions.find(o => o.value.hourTypeTag === 'OT'),
    [payrollHourTypeOptions]
  );

  const handlePayrollExportSettingsSelect = async (type, option) => {
    const optionToClear = payrollHourTypeOptions.find(o => o.value.hourTypeTag === type);
    if (optionToClear !== undefined) {
      payrollHourTypesFormik.setFieldValue(`[${optionToClear.value?.sortOrder}].hourTypeTag`, null);
    }

    if (type === 'REG') {
      payrollHourTypesFormik.setFieldValue(`[${option.value?.sortOrder}].hourTypeTag`, 'REG');
    }
    if (type === 'OT') {
      payrollHourTypesFormik.setFieldValue(`[${option.value?.sortOrder}].hourTypeTag`, 'OT');
    }
  };

  return (
    <>
      <ThemeProvider>
        <Typography css={styles.greenHeading} variant={TV.BASE} weight={TW.BOLD}>
          Payroll Hour Rates
        </Typography>
        <PayrollHourRatesTable
          formik={payrollHourRatesTableFormik}
          loading={updatingLabourRateGroupEntries}
          payrollHourTypes={payrollHourTypes}
          tableLength={labourRateGroups.length}
        />
        <Divider />
        <Typography css={styles.greenHeading} variant={TV.BASE} weight={TW.BOLD}>
          Payroll Hour Types
        </Typography>
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant={TV.L} weight={TW.MEDIUM}>
            Map Payroll Hours to Billing Hours
          </Typography>
          <Switch
            defaultChecked={payrollSettingsFormik.values.mapPayrollHourToBilling}
            onChange={async (_, value) => {
              payrollSettingsFormik.setFieldValue('mapPayrollHourToBilling', value);
            }}
          />
        </div>
        <div css={{ display: 'flex', width: 355, marginBottom: theme.spacing(1) }}>
          <Typography color={theme.palette.grayscale(60)} variant={TV.BASE} weight={TW.MEDIUM}>
            Enabling this feature will allow Payroll Hour Types to map to Billing Hour Types.
            Billing hours are automatically filled when Labor Line Items are generated on Job
            Reports.
          </Typography>
        </div>
        <PayrollHourTypesTable
          adpExportEnabled={flags[FeatureFlags.ADP_EXPORT]}
          billingHourTypes={billingHourTypes}
          loading={updatingPayrollHourTypes}
          mapPayrollHourToBilling={payrollSettingsFormik.values.mapPayrollHourToBilling}
          payrollHourTypesFormik={payrollHourTypesFormik}
        />
        {flags[FeatureFlags.ADP_EXPORT] && (
          <>
            <Divider />
            <Typography css={styles.greenHeading} variant={TV.BASE} weight={TW.BOLD}>
              Payroll Export Setting
            </Typography>
            <Select
              defaultValue={payrollRegHourTypeSelect}
              label="Payroll Reg Hour Type"
              options={payrollHourTypeOptions.filter(
                o => o.value.id !== payrollOTHourTypeSelect?.value?.id
              )}
              placeholder="Select payroll hour type"
              style={{ width: 335, marginBottom: 20 }}
              onChange={option => handlePayrollExportSettingsSelect('REG', option)}
            />
            <Select
              defaultValue={payrollOTHourTypeSelect}
              label="Payroll O/T Hour Type"
              options={payrollHourTypeOptions.filter(
                o => o.value.id !== payrollRegHourTypeSelect?.value?.id
              )}
              placeholder="Select payroll hour type"
              style={{ width: 335 }}
              onChange={option => handlePayrollExportSettingsSelect('OT', option)}
            />
          </>
        )}
      </ThemeProvider>
      <SaveButton
        disabled={!isDirty}
        loading={
          updatingPayrollHourTypes || updatingLabourRateGroupEntries || updatingPayrollSettings
        }
        onClick={async () => {
          if (payrollHourRatesTableFormik.dirty) {
            const labourRateGroupEntriesToUpdate = getDirtyLabourRateGroupEntries({
              labourRateGroups,
              updatedRatesTable: payrollHourRatesTableFormik.values
            });
            bulkUpdateLabourRateGroupEntries({
              labourRateGroupEntries: labourRateGroupEntriesToUpdate
            });
          }

          if (payrollSettingsFormik.dirty) {
            updatePayrollSettings({
              partitionKey: user?.tenantId,
              formData: payrollSettingsFormik.values
            });
          }

          if (payrollHourTypesFormik.dirty) {
            bulkUpdatePayrollHourTypes({
              companyId: user?.tenantCompanyId,
              payrollHourTypes: payrollHourTypesFormik.values
            });
          }
        }}
      />
    </>
  );
};

export default PayrollHourRatesAndTypes;
