import { useCallback } from 'react';

import gql from 'graphql-tag';

import { useSelector } from 'react-redux';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { getTaskTemplateCheckLists } from './useChecklistLibrary';

const DELETE_TASK_TEMPLATE_CHECKLIST = gql`
  mutation deleteTaskTemplateChecklists($input: [String!]!) {
    deleteTaskTemplateChecklists(input: $input)
  }
`;

export const useDeleteTaskTemplateChecklist = ({ assetTypeId = 'null', ...options }) => {
  const user = useSelector(state => state.user);
  const update = useCallback(
    (cache, { data: { deleteTaskTemplateChecklists } }) => {
      const variables = {
        tenantId: user.tenantId,
        assetTypeId
      };

      const cachedData = cache.readQuery({
        query: getTaskTemplateCheckLists,
        variables
      });

      const existingData = cachedData?.taskTemplateChecklists || [];
      const updatedChecklists = existingData.filter(
        item => !deleteTaskTemplateChecklists.includes(item.id)
      );
      cache.writeQuery({
        query: getTaskTemplateCheckLists,
        variables,
        data: { taskTemplateChecklists: updatedChecklists }
      });
    },
    [assetTypeId, user.tenantId]
  );

  return useExtendedMutation(DELETE_TASK_TEMPLATE_CHECKLIST, {
    serializer: ({ id }) => ({
      variables: {
        input: [id]
      }
    }),
    update,
    onSuccess: (transformedData, snackbarOn) => snackbarOn('success', 'Checklist deleted'),
    onError: (transformedData, snackbarOn) => snackbarOn('error', 'Unable to Delete Checklist'),
    ...options
  });
};
