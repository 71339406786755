// TODO write test to keep fragment and empty fragment in sync. Too easy to break.
export const nonVisitDetailsFragment = `
  id
  description
  assignedEntityId
  departmentId
  employeeId
  entityType
  eventTypeId
  isActive
  name
  payrollHourTypeId
  plannedEndTimeUTC
  plannedStartTimeUTC
  status
  version
  timesheetEntryBinders {
    items {
      id
      manualStatus
    }
  }
`;

export const emptyNonVisitDetailsFragment = {
  __typename: 'NonVisitEvent',
  id: 'optimistic-non-visit',
  assignedEntityId: null,
  departmentId: null,
  description: null,
  employeeId: 'optimistic-employee-id',
  entityType: 'NonVisitEvent',
  eventTypeId: 'optimistic-event-type-id',
  isActive: null,
  name: null,
  payrollHourTypeId: null,
  plannedEndTimeUTC: null,
  plannedStartTimeUTC: null,
  status: null,
  version: null,
  timesheetEntryBinders: {
    items: []
  }
};
