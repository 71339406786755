import React from 'react';

import PropTypes from 'prop-types';

import { SergeantModal } from 'components';
import PlacesSearch from 'components/BuildHeroFormComponents/PlacesSearch';
import { EditAddressForm } from 'meta/Customer/CustomerDetail/index';
import { capitalizeFirstLetter } from 'utils';
import { ADDRESS_FIELDS, Mode } from 'utils/constants';

export const EditAddressModal = ({
  data,
  dataType,
  editAddressType,
  open,
  onClose,
  onComplete,
  taxOptions
}) => {
  const addressData = {};
  ADDRESS_FIELDS.forEach(key => {
    addressData[key] = data[editAddressType + capitalizeFirstLetter(key)];
  });
  return (
    <SergeantModal
      customComponents={{
        PlacesSearch
      }}
      data={{ ...data, ...addressData }}
      dataType={dataType}
      handleClose={onClose}
      handlePrimaryAction={async (completed, stopLoading) => {
        const processedData = completed;
        ADDRESS_FIELDS.forEach(key => {
          processedData[editAddressType + capitalizeFirstLetter(key)] = completed[key];
        });
        await onComplete(processedData, false);
        onClose();
        stopLoading();
      }}
      layout={EditAddressForm(editAddressType, taxOptions)}
      mode={Mode.EDIT}
      open={open}
    />
  );
};

EditAddressModal.propTypes = {
  data: PropTypes.object.isRequired,
  dataType: PropTypes.string.isRequired,
  editAddressType: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  taxOptions: PropTypes.array
};
EditAddressModal.defaultProps = {
  taxOptions: null
};
