import { CommonService } from 'services/core';
import { Logger } from 'services/Logger';

import { CREWS } from './queries';

// eslint-disable-next-line import/prefer-default-export
export const getAllCrews = async (filter, limit, offset, sortBy, sortOrder) => {
  try {
    const queryParams = {
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    const commonService = new CommonService();
    const response = await commonService.getQuery(queryParams, CREWS);
    const { items = [], nextToken = null } = response?.data?.crews;

    const processedCrews = items.map(crew => ({
      ...crew,
      departmentName: crew.department?.tagName,
      foremanName: crew.foreman?.name,
      numTechs: crew.techs?.length || 0,
      techNames: crew.techs?.map(({ name }) => name).join(', ')
    }));
    return { items: processedCrews, nextToken };
  } catch (error) {
    Logger.error(error);
  }
};
