import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function Jobs(props) {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SvgIcon {...props}>
      <path d="M12 1C18.0751 1 23 5.92487 23 12C23 17.2399 19.3363 21.624 14.4307 22.7305L14.4262 19.5313H14.4047C14.4047 19.5313 15.0383 15.571 18.4446 12.1028C18.4446 12.1029 18.4516 12.0969 18.4642 12.0848L18.4961 12.0523C18.7702 11.7596 19.9503 10.1754 17.3423 6.77179C17.3423 6.77179 15.1003 4.3746 13.8907 4L13.2656 4.31939L14.6979 8.83356C14.6979 8.83356 12.7215 12.4145 9.07973 10.5753L7.39041 5.81244L6.58256 6.34554C6.58256 6.34554 2.43226 12.4584 7.57382 15.5508L7.60182 15.57C7.84773 15.7432 9.63057 17.0828 9.63057 19.5319L9.63405 22.7449C4.69603 21.6626 1 17.2629 1 12C1 5.92487 5.92487 1 12 1Z" />
    </SvgIcon>
  );
}
