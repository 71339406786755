import { useRef, useState } from 'react';

export const useFormService = () => {
  const [isDirty, setIsDirty] = useState(false);
  const formService = useRef();

  const onCreateService = service => {
    formService.current = service;
  };

  const onDirtyChange = dirty => {
    setIsDirty(dirty);
  };

  const resetForm = () => {
    formService.current.reset();
  };

  const submitForm = () => {
    formService.current?.submit();
  };

  return {
    formService,
    isDirty,
    onCreateService,
    onDirtyChange,
    resetForm,
    submitForm
  };
};
