import React from 'react';

import Placeholder from 'components/Placeholder';

// This small, utility HOC expects a component that changes appearance based on
// an asynchronous data fetch. It's mainly useful in reducing the number of boilerplate
// ternary loading expressions in the returned JSX of components.
function withLoading(WrappedComponent) {
  return function WithLoading(props) {
    const { isLoading, loadingParams, ...rest } = props;
    return isLoading ? <Placeholder {...loadingParams} /> : <WrappedComponent {...rest} />;
  };
}

export default withLoading;
