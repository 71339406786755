import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';

import Spinner from 'components/Spinners/CircularIndeterminate';
import { snackbarOn, spinnerOff, spinnerOn } from 'redux/actions/globalActions';
import { Logger } from 'services/Logger';

/**
 * Multi row query components utilizes React Apollo query component
 * generates children components for each row of data
 *
 *
 * props:
 *  Query - Graphql query, all queries must be wrapped with gql tag
 *  variable - parameter / filter criterias for the query
 *  client - ONLY for Development purpose, the client should be taken from React context using apollo provider
 */

class MultiRowQuery extends Component {
  render() {
    const { client, query, variable, preProcessFunction } = this.props;
    let queryData = '';
    // this.props.spinnerOn();

    return (
      true && (
        <Query
          client={client}
          errorPolicy="all"
          fetchPolicy="network-only"
          query={query}
          variables={variable}
        >
          {({ loading, error, data, refetch }) => {
            if (loading) {
              return <Spinner />;
            }
            if (error) {
              //    this.props.spinnerOff();
              //  this.props.snackbarOn(error);
              Logger.error(`Error in MultiRowQuery ${JSON.stringify(error)}`);
              const { graphQLErrors, networkError } = error;
              if (graphQLErrors) {
                if (graphQLErrors[0] && graphQLErrors[0].errorType === 'Unauthorized') {
                  this.props.snackbarOn('error', 'You are not authorized to access data');
                } else {
                  this.props.snackbarOn('error', 'Unable to fetch data, please try again later');
                }
              }
              if (networkError) {
                this.props.snackbarOn('error', 'Network error, please contact system admin');
              }

              return null;
            }

            queryData = data;
            if (preProcessFunction && data) {
              queryData = preProcessFunction(data);
            }
            const childrenWithData = React.Children.map(this.props.children, child =>
              React.cloneElement(child, {
                queryResult: queryData,
                loading,
                error,
                refetch
              })
            );

            // render props expects a function that returns a ReactElement
            return <>{childrenWithData}</>;
          }}
        </Query>
      )
    );
  }
}

// export default MultiRowQuery;

// utilizes global spinner actions
const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message)),
  spinnerOn: () => dispatch(spinnerOn()),
  spinnerOff: () => dispatch(spinnerOff())
});
MultiRowQuery.propTypes = {
  query: PropTypes.object.isRequired
};

export default connect(null, mapDispatchToProps)(MultiRowQuery);
