import gql from 'graphql-tag';

export const getRateCardQuery = gql`
  query rateCard($partitionKey: String, $id: String) {
    serviceAgreement(partitionKey: $partitionKey, id: $id) {
      rateCardJSON
    }
  }
`;

export const getServiceAgreementsQuery = gql`
  query serviceAgreements(
    $companyId: String
    $customerId: String
    $pagination: PaginationInput
    $sorting: [SortingInput]
  ) {
    serviceAgreements(
      companyId: $companyId
      customerId: $customerId
      pagination: $pagination
      sorting: $sorting
    ) {
      items {
        id
        status
        agreementName
        agreementNumber
        pricebookId
        customerId
        customer {
          customerName
          entityType
        }
        propertiesJSON
      }
    }
  }
`;

export const getActiveServiceAgreementsForCustomerQuery = gql`
  query getCompany($partitionKey: String!, $sortKey: String!, $customerId: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      serviceAgreements(
        entityConnection: "ServiceAgreement"
        filter: {
          stringFilters: [
            { fieldName: "ServiceAgreement.customerId", filterInput: { eq: $customerId } }
            { fieldName: "ServiceAgreement.status", filterInput: { eq: "Active" } }
          ]
        }
      ) {
        items {
          id
          status
          agreementName
          agreementNumber
          pricebookId
          customerId
          customer {
            customerName
            entityType
          }
          propertiesJSON
        }
      }
    }
  }
`;

export const getServiceAgreementByIdQuery = gql`
  query serviceAgreementId($partitionKey: String, $id: String) {
    serviceAgreement(partitionKey: $partitionKey, id: $id) {
      id
      tenantId
      status
      isRenewed
      advancedScheduling
      advancedSchedulingState
      agreementName
      agreementNumber
      startDate
      endDate
      renewalDate
      renewalNote
      version
      paymentTerms
      annualContractValue
      totalContractValue
      termPrice
      firstBillDate
      nextBillDate
      lastBilledDate
      customerPoNumber
      isActive
      markup
      pricebookId
      departmentId
      invoiceCount
      soldById
      soldBy {
        id
        name
        firstName
        lastName
      }
      department {
        id
        tagName
      }
      attachments {
        items {
          id
          type
          fileName
          comment
          fileUrl
          customFileName
          version
          createdBy
          createdDate
          createdDateTime
          lastUpdatedBy
          lastUpdatedDateTime
        }
      }
      contractPdfUrl
      customerId
      customer {
        id
        customerName
        tenantId
        tenantCompanyId
        sortKey
        entityType
        hierarchy
        taxRateId
        isTaxable
        taxRate {
          name
          taxRate
        }
        companyAddresses {
          items {
            billTo
            addressLine1
            addressLine2
            city
            zipcode
            state
            addressType
          }
        }
        customerPropertiesView(
          entityConnection: "CustomerProperty"
          filter: { booleanFilters: [{ fieldName: "isActive", filterInput: { ne: false } }] }
        ) {
          items {
            id
            companyName
          }
        }
      }
      propertiesJSON
      preferredTechnicians {
        departmentId
        crewId
        primaryTechnicianId
        additionalTechnicianIds
      }
      billingType
      billingCustomerId
      billingCustomer {
        id
        customerName
        companyAddresses {
          items {
            billTo
            addressLine1
            addressLine2
            city
            zipcode
            state
            addressType
          }
        }
      }
      serviceAgreementServiceAgreementTags {
        id
        serviceAgreementTag {
          id
          tagName
        }
      }
      upcomingMaintenanceJobs {
        earliestDueDate
        latestDueDate
        number
        nextStartDate
        nextGenerationDate
      }
      addingMaintenanceJobsStatus
    }
  }
`;

export const transitionServiceAgreementMutation = gql`
  mutation serviceAgreementTransition(
    $partitionKey: String
    $action: ServiceAgreementActions!
    $data: ServiceAgreementUpdateInput!
  ) {
    serviceAgreementTransition(partitionKey: $partitionKey, action: $action, data: $data) {
      id
      status
      agreementName
      version
      addingMaintenanceJobsStatus
    }
  }
`;

export const updateServiceAgreementMutation = gql`
  mutation updateServiceAgreement($data: ServiceAgreementUpdateInput!) {
    updateServiceAgreement(input: $data) {
      id
      status
      version
      rateCardJSON
      preferredTechnicians {
        departmentId
        crewId
        primaryTechnicianId
        additionalTechnicianIds
      }
    }
  }
`;

export const addAttachmentToServiceAgreementMutation = gql`
  mutation addAttachmentsToServiceAgreement($data: AddAttachmentsToServiceAgreementInput!) {
    addAttachmentsToServiceAgreement(data: $data) {
      id
      fileName
      fileUrl
      fileSize
      customFileName
      comment
      version
      createdBy
      createdDate
      lastUpdatedDate
      lastUpdatedDateTime
      lastUpdatedBy
    }
  }
`;

export const addNoteToServiceAgreementMutation = gql`
  mutation addNotesToServiceAgreement($data: AddNotesToServiceAgreementInput!) {
    addNotesToServiceAgreement(data: $data) {
      id
      subject
      note
      version
      createdBy
      lastUpdatedDate
      lastUpdatedDateTime
      lastUpdatedBy
    }
  }
`;

export const getServiceAgreementAttachmentsAndNotesQuery = gql`
  query serviceAgreementId($partitionKey: String, $id: String) {
    serviceAgreement(partitionKey: $partitionKey, id: $id) {
      id
      renewalGroupId
      isNotesAndAttachmentCopied
      attachments {
        items {
          id
          type
          fileName
          comment
          fileUrl
          customFileName
          version
          createdBy
          createdDate
          createdDateTime
          lastUpdatedBy
          lastUpdatedDateTime
        }
      }
      notes {
        items {
          id
          note
          subject
          version
          createdBy
          createdDateTime
          lastUpdatedBy
          lastUpdatedDateTime
        }
      }
    }
  }
`;

export const copyNotesToRenewedServiceAgreementMutation = gql`
  mutation copyNotesToRenewedServiceAgreement($id: String!, $skipNoteAndAttachment: Boolean) {
    copyNotesToRenewedServiceAgreement(id: $id, skipNoteAndAttachment: $skipNoteAndAttachment) {
      id
      agreementNumber
      isNotesAndAttachmentCopied
    }
  }
`;

export default getServiceAgreementByIdQuery;
