/* eslint-disable no-nested-ternary */
import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import People from '@material-ui/icons/People';
import PeopleOutline from '@material-ui/icons/PeopleOutline';
import Person from '@material-ui/icons/Person';
import PersonOutline from '@material-ui/icons/PersonOutline';
import { bool, object, string } from 'prop-types';
import Skeleton from 'react-loading-skeleton';

import { VisitStatuses } from '@dispatch/Dispatch.constants';
import { VISIT_DETAILS_PROP } from '@dispatch/queries';
import { useDispatchSettings } from '@dispatch/settings';

import { useStyles } from './StatusHeader.styles';

const VisibilityToTechIcon = ({ icon, text, released }) => {
  const styles = useStyles();

  return (
    <Tooltip title={text}>
      <img alt={text} css={released ? styles.iconReleased : styles.iconUnreleased} src={icon} />
    </Tooltip>
  );
};

VisibilityToTechIcon.propTypes = {
  icon: object.isRequired,
  text: string.isRequired,
  released: bool
};

VisibilityToTechIcon.defaultProps = {
  released: false
};

const getHeaderColorAndTitle = (releaseToTechEnabled, visit) => {
  const status = VisitStatuses.get(visit.status)?.value || {};
  let headerTitle = status.displayValue || 'unknown';
  let headerBgColor = status.primaryColor;

  if (visit.status === VisitStatuses.SCHEDULED.key && releaseToTechEnabled) {
    if (visit.detailsSent) {
      headerBgColor = 'black';
    } else {
      headerTitle = `${headerTitle} (Unreleased)`;
    }
  }

  return {
    headerTitle,
    headerBgColor
  };
};

const StatusHeader = ({ visit, loading }) => {
  const styles = useStyles();
  const { releaseToTechEnabled } = useDispatchSettings();

  if (loading) {
    return (
      <div css={styles.skeleton}>
        <Skeleton />
      </div>
    );
  }

  const { job, primaryTechId, extraTechs, extraTechsNumber } = visit;
  const { headerTitle, headerBgColor } = getHeaderColorAndTitle(releaseToTechEnabled, visit);

  return (
    <div css={styles.root}>
      {job?.priority && <div css={styles.priority}>{job?.priority}</div>}
      <div css={styles.status} style={{ background: headerBgColor }}>
        <div css={styles.leftContent}>
          <p style={{ color: 'white', margin: 0, marginTop: -2 }}>{headerTitle}</p>
        </div>
        <div css={styles.rightContent}>
          {extraTechs?.length || extraTechsNumber ? (
            extraTechsNumber > extraTechs?.length ? (
              <PeopleOutline css={styles.iconTech} />
            ) : (
              <People css={styles.iconTech} />
            )
          ) : primaryTechId ? (
            <Person css={styles.iconTech} />
          ) : (
            <PersonOutline css={styles.iconTech} />
          )}
        </div>
      </div>
    </div>
  );
};

StatusHeader.propTypes = {
  loading: bool.isRequired,
  visit: VISIT_DETAILS_PROP.isRequired
};

export default StatusHeader;
