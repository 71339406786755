import React, { useEffect, useMemo, useState } from 'react';

import { Divider, Button as SergeantButton, ThemeProvider, Typography } from '@BuildHero/sergeant';
import { isEmpty, noop } from 'lodash';

import { ConfirmLeave, SergeantModal } from 'components';
import Placeholder from 'components/Placeholder';
import { timecardOpenStatusTypes, TimeCardStatusTypes } from 'utils/AppConstants';

import { timesheetViews } from '../constants';
import {
  useTimesheetPeriod,
  useUpdateTimesheetEntryBinders,
  useUpdateTimesheetEntryHours,
  useUpdateTimesheetManualStatus
} from '../customHooks';

import DateNavigation from './components/DateNavigation';
import { DayCard } from './components/DayCard';
import TabFooter from './components/TabFooter';
import { generateWeekDataFromBinder, showDivider, startDayToday } from './helpers';
import requestReasonMetaLayout from './RequestReasonMeta';

const useStyles = () => ({
  outerContainer: {
    minHeight: 500
  },
  visitsSection: {
    marginTop: 36
  },
  flexButtons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  approvalButtonsTopline: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '25%'
  }
});

const getApproveButtonLabel = workEvents =>
  workEvents.filter(e => e.binderStatus === TimeCardStatusTypes.PENDING).length
    ? 'Submit & Approve'
    : 'Approve';

const ToReview = ({
  selectedEmployee,
  payrollHourTypes,
  timesheetPeriods = [],
  payrollSetting,
  snackbarOn,
  setExportSelectedDate,
  onUpdateDayCard = noop,
  setIsExportDisabled = noop,
  setDirty = noop,
  selectedDate = null,
  setSelectedDate = noop,
  selectedPeriod,
  setSelectedPeriod = noop,
  dayOrWeek = timesheetViews.DAY,
  setDayOrWeek = noop
}) => {
  const styles = useStyles();

  const [timesheetEntriesToUpdate, setTimesheetEntriesToUpdate] = useState([]);
  const [timesheetEntryBindersToUpdate, setTimesheetEntryBindersToUpdate] = useState([]);
  const [isRequestReasonModalOpen, setIsRequestReasonModalOpen] = useState(false);
  const [dismissedBinderMap, setDismissedBinderMap] = useState({ dismissed: [], undismissed: [] });
  const [currentDayIndex, setCurrentDayIndex] = useState();
  const [updatingStatus, setUpdatingStatus] = useState();

  const [period, isLoading, , refetch] = useTimesheetPeriod({ id: selectedPeriod?.id });

  const weekDates = useMemo(() => {
    if (isEmpty(period)) return [];

    const {
      timesheetEntryBinders: { items: timesheetEntryBinders }
    } = period;

    return generateWeekDataFromBinder(
      timesheetEntryBinders,
      period,
      payrollSetting.timeZone,
      payrollHourTypes,
      timecardOpenStatusTypes,
      timesheetEntriesToUpdate,
      dismissedBinderMap
    );
  }, [
    period,
    payrollSetting.timeZone,
    payrollHourTypes,
    timesheetEntriesToUpdate,
    dismissedBinderMap
  ]);

  const weekDate = useMemo(
    () =>
      weekDates.find(
        ({ dayStartUTC, dayEndUTC }) => dayStartUTC <= selectedDate && selectedDate <= dayEndUTC
      ) || {},
    [selectedDate, weekDates]
  );

  const [
    updateTimesheetEntryBinders,
    { loading: updateBindersLoading }
  ] = useUpdateTimesheetEntryBinders();
  const [updateTimesheetEntryHours, { loading: updateLoading }] = useUpdateTimesheetEntryHours();
  const [
    updateTimesheetManualStatus,
    { loading: updateStatusLoading }
  ] = useUpdateTimesheetManualStatus();

  useEffect(() => {
    setDirty(timesheetEntriesToUpdate.length + timesheetEntryBindersToUpdate.length);
  }, [timesheetEntriesToUpdate.length, timesheetEntryBindersToUpdate.length]);

  const updateTimesheetEntries = () => {
    updateTimesheetEntryHours({
      employee: selectedEmployee,
      timesheetEntries: timesheetEntriesToUpdate
    }).then(() => {
      setTimesheetEntriesToUpdate([]);
    });

    updateTimesheetEntryBinders({
      employee: selectedEmployee,
      timesheetEntryBinders: timesheetEntryBindersToUpdate
    }).then(() => {
      setTimesheetEntryBindersToUpdate([]);
    });
  };

  const queueTimesheetEntriesToUpdate = entry => {
    const unsavedTimesheetEntryIndex = timesheetEntriesToUpdate.findIndex(b => b.id === entry.id);
    if (unsavedTimesheetEntryIndex === -1) {
      setTimesheetEntriesToUpdate([...timesheetEntriesToUpdate, { ...entry }]);
    } else {
      const newArr = [...timesheetEntriesToUpdate];
      newArr[unsavedTimesheetEntryIndex] = { ...newArr[unsavedTimesheetEntryIndex], ...entry };
      setTimesheetEntriesToUpdate(newArr);
    }
  };

  const queueTimesheetEntryBindersToUpdate = binder => {
    const unsavedTimesheetEntryBinderIndex = timesheetEntryBindersToUpdate.findIndex(
      b => b.id === binder.id
    );
    if (unsavedTimesheetEntryBinderIndex === -1) {
      setTimesheetEntryBindersToUpdate([...timesheetEntryBindersToUpdate, { ...binder }]);
    } else {
      const newArr = [...timesheetEntryBindersToUpdate];
      newArr[unsavedTimesheetEntryBinderIndex] = {
        ...newArr[unsavedTimesheetEntryBinderIndex],
        ...binder
      };
      setTimesheetEntryBindersToUpdate(newArr);
    }
  };

  const isSaveEditsDisabled =
    isLoading === 'saveEdits' ||
    (timesheetEntriesToUpdate.length === 0 && timesheetEntryBindersToUpdate.length === 0);

  const handleApprove = startDate => {
    if (!isSaveEditsDisabled) return snackbarOn('error', 'Please save your edits before approving');
    setUpdatingStatus(TimeCardStatusTypes.APPROVED);

    updateTimesheetManualStatus({
      employeeId: selectedEmployee.id,
      startDate,
      manualStatus: TimeCardStatusTypes.APPROVED
    }).then(() => {
      onUpdateDayCard();
    });
  };

  const handleRequestRevision = ({ reason }) => {
    setUpdatingStatus(TimeCardStatusTypes.DISPUTED);
    setIsRequestReasonModalOpen(false);
    const updateData = dayOrWeek === timesheetViews.DAY ? weekDate : weekDates[currentDayIndex];

    updateTimesheetManualStatus({
      employeeId: selectedEmployee.id,
      startDate: updateData.dayStartUTC,
      manualStatus: TimeCardStatusTypes.DISPUTED,
      reason
    }).then(() => {
      onUpdateDayCard();
    });
  };

  const noTimesheetsMessage = 'No timesheets submitted for review';

  const dayCardProps = {
    isEditable: true,
    tab: 'toReview',
    payrollHourTypes,
    updateTimesheetEntry: queueTimesheetEntriesToUpdate,
    updateTimesheetEntryBinder: queueTimesheetEntryBindersToUpdate,
    payrollSetting,
    tenantId: selectedEmployee.tenantId,
    refetchTimesheetPeriod: refetch,
    employeeId: selectedEmployee.id,
    setDismissedBinderMap,
    onUpdateDayCard,
    canDismiss: true,
    areBindersEnabled: true
  };

  if (isLoading) return <Placeholder variant="table" />;

  return (
    <div css={styles.outerContainer}>
      <ConfirmLeave
        when={timesheetEntriesToUpdate.length !== 0 && timesheetEntryBindersToUpdate.length !== 0}
      />
      <DateNavigation
        availableStatuses={timecardOpenStatusTypes}
        dayOrWeek={dayOrWeek}
        payrollSetting={payrollSetting}
        selectedDate={selectedDate}
        selectedEmployee={selectedEmployee}
        selectedPeriod={selectedPeriod}
        setDayOrWeek={setDayOrWeek}
        setExportSelectedDate={setExportSelectedDate}
        setIsExportDisabled={setIsExportDisabled}
        setSelectedDate={setSelectedDate}
        setSelectedPeriod={setSelectedPeriod}
        setTimesheetEntriesToUpdate={setTimesheetEntriesToUpdate}
        setTimesheetEntryBindersToUpdate={setTimesheetEntryBindersToUpdate}
        snackbarOn={snackbarOn}
        timesheetPeriods={timesheetPeriods}
        weekDate={weekDate}
        weekDates={weekDates}
      />

      <ThemeProvider>
        {dayOrWeek === timesheetViews.DAY && (
          <div css={styles.visitsSection}>
            {!isEmpty(weekDate) &&
            (weekDate.workEvents.length || weekDate.dismissedBinders.length) ? (
              <DayCard day={weekDate} {...dayCardProps} />
            ) : (
              <>{selectedDate && <Typography>{noTimesheetsMessage}</Typography>}</>
            )}
          </div>
        )}
        {dayOrWeek === timesheetViews.WEEK && (
          <div css={styles.visitsSection}>
            {weekDates.map((day, i) => {
              // do not show future timesheets
              if (day.dayStartUTC > startDayToday(payrollSetting.timeZone)) return null;
              return (
                <>
                  <DayCard day={day} key={day.dayStartUTC} {...dayCardProps}>
                    <div css={styles.approvalButtonsTopline}>
                      <SergeantButton
                        disabled={!day.workEvents.length}
                        loading={
                          updateStatusLoading &&
                          currentDayIndex === i &&
                          updatingStatus === TimeCardStatusTypes.APPROVED
                        }
                        type="tertiary"
                        onClick={() => {
                          setCurrentDayIndex(i);
                          handleApprove(day.dayStartUTC);
                        }} // TODO: add disable logic
                      >
                        {getApproveButtonLabel(day.workEvents)}
                      </SergeantButton>
                      <SergeantButton
                        disabled={!day.workEvents.length}
                        loading={
                          !isRequestReasonModalOpen &&
                          updateStatusLoading &&
                          currentDayIndex === i &&
                          updatingStatus === TimeCardStatusTypes.DISPUTED
                        }
                        style={{ marginLeft: 24 }}
                        type="tertiary"
                        onClick={() => {
                          setCurrentDayIndex(i);
                          setIsRequestReasonModalOpen(true);
                        }} // TODO: add disable logic
                      >
                        Request Revision
                      </SergeantButton>
                    </div>
                  </DayCard>
                  {showDivider(i, day, weekDates) ? <Divider /> : null}
                </>
              );
            })}
            {selectedPeriod.id &&
              weekDates.every(w => !w.workEvents.length) &&
              weekDates.every(w => !w.dismissedBinders.length) && (
                <Typography>{noTimesheetsMessage}</Typography>
              )}
          </div>
        )}
        {((!isEmpty(weekDate) && dayOrWeek === timesheetViews.DAY) ||
          (dayOrWeek === timesheetViews.WEEK && weekDates?.some(w => w.workEvents.length > 0))) && (
          <TabFooter
            dayOrWeek={dayOrWeek}
            payrollHourTypes={payrollHourTypes}
            weekDate={weekDate}
            weekDates={weekDates}
          >
            <div css={styles.flexButtons}>
              <SergeantButton
                disabled={isSaveEditsDisabled}
                loading={updateLoading || updateBindersLoading}
                type="secondary"
                onClick={updateTimesheetEntries}
              >
                Save Edits
              </SergeantButton>
              {dayOrWeek === timesheetViews.DAY && (
                <>
                  <SergeantButton
                    disabled={!weekDate.workEvents.length}
                    loading={updateStatusLoading && updatingStatus === TimeCardStatusTypes.APPROVED}
                    style={{ marginLeft: 24 }}
                    type="tertiary"
                    onClick={() => handleApprove(weekDate.dayStartUTC)} // TODO: add disable logic
                  >
                    {getApproveButtonLabel(weekDate.workEvents)}
                  </SergeantButton>
                  <SergeantButton
                    disabled={!weekDate.workEvents.length}
                    loading={
                      !isRequestReasonModalOpen &&
                      updateStatusLoading &&
                      updatingStatus === TimeCardStatusTypes.DISPUTED
                    }
                    style={{ marginLeft: 24 }}
                    type="tertiary"
                    onClick={() => {
                      setIsRequestReasonModalOpen(true);
                    }} // TODO: add disable logic
                  >
                    Request Revision
                  </SergeantButton>
                </>
              )}
            </div>
          </TabFooter>
        )}
      </ThemeProvider>

      <SergeantModal
        handleClose={() => setIsRequestReasonModalOpen(false)}
        handlePrimaryAction={handleRequestRevision}
        layout={requestReasonMetaLayout}
        open={isRequestReasonModalOpen}
        title="Request Revision"
      />
    </div>
  );
};

export default ToReview;
