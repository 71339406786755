/* eslint-disable no-tabs */
import React from 'react';

import { SvgIcon } from '@material-ui/core';

const CompleteIcon = props => (
  <SvgIcon {...props}>
    <path
      className="st0"
      d="M13.5,14.2c1.8-2.1,4.7-2.8,7.3-1.6c0.3,0.1,0.4,0.5,0.3,0.7c-0.1,0.3-0.5,0.4-0.7,0.3
					c-2.1-0.9-4.5-0.4-5.9,1.3s-1.6,4.2-0.4,6.1s3.5,2.7,5.7,2.1c2.2-0.6,3.6-2.6,3.6-4.9l0,0v-0.5c0-0.3,0.3-0.6,0.6-0.6
					c0.3,0,0.6,0.3,0.6,0.6l0,0v0.5c0,2.8-1.8,5.2-4.5,6s-5.5-0.3-7-2.6S11.7,16.3,13.5,14.2z M24,12.7c0.2-0.2,0.6-0.2,0.8,0
					s0.2,0.6,0,0.8l0,0l-6.2,6.2c-0.2,0.2-0.6,0.2-0.8,0l0,0l-1.7-1.7c-0.2-0.2-0.2-0.6,0-0.8s0.6-0.2,0.8,0l0,0l1.3,1.3L24,12.7z"
    />
  </SvgIcon>
);

export default CompleteIcon;
