export const useStyles = ({ isEmptyState }) => ({
  jobReportEmptyStateWrapper: theme => ({
    display: 'flex',
    flexDirection: 'column',
    border: `1px dashed ${theme.palette.grayscale(80)}`,
    borderRadius: 4,
    padding: '41px 180px 41px 180px',
    backgroundColor: '#FAFAFA',
    alignItems: 'center'
  }),
  jobReportsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: isEmptyState ? 12 : 0
  },
  moreButton: theme => ({ padding: 0, fontSize: 24, color: theme.palette.grayscale(50) })
});
