import React, { Component } from 'react';

import { PDFComponents, PDFForm, ThemeProvider } from '@BuildHero/sergeant';
import { PDFViewer } from '@react-pdf/renderer';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';
import moment from 'moment';
import * as R from 'ramda';
import { connect } from 'react-redux';

import { Spinner } from 'components';
import Attachments from 'components/BuildHeroFormComponents/pdf-components/JobReport/attachments';
import PurchasedView from 'components/BuildHeroFormComponents/pdf-components/JobReport/purchasedView';
import VisitSummary from 'components/BuildHeroFormComponents/pdf-components/JobReport/VisitSummary';
import Context from 'components/Context';
import { snackbarOn } from 'redux/actions/globalActions';
import AmplifyService from 'services/AmplifyService';
import { JobService } from 'services/core';

import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';
import { grayscalePdf } from 'themes/BuildHeroTheme';
import {
  capitalizeFirstLetter,
  convertToCurrencyString,
  formatAddress,
  getFileExtension,
  getGuardedFileName,
  isCloudinaryImageType
} from 'utils';
import { AppConstants } from 'utils/AppConstants';

import { formatLongTextForPDF } from 'utils/PDFFormattingUtils';

import { SignaturesPDF } from './CustomerSignaturePDF';
import { formatJobTaskForJobReport } from './helpers';
import { PDFDocumentLayout as PDFDocumentLayoutV1 } from './JobPDFDocumentLayoutV1';
import { PDFDocumentLayout as PDFDocumentLayoutV2 } from './JobPDFDocumentLayoutV2';
import { SignatureScreen } from './SignaturePDF';

const JOB_FORMS_TITLE = 'Job Forms';
const TASK_FORMS_TITLE = 'Task Forms';

function getFormHeader(name, isFirst, formsTitle) {
  const alignment = { marginLeft: 40, marginRight: 40, marginBottom: 20 };
  const pageContent = {
    options: {
      pageSize: 'LETTER',
      pageFooter: ({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`
    },
    contents: [
      {
        options: alignment,
        contents: [
          {
            options: {
              fontSize: 14,
              fontWeight: 'bold',
              marginBottom: 10
            },
            component: 'BodyText',
            source: name
          }
        ]
      }
    ]
  };
  if (isFirst) {
    pageContent.contents[0].contents.splice(
      0,
      0,
      {
        options: {
          fontSize: 18,
          fontWeight: 'bold',
          marginBottom: 10
        },
        component: 'BodyText',
        source: formsTitle
      },
      {
        options: {
          color: grayscalePdf(60),
          marginBottom: 20
        },
        component: {
          default: 'Divider'
        }
      }
    );
  }
  return pageContent;
}

const SgtConverter = ({ form, field, options, style }) => {
  const { sgtComponent, ...rest } = options;
  const SGTControl = PDFComponents[sgtComponent];
  return (
    <ThemeProvider>
      <SGTControl field={field} form={form} props={{ ...rest, style }} />
    </ThemeProvider>
  );
};

function processFormData(formDataItems = [], isFirst, formsTitle) {
  return formDataItems.map(item => {
    if (!item) return {};
    const formDataJson = JSON.parse(item.formDataJson);
    if (!formDataJson) return {};
    const { formData, name } = formDataJson;
    const { meta, assetMap } = formData;
    const [layout, data] = meta;
    const customData = {};
    customData[item.id] = data;
    const { storageService } = AmplifyService;
    if (assetMap && Object.keys(assetMap)) {
      Object.keys(assetMap).forEach(key => {
        if (assetMap[key].length) {
          data[key] = storageService.getFile(assetMap[key][0].fileUrl);
        }
      });
    }
    const pageContent = getFormHeader(name, isFirst, formsTitle);
    pageContent.contents[0].contents.push({
      contents: layout.layouts.default.contents.map(({ options, contents }) => ({
        contents,
        options: { ...options, grow: 1, shrink: 0, size: 'auto' }
      })),
      sourceForContents: item.id
    });
    return { contents: pageContent, customData };
  });
}

class JobReportPDF extends Component {
  constructor(props) {
    super(props);
    this.jobServiceObj = new JobService();
    this.state = {
      tenantCompanyLogo: '',
      pdfToRenderString: undefined,
      data: '',
      techCosts: '',
      imageCachingExecuting: true
    };
  }

  componentWillMount = async () => {
    const { visitIdList, jobNumber, pdfOptions } = this.props;
    if (!visitIdList || visitIdList.length === 0) {
      return;
    }
    try {
      const data = await this.jobServiceObj.getJobReportForVisitIdByJobNumber(
        jobNumber,
        visitIdList,
        { ...pdfOptions }
      );
      const techCosts = await this.jobServiceObj.getTechCostForJobReport(visitIdList);

      this.setState({ data, techCosts });
      this.triggerImageCache();
    } catch (error) {
      this.props.snackbarOn('error', 'Unable to get job report', error);
    }
  };

  componentDidMount = async () => {
    let company = {};
    let employeesList = [];
    if (Context.getCompanyContext() && Context.getCompanyContext().getCompany) {
      const {
        logoUrl,
        phonePrimary,
        fax,
        companyAddresses,
        employees
      } = Context.getCompanyContext().getCompany;
      await this.setLogoURL(logoUrl);
      company = {
        ...company,
        logoUrl,
        phonePrimary,
        fax,
        companyAddresses
      };
      employeesList = employees?.items || [];
    }

    this.setState({
      company,
      employees: employeesList
    });
    this.triggerImageCache();
  };

  getEmployeeNameById = id => {
    const { employees } = this.state;
    const selectedEmployee = (employees || []).find(emp => emp.id === id);
    const name =
      (selectedEmployee || {}).name ||
      `${(selectedEmployee || {}).firstName || ''} ${(selectedEmployee || {}).lastName || ''}`;
    return name || '';
  };

  addressProcessing = (addressType, addressList) => {
    if (!addressList) {
      return null;
    }
    const addressArr =
      addressList &&
      addressList.items &&
      addressList.items.filter(item => item.addressType && item.addressType === addressType);

    const address = (addressArr && addressArr.length > 0 && addressArr[0]) || {};

    const addressLine3 = [];
    if (address.city) addressLine3.push(address.city);
    if (address.state) addressLine3.push(address.state);
    if (address.zipcode) addressLine3.push(address.zipcode);
    return {
      addressBillTo: address.billTo || '',
      addressLine1: address.addressLine1 || '',
      addressLine2: address.addressLine2 || '',
      addressLine3
    };
  };

  // this is an aproach to solve an issue where if pdf-renderer fails to upload an image, it shows blank
  // this can happen in a scenario where either network is slow or there are very large number of images
  // Since Image component of pdf-render is dumb component we don't ahve any control over them
  // So we are loading images before actually calling the pdf-render and cache them
  // later pdf-renderer will fetch those images from cache
  triggerImageCache = async () => {
    const { tenantCompanyLogo, data } = this.state;

    if (tenantCompanyLogo === '' || data === '') return;

    const { data: formattedData } = this.getFormattedData();
    const extractCloudinaryFileUrl = (items = []) =>
      items
        .map(i => i.attachments)
        .flat()
        .map(i => i.fileUrl)
        .filter(fileUrl => isCloudinaryImageType(getFileExtension(fileUrl)));
    const fileUrls = [
      ...extractCloudinaryFileUrl(formattedData.visitAttachments),
      ...extractCloudinaryFileUrl(formattedData.visitReceipts)
    ];
    const fetchImagePromises = fileUrls.map(
      f => new Promise((resolve, reject) => this.fetchImage(f, resolve, reject, 1))
    );

    try {
      await Promise.all(fetchImagePromises);
    } catch (error) {
      // handle error fetching image
    } finally {
      this.setState({ imageCachingExecuting: false });
    }
  };

  fetchImage = (url, resolve, reject, retry) => {
    const { storageService } = AmplifyService;
    const img = new Image();
    img.src = storageService.getFile(getGuardedFileName(url));
    img.onload = resolve();
    img.onerror = () => {
      // we can configure any number of retries to load the desired image
      // PDF renderer will also try to load image from source
      if (retry === 1) {
        reject();
      }
      this.fetchImage(url, resolve, reject, retry + 1);
    };
  };

  sortAttachment = (attachment1, attachment2) => {
    if (attachment1.type.toLowerCase() === attachment2.type.toLowerCase()) {
      return attachment1.createdDate < attachment2.createdDate ? -1 : 1;
    }
    return attachment1.type.toLowerCase() === 'before' ? -1 : 1;
  };

  getAttachmentDate = ({ addedDateTime, createdDate }) => {
    if (addedDateTime) return addedDateTime / 1000;
    return createdDate;
  };

  formatVisitAttachment = (visits, showDateAndTimeForPhotos = true) => {
    const visitsBind = [];
    visits.forEach(visit => {
      let attachments = [];
      visit.reviewReports?.items?.forEach(reviewReport => {
        if (reviewReport.attachments?.items?.length) {
          const reviewAttachment = reviewReport.attachments.items
            .sort((x, y) => this.sortAttachment(x, y))
            .map(x => ({
              ...x,
              createdDate: showDateAndTimeForPhotos ? this.getAttachmentDate(x) : undefined,
              type: capitalizeFirstLetter(x.type)
            }));
          attachments = attachments.concat(...reviewAttachment);
        }
      });
      if (attachments.length) {
        visitsBind.push({
          type: 'Visit',
          header: visit.visitNumber,
          attachments
        });
      }
    });

    return visitsBind;
  };

  formatVisitReceiptsAttachments = visits => {
    const visitsBind = [];
    visits.forEach(visit => {
      visit.reviewReports?.items?.forEach(reviewReport => {
        if (reviewReport.purchaseOrders?.items?.length) {
          const purchaseOrders = reviewReport.purchaseOrders.items.sort((x, y) => {
            return x.dateOfPurchase < y.dateOfPurchase ? -1 : 1;
          });
          purchaseOrders.forEach(purchaseOrder => {
            let attachments = [];
            const { poNumber, addedBy, createdBy, dateOfPurchase } = purchaseOrder;
            if (purchaseOrder.purchaseOrderReceipts.items.length) {
              attachments = attachments.concat(
                purchaseOrder.purchaseOrderReceipts.items.map(attachment => ({
                  fileUrl: attachment.imageUrl,
                  type: '',
                  createdDate: dateOfPurchase,
                  createdBy: addedBy || createdBy
                }))
              );
            }
            if (attachments.length) {
              visitsBind.push({
                type: 'PURCHASE ORDER',
                header: poNumber,
                attachments
              });
            }
          });
        }
      });
    });
    return visitsBind;
  };

  formatVisitsCustomerSignature = visits => {
    const storageService = new StorageService();
    const signatureArray = [];
    visits.forEach(visit => {
      visit?.customerSignaturesPdfs?.items?.forEach(item =>
        signatureArray.push({
          ...item,
          fileUrl: storageService.getFile(item.fileUrl).replace('.pdf', '.jpg')
        })
      );
    });
    return signatureArray;
  };

  // TODO Break this up and simplify the logic
  getFormattedData = () => {
    const { pdfOptions, presetJobReport } = this.props;
    const {
      showHoursWorked,
      showHoursBilled,
      showInventoryItems,
      showPurchasedItems,
      showCostSummary,
      showPriceSummary,
      showMaterialsCostSummary,
      showMaterialsPriceSummary,
      showDateAndTimeForPhotos
    } = pdfOptions || {};
    const { data, company, tenantCompanyLogo, techCosts } = this.state;
    const { property } = data || {};
    const propertyAddressObj =
      this.addressProcessing('propertyAddress', property?.companyAddresses) || {};
    const companyAddressObj =
      this.addressProcessing('shippingAddress', company?.companyAddresses) || {};
    const timesheetColumns = [
      {
        id: 'name',
        label: 'Name'
      },
      {
        id: 'REG',
        label: 'Straight time',
        width: 95
      },
      {
        id: 'OT',
        label: 'Overtime',
        width: 95
      },
      {
        id: 'DT',
        label: 'Double time',
        width: 95
      }
    ];
    let authorizedBy;
    if (data.authorizedBy) {
      authorizedBy = `${data.authorizedBy.firstName || ''} ${data.authorizedBy.lastName || ''}`;
    }

    let visitsArr = data?.visits?.items || [];
    const summaries = [];
    const assets = [];
    const labors = [];
    const laborCosts = [];
    const partsAndMaterials = [];
    const billItems = [];
    const labourInfo = [];
    const customFormLayouts = [];
    const customTaskFormLayouts = [];
    let timeSubtotal = 0;
    let inventorySubtotal = 0;
    let purchasedSubtotal = 0;
    let timeCostSubtotal = 0;
    let inventoryCostSubtotal = 0;
    let purchasedCostSubtotal = 0;

    const totalLaborInfo = {
      footer: true,
      cost: 0,
      hours: 0,
      total: 0
    };
    const totalInventory = {
      footer: true,
      quantity: 0,
      costSubtotal: 0,
      priceSubtotal: 0
    };
    const purchaseOrders = [];
    let formData = {};

    const groupedTechCosts = _.groupBy(_.sortBy(techCosts, 'visit.visitNumber'), 'employeeId');
    Object.values(groupedTechCosts)?.forEach(tech => {
      tech.forEach((lineItem, lineIndex) => {
        laborCosts.push({
          visitNumber: lineItem?.visit?.visitNumber || '',
          technician: lineIndex === 0 && lineItem?.name,
          laborType: lineIndex === 0 && lineItem?.labourTypeName,
          description: lineItem?.hourTypeDescription || lineItem?.hourTypeAbbreviation || '',
          hours: +lineItem.duration,
          cost: lineItem.hourCost,
          total: lineItem.totalCost
        });
      });
    });

    const labourCostSubtotal = _.sumBy(laborCosts, 'total');

    processFormData(data?.formData?.items, customFormLayouts.length === 0, JOB_FORMS_TITLE).forEach(
      prop => {
        const { contents, customData } = prop;
        if (contents) {
          customFormLayouts.push(contents);
          formData = { ...formData, ...customData };
        }
      }
    );

    data?.jobTasks?.items?.forEach(jTask => {
      processFormData(
        jTask?.task?.formData?.items,
        customTaskFormLayouts.length === 0,
        TASK_FORMS_TITLE
      ).forEach(prop => {
        const { contents, customData } = prop;
        if (contents) {
          customTaskFormLayouts.push(contents);
          formData = { ...formData, ...customData };
        }
      });
    });

    visitsArr = visitsArr.sort((left, right) => left.visitNumber - right.visitNumber);

    if (Array.isArray(visitsArr)) {
      const localAssetArray = [];
      visitsArr.forEach(visit => {
        (visit?.visitAssets?.items || []).forEach(visitAsset => {
          assets.push(visitAsset?.propertyAsset);
          localAssetArray.push(visitAsset?.propertyAsset);
        });
        const assetNames = R.pluck('assetName', localAssetArray) || [];
        processFormData(
          visit.formData?.items,
          customFormLayouts.length === 0,
          JOB_FORMS_TITLE
        ).forEach(prop => {
          const { contents, customData } = prop;
          if (contents) {
            customFormLayouts.push(contents);
            formData = { ...formData, ...customData };
          }
        });

        const timeCards = visit?.timeCardLinesView?.items || [];
        const massagedTimeCards = {};
        timeCards.forEach(card => {
          const localCard = {};
          localCard.visitNumber = visit.visitNumber;
          localCard.name =
            card.employeeName || this.getEmployeeNameById(card.employeeId) || card.employeeId;
          timesheetColumns.push({
            id: `${card.timeTypeAbbreviation}`,
            label: `${card.timeType}`
          });
          localCard[card.timeTypeAbbreviation] = `${card.timeMinutes || 0} hours`;

          if (massagedTimeCards[localCard.name]) {
            massagedTimeCards[localCard.name].push(localCard);
          } else {
            massagedTimeCards[localCard.name] = [localCard];
          }
        });

        Object.values(massagedTimeCards).forEach(cardArr => {
          let employeeTimesheet = {};
          (cardArr || []).forEach(card => {
            employeeTimesheet = Object.assign(employeeTimesheet, card);
          });
          labors.push(employeeTimesheet);
        });
        const technicianTeam = [...visit?.primaryTechs?.items?.map(x => x.mappedEntity)];
        const techteamNames = technicianTeam?.map(x => `${x.firstName} ${x.lastName}`);
        const reviewReportArr = visit?.reviewReports?.items || [];

        if (Array.isArray(reviewReportArr)) {
          reviewReportArr.forEach(report => {
            if (report.summaries && report.summaries.items) {
              report.summaries.items.forEach(summaryItem => {
                if (!summaryItem.includeInInvoice) return;
                summaries.push({
                  title: `Visit ${visit.visitNumber}`,
                  subTitles: [
                    {
                      subTitleKey: 'Work performed on ',
                      subTitleValue: `${moment
                        .unix(
                          summaryItem.addedBy
                            ? visit.scheduledFor
                            : summaryItem.lastUpdatedDateTime / 1000
                        )
                        .format(AppConstants.DATE_FORMAT)} by ${summaryItem.addedBy ||
                        summaryItem.createdBy ||
                        ''}`
                    },
                    {
                      subTitleKey: 'Asset: ',
                      subTitleValue: `${assetNames.join(', ')}`
                    }
                  ],
                  value: `Summary: ${summaryItem.summary}`
                });
              });
            }
            if (report.labourRateLineItems?.items) {
              report.labourRateLineItems.items.forEach(labour => {
                const allCosts = [];
                let item = {};
                item.visitNumber = visit.visitNumber;
                item.technician = `${labour.employee.firstName} ${labour.employee.lastName}`;
                item.laborType = labour.labourType?.name;
                item.cost = labour.cost;
                if (labour.labourRateBillingHourLines?.items) {
                  const billingRates = labour.labourRateBillingHourLines?.items.sort((x, y) =>
                    x.hourType.createdDate > y.hourType.createdDate ? 1 : -1
                  );
                  billingRates.forEach(rates => {
                    if (allCosts.length > 0) {
                      item = {};
                    }
                    item.description = rates.hourType.hourType;
                    item.hours = rates.totalHours;
                    item.rate = rates.rate;
                    item.total = rates.totalHours * rates.rate;
                    if (item.hours) {
                      totalLaborInfo.hours += item.hours;
                      totalLaborInfo.total += item.total;
                      if (allCosts.length === 0 && item.cost) {
                        totalLaborInfo.cost += item.cost;
                      }
                      allCosts.push(item);
                    }
                  });
                }
                if (allCosts.length) {
                  labourInfo.push(...allCosts);
                }
              });
            }

            if (report.inventoryParts?.items) {
              const inventoryArr = report?.inventoryParts?.items || [];
              inventoryArr.forEach(item => {
                if (!item.includeInInvoice) return;
                const localItem = item;
                localItem.costSubtotal = item.quantity * item.unitCost;
                localItem.priceSubtotal = item.quantity * item.unitPrice;
                localItem.visitNumber = visit.visitNumber;
                localItem.productCode = item.product?.code;
                totalInventory.quantity += localItem.quantity;
                totalInventory.costSubtotal += localItem.costSubtotal;
                totalInventory.priceSubtotal += localItem.priceSubtotal;
                partsAndMaterials.push(localItem);
              });
            }

            if (this.props.flags.procurement && report.reviewReportBillItems?.items) {
              report.reviewReportBillItems.items.forEach(billItem => {
                const priceSubtotal = billItem.quantity * billItem.unitPrice;
                const costSubtotal = billItem.quantity * billItem.unitCost;
                purchasedSubtotal += priceSubtotal;
                purchasedCostSubtotal += costSubtotal;
                billItems.push({
                  poNumber: billItem.billLine.bill.purchaseOrder.poNumber,
                  description: billItem.description,
                  vendor: billItem.billLine.bill.purchaseOrder.vendorName,
                  quantity: billItem.quantity,
                  unitCost: billItem.unitCost,
                  unitPrice: billItem.unitPrice,
                  costSubtotal,
                  priceSubtotal
                });
              });
            }

            if (report.purchaseOrders?.items) {
              report.purchaseOrders.items.forEach(purchaseOrderItem => {
                const partsAndMaterialsPurchased = [];
                const purchaseOrderLines = purchaseOrderItem?.purchaseOrderLines?.items || [];
                const totalPurchased = {
                  footer: true,
                  quantity: 0,
                  costSubtotal: 0,
                  priceSubtotal: 0
                };
                purchaseOrderLines.forEach(item => {
                  if (!item.includeInInvoice) return;
                  const localItem = item;
                  localItem.costSubtotal = item.quantity * item.unitCost;
                  localItem.priceSubtotal = item.quantity * item.unitPrice;
                  localItem.visitNumber = visit.visitNumber;
                  localItem.poNumber = purchaseOrderItem.poNumber;
                  localItem.productCode = item.product?.code;
                  totalPurchased.quantity += localItem.quantity;
                  totalPurchased.costSubtotal += localItem.costSubtotal;
                  totalPurchased.priceSubtotal += localItem.priceSubtotal;
                  partsAndMaterialsPurchased.push(localItem);
                });
                const imageUrl = purchaseOrderItem.purchaseOrderReceipts?.items?.[0]?.imageUrl;
                const {
                  poNumber,
                  addedBy,
                  dateOfPurchase,
                  receiptNumber,
                  vendorName
                } = purchaseOrderItem;
                purchasedSubtotal += totalPurchased.priceSubtotal;
                purchasedCostSubtotal += totalPurchased.costSubtotal;
                purchaseOrders.push({
                  imageUrl,
                  addedBy,
                  poNumber,
                  receiptNumber,
                  vendorName,
                  dateOfPurchase,
                  total: totalPurchased.priceSubtotal,
                  hideReceipt: !showHoursWorked, // prev hideCost
                  purchaseOrderLines: partsAndMaterialsPurchased
                });
              });
            }
          });
        }
      });
    }
    if (labourInfo.length) {
      timeSubtotal = totalLaborInfo.total;
      timeCostSubtotal = totalLaborInfo.cost;
    }

    // v1 dont have filtering controls
    if (showInventoryItems || !presetJobReport) {
      inventorySubtotal += totalInventory.priceSubtotal;
      inventoryCostSubtotal += totalInventory.costSubtotal;
    }

    const uniqTimesheetColumns = _.uniqBy(timesheetColumns, 'id');
    const combinedPropertyAddr = [];
    if (propertyAddressObj.addressLine1) combinedPropertyAddr.push(propertyAddressObj.addressLine1);
    if (propertyAddressObj.addressLine2) combinedPropertyAddr.push(propertyAddressObj.addressLine2);
    if ((propertyAddressObj.addressLine3 || []).length > 0)
      combinedPropertyAddr.push(propertyAddressObj.addressLine3.join(','));

    const defaultText = '-';
    let billingAddress = defaultText;
    const customerInfo = data.billingCustomer;
    if (customerInfo && customerInfo.companyAddresses) {
      const customerAddresses = customerInfo.companyAddresses?.items || [];
      let customerBillingAddresses = null;
      if (!_.isEmpty(customerAddresses)) {
        if (customerAddresses.length === 1) {
          [customerBillingAddresses] = customerAddresses;
        } else {
          customerBillingAddresses = customerAddresses.find(
            address => address.addressType === 'billingAddress'
          );
        }
      }
      billingAddress = customerBillingAddresses && formatAddress(customerBillingAddresses, true);
    }
    const getMaterialsSuffix = (inventory, purchasedItems) => {
      if (!presetJobReport) return '';

      const text = [];
      if (inventory) text.push('Inventory');
      if (purchasedItems) text.push('Purchases');

      if (text.length === 0) return '';
      return text.length === 1 ? ` (${text})` : ` (${text.join(' & ')})`;
    };

    const getMaterialTotal = (
      inventoryTotal,
      purchasedItemTotal,
      inventoryFlag,
      purchasedItemFlag
    ) => {
      if (!inventoryFlag && presetJobReport) return purchasedItemTotal;
      if (!purchasedItemFlag && presetJobReport) return inventoryTotal;
      return inventoryTotal + purchasedItemTotal;
    };

    const formattedData = {
      tenantCompanyLogoUrl: tenantCompanyLogo,
      tenantCompanyPhone: company.phonePrimary,
      tenantCompanyFax: company.fax,
      customerProvidedPONumber: data.customerProvidedPONumber || defaultText,
      customerProvidedWONumber: data.customerProvidedWONumber || defaultText,
      jobTypeName: data.jobTypeName || defaultText,
      amountQuoted: data.amountQuoted,
      amountNotToExceed: data.amountNotToExceed,
      authorizedBy: formatLongTextForPDF(authorizedBy || defaultText, 24),
      customerName: formatLongTextForPDF(data.customerName || defaultText, 24),
      customerAddress: billingAddress,
      propertyName: formatLongTextForPDF(property.companyName || '', 24),
      propertyAddress: (combinedPropertyAddr || []).join(' \n ') || '',
      issueDescription: data.issueDescription || ' ',
      jobNumber: data.customIdentifier || data.jobNumber,
      currentDate: moment().unix(),
      // used in default form
      tenantCompanyAddress1: companyAddressObj.addressLine1,
      tenantCompanyAddress3: companyAddressObj.addressLine3.join(', '),
      summaries,
      assets: [{ value: assets }],
      labors: labourInfo,
      laborCosts,
      visits: [{ value: visitsArr }],
      visitAttachments: this.formatVisitAttachment(visitsArr, showDateAndTimeForPhotos),
      visitReceipts: this.formatVisitReceiptsAttachments(visitsArr),
      partsAndMaterials,
      billItems,
      jobTasks: formatJobTaskForJobReport(data),
      customerSignatures: this.formatVisitsCustomerSignature(data?.visits?.items || []),
      partsAndMaterialsPurchased: purchaseOrders,
      timesheetColumns: uniqTimesheetColumns,
      timeSubtotal: convertToCurrencyString(timeSubtotal),
      inventorySubtotal: convertToCurrencyString(inventorySubtotal),
      purchasedSubtotal: convertToCurrencyString(purchasedSubtotal),
      total: convertToCurrencyString(timeSubtotal + inventorySubtotal + purchasedSubtotal),
      totalSummaryTable: [
        (showCostSummary || showPriceSummary || !presetJobReport) && {
          itemName: 'Labor',
          costSubtotal: showCostSummary || !presetJobReport ? labourCostSubtotal : null,
          priceSubtotal: showPriceSummary || !presetJobReport ? timeSubtotal : null
        },
        (showMaterialsCostSummary || showMaterialsPriceSummary || !presetJobReport) && {
          itemName: `Materials${getMaterialsSuffix(showInventoryItems, showPurchasedItems)}`,
          costSubtotal:
            showMaterialsCostSummary || !presetJobReport
              ? getMaterialTotal(
                  inventoryCostSubtotal,
                  purchasedCostSubtotal,
                  showInventoryItems,
                  showPurchasedItems
                )
              : null,
          priceSubtotal:
            showMaterialsPriceSummary || !presetJobReport
              ? getMaterialTotal(
                  inventorySubtotal,
                  purchasedSubtotal,
                  showInventoryItems,
                  showPurchasedItems
                )
              : null
        }
      ].filter(Boolean),
      customFormLayouts,
      customTaskFormLayouts,
      ...formData
    };

    return { data: formattedData, customFormLayouts, customTaskFormLayouts };
  };

  setLogoURL = async logoUrl => {
    const companyImageInfo = await this.getImage(logoUrl);
    const companyLogo = companyImageInfo.url || null;
    this.setState({ tenantCompanyLogo: companyLogo });
  };

  getImage = async url => {
    if (!url) return {};
    let imageS3Url;
    let type;
    try {
      const storageService = new StorageService();
      imageS3Url = await storageService.getFile(url);
    } catch (error) {
      Logger.info(`Error uploading image ${error}`);
    }
    // PNG rendering in PDF is creating wierd behaviour as PDF dont have backgrounds
    return { url: imageS3Url?.toLowerCase()?.replace('.png', '.jpg'), type };
  };

  render() {
    const { tenantCompanyLogo, data, imageCachingExecuting } = this.state;
    const { pdfOptions = {}, reportType, presetJobReport } = this.props;

    if (tenantCompanyLogo === '' || data === '' || imageCachingExecuting) {
      return <Spinner />;
    }
    const PDFDocumentLayout = presetJobReport ? PDFDocumentLayoutV2 : PDFDocumentLayoutV1;
    const { data: dataForForm, customFormLayouts, customTaskFormLayouts } = this.getFormattedData();

    const pdfTitle = `${reportType} Report #${dataForForm.jobNumber}`;

    return (
      <PDFViewer height="100%" width="100%">
        <PDFForm
          configuration={PDFDocumentLayout({
            reportType,
            pdfTitle,
            customFormLayouts,
            customTaskFormLayouts,
            procurementFlag: this.props.flags.procurement,
            ...pdfOptions
          })}
          customComponents={{
            VisitSummary,
            PurchasedView,
            Attachments,
            SgtConverter,
            SignaturesPDF,
            SignatureScreen
          }}
          data={dataForForm}
          layout="pdf"
        />
      </PDFViewer>
    );
  }
}

const mapStateToProps = state => ({ user: state.user });

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const reduxConnectedQuoteDetail = connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(JobReportPDF));

export default reduxConnectedQuoteDetail;
