import { Logger } from 'services/Logger';
import { AccountingApp } from 'utils/constants';

import * as CONSTANTS from '../constants';
import initialState from '../state';

export const alert = (state = initialState.alert, action) => {
  switch (action.type) {
    case CONSTANTS.ALERT_ON:
      return action.alert;
    case CONSTANTS.ALERT_OFF:
      return action.alert;
    default:
      return state;
  }
};

export const contextNotification = (state = initialState.contextNotification, action) => {
  switch (action.type) {
    case 'CONTEXT_UPDATED':
      return action.companyContext;
    default:
      return state;
  }
};

export const visitUpdateNotification = (state = initialState.visitUpdateNotification, action) => {
  switch (action.type) {
    case 'VISIT_UPDATED':
      return action.visitUpdate;
    default:
      return state;
  }
};

export const pageNotification = (state = initialState.pageNotification, action) => {
  switch (action.type) {
    case CONSTANTS.SNACKBAR_ON:
      return action.pageNotification;
    case CONSTANTS.SNACKBAR_OFF:
      return action.pageNotification;
    default:
      return state;
  }
};
export const application = (state = initialState.application, action) => {
  switch (action.type) {
    case CONSTANTS.SPINNER_ON:
      return { ...state, spinner: action.spinner };
    case CONSTANTS.SPINNER_OFF:
      return { ...state, spinner: action.spinner };
    case CONSTANTS.PAGE_MODE:
      return { ...state, pageMode: action.pageMode };
    case CONSTANTS.SHOW_MUTATION_CONFIRMATION:
      return {
        ...state,
        mutationConfirmation: {
          open: true,
          ...action.payload
        }
      };
    case CONSTANTS.HIDE_MUTATION_CONFIRMATION:
      return {
        ...state,
        mutationConfirmation: {
          ...state.mutationConfirmation,
          open: false
        }
      };
    default:
      return state;
  }
};

const parseTenantSettingValue = value => {
  switch (value) {
    case 'true':
      return true;
    case '0':
    case 'false':
      return false;
    case 'NULL':
      return null;
    default: {
      if (value?.startsWith('{') || value?.startsWith('[')) {
        try {
          return JSON.parse(value);
        } catch (error) {
          Logger.error(`Invalid tenant setting JSON: ${value}`, error);
        }
      }
      return value;
    }
  }
};

const parseTenantSettingObjects = settings =>
  settings.reduce(
    (obj, s) => ({ ...obj, [s.settingKey]: parseTenantSettingValue(s.settingValue) }),
    {}
  );

export const settings = (state = initialState.settings, action) => {
  switch (action.type) {
    case CONSTANTS.SET_SETTINGS:
      const tenantSettings = parseTenantSettingObjects(action.settings);
      return {
        ...state,
        ...tenantSettings,
        isVistaEnabled: tenantSettings.accountingApp === AccountingApp.VISTA,
        isSpectrumEnabled: tenantSettings.accountingApp === AccountingApp.SPECTRUM,
        isQuoteToPoEnabled:
          tenantSettings.accountingApp !== AccountingApp.VISTA &&
          tenantSettings.accountingApp !== AccountingApp.SPECTRUM &&
          tenantSettings.accountingApp !== AccountingApp.SAGE
      };
    case CONSTANTS.UPDATE_SETTING:
      const tenantSetting = {
        [action.setting.settingKey]: parseTenantSettingValue(action.setting.settingValue)
      };
      return {
        ...state,
        ...tenantSetting
      };
    default:
      return state;
  }
};

export const options = (state = initialState.options, action) => {
  switch (action.type) {
    case CONSTANTS.SET_OPTIONS:
      return { ...state, ...action.options };
    default:
      return state;
  }
};

export const company = (state = initialState.company, action) => {
  switch (action.type) {
    case CONSTANTS.SET_COMPANY:
      return { ...state, ...action.company };
    default:
      return state;
  }
};

export const companySettings = (state = initialState.companySettings, action) => {
  switch (action.type) {
    case CONSTANTS.SET_COMPANY_SETTINGS:
      return { ...state, ...action.companySettings };
    default:
      return state;
  }
};

export const user = (state = initialState.user, action) => {
  switch (action.type) {
    case CONSTANTS.SIGNED_IN:
      return {
        ...state,
        username: action.username,
        displayName: action.displayName,
        isAuthenticated: action.isAuthenticated,
        tenantId: action.tenantId,
        tenantCompanyId: action.tenantCompanyId,
        cognitoRole: action.cognitoRole,
        employeeId: action.employeeId,
        appPermissionRules: action.appPermissionRules,
        email: action.email
      };

    case CONSTANTS.SIGNED_OUT:
      return {
        ...state,
        username: action.username,
        displayName: action.displayName,
        isAuthenticated: action.isAuthenticated,
        tenantId: action.tenantId,
        tenantCompanyId: action.tenantCompanyId,
        cognitoRole: action.cognitoRole,
        appPermissionRules: action.appPermissionRules
      };

    case CONSTANTS.SET_USER_TENANT_SETTINGS:
      return {
        ...state,
        tenantSettings: action.tenantSettings
      };

    case CONSTANTS.UPDATE_USER_TENANT_SETTING:
      const tenantSettingIndex = state.tenantSettings.findIndex(
        tenantSetting => tenantSetting.id === action.tenantSetting.id
      );
      const updatedTenantSettings = [...state.tenantSettings];
      updatedTenantSettings[tenantSettingIndex] = action.tenantSetting;
      return {
        ...state,
        tenantSettings: updatedTenantSettings
      };

    default:
      return state;
  }
};

export const entities = (state = initialState, action) => state;
export const components = (state = initialState, action) => state;

export const menu = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.APP_MENU:
      return { ...state, appName: action.menu };
    case CONSTANTS.MENU_OPEN:
      return { ...state, isOpen: action.isOpen };
    default:
      return state;
  }
};

export const launchDarklyFlags = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.SET_LD_FLAGS:
      return action.launchDarklyFlags;
    default:
      return state;
  }
};
