import React, { useEffect, useRef, useState } from 'react';

import { Field, FieldType, MUIForm, ThemeProvider, ThumbnailSize } from '@BuildHero/sergeant';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { useFlags } from 'launchdarkly-react-client-sdk';
import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PlacesSearch from 'components/BuildHeroFormComponents/PlacesSearch';
import DefaultButton from 'components/Buttons/DefaultButton';
import PageHeader from 'components/PageHeader';
import ActionsMenu from 'components/ResponsiveTable/ActionsMenu';
import NamedIcon from 'components/ResponsiveTable/NamedIcon';
import StatusChip from 'components/StatusChip';
import withLoading from 'components/WithLoading';

import Labels from 'meta/labels';
import PageMap from 'meta/PageMap';
import { receiptStatusChips } from 'meta/Procurement/PurchaseOrders/frameStatusChips';
import {
  poFrameSideFormShippingFields,
  poFrameSideFormShippingLayout
} from 'meta/Procurement/PurchaseOrders/poFrameSideFormShipping';
import {
  poFrameSideFormVendorFields,
  poFrameSideFormVendorLayout
} from 'meta/Procurement/PurchaseOrders/poFrameSideFormVendor';
import {
  rbFrameTopFormFields,
  rbFrameTopFormLayout
} from 'meta/Procurement/PurchaseOrders/rbFrameTopForm';
import { snackbarOn } from 'redux/actions/globalActions';
import { ApprovalRejectionModal } from 'scenes/Procurement/component/ApprovalRejectionModal';
import EntityImagesCarousel from 'scenes/Procurement/component/EntityImagesCarousel';
import {
  getAddressByType,
  getAddressObj,
  getReceiptDueDate
} from 'scenes/Procurement/component/utils';
import {
  AttachmentType,
  loadingParams,
  ProcurementType,
  ShippingInformation,
  statusToNoteLabel
} from 'scenes/Procurement/constants';
import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';
import buildHeroMuiFormOverrides from 'scenes/ProjectManagement/components/buildHeroMuiFormOverrides';
import CustomFieldWithLabel from 'scenes/ProjectManagement/components/CustomFieldWithLabel';
import { generateDefaultValidationSchema } from 'scenes/ProjectManagement/components/formattingUtils';
import {
  getCloudinaryImageUrl,
  getLabelFromValues
} from 'scenes/ProjectManagement/components/utils';
import { attachmentChange, attachmentDelete } from 'services/API/attachment';

import { getCompanyBytenantId } from 'services/API/companies';
import getCompanyAddressByParentId from 'services/API/companyAddress';
import {
  addReceiptAttachment,
  postReceiptPost,
  purchaseOrderReceiptChange
} from 'services/API/purchaseOrderReceipt';
import { getTaxRateById } from 'services/API/taxRate';

import { getCombinedAddress } from 'utils';
import { ProcurementPurchaseOrderReceiptStatus, VendorApprovalStatus } from 'utils/AppConstants';
import { AccountingApp, EnumType, ExportStatus, Mode, SyncStatus } from 'utils/constants';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import LocationView from '../LocationView';
import MuiFormSectionTitle from '../MuiFormSectionTitle';
import { ProcurementStatusButtons } from '../ProcurementStatusButtons';

import VendorDocumentNumberImage from '../VendorDocumentNumberImage';

import GeneratePdfModal from './GeneratePdfModal';
import { useReceiptSubscription } from './StatusSubscription.gql';
import SyncStatusChip from './SyncStatusChip';

const CustomFieldWithLabelNarrow = ({ form, field, options }) => {
  return (
    <CustomFieldWithLabel
      field={field}
      form={form}
      options={options}
      style={{ lineHeight: '16px' }}
    />
  );
};

const CustomFieldWithLabelWrap = ({ field, options }) => {
  return (
    <CustomFieldWithLabel
      field={field}
      nowrap={false}
      options={options}
      style={{
        lineHeight: '20px',
        wordBreak: 'break-word',
        ...(options.marginRight ? { marginRight: options.marginRight } : {})
      }}
    />
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    color: '#333333'
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderTop: `1px solid ${theme.palette.grayscale(90)}`
  },
  sidebar: {
    borderRight: `1px solid ${theme.palette.grayscale(90)}`,
    width: 218,
    minWidth: 218
  },
  sidebarInner: {
    margin: '24px 16px 24px 0px',
    width: 202
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    overflow: 'auto'
  },
  topbar: {
    padding: '24px 16px'
  },
  childrenContainer: {
    padding: '24px 16px'
  },
  imageContainer: {
    marginTop: '30px'
  },
  carouselContainer: {
    marginTop: '15px',
    marginRight: '16px'
  },
  formContainer: buildHeroMuiFormOverrides(theme)
}));

const editApprovalNoteEnabledStatuses = [
  VendorApprovalStatus.APPROVED,
  VendorApprovalStatus.REJECTED
];

const MuiFormWithLoading = withLoading(MUIForm);

const POData = withLoading(({ data }) => {
  const { PurchaseOrder, PurchaseOrderReceipt } = data;
  const style = { margin: '0px 0px 16px' };
  return (
    <ThemeProvider>
      <Field
        label="Purchase Order"
        style={style}
        type={FieldType.LINK}
        value={{
          label: `${PurchaseOrder?.poNumber}`,
          to: `/procurement/purchaseorders/view/${PurchaseOrder?.id}`
        }}
      />
      {PurchaseOrderReceipt && (
        <Field
          label={ProcurementType.RECEIPT}
          style={style}
          type={FieldType.LINK}
          value={{
            label: `${PurchaseOrderReceipt?.receiptNumber}`,
            to: `/procurement/receipts-bills/receipt/view/${PurchaseOrderReceipt?.id}`
          }}
        />
      )}
    </ThemeProvider>
  );
});

const enabledPostReceiptStatuses = [VendorApprovalStatus.APPROVED, VendorApprovalStatus.UNREVIEWED];

const ReceiptBillDetailFrame = ({ children, ...props }) => {
  const {
    id,
    rbData,
    receiptItems,
    type,
    mode,
    user,
    getHandleCreateService,
    getHandleComplete,
    handleSubmitStart,
    setOnSubmitFinal,
    isSubmitting,
    handleFormsSubmit,
    actionButtonHandler,
    actionButtons,
    frameDataReducer,
    isLoading,
    isFileDownloading,
    disableEdit,
    isPurchaseOrderVoid,
    isApprovalRejectionModalOpen,
    setIsApprovalRejectionModalOpen,
    approvalRejectionModalRef,
    isVendorInvoiceApproval
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const { Geocoder, GeocoderStatus } = google.maps;
  const { current: geocoder } = useRef(new Geocoder());
  const [showGeneratePdfModal, setShowGeneratePdfModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [companyInfo, setCompanyInfo] = useState({});
  const [finalTaxRate, setFinalTaxRate] = useState(0);
  const [manualEntry, setManualEntry] = useState(
    rbData.sidebarShipping.shipTo === ShippingInformation.MANUALLY
  );
  const [status, setStatus] = useState(camelCase(rbData.status));
  const [syncStatus, setSyncStatus] = useState(rbData.syncStatus);
  const [syncLog, setSyncLog] = useState(rbData.syncLog);
  const statusInfo = useReceiptSubscription(user.tenantId, rbData.id);
  const { accountingApp: currentAccountingApp } = useSelector(state => state.settings);

  const flags = useFlags();
  const departmentsAreLimitedByJob = flags[FeatureFlags.DEPARTMENTS_ARE_LIMITED_BY_JOB];

  const handleReceiptAttachmentCallBack = async (attachment, record) => {
    const isApprovalStatusChanged = rbData?.approvalStatus !== record.approvalStatus;
    if (record?.type !== AttachmentType.vendorInvoice) return;
    const isApprovalStatusApprovedOrRejected =
      record.approvalStatus === VendorApprovalStatus.APPROVED ||
      record.approvalStatus === VendorApprovalStatus.REJECTED;
    const isRevertToPendingStatus =
      rbData?.status === ExportStatus.POSTED &&
      rbData?.vendorDocumentNumber !== record.vendorDocumentNumber;
    const payload = {
      approvalStatus: record.approvalStatus,
      invoiceAmount: record.invoiceAmount,
      vendorDocumentNumber: record.vendorDocumentNumber,
      ...(isApprovalStatusChanged && {
        approvalNote: null,
        approvalNoteDateTime: isApprovalStatusApprovedOrRejected ? moment().valueOf() : null,
        approvalNoteById: isApprovalStatusApprovedOrRejected ? user?.employeeId : null
      }),
      vendorDocumentAttachmentId: attachment?.id,
      ...(isRevertToPendingStatus && {
        status: ExportStatus.PENDING, // revert to pending status
        syncStatus: null // revert to not in sync
      })
    };
    await purchaseOrderReceiptChange(rbData?.id, payload);
    frameDataReducer({
      type: 'updateVendorInvoiceData',
      payload,
      user
    });
  };

  const handleApprovalStatusUpdate = async payload => {
    await purchaseOrderReceiptChange(rbData?.id, payload);
    frameDataReducer({
      type: 'updateApprovalStatus',
      payload,
      user
    });
  };

  const handleSaveApprovalRejectionNote = async data => {
    const payload = {
      approvalStatus: approvalRejectionModalRef.current.status,
      approvalNote: data?.approvalNote,
      approvalNoteDateTime: moment().valueOf(),
      approvalNoteById: user?.employeeId
    };
    await handleApprovalStatusUpdate(payload);
    setIsApprovalRejectionModalOpen(false);
    approvalRejectionModalRef.current = {
      status: null
    };
  };

  const handleApproveReceipt = () => {
    approvalRejectionModalRef.current = {
      status: VendorApprovalStatus.APPROVED,
      mode: Mode.ADD
    };
    setIsApprovalRejectionModalOpen(true);
  };

  document.title = `BuildOps - ${type} #${rbData.billNumber || rbData.receiptNumber || ''}`;

  const showActionMenu = event => {
    setAnchorEl({ ref: event.currentTarget });
  };

  useEffect(() => {
    setOnSubmitFinal(handleFormsSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rbData.status !== undefined) {
      setStatus(camelCase(rbData.status));
    }
  }, [rbData.status]);

  useEffect(() => {
    if (rbData.syncStatus !== undefined) {
      setSyncStatus(rbData.syncStatus);
    }
  }, [rbData.syncStatus]);

  useEffect(() => {
    if (rbData.syncLog !== undefined) {
      setSyncLog(rbData.syncLog);
    }
  }, [rbData.syncLog]);

  useEffect(() => {
    if (statusInfo.newStatus !== undefined) {
      setStatus(camelCase(statusInfo.newStatus));
    }
  }, [statusInfo.newStatus]);

  useEffect(() => {
    if (statusInfo.newSyncStatus !== undefined) {
      setSyncStatus(statusInfo.newSyncStatus);
    }
  }, [statusInfo.newSyncStatus]);

  useEffect(() => {
    if (statusInfo.newSyncLog !== undefined) {
      setSyncLog(statusInfo.newSyncLog);
    }
  }, [statusInfo.newSyncLog]);

  const validatePostReceipt = receipt => {
    if ([AccountingApp.QUICKBOOKS].includes(currentAccountingApp)) {
      const itemsWithNoExpenseAccount = receipt.PurchaseOrderReceiptLine.filter(
        lineItem => !lineItem.Product.glExpenseAccountRefValue
      ).map(lineItem => lineItem.PurchaseOrderLine.itemName);
      if (itemsWithNoExpenseAccount.length > 0) {
        return `Line item(s) ${itemsWithNoExpenseAccount.join(
          ', '
        )} on this Receipt are missing an expense account. Please update them in your item list and try again.`;
      }
    }
    return '';
  };

  const handlePostReceipt = async () => {
    const receipt = { ...rbData };
    const response = await postReceiptPost(receipt?.id);
    const errorMessage = response?.id ? validatePostReceipt(receipt) : 'Unable to post receipt';
    if (errorMessage) {
      props.snackbarOn('error', errorMessage);
    } else {
      history.push(`/procurement/receipts-bills/receipt/view/${id}`);
      props.snackbarOn('success', 'Successfully Posted Receipt');
    }

    setShowGeneratePdfModal(false);
  };

  const handlePreviewReceipt = async () => {
    const [companyData, taxData] = await Promise.all([
      getCompanyBytenantId(user.tenantId),
      getTaxRateById(rbData.taxRateId)
    ]);
    setCompanyInfo(companyData[0]);
    if (taxData?.taxRate) {
      setFinalTaxRate(parseFloat(taxData?.taxRate));
    }
    setShowGeneratePdfModal(true);
  };

  const postReceiptSyncStatusOrExportedCondition =
    ((!rbData.syncStatus ||
      (rbData.syncStatus === SyncStatus.SYNC_FAILED &&
        status.toLowerCase() !== ProcurementPurchaseOrderReceiptStatus.CLOSED.toLowerCase())) &&
      status.toLowerCase() !== ProcurementPurchaseOrderReceiptStatus.EXPORTED.toLowerCase() &&
      status.toLowerCase() !== ProcurementPurchaseOrderReceiptStatus.BYPASSED.toLowerCase()) ||
    (status.toLowerCase() === ProcurementPurchaseOrderReceiptStatus.EXPORTED.toLowerCase() &&
      mode === 'edit');

  const postReceiptEnabled =
    (!isLoading &&
      postReceiptSyncStatusOrExportedCondition &&
      !flags[FeatureFlags.VENDOR_INVOICE_APPROVAL]) ||
    (!isLoading &&
      postReceiptSyncStatusOrExportedCondition &&
      enabledPostReceiptStatuses.includes(rbData?.approvalStatus) &&
      flags[FeatureFlags.VENDOR_INVOICE_APPROVAL] &&
      mode !== Mode.EDIT);

  const approveReceiptEnabled =
    flags[FeatureFlags.VENDOR_INVOICE_APPROVAL] &&
    !isLoading &&
    rbData?.approvalStatus &&
    !enabledPostReceiptStatuses.includes(rbData?.approvalStatus) &&
    mode !== Mode.EDIT;

  const headerButtons = [
    <div key="procurementReceiptFrameHeaderButtons">
      {// users should still be able to edit after posting a receipt (even in the exported status)
      !disableEdit &&
        status.toLowerCase() !== ProcurementPurchaseOrderReceiptStatus.CLOSED.toLowerCase() && (
          <DefaultButton
            handle={
              mode === 'edit'
                ? () => history.push(`/procurement/receipts-bills/receipt/view/${id}`)
                : () => history.push(`/procurement/receipts-bills/receipt/edit/${id}`)
            }
            label={mode === 'edit' ? Labels.cancelButtonText[user.locale] : 'Edit'}
            showSpinner={isSubmitting || isFileDownloading}
            style={{ marginRight: 8 }}
            variant="outlined"
          />
        )}
      {mode === 'edit' ? (
        <DefaultButton
          handle={handleSubmitStart}
          label={Labels.saveChangesButtonText[user.locale]}
          showSpinner={isSubmitting || isFileDownloading}
          style={{ marginRight: 8 }}
          variant="containedPrimary"
        />
      ) : null}
      {/* should not be displayed on the Edit mode */}
      {mode !== Mode.EDIT ? (
        <DefaultButton
          disabled={isSubmitting}
          handle={handlePreviewReceipt}
          label={Labels.previewReceipt[user.locale]}
          showSpinner={isSubmitting || isFileDownloading}
          style={{ marginRight: 8 }}
          variant="contained"
        />
      ) : null}
      {// users should still be able to edit and re-post the receipt although the status is Exported
      postReceiptEnabled && (
        <DefaultButton
          color="primary"
          disabled={
            isSubmitting ||
            (status.toLowerCase() !==
              ProcurementPurchaseOrderReceiptStatus.EXPORTED.toLowerCase() &&
              mode === 'edit')
          }
          handle={
            status.toLowerCase() === ProcurementPurchaseOrderReceiptStatus.EXPORTED.toLowerCase() &&
            mode === 'edit'
              ? handleSubmitStart
              : handlePostReceipt
          }
          label={Labels.postReceipt[user.locale]}
          showSpinner={isSubmitting || isFileDownloading}
          variant="contained"
        />
      )}
      {approveReceiptEnabled && (
        <DefaultButton
          color="primary"
          handle={handleApproveReceipt}
          label={Labels.Approve[user.locale]}
          style={{ marginRight: 8 }}
          variant="contained"
        />
      )}

      {!isEmpty(actionButtons) && (
        <>
          <IconButton onClick={showActionMenu}>
            <NamedIcon className={classes.iconButton} name="MoreVert" />
          </IconButton>
          <ActionsMenu
            anchorEl={anchorEl}
            handleMenuClose={() => setAnchorEl(null)}
            rowActionButtons={{
              ...(postReceiptEnabled &&
                ![
                  ProcurementPurchaseOrderReceiptStatus.EXPORTED,
                  ProcurementPurchaseOrderReceiptStatus.POSTED
                ].includes(rbData?.status) && {
                  bypassPostingReceipt: {
                    label: 'Bypass Posting Receipt',
                    lookUpIconMap: true,
                    icon: 'bypassIcon',
                    caslAction: 'view',
                    caslKey: ''
                  }
                }),
              ...actionButtons,
              ...(editApprovalNoteEnabledStatuses.includes(rbData?.approvalStatus) && {
                editApprovalNote: {
                  label: `Edit ${statusToNoteLabel[rbData?.approvalStatus]}`,
                  icon: 'Note',
                  caslAction: 'view',
                  caslKey: ''
                }
              })
            }}
            rowActions={actionButtonHandler}
          />
        </>
      )}
    </div>
  ];

  const additionalTitleComponents = [
    status ? (
      <StatusChip
        backgroundColor={receiptStatusChips[status]?.backgroundColor}
        key="procurementRBFrameTitleStatusChips"
        label={receiptStatusChips[status]?.name[user.locale]}
        style={{
          height: 24,
          lineHeight: '24px',
          borderRadius: 2,
          marginLeft: 24
        }}
        textColor={receiptStatusChips[status]?.textColor}
      />
    ) : null,
    flags[FeatureFlags.VENDOR_INVOICE_APPROVAL] && type === ProcurementType.RECEIPT ? (
      <ProcurementStatusButtons
        enumType={EnumType.VENDOR_APPROVAL_STATUS}
        frameDataReducer={frameDataReducer}
        receiptId={rbData.id}
        user={user}
        value={rbData.approvalStatus}
      />
    ) : null
  ];

  const handleSidebarFormVendorChange = data => {
    const vendorAddress = getCombinedAddress(data.vendor);

    geocoder.geocode({ address: vendorAddress }, (results, statusCode) => {
      if (statusCode === GeocoderStatus.OK) {
        frameDataReducer({
          type: 'updateSidebarVendor',
          payload: {
            vendor: data.vendor,
            vendorName: data.vendor?.name || '',
            vendorAddress: vendorAddress || '',
            phoneNumber: data.vendor?.phone || '',
            vendorEmail: data.vendor?.email || '',
            vendorLocation: {
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng()
            }
          }
        });
      }
    });
  };

  const handleTopbarFormChange = data => {
    frameDataReducer({
      type: 'updateTopbar',
      payload: {
        ...data,
        depId: data.Department?.id || data.depId
      }
    });
  };

  const handleSidebarFormShippingChange = data => {
    if (!data.shippingAddress || data.shippingAddress === '-') return;

    geocoder.geocode({ address: data.shippingAddress }, (results, statusCode) => {
      if (statusCode === GeocoderStatus.OK) {
        frameDataReducer({
          type: 'updateSidebarShipping',
          payload: {
            ...data,
            shipTo: data.shipTo || '',
            shippingAddress: data.shippingAddress || '',
            addressLine1: data.addressLine1 || undefined,
            addressLine2: data.addressLine2 || undefined,
            city: data.city || undefined,
            state: data.state || undefined,
            zipcode: data.zipcode || undefined,
            shippingLocation: {
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng()
            }
          }
        });
      }
    });
  };

  const matchingShippingAddress = async (userSelect, formData) => {
    let newFormValue = {};
    let result;
    let propertyAddr;

    switch (userSelect) {
      case ShippingInformation.JOB_SITE:
        result = await getCompanyAddressByParentId(
          formData.values.jobAndProject?.customerPropertyId
        );
        propertyAddr = getAddressByType(result, 'propertyAddress');
        newFormValue = {
          ...formData.values,
          shipTo: userSelect,
          shippingAddress: getCombinedAddress(propertyAddr[0] || null),
          shippingAddressObj: getAddressObj(propertyAddr[0])
        };
        formData.setValues(newFormValue);
        break;
      case ShippingInformation.WAREHOUSE:
        propertyAddr = getAddressByType(
          formData.values.department?.companyAddresses || [],
          'shippingAddress'
        );
        newFormValue = {
          ...formData.values,
          shipTo: userSelect,
          shippingAddress: getCombinedAddress(propertyAddr[0] || null),
          shippingAddressObj: getAddressObj(propertyAddr[0])
        };
        formData.setValues(newFormValue);
        break;
      case ShippingInformation.VENDOR_PICKUP:
        newFormValue = {
          ...formData.values,
          shipTo: userSelect,
          shippingAddress: getCombinedAddress(rbData.sidebarVendor.vendor) || null,
          shippingAddressObj: getAddressObj(rbData.sidebarVendor.vendor)
        };
        formData.setValues(newFormValue);
        break;
      case ShippingInformation.MANUALLY:
      default:
        newFormValue = {
          ...formData.values,
          shipTo: userSelect,
          shippingAddress: getCombinedAddress(rbData),
          shippingAddressObj: getAddressObj(rbData)
        };
        formData.setValues(newFormValue);
        break;
    }
    handleSidebarFormShippingChange(newFormValue);
  };

  const handleChangeShipToSelect = async (field, form) => {
    await matchingShippingAddress(field.value, form);

    switch (field.value) {
      case ShippingInformation.JOB_SITE:
      case ShippingInformation.WAREHOUSE:
      case ShippingInformation.VENDOR_PICKUP:
        setManualEntry(false);
        break;
      case ShippingInformation.MANUALLY:
      default:
        setManualEntry(true);
        break;
    }
  };

  const dueDate = getReceiptDueDate(rbData);
  const accountSyncStatus = rbData.syncStatus ?? 'Not Synced';

  const handleAddImage = async (receiptId, data) => {
    const attachment = await addReceiptAttachment(receiptId, data);
    frameDataReducer({
      type: 'updateSidebarAttachments',
      payload: {
        Attachment: [...rbData.Attachment, attachment]
      }
    });
    await handleReceiptAttachmentCallBack(attachment, data);
  };

  const handleEditImage = async (attachmentId, record) => {
    const payload = {
      fileUrl: record?.fileUrl,
      fileName: record?.fileName,
      fileSize: record?.fileSize,
      comment: record?.comment,
      customFileName: record?.customFileName
    };
    const attachment = await attachmentChange(attachmentId, payload);
    const index = rbData?.Attachment?.findIndex(el => el.id === attachment?.id);
    rbData.Attachment[index] = attachment;
    frameDataReducer({
      type: 'updateSidebarAttachments',
      payload: {
        Attachment: [...rbData.Attachment]
      }
    });
    await handleReceiptAttachmentCallBack(attachment, record);
  };

  const handleDeleteImage = async record => {
    await attachmentDelete(record?.id);
    frameDataReducer({
      type: 'updateSidebarAttachments',
      payload: {
        Attachment: [...rbData.Attachment.filter(el => el.id !== record?.id)]
      }
    });
    if (record?.type === AttachmentType.vendorInvoice) {
      const payload = {
        vendorDocumentAttachmentId: null
      };
      await purchaseOrderReceiptChange(rbData?.id, payload);
      frameDataReducer({
        type: 'updateVendorInvoiceData',
        payload,
        user
      });
    }
  };

  const formatAttachments = receiptData => {
    return receiptData?.Attachment?.map(attachment => {
      return {
        ...attachment,
        url: getCloudinaryImageUrl(attachment.fileUrl),
        ...(attachment.type === AttachmentType.vendorInvoice && {
          invoiceAmount: receiptData?.invoiceAmount,
          vendorDocumentNumber: receiptData?.vendorDocumentNumber,
          approvalStatus: receiptData?.approvalStatus
        })
      };
    });
  };

  return (
    <div className={classes.root}>
      <PageHeader
        additionalTitleComponents={additionalTitleComponents}
        breadcrumbsArray={[
          {
            link: '',
            title: 'Procurement'
          },
          {
            link: '/procurement/receipts-bills/',
            title: PageMap.procurementReceiptBills.title[user.locale]
          }
        ]}
        key="procumentRBFramePageHeader"
        overrideHeaderButtons={
          type === ProcurementType.RECEIPT && !isPurchaseOrderVoid ? headerButtons : null
        }
        pageMapKey="procurementReceiptBills"
        title={
          type === ProcurementType.RECEIPT
            ? `Receipt #${rbData?.receiptNumber || ''}`
            : `Bill #${rbData?.billNumber || ''}`
        }
        userLocale={user.locale}
      />
      <div className={classes.body}>
        <div className={classes.sidebar}>
          <div className={classNames(classes.sidebarInner, classes.formContainer)}>
            <POData data={rbData} isLoading={isLoading} loadingParams={loadingParams.leftSection} />
            <MuiFormWithLoading
              configuration={poFrameSideFormVendorLayout()}
              customComponents={{
                MuiFormSectionTitle,
                CustomFieldWithLabelNarrow,
                CustomFieldWithLabelWrap,
                LocationView,
                SearchBar
              }}
              data={rbData.sidebarVendor}
              isLoading={isLoading}
              layout={mode}
              loadingParams={loadingParams.leftSection}
              validationSchema={generateDefaultValidationSchema(poFrameSideFormVendorFields)}
              onComplete={getHandleComplete('sidebarVendor')}
              onCreateService={getHandleCreateService('sidebarVendor')}
              onFormChange={handleSidebarFormVendorChange}
            />
            <MuiFormWithLoading
              configuration={poFrameSideFormShippingLayout({
                shipTo: getLabelFromValues(ShippingInformation),
                manualEntry,
                handleChangeShipToSelect,
                mode
              })}
              customComponents={{
                MuiFormSectionTitle,
                CustomFieldWithLabelNarrow,
                CustomFieldWithLabelWrap,
                PlacesSearch,
                LocationView,
                SearchBar
              }}
              data={rbData.sidebarShipping}
              isLoading={isLoading}
              layout={mode}
              loadingParams={loadingParams.leftSection}
              validationSchema={generateDefaultValidationSchema(poFrameSideFormShippingFields)}
              onComplete={getHandleComplete('sidebarShipping')}
              onCreateService={getHandleCreateService('sidebarShipping')}
              onFormChange={handleSidebarFormShippingChange}
            />
            {type === ProcurementType.RECEIPT && (
              <div className={classes.imageContainer}>
                <MuiFormSectionTitle options={{ label: 'Images' }} />
                <div className={classes.carouselContainer}>
                  <EntityImagesCarousel
                    disableVendorInvoiceOption={rbData.Attachment?.some(
                      a => a.type === AttachmentType.vendorInvoice
                    )}
                    images={formatAttachments(rbData)}
                    parentData={rbData}
                    parentId={rbData.id}
                    thumbnailSize={ThumbnailSize.SMALL}
                    user={user}
                    onDelete={handleDeleteImage}
                    onEdit={handleEditImage}
                    onSave={handleAddImage}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={classes.content}>
          <div className={classNames(classes.topbar, classes.formContainer)}>
            <MuiFormWithLoading
              configuration={rbFrameTopFormLayout({
                data: rbData,
                departmentsAreLimitedByJob,
                isVendorInvoiceApproval,
                handleEditImage,
                handleDeleteImage,
                disableVendorInvoiceEdit: type === ProcurementType.BILL
              })}
              customComponents={{
                MuiFormSectionTitle,
                CustomFieldWithLabelNarrow,
                SearchBar,
                VendorDocumentNumberImage
              }}
              data={{ ...rbData, dueDate, accountSyncStatus }}
              isLoading={isLoading}
              layout={mode}
              loadingParams={loadingParams.mainSection}
              validationSchema={generateDefaultValidationSchema(rbFrameTopFormFields)}
              onComplete={getHandleComplete('mainTop')}
              onCreateService={getHandleCreateService('mainTop')}
              onFormChange={handleTopbarFormChange}
            />
          </div>
          {rbData.receiptNumber && (
            <div className={classes.topbar}>
              <SyncStatusChip syncLog={syncLog} syncStatus={syncStatus} />
            </div>
          )}
          <Divider />
          <div className={classes.childrenContainer}>
            <div>{children}</div>
          </div>
        </div>
      </div>
      <GeneratePdfModal
        companyInfo={companyInfo}
        handleClose={() => setShowGeneratePdfModal(false)}
        handlePostReceipt={handlePostReceipt}
        open={showGeneratePdfModal}
        pdfData={rbData}
        receiptItems={receiptItems}
        receiptStatus={status}
        taxRate={finalTaxRate}
        user={user}
      />
      <ApprovalRejectionModal
        data={rbData}
        handleClose={() => setIsApprovalRejectionModalOpen(false)}
        handleOnComplete={handleSaveApprovalRejectionNote}
        isOpen={isApprovalRejectionModalOpen}
        label="Vendor Invoice"
        mode={approvalRejectionModalRef?.current?.mode}
        status={approvalRejectionModalRef?.current?.status}
      />
    </div>
  );
};

CustomFieldWithLabelNarrow.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

CustomFieldWithLabelWrap.propTypes = {
  field: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

ReceiptBillDetailFrame.propTypes = {
  id: PropTypes.string.isRequired,
  rbData: PropTypes.object.isRequired,
  receiptItems: PropTypes.array,
  type: PropTypes.string,
  mode: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  getHandleCreateService: PropTypes.func,
  getHandleComplete: PropTypes.func,
  handleSubmitStart: PropTypes.func,
  setOnSubmitFinal: PropTypes.func,
  isSubmitting: PropTypes.bool,
  handleFormsSubmit: PropTypes.func,
  actionButtonHandler: PropTypes.func,
  actionButtons: PropTypes.object,
  frameDataReducer: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  isFileDownloading: PropTypes.bool,
  isPurchaseOrderVoid: PropTypes.bool,
  isApprovalRejectionModalOpen: PropTypes.bool,
  setIsApprovalRejectionModalOpen: PropTypes.func,
  approvalRejectionModalRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object })
  ]),
  disableEdit: PropTypes.bool,
  isVendorInvoiceApproval: PropTypes.bool,
  receiptStatus: PropTypes.string
};

ReceiptBillDetailFrame.defaultProps = {
  type: ProcurementType.RECEIPT,
  receiptItems: [],
  isSubmitting: false,
  isFileDownloading: false,
  getHandleCreateService: () => {},
  getHandleComplete: () => {},
  handleSubmitStart: () => {},
  setOnSubmitFinal: () => {},
  handleFormsSubmit: () => {},
  actionButtonHandler: () => {},
  frameDataReducer: () => {},
  actionButtons: {},
  isPurchaseOrderVoid: false,
  isApprovalRejectionModalOpen: false,
  setIsApprovalRejectionModalOpen: () => {},
  approvalRejectionModalRef: null,
  disableEdit: false,
  isVendorInvoiceApproval: false,
  receiptStatus: ''
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = { snackbarOn };
const ReduxConnectedReceiptDetailFrame = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceiptBillDetailFrame);

export default ReduxConnectedReceiptDetailFrame;
