import { useSubscription } from '@apollo/client';
import gql from 'graphql-tag';

import { updateWatchedNonVisitQueries } from '@dispatch/Dispatch.utils';
import { nonVisitDetailsFragment } from '@dispatch/fragments';
import { getState } from 'redux/store';

export const NON_VISIT_EVENT_BULK_SUBSCRIPTION = gql`
    subscription nonVisitEventBulkUpdateNotification($partitionKey: String!) {
        nonVisitEventBulkUpdateNotification(partitionKey: $partitionKey) {
            partitionKey
            items {
              ${nonVisitDetailsFragment}   
            }
        }
    }
`;

export const useNonVisitEventBulkSubscription = () => {
  const { tenantId } = getState().user;

  return useSubscription(NON_VISIT_EVENT_BULK_SUBSCRIPTION, {
    variables: { partitionKey: tenantId },
    onSubscriptionData: ({ client, subscriptionData }) => {
      subscriptionData.data.nonVisitEventBulkUpdateNotification.items.forEach(nonVisitEvent =>
        updateWatchedNonVisitQueries(client.cache, nonVisitEvent)
      );
    }
  });
};
