/** @jsxRuntime classic */
/** @jsx jsx */
import React, { memo, useCallback } from 'react';

import { jsx } from '@emotion/react';
import { TablePagination } from '@material-ui/core';
import { func, number } from 'prop-types';

import { useFooterStyles } from './Footer.styles';

const Footer = ({ rowsPerPage, setRowsPerPage, page, setPage, count }) => {
  const styles = useFooterStyles();

  const handleChangePage = useCallback(
    (e, selectedPage) => {
      setPage(selectedPage);
    },
    [setPage]
  );

  const handleChangeRowsPerPage = useCallback(
    e => {
      setRowsPerPage(e.target.value);
    },
    [setRowsPerPage]
  );

  return (
    <div css={styles.footer}>
      <TablePagination
        component="div"
        count={count}
        css={styles.pagination}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};

Footer.propTypes = {
  rowsPerPage: number.isRequired,
  setRowsPerPage: func.isRequired,
  page: number.isRequired,
  setPage: func.isRequired,
  count: number.isRequired
};

export default memo(Footer);
