import React from 'react';

import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  TextField,
  Typography
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import styles from './styles';

const LocalSearch = props => {
  const classes = styles();
  const { onSearch, onFilter, data, filterApplied } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterObjs, setFilterObjs] = React.useState([]);
  const controlCheckbox = (name, label) => {
    const filterNames = filterObjs.map(obj => obj.name);
    return (
      <>
        <Checkbox
          checked={filterNames.includes(name)}
          checkedIcon={<CheckBoxIcon className={`${classes.checkbox} ${classes.selected}`} />}
          disabled={filterNames.includes('all') && name !== 'all'}
          icon={<CheckBoxOutlineBlankIcon className={classes.checkbox} />}
          key={`checkBox${label}`}
          style={{ fontWeight: 'normal' }}
          onChange={event => {
            if (event.target.checked) {
              setFilterObjs([...filterObjs, { name, label }]);
            } else {
              setFilterObjs(filterObjs.filter(filterObj => name !== filterObj.name));
            }
          }}
        />
        <Typography key={`label${label}`} variant="caption">
          {label}
        </Typography>
      </>
    );
  };

  const renderFilterChip = filter => (
    <Chip
      className={classes.chip}
      deleteIcon={<CloseIcon />}
      key={`Chip${filter.label}`}
      label={filter.label}
      onDelete={() => {
        const restOfFilters = filterObjs.filter(obj => obj.name !== filter.name);
        setFilterObjs(restOfFilters);
        onFilter(restOfFilters.map(obj => obj.name));
      }}
    />
  );

  const resetFilter = () => {
    setFilterObjs([]);
    onFilter([]);
    setAnchorEl(null);
  };

  return (
    <Grid container>
      <Grid item>
        <TextField
          autoComplete="off"
          className={classes.searchBoxStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ color: '#999999', fontSize: 16 }} />
              </InputAdornment>
            )
          }}
          placeholder="Filter inventorty items..."
          variant="outlined"
          onChange={onSearch}
        />
        <IconButton
          aria-label="Fitler"
          className={classes.filterButtonStyle}
          onClick={event => setAnchorEl(event.currentTarget)}
        >
          <ArrowDropDown />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          classes={{ paper: classes.popover }}
          className={classes.menu}
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={() => setAnchorEl(null)}
        >
          <Grid className={classes.filterMenu} item>
            <Typography className={classes.filterTitle} variant="caption">
              Quantity On Hand
            </Typography>
            <li>{controlCheckbox('all', 'All')}</li>
            <li>{controlCheckbox('inStock', 'In Stock')}</li>
            <li>{controlCheckbox('belowStock', 'Below Stock')}</li>
            <li>{controlCheckbox('outOfStock', 'Out of Stock')}</li>
            <Grid className={classes.filterActionWrapper} item>
              <Button className={classes.filterActionBtn} variant="text" onClick={resetFilter}>
                Reset
              </Button>
              <Typography variant="caption">|</Typography>
              <Button
                className={classes.filterActionBtn}
                variant="text"
                onClick={() => {
                  onFilter(filterObjs.map(obj => obj.name));
                  setAnchorEl(null);
                }}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </Menu>
      </Grid>
      {!anchorEl && filterApplied && filterObjs.length > 0 && (
        <Grid className={classes.filterSummary} item>
          <Typography className={classes.filterCount}>
            Filters (
            <Typography className={classes.resultCount} variant="caption">
              {data?.length} results
            </Typography>
            ):
          </Typography>
          <Grid item>{filterObjs.map(renderFilterChip)}</Grid>
          {filterObjs.length !== 0 && (
            <Typography className={classes.clear} variant="body3" onClick={resetFilter}>
              Clear All
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default LocalSearch;
