import React from 'react';

import { tableDateFormatter, tableEmptyValueFormatter } from 'components/WrapTable';
import withLazyMount from 'utils/withLazyMount';

import { ReportTableSectionDefaultProps, ReportTableSectionPropTypes } from '../../propTypes';
import { selectAssets } from '../../selectors';
import ReportTable from '../ReportTable';
import ThumbnailCell from '../ThumbnailCell';

import AssetLink from './components/AssetLink';

export const COLUMNS = [
  {
    field: 'id',
    flex: 1,
    hide: true
  },
  {
    field: 'imageUrl',
    flex: 1,
    headerName: ' ',
    align: 'center',
    renderCell: ThumbnailCell
  },
  {
    field: 'assetName',
    flex: 2,
    headerName: 'Asset Name',
    renderCell: AssetLink
  },
  {
    field: 'make',
    flex: 2,
    headerName: 'Make',
    valueFormatter: tableEmptyValueFormatter
  },
  {
    field: 'modelNumber',
    flex: 2,
    headerName: 'Model',
    valueFormatter: tableEmptyValueFormatter
  },
  {
    field: 'serialNo',
    flex: 2,
    headerName: 'Serial Number',
    valueFormatter: tableEmptyValueFormatter
  },
  {
    field: 'installDate',
    flex: 2,
    headerName: 'Install Date',
    valueFormatter: tableDateFormatter
  },
  {
    field: 'location',
    flex: 2,
    headerName: 'Location',
    valueFormatter: tableEmptyValueFormatter
  },
  {
    field: 'propertyZone',
    flex: 2,
    headerName: 'Property Zone',
    valueFormatter: tableEmptyValueFormatter
  }
];

const AssetTable = ({ visit, loading, error }) => {
  const title = 'Assets Worked On';
  const rows = selectAssets(visit) || [];
  return (
    <ReportTable
      columns={COLUMNS}
      error={error}
      loading={loading}
      noDataMessage="No Assets Worked On"
      rows={rows}
      title={title}
    />
  );
};
AssetTable.propTypes = ReportTableSectionPropTypes;
AssetTable.defaultProps = ReportTableSectionDefaultProps;

export default withLazyMount(AssetTable);
