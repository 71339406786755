import React from 'react';

import { array, func } from 'prop-types';

import { EventEntities } from '@dispatch/Dispatch.constants';
import { EventDataPropTypes } from '@dispatch/Dispatch.props';
import { prepareNonVisitData } from '@dispatch/Dispatch.utils';
import { useFormService } from '@dispatch/hooks';
import { useDispatchSettings } from '@dispatch/settings';
import { useSnackbar } from 'customHooks/useSnackbar';
import { getState } from 'redux/store';

import DrawerLayout from '../DrawerLayout';
import EventBadge from '../EventBadge';
import NonBillableEventForm from '../NonBillableEventForm';

const CreateNonBillableEvent = ({
  addNonVisitTuple,
  addRecurringNonBillableTuple,
  eventData,
  closeDrawer
}) => {
  const { tenantId, tenantCompanyId } = getState().user;
  const nonVisitEventData = prepareNonVisitData(eventData);
  const [triggerMutation, mutationResponse] = addNonVisitTuple;
  const [triggerAddRecurringEventMutation] = addRecurringNonBillableTuple;
  const { isDirty, onCreateService, onDirtyChange, resetForm, submitForm } = useFormService();
  const { loading } = mutationResponse;
  const { showDepartmentForNonBillable } = useDispatchSettings();
  const snackbar = useSnackbar();

  const createNonVisitEvent = (data, shouldCreateRecurringEvents) => {
    closeDrawer();
    if (shouldCreateRecurringEvents) {
      (async () => {
        snackbar('success', 'Creating your non-billable events. This may take a while.');
        const response = await triggerAddRecurringEventMutation({
          ...data,
          employeeId: eventData.primaryTechId
        });
        if (response) {
          snackbar('success', 'Recurring non-billable events created successfully.');
        }
      })();
    } else {
      triggerMutation({ tenantId, tenantCompanyId, data, techId: eventData.primaryTechId });
    }
    resetForm();
  };

  const renderHeader = () => (
    <EventBadge eventType={EventEntities.NON_BILLABLE_EVENT.value.clientValue} />
  );

  const renderBody = () => (
    <NonBillableEventForm
      allowMultipleEventsCreation
      disabled={loading}
      enableDepartment={showDepartmentForNonBillable}
      eventData={nonVisitEventData}
      onComplete={createNonVisitEvent}
      onCreateService={onCreateService}
      onDirtyChange={onDirtyChange}
    />
  );

  return (
    <DrawerLayout
      disabledClose={loading}
      disabledSave={loading}
      isDirty={isDirty}
      loading={loading}
      renderBody={renderBody}
      renderHeader={renderHeader}
      onResetForm={resetForm}
      onSubmitForm={submitForm}
    />
  );
};

CreateNonBillableEvent.propTypes = {
  addNonVisitTuple: array.isRequired,
  addRecurringNonBillableTuple: array.isRequired,
  eventData: EventDataPropTypes.isRequired,
  closeDrawer: func.isRequired
};

export default CreateNonBillableEvent;
