import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

/**
 *  Compnent combines all fields in the formik values based on type prefix
 */
const HighlightAddress = ({ field, form, specialbehaviour, classes, ...rest }) => {
  let addressValue;
  // fields should be available and prefixed with address type
  const { fontWeight } = specialbehaviour;
  const addressType = field.name;
  const addressLine1 = form.values[`${addressType}AddressLine1`] || '';
  const addressLine2 = form.values[`${addressType}AddressLine2`] || '';
  const city = form.values[`${addressType}City`] || '';
  const state = form.values[`${addressType}State`] || '';
  const zipcode = form.values[`${addressType}Zipcode`] || '';

  addressValue = addressLine1 ? `${addressLine1}, ` : '';
  addressValue += addressLine2 ? `${addressLine2}, ` : '';
  addressValue += city ? `${city}, ` : '';
  addressValue += state ? `${state}, ` : '';
  addressValue += zipcode;

  let fieldLabel = rest.label;
  if (fieldLabel && fieldLabel.charAt(0) === '*') {
    fieldLabel = fieldLabel.slice(1);
  }

  return (
    <>
      <Typography className={classes.label} variant="caption">
        {fieldLabel}
      </Typography>

      <Typography
        className={
          fontWeight && fontWeight === 'normal' ? classes.normalContent : classes.boldContent
        }
      >
        {addressValue || '-'}
      </Typography>
    </>
  );
};
// export default MuiTextInput;
export default withStyles(styles)(HighlightAddress);
