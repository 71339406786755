import React, { useCallback, useEffect, useReducer, useState } from 'react';

import { FileUploader, ThemeProvider, TV, Typography } from '@BuildHero/sergeant';

import { validateLicense as syncfusionValidateLicense } from '@syncfusion/ej2-base';
import PropTypes from 'prop-types';

import { AttachmentItemPreview } from 'components';

import { deleteAttachment } from 'scenes/ServiceAgreements/DetailView/service';

const uuidRegEx = new RegExp(
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
);

const reducerActions = {
  ADD: 'add',
  REMOVE: 'remove'
};

const reducers = {
  [reducerActions.ADD]: (state, payload) => [
    ...state,
    ...payload.map(file => {
      const { id, linkUrl: fileUrl, name: fileName, rawFile, size: fileSize, type } = file;
      return {
        id,
        blob: new Blob([rawFile], { type }),
        fileUrl,
        fileName,
        fileSize,
        type
      };
    })
  ],
  [reducerActions.REMOVE]: (state, payload) => state.filter(file => file.id !== payload.id)
};

const filesDataReducer = (state, { actionType, payload }) => {
  const fn = reducers[actionType];
  return fn ? fn(state, payload) : state;
};

const Attacher = ({ data, limit, title, updateFileArray, multiple }) => {
  const [filesData, dispatchFilesData] = useReducer(filesDataReducer, data);
  const [fileUploaderState, setFileUploaderState] = useState({ element: { value: undefined } });

  syncfusionValidateLicense();

  useEffect(() => {
    updateFileArray(filesData);
  }, [filesData, updateFileArray]);

  const handleUpdateFilesByUploader = useCallback(
    (actionType, payload) => {
      // Reset the value of the html input so the same files can be submitted again
      fileUploaderState.element.value = '';
      dispatchFilesData({
        actionType,
        payload
      });
    },
    [fileUploaderState.element.value]
  );

  const handleRemoveUploadedFiles = useCallback(
    async (actionType, payload) => {
      if (uuidRegEx.test(payload.id)) {
        await deleteAttachment(payload.id, () => {});
      }
      dispatchFilesData({ actionType, payload });
      handleUpdateFilesByUploader(actionType, payload);
    },
    [handleUpdateFilesByUploader]
  );

  return (
    <div
      css={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flexStart',
        padding: 24,
        borderRadius: 16
      }}
    >
      <ThemeProvider>
        <Typography
          css={{
            marginBottom: '10px',
            fontWeight: 700
          }}
          variant={TV.BASE}
        >
          {title}
        </Typography>
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          {filesData.map(fileData => (
            <AttachmentItemPreview
              fileData={fileData}
              key={fileData.id}
              suppressRenderError
              onRemoveFile={handleRemoveUploadedFiles}
            />
          ))}
        </div>
        {(!limit || limit > filesData.length) && (
          <FileUploader
            handleFilesChange={(actionType, payload) =>
              handleUpdateFilesByUploader(actionType, payload)
            }
            multiple={multiple}
            onCreateUploader={uploader => setFileUploaderState(uploader)}
          />
        )}
      </ThemeProvider>
    </div>
  );
};

Attacher.propTypes = {
  data: PropTypes.array.isRequired,
  limit: PropTypes.number,
  title: PropTypes.string.isRequired,
  updateFileArray: PropTypes.func.isRequired,
  multiple: PropTypes.bool
};

Attacher.defaultProps = {
  limit: 0,
  multiple: true
};

export default Attacher;
