import React, { memo, useMemo } from 'react';

import { Skeleton } from '@material-ui/lab';
import { arrayOf, number, object } from 'prop-types';

import NonVisitCard from '@dispatch/components/NonVisitCard';
import VisitCard from '@dispatch/components/VisitCard';
import { TECH_PROP } from '@dispatch/queries';

import { CardTypes } from '../../../../../../DispatchBoard.constants';

import AvailableTimeCard from '../AvailableTimeCard';

import { useStyles } from './DayCell.styles';

const DayCell = ({ dayEvents, tech, laneIndex, dayIndex, eventsLoading }) => {
  const styles = useStyles();
  const cell = useMemo(() => ({ laneIndex, dayIndex }), [laneIndex, dayIndex]);

  if (eventsLoading) {
    return (
      <div css={styles.root}>
        <Skeleton css={styles.loadingCell} variant="rect" />
      </div>
    );
  }

  return (
    <div css={styles.root}>
      {dayEvents.map((event, i) => {
        switch (event.__typename) {
          case CardTypes.AvailableTime: {
            return (
              <AvailableTimeCard
                availableTime={event}
                cell={cell}
                isLastEvent={event.isLastEvent}
                key={event?.range?.start?.unix() || i}
                tech={tech}
              />
            );
          }
          case CardTypes.Visit: {
            return <VisitCard key={event.id} srcTech={tech.id} visit={event} />;
          }
          case CardTypes.ManDay:
          case CardTypes.NonVisitEvent: {
            return <NonVisitCard event={event} key={event.id} srcTech={tech.id} weekView />;
          }
          default: {
            return null;
          }
        }
      })}
    </div>
  );
};

DayCell.propTypes = {
  dayEvents: arrayOf(object).isRequired,
  tech: TECH_PROP.isRequired,
  laneIndex: number.isRequired,
  dayIndex: number.isRequired
};

export default memo(DayCell);
