import { useCallback } from 'react';

import gql from 'graphql-tag';
import { useSelector } from 'react-redux';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { PROPERTY_CHECKLISTS } from '../index';

import { getTaskTemplateCheckLists, TaskTemplateFragment } from './useChecklistLibrary';

const UPDATE_TASK_TEMPLATE = gql`
  mutation updateTaskTemplateChecklist(
    $taskTemplateChecklistId: String!
    $input: TaskTemplateChecklistUpdateInput!
  ) {
    updateTaskTemplateChecklist(taskTemplateChecklistId: $taskTemplateChecklistId, input: $input) {
      taskTemplateChecklist {
        id
        name
        isDefault
        assetTypeId
        taskTemplates {
          ${TaskTemplateFragment}
        }
      }
      updatedTaskTemplateChecklistIsDefault {
        id
        isDefault
      }
    }
  }
`;

export const useUpdateTaskTemplateChecklist = (options = {}) => {
  const user = useSelector(state => state.user);

  const update = useCallback(
    (cache, { data: { updateTaskTemplateChecklist } }) => {
      const variables = {
        tenantId: user.tenantId,
        assetTypeId: updateTaskTemplateChecklist.taskTemplateChecklist.assetTypeId || 'null'
      };
      const cachedData = cache.readQuery({
        query: getTaskTemplateCheckLists,
        variables
      });

      const existingData =
        cachedData?.taskTemplateChecklists?.filter(
          item => item.id !== updateTaskTemplateChecklist.id
        ) || [];

      if (updateTaskTemplateChecklist?.updatedTaskTemplateChecklistIsDefault?.length) {
        const otherDefaultUpdatesMap = updateTaskTemplateChecklist.updatedTaskTemplateChecklistIsDefault.map(
          item => ({ [item.id]: item.isDefault })
        );
        existingData.map(cList => {
          if (otherDefaultUpdatesMap[cList]) {
            return { ...cList, isDefault: otherDefaultUpdatesMap[cList] };
          }
          return cList;
        });
      }

      const updatedChecklists = {
        taskTemplateChecklists: [...existingData, updateTaskTemplateChecklist.taskTemplateChecklist]
      };

      cache.writeQuery({
        query: getTaskTemplateCheckLists,
        variables,
        data: { taskTemplateChecklists: updatedChecklists }
      });
    },
    [user.tenantId]
  );

  return useExtendedMutation(UPDATE_TASK_TEMPLATE, {
    serializer: ({ id, assetTypeId, name, isDefault = false }) => ({
      variables: {
        taskTemplateChecklistId: id,
        input: {
          name,
          isDefault,
          assetTypeId: assetTypeId === PROPERTY_CHECKLISTS.id ? undefined : assetTypeId
        }
      }
    }),
    update,
    onSuccess: (transformedData, snackbarOn) => snackbarOn('success', 'Checklist was updated'),
    onError: (transformedData, snackbarOn) => snackbarOn('error', 'Unable to Update Checklist'),
    ...options
  });
};
