import { useCallback } from 'react';

import gql from 'graphql-tag';

import { useSelector } from 'react-redux';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import {
  mutationOperations,
  updateTaskTemplateCacheForTaskTemplateChecklistQuery
} from '../helper';

import { getTaskTemplateCheckLists } from './useChecklistLibrary';

const DELETE_TASK_TEMPLATE = gql`
  mutation deleteTaskTemplates($input: [String!]!) {
    deleteTaskTemplates(input: $input)
  }
`;

export const useDeleteTaskTemplate = ({ assetTypeId, checklistId, ...options }) => {
  const user = useSelector(state => state.user);
  const update = useCallback(
    (cache, { data: { deleteTaskTemplates } }) => {
      const queryVariables = {
        tenantId: user.tenantId,
        assetTypeId: assetTypeId || 'null'
      };
      const cachedData = cache.readQuery({
        query: getTaskTemplateCheckLists,
        variables: queryVariables
      });

      const updatedTaskTemplateChecklists = updateTaskTemplateCacheForTaskTemplateChecklistQuery(
        cachedData,
        checklistId,
        deleteTaskTemplates,
        mutationOperations.DELETE
      );

      cache.writeQuery({
        query: getTaskTemplateCheckLists,
        data: { taskTemplateChecklists: updatedTaskTemplateChecklists },
        variables: queryVariables
      });
    },
    [assetTypeId, checklistId, user.tenantId]
  );

  return useExtendedMutation(DELETE_TASK_TEMPLATE, {
    serializer: ({ id }) => ({
      variables: {
        input: [id]
      }
    }),
    update,
    onError: (transformedData, snackbarOn) => snackbarOn('error', 'Unable to Delete Task template'),
    ...options
  });
};
