import React, { useState } from 'react';

import { Divider, ThemeProvider } from '@BuildHero/sergeant';

import { Typography } from '@material-ui/core';

import { connect } from 'react-redux';

import { DefaultButton, Placeholder, UserPermission } from 'components';
import { ViewVisit, VisitCreateModal } from 'components/Visit';
import useEmployees from 'customHooks/useEmployees';
import { isTenantSettingEnabled } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';
import { JobStatus } from 'utils/constants';

const VisitsTab = props => {
  const { visitsData, jobData, tasksData, propertyAssets, user, fetchVisits, isLoading } = props;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const isAssetDropdownEnabled = isTenantSettingEnabled('assetTracking');

  const [activeTechs] = useEmployees({
    includeDepartments: true,
    filter: { isActive: { eq: true }, isTech: { eq: true } }
  });

  let maxVisitNumber = 0;
  if (visitsData.length) {
    const visitNumbers = visitsData.map(el => parseInt(el.visitNumber, 10));
    maxVisitNumber = Math.max(...visitNumbers);
  }
  return (
    <>
      <UserPermission action={PermissionConstants.OBJECT_VISIT} I="create">
        {![JobStatus.COMPLETE, JobStatus.CANCELED, JobStatus.ON_HOLD, 'On hold'].includes(
          jobData.status
        ) && (
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <DefaultButton
              color="primary"
              handle={() => {
                setIsPopupOpen(true);
              }}
              label="Add Visit"
            />
          </div>
        )}
      </UserPermission>
      {isLoading || !activeTechs ? (
        <Placeholder repeatCount={3} />
      ) : (
        <>
          {visitsData.length === 0 && (
            <Typography style={{ lineHeight: 4 }} variant="caption">
              No Visits
            </Typography>
          )}
          {visitsData.map(item => (
            <div key={item.id}>
              <ThemeProvider>
                <Divider />
              </ThemeProvider>
              <ViewVisit
                continueInNewVisit={() => {
                  setIsPopupOpen(true);
                }}
                enableAssetDropDown={isAssetDropdownEnabled}
                handleDelete={() => fetchVisits()}
                job={jobData}
                key={item.visitNumber}
                locale={user.locale}
                nextVisitNumber={maxVisitNumber + 1}
                propertyAssets={propertyAssets}
                tasks={tasksData}
                techs={activeTechs}
                updateVisits={() => fetchVisits()} // for on hold status, new visit needs to be created when continue in new visit is clicked
                user={props.user}
                visit={item}
              />
            </div>
          ))}
        </>
      )}
      <VisitCreateModal
        handleClose={() => setIsPopupOpen(false)}
        job={jobData}
        locale={props.user.locale}
        open={isPopupOpen}
        propertyAssets={propertyAssets}
        techs={activeTechs}
        user={props.user}
        visit={{}}
        onComplete={() => fetchVisits()}
      />
    </>
  );
};

export default connect(state => ({ user: state.user }), null)(VisitsTab);
