import gql from 'graphql-tag';

const validateAccountingEntity = gql`
  mutation validateAccountingEntity(
    $tenantId: String!
    $entityName: String!
    $entityRecord: JSON!
  ) {
    validateAccountingEntity(
      tenantId: $tenantId
      entityName: $entityName
      entityRecord: $entityRecord
    ) {
      valid
      errors
    }
  }
`;

export default validateAccountingEntity;
