import React, { useRef, useState } from 'react';

import { Button } from '@BuildHero/sergeant';
import { IconButton } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { bool, func } from 'prop-types';

import { PopperMenu } from 'components';

import { useStyles } from './AddLineItemButton.styles';

const AddLineItemButton = ({
  disabled,
  onAddLineItem,
  onAddLineItemsForTechnicians,
  disableAddLineItemsForTechnicians
}) => {
  const classes = useStyles();
  const menuIconRef = useRef();
  const [menuOpen, setMenuOpen] = useState();
  const handleMenuClick = () => {
    setMenuOpen(true);
  };
  const handleMenuClose = () => {
    setMenuOpen(false);
  };
  return (
    <div css={classes.container({ disabled })}>
      <Button
        css={classes.button}
        disabled={disabled || disableAddLineItemsForTechnicians}
        size="small"
        type="secondary"
        onClick={onAddLineItemsForTechnicians}
      >
        Add Labor For Techs Who Worked
      </Button>
      <div css={classes.divider} />
      <IconButton css={classes.menu} disableRipple ref={menuIconRef} onClick={handleMenuClick}>
        <ArrowDropDownIcon style={{ color: 'white' }} />
      </IconButton>
      <PopperMenu
        anchorEl={menuIconRef.current}
        itemList={[
          {
            label: 'Add Labor For Other Techs',
            key: 'AddLaborLineItem',
            onClick: onAddLineItem,
            disabled
          }
        ]}
        open={menuOpen}
        onClose={handleMenuClose}
      />
    </div>
  );
};
AddLineItemButton.propTypes = {
  disabled: bool.isRequired,
  onAddLineItem: func.isRequired,
  onAddLineItemsForTechnicians: func.isRequired,
  disableAddLineItemsForTechnicians: bool.isRequired
};
export default AddLineItemButton;
