import React from 'react';

import { func } from 'prop-types';

import XGrid from 'components/XGrid';

import { useColumns } from './VisitsTable.columns';
import { GET_VISITS } from './VisitsTable.query';

const VisitsTable = ({ onVisitClick, ...xGridProps }) => {
  const columns = useColumns({ onVisitClick });

  return <XGrid columns={columns} entityTypeName="Visit" query={GET_VISITS} {...xGridProps} />;
};

VisitsTable.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onVisitClick: func
};

VisitsTable.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  tableName: 'VisitsXGrid'
};

export default VisitsTable;
