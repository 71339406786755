import React from 'react';

import { shape, string } from 'prop-types';

import VisitCard from '@dispatch/components/VisitCard';
import { VisitStatuses } from '@dispatch/Dispatch.constants';
import { emptyVisitDetailsFragment } from '@dispatch/fragments';
import mergeDeep from 'utils/mergeDeep';

const formatVisitPartial = job => {
  const fragment = {
    job,
    status: VisitStatuses.UNKNOWN.key,
    Status: VisitStatuses.UNKNOWN,
    property: {
      customerPropertyName: job?.property?.companyName,
      id: job?.property?.id
    }
  };

  return mergeDeep(emptyVisitDetailsFragment, fragment);
};

const JobDetailsHeader = ({ job }) => {
  return <VisitCard isDrawer visit={formatVisitPartial(job)} />;
};

JobDetailsHeader.propTypes = {
  job: shape({
    customerId: string,
    customerName: string,
    jobNumber: string,
    property: shape({
      companyName: string.isRequired,
      id: string.isRequired
    })
  }).isRequired
};

export default JobDetailsHeader;
