import { gql } from '@apollo/client/core';

import { getTenantIdAndSortKey } from '@dispatch/Dispatch.utils';
import useExtendedQuery from 'customHooks/useExtendedQuery';

const GET_PAYROLL_HOUR_TYPES = gql`
  query getPayrollHourTypes(
    $partitionKey: String!
    $sortKey: String!
    $filter: TablePayrollHourTypeFilterInput
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      payrollHourTypes(filter: $filter) {
        items {
          id
          hourType
          sortOrder
          isArchived
        }
      }
    }
  }
`;

const transform = data =>
  !data?.getCompany?.payrollHourTypes?.items?.length
    ? []
    : [...data.getCompany.payrollHourTypes.items].sort((a, b) => a.sortOrder - b.sortOrder);

const usePayrollHourTypes = options => {
  const { tenantId, sortKey } = getTenantIdAndSortKey();

  return useExtendedQuery(GET_PAYROLL_HOUR_TYPES, {
    variables: {
      filter: { isActive: { eq: true }, isArchived: { eq: false } },
      partitionKey: tenantId,
      sortKey
    },
    defaultData: [],
    transform,
    ...options
  });
};

export default usePayrollHourTypes;
