import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const UPDATE_TASK = gql`
  mutation updateTask($partitionKey: String!, $data: UpdateTaskInput!) {
    updateTask(partitionKey: $partitionKey, data: $data) {
      id
      version
      moreInfoNeeded
    }
  }
`;

const serializer = ({ tenantId, data }) => ({
  variables: {
    partitionKey: tenantId,
    data
  }
});

export const useUpdateTask = () => {
  return useExtendedMutation(UPDATE_TASK, {
    serializer
  });
};
