import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function Inventory(props) {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SvgIcon {...props}>
      <path d="M8.14286 1H15.8571V3H15V4.5H9V3H8.14286V1ZM17.2795 8.30653L10.3 15.557L7.72049 12.878L6.27951 14.2649L10.3 18.442L18.7205 9.69347L17.2795 8.30653ZM20 3C20.5523 3 21 3.44772 21 4V22C21 22.5523 20.5523 23 20 23H4C3.44772 23 3 22.5523 3 22V4C3 3.44772 3.44772 3 4 3H7.5V5.5H16.5V3H20Z" />
    </SvgIcon>
  );
}
