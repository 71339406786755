import React, { useEffect, useMemo } from 'react';

import { MUIForm } from '@BuildHero/sergeant';

import { useCompanyTimezone } from '@pm/components/hooks';

import AlgoliaSearchWrapper from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';

import { searchIndex } from 'constants/algoliaIndex';
import useEmployees from 'customHooks/useEmployees';
import { constructSelectOptions } from 'utils/constructSelectOptions';

const configuration = ({ departments, companyTimezone, soldByOptions, serviceAgreement }) => ({
  fields: {},
  layouts: {
    default: {
      options: {},
      contents: [
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                grow: 1,
                padding: 8
              },
              contents: [
                {
                  component: 'TextInput',
                  source: 'agreementName',
                  options: {
                    isRequired: true,
                    label: 'Agreement Name'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                grow: 1,
                padding: 8
              },
              contents: [
                {
                  component: 'AlgoliaSearchWrapper',
                  source: 'customerName',
                  options: {
                    label: 'Customer',
                    placeholder: 'Search & Select Customer',
                    searchIndexName: searchIndex,
                    searchResultDisplayFields: ['customerName'],
                    required: true,
                    disabled: serviceAgreement?.customerProperties?.items?.length,
                    filters: [
                      {
                        attribute: 'entityType',
                        valueArray: ['Customer']
                      }
                    ],
                    valuesToSet: [
                      {
                        customerName: 'customerName',
                        customerId: 'id'
                      }
                    ]
                  }
                }
              ]
            },
            {
              options: {
                grow: 1,
                padding: 8
              },
              contents: [
                {
                  component: 'AlgoliaSearchWrapper',
                  source: 'billingCustomerName',
                  options: {
                    label: 'Billing Customer',
                    placeholder: 'Search & Select Customer',
                    searchIndexName: searchIndex,
                    searchResultDisplayFields: ['customerName'],
                    required: true,
                    filters: [
                      {
                        attribute: 'entityType',
                        valueArray: ['Customer']
                      }
                    ],
                    valuesToSet: [
                      {
                        billingCustomerName: 'customerName',
                        billingCustomerId: 'id'
                      }
                    ]
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                grow: 1,
                padding: 8
              },
              contents: [
                {
                  component: 'DateInput',
                  source: 'startDate',
                  options: {
                    isRequired: true,
                    label: 'Start Date',
                    timezone: companyTimezone
                  }
                }
              ]
            },
            {
              options: {
                grow: 1,
                padding: 8
              },
              contents: [
                {
                  component: 'DateInput',
                  source: 'endDate',
                  options: {
                    label: 'End Date',
                    timezone: companyTimezone
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                grow: 1,
                padding: 8
              },
              contents: [
                {
                  component: 'SelectInput',
                  source: 'departmentId',
                  options: {
                    isRequired: true,
                    isSearchable: true,
                    valueSet: departments,
                    label: 'Department'
                  }
                }
              ]
            },
            {
              options: {
                grow: 1,
                padding: 8
              },
              contents: [
                {
                  component: 'SelectInput',
                  source: 'soldById',
                  options: {
                    isSearchable: true,
                    valueSet: soldByOptions,
                    label: 'Sold By'
                  }
                }
              ]
            },
            {
              options: {
                grow: 1,
                padding: 8
              },
              contents: [
                {
                  component: 'TextInput',
                  source: 'customerPoNumber',
                  options: { label: 'PO Number' }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      agreementName: {
        type: 'string'
      },
      customerName: {
        type: 'string'
      },
      billingCustomerName: {
        type: 'string'
      }
    }
  },
  validationErrors: {
    agreementName: {
      required: 'Field is required.'
    },
    customerName: {
      required: 'Field is required'
    },
    billingCustomerName: {
      required: 'Field is required'
    }
  }
});

const Overview = ({
  service,
  setService,
  saveData,
  data,
  departments,
  revisiting = false,
  onDirty,
  initialized
}) => {
  const [soldByOptions] = useEmployees({
    filter: { isActive: { eq: true } },
    transform: activeEmployees => constructSelectOptions(activeEmployees, 'name')
  });

  useEffect(() => {
    if (revisiting && service) {
      service.validateForm();
    }
  }, [service]);

  useEffect(() => {
    onDirty(false);
  }, []);

  const initialData = useMemo(() => data, [initialized]);

  const [{ data: companyTimezone }] = useCompanyTimezone();

  return (
    <MUIForm
      configuration={configuration({
        departments,
        companyTimezone,
        soldByOptions,
        serviceAgreement: data
      })}
      customComponents={{
        AlgoliaSearchWrapper
      }}
      data={initialData}
      key="Overview"
      layouts="edit"
      validate={newData => {
        saveData(oldData => ({
          ...oldData,
          ...newData
        }));

        const errors = {};
        if (!newData.agreementName) {
          errors.agreementName = 'Field is required';
        }
        if (!newData.customerId || !newData.customerName) {
          errors.customerName = 'Field is required';
        }
        if (!newData.billingCustomerId || !newData.billingCustomerName) {
          errors.billingCustomerName = 'Field is required';
        }
        if (!newData.startDate) {
          errors.startDate = 'Field is required';
        }
        if (newData.endDate && newData.endDate < newData.startDate) {
          errors.endDate = 'End date cannot be earlier than start date';
        }
        if (!newData.departmentId) {
          errors.departmentId = 'Field is required';
        }

        return errors;
      }}
      onComplete={() => {}}
      onCreateService={s => setService(s)}
      onDirtyChange={onDirty}
      onFieldChange={(field, value) => {
        setService(currentService => {
          if (['customerId', 'customerName'].includes(field)) {
            if (value) {
              const customerToBillingCustomerFieldMap = {
                customerId: 'billingCustomerId',
                customerName: 'billingCustomerName'
              };
              const billingCustomerField = customerToBillingCustomerFieldMap[field];
              saveData(oldData => {
                const billingCustomerValue = oldData[billingCustomerField] || value;
                currentService.formikContext.setFieldValue(
                  billingCustomerField,
                  billingCustomerValue
                );
                return {
                  ...oldData,
                  [field]: value,
                  [billingCustomerField]: billingCustomerValue
                };
              });
            } else {
              saveData(oldData => ({
                ...oldData,
                customerId: null,
                customerName: null
              }));
            }
          }
          if (['billingCustomerId', 'billingCustomerName'].includes(field)) {
            if (value) {
              saveData(oldData => ({
                ...oldData,
                [field]: value
              }));
            } else {
              saveData(oldData => ({
                ...oldData,
                billingCustomerId: null,
                billingCustomerName: null
              }));
            }
          }
          return currentService;
        });
      }}
    />
  );
};

export default Overview;
