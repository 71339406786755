import React, { useEffect, useRef, useState } from 'react';

import { makeStyles, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import classNames from 'classnames';
import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { TESTING_ID } from 'utils/constants';
import TestingIdUtils from 'utils/TestingIdUtils';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiAutocomplete-endAdornment': {
      right: '6px'
    }
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  label: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'normal',
    letterSpacing: '0.01px',
    lineHeight: '14px',
    marginTop: '0px'
  },
  requiredLabel: {
    fontSize: '8px',
    letterSpacing: 0,
    lineHeight: '14px',
    marginLeft: '6px'
  },
  searchBar: {},
  searchField: {}
}));

/**
 * @param {function} onSelectionChange              function callback to get selection outside of muiform
 * @param {function} onResultChange                 function callback to get results from api outside of muiform
 * @param {string}   options.placeholder            placeholder text
 * @param {function} options.resultFormatFunction   function callback to format the results fetched from the API
 * @param {boolean}  options.[multiple]             if true, the field will allow for multiple entries, and they will be stored in an array that gets passed to the MUIForm field
 * @param {function} options.searchFunction         API function of form (searchText, [searchColumns]) => Promise to get data from backend
 * @param {function} options.[emptySearchFunction]  if !minChars, the searchbar will get results using this function when the search query is empty
 * @param {array}    options.[searchColumns]        an array of column labels from the backend table that you're searching on. If set, the searchFunction will search on these columns instead of the default column
 * @param {int}      options.[minChars]             if set, the component will only start searching once searchText.length >= minChars
 * @param {boolean}  options.[searchOnOpen]         if true, the component will always do a empty search when popup is opened.
 *  It is useful when searchFunction depends on external states (e.g. selectedCustomer?.id) to make sure the states used are up-to-date.
 * @param {boolean}  options.[useId]                if true, the component will return only the object's id rather than the object itself
 * @param {boolean}  options.[frontendFiltering]    if true, the component will filter search results on the frontend based on the input
 * @param {boolean}  options.[hideOptions]          if true, the options list will be hidden from the user
 * */

const SearchBar = ({ options, field, form, className, onSelectionChange, onResultChange }) => {
  const {
    placeholder,
    resultFormatFunction,
    multiple,
    searchFunction,
    emptySearchFunction,
    searchColumns,
    minChars,
    searchOnOpen,
    useId,
    frontendFiltering,
    hideOptions,
    searchParams,
    disableClearable,
    disablePreFill,
    clearOnSelect,
    disabled,
    noDuplicates,
    filterFunction, // This is a function that can be passed in for filtering the given search results
    sortFunction, // This is a function that can be passed in for sorting the given search results
    onSelect, // same as onSelectionChange, I'm adding it to options so you can use it in a MUIForm,
    additionalSearchData, // to add some data and make a group in front of the search results
    freeSolo, // allows freeform text entry for the field,
    needRefresh, // to refresh input text using current field values
    setDefaultValue,
    resetDependentDropdownsOnSelection,
    testingid
  } = options;
  const [selection, setSelection] = useState(multiple ? [] : field?.value || null);
  const [searchResults, setSearchResults] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const fetchCounter = useRef(0);
  const classes = useStyles();
  const errorText = (form && field && form.errors && form.errors[field.name]) || '';
  const searchBarTestingId = TestingIdUtils.generateTestingId({
    customTestingId: testingid,
    componentDefaultTestingId: TESTING_ID.SEARCH_BAR
  });

  const removeSelectedFromOptions = allOptions => {
    if (selection && Array.isArray(selection) && Array.isArray(allOptions)) {
      const selectedIds = selection.map(sel => sel.id);
      const filteredResults = allOptions.filter(result => !selectedIds.includes(result.id));
      return filteredResults;
    }
    return allOptions;
  };

  const search = async (value, customSearchFunction) => {
    const localFetchCounter = fetchCounter.current + 1;
    fetchCounter.current += 1;
    let resultOptions;
    let searchResult;
    if (customSearchFunction) {
      resultOptions = await (searchColumns
        ? customSearchFunction(value, searchColumns)
        : customSearchFunction(value));
    } else if (searchParams && Array.isArray(searchParams)) {
      searchResult = await (searchColumns
        ? searchFunction(value, searchColumns, ...searchParams)
        : searchFunction(value));
      if (filterFunction) searchResult = filterFunction(searchResult);
      resultOptions = [
        ...(additionalSearchData?.length
          ? [
              ...additionalSearchData,
              // to make a group together
              ...searchResult.map(item => ({ ...item, groupLabel: 'Default Departments' }))
            ]
          : searchResult)
      ];
    } else {
      searchResult = await (searchColumns
        ? searchFunction(value, searchColumns)
        : searchFunction(value));
      if (filterFunction) searchResult = filterFunction(searchResult);
      if (additionalSearchData?.length) {
        resultOptions = [
          ...additionalSearchData,
          // to make a group together
          ...searchResult.map(item => ({ ...item, groupLabel: 'Default Departments' }))
        ];
      } else {
        resultOptions = Array.isArray(searchResult) ? [...searchResult] : [];
      }
    }
    if (multiple && noDuplicates) {
      resultOptions = removeSelectedFromOptions(resultOptions);
    }
    // only update search options associated with the latest user inputs
    if (localFetchCounter >= fetchCounter.current) {
      setSearchResults(resultOptions);
    }
  };

  const checkAndSearch = value => {
    if (minChars) {
      if (value?.length >= minChars) {
        search(value);
      } else {
        setSearchResults([]);
      }
    } else if (value?.length === 0) {
      emptySearchFunction ? search(value, emptySearchFunction) : search(value);
    } else {
      search(value);
    }
  };

  const onSelection = value => {
    setSelection(value);
  };

  useEffect(() => {
    if (field && form) {
      let value;
      if (useId) {
        if (multiple) {
          value = selection.map(item => item.id || null);
        } else if (selection) {
          value = selection.id;
        }
      } else {
        value = selection;
      }
      if (field.value !== value) {
        form.setFieldValue(field.name, value);
      }
    }
    if (onSelectionChange) {
      onSelectionChange(selection);
    }
    if (onSelect) {
      onSelect(selection, field, form, isOpen);
    }
    if (clearOnSelect && selection !== null) {
      setSelection(null);
    }
    if (multiple && noDuplicates) {
      search(userInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  useEffect(() => {
    if (onResultChange) {
      onResultChange(searchResults);
    }

    if (setDefaultValue) {
      setDefaultValue({ searchResults, selection, isOpen, setSelection, form });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults]);

  useEffect(() => {
    if (!disablePreFill) {
      if (field && field.value) {
        if (multiple) {
          if (
            Array.isArray(field.value) &&
            field.value?.length > 0 &&
            (!selection || (Array.isArray(selection) && selection.length === 0))
          ) {
            if (noDuplicates) {
              const uniqueId = [...new Set(field.value.map(element => element?.id))];
              setSelection(uniqueId.map(element => field.value.find(x => x?.id === element)));
            } else {
              setSelection(field.value);
            }
          }
        } else if (
          typeof field.value === 'object' &&
          !Array.isArray(field.value) &&
          !isEmpty(field.value) &&
          (isEmpty(selection) || needRefresh)
        ) {
          setSelection(field.value);
        }
      } else if (field && !field.value && isEmpty(selection) && setDefaultValue) {
        checkAndSearch('');
      } else if (
        field &&
        !field.value &&
        !isEmpty(selection) &&
        resetDependentDropdownsOnSelection
      ) {
        setSelection(null);
      } else if (needRefresh && field && field.value !== selection) {
        // this check is here incase the field's value was changed outside of this component.
        // EX: ProjectManagement/components/CreateProject/index.js line 166.
        if (!field.value) {
          setSelection(null);
        } else {
          setSelection(field.value);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  useEffect(() => {
    if (!searchResults.length) {
      checkAndSearch('');
    }
  }, []);

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.labelContainer}>
        {options.label && (
          <Typography className={classes.label} gutterBottom variant="caption">
            {options.label}
          </Typography>
        )}
        {options.isRequired && (
          <Typography className={classes.requiredLabel} gutterBottom variant="caption">
            REQUIRED
          </Typography>
        )}
        {options.isOptional && (
          <Typography className={classes.requiredLabel} gutterBottom variant="caption">
            OPTIONAL
          </Typography>
        )}
      </div>
      <Autocomplete
        className={classes.searchBar}
        clearOnBlur={!disableClearable}
        disableClearable={disableClearable}
        disabled={disabled}
        filterOptions={frontendFiltering || (items => items)}
        filterSelectedOptions
        freeSolo={freeSolo}
        getOptionLabel={resultFormatFunction}
        groupBy={additionalSearchData?.length ? result => result.groupLabel : null}
        multiple={multiple}
        open={hideOptions ? false : undefined}
        options={sortFunction ? searchResults.sort(sortFunction) : searchResults}
        renderInput={params => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            className={classes.searchField}
            color="secondary"
            error={errorText !== ''}
            helperText={errorText ? options.errorText : options.helperText}
            InputProps={{
              ...params.InputProps,
              className: classes.searchField
            }}
            placeholder={placeholder}
            testingid={searchBarTestingId}
            variant="standard"
          />
        )}
        value={selection}
        onChange={(_, value) => onSelection(value)}
        onClose={() => setIsOpen(false)}
        onInputChange={debounce((_, value, reason) => {
          if (reason === 'input') {
            setUserInput(value);
            checkAndSearch(value);
          } else if (reason === 'reset' || reason === 'clear') {
            if (userInput) {
              setSearchResults([]);
              setUserInput('');
            }
          }
        }, 250)}
        onOpen={
          (searchResults.length || userInput) && !searchOnOpen
            ? undefined
            : () => {
                setSearchResults([]);
                setIsOpen(true);
                checkAndSearch('');
              }
        }
      />
    </div>
  );
};

SearchBar.propTypes = {
  options: PropTypes.object.isRequired,
  field: PropTypes.object,
  form: PropTypes.object,
  className: PropTypes.string,
  onSelectionChange: PropTypes.func,
  onResultChange: PropTypes.func
};

SearchBar.defaultProps = {
  className: '',
  onSelectionChange: () => {},
  onResultChange: () => {},
  field: undefined,
  form: undefined
};

export default SearchBar;
