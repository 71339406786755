import React, { useEffect, useMemo, useState } from 'react';

import { ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { useTheme } from '@material-ui/core';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';

import { LinkButton, StatusChip } from 'components';
import WrapTable from 'components/WrapTable';
import { convertToCurrencyString } from 'utils';

import { SectionHeader, VisitSubjectField } from '../Components';

const COLUMNS = [
  {
    field: 'id',
    flex: 1,
    hide: true
  },
  {
    field: 'purchaseOrder',
    flex: 1,
    headerName: 'Purchase Order',
    align: 'flex-start',
    renderCell: cell => {
      return (
        <LinkButton
          label={cell.value}
          path={`/procurement/purchaseorders/view/${cell.row.id}`}
          style={{ width: 'fit-content', justifyContent: 'flex-start' }}
        />
      );
    }
  },
  {
    field: 'status',
    flex: 1,
    headerName: 'Status',
    align: 'flex-start',
    renderCell: cell => {
      return (
        <StatusChip
          enumType="PROCUMENT_PURCHASE_ORDER_STATUS"
          enumValue={cell.value}
          label={cell.value}
          style={{ borderRadius: '2px', width: 'fit-content' }}
        />
      );
    }
  },
  {
    field: 'vendor',
    flex: 1,
    headerName: 'Vendor',
    align: 'flex-start'
  },
  {
    field: 'poType',
    flex: 1,
    headerName: 'PO Type',
    align: 'flex-start'
  },
  {
    field: 'description',
    flex: 4,
    headerName: 'Description',
    align: 'flex-start'
  },
  {
    field: 'department',
    flex: 1,
    headerName: 'Department',
    align: 'flex-start'
  },
  {
    field: 'createdBy',
    flex: 1,
    headerName: 'Created By',
    align: 'flex-start'
  },
  {
    field: 'subtotal',
    flex: 1,
    headerName: 'Subtotal',
    align: 'flex-start',
    totalGetter: ({ rows }) => rows.reduce((acc, r) => acc + r.subtotal, 0),
    renderTotal: props => {
      return (
        <Typography
          css={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}
          numeric
          variant={TV.BASE}
          weight={TW.BOLD}
        >
          {convertToCurrencyString(props.formattedValue ?? 0)}
        </Typography>
      );
    },
    renderCell: cell => {
      return convertToCurrencyString(cell.value || '0');
    }
  }
];
const mapFOtoRow = (fieldOrder, visit) => {
  return {
    [COLUMNS[0].field]: fieldOrder.id || ' - ',
    [COLUMNS[1].field]: fieldOrder.poNumber || ' - ',
    [COLUMNS[2].field]: fieldOrder.status || ' - ',
    [COLUMNS[3].field]: fieldOrder.vendorName || ' - ',
    [COLUMNS[4].field]: fieldOrder.poType?.name,
    [COLUMNS[5].field]: fieldOrder.description || ' - ',
    [COLUMNS[6].field]: fieldOrder.department?.tagName || ' - ',
    [COLUMNS[7].field]: fieldOrder.createdBy || ' - ',
    [COLUMNS[8].field]: fieldOrder.totalAmountPreTax || 0,
    visit: VisitSubjectField({
      reviewStatus: visit.reviewStatus,
      visitNumber: visit.visitNumber,
      scheduledFor: visit.scheduledFor
    })
  };
};

const VisitFieldOrdersTable = ({ visits, isLoading }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fieldOrders = visits.reduce((fos, visit) => {
      if (visit?.visitPurchaseOrders?.items?.length > 0) {
        visit.visitPurchaseOrders.items.forEach(fo => {
          const mappedFO = mapFOtoRow(fo, visit);
          fos.push(mappedFO);
        });
      }
      return fos;
    }, []);
    setRows(sortBy(fieldOrders, 'purchaseOrder'));
  }, [visits]);

  const getColumns = multiVisit => {
    return multiVisit
      ? [
          {
            field: 'visit',
            flex: 1,
            headerName: 'Visit',
            align: 'flex-start',
            renderCell: cell => {
              return cell.value;
            }
          },
          ...COLUMNS
        ]
      : COLUMNS;
  };
  const columns = useMemo(() => getColumns(visits.length > 1), [visits]);
  return (
    <ThemeProvider>
      <SectionHeader title="Field Orders Created" />
      <WrapTable
        columns={columns}
        enableTotalsRow
        hideFooter={rows.length <= 10}
        loading={isLoading}
        loadingRows={3}
        lockRowHeight
        noDataMessage="No Field Orders Created"
        rows={rows}
        tableOnly
      />
    </ThemeProvider>
  );
};

VisitFieldOrdersTable.propTypes = {
  visits: PropTypes.array,
  isLoading: PropTypes.bool
};

VisitFieldOrdersTable.defaultProps = {
  visits: [],
  isLoading: false
};
export default VisitFieldOrdersTable;
