import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getFormattedActivityData, handleUploadAttachments } from '@pm/components/utils';
import sidebarData from 'meta/ProjectManagement/Finance/payApplicationDetailSidebar';
import { snackbarOn } from 'redux/actions/globalActions';

import Activity from 'scenes/ProjectManagement/components/Activity';
import AttachmentsV1 from 'scenes/ProjectManagement/components/Attachments';
import AttachmentsV2 from 'scenes/ProjectManagement/components/AttachmentsV2';
import Messages from 'scenes/ProjectManagement/components/Messages';
import Sidebar from 'scenes/ProjectManagement/components/Sidebar';
import { getCustomerReps } from 'services/API/customerRep';
import { getEmployees } from 'services/API/employee';
import { getPayApplicationById, payApplicationChange } from 'services/API/payApplication';
import { getEmail } from 'services/API/sendEmail';
import { AppConstants } from 'utils/AppConstants';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

const useStyles = makeStyles(theme => ({
  emailContainer: {
    position: 'absolute',
    display: 'flex',
    left: '0%',
    right: '0px',
    top: '60px'
  },
  sidebarContainer: {
    borderRight: `1px solid ${theme.palette.grayscale(90)}`,
    width: '242px',
    bottom: '0px',
    top: '0px'
  },
  emailShow: {
    marginLeft: '20px'
  },
  messagesContainer: {
    right: '0px',
    borderBottom: `1px solid ${theme.palette.grayscale(90)}`
  },
  activityContainer: {
    borderTop: `1px solid ${theme.palette.grayscale(90)}`,
    marginBottom: '150px'
  },
  backIcon: {
    fontSize: 20
  },
  detailTop: {
    marginTop: 24,
    paddingBottom: 10
  },
  profilePic: {
    width: '35px',
    height: '35px',
    overflow: 'hidden',
    borderRadius: '50%',
    bottom: '0px',
    fontSize: 14,
    marginRight: 10
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  activity: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px'
  },
  nameplate: {
    fontSize: '14px',
    marginRight: 4
  },
  contents: {
    marginTop: '10px',
    fontSize: '14px',
    padding: '10px 0px 10px 0px'
  },
  button: {
    margin: 3,
    padding: 3
  }
}));

const PayApplicationShow = ({ user, snackbar, emailButton, ...props }) => {
  const classes = useStyles();
  const { projectId, payApplicationId, payApplication, onAttachmentUpdate } = props || {};
  const [activityData, setActivityData] = useState([]);
  const [formattedData, setFormattedData] = useState({});
  const [attachmentData, setAttachmentData] = useState([]);
  const [messageData, setMessageData] = useState([]);
  const [customerReps, setCustomerReps] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [payApp, setPA] = useState([]);

  const getFormattedData = data => {
    const sendTo = data?.sendToCustomer?.name || '';
    const convert = {
      owner: 'Owner',
      architect: 'Architect',
      'mechanical engineer': 'Mechanical Engineer',
      'electrical engineer': 'Electrical Engineer',
      'general contractor': 'General Contractor',
      'mechanical contractor': 'Mechanical Contractor',
      'electrical contractor': 'Electrical Contractor'
    };
    const ret = {
      sendTo,
      returnTo: data?.returnToObject ? data.returnToObject.name : '',
      period:
        data?.periodFrom && data?.periodTo
          ? `${moment.unix(data?.periodFrom).format(AppConstants.DATE_FORMAT)} to ${moment
              .unix(data?.periodTo)
              .format(AppConstants.DATE_FORMAT)}`
          : '',
      distributeTo: convert[data?.distributeTo]
    };
    return ret;
  };

  const getFormattedEmailData = data => {
    const sortedData = data.sort((a, b) => (a.createdDateTime > b.createdDateTime ? -1 : 1));
    let i = 0;
    const ret = [];
    while (i < sortedData.length) {
      const currData = sortedData[i];
      const sendToEmails = currData.sendTo.split(';');
      const recipients = [];
      let j = 0;
      while (j < sendToEmails.length) {
        const currEmail = sendToEmails[j];
        const possibleCustomerRep = customerReps.find(element => element.email === currEmail);
        if (possibleCustomerRep) {
          recipients.push(possibleCustomerRep);
        }
        const possibleEmployee = employees.find(element => element.email === currEmail);
        if (possibleEmployee) {
          recipients.push(possibleEmployee);
        }
        j += 1;
      }
      ret.push({
        id: currData?.id || '',
        subject: currData?.subject || '',
        email: currData?.sendTo || '',
        recipients,
        recipient: recipients.length ? recipients[0].name : '',
        emailContent: currData?.body || '',
        date: currData.createdDate ? moment.unix(currData.createdDate).toDate() : '',
        read: true,
        attachments: currData.attachments,
        inReplyTo: currData.inReplyTo,
        from: currData.from
      });
      i += 1;
    }
    return ret;
  };

  const update = () => {
    setAttachmentData(
      Array.isArray(payApp?.PayApplicationAttachment)
        ? payApp.PayApplicationAttachment.filter(element => element.deletedDate === null)
        : []
    );
    getCustomerReps().then(cust => {
      setCustomerReps(cust);
    });
    getEmployees().then(emp => {
      setEmployees(emp);
    });
    setFormattedData(getFormattedData(payApp));
    setActivityData(getFormattedActivityData(payApp));
  };

  useEffect(() => {
    getEmail(payApp?.id).then(email => {
      setMessageData(getFormattedEmailData(email));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerReps]);

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payApp, payApplicationId, projectId]);

  useEffect(() => {
    setPA(payApplication);
  }, [payApplication]);

  const handleUploadFileList = (newAttachments, selectedImages, selectedFiles) => {
    const combinedAttachments = handleUploadAttachments(
      newAttachments,
      selectedImages,
      selectedFiles,
      payApp?.PayApplicationAttachment || [],
      user
    );
    const payload = {
      PayApplicationAttachment: combinedAttachments
    };
    onAttachmentUpdate(combinedAttachments);
    payApplicationChange(payApplicationId, payload).then(pa => {
      snackbar('success', 'Attachments saved.');
      getPayApplicationById(pa.id).then(p => {
        setPA(p);
      });
    });
  };

  const Attachments = useFlags()[FeatureFlags.PM_FILE_SYSTEM] ? AttachmentsV2 : AttachmentsV1;

  const displayAttachments = data => {
    return (
      <Attachments
        attachedFileCount={data?.length || 0}
        attachedFiles={data || []}
        chunkSize={5}
        imageHeight={180}
        imageWidth={200}
        smallButton
        uploadFileList={handleUploadFileList}
      />
    );
  };

  return (
    <>
      <div className={classes.emailContainer}>
        <div className={classes.sidebarContainer}>
          <Sidebar sidebarData={sidebarData} source={formattedData} />
        </div>
        <div style={{ display: 'block', right: '0px' }}>
          <div className={classes.messagesContainer}>
            <Messages button={emailButton} messageData={messageData} user={user} />
          </div>
          <div className={classes.attachmentsContainer}>{displayAttachments(attachmentData)}</div>
          <div className={classes.activityContainer}>
            <Activity activityData={activityData} />
          </div>
        </div>
      </div>
    </>
  );
};

PayApplicationShow.propTypes = {
  user: PropTypes.object.isRequired,
  snackbar: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ user: state.user });

const mapDispatcherToProps = dispatch => ({
  snackbar: (mode, message) => dispatch(snackbarOn(mode, message))
});

const ReduxConnectedPayApplicationShow = connect(
  mapStateToProps,
  mapDispatcherToProps
)(PayApplicationShow);

export default connect(mapStateToProps)(ReduxConnectedPayApplicationShow);
