import React, { Fragment, useEffect, useMemo } from 'react';

import { Divider, NumberInput, Select, TimeInput, TV, TW, Typography } from '@BuildHero/sergeant';
import moment from 'moment';

import uuidV4 from 'uuid/v4';

import useCompanyForms from 'customHooks/useCompanyForms';
import { FormEntityType } from 'utils/AppConstants';

const defaultVisitLine = {
  duration: 0,
  startTime: 0,
  numberOfTechs: 0,
  primaryTechnician: null,
  additionalTechnicians: []
};

const VisitScheduler = ({ props, field, name, form }) => {
  const { budgetedHours = 0, daysBeforeDueDate = 0, numberOfVisits, techOptions } = props;

  const value = field?.value ?? form?.values[name] ?? [];

  const fieldName = field?.name ?? name;

  const [availableForms] = useCompanyForms({
    formType: FormEntityType.VISIT
  });

  const formOptions = useMemo(
    () => availableForms?.map(({ id, name: formName }) => ({ label: formName, value: id })) || [],
    [availableForms]
  );

  // forms are at group level, but BE api are built with future requirements in mind
  // hence picking any visits forms is sufficient for now
  const selectedForms =
    form.values?.visitFormsOptions ||
    value?.[0]?.forms?.map(f => formOptions.find(fOption => fOption.value === f.formId)) ||
    [];

  useEffect(() => {
    if (!value?.length) {
      const visits = [];
      for (let i = 0; i < numberOfVisits; i += 1) {
        visits.push({ ...defaultVisitLine, id: uuidV4(), visitNumber: i + 1 });
      }
      form.setFieldValue(fieldName, visits);
    } else if (value?.length < numberOfVisits) {
      const visits = [...value];
      const diff = numberOfVisits - value?.length;
      for (let i = 0; i < diff; i += 1) {
        visits.push({ ...defaultVisitLine, id: uuidV4(), visitNumber: i + 1 + value.length });
      }
      form.setFieldValue(fieldName, visits);
    } else if ((numberOfVisits || numberOfVisits === 0) && value?.length > numberOfVisits) {
      const visits = [...value].slice(0, numberOfVisits);
      form.setFieldValue(fieldName, visits);
    }
  }, [numberOfVisits]);

  const changeVisitProp = ({ id, key, newValue }) => {
    const visits = [...value].map(v => {
      if (v.id !== id) return v;
      return { ...v, [key]: newValue };
    });
    form.setFieldValue(fieldName, visits);
  };

  return (
    <>
      <Typography css={{ display: 'flex' }} variant={TV.BASE} weight={TW.MEDIUM}>
        Total Scheduled Labor:{' '}
        <Typography variant={TV.BASE} weight={TW.BOLD}>
          {value?.reduce((acc, v) => acc + v.duration, 0) ?? 0}/{budgetedHours} hours
        </Typography>
      </Typography>
      <Divider />
      {value?.length > 0 && (
        <Select
          label="Visit Forms"
          multi
          options={formOptions?.filter(
            item => !selectedForms.map(i => i.value).includes(item.value)
          )}
          portal
          searchable
          value={selectedForms}
          onChange={selectedOptions => {
            form.setFieldValue('visitFormsOptions', selectedOptions);
          }}
        />
      )}
      {value?.map(v => {
        return (
          <Fragment key={v.id}>
            <div css={{ display: 'flex', width: '100%' }}>
              <Typography variant={TV.BASE} weight={TW.BOLD}>
                Visit {v.visitNumber}
              </Typography>
              <Typography variant={TV.BASE} weight={TW.REGULAR}>
                {' '}
                {value.length + daysBeforeDueDate - v.visitNumber} days from due date
              </Typography>
            </div>
            <div css={{ display: 'flex', width: '100%', gap: 10 }}>
              <NumberInput
                css={{ minWidth: 100 }}
                label="Visit Duration"
                renderSuffix={() => (
                  <Typography css={{ minWidth: 50 }} variant={TV.BASE} weight={TW.MEDIUM}>
                    Hours
                  </Typography>
                )}
                value={v.duration}
                onChange={newDuration => {
                  changeVisitProp({ id: v.id, key: 'duration', newValue: newDuration });
                }}
              />
              <TimeInput
                css={{ minWidth: 150 }}
                label="Start Time"
                value={moment()
                  .startOf('day')
                  .add(v.startTime, 'minutes')
                  .format('HH:mm')}
                onChange={newStartTime => {
                  const minutesFromMidnight = newStartTime.split(':').reduce((acc, t, i) => {
                    if (i === 0) {
                      // hours
                      return acc + parseInt(t, 10) * 60;
                    }
                    // minutes
                    return acc + parseInt(t, 10);
                  }, 0);
                  changeVisitProp({ id: v.id, key: 'startTime', newValue: minutesFromMidnight });
                }}
              />
              <NumberInput
                css={{ minWidth: 100 }}
                label="Number of Techs"
                value={v.numberOfTechs}
                onChange={newNumberOfTechs => {
                  changeVisitProp({ id: v.id, key: 'numberOfTechs', newValue: newNumberOfTechs });
                }}
              />
              <Select
                label="Primary Technician"
                options={techOptions.filter(
                  o => !v.additionalTechnicians?.map(t => t.value)?.includes(o.value)
                )}
                portal
                searchable
                value={v.primaryTechnician}
                onChange={tech => {
                  changeVisitProp({ id: v.id, key: 'primaryTechnician', newValue: tech });
                }}
              />
              <Select
                label="Additional Technician"
                multi
                options={techOptions.filter(o => v.primaryTechnician?.value !== o.value)}
                portal
                searchable
                value={v.additionalTechnicians}
                onChange={techs => {
                  changeVisitProp({ id: v.id, key: 'additionalTechnicians', newValue: techs });
                }}
              />
            </div>
          </Fragment>
        );
      })}
    </>
  );
};

export default VisitScheduler;
