import React from 'react';

import { Typography } from '@material-ui/core';
import { compact } from 'lodash';
import PropTypes from 'prop-types';

import { eventTypeLabel } from './constants';

const TimestampType = ({ meta, record }) => {
  const eventType = record.timekeepingLedger?.eventType?.name;
  const [label] = compact([eventTypeLabel[eventType], record.customLabel, record[meta.id]]);
  return <Typography variant="h6">{label}</Typography>;
};

export default TimestampType;

TimestampType.propTypes = {
  meta: PropTypes.object.isRequired,
  record: PropTypes.func.isRequired
};
