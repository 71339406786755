import gql from 'graphql-tag';

import useExtendedLazyQuery from 'customHooks/useExtendedLazyQuery';
import { useSnackbar } from 'customHooks/useSnackbar';
import { logErrorWithCallback } from 'utils';

const PREV_NEXT_TIMESHEET_QUERY = gql`
  query prevNextTimesheetForEmployee($employeeId: String!, $data: TimesheetPeriodScrollInput!) {
    prevNextTimesheetForEmployee(employeeId: $employeeId, data: $data) {
      timesheetPeriod {
        id
        dateStartUTC
        dateEndUTC
      }
      startDayCompanyTZ
    }
  }
`;

const serializer = data => {
  const { employeeId, statuses, date, dir } = data;
  return {
    variables: {
      employeeId,
      data: {
        statuses,
        date,
        dir
      }
    }
  };
};

const usePrevNextTimesheetForEmployee = ({ onCompleted }) => {
  const snackbar = useSnackbar();

  return useExtendedLazyQuery(PREV_NEXT_TIMESHEET_QUERY, {
    serializer,
    transform: result => result?.prevNextTimesheetForEmployee,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onError: error => {
      logErrorWithCallback(error, snackbar, 'Unable to fetch next timesheet');
    },
    onCompleted
  });
};

export default usePrevNextTimesheetForEmployee;
