/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import moment from 'moment';

import AmplifyService from 'services/AmplifyService';
import { AddressType } from 'utils/constants';

import getAllAssetByCompany from '../../graphql/lists/queries/getAllAssetByCompany';
import getAllAttachmentByCompany from '../../graphql/lists/queries/getAllAttachmentByCompany';
import getAllCustomerByCompany from '../../graphql/lists/queries/getAllCustomerByCompany';
import getAllFormByCompany from '../../graphql/lists/queries/getAllFormByCompany';
import getAllInvoiceByCompany from '../../graphql/lists/queries/getAllInvoiceByCompany';
import getAllInvoiceByCompanyForPayments from '../../graphql/lists/queries/getAllInvoiceByCompanyForPayments';
import getAllPaymentByCompany from '../../graphql/lists/queries/getAllPaymentByCompany';
import getAllPropertyByCompany from '../../graphql/lists/queries/getAllPropertyByCompany';
import getAllQuoteByCompany from '../../graphql/lists/queries/getAllQuoteByCompany';
import getAllReviewReportByCompany from '../../graphql/lists/queries/getAllReviewReportByCompany';
import getAllTechReportByCompany from '../../graphql/lists/queries/getAllTechReportByCompany';
import getInvoiceBalanceData, {
  MAX_PAGE_SIZE as INVOICE_BALANCE_PAGE_SIZE
} from '../../graphql/lists/queries/getInvoiceBalanceData';
import { AppConstants, InvoiceConstants } from '../../utils/AppConstants';
import { roundCurrency } from '../../utils/currency';
import CommonService from '../Common/CommonService';
import CustomerService from '../Customer/CustomerService';

const { PROPERTY, BILLING } = AddressType;
const submittedOrInvoiced = ['Submitted', 'submitted', 'Invoiced', 'invoiced'];

export default class ListService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
    this.CommonService = new CommonService();
    this.CustomerService = new CustomerService();
  }

  // NB: Mutates invoices
  async annotateInvoiceBalances(partitionKey, companySortKey, invoices) {
    const byId = new Map();
    for (const invo of invoices) {
      invo.paymentTotal = 0;
      byId.set(invo.id, invo);
    }

    // There's an (apparently undocumented) restriction in the RDS Data Service
    // API we ultimately use, so we have to paginate through
    const doPage = async invoiceIds => {
      const {
        data: {
          getCompany: { paymentTotals }
        }
      } = await this.api.query(getInvoiceBalanceData, {
        partitionKey,
        sortKey: companySortKey,
        invoiceIds
      });

      for (const { invoiceId: id, total } of paymentTotals.items)
        if (total) byId.get(id).paymentTotal = parseFloat(total);
    };

    await Promise.all(
      _.chunk(
        invoices.map(i => i.id),
        INVOICE_BALANCE_PAGE_SIZE
      ).map(doPage)
    );

    for (const invo of byId.values())
      invo.balance = roundCurrency(invo.totalAmount - invo.paymentTotal);
  }

  getMappedEntityTagname = entityItems => {
    // sorted, comma-separated tag names
    return entityItems?.items
      ?.map(i => i.mappedEntity?.tagName)
      ?.sort()
      ?.join(', ');
  };

  getTechNameFromTechList = (techList = []) => {
    return `${techList?.[0]?.employee?.firstName || ''} ${techList?.[0]?.employee?.lastName ||
      ''} `;
  };

  getCombinedAddress = address => {
    const addressArray = [
      address?.addressLine1,
      address?.addressLine2,
      address?.state,
      address?.city,
      address?.zipcode
    ];
    return addressArray.filter(item => !!item).join(', ') || '-';
  };

  // Method to get review report Id from invoices query . To be refined

  getReviewReportId = InvoicesData => {
    let reviewReportId = '';
    if (InvoicesData?.RelatedVisit?.items?.[0]?.visit) {
      const allVisits = InvoicesData.RelatedVisit.items[0].visit;
      const { visitNumber } = allVisits;
      const invoices = InvoicesData?.RelatedVisit?.items?.[0]?.visit?.job?.invoices?.items;

      invoices.forEach(invoice => {
        if (invoice.invoiceNumber === InvoicesData.invoiceNumber) {
          const { invoiceVisits } = invoice;
          if (invoiceVisits && invoiceVisits.items) {
            const invoiceVisitItems = invoiceVisits.items;
            invoiceVisitItems.forEach(invoiceVisitItem => {
              if (
                invoiceVisitItem.visit.visitNumber === visitNumber &&
                allVisits?.reviewReports?.items?.length
              ) {
                reviewReportId = allVisits.reviewReports.items[0].id;
              }
            });
          }
        }
      });
    }
    return reviewReportId;
  };

  // Method to get invoice Id from review report query . To be refined

  getInvoiceId = reviewData => {
    let invoiceId = '';
    let invoiceNo = '';
    if (reviewData?.visit?.visitNumber) {
      const currentVisitNumber = reviewData.visit.visitNumber;

      const invoices = reviewData?.visit?.job?.invoice?.items;
      if (invoices) {
        invoices.forEach(invoice => {
          if (!['void', 'Void'].includes(invoice.status)) {
            const { invoiceVisits } = invoice;
            const invoiceVisitItems = invoiceVisits && invoiceVisits.items;
            if (invoiceVisitItems) {
              invoiceVisitItems.forEach(invoiceVisit => {
                const { visit } = invoiceVisit;
                if (visit) {
                  if (visit.visitNumber === currentVisitNumber) {
                    invoiceId = invoice.id;
                    invoiceNo = invoice.invoiceNumber;
                  }
                }
              });
            }
          }
        });
      }
    }
    return { invoiceId, invoiceNumber: invoiceNo };
  };

  // Method to compute latest Scheduled date for a Job

  getLatestSchedule = schedules => {
    let latestSchedule = null;
    if (schedules.length > 0) {
      latestSchedule = Math.min(...schedules);
    }
    return latestSchedule;
  };

  // Capitalize first letter method

  capitalizeFirstLetter = inputString => {
    let capitalizedString = '';
    if (inputString) capitalizedString = inputString.charAt(0).toUpperCase() + inputString.slice(1);
    return capitalizedString;
  };

  getInvoicesByStatus = async (
    partitionKey,
    sortKey,
    filter,
    limit,
    offset,
    sortBy,
    sortOrder,
    status
  ) => {
    const { stringFilters, ...rest } = filter || {};
    let customizedFilter = {
      stringFilters: [{ fieldName: 'Invoice.status', filterInput: { eq: status } }]
    };

    let combinedStringFilterArr = customizedFilter.stringFilters;
    if (stringFilters) {
      combinedStringFilterArr = combinedStringFilterArr.concat(stringFilters);
    }
    customizedFilter.stringFilters = combinedStringFilterArr;

    if (rest) {
      customizedFilter = { ...customizedFilter, ...rest };
    }

    const params = {
      partitionKey,
      sortKey,
      filter: customizedFilter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    let result;
    const response = await this.api.query(getAllInvoiceByCompany, params);
    const formattedResult = { items: [] };
    if (response?.data) {
      result = response.data.getCompany?.invoices || {};
      let visitNumbersString = '';
      if (result?.items?.length) {
        result.items.forEach(item => {
          const relatedVisit = item.RelatedVisit;
          let visit;
          if (relatedVisit?.items?.length) {
            relatedVisit.items.forEach((relatedVisitItem, index) => {
              visitNumbersString =
                visitNumbersString + index < relatedVisitItem.length - 1
                  ? `, ${relatedVisitItem.visit.visitNumber}`
                  : relatedVisitItem.visit.visitNumber;
            });
            // eslint-disable-next-line prefer-destructuring
            ({ visit } = relatedVisit.items[0]);
          }

          const job = visit?.job || {};

          const employeeName = this.getTechNameFromTechList(item?.primaryTechs?.items);
          const jobTags = this.getMappedEntityTagname(job.jobJobTags);
          formattedResult.items.push({
            id: item.id,
            sortKey: item.sortKey,
            employeeName,
            visitNumbers: visitNumbersString,
            invoiceNumber: item.invoiceNumber,
            customIdentifier: job.customIdentifier || job.jobNumber,
            jobNumber: job.jobNumber,
            jobSortKey: job.sortKey,
            jobTypeInternal: job.jobTypeInternal,
            customerName: job.customerName,
            customerPropertyName: job.customerPropertyName,
            status: job.status,
            jobTypeName: job.jobTypeName,
            owner: (job.owner || {}).name,
            createdDate: item.createdDate,
            departmentName: visit && visit.departmentName,
            linkName: 'View invoice',
            jobTags
          });
        });
      }

      if (result.nextToken) {
        formattedResult.nextToken = result.nextToken;
      }
    }
    return formattedResult;
  };

  getIncompleteVisits = async (partitionKey, sortKey, filter, limit, offset, sortBy, sortOrder) => {
    const cutoffTimeStamp = moment()
      .startOf('day')
      .subtract(1, 'day')
      .unix();

    const { stringFilters, ...rest } = filter || {};
    let customizedFilter = {
      stringFilters: [{ fieldName: 'Visit.status', filterInput: { in: ['Scheduled'] } }],
      integerFilters: [{ fieldName: 'ScheduledFor', filterInput: { lt: cutoffTimeStamp } }]
    };

    let combinedStringFilterArr = customizedFilter.stringFilters;
    if (stringFilters) {
      combinedStringFilterArr = combinedStringFilterArr.concat(stringFilters);
    }
    customizedFilter.stringFilters = combinedStringFilterArr;

    if (rest) {
      customizedFilter = { ...customizedFilter, ...rest };
    }

    const params = {
      partitionKey,
      sortKey,
      filter: customizedFilter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'scheduledFor',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    let result;
    const response = await this.api.query(getAllTechReportByCompany, params);
    const formattedResult = { items: [] };
    if (response?.data) {
      result = response.data.getCompany?.visit || {};
      if (result?.items) {
        result.items.forEach(item => {
          const job = item.job || {};
          const employeeName = this.getTechNameFromTechList(item?.primaryTechs?.items);
          const jobTags = this.getMappedEntityTagname(job.jobJobTags);
          formattedResult.items.push({
            id: item.id,
            sortKey: item.sortKey,
            employeeName,
            visitNumber: item.visitNumber,
            customIdentifier: job.customIdentifier || job.jobNumber,
            jobNumber: job.jobNumber,
            jobSortKey: job.sortKey,
            jobTypeInternal: job.jobTypeInternal,
            customerName: job.customerName,
            customerPropertyName: job.customerPropertyName,
            status: job.status,
            jobTypeName: job.jobTypeName,
            owner: (job.owner || {}).name,
            createdDate: item.createdDate,
            scheduledFor: item.scheduledFor,
            departmentName: item.departmentName,
            jobTags
          });
        });
      }
      if (result.nextToken) {
        formattedResult.nextToken = result.nextToken;
      }
    }
    return formattedResult;
  };

  // Use this function to replace/transform the query conditions while executing filter
  // At many places in table we are showing computed/inferred values which is not diectly filterable
  // In such cases, we can use it to correctly tranform the query.
  // For e.g check flow for Technician table => lastUpdatedBy
  replaceFilterCondition = (filter, newQueryCondition) => {
    const { stringFilters } = filter;
    if (stringFilters.length && newQueryCondition.length) {
      const newStringFilter = _.cloneDeep(stringFilters);
      newQueryCondition.forEach(query => {
        const filterKeyToReplace = query.key;
        const cond = JSON.stringify(query.condition);
        const filterCond = newStringFilter.find(
          field =>
            field.fieldName === filterKeyToReplace && JSON.stringify(field.filterInput) === cond
        );
        if (filterCond) {
          filterCond.fieldName = query.fieldName;
          filterCond.filterInput = query.filterInput;
        }
      });
      return { ...filter, stringFilters: newStringFilter };
    }
    return filter;
  };

  getAllTechReports = async (partitionKey, sortKey, filter, limit, offset, sortBy, sortOrder) => {
    const { stringFilters, ...rest } = filter || {};
    let customizedFilter = {
      stringFilters: [],
      booleanFilters: [{ fieldName: 'Job.closeoutReport', filterInput: { ne: true } }]
    };

    let combinedStringFilterArr = customizedFilter.stringFilters;
    if (stringFilters) {
      combinedStringFilterArr = combinedStringFilterArr.concat(stringFilters);
    }
    customizedFilter.stringFilters = combinedStringFilterArr;

    if (rest) {
      customizedFilter = { ...customizedFilter, ...rest };
    }

    const params = {
      partitionKey,
      sortKey,
      filter: customizedFilter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    let result;
    const response = await this.api.query(getAllTechReportByCompany, params);
    const formattedResult = { items: [] };
    if (response?.data) {
      result = response.data.getCompany?.visit || {};
      if (result?.items) {
        result.items.forEach(item => {
          const job = item.job || {};
          const employeeName = this.getTechNameFromTechList(item?.primaryTechs?.items);

          const reviewReport = item?.reviewReports?.items?.[0];
          const reviewReportId = reviewReport?.id;

          const reviewReportLabel = reviewReportId
            ? ` Review : ${job.jobNumber}/${item.visitNumber}`
            : '-';
          const displayJobNumber = job.customIdentifier || job.jobNumber || '';
          const techReportLabel = ` TechReport : ${displayJobNumber} / ${item.visitNumber}`;
          const jobTags = this.getMappedEntityTagname(job.jobJobTags);

          let billingCustomerAddress;
          if (Array.isArray(item?.job?.billingCustomer?.companyAddresses?.items)) {
            billingCustomerAddress = item.job.billingCustomer.companyAddresses.items.filter(
              address => address.addressType === 'billingAddress'
            );
          }

          let billingAddress;
          let companyAddress;
          if (Array.isArray(item?.job?.customer?.companyAddresses?.items)) {
            billingAddress = item.job.customer.companyAddresses.items.filter(
              address => address.addressType === 'billingAddress'
            );
            companyAddress = item.job.customer.companyAddresses.items.filter(
              address => address.addressType === 'businessAddress'
            );
          }

          let propertyAddress;
          if (Array.isArray(item?.job?.customerProperty?.companyAddresses?.items)) {
            propertyAddress = item.job.customerProperty.companyAddresses.items.filter(
              address => address.addressType === 'propertyAddress'
            );
          }

          const billingAddressObject = billingCustomerAddress?.[0] || billingAddress?.[0];
          const fullBillingCustomerAddress = this.getCombinedAddress(billingAddressObject);
          const fullCompanyAddress = this.getCombinedAddress(companyAddress?.[0]);
          const fullPropertyAddress = this.getCombinedAddress(propertyAddress?.[0]);

          let ownerValue = '';

          if (job.owner) {
            ownerValue =
              job.owner.name || `${job.owner.firstName || ''} ${job.owner.lastName || ''}`;
          }

          const summary =
            Array.isArray(item?.summaries?.items) &&
            item.summaries.items.reduce((acc, s) => {
              if (!acc) return s.summary;

              return acc.concat(` ${s.summary}`);
            }, '');

          formattedResult.items.push({
            id: item.id,
            sortKey: item.sortKey,
            employeeName,
            visitNumber: item.visitNumber,
            description: item.description,
            summary,
            customIdentifier: job.customIdentifier || job.jobNumber || '',
            jobNumber: job.jobNumber,
            jobSortKey: job.sortKey,
            jobDescription: job.issueDescription,
            jobStatus: job.status,
            jobType: job.jobTypeName,
            jobTypeInternal: job.jobTypeInternal,
            ownerValue,
            customerName: job.customerName,
            customerId: job.customerId,
            customerSortKey: job.customerSortKey,
            customerPropertyId: job.customerPropertyId,
            customerPropertySortKey: job.customerPropertySortKey,
            customerPropertyName: job.customerPropertyName,
            createdDate: item.createdDate,
            visitSubmittedDate: item.submittedDate,
            completedDate: job.completedDate,
            departmentName: item.departmentName,
            status: this.capitalizeFirstLetter(item.status),
            reviewReportId,
            reviewReportLabel,
            techReportLabel,
            reviewReportCreatedBy: reviewReportId ? reviewReport.createdBy : '-',
            reviewReportCreatedDate: reviewReportId ? reviewReport.createdDate : '',
            jobTags,
            scheduledFor: item.scheduledFor,
            billingAddress: fullBillingCustomerAddress,
            propertyAddress: fullPropertyAddress || fullCompanyAddress || fullBillingCustomerAddress
          });
        });
      }
      if (result.nextToken) {
        formattedResult.nextToken = result.nextToken;
      }
    }
    return formattedResult;
  };

  getAllReviewReports = async (partitionKey, sortKey, filter, limit, offset, sortBy, sortOrder) => {
    const { stringFilters, integerFilters, ...rest } = filter || {};
    let customizedFilter = {
      stringFilters: [],
      integerFilters: integerFilters || []
    };
    const rangeQueryConstant = 'between';
    let combinedStringFilterArr = customizedFilter.stringFilters;
    const combinedIntegerFilterArr = customizedFilter.integerFilters;

    if (stringFilters) {
      combinedStringFilterArr = combinedStringFilterArr.concat(stringFilters);

      const statusFilter = stringFilters.filter(filter => filter.fieldName.includes('status'));
      if (_.isEmpty(statusFilter)) {
        const submittedByFilter = stringFilters.filter(filter =>
          filter.fieldName.includes('lastUpdatedBy')
        );
        if (submittedByFilter && !_.isEmpty(submittedByFilter)) {
          const statusForSubmittedByFilter = {
            fieldName: 'ReviewReport.status',
            filterInput: { eq: 'Submitted' }
          };
          combinedStringFilterArr = combinedStringFilterArr.concat({
            ...statusForSubmittedByFilter
          });
        }
      }
    }

    if (integerFilters) {
      const submittedOnFilter = integerFilters.filter(filt =>
        filt.fieldName.includes('lastUpdatedDate')
      );
      const startDate = submittedOnFilter?.[0]?.filterInput?.[rangeQueryConstant]?.[0];
      if (startDate) {
        const createdOnFilter = {
          fieldName: 'ReviewReport.createdDateTime',
          filterInput: { gt: startDate }
        };
        combinedIntegerFilterArr.push(createdOnFilter);

        const statusFilter = {
          fieldName: 'ReviewReport.status',
          filterInput: { in: submittedOrInvoiced }
        };
        combinedStringFilterArr.push(statusFilter);
      }
    }

    customizedFilter.stringFilters = combinedStringFilterArr;
    customizedFilter.integerFilters = combinedIntegerFilterArr;

    if (rest) {
      customizedFilter = { ...customizedFilter, ...rest };
    }

    const params = {
      partitionKey,
      sortKey,
      filter: customizedFilter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    let result;
    const response = await this.api.query(getAllReviewReportByCompany, params);
    const formattedResult = { items: [] };
    if (response?.data) {
      result = response.data.getCompany?.reviewReport || {};
      if (result?.items) {
        result.items.forEach(item => {
          const visit = item.visit || {};
          const job = visit.job || {};

          const techReportId = visit.id;
          const displayJobNumber = job.customIdentifier || job.jobNumber || '';
          const techReportLabel = ` TechReport : ${displayJobNumber} / ${visit.visitNumber || ''}`;
          const reviewReportLabel = `Review : ${displayJobNumber} / ${visit.visitNumber || ''}`;

          const employeeName = this.getTechNameFromTechList(item?.primaryTechs?.items);

          const jobTags = this.getMappedEntityTagname(job.jobJobTags);
          const invoiceDetails = this.getInvoiceId(item);
          const { invoiceId } = invoiceDetails;
          const invoiceLabel = invoiceDetails.invoiceNumber;

          let billingCustomerAddress;
          if (Array.isArray(item?.visit?.job?.billingCustomer?.companyAddresses?.items)) {
            billingCustomerAddress = item.visit.job.billingCustomer.companyAddresses.items.filter(
              address => address.addressType === 'billingAddress'
            );
          }

          let billingAddress;
          if (Array.isArray(item?.visit?.job?.customer?.companyAddresses?.items)) {
            billingAddress = item.visit.job.customer.companyAddresses.items.filter(
              address => address.addressType === 'billingAddress'
            );
          }

          let propertyAddress;
          if (Array.isArray(item?.visit?.job?.customerProperty?.companyAddresses?.items)) {
            propertyAddress = item.visit.job.customerProperty.companyAddresses.items.filter(
              address => address.addressType === 'propertyAddress'
            );
          }

          const billingAddressObject = billingCustomerAddress?.[0] || billingAddress?.[0];
          const fullBillingCustomerAddress = this.getCombinedAddress(billingAddressObject);
          const fullPropertyAddress = this.getCombinedAddress(propertyAddress?.[0]);

          let ownerValue = '';

          if (job.owner) {
            ownerValue =
              job.owner.name || `${job.owner.firstName || ''} ${job.owner.lastName || ''}`;
          }

          const technicians = [...visit?.primaryTechs?.items, ...visit?.extraTechs?.items]
            .map(entry => entry.mappedEntity?.name)
            .join(', ');

          const visitSummary =
            Array.isArray(visit?.summaries?.items) &&
            visit.summaries.items.reduce((acc, s) => {
              if (!acc) return s.summary;
              return acc.concat(` ${s.summary}`);
            }, '');

          formattedResult.items.push({
            id: item.id,
            sortKey: item.sortKey,
            employeeName,
            visitNumber: visit.visitNumber,
            customIdentifier: job.customIdentifier || job.jobNumber || '',
            jobNumber: job.jobNumber,
            jobSortKey: job.sortKey,
            jobTypeInternal: job.jobTypeInternal,
            completedDate: job.completedDate,
            customerName: job.customerName,
            customerId: job.customerId,
            customerSortKey: job.customerSortKey,
            customerPropertyId: job.customerPropertyId,
            customerPropertySortKey: job.customerPropertySortKey,
            customerPropertyName: job.customerPropertyName,
            createdBy: item.createdBy,
            createdDate: item.createdDate,
            departmentName: item.departmentName,
            status: this.capitalizeFirstLetter(item.status),
            techReportId,
            techReportLabel,
            reviewReportLabel,
            invoiceId,
            invoiceNumber: invoiceId ? invoiceLabel : '-',
            submittedBy: submittedOrInvoiced.includes(item.status) ? item.lastUpdatedBy : '-',
            submittedDate: submittedOrInvoiced.includes(item.status)
              ? item.lastUpdatedDateTime / 1000
              : '',
            jobTags,
            billingAddress: fullBillingCustomerAddress,
            jobDescription: job.issueDescription,
            jobStatus: job.status,
            jobType: job.jobTypeName,
            ownerValue,
            propertyAddress: fullPropertyAddress,
            techReportSubmittedBy: visit.submittedBy,
            techReportSubmittedOn: visit.submittedDate,
            technicians,
            visitDescription: visit.description,
            visitSummary
          });
        });
      }

      if (result.nextToken) {
        formattedResult.nextToken = result.nextToken;
      }
    }
    return formattedResult;
  };

  getAllInvoices = async (
    partitionKey,
    sortKey,
    filter = {},
    limit,
    offset,
    sortBy,
    sortOrder,
    status,
    paymentId = false,
    unpaidInvoiceIds,
    adjustmentsFlag = false
  ) => {
    const { stringFilters, ...rest } = filter;
    const customizedFilter = {
      stringFilters: [],
      integerFilters: []
    };
    if (status) {
      if (status === InvoiceConstants.UNPAID) {
        customizedFilter.stringFilters.push({
          fieldName: 'Invoice.id',
          filterInput: { in: unpaidInvoiceIds }
        });
      } else {
        customizedFilter.stringFilters.push({
          fieldName: 'Invoice.status',
          filterInput: { eq: status }
        });
      }
    }

    let combinedStringFilterArr = customizedFilter.stringFilters;
    if (stringFilters) {
      combinedStringFilterArr = combinedStringFilterArr.concat(stringFilters);
    }
    customizedFilter.stringFilters = combinedStringFilterArr;

    const params = {
      partitionKey,
      sortKey,
      filter: { ...customizedFilter, ...rest },
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ],
      adjustmentsFlag
    };

    const response = await this.api.query(getAllInvoiceByCompany, params);
    const invoices = response.data?.getCompany?.invoices;
    const formattedResult = { items: [], nextToken: invoices.nextToken };

    // Getting review report Id
    formattedResult.items = invoices?.items?.map(item => {
      const reviewReportId = this.getReviewReportId(item);
      const visit = item.RelatedVisit?.items?.[0]?.visit;
      const job = item.job || visit?.job;

      const employee = item.primaryTechs?.items?.[0]?.employee;
      const employeeName = employee ? `${employee.firstName} ${employee.lastName}` : null;

      const paymentInvoices = item.paymentInvoiceView.items;
      const amountToApply = paymentId
        ? paymentInvoices.reduce(
            (totalApplied, paymentInvoice) =>
              paymentInvoice.parentId === paymentId
                ? roundCurrency(totalApplied + paymentInvoice.appliedAmount)
                : totalApplied,
            null
          )
        : null;

      const jobTags = this.getMappedEntityTagname(job?.jobJobTags);
      const propertyAddressObj =
        _.get(item, 'companyAddresses.items') ||
        _.get(item, 'parentEntity.parentEntity.companyAddresses.items') ||
        _.get(item, 'billingCustomer.companyAddresses.items', []);

      const propertyAddress = this.getCombinedAddress(
        _.find(propertyAddressObj, ['addressType', PROPERTY])
      );
      const invoiceTags = this.getMappedEntityTagname(item.invoiceInvoiceTags);
      return {
        id: item.id,
        sortKey: item.sortKey,
        adjustedBalance: item.adjustedBalance,
        projectName: item.projectName,
        projectId: item.projectId,
        employeeName,
        invoiceNumber: item.invoiceNumber,
        completedDate: job?.completedDate,
        customIdentifier: job?.customIdentifier || job?.jobNumber,
        jobNumber: job?.jobNumber,
        jobSortKey: job?.sortKey,
        jobId: job?.id,
        jobStatus: job?.status,
        jobTypeName: job?.jobTypeName,
        jobTypeInternal: job?.jobTypeInternal,
        owner: job?.owner?.name,
        customerName: item.customer?.customerName || job?.customerName,
        customerSortKey: item.customer?.sortKey || job?.customerSortKey,
        customerId: item.customer?.id || job?.customerId,
        billingCustomerName: item.billingCustomer?.customerName || job?.billingCustomerName,
        billingCustomerSortKey: item.billingCustomer?.sortKey || job?.billingCustomerSortKey,
        billingCustomerId: item.billingCustomer?.id || job?.billingCustomerId,
        customerPropertyName: item.customerProperty?.companyName || job?.customerPropertyName,
        customerPropertySortKey: item.customerProperty?.sortKey || job?.customerPropertySortKey,
        customerPropertyId: item.customerProperty?.id || job?.customerPropertyId,
        createdDate: item.createdDate,
        status: item.status,
        syncStatus: item.syncStatus,
        createdBy: item.createdBy,
        lastUpdatedDateTime: item.lastUpdatedDateTime,
        totalAmount: item.totalAmount,
        dueDate: item.dueDate,
        issuedDate: item.issuedDate,
        reviewReportId,
        reviewReportLabel: reviewReportId
          ? `Review : ${job.jobNumber} / ${visit.visitNumber}`
          : null,
        invoicePdfUrl: item.invoicePdfUrl,
        departmentName: item.department?.tagName,
        departmentId: item.department?.id,
        paymentInvoiceView: { items: paymentInvoices },
        amountToApply,
        jobTags,
        customerProvidedPONumber: item?.customerProvidedPONumber,
        customerProvidedWONumber: item?.customerProvidedWONumber,
        amountNotToExceed: item?.amountNotToExceed,
        propertyAddress,
        submittedBy: item.RelatedVisit.items?.[0]?.visit?.submittedBy,
        invoiceTags
      };
    });

    await this.annotateInvoiceBalances(partitionKey, sortKey, formattedResult.items);
    return formattedResult;
  };

  getAllInvoiceJobForPayment = async (
    partitionKey,
    sortKey,
    filter = {},
    limit,
    offset,
    sortBy,
    sortOrder,
    status,
    paymentId = false,
    unpaidInvoiceIds,
    adjustmentsFlag = false
  ) => {
    const { stringFilters, ...rest } = filter;
    const customizedFilter = {
      stringFilters: [],
      integerFilters: []
    };
    if (status) {
      if (status === InvoiceConstants.UNPAID) {
        customizedFilter.stringFilters.push({
          fieldName: 'Invoice.id',
          filterInput: { in: unpaidInvoiceIds }
        });
      } else {
        customizedFilter.stringFilters.push({
          fieldName: 'Invoice.status',
          filterInput: { eq: status }
        });
      }
    }

    let combinedStringFilterArr = customizedFilter.stringFilters;
    if (stringFilters) {
      combinedStringFilterArr = combinedStringFilterArr.concat(stringFilters);
    }
    customizedFilter.stringFilters = combinedStringFilterArr;

    const params = {
      partitionKey,
      sortKey,
      filter: { ...customizedFilter, ...rest },
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ],
      adjustmentsFlag
    };

    const response = await this.api.query(getAllInvoiceByCompanyForPayments, params);
    const invoices = response.data?.getCompany?.invoices;
    const formattedResult = { items: [], nextToken: invoices.nextToken };

    // Getting review report Id
    formattedResult.items = invoices?.items?.map(item => {
      const { job } = item;
      const paymentInvoices = item.paymentInvoiceView.items;
      const amountToApply = paymentId
        ? paymentInvoices.reduce(
            (totalApplied, paymentInvoice) =>
              paymentInvoice.parentId === paymentId
                ? roundCurrency(totalApplied + paymentInvoice.appliedAmount)
                : totalApplied,
            null
          )
        : null;

      return {
        id: item.id,
        sortKey: item.sortKey,
        adjustedBalance: item.adjustedBalance,
        invoiceNumber: item.invoiceNumber,
        customIdentifier: job?.customIdentifier || job?.jobNumber,
        jobNumber: job?.jobNumber,
        jobId: job?.id,
        jobTypeInternal: job?.jobTypeInternal,
        createdDate: item.createdDate,
        status: item.status,
        syncStatus: item.syncStatus,
        createdBy: item.createdBy,
        lastUpdatedDateTime: item.lastUpdatedDateTime,
        totalAmount: item.totalAmount,
        dueDate: item.dueDate,
        issuedDate: item.issuedDate,
        invoicePdfUrl: item.invoicePdfUrl,
        departmentName: item.department?.tagName,
        departmentId: item.department?.id,
        paymentInvoiceView: { items: paymentInvoices },
        amountToApply,
        customerProvidedPONumber: item?.customerProvidedPONumber,
        customerProvidedWONumber: item?.customerProvidedWONumber,
        amountNotToExceed: item?.amountNotToExceed
      };
    });

    await this.annotateInvoiceBalances(partitionKey, sortKey, formattedResult.items);
    return formattedResult;
  };

  getAllPayments = async (
    partitionKey,
    sortKey,
    filter = {},
    limit,
    offset,
    sortBy,
    sortOrder,
    status,
    invoiceId
  ) => {
    const customizedFilter = {
      stringFilters: [],
      integerFilters: [{ fieldName: 'Payment.deletedDate', filterInput: { is: null } }]
    };

    ['stringFilters', 'integerFilters'].forEach(filterType => {
      let combinedFilterArr = customizedFilter[filterType];
      if (filter[filterType]) {
        combinedFilterArr = combinedFilterArr.concat(filter[filterType]);
      }
      customizedFilter[filterType] = combinedFilterArr;
    });

    const params = {
      partitionKey,
      sortKey,
      filter: { ...filter, ...customizedFilter },
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    const response = await this.api.query(getAllPaymentByCompany, params);
    const payments = response.data?.getCompany?.payments;
    const formattedResult = {
      items: [],
      nextToken: payments.nextToken,
      paymentInvoicesForInvoice: []
    };

    formattedResult.items = payments?.items?.map(item => {
      const paymentInvoices = item.paymentInvoices.items;
      let amountToApply = null;
      if (invoiceId) {
        formattedResult.paymentInvoicesForInvoice.push(
          ...paymentInvoices.filter(pmtInv => pmtInv.invoiceId === invoiceId)
        );
        amountToApply = paymentInvoices.reduce(
          (totalApplied, paymentInvoice) =>
            paymentInvoice.invoiceId === invoiceId
              ? roundCurrency(totalApplied + paymentInvoice.appliedAmount)
              : totalApplied,
          null
        );
      }

      // @TODO - discuss - what should invoiceCreatedDate be if there are multiple invoices?
      // @TODO - discuss - should the created data be the .invoice.createdDate or paymentInvoice.createdDate?
      const invoiceCreatedDate =
        Array.isArray(paymentInvoices) &&
        paymentInvoices.length > 0 &&
        paymentInvoices[0].invoice?.createdDate;

      return {
        ...item,
        appliedAmount: roundCurrency(item.paymentAmount - item.availableAmount),
        paymentTypeName: item.paymentType?.name,
        paymentTypeId: item.paymentType?.id,
        paymentInvoices: { items: paymentInvoices },
        amountToApply,
        invoiceCreatedDate
      };
    });

    return formattedResult;
  };

  getAllCustomers = async (partitionKey, sortKey, filter, limit, offset, sortBy, sortOrder) => {
    const { stringFilters, ...rest } = filter || {};
    let customizedFilter = {
      stringFilters: []
    };

    let combinedStringFilterArr = customizedFilter.stringFilters;
    if (stringFilters) {
      combinedStringFilterArr = combinedStringFilterArr.concat(stringFilters);
    }
    customizedFilter.stringFilters = combinedStringFilterArr;

    if (rest) {
      customizedFilter = { ...customizedFilter, ...rest };
    }

    const params = {
      partitionKey,
      sortKey,
      filter: customizedFilter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    let result;

    const response = await this.api.query(getAllCustomerByCompany, params);
    const formattedResult = { items: [] };
    if (response?.data) {
      result = response.data.getCompany?.customer || {};
      if (result?.items) {
        result.items.forEach(item => {
          let billingAddress;
          let businessAddress;
          if (item?.companyAddresses?.items?.length) {
            billingAddress = item.companyAddresses.items.filter(
              address => address.addressType === 'billingAddress'
            );
            businessAddress = item.companyAddresses.items.filter(
              address => address.addressType === 'businessAddress'
            );
          }

          const fullBillingAddress = this.getCombinedAddress(billingAddress?.[0]);
          const fullBusinessAddress = this.getCombinedAddress(businessAddress?.[0]);

          const tags = item?.customerTags?.items
            ?.map(entry => entry?.mappedEntity?.tagName)
            .filter(e => e); // @TODO - investigate - why some entries have mappedEntity as null

          formattedResult.items.push({
            id: item.id,
            sortKey: item.sortKey,
            customerName: item.customerName,
            createdBy: item.createdBy,
            createdDate: item.createdDate,
            customerType: item.customerTypeValue,
            email: item.email,
            propertyCount: item.propertyCountComputed,
            phonePrimary: item.phonePrimary,
            phoneAlternate: item.phoneAlternate,
            billingAddress: fullBillingAddress,
            businessAddress: fullBusinessAddress,
            openJobs: item.openJobsComputed,
            arBalance: item.outstandingBalanceComputed,
            overdueBalance: item.overdueBalanceComputed,
            openJobValue: item.openJobsValueComputed,
            isActive: item.isActive,
            tags: tags || []
          });
        });
      }

      if (result.nextToken) {
        formattedResult.nextToken = result.nextToken;
      }
    }
    return formattedResult;
  };

  getAllAssets = async (partitionKey, sortKey, filter, limit, offset, sortBy, sortOrder) => {
    const { stringFilters, ...rest } = filter || {};
    let customizedFilter = {
      stringFilters: []
    };

    let combinedStringFilterArr = customizedFilter.stringFilters;
    if (stringFilters) {
      combinedStringFilterArr = combinedStringFilterArr.concat(stringFilters);
    }
    customizedFilter.stringFilters = combinedStringFilterArr;

    if (rest) {
      customizedFilter = { ...customizedFilter, ...rest };
    }

    const params = {
      partitionKey,
      sortKey,
      filter: customizedFilter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    let result;
    const response = await this.api.query(getAllAssetByCompany, params);
    const formattedResult = { items: [] };
    if (response?.data) {
      result = response.data.getCompany?.asset || {};
      if (result?.items) {
        result.items.forEach(item => {
          const property = item.property || {};
          let parentCustomer = {};
          if (property && property.customer) {
            parentCustomer = property.customer;
          }
          formattedResult.items.push({
            id: item.id,
            sortKey: item.sortKey,
            assetName: item.assetName,
            make: item.make,
            modelNumber: item.modelNumber,
            serialNo: item.serialNo,
            installDate: item.installDate,
            customerPropertyName: property && property.companyName,
            customerPropertyId: property && property.id,
            customerPropertySortKey: property.sortKey,
            customerName: parentCustomer && parentCustomer.customerName,
            customerId: parentCustomer && parentCustomer.id,
            customerSortKey: parentCustomer && parentCustomer.sortKey
          });
        });
      }

      if (result.nextToken) {
        formattedResult.nextToken = result.nextToken;
      }
    }
    return formattedResult;
  };

  getAllForms = async (partitionKey, sortKey, filter, limit, offset, sortBy, sortOrder) => {
    const { stringFilters, ...rest } = filter || {};
    let customizedFilter = {
      stringFilters: []
    };

    let combinedStringFilterArr = customizedFilter.stringFilters;
    if (stringFilters) {
      combinedStringFilterArr = combinedStringFilterArr.concat(stringFilters);
    }
    customizedFilter.stringFilters = combinedStringFilterArr;

    if (rest) {
      customizedFilter = { ...customizedFilter, ...rest };
    }

    const params = {
      partitionKey,
      sortKey,
      filter: customizedFilter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    let result;
    const response = await this.api.query(getAllFormByCompany, params);
    const formattedResult = { items: [] };
    if (response?.data) {
      result = response.data.getCompany?.form || {};
      if (result?.items) {
        result.items.forEach(item => {
          formattedResult.items.push({
            id: item.id
          });
        });
      }

      if (result.nextToken) {
        formattedResult.nextToken = result.nextToken;
      }
    }
    return formattedResult;
  };

  getAllQuotes = async (partitionKey, sortKey, filter, limit, offset, sortBy, sortOrder) => {
    const { stringFilters, ...rest } = filter || {};
    let customizedFilter = {
      stringFilters: []
    };

    let combinedStringFilterArr = customizedFilter.stringFilters;
    if (stringFilters) {
      combinedStringFilterArr = combinedStringFilterArr.concat(stringFilters);
    }
    customizedFilter.stringFilters = combinedStringFilterArr;

    if (rest) {
      customizedFilter = { ...customizedFilter, ...rest };
    }

    const params = {
      partitionKey,
      sortKey,
      filter: customizedFilter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    let result;
    const response = await this.api.query(getAllQuoteByCompany, params);
    const formattedResult = { items: [] };
    if (response?.data) {
      result = response.data.getCompany?.quote || {};
      if (result?.items) {
        result.items.forEach(item => {
          const customer =
            item.opportunity && item.opportunity.property && item.opportunity.property.customer;
          const property = item.opportunity && item.opportunity.property;

          const billingAddressObj =
            _.find(property?.billingCustomer?.companyAddresses?.items, ['addressType', BILLING]) ||
            _.find(property?.companyAddresses?.items, ['addressType', BILLING]) ||
            _.find(property?.customer?.companyAddresses?.items, ['addressType', BILLING]);

          const billingAddress = this.getCombinedAddress(billingAddressObj);

          const getRepName = (rep = {}) =>
            rep?.name || rep?.firstName || rep?.lastName || rep?.middleName;

          const propertyRep = getRepName(item?.propertyRep);
          const orderedBy = getRepName(item?.orderedBy);
          const quoteTagsFromVersions = item.versionedQuoteView.items.flatMap(
            version => version.quoteQuoteTags.items
          );
          const allQuoteTags = { items: [...item.quoteQuoteTags.items, ...quoteTagsFromVersions] };
          const quoteTags = this.getMappedEntityTagname(allQuoteTags);
          formattedResult.items.push({
            accountManagerValue: item.accountManagerValue,
            id: item.id,
            quoteNumber: item.quoteNumber,
            customIdentifier: item.customIdentifier || item.quoteNumber,
            description: item.description,
            name: item.name,
            createdDate: item.createdDate,
            dueDate: item.dueDate,
            createdBy: item.createdBy,
            ownerValue: item.ownerValue || '',
            salesByValue: item.salesByValue || '',
            totalAmountComputed: item.totalAmountComputed,
            expirationDate: item.expirationDate,
            status: this.capitalizeFirstLetter(item.status),
            email: item.email,
            customerName: (customer && customer.customerName) || '',
            customerId: (customer && customer.id) || '',
            customerSortKey: (customer && customer.sortKey) || '',
            customerPropertyId: (property && property.id) || '',
            customerPropertyName: (property && property.companyName) || '',
            customerPropertySortKey: (property && property.sortKey) || '',
            department: item.departmentValue,
            billingAddress,
            customerPoNumber: item?.customerPoNumber,
            propertyRep,
            orderedBy,
            jobs: item?.jobs,
            versionNumber: item?.versionNumber,
            versionLabel: item?.versionLabel,
            rejectedReason: item?.rejectedReason,
            issueDescription: item?.issueDescription,
            quoteTags
          });
        });
      }

      if (result.nextToken) {
        formattedResult.nextToken = result.nextToken;
      }
    }
    return formattedResult;
  };

  getAllProperties = async (partitionKey, sortKey, filter, limit, offset, sortBy, sortOrder) => {
    const { stringFilters, ...rest } = filter || {};
    let customizedFilter = {
      stringFilters: []
    };

    let combinedStringFilterArr = customizedFilter.stringFilters;
    if (stringFilters) {
      combinedStringFilterArr = combinedStringFilterArr.concat(stringFilters);
    }
    customizedFilter.stringFilters = combinedStringFilterArr;

    if (rest) {
      customizedFilter = { ...customizedFilter, ...rest };
    }

    const params = {
      partitionKey,
      sortKey,
      filter: customizedFilter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    let result;
    const response = await this.api.query(getAllPropertyByCompany, params);
    const formattedResult = { items: [] };
    if (response?.data) {
      result = response.data.getCompany?.property || {};
      if (result?.items) {
        result.items.forEach(property => {
          const { customer } = property;
          const propertyAddress = this.getCombinedAddress(
            _.find(property?.companyAddresses?.items, ['addressType', 'propertyAddress'])
          );
          const billingAddress = this.getCombinedAddress(
            _.find(property?.billingCustomer?.companyAddresses?.items, [
              'addressType',
              'billingAddress'
            ])
          );
          const { latitude, longitude } = property?.companyAddresses?.items?.[0] || {};
          formattedResult.items.push({
            id: property?.id,
            billingCustomerId: property?.billingCustomerId,
            billingCustomerName: property?.billingCustomer?.customerName,
            customerPropertyId: property?.id,
            customerPropertySortKey: property?.sortKey,
            customerPropertyName: property?.companyName,
            customerName: customer?.customerName,
            customerId: customer?.id,
            customerReps: customer?.customerReps,
            customerSortKey: customer?.sortKey,
            customerPropertyTypeValue: property?.customerPropertyTypeValue,
            latitude,
            longitude,
            overdueBalance: property?.overdueBalanceComputed || 0,
            openJobs: property?.openJobsComputed || 0,
            openJobValue: property?.openJobsValueComputed || 0,
            outstandingBalance: property?.outstandingBalanceComputed || 0,
            propertyAddress,
            propertyReps: property?.customerReps,
            propertyStatus: property?.isActive ? AppConstants.ACTIVE : AppConstants.DEACTIVATED,
            billingAddress,
            accountNumber: property?.accountNumber,
            createdBy: property?.createdBy,
            createdDate: property?.createdDate,
            customerTypeValue: property?.customer?.customerTypeValue
          });
        });
      }

      if (result.nextToken) {
        formattedResult.nextToken = result.nextToken;
      }
    }
    return formattedResult;
  };

  getAllAttachments = async (partitionKey, sortKey, filter, limit, offset, sortBy, sortOrder) => {
    const { stringFilters, ...rest } = filter || {};
    let customizedFilter = {
      stringFilters: []
    };

    let combinedStringFilterArr = customizedFilter.stringFilters;
    if (stringFilters) {
      combinedStringFilterArr = combinedStringFilterArr.concat(stringFilters);
    }
    customizedFilter.stringFilters = combinedStringFilterArr;

    if (rest) {
      customizedFilter = { ...customizedFilter, ...rest };
    }

    const params = {
      partitionKey,
      sortKey,
      filter: customizedFilter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    let result;
    const response = await this.api.query(getAllAttachmentByCompany, params);
    const formattedResult = { items: [] };
    if (response?.data) {
      result = response.data.getCompany?.attachment || {};
      if (result?.items) {
        result.items.forEach(item => {
          const job = item.job || {};
          formattedResult.items.push({
            id: item.id,
            dateAdded: item.createdDate,
            customFileName: item.customFileName,
            addedBy: item.addedBy || item.createdBy,
            fileName: item.fileName,
            customerId: job.customerId,
            customerSortKey: job.customerSortKey,
            customerName: job.customerName,
            customerPropertyName: job.customerPropertyName,
            customerPropertyId: job.customerPropertyId,
            customerPropertySortKey: job.customerPropertySortKey,
            jobNumber: job.jobNumber
          });
        });
      }

      if (result.nextToken) {
        formattedResult.nextToken = result.nextToken;
      }
    }
    return formattedResult;
  };
}
