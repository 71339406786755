import { getMutationParams } from 'scenes/Customer/PropertyDetail/Tasks/AddTaskToQuote';
import { CustomerPropertyService } from 'services/core';
import { logErrorWithCallback } from 'utils';

export const handleAddToQuote = async ({
  addTaskService,
  fetchTasksOnCustomerProperty,
  propertyId,
  quoteInfo,
  recommendedTaskResponse,
  selectionModel,
  setAddQuoteLoading,
  setIsModalOpen,
  snackbarOn,
  tenantId
}) => {
  try {
    setAddQuoteLoading(true);
    const customerPropertyServiceObj = new CustomerPropertyService();
    const addTasksToQuotePromises = selectionModel.map(async taskId => {
      const response = await customerPropertyServiceObj.getTaskById(taskId);
      const selectedTask = recommendedTaskResponse.items.find(task => task.id === taskId);
      const formattedTask = {
        taskEntries: response,
        id: taskId,
        assetId: selectedTask?.assetId,
        description: selectedTask?.description,
        name: selectedTask?.name
      };
      const mutationParams = getMutationParams(quoteInfo, [formattedTask], tenantId, propertyId);
      return mutationParams[0].taskToUpdate;
    });
    const tasksPayload = await Promise.all(addTasksToQuotePromises);
    await addTaskService(tasksPayload.flat());

    snackbarOn(
      'success',
      `Quote with recommended tasks created successfuly ${quoteInfo.customIdentifier ||
        quoteInfo.quoteNumber}`
    );
    await fetchTasksOnCustomerProperty();
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to add tasks to existing quote');
  } finally {
    setAddQuoteLoading(false);
    setIsModalOpen(false);
  }
};

export const handleCancel = ({ setIsModalOpen, setSelectionModel }) => {
  setSelectionModel([]);
  setIsModalOpen(false);
};
