export const FeatureFlags = {
  ADJUSTMENTS: 'adjustments',
  ADP_EXPORT: 'adpExport',
  ASSIGNED_PENDING_TASKS_MOBILE: 'assignedPendingTasksMobile',
  ASYNC_JOB_REPORT: 'jobReportV3',
  CHANGE_ORDER_WORKFLOW: 'changeOrderWorkflow',
  CREW_PICK_LIST: 'crewPickList',
  DEBUG_MODE: 'debugMode',
  DEPARTMENTS_ARE_LIMITED_BY_JOB: 'departmentsAreLimitedByJob',
  DISPATCH_BOARD_LONG_POLL: 'dispatchBoardLongPoll',
  DISPATCH_MAP_VIEW: 'dispatchMapView',
  DISPLAY_PRICEBOOK_MARKUP: 'displayPricebookMarkup',
  EMAIL_REPLY_ADDRESS: 'emailReplyAddress',
  EMAIL_REPLIES: 'emailReplies',
  EMAIL_ATTACHMENTS_INVOICE: 'emailAttachmentsInvoice',
  ENABLE_DISPATCH_LAZY_LOAD: 'enableDispatchLazyLoad',
  ENABLE_EDIT_PRICEBOOK: 'enableEditPricebook',
  ENTERPRISE_LABOR_COSTING: 'enterpriseLaborCosting',
  JOB_CLOSEOUT: 'jobCloseout',
  JOB_DISPATCH: 'jobDispatch',
  JOB_PROCUREMENT_STATUS: 'jobProcurementStatus',
  JOB_QUOTE_STATUS: 'jobQuoteStatus',
  JOB_QUOTE_INCURRED_COST: 'jobQuoteIncurredCost',
  MAINTENANCE_TEMPLATES_V2: 'maintenanceTemplatesV2',
  MARKUP_AND_MARGIN: 'markupAndMargin',
  METABASE: 'metabase',
  SIGMA: 'sigma',
  PENDING_TASKS_TABLE: 'pendingTasksTable',
  PM_FILE_SYSTEM: 'pmFileSystem',
  PRESET_JOB_REPORT: 'jobReportV2',
  PRICEBOOK_AUTO_UPDATE_SCALED_MARKUP: 'pricebookAutoUpdateScaledMarkup',
  PROCUREMENT: 'procurement',
  PROCUREMENT_USAGE: 'procurementUsage',
  PRODUCT_CATEGORIES_ENABLED: 'productCategoriesEnabled',
  PROJECT_MANAGEMENT: 'projectManagement',
  QUOTESV2: 'quotesV2',
  QUOTE_ATTACHMENTS_ENHANCEMENTS: 'quoteAttachmentsEnhancements',
  RECOMMENDED_TASKS_WORKFLOW: 'recommendedTaskWorkflow',
  REPORTS_V2: 'reportsV2',
  SERVICE_AGREEMENTS: 'serviceAgreements',
  SERVICE_AGREEMENTS_ADVANCED_SCHEDULING: 'serviceAgreementAdvancedScheduling',
  SERVICE_AGREEMENTS_JOBS_PREVIEW: 'serviceAgreementsJobsPreview',
  SERVICE_CHANNEL_INTEGRATION: 'serviceChannelIntegration',
  SHOW_BILL_TAX_AND_TOTAL_FROM_ACCOUNTING_SYSTEM: 'showBillTaxAndTotalFromAccountingSystem',
  TASKS_OVERFLOW_X_GRID: 'tasksOverflowXGrid',
  TRUCK_STOCK_INVENTORY: 'truckStockInventory',
  USE_PROPERTY_PRICEBOOKS: 'usePropertyPricebooks',
  VENDOR_INVOICE_APPROVAL: 'vendorInvoiceApproval',
  VERBOSE_ERRORS: 'verboseErrors',
  VISIT_CLOSEOUT_ACTIONS: 'visitCloseoutActions',
  WRINKLE_IN_TIME: 'wrinkeInTime',
  REQUISITION_FOR_JOB_VISITS: 'requisitionsForJobVisits',
  SPECTRUM_JOB_COST_DETAILS: 'procurementSpectrumJobPhaseAndCostType',
  RECURRING_NVES: 'recurringNves',
  SPARSE_PRICEBOOKS: 'dynamic-pricebooks'
};

export default FeatureFlags;
