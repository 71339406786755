import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { GET_REVIEW_REPORT_BY_ID } from '../queries';

const DELETE_ATTACHMENT = gql`
  mutation deleteAttachmentById($partitionKey: String!, $id: String!) {
    deleteAttachmentById(partitionKey: $partitionKey, id: $id) {
      id
    }
  }
`;

const serializer = ({ tenantId, attachmentId }) => ({
  variables: {
    partitionKey: tenantId,
    id: attachmentId
  }
});

export const useDeleteReviewReportAttachment = reviewReportId => {
  return useExtendedMutation(DELETE_ATTACHMENT, {
    serializer,
    showConfirmation: () => true,
    confirmationModalOptions: {
      title: 'Delete Attachment',
      warn: true,
      modalText: 'Are you sure you want to delete this attachment?'
    },
    update: (cache, { data: { deleteAttachmentById } }) => {
      const query = {
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        }
      };
      const cachedData = cache.readQuery(query);
      cache.writeQuery({
        ...query,
        data: {
          getReviewReportById: {
            ...cachedData.getReviewReportById,
            attachments: {
              __typename: cachedData.getReviewReportById?.attachments?.__typename,
              items:
                cachedData.getReviewReportById?.attachments?.items?.filter(
                  item => item?.id !== deleteAttachmentById?.id
                ) || []
            }
          }
        }
      });
    }
  });
};
