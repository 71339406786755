import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { payrollSettingFilter } from 'constants/common';
import { useSnackbar } from 'customHooks/useSnackbar';
import { CompanyService } from 'services/core';
import { logErrorWithCallback } from 'utils';

export const useCompanyTimezone = () => {
  const { tenantId, tenantCompanyId } = useSelector(state => state?.user) || {};
  const [companyTimezone, setCompanyTimezone] = useState();
  const [loading, setLoading] = useState(false);
  const snackbar = useSnackbar();

  useEffect(() => {
    (async () => {
      if (!tenantId || !tenantCompanyId) {
        return;
      }

      try {
        setLoading(true);
        const Service = new CompanyService();
        const { data } = await Service.getCompanySettings(
          tenantId,
          tenantCompanyId,
          payrollSettingFilter
        );
        const settingsRaw = data?.getCompany?.companySettings?.items?.[0]?.settings;
        const parsedSettings = settingsRaw ? JSON.parse(settingsRaw) : {};
        setCompanyTimezone(parsedSettings?.timeZone);
      } catch (error) {
        logErrorWithCallback(error, snackbar, 'Unable to fetch payroll settings');
      } finally {
        setLoading(false);
      }

      return {};
    })();
  }, [tenantId, tenantCompanyId, snackbar]);

  return [{ data: companyTimezone, loading }];
};
