import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { func, string } from 'prop-types';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { GET_DISPATCH_EVENTS_BY_TECH_IDS } from '@dispatch/components/DispatchBoard/queries/useDispatchEventsByTechIds';
import { DB_LAZY_LOAD_THRESHOLD } from '@dispatch/Dispatch.constants';
import { watchedQueries } from '@dispatch/Dispatch.store';
import { TECHS_RESPONSE_PROP } from '@dispatch/queries';
import { useDispatchSettings } from '@dispatch/settings';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { useNewTechEventsOnDayChange, useVisitDragLifecycle } from '../../DispatchBoard.hooks';
import { selectEndTime, selectStartTime } from '../../DispatchBoard.selectors';
import { useStyles } from '../../DispatchBoard.styles';
import TechsFilterButton from '../TechsFilterButton';

import WeekSwimLane from './components/WeekSwimLane';
import WeekViewHeader from './components/WeekViewHeader';
import { WeekViewContext } from './WeekView.context';
import { useItemData, useVisitDrop } from './WeekView.hooks';
import { selectLaneHeight } from './WeekView.selectors';

const BoardFilterContainer = () => {
  const classes = useStyles();

  return (
    <div className={classes.boardFilterContainer}>
      <TechsFilterButton />
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const innerElementType = forwardRef(({ children, style, ...rest }, ref) => (
  <div ref={ref} style={{ ...style, width: '100%' }} {...rest}>
    <BoardFilterContainer />
    <WeekViewHeader />
    {children}
  </div>
));

const WeekView = ({ techsResponse, lazyDispatchEventsTuple, startDay, filterBy }) => {
  const isLazyLoadEnabled = useFlags()[FeatureFlags.ENABLE_DISPATCH_LAZY_LOAD];
  const listRef = useRef();
  const [infiniteLoader, setInfiniteLoader] = useState();
  const [activeCell, setActiveCell] = useState(null);
  const [collected, dropRef] = useVisitDrop();
  const [queryDispatchEvents, dispatchEventsResponse] = lazyDispatchEventsTuple;
  const { weekViewStartDay } = useDispatchSettings();
  const startDateTime = selectStartTime({ day: startDay, weekViewStartDay, weekView: true });
  const endDateTime = selectEndTime({ day: startDay, weekViewStartDay, weekView: true });
  const techIds = useMemo(() => techsResponse?.data?.map(tech => tech.id), [techsResponse]);

  const itemData = useItemData({
    techsResponse,
    dispatchEventsResponse,
    startDay
  });

  useEffect(() => {
    listRef.current?.resetAfterIndex(activeCell?.laneIndex || 0);
  }, [itemData, activeCell]);

  useVisitDragLifecycle({ ...collected, filterBy });
  useNewTechEventsOnDayChange({
    techIds,
    dispatchEventsResponse,
    infiniteLoader,
    queryDispatchEvents,
    startDateTime,
    endDateTime,
    isLazyLoadEnabled
  });

  const loadMoreItems = (startIndex, stopIndex) => {
    if (!techsResponse.loading) {
      const techsToFetch = techIds.slice(startIndex - 1, stopIndex);

      const variables = { startDateTime, endDateTime, techIds: techsToFetch };

      watchedQueries.useDispatchBoardEvents = {
        query: GET_DISPATCH_EVENTS_BY_TECH_IDS,
        variables
      };

      if (techsToFetch.length) {
        return (dispatchEventsResponse.fetchMore || queryDispatchEvents)({
          variables: {
            data: variables
          }
        });
      }
    }
  };

  const isItemLoaded = index => {
    if (index === 0) return true;
    const techId = techIds[index - 1];
    return Boolean(dispatchEventsResponse.data[techId]);
  };

  return (
    <WeekViewContext.Provider value={{ setActiveCell }}>
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemData.length}
            loadMoreItems={loadMoreItems}
            minimumBatchSize={5}
            ref={setInfiniteLoader}
            threshold={isLazyLoadEnabled ? DB_LAZY_LOAD_THRESHOLD : 1000}
          >
            {({ onItemsRendered, ref }) => (
              <VariableSizeList
                height={height}
                innerElementType={innerElementType}
                itemCount={itemData.length}
                itemData={itemData}
                itemKey={i => itemData[i]?.tech?.id || i}
                itemSize={i => selectLaneHeight(i, itemData, activeCell)}
                outerRef={dropRef}
                overscanCount={10}
                ref={newRef => {
                  listRef.current = newRef;
                  ref(newRef);
                }}
                width={width}
                onItemsRendered={onItemsRendered}
              >
                {WeekSwimLane}
              </VariableSizeList>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </WeekViewContext.Provider>
  );
};

WeekView.propTypes = {
  techsResponse: TECHS_RESPONSE_PROP.isRequired,
  // eslint-disable-next-line react/require-default-props
  startDay: string,
  filterBy: func.isRequired
};

export default WeekView;
