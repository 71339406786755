import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: '230px'
  },
  dateLabel: {
    color: theme.palette.text.primary,
    cursor: 'pointer',
    fontWeight: 400
  },
  cancelButton: {
    marginRight: theme.spacing(1)
  },
  actionWrapper: {
    padding: theme.spacing(1)
  },
  hideRangeFilter: {
    '& .rdrDefinedRangesWrapper': {
      display: 'none'
    }
  },
  dateRangePicker: {
    '& .rdrDateDisplayWrapper': {
      display: 'none'
    },
    '& .rdrInputRanges': {
      display: 'none'
    },
    '& .rdrStaticRanges': {
      paddingTop: theme.spacing(5)
    },
    '& .rdrDayHovered, .rdrDayInPreview, .rdrDayEndPreview, .rdrDayStartPreview': {
      borderColor: `${theme.palette.other.darkBlue} !important`
    },
    '& .rdrSelected, .rdrStartEdge, .rdrEndEdge, .rdrInRange, .rdrStaticRangeLabel': {
      color: `${theme.palette.other.darkBlue} !important`
    }
  }
}));
