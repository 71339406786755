import { useCallback } from 'react';

import { DEFAULT_PRESET } from 'scenes/Jobs/DetailView/JobTabs/InvoiceAndReport';
import { generateJobReportPDFs } from 'scenes/Jobs/DetailView/JobTabs/InvoiceAndReport/exportJobReportService';

export const useGenerateJobReportPdf = (job, snackbar) =>
  useCallback(async () => {
    const visits = job?.visits?.items || [];
    const success = await generateJobReportPDFs({
      visitIds: visits.map(v => v.id),
      viewSettings: JSON.parse(DEFAULT_PRESET.settings),
      jobId: job.id,
      isJobCloseoutReport: job.closeoutReport,
      snackbarOn: snackbar
    });
    if (success === true) {
      snackbar('success', 'Generating Job Report. Please hit refresh after a few seconds. ');
    }
  }, [job, snackbar]);
