import React, { useCallback, useState } from 'react';

import { Button, Modal, SgtForm, ThemeProvider } from '@BuildHero/sergeant';

import ErrorBoundaries from 'scenes/Error';

import configuration from './NewTenantModal.config';
import { useProvisionTenant } from './NewTenantModal.gql';

const NewTenantModal = ({ open, onClose }) => {
  const [formService, setFormService] = useState();
  const [provisionTenant, { loading }] = useProvisionTenant();

  const handleSubmit = useCallback(
    async newData => {
      const {
        data: {
          tenantProvision: { id }
        }
      } = await provisionTenant({ ...newData, type: newData.type?.value });
      onClose(id);
    },
    [provisionTenant, onClose]
  );

  return (
    <ErrorBoundaries>
      <ThemeProvider>
        <Modal
          actions={
            <Button
              loading={loading}
              name="save"
              onClick={formService?.formikContext?.handleSubmit}
            >
              Save Tenant
            </Button>
          }
          fullScreen
          open={open}
          title="New Tenant"
          onClose={() => onClose()}
        >
          <SgtForm
            configuration={configuration}
            formikProps={{
              validateOnChange: false,
              validateOnBlur: true
            }}
            initialValues={{
              firstName: '',
              lastName: '',
              companyName: '',
              accountName: '',
              userName: '',
              email: '',
              type: { label: 'Test', value: 'Test' },
              salesforceId: '',
              tier: 'free',
              status: 'active',
              ownerName: 'BuildOps',
              sendWelcomeEmail: true
            }}
            layout="default"
            onCreateService={setFormService}
            onSubmit={handleSubmit}
          />
        </Modal>
      </ThemeProvider>
    </ErrorBoundaries>
  );
};

export default NewTenantModal;
