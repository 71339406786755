import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Button,
  ButtonType,
  Checkbox,
  Modal,
  MoreButton,
  SgtForm,
  ThemeProvider,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';
import { Box, IconButton, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EditIcon from '@material-ui/icons/Edit';
import WarningIcon from '@material-ui/icons/Warning';

import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import Skeleton from 'react-loading-skeleton';

import { useSelector } from 'react-redux';

import { useCompanyTimezone } from '@pm/components/hooks';

import { LinkButton } from 'components';

import WrapTable from 'components/WrapTable';

import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import useCrews from 'customHooks/useCrews';
import useDepartments from 'customHooks/useDepartments';
import useServiceAgreement from 'customHooks/useServiceAgreement';

import { getFormattedDateString, getFormattedHoursString, getQuantityString } from 'utils';

import { useDispatchTechs } from '../../../Dispatch/queries/useDispatchTechs';

import useCustomerPropertyMaintenanceJobPreview from '../MaintenancePlan/hooks/useCustomerPropertyMaintenanceJobPreview';

import useAdvancedSchedulingServiceAgreementCustomerPropertyMetadata from './hooks/useAdvancedSchedulingServiceAgreementCustomerPropertyMetadata';
import useDeleteAdvancedMaintenanceTemplateGroupScheduleById from './hooks/useDeleteAdvancedMaintenanceTemplateGroupScheduleById';
import useUpsertAdvancedMaintenanceTemplateGroup from './hooks/useUpsertAdvancedMaintenanceTemplateGroup';
import useUpsertCustomerPropertyServiceAgreementMetadata from './hooks/useUpsertCustomerPropertyServiceAgreementMetadata';
import { maintenanceGroupLayout, peopleLayout } from './layout';

import VisitScheduler from './VisitScheduler';

const styledLabel = (label, isTitle = false) => (
  <Typography variant={TV.B2} weight={isTitle ? TW.BOLD : TW.REGULAR}>
    {label}
  </Typography>
);

const INITIAL_GROUP_MODAL_STATE = {
  open: false,
  maintenancesToGroup: [],
  excludedMaintenancesToGroup: []
};

const EDIT_MAINTENANCE_DEFAULT_VALUES = {
  id: undefined,
  maintenanceGroupName: '',
  maintenanceTags: null,
  maintenanceType: {},
  budgetedHours: 0,
  numberOfVisits: 0,
  daysBeforeDueDate: 0,
  crewId: null,
  primaryTechId: null,
  extraTechIds: null,
  description: '',
  visitsToSchedule: null
};

const navButtonStyle = {
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: '0%',
  maxHeight: 32,
  maxWidth: 32,
  marginLeft: 15
};

const ScheduleMaintenances = ({ serviceAgreementId, triggerSave, setSaving }) => {
  const theme = useTheme();

  const useStyles = makeStyles({
    '@global': {
      '.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
        backgroundColor: 'white'
      },
      '.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label': {
        backgroundColor: 'gray'
      }
    }
  });

  const [tasksPreviewModal, setTasksPreviewModal] = useState({ open: false });

  const maintenanceTagOptions = useSelector(s =>
    s.company?.jobTags?.items
      ?.sort((a, b) => a.sortOrder - b.sortOrder)
      ?.map(t => ({
        id: t.id,
        value: t.id,
        label: t.tagName
      }))
  );

  const maintenanceTypeOptions = useSelector(s =>
    s.company.jobTypes?.items
      ?.filter(t => t.tagType === 'MaintenanceType')
      ?.sort((a, b) => a.sortOrder - b.sortOrder)
      ?.map(t => ({
        id: t.id,
        value: t.id,
        label: t.tagName
      }))
  );

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [
    serviceAgreementPropertyMetadataFormService,
    setServiceAgreementPropertyMetadataFormService
  ] = useState(null);

  useEffect(() => {
    if (!isFirstLoad) {
      serviceAgreementPropertyMetadataFormService.submit();
    } else {
      setIsFirstLoad(false);
    }
  }, [triggerSave]);

  const [serviceAgreement = {}, loadingServiceAgreement] = useServiceAgreement({
    serviceAgreementId
  });

  const {
    customerProperties: { items: customerProperties = [] } = {},
    propertyAssets: { items: propertyAssets = [] } = {}
  } = serviceAgreement;

  const propReps = useMemo(
    () =>
      customerProperties
        ?.reduce((acc, p) => [...acc, ...(p?.customerReps?.items || [])], [])
        ?.map(x => ({
          label: x.mappedEntity.name,
          value: x.mappedEntityId
        })),
    [customerProperties]
  );

  const properties = useMemo(
    () =>
      customerProperties.map(({ id, entityType, companyName: propertyName }) => ({
        id,
        entityType,
        propertyName,
        assets: propertyAssets.filter(({ parentId }) => parentId === id)
      })),
    [customerProperties, propertyAssets]
  );

  const [selectedProperty, setSelectedProperty] = useState();
  const selectedPropertyId = useMemo(() => selectedProperty?.id, [selectedProperty]);

  useEffect(() => {
    // Initialize selectedProperty to first property on first load
    if (properties.length && !selectedPropertyId) {
      setSelectedProperty(properties[0]);
    }
  }, [properties, selectedPropertyId]);

  const curIndex = useMemo(() => {
    const findResult = properties?.findIndex(x => x.id === selectedPropertyId);
    return findResult >= 0 ? findResult : 0;
  }, [properties, selectedPropertyId]);

  const confirmContext = useConfirmModal();
  const [tempFormChanges, setTempFormChanges] = useState([]);

  const handleSelectProperty = async property => {
    if (property.id !== selectedPropertyId) {
      if (tempFormChanges.length > 0) {
        const shouldContinue = await confirmContext.confirm({
          body: 'You have unsaved changes, are you sure you want to leave?',
          buttonLabel: 'Ok',
          cancelButtonLabel: 'Cancel'
        });
        if (shouldContinue) {
          setSelectedProperty(property);
          setTempFormChanges([]);
        }
      } else {
        setSelectedProperty(property);
        setTempFormChanges([]);
      }
    }
  };

  const [
    serviceAgreementPropertyMetadata,
    loadingServiceAgreementPropertyMetadata,
    refetchAdvancedSchedulingServiceAgreementCustomerPropertyMetadata
  ] = useAdvancedSchedulingServiceAgreementCustomerPropertyMetadata({
    serviceAgreementId,
    customerPropertyId: selectedPropertyId
  });

  const initialMetadata = useMemo(() => {
    if (!serviceAgreementPropertyMetadata?.customerPropertyServiceAgreementsMetadata) return {};

    const {
      department,
      primaryTech,
      propertyRep,
      selectedCrew,
      additionalTechs
    } = serviceAgreementPropertyMetadata?.customerPropertyServiceAgreementsMetadata;
    return {
      departmentId: department ? { label: department.tagName, value: department.id } : null,
      propertyRepId: propertyRep ? { label: propertyRep.name, value: propertyRep.id } : null,
      primaryTechId: primaryTech ? { label: primaryTech.name, value: primaryTech.id } : null,
      crewId: selectedCrew ? { label: selectedCrew.name, value: selectedCrew.id } : null,
      extraTechIds: additionalTechs?.map(t => ({ label: t.employee.name, value: t.employee.id }))
    };
  }, [serviceAgreementPropertyMetadata]);

  const {
    data: maintenanceRows = [],
    loading: loadingMaintenanceJobPreview
  } = useCustomerPropertyMaintenanceJobPreview({
    serviceAgreementId,
    customerPropertyId: selectedPropertyId
  });

  const ungroupedMaintenanceRows = useMemo(
    () =>
      maintenanceRows.filter(
        r =>
          !r.singleJobTaskTemplateId &&
          !serviceAgreementPropertyMetadata?.advancedMaintenanceTemplateGroups
            ?.filter(g => !g.singleJobTaskTemplateId)
            ?.reduce((acc, g) => [...acc, ...g.advancedMaintenanceTemplateGroupSchedules], [])
            ?.map(gr => gr.firstDueDate)
            ?.includes(r.firstDueDate)
      ),
    [maintenanceRows, serviceAgreementPropertyMetadata]
  );

  const excludedMaintenanceRows = useMemo(
    () =>
      Object.values(
        maintenanceRows
          .filter(r => r.singleJobTaskTemplateId)
          .reduce((acc, curr) => {
            const { singleJobTaskTemplateId } = curr;
            if (!acc[singleJobTaskTemplateId]) {
              return {
                ...acc,
                [singleJobTaskTemplateId]: {
                  ...curr,
                  annualOccurrences: 1,
                  visitsScheduled: serviceAgreementPropertyMetadata?.advancedMaintenanceTemplateGroups
                    ?.filter(g => g.singleJobTaskTemplateId === singleJobTaskTemplateId)
                    ?.reduce((visits, { visitTemplates }) => visits + visitTemplates.length, 0)
                }
              };
            }
            const prev = acc[singleJobTaskTemplateId];
            return {
              ...acc,
              [singleJobTaskTemplateId]: {
                ...prev,
                firstDueDate: Math.min(prev.firstDueDate, curr.firstDueDate),
                numberOfPropertyAssets: prev.numberOfPropertyAssets + curr.numberOfPropertyAssets,
                cumulativeLaborEstimate:
                  prev.cumulativeLaborEstimate + curr.cumulativeLaborEstimate,
                annualOccurrences: prev.annualOccurrences + 1
              }
            };
          }, {})
      ),
    [maintenanceRows, serviceAgreementPropertyMetadata]
  );

  const [modal, setModal] = useState(INITIAL_GROUP_MODAL_STATE);
  const [maintenanceGroupModalFormService, setMaintenanceGroupModalFormService] = useState(null);

  const [selectedSingleJobTaskTemplateId, setSelectedSingleJobTaskTemplateId] = useState(null);
  const [
    selectedAdvancedMaintenanceTemplateGroupId,
    setSelectedAdvancedMaintenanceTemplateGroupId
  ] = useState(undefined);

  const maintenancesForPreview = useMemo(
    () =>
      selectedSingleJobTaskTemplateId
        ? maintenanceRows.filter(r => modal.excludedMaintenancesToGroup.includes(r.id))
        : ungroupedMaintenanceRows.filter(r => modal.maintenancesToGroup.includes(r.id)),
    [
      selectedSingleJobTaskTemplateId,
      maintenanceRows,
      ungroupedMaintenanceRows,
      modal.maintenancesToGroup,
      modal.excludedMaintenancesToGroup
    ]
  );

  const maintenanceGroupNames = useMemo(
    () =>
      Object.keys(
        serviceAgreementPropertyMetadata?.advancedMaintenanceTemplateGroups
          ?.filter(g => g.id !== selectedAdvancedMaintenanceTemplateGroupId)
          ?.reduce(
            (acc, { name, advancedMaintenanceTemplateGroupSchedules }) =>
              advancedMaintenanceTemplateGroupSchedules.length
                ? {
                    ...acc,
                    [name]: true
                  }
                : acc,
            {}
          ) ?? {}
      ) ?? [],
    [
      serviceAgreementPropertyMetadata?.advancedMaintenanceTemplateGroups,
      selectedAdvancedMaintenanceTemplateGroupId
    ]
  );

  const [{ data: companyTimezone }] = useCompanyTimezone();

  const [departmentsResponse, loadingDepartments] = useDepartments();
  const departments = useMemo(
    () => departmentsResponse.map(d => ({ label: d.name, value: d.id })),
    [departmentsResponse]
  );

  const [crewsResponse, loadingCrews] = useCrews();
  const crews = useMemo(() => crewsResponse.map(d => ({ label: d.name, value: d.id })), [
    crewsResponse
  ]);

  const techsResponse = useDispatchTechs();
  const loadingTechs = useMemo(() => techsResponse?.loading, [techsResponse]);
  const techOptions = useMemo(
    () =>
      techsResponse?.data?.map(tech => ({
        value: tech.id,
        label: tech.name
      })) || [],
    [techsResponse]
  );

  const ungroupedMaintenanceColumns = useMemo(() => {
    return [
      {
        field: 'id',
        width: 32,
        renderHeader: () => (
          <Checkbox
            checked={
              ungroupedMaintenanceRows.length &&
              modal.maintenancesToGroup.length === ungroupedMaintenanceRows.length
            }
            color="secondary"
            disabled={!ungroupedMaintenanceRows.length}
            onChange={event => {
              setSelectedSingleJobTaskTemplateId(null);
              setSelectedAdvancedMaintenanceTemplateGroupId(undefined);
              setModal(oldModal => ({
                ...oldModal,
                maintenancesToGroup: event.target.checked
                  ? ungroupedMaintenanceRows.map(r => r.id)
                  : [],
                excludedMaintenancesToGroup: []
              }));
            }}
          />
        ),
        renderCell: cell => (
          <Checkbox
            checked={modal.maintenancesToGroup.includes(cell.row.id)}
            color="secondary"
            onChange={event => {
              setSelectedSingleJobTaskTemplateId(null);
              setSelectedAdvancedMaintenanceTemplateGroupId(undefined);
              setModal(oldModal => ({
                ...oldModal,
                maintenancesToGroup: event.target.checked
                  ? [...oldModal.maintenancesToGroup, cell.row.id]
                  : oldModal.maintenancesToGroup.filter(m => m !== cell.row.id),
                excludedMaintenancesToGroup: []
              }));
            }}
          />
        )
      },
      { field: 'maintenanceName', headerName: 'Maintenance', minWidth: 180 },
      {
        field: 'firstDueDate',
        headerName: 'Due Date',
        flex: 1,
        renderCell: cell => getFormattedDateString(cell.value, companyTimezone)
      },
      {
        field: 'numberOfPropertyAssets',
        headerName: 'Assets',
        flex: 1,
        renderCell: cell => getQuantityString(cell.value, 'asset')
      },
      {
        field: 'cumulativeLaborEstimate',
        headerName: 'Labor Estimate',
        flex: 1,
        renderCell: cell => getFormattedHoursString(cell.value)
      },
      {
        field: 'propertyChecklistNames',
        headerName: 'Property Checklists',
        flex: 1
      },
      {
        field: 'mergedTaskTemplates',
        headerName: 'Maintenance Tasks',
        flex: 1,
        renderCell: cell => (
          <LinkButton
            disableLink
            label={`${cell.value.length} Tasks`}
            onClick={() =>
              setTasksPreviewModal({
                open: true,
                tasks: cell.value
              })
            }
          />
        )
      }
    ];
  }, [companyTimezone, modal, ungroupedMaintenanceRows]);

  const [
    deleteAdvancedMaintenanceTemplateGroupScheduleById,
    { loading: deletingAdvancedMaintenanceTemplateGroupScheduleById }
  ] = useDeleteAdvancedMaintenanceTemplateGroupScheduleById({
    onSuccess: () => {
      refetchAdvancedSchedulingServiceAgreementCustomerPropertyMetadata();
    }
  });

  const groupedMaintenanceColumns = useMemo(() => {
    return [
      { field: 'maintenanceName', headerName: 'Maintenance', flex: 1 },
      {
        field: 'firstDueDate',
        headerName: 'Due Date',
        flex: 1,
        renderCell: cell => getFormattedDateString(cell.value, companyTimezone)
      },
      { field: 'maintenanceType', headerName: 'Maintenance Type', flex: 1 },
      {
        field: 'visitsScheduled',
        headerName: 'Visits Scheduled',
        flex: 1,
        renderCell: cell => `${cell.value} visit(s)`
      },
      {
        field: 'assets',
        headerName: 'Assets',
        flex: 1,
        renderCell: cell => getQuantityString(cell.value, 'asset')
      },
      {
        field: 'laborEstimate',
        headerName: 'Labor Estimate',
        flex: 1,
        renderCell: cell => getFormattedHoursString(cell.value)
      },
      {
        field: 'budgetedHours',
        headerName: 'Budgeted Hours',
        flex: 1,
        renderCell: cell => getQuantityString(cell.value, 'hour')
      },
      { field: 'primaryTechnician', headerName: 'Primary Technician', flex: 1 },
      { field: 'additionalTechnicians', headerName: 'Additional Technicians', flex: 1 },
      {
        field: 'id',
        width: 32,
        renderCell: cell => (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <MoreButton
              options={[
                {
                  label: 'Remove from Group',
                  onClick: async () => {
                    if (
                      await confirmContext.confirm({
                        title: (
                          <>
                            <WarningIcon
                              style={{
                                color: theme.palette.support.yellow.dark,
                                fontSize: 24,
                                marginRight: theme.spacing(1)
                              }}
                            />
                            Remove from Group
                          </>
                        ),
                        body: 'All grouping details will be removed from this maintenance.',
                        buttonLabel: 'Continue',
                        cancelButtonLabel: 'Close'
                      })
                    ) {
                      await deleteAdvancedMaintenanceTemplateGroupScheduleById({
                        scheduleId: cell.row.id
                      });
                    }
                  }
                }
              ]}
              style={{ padding: 0 }}
            />
          </Box>
        )
      }
    ];
  }, [companyTimezone, confirmContext]);

  const getFormValuesFromMaintenanceGroup = useCallback(
    ({
      id,
      name,
      description,
      jobTags,
      jobType,
      numberOfVisits,
      budgetedHours,
      selectedCrew,
      primaryTech,
      additionalTechs,
      visitTemplates
    }) => ({
      id,
      maintenanceGroupName: name,
      description,
      maintenanceTags: jobTags.map(({ jobTag: { id: tagId, tagName } }) => ({
        id: tagId,
        value: tagId,
        label: tagName
      })),
      maintenanceType: {
        id: jobType.id,
        value: jobType.id,
        label: jobType.tagName
      },
      numberOfVisits,
      budgetedHours: budgetedHours || 0,
      crewId: selectedCrew?.id ? crews.find(c => c.value === selectedCrew.id) ?? null : null,
      primaryTechId: primaryTech
        ? {
            value: primaryTech.id,
            label: primaryTech.name
          }
        : null,
      extraTechIds: additionalTechs.map(({ employee }) => ({
        value: employee.id,
        label: employee.name
      })),
      visitsToSchedule: visitTemplates.map(
        (
          {
            id: visitId,
            duration,
            startTimeMinutesSinceMidnight,
            numberOfTechs,
            primaryTech: visitPrimaryTech,
            additionalTechs: visitAdditionalTechs,
            forms
          },
          visitIndex
        ) => ({
          id: visitId,
          visitNumber: visitIndex + 1,
          duration,
          startTime: startTimeMinutesSinceMidnight,
          numberOfTechs,
          primaryTechnician: visitPrimaryTech?.id
            ? techOptions.find(t => t.value === visitPrimaryTech.id) ?? null
            : null,
          additionalTechnicians:
            visitAdditionalTechs
              ?.map(({ employee }) => techOptions.find(t => t.value === employee.id))
              ?.filter(Boolean) ?? [],
          forms
        })
      ),
      daysBeforeDueDate: visitTemplates.reduce(
        (min, { daysBeforeDueDate }) => Math.min(daysBeforeDueDate, min),
        Number.MAX_SAFE_INTEGER
      )
    }),
    [crews, techOptions]
  );

  const excludedMaintenanceColumns = useMemo(() => {
    return [
      { field: 'singleJobTaskTemplateName', headerName: 'Task', flex: 3 },
      {
        field: 'cumulativeLaborEstimate',
        headerName: 'Labor Estimate',
        flex: 1,
        renderCell: cell => getFormattedHoursString(cell.value)
      },
      { field: 'annualOccurrences', headerName: 'Annual Occurrences', flex: 1 },
      {
        field: 'visitsScheduled',
        headerName: 'Visits Scheduled',
        flex: 1,
        renderCell: cell => `${cell.value} visit(s)`
      },
      {
        field: 'id',
        renderCell: cell => (
          <IconButton
            style={{ padding: 0 }}
            onClick={() => {
              setSelectedSingleJobTaskTemplateId(cell.row.singleJobTaskTemplateId);

              const savedMaintenanceGroup = serviceAgreementPropertyMetadata?.advancedMaintenanceTemplateGroups?.find(
                g => g.singleJobTaskTemplateId === cell.row.singleJobTaskTemplateId
              );

              setSelectedAdvancedMaintenanceTemplateGroupId(savedMaintenanceGroup?.id);

              const savedValues = savedMaintenanceGroup
                ? getFormValuesFromMaintenanceGroup(savedMaintenanceGroup)
                : {};

              setModal(oldModal => ({
                ...oldModal,
                open: true,
                defaultValues: {
                  ...EDIT_MAINTENANCE_DEFAULT_VALUES,
                  ...serviceAgreementPropertyMetadataFormService.formikContext.values,
                  ...savedValues
                },
                excludedMaintenancesToGroup: maintenanceRows
                  .filter(r => r.singleJobTaskTemplateId === cell.row.singleJobTaskTemplateId)
                  .map(r => r.id)
              }));
            }}
          >
            <EditIcon style={{ fontSize: 16 }} />
          </IconButton>
        ),
        width: 32
      }
    ];
  }, [
    serviceAgreementPropertyMetadata?.advancedMaintenanceTemplateGroups,
    serviceAgreementPropertyMetadataFormService,
    getFormValuesFromMaintenanceGroup,
    maintenanceRows
  ]);

  const [
    upsertCustomerPropertyServiceAgreementMetadata,
    { loading: upsertingCustomerPropertyServiceAgreementMetadata }
  ] = useUpsertCustomerPropertyServiceAgreementMetadata({
    serviceAgreementId,
    onSuccess: () => {
      setTempFormChanges([]);
      refetchAdvancedSchedulingServiceAgreementCustomerPropertyMetadata();
    }
  });
  const handleSaveMetadata = data => {
    upsertCustomerPropertyServiceAgreementMetadata({
      id: serviceAgreementPropertyMetadata?.customerPropertyServiceAgreementsMetadata?.id,
      customerPropertyId: selectedPropertyId,
      departmentId: data.departmentId?.value || serviceAgreement?.departmentId,
      propertyRepId: data.propertyRepId?.value,
      primaryTechId: data.primaryTechId?.value,
      selectedCrew: data.crewId?.value,
      additionalTechIds: data.extraTechIds?.map(t => t.value) || []
    });
  };

  const [
    upsertAdvancedMaintenanceTemplateGroup,
    { loading: upsertingAdvancedMaintenanceTemplateGroup }
  ] = useUpsertAdvancedMaintenanceTemplateGroup({
    serviceAgreementId,
    onSuccess: () => {
      setModal(INITIAL_GROUP_MODAL_STATE);
      refetchAdvancedSchedulingServiceAgreementCustomerPropertyMetadata();
    }
  });

  const handleSaveMaintenanceGroup = useCallback(
    data => {
      const {
        id = selectedAdvancedMaintenanceTemplateGroupId,
        description,
        crewId,
        primaryTechId,
        maintenanceGroupName,
        maintenanceType,
        numberOfVisits,
        budgetedHours,
        extraTechIds,
        maintenanceTags,
        visitsToSchedule,
        visitFormsOptions,
        daysBeforeDueDate
      } = data;

      const savedMaintenancePreviewNames =
        serviceAgreementPropertyMetadata?.advancedMaintenanceTemplateGroups
          ?.find(g => g.id === id)
          ?.advancedMaintenanceTemplateGroupSchedules?.map(v => v.name) ?? [];

      upsertAdvancedMaintenanceTemplateGroup({
        id,
        customerPropertyId: selectedPropertyId,
        selectedCrew: crewId?.value,
        primaryTechId: primaryTechId?.value,
        name: maintenanceGroupName,
        description,
        jobTypeId: maintenanceType?.value,
        singleJobTaskTemplateId: selectedSingleJobTaskTemplateId,
        numberOfVisits: numberOfVisits || 0,
        budgetedHours: budgetedHours || 0,
        additionalTechIds: extraTechIds?.map(t => t.value) || [],
        jobTagIds: maintenanceTags?.map(t => t.value) || [],
        visitTemplates:
          visitsToSchedule?.map(v => ({
            id: undefined,
            primaryTechId: v.primaryTechnician?.value,
            startTimeMinutesSinceMidnight: v.startTime,
            daysBeforeDueDate: daysBeforeDueDate + v.visitNumber - 1,
            duration: v.duration,
            numberOfTechs: v.numberOfTechs || 0,
            additionalTechIds: v.additionalTechnicians?.map(t => t.value) || [],
            formIds: visitFormsOptions?.map(opt => opt.value) || []
          })) || [],
        advancedMaintenanceTemplateGroupSchedules: maintenancesForPreview
          .filter(
            m => !selectedSingleJobTaskTemplateId || !savedMaintenancePreviewNames.includes(m.name)
          )
          .map(m => {
            const [year, month, day] = moment(m.firstDueDate * 1000)
              .tz(companyTimezone)
              .format('YYYY:MM:DD')
              .split(':');
            return {
              name: m.maintenanceName,
              firstDueDate: m.firstDueDate,
              numberOfAssets: m.numberOfPropertyAssets,
              laborEstimate: m.cumulativeLaborEstimate,
              serviceAgreementId,
              customerPropertyId: selectedPropertyId,
              dayOfMonth: parseInt(day, 10),
              monthOfYear: parseInt(month, 10),
              year: parseInt(year, 10)
            };
          })
      });
    },
    [
      companyTimezone,
      maintenancesForPreview,
      maintenanceRows,
      selectedPropertyId,
      selectedSingleJobTaskTemplateId,
      selectedAdvancedMaintenanceTemplateGroupId,
      serviceAgreementId,
      serviceAgreementPropertyMetadata?.advancedMaintenanceTemplateGroups
    ]
  );

  useEffect(() => {
    setSaving(
      upsertingCustomerPropertyServiceAgreementMetadata ||
        loadingServiceAgreementPropertyMetadata ||
        upsertingAdvancedMaintenanceTemplateGroup ||
        deletingAdvancedMaintenanceTemplateGroupScheduleById
    );
  }, [
    upsertingCustomerPropertyServiceAgreementMetadata,
    loadingServiceAgreementPropertyMetadata,
    upsertingAdvancedMaintenanceTemplateGroup,
    deletingAdvancedMaintenanceTemplateGroupScheduleById
  ]);

  const loading = useMemo(
    () =>
      loadingServiceAgreement ||
      loadingMaintenanceJobPreview ||
      loadingDepartments ||
      loadingCrews ||
      loadingTechs ||
      loadingServiceAgreementPropertyMetadata ||
      !selectedPropertyId,
    [
      loadingServiceAgreement,
      loadingMaintenanceJobPreview,
      loadingDepartments,
      loadingCrews,
      loadingTechs,
      loadingServiceAgreementPropertyMetadata,
      selectedPropertyId
    ]
  );

  return (
    <ThemeProvider>
      <Box
        display="flex"
        left="10px"
        marginTop="-40px"
        minHeight="100vh"
        paddingBottom="171px"
        paddingRight={3}
        position="absolute"
        width="100%"
      >
        {/* Properties sidebar nav */}
        <Box
          borderRight={`1px solid ${theme.palette.grayscale(80)}`}
          display="flex"
          p={2}
          width={244}
        >
          <TreeView>
            {properties.map(property => (
              <TreeItem
                css={useStyles}
                key={property.id}
                label={styledLabel(property.propertyName, true)}
                nodeId={property.id}
                onClick={() => handleSelectProperty(property)}
              />
            ))}
          </TreeView>
        </Box>

        {/* Property header nav */}
        <Box display="flex" flexDirection="column" flexGrow={2} p={2}>
          <Box style={{ paddingBottom: 24 }}>
            <Typography variant={TV.L} weight={TW.BOLD}>
              {selectedProperty?.propertyName}
              <IconButton
                css={navButtonStyle}
                disabled={curIndex === 0}
                onClick={() => {
                  handleSelectProperty(properties[curIndex - 1]);
                }}
              >
                <ArrowBackIcon style={{ fontSize: 16 }} />
              </IconButton>
              <IconButton
                css={navButtonStyle}
                disabled={curIndex === properties?.length - 1}
                onClick={() => {
                  handleSelectProperty(properties[curIndex + 1]);
                }}
              >
                <ArrowForwardIcon style={{ fontSize: 16 }} />
              </IconButton>
            </Typography>
          </Box>

          {/* People section */}
          <Box>
            <Typography style={{ marginBottom: theme.spacing(1) }} variant={TV.B1} weight={TW.BOLD}>
              People
            </Typography>
            <Box>
              {loading ? (
                <Skeleton height={126} />
              ) : (
                <SgtForm
                  configuration={peopleLayout({
                    departments,
                    propReps,
                    crews,
                    techOptions
                  })}
                  initialValues={initialMetadata}
                  key="People"
                  layouts="edit"
                  loading={loading}
                  onCreateService={service =>
                    setServiceAgreementPropertyMetadataFormService(service)
                  }
                  onFieldChange={key => setTempFormChanges(prev => [...prev, key])}
                  onSubmit={handleSaveMetadata}
                />
              )}
            </Box>
          </Box>

          {/* Ungrouped maintenances section */}
          <Box style={{ marginTop: theme.spacing(3) }}>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: theme.spacing(1)
              }}
            >
              <Typography variant={TV.B1} weight={TW.BOLD}>
                Maintenances
              </Typography>
              <Button
                disabled={!modal.maintenancesToGroup.length}
                type="secondary"
                onClick={() => {
                  setSelectedSingleJobTaskTemplateId(null);
                  setSelectedAdvancedMaintenanceTemplateGroupId(undefined);
                  setModal(oldModal => ({
                    ...oldModal,
                    open: true,
                    defaultValues: {
                      ...EDIT_MAINTENANCE_DEFAULT_VALUES,
                      ...serviceAgreementPropertyMetadataFormService.formikContext.values
                    }
                  }));
                }}
              >
                Edit Maintenances
              </Button>
            </Box>
            <WrapTable
              columns={ungroupedMaintenanceColumns}
              loading={loading}
              noDataMessage="No Maintenances"
              rows={ungroupedMaintenanceRows}
            />
          </Box>

          {/* Grouped maintenances section */}
          {serviceAgreementPropertyMetadata?.advancedMaintenanceTemplateGroups
            ?.filter(
              g => !g.singleJobTaskTemplateId && g.advancedMaintenanceTemplateGroupSchedules.length
            )
            .map(g => (
              <Box key={g.id} style={{ marginTop: theme.spacing(7) }}>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: theme.spacing(1)
                  }}
                >
                  <Typography variant={TV.B2} weight={TW.BOLD}>
                    {g.name}
                  </Typography>
                  <Button
                    type="secondary"
                    onClick={() => {
                      setSelectedSingleJobTaskTemplateId(null);
                      setSelectedAdvancedMaintenanceTemplateGroupId(g.id);
                      setModal(oldModal => ({
                        ...oldModal,
                        open: true,
                        defaultValues: {
                          ...EDIT_MAINTENANCE_DEFAULT_VALUES,
                          ...serviceAgreementPropertyMetadataFormService.formikContext.values,
                          ...getFormValuesFromMaintenanceGroup(g)
                        }
                      }));
                    }}
                  >
                    Edit Maintenances
                  </Button>
                </Box>
                <WrapTable
                  columns={groupedMaintenanceColumns}
                  loading={loading}
                  noDataMessage="Something went wrong, please contact BuildOps Support"
                  rows={g.advancedMaintenanceTemplateGroupSchedules.map(m => ({
                    id: m.id,
                    maintenanceName: m.name,
                    firstDueDate: m.firstDueDate,
                    maintenanceType: g.jobType.tagName,
                    visitsScheduled: g.visitTemplates.length,
                    assets: m.numberOfAssets,
                    laborEstimate: m.laborEstimate,
                    budgetedHours: g.budgetedHours || 0,
                    primaryTechnician: g.primaryTech?.name,
                    additionalTechnicians: g.additionalTechs.map(t => t.employee.name).join(', ')
                  }))}
                />
              </Box>
            ))}

          {/* Excluded maintenances section */}
          <Box style={{ marginTop: theme.spacing(7) }}>
            <Typography style={{ marginBottom: theme.spacing(1) }} variant={TV.B1} weight={TW.BOLD}>
              Excluded Maintenances
            </Typography>
            <WrapTable
              columns={excludedMaintenanceColumns}
              loading={loading}
              noDataMessage="No Maintenances"
              rows={excludedMaintenanceRows}
            />
          </Box>
        </Box>
      </Box>
      <Modal
        open={tasksPreviewModal.open}
        title="Maintenance Tasks"
        onClose={() => setTasksPreviewModal({ open: false })}
      >
        {tasksPreviewModal.tasks?.map(t => (
          <div key={t.id}>{t.name}</div>
        ))}
      </Modal>

      {/* Maintenance group modal */}
      <Modal
        actions={
          <Button
            disabled={upsertingAdvancedMaintenanceTemplateGroup}
            fullWidth
            type={ButtonType.PRIMARY}
            onClick={() => maintenanceGroupModalFormService.submit()}
          >
            Save
          </Button>
        }
        css={{ width: 1200 }}
        maxWidth="xl"
        open={modal.open}
        style={{ width: 1200 }}
        title="Edit Maintenances"
        onClose={() => {
          setSelectedSingleJobTaskTemplateId(null);
          setSelectedAdvancedMaintenanceTemplateGroupId(undefined);
          setModal(oldModal => ({
            ...oldModal,
            open: false,
            excludedMaintenancesToGroup: []
          }));
        }}
      >
        <Typography variant={TV.S3} weight={TW.MEDIUM}>
          SELECTED MAINTENANCES
        </Typography>
        <Box
          style={{
            display: 'flex',
            textAlign: 'left',
            gap: 5,
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          {maintenancesForPreview.map(m => (
            <Box
              key={m.id}
              style={{
                display: 'inline-flex',
                justifyContent: 'space-between',
                maxWidth: 230
              }}
            >
              <Typography variant={TV.BASE} weight={TW.MEDIUM}>
                {getFormattedDateString(m.firstDueDate, companyTimezone)}
              </Typography>
              <Typography
                css={t => ({
                  color: t.palette.text.secondary
                })}
                variant={TV.BASE}
                weight={TW.MEDIUM}
              >
                {getFormattedHoursString(m.cumulativeLaborEstimate)} estimated
              </Typography>
            </Box>
          ))}
        </Box>

        <SgtForm
          configuration={maintenanceGroupLayout({
            maintenanceGroupNames,
            maintenanceTagOptions,
            maintenanceTypeOptions,
            crewOptions: crews,
            techOptions
          })}
          customComponents={{ VisitScheduler }}
          initialValues={modal.defaultValues}
          onCreateService={service => setMaintenanceGroupModalFormService(service)}
          onSubmit={handleSaveMaintenanceGroup}
        />
      </Modal>
    </ThemeProvider>
  );
};

ScheduleMaintenances.propTypes = {
  serviceAgreementId: PropTypes.string.isRequired,
  triggerSave: PropTypes.bool.isRequired,
  setSaving: PropTypes.func.isRequired
};

ScheduleMaintenances.defaultProps = {};

export default ScheduleMaintenances;
