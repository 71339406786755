import { useEffect, useState } from 'react';

import { CommonService } from 'services/core';
import { Logger } from 'services/Logger';
import { FormEntityType } from 'utils/AppConstants';

const useAvailableForms = user => {
  const [forms, setForms] = useState([]);
  useEffect(() => {
    (async () => {
      const commonService = new CommonService();
      try {
        const visitFormsQueryResult = await commonService.getFormsAvailableToEntity(
          user.tenantId,
          `${user.tenantId}_Company_${user.tenantCompanyId}`,
          FormEntityType.VISIT
        );

        const result = (visitFormsQueryResult?.data?.getCompany?.forms?.items || []).filter(
          form => form.latestPublishedFormDefinition
        );
        setForms(result);
      } catch (error) {
        Logger.warn(`Error fetching forms for user id ${user?.id}: ${error.message}`);
      }
    })();
  }, [user]);
  return forms;
};

export default useAvailableForms;
