import React, { useEffect, useMemo } from 'react';

import {
  theme as bhtheme,
  Button,
  MultiSelect,
  ThemeProvider,
  Typography
} from '@BuildHero/sergeant';
import { jsx } from '@emotion/react';
import { Box, IconButton } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useSelector } from 'react-redux';

import NeedsReview from 'assets/Icons/NeedsReview.svg';
import Reviewed from 'assets/Icons/Reviewed.svg';
import { StatusChip } from 'components';
import StorageService from 'services/StorageService';
import { TechReportStatus, VISIT_REVIEW_STATUS } from 'utils/AppConstants';

import { MODES } from '..';
import { useGenerateTechReport } from '../../mutations/useGenerateTechReport';
import { useReviewJobCloseoutReport } from '../../mutations/useReviewJobCloseoutReport';
import { mergeVisitArraysById } from '../../utils';

import { useLazyVisitDetails } from './useLazyVisitDetails';

const allVisitsStyle = theme => {
  return {
    borderRadius: 16,
    borderStyle: 'solid',
    borderWidth: 1,
    height: 32,
    cursor: 'pointer',
    marginLeft: 8
  };
};

const downloadTechReport = async fileKey => {
  const storageService = new StorageService();
  const url = await storageService.getFileUrl(fileKey);
  window.open(url);
};

const ActionBar = ({
  visits,
  setSpecificVisits,
  allVisits,
  mode,
  jobId,
  refetchJob,
  isLoading
}) => {
  if (isLoading) {
    return (
      <ThemeProvider>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div css={{ width: 650, display: 'flex', justifyContent: 'space-between' }}>
            <div css={{ width: 300 }}>
              <MultiSelect disabled />
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }

  const [getVisitStatus, { data: updatedVisit, loading: refreshLoading }] = useLazyVisitDetails();

  // will be fired only when tech report is processing and refresh button is shown
  useEffect(() => {
    if (mode === MODES.VIEW_SINGLE_VISIT && updatedVisit && visits[0]?.id === updatedVisit?.id) {
      setSpecificVisits([{ ...visits[0], ...updatedVisit }]);
    }
  }, [updatedVisit, mode]);

  const [reviewVisit, { loading: reviewingVisit }] = useReviewJobCloseoutReport();

  const [generateReport, { loading: generatingReport }] = useGenerateTechReport();

  const visitOptions = useMemo(
    () =>
      allVisits
        .map(v => ({
          id: v.id,
          label: `Visit ${v.visitNumber}`,
          value: v.id,
          group: v.reviewStatus,
          visitNumber: v.visitNumber
        }))
        .sort((a, b) => a.visitNumber - b.visitNumber),
    [allVisits]
  );

  const unreviewedVisitIds = useMemo(
    () => visitOptions.filter(o => o.group === VISIT_REVIEW_STATUS.UNREVIEWED).map(o => o.id),
    [visitOptions]
  );

  const reviewedVisitIds = useMemo(
    () => visitOptions.filter(o => o.group === VISIT_REVIEW_STATUS.REVIEWED).map(o => o.id),
    [visitOptions]
  );

  const selectedVisits = useMemo(() => {
    const visitIds = visits.map(v => v.id);
    return visitOptions.filter(o => visitIds.includes(o.value));
  }, [visits, visitOptions]);

  const allVisitsSelected = useMemo(() => selectedVisits.length === visitOptions.length, [
    selectedVisits,
    visitOptions
  ]);

  const onlyUnreviewedVisitsSelected = useMemo(
    () =>
      unreviewedVisitIds.length &&
      selectedVisits.length === unreviewedVisitIds.length &&
      selectedVisits.every(v => v.group === VISIT_REVIEW_STATUS.UNREVIEWED),
    [unreviewedVisitIds, selectedVisits]
  );

  const onlyReviewedVisitsSelected = useMemo(
    () =>
      reviewedVisitIds.length &&
      selectedVisits.length === reviewedVisitIds.length &&
      selectedVisits.every(v => v.group === VISIT_REVIEW_STATUS.REVIEWED),
    [reviewedVisitIds, selectedVisits]
  );

  const showOriginal = useMemo(
    () =>
      mode === MODES.VIEW_SINGLE_VISIT &&
      ![TechReportStatus.ERROR, TechReportStatus.PROCESSING].includes(visits[0]?.techReport)
        ? visits[0].techReport
        : null,
    [mode, visits]
  );

  const visitsMissingTechReport = useMemo(() => visits.some(v => !v.techReport), [visits]);

  const visitsHaveTechReport = useMemo(() => visits.some(v => v.techReport), [visits]);

  const handleEdit = async () => {
    if (mode === MODES.VIEW_SINGLE_VISIT) {
      const updatedResponse = await generateReport(visits[0]?.id);
      const visitToUpdate = updatedResponse?.data?.generateTechReport;
      const mergedVisits = mergeVisitArraysById(visits, [visitToUpdate]);
      setSpecificVisits(mergedVisits);
      return;
    }
    const promises = visits.map(v => {
      if (!v.techReport) {
        return generateReport(v.id);
      }
      return false;
    });
    const responses = await Promise.all(promises);
    const updatedVisits = responses.filter(Boolean);
    const mergedVisits = mergeVisitArraysById(
      visits,
      updatedVisits.map(res => res?.data?.generateTechReport)
    );
    setSpecificVisits(mergedVisits);
  };

  return (
    <ThemeProvider>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <div css={{ width: 650, display: 'flex', justifyContent: 'space-between' }}>
          <div css={{ width: 300 }}>
            <MultiSelect
              grouped // change key in order to reflect grouping changes in visitOptions when a visit is reviewed
              key={reviewedVisitIds.toString()}
              options={visitOptions}
              selectedOptions={selectedVisits}
              showChips
              onChange={newOptions => {
                if (newOptions.length === 0) {
                  // prevent selection from being empty
                  setSpecificVisits(currentlySelectedVisits => [...currentlySelectedVisits]);
                  return;
                }
                const newOptionIds = newOptions.map(o => o.id);
                setSpecificVisits(allVisits.filter(v => newOptionIds.includes(v.id)));
              }}
            />
            {visitsMissingTechReport &&
              mode === MODES.VIEW_SINGLE_VISIT &&
              'This visit can only be edited on Mobile'}
            {visitsMissingTechReport &&
              mode === MODES.VIEW_MULTIPLE_VISITS &&
              'These visits can only be edited on Mobile'}
            {visitsHaveTechReport &&
              mode === MODES.VIEW_SINGLE_VISIT &&
              'This visit can only be edited on web'}
            {!visitsMissingTechReport &&
              visitsHaveTechReport &&
              mode === MODES.VIEW_MULTIPLE_VISITS &&
              'These visits can only be edited on web'}
          </div>
          {allVisitsSelected ? (
            <StatusChip
              backgroundColor={bhtheme.palette.support.blue.light}
              css={theme => ({
                ...allVisitsStyle(theme),
                borderColor: bhtheme.palette.support.blue.dark
              })}
              key="AllVisitsSelected"
              label="All Visits"
              showIcon
              textColor={bhtheme.palette.support.blue.dark}
            />
          ) : (
            <StatusChip
              backgroundColor={bhtheme.palette.leading.main}
              css={theme => ({
                ...allVisitsStyle(theme),
                borderColor: theme.palette.leading.contrastText
              })}
              key="AllVisitsUnSelected"
              label="All Visits"
              showIcon
              textColor={bhtheme.palette.leading.contrastText}
              onClick={() => setSpecificVisits(allVisits)}
            />
          )}
          {onlyUnreviewedVisitsSelected ? (
            <StatusChip
              backgroundColor={bhtheme.palette.support.blue.light}
              css={theme => ({
                ...allVisitsStyle(theme),
                borderColor: theme.palette.support.blue.dark
              })}
              icon={
                <img
                  alt="AllUnreviewed"
                  css={{
                    height: 20,
                    filter:
                      // see https://codepen.io/sosuke/pen/Pjoqqp for calculating colors for svgs that start as black
                      'invert(13%) sepia(48%) saturate(6163%) hue-rotate(219deg) brightness(89%) contrast(102%)'
                  }}
                  src={NeedsReview}
                />
              }
              key="OnlyUnreviewedSelected"
              label="All Unreviewed"
              showIcon
              textColor={bhtheme.palette.support.blue.dark}
            />
          ) : (
            <StatusChip
              backgroundColor={bhtheme.palette.leading.main}
              css={allVisitsStyle}
              icon={
                <img
                  alt="AllUnreviewed"
                  css={{
                    height: 20,
                    filter:
                      // see https://codepen.io/sosuke/pen/Pjoqqp for calculating colors for svgs that start as black
                      'invert(53%) sepia(92%) saturate(442%) hue-rotate(13deg) brightness(92%) contrast(101%)'
                  }}
                  src={NeedsReview}
                />
              }
              key="OnlyUnreviewedUnSelected"
              label="All Unreviewed"
              showIcon
              textColor={bhtheme.palette.leading.contrastText}
              onClick={() => {
                const newVisits = allVisits.filter(
                  v => v.reviewStatus === VISIT_REVIEW_STATUS.UNREVIEWED
                );
                if (newVisits.length === 0) return;
                setSpecificVisits(newVisits);
              }}
            />
          )}
          {onlyReviewedVisitsSelected ? (
            <StatusChip
              backgroundColor={bhtheme.palette.support.blue.light}
              css={theme => ({
                ...allVisitsStyle(theme),
                borderColor: theme.palette.support.blue.dark
              })}
              icon={
                <img
                  alt="AllReviewed"
                  css={{
                    height: 20,
                    filter:
                      // see https://codepen.io/sosuke/pen/Pjoqqp for calculating colors for svgs that start as black
                      'invert(13%) sepia(48%) saturate(6163%) hue-rotate(219deg) brightness(89%) contrast(102%)'
                  }}
                  src={Reviewed}
                />
              }
              key="OnlyReviewedUnSelected"
              label="All Reviewed"
              showIcon
              textColor={bhtheme.palette.support.blue.dark}
            />
          ) : (
            <StatusChip
              backgroundColor={bhtheme.palette.leading.main}
              css={allVisitsStyle}
              icon={
                <img
                  alt="AllReviewed"
                  css={{
                    height: 20,
                    filter:
                      // see https://codepen.io/sosuke/pen/Pjoqqp for calculating colors for svgs that start as black
                      'invert(33%) sepia(14%) saturate(7004%) hue-rotate(129deg) brightness(94%) contrast(101%)'
                  }}
                  src={Reviewed}
                />
              }
              key="OnlyReviewedSelected"
              label="All Reviewed"
              showIcon
              textColor={bhtheme.palette.leading.contrastText}
              onClick={() => {
                const newVisits = allVisits.filter(
                  v => v.reviewStatus === VISIT_REVIEW_STATUS.REVIEWED
                );
                if (newVisits.length === 0) return;

                setSpecificVisits(newVisits);
              }}
            />
          )}
        </div>
        <Box alignItems="center" display="flex" flexDirection="row">
          {showOriginal && (
            <Typography
              css={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => downloadTechReport(visits[0].techReport)}
            >
              View Original
            </Typography>
          )}
          {mode === MODES.VIEW_SINGLE_VISIT &&
            visits[0]?.techReport === TechReportStatus.PROCESSING && (
              <>
                <Typography>Processing...</Typography>
                <IconButton
                  aria-label="refresh"
                  css={theme => ({
                    marginLeft: 4,
                    color: refreshLoading ? theme.palette.support.grey.light : ''
                  })}
                  disabled={refreshLoading}
                  onClick={async () => getVisitStatus(visits[0]?.id)}
                >
                  <RefreshIcon fontSize="small" />
                </IconButton>
              </>
            )}

          {/** TODO hook the edit button to tech report pdf generation */}
          {visitsMissingTechReport && (
            <Button
              css={{ marginLeft: 8 }}
              loading={generatingReport}
              size="small"
              type="tertiary"
              onClick={handleEdit}
            >
              Edit {mode === MODES.VIEW_MULTIPLE_VISITS && 'All'}
            </Button>
          )}
          {visits.find(v => v.reviewStatus === VISIT_REVIEW_STATUS.UNREVIEWED) && (
            <Button
              css={{ marginLeft: 8 }}
              loading={reviewingVisit}
              size="small"
              type="primary"
              onClick={async () => {
                await reviewVisit({
                  visitIds: visits
                    .filter(v => v.reviewStatus === VISIT_REVIEW_STATUS.UNREVIEWED)
                    .map(v => v.id),
                  jobId
                });
                await refetchJob();
              }}
            >
              Review {mode === MODES.VIEW_MULTIPLE_VISITS && 'All'}
            </Button>
          )}
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default ActionBar;
