/* eslint-disable camelcase */
/* global google */
import React from 'react';

// import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PlacesAutocomplete from 'react-places-autocomplete';

import Context from 'components/Context';
import { getTenantSettingValueForKey } from 'utils';
import { GoogleMapsComponentType } from 'utils/AppConstants';

import styles from './styles';

const activeItemStyle = {
  backgroundColor: '#f2f2f2',
  cursor: 'pointer',
  color: '#3f3f3f',
  fontFamily: ['Inter', 'sans-serif'].join(','),
  fontSize: 14,
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.29,
  letterSpacing: 'normal',
  padding: 5
};

const itemStyle = {
  backgroundColor: '#ffffff',
  cursor: 'pointer',
  color: 'black',
  fontFamily: ['Inter', 'sans-serif'].join(','),
  fontSize: 14,
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.29,
  letterSpacing: 'normal',
  padding: 5
};

const createPlaceRequest = function(placeId) {
  const placeRequest = new Promise((resolve, reject) => {
    new google.maps.places.PlacesService(document.createElement('div')).getDetails(
      {
        placeId,
        fields: ['address_components', 'types', 'geometry']
      },
      (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(place);
        } else {
          reject();
        }
      }
    );
  });
  return placeRequest;
};

const getPlaceDetailFromId = function(placeId) {
  const placeRequest = createPlaceRequest(placeId);
  return placeRequest.then(placeResult => {
    const { address_components: addressComponents, geometry } = placeResult;
    const placeDetail = addressComponents.reduce((accumulator, addressComponent) => {
      const { types, short_name, long_name } = addressComponent;
      if (!types) return accumulator;
      for (const type of types) {
        accumulator[type] = {
          shortName: short_name,
          longName: long_name
        };
      }
      return accumulator;
    }, {});
    placeDetail.latitude = geometry.location.lat();
    placeDetail.longitude = geometry.location.lng();
    return placeDetail;
  });
};

export class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = { address: this.props.field?.value, popper: false, anchorEl: null };
  }

  componentDidUpdate(prevProps) {
    if (this.props.field?.value !== prevProps.field?.value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ address: this.props.field?.value });
    }
  }

  handleChange = address => {
    let useParentValue = false;
    const { form, specialbehaviour = {} } = this.props;
    const { dependentField, hideFieldOnCondition } = specialbehaviour;
    if (
      !hideFieldOnCondition &&
      dependentField &&
      form &&
      form.values &&
      form.values[dependentField] === 'true'
    ) {
      useParentValue = true;
    }
    if (!useParentValue) {
      this.setState(prevState => ({
        ...prevState,
        address,
        popper: true,
        anchorEl: this.myRef.current
      }));
    }

    if (address === '') {
      this.setState({ address });
    }
    if (form && this.props.field.name) {
      this.props.form.setFieldValue(this.props.field.name, address, false);
    }
  };

  handleError = () => {
    if (this.props.form && this.props.field.name) {
      this.props.form.setFieldValue(this.props.field.name, this.state.address, false);
    }
  };

  prepareForCustomHandleSelect = (address, placeId) => {
    return getPlaceDetailFromId(placeId);
  };

  setFormValuesFromSelectedAddress = (address, placeDetail) => {
    const { addressLine1, addressLine2, state, city, zipcode, latitude, longitude } =
      this.props.specialbehaviour ?? {};
    let addressLine1Value = '';
    let addressLine2Value = '';
    let cityValue = '';
    let zipCodeValue = '';
    let stateValue = '';
    let latitudeVal = '';
    let longitudeVal = '';
    let countryVal = '';
    let countyVal = '';

    const { values, setValues } = this.props.form;
    const { address_components, geometry } = placeDetail;
    latitudeVal = geometry.location.lat();
    longitudeVal = geometry.location.lng();
    address_components.forEach(addressComp => {
      const { types, short_name, long_name } = addressComp;
      if (types && types.length > 0) {
        if (types.includes(GoogleMapsComponentType.STATE)) {
          stateValue = short_name;
        }

        if (types.includes(GoogleMapsComponentType.ZIPCODE)) {
          zipCodeValue = short_name;
        }

        if (types.includes(GoogleMapsComponentType.COUNTRY)) {
          countryVal = long_name;
        }

        if (types.includes(GoogleMapsComponentType.COUNTY)) {
          countyVal = long_name;
        }
      }
    });
    const placeName = address
      ? address
          .replace(`, ${stateValue}`, '')
          .replace(`, ${zipCodeValue}`, '')
          .replace(', USA', '') // for us address, the long name for country is US, but the address sugesstions have USA :-(
          .replace(`, ${countryVal}`, '')
          .split(',')
      : '';
    cityValue = placeName && placeName.length > 0 && placeName[placeName.length - 1];
    addressLine2Value = placeName && placeName.length > 2 && placeName[placeName.length - 2];
    addressLine1Value = placeName && placeName.length > 0 && placeName[0];

    const modifiedValues = {
      ...values,
      [addressLine1]: addressLine1Value || '',
      [addressLine2]: addressLine2Value || '',
      [state]: stateValue,
      [city]: cityValue,
      [zipcode]: zipCodeValue,
      country: countryVal
    };

    if (latitude && longitude) {
      modifiedValues[latitude] = latitudeVal;
      modifiedValues[longitude] = longitudeVal;
    }
    setValues(modifiedValues);

    const { specialbehaviour = {} } = this.props;
    const isAutoassignTaxRateDisabled =
      getTenantSettingValueForKey('isAutoassignTaxRateDisabled') === 'true';
    if (
      !isAutoassignTaxRateDisabled &&
      specialbehaviour.regionField &&
      stateValue &&
      countyVal &&
      cityValue
    ) {
      let regions = specialbehaviour?.queryResult?.[specialbehaviour?.regionOptionsPath] ?? [];
      // trim whitespace generated from string processing and bucketing
      regions = regions.map(region => ({
        ...region,
        state: region?.state?.trim().toLowerCase(),
        county: region?.county?.trim().toLowerCase(),
        city: region?.city?.trim().toLowerCase()
      }));

      const stateFromAddress = stateValue.trim().toLowerCase();
      const countyFromAddress = countyVal.trim().toLowerCase();
      const cityFromAddress = cityValue.trim().toLowerCase();

      // get best match. Priority: city, county, state
      // will never match a tax rate where city, county, and state are falsy
      let match = null;
      regions.some(candidate => {
        // throw away any non-matches
        if (
          (candidate.state && candidate.state !== stateFromAddress) ||
          (candidate.county && candidate.county !== countyFromAddress) ||
          (candidate.city && candidate.city !== cityFromAddress)
        ) {
          return false;
        }

        // this assumes that if candidate.city is defined, then it also has
        // state & county defined.
        if (
          candidate.city ||
          (candidate.county && !match?.county) ||
          (candidate.state && !match?.state)
        ) {
          match = candidate;
        }
        return candidate.city;
      });

      if (match) {
        this.props.form.setFieldValue(
          specialbehaviour.regionField,
          match?.[specialbehaviour.regionOptionField],
          false
        );
      }
    }

    this.setState(prevState => ({
      ...prevState,
      address:
        addressLine1Value ||
        (address &&
          address
            .replace(`, ${stateValue}`, '')
            .replace(`, ${cityValue}`, '')
            .replace(`, ${zipCodeValue}`, '')
            .replace(', USA', '')),
      popper: false
    }));
  };

  handleSelect = (address, placeId) => {
    if (!this.props.form) return;
    const placeRequest = createPlaceRequest(placeId);

    placeRequest.then(placeDetail => this.setFormValuesFromSelectedAddress(address, placeDetail));
  };

  handleClose = () => {
    this.setState(prevState => ({ ...prevState, anchorEl: null }));
  };

  getSearchOptions = () => {
    let countryArr;
    if (Context.getCompanyContext() && Context.getCompanyContext().getCompany) {
      const { listTenantSettings } = Context.getCompanyContext();
      const addressSetting =
        listTenantSettings &&
        listTenantSettings.find(
          setting => setting.settingKey === 'countrySetting' && setting.settingValue
        );
      if (addressSetting) {
        const addressSettingValue = addressSetting.settingValue;
        const countryCode = addressSettingValue && addressSettingValue.replace('us').split(',');
        countryArr = [];
        (countryCode || []).forEach(code => countryArr.push(code));
      }
    }

    const searchOptions = {
      componentRestrictions: {
        country: countryArr || ['us']
      }
    };

    return searchOptions;
  };

  render() {
    const { field, form, specialbehaviour = {}, user, theme, value, ...rest } = this.props;
    const errorText = (form && field && form.errors && form.errors[field.name]) || '';
    const { dependentField, hideFieldOnCondition } = specialbehaviour;

    if (
      hideFieldOnCondition &&
      dependentField &&
      ((form && form.values[dependentField] === '') || form.values[dependentField] === 'false')
    ) {
      return <></>;
    }

    let useParentValue = false;
    if (
      !hideFieldOnCondition &&
      dependentField &&
      form &&
      form.values[dependentField] &&
      form.values[dependentField] === 'true'
    ) {
      useParentValue = true;
    }
    return (
      <PlacesAutocomplete
        searchOptions={{ ...this.props.searchOptions, ...this.getSearchOptions() }}
        shouldFetchSuggestions={!useParentValue}
        value={this.props.value || (useParentValue && field?.value) || this.state.address}
        onChange={event => {
          this.handleChange(event);
          if (this.props.onChange) this.props.onChange(event);
        }}
        onError={this.handleError}
        onFocus={this.handleClick}
        onSelect={
          this.props.onSelect
            ? (address, placeId) => {
                getPlaceDetailFromId(placeId).then(placeDetail => {
                  this.props.onSelect(placeDetail);
                });
              }
            : this.handleSelect
        }
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div id="parent">
            <TextField
              autoComplete="off"
              error={errorText !== ''}
              fullWidth
              helperText={errorText}
              name={field?.name}
              variant="filled"
              {...rest}
              inputProps={getInputProps({
                className: 'location-search-input',
                ref: this.myRef,
                // chrome disrespects autocomplete off so have to use this hack
                autoComplete: 'user-password'
              })}
            />
            <div />
            <Popper
              anchorEl={this.state.anchorEl}
              disablePortal
              id={`${field?.name}Popper`}
              modifiers={{
                preventOverflow: {
                  enabled: true,
                  boundariesElement: 'window'
                },
                flip: {
                  enabled: false
                }
              }}
              open={suggestions && suggestions.length > 0}
              placement="bottom-start"
              style={{ zIndex: 5 }}
              transition
            >
              <Paper
                style={
                  (suggestions && suggestions.length > 0) || loading
                    ? { padding: 10, zIndex: 'auto' }
                    : null
                }
              >
                {loading && <div style={itemStyle}>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active ? activeItemStyle : itemStyle;
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
                {suggestions && suggestions.length > 0 && (
                  <div id="google-logo" style={{ textAlign: 'right', padding: '1px' }}>
                    <img
                      alt="Powered by Google"
                      src="https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png"
                    />
                  </div>
                )}
              </Paper>
            </Popper>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LocationSearchInput);
