import gql from 'graphql-tag';
import moment from 'moment';

import { updateWatchedNonVisitQueries } from '@dispatch/Dispatch.utils';
import { nonVisitDetailsFragment } from '@dispatch/fragments';
import useExtendedMutation from 'customHooks/useExtendedMutation';
import { getState } from 'redux/store';

// TODO: rename, this is used for non-billable events as well
const UPDATE_BILLABLE_NON_VISIT_MUTATION = gql`
  mutation updateNonVisitEvent($partitionKey: String, $data: UpdateNonVisitEventInput!) {
    updateNonVisitEvent(partitionKey: $partitionKey, data: $data) {
      ${nonVisitDetailsFragment}
    }
  }
`;

const serializer = ({
  eventId,
  data: {
    eventName,
    description,
    from,
    to,
    version,
    departmentId,
    payrollHourTypeId,
    employeeId,
    status
  }
}) => ({
  variables: {
    partitionKey: getState().user.tenantId,
    data: {
      id: eventId,
      name: eventName,
      description,
      departmentId,
      employeeId,
      payrollHourTypeId,
      plannedStartTimeUTC: from,
      plannedEndTimeUTC: to,
      version,
      status
    }
  }
});

const optimisticResponseFactory = ({
  eventId,
  originalEvent,
  data: { eventName, description, from, to, version, departmentId, payrollHourTypeId, employeeId }
}) => ({
  updateNonVisitEvent: {
    ...originalEvent,
    id: eventId,
    employeeId,
    departmentId,
    name: eventName,
    description,
    payrollHourTypeId,
    plannedEndTimeUTC: to,
    plannedStartTimeUTC: from,
    version
  }
});

const CONFIRMATION_TEXT =
  'If any technicians working on this event ' +
  'are offline and have already started working on this event, ' +
  'any information that has not been uploaded from their mobile app ' +
  'may be lost. Are you sure you want to continue?';

const useUpdateBillableNonVisit = () => {
  return useExtendedMutation(UPDATE_BILLABLE_NON_VISIT_MUTATION, {
    serializer,
    optimisticResponseFactory,
    showConfirmation: ({ data }) => {
      const isFutureEvent = moment.unix(data.from).isAfter(moment().endOf('day'));
      return !isFutureEvent;
    },
    confirmationModalOptions: {
      title: 'Reschedule Event',
      warn: true,
      modalText: CONFIRMATION_TEXT
    },
    update: (cache, { data }) => {
      const updatedNonVisit = data.updateNonVisitEvent;
      updateWatchedNonVisitQueries(cache, updatedNonVisit);
    }
  });
};

export default useUpdateBillableNonVisit;
