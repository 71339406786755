import { isEmpty, sortBy } from 'lodash';

import Context from 'components/Context';
import { getLabourTypes } from 'scenes/Settings/Payroll/helpers';
import { Logger } from 'services/Logger';
import { getTenantSettingValueForKey, round } from 'utils';
import { QuoteStatus } from 'utils/AppConstants';

import FeatureFlags from 'utils/FeatureFlagConstants';
import { SettingConstants } from '../constants';
import QuotesUtils from '../index.utils';
import entityMapAttributes from '../meta/entityMapAttributes';

const formatQuotePurchaseOrders = data => {
  const { quotePurchaseOrders, versionNumber, opportunity } = data;
  const versionedQuotePurchaseOrders =
    opportunity?.versionedQuotes?.items?.reduce((acc, curr) => {
      const currentQuotePurchaseOrders = curr.quotePurchaseOrders.items.map(item => ({
        poId: item.purchaseOrder.id,
        poNumber: item.purchaseOrder.poNumber,
        poStatus: item.purchaseOrder.status,
        quoteVersionNumber: curr.versionNumber
      }));
      return [...acc, ...currentQuotePurchaseOrders];
    }, []) || [];
  const primaryQuotePurchaseOrders =
    quotePurchaseOrders?.items?.map(item => ({
      poId: item.purchaseOrder.id,
      poNumber: item.purchaseOrder.poNumber,
      poStatus: item.purchaseOrder.status,
      quoteVersionNumber: versionNumber
    })) || [];
  return [...primaryQuotePurchaseOrders, ...versionedQuotePurchaseOrders];
};

export const processQuoteResponse = quoteResponse => {
  const { opportunity, attachments, formData, ...quoteInfo } = quoteResponse || {};
  const taskAttachments = QuotesUtils.composeTaskAttachments(quoteInfo?.quoteLineTasks);
  const allAttachments = { items: [...(attachments?.items || []), ...(taskAttachments || [])] };
  const composedAttachments = QuotesUtils.composeQuoteAttachments(allAttachments);

  return {
    quoteInfo,
    property: opportunity?.property || {},
    customer: opportunity?.property?.customer || {},
    quoteAttachments: composedAttachments,
    customFormdata: formData,
    quotePurchaseOrders: formatQuotePurchaseOrders(quoteResponse)
  };
};

export const getFormattedDataForInfo = data => {
  if (data) {
    const formattedData = {
      id: data.id || '',
      name: data.name || '',
      versionLabel: data.versionLabel || '',
      ownerId: data.ownerId || '',
      salesById: data.salesById || '',
      totalAmountQuoted: data.totalAmountQuoted,
      totalAmountQuotedOverride:
        (data.totalAmountQuotedOverride
          ? data.totalAmountQuotedOverride
          : data.totalAmountQuoted) || '',
      customerPoNumber: data.customerPoNumber || '',
      departmentId: data.departmentId || ''
    };
    if (formattedData.totalAmountQuotedOverride) {
      formattedData.totalAmountQuotedOverride = parseFloat(
        formattedData.totalAmountQuotedOverride.toFixed(2)
      );
    }
    return formattedData;
  }
  return {};
};

export const constructSelectSettings = data => {
  return data?.map(item => {
    const parsed = JSON.parse(item.settings);
    parsed[SettingConstants.QUOTE_PRESETS] = {
      label: item.displayName,
      value: item.id
    };
    return parsed;
  });
};

export const overridesAreEnabled = config =>
  config?.[SettingConstants.QUOTE_MODE]?.[SettingConstants.ENABLE_OVERRIDES];

export default processQuoteResponse;

const getButtonMeta = label => ({ label });

const cancelQuotePrompt = (setConfirmData, cancelQuote) => {
  setConfirmData({
    confirmDialog: true,
    confirmAction: () => cancelQuote(setConfirmData),
    confirmMessage: 'Are you sure you want to cancel the quote?',
    handleCancelConfirmation: () =>
      setConfirmData({ confirmDialog: false, confirmMessage: '', confirmAction: '' })
  });
};

export const getButtonsForQuote = ({
  cancelQuote,
  cloneQuote,
  updateQuoteStatusMutation,
  handleActionIfDeactivated,
  quoteInfo,
  reopenQuote,
  setConfirmData,
  setOpenReasonsModal,
  setQuoteStatus,
  dissociateJob,
  flags
}) => {
  const buttons = {};

  // Need to change this way of code
  if (!isEmpty(quoteInfo)) {
    if (
      [
        QuoteStatus.DRAFT,
        QuoteStatus.EMAIL_READ,
        QuoteStatus.EMAIL_BOUNCED,
        QuoteStatus.CUSTOMER_VIEWED
      ].includes(quoteInfo.status)
    ) {
      buttons.approve = getButtonMeta('Approve quote');
      buttons.approve.action = () => {
        setOpenReasonsModal(true);
        setQuoteStatus('Approve');
      };
      buttons.reject = getButtonMeta('Reject quote');
      buttons.reject.action = () => {
        setOpenReasonsModal(true);
        setQuoteStatus('Reject');
      };
      buttons.cancel = getButtonMeta('Cancel quote');
      buttons.cancel.action = () => cancelQuotePrompt(setConfirmData, cancelQuote);
      buttons.clone = getButtonMeta('Clone quote');
      buttons.clone.action = () => cloneQuote('clone');
      buttons.markAsSent = getButtonMeta('Mark as Sent');
      buttons.markAsSent.action = () => {
        updateQuoteStatusMutation(QuoteStatus.SENT_TO_CUSTOMER);
        setQuoteStatus('SentToCustomer');
      };
    } else if ([QuoteStatus.JOB_ADDED, QuoteStatus.DISCARDED].includes(quoteInfo.status)) {
      buttons.clone = getButtonMeta('Clone quote');
      buttons.clone.action = () => handleActionIfDeactivated(cloneQuote, 'cloning', 'clone');
    } else if ([QuoteStatus.REJECTED, QuoteStatus.APPROVED].includes(quoteInfo.status)) {
      buttons.cancel = getButtonMeta('Cancel quote');
      buttons.cancel.action = () => cancelQuotePrompt(setConfirmData, cancelQuote);
      buttons.clone = getButtonMeta('Clone quote');
      buttons.clone.action = () => cloneQuote('clone');
    } else if (quoteInfo.status === QuoteStatus.CANCELLED) {
      buttons.clone = getButtonMeta('Clone quote');
      buttons.clone.action = () => handleActionIfDeactivated(cloneQuote, 'cloning', 'clone');
      buttons.reopen = getButtonMeta('Reopen quote');
      buttons.reopen.action = () => handleActionIfDeactivated(reopenQuote, 'reopening', 'reopen');
    } else if (quoteInfo.status === QuoteStatus.SENT_TO_CUSTOMER) {
      buttons.approve = getButtonMeta('Approve quote');
      buttons.approve.action = () => {
        setOpenReasonsModal(true);
        setQuoteStatus('Approve');
      };
      buttons.reject = getButtonMeta('Reject quote');
      buttons.reject.action = () => {
        setOpenReasonsModal(true);
        setQuoteStatus('Reject');
      };
      buttons.cancel = getButtonMeta('Cancel quote');
      buttons.cancel.action = () => cancelQuotePrompt(setConfirmData, cancelQuote);
      buttons.clone = getButtonMeta('Clone quote');
      buttons.clone.action = () => cloneQuote('clone');
    }
    if (
      quoteInfo.quoteJobs?.items?.length &&
      flags[FeatureFlags.CHANGE_ORDER_WORKFLOW] &&
      flags[FeatureFlags.JOB_CLOSEOUT]
    ) {
      buttons.dissociateJob = getButtonMeta('Dissociate Job');
      buttons.dissociateJob.action = () => dissociateJob();
    }
  }
  return buttons;
};

export const getQuoteName = (quoteInfo, hasMultipleVersions) => {
  const { customIdentifier, quoteNumber, versionNumber, name, versionLabel } = quoteInfo;
  return `${customIdentifier || quoteNumber}${
    hasMultipleVersions ? ` Version ${versionNumber}` : ''
  }${name ? ` ${name}` : ''}${versionLabel ? ` ${versionLabel}` : ''}`;
};

export const getQuoteNumberAndVersion = (quoteInfo, hasMultipleVersions) => {
  const { customIdentifier, quoteNumber, versionNumber } = quoteInfo;
  return `${customIdentifier || quoteNumber || ''}${
    hasMultipleVersions ? ` Version ${versionNumber}` : ''
  }`;
};

export const getServiceAgreementsByProperty = (serviceAgreements = [], propertyId) => {
  if (propertyId && serviceAgreements?.length) {
    const propertyServiceAgreements = serviceAgreements?.reduce((options, sa) => {
      if (!isEmpty(sa?.propertiesJSON)) {
        try {
          const properties = JSON.parse(sa.propertiesJSON);
          const isValid = properties?.some(property => property?.value === propertyId);
          if (isValid) return [...options, sa];
        } catch (e) {
          Logger.error(`Unable to parse property details for service agreement`, e);
        }
      }
      return options;
    }, []);
    return propertyServiceAgreements;
  }
  return [];
};

export const checkCustomQuoteNumber = () => {
  if (Context.getCompanyContext() && Context.getCompanyContext().getCompany) {
    const customExpressionValue = getTenantSettingValueForKey('custom_quote_expression');
    const isCustomQuoteNumberEnabled = !!customExpressionValue?.trim();
    return isCustomQuoteNumberEnabled;
  }
  return false;
};

export const checkCustomJobNumber = () => {
  if (Context.getCompanyContext() && Context.getCompanyContext().getCompany) {
    const customExpressionValue = getTenantSettingValueForKey('job_customJobNumber');
    const isCustomJobNumberEnabled = !!customExpressionValue?.trim();
    return isCustomJobNumberEnabled;
  }
  return false;
};

const isLogIncluded = (log, entityMapWithChange) => {
  const entityData = entityMapWithChange[log.entityType];

  if (!entityData) return false;

  return (
    (log.entityField in entityData.fields ||
      entityData.executionTypes?.includes(log.changeType) ||
      log.customMessage) &&
    round(log.old, 5) !== round(log.new, 5)
  );
};

export const filterQuoteLogs = changeList => {
  return changeList.reduce((result, change) => {
    const entityMapWithChange = entityMapAttributes(change);
    if (isLogIncluded(change, entityMapWithChange)) {
      const entityChange = entityMapWithChange[change.entityType];
      const fieldName = entityChange.fields[change.entityField] || change.field;
      const hideChanges = entityChange.hideChanges?.includes(change.entityField) || false;
      result.push({
        ...change,
        field: fieldName,
        hideChanges
      });
    }
    return result;
  }, []);
};

export const flattenAuditLogs = entityWithLogs => {
  return entityWithLogs?.items?.reduce((acc, cur) => [...acc, ...cur.auditLogs?.items], []);
};

export const getFormattedQuoteAuditLogs = quoteAuditLogData => {
  const {
    auditLogs,
    quoteTaskGroups,
    quoteLineTasks,
    quoteLineProducts,
    attachments
  } = quoteAuditLogData;

  const quoteTaskGroupLogs = flattenAuditLogs(quoteTaskGroups);
  const quoteLineProductLogs = flattenAuditLogs(quoteLineProducts);
  const attachmentLogs = flattenAuditLogs(attachments);
  const quoteLineTaskLogs = quoteLineTasks.items.reduce((acc, cur) => {
    const taskLineProductLogs = flattenAuditLogs(cur.quoteLineProducts);
    const taskLineLaborLogs = flattenAuditLogs(cur.quoteLineLabors);
    return [...acc, ...cur.auditLogs.items, ...taskLineProductLogs, ...taskLineLaborLogs];
  }, []);

  const combineAuditLogs = [
    ...auditLogs.items,
    ...quoteTaskGroupLogs,
    ...quoteLineTaskLogs,
    ...quoteLineProductLogs,
    ...attachmentLogs
  ];
  return combineAuditLogs;
};

// generate the labor types with all payroll costs associated
// FF used to determine if costs are generated using labor rate
// groups and types or just labor types
export const getLaborTypesWithPayrollCosts = ({
  labourTypes,
  labourRateGroups,
  payrollHourTypes,
  enterpriseLaborCostingEnabled
}) => {
  const formattedLabourTypes = getLabourTypes(labourTypes);
  if (enterpriseLaborCostingEnabled) {
    return formattedLabourTypes
      .map(type =>
        labourRateGroups.map(group => ({
          ...type,
          labourTypeBillingHourTypeProducts: type.labourTypeBillingHourTypeProducts.filter(
            ({ labourRateGroupId }) => labourRateGroupId === group.id
          ),
          labourRateGroupId: group.id,
          labourTypeId: type.id,
          name: `${group.name} - ${type.name}`,
          sortOrder: group.sortOrder * 10 + type.sortOrder,
          quoteLineLaborPayrollHours: sortBy(
            group.labourRateGroupEntries?.items
              ?.filter(({ labourTypeId }) => labourTypeId === type.id)
              .reduce((result, entry) => {
                const hourTypeInfo = payrollHourTypes.find(
                  ({ id }) => id === entry.payrollHourTypeId
                );
                if (hourTypeInfo?.isArchived) {
                  return result;
                }
                // Add sorting info so labor rates and billing rates displayed in same order
                return [
                  ...result,
                  {
                    unitCost: entry.rate || 0,
                    hourType: hourTypeInfo?.hourType,
                    sortOrder: hourTypeInfo?.sortOrder,
                    payrollHourTypeId: hourTypeInfo?.id,
                    costCodeId: entry.costCodeId,
                    jobCostTypeId: entry.jobCostTypeId,
                    labourRateGroupId: group.id
                  }
                ];
              }, []),
            'sortOrder'
          )
        }))
      )
      .flat();
  }

  return formattedLabourTypes.map(type => ({
    ...type,
    labourTypeId: type.id,
    labourRateGroupId: null,
    quoteLineLaborPayrollHours: sortBy(
      type.payrollCosts.reduce((result, costs) => {
        const hourTypeInfo = payrollHourTypes.find(hourType => hourType.id === costs.hourTypeId);
        // Add sorting info so labor rates and billing rates displayed in same order
        return [
          ...result,
          {
            unitCost: costs.cost || 0,
            hourType: hourTypeInfo?.hourType,
            sortOrder: hourTypeInfo?.sortOrder,
            payrollHourTypeId: hourTypeInfo?.id,
            costCodeId: costs.costCodeId,
            jobCostTypeId: costs.jobCostTypeId
          }
        ];
      }, []),
      'sortOrder'
    )
  }));
};

export const getBillingRates = ({
  laborTypesWithPayrollCosts,
  billingHourTypes,
  quoteState,
  pricebook
}) => {
  const billingHourTypeItems = sortBy(billingHourTypes, 'sortOrder') || [];
  let pricebookLaborEntryItems = [];
  if (quoteState.quoteInfo.priceBook) {
    pricebookLaborEntryItems = quoteState.quoteInfo.priceBook?.pricebookLabourEntries?.items;
  } else {
    // Use default pricebook if no PB associated with quote
    pricebookLaborEntryItems = pricebook.pricebookLabourEntries.items;
  }
  const laborTypesWithBillingAndLaborRates = laborTypesWithPayrollCosts.map(type => ({
    ...type,
    quoteLineLaborBillingHours: billingHourTypeItems.map(item => ({
      billingHourTypeId: item.id,
      hourType: item.hourType,
      sortOrder: item?.sortOrder,
      unitPrice:
        pricebookLaborEntryItems.find(
          elem =>
            (elem.labourTypeId === type.id || elem.labourTypeId === type.labourTypeId) &&
            elem.billingHourTypeId === item.id
        )?.rate || 0,
      productId: type.labourTypeBillingHourTypeProducts?.find(
        el => el.billingHourTypeId === item.id
      )?.productId
    }))
  }));

  return sortBy(laborTypesWithBillingAndLaborRates, 'sortOrder');
};
