const rbDetailTableMeta = areJobCostDetailsRequired => [
  {
    id: 'lineNumber',
    label: 'Line #',
    type: 'number',
    numeric: true,
    align: 'left'
  },
  {
    id: 'Product.name',
    label: 'Item Name',
    type: 'string',
    align: 'left'
  },
  {
    id: 'description',
    label: 'Description',
    type: 'string',
    align: 'left'
  },
  {
    id: 'Department.tagName',
    label: 'Department',
    type: 'string',
    align: 'left'
  },
  {
    id: 'unitOfMeasure',
    label: 'UOM',
    type: 'string',
    align: 'left',
    headerStyle: {
      maxWidth: 75
    }
  },
  {
    id: 'taxable',
    label: 'Taxable',
    type: 'boolean',
    align: 'left',
    headerStyle: {
      maxWidth: 95
    }
  },
  {
    id: 'unitCost',
    label: 'Cost',
    type: 'CustomCurrency',
    isCustom: true,
    numeric: true,
    align: 'left'
  },
  {
    id: 'quantity',
    label: 'Qty Fulfilled',
    type: 'number',
    numeric: true,
    align: 'left',
    headerStyle: {
      maxWidth: 120
    }
  },
  {
    id: 'totalCost',
    label: 'Total',
    type: 'currency',
    align: 'left',
    numeric: true
  },
  ...(areJobCostDetailsRequired
    ? [
        {
          id: 'Job.jobNumber',
          label: 'Job/Project',
          type: 'string',
          align: 'left'
        },
        {
          id: 'JcPhase.name',
          label: 'Job Phase',
          type: 'string',
          align: 'left'
        },
        {
          id: 'JcCostType.name',
          label: 'Cost Type',
          type: 'string',
          align: 'left'
        }
      ]
    : [])
];

export default rbDetailTableMeta;
