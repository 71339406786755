import React, { useCallback, useRef } from 'react';

import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { Context, DefaultButton } from 'components';
import SectionHeader from 'components/SectionHeader';
import { COMPANY_SETTING_TYPE } from 'constants/common';
import useCompanySettings from 'customHooks/useCompanySettings';
import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import { EmployeeStatus } from 'utils/AppConstants';
import { constructSelectOptions } from 'utils/constructSelectOptions';

import AfterHourForm from './Form';
import { extractAfterHourSettings } from './helpers';
import { fetchAllDepartmentsAndEmployees, saveHourSetting } from './Services';
import AfterHourList from './Setting';

const AfterHourSection = ({ departmentsUpdatedCount, ...props }) => {
  const user = useSelector(s => s.user);
  const dispatch = useDispatch();
  const snackbar = useCallback((...args) => dispatch(snackbarOn(...args)), [dispatch]);
  const [openForm, setOpenForm] = React.useState(false);
  const [afterHourSettings, , getData] = useCompanySettings(
    COMPANY_SETTING_TYPE.AFTER_HOURS,
    user.tenantId,
    user.tenantCompanyId,
    snackbar
  );

  const prevDepartmentsUpdatedCount = useRef(departmentsUpdatedCount);

  const afterHours = extractAfterHourSettings(afterHourSettings);

  const [employees, setEmployees] = React.useState([]);
  const [departments, setDepartments] = React.useState([]);
  const [afterHourSetting, setAfterHourSetting] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  const addDepartmentIds = employee => {
    const employeeDepartments = employee?.departments?.items || [];
    const departmentIds = employeeDepartments.map(department => department?.mappedEntity?.id);
    return {
      ...employee,
      departmentIds
    };
  };

  React.useEffect(() => {
    const handleFetchData = async () => {
      setIsLoading(true);
      const { departmentItems, employeeItems } = await fetchAllDepartmentsAndEmployees({
        user,
        snackbarOn: snackbar
      });
      prevDepartmentsUpdatedCount.current = departmentsUpdatedCount;
      setEmployees(employeeItems.map(addDepartmentIds));
      setDepartments(departmentItems);
      setIsLoading(false);
    };
    if (
      prevDepartmentsUpdatedCount.current !== departmentsUpdatedCount ||
      !prevDepartmentsUpdatedCount.current
    ) {
      handleFetchData();
    }
  }, [departmentsUpdatedCount, snackbar, user]);

  const employeeOptions = employees
    .filter(employee => employee?.isTech && employee?.status === EmployeeStatus.ACTIVE)
    .map(employee => ({
      label: employee?.name || '',
      value: employee?.id,
      departmentIds: employee?.departmentIds
    }));
  const departmentOptions = constructSelectOptions(departments, 'tagName');

  const handleFormDialogAction = ({ isOpen = false, afterHourValue = {} }) => {
    setOpenForm(isOpen);
    setAfterHourSetting(afterHourValue);
  };

  const handleItemAdd = (setting, stopSpinner) => {
    const successCallback = () => {
      getData();
      stopSpinner();
      handleFormDialogAction({});
    };

    saveHourSetting({
      user,
      afterHourSetting: setting,
      snackbarOn: snackbar,
      successCallback,
      stopSpinner
    });
  };

  return (
    <Box display="flex" flexDirection="column">
      <SectionHeader
        overrideHeaderButtons={[
          <DefaultButton
            color="secondary"
            disabled={isLoading}
            handle={() => handleFormDialogAction({ isOpen: true })}
            key="addSchedule"
            label="Add schedule"
          />
        ]}
        title={Labels.companyHourSetting[user.locale]}
      />
      {openForm && (
        <AfterHourForm
          afterHourSetting={afterHourSetting}
          departmentOptions={departmentOptions}
          employeeOptions={employeeOptions}
          isOpen={openForm}
          onClose={() => handleFormDialogAction({})}
          onSave={(value, stopSpinner) => handleItemAdd(value, stopSpinner)}
        />
      )}
      {afterHours.map(afterHour => (
        <AfterHourList
          data={afterHour}
          departmentOptions={departmentOptions}
          employeeOptions={employeeOptions}
          isLoading={isLoading}
          key={afterHour.id}
          onEdit={() => handleFormDialogAction({ isOpen: true, afterHourValue: afterHour })}
        />
      ))}
    </Box>
  );
};

export default AfterHourSection;
