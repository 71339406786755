import React, { useRef, useState } from 'react';

import { Button, TV, Typography } from '@BuildHero/sergeant';

import { useSelector } from 'react-redux';

import { getState } from 'redux/store';
import { PricingStrategy } from 'utils/constants';

import { InventoryItemFormModal } from '../../../../../../components/PartsAndMaterials';
import { useAddInventoryParts } from '../../../../../../mutations/useAddInventoryParts';
import { useDeleteInventoryItem } from '../../../../../../mutations/useDeleteInventoryPart';
import {
  useToggleInventoryPart,
  useUpdateInventoryPart
} from '../../../../../../mutations/useUpdateInventoryPart';

import InventoryItems from '../../../../../PartsAndMaterialsSection/components/InventoryItemsSection';

import { useReviewReportDisabled } from '../../../../ReviewReport.contexts';

import { useAvailableTrucks, useGetProductFilter } from './ReviewReportInventoryItems.hooks';
import {
  selectInventoryInput,
  selectInventoryItemFormData,
  selectInventoryItemUpdateInput
} from './ReviewReportInventoryItems.selectors';
import { useStyles } from './ReviewReportInventoryItems.styles';

const InventoryModalModes = {
  ADD: 'ADD',
  EDIT: 'EDIT'
};

const ReviewReportInventoryItems = ({ reviewReport, visit, job, loading, error }) => {
  const styles = useStyles();
  const defaultPriceBookId = useSelector(state => state?.company?.defaultPriceBookId);
  const { disabled } = useReviewReportDisabled();
  const availableTrucks = useAvailableTrucks(visit);
  const productFilter = useGetProductFilter({ job });
  const [inventoryItemModalOpen, setInventoryItemModalOpen] = useState(false);
  const modalMode = useRef(null);
  const modalInitialValues = useRef({});
  const marginEnabled = useSelector(s => s.settings.pricingStrategy === PricingStrategy.MARGIN);

  const [addInventoryParts, addInventoryPartsResponse] = useAddInventoryParts(reviewReport.id, {
    serializer: ({ reviewReportId, inventoryParts: formParts, departmentName }) => ({
      variables: {
        partitionKey: getState()?.company?.partitionKey,
        data: {
          reviewReportId,
          inventoryParts: formParts?.map(formData =>
            selectInventoryInput({ departmentName, ...formData }, marginEnabled)
          )
        }
      }
    })
  });

  const [updateInventoryItem] = useUpdateInventoryPart({
    serializer: ({ inventoryItem: formData }) => ({
      variables: {
        partitionKey: getState()?.company?.partitionKey,
        data: selectInventoryItemUpdateInput(formData, marginEnabled)
      }
    })
  });

  const [deleteInventoryItem] = useDeleteInventoryItem(reviewReport.id);
  const [toggleInventoryItem] = useToggleInventoryPart();

  const handleAddClick = () => {
    modalMode.current = InventoryModalModes.ADD;
    modalInitialValues.current = {};
    setInventoryItemModalOpen(true);
  };

  const handleEditClick = inventoryItemId => {
    modalMode.current = InventoryModalModes.EDIT;
    modalInitialValues.current = selectInventoryItemFormData(
      reviewReport?.inventoryParts?.items?.find(part => part.id === inventoryItemId) || {}
    );
    setInventoryItemModalOpen(true);
  };

  const handleInventoryPartSubmit = inventoryItem => {
    if (modalMode.current === InventoryModalModes.ADD) {
      addInventoryParts({
        reviewReportId: reviewReport.id,
        inventoryParts: [inventoryItem],
        departmentName: visit.departmentName
      });
    } else {
      updateInventoryItem({ inventoryItem });
    }

    setInventoryItemModalOpen(false);
  };

  const handleInvoiceToggle = ({ value, row }) => {
    toggleInventoryItem({ id: row.id, version: row.version, includeInInvoice: !value });
  };

  const handleDeleteClick = inventoryItemId => {
    deleteInventoryItem({ inventoryItemId });
  };

  return (
    <>
      <div css={styles.subSectionContainer}>
        <div css={styles.headerContainer}>
          <div>
            <Typography css={styles.title}>Inventory items</Typography>
            <Typography variant={TV.S1}>Selected items will be added to the invoice</Typography>
          </div>
          <Button disabled={disabled} size="small" type="secondary" onClick={handleAddClick}>
            Add Inventory Item
          </Button>
        </div>
        <InventoryItems
          error={error}
          handleDeleteClick={handleDeleteClick}
          handleEditClick={handleEditClick}
          handleInvoiceToggle={handleInvoiceToggle}
          isReviewReport
          items={reviewReport?.inventoryParts}
          loading={loading}
          reviewReportId={reviewReport.id}
        />
      </div>
      <InventoryItemFormModal
        availableTrucks={availableTrucks}
        initialValues={modalInitialValues.current}
        loading={addInventoryPartsResponse.loading}
        open={inventoryItemModalOpen}
        priceBookId={job?.priceBookId || defaultPriceBookId}
        productFilter={productFilter}
        title={`${modalMode.current === InventoryModalModes.ADD ? 'Add' : 'Edit'} Inventory Item`}
        onClose={() => setInventoryItemModalOpen(false)}
        onSubmit={handleInventoryPartSubmit}
      />
    </>
  );
};

export default ReviewReportInventoryItems;
