import React from 'react';

// eslint-disable-next-line no-unused-vars
import { jsx } from '@emotion/react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import styles from './IntegrationsList.style';
import ServiceChannelIntegration from './ServiceChannel';

const IntegrationsList = () => {
  const flags = useFlags();

  return (
    <div css={styles.container}>
      {flags[FeatureFlags.SERVICE_CHANNEL_INTEGRATION] && <ServiceChannelIntegration />}
    </div>
  );
};

export default IntegrationsList;
