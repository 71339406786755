import React from 'react';

import Placeholder from './Placeholder';

const NoAssets = () => (
  <Placeholder
    height={400}
    subtitle="Assets will show up once you have selected a property"
    subtitleWidth={194}
    title=" No Assets"
  />
);

export default NoAssets;
