import React, { useEffect, useState } from 'react';

import { bool } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useConfirmModal } from 'customHooks/ConfirmModalContext';

/**
 * Component to confirm if the user wants to navigate away/close tab
 * @param {boolean} props.when If true, will alert user if they try to navigate away
 */
export default function ConfirmLeave({ when }) {
  const history = useHistory();

  const [counter, setCounter] = useState(0);
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  const confirmContext = useConfirmModal();

  useEffect(() => {
    if (when) {
      history.block(prompt => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        setCounter(counter + 1);
        return false;
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when, counter]);

  useEffect(() => {
    const confirm = async () => {
      if (
        showPrompt &&
        (await confirmContext.confirm({
          body: 'You have unsaved changes, are you sure you want to leave?',
          buttonLabel: 'Ok',
          cancelButtonLabel: 'Cancel'
        }))
      ) {
        history.block(() => {});
        history.push(currentPath);
      }
    };
    confirm();
  }, [counter]);

  return null;
}

ConfirmLeave.propTypes = {
  when: bool
};
ConfirmLeave.defaultProps = {
  when: false
};
