export const useStyles = () => ({
  savedFilterWrapper: {
    marginBottom: 20
  },
  filterCaption: theme => ({
    fontSize: 10,
    lineHeight: '12px',
    marginBottom: 4,
    fontWeight: 500,
    color: theme.palette.grayscale(20),
    letterSpacing: '0.02em'
  }),
  filtersTitle: theme => ({
    marginLeft: 8,
    marginTop: 16,
    marginBottom: 16,
    color: theme.palette.grayscale(20),
    fontWeight: 500,
    fontSize: 14
  }),
  sectionTitle: theme => ({
    marginLeft: 8,
    marginBottom: 6,
    color: theme.palette.grayscale(60),
    fontWeight: 500,
    fontSize: 10
  }),
  menuText: theme => ({
    color: theme.palette.grayscale(20),
    fontSize: 12,
    fontWeight: 400
  }),
  moreIcon: theme => ({
    color: theme.palette.grayscale(20),
    position: 'absolute',
    right: 10,
    fontSize: 16,
    minWidth: 0
  }),
  moreIconStyle: {
    fontSize: 16
  },
  iconStyle: {
    fontSize: 14,
    marginRight: 6
  },
  button: theme => ({
    height: 48,
    width: '100%',
    paddingLeft: 16,
    alignItems: 'center',
    color: theme.palette.secondary.main,
    borderTop: `1px solid ${theme.palette.grayscale(90)}`,
    justifyContent: 'left'
  }),
  selectWrapper: theme => ({
    position: 'relative',
    width: '100%',
    height: 36,
    borderRadius: 2,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '-0.011px',
    border: 0,
    marginRight: 8,
    '&.MuiSelect-root': {
      borderRadius: 4,
      alignSelf: 'flex-start'
    },
    '&.MuiSelect-select.MuiSelect-select': {
      fontSize: 13,
      display: 'flex',
      flexWrap: 'wrap',
      alignSelf: 'center',
      flexDirection: 'row',
      width: 120,
      paddingLeft: 16
    }
  })
});
