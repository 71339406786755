import React from 'react';

import { TH, TV, TW, Typography } from '@BuildHero/sergeant';
import { css } from '@emotion/react'; // eslint-disable-line no-unused-vars

import StatusChip from 'components/StatusChip';
import { EnumType } from 'utils/constants';

const Label = label => (
  <Typography height={TH.COMFORTABLE} variant={TV.BASE} weight={TW.BOLD}>
    {label}
  </Typography>
);

const containerStyle = { display: 'flex', gap: 8, alignItems: 'center' };

const RenderJob = (label, value, palette) => (
  <div css={containerStyle}>
    {Label(label)}
    <StatusChip
      css={{ borderRadius: 2, cursor: 'pointer' }}
      enumType={EnumType.JOB_STATUS}
      enumValue={value.status}
      label={value.status}
    />
    <Typography color={palette.text.secondary} variant={TV.S1}>
      {value.customerPropertyName}
    </Typography>
    <Typography color={palette.text.secondary} variant={TV.S1}>
      {value.customerName}
    </Typography>
  </div>
);

export const renderFor = {
  Customer: Label,
  CustomerProperty: (label, value, palette) => (
    <div css={containerStyle}>
      {Label(label)}
      <Typography color={palette.text.secondary} variant={TV.S1}>
        {value.customerName}
      </Typography>
    </div>
  ),
  Job: RenderJob,
  Maintenance: RenderJob,
  ServiceAgreement: (label, value, palette) => (
    <div css={containerStyle}>
      {Label(label)}
      <Typography>{value.agreementNumber}</Typography>
      <Typography color={palette.text.secondary} variant={TV.S1}>
        {value.customerName}
      </Typography>
    </div>
  ),
  Project: (label, value, palette) => (
    <div css={containerStyle}>
      {Label(label)}
      <StatusChip
        css={{ borderRadius: 2, cursor: 'pointer' }}
        enumType={EnumType.PROJECT_MANAGEMENT_SUBMITTAL_STATUS}
        enumValue={value.status}
        label={value.status}
      />
      <Typography color={palette.text.secondary} variant={TV.S1}>
        {value.propertyName}
      </Typography>
      <Typography color={palette.text.secondary} variant={TV.S1}>
        {value.customerName}
      </Typography>
    </div>
  )
};
