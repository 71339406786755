import React from 'react';

import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';

import { ResponsiveTable } from 'components';
import { assetsRows } from 'meta/Jobs/Invoice/review-report-tables';

const AssetsWorkedOn = ({ classes, current }) => {
  const { context } = current;
  let visitAssets = [];
  if (!_.isEmpty(context.visitAssets)) {
    visitAssets = context.visitAssets.map(asset => asset.propertyAsset);
  }

  return (
    <>
      <Typography className={classes.sectionTitle}>Assets Worked On</Typography>
      <Divider classes={{ root: classes.blueDivider }} variant="fullWidth" />
      <ResponsiveTable
        data={visitAssets}
        disableFilter
        disablePagination
        noDataMsg="No assets"
        noMaxHeight
        rowMetadata={assetsRows}
      />
    </>
  );
};

export default AssetsWorkedOn;
