import React from 'react';

import { DateInput } from '@BuildHero/sergeant';
import { Typography, useTheme } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { object } from 'prop-types';

import { useStyles } from './ReportDateInputField.styles';

const ReportDateInputField = ({ field, form, options }) => {
  const styles = useStyles();
  const theme = useTheme();
  const timezone = options?.timezone;
  const error = form?.errors?.[field.name];
  const errorColor = theme.palette.error.main;
  return (
    <>
      <div css={styles.label}>
        <Typography css={styles.text} gutterBottom variant="caption">
          Date
        </Typography>
        <Typography css={styles.required} gutterBottom variant="caption">
          Required
        </Typography>
      </div>
      <DateInput
        disabled={options?.disabled || !field.value || !timezone}
        timezone={timezone}
        value={field.value}
        onChange={value => form.setFieldValue(field.name, value)}
      />
      {error && (
        <div css={styles.error}>
          <ErrorIcon fontSize="small" style={{ marginRight: 4, color: errorColor }} />
          <Typography style={{ color: errorColor }} variant="caption">
            {error}
          </Typography>
        </div>
      )}
    </>
  );
};

ReportDateInputField.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
  options: object.isRequired
};

export default ReportDateInputField;
