import { useCallback, useEffect, useState } from 'react';

import { CustomerPropertyService } from 'services/core';
import { logErrorWithCallback } from 'utils';
import { PropertyEntities } from 'utils/constants';

const { ASSETS, JOB, MAINTENANCE_JOB, QUOTE } = PropertyEntities;
const massageFn = response => response?.items;

const getPropertyEntityServices = service => ({
  [ASSETS]: {
    queryService: service.listPropertyAssets,
    massageFn: response => response.data.getCustomerPropertyById?.propertyAssets?.items
  },
  [JOB]: {
    queryService: service.getJobsByProperty,
    massageFn
  },
  [MAINTENANCE_JOB]: {
    queryService: service.getMaintenanceRecordsByProperty,
    massageFn
  },
  [QUOTE]: {
    queryService: service.getQuotesByCustomerPropertyById,
    massageFn
  }
});

export const getPropertyEntity = async (entity, params, snackbarOn) => {
  let responseData = [];
  try {
    const Services = getPropertyEntityServices(new CustomerPropertyService())?.[entity];
    let response;
    if (typeof params !== 'string') {
      response = await Services?.queryService(...params);
    } else {
      response = await Services?.queryService(params);
    }
    responseData = Services?.massageFn(response);
  } catch (error) {
    logErrorWithCallback(
      error,
      snackbarOn,
      `Unable to fetch property ${entity}, please try again later`
    );
  }
  return responseData;
};

/**
 * React hook to query property entities.
 *
 * Property entities are Assets, JOB, MAINTENANCE_JOB, and QUOTE
 *
 * @param {PropertyEntities} entity
 * @param {string | object} params
 * @param {*} snackbarOn
 * @returns { propertyEntities: [], getData: function, loaded: Boolean}
 */

const usePropertyEntities = (entity, params, snackbarOn) => {
  const [propertyEntities, setPropertyEntities] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const getData = useCallback(async () => {
    setLoaded(false);
    const assets = await getPropertyEntity(entity, params, snackbarOn);
    setPropertyEntities(assets);
    setLoaded(true);
  }, [snackbarOn, entity, params]);

  useEffect(() => {
    if (params && entity) {
      getData();
    }
  }, [entity, params, getData]);
  return { propertyEntities, getData, loaded };
};

export default usePropertyEntities;
