import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Button, ThemeProvider, TW, Typography } from '@BuildHero/sergeant';
import { useTheme } from '@material-ui/core';

import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';

import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';

import { isEmpty } from 'lodash';

import useDepartments from 'customHooks/useDepartments';
import useServiceAgreement from 'customHooks/useServiceAgreement';
import { useSnackbar } from 'customHooks/useSnackbar';
import { useTrigger } from 'customHooks/useTrigger';

import Attachments from './Attachments';
import { attachmentTypes } from './Attachments/constants';
import Billing from './Billing';
import { useCreateDraftServiceAgreement } from './hooks/useCreateDraftServiceAgreement';
import { useUpdateServiceAgreement } from './hooks/useUpdateServiceAgreement';
import Overview from './Overview';
import RateCard from './RateCard';

const INITIAL_STEPS_STATE = {
  OVERVIEW: { value: 'OVERVIEW', label: 'Overview', viewed: false, errors: {} },
  BILLING: { value: 'BILLING', label: 'Billing', viewed: false, errors: {} },
  RATE_CARD: { value: 'RATE_CARD', label: 'Rate Card', viewed: false, errors: {} },
  ATTACHMENTS: { value: 'ATTACHMENTS', label: 'Attachments', viewed: false, errors: {} }
};

const steps = [
  {
    label: INITIAL_STEPS_STATE.OVERVIEW.label,
    value: INITIAL_STEPS_STATE.OVERVIEW.value,
    render: ({
      setService,
      saveData,
      data,
      revisiting,
      service,
      departments,
      onDirty,
      initialized
    }) => {
      return (
        <Overview
          data={data}
          departments={departments}
          initialized={initialized}
          revisiting={revisiting}
          saveData={saveData}
          service={service}
          setService={setService}
          onDirty={onDirty}
        />
      );
    }
  },
  {
    label: INITIAL_STEPS_STATE.BILLING.label,
    value: INITIAL_STEPS_STATE.BILLING.value,
    render: ({ setService, saveData, data, revisiting, service, onDirty }) => (
      <Billing
        data={data}
        revisiting={revisiting}
        saveData={saveData}
        service={service}
        setService={setService}
        onDirty={onDirty}
      />
    )
  },
  {
    label: INITIAL_STEPS_STATE.RATE_CARD.label,
    value: INITIAL_STEPS_STATE.RATE_CARD.value,
    render: ({ saveData, data }) => <RateCard data={data} saveData={saveData} />
  },
  {
    label: INITIAL_STEPS_STATE.ATTACHMENTS.label,
    value: INITIAL_STEPS_STATE.ATTACHMENTS.value,
    render: ({ attachmentRef, data, saveData }) => (
      <Attachments data={data} ref={attachmentRef} saveData={saveData} />
    )
  }
];

const allManditoryStepsAreViewedAndComplete = ({ stepState }) =>
  stepState.OVERVIEW.viewed &&
  stepState.BILLING.viewed &&
  isEmpty({
    ...stepState.OVERVIEW.errors,
    ...stepState.BILLING.errors,
    ...stepState.RATE_CARD.errors,
    ...stepState.ATTACHMENTS.errors
  });

const existingSAAndComplete = ({ stepState, serviceAgreementId }) =>
  serviceAgreementId &&
  isEmpty({
    ...stepState.OVERVIEW.errors,
    ...stepState.BILLING.errors,
    ...stepState.RATE_CARD.errors,
    ...stepState.ATTACHMENTS.errors
  });

const newAgreementAndOverviewAndBillingNotFilledOut = ({ stepState, serviceAgreementId }) =>
  !serviceAgreementId &&
  (!stepState.OVERVIEW.viewed ||
    !stepState.BILLING.viewed ||
    !isEmpty(stepState.OVERVIEW.errors) ||
    !isEmpty(stepState.BILLING.errors));

const renderBulletIcon = ({ step, stepState, isPastStep, isFutureStep, index, theme }) => {
  if (isFutureStep) {
    return (
      <Typography css={{ color: theme.palette.grayscale(50) }} weight={TW.MEDIUM}>
        1.{index + 1}
      </Typography>
    );
  }
  if (isPastStep && isEmpty(stepState[step.value].errors)) {
    return <CheckIcon css={{ color: theme.palette.support.green.dark }} />;
  }

  if (isPastStep && !isEmpty(stepState[step.value].errors)) {
    return <ErrorIcon css={{ color: theme.palette.error.main }} />;
  }
  return <Typography weight={TW.MEDIUM}>1.{index + 1}</Typography>;
};

const AgreementInformation = ({
  triggerSave,
  setSaving,
  serviceAgreementId,
  setServiceAgreementId,
  setCurrentStepComplete,
  onDirty,
  dirty
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [service, setService] = useState();
  const [savedData, setSavedData] = useState({});
  const [initializedSA, setInitializedSA] = useState(false);
  const [stepState, setStepState] = useState(INITIAL_STEPS_STATE);
  const [departmentsResponse] = useDepartments();
  const [tryResave, trySavingAgain] = useTrigger();
  const attachmentRef = useRef(null);

  const [_, loadingServiceAgreement] = useServiceAgreement({
    serviceAgreementId,
    onSuccess: result => {
      setSavedData(oldValues => ({
        ...oldValues,
        ...result,
        customerName: result?.customer?.customerName,
        billingCustomerName: result?.billingCustomer?.customerName,
        contracts:
          result?.attachments?.items?.filter(x => x.type === attachmentTypes.CONTRACT) ?? [],
        fileAttachments:
          result?.attachments?.items?.filter(x => x.type === attachmentTypes.ATTACHMENT) ?? []
      }));
      setInitializedSA(true);
    }
  });

  const [
    createDraftServiceAgreement,
    { loading: creatingDraftServiceAgreement }
  ] = useCreateDraftServiceAgreement({
    onComplete: async ({ id }) => {
      await attachmentRef?.current?.onSave?.(id);

      window.history.replaceState(null, '', `/serviceAgreement/editdraft/${id}`);
      setServiceAgreementId(id);
    }
  });

  const [
    updateServiceAgreement,
    { loading: updatingServiceAgreement }
  ] = useUpdateServiceAgreement();

  useEffect(() => {
    setSaving(creatingDraftServiceAgreement || loadingServiceAgreement || updatingServiceAgreement);
  }, [creatingDraftServiceAgreement, loadingServiceAgreement, updatingServiceAgreement]);

  const snackbar = useSnackbar();

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const handleNext = async ({ step, onNoError }) => {
    const isValid = (await service?.validateForm()) || {};
    setStepState(oldState => ({
      ...oldState,
      [step.value]: { ...oldState[step.value], viewed: true, errors: isValid }
    }));

    if (onNoError && isEmpty(isValid)) {
      onDirty(false);
      onNoError();
    } else if (onNoError) {
      snackbar('error', 'invalid data, please try again');
    } else {
      onDirty(false);
      setActiveStep(prevActiveStep => {
        if (prevActiveStep === steps.length - 1) return prevActiveStep;
        setService();
        return prevActiveStep + 1;
      });
    }
  };

  useEffect(() => {
    // saves can be triggered from parent component
    if (!isFirstLoad) {
      if (newAgreementAndOverviewAndBillingNotFilledOut({ stepState, serviceAgreementId })) {
        setSaving(false);
        snackbar('error', 'Please fill out overview and billing before saving the agreement');
      } else if (!serviceAgreementId) {
        createDraftServiceAgreement(savedData);
      } else if (dirty) {
        handleNext({ step: steps[activeStep], onNoError: trySavingAgain });
      } else {
        updateServiceAgreement({ id: serviceAgreementId, ...savedData });
        const updateAttachments = async () => {
          await attachmentRef?.current?.onSave?.(serviceAgreementId);
        };
        updateAttachments();
      }
    } else {
      setIsFirstLoad(false);
    }
  }, [triggerSave, tryResave]);

  useEffect(() => {
    if (
      existingSAAndComplete({ serviceAgreementId, stepState }) ||
      allManditoryStepsAreViewedAndComplete({ stepState })
    ) {
      setCurrentStepComplete(true);
    } else {
      setCurrentStepComplete(false);
    }
  }, [serviceAgreementId, stepState]);

  const departmentOptions = useMemo(
    () => departmentsResponse.map(d => ({ label: d.name, value: d.id })),
    [departmentsResponse]
  );

  const handleBack = ({ index }) => {
    setActiveStep(index);
  };

  const theme = useTheme();

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step, index) => {
        const isPastStep = index < activeStep;
        const isFutureStep = index > activeStep;
        const isNextStep = index === activeStep + 1;
        return (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={() =>
                renderBulletIcon({ isPastStep, theme, index, isFutureStep, stepState, step })
              }
            >
              <ThemeProvider>
                <Button
                  disabled={isFutureStep && !isNextStep}
                  type="leading"
                  onClick={() => {
                    if (isNextStep) {
                      handleNext({ step: steps[activeStep] });
                    } else if (isPastStep) {
                      handleBack({ index });
                    }
                  }}
                >
                  {step.label}
                </Button>
              </ThemeProvider>
            </StepLabel>
            <StepContent>
              {step.render({
                service,
                setService,
                saveData: setSavedData,
                data: savedData,
                revisiting: stepState[step.value].viewed,
                departments: departmentOptions,
                onDirty,
                attachmentRef,
                initialized: initializedSA
              })}
            </StepContent>
          </Step>
        );
      })}
    </Stepper>
  );
};
export default AgreementInformation;
