import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation } from 'react-router-dom';

import FeatureFlagsConstants from 'utils/FeatureFlagConstants';

import TechnicianReportV2 from '../componentsV2/TechnicianReport';
import TechnicianReportV1 from '../TechnicianReport';

const TechnicianReportRoute = props => {
  const { search } = useLocation();
  const ldFlags = useFlags();

  // TODO: Remove with golive
  if (search === '?v2') return <TechnicianReportV2 {...props} />;

  if (search === '?v1' || !ldFlags[FeatureFlagsConstants.REPORTS_V2]) {
    return <TechnicianReportV1 {...props} />;
  }

  return <TechnicianReportV2 {...props} />;
};

export default TechnicianReportRoute;
