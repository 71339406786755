import AmplifyService from 'services/AmplifyService';

import * as Mutations from '../../graphql/serviceAgreement/mutations';
import {
  addServiceAgreementToCompanyMutation,
  addTagsToServiceAgreementMutation,
  deleteServiceAgreementServiceAgreementTagMutation,
  softDeleteServiceAgreementTagMutation
} from '../../graphql/serviceAgreement/mutations/serviceAgreementTags';
import updateServiceAgreement from '../../graphql/serviceAgreement/mutations/updateServiceAgreement';
import * as Query from '../../graphql/serviceAgreement/queries';

export default class ServiceAgreement {
  constructor() {
    this.api = AmplifyService.appSyncClient();
  }

  getServiceAgreement = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };

    const response = await this.api.query(Query.serviceAgreement, params);
    return response;
  };

  addServiceAgreementTemplate = async data => {
    const params = {
      data
    };

    const response = await this.api.mutate(Mutations.addMaintenanceTemplates, params);
    return response;
  };

  updateServiceAgreementTemplate = async data => {
    const params = {
      data
    };

    const response = await this.api.mutate(Mutations.updateMaintenanceTemplate, params);
    return response;
  };

  updateServiceAgreement = async values => {
    const response = await this.api.mutate(updateServiceAgreement, { data: values });
    return response;
  };

  addTagsToServiceAgreement = async params => {
    if (params?.paritionKey || !params?.data) return;
    const response = await this.api.mutate(addTagsToServiceAgreementMutation, params);
    return response;
  };

  deleteServiceAgreementServiceAgreementTag = async (partitionKey, id) => {
    const params = { partitionKey, id };
    await this.api.mutate(deleteServiceAgreementServiceAgreementTagMutation, params);
    return { success: true };
  };

  addServiceAgreementTagToCompany = async ({ serviceAgreementTags }) => {
    const activeTags = serviceAgreementTags?.map(({ __typename, ...tag }) => ({
      ...tag
    }));
    const response = await this.api.mutate(addServiceAgreementToCompanyMutation, {
      data: activeTags
    });
    return response;
  };

  deleteServiceAgreementTagsFromCompany = async id => {
    const response = await this.api.mutate(softDeleteServiceAgreementTagMutation, { id });
    return response;
  };
}
