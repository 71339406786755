import React, { forwardRef, memo, useMemo, useRef, useState } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { array, func, string } from 'prop-types';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { DB_LAZY_LOAD_THRESHOLD } from '@dispatch/Dispatch.constants';
import { watchedQueries } from '@dispatch/Dispatch.store';
import { ElementSizes } from '@dispatch/Dispatch.styles';
import { TECHS_RESPONSE_PROP } from '@dispatch/queries';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { useNewTechEventsOnDayChange, useVisitDragLifecycle } from '../../DispatchBoard.hooks';
import { selectEndTime, selectStartTime } from '../../DispatchBoard.selectors';
import { useStyles } from '../../DispatchBoard.styles';

import { GET_DISPATCH_EVENTS_BY_TECH_IDS } from '../../queries/useDispatchEventsByTechIds';
import TechsFilterButton from '../TechsFilterButton';

import DailyViewHeader from './components/DailyViewHeader';
import SwimLane from './components/SwimLane';
import { BoardContext, useBoardContext } from './DailyView.context';

import {
  useResetListItemSize,
  useScrollToCurrentTime,
  useSetBoardHeight
} from './DailyView.effects';

import { useItemData, useVisitDrop } from './DailyView.hooks';
import { selectLaneHeight } from './DailyView.selectors';
import { useTimePosition } from './hocs/withTimePosition';

const BoardFilterContainer = () => {
  const classes = useStyles();

  return (
    <div className={classes.boardFilterContainer}>
      <TechsFilterButton />
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const innerElementType = forwardRef(({ children, style, ...rest }, ref) => {
  const { boardHeight } = useBoardContext();

  return (
    <div ref={ref} style={{ ...style, width: ElementSizes.laneWidth }} {...rest}>
      <BoardFilterContainer />
      <DailyViewHeader boardHeight={boardHeight} />
      {children}
    </div>
  );
});

const DailyView = ({
  day,
  clearVisitRowHover,
  filterBy,
  techsResponse,
  lazyDispatchEventsTuple,
  visitTransitionTuple
}) => {
  const isLazyLoadEnabled = useFlags()[FeatureFlags.ENABLE_DISPATCH_LAZY_LOAD];
  const [boardRef, setRef] = useState();
  const [innerRef, setInnerRef] = useState();
  const [innerHeight, setInnerHeight] = useState(0);
  const listRef = useRef();
  const [infiniteLoader, setInfiniteLoader] = useState();
  const clientOffsetRef = useRef();
  const { position, positionOffset } = useTimePosition();
  const startDateTime = selectStartTime({ day });
  const endDateTime = selectEndTime({ day });
  const techIds = useMemo(() => techsResponse?.data?.map(tech => tech.id), [techsResponse]);

  const [triggerVisitTransition] = visitTransitionTuple;
  const [queryDispatchEvents, dispatchEventsResponse] = lazyDispatchEventsTuple;

  const itemData = useItemData({
    techsResponse,
    dispatchEventsResponse,
    boardRef,
    day
  });

  const [collected, dropRef] = useVisitDrop({
    clientOffsetRef,
    triggerVisitTransition,
    boardRef,
    day,
    clearVisitRowHover
  });

  // effects
  useVisitDragLifecycle({ ...collected, filterBy });
  useScrollToCurrentTime({ boardRef, position, positionOffset });
  useSetBoardHeight({ setInnerHeight, innerRef, itemData });
  useResetListItemSize({ listRef, itemData });
  useNewTechEventsOnDayChange({
    techIds,
    dispatchEventsResponse,
    infiniteLoader,
    queryDispatchEvents,
    startDateTime,
    endDateTime,
    isLazyLoadEnabled
  });

  const handleSetOuterRef = ref => {
    dropRef(ref);
    setRef(ref);
  };

  const loadMoreItems = (startIndex, stopIndex) => {
    if (!techsResponse.loading) {
      const techsToFetch = techIds.slice(startIndex - 1, stopIndex);

      const variables = { startDateTime, endDateTime, techIds: techsToFetch };

      watchedQueries.useDispatchBoardEvents = {
        query: GET_DISPATCH_EVENTS_BY_TECH_IDS,
        variables
      };

      if (techsToFetch.length) {
        return (dispatchEventsResponse.fetchMore || queryDispatchEvents)({
          variables: {
            data: variables
          }
        });
      }
    }
  };

  const isItemLoaded = index => {
    if (index === 0) return true;
    const techId = techIds[index - 1];
    return Boolean(dispatchEventsResponse.data[techId]);
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <BoardContext.Provider value={{ boardHeight: innerHeight, isOverBoard: collected?.isOver }}>
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemData.length}
            loadMoreItems={loadMoreItems}
            minimumBatchSize={10}
            ref={setInfiniteLoader}
            threshold={isLazyLoadEnabled ? DB_LAZY_LOAD_THRESHOLD : 1000}
          >
            {({ onItemsRendered, ref }) => (
              <VariableSizeList
                height={height}
                innerElementType={innerElementType}
                innerRef={setInnerRef}
                itemCount={itemData.length}
                itemData={itemData}
                itemKey={i => itemData[i]?.tech?.id || i}
                itemSize={i => selectLaneHeight(i, itemData)}
                outerRef={handleSetOuterRef}
                overscanCount={10}
                ref={newRef => {
                  listRef.current = newRef;
                  ref(newRef);
                }}
                width={width}
                onItemsRendered={onItemsRendered}
              >
                {SwimLane}
              </VariableSizeList>
            )}
          </InfiniteLoader>
        </BoardContext.Provider>
      )}
    </AutoSizer>
  );
};

DailyView.propTypes = {
  // eslint-disable-next-line react/require-default-props
  day: string,
  clearVisitRowHover: func.isRequired,
  filterBy: func.isRequired,
  techsResponse: TECHS_RESPONSE_PROP.isRequired,
  visitTransitionTuple: array.isRequired
};

export default memo(DailyView);
