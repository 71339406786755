import React, { useEffect, useState } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  layout,
  purchaseOrderCostFields
} from 'meta/Procurement/PurchaseOrders/purchaseOrderCostForm';
import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';
import buildHeroMuiFormOverrides from 'scenes/ProjectManagement/components/buildHeroMuiFormOverrides';
import { generateDefaultValidationSchema } from 'scenes/ProjectManagement/components/formattingUtils';
import { getContentText } from 'scenes/ProjectManagement/components/utils';
import { getTaxRateById } from 'services/API/taxRate';
import { roundCurrency } from 'utils';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import MuiFormSectionTitle from '../MuiFormSectionTitle';

const useStyles = makeStyles(theme => ({
  root: {
    width: 219,
    border: '1px solid #CCCCCC',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0px 16px 0px'
  },
  titleContainer: {
    margin: 8
  },
  itemContainer: {
    margin: 8,
    display: 'flex',
    justifyContent: 'space-between'
  },
  indicator: {
    fontSize: 11
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: '-0.03em'
  },
  content: {
    fontWeight: 700,
    fontSize: 14,
    letterSpacing: '-0.03em',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'zero' on, 'salt' on, 'ss01' on"
  },
  divider: {
    width: '100%',
    height: 1,
    background: theme.palette.grayscale(20)
  },
  bold: {
    fontWeight: 700
  },
  formContainer: buildHeroMuiFormOverrides(theme)
}));

const PurchaseOrderCost = props => {
  const classes = useStyles();
  const {
    formLayout,
    lineItems,
    freightCosts,
    useCalculatedAmt,
    taxRateId,
    taxRate,
    taxFromDB,
    totalCostFromDB,
    onFormChange,
    getHandleCreateService,
    getHandleComplete,
    useMultipleForm
  } = props;
  const flags = useFlags();
  const tenantSettings = useSelector(s => s.settings);
  const { accountingApp } = tenantSettings;
  const accountingIntegrationEnabled = !!accountingApp;
  const showBillTaxAndTotalFromAccountingSystem =
    flags[FeatureFlags.SHOW_BILL_TAX_AND_TOTAL_FROM_ACCOUNTING_SYSTEM] &&
    accountingIntegrationEnabled &&
    !useCalculatedAmt;

  const [freightCost, setFreightCost] = useState();
  const [subtotal, setSubtotal] = useState();
  const [taxRateObject, setTaxRateObject] = useState(taxRate || null);
  const [finalTaxRate, setFinalTaxRate] = useState(taxRate?.taxRate || 0);
  const [taxAmount, setTaxAmount] = useState();
  const [total, setTotal] = useState(0);
  const [formData, setFormData] = useState({});
  const [showAccountingIndicator, setShowAccountingIndicator] = useState(false);

  useEffect(() => {
    if (freightCosts) {
      setFreightCost(parseFloat(freightCosts));
    }
  }, [freightCosts]);

  useEffect(() => {
    if (!taxRateId) return;

    const getTaxRate = async () => {
      if (taxRate?.id && taxRate?.taxRate) {
        setFinalTaxRate(parseFloat(parseFloat(taxRate.taxRate) / 100));
        setTaxRateObject(taxRateObject || taxRate);
      } else {
        const result = await getTaxRateById(taxRateId);
        setFinalTaxRate(parseFloat(parseFloat(result.taxRate) / 100));
        setTaxRateObject(result);
      }
    };
    getTaxRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxRateId]);

  useEffect(() => {
    const getInitTotal = () => {
      /*
      let lineItemsTotal = 0;
      let taxableTotal = 0;
      if (lineItems?.length > 0) {
        lineItemsTotal = lineItems
          .map(line => line.quantity * line.unitCost)
          .reduce((prev, next) => prev + next);
        const taxableItems = lineItems.filter(line => line.taxable);
        if (taxableItems?.length) {
          taxableTotal = taxableItems
            .map(line => line.quantity * line.unitCost)
            .reduce((prev, next) => prev + next);
        }
      }
      const newTaxAmount = taxableTotal * finalTaxRate;
      const newTotal = lineItemsTotal + newTaxAmount + (freightCost || 0);
*/

      let lineItemsTotal = 0;
      let newTaxAmount = 0;
      const taxableItems = lineItems?.filter(line => line.taxable);

      if (lineItems?.length > 0) {
        lineItemsTotal = lineItems
          .map(line =>
            roundCurrency(parseFloat(line.quantity || 0) * parseFloat(line.unitCost || 0))
          )
          .reduce((prev, next) => prev + next);
        if (taxableItems?.length && finalTaxRate > 0.0) {
          newTaxAmount += taxableItems
            .map(line =>
              parseFloat(
                roundCurrency(parseFloat(line.quantity || 0) * parseFloat(line.unitCost || 0))
              )
            )
            .reduce((prev, next) => prev + next);
          newTaxAmount = roundCurrency(finalTaxRate * newTaxAmount);
        }
      }

      newTaxAmount = roundCurrency(newTaxAmount);
      const newTotal = lineItemsTotal + newTaxAmount + Number(freightCost || '0.0');

      setTotal(useCalculatedAmt ? newTotal : totalCostFromDB);
      setSubtotal(lineItemsTotal);
      setTaxAmount(useCalculatedAmt ? newTaxAmount : taxFromDB);
      if (
        (showBillTaxAndTotalFromAccountingSystem && taxFromDB !== newTaxAmount) ||
        (showBillTaxAndTotalFromAccountingSystem && totalCostFromDB !== newTotal)
      ) {
        setShowAccountingIndicator(true);
      } else if (showAccountingIndicator) {
        setShowAccountingIndicator(false);
      }

      if (formLayout === 'edit') {
        onFormChange({
          freightCost,
          taxRateId: taxRateObject?.id || '',
          taxRate: taxRateObject,
          subtotal: lineItemsTotal,
          taxAmount: newTaxAmount,
          newTotal
        });
      }
    };

    getInitTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freightCost, lineItems, finalTaxRate, totalCostFromDB, taxFromDB]);

  const getFormattedData = () => {
    return {
      freightCost: freightCost || 0,
      taxRateId: taxRateId || taxRateObject?.id || '',
      taxRate: taxRateObject,
      subtotal: subtotal || 0,
      taxAmount,
      total
    };
  };

  useEffect(() => {
    setFormData(getFormattedData());
  }, [freightCost, taxRateId, taxRateObject, subtotal, taxAmount, total]);

  const handleChangeFeightCost = ({ form, currentValue }) => {
    const newFeightCost = roundCurrency(currentValue);
    form.setFieldValue('freightCost', newFeightCost);
    setFreightCost(newFeightCost);
  };

  const handleChangeTaxRate = selection => {
    if (selection) {
      const selectedTaxRate = parseFloat(selection.taxRate / 100) || 0;
      setTaxRateObject(selection);
      setFinalTaxRate(selectedTaxRate);
    } else {
      // we dont have a valid tax rate make it 0
      setTaxRateObject(null);
      setFinalTaxRate(0);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <MuiFormSectionTitle options={{ label: 'Purchase Order Cost' }} />
      </div>
      {formLayout === 'edit' ? (
        <div className={classes.formContainer}>
          <MUIForm
            configuration={layout({ handleChangeFeightCost, handleChangeTaxRate })}
            customComponents={{ SearchBar }}
            data={formData}
            layout={formLayout}
            validationSchema={generateDefaultValidationSchema(purchaseOrderCostFields)}
            onComplete={useMultipleForm ? getHandleComplete('purchaseOrderCost') : () => {}}
            onCreateService={
              useMultipleForm ? getHandleCreateService('purchaseOrderCost') : () => {}
            }
          />
        </div>
      ) : (
        <>
          <div className={classes.itemContainer}>
            <Typography className={classes.label}>Freight Cost</Typography>
            <Typography className={classes.content}>
              {getContentText('currency', freightCost || 0, 2)}
            </Typography>
          </div>
          <div className={classes.itemContainer}>
            <Typography className={classes.label}>Tax Rate</Typography>
            <Typography className={classes.content}>
              {getContentText('percentage', finalTaxRate * 100, 3)}
            </Typography>
          </div>
        </>
      )}
      <div className={classes.itemContainer}>
        <Typography className={classes.label}>Subtotal</Typography>
        <Typography className={classes.content}>
          {getContentText('currency', Number.isNaN(subtotal) ? 0 : subtotal, 2)}
        </Typography>
      </div>
      <div className={classes.itemContainer}>
        <Typography className={classes.label}>Tax Amount</Typography>
        <Typography className={classes.content}>
          {getContentText('currency', Number.isNaN(taxAmount) ? 0 : taxAmount, 2)}
        </Typography>
      </div>
      <div className={classes.itemContainer}>
        <Divider className={classes.divider} />
      </div>
      <div className={classes.itemContainer}>
        <Typography className={classNames(classes.label, classes.bold)}>Total</Typography>
        <Typography className={classes.content}>{getContentText('currency', total, 2)}</Typography>
      </div>
      {showAccountingIndicator && (
        <div className={classes.itemContainer}>
          <Typography className={classes.indicator}>
            Tax Amount and Total have been updated by your accounting system
          </Typography>
        </div>
      )}
    </div>
  );
};

PurchaseOrderCost.propTypes = {
  formLayout: PropTypes.string.isRequired,
  useCalculatedAmt: PropTypes.bool,
  lineItems: PropTypes.array.isRequired,
  freightCosts: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  taxRateId: PropTypes.string.isRequired,
  taxRate: PropTypes.object,
  onFormChange: PropTypes.func,
  getHandleCreateService: PropTypes.func,
  getHandleComplete: PropTypes.func,
  useMultipleForm: PropTypes.bool,
  taxFromDB: PropTypes.number,
  totalCostFromDB: PropTypes.number
};

PurchaseOrderCost.defaultProps = {
  useCalculatedAmt: false,
  taxRate: null,
  onFormChange: () => {},
  getHandleCreateService: () => {},
  getHandleComplete: () => {},
  useMultipleForm: false,
  taxFromDB: null,
  totalCostFromDB: null
};

export default PurchaseOrderCost;
