import useExtendedMutation from 'customHooks/useExtendedMutation';
import addPurchaseOrderReceipt from 'services/core/graphql/review-report/mutations/AddPurchaseOrderReceipt';

import { GET_REVIEW_REPORT_BY_ID } from '../queries';

export const useAddReviewReportPurchaseOrderReceipt = reviewReportId =>
  useExtendedMutation(addPurchaseOrderReceipt, {
    serializer: ({ tenantId, purchaseOrderId, purchaseOrderReceipts }) => ({
      variables: {
        partitionKey: tenantId,
        data: {
          purchaseOrderId,
          purchaseOrderReceipts
        }
      }
    }),
    update: (cache, { data: { addPurchaseOrderReceipts } }) => {
      if (!addPurchaseOrderReceipts?.length) {
        return;
      }

      const cachedData = cache.readQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        }
      });

      cache.writeQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        },
        data: {
          getReviewReportById: {
            ...(cachedData?.getReviewReportById || {}),
            purchaseOrders: {
              __typename: cachedData.getReviewReportById?.purchaseOrders?.__typename,
              items: cachedData.getReviewReportById?.purchaseOrders?.items?.map(purchaseOrder => {
                if (purchaseOrder.id === addPurchaseOrderReceipts?.[0]?.parentId) {
                  return {
                    ...purchaseOrder,
                    purchaseOrderReceipts: {
                      __typename: 'PurchaseOrderReceiptConnection',
                      items: addPurchaseOrderReceipts
                    }
                  };
                }
                return purchaseOrder;
              })
            }
          }
        }
      });
    }
  });
