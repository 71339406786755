import { useTheme } from '@emotion/react';

import { DEFAULT_COLUMN_WIDTH, ROW_HEIGHT } from '../WrapTable.constants';

const noWrapCss = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
};

const flexAlignMap = {
  center: 'center',
  right: 'flex-end'
};

export const useColumnStyles = ({ colDef, scrollX, lockRowHeight, groupExpanded, ...props }) => {
  const { palette } = useTheme();
  const {
    flex,
    minWidth,
    maxWidth,
    noWrap,
    width,
    align,
    justify,
    noPadding,
    cellCss = {},
    overflow = 'hidden'
  } = colDef;

  const shouldNotWrap = props.noWrap || lockRowHeight || noWrap;
  const overriddenCss = typeof cellCss === 'function' ? cellCss(props) : cellCss;

  return {
    column: {
      paddingTop: noPadding ? 0 : 12,
      paddingBottom: noPadding ? 0 : 12,
      paddingRight: noPadding ? 0 : 8,
      paddingLeft: noPadding ? 0 : 8,
      boxSizing: 'border-box',
      borderLeft: `1px solid ${palette.grayscale(90)}`,
      borderBottom: `1px solid ${palette.grayscale(90)}`,
      display: 'flex',
      alignItems: flexAlignMap[align],
      justifyContent: justify,
      flex: flex || 1,
      width: width || DEFAULT_COLUMN_WIDTH,
      flexBasis: width || DEFAULT_COLUMN_WIDTH,
      flexShrink: scrollX ? 0 : 1,
      minWidth: minWidth || width,
      maxWidth: maxWidth || width,
      maxHeight: lockRowHeight ? ROW_HEIGHT : undefined,
      overflow,
      '& *': {
        fontWeight: groupExpanded ? 600 : undefined,
        ...(shouldNotWrap ? noWrapCss : { overflowWrap: 'anywhere' }),
        textAlign: align
      },
      ...overriddenCss,
      '& [class*="MuiInput-root"]': {
        minHeight: ROW_HEIGHT - 1,
        borderRadius: 0,
        border: 'none',
        backgroundColor: palette.grayscale(96),
        '&:focus-within': {
          border: 'solid 2px #333',
          backgroundColor: 'white'
        }
      },
      '& [class*="Mui-disabled"]': {
        backgroundColor: 'white',
        color: 'black'
      }
    },
    error: {
      backgroundColor: palette.support.red.light,
      p: {
        color: palette.support.red.dark
      }
    },
    expandButton: {
      marginTop: -8,
      marginBottom: -8,
      marginRight: 8
    }
  };
};

export const useLoadingColumnStyles = ({ flex, minWidth, maxWidth, width, scrollX }) => ({
  loadingColumn: ({ palette }) => ({
    boxSizing: 'border-box',
    borderLeft: `1px solid ${palette.grayscale(90)}`,
    borderBottom: `1px solid ${palette.grayscale(90)}`,
    display: 'flex',
    flexDirection: 'column',
    flex: flex || 1,
    width: width || DEFAULT_COLUMN_WIDTH,
    flexBasis: width || DEFAULT_COLUMN_WIDTH,
    flexShrink: scrollX ? 0 : 1,
    minWidth: minWidth || width,
    maxWidth: maxWidth || width,
    maxHeight: ROW_HEIGHT
  })
});
