import React from 'react';

import { FieldType, PDFComponents, ThemeProvider, TV, TW } from '@BuildHero/sergeant';
import { View } from '@react-pdf/renderer';

const PurchasedView = ({ field, props, options }) => {
  const displayValue = field.value || [];
  if (displayValue.length === 0 || !Array.isArray(displayValue)) return null;
  const purchaseOrders = displayValue;
  const tableProps = options ?? props;
  const style = { flexGrow: 3 };

  return (
    <ThemeProvider>
      <View style={{ flexDirection: 'column' }}>
        {purchaseOrders.map(
          ({
            purchaseOrderLines,
            addedBy,
            vendorName,
            poNumber,
            dateOfPurchase,
            receiptNumber
          }) => {
            const POLabel = `Purchase Order ${poNumber}`;
            return (
              <View key={`view_${poNumber}`} style={{ marginBottom: 12 }}>
                <View wrap={false}>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingVertical: 4
                    }}
                  >
                    <PDFComponents.Typography
                      props={{ value: POLabel, variant: TV.PRINT, weight: TW.BOLD }}
                    />
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingVertical: 4
                    }}
                  >
                    <PDFComponents.Field props={{ label: 'ADDED BY', value: addedBy, style }} />
                    <PDFComponents.Field
                      props={{ label: 'DATE', value: dateOfPurchase, type: FieldType.DATE, style }}
                    />
                    {!tableProps?.hideCost ? (
                      <PDFComponents.Field props={{ label: 'VENDOR', value: vendorName, style }} />
                    ) : null}
                    <PDFComponents.Field
                      props={{ label: 'RECEIPT NUMBER', value: receiptNumber, style }}
                    />
                  </View>
                </View>
                <PDFComponents.Table field={{ value: purchaseOrderLines }} props={tableProps} />
                <PDFComponents.Divider props={{}} />
              </View>
            );
          }
        )}
      </View>
    </ThemeProvider>
  );
};

export default PurchasedView;
