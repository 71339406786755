// This file is for utility constants
import { theme } from '@BuildHero/sergeant';

export const AccountType = {
  AR: 'AR',
  AP: 'AP'
};

export const NetworkStatuses = {
  HAS_UNSAVED_DATA: 'HAS_UNSAVED_DATA',
  READY: 'READY',
  UPDATING: 'UPDATING',
  RETRYING: 'RETRYING',
  ERROR: 'ERROR'
};

export const JobStatus = {
  OPEN: 'Open',
  IN_PROGRESS: 'In Progress',
  ON_HOLD: 'On Hold',
  CANCELED: 'Canceled',
  CONVERTED: 'Converted',
  COMPLETE: 'Complete',
  SCHEDULED: 'Scheduled',
  UNSCHEDULED: 'Unscheduled',
  EXPORTED: 'Exported'
};

export const eventStatusMappings = {
  [JobStatus.OPEN]: 'NEW_VISIT',
  [JobStatus.IN_PROGRESS]: 'ON_CONTINUE',
  [JobStatus.ON_HOLD]: 'ON_WEB_HOLD',
  [JobStatus.CANCELED]: 'ON_CANCEL',
  [JobStatus.COMPLETE]: 'ON_COMPLETE',
  [JobStatus.SCHEDULED]: 'ON_SCHEDULED',
  [JobStatus.UNSCHEDULED]: 'ON_UNSCHEDULED',
  [JobStatus.EXPORTED]: 'ON_EXPORTED'
};

export const allJobStatusActions = {
  NEW_VISIT: 'NEW_VISIT',
  ON_COMPLETE: 'ON_COMPLETE',
  ON_WEB_HOLD: 'ON_WEB_HOLD',
  ON_CANCEL: 'ON_CANCEL',
  ON_CONTINUE: 'ON_CONTINUE',
  ON_REOPEN: 'ON_REOPEN',
  ON_SCHEDULED: 'ON_SCHEDULED',
  ON_UNSCHEDULED: 'ON_UNSCHEDULED'
};

export const entityListFilter = [
  { label: 'Customer', value: 'Customer' },
  { label: 'Property', value: 'CustomerProperty' },
  { label: 'Job', value: 'Job' },
  { label: 'Quote', value: 'Quote' },
  { label: 'Visit', value: 'Visit' },
  { label: 'Schedule', value: 'Schedule' },
  { label: 'CompanyAddress', value: 'CompanyAddress' },
  { label: 'PurchaseOrderLine', value: 'PurchaseOrderLine' },
  { label: 'PurchaseOrder', value: 'PurchaseOrder' },
  { label: 'Attachment', value: 'Attachment' },
  { label: 'Device', value: 'Device' },
  { label: 'Note', value: 'Note' },
  { label: 'Opportunity', value: 'Opportunity' },
  { label: 'CustomerPropertyType', value: 'CustomerPropertyType' },
  { label: 'JobType', value: 'JobType' },
  { label: 'CustomerType', value: 'CustomerType' },
  { label: 'CustomerTag', value: 'CustomerTag' },
  { label: 'TaskType', value: 'TaskType' },
  { label: 'ReviewReport', value: 'ReviewReport' },
  { label: 'Invoice', value: 'Invoice' }
];

export const Mode = {
  EDIT: 'edit',
  EDIT_DRAFT: 'editdraft',
  ENABLE: 'enable',
  VIEW: 'view',
  DELETE: 'delete',
  ADD: 'add',
  DEACTIVATE: 'deactivate',
  ACTIVATE: 'activate',
  RENAME: 'rename',
  NEW: 'new',
  VOID: 'void',
  past: mode => {
    const pastTense = {
      [Mode.EDIT]: 'updated',
      [Mode.ENABLED]: 'enabled',
      [Mode.VIEW]: 'viewed',
      [Mode.DELETE]: 'deleted',
      [Mode.ADD]: 'added',
      [Mode.DEACTIVATE]: 'deactivated',
      [Mode.ACTIVATE]: 'activated',
      [Mode.VOID]: 'void'
    };
    return pastTense[mode];
  }
};

export const QuickAddModal = {
  CREATE_INVOICE: 'CREATE_INVOICE_MODAL',
  CREATE_QUOTE: 'CREATE_QUOTE_MODAL',
  CREATE_JOB: 'CREATE_JOB_MODAL',
  CREATE_VISIT: 'CREATE_VISIT_MODAL',
  CREATE_PAYMENT: 'CREATE_PAYMENT_MODAL',
  CREATE_PRICEBOOK: 'CREATE_PRICEBOOK_MODAL',
  NONE: 'NO_MODAL'
};

export const InvoiceStatus = {
  POSTED: 'posted',
  DRAFT: 'draft',
  CLOSED: 'closed',
  EXPORTED: 'exported',
  VOID: 'void'
};

export const InvoiceStatusColor = {
  [InvoiceStatus.POSTED]: theme.palette.support.orange.medium,
  [InvoiceStatus.DRAFT]: theme.palette.grayscale(80),
  [InvoiceStatus.CLOSED]: theme.palette.support.green.medium,
  [InvoiceStatus.EXPORTED]: theme.palette.support.blue.medium,
  [InvoiceStatus.VOID]: theme.palette.support.red.medium
};

export const PurchaseOrderStatus = {
  DRAFT: 'draft',
  ORDERED: 'ordered',
  VOID: 'void',
  PARTIALLY_FULFILLED: 'partiallyFulfilled',
  FULFILLED: 'fulfilled',
  UNFULFILLED: 'unfulfilled'
};

export const InvoiceItemSourceType = {
  INVOICE: 'INVOICE',
  QUOTE: 'QUOTE',
  VISIT: 'VISIT'
};

export const InventoryPartSourceType = {
  INVOICE: 'INVOICE',
  QUOTE: 'QUOTE',
  AMOUNT_QUOTED: 'AMOUNT_QUOTED',
  VISIT: 'VISIT',
  JOB_REPORT: 'JOB_REPORT'
};

export const LaborLineSourceType = {
  INVOICE: 'INVOICE',
  QUOTE: 'QUOTE',
  VISIT: 'VISIT',
  JOB_REPORT: 'JOB_REPORT'
};

export const InvoiceItemType = {
  INVENTORY_PART: 'InventoryPart',
  PUCHASE_ORDER_LINE: 'PurchaseOrderLine',
  LABOR_LINE_ITEM: 'LaborLineItem',
  DISCOUNT: 'Discount',
  FEE: 'Fee'
};

export const WIPReportStatus = {
  PENDING: 'pending',
  POSTED: 'posted',
  CLOSED: 'closed',
  EXPORTED: 'exported'
};

export const QuoteLineItemType = {
  MATERIAL: 'Material',
  DISCOUNT: 'Discount',
  FEE: 'Fee'
};

export const PaymentStatus = {
  UNAPPLIED: 'Unapplied',
  PARTIALLY_APPLIED: 'PartiallyApplied',
  APPLIED: 'Applied'
};

export const ExportStatus = {
  PENDING: 'Pending',
  POSTED: 'Posted',
  EXPORTED: 'Exported',
  BYPASSED: 'Bypassed',
  CLOSED: 'Closed',
  VOIDED: 'Voided'
};

export const SyncStatus = {
  NOT_SYNCED: 'NotSynced',
  SYNCING: 'Syncing',
  IN_SYNC: 'InSync',
  SYNC_FAILED: 'SyncFailed',
  BYPASSED: 'Bypassed'
};

export const EntityType = {
  MAINTENANCE_TEMPLATE: 'Maintenance Template',
  JOB: 'Job',
  SERVICE_AGREEMENT: 'ServiceAgreement',
  MAINTENANCE_JOB: 'Maintenance',
  REVIEW_REPORT: 'ReviewReport',
  TASK: 'Task',
  PROPERTY: 'Property',
  ASSET: 'PropertyAsset',
  CUSTOMER_PROPERTY: 'CustomerProperty',
  PROPERTY_ADDRESS: 'PropertyAddress',
  CUSTOMER: 'Customer',
  COMPANY_ADDRESS: 'CompanyAddress',
  INVOICE: 'Invoice',
  PAYMENT: 'Payment',
  LINE_ITEM: 'Line Item',
  DISCOUNT: 'Discount',
  QUOTE: 'Quote',
  VENDOR: 'Vendor',
  PRODUCT: 'Product',
  PURCHASE_ORDER_RECEIPT: 'PurchaseOrderReceipt',
  TIMESHEET_ENTRY: 'TimesheetEntry',
  TIMESHEET_ENTRY_BINDER: 'TimesheetEntryBinder'
};

const { JOB, MAINTENANCE_JOB, QUOTE } = EntityType;

export const PropertyEntities = {
  ASSETS: 'propertyAssets',
  JOB,
  MAINTENANCE_JOB,
  QUOTE
};

// currently being used in StatusChip
export const EnumType = {
  ADJUSTMENT_STATUS: 'ADJUSTMENT_STATUS',
  ARCHIVED_STATUS: 'ARCHIVED_STATUS',
  BILL_STATUS: 'BILL_STATUS',
  MAINTENANCE_STATUS: 'MAINTENANCE_STATUS',
  SERVICE_AGREEMENT_STATUS: 'SERVICE_AGREEMENT_STATUS',
  JOB_STATUS: 'JOB_STATUS',
  VISIT_STATUS: 'visitStatus',
  VISIT_REVIEW_STATUS: 'VISIT_REVIEW_STATUS',
  JOB_CLOSEOUT_STATUS: 'JOB_CLOSEOUT_STATUS',
  CUSTOMER_STATUS: 'CUSTOMER_STATUS',
  PROPERTY_STATUS: 'PROPERTY_STATUS',
  TIMESHEET_REVIEW_STATUS: 'TIMESHEET_REVIEW_STATUS',
  QUOTE_STATUS: 'QUOTE_STATUS',
  INVOICE_STATUS: 'INVOICE_STATUS',
  INVOICE_BILLING_STATUS: 'INVOICE_BILLING_STATUS',
  SYNC_STATUS: 'SYNC_STATUS',
  EXPORT_STATUS: 'EXPORT_STATUS',
  PAYMENT_STATUS: 'PAYMENT_STATUS',
  OPEN_TASK_STATUS: 'OPEN_TASK_STATUS',
  PROJECT_MANAGEMENT_SUBMITTAL_STATUS: 'PROJECT_MANAGEMENT_SUBMITTAL_STATUS',
  PROJECT_MANAGEMENT_PAY_APPLICATION_STATUS: 'PROJECT_MANAGEMENT_PAY_APPLICATION_STATUS',
  PROCUMENT_PURCHASE_ORDER_STATUS: 'PROCUMENT_PURCHASE_ORDER_STATUS',
  PROCUMENT_PURCHASE_ORDER_RECEIPT_STATUS: 'PROCUMENT_PURCHASE_ORDER_RECEIPT_STATUS',
  SERVICE_CHANNEL_WORK_ORDER_STATUS: 'SERVICE_CHANNEL_WORK_ORDER_STATUS',
  WIP_REPORT_STATUS: 'WIP_REPORT_STATUS',
  JOB_PROCUREMENT_STATUS: 'JOB_PROCUREMENT_STATUS',
  JOB_QUOTE_STATUS: 'JOB_QUOTE_STATUS',
  JOB_REPORT_STATUS: 'JOB_REPORT_STATUS',
  JOB_BILLING_STATUS: 'JOB_BILLING_STATUS',
  VENDOR_APPROVAL_STATUS: 'VENDOR_APPROVAL_STATUS',
  RECOMMENDED_TASK_STATUS: 'RECOMMENDED_TASK_STATUS',
  SIGNATURE_STATUS: 'SIGNATURE_STATUS',
  JOB_BILLING_TYPE: 'JOB_BILLING_TYPE'
};

export const SIGNATURE_STATUS = {
  COMPLETE: 'Complete',
  PENDING: 'Pending'
};

export const ServiceChannelWorkOrderStatus = {
  Open: 'Open',
  JobCreated: 'Job Created',
  Error: 'Error',
  Discarded: 'Discarded'
};

export const CustomFieldTypes = {
  JobTags: 'JobTag',
  JobTypes: 'JobType',
  MaintenanceTypes: 'MaintenanceType',
  InvoiceTags: 'InvoiceTag',
  PurchaseOrderTags: 'PurchaseOrderTag',
  QuoteTags: 'QuoteTag',
  ServiceAgreementTags: 'ServiceAgreementTag'
};

export const CustomFieldTypeNames = {
  CustomerTags: 'Customer Tags',
  CustomerTypes: 'Customer Types',
  CustomerPropertyTypes: 'Customer Property Types',
  JobTypes: 'Job Types',
  JobTags: 'Job Tags',
  InvoiceTags: 'Invoice Tags',
  PurchaseOrderTags: 'Purchase Order Tags',
  TaskTypes: 'Task Types',
  AssetTypes: 'Asset Types',
  MaintenanceTypes: 'Maintenance Types',
  ServiceAgreementTags: 'Service Agreement Tags',
  QuoteTags: 'Quote Tags',
  ProjectTypes: 'Project Types',
  ProjectSubTypes: 'Project Sub Types',
  ProductCategories: 'Item Category'
};

export const CustomFieldNames = {
  CustomerTags: 'customerTags',
  CustomerTypes: 'customerTypes',
  CustomerPropertyTypes: 'customerPropertyTypes',
  JobTypes: 'jobTypes',
  JobTags: 'jobTags',
  InvoiceTags: 'invoiceTags',
  PurchaseOrderTags: 'purchaseOrderTags',
  TaskTypes: 'taskTypes',
  AssetTypes: 'assetTypes',
  MaintenanceTypes: 'maintenanceTypes',
  ServiceAgreementTags: 'serviceAgreementTags',
  QuoteTags: 'quoteTags',
  ProjectTypes: 'projectTypes',
  ProjectSubTypes: 'projectSubTypes',
  ProductCategories: 'productCategories'
};

export const CustomFieldNameMapping = {
  [CustomFieldTypeNames.CustomerTags]: 'customerTags',
  [CustomFieldTypeNames.CustomerTypes]: 'customerTypes',
  [CustomFieldTypeNames.CustomerPropertyTypes]: 'customerPropertyTypes',
  [CustomFieldTypeNames.JobTypes]: 'jobTypes',
  [CustomFieldTypeNames.JobTags]: 'jobTags',
  [CustomFieldTypeNames.InvoiceTags]: 'invoiceTags',
  [CustomFieldTypeNames.PurchaseOrderTags]: 'purchaseOrderTags',
  [CustomFieldTypeNames.TaskTypes]: 'taskTypes',
  [CustomFieldTypeNames.AssetTypes]: 'assetTypes',
  [CustomFieldTypeNames.MaintenanceTypes]: 'maintenanceTypes',
  [CustomFieldTypeNames.ServiceAgreementTags]: 'serviceAgreementTags',
  [CustomFieldTypeNames.QuoteTags]: 'quoteTags',
  [CustomFieldTypeNames.ProjectTypes]: 'projectTypes',
  [CustomFieldTypeNames.ProjectSubTypes]: 'projectSubTypes',
  [CustomFieldTypeNames.ProductCategories]: 'productCategories'
};

export const AccountingApp = {
  SAGE: 'sage',
  QUICKBOOKS: 'quickbooks',
  RYVIT_VISTA: 'ryvit-vista',
  COMPUTER_EASE: 'computer ease',
  INTACCT: 'intacct',
  SPECTRUM: 'spectrum',
  VISTA: 'vista'
};

export const IntacctDimentionType = {
  ENTITY: 'ENTITY',
  CLASS: 'CLASS',
  DEPARTMENT: 'DEPARTMENT',
  LOCATION: 'LOCATION'
};

export const MaterialMarkupType = {
  NONE: 'None',
  FLAT: 'Flat',
  RANGE: 'Range'
};

export const MarkupType = {
  PERCENTAGE: 'Percentage',
  FLAT: 'Flat'
};

export const filterTypes = {
  INTEGER_FILTERS: 'integerFilters',
  FLOAT_FILTERS: 'floatFilters',
  STRING_FILTERS: 'stringFilters',
  BOOL_FILTERS: 'booleanFilters'
};

export const FormVersion = {
  EDIT: 'edit',
  DEFAULT: 'default'
};

export const VisitCreationType = {
  BULK: 'Bulk',
  MANUAL: 'Manual'
};

export const PathName = {
  JOB: 'job',
  MAINTENANCE: 'maintenance'
};

export const DisplayName = {
  MAINTENANCE: 'Maintenance',
  JOB: 'Job'
};

export const AddressType = {
  BILLING: 'billingAddress',
  PROPERTY: 'propertyAddress',
  COMPANY: 'companyAddress',
  BUSINESS: 'businessAddress'
};

export const ADDRESS_FIELDS = [
  'addressLine1',
  'addressLine2',
  'city',
  'county',
  'state',
  'zipcode',
  'latitude',
  'longitude',
  'billTo',
  'shipTo'
];

export const TenantSetting = {
  ACCOUNTING_APP: 'accountingApp',
  SYNC_IMMEDIATELY: 'syncImmediately',
  TAX_RATE_ASSIGN_DISABLED: 'isAutoassignTaxRateDisabled',
  PRICING_STRATEGY: 'pricingStrategy'
};

export const EnvelopeType = {
  SINGLE: 'Single Pane',
  DOUBLE: 'Double Pane',
  SINGLE_LARGE_LOGO: 'Single Pane - Large Logo'
};

export const CellDataTypes = {
  DATE_ARRAY: 'dateArray'
};

export const ServiceAgreementScheduleTypes = {
  DAILY: 'Daily',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  BIANNUALLY: 'Biannually',
  ANNUALLY: 'Annually'
};

const { DAILY, MONTHLY, QUARTERLY, BIANNUALLY, ANNUALLY } = ServiceAgreementScheduleTypes;

export const momentShortHandMapping = {
  [DAILY]: 'd',
  [MONTHLY]: 'M',
  [QUARTERLY]: 'Q',
  [BIANNUALLY]: 'Q',
  [ANNUALLY]: 'y'
};

export const SergeantButtonTypes = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  LEADING: 'leading',
  ERROR: 'error'
};

export const serviceErrorMessage = {
  TABLE: 'Unable to export the table, please try again later'
};

export const PaymentTermType = {
  INVOICING: 'Invoicing',
  PURCHASING: 'Purchasing',
  BOTH: 'Both'
};

export const TransactionType = {
  WRITE_OFF: 'WriteOff',
  OVERPAYMENT: 'Overpayment',
  REFUND: 'Refund'
};

export const AdjustmentStatus = {
  UNAPPLIED: 'Unapplied',
  PARTIALLY_APPLIED: 'PartiallyApplied',
  APPLIED: 'Applied'
};

export const ArchivedStatus = {
  IS_ARCHIVED: 'Archived'
};

// Backend enum
export const EmailInvoiceType = {
  NonBilling: 'NonBilling',
  EmailTo: 'EmailTo',
  EmailCC: 'EmailCC'
};

// Labels in UI
export const EmailInvoiceTypeLabel = {
  NonBilling: 'None',
  EmailTo: 'Email To',
  EmailCC: 'Email CC'
};

export const BILLING_INFO_TOOLTIP =
  'This box is for 2 lines of info. In the first line, enter the name of the Billing Customer or how they prefer to be addressed. Then hit Enter to go to next line. In the second line, add an "ATTN" entry if applicable.';
export const LABOR_RATE_INFO_TOOLTIP = 'Job specific labor rates';

export const BestContactType = {
  CELLPHONE: 'Cell phone',
  EMAIL: 'Email',
  LANDLINE: 'Landline'
};

export const PermissionAction = {
  ALLOW: 'allow',
  READ: 'read',
  CREATE: 'create',
  UPDATE: 'update'
};

export const PermissionCategory = {
  DATA: 'Data',
  MENU: 'Menu',
  FUNCTIONS: 'Functions',
  OBJECT: 'Object'
};

export const PermissionSubcategory = {
  QUOTE_TO_PO: 'Create Purchase Orders directly on Quotes',
  QUOTES: 'Quotes',
  PURCHASE_ORDER: 'PurchaseOrder',
  PROJECT_MANAGEMENT: 'Project Management'
};

export const JobReviewStatus = {
  NO_REVIEW_NEEDED: 'NO_REVIEW_NEEDED',
  REVIEW_NEEDED: 'REVIEW_NEEDED',
  REVIEWED: 'REVIEWED'
};

export const PricingStrategy = {
  MARKUP: 'MARKUP',
  MARGIN: 'MARGIN',
  MARKUP_AND_MARGIN: 'MARKUP_AND_MARGIN'
};

export const MobileFieldOrders = {
  FIELD_ORDERS_ONLY: 'Allow Field Orders Only',
  REQUISITIONS_ONLY: 'Allow Requisitions Only',
  BOTH_FO_AND_REQUISITION: 'Allow Both'
};

export const uniqueIdentifier = '[buildops]';

export const ADDING_MAINTENANCE_JOBS_STATUS = {
  DONE: 'DONE',
  ADDING: 'ADDING',
  FAILED: 'FAILED'
};

export const TESTING_ID = {
  SEARCH_BAR: 'search-bar',
  ALGOLIA_SEARCH: 'algolia-search',
  DEFAULT_BUTTON: 'default-button'
};

export const DEFAULT_PRICEBOOK_MSG = {
  HIDE: 'Hide msg',
  PROPERTY: 'Initial value from Property',
  CUSTOMER: 'Initial value from Customer'
};
