import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import * as R from 'ramda';

import WrapTable from 'components/WrapTable';
import useServiceAgreement from 'customHooks/useServiceAgreement';

import { useChecklistLibrary } from 'scenes/ChecklistLibrary/hooks/useChecklistLibrary';
import { processAddressArrayAsJson } from 'utils';

import useAdvancedSchedulingServiceAgreementCustomerPropertiesMetadata from '../hooks';

import PropertyAssets from '../PropertyAssets';

const propColumns = [
  {
    field: 'companyName',
    headerName: 'Property',
    minWidth: 120
  },
  {
    field: 'address',
    headerName: 'Property Address',
    minWidth: 150
  },
  {
    field: 'propertyRep',
    headerName: 'Property Representative',
    minWidth: 110
  },
  {
    field: 'primaryTech',
    headerName: 'Primary Technician',
    minWidth: 110
  },
  {
    field: 'additionalTechs',
    headerName: 'Additional Technicians',
    minWidth: 120
  }
];

const makeAddress = address =>
  `${address?.addressLine1}, ${address?.city}, ${address?.state}, ${address?.zipcode}`;

const PropertyAndAssets = ({ serviceAgreementId }) => {
  const [saProps, setSAProps] = useState([]);
  const [saPropMetadata, setSAPropMetadata] = useState([]);

  const { data: checklists = [] } = useChecklistLibrary();

  const [
    {
      // selectedProperties (with assetType and asset options inside)
      customerProperties: { items: serviceAgreementProperties = [] } = {},
      // selected assets
      propertyAssets: { items: serviceAgreementAssets = [] } = {}
    } = {},
    isLoadingServiceAgreement
  ] = useServiceAgreement({
    serviceAgreementId
  });

  useEffect(() => {
    if (!isLoadingServiceAgreement) {
      if (serviceAgreementProperties) {
        // initializes the selected properties
        setSAProps(
          serviceAgreementProperties
            .filter(({ isActive }) => isActive)
            .map(
              ({
                id,
                companyName,
                companyAddresses: { items: addressArray },
                propertyAssets: { items: assets },
                taskTemplateChecklists
              }) => ({
                id,
                companyName,
                address: processAddressArrayAsJson(addressArray).propertyAddress || '',
                assets: assets
                  .filter(({ isActive }) => isActive)
                  .map(({ assetType, ...asset }) => {
                    return {
                      assetTypeName: assetType?.tagName || 'Undefined',
                      assetTypeId: assetType?.id,
                      ...asset
                    };
                  })
                  .sort((a, b) => a.assetTypeName.localeCompare(b.assetTypeName)),
                taskTemplateChecklists
              })
            )
            .sort((a, b) => a.companyName.localeCompare(b.companyName))
        );
      }
    }
  }, [isLoadingServiceAgreement, serviceAgreementProperties, serviceAgreementAssets]);

  const [
    serviceAgreementPropertyMetadata,
    loadingServiceAgreementPropertyMetadata
  ] = useAdvancedSchedulingServiceAgreementCustomerPropertiesMetadata({
    serviceAgreementId
  });

  useEffect(() => {
    if (!loadingServiceAgreementPropertyMetadata) {
      if (serviceAgreementPropertyMetadata) {
        const metadata = serviceAgreementPropertyMetadata?.customerPropertyServiceAgreementsMetadata?.map(
          x => ({
            id: x.id,
            address: makeAddress(
              x.customerProperty.companyAddresses?.items?.find?.(
                y => y.addressType === 'propertyAddress'
              )
            ),
            companyName: x.customerProperty.companyName,
            propertyRep: x.propertyRep?.name ?? '-',
            primaryTech: x.primaryTech?.name ?? '-',
            additionalTechs: x.additionalTechs.map(y => y.employee.name).join(', ') || '-'
          })
        );

        const metadataCompanyNames = R.pluck('companyName', metadata);
        const propData = saProps
          .filter(x => !metadataCompanyNames.includes(x.companyName))
          .map(x => ({
            id: x.id,
            companyName: x.companyName,
            address: x.address,
            propertyRep: '-',
            primaryTech: '-',
            additionalTechs: '-'
          }));

        setSAPropMetadata(
          [...metadata, ...propData].sort((a, b) => a.companyName.localeCompare(b.companyName))
        );
      }
    }
  }, [
    isLoadingServiceAgreement,
    loadingServiceAgreementPropertyMetadata,
    saProps,
    serviceAgreementProperties,
    serviceAgreementPropertyMetadata
  ]);

  return (
    <>
      <WrapTable autoHeight columns={propColumns} hideFooter rows={saPropMetadata} />
      {saProps.map(({ id, label, rightLabel, assets, companyName, address }) => (
        <React.Fragment key={id}>
          <PropertyAssets
            address={address}
            assets={assets}
            checklists={checklists}
            label={label}
            name={companyName}
            rightLabel={rightLabel}
          />
        </React.Fragment>
      ))}
    </>
  );
};

PropertyAndAssets.propTypes = {
  serviceAgreementId: PropTypes.string.isRequired
};

PropertyAndAssets.defaultProps = {};

export default PropertyAndAssets;
