import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';
import { getJcCostTypesByJobJcPhase, getJcPhasesByJob } from 'services/API/jobJcPhaseJcCosttype';
import { getPhaseDeptCostCodes, getPhaseDeptsByPhase } from 'services/API/projectPhaseDepartment';
import { getPhasesByProject } from 'services/API/projectPhases';

import costTypeOptionsForDropdown from './costTypeOptionsDropdown';

const margin = '0px 8px 8px 8px';

const addItemsFields = (associatedProject, areJobCostDetailsRequired) => ({
  itemName: { required: true },
  description: { required: false },
  unitOfMeasure: { required: false },
  unitCost: { required: true },
  quantity: { required: true },
  totalCost: { required: true },
  phaseDepartment: { required: Boolean(associatedProject?.id) },
  projectPhase: { required: Boolean(associatedProject?.id) },
  projectCostCode: { required: Boolean(associatedProject?.id) },
  projectCostType: { required: Boolean(associatedProject?.id) },
  jcPhase: { required: Boolean(areJobCostDetailsRequired) },
  jcCostType: { required: Boolean(areJobCostDetailsRequired) }
});

const addItemsContents = ({
  associatedProject,
  handleChangeQuantity,
  handleChangeUnitCost,
  handleSearchSelect,
  handleSetDefaultValue,
  areJobCostDetailsRequired,
  item,
  selectedPhaseId,
  showLabel
}) => {
  return [
    {
      options: {
        direction: 'row',
        grow: 1,
        flexWrap: 'wrap'
      },
      contents: [
        {
          options: {
            flex: '0 0 auto',
            direction: 'column',
            width: '300px',
            margin: '0px 8px 8px 0px'
          },
          contents: [
            {
              component: {
                edit: 'CustomFieldWithLabel'
              },
              source: 'itemName',
              options: {
                label: showLabel ? 'Item' : '',
                fullWidth: true,
                alignment: 'left'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: '1 1 auto',
            minWidth: '300px',
            margin
          },
          contents: [
            {
              component: {
                edit: 'TextInput'
              },
              source: 'description',
              options: {
                label: showLabel ? 'Description' : '',
                fullWidth: true,
                alignment: 'left'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: '0 0 50px',
            margin
          },
          contents: [
            {
              component: {
                edit: 'CustomFieldWithLabel'
              },
              source: 'unitOfMeasure',
              options: {
                label: showLabel ? 'UOM' : '',
                fullWidth: true,
                alignment: 'left'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: '0 0 80px',
            margin
          },
          contents: [
            {
              component: {
                edit: 'TextInput'
              },
              source: 'quantity',
              options: {
                label: showLabel ? 'Quantity' : '',
                fullWidth: true,
                alignment: 'left',
                type: 'numeric',
                onChange: handleChangeQuantity
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: '0 0 127px',
            margin
          },
          contents: [
            {
              component: {
                edit: 'CurrencyInput'
              },
              source: 'unitCost',
              options: {
                label: showLabel ? 'Unit Cost' : '',
                fullWidth: true,
                alignment: 'left',
                onChange: handleChangeUnitCost,
                allowExcessiveDigits: true
              }
            }
          ]
        },
        ...(areJobCostDetailsRequired && !associatedProject?.id
          ? [
              {
                options: {
                  direction: 'column',
                  flex: '0 0 127px',
                  margin
                },
                contents: [
                  {
                    component: 'SearchBar',
                    source: 'jcPhase',
                    options: {
                      isRequired: true,
                      resultFormatFunction: phase => phase?.name,
                      searchFunction: () => getJcPhasesByJob(item?.jobId),
                      filterFunction: results => {
                        const uniquePhases = Array.from(
                          new Set(results.map(elem => elem.id))
                        ).map(jcPhaseId => results.find(elem => elem.id === jcPhaseId));
                        return uniquePhases;
                      },
                      onSelect: handleSearchSelect,
                      label: 'Job Phase',
                      placeholder: 'Select',
                      fullWidth: true,
                      alignment: 'left',
                      variant: 'standard',
                      color: 'secondary',
                      searchOnOpen: true,
                      setDefaultValue: handleSetDefaultValue,
                      resetDependentDropdownsOnSelection: true
                    }
                  }
                ]
              },
              {
                options: {
                  direction: 'column',
                  flex: '0 0 127px',
                  margin
                },
                contents: [
                  {
                    component: 'SearchBar',
                    source: 'jcCostType',
                    options: {
                      disabled: !item?.jcPhase,
                      isRequired: true,
                      resultFormatFunction: result => result?.name,
                      searchFunction: () =>
                        getJcCostTypesByJobJcPhase(item?.jobId, item?.jcPhase?.id),
                      onSelect: handleSearchSelect,
                      label: 'Cost Type',
                      placeholder: 'Select',
                      fullWidth: true,
                      alignment: 'left',
                      variant: 'standard',
                      color: 'secondary',
                      searchOnOpen: true,
                      setDefaultValue: handleSetDefaultValue,
                      resetDependentDropdownsOnSelection: true
                    }
                  }
                ]
              }
            ]
          : []),
        {
          options: {
            direction: 'column',
            flex: '0 0 120px',
            margin
          },
          contents: [
            {
              component: {
                edit: 'CustomFieldWithLabel'
              },
              source: 'totalCost',
              options: {
                label: showLabel ? 'Total Cost' : '',
                fullWidth: true,
                alignment: 'left',
                type: 'currency'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: '0 0 50px',
            margin
          },
          contents: [
            {
              component: {
                edit: 'CheckboxInput'
              },
              source: 'taxable',
              options: {
                label: showLabel ? 'Taxable' : '',
                labelPlacement: 'top'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: '0 0 24px',
            margin: '0px 0px 8px 8px'
          },
          contents: [
            {
              component: {
                edit: 'CustomMoreAction'
              },
              options: {
                label: showLabel ? 'More' : '',
                fullWidth: true,
                alignment: 'left'
              }
            }
          ]
        }
      ]
    },
    {
      options: {
        direction: 'row',
        grow: 1,
        flexWrap: 'wrap'
      },
      contents: [
        associatedProject?.id
          ? {
              options: {
                direction: 'column',
                flex: '0 0 180px',
                marginLeft: '316px'
              },
              contents: [
                {
                  component: 'SearchBar',
                  source: 'projectPhase',
                  options: {
                    isRequired: true,
                    resultFormatFunction: phase => phase.name,
                    searchFunction: () => getPhasesByProject(associatedProject?.id),
                    onSelect: handleSearchSelect,
                    label: 'Phase',
                    placeholder: 'Select Phase',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary',
                    searchOnOpen: true,
                    setDefaultValue: handleSetDefaultValue,
                    resetDependentDropdownsOnSelection: true
                  }
                }
              ]
            }
          : { options: { minWidth: '0px', display: 'none' }, contents: [] },
        associatedProject?.id
          ? {
              options: {
                direction: 'column',
                flex: '0 0 180px',
                margin
              },
              contents: [
                {
                  component: 'SearchBar',
                  source: 'phaseDepartment',
                  options: {
                    disabled: !item?.projectPhase,
                    isRequired: true,
                    resultFormatFunction: dept => dept.tagName,
                    searchFunction: () =>
                      getPhaseDeptsByPhase(selectedPhaseId || item?.projectPhase?.id),
                    label: 'Department',
                    placeholder: 'Select Department',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary',
                    onSelect: handleSearchSelect,
                    searchOnOpen: true,
                    resetDependentDropdownsOnSelection: true,
                    setDefaultValue: handleSetDefaultValue
                  }
                }
              ]
            }
          : { options: { minWidth: '0px', display: 'none' }, contents: [] },
        associatedProject?.id
          ? {
              options: {
                direction: 'column',
                flex: '0 0 180px',
                margin
              },
              contents: [
                {
                  component: 'SearchBar',
                  source: 'projectCostCode',
                  onSelectionChange: handleSearchSelect,
                  options: {
                    disabled: !item?.phaseDepartment?.id,
                    isRequired: true,
                    resultFormatFunction: costCode => `${costCode.name} - ${costCode.description}`,
                    searchFunction: () => getPhaseDeptCostCodes(item?.phaseDepartment?.id),
                    label: 'Cost Code',
                    placeholder: 'Select Cost Code',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary',
                    onSelect: handleSearchSelect,
                    searchOnOpen: true,
                    resetDependentDropdownsOnSelection: true
                  }
                }
              ]
            }
          : { options: { minWidth: '0px', display: 'none' }, contents: [] },
        associatedProject?.id
          ? {
              options: {
                direction: 'column',
                flex: '0 0 180px',
                margin
              },
              contents: [
                {
                  component: 'SelectInput',
                  source: 'projectCostType',
                  options: {
                    label: 'Cost Type',
                    isRequired: true,
                    fullWidth: false,
                    variant: 'standard',
                    color: 'secondary',
                    valueSet: costTypeOptionsForDropdown(item?.projectCostCode)
                  }
                }
              ]
            }
          : { options: { minWidth: '0px', display: 'none' }, contents: [] }
      ]
    }
  ];
};

const addItemsLayout = ({
  showLabel,
  handleChangeUnitCost,
  handleChangeQuantity,
  areJobCostDetailsRequired,
  item,
  associatedProject,
  handlePhaseSelection,
  handleSearchSelect,
  handleSetDefaultValue,
  selectedPhaseId
}) => {
  return {
    fields: generateDefaultFieldsObject(
      addItemsFields(associatedProject, areJobCostDetailsRequired)
    ),
    layouts: {
      edit: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: addItemsContents({
          associatedProject,
          handleChangeQuantity,
          handleChangeUnitCost,
          handlePhaseSelection,
          handleSearchSelect,
          handleSetDefaultValue,
          areJobCostDetailsRequired,
          item,
          selectedPhaseId,
          showLabel
        })
      }
    }
  };
};

export { addItemsFields, addItemsLayout };
