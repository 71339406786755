import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { inventoryFragment } from 'customHooks/useJob';

const UPDATE_INVENTORY_PART = gql`
  mutation updateInventoryPart(
    $partitionKey: String!
    $data: UpdateInventoryPartInput!
  ) {
    updateInventoryPart(partitionKey: $partitionKey, data: $data) {
      ${inventoryFragment}
    }
  }
`;

const serializer = ({ tenantId, inventoryPart }) => ({
  variables: {
    partitionKey: tenantId,
    data: inventoryPart
  }
});

const optimisticResponseFactory = ({ inventoryPart }) => {
  return {
    updateInventoryPart: {
      ...inventoryPart,
      version: inventoryPart.version + 1
    }
  };
};

export const useUpdateInventoryPart = () => {
  return useExtendedMutation(UPDATE_INVENTORY_PART, {
    serializer,
    optimisticResponseFactory
  });
};
