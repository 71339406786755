import React from 'react';

import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { getContentText } from '../utils';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.grayscale(96),
    borderBottom: '5px solid #fafafa',
    borderColor: theme.palette.grayscale(96),
    minWidth: 150,
    height: 108
  },
  cardContent: {
    paddingTop: 22
  },
  selectedCard: {
    borderBottom: '5px solid #2dce89',
    minWidth: 150,
    height: 108
  },
  title: {
    fontSize: 8,
    color: theme.palette.brand.logoBlue,
    textTransform: 'uppercase',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'zero' on, 'salt' on, 'ss01' on"
  },
  highlightText: {
    fontSize: 32,
    fontWeight: 'bolder',
    marginBottom: 10,
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'zero' on, 'salt' on, 'ss01' on"
  },
  button: {
    display: 'inline',
    margin: 10,
    flexGrow: 1,
    cursor: 'default'
  }
}));

export default function CustomDataCard(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <ButtonBase
      className={classes.button}
      disableRipple
      style={props.style}
      onClick={props.handleClick}
    >
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Grid alignItems="center" container direction="row" justify="center">
            {props.items.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item key={`custom-data-card-container-${index}-${item.title}`} xs={6}>
                <Typography
                  className={classes.highlightText}
                  style={item.warn ? { color: theme.palette.other.scarletRed } : null}
                  variant="h3"
                >
                  {getContentText(item.type, item.highlight)}
                </Typography>
                <Typography className={classes.title}>{item.title}</Typography>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </ButtonBase>
  );
}
