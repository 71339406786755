import React from 'react';

import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.grayscale(96),
    borderBottom: '5px solid #fafafa',
    borderColor: theme.palette.grayscale(96),
    height: 108,
    minWidth: 150
  },
  cardContent: {
    paddingTop: 22
  },
  selectedCard: {
    borderBottom: '5px solid #2dce89',
    height: 108,
    minWidth: 150
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.brand.logoBlue
  },
  highlightText: {
    fontSize: 32,
    fontWeight: 'bolder',
    marginBottom: 10
  },
  button: {
    display: 'inline',
    margin: 10,
    flexGrow: 1
  }
}));

export default function HightlightButtons(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <ButtonBase className={classes.button} onClick={props.handleClick}>
      <Card className={props.selected ? classes.selectedCard : classes.card}>
        <CardContent className={classes.cardContent}>
          <Grid alignItems="center" container direction="row" justify="flex-end">
            <Grid item xs>
              <Typography
                className={classes.highlightText}
                style={props.warn ? { color: theme.palette.other.scarletRed } : null}
                variant="h3"
              >
                {props.highlight}
              </Typography>
              <Typography className={classes.title}>{props.title}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </ButtonBase>
  );
}
