import { removeEmptyValues, removeNestedJson, removeNullValues } from '../../utils';
import InstructionHelper from '../InstructionHelper';

const createScheduleSchemaMapping = visit => {
  // added while processing query result
  const { employee } = visit;
  const processedVisit = removeEmptyValues(removeNullValues(visit));
  const strippedVisit = removeNestedJson(processedVisit);
  const schedule = {
    scheduledFor: visit.scheduledFor,
    startTime: visit.startTime,
    endTime: visit.endTime,
    onRoute: true,
    version: 1,
    employeeSortKey: employee.sortKey,
    entityType: 'Schedule',
    tenantId: visit.tenantId,
    tenantCompanyId: visit.tenantCompanyId
  };

  const data = [{ ...strippedVisit }, { ...schedule }];
  const ins = new InstructionHelper();
  const temp = ins.oneToMany(0, 1);

  const { instructionSet } = temp;
  const ret = { data: JSON.stringify(data), instructionSet: JSON.stringify(instructionSet) };
  return ret;
};

export default createScheduleSchemaMapping;
