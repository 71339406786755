import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';

import { getFileExtension } from 'utils';

import { getFormattedForms } from './SelectAttachments.utils';

const getAttachmentsByJobId = gql`
  query getAllAttachmentsByJobId($jobId: String!) {
    getJobById(id: $jobId) {
      id
      jobNumber
      customIdentifier
      visits(entityConnection: "Visit") {
        items {
          id
          formData(entityConnection: "FormData") {
            items {
              id
              formDataJson
              version
              createdDateTime
              lastUpdatedDateTime
              lastUpdatedBy
              sortKey
              form(entityConnection: "form") {
                id
                name
                description
                viewType
              }
            }
          }
        }
      }
      allAttachments(entityConnection: "Attachment") {
        items {
          fileName
          customFileName
          fileUrl
          id
          partitionKey
          sortKey
          hierarchy
          type
          entityType
          description
          comment
        }
      }
      formData(entityConnection: "FormData") {
        items {
          id
          formDataJson
          form(entityConnection: "form") {
            id
            name
            description
            viewType
          }
        }
      }
    }
  }
`;

export const useGetJobAttachments = jobId => {
  return useExtendedQuery(getAttachmentsByJobId, {
    variables: {
      jobId
    },
    transform: data => {
      const jobData = data?.getJobById;
      const { customIdentifier, allAttachments, formData, id, jobNumber, visits } = jobData;

      // TODO: Support image type documents in v2
      const pdfDocument =
        allAttachments?.items?.filter(document => getFileExtension(document.fileName) === 'pdf') ||
        [];

      const formattedForms = getFormattedForms(formData, visits);

      return {
        jobDocuments: pdfDocument,
        jobForms: formattedForms,
        customIdentifier,
        id,
        jobNumber,
        visits
      };
    },
    loadingData: {
      id: undefined,
      jobNumber: undefined,
      jobDocuments: [],
      jobForms: [],
      customIdentifier: undefined,
      visits: []
    }
  });
};

export default useGetJobAttachments;
