import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { logErrorWithCallback } from 'utils';

export const getTimesheetSignature = gql`
  query getTimesheetSignature($employeeId: String!, $startDayCompanyTZ: BigInt!) {
    getTimesheetSignature(employeeId: $employeeId, startDayCompanyTZ: $startDayCompanyTZ) {
      id
      entityType
      tenantId
      tenantCompanyId
      employeeId
      startDayCompanyTZ
      attachmentId
      signatureImageUrl
      lastUpdatedDate
    }
  }
`;

const useTimesheetSignature = ({ employeeId, startDayCompanyTZ }, options = {}) => {
  const { data, loading, refetch } = useExtendedQuery(getTimesheetSignature, {
    variables: { employeeId, startDayCompanyTZ },
    transform: result => result?.getTimesheetSignature,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !employeeId || !startDayCompanyTZ,
    ...options,
    onError: (error, snackbar) => {
      logErrorWithCallback(error, snackbar, 'Unable to fetch timesheet signature');
    }
  });

  return [data || [], loading, refetch];
};

export default useTimesheetSignature;
