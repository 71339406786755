import React from 'react';

import { Button, ButtonType, FieldType, formatValue } from '@BuildHero/sergeant';
import EditIcon from '@material-ui/icons/Edit';
import { getGridNumericColumnOperators, getGridStringOperators } from '@mui/x-data-grid-pro';
import { Link } from 'react-router-dom';

import StatusChip from 'components/StatusChip';

import { formatAddress, toTitleCase } from 'utils';
import { JobTypes, StatusValToLabelMapping } from 'utils/AppConstants';

import EnumFilterInput, { EnumMultiSelectFilterInput } from './FilterInputs/EnumFilterInput';

export const ColumnType = {
  ...FieldType,
  ADDRESS: 'address',
  ENUM: 'enum',
  TAG: 'tag',
  TAGS: 'tags',
  EDITLINK: 'editLink'
};

export const operatorLabel = {
  '=': 'is equal to',
  '!=': 'is not equal to',
  '<': 'is less than',
  '>': 'is greater than',
  '>=': 'is equal or greater than',
  '<=': 'is equal or less than',
  isEmpty: 'is empty',
  isNotEmpty: 'is not empty',
  contains: 'contains',
  notContains: "doesn't contain",
  in: 'is in',
  notIn: 'is not in'
};

export const enumFilterOperators = getGridStringOperators()
  .filter(o => ['isEmpty', 'isNotEmpty', 'contains'].includes(o.value))
  .map(o =>
    o.value === 'contains'
      ? [
          {
            ...o,
            InputComponent: EnumFilterInput
          },
          {
            ...o,
            label: operatorLabel.notContains,
            value: 'notContains',
            InputComponent: EnumFilterInput
          },
          {
            ...o,
            label: operatorLabel.in,
            value: 'in',
            InputComponent: EnumMultiSelectFilterInput
          },
          {
            ...o,
            label: operatorLabel.notIn,
            value: 'notIn',
            InputComponent: EnumMultiSelectFilterInput
          }
        ]
      : o
  )
  .flat();

const numberWordLabelsFilterOperators = getGridNumericColumnOperators().map(o => {
  return { ...o, label: operatorLabel[o.label] };
});

export const column = {
  [FieldType.NUMBER]: {
    type: 'number',
    filterOperators: numberWordLabelsFilterOperators
  },
  [FieldType.CURRENCY]: {
    type: 'number',
    cellClassName: ({ value, colDef }) =>
      colDef.isBalance && value > 0 ? 'MuiDataGrid-cellBoldRed' : 'MuiDataGrid-cellBold',
    filterOperators: numberWordLabelsFilterOperators,
    valueFormatter: ({ value }) => formatValue[FieldType.CURRENCY](value)
  },
  [FieldType.DATE]: {
    type: 'date',
    valueFormatter: ({ value }) => formatValue[FieldType.DATE](value)
  },
  [FieldType.DATETIME]: {
    type: 'datetime',
    valueFormatter: ({ value }) => formatValue[FieldType.DATETIME](value)
  },
  [FieldType.PERCENTAGE]: {
    type: 'number',
    valueFormatter: ({ value }) => formatValue[FieldType.PERCENTAGE](value)
  },
  [FieldType.CHIP]: {
    type: 'string',
    valueFormatter: ({ value }) => value?.join(', '),
    renderCell: ({ value }) =>
      formatValue[FieldType.CHIP](
        value?.map(i => ({ label: i })),
        {
          containerProps: {
            style: { flexWrap: 'unset', gap: '4px' }
          },
          chipProps: { size: 'small' }
        }
      )
  },
  [FieldType.LINK]: {
    cellClassName: 'MuiDataGrid-cellBold',
    valueFormatter: ({ value }) => value?.label || '',
    renderCell: ({ value, colDef, api }) =>
      formatValue[FieldType.LINK](value, {
        containerProps: {
          style: { flexWrap: 'unset', gap: '8px' }
        },
        linkProps: { testingid: `record-${colDef.field}-${api.getRowIndex()}` }
      }),
    sortComparator: (v1, v2) => v1?.toString().localeCompare(v2?.toString())
  },
  [ColumnType.EDITLINK]: {
    disableExport: true,
    renderCell: ({ value }) => (
      <Button component={Link} endIcon={<EditIcon />} type={ButtonType.LEADING} {...value} />
    )
  },
  [ColumnType.TAG]: {
    type: 'string',
    filterOperators: enumFilterOperators
  },
  [ColumnType.TAGS]: {
    type: 'string',
    filterOperators: enumFilterOperators,
    valueFormatter: ({ value }) =>
      (Array.isArray(value) ? value : [value]).filter(Boolean).join(', '),
    renderCell: ({ value }) =>
      formatValue[FieldType.CHIP](
        (Array.isArray(value) ? value : [value]).filter(Boolean).map?.(i => ({ label: i })),
        {
          containerProps: {
            style: { flexWrap: 'unset', gap: '4px' }
          },
          chipProps: { size: 'small' }
        }
      )
  },
  [ColumnType.ENUM]: {
    type: 'string',
    filterOperators: enumFilterOperators,
    valueFormatter: ({ value }) => toTitleCase(value),
    renderCell: ({ value, colDef }) =>
      value && (
        <StatusChip
          customTheme={colDef.customTheme}
          enumType={colDef.enumType}
          enumValue={value}
          label={StatusValToLabelMapping(colDef.enumType, value) || value}
          noLabelFormat={colDef.noLabelFormat}
          showIcon={colDef.showIcon}
          style={{ borderRadius: 2 }}
        />
      )
  },
  [ColumnType.ADDRESS]: {
    type: 'string',
    valueFormatter: ({ value }) => formatAddress(value)
  },
  [ColumnType.BOOL]: {
    type: 'boolean'
  }
};

export const valueGetters = {
  invoiceLink: ({ value, id }) => {
    return {
      label: value,
      to: `/invoice/view/${id}`
    };
  },
  reviewReportLink: ({ value }) => {
    return {
      label: 'View report',
      to: `/reviewreport/view/${value}`
    };
  },
  techReportLink: ({ value }) => {
    return {
      label: 'View report',
      to: `/technicianreport/view/${value}`
    };
  },
  jobLink: ({ id, value, getValue }) => {
    const jobNumber = getValue(id, 'jobNumber');
    return {
      label: value || jobNumber,
      to: `/job/view/${encodeURIComponent(jobNumber)}`
    };
  },
  customerLink: ({ id, value, getValue, colDef }) => {
    const dataField = colDef?.dataField || 'customerId';
    return {
      label: value,
      to: `/customer/${colDef?.isEditLink ? 'edit' : 'view'}/${getValue(id, dataField)}`
    };
  },
  billingCustomerLink: ({ id, value, getValue }) => {
    return {
      label: value,
      to: `/customer/view/${getValue(id, 'billingCustomerId')}`
    };
  },
  propertyLink: ({ id, value, getValue, colDef }) => {
    const dataField = colDef?.dataField || 'customerPropertyId';
    return {
      label: value,
      to: `/property/${colDef?.isEditLink ? 'edit' : 'view'}/${getValue(id, dataField)}`
    };
  },
  serviceAgreementLink: ({ id, value, getValue, colDef }) => {
    const dataField = colDef?.dataField || 'serviceAgreementId';
    return {
      label: value,
      to: `/serviceAgreement/view/${getValue(id, dataField)}`
    };
  },
  projectLink: ({ id, value, getValue, colDef }) => {
    const dataField = colDef?.dataField || 'projectId';
    return {
      label: value,
      to: `/project/view/${getValue(id, dataField)}`
    };
  },
  purchaseOrderLink: ({ id, value, getValue, colDef }) => {
    const dataField = colDef?.dataField || 'id';
    return {
      label: value,
      to: `/procurement/purchaseorders/view/${getValue(id, dataField)}`
    };
  },
  quoteLink: ({ id, value, getValue, colDef }) => {
    const dataField = colDef?.dataField || 'id';
    return {
      label: value,
      to: `/quote/${getValue(id, dataField)}`
    };
  },
  jobOrMaintenanceLink: ({ id, value, getValue }) => {
    const jobNumber = getValue(id, 'jobNumber');
    const jobTypeInternal = getValue(id, 'jobTypeInternal');

    let jobOrProjectLink;
    if (jobNumber) {
      const isMaintenanceJob = jobTypeInternal === JobTypes.MAINTENANCE;
      jobOrProjectLink = `/${isMaintenanceJob ? 'maintenance' : 'job'}/view/${encodeURIComponent(
        jobNumber
      )}`;
    } else {
      jobOrProjectLink = `/project/view/${getValue(id, 'projectId')}/dashboard`;
    }
    return {
      label: value,
      to: jobOrProjectLink
    };
  },
  receiptLink: ({ id, value, getValue, colDef }) => {
    const dataField = colDef?.dataField || 'id';
    return {
      label: value,
      to: `/procurement/receipts-bills/receipt/view/${getValue(id, dataField)}`
    };
  },
  billLink: ({ id, value, getValue, colDef }) => {
    const dataField = colDef?.dataField || 'id';
    return {
      label: value,
      to: `/procurement/receipts-bills/bill/view/${getValue(id, dataField)}`
    };
  },
  assetLink: ({ id, value, getValue, colDef }) => {
    const dataField = colDef?.dataField || 'id';
    return {
      label: value?.assetName || '',
      to: `/asset/view/${getValue(id, dataField)}`
    };
  },
  formLink: ({ id, value, getValue, colDef }) => {
    const dataField = colDef?.dataField || 'id';
    return {
      label: value,
      to: `/settings/forms/edit/${getValue(id, dataField)}`
    };
  },
  wipLink: ({ id, value, getValue, colDef }) => {
    const dataField = colDef?.dataField || 'id';
    return {
      label: value,
      to: `/wipReports/view/${getValue(id, dataField)}`
    };
  },
  jobVisitLink: ({ row }) => {
    return {
      label:
        (row?.submittedFrom &&
          `Job ${row.submittedFrom.jobIdentifier} - Visit #${row.submittedFrom.visitNumber}`) ||
        '',
      to:
        (row?.submittedFrom && `/job/view/${encodeURIComponent(row.submittedFrom.jobNumber)}`) || ''
    };
  }
};
