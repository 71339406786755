import React from 'react';

import Box from '@material-ui/core/Box';
import {
  DetailsView,
  FileManagerComponent,
  Inject,
  Toolbar
} from '@syncfusion/ej2-react-filemanager';
import { string } from 'prop-types';

import ErrorBoundaries from 'scenes/Error';

import {
  beforeDownload,
  beforeImageLoad,
  beforeSend,
  getFilesApiUrl,
  useJwtToken
} from '../FileManagement.hooks';

import FileViewer from '../FileViewer';

import { useStyles } from './FileManager.styles';

const FileManager = ({ projectId }) => {
  const styles = useStyles();
  const jwtToken = useJwtToken();
  const fsApiUrl = getFilesApiUrl(projectId);
  return (
    <ErrorBoundaries>
      <Box css={styles.root}>
        <div className="control-section">
          {jwtToken && (
            <FileViewer>
              {handleFileOpen => (
                <FileManagerComponent
                  ajaxSettings={{
                    url: fsApiUrl,
                    uploadUrl: `${fsApiUrl}/upload`,
                    downloadUrl: `${fsApiUrl}/download`
                  }}
                  beforeDownload={beforeDownload(jwtToken)}
                  beforeImageLoad={beforeImageLoad}
                  beforeSend={beforeSend(jwtToken)}
                  contextMenuSettings={{
                    folder: ['Open', 'Rename', 'Delete'],
                    file: ['Open', '|', 'Cut', 'Copy', '|', 'Delete', 'Download', 'Rename'],
                    layout: [
                      'SortBy',
                      'View',
                      'Refresh',
                      '|',
                      'Paste',
                      '|',
                      'NewFolder',
                      'Upload',
                      '|',
                      'SelectAll'
                    ]
                  }}
                  fileOpen={handleFileOpen}
                  id="file_storage"
                  toolbarSettings={{
                    items: [
                      'NewFolder',
                      'Upload',
                      'Download',
                      'Rename',
                      'Delete',
                      'SortBy',
                      'Refresh',
                      'Selection',
                      'View'
                    ]
                  }}
                >
                  <Inject services={[DetailsView, Toolbar]} />
                </FileManagerComponent>
              )}
            </FileViewer>
          )}
        </div>
      </Box>
    </ErrorBoundaries>
  );
};

FileManager.propTypes = {
  projectId: string.isRequired
};

export default FileManager;
