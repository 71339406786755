import React, { Component } from 'react';

import { datadogRum } from '@datadog/browser-rum';

import { LicenseInfo } from '@mui/x-data-grid-pro';
import { registerLicense as syncFusionRegisterLicense } from '@syncfusion/ej2-base';
import { withLDConsumer, withLDProvider } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import configForEnvironment from 'configs/aws-exports';

import { ConfirmModalProvider } from 'customHooks/ConfirmModalContext';
import { sentryMessage } from 'services/Logger';

import Context from './components/Context';
import MutationConfirmationModal from './components/MutationConfirmationModal';
import Navigation from './components/Navigation';
import SnackBar from './components/Snackbar';
import Spinner from './components/Spinners';
import ENV, { DD_ENV } from './configs/env';
import MenuData from './meta/Menu';

import {
  alertOff,
  alertOn,
  setLaunchDarklyFlags,
  snackbarOff,
  snackbarOn,
  spinnerOff,
  spinnerOn
} from './redux/actions/globalActions';
import { RouterConfig } from './scenes/Routes';
import './App.css';

datadogRum.init({
  ...DD_ENV,
  site: 'datadoghq.com',
  service: 'buildops-webapp',
  env: ENV,
  sampleRate: 100,
  trackInteractions: true,
  // Connect RUM with prod application monitoring traces. Other environments do not have APM enabled.
  allowedTracingOrigins: [
    'https://graphql.live.buildops.com',
    'https://rest-api.live.buildops.com',
    'https://projectmgmt.live.buildops.com'
  ],
  // lets us see exactly what the user saw on session replay, very useful for finding issues.
  defaultPrivacyLevel: 'allow'
});

datadogRum.startSessionReplayRecording();

const initialLDFlagsLoadingState = {
  loadingFlagsForTenant: '',
  tenant: ''
};

const ldFeatureFilter = ({ menuItem, flags }) => {
  const itemKeys = Object.keys(menuItem);
  if (itemKeys.length !== 1) {
    sentryMessage('Invalid menu item data', { data: JSON.stringify(menuItem) });
    if (itemKeys.length === 0) return menuItem;
  }
  const itemKey = itemKeys[0];

  if (menuItem[itemKey]?.ldFlag && !flags[menuItem[itemKey].ldFlag]) {
    return {};
  }

  if (menuItem[itemKey]?.notLdFlag && flags[menuItem[itemKey].notLdFlag]) {
    return {};
  }

  return {
    [itemKey]: {
      ...menuItem[itemKey],
      menus: Array.isArray(menuItem[itemKey]?.menus)
        ? menuItem[itemKey]?.menus.filter(
            item => !item.ldFlag || (flags[item.ldFlag] && !flags[item.notLdFlag])
          )
        : undefined
    }
  };
};

class App extends Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.callAlert = this.callAlert.bind(this);
    this.fnRejected = this.fnRejected.bind(this);
    this.fnAccept = this.fnAccept.bind(this);
    this.state = {
      context: ''
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { user } = this.props;
    if (user && user.isAuthenticated) {
      if (
        this.props.user.tenantId !== '' &&
        this.props.user.tenantCompanyId !== '' &&
        this.state.context === ''
      ) {
        Context.setCompanyContext(
          this.props.user.tenantId,
          Context.generateCompanyContextSortKey(this.props.user),
          this.setContextvalue,
          false
        );
        Context.subscribeToVisitUpdate({ partitionKey: this.props.user.tenantId });
      }
    } else if (this.state.context !== '') {
      Context.resetCompanyContext();
      this.setContextvalue('');
    }
    LicenseInfo.setLicenseKey(
      '091168382feeed7c660f98dc4fde2ff3T1JERVI6MjUyODEsRVhQSVJZPTE2NTM2MDg1MzYwMDAsS0VZVkVSU0lPTj0x'
    );

    syncFusionRegisterLicense(
      'ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkFjX35Wc3RQR2ReUkQ='
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.isAuthenticated) {
      this.setLDForTenant(this.props.user.tenantId, false, this.state?.context?.getCompany);
    } else {
      const ldAnonId = configForEnvironment(ENV).launchdarklyAnonymousId;
      // ADW prevents launch darkly from tracking anonymous users against our MAU count
      this.setLDForTenant(ldAnonId, true);
    }

    const { user } = prevProps;
    if (this.mounted && user !== this.props.user) {
      if (this.props.user && this.props.user.isAuthenticated) {
        if (
          this.props.user.tenantId !== '' &&
          this.props.user.tenantCompanyId !== '' &&
          this.state.context === ''
        ) {
          Context.setCompanyContext(
            this.props.user.tenantId,
            Context.generateCompanyContextSortKey(this.props.user),
            this.setContextvalue,
            false
          );
        }
      } else if (this.state.context !== '') {
        Context.resetCompanyContext();
        this.setContextvalue('');
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setContextvalue = context => {
    this.setState(prevState => ({ ...prevState, context }));
  };

  raiseNotification(e, mode, message) {
    e.preventDefault();
    this.props.snackbarOn(mode, message);
  }

  fnAccept() {
    this.props.logger('alert accepted', 'info');
    this.props.alertOff();
  }

  fnRejected() {
    this.props.alertOff();
  }

  callAlert() {
    const title = 'Alert title';
    const message = 'Alert box message goes here...';
    const buttonArray = ['Yes, Sure', 'No Cant'];
    const callBackFunctions = [this.fnAccept, this.fnRejected];
    this.props.alertOn(title, message, buttonArray, callBackFunctions);
  }

  async setLDForTenant(id, anonymous = false, companyObj = {}) {
    const { flags, ldClient, launchDarklyFlags, user } = this.props;
    if (!launchDarklyFlags.loading) {
      return this.props.setLaunchDarklyFlags({
        flags,
        loading: {
          ...initialLDFlagsLoadingState
        }
      });
    }

    if (!ldClient || launchDarklyFlags.loading.tenant === id) return;

    if (!isEmpty(flags) && launchDarklyFlags.loading.loadingFlagsForTenant === id) {
      const ldUser = ldClient.getUser();
      if (ldUser.key === launchDarklyFlags.loading.loadingFlagsForTenant) {
        // flags have loaded for the newly signed in tenant.
        return this.props.setLaunchDarklyFlags({
          flags,
          loading: {
            ...initialLDFlagsLoadingState,
            tenant: id
          }
        });
      }
    }

    if (launchDarklyFlags.loading.loadingFlagsForTenant !== id) {
      const { companyName = '', email = '', tenantType = '' } = companyObj;

      // need to identify user which will load flags for the new tenant.
      await ldClient.identify({
        key: id,
        name: companyName,
        email,
        anonymous,
        custom: {
          tenantType
        }
      });

      this.props.setLaunchDarklyFlags({
        flags,
        loading: {
          ...initialLDFlagsLoadingState,
          loadingFlagsForTenant: id
        }
      });
    }
  }

  render() {
    const { menu, user, auth, flags } = this.props;

    // don't load the page until flags data is populated so menu items are
    // loaded properly
    if (!Object.keys(flags).length) return null;

    const menuData = MenuData.en_US[menu.appName].map(menuItem => {
      const f = ldFeatureFilter({ menuItem, flags });
      return f.section?.menus.length === 0 ? {} : f;
    });

    if (user.isAuthenticated) {
      datadogRum.setUser({
        id: user.employeeId,
        name: user.displayName,
        email: user.email,
        tenantId: user.tenantId
      });
      return (
        <BrowserRouter>
          {this.state.context !== '' && (
            <ConfirmModalProvider>
              <Navigation auth={auth} data={menuData}>
                <SnackBar closeAction={this.props.snackbarOff} />
                <MutationConfirmationModal />
                <Spinner type="FAST" />
                <RouterConfig auth={auth} flags={flags} />
              </Navigation>
            </ConfirmModalProvider>
          )}
        </BrowserRouter>
      );
    }
    return (
      <BrowserRouter>
        <SnackBar closeAction={this.props.snackbarOff} />
        <RouterConfig auth={auth} flags={flags} />
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => ({
  application: state.application,
  menu: state.menu,
  user: state.user,
  launchDarklyFlags: state.launchDarklyFlags
});

const mapDispatchToProps = dispatch => ({
  spinnerOn: () => dispatch(spinnerOn()),
  spinnerOff: () => dispatch(spinnerOff()),
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message)),
  snackbarOff: () => dispatch(snackbarOff()),
  alertOff: () => dispatch(alertOff()),
  alertOn: (title, message, buttonArray, callBackFunctions) =>
    dispatch(alertOn(title, message, buttonArray, callBackFunctions)),
  setLaunchDarklyFlags: state => dispatch(setLaunchDarklyFlags(state))
});

const connectedApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withLDProvider({
    clientSideID: configForEnvironment(ENV).launchdarklyClientId,
    user: { key: configForEnvironment(ENV).launchdarklyAnonymousId, anonymous: true }
  })(withLDConsumer()(App))
);

export default connectedApp;
