import React, { useState } from 'react';

import { array, bool, func } from 'prop-types';

import * as R from 'ramda';

import { isImageFile } from '@pm/components/AttachmentsV2/Attachments.helpers';

import AddAttachmentsModal from './components/AddAttachmentsModal';
import AddFilesFromFileManagerModal from './components/AddFilesFromFileManagerModal';

const AddAttachments = ({ open, images, files, onAttachmentsSelected, onClose }) => {
  const [selectedImages, setSelectedImages] = useState(images);
  const [selectedFiles, setSelectedFiles] = useState(files);
  const [filesFromFileManagerModalOpen, setFilesFromFileManagerModalOpen] = useState(false);
  const openFilesFromFileManagerModal = () => setFilesFromFileManagerModalOpen(true);
  const closeFilesFromFileManagerModal = () => setFilesFromFileManagerModalOpen(false);

  const handleAddAttachments = async (data, stopLoading) => {
    try {
      const { images: newImages, files: newFiles } = await onAttachmentsSelected(data);
      setSelectedImages(newImages);
      setSelectedFiles(newFiles);
    } finally {
      stopLoading();
    }
  };

  const handleAddAttachmentsFromFileManager = async (data, stopLoading) => {
    try {
      const [addedImages, addedFiles] = R.partition(isImageFile)(data);
      setSelectedImages([...selectedImages, ...addedImages]);
      setSelectedFiles([...selectedFiles, ...addedFiles]);
    } finally {
      stopLoading();
      closeFilesFromFileManagerModal();
    }
  };

  const disablePrimaryButton =
    !images.length && !files.length && !selectedImages.length && !selectedFiles.length;

  if (!open) {
    return null;
  }

  return (
    <>
      <AddFilesFromFileManagerModal
        files={selectedFiles}
        images={selectedImages}
        open={filesFromFileManagerModalOpen}
        onClose={closeFilesFromFileManagerModal}
        onPrimaryAction={handleAddAttachmentsFromFileManager}
      />
      <AddAttachmentsModal
        disablePrimaryButton={disablePrimaryButton}
        files={selectedFiles}
        images={selectedImages}
        open={open}
        onClose={onClose}
        onFilesSelected={setSelectedFiles}
        onImagesSelected={setSelectedImages}
        onOpenFilesModal={openFilesFromFileManagerModal}
        onPrimaryAction={handleAddAttachments}
      />
    </>
  );
};

AddAttachments.propTypes = {
  open: bool.isRequired,
  images: array.isRequired,
  files: array.isRequired,
  onAttachmentsSelected: func.isRequired,
  onClose: func.isRequired
};

export default AddAttachments;
