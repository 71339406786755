const lineItemOrDiscount = ({
  isDisplayed,
  lineItems,
  ItemType,
  type,
  convertToCurrencyStringCheck,
  styles,
  fontFamilyStyle
}) =>
  isDisplayed && lineItems.length > 0
    ? `${lineItems
        .map(
          item => `
  <tr>
    <td style="${styles}width:50%;border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);" colspan="2" class="restricted">
      <span fontfamily=${fontFamilyStyle} >${item.description || item.name}</span></td>
    <td colspan="3" style="${styles}text-align:right;border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);" class="restricted">
      <span fontfamily=${fontFamilyStyle} >${
            type === ItemType.DISCOUNT
              ? `-${convertToCurrencyStringCheck(item.unitPrice)}`
              : convertToCurrencyStringCheck(item.unitPrice)
          }</span></td>
    </tr>
  `
        )
        .join('')}
  `
    : '';

export default lineItemOrDiscount;
