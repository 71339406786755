import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const DELETE_ATTACHMENT_BY_ID = gql`
  mutation deleteAttachmentById($partitionKey: String!, $id: String!) {
    deleteAttachmentById(partitionKey: $partitionKey, id: $id) {
      id
      fileName
      fileUrl
    }
  }
`;

const serializer = ({ tenantId, id }) => ({
  variables: {
    partitionKey: tenantId,
    id
  }
});

export const useDeleteAttachmentById = () => {
  return useExtendedMutation(DELETE_ATTACHMENT_BY_ID, {
    serializer
  });
};
