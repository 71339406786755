import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

import styles from '../styles';

const ModalHeader = ({
  title,
  iconComponent,
  modalHeaderButtons,
  buttonStyles,
  handleClose,
  classes,
  centerComponent,
  centerComponentStyles
}) => (
  <AppBar className={classes.appBar}>
    <Toolbar className={classes.toolbar}>
      <IconButton aria-label="close" color="inherit" edge="start" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <Grid alignItems="center" container direction="row" justify="space-between">
        <Grid item>
          {(title || iconComponent) && (
            <Typography className={classes.title} gutterBottom variant="h6">
              {iconComponent || null} {typeof title === 'string' ? title : ''}
            </Typography>
          )}
        </Grid>
        <Grid item style={{ flex: 1, ...centerComponentStyles }}>
          {centerComponent}
        </Grid>
        <Grid className={classes.buttonContainer} item style={{ ...buttonStyles }}>
          {modalHeaderButtons}
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar>
);

ModalHeader.propTypes = {
  centerComponent: PropTypes.elementType,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  iconComponent: PropTypes.elementType,
  centerComponentStyles: PropTypes.object,
  modalHeaderButtons: PropTypes.elementType,
  buttonStyles: PropTypes.object
};

ModalHeader.defaultProps = {
  centerComponent: null,
  iconComponent: undefined,
  centerComponentStyles: undefined,
  modalHeaderButtons: undefined,
  buttonStyles: undefined
};

export default withStyles(styles)(ModalHeader);
