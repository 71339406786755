import { withDispatchActions, withQueryStringStore } from '@dispatch/Dispatch.store';

import compose from 'utils/compose';

import DispatchTray from './DispatchTray.component';

const mapQueryStringToProps = store => ({
  tab: store.state.tab,
  openTab: store.openTab
});

const mapActionsToProps = ({ openEvent }) => ({
  openEvent
});

export default compose(
  withQueryStringStore(mapQueryStringToProps),
  withDispatchActions(mapActionsToProps)
)(DispatchTray);
