import moment from 'moment-timezone';
import * as R from 'ramda';

import { TimeCardStatusTypes } from 'utils/AppConstants';

const convertToReportDateTime = (reportDateStartOfDay, time) => {
  if (time) {
    const momentTime = moment.unix(time);
    const seconds = momentTime.diff(momentTime.clone().startOf('day'), 'seconds');
    return reportDateStartOfDay + seconds;
  }
  return undefined;
};

export const serializeProjectTimesheets = ({ projectTimesheets, dailyReportDate }) => {
  return (
    projectTimesheets?.map(item => {
      const startTime = convertToReportDateTime(dailyReportDate, item?.startTime);
      const endTime = convertToReportDateTime(dailyReportDate, item?.endTime);
      return {
        ...item,
        timesheetEntries: item?.timesheetEntries?.map(entry => ({
          ...entry,
          duration: entry?.duration ?? 0
        })),
        startTime,
        endTime
      };
    }) || []
  );
};

export const getTimesheetKey = timesheet =>
  `${timesheet?.employeeId}_${timesheet?.projectPhaseId}_${timesheet?.projectPhaseDepartmentId}_${timesheet?.projectPhaseDepartmentCostCodeId}`;

export const getUniqTimesheetsMap = timesheets =>
  timesheets?.reduce((result, item = {}) => {
    const key = getTimesheetKey(item);
    const duration = R.sum(R.map(entry => entry?.duration ?? 0)(item?.timesheetEntries || []));
    if (!result[key] && duration) {
      return {
        ...result,
        [key]: item.id || item.key
      };
    }
    return result;
  }, {}) || {};

export const getTimesheetStatus = timesheet => {
  const entries = timesheet?.timesheetEntries || [];
  const statusesSet = new Set(R.map(R.prop('manualStatus'), entries));

  if (statusesSet.has(TimeCardStatusTypes.SUBMITTED)) {
    return TimeCardStatusTypes.SUBMITTED;
  }

  if (statusesSet.has(TimeCardStatusTypes.DISPUTED)) {
    return TimeCardStatusTypes.DISPUTED;
  }

  if (statusesSet.has(TimeCardStatusTypes.APPROVED)) {
    return TimeCardStatusTypes.APPROVED;
  }

  return TimeCardStatusTypes.PENDING;
};

export const doesEmployeeHaveApprovedOrPendingTimesheets = (employeeId, timesheets) => {
  const approvedOrPendingEmployeeIds = R.pipe(
    R.filter(
      timesheet =>
        timesheet?.timesheetStatus === TimeCardStatusTypes.APPROVED ||
        timesheet?.timesheetStatus === TimeCardStatusTypes.DISPUTED ||
        timesheet.isDismissed
    ),
    R.map(timesheet => timesheet?.employeeId),
    R.filter(employeeId => Boolean(employeeId)),
    R.uniq
  )(timesheets || []);
  return R.includes(employeeId)(approvedOrPendingEmployeeIds);
};
