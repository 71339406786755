import React, { useEffect, useMemo, useState } from 'react';

import { Divider, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { useFormik } from 'formik';
import { isEqual, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  useBulkUpdateLabourRateGroupEntries,
  useBulkUpdateLabourRateGroups,
  useBulkUpdateLabourTypes
} from '../../../mutations';

import styles from '../../styles';

import SaveButton from '../SaveButton';

import LaborRateGroupsTable from './LaborRateGroupsTable';
import LaborTypesTable from './LaborTypesTable';
import PayrollCostCodesTable from './PayrollCostCodesTable';
import { useFormattedData } from './PayrollCostCodesTable/PayrollCostCodesTable.hooks';

const getDirtyLabourRateGroupEntries = ({ labourRateGroups, updatedCostCodesTable }) =>
  labourRateGroups
    .map(labourRateGroup => {
      const labourRateGroupEntries = labourRateGroup?.labourRateGroupEntries?.items || [];
      return labourRateGroupEntries.reduce((result, entry) => {
        const tableLineItem = updatedCostCodesTable.find(
          item =>
            item.labourRateGroupId === labourRateGroup.id &&
            item.labourTypeId === entry.labourTypeId
        );

        const { hourTypeAbbreviation } = entry.payrollHourType;

        if (
          tableLineItem &&
          tableLineItem[hourTypeAbbreviation]?.costCode?.value &&
          tableLineItem[hourTypeAbbreviation]?.jobCostType?.value &&
          (tableLineItem[hourTypeAbbreviation].costCode.value !== entry.costCodeId ||
            tableLineItem[hourTypeAbbreviation].jobCostType.value !== entry.jobCostTypeId)
        ) {
          return [
            ...result,
            {
              ...entry,
              costCodeId: tableLineItem[hourTypeAbbreviation]?.costCode?.value,
              jobCostTypeId: tableLineItem[hourTypeAbbreviation]?.jobCostType?.value
            }
          ];
        }
        return result;
      }, []);
    })
    .flat();

const LaborRateGroupsAndTypes = ({
  labourRateGroups,
  labourRateGroupsLoading,
  labourTypes,
  labourTypesLoading,
  payrollHourTypes,
  costCodes,
  jobCostTypes,
  isDirty,
  setIsDirty
}) => {
  const user = useSelector(state => state?.user);
  const [laborRateGroupsToUpdate, setLaborRateGroupsToUpdate] = useState(labourRateGroups);
  const [laborTypesToUpdate, setLaborTypesToUpdate] = useState(labourTypes);

  useEffect(() => {
    setLaborRateGroupsToUpdate(labourRateGroups);
  }, [labourRateGroups]);

  useEffect(() => {
    setLaborTypesToUpdate(labourTypes);
  }, [labourTypes]);

  const formattedData = useFormattedData({
    payrollHourTypes,
    labourRateGroups,
    labourTypes
  });
  const payrollHourCostsTableFormik = useFormik({
    enableReinitialize: true,
    initialValues: formattedData
  });

  useEffect(() => {
    setIsDirty(
      !isEqual(labourRateGroups, sortBy(laborRateGroupsToUpdate, 'sortOrder')) ||
        !isEqual(labourTypes, sortBy(laborTypesToUpdate, 'sortOrder')) ||
        payrollHourCostsTableFormik.dirty
    );
  }, [
    laborRateGroupsToUpdate,
    labourRateGroups,
    laborTypesToUpdate,
    labourTypes,
    setIsDirty,
    payrollHourCostsTableFormik.dirty
  ]);

  const [
    bulkUpdateLabourRateGroups,
    { loading: updatingLabourRateGroups }
  ] = useBulkUpdateLabourRateGroups();
  const handleSaveLabourRateGroups = () => {
    bulkUpdateLabourRateGroups({
      companyId: user?.tenantCompanyId,
      labourRateGroups: laborRateGroupsToUpdate
    });
  };

  const [
    bulkUpdateLabourRateGroupEntries,
    { loading: updatingLabourRateGroupEntries }
  ] = useBulkUpdateLabourRateGroupEntries();
  const handleSaveLabourRateGroupEntries = () => {
    if (!payrollHourCostsTableFormik.dirty) return;

    const dirtyEntries = getDirtyLabourRateGroupEntries({
      labourRateGroups,
      updatedCostCodesTable: payrollHourCostsTableFormik.values
    });
    bulkUpdateLabourRateGroupEntries({
      labourRateGroupEntries: dirtyEntries
    });
  };

  const [bulkUpdateLabourTypes, { loading: updatingLabourTypes }] = useBulkUpdateLabourTypes();
  const handleSaveLabourTypes = () => {
    bulkUpdateLabourTypes({
      companyId: user?.tenantCompanyId,
      labourTypes: laborTypesToUpdate
    });
  };

  const costCodeOptions = useMemo(
    () => costCodes.map(code => ({ label: code.name, value: code.id })),
    [costCodes]
  );

  const jobCostTypeOptions = useMemo(
    () => jobCostTypes.map(type => ({ label: type.name, value: type.id })),
    [jobCostTypes]
  );

  return (
    <>
      <ThemeProvider>
        <Typography css={styles.greenHeading} variant={TV.BASE} weight={TW.BOLD}>
          Labor Rate Groups
        </Typography>
        <LaborRateGroupsTable
          laborRateGroups={laborRateGroupsToUpdate}
          loading={updatingLabourRateGroups}
          setLaborRateGroups={setLaborRateGroupsToUpdate}
        />
        <Divider />
        <Typography css={styles.greenHeading} variant={TV.BASE} weight={TW.BOLD}>
          Labor Types
        </Typography>
        <LaborTypesTable
          laborTypes={laborTypesToUpdate}
          loading={updatingLabourTypes}
          setLaborTypes={setLaborTypesToUpdate}
        />
        <Divider />
        <Typography css={styles.greenHeading} variant={TV.BASE} weight={TW.BOLD}>
          Payroll Cost Codes &amp; Types
        </Typography>
        <PayrollCostCodesTable
          costCodeOptions={costCodeOptions}
          formik={payrollHourCostsTableFormik}
          jobCostTypeOptions={jobCostTypeOptions}
          loading={updatingLabourRateGroupEntries || updatingLabourRateGroups}
          payrollHourTypes={payrollHourTypes}
          tableLength={labourRateGroups.length}
        />
      </ThemeProvider>
      <SaveButton
        disabled={!isDirty}
        loading={
          labourRateGroupsLoading ||
          labourTypesLoading ||
          updatingLabourRateGroups ||
          updatingLabourRateGroupEntries ||
          updatingLabourTypes
        }
        onClick={async () => {
          handleSaveLabourRateGroupEntries();
          handleSaveLabourRateGroups();
          handleSaveLabourTypes();
          setIsDirty(false);
        }}
      />
    </>
  );
};

LaborRateGroupsAndTypes.propTypes = {
  labourRateGroups: PropTypes.array,
  labourRateGroupsLoading: PropTypes.bool,
  labourTypes: PropTypes.array,
  labourTypesLoading: PropTypes.bool,
  payrollHourTypes: PropTypes.array,
  costCodes: PropTypes.array,
  jobCostTypes: PropTypes.array,
  isDirty: PropTypes.bool,
  setIsDirty: PropTypes.func
};

LaborRateGroupsAndTypes.defaultProps = {
  labourRateGroups: [],
  labourRateGroupsLoading: true,
  labourTypes: [],
  labourTypesLoading: true,
  payrollHourTypes: [],
  costCodes: [],
  jobCostTypes: [],
  isDirty: false,
  setIsDirty: () => {}
};

export default LaborRateGroupsAndTypes;
