/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

import { sentryException } from '../Logger/index';

export async function phaseCreate(phase) {
  return await instance
    .post('projectphases', phase)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to create Project Phase');
      return [];
    });
}

export async function phaseChange(id, phase) {
  return await instance
    .put(`projectphases/${id}`, phase)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to change Project Phase');
      return [];
    });
}

export async function getPhasesByProject(projectId) {
  return await instance
    .get(`projectphases/byproject/${projectId}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get Project Phase');
      return [];
    });
}

export async function getPhaseById(phaseId) {
  return await instance
    .get(`projectphases/${phaseId}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get Project Phase');
      return [];
    });
}

export async function phaseDelete(phaseId) {
  return await instance
    .delete(`projectPhases/${phaseId}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to delete Project Phase');
      return [];
    });
}
