import React from 'react';

import { shape, string } from 'prop-types';

import { StatusChip } from 'components';
import { JobBillingStatus, StatusValToLabelMapping } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';

import { useStyles } from './JobBillingStatusChip.styles';

const JobBillingStatusChip = ({ job }) => {
  // NOTE: Do not do any override logic on job billing status. This is no longer a thing that is neccesary in any case.
  const styles = useStyles();
  return (
    <StatusChip
      css={styles.chip}
      enumType={EnumType.JOB_BILLING_STATUS}
      enumValue={job.billingStatus ?? JobBillingStatus.NOT_INVOICED}
      label={StatusValToLabelMapping(
        EnumType.JOB_BILLING_STATUS,
        job.billingStatus ?? JobBillingStatus.NOT_INVOICED
      )}
      showIcon
    />
  );
};

JobBillingStatusChip.propTypes = {
  job: shape({
    billingStatus: string.isRequired
  }).isRequired
};

export default JobBillingStatusChip;
