import React from 'react';

import { Button } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.grayscale(20),
    textDecoration: 'underline',
    textDecorationColor: theme.palette.grayscale(20),
    // Unset from Mui Button default
    minWidth: 0,
    '&:hover': {
      color: theme.palette.grayscale(60),
      textDecoration: 'underline',
      textDecorationColor: theme.palette.grayscale(60)
    },
    '&:disabled': {
      color: theme.palette.grayscale(60),
      textDecoration: 'underline',
      textDecorationColor: theme.palette.grayscale(60)
    }
  }
}));

const RouterLink = React.forwardRef((props, ref) => <Link {...props} ref={ref} />);

// `disableLink` prop allows text-style button to be used for things other than links.
// For example, such a button can be used as an inline "show more text" option.
const LinkButton = React.forwardRef(
  ({ label, path, state, classes = {}, disableLink, onClick, ...attr }, ref) => {
    const finalClasses = { ...classes };
    const additionalClasses = useStyles();
    finalClasses.root =
      classes.root?.concat(` ${additionalClasses.root}`) || additionalClasses.root;
    return (
      <Button
        classes={finalClasses}
        component={disableLink ? undefined : RouterLink}
        disableRipple
        ref={ref}
        to={disableLink ? undefined : { pathname: path, state }}
        onClick={disableLink && onClick}
        {...attr}
      >
        {label}
      </Button>
    );
  }
);
export default LinkButton;
