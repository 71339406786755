import { useMemo } from 'react';

import * as R from 'ramda';

import { LineItemBillingStatus } from 'utils/AppConstants';
import { InventoryPartSourceType } from 'utils/constants';
import { getUnitPrice } from 'utils/onCalcChange';

import { roundToNearestCent } from '../../../../utils';
import { filterBillableVisits, filterNonBilledItems } from '../../../JobCloseoutHeader.helpers';
import { TimeAndMaterialsItemsTypes } from '../../CreateInvoice.constants';

const calculateAmount = R.pipe(
  R.map(item => {
    const { unitPrice, unitCost, rate, quantity, totalHours, markup } = item || {};

    // When you have unit Price and quantity, dont need to recompute the unitPrice. As the unit price saved will have some rounding off.
    if (unitPrice && quantity) {
      return roundToNearestCent(unitPrice * quantity);
    }

    if (unitCost && quantity && markup) {
      return roundToNearestCent(unitCost * quantity * (1 + markup / 100));
    }

    return roundToNearestCent((unitPrice ?? unitCost ?? rate ?? 0) * (quantity ?? totalHours ?? 0));
  }),
  R.sum
);

export const useVisits = jobData =>
  useMemo(
    () =>
      R.pipe(
        filterBillableVisits,
        R.map(visit => {
          const inventoryItems = filterNonBilledItems(visit.inventoryParts?.items || []);
          const laborItems = R.pipe(
            R.map(
              labourRateLineItem => labourRateLineItem?.labourRateBillingHourLines?.items || []
            ),
            R.flatten,
            filterNonBilledItems,
            R.filter(
              hourLine =>
                hourLine.totalHours > 0 &&
                !hourLine.timesheetEntry?.timesheetEntryBinder?.isDismissed
            )
          )(visit.labourRateLineItems?.items || []);

          const amount = calculateAmount(inventoryItems) + calculateAmount(laborItems);
          return {
            ...visit,
            amount,
            inventoryItems,
            laborItems
          };
        })
      )(jobData?.visits.items || []),
    [jobData]
  );

export const useGetVisitIds = (visits, selectedVisitIds, type) =>
  useMemo(() => {
    if (type === TimeAndMaterialsItemsTypes.ENTIRE_JOB) return visits.map(v => v.id);
    if (type === TimeAndMaterialsItemsTypes.VISITS) return selectedVisitIds;
    return [];
  }, [selectedVisitIds, type, visits]);

export const useInvoiceTotal = ({ items, pricebookEntries }) =>
  useMemo(() => {
    const pricebookEntryMap = R.reduce((result, priceBookEntry) => {
      return {
        ...result,
        [priceBookEntry?.productSortKey]: priceBookEntry?.markupValue
      };
    }, {})(pricebookEntries || []);

    const lineItems =
      items?.map(item => {
        const { unitCost, quantity, unitPrice, rate, totalHours, remainingQuantity } = item || {};
        const markup = item?.markup ?? pricebookEntryMap[item?.product?.sortKey];
        const calculatedPrice = unitCost && markup ? getUnitPrice(unitCost, markup) : undefined;
        return {
          unitCost,
          // remainingQuantity is a misnomer, should read "received quantity"
          quantity: remainingQuantity ?? quantity,
          unitPrice: unitPrice ?? calculatedPrice,
          rate,
          totalHours,
          markup
        };
      }) || [];

    return calculateAmount(lineItems);
  }, [items, pricebookEntries]);

export const useLineItemsByTypeAndVisits = ({
  jobData,
  visits,
  selectedItemsType,
  selectedVisitIds
}) =>
  useMemo(() => {
    const selectedVisits =
      selectedItemsType === TimeAndMaterialsItemsTypes.VISITS
        ? R.filter(visit => R.includes(visit?.id)(selectedVisitIds))(visits)
        : visits;

    const lineItems = R.map(visit => {
      const { inventoryItems, laborItems } = visit;

      if (selectedItemsType === TimeAndMaterialsItemsTypes.INVENTORY_ITEMS) {
        return { inventoryItems };
      }

      if (selectedItemsType === TimeAndMaterialsItemsTypes.LABOR_ITEMS) {
        return { laborItems };
      }

      if (selectedItemsType === TimeAndMaterialsItemsTypes.PURCHASED_ITEMS) {
        return {};
      }

      return { inventoryItems, laborItems };
    })(selectedVisits);

    const purchasedItems =
      selectedItemsType === TimeAndMaterialsItemsTypes.PURCHASED_ITEMS ||
      selectedItemsType === TimeAndMaterialsItemsTypes.ENTIRE_JOB
        ? filterNonBilledItems(
            jobData?.billLines?.items?.map(item => {
              if (item?.purchaseOrderLine) {
                const { purchaseOrderLine } = item;
                return {
                  ...item,
                  id: purchaseOrderLine?.id,
                  billingStatus: purchaseOrderLine?.billingStatus,
                  unitPrice:
                    typeof purchaseOrderLine.unitPrice === 'number' // this means the markup/unitprice were manually set by the user
                      ? purchaseOrderLine.unitPrice
                      : undefined,
                  quantity: purchaseOrderLine.quantity ?? item.quantity,
                  unitCost: purchaseOrderLine.unitCost ?? item.unitCost,
                  markup: purchaseOrderLine.markup,
                  aggregateRemainingQuantity: true
                };
              }
              return item;
            }) || []
          )
        : [];

    // Filter out all visit inventory parts
    const inventoryItems =
      selectedItemsType === TimeAndMaterialsItemsTypes.INVENTORY_ITEMS ||
      selectedItemsType === TimeAndMaterialsItemsTypes.ENTIRE_JOB
        ? filterNonBilledItems(
            jobData.inventoryParts?.items?.filter?.(
              part => part.source === InventoryPartSourceType.JOB_REPORT
            )
          )
        : [];

    return R.pipe(
      R.filter(item => item?.billingStatus !== LineItemBillingStatus.DO_NOT_INVOICE),
      R.reduce((acc, elem) => {
        if (!elem.aggregateRemainingQuantity) return { ...acc, [elem.id]: elem };
        return {
          ...acc,
          [elem.id]: {
            ...elem,
            // remainingQuantity is a misnomer, in this context it is really the amount recieved and it should be aggregated across all of the billLines if it exists.
            remainingQuantity:
              (elem.remainingQuantity ?? 0) + (acc?.[elem.id]?.remainingQuantity || 0)
          }
        };
      }, {}),
      Object.values
    )([...R.flatten(R.map(R.values, lineItems)), ...purchasedItems, ...inventoryItems]);
  }, [jobData, visits, selectedItemsType, selectedVisitIds]);
