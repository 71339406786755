import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const GENERATE_LABOUR_RATE_LINE_ITEMS_FOR_VISIT = gql`
  mutation GenerateLabourRateLineItemsForVisit(
    $partitionKey: String
    $data: AddLabourRateLineItemsToVisitInput!
  ) {
    generateLabourRateLineItemsForVisit(partitionKey: $partitionKey, data: $data) {
      id
      labourRateBillingHourLines {
        items {
          id
          rate
          version
          hourTypeId
          totalHours
          costCodeId
          revenueTypeId
          productId
        }
      }
    }
  }
`;

const serializer = ({ tenantId, visitId }) => ({
  variables: {
    partitionKey: tenantId,
    data: {
      visitId
    }
  }
});

const update = (cache, { data: { generateLabourRateLineItemsForVisit } }, ref) => {
  cache.modify({
    id: `Visit:${ref.visitId}`,
    fields: {
      labourRateLineItems(existingSummariesRef) {
        const newRefs = generateLabourRateLineItemsForVisit.map(labourRateLineItem => {
          return {
            __ref: `${labourRateLineItem.__typename}:${labourRateLineItem.id}`
          };
        });

        return {
          items: [...existingSummariesRef.items, ...newRefs]
        };
      }
    }
  });
};

export const useGenerateLabourRateLineItemsForVisit = () => {
  return useExtendedMutation(GENERATE_LABOUR_RATE_LINE_ITEMS_FOR_VISIT, {
    serializer,
    update
  });
};
