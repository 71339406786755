import React, { useMemo } from 'react';

import { Typography } from '@BuildHero/sergeant';
import { Checkbox } from '@material-ui/core';
import { noop } from 'lodash';
import { bool, func, object } from 'prop-types';

import { convertToCurrencyString } from 'utils';

import { useStyles } from './QuoteItem.styles';

const QuoteItem = ({ quote, checked, onChange, hideCheckbox, invoices }) => {
  const styles = useStyles();
  const amountAlreadyInvoiced = useMemo(
    () =>
      invoices?.reduce((totalAmount, invoiceItem) => {
        if (invoiceItem.quoteId === quote?.id) {
          return totalAmount + invoiceItem.amount;
        }
        return totalAmount;
      }, 0) || 0,
    [invoices, quote.id]
  );
  const amount = convertToCurrencyString(quote.subTotal - amountAlreadyInvoiced);
  return (
    <div css={styles.rowContainer}>
      <div css={styles.labelContainer}>
        {!hideCheckbox && (
          <Checkbox checked={checked} css={styles.checkbox} onChange={() => onChange(quote.id)} />
        )}
        <Typography>{quote.label}</Typography>
      </div>
      <Typography>{amount}</Typography>
    </div>
  );
};

QuoteItem.propTypes = {
  quote: object.isRequired,
  checked: bool,
  onChange: func,
  hideCheckbox: bool
};

QuoteItem.defaultProps = {
  hideCheckbox: false,
  checked: false,
  onChange: noop
};

export default QuoteItem;
