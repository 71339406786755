import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const GET_SYNC_ERROR_MAPPINGS = gql`
  query syncErrorMappingList($tenantId: String!) {
    syncErrorMappingList(tenantId: $tenantId) {
      items
    }
  }
`;

export const useSyncErrorMappingQuery = tenantId => {
  const [data, setData] = useState(null);

  const {
    loading,
    error,
    data: { syncErrorMappingList: { items: rawData } = {} } = {},
    ...rest
  } = useQuery(GET_SYNC_ERROR_MAPPINGS, {
    variables: { tenantId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !tenantId
  });

  useEffect(() => {
    setData(rawData);
  }, [rawData]);

  return { loading: loading || !data, error, data, ...rest };
};
