import React from 'react';

import { Field, FieldType, TV, Typography } from '@BuildHero/sergeant';
import CheckIcon from '@material-ui/icons/Check';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { orderBy } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';

import { convertToCurrencyString, toTitleCase } from 'utils';
import {
  AppConstants,
  JobProcurementStatus,
  jobQuoteStatus,
  JobTypes,
  ProcurementPurchaseOrderStatus,
  QuoteStatus,
  TimesheetReviewStatusTypes
} from 'utils/AppConstants';

import { PurchaseOrderStatus } from 'utils/constants';

import PageHeaderUtils from '../Quotes/components/PageHeader/index.utils';

import { JobCloseoutTypes } from './constants';

export const getJobCloseoutType = (jobData, jobCloseoutTypeFromUrl = null) => {
  if ((!jobData || isEmpty(jobData)) && jobCloseoutTypeFromUrl) return jobCloseoutTypeFromUrl;

  const { jobTypeInternal, billingType } = jobData;

  if (jobTypeInternal === JobTypes.MAINTENANCE) {
    return JobCloseoutTypes.MAINTENANCE;
  }

  return billingType || JobCloseoutTypes.TIME_AND_MATERIAL;
};

export default getJobCloseoutType;

export const anyItemModifiedByInvoice = (invoices, jobData) => {
  const inventoryParts = jobData.inventoryParts?.items || [];
  const purchaseOrderLines = jobData?.purchaseOrderLines?.items || [];
  const billLines = jobData?.billLines?.items || [];
  const visits = jobData.visits?.items || [];

  const allInvoiceItems = invoices.map(({ invoiceItems }) => invoiceItems?.items || []).flat();

  return allInvoiceItems.some(invoiceItem => {
    const matchingPart = inventoryParts.find(part => part.invoiceItemId === invoiceItem.id);
    if (matchingPart) {
      return (
        invoiceItem?.quantity !== matchingPart?.quantity ||
        invoiceItem?.unitCost !== matchingPart?.unitCost ||
        invoiceItem?.unitPrice !== matchingPart?.unitPrice
      );
    }

    const matchingPOLine = purchaseOrderLines.find(({ id }) => id === invoiceItem.sourceLineItemId);
    if (matchingPOLine) {
      return (
        invoiceItem?.quantity !== matchingPOLine?.quantity ||
        invoiceItem?.unitCost !== matchingPOLine?.unitCost
      );
    }

    const matchingBillLine = billLines.find(({ id }) => id === invoiceItem.sourceLineItemId);
    if (matchingBillLine) {
      return (
        invoiceItem?.quantity !== matchingBillLine?.quantity ||
        invoiceItem?.unitCost !== matchingBillLine?.unitCost
      );
    }

    visits.some(visit => {
      const visitParts = visit.inventoryParts?.items || [];
      const matchingVisitPart = visitParts.find(({ id }) => id === invoiceItem.sourceLineItemId);
      if (matchingVisitPart) {
        return (
          invoiceItem?.quantity !== matchingVisitPart?.totalHours ||
          invoiceItem?.unitPrice !== matchingVisitPart?.rate
        );
      }

      const billingHourLines = (visit.labourRateLineItems?.items || [])
        .map(lineItem => lineItem.labourRateBillingHourLines?.items)
        .flat();
      const matchingBillingHourLine = billingHourLines.find(
        ({ id }) => id === invoiceItem.sourceLineItemId
      );
      if (matchingBillingHourLine) {
        return (
          invoiceItem?.quantity !== matchingBillingHourLine?.quantity ||
          invoiceItem?.unitCost !== matchingBillingHourLine?.unitCost ||
          invoiceItem?.unitPrice !== matchingBillingHourLine?.unitPrice
        );
      }

      return false;
    });

    return false;
  });
};

export const getVisitDisplayText = (visit, companyTimezone) =>
  `Visit ${visit.visitNumber} - ${moment
    .tz(moment.unix(visit.scheduledFor), companyTimezone)
    .format(AppConstants.DATE_FORMAT)}`;

export const getBillableEventDisplayText = (event, companyTimezone) =>
  `Billable Event - ${moment
    .tz(moment.unix(event.plannedStartTimeUTC), companyTimezone)
    .format(AppConstants.DATE_FORMAT)}`;

export const mergeVisitArraysById = (visitsArr, updatedVisitsArr) => {
  return visitsArr.map(visit => {
    const updatedVisit = updatedVisitsArr.find(v => v.id === visit.id);
    if (updatedVisit) {
      return { ...visit, ...updatedVisit };
    }
    return visit;
  });
};

const timeSubmittedIcon = (timesheetManualStatus, duration) => {
  if (!duration || duration === 0) {
    return <></>;
  }

  if (timesheetManualStatus === TimesheetReviewStatusTypes.APPROVED) {
    return <CheckIcon css={{ height: 16, marginLeft: 4 }} />;
  }

  return <HourglassEmptyIcon css={{ height: 16, marginLeft: 4 }} />;
};

export const timeSubmittedDisplay = ({
  timesheetManualStatus,
  employeeId,
  durationDisplayText,
  duration,
  scheduledFor
}) => (
  <div
    css={{
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap'
    }}
  >
    <Field
      type={FieldType.LINK}
      value={{
        label: durationDisplayText,
        to: `/timesheets/${employeeId}/${scheduledFor}`
      }}
    />
    {timeSubmittedIcon(timesheetManualStatus, duration)}
  </div>
);

// custom currency formatter to incorporate numeric Typography
// and allow subtotals to be calculated correctly
export const tableCurrencyFormatter = ({ value }) => (
  <Typography
    css={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}
    numeric
    variant={TV.BASE}
  >
    {convertToCurrencyString(value ?? 0)}
  </Typography>
);

// custom percentage formatter to incorporate numeric Typography
export const tablePercentageFormatter = ({ value }) => (
  <Typography
    css={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}
    numeric
    variant={TV.BASE}
  >
    {value ? `${value}%` : '-'}
  </Typography>
);

export const calculateLaborRate = ({
  labourRates,
  labourTypeId,
  payrollBillingHourTypeId,
  priceBookId
}) =>
  labourRates.find(
    labourRate =>
      labourRate.labourTypeId === labourTypeId &&
      labourRate.billingHourTypeId === payrollBillingHourTypeId &&
      (!priceBookId || priceBookId === labourRate.parentId)
  )?.rate;

// @TODO replace this from utils of Math repo.
export const roundToNearestCent = val => Math.round(val * 100) / 100;

const generateStatusMap = items => {
  const statusMap = {};
  items?.forEach(({ status }) => {
    statusMap[status] ? (statusMap[status] += 1) : (statusMap[status] = 1);
  });
  return statusMap;
};

export const getProcurementStatus = purchaseOrders => {
  const statusMap = generateStatusMap(purchaseOrders);
  if (
    statusMap[ProcurementPurchaseOrderStatus.DRAFT] ||
    statusMap[PurchaseOrderStatus.DRAFT] >= 1
  ) {
    return JobProcurementStatus.POS_IN_DRAFT;
  }
  if (
    statusMap[ProcurementPurchaseOrderStatus.ORDERED] ||
    statusMap[PurchaseOrderStatus.ORDERED] >= 1
  ) {
    return JobProcurementStatus.POS_ORDERED;
  }
  if (
    statusMap[ProcurementPurchaseOrderStatus.PARTIALLY_FULFILLED] ||
    statusMap[PurchaseOrderStatus.PARTIALLY_FULFILLED] >= 1
  ) {
    return JobProcurementStatus.POS_PARTIALLY_RECEIVED;
  }
  if (
    statusMap[ProcurementPurchaseOrderStatus.FULFILLED] ||
    statusMap[PurchaseOrderStatus.FULFILLED] >= 1
  ) {
    return JobProcurementStatus.POS_RECEIVED;
  }
  return JobProcurementStatus.NO_POS;
};

const totalSumOfStatusMaps = statusMap =>
  statusMap?.reduce((acc, status) => {
    return acc + status?.value;
  }, 0);

const getStatusAggregate = (statusMap, status) => statusMap[status] || 0;

export const generateProcurementStatusTooltip = (purchaseOrders, theme) => {
  const statusMap = generateStatusMap(purchaseOrders);
  const POsStatusList = [
    {
      value:
        getStatusAggregate(statusMap, ProcurementPurchaseOrderStatus.DRAFT) +
        getStatusAggregate(statusMap, PurchaseOrderStatus.DRAFT) +
        0,
      label: 'in Draft'
    },
    {
      value:
        getStatusAggregate(statusMap, ProcurementPurchaseOrderStatus.ORDERED) +
        getStatusAggregate(statusMap, PurchaseOrderStatus.ORDERED) +
        0,
      label: 'Ordered'
    },
    {
      value:
        getStatusAggregate(statusMap, ProcurementPurchaseOrderStatus.PARTIALLY_FULFILLED) +
        getStatusAggregate(statusMap, PurchaseOrderStatus.PARTIALLY_FULFILLED) +
        0,
      label: 'Partially Received'
    },
    {
      value:
        getStatusAggregate(statusMap, ProcurementPurchaseOrderStatus.FULFILLED) +
        getStatusAggregate(statusMap, PurchaseOrderStatus.FULFILLED) +
        0,
      label: 'Received'
    },
    {
      value:
        getStatusAggregate(statusMap, ProcurementPurchaseOrderStatus.VOID) +
        getStatusAggregate(statusMap, PurchaseOrderStatus.VOID) +
        0,
      label: 'Void'
    }
  ];
  const total = totalSumOfStatusMaps(POsStatusList) || 0;
  return (
    total > 0 && (
      <>
        <Typography
          css={{
            color: theme?.palette.primary?.contrastText || '#333333',
            fontWeight: 700,
            fontSize: '10px',
            lineHeight: '12px'
          }}
          variant={TV.S2}
        >
          {total} POs Total
        </Typography>
        {POsStatusList.map(
          poStatus =>
            poStatus.value > 0 && (
              <Typography
                css={{
                  color: theme?.palette.primary?.contrastText || '#FFFFFF',
                  fontSize: '10px',
                  lineHeight: '12px'
                }}
                key={`${poStatus.label}-${poStatus.value}`}
                variant={TV.S2}
              >
                {poStatus.value} {poStatus.label}
              </Typography>
            )
        )}
      </>
    )
  );
};
export const generateQuoteStatusTooltip = (quotes, theme) => {
  const quoteItems = quotes && quotes.map(item => item?.quote);
  const statusMap = generateStatusMap(quoteItems);
  const quoteStatusList = [
    {
      value: getStatusAggregate(statusMap, QuoteStatus.CANCELLED),
      label: `in ${toTitleCase(QuoteStatus.CANCELLED)}`
    },
    {
      value: getStatusAggregate(statusMap, QuoteStatus.DRAFT),
      label: `in ${toTitleCase(QuoteStatus.DRAFT)}`
    },
    {
      value:
        getStatusAggregate(statusMap, QuoteStatus.SENT_TO_CUSTOMER) +
        getStatusAggregate(statusMap, QuoteStatus.EMAIL_READ) +
        getStatusAggregate(statusMap, QuoteStatus.CUSTOMER_VIEWED) +
        0,
      label: `in ${toTitleCase(QuoteStatus.SENT_TO_CUSTOMER)}`
    },
    {
      value: getStatusAggregate(statusMap, QuoteStatus.REJECTED),
      label: `in ${toTitleCase(QuoteStatus.REJECTED)}`
    },
    {
      value:
        getStatusAggregate(statusMap, QuoteStatus.APPROVED) +
        getStatusAggregate(statusMap, QuoteStatus.JOB_ADDED) +
        0,
      label: `in ${toTitleCase(QuoteStatus.APPROVED)}`
    }
  ];

  const total = totalSumOfStatusMaps(quoteStatusList) || 0;
  return (
    total > 0 && (
      <>
        <Typography
          css={{
            color: theme?.palette.primary?.contrastText || '#333333',
            fontWeight: 700,
            fontSize: '10px',
            lineHeight: '12px'
          }}
          variant={TV.S2}
        >
          {total} Quotes Total
        </Typography>
        {quoteStatusList.map(
          quoteStatus =>
            quoteStatus.value > 0 && (
              <Typography
                css={{
                  color: theme?.palette.primary?.contrastText || '#FFFFFF',
                  fontSize: '10px',
                  lineHeight: '12px'
                }}
                key={`${quoteStatus.label}-${quoteStatus.value}`}
                variant={TV.S2}
              >
                {quoteStatus.value} {quoteStatus.label}
              </Typography>
            )
        )}
      </>
    )
  );
};

export const getQuoteStatus = quotes => {
  const sortedQuotes = orderBy(
    quotes && quotes.map(item => item?.quote).filter(quote => quote),
    'lastUpdatedDate',
    'desc'
  );

  const statusMap = generateStatusMap(sortedQuotes);
  if (statusMap[QuoteStatus.DRAFT] >= 1) {
    return jobQuoteStatus.QUOTE_IN_DRAFT;
  }
  if (
    statusMap[QuoteStatus.SENT_TO_CUSTOMER] ||
    statusMap[QuoteStatus.EMAIL_READ] ||
    statusMap[QuoteStatus.CUSTOMER_VIEWED] >= 1
  ) {
    return jobQuoteStatus.QUOTE_SENT;
  }
  if (statusMap[QuoteStatus.REJECTED]) {
    return jobQuoteStatus.QUOTE_REJECTED;
  }
  if (statusMap[QuoteStatus.APPROVED] || statusMap[QuoteStatus.JOB_ADDED]) {
    return jobQuoteStatus.QUOTE_APPROVED;
  }
  return jobQuoteStatus.NO_QUOTES;
};

export const generateQuoteHoursTip = (totalHours, quotes) => {
  const quoteItems = quotes?.filter(
    qJ => qJ?.quote?.status === QuoteStatus.APPROVED || qJ?.quote?.status === QuoteStatus.JOB_ADDED
  );

  const quoteTimeMap = quoteItems?.map(quoteItem => {
    const quoteHours =
      PageHeaderUtils.calcTotalBudgetedLaborHours(quoteItem?.quote?.quoteLineTasks, true) || 0;

    return {
      number: quoteItem?.quote?.customIdentifier || quoteItem?.quote?.quoteNumber,
      hours: quoteHours
    };
  });
  return (
    quoteItems?.length > 0 && (
      <>
        <Typography
          css={{
            color: '#FFFFFF',
            fontWeight: 700,
            fontSize: '10px',
            lineHeight: '12px'
          }}
          variant={TV.S2}
        >
          {totalHours} Total Budgeted Hours
        </Typography>
        {quoteTimeMap.map(
          quoteTimeItem =>
            quoteTimeItem.hours > 0 && (
              <Typography
                css={{
                  color: '#FFFFFF',
                  fontSize: '10px',
                  lineHeight: '12px'
                }}
                key={`${quoteTimeItem.hours}-${quoteTimeItem.number}`}
                variant={TV.S2}
              >
                {quoteTimeItem.hours} in Quote {quoteTimeItem.number}
              </Typography>
            )
        )}
      </>
    )
  );
};

export const generateQuotePricingTip = (totalPrice, quotes, priceProp, totalTitle = 'Quoted') => {
  const quoteItems = quotes?.filter(
    qJ => qJ?.quote?.status === QuoteStatus.APPROVED || qJ?.quote?.status === QuoteStatus.JOB_ADDED
  );

  const quotePriceMap = quoteItems?.map(quoteItem => {
    const quotePrice = quoteItem?.quote?.[priceProp] || 0;
    return {
      number: quoteItem?.quote?.customIdentifier || quoteItem?.quote?.quoteNumber,
      price: quotePrice
    };
  });
  return (
    quoteItems?.length > 0 && (
      <>
        <Typography
          css={{
            color: '#FFFFFF',
            fontWeight: 700,
            fontSize: '10px',
            lineHeight: '12px'
          }}
          variant={TV.S2}
        >
          {convertToCurrencyString(totalPrice)} {totalTitle}
        </Typography>
        {quotePriceMap.map(
          quoteTimeItem =>
            quoteTimeItem.price > 0 && (
              <Typography
                css={{
                  color: '#FFFFFF',
                  fontSize: '10px',
                  lineHeight: '12px'
                }}
                key={`${quoteTimeItem.price}-${quoteTimeItem.number}`}
                variant={TV.S2}
              >
                {convertToCurrencyString(quoteTimeItem.price)} in Quote {quoteTimeItem.number}
              </Typography>
            )
        )}
      </>
    )
  );
};
