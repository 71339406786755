import pull from 'lodash/pull';

import { DEFAULT_VISIT_DURATION_IN_MIN } from '@dispatch/Dispatch.constants';
import { DragScenarios } from '@dispatch/dnd';
import { getState } from 'redux/store';

export const serializeDropData = ({ item, timePosition, tech, dragScenario }) => {
  const { srcTech, data } = item;

  switch (dragScenario) {
    case DragScenarios.FULLY_ASSIGNED_SINGLE_TECH: {
      return {
        originalVisit: data,
        inputData: {
          id: data.id,
          scheduledFor: timePosition.unixTime,
          primaryTechId: data.primaryTechId,
          actualDuration: data.actualDuration || DEFAULT_VISIT_DURATION_IN_MIN
        }
      };
    }

    case DragScenarios.UNASSIGNED_SINGLE_TECH: {
      return {
        originalVisit: data,
        inputData: {
          id: data.id,
          scheduledFor: timePosition.unixTime,
          primaryTechId: tech.id,
          actualDuration: data.actualDuration || DEFAULT_VISIT_DURATION_IN_MIN
        }
      };
    }

    case DragScenarios.VISIT_BOARD_TO_BOARD: {
      const srcIsPrimary = srcTech === data.primaryTechId;

      return {
        originalVisit: data,
        inputData: {
          id: data.id,
          scheduledFor: timePosition.unixTime,
          primaryTechId: srcIsPrimary ? tech.id : data.primaryTechId,
          extraTechs: srcIsPrimary ? data.extraTechs : [...pull(data.extraTechs, srcTech), tech.id],
          actualDuration: data.actualDuration || DEFAULT_VISIT_DURATION_IN_MIN
        }
      };
    }

    case DragScenarios.FULLY_ASSIGNED_MULTI_TECH: {
      return {
        originalVisit: data,
        inputData: {
          id: data.id,
          scheduledFor: timePosition.unixTime,
          actualDuration: data.actualDuration || DEFAULT_VISIT_DURATION_IN_MIN,
          primaryTechId: data.primaryTechId,
          extraTechs: data.extraTechs
        }
      };
    }

    case DragScenarios.NON_VISIT_BOARD_TO_BOARD: {
      const { tenantId } = getState().user;

      return {
        eventId: data.id,
        tenantId,
        originalEvent: data,
        data: {
          from: timePosition.unixTime,
          to: timePosition.unixTime + data.range.diff() / 1000,
          employeeId: tech.id,
          version: data.version
        }
      };
    }

    case DragScenarios.MAN_DAY_BOARD_TO_BOARD: {
      return {
        id: data.id,
        startDateTime: timePosition.unixTime,
        endDateTime: timePosition.unixTime + data.range.diff() / 1000,
        technicianIds: [...pull([...data.technicianIds], srcTech), tech.id]
      };
    }

    default: {
      return {};
    }
  }
};
