import { sortBy } from 'lodash';

import AmplifyService from 'services/AmplifyService';
import { Logger } from 'services/Logger';
import { logErrorWithCallback } from 'utils';

import { deleteViewMutation, getAllTableViewsByName, saveViewMutation } from './queries';

export const viewIsDefault = view =>
  Array.isArray(view?.userSettingEmployees?.items) && view?.userSettingEmployees?.items.length > 0;

export const saveView = async (data, partitionKey, snackbar) => {
  const appsyncClient = await AmplifyService.appSyncClient();
  let savedView;
  try {
    const response = await appsyncClient.client.mutate({
      mutation: saveViewMutation,
      variables: {
        partitionKey,
        data
      }
    });
    savedView = response?.data?.saveTableSettingToCompany?.[0];
  } catch (error) {
    logErrorWithCallback(error, snackbar, `Unable to save Table View`);
    Logger.error(error);
  }
  return savedView;
};

export const queryViews = async (user, tableName) => {
  const appsyncClient = await AmplifyService.appSyncClient();
  let response;
  try {
    response = await appsyncClient.client.query({
      query: getAllTableViewsByName,
      variables: {
        partitionKey: user.tenantId,
        sortKey: `${user.tenantId}_Company_${user.tenantCompanyId}`,
        name: tableName
      }
    });
  } catch (error) {
    Logger.error(error);
  }
  const queriedViews = response?.data.getCompany?.userSettings?.items || [];
  return sortBy(queriedViews, 'displayName');
};

export const deleteView = async (viewToDelete, user, snackbar) => {
  const appsyncClient = await AmplifyService.appSyncClient();
  let response;
  try {
    response = await appsyncClient.client.mutate({
      mutation: deleteViewMutation,
      variables: {
        partitionKey: user.tenantId,
        id: viewToDelete.id
      }
    });
    if (response) {
      snackbar('success', `Successfully deleted ${viewToDelete.displayName}`);
      return true;
    }
    return false;
  } catch (error) {
    logErrorWithCallback(
      error,
      snackbar,
      `Unable to delete ${viewToDelete.displayName}, please try again later`
    );
    Logger.error(error);
    return false;
  }
};

export const getSettingObjectFromView = view => JSON.parse(view.settings);

export const accessConstants = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE'
};

export default saveView;
