import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';
import { ServiceAgreementStatus } from 'utils/AppConstants';

import { BILLING_TYPES } from './constants';
import { getPaymentTermsOptions } from './helpers';

const { ACTIVE, CANCELLED } = ServiceAgreementStatus;

const minFirstRowItemWidth = 200;
const minItemWidth = 150;
const padding = 12;

const mainSectionFirstRowItems = options => {
  return [
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          options: {
            grow: 0,
            shrink: 0,
            marginBottom: 10
          },
          contents: [
            {
              component: {
                default: 'Title'
              },
              options: {
                label: 'Schedule'
              }
            }
          ]
        },
        {
          component: {
            edit: 'DateInput',
            view: 'FieldWithLabel'
          },
          source: {
            view: 'startDateFormatted',
            edit: 'startDate'
          },
          options: {
            label: 'Start Date',
            color: 'secondary',
            disabled: options.agreementInfo?.status === ACTIVE,
            timezone: options.companyTimeZone
          }
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        paddingTop: 42,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          component: {
            edit: 'DateInput',
            view: 'FieldWithLabel'
          },
          source: {
            view: 'endDateFormatted',
            edit: 'endDate'
          },
          options: {
            label: 'End date',
            color: 'secondary',
            timezone: options.companyTimeZone
          }
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        paddingTop: 42,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          component: {
            edit: 'SelectInput',
            view: 'FieldWithLabel'
          },
          source: { edit: 'soldById', view: 'soldByName' },
          options: {
            label: 'Sold By',
            inputOptions: options.soldByList
          }
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        justify: 'space-between',
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          component: {
            default: 'Title'
          },
          options: {
            label: 'Contract'
          }
        },
        {
          component: {
            default: 'Attachments',
            view: 'AttachItem'
          },
          options: {
            type: 'Contract',
            variant: 'outlined',
            restrictSingleAttachment: true
          },
          source: 'contract'
        }
      ]
    }
  ];
};

const mainSectionSecondRowItems = options => {
  return [
    // Pricing
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        minWidth: minFirstRowItemWidth * 2
      },
      contents: [
        {
          options: {
            grow: 0,
            shrink: 0,
            marginBottom: 10
          },
          contents: [
            {
              component: {
                default: 'Title'
              },
              options: {
                label: 'Pricing'
              }
            }
          ]
        },
        {
          component: {
            edit: 'SelectInput',
            view: 'FieldWithLabel'
          },
          source: { edit: 'billingType', view: 'billingTypeValue' },
          options: {
            label: 'Billing Type',
            inputOptions: [
              {
                label: 'One Time Payment',
                value: 'OneTimePayment'
              },
              {
                label: 'Recurring Billing',
                value: 'RecurringBilling'
              },
              {
                label: 'Bill On Service Completion',
                value: 'BillOnServiceCompletion'
              }
            ]
          }
        }
      ]
    },
    {
      options: {
        grow: 0,
        direction: 'row'
      },
      contents:
        options.formData.billingType === 'RecurringBilling'
          ? [
              {
                options: {
                  direction: 'column',
                  grow: 0,
                  padding,
                  paddingTop: 42,
                  minWidth: minFirstRowItemWidth
                },
                contents: [
                  {
                    component: {
                      edit: 'CurrencyInput',
                      view: 'FieldWithLabel'
                    },
                    source: 'annualContractValue',
                    options: {
                      label: 'Annual contract value',
                      type: 'currency'
                    }
                  }
                ]
              },
              {
                options: {
                  direction: 'column',
                  grow: 0,
                  padding,
                  paddingTop: 42,
                  minWidth: minFirstRowItemWidth
                },
                contents: [
                  {
                    component: {
                      edit: 'CurrencyInput',
                      view: 'FieldWithLabel'
                    },
                    source: 'termPrice',
                    options: {
                      label: 'term price',
                      type: 'currency'
                    }
                  }
                ]
              },
              {
                options: {
                  direction: 'column',
                  grow: 0,
                  padding,
                  paddingTop: 42,
                  minWidth: minFirstRowItemWidth
                },
                contents: [
                  {
                    component: {
                      edit: 'SelectInput',
                      view: null
                    },
                    source: 'paymentTerms',
                    options: {
                      label: 'Payment schedule',
                      inputOptions: getPaymentTermsOptions
                    }
                  },
                  {
                    component: {
                      edit: null,
                      view: 'FieldWithLabel'
                    },
                    source: 'paymentTermsLabel',
                    options: {
                      label: 'Payment schedule'
                    }
                  }
                ]
              },
              {
                options: {
                  direction: 'column',
                  grow: 0,
                  padding,
                  paddingTop: 42,
                  minWidth: minFirstRowItemWidth
                },
                contents: [
                  {
                    component: {
                      edit: 'DateInput',
                      view: 'FieldWithLabel'
                    },
                    source: {
                      view: 'firstBillDateFormatted',
                      edit: 'firstBillDate'
                    },
                    options: {
                      label: 'First Billable Date',
                      color: 'secondary',
                      timezone: options.companyTimeZone,
                      disabled:
                        options.agreementInfo?.invoiceCount !== 0 &&
                        BILLING_TYPES[options.formData.billingType] ===
                          options.formData.billingTypeValue
                    }
                  }
                ]
              },
              {
                options: {
                  direction: 'column',
                  grow: 0,
                  paddingTop: 42,
                  minWidth: options.agreementInfo.status === ACTIVE ? minItemWidth : 0
                },
                contents: [
                  {
                    component: {
                      edit: null,
                      view: options.agreementInfo.status === ACTIVE ? 'FieldWithLabel' : null
                    },
                    source: {
                      view: 'nextBillDateFormatted',
                      edit: 'nextBillDate'
                    },
                    options: {
                      label: 'Next Billable Date'
                    }
                  }
                ]
              },
              {
                options: {
                  direction: 'column',
                  grow: 0,
                  paddingTop: 42,
                  minWidth: options.agreementInfo.status === ACTIVE ? minItemWidth : 0
                },
                contents: [
                  {
                    component: {
                      edit: null,
                      view: options.agreementInfo.status === ACTIVE ? 'FieldWithLabel' : null
                    },
                    source: {
                      view: 'lastBilledDateFormatted',
                      edit: 'lastBilledDate'
                    },
                    options: {
                      label: 'Last Billed Date'
                    }
                  }
                ]
              }
            ].filter(Boolean)
          : [
              {
                options: {
                  direction: 'column',
                  grow: 0,
                  padding,
                  paddingTop: 42,
                  minWidth: minFirstRowItemWidth
                },
                contents: [
                  {
                    component: {
                      edit: 'CurrencyInput',
                      view: 'FieldWithLabel'
                    },
                    source: 'annualContractValue',
                    options: {
                      label: 'Annual contract value',
                      type: 'currency'
                    }
                  }
                ]
              },
              {
                options: {
                  direction: 'column',
                  grow: 0,
                  padding,
                  paddingTop: 42,
                  minWidth: minFirstRowItemWidth
                },
                contents: [
                  {
                    component: {
                      edit: 'CurrencyInput',
                      view: 'FieldWithLabel'
                    },
                    source: 'totalContractValue',
                    options: {
                      label: 'total contract value',
                      type: 'currency'
                    }
                  }
                ]
              },
              // Keep the PO Number and Department Field unmoved
              {
                options: {
                  direction: 'column',
                  grow: 0,
                  padding,
                  paddingTop: 42,
                  minWidth: 240
                },
                contents: [
                  {
                    component: null
                  }
                ]
              }
            ].filter(Boolean)
    },
    {
      options: {
        direction: 'row',
        grow: 1,
        paddingTop: 42,
        paddingLeft: 12,
        justifyContent: 'flex-end',
        minWidth: minItemWidth + minFirstRowItemWidth + 24
      },
      contents: [
        {
          options: {
            direction: 'column',
            grow: 0,
            minWidth: minItemWidth
          },
          contents: [
            {
              component: {
                edit: 'TextInput',
                view: 'FieldWithLabel'
              },
              source: 'customerPoNumber',
              options: {
                label: 'PO Number',
                overflowWrap: 'anywhere'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            grow: 0,
            minWidth: minFirstRowItemWidth,
            paddingLeft: 12
          },
          contents: [
            {
              component: {
                edit: 'SelectInput',
                view: 'FieldWithLabel'
              },
              source: {
                edit: 'departmentId',
                view: 'department.tagName'
              },
              options: {
                isRequired: true,
                isSearchable: true,
                valueSet: options.departmentOptions,
                label: 'Department'
              }
            }
          ]
        }
      ]
    }
  ];
};

const sidebarItems = options => {
  return [
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        paddingTop: 0,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          options: {
            label: 'Customer Information'
          },
          component: {
            default: 'Title'
          }
        },
        {
          component: {
            default: 'LinkButton'
          },
          source: 'customer',
          options: {
            label: 'Customer'
          }
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          options: {
            label: 'Billing Customer',
            required: true,
            placeholder: 'Search...',
            searchIndexName: defaultSearchIndex,
            searchResultDisplayFields: ['customerName'],
            setItemName: 1,
            filters: [
              {
                attribute: 'entityType',
                valueArray: ['Customer']
              },
              {
                attribute: 'status',
                valueArray: ['active']
              }
            ],
            onChange: options.setBillingAddress
          },
          component: {
            edit: 'AlgoliaSearchWrapper',
            view: 'LinkButton'
          },
          source: 'billingCustomer'
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        paddingTop: 0,
        minWidth: 220
      },
      contents: [
        {
          options: {
            label: 'Same as Customer Address',
            onChange: options.setSameAsCustomer
          },
          component: {
            edit: 'CheckboxInput',
            view: null
          },
          source: 'sameAsCustomer'
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          component: {
            default: 'FieldWithLabel'
          },
          source: 'billingAddress',
          options: {
            label: 'Billing Address'
          }
        }
      ]
    },
    ...(!options.agreementInfo?.advancedScheduling
      ? [
          {
            options: {
              direction: 'column',
              grow: 0,
              padding,
              paddingTop: 16,
              paddingBottom: 16,
              paddingRight: 0,
              minWidth: minFirstRowItemWidth
            },
            contents: [
              {
                options: {
                  label: 'Properties'
                },
                component: {
                  default: 'Title'
                }
              },
              {
                options: {
                  isMulti: true,
                  itemLabelReference: 'label',
                  linkReference: 'value',
                  linkPath: '/property/view'
                },
                component: {
                  default: 'LinkList',
                  edit: options.agreementInfo?.status === ACTIVE ? 'LinkList' : 'Properties'
                },
                source: 'selectedProperties'
              }
            ]
          },
          {
            options: {
              direction: 'column',
              grow: 0,
              padding,
              minWidth: minFirstRowItemWidth
            },
            contents: [
              {
                options: {
                  label: 'Preferred Technicians'
                },
                component: {
                  default: 'Title'
                }
              },
              {
                component: {
                  default: 'PreferredTechnicians'
                },
                options: {
                  onUpdatePreferredTechnicians: options.onUpdatePreferredTechnicians,
                  canEdit: ![CANCELLED, ACTIVE].includes(options.agreementInfo?.status)
                },
                source: 'preferredTechnicians'
              }
            ]
          }
        ]
      : [])
  ];
};

const mainSectionLayout = options => ({
  options: {
    direction: 'column',
    grow: 0
  },
  contents: [
    {
      options: {
        direction: 'row',
        grow: 0,
        flexWrap: 'wrap'
      },
      contents: mainSectionFirstRowItems(options)
    },
    {
      options: {
        direction: 'row',
        grow: 0,
        flexWrap: 'wrap'
      },
      contents: mainSectionSecondRowItems(options)
    }
  ]
});

export const mainSection = options => ({
  fields: {},
  layouts: {
    default: {
      ...mainSectionLayout(options)
    },
    view: {
      ...mainSectionLayout(options)
    }
  }
});

const sidebarSectionLayout = options => ({
  options: {
    direction: 'column',
    grow: 0
  },
  contents: sidebarItems(options)
});

export const sidebarSection = options => ({
  fields: {},
  layouts: {
    default: {
      ...sidebarSectionLayout(options)
    },
    view: {
      ...sidebarSectionLayout(options)
    }
  },
  validation: {
    type: 'object',
    properties: {
      billingCustomer: { type: 'string', required: true }
    }
  },
  validationErrors: {
    billingCustomer: {
      required: 'Billing customer is required.'
    }
  }
});
