import React from 'react';

import { MenuList } from '@BuildHero/sergeant';

export default function InfiniteHits({
  hits, // infiteHits
  hasMore, // infiteHits
  refineNext, // infiteHits
  parentRefine,
  anchorRef,
  open,
  setOpen,
  handleRenderOption,
  formatHitLabel,
  formatHit,
  onChange
}) {
  const handleScroll = event => {
    if (
      hasMore &&
      event.target.scrollTop === event.target.scrollHeight - event.target.offsetHeight
    ) {
      // user is at the end of the list so load more items
      refineNext();
    }
  };
  return (
    <MenuList
      anchorRef={anchorRef}
      disablePortal={false}
      // only close if it's a selection. Clickaway is handled by this parent
      handleClose={(e, isSelection) => isSelection && setOpen(false)}
      handleRenderOption={handleRenderOption}
      modifiers={{
        sameWidth: {
          name: 'sameWidth',
          enabled: true,
          phase: 'beforeWrite',
          requires: ['computeStyles'],
          fn: state => {
            const data = state;
            data.instance.popper.style.width = `${state.instance.reference.offsetWidth}px`;
            return data;
          }
        },
        offset: {
          offset: '0,10'
        }
      }}
      open={open}
      options={hits.map(hit => {
        const label = formatHitLabel(hit);
        return {
          value: formatHit ? formatHit(hit) : hit,
          label,
          key: hit.id || label,
          onClick: h => {
            parentRefine(label);
            if (onChange) onChange(h);
          }
        };
      })}
      paperProps={{
        style: { maxHeight: '40vh', overflowY: 'auto' },
        onScroll: handleScroll
      }}
      placement="bottom-start"
    />
  );
}
