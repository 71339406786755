import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Moment from 'moment';

import AppConstants from 'utils/AppConstants';

import styles from './styles';

/**
 * Renders Readonly inputs as per the build hero designs
 */

class Label extends Component {
  render() {
    const { field, fieldProps, form, classes, specialbehaviour, ...rest } = this.props;
    // Arrayfiled will not have values in field props
    const fieldName = fieldProps && fieldProps.name ? fieldProps.name : field.name;

    let referenceFieldName;
    if (specialbehaviour && specialbehaviour.fieldName) {
      referenceFieldName = specialbehaviour.fieldName;
    }
    const fieldType = typeof form.values[fieldName];
    let fieldLabel = rest.label;
    if (fieldLabel && fieldLabel.charAt(0) === '*') {
      fieldLabel = fieldLabel.slice(1);
    }
    let displayValue = (field && field.value) || '';
    if (typeof displayValue === 'boolean') {
      displayValue = displayValue === false ? 'No' : 'Yes';
    }
    const isBigContent = specialbehaviour && specialbehaviour.bigContent;
    const contentHeight = specialbehaviour && specialbehaviour.contentHeight;

    if (specialbehaviour && specialbehaviour.saveUnixTimeStamp && field && field.value) {
      displayValue = isNaN(field.value)
        ? Moment(field.value).format(AppConstants.DATE_FORMAT)
        : Moment.unix(field.value).format(AppConstants.DATE_FORMAT);
    }

    return (
      <>
        {fieldType && fieldType !== 'object' && (
          <>
            <Typography className={classes.label} variant="caption">
              {fieldLabel}
            </Typography>
            {isBigContent && displayValue.length > 196 && (
              <div style={{ height: contentHeight || 180, overflowY: 'auto' }}>
                <Typography className={classes.bigContent}>{displayValue}</Typography>
              </div>
            )}
            {!isBigContent && (
              <Typography className={classes.content}>{displayValue || '-'}</Typography>
            )}
            {isBigContent && field.value.length < 196 && (
              <Typography className={classes.content}>{displayValue || '-'}</Typography>
            )}
          </>
        )}

        {fieldType &&
          fieldType === 'object' &&
          form.values[fieldProps.name].map((data, index) => (
            <Grid
              item
              key={`Grid${data.id || index}`}
              style={referenceFieldName ? { paddingBottom: 4, paddingTop: 0 } : {}}
              xs={12}
            >
              <Typography className={classes.content} key={`data${data.id || index}`}>
                {referenceFieldName ? data[referenceFieldName] : data} 1
              </Typography>
            </Grid>
          ))}
        {fieldType && fieldType === 'object' && form.values[fieldProps.name].length === 0 && (
          <Grid
            item
            key="Gridempty"
            style={referenceFieldName ? { paddingBottom: 4, paddingTop: 0 } : {}}
            xs={12}
          >
            <Typography key="emptydata">-</Typography>
          </Grid>
        )}
      </>
    );
  }
}

// export default MuiTextInput;
export default withStyles(styles)(Label);
