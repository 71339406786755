import StorageService from 'services/StorageService';

export const processDocumentsData = async documents => {
  const storageService = new StorageService();
  const documentsData = await Promise.all(
    documents.map(async file => {
      const { fileUrl, fileName, customFileName } = file;
      const remoteUrl = storageService.getFile(fileUrl);
      // Add file extension if missing
      const newFileName = customFileName
        ? `${customFileName.replace(/\s/g, '_').replace(/\.[^/.]+$/, '')}.pdf`
        : fileName;
      const blob = await (await fetch(remoteUrl)).blob();
      return {
        ...file,
        blob,
        remoteUrl,
        fileName: newFileName,
        fileSize: blob.size,
        remote: true,
        fileUrl: URL.createObjectURL(blob),
        type: 'application/pdf'
      };
    })
  );
  return documentsData;
};
