import gql from 'graphql-tag';
import { pick } from 'lodash';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { logErrorWithCallback } from 'utils';

const BULK_UPDATE_LABOUR_TYPE_BILLING_HOUR_TYPE_PRODUCTS = gql`
  mutation bulkUpdateLabourTypeBillingHourTypeProducts(
    $data: UpdateLabourTypeBillingHourTypeProductsInput!
  ) {
    bulkUpdateLabourTypeBillingHourTypeProducts(data: $data) {
      id
      labourTypeId
      labourType {
        id
        name
      }
      billingHourTypeId
      billingHourType {
        id
        hourType
        hourTypeAbbreviation
      }
      productId
      product {
        id
        name
      }
      labourRateGroupId
    }
  }
`;

const serializer = ({ companyId, labourTypeBillingHourTypeProducts }) => ({
  variables: {
    data: {
      companyId,
      labourTypeBillingHourTypeProducts: labourTypeBillingHourTypeProducts.map(
        labourTypeBillingHourTypeProduct =>
          pick(labourTypeBillingHourTypeProduct, [
            'id',
            'labourTypeId',
            'billingHourTypeId',
            'productId',
            'labourRateGroupId'
          ])
      )
    }
  }
});

export const useBulkUpdateLabourTypeBillingHourTypeProducts = () => {
  return useExtendedMutation(BULK_UPDATE_LABOUR_TYPE_BILLING_HOUR_TYPE_PRODUCTS, {
    serializer,
    onError: (err, snackbarOn) =>
      logErrorWithCallback(
        err,
        snackbarOn,
        'Unable to update labour type billing hour type products'
      )
  });
};
