import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import { makeStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { pickBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import AlgoliaSearch from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import PlacesSearch from 'components/BuildHeroFormComponents/PlacesSearch';
import DefaultButton from 'components/Buttons/DefaultButton';
// eslint-disable-next-line import/no-cycle
import Labels from 'meta/labels';
import {
  generalInformationFields,
  layout,
  shippingInformationFields
} from 'meta/Procurement/PurchaseOrders/generalInformationForm';
import MuiFormSectionTitle from 'scenes/Procurement/component/MuiFormSectionTitle';
import {
  getAddressByType,
  getJobPropertyAddressByJobId,
  getWarehouseAddress
} from 'scenes/Procurement/component/utils';
import {
  ShippingInformation,
  ShippingInformationWithNoJob,
  ShipToNameTypes
} from 'scenes/Procurement/constants';
import ProcurementUtils from 'scenes/Procurement/Procurement.utils';
import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';
import buildHeroMuiFormOverrides from 'scenes/ProjectManagement/components/buildHeroMuiFormOverrides';
import CustomDivider from 'scenes/ProjectManagement/components/CustomDivider';
import CustomFieldWithLabel from 'scenes/ProjectManagement/components/CustomFieldWithLabel';
import { generateDefaultValidationSchema } from 'scenes/ProjectManagement/components/formattingUtils';
import {
  checkRequiredFieldsFilled,
  getCombinedAddressFromProjectData,
  getLabelFromValues
} from 'scenes/ProjectManagement/components/utils';
import getCompanyAddressByParentId from 'services/API/companyAddress';
import { getDepartmentById, getDepartmentsByJob } from 'services/API/department';
import { getProjectById } from 'services/API/project';
import { getPurchaseOrderTags } from 'services/API/purchaseOrderTag';
import { JobService } from 'services/core';
import { getCombinedAddress } from 'utils';
import { JobTypes } from 'utils/AppConstants';
import { TESTING_ID } from 'utils/constants';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import TestingIdUtils from 'utils/TestingIdUtils';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 704,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  formContainer: {
    ...buildHeroMuiFormOverrides(theme),
    '& .MuiSvgIcon-root': {
      color: 'inherit',
      fontSize: 'inherit'
    },
    '& fieldset': {
      flexDirection: 'row',
      alignItems: 'center',
      '& .MuiTypography-caption': {
        margin: '0 16px 0 0'
      },
      '& .MuiFormControlLabel-label': {
        fontSize: 14
      }
    },
    '& .employee-select input': {
      padding: '11px 8px!important'
    }
  }
}));

const CustomDividerStyled = () => {
  return <CustomDivider backgroundColor="#E5E5E5" padding={8} width={714} />;
};

const CustomFieldWithLabelStyled = ({ field, options }) => {
  return (
    <CustomFieldWithLabel
      field={field}
      options={options}
      style={{ background: '#F0F0F0', color: '#999999', padding: '1px 8px', height: 41 }}
    />
  );
};

const GeneralStep = props => {
  const classes = useStyles();
  const {
    userLocale,
    associatedProject,
    onClickNextStep,
    generalData,
    dataReducer,
    setSaveAsDraftEnabled,
    formService,
    setFormService,
    jobProjectFieldReadOnly,
    generalInfoCustomField,
    handleJobUpdated
  } = props;
  const flags = useFlags();
  const { isVistaEnabled } = useSelector(state => state.settings);
  const [goNextStep, setGoNextStep] = useState(false);
  const [depId, setDepartmentId] = useState('');
  const [jobId, setJobId] = useState('');
  const [tagOptions, setTagOptions] = useState(props.tagOptions);
  const [assignedDepartments, setAssignedDepartments] = useState([]);
  const [showShippingAddress, setShowShippingAddress] = useState(
    generalData?.shipTo !== ShippingInformation.VENDOR_PICKUP && generalData?.shipTo !== undefined
  );
  const [manualEntry, setManualEntry] = useState(
    generalData?.shipTo === ShippingInformation.MANUALLY
  );
  const [shipToIsEmployee, setShipToIsEmployee] = useState(true);
  const departmentsAreLimitedByJob = flags[FeatureFlags.DEPARTMENTS_ARE_LIMITED_BY_JOB];
  const isVendorInvoiceApproval = flags[FeatureFlags.VENDOR_INVOICE_APPROVAL];
  const generatedGeneralInfoFields = {
    ...generalInformationFields,
    department: {
      required: true
    },
    jobOrProjectDisplay: {
      required: isVistaEnabled
    }
  };

  const requiredFields = pickBy(
    { ...generatedGeneralInfoFields, ...shippingInformationFields },
    value => value.required === true
  );

  const save = finalizeData => {
    if (formService) {
      dataReducer({ type: 'putGeneralInfo', payload: finalizeData });
      onClickNextStep();
    }
  };

  const shipToOptions = getLabelFromValues(
    jobProjectFieldReadOnly ? ShippingInformationWithNoJob : ShippingInformation
  );

  const getFormattedData = useCallback(
    (data = null) => {
      let defaultJobId;
      let defaultProjectId;
      // to support preloading scenarios
      const jobNumberFromParent =
        data?.jobAndProject?.customIdentifier ||
        data?.jobAndProject?.jobNumber ||
        associatedProject?.name ||
        associatedProject?.number;

      if (data?.jobAndProject?.jobNumber) {
        defaultJobId = data?.jobAndProject?.id || '';
      }
      if (data?.jobAndProject?.projectNumber) {
        defaultProjectId = data?.jobAndProject?.id || '';
      }

      return {
        poNumber: data?.poNumber || '-',
        poType: data?.poType || '',
        date: data?.date || moment.utc(moment().format('L')).unix(),
        department: data?.department || '',
        vendor: data?.vendor || '',
        assignedTo: data?.assignedTo || '',
        jobOrProjectDisplay: data?.jobOrProjectDisplay || jobNumberFromParent,
        jobNumber: data?.jobNumber || data?.jobAndProject?.jobNumber,
        // make sure both job and project Id fields are not set at the same time with initial data
        jobId: data?.jobId || defaultJobId,
        projectId: data?.projectId || defaultProjectId,
        jobAndProject: data?.jobAndProject || associatedProject || '',
        description: data?.description || '',
        requiredByDate: data?.requiredByDate || null,
        projectManager: data?.projectManager || '',
        purchaseOrderTags: data?.purchaseOrderTags || [],
        shipTo: data?.shipTo || '',
        shippingAddress: data?.shippingAddress || '',
        shipToName: data?.shipToName || '',
        shipToNameType: data?.shipToNameType || ShipToNameTypes.EMPLOYEE,
        shipToInstructions: data?.shipToInstructions || '',
        shipToEmployee: data?.shipToEmployee || ''
      };
    },
    [associatedProject]
  );

  const initialFormData = useMemo(() => getFormattedData(generalData), [
    generalData,
    getFormattedData
  ]);

  const matchingShippingAddress = async (userSelect, formData) => {
    let shippingAddress = '-';
    switch (userSelect) {
      case ShippingInformation.JOB_SITE:
        if (associatedProject?.id) {
          shippingAddress = getCombinedAddressFromProjectData(associatedProject);
        } else if (formData.values.projectId) {
          const project = await getProjectById(formData.values.projectId);
          shippingAddress = getCombinedAddressFromProjectData(project);
        } else {
          shippingAddress = await getJobPropertyAddressByJobId(formData.values.jobId);
        }
        formData.setValues({
          ...formData.values,
          shipTo: userSelect,
          shippingAddress
        });
        break;
      case ShippingInformation.WAREHOUSE:
        if (formData.values.department?.companyAddresses) {
          shippingAddress = getWarehouseAddress(formData.values.department);
        } else if (formData.values.department?.id) {
          const result = await getCompanyAddressByParentId(formData.values.department?.id);
          const department = getAddressByType(result, 'shippingAddress')[0];
          shippingAddress = getCombinedAddress(department) || '-';
        }
        formData.setValues({
          ...formData.values,
          shipTo: userSelect,
          shippingAddress
        });
        break;
      case ShippingInformation.VENDOR_PICKUP:
        formData.setValues({
          ...formData.values,
          shipTo: userSelect,
          shippingAddress: getCombinedAddress(formData.values.vendor) || '-'
        });
        break;
      case ShippingInformation.MANUALLY:
        formData.setValues({
          ...formData.values,
          shipTo: userSelect,
          shippingAddress:
            userSelect === formData.values.shipTo ? formData.values.shippingAddress : ''
        });
        break;
      default:
        formData.setValues({
          ...formData.values,
          shipTo: userSelect
        });
        break;
    }
  };

  const handleChangeShipToSelect = async (field, form) => {
    await matchingShippingAddress(field.value, form);

    switch (field.value) {
      case ShippingInformation.JOB_SITE:
      case ShippingInformation.WAREHOUSE:
      case ShippingInformation.VENDOR_PICKUP:
        setShowShippingAddress(true);
        setManualEntry(false);
        break;
      case ShippingInformation.MANUALLY:
      default:
        setShowShippingAddress(true);
        setManualEntry(true);
        break;
    }
  };

  const getJobOrProjectLinkForModal = data => {
    if (data.jobNumber) {
      const isMaintenanceJob = data.jobTypeInternal === JobTypes.MAINTENANCE;
      return `${isMaintenanceJob ? 'maintenance' : 'job'}/view/${encodeURIComponent(
        data.jobNumber
      )}`;
    }
    if (data.projectNumber) {
      return `project/view/${data.id}/dashboard`;
    }
  };

  const handleJobIdChange = async (selectedItem, _selectedItemName, form) => {
    const jobService = new JobService();
    const jobDetails = await jobService.getJobDetailsByJobNumber(`${selectedItem.jobNumber}`);
    handleJobUpdated(jobDetails?.data?.getJobByJobNumber);
    let defaultDepartment = null;
    let jobDept = null;
    let defaultProjectManager = null;

    if (departmentsAreLimitedByJob) {
      const deptsOnSelectedJob = await getDepartmentsByJob(selectedItem.id);
      if (deptsOnSelectedJob.length === 1) {
        defaultDepartment = deptsOnSelectedJob[0];
      }
    } else {
      defaultDepartment = form.values.department;
      if (selectedItem?.departmentId && !defaultDepartment?.id) {
        jobDept = await getDepartmentById(
          Array.isArray(selectedItem.departmentId)
            ? selectedItem.departmentId[0]
            : selectedItem.departmentId
        );
      }
    }

    defaultProjectManager = selectedItem?.owner || selectedItem?.projectManager;
    const modifiedValues = {
      ...form.values,
      jobOrProjectLink: getJobOrProjectLinkForModal(selectedItem),
      jobOrProjectDisplay:
        selectedItem?.customIdentifier ||
        selectedItem?.jobNumber ||
        selectedItem?.projectName ||
        selectedItem?.projectNumber ||
        '',
      jobNumber: selectedItem?.jobNumber || '',
      projectId: selectedItem?.projectNumber ? selectedItem?.id : null,
      jobId: selectedItem?.jobNumber ? selectedItem?.id : null,
      department: defaultDepartment?.id ? defaultDepartment : jobDept,
      // set initial data to empty object when job or project selection is made
      jobAndProject: {},
      projectManager: isVendorInvoiceApproval ? defaultProjectManager : null
    };
    setAssignedDepartments(defaultDepartment || []);
    form.setValues(modifiedValues);
    matchingShippingAddress(form.values?.shipTo, { ...form, values: modifiedValues });
  };

  const handleFormChange = async data => {
    setShipToIsEmployee(data.shipToNameType === ShipToNameTypes.EMPLOYEE);
    setDepartmentId(data.department?.id);
    setJobId(data.jobId);
    const result = checkRequiredFieldsFilled(data, requiredFields);

    if (result) {
      if (data.shipTo === ShippingInformation.MANUALLY && data.shippingAddress.length === 0) {
        setGoNextStep(false);
        setSaveAsDraftEnabled(false);
      } else {
        setGoNextStep(true);
        setSaveAsDraftEnabled(true);
      }
      dataReducer({ type: 'putGeneralInfo', payload: data });
    } else {
      setGoNextStep(false);
      setSaveAsDraftEnabled(false);
    }
  };

  const customSearchBar = ({ options, field, form }) => {
    return (
      <SearchBar
        field={field}
        form={form}
        options={options}
        onSelectionChange={values => {
          if (!values || !form.values.shipTo) return;

          // eslint-disable-next-line no-param-reassign
          form.values[field.name] = values;
          handleChangeShipToSelect({ value: form.values.shipTo }, form);
        }}
      />
    );
  };

  useEffect(() => {
    setTagOptions(props.tagOptions);
  }, [props.tagOptions]);

  const tagOptionsFetched = useRef(false);
  if (!tagOptions.length && !tagOptionsFetched.current) {
    tagOptionsFetched.current = true;
    getPurchaseOrderTags().then(purchaseOrderTags => {
      setTagOptions(constructSelectOptions(purchaseOrderTags, 'tagName'));
    });
  }

  return (
    <div className={classes.root}>
      <div className={classes.formContainer}>
        <MUIForm
          configuration={layout({
            isVendorInvoiceApproval,
            tags: tagOptions,
            shipTo: shipToOptions,
            showShippingAddress,
            manualEntry,
            handleJobIdChange,
            handleChangeShipToSelect,
            shipToIsEmployee,
            depId,
            associatedProject,
            assignedDepartments,
            departmentsAreLimitedByJob: departmentsAreLimitedByJob && jobId,
            jobId,
            isVistaEnabled,
            jobProjectFieldReadOnly,
            handleSetDefaultValue: ProcurementUtils.handleSetDefaultValue
          })}
          customComponents={{
            MuiFormSectionTitle,
            CustomDividerStyled,
            PlacesSearch,
            SearchBar,
            customSearchBar,
            CustomFieldWithLabelStyled,
            AlgoliaSearch,
            generalInfoCustomField
          }}
          data={initialFormData}
          layout="edit"
          validationSchema={generateDefaultValidationSchema({
            ...generatedGeneralInfoFields,
            ...shippingInformationFields
          })}
          onComplete={async completed => save(completed)}
          onCreateService={service => setFormService(service)}
          onFormChange={handleFormChange}
        />
      </div>
      <div className={classes.buttonContainer}>
        <DefaultButton
          disabled={!goNextStep}
          label={Labels.nextStep[userLocale]}
          style={{ width: 82, height: 30, fontSize: 12, padding: 8, margin: 8 }}
          testingid={TestingIdUtils.generateTestingId({
            customTestingId: 'next-step',
            componentDefaultTestingId: TESTING_ID.DEFAULT_BUTTON
          })}
          variant={goNextStep ? 'containedSecondary' : 'contained'}
          onClick={() => formService.submit()}
        />
      </div>
    </div>
  );
};

CustomFieldWithLabelStyled.propTypes = {
  field: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

GeneralStep.propTypes = {
  userLocale: PropTypes.string.isRequired,
  onClickNextStep: PropTypes.func.isRequired,
  generalData: PropTypes.object.isRequired,
  dataReducer: PropTypes.func.isRequired,
  associatedProject: PropTypes.object.isRequired,
  setSaveAsDraftEnabled: PropTypes.func.isRequired,
  tagOptions: PropTypes.array.isRequired,
  formService: PropTypes.object.isRequired,
  setFormService: PropTypes.func.isRequired,
  jobProjectFieldReadOnly: PropTypes.bool,
  generalInfoCustomField: PropTypes.func,
  handleJobUpdated: PropTypes.func
};

GeneralStep.defaultProps = {
  jobProjectFieldReadOnly: false,
  handleJobUpdated: () => {},
  generalInfoCustomField: () => <div />
};

export default GeneralStep;
