/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box } from '@material-ui/core';
import { difference, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { DEFAULT_VISIT_DURATION_IN_MIN } from '@dispatch/Dispatch.constants';
import { FullScreenModal, withMultipleForms } from 'components';
import DefaultButton from 'components/Buttons/DefaultButton';
import Context from 'components/Context';
import buildVisitCreatePayload from 'components/Visit/components/VisitForm/helpers/buildVisitCreatePayload';
import useEmployees from 'customHooks/useEmployees';
import usePayrollSettings from 'customHooks/usePayrollSettings';
import labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import AmplifyService from 'services/AmplifyService';
import { CommonService } from 'services/core';
import { Logger } from 'services/Logger';

import AppConstant, { FormEntityType } from 'utils/AppConstants';

import { getMinutesFromHrs } from '../helpers';
import { BulkAddVisitsToJobs } from '../queries';

import Forms from './forms';
import ScheduleModal from './ScheduleModal';
import { useStyles } from './styles';

function ManualVisitCreationModal(props) {
  const classes = useStyles();
  const { handleClose, snackbar, user, isOpen, items, jobsPreferredTechniciansConfigMap } = props;
  // withMultipleForms HOC props
  const {
    getHandleCreateService,
    getFormService,
    getHandleComplete,
    handleSubmitStart,
    setOnSubmitFinal
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [enableScheduling, setenableScheduling] = useState(false);
  const [openScheduleModal, setopenScheduleModal] = useState(false);
  const [formsList, setFormsList] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [checkScheduledVisits, setScheduledVisits] = useState([]);

  const [techs] = useEmployees({
    includeDepartments: true,
    filter: { isActive: { eq: true }, isTech: { eq: true } }
  });

  const [payrollSettings] = usePayrollSettings();
  const companyTimeZone = payrollSettings?.timeZone;

  const formDataInArr = true;
  // total Visits ids selected from maintenance list
  const incompleteVisits = useRef(items?.map(item => item.id) || []);
  const notCompleted = difference(incompleteVisits.current, checkScheduledVisits);
  const getDepartmentsAndTech = useCallback(() => {
    const company = Context.getCompanyContext();
    const departmentList = company?.getCompany?.departments?.items;
    setDepartments(departmentList);
  }, []);

  const getForms = async () => {
    const commonService = new CommonService();
    const formsResult = await commonService.getFormsAvailableToEntity(
      user.tenantId,
      `${user.tenantId}_Company_${user.tenantCompanyId}`,
      FormEntityType.VISIT
    );
    // only get forms with a published version
    const availableForms = (formsResult?.data?.getCompany?.forms?.items || []).filter(
      form => form.latestPublishedFormDefinition
    );
    if (availableForms.length) {
      const formList = availableForms.map(form => ({
        label: form.name,
        value: form
      }));

      setFormsList(formList);
    }
  };
  useEffect(() => {
    getDepartmentsAndTech();
    getForms();
  }, [getDepartmentsAndTech]);

  const createVisitPayload = form => {
    const localForm = { ...form, description: form.visitDescription };
    if (!localForm?.visitDuration) {
      // common payload creation adds minutes
      const budgetedMinutes = getMinutesFromHrs(localForm?.budgetedHours);
      localForm.visitDuration = budgetedMinutes || DEFAULT_VISIT_DURATION_IN_MIN; // if not present it breaks mobile app, hence defaulting to 60 mins
    }
    const completedVisits = checkScheduledVisits?.find(item => item === localForm.maintenanceId);
    if (completedVisits && localForm?.preferredTechnicians?.departmentId) {
      const payload = buildVisitCreatePayload({
        jobId: localForm.maintenanceId,
        user,
        availableForms: formsList,
        propertyAssets: [],
        visitFormData: localForm
      });
      return {
        ...payload,
        visitAssets: jobsPreferredTechniciansConfigMap?.[localForm.jobNumber]?.visitAssets || []
      };
    }
  };

  const createVisits = async data => {
    const appsyncClient = AmplifyService.appSyncClient();
    return appsyncClient.client.mutate({
      mutation: BulkAddVisitsToJobs,
      variables: {
        partitionKey: user.tenantId,
        input: {
          visits: data
        }
      }
    });
  };

  const handleScheduleVisits = () => {
    setOnSubmitFinal(async formsData => {
      setIsSubmitting(true);
      try {
        if (formsData) {
          const payload = formsData
            .map(data => createVisitPayload(data))
            .filter(data => data !== undefined);
          await createVisits(payload);
          snackbar('success', `Successfully created visits.`);
          props.refreshList();
        }
      } catch (error) {
        Logger.error(error);
        snackbar('error', `Unable to create visits. Please try again.`, error);
      } finally {
        setIsSubmitting(false);
        handleClose();
      }
    });
  };

  useEffect(() => {
    if (enableScheduling) {
      handleSubmitStart(formDataInArr);
      setopenScheduleModal(false);
      setenableScheduling(false);
      handleScheduleVisits();
    }
  }, [handleScheduleVisits, enableScheduling]);

  const handleClick = () => {
    if (notCompleted.length !== 0) setopenScheduleModal(true);
    else {
      setopenScheduleModal(false);
      setenableScheduling(true);
    }
  };

  return (
    <FullScreenModal
      handleClose={() => props.handleClose()}
      modalHeaderButtons={[
        <DefaultButton
          disabled={checkScheduledVisits.length === 0 || isSubmitting}
          label="Create Visits"
          showSpinner={isSubmitting}
          variant="containedPrimary"
          onClick={handleClick}
        />
      ]}
      open={isOpen}
      title={labels.manualVisitCreation[user.locale]}
    >
      <Box display="flex" flexDirection="column" mt={2}>
        {items.map(visit => {
          const preferredTechniciansConfig = jobsPreferredTechniciansConfigMap[visit?.jobNumber];
          const { maintenanceTemplate } = preferredTechniciansConfig || {};
          const data = {
            maintenanceId: visit.id,
            jobNumber: visit?.jobNumber,
            serviceAgreementName: visit.serviceAgreementName,
            interval: visit.interval,
            visitDescription: maintenanceTemplate?.serviceDescription,
            propertyName: visit.propertyName,
            tags: visit.tags,
            estimatedVisits: maintenanceTemplate?.estimatedVisits,
            budgetedHours: maintenanceTemplate?.budgetedHours,
            jobTypeName: visit.jobTypeName
          };
          if (visit.dueDate) {
            data.dueDate = moment
              .tz(moment.unix(visit.dueDate), companyTimeZone)
              .format(AppConstant.DATE_FORMAT);
          }
          return (
            <>
              <Forms
                classes={classes}
                data={data}
                departments={departments}
                formsList={formsList}
                getFormService={getFormService}
                getHandleComplete={getHandleComplete}
                getHandleCreateService={getHandleCreateService}
                preferredTechniciansConfig={preferredTechniciansConfig}
                setScheduledVisits={setScheduledVisits}
                techs={techs}
                user={user}
              />
            </>
          );
        })}
      </Box>
      <ScheduleModal
        classes={classes}
        handleClose={() => setopenScheduleModal(false)}
        handleScheduleVisits={() => {
          setenableScheduling(true);
        }}
        isSubmitting={isSubmitting}
        openScheduleModal={openScheduleModal}
        user={user}
      />
    </FullScreenModal>
  );
}

ManualVisitCreationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    // e.g. "en" - options are "en" or "es" - used to decide on language
    locale: PropTypes.string.isRequired
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  refreshList: PropTypes.func.isRequired, // to update parent component list
  // maintenance records that were selected
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      dueDate: PropTypes.num,
      serviceAgreementName: PropTypes.string,
      interval: PropTypes.string
    })
  ).isRequired
};

const MultipleForms = props => {
  // withMultipleForms cannot be used with empty list
  if (isEmpty(props?.jobsPreferredTechniciansConfigMap)) return null;
  const formNames = Object.values(props.jobsPreferredTechniciansConfigMap).map(maintenanceJob =>
    Array(maintenanceJob?.maintenanceTemplate?.estimatedVisits || 1)
      .fill()
      .map((key, index) => `form-${maintenanceJob?.maintenanceId}-${index + 1}`)
  );

  const flatFormNames = formNames.flat();
  const WrappedModal = withMultipleForms(ManualVisitCreationModal, flatFormNames);
  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <WrappedModal {...props} />;
};
export default connect(null, { snackbar: snackbarOn })(MultipleForms);
