import React from 'react';

import { FieldType } from '@BuildHero/sergeant';
import { InputAdornment } from '@material-ui/core';
import { number, object, string } from 'yup';

import { InvoiceItemType } from 'utils/constants';
import { sgtItemCalc } from 'utils/onCalcChange';

export default ({
  lineItemType,
  isQuoteItem,
  priceBookId,
  departmentOptions,
  costCodeOptions,
  costTypeOptions,
  revenueTypeOptions,
  isVista,
  showMargin,
  showMarkup,
  jcContractItemOptions,
  disableUnitCost,
  disableCostingFields
}) => {
  const isDiscountOrFee =
    lineItemType === InvoiceItemType.DISCOUNT || lineItemType === InvoiceItemType.FEE;
  const isInventory =
    lineItemType === InvoiceItemType.INVENTORY_PART ||
    lineItemType === InvoiceItemType.PUCHASE_ORDER_LINE;

  const rowStyle = {
    gap: 16,
    flexDirection: 'row',
    marginBottom: 16
  };
  const colStyle = {
    width: 200
  };

  const markupField = showMarkup && {
    component: 'PercentageInput',
    source: 'markupValue',
    props: {
      style: showMargin ? { width: 100 } : colStyle,
      label: 'Markup',
      slowField: true,
      onChange: sgtItemCalc,
      disabled: disableCostingFields
    }
  };

  const marginField = showMargin && {
    component: 'PercentageInput',
    source: 'marginValue',
    props: {
      testingid: 'percent-margin',
      style: showMarkup ? { width: 100 } : colStyle,
      label: 'Margin',
      slowField: true,
      onChange: sgtItemCalc,
      disabled: disableCostingFields
    }
  };

  return {
    validation: object({
      name: string()
        .required('Product is required.')
        .nullable(),
      productId: string()
        .required()
        .nullable(),
      department: object({ value: string() })
        .required('Department is required.')
        .nullable(),
      jcContractItem: isVista
        ? object({ value: string() })
            .required('Contract Item is required.')
            .nullable()
        : undefined,
      quantity: number()
        .required('Quantity is required.')
        .moreThan(0, 'Quantity must be greater than 0')
        .nullable(),
      unitPrice: number()
        .required('Unit price is required.')
        .nullable(),
      unitCost: number().nullable(),
      amount: number('Amount must be a valid number')
        .required('Amount is required')
        .nullable()
    }),
    layouts: {
      default: {
        contents: [
          // row 1
          {
            props: { style: rowStyle },
            contents: [
              {
                component: 'ProductWithPricingSearch',
                source: 'name',
                props: {
                  testingid: 'product-search',
                  style: { width: 416 },
                  label: 'Product',
                  required: true,
                  disabled: isQuoteItem,
                  priceBookId,
                  costCodeOptions,
                  costTypeOptions,
                  revenueTypeOptions
                }
              },
              {
                component: 'Select',
                source: 'department',
                props: {
                  style: colStyle,
                  label: 'Department',
                  required: true,
                  portal: true,
                  options: departmentOptions,
                  searchable: true
                }
              },
              isVista && {
                component: 'Select',
                source: 'jcContractItem',
                props: {
                  style: colStyle,
                  label: 'Contract Item',
                  options: jcContractItemOptions,
                  portal: true,
                  menuHeight: 150,
                  searchable: true,
                  required: true
                }
              }
            ]
          },
          // row 2
          {
            props: { style: rowStyle },
            contents: [
              {
                component: 'Input',
                source: 'description',
                props: {
                  label: 'Description',
                  multiline: true,
                  rows: 4,
                  inputProps: {
                    testingid: 'input-description'
                  }
                }
              }
            ]
          },
          // row 3
          !isDiscountOrFee && {
            props: { style: rowStyle },
            contents: [
              {
                component: 'NumberInput',
                source: 'quantity',
                resolveProps: ({ product }) => ({
                  endAdornment: (
                    <InputAdornment position="end">
                      {product?.unitOfMeasure?.name || 'units'}
                    </InputAdornment>
                  )
                }),
                props: {
                  testingid: 'quantity-number',
                  style: colStyle,
                  label: 'Quantity',
                  required: true,
                  onChange: sgtItemCalc,
                  disabled: disableCostingFields
                }
              },
              isInventory && {
                component: 'CurrencyInput',
                source: 'unitCost',
                props: {
                  testingid: 'currencyInput-unitcost',
                  style: colStyle,
                  label: 'Unit Cost',
                  slowField: true,
                  onChange: sgtItemCalc,
                  disabled: disableUnitCost
                }
              },
              isInventory && markupField,
              isInventory && marginField,
              {
                component: 'CurrencyInput',
                source: 'unitPrice',
                props: {
                  testingid: 'currencyInput-unitprice',
                  style: colStyle,
                  label: 'Unit Price',
                  required: true,
                  slowField: true,
                  onChange: sgtItemCalc,
                  localeStringOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  },
                  disabled: disableCostingFields
                }
              }
            ]
          },
          // row 4
          {
            props: { style: rowStyle },
            contents: [
              {
                component: 'Select',
                source: 'costCode',
                props: {
                  style: colStyle,
                  label: 'Cost Code',
                  options: costCodeOptions,
                  portal: true,
                  menuHeight: 150,
                  searchable: true,
                  clearable: true
                }
              },
              {
                component: 'Select',
                source: 'jobCostType',
                props: {
                  style: colStyle,
                  label: 'Cost Type',
                  options: costTypeOptions,
                  portal: true,
                  menuHeight: 150,
                  searchable: true,
                  clearable: true
                }
              },
              {
                component: 'Select',
                source: 'revenueType',
                props: {
                  style: colStyle,
                  label: 'Revenue Type',
                  options: revenueTypeOptions,
                  portal: true,
                  menuHeight: 150,
                  searchable: true,
                  clearable: true
                }
              }
            ]
          },
          // row 5
          {
            props: { style: { flexDirection: 'row', gap: 16 } },
            contents: [
              isDiscountOrFee && {
                component: 'Select',
                source: 'type',
                props: {
                  style: colStyle,
                  label: 'Discount / Fee',
                  required: true,
                  portal: true,
                  options: [
                    { label: InvoiceItemType.DISCOUNT, value: InvoiceItemType.DISCOUNT },
                    { label: InvoiceItemType.FEE, value: InvoiceItemType.FEE }
                  ]
                }
              },
              {
                component: isDiscountOrFee ? 'CurrencyInput' : 'Field',
                source: 'amount',
                props: isDiscountOrFee
                  ? {
                      testingid: 'currencyInput-subTotal',
                      style: colStyle,
                      label: 'Subtotal',
                      required: true,
                      slowField: true,
                      onChange: sgtItemCalc
                    }
                  : {
                      testingid: 'subTotal',
                      style: colStyle,
                      label: 'Subtotal',
                      slowField: true,
                      type: FieldType.CURRENCY
                    }
              },
              {
                component: 'Checkbox',
                source: 'taxable',
                props: {
                  testingid: 'checkbox-taxable',
                  style: colStyle,
                  label: 'Taxable'
                }
              }
            ]
          }
        ]
      }
    }
  };
};
