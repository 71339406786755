import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import ExpandMore from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import PropTypes from 'prop-types';

import AutoComplete from 'components/AutoCompleteDropdown';
import useEmployees from 'customHooks/useEmployees';

import useStyle from './EmployeeSelector.styles';

const EmployeeSelector = ({ selectedEmployee, onSelectEmployee }) => {
  const classes = useStyle();
  const { name, firstName = '', lastName = '' } = selectedEmployee;

  const [employeeOptions] = useEmployees({
    filter: { isActive: { eq: true }, isTech: { eq: true } },
    transform: activeEmployees =>
      _.sortBy(activeEmployees, 'firstName').map(employee => ({
        label: employee.code ? `${employee.name} (${employee.code})` : employee.name,
        value: employee
      }))
  });

  const handleEmployeeSelect = employee => {
    onSelectEmployee(employee);
  };

  return (
    <Grid alignItems="center" className={classes.wrapper} container justifyContent="space-between">
      <Avatar alt="" className={classes.avatar} src={selectedEmployee.profilePictureUrl}>
        {name && `${firstName.charAt(0)}${lastName.charAt(0)}`}
      </Avatar>
      <Grid className={classes.selectionContainer} item xs={12}>
        <AutoComplete
          DropdownIndicator={() => <ExpandMore />}
          handleOnChange={handleEmployeeSelect}
          inputProps={{
            textFieldClassName: classes.selectionInputPlaceholder,
            inputClassNames: classes.selectionInput,
            className: classes.selectionWrapper,
            disableUnderline: true
          }}
          name="employee"
          options={employeeOptions}
          placeholder="Select an employee"
          value={selectedEmployee}
        />
      </Grid>
    </Grid>
  );
};

export default EmployeeSelector;

EmployeeSelector.propTypes = {
  selectedEmployee: PropTypes.object,
  onSelectEmployee: PropTypes.func.isRequired
};

EmployeeSelector.defaultProps = {
  selectedEmployee: null
};
