import React from 'react';

import Typography from '@material-ui/core/Typography';

import { column, ColumnType, valueGetters } from 'components/XGrid/columnTypes';
import { MultiSelectTypes } from 'utils/AppConstants';

const rbBillListTableMeta = isVendorInvoiceApproval => [
  {
    field: 'billNumber',
    headerName: 'Bill Number',
    width: 140,
    valueGetter: valueGetters.billLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'poNumber',
    headerName: 'PO Number',
    width: 140,
    dataField: 'purchaseOrderId',
    valueGetter: valueGetters.purchaseOrderLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'vendorName',
    headerName: 'Vendor',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'vendorDocumentNumber',
    headerName: 'Vendor Document Number',
    width: 235,
    ...column[ColumnType.TEXT],
    renderCell: ({ value, row }) => (
      <Typography
        style={
          row.vendorDocumentAttachmentId ? { textDecoration: 'underline', cursor: 'pointer' } : {}
        }
      >
        {value}
      </Typography>
    )
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'departmentName',
    headerName: 'Department',
    width: 150,
    enumType: MultiSelectTypes.DEPARTMENTS,
    ...column[ColumnType.TAGS]
  },
  {
    field: 'jobOrProjectName',
    headerName: 'Job / Project',
    width: 150,
    valueGetter: valueGetters.jobOrMaintenanceLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'totalCost',
    headerName: 'Total',
    width: 150,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'createdDate',
    headerName: 'Created On',
    width: 150,
    ...column[ColumnType.DATE]
  },
  ...(isVendorInvoiceApproval
    ? [
        {
          field: 'projectManagerName',
          headerName: 'Project Manager',
          width: 165,
          ...column[ColumnType.TEXT]
        },
        {
          field: 'invoiceAmount',
          headerName: 'Invoice Amount',
          width: 150,
          ...column[ColumnType.CURRENCY]
        }
      ]
    : [])
];

export default rbBillListTableMeta;
