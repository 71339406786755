import React, { useMemo } from 'react';

import { MUIFormComponentNames } from '@BuildHero/sergeant';
import { useTheme } from '@material-ui/core';

import { row } from 'utils/layout';

import { getFormValidation, getFormValidationErrors } from '../../helpers/NonVisitEvents.helpers';
import {
  getCreateMultipleEventsSwitch,
  getDateTimeField,
  getDurationField,
  getEndDateField,
  getExcludeWeekendsField,
  getStartDateTimeField
} from '../../helpers/NonVisitEventsFormFields';
import PropertyAddress from '../VisitDetailsForm/components/PropertyAddress';

export const useBillableEventFormConfig = ({
  allowMultipleEventsCreation,
  disabled,
  job,
  multipleEventsCreationEnabled,
  onCreateMultipleEventsChange,
  visit,
  visitDataLoading
}) => {
  const theme = useTheme();
  return useMemo(() => {
    const rowOptions = {
      marginBottom: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column'
    };

    const EVENT_NAME_FIELD = [
      {
        component: MUIFormComponentNames.TextInput,
        source: 'eventName',
        options: {
          isRequired: true,
          disabled,
          label: 'EVENT NAME'
        }
      }
    ];

    const DESCRIPTION_FIELD = [
      {
        component: MUIFormComponentNames.TextInput,
        source: 'description',
        options: {
          disabled,
          label: 'DESCRIPTION',
          lines: 4,
          maxTextLen: 80
        }
      }
    ];

    const PROPERTY_ADDRESS = [
      {
        component: MUIFormComponentNames.FieldWithLabel,
        options: {
          label: 'Property Address',
          type: 'custom',
          transform: () => <PropertyAddress job={job} loading={visitDataLoading} visit={visit} />
        }
      }
    ];

    const durationField = getDurationField({ disabled });
    const excludeWeekendsField = getExcludeWeekendsField();
    const createMultipleEventsSwitch = getCreateMultipleEventsSwitch({
      allowMultipleEventsCreation,
      disabled,
      multipleEventsCreationEnabled,
      onCreateMultipleEventsChange,
      label: 'Create Multiple Billable Events'
    });
    const startDateTimeField = getStartDateTimeField({ disabled });
    const dateTimeField = getDateTimeField({ disabled });
    const endDateField = getEndDateField({ disabled });

    return {
      fields: {},
      layouts: {
        default: {
          contents: [
            {
              component: 'AccordionSection',
              options: {
                heading: 'Details',
                expanded: true
              },
              contents: [
                row(EVENT_NAME_FIELD, rowOptions),
                row(DESCRIPTION_FIELD, rowOptions),
                row(PROPERTY_ADDRESS, rowOptions)
              ]
            },
            {
              component: 'AccordionSection',
              options: {
                heading: 'Scheduling',
                expanded: true
              },

              contents: multipleEventsCreationEnabled
                ? [
                    row(createMultipleEventsSwitch, {
                      ...rowOptions,
                      marginBottom: theme.spacing(3)
                    }),
                    row(startDateTimeField, rowOptions),
                    row(endDateField, rowOptions),
                    row(excludeWeekendsField, rowOptions),
                    row(durationField, rowOptions)
                  ]
                : [
                    row(createMultipleEventsSwitch, {
                      ...rowOptions,
                      marginBottom: theme.spacing(3)
                    }),
                    row(dateTimeField, rowOptions),
                    row(durationField, rowOptions)
                  ]
            }
          ]
        }
      },
      validation: getFormValidation(multipleEventsCreationEnabled),
      validationErrors: getFormValidationErrors()
    };
  }, [
    allowMultipleEventsCreation,
    disabled,
    job,
    multipleEventsCreationEnabled,
    onCreateMultipleEventsChange,
    visit,
    visitDataLoading,
    theme
  ]);
};
