import { PDFDocument } from 'pdf-lib';
import * as R from 'ramda';

import {
  addAllPages,
  convertPDFLibDocumentToBlob
} from 'scenes/ProjectManagement/components/utils';

const getCombinedFileName = attachments => {
  const rawFileName = attachments[0].fileName.replace(/\.[^/.]+$/, '');
  return attachments.some(attachment => attachment.entityType === 'JobReport')
    ? `${rawFileName}-JobReport`
    : rawFileName;
};

export const addAttachmentsToPDF = async (attachments, returnAsPDFDocument) => {
  const pdfDoc = await PDFDocument.create();
  const mergedPDF = {
    fileName: null,
    fileUrl: null,
    blob: null,
    fileSize: null,
    type: null,
    remote: false
  };
  const pdfAttachments = attachments?.filter(attachment => attachment?.type === 'application/pdf');

  // Use pdf-lib to merge all PDF attachments
  if (pdfAttachments && pdfAttachments?.length !== 0) {
    const donors = pdfAttachments.map(async ({ blob }) => {
      const buffer = await blob.arrayBuffer();
      return PDFDocument.load(buffer);
    });
    // Array.reduce() helps maintain the order of pdf documents
    await donors.reduce(async (l, r) => {
      return Promise.all([l, r]).then(async ([mergedDoc, nextDoc]) =>
        addAllPages(mergedDoc, nextDoc)
      );
    }, pdfDoc);

    const combinedBlob = await convertPDFLibDocumentToBlob(pdfDoc);
    const combinedUrl = URL.createObjectURL(combinedBlob);
    mergedPDF.blob = combinedBlob;
    mergedPDF.fileSize = combinedBlob.size;
    mergedPDF.fileUrl = combinedUrl;
    mergedPDF.type = combinedBlob.size.type;
    mergedPDF.fileName = `${getCombinedFileName(pdfAttachments)}.pdf`;
  }
  return returnAsPDFDocument ? pdfDoc : [mergedPDF];
};

export const prepareFilesData = filesData => {
  return filesData.map(fileData =>
    R.pick(['fileName', 'fileUrl', 'blob', 'fileSize', 'type', 'remote', 'remoteUrl'], fileData)
  );
};
