import React, { useEffect, useRef, useState } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import { Box, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import uuid from 'uuid';

import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';
import CustomDivider from 'scenes/ProjectManagement/components/CustomDivider/index';
import CustomFieldWithLabel from 'scenes/ProjectManagement/components/CustomFieldWithLabel';
import { generateDefaultValidationSchema } from 'scenes/ProjectManagement/components/formattingUtils';
import FormWithAccordion from 'scenes/ProjectManagement/components/FormWithAccordion';

import MuiFormSectionTitle from '../../../../../../Procurement/component/MuiFormSectionTitle';

import { getCodeTotal, getFormattedPhaseDeptCode } from '../utils';

import layout, { codesLayout } from './layout';

const CustomDividerStyled = () => {
  return <CustomDivider backgroundColor="#E5E5E5" padding={8} width={1200} />;
};

const PhaseDepartmentForm = props => {
  const {
    projectId,
    phaseDeptData = {},
    classes,
    index,
    deptTempId,
    phaseDeptIds,
    updateContractValue,
    getHandleCreateService,
    getHandleRemoveService,
    getHandleComplete,
    contractValue,
    onDeptSelection,
    onDeleteDepartment,
    deleteDisabled
  } = props;
  const [tableCostCodes, setTableCostCodes] = useState([]);
  const [expanded, setExpanded] = useState(true);
  const [departmentSelected, setDeptSelected] = useState(!!phaseDeptData?.id);
  const [departmentName, setDeptName] = useState(
    phaseDeptData?.Department?.tagName || phaseDeptData?.tagName || `Department ${index + 1}`
  );
  const tableCostCodesRef = useRef();
  tableCostCodesRef.current = tableCostCodes;

  const formatDeptForForm = data => ({
    id: data?.id || undefined,
    projectPhaseId: data?.projectPhaseId || undefined,
    Department: data?.Department || undefined,
    materialCost: data?.materialCost || 0,
    laborDollars: data?.laborDollars || 0,
    subcontractorCost: data?.subcontractorCost || 0,
    otherCost: data?.otherCost || 0,
    equipmentCost: data?.equipmentCost || 0,
    overheadCost: data?.overheadCost || 0,
    contractValue: data?.contractValue || 0,
    totalRevenue: data?.totalRevenue || getCodeTotal(data)
  });

  useEffect(() => {
    if (phaseDeptData?.ProjectPhaseDepartmentCostCode) {
      const newCodes = phaseDeptData.ProjectPhaseDepartmentCostCode.map(code => ({
        ...code,
        ccTempId: uuid.v4(),
        totalCost: getCodeTotal(code)
      }));

      setTableCostCodes(newCodes);
    }
  }, [phaseDeptData]);

  useEffect(() => {
    if (!departmentSelected && phaseDeptData?.Department?.id) {
      setDeptSelected(true);
    } else if (departmentSelected && !phaseDeptData?.Department?.id) {
      setDeptSelected(false);
    }
  }, [departmentSelected, phaseDeptData]);

  const sectionTitle = ({ options }) => (
    <Typography className={classes.sectionTitle} variant="subtitle1">
      {options.label}
    </Typography>
  );

  const addCodeToTable = newCostCode => {
    if (newCostCode) {
      setTableCostCodes(prevState => [
        ...prevState,
        { ...getFormattedPhaseDeptCode({ ...newCostCode, projectId }, true), ccTempId: uuid.v4() }
      ]);
    }
  };

  const onDeleteCostCode = ccIndex => {
    setTableCostCodes(prevState => R.remove(ccIndex, 1, prevState));
  };

  const updateCode = (field, value, codeIndex) => {
    if (field === 'totalCost') return;
    setTableCostCodes(prevState =>
      prevState.map((code, mappedIndex) =>
        codeIndex === mappedIndex
          ? {
              ...code,
              [field]: value,
              totalCost: getCodeTotal({ ...code, [field]: value })
            }
          : code
      )
    );
  };

  const formatDepartmentForSaving = data => ({
    id: data?.id ? data.id : undefined,
    Department: data?.Department,
    departmentId: data?.Department?.id || data?.departmentId,
    projectPhaseId: data?.projectPhaseId,
    tagName: data?.Department ? data.Department.tagName : data?.tagName,
    equipmentCost: parseFloat(data?.equipmentCost) || 0,
    materialCost: parseFloat(data?.materialCost) || 0,
    subcontractorCost: parseFloat(data?.subcontractorCost) || 0,
    laborDollars: parseFloat(data?.laborDollars) || 0,
    otherCost: parseFloat(data?.otherCost) || 0,
    overheadCost: parseFloat(data?.overheadCost) || 0
  });

  const handleDeptSelect = deptSelection => {
    if (!deptSelection?.id) {
      setDeptName(`Department ${index + 1}`);
      setDeptSelected(false);
      return;
    }

    onDeptSelection({ Department: deptSelection }, index);
    if (departmentName !== deptSelection?.tagName) {
      setDeptName(deptSelection.tagName);
    }
    if (!departmentSelected) {
      setDeptSelected(true);
    }
  };

  const handleFieldChange = (field, value) => {
    if (R.includes(field, ['contractValue', 'ProjectPhaseDepartment', 'totalRevenue'])) {
      return;
    }
    updateContractValue(field, value, index);
  };

  // Use temporary Id to keep track of form service
  const generateDepartmentString = phaseDeptTempId => `PhaseDept-${phaseDeptTempId}`;
  const generatePhaseDeptCostCodeString = (phaseDeptTempId, ccTempId) =>
    `PhaseDept-${phaseDeptTempId}-code-${ccTempId}`;

  const deleteDeptButton = () => (
    <Button
      className={classes.deleteButton}
      color="secondary"
      disabled={deleteDisabled}
      style={{ visibility: !deleteDisabled ? 'visible' : 'hidden' }}
      variant="contained"
      onClick={() => {
        onDeleteDepartment(index);
        getHandleRemoveService(generateDepartmentString(deptTempId));
        // Need to remove all cost codes services belong to the department as well
        tableCostCodesRef.current.forEach(({ ccTempId }) => {
          getHandleRemoveService(generatePhaseDeptCostCodeString(deptTempId, ccTempId));
        });
      }}
    >
      Delete
    </Button>
  );

  const deleteCostCodeButton = ({ ccTempId }, ccIndex) => (
    <Button
      className={classes.deleteButton}
      color="secondary"
      style={{ visibility: !deleteDisabled ? 'visible' : 'hidden' }}
      variant="contained"
      onClick={() => {
        onDeleteCostCode(ccIndex);
        getHandleRemoveService(generatePhaseDeptCostCodeString(deptTempId, ccTempId));
      }}
    >
      Delete
    </Button>
  );

  return (
    <FormWithAccordion
      expanded={expanded}
      formComponent={() => (
        <Box>
          <MUIForm
            configuration={layout(
              projectId,
              phaseDeptIds,
              tableCostCodes.map(cc => cc.projectCostCodeId),
              addCodeToTable,
              handleDeptSelect,
              departmentSelected
            )}
            customComponents={{
              CustomDividerStyled,
              SearchBar,
              MuiFormSectionTitle,
              sectionTitle,
              CustomFieldWithLabel,
              deleteDeptButton
            }}
            data={formatDeptForForm({ ...phaseDeptData, contractValue })}
            key={`phase-department-form-${phaseDeptData?.id || index}`}
            mode="default"
            validationSchema={generateDefaultValidationSchema({ Department: { required: true } })}
            onComplete={getHandleComplete(
              generateDepartmentString(deptTempId),
              formatDepartmentForSaving,
              ['PhaseData', 'ProjectPhaseDepartment']
            )}
            onCreateService={getHandleCreateService(generateDepartmentString(deptTempId))}
            onFieldChange={handleFieldChange}
          />
          <Box style={{ marginTop: '30px', overflowX: 'auto' }}>
            <Box style={{ minWidth: '1200px' }}>
              {tableCostCodes.map((code, codeIndex) => (
                <MUIForm
                  configuration={codesLayout(departmentSelected, codeIndex)}
                  customComponents={{
                    CustomFieldWithLabel,
                    deleteCostCodeButton: ({ options }) =>
                      deleteCostCodeButton(code, options.codeIndex)
                  }}
                  data={code}
                  key={`phaseDeptForm-${code.ccTempId}`}
                  mode="default"
                  onComplete={getHandleComplete(
                    generatePhaseDeptCostCodeString(deptTempId, code.ccTempId),
                    undefined,
                    [
                      'PhaseData',
                      'ProjectPhaseDepartment',
                      `PhaseDept-${deptTempId}`,
                      'ProjectPhaseDepartmentCostCode'
                    ]
                  )}
                  onCreateService={getHandleCreateService(
                    generatePhaseDeptCostCodeString(deptTempId, code.ccTempId)
                  )}
                  onFieldChange={(field, value) => updateCode(field, value, codeIndex)}
                />
              ))}
            </Box>
          </Box>
        </Box>
      )}
      handlePanelExpand={() => setExpanded(!expanded)}
      sectionName={departmentName}
    />
  );
};

PhaseDepartmentForm.propTypes = {
  projectId: PropTypes.string.isRequired,
  phaseDeptData: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  deptTempId: PropTypes.string.isRequired,
  phaseDeptIds: PropTypes.array.isRequired,
  updateContractValue: PropTypes.func.isRequired,
  getHandleCreateService: PropTypes.func.isRequired,
  getHandleRemoveService: PropTypes.func.isRequired,
  getHandleComplete: PropTypes.func.isRequired,
  contractValue: PropTypes.number.isRequired,
  onDeptSelection: PropTypes.func.isRequired,
  onDeleteDepartment: PropTypes.func.isRequired,
  deleteDisabled: PropTypes.bool.isRequired
};

export default PhaseDepartmentForm;
