import React from 'react';

import { Typography } from '@BuildHero/sergeant';
import { any, array, bool } from 'prop-types';

import WrapTable from 'components/WrapTable';
import { TablePlaceholderVariant } from 'components/WrapTable/WrapTable.constants';

import PurchasedItem from './components/PurchasedItem';
import PurchasedLineItemsTable from './components/PurchasedLineItemsTable';
import { useStyles } from './PurchasedItems.styles';

const PurchasedItems = ({ items, loading, error }) => {
  const styles = useStyles();
  return (
    <>
      <Typography css={styles.title}>Purchased items</Typography>
      {items.map(item => {
        const lineItems = item?.purchaseOrderLines?.items;
        return (
          <div key={item?.id}>
            <div css={styles.container}>
              <PurchasedItem
                item={{ ...item, fileUrl: item?.purchaseOrderReceipts?.items?.[0]?.imageUrl }}
              />
            </div>
            <div css={styles.lineItemsContainer}>
              <PurchasedLineItemsTable error={error} lineItems={lineItems} loading={loading} />
            </div>
          </div>
        );
      })}
      {!items.length && (
        <WrapTable
          columns={[]}
          error={error}
          loading={loading}
          noDataMessage="No Purchased Items"
          placeholderVariant={TablePlaceholderVariant.TEXT}
          rows={[]}
        />
      )}
    </>
  );
};

PurchasedItems.propTypes = {
  items: array.isRequired,
  loading: bool.isRequired,
  error: any
};

PurchasedItems.defaultProps = {
  error: undefined
};

export default PurchasedItems;
