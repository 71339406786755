import { generateDefaultFieldsObject } from '@pm/components/formattingUtils';
import { customerRepSearch } from 'services/API/customerRep';
import { employeeSearch, getEmployees } from 'services/API/employee';

const margin = '0px 0px 21px 0px';

const emailFields = {
  sendTo: { required: true },
  bcc: { required: false },
  cc: { required: false },
  body: { required: false }
};

const sortFunction = (a, b) => {
  const fieldToCompare = a?.customerName ? 'customerName' : 'name';
  return a?.[fieldToCompare]?.toLowerCase() > b?.[fieldToCompare]?.toLowerCase() ? 1 : -1;
};

const resultFormatFunction = result => {
  if (typeof result === 'string') {
    return result;
  }
  if (result.email && (result.customerName || result.name)) {
    return `${result.customerName || result.name} - ${result.email}`;
  }
  if (result.email) {
    return `${result.email}`;
  }
  return null;
};

const approvalContents = (project, setSendTo, setCC, setBCC, setSubject) => [
  // Send to field
  {
    options: {
      direction: 'row'
    },
    contents: [
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            component: 'FormLabel',
            options: {
              label: 'SEND TO'
            }
          },
          {
            component: 'SearchBar',
            source: 'sendTo',
            options: {
              variant: 'standard',
              color: 'secondary',
              freeSolo: true,
              multiline: true,
              placeholder: '',
              searchFunction: (term, searchCols) => {
                return Promise.all([
                  customerRepSearch(term, searchCols, project?.customer?.id || undefined, project),
                  employeeSearch(term)
                ]).then(result => [...result[0], ...result[1]]);
              },
              emptySearchFunction: () => {
                return Promise.all([
                  customerRepSearch('', undefined, project?.customer?.id || undefined, project),
                  getEmployees()
                ]).then(result => [...result[0], ...result[1]]);
              },
              resultFormatFunction,
              sortFunction,
              multiple: true,
              noDuplicates: true,
              onSelect: setSendTo
            }
          }
        ]
      }
    ]
  },
  // CC field
  {
    options: {
      direction: 'row'
    },
    contents: [
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            component: 'FormLabel',
            options: {
              label: 'CC'
            }
          },
          {
            component: 'SearchBar',
            source: 'cc',
            options: {
              variant: 'standard',
              color: 'secondary',
              freeSolo: true,
              multiline: true,
              placeholder: '',
              searchFunction: (term, searchCols) => {
                return Promise.all([
                  customerRepSearch(term, searchCols, project?.customer?.id || undefined, project),
                  employeeSearch(term)
                ]).then(result => [...result[0], ...result[1]]);
              },
              emptySearchFunction: () => {
                return Promise.all([
                  customerRepSearch('', undefined, project?.customer?.id || undefined, project),
                  getEmployees()
                ]).then(result => [...result[0], ...result[1]]);
              },
              resultFormatFunction,
              sortFunction,
              multiple: true,
              noDuplicates: true,
              onSelect: setCC
            }
          }
        ]
      }
    ]
  },
  // BCC Field
  {
    options: {
      direction: 'row'
    },
    contents: [
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            component: 'FormLabel',
            options: {
              label: 'BCC'
            }
          },
          {
            component: 'SearchBar',
            source: 'bcc',
            options: {
              variant: 'standard',
              color: 'secondary',
              freeSolo: true,
              multiline: true,
              placeholder: '',
              searchFunction: (term, searchCols) => {
                return Promise.all([
                  customerRepSearch(term, searchCols, project?.customer?.id || undefined, project),
                  employeeSearch(term)
                ]).then(result => [...result[0], ...result[1]]);
              },
              emptySearchFunction: () => {
                return Promise.all([
                  customerRepSearch('', undefined, project?.customer?.id || undefined, project),
                  getEmployees()
                ]).then(result => [...result[0], ...result[1]]);
              },
              resultFormatFunction,
              sortFunction,
              multiple: true,
              noDuplicates: true,
              onSelect: setBCC
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      direction: 'row'
    },
    contents: [
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            component: 'FormLabel',
            options: {
              label: 'SUBJECT'
            }
          },
          {
            component: 'TextInput',
            source: 'subject',
            options: {
              variant: 'filled',
              onChange: setSubject
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      direction: 'row'
    },
    contents: [
      {
        options: {
          direction: 'column',
          margin,
          maxWidth: '100%'
        },
        contents: [
          {
            component: 'FormLabel',
            options: {
              label: 'BODY'
            }
          },
          {
            component: 'EmailBodyTextField',
            source: 'body',
            options: {
              variant: 'standard',
              color: 'secondary',
              placeholder: ''
            }
          }
        ]
      }
    ]
  }
];

const emailLayout = (project, setSendTo, setCC, setBCC, setSubject) => {
  return {
    fields: generateDefaultFieldsObject(emailFields),
    layouts: {
      default: {
        options: {},
        contents: approvalContents(project, setSendTo, setCC, setBCC, setSubject)
      },
      edit: {
        options: {},
        contents: approvalContents(project, setSendTo, setCC, setBCC, setSubject)
      }
    }
  };
};

export default emailLayout;
