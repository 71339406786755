import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';

import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';

import { ChipArray, PageHeader, XGrid } from 'components';
import AlgoliaSearch from 'components/AlgoliaSearch';
import HighlightCard from 'components/HighlightCard';
import { column, ColumnType, valueGetters } from 'components/XGrid/columnTypes';
import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';
import { MIXPANEL_EVENT, MIXPANEL_PAGE_NAME } from 'constants/mixpanelEvents';
import useEmployees from 'customHooks/useEmployees';
import { summaryTitles } from 'meta/FollowUp';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { Logger } from 'services/Logger';
import { sendMixpanelEvent } from 'services/mixpanel';
import { MultiSelectTypes } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';

import { GET_FOLLOW_UP_COUNT, GET_FOLLOWUPS } from './gql';

const ALL = 'All';
const MY_JOBS = 'My jobs';
const EMPTY = '';

const FollowUpTypes = {
  completedVisits: {
    typeName: 'COMPLETED_TECH_REPORT',
    sortField: 'Visit.createdDate',
    customerName: 'customerName'
  },
  draftReviewReports: {
    typeName: 'DRAFT_REVIEW_REPORT',
    sortField: 'Visit.createdDate',
    customerName: 'customerName'
  },
  submittedReviewReports: {
    typeName: 'SUBMITTED_REVIEW_REPORT',
    sortField: 'Visit.createdDate',
    customerName: 'customerName'
  },
  draftInvoices: {
    typeName: 'DRAFT_INVOICES',
    sortField: 'Invoice.createdDate',
    customerName: 'billingCustomerName'
  },
  unpaidInvoices: {
    typeName: 'UNPAID_INVOICES',
    sortField: 'Invoice.createdDate',
    customerName: 'billingCustomerName'
  }
};

const followUpcolumns = {
  completedVisits: [
    {
      field: 'jobCustomIdentifier',
      headerName: 'Job',
      width: 100,
      valueGetter: valueGetters.jobOrMaintenanceLink,
      ...column[ColumnType.LINK]
    },
    {
      field: 'visitNumber',
      headerName: 'Visit',
      width: 100
    },
    {
      field: 'departmentName',
      headerName: 'Department',
      width: 200,
      enumType: MultiSelectTypes.DEPARTMENTS,
      ...column[ColumnType.TAGS]
    },
    {
      field: 'customerName',
      headerName: 'Customer',
      width: 200
    },
    {
      field: 'propertyName',
      headerName: 'Property',
      width: 200
    },
    {
      field: 'jobTypeName',
      headerName: 'Job Type',
      width: 200,
      enumType: MultiSelectTypes.JOB_TYPES,
      ...column[ColumnType.TAGS]
    },
    {
      field: 'jobStatus',
      headerName: 'Job Status',
      width: 150,
      enumType: EnumType.JOB_STATUS,
      showIcon: true,
      ...column[ColumnType.ENUM]
    },
    {
      field: 'jobTags',
      headerName: 'Job Tags',
      width: 200,
      enumType: MultiSelectTypes.JOB_TAGS,
      ...column[ColumnType.TAGS]
    },
    {
      field: 'projectManeger',
      headerName: 'Project Manager',
      width: 200
    },
    {
      field: 'visitCreatedDate',
      headerName: 'Created On',
      width: 200,
      ...column[ColumnType.DATE]
    },
    {
      field: 'jobCompletedDate',
      headerName: 'Job Completion Date',
      width: 200,
      ...column[ColumnType.DATE]
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 200
    },
    {
      field: 'submittedBy',
      headerName: 'Submitted By',
      width: 200
    },
    {
      field: 'visitId',
      headerName: 'Report',
      width: 200,
      valueGetter: valueGetters.techReportLink,
      ...column[ColumnType.LINK]
    },
    {
      field: 'jobOwnerId',
      headerName: 'MyJobGlobalFilter',
      width: 100,
      hide: true,
      metaOnly: true
    }
  ],
  draftReviewReports: [
    {
      field: 'jobCustomIdentifier',
      headerName: 'Job',
      width: 100,
      valueGetter: valueGetters.jobLink,
      ...column[ColumnType.LINK]
    },
    {
      field: 'visitNumber',
      headerName: 'Visit',
      width: 100
    },
    {
      field: 'departmentName',
      headerName: 'Department',
      width: 200,
      enumType: MultiSelectTypes.DEPARTMENTS,
      ...column[ColumnType.TAGS]
    },
    {
      field: 'customerName',
      headerName: 'Customer',
      width: 200
    },
    {
      field: 'propertyName',
      headerName: 'Property',
      width: 200
    },
    {
      field: 'jobTypeName',
      headerName: 'Job Type',
      width: 200,
      enumType: MultiSelectTypes.JOB_TYPES,
      ...column[ColumnType.TAGS]
    },
    {
      field: 'jobStatus',
      headerName: 'Job Status',
      width: 150,
      enumType: EnumType.JOB_STATUS,
      showIcon: true,
      ...column[ColumnType.ENUM]
    },
    {
      field: 'jobTags',
      headerName: 'Job Tags',
      width: 200,
      enumType: MultiSelectTypes.JOB_TAGS,
      ...column[ColumnType.TAGS]
    },
    {
      field: 'projectManeger',
      headerName: 'Project Manager',
      width: 200
    },
    {
      field: 'visitCreatedDate',
      headerName: 'Created On',
      width: 200,
      ...column[ColumnType.DATE]
    },
    {
      field: 'jobCompletedDate',
      headerName: 'Job Completion Date',
      width: 200,
      ...column[ColumnType.DATE]
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 200
    },
    {
      field: 'submittedBy',
      headerName: 'Submitted By',
      width: 200
    },
    {
      field: 'reviewReportId',
      headerName: 'Report',
      width: 200,
      valueGetter: valueGetters.reviewReportLink,
      ...column[ColumnType.LINK]
    },
    {
      field: 'jobOwnerId',
      headerName: 'MyJobGlobalFilter',
      width: 100,
      hide: true,
      metaOnly: true
    }
  ],
  submittedReviewReports: [
    {
      field: 'jobCustomIdentifier',
      headerName: 'Job',
      width: 100,
      valueGetter: valueGetters.jobLink,
      ...column[ColumnType.LINK]
    },
    {
      field: 'visitNumber',
      headerName: 'Visit',
      width: 100
    },
    {
      field: 'departmentName',
      headerName: 'Department',
      width: 200,
      enumType: MultiSelectTypes.DEPARTMENTS,
      ...column[ColumnType.TAGS]
    },
    {
      field: 'customerName',
      headerName: 'Customer',
      width: 200
    },
    {
      field: 'propertyName',
      headerName: 'Property',
      width: 200
    },
    {
      field: 'jobTypeName',
      headerName: 'Job Type',
      width: 200,
      enumType: MultiSelectTypes.JOB_TYPES,
      ...column[ColumnType.TAGS]
    },
    {
      field: 'jobStatus',
      headerName: 'Job Status',
      width: 150,
      enumType: EnumType.JOB_STATUS,
      showIcon: true,
      ...column[ColumnType.ENUM]
    },
    {
      field: 'jobTags',
      headerName: 'Job Tags',
      width: 200,
      enumType: MultiSelectTypes.JOB_TAGS,
      ...column[ColumnType.TAGS]
    },
    {
      field: 'projectManeger',
      headerName: 'Project Manager',
      width: 200
    },
    {
      field: 'visitCreatedDate',
      headerName: 'Created On',
      width: 200,
      ...column[ColumnType.DATE]
    },
    {
      field: 'jobCompletedDate',
      headerName: 'Job Completion Date',
      width: 200,
      ...column[ColumnType.DATE]
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 200
    },
    {
      field: 'submittedBy',
      headerName: 'Submitted By',
      width: 200
    },
    {
      field: 'reviewReportId',
      headerName: 'Report',
      width: 200,
      valueGetter: valueGetters.reviewReportLink,
      ...column[ColumnType.LINK]
    },
    {
      field: 'jobOwnerId',
      headerName: 'MyJobGlobalFilter',
      width: 100,
      hide: true,
      metaOnly: true
    }
  ],
  draftInvoices: [
    {
      field: 'invoiceNumber',
      headerName: 'Invoice',
      width: 125,
      valueGetter: valueGetters.invoiceLink,
      ...column[ColumnType.LINK]
    },
    {
      field: 'jobCustomIdentifier',
      headerName: 'Job',
      valueGetter: valueGetters.jobLink,
      ...column[ColumnType.LINK]
    },
    {
      field: 'billingCustomerName',
      headerName: 'Billing Customer',
      width: 200,
      valueGetter: valueGetters.billingCustomerLink,
      ...column[ColumnType.LINK]
    },
    {
      field: 'propertyName',
      headerName: 'Property',
      width: 200,
      dataField: 'propertyId',
      valueGetter: valueGetters.propertyLink,
      ...column[ColumnType.LINK]
    },
    {
      field: 'departmentName',
      headerName: 'Department',
      width: 200,
      enumType: MultiSelectTypes.DEPARTMENTS,
      ...column[ColumnType.TAGS]
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      enumType: MultiSelectTypes.INVOICE_STATUS,
      ...column[ColumnType.ENUM]
    },
    {
      field: 'jobTags',
      headerName: 'Job Tags',
      width: 200,
      enumType: MultiSelectTypes.JOB_TAGS,
      ...column[ColumnType.TAGS]
    },
    {
      field: 'invoiceTags',
      headerName: 'Invoice Tags',
      width: 200,
      enumType: MultiSelectTypes.INVOICE_TAGS,
      ...column[ColumnType.TAGS]
    },
    {
      field: 'dueDate',
      headerName: 'Due',
      width: 200,
      ...column[ColumnType.DATE]
    },
    {
      field: 'issuedDate',
      headerName: 'Issued',
      width: 200,
      ...column[ColumnType.DATE]
    },
    {
      field: 'jobCompletedDate',
      headerName: 'Job Completion Date',
      width: 200,
      ...column[ColumnType.DATE]
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 200
    },
    {
      field: 'submittedBy',
      headerName: 'Submitted By',
      width: 200
    },
    {
      field: 'totalAmount',
      headerName: 'Amount',
      width: 200,
      ...column[ColumnType.CURRENCY]
    },
    {
      field: 'outstandingBalance',
      headerName: 'Balance',
      width: 200,
      ...column[ColumnType.CURRENCY],
      cellClassName: 'MuiDataGrid-cellBoldRed'
    },
    {
      field: 'jobOwnerId',
      headerName: 'MyJobGlobalFilter',
      width: 100,
      hide: true,
      metaOnly: true
    }
  ],
  unpaidInvoices: [
    {
      field: 'invoiceNumber',
      headerName: 'Invoice',
      width: 125,
      valueGetter: valueGetters.invoiceLink,
      ...column[ColumnType.LINK]
    },
    {
      field: 'jobCustomIdentifier',
      headerName: 'Job',
      valueGetter: valueGetters.jobLink,
      ...column[ColumnType.LINK]
    },
    {
      field: 'billingCustomerName',
      headerName: 'Billing Customer',
      width: 200,
      valueGetter: valueGetters.billingCustomerLink,
      ...column[ColumnType.LINK]
    },
    {
      field: 'propertyName',
      headerName: 'Property',
      width: 200,
      dataField: 'propertyId',
      valueGetter: valueGetters.propertyLink,
      ...column[ColumnType.LINK]
    },
    {
      field: 'departmentName',
      headerName: 'Department',
      width: 200,
      enumType: MultiSelectTypes.DEPARTMENTS,
      ...column[ColumnType.TAGS]
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      enumType: MultiSelectTypes.INVOICE_STATUS,
      ...column[ColumnType.ENUM]
    },
    {
      field: 'jobTags',
      headerName: 'Job Tags',
      width: 200,
      enumType: MultiSelectTypes.JOB_TAGS,
      ...column[ColumnType.TAGS]
    },
    {
      field: 'invoiceTags',
      headerName: 'Invoice Tags',
      width: 200,
      enumType: MultiSelectTypes.INVOICE_TAGS,
      ...column[ColumnType.TAGS]
    },
    {
      field: 'dueDate',
      headerName: 'Due',
      width: 200,
      ...column[ColumnType.DATE]
    },
    {
      field: 'issuedDate',
      headerName: 'Issued',
      width: 200,
      ...column[ColumnType.DATE]
    },
    {
      field: 'jobCompletedDate',
      headerName: 'Job Completion Date',
      width: 200,
      ...column[ColumnType.DATE]
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 200
    },
    {
      field: 'submittedBy',
      headerName: 'Submitted By',
      width: 200
    },
    {
      field: 'totalAmount',
      headerName: 'Amount',
      width: 200,
      ...column[ColumnType.CURRENCY]
    },
    {
      field: 'outstandingBalance',
      headerName: 'Balance',
      width: 200,
      ...column[ColumnType.CURRENCY],
      cellClassName: 'MuiDataGrid-cellBoldRed'
    },
    {
      field: 'jobOwnerId',
      headerName: 'MyJobGlobalFilter',
      width: 100,
      hide: true,
      metaOnly: true
    }
  ]
};

const summaryOldToNewKeyMap = {
  COMPLETED_TECH_REPORT: { oldKey: 'completedVisits', sortOrder: 1 },
  DRAFT_REVIEW_REPORT: { oldKey: 'draftReviewReports', sortOrder: 2 },
  SUBMITTED_REVIEW_REPORT: { oldKey: 'submittedReviewReports', sortOrder: 3 },
  DRAFT_INVOICES: { oldKey: 'draftInvoices', sortOrder: 4 },
  UNPAID_INVOICES: { oldKey: 'unpaidInvoices', sortOrder: 5 }
};

const GLOBAL_XGRID_MY_JOBS_FILTER_ID = -1;
const GLOBAL_XGRID_CUSTOMER_OR_PROPERTY_FILTER_ID = -2;

const FollowUp = ({ user, ...props }) => {
  const [employees] = useEmployees();
  const [highlightTableName, setHighlightTableName] = useState(
    summaryOldToNewKeyMap.COMPLETED_TECH_REPORT.oldKey
  );

  const [globalXGridFilters, setGlobalXGridFilters] = useState([]);
  const [globalFilterName, setGlobalFilterName] = useState(ALL);
  const [filterName, setFilterName] = useState('');
  const [showPMFilters, setShowPMFilters] = useState(false);
  const [columns, setColumns] = useState(followUpcolumns.completedVisits);
  const [selectedFollowUpType, setSelectedFollowUpType] = useState(FollowUpTypes.completedVisits);
  const [summaryFilter, setSummaryFilter] = useState({});

  const [updatingGlobalFilters, setUpdatingGlobalFilters] = useState(false);

  const { data, loading, error, refetch } = useQuery(GET_FOLLOW_UP_COUNT, {
    variables: {
      tenantId: user.tenantId,
      filter: summaryFilter
    },
    fetchPolicy: 'cache-and-network'
  });

  const summaryData =
    !loading && !error
      ? Object.keys(data.getFollowUpCounts)
          .sort((a, b) => summaryOldToNewKeyMap[a].sortOrder - summaryOldToNewKeyMap[b].sortOrder)
          .reduce((acc, key) => {
            return {
              ...acc,
              [summaryOldToNewKeyMap[key].oldKey]: data.getFollowUpCounts[key]
            };
          }, {})
      : {};

  document.title = 'BuildOps - Follow up';

  useEffect(() => {
    let PMFilters = false;
    const currentEmployee = employees?.find(
      item => item.id === user.employeeId && (item.isSales === 'true' || item.isSales === true)
    );

    if (currentEmployee) {
      PMFilters = true;
    }

    setShowPMFilters(PMFilters);

    sendMixpanelEvent(MIXPANEL_EVENT.PAGE_VIEW, MIXPANEL_PAGE_NAME.FOLLOW_UPS);
  }, [employees]);

  const getGlobalFilters = async value => {
    switch (value) {
      case MY_JOBS: {
        if (globalFilterName === MY_JOBS) return; // already selected
        setUpdatingGlobalFilters(true);
        setGlobalFilterName(MY_JOBS);

        const newXGridFilters = [
          ...globalXGridFilters,
          {
            columnField: 'jobOwnerId',
            operatorValue: 'equals',
            value: user.employeeId,
            id: GLOBAL_XGRID_MY_JOBS_FILTER_ID
          }
        ];

        setSummaryFilter({ ...summaryFilter, jobOwnerId: user.employeeId });
        setGlobalXGridFilters(newXGridFilters);

        break;
      }
      case ALL: {
        if (globalFilterName === ALL) return; // already selected
        setUpdatingGlobalFilters(true);

        setGlobalFilterName(ALL);

        const newXGridFilters = globalXGridFilters.filter(
          f => f.id !== GLOBAL_XGRID_MY_JOBS_FILTER_ID
        );

        const { jobOwnerId, ...otherFilters } = summaryFilter;
        setSummaryFilter({ ...otherFilters });

        setGlobalXGridFilters(newXGridFilters);

        break;
      }
      case EMPTY: {
        if (!filterName) return; // already empty
        setUpdatingGlobalFilters(true);

        const newXGridFilters = globalXGridFilters.filter(
          f => f.id !== GLOBAL_XGRID_CUSTOMER_OR_PROPERTY_FILTER_ID
        );

        const { jobOwnerId, ...otherFilters } = summaryFilter;
        setSummaryFilter(jobOwnerId ? { jobOwnerId } : {});

        setGlobalXGridFilters(newXGridFilters);
        setFilterName('');
        break;
      }
      default: {
        setUpdatingGlobalFilters(true);

        let newSummaryFilter = {};
        let filterValue;
        let filterOnXGridFieldName;
        if (value && value.entityType === 'Customer') {
          filterOnXGridFieldName = selectedFollowUpType.customerName;
          filterValue = value.customerName;
          newSummaryFilter = { customerId: value.id };
        } else if (value && value.entityType === 'CustomerProperty') {
          filterOnXGridFieldName = 'propertyName';
          filterValue = value.companyName;
          newSummaryFilter = { propertyId: value.id };
        }

        const existingXGridFilters = globalXGridFilters.filter(
          f => f.id !== GLOBAL_XGRID_CUSTOMER_OR_PROPERTY_FILTER_ID
        );

        const newXGridFilters = [
          ...existingXGridFilters,
          {
            columnField: filterOnXGridFieldName,
            operatorValue: 'equals',
            value: filterValue,
            id: GLOBAL_XGRID_CUSTOMER_OR_PROPERTY_FILTER_ID
          }
        ];

        const { jobOwnerId, ...otherFilters } = summaryFilter;
        setSummaryFilter(
          jobOwnerId ? { jobOwnerId, ...newSummaryFilter } : { ...newSummaryFilter }
        );
        setGlobalXGridFilters(newXGridFilters);
        setFilterName(filterValue);
        break;
      }
    }
    setUpdatingGlobalFilters(false);
  };

  if (error) {
    Logger.error('Failed to load followups summary, please try again later', error);
    props.snackbarOn('error', 'Failed to load followups summary, please try again later');
  }

  return (
    <ErrorBoundaries>
      <PageHeader pageMapKey="followUps" userLocale={user.locale} />
      <Grid container spacing={1}>
        {showPMFilters && (
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <ChipArray
              dataArr={[ALL, MY_JOBS]}
              disabled={updatingGlobalFilters || loading}
              handleClick={getGlobalFilters}
              selectedValue={globalFilterName}
            />
          </Grid>
        )}
        <Grid item lg={4} md={6} sm={12} xl={3} xs={12}>
          <AlgoliaSearch
            delimiter=" - "
            disabled={updatingGlobalFilters || loading}
            disableSearch={!!filterName}
            displayText={['customerName', 'companyName']}
            filters={[
              {
                attribute: 'entityType',
                valueArray: ['Customer', 'CustomerProperty']
              }
            ]}
            handleChange={getGlobalFilters}
            label="Filter by customer or property"
            name="Filter"
            searchIndex={defaultSearchIndex}
            showEntityNameInResults
            value={filterName}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {summaryData &&
            Object.keys(summaryData).map(summaryKey => (
              <HighlightCard
                handleClick={() => {
                  if (
                    selectedFollowUpType.customerName !== FollowUpTypes[summaryKey].customerName
                  ) {
                    const indexOfFilterThatNeedsToChange = globalXGridFilters.findIndex(
                      f =>
                        f.id === GLOBAL_XGRID_CUSTOMER_OR_PROPERTY_FILTER_ID &&
                        f.columnField.includes('ustomer')
                    );

                    if (indexOfFilterThatNeedsToChange !== -1) {
                      const newXGridFilters = globalXGridFilters.map((f, i) => {
                        if (i !== indexOfFilterThatNeedsToChange) return f;
                        return {
                          ...f,
                          columnField: FollowUpTypes[summaryKey].customerName
                        };
                      });
                      setGlobalXGridFilters(newXGridFilters);
                    }
                  }

                  setColumns(followUpcolumns[summaryKey]);
                  setSelectedFollowUpType(FollowUpTypes[summaryKey]);
                  setHighlightTableName(summaryKey);
                }}
                highlight={summaryData[summaryKey]}
                key={summaryTitles[summaryKey]?.title}
                selected={highlightTableName === summaryKey}
                title={summaryTitles[summaryKey]?.title}
                warn={summaryData[summaryKey] && summaryData[summaryKey] > 50}
              />
            ))}
        </Grid>

        <Grid item xs={12}>
          <div style={{ display: 'flex', height: '1000px', width: '100%' }}>
            <XGrid
              columns={columns}
              defaultSort={[{ field: selectedFollowUpType.sortField, sort: 'desc' }]}
              enableRefreshBtn
              entityTypeName="FollowUps"
              globalFilters={globalXGridFilters}
              key={highlightTableName}
              query={GET_FOLLOWUPS}
              queryVariables={{ followUpType: selectedFollowUpType.typeName }}
              tableName={`${highlightTableName}_FollowUpsXGrid`}
              onRefresh={refetch}
              onRemoveGlobalFilters={async removedFilterIds => {
                if (removedFilterIds.includes(GLOBAL_XGRID_MY_JOBS_FILTER_ID)) {
                  await getGlobalFilters(ALL);
                }
                if (removedFilterIds.includes(GLOBAL_XGRID_CUSTOMER_OR_PROPERTY_FILTER_ID)) {
                  await getGlobalFilters(EMPTY);
                }
              }}
            />
          </div>
        </Grid>
      </Grid>
    </ErrorBoundaries>
  );
};

const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});
const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const connectedFollowUp = connect(mapStateToProps, mapDispatchToProps)(FollowUp);

export default connectedFollowUp;
