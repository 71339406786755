import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import OutlineInfo from '@material-ui/icons/InfoOutlined';

// import IconButton from "@material-ui/core/IconButton";
import styles from './styles';

const TooltipIcon = props => (
  <Tooltip style={props.style} title={props.helpText}>
    {/* <IconButton
        aria-label={props.helpText}
        className={props.classes.iconSize}
      > */}
    <OutlineInfo className={props.classes.iconSize} />
    {/* </IconButton> */}
  </Tooltip>
);

export default withStyles(styles)(TooltipIcon);
