import React, { memo, useRef } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useDepartments, usePayrollHourTypes } from '@dispatch/queries';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import NonBillableEventForm from './NonBillableEventForm.component';
import NonBillableEventFormV2 from './NonBillableEventFormV2.component';

const DataProvider = props => {
  const isRecurringNves = useFlags()[FeatureFlags.RECURRING_NVES];
  const FormComponent = isRecurringNves ? NonBillableEventFormV2 : NonBillableEventForm;
  const Memoized = useRef(memo(FormComponent)).current;
  const departmentsResponse = useDepartments();
  const payrollHourTypesResponse = usePayrollHourTypes();
  return (
    <Memoized
      {...props}
      departmentsResponse={departmentsResponse}
      payrollHourTypesResponse={payrollHourTypesResponse}
    />
  );
};

export default DataProvider;
