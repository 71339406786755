import { every } from 'lodash';
import * as R from 'ramda';
import { v4 as uuid } from 'uuid';

import { TimeCardStatusTypes } from 'utils/AppConstants';
import toMap from 'utils/toMap';

export const calculateRemainingTime = ({
  startTime,
  endTime,
  lunchInTime,
  lunchOutTime,
  phases
}) => {
  const distributedTime = R.pipe(
    R.map(item => item?.timesheetEntries || []),
    R.flatten,
    R.map(item => item?.duration || 0),
    R.sum
  )(phases || []);
  const timeDiff = endTime > startTime ? endTime - startTime : 0;
  const lunchTimeDiff = lunchOutTime > lunchInTime ? lunchOutTime - lunchInTime : 0;
  const remainingTime = timeDiff - lunchTimeDiff - distributedTime;
  return (remainingTime / 3600).toFixed(2);
};

export const isValidLaborProductivity = data =>
  Boolean(
    data?.startTime && data?.endTime && data?.endTime > data?.startTime && data?.workers?.length
  );

export const isValidPhases = data => {
  const { phases } = data;
  const allPhasesValid = every(phases, phase =>
    Boolean(
      phase?.projectPhaseId &&
        phase?.projectPhaseDepartmentId &&
        phase?.projectPhaseDepartmentCostCodeId
    )
  );

  if (!allPhasesValid) {
    return false;
  }

  const remainingTime = calculateRemainingTime(data);
  return Math.abs(remainingTime) === 0;
};

export const isValidLaborFormData = data => {
  return isValidLaborProductivity(data) && isValidPhases(data);
};

export const getPhaseDeptCostCodesMapping = projectPhases =>
  projectPhases.reduce((phases, phase) => {
    const phaseDepts = phase.ProjectPhaseDepartment?.reduce((depts, department) => {
      const deptsCostCodes = department.ProjectPhaseDepartmentCostCode?.reduce(
        (costCodeIds, costCode) => {
          return { ...costCodeIds, [costCode.id]: costCode.id };
        },
        {}
      );
      return { ...depts, [department.id]: deptsCostCodes };
    }, {});
    return { ...phases, [phase.id]: phaseDepts };
  }, {});

export const getDefaultIdFromMapping = valueMapping =>
  Object.keys(valueMapping || {}).length === 1 ? Object.keys(valueMapping)[0] : undefined;

export const getDefaultIds = projectPhases => {
  const phaseDeptCostCodesMapping = getPhaseDeptCostCodesMapping(projectPhases);
  const defaultPhaseId = getDefaultIdFromMapping(phaseDeptCostCodesMapping);
  const defaultDepartment = phaseDeptCostCodesMapping?.[defaultPhaseId] || {};
  const defaultDepartmentId = getDefaultIdFromMapping(defaultDepartment);
  const defaultCostCode = defaultDepartment?.[defaultDepartmentId];
  const defaultCostCodeId = getDefaultIdFromMapping(defaultCostCode);

  return {
    defaultPhaseId,
    defaultDepartmentId,
    defaultCostCodeId
  };
};

export const phaseTemplate = ({ phaseIndex, payrollHourTypes, projectPhases }) => {
  const { defaultPhaseId, defaultDepartmentId, defaultCostCodeId } = getDefaultIds(projectPhases);

  return {
    phaseIndex,
    projectPhaseId: defaultPhaseId,
    projectPhaseDepartmentId: defaultDepartmentId,
    projectPhaseDepartmentCostCodeId: defaultCostCodeId,
    timesheetEntries: payrollHourTypes?.map(item => ({
      hourTypeId: item?.id,
      hourTypeAbbreviation: item?.hourTypeAbbreviation
    }))
  };
};

export const convertFormDataToEmployeesTimesheets = ({ formLaborData }) => {
  const { workers, phases, startTime, endTime } = formLaborData;
  return (
    workers?.reduce((result, employeeId) => {
      const employeeTimesheets =
        phases?.reduce((employeeTimesheetsResult, phase) => {
          return [
            ...employeeTimesheetsResult,
            {
              key: uuid(),
              employeeId,
              startTime,
              endTime,
              projectPhaseId: phase?.projectPhaseId || null,
              projectPhaseDepartmentId: phase?.projectPhaseDepartmentId || null,
              projectPhaseDepartmentCostCodeId: phase?.projectPhaseDepartmentCostCodeId || null,
              timesheetEntries:
                phase?.timesheetEntries?.map(timesheetEntry => ({
                  hourTypeId: timesheetEntry?.hourTypeId,
                  hourTypeAbbreviation: timesheetEntry?.hourTypeAbbreviation,
                  duration: parseFloat(timesheetEntry?.duration || 0)
                })) || []
            }
          ];
        }, []) || [];
      return [...result, ...employeeTimesheets];
    }, []) || []
  );
};

const mapTimesheetEntriesDuration = timesheetEntries =>
  timesheetEntries?.reduce(
    (resultMap, item) => ({
      ...resultMap,
      [item?.hourTypeId]: item?.duration || 0
    }),
    {}
  ) || {};

const mergeTimesheetEntries = (prevTimesheet, nextTimesheet) => {
  const nextTimesheetEntriesDurationMap = mapTimesheetEntriesDuration(
    nextTimesheet?.timesheetEntries
  );
  return {
    ...prevTimesheet,
    timesheetEntries:
      prevTimesheet?.timesheetEntries?.map(item => ({
        ...item,
        duration: (item?.duration || 0) + (nextTimesheetEntriesDurationMap[item.hourTypeId] || 0)
      })) || []
  };
};

export const mergeTimesheets = (prevTimesheets, nextTimesheets) => {
  const mergedTimesheetsMap = [...prevTimesheets, ...nextTimesheets].reduce((result, timesheet) => {
    const timesheetKey = `${timesheet?.employeeId}_${timesheet?.projectPhaseId}_${timesheet?.projectPhaseDepartmentId}_${timesheet?.projectPhaseDepartmentCostCodeId}`;
    const matchingTimesheet = result[timesheetKey];
    return {
      ...result,
      [timesheetKey]: matchingTimesheet
        ? mergeTimesheetEntries(matchingTimesheet, timesheet)
        : timesheet
    };
  }, {});
  return Object.values(mergedTimesheetsMap);
};

const getApprovedEmployees = ({ timesheets, employeesMap }) => {
  const isApproved = R.pipe(R.prop('timesheetStatus'), R.equals(TimeCardStatusTypes.APPROVED));
  return R.pipe(
    R.filter(isApproved),
    R.map(R.prop('employeeId')),
    R.uniq,
    R.map(id => ({
      ...(employeesMap[id] || {}),
      status: 'Approved'
    }))
  )(timesheets || []);
};

const getPendingEmployees = ({ timesheets, employeesMap }) => {
  const isPending = R.pipe(R.prop('timesheetStatus'), R.equals(TimeCardStatusTypes.DISPUTED));
  return R.pipe(
    R.filter(isPending),
    R.map(R.prop('employeeId')),
    R.uniq,
    R.map(id => ({
      ...(employeesMap[id] || {}),
      status: 'Pending Revision'
    }))
  )(timesheets || []);
};

export const getEmployeesWithApprovedOrPendingTimesheets = ({ timesheets, employees }) => {
  const employeesMap = toMap(employees);
  return [
    ...getApprovedEmployees({ timesheets, employeesMap }),
    ...getPendingEmployees({ timesheets, employeesMap })
  ];
};
