import React from 'react';

import { ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import Grid from '@material-ui/core/Grid';
import Linkify from 'react-linkify';
import Moment from 'react-moment';

import LinkButton from 'components/Buttons/BlueText';
import useEagerMultiNotes from 'customHooks/useEagerMultiNotes/useEagerMultiNotes';
import ViewableIcon from 'customHooks/useEagerMultiNotes/ViewableIcon';
import AppConstants from 'utils/AppConstants';

const style = {
  inputLabel: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'normal',
    letterSpacing: '0.01px',
    lineHeight: '14px'
  },
  label: {
    fontSize: 10,
    letterSpacing: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal'
  },
  bigContent: {
    color: '#3f3f3f',
    fontSize: 14,
    letterSpacing: 0.24,
    fontWeight: 'normal',
    lineHeight: 'normal',
    paddingTop: 7,
    whiteSpace: 'pre-line',
    wordBreak: 'break-word'
  },
  footer: {
    fontSize: 12,
    letterSpacing: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    paddingTop: 7,
    paddingBottom: 7
  },
  subject: {
    fontSize: 16,
    paddingTop: 7,
    paddingBottom: 7
  },
  subjectContainer: {
    display: 'flex'
  },
  icon: {
    marginTop: 7
  }
};

const Note = ({ hasShareWithTechsOption, ...note }) => (
  <ThemeProvider>
    <Linkify>
      <Grid css={style.subjectContainer} item lg={10} md={10} sm={12} xl={9} xs={12}>
        <Typography css={style.subject} variant={TV.PRINT} weight={TW.REGULAR}>
          {note?.subject || ''}
        </Typography>
        {hasShareWithTechsOption && (
          <div css={style.icon}>
            <ViewableIcon viewable={!note?.hideFromTechniciansOnMobile} />
          </div>
        )}
      </Grid>
      <Grid item lg={10} md={10} sm={12} xl={9} xs={12}>
        <Typography css={style.bigContent} variant={TV.S2} weight={TW.REGULAR}>
          {note?.note || ''}
        </Typography>
      </Grid>
      {note?.lastUpdatedDateTime && (
        <Grid css={style.footer} item lg={10} md={10} sm={12} xl={9} xs={12}>
          <Typography css={style.label}>
            Last edited by {note.displayLastUpdatedBy || note.lastUpdatedBy} on{' '}
            <Moment format={AppConstants.DATETIME_FORMAT} unix>
              {note.displayLastUpdatedDateTime || note.lastUpdatedDateTime / 1000}
            </Moment>
          </Typography>
        </Grid>
      )}
    </Linkify>
  </ThemeProvider>
);

const Notes = ({
  title,
  notesData,
  showAllNotes,
  noDataMessage,
  linkName,
  parent,
  refetch,
  isActive = true,
  shareWithTechsOption = true
}) => {
  const notes = (showAllNotes ? notesData : notesData?.slice(0, 2)) || [];
  const notesCountText = notesData?.length ? `(${notesData.length})` : '';

  const hasShareWithTechsOption = shareWithTechsOption;
  const { MultiNoteModal, openMultiNoteModal } = useEagerMultiNotes(
    refetch,
    hasShareWithTechsOption
  );

  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
          <Typography css={style.inputLabel} variant={TV.S3} weight={TW.REGULAR}>
            {title}
          </Typography>
          {notes.length === 0 && (
            <Typography css={style.label} variant={TV.S3} weight={TW.REGULAR}>
              {noDataMessage}
            </Typography>
          )}

          {notes.map(note => (
            <Note hasShareWithTechsOption={hasShareWithTechsOption} key={note.id} {...note} />
          ))}
          <Grid item lg={10} md={10} sm={12} xl={9} xs={12}>
            <LinkButton
              handle={() =>
                openMultiNoteModal({
                  name: parent.name,
                  id: parent.id,
                  entityType: parent.entityType,
                  notesCount: notesData?.length,
                  displayEntityType: parent.displayEntityType,
                  version: parent.version
                })
              }
              label={
                isActive ? `${linkName} ${notesCountText}` : `View all notes ${notesCountText}`
              }
              style={{ paddingLeft: 0, textAlign: 'left' }}
            />
          </Grid>
        </Grid>
      </Grid>
      {MultiNoteModal}
    </>
  );
};

export default Notes;
