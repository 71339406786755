import {
  column,
  ColumnType,
  enumFilterOperators,
  valueGetters
} from 'components/XGrid/columnTypes';

import { EnumType } from 'utils/constants';

import {
  renderAttachmentData,
  renderFormData,
  renderMoreButton,
  renderMoreInfoNeeded
} from './index.helper';

const taskColumns = props => [
  {
    field: 'customerPropertyName',
    headerName: 'Property',
    width: 151,
    valueGetter: ({ id, value, getValue, colDef, row }) => {
      const dataField = row?.quoteCustomerPropertyId
        ? 'quoteCustomerPropertyId'
        : 'customerPropertyId';
      return valueGetters.propertyLink({
        id,
        value: row?.quoteCustomerPropertyName || value,
        getValue,
        colDef: {
          ...colDef,
          dataField
        }
      });
    },
    ...column[ColumnType.LINK],
    hide: props.hideProperty
  },
  {
    field: 'assetValue',
    headerName: 'Asset',
    width: 150,
    valueGetter: ({ id, getValue, colDef, row }) => {
      const modifiedValue = row.quoteAssetValue || row.assetValue;
      const dataField = row?.quoteAssetId ? 'quoteAssetId' : 'assetId';
      return valueGetters.assetLink({
        id,
        value: { assetName: modifiedValue },
        getValue,
        colDef: { ...colDef, dataField }
      });
    },
    ...column[ColumnType.LINK]
  },
  {
    field: 'name',
    headerName: 'Recommendation',
    width: 150,
    valueGetter: ({ row }) => row.quoteName || row.name,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 277,
    valueGetter: ({ row }) => row.quoteDescription || row.description,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 110,
    enumType: EnumType.RECOMMENDED_TASK_STATUS,
    ...column[ColumnType.ENUM],
    filterOperators: enumFilterOperators.map(o => ({
      ...o,
      InputComponentProps: {
        customApplyValue: (applyFn, item, value) => {
          if (value === 'Recommended') {
            return applyFn({ ...item, value: 'Open' });
          }
          return applyFn({ ...item, value });
        }
      }
    }))
  },
  {
    field: 'moreInfoNeeded',
    headerName: 'Follow Up',
    width: 130,
    renderCell: ({ row }) => renderMoreInfoNeeded(row),
    ...column[ColumnType.BOOL],
    hide: props.hideFollowUp
  },
  {
    field: 'quoteIdentifier',
    headerName: 'Added to Quote',
    width: 150,
    valueGetter: ({ id, value, getValue, colDef }) =>
      valueGetters.quoteLink({
        id,
        value,
        getValue,
        colDef: { ...colDef, dataField: 'quoteId' }
      }),
    ...column[ColumnType.LINK],
    hide: props.selectedTab === 0
  },
  {
    field: 'jobIdentifier',
    headerName: 'Added to Job',
    width: 150,
    valueGetter: valueGetters.jobLink,
    ...column[ColumnType.LINK],
    hide: props.selectedTab === 0 || props.selectedTab === 1
  },
  {
    field: 'formData',
    headerName: 'Forms',
    width: 180,
    renderCell: ({ value }) => renderFormData(value)
  },
  {
    field: 'attachmentData',
    headerName: 'Attachments',
    width: 150,
    renderCell: ({ value }) => renderAttachmentData(value)
  },
  {
    field: 'submittedFrom',
    headerName: 'Submitted From',
    width: 170,
    valueGetter: valueGetters.jobVisitLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'createdDate',
    headerName: 'Created Date',
    width: 150,
    ...column[ColumnType.DATE],
    hide: props.hideCreatedDate
  },
  {
    field: 'action', // doesnt matter as we are not using the value
    headerName: ' ',
    width: 36,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => renderMoreButton({ row, ...props }),
    hide: props.selectedTab !== 0
  }
];

export default taskColumns;
