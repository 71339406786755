const taskSubtotal = ({
  convertToCurrencyStringCheck,
  settingsTotals,
  SettingConstants,
  smartFieldInfo,
  styles,
  fontFamilyStyle
}) =>
  settingsTotals[SettingConstants.SHOW_TASK_SUBTOTAL]
    ? `<tr>
    <td style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);width:50%;" colspan="2"><strong>Task Subtotal</strong></td>
    <td colspan="3" class="restricted" style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);text-align:right;"><span fontfamily=${fontFamilyStyle} >${convertToCurrencyStringCheck(
        smartFieldInfo.taskSubTotal
      )}</span></td>
  </tr>`
    : '';

export default taskSubtotal;
