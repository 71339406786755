import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';

import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import withStyles from '@material-ui/core/styles/withStyles';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    height: 36,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#F0F0F0',
    borderRadius: 2,
    width: 218,
    padding: 8
  },
  icon: {
    fontSize: 16,
    color: '#999999'
  },
  input: {
    width: '100%',
    fontSize: 14,
    letterSpacing: '-0.02em',
    marginLeft: 8
  },
  iconButton: {
    color: '#999999',
    fontSize: 16,
    transform: 'scale(1, 1)',
    transition: theme.transitions.create(['transform', 'color'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut
    })
  },
  iconButtonHidden: {
    transform: 'scale(0, 0)',
    '& > $icon': {
      opacity: 0
    }
  }
});

const CustomSearchBar = forwardRef(
  ({ className, classes, style, onCancelSearch, onRequestSearch, ...inputProps }, ref) => {
    const inputRef = useRef();
    const [value, setValue] = useState(inputProps.value);

    useEffect(() => {
      setValue(inputProps.value);
    }, [inputProps.value]);

    const handleFocus = useCallback(
      e => {
        if (inputProps.onFocus) {
          inputProps.onFocus(e);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [inputProps.onFocus]
    );

    const handleRequestSearch = useCallback(() => {
      if (onRequestSearch) {
        onRequestSearch(value);
      }
    }, [onRequestSearch, value]);

    const handleInput = useCallback(
      e => {
        setValue(e.target.value);
        if (e.target.value.length === 0 && onCancelSearch) {
          onCancelSearch();
          return;
        }
        if (inputProps.onChange) {
          inputProps.onChange(e.target.value);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [inputProps.onChange]
    );

    const handleCancel = useCallback(() => {
      setValue('');
      if (onCancelSearch) {
        onCancelSearch();
      }
    }, [onCancelSearch]);

    const handleKeyUp = useCallback(
      e => {
        if (e.charCode === 13 || e.key === 'Enter') {
          handleRequestSearch();
        } else if (e.charCode === 27 || e.key === 'Escape') {
          handleCancel();
        }
        if (inputProps.onKeyUp) {
          inputProps.onKeyUp(e);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [handleRequestSearch, handleCancel, inputProps.onKeyUp]
    );

    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current.focus();
      }
    }));

    return (
      <div className={classNames(classes.root, className)} style={style}>
        <SearchIcon className={classes.icon} />
        <Input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...inputProps}
          className={classes.input}
          disableUnderline
          fullWidth
          inputRef={inputRef}
          value={value}
          onChange={handleInput}
          onFocus={handleFocus}
          onKeyUp={handleKeyUp}
        />
        <IconButton
          className={classNames(classes.iconButton, {
            [classes.iconButtonHidden]: value === ''
          })}
          onClick={handleCancel}
        >
          <ClearIcon className={classes.icon} />
        </IconButton>
      </div>
    );
  }
);

CustomSearchBar.defaultProps = {
  className: '',
  placeholder: 'Search',
  value: ''
};

export default withStyles(styles)(CustomSearchBar);
