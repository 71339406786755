import React from 'react';

import { Typography } from '@BuildHero/sergeant';

import { useStyles } from './EmptyQuoteListMessage.styles';

const EmptyQuoteListMessage = () => {
  const styles = useStyles();
  return <Typography css={styles.text}>This job does not have any quotes associated</Typography>;
};

export default EmptyQuoteListMessage;
