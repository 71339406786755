import { isArray, isUndefined } from 'lodash';

import mergeDeep from 'utils/mergeDeep';

export const patchArray = (arr, update) => {
  if (isArray(update)) return update.reduce((acc, item) => patchArray(acc, item), arr);

  const i = arr.findIndex(prev => prev.id === update.id);

  return isUndefined(i)
    ? [update, ...arr]
    : [...arr.slice(0, i), mergeDeep(arr[i], update), ...arr.slice(i + 1)];
};
