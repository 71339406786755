import { connect } from 'react-redux';

import { withQueryStringStore } from '@dispatch/Dispatch.store';
import { refreshTableData } from 'redux/actions/dispatchActions';
import compose from 'utils/compose';

import VisitsTabs from './VisitsTabs.component';

const mapQueryStringToProps = store => ({
  tab: store.state.tab,
  openTab: store.openTab,
  trayOpen: Boolean(store.state.trayOpen)
});

const mapDispatchToProps = {
  refreshTableData
};

export default compose(
  withQueryStringStore(mapQueryStringToProps),
  connect(null, mapDispatchToProps)
)(VisitsTabs);
