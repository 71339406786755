import React from 'react';

import { Divider, ThemeProvider } from '@BuildHero/sergeant';
import { useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { PageHeader, UserPermission } from 'components';
import ErrorBoundary from 'scenes/Error';
import { PermissionConstants } from 'utils/AppConstants';

import ServiceChannelList from './ServiceChannelList.component';

const ServiceChannelContainer = () => {
  const user = useSelector(state => state.user);
  const { spacing } = useTheme();

  return (
    <UserPermission action={PermissionConstants.OBJECT_JOB} I="read">
      <ErrorBoundary>
        <PageHeader pageMapKey="serviceChannel" userLocale={user.locale} />
        <ThemeProvider>
          <Divider marginLeft={-spacing(3)} width={`calc(100% + ${spacing(6)}px)`} />
        </ThemeProvider>
        <ServiceChannelList />
      </ErrorBoundary>
    </UserPermission>
  );
};

export default ServiceChannelContainer;
