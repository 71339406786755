import React, { Component, Fragment } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Moment from 'react-moment';
import { connect } from 'react-redux';

import ConfirmModal from 'components/Modal/ConfirmDialog';
import { snackbarOn, spinnerOff, spinnerOn } from 'redux/actions/globalActions';
import { CommonService } from 'services/core';

import { Logger } from 'services/Logger';
import AppConstants from 'utils/AppConstants';

import styles from './styles';

class Note extends Component {
  constructor(props) {
    super(props);
    this.CommonService = new CommonService();
    this.state = {
      mode: props.data.id ? 'view' : 'edit',
      note: props.data,
      oldNote: props.data,
      relationName: props.relationName,
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: ''
    };
  }

  handleOnEdit = note => {
    this.setState({ mode: 'edit', note });
  };

  handleOnChange = (note, field) => event => {
    const newNote =
      field === 'note'
        ? { ...note, note: event.target.value }
        : { ...note, subject: event.target.value };
    this.setState({ note: newNote });
  };

  handleSave = async note => {
    if (note.note.trim() === '' && (!note.subject || note.subject?.trim() === '')) {
      this.props.snackbarOn('error', 'Cannot save an empty note');
      return;
    }
    const { mutationService, user } = this.props;

    const localNote = note;
    localNote.tenantId = user.tenantId;
    try {
      let data;
      let id;
      let partitionKey;
      let sortKey;
      if (mutationService) {
        ({ data } = await mutationService(localNote, this.props.parent, this.state.relationName));
      } else {
        ({ data } = await this.CommonService.mutateNoteWithRelationName(
          localNote,
          this.props.parent,
          this.state.relationName
        ));
        // eslint-disable-next-line prefer-destructuring
        ({ id, partitionKey, sortKey } = data.batchCreateEntityData[0]);
      }

      if (data) {
        this.props.snackbarOn('success', 'Successfully posted');
        const savedNote = { ...localNote, id, partitionKey, sortKey };
        this.setState({ mode: 'view', note: savedNote, oldNote: savedNote });
      }
    } catch (error) {
      Logger.error(error);
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        this.props.snackbarOn('error', error.graphQLErrors[0].message);
      } else {
        this.props.snackbarOn('error', 'Unable to save changes, please try again later');
      }
    }
  };

  handleCancel = note => {
    if (note.id) {
      this.setState(prevState => ({ mode: 'view', note: prevState.oldNote }));
    } else {
      this.setState({ note: '' });
    }
  };

  handleCancelConfirmation = () => {
    this.setState({ confirmDialog: false, confirmAction: '', confirmMessage: '' });
  };

  handleOnDelete = note => {
    this.setState({
      confirmDialog: true,
      confirmAction: () => this.deleteNote(note),
      confirmMessage: 'note'
    });
  };

  deleteNote = async note => {
    if (note.id) {
      try {
        const { partitionKey, sortKey } = note;
        const { data } = await this.CommonService.deleteNote(partitionKey, sortKey);
        if (data) {
          this.props.snackbarOn('success', 'Successfully deleted');
          this.props.deleteHandle(note);
        }
      } catch (error) {
        Logger.error(error);
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          this.props.snackbarOn('error', error.graphQLErrors[0].message);
        } else {
          this.props.snackbarOn('error', 'Unable to delete, please try again later');
        }
      }
    } else {
      this.props.deleteHandle(note);
    }
  };

  render() {
    const item = this.state.note;
    const { index, classes } = this.props;
    const { mode } = this.state;

    return (
      <Fragment key={`gridindc${index}`}>
        {item && mode === 'view' && (
          <Fragment key={`noteKeyFrag${index}`}>
            <Grid item key={`notegrid${index}`} xs={10}>
              <Typography className={classes.content} key={`notedata${index}`}>
                <Typography>{item.subject}</Typography>
                {item.note}
              </Typography>
            </Grid>
            <Grid item key={`iconsGride${index}`} xs={1}>
              <IconButton
                aria-label="Edit"
                className={classes.iconButton}
                key={`iconButton${item.id}`}
                onClick={() => this.handleOnEdit(item)}
              >
                <EditIcon className={classes.iconStyle} key={`editIconButton${item.id}`} />
              </IconButton>
            </Grid>
            <Grid item key={`iconsGridd${index}`} xs={1}>
              <IconButton
                aria-label="Delete"
                className={classes.iconButton}
                key={`iconDeleteButton${item.id}`}
                onClick={() => this.handleOnDelete(item)}
              >
                <DeleteIcon className={classes.iconStyle} key={`deleteIconButton${item.id}`} />
              </IconButton>
            </Grid>
          </Fragment>
        )}
        {item.note && mode === 'edit' && (
          <Fragment key={`noteKeyFrag${index}`}>
            <Grid
              item
              key={`subjectgrid${index}`}
              style={{ paddingTop: 5, paddingBottom: 2 }}
              xs={12}
            >
              <TextField
                key={`subjectKeyFragTextInput${index}`}
                label="Subject"
                name={`input${index}`}
                style={{ paddingTop: 4, paddingBottom: 20 }}
                // InputProps={{ style: { height: 88 } }}
                value={this.state.note.subject}
                variant="filled"
                onChange={this.handleOnChange(item, 'subject')}
              />
            </Grid>
            <Grid item key={`notegrid${index}`} style={{ paddingTop: 5, paddingBottom: 2 }} xs={12}>
              <TextField
                fullWidth
                InputProps={{ style: { height: 88 } }}
                key={`noteKeyFragTextInput${index}`}
                label="Note"
                multiline
                name={`input${index}`}
                rows="3"
                rowsMax="3"
                style={{ paddingBottom: 20 }}
                value={this.state.note.note}
                variant="filled"
                onChange={this.handleOnChange(item, 'note')}
              />
            </Grid>
            <Grid item key={`buttonEdit${index}`} style={{ padding: 5, marginLeft: '70%' }}>
              <Button
                classes={{
                  outlinedSecondary: classes.buttonOutlinedSecondary
                }}
                key={`buttonEditBtncancel${index}`}
                onClick={() => this.handleCancel(item)}
              >
                Cancel
              </Button>
              <Button
                classes={{
                  outlinedSecondary: classes.buttonOutlinedSecondary
                }}
                key={`buttonEditBtnSave${index}`}
                onClick={() => this.handleSave(this.state.note)}
              >
                Save
              </Button>
            </Grid>
          </Fragment>
        )}
        {item.lastUpdatedDateTime && (
          <Grid className={classes.footer} item key={`footer${index}`} xs={12}>
            <Typography className={classes.label} key={`footerTypo${index}`} variant="caption">
              Last edited by {item.lastUpdatedBy} on &nbsp;
              <Moment format={AppConstants.DATETIME_FORMAT} key={`footerDate${index}`} unix>
                {item.lastUpdatedDateTime / 1000}
              </Moment>
            </Typography>
          </Grid>
        )}
        <ConfirmModal
          cancel={this.handleCancelConfirmation}
          confirm={this.state.confirmAction}
          key={`confirmModal${index}`}
          message={this.state.confirmMessage}
          open={this.state.confirmDialog}
        />
      </Fragment>
    );
  }
}
const styleNode = withStyles(styles, { withTheme: true })(Note);

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message)),
  spinnerOn: () => dispatch(spinnerOn()),
  spinnerOff: () => dispatch(spinnerOff())
});

const reduxConnectedNote = connect(mapStateToProps, mapDispatchToProps)(styleNode);

export default reduxConnectedNote;
