import React from 'react';

import { Field, FieldType, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { Box, useTheme } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { Notes } from 'components';
import theme from 'themes/BuildHeroTheme';

// Take data from GET_INVOICE in src/scenes/Invoices/InvoiceDetail/InvoiceDetail.gql.js
// via formatInvoiceData in src/scenes/Invoices/InvoiceDetail/InvoiceDetail.utils.js,
// emit props suitable for InvoiceInfoPanel.
export const normalizePropsFromInvoiceData = data => {
  let billingCustomerProps = {};
  if (data.billingCustomer?.id && data.billingCustomer.id !== data.customer?.id) {
    billingCustomerProps = {
      billingCustomerLinkLabel: data?.billingCustomer?.link?.label,
      billingCustomerLinkTo: data?.billingCustomer?.link?.to,
      billingCustomerNotes: data?.billingCustomer?.notes || [],
      billingCustomerTags: data?.billingCustomer?.tags || []
    };
  }

  return {
    ...billingCustomerProps,
    customerLinkLabel: data?.customer?.link?.label,
    customerLinkTo: data?.customer?.link?.to,
    customerNotes: data?.customer?.notes || [],
    customerTags: data?.customer?.tags || [],
    billingAddress: data?.billingAddressString,
    propertyLinkLabel: data?.customerProperty?.link?.label,
    propertyLinkTo: data?.customerProperty?.link?.to,
    propertyAddress: data?.propertyAddress,
    propertyType: data?.customerProperty?.customerPropertyTypeValue,
    propertyNotes: data?.customerProperty?.notes || [],
    propertyRep: data?.job?.customerRepName,
    jobType: data?.job?.jobTypeName,
    jobIssueDescription: data?.job?.issueDescription,
    jobNotes: data?.job?.notes || [],
    jobTags: data?.job?.tags || [],
    jobAmountQuoted: data?.job?.amountQuoted,
    jobCostAmount: data?.job?.costAmount
  };
};

function InvoiceInfoPanel({
  billingCustomerLinkLabel,
  billingCustomerLinkTo,
  billingCustomerNotes,
  billingCustomerTags,
  billingAddress,
  customerLinkLabel,
  customerLinkTo,
  customerNotes,
  customerTags,
  propertyLinkLabel,
  propertyLinkTo,
  propertyAddress,
  propertyType,
  propertyNotes,
  propertyRep,
  jobType,
  jobIssueDescription,
  jobNotes,
  jobTags,
  jobAmountQuoted,
  jobCostAmount
}) {
  const hasBillingCustomerLink = !!billingCustomerLinkLabel && !!billingCustomerLinkTo;
  const hasBillingCustomerNotes = billingCustomerNotes && billingCustomerNotes.length > 0;
  const hasBillingCustomerTags = billingCustomerTags && billingCustomerTags.length > 0;
  const hasCustomerLink = !!customerLinkLabel && !!customerLinkTo;
  const hasCustomerNotes = customerNotes && customerNotes.length > 0;
  const hasCustomerTags = customerTags && customerTags.length > 0;
  const hasAnyCustomerInfo =
    hasBillingCustomerLink ||
    hasBillingCustomerNotes ||
    hasBillingCustomerTags ||
    hasCustomerLink ||
    hasCustomerTags ||
    hasCustomerNotes ||
    !!billingAddress;

  const hasPropertyLink = !!propertyLinkLabel && !!propertyLinkTo;
  const hasPropertyNotes = propertyNotes && propertyNotes.length > 0;
  const hasAnyPropertyInfo = hasPropertyLink || hasPropertyNotes || propertyType || propertyRep;

  const hasJobTags = jobTags && jobTags.length > 0;
  const hasJobNotes = jobNotes && jobNotes.length > 0;
  const hasAnyJobInfo =
    !!jobType ||
    !!jobIssueDescription ||
    hasJobNotes ||
    hasJobTags ||
    !!jobAmountQuoted ||
    !!jobCostAmount;

  const { spacing } = useTheme();

  return (
    <ThemeProvider>
      <Box
        display="flex"
        flexDirection="column"
        style={{ rowGap: spacing(2), width: '100%', overflowWrap: 'break-word' }}
      >
        {hasAnyCustomerInfo && (
          <Typography variant={TV.BASE} weight={TW.BOLD}>
            Customer Information
          </Typography>
        )}

        {hasBillingCustomerLink && (
          <Field
            label="Billing Customer"
            margin={24}
            testingid="billingCustomerName"
            type={FieldType.LINK}
            value={{ label: billingCustomerLinkLabel, to: billingCustomerLinkTo }}
          />
        )}

        {hasBillingCustomerNotes && (
          <MuiThemeProvider theme={theme}>
            <Notes
              isActive={false}
              notesData={billingCustomerNotes}
              showAllNotes
              subtitle="Notes on Billing Customer"
              title="Billing Customer Notes"
            />
          </MuiThemeProvider>
        )}

        {hasBillingCustomerTags && (
          <Field
            label="Billing Customer Tags"
            testingid="billing-customer-tags"
            type={FieldType.TAG}
            value={billingCustomerTags}
          />
        )}

        {hasCustomerLink && (
          <Field
            label="Customer"
            testingid="customerName-invoices"
            type={FieldType.LINK}
            value={{ label: customerLinkLabel, to: customerLinkTo }}
          />
        )}

        {hasCustomerNotes && (
          <MuiThemeProvider theme={theme}>
            <Notes
              isActive={false}
              notesData={customerNotes}
              showAllNotes
              subtitle="Notes on Customer"
              title="Customer Notes"
            />
          </MuiThemeProvider>
        )}

        {hasCustomerTags && (
          <Field
            label="Customer Tags"
            testingid="customerTags"
            type={FieldType.TAG}
            value={customerTags}
          />
        )}

        {billingAddress && (
          <Field
            label="Billing Address"
            testingid="customerBillingAddress-invoices"
            type={FieldType.TEXT}
            value={billingAddress}
          />
        )}

        {hasAnyPropertyInfo && (
          <Typography style={{ marginTop: spacing(1) }} variant={TV.BASE} weight={TW.BOLD}>
            Property Information
          </Typography>
        )}

        {hasPropertyLink && (
          <Field
            label="Property"
            testingid="propertyName-invoices"
            type={FieldType.LINK}
            value={{ label: propertyLinkLabel, to: propertyLinkTo }}
          />
        )}

        {propertyAddress && (
          <Field
            label="Address"
            testingid="propertyAddress-invoices"
            type={FieldType.TEXT}
            value={propertyAddress}
          />
        )}

        {propertyType && (
          <Field
            label="Property Type"
            testingid="propertyType-invoices"
            type={FieldType.TEXT}
            value={propertyType}
          />
        )}

        {hasPropertyNotes && (
          <MuiThemeProvider theme={theme}>
            <Notes
              isActive={false}
              notesData={propertyNotes}
              showAllNotes
              subtitle="Notes on Property"
              title="Property Instructions"
            />
          </MuiThemeProvider>
        )}

        {propertyRep && (
          <Field
            label="Property Representative"
            testingid="propertyRepresentative-invoices"
            type={FieldType.TEXT}
            value={propertyRep}
          />
        )}

        {hasAnyJobInfo && (
          <Typography style={{ marginTop: spacing(1) }} variant={TV.BASE} weight={TW.BOLD}>
            Job Information
          </Typography>
        )}

        {jobType && (
          <Field label="Job Type" testingid="jobType" type={FieldType.TEXT} value={jobType} />
        )}

        {jobIssueDescription && (
          <Field
            label="Issue Description"
            testingid="issueDescription-invoices"
            type={FieldType.TEXT}
            value={jobIssueDescription}
          />
        )}

        {hasJobNotes && (
          <MuiThemeProvider theme={theme}>
            <Notes
              isActive={false}
              notesData={jobNotes}
              showAllNotes
              subtitle="Notes on Job"
              title="Office Notes"
            />
          </MuiThemeProvider>
        )}

        {hasJobTags && (
          <Field
            label="Job Tags"
            testingid="jobTags-invoices"
            type={FieldType.TAG}
            value={jobTags}
          />
        )}

        {jobAmountQuoted && (
          <Field
            label="Amount Quoted"
            testingid="amountQuoted-invoices"
            type={FieldType.CURRENCY}
            value={jobAmountQuoted}
          />
        )}

        {jobCostAmount && (
          <Field
            label="Cost Amount"
            testingid="costAmount-invoices"
            type={FieldType.CURRENCY}
            value={jobCostAmount}
          />
        )}
      </Box>
    </ThemeProvider>
  );
}

InvoiceInfoPanel.propTypes = {
  billingCustomerLinkLabel: PropTypes.string,
  billingCustomerLinkTo: PropTypes.string,
  billingCustomerNotes: PropTypes.array,
  billingCustomerTags: PropTypes.array,
  billingAddress: PropTypes.string,
  customerLinkLabel: PropTypes.string,
  customerLinkTo: PropTypes.string,
  customerNotes: PropTypes.array,
  customerTags: PropTypes.array,
  propertyLinkLabel: PropTypes.string,
  propertyLinkTo: PropTypes.string,
  propertyAddress: PropTypes.string,
  propertyNotes: PropTypes.array,
  propertyRep: PropTypes.string,
  jobType: PropTypes.string,
  jobIssueDescription: PropTypes.string,
  jobNotes: PropTypes.array,
  jobTags: PropTypes.array,
  jobAmountQuoted: PropTypes.number,
  jobCostAmount: PropTypes.number
};

InvoiceInfoPanel.defaultProps = {
  billingCustomerLinkLabel: undefined,
  billingCustomerLinkTo: undefined,
  billingCustomerNotes: [],
  billingCustomerTags: [],
  billingAddress: undefined,
  customerLinkLabel: undefined,
  customerLinkTo: undefined,
  customerNotes: [],
  customerTags: [],
  propertyLinkLabel: undefined,
  propertyLinkTo: undefined,
  propertyAddress: undefined,
  propertyNotes: [],
  propertyRep: undefined,
  jobType: undefined,
  jobIssueDescription: undefined,
  jobNotes: [],
  jobTags: [],
  jobAmountQuoted: undefined,
  jobCostAmount: undefined
};

export default InvoiceInfoPanel;
