import React from 'react';

import { Button, MUIForm, ThemeProvider } from '@BuildHero/sergeant';
import { useTheme } from '@material-ui/core';

import PopOverButton from 'components/PopOverButton';
import useDepartments from 'customHooks/useDepartments';

import SavedFilter from './SavedFilter';

import { useStyles } from './TimesheetFilter.styles';

const getNumberOfAppliedFilters = ({ filterState }) => {
  return Object.values(filterState).reduce((result, values) => {
    if (values?.length) {
      return result + 1;
    }
    return result;
  }, 0);
};

const getButtonText = ({ numberOfAppliedFilters, buttonText }) => {
  if (numberOfAppliedFilters === 1) {
    return '1 Filter Applied';
  }
  if (numberOfAppliedFilters >= 1) {
    return `${numberOfAppliedFilters} Filters Applied`;
  }
  return buttonText;
};

const getOptions = items => {
  return (
    items
      ?.filter(item => item?.name && item?.id)
      .map(item => ({ label: item.name, value: item.id })) || []
  );
};

const filterText = {
  departments: {
    label: 'Departments',
    placeholder: 'All departments'
  },
  crews: {
    label: 'Crews',
    placeholder: 'All Crews'
  },
  technicians: {
    label: 'Technicians',
    placeholder: 'Select Technicians'
  }
};

const useFilterConfig = ({ filterOptions }) => {
  const theme = useTheme();

  const options = {
    marginBottom: theme.spacing(3)
  };

  return {
    fields: {},
    layouts: {
      default: {
        contents: Object.entries(filterOptions).map(([source, data]) => {
          return {
            options,
            contents: [
              {
                component: 'SelectInput',
                source,
                options: {
                  label: filterText[source]?.label,
                  placeholder: filterText[source]?.placeholder,
                  isMultipleSelection: true,
                  isSearchable: true,
                  valueSet: getOptions(data)
                }
              }
            ]
          };
        })
      }
    }
  };
};

const TimesheetFilter = ({ filterBy, filter, employees, crews: crewsFilterOptions }) => {
  const [departmentsResponse] = useDepartments();

  const { departments, crews, technicians } = filter;

  const classes = useStyles();

  const handleFilterChange = (field, value) => {
    filterBy(oldFilter => ({ ...oldFilter, [field]: value }));
  };

  const handleFiltersClear = () => {
    filterBy({
      departments: undefined,
      crews: undefined,
      technicians: undefined
    });
  };

  const configuration = useFilterConfig({
    filterOptions: {
      departments: departmentsResponse,
      crews: crewsFilterOptions,
      technicians: employees
    }
  });

  const numberOfAppliedFilters = getNumberOfAppliedFilters({ filterState: filter });

  const text = getButtonText({ numberOfAppliedFilters, buttonText: 'Timesheet Filter' });

  return (
    <PopOverButton
      buttonText={text}
      classes={{ button: numberOfAppliedFilters ? classes.withFilters : classes.withoutFilters }}
      type="select"
    >
      <ThemeProvider>
        <SavedFilter
          filterBy={filterBy}
          filterData={filter}
          handleFiltersClear={handleFiltersClear}
        />
      </ThemeProvider>
      <MUIForm
        configuration={configuration}
        data={{ departments, crews, technicians }}
        onFieldChange={handleFilterChange}
      />
      <ThemeProvider>
        <Button type="tertiary" onClick={handleFiltersClear}>
          Clear all
        </Button>
      </ThemeProvider>
    </PopOverButton>
  );
};

export default TimesheetFilter;
