import { calculateMarkupFromMargin } from '@BuildHero/math';

import { roundCurrency, roundFloat } from 'utils';
import { InvoiceItemType } from 'utils/constants';
import { convertForMathLib } from 'utils/mathLibrary';

const costableTypes = [InvoiceItemType.INVENTORY_PART, InvoiceItemType.PUCHASE_ORDER_LINE];

export const formatForInvoiceItemMutation = (item, isMarginEnabled) => ({
  name: item.name,
  description: item.description || null,
  lineItemType: item.type?.value || item.lineItemType,
  quantity: item.quantity,
  unitPrice: roundCurrency(item.unitPrice),
  unitCost: costableTypes.includes(item.lineItemType) ? roundFloat(item.unitCost) : 0,
  amount: roundCurrency(item.amount),
  taxable: !!item.taxable,
  markupValue: isMarginEnabled
    ? convertForMathLib(calculateMarkupFromMargin, item.marginValue)
    : roundFloat(item.markupValue),
  productSortKey: item.productSortKey,
  productId: item.productId,
  accountingRefIdOfEntity: item.accountingRefIdOfEntity,
  accountingRefIdOfClass: item.department?.accountingRefIdOfClass || null,
  departmentId: item.department?.value || null,
  jobCostTypeId: item.jobCostType?.value || null,
  costCodeId: item.costCode?.value || null,
  revenueTypeId: item.revenueType?.value || null,
  jcContractItemId: item.jcContractItem?.value || null,
  sortOrder: item.sortOrder,
  version: item.version,
  id: item.id
});
