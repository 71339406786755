import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { ThemeProvider, TV, TW } from '@BuildHero/sergeant';
import { Box, FormControlLabel, Switch, Typography, useTheme } from '@material-ui/core';

import { ResponsiveTable } from 'components';
import useCostCodes from 'customHooks/useCostCodes';
import JobCostingReportTable, { costRows, revenueRows } from 'meta/Jobs/JobCostingReportTable';
import { useUpdateJobCostingValues } from 'scenes/Jobs/JobDetail/mutations/useUpdateJobCostingValues';
import { useGetDerivedJobCosting } from 'scenes/Jobs/JobDetail/queries/useGetDerivedJobCosting';
import { convertToCurrencyString, logErrorWithCallback } from 'utils';

import { calculateGross, getCostReport, getReportsByCostCode, getRevenueReport } from './helpers';
import { JobCostingCloseoutTable } from './JobCostingCloseout';
import { JobCostingLabor } from './JobCostingLabor';
import { getJobCostingReport } from './JobCostingReport.gql';
import useStyles from './JobCostingReport.styles';

const getVariant = isOverAllTotal => (isOverAllTotal ? 'h6' : 'body2');

const findItemValue = (list, value, dataKey) => {
  const item = list.find(({ id }) => id === value);
  return item?.[dataKey] || 'Unspecified';
};

const Total = ({ record, meta }) => {
  const { id, fieldType } = meta;
  const { [id]: total, isOverAllTotal = false } = record;

  if (!total && total !== 0) return null;
  const valueMap = {
    currency: convertToCurrencyString(total),
    percentage: `${total}%`
  };
  const value = valueMap[fieldType];
  const variant = getVariant(isOverAllTotal);
  return <Typography variant={variant}>{value || '-'}</Typography>;
};

const CostTypeLabel = ({ record }) => {
  const { name, isOverAllTotal } = record;
  if (isOverAllTotal) return null;
  return <Typography variant="h6">{name || '-'}</Typography>;
};

const JobCostingReport = ({ jobId, jobData, user, snackbarOn }) => {
  const classes = useStyles();
  const commonArgs = [user.tenantId, user.tenantCompanyId, snackbarOn];
  const [costCodes] = useCostCodes(...commonArgs);

  const { data, loading, error } = useQuery(getJobCostingReport, {
    variables: { partitionKey: user.tenantId, jobId },
    fetchPolicy: 'network-only' // as lot of items can be removed and added to job in realtime.
  });

  const reports = data?.getJobCostingReport || [];

  if (error) {
    logErrorWithCallback(
      error,
      snackbarOn,
      'unable to get the job costing report, please try again later.'
    );
  }

  const { detailedJobCostingEnabled } = jobData;
  const [isDetailView, setIsDetailView] = React.useState(detailedJobCostingEnabled ?? true);
  const [detailedReports, setDetailedReports] = React.useState();

  const generateDetailView = () => {
    if (!loading && reports !== undefined) {
      const reportsGroupByCostCode = getReportsByCostCode(reports);
      const { grossProfit, grossMargin } = calculateGross(reportsGroupByCostCode);
      const totals = reportsGroupByCostCode.reduce(
        (acc, b) => ({
          revenue: acc.revenue + b.revenue,
          cost: acc.cost + b.cost
        }),
        { revenue: 0, cost: 0 }
      );

      const reportWithTotalsRow = [
        ...reportsGroupByCostCode,
        {
          cost: totals.cost,
          grossMargin,
          profit: grossProfit,
          revenue: totals.revenue,
          totalsRow: 'totalsRow'
        }
      ];

      setDetailedReports(reportWithTotalsRow);
    }
  };

  const toggleDetailView = () => {
    if (!isDetailView) {
      generateDetailView();
    }
    setIsDetailView(!isDetailView);
  };

  // by default the detail view is on, generate the report
  if (!detailedReports && isDetailView) {
    generateDetailView();
  }

  // though detailedJobCostingEnabled cannot be modified in edit view,
  // user can navigate tabs before getting full job info which has the setting
  React.useEffect(() => {
    setIsDetailView(detailedJobCostingEnabled);
  }, [detailedJobCostingEnabled]);

  const revenueReport = getRevenueReport(reports);
  const costReport = getCostReport(reports);

  const { grossProfit, grossMargin } = calculateGross(reports);

  const detailViewItems = [
    { title: 'Revenue', data: revenueReport, meta: revenueRows },
    { title: 'Cost', data: costReport, meta: costRows }
  ];

  const label = isDetailView ? 'Detailed View' : 'Simple View';
  const subtext = isDetailView ? 'Broken out by Cost Code' : 'Roll-up by Cost Type';

  const theme = useTheme();

  if (jobData && jobData?.closeoutReport) {
    return (
      <ThemeProvider>
        <Typography css={{ marginBottom: theme.spacing(2) }} variant={TV.L} weight={TW.BOLD}>
          By Cost Code
        </Typography>
        <JobCostingCloseoutTable byCost="code" jobData={jobData} />
        <Typography
          css={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }}
          variant={TV.L}
          weight={TW.BOLD}
        >
          By Cost Type
        </Typography>
        <JobCostingCloseoutTable byCost="type" jobData={jobData} />
        <Box css={{ marginTop: theme.spacing(2) }} display="flex">
          <JobCostingLabor jobId={jobId} snackbarOn={snackbarOn} user={user} />
        </Box>
      </ThemeProvider>
    );
  }
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" mb={1}>
        <Box className={classes.viewDescriptionSection} display="flex" flexDirection="column">
          <Typography className={classes.title} variant="h6">
            {label}
          </Typography>
          <Typography className={classes.titleDescription} variant="body2">
            {subtext}
          </Typography>
        </Box>
        <FormControlLabel control={<Switch checked={isDetailView} onChange={toggleDetailView} />} />
      </Box>
      {isDetailView && (
        <ResponsiveTable
          customCellComponents={{
            CostDescription: ({ record }) =>
              findItemValue(costCodes, record.costCodeId, 'description')
          }}
          data={detailedReports || []}
          disableFilter
          disablePagination
          isLoading={loading}
          rowMetadata={JobCostingReportTable}
        />
      )}
      {!isDetailView && (
        <Box display="flex" flexDirection="column">
          {detailViewItems.map(item => {
            return (
              <Box display="flex" flex={1} flexDirection="column" pt={4}>
                <Typography className={classes.detailViewTitle} variant="h4">
                  {item.title}
                </Typography>
                <ResponsiveTable
                  customCellComponents={{
                    Total,
                    CostTypeLabel
                  }}
                  data={item.data}
                  disableFilter
                  disablePagination
                  hasSummaryRow
                  isLoading={loading}
                  rowMetadata={item.meta}
                />
              </Box>
            );
          })}
          <Box display="flex" flex={1} flexDirection="column">
            <Box display="flex" justifyContent="flex-end">
              <Box
                className={classes.costSummaryWrapper}
                display="flex"
                flexDirection="column"
                p={1}
                pr={2}
              >
                <Box alignItems="center" display="flex" justifyContent="space-between">
                  <Typography className={classes.costSummaryLabel} variant="body2">
                    Gross Profit
                  </Typography>
                  <Typography variant="h6">{convertToCurrencyString(grossProfit)}</Typography>
                </Box>
                <Box alignItems="center" display="flex" justifyContent="space-between">
                  <Typography className={classes.costSummaryLabel} variant="body2">
                    Gross Margin
                  </Typography>
                  <Typography variant="h6">{grossMargin || 0}%</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Box display="flex">
        <JobCostingLabor jobId={jobId} snackbarOn={snackbarOn} user={user} />
      </Box>
    </Box>
  );
};

export default JobCostingReport;
