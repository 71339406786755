import React from 'react';

import compact from 'lodash/compact';
import orderBy from 'lodash/orderBy';

import { array, arrayOf, bool, shape, string } from 'prop-types';

import TechCard from '@dispatch/components/TechCard';

import { useStyles } from './TechStatuses.styles';

const TechStatuses = ({ form, options }) => {
  const classes = useStyles();
  const { primaryTechId, extraTechs } = form?.values || {};
  const { schedules, techs, techLoading } = options;
  const techIds = compact([primaryTechId, ...(extraTechs || [])]);
  const techStatuses =
    schedules?.reduce(
      (result, schedule) => ({
        ...result,
        [schedule?.employee?.id]: schedule?.status
      }),
      {}
    ) || {};

  const techsMap =
    techs?.reduce(
      (result, tech) => ({
        ...result,
        [tech?.id]: tech
      }),
      {}
    ) || {};

  const techsData = compact(
    techIds.map(techId => {
      const tech = techsMap[techId];
      const status = techStatuses[techId];
      if (!tech) {
        return null;
      }
      return {
        ...tech,
        status
      };
    })
  );

  return orderBy(techsData, 'name').map(tech => (
    <div className={classes.techContainer}>
      <TechCard showStatus tech={tech} techLoading={techLoading} />
    </div>
  ));
};

TechStatuses.propTypes = {
  form: shape({
    values: shape({
      primaryTechId: string,
      extraTechs: arrayOf(string)
    }).isRequired
  }).isRequired,
  options: shape({
    techs: array,
    techsLoading: bool,
    schedules: arrayOf(
      shape({
        employee: shape({
          id: string
        }),
        status: string
      })
    )
  }).isRequired
};

export default TechStatuses;
