import React from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';

const Toolbar = props => {
  const {
    classes,
    name,
    description,
    associatedEntityType,
    updateFormProperties,
    openPreviewFormModal,
    developerVersion
  } = props;
  return (
    <div className={classes.toolbar}>
      <div className={classes.toolbarComponent} style={{ flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <Typography variant="h5">{name}</Typography>
          <Button aria-label="edit" className={classes.itemControlButton}>
            <EditIcon className={classes.itemControlIcon} onClick={updateFormProperties} />
          </Button>
        </div>
        <div style={{ display: 'flex' }}>
          <Typography style={{ paddingRight: 6, borderRight: '1px solid #000' }} variant="body2">
            {associatedEntityType}
          </Typography>
          <Typography style={{ paddingLeft: 6 }} variant="body2">
            {description}
          </Typography>
        </div>
      </div>
      <div className={classes.toolbarComponent} style={{ justifyContent: 'flex-end' }}>
        {developerVersion && (
          <Button
            color="primary"
            style={{ marginRight: 12 }}
            variant="contained"
            onClick={() => openPreviewFormModal('metadata')}
          >
            Metadata
          </Button>
        )}
        <Button color="primary" variant="contained" onClick={() => openPreviewFormModal('form')}>
          Preview
        </Button>
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  associatedEntityType: PropTypes.string,
  developerVersion: PropTypes.bool,
  updateFormProperties: PropTypes.func.isRequired,
  openPreviewFormModal: PropTypes.func.isRequired
};

Toolbar.defaultProps = {
  name: '',
  description: '',
  associatedEntityType: '',
  developerVersion: false
};

export default Toolbar;
