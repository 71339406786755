import React, { useCallback, useEffect, useState } from 'react';

import { Button, Divider, Label } from '@BuildHero/sergeant';
import { Box, Grid } from '@material-ui/core';

import PropTypes from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useCompanyTimezone } from '@pm/components/hooks';

import WrapTable from 'components/WrapTable';
import useEmployees from 'customHooks/useEmployees';

import { useLazyJobDetails } from '../../../../useLazyJobDetails';

import { handleAddCostsToQuote, skipCostsAndAddToQuote } from '../index';

import { Accordion, AccordionDetails, AccordionSummary } from './components/accordions';
import IncurredCostsModal from './components/IncurredCostsModal';
import { LABEL_SCHEMA } from './constants';
import incurredCostFragment from './incurredCostsFragment';
import { getDraggableStyle, useModalStyles, useStyles } from './styles';
import { getPartsColumns, getPartsRows } from './tableConfig';

const AddIncurredCosts = ({ open, handleOnClose, jobData, currentProperty, snackbar }) => {
  const classes = useStyles();
  const modalClasses = useModalStyles();

  const [{ data: companyTimezone }] = useCompanyTimezone();
  const [companyEmployees] = useEmployees();
  const history = useHistory();
  const user = useSelector(state => state.user);
  const { locale } = user ?? { locale: 'en' };

  const [loadingQuoteData, setLoadingQuoteData] = useState(false);
  const [taskGroups, setTaskGroups] = useState([
    { groupNumber: 0, expanded: true, groupName: null, groupDescription: null }
  ]);
  const [currentTaskGroupNumber, setTaskGroupNumber] = useState(0);
  const [partsAndMaterialForm, setPartsAndMaterialForm] = useState(null);
  const [laborForm, setLaborForm] = useState(null);
  const [addQuoteLoading, setAddQuoteLoading] = useState(false);

  const [getJobDetails, { data: incurredCostsData }] = useLazyJobDetails(incurredCostFragment);

  useEffect(() => {
    if (open && jobData?.id && !incurredCostsData?.visits) {
      setLoadingQuoteData(true);
      getJobDetails({ id: jobData?.id, partitionKey: user?.tenantId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (incurredCostsData?.visits) {
      setLoadingQuoteData(false);
    }
  }, [incurredCostsData]);

  const handleDragEnd = useCallback(
    ({ source, destination }) => {
      if (destination && destination.index !== source.index) {
        [taskGroups[source.index], taskGroups[destination.index]] = [
          taskGroups[destination.index],
          taskGroups[source.index]
        ];
        setTaskGroups(taskGroups);
      }
    },
    [taskGroups]
  );

  const onCloseModal = () => {
    if (!addQuoteLoading) {
      setPartsAndMaterialForm(null);
      setLaborForm(null);
      setTaskGroups([{ groupNumber: 0, expanded: true, groupName: null, groupDescription: null }]);
      handleOnClose();
    }
  };

  const onNameChange = ({ taskGroupNumber, currentValue }) => {
    setTaskGroups(
      taskGroups.map(tG => {
        if (tG.groupNumber === taskGroupNumber) {
          return {
            ...tG,
            groupName: currentValue
          };
        }
        return tG;
      })
    );
  };
  const onDescriptionChange = ({ taskGroupNumber, currentValue }) => {
    setTaskGroups(
      taskGroups.map(tG => {
        if (tG.groupNumber === taskGroupNumber) {
          return {
            ...tG,
            groupDescription: currentValue
          };
        }
        return tG;
      })
    );
  };

  const addTask = () => {
    setTaskGroups([
      ...taskGroups,
      {
        groupNumber: currentTaskGroupNumber + 1,
        expanded: true,
        groupName: null,
        groupDescription: null
      }
    ]);
    setTaskGroupNumber(currentTaskGroupNumber + 1);
  };

  const onCollapseTaskGroup = taskGroup => {
    setTaskGroups(
      taskGroups?.map(t => {
        if (t.groupNumber === taskGroup.groupNumber) {
          return {
            ...t,
            expanded: !t.expanded
          };
        }
        return t;
      })
    );
  };

  const onDeleteTaskGroup = taskGroup => {
    setPartsAndMaterialForm(
      partsAndMaterialForm?.map(partsAndMaterial => {
        if (partsAndMaterial.taskIncludedInGroup === taskGroup?.groupNumber) {
          return {
            ...partsAndMaterial,
            taskIncludedInGroup: null
          };
        }
        return partsAndMaterial;
      })
    );
    setTaskGroups(taskGroups?.filter(tG => tG.groupNumber !== taskGroup?.groupNumber));
  };

  return (
    <IncurredCostsModal
      classes={modalClasses}
      handleAddCostsToQuote={() =>
        handleAddCostsToQuote(
          taskGroups,
          partsAndMaterialForm,
          setAddQuoteLoading,
          currentProperty,
          jobData,
          history,
          user,
          companyEmployees,
          snackbar
        )
      }
      handleClose={onCloseModal}
      loading={addQuoteLoading}
      locale={locale}
      open={open}
      skipAddCosts={() =>
        skipCostsAndAddToQuote(
          setAddQuoteLoading,
          currentProperty,
          jobData,
          history,
          user,
          companyEmployees,
          snackbar
        )
      }
      onDragEnd={handleDragEnd}
    >
      <Droppable droppableId="mainIncurredCostDroppable" key="mainIncurredCostDroppable">
        {dropableProvided => (
          <div ref={dropableProvided.innerRef} {...dropableProvided.draggableProps}>
            {taskGroups?.map((taskGroup, taskGroupI) => {
              return (
                <Draggable
                  draggableId={`task-group-${taskGroup?.groupNumber}`}
                  index={taskGroupI}
                  key={`task-group-${taskGroup?.groupNumber}`}
                >
                  {(provided, snapshot) => (
                    <>
                      <div style={{ opacity: snapshot.isDragging ? 0.5 : 1 }}>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getDraggableStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <Box borderRadius={4} boxShadow={2} m={0} p={0}>
                            <Accordion
                              expanded={taskGroup?.expanded}
                              square
                              style={{ userSelect: 'text' }}
                            >
                              <AccordionSummary
                                classes={classes}
                                locale={locale}
                                provided={provided}
                                taskGroup={taskGroup}
                                onCollapseTaskGroup={onCollapseTaskGroup}
                                onDeleteTaskGroup={onDeleteTaskGroup}
                                onNameChange={onNameChange}
                              />
                              <AccordionDetails
                                classes={classes}
                                locale={locale}
                                taskGroup={taskGroup}
                                onDescriptionChange={onDescriptionChange}
                              >
                                <Grid item xs={12}>
                                  <Label label={LABEL_SCHEMA.partsAndMaterials[locale]} />
                                </Grid>
                                <Grid className={classes.partTable} item xs={12}>
                                  <WrapTable
                                    columns={getPartsColumns(
                                      taskGroup.groupNumber,
                                      partsAndMaterialForm,
                                      setPartsAndMaterialForm
                                    )}
                                    hideFooter
                                    loading={loadingQuoteData}
                                    loadingRows={1}
                                    noDataMessage={LABEL_SCHEMA.noPartsAndMaterials[locale]}
                                    rows={getPartsRows(
                                      taskGroup.groupNumber,
                                      incurredCostsData,
                                      partsAndMaterialForm,
                                      setPartsAndMaterialForm,
                                      companyTimezone
                                    )}
                                  />
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          </Box>
                        </div>
                      </div>
                    </>
                  )}
                </Draggable>
              );
            })}
          </div>
        )}
      </Droppable>
      <Button className={classes.addTaskBtn} size="small" type="leading" onClick={addTask}>
        {LABEL_SCHEMA.addTask[locale]}
      </Button>
    </IncurredCostsModal>
  );
};

AddIncurredCosts.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  jobData: PropTypes.object.isRequired,
  currentProperty: PropTypes.object.isRequired,
  snackbar: PropTypes.func.isRequired
};

export default AddIncurredCosts;
