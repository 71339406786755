import React from 'react';

import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import { getContentText } from 'scenes/ProjectManagement/components/utils';
import toFixedNumber from 'utils/toFixedNumber';

import styles from '../style';

import ContractApplicationTable from './ContractApplicationTable';

const ContractApplicationLeftside = ({ tableData, totals, payAppSummary }) => {
  const averageRetainage = toFixedNumber(
    (totals.retainedAmount / totals.totalCompletedAndStoredToDate) * 100
  );

  const retainedWork = (
    (totals.retainedAmount / totals.totalCompletedAndStoredToDate) *
    totals.workCompletedToDate
  ).toFixed(2);

  const retainedMaterials = (
    (totals.retainedAmount / totals.totalCompletedAndStoredToDate) *
    totals.materialsStoredToDate
  ).toFixed(2);

  return (
    <View>
      <Text style={styles.title}>CONTRACTOR'S APPLICATION FOR PAYMENT</Text>
      <Text style={[styles.smallText, styles.marginTop5]}>
        Application is made for payment, as shown below, in connection with the Contract.
      </Text>
      <Text style={styles.smallText}>Continuation Sheet is attached.</Text>
      <Text style={[styles.text, { marginTop: 10, textAlign: 'center' }]}>
        SEE ATTACHED SWORN STATEMENT FROM CONTRACTOR TO OWNER
      </Text>
      <View style={[styles.displayFlexSpaceBetween, styles.marginTop10]}>
        <Text style={styles.text}>1. ORIGINAL CONTRACT SUM</Text>
        <Text style={[styles.underline, styles.textAlignRight]}>
          {getContentText('currency', totals.originalContractValue, 2)}
        </Text>
      </View>
      <View style={[styles.displayFlexSpaceBetween, styles.marginTop5]}>
        <Text style={styles.text}>2. Net change by Change Orders Additions / Deductions</Text>
        <Text style={styles.textAlignRight}>
          {getContentText('currency', totals.coContractValue, 2)}
        </Text>
      </View>
      <View style={[styles.displayFlexSpaceBetween, styles.marginTop5]}>
        <Text style={styles.text}>3. CONTRACT SUM TO DATE (Line 1 ± 2)</Text>
        <Text style={styles.textAlignRight}>
          {getContentText('currency', totals.contractValue, 2)}
        </Text>
      </View>
      <View style={[styles.displayFlexSpaceBetween, styles.marginTop5]}>
        <Text style={styles.text}>4. TOTAL COMPLETED & STORED TO DATE</Text>
        <Text style={styles.textAlignRight}>
          {getContentText('currency', totals.totalCompletedAndStoredToDate, 2)}
        </Text>
      </View>
      <View style={[styles.displayFlexSpaceBetween, { marginTop: 1 }]}>
        <Text style={[styles.text, { paddingLeft: 20 }]}>
          (Column G Original Contract Continuation Sheet Attached)
        </Text>
      </View>
      <View style={[styles.displayFlexSpaceBetween, styles.marginTop5]}>
        <Text style={styles.text}>5. RETAINAGE</Text>
      </View>
      <View style={[styles.displayFlexSpaceBetween, styles.marginTop5]}>
        <View style={[styles.displayFlex, styles.paddingLeft10]}>
          <Text style={styles.text}>a. </Text>
          <Text style={[styles.underline, styles.shortUnderline]}>{averageRetainage || 0}</Text>
          <Text style={styles.text}>% of Completed Work</Text>
        </View>
        <Text style={styles.textAlignRight}>{getContentText('currency', retainedWork, 2)}</Text>
      </View>
      <View style={[styles.displayFlexSpaceBetween, styles.marginTop5]}>
        <View style={[styles.displayFlex, styles.paddingLeft10]}>
          <Text style={styles.text}>b. </Text>
          <Text style={[styles.underline, styles.shortUnderline]}>{averageRetainage || 0}</Text>
          <Text style={styles.text}>% of Materials Stored</Text>
        </View>
        <Text style={styles.textAlignRight}>
          {getContentText('currency', retainedMaterials, 2)}
        </Text>
      </View>
      <View style={[styles.displayFlexSpaceBetween, styles.marginTop5]}>
        <Text style={styles.text}>Total Retainage</Text>
      </View>
      <View style={[styles.displayFlexSpaceBetween, styles.marginTop5]}>
        <Text style={styles.text}>6. TOTAL EARNED LESS RETAINAGE</Text>
        <Text style={styles.textAlignRight}>
          {getContentText('currency', totals.invoiceAmount, 2)}
        </Text>
      </View>
      <View style={[styles.displayFlexSpaceBetween, { marginTop: 10 }]}>
        <Text style={styles.text}>7. LESS PREVIOUS CERTIFICATES FOR</Text>
      </View>
      <View style={[styles.displayFlexSpaceBetween, { marginTop: 2 }]}>
        <Text style={[styles.text, styles.paddingLeft10]}>
          PAYMENT (Line 6 from prior Certificate)
        </Text>
        <Text style={styles.textAlignRight}>
          {getContentText('currency', totals.invoiceAmount - payAppSummary.invoiceAmount, 2)}
        </Text>
      </View>
      <View style={[styles.displayFlexSpaceBetween, styles.marginTop5]}>
        <Text style={styles.text}>8. CURRENT PAYMENT DUE THIS PERIOD</Text>
        <Text style={[styles.underline, styles.textAlignRight]}>
          {getContentText('currency', payAppSummary.invoiceAmount, 2)}
        </Text>
      </View>
      <View style={[styles.displayFlexSpaceBetween, styles.marginTop5]}>
        <Text style={styles.text}>9. BALANCE TO FINISH, INCLUDING RETAINAGE</Text>
        <Text style={[styles.underline, styles.textAlignRight]}>
          {getContentText('currency', totals.contractValue - totals.invoiceAmount, 2)}
        </Text>
      </View>
      <View style={[styles.displayFlexSpaceBetween, { marginTop: 1 }]}>
        <Text style={[styles.text, { paddingLeft: 40 }]}>(Line 3 less Line 6)</Text>
      </View>
      <ContractApplicationTable {...{ tableData, totals, payAppSummary }} />
    </View>
  );
};

ContractApplicationLeftside.propTypes = {
  projectData: PropTypes.object.isRequired,
  paData: PropTypes.object.isRequired,
  sovData: PropTypes.array.isRequired,
  coData: PropTypes.array.isRequired
};

export default ContractApplicationLeftside;
