import React, { useMemo } from 'react';

import moment from 'moment-timezone';

import { secondsToHour } from 'scenes/Payroll/TimeTrackingReport/helpers';
import AppConstants, { TimeCardStatusTypes } from 'utils/AppConstants';
import { EntityType } from 'utils/constants';

import LogRow from './LogRow';

const timesheetStatusMap = {
  PENDING: 'Unsubmitted',
  SUBMITTED: 'Submitted',
  REOPENED: 'Reopened',
  DISPUTED: 'Pending Revision',
  APPROVED: 'Approved'
};

const displayStatus = status => (status ? timesheetStatusMap[status] : 'Empty');

const TimesheetEntryAuditMessages = {
  Edited: ({ relevantChangeLog, timesheetEntry }) => {
    return `
      Changed the value for hour type "${timesheetEntry?.hourType?.hourType}"
      ${
        typeof relevantChangeLog?.old !== 'undefined'
          ? `
          from
          ${secondsToHour(
            Number.isInteger(relevantChangeLog?.old)
              ? relevantChangeLog?.old
              : timesheetEntry?.actualTotalDuration
          )}
      `
          : ''
      }
      to ${secondsToHour(relevantChangeLog?.new)}`;
  },
  Added: ({ timesheetEntry }) => {
    return `
      Logged hour type "${timesheetEntry?.hourType?.hourType}" with value ${secondsToHour(
      timesheetEntry?.actualTotalDuration
    )}
    `;
  }
};

const TimesheetEntryBinderAuditMessages = {
  manualStatus: ({ relevantChangeLog }) => {
    const { old: oldStatus, new: newStatus } = relevantChangeLog;
    if (oldStatus === TimeCardStatusTypes.PENDING && newStatus === TimeCardStatusTypes.APPROVED) {
      return 'Changed status - Submitted and Approved time';
    }
    return `
        Changed status from ${displayStatus(relevantChangeLog?.old)}
        to ${displayStatus(relevantChangeLog?.new)}`;
  },
  isDismissed: ({ relevantChangeLog }) => {
    return relevantChangeLog?.new ? 'Timesheet dismissed' : 'Timesheet undismissed';
  },
  dismissedReason: ({ relevantChangeLog }) => {
    // we default to showing a dismissed reason of `dismissed by $USER`, don't need to show those
    if (relevantChangeLog?.new && !relevantChangeLog.new.startsWith('dismissed by')) {
      return `Dismissed reason: ${relevantChangeLog.new}`;
    }
  },
  manualDisputedReason: ({ relevantChangeLog }) => {
    return relevantChangeLog?.new && `Pending Revision reason: ${relevantChangeLog.new}`;
  },
  manualReopenReason: ({ relevantChangeLog }) => {
    return relevantChangeLog?.new && `Reopen reason: ${relevantChangeLog.new}`;
  }
};

const AuditLogEntry = ({ workEvent, auditLog, payrollSetting }) => {
  const { timesheetHours } = workEvent;
  const {
    auditedEntityId,
    auditedEntityType,
    executedBy,
    changeLog = '[]',
    executedDateTime,
    executionType,
    id
  } = auditLog;

  const parsedChangeLog = JSON.parse(changeLog);

  const date = moment
    .tz(
      moment.unix(parseInt(parseInt(executedDateTime, 10) / 1000, 10)).format(),
      payrollSetting.timeZone
    )
    .format(AppConstants.DATETIME_FORMAT);

  const auditLogMessage = useMemo(() => {
    switch (auditedEntityType) {
      case EntityType.TIMESHEET_ENTRY: {
        const timesheetEntry = Object.values(timesheetHours).find(h => h.id === auditedEntityId);
        if (executionType === 'Added' && timesheetEntry) {
          return TimesheetEntryAuditMessages.Added({ timesheetEntry });
        }
        if (executionType === 'Edited' && parsedChangeLog && timesheetEntry) {
          const relevantChangeLog = parsedChangeLog.find(
            l => l.field === 'actualTotalDurationOverride'
          );
          if (relevantChangeLog) {
            return TimesheetEntryAuditMessages.Edited({
              timesheetEntry,
              relevantChangeLog
            });
          }
        }
        break;
      }
      case EntityType.TIMESHEET_ENTRY_BINDER: {
        if (executionType === 'Edited' && parsedChangeLog) {
          const changeLogFields = [
            'manualStatus',
            'manualDisputedReason',
            'manualReopenReason',
            'isDismissed',
            'dismissedReason'
          ];
          const auditLogMessages = [];
          changeLogFields.forEach(field => {
            const relevantChangeLog = parsedChangeLog.find(l => l.field === field);
            if (relevantChangeLog && TimesheetEntryBinderAuditMessages[field]) {
              const newMessage = TimesheetEntryBinderAuditMessages[field]({ relevantChangeLog });
              if (newMessage) auditLogMessages.push(newMessage);
            }
          });
          return auditLogMessages.join('. ');
        }
        break;
      }
      default:
        return null;
    }
  });

  if (!auditLogMessage) return null;

  return (
    <LogRow
      author={executedBy}
      date={date}
      id={id}
      key={id}
      message={auditLogMessage}
      subject={executionType}
    />
  );
};

export default AuditLogEntry;
