import { gql } from '@apollo/client';

import moment from 'moment';

import { DEFAULT_EVENT_DURATION_IN_MIN } from '@dispatch/Dispatch.constants';
import { manDayFragment, nonVisitDetailsFragment, visitDetailsFragment } from '@dispatch/fragments';
import { transformVisitDetails } from '@dispatch/queries';
import { getHttpClient } from 'client';

import useExtendedLazyQuery from 'customHooks/useExtendedLazyQuery';

import {
  selectCardWidth,
  selectCardXPosition,
  selectTechEvents,
  selectVisitWidth,
  selectVisitXPosition
} from '../DispatchBoard.selectors';

const EMPTY_ARRAY = [];

export const GET_DISPATCH_EVENTS_BY_TECH_IDS = gql`
  query getDispatchEventsByTechIds($data: GetDispatchEventsByTechIdsInput) {
    getDispatchEventsByTechIds(data: $data) {
      visits {
        ${visitDetailsFragment}
      }
      nonVisitEvents {
        ${nonVisitDetailsFragment}
      }
      manDays {
        ${manDayFragment}
      }
      techIds
    }
  }
`;

const transformBoardVisits = data => {
  const visits = data?.getDispatchEventsByTechIds?.visits || EMPTY_ARRAY;

  const techVisits = {};

  visits.forEach(baseVisit => {
    const transformedVisit = transformVisitDetails({ visit: baseVisit });

    const visit = {
      ...transformedVisit,
      left: selectVisitXPosition(transformedVisit),
      width: selectVisitWidth(transformedVisit)
    };

    const { primaryTechId } = visit;

    if (primaryTechId) {
      techVisits[primaryTechId] = [...(techVisits[primaryTechId] || EMPTY_ARRAY), visit];
    }

    visit.extraTechs?.forEach(techId => {
      techVisits[techId] = [...(techVisits[techId] || EMPTY_ARRAY), visit];
    });
  });

  return techVisits;
};

const transformNonVisitEvents = data => {
  const events = data?.getDispatchEventsByTechIds?.nonVisitEvents;
  if (!events) return {};

  return events.reduce((result, event) => {
    if (!event.isActive) return result;

    const { employeeId } = event;
    const employeeEvents = result[employeeId] || EMPTY_ARRAY;
    const startTime = event.plannedStartTimeUTC;

    const durationInMinutes =
      event.plannedStartTimeUTC && event.plannedEndTimeUTC
        ? (event.plannedEndTimeUTC - event.plannedStartTimeUTC) / 60
        : DEFAULT_EVENT_DURATION_IN_MIN;

    return {
      ...result,
      [employeeId]: [
        ...employeeEvents,
        {
          ...event,
          left: selectCardXPosition(startTime),
          width: selectCardWidth(durationInMinutes),
          range: moment.range(
            moment.unix(event.plannedStartTimeUTC),
            moment.unix(event.plannedEndTimeUTC)
          )
        }
      ]
    };
  }, {});
};

const transformManDays = data => {
  return (
    data?.getDispatchEventsByTechIds?.manDays?.reduce((result, item) => {
      const techsItems =
        item.technicianIds?.reduce((techItems, technicianId) => {
          if (!technicianId) {
            return techItems;
          }
          const startPosition = selectCardXPosition(item.startDateTime);
          const endPosition = selectCardXPosition(item.endDateTime);
          const project = item.project || {};
          return {
            ...techItems,
            [technicianId]: [
              ...(result[technicianId] || EMPTY_ARRAY),
              {
                ...item,
                name:
                  project?.number && project?.name
                    ? `${project.name} – #${project.number}`
                    : project?.name || project?.number,
                left: startPosition,
                width: endPosition - startPosition,
                range: moment.range(moment.unix(item.startDateTime), moment.unix(item.endDateTime))
              }
            ]
          };
        }, {}) || {};
      return {
        ...result,
        ...techsItems
      };
    }, {}) || {}
  );
};

const transformDispatchEvents = data => {
  const visitsMap = transformBoardVisits(data);
  const nonVisitEventsMap = transformNonVisitEvents(data);
  const manDaysMap = transformManDays(data);
  const techIds = data?.getDispatchEventsByTechIds?.techIds || [];

  const techEventsMap = techIds.reduce(
    (acc, techId) => ({
      ...acc,
      [techId]: selectTechEvents({
        techVisits: visitsMap?.[techId] || [],
        techNonVisitEvents: nonVisitEventsMap?.[techId] || [],
        techManDayItems: manDaysMap?.[techId] || []
      })
    }),
    {}
  );

  return techEventsMap;
};

const DEFAULT_DATA = {};

export const useDispatchEventsByTechIds = (
  { techIds, startDateTime, endDateTime },
  options = {}
) => {
  return useExtendedLazyQuery(GET_DISPATCH_EVENTS_BY_TECH_IDS, {
    variables: { data: { techIds, startDateTime, endDateTime } },
    transform: transformDispatchEvents,
    defaultData: DEFAULT_DATA,
    ...options,
    skip: !(techIds?.length && startDateTime && endDateTime) || options.skip
  });
};

export const resetDispatchEvents = () => {
  const client = getHttpClient();

  return client.writeQuery({
    query: GET_DISPATCH_EVENTS_BY_TECH_IDS,
    data: {
      getDispatchEventsByTechIds: {
        visits: [],
        nonVisitEvents: [],
        manDays: [],
        techIds: []
      }
    },
    variables: { replace: true }
  });
};
