import React from 'react';

import { PDFComponents, TV, TW } from '@BuildHero/sergeant';
import { Image, StyleSheet, View } from '@react-pdf/renderer';

import Moment from 'moment';

import AmplifyService from 'services/AmplifyService';
import {
  getFileExtension,
  getFileNameForPdf,
  getGuardedFileName,
  isCloudinaryImageType
} from 'utils';
import AppConstants from 'utils/AppConstants';

const attachmentStyles = {
  visitView: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  view: {
    width: '50%',
    marginTop: 8,
    paddingBottom: 10,
    paddingRight: 16,
    alignContent: 'flex-start',
    alignItems: 'flex-start'
  },
  image: {
    objectFit: 'contain',
    width: '100%',
    height: 'auto',
    maxHeight: 240,
    margin: 1,
    alignSelf: 'center',
    marginTop: 8
  }
};

const Attachments = ({ field }) => {
  const visits = field?.value || [];
  if (visits.length === 0 || !Array.isArray(visits)) return null;
  const styles = StyleSheet.create(attachmentStyles);

  return (
    <View style={styles.visitView}>
      {visits.map(visit => {
        const { attachments } = visit;
        if (attachments.length === 0) return null;
        return (
          <View key={`view_${visit.header}`} style={styles.visitView}>
            <View style={{ width: '100%', marginTop: 8 }}>
              <PDFComponents.Typography
                props={{
                  variant: TV.S1,
                  weight: TW.BOLD,
                  value: `${visit.type} ${visit.header}`
                }}
              />
            </View>
            <Photos attachments={attachments} type="Before" />
            <Photos attachments={attachments} type="After" />
          </View>
        );
      })}
    </View>
  );
};

const Photos = ({ attachments = [], type = 'Before' }) => {
  const styles = StyleSheet.create(attachmentStyles);
  const { storageService } = AmplifyService;

  return attachments
    .filter(a => a.type === type)
    .map((attachment, i) => {
      const ext = getFileExtension(attachment.fileUrl);
      if (!isCloudinaryImageType(ext)) return null;
      return (
        <>
          {i === 0 ? (
            <View style={{ width: '100%', marginTop: 8 }}>
              <PDFComponents.Typography
                props={{
                  variant: TV.S2,
                  weight: TW.BOLD,
                  value: `${type} Photos`
                }}
              />
            </View>
          ) : null}
          <View key={`view_${attachment.id}`} style={styles.view} wrap={false}>
            <View>
              <PDFComponents.Typography props={{ value: attachment.customFileName }} />
            </View>
            <Image
              key={attachment.id}
              src={storageService.getFile(
                getFileNameForPdf(getGuardedFileName(attachment.fileUrl))
              )}
              style={styles.image}
            />
            {attachment.comment ? (
              <View style={{ marginTop: 8 }}>
                <PDFComponents.Typography props={{ value: attachment.comment }} />
              </View>
            ) : null}
            <View
              style={{
                justifyContent: 'flex-end',
                alignSelf: 'flex-end',
                marginTop: 8,
                color: '#808080'
              }}
            >
              <PDFComponents.Typography
                props={{
                  value: `Added by: ${attachment.addedBy || attachment.createdBy}`
                }}
              />
            </View>
            {attachment.createdDate ? (
              <View
                style={{
                  justifyContent: 'flex-end',
                  alignSelf: 'flex-end',
                  color: '#808080'
                }}
              >
                <PDFComponents.Typography
                  props={{
                    value:
                      attachment.createdDate &&
                      Moment.unix(attachment.createdDate).format(AppConstants.DATETIME_FORMAT)
                  }}
                />
              </View>
            ) : null}
          </View>
        </>
      );
    });
};

export default Attachments;
