import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { useSnackbar } from 'customHooks/useSnackbar';
import { sentryMessage } from 'services/Logger';
import { logErrorWithCallback } from 'utils';

export const getTimesheetPeriodSelectOptionsQuery = gql`
  query getTimesheetPeriods($partitionKey: String!, $sortKey: String!) {
    getEmployee(partitionKey: $partitionKey, sortKey: $sortKey) {
      timesheetPeriods(entityConnection: "TimesheetPeriod") {
        items {
          id
          dateStartUTC
          dateEndUTC
        }
      }
    }
  }
`;

const useTimesheetPeriodSelectOptions = ({ employee }, options = {}) => {
  const snackbar = useSnackbar();

  const { data, loading, error, refetch } = useExtendedQuery(getTimesheetPeriodSelectOptionsQuery, {
    variables: { partitionKey: employee?.tenantId, sortKey: employee?.sortKey },
    transform: result => result?.getEmployee?.timesheetPeriods?.items || [],
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !employee,
    ...options
  });

  if (error) {
    logErrorWithCallback(error, snackbar, `Unable to fetch timesheet periods`);
  }

  if (data?.filter((p, i, ps) => i !== 0 && p.dateStartUTC === ps[i - 1].dateStartUTC).length) {
    sentryMessage('Duplicate timesheet periods exist', { periods: data });
  }

  return [data || [], loading, error, refetch];
};

export default useTimesheetPeriodSelectOptions;
