import { useMemo } from 'react';

import { round } from 'lodash';

import { BoldCell, tableCurrencyFormatter, tableDateTimeFormatter } from 'components/WrapTable';
import { checkPermission } from 'utils';
import { PermissionConstants, TimeCardStatusTypes } from 'utils/AppConstants';

import { selectTimesheetEntries } from '../../selectors';

const statusMappings = {
  PENDING: 'Not Submitted',
  SUBMITTED: 'Submitted for Review',
  DISPUTED: 'Pending Revision',
  APPROVED: 'Approved'
};

const createTimeEnteredByTechRows = (timesheetEntries = [], nonVisitEventName = null) =>
  timesheetEntries
    .filter(entry => !entry.timesheetEntryBinder?.isDismissed)
    .reduce((rows, entry) => {
      const employeeId = entry.timesheetPeriod.parentId;

      const existingRowIndex = rows.findIndex(r => r.employeeId === employeeId);

      const duration = Number.isInteger(entry.actualTotalDurationOverride)
        ? entry.actualTotalDurationOverride
        : entry.actualTotalDuration;

      const durationHours = duration / 3600;

      if (existingRowIndex !== -1) {
        const newRows = [...rows];
        newRows[existingRowIndex] = {
          ...rows[existingRowIndex],
          [entry.hourType.hourType]: durationHours,
          [`${entry.hourType.id}_cost`]: entry.cost * durationHours,
          totalTime: newRows[existingRowIndex].totalTime + durationHours,
          totalCost: newRows[existingRowIndex].totalCost + entry.cost * durationHours
        };

        return newRows;
      }

      return [
        ...rows,
        {
          id: entry.id,
          employeeId,
          employeeName: entry.timesheetEntryBinder?.employee?.name || entry.createdBy,
          [entry.hourType.hourType]: durationHours,
          [`${entry.hourType.id}_cost`]: entry.cost * durationHours,
          totalTime: durationHours,
          totalCost: entry.cost * durationHours,
          status: statusMappings[entry.manualStatus || TimeCardStatusTypes.PENDING],
          approvedBy: entry.manualApprovedBy,
          approvedDateTime: entry.manualApprovedDate * 1000,
          lastUpdatedBy: entry.lastUpdatedBy,
          lastUpdatedDateTime: entry.lastUpdatedDateTime,
          nonVisitEventName
        }
      ];
    }, []);

export const useGetManualTimeRows = ({ visit, isAutomatedTimeTracking }) =>
  useMemo(() => {
    const timesheetEntries = selectTimesheetEntries(visit);
    const visitManualTimeRows = createTimeEnteredByTechRows(timesheetEntries);

    const billableNonVisitEvents =
      visit?.nonVisitEvents?.items.filter(item => item.eventType.isBillable) || [];

    const billableNonVisitManualTimeRows = !isAutomatedTimeTracking
      ? billableNonVisitEvents.reduce((acc, nve) => {
          const rowsFromNVE =
            createTimeEnteredByTechRows(nve?.timesheetEntries?.items, nve?.name) || [];
          return [...acc, ...rowsFromNVE];
        }, [])
      : [];

    return [...visitManualTimeRows, ...billableNonVisitManualTimeRows];
  }, [visit, isAutomatedTimeTracking]);

const createTimeEnteredByTechCols = (payrollHourTypes, styles, user) => {
  if (!payrollHourTypes?.length) {
    return [];
  }

  let timeCardColumns = [
    {
      field: 'employeeName',
      flex: 2,
      headerName: 'Name',
      minWidth: styles.minWidth
    }
  ];

  timeCardColumns = payrollHourTypes
    .sort((a, b) => {
      const aSort = Number.isInteger(a.sortOrder) ? a.sortOrder : 99;
      const bSort = Number.isInteger(b.sortOrder) ? b.sortOrder : 99;

      return aSort - bSort;
    })
    .reduce((rows, type) => {
      return [
        ...rows,
        {
          field: type.hourType,
          flex: 2,
          headerName: type.hourTypeAbbreviation,
          valueFormatter: ({ value }) => round(value, 2)
        },
        {
          field: `${type.id}_cost`,
          flex: 2,
          headerName: 'Cost',
          hide:
            !user ||
            !checkPermission('allow', PermissionConstants.DATA_PAYROLL_RATES_ON_REPORTS, user),
          valueFormatter: tableCurrencyFormatter,
          renderCell: BoldCell
        }
      ];
    }, timeCardColumns);

  timeCardColumns.push.apply(timeCardColumns, [
    {
      field: 'totalTime',
      flex: 2,
      headerName: 'Total',
      valueFormatter: ({ value }) => round(value, 2)
    },
    {
      field: 'totalCost',
      flex: 2,
      headerName: 'Cost',
      hide:
        !user || !checkPermission('allow', PermissionConstants.DATA_PAYROLL_RATES_ON_REPORTS, user),
      valueFormatter: tableCurrencyFormatter,
      renderCell: BoldCell
    },
    {
      field: 'status',
      flex: 2,
      headerName: 'Status',
      minWidth: styles.minWidth
    },
    {
      field: 'approvedBy',
      flex: 2,
      headerName: 'Approved by',
      minWidth: styles.minWidth,
      valueFormatter: ({ value }) => value ?? '-'
    },
    {
      field: 'approvedDateTime',
      flex: 2,
      headerName: 'Date Approved',
      minWidth: styles.minWidth,
      valueFormatter: ({ value }) => (value ? tableDateTimeFormatter({ value }) : '-')
    },
    {
      field: 'lastUpdatedBy',
      flex: 2,
      headerName: 'Last updated by',
      minWidth: styles.minWidth
    },
    {
      field: 'lastUpdatedDateTime',
      flex: 2,
      headerName: 'Date last updated',
      minWidth: styles.minWidth,
      valueFormatter: ({ value }) => (value ? tableDateTimeFormatter({ value }) : '')
    },
    {
      field: 'nonVisitEventName',
      flex: 2,
      headerName: 'Billable Non-Visit',
      minWidth: styles.minWidth,
      valueFormatter: ({ value }) => value ?? '-'
    }
  ]);

  return timeCardColumns;
};

export const useGetManualTimeCols = ({ payrollHourTypes, styles, user }) =>
  useMemo(() => createTimeEnteredByTechCols(payrollHourTypes, styles.technicianTimeTable, user), [
    payrollHourTypes,
    styles,
    user
  ]);
