const ungroupedTaskData = ({
  HTML,
  tasksWithNoGroup,
  settingsJSON,
  smartFieldInfo,
  styles,
  fontFamilyStyle,
  tasksWithGroups
}) => {
  return tasksWithNoGroup.length > 0
    ? tasksWithNoGroup
        .map(
          (task, idx) => `<figure data-smartfield=${
            tasksWithGroups.length === 0 && idx === 0 ? 'TaskGroups' : 'RemoveItem'
          }>
      <table style="border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);width:100%;">
    ${HTML.tasks({
      group: { tasks: [task] },
      smartFieldInfo,
      settingsJSON,
      removeTaskSmartfield: true,
      HTML,
      styles,
      fontFamilyStyle,
      taskGroupIdx: idx + 1
    })}</table></figure>`
        )
        .join('')
    : '';
};

export default ungroupedTaskData;
