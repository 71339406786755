import React, { Component } from 'react';

import { Button, Input } from '@BuildHero/sergeant';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { snackbarOff, snackbarOn } from 'redux/actions/globalActions';

import styles from '../../styles';

export class ForgotPasswordUsername extends Component {
  constructor(props) {
    super(props);
    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.state = {
      spinner: false
    };
  }

  componentWillUnmount = () => {
    this.setState({ spinner: false });
  };

  saveAndContinue = async e => {
    e.preventDefault();
    this.setState({ spinner: true });
    const { username } = this.props.values;
    this.props.validateValue('username', username);
    let errorMessage = '';
    if (username.trim() === '') {
      errorMessage += 'Username';
    }

    if (errorMessage !== '') {
      errorMessage += ' must be filled in.';
      this.setState({ spinner: false });
      this.props.displayError(errorMessage);
      return;
    }

    const { usernameValid } = this.props.validValues;

    if (usernameValid) {
      await this.props.nextStep();
      this.setState({ spinner: false });
    } else {
      errorMessage = 'Please check that you filled in correctly.';
      this.setState({ spinner: false });
      this.props.displayError(errorMessage);
    }
  };

  render() {
    const { errorMessages, classes, values, i18nMessages, locale } = this.props;

    return (
      <>
        <Grid className={classes.authSection} item xs={12}>
          <Input
            error={errorMessages.username?.length > 0}
            fullWidth
            key="username"
            label={i18nMessages.enterYourUsername[locale]}
            name="username"
            subtext={errorMessages.username}
            value={values.username}
            onChange={this.props.handleChange('username')}
          />
        </Grid>

        <Grid className={classes.authSection} item xs={12}>
          <Button
            fullWidth
            loading={this.state.spinner}
            type="primary"
            onClick={event => this.saveAndContinue(event)}
          >
            {i18nMessages.getVerificationCodeButtonText[locale]}
          </Button>
        </Grid>
        <Grid className={classes.authSection} item xs={12}>
          <Button
            fullWidth
            startIcon={<ArrowBackIcon />}
            type="secondary"
            onClick={() => this.props.history.push('/signin')}
          >
            {i18nMessages.signInInsteadButtonText[locale]}
          </Button>
        </Grid>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message)),
  snackbarOff: () => dispatch(snackbarOff())
});

const reduxConnectedForgotPasswordUsername = connect(
  null,
  mapDispatchToProps
)(ForgotPasswordUsername);

export const ForgotPasswordUsernameWithStyle = withStyles(styles)(ForgotPasswordUsername);

export default compose(withRouter, withStyles(styles))(reduxConnectedForgotPasswordUsername);
