import React from 'react';

import { Button, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';
import { Box, Grid, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import uuid from 'uuid';

import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import { reorder } from 'utils';

import { getArchiveConfirmContent } from '../../../utils';

import LaborTypeCard from './LaborTypeCard';

const LaborTypesTable = ({ laborTypes, setLaborTypes, loading }) => {
  const theme = useTheme();
  const confirmContext = useConfirmModal();

  const creatingNewLaborType = laborTypes[laborTypes.length - 1]?.name === '';

  const resetSortOrder = unorderedLaborTypes =>
    unorderedLaborTypes.map((g, i) => ({ ...g, sortOrder: i }));

  const onModifyLaborType = async ({ field, value, index }) => {
    if (
      field !== 'isArchived' ||
      (await confirmContext.confirm(getArchiveConfirmContent(value, laborTypes[index]?.name)))
    ) {
      setLaborTypes(existingLaborTypes => {
        return existingLaborTypes.map((g, i) => {
          if (i === index) return { ...g, [field]: value };
          return g;
        });
      });
    }
  };

  const onRemoveLaborType = ({ index }) => {
    setLaborTypes(existingLaborTypes =>
      resetSortOrder(existingLaborTypes.filter((t, i) => i !== index))
    );
  };

  const moveLaborType = ({ source, destination }) => {
    if (!Number.isInteger(source?.index) || !Number.isInteger(destination?.index)) return; // invalid drag
    setLaborTypes(existingLaborTypes =>
      resetSortOrder(
        reorder({
          arr: existingLaborTypes,
          source: source.index,
          destination: destination.index
        })
      )
    );
  };

  const onAddLaborType = () => {
    setLaborTypes(existingLaborTypes => [
      ...existingLaborTypes,
      {
        id: uuid.v4(),
        name: '',
        sortOrder: existingLaborTypes.length,
        isArchived: false,
        unsaved: true
      }
    ]);
  };

  return (
    <ThemeProvider>
      <Grid item style={{ padding: 0, width: '100%' }}>
        <Box component="div">
          <DragDropContext isDropDisabled={loading} onDragEnd={moveLaborType}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {laborTypes.map((item, index) => (
                    <LaborTypeCard
                      index={index}
                      isDragDisabled={loading}
                      item={item}
                      laborTypes={laborTypes}
                      onAddLaborType={onAddLaborType}
                      onModifyLaborType={onModifyLaborType}
                      onRemoveLaborType={onRemoveLaborType}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {!creatingNewLaborType && (
              <Button type="leading" onClick={onAddLaborType}>
                <AddIcon css={{ height: theme.spacing(3) }} />
                <Typography variant={TV.BASE} weight={TW.MEDIUM}>
                  Add Labor Type
                </Typography>
              </Button>
            )}
          </DragDropContext>
        </Box>
      </Grid>
    </ThemeProvider>
  );
};

LaborTypesTable.propTypes = {
  laborTypes: PropTypes.array,
  setLaborTypes: PropTypes.func,
  loading: PropTypes.bool
};

LaborTypesTable.defaultProps = {
  laborTypes: [],
  setLaborTypes: () => {},
  loading: false
};

export default LaborTypesTable;
