import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';

const GET_TASK_BY_ID = gql`
  query getTaskById($id: String!) {
    data: getTaskById(id: $id) {
      id
      assetId
      taskEntries {
        items {
          description
          id
          markupType
          markupValue
          name
          productId
          quantity
          sortOrder
          taxable
          unitCost
          unitPrice
          version
        }
      }
    }
  }
`;

const transform = response => {
  return response?.data || {};
};

export const useTaskById = id => {
  return useExtendedQuery(GET_TASK_BY_ID, {
    transform,
    variables: {
      id
    }
  });
};
