import { ButtonType } from '@BuildHero/sergeant';
import moment from 'moment';

import { ScheduleInterval } from './constants';

import { PROPERTY_CHECKLISTS } from './index';

export const updateDefaultCheckListConfirmMessage = {
  body:
    'Another checklist is already set as default. Would you like to update this checklist to be the default instead?',
  title: 'Update Default Checklist',
  buttonLabel: 'Update Default',
  cancelButtonLabel: 'Cancel',
  cancelButtonType: ButtonType.TERTIARY
};

export const getCheckListPayload = values => ({
  id: values.id,
  name: values.checklistName,
  assetTypeId: values.assetTypeId,
  isDefault: values.isDefaultChecklist
});

export const getCheckListFormValues = cList => ({
  checklistName: cList.name,
  assetTypeId: cList.assetTypeId || PROPERTY_CHECKLISTS.id,
  isDefaultChecklist: cList.isDefault,
  id: cList.id
});

export const defaultCreateTemplatePayload = {
  startDateTime: moment().unix(),
  sortOrder: 0,
  interval: ScheduleInterval.QUARTERLY,
  numberOfOccurrences: 1,
  isSingleJob: false
};

export const mutationOperations = {
  ADD: 'add',
  UPDATE: 'update',
  DELETE: 'delete'
};

export const updateTaskTemplateCacheForTaskTemplateChecklistQuery = (
  cachedData,
  checklistId,
  newData,
  operation
) => {
  if (!checklistId || !cachedData || !newData) return;
  const updatedTaskTemplateChecklists = cachedData.taskTemplateChecklists.map(cList => {
    if (cList.id === checklistId) {
      const localCList = { ...cList };
      let updatedTaskTemplates;
      if (operation === mutationOperations.ADD)
        updatedTaskTemplates = [...cList.taskTemplates, newData];
      if (operation === mutationOperations.UPDATE) {
        updatedTaskTemplates = cList.taskTemplates.map(task =>
          task.id === newData.id ? newData : task
        );
      }
      if (operation === mutationOperations.DELETE) {
        updatedTaskTemplates = cList.taskTemplates.filter(task => !newData.includes(task.id));
      }
      localCList.taskTemplates = updatedTaskTemplates;
      return localCList;
    }
    return cList;
  });

  return updatedTaskTemplateChecklists;
};
