import React, { useEffect, useState } from 'react';

import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';

import { arrayOf, bool, func, object } from 'prop-types';
import { useSelector } from 'react-redux';

import { Modal, ResponsiveTable, SectionHeader } from 'components';

import Labels from 'meta/labels';
import { visitListMeta } from 'scenes/Jobs/JobDetail/ReviewReport/VisitsMeta';

const MultipleVisitsModal = ({ open, handleClose, createInvoice, job, visit, multipleVisits }) => {
  const user = useSelector(state => state.user);
  const [visitsToInvoice, setVisitsToInvoice] = useState();
  const [finalInvoiceFlag, setFinalInvoiceFlag] = useState(false);

  useEffect(() => {
    if (open) {
      setVisitsToInvoice([visit.id]);
    }
  }, [open, visit]);

  const toggleInvoiceFlag = () => {
    setFinalInvoiceFlag(!finalInvoiceFlag);
  };

  const handleRowActions = (mode, record) => {
    const visitIds = record.map(item => item.id);
    setVisitsToInvoice(visitIds);
  };
  return (
    <Modal handleClose={handleClose} open={open} width="1056">
      <div>
        <SectionHeader
          enablePadding
          style={{ fontSize: 18, paddingTop: 18 }}
          title={`${Labels.createInvoiceFromJobs[user.locale]}`}
        />
        <Typography style={{ fontSize: 16, paddingBottom: 14 }}>Visit(s) :</Typography>
        <ResponsiveTable
          data={multipleVisits}
          disableFilter
          noDataMsg="No tasks added to property"
          rowActionButtons={{
            select: {
              referenceKey: 'id',
              defaultSelectedRow: visit
            }
          }}
          rowActions={handleRowActions}
          rowMetadata={visitListMeta}
        />
        <div style={{ paddingTop: 18, paddingBottom: 0 }}>
          <FormControlLabel
            control={<Checkbox onChange={toggleInvoiceFlag} />}
            label={`${Labels.finalInvoice[user.locale]}`}
            labelPlacement="start"
          />
        </div>
        <Grid container direction="row" justify="flex-end" style={{ paddingTop: 10 }}>
          <Grid item style={{ paddingRight: 16 }}>
            <Button color="secondary" variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item style={{ paddingLeft: 16 }}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                handleClose();
                createInvoice({ visitIds: visitsToInvoice, job, finalInvoiceFlag });
              }}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

MultipleVisitsModal.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  createInvoice: func.isRequired,
  job: object.isRequired,
  visit: object.isRequired,
  multipleVisits: arrayOf(object)
};

MultipleVisitsModal.defaultProps = {
  multipleVisits: []
};

export default MultipleVisitsModal;
