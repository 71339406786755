import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { SergeantModal } from 'components';
import AlgoliaSearchWrapper from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import NewAgreementLayout from 'meta/ServiceAgreements/NewAgreement';
import { snackbarOn } from 'redux/actions/globalActions';
import AmplifyService from 'services/AmplifyService';
import { Logger } from 'services/Logger';
import { logErrorWithCallback } from 'utils';
import { ServiceAgreementStatus } from 'utils/AppConstants';
import { Mode } from 'utils/constants';

import { GetServiceAgreementNextCounterValue } from '../queries';

import { AddServiceAgreementToCompany } from './graphql';

const CreateServiceAgreement = ({ showModal, onModalClose, user, snackBar, customer }) => {
  const { client } = AmplifyService.appSyncClient();
  const [disablePrimaryButton, setDisablePrimaryButton] = useState(true);
  const [formData, setFormData] = useState({
    customerId: customer?.id,
    customerName: customer?.customerName
  });

  const handlePrimaryAction = async (
    { agreementName, agreementNumber, customerId },
    modalCallback
  ) => {
    try {
      // when the autogenerated number is chosen, value should not be sent to enable backend to generate it
      // per design, if no value sent for agreement number, value will be autogenerated,
      // when value is present its used as custom agreement number
      const localAgreementNumber =
        agreementNumber === formData?.agreementNumber ? undefined : agreementNumber;
      const { data } = await client.mutate({
        mutation: AddServiceAgreementToCompany,
        variables: {
          data: {
            agreementName,
            agreementNumber: localAgreementNumber,
            customerId,
            companyId: user.tenantCompanyId,
            status: ServiceAgreementStatus.DRAFT
          }
        }
      });

      snackBar('success', 'Service agreement created successfully!');
      onModalClose(false, data.addServiceAgreementToCompany);
    } catch (e) {
      logErrorWithCallback(e, snackBar);
    } finally {
      modalCallback();
    }
  };

  const handleFormChange = data => {
    setDisablePrimaryButton(!data.agreementName || !data.customerId);
  };

  useEffect(() => {
    const getLatestSANumber = async () => {
      const { data } = await client.query({
        query: GetServiceAgreementNextCounterValue
      });
      try {
        const { serviceAgreement } = JSON.parse(data.getServiceAgreementNextCounterValue);
        setFormData({ ...formData, agreementNumber: `SA${serviceAgreement}` });
      } catch (error) {
        Logger.error(error);
      }
    };

    getLatestSANumber();
  }, [client]);

  return (
    <SergeantModal
      customComponents={{
        AlgoliaSearchWrapper
      }}
      customPrimaryButtonLabel="Create New Service Agreement"
      data={formData}
      dataType="New Service Agreement"
      disablePrimaryButton={disablePrimaryButton}
      handleClose={() => onModalClose(false)}
      handlePrimaryAction={handlePrimaryAction}
      layout={NewAgreementLayout(formData)}
      maxWidth="440"
      mode={Mode.ADD}
      open={showModal}
      onFormChange={handleFormChange}
    />
  );
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  snackBar: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

CreateServiceAgreement.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    tenantCompanyId: PropTypes.string
  }).isRequired,
  snackBar: PropTypes.func.isRequired,
  // to prepopulate the customer when creating SA from customer details page
  customer: PropTypes.shape({
    customerName: PropTypes.string,
    customerId: PropTypes.string
  })
};

CreateServiceAgreement.defaultProps = {
  customer: { customerName: '', customerId: '' }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateServiceAgreement);
