import React from 'react';

import { MUIFormComponents } from '@BuildHero/sergeant';
import { Box, Checkbox, Divider, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const SuggestedParts = () => <Typography variant="h6">Suggested Parts</Typography>;
export const AddAdditionalParts = () => <Typography variant="h6">Add Additional Parts</Typography>;
export const DividerWithPadding = () => (
  <Box py={2}>
    <Divider />
  </Box>
);

export const CheckboxList = ({ field, form, options }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { setFieldValue } = form;
  const { checkboxItems } = field?.value;
  const { associatedPartsAndMaterials } = options;

  const handleEdit = (value, id) => {
    const index = checkboxItems.map(({ productId }) => productId).indexOf(id);
    checkboxItems[index] = value;
    setFieldValue('checkboxItems', checkboxItems);
  };
  const onChange = (part, checked) => {
    setFieldValue(
      'checkboxItems',
      checked
        ? [...checkboxItems, part]
        : checkboxItems.filter(({ productId }) => productId !== part.productId)
    );
  };

  return (
    <Box display="flex" flexDirection="column" width="850px">
      {associatedPartsAndMaterials.map(part => {
        return (
          <Box display="flex" key={part.id} py={1}>
            <Box width="5%">
              <Checkbox
                classes={{ root: classes.root, checked: classes.checked }}
                defaultChecked
                onChange={e => onChange(part, e.target.checked)}
              />
            </Box>
            <DisplayValue
              field={{ value: part.product?.name }}
              options={{ label: 'Item' }}
              width="40%"
            />
            <DisplayValue
              field={{ value: part.product?.description }}
              options={{ label: 'Description' }}
              width="40%"
            />
            <Box width="15%">
              <MUIFormComponents.LabelControl value="Quantity" />
              <TextField
                InputProps={{
                  className: classes.quantityInput,
                  disableUnderline: true,
                  inputProps: { min: 0 }
                }}
                type="number"
                value={part.quantity}
                variant="filled"
                onChange={e =>
                  handleEdit({ ...part, quantity: Number(e.target.value || 0) }, part.productId)
                }
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const DisplayValue = ({ width, ...props }) => (
  <Box display="flex" flexDirection="column" flexWrap="wrap" width={width}>
    <MUIFormComponents.FieldWithLabel {...props} />
  </Box>
);

const styles = theme => ({
  quantityInput: {
    '& input': {
      padding: theme.spacing(0.5)
    }
  },
  root: {
    '&$checked': {
      color: '#000'
    }
  },
  checked: {}
});
