import React, { useCallback, useEffect, useReducer, useState } from 'react';

import {
  Divider,
  FileUploader,
  InlineAlert,
  InlineAlertTypes,
  ThemeProvider,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';
import { Backdrop, Box, CircularProgress } from '@material-ui/core';
import { validateLicense as syncfusionValidateLicense } from '@syncfusion/ej2-base';
import uuid from 'uuid';

import { AttachmentItemPreview, Placeholder } from 'components';

import { StepButtonStateConstants } from '../../InvoiceEmailModal.constants';

import { AttachmentOptions, FileDataDispatchActions } from './AddAttachmentStep.constants';
import { useStyles } from './AddAttachmentStep.styles';
import { addAttachmentsToPDF, prepareFilesData } from './AddAttachmentStep.utils';
import AttachmentOptionsRadioGroup from './components/AttachmentOptionsRadioGroup';
import SelectAttachment from './components/SelectAttachments';

const filesDataReducer = (state, { type, payload }) => {
  switch (type) {
    case FileDataDispatchActions.INITIALIZE: {
      const { invoiceData, invoicePdfBlob } = payload;
      const url = URL.createObjectURL(invoicePdfBlob);
      return [
        {
          id: uuid.v4(), // unique id for invoice pdf
          fileUrl: url,
          blob: invoicePdfBlob,
          fileName: invoiceData.pdfFileName,
          fileSize: invoicePdfBlob.size,
          type: invoicePdfBlob.type,
          remote: false
        }
      ];
    }
    case FileDataDispatchActions.ADD: {
      const { fileData } = payload;
      return [...state, fileData];
    }
    case FileDataDispatchActions.ADD_ALL: {
      const { files } = payload;
      return state.concat(files);
    }
    case FileDataDispatchActions.REMOVE: {
      const { fileData } = payload;
      return state.filter(file => file.id !== fileData.id);
    }
    default:
      return state;
  }
};

const AddAttachmentStep = ({
  handleAttachmentsChange,
  stepIndex,
  invoiceData,
  setNextStepHandlers,
  onButtonStateChange
}) => {
  const styles = useStyles();
  const { getInvoicePdfBlob, job } = invoiceData;
  const [fileUploaderState, setFileUploaderState] = useState({ element: { value: undefined } });
  const [attachmentOption, setAttachmentOption] = useState(AttachmentOptions.COMBINE);
  const [showTypeAlert, setShowTypeAlert] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [filesData, dispatchFilesData] = useReducer(filesDataReducer, []);

  syncfusionValidateLicense();

  const dispatchButtonState = useCallback(
    buttonState => {
      onButtonStateChange(stepIndex, buttonState);
    },
    [onButtonStateChange, stepIndex]
  );

  const handleAttachmentOptionChange = useCallback(selection => {
    setAttachmentOption(selection.target.value);
  }, []);

  useEffect(() => {
    // Close the alert after 8 seconds
    setTimeout(() => setShowTypeAlert(false), 8000);
  }, [showTypeAlert]);

  useEffect(() => {
    const setInvoicePdfBlobData = async () => {
      dispatchButtonState(StepButtonStateConstants.LOADING);
      const invoiceBlobData = await getInvoicePdfBlob();
      dispatchFilesData({
        type: FileDataDispatchActions.INITIALIZE,
        payload: { invoiceData, invoicePdfBlob: invoiceBlobData }
      });
      dispatchButtonState(StepButtonStateConstants.ACTIVE);
    };

    setInvoicePdfBlobData();
  }, [invoiceData, getInvoicePdfBlob, dispatchButtonState, stepIndex]);

  useEffect(() => {
    const getFinalAttachments = async () => {
      if (filesData.length === 1) {
        handleAttachmentsChange(prepareFilesData(filesData));
        return;
      }
      switch (attachmentOption) {
        case AttachmentOptions.SEPARATE: {
          handleAttachmentsChange(prepareFilesData(filesData));
          break;
        }
        case AttachmentOptions.COMBINE:
        default: {
          dispatchButtonState(StepButtonStateConstants.LOADING);
          setShowBackdrop(true);
          // Wait for at least 1 second for the combination step for smoother UX
          const [mergedPDF] = await Promise.all([
            addAttachmentsToPDF(filesData, false),
            new Promise(resolve => setTimeout(resolve, 1000))
          ]);
          handleAttachmentsChange(mergedPDF);
          dispatchButtonState(StepButtonStateConstants.ACTIVE);
          setShowBackdrop(false);
          break;
        }
      }
    };
    setNextStepHandlers(prev => {
      const newStepHandlers = [...prev];
      newStepHandlers[stepIndex] = getFinalAttachments;
      return newStepHandlers;
    });
  }, [
    dispatchButtonState,
    filesData,
    handleAttachmentsChange,
    attachmentOption,
    setNextStepHandlers,
    stepIndex
  ]);

  const prepareUploadedFiles = useCallback(files => {
    return files
      .map(file => {
        let fileType;
        const { id, linkUrl, name, rawFile, size, type } = file;
        // TODO: Support file type other than PDF - jpeg, png, etc...
        if (type === 'pdf') {
          setShowTypeAlert(false);
          fileType = 'application/pdf';
          return {
            id,
            fileUrl: linkUrl,
            blob: new Blob([rawFile], { type: fileType }),
            fileName: name.replace(/\s/g, '_'),
            fileSize: size,
            type: fileType,
            remote: false
          };
        }
        setShowTypeAlert(true);
        return null;
      })
      .filter(Boolean);
  }, []);

  const handleUpdateFilesByUploader = useCallback(
    (type, files) => {
      // Reset the value of the html input so the same files can be submitted again
      fileUploaderState.element.value = '';
      if (type === FileDataDispatchActions.ADD) {
        const allFiles = prepareUploadedFiles(files);
        dispatchFilesData({ type: FileDataDispatchActions.ADD_ALL, payload: { files: allFiles } });
      }
      if (type === FileDataDispatchActions.REMOVE) {
      }
    },
    [prepareUploadedFiles, fileUploaderState.element.value]
  );

  const handleRemoveUploadedFiles = useCallback(
    (type, fileData) => {
      dispatchFilesData({ type, payload: { fileData } });
      handleUpdateFilesByUploader(type, fileData);
    },
    [handleUpdateFilesByUploader]
  );

  return (
    <ThemeProvider>
      <div style={{ width: 688 }}>
        <Typography css={styles.title} variant={TV.XL} weight={TW.BOLD}>
          Select Attachments
        </Typography>
        <Typography css={styles.subtitle} variant={TV.BASE}>
          Select existing job attachments or upload files from another source to attach to the
          invoice email.
        </Typography>
        {job.id && (
          <div style={{ marginBottom: 24 }}>
            <SelectAttachment
              dispatchButtonState={dispatchButtonState}
              dispatchFilesData={dispatchFilesData}
              filesData={filesData}
              jobId={job.id}
              stepIndex={stepIndex}
            />
          </div>
        )}
        <div css={styles.attachmentsContainer}>
          <Typography css={styles.title} variant={TV.L} weight={TW.BOLD}>
            Attachments
          </Typography>
          <Typography variant={TV.BASE}>
            Select files to attach or combine with the invoice. Only PDF file types are supported.
          </Typography>
          <Divider margin={24} />
          {filesData?.length > 1 && (
            <AttachmentOptionsRadioGroup
              value={attachmentOption}
              onChangeAttachmentOption={handleAttachmentOptionChange}
            />
          )}
          <Box display="flex" flexWrap="wrap">
            {filesData?.[0]?.fileUrl ? (
              filesData.map((fileData, index) => (
                <AttachmentItemPreview
                  fileData={fileData}
                  key={fileData.id}
                  removable={index !== 0} // key is important to reduce unnecessary re-renders
                  onRemoveFile={handleRemoveUploadedFiles}
                />
              ))
            ) : (
              <Placeholder
                paddingBottom={8}
                paddingLeft={4}
                paddingRight={4}
                paddingTop={4}
                repeatCount={1}
                variant="card"
              />
            )}
          </Box>
          <FileUploader
            allowedExtensions=".pdf" // TODO: Support other types of files
            handleFilesChange={(type, data) => handleUpdateFilesByUploader(type, data)}
            onCreateUploader={uploader => setFileUploaderState(uploader)}
          />
          {showBackdrop && (
            <Backdrop css={{ zIndex: 1200, backgroundColor: 'rgba(51, 51, 51, 0.8)' }} open>
              <Box alignItems="center" display="flex" flexDirection="column">
                <CircularProgress
                  css={{ color: 'white', marginBottom: 40 }}
                  disableShrink
                  variant="indeterminate"
                />
                <Typography color="white" variant={TV.XL}>
                  Combining Files...
                </Typography>
              </Box>
            </Backdrop>
          )}
          {showTypeAlert && (
            <InlineAlert
              message="Only PDF file types are supported as attachments."
              style={styles.inlineAlert}
              type={InlineAlertTypes.RED}
            />
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default React.memo(AddAttachmentStep);
