import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { GET_VISIT_DETAILS_BY_REPORT_ID } from '../queries';

const CREATE_REVIEW_REPORT = gql`
  mutation createReviewReportFromVisit(
    $partitionKey: String
    $data: CreateReviewReportFromVisitInput!
  ) {
    createReviewReportFromVisit(partitionKey: $partitionKey, data: $data) {
      id
    }
  }
`;

const serializer = ({ tenantId, sortKey }) => ({
  variables: {
    partitionKey: tenantId,
    data: {
      visitSortKey: sortKey
    }
  }
});

export const transformCreatedReviewReportData = data => data?.createReviewReportFromVisit;

export const useCreateReviewReport = techReportId => {
  return useExtendedMutation(CREATE_REVIEW_REPORT, {
    serializer,
    transform: transformCreatedReviewReportData,
    update: (cache, { data: { createReviewReportFromVisit } }) => {
      const cachedData = cache.readQuery({
        query: GET_VISIT_DETAILS_BY_REPORT_ID,
        variables: {
          id: techReportId
        }
      });
      cache.writeQuery({
        query: GET_VISIT_DETAILS_BY_REPORT_ID,
        variables: {
          id: techReportId
        },
        data: {
          getVisitById: {
            ...cachedData.getVisitById,
            reviewReports: {
              items: [
                {
                  id: createReviewReportFromVisit?.id,
                  __typename: 'ReviewReport'
                }
              ],
              __typename: 'ReviewReportConnection'
            }
          }
        }
      });
    }
  });
};
