import React, { useState } from 'react';

import { MUIForm } from '@BuildHero/sergeant';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal } from 'components';
import { taskTemplate, truckTemplate } from 'constants/bundle';
import addToBundleForm from 'meta/Settings/Inventory/items/addToBundleForm';
import {
  taskTemplateItem,
  truckTemplateItem
} from 'meta/Settings/Inventory/items/addToBundleValidation';
import { snackbarOn } from 'redux/actions/globalActions';
import arrayToObject from 'utils/arrayToObject';

import formActions from './formActionConfig';
import SearchBundle from './SearchBundle';
import { addProductToBundle } from './service';

function AddToBundle({ isVisible, handleFormClose, user, product, snackbarOn: snackbar }) {
  const [MUIService, setMUIService] = useState();
  const [bundle, setBundle] = useState({});

  const isTruckTemplate = bundle.type === truckTemplate;

  if (!isVisible) return null;
  const invokeFormSubmit = () => MUIService?.submit(); // eslint-disable-line no-underscore-dangle
  const formConfig = addToBundleForm();
  const formFields = Object.keys(formConfig.fields);

  const handleDialogClose = () => {
    setBundle({});
    handleFormClose();
  };

  const handleOnSave = data =>
    addProductToBundle({
      user,
      product,
      bundle,
      snackbar,
      productProperties: data,
      successCallback: handleDialogClose
    });

  const layout = bundle.type || taskTemplate;
  const { unitCost, unitOfMeasureValue, taxable } = product;

  return (
    <Modal
      buttons={formActions(invokeFormSubmit, handleDialogClose)}
      handleClose={handleDialogClose}
      modalTitle="Add To Bundle"
      open={isVisible}
      showModalHeader
      width="960"
    >
      <SearchBundle bundle={bundle} updateBundle={setBundle} />
      {!isEmpty(bundle) && (
        <MUIForm
          configuration={formConfig}
          data={{
            ...arrayToObject(formFields),
            unitCost,
            unitOfMeasure: unitOfMeasureValue || '',
            taxable
          }}
          layout={layout}
          validationSchema={isTruckTemplate ? truckTemplateItem : taskTemplateItem}
          onComplete={handleOnSave}
          onCreateService={formService => setMUIService(formService)}
        />
      )}
    </Modal>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedAddToBundle = connect(mapStateToProps, mapDispatcherToProps)(AddToBundle);

export default reduxConnectedAddToBundle;

AddToBundle.propTypes = {
  isVisible: PropTypes.bool,
  user: PropTypes.object.isRequired,
  snackbarOn: PropTypes.func.isRequired,
  handleFormClose: PropTypes.func.isRequired
};

AddToBundle.defaultProps = {
  isVisible: false
};
