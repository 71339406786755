/* eslint-disable jsx-a11y/click-events-have-key-events,no-nested-ternary,react/require-default-props */
import React, { useEffect } from 'react';

import { Checkbox, theme, ThemeProvider, TV, Typography } from '@BuildHero/sergeant';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { intersection } from 'lodash';
import moment from 'moment';
import { bool, func, oneOf, string } from 'prop-types';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Link } from 'react-router-dom';

import JobLink from '@dispatch/components/JobLink';
import VisitLink from '@dispatch/components/VisitLink';
import { EVENT_TYPES, VisitActions, VisitStatuses } from '@dispatch/Dispatch.constants';
import { selectRangeText } from '@dispatch/Dispatch.selectors';
import { ElementSizes } from '@dispatch/Dispatch.styles';
import { emptyVisitDetailsFragment } from '@dispatch/fragments';
import { VISIT_DETAILS_PROP } from '@dispatch/queries';
import Routes from 'scenes/Routes';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import StatusHeader from '../StatusHeader';

import { CornerBannerConfig, CornerBannerTypes } from './VisitCard.constants';
import {
  useGetCornerBannerProps,
  useVisitDrag,
  useVisitEndDrag,
  useVisitStartDrag
} from './VisitCard.hooks';
import { useCornerBannerStyles, useStyles } from './VisitCard.styles';

const CornerBanner = ({ checked, bannerType, shouldRender }) => {
  const bannerConfig = CornerBannerConfig[bannerType] || {};
  const styles = useCornerBannerStyles({ bannerConfig, checked });
  const { icon, text, hasCheckbox, TV: textVariant } = bannerConfig;
  if (!shouldRender) return null;

  return (
    <div css={styles.root}>
      {icon ?? null}
      {text && <Typography variant={textVariant || TV.S1}>{text}</Typography>}
      {hasCheckbox && <Checkbox checked={checked} css={styles.checkbox} disabled noWrapper />}
    </div>
  );
};

CornerBanner.propTypes = {
  checked: bool,
  bannerType: oneOf(Object.values(CornerBannerTypes)),
  shouldRender: bool
};

const VisitCard = ({
  visit,
  loading,
  srcTech,
  openEvent,
  mapView,
  weekView,
  isDragging,
  isDrawer
}) => {
  const flags = useFlags();
  const isOptimistic = visit.id === emptyVisitDetailsFragment.id;
  const Status = VisitStatuses.get(visit.status)?.value || {};
  const rangeText = selectRangeText(visit);
  const { bannerType, checked: bannerChecked } = useGetCornerBannerProps(
    visit,
    flags[FeatureFlags.VISIT_CLOSEOUT_ACTIONS]
  );
  const [, dragRef, dragPreview] = useVisitDrag({ visit, srcTech, isDrawer, isOptimistic });

  const [collectedStart, startDragRef, startDragPreview] = useVisitStartDrag({
    visit,
    srcTech,
    weekView,
    mapView,
    wrinkleTimeFF: flags[FeatureFlags.WRINKLE_IN_TIME],
    isOptimistic
  });

  const [collectedEnd, endDragRef, endDragPreview] = useVisitEndDrag({
    visit,
    srcTech,
    weekView,
    mapView,
    wrinkleTimeFF: flags[FeatureFlags.WRINKLE_IN_TIME],
    isOptimistic
  });

  const styles = useStyles({
    loading,
    mapView,
    weekView,
    visitWidth: visit.width,
    isOptimistic,
    isDragging,
    canDragVisit: Boolean(
      intersection(visit.nextEvents, [VisitActions.SCHEDULE.key, VisitActions.RESCHEDULE.key])[0]
    ),
    canDragStart: collectedStart.canDrag,
    canDragEnd: collectedEnd.canDrag,
    isDrawer
  });

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
    startDragPreview(getEmptyImage(), { captureDraggingState: true });
    endDragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const handleVisitClick = event => {
    if (!isOptimistic) {
      event.stopPropagation();
      if (!loading) {
        openEvent({
          eventId: visit.id,
          eventType: EVENT_TYPES.VISIT
        });
      }
    }
  };

  return (
    <ThemeProvider>
      <div
        css={styles.root}
        ref={dragRef}
        role="button"
        style={{
          left: isDragging ? undefined : visit.left,
          top: isDragging ? undefined : (visit.position || 0) * ElementSizes.cellHeight,
          backgroundColor: Status.secondaryColor || theme.palette.grayscale(98),
          border: `solid 1px ${Status.primaryColor || theme.palette.grayscale(80)}`
        }}
        tabIndex="0"
        onClick={handleVisitClick}
      >
        <div css={styles.positioningBoundary}>
          <div css={styles.dragStart} ref={startDragRef} />
          <CornerBanner
            bannerType={bannerType}
            checked={bannerChecked}
            shouldRender={!!bannerType}
          />
          <StatusHeader loading={loading} small visit={visit} weekView={weekView} />
          <div css={styles.content}>
            <Typography css={styles.propertyName} loading={loading} variant="body2" width={100}>
              <Link to={Routes.property({ mode: 'view', id: visit.property?.id })}>
                {visit.property?.customerPropertyName}
              </Link>
            </Typography>
            <Typography css={styles.visitNumber} loading={loading} variant="body2" width={80}>
              <JobLink job={visit.job} />, <VisitLink visit={visit} />
            </Typography>
            {weekView && (
              <Typography css={styles.scheduledFor} variant="body2">
                {rangeText}
              </Typography>
            )}
            {mapView && visit.scheduledFor && (
              <Typography css={styles.scheduledFor} variant="body2">
                Scheduled: {moment.unix(visit.scheduledFor).format('MM/DD/YY hh:mm a')}
              </Typography>
            )}
            {mapView && visit.description && (
              <Typography css={styles.description} variant="body2">
                {visit.description}
              </Typography>
            )}
          </div>
          <div css={styles.dragEnd} ref={endDragRef} />
        </div>
      </div>
    </ThemeProvider>
  );
};

VisitCard.propTypes = {
  loading: bool,
  isDragging: bool,
  isDrawer: bool,
  mapView: bool,
  openEvent: func.isRequired,
  visit: VISIT_DETAILS_PROP.isRequired,
  srcTech: string,
  weekView: bool
};

VisitCard.defaultProps = {
  isDragging: false,
  isDrawer: false,
  loading: false
};

export default VisitCard;
