import gql from 'graphql-tag';

import { useDispatch } from 'react-redux';

import { updateWatchedVisitQueries } from '@dispatch/Dispatch.utils';
import { emptyVisitDetailsFragment, visitDetailsFragment } from '@dispatch/fragments';
import useExtendedMutation from 'customHooks/useExtendedMutation';
import { createMultiVisitEnd } from 'redux/actions/dispatchActions';
import { snackbarOn } from 'redux/actions/globalActions';
import mergeDeep from 'utils/mergeDeep';

import getOptimisticVisit from '../getOptimisticVisit';

const ADD_MULTIPLE_VISITS_MUTATION = gql`
  mutation createRecurringVisit($partitionKey: String!, $input: AddRecurringVisitInput!) {
    createRecurringVisit(partitionKey: $partitionKey, data: $input) {
      ${visitDetailsFragment}
    }
  }
`;

const serializer = ({ tenantId, tenantCompanyId, visit, job }) => {
  const {
    endDate,
    excludeWeekend,
    extraTechs,
    extraTechsNumber,
    primaryTechId,
    scheduledFor,
    actualDuration,
    ...visitInput
  } = visit;
  return {
    variables: {
      partitionKey: tenantId,
      input: {
        endDate,
        excludeWeekend,
        visit: {
          tenantCompanyId,
          jobId: job.id,
          primaryTechnicianId: primaryTechId,
          extraTechnicians: {
            requiredCount: extraTechsNumber || 0,
            technicianIds: extraTechs || []
          },
          visitDateTime: {
            actualDuration: `${actualDuration} minutes`,
            scheduledFor
          },
          ...visitInput,
          job: undefined
        }
      }
    }
  };
};

const optimisticResponseFactory = ({ visit, job }) => {
  const optimisticVisit = getOptimisticVisit({ visit, job });
  return { createRecurringVisit: [mergeDeep(emptyVisitDetailsFragment, optimisticVisit)] };
};

const useAddMultipleVisits = () => {
  const dispatch = useDispatch();

  return useExtendedMutation(ADD_MULTIPLE_VISITS_MUTATION, {
    serializer,
    optimisticResponseFactory,
    update: (cache, { data }) => {
      data.createRecurringVisit?.forEach(updatedVisit =>
        updateWatchedVisitQueries({
          cache,
          updatedVisit
        })
      );
    },
    onError: () => {
      dispatch(createMultiVisitEnd());
    },
    onCompleted: () => {
      dispatch(createMultiVisitEnd());
      dispatch(snackbarOn('success', 'Recurring created successfully.'));
    }
  });
};

export default useAddMultipleVisits;
