import React, { useCallback, useState } from 'react';

import { ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';

import {
  Box,
  IconButton,
  List,
  ListItem,
  Tooltip,
  useTheme,
  withStyles,
  withWidth
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { noop, sortBy } from 'lodash';
import { useSelector } from 'react-redux';

import { PageHeader, SergeantModal } from 'components';
import Context from 'components/Context';
import styles from 'components/Layouts/LeftSidebarWithContent.styles';
import ErrorBoundaries from 'scenes/Error';
import { Mode } from 'utils/constants';

import { addAssetTypeLayout } from './addAssetTypeLayout';

import ChecklistListing from './checklistListing';
import { useAddAssetTypeToCompany } from './hooks/useAddAssetType';

const breadcrumbsArray = [
  { link: '', title: 'Directory' },
  { link: `/checklistLibrary`, title: `Checklist Library` }
];

export const PROPERTY_CHECKLISTS = {
  tagName: 'Property Checklists',
  id: 'null'
};

export const TooltipInfo = ({ options: { message } }) => {
  const theme = useTheme();
  return (
    <Tooltip title={message}>
      <InfoOutlinedIcon css={{ marginLeft: theme.spacing(0.5), cursor: 'pointer', fontSize: 14 }} />
    </Tooltip>
  );
};

const ChecklistLibrary = ({ classes }) => {
  const theme = useTheme();
  const user = useSelector(state => state.user);
  const assetTypes = useSelector(state => state.company?.assetTypes?.items);
  const sortedAssetTypes = sortBy(assetTypes, 'sortOrder');
  const [currentAssetType, setCurrentAssetType] = useState(
    sortedAssetTypes[0] ?? PROPERTY_CHECKLISTS
  );
  const [addAssetTypeModal, setAddAssetTypeModal] = useState({ open: false, mode: Mode.VOID });
  const [addAssetType] = useAddAssetTypeToCompany();

  const handleAddAssetType = useCallback(
    async values => {
      await addAssetType({ assetType: values.assetTypeName, sortOrder: assetTypes.length });
      setAddAssetTypeModal({ open: false });
      Context.setCompanyContext(
        user.tenantId,
        Context.generateCompanyContextSortKey(user),
        noop,
        true
      );
    },
    [assetTypes]
  );

  return (
    <ErrorBoundaries>
      <PageHeader
        breadcrumbsArray={breadcrumbsArray}
        pageMapKey="checklistLibrary"
        userLocale={user.locale}
      />
      <div className={classes.body} css={{ height: '100%' }}>
        <ThemeProvider>
          <div className={classes.sidebar}>
            <div className={classes.sidebarInner}>
              <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography variant={TV.BASE} weight={TW.BOLD}>
                  Asset Types
                </Typography>
                <IconButton
                  css={{ padding: 2 }}
                  onClick={() => setAddAssetTypeModal({ open: true, mode: Mode.ADD, data: {} })}
                >
                  <AddIcon css={{ color: theme.palette.grayscale(20) }} fontSize="small" />
                </IconButton>
              </Box>
              <List>
                {[...sortedAssetTypes, PROPERTY_CHECKLISTS].map(type => (
                  <ListItem
                    button
                    css={
                      type.id === currentAssetType?.id
                        ? {
                            color: theme.palette.support.blue.dark,
                            fontWeight: 'bold',
                            backgroundColor: 'none'
                          }
                        : {}
                    }
                    key={`listItem-${currentAssetType.id}-${type.id}`}
                    selected={type.id === currentAssetType?.id}
                    onClick={() => setCurrentAssetType(type)}
                  >
                    {type.tagName}
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        </ThemeProvider>
        <div className={classes.detail} css={{ padding: 16, height: '100%' }}>
          {currentAssetType && (
            <div className={classes.detailForm} css={{ height: '100%' }}>
              <ChecklistListing currentAssetType={currentAssetType} isChecklistDefinition />
            </div>
          )}
        </div>
      </div>
      <SergeantModal
        customPrimaryButtonLabel="Add Asset Type"
        data={addAssetTypeModal.data}
        dataType="Asset Type"
        formVersion={addAssetTypeModal.mode}
        fullWidth
        handleClose={() => setAddAssetTypeModal({ open: false })}
        handlePrimaryAction={handleAddAssetType}
        layout={addAssetTypeLayout}
        maxWidth={480}
        mode={addAssetTypeModal.mode}
        open={addAssetTypeModal.open}
      />
    </ErrorBoundaries>
  );
};

const WithWidth = withWidth()(ChecklistLibrary);
export default withStyles(styles)(WithWidth);
