import {
  composeAddTaskPayload,
  composeTaskEntriesPayload,
  composeUpdateTaskPayload
} from './CreateModal.utils';

export const onCreateTaskSave = async ({
  addTaskToProperty,
  addTaskEntryToTask,
  isLoading,
  onComplete,
  payload,
  tenantId
}) => {
  const composedAddTaskPayload = composeAddTaskPayload(payload);
  const [, entriesPayload] = composeTaskEntriesPayload(payload.partsData);

  const response = await addTaskToProperty({
    customerPropertyId: payload.propertyId,
    tasks: [composedAddTaskPayload],
    tenantId
  });

  if (entriesPayload.length) {
    await addTaskEntryToTask({
      tenantId,
      taskId: response?.data?.addTasksToCustomerProperty[0]?.id,
      taskEntries: entriesPayload
    });
  }

  if (!isLoading) {
    onComplete();
  }
};

export const onEditTaskSave = async ({
  addTaskEntryToTask,
  deleteAttachmentById,
  deleteFormById,
  isLoading,
  onComplete,
  payload,
  refetchTaskData,
  row,
  tenantId,
  updateTask
}) => {
  const [existingParts, newParts] = composeTaskEntriesPayload(payload.partsData);
  const composedPayload = composeUpdateTaskPayload(payload, existingParts);
  const prevAttachmentData = JSON.parse(row.attachmentData) || [];
  const prevFormsData = JSON.parse(row.formData) || [];

  const attachmentsToDelete = prevAttachmentData.filter(
    prevA => !payload.attachments.some(currA => currA.id === prevA.id)
  );
  if (attachmentsToDelete.length) {
    attachmentsToDelete.forEach(item => deleteAttachmentById({ id: item.id, tenantId }));
  }

  const formsToDelete = prevFormsData.filter(
    prevF => !payload.addedForms.some(currF => currF.formDataId === prevF.formDataId)
  );

  if (formsToDelete.length) {
    formsToDelete.forEach(item => deleteFormById({ id: item.formDataId, tenantId }));
  }

  const taskId = row?.id;
  await updateTask({
    data: {
      ...composedPayload,
      id: taskId,
      version: row?.version
    },
    tenantId
  });

  if (newParts.length) {
    await addTaskEntryToTask({
      tenantId,
      taskId,
      taskEntries: newParts
    });
  }

  if (!isLoading) {
    onComplete();
    refetchTaskData(taskId);
  }
};
