import gql from 'graphql-tag';

const getTechnicianReportById = gql`
  query getTechnicianReportById($id: String!) {
    getVisitById(id: $id) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      tenantId
      hierarchy
      entityType
      status
      version
      createdDateTime
      scheduledFor
      lastUpdatedDateTime
      submittedBy
      submittedDate
      primaryTechs(entityConnection: "VisitPrimaryTech") {
        items {
          id
          mappedEntity {
            ... on Employee {
              id
              firstName
              lastName
              truckEmployeeView(entityConnection: "TruckEmployee") {
                items {
                  id
                  parentEntity {
                    ... on Truck {
                      id
                      name
                      isActive
                    }
                  }
                }
              }
            }
          }
        }
      }
      extraTechs(entityConnection: "VisitExtraTech") {
        items {
          id
          mappedEntity {
            ... on Employee {
              id
              firstName
              lastName
              truckEmployeeView(entityConnection: "TruckEmployee") {
                items {
                  id
                  parentEntity {
                    ... on Truck {
                      id
                      name
                      isActive
                    }
                  }
                }
              }
            }
          }
        }
      }
      timesheetNotes(entityConnection: "TimesheetNote") {
        items {
          id
          note
          subject
          createdBy
          lastUpdatedBy
          attachments(entityConnection: "Attachment") {
            items {
              fileUrl
              id
              _deleted
            }
          }
        }
      }
      timesheetEntries: timesheetEntriesView(entityConnection: "TimesheetEntry") {
        items {
          id
          reviewStatus
          approvalStatus
          actualStartTimeUTC
          actualStartTimeOverrideUTC
          actualEndTimeUTC
          actualEndTimeOverrideUTC
          actualTotalDuration
          actualTotalDurationOverride
          lastUpdatedBy
          lastUpdatedDateTime
          createdBy
          cost
          hourTypeId
          hourType {
            id
            hourType
            hourTypeAbbreviation
          }
          startLatitude
          startLongitude
          endLatitude
          endLongitude
          userActionType
          billableEntityId
          entryType
          manualStatus
          manualApprovedBy
          manualApprovedDate
          timesheetPeriod {
            parentId
          }
          timekeepingLedger(entityConnection: "TimekeepingLedger") {
            id
            employeeName
            billableEntityType
            eventType(entityConnection: "EventType") {
              id
              name
              isBillable
            }
            eventEntity(entityConnection: "EventEntity") {
              ... on NonVisitEvent {
                id
                name
                timesheetNotes(entityConnection: "TimesheetNote") {
                  items {
                    id
                    note
                    subject
                    createdBy
                    attachments(entityConnection: "Attachment") {
                      items {
                        fileUrl
                        id
                        _deleted
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      parentEntity {
        ... on Job {
          id
          jobNumber
          closeoutReport
          customIdentifier
          tenantId
          jobTypeName
          jobTypeInternal
          issueDescription
          lastUpdatedBy
          lastUpdatedDateTime
          sortKey
          totalBudgetedHours
          owner(entityConnection: "owner") {
            id
            name
            firstName
            lastName
          }
          billingCustomer(entityConnection: "BillingCustomer") {
            id
            sortKey
            customerName
            companyAddresses(entityConnection: "CompanyAddress") {
              items {
                id
                addressType
                addressLine1
                addressLine2
                billTo
                city
                state
                zipcode
                longitude
                latitude
              }
            }
          }
          parentEntity {
            ... on CustomerProperty {
              companyName
              id
              billTo
              parentEntity {
                ... on Customer {
                  customerName
                  id
                  companyAddresses(entityConnection: "CompanyAddress") {
                    items {
                      id
                      addressType
                      addressLine1
                      addressLine2
                      city
                      state
                      zipcode
                      longitude
                      latitude
                      billTo
                    }
                  }
                }
              }
              companyAddresses(entityConnection: "CompanyAddress") {
                items {
                  id
                  addressType
                  addressLine1
                  addressLine2
                  city
                  state
                  zipcode
                  longitude
                  latitude
                }
              }
            }
          }
        }
      }
      visitNumber
      description
      lastUpdatedBy
      lastUpdatedDateTime
      departmentName
      parentEntity {
        ... on Job {
          id
          jobNumber
          parentEntity {
            ... on CustomerProperty {
              companyAddresses(entityConnection: "CompanyAddress") {
                items {
                  id
                  latitude
                  longitude
                }
              }
            }
          }
        }
      }
      reviewReports(entityConnection: "ReviewReport") {
        items {
          id
          sortKey
          createdBy
          createdDateTime
        }
      }
      purchaseOrders(entityConnection: "PurchaseOrder") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          tenantId
          hierarchy
          entityType
          vendorName
          receiptNumber
          poNumber
          createdBy
          dateOfPurchase
          totalAmountPreTax
          tax
          purchaseOrderLines(entityConnection: "PurchaseOrderLine") {
            items {
              id
              entityType
              itemName
              description
              quantity
              unitCost
              markup
              unitPrice
              amount
              createdBy
            }
          }
          purchaseOrderReceipts(entityConnection: "PurchaseOrderReceipt") {
            items {
              id
              imageUrl
              fileName
              version
            }
          }
        }
      }
      inventoryParts(entityConnection: "InventoryPart") {
        items {
          id
          sortKey
          name: itemName
          departmentName
          accountingRefIdOfClass
          description
          truck(entityConnection: "Truck") {
            value: id
            label: name
          }
          vendorName
          quantity
          unitCost
          unitPrice
          markupValue: markup
          amount
          taxable
          includeInInvoice
          version
          addedBy
          createdBy
          costCode(entityConnection: "CostCode") {
            value: id
            label: name
          }
          jobCostType(entityConnection: "jobCostType") {
            value: id
            label: name
            type
          }
          revenueType(entityConnection: "revenueType") {
            value: id
            label: name
            type
          }
          product {
            id
            name
            version
            unitOfMeasure(entityConnection: "UnitOfMeasure") {
              id
              name
            }
          }
        }
      }
      visitAssets(entityConnection: "VisitAsset", filter: { propertyAssetId: { ne: "null" } }) {
        items {
          id
          propertyAsset(entityConnection: "PropertyAsset") {
            id
            assetName
            make
            modelNumber
            serialNo
            installDate
            location
            propertyZone
            imageUrl
          }
        }
      }
      summaries(entityConnection: "Summary") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          tenantId
          hierarchy
          entityType
          summary
          lastUpdatedBy
          lastUpdatedDateTime
        }
      }
      attachments(entityConnection: "Attachment") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          tenantId
          hierarchy
          entityType
          customFileName
          fileName
          fileUrl
          type
          addedBy
          addedDateTime
          dateAdded
          createdDate
          createdBy
          description
          comment
          sortOrder
        }
      }
      notes(entityConnection: "Note") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          tenantId
          hierarchy
          entityType
          createdBy
          subject
          note
          lastUpdatedBy
          lastUpdatedDateTime
          version
        }
      }
      customerSignatures(entityConnection: "CustomerSignature") {
        items {
          nameOfSignee
          signatureImageUrl
          signedDateTime
          capturedBy
          includeInInvoice
          createdBy
          createdDate
        }
      }
      timeSheets(entityConnection: "TimeSheet") {
        items {
          createdBy
          clockInTime
          clockOutTime
          totalDuration
          labourType
          lastUpdatedBy
          lastUpdatedDateTime
          gpsLocations(entityConnection: "GpsLocation") {
            items {
              latitude
              longitude
              userAction
            }
          }
        }
      }
      timeCardLinesView(entityConnection: "TimeCardLine") {
        items {
          lastUpdatedBy
          lastUpdatedDateTime
          timeType
          timeTypeAbbreviation
          timeMinutes
          employeeId
          employeeName
        }
      }
      timeCardVisitsView(entityConnection: "TimeCardVisit") {
        items {
          approvedBy
          approvedDateTime
          status
          employeeId
          employeeName
          notes(entityConnection: "Note") {
            items {
              note
              subject
              lastUpdatedBy
              lastUpdatedDateTime
            }
          }
        }
      }
      nonVisitEvents {
        nextToken
        items {
          id
          name
          description
          assignedEntityType
          assignedEntityId
          plannedStartTimeUTC
          plannedEndTimeUTC
          status
          eventTypeId
          departmentId
          assignedEntityId
          employeeId
          parentId
          isActive
          entityType
          createdBy
          createdDate
          createdDateTime
          timesheetNotes {
            items {
              id
              note
              subject
              createdBy
              lastUpdatedBy
              attachments(entityConnection: "Attachment") {
                items {
                  fileUrl
                  id
                  _deleted
                }
              }
            }
          }
          timekeepingLedgersView {
            nextToken
            items {
              id
              userActionType
              employeeName
              startLatitude
              startLongitude
              endLatitude
              endLongitude
              actualStartTimeUTC
              actualEndTimeUTC
              actualHours
              actualMinutes
              actualTotalDuration
              actualCreatedDate
              employeeId
            }
          }
          timesheetEntries {
            items {
              id
              reviewStatus
              approvalStatus
              actualStartTimeUTC
              actualStartTimeOverrideUTC
              actualEndTimeUTC
              actualEndTimeOverrideUTC
              actualTotalDuration
              actualTotalDurationOverride
              lastUpdatedBy
              lastUpdatedDateTime
              createdBy
              cost
              hourTypeId
              hourType {
                id
                hourType
                hourTypeAbbreviation
              }
              startLatitude
              startLongitude
              endLatitude
              endLongitude
              userActionType
              billableEntityId
              entryType
              manualStatus
              manualApprovedBy
              manualApprovedDate
              timesheetPeriod {
                parentId
              }
            }
          }
        }
      }
    }
  }
`;

export default getTechnicianReportById;
