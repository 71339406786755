export const labelSchema = {
  gatedWorkflowsSectionTitle: {
    en: 'Gated Workflows',
    es: 'Gated Workflows'
  },
  saveButtonLabel: {
    en: 'Save changes',
    es: 'Save changes'
  },
  customerSignatureSettingsSectionTitle: {
    en: 'Customer Signature Page Settings',
    es: 'Customer Signature Page Settings'
  },
  customerSignatureSettingsSectionSubTitle: {
    en: 'Specify the details that will be displayed to customers on the customer signature page',
    es: 'Specify the details that will be displayed to customers on the customer signature page'
  },
  visitSummaryVisible: {
    en: 'Visit Summary',
    es: 'Visit Summary'
  },
  assetsWorkedOnVisible: {
    en: 'Assets Worked On',
    es: 'Assets Worked On'
  },
  inventoryItemsUsedVisible: {
    en: 'Inventory Items Used',
    es: 'Inventory Items Used'
  },
  purchasedItemsVisible: {
    en: 'Purchase Orders',
    es: 'Purchase Orders'
  },
  purchasedItemsVendorNameVisible: {
    en: 'Vendor Name',
    es: 'Vendor Name'
  }
};

export default labelSchema;
