import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';

import { PageHeader, ResponsiveTable, UserPermission } from 'components';
import CreateEntryButton from 'components/Buttons/CreateEntryButton';
import { Tab, Tabs } from 'components/Tabs';
import ErrorBoundaries from 'scenes/Error';
import { PermissionConstants } from 'utils/AppConstants';

import CreateCrew from './CreateCrew';
import EmployeeSection from './EmployeeSection';
import useAppRoles from './useAppRoles';
import { getAllCrews } from './utils';

const PersonnelTabs = {
  EMPLOYEES: 'EMPLOYEES',
  CREWS: 'CREWS'
};

const tabIndexMap = {
  0: PersonnelTabs.EMPLOYEES,
  1: PersonnelTabs.CREWS
};

const People = props => {
  document.title = 'BuildOps - People';
  const [selectedTab, setSelectedTab] = useState(tabIndexMap[0]);
  const [isCreatingCrew, setIsCreatingCrew] = useState(false);
  const user = useSelector(s => s.user);

  const [appRoles] = useAppRoles();

  const pageHeaderButtons = {
    [PersonnelTabs.CREWS]: [
      <CreateEntryButton
        handleAdd={() => setIsCreatingCrew(true)}
        key="createCrew"
        label="New Crew"
      />
    ]
  };

  const crewListMeta = [
    {
      id: 'name',
      filterKey: 'Crews.name',
      label: 'Name',
      showLink: 'view',
      linkPath: '/settings/crew/view',
      linkReference: 'id'
    },
    {
      id: 'departmentName',
      filterKey: 'Department.tagName',
      label: 'Department'
    },
    {
      id: 'foremanName',
      filterKey: 'foreman.name',
      label: 'Foreman'
    },
    {
      id: 'techNames',
      label: 'Crew Members',
      disableSort: true
    },
    {
      id: 'numTechs',
      label: 'Number of Crew Members',
      disableSort: true
    }
  ];

  return (
    <ErrorBoundaries>
      <PageHeader
        overrideHeaderButtons={pageHeaderButtons[selectedTab]}
        pageMapKey="people"
        userLocale={user.locale}
      />
      <UserPermission action={PermissionConstants.MENU_SETTINGS} I="allow">
        <Grid container direction="row" spacing={3}>
          <UserPermission action={PermissionConstants.OBJECT_EMPLOYEE} I="read">
            <Grid item lg={12} md={12} sm={12} style={{ paddingTop: 50 }} xs={12}>
              <Tabs
                onChange={(event, value) => {
                  setSelectedTab(tabIndexMap[value]);
                }}
              >
                <Tab label="Employees" tabKey="employees">
                  <EmployeeSection
                    appRoles={appRoles}
                    key={appRoles?.length}
                    mounted={props.mounted}
                    openCurrentEmployee={props.location.openCurrentEmployee}
                  />
                </Tab>
                <Tab label="Crews" tabKey="crews">
                  <ResponsiveTable
                    disableFilter
                    noDataMsg="No Crews"
                    rowMetadata={crewListMeta}
                    service={getAllCrews}
                    useServerQueries
                  />
                </Tab>
              </Tabs>
            </Grid>
          </UserPermission>
        </Grid>
      </UserPermission>
      <CreateCrew
        handleClose={() => setIsCreatingCrew(false)}
        history={props.history}
        open={isCreatingCrew}
      />
    </ErrorBoundaries>
  );
};

export default People;
