import orderBy from 'lodash/orderBy';
import moment from 'moment';

import { ElementSizes } from '@dispatch/Dispatch.styles';
import { ItemTypes } from '@dispatch/dnd';

import { sortByStart } from './DispatchBoard.utils';

const SECONDS_IN_ONE_DAY = 60 * 60 * 24;

export const selectVisitXPosition = visit => {
  const dayStart = moment
    .unix(visit.scheduledFor)
    .startOf('day')
    .unix();

  return Math.floor(
    ((visit.scheduledFor - dayStart) / SECONDS_IN_ONE_DAY) * ElementSizes.timeWidth
  );
};

export const selectVisitWidth = visit => {
  return (visit.actualDuration / 60) * ElementSizes.cellWidth || ElementSizes.techCellWidth;
};

export const selectCardXPosition = timestamp => {
  const dayStart = moment
    .unix(timestamp)
    .startOf('day')
    .unix();

  return Math.floor(((timestamp - dayStart) / SECONDS_IN_ONE_DAY) * ElementSizes.timeWidth);
};

export const selectCardWidth = durationInMinutes => {
  return (durationInMinutes / 60) * ElementSizes.cellWidth || ElementSizes.techCellWidth;
};

export const selectEndTime = ({ day, endDay, mapView, weekView, weekViewStartDay }) => {
  if (mapView) {
    return moment(endDay)
      .endOf('day')
      .unix();
  }

  if (weekView) {
    if (weekViewStartDay) {
      return moment(day)
        .isoWeekday(weekViewStartDay)
        .add(7, 'days')
        .endOf('day')
        .unix();
    }
    return moment(day)
      .endOf('isoWeek')
      .unix();
  }

  return moment(day)
    .endOf('day')
    .unix();
};

export const selectStartTime = ({ day, weekView, weekViewStartDay }) => {
  if (weekView) {
    if (weekViewStartDay) {
      return moment(day)
        .isoWeekday(weekViewStartDay)
        .subtract(8, 'days')
        .startOf('day')
        .unix();
    }
    return moment(day)
      .startOf('isoWeek')
      .unix();
  }

  return moment(day)
    .startOf('day')
    .unix();
};

export const selectTechStatus = (techId, visits) => {
  const techVisitSchedules = visits?.reduce((result, visit) => {
    const schedules = visit?.schedules?.filter(schedule => schedule?.employee?.id === techId) || [];
    return [...result, ...schedules];
  }, []);

  return orderBy(techVisitSchedules, ['lastUpdatedDateTime'], ['desc'])[0]?.status;
};

export const selectBoardVisits = (visits = []) => {
  return visits.filter(
    visit => visit.Status.showOnBoard && visit.actualDuration && visit.scheduledFor
  );
};

export const selectTechEvents = ({ techVisits, techNonVisitEvents, techManDayItems }) => {
  const events = [...selectBoardVisits(techVisits), ...techNonVisitEvents, ...techManDayItems].sort(
    sortByStart
  );

  const positionMap = {};

  const selectPosition = event => {
    const positions = Object.keys(positionMap);

    for (let i = 0; i < positions.length; i += 1) {
      const positionRange = positionMap[i];
      if (!event.range.overlaps(positionRange)) return i;
    }

    return positions.length;
  };

  return events.map(event => {
    const position = selectPosition(event);

    if (!positionMap[position] || positionMap[position].end.isBefore(event.range.end)) {
      positionMap[position] = event.range;
    }

    return { ...event, position };
  });
};

export const selectDropTech = item => {
  switch (item?.type) {
    case ItemTypes.BOARD_VISIT:
    case ItemTypes.TABLE_VISIT:
      return item?.data?.primaryTechId;
    case ItemTypes.NON_VISIT_EVENT:
      return item?.data?.employeeId;
    default:
      return undefined;
  }
};
