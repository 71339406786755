import { useExtendedFetchForPMAPI as useExtendedFetch } from 'customHooks/useExtendedFetch';

import { AccountingSettings } from './ProjectAccountingSettings.constants';

export const useUpdateProjectAccountingSettings = () => {
  return useExtendedFetch(
    {
      url: 'accounting-settings',
      method: 'POST'
    },
    {
      defaultData: {},
      manual: true
    }
  );
};

const insertSettingIfValue = (settingKey, data) => {
  const value = data?.[settingKey]?.value;
  if (value) {
    return {
      [settingKey]: {
        value
      }
    };
  }

  return {};
};

export const serializeSettings = data => ({
  settingsJSON: {
    ...insertSettingIfValue(AccountingSettings.RETAINAGE, data),
    ...insertSettingIfValue(AccountingSettings.CHANGE_ORDERS, data)
  }
});
