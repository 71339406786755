import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SergeantModal } from 'components';
import DefaultButton from 'components/Buttons/DefaultButton';
import ResponsiveTable from 'components/ResponsiveTable';
import Labels from 'meta/labels';
import simpleRowActionButtons from 'meta/ProjectManagement/global/simpleTableRowActionButton';
import submittalPackageRowMeta from 'meta/ProjectManagement/Submittals/submittalPackageTable';
import { setOpenQuickAddModal } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
// import CustomSearchBar from 'scenes/ProjectManagement/components/CustomSearchBar';
import CustomHighlightCard from 'scenes/ProjectManagement/components/CustomHighlightCard';
import {
  SubmittalsPackageStatus,
  SubmittalsPackageStatusBtnTitles
} from 'scenes/ProjectManagement/constants';
import { getSubmittalPackages, submittalPackageChange } from 'services/API/submittalPackage';

import CreateSubmittalPackage from './CreateSubmittalPackage';
import PackageDetailView from './PackageDetailView';

const useStyles = makeStyles(() => ({
  root: {
    margin: '31px 0px 20px 17px'
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  highlightCardContainer: {
    marginTop: 20
  }
}));

const PackageLists = props => {
  const { user, projectId, projectData, packageId } = props;
  const classes = useStyles();
  const [currentTable, setCurrentTable] = useState(0);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isOpendModal, setIsOpendModal] = useState(false);
  const [selectedEditItem, setSelectedEditItem] = useState({});
  const [isDetailViewOpen, setIsDetailViewOpen] = useState(!!packageId);
  const [draftPackages, setDraftPackages] = useState([]);
  const [sentPackages, setSentPackages] = useState([]);
  const [respondedPackages, setRespondedPackages] = useState([]);
  const [closedPackages, setClosedPackages] = useState([]);

  const history = useHistory();

  const sanitizeData = data => {
    return data.map(item => {
      const daysOverdue = item.dueDate ? moment().diff(moment.unix(item.dueDate), 'days') : 0;

      return {
        ...item,
        packageItems: item.submittalPackageSubmittalItems?.length || 0,
        daysOverdue: daysOverdue > 0 ? daysOverdue : 0
      };
    });
  };

  const getTableData = tableIndex => {
    switch (tableIndex) {
      case 1:
        return sentPackages;
      case 2:
        return respondedPackages;
      case 3:
        return closedPackages;
      case 0:
      default:
        return draftPackages;
    }
  };

  const getAllSubmittals = () => {
    getSubmittalPackages(projectId).then(items => {
      if (!items || items?.length === 0) return;

      const sanitizedData = sanitizeData(items);
      const draft = sanitizedData.filter(item => item.status === SubmittalsPackageStatus.DRAFT);
      const sent = sanitizedData.filter(item => item.status === SubmittalsPackageStatus.SENT);
      const returned = sanitizedData.filter(
        item => item.status === SubmittalsPackageStatus.RESPONDED
      );
      const approved = sanitizedData.filter(
        item => item.status === SubmittalsPackageStatus.APPROVED
      );
      const approvedAsNoted = sanitizedData.filter(
        item => item.status === SubmittalsPackageStatus.APPROVED_AS_NOTED
      );

      setDraftPackages(draft);
      setSentPackages(sent);
      setRespondedPackages(returned);
      setClosedPackages([...approved, ...approvedAsNoted]);
    });
  };

  useEffect(() => {
    getAllSubmittals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [confirmDelete, setConfirmDelete] = useState({
    confirmAction: () => {},
    confirmDialog: false,
    confirmTitle: ''
  });

  const handleCancelConfirmation = () =>
    setConfirmDelete({ confirmAction: () => {}, confirmDialog: false, confirmTitle: '' });

  const deleteAction = async (record, stopLoading) => {
    const payload = {
      deletedDate: moment().unix()
    };
    // for soft delete
    await submittalPackageChange(record.id, payload);
    getAllSubmittals();
    setConfirmDelete({ confirmAction: () => {}, confirmDialog: false, confirmTitle: '' });
    stopLoading();
  };

  const handleRowactions = async (actionType, record) => {
    const selectedItem = getTableData(currentTable)?.filter(
      item => item.number === record.number
    )[0];

    if (actionType === 'remove') {
      setConfirmDelete({
        confirmAction: (data, stopLoading) => deleteAction(record, stopLoading),
        confirmDialog: true,
        confirmTitle: `Delete Submittal Package ${record.number}`
      });
    } else if (actionType === 'edit') {
      setIsEditModal(true);
      setIsOpendModal(true);
      setSelectedEditItem(selectedItem);
    }
  };

  return (
    <ErrorBoundaries>
      <Box className={classes.root}>
        <Box className={classes.toolbarContainer}>
          {/* TODO: search function */}
          {/* <CustomSearchBar key="submittalsPackagesSearch" placeholder="Search Packages" /> */}
          <div />
          <DefaultButton
            buttonProps={{ startIcon: <AddCircleOutlineIcon style={{ fontSize: 14 }} /> }}
            key="createSubmittalPackageBtn"
            label={Labels.createSubmittalPackage[user.locale]}
            style={{ height: 30, fontSize: 12 }}
            variant="containedPrimary"
            onClick={() => setIsOpendModal(true)}
          />
        </Box>
        <SergeantModal
          alignCloseRight
          customComponents={{}}
          customPrimaryButtonLabel="Delete"
          dataType="Item"
          handleClose={handleCancelConfirmation}
          handlePrimaryAction={confirmDelete.confirmAction}
          mode="delete"
          open={confirmDelete.confirmDialog}
          title={confirmDelete.confirmTitle}
        />
        <Box className={classes.highlightCardContainer}>
          <Grid container spacing={2}>
            <Grid container direction="row" xs={12}>
              {SubmittalsPackageStatusBtnTitles.map((statusName, index) => (
                <CustomHighlightCard
                  handleClick={() => setCurrentTable(index)}
                  highlight={getTableData(index)?.length || 0}
                  key={`submittalPackagesHighlightCard-${statusName}`}
                  selected={currentTable === index}
                  title={statusName}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <ResponsiveTable
                data={getTableData(currentTable)}
                defaults={{
                  sortBy: 'number',
                  sortOrder: 'desc'
                }}
                disableFilter
                noDataMsg="No Submittal Packages"
                rowActionButtons={currentTable === 3 ? {} : simpleRowActionButtons}
                rowActions={handleRowactions}
                rowMetadata={submittalPackageRowMeta}
                tableName="ProjectManagementSubmittalPackages"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <PackageDetailView
        handleClose={() => {
          setIsDetailViewOpen(false);
          history.goBack();
        }}
        open={isDetailViewOpen}
        packageId={packageId}
        projectData={projectData}
        projectId={projectId}
        user={user}
      />
      <CreateSubmittalPackage
        editMode={isEditModal}
        handleClose={() => {
          getAllSubmittals();
          setIsEditModal(false);
          setIsOpendModal(false);
        }}
        initialData={{
          type: isEditModal ? 'edit' : 'default',
          itemData: isEditModal ? selectedEditItem : {}
        }}
        open={isOpendModal}
        projectData={projectData}
        projectId={projectId}
        user={user}
      />
    </ErrorBoundaries>
  );
};

PackageLists.propTypes = {
  user: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  projectData: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = { setOpenQuickAddModal };
const ReduxConnectedPackages = connect(mapStateToProps, mapDispatchToProps)(PackageLists);
export default ReduxConnectedPackages;
