import { isEmpty } from 'lodash';

import { COMPANY_SETTING_TYPE } from 'constants/common';
import useCompanySettings from 'customHooks/useCompanySettings';
import { useSnackbar } from 'customHooks/useSnackbar';
import useTenantCompanyId from 'customHooks/useTenantCompanyId';
import useTenantId from 'customHooks/useTenantId';

const useQuotedAmountSetting = () => {
  const tenantId = useTenantId();
  const tenantCompanyId = useTenantCompanyId();
  const snackbar = useSnackbar();
  const [data] = useCompanySettings(
    COMPANY_SETTING_TYPE.QUOTE,
    tenantId,
    tenantCompanyId,
    snackbar
  );
  try {
    if (!isEmpty(data)) {
      return JSON.parse(data.settings).defaultQuotedWorkLineItems || [];
    }
  } catch {
    snackbar('warning', 'Cannot read quotes settings');
  }
  return {};
};

export default useQuotedAmountSetting;
