import React, { useMemo } from 'react';

import { Typography } from '@material-ui/core';

import { startCase } from 'lodash';

import { BoldCell, tableCurrencyFormatter, tablePercentageFormatter } from 'components/WrapTable';
import toFixedNumber from 'utils/toFixedNumber';

import { ProjectFinanceTableView } from './ProjectFinanceTable.constants';
import * as Getters from './ProjectFinanceTable.getters';

const renderFinanceRecordDetailsCell = props => {
  if (props.isGroupRow) {
    return <Typography testingid={props.cellAddress}>{props.formattedValue}</Typography>;
  }

  return <Typography testingid={props.cellAddress}>{props.row.title}</Typography>;
};

export const useProjectFinanceTableColumns = ({ debug, view }) => {
  return useMemo(() => {
    const ENTITY = {
      headerName: 'Entity',
      field: 'entityType',
      valueGetter: Getters.getEntityId,
      showTooltip: true,
      hide: !debug,
      renderTotal: BoldCell
    };

    const PHASE = {
      headerName: 'Phase',
      field: 'projectPhaseId',
      valueGetter: ({ row }) => row.projectPhaseName,
      renderCell: renderFinanceRecordDetailsCell,
      renderTotal: BoldCell
    };

    const COST_CODE = {
      headerName: 'Cost Code',
      field: 'projectCostCodeId',
      valueGetter: ({ row }) => row.projectCostCodeName,
      renderCell: renderFinanceRecordDetailsCell,
      renderTotal: BoldCell,
      hide: view === ProjectFinanceTableView.REVENUE_BUDGET
    };

    const COST_TYPE = {
      headerName: 'Cost Type',
      field: 'costType',
      valueFormatter: ({ value }) => startCase(value),
      renderCell: renderFinanceRecordDetailsCell,
      renderTotal: BoldCell
    };

    const DEPARTMENT = {
      headerName: 'Department',
      field: 'departmentId',
      valueGetter: ({ row }) => row.departmentName,
      hide: !debug,
      renderTotal: BoldCell
    };

    const STARTING_BUDGET = {
      headerName: 'Starting Budget',
      field: 'startingBudget',
      valueGetter: Getters.getStartingBudget,
      valueFormatter: tableCurrencyFormatter,
      totalGetter: Getters.getStartingBudgetTotal,
      totalFormatter: tableCurrencyFormatter,
      renderTotal: BoldCell,
      hide: view !== ProjectFinanceTableView.COST_BUDGET
    };

    const COMMITTED_AMOUNT = {
      headerName: 'Committed Costs',
      field: 'committedAmount',
      valueGetter: Getters.getCommittedAmount,
      valueFormatter: tableCurrencyFormatter,
      totalGetter: Getters.getCommittedAmountTotal,
      totalFormatter: tableCurrencyFormatter,
      renderTotal: BoldCell,
      hide: view !== ProjectFinanceTableView.COST_BUDGET
    };

    const BUDGET_CHANGE_ORDERS = {
      headerName: 'Change Orders',
      field: 'budgetChangeOrders',
      valueGetter: Getters.getBudgetChangeOrders,
      valueFormatter: tableCurrencyFormatter,
      totalGetter: Getters.getBudgetChangeOrdersTotal,
      totalFormatter: tableCurrencyFormatter,
      renderTotal: BoldCell,
      hide: view !== ProjectFinanceTableView.COST_BUDGET
    };

    const UPDATED_BUDGET = {
      headerName: 'Updated Budget',
      field: 'updatedBudget',
      valueGetter: Getters.getUpdatedBudget,
      valueFormatter: tableCurrencyFormatter,
      totalGetter: Getters.getUpdatedBudgetTotal,
      totalFormatter: tableCurrencyFormatter,
      renderTotal: BoldCell,
      hide: view !== ProjectFinanceTableView.COST_BUDGET
    };

    const ACTUAL_COSTS = {
      headerName: 'Actual Costs',
      field: 'actualCosts',
      valueGetter: Getters.getActualCosts,
      valueFormatter: tableCurrencyFormatter,
      totalGetter: Getters.getActualCostsTotal,
      totalFormatter: tableCurrencyFormatter,
      renderTotal: BoldCell,
      hide: view !== ProjectFinanceTableView.COST_BUDGET
    };

    const PERCENT_COMPLETE = {
      headerName: 'Percent Used ($)',
      field: 'percentComplete',
      totalGetter: Getters.getPercentCompleteTotal,
      valueFormatter: tablePercentageFormatter,
      totalFormatter: tablePercentageFormatter,
      renderTotal: BoldCell,
      hide: view !== ProjectFinanceTableView.COST_BUDGET
    };

    const VARIANCE = {
      headerName: 'Updated vs Starting Budget',
      field: 'costVariance',
      totalGetter: Getters.getCostVarianceTotal,
      valueFormatter: tablePercentageFormatter,
      totalFormatter: tablePercentageFormatter,
      renderTotal: BoldCell,
      hide: view !== ProjectFinanceTableView.COST_BUDGET
    };

    const ORIGINAL_CONTRACT_AMOUNT = {
      headerName: 'Base Contract',
      field: 'originalContractAmount',
      valueGetter: Getters.getOriginalContractAmount,
      valueFormatter: tableCurrencyFormatter,
      totalGetter: Getters.getOriginalContractTotal,
      totalFormatter: tableCurrencyFormatter,
      renderTotal: BoldCell,
      hide: view !== ProjectFinanceTableView.REVENUE_BUDGET
    };

    const CHANGE_ORDERS = {
      headerName: 'Change Orders',
      field: 'changeOrders',
      valueGetter: Getters.getChangeOrderAmount,
      valueFormatter: tableCurrencyFormatter,
      totalGetter: Getters.getChangeOrderTotal,
      totalFormatter: tableCurrencyFormatter,
      renderTotal: BoldCell,
      hide: view !== ProjectFinanceTableView.REVENUE_BUDGET
    };

    const UPDATED_CONTRACT_AMOUNT = {
      headerName: 'Updated Contract',
      field: 'updatedContractAmount',
      valueGetter: Getters.getUpdatedContractAmount,
      valueFormatter: tableCurrencyFormatter,
      totalGetter: Getters.getUpdatedContractTotal,
      totalFormatter: tableCurrencyFormatter,
      renderTotal: BoldCell,
      hide: view !== ProjectFinanceTableView.REVENUE_BUDGET
    };

    const REVENUE_COMPLETED = {
      headerName: 'Revenue Completed',
      field: 'revenueCompleted',
      valueGetter: Getters.getRevenueCompleted,
      valueFormatter: tableCurrencyFormatter,
      totalGetter: Getters.getRevenueCompletedTotal,
      totalFormatter: tableCurrencyFormatter,
      renderTotal: BoldCell,
      hide: view !== ProjectFinanceTableView.REVENUE_BUDGET
    };

    const ORIGINAL_ESTIMATED_MARGIN = {
      headerName: 'Original Estimated Margin',
      field: 'originalEstimatedMargin',
      totalGetter: Getters.getOriginalEstimatedMarginTotal,
      valueFormatter: tablePercentageFormatter,
      totalFormatter: tablePercentageFormatter,
      renderTotal: BoldCell,
      hide: view !== ProjectFinanceTableView.REVENUE_BUDGET
    };

    const UPDATED_ESTIMATED_MARGIN = {
      headerName: 'Updated Estimated Margin',
      field: 'updatedEstimatedMargin',
      totalGetter: Getters.getUpdatedEstimatedMarginTotal,
      valueFormatter: tablePercentageFormatter,
      totalFormatter: tablePercentageFormatter,
      renderTotal: BoldCell,
      hide: view !== ProjectFinanceTableView.REVENUE_BUDGET
    };

    const ESTIMATED_HOURS = {
      headerName: 'Estimated Hours',
      field: 'estimatedHours',
      valueGetter: Getters.getEstimatedHours,
      valueFormatter: ({ value }) => toFixedNumber(value),
      totalGetter: Getters.getEstimatedHoursTotal,
      totalFormatter: ({ value }) => toFixedNumber(value),
      renderTotal: BoldCell,
      hide: view !== ProjectFinanceTableView.LABOR_REPORT
    };

    const APPROVED_CHANGE_ORDERS = {
      headerName: 'Approved Change Orders',
      field: 'changeOrderHours',
      valueGetter: Getters.getChangeOrderHours,
      valueFormatter: ({ value }) => toFixedNumber(value),
      totalGetter: Getters.getChangeOrderHoursTotal,
      totalFormatter: ({ value }) => toFixedNumber(value),
      renderTotal: BoldCell,
      hide: view !== ProjectFinanceTableView.LABOR_REPORT
    };

    const TOTAL_HOURS = {
      headerName: 'Total Hours',
      field: 'totalHours',
      valueGetter: Getters.getTotalHours,
      valueFormatter: ({ value }) => toFixedNumber(value),
      totalGetter: Getters.getTotalHoursTotal,
      totalFormatter: ({ value }) => toFixedNumber(value),
      renderTotal: BoldCell,
      hide: view !== ProjectFinanceTableView.LABOR_REPORT
    };

    const HOURS_USED = {
      headerName: 'Hours Used',
      field: 'hoursUsed',
      valueGetter: Getters.getHoursUsed,
      valueFormatter: ({ value }) => toFixedNumber(value),
      totalGetter: Getters.getHoursUsedTotal,
      totalFormatter: ({ value }) => toFixedNumber(value),
      renderTotal: BoldCell,
      hide: view !== ProjectFinanceTableView.LABOR_REPORT
    };

    const PERCENT_USED = {
      headerName: 'Percent Used (hrs)',
      field: 'hoursPercentComplete',
      totalGetter: Getters.getHoursPercentCompleteTotal,
      valueFormatter: tablePercentageFormatter,
      totalFormatter: tablePercentageFormatter,
      renderTotal: BoldCell,
      hide: view !== ProjectFinanceTableView.LABOR_REPORT
    };

    const HOUR_VARIANCE = {
      headerName: 'Variance',
      field: 'hourVariance',
      totalGetter: Getters.getHourVarianceTotal,
      valueFormatter: tablePercentageFormatter,
      totalFormatter: tablePercentageFormatter,
      renderTotal: BoldCell,
      hide: view !== ProjectFinanceTableView.LABOR_REPORT
    };

    return [
      ENTITY,
      PHASE,
      COST_CODE,
      DEPARTMENT,
      COST_TYPE,
      STARTING_BUDGET,
      BUDGET_CHANGE_ORDERS,
      UPDATED_BUDGET,
      COMMITTED_AMOUNT,
      ACTUAL_COSTS,
      PERCENT_COMPLETE,
      VARIANCE,
      ORIGINAL_CONTRACT_AMOUNT,
      CHANGE_ORDERS,
      UPDATED_CONTRACT_AMOUNT,
      REVENUE_COMPLETED,
      ORIGINAL_ESTIMATED_MARGIN,
      UPDATED_ESTIMATED_MARGIN,
      ESTIMATED_HOURS,
      APPROVED_CHANGE_ORDERS,
      TOTAL_HOURS,
      HOURS_USED,
      PERCENT_USED,
      {
        ...PERCENT_COMPLETE,
        hide: view !== ProjectFinanceTableView.LABOR_REPORT
      },
      HOUR_VARIANCE
    ];
  }, [debug, view]);
};
