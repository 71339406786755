/* eslint-disable camelcase */
import React from 'react';

import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import { CorruptData, ResponsiveTable } from 'components';
import invoiceLabels from 'meta/Jobs/Invoice/labels';
import { manualTechnicianTimesheetNotes } from 'meta/Jobs/Invoice/review-report-tables';
import { AttachmentImages } from 'scenes/Payroll/TimeTrackingReport/TimesheetNotes';
import { APRIL_30_2021, TimeCardStatusTypes } from 'utils/AppConstants';

const statusMappings = {
  PENDING: 'Not Submitted',
  SUBMITTED: 'Submitted for Review',
  DISPUTED: 'Pending Revision',
  APPROVED: 'Approved'
};

const convertTimesheetEntriesToTimeCardRows = (timesheetEntries, nonVisitEventName = null) =>
  timesheetEntries
    .filter(entry => !entry.timesheetEntryBinder?.isDismissed)
    .reduce((rows, entry) => {
      const employeeId = entry.timesheetPeriod.parentId;

      const existingRowIndex = rows.findIndex(r => r.employeeId === employeeId);

      const duration = Number.isInteger(entry.actualTotalDurationOverride)
        ? entry.actualTotalDurationOverride
        : entry.actualTotalDuration;

      const durationHours = duration / 3600;

      if (existingRowIndex !== -1) {
        const newRows = [...rows];
        newRows[existingRowIndex] = {
          ...rows[existingRowIndex],
          [entry.hourType.hourType]: durationHours,
          [`${entry.hourType.id}_cost`]: entry.cost * durationHours,
          totalTime: newRows[existingRowIndex].totalTime + durationHours,
          totalCost: newRows[existingRowIndex].totalCost + entry.cost * durationHours
        };

        return newRows;
      }

      return [
        ...rows,
        {
          employeeId,
          employeeName: entry.createdBy,
          [entry.hourType.hourType]: durationHours,
          [`${entry.hourType.id}_cost`]: entry.cost * durationHours,
          totalTime: durationHours,
          totalCost: entry.cost * durationHours,
          status: statusMappings[entry.manualStatus || TimeCardStatusTypes.PENDING],
          approvedBy: entry.manualApprovedBy,
          approvedDateTime: entry.manualApprovedDate,
          lastUpdatedBy: entry.lastUpdatedBy,
          lastUpdatedDateTime: entry.lastUpdatedDateTime,
          nonVisitEventName
        }
      ];
    }, []);

const Timesheet = ({ classes, current, user }) => {
  const { context } = current;

  if (context.isAutomatedTimeTrackingEnabled) return null;

  const { payrollHourTypes, visitTimesheetNotes, nonVisitEvents } = context;

  const nonVisitTimesheetNotes =
    nonVisitEvents?.reduce((acc, nve) => {
      const notes =
        nve.timesheetNotes?.items.map(item => ({ ...item, eventName: 'Billable Visit' })) || [];
      return [...acc, ...notes];
    }, []) || [];

  const timesheetNotes = [...visitTimesheetNotes, ...nonVisitTimesheetNotes];

  let timeCardColumns = [
    {
      id: 'employeeName',
      disablePadding: true,
      label: 'Name'
    }
  ];

  timeCardColumns = payrollHourTypes
    .sort((a, b) => {
      const aSort = Number.isInteger(a.sortOrder) ? a.sortOrder : 99;
      const bSort = Number.isInteger(b.sortOrder) ? b.sortOrder : 99;

      return aSort - bSort;
    })
    .reduce((rows, type) => {
      return [
        ...rows,
        {
          id: type.hourType,
          label: type.hourTypeAbbreviation,
          numeric: true,
          disablePadding: true
        },
        {
          id: `${type.id}_cost`,
          label: 'Cost',
          type: 'currency',
          numeric: true,
          disablePadding: true
        }
      ];
    }, timeCardColumns);

  timeCardColumns.push.apply(timeCardColumns, [
    {
      id: 'totalTime',
      numeric: 'true',
      disablePadding: true,
      label: 'Total'
    },
    {
      id: 'totalCost',
      numeric: 'true',
      type: 'currency',
      disablePadding: true,
      label: 'Cost'
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      label: 'Status'
    },
    {
      id: 'approvedBy',
      numeric: false,
      disablePadding: true,
      label: 'Approved by'
    },
    {
      id: 'approvedDateTime',
      numeric: false,
      type: 'datetime',
      disablePadding: true,
      label: 'Date Approved'
    },
    {
      id: 'lastUpdatedBy',
      numeric: false,
      disablePadding: true,
      label: 'Last updated by'
    },
    {
      id: 'lastUpdatedDateTime',
      numeric: false,
      type: 'datetime',
      disablePadding: true,
      label: 'Date last updated'
    },
    {
      id: 'nonVisitEventName',
      numeric: false,
      disablePadding: true,
      label: 'Billable Non-Visit'
    }
  ]);

  const entriesThatMayBeCorrupt = context.timesheetEntries.reduce(
    (acc, entry) => {
      if (!Number.isInteger(entry.actualStartTimeUTC) || entry.actualStartTimeUTC > APRIL_30_2021) {
        return {
          ...acc,
          new: [...acc.new, entry]
        };
      }

      return {
        ...acc,
        old: [...acc.old, entry]
      };
    },
    {
      old: [],
      new: []
    }
  );

  const entryMissingRequiredProp = entriesThatMayBeCorrupt.new.find(
    entry => !entry.id || !entry.hourTypeId || !entry.timesheetPeriod?.parentId
  );

  const multipleEntriesForSameHourType = entriesThatMayBeCorrupt.new.find(entry =>
    context.timesheetEntries.find(
      e =>
        e.id !== entry.id &&
        e.hourTypeId === entry.hourTypeId &&
        e.timesheetPeriod.parentId === entry.timesheetPeriod.parentId
    )
  );

  const corruptData = entryMissingRequiredProp || multipleEntriesForSameHourType;

  if (corruptData) {
    return (
      <CorruptData
        corruptData={corruptData}
        reason={
          entryMissingRequiredProp ? 'entryMissingRequiredProp' : 'multipleEntriesForSameHourType'
        }
      />
    );
  }

  const oldEntryMissingRequiredProp = entriesThatMayBeCorrupt.old.find(
    entry => !entry.id || !entry.hourTypeId || !entry.timesheetPeriod?.parentId
  );

  const oldMultipleEntriesForSameHourType = entriesThatMayBeCorrupt.old.find(entry =>
    context.timesheetEntries.find(
      e =>
        e.id !== entry.id &&
        e.hourTypeId === entry.hourTypeId &&
        e.timesheetPeriod.parentId === entry.timesheetPeriod.parentId
    )
  );

  const oldCorruptData = oldEntryMissingRequiredProp || oldMultipleEntriesForSameHourType;

  if (oldCorruptData) {
    return null;
  }

  const timeCardRowsFromVisit = convertTimesheetEntriesToTimeCardRows(context.timesheetEntries);

  const timeCardRowsFromNonVisitEvents = nonVisitEvents.reduce((acc, nve) => {
    const rowsFromNVE = convertTimesheetEntriesToTimeCardRows(
      nve.timesheetEntries?.items || [],
      nve.name
    );

    return [...acc, ...rowsFromNVE];
  }, []);

  const timeCardRows = [...timeCardRowsFromVisit, ...timeCardRowsFromNonVisitEvents];

  if (context.isAutomatedTimeTrackingEnabled) {
    return null;
  }

  return (
    <>
      <Typography className={classes.greySectionTitle}>
        {invoiceLabels.timeCards[user.locale]}
      </Typography>

      <ResponsiveTable
        data={timeCardRows}
        disableFilter
        disablePagination
        noDataMsg="No items"
        noMaxHeight
        rowMetadata={timeCardColumns}
      />
      <Typography className={classes.greySectionTitle}>
        {invoiceLabels.notesByTechnician[user.locale]}
      </Typography>
      <ResponsiveTable
        customCellComponents={{
          AttachmentImages: ({ record }) => <AttachmentImages attachments={record.attachments} />
        }}
        data={timesheetNotes}
        disableFilter
        disablePagination
        noMaxHeight
        rowMetadata={manualTechnicianTimesheetNotes}
      />
    </>
  );
};

Timesheet.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.shape({
    locale: PropTypes.string
  }).isRequired,
  current: PropTypes.shape({
    context: PropTypes.shape({
      isAutomatedTimeTrackingEnabled: PropTypes.bool,
      reduxActions: PropTypes.shape({ snackbarOn: PropTypes.func.isRequired }),
      companyAddresses: PropTypes.shape({
        items: PropTypes.array
      }),
      payrollHourTypes: PropTypes.array,
      timeCardLines: PropTypes.array,
      timeCardVisits: PropTypes.array,
      timeSheets: PropTypes.array
    }).isRequired
  }).isRequired
};

export default Timesheet;
