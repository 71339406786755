import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';

import Divider from 'components/Divider';
import PageHeader from 'components/PageHeader';
// import Breadcrumb from "components/Breadcrumb";

class Settings extends Component {
  render() {
    return (
      <>
        <PageHeader title="Settings" />
        <Divider />
      </>
    );
  }
}
const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps, null)(Settings);
