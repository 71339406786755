import React, { useEffect, useState } from 'react';

import { Button, ButtonType, MoreButton, SgtForm, ThemeProvider } from '@BuildHero/sergeant';
import { makeStyles } from '@material-ui/core';
import { NotInterested } from '@material-ui/icons';
import Skeleton from 'react-loading-skeleton';

import { connect } from 'react-redux';

import {
  PageHeader,
  StatusChip,
  SyncStatus as SyncStatusDisplay,
  UserPermission
} from 'components';
import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundary from 'scenes/Error';

import { logErrorWithCallback } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';
import { EnumType, ExportStatus, SyncStatus } from 'utils/constants';

import { exportConfirmContent, GetViewConfiguration } from './Adjustment.config';
import {
  useAdjustmentQuery,
  useAdjustmentSubscription,
  useUpsertAdjustment
} from './Adjustment.gql';
import AdjustmentModal from './Adjustment.modal';
import { TransactionList } from './components';

const useStyles = makeStyles(({ spacing }) => ({
  moreButton: {
    fontSize: spacing(3),
    padding: 0
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: 'auto',
    gap: 8
  }
}));

function Adjustment({ user, snackbar, computedMatch }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { data, loading, error } = useAdjustmentQuery(computedMatch.params.id);
  const [upsert, { loading: upsertLoading, error: upsertError }] = useUpsertAdjustment();
  useAdjustmentSubscription(user.tenantId, computedMatch.params.id);
  const readOnly = [ExportStatus.CLOSED, ExportStatus.VOIDED].includes(data?.exportStatus);
  const confirmContext = useConfirmModal();

  useEffect(() => {
    if (upsertError)
      logErrorWithCallback(
        upsertError,
        snackbar,
        `Error updating adjustment: ${upsertError?.message}. Please try again.`
      );
  }, [upsertError, snackbar]);

  if (error) return <>Error loading adjustment data. Please try again.</>;
  return (
    <UserPermission action={PermissionConstants.OBJECT_INVOICE} I="read">
      <ErrorBoundary>
        <PageHeader
          additionalTitleComponents={
            loading ? (
              <Skeleton height={32} width={120} />
            ) : (
              <>
                <StatusChip
                  enumType={EnumType.ADJUSTMENT_STATUS}
                  enumValue={data?.status}
                  label={data?.status}
                />
                {!!data?.exportStatus && (
                  <StatusChip
                    enumType={EnumType.EXPORT_STATUS}
                    enumValue={data?.exportStatus}
                    label={data?.exportStatus}
                  />
                )}
              </>
            )
          }
          breadcrumbsArray={[
            { title: 'Accounting', link: '' },
            { title: 'Adjustments', link: '/adjustment/list' }
          ]}
          pageMapKey="adjustments"
          title={`Adjustment ${data?.number ?? ''}`}
          userLocale={user.locale}
        >
          <div className={classes.buttonContainer}>
            {loading ? (
              <Skeleton count={2} height={40} style={{ lineHeight: 'unset' }} width={150} />
            ) : (
              !readOnly && (
                <ThemeProvider>
                  <Button
                    disabled={upsertLoading}
                    testingid="edit-adjustment"
                    type={ButtonType.TERTIARY}
                    onClick={() => setOpen(true)}
                  >
                    Edit Adjustment
                  </Button>
                  <Button
                    loading={upsertLoading}
                    testingid="export-adjustment"
                    onClick={async () =>
                      (await confirmContext.confirm(exportConfirmContent)) &&
                      upsert({
                        ...data,
                        syncStatus: SyncStatus.SYNCING,
                        exportStatus: ExportStatus.POSTED
                      })
                    }
                  >
                    Export Adjustment
                  </Button>
                  <MoreButton
                    className={classes.moreButton}
                    disabled={upsertLoading}
                    options={[
                      {
                        label: 'Void Adjustment',
                        icon: NotInterested,
                        onClick: () =>
                          upsert({
                            ...data,
                            // mimic deleting all applications
                            applicationMapRef: {
                              current: data.transactions.reduce(
                                (obj, t) => ({
                                  ...obj,
                                  [t.id]: {
                                    id: t.id,
                                    appliedAmount: 0
                                  }
                                }),
                                {}
                              )
                            },
                            syncStatus: null,
                            exportStatus: ExportStatus.VOIDED
                          })
                      }
                    ]}
                    testingid="kebabMenu-adjustment"
                  />
                </ThemeProvider>
              )
            )}
          </div>
        </PageHeader>
        {loading ? (
          <Skeleton height={500} />
        ) : (
          <>
            <SyncStatusDisplay
              locale={user.locale}
              syncLog={data?.syncLog}
              syncStatus={data?.syncStatus ?? undefined}
            />
            <br />
            <SgtForm
              configuration={GetViewConfiguration(data?.accountingApp)}
              customComponents={{ TransactionList }}
              formikProps={{ enableReinitialize: true }}
              initialValues={data}
            />
            <AdjustmentModal data={data} open={open} onClose={() => setOpen(false)} />
          </>
        )}
      </ErrorBoundary>
    </UserPermission>
  );
}

export default connect(state => state, { snackbar: snackbarOn })(Adjustment);
