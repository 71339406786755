import React, { Component } from 'react';

import { connect } from 'react-redux';

import { PageHeader, UserPermission } from 'components';
import ResponsiveTable from 'components/ResponsiveTable';
import { techReportRowsMeta } from 'meta/ListViews';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { ListService } from 'services/core';
import { Logger } from 'services/Logger';
import { PermissionConstants } from 'utils/AppConstants';

class TechReportView extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.ListService = new ListService();
  }

  getAllTechReports = async (filter, limit, offset, sortBy, sortOrder) => {
    if (!this.props.user.tenantId) {
      return { items: [], nextToken: '0' };
    }
    let data;
    const sortKey = `${this.props.user.tenantId}_Company_${this.props.user.tenantCompanyId}`;
    try {
      data = await this.ListService.getAllTechReports(
        this.props.user.tenantId,
        sortKey,
        filter,
        limit,
        offset,
        sortBy,
        sortOrder
      );

      return data;
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn(
        'error',
        'Unable to fetch Technician Reports, please try again later',
        error
      );
    }
    return data || [];
  };

  render() {
    const { user } = this.props;
    document.title = 'BuildOps - Tech Report List';

    return (
      <ErrorBoundaries>
        <UserPermission action={PermissionConstants.OBJECT_VISIT} I="read">
          <PageHeader pageMapKey="technicianReport" userLocale={user.locale} />
          <ResponsiveTable
            caslKey={PermissionConstants.OBJECT_VISIT}
            fullScreen
            noDataMsg="No Technician Reports"
            rowMetadata={techReportRowsMeta()}
            service={this.getAllTechReports}
            tableName="Technician Reports"
          />
        </UserPermission>
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const reduxConnectedTech = connect(mapStateToProps, mapDispatcherToProps)(TechReportView);

export default reduxConnectedTech;
