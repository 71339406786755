import React from 'react';

import { Button } from '@material-ui/core';

import { DefaultButton } from 'components';

import { useStyles } from './ModalHeaderButtons.styles';

export const StepButton = ({ onClick, stepIndex, marginRight, ...otherProps }) => {
  const handleClick = () => onClick(stepIndex);
  const styles = useStyles({ marginRight });
  return (
    <DefaultButton
      buttonProps={{ css: styles.buttonWithRightPadding }}
      {...otherProps}
      onClick={handleClick}
    />
  );
};
