import React, { useCallback, useMemo, useRef, useState } from 'react';

import { Button, Modal, SgtForm, ThemeProvider } from '@BuildHero/sergeant';
import { object, string } from 'yup';

import { SgtJobSearch } from 'components/SgtAlgoliaSearch/SgtFormComponents';

const rowStyle = { gap: 16, flexDirection: 'row', marginBottom: 16 };

const configuration = {
  layouts: {
    default: {
      props: {},
      contents: [
        {
          props: { style: rowStyle },
          contents: [
            {
              component: 'Field',
              source: 'subscriberName',
              props: {
                slowField: true,
                style: { width: 190 },
                label: 'Subscriber'
              }
            },
            {
              component: 'Field',
              source: 'locationName',
              props: {
                slowField: true,
                style: { width: 190 },
                label: 'Location'
              }
            },
            {
              component: 'Field',
              source: 'locationStoreId',
              props: {
                slowField: true,
                style: { width: 190 },
                label: 'Location ID'
              }
            }
          ]
        },
        {
          component: 'SgtJobSearch',
          source: 'job.customIdentifier',
          resolveProps: ({ loading }) => ({ disabled: loading }),
          props: {
            label: 'Job Number',
            required: true
          }
        }
      ]
    }
  },
  validation: object({
    job: object({
      customIdentifier: string()
        .nullable()
        .required('Please select a job.'),
      jobNumber: string().nullable()
    })
  })
};

const useAddToJobModal = () => {
  const [open, setOpen] = useState();
  const [data, setData] = useState();
  const [formService, setFormService] = useState();
  const resolver = useRef();

  const handleClose = useCallback(() => {
    if (resolver.current) resolver.current();
    setOpen(false);
  }, []);

  const handleAdd = useCallback(({ job }) => {
    if (resolver.current) resolver.current(job.id);
    setOpen(false);
  }, []);

  const AddToJobModal = useMemo(
    () => (
      <ThemeProvider>
        <Modal
          actions={
            <Button fullWidth name="submit" onClick={formService?.formikContext?.handleSubmit}>
              Add To Job
            </Button>
          }
          open={open}
          title="Add to Existing Job"
          onClose={handleClose}
        >
          <SgtForm
            configuration={configuration}
            customComponents={{ SgtJobSearch }}
            formikProps={{ validateOnChange: false, validateOnBlur: true }}
            initialValues={{ ...data, job: { customIdentifier: null, id: null } }}
            onCreateService={setFormService}
            onSubmit={handleAdd}
          />
        </Modal>
      </ThemeProvider>
    ),
    [open, handleAdd, handleClose, formService]
  );

  const triggerAddToJob = useCallback(({ subscriberName, locationName, locationStoreId }) => {
    setOpen(true);
    setData({ subscriberName, locationName, locationStoreId });
    return new Promise(resolve => {
      resolver.current = resolve;
    });
  }, []);

  return { AddToJobModal, triggerAddToJob };
};

export default useAddToJobModal;
