/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';

import { PDFDocument } from '@BuildHero/sergeant';
import { makeStyles } from '@material-ui/core/styles';
import { pdf, PDFViewer } from '@react-pdf/renderer';
import moment from 'moment';
import PropTypes from 'prop-types';

import DefaultButton from 'components/Buttons/DefaultButton';
import FullScreenModal from 'components/FullScreenModal';

import PDFDocumentLayout from 'meta/Procurement/PurchaseOrders/poReceiptPdf';
import SendEmailPopUp from 'scenes/Procurement/component/SendEmailPopUp';
import { getCloudinaryImageUrl } from 'scenes/ProjectManagement/components/utils';
import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';
import { formatAddress, parseFloatAndRound, roundCurrency } from 'utils';
import { AppConstants, ProcurementPurchaseOrderReceiptStatus } from 'utils/AppConstants';
// eslint-disable-next-line import/no-cycle

import { getFormattedDate, getJobProjectOrMaintenanceName, getReceiptDueDate } from '../../utils';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

const GeneratePdfModal = props => {
  const {
    open,
    user,
    pdfData,
    receiptItems,
    companyInfo,
    taxRate,
    handleClose,
    handlePostReceipt,
    receiptStatus
  } = props;
  const classes = useStyles();
  const [isOpendEmailModal, setIsOpendEmailModal] = useState(false);
  const [uploadedPdfUrl, setUploadedPdfUrl] = useState('');
  const [uploadedPdfFileName, setUploadedPdfFileName] = useState('');
  const [orderEmailRecipient, setOrderEmailRecipient] = useState('');
  const [emailSubject, setEmailSubject] = useState('Purchase Order Receipt');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [total, setTotal] = useState();
  const [subtotal, setSubtotal] = useState();
  const [taxAmount, setTaxAmount] = useState();

  const getPdfCosts = () => {
    let lineItemsTotal = 0;
    let newTaxAmount = 0;
    const taxableItems = receiptItems?.filter(line => line.taxable);
    if (receiptItems?.length > 0) {
      lineItemsTotal = receiptItems
        .map(line => roundCurrency(line.quantity * line.unitCost))
        .reduce((prev, next) => prev + next);

      if (taxableItems?.length) {
        newTaxAmount = taxableItems
          .map(line => roundCurrency(line.quantity * line.unitCost))
          .reduce((prev, next) => prev + next);
        newTaxAmount = roundCurrency((newTaxAmount * parseFloat(taxRate)) / 100);
      }
    }
    const newTotal = lineItemsTotal + newTaxAmount + parseFloatAndRound(pdfData?.freight, 2);
    setTotal(newTotal);
    setSubtotal(lineItemsTotal);
    setTaxAmount(newTaxAmount);
  };

  useEffect(() => {
    if (open) getPdfCosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const sanitizeItems = items => {
    if (!items) return [];

    return items.map(item => {
      return {
        itemName: item.Product?.name || '',
        description: item.description || '',
        costCode: item.Product?.code || '',
        quantity: item.quantity || 0,
        unitCost: item.unitCost || 0,
        totalCost: roundCurrency(Number(item.unitCost ?? 0) * Number(item.quantity ?? 0)) || 0
      };
    });
  };
  const getFormattedData = () => {
    return {
      senderLogoUrl: getCloudinaryImageUrl(companyInfo?.logoUrl || null),
      senderAddress: formatAddress(companyInfo?.companyAddresses || null, true) || null,
      senderPhoneNumber: companyInfo?.phonePrimary || null,
      receiptNumber: pdfData?.receiptNumber || null,
      date: moment().format(AppConstants.DATE_FORMAT),
      issuedBy: getFormattedDate(pdfData?.issuedBy),
      dueDate: getFormattedDate(getReceiptDueDate(pdfData)),
      jobProjectOrMaintenanceName: getJobProjectOrMaintenanceName(pdfData),
      vendorName: pdfData?.sidebarVendor?.vendorName || '',
      vendorAddress: pdfData?.sidebarVendor?.vendorAddress || null,
      vendorPhoneNumber: pdfData?.sidebarVendor?.phoneNumber || null,
      termsAndConditions: pdfData?.PurchaseOrder?.termsAndConditions || null,
      shipToName: pdfData?.sidebarShipping?.shipToName || null,
      shipToInstructions: pdfData?.sidebarShipping?.shipToInstructions || null,
      shippingAddress: pdfData?.sidebarShipping?.shippingAddress || null,
      shippingPhoneNumber: pdfData?.sidebarShipping?.phoneNumber || null,
      description: pdfData?.description || 'No description',
      poItems: sanitizeItems(receiptItems) || [],
      subtotal: parseFloatAndRound(subtotal, 2) || 0,
      freightCost: parseFloatAndRound(pdfData?.freight, 2) || 0,
      taxRate: parseFloatAndRound(taxRate, 3) || 0,
      taxAmount: parseFloatAndRound(taxAmount, 2) || 0,
      total: parseFloatAndRound(total, 2) || 0
    };
  };

  const renderPDF = () => {
    return (
      <PDFDocument
        configuration={PDFDocumentLayout(pdfData)}
        initialValues={getFormattedData()}
        layout="pdf"
      />
    );
  };

  const uploadPDF = async pdfFile => {
    const currentDayFileFormat = moment().format('MM-DD-YYYY');
    const poUploadFileName = `Purchase_Order_Receipt_${currentDayFileFormat}.pdf`;

    try {
      const storageService = new StorageService();
      const s3Response = await storageService.uploadFile(
        pdfFile,
        `${user.tenantId}/${poUploadFileName}`,
        e => e,
        'application/pdf'
      );
      setOrderEmailRecipient(pdfData?.generalInfo?.vendor?.email || '');
      setEmailSubject(
        companyInfo?.companyName
          ? `Purchase Order Receipt - ${companyInfo?.companyName}`
          : 'Purchase Order Receipt'
      );
      setUploadedPdfFileName(poUploadFileName);
      return s3Response;
    } catch (error) {
      Logger.error(error);
    }
  };

  const generateAndUploadPDF = async () => {
    const pdfDoc = renderPDF();
    const blobData = await pdf(pdfDoc).toBlob();
    if (blobData) {
      const pdfFileUrl = await uploadPDF(blobData);
      setUploadedPdfUrl(pdfFileUrl);
    }
    setIsSubmitting(false);
    setIsOpendEmailModal(true);
  };

  const handleSharePdf = () => {
    setIsSubmitting(true);
    generateAndUploadPDF();
  };

  const handleSendEmailModalClose = () => {
    setIsOpendEmailModal(false);
  };

  return (
    <div className={classes.root}>
      <FullScreenModal
        contentStyle={{ marginTop: 0, marginBottom: 0, flex: '1 1 auto' }}
        handleClose={handleClose}
        modalHeaderButtons={[
          <DefaultButton
            key="generatePdfEmailReceiptPdfBtn"
            label="Email Receipt"
            showSpinner={isSubmitting}
            style={{ marginRight: 8 }}
            variant="containedSecondary"
            onClick={() => handleSharePdf()}
          />,
          ![
            ProcurementPurchaseOrderReceiptStatus.POSTED.toLowerCase(),
            ProcurementPurchaseOrderReceiptStatus.BYPASSED.toLowerCase()
          ].includes(receiptStatus) && (
            <DefaultButton
              key="generatePdfPostReceiptPdfBtn"
              label="Post Receipt"
              showSpinner={isSubmitting}
              style={{ marginRight: 8 }}
              variant="containedPrimary"
              onClick={handlePostReceipt}
            />
          )
        ]}
        open={open}
        style={{ display: 'flex', flex: '1 1 auto' }}
        title={`Receipt ${pdfData?.receiptNumber || ''}`}
      >
        <PDFViewer height="100%" width="100%">
          <PDFDocument
            configuration={PDFDocumentLayout(pdfData)}
            initialValues={getFormattedData()}
            layout="pdf"
          />
        </PDFViewer>
      </FullScreenModal>
      <SendEmailPopUp
        btnLabel="Send Email"
        emailSubject={emailSubject}
        handleModalClose={handleSendEmailModalClose}
        open={isOpendEmailModal}
        orderEmailRecipient={orderEmailRecipient}
        pdfData={getFormattedData()}
        title="Email Purchase Order Receipt"
        uploadedPdfFileName={uploadedPdfFileName}
        uploadedPdfUrl={uploadedPdfUrl}
      />
    </div>
  );
};

GeneratePdfModal.propTypes = {
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  pdfData: PropTypes.object,
  receiptItems: PropTypes.array,
  companyInfo: PropTypes.object.isRequired,
  taxRate: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  handlePostReceipt: PropTypes.func.isRequired
};

GeneratePdfModal.defaultProps = {
  pdfData: {},
  receiptItems: []
};

export default GeneratePdfModal;
