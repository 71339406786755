import React from 'react';

import { Typography } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';

import { AlgoliaSearch, Modal } from 'components';
import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';
import { snackbarOn } from 'redux/actions/globalActions';
import { checkPermission } from 'utils';
import { AppConstants, PermissionConstants } from 'utils/AppConstants';

const CreateQuotePopup = props => {
  const { open, history, handleClose, snackbar } = props;
  const user = useSelector(s => s.user);
  const hasPermissionToEditQuote = checkPermission('edit', PermissionConstants.OBJECT_QUOTES, user);

  const redirectToNewQuotePage = customerProperty => {
    history.push('/quotes/new', {
      propertyId: customerProperty.parentId
    });
    handleClose();
  };

  const handleSearchResultsFilter = resultList => {
    const companyAddressResult = resultList.filter(
      hit => hit.entityType === 'CompanyAddress' && hit.parentStatus === AppConstants.ACTIVE
    );
    return companyAddressResult;
  };

  return (
    <Modal handleClose={handleClose} open={open} width="1100">
      <div style={{ marginTop: 20, marginBottom: 200 }}>
        <Typography style={{ marginBottom: 10 }}>
          Please select a customer property to create quote:
        </Typography>
        <AlgoliaSearch
          delimiter=" "
          displayText={['customerPropertyName', 'address']}
          filters={[
            {
              attribute: 'entityType',
              valueArray: ['CompanyAddress']
            },
            {
              attribute: 'parentEntityType',
              valueArray: ['CustomerProperty']
            },
            {
              attribute: 'addressType',
              valueArray: ['propertyAddress']
            }
          ]}
          handleChange={customerProperty => {
            if (!hasPermissionToEditQuote) {
              handleClose();
              return snackbar('error', 'User is not authorized to create a quote');
            }
            return customerProperty && redirectToNewQuotePage(customerProperty);
          }}
          handleFilter={handleSearchResultsFilter}
          locatedInPopUp
          name="selectProperty"
          placeholder="Search by customer property name or its address"
          searchIndex={defaultSearchIndex}
          suggestionMarginTop={156}
          value=""
        />
      </div>
    </Modal>
  );
};

export default connect(() => {}, { snackbar: snackbarOn })(CreateQuotePopup);
