import React, { useMemo } from 'react';

import { MUIFormComponentNames } from '@BuildHero/sergeant';
import { useTheme } from '@material-ui/core';

import { getFormValidation, getFormValidationErrors } from '../../NonVisitEvents.helpers';
import PropertyAddress from '../VisitDetailsForm/components/PropertyAddress';

export const useBillableEventFormConfig = ({
  disabled,
  disableScheduling,
  visit,
  job,
  visitDataLoading
}) => {
  const theme = useTheme();
  return useMemo(() => {
    const row = contents => ({
      options: {
        marginBottom: theme.spacing(2)
      },
      contents
    });

    const EVENT_NAME_FIELD = [
      {
        component: MUIFormComponentNames.TextInput,
        source: 'eventName',
        options: {
          isRequired: true,
          disabled,
          label: 'EVENT NAME'
        }
      }
    ];

    const DESCRIPTION_FIELD = [
      {
        component: MUIFormComponentNames.TextInput,
        source: 'description',
        options: {
          disabled,
          label: 'DESCRIPTION',
          lines: 4,
          maxTextLen: 80
        }
      }
    ];

    const PROPERTY_ADDRESS = [
      {
        component: MUIFormComponentNames.FieldWithLabel,
        options: {
          label: 'Property Address',
          type: 'custom',
          transform: () => <PropertyAddress job={job} loading={visitDataLoading} visit={visit} />
        }
      }
    ];

    const FROM_FIELD = [
      {
        component: MUIFormComponentNames.LabelControl,
        source: 'FROM',
        options: {
          required: true
        }
      },
      {
        component: 'DateTimeInput',
        source: 'from',
        options: {
          disabled,
          disableDateEditing: disableScheduling
        }
      }
    ];

    const TO_FIELD = [
      {
        component: MUIFormComponentNames.LabelControl,
        source: 'TO',
        options: {
          required: true
        }
      },
      {
        component: 'DateTimeInput',
        source: 'to',
        options: {
          disabled,
          disableDateEditing: disableScheduling
        }
      }
    ];

    return {
      fields: {},
      layouts: {
        default: {
          contents: [
            {
              component: 'AccordionSection',
              options: {
                heading: 'Details',
                expanded: true
              },
              contents: [row(EVENT_NAME_FIELD), row(DESCRIPTION_FIELD), row(PROPERTY_ADDRESS)]
            },
            {
              component: 'AccordionSection',
              options: {
                heading: 'Scheduling',
                expanded: true
              },
              contents: [row(FROM_FIELD), row(TO_FIELD)]
            }
          ]
        }
      },
      validation: getFormValidation(),
      validationErrors: getFormValidationErrors()
    };
  }, [disabled, disableScheduling, theme, visit, job, visitDataLoading]);
};
