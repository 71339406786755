import React from 'react';

import { shape, string } from 'prop-types';

import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';

import { useStyles } from './FileNameCell.styles';

const downloadFile = async ({ fileUrl, fileName }) => {
  const storageService = new StorageService();
  try {
    const url = await storageService.getFile(fileUrl, false);
    if (url) {
      const aLink = document.createElement('a');
      aLink.target = '_blank';
      aLink.download = fileName;
      aLink.href = url;
      aLink.click();
    }
  } catch (error) {
    Logger.info(`Error uploading image ${error}`);
  }
};

const FileNameCell = ({ formattedValue, row }) => {
  const styles = useStyles();
  const fileUrl = row?.fileUrl;
  const fileName = formattedValue;
  const handleClick = () => downloadFile({ fileUrl, fileName });
  return (
    <div
      aria-label={`Download file ${formattedValue}`}
      css={styles.container}
      role="button"
      tabIndex="0"
      onClick={handleClick}
      onKeyPress={handleClick}
    >
      {formattedValue}
    </div>
  );
};

FileNameCell.propTypes = {
  formattedValue: string.isRequired,
  row: shape({ fileUrl: string }).isRequired
};

export default FileNameCell;
