import React from 'react';

import { Button, Modal, ThemeProvider } from '@BuildHero/sergeant';
import { noop } from 'lodash';
import { bool, func, string } from 'prop-types';

const MutationConfirmationModal = ({
  modalText,
  title,
  buttonText,
  open,
  onClose,
  onContinue,
  warn,
  ...props
}) => (
  <ThemeProvider>
    <Modal
      {...props}
      actions={
        <Button fullWidth type={warn ? 'error' : 'primary'} onClick={onContinue}>
          {buttonText}
        </Button>
      }
      open={open}
      title={title}
      onClose={onClose}
    >
      {modalText}
    </Modal>
  </ThemeProvider>
);

MutationConfirmationModal.propTypes = {
  modalText: string,
  buttonText: string,
  title: string,
  open: bool,
  warn: bool,
  onClose: func,
  onContinue: func
};

MutationConfirmationModal.defaultProps = {
  modalText: 'Are you sure you want to save these changes?',
  buttonText: 'Confirm Changes',
  title: 'Confirm Save',
  open: false,
  warn: false,
  onClose: noop,
  onContinue: noop
};

export default MutationConfirmationModal;
