import Labels from 'meta/labels';
import { PricingStrategy } from 'utils/constants';

const row1 = {
  options: {
    size: 3,
    direction: 'row',
    grow: 0
  },
  contents: [
    {
      options: {
        direction: 'column',
        grow: 1,
        padding: 12
      },
      contents: [
        {
          component: {
            edit: 'TextInput'
          },
          source: 'name',
          options: {
            label: 'Name',
            placeholder: '',
            isRequired: true
          }
        }
      ]
    },
    { contents: [{ component: null }] },
    { contents: [{ component: null }] }
  ]
};

const row2 = {
  options: {
    size: 2,
    direction: 'row',
    grow: 0
  },
  contents: [
    {
      options: {
        direction: 'column',
        grow: 1,
        padding: 12
      },
      contents: [
        {
          component: {
            edit: 'TextInput'
          },
          source: 'description',
          options: {
            label: 'Description',
            placeholder: '',
            isRequired: false,
            lines: 4
          }
        }
      ]
    },
    { contents: [{ component: null }] }
  ]
};

const row3 = (showConfigSection, locale) => {
  if (!showConfigSection) {
    return { contents: [{ component: null }] };
  }

  return {
    options: {
      size: 2,
      direction: 'row',
      grow: 0
    },
    contents: [
      {
        options: {
          direction: 'column',
          grow: 1,
          padding: 12
        },
        contents: [
          { component: { edit: 'ConfigHeader' } },
          {
            component: { edit: 'RadioButtonsGroup' },
            source: 'pricebookConfig',
            options: {
              btnDirection: 'Horizontal',
              inputOptionLabels: `${Labels.configMarkup[locale]}, ${Labels.configMargin[locale]}`,
              inputOptions: `${PricingStrategy.MARKUP}, ${PricingStrategy.MARGIN}`
            }
          }
        ]
      }
    ]
  };
};

const row4 = (marginEnabled, showConfigSection) => ({
  options: {
    size: 3,
    direction: 'row',
    grow: 0
  },
  contents: [
    {
      options: {
        direction: 'column',
        grow: 1,
        padding: 12
      },
      contents: [
        {
          component: {
            edit: 'DefaultRate'
          },
          source: 'defaultMarkup',
          options: {
            marginEnabled,
            showConfigSection
          }
        }
      ]
    },
    { contents: [{ component: null }] },
    { contents: [{ component: null }] }
  ]
});

const row5 = showLabourRateSection => {
  if (!showLabourRateSection) {
    return { contents: [{ component: null }] };
  }

  return {
    options: {
      size: 2,
      direction: 'row',
      grow: 0
    },
    contents: [
      {
        options: {
          direction: 'column',
          grow: 1,
          padding: 12
        },
        contents: [
          {
            component: {
              edit: 'LaborRateSection'
            },
            source: 'laborRate',
            options: {
              // label: '',
              placeholder: ''
            }
          }
        ]
      },
      { contents: [{ component: null }] }
    ]
  };
};

const row6 = (
  showMaterialRateSection,
  allowFormatChange,
  showConfigSection,
  allowSelectExistingPricebook
) => {
  if (!showMaterialRateSection) {
    return { contents: [{ component: null }] };
  }

  return {
    options: {
      size: 2,
      direction: 'row',
      grow: 0
    },
    contents: [
      {
        options: {
          direction: 'column',
          grow: 1,
          padding: 12
        },
        contents: [
          {
            component: {
              edit: 'MaterialRateSection'
            },
            source: 'materialRate',
            options: {
              // label: '',
              placeholder: '',
              allowFormatChange,
              showConfigSection,
              allowSelectExistingPricebook
            }
          }
        ]
      },
      { contents: [{ component: null }] }
    ]
  };
};

const fieldValues = settings => ({
  options: {
    pageSize: 'LETTER',
    grow: 0
  },
  contents: [
    row1,
    row2,
    row3(settings.showConfigSection, settings.locale),
    row4(settings.marginEnabled, settings.showConfigSection),
    row5(settings.showLabourRateSection),
    row6(
      settings.showMaterialRateSection,
      settings.allowFormatChange,
      settings.showConfigSection,
      settings.allowSelectExistingPricebook
    )
  ]
});

const createPricebookLayout = settings => ({
  fields: {
    name: {
      edit: 'name'
    },
    description: {
      edit: 'description'
    },
    pricebookConfig: {
      edit: 'pricebookConfig'
    },
    defaultMarkup: {
      edit: 'defaultMarkup'
    },
    laborRate: {
      edit: 'laborRate'
    },
    materialRate: {
      edit: 'materialRate'
    }
  },
  layouts: {
    edit: fieldValues(settings)
  },
  validation: {
    type: 'object',
    properties: {
      name: {
        type: 'string'
      },
      description: {
        type: 'string'
      },
      defaultMarkup: {
        type: 'number'
      }
    },
    required: ['name', 'defaultMarkup']
  },
  validationErrors: {
    name: {
      required: 'Field is required.'
    },
    defaultMarkup: {
      required: 'Field is required.'
    }
  }
});

export default createPricebookLayout;
