import moment from 'moment';

import { removeEmptyValues, removeNestedJson, removeNullValues } from '../../utils';
import InstructionHelper from '../InstructionHelper';

const createTimeSheetSchemaMapping = schedule => {
  // added while processing query result
  const processedSchedule = removeEmptyValues(removeNullValues(schedule));
  const strippedSchedule = removeNestedJson(processedSchedule);
  const timeSheet = {
    clockInTime: moment().unix(),
    visitDate: moment(new Date()).unix(),
    version: 1,
    entityType: 'TimeSheet',
    tenantId: schedule.tenantId,
    tenantCompanyId: schedule.tenantCompanyId,
    parentSortKey: schedule.sortKey
  };

  const data = [{ ...strippedSchedule }, { ...timeSheet }];
  const ins = new InstructionHelper();
  const temp = ins.oneToMany(0, 1);

  const { instructionSet } = temp;
  const ret = { data: JSON.stringify(data), instructionSet: JSON.stringify(instructionSet) };
  return ret;
};

export default createTimeSheetSchemaMapping;
