import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';

const propertyAssetMaintenanceJobPreview = gql`
  query propertyAssetMaintenanceJobPreview($serviceAgreementId: String!, $assetTypeId: String!) {
    propertyAssetMaintenanceJobPreview(
      serviceAgreementId: $serviceAgreementId
      assetTypeId: $assetTypeId
    ) {
      propertyAsset {
        id
        entityType
        assetName
        make
        modelNumber
        serialNo
        mergedTaskTemplateChecklists {
          id
          name
        }
      }
      numberOfAnnualMaintenanceJobs
    }
  }
`;

const usePropertyAssetMaintenanceJobPreview = (
  { serviceAgreementId, assetTypeId },
  options = {}
) => {
  return useExtendedQuery(propertyAssetMaintenanceJobPreview, {
    variables: {
      serviceAgreementId,
      assetTypeId
    },
    skip: !serviceAgreementId || !assetTypeId,
    transform: response =>
      (response?.propertyAssetMaintenanceJobPreview || []).map(
        ({
          propertyAsset: {
            id,
            entityType,
            assetName,
            make,
            modelNumber,
            serialNo,
            mergedTaskTemplateChecklists = []
          },
          numberOfAnnualMaintenanceJobs
        }) => ({
          id,
          entityType,
          assetName,
          make,
          modelNumber,
          serialNo,
          mergedTaskTemplateChecklists,
          numberOfAnnualMaintenanceJobs
        })
      ),
    fetchPolicy: 'no-cache',
    ...options
  });
};

export default usePropertyAssetMaintenanceJobPreview;
