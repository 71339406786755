import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, ButtonType, Modal, SgtForm, ThemeProvider } from '@BuildHero/sergeant';
import { useTheme } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';

import {
  SgtCustomerSearch as CustomerSearch,
  SgtPropertySearch as PropertySearch
} from 'components';

import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import { snackbarOn } from 'redux/actions/globalActions';
import { ExportStatus, SyncStatus } from 'utils/constants';

import { exportConfirmContent, GetEditConfiguration } from './Adjustment.config';
import { useUpsertAdjustment } from './Adjustment.gql';
import { formatAdjustmentData, handleFieldChange } from './Adjustment.utils';
import GetValidation from './Adjustment.validation';
import { AdjustmentApplication, AdjustmentItem, InputOrField } from './components';

const SgtFormWrapper = React.memo(SgtForm);

function AdjustmentModal({ user, snackbar, open, onClose, data: givenData }) {
  const { spacing } = useTheme();
  const [upsert, { loading, error }] = useUpsertAdjustment();
  const [formService, setFormService] = useState();
  const [data, setData] = useState();
  const [lastClickedButton, setLastClickedButton] = useState();
  const confirmContext = useConfirmModal();

  useEffect(() => {
    const getFormattedData = async () => setData(await formatAdjustmentData(givenData, user));
    getFormattedData();
  }, [givenData, user]);

  useEffect(() => {
    if (error) snackbar('error', `Error updating adjustment: ${error?.message}. Please try again.`);
  }, [error, snackbar]);

  const configuration = useMemo(() => data && GetEditConfiguration(data), [data]);

  const handleSave = useCallback(
    async e => {
      const buttonName = e.currentTarget.name;
      setLastClickedButton(buttonName);
      if (buttonName === 'post') {
        if (!(await confirmContext.confirm(exportConfirmContent))) return;
        formService.formikContext.setFieldValue('syncStatus', SyncStatus.SYNCING);
        formService.formikContext.setFieldValue('exportStatus', ExportStatus.POSTED);
      }
      // since setFieldValue is async, we wait to make sure the new value
      // is included in the submit action
      // https://github.com/formium/formik/issues/529
      setTimeout(() => formService.formikContext.handleSubmit(), 1);
    },
    [formService]
  );

  const handleSubmit = useCallback(
    async newData => {
      const {
        data: {
          upsertAdjustment: { id }
        }
      } = await upsert(newData);
      onClose(id);
    },
    [upsert, onClose]
  );

  const sgtForm = useMemo(
    () =>
      !data ? (
        <Skeleton height={900} />
      ) : (
        <SgtFormWrapper
          configuration={configuration}
          customComponents={{
            CustomerSearch,
            PropertySearch,
            InputOrField,
            AdjustmentApplication,
            AdjustmentItem
          }}
          formikProps={{
            validateOnChange: false,
            validateOnBlur: true
          }}
          initialValues={data}
          layout="default"
          validationSchema={GetValidation(data.accountingApp)}
          onCreateService={setFormService}
          onFieldChange={handleFieldChange}
          onSubmit={handleSubmit}
        />
      ),
    [configuration, data, handleSubmit]
  );

  return (
    <ThemeProvider>
      <Modal
        actions={
          <div style={{ display: 'flex', flexDirection: 'row', gap: spacing(1) }}>
            <Button
              disabled={loading}
              loading={loading && lastClickedButton === 'post'}
              name="post"
              testingid="save-export-adjustment"
              type={ButtonType.SECONDARY}
              onClick={handleSave}
            >
              Save & Export Adjustment
            </Button>
            {![ExportStatus.POSTED, ExportStatus.EXPORTED].includes(data?.exportStatus) && (
              <Button
                disabled={loading}
                loading={loading && lastClickedButton === 'save'}
                name="save"
                testingid="save-adjustment"
                onClick={handleSave}
              >
                Save Adjustment
              </Button>
            )}
          </div>
        }
        fullScreen
        open={open}
        title={`${data?.id ? 'Edit' : 'New'} Adjustment`}
        onClose={() => onClose()}
      >
        {sgtForm}
      </Modal>
    </ThemeProvider>
  );
}

AdjustmentModal.propTypes = {
  user: PropTypes.object.isRequired, // redux
  snackbar: PropTypes.func.isRequired, // redux
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object
};

AdjustmentModal.defaultProps = {
  data: {
    number: null,
    billingCustomer: null,
    property: null,
    adjustmentType: null,
    transactionType: null,
    ledgerAccount: null,
    ledgerOffsetAccount: null,
    department: null,
    amount: null,
    availableAmount: 0,
    appliedAmount: 0,
    taxAmount: 0,
    taxLedgerAccount: null,
    taxRate: null,
    date: moment().unix(),
    creditCardTransaction: false,
    adjustmentItems: { items: [] },
    transactions: { items: [] },
    exportStatus: ExportStatus.PENDING
  }
};

export default connect(state => state, { snackbar: snackbarOn })(AdjustmentModal);
