import React, { useState } from 'react';

import { Divider } from '@BuildHero/sergeant';
import { useTheme } from '@material-ui/core';
import gql from 'graphql-tag';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PageHeader, XGrid } from 'components';
import CreateEntryButton from 'components/Buttons/CreateEntryButton';
import { column, ColumnType } from 'components/XGrid/columnTypes';
import ErrorBoundaries from 'scenes/Error';

import NewTenantModal from './NewTenantModal';
import QuickFilter from './QuickFilter';

const GET_TENANTS = gql`
  query getTenantList(
    $tenantId: String
    $filter: TableFilterInput
    $pagination: PaginationInput
    $sorting: [TableSortingInput]
  ) {
    data: getTenantList(
      tenantId: $tenantId
      filter: $filter
      pagination: $pagination
      sorting: $sorting
    ) {
      rowCount
      items
    }
  }
`;

const columns = [
  {
    field: 'quickFilter',
    headerName: '',
    hide: true,
    sortable: false,
    filterable: true,
    metaOnly: true
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 325,
    valueGetter: ({ value }) => ({
      label: value,
      to: `/admin/tenant/${value}`
    }),
    ...column[ColumnType.LINK]
  },
  {
    field: 'companyName',
    headerName: 'Name',
    width: 300
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 200
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    valueGetter: ({ value }) => value?.toLowerCase() === 'active',
    type: 'boolean'
  },
  {
    field: 'accountName',
    headerName: 'Account Name',
    width: 200
  },
  {
    field: 'ownerName',
    headerName: 'Owner Name',
    width: 100
  },
  {
    field: 'tier',
    headerName: 'Tier',
    width: 75
  },
  {
    field: 'userName',
    headerName: 'Username',
    width: 200
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 200
  },
  {
    field: 'salesforceId',
    headerName: 'Salesforce ID',
    width: 200
  },
  {
    field: 'lastUpdatedBy',
    headerName: 'Last Updated By',
    width: 175
  },
  {
    field: 'lastUpdatedDateTime',
    headerName: 'Last Updated',
    width: 175,
    ...column[ColumnType.DATETIME]
  }
];

const TenantList = () => {
  const { spacing } = useTheme();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState();
  const user = useSelector(state => state.user);

  return (
    <ErrorBoundaries>
      <NewTenantModal
        open={modalOpen}
        onClose={id => {
          if (id) {
            history.push(`/admin/tenant/${id}`);
          }
          setModalOpen(false);
        }}
      />
      <PageHeader title="Tenants" userLocale={user.locale}>
        <CreateEntryButton handleAdd={() => setModalOpen(true)} label="New Tenant" />
      </PageHeader>
      <Divider marginLeft={-spacing(3)} width={`calc(100% + ${spacing(6)}px)`} />
      <XGrid
        columns={columns}
        enableExport
        entityTypeName="Tenants"
        query={GET_TENANTS}
        toolbarCustomComponent={QuickFilter}
      />
    </ErrorBoundaries>
  );
};

export default TenantList;
