import * as JobActions from '../../redux/actions/jobActions';
import initialState from '../../redux/state';

export const jobs = (state = initialState.jobs, action) => {
  switch (action.type) {
    case JobActions.START_GET_JOB: {
      return {
        ...state,
        isLoading: true
      };
    }
    case JobActions.START_GET_JOB_SUCCESS: {
      return {
        ...state,
        currentJob: action.payload,
        isLoading: false
      };
    }
    case JobActions.START_GET_JOB_FAILURE: {
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    }
    case JobActions.START_JOB_UPDATE: {
      return {
        ...state,
        isLoading: true
      };
    }
    case JobActions.START_JOB_UPDATE_SUCCESS: {
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          ...action.payload
        },
        isLoading: false
      };
    }
    case JobActions.START_JOB_UPDATE_FAILURE: {
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    }
    default: {
      return state;
    }
  }
};
