import React, { useState } from 'react';

import {
  InlineAlert,
  InlineAlertTypes,
  Switch,
  ThemeProvider,
  TV,
  TW,
  Typography
} from '@BuildHero/sergeant';
import { Box, Divider, useTheme } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import { InventoryPartSourceType, InvoiceStatus } from 'utils/constants';

import InventoryPartsCostsTable from '../InventoryPartsCostsTable';
import { InvoiceDiscounts } from '../InvoiceOverviewDiscounts';
import { InvoiceOtherItems } from '../InvoiceOverviewOtherItems';
import { useBulkUpdateLabourRateBillingHourLines } from '../mutations';
import { getStyles } from '../styles';
import TimeAndMaterialLaborTable from '../TimeAndMaterialOverviewTab/LaborCosts';
import { anyItemModifiedByInvoice } from '../utils';

import PricebookSelect from './PricebookSelect';
import PurchasedItemsTable from './PurchasedItemsTable';

const CostInvoiceItems = ({ jobData, visits, refetchJob, companyTimezone, isLoading }) => {
  const [hideInvoiced, setHideInvoiced] = useState(false); // eslint-disable-line

  const theme = useTheme();
  const styles = getStyles(theme);
  const invoices = jobData.invoices?.items || [];
  const [childrenFinishedLoading, setChildrenFinishedLoading] = useState(false);

  const [
    bulkUpdateLabourRateBillingHourLines,
    { loading: bulkUpdateLabourRateBillingHourLinesLoading }
  ] = useBulkUpdateLabourRateBillingHourLines();

  const invoiceItemsModifiedByInvoice = () => {
    const itemAddedFromInvoice = (jobData.invoices?.items || [])
      .filter(({ status }) => status !== InvoiceStatus.VOID)
      .some(invoice =>
        (invoice.invoiceItems?.items || []).some(invoiceItem => {
          if (invoiceItem.source === InventoryPartSourceType.INVOICE) return true;

          return false;
        })
      );

    return itemAddedFromInvoice || anyItemModifiedByInvoice(invoices, jobData);
  };

  return (
    <ThemeProvider>
      <Divider css={styles.divider} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          flexWrap: 'nowrap'
        }}
      >
        <Typography variant={TV.L} weight={TW.BOLD}>
          Cost &amp; Invoice Items
        </Typography>
        <Switch
          horizontalLabel
          label="Hide Invoiced Items"
          style={{ width: 'initial' }}
          onChange={() => setHideInvoiced(prev => !prev)}
        />
      </Box>
      {!isLoading && invoiceItemsModifiedByInvoice() && (
        <InlineAlert Icon={WarningIcon} type={InlineAlertTypes.YELLOW}>
          This information has been modified from an invoice.
        </InlineAlert>
      )}
      <PricebookSelect
        isLoading={isLoading || bulkUpdateLabourRateBillingHourLinesLoading}
        isLoading={
          isLoading || bulkUpdateLabourRateBillingHourLinesLoading || !childrenFinishedLoading
        }
        jobId={jobData.id}
        jobVersion={jobData.version}
        priceBookId={jobData.priceBookId}
      />
      <TimeAndMaterialLaborTable
        bulkUpdateLabourRateBillingHourLines={bulkUpdateLabourRateBillingHourLines}
        bulkUpdateLabourRateBillingHourLinesLoading={bulkUpdateLabourRateBillingHourLinesLoading}
        companyTimezone={companyTimezone}
        hideInvoiced={hideInvoiced}
        invoices={invoices}
        isLoading={isLoading}
        priceBookId={jobData.priceBookId}
        visits={visits}
        onLoad={setChildrenFinishedLoading}
      />
      <InventoryPartsCostsTable
        companyTimezone={companyTimezone}
        hideInvoiced={hideInvoiced}
        invoices={invoices}
        isLoading={isLoading}
        jobData={jobData}
        refetchJob={refetchJob}
        visits={visits}
      />
      {!hideInvoiced && !isLoading && <InvoiceOtherItems jobData={jobData} />}
      {!hideInvoiced && !isLoading && <InvoiceDiscounts jobData={jobData} />}
      <PurchasedItemsTable
        billLines={jobData?.billLines?.items}
        hideInvoiced={hideInvoiced}
        invoices={jobData?.invoices}
        isLoading={isLoading}
        priceBookId={jobData?.priceBookId}
        purchaseOrderLines={jobData?.purchaseOrderLines?.items}
        purchaseOrderReceiptLines={jobData?.purchaseOrderReceiptLines?.items}
        purchaseOrders={jobData?.purchaseOrders?.items}
        refetchJob={refetchJob}
      />
    </ThemeProvider>
  );
};

CostInvoiceItems.defaultProps = {
  isLoading: false
};

export default CostInvoiceItems;
