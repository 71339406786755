import React from 'react';

import { func } from 'prop-types';

import { AlgoliaSearch } from 'components';
import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';
import { EntityType, JobStatus } from 'utils/constants';

const JobSearchHeader = ({ onJobSelected }) => {
  const handleChange = job => onJobSelected(job?.jobNumber);
  return (
    <AlgoliaSearch
      delimiter=", "
      displayText={['jobNumber', 'customerName', 'customerPropertyName']}
      filters={[
        {
          attribute: 'entityType',
          valueArray: [EntityType.JOB]
        },
        {
          attribute: 'status',
          negate: true,
          valueArray: [JobStatus.CANCELED]
        },
        {
          attribute: 'status',
          negate: true,
          valueArray: [JobStatus.COMPLETE]
        },
        {
          attribute: 'status',
          negate: true,
          valueArray: [JobStatus.ON_HOLD]
        }
      ]}
      handleChange={handleChange}
      isRequired
      locatedInPopUp
      name="selectJob"
      placeholder="Search Companies, Properties, or Jobs"
      searchIndex={defaultSearchIndex}
      value=""
    />
  );
};

JobSearchHeader.propTypes = {
  onJobSelected: func.isRequired
};

export default JobSearchHeader;
