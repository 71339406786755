import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, ButtonType, ThemeProvider } from '@BuildHero/sergeant';
import gql from 'graphql-tag';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';

import { PageHeader, UserPermission, XGrid } from 'components';
import CreateEntryButton from 'components/Buttons/CreateEntryButton';
import SelectCustomerModal from 'components/Modal/SelectCustomerModal';
import { column, ColumnType, valueGetters } from 'components/XGrid/columnTypes';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import AccountingValidationService from 'services/core/graphql-services/AccountingValidation';
import addCustomerPropertiesToCustomer from 'services/core/graphql/crm/customer-property/mutations/addCustomerPropertiesToCustomer';
import getCustomerById from 'services/core/graphql/crm/customer/queries/getCustomerById';
import transformToCustomerPropertySchema from 'services/core/mutation-schema/customer-and-property/updatePropertySchema';
import { Logger } from 'services/Logger';

import { MultiSelectTypes, PermissionConstants } from 'utils/AppConstants';
import { AccountingApp, EntityType, EnumType } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import AddProperty from './AddProperty';

const GET_CUSTOMER_PROPERTIES = gql`
  query getCustomerPropertiesList(
    $tenantId: String
    $filter: TableFilterInput
    $pagination: PaginationInput
    $sorting: [TableSortingInput]
  ) {
    data: getCustomerPropertiesList(
      tenantId: $tenantId
      filter: $filter
      pagination: $pagination
      sorting: $sorting
    ) {
      rowCount
      items
    }
  }
`;

/**
 * We can either massage the data here through valueGetter
 * or we can format the data before it goes into the table.
 */
const propertyColumns = [
  {
    field: 'companyName',
    headerName: 'Property',
    width: 200,
    dataField: 'id',
    valueGetter: valueGetters.propertyLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    enumType: EnumType.PROPERTY_STATUS,
    ...column[ColumnType.ENUM]
  },
  {
    field: 'customerName',
    headerName: 'Customer',
    width: 250,
    valueGetter: valueGetters.customerLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'customerPropertyTypeValue',
    headerName: 'Property Type',
    width: 150,
    enumType: MultiSelectTypes.CUSTOMER_PROPERTY_TYPES,
    ...column[ColumnType.ENUM]
  },
  {
    field: 'openJobsCount',
    headerName: 'Open Jobs',
    width: 130,
    ...column[ColumnType.NUMBER]
  },
  {
    field: 'openJobsValue',
    headerName: 'Open Jobs Value',
    width: 170,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'outstandingBalance',
    headerName: 'Outstanding Balance',
    width: 210,
    isBalance: true,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'overdueBalance',
    headerName: 'Overdue Balance',
    width: 170,
    isBalance: true,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'propertyAddresses',
    headerName: 'Property Address',
    width: 250,
    ...column[ColumnType.ADDRESS]
  },
  {
    field: 'accountNumber',
    headerName: 'Account Number',
    width: 200,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'billingAddresses',
    headerName: 'Billing Address',
    width: 250,
    ...column[ColumnType.ADDRESS]
  },
  {
    field: 'billingCustomerName',
    headerName: 'Billing Customer',
    width: 250,
    valueGetter: valueGetters.customerLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'createdDate',
    headerName: 'Created On',
    width: 150,
    ...column[ColumnType.DATE]
  },
  {
    field: 'customerTypeValue',
    headerName: 'Customer Type',
    width: 200,
    enumType: MultiSelectTypes.CUSTOMER_TYPES,
    ...column[ColumnType.ENUM]
  },
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    filterable: false,
    isEditLink: true,
    dataField: 'id',
    valueGetter: valueGetters.propertyLink,
    ...column[ColumnType.EDITLINK]
  }
];

const PropertyList = ({ history }) => {
  const user = useSelector(state => state.user);
  const flags = useFlags();
  const dispatch = useDispatch();
  const snackbar = useCallback((...args) => dispatch(snackbarOn(...args)), [dispatch]);

  const [addProperty] = useMutation(addCustomerPropertiesToCustomer);
  const tenantSettings = useSelector(s => s.settings);
  const { accountingApp, syncImmediately } = tenantSettings;
  const instantSyncSetting = !!syncImmediately;

  const [showSelectCustomerModal, setShowSelectCustomerModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [showAddPropertyModal, setShowAddPropertyModal] = useState(false);
  const [formService, setFormService] = useState();
  const [isSubmitting, setIsSubmitting] = useState();
  const accountingValidationService = new AccountingValidationService();

  const pageHeaderButtons = [
    <CreateEntryButton
      caslKey={PermissionConstants.OBJECT_PROPERTY}
      handleAdd={() => setShowSelectCustomerModal(true)}
      key="createProperty"
      label="Add Property"
    />
  ];

  const handleOnCompleteAddProperty = useCallback(
    async data => {
      setIsSubmitting(true);
      // validation - if isTaxable then require taxRate
      const newProperty = { ...data };
      if (newProperty.isTaxable && newProperty.isTaxable !== 'false' && !newProperty.taxRateId) {
        setIsSubmitting(false);
        return snackbar(
          'error',
          'Tax rate required for a taxable property.\nPlease select a tax rate.'
        );
      }
      const validationMessage = await accountingValidationService.validateEntity(
        user.tenantId,
        EntityType.CUSTOMER_PROPERTY,
        newProperty
      );
      if (validationMessage) {
        setIsSubmitting(false);
        return snackbar('error', validationMessage);
      }

      newProperty.syncNow = instantSyncSetting;
      newProperty.sameAddress = data.useCustomerAddress;
      newProperty.isTaxable = data.isTaxable;
      newProperty.customerName = selectedCustomer.customerName;
      const quickbooksSetting = accountingApp === AccountingApp.QUICKBOOKS;

      newProperty.isIntegrationEnabled = quickbooksSetting;
      try {
        const payload = transformToCustomerPropertySchema(
          newProperty,
          selectedCustomer.id,
          instantSyncSetting,
          true
        );
        const addPropertyResponse = await addProperty({
          variables: {
            partitionKey: user.tenantId,
            data: payload
          }
        });
        setIsSubmitting(false);
        const newPropertyId = addPropertyResponse?.data?.addCustomerPropertiesToCustomer?.[0]?.id;
        if (newPropertyId) {
          history.push(`/property/view/${newPropertyId}`);
        }
      } catch (error) {
        setIsSubmitting(false);
        Logger.error(error);
        snackbar('error', 'Unable to save customer property', error);
      }
    },
    [
      accountingApp,
      addProperty,
      history,
      selectedCustomer,
      snackbar,
      instantSyncSetting,
      user.tenantId
    ]
  );

  const handleSave = useCallback(() => {
    formService.submit();
  }, [formService]);

  const setModalHeaderButtons = useMemo(
    () => (
      <ThemeProvider>
        <Button
          disabled={isSubmitting}
          loading={isSubmitting}
          type={ButtonType.PRIMARY}
          onClick={handleSave}
        >
          Save Property
        </Button>
      </ThemeProvider>
    ),
    [handleSave, isSubmitting]
  );

  const [getCustomer, { data: customerData }] = useLazyQuery(getCustomerById);

  useEffect(() => {
    if (customerData) {
      setSelectedCustomer(customerData?.getCustomerById);
      setShowSelectCustomerModal(false);
      setShowAddPropertyModal(true);
    }
  }, [customerData]);

  const handleCustomerSelection = useCallback(
    async customer =>
      getCustomer({
        variables: {
          id: `${customer?.entityType === EntityType.CUSTOMER ? customer?.id : customer?.parentId}`
        }
      }),
    [getCustomer]
  );

  return (
    <ErrorBoundaries>
      <UserPermission action={PermissionConstants.OBJECT_CUSTOMER} I="read">
        <PageHeader
          overrideHeaderButtons={pageHeaderButtons}
          pageMapKey="properties"
          userLocale={user.locale}
        />
        <XGrid
          columns={propertyColumns}
          enableExport
          entityTypeName="Properties"
          query={GET_CUSTOMER_PROPERTIES}
          tableName="PropertiesXGrid"
        />
        <SelectCustomerModal
          handleClose={() => setShowSelectCustomerModal(false)}
          open={showSelectCustomerModal}
          onCustomerSelected={handleCustomerSelection}
        />
        {showAddPropertyModal && (
          <AddProperty
            caslKey={PermissionConstants.OBJECT_PROPERTY}
            data={{}}
            handleClose={() => setShowAddPropertyModal(false)}
            headerButtons={setModalHeaderButtons}
            layout="edit"
            open={showAddPropertyModal}
            parent={selectedCustomer || {}}
            title="Add Property"
            usePropertyPricebooks={flags[FeatureFlags.USE_PROPERTY_PRICEBOOKS]}
            onComplete={handleOnCompleteAddProperty}
            onCreateService={service => setFormService(service)}
          />
        )}
      </UserPermission>
    </ErrorBoundaries>
  );
};

export default PropertyList;
