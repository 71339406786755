import React from 'react';

import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import ProfileIcon from 'components/Navigation/UserControls/ProfileIcon/index';

const EmployeesList = ({ form }) => {
  const { employees } = form.values;
  return (
    <div>
      {employees?.length ? (
        <List>
          <Divider />
          {employees.map(
            item =>
              item.mappedEntity && (
                <EmployeeListItem
                  employeeName={`${item.mappedEntity.firstName?.trim() ||
                    ''} ${item.mappedEntity.lastName?.trim() || ''}`}
                  key={item.id}
                  profilePictureUrl={item.mappedEntity.profilePictureUrl}
                />
              )
          )}
        </List>
      ) : (
        <Typography variant="body2">There are no employees assigned to this department.</Typography>
      )}
    </div>
  );
};

const EmployeeListItem = ({ profilePictureUrl, employeeName, key }) => {
  const imageKey = profilePictureUrl || ' ';
  return (
    <ListItem button divider key={key}>
      <Box display="flex-start">
        <ProfileIcon alt={employeeName} employeeName={employeeName} imageKey={imageKey} />
      </Box>
      <ListItemText id={employeeName} primary={employeeName} />
    </ListItem>
  );
};

export default EmployeesList;
