import React, { useEffect, useRef, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Divider, Button as SgtButton, ThemeProvider } from '@BuildHero/sergeant';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { flatten, keys, sortBy } from 'lodash';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';

import { ConfirmLeave } from 'components';

import ResponsiveTable from 'components/ResponsiveTable';

import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';

import { CustomerPropertyService, JobService, QuoteService } from 'services/core';
import { Logger } from 'services/Logger';
import { logErrorWithCallback } from 'utils';
import { TaskConstants } from 'utils/AppConstants';

import { batchMutateJobTasks } from './BatchMutateJobTasks.gql';
import { NewCardButton, TaskCard } from './TaskCard';
import useStyles from './Tasks.styles';
import { taskListMeta } from './TasksLayout';
import TaskV2Tab from './TaskV2Tab';

const formatTasksInitialData = data => {
  return data.reduce((acc, jobTask) => {
    if (jobTask.assetId) return acc;

    return [
      ...acc,
      {
        id: jobTask.id,
        name: jobTask.name,
        description: jobTask.description,
        partsAndMaterials:
          jobTask.taskEntries?.items?.map(entry => ({
            id: entry.productId,
            label: entry.name,
            group: undefined, // TODO: fix this
            value: {
              updateId: entry.id,
              name: entry.product?.name,
              code: entry.product?.code,
              description: entry.description,
              quantity: entry.quantity,
              version: entry.version,
              productId: entry.productId
            }
          })) ?? [],
        forms:
          jobTask.formData?.items?.map(form => ({
            id: form.formId,
            label: form.form.name,
            value: {
              required: form.isRequired,
              formId: form.formId,
              formDefinitionId: form.formDefinitionId,
              formSortKey: form.formSortKey,
              formDefinitionSortKey: form.formDefinitionSortKey,
              updateId: form.id
            }
          })) ?? [],
        status: jobTask.status,
        originalStatus: jobTask.status,
        version: jobTask.version,
        jobTaskId: jobTask.jobTaskId,
        taskNumber: jobTask.taskNumber
      }
    ];
  }, []);
};

const formatAssetTasksInitialData = data => {
  return data.reduce((acc, jobTask) => {
    if (!jobTask.assetId) return acc;
    const task = {
      id: jobTask.id,
      name: jobTask.name,
      description: jobTask.description,
      assetId: jobTask.assetId,
      assetTypeId: jobTask.asset?.assetTypeId,
      partsAndMaterials:
        jobTask.taskEntries?.items?.map(entry => ({
          id: entry.productId,
          label: entry.name,
          group: undefined, // TODO: fix this
          value: {
            updateId: entry.id,
            name: entry.product?.name,
            code: entry.product?.code,
            description: entry.description,
            quantity: entry.quantity,
            version: entry.version,
            productId: entry.productId
          }
        })) ?? [],
      forms:
        jobTask.formData?.items?.map(form => ({
          id: form.formId,
          label: form.form.name,
          value: {
            required: form.isRequired,
            formId: form.formId,
            formDefinitionId: form.formDefinitionId,
            formSortKey: form.formSortKey,
            formDefinitionSortKey: form.formDefinitionSortKey,
            updateId: form.id
          }
        })) ?? [],
      status: jobTask.status,
      originalStatus: jobTask.status,
      version: jobTask.version,
      jobTaskId: jobTask.jobTaskId,
      taskNumber: jobTask.taskNumber
    };
    return {
      ...acc,
      [jobTask.assetId]: [...(acc[jobTask.assetId] ?? []), task]
    };
  }, {});
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export function TasksDNDComponent(props) {
  const {
    jobData,
    quoteService,
    jobService,
    user,
    setTasksDataForTable,
    handleJobTaskDelete,
    taskGroups,
    setTaskGroups,
    fetchJobData
  } = props;
  const classes = useStyles();
  const customerPropertyService = new CustomerPropertyService();

  async function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the task groups
    if (!destination) {
      return;
    }
    const sInd = source.droppableId;
    const dInd = destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(taskGroups[sInd], source.index, destination.index);
      const newState = [...taskGroups];
      newState[sInd] = items;
      setTaskGroups(newState);
      const changes = newState[sInd]
        .filter((item, index) => item !== taskGroups[sInd][index])
        .map(item => ({
          item,
          newIndex: newState[sInd].findIndex(elem => elem.id === item.id)
        }));
      try {
        const promises = changes.map(set => {
          const newItem = set.item;
          newItem.sortOrder = set.newIndex;
          return customerPropertyService.updateTask(user.tenantId, {
            id: newItem.id,
            version: newItem.version,
            sortOrder: newItem.sortOrder
          });
        });
        await Promise.all(promises).then(values => {
          values.forEach(response => {
            if (response?.data?.updateTask) {
              const data = response?.data?.updateTask;
              const index = newState[sInd].findIndex(elem => elem.id === data.id);
              newState[sInd][index].version = data.version;
              newState[sInd][index].sortOrder = data.newIndex;
            }
          });
        });
      } catch (e) {
        logErrorWithCallback(e, props.snackbarOn, 'Unable to update order');
      }
      setTaskGroups(newState);
    } else {
      const result = move(taskGroups[sInd], taskGroups[dInd], source, destination);
      const newState = [...taskGroups];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setTaskGroups(newState.filter(group => group.length));
    }
  }

  return (
    <>
      <Box className={classes.container} component="div">
        <DragDropContext onDragEnd={onDragEnd}>
          <Box justifyContent="center">
            <Droppable droppableId={0} key={0}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                  {taskGroups[0].map((item, index) => (
                    <TaskCard
                      handleJobTaskDelete={handleJobTaskDelete}
                      index={index}
                      item={item}
                      jobData={jobData}
                      setTaskGroups={setTaskGroups}
                      setTasksDataForTable={setTasksDataForTable}
                      taskGroups={taskGroups}
                      user={user}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <NewCardButton
              fetchJobData={fetchJobData}
              jobData={jobData}
              jobService={jobService}
              quoteService={quoteService}
              setTaskGroups={setTaskGroups}
              taskGroups={taskGroups}
              user={user}
            />
          </Box>
        </DragDropContext>
      </Box>
    </>
  );
}

function Tasks(props) {
  const { user, setTouched, touched, jobId, showTaskList = true, shouldDisallowEditing } = props;

  const jobService = new JobService();
  const quoteService = new QuoteService();
  const customerPropertyService = new CustomerPropertyService();

  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [jobData, setJobData] = useState({});
  const [tasks, setTasks] = useState([]);
  const [taskGroups, setTaskGroups] = useState([[]]);
  const [hasFetched, setHasFetched] = useState(false);
  const [jobTaskIds, setJobTaskIds] = useState([]);
  const [tasksDataForTable, setTasksDataForTable] = useState({ items: [], nextToken: '0' });
  const [updatingTasks, setUpdatingTasks] = useState(false);

  const [updateTasks] = useMutation(batchMutateJobTasks);

  const submitService = useRef(null);

  const { maintenanceTemplatesV2 } = useFlags();

  const formatTasks = data => {
    const jobTasks = data.jobTasks?.items
      ?.map(jobTask => ({ ...jobTask.task, jobTaskId: jobTask.id }))
      .filter(task => task.isActive !== null && task.isActive);

    return sortBy(jobTasks, 'sortOrder');
  };

  const getTasksByCustomerPropertyById = async (passedJobData, passedTaskIds) => {
    const localTaskIds = passedTaskIds || jobTaskIds;
    if (!user.tenantId || keys(passedJobData).length === 0) {
      return { items: [], nextToken: '0' };
    }
    try {
      const data = await customerPropertyService.getTasksByCustomerPropertyById(
        passedJobData.customerPropertyId
      );
      if (data?.items) {
        data.items = data.items.filter(
          task =>
            !localTaskIds.includes(task.id) &&
            (task.status === TaskConstants.OPEN || task.status === TaskConstants.PENDING)
        );
      }
      setTasksDataForTable(data.items);
      setHasFetched(true);
    } catch (error) {
      props.snackbarOn('error', 'Unable to fetch tasks, please try again later');
    }
  };

  const fetchJobData = async (includePendingTasks = true) => {
    try {
      setLoading(true);
      const { data } = await jobService.getJobDetailsInfoByJobNumber(`${props.jobData.jobNumber}`);
      setJobData(data.getJobByJobNumber);
      const jobTasks = formatTasks(data.getJobByJobNumber);

      setTasks(jobTasks);
      setTaskGroups([jobTasks || []]);
      setJobTaskIds(data.getJobByJobNumber?.jobTasks?.items?.map(element => element.task.id));

      if (includePendingTasks) {
        getTasksByCustomerPropertyById(data.getJobByJobNumber);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveTaskData = async removedTaskId => {
    const filteredTaskIds = jobTaskIds.filter(taskId => taskId !== removedTaskId);
    setJobTaskIds(filteredTaskIds);
    await getTasksByCustomerPropertyById(jobData, filteredTaskIds);
  };

  useEffect(() => {
    fetchJobData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowActions = (actionName, actionData) => {
    setSelectedItems(actionData);
  };

  const addTasksToJob = async () => {
    if (selectedItems.length) {
      setLoading(true);
      try {
        const data = {
          jobId: jobData.id,
          jobTasks: selectedItems.map(task => ({ taskId: task.id }))
        };
        const response = await jobService.addJobTasksToJob(jobData.partitionKey, data);
        if (response?.data) {
          const newState = [...taskGroups];
          const newJobTasks = [...jobTaskIds];
          response.data.addJobTasksToJob.forEach(jt => {
            newState[0].push({ ...jt.task, jobTaskId: jt.id });
            newJobTasks.push(jt.task.id);
          });
          setTaskGroups(newState);
          setJobTaskIds(newJobTasks);
          setSelectedItems([]);
          getTasksByCustomerPropertyById(jobData); // Refresh the Pending task table
          props.snackbarOn('success', 'Successfully added tasks to job');
        }
      } catch (e) {
        props.snackbarOn(
          'error',
          'Unable to add tasks to job. Please note that the same task' +
            " can't be added to multiple jobs.",
          e
        );
      }
      setLoading(false);
    }
  };

  const initialTasks = formatTasksInitialData(tasks);
  const initialAssetTasks = formatAssetTasksInitialData(tasks);

  const handleSubmit = async ({ assetTasks, tasks: tasksArray }) => {
    setTouched(false);
    setUpdatingTasks(true);
    try {
      const flattenedTasks = [
        ...flatten(keys(assetTasks).map(assetId => assetTasks[assetId])),
        ...tasksArray
      ];

      const params = {
        partitionKey: user.tenantId,
        data: {
          jobId,
          tasks: flattenedTasks.map((t, i) => ({
            id: t.version ? t.id : undefined, // only pass ids for tasks that already exist in the BE
            name: t.name,
            description: t.description,
            sortOrder: i,
            assetId: t.assetId,
            status: t.status,
            formData:
              t.forms?.map(f => ({
                // updateId will be undefined when adding a new form. updateId will only be defined for formData entries that already exist in the BE
                id: f.value?.updateId,
                formId: f.id,
                formDefinitionId: f.value?.formDefinitionId,
                formDefinitionSortKey: f.value?.formDefinitionSortKey,
                formSortKey: f.value?.formSortKey,
                isRequired: f.value?.required
              })) || [],
            taskEntries:
              t.partsAndMaterials?.map((p, pi) => ({
                id: p.value?.updateId, // updateId will be undefined for newly added  partsAndMaterials entries
                productId: p.value?.productId,
                name: p.value?.name,
                description: p.value?.description,
                sortOrder: pi,
                quantity: p.value?.quantity
              })) || []
          }))
        }
      };

      const response = await updateTasks({
        variables: params
      });

      const newTasks = response.data.batchMutateJobTasks;

      setTasks(newTasks);
    } catch (e) {
      Logger.error(e);
      props.snackbarOn('error', e.message || 'Failed to update tasks, please try again later', e);
      setTouched(true);
    }
    setUpdatingTasks(false);
  };

  return (
    <ErrorBoundaries>
      <ConfirmLeave when={touched} />
      <Grid item lg={12} md={12} sm={12} style={{ padding: 0 }} xl={12} xs={12}>
        {maintenanceTemplatesV2 ? (
          <div>
            {loading || updatingTasks ? (
              <Skeleton height={250} />
            ) : (
              <div>
                <TaskV2Tab
                  assetTasks={initialAssetTasks}
                  fetchJobData={fetchJobData}
                  headerButtons={[
                    () => (
                      <SgtButton
                        disabled={!touched || updatingTasks}
                        onClick={() => submitService.current?.submit()}
                      >
                        Save
                      </SgtButton>
                    )
                  ]}
                  jobId={jobId}
                  jobNumber={jobData?.jobNumber}
                  jobStatus={jobData.status}
                  jobTypeInternal={jobData.jobTypeInternal}
                  propertyId={jobData.customerPropertyId}
                  setSubmitService={service => {
                    submitService.current = service;
                  }}
                  setTouched={setTouched}
                  shouldDisallowEditing={shouldDisallowEditing}
                  showCheckmarks
                  tasks={initialTasks}
                  onSubmit={handleSubmit}
                />
              </div>
            )}
          </div>
        ) : (
          <TasksDNDComponent
            fetchJobData={fetchJobData}
            handleJobTaskDelete={handleRemoveTaskData}
            jobData={jobData}
            jobService={jobService}
            quoteService={quoteService}
            setTaskGroups={setTaskGroups}
            setTasksDataForTable={setTasksDataForTable}
            taskGroups={taskGroups}
            user={user}
          />
        )}
      </Grid>

      {showTaskList && !maintenanceTemplatesV2 && (
        <>
          <ThemeProvider>
            <Divider />
          </ThemeProvider>
          <Grid item lg={12} md={12} sm={12} style={{ margintop: 30 }} xl={12} xs={12}>
            <Typography className={classes.tableHeader} variant="body2">
              {Labels.pendingTasksProperty[user.locale]}
            </Typography>
            <ResponsiveTable
              data={tasksDataForTable}
              disableFilter
              isLoading={!hasFetched}
              noDataMsg="No tasks added to property"
              rowActionButtons={{
                select: {
                  referenceKey: 'id'
                }
              }}
              rowActions={handleRowActions}
              rowMetadata={taskListMeta}
            />
            <Box display="flex" flexDirection="row-reverse">
              <Button
                className={classes.addTaskToJobBtn}
                size="small"
                startIcon={<AddCircleOutlineIcon />}
                variant="contained"
                onClick={addTasksToJob}
              >
                {loading ? 'Adding to job...' : 'Add To Job'}
              </Button>
            </Box>
          </Grid>
        </>
      )}
    </ErrorBoundaries>
  );
}

const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const connectedTasks = connect(mapStateToProps, mapDispatcherToProps)(Tasks);
export default withStyles({ withTheme: true })(connectedTasks);
