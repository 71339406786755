import { DispatchTheme, ElementSizes } from '@dispatch/Dispatch.styles';

export const useStyles = () => ({
  root: {
    width: 0,
    flex: '1 1 0px',
    height: '100%',
    borderRight: DispatchTheme.mixins.mainBorder,
    borderBottom: DispatchTheme.mixins.mainBorder,
    display: 'flex',
    flexDirection: 'column'
  },
  loadingCell: {
    height: ElementSizes.cellHeight,
    width: '100%'
  }
});
