import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    color: theme.palette.grayscale(20),
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: '-0.03em'
  }
}));

const MuiFormSectionTitle = ({ options }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.sectionTitle} variant="subtitle1">
      {options.label}
    </Typography>
  );
};

export default MuiFormSectionTitle;
