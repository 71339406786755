import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const CREATE_VISIT_SUMMARIES = gql`
  mutation addSummariesToVisit(
    $partitionKey: String!
    $visitId: String!
    $summaries: [SummaryInput]!
  ) {
    addSummariesToVisit(
      partitionKey: $partitionKey
      data: { visitId: $visitId, summaries: $summaries }
    ) {
      id
      summary
      includeInInvoice
      lastUpdatedBy
      lastUpdatedDateTime
      version
    }
  }
`;

const serializer = ({ tenantId, visitId, summaries }) => ({
  variables: {
    partitionKey: tenantId,
    visitId,
    summaries
  }
});

// TODO: Temporarily disabled optimisticResponses because generating random ids
// is a pain
/*
const optimisticResponseFactory = ({ visitId, summaries }) => {
  const optimisticSummaries = summaries.map(summary => ({
    __typename: 'Summary',
    id: 'Temporary',
    summary: summary.content,
    version: summary.version + 1
  }));

  return {
    addSummariesToVisit: optimisticSummaries
  };
};
*/

const update = (cache, { data: { addSummariesToVisit } }, ref) => {
  cache.modify({
    id: `Visit:${ref.visitId}`,
    fields: {
      summaries(existingSummariesRef) {
        const newRefs = addSummariesToVisit.map(summary => {
          return {
            __ref: `${summary.__typename}:${summary.id}`
          };
        });

        return {
          items: [...existingSummariesRef.items, ...newRefs]
        };
      }
    }
  });
};

export const useCreateVisitSummary = () => {
  return useExtendedMutation(CREATE_VISIT_SUMMARIES, {
    serializer,
    // optimisticResponseFactory,
    update
  });
};
