import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import DateInTimezone from '@pm/ProjectDetails/FieldReport/DailyReportList/components/DateInTimezone';
import DefaultButton from 'components/Buttons/DefaultButton';
import ResponsiveTable from 'components/ResponsiveTable';
import SergeantModal from 'components/SergeantModal';
import dailyReportTableMeta from 'meta/ProjectManagement/FieldReport/dailyReportTable';
import simpleRowActionButtons from 'meta/ProjectManagement/global/simpleTableRowActionButton';
import { dailyReportDelete, getDailyReports } from 'services/API/dailyReport';

import GenerateDailyReport from './GenerateDailyReport';

const useStyles = makeStyles(() => ({
  tableContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px'
  }
}));

const DailyReportsList = () => {
  const classes = useStyles();
  const { mode, projectId } = useParams();
  const [dailyReports, setDailyReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenedModal, setIsOpenedModal] = useState(false);
  const [editReportId, setEditReportId] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState({
    confirmAction: () => {},
    confirmDialog: false,
    confirmTitle: ''
  });
  const timezone = useSelector(s => s.pm.timezone.timezone);

  const update = () => {
    getDailyReports(projectId).then(reports => {
      setDailyReports(reports.sort((a, b) => (a.number > b.number ? 1 : -1)));
      setIsLoading(false);
    });
  };

  const deleteAction = async (record, stopLoading) => {
    await dailyReportDelete(record.id);
    setConfirmDelete({ confirmAction: '', confirmDialog: false, confirmTitle: '' });
    update();
    stopLoading();
  };

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const handleCancelConfirmation = () =>
    setConfirmDelete({ confirmAction: '', confirmDialog: false, confirmTitle: '' });

  const handleReportRowactions = async (actionType, record) => {
    if (actionType === 'remove') {
      setConfirmDelete({
        confirmAction: (data, stopLoading) => deleteAction(record, stopLoading),
        confirmDialog: true,
        confirmTitle: `Delete Daily Report ${record.number}`
      });
    } else if (actionType === 'edit') {
      setEditReportId(record.id);
      setIsOpenedModal(true);
    }
  };

  return (
    <div>
      <div className={classes.tableContainer}>
        <DefaultButton
          buttonProps={{ startIcon: <AddCircleOutlineIcon style={{ fontSize: 14 }} /> }}
          key="generateDailyReportModalButton"
          label="Generate Daily Report"
          style={{ height: 30, fontSize: 12 }}
          variant="containedPrimary"
          onClick={() => setIsOpenedModal(true)}
        />
      </div>
      <ResponsiveTable
        customCellComponents={{
          // eslint-disable-next-line react/prop-types
          dateInCompanyTZ: ({ record }) => <DateInTimezone record={record} timezone={timezone} />
        }}
        data={dailyReports}
        disableFilter
        isLoading={isLoading}
        noDataMsg="No Daily Reports"
        rowActionButtons={simpleRowActionButtons}
        rowActions={handleReportRowactions}
        rowMetadata={dailyReportTableMeta(mode, projectId)}
      />
      {/* --------------- Daily Report Generate view for editing --------------- */}
      <GenerateDailyReport
        dailyReportId={editReportId}
        handleClose={() => {
          setIsOpenedModal(false);
          setEditReportId(null);
          update();
        }}
        open={isOpenedModal}
        projectId={projectId}
        update={update}
      />
      {/* --------------- Sergeant Modal for deleting an item --------------- */}
      <SergeantModal
        alignCloseRight
        customComponents={{}}
        customPrimaryButtonLabel="Delete"
        dataType="Item"
        handleClose={handleCancelConfirmation}
        handlePrimaryAction={confirmDelete.confirmAction}
        mode="delete"
        open={confirmDelete.confirmDialog}
        title={confirmDelete.confirmTitle}
      />
    </div>
  );
};

DailyReportsList.propTypes = {};
DailyReportsList.defaultProps = {};
export default DailyReportsList;
