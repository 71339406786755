import { truckTemplate } from 'constants/bundle';
import { PricebookService } from 'services/core';
import { Logger } from 'services/Logger';
import { convertStringToFloat, getBooleanValue } from 'utils';
import extractErrorMessage from 'utils/extractErrorMessage';
import minMaxValidation from 'utils/minMaxValidation';

const addProductToBundle = async ({
  user,
  product,
  bundle,
  productProperties,
  snackbar,
  successCallback
}) => {
  try {
    const { tenantId } = user;
    const { addProductEntriesToBundle } = new PricebookService();
    const payload = {
      productBundleId: bundle.id,
      productBundleEntries: {
        productId: product.id,
        name: product?.name,
        description: product?.description,
        taxable: getBooleanValue(product?.taxable) || false,
        unitCost: convertStringToFloat(productProperties?.unitCost || 0),
        quantity: convertStringToFloat(productProperties?.quantity || 0),
        quantityOnHand: convertStringToFloat(productProperties?.quantityOnHand || 0),
        unitOfMeasure: productProperties?.unitOfMeasure,
        maxQuantity: convertStringToFloat(productProperties?.maxQuantity || 0),
        minQuantity: convertStringToFloat(productProperties?.minQuantity || 0),
        isActive: true
      }
    };

    const isTruckTemplate = bundle.type === truckTemplate;
    const { isValid, message } = minMaxValidation(payload.productBundleEntries);
    if (isTruckTemplate && !isValid) return snackbar('error', message);

    await addProductEntriesToBundle(tenantId, payload);
    snackbar('success', `Successfully added ${product.name} to the bundle ${bundle.name}`);
    successCallback();
  } catch (error) {
    Logger.error(error);
    const errorMessage = extractErrorMessage(error, 'Unable to add product to bundle');
    snackbar('error', errorMessage);
  }
};

export { addProductToBundle };
