import { useEffect, useMemo, useRef } from 'react';

import intersection from 'lodash/intersection';
import { useHistory } from 'react-router-dom';

import { DB_LAZY_LOAD_THRESHOLD } from '@dispatch/Dispatch.constants';
import { watchedQueries } from '@dispatch/Dispatch.store';
import { DragScenarios } from '@dispatch/dnd';
import { useCrews, useDispatchTechs } from '@dispatch/queries';

import { greatest } from 'utils/greatest';

import {
  GET_DISPATCH_EVENTS_BY_TECH_IDS,
  resetDispatchEvents
} from './queries/useDispatchEventsByTechIds';

export const useFilteredTechnicians = ({ crews, departments, technicians }) => {
  // apply useFilteredTechnicians in the DataProvider so these queries do not trigger re-renders
  const techsResponse = useDispatchTechs();
  const crewsResponse = useCrews();

  return useMemo(() => {
    if (techsResponse.loading) return techsResponse;

    const crewsMap = crewsResponse.data.reduce((result, crew) => {
      const { id: crewId, techs, foremanId } = crew;
      const techIds = techs.map(({ id }) => id);
      const foremanIds = foremanId ? [foremanId] : [];
      return {
        ...result,
        [crewId]: [...techIds, ...foremanIds]
      };
    }, {});

    const crewsTechnicians = crews.reduce((result, crewId) => {
      const crewTechs = crewsMap[crewId] || [];
      return [...result, ...crewTechs];
    }, []);

    const data = techsResponse.data.filter(tech => {
      const techDepartments = tech?.departments;
      if (technicians?.includes(tech.id)) return true;
      if (intersection(departments, techDepartments).length) return true;
      if (crewsTechnicians?.includes(tech.id)) return true;
      return !(technicians?.length || departments?.length || crews?.length);
    });

    return {
      ...techsResponse,
      data
    };
  }, [crews, departments, technicians, crewsResponse, techsResponse]);
};

export const useFilteredDispatchEventsTuple = ({ lazyDispatchEventsTuple, flaggedVisits }) => {
  return useMemo(() => {
    const [trigger, response] = lazyDispatchEventsTuple;
    const { data } = response;
    if (flaggedVisits) {
      const filteredData = Object.keys(data).reduce((prevData, techId) => {
        const flaggedEvents = data[techId].filter(event => {
          // Non-visit events are not affected by flaggedVisits filter
          if (event?.__typename !== 'Visit') return true;
          return event?.visitFlags?.some(flag => !flag.isDismissed);
        });
        return { ...prevData, [techId]: flaggedEvents };
      }, {});
      return [trigger, { ...response, data: filteredData }];
    }

    return [trigger, response];
  }, [flaggedVisits, lazyDispatchEventsTuple]);
};

export const useVisitDragLifecycle = ({ dragScenario, primaryTech, extraTechs, filterBy }) => {
  const prevDragScenario = useRef();
  const history = useHistory();

  useEffect(() => {
    // drag start hook
    switch (dragScenario) {
      case DragScenarios.FULLY_ASSIGNED_SINGLE_TECH: {
        filterBy({ technicians: [primaryTech] });
        break;
      }
      case DragScenarios.FULLY_ASSIGNED_MULTI_TECH: {
        filterBy({ technicians: [primaryTech, ...extraTechs] });
        break;
      }
      case DragScenarios.NO_DRAG: {
        // drag end hook
        switch (prevDragScenario.current) {
          case DragScenarios.FULLY_ASSIGNED_SINGLE_TECH:
          case DragScenarios.FULLY_ASSIGNED_MULTI_TECH: {
            history.goBack();
            break;
          }
          default: {
            break;
          }
        }
        break;
      }
      default: {
        break;
      }
    }

    prevDragScenario.current = dragScenario;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragScenario]);
};

export const useIsLaneHighlighted = ({
  isOver,
  primaryTech,
  techId,
  isHighlightedTech,
  dragScenario,
  canDrop
}) => {
  return useMemo(() => {
    switch (dragScenario) {
      case DragScenarios.UNASSIGNED_SINGLE_TECH: {
        return isOver;
      }
      case DragScenarios.FULLY_ASSIGNED_SINGLE_TECH: {
        return primaryTech === techId;
      }
      case DragScenarios.VISIT_END:
      case DragScenarios.VISIT_START:
      case DragScenarios.EVENT_END:
      case DragScenarios.EVENT_START:
      case DragScenarios.MAN_DAY_BOARD_TO_BOARD:
      case DragScenarios.MAN_DAY_START:
      case DragScenarios.MAN_DAY_END:
      case DragScenarios.NON_VISIT_BOARD_TO_BOARD:
      case DragScenarios.VISIT_BOARD_TO_BOARD: {
        return isOver && canDrop;
      }
      case DragScenarios.FULLY_ASSIGNED_MULTI_TECH: {
        return canDrop;
      }
      case DragScenarios.NO_DRAG: {
        // highlighted techs set in appStore when hovering over an assigned draggable visit
        return isHighlightedTech;
      }
      default: {
        return false;
      }
    }
  }, [isOver, primaryTech, techId, isHighlightedTech, dragScenario, canDrop]);
};

export const useNewTechEventsOnDayChange = ({
  techIds,
  dispatchEventsResponse,
  infiniteLoader,
  queryDispatchEvents,
  startDateTime,
  endDateTime,
  isLazyLoadEnabled
}) => {
  useEffect(() => {
    if (techIds.length && infiniteLoader) {
      infiniteLoader.resetloadMoreItemsCache();

      resetDispatchEvents();

      const techsToFetch = isLazyLoadEnabled
        ? techIds.slice(
            infiniteLoader._lastRenderedStartIndex,
            greatest(infiniteLoader._lastRenderedStopIndex, DB_LAZY_LOAD_THRESHOLD)
          )
        : techIds;

      const variables = { startDateTime, endDateTime, techIds: techsToFetch };

      watchedQueries.useDispatchBoardEvents = {
        query: GET_DISPATCH_EVENTS_BY_TECH_IDS,
        variables
      };

      (dispatchEventsResponse.fetchMore || queryDispatchEvents)({
        variables: {
          data: variables
        }
      });
    }

    return () => {
      resetDispatchEvents();
    };
  }, [startDateTime, endDateTime, infiniteLoader, techIds, dispatchEventsResponse?.called]);
};
