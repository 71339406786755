import React from 'react';

import { connect } from 'react-redux';

import AddProductsWithSearch from 'components/AddProductsWithSearch';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { CustomerPropertyService } from 'services/core';
import { Logger } from 'services/Logger';

function TaskDetail(props) {
  const [data, setData] = React.useState([]);
  const [refreshCounter, setRefreshCounter] = React.useState(0);
  const customerPropertyServiceObj = new CustomerPropertyService();

  React.useEffect(() => {
    let ignore = false;
    async function fetchData() {
      if (!ignore) {
        let response;
        try {
          response = await customerPropertyServiceObj.getTaskById(props.record.id);
        } catch (error) {
          Logger.error(error);
          props.snackbarOn('error', 'Unable to fetch task items, please try again later');
        }
        setData(response || []);
      }
    }
    fetchData();
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addTaskEntryToTask = async values => {
    if (!values || !Array.isArray(values)) {
      Logger.error('Error in picking products');
      return;
    }
    const payload = {
      taskId: props.record.id,
      taskEntries: []
    };

    const updatePayload = {
      taskEntries: []
    };
    values.forEach(item => {
      const taskEntryItem = {
        isActive: true,
        productId: item.productId,
        name: item.name || null,
        description: item.description || null,
        unitPrice: item.unitPrice || 0,
        unitCost: item.unitCost || 0,
        markupType: item.markupType || 'Percentage',
        markupValue: item.markupValue || 0,
        quantity: Number(item.quantity) || 0,
        taxable: item.taxable
      };
      if (item.entityType === 'TaskEntry') {
        taskEntryItem.id = item.id;
        taskEntryItem.version = item.version;
        updatePayload.taskEntries.push(taskEntryItem);
      } else {
        payload.taskEntries.push(taskEntryItem);
      }
    });

    try {
      let positiveResponse = false;
      if (payload.taskEntries.length > 0) {
        const response = await customerPropertyServiceObj.addTaskEntriesToTask(
          props.user.tenantId,
          payload
        );
        if (response && response.data) {
          positiveResponse = true;
        }
      }

      if (updatePayload.taskEntries.length > 0) {
        const response = await customerPropertyServiceObj.updateTaskEntriesForTask(
          props.user.tenantId,
          updatePayload
        );
        if (response && response.data) {
          positiveResponse = true;
        }
      }

      if (positiveResponse) {
        const response = await customerPropertyServiceObj.getTaskById(props.record.id);
        setData(response);
        setRefreshCounter(refreshCounter + 1);
        await props.refetch();
        props.snackbarOn(
          'success',
          `Successfuly added products to the Task - ${props.record.name}`
        );
        props.handleClose();
      }
    } catch (error) {
      Logger.error(error);
      props.snackbarOn('error', 'Unable to add products to the bundle');
    }
  };

  const deleteTaskEntry = async product => {
    try {
      const deleteResp = await customerPropertyServiceObj.deleteTaskEntryFromTask(
        props.user.tenantId,
        product.id
      );
      if (deleteResp) {
        const response = await customerPropertyServiceObj.getTaskById(props.record.id);
        setData(response);
        setRefreshCounter(refreshCounter + 1);
        props.snackbarOn('success', `Successfully deleted product ${product.name}`);
      }
    } catch (error) {
      Logger.error(error);
      props.snackbarOn('error', 'Unable to delete product, please try again later', error);
    }
  };

  return (
    <ErrorBoundaries>
      <AddProductsWithSearch
        handleClose={props.handleClose}
        handleDeleteProduct={deleteTaskEntry}
        handleSave={addTaskEntryToTask}
        key={refreshCounter}
        overrideLabel="Search and add items"
        productArray={data.items}
        viewOnly={props.mode === 'view'}
      />
    </ErrorBoundaries>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const reduxConnectedTaskDetail = connect(mapStateToProps, mapDispatcherToProps)(TaskDetail);

export default reduxConnectedTaskDetail;
