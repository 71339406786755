import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';

const GET_RECOMMENDED_TASK_LIST = gql`
  query getRecommendedTaskList(
    $tenantId: String
    $filter: TableFilterInput
    $pagination: PaginationInput
    $sorting: [TableSortingInput]
  ) {
    data: getRecommendedTaskList(
      tenantId: $tenantId
      filter: $filter
      pagination: $pagination
      sorting: $sorting
    ) {
      rowCount
      items
    }
  }
`;

export default GET_RECOMMENDED_TASK_LIST;

const transform = response => {
  return response?.data || {};
};

export const useRecommendedTaskList = id => {
  return useExtendedQuery(GET_RECOMMENDED_TASK_LIST, {
    transform,
    variables: {
      id
    }
  });
};
