/* eslint-disable import/prefer-default-export */
export const PropertyAssetLayout = (assetTypeOptions, isModal = false, hasServiceAgreements) => ({
  fields: {
    imageUrl: {
      default: 'imageUrl',
      edit: 'imageUrl'
    },
    assetName: {
      default: 'assetName',
      edit: 'assetName'
    },
    assetType: {
      default: 'assetType',
      edit: 'assetTypeId'
    },
    make: {
      default: 'make',
      edit: 'make'
    },
    modelNumber: {
      default: 'modelNumber',
      edit: 'modelNumber'
    },
    serialNo: {
      default: 'serialNo',
      edit: 'serialNo'
    },
    installDate: {
      default: 'installDate',
      edit: 'installDate'
    },
    location: {
      default: 'location',
      edit: 'location'
    },
    propertyZone: {
      default: 'propertyZone',
      edit: 'propertyZone'
    },
    customer: {
      default: 'property.customer.customerName'
    },
    property: {
      default: 'property.companyName'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          component: {
            edit: 'OcrButton',
            default: null
          }
        },
        {
          options: {
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 0,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'LogoButton',
                    default: 'ImageControl'
                  },
                  source: 'imageUrl',
                  options: {
                    label: 'Image',
                    placeholder: '',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                size: 'auto',
                grow: 0,
                shrink: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'AssetTitle'
                  },
                  source: 'assetName',
                  options: {
                    label: 'Asset Name',
                    placeholder: '',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                size: '40%',
                grow: 0,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'SelectInput',
                    new: 'SelectInput',
                    default: null
                  },
                  source: 'assetTypeId',
                  options: {
                    label: 'Asset Type',
                    fullWidth: true,
                    inputOptions: assetTypeOptions,
                    alignment: 'left',
                    enableSort: false,
                    isRequired: true
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12,
                width: '20%'
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'make',
                  options: {
                    label: 'Make',
                    placeholder: '',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12,
                width: '20%'
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'modelNumber',
                  options: {
                    label: 'Model',
                    placeholder: '',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12,
                width: '20%'
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'serialNo',
                  options: {
                    label: 'Serial',
                    placeholder: '',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12,
                width: '20%'
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'customer',
                  options: {
                    label: 'Customer',
                    placeholder: '',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12,
                hide: isModal
              },
              contents: [
                {
                  component: {
                    edit: null,
                    default: 'FieldWithLabel'
                  },
                  source: 'assetType.tagName',
                  options: {
                    label: 'Asset Type',
                    placeholder: '',
                    fullWidth: true,
                    inputOptions: assetTypeOptions,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12,
                width: '20%'
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'ownerIdentifier',
                  options: {
                    label: 'Owner ID',
                    placeholder: '',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12,
                width: '20%'
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'internalIdentifier',
                  options: {
                    label: 'Internal Asset ID',
                    placeholder: '',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12,
                width: '20%'
              },
              contents: [
                {
                  component: {
                    edit: 'DateInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'installDate',
                  options: {
                    label: 'Install Date',
                    placeholder: '',
                    fullWidth: true,
                    alignment: 'left',
                    searchableCategory: 'date'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12,
                width: '20%'
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'property',
                  options: {
                    label: 'Property',
                    placeholder: '',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            // to manage spacing
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12,
                width: '20%',
                hide: isModal
              },
              contents: []
            }
          ]
        },
        {
          options: {
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12,
                width: '20%'
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'location',
                  options: {
                    label: 'Asset Location',
                    placeholder: '',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12,
                width: '20%'
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'propertyZone',
                  options: {
                    label: 'Property Zone Served',
                    placeholder: '',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12,
                width: '20%',
                hide: !hasServiceAgreements
              },
              contents: [
                {
                  component: {
                    default: 'LinkFieldWithLabel'
                  },
                  source: 'serviceAgreementDisplays',
                  options: {
                    label: 'Service Agreements'
                  }
                }
              ]
            },
            // to manage spacing
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12,
                width: '20%',
                hide: isModal
              },
              contents: []
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      assetName: {
        type: 'string'
      },
      assetTypeId: {
        type: 'string'
      }
    },
    required: ['assetName', 'assetTypeId']
  },
  validationErrors: {
    assetName: {
      required: 'Asset name is required.'
    },
    assetTypeId: {
      required: 'Asset Type is required.'
    }
  }
});
