import gql from 'graphql-tag';

import { attachmentFragment } from 'scenes/Quotes/queries/queryFragments';

const getQuoteTasksById = gql`
  query getQuoteById($id: String!, $sort: [SortingInput]) {
    getQuoteById(id: $id) {
      id
      quoteLineTasks(entityConnection: "QuoteLineTask") {
        nextToken
        items {
          id
          version
          entityType
          sortKey
          taskId
          unitPrice
          unitCost
          quantity
          taxable
          markupType
          markupValue
          name
          createdBy
          createdDateTime
          sortOrder
          description
          task(entityConnection: "Task"){
            isRecommended
            attachments {
              items {
                ${attachmentFragment}
              }
            }
            formData {
              items {
                id
                formId
                formDataJson
                lastUpdatedDateTime
                lastUpdatedBy
                sortKey
                version
              }
            }
          }
          subtotal: aggregatedField(
            input: {
              entityConnection: "QuoteLineProduct"
              isDirectChildren: true
              aggregations: [
                {
                  aggregationType: SUM
                  aggregationField: "QuoteLineProduct.unitPrice * QuoteLineProduct.quantity"
                }
              ]
            }
          ) {
            items {
              total: aggregation1
            }
          }
          taxableTotal: aggregatedField(
            input: {
              entityConnection: "QuoteLineProduct"
              isDirectChildren: true
              aggregations: [
                {
                  aggregationType: SUM
                  aggregationField: "QuoteLineProduct.unitPrice * QuoteLineProduct.quantity"
                }
              ]
              filter: {
                booleanFilters: { fieldName: "QuoteLineProduct.taxable", filterInput: { eq: true } }
              }
            }
          ) {
            items {
              total: aggregation1
            }
          }
          products: quoteLineProductsView(entityConnection: "QuoteLineProduct", sorting: $sort) {
            nextToken
            items {
              id
              version
              productId
              sortKey
              entityType
              name
              sortOrder
              description
              unitPrice
              unitCost
              quantity
              markupType
              markupValue
              taxable
              sortOrder
              priceBookEntryId
            }
          }
        }
      }
    }
  }
`;
export default getQuoteTasksById;
