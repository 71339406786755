const RolesData = () => [
  {
    roleName: 'Technician',
    permissions: [
      {
        category: 'Data',
        subcategory: 'View Labor Rates',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View all Job Notes on Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View all Job Attachments on Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View Cost Data',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View Price Data',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'Show Financial Data',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View and Edit Employee Hourly Rates',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'Access to Data Warehouse',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Settings',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Reports And Dashboards',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Follow ups',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Dispatch',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Lists',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Accounting',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Timesheets',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Assigned Tasks (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Pending Tasks (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Pending Tasks to Work (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'TimeTrackingReport',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Job complete from mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Web',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Dispatchable',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Admin',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Job tag on mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Customer',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Property',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Job',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Task',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Visit',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Asset',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Quotes',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Employee',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Invoice',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'ReviewReport',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Timesheets',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      }
    ].filter(Boolean)
  },
  {
    roleName: 'Dispatcher',
    permissions: [
      {
        category: 'Data',
        subcategory: 'View Labor Rates',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View all Job Notes on Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View all Job Attachments on Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View Cost Data',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View Price Data',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'Access to Data Warehouse',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Settings',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Reports And Dashboards',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Follow ups',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Dispatch',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Lists',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Accounting',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Timesheets',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Payroll Settings',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Workflows',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Procurement',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Project Management',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Assigned Tasks (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Pending Tasks (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Pending Tasks to Work (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'TimeTrackingReport',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Job complete from mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Web',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Dispatchable',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Admin',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Job tag on mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Customer',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Property',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Job',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Task',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Visit',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Asset',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Quotes',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Employee',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Invoice',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'ReviewReport',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Timesheets',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Workflows',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Product',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Truck',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'TimetrackingSetting',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'ServiceAgreement',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PurchaseOrder',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Dashboard',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM FieldReport',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM RFI',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM ChangeOrder',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Submittals',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Purchasing',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Finance',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM ProjectSettings',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      }
    ].filter(Boolean)
  },
  {
    roleName: 'Project Manager',
    permissions: [
      {
        category: 'Data',
        subcategory: 'View Labor Rates',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View all Job Notes on Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View all Job Attachments on Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View Cost Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View Price Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'Show Financial Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View and Edit Employee Hourly Rates',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'Access to Data Warehouse',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Settings',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Reports And Dashboards',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Follow ups',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Dispatch',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Lists',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Accounting',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Timesheets',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Payroll Settings',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Workflows',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Procurement',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Project Management',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Assigned Tasks (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Pending Tasks (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Pending Tasks to Work (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'TimeTrackingReport',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Job complete from mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Web',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Dispatchable',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Admin',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Job tag on mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Customer',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Property',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Job',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Task',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Visit',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Asset',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Quotes',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Employee',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Invoice',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'ReviewReport',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Timesheets',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Workflows',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Product',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Truck',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'TimetrackingSetting',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'ServiceAgreement',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PurchaseOrder',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Dashboard',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM FieldReport',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM RFI',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM ChangeOrder',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Submittals',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Purchasing',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Finance',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM ProjectSettings',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      }
    ].filter(Boolean)
  },
  {
    roleName: 'Project Lead',
    permissions: [
      {
        category: 'Data',
        subcategory: 'View Labor Rates',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View all Job Notes on Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View all Job Attachments on Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View Cost Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View Price Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'Show Financial Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View and Edit Employee Hourly Rates',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'Access to Data Warehouse',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Settings',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Reports And Dashboards',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Follow ups',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Dispatch',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Lists',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Accounting',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Timesheets',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Payroll Settings',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Workflows',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Procurement',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Project Management',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Assigned Tasks (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Pending Tasks (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Pending Tasks to Work (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'TimeTrackingReport',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Job complete from mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Web',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Dispatchable',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Admin',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Job tag on mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Customer',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Property',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Job',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Task',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Visit',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Asset',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Quotes',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Employee',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Invoice',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'ReviewReport',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Timesheets',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Workflows',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Product',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Truck',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'TimetrackingSetting',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'ServiceAgreement',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PurchaseOrder',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Dashboard',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM FieldReport',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM RFI',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM ChangeOrder',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Submittals',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Purchasing',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Finance',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM ProjectSettings',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      }
    ].filter(Boolean)
  },
  {
    roleName: 'Office Manager',
    permissions: [
      {
        category: 'Data',
        subcategory: 'View Labor Rates',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View all Job Notes on Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View all Job Attachments on Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View Cost Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View Price Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'Show Financial Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View and Edit Employee Hourly Rates',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'Access to Data Warehouse',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Settings',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Reports And Dashboards',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Follow ups',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Dispatch',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Lists',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Accounting',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Timesheets',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Payroll Settings',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Workflows',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Procurement',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Project Management',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Assigned Tasks (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Pending Tasks (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Pending Tasks to Work (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'TimeTrackingReport',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Job complete from mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Web',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Dispatchable',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Admin',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Job tag on mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Customer',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Property',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Job',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Task',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Visit',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Asset',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Quotes',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Employee',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Invoice',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'ReviewReport',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Timesheets',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Workflows',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Product',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Truck',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'TimetrackingSetting',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'ServiceAgreement',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PurchaseOrder',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Dashboard',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM FieldReport',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM RFI',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM ChangeOrder',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Submittals',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Purchasing',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Finance',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM ProjectSettings',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      }
    ].filter(Boolean)
  },
  {
    roleName: 'Finance Manager',
    permissions: [
      {
        category: 'Data',
        subcategory: 'View Labor Rates',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View all Job Notes on Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View all Job Attachments on Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View Cost Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View Price Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'Show Financial Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View and Edit Employee Hourly Rates',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'Access to Data Warehouse',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Settings',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Reports And Dashboards',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Follow ups',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Dispatch',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Lists',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Accounting',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Timesheets',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Payroll Settings',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Workflows',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Procurement',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Project Management',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Assigned Tasks (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Pending Tasks (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Pending Tasks to Work (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'TimeTrackingReport',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Job complete from mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Web',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Dispatchable',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Admin',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Job tag on mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Customer',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Property',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Job',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Task',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Visit',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Asset',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Quotes',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Employee',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Invoice',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'ReviewReport',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Timesheets',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Workflows',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Product',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Truck',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'TimetrackingSetting',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'ServiceAgreement',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PurchaseOrder',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Dashboard',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM FieldReport',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM RFI',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM ChangeOrder',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Submittals',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Purchasing',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Finance',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM ProjectSettings',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      }
    ].filter(Boolean)
  },
  {
    roleName: 'Office Head',
    permissions: [
      {
        category: 'Data',
        subcategory: 'View Labor Rates',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View all Job Notes on Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View all Job Attachments on Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View Cost Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View Price Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'Show Financial Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View and Edit Employee Hourly Rates',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'Access to Data Warehouse',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Settings',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Reports And Dashboards',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Follow ups',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Dispatch',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Lists',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Accounting',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Timesheets',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Payroll Settings',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Workflows',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Procurement',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Project Management',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Assigned Tasks (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Pending Tasks (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Pending Tasks to Work (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'TimeTrackingReport',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Job complete from mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Web',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Dispatchable',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Admin',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Job tag on mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Customer',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Property',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Job',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Task',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Visit',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Asset',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Quotes',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Employee',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Invoice',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'ReviewReport',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Timesheets',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Workflows',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Product',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Truck',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'TimetrackingSetting',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'ServiceAgreement',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PurchaseOrder',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Dashboard',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM FieldReport',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM RFI',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM ChangeOrder',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Submittals',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Purchasing',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Finance',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM ProjectSettings',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      }
    ].filter(Boolean)
  },
  {
    roleName: 'Administrator',
    permissions: [
      {
        category: 'Data',
        subcategory: 'View Labor Rates',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View all Job Notes on Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View all Job Attachments on Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View Cost Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View Price Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'Show Financial Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View and Edit Employee Hourly Rates',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'Access to Data Warehouse',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Settings',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Reports And Dashboards',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Follow ups',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Dispatch',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Lists',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Accounting',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Timesheets',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Payroll Settings',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Workflows',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Procurement',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Project Management',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Assigned Tasks (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Pending Tasks (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Pending Tasks to Work (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'TimeTrackingReport',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Job complete from mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Web',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Dispatchable',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Admin',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Job tag on mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Customer',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Property',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Job',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Task',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Visit',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Asset',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Quotes',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Employee',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Invoice',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'ReviewReport',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Timesheets',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Workflows',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Product',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Truck',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'TimetrackingSetting',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'ServiceAgreement',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PurchaseOrder',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Dashboard',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM FieldReport',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM RFI',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM ChangeOrder',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Submittals',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Purchasing',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Finance',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM ProjectSettings',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      }
    ].filter(Boolean)
  },
  {
    roleName: 'Sales Manager',
    permissions: [
      {
        category: 'Data',
        subcategory: 'View Labor Rates',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View all Job Notes on Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View all Job Attachments on Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View Cost Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View Price Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'Show Financial Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View and Edit Employee Hourly Rates',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'Access to Data Warehouse',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Settings',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Reports And Dashboards',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Follow ups',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Dispatch',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Lists',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Accounting',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Timesheets',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Payroll Settings',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Workflows',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Procurement',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Project Management',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Assigned Tasks (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Pending Tasks (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Pending Tasks to Work (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'TimeTrackingReport',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Job complete from mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Web',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Dispatchable',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Admin',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Job tag on mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Customer',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Property',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Job',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Task',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Visit',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Asset',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Quotes',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Employee',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Invoice',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'ReviewReport',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Timesheets',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Workflows',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Product',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Truck',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'TimetrackingSetting',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'ServiceAgreement',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PurchaseOrder',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Dashboard',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM FieldReport',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM RFI',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM ChangeOrder',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Submittals',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Purchasing',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Finance',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM ProjectSettings',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      }
    ].filter(Boolean)
  },
  {
    roleName: 'Sales Engineer',
    permissions: [
      {
        category: 'Data',
        subcategory: 'View Labor Rates',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View all Job Notes on Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View all Job Attachments on Mobile',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View Cost Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View Price Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'Show Financial Data',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'View and Edit Employee Hourly Rates',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Data',
        subcategory: 'Access to Data Warehouse',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Settings',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Reports And Dashboards',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Follow ups',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Dispatch',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Lists',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Accounting',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Timesheets',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Payroll Settings',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Workflows',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Procurement',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Menu',
        subcategory: 'Project Management',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Assigned Tasks (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Pending Tasks (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Add Pending Tasks to Work (Mobile)',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'TimeTrackingReport',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Job complete from mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Web',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Dispatchable',
        actions: [
          {
            actionName: 'allow',
            value: true
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Admin',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Functions',
        subcategory: 'Job tag on mobile',
        actions: [
          {
            actionName: 'allow',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Customer',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Property',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Job',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Task',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Visit',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Asset',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Quotes',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Employee',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Invoice',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'ReviewReport',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Timesheets',
        actions: [
          {
            actionName: 'create',
            value: true
          },
          {
            actionName: 'update',
            value: true
          },
          {
            actionName: 'delete',
            value: true
          },
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Workflows',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Product',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'Truck',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'TimetrackingSetting',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'ServiceAgreement',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PurchaseOrder',
        actions: [
          {
            actionName: 'create',
            value: false
          },
          {
            actionName: 'update',
            value: false
          },
          {
            actionName: 'delete',
            value: false
          },
          {
            actionName: 'read',
            value: false
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Dashboard',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM FieldReport',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM RFI',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM ChangeOrder',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Submittals',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Purchasing',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM Finance',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      },
      {
        category: 'Object',
        subcategory: 'PM ProjectSettings',
        actions: [
          {
            actionName: 'read',
            value: true
          }
        ]
      }
    ].filter(Boolean)
  }
];

export default RolesData;
