import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';

export const MergedTaskTemplateFragment = `
  id
  isActive
  name
  description
  numberOfOccurrences
  startDateTime
  interval
  laborEstimate
  isSingleJob
  schedules {
    __typename
    ... on IntervalSchedule {
      dayOfMonth
      monthOfYear
      year
    }
  }
  forms {
    id
    isRequired
  }
  parts {
    items {
      id
      productId
      productDescription
      quantity
      product {
        id
        name
        code
      }
    }
    nextToken
  }
  editType
`;

export const taskTemplateChecklistsOnServiceAgreement = gql`
  query taskTemplateChecklistsOnServiceAgreement($serviceAgreementId: String!, $propertyAssetId: String, $customerPropertyId: String) {
    taskTemplateChecklistsOnServiceAgreement(serviceAgreementId: $serviceAgreementId, propertyAssetId: $propertyAssetId, customerPropertyId: $customerPropertyId) {
      id
      name
      assetTypeId
      isDefault
      mergedTaskTemplates {
        ${MergedTaskTemplateFragment}
      }
    }
  }
`;

export const useTaskTemplateChecklistsOnServiceAgreement = (
  { serviceAgreementId, propertyAssetId, customerPropertyId } = {},
  options = {}
) => {
  const params = { serviceAgreementId };
  if (propertyAssetId) {
    params.propertyAssetId = propertyAssetId;
  } else {
    params.customerPropertyId = customerPropertyId;
  }

  return useExtendedQuery(taskTemplateChecklistsOnServiceAgreement, {
    variables: params,
    transform: result => result?.taskTemplateChecklistsOnServiceAgreement,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options
  });
};

export default useTaskTemplateChecklistsOnServiceAgreement;
