import React, { useMemo, useState } from 'react';

import {
  Button,
  Modal,
  Select,
  Typography as SgtTypography,
  TH,
  ThemeProvider,
  TV,
  TW
} from '@BuildHero/sergeant';

import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { StatusChip } from 'components';
import ManualStatusChangeModal from 'components/ManualStatusChangeModal/index';
import useUpdateJob from 'scenes/JobCloseout/JobCloseoutHeader/hooks/useUpdateJob';
import { getQuoteStatus } from 'scenes/JobCloseout/utils';
import { JobService } from 'services/core';
import { logErrorWithCallback } from 'utils';
import { EnumType } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import AttachmentsForJob from '../AttachmentsForJob';
import PageHeaderUtils from '../index.utils';

const ExistingJobModal = ({
  classes,
  existingJobOptions,
  flags,
  handleAddExistingJobToQuote,
  loading,
  openExistingJobModal,
  propertyDetails,
  quoteAttachments,
  quoteInfo,
  selectedJob,
  setOpenExistingJobModal,
  setSelectedJob,
  snackbar
}) => {
  const [fields, setFields] = useState({ copyFields: [], doNotCopyFields: [] });
  const [formService, setFormService] = useState();
  const [updateJob] = useUpdateJob();
  const [showBillingCustomerWarning, setShowBillingCustomerWarning] = useState(false);
  const [openQuoteStatusChangeModal, setOpenQuoteStatusChangeModal] = useState(false);
  const [quoteStatusModalData, setQuoteStatusModalData] = useState(null);

  // Custom options for select so status chip and styles can be applied
  const formattedOptionLabel = ({ label, status }, { context }) => {
    if (context === 'menu') {
      return (
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <div style={{ marginRight: 12 }}>
            <Typography style={{ fontSize: 10 }}>JOB</Typography>
            <Typography style={{ fontWeight: 'bold' }}>{label}</Typography>
          </div>
          <StatusChip
            enumType={EnumType.JOB_STATUS}
            enumValue={status}
            label={status}
            style={{ borderRadius: 2 }}
          />
        </div>
      );
    }

    if (context === 'value') {
      return <Typography>{`Job ${label}`}</Typography>;
    }
  };

  const FieldsComponent = (fieldsArr, type) =>
    fieldsArr.length > 0 && (
      <Box>
        <SgtTypography
          style={{ paddingTop: 16, paddingBottom: 12, color: '#999999' }}
          variant={TV.S2}
        >
          {type === 'copy'
            ? 'The following fields will be copied to the Job:'
            : 'The following fields are already populated on the Job and will not be populated from this Quote:'}
        </SgtTypography>
        {fieldsArr.map(field => (
          <SgtTypography height={TH.COMFORTABLE} key={field} variant={TV.BASE} weight={TW.REGULAR}>
            {field}
          </SgtTypography>
        ))}
      </Box>
    );

  const handleJobSelect = async evt => {
    setSelectedJob(evt);
    if (!evt) {
      setShowBillingCustomerWarning(false);
      return;
    }
    try {
      const { data } = await new JobService().getJobDetailsInfoByJobNumber(evt.jobNumber);
      if (data?.getJobByJobNumber?.manualQuoteStatus) {
        setQuoteStatusModalData({
          manualStatus: data?.getJobByJobNumber?.manualQuoteStatus,
          automatedStatus: getQuoteStatus([
            ...data?.getJobByJobNumber?.quoteJobs?.items,
            { quote: { status: quoteInfo?.status } }
          ]),
          id: data?.getJobByJobNumber?.id,
          version: data?.getJobByJobNumber?.version
        });
      } else {
        setQuoteStatusModalData(null);
      }

      const {
        authorizedById: companyRepOnJob,
        costAmount: costAmountOnJob,
        departments: departmentsOnJob,
        ownerId: projectManagerOnJob,
        billingCustomerId: billingCustomerOnJob,
        soldById: soldByOnJob
      } = data.getJobByJobNumber;
      const { id: propertyRepOnJob } = data.getJobByJobNumber.customerRep || {};
      const {
        departmentId: departmentOnQuote,
        orderedById: companyRepOnQuote,
        ownerId: projectManagerOnQuote,
        propertyRepId: propertyRepOnQuote,
        salesById: soldByOnQuote,
        totalEstimatedCost: costAmountOnQuote
      } = quoteInfo;

      const billingCustomerOnQuote = propertyDetails.property.billingCustomerId;
      const addingQuoteFromJob =
        departmentOnQuote &&
        !departmentsOnJob.items.some(dept => dept.mappedEntityId === departmentOnQuote);

      if (billingCustomerOnJob !== billingCustomerOnQuote) {
        setShowBillingCustomerWarning(true);
      }
      const { copyFields, doNotCopyFields } = PageHeaderUtils.composeFieldsForJob({
        addingQuoteFromJob,
        companyRepOnQuote,
        companyRepOnJob,
        costAmountOnJob,
        costAmountOnQuote,
        departmentOnQuote,
        flags,
        projectManagerOnJob,
        projectManagerOnQuote,
        propertyRepOnJob,
        propertyRepOnQuote,
        soldByOnJob,
        soldByOnQuote
      });
      setFields({ copyFields, doNotCopyFields });
    } catch (error) {
      logErrorWithCallback(error, snackbar, 'Unable to add quote to existing job');
    }
  };

  const ExistingJobsComponent = useMemo(
    () => (
      <Select
        clearable
        formatOptionLabel={formattedOptionLabel}
        menuHeight={175}
        options={existingJobOptions}
        placeholder="Search & Select Job"
        searchable
        subtext={
          showBillingCustomerWarning
            ? 'Billing customer on the selected job is different from this quote'
            : null
        }
        value={selectedJob}
        warning={showBillingCustomerWarning}
        onChange={handleJobSelect}
      />
    ),
    [existingJobOptions, selectedJob, showBillingCustomerWarning]
  );

  const submit = () => {
    if (formService?.formikContext?.handleSubmit) formService?.formikContext?.handleSubmit();
    else handleAddExistingJobToQuote();
  };

  const checkJobQuoteStatusChange = () => {
    if (flags[FeatureFlags.JOB_QUOTE_STATUS] && quoteStatusModalData) {
      setOpenQuoteStatusChangeModal(true);
    } else {
      submit();
    }
  };

  const updateJobAndSubmit = async () => {
    await updateJob({
      id: quoteStatusModalData?.id,
      version: quoteStatusModalData?.version,
      manualQuoteStatus: null
    });
    submit();
  };

  return (
    <ThemeProvider>
      <Modal
        actions={
          <Button
            disabled={!selectedJob}
            fullWidth
            loading={loading}
            style={{ marginTop: '1rem' }}
            onClick={checkJobQuoteStatusChange}
          >
            Continue
          </Button>
        }
        open={openExistingJobModal}
        PaperProps={{
          style: { minWidth: 454, minHeight: 375, overflowY: 'auto' },
          className: classes.existingJobModal
        }}
        title="Add Quote to Existing Job"
        onClose={() => setOpenExistingJobModal(false)}
      >
        {ExistingJobsComponent}
        {selectedJob && (
          <ThemeProvider>
            {FieldsComponent(fields.copyFields, 'copy')}
            {FieldsComponent(fields.doNotCopyFields)}
            {flags[FeatureFlags.QUOTE_ATTACHMENTS_ENHANCEMENTS] && (
              <Box style={{ paddingTop: 24 }}>
                <AttachmentsForJob
                  handleSubmit={handleAddExistingJobToQuote}
                  quoteAttachments={quoteAttachments}
                  setFormService={setFormService}
                />
              </Box>
            )}
          </ThemeProvider>
        )}
      </Modal>
      {flags[FeatureFlags.JOB_QUOTE_STATUS] && (
        <ManualStatusChangeModal
          data={quoteStatusModalData}
          handleClose={xButtonClosed => {
            if (!xButtonClosed) {
              submit();
            }
            setOpenQuoteStatusChangeModal(false);
          }}
          handleSubmit={() => {
            updateJobAndSubmit();
            setOpenQuoteStatusChangeModal(false);
          }}
          open={openQuoteStatusChangeModal}
          statusEnumType={EnumType.JOB_QUOTE_STATUS}
          title="Update Quote Status"
        />
      )}
    </ThemeProvider>
  );
};

export default ExistingJobModal;

ExistingJobModal.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAddExistingJobToQuote: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  openExistingJobModal: PropTypes.bool.isRequired,
  selectedJob: PropTypes.object,
  setOpenExistingJobModal: PropTypes.func.isRequired
};

ExistingJobModal.defaultProps = {
  selectedJob: {}
};
