import React, { useEffect, useState } from 'react';

import { Select, Typography } from '@BuildHero/sergeant';
import { Box, TextField } from '@material-ui/core';
import { func, shape, string } from 'prop-types';

import ItemActions from '../../../../ItemActions';

import { directoryPermissions } from '../../ProjectFileStorageSettings.constants';

import useStyles from './ProjectFileStorageSettingItem.styles';

const ProjectFileStorageSettingItem = ({ setting, onSettingChange, onSettingSave }) => {
  const classes = useStyles();

  const [originalSetting, setOriginalSetting] = useState(setting);

  useEffect(() => {
    if (!setting.isBeingEdited) {
      setOriginalSetting(setting);
    }
  }, [setting]);

  const permissions = directoryPermissions.filter(permission =>
    setting.permissions?.includes(permission.value)
  );

  const handleSettingSave = () => {
    onSettingSave();
  };

  const handleSettingCancel = () => {
    onSettingChange({ ...originalSetting, isBeingEdited: false });
  };

  const handleDirectoryNameChange = event => {
    const directoryName = event.target.value || '';
    onSettingChange({ ...setting, directoryName, isBeingEdited: true });
  };

  const handlePermissionChange = options => {
    const newPermissions = options?.map(option => option?.value) || [];
    onSettingChange({
      ...setting,
      permissions: newPermissions,
      isBeingEdited: true
    });
  };

  return (
    <>
      <Box className={classes.itemRow} display="flex">
        <Box>
          <Typography className={classes.textLabel}>&nbsp;</Typography>
          <TextField
            InputProps={{
              className: classes.textInput,
              disableUnderline: true
            }}
            placeholder="Folder Name"
            value={setting.directoryName || ''}
            onChange={handleDirectoryNameChange}
          />
        </Box>
        <Box>
          <Typography className={classes.textLabel}>Access</Typography>
          <Select
            className={classes.dropdown}
            clearable
            multi
            options={directoryPermissions}
            placeholder="Folder Permission"
            testingid="PermissionDropdown"
            value={permissions}
            onChange={handlePermissionChange}
          />
        </Box>
        <div>
          <Typography className={classes.textLabel}>&nbsp;</Typography>
          <ItemActions
            handleCancel={handleSettingCancel}
            handleSave={handleSettingSave}
            isVisible={setting.isBeingEdited}
          />
        </div>
      </Box>
    </>
  );
};

ProjectFileStorageSettingItem.propTypes = {
  setting: shape({
    directoryName: string.isRequired,
    permission: string.isRequired
  }).isRequired,
  onSettingChange: func.isRequired,
  onSettingSave: func.isRequired
};

export default ProjectFileStorageSettingItem;
