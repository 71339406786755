/* eslint-disable camelcase */
import React, { useMemo } from 'react';

import { Modal, ThemeProvider } from '@BuildHero/sergeant';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';

import { PageForm, ResponsiveTable } from 'components';

import useLabourTypeDependency from 'customHooks/useLabourTypeDependency';
import { billItemsRows } from 'meta/Jobs/Invoice/review-report-tables';
import BillItemLayout from 'meta/Jobs/ReviewReport/BillLineForm';
import ErrorBoundaries from 'scenes/Error';
import { validations } from 'services/core';
import { setTitleForPageForm } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';

import { getMarkupFromMargin } from 'utils/onCalcChange';

import RowActions from './ReviewReportRowActions';

const useStyles = makeStyles(() => ({
  customCurrency: {
    fontSize: '1em',
    fontWeight: '700',
    textAlign: 'right',
    alignSelf: 'right'
  }
}));

const CustomCurrency = valueProps => {
  const classes = useStyles();
  const { record, meta } = valueProps;
  return (
    <Typography className={classes.customCurrency}>
      ${(record[meta.id] || 0).toLocaleString('en-US', { maximumFractionDigits: 5 })}
    </Typography>
  );
};

export const EditBillItemLineModal = ({ open, data, onClose, onComplete, onCancel }) => {
  const BillLayoutMeta = BillItemLayout.entity.layouts.web;
  BillLayoutMeta.buttons.cancel.action = onCancel;
  const user = useSelector(state => state.user);

  const [costCodes, costTypes, revenueTypes] = useLabourTypeDependency(
    user.tenantId,
    user.tenantCompanyId
  );

  return (
    <ThemeProvider>
      <Modal fullWidth maxWidth="md" open={open} onClose={onClose}>
        <ErrorBoundaries>
          <PageForm
            buttons={BillLayoutMeta.buttons}
            caslKey={PermissionConstants.OBJECT_REVIEWREPORT}
            data={data}
            entityName="PurchaseOrderLine"
            layout={setTitleForPageForm('edit', BillLayoutMeta.sections)}
            mode="edit"
            noDataMsg="No items"
            queryResult={{
              costCodes,
              costTypes,
              revenueTypes
            }}
            showFooterButton
            skipSave
            validateWithSchema={validations.reviewReportBillLinesSchema}
            onComplete={onComplete}
          />
        </ErrorBoundaries>
      </Modal>
    </ThemeProvider>
  );
};

const BillItemLine = ({ current, send, billItem, isReviewReport, marginEnabled }) => {
  const { context, value } = current;

  const isEditable = isReviewReport && !context.freezeEdit;

  const BillItemLineActions = ({ record }) => {
    return (
      <RowActions
        deleteAction={() =>
          send('DELETE_BILL_LINE_ITEM', {
            data: { id: record.id, parent: billItem }
          })
        }
        editAction={() => send('EDIT_BILL_LINE_ITEM', { data: record, parent: billItem })}
        isEditable={isEditable}
        record={record}
      />
    );
  };

  const billItemsTableMeta = useMemo(
    () =>
      [
        ...billItemsRows(marginEnabled),
        isReviewReport && {
          id: 'actions',
          isCustom: true,
          type: 'actions',
          label: ''
        }
      ].filter(Boolean),
    [isReviewReport]
  );

  return (
    <>
      <Grid style={{ marginLeft: 100, marginBottom: 10 }}>
        <ResponsiveTable
          customCellComponents={{ actions: BillItemLineActions, CustomCurrency }}
          data={billItem?.billLineItems || []}
          disableFilter
          disablePagination
          noDataMsg="No items"
          noMaxHeight
          rowMetadata={billItemsTableMeta}
        />
      </Grid>
      <EditBillItemLineModal
        data={context.modalRecord}
        open={value?.bill_line_edited || false}
        onCancel={() => send('CLOSE')}
        onClose={() => send('CLOSE')}
        onComplete={values => {
          const formattedValues = {
            ...values,
            markup: marginEnabled ? getMarkupFromMargin(values.markup) : values.markup
          };
          send('SAVE', { saveData: formattedValues });
        }}
      />
    </>
  );
};

export default BillItemLine;
