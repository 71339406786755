/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useMemo } from 'react';

import { jsx } from '@emotion/react';

import Column from '../Column';

const getTotalLabel = () => 'TOTAL';

const TotalColumn = ({ rows, colDef, scrollX, noWrap, lockRowHeight, isFirstColumn }) => {
  const totalColDef = useMemo(
    () => ({
      ...colDef,
      valueGetter: isFirstColumn ? getTotalLabel : colDef.totalGetter,
      valueFormatter: colDef.totalFormatter,
      renderCell: colDef.renderTotal,
      cellValueGetter: colDef.valueGetter
    }),
    [colDef]
  );

  return (
    <Column
      key={colDef.field}
      rows={rows}
      {...{ scrollX, noWrap, colDef: totalColDef, lockRowHeight, isTotal: true }}
    />
  );
};

export default TotalColumn;
