import React from 'react';

import { Button, ThemeProvider, TV, TW, Typography } from '@BuildHero/sergeant';

import { StepLabel, useTheme } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import Stepper from '@material-ui/core/Stepper';

import { withStyles } from '@material-ui/core/styles';

import BackArrowIcon from '@material-ui/icons/ArrowBack';
import ForwardArrowIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';

const style = {
  root: {
    backgroundColor: 'inherit'
  }
};

const useStyles = theme => ({
  fixedSection: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100vw',
    backgroundColor: theme.palette.grayscale(95),
    borderTop: 'solid 1px',
    borderColor: theme.palette.grayscale(80),
    padding: 24
  },
  footerContent: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  stepIcon: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    color: theme.palette.grayscale(95),
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: '4px'
  },
  nextStepIcon: {
    backgroundColor: theme.palette.grayscale(50)
  },
  prevStepIcon: {
    backgroundColor: theme.palette.brand.logoBlue,
    paddingTop: 0
  },
  currentStepIcon: {
    backgroundColor: theme.palette.support.blue.dark
  },
  stepContent: {
    paddingLeft: 8
  },
  stepper: {
    width: 1200
  }
});
const ServiceAgreementCreationWizardFooter = ({
  steps,
  currentStepIndex,
  onChangeStep,
  classes,
  disabledNext,
  disabledPrevious,
  transitioningStep
}) => {
  const theme = useTheme();

  const styles = useStyles(theme);

  return (
    <ThemeProvider>
      <div css={styles.fixedSection}>
        <div css={styles.footerContent}>
          <Button
            disabled={disabledPrevious || currentStepIndex === 0}
            loading={transitioningStep === 'BACK'}
            style={{
              marginTop: 10,
              backgroundColor: theme.palette.support.blue.dark,
              height: 40
            }}
            variant={TV.L}
            weight={TW.BOLD}
            onClick={() => onChangeStep('BACK')}
          >
            <BackArrowIcon />
            Previous Step
          </Button>
          <div css={styles.stepper}>
            <Stepper activeStep={currentStepIndex} className={classes.root}>
              {steps.map((s, index) => {
                const isCurrentStep = index === currentStepIndex;
                const isPastStep = index < currentStepIndex;
                const isForwardStep = index > currentStepIndex;
                return (
                  <Step classes={styles.step} key={s.label}>
                    <StepLabel
                      completed={index < currentStepIndex}
                      optional={
                        s.isRequired && (
                          <Typography
                            color={theme.palette.grayscale(50)}
                            variant={TV.S2}
                            weight={TW.MEDIUM}
                          >
                            REQUIRED
                          </Typography>
                        )
                      }
                      StepIconComponent={() => (
                        <div
                          css={{
                            ...styles.stepIcon,
                            ...(isPastStep ? styles.prevStepIcon : {}),
                            ...(isCurrentStep ? styles.currentStepIcon : {}),
                            ...(isForwardStep ? styles.nextStepIcon : {})
                          }}
                        >
                          {isPastStep ? (
                            <CheckIcon />
                          ) : (
                            <Typography weight={TW.MEDIUM}>{index + 1}</Typography>
                          )}
                        </div>
                      )}
                    >
                      {s.label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          <Button
            disabled={disabledNext || currentStepIndex === steps.length - 1}
            loading={transitioningStep === 'FORWARD'}
            style={{
              marginTop: 10,
              backgroundColor: theme.palette.support.blue.dark,
              height: 40
            }}
            weight={TW.BOLD}
            onClick={() => onChangeStep('FORWARD')}
          >
            Next Step
            <ForwardArrowIcon />
          </Button>
        </div>
      </div>
      {/* empty div to allow scrolling to bottom of page */}
      <div
        style={{
          height: 171
        }}
      />
    </ThemeProvider>
  );
};

export default withStyles(style)(ServiceAgreementCreationWizardFooter);
