import AmplifyService from 'services/AmplifyService';

import addLabourRate from '../../graphql/pricebook/mutations/addLabourRate';
import addProductBundles from '../../graphql/pricebook/mutations/addProductBundle';
import addProductEntriesToBundle from '../../graphql/pricebook/mutations/addProductEntriesToBundle';
import softDeleteProductBundle from '../../graphql/pricebook/mutations/softDeleteProductBundle';
import softDeleteProductBundleEntry from '../../graphql/pricebook/mutations/softDeleteProductBundleEntry';
import updateLabourRate from '../../graphql/pricebook/mutations/updateLabourRate';
import updateProduct from '../../graphql/pricebook/mutations/updateProduct';
import updateProductBundles from '../../graphql/pricebook/mutations/updateProductBundle';
import updateProductEntriesInBundle from '../../graphql/pricebook/mutations/updateProductEntriesInBundle';
import getProductBundles from '../../graphql/pricebook/queries/getProductBundles';
import getProducts from '../../graphql/pricebook/queries/getProducts';
import getProductById from '../../graphql/pricebook/queries/getProductsById';
import getProductsByProductBundleId from '../../graphql/pricebook/queries/getProductsByProductBundleId';
import addNonInventoryProduct from '../../graphql/quickbookIntegration/mutations/addNonInventoryProduct';

export default class PricebookService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
  }

  getProducts = async (partitionKey, sortKey, filter, limit, offset, sortBy, sortOrder) => {
    const params = {
      partitionKey,
      sortKey,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    let customizedFilter;
    if (filter && filter.booleanFilters) {
      const tempBooleanFilter = filter.booleanFilters;
      customizedFilter = {
        ...filter,
        booleanFilters: [
          { fieldName: 'Product.isActive', filterInput: { eq: true } },
          ...tempBooleanFilter
        ]
      };
    } else {
      customizedFilter = {
        booleanFilters: [{ fieldName: 'Product.isActive', filterInput: { eq: true } }],
        ...filter
      };
    }
    params.filter = customizedFilter;

    let result;
    const response = await this.api.query(getProducts, params);
    const formattedResult = { items: [] };
    if (response && response.data) {
      result = response.data.getCompany.products;
      if (result.items && result.items.length) {
        result.items.forEach(item => {
          const vendorArray =
            item.productVendors &&
            item.productVendors.items &&
            item.productVendors.items.length > 0 &&
            item.productVendors.items;
          const vendorNames = [];
          vendorArray &&
            vendorArray.forEach(vendor => {
              vendorNames.push(vendor.name);
            });
          formattedResult.items.push({
            id: item.id,
            sortKey: item.sortKey,
            version: item.version,
            name: item.name,
            description: item.description,
            accountingRefId: item.accountingRefId,
            syncStatus: item.syncStatus,
            code: item.code,
            isActive: item.isActive,
            unitCost: item.unitCost,
            taxable: item.taxable,
            vendorSortKey: ((((item.productVendors || {}).items || [])[0] || {}).vendor || {})
              .sortKey,
            vendorName: ((((item.productVendors || {}).items || [])[0] || {}).vendor || {}).name,
            glIncomeAccountRefId: (item.glIncomeAccountRef || {}).id,
            glIncomeAccountRef: item.glIncomeAccountRef,
            glExpenseAccountRefId: (item.glExpenseAccountRef || {}).id,
            glExpenseAccountRef: item.glExpenseAccountRef,
            unitOfMeasureId: item.unitOfMeasureId,
            unitOfMeasure: item.unitOfMeasure,
            costCodeId: item.costCodeId,
            costCode: item.costCode,
            jobCostTypeId: item.jobCostTypeId,
            jobCostType: item.jobCostType,
            revenueTypeId: item.revenueTypeId,
            revenueType: item.revenueType,
            itemGlGroupId: item.itemGlGroupId,
            itemGlGroup: item.itemGlGroup
          });
        });
      }
    }
    if (result.nextToken) {
      formattedResult.nextToken = result.nextToken;
    }
    return formattedResult;
  };

  getProductBundles = async (partitionKey, sortKey, filter, limit, offset, sortBy, sortOrder) => {
    const params = {
      partitionKey,
      sortKey,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    let customizedFilter = {
      booleanFilters: [{ fieldName: 'isActive', filterInput: { eq: true } }]
    };

    if (filter && filter.booleanFilters) {
      const tempBooleanFilter = filter.booleanFilters;
      customizedFilter = {
        ...filter,
        booleanFilters: [{ fieldName: 'isActive', filterInput: { eq: true } }, ...tempBooleanFilter]
      };
    } else if (filter) {
      customizedFilter = { ...customizedFilter, ...filter };
    }
    params.filter = customizedFilter;

    if (limit) {
      params.limit = limit;
      params.offset = offset || 0;
    }

    let result;
    const response = await this.api.query(getProductBundles, params);
    const formattedResult = { items: [] };
    if (
      response &&
      response.data &&
      response.data.getCompany &&
      response.data.getCompany.productBundles
    ) {
      result = response.data.getCompany.productBundles;
      if (result.items && result.items.length) {
        result.items.forEach(item => {
          formattedResult.items.push({
            id: item.id,
            sortKey: item.sortKey,
            version: item.version,
            name: item.name,
            description: item.description,
            category: item.category,
            subCategory: item.subCategory,
            type: item.type,
            isActive: item.isActive,
            productBundleEntries: item.productBundleEntries
          });
        });
      }
    }
    if (result && result.nextToken) {
      formattedResult.nextToken = result.nextToken;
    }
    return formattedResult;
  };

  getProductsByProductBundleId = async (id, filter, limit, offset, sortBy, sortOrder) => {
    const params = {
      id,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    let customizedFilter;
    if (filter && filter.booleanFilters) {
      const tempBooleanFilter = filter.booleanFilters;
      customizedFilter = {
        ...filter,
        booleanFilters: [
          { fieldName: 'ProductBundleEntry.isActive', filterInput: { eq: true } },
          ...tempBooleanFilter
        ]
      };
    } else {
      customizedFilter = {
        booleanFilters: [{ fieldName: 'ProductBundleEntry.isActive', filterInput: { eq: true } }]
      };
    }
    params.filter = customizedFilter;

    if (limit) {
      params.limit = limit;
      params.offset = offset || 0;
    }

    let productBundleInfo;
    let result;
    const response = await this.api.query(getProductsByProductBundleId, params);
    if (response && response.data && response.data.getProductBundleById) {
      result = response.data.getProductBundleById;
      productBundleInfo = {
        id: result.id,
        version: result.version,
        name: result.name,
        description: result.description,
        category: result.category,
        subCategory: result.subCategory,
        type: result.type,
        isActive: result.isActive,
        items: []
      };

      if (
        result.productBundleEntriesView &&
        result.productBundleEntriesView.items &&
        result.productBundleEntriesView.items.length
      ) {
        result.productBundleEntriesView.items.forEach(item => {
          productBundleInfo.items.push({
            id: item.id,
            version: item.version,
            isActive: item.isActive,
            name: item.name,
            description: item.description,
            unitCost: item.unitCost,
            productId: item.productId,
            taxable: item.taxable,
            quantity: item.quantity,
            quantityOnHand: item.quantityOnHand,
            minQuantity: item.minQuantity,
            maxQuantity: item.maxQuantity,
            unitOfMeasure: ((item.product || {}).unitOfMeasure || {}).name || ''
          });
        });
      }
    }
    if (result && result.productBundleEntriesView && result.productBundleEntriesView.nextToken) {
      productBundleInfo.nextToken = result.productBundleEntriesView.nextToken;
    }
    return productBundleInfo;
  };

  getProductById = async id => {
    const response = await this.api.query(getProductById, { id });
    let result;
    if (response && response.data) {
      result = response.data.getProductById;
    }
    return result;
  };

  addNonInventoryProduct = async (partitionKey, values) => {
    const dataSet = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(addNonInventoryProduct, dataSet);
    return response;
  };

  updateProduct = async (partitionKey, values) => {
    const dataSet = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(updateProduct, dataSet);
    return response;
  };

  addProductBundle = async (partitionKey, values) => {
    const dataSet = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(addProductBundles, dataSet);
    return response;
  };

  updateProductBundle = async (partitionKey, values) => {
    const dataSet = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(updateProductBundles, dataSet);
    return response;
  };

  addProductEntriesToBundle = async (partitionKey, values) => {
    const dataSet = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(addProductEntriesToBundle, dataSet);
    return response;
  };

  updateProductEntriesInBundle = async (partitionKey, values) => {
    const dataSet = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(updateProductEntriesInBundle, dataSet);
    return response;
  };

  softDeleteProductBundle = async (partitionKey, id) => {
    const dataSet = {
      partitionKey,
      id
    };

    const response = await this.api.mutate(softDeleteProductBundle, dataSet);
    return response;
  };

  softDeleteProductBundleEntry = async (partitionKey, id) => {
    const dataSet = {
      partitionKey,
      id
    };

    const response = await this.api.mutate(softDeleteProductBundleEntry, dataSet);
    return response;
  };

  addLabourRate = async (partitionKey, data) => {
    const params = {
      data,
      partitionKey
    };

    const response = await this.api.mutate(addLabourRate, params);
    return response;
  };

  updateLabourRate = async (partitionKey, data) => {
    const params = {
      data,
      partitionKey
    };

    const response = await this.api.mutate(updateLabourRate, params);
    return response;
  };
}
