import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';

import { reportFragment } from './queryFragment';

export const GET_VISIT_DETAILS_BY_REPORT_ID = gql`
  query getVisitById($id: String!) {
    getVisitById(id: $id) {
      ${reportFragment}
    }
  }
`;

const transform = data => {
  return data?.getVisitById;
};

export const useVisitDetails = (id, queryFragment = GET_VISIT_DETAILS_BY_REPORT_ID) => {
  return useExtendedQuery(queryFragment, {
    variables: {
      id
    },
    transform,
    defaultData: {
      visit: {
        job: {}
      }
    }
  });
};
